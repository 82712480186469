import React, { useState, useEffect } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { Field, reduxForm, formValueSelector, reset, FieldArray, change } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusPercentageInput,
  ReduxPlusMinusInputWithRange,
} from '../../../../component/custom-redux-components';
import { comboServiceCode, getServiceCode } from '../../../../store/actions';
import validate from '../../../../core/generic-form/validation';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
import BuildServiceBundle from './buildServiceBundle';
const { Panel } = Collapse;

const ServiceType = props => {
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.accessingInContract) {
      dispatch(
        comboServiceCode({
          Json: JSON.stringify({
            ServiceCode: [
              {
                ServiceCodeTypeId: 2,
              },
            ],
          }),
        }),
      );
      if (props.fields && props.fields.length < 1) {
        props.fields.push();
      }
    }
  }, []);

  const {
    fields,
    CenterConsumable,
    ConsumableFormData,
    setTotalCostOfCare,
    centerServiceTypeCenter,
    existingRows,
    centerOverHeadCostState,
    existingRowsBuildServiceBundle,
    goalIndex,
    bundleIndex,
    accessingInContract,
  } = props;
  let existingRowsWithServiceCodes =
    (existingRows && existingRows.filter(item => item && item.ProgramId)) || [];

  let NewCenterServiceTypeCenter = centerServiceTypeCenter || [];
  let scDropDownOptions = [];
  existingRowsWithServiceCodes.map((mainItem, index) => {
    NewCenterServiceTypeCenter =
      NewCenterServiceTypeCenter &&
      NewCenterServiceTypeCenter.filter(item => {
        return item.Value != mainItem.ProgramId;
      });
  });

  const handleTotalChange = () => {
    // ConsumableFormData && ConsumableFormData.length > 0 &&
    if (
      ConsumableFormData &&
      ConsumableFormData.some(item => item && item.Consumable != undefined)
    ) {
      let result = ConsumableFormData.map((Item, Index) => {
        let filterV;
        let consumablePriceValue;
        if (Item != undefined && Item.Consumable != undefined) {
          filterV = CenterConsumable.filter(option => {
            return option.Value == Item.Consumable;
          });
          consumablePriceValue = filterV.length > 0 && filterV[0].Price * Item.UnitNeeded;
        }
        return consumablePriceValue;
      });
      let newTotal =
        result.filter(item => item).length > 0 &&
        result
          .filter(item => item)
          .reduce((p, c) => {
            return p + c;
          });
      if (!isNaN(newTotal)) {
        setTotal(newTotal);
        setTotalCostOfCare(newTotal);
      }
    } else {
      setTotal(0);
      setTotalCostOfCare(0);
    }
  };

  const onChange = key => {
    // console.log(key);
  };
  useEffect(() => {
    handleTotalChange();
  }, [ConsumableFormData]);

  return (
    <div className="row buildServiceBundle">
      <div className="col-12 col-md-12 col-lg-12">
        <div className="p-2">
          <div className="d-flex justify-content-between p-2 mx-3">
            {/* <div className="bold h5">{`Service Types`}</div> */}
          </div>
          <Collapse
            // expandIcon={({ isActive }) => (
            //   <RightCircleOutlined
            //     rotate={isActive ? -90 : 90}
            //     style={{
            //       fontSize: '20px',
            //       marginTop: '4px',
            //       color: 'rgba(0,0,0,0.7)',
            //     }}
            //   />
            // )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={onChange}
          >
            {fields.length > 0 ? (
              fields.map((item, index) => {
                let temp = [];
                if (existingRowsWithServiceCodes[index]) {
                  temp = centerServiceTypeCenter
                    ? centerServiceTypeCenter.filter(
                        code => code.Value == existingRowsWithServiceCodes[index].ProgramId,
                      )
                    : [];
                }
                scDropDownOptions = [...NewCenterServiceTypeCenter, ...temp];

                return (
                  <>
                    <Panel
                      className="service-type-panel-border"
                      key={index}
                      header={
                        <>
                          <div className="row">
                            <div className="col-md-3 panel-header-text">{`Service Type ${index +
                              1}`}</div>
                            <div className="col-md-9 text-right d-flex">
                              {props.existingRows ? (
                                props.existingRows.every(item => {
                                  if (item != undefined) {
                                    if (Object.keys(item).length > 0) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                }) ? (
                                  <>
                                    {!props.disabled && (
                                      <button
                                        id="scroll-to-bottom"
                                        type="button"
                                        class="btn-newCon"
                                        style={{
                                          position: 'unset',
                                          width: 'unset',
                                        }}
                                        onClick={event => {
                                          event.stopPropagation();
                                          fields.push();
                                          let pageBottom = document.querySelector('#page-bottom');
                                          pageBottom.scrollIntoView(true);
                                        }}
                                      >
                                        + Add{' '}
                                        {fields.length == 0
                                          ? `Service Type`
                                          : `Another Service Type`}
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn-disabled"
                                    style={{
                                      position: 'unset',
                                      width: 'unset',
                                    }}
                                    disabled={true}
                                  >
                                    + Add{' '}
                                    {fields.length == 0 ? `Service Type` : `Another Service Type`}
                                  </button>
                                )
                              ) : (
                                <>
                                  {!props.disabled && (
                                    <button
                                      type="button"
                                      class="btn-newCon"
                                      style={{
                                        position: 'unset',
                                        width: 'unset',
                                      }}
                                      onClick={event => {
                                        event.stopPropagation();
                                        fields.push();
                                      }}
                                    >
                                      + Add{' '}
                                      {fields.length == 0 ? `Service Type` : `Another Service Type`}
                                    </button>
                                  )}
                                </>
                              )}
                              {fields.length > 1 && (
                                <div>
                                  {!props.disabled && (
                                    <a
                                      class="col-1 delete-contract"
                                      onClick={() => {
                                        if (fields.length && fields.length > 0) {
                                          fields.remove(index);
                                        }
                                      }}
                                    >
                                      <i class="fas fa-trash" />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    >
                      <div
                        class="mx-4"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {' '}
                        <div class="row p-2">
                          {/* <div key={index} className="col-12 field-array-item">
                                  
                                 </div> */}
                          <div className="col-6">
                            <div class="form-group">
                              <Field
                                name={`${item}ProgramId`}
                                type="text"
                                component={ReduxSelect}
                                placeholder="Select Service Type*"
                                //   errorMessage="Center Name Required"
                                validate={validate.required}
                                fieldName={'Service Type'}
                                // options={centerProgramType}
                                options={scDropDownOptions}
                                // isMulti={true}
                                class="form-control"
                                onChange={() => {
                                  dispatch(
                                    change(
                                      'contractForm',
                                      `Bundles[${bundleIndex}]ServiceType[${index}]BuildServiceBundle`,
                                      [{}],
                                    ),
                                  );
                                  props.setFieldChanged('ProgramId');
                                }}
                                disabled={props.disabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {props.existingRows &&
                      props.existingRows[index] &&
                      props.existingRows[index].ProgramId ? (
                        <FieldArray
                          name={`${item}BuildServiceBundle`}
                          component={BuildServiceBundle}
                          initialValues={
                            props.initialValues &&
                            props.initialValues[index] &&
                            props.initialValues[index].BuildServiceBundle
                          }
                          setIndexOfNetMarginChange={props.setIndexOfNetMarginChange}
                          setCurrentServiceTypeIndex={props.setCurrentServiceTypeIndex}
                          centerOverHeadCostState={centerOverHeadCostState}
                          setTotalCostOfCare={setTotalCostOfCare}
                          existingRowsBuildServiceBundle={existingRowsBuildServiceBundle}
                          serviceTypeIndex={index}
                          goalIndex={goalIndex}
                          bundleIndex={bundleIndex}
                          setFieldChanged={props.setFieldChanged}
                          existingRows={
                            existingRows &&
                            existingRows[index] &&
                            existingRows[index].BuildServiceBundle
                          }
                          accessingInContract={accessingInContract}
                          disabled={props.disabled}
                        />
                      ) : (
                        <div className="row">
                          <div className="col-md-12 mx-4 mt-2">Please select a Service Type.</div>
                        </div>
                      )}
                    </Panel>
                  </>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
          <p id="page-bottom" style={props.accessingInContract ? {} : { height: '100px' }}></p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    existingRowsBuildServiceBundle: formValueSelector('serviceBundle')(state, 'BuildServiceBundle'),
  };
};

const ReduxServiceType = connect(mapStateToProps)(ServiceType);
export default ReduxServiceType;
