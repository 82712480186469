import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FamilyProgram from './familyProgram';
import SmallGroup from './smallGroup';
import Analytics from './analytics';
import PreIntake from './preIntake';
import Breathalyzer from './breathalyzer';
import AugmentedAI from './augmentedAI';
class TrialExpired extends Component {
  renderModule = () => {
    const { StatusData, moduleName } = this.props;
    switch (moduleName) {
      case 'FamilyProgram':
        return <FamilyProgram history={this.props.history} {...this.props} />;
      case 'SmallGroup':
        return <SmallGroup history={this.props.history} {...this.props} />;
      case 'Analytics':
        return <Analytics StatusData={StatusData} history={this.props.history} />;
      case 'PreIntake':
        return <PreIntake StatusData={StatusData} history={this.props.history} />;
      case 'Breathalyzer':
        return <Breathalyzer StatusData={StatusData} history={this.props.history} />;
      case 'AugmentedAI':
        return <AugmentedAI StatusData={StatusData}  history={this.props.history} {...this.props} />;
      case 'Judicial':
        return null;
      case 'Alumni':
        return null;
      case 'ClinicalWorkflow':
        return null;
      case 'Telehealth':
        return null;
      case 'GrowthPlan':
        return null;
      case 'Courses':
        return null;
      default:
        return null;
    }
  };

  render() {
    return <>{this.renderModule()}</>;
  }
}

export default withRouter(TrialExpired);
