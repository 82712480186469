import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import TextareaAutosize from 'react-autosize-textarea';

const TwilioNotes = props => {
  const [notesText, setNotesText] = useState();
  const handleChange = event => {
    let val = event.target.value;
    setNotesText(val);
    props.getTwilioNotes(val);
  };
  return (
    <div className="twilio-notes">
      <div className="text-box">
        <TextareaAutosize
          type="text"
          tabindex="0"
          rows="2"
          placeholder="Take a note..."
          className="textarea-type"
          value={notesText}
          onChange={handleChange}
          style={{
            borderRadius: '4px',
            border: '1px solid #DEDEDE',
            maxHeight: '400px',
            overflow: 'auto',
            width: '100%',
          }}
        />
        <span className="info-text">This will be saved in appointment notes </span>
      </div>
    </div>
  );
};
export default TwilioNotes;
