import React, { Component } from 'react';
import ListRenderer from '../Components/listRenderer';
import { Table, Drawer } from 'antd';
import { Line, Bar } from 'react-chartjs-2';
import CustomModal from '../core/modal';
import AntdModal from '../Components/CustomModal';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { connect } from 'react-redux';
import {
  getStudentSurveyDashboardData,
  resetSurveyAnswersGet,
  surveyAnswersGet,
  userFeatureFlagGet,
} from '../store/actions';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Select from '../component/select/index';
import { Webcam } from 'react-webcam';
import renderHTML from 'react-render-html';
import SurveyResponseDrawer from './customScreens/surveyResponse';
import Loader, { Loader2 } from '../Components/Loader';
import PageTitle from '../Components/PageTitle';

const columns = [
  {
    title: 'Survey Category',
    dataIndex: 'SurveyCategory',
    key: 'SurveyCategory',
    sorter: (a, b) => isComparer(a.SurveyCategory, b.SurveyCategory),
    sortDirections: ['descend', 'ascend'],
    width: 250,
    render: (text, record) => (
      <div class="student-list-pic d-flex align-items-center">
        {record.IsAugmentAllowed ? (
          <>
            <img
              src={'images/gpt-ai.svg'}
              // alt={record.altText}
              class="img-circle mr-2"
              height="40"
              width="40"
              style={{
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
            <span> {text}</span>
          </>
        ) : (
          <span> {text}</span>
        )}
      </div>
    ),
  },
  {
    title: 'Survey Sub-Category',
    dataIndex: 'SurveySubCategory',
    key: 'SurveySubCategory',
    sorter: (a, b) => isComparer(a.SurveySubCategory, b.SurveySubCategory),
    sortDirections: ['descend', 'ascend'],
    width: 250,
  },
  {
    title: 'Completed Date',
    dataIndex: 'CompletedDt',
    key: 'CompletedDt',
    sorter: (a, b) => isDateComparer(a.CompletedDtSortOrder, b.CompletedDtSortOrder),
    sortDirections: ['ascend', 'descend'],
    width: 250,
  },
  {
    title: 'Total Score',
    dataIndex: 'TotalScore',
    key: 'TotalScore',
    sorter: (a, b) => a.TotalScore - b.TotalScore,
    sortDirections: ['descend', 'ascend'],
    width: 250,
  },
];

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter);
}

class DischargeModal extends Component {
  state = {
    dueDate: '',
    dischargeReason: '',
    exitReasonNotes: '',
  };
  handleChange = date => {
    this.setState({
      dueDate: date,
    });
  };
  render() {
    const { onOk, onCancel, reasonOptions, onDischarge, history } = this.props;
    const { dueDate, dischargeReason, exitReasonNotes } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Discharge Date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">Discharge Details</h4>
        </div>
        <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5 enrollment-form">
          <div class="form-group">
            <DatePicker
              selected={dueDate}
              onChange={this.handleChange}
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
            />
            <i class="far fa-calendar-alt form-control-icon" />
          </div>
          <div class="form-group">
            <Select
              data={reasonOptions}
              allowDefault={true}
              value={dischargeReason}
              onChange={event => this.setState({ dischargeReason: parseInt(event.target.value) })}
            />
            <i class="fas fa-angle-down form-control-icon" />
          </div>
          <div class="form-group">
            <textarea
              type="text"
              class="form-control"
              value={exitReasonNotes}
              onChange={e => this.setState({ exitReasonNotes: e.target.value })}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>

        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" class="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
          <button
            type="button"
            class="btn "
            onClick={() => {
              onOk();
              onDischarge(dischargeReason, dueDate, exitReasonNotes);
              history.goBack();
            }}
          >
            Discharge
          </button>
        </div>
      </div>
    );
  }
}

const activeKey = 0;
class StudentSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyanswersdata: [],
      surveyanswersdatacol: [
        { title: 'Q No', dataIndex: 'QuestionNumber', key: 'QuestionNumber' },
        {
          title: 'Question',
          dataIndex: 'Question',
          key: 'Question',
          render: (text, record) => {
            return (
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && renderHTML(text)}
              </span>
            );
          },
        },
        { title: 'Answer', dataIndex: 'Answer', key: 'Answer' },
        { title: 'Responded Date Time', dataIndex: 'RespondedDateTime', key: 'RespondedDateTime' },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.dispatch(getStudentSurveyDashboardData(this.getStudentId()));
    // document.getElementsByClassName('ant-tabs-nav-scroll')[0].style.justifyContent = 'normal';
  }

  componentWillUnmount() {
    this.props.dispatch(resetSurveyAnswersGet());
  }

  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/students-survey/', ''));
  }

  componentWillReceiveProps({ surveyanswersdata, AugmentedAIStatusData }) {
    if (surveyanswersdata.length > 0) {
      this.setState({ surveyanswersdata, isResultDrawerOpen: true });
    }

    if (JSON.stringify(AugmentedAIStatusData) != JSON.stringify(this.props.AugmentedAIStatusData)) {
      if (AugmentedAIStatusData && AugmentedAIStatusData.StudentId == null) {
        this.props.dispatch(
          userFeatureFlagGet({
            JsonInput: JSON.stringify({ Center: { StudentId: this.getStudentId() } }),
          }),
        );
      }
    }
  }

  onrowclick = record => {
    this.state.questionCategory = record.SurveyCategory;
    this.state.answerRecord = record;
    this.props.dispatch(
      surveyAnswersGet({ studentId: this.getStudentId(), surveyId: record.SurveyId }),
    );
  };

  toggleDrawer = () => {
    this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
  };

  render() {
    const {
      surveryData: { StudentImageUrl, FullName, FirstName, LastName, MiddleName },
      lineCharts,
      polarCharts,
      isFetching,
      reasonOptions,
      EnrollStatus,
      isDischarged,
      EnrollExitNote,
      timeSeriesCharts,
      stackedBarCharts,
    } = this.props;
    const { surveyanswersdata, surveyanswersdatacol } = this.state;
    const {
      StudentData: { AdmissionDate },
      StudentMisc,
    } = this.props;
    var renderItems = null;
    var tabs = [];
    var records = null;
    const list = [];
    let barData, barOptions;
    if (stackedBarCharts[0] && stackedBarCharts[0].XAxisLabels) {
      const labels = stackedBarCharts[0].DataLabels.split(',');
      barData = {
        labels: stackedBarCharts[0].XAxisLabels.split(','), // DataLabels.split(','),
        datasets: [
          {
            type: 'bar',
            label: labels[0],
            borderColor: '#77B1FF',
            backgroundColor: '#77B1FF',
            borderWidth: 2,
            data: stackedBarCharts[0].Data1.split(','),
          },
          {
            type: 'bar',
            label: labels[1],
            borderColor: '#8ACB91',
            backgroundColor: '#8ACB91',
            borderWidth: 2,
            data: stackedBarCharts[0].Data2.split(','),
          },
          {
            type: 'bar',
            label: labels[2],
            backgroundColor: '#FFB37F',
            data: stackedBarCharts[0].Data3.split(','),
            borderColor: '#FFB37F',
            borderWidth: 2,
          },
          // {
          //   type: 'bar',
          //   label: 'Dataset 4',
          //   backgroundColor: 'blue',
          //   stack: 'Stack 0',
          //   data: [7, 2, 4, 5, 6, 4, 2]
          // }
        ],
      };
      barOptions = {
        maintainAspectRatio: true,
        responsive: true,
        title: {
          display: true,
          text: 'Progress Charts',
        },
        tooltips: {
          mode: 'index',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      };
    }
    if (polarCharts)
      polarCharts.map((item, index) => {
        const { ChartData, ChartLabels, LadderChartHeader, SurveyQuestionCategoryId } = item;
        const data = {
          datasets: [
            {
              data: ChartData.split(','),
              backgroundColor: [
                'rgba(0,123,255,0.6)',
                'rgba(253,126,20,0.6)',
                'rgba(40,167,69,0.6)',
                'rgba(232,62,140,0.6)',
                'rgba(255,193,7,0.6)',
                'rgba(220,53,69,0.6)',
                'rgba(102,160,242,0.6)',
                'rgba(0,0,255,0.6)',
                'rgba(0,255,0,0.6)',
                'rgba(51,0,0,0.6)',
                'rgba(138,163,123,0.6)',
                'rgba((204,0,255,0.6)',
              ],
            },
          ],
          labels: ChartLabels.split(','),
        };
        const option = {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                maxBarThickness: 48,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        };
        list.push({
          Name: LadderChartHeader,
          ExtraComponent: () => (
            <div className="row">
              <div className="col-12">
                <div className="polar">
                  <Bar data={data} options={option} />
                </div>
              </div>
            </div>
          ),
        });
      });

    if (polarCharts && polarCharts.length > 0) {
      for (var index in polarCharts) {
        var chart = polarCharts[index];
        var item = {
          type: 'Polar',
          title: chart.LadderChartHeader,
          data: {
            datasets: [
              {
                data: chart.ChartData.split(','),
                backgroundColor: [
                  'rgba(0,123,255,0.6)',
                  'rgba(253,126,20,0.6)',
                  'rgba(40,167,69,0.6)',
                  'rgba(232,62,140,0.6)',
                  'rgba(255,193,7,0.6)',
                  'rgba(220,53,69,0.6)',
                  'rgba(102,160,242,0.6)',
                  'rgba(0,0,255,0.6)',
                  'rgba(0,255,0,0.6)',
                  'rgba(51,0,0,0.6)',
                  'rgba(138,163,123,0.6)',
                  'rgba((204,0,255,0.6)',
                ],
              },
            ],
            labels: chart.ChartLabels.split(','),
          },
          option: {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              position: 'bottom',
            },
            title: {
              display: false,
              text: chart.LadderChartHeader,
              fontSize: 20,
              lineHeight: 1.4,
            },
            scale: {
              ticks: {
                display: true,
                beginAtZero: true,
                max: 100,
              },
              reverse: false,
            },
            animation: {
              animateRotate: false,
              animateScale: true,
            },
          },
        };
        tabs.push(item);
      }
    }

    if (timeSeriesCharts && timeSeriesCharts.length > 0 && timeSeriesCharts[0].XAxisLabels) {
      if (!renderItems) {
        renderItems = [];
      }

      const { Data1, Data2, Data3, Data4, Data5, DataLabels, XAxisLabels } = timeSeriesCharts[0];
      const dataLabels = DataLabels.split(',');

      var item = {
        type: 'Line',
        title: 'Survey Time Series',
        data: {
          labels: XAxisLabels.split(','),
          datasets: [
            {
              data: Data1.split(','),
              borderColor: '#2FE192',
              label: dataLabels[0],
              fill: false,
            },
            {
              data: Data2.split(','),
              borderColor: '#46B4FE',
              label: dataLabels[1],
              fill: false,
            },
            {
              data: Data3.split(','),
              borderColor: '#F98484',
              label: dataLabels[2],
              fill: false,
            },
            {
              data: Data4.split(','),
              borderColor: '#FEAD46',
              label: dataLabels[3],
              fill: false,
            },
            {
              data: Data5.split(','),
              borderColor: '#B2B2B2',
              label: dataLabels[4],
              fill: false,
            },
          ],
        },

        option: {
          tooltips: {
            caretPadding: 8,
          },
          // tooltips: {
          //   enabled: false,
          //   custom: function(tooltipModel) {
          //     var Index = tooltipModel.dataPoints && tooltipModel.dataPoints[0].index;
          //     var scores = lineCharts[0].Scores.split(',');
          //     // Tooltip Element
          //     var tooltipEl = document.getElementById('chartjs-tooltip');

          //     // Create element on first render
          //     if (!tooltipEl) {
          //       tooltipEl = document.createElement('div');
          //       tooltipEl.id = 'chartjs-tooltip';
          //       tooltipEl.innerHTML = '<table></table>';
          //       document.body.appendChild(tooltipEl);
          //     }

          //     // Hide if no tooltip
          //     if (tooltipModel.opacity === 0) {
          //       tooltipEl.style.opacity = 0;
          //       return;
          //     }

          //     // Set caret Position
          //     tooltipEl.classList.remove('above', 'below', 'no-transform');
          //     if (tooltipModel.yAlign) {
          //       tooltipEl.classList.add(tooltipModel.yAlign);
          //     } else {
          //       tooltipEl.classList.add('no-transform');
          //     }

          //     function getBody(bodyItem) {
          //       return bodyItem.lines[0].split(',');
          //     }

          //     // Set Text
          //     if (tooltipModel.body) {
          //       var titleLines = tooltipModel.title || [];
          //       var bodyLines = tooltipModel.body.map(getBody);

          //       var innerHtml = '<thead>';

          //       titleLines.forEach(function(title) {
          //         innerHtml += '<tr><th>' + title + '</th></tr>';
          //       });
          //       innerHtml += '</thead><tbody>';

          //       bodyLines.forEach(function(body, i) {
          //         var colors = tooltipModel.labelColors[i];
          //         var style = 'background:' + colors.backgroundColor;
          //         style += '; border-color:' + colors.borderColor;
          //         style += '; border-width: 2px';
          //         var span = '<span style="' + style + '"></span>';
          //         innerHtml +=
          //           '<tr><td>' +
          //           span +
          //           body[Index] +
          //           '<br/>' +
          //           'Score:' +
          //           scores[Index] +
          //           '</td></tr>';
          //       });
          //       innerHtml += '</tbody>';

          //       var tableRoot = tooltipEl.querySelector('table');
          //       tableRoot.innerHTML = innerHtml;
          //     }

          //     // `this` will be the overall tooltip
          //     var position = this._chart.canvas.getBoundingClientRect();

          //     // Display, position, and set styles for font
          //     tooltipEl.style.opacity = 1;
          //     tooltipEl.style.position = 'absolute';
          //     tooltipEl.style.left =
          //       position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          //     tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          //     tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          //     tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          //     tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          //     tooltipEl.style.padding =
          //       tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          //     tooltipEl.style.pointerEvents = 'none';
          //     tooltipEl.style.background = 'rgba(0, 0, 0, 0.63)';
          //     tooltipEl.style.color = '#ffffff';
          //     tooltipEl.style.border = '1px solid grey';
          //     tooltipEl.style.borderRadius = '4px';
          //   },
          // },
          legend: { position: 'bottom' },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                  max: 103,
                  min: -10,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontSize: 14,
                  fontColor: '#232c3d',
                },
              },
            ],
          },
          title: {
            display: false,
            text: 'World population per region (in millions)',
          },
          maintainAspectRatio: false,
          responsive: true,
          spanGaps: true,
          elements: {
            line: {
              tension: 0,
            },
          },
          animation: {
            onProgress: function(animation) {
              this.ctx.font = '.9rem "Gotham Book",sans-serif';
              this.ctx.fillStyle = 'white';
              this.ctx.textAlign = 'center';
              this.ctx.backgroundColor = 'white';
              if (this.chart && this.chart.data && this.chart.data.datasets) {
                this.chart.data.datasets.forEach(datasetItem => {
                  if (datasetItem && datasetItem._meta && datasetItem._meta[1]) {
                    Object.values(datasetItem._meta[1].data).forEach((item, index) => {
                      const {
                        _model: { x, y },
                      } = item;
                      this.ctx.fillStyle = 'white';
                      this.ctx.fillRect(
                        x - 1 - this.ctx.measureText(datasetItem.data[index]).width / 2,
                        y - 4,
                        this.ctx.measureText(datasetItem.data[index]).width + 2,
                        8,
                      );
                      this.ctx.fillStyle = 'black';
                      this.ctx.fillText(datasetItem.data[index], x, y + 3);
                    });
                  }
                });
              }
            },
          },
        },
      };
      renderItems.push(item);
    }

    if (records) {
      var item = {
        type: 'Table',
        title: 'Survey List',
        data: records,
        option: this.state.columns,
      };
      renderItems.push(item);
    }

    return (
      <div>
        <PageTitle TitleText="Students Survey" />
        <div class="main students-page mb-5">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 mb-3">
                <a
                  onClick={() => this.props.history.push('/studentProfile/' + this.getStudentId())}
                  class="back-link"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 32 32"
                    class="back-arrow"
                  >
                    <path
                      fill="#000"
                      fill-rule="nonzero"
                      d="M14.2 25.956a.886.886 0 0 1-.63-.261l-7.79-7.81a2.67 2.67 0 0 1 0-3.77l7.79-7.81a.889.889 0 0 1 1.259 1.256l-7.791 7.81a.89.89 0 0 0-.001 1.258l7.792 7.81a.889.889 0 0 1-.63 1.517zM27.755 16a.889.889 0 0 0-.89-.889H10.733a.889.889 0 1 0 0 1.778h16.135a.889.889 0 0 0 .889-.889z"
                    />
                  </svg>{' '}
                  Back
                </a>
              </div>
              <div class="col-12 mb-4 pt-2">
                <div class="card border-0 students-profile ">
                  <div class="row card-body align-items-center">
                    <div class="col-12 col-xl-6 mb-3 mb-xl-0">
                      <div class="row">
                        <div class="col-12 col-sm-auto pr-sm-0 mb-3 mb-md-0 text-center">
                          <div class="EditImage">
                            <div
                              class="images-placeholder2"
                              style={{
                                backgroundImage: 'url(&quot;images/surveys-img1.jpg&quot;)',
                              }}
                            >
                              <img
                                src={StudentMisc.StudentImageUrl}
                                class="students-profile-img images-placeholder2"
                                alt=""
                                width="84"
                              />
                              <CustomModal
                                ModalButton={({ onClick }) => (
                                  <div class="EditImageHover">
                                    <div class="group">
                                      <button
                                        title="Edit Image"
                                        class="btn"
                                        onClick={onClick}
                                        disabled={isDischarged}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  </div>
                                )}
                                ModalContent={({ onOk, onCancel }) => (
                                  <div class="modal-content border-0">
                                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                      >
                                        &times;
                                      </button>
                                      <h4 class="modal-title col-12">Take A picture</h4>
                                    </div>
                                    <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
                                      <Webcam
                                        audio={false}
                                        ref={this.setRef}
                                        style={{ height: '400px', width: '400px', margin: 'auto' }}
                                        screenshotFormat="image/jpeg"
                                      />
                                    </div>
                                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                      <button
                                        type="button"
                                        class="btn btn-outline-primary"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        class="btn"
                                        onClick={() => {
                                          onOk();
                                          this.capture();
                                        }}
                                      >
                                        Capture
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-auto">
                          <h4 class="students-name">
                            {FirstName} {MiddleName} {LastName}
                          </h4>
                          <div class="students-action-button">
                            <button
                              onClick={() =>
                                this.props.history.push(
                                  `/enrollment/?studentid=${this.getStudentId()}`,
                                )
                              }
                              title="Edit Profile"
                              class="survey-btn btn-outline-primary btn black-btn"
                              disabled={isDischarged}
                            >
                              <figure
                                class="action-button-icon"
                                style={{ display: 'inline-block' }}
                              >
                                <img src="images/edit-profile-icon.svg" alt="" width="23" />
                              </figure>
                              Edit Profile
                            </button>
                            &nbsp;&nbsp;
                            {/* <AntdModal
                              ModalButton={({ onClick }) => (
                                <button
                                  title="Discharge"
                                  class="survey-btn btn btn-outline-primary black-btn"
                                  onClick={onClick}
                                  style={{
                                    display: 'inline',
                                  }}
                                >
                                  <figure
                                    class="action-button-icon"
                                    style={{ display: 'inline-block' }}
                                  >
                                    <img src="images/discharge-icon.svg" alt="" width="23" />
                                  </figure>
                                  {EnrollStatus && EnrollStatus == 'Discharged' ? (
                                    <span>Discharged</span>
                                  ) : (
                                    'Discharge'
                                  )}
                                </button>
                              )}
                              ModalContent={props => (
                                <DischargeModal
                                  onDischarge={this.onDischarge}
                                  history={this.props.history}
                                  reasonOptions={reasonOptions}
                                  EnrollExitNote={EnrollExitNote}
                                  {...props}
                                />
                              )}
                            /> */}
                            &nbsp;&nbsp;
                            {this.getStudentId() > 0 && (
                              <button
                                type="button"
                                class="btn btn-outline-primary survey-btn"
                                value="Pin"
                                disabled={isDischarged}
                                onClick={() => {
                                  const isWebCam =
                                    localStorage.getItem('isWebCam') === '0' ? false : true;
                                  if (isWebCam) {
                                    this.props.history.push({
                                      pathname: '/camera',
                                      search: 'studentId=' + this.getStudentId(),
                                      param: {
                                        message:
                                          'Changing PIN requires new profile picture to be taken.',
                                      },
                                    });
                                  } else {
                                    this.props.history.push({
                                      pathname: '/pin',
                                      search: 'studentId=' + this.getStudentId(),
                                    });
                                  }
                                }}
                              >
                                <figure
                                  class="action-button-icon"
                                  style={{ display: 'inline-block' }}
                                >
                                  <img src="images/change-pin@3x.png" alt="" width="23" />
                                  Pin
                                </figure>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-xl-6">
                      <div class="row students-detail   text-md-left  justify-content-md-between">
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Service Type</span>
                          <h5
                            class="info-detail m-md-0"
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '120px',
                            }}
                          >
                            {StudentMisc.ServiceType}
                          </h5>
                        </div>
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Age</span>
                          <h5 class="info-detail m-md-0">{StudentMisc.Age} Years</h5>
                        </div>
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Admission Date</span>
                          <h5 class="info-detail m-md-0">{AdmissionDate}</h5>
                        </div>
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Cost of Care To-Date</span>
                          <h5 class="info-detail m-md-0">${StudentMisc.CostofCareToDate}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="col-12 mb-5">
                <div class="card border-0 " style={{ overflow: 'hidden' }}>
                  <div class="row card-body">
                    <div style={{ marginLeft: '20px' }} class="center-tabs w-100">
                      code fot the tabs graphs
                      <ListRenderer list={list} activeKey={activeKey} />
                      {stackedBarCharts[0] && (
                        <Bar data={barData} options={barOptions} width={300} height={100} />
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-12 mb-5">
                <div class="card border-0 ">
                  <div class="row card-body">
                    <div class="col-12 mb-lg-4 pt-4">
                      <div class="row">
                        <div class="col-12 col-md-8 col-lg-10">
                          <h5 class="text-uppercase ">Survey Time Series</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <Line data={lineData1} options={lineOptions1} width={700} height={250} />
                      {renderItems && (
                        <Line
                          data={renderItems[0].data}
                          options={renderItems[0].option}
                          width={350}
                          height={300}
                          style={{ paddingTop: '10px' }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              <div class="col-12 mb-5 pt-2">
                <Loader2 loading={this.props.isFetching} />
                <div class="card border-0 ">
                  <div class="row card-body">
                    <div class="col-12 mb-lg-4">
                      <h5 class="text-uppercase pt-4">Survey List</h5>
                    </div>
                    <div class="col-12">
                      <div class="survey-list-table row-hover-enabled mb-3">
                        {/* <table class="table table-lg filtertable "> */}
                        <div>
                          <Table
                            onRow={(record, rowIndex) => {
                              return { onClick: e => this.onrowclick(record, rowIndex) };
                            }}
                            dataSource={this.props.records}
                            columns={columns}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div class="dataTables_wrapper no-footer">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a class="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a class="paginate_button next">Next</a>
                                      ) : type == 'jump-prev' ? (
                                        <a class="paginate_button ">{originalElement}</a>
                                      ) : type == 'jump-next' ? (
                                        <a class="paginate_button ">{originalElement}</a>
                                      ) : (
                                        <a class="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              showSizeChanger: true,
                              // hideOnSinglePage: true,
                              defaultPageSize: 10,
                              className: 'dataTables_wrapper dataTables_paginate',
                            }}
                          />
                          <Drawer
                            className="custom-drawer"
                            title="Survey Response"
                            placement="right"
                            visible={this.state.isResultDrawerOpen}
                            width={800}
                            bodyStyle={{ paddingBottom: 80 }}
                            closable={true}
                            destroyOnClose={true}
                            onClose={() => {
                              this.props.dispatch(resetSurveyAnswersGet());
                              this.toggleDrawer();
                            }}
                          >
                            <SurveyResponseDrawer
                              ClientId={this.getStudentId()}
                              toggleDrawer={this.toggleDrawer}
                              answerRecord={this.state.answerRecord}
                              ColData={surveyanswersdatacol}
                              RowData={surveyanswersdata}
                            />
                          </Drawer>
                          {/* <CustomModal
                            isOpen={this.state.modal}
                            yesAction={() => {
                              // this.toggle();
                              this.setState({
                                modal: false,
                              });
                            }}
                            yesText="OK"
                            hideCancel={true}
                            changeMaxWidth="600px"
                            className="px-0"
                          >
                            <div
                              class="col-lg-12 mb-3 student-profile-smiley-chart"
                              style={{ width: '100%' }}
                            >
                              <h4 class="text-center">
                                {this.state.questionCategory}
                                <br /> Survey Answers
                              </h4>
                              <div class="mar-top-30">
                                <Table
                                  dataSource={surveyanswersdata}
                                  columns={surveyanswersdatacol}
                                  pagination={{
                                    itemRender: (page, type, originalElement) => {
                                      return (
                                        <div class="dataTables_wrapper no-footer">
                                          <div class="dataTables_paginate paging_simple_numbers">
                                            {type == 'prev' ? (
                                              <a class="paginate_button previous">Previous</a>
                                            ) : type == 'next' ? (
                                              <a class="paginate_button next">Next</a>
                                            ) : (
                                              <a class="paginate_button ">{page}</a>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    },
                                    showSizeChanger: true,
                                    defaultPageSize: 5,
                                    // hideOnSinglePage: true,
                                  }}
                                />
                              </div>
                            </div>
                          </CustomModal> */}
                        </div>
                        {/* </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  get_student_survery_dashboard_data,
  survey_answers_get,
  student_get_profile_dashboard_data,
  combos,
  user_feature_flag_get,
}) => {
  var { data, isFetching, error } = get_student_survery_dashboard_data;
  const { data: fFData } = user_feature_flag_get;
  var records = null;
  var surveryData = {};
  var lineCharts = [],
    timeSeriesCharts = [],
    stackedBarCharts = [];
  var polarCharts = [],
    surveyanswersdata = [],
    StudentData = {},
    StudentMisc = {},
    isDischarged = false;
  var EnrollStatus = null,
    EnrollExitNote = null;
  let reasonOptions = [];
  let AugmentedAIStatusData = null;

  if (data) {
    surveryData = data;
    records = surveryData.SurveyList;
    polarCharts = surveryData.LadderPolarCharts;
    lineCharts = surveryData.SurveyLineChart;
    timeSeriesCharts = data.TimeSeriesCharts;
    stackedBarCharts = data.StackedBarCharts;
  }

  if (student_get_profile_dashboard_data.data) {
    StudentData = student_get_profile_dashboard_data.data;
    StudentMisc = StudentData.StudentMisc[0];
    EnrollStatus = StudentMisc.EnrollStatus;
    EnrollExitNote = StudentMisc.DischargeReason;
    isDischarged = EnrollStatus === 'Discharged';
  }

  if (survey_answers_get.data) {
    surveyanswersdata = Object.values(survey_answers_get.data);
  }
  if (combos.data && combos.data.ExitReason) {
    reasonOptions = combos.data.ExitReason || [];
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  return {
    records,
    surveryData,
    lineCharts,
    polarCharts,
    isFetching,
    surveyanswersdata,
    StudentData,
    StudentMisc,
    EnrollStatus,
    isDischarged,
    EnrollExitNote,
    timeSeriesCharts,
    stackedBarCharts,
    reasonOptions: reasonOptions,
    AugmentedAIStatusData,
  };
};
export default connect(mapStateToProps)(StudentSurveys);
