import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getSmallGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.getSmallGroup(payload);
    yield put({
      type: ActionTypes.GET_SMALLGROUP_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SMALLGROUP_DATA_FAILURE,
    });
  }
}
export function* addSmallGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.addSmallGroup(payload);
    yield put({
      type: ActionTypes.ADD_SMALLGROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_SMALLGROUP_DATA_FAILURE,
    });
  }
}
export function* getSmallGroupList(action) {
  try {
    const { payload } = action;
    const response = yield API.getSmallGroupList(payload);
    yield put({
      type: ActionTypes.GET_SMALL_GROUP_LIST_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SMALL_GROUP_LIST_FAILURE,
    });
  }
}
export function* getSmallGroupUsers(action) {
  try {
    const { payload } = action;
    const response = yield API.getSmallGroupUsers(payload);
    yield put({
      type: ActionTypes.GET_SMALL_GROUP_USERS_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SMALL_GROUP_USERS_FAILURE,
    });
  }
}
export function* uploadLeaderPhoto(action) {
  try {
    const { payload } = action;
    const response = yield API.uploadLeaderPhoto(payload);
    yield put({
      type: ActionTypes.UPLOAD_LEADER_PHOTO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPLOAD_LEADER_PHOTO_FAILURE,
    });
  }
}
export function* isStaffProBio(action) {
  try {
    const { payload } = action;
    const response = yield API.isStaffProBio(payload);
    yield put({
      type: ActionTypes.CHECK_STAFF_PRO_BIO_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CHECK_STAFF_PRO_BIO_FAILURE,
    });
  }
}
export function* updateStaffProBio(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffProBio(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_PRO_BIO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_PRO_BIO_FAILURE,
    });
  }
}
export function* pastMeetingInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.pastMeetingInfo(payload);
    yield put({
      type: ActionTypes.PAST_MEETING_INFO_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAST_MEETING_INFO_FAILURE,
    });
  }
}
export function* listPastMeetingInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.listPastMeetingInfo(payload);
    yield put({
      type: ActionTypes.LIST_PAST_MEETING_INFO_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_PAST_MEETING_INFO_FAILURE,
    });
  }
}
export function* updateSmallGroupAttendence(action) {
  try {
    const { payload } = action;
    const response = yield API.updateSmallGroupAttendence(payload);
    debugger;
    yield put({
      type: ActionTypes.UPDATE_SMALL_GROUP_ATTENDENCE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_SMALL_GROUP_ATTENDENCE_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_SMALLGROUP_REQUEST, getSmallGroup),
    takeLatest(ActionTypes.ADD_SMALLGROUP_REQUEST, addSmallGroup),
    takeLatest(ActionTypes.GET_SMALL_GROUP_LIST_REQUEST, getSmallGroupList),
    takeLatest(ActionTypes.GET_SMALL_GROUP_USERS_REQUEST, getSmallGroupUsers),
    takeLatest(ActionTypes.UPLOAD_LEADER_PHOTO_REQUEST, uploadLeaderPhoto),
    takeLatest(ActionTypes.CHECK_STAFF_PRO_BIO_REQUEST, isStaffProBio),
    takeLatest(ActionTypes.UPDATE_STAFF_PRO_BIO_REQUEST, updateStaffProBio),
    takeLatest(ActionTypes.PAST_MEETING_INFO_REQUEST, pastMeetingInfo),
    takeLatest(ActionTypes.LIST_PAST_MEETING_INFO_REQUEST, listPastMeetingInfo),
    takeLatest(ActionTypes.UPDATE_SMALL_GROUP_ATTENDENCE_REQUEST, updateSmallGroupAttendence),
  ]);
}
