import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { validateStaffPin, resetValidateStaffPin, validatePinExists } from '../store/actions';
import { Link } from 'react-router-dom';
import Loader, { Loader2 } from './Loader';
import SignaturePad from 'react-signature-canvas';
import SubmitButton from './submitButton';
class ValidatePin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enterPinModal: false,
      staffpin: '',
      errorMsg: '',
      signImageByteData: '',
    };

    this.pinInput = React.createRef();
  }

  componentDidMount() {
    this.props.dispatch(validatePinExists({}));
    this.props.dispatch(resetValidateStaffPin());
  }
  componentWillReceiveProps({ validPinModalVisible, validateStaffPinData }) {
    // if (validPinModalVisible && validPinModalVisible != this.props.validPinModalVisible) {
    //   this.setState({ enterPinModal: validPinModalVisible });
    // }

    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      if (validateStaffPinData.IsValid) {
        // this.setState({ enterPinModal: false });
        this.props.resetValidPinModalVisible();
        this.setState({ staffpin: '' });
      } else {
        if (Object.keys(validateStaffPinData).length > 1) {
          this.setState({ errorMsg: 'Incorrect Pin', staffpin: '' });
        }

        setTimeout(() => {
          this.setState({ errorMsg: '' });
          this.props.dispatch(resetValidateStaffPin());
        }, 3000);
        this.props.dispatch(resetValidateStaffPin());
      }
    }
  }
  componentDidUpdate() {
    if (this.pinInput && this.pinInput.current) {
      this.pinInput.current.focus();
    }
  }

  handlePinChange = e => {
    if (Number.isInteger(parseInt(e.target.value)) || e.target.value.length == 0) {
      if (e.target.value) {
        this.setState({ staffpin: e.target.value ? e.target.value : '' });
      } else {
        this.setState({ staffpin: e.target.value });
      }
    }
  };

  validatePin = () => {
    if (this.props.IsRealSignature) {
      this.props.dispatch(
        validateStaffPin({
          JsonInput: JSON.stringify({
            Staff: [
              {
                PIN: null,
                FormType: this.props.FormType,
                FormId: this.props.FormId,
              },
            ],
          }),
          IsSign: true,
          ImageSign: JSON.stringify({ Image: [{ Data: this.state.signImageByteData }] }),
        }),
      );
    } else {
      this.props.dispatch(
        validateStaffPin({
          JsonInput: JSON.stringify({
            Staff: [
              {
                PIN: this.state.staffpin,
                FormType: this.props.FormType,
                FormId: this.props.FormId,
              },
            ],
          }),
          // IsSign: IsRealSignature,
          // ImageSign:
        }),
      );
    }
  };
  enterButton = event => {
    if (event.key == 'Enter') {
      this.validatePin();
    }
  };

  saveSignature = data => {
    this.setState({ signImageByteData: data });
  };

  render() {
    const sigPadObj = {};
    const clear = index => {
      sigPadObj[index].clear();
    };
    const { IsRealSignature, isPinFetching } = this.props;
    return (
      <>
        <Modal
          width={600}
          zIndex={99999999}
          visible={this.props.validPinModalVisible}
          footer={null}
          onCancel={() => {}}
          closable={false}
          destroyOnClose={true}
        >
          <div class="modal-content border-0">
            <Loader2 loading={this.props.isFetching} />
            <div class="modal-header flex-wrap border-bottom-0 ">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                onClick={() => {
                  // this.setState({ enterPinModal: false });
                  if (this.props.resetPinConfirmModalVisible) {
                    this.props.resetPinConfirmModalVisible();
                  }
                  this.props.resetValidPinModalVisible();
                  if (this.props.offTheModal) {
                    this.props.offTheModal();
                  }
                }}
              >
                ×
              </button>
              <h4 class="modal-title col-12 text-center">
                {this.props.pinCheck
                  ? !this.props.IsRealSignature
                    ? 'PIN Confirmation'
                    : ' Signature'
                  : 'Setup a PIN'}
              </h4>
            </div>

            <div class="modal-body">
              {this.props.pinCheck ? (
                <form autocomplete="off">
                  {!this.props.IsRealSignature ? (
                    <div>
                      <p class="col-12 mb-3 font-weight-bold text-center">
                        Please validate your PIN to Sign and Submit.
                        {/* Are you sure you want to sign and submit this form.After submitting you will not be
                able to make changes to this form,Please enter your pin to sign and submit. */}
                      </p>
                      <input
                        type="password"
                        placeholder="Enter PIN here..."
                        onChange={this.handlePinChange}
                        value={this.state.staffpin}
                        className="form-control"
                        ref={this.pinInput}
                        style={{
                          marginRight: '15px',
                        }}
                        onKeyPress={this.enterButton}
                        autoFocus
                        autocomplete="false"
                      />
                      <span style={{ color: 'red' }}>
                        {this.state.errorMsg && this.state.errorMsg}
                      </span>
                      <br />
                      <div className="d-flex justify-content-center">
                        <input
                          className="btn"
                          type="button"
                          value="Validate PIN"
                          onClick={this.validatePin}
                        />
                      </div>
                      <br />
                      <br />
                      <div>
                        <span style={{ fontWeight: 'bold' }}>Attention:</span> <br />
                        Once you validate your PIN, you will not be able to make any further changes
                        to this form.
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="" style={{ width: '400px' }}>
                        <div className="sigContainer">
                          <SignaturePad
                            canvasProps={{ className: 'sigPad', height: 140, width: 400 }}
                            ref={ref => {
                              Object.assign(sigPadObj, { [1]: ref });
                            }}
                            onEnd={() => {
                              // this.trim(`sign+${SurveyQuestionId}`, index);
                              this.saveSignature(sigPadObj[1].toDataURL('image/png'));
                            }}
                          />
                        </div>
                        <div className="text-right mt-2">
                          {/* <button
                        className="can-buttons d-inline"
                        onClick={() => {
                          saveSignature(this.state.SignatureCanvas);
                        }}
                      >
                        Save
                      </button> */}
                          <button
                            className="can-buttons d-inline"
                            onClick={() => {
                              clear(1);
                              this.setState({ signImageByteData: '' });
                            }}
                          >
                            Clear
                          </button>
                        </div>
                        <div className="text-danger text-center">{this.state.errorForSign}</div>
                        <div className="d-flex justify-content-center">
                          <SubmitButton
                            // className="btn"
                            type="button"
                            loading={isPinFetching}
                            value="Submit Signature"
                            onClick={() => {
                              if (this.state.signImageByteData) {
                                this.validatePin();
                              } else {
                                this.setState(
                                  {
                                    errorForSign: 'Please sign before clicking submit',
                                  },
                                  () => {
                                    setTimeout(() => {
                                      this.setState({
                                        errorForSign: '',
                                      });
                                    }, 3000);
                                  },
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              ) : (
                <div>
                  <p className="text-center">
                    Please go to <b>Settings</b> and setup a PIN before you Sign and Submit.
                  </p>
                  <div className="text-center">
                    {' '}
                    <Link
                      className="btn"
                      to={{
                        pathname: '/settings',
                      }}
                      // target="_blank"
                    >
                      Open Settings
                    </Link>
                  </div>
                  {/* <button onClick={this.props.history.push('')}>Set Pin</button> */}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { validate_staff_pin, validate_pin_exists } = state;
  var { data, isFetching: isPinFetching } = validate_staff_pin;
  var { data: HasPinCheck, isFetching } = validate_pin_exists;

  let validateStaffPinData = {};
  let pinCheck = false;

  if (data && data) {
    validateStaffPinData = data;
  }
  if (HasPinCheck && HasPinCheck[0]) {
    pinCheck = HasPinCheck[0].Staff[0].HasPin;
  }

  return {
    validateStaffPinData,
    pinCheck,
    isFetching,
    isPinFetching,
  };
};

const ReduxValidatePin = connect(mapStateToProps)(ValidatePin);

export default ReduxValidatePin;
