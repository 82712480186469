import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover, Cascader, Icon, Checkbox } from 'antd';
import { staffPermission, updateStaffPermission } from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';

class PermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RolesPermissions: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(staffPermission({ staffId: this.props.staffId }));
    // var result = ProcessCombos('CourseType,CourseTopic,CenterCourses');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
  }
  componentWillReceiveProps({ RolesPermissions, isUpdated }) {
    if (RolesPermissions) {
      this.setState({
        RolesPermissions,
      });
    }
    if (isUpdated && isUpdated != this.props.isUpdated) {
      this.setState(
        {
          Msg: 'Saved successfully.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              Msg: '',
            });
          }, 2000);
        },
      );

      // this.props.dispatch(reset('inviteForm'));
    }
  }
  render() {
    const { isFetching, onOk, onCancel, staffId } = this.props;
    const { RolesPermissions, Msg } = this.state;

    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Roles Permissions</h4>
        </div>
        <div class="modal-body">
          <Loader2 loading={isFetching} />
          <div style={{ textAlign: 'center' }}>
            {/* <h6 style={{ fontSize: '14px', color: 'rgb(18, 196, 117)' }}>{Msg}</h6> */}
            {/* <h6 style={{ fontSize: '16px', color: 'rgb(255, 0, 0)' }}>{this.state.errorMessage}</h6> */}
          </div>
          <div class="row m-0 filter-modal">
            {RolesPermissions &&
              RolesPermissions.map((item, index) => {
                return (
                  <>
                    <div class="col-12 roles-permissions-modal">
                      <span className="rolls">
                        {index + 1}. {item.Description}
                      </span>
                      {this.state.index === index && (
                        <h6
                          style={{
                            fontSize: '14px',
                            color: 'rgb(18, 196, 117)',
                            marginLeft: '1.5rem',
                            display: 'inline-block',
                          }}
                        >
                          {Msg}
                        </h6>
                      )}
                      <div className="permissions-block">
                        {item.Permission &&
                          item.Permission.map((permission, rollIndex) => {
                            return (
                              <div>
                                <Checkbox
                                  disabled={permission.IsPermissionReadOnly}
                                  // className="permissions"
                                  className={
                                    permission.IsStaffPermissionEnabled
                                      ? 'permissions-active'
                                      : 'permissions'
                                  }
                                  name="permissions"
                                  checked={permission.IsStaffPermissionEnabled}
                                  value={permission.Name}
                                  onClick={e => {
                                    let newPermission = permission;
                                    newPermission.IsStaffPermissionEnabled = e.target.checked;
                                    let result = item;
                                    result.Permission[rollIndex] = newPermission;
                                    let data = RolesPermissions;
                                    data[index] = result;
                                    this.setState({
                                      index: index,
                                      RolesPermissions: data,
                                    });
                                    this.props.dispatch(
                                      updateStaffPermission({
                                        staffId: staffId,
                                        jsonInput: JSON.stringify({
                                          StaffPermission: [
                                            {
                                              StaffPermissionId: permission.StaffPermissionId,
                                              PermissionId: permission.PermissionId,
                                              IsStaffPermissionEnabled: e.target.checked,
                                            },
                                          ],
                                        }),
                                      }),
                                    );
                                  }}
                                  // onClick={e => {
                                  //   let result = this.state.result;
                                  //   result[val] = e.target.checked;
                                  //   let keys = Object.keys(result);
                                  //   let filtered = keys.filter(key => {
                                  //     return result[key];
                                  //   });
                                  //   this.setState({
                                  //     result,
                                  //     filtered,
                                  //   });
                                  //   onClick.bind(scope, {
                                  //     surveyQuestionId: SurveyQuestionId,
                                  //     response: JSON.stringify(filtered),
                                  //     level: level,
                                  //     subLevel: subLevel,
                                  //     subCatLevel: subCatLevel,
                                  //     index: index,
                                  //   })();
                                  // }}
                                >
                                  {' '}
                                  {permission.Name}
                                </Checkbox>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div class="modal-footer  border-top-0">
          <button
            type="button"
            class="btn"
            onClick={() => {
              onCancel();
            }}
          >
            Close
          </button>
          {/* <button
            type="button"
            class="btn"
            onClick={() => {
              onOk();
            }}
          >
            Save
          </button> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ get_staff_permission, update_staff_permission }) => {
  const { data: RolesPermissionsData, isFetching, error } = get_staff_permission;
  let RolesPermissions = null;
  let isUpdated = false;

  if (RolesPermissionsData && RolesPermissionsData.StaffPermission) {
    RolesPermissions = RolesPermissionsData.StaffPermission || [];
  }
  if (update_staff_permission && update_staff_permission.data) {
    isUpdated = update_staff_permission.data.success;
  }
  return {
    RolesPermissions: RolesPermissions,
    isFetching,
    isUpdated,
  };
};

export default connect(mapStateToProps)(PermissionModal);
