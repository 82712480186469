import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader2 } from '../../../../Components/Loader';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import validate from '../../validation/validate';
import { Table, Tooltip, Button } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { stringDate } from '../../../../util/date';
import CustomModal from '../../../../core/modal';
import AntdModal from '../../../../Components/CustomModal';
import SubmitButton from '../../../../Components/submitButton';
import {
  listCombos,
  getClientAuthorization,
  deleteClientAuthorization,
  listClientAuthorization,
  upsertClientAuthorization,
  resertGetClientAuthorization,
} from '../../../../store/actions';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
  Archived_Sucess_Msg,
} from '../../../../app-config';
import PersistTableSort from '../../../../component/persistTableSort';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../../core/dynamic-form';
import { ProcessCombos } from '../../../../store/comboConfig';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

class AuthorizationsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizationListView: true,
      authorizationsData: {},
      clientAuthorizationId: null,
      cTab: new URLSearchParams(props.history.location.search).get('tab'),
    };
  }

  backButton = bool => {
    this.setState({ authorizationListView: !this.state.authorizationListView });
  };

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    this.props.dispatch(listCombos({ comboTypes: 'CenterProgramType' }));
    // var result = ProcessCombos(',InsurancePriority');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
    // Removing IsVBC because data is not coming from backend.
    this.props.dispatch(
      listClientAuthorization({
        Json: JSON.stringify({
          ClientAuthorization: [
            {
              ClientId: this.props.clientId || this.getClientId(),
              // IsVBC: this.state.cTab == 'smart-contract' ? true : false,
              SmartContractId: this.state.cTab == 'smart-contract' ? this.getContractId() : null,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({
    isInserted,
    authorizationInfo,
    isAuthorizationDeactivated,
    clientAuthorizationId,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(
        listClientAuthorization({
          Json: JSON.stringify({
            ClientAuthorization: [
              {
                ClientId: this.props.clientId || this.getClientId(),
                SmartContractId: this.state.cTab == 'smart-contract' ? this.getContractId() : null,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(reset('authorizationsForm'));
    }
    if (clientAuthorizationId) {
      this.setState({ clientAuthorizationId });
    }
    if (
      isAuthorizationDeactivated &&
      isAuthorizationDeactivated != this.props.isAuthorizationDeactivated
    ) {
      this.deleteToggle(Archived_Sucess_Msg);
      this.props.dispatch(
        listClientAuthorization({
          Json: JSON.stringify({
            ClientAuthorization: [
              {
                ClientId: this.props.clientId || this.getClientId(),
                SmartContractId: this.state.cTab == 'smart-contract' ? this.getContractId() : null,
              },
            ],
          }),
        }),
      );
    }
    if (authorizationInfo && authorizationInfo.ClientId != this.props.authorizationInfo.ClientId) {
      let data = {
        authNumber: authorizationInfo.AuthNumber,
        authStartDate: authorizationInfo.AuthStartDate,
        authEndDate: authorizationInfo.AuthEndDate,
        authReviewDate: authorizationInfo.AuthReviewDate,
        payerName: authorizationInfo.PayerName,
        numberOfVisits: authorizationInfo.NumberOfVisits,
        procedureCode: parseInt(authorizationInfo.ProcedureCode),
        authNote: authorizationInfo.AuthNote,
        visitApproved: authorizationInfo.VisitApproved,
        visitRemaining: authorizationInfo.VisitRemaining,
        authLookupNumber: authorizationInfo.AuthLookupNumber,
        authLookupStartDate: authorizationInfo.AuthLookupStartDate,
        authLookupEndDate: authorizationInfo.AuthLookupEndDate,
        authLookupVisitApproved: authorizationInfo.AuthLookupVisitApproved,
        authLookupVisitRemaining: authorizationInfo.AuthLookupVisitRemaining,
        authLookupProcedureCode: authorizationInfo.AuthLookupProcedureCode,
        UpdatedBy: authorizationInfo.UpdatedBy,
        IsArchived: authorizationInfo.IsArchived,
        DateInserted: authorizationInfo.DateInserted,
        ClientId: authorizationInfo.ClientId,
        ClientAuthorizationId: authorizationInfo.ClientAuthorizationId,
      };
      this.setState({ authorizationsData: data });
    }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  deleteToggle = header => {
    this.setState(prevState => ({
      isDeleted: !prevState.isDeleted,
      header,
    }));
  };

  getContractId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var smartContractId = param.get('smartContractId');
    if (smartContractId) {
      smartContractId = parseInt(smartContractId);
    } else {
      smartContractId = null;
    }
    return smartContractId;
  };

  onSubmit(values) {
    const { authorizationsData } = this.state;
    const {
      ClientId,
      authNumber,
      authStartDate,
      authEndDate,
      numberOfVisits,
      procedureCode,
      authNote,
      visitApproved,
      visitRemaining,
      authLookupNumber,
      authLookupStartDate,
      authLookupEndDate,
      authLookupVisitApproved,
      authLookupVisitRemaining,
      authLookupProcedureCode,
      authReviewDate,
      payerName,
    } = values;
    let patientId = null;
    let paramClientId = this.props.clientId;
    if (paramClientId && paramClientId > 0) {
      patientId = paramClientId;
    }
    const data = {
      ClientAuthorization: [
        {
          ClientAuthorizationId: authorizationsData.ClientAuthorizationId || null,
          ClientId: authorizationsData.ClientId || patientId,
          AuthNumber: authNumber,
          AuthStartDate: stringDate(authStartDate),
          AuthEndDate: stringDate(authEndDate),
          NumberOfVisits: numberOfVisits,
          ProcedureCode: procedureCode,
          AuthNote: authNote,
          VisitApproved: visitApproved,
          VisitRemaining: visitRemaining,
          AuthLookupNumber: authLookupNumber,
          AuthLookupStartDate: stringDate(authLookupStartDate),
          AuthLookupEndDate: stringDate(authLookupEndDate),
          AuthLookupVisitApproved: authLookupVisitApproved,
          AuthLookupVisitRemaining: authLookupVisitRemaining,
          AuthLookupProcedureCode: authLookupProcedureCode,
          AuthReviewDate: stringDate(authReviewDate),
          PayerName: payerName,
          SmartContractId: this.state.cTab == 'smart-contract' ? this.getContractId() : null,
        },
      ],
    };
    let AuthorizationData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(upsertClientAuthorization(AuthorizationData));
  }

  render() {
    const { authorizationListView } = this.state;
    const tempColumns = [
      // {
      //   title: 'Diagnosis Code',
      //   dataIndex: 'DiagnosisCode',
      //   key: 'DiagnosisCode',
      //   sorter: (a, b) => isComparer(a.DiagnosisCode, b.DiagnosisCode),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Authorization Number',
        dataIndex: 'AuthNumber',
        key: 'AuthNumber',
        sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="text-right">
              <span className="">{text}</span>
            </div>
          );
        },
      },
      {
        title: 'Procedure Code',
        dataIndex: 'ProcedureCode',
        key: 'ProcedureCode',
        sorter: (a, b) => isComparer(a.ProcedureCode, b.ProcedureCode),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => {
        //   return (
        //     <div className="">
        //       <span className="">{text ? 'Yes' : 'No'}</span>
        //     </div>
        //   );
        // },
      },
      {
        title: 'Start Date',
        dataIndex: 'AuthStartDate',
        key: 'AuthStartDate',
        sorter: (a, b) => isDateComparer(a.AuthStartDateForSort, b.AuthStartDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date',
        dataIndex: 'AuthEndDate',
        key: 'AuthEndDate',
        sorter: (a, b) => isDateComparer(a.AuthEndDateForSort, b.AuthEndDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Review Date',
        dataIndex: 'AuthReviewDate',
        key: 'AuthReviewDate',
        sorter: (a, b) => isDateComparer(a.AuthReviewDateForSort, b.AuthReviewDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Visits Approved',
        dataIndex: 'VisitApproved',
        key: 'VisitApproved',
        sorter: (a, b) => isNumericComparer(a.VisitApproved, b.VisitApproved),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="text-right">
              <span className="">{text}</span>
            </div>
          );
        },
      },
      {
        title: 'Visits Remaining',
        dataIndex: 'VisitRemaining',
        key: 'VisitRemaining',
        sorter: (a, b) => isNumericComparer(a.VisitRemaining, b.VisitRemaining),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="text-right">
              <span className="">{text}</span>
            </div>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        sorter: (a, b) => isComparer(a.Status, b.Status),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Payer Name',
        dataIndex: 'PayerName',
        key: 'PayerName',
        sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Notes',
        dataIndex: 'AuthNote',
        key: 'AuthNote',
        sorter: (a, b) => isComparer(a.AuthNote, b.AuthNote),
        sortDirections: ['descend', 'ascend'],
      },

      // {
      //   title: 'AuthLookupNumber',
      //   dataIndex: 'AuthLookupNumber',
      //   key: 'AuthLookupNumber',
      //   sorter: (a, b) => isComparer(a.AuthLookupNumber, b.AuthLookupNumber),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'AuthLookupStartDate',
      //   dataIndex: 'AuthLookupStartDate',
      //   key: 'AuthLookupStartDate',
      //   sorter: (a, b) => isDateComparer(a.AuthLookupStartDate, b.AuthLookupStartDate),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'AuthLookupEndDate',
      //   dataIndex: 'AuthLookupEndDate',
      //   key: 'AuthLookupEndDate',
      //   sorter: (a, b) => isDateComparer(a.AuthLookupEndDate, b.AuthLookupEndDate),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'AuthLookupVisitApproved',
      //   dataIndex: 'AuthLookupVisitApproved',
      //   key: 'AuthLookupVisitApproved',
      //   sorter: (a, b) => isComparer(a.AuthLookupVisitApproved, b.AuthLookupVisitApproved),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'AuthLookupVisitRemaining',
      //   dataIndex: 'AuthLookupVisitRemaining',
      //   key: 'AuthLookupVisitRemaining',
      //   sorter: (a, b) => isComparer(a.AuthLookupVisitRemaining, b.AuthLookupVisitRemaining),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'AuthLookupProcedureCode',
      //   dataIndex: 'AuthLookupProcedureCode',
      //   key: 'AuthLookupProcedureCode',
      //   sorter: (a, b) => isComparer(a.AuthLookupProcedureCode, b.AuthLookupProcedureCode),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'DateInserted',
      //   dataIndex: 'DateInserted',
      //   key: 'DateInserted',
      //   sorter: (a, b) => isDateComparer(a.DateInserted, b.DateInserted),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'UpdatedBy',
      //   dataIndex: 'UpdatedBy',
      //   key: 'UpdatedBy',
      //   sorter: (a, b) => isDateComparer(a.UpdatedBy, b.UpdatedBy),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'IsArchived',
      //   dataIndex: 'IsArchived',
      //   key: 'IsArchived',
      //   sorter: (a, b) => isDateComparer(a.IsArchived, b.IsArchived),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Action',
        render: (text, record, index) => (
          <div className="tools-grid" style={{ padding: '0px' }}>
            <>
              <div className="mr-3 align-self-center" style={{ cursor: 'pointer' }}>
                <span
                  className="start-btn"
                  onClick={event => {
                    this.setState({
                      authorizationListView: false,
                      disable: record.IsArchived,
                    });
                    this.props.dispatch(
                      getClientAuthorization({
                        Json: JSON.stringify({
                          ClientAuthorization: [
                            {
                              ClientAuthorizationId: record.ClientAuthorizationId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  {record.IsArchived
                    ? 'View'
                    : !this.props.isReadOnlyUser && !this.props.isDeActivated
                    ? 'Edit'
                    : null}
                </span>
              </div>
              {/* {!record.IsSigned && ( */}
              <div>
                {!this.props.isReadOnlyUser && !this.props.isDeActivated && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        className="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-Screen-Tool"
                      >
                        <Tooltip placement="top" title="Archive">
                          <p className="mb-0" style={{ width: '20px', height: '20px' }}>
                            <img
                              src="images/archive.svg"
                              style={{ width: '20px', height: '20px' }}
                            />
                            {/* <i class="far fa-trash-alt"></i> */}
                          </p>
                        </Tooltip>
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 className="modal-title col-12 mb-3">
                            Are you sure you want to archive this?
                          </h4>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                deleteClientAuthorization({
                                  json: JSON.stringify({
                                    ClientAuthorization: [
                                      {
                                        ClientAuthorizationId: record.ClientAuthorizationId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
              {/* )} */}
            </>
          </div>
        ),
      },
    ];
    const tempColumnsExpired = [
      {
        title: 'Authorization Number',
        dataIndex: 'AuthNumber',
        key: 'AuthNumber',
        sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="text-right">
              <span className="">{text}</span>
            </div>
          );
        },
      },
      {
        title: 'Procedure Code',
        dataIndex: 'ProcedureCode',
        key: 'ProcedureCode',
        sorter: (a, b) => isComparer(a.ProcedureCode, b.ProcedureCode),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Date',
        dataIndex: 'AuthStartDate',
        key: 'AuthStartDate',
        sorter: (a, b) => isDateComparer(a.AuthStartDate, b.AuthStartDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date',
        dataIndex: 'AuthEndDate',
        key: 'AuthEndDate',
        sorter: (a, b) => isDateComparer(a.AuthEndDate, b.AuthEndDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Visits Approved',
        dataIndex: 'VisitApproved',
        key: 'VisitApproved',
        sorter: (a, b) => isNumericComparer(a.VisitApproved, b.VisitApproved),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="text-right">
              <span className="">{text}</span>
            </div>
          );
        },
      },
      {
        title: 'Visits Remaining',
        dataIndex: 'VisitRemaining',
        key: 'VisitRemaining',
        sorter: (a, b) => isNumericComparer(a.VisitRemaining, b.VisitRemaining),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="text-right">
              <span className="">{text}</span>
            </div>
          );
        },
      },

      {
        title: 'Notes',
        dataIndex: 'AuthNote',
        key: 'AuthNote',
        sorter: (a, b) => isComparer(a.AuthNote, b.AuthNote),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div className="tools-grid" style={{ padding: '0px' }}>
            <div className="mr-5 align-self-center" style={{ cursor: 'pointer' }}>
              <span
                className="start-btn"
                onClick={event => {
                  this.setState({
                    authorizationListView: false,
                    disable: record.IsArchived,
                  });
                  this.props.dispatch(
                    getClientAuthorization({
                      Json: JSON.stringify({
                        ClientAuthorization: [
                          {
                            ClientAuthorizationId: record.ClientAuthorizationId,
                          },
                        ],
                      }),
                    }),
                  );
                }}
              >
                {record.IsArchived
                  ? 'View'
                  : !this.props.isReadOnlyUser && !this.props.isDeActivated
                  ? 'Edit'
                  : null}
              </span>
            </div>
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        <div className="main rcm-tab">
          {authorizationListView ? (
            <div className="container-fluid">
              <div className="row py-4">
                <div className="col-6">
                  <h4 className="">Authorization List</h4>
                </div>
                <div className="col-6">
                  {!this.props.isReadOnlyUser && !this.props.isDeActivated && (
                    <div className="text-right">
                      <input
                        type="button"
                        className="btn btn-eForm-Next mx-2"
                        value="Add New Authorization"
                        onClick={event => {
                          this.props.dispatch(resertGetClientAuthorization({}));
                          this.setState(
                            {
                              authorizationListView: false,
                              disable: false,
                              desc: {},
                              authorizationsData: {},
                              clientAuthorizationId: null,
                            },
                            // () => {
                            //   this.props.dispatch(reset('authorizationsForm'));
                            // },
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12">
                  {/* <h6 className="">Authorization List</h6> */}
                  <div className="rcm-subtab-table-block" style={{ position: 'relative' }}>
                    <Loader2 loading={this.props.isFetchingList} />
                    <PersistTableSort
                      name="AuthorizationList"
                      // loading={this.props.isFetchingList}
                      columns={tempColumns}
                      dataSource={this.props.List}
                      size={'middle'}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div className="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a className="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a className="paginate_button next">Next</a>
                                ) : (
                                  <a className="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                      style={{ padding: '20px' }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h6 className="">Expired Authorization</h6>
                  <div className="rcm-subtab-table-block" style={{ position: 'relative' }}>
                    <Loader2 loading={this.props.isFetchingList} />
                    <PersistTableSort
                      name="AuthorizationListExpired"
                      // loading={this.props.isFetchingList}
                      columns={tempColumnsExpired}
                      dataSource={this.props.ListExpired}
                      size={'middle'}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                      style={{ padding: '20px' }}
                    />
                  </div>
                </div>
                <div className="col-12 text-right mt-4">
                  <div className="">
                    <Button
                      type="primary"
                      onClick={() => {
                        this.props.goToPreviuos();
                      }}
                      className={`btn btn-outline-primary`}
                      style={{ height: '42px' }}
                    >
                      Previous
                    </Button>{' '}
                    &nbsp;
                    <Button
                      type="primary"
                      onClick={() => {
                        this.props.handleNext();
                      }}
                      className={`btn save-button`}
                      style={{ height: '42px' }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="assessment-main-title d-flex align-items-center justify-content-between mt-5">
                    <div
                      onClick={() => {
                        this.backButton(true);
                      }}
                      style={{ cursor: 'pointer' }}
                      className="text-left d-flex"
                    >
                      <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                    </div>
                  </div>
                  <div>
                    <ReduxAuthorizations
                      {...this.props}
                      onSubmit={this.onSubmit.bind(this)}
                      // onSaveClick={this.onSubmit.bind(this)}
                      initialValues={this.state.authorizationsData}
                      clientAuthorizationId={this.state.clientAuthorizationId}
                      onCancel={this.props.onCancel}
                      resetForm={() => {
                        this.props.dispatch(reset('authorizationsForm'));
                        this.setState({
                          authorizationsData: {},
                        });
                      }}
                      disable={this.state.disable}
                      toggle={this.toggle}
                      clientData={this.props.clientData}
                      backButton={this.backButton.bind(this)}
                      IsVBC={this.state.cTab}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // this.props.onCancel();
            this.backButton(true);
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isDeleted}
          header={this.state.header}
          yesAction={() => {
            this.deleteToggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

class AuthorizationsForm extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    var result = ProcessCombos('RCM_ServiceCode,RCM_PayerInsurance');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }

  render() {
    const {
      onSubmit,
      handleSubmit,
      dispatch,
      history,
      name,
      disable,
      Category,
      DiagnosisType,
      Ranking,
      centerProgramType,
      StatusMedAnalysis,
      Diagnosis,
      descriptionList,
      resetForm,
      DiagnosisId,
      setIsSign,
      clientData,
      ServiceCodeList,
      PayerList,
      backButton,
      clientAuthorizationId,
      IsVBC,
    } = this.props;
    var formFields = [
      // {
      //   name: 'ClientId',
      //   // isRequired: true,
      //   disabled: true,
      //   fieldName: 'Patient ID',
      // },
      {
        name: 'authNumber',
        isRequired: true,
        fieldName: 'Authorization Number',
        validate: [validate.required],
      },
      {
        name: 'authStartDate',
        fieldName: 'Authorization Start Date',
        placeholder: 'Start Date',
        componentType: COMPONENT_TYPES.ReduxDateInput,
        isRequired: true,
        label: 'Start Date*',
        displayCalenderIcon: true,
        validate: [validate.required],
      },
      {
        name: 'authEndDate',
        fieldName: 'Authorization End Date',
        placeholder: 'End Date',
        componentType: COMPONENT_TYPES.ReduxDateInput,
        isRequired: true,
        label: 'End Date*',
        displayCalenderIcon: true,
        validate: [validate.required],
      },
      {
        name: 'authReviewDate',
        fieldName: 'Review Date',
        placeholder: 'Review Date',
        componentType: COMPONENT_TYPES.ReduxDateInput,
        // isRequired: true,
        label: 'Review Date',
        displayCalenderIcon: true,
      },
      // {
      //   name: 'numberOfVisits',
      //   // isRequired: true,
      //   fieldName: 'Number Of Visits',
      // },
      {
        name: 'visitApproved',
        fieldName: 'Visits Approved',
        isRequired: true,
        validate: [validate.required],
        type: 'number',
      },
      {
        name: 'visitRemaining',
        // isRequired: true,
        fieldName: 'Visits Remaining',
        disabled: true,
      },
      {
        name: 'procedureCode',
        fieldName: 'Procedure Code',
        options: ServiceCodeList,
        // disabled: true,
      },
      {
        name: 'payerName',
        fieldName: 'Payer',
        class: `col-12 col-md-6 col-lg-4 ${IsVBC == 'smart-contract' ? 'd-block' : 'd-none'}`,
        options: PayerList,
        // disabled: true,
      },
      {
        name: 'authNote',
        fieldName: 'Notes',
        class: 'col-12 col-md-6 col-lg-8',
        componentType: COMPONENT_TYPES.ReduxTextarea,
        label: 'Authorization Notes',
        // isRequired: true,
      },
      // {
      //   name: 'authNote',
      //   fieldName: 'Notes',
      //   class: 'col-12 col-md-6 col-lg-8',
      //   type: 'textarea',
      //   style: { height: '130px' },
      // },
      // {
      //   name: 'maritalStatus',
      //   fieldName: 'Marital Status',
      //   options: MaritalStatus,
      // },
      // {
      //   name: 'practitioner',
      //   fieldName: 'Provider Name',
      //   options: Practitioner,
      //   isRequired: true,
      // },

      // {
      //   name: 'centerProgramType',
      //   fieldName: 'Program',
      //   options: CenterProgramType,
      // },
      // {
      //   name: 'programLevel',
      //   fieldName: 'Program Level',
      //   options: ProgramLevel,
      // },

      // {
      //   displayTitle: true,
      //   title: 'Auth Lookup',
      // },
      // {
      //   name: 'authLookupNumber',
      //   fieldName: 'Authorization Number',
      //   placeholder: 'Authorization Number',
      // },
      // {
      //   name: 'authLookupStartDate',
      //   fieldName: 'Start Date',
      //   placeholder: 'Start Date',
      //   componentType: COMPONENT_TYPES.ReduxDateInput,
      //   // isRequired: true,
      //   label: 'Start Date',
      // },
      // {
      //   name: 'authLookupEndDate',
      //   fieldName: 'End Date',
      //   placeholder: 'End Date',
      //   componentType: COMPONENT_TYPES.ReduxDateInput,
      //   // isRequired: true,
      //   label: 'End Date',
      // },
      // {
      //   name: 'authLookupVisitApproved',
      //   fieldName: 'Visit Approved',
      // },
      // {
      //   name: 'authLookupVisitRemaining',
      //   // isRequired: true,
      //   fieldName: 'Visits Remaining',
      // },
      // {
      //   name: 'authLookupProcedureCode',
      //   fieldName: 'Procedure Code',
      //   placeholder: 'Procedure Code (CPT)',
      //   // disabled: true,
      // },
    ];
    return (
      <div className="main authorization-page mb-5">
        <div className="container-fluid px-0">
          <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '36px 20px 20px 20px' }}>
            <div className="enrollment-form authorization-form">
              <div className="row">
                <div className="col-12">
                  <h6>{clientAuthorizationId ? 'Edit Authorization' : 'New Authorization'}</h6>
                </div>
                <DynamicForm formFields={formFields} disable={disable} />
              </div>
              <div className="row pagination-button pt-5" style={{ right: '40px' }}>
                {!disable && (
                  <div className="col-12 text-right">
                    <button
                      onClick={() => {
                        backButton(true);
                      }}
                      type="button"
                      className="btn btn-outline-primary btn-eForm-Prev mr-2"
                      disabled={disable}
                    >
                      Cancel
                    </button>
                    <SubmitButton loading={this.props.isUpsertFetching} value={'Save'} />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const ReduxAuthorizations = reduxForm({
  form: 'authorizationsForm',
  enableReinitialize: true,
  onSubmitFail: errors => {
    const firstError = Object.keys(errors)[0];
    let el =
      document.querySelector(`[name="${firstError}"]`) || document.getElementById(`${firstError}`);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  },
})(connect()(withRouter(AuthorizationsForm)));
const selector = formValueSelector('authorizationsForm');

const mapStateToPropsParent = state => {
  const {
    combos,
    get_client_authorization,
    delete_client_authorization,
    list_client_authorization,
    upsert_client_authorization,
  } = state;
  const { data: authorizationData } = get_client_authorization;
  const { data: ListData, isFetching } = list_client_authorization;
  const { isFetching: isUpsertFetching } = upsert_client_authorization;

  let isInserted = false;
  let isAuthorizationDeactivated = false;
  let clientAuthorizationId;
  let clientData = [];
  let authorizationInfo = {};
  let List = [];
  let ListExpired = [];
  let ServiceCodeList = [];
  let PayerList = [];

  if (combos.data && combos.data['RCM_ServiceCode']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'] || [];
  }
  if (combos.data && combos.data['RCM_PayerInsurance']) {
    PayerList = combos.data['RCM_PayerInsurance'] || [];
  }

  if (upsert_client_authorization.data && upsert_client_authorization.data.success) {
    isInserted = true;
  }
  if (delete_client_authorization.data && delete_client_authorization.data.success) {
    isAuthorizationDeactivated = true;
  }

  if (authorizationData && authorizationData.ClientAuthorization) {
    authorizationInfo = authorizationData.ClientAuthorization[0] || {};
    clientAuthorizationId = authorizationData.ClientAuthorization[0].ClientAuthorizationId;
  }
  if (ListData && ListData.ClientAuthorization) {
    List = ListData.ClientAuthorization;
  }
  if (ListData && ListData.ClientAuthorizationExpired) {
    ListExpired = ListData.ClientAuthorizationExpired;
  }

  return {
    isInserted,
    isFetchingList: isFetching,
    clientData,
    List,
    ListExpired,
    authorizationInfo,
    isAuthorizationDeactivated,
    ServiceCodeList,
    PayerList,
    clientAuthorizationId,
    isUpsertFetching,
  };
};

export default connect(mapStateToPropsParent)(withRouter(AuthorizationsStep));
