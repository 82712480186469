import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { Loader2 } from '../../../Components/Loader';
import renderHTML from 'react-render-html';
import { GPT3EnrollSurveys } from '../../../store/actions';

class SurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(
      GPT3EnrollSurveys({
        Json: JSON.stringify({
          StudentId: this.props.studentId,
          UserGuid: this.props.UserGuid,
        }),
      }),
    );
  }

  onRowClick = resourceId => {
    this.props.history.push('/manage/app-resource-form?appresourceId=' + resourceId);
  };

  onManageClick = announcementId => {
    this.props.history.push('/manage/staff?announcementId=' + announcementId);
  };

  columns = [
    {
      title: 'Survey Category',
      dataIndex: 'Name',
      key: 'Name',
      sorter: (a, b) => isComparer(a.Name, b.Name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Completed Date',
      dataIndex: 'CompletedDate',
      key: 'CompletedDate',
      sorter: (a, b) => isDateComparer(a.CompletedDate, b.CompletedDate),
      sortDirections: ['descend', 'ascend'],
      // render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
    },
    {
      title: 'Total Scores',
      dataIndex: 'TotalSCore',
      key: 'TotalSCore',
      sorter: (a, b) => isNumericComparer(a.TotalSCore, b.TotalSCore),
      sortDirections: ['descend', 'ascend'],
      // render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
      align: 'right',
    },

    {
      title: 'Actions',
      dataIndex: 'x',
      key: 'Actions',
      render: (text, record) => (
        <div
          className="text-center"
          onClick={() => {
            //   this.getAuthDetails(record);
            this.props.changeAugmentId(record.SurveyIds);
          }}
          style={{ cursor: 'pointer' }}
        >
          <img src={`images/gpt-ai.svg`} />
        </div>
      ),
    },
  ];

  render() {
    const { isFetching, EnrollSurveysList, isFetchingGPTSurveys } = this.props;

    return (
      <div class="row">
        <div class="col-12 mb-5">
          <div class="card border-0 ">
            <div class="row card-body">
              <div class="col-12">
                <Loader2 loading={isFetchingGPTSurveys} />
                <div className="row-hover-enabled">
                  <Table
                    columns={this.columns}
                    dataSource={EnrollSurveysList}
                    // onRow={(record, rowIndex) => {
                    //   return {
                    //     onClick: event => this.onRowClick(record.ResourceId),
                    //   };
                    // }}
                    // pagination={{
                    //   itemRender: (page, type, originalElement) => {
                    //     return (
                    //       <div class="dataTables_wrapper no-footer">
                    //         <div class="dataTables_paginate paging_simple_numbers">
                    //           {type == 'prev' ? (
                    //             <a class="paginate_button previous">Previous</a>
                    //           ) : type == 'next' ? (
                    //             <a class="paginate_button next">Next</a>
                    //           ) : (
                    //             <a class="paginate_button ">{page}</a>
                    //           )}
                    //         </div>
                    //       </div>
                    //     );
                    //   },
                    //   showSizeChanger: true,
                    //   // hideOnSinglePage: true,
                    // }}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    augmented_summary_get,
    gpt3_enroll_surveys,
    augment_with_ai,
    augment_with_ai_get,
  } = state;
  const { data: AugmentedSummaryData, isFetching } = augmented_summary_get;
  const { data: EnrollSurveysData, isFetching: isFetchingGPTSurveys } = gpt3_enroll_surveys;
  const { data: AugmentWithAIData, isFetching: isFetchingAugmentWithAI } = augment_with_ai;
  const {
    data: AugmentWithAIGetData,
    isFetching: isFetchingAugmentWithAIGet,
  } = augment_with_ai_get;

  let augmentedSummary = null;
  let isHistory = false;
  let EnrollSurveysList = null;
  let LatestSurveyIds = null;
  let isAugmentedWithAI = false;
  let AugmentedWithAI;

  if (AugmentedSummaryData && AugmentedSummaryData.Summary) {
    augmentedSummary = AugmentedSummaryData.Summary[0];
    isHistory = AugmentedSummaryData.History && AugmentedSummaryData.History.length > 0;
  }
  if (EnrollSurveysData && EnrollSurveysData.StudentId) {
    EnrollSurveysList = EnrollSurveysData.Surveys;
    LatestSurveyIds = EnrollSurveysData.SurveyIds;
  }
  if (AugmentWithAIData && AugmentWithAIData.success) {
    isAugmentedWithAI = true;
    // if (result == 'Successful') {
    //   isAugmentedWithAI = true;
    // } else {
    //   isAugmentedWithAI = false;
    // }
  }
  if (AugmentWithAIGetData && AugmentWithAIGetData.data) {
    AugmentedWithAI = AugmentWithAIGetData.data;
  }
  return {
    augmentedSummary,
    isHistory,
    isFetching,
    EnrollSurveysList,
    LatestSurveyIds,
    isFetchingGPTSurveys,
    isAugmentedWithAI,
    AugmentedWithAI,
  };
};

export default connect(mapStateToProps)(SurveyList);
