import React, { Component } from 'react';
import { Button } from 'antd';

export default class SubmitButton extends Component {
  render() {
    const { loading, value, disabled, style,className, onClick, type } = this.props;
    return (
      <Button
        loading={loading}
        htmlType={ type ? type : 'submit' }
        type="primary"
        onClick={onClick}
        className={`btn save-button ${className}`}
        disabled={disabled}
        style={style}
        // size={'large'}
      >
        {value || 'Save'}
      </Button>
    );
  }
}
export class AIActionButton extends Component {
  render() {
    const { loading, value, title, disabled, style, onClick, className } = this.props;
    return (
      <Button
        loading={loading}
        title={title}
        type="primary"
        onClick={onClick}
        disabled={disabled}
        className={className ? className : `ai-btn`}
        style={style}
      >
        {value}
      </Button>
    );
  }
}
