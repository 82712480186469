import React, { Component } from 'react';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
  RadioButtonMultilineSingleSelect,
  AlphaNumeric,
  MultiSelectOneLineType,
  Numeric,
  Binary,
  Date,
  Textarea,
  UnSupported,
} from './index';

const SURVEYTYPES = {
  RadioButtonMultilineSingleSelect: 19,
  AlphaNumeric: 20,
  Textarea: 23,
  Numeric: 24,
  MultiSelectOneLineType: 25,
  Binary: 26,
  Date: 27,
};

const DragHandle = sortableHandle(() => (
  <img src="images/dragDrop.svg" className="mr-2" style={{ position: 'relative', top: '2px', cursor: 'grab' }} />
));
const SortableItem = SortableElement(
  ({
    SurveyQuestionListItems,
    SurveyId,
    survey,
    index,
    onClick,
    setValue,
    setToggle,
    scope,
    level,
    subLevel,
    subCatLevel,
    isReadOnly,
    TabName,
    Sequence,
    sequenceNum,
    SurveyQuestionList,
    activeKey,
    onMoveTo,
    onEditAction,
    onQuestionStateChange,
    questionsListOptions,
    isAdmin,
  }) => {
    let configProps = {
      survey: survey,
      SurveyQuestionListItems: SurveyQuestionListItems,
      index: index,
      SurveyId: SurveyId,
      sequenceNum: sequenceNum,
      onClick: onClick,
      setValue: setValue,
      setToggle: setToggle,
      scope: scope,
      total: SurveyQuestionListItems.length,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      isReadOnly: isReadOnly,
      Sequence: Sequence,
      SurveyQuestionList: SurveyQuestionList,
      TabName: TabName,
      activeKey: activeKey,
      onMoveTo: onMoveTo,
      onEditAction: onEditAction,
      onQuestionStateChange: onQuestionStateChange,
      questionsListOptions: questionsListOptions,
      isAdmin: isAdmin,
    };
    if (survey.SurveyQuestionTypeId == SURVEYTYPES.RadioButtonMultilineSingleSelect) {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <RadioButtonMultilineSingleSelect {...configProps} />
        </li>
      );
    } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.AlphaNumeric) {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <AlphaNumeric {...configProps} />
        </li>
      );
    } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Numeric) {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <Numeric {...configProps} />
        </li>
      );
    } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.MultiSelectOneLineType) {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <MultiSelectOneLineType {...configProps} />
        </li>
      );
    } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Binary) {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <Binary {...configProps} />
        </li>
      );
    } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Date) {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <Date {...configProps} />
        </li>
      );
    } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Textarea) {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <Textarea {...configProps} />
        </li>
      );
    } else {
      return (
        <li className="mb-3 sortable-element-item">
          {!survey.IsArchived && !isAdmin && <DragHandle />}
          <UnSupported {...configProps} />
        </li>
      );
    }
  },
);

const SortableList = SortableContainer(
  ({
    items,
    onCheckChanged,
    SurveyQuestion,
    SurveyId,
    onClick,
    setValue,
    setToggle,
    scope,
    level,
    subLevel,
    subCatLevel,
    isReadOnly,
    TabName,
    Sequence,
    SurveyQuestionList,
    activeKey,
    onMoveTo,
    onEditAction,
    onQuestionStateChange,
    questionsListOptions,
    isAdmin,
  }) => {
    return (
      <ul
        className="mb-0"
        style={{ display: 'flex', flexDirection: 'column', padding: '0', width: '100%' }}
      >
        {items &&
          items.map((survey, index) => {
            return (
              <SortableItem
                // key={item.value}
                sequenceNum={index}
                index={index}
                onCheckChanged={onCheckChanged}
                survey={survey}
                SurveyId={SurveyId}
                onClick={onClick}
                setValue={setValue}
                setToggle={setToggle}
                scope={scope}
                total={SurveyQuestion.length}
                level={level}
                subLevel={subLevel}
                subCatLevel={subCatLevel}
                isReadOnly={isReadOnly}
                Sequence={Sequence}
                SurveyQuestionListItems={items}
                TabName={TabName}
                SurveyQuestionList={SurveyQuestionList}
                activeKey={activeKey}
                onMoveTo={onMoveTo}
                onEditAction={onEditAction}
                onQuestionStateChange={onQuestionStateChange}
                questionsListOptions={questionsListOptions}
                isAdmin={isAdmin}
              />
            );
          })}
      </ul>
    );
  },
);

class QuestionType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionList: [],
      draggableQ: [],
    };
  }

  componentDidMount() {
    this.setState({
      questionList: this.props.SurveyQuestion,
      draggableQ: this.props.Draggable,
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ draggableQ }) => {
      const newList = arrayMove(draggableQ, oldIndex, newIndex);
      return {
        draggableQ: newList,
      };
    });
    this.props.onSort([this.state.draggableQ]);
  }

  render() {
    const {
      SurveyQuestion,
      SurveyId,
      onNext,
      setValue,
      scope,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      SurveyQuestionList,
      activeKey,
      onMoveTo,
      onEditAction,
      UnDraggable,
      Draggable,
      onQuestionStateChange,
      questionsListOptions,
      isAdmin,
      setToggle,
    } = this.props;
    const { newQuestionIndex, oldQuestionIndex } = this.state;

    return (
      <div className="row question-library rcm-panel">
        {Draggable && (
          <SortableList
            useDragHandle
            useWindowAsScrollContainer
            lockAxis="y"
            lockToContainerEdges={true}
            // pressDelay={250}
            items={this.state.draggableQ}
            onSortEnd={this.onSortEnd.bind(this)}
            SurveyId={SurveyId}
            // index={index}
            onClick={onNext}
            setValue={setValue}
            setToggle={setToggle}
            scope={scope}
            total={SurveyQuestion.length}
            level={level}
            subLevel={subLevel}
            subCatLevel={subCatLevel}
            isReadOnly={isReadOnly}
            Sequence={Sequence}
            TabName={TabName}
            SurveyQuestion={SurveyQuestion}
            SurveyQuestionList={SurveyQuestionList}
            newQuestionIndex={newQuestionIndex}
            activeKey={activeKey}
            onMoveTo={onMoveTo}
            onEditAction={onEditAction}
            onQuestionStateChange={onQuestionStateChange}
            questionsListOptions={questionsListOptions}
            isAdmin={isAdmin}
          />
        )}
        {UnDraggable &&
          UnDraggable.map((survey, index) => {
            let configProps = {
              survey: survey,
              SurveyQuestionListItems: survey,
              index: index,
              SurveyId: SurveyId,
              sequenceNum: index,
              onClick: onNext,
              setValue: setValue,
              setToggle: setToggle,
              scope: scope,
              total: SurveyQuestion.length,
              level: level,
              subLevel: subLevel,
              subCatLevel: subCatLevel,
              isReadOnly: isReadOnly,
              Sequence: Sequence,
              SurveyQuestionList: SurveyQuestionList,
              TabName: TabName,
              activeKey: activeKey,
              onMoveTo: onMoveTo,
              onEditAction: onEditAction,
              onQuestionStateChange: onQuestionStateChange,
              questionsListOptions: questionsListOptions,
              isAdmin: isAdmin,
            };
            if (survey.SurveyQuestionTypeId == SURVEYTYPES.RadioButtonMultilineSingleSelect) {
              return <RadioButtonMultilineSingleSelect {...configProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.AlphaNumeric) {
              return <AlphaNumeric {...configProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Numeric) {
              return <Numeric {...configProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.MultiSelectOneLineType) {
              return <MultiSelectOneLineType {...configProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Binary) {
              return <Binary {...configProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Date) {
              return <Date {...configProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Textarea) {
              return <Textarea {...configProps} />;
            } else {
              return <UnSupported {...configProps} />;
            }
          })}
      </div>
    );
  }
}
export default QuestionType;
