import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

export default props => {
  const { survey, onClick, scope, index, total, level, subLevel, subCatLevel, isReadOnly } = props;
  const {
    Question,
    QuestionNumber,
    DoesNotApplyText,
    DoesNotApplyValue,
    Option1Value,
    Option1Text,
    Option2Value,
    Option2Text,
    Option3Value,
    Option3Text,
    Option4Value,
    Option4Text,
    Option5Value,
    Option5Text,
    Option6Value,
    Option6Text,
    Option7Value,
    Option7Text,
    Option8Value,
    Option8Text,
    Option9Value,
    Option9Text,
    Option10Value,
    Option10Text,
    Option11Value,
    Option11Text,
    Response,
    SurveyQuestionId,
  } = survey;
  const textArray = [
      Option1Text,
      Option2Text,
      Option3Text,
      Option4Text,
      Option5Text,
      Option6Text,
      Option7Text,
      Option8Text,
      Option9Text,
      Option10Text,
      Option11Text,
    ],
    valueArray = [
      Option1Value,
      Option2Value,
      Option3Value,
      Option4Value,
      Option5Value,
      Option6Value,
      Option7Value,
      Option8Value,
      Option9Value,
      Option10Value,
      Option11Value,
    ];
  return (
    <div className="type-border col-12">
      <div>
        <SurveyQuestion survey={survey} />
        <ul class="answer-options d-flex flex-wrap pt-2">
          {textArray.map((item, qIndex) => {
            return (
              item && (
                <li className="mr-2" style={{ display: 'inherit' }}>
                  <span
                    className={Response == valueArray[qIndex] ? 'selected' : 'normal'}
                    // className={'selected'}
                    style={{ padding: '10px 20px' }}
                  >
                    {' '}
                    <input
                      disabled={isReadOnly}
                      type="radio"
                      name="current-situation"
                      value={item}
                      onClick={onClick.bind(scope, {
                        surveyQuestionId: SurveyQuestionId,
                        response:
                          Response == valueArray[qIndex] ? DoesNotApplyValue : valueArray[qIndex],
                        level: level,
                        subLevel: subLevel,
                        subCatLevel: subCatLevel,
                        index: index,
                      })}
                    />
                    {item && item.length > 0 && renderHTML(item)}
                  </span>
                </li>
              )
            );
          })}
        </ul>
        <DoesNotApplyOption surveyProps={props} />
      </div>
    </div>
  );
};
