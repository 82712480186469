import React, { Component } from 'react';
import { Table, Drawer, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import ContractDetailsDrawer from './components/contractDetailsDrawer';
import FilterModal from '../timeLineFIlters';
import TimelineComponent from '../../timelineComponent';
import AntdModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import AuthorizationTable from './components/nestedAuthorizationTable';
import AuthorizationDetailDrawer from './components/authorizationDetailDrawer';
// import getParam from '../../../util/getParam';
import {
  payerPortalAuthorizationStatusUpsert,
  payerPortalAuthorizationGet,
  clientBannerGet,
} from '../../../store/actions';

class ClientAuthorizationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetailDrawerOpen: false,
      isTimelineModalOpen: false,
      isTimelineDetailsDrawerOpen: false,
      isModelOpen: false,
      smartContractId: null,
      DetailDrawer: null,
      authDetails: null,
      drawerTitle: '',
    };
  }

  authorizationStatusUpsert = (ClientAuthorizationId, IsApproved) => {
    this.props.dispatch(
      payerPortalAuthorizationStatusUpsert({
        JSON: JSON.stringify({
          Authorization: [
            {
              ClientAuthorizationId: ClientAuthorizationId,
              IsApproved: IsApproved,
            },
          ],
        }),
      }),
    );
  };

  getClientId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  };

  openAndCloseModal = () => {
    this.setState({ isDetailDrawerOpen: !this.state.isDetailDrawerOpen });
  };

  getAuthDetails = (authDetails, SmartContractId) => {
    this.setState({
      isDetailDrawerOpen: true,
      DetailDrawer: AuthorizationDetailDrawer,
      authDetails,
      drawerTitle: 'View Authorization',
      smartContractId: SmartContractId,
    });
  };

  // componentDidMount() {
  //   this.props.dispatch(
  //     payerPortalAuthorizationGet({
  //       JSON: JSON.stringify({
  //         Authorizations: [
  //           {
  //             ClientId: this.getClientId(),
  //           },
  //         ],
  //       }),
  //     }),
  //   );
  // }

  componentWillReceiveProps({ isSuccess, isReqSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState({
        isDetailDrawerOpen: false,
      });
      this.props.dispatch(
        payerPortalAuthorizationGet({
          JSON: JSON.stringify({
            Authorizations: [
              {
                ClientId: this.getClientId(),
              },
            ],
          }),
        }),
      );
      this.props.dispatch(
        clientBannerGet({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.getClientId(),
              },
            ],
          }),
        }),
      );
    }
    // if (isReqSuccess && isReqSuccess != this.props.isReqSuccess) {
    //   this.setState({
    //     isDetailDrawerOpen: false,
    //   });
    // }
  }
  render() {
    const {
      smartContractList,
      contractDetailsOverView,
      smartContractDetailArray,
      smartContractMilestones,
    } = this.props;
    const {
      DetailDrawer,
      drawerTitle,
      isDetailDrawerOpen,
      smartContractId,
      authDetails,
    } = this.state;

    const columns = [
      {
        title: 'Contract Name',
        dataIndex: 'ContractName',
        key: 'ContractName',
        sorter: (a, b) => isComparer(a.ContractName, b.ContractName),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <p
            className="client-info-text mb-0"
            style={{
              cursor: 'pointer',
              color: '#009EF7',
              textDecoration: 'underline',
              fontWeight: '700',
            }}
            onClick={e => {
              this.setState({
                isDetailDrawerOpen: true,
                smartContractId: record.SmartContractId,
                DetailDrawer: ContractDetailsDrawer,
                drawerTitle: 'Contract Details',
              });
            }}
          >
            {text}
          </p>
        ),
      },
      {
        title: 'Total No. of Authorization',
        dataIndex: 'AuthCount',
        key: 'AuthCount',
        sorter: (a, b) => isNumericComparer(a.AuthCount, b.AuthCount),
        sortDirections: ['descend', 'ascend'],
        align: 'right',
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        sorter: (a, b) => isComparer(a.Status, b.Status),
        sortDirections: ['descend', 'ascend'],
      },
    ];

    return (
      <div className="">
        <div class="row">
          <div class="col-12 mb-5">
            <div class="border-0 ">
              <div class="row card-body">
                <div class="col-12">
                  <div className="row-hover-enabled">
                    <Table
                      columns={columns}
                      expandable={{
                        //   expandedRowRender: BundleServiceCategory,
                        expandedRowRender: record => (
                          <AuthorizationTable
                            authRecord={record}
                            getAuthDetails={this.getAuthDetails}
                          />
                        ),
                        expandIcon: ({ expanded, onExpand, record }) =>
                          expanded ? (
                            <img
                              src="images/icons/expand_grid.svg"
                              onClick={e => {
                                onExpand(record, e);
                                e.stopPropagation();
                              }}
                            />
                          ) : (
                            <img
                              src="images/icons/collapse_grid.svg"
                              onClick={e => {
                                onExpand(record, e);
                                e.stopPropagation();
                              }}
                            />
                          ),
                      }}
                      dataSource={smartContractList}
                      size={'middle'}
                      pagination={false}
                      className="nested-tables"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {DetailDrawer && (
          <Drawer
            className="contract-drawer"
            // title={drawerTitle}
            title={<span className="contract-drawer-title">{drawerTitle}</span>}
            open={isDetailDrawerOpen}
            onClose={() => {
              this.setState({
                isDetailDrawerOpen: false,
                DetailDrawer: null,
                authDetails: null,
              });
            }}
            width={900}
            bodyStyle={{ paddingBottom: 80, backgroundColor: 'white' }}
            destroyOnClose={true}
            placement="right"
            // className="print-drawer"
          >
            <DetailDrawer
              smartContractDetailArray={smartContractDetailArray}
              smartContractMilestones={smartContractMilestones}
              contractDetailsOverView={contractDetailsOverView}
              smartContractId={smartContractId}
              authDetails={authDetails}
              dispatch={this.props.dispatch}
              ClientId={this.getClientId()}
              openAndCloseModal={this.openAndCloseModal}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

// export default ClientAuthorizationsList;

const mapStateToProps = ({
  payer_portal_authorization_status_upsert,
  payer_portal_request_insert,
  payer_portal_authorization_get,
}) => {
  const { data, isFetching } = payer_portal_authorization_status_upsert;
  let { data: requestData } = payer_portal_request_insert;
  const { data: authorizationData, error } = payer_portal_authorization_get;

  let isSuccess = false;
  let isReqSuccess = false;
  if (data && data.success) {
    isSuccess = data.success;
  }
  if (requestData && requestData.success) {
    isReqSuccess = requestData.success;
  }
  return { isSuccess, isReqSuccess };
};

export default connect(mapStateToProps)(withRouter(ClientAuthorizationsList));
