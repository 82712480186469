import React, { Component } from 'react';
import { getSurveys } from '../store/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Switch } from 'antd';
import Loader from '../Components/Loader';
import ListRenderer from '../Components/listRenderer';
import { studentBanner } from '../store/actions';
import PageTitle from '../Components/PageTitle';

const activeKey = 0;

class Surveyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      filteredSurveys: [],
      searchBoxValue: '',
      toggleActive: false,
      studentInfo: {},
      FormsSurvey: [],
      AssessmentSurvey: [],
    };
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId') || null;
  }

  onToggle = () => {
    let active = !this.state.toggleActive;
    var showOverDue = active == false ? 0 : 1;
    var studentId = this.getStudentId();
    if (studentId) {
      this.props.dispatch(getSurveys({ showOnlyOverdue: showOverDue, studentId: studentId }));
    } else {
      this.props.dispatch(getSurveys({ showOnlyOverdue: showOverDue }));
    }

    this.state.toggleActive = active;
  };

  componentDidMount() {
    this.getSurveys();
  }

  getSurveys = () => {
    var studentId = this.getStudentId();
    if (studentId) {
      let data = {
        Json: JSON.stringify({
          Student: [
            {
              StudentId: studentId,
            },
          ],
        }),
      };
      this.props.dispatch(studentBanner(data));
      if (this.getFilterParam()) {
        this.props.dispatch(getSurveys({ showOnlyOverdue: 1, studentId: studentId }));
        this.setState({ toggleActive: true });
      } else {
        this.props.dispatch(getSurveys({ showOnlyOverdue: 0, studentId: studentId }));
      }
    } else {
      if (this.getFilterParam()) {
        this.props.dispatch(getSurveys({ showOnlyOverdue: 1 }));
        this.setState({ toggleActive: true });
      } else {
        this.props.dispatch(getSurveys({ showOnlyOverdue: 0 }));
      }
    }
  };

  componentWillReceiveProps({ surveys, location, studentDetails }) {
    const { searchBoxValue } = this.state;
    if (this.getStudentId()) {
      this.setState({
        studentInfo: studentDetails,
      });
    } else {
      this.setState({
        studentInfo: null,
      });
    }

    if (surveys.length > 0) {
      if (searchBoxValue.length > 0) {
        var str = searchBoxValue;
        var filteredSurveys = [];
        for (let survey of surveys) {
          if (
            (survey.FirstName && survey.FirstName.toLowerCase().indexOf(str) >= 0) ||
            (survey.LastName && survey.LastName.toLowerCase().indexOf(str) >= 0)
          ) {
            filteredSurveys.push(survey);
          }
        }

        this.setState({
          filteredSurveys: filteredSurveys,
          searchBoxValue: str,
          surveys: surveys,
          // studentInfo: studentDetails,
        });
      } else {
        this.setState({
          filteredSurveys: surveys,
          surveys: surveys,
          // studentInfo: studentDetails,
        });
      }
    }
    if (surveys.length > 0 && JSON.stringify(surveys) != JSON.stringify(this.props.surveys)) {
      let FormsSurvey = [];
      let AssessmentSurvey = [];
      surveys.length > 0 &&
        surveys.map(item => {
          if (item.IsForm) {
            FormsSurvey.push(item);
          } else {
            AssessmentSurvey.push(item);
          }
        });
      this.setState({
        FormsSurvey,
        AssessmentSurvey,
      });
    }
    if (location !== this.props.location) this.getSurveys();
  }
  onSearchBoxChange(event) {
    var str = event.target.value.toLowerCase();
    // var filteredSurveys = [];
    // for (var survey of this.state.surveys) {
    //   if (
    //     survey.FirstName.toLowerCase().indexOf(str) >= 0 ||
    //     survey.LastName.toLowerCase().indexOf(str) >= 0
    //   ) {
    //     filteredSurveys.push(survey);
    //   }
    // }
    let filteredSurveys =
      this.state.surveys &&
      this.state.surveys.filter(item => {
        if (
          (item.FirstName && item.FirstName.toLowerCase().indexOf(str) >= 0) ||
          (item.LastName && item.LastName.toLowerCase().indexOf(str) >= 0)
        ) {
          return item;
        }
      });
    this.setState({
      filteredSurveys: filteredSurveys,
      searchBoxValue: str,
    });
  }

  getFilterParam() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('Filter') || false;
  }
  getIgnoreUpdatingPin() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('ignoreUpdatingPin') == 'true' ? true : false;
  }

  onSelectSurvey = (surveyId, studentId, IsForm) => {
    var searchStr = 'surveyId=' + surveyId + '&form=' + IsForm + '&studentId=' + studentId;
    if (this.getIgnoreUpdatingPin()) {
      this.props.history.push({
        pathname: 'survey',
        search: searchStr,
      });
    } else {
      var me = this;
      const isWebCam = localStorage.getItem('isWebCam') === '0' ? false : true;
      if (isWebCam) {
        axios.post('Student/LoadStudentDefaultValues', { studentId: studentId }).then(response => {
          if (response.data.data.isNewImageRequired == 1) {
            me.props.history.push({
              pathname: '/camera',
              search:
                'surveyId=' +
                surveyId +
                '&form=' +
                IsForm +
                '&studentId=' +
                studentId +
                '&ignoreUpdatingPin=' +
                !response.data.data.IsPinValidate,
            });
          } else if (response.data.data.isFacialAuthRequired) {
            me.props.history.push({
              pathname: '/authentication',
              search:
                'surveyId=' +
                surveyId +
                '&form=' +
                IsForm +
                '&studentId=' +
                studentId +
                '&ignoreUpdatingPin=' +
                !response.data.data.IsPinValidate,
            });
          } else {
            me.props.history.push({
              pathname: '/validate',
              search:
                'surveyId=' +
                surveyId +
                '&form=' +
                IsForm +
                '&studentId=' +
                studentId +
                '&ignoreUpdatingPin=' +
                !response.data.data.IsPinValidate,
            });
          }
        });
      } else {
        me.props.history.push({
          pathname: '/validate',
          search: 'surveyId=' + surveyId + '&form=' + IsForm + '&studentId=' + studentId,
        });
      }
    }
  };

  getMulti = array => {
    for (let item of array) {
      item.count = 0;
      for (let checkItem of array) {
        if (item.StudentId == checkItem.StudentId) {
          item.count += 1;
        }
      }
    }
    if (!this.getStudentId()) {
      let indexArray = [...new Set(array.map(item => item.StudentId))],
        newArray = [];
      for (let item of indexArray) {
        newArray.push(array.find(aItem => aItem.StudentId == item));
      }
      return newArray;
    } else return array;
  };
  GetAssessments = () => {
    const { AssessmentSurvey } = this.state;
    return (
      <div className="row">
        {AssessmentSurvey &&
          AssessmentSurvey.map(surveyItem => {
            const { SurveyId, StudentId, IsForm, SurveyName, DueDate, DueStatus } = surveyItem;
            if (!IsForm) {
              return (
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className="survey-block surveys-card"
                    onClick={this.onSelectSurvey.bind(null, SurveyId, StudentId, IsForm)}
                  >
                    <div className="survey-name">
                      <span>{SurveyName}</span>
                    </div>
                    <span className="br"></span>
                    <div class="surveys-date-box ">
                      <span class="surveys-status">Due:</span>
                      <span class="surveys-date">
                        <i class="far fa-calendar-alt form-control-icon" />
                        {DueDate}
                      </span>
                      <span
                        class={
                          DueStatus == 1
                            ? 'alert-danger-dot'
                            : DueStatus == 2
                            ? 'alert-orange-dot'
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    );
  };
  GetForms = () => {
    const { FormsSurvey } = this.state;
    return (
      <div className="row">
        {FormsSurvey &&
          FormsSurvey.map(surveyItem => {
            const { SurveyId, StudentId, IsForm, SurveyName, DueDate, DueStatus } = surveyItem;
            if (IsForm) {
              debugger;

              return (
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div
                    className="survey-block surveys-card"
                    onClick={this.onSelectSurvey.bind(null, SurveyId, StudentId, IsForm)}
                  >
                    <div className="survey-name">
                      <span>{SurveyName}</span>
                    </div>
                    <span className="br"></span>
                    <div class="surveys-date-box ">
                      <span class="surveys-status">Due:</span>
                      <span class="surveys-date">
                        <i class="far fa-calendar-alt form-control-icon" />
                        {DueDate}
                      </span>
                      <span
                        class={
                          DueStatus == 1
                            ? 'alert-danger-dot'
                            : DueStatus == 2
                            ? 'alert-orange-dot'
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    );
  };
  render() {
    const {
      filteredSurveys,
      searchBoxValue,
      studentInfo,
      FormsSurvey,
      AssessmentSurvey,
    } = this.state;
    const { isFetching, surveys, history } = this.props;
    const list = [
      { Name: 'Assessments', ExtraComponent: () => this.GetAssessments() },
      { Name: 'Forms', ExtraComponent: () => this.GetForms() },
    ];
    let imageUrl = 'images/blank-profile.png';
    return (
      <div>
        <PageTitle TitleText="Students" />
        <Loader loading={isFetching} />
        <div class="main students-page mb-5" style={{ marginTop: '-22px' }}>
          <div class="container-fluid">
            <div class="row top-table-search mt-5 mb-3 align-items-center">
              <div class="col-12">
                <a
                  class="back-link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (this.getStudentId()) {
                      this.props.history.push('/surveyer');
                    } else {
                      this.props.history.push('dashboardNew/inHouse');
                    }
                  }}
                >
                  {this.getStudentId() ? (
                    <span className="d-flex">
                      <img src="images/back-arrow.svg" /> Back
                    </span>
                  ) : (
                    <span className="d-flex">
                      <img src="images/back-arrow.svg" /> Back to Dashboard
                    </span>
                  )}
                </a>
                <h3 class="page-title mb-3 mb-md-0 text-uppercase">Surveys</h3>
              </div>

              <div class="col-12 col-md-4 col-lg-4-">
                {studentInfo && (
                  <h5 class="font-weight-semibold">
                    <div className="d-flex align-items-center">
                      <div class="students-profile mr-2">
                        <div
                          class="images-placeholder2"
                          // style={{
                          //   backgroundImage: 'url(&quot;images/surveys-img1.jpg&quot;)',
                          // }}
                        >
                          <img
                            src={studentInfo.StudentImageUrl || imageUrl}
                            class="students-profile-img images-placeholder2"
                            alt=""
                            width="84"
                          />
                        </div>
                      </div>
                      <div style={{ marginLeft: '5px' }}>
                        <div class="d-flex ml-1 mb-2">
                          <span className="d-block mr-1">{studentInfo.DisplayName}</span>
                          {/* <span className="d-block">
                            ({studentInfo.StudentId}-{studentInfo.CenterId})
                          </span> */}
                        </div>
                        <div className="d-inline enrollment-page top-heading-row">
                          <button
                            onClick={() => {
                              this.props.history.push(`/studentProfile/${studentInfo.StudentId}`);
                            }}
                            class="btn btn-outline-primary py-1"
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </h5>
                )}
              </div>

              <div class="col-12 col-md-8 col-lg-8 text-md-right">
                <div class="custom-control custom-switch d-inline-flex mb-2 mb-md-0 mr-md-3">
                  <input type="checkbox" class="custom-control-input" id="show-overdue-survey" />
                  <Switch
                    style={{ display: 'block', marginTop: '2px', blockSize: 'small' }}
                    checked={this.state.toggleActive}
                    onChange={this.onToggle}
                  />
                  <label for="show-overdue-survey" className="show-overdue-survey">
                    Show Overdue
                  </label>
                </div>
                {!this.getStudentId() && (
                  <>
                    <div class="search-box d-inline-flex w-100">
                      <div class="form-group has-search w-100">
                        <input
                          type="text"
                          class="form-control shadow-sm"
                          placeholder="Search"
                          onChange={this.onSearchBoxChange.bind(this)}
                          value={searchBoxValue}
                        />
                        <button class="btn search-icon " type="button">
                          {' '}
                          <i class="fa fa-search" />{' '}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {this.getStudentId() ? (
              <>
                <div className="row">
                  <div class="col-12 mb-5">
                    <div className="vertical-tabs enrollment-form survey-section">
                      <ListRenderer
                        list={list}
                        activeKey={activeKey}
                        tabPosition="left"
                        destroyInactiveTabPane={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="row">
                  {filteredSurveys.length === 0 ? (
                    <div
                      style={{
                        height: '400px',
                        textAlign: 'center',
                        width: '100%',
                        fontSize: '27px',
                        paddingTop: '85px',
                      }}
                    >
                      There are no pending surveys
                    </div>
                  ) : (
                    this.getMulti(filteredSurveys).map((survey, index) => {
                      const {
                        AssignedByStaffId,
                        AssignedDate,
                        DueDate,
                        DueStatus,
                        FirstName,
                        LastName,
                        ProfileImgAttachmentId,
                        StartedDate,
                        StudentId,
                        StudentImageUrl,
                        SurveyId,
                        SurveyName,
                        SurveyQuestionCategoryId,
                        count,
                        IsForm,
                      } = survey;
                      return (
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-auto  ">
                          {!this.getStudentId() && (
                            <em
                              class="active-badge badge"
                              style={{
                                position: 'absolute',
                                left: '83%',
                                zIndex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {count}
                            </em>
                          )}
                          <div class="surveys-card" style={{ borderRadius: '0px 0px 11px 11px' }}>
                            <div
                              class="images-placeholder"
                              style={{
                                backgroundImage: `url(${StudentImageUrl})`,
                                // borderRadius: '11px 11px 0px 0px',
                              }}
                            >
                              <img
                                class="card-img-top img-background"
                                src={StudentImageUrl}
                                alt=""
                              />
                              <div class="surveys-hover">
                                <div class="group w-100">
                                  <Link
                                    to={{ pathname: '/studentProfile/' + StudentId }}
                                    title="View Profile"
                                    class="btn btn-outline-primary"
                                  >
                                    View Profile
                                  </Link>
                                  {count > 1 && !this.getStudentId() ? (
                                    <Link to={`/surveyer/?studentId=${StudentId}`} class="btn">
                                      <span style={{ color: '#FFFFFF' }}> Go To Surveys</span>
                                    </Link>
                                  ) : (
                                    <a
                                      onClick={this.onSelectSurvey.bind(
                                        null,
                                        SurveyId,
                                        StudentId,
                                        IsForm,
                                      )}
                                      class="btn"
                                    >
                                      <span style={{ color: '#FFFFFF' }}> Launch Survey</span>
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="surveys-card-body">
                              <div class="surveys-info">
                                <h5
                                  class="card-title mb-0 h5"
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {FirstName} {LastName}
                                </h5>
                                {
                                  <span class="department-name">
                                    {count > 1 && !this.getStudentId()
                                      ? 'Multiple Surveys'
                                      : SurveyName}
                                  </span>
                                }
                              </div>
                              <div class="surveys-date-box">
                                <span class="surveys-status">Due:</span>
                                <span class="surveys-date">
                                  <i class="far fa-calendar-alt form-control-icon" />
                                  {DueDate}
                                </span>
                                <span
                                  class={
                                    DueStatus == '1'
                                      ? 'alert-danger-dot'
                                      : DueStatus == '2'
                                      ? 'alert-orange-dot'
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ surveys, student_banner }) => {
  var { data, isFetching, error } = surveys;
  let { data: clientBanner } = student_banner;
  let studentDetails;
  if (clientBanner) {
    studentDetails = clientBanner;
  }
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }
  return {
    surveys: data || [],
    isFetching,
    studentDetails,
  };
};

export default connect(mapStateToProps)(Surveyer);
