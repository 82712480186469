import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import DynamicGrid from '../../../Components/DynamicGrid';
import { ProcessCombos } from '../../../store/comboConfig';
import AntdModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import {
  getClientCounselor,
  listCombos,
  getDynamicComos,
  prospectArchive,
  resetProspectArchive,
  getGridData,
} from '../../../store/actions';
import PageTitle from '../../../Components/PageTitle';

class PreIntakeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchLatestData: null,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );
    var result = ProcessCombos('ClientType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let comboTypes = {
      ComboTypes: 'CenterServiceTypeCenter',
      Json: JSON.stringify({
        Combo: [
          {
            StudentId: null,
            StudentCenterId: null,
          },
        ],
      }),
    };
    this.props.dispatch(getDynamicComos(comboTypes));
    this.props.dispatch(
      getClientCounselor({
        CenterId: this.state.filterCenterId,
      }),
    );
  }
  toggleSuccess = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  moveToProspect = record => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: record.StudentId,
            EnrollStatusId: 3,
          },
        ],
      }),
    };
    Axios.post('Student/StudentEnrollStatusUpdate', data)
      .then(response => {
        if (response.data.success) {
          this.toggleSuccess('Client moved from Intake to Prospect Successfully.');
          if (this.state.fetchLatestData) {
            this.state.fetchLatestData();
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getPopOverData = record => {
    const menuAction = JSON.parse(record.Actions).filter(item => item.IsMultiAction)[0];
    return (
      <>
        <p
          onClick={() => {
            this.props.history.push(`/clients/intake/dashboard?tab=summary&id=${record.StudentId}`);
          }}
        >
          View Details
        </p>
        <AntdModal
          ModalButton={({ onClick }) => (
            <p class="" onClick={onClick}>
              {record.IsIntakeArchived ? 'Restore' : 'Archive'}
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                  &times;
                </button>
                <h4 className="modal-title col-12">
                  Are you sure you want to {record.IsIntakeArchived ? 'restore' : 'archive'} this
                  intake?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  type="button"
                  className="btn btn-outline-primary py-1"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn py-1"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      prospectArchive({
                        Json: JSON.stringify({
                          Student: [
                            {
                              StudentId: record.StudentId,
                              Archive: !record.IsIntakeArchived,
                              EnrollStatusId: record.EnrollStatusId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
        {menuAction.IsProspectUser && (
          <AntdModal
            ModalButton={({ onClick }) => (
              <p onClick={onClick} data-target="#move-intake-model">
                Move to Prospect
              </p>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div className="modal-content border-0">
                <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                  <h4 className="modal-title col-12 mb-3">
                    Are you sure you want to move to prospect?
                  </h4>
                </div>
                <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      onOk();
                      this.moveToProspect(record);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          />
        )}
      </>
    );
  };
  getGridLatestData = getData => {
    this.setState({ fetchLatestData: getData });
  };

  componentWillReceiveProps({ isArchivedSuccess, successMsg }) {
    if (isArchivedSuccess && isArchivedSuccess != this.props.isArchivedSuccess) {
      this.toggle(successMsg);
      this.props.dispatch(resetProspectArchive({}));
    }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };

  render() {
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Intake" />
        <div class="content-area">
          <div class="row top-table-search my-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h3 class="page-title mb-3 mb-md-0 ">Intake Roster</h3>
            </div>
          </div>
          <div />
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 " style={{ backgroundColor: 'unset', boxShadow: 'none' }}>
                <div className="dynamic-grid">
                  <DynamicGrid
                    ScreenName={'Intake'}
                    FilterBy={{ IsIntakeList: true }}
                    getPopOverData={this.getPopOverData}
                    getGridLatestData={this.getGridLatestData}
                  />
                </div>
              </div>
            </div>
          </div>
          <CustomModal
            isOpen={this.state.isModalOpen}
            header={this.state.header}
            yesAction={() => {
              this.setState({
                isModalOpen: false,
                header: '',
              });
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>

        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.state.fetchLatestData();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ prospect_archive }) => {
  const { data: archiveData } = prospect_archive;

  let isArchivedSuccess = null;
  let successMsg = '';

  if (archiveData && archiveData.success) {
    isArchivedSuccess = true;
    successMsg = archiveData.data && JSON.parse(archiveData.data)[0].Msg;
  }
  return {
    isArchivedSuccess,
    successMsg,
  };
};

export default connect(mapStateToProps)(withRouter(PreIntakeList));
