import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isComparer, isDateComparer } from '../util/handler';
import { adminstrationListCourse } from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomModal from '../Components/CustomModal';
import { Checkbox, Radio } from 'antd';
import PageTitle from '../Components/PageTitle';

import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import { ProcessCombos } from '../store/comboConfig';

class FilterModal extends Component {
  constructor(props) {
    super(props);
    const { checked, author, filterCourseType, filterCourseTopic, filterNameCourse } = props;
    this.state = {
      checked: checked || null,
      author: author || null,
      filterCourseType: filterCourseType || null,
      filterCourseTopic: filterCourseTopic || null,
      filterNameCourse: filterNameCourse || null,
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, data) => {
    this.setState({
      [key]: data,
    });
  };

  handleCourseTypeChange = filterCourseType => {
    this.setState({ filterCourseType });
  };

  handleCourseTopicChange = filterCourseTopic => {
    this.setState({ filterCourseTopic });
  };

  handleNameCourseChange = filterNameCourse => {
    this.setState({ filterNameCourse });
  };

  resetState = () =>
    this.setState({
      checked: null,
      author: null,
      filterCourseType: null,
      filterCourseTopic: null,
      filterNameCourse: null,
    });

  render() {
    const {
      onOk,
      onCancel,
      Centers,
      refreshGrid,
      CourseType,
      CourseTopic,
      NameCourse,
    } = this.props;
    const { checked, author, filterCourseType, filterCourseTopic, filterNameCourse } = this.state;

    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0 filter-modal">
            <div class="col-12 text-left mb-3">
              <span className="filter-status">Ladder Associations</span>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mr-2 mb-2">
                  <Radio.Group
                    name="radiogroup"
                    onChange={this.onChange}
                    value={checked}
                    className="course-filter"
                  >
                    <Radio value={null} style={{ paddingRight: '50px' }}>
                      All
                    </Radio>
                    <Radio value="12001">Discovering Hope</Radio>
                    <Radio value="12002">Deciding To Change</Radio>
                    <Radio value="12003">Thinking Differently</Radio>
                    <Radio value="12004">Finding Purpose</Radio>
                    <Radio value="12005">Living Healthy</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>

            {/* NameCourse Input */}
            {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Course Name</span>
            </div> */}

            {/* <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-20px' }}>
                  <input
                    type="text"
                    class="form-control"
                    onChange={courseName => {
                      var CourName = (courseName && courseName.target.value) || null;
                    this.setState({
                        nameCourse: CourName,
                      });
                    }}
                    placeholder="Course Name"
                  />
                </div>
              </div>
            </div> */}
            {/* NameCourse Input */}

            {/* CourseType Dropdown */}
            {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">CourseType</span>
            </div> */}

            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={CourseType}
                  onChange={this.handleCourseTypeChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterCourseType}
                  placeholder="Course Type"
                />
              </div>
            </div>
            {/* CourseType Dropdown */}
            <br />
            <br />
            <br />

            {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">CourseTopic</span>
            </div> */}
            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={CourseTopic}
                  onChange={this.handleCourseTopicChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterCourseTopic}
                  placeholder="Course Topic"
                />
              </div>
            </div>

            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={NameCourse}
                  onChange={this.handleNameCourseChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterNameCourse}
                  placeholder="Course Name"
                />
              </div>
            </div>

            {/* Author Input */}
            {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Author Name</span>
            </div> */}
            <br />
            <br />
            <br />

            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <div>
                  <input
                    type="text"
                    class={`form-control ${this.state.author && 'not-empty'}`}
                    defaultValue={this.state.author}
                    onChange={authName => {
                      var nameAuth = (authName && authName.target.value) || null;
                      this.setState({
                        author: nameAuth,
                      });
                    }}
                  />
                  <label className="placeholder-label">Author Name</label>
                </div>
              </div>
            </div>
            {/* Author Input */}
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              // onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
          <button
            type="button"
            class="btn"
            onClick={() => {
              onOk();
              refreshGrid(checked, author, filterCourseType, filterCourseTopic, filterNameCourse);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

const FilterModalWithRouter = withRouter(FilterModal);

class Courses extends Component {
  state = {
    searchStr: null,
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount({ searchStr } = this.state) {
    const { dispatch } = this.props;
    dispatch(adminstrationListCourse({ searchStr: searchStr }));

    var result = ProcessCombos('CourseType,CourseTopic,CenterCourses');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    const { dispatch } = this.props;
    if (value.length == 0) {
      dispatch(adminstrationListCourse({ searchStr: this.state.searchStr }));
    } else {
      dispatch(adminstrationListCourse({ searchStr: this.state.searchStr }));
    }
  };

  onRowClick = (event, record, rowIndex) => {
    const { history } = this.props;
    history.push('/manage/course-form?courseId=' + record.CourseId);
  };

  refreshGrid = (filterType, author, filterCourseType, filterCourseTopic, filterNameCourse) => {
    const { searchStr } = this.state;
    this.setState({
      checked: filterType,
      author,
      filterCourseType,
      filterCourseTopic,
      filterNameCourse,
    });
    this.props.dispatch(
      adminstrationListCourse({
        // Filter: filterType ? filterType.toString() : null,
        // searchStr: searchStr,
        // searchStr: searchStr == null ? nameCourse : searchStr,
        LadderAssociation: filterType ? filterType.toString() : null,
        Author: author,
        CourseType: filterCourseType,
        CourseTopic: filterCourseTopic,
        searchStr: searchStr == null ? filterNameCourse : searchStr,
      }),
    );
  };

  render() {
    const { isFetching, courses, Centers, CourseType, CourseTopic, NameCourse } = this.props;

    const CoursesModalData = {};
    const { students, checked, filterCourseType, filterCourseTopic, filterNameCourse } = this.state;
    const FilterModalData = { checked, filterCourseType, filterCourseTopic, filterNameCourse };

    const columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        sorter: (a, b) => isComparer(a.Name, b.Name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Ladder Associations',
        dataIndex: 'LadderAssociations',
        key: 'LadderAssociations',
        render: (text, record) => <div>{text}</div>,
        sorter: (a, b) => isComparer(a.LadderAssociations, b.LadderAssociations),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Ladder Category',
        dataIndex: 'ContractLadderAssociation',
        key: 'ContractLadderAssociation',
        render: (text, record) => <div>{text}</div>,
        sorter: (a, b) => isComparer(a.ContractLadderAssociation, b.ContractLadderAssociation),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Topic',
        dataIndex: 'CourseTopicDesc',
        key: 'CourseTopicDesc',
        render: (text, record) => <div>{text}</div>,
        sorter: (a, b) => isComparer(a.CourseTopicDesc, b.CourseTopicDesc),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Course Type',
        dataIndex: 'CourseTypeDesc',
        key: 'CourseTypeDesc',
        width: 300,
        sorter: (a, b) => isComparer(a.CourseTypeDesc, b.CourseTypeDesc),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Published Date',
        dataIndex: 'PublishedDate',
        key: 'PublishedDate',
        sorter: (a, b) => isDateComparer(a.PublishedDateForSort, b.PublishedDateForSort),
        sortDirections: ['descend', 'ascend'],
        render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
      },
      {
        title: 'Author',
        key: 'AuthorName',
        dataIndex: 'AuthorName',
        sorter: (a, b) => isComparer(a.AuthorName, b.AuthorName),
        sortDirections: ['descend', 'ascend'],
      },
    ];

    return (
      <div class="container-fluid">
        <PageTitle TitleText="Courses" />
        {/* <Loader loading={isFetching} /> */}
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Courses</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control 
                   shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>

              <CustomModal
                ModalButton={({ onClick }) => (
                  <input
                    type="button"
                    class="btn btn-outline-primary px-5"
                    value="Filter"
                    onClick={onClick}
                  />
                )}
                ModalContent={props => (
                  <FilterModalWithRouter
                    {...props}
                    {...FilterModalData}
                    refreshGrid={this.refreshGrid}
                    // Centers={Centers}
                    CourseType={CourseType}
                    CourseTopic={CourseTopic}
                    NameCourse={NameCourse}
                  />
                )}
              />

              <Link to="/manage/course-form" class="btn black-btn" color="blue">
                Add a Course
              </Link>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="row-hover-enabled">
                      <Table
                        columns={columns}
                        dataSource={courses}
                        size={'middle'}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: event => this.onRowClick(event, record, rowIndex),
                          };
                        }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ course_get_all, combos }) => {
  var { data, isFetching, error } = course_get_all;
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }
  // var Centers = [{ Value: null, Text: 'Please Select' }];
  var CourseType = [];
  var CourseTopic = [];
  var CenterCourses = [];

  if (combos.data) {
    // if (combos.data.Center)
    //   for (var center of combos.data.Center) {
    //     Centers.push(center);
    //   }

    if (combos.data.CourseType)
      for (var courseType of combos.data.CourseType) {
        CourseType.push(courseType);
      }

    if (combos.data.CourseTopic)
      for (var courseTopic of combos.data.CourseTopic) {
        CourseTopic.push(courseTopic);
      }

    if (combos.data.CenterCourses)
      for (var centerCourses of combos.data.CenterCourses) {
        let valueReplacedWithText = { Text: centerCourses.Text, Value: centerCourses.Text };
        CenterCourses.push(valueReplacedWithText);
      }
  }

  return {
    courses: data || [],
    isFetching,
    // Centers: Centers,
    CourseType: CourseType,
    CourseTopic: CourseTopic,
    NameCourse: CenterCourses,
  };
};

export default connect(mapStateToProps)(Courses);
