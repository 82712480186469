import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, reset, destroy, Field, reduxForm, isDirty } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import { Steps, Button, message, Modal, Drawer } from 'antd';
import DemographicsForm from './demographicsForm';
import ClientScreening from './screening';
import ProgramAlignment from './programAlignment';
import AcceptanceQuestionnaire from './acceptanceQuestionnaire';
import { surveyLatestAnswerDateUpdate, studentBanner } from '../../../store/actions';
import CustomModal from '../../../core/modal';
import Twilio from '../../../Components/twilio';
import { Twilio_Enterprise } from '../../../app-config/screenName';
import CallEndedForm from './dashboard/AppointmentsScreens/CallEnded';
import CloseIcon from '../../../Components/CloseIcon';
import PageTitle from '../../../Components/PageTitle';

const { Step } = Steps;

class MultiStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      IsDeactivated: false,
      isSaveClicked: false,
      isCallEndedDrawer: false,
      stepMapping: {
        0: 'demographics',
        1: 'client-screening',
        2: 'program-alignment',
        3: 'acceptance-questionnaire',
      },
      steps: [
        {
          title: 'Demographics',
          content: (
            <DemographicsForm
              updateStep={this.updateStep}
              ID={this.getIntakeId()}
              getSaveStatus={this.getSaveStatus}
            />
          ),
        },
        {
          title: 'Client Screening',
          content: (
            <ClientScreening ID={this.getIntakeId()} getQuestionList={this.getQuestionList} />
          ),
        },
        {
          title: 'Program Alignment',
          content: (
            <ProgramAlignment
              ID={this.getIntakeId()}
              ProgramAllignmentSurveyId={this.props.ProgramAllignmentSurveyId}
              getQuestionList={this.getQuestionList}
            />
          ),
        },
      ],
      bannerData: {},
    };
  }
  componentDidMount() {
    const { activeTabs, stepMapping } = this.state;
    var params = new URLSearchParams(this.props.location.search);
    let step = params.get('step') || params.get('tab');
    if (step && stepMapping) {
      this.setState({
        current: Object.values(stepMapping).indexOf(step),
      });
    } else {
      this.setState({
        current: 0,
      });
    }
    if (this.getIntakeId()) {
      this.props.dispatch(
        studentBanner({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: this.getIntakeId(),
              },
            ],
          }),
        }),
      );
    }
    if (this.props.isEnableAcceptanceQuestionnaireTab) {
      let newStep = this.state.steps;
      newStep.push({
        title: 'Acceptance Questionnaire',
        content: (
          <AcceptanceQuestionnaire
            ID={this.getIntakeId()}
            AcceptanceQuestionnaireSurveyId={this.props.AcceptanceQuestionnaireSurveyId}
            getQuestionList={this.getQuestionList}
          />
        ),
      });
      this.setState({
        steps: newStep,
      });
    }
  }

  getSaveStatus = () => {
    this.setState({
      isSaveClicked: false,
    });
    return this.state.isSaveClicked;
  };
  getTab = () => {
    const param = new URLSearchParams(this.props.history.location.search);
    const tab = param.get('tab');
    return tab;
  };
  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = null;
    }
    return studentId;
  }
  getSurveyId = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let surveyid = param.get('surveyid');
    if (surveyid) {
      surveyid = parseInt(surveyid);
    } else {
      surveyid = null;
    }
    return surveyid;
  };

  getSurveyType = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let stepName = param.get('step');
    if (stepName && stepName == 'client-screening') {
      return 'ClientScreening';
    } else if (stepName && stepName == 'program-alignment') {
      return 'ProgramAlignment';
    } else {
      return null;
    }
  };
  updateStepUrl = (currentKey, GenratedIntakeId, PreSurveyId) => {
    let clickedStep = this.state.stepMapping[currentKey];
    let SurveyId = PreSurveyId || this.getSurveyId();
    this.props.history.push(
      `/pre-intake-form?step=${clickedStep}&studentid=${this.getIntakeId() ||
        GenratedIntakeId}&surveyid=${SurveyId}`,
    );
    this.setState({
      current: currentKey,
    });
  };
  getQuestionList = list => {
    this.setState({
      QuestionList: list,
    });
  };
  isMandatoryQuestion = () => {
    let isRequired;
    for (let i = 0; i < this.state.QuestionList.SurveyQuestion.length; i++) {
      let list = this.state.QuestionList.SurveyQuestion[i];
      isRequired = list.Questions.some(item => {
        return !item.Response && item.IsRequired === true;
      });
      if (isRequired) break;
    }
    return isRequired;
  };
  isCompletedAllQuestion = () => {
    let isNotCompleted;
    if (this.state.QuestionList && this.state.QuestionList.SurveyQuestion)
      for (let i = 0; i < this.state.QuestionList.SurveyQuestion.length; i++) {
        let list = this.state.QuestionList.SurveyQuestion[i];
        isNotCompleted =
          list.Questions &&
          list.Questions.some(item => {
            return !item.Response;
          });
        if (isNotCompleted) break;
      }
    return isNotCompleted;
  };
  handleNext = () => {
    if (this.state.current === 0) {
      this.props.dispatch(submit('prospectForm'));
    } else if (
      this.state.current === 1 &&
      this.state.QuestionList &&
      this.state.QuestionList.SurveyQuestion &&
      this.state.QuestionList.SurveyQuestion.length
    ) {
      if (this.isMandatoryQuestion()) {
        this.toggle(
          'Some mandatory questions are not answered yet. Please answer them to continue.',
        );
      } else {
        this.props.dispatch(
          surveyLatestAnswerDateUpdate({
            // Surveyid: this.getSurveyId(),
            json: JSON.stringify({
              Survey: [
                {
                  SurveyId: this.getSurveyId(),
                  SurveyType: this.getSurveyType(),
                },
              ],
            }),
          }),
        );
        let currentKey = this.state.current + 1;
        this.updateStepUrl(currentKey);
      }
    } else if (this.state.current === 2) {
      if (this.isCompletedAllQuestion()) {
        this.setState({
          isVisible: true,
        });
      } else {
        this.props.dispatch(
          surveyLatestAnswerDateUpdate({
            json: JSON.stringify({
              Survey: [
                {
                  SurveyId: this.props.ProgramAllignmentSurveyId,
                  SurveyType: this.getSurveyType(),
                },
              ],
            }),
          }),
        );
        let currentKey = this.state.current + 1;
        this.updateStepUrl(currentKey);
      }
    } else {
      let currentKey = this.state.current + 1;
      this.updateStepUrl(currentKey);
    }
  };
  updateStep = GenratedIntakeId => {
    let me = this;
    let currentKey = me.state.current + 1;
    me.updateStepUrl(currentKey, GenratedIntakeId);
  };
  handlePrevious = () => {
    let currentKey = this.state.current - 1;
    this.updateStepUrl(currentKey);
  };
  onStepClick = current => {
    if (this.getIntakeId()) {
      let currentKey = current;
      this.updateStepUrl(currentKey);
    } else {
      return;
    }
  };
  gotoDashboard = () => {
    this.props.history.push(`/clients/pre-intake/dashboard?tab=summary&id=${this.getIntakeId()}`);
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  getCallEndDetails = (isRecored, callDetails, twilioData) => {
    let SID = callDetails.parameters.CallSid;
    this.setState({
      isCallEndedDrawer: isRecored ? true : false,
      CallSid: SID,
      twilioData: twilioData,
    });
  };
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  toggleDarwer = () => {
    this.setState({
      isCallEndedDrawer: false,
      CallSid: null,
      twilioData: null,
    });
  };
  componentWillReceiveProps({
    BannerData,
    isEnableAcceptanceQuestionnaireTab,
    AcceptanceQuestionnaireSurveyId,
  }) {
    if (BannerData && JSON.stringify(BannerData) != JSON.stringify(this.props.BannerData)) {
      this.setState({
        bannerData: BannerData,
      });
    }
    if (
      isEnableAcceptanceQuestionnaireTab &&
      isEnableAcceptanceQuestionnaireTab != this.props.isEnableAcceptanceQuestionnaireTab
    ) {
      let newStep = this.state.steps;
      newStep.push({
        title: 'Acceptance Questionnaire',
        content: (
          <AcceptanceQuestionnaire
            ID={this.getIntakeId()}
            AcceptanceQuestionnaireSurveyId={
              AcceptanceQuestionnaireSurveyId || this.props.AcceptanceQuestionnaireSurveyId
            }
            getQuestionList={this.getQuestionList}
          />
        ),
      });
      this.setState({
        steps: newStep,
      });
    }
  }
  render() {
    const { current, steps, IsDeactivated, bannerData } = this.state;
    const { BannerData } = this.props;
    return (
      <div>
        <PageTitle TitleText="Prospect" />
        <div className="w-100 rcm-panel">
          <div className="main enrollment-page mb-5">
            <div className="container-fluid px-5">
              <div className="content-area prospect">
                <>
                  <div className="top-table-search">
                    <div className="mt-1 cursor-pointer" style={{ display: 'inline-block' }}>
                      <a
                        class="back-link"
                        onClick={() => this.props.history.push(`/clients/pre-intake`)}
                      >
                        <img src="images/back-arrow.svg" /> Back to list
                      </a>
                    </div>
                  </div>
                  <div className="row prospect-steps">
                    <div className="col-12">
                      <div className="multi-step-form invite-multi mb-5">
                        <div className="col-12">
                          <div className="steps-action d-flex align-items-center justify-content-between mb-2">
                            <div className="text-left">
                              {this.getIntakeId() ? (
                                <h5 className="d-inline">Edit Prospect </h5>
                              ) : (
                                <h5 className="d-inline">New Prospect </h5>
                              )}
                            </div>
                            {!this.props.isFetching && !IsDeactivated && (
                              <div>
                                {bannerData.IsVoiceCallAllowed > 0 && (
                                  <button
                                    className="btn px-3 mr-2"
                                    onClick={event => {
                                      this.setState({
                                        callModelVisible: true,
                                        clickedRecord: {
                                          Phone: bannerData.PrimaryPhone,
                                          Name: bannerData.PrefferedName,
                                          ProfileImageUrl: bannerData.StudentImageUrl,
                                          StudentId: bannerData.StudentId,
                                        },
                                      });
                                      event.stopPropagation();
                                    }}
                                  >
                                    <i class="fas fa-phone-alt"></i> Call
                                  </button>
                                )}

                                {current > 0 && (
                                  <button
                                    onClick={this.handlePrevious}
                                    class="btn btn-outline-primary mr-2 px-4"
                                  >
                                    Back
                                  </button>
                                )}
                                {current < steps.length - 1 && (
                                  <>
                                    {current == 0 && (
                                      <button
                                        onClick={() => {
                                          this.setState(
                                            {
                                              isSaveClicked: true,
                                            },
                                            () => {
                                              // this.handleNext();
                                              this.props.dispatch(submit('prospectForm'));
                                            },
                                          );
                                        }}
                                        class="btn btn-outline-primary px-4 mx-2"
                                      >
                                        Save
                                      </button>
                                    )}

                                    <button
                                      onClick={this.handleNext}
                                      class="btn px-4"
                                      disabled={this.props.isFetchingSurveyQuestionAssessment}
                                      // disabled={
                                      //   this.state.current === 1 && this.getTab() != 'financial'
                                      // }
                                    >
                                      Save & Next
                                    </button>
                                  </>
                                )}
                                {current === steps.length - 1 && (
                                  <button
                                    onClick={() => {
                                      if (steps.length == 3) {
                                        if (this.isCompletedAllQuestion()) {
                                          this.setState({
                                            isVisible: true,
                                          });
                                        } else {
                                          this.props.dispatch(
                                            surveyLatestAnswerDateUpdate({
                                              json: JSON.stringify({
                                                Survey: [
                                                  {
                                                    SurveyId: this.props.ProgramAllignmentSurveyId,
                                                    SurveyType: this.getSurveyType(),
                                                  },
                                                ],
                                              }),
                                            }),
                                          );
                                          this.gotoDashboard();
                                        }
                                      } else {
                                        this.gotoDashboard();
                                      }
                                    }}
                                    class="btn px-4"
                                    disabled={this.props.isFetchingSurveyQuestionAssessment}
                                  >
                                    Save & Analyze
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <Steps
                          current={current}
                          // onChange={this.onStepClick}  // disableing tab navigation
                        >
                          {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                          ))}
                        </Steps>
                        <div className="steps-content mb-2" style={{ backgroundColor: 'unset' }}>
                          {steps[current] && steps[current].content}
                        </div>

                        <div className="col-12">
                          <div className="steps-action d-flex align-items-center justify-content-end">
                            {!this.props.isFetching && !IsDeactivated && (
                              <div>
                                {current > 0 && (
                                  <button
                                    onClick={this.handlePrevious}
                                    class="btn btn-outline-primary mr-2 px-4"
                                  >
                                    Back
                                  </button>
                                )}

                                {current < steps.length - 1 && (
                                  <>
                                    {current == 0 && (
                                      <button
                                        onClick={() => {
                                          this.setState(
                                            {
                                              isSaveClicked: true,
                                            },
                                            () => {
                                              // this.handleNext();
                                              this.props.dispatch(submit('prospectForm'));
                                            },
                                          );
                                        }}
                                        class="btn btn-outline-primary px-4 mx-2"
                                      >
                                        Save
                                      </button>
                                    )}
                                    <button
                                      onClick={this.handleNext}
                                      class="btn px-4"
                                      disabled={this.props.isFetchingSurveyQuestionAssessment}
                                      // disabled={
                                      //   this.state.current === 1 && this.getTab() != 'financial'
                                      // }
                                    >
                                      Save & Next
                                    </button>
                                  </>
                                )}
                                {current === steps.length - 1 && (
                                  <button
                                    onClick={() => {
                                      if (steps.length == 3) {
                                        if (this.isCompletedAllQuestion()) {
                                          this.setState({
                                            isVisible: true,
                                          });
                                        } else {
                                          this.props.dispatch(
                                            surveyLatestAnswerDateUpdate({
                                              json: JSON.stringify({
                                                Survey: [
                                                  {
                                                    SurveyId: this.props.ProgramAllignmentSurveyId,
                                                    SurveyType: this.getSurveyType(),
                                                  },
                                                ],
                                              }),
                                            }),
                                          );
                                          this.gotoDashboard();
                                        }
                                      } else {
                                        this.gotoDashboard();
                                      }
                                    }}
                                    class="btn px-4"
                                    disabled={this.props.isFetchingSurveyQuestionAssessment}
                                  >
                                    Save & Analyze
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                {this.state.isModelOpen && (
                  <CustomModal
                    isOpen={this.state.isModelOpen}
                    header={this.state.header}
                    changeMaxWidth="650px"
                    yesAction={() => {
                      this.toggle();
                    }}
                    yesText="Ok"
                    hideCancel={true}
                  />
                )}
                <Modal
                  open={this.state.isVisible}
                  footer={null}
                  closable={false}
                  width={700}
                  destroyOnClose={true}
                >
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Some questions are not answered yet. Do you still want to save and
                        {steps.length == 4 ? ' continue' : ' analyze'}?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary"
                        onClick={() => {
                          this.setState({
                            isVisible: false,
                          });
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          if (steps.length == 4) {
                            this.props.dispatch(
                              surveyLatestAnswerDateUpdate({
                                json: JSON.stringify({
                                  Survey: [
                                    {
                                      SurveyId: this.props.ProgramAllignmentSurveyId,
                                      SurveyType: this.getSurveyType(),
                                    },
                                  ],
                                }),
                              }),
                            );

                            this.setState({
                              isVisible: false,
                            });
                            let currentKey = this.state.current + 1;
                            this.updateStepUrl(currentKey);
                          } else {
                            this.gotoDashboard();
                          }
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
            IsCallRecording={bannerData.IsthisPeerRecoveryClient}
            ScreenName={Twilio_Enterprise}
            getCallEndDetails={this.getCallEndDetails}
          />
        )}
        {this.state.isCallEndedDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">Add Appointment</span>
              </div>
            }
            placement="right"
            open={this.state.isCallEndedDrawer}
            width={700}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={this.toggleDarwer}
          >
            <CallEndedForm
              CallSid={this.state.CallSid}
              twilioData={this.state.twilioData}
              isTwilioAppointment={true}
              onClose={this.toggleDarwer}
            />
          </Drawer>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({
  get_form_field_meta_data,
  student_banner,
  student_add,
  list_survey_question_assessment,
  center_config_get,
}) => {
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: centerConfig } = center_config_get;

  const { data: bannerData } = student_banner;
  const { data: getGenericFormFields, isFetching } = get_form_field_meta_data;
  const { isFetching: isFetchingAdding } = student_add;
  let ProgramAllignmentSurveyId = null;
  let AcceptanceQuestionnaireSurveyId = null;
  let BannerData = null;
  let isEnableAcceptanceQuestionnaireTab = null;
  if (bannerData) {
    ProgramAllignmentSurveyId = bannerData.ProgramAllignmentSurveyId;
    AcceptanceQuestionnaireSurveyId = bannerData.AcceptanceQuestionnaireSurveyId;
    BannerData = bannerData;
  }
  if (centerConfig && centerConfig.CenterConfig) {
    isEnableAcceptanceQuestionnaireTab =
      centerConfig.CenterConfig[0] && centerConfig.CenterConfig[0].EnableAcceptanceQuestionnaireTab;
  }
  // let isUserProfile = false;
  // if (update_user_profile && update_user_profile.data) {
  //   isUserProfile = update_user_profile.data.userProfile;
  // }
  return {
    isFetching: isFetching || isFetchingAdding,
    ProgramAllignmentSurveyId,
    AcceptanceQuestionnaireSurveyId,
    isFetchingSurveyQuestionAssessment,
    BannerData,
    isEnableAcceptanceQuestionnaireTab,
  };
};

export default connect(mapStateToProps)(withRouter(MultiStepForm));
