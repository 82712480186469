import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form } from 'antd';
// import { currencyFormatter, numberFormat } from '../../../util/formatter';

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  isString,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  required,
  style,
  minWidth,
  type,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: required == undefined ? true : required,
            message: `${title} required.`,
          },
        ]}
      >
        <Input
          type={type || 'text'}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={style}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 15,
          display: 'flex',
          border: '1px solid #c0c0c0',
          minHeight: '35px',
          minWidth: minWidth || '80px',
        }}
        onClick={toggleEdit}
      >
        {isString ? children : <>${children}</>}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
