import React, { Component } from 'react';
import { Input } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  submit,
} from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SubmitButton from '../../../../../Components/submitButton';
import { payerPortalRequestUpsert } from '../../../../../store/actions';
import { ReduxInput } from '../../../../../component/custom-redux-components';

const { TextArea } = Input;

class ConditionRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      ifValue: null,
      thenValue: null,
    };
  }

  addNoteChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  onRequestSubmit = () => {
    this.props.dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: null,
              SmartContractId: this.props.contractId,
              RequestType: '30205',
              Id: this.props.termsCategoryId,
              Note: this.state.value,
              If: this.state.ifValue,
              Then: this.state.thenValue,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { conditionCase } = this.props;
    return (
      <div>
        <div className="client-detail-box p-0 m-0">
          <p className="client-label mb-0">Condition*</p>
          <p className="client-text mb-0">{`${this.props.termCategory}`}</p>
        </div>

        <div className="enrollment-form">
          <h5 className="col-12 mt-4 p-0">Case</h5>
          {conditionCase &&
            conditionCase.length > 0 &&
            conditionCase.map((item, index) => {
              const { CaseIf, CaseThen } = item;
              return (
                <div className='mb-3 row px-3'>
                  <div className="client-detail-box p-0 m-0 col-6">
                    <p className="client-label mb-0">If*</p>
                    <p className="client-text mb-0">{CaseIf && CaseIf}</p>
                  </div>

                  <div className="client-detail-box p-0 m-0 col-6">
                    <p className="client-label mb-0">Then*</p>
                    <p className="client-text mb-0">{CaseThen && CaseThen}</p>
                  </div>
                </div>
              );
             
            })}
          {/* <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name="if"
                type="text"
                errorMessage="Title Required"
                component={ReduxInput}
                value={this.state.ifValue}
                fieldName={'If'}
                onChange={e => {
                  this.setState({ ifValue: e.target.value });
                }}
              />
              <label class="placeholder-label">If*</label>
            </div>
          </div> */}

          {/* <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name="then"
                type="text"
                component={ReduxInput}
                fieldName={'Then'}
                onChange={e => {
                  this.setState({ thenValue: e.target.value });
                }}
                value={this.state.thenValue}
              />
              <label class="placeholder-label">Then*</label>
            </div>
          </div> */}

          {/* <ReduxInput
            onChange={e => {
              this.setState({ thenValue: e.target.value });
            }}
            value={this.state.thenValue}
            className={'col-6'}
            placeholder={'Then'}
          /> */}
        </div>

        <div className="mt-3">
          <h6>Add Note*</h6>
          <TextArea rows={5} value={this.state.value} onChange={this.addNoteChange} />
        </div>

        <div className="d-flex justify-content-end mt-5 align-items-center  w-100">
          <button
            type="button"
            className="btn mr-3 btn-outline-primary"
            onClick={this.props.openAndCloseModal}
          >
            Cancel
          </button>
          {/* <button type="button" className="btn btn-primary" onClick={this.onRequestSubmit}>
            Send Request
          </button> */}
          <SubmitButton
            value={'Send Request'}
            // className="btn btn-primary"
            loading={this.props.isReqFetching}
            onClick={this.onRequestSubmit}
            style={{ height: '45px' }}
            disabled={
              !(this.state.value && this.state.value.length > 0) 
            }
          />
        </div>
      </div>
    );
  }
}

const ReduxConditionRequest = reduxForm({
  form: 'ConditionRequestForm',
  enableReinitialize: true,
})(connect()(withRouter(ConditionRequest)));

export default ReduxConditionRequest;
