import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Button } from 'antd';
import renderHTML from 'react-render-html';
import { suspiciousTermAck } from '../../../store/actions';
// import CustomModal2 from '../../../core/modal';
class ForbiddenWords extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  acknowledgeFunction = (AckSubOption, ChatGuid) => {
    this.props.dispatch(
      suspiciousTermAck({
        Json: JSON.stringify({
          Acknowledge: [
            {
              ChatGuid: ChatGuid,
              AckSubOption: AckSubOption,
            },
          ],
        }),
      }),
    );
  };

  // toggle = header => {
  //   this.setState(prevState => ({
  //     isModalOpen: !prevState.isModalOpen,
  //     header,
  //   }));
  // };

  componentWillReceiveProps({ isAcknowledged }) {
    // if (isAcknowledged && isAcknowledged != this.props.isAcknowledged) {
    //   // this.setState(
    //   //   {
    //   //     msg: 'Acknowledged Successfully',
    //   //   },
    //   //   () => {
    //   //     setTimeout(() => {
    //   //       this.setState({
    //   //         msg: '',
    //   //       });
    //   //     }, 2000);
    //   //   },
    //   // );
    //   this.setState({ msg: 'Acknowledged Successfully' });
    //   this.props.onCancel2();
    //   this.toggle('Acknowledged Successfully');
    // }
  }
  render() {
    const { EventJsonData, onCancel } = this.props;
    const { Conversation } = EventJsonData || {};
    return (
      <div class="border-0">
        <div class="row">
          <span style={{ fontSize: '16px', color: 'green' }}>
            {' '}
            {this.state.msg && this.state.msg}
          </span>

          <div className="col-12" style={{ overflowY: 'scroll', height: '500px' }}>
            {Conversation &&
              Conversation.map(item => {
                const {
                  ChatGuid,
                  FromUser,
                  FromUserBadgeId,
                  FromUserProfileImgUrl,
                  Msg,
                  ReceivedDate,
                  ToUser,
                  ToUserBadgeId,
                  ToUserProfileImgUrl,
                  Phrases,
                  IsAck,
                } = item || {};
                // let terms = Msg.match(Phrases);
                let isSearched = Msg.search(Phrases);
                // let searchedTerms = Msg.replace(/Welcome|Glad/gi, function(x) {
                //   let terms = x.fontcolor('red');
                //   return terms;
                // });
                let searchedTerms = Msg.replace(
                  // `//${Phrases}//gi`,
                  Phrases,
                  `<span style="color:red; background:#FFD5D5;padding:2px;margin:0px 2px;border-radius:4px;cursor:pointer;">${Phrases}</span>`,
                );
                // let searchedTerms = Msg.replace(
                //   /Welcome|Glad/gi,
                //   `<span style="color:white; background:#FF7979;padding:2px;margin:0px 2px;border-radius:4px;cursor:pointer;" ${(onclick = this
                //     .myFunction)}>Welcome1</span>`,
                // );
                return (
                  <div className="task-box" style={{ backgroundColor: '#f8f8f8' }}>
                    <div className="row no-gutters w-100 justify-content-between align-items-center">
                      <div className="col-12 mb-1">
                        <div className="row">
                          <div className="col-md-4 d-flex justify-content-between">
                            <img
                              className="autoSelectImage"
                              src={FromUserProfileImgUrl || 'images/blank-profile.png'}
                            />
                            <div>
                              <span style={{ fontWeight: 'bold' }}>{FromUser}</span>
                              <br />
                              {ReceivedDate}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <p style={{ color: '#828282', fontSize: '16px' }}>
                              {/* {Msg}
                              <br /> */}
                              {renderHTML(searchedTerms)}
                            </p>
                            <div className="d-block text-right">
                              {/* {IsAck == false && 'false'}
                              {IsAck == null && 'null'}
                              {IsAck == true && 'true'} */}
                              {/* {isSearched === 0 && ( */}
                              {typeof IsAck == 'boolean' ? (
                                IsAck == true ? (
                                  <span
                                    style={{
                                      height: '36px',
                                      width: '250px',
                                      // background: IsAck ? '#E6E6E6' : '#4AB0FE',
                                      color: IsAck ? '#515151' : '#fff',
                                      border: '0px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>{' '}
                                    Acknowledged
                                  </span>
                                ) : (
                                  <>
                                    <Popover
                                      content={
                                        <div>
                                          <p
                                            onClick={() => {
                                              this.acknowledgeFunction(1, ChatGuid);
                                            }}
                                          >
                                            Yes, Suspicious term(s) used.
                                          </p>
                                          <p
                                            onClick={() => {
                                              this.acknowledgeFunction(2, ChatGuid);
                                            }}
                                          >
                                            No, Suspicious term(s) not used.
                                          </p>
                                          <p
                                            onClick={() => {
                                              this.acknowledgeFunction(3, ChatGuid);
                                            }}
                                          >
                                            Not Sure, but keep notifying me.
                                          </p>
                                          <p
                                            onClick={() => {
                                              this.acknowledgeFunction(4, ChatGuid);
                                            }}
                                          >
                                            Don't show me this in the future.
                                          </p>
                                        </div>
                                      }
                                    >
                                      <span
                                        style={{
                                          height: '36px',
                                          width: '250px',
                                          background: IsAck ? '#E6E6E6' : '#4AB0FE',
                                          color: IsAck ? '#515151' : '#fff',
                                          border: '0px',
                                          fontWeight: 'bold',
                                          padding: '10px 15px',
                                        }}
                                      >
                                        Acknowledge
                                      </span>
                                    </Popover>
                                  </>
                                )
                              ) : (
                                ''
                              )}
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <CustomModal2
                      isOpen={this.state.isModalOpen}
                      header={this.state.header}
                      yesAction={() => {
                        this.toggle();
                        this.props.callDataApi();
                        this.props.onCancel();
                      }}
                      yesText="OK"
                      hideCancel={true}
                    /> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ suspicious_term_ack }) => {
  var { data, isFetching, error } = suspicious_term_ack;

  let isAcknowledged = false;

  if (data && data.success) {
    isAcknowledged = true;
  }

  return {
    isAcknowledged: isAcknowledged,
    isFetching,
  };
};
export default connect(mapStateToProps)(ForbiddenWords);
