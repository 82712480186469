import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import SignaturePad from 'react-signature-canvas';

class SignatureType extends Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      value: Response,
      HtmlTemplate: null,
      trimmedDataURL: null,
    };
  }
  sigPadObj = {};
  clear = index => {
    this.sigPadObj[index].clear();
  };
  onClick() {
    debugger;
    if (this.state.SignatureCanvas) {
      const {
        survey: { SurveyQuestionId },
        setValue,
        scope,
      } = this.props;
      setValue.call(scope, {
        response: this.state.SignatureCanvas,
        surveyQuestionId: SurveyQuestionId,
      });
    }
    $('.main-banner').slick('slickNext');
  }
  save = () => {
    const { SignatureCanvas, HtmlTemplate } = this.state;
    let {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
    } = this.props;
    let imgByteArr = Object.values(SignatureCanvas);
    setValue.call(scope, {
      response: imgByteArr,
      surveyQuestionId: survey.SurveyQuestionId,
    });
  };
  saveSignature = (signOne, data, SurveyQuestionId, index) => {
    debugger;
    this.setState({
      surveyQuestionId: SurveyQuestionId,
      SignatureCanvas: data,
    });
  };
  render() {
    const {
      survey: { Question, DoesNotApplyText, DoesNotApplyValue, SurveyId, SurveyQuestionId },
      setValue,
      scope,
      index,
      total,
    } = this.props;
    debugger;
    return (
      <div className=" card border-0">
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <div className="d-flex flex-column">
            <h5 className="survey-question">
              {Question}
              {/* <span class="d-md-block">these psychological or emotional problems?</span> */}
            </h5>
            <div style={{ textAlign: '-webkit-center' }}>
              <div className="" style={{ width: '400px' }}>
                <div className="sigContainer">
                  <SignaturePad
                    canvasProps={{ className: 'sigPad', height: 140, width: 400 }}
                    ref={ref => {
                      Object.assign(this.sigPadObj, { [index]: ref });
                    }}
                    onEnd={() => {
                      // this.trim(`sign+${SurveyQuestionId}`, index);
                      this.saveSignature(
                        `${SurveyQuestionId}`,
                        this.sigPadObj[index].getTrimmedCanvas().toDataURL('image/png'),
                        SurveyQuestionId,
                        index,
                      );
                    }}
                  />
                </div>
                <div className="text-right mt-2">
                  <button
                    className="can-buttons d-inline"
                    onClick={() => {
                      this.clear(index);
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
              }}
            >
              <button class="btn px-5" onClick={this.onClick.bind(this)} tabindex="0">
                Next
              </button>
            </div>
            {DoesNotApplyText && (
              <div className="not-apply-to-me">
                <a
                  name="current-situation"
                  value={DoesNotApplyText}
                  onClick={this.props.onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: DoesNotApplyValue,
                  })}
                >
                  {DoesNotApplyText}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SignatureType;
