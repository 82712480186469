import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ListRenderer from '../../../Components/listRenderer';
import {
  tokenManagementListGetAll,
  tokensStudentTransactionHistory,
  tokensCounselorTransactionHistory,
} from '../../../store/actions';
import AllTransactions from './AllTransactions';
import ClientsTransactions from './ClientsTransactions';
import CounselorsTransactions from './CounselorsTransactions';
import PageTitle from '../../../Components/PageTitle';
// import Loader, { Loader2 } from '../../../Components/Loader';

class TokenManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '0',
    };
  }
  getCenterId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var centerId = param.get('id');
    if (centerId) {
      centerId = parseInt(centerId);
    } else {
      centerId = null;
    }
    return centerId;
  }
  componentDidMount() {
    this.props.dispatch(
      tokenManagementListGetAll({
        JsonInput: JSON.stringify({
          TokenManagement: {
            CenterId: this.getCenterId(),
          },
        }),
      }),
    );
    this.props.dispatch(
      tokensStudentTransactionHistory({
        JsonInput: JSON.stringify({
          TokenManagement: {
            CenterId: this.getCenterId(),
          },
        }),
      }),
    );
    this.props.dispatch(
      tokensCounselorTransactionHistory({
        JsonInput: JSON.stringify({
          TokenManagement: {
            CenterId: this.getCenterId(),
          },
        }),
      }),
    );
    // this.props.dispatch(tokensTransactionListGet({}));
    // this.props.dispatch(tokensPurchaseStatsGet({}));
    // this.props.dispatch(tokensUsedListGet({}));
  }
  render() {
    const { isFetching, tokenList, tokenStats } = this.props;

    const list = [
      {
        Name: 'All Transactions',
        ExtraComponent: () => {
          return <AllTransactions />;
        },
      },
      {
        Name: 'Counselors Transactions',
        ExtraComponent: () => {
          return <CounselorsTransactions />;
        },
      },
      {
        Name: 'Clients Transactions',
        ExtraComponent: () => {
          return <ClientsTransactions />;
        },
      },
    ];
    return (
      <div class="main mb-5">
        <div class="container-fluid token-management">
          <PageTitle TitleText="Token" />
          <div class="row top-table-search mb-1 align-items-center">
            <div class="col-12">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Token Management</h3>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="card border-0">
                <div class="card-body center-tabs">
                  <ListRenderer list={list} activeKey={this.state.activeKey} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(TokenManagement);
