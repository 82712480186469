import React, { useState, useEffect } from 'react';
import { Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { Field, reduxForm, formValueSelector, reset, FieldArray, change } from 'redux-form';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../../../../component/custom-redux-components';
import {
  comboServiceCode,
  getServiceCode,
  serviceCodeBundleGet,
} from '../../../../../../store/actions';
import validate from '../../../../../../core/generic-form/validation';
import BuildServiceBundle from './bundleService';
import ReduxServiceType from '../../../../../MasterBillServices/serviceBundles/serviceBundleForm/serviceType';
import ReduxGoals from './goals';
const { Panel } = Collapse;

const AddBundle = props => {
  const [total, setTotal] = useState(0);
  const [serviceCodeInfo, setServiceCodeInfo] = useState([]);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.disabled) {
      setIsButtonVisible(false);
    }
  }, [props.disabled]);

  useEffect(() => {
    dispatch(
      comboServiceCode({
        Json: JSON.stringify({
          ServiceCode: [
            {
              ServiceCodeTypeId: 2,
            },
          ],
        }),
      }),
    );
  }, []);

  useEffect(() => {
    if (props.serviceCodeInfo && Object.keys(props.serviceCodeInfo).length > 0) {
      let tempInit = props.serviceCodeInfo.filter(item => item != undefined);
      setServiceCodeInfo(tempInit);
    }
  }, [props.serviceCodeInfo]);

  useEffect(() => {
    if (
      props.initialValues &&
      JSON.stringify(props.initialValues) != JSON.stringify(serviceCodeInfo)
    ) {
      // setServiceCodeInfo(props.initialValues);
      // working one
      //  setServiceCodeInfo(props.initialValues[0].BuildServiceBundle);
      let tempInit = props.initialValues.filter(item => item != undefined);
      setServiceCodeInfo(tempInit);
    }
  }, [props.initialValues]);

  const {
    fields,
    CenterConsumable,
    ConsumableFormData,
    setTotalCostOfCare,
    existingRows,
    serviceCodeBundle,
    goalIndex,
    setFieldChanged,
    centerServiceTypeCenter,
    goalLibrary,
  } = props;
  let existingRowsWithServiceCodeBundleId =
    (existingRows && existingRows.filter(item => item && item.ServiceCodeBundleId)) || [];
  let NewComboServiceCodeBundle = serviceCodeBundle || [];
  let scbDropDownOptions = [];
  existingRowsWithServiceCodeBundleId.map((mainItem, index) => {
    NewComboServiceCodeBundle =
      NewComboServiceCodeBundle &&
      NewComboServiceCodeBundle.filter(item => {
        return item.Value != mainItem.ServiceCodeBundleId;
      });
  });

  const handleTotalChange = () => {
    if (
      ConsumableFormData &&
      ConsumableFormData.some(item => item && item.Consumable != undefined)
    ) {
      let result = ConsumableFormData.map((Item, Index) => {
        let filterV;
        let consumablePriceValue;
        if (Item != undefined && Item.Consumable != undefined) {
          filterV = CenterConsumable.filter(option => {
            return option.Value == Item.Consumable;
          });
          consumablePriceValue = filterV.length > 0 && filterV[0].Price * Item.UnitNeeded;
        }
        return consumablePriceValue;
      });
      let newTotal =
        result.filter(item => item).length > 0 &&
        result
          .filter(item => item)
          .reduce((p, c) => {
            return p + c;
          });
      if (!isNaN(newTotal)) {
        setTotal(newTotal);
        setTotalCostOfCare(newTotal);
      }
    } else {
      setTotal(0);
      setTotalCostOfCare(0);
    }
  };

  const onChange = key => {
    console.log(key);
  };
  useEffect(() => {
    handleTotalChange();
  }, [ConsumableFormData]);

  const {
    comboServiceCodeValue,
    DurationFrequencyCombo,
    PayerCombo,
    goalData,
    isGoalFetching,
  } = useSelector(
    ({
      combo_service_code,
      client_insurance_combo_get,
      combos,
      goal_library_get: { data: goalInfo, isFetching },
    }) => {
      return {
        DurationFrequencyCombo: combos && combos.data['DurationFrequency'],
        comboServiceCodeValue:
          combo_service_code && combo_service_code.data && combo_service_code.data.ServiceCode,
        PayerCombo:
          (client_insurance_combo_get &&
            client_insurance_combo_get.data &&
            client_insurance_combo_get.data.Client) ||
          [],
        goalData: goalInfo,
        isGoalFetching: isFetching ? isFetching : false,
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (goalData) {
      dispatch(
        change(
          'contractForm',
          `Bundles[${goalData.bundleIndex}]Goal[${goalData.goalIndex}]Milestone`,
          goalData.GoalLibrary[0].Milestone,
        ),
      );
    }
  }, [goalData]);
  return (
    <div className="row buildServiceBundle" style={{ background: '#f8f8f8' }}>
      <div className="col-12 col-md-12 col-lg-12 my-1">
        <div className="">
          <Collapse
            // expandIcon={({ isActive }) => (
            //   <RightCircleOutlined
            //     rotate={isActive ? -90 : 90}
            //     style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
            //   />
            // )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={onChange}
          >
            {fields.length > 0 ? (
              fields.map((item, index) => {
                const {
                  LaberCost,
                  NetMargin,
                  NetMarginCost,
                  NetAmount,
                  OverHeadCost,
                  StandardCost,
                  TotalCost,
                  TotalServiceCost,
                  UnitOfMeasurementId,
                  UpdatedBy,
                  ConsumableCost,
                  ServiceCodeDescription,
                  BillingRate,
                  OverHeadCostPercentage,
                  Program,
                  DaysInTreatment,
                } =
                  (serviceCodeInfo &&
                    serviceCodeInfo[index] &&
                    serviceCodeInfo[index].BuildServiceBundle &&
                    serviceCodeInfo[index].BuildServiceBundle.length > 0 &&
                    serviceCodeInfo[index]) ||
                  {};

                let temp = [];
                if (existingRowsWithServiceCodeBundleId[index]) {
                  temp =
                    serviceCodeBundle && serviceCodeBundle.length > 0
                      ? serviceCodeBundle.filter(
                          code =>
                            code.Value ==
                            existingRowsWithServiceCodeBundleId[index].ServiceCodeBundleId,
                        )
                      : [];
                }
                scbDropDownOptions = [...NewComboServiceCodeBundle, ...temp];

                return (
                  <>
                    <Panel
                      key={index}
                      className="bundle-panel-border"
                      header={
                        <>
                          <div className="row">
                            <div className="col-md-3 panel-header-text">{`Bundle ${index +
                              1}`}</div>
                            <div className="col-md-9 text-right d-flex">
                              {!props.disabled && (
                                <button
                                  type="button"
                                  class="btn-newCon"
                                  style={{
                                    position: 'unset',
                                    width: 'unset',
                                  }}
                                  onClick={event => {
                                    event.stopPropagation();
                                    fields.push();
                                  }}
                                >
                                  + Add{' '}
                                  {fields.length == 0 ? 'Service Bundle' : 'Another Service Bundle'}
                                </button>
                              )}
                              {fields.length > 1 && (
                                <div className="text-right">
                                  {!props.disabled && (
                                    <a
                                      class="col-1 delete-contract"
                                      onClick={() => {
                                        if (fields.length && fields.length > 0) {
                                          fields.remove(index);
                                        }
                                      }}
                                    >
                                      <i class="fas fa-trash" />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    >
                      <div
                        class="mx-4"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {' '}
                        <div class="row p-2">
                          <div className="col-6">
                            <div class="form-group">
                              <Field
                                name={`${item}ClientInsuranceId`}
                                // type="text"
                                // errorMessage="State Required"
                                component={ReduxSelect}
                                options={PayerCombo}
                                validate={validate.required}
                                placeholder={'Assign Payer*'}
                                fieldName={'Assign Payer'}
                                class="form-control"
                                disabled={props.disabled}
                              />
                              {/* <label class="placeholder-label">Select Consumable</label> */}
                              {/* <span class="small-info">Provide a specific list of goal(s) to be achieved</span> */}
                            </div>
                          </div>
                          <div className="col-4"></div>
                          <div className="col-6">
                            <div class="form-group">
                              <Field
                                name={`${item}ServiceCodeBundleId`}
                                type="text"
                                // errorMessage="State Required"
                                component={ReduxSelect}
                                options={scbDropDownOptions}
                                validate={validate.required}
                                placeholder={`Service Code Bundle ${index + 1}*`}
                                fieldName={'Service Code Bundle'}
                                class="form-control"
                                onChange={selectedValue => {
                                  dispatch(
                                    serviceCodeBundleGet({
                                      JsonInput: JSON.stringify({
                                        ServiceCodeBundle: [
                                          {
                                            ServiceCodeBundleId: selectedValue,
                                          },
                                        ],
                                      }),
                                      goalIndex,
                                      bundleIndex: index,
                                    }),
                                  );
                                }}
                                disabled={props.disabled}
                              />
                              {/* <label class="placeholder-label">Select Consumable</label> */}
                              {/* <span class="small-info">Provide a specific list of goal(s) to be achieved</span> */}
                            </div>
                          </div>
                          <div className="col-3">
                            <div class="form-group">
                              <Field
                                name={`${item}Duration`}
                                type="number"
                                component={ReduxInput}
                                //   errorMessage="Center Name Required"
                                validate={validate.required}
                                fieldName={'Duration'}
                                className="numbers-only"
                                max={365}
                                disableOnPaste={true}
                                disabled={props.disabled}
                              />
                              <label class="placeholder-label">Duration*</label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div class="form-group">
                              <Field
                                name={`${item}FrequencyId`}
                                type="text"
                                component={ReduxSelect}
                                options={DurationFrequencyCombo}
                                validate={validate.required}
                                placeholder={`Frequency*`}
                                fieldName={'Frequency'}
                                class="form-control"
                                disabled={props.disabled}
                              />
                              {/* <label class="placeholder-label">Select Consumable</label> */}
                              {/* <span class="small-info">Provide a specific list of goal(s) to be achieved</span> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mx-4">
                        {Program && (
                          <div className="col-md-4 mt-2 enrollment-form">
                            <div class="form-group mb-0 w-100">
                              <label
                                className="placeholder-label"
                                style={{
                                  zIndex: 1,
                                  fontSize: '11px',
                                  top: '-5px',
                                  // left: !!disabled || value || active ? '0' : '0',
                                  position: 'absolute',
                                }}
                              >
                                Service
                              </label>
                              <span
                                className=""
                                style={{
                                  marginTop: '13px',
                                  display: 'inline-block',
                                }}
                              >
                                {Program}
                              </span>
                            </div>
                          </div>
                        )}
                        {DaysInTreatment && (
                          <div className="col-md-2 mt-2 enrollment-form">
                            <div class="form-group mb-0 w-100">
                              <label
                                className="placeholder-label"
                                style={{
                                  zIndex: 1,
                                  fontSize: '11px',
                                  top: '-5px',
                                  // left: !!disabled || value || active ? '0' : '0',
                                  position: 'absolute',
                                }}
                              >
                                Days In Treatment
                              </label>
                              <span
                                className=""
                                style={{
                                  marginTop: '13px',
                                  display: 'inline-block',
                                }}
                              >
                                {DaysInTreatment}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {existingRows &&
                      existingRows[index] &&
                      existingRows[index].ServiceCodeBundleId ? (
                        <>
                          {' '}
                          <div div class="col-12 col-md-12 col-lg-12">
                            <FieldArray
                              name={`${item}ServiceType`}
                              component={ReduxServiceType}
                              initialValues={
                                (props.initialValues &&
                                  props.initialValues[index] &&
                                  props.initialValues[index].BuildServiceBundle) ||
                                (props.initialValues &&
                                  props.initialValues[index] &&
                                  props.initialValues[index].ServiceType)
                              }
                              setIndexOfNetMarginChange={props.setIndexOfNetMarginChange}
                              setCurrentServiceTypeIndex={props.setCurrentServiceTypeIndex}
                              // centerOverHeadCostState={centerOverHeadCostState}
                              centerOverHeadCostState={10}
                              // setTotalCostOfCare={setTotalCostOfCare}
                              setTotalCostOfCare={() => {}}
                              existingRows={
                                existingRows &&
                                existingRows[index] &&
                                existingRows[index].ServiceType
                              }
                              centerServiceTypeCenter={centerServiceTypeCenter}
                              setFieldChanged={setFieldChanged}
                              goalIndex={goalIndex}
                              bundleIndex={index}
                              accessingInContract={false}
                              disabled={props.disabled}
                            />
                          </div>{' '}
                          <div div class="col-12 col-md-12 col-lg-12">
                            <FieldArray
                                name={`${item}Goal`}
                                component={ReduxGoals}
                                goalLibrary={goalLibrary}
                                SmartContractId={
                                  props.initialValues && props.initialValues.SmartContractId
                                }
                                initialValues={props.initialValues && props.initialValues.Goal}
                                // smartContractMeasure={smartContractMeasure}
                                serviceCodeBundle={serviceCodeBundle}
                                // centerOverHeadCostState={centerOverHeadCostState}
                                // setTotalCostOfCare={setTotalCostOfCare}
                                setFieldChanged={setFieldChanged}
                                // setIndexOfNetMarginChange={setIndexOfNetMarginChange}
                                // setCurrentServiceTypeIndex={setCurrentServiceTypeIndex}
                                existingRows={
                                  existingRows && existingRows[index] && existingRows[index].Goal
                                }
                                centerServiceTypeCenter={centerServiceTypeCenter}
                                disabled={props.disabled}
                                bundleIndex={index}
                                isGoalFetching={isGoalFetching}
                            />
                          </div>
                        </>
                      ) : (
                        <div class="row mx-4">
                          <div className="col-6">Please select a Bundle.</div>
                        </div>
                      )}
                    </Panel>
                  </>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = state => {
//   const { combo_service_code, client_insurance_combo_get, combos } = state;
//   const { data: comboServiceCodeData } = combo_service_code;
//   const { data: payerDropdown } = client_insurance_combo_get;
//   let comboServiceCodeValue = [];
//   let PayerCombo = null;
//   let DurationFrequencyCombo = [];

//   if (comboServiceCodeData && comboServiceCodeData.ServiceCode) {
//     comboServiceCodeValue = comboServiceCodeData.ServiceCode;
//   }
//   if (payerDropdown && payerDropdown.Client) {
//     PayerCombo = payerDropdown.Client || [];
//   }
//   if (combos.data && combos.data['DurationFrequency']) {
//     DurationFrequencyCombo = combos.data['DurationFrequency'] || [];
//   }
//   return {
//     comboServiceCodeValue,
//     PayerCombo,
//     DurationFrequencyCombo,
//   };
// };

const ReduxAddBundle = AddBundle;
export default ReduxAddBundle;
