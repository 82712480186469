import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../validation/validate';
import {
  ReduxSelect,
  ReduxInput,
  ReduxToggle,
  ReduxTextarea,
  ReduxPhoneInput,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { listCombos } from '../../../store/actions';

const FaithProfile = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    FaithType,
    ChurchDenomination,
    style,
    isCurrentChurchValue,
    faithTypeValue,
    isFaithAffiliationValue,
    StateProvince,
    Country,
    goToPreviuos,
    disable,
    dispatch,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
  } = props;
  const countryChange = (countryISO, currentIndex) => {
    if (countryISO) {
      dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
  };
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Do You Have A Faith Or Religious Affiliation?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isFaithAffiliation" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      <div class="row mb-4">
        {isFaithAffiliationValue && (
          <>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`faithTypeId`}
                  placeholder="Faith Type"
                  component={ReduxSelect}
                  options={FaithType}
                  disabled={disable}
                />
              </div>
            </div>
            {faithTypeValue == 1 && (
              <>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name={`churchDenominationId`}
                      placeholder="Denominations*"
                      component={ReduxSelect}
                      options={ChurchDenomination}
                      validate={validate.required}
                      fieldName={'Denominations'}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      <Field
                        name={`isBornAgain`}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                      {'   '}
                      Is Born Again?
                    </label>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      <Field
                        name={`isSpiritFilled`}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                      {'   '}
                      Is Spirit Filled?
                    </label>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      <Field
                        name={`isChurchGoing`}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                      {'   '}
                      Current Church
                    </label>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {isCurrentChurchValue && faithTypeValue == 1 && (
          <>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchName`}
                  type="text"
                  component={ReduxInput}
                  validate={validate.required}
                  fieldName={'Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">Name*</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchCity`}
                  type="text"
                  component={ReduxInput}
                  validate={validate.required}
                  fieldName={'City'}
                  disabled={disable}
                />
                <label class="placeholder-label">City*</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchCountry`}
                  type="text"
                  errorMessage="Country Required"
                  component={ReduxSelect}
                  options={Country}
                  validate={validate.required}
                  onChange={countryISO => countryChange(countryISO)}
                  placeholder="Country*"
                  fieldName={'Country'}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchState`}
                  // type="text"
                  component={ReduxSelect}
                  validate={validate.required}
                  options={StateProvince}
                  placeholder="State/Province/Territories*"
                  fieldName="State/Province/Territories"
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchPhone`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Church Phone'}
                  disabled={disable}
                />
                <label class="placeholder-label">Church Phone</label>
              </div>
            </div>

            {/* <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchState`}
                  type="text"
                  component={ReduxInput}
                  validate={validate.required}
                  fieldName={'State'}
                  disabled={disable}
                />
                <label class="placeholder-label">State*</label>
              </div>
            </div> */}
          </>
        )}
        <div class="col-12 col-md-6 col-lg-8">
          <div class="form-group">
            <Field
              name={`faithNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>
      </div>

      <div class="row pagination-button">
        <div class="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              class="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  var isCurrentChurchValue = selector(state, 'isChurchGoing');
  var faithTypeValue = selector(state, 'faithTypeId');
  var isFaithAffiliationValue = selector(state, 'isFaithAffiliation');
  return {
    FaithType: combos.data.FaithType,
    ChurchDenomination: combos.data.ChurchDenomination,
    HealthInsuranceType: combos.data.HealthInsuranceType,
    Country: combos.data.Country,
    isCurrentChurchValue,
    faithTypeValue,
    isFaithAffiliationValue,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(FaithProfile),
);

const selector = formValueSelector('wizard');
