import React, { useState } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import validate from '../validation/validate';
import {
  ReduxInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import CloseIcon from '../../../Components/CloseIcon';
// import { currencyFormatter } from '../../../util/formatter';
import LevelOfCareDrawer from './levelOfCareDrawer';

const Validator = {
  required: value => (value ? undefined : 'required'),
  allowPositive: value =>
    value && parseInt(value) < 0
      ? 'should not be less than 0'
      : Number.isInteger(value) || !isNaN(value)
      ? undefined
      : 'allows numeric values only',
  // allowDecimal: value =>
  //   Number.isInteger(value) || !isNaN(value) ? undefined : 'allows numeric values only',
  endDate: (value, values, props, name) => {
    if (!value) {
      return;
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values[objectName][index].startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return 'has to be greater than Start Date';
    }
  },
};

const LevelOfCare = ({
  fields,
  name,
  meta: { touched, error },
  CenterServiceTypeCenter,
  Frequency,
  existingRows,
  disable,
  Phase,
  OutcomeField,
  isAllowToEditClosed,
}) => {
  let NewPhase = Phase;
  let phaseOption = [];
  existingRows.map((phaseItem, index) => {
    NewPhase = NewPhase.filter(item => {
      return item.Value != phaseItem.phaseId;
    });
  });
  return (
    <>
      {fields.map((levelOfCare, index) => {
        let currentPhase = Phase.filter(cPhase => cPhase.Value == existingRows[index].phaseId);
        phaseOption = [...NewPhase, ...currentPhase];
        // for (let i = 0; i < index; i++) {
        //   phaseOption = phaseOption.filter(
        //     (phaseItem, phaseIndex) => existingRows[i].phaseId != phaseItem.Value,
        //   );
        // }
        return (
          <div key={index}>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Level in the program {index == 0 && '(Active)'}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete Phase Level
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.phaseId`}
                    component={ReduxSelect}
                    errorMessage="Field Required"
                    options={phaseOption}
                    placeholder="Student Phase/Level in the program"
                    // validate={validate.required}
                    fieldName={'Student Phase/Level in the program'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.centerServiceTypeCenterId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Phase*"
                    options={CenterServiceTypeCenter}
                    validate={validate.required}
                    fieldName={'Phase'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.outcomeField`}
                    component={ReduxSelect}
                    // errorMessage="Field Required"
                    options={OutcomeField}
                    placeholder="Outcome"
                    // validate={validate.required}
                    fieldName={'Outcome'}
                    disabled={disable}
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.startDate`}
                    type="text"
                    label={'Start Date*'}
                    component={ReduxDateInput}
                    validate={validate.required}
                    fieldName={'Start Date'}
                    disabled={isAllowToEditClosed ? !isAllowToEditClosed : disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  {!existingRows[index].isEndDateEstimated ? (
                    <Field
                      name={`${levelOfCare}.endDate`}
                      type="text"
                      label={'End Date'}
                      component={ReduxDateInput}
                      fieldName={'End Date'}
                      disabled={isAllowToEditClosed ? !isAllowToEditClosed : disable}
                    />
                  ) : (
                    <Field
                      name={`${levelOfCare}.endDate`}
                      type="text"
                      label={'End Date'}
                      validate={Validator.endDate}
                      component={ReduxDateInput}
                      fieldName={'End Date'}
                      disabled={isAllowToEditClosed ? !isAllowToEditClosed : disable}
                    />
                  )}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="custom-control-label" for="is-emergency-contact">
                    <Field
                      name={`${levelOfCare}.isEndDateEstimated`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      disabled={disable}
                    />
                    {'   '}
                    Is End Date Estimated?
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.estimatedCostFrequencyCode`}
                    type="text"
                    component={ReduxSelect}
                    options={Frequency}
                    placeholder="Cost of Care Frequency*"
                    validate={validate.required}
                    fieldName={'Cost of Care Frequency'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  {/* <Field name={`${levelOfCare}.line1`} type="text" component={ReduxInput} />
                  <label class="placeholder-label">Permanent Address Line 1</label> */}
                  <Field
                    name={`${levelOfCare}.estimatedCost`}
                    component={ReduxDollarInput}
                    validate={[Validator.required, Validator.allowPositive]}
                    fieldName={'Estimated cost'}
                    disabled={disable}
                    dollarPlaceHolder={'Standard Cost of Care *'}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.notes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  {/* <Field name={`${levelOfCare}.line2`} type="text" component={ReduxInput} /> */}
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div class="row">
        {phaseOption.length != 1 && (
          <div class="col-12 text-right pt-4 pb-4">
            <a
              class="add-contact"
              // onClick={() =>
              //   fields.push({
              //     isEndDateEstimated: true,
              //     estimatedCostFrequencyCode: 'D',
              //     estimatedCost: Number(191.2).toFixed(2),
              //   })
              // }
              onClick={() => {
                if (!disable) {
                  return fields.push({
                    isEndDateEstimated: true,
                    estimatedCostFrequencyCode: 'D',
                    estimatedCost: Number(191.2).toFixed(2),
                  });
                }
              }}
              disabled={disable}
            >
              {fields.length >= 1 && (
                <label>
                  {' '}
                  <a>+ Add Another Phase Level</a>
                </label>
              )}
              {fields.length == 0 && (
                <label>
                  {' '}
                  <a>+ Add Phase Level</a>
                </label>
              )}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

const LevelOfCares = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    CenterServiceTypeCenter,
    Frequency,
    style,
    goToPreviuos,
    existingRows,
    disable,
    onLastSubmit,
    currentTab,
    tabsCount,
    Phase,
    OutcomeField,
    handleSubmitAndNext,
    isReInstated,
    isAllowToEditClosed,
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="col-6 mt-4 mb-0">PHASE LEVEL</h4>
        {isReInstated && (
          <div className="col-6 text-right mt-4">
            <button className="btn btn-outline-primary" onClick={() => setIsDrawerOpen(true)}>
              Previous Episode(s) Phase Level(s)
            </button>
          </div>
        )}
      </div>

      <form
        onSubmit={handleSubmit(data => {
          handleSubmitAndNext(data, currentTab, tabsCount);
        })}
        style={style}
      >
        {existingRows.length < 1 && (
          <div className="row">
            <div className="col-10">
              <div style={{ textAlign: 'left', padding: '10px' }}>
                <h6>
                  NOTE:{' '}
                  <small>
                    "Level of Care" is mandatory to transition a client from "Intake" to "Enrolled"
                    Status.
                  </small>
                </h6>
              </div>
            </div>
          </div>
        )}

        <FieldArray
          name={name}
          component={LevelOfCare}
          CenterServiceTypeCenter={CenterServiceTypeCenter}
          Frequency={Frequency}
          existingRows={existingRows}
          disable={disable}
          Phase={Phase}
          OutcomeField={OutcomeField}
          isAllowToEditClosed={isAllowToEditClosed}
        />
        <div class="row pagination-button">
          <div class="col-12 text-right">
            {currentTab > 0 && (
              <button
                onClick={goToPreviuos}
                type="button"
                class="btn btn-outline-primary btn-eForm-Prev mr-2"
                disabled={disable}
              >
                Previous
              </button>
            )}
            {currentTab + 1 != tabsCount && (
              <button
                type="submit"
                // onClick={handleNext}
                class="btn btn-eForm-Next"
                disabled={disable}
              >
                Next
              </button>
            )}
            {currentTab + 1 == tabsCount && (
              <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
                Submit
              </button>
            )}
          </div>
        </div>

        <Drawer
          className="custom-drawer enrollment-form"
          title="Previous Episode(s) Phase Level(s)"
          placement="right"
          onClose={() => setIsDrawerOpen(false)}
          open={isDrawerOpen}
          width="900"
          closeIcon={<CloseIcon />}
        >
          <LevelOfCareDrawer disable={true} />
        </Drawer>
      </form>
    </div>
  );
};

export default connect((state, props) => {
  const { combos, get_dynamic_combos } = state;
  const startDate = selector(state, `${props.Financial}.startDate`);
  const existingRows = selector(state, `${props.name}`);
  let Phase = [];
  let OutcomeField = [];
  if (combos && combos.data && combos.data.Phase) {
    Phase = combos.data.Phase;
  }
  if (combos && combos.data && combos.data.OutcomeField) {
    OutcomeField = combos.data.OutcomeField;
  }
  return {
    CenterServiceTypeCenter:
      (get_dynamic_combos &&
        get_dynamic_combos.data &&
        get_dynamic_combos.data.CenterServiceTypeCenter) ||
      [],
    Frequency: combos.data.Frequency,
    existingRows,
    startDate,
    Phase,
    OutcomeField,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(LevelOfCares),
);

const selector = formValueSelector('wizard');
