import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate from '../validation/validate';
import renderField from '../reduxFormComponent/renderField';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';
import staticData from './staticDropdown';
import ToggleNote from '../toggleNote';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class MedicalHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const {
      fields,
      name,
      meta: { touched, error },
      FinancialResponsibilityType,
      Frequency,
      existingRows,
      disable,
      ClientMedicalHistory,
      existingRowsMedicalPast,
      NotRequired,
      hideQuestions,
    } = this.props;
    return (
      <>
        {fields.map((medicalPast, index) => {
          return (
            <>
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <h6>Medical History {index + 1}</h6>
                </div>
                <div className="col-12 col-md-6">
                  <a
                    className="delete-contact"
                    onClick={() => fields.remove(index)}
                    disabled={disable}
                  >
                    <i className="fas fa-trash" /> Delete Medical History
                  </a>
                </div>
              </div>
              <div className="row mb-4">
                {!hideQuestions && (
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <Field
                        name={`${medicalPast}.relation`}
                        type="text"
                        component={ReduxSelect}
                        options={ClientMedicalHistory}
                        placeholder="Family medical history for*"
                        validate={validate.required}
                        fieldName={'Family medical history for'}
                        disabled={disable}
                      />
                    </div>
                  </div>
                )}

                {!hideQuestions && (
                  <div className="col-12 col-md-6 col-lg-8">
                    <div className="form-group">
                      <Field
                        name={`${medicalPast}.familyMedicalPast`}
                        type="textarea"
                        component={ReduxInput}
                        disabled={disable}
                      />
                      <label className="placeholder-label">
                        History should include Drug use, Alcohol use, Physical ailments, Mental
                        Health issues
                      </label>
                    </div>
                  </div>
                )}

                <ToggleNote
                  isToggleOn={
                    existingRowsMedicalPast && existingRowsMedicalPast[index].isUnderMedication
                  }
                  toggleQuestion={'Are you currently taking any prescription medications?'}
                  toggleFiledName={`${medicalPast}.isUnderMedication`}
                  toggleNoteFieldName={`${medicalPast}.currentMedications`}
                  disable={disable}
                  notesPlaceholder={`Current Medications`}
                />

                <div className="col-12 col-md-6 col-lg-8">
                  <div className="form-group">
                    <Field
                      name={`${medicalPast}.devlopmentalIssuesPast`}
                      type="textarea"
                      component={ReduxInput}
                      disabled={disable}
                    />
                    <label className="placeholder-label">
                      History of developmental issues such as learning disabilities{' '}
                    </label>
                  </div>
                </div>

                <div
                  className={
                    existingRowsMedicalPast && existingRowsMedicalPast[index].hasAllergies
                      ? 'col-12 p-0 mb-3'
                      : 'col-12 p-0'
                  }
                  style={{
                    backgroundColor:
                      existingRowsMedicalPast && existingRowsMedicalPast[index].hasAllergies
                        ? '#F6F6F6'
                        : 'transparent',
                  }}
                >
                  <div
                    className={
                      existingRowsMedicalPast && existingRowsMedicalPast[index].hasAllergies
                        ? 'col-12 col-md-6 col-lg-12 mt-2'
                        : 'col-12 col-md-6 col-lg-12'
                    }
                  >
                    <div className="form-group">
                      <label className="custom-control-label" for="is-detox">
                        Do you have any allergies?
                        {'   '}
                        <Field
                          name={`${medicalPast}.hasAllergies`}
                          type="text"
                          className="custom-control-input not-empty"
                          component={ReduxToggle}
                          disabled={disable}
                        />
                      </label>
                    </div>
                  </div>

                  {!hideQuestions &&
                    existingRowsMedicalPast &&
                    existingRowsMedicalPast[index].hasAllergies && (
                      <div className="col-12 d-flex p-0 toggle-notes">
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group">
                            <Field
                              name={`${medicalPast}.allergiesMedicines`}
                              type="text"
                              component={ReduxInput}
                              // validate={validate.required}
                              fieldName={'Allergies Medicines Name'}
                              disabled={disable}
                            />
                            <label className="placeholder-label">Allergies - Medications</label>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group">
                            <Field
                              name={`${medicalPast}.allergiesFood`}
                              type="text"
                              component={ReduxInput}
                              // validate={validate.required}
                              fieldName={'Allergies Food Name'}
                              disabled={disable}
                            />
                            <label className="placeholder-label">Allergies - Foods</label>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <div className="form-group">
                            <Field
                              name={`${medicalPast}.allergiesOthers`}
                              type="text"
                              component={ReduxInput}
                              // validate={validate.required}
                              fieldName={'Allergies Others Name'}
                              disabled={disable}
                            />
                            <label className="placeholder-label">Allergies - Others</label>
                          </div>
                        </div>
                      </div>
                    )}

                  {hideQuestions &&
                    existingRowsMedicalPast &&
                    existingRowsMedicalPast[index].hasAllergies && (
                      <div className="col-12 col-md-6 col-lg-12">
                        <div className="form-group">
                          <Field
                            name={`${medicalPast}.hasAllergiesNote`}
                            type="textarea"
                            component={ReduxTextarea}
                            disabled={disable}
                          />
                          <label className="placeholder-label">Notes</label>
                        </div>
                      </div>
                    )}
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <Field
                      name={`${medicalPast}.currentPhysicanName`}
                      type="text"
                      component={ReduxInput}
                      validate={!NotRequired ? validate.required : validate.notRequired}
                      fieldName={'Physician Name'}
                      disabled={disable}
                    />
                    <label className="placeholder-label">Physician Name{!NotRequired && '*'}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <Field
                      name={`${medicalPast}.currentPhysicanPhone`}
                      type="text"
                      component={ReduxInput}
                      validate={[
                        !NotRequired ? Validator.required : validate.notRequired,
                        Validator.allowNumericMobile,
                      ]}
                      fieldName={'Physician Phone'}
                      disabled={disable}
                    />
                    <label className="placeholder-label">
                      Physician Phone{!NotRequired && '*'}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <Field
                      name={`${medicalPast}.currentPhysicanAddress`}
                      type="text"
                      component={ReduxInput}
                      validate={!NotRequired ? validate.required : validate.notRequired}
                      fieldName={'Physician Address'}
                      disabled={disable}
                    />
                    <label className="placeholder-label">
                      Physician Address{!NotRequired && '*'}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-tobacco-use">
                      Do you use tobacco?
                      {'   '}
                      <Field
                        name={`${medicalPast}.isTobaccoUser`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-detox">
                      Will you need to detox?
                      {'   '}
                      <Field
                        name={`${medicalPast}.needTobaccoDetox`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-Suicidal-Thoughts">
                      Have you ever or are you currently experiencing suicidal thoughts?
                      {'   '}
                      <Field
                        name={`${medicalPast}.hasSuicidalThoughts`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div>

                <ToggleNote
                  isToggleOn={
                    existingRowsMedicalPast && existingRowsMedicalPast[index].needAccommodation
                  }
                  toggleQuestion={'Any physical Condition that you may need accomodation for?'}
                  toggleFiledName={`${medicalPast}.needAccommodation`}
                  toggleNoteFieldName={`${medicalPast}.accommodationNote`}
                  disable={disable}
                  notesPlaceholder={`If, "Yes" what is the condition and requested accommodation?`}
                />

                {/* <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-detox">
                      Are you currently taking any prescription medications?
                      {'   '}
                      <Field
                        name={`${medicalPast}.isUnderMedication`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div> */}
                {/* {existingRowsMedicalPast && existingRowsMedicalPast[index].isUnderMedication && (
                  <div className="col-12 col-md-6 col-lg-8">
                    <div className="form-group">
                      <Field
                        name={`${medicalPast}.medicationList`}
                        type="textarea"
                        component={ReduxInput}
                        disabled={disable}
                      />
                      <label className="placeholder-label">If, "Yes" list medications.</label>
                    </div>
                  </div>
                )} */}

                {/* <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-detox">
                      Do you have any allergies?
                      {'   '}
                      <Field
                        name={`${medicalPast}.hasAllergies`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div> */}

                <ToggleNote
                  isToggleOn={
                    existingRowsMedicalPast &&
                    existingRowsMedicalPast[index].hasMentalHealthDiagnosis
                  }
                  toggleQuestion={'Do you have any Mental Health Diagnosis?'}
                  toggleFiledName={`${medicalPast}.hasMentalHealthDiagnosis`}
                  toggleNoteFieldName={`${medicalPast}.diagnosisNote`}
                  disable={disable}
                  notesPlaceholder={`If Yes, List the Diagnosis`}
                />
                {/* <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-detox">
                      Client has been informed of the requirement to obtain a physical within 30
                      days of admission?
                      {'   '}
                      <Field
                        name={`${medicalPast}.physicalWithin30Days`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div> */}
                {/* <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-detox">
                      Client has been informed that no income will be generated through vocational
                      training?
                      {'   '}
                      <Field
                        name={`${medicalPast}.noIncomeFromVocationalTraining`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div> */}
                <ToggleNote
                  isToggleOn={
                    existingRowsMedicalPast && existingRowsMedicalPast[index].isPreviouslyEnrolled
                  }
                  toggleQuestion={'Have you previously been enrolled in one of our programs?'}
                  toggleFiledName={`${medicalPast}.isPreviouslyEnrolled`}
                  toggleNoteFieldName={`${medicalPast}.isPreviouslyEnrolledNote`}
                  disable={disable}
                />
                {/* <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="custom-control-label" for="is-detox">
                      Have you been in treatment for addiction before?
                      {'   '}
                      <Field
                        name={`${medicalPast}.treatmentForAddiction`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                    </label>
                  </div>
                </div> */}

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <Field
                      name={`${medicalPast}.conditionofTeeth`}
                      type="text"
                      component={ReduxInput}
                      // validate={validate.required}
                      fieldName={'Teeth Condition'}
                      disabled={disable}
                    />
                    <label className="placeholder-label">
                      What is the condition of your teeth?{' '}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-8">
                  <div className="form-group">
                    <Field
                      name={`${medicalPast}.notes`}
                      type="textarea"
                      component={ReduxTextarea}
                      disabled={disable}
                    />
                    <label className="placeholder-label">Notes</label>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <div className="row">
          <div className="col-12 text-right pt-4 pb-4">
            <a className="add-contact" onClick={() => fields.push({})} disabled={disable}>
              {fields.length >= 1 && (
                <label>
                  <a> + Add Another Medical History</a>
                </label>
              )}
              {fields.length == 0 && (
                <label>
                  {' '}
                  <a>+ Add Medical History</a>
                </label>
              )}
            </a>
          </div>
        </div>
      </>
    );
  }
}

const MedicalHistoryUI = props => {
  const {
    handleSubmit,
    handleNext,
    onLastSubmit,
    name,
    FinancialResponsibilityType,
    ClientMedicalHistory,
    Frequency,
    style,
    existingRows,
    disable,
    currentTab,
    tabsCount,
    goToPreviuos,
    handleSubmitAndNext,
    existingRowsMedicalPast,
    NotRequired,
    hideQuestions,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <FieldArray
        name={name}
        component={MedicalHistory}
        FinancialResponsibilityType={FinancialResponsibilityType}
        ClientMedicalHistory={ClientMedicalHistory}
        Frequency={Frequency}
        existingRows={existingRows}
        disable={disable}
        existingRowsMedicalPast={existingRowsMedicalPast}
        NotRequired={NotRequired}
        hideQuestions={hideQuestions}
      />
      <div className="row pagination-button">
        <div className="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              className="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              className="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" className="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos, user_feature_flag_get } = state;
  const { data: fFData } = user_feature_flag_get;
  const existingRows = selector(state, 'fundSources');
  const existingRowsMedicalPast = selector(state, 'medicalPast');
  let NotRequired;
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    NotRequired = fFData.FeatureFlag[0].NotRequired;
  }
  return {
    Frequency: combos.data.Frequency,
    ClientMedicalHistory: combos.data.ClientMedicalHistory,
    FinancialResponsibilityType: combos.data.FinancialResponsibilityType,
    existingRows,
    existingRowsMedicalPast,
    NotRequired,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(MedicalHistoryUI),
);

const selector = formValueSelector('wizard');
