import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
// import { addDays, subDays } from 'date-fns';
import addDays from 'date-fns/addDays';
import { formatDistance, subDays } from 'date-fns';
import { getDate, getLocalDate } from '../../util/date';
import InputMask from 'react-input-mask';

const CustomDatepicker = ({ onChange, date, value }) => {
  var props = {
    onChange: onChange,
    dateFormat: 'dd-MMM-yyyy',
  };
  if (date) {
    props.selected = date; //moment(date, 'YYYY/MM/DD');
  }
  return <DatePicker {...props} />;
};

export default CustomDatepicker;

const ReduxDateInputWithoutMask = ({
  input,
  name,
  id,
  value,
  onChange,
  meta,
  errorMessage,
  minDate,
  maxDate,
  disabled,
  tabIndex,
  label,
  fieldName,
  autoComplete,
}) => {
  const { touched, error, warning, active } = meta || {};
  var props = {
    style: { display: 'block', backgroundColor: '#f1f1f1' },
    dateFormat: 'dd-MMM-yyyy',
  };

  if (input) {
    onChange = input.onChange;
  }

  if (input && input.value) {
    if (typeof input.value == 'object') {
      value = getLocalDate(input.value); //moment(input.value, 'YYYY/MM/DD');
    } else {
      //this is commented but may be reused if date -1 issue faced
      value = getDate(input.value);
      // value = getLocalDate(input.value);
    }
  }

  return (
    <div>
      <label
        class="placeholder-label datepicker-label"
        style={{
          zIndex: 1,
          fontSize: !!disabled || value || active ? '14px' : '16px',
          top: !!disabled || value || active ? '-5px' : '10px',
        }}
      >
        {label}
      </label>
      <DatePicker
        {...props}
        autoComplete={autoComplete}
        name={name}
        tabIndex={tabIndex}
        id={id}
        showOnInputClick
        onChange={onChange}
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        className={`form-control`}
        showPopperArrow={false}
        showYearDropdown
        popperProps={{
          positionFixed: true,
        }}
        // popperPlacement="bottom-end"
        // popperModifiers={{
        //   offset: {
        //     enabled: true,
        //     offset: '5px, 10px',
        //   },
        //   preventOverflow: {
        //     enabled: true,
        //     escapeWithReference: false,
        //     boundariesElement: 'viewport',
        //   },
        // }}
      />
      {touched &&
        ((error && (
          <>
            <br />
            <span className="error-text">
              {fieldName} {errorMessage || error}
            </span>
          </>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

const ReduxDateInput = ({
  input,
  name,
  id,
  value,
  onChange,
  meta,
  errorMessage,
  minDate,
  maxDate,
  disabled,
  tabIndex,
  label,
  fieldName,
  autoComplete,
  onBlurEvent,
}) => {
  const datePickerRef = useRef(null);
  const [placeholderLable, setPlaceholderLabel] = useState(false);
  const { touched, error, warning, active } = meta || {};
  var props = {
    style: { display: 'block', backgroundColor: '#f1f1f1' },
    dateFormat: 'MM-dd-yyyy',
  };

  if (input) {
    onChange = input.onChange;
  }

  if (input && input.value) {
    if (typeof input.value == 'object') {
      value = getLocalDate(input.value); //moment(input.value, 'YYYY/MM/DD');
    } else {
      //this is commented but may be reused if date -1 issue faced
      value = getDate(input.value);
      // value = getLocalDate(input.value);
    }
  }

  return (
    <div className="masked-date">
      <label
        class="placeholder-label datepicker-label"
        style={{
          zIndex: 1,
          fontSize: !!disabled || value || active || placeholderLable ? '14px' : '16px',
          top: !!disabled || value || active || placeholderLable ? '-5px' : '10px',
        }}
      >
        {label}
      </label>
      <DatePicker
        {...props}
        autoComplete={autoComplete}
        name={name}
        tabIndex={tabIndex}
        id={id}
        showOnInputClick
        onChange={onChange}
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        className={`form-control`}
        showPopperArrow={false}
        showYearDropdown
        // showMonthYearDropdown
        // useShortMonthInDropdown
        popperProps={{
          positionFixed: true,
        }}
        onFocus={e => {
          setPlaceholderLabel(true);
        }}
        onBlur={e => {
          setPlaceholderLabel(false);
          onBlurEvent && onBlurEvent(e);
        }}
        // popperPlacement="bottom-end"
        // popperModifiers={{
        //   offset: {
        //     enabled: true,
        //     offset: '5px, 10px',
        //   },
        //   preventOverflow: {
        //     enabled: true,
        //     escapeWithReference: false,
        //     boundariesElement: 'viewport',
        //   },
        // }}
        // highlightDates={[subDays(new Date(), 7), addDays(new Date(), 7)]}
        ref={datePickerRef}
        isClearable={!disabled}
        customInput={
          <InputMask
            mask="99/99/9999"
            className="w-100"
            value={props.value}
            onChange={onChange}
            onClick={() => {
              datePickerRef.current.setOpen(true);
            }}
          >
            {inputProps => <input {...inputProps} disabled={disabled} />}
          </InputMask>
        }
        alwaysShowMask
      />
      {touched &&
        ((error && (
          <>
            <br />
            <span className="error-text">
              {fieldName} {errorMessage || error}
            </span>
          </>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

const ReduxMonthYearInput = ({
  input,
  name,
  id,
  value,
  onChange,
  meta,
  errorMessage,
  minDate,
  maxDate,
  disabled,
  tabIndex,
  label,
  fieldName,
  autoComplete,
  onBlurEvent,
}) => {
  const datePickerRef = useRef(null);
  const [placeholderLable, setPlaceholderLabel] = useState(false);
  const { touched, error, warning, active } = meta || {};
  var props = {
    style: { display: 'block', backgroundColor: '#f1f1f1' },
    dateFormat: 'MM/yyyy',
  };

  if (input) {
    onChange = input.onChange;
  }

  if (input && input.value) {
    if (typeof input.value == 'object') {
      value = getLocalDate(input.value); //moment(input.value, 'YYYY/MM/DD');
    } else {
      console.log('object not true');
      //this is commented but may be reused if date -1 issue faced
      value = getDate(input.value);
      // value = getLocalDate(input.value);
    }
  }

  return (
    <div className="masked-date">
      <label
        class="placeholder-label datepicker-label"
        style={{
          zIndex: 1,
          fontSize: !!disabled || value || active || placeholderLable ? '14px' : '16px',
          top: !!disabled || value || active || placeholderLable ? '-5px' : '10px',
        }}
      >
        {label}
      </label>
      <DatePicker
        {...props}
        autoComplete={autoComplete}
        name={name}
        tabIndex={tabIndex}
        id={id}
        showOnInputClick
        onChange={onChange}
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        className={`form-control`}
        showPopperArrow={false}
        showYearDropdown
        showMonthYearPicker={true}
        // showMonthYearDropdown
        // useShortMonthInDropdown
        popperProps={{
          positionFixed: true,
        }}
        onFocus={e => {
          setPlaceholderLabel(true);
        }}
        onBlur={e => {
          setPlaceholderLabel(false);
          onBlurEvent && onBlurEvent(e);
        }}
        // popperPlacement="bottom-end"
        // popperModifiers={{
        //   offset: {
        //     enabled: true,
        //     offset: '5px, 10px',
        //   },
        //   preventOverflow: {
        //     enabled: true,
        //     escapeWithReference: false,
        //     boundariesElement: 'viewport',
        //   },
        // }}
        // highlightDates={[subDays(new Date(), 7), addDays(new Date(), 7)]}
        ref={datePickerRef}
        isClearable={!disabled}
        dateFormat={'MM/yyyy'}
        dropdownMode="scroll"
        alwaysShowMask
      />
      {touched &&
        ((error && (
          <>
            <br />
            <span className="error-text">
              {fieldName} {errorMessage || error}
            </span>
          </>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

  
export { ReduxDateInputWithoutMask, ReduxDateInput, ReduxMonthYearInput };
