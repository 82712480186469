import React, { useEffect, useState, useRef } from 'react';

const ClipboardCopy = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const CLIP_STYLES = {
    //styles, img when text is copied to clipboard
    bgCopied: { backgroundColor: '#2FE192', margin: '0' },
    topCopied: { top: '45%' },
    imgCopied: 'images/icons/tick_white.svg',

    //styles, img before when text is copied to clipboard
    bgNotCopied: { backgroundColor: 'white', margin: '0' },
    topNotCopied: { top: '39%' },
    imgNotCopied: 'images/icons/copytoclipboard.svg',
  };
  return (
    <span style={isCopied ? CLIP_STYLES.bgCopied : CLIP_STYLES.bgNotCopied}>
      <img
        src={isCopied ? CLIP_STYLES.imgCopied : CLIP_STYLES.imgNotCopied}
        onClick={handleCopyClick}
        style={isCopied ? CLIP_STYLES.topCopied : CLIP_STYLES.topNotCopied}
      />
    </span>
  );
};

export default ClipboardCopy;
