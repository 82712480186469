import React, { Component } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../../component/custom-redux-components';
import Validator from '../../../Validation';

class Date extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { QuestionFormatId, name, answerOptions } = this.props;
    const { SurveyNumericInputCondition } = answerOptions || {};
    return (
      <div class="row mb-3 options-row">
        <span
          style={{
            border: '1px solid #AAACB5',
            borderRadius: '4px',
            height: '70px',
            width: '100%',
            padding: '10px',
            color: '#A1A5B7',
          }}
        >
          MM-DD-YYYY
        </span>
      </div>
    );
  }
}
export default Date;
