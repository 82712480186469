import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxInput,
} from '../component/custom-redux-components';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  getFormSyncErrors,
  isDirty,
} from 'redux-form';
import { Delete_Sucess_Msg } from '../app-config';
import {
  getContractTemplate,
  upsertContractTemplate,
  resetGetContractTemplate,
  resetUpsertContractTemplate,
  listCombos,
} from '../store/actions';
import { stringDate } from '../util/date';
import AntdModal from '../Components/CustomModal';
import CustomModal from '../core/modal/index';
import { ProcessCombos } from '../store/comboConfig';
import moment from 'moment';

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 >= date1) {
    } else {
      return 'has to be greater than Start Date';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

class NewTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newContract: {
        // minorThemes: [''],
        // goals: [''],
        // bibleMemorizations: [''],
        // bibleReadings: [''],
        // lessons: [''],
        // specialProjects: [''],
        // cQProjectIds: [{}],
      },
      isModelOpen: false,
      header: null,
    };
  }

  componentDidMount() {
    var result = ProcessCombos(
      'ContractLadderAssociation,ContractMajorTheme,ContractCharacterQuality,ContractCQProject,CenterCourses',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }

    var contractTemplateId = null;
    if (this.getContractTemplateId() && this.getContractTemplateId() != 'null') {
      contractTemplateId = this.getContractTemplateId();
      this.props.dispatch(
        getContractTemplate({
          // StudentId: this.getStudentId(),
          ContractTemplateId: contractTemplateId,
        }),
      );
    }
  }
  mapCharacterData = data => {
    let cqData = [];
    if (data) {
      for (let item of data) {
        let obj = {};
        obj.studentContractCharacterQualityId = item.StudentContractCharacterQualityId;
        obj.characterQualityIds = item.CharacterQualityId;
        obj.cQProjectIds = item.CQProjectId;
        cqData.push(obj);
      }
    }
    return cqData;
  };
  mapCourseData = data => {
    let courseData = [];
    let dataArr = data && data.split(',');
    if (dataArr) {
      for (let item of dataArr) {
        let obj = {};
        obj.courseIds = parseInt(item);
        courseData.push(obj);
      }
    }
    return courseData;
  };
  componentWillReceiveProps({ templateData, updateSuccess, deleteSuccess }) {
    if (updateSuccess) {
      this.toggle();
      this.props.dispatch(resetUpsertContractTemplate());
    }
    if (deleteSuccess) {
      this.toggle(Delete_Sucess_Msg);
      // this.props.dispatch(resetDeleteContract());
    }
    if (templateData && templateData.ContractTemplateId && this.getContractTemplateId() > 0) {
      const data = {
        templateName: templateData.TemplateName,
        description: templateData.Description,
        majorThemeId: templateData.MajorThemeId,
        minorThemes: (templateData.MinorThemes && templateData.MinorThemes.split('|')) || [''],
        goals: (templateData.Goals && templateData.Goals.split('|')) || [''],
        bibleMemorizations: (templateData.BibleMemorizations &&
          templateData.BibleMemorizations.split('|')) || [''],
        bibleReadings: (templateData.BibleReading && templateData.BibleReading.split('|')) || [''],
        lessons: (templateData.Lessons && templateData.Lessons.split('|')) || [''],
        specialProjects: (templateData.SpecialProjects &&
          templateData.SpecialProjects.split('|')) || [''],
        // characterQualityIds: templateData.CharacterQualityIds,
        // courseIds: templateData.CourseIds,
        courseIds: this.mapCourseData(templateData.CourseIds),
        ladderAssociationId: templateData.LadderAssociationId,
        // cQProjectIds: templateData.CQProjectIds,
        // cQProjectIds: (templateData.cQProjectIds && templateData.cQProjectIds.split('|')) || [''],
        cqProject: this.mapCharacterData(templateData.CharacterQuality),

        adminNotes: templateData.AdminNotes,
        recipients: templateData.Recipients,
        startDate: templateData.StartDate,
        dueDate: templateData.DueDate ? moment(templateData.DueDate).format('YYYY-MM-DD') : '',
        studentId: templateData.StudentId,
        msg: templateData.Msg,
        isPublic: templateData.IsPublic,
      };
      this.setState({ newContract: data });
    }
  }

  getStudentId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId' || 'StudentId');
  };

  getContractTemplateId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('contractTemplateId' || 'ContractTemplateId');
  }

  getViewMode = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('viewMode') == 'true' ? true : false;
  };

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.courseIds);
    }
    return array.toString();
  };

  onSubmit(values) {
    const { isClone } = this.props;
    let data = {
      ContractTemplate: [
        {
          TemplateName: values.templateName,
          Description: values.description,
          LadderAssociationId: values.ladderAssociationId,
          MajorThemeId: values.majorThemeId,
          MinorThemes: values.minorThemes && values.minorThemes.toString().replace(/,/g, '|'),
          Goals: values.goals && values.goals.toString().replace(/,/g, '|'),
          BibleMemorizations:
            values.bibleMemorizations && values.bibleMemorizations.toString().replace(/,/g, '|'),
          BibleReading: values.bibleReadings && values.bibleReadings.toString().replace(/,/g, '|'),
          Lessons: values.lessons && values.lessons.toString().replace(/,/g, '|'),
          SpecialProjects:
            values.specialProjects && values.specialProjects.toString().replace(/,/g, '|'),
          // CharacterQualityIds: this.getStringType(values.characterQualityIds),
          CourseIds: this.getStringType(values.courseIds),
          // CQProjectIds: this.getStringType(values.cQProjectIds),
          AdminNotes: values.adminNotes,
          Recipients: values.recipients,
          StartDate: stringDate(values.startDate),
          DueDate: stringDate(values.dueDate),
          Msg: values.msg,
          IsPublic: values.isPublic,
          CharacterQuality:
            values.cqProject &&
            values.cqProject.map(item => {
              if (item == undefined) return;
              else
                return {
                  StudentContractCharacterQualityId:
                    (item.studentContractCharacterQualityId &&
                      item.studentContractCharacterQualityId) ||
                    null,
                  CharacterQualityId: item.characterQualityIds,
                  CQProjectId: item.cQProjectIds,
                };
            }),
        },
      ],
    };
    data = {
      ContractTemplateId: isClone
        ? null
        : this.getContractTemplateId() > 0
        ? this.getContractTemplateId()
        : null,
      Json: JSON.stringify(data),
    };
    this.props.dispatch(upsertContractTemplate(data));
  }

  toggle = header => this.setState(ps => ({ isModelOpen: !ps.isModelOpen, header }));

  componentWillUnmount() {
    this.props.dispatch(resetGetContractTemplate());
  }

  render() {
    return (
      <>
        <ReduxNewContract
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.state.newContract}
          getContractIdAPI={this.getContractId}
          getStudentIdAPI={this.getStudentId}
          // getContractId={this.getContractId()}
          // getStudentId={this.getStudentId()}
          viewMode={this.props.isView}
          isClone={this.props.isClone}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header || 'Your changes have been saved sucessfully'}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/templates?studentId=' + this.getStudentId());
          }}
          yesText="OK"
          hideCancel={true}
        />
      </>
    );
  }
}

const MinorTheme = ({ fields, viewMode }) => (
  <div className="row">
    <div className="col-12 col-md-6 col-lg-5 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-9 field-array-item">
              <div class="form-group">
                <Field
                  name={item}
                  class="form-control"
                  type="text"
                  disabled={viewMode}
                  component={ReduxInput}
                />
                <label class="placeholder-label">Minor Theme(s)</label>
                <span class="small-info">Doubles as the “name” of growth plan</span>
              </div>
            </div>
            <a
              class="col-3 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              {!viewMode && (
                <>
                  <i class="fas fa-trash" /> Delete
                </>
              )}
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Minor Theme Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'MINOR THEME' : 'ANOTHER MINOR THEME'}
      </button>
    </div>
  </div>
);

const Goals = ({ fields, viewMode }) => (
  <div className="row">
    <div className="col-12 col-md-6 col-lg-5 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-9 field-array-item">
              <div class="form-group">
                <Field
                  name={item}
                  class="form-control"
                  type="text"
                  disabled={viewMode}
                  component={ReduxInput}
                />
                <label class="placeholder-label">Goal(s)</label>
                <span class="small-info">Provide a specific list of goals to be achieved</span>
              </div>
            </div>
            <a
              class="col-3 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              {!viewMode && (
                <>
                  <i class="fas fa-trash" /> Delete
                </>
              )}
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Goal Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'Goal' : 'Another Goal'}
      </button>
    </div>
  </div>
);

const BibleMemo = ({ fields, viewMode }) => (
  <div className="row">
    <div className="col-12 col-md-6 col-lg-5 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-9 field-array-item">
              <div class="form-group">
                <Field
                  name={item}
                  class="form-control"
                  type="text"
                  disabled={viewMode}
                  component={ReduxInput}
                />
                <label class="placeholder-label">Bible Memorization</label>
                <span class="small-info">Two or more scripture verses</span>
              </div>
            </div>
            <a
              class="col-3 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              {!viewMode && (
                <>
                  <i class="fas fa-trash" /> Delete
                </>
              )}
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Bible Memorization Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'Bible Memorization' : 'Another Bible Memorization'}
      </button>
    </div>
  </div>
);

const BibleReading = ({ fields, viewMode }) => (
  <div className="row">
    <div className="col-12 col-md-6 col-lg-5 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-9 field-array-item">
              <div class="form-group">
                <Field
                  name={item}
                  class="form-control"
                  type="text"
                  disabled={viewMode}
                  component={ReduxInput}
                />
                <label class="placeholder-label">Bible Reading</label>
                <span class="small-info">One or more books</span>
              </div>
            </div>
            <a
              class="col-3 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              {!viewMode && (
                <>
                  <i class="fas fa-trash" /> Delete
                </>
              )}
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Bible Reading Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'Bible Reading' : 'Another Bible Reading'}
      </button>
    </div>
  </div>
);

const Lessons = ({ fields, viewMode }) => (
  <div className="row">
    <div className="col-12 col-md-6 col-lg-5 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-9 field-array-item">
              <div class="form-group">
                <Field
                  name={item}
                  class="form-control"
                  type="text"
                  disabled={viewMode}
                  component={ReduxInput}
                />
                <label class="placeholder-label">Personal Reading</label>
                <span class="small-info">One or more personal reading</span>
              </div>
            </div>
            <a
              class="col-3 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              {!viewMode && (
                <>
                  <i class="fas fa-trash" /> Delete
                </>
              )}
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Personal Reading Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'PERSONAL READING' : 'ANOTHER PERSONAL READING'}
      </button>
    </div>
  </div>
);

const SpecialProject = ({ fields, viewMode }) => (
  <div className="row mb-5">
    <div className="col-12 col-md-6 col-lg-5 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-9 field-array-item">
              <div class="form-group">
                <Field
                  name={item}
                  class="form-control"
                  type="text"
                  disabled={viewMode}
                  component={ReduxInput}
                />
                <label class="placeholder-label">Special Project(s)</label>
                <span class="small-info">Special project (optional)</span>
              </div>
            </div>
            <a
              class="col-3 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              {!viewMode && (
                <>
                  <i class="fas fa-trash" /> Delete
                </>
              )}
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Special Projects Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'Special Projects' : 'Another Special Projects'}
      </button>
    </div>
  </div>
);
const CQProject = ({ fields, ContractCharacterQuality, ContractCQProject, viewMode }) => (
  <div className="row">
    <div className="col-9 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row align-items-baseline">
            <div key={index} className="col-10 field-array-item">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    {/* <label class="placeholder-label">Character Qualities</label> */}
                    <Field
                      name={`${item}.characterQualityIds`}
                      class="custom-select enrollment-select"
                      component={ReduxSelect}
                      placeholder="Character Qualities"
                      options={ContractCharacterQuality}
                      disabled={viewMode}
                    />
                    <span class="small-info">Select the character trait focus</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    {/* <label class="placeholder-label">CQ Projects</label> */}
                    <Field
                      name={`${item}.cQProjectIds`}
                      class="custom-select enrollment-select"
                      component={ReduxSelect}
                      placeholder="CQ Projects"
                      options={ContractCQProject}
                      disabled={viewMode}
                    />

                    <span class="small-info">
                      Select a project type to assist in character trait development
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <a
              class="col-2 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>CQ Project Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'CQ Project' : 'ANOTHER CQ Project'}
      </button>
    </div>
  </div>
);
const Courses = ({ fields, courseOptions, viewMode }) => (
  <div className="row">
    <div className="col-12 col-md-6 col-lg-5 pr-xl-5 my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row ">
            <div key={index} className="col-9 field-array-item">
              <div class="form-group">
                <Field
                  name={`${item}.courseIds`}
                  class="custom-select enrollment-select"
                  component={ReduxSelect}
                  placeholder="Lessons"
                  options={courseOptions}
                />
                <span class="small-info">Select lesson(s)</span>
              </div>
            </div>
            <a
              class="col-2 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              {!viewMode && (
                <>
                  <i class="fas fa-trash" /> Delete
                </>
              )}
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Lesson Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button disabled={viewMode} type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'Lesson' : 'ANOTHER Lesson'}
      </button>
    </div>
  </div>
);
let NewContract = ({
  isDirty,
  isPristine,
  onSaveClick,
  getContractId,
  history,
  handleSubmit,
  ContractMajorTheme,
  ContractCharacterQuality,
  ContractCQProject,
  getStudentIdAPI,
  dispatch,
  viewMode,
  CourseOptions,
  ContractLadderAssociation,
}) => {
  return (
    <form onSubmit={handleSubmit(onSaveClick)} autocomplete="off">
      <div class="main enrollment-page mb-5">
        <div class="container-fluid">
          <div class="row top-table-search mt-5 mb-3 align-items-end">
            <div class="col-12 col-md-6 col-lg-3">
              {isDirty ? (
                <AntdModal
                  modalWidth={420}
                  ModalButton={({ onClick }) => (
                    <a class="back-link" style={{ cursor: 'pointer' }} onClick={onClick}>
                      <img src="images/back-arrow.svg" /> Back
                    </a>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div className="modal-content border-0">
                      <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                        {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                        <h4 className="modal-title col-12">
                          Are you sure you want to exit this page? Any unsaved changes will be lost.
                        </h4>
                      </div>
                      <div className="modal-footer justify-content-center border-top-0 pb-4 pt-2">
                        <button
                          type="button"
                          className="btn btn-outline-primary py-1"
                          data-dismiss="modal"
                          onClick={onCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn py-1"
                          onClick={() => {
                            onOk();
                           history.goBack();
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  )}
                />
              ) : (
                <a class="back-link" onClick={() => history.goBack()}>
                  <img src="images/back-arrow.svg" /> Back
                </a>
              )}
              {/* <a class="back-link" onClick={() => history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a> */}
              {/* <AntdModal
                modalWidth={420}
                ModalButton={({ onClick }) => (
                  <a class="back-link" style={{ cursor: 'pointer' }} onClick={onClick}>
                    <img src="images/back-arrow.svg" /> Back
                  </a>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div className="modal-content border-0">
                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      <h4 className="modal-title col-12">
                        Are you sure you want to exit this page? Any unsaved changes will be lost.
                      </h4>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pb-4 pt-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary py-1"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn py-1"
                        onClick={() => {
                          onOk();
                          history.goBack();
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
              /> */}
              <div class="d-flex align-items-center flex-wrap">
                <h3 class="page-title mb-3 mb-md-0 ">
                  {/* {getContractId > 0 ? 'Edit Contract' : 'New Contract'}{' '} */}
                  {/* New Template */}
                  {viewMode ? 'View Template' : 'New Template'}
                </h3>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-9 text-right">
              {!viewMode && (
                <>
                  {isDirty ? (
                    <AntdModal
                      modalWidth={420}
                      ModalButton={({ onClick }) => (
                        <button
                          type="button"
                          className="btn btn-outline-primary black-btn"
                          data-dismiss="modal"
                          onClick={onClick}
                        >
                          Cancel
                        </button>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div className="modal-content border-0">
                          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                            <h4 className="modal-title col-12">
                              Are you sure you want to exit this page? Any unsaved changes will be
                              lost.
                            </h4>
                          </div>
                          <div className="modal-footer justify-content-center border-top-0 pb-4 pt-2">
                            <button
                              type="button"
                              className="btn btn-outline-primary py-1"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn py-1"
                              onClick={() => {
                                onOk();
                                // history.goBack();
                                history.push('/templates?studentId=' + getStudentIdAPI());
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  ) : (
                    <Link
                      to={'/templates?studentId=' + getStudentIdAPI()}
                      class="btn btn-outline-primary black-btn"
                    >
                      Cancel
                    </Link>
                  )}
                </>
                // <Link
                //   to={'/templates?studentId=' + getStudentIdAPI()}
                //   class="btn btn-outline-primary black-btn"
                // >
                //   Cancel
                // </Link>
                // <AntdModal
                //   modalWidth={420}
                //   ModalButton={({ onClick }) => (
                //     <button
                //       type="button"
                //       className="btn btn-outline-primary black-btn"
                //       data-dismiss="modal"
                //       onClick={onClick}
                //     >
                //       Cancel
                //     </button>
                //   )}
                //   ModalContent={({ onOk, onCancel }) => (
                //     <div className="modal-content border-0">
                //       <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                //         {/* <button
                //         type="button"
                //         className="close"
                //         data-dismiss="modal"
                //         onClick={onCancel}
                //       >
                //         &times;
                //       </button> */}
                //         <h4 className="modal-title col-12">
                //           Are you sure you want to exit this page? Any unsaved changes will be lost.
                //         </h4>
                //       </div>
                //       <div className="modal-footer justify-content-center border-top-0 pb-4 pt-2">
                //         <button
                //           type="button"
                //           className="btn btn-outline-primary py-1"
                //           data-dismiss="modal"
                //           onClick={onCancel}
                //         >
                //           Cancel
                //         </button>
                //         <button
                //           type="button"
                //           className="btn py-1"
                //           onClick={() => {
                //             onOk();
                //             // history.goBack();
                //             history.push('/templates?studentId=' + getStudentIdAPI());
                //           }}
                //         >
                //           Ok
                //         </button>
                //       </div>
                //     </div>
                //   )}
                // />
              )}
              <button disabled={viewMode} type="submit" class="btn">
                Save
              </button>
            </div>
          </div>
          <div class="row mb-4 top-contract-row">
            <div class="col-12">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form">
                  <div class="row">
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          name="templateName"
                          type="text"
                          errorMessage="Required"
                          component={ReduxInput}
                          disabled={viewMode}
                          validate={Validator.required}
                          label={'Template Name'}
                          fieldName={'Template Name'}
                        />
                        <label class="placeholder-label">Template Name*</label>
                        <span class="small-info">
                          A good practice is to make this the same thing as the Minor Theme.
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          name="startDate"
                          type="text"
                          class="form-control datepicker hasDatepicker"
                          id="dp1568900339471"
                          errorMessage="Start Date Required"
                          component={ReduxDateInput}
                          disabled={viewMode}
                          validate={Validator.required}
                          label="Start Date*"
                          autocomplete="off"
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                        <span class="small-info">Date contract is to begin</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          name="dueDate"
                          type="text"
                          class="form-control datepicker hasDatepicker"
                          id="dp1568900339472"
                          // errorMessage="Due Date Required"
                          component={ReduxDateInput}
                          disabled={viewMode}
                          label="Due Date*"
                          fieldName={'Due Date'}
                          validate={[Validator.required, Validator.endDate]}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                        <span class="small-info">Expected date of completion</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5 mb-2">
                      <div class="form-group">
                        <Field
                          name="description"
                          type="textarea"
                          errorMessage="Required"
                          disabled={viewMode}
                          component={ReduxInput}
                          // validate={Validator.required}
                        />
                        <label class="placeholder-label">Template Description</label>
                        <span class="small-info">
                          Please write a detailed explanation for this template.
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5 mb-2">
                      <div class="form-group">
                        <label class="custom-control-label" for>
                          <Field
                            name="isPublic"
                            type="text"
                            component={ReduxToggle}
                            disabled={viewMode}
                          />
                          Make it public
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row  mb-5">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form">
                  <div class="row pb-5 pl-3 pr-3 pt-3">
                    <div class="col-12 p-0">
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="ladderAssociationId"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Ladder Association*"
                              options={ContractLadderAssociation}
                              validate={Validator.required}
                              disabled={viewMode}
                              fieldName={'Ladder Association'}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="majorThemeId"
                              type="text"
                              component={ReduxSelect}
                              placeholder="Major Theme*"
                              validate={Validator.required}
                              options={ContractMajorTheme}
                              disabled={viewMode}
                              fieldName={'Major Theme'}
                            />
                            <span class="small-info">Select general theme</span>
                          </div>
                        </div>
                      </div>
                      <FieldArray name="minorThemes" viewMode={viewMode} component={MinorTheme} />
                      <FieldArray name="goals" viewMode={viewMode} component={Goals} />
                      <FieldArray
                        name="bibleMemorizations"
                        viewMode={viewMode}
                        component={BibleMemo}
                      />
                      <FieldArray
                        name="bibleReadings"
                        viewMode={viewMode}
                        component={BibleReading}
                      />
                      <FieldArray name="lessons" viewMode={viewMode} component={Lessons} />
                      {/* Course Start Here */}
                      {/* <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="courseIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              viewMode={viewMode}
                              placeholder="Lessons"
                              options={CourseOptions}
                              isMulti
                            />
                            <span class="small-info">Select Lesson(s)</span>
                          </div>
                        </div>
                      </div> */}
                      <FieldArray
                        name="courseIds"
                        component={Courses}
                        viewMode={viewMode}
                        courseOptions={CourseOptions}
                      />
                      <FieldArray
                        name="cqProject"
                        component={CQProject}
                        viewMode={viewMode}
                        ContractCharacterQuality={ContractCharacterQuality}
                        ContractCQProject={ContractCQProject}
                      />
                      {/* Course Start Here */}
                      {/* <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="characterQualityIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              viewMode={viewMode}
                              placeholder="Character Qualities"
                              options={ContractCharacterQuality}
                              isMulti
                            />
                            <span class="small-info">Select the character trait focus.</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="cQProjectIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              viewMode={viewMode}
                              placeholder="CQ Projects"
                              isMulti
                              options={ContractCQProject}
                            />

                            <span class="small-info">
                              Select a project type to assist in character trait development.
                            </span>
                          </div>
                        </div>
                      </div> */}
                      <FieldArray
                        name="specialProjects"
                        viewMode={viewMode}
                        component={SpecialProject}
                      />

                      <div class="row">
                        <div class="col-12 col-md-12 col-lg-8 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="adminNotes"
                              class="form-control textarea-box"
                              type="textarea"
                              rows="4"
                              component={ReduxInput}
                              disabled={viewMode}
                              // validate={Validator.required}
                            />
                            <label class="placeholder-label">Administrative Notes</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4 pr-xl-5 pt-3 pb-3 mb-2">
                        <div class="custom-control-input">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="notify-checkbox"
                          />
                          <label class="custom-control-label" for="notify-checkbox">
                            Notify
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 text-right">
              {getContractId > 0 && (
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <a
                      class="deactivate-link"
                      data-toggle="modal"
                      data-target="#deactivate-staff-model"
                      onClick={onClick}
                    >
                      <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                    </a>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to Deactivate <br />
                          this contract?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn black-btn"
                          onClick={() => {
                            onCancel();
                            history.push('/templates?studentId=' + getStudentIdAPI());
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            onOk();
                            // dispatch(
                            //   deleteContract({
                            //     StudentId: getStudentId,
                            //     StudentContractId: getContractId,
                            //   }),
                            // );
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const { combos } = state;
  return {
    ContractLadderAssociation: !combos.data ? [] : combos.data.ContractLadderAssociation,
    ContractMajorTheme: !combos.data ? [] : combos.data.ContractMajorTheme,
    ContractCharacterQuality: !combos.data ? [] : combos.data.ContractCharacterQuality,
    ContractCQProject: !combos.data ? [] : combos.data.ContractCQProject,
    CourseOptions: (combos.data && combos.data.CenterCourses) || [],
    isDirty: isDirty('new-contract')(state),
  };
};

const ReduxNewContract = connect(mapStateToProps)(
  reduxForm({
    form: 'new-contract',
    enableReinitialize: true,
  })(withRouter(NewContract)),
);

const mapStateToParent = ({ get_contract_template, upsert_contract_template, delete_contract }) => {
  const { data, isFetching, error } = get_contract_template;
  const { data: updateData } = upsert_contract_template;
  const { data: deleteData } = delete_contract;
  let updateSuccess = false,
    deleteSuccess = false;
  if (updateData && updateData.success) updateSuccess = true;
  if (deleteData && deleteData.success) deleteSuccess = true;
  return {
    templateData: (data && data[0]) || {},
    isFetching: isFetching,
    updateSuccess,
  };
};

export default connect(mapStateToParent)(NewTemplateForm);
