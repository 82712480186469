import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Popover, Cascader, Icon, Checkbox } from 'antd';
// import RolesPermissions from '../../PopupModals/RolesPermissions';
import { staffPermission, updateStaffPermission, loadDefaultValues } from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';
import PageTitle from '../../../Components/PageTitle';
class RollesPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      RolesPermissions: [],
    };
  }

  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };

  componentDidMount() {
    this.props.dispatch(staffPermission({ staffId: this.getStaffId() }));
  }
  componentWillReceiveProps({ RolesPermissions, isUpdated }) {
    if (RolesPermissions) {
      this.setState({
        RolesPermissions,
      });
    }
    if (isUpdated && isUpdated != this.props.isUpdated) {
      this.setState(
        {
          Msg: 'Saved successfully.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              Msg: '',
            });
            this.props.dispatch(loadDefaultValues({}));
          }, 2000);
        },
      );

      // this.props.dispatch(reset('inviteForm'));
    }
  }
  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = -1;
    }
    return staffId;
  };
  render() {
    const { isFetching, onOk, onCancel, isDeactivated } = this.props;
    const { RolesPermissions, Msg } = this.state;

    return (
      <div class="mt-5 mx-2">
        <PageTitle TitleText="Roles & Permissions" />
        <div class="content-area container-fluid">
          {/* <Loader2 loading={isFetching} /> */}
          <div class="row filter-modal">
            {RolesPermissions &&
              RolesPermissions.map((item, index) => {
                return (
                  <>
                    <div class="col-12 roles-permissions-modal">
                      <span className="rolls">
                        {index + 1}. {item.Description}
                      </span>
                      {this.state.index === index && (
                        <h6
                          style={{
                            fontSize: '14px',
                            color: 'rgb(18, 196, 117)',
                            marginLeft: '1.5rem',
                            display: 'inline-block',
                          }}
                        >
                          {Msg}
                        </h6>
                      )}
                      <div className="permissions-block">
                        {item.Permission &&
                          item.Permission.map((permission, rollIndex) => {
                            return (
                              <div>
                                <Checkbox
                                  disabled={permission.IsPermissionReadOnly || isDeactivated}
                                  // className="permissions"
                                  className={
                                    permission.IsStaffPermissionEnabled
                                      ? 'permissions-active'
                                      : 'permissions'
                                  }
                                  name="permissions"
                                  checked={permission.IsStaffPermissionEnabled}
                                  value={permission.Name}
                                  onClick={e => {
                                    let newPermission = permission;
                                    newPermission.IsStaffPermissionEnabled = e.target.checked;
                                    let result = item;
                                    result.Permission[rollIndex] = newPermission;
                                    let data = RolesPermissions;
                                    data[index] = result;
                                    this.setState({
                                      index: index,
                                      RolesPermissions: data,
                                    });
                                    this.props.dispatch(
                                      updateStaffPermission({
                                        staffId: this.getStaffId(),
                                        jsonInput: JSON.stringify({
                                          StaffPermission: [
                                            {
                                              StaffPermissionId: permission.StaffPermissionId,
                                              PermissionId: permission.PermissionId,
                                              IsStaffPermissionEnabled: e.target.checked,
                                            },
                                          ],
                                        }),
                                      }),
                                    );
                                  }}
                                >
                                  {permission.Name}
                                </Checkbox>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_staff_permission, update_staff_permission, form_data_get }) => {
  const { data: RolesPermissionsData, isFetching, error } = get_staff_permission;
  const { data } = form_data_get;

  let RolesPermissions = null;
  let isUpdated = false;
  let isDeactivated = false;

  if (RolesPermissionsData && RolesPermissionsData.StaffPermission) {
    RolesPermissions = RolesPermissionsData.StaffPermission || [];
  }
  if (update_staff_permission && update_staff_permission.data) {
    isUpdated = update_staff_permission.data.success;
  }
  if (data && data.Staff[0]) {
    isDeactivated = data.Staff[0].IsDeactivated;
  }
  return {
    RolesPermissions: RolesPermissions,
    isFetching,
    isUpdated,
    isDeactivated,
  };
};
export default connect(mapStateToProps)(withRouter(RollesPermissions));
