import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import moment from 'moment';
import { Table, Button } from 'antd';
import { PlusCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import { timeConvert } from '../../../../../util/time';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  ReduxTimePicker,
  CheckboxGroup,
} from '../../../../../component/custom-redux-components';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import { ProcessCombos } from '../../../../../store/comboConfig';
import {
  upsertServiceLine,
  listServiceLine,
  getServiceCode,
  getServiceLine,
  medicateCombos,
  upsertGroupServiceLine,
} from '../../../../../store/actions';
import PersistTableSort from '../../../../../component/persistTableSort';
import SubmitButton from '../../../../../Components/submitButton';

class ServiceLineDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Attendees: [],
      ServiceLineData: {},
      ServiceInfo: {},
      expandedRowKeys: [],
      ServiceDetails: [],
      supervisorId: null,
      serviceCode: null,
    };
  }
  componentDidMount() {
    var result = ProcessCombos('RCM_ServiceCode,Supervisor,UnitOfMeasurement');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  componentWillReceiveProps({ ServiceLineData, ServiceInfo, ServiceCodeInfo, isCreated }) {
    if (isCreated && isCreated != this.props.isCreated) {
      this.setState({
        expandedRowKeys: [],
      });
    }
    if (ServiceLineData) {
      this.setState({
        serviceCode: parseInt(ServiceLineData.ServiceCodeId),
        supervisorId: ServiceLineData.SupervisorId,
        ServiceLineData: ServiceLineData,
        Attendees: ServiceLineData.Attendees,
        GroupAppointmentId: ServiceLineData.GroupAppointmentId,
      });
    }
    if (ServiceInfo && JSON.stringify(ServiceInfo) != JSON.stringify(this.props.ServiceInfo)) {
      this.setState({
        ServiceInfo: ServiceInfo,
        ServiceDetails: JSON.parse(ServiceInfo.ServiceDetails),
      });
    }
    if (
      ServiceCodeInfo &&
      JSON.stringify(ServiceCodeInfo) != JSON.stringify(this.props.ServiceCodeInfo)
    ) {
      let code = this.props.existingServiceCodesRows;
      if (code && code.length > ServiceCodeInfo.ReactIndex) {
        code[ServiceCodeInfo.ReactIndex].ServiceCodeId = ServiceCodeInfo.ServiceCodeId;
        code[ServiceCodeInfo.ReactIndex].UnitOfMeasurementId = ServiceCodeInfo.UnitOfMeasurementId;
        code[ServiceCodeInfo.ReactIndex].UnitsId = ServiceCodeInfo.UpdatedBy;
        code[ServiceCodeInfo.ReactIndex].ServiceCodeDescription =
          ServiceCodeInfo.ServiceCodeDescription;
      }
      this.setState({
        ServiceDetails: code,
      });
    }
  }
  onTableRowExpand = (expanded, record) => {
    let keys = [];
    if (expanded) {
      keys.push(record.key); // I have set my record.key as row key.
    }

    this.setState({ expandedRowKeys: keys, record });
  };
  handleServiceCode = serviceCode => {
    this.setState({ serviceCode });
    this.props.dispatch(
      upsertGroupServiceLine({
        json: JSON.stringify({
          GroupServiceLine: [
            {
              GroupAppointmentId: this.state.GroupAppointmentId,
              ServiceCodeId: serviceCode,
              // UnitsId: '',
              // DiagnosisListId: '',
              SupervisorId: this.state.supervisorId,
            },
          ],
        }),
      }),
    );
  };

  handleSupervisor = supervisorId => {
    this.setState({ supervisorId });
    this.props.dispatch(
      upsertGroupServiceLine({
        json: JSON.stringify({
          GroupServiceLine: [
            {
              GroupAppointmentId: this.state.GroupAppointmentId,
              ClientId: this.props.ClientId,
              ServiceCodeId: this.state.serviceCode,
              // UnitsId: '',
              // DiagnosisListId: '',
              SupervisorId: supervisorId,
            },
          ],
        }),
      }),
    );
  };
  deleteMoreCode = index => {
    let ServiceDetails = this.state.ServiceDetails;
    ServiceDetails.splice(index, 1);
    this.setState({
      ServiceDetails,
    });
  };
  render() {
    const { handleSubmit, toggleView, ServiceCodeList, Supervisor } = this.props;
    const { Attendees, ServiceLineData, ServiceInfo } = this.state;
    const {
      AttendeesCount,
      GroupName,
      SessionDate,
      DurationInMin,
      SessionTime,
      EndTime,
      StartTime,
      GroupAppointmentId,
    } = ServiceLineData;
    const columns = [
      {
        title: 'Patient name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },

      {
        title: 'Start/End Time',
        dataIndex: 'SessionTime',
        key: 'SessionTime',
        sorter: (a, b) => isNumericComparer(a.SessionTime, b.SessionTime),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Supervisor',
        dataIndex: 'SupervisorName',
        key: 'SupervisorName',
        sorter: (a, b) => isComparer(a.SupervisorName, b.SupervisorName),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Service Code(s)',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      // {
      //   title: 'Action',
      //   // width: 300,
      // },
    ];
    return (
      <div className="row">
        <div className="col-12">
          <div className="group-service-detail">
            <div className="service-detail-header">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  onClick={() => {
                    toggleView();
                  }}
                  className="text-left d-flex"
                  style={{ cursor: 'pointer' }}
                >
                  <img src="images/back-arrow.svg" /> Back to Group Service
                </div>
              </div>
              <div className="service-header">
                <div className="row">
                  <div className="col-2">
                    <h6>{GroupName}</h6>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <span className="text">Start Time</span>
                        <br />
                        <span className="value">{StartTime}</span>
                      </div>
                      <div className="col-6">
                        <span className="text">End Time</span>
                        <br />
                        <span className="value">{EndTime}</span>
                      </div>
                      <div className="col-6">
                        <span className="text">Session Date</span>
                        <br />
                        <span className="value">{SessionDate}</span>
                      </div>

                      <div className="col-6">
                        <span className="text">Duration</span>
                        <br />
                        <span className="value">
                          {DurationInMin && timeConvert(DurationInMin)}
                          {/* {moment
                            .utc(moment.duration(DurationInMin * 60, 'seconds').asMilliseconds())
                            .format('HH:mm')}{' '} */}
                        </span>
                        {/* <span>Hours</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div class="form-group call-schedule">
                      <ReduxSelect
                        options={ServiceCodeList}
                        onChange={this.handleServiceCode}
                        className="basic-single"
                        classNamePrefix="select"
                        value={this.state.serviceCode}
                        placeholder="Service Code"
                      />
                    </div>
                    <div class="form-group mb-2">
                      <ReduxSelect
                        options={Supervisor}
                        onChange={this.handleSupervisor}
                        className="basic-single"
                        classNamePrefix="select"
                        value={this.state.supervisorId}
                        placeholder="Supervisor"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-detail-body">
              <div className="attendees">
                <h6>Attendees {AttendeesCount && `(${AttendeesCount})`}</h6>
                <div className=" rcm-subtab-table-block">
                  <div className="">
                    <PersistTableSort
                      name="Attendees"
                      columns={columns}
                      dataSource={Attendees}
                      size={'middle'}
                      pagination={false}
                      expandedRowKeys={this.state.expandedRowKeys}
                      expandRowByClick={true}
                      expandable={{
                        onExpand: (expanded, record) => {
                          if (expanded) {
                            this.props.dispatch(
                              getServiceLine({
                                json: JSON.stringify({
                                  ServiceLine: [
                                    {
                                      ClientId: record.ClientId,
                                      GroupAppointmentId: GroupAppointmentId,
                                    },
                                  ],
                                }),
                              }),
                            );
                          }
                          this.onTableRowExpand(expanded, record);
                        },
                        expandedRowRender: record => (
                          <div>
                            <ReduxServiceLineForm
                              isFetching={this.props.isFetching}
                              // initialValues={this.props.ServiceInfo}
                              initialValues={{
                                startTime: ServiceInfo.StartTime,
                                endTime: ServiceInfo.EndTime,
                                ServiceCodes: this.state.ServiceDetails,
                                DiagnosisCode:
                                  ServiceInfo.DiagnosisListId &&
                                  ServiceInfo.DiagnosisListId.split(',') &&
                                  ServiceInfo.DiagnosisListId.split(',').map(Number),
                                SupervisorId: ServiceInfo.SupervisorId,
                              }}
                              ServiceInfo={ServiceInfo}
                              deleteMoreCode={this.deleteMoreCode}
                              ServiceDetails={this.state.ServiceDetails}
                              UnitOfMeasurement={this.props.UnitOfMeasurement}
                              GroupAppointmentId={GroupAppointmentId}
                            />
                          </div>
                        ),
                        expandIcon: ({ expanded, onExpand, record }) =>
                          expanded ? (
                            <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                          ) : (
                            <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                          ),
                        // rowExpandable: record => record.ClientName !== 'Not Expandable',
                      }}
                      // scroll={{ y: 300 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { combos, get_service_line, get_service_code } = state;
  const { data: serviceInfoData, isFetching } = get_service_line;
  const { data: serviceCodeInfoData } = get_service_code;

  let ServiceInfo = null;
  let ServiceCodeList = [];
  let Supervisor = [];
  let serviceCodeInfo = null;

  if (combos.data && combos.data['RCM_ServiceCode']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'];
    Supervisor = combos.data['Supervisor'];
  }
  if (serviceInfoData) {
    ServiceInfo = serviceInfoData || {};
  }
  if (serviceCodeInfoData && serviceCodeInfoData.ServiceCode) {
    serviceCodeInfo = serviceCodeInfoData.ServiceCode[0] || [];
  }
  const existingServiceCodesRows = FormSelector(state, 'ServiceCodes');
  return {
    ServiceCodeList: ServiceCodeList,
    ServiceCodeInfo: serviceCodeInfo,
    Supervisor: Supervisor,
    ServiceInfo,
    isFetching,
    existingServiceCodesRows,
  };
};
export default reduxForm({
  form: 'groupServiceDetail',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(ServiceLineDetail)));

const AddMoreCode = ({
  fields,
  meta: { touched, error },
  disable,
  existingRows,
  ServiceCodeList,
  ServiceCodes,
  UnitOfMeasurement,
  deleteMoreCode,
  dispatch,
}) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <>
            <div
              class="row"
              style={{
                background: '#F8F8F8',
                margin: '0px 5px',
                padding: '5px 0px',
                alignItems: 'center',
              }}
            >
              <div class="col-4">
                <div class="form-group service_line_code">
                  <Field
                    name={`${field}.ServiceCodeId`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Code`}
                    options={ServiceCodeList}
                    fieldName={'Code'}
                    disabled={disable}
                    onChange={value => {
                      dispatch(
                        getServiceCode({
                          json: JSON.stringify({
                            ServiceCode: [
                              {
                                ServiceCodeId: value,
                                ReactIndex: index,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div class="col-8">
                <div
                  style={{
                    background: '#F8F8F8',
                    // height: '80px'
                  }}
                  className="p-2"
                >
                  <p
                    style={{
                      color: '#959595',
                      fontSize: '16px',
                      marginBottom: '0px',
                      fontWeight: '400',
                    }}
                  >
                    Code Description
                  </p>
                  <p>
                    {ServiceCodes &&
                      ServiceCodes[index] &&
                      ServiceCodes[index].ServiceCodeDescription}
                  </p>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${field}.UnitOfMeasurementId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Unit of Measure`}
                    options={UnitOfMeasurement}
                    fieldName={'Unit of Measure'}
                    // disabled={true}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${field}.UnitsId`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Units'}
                    min={0}
                    max={999}
                    negativeNumberBlocked={true}
                    controlledMaxAge={true}
                    style={{ background: '#F8F8F8', paddingRight: '15px' }}
                  />
                  <label class="placeholder-label">Units</label>
                </div>
              </div>
              <div class="col-3"></div>
              <div class="col-1">
                {fields.length > 1 && (
                  <a
                    class="delete-contact"
                    onClick={() => {
                      fields.remove(index);
                      deleteMoreCode(index);
                    }}
                    disabled={disable}
                  >
                    <i class="fas fa-trash" />
                  </a>
                )}
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-1 pb-1">
          {/* <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}> */}
          <a
            class="add-contact"
            onClick={() => fields.push({})}
            disabled={disable}
            style={{ marginLeft: 'unset' }}
          >
            <label>+ Add {fields.length == 0 ? 'Code' : 'Another Code'}</label>
          </a>
        </div>
      </div>
    </>
  );
};

class ServiceLineForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineInfo: {},
    };
  }

  componentDidMount() {
    // var result = ProcessCombos('RCM_ServiceCode,Supervisor');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
    // this.props.dispatch(
    //   getServiceLine({
    //     json: JSON.stringify({
    //       ServiceLine: [
    //         {
    //           AppointmentId: 132,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }
  componentWillReceiveProps({ ServiceInfo }) {
    if (ServiceInfo && JSON.stringify(ServiceInfo) != JSON.stringify(this.props.ServiceInfo)) {
      this.setState({
        ServiceLineInfo: ServiceInfo,
      });
    }
  }
  onSaveClick = values => {
    const { DiagnosisCode, ServiceCodes, SupervisorId } = values;
    let ServiceCodeId = [];
    let unitsId = [];
    ServiceCodes.map(item => {
      ServiceCodeId.push(item.ServiceCodeId);
      unitsId.push(item.UnitsId);
    });
    let ServiceLineData = {
      json: JSON.stringify({
        ServiceLine: [
          {
            AppointmentId: this.props.AppointmentId,
            ServiceLineId: this.state.ServiceLineInfo.ServiceLineId,
            ClientId: this.state.ServiceLineInfo.ClientId,
            GroupAppointmentId: this.state.ServiceLineInfo.GroupAppointmentId,
            ServiceCodeId: ServiceCodeId.join(),
            UnitsId: unitsId.join(),
            DiagnosisListId: DiagnosisCode && DiagnosisCode.join(),
            // SupervisorId: SupervisorId,
          },
        ],
      }),
    };
    this.props.dispatch(upsertServiceLine(ServiceLineData));
  };
  render() {
    const {
      handleSubmit,
      disable,
      isFetching,
      ServiceCodeList,
      Supervisor,
      deleteMoreCode,
      isUpsertFetching,
    } = this.props;
    const { ServiceLineInfo } = this.state;
    const {
      AppointmentId,
      DiagnosisInfo,
      EndTime,
      PlaceOfServiceId,
      ProgramDescription,
      ServiceCodeDescription,
      ServiceCodeId,
      SessionDate,
      SessionTime,
      SessionType,
      StartTime,
      SupervisorId,
      SupervisorName,
      PractitionerName,
      ClientName,
      ClientId,
      PlaceOfServiceName,
      FacilityName,
      StatusDescription,
      Diagnosis,
      DurationInMin,
      ServiceDetails,
    } = ServiceLineInfo;
    const DiagnosisOption =
      (Diagnosis &&
        Diagnosis.map(item => {
          return {
            label: item.DiagnosisDictDesc,
            value: item.DiagnosisListId,
            description: item.FullDescription,
          };
        })) ||
      [];
    return (
      <form onSubmit={handleSubmit(this.onSaveClick)}>
        <Loader2 loading={isFetching} />

        <div className="row manage-attendees-header mb-3">
          <div className="col-12">
            <div className="manage-attendees">
              <div className="block mr-2">
                <span className="attendees-name">{ClientName}</span>
                <p className="attendees-id"> {ClientId}</p>
              </div>
              <div className="d-flex align-items-center mr-2">
                <img
                  src="images/icons/grommet-icons_status-good.svg"
                  width="24px"
                  className="mr-1"
                />
                {StatusDescription}
              </div>
              <div className="d-flex align-items-center mr-2">
                <img src="images/icons/location_black.svg" width="24px" className="mr-1" />
                {FacilityName}
              </div>
              <div className="block mx-2">
                <span className="name">{'POS'}</span>
                <p className="value"> {PlaceOfServiceName}</p>
              </div>
              <div className="block mr-2">
                <span className="name">{'Program'}</span>
                <p className="value"> {ProgramDescription}</p>
              </div>
              <div className="block mr-2">
                <span className="name">{'Staff'}</span>
                <p className="value"> {PractitionerName}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row service-row">
          <div className="col-12">
            <div className="row" style={{ padding: '10px 0px', margin: '0px 5px' }}>
              <div className="col-3">
                {/* <div class="form-group">
                  <Field
                    name="startTime"
                    type="text"
                    component={ReduxTimePicker}
                    fieldName={'Start Time'}
                    //   validate={Validator.required}
                    label={'Start Time*'}
                    minuteStep={5}
                  />
                </div> */}
                <div class="form-group ">
                  <div className="block">
                    <span className="name">Start Time</span>
                    <br />
                    <span className="value">{StartTime}</span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                {/* <div class="form-group">
                  <Field
                    name="endTime"
                    type="text"
                    component={ReduxTimePicker}
                    fieldName={'End Time'}
                    //   validate={[Validator.required, Validator.endTime]}
                    label={'End Time*'}
                    minuteStep={5}
                  />
                </div> */}
                <div class="form-group">
                  <div className="block">
                    <span className="name">End Time</span>
                    <br />
                    <span className="value">{EndTime}</span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div class="form-group">
                  <div className="block">
                    <span className="name">Duration</span>
                    <br />
                    <span className="value">
                      {DurationInMin && timeConvert(DurationInMin)}
                      {/* {moment
                        .utc(moment.duration(DurationInMin * 60, 'seconds').asMilliseconds())
                        .format('HH:mm')}{' '} */}
                    </span>
                    {/* <span>Hours</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <FieldArray
              name="ServiceCodes"
              // disable={disable}
              component={AddMoreCode}
              deleteMoreCode={deleteMoreCode}
              ServiceCodeList={ServiceCodeList}
              ServiceCodes={this.props.ServiceDetails}
              UnitOfMeasurement={this.props.UnitOfMeasurement}
              // existingRows={existingServiceCodesRows}
              {...this.props}
            />
          </div>
          <div class="col-12 mt-2">
            <h6>Diagnosis</h6>
            <div class="col-12">
              <div
                class="form-group"
                style={{
                  flexFlow: 'wrap',
                  display: 'flex',
                }}
              >
                <Field
                  name="DiagnosisCode"
                  component={CheckboxGroup}
                  col={24}
                  widthClassName={'w-75'}
                  options={DiagnosisOption}
                  fullDescription={true}
                />
              </div>
            </div>
          </div>
          <div className="col-12 text-right pr-4">
            {/* <button type="submit" class="btn px-3 py-1">
              Save
            </button> */}
            <SubmitButton
              className={' px-3 py-1'}
              loading={this.props.isUpsertFetching}
              value={'Save'}
            />
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToPropsForm = state => {
  const { combos, upsert_service_line } = state;

  let ServiceInfo = {};
  let ServiceCodeList = [];
  let Supervisor = [];
  let UnitOfMeasurement = [];
  const { isFetching: isUpsertFetching } = upsert_service_line;

  if (combos.data && combos.data['RCM_ServiceCode']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'];
    Supervisor = combos.data['Supervisor'];
    UnitOfMeasurement = combos.data['UnitOfMeasurement'] || [];
  }

  return {
    ServiceCodeList: ServiceCodeList,
    Supervisor,
    UnitOfMeasurement,
    isUpsertFetching,
  };
};
const ReduxServiceLineForm = reduxForm({
  form: 'serviceLineForm',
  enableReinitialize: true,
})(connect(mapStateToPropsForm)(withRouter(ServiceLineForm)));
const FormSelector = formValueSelector('serviceLineForm');
