import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button, Drawer, Tooltip } from 'antd';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
} from '../../../../../component/custom-redux-components';
import { taskClientReassign, taskClientUpdate } from '../../../../../store/actions';

let statusArray = {
  0: 'incomplete',
  4: 'pending',
  3: 'active',
  2: 'terminated',
  5: 'expired',
  1: 'delivered',
  7: 'declined',
};

class TaskList extends Component {
  constructor() {
    super();
    this.state = { selectedAppointments: {} };
  }

  // reassignTask = TaskClientId => {
  //   let data = {
  //     Task: [
  //       {
  //         TaskClientId: TaskClientId,
  //       },
  //     ],
  //   };
  //   let Data = {
  //     Json: JSON.stringify(data),
  //   };
  //   this.props.dispatch(taskClientReassign(Data));
  // };

  linkAppointment = (index, value, TaskClientId, IsContractualTask) => {
    let temp = {};
    temp[index] = value;
    this.setState(
      {
        selectedAppointments: {
          ...this.state.selectedAppointments,
          ...temp,
        },
      },
      () => {
        let data = {
          Task: [
            {
              TaskClientId: TaskClientId,
              AppointmentId: value,
              IsContractualTask: IsContractualTask,
            },
          ],
        };
        let Data = {
          Json: JSON.stringify(data),
        };
        this.props.dispatch(taskClientUpdate(Data));
      },
    );
  };

  taskMarking = (TaskClientId, IsContractualTask, StatusId) => {
    let data = {
      Task: [
        {
          TaskClientId,
          IsContractualTask,
          StatusId,
        },
      ],
    };
    let Data = {
      Json: JSON.stringify(data),
      StatusId: StatusId,
    };
    this.props.dispatch(taskClientUpdate(Data));
  };
  render() {
    const { taskListData } = this.props;
    return (
      <>
        {taskListData &&
          taskListData.map((item, index) => {
            const {
              TaskClientId,
              TaskId,
              ClientId,
              AssignedDate,
              DueDate,
              FinishedDate,
              IsTaskCompleted,
              AppointmentId,
              CreatedBy,
              Task,
              Goal,
              Milestone,
              ContractName,
              CreatedOn,
              IsContractualTask,
              Title,
              StatusId,
              StatusText,
              AppointmentLinkStatusText,
            } = item || {};
            return (
              <>
                {IsContractualTask ? (
                  <>
                    <div className="row mt-2 justify-content-between align-items-center">
                      <div className="col-12">
                        <div className="list-item">
                          <div className="row mt-3 mb-3 justify-content-between align-items-top p-4">
                            <div className="col-8">
                              {Task && (
                                <div className="item">
                                  <span className="label">Task:</span>
                                  <span className="value">
                                    {Task}
                                    <span
                                      style={{
                                        color: '#009EF7',
                                        borderRadius: '2px',
                                        background: 'rgba(70, 180, 254, 0.11)',
                                        padding: '2px 4px',
                                        marginLeft: '5px',
                                        textTransform: 'uppercase',
                                        display: 'inline-block',
                                      }}
                                    >
                                      Contractual Task
                                    </span>
                                  </span>
                                </div>
                              )}
                              {Goal && (
                                <div className="item">
                                  <span className="label">Goal:</span>
                                  <span className="value">{Goal}</span>
                                </div>
                              )}
                              {Milestone && (
                                <div className="item">
                                  <span className="label">Milestone:</span>
                                  <span className="value">{Milestone}</span>
                                </div>
                              )}
                              {CreatedBy && <div className="item">by {CreatedBy}</div>}
                              {AppointmentLinkStatusText && (
                                <div className="item">
                                  <span className="label">Appointment Link Status Text:</span>
                                  <span className="value">{AppointmentLinkStatusText}</span>
                                </div>
                              )}
                              {StatusId == 0 && !AppointmentLinkStatusText && (
                                <div className="w-50 mt-3">
                                  <ReduxSelect
                                    onChange={value => {
                                      this.linkAppointment(
                                        index,
                                        value,
                                        TaskClientId,
                                        IsContractualTask,
                                      );
                                    }}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={this.state.selectedAppointments[index]}
                                    placeholder="Select Appointment"
                                    options={this.props.clinicalTaskAppointmentDropdown}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-4 text-right">
                              {AssignedDate && <div className="item">{AssignedDate}</div>}
                              <span className="contract-status">
                                {' '}
                                {StatusText && (
                                  <span
                                    className={statusArray[StatusId]}
                                    style={{
                                      marginTop: '3px',
                                      textTransform: 'uppercase',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {StatusText}
                                  </span>
                                )}
                              </span>

                              {/* {StatusText && <div className="item">{StatusText}</div>} */}
                            </div>
                            {StatusId == 0 ? (
                              <>
                                {' '}
                                <div className="col-md-12 mt-3 mb-3 d-flex justify-content-end">
                                  <div
                                    className="btn-deny"
                                    onClick={() => {
                                      this.taskMarking(TaskClientId, IsContractualTask, 2);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Mark as Failed
                                  </div>
                                  <div
                                    className="btn-approved"
                                    onClick={() => {
                                      this.taskMarking(TaskClientId, IsContractualTask, 1);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Mark as Completed
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-2 justify-content-between align-items-center">
                      <div className="col-12">
                        <div className="list-item">
                          <div className="row mt-3 justify-content-between align-items-top p-4">
                            <div className="col-8">
                              {Title && (
                                <div className="item">
                                  <span className="label">Task:</span>
                                  <span className="value">{Title}</span>
                                </div>
                              )}
                              {AssignedDate && (
                                <div className="item">
                                  <span className="label">Assigned:</span>
                                  <span className="value">{AssignedDate}</span>
                                </div>
                              )}
                              {DueDate && (
                                <div className="item">
                                  <span className="label">Due:</span>
                                  <span className="value">{DueDate}</span>
                                </div>
                              )}
                              {AppointmentLinkStatusText && (
                                <div className="item">
                                  <span className="label">Appointment Link Status Text:</span>
                                  <span className="value">{AppointmentLinkStatusText}</span>
                                </div>
                              )}
                              {CreatedBy && <div className="item">by {CreatedBy}</div>}
                              {StatusId == 0 && !AppointmentLinkStatusText && (
                                <div className="w-50 mt-3">
                                  <ReduxSelect
                                    onChange={value => {
                                      this.linkAppointment(
                                        index,
                                        value,
                                        TaskClientId,
                                        IsContractualTask,
                                      );
                                    }}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={this.state.selectedAppointments[index]}
                                    placeholder="Select Appointment"
                                    options={this.props.clinicalTaskAppointmentDropdown}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-4 text-right">
                              {AssignedDate && (
                                <div
                                  className="item"
                                  style={{ color: '#959595', fontWeight: '700' }}
                                >
                                  {AssignedDate}
                                </div>
                              )}
                              <span className="contract-status d-block" a>
                                {' '}
                                {StatusText && (
                                  <span
                                    className={statusArray[StatusId]}
                                    style={{
                                      marginTop: '3px',
                                      textTransform: 'uppercase',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {StatusText}
                                  </span>
                                )}
                              </span>
                            </div>{' '}
                            <div className="col-md-12 mt-3 mb-3 d-flex justify-content-end">
                              <div
                                className="btn-repeat"
                                onClick={() => {
                                  // this.reassignTask(TaskClientId);
                                  // As per vishnu we have to open modal and fill that task details over there.

                                  this.props.changeDrawerState(Title);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                Repeat
                              </div>
                              {StatusId == 0 ? (
                                <>
                                  <div
                                    className="btn-deny"
                                    onClick={() => {
                                      this.taskMarking(TaskClientId, IsContractualTask, 2);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Mark as Failed
                                  </div>
                                  <div
                                    className="btn-approved"
                                    onClick={() => {
                                      this.taskMarking(TaskClientId, IsContractualTask, 1);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Mark as Completed
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}
      </>
    );
  }
}
export default withRouter(connect()(TaskList));
