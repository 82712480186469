import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';

function PowerBiReports(props) {
  const [reportName, setReportName] = useState('');
  const { report } = props;
  const { ReportName, Description, Imageurl, AnalyticReportId } = report ? report : [];
  useEffect(() => {
    if (ReportName.length > 0) {
      setReportName(
        ReportName.toLowerCase()
          .split(' ')
          .join('-'),
      );
    }
  }, [ReportName]);

  return (
    <a
      class="col-12 py-4 m-3 d-flex align-items-center px-0"
      style={{ backgroundColor: 'white', borderRadius: '10px', cursor: 'pointer' }}
      href={`#/powerBi?report-name=${reportName}&report-id=${AnalyticReportId}`}
      target="_blank"
    >
      <img
        src={`${Imageurl}`}
        style={{ width: '68px', height: '68px', borderRadius: '10px' }}
        className="col-1"
      />
      <div className="col-10 p-0">
        <h5>{ReportName}</h5>
        <p className="mb-0" style={{ color: '#7F8188' }}>
          {Description}
        </p>
      </div>

      <a
        className="mt-3 col-1 p-0"
        href={`#/powerBi?report-name=${reportName}&report-id=${AnalyticReportId}`}
        target="_blank"
        style={{ padding: '10px 40px', height: '45px', color: '#4AB0FE', fontWeight: '600' }}
      >
        View Report
      </a>
    </a>
  );
}

export default PowerBiReports;
