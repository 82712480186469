import React, { useState, useEffect } from 'react';
import { Drawer, Checkbox, Input, Modal, Button } from 'antd';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  payerPortalRequestGet,
  payerPortalRequestUpsert,
  resetPayerPortalRequestUpsert,
} from '../../../../../../store/actions';
import ConfirmationPopup from '../../../../../../Components/ConfirmationPopup';

const { TextArea } = Input;

const ContractTerms = ({ clientId, smartContractId, onClose, getLatestData, setFieldDisabled }) => {
  const [noteValue, setNoteValue] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (smartContractId) {
      getRequestData();
    }
  }, []);
  const { TermsRequest, isReqSuccess } = useSelector(
    ({
      payer_portal_request_get: { data: requestGetData, isFetching, error },
      payer_portal_request_upsert: { data: requestUpsert },
    }) => {
      return {
        TermsRequest: requestGetData && requestGetData.TermsRequest,
        isReqSuccess: requestUpsert && requestUpsert.success,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    if (smartContractId && isReqSuccess) {
      dispatch(resetPayerPortalRequestUpsert());
      getRequestData();
      getLatestData();
      onClose();
    }
  }, [isReqSuccess]);
  const getRequestData = () => {
    dispatch(
      payerPortalRequestGet({
        Json: JSON.stringify({
          Request: [
            {
              SmartContractId: smartContractId,
              Id: null,
              IsContractRequest: false,
              IsTermsRequest: true,
            },
          ],
        }),
      }),
    );
  };
  const onDeny = record => {
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30311}]} Approve
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30312}]} decline
    dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: record.PayerPortalRequestId,
              StatusId: 30312,
            },
          ],
        }),
      }),
    );
  };

  const onGoTo = (record, statusId) => {
    dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: record.PayerPortalRequestId,
              StatusId: statusId,
              ChangeNotes: noteValue,
            },
          ],
        }),
      }),
    );
    // onClose();
  };

  const addNoteChange = e => {
    setNoteValue(e.target.value);
  };

  return (
    <div className="contract-terms contract-details">
      {TermsRequest &&
        TermsRequest.map((item, index) => {
          const { RequestType, StatusId, OverAllDetails } = item;
          return (
            <div className="sections">
              <div className="section-item">
                <div className="p-1">
                  <div className="request-detail">
                    <span className="label">
                      {index + 1}. {RequestType == 30204 ? <>Terms Category</> : <>Condition</>}
                    </span>
                    {item.OverAllDetails && item.OverAllDetails[0] && (
                      <span className="" style={{ fontSize: '15px' }}>
                        {item.OverAllDetails[0].Category || item.OverAllDetails[0].Condition}
                      </span>
                    )}
                  </div>
                  <div className="request-detail">
                    <span className="label">Change From Payer</span>
                    <span className="details">{item.Note}</span>
                  </div>
                </div>
                {StatusId == 30330 ? (
                  <>
                    <div className="">
                      <span className="label">
                        Please mention the changes done by you in service code.
                      </span>
                      <span className="des-value">
                        {' '}
                        <TextArea
                          rows={5}
                          value={noteValue}
                          onChange={e => {
                            addNoteChange(e);
                          }}
                        />
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="sections-action">
                {StatusId ? (
                  <div>
                    {StatusId == 30312 && <span className="denied">Request Denied</span>}
                    {StatusId == 30311 && <span className="approved">Request Approved</span>}
                    {StatusId == 30330 && (
                      <div>
                        <button
                          type="button"
                          className="btn-approved px-4 py-2"
                          onClick={() => {
                            onGoTo(item, 30311);
                            setFieldDisabled(true);
                          }}
                        >
                          Changes Done
                        </button>
                        <ConfirmationPopup
                          className={'btn-deny px-4 py-2 mr-0'}
                          yesAction={() => {
                            onDeny(item);
                          }}
                          buttonText="Deny"
                          title="Are you sure you want to deny this request."
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary px-4 py-2"
                      onClick={() => {
                        onGoTo(item, 30330);
                        setFieldDisabled(false);
                        // onClose();
                      }}
                    >
                      Go to Contract
                    </button>
                    <ConfirmationPopup
                      className={'btn-deny px-4 py-2 mr-0'}
                      yesAction={() => {
                        onDeny(item);
                      }}
                      buttonText="Deny"
                      title="Are you sure you want to deny this request."
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ContractTerms;
