import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import UploadProfile from '../../Components/uploadProfilePicture';
import { studentBanner } from '../../store/actions';
import Twilio from '../../Components/twilio';
import CustomModal from '../../core/modal';
import { Twilio_Enterprise } from '../../app-config/screenName';
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
          },
        ],
      }),
    };
    this.props.dispatch(studentBanner(data));
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  capture = imageSrc => {
    const me = this;
    Axios.post('student/UploadImage', {
      image: imageSrc,
      studentId: this.props.ClientId,
      IsProfilePic: true,
    })
      .then(response => {
        if (response.data.success) {
          this.toggle('Profile picture has been uploaded successfully');
        }
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  render() {
    const {
      StudentId,
      PrefferedName,
      PrimaryPhone,
      Email,
      StateOrProvince,
      PreIntakeClientStatus,
      City,
      PreItakeSurveyId,
      StudentImageUrl,
      IsVoiceCallAllowed,
      EditActionUrl,
      IsthisPeerRecoveryClient,
    } = this.props.studentInfo;
    return (
      <div class="card border-0 students-profile mb-3">
        <div class="row card-body align-items-center mx-1 p-2">
          <div class="col-12 col-xl-4 mb-4 mb-xl-0">
            <div class="row align-items-center">
              <UploadProfile imgSrc={StudentImageUrl} captureProfile={this.capture} />

              <div class="col-12 col-sm-auto">
                <h4 class="students-name text-xs-center text-md-left">{PrefferedName}</h4>
                <div className="d-flex">
                  {IsVoiceCallAllowed > 0 ? (
                    <span
                      className="students-phone"
                      style={{ color: '#46B4FE', cursor: 'pointer', display: 'flex' }}
                      onClick={event => {
                        this.setState({
                          callModelVisible: true,
                          clickedRecord: {
                            Phone: PrimaryPhone,
                            Name: PrefferedName,
                            ProfileImageUrl: StudentImageUrl,
                            StudentId: StudentId,
                          },
                        });
                        event.stopPropagation();
                      }}
                    >
                      <img
                        src={`images/icons/phone-outline.svg`}
                        alt="Calling Allowed"
                        width="15px"
                        className="mr-1"
                      />
                      {PrimaryPhone ? PrimaryPhone : 'N/A'}
                    </span>
                  ) : (
                    <span style={{ color: '#000', display: 'flex' }} className="students-phone">
                      <img
                        src={`images/icons/phone-outline_gray.svg`}
                        alt="Calling Not Allowed"
                        width="15px"
                        className="mr-1"
                      />
                      {PrimaryPhone ? PrimaryPhone : 'N/A'}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-7">
            <div class="row students-detail text-md-left justify-content-md-between">
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">Email</span>
                <h6
                  class="info-detail m-md-0"
                  // style={{
                  //   textOverflow: 'ellipsis',
                  //   whiteSpace: 'nowrap',
                  //   overflow: 'hidden',
                  //   width: '120px',
                  // }}
                >
                  {Email ? Email : 'N/A'}
                </h6>
              </div>
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">State</span>
                <h6 class="info-detail m-md-0">{StateOrProvince ? StateOrProvince : 'N/A'}</h6>
              </div>
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">City</span>
                <h6 class="info-detail m-md-0">{City ? City : 'N/A'}</h6>
              </div>
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">Status</span>
                <h6 class="info-detail m-md-0">
                  {PreIntakeClientStatus ? PreIntakeClientStatus : 'N/A'}
                </h6>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-1">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                border: '2px solid #46B4FE',
                borderRadius: '20px',
                padding: '4px',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.history.push(EditActionUrl || this.props.EditActionUrl);
              }}
            >
              <img src="/images/editIcon.svg" />
              <span style={{ color: '#46B4FE', marginLeft: '7px' }}>Edit</span>
            </div>
          </div>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
            IsCallRecording={IsthisPeerRecoveryClient}
            ScreenName={Twilio_Enterprise}
            getCallEndDetails={this.props.getCallEndDetails}
          />
        )}
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.props.dispatch(
                studentBanner({
                  Json: JSON.stringify({
                    Student: [
                      {
                        StudentId: this.props.ClientId,
                      },
                    ],
                  }),
                }),
              );
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { student_banner } = state;
  const { data, isFetching, error } = student_banner;

  let studentInfo = {};
  let preItakeSurveyId = null;

  if (data) {
    studentInfo = data;
    preItakeSurveyId = data.PreItakeSurveyId;
  }

  return {
    studentInfo,
    preItakeSurveyId,
  };
};

export default connect(mapStateToProps)(withRouter(Banner));
