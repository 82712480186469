import React from 'react';
import { connect } from 'react-redux';
import {
  centerOverHeadCostGet,
  centerLaberCostGet,
  centerOverHeadCostUpsert,
} from '../../store/actions';
import CustomModal from '../../core/modal';
import { Loader2 } from '../../Components/Loader';
import PageTitle from '../../Components/PageTitle';
class OverHeadCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(centerOverHeadCostGet({}));
    this.props.dispatch(centerLaberCostGet({}));
  }

  componentWillReceiveProps({ centerOverHeadCost, isCenterOverheadCostUpdated }) {
    if (centerOverHeadCost != this.props.centerOverHeadCost) {
      this.setState({ centerOverHeadCost });
    }

    if (
      isCenterOverheadCostUpdated &&
      isCenterOverheadCostUpdated != this.props.isCenterOverheadCostUpdated
    ) {
      this.setState({
        ...this.state,
        isCenterOverheadCostUpdated,
      });
      // this.toggle('Center Overhead Cost updated Successfully');
    }
  }

  handleOverHeadChange = value => {
    let number = this.state.centerOverHeadCost;
    if (number >= 0 && number <= 100) {
      this.setState({
        centerOverHeadCost:
          value == 'Plus'
            ? this.state.centerOverHeadCost + 1 == 101
              ? 100
              : this.state.centerOverHeadCost + 1
            : this.state.centerOverHeadCost == 0
            ? 0
            : this.state.centerOverHeadCost - 1,
      });
      this.props.dispatch(
        centerOverHeadCostUpsert({
          jsonInput: JSON.stringify({
            CenterOverHead: [
              {
                OverHeadValue:
                  value == 'Plus'
                    ? this.state.centerOverHeadCost + 1 == 101
                      ? 100
                      : this.state.centerOverHeadCost + 1
                    : this.state.centerOverHeadCost == 0 || this.state.centerOverHeadCost ==null
                    ? 0
                    : this.state.centerOverHeadCost - 1,
              },
            ],
          }),
        }),
      );
    }
  };

  handleOnChange = event => {
    let number = event.target.value ? parseInt(event.target.value):0;
    if (number >= 0 && number <= 100) {
       this.setState({
         centerOverHeadCost: number ? number : null,
       });
    }
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <PageTitle TitleText="Overhead Cost | Master Bill of Services" />
        <Loader2 loading={this.props.isFetching} />
        <div className="container-fluid mt-5">
          <div className="p-3" style={{ border: '1px solid #EAEAEA', borderRadius: '4px' }}>
            <h4>Overhead Cost</h4>
            <p style={{ color: '#959595' }}>
              Overhead costs are indirect costs that are essential for the operation of a healthcare
              enterprise system, but do not directly contribute to the patient's care.
            </p>
            <div className="row">
              {' '}
              <div class="col-12 col-xl-3 mt-3">
                <div class="license-quantity d-flex  justify-content-center align-items-center">
                  <label
                    class="placeholder-label flex-grow-1"
                    style={{ marginBottom: 'unset', fontWeight: '800' }}
                  >
                    Set Overhead %
                  </label>
                  <div class="quantity-btn flex-grow-1" style={{ marginBottom: 'unset' }}>
                    <div class="numbers-row">
                      <input
                        name={'centerOverHeadCost'}
                        class="number-icon numbers-only"
                        type="number"
                        min="0"
                        max="3"
                        value={this.state.centerOverHeadCost}
                        onChange={this.handleOnChange}
                        onBlur={() => {
                          this.props.dispatch(
                            centerOverHeadCostUpsert({
                              jsonInput: JSON.stringify({
                                CenterOverHead: [
                                  {
                                    OverHeadValue:
                                      this.state.centerOverHeadCost &&
                                      this.state.centerOverHeadCost.toString()
                                        ? this.state.centerOverHeadCost
                                        : 0,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                        onWheel={event => {
                          window.document.activeElement.blur();
                        }}
                        onKeyDown={evt =>
                          ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()
                        }
                        // onChange={this.getCapacityCount}
                        //   validate={Validator.minValue}
                        tabindex="0"
                        style={{
                          textAlign: 'center',
                          margin: '0px 5px',
                          height: '32px',
                          width: '56px',
                          fontSize: '16px',
                        }}
                      />
                      <div
                        style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                        class="add quantity-button"
                        onClick={() => {
                          this.handleOverHeadChange('Plus');
                        }}
                      >
                        +
                      </div>
                      <div
                        style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                        class="remove quantity-button"
                        onClick={() => {
                          this.handleOverHeadChange('Minus');
                        }}
                      >
                        -
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        /> */}
      </React.Fragment>
    );
  }
}

const mapStateToPropsParent = ({ center_over_head_cost_get, center_over_head_cost_upsert }) => {
  let centerOverHeadCost = null;
  let isCenterOverheadCostUpdated = null;
  let isFetching = false;
  if (center_over_head_cost_get && center_over_head_cost_get.data) {
    centerOverHeadCost = center_over_head_cost_get.data.CenterOverHead[0].OverHeadValue;
  }

  if (center_over_head_cost_get && center_over_head_cost_get.isFetching) {
    isFetching = center_over_head_cost_get.isFetching;
  }

  if (
    center_over_head_cost_upsert &&
    center_over_head_cost_upsert.data &&
    center_over_head_cost_upsert.data.success
  ) {
    isCenterOverheadCostUpdated = center_over_head_cost_upsert.data.success;
  }

  return {
    centerOverHeadCost,
    isCenterOverheadCostUpdated,
    isFetching,
  };
};
export default connect(mapStateToPropsParent)(OverHeadCost);
