import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import TextareaAutosize from 'react-autosize-textarea';
import DictationComponent from '../../../../../../Components/DictationComponent_1';
import { connect } from 'react-redux';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class TextAreaType extends React.Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      value: Response || '',
    };
  }

  getDicatationStatus = value => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    let timer;
    return () => {
      clearTimeout(timer);
      setTimeout(() => {
        setValue.call(scope, {
          response: this.state.value,
          surveyQuestionId: SurveyQuestionId,
          level: level,
          subLevel: subLevel,
          subCatLevel: subCatLevel,
          index: index,
        });
      }, 3000);
    };
  };

  onChange = (event, click) => {
    const { value } = event.target;
    // const { SurveyQuestionId } = this.props.survey;
    this.setState({
      value: value,
    });
  };
  handleDictate = result => {
    if (result) {
      // this.props.change('note', this.props.presentNoteValue + ' ' + result.result.transcript);
      let data;
      if (this.state.value) {
        data = `${this.state.value} ${result}`;
      } else {
        data = result;
      }
      this.setState({ value: data });
    }
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      FeatureFlags: { enableDictation },
    } = this.props;
    const { SurveyQuestionId, Response } = survey;
    return (
      <>
        <div className="type-border col-12 rcm-panel">
          <div
          // class="card-body"
          >
            <SurveyQuestion survey={survey} ClassName={'mb-3'} />

            <div class="answer-options text-left">
              <div class="quantity-btn">
                <div class="print-textarea-component">
                  <TextareaAutosize
                    disabled={isReadOnly}
                    // style={{ paddingLeft: '10px' }}
                    style={{ height: '150px' }}
                    value={this.state.value}
                    id="question"
                    name="question"
                    rows="2"
                    placeholder="Explain"
                    // className={this.state.value ? `textarea-type` : `print-time-textarea-novalue`}
                    className="textarea-type"
                    onChange={this.onChange}
                    onBlur={() => {
                      setValue.call(scope, {
                        response: this.state.value,
                        surveyQuestionId: SurveyQuestionId,
                        level: level,
                        subLevel: subLevel,
                        subCatLevel: subCatLevel,
                        index: index,
                      });
                    }}
                  />
                  {enableDictation && !isReadOnly && (
                    <div
                      className="dictation-space d-flex justify-content-end"
                      style={{ paddingRight: '10px' }}
                    >
                      <DictationComponent
                        handleDictate={this.handleDictate}
                        style={{ paddingRight: '10px' }}
                        getDicatationStatus={this.getDicatationStatus}
                      />
                      <button
                        style={{
                          marginLeft: '10px',
                          marginRight: '8px',
                          cursor: 'pointer',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          background: 'transparent',
                          border: '2px solid #ff7875',
                          color: 'rgb(191, 191, 191)',
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: '4px',
                        }}
                        onClick={() => {
                          debugger;
                          this.setState({ value: '' });
                          this.getDicatationStatus();
                        }}
                      >
                        <img
                          src="images/clear_icon.svg"
                          // onClick={() => {
                          //   debugger;
                          //   alert("clear button is clicked")
                          //   this.setState({ value: '' });
                          //   this.getDicatationStatus();
                          // }}
                        />
                        <p style={{ marginBottom: '0', marginLeft: '7px', color: '#ff7875' }}>
                          Clear
                        </p>
                        {/* <i
                          class="far fa-window-close"
                          onClick={() => {
                            debugger;
                            this.setState({ value: '' });
                            this.getDicatationStatus();
                          }}
                        ></i> */}
                      </button>
                    </div>
                  )}
                </div>
                {this.state.value && (
                  <div className="print-textarea-text">
                    <span className=""> {this.state.value}</span>
                  </div>
                )}
              </div>
            </div>

            <DoesNotApplyOption surveyProps={this.props} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user_feature_flag_get } = state;
  const { data: fFData } = user_feature_flag_get;

  let FeatureFlags = {};

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
  }
  return {
    FeatureFlags,
  };
};

export default connect(mapStateToProps)(TextAreaType);
