import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListRenderer from '../Components/listRenderer';
import {
  updateStaffEmail,
  updateStaffPassword,
  updateStaffCenter,
  loadDefaultValues,
  resetStaffEmail,
  resetStaffPassword,
  resetStaffCenter,
  uploadStaffImage,
  upsertPin,
  listCombos,
  updateUserProfile,
} from '../store/actions';
import { ReduxSelect, ReduxInput } from '../component/custom-redux-components';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import CustomModal from '../core/modal';
import Loader from '../Components/Loader';
import Webcam from 'react-webcam';
import Axios from 'axios';
import { Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import IndividualPricing from './Pricing/individualPricing';
import MobileAppDetails from './setting_MobileAppDetails';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  confirmEmail: (value, values) => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        if (value != values.email) {
          return 'Email and Confirm Email must be same';
        }
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  password: value => {
    // if (value) {
    //   if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/i.test(value)) {
    //     return 'Invalid password';
    //   } else {
    //     return undefined;
    //   }
    // } else {
    //   return 'Field Required';
    // }
    if (value) {
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/.test(value)) {
        return 'Please ensure your password has minimum 8 characters which includes a Capital Letter,Small Letter,Special Symbol & a Number.Example : Hello@123 ';
      } else {
        return undefined;
      }
    } else {
      return 'Required';
    }
  },
  confirmPassword: (value, values) => {
    if (value) {
      if (value != values.newPassword) {
        return 'Password and Confirm Password must be same';
      }
      return undefined;
    } else {
      return 'Required';
    }
  },
  pinLength: value => {
    let validPasswordLengthArray = [6, 7, 8, 9, 10];
    if (validPasswordLengthArray.includes(value.length)) {
    } else {
      return 'should be of 6-10 digits';
    }
  },
  confirmPin: (value, values) => {
    if (value) {
      if (value != values.pin) {
        return 'and Pin must be same';
      }
      return undefined;
    } else {
      return 'Required';
    }
  },
  allowNumeric: value => (value && !/^[0-9]+$/.test(value) ? 'should be numeric only' : undefined),
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

let ChangeEmailForm = ({ onSubmit, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '36px 20px 150px 20px' }}>
      <div class="enrollment-form setting-gap">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label class="form-group "> Old Email : {localStorage.getItem('email')}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="email"
                class="form-control"
                component={ReduxInput}
                validate={Validator.email}
                fieldName="New Email"
              />
              <label class="placeholder-label">New Email*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="confirmEmail"
                class="form-control"
                component={ReduxInput}
                validate={Validator.confirmEmail}
                fieldName="Confirm Email"
              />
              <label class="placeholder-label">Confirm Email*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-2">
            <input
              type="submit"
              value="Save"
              class="btn btn-sm"
              style={{ minWidth: '146px', padding: '5px 20px' }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const ReduxChangeEmailForm = reduxForm({
  form: 'ReduxChangeEmailForm',
})(ChangeEmailForm);

let ChangePasswordForm = ({ onSubmit, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '36px 20px 150px 20px' }}>
      <div class="enrollment-form setting-gap">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="oldPassword"
                type="password"
                component={ReduxInput}
                validate={Validator.required}
                class="form-control"
                fieldName="Old Password"
              />
              <label class="placeholder-label">Old Password*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="newPassword"
                type="password"
                component={ReduxInput}
                validate={Validator.password}
                class="form-control"
                fieldName="New Password"
              />
              <label class="placeholder-label">New Password*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="confirmPassword"
                type="password"
                component={ReduxInput}
                validate={Validator.confirmPassword}
                class="form-control"
                fieldName="Confirm Password"
              />
              <label class="placeholder-label">Confirm Password*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-2">
            <input type="submit" value="Save" class="btn btn-sm stn-btn" />
          </div>
        </div>
      </div>
    </form>
  );
};

const ReduxChangePasswordForm = reduxForm({
  form: 'ReduxChangePasswordForm',
})(ChangePasswordForm);

let ChangeDefaultCenterForm = ({
  onSubmit,
  handleSubmit,
  Center,
  initialValues,
  isData,
  initialize,
}) => {
  if (!isData) initialize(initialValues);
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '36px 20px 150px 20px' }}>
      <div class="row setting-gap">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group remove-combo-padding">
            <Field
              name={`centerId`}
              component={ReduxSelect}
              options={Center}
              validate={Validator.required}
              placeholder="Default Center"
            />
          </div>
        </div>
      </div>
      <div class="row setting-gap">
        <div class="col-12 col-md-6 col-lg-4 mt-2">
          <input type="submit" value="Save" class="btn btn-sm stn-btn" />
        </div>
      </div>
    </form>
  );
};

const ReduxChangeDefaultCenterForm = connect(state => ({
  isData: formValueSelector('ReduxChangeDefaultCenterForm')(state, 'centerId'),
}))(
  reduxForm({
    form: 'ReduxChangeDefaultCenterForm',
  })(ChangeDefaultCenterForm),
);

let ChangePin = ({ onSubmit, handleSubmit, isCurrentPasswordData }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '36px 20px 150px 20px' }}>
      <div class="enrollment-form setting-gap">
        <div class="row">
          <div className="col-md-6">
            <div className="row">
              <div class="col-12 col-md-12 col-lg-12 mb-2" style={{ color: '#959595' }}>
                Please enter your login password to setup/change your PIN.
              </div>

              <div class="col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <Field
                    name="currentPassword"
                    class="form-control"
                    component={ReduxInput}
                    type="password"
                    fieldName="Current Password"
                    validate={Validator.required}
                  />
                  <label class="placeholder-label">Current Password*</label>
                  {/* <span class="small-info">Enter your Login Password</span> */}
                </div>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 d-flex justify-content-center"
                style={{ margin: '20px 0px 0px' }}
              >
                <div
                  style={{
                    // borderBottom: '1px dashed rgba(149, 149, 149, 0.5)',
                    width: '50px',
                  }}
                >
                  {' '}
                </div>
              </div>
              {/* <div class="divider">
                <span></span>
                <span>
                  <i class="fas fa-circle" style={{ fontSize: '10px' }}></i>
                </span>
                <span></span>
              </div> */}
              <div class="col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <Field
                    name="pin"
                    class="form-control"
                    component={ReduxInput}
                    type="password"
                    fieldName="PIN"
                    validate={[Validator.required, Validator.pinLength, Validator.allowNumeric]}
                  />
                  <label class="placeholder-label">Pin*</label>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <Field
                    name="confirmPin"
                    class="form-control"
                    component={ReduxInput}
                    type="password"
                    fieldName="Confirm Pin "
                    validate={[Validator.confirmPin, Validator.required, Validator.allowNumeric]}
                  />
                  <label class="placeholder-label">Confirm Pin*</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-2">
            <input
              type="submit"
              value="Save"
              class="btn btn-sm"
              style={{ minWidth: '146px', padding: '5px 20px' }}
              disabled={!isCurrentPasswordData}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
const ReduxChangePin = connect(state => ({
  isCurrentPasswordData: formValueSelector('ReduxChangePin')(state, 'currentPassword'),
}))(
  reduxForm({
    form: 'ReduxChangePin',
  })(ChangePin),
);
class ChangeProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startCamera: true,
      level: 2,
      imageSrc: null,
    };
  }
  setRef = webcam => {
    this.webcam = webcam;
  };

  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('surveyId');
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }

  getIgnoreUpdatingPin() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('ignoreUpdatingPin') == 'true' ? true : false;
  }

  process = () => {
    if (this.state.level == 1) {
      this.setState({ startCamera: true, level: 2 });
    } else if (this.state.level == 2) {
      const imageSrc = this.webcam.getScreenshot();
      //this.props.dispatch(uploadStaffImage({ image: imageSrc }));
      const me = this;
      Axios.post('Adminstration/UploadStaffImage', {
        image: imageSrc,
      })
        .then(response => {
          localStorage.setItem('profileImgUrl', response.data.result);
          me.setState({ startCamera: false, level: 3, imageSrc: imageSrc });
        })
        .catch(error => {
          console.log('Image Failed');
        });
    } else if (this.state.level == 3) {
    }
  };

  // onUserMediaError = err => {
  //   var searchStr = 'surveyId=' + this.getSurveyId() + '&studentId=' + this.getStudentId();
  //   const errorMsg = err.name == 'NotFoundError' ? 'not available' : 'not enabled';
  //   let me = this;
  //   Modal.error({
  //     title: 'Auto Redirecting Page to Validate Pin as Camera is ' + errorMsg,
  //     onOk() {
  //       // if (me.getIgnoreUpdatingPin()) {
  //       //   me.props.history.push({
  //       //     pathname: 'validate',
  //       //     search: searchStr,
  //       //   });
  //       // } else {
  //       //   me.props.history.push({
  //       //     pathname: 'pin',
  //       //     search: searchStr,
  //       //   });
  //       // }
  //     },
  //   });
  // };

  render() {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user',
    };

    var text = '';
    if (this.state.level == 1) {
      text = 'Start Camera';
    } else if (this.state.level == 2) {
      text = 'Capture';
      if (this.props.location && this.props.location.param && this.props.location.param.message) {
        alert(this.props.location.param.message);
      }
    } else if (this.state.level == 3) {
      text = 'Saved';
    }

    return (
      <div class="main complete-selfie-page  mb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-center">
              <div class="complete-selfie-box">
                <h3>Let’s take a selfie.</h3>
                {this.state.startCamera && (
                  <Webcam
                    audio={false}
                    onUserMediaError={this.onUserMediaError}
                    ref={this.setRef}
                    screenshotFormat="image/jpeg"
                    className="selfie-img"
                    videoConstraints={videoConstraints}
                  />
                )}
                {this.state.level == 3 && (
                  <figure class="selfie-img">
                    <img src={this.state.imageSrc} class="img-background" />
                  </figure>
                )}
                <input type="button" value={text} onClick={this.process} class="btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ReduxChangeProfilePicture = reduxForm({
  form: 'ReduxChangeDefaultCenterForm',
})(withRouter(ChangeProfilePicture));

const activeKey = 0;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(reset('ReduxChangeDefaultCenterForm'));
    this.state = {
      emailModelOpen: false,
      passwordModelOpen: false,
      centerModelOpen: false,
      upsertPinModelOpen: false,
    };
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  onChangeEmail = values => {
    this.props.dispatch(updateStaffEmail({ email: values.email }));
  };
  onChangePin = values => {
    this.props.dispatch(
      upsertPin({
        JsonInput: JSON.stringify({
          Staff: [{ PIN: values.pin }],
        }),
        OldPassWord: values.currentPassword,
      }),
    );
  };
  componentDidMount() {
    this.props.dispatch(listCombos({ comboTypes: 'Center' }));
  }

  componentWillReceiveProps({ isCenterUpdated, isEmailUpdated, isPasswordUpdated, isPinUpdated }) {
    const { dispatch } = this.props;
    if (isCenterUpdated && isCenterUpdated != this.props.isCenterUpdated) {
      this.props.dispatch(loadDefaultValues({}));
      sessionStorage.removeItem('state');
      sessionStorage.removeItem('isBack');
    }
    if (isEmailUpdated) {
      this.setState({ emailModelOpen: true });
      dispatch(resetStaffEmail());
    }
    if (isPasswordUpdated) {
      this.setState({ passwordModelOpen: true });
      dispatch(resetStaffPassword());
    }
    if (isCenterUpdated) {
      this.setState({ centerModelOpen: true });
      dispatch(resetStaffCenter());
    }
    if (isPinUpdated && isPinUpdated != this.props.isPinUpdated) {
      this.setState({ upsertPinModelOpen: true });
    }
  }

  toggle = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };

  onPasswordChange = values => {
    this.props.dispatch(
      updateStaffPassword({
        oldPassWord: values.oldPassword,
        newPassword: values.newPassword,
      }),
    );
  };

  onCenterChange = values => {
    this.props.dispatch(updateStaffCenter({ centerId: values.centerId }));
    this.props.dispatch(reset('ReduxChangeDefaultCenterForm'));
  };

  onPictureChange = values => {};

  render() {
    const {
      combos: { Center },
      defaultCenter,
      isFetching,
      userId,
    } = this.props;

    const list = [
      {
        Name: 'Pin',
        ExtraComponent: () => <ReduxChangePin onSubmit={this.onChangePin} />,
      },
      {
        Name: 'Email Address',
        ExtraComponent: () => <ReduxChangeEmailForm onSubmit={this.onChangeEmail} />,
      },
      {
        Name: 'Password',
        ExtraComponent: () => <ReduxChangePasswordForm onSubmit={this.onPasswordChange} />,
      },
      {
        Name: 'Default Center',
        ExtraComponent: () => (
          <ReduxChangeDefaultCenterForm
            initialValues={{ centerId: defaultCenter }}
            Center={Center}
            onSubmit={this.onCenterChange}
            enableReinitialize
          />
        ),
      },
      {
        Name: 'Mobile App Detail',
        ExtraComponent: () => <MobileAppDetails UserId={userId} />,
      },
      {
        Name: 'Profile Picture',
        ExtraComponent: () => <ReduxChangeProfilePicture onSubmit={this.onPictureChange} />,
      },
      // {
      //   Name: 'Profile Management',
      //   ExtraComponent: () => {
      //     return (
      //       <div className="" style={{ padding: '36px 20px 150px 20px' }}>
      //         <div class="enrollment-form setting-gap">
      //           <div class="row">
      //             <h6
      //               onClick={() => {
      //                 this.props.dispatch(updateUserProfile({ userProfile: true }));
      //                 this.props.history.push(
      //                   `/manage/staff-management/staff-form?step=basic-detail&user-profile=true&staffId=${userId}`,
      //                 );
      //               }}
      //               style={{ cursor: 'pointer' }}
      //             >
      //               Click here to update profile
      //             </h6>
      //           </div>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
    ];

    return (
      <>
        <Loader loading={isFetching} />
        <div class="main   mb-5">
          <div class="container-fluid">
            <div class="row top-heading-row mt-5 mb-3 align-items-center">
              <div class="col-12 col-md-4 col-lg-3">
                <h3 class="page-title mb-3 mb-md-0">Settings</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-5">
                <div class="vertical-tabs enrollment-form">
                  <ListRenderer list={list} activeKey={activeKey} tabPosition="left" />
                </div>
              </div>
            </div>
          </div>

          <CustomModal
            isOpen={this.state.emailModelOpen}
            header={'Your changes have been saved successfully'}
            yesAction={() => {
              this.toggle('emailModelOpen');
            }}
            yesText="OK"
            hideCancel={true}
          />
          <CustomModal
            isOpen={this.state.passwordModelOpen}
            header={'Your Password has been updated successfully'}
            yesAction={() => {
              this.toggle('passwordModelOpen');
            }}
            yesText="OK"
            hideCancel={true}
          />
          <CustomModal
            isOpen={this.state.centerModelOpen}
            header={'Your changes have been saved successfully'}
            yesAction={() => {
              sessionStorage.removeItem('appliedFilter');
              this.toggle('centerModelOpen');
            }}
            yesText="OK"
            hideCancel={true}
          />
          <CustomModal
            isOpen={this.state.upsertPinModelOpen}
            header={'Your pin changes have been saved successfully'}
            yesAction={() => {
              // sessionStorage.removeItem('appliedFilter');
              this.toggle('upsertPinModelOpen');
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  combos,
  default_values,
  update_staff_email,
  update_staff_password,
  update_staff_center,
  upsert_pin,
}) => {
  var defaultCenter = null;
  var userId = null;
  var isFetching = false;
  var isCenterUpdated = false,
    isEmailUpdated = false,
    isPasswordUpdated = false;
  let Center = [];
  let isPinUpdated = false;

  if (!combos.data) {
    combos.data = {
      Center: [],
    };
  }
  // if (combos.data && combos.data.Center) {
  //   Center = combos.data.Center;
  // }
  if (default_values && default_values.data) {
    defaultCenter = default_values.data.centerId;
    userId = default_values.data.userId;
  }

  if (update_staff_email) {
    if (update_staff_email.isFetching) {
      if (!isFetching) {
        isFetching = update_staff_email.isFetching;
      }
    } else if (update_staff_email.data && update_staff_email.data.success) {
      isEmailUpdated = true;
    }
  }

  if (update_staff_password && update_staff_password.isFetching) {
    if (!isFetching) {
      isFetching = update_staff_password.isFetching;
    }
  } else if (update_staff_password.data && update_staff_password.data.success) {
    isPasswordUpdated = true;
  }

  if (update_staff_center) {
    if (update_staff_center.isFetching) {
      if (!isFetching) {
        isFetching = update_staff_center.isFetching;
      }
    } else if (update_staff_center.data && update_staff_center.data.success) {
      isCenterUpdated = true;
    }
  }

  if (upsert_pin && upsert_pin.data && upsert_pin.data.success) {
    isPinUpdated = upsert_pin.data.success;
  }
  return {
    combos: combos.data,
    defaultCenter,
    isFetching,
    isCenterUpdated,
    isEmailUpdated,
    isPasswordUpdated,
    isPinUpdated,
    userId,
  };
};

export default connect(mapStateToProps)(Settings);
