import React, { Component } from 'react';
import { Button, Select, Collapse } from 'antd';
import user from '../../images/blank-profile.png';
import { connect } from 'react-redux';
import moment from 'moment';
import Paragraph from 'antd/lib/typography/Paragraph';
import renderHTML from 'react-render-html';
import {
  // getUserNotePatient,
  // getUserNoteComment,
  // addUserNoteComment,
  // getUserNoteEmoji,
  // addUserNoteEmoji,
  getStudentNote,
  getStudentNoteComment,
  addStudentNoteComment,
  addStudentNoteEmoji,
  getStudentNoteEmoji,
  studentJournalAugmentGet,
} from '../../store/actions';
import { CaretRightOutlined } from '@ant-design/icons';
import { SettingOutlined } from '@ant-design/icons';
import Loader, { Loader2 } from '../../Components/Loader';
import _blank from '../../images/blank-profile.png';
import { v4 as uuidv4 } from 'uuid';
import TextareaAutosize from 'react-autosize-textarea';
import AugmentWithAIForJournals from './AugmentWithAIForJournals/index';
import { CommonLicenceScreens } from '../licenceScreens';
import StudentNoteHistory from './studentNoteHistory';

const { Option } = Select;
var activePanel = 1;
const { Panel } = Collapse;

class PanelHeader extends Component {
  render() {
    const { headerData, EmojiList } = this.props;
    const {
      Note,
      NoteType,
      IsConfidential,
      LastModified,
      UpdateByDisplayName,
      CommentCount,
    } = headerData;
    // let color = headerData.Color === 'blue' ? 'blue' : 'default';
    let color = 'default';
    const statusText = {
      default: {
        text: 'Up Next',
        class: 'fa fa-lock mr-1',
      },
      blue: {
        text: 'Current Level',
        class: '',
      },
      green: {
        text: 'Level Achieved',
        class: 'fa fa-check',
      },
    };
    return (
      <div className="panel-header row">
        {/* <div className="col-3 px-0">
          <div className="panel-header-left">
            <div className="outcome-icon">
              <img src={`images/Outcomes@2x_${color}.png`} alt="Outcomes" />
              <span>{headerData.Level}</span>
            </div>
            <span className="day-count">{headerData.Range}</span>
          </div>
        </div> */}
        <div className="col-12 pt-2">
          <Select
            className="emoji-dropdown"
            // defaultValue={
            //   treatmentPlanInterventionId &&
            //   treatmentPlanInterventionId[record.ClientId]
            // }
            style={{ width: 65 }}
            // onChange={value => {
            //   this.handleSelectChange(record.ClientId, value);
            // }}
          >
            {EmojiList &&
              EmojiList.map(item => {
                return <Option value={item.Value}>{item.Text}</Option>;
              })}
          </Select>{' '}
          <div className="d-inline">
            <img src="/images/chatCount.svg" style={{ verticalAlign: 'text-bottom' }} />{' '}
            {CommentCount}
          </div>
        </div>
      </div>
    );
  }
}

class NotesFromClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: '',
    };
  }
  componentDidMount() {
    const { AugmentedAIStatusData } = this.props;
    // window.scrollTo(0, 0);
    // this.props.dispatch(getStudentNote({ StudentId: 1000758 }));
    this.props.dispatch(getStudentNoteEmoji({ StudentId: this.props.StudentId }));
    if (
      AugmentedAIStatusData &&
      AugmentedAIStatusData.LicenseStatusId != 1 &&
      AugmentedAIStatusData.LicenseStatusId != 3 &&
      AugmentedAIStatusData.LicenseStatusId != 5
    ) {
      this.props.dispatch(
        studentJournalAugmentGet({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: this.props.StudentId,
              },
            ],
          }),
        }),
      );
    }
  }
  componentWillReceiveProps({ isCommented }) {
    if (isCommented && JSON.stringify(isCommented) != JSON.stringify(this.props.isCommented)) {
      this.props.dispatch(
        getStudentNoteComment({
          StudentId: this.props.StudentId,
          Json: JSON.stringify({
            StudentNoteComment: [
              {
                StudentNoteGuid: this.state.StudentNoteGuid,
              },
            ],
          }),
        }),
      );
      this.setState({
        commentText: '',
      });
    }
  }
  render() {
    const {
      StudentId,
      LastUpdatedDateTime,
      StudentNote,
      EmojiList,
      UserComment,
      isFetchingComment,
      journalNotes,
      AugmentedAIStatusData,
    } = this.props;

    const chatStatus = { '1': 'fa-hourglass', '2': 'fa-check-double', '3': 'fa-check' };
    return (
      <div className="chat-container">
        {AugmentedAIStatusData && (
          <CommonLicenceScreens
            StatusData={this.props.AugmentedAIStatusData}
            moduleName={'AugmentedAI'}
          />
        )}
        <>
          <div class="row mb-2">
            <div class="col-12">
              <>
                {AugmentedAIStatusData &&
                  AugmentedAIStatusData.LicenseStatusId != 1 &&
                  AugmentedAIStatusData.LicenseStatusId != 3 &&
                  AugmentedAIStatusData.LicenseStatusId != 5 && (
                    <>
                      <AugmentWithAIForJournals
                        isEnterprise={true}
                        StudentId={this.props.StudentId}
                      />
                    </>
                  )}
              </>
            </div>
          </div>
        </>

        {journalNotes.Answer && (
          <div style={{ backgroundColor: '#F7F8FA' }} className="p-3 mt-3">
            {journalNotes.DateRange && (
              <p style={{ color: 'rgba(151, 151, 151, 0.50)' }} className="text-right">
                {journalNotes.DateRange}
              </p>
            )}
            <p className="mb-0" style={{ color: '#000', fontSize: '15px' }}>
              {journalNotes.Answer && renderHTML(journalNotes.Answer)}
            </p>
          </div>
        )}
        <div style={{ height: '650px', overflowX: 'hidden', overflowY: 'scroll' }}>
          {StudentNote &&
            StudentNote.map((item, index) => {
              const {
                // Note,
                // NoteType,
                // IsConfidential,
                // LastModified,
                // UpdateByDisplayName,
                // CommentCount,
                // UserNoteGuid,
                // UserNoteEmoji,
                ThumbnailUrl,
                StudentNoteId,
                StudentNoteGuid,
                Note,
                IsPrivate,
                DateInserted,
                LastModified,
                IsConfidential,
                NoteType,
                UpdateByDisplayName,
                CommentCount,
                StudentNoteEmoji,
                IsHistoryAllowed,
              } = item;
              return (
                <div className="note-box">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h6 className="note-type">
                        <Paragraph
                          ellipsis={{ rows: 1, expandable: false }}
                          textAlign="justify"
                          className="mb-0"
                          style={{ color: '#000000', display: 'inline-block' }}
                        >
                          {NoteType}
                        </Paragraph>
                        {IsConfidential && (
                          <span className="alert alert-danger confidential-alert border-0 text-uppercase ml-2">
                            <i className="fas fa-lock" /> Confidential
                          </span>
                        )}
                      </h6>
                      <span className="note-author">by {UpdateByDisplayName}</span>
                      <img src={ThumbnailUrl} />
                    </div>
                    <div className="col-12 col-md-5">
                      <span className="note-date mr-2">{LastModified}</span>
                      {IsHistoryAllowed && <StudentNoteHistory rowRecord={item} />}
                    </div>
                    <div className="col-12 pt-2">
                      <p className="note-text mb-0">{Note}</p>
                    </div>
                    <div className="col-12 pt-2">
                      <Collapse
                        accordion
                        bordered={false}
                        // defaultActiveKey={activePanel + 'blue'}
                        expandIconPosition="right"
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        className="site-collapse-custom-collapse"
                        onChange={() => {
                          this.props.dispatch(
                            getStudentNoteComment({
                              StudentId: StudentId,
                              Json: JSON.stringify({
                                StudentNoteComment: [
                                  {
                                    StudentNoteGuid: StudentNoteGuid,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      >
                        <Panel
                          // header={<PanelHeader headerData={item} EmojiList={EmojiList} />}
                          header={
                            <div className="jounal-user-post">
                              <Select
                                className="emoji-dropdown mr-2"
                                defaultValue={StudentNoteEmoji && StudentNoteEmoji[0].Emoji}
                                style={{ width: 65 }}
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                                onChange={value => {
                                  this.props.dispatch(
                                    addStudentNoteEmoji({
                                      StudentId: StudentId,
                                      Json: JSON.stringify({
                                        StudentNoteEmoji: [
                                          {
                                            StudentNoteGuid: StudentNoteGuid,
                                            StudentNoteEmojiGuid: uuidv4(),
                                            Emoji: value,
                                            RecordedDateTime: moment(),
                                          },
                                        ],
                                      }),
                                    }),
                                  );
                                }}
                              >
                                {EmojiList &&
                                  EmojiList.map(item => {
                                    return <Option value={item.Value}>{item.Text}</Option>;
                                  })}
                              </Select>{' '}
                              <img
                                src="/images/chatCount.svg"
                                style={{ verticalAlign: 'text-bottom' }}
                              />{' '}
                              {CommentCount}
                            </div>
                          }
                          key={activePanel + 'blue'}
                          className={`site-collapse-custom-panel-blue user-note-panel`}
                          showArrow={true}
                        >
                          <Loader2 loading={isFetchingComment} />
                          <div
                            className="panel-text pt-2 jounal-user-note container px-0 mx-0"
                            style={{ backgroundColor: '#fff' }}
                          >
                            <div
                              style={{
                                // height: '300px',
                                maxHeight: '300px',
                                minHeight: '50px',
                                overflowX: 'hidden',
                                backgroundColor: '#f8f8f8',
                                padding: '0px 15px',
                              }}
                            >
                              {UserComment && UserComment.length > 0 ? (
                                UserComment.map(comment => {
                                  return (
                                    <>
                                      {!comment.IsStudentComment ? (
                                        <div class="reply-dialogbox">
                                          <div>
                                            <div class="reply-dialog-body">
                                              <span class="tip tip-reply"></span>
                                              <div class="reply-message">
                                                {/* <span>{comment.Comment}</span> */}
                                                <div className="message">
                                                  <span className="counselor-name">
                                                    {comment.DisplayName}
                                                  </span>
                                                  <span>{comment.Comment}</span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="text-right">
                                              <span className="chat-emoji">
                                                {comment.StudentNoteEmoji &&
                                                  comment.StudentNoteEmoji[0].Emoji}
                                              </span>
                                              <span className="chat-time">
                                                {moment(comment.CommentDateTime).format('lll')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div class="comment-dialogbox">
                                          <img
                                            class="rounded-circle mr-1"
                                            width="36"
                                            height="36"
                                            alt="img"
                                            src={comment.CommentFriendProfileImageUrl || _blank}
                                          />
                                          <div>
                                            <div class="comment-dialog-body">
                                              <span class="tip tip-comment"></span>
                                              <div class="comment-message">
                                                <div className="message">
                                                  <span className="counselor-name">
                                                    {comment.DisplayName}
                                                  </span>
                                                  <span>{comment.Comment}</span>
                                                </div>
                                              </div>
                                            </div>
                                            <span className="chat-time">
                                              {moment(comment.CommentDateTime).format('lll')}
                                            </span>
                                            <Select
                                              className="emoji-dropdown"
                                              defaultValue={
                                                comment.StudentNoteEmoji &&
                                                comment.StudentNoteEmoji[0].Emoji
                                              }
                                              style={{ width: 60 }}
                                              onChange={value => {
                                                this.props.dispatch(
                                                  addStudentNoteEmoji({
                                                    StudentId: StudentId,
                                                    Json: JSON.stringify({
                                                      StudentNoteEmoji: [
                                                        {
                                                          StudentNoteCommentGuid:
                                                            comment.StudentNoteCommentGuid,
                                                          StudentNoteEmojiGuid: uuidv4(),
                                                          Emoji: value,
                                                          RecordedDateTime: moment(),
                                                        },
                                                      ],
                                                    }),
                                                  }),
                                                );
                                              }}
                                            >
                                              {EmojiList &&
                                                EmojiList.map(item => {
                                                  return (
                                                    <Option value={item.Value}>{item.Text}</Option>
                                                  );
                                                })}
                                            </Select>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <div className="text-center mt-2">
                                  <h6 style={{ color: '#979797' }}>
                                    No comments available for this note.
                                  </h6>
                                </div>
                              )}
                            </div>
                            {true && (
                              <div className="chat-form p-0" style={{ alignItems: 'flex-end' }}>
                                {/* <img
                                  class="rounded-circle mr-2 "
                                  width="36"
                                  height="36"
                                  alt="img"
                                  src={_blank}
                                /> */}
                                <div className="form-group" style={{ flexGrow: '1' }}>
                                  <TextareaAutosize
                                    className={`form-control not-empty`}
                                    // style={{ minHeight: '75px' }}
                                    rows="1"
                                    name="textarea_field"
                                    key="Type here"
                                    id="commentBox"
                                    placeholder="Add a comment.."
                                    onChange={event => {
                                      this.setState({
                                        commentText: event.target.value,
                                      });
                                    }}
                                  >
                                    {this.state.commentText}
                                  </TextareaAutosize>
                                  {/* <label class="placeholder-label">Add a comment..</label> */}
                                </div>
                                <div className="send-button">
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      this.props.dispatch(
                                        addStudentNoteComment({
                                          StudentId: StudentId,
                                          Json: JSON.stringify({
                                            StudentNoteComment: [
                                              {
                                                StudentNoteGuid: StudentNoteGuid,
                                                StudentNoteCommentGuid: uuidv4(),
                                                Comment: this.state.commentText,
                                                RecordedDateTime: moment(),
                                              },
                                            ],
                                          }),
                                        }),
                                      );
                                      document.getElementById('commentBox').value = '';
                                      this.setState({
                                        commentText: '',
                                        StudentNoteGuid: StudentNoteGuid,
                                      });
                                    }}
                                  >
                                    POST
                                  </Button>
                                </div>
                              </div>
                            )}
                            {/* {item.Question && item.Question.map(ques => <li>{ques.Question}</li>)} */}
                            {/* UserGuid : "1562AB37-7946-43C1-AB74-8F35408324AC" */}
                            {/* Json: "{"UserNoteComment":[{"UserNoteGuid" : "739021DF-3559-4783-B6E9-65BEF1BE9192", "UserNoteCommentGuid" : "739021DF-3559-4783-B6E9-65BEF1BE9192", "Comment" : "Comment to note", "RecordedDateTime":"2021-01-21 06:27:10"}]}" */}
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                    {/* <div className="col-12 pt-2">
                      <Select
                        className="emoji-dropdown"
                        // defaultValue={
                        //   treatmentPlanInterventionId &&
                        //   treatmentPlanInterventionId[record.ClientId]
                        // }
                        style={{ width: 65 }}
                        // onChange={value => {
                        //   this.handleSelectChange(record.ClientId, value);
                        // }}
                      >
                        {EmojiList &&
                          EmojiList.map(item => {
                            return <Option value={item.Value}>{item.Text}</Option>;
                          })}
                      </Select>{' '}
                      <div className="d-inline">
                        <img src="/images/chatCount.svg" style={{ verticalAlign: 'text-bottom' }} />{' '}
                        {CommentCount}
                      </div>
                    </div> */}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  get_student_note,
  get_student_noteEmoji,
  get_student_noteComment,
  add_student_noteComment,
  student_journal_augment_get,
}) => {
  const { data, isFetching, error } = get_student_note;
  const { data: emojiData } = get_student_noteEmoji;
  const { data: studentCommentData, isFetching: isFetchingComment } = get_student_noteComment;
  const { data: addCommentData, isFetching: isFetchingAddComment } = add_student_noteComment;
  const {
    data: studentJournalNote,
    isFetching: isStudentJournalFetching,
  } = student_journal_augment_get;
  let StudentNote = [];
  let UserComment = [];
  let EmojiList = [];
  let isCommented = false;
  let journalNotes = {};

  if (studentJournalNote && studentJournalNote.Notes && studentJournalNote.Notes[0]) {
    journalNotes = studentJournalNote && studentJournalNote.Notes && studentJournalNote.Notes[0];
  }
  if (data && data.StudentNote) {
    StudentNote = data.StudentNote || [];
  }
  if (emojiData && emojiData.EmojiList) {
    EmojiList = emojiData.EmojiList || [];
  }
  if (studentCommentData && studentCommentData.StudentNoteComment) {
    UserComment = studentCommentData.StudentNoteComment || [];
  }
  if (addCommentData && addCommentData.success) {
    isCommented = true;
  }
  return {
    isFetching: isFetching,
    isFetchingComment: isFetchingComment,
    StudentNote: StudentNote,
    EmojiList,
    UserComment,
    isCommented,
    journalNotes,
  };
};
export default connect(mapStateToProps)(NotesFromClient);
