import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { ProcessCombos } from '../../../../store/comboConfig';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  ReduxTimePicker,
  CheckboxGroup,
} from '../../../../component/custom-redux-components';
import PersistTableSort from '../../../../component/persistTableSort';
import Loader, { Loader2 } from '../../../../Components/Loader';
import {
  listServiceLinePayment,
  upsertServiceLinePayment,
  deleteServiceLinePayment,
  getServiceLinePayment,
  listCWPayment,
  getPatientList,
  listServiceLine,
  upsertPaymentClient,
  comboPayer,
} from '../../../../store/actions';
import AntdModal from '../../../../Components/CustomModal';
import CustomModal from '../../../../core/modal';
import { Table, Modal, Tooltip } from 'antd';
import validate from '../../validation/validate';
import Autocomplete from 'react-autocomplete';
import { timeConvert } from '../../../../util/time';
import { EditableRow, EditableCell } from './customScreens/EditableTable';
import moment from 'moment';
import { data } from 'jquery';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
import renderHTML from 'react-render-html';
import SubmitButton from '../../../../Components/submitButton';

class PaymentServiceLineDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createAndEditPaymentModal: false,
      ServiceLineData: [],
      editedSource: {},
      isModelOpen: false,
      paymentFormData: {},
      IsEdit: false,
    };
  }

  componentDidMount() {
    var result = ProcessCombos('PaymentSource,CWPaymentMethod,');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(comboPayer({}));
  }
  clearServiceLine = value => {
    if (value != this.props.PayerIdValue) {
      this.setState({
        ServiceLineData: [],
      });
    }
  };

  componentWillReceiveProps({
    isInserted,
    upsertData,
    PayerIdValue,
    PayerInfoData,
    PaymentServiceList,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      // this.props.history.push('/cw/accounting?tab=insurance-payment-list');
      if (upsertData.Result === -1) {
        this.setState({
          WarningMessage: upsertData.WarningMessage,
        });
      } else {
        this.props.dispatch(listCWPayment({}));
        this.toggle('Payment updated successfully');
      }
    }
    // if (PayerIdValue != this.props.PayerIdValue) {
    //   this.setState({
    //     ServiceLineData: [],
    //   });
    // }
    if (
      PayerInfoData &&
      JSON.stringify(PayerInfoData) != JSON.stringify(this.props.PayerInfoData)
    ) {
      let PaymentKeyAdded;
      if (PaymentServiceList) {
        PaymentKeyAdded = {
          ...PaymentServiceList[0],
          AmountPaid: PaymentServiceList[0].CWPSL_Amount,
        };
      } else {
        PaymentKeyAdded = null;
      }
      let NewList =
        PaymentServiceList &&
        PaymentServiceList.map(item => {
          return {
            ...item,
            AmountPaid: item.CWPSL_Amount,
          };
        });
      this.setState({
        paymentFormData: {
          ...PayerInfoData,
          PaymentDate: moment(PayerInfoData.PaymentDate).format('YYYY-MM-DD'),
        },
        ServiceLineData: NewList,
      });
    }
    if (
      PaymentServiceList &&
      JSON.stringify(PaymentServiceList) != JSON.stringify(this.props.PaymentServiceList)
    ) {
      let PaymentKeyAdded;
      if (PaymentServiceList) {
        PaymentKeyAdded = {
          ...PaymentServiceList[0],
          AmountPaid: PaymentServiceList[0].CWPSL_Amount,
        };
      } else {
        PaymentKeyAdded = null;
      }
      let NewList = PaymentServiceList.map(item => {
        return {
          ...item,
          AmountPaid: item.CWPSL_Amount,
        };
      });
      this.setState({
        paymentFormData: {
          ...PayerInfoData,
          PaymentDate: moment(PayerInfoData.PaymentDate).format('YYYY-MM-DD'),
        },
        ServiceLineData: NewList,
      });
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  };
  handleSave = row => {
    const newData = [...this.state.ServiceLineData];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    let editedSource = this.state.editedSource;
    let newSource = {
      ...editedSource,
      [row.key]: row,
    };
    this.setState({
      ServiceLineData: newData,
      editedSource: newSource,
    });
  };
  getServiceLineRecord = value => {
    debugger;
    let newArray = [...this.state.ServiceLineData, ...value];
    let uniqueServiceLineArray = this.getUniqueListBy(newArray, 'ServiceLineId');
    this.setState({
      ServiceLineData: uniqueServiceLineArray,
      createAndEditPaymentModal: false,
    });
  };
  removeServiceline = (index, onOk) => {
    let dataSource = [...this.state.ServiceLineData];
    dataSource.splice(index, 1);
    this.setState({
      ServiceLineData: dataSource,
    });
    onOk();
  };
  setSaveForNow = val => {
    this.setState({ IsEdit: val });
  };
  onSubmit(values) {
    const {
      Amount,
      CheckInfo,
      Payer,
      PayerType,
      PaymentDate,
      PaymentMethode,
      PaymentSourceId,
      PayerId,
      PaymentMethodId,
      PaymentNote,
      ChequeNumber,
    } = values;
    const { ServiceLineData, editedSource, IsEdit, paymentFormData } = this.state;
    let gridData = [];
    gridData =
      ServiceLineData &&
      ServiceLineData.map((item, index) => {
        return {
          CWPaymentServiceLineId: item.CWPaymentServiceLineId || null,
          ServiceLineId: item.ServiceLineId,
          CWPSL_Amount: parseFloat(item.AmountPaid),
          AdjustmentAmount: item.AdjustmentAmount,
          ExplanationCode: item.ExplanationCode,
          Allowed: item.Allowed,
          Deduct: item.Deduct,
          CoPay: item.CoPay,
          CoInsurance: item.CoInsurance,
          Discount: item.Discount,
          Interest: item.Interest,
          MedicareAllowed: item.MedicareAllowed,
          MedicarePaid: item.MedicarePaid,
          ThirdParty: item.ThirdParty,
          Withheld: item.Withheld,
        };
      });
    let data = {
      Json: JSON.stringify({
        CWPayment: [
          {
            CWPaymentId: paymentFormData.CWPaymentId || null,
            PaymentDate: moment(PaymentDate).format('YYYY-MM-DD'),
            PaymentSourceId: PaymentSourceId,
            PaymentMethodId: PaymentMethodId,
            Amount: Amount,
            Note: PaymentNote,
            CWPaymentServiceLine: gridData,
            PayerId: PayerId,
            ChequeNumber: ChequeNumber,
            IsEdit: IsEdit,
          },
        ],
      }),
    };
    this.props.dispatch(upsertPaymentClient(data));
  }
  render() {
    const { Payment, CWPaymentMethod, toggleView, Payer } = this.props;
    const { ServiceLineData, WarningMessage } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = [
      {
        title: 'Patient ID',
        // dataIndex: 'ClientId',
        dataIndex: 'DisplayClientId',
        key: 'ClientId',
        // sorter: (a, b) => isNumericComparer(a.ClientId, b.ClientId),
        sorter: (a, b) => isNumericComparer(a.DisplayClientId, b.DisplayClientId),
        width: 100,
        // fixed: 'left',
      },
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        width: 150,
        // fixed: 'left',
      },
      {
        // title: 'Appointment Date',
        title: (
          <span>
            Appointment <br />
            Date
          </span>
        ),
        dataIndex: 'DateofService',
        key: '3',
        sorter: (a, b) => isComparer(a.DateofServiceForSort, b.DateofServiceForSort),
      },
      {
        title: 'Start Time',
        dataIndex: 'StartTime',
        key: '4',
        sorter: (a, b) => isComparer(a.StartTime, b.StartTime),
      },
      {
        title: 'Service Code',
        dataIndex: 'ServiceCode',
        key: '5',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
      },
      {
        title: 'Billed',
        dataIndex: 'TotalAmount',
        key: '6',
        sorter: (a, b) => isNumericComparer(a.TotalAmount, b.TotalAmount),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Amt Due',
        dataIndex: 'TotalDue',
        key: '7',
        sorter: (a, b) => isNumericComparer(a.TotalDue, b.TotalDue),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: (
          <span>
            Adjustment <br />
            Amt
          </span>
        ),
        // title: 'Adjustment Amount',
        dataIndex: 'AdjustmentAmount',
        key: '8',
        // align: 'right',
        editable: true,
        // render(text, record) {
        //   return {
        //     props: {
        //       style: { background: '#e6f7ff' },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'AdjustmentAmount',
          title: 'Amount Paid',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Amt Paid',
        dataIndex: 'AmountPaid',
        key: '9',
        // align: 'right',
        editable: true,
        // render(text, record) {
        //   return {
        //     props: {
        //       style: { background: '#e6f7ff' },
        //     },
        //     children: <div>{text}</div>,
        //   };
        // },
        onCell: record => ({
          record,
          editable: true,

          dataIndex: 'AmountPaid',
          title: 'Amount Paid',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Explanation Code',
        dataIndex: 'ExplanationCode',
        key: '10',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          isString: true,
          required: false,
          dataIndex: 'ExplanationCode',
          title: 'Expl Code',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Allowed',
        dataIndex: 'Allowed',
        key: '11',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'Allowed',
          title: 'Allowed',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Deduct',
        dataIndex: 'Deduct',
        key: '12',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'Deduct',
          title: 'Deduct',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'CoPay',
        dataIndex: 'CoPay',
        key: '13',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'CoPay',
          title: 'CoPay',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'CoInsurance',
        dataIndex: 'CoInsurance',
        key: '14',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'CoInsurance',
          title: 'CoInsurance',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Discount',
        dataIndex: 'Discount',
        key: '15',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'Discount',
          title: 'Discount',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Interest',
        dataIndex: 'Interest',
        key: '16',
        editable: true,
        required: false,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'Interest',
          title: 'Interest',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Medicare Allowed',
        dataIndex: 'MedicareAllowed',
        key: '17',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'MedicareAllowed',
          title: 'MedicareAllowed',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Medicare Paid',
        dataIndex: 'MedicarePaid',
        key: '18',
        editable: true,
        required: false,
        onCell: record => ({
          record,
          editable: true,
          dataIndex: 'MedicarePaid',
          title: 'MedicarePaid',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Third Party',
        dataIndex: 'ThirdParty',
        key: '19',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'ThirdParty',
          title: 'ThirdParty',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Payment Received',
        dataIndex: 'Withheld',
        key: '20',
        editable: true,
        onCell: record => ({
          record,
          editable: true,
          required: false,
          dataIndex: 'Withheld',
          title: 'Withheld',
          handleSave: this.handleSave,
        }),
      },
      {
        title: 'Action',
        render: (text, record, index) => (
          <div class="tools-grid d-flex align-items-center" style={{ border: '0px solid red' }}>
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Payment"
                  >
                    <Tooltip placement="top" title="Delete">
                      <p>
                        <i class="far fa-trash-alt"></i>
                      </p>
                    </Tooltip>
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to remove this record?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.removeServiceline(index, onOk);
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    return (
      <div className="row">
        <div className="col-12">
          <div className="group-service-detail">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center my-2">
                  <div
                    onClick={() => {
                      toggleView();
                    }}
                    className="text-left d-flex"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="images/back-arrow.svg" /> Back to Payment List
                  </div>
                </div>
              </div>
              <div className="">
                <ReduxPaymentForm
                  initialValues={this.state.paymentFormData}
                  PaymentOption={Payment}
                  CWPaymentMethodOption={CWPaymentMethod}
                  Payer={Payer}
                  onSaveClick={this.onSubmit.bind(this)}
                  setSaveForNow={this.setSaveForNow}
                  clearServiceLine={this.clearServiceLine}
                  isUpsertFetching={this.props.isUpsertFetching}
                  IsEdit={this.state.IsEdit}
                />
              </div>
              {WarningMessage && (
                <div className="" style={{ paddingLeft: '24px' }}>
                  <p className="text-danger">{WarningMessage}</p>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <span
                  style={{
                    width: '150px',
                    color: '#1890ff',
                    padding: '10px',
                    cursor: 'pointer',
                    fontWeight: '800',
                  }}
                  onClick={event => {
                    this.setState({
                      createAndEditPaymentModal: true,
                    });
                  }}
                >
                  Add Service Line(s)
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <PersistTableSort
                  name="pament-list"
                  // columns={tableCoumns}
                  // dataSource={paymentList}
                  components={components}
                  size={'middle'}
                  columns={columns}
                  dataSource={ServiceLineData}
                  pagination={false}
                  rowClassName={() => 'editable-row'}
                />

                {/* <Table
                  // components={components}
                  rowClassName={() => 'editable-row'}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={ServiceLineData}
                  pagination={false}
                /> */}
              </div>
            </div>
          </div>
        </div>
        {
          <Modal
            zIndex={100}
            visible={this.state.createAndEditPaymentModal}
            footer={null}
            closable={false}
            width={900}
            destroyOnClose={true}
          >
            <ReduxSelectPatientServiceLine
              getServiceLineRecord={this.getServiceLineRecord}
              onCancel={() => {
                this.setState({
                  createAndEditPaymentModal: false,
                  PayerInfo: {},
                });
              }}
            />
          </Modal>
        }
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            // msgContentArray={ this.state.msgContentArray}
            yesAction={() => {
              this.toggle();
              this.props.toggleView();
              // onCancel();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { combos, upsert_payment_client, combo_payer, get_CWPayment } = state;
  // const { data: ServiceLinePaymentData, isFetching } = list_service_line_payment;
  const { data, isFetching } = get_CWPayment;
  const { isFetching: isUpsertFetching } = upsert_payment_client;


  let isInserted = false;
  let Payment = [];
  let CWPaymentMethod = [];
  let Payer = [];
  let upsertData = [];
  let PayerInfoData = {};
  let PaymentServiceList = [];

  if (combos.data && combos.data['PaymentSource']) {
    Payment = combos.data['PaymentSource'];
    CWPaymentMethod = combos.data['CWPaymentMethod'];
  }
  if (upsert_payment_client.data && upsert_payment_client.data.success) {
    isInserted = true;
    upsertData = JSON.parse(upsert_payment_client.data.data)[0];
  }
  if (combo_payer.data && combo_payer.data.Payer) {
    Payer = combo_payer.data.Payer || [];
  }
  if (data && data.data && data.data[0]) {
    PayerInfoData = data.data[0];
    PaymentServiceList = JSON.parse(data.data[0].CWPaymentServiceLine);
  }
  return {
    Payment,
    CWPaymentMethod,
    isInserted,
    upsertData,
    Payer,
    PayerIdValue: selector(state, 'PayerId'),
    PayerInfoData,
    PaymentServiceList,
    isFetching,
    isUpsertFetching,
  };
};

class SelectPatientServiceLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedClient: {},
      selectedRowKeys: [],
      ServiceList: [],
      record: [],
      columns: [
        {
          title: 'Appointment Date',
          dataIndex: 'SessionDate',
          key: '1',
          sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
        },
        {
          title: 'Start Time',
          dataIndex: 'StartTime',
          key: '2',
          sorter: (a, b) => isComparer(a.StartTime, b.StartTime),
        },
        {
          title: 'Service Code',
          dataIndex: 'ServiceCode',
          key: '3',
          sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        },
        {
          title: 'Total Cost',
          dataIndex: 'TotalAmount',
          key: '4',
          sorter: (a, b) => isNumericComparer(a.TotalAmount, b.TotalAmount),
          sortDirections: ['descend', 'ascend'],
          render: (text, record) => {
            return <div className="">{currencyFormatter.format(text || 0)}</div>;
          },
        },
        {
          title: 'Amt Due',
          dataIndex: 'TotalDue',
          key: '5',
          sorter: (a, b) => isNumericComparer(a.TotalDue, b.TotalDue),
          sortDirections: ['descend', 'ascend'],
          render: (text, record) => {
            return <div className="">{currencyFormatter.format(text || 0)}</div>;
          },
        },
      ],
    };
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };
  componentDidMount() {
    this.props.dispatch(
      getPatientList({
        Json: JSON.stringify({
          Client: [
            {
              FilterBy: 'Active',
              PayerId: this.props.PayerIdValue,
              IsPaymentScreen: true,
              Amount: this.props.AmountValue,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ ServiceList }) {
    if (ServiceList && JSON.stringify(ServiceList) != JSON.stringify(this.props.ServiceList)) {
      this.setState({ ServiceList });
    }
  }
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    var data = {
      Client: [
        {
          Search: value,
          FilterBy: 'Active',
          PayerId: this.props.PayerIdValue,
          IsPaymentScreen: true,
          Amount: this.props.AmountValue,
        },
      ],
    };
    var appointmentData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(getPatientList(appointmentData));

    // Json - {"Client":[{"Search":"SHARMA"}]}
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  render() {
    const { onCancel, getServiceLineRecord, PayerIdValue } = this.props;
    const { searchedClient, columns, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => {
        return {
          name: record.ServiceLineId,
        };
      },
      // onSelect: row => {
      //   // debugger;
      //   // AppointmentId;
      //   let stateRecord = this.state.record;
      //   let newRecord = { ...row, AdjustmentAmount: row.TotalAmount, AmountPaid: row.TotalAmount };
      //   debugger;
      //   // let index = stateRecord.indexOf(newRecord.ServiceLineId);
      //   // newArray.splice(newArray.indexOf(record.ClientId), 1);
      //   // stateRecord.map((item, index) => {
      //   //   if (item.ServiceLineId === row.ServiceLineId) index = index;
      //   // });
      //   // const index = stateRecord.findIndex((item, index) => {
      //   //   if (row.ServiceLineId === item.ServiceLineId) return index;
      //   // });
      //   debugger;
      //   if (index) {
      //     stateRecord[index] = newRecord;
      //     this.setState({
      //       record: stateRecord,
      //     });
      //   } else {
      //     this.setState({
      //       record: [...stateRecord, newRecord],
      //     });
      //   }
      // },
    };
    return (
      <>
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-center">Patient & Service Line(s)</h4>
          <hr />
        </div>
        <div class="modal-body service-row enrollment-form px-4">
          <div className="row">
            <div className="col-12">
              <div class="w-90">
                <div class="form-group has-search searchDiv">
                  <Autocomplete
                    // getItemValue={item => `${item[SearchOption[TabName].idKey]}`}
                    getItemValue={item => {
                      return JSON.stringify(item.ClientId);
                    }}
                    // items={SearchOption[TabName].SearchOption.map(item => {
                    //   return {
                    //     label: item.DisplayName,
                    //     ...item,
                    //   };
                    // })}
                    items={this.props.client.map(item => {
                      return {
                        label: ` ${item.FirstName} ( ${item.DisplayClientId} )`,
                        ...item,
                      };
                    })}
                    // renderItem={(item, isHighlighted) => {
                    //   return (
                    //     <div
                    //       key={item[SearchOption[TabName].idKey]}
                    //       style={{
                    //         margin: '5px',
                    //         background: isHighlighted ? 'lightgray' : 'white',
                    //       }}
                    //     >
                    //       {item.label}
                    //     </div>
                    //   );
                    // }}
                    renderItem={(item, isHighlighted) => {
                      return (
                        <div
                          style={{
                            background: isHighlighted ? 'lightgray' : 'white',
                            padding: '10px 5px',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {renderHTML(
                            `<img class="autoSelectImage" src=${
                              item.ProfileImgUrl ? item.ProfileImgUrl : 'images/blank-profile.png'
                            }  width="40" height="40">`,
                          )}
                          &nbsp; &nbsp;{' '}
                          <span style={{ color: 'black', fontWeight: 'bold' }}>
                            {item.LastName}
                          </span>
                          ,&nbsp; <span style={{ color: 'black' }}>{item.FirstName}</span> &nbsp;{' '}
                          <span style={{ color: 'grey' }}>({item.DisplayClientId})</span>
                        </div>
                      );
                    }}
                    // value={value}
                    // onChange={e => (value = e.target.value)}
                    // onSelect={val => (value = val)}

                    onChange={this.studentSearch}
                    ref={ref => (this.input = ref)}
                    // onChange={e => {
                    //   this.setState({ searchStr: e.target.value });
                    //   this.props.dispatch(
                    //     SearchOption[TabName].dispatchMethod({ SearchStr: e.target.value }),
                    //   );
                    // }}

                    onSelect={value => {
                      if (this.props.client.length > 0) {
                        let searchResult = this.props.client.filter(item => {
                          if (item.ClientId == parseInt(value)) {
                            return item;
                          } else {
                            return null;
                          }
                        });
                        // this.props.setSearchClientValues(searchResult[0]);
                        this.props.dispatch(
                          listServiceLine({
                            json: JSON.stringify({
                              ServiceLine: [
                                {
                                  ClientId: value,
                                  IsPaymentScreen: true,
                                },
                              ],
                            }),
                          }),
                        );
                        this.setState({ searchedClient: searchResult[0] });
                      } else {
                        return [];
                      }

                      // this.props.history.push(SearchOption[TabName].pushKey + value);
                    }}
                    value={this.state.searchStr}
                    // wrapperStyle={{ display: 'unset' }}
                    renderInput={props => (
                      <input
                        type="text"
                        {...props}
                        class="form-control shadow-sm search-placeholder"
                        placeholder="Search Patient"
                        style={{
                          paddingLeft: '30px',
                          background: 'rgb(255, 255, 255)',
                          boxShadow: 'rgba(0, 0, 0, 0.043) 0px 7px 28px',
                          borderRadius: ' 26px',
                        }}
                      />
                    )}
                    menuStyle={{
                      borderRadius: '3px',
                      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                      background: 'rgba(255, 255, 255, 0.9)',
                      padding: this.state.searchStr ? '4px 4px' : '0px 4px',
                      fontSize: '90%',
                      position: 'fixed',
                      overflow: 'auto',
                      maxHeight: '50%',
                      zIndex: 1,
                    }}
                  />
                  {/* <i class="fas fa-search form-control-icon" /> */}
                </div>
              </div>
            </div>
          </div>
          {searchedClient.ClientId && (
            <div className="payer-info-section">
              <div className="section-col">
                <div className="payer-info-item">
                  <span className="label">ClientId </span>
                  <span className="value">{searchedClient.DisplayClientId}</span>
                </div>
                <div className="payer-info-item">
                  <span className="label">Name </span>
                  <span className="value">{searchedClient.FirstName}</span>
                </div>
                <div className="payer-info-item">
                  <span className="label">Date Of Birth </span>
                  <span className="value">
                    {moment(searchedClient.DateOfBirth).format('MM-DD-YYYY')}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={this.state.ServiceList}
                pagination={false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right pt-3 pb-0">
              <input
                type="button"
                style={{ width: '150px' }}
                class="btn"
                value={'Add'}
                disabled={!selectedRowKeys.length > 0}
                onClick={() => {
                  let stateRecord = this.state.selectedRows;
                  let newRecord = stateRecord.map(item => {
                    return {
                      ...item,
                      AdjustmentAmount: 0,
                      AmountPaid: item.TotalDue,
                    };
                  });
                  getServiceLineRecord(newRecord);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToPropsChild = state => {
  const { combos, get_patient_list, list_service_line } = state;
  const { data: getPatientListData } = get_patient_list;
  const { data: serviceListData, isFetching } = list_service_line;

  let Payment = [];
  let Client = [];
  let ServiceList;

  if (getPatientListData && getPatientListData.data) {
    Client = JSON.parse(getPatientListData.data)[0].Client;
  }
  if (serviceListData && serviceListData.ServiceLine) {
    let data = [];
    data = serviceListData.ServiceLine.map(item => {
      return { key: item.ServiceLineId, ...item };
    });

    ServiceList = data || [];
  }

  return {
    client: Client || [],
    ServiceList,
    PayerIdValue: selector(state, 'PayerId'),
    AmountValue: selector(state, 'Amount'),
  };
};
const ReduxSelectPatientServiceLine = connect(mapStateToPropsChild)(SelectPatientServiceLine);

class PaymentForm extends React.Component {
  render() {
    const {
      handleSubmit,
      disable,
      onSaveClick,
      isFetching,
      PaymentOption,
      CWPaymentMethodOption,
      onCancel,
      ServiceLineId,
      PaymentRecordVal,
      Payer,
      PaymentSourceValue,
      setSaveForNow,
      isUpsertFetching,
      IsEdit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(onSaveClick)}>
        <div class="service-row enrollment-form px-4">
          <div className="row mb-4">
            <div className="col-12 text-right pr-4 mb-2">
              {/* <button
                type="submit"
                class="btn px-3 mr-3"
                onClick={() => {
                  setSaveForNow(true);
                }}
              >
                Save For Now
              </button> */}
              <SubmitButton
                loading={IsEdit && isUpsertFetching}
                className="px-3 mr-3"
                style={{ height: '45px' }}
                onClick={() => {
                  setSaveForNow(true);
                }}
                value={'Save For Now'}
              />
              {/* <button
                type="submit"
                class="btn px-3"
                onClick={() => {
                  setSaveForNow(false);
                }}
              >
                Final Submit
              </button> */}
              <SubmitButton
                loading={!IsEdit && isUpsertFetching}
                style={{ height: '45px' }}
                className="px-3"
                onClick={() => {
                  setSaveForNow(false);
                }}
                value={'Final Submit'}
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`PaymentDate`}
                  validate={validate.required}
                  fieldName={'Payment Date'}
                  type="text"
                  component={ReduxDateInput}
                  label={'Payment Date* '}
                />
              </div>
            </div>
            {/* <div class="col-4">
            <div class="form-group">
              <Field
                name={`PayerType`}
                // validate={validate.required}
                type="text"
                component={ReduxSelect}
                placeholder={`Payer Type`}
                options={[{ Value: '1', Text: 'Patient' }]}
                fieldName={'Payer Type'}
                disabled={disable}
              />
            </div>
          </div>
         */}
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`PaymentSourceId`}
                  validate={validate.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Payment Source*`}
                  options={PaymentOption}
                  fieldName={'Payment Source'}
                  // disabled={disable}
                />
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              {PaymentSourceValue === 25101 && (
                <div class="form-group">
                  <Field
                    name={`PayerId`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Insurance Payer`}
                    options={Payer}
                    fieldName={'Insurance Payer'}
                    onChange={value => {
                      this.props.clearServiceLine(value);
                    }}
                    // disabled={disable}
                  />
                </div>
              )}
            </div>

            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`PaymentMethodId`}
                  validate={validate.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Payment Method*`}
                  options={CWPaymentMethodOption}
                  fieldName={'Payment Method'}
                  // disabled={disable}
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`ChequeNumber`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Check Number'}
                  disabled={disable}
                />
                <label class="placeholder-label">Check Number </label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`Amount`}
                  type="text"
                  component={ReduxInput}
                  validate={[validate.required, validate.allowAmount]}
                  fieldName={'Amount'}
                />
                <label class="placeholder-label">Amount*</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-8">
              <div class="form-group">
                <Field
                  name={`PaymentNote`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Payment Note'}
                  disabled={disable}
                />
                <label class="placeholder-label">Payment Note</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

// const mapStateToProps = state => {
//   const { combos, medicate_combos } = state;
//   return {
//     // WorkSkill: combos.data.WorkSkill,
//     // isEmployedValue: selector(state, 'isEmployed'),
//   };
// };
const ReduxPaymentForm = connect(state => ({
  PaymentSourceValue: formValueSelector('payerForm')(state, 'PaymentSourceId'),
  PayerIdValue: formValueSelector('payerForm')(state, 'PayerId'),
}))(
  reduxForm({
    form: 'payerForm',
    enableReinitialize: true,
  })(PaymentForm),
);

const selector = formValueSelector('payerForm');

export default connect(mapStateToProps)(PaymentServiceLineDetail);
