import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicGrid from '../../Components/DynamicGrid';
import PageTitle from '../../Components/PageTitle';
class GridData extends Component {
  state = {
    searchStr: null,
  };

  render() {
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Announcement List" />
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.goBack()}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Announcements(Dynamic Screen)</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end"></div>
          </div>
          <div className="dynamic-grid">
            <DynamicGrid ScreenName="Announcement" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(GridData);
