import React from 'react';

class TestPage extends React.PureComponent {
  render() {
    const { header, currentUser, students } = this.props;
    return (
      <div>
        {header}
        <br />
        Form Panel
        <div>
          Exising User:
          <br />
          username : {currentUser.UserName}
          <br />
          password : {currentUser.Password}
        </div>
        <br />
        <table>
          <thead>
            <td> User Name</td>
            <td> Password</td>
          </thead>
          <tbody>
            {students.map(item => (
              <tr>
                <td> {item.UserName} </td>
                <td> {item.Password} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

TestPage.defaultProps = {
  header: 'Welcome to Demo Page',
  currentUser: {
    UserName: 'Arjun',
    Password: 'Krishna',
  },
  students: [
    { UserName: 'Krishna', Password: 123 },
    { UserName: 'Preethi', Password: 34343 },
    { UserName: 'Neha', Password: 3434 },
  ],
};

export default TestPage;
