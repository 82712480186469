import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import Loader, { Loader2 } from '../../Components/Loader';

import {
  getStudent,
  addStudent,
  resetAddRequest,
  appInviteDashboard,
  userAppLinkValidate,
  resetUserAppLinkValidate,
  resetGetStudent,
  listSurveyQuestionAssessment,
  surveyAnswerUpsert,
  augmentedClientInsightsGet,
} from '../../store/actions';
import QuestionType from '../RCM/patientEnrollmentForm/formSteps/questionType/questionComponent';
import PageTitle from '../../Components/PageTitle';
class AugmentedResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getIntakeId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentId = param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = null;
    }
    return studentId;
  }
  getSurveyId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let surveyid = param.get('surveyid');
    if (surveyid) {
      surveyid = parseInt(surveyid);
    } else {
      surveyid = null;
    }
    return surveyid;
  }
  componentDidMount() {
    let {
      FeatureFlags: { preIntakeAugmentedInsight },
    } = this.props;
    this.props.dispatch(
      listSurveyQuestionAssessment({
        json: JSON.stringify({
          Survey: [
            {
              SurveyId: this.getSurveyId(),
            },
          ],
        }),
      }),
    );
    if (preIntakeAugmentedInsight)
      this.props.dispatch(
        augmentedClientInsightsGet({
          StudentId: this.getIntakeId(),
          SurveyId: this.getSurveyId(),
        }),
      );
  }
  componentWillReceiveProps({ surveyQuestion }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      this.setState({ SurveyQuestionList: surveyQuestion });
    }
  }
  render() {
    const { SurveyQuestionList } = this.state;
    const {
      augmentedData,
      augmentedAnswer,
      isFetching,
      FeatureFlags: { preIntakeAugmentedInsight },
    } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Prospect Survey Result " />

        {SurveyQuestionList && (
          <div class="container-fluid enrollment-form pre-intake-form">
            <div class="row top-table-search my-2 align-items-center">
              <div class="col-12 col-md-4 col-lg-4">
                <a
                  class="back-link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.history.goBack()}
                >
                  <img src="images/back-arrow.svg" /> Back
                </a>
                <h3 class="page-title mb-3 mb-md-0">Prospect Survey Result</h3>
              </div>
              <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
                <input
                  type="button"
                  class=" btn"
                  value="Done"
                  onClick={() => {
                    this.props.history.replace(
                      `/clients/pre-intake/dashboard?id=${this.getIntakeId()}`,
                    );
                    // this.props.history.replace(`pre-intake-form/?studentid=${this.getIntakeId()}`);
                  }}
                />
              </div>
            </div>
            <div class="row pb-5">
              {preIntakeAugmentedInsight && (
                <div className="col-12 mb-2 py-2">
                  <div class="card border-0 rounded-0">
                    <Loader2 loading={isFetching} />

                    <div class="card-body">
                      <h5>{augmentedData && augmentedData.ShortQuestion}</h5>
                      <p>{augmentedAnswer && <>{renderHTML(augmentedAnswer)}</>}</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-12">
                <div class="card border-0 rounded-0">
                  <div class="card-body" style={{ pointerEvents: 'none' }}>
                    <div className="row survery-question-box" style={{ maxWidth: 'unset' }}>
                      <div className="col-12">
                        {SurveyQuestionList && SurveyQuestionList.SurveyQuestion && (
                          <div
                            style={{
                              padding: '5px',
                              margin: '0px 5px',
                            }}
                          >
                            <QuestionType
                              SurveyQuestion={SurveyQuestionList.SurveyQuestion}
                              SurveyId={SurveyQuestionList.SurveyId}
                              //   onNext={this.onNext}
                              //   setValue={this.setValue}
                              scope={this}
                              level={0}
                              //   isReadOnly={true}
                              // saveSignature={this.saveSignature}
                            />
                          </div>
                        )}
                        {SurveyQuestionList &&
                          SurveyQuestionList.SurveyCategory &&
                          SurveyQuestionList.SurveyCategory.map((surveyCategory, index) => {
                            return (
                              <div>
                                <div
                                  className="assessment-sub-title"
                                  style={{
                                    margin: '0px 25px',
                                  }}
                                >
                                  <span className="sub-title-text">
                                    {surveyCategory.SurveyCategory}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: '#F8F8F8',
                                    padding: '25px',
                                    margin: '0px 25px',
                                  }}
                                >
                                  <QuestionType
                                    SurveyQuestion={surveyCategory.SurveyQuestion}
                                    SurveyId={SurveyQuestionList.SurveyId}
                                    // onNext={this.onNext}
                                    // setValue={this.setValue}
                                    scope={this}
                                    level={1}
                                    subLevel={index}
                                    // isReadOnly={true}
                                    // saveSignature={this.saveSignature}
                                  />

                                  {surveyCategory &&
                                    surveyCategory.SurveySubCategory &&
                                    surveyCategory.SurveySubCategory.map(
                                      (subCategory, subIndex) => {
                                        return (
                                          <div
                                            style={{
                                              backgroundColor: '#FFF',
                                              padding: '15px',
                                            }}
                                          >
                                            <div className="assessment-sub-title">
                                              <span className="sub-title-text">
                                                {subCategory.SurveySubCategory}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                backgroundColor: '#FFF',
                                                padding: '15px',
                                                // margin: '0px 25px',
                                              }}
                                            >
                                              <QuestionType
                                                SurveyQuestion={subCategory.SurveyQuestion}
                                                SurveyId={SurveyQuestionList.SurveyId}
                                                // onNext={this.onNext}
                                                // setValue={this.setValue}
                                                scope={this}
                                                level={2}
                                                subLevel={index}
                                                subCatLevel={subIndex}
                                                // isReadOnly={true}
                                                // saveSignature={this.saveSignature}
                                              />
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    list_survey_question_assessment,
    augmented_client_insights_get,
    user_feature_flag_get,
  } = state;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = list_survey_question_assessment;
  const { data, isFetching } = augmented_client_insights_get;
  const { data: fFData } = user_feature_flag_get;

  let surveyQuestion;
  let augmentedData;
  let augmentedAnswer;
  let FeatureFlags = {};

  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }
  if (data && data.data) {
    augmentedData = data.data[0];
    augmentedAnswer = data.data[0].Answer;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
  }
  return {
    surveyQuestion,
    isFetchingSurveyQuestion: isFetchingSurveyQuestion,
    augmentedData: augmentedData,
    augmentedAnswer,
    isFetching,
    FeatureFlags,
  };
};
export default connect(mapStateToProps)(AugmentedResult);
