import React, { Component } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import validate, {
  dateofbirthNormalize,
  MobileFormatter,
  MobileNormalize,
  ssnFormatter,
  ssnNormalize,
} from '../../validation/validate';
import { withRouter } from 'react-router-dom';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import CustomModal from '../../../../core/modal';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../../core/dynamic-form';

import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
  ReduxToggle,
  ReduxAutoComplete,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { listCombos, medicateCombos } from '../../../../store/actions';
import {
  getPatientDetail,
  upsertClientInsurance,
  resetUpsertClientInsurance,
  getInsurancePriorityOrder,
  upsertInsurancePriorityOrder,
  clientBannerGet,
} from '../../../../store/actions';
import { stringDate } from '../../../../util/date';
import moment from 'moment';
import ManageEligibility from './manageEligibility';
import { ProcessCombos } from '../../../../store/comboConfig';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Table, Modal } from 'antd';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { MenuOutlined } from '@ant-design/icons';
import SubmitButton from '../../../../Components/submitButton';
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999', marginRight: '10px' }} />
));

const insuranceCount = {
  '0': 'PRIMARY',
  '1': 'SECONDARY',
  '2': 'TERTIARY',
  '3': 'QUATERNARY',
  '4': 'QUINARY',
  '5': 'SENARY',
  '6': 'SEPTENARY',
  '7': 'OCTONARY',
  '8': 'NONARY',
  '9': 'DENARY',
};
const SortableItem = sortableElement(({ value, tempIndex }) => (
  <li
    tabIndex={0}
    style={{
      padding: '10px',
      margin: '10px',
      background: 'azure',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      cursor: 'grab',
      border: '1px solid #d9d9d9',
    }}
  >
    <div className="d-flex align-items-center">
      <span className="font-weight-bolder" style={{ minWidth: '100px' }}>
        {insuranceCount[tempIndex]}
      </span>
      <span style={{ minWidth: '20px' }}>: </span>
      <span> {value}</span>
    </div>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul style={{ width: '100%', paddingLeft: '0px' }}>{children}</ul>;
});

class HealthInsuranceStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setOrderModal: false,
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes: 'PatientRelationshipToInsured,InsuredAuthorization',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    var result = ProcessCombos('RCM_PayerInsurance,InsurancePriority');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillUnmount() {
    // this.props.reset();
    this.props.dispatch(reset('healthInsuranceForm'));
  }

  componentWillReceiveProps({ isInserted, isRemoved }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(resetUpsertClientInsurance());
    }
    if (isRemoved) {
      this.toggle(Delete_Sucess_Msg);
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  mapClientSubscriberToState = () => {
    let { clientInfo } = this.props;
    let ClientInsuranceData = clientInfo && clientInfo.ClientInsurance;
    let SubscriberInformations = [
      {
        patientRelationshipToPrimaryInsured: 20501,
      },
    ];
    if (ClientInsuranceData) {
      SubscriberInformations = [];
      for (let item of ClientInsuranceData) {
        let subscriberInformation = {};
        subscriberInformation.notes = item.Notes;
        subscriberInformation.clientInsuranceId = item.ClientInsuranceId;
        subscriberInformation.primaryInsurance = item.PrimaryInsuranceId;
        subscriberInformation.insuranceName = item.InsuranceName;
        subscriberInformation.insurerFirstName = item.InsurerFirstName;
        subscriberInformation.insurerLastName = item.InsurerLastName;
        subscriberInformation.insurerMiddleName = item.InsurerMiddleName;
        subscriberInformation.patientRelationshipToPrimaryInsured =
          item.PatientRelationshipToPrimaryInsured &&
          parseInt(item.PatientRelationshipToPrimaryInsured);
        subscriberInformation.insurerGroupNumber = item.InsurerGroupNumber;
        subscriberInformation.insurerPlanName = item.InsurerPlanName;
        subscriberInformation.insuredAuthorization =
          item.InsuredAuthorization && parseInt(item.InsuredAuthorization);
        subscriberInformation.insurerDeductible = item.InsurerDeductible;
        subscriberInformation.patientCoPayment = item.PatientCoPayment;
        subscriberInformation.signatureOnFile = item.InsurerReleaseOfInformation;
        subscriberInformation.signatureDate = item.InsurerSignatureDate;
        subscriberInformation.subcriberId = item.ClientSubscriberId;
        subscriberInformation.subscriberGroupNumber = item.SubscriberGroupNumber;
        subscriberInformation.subscriberFirstName = item.SubscriberFirstName;
        subscriberInformation.subscriberLastName = item.SubscriberLastName;
        subscriberInformation.subscriberMiddleName = item.SubscriberMiddleName;
        subscriberInformation.subscriberAddressLine1 = item.SubscriberAddressLine1;
        subscriberInformation.subscriberAddressLine2 = item.SubscriberAddressLine2;
        subscriberInformation.subscriberCity = item.SubscriberCity;
        subscriberInformation.subscriberStateOrProvince = item.SubscriberStateOrProvince;
        subscriberInformation.subscriberPostalCode = item.SubscriberPostalCode;
        subscriberInformation.subscriberDOB = item.SubscriberDOB
          ? stringDate(item.SubscriberDOB)
          : null;
        // item.SubscriberDOB && moment(item.SubscriberDOB).format('MM/DD/YYYY');
        subscriberInformation.subscriberSSN = item.SubscriberSSN;
        subscriberInformation.subscriberGender = item.SubscriberGender;
        subscriberInformation.subscriberMedicaidId = item.SubscriberMedicaidId;

        SubscriberInformations.push(subscriberInformation);
      }
    }
    return SubscriberInformations;
  };
  onSubmit(values) {
    const { SubscriberInformation } = values;
    // let patientId = 20000019;
    let patientId = null;
    let paramClientId = this.props.clientId;
    if (paramClientId && paramClientId > 0) {
      patientId = paramClientId;
    }

    const data = {
      Client: [
        {
          ClientInsurance:
            SubscriberInformation &&
            SubscriberInformation.map(item => {
              return {
                ClientId: patientId,
                ClientInsuranceId: item.clientInsuranceId,
                PrimaryInsuranceId: item.primaryInsurance,
                InsuranceName: item.insuranceName,
                InsurerFirstName: item.insurerFirstName,
                InsurerLastName: item.insurerLastName,
                InsurerMiddleName: item.insurerMiddleName,
                PatientRelationshipToPrimaryInsured: item.patientRelationshipToPrimaryInsured,
                InsurerGroupNumber: item.insurerGroupNumber,
                InsurerPlanName: item.insurerPlanName,
                InsuredAuthorization: item.insuredAuthorization,
                InsurerDeductible: item.insurerDeductible,
                PatientCoPayment: item.patientCoPayment,
                InsurerReleaseOfInformation: item.signatureOnFile,
                InsurerSignatureDate: stringDate(item.signatureDate),
                ClientSubscriberId: item.subcriberId || null,
                SubscriberGroupNumber: item.subscriberGroupNumber,
                SubscriberFirstName: item.subscriberFirstName,
                SubscriberLastName: item.subscriberLastName,
                SubscriberMiddleName: item.subscriberMiddleName,
                SubscriberAddressLine1: item.subscriberAddressLine1,
                SubscriberAddressLine2: item.subscriberAddressLine2,
                SubscriberCity: item.subscriberCity,
                SubscriberStateOrProvince: item.subscriberStateOrProvince,
                SubscriberPostalCode: item.subscriberPostalCode,
                SubscriberDOB: item.subscriberDOB,
                SubscriberSSN: item.subscriberSSN,
                SubscriberGender: item.subscriberGender,
                SubscriberMedicaidId: item.subscriberMedicaidId,
                Notes: item.notes,
              };
            }),
        },
      ],
    };
    let healthData = {
      // courseId: courseId,
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertClientInsurance(healthData));
  }
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-right pt-3 pb-0 pr-3">
            {!this.props.isDeActivated && (
              <>
                {!this.props.isReadOnlyUser && (
                  <span
                    style={{
                      width: '150px',
                      color: '#1890ff',
                      padding: '10px',
                      cursor: 'pointer',
                      fontWeight: '800',
                    }}
                    onClick={event => {
                      this.setState({
                        setOrderModal: true,
                      });
                    }}
                  >
                    Change Insurance Order
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <ReduxHealthInsuranceUI
          onSaveClick={this.onSubmit.bind(this)}
          clientId={this.getClientId()}
          initialValues={{
            SubscriberInformation: this.mapClientSubscriberToState(),
          }}
          ClientInsurance={this.props.clientData.ClientInsurance}
          disable={this.props.isDeActivated || this.props.isReadOnlyUser}
          onCancel={this.props.onCancel}
          style={{ padding: '36px 20px 150px 20px' }}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.dispatch(
              getPatientDetail({
                json: JSON.stringify({
                  Client: [
                    {
                      ClientId: this.props.clientId,
                    },
                  ],
                }),
              }),
            );
            this.props.dispatch(
              clientBannerGet({
                json: JSON.stringify({
                  Client: [
                    {
                      ClientId: this.props.clientId,
                    },
                  ],
                }),
              }),
            );
          }}
          yesText="OK"
          hideCancel={true}
        />
        <Modal
          zIndex={100}
          visible={this.state.setOrderModal}
          footer={null}
          closable={false}
          width={600}
        >
          <SetInsuranceOrder
            // ClientInsurance={this.props.clientData.ClientInsurance}
            clientId={this.getClientId()}
            onCancel={() => {
              this.setState({
                setOrderModal: false,
              });
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}
class SetInsuranceOrder extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(
      getInsurancePriorityOrder({
        json: JSON.stringify({
          Client: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ insurancePriority, isInserted }) {
    if (
      insurancePriority &&
      insurancePriority.length > 0 &&
      JSON.stringify(insurancePriority) != JSON.stringify(this.props.insurancePriority)
    ) {
      let OrderData;
      OrderData = insurancePriority.map(item => {
        return item.InsuranceName;
      });
      this.setState({
        items: OrderData,
      });
    }
    if (isInserted && isInserted != this.props.isInserted) {
      this.props.dispatch(
        getPatientDetail({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
      this.props.onCancel();
    }
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  saveOrder = () => {
    let newOrder = this.state.items;
    let newOrderData;
    newOrderData = newOrder.map(item => {
      return {
        ClientId: this.props.clientId,
        InsuranceName: item,
      };
    });
    this.props.dispatch(
      upsertInsurancePriorityOrder({
        json: JSON.stringify({
          Client: newOrderData,
        }),
      }),
    );
  };
  render() {
    const { items } = this.state;
    const { onCancel, isFetching, isOrderFetching } = this.props;

    return (
      <div>
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-center">Change Insurance Order</h4>
          <div className="col-12 text-center">
            <span className="font-weight-lighter" style={{ fontSize: '11px' }}>
              Drag and drop to swap the insurance order
            </span>
          </div>
        </div>
        <div class="modal-body enrollment-form px-4">
          <div className="row mb-4">
            <Loader2 loading={isFetching} />
            <div className="col-12">
              <SortableContainer
                onSortEnd={this.onSortEnd}
                helperClass="sortableHelper"

                // useDragHandle
              >
                {items.map((value, index) => {
                  return (
                    <SortableItem
                      helperClass="sortableHelper"
                      key={`item-${value}`}
                      index={index}
                      value={value}
                      tempIndex={index}
                    />
                  );
                })}
              </SortableContainer>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <SubmitButton
                // type="button"
                style={{ height: '45px'}}
                // class="btn"
                loading={isOrderFetching}
                value={'Save'}
                onClick={this.saveOrder}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToPropsPriority = ({
  get_insurance_priority_order,
  upsert_insurance_priority_order,
}) => {
  const { data, isFetching } = get_insurance_priority_order;
  const { isFetching: isOrderFetching } = upsert_insurance_priority_order;
  let insurancePriority;
  let isInserted = false;

  if (data && data.data) {
    insurancePriority = data.data || [];
  }
  if (upsert_insurance_priority_order.data && upsert_insurance_priority_order.data.success) {
    isInserted = true;
  }
  return {
    insurancePriority,
    isFetching,
    isInserted,
    isOrderFetching,
  };
};
SetInsuranceOrder = connect(mapStateToPropsPriority)(SetInsuranceOrder);

const HealthInsurance = ({
  fields,
  name,
  meta: { touched, error },
  HealthInsuranceType,
  PatientRelationshipToInsured,
  InsuredAuthorization,
  StateProvince,
  Filter_StateProvince,
  Gender,
  Country,
  disable,
  dispatch,
  ExistingInsuranceRows,
  ClientInsurance,
  clientId,
  PayerInsurance,
}) => {
  const countryChange = (countryISO, currentIndex) => {
    if (countryISO) {
      dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
  };
  const insuranceCount = {
    '1': 'PRIMARY',
    '2': 'SECONDARY',
    '3': 'TERTIARY',
    '4': 'QUATERNARY',
    '5': 'QUINARY',
    '6': 'SENARY',
    '7': 'SEPTENARY',
    '8': 'OCTONARY',
    '9': 'NONARY',
    '10': 'DENARY',
  };
  // name: insuranceCount[1] || 'orange',

  return (
    <>
      {fields.map((item, index) => {
        var formFields = [
          {
            name: 'insuranceName',
            fieldName: 'Insurance Name',
            options: PayerInsurance,
            label: 'Insurance Name',
            componentType: COMPONENT_TYPES.ReduxAutoComplete,
          },
          {
            name: 'insurerPlanName',
            fieldName: 'Plan Name',
          },
          {
            name: 'patientRelationshipToPrimaryInsured',
            fieldName: 'Patient Relationship to Primary Insured',
            options: PatientRelationshipToInsured,
          },
          // {
          //   name: 'insurerFirstName',
          //   fieldName: 'First Name',
          //   placeholder: 'Insurer First Name',
          // },
          // {
          //   name: 'insurerLastName',
          //   fieldName: 'Last Name',
          //   placeholder: 'Insurer Last Name',
          // },
          {
            name: 'insuredAuthorization',
            fieldName: 'Insured Authorization',
            options: InsuredAuthorization,
          },
          {
            name: 'primaryInsurance',
            fieldName: 'Insurance ID',
            placeholder: 'Insurance ID',
          },
          {
            name: 'subscriberGroupNumber',
            fieldName: 'Group Number',
          },
          {
            name: 'subscriberMedicaidId',
            fieldName: 'Medicaid ID',
          },

          {
            name: 'insurerDeductible',
            fieldName: 'Deductible',
          },
          {
            name: 'patientCoPayment',
            fieldName: 'Patient Co-Payment',
          },

          // {
          //   renderComponent: <br />,
          // },
          {
            name: 'signatureOnFile',
            fieldName: 'Release of Information: Signature on File',
            options: [
              {
                Text: 'Yes',
                Value: 'yes',
              },
              {
                Text: 'No',
                Value: 'no',
              },
            ],
          },
          {
            name: 'signatureDate',
            fieldName: 'Admission Date?',
            label: 'Signature Date',
            condition: () => ExistingInsuranceRows[index].signatureOnFile != 'no',
            displayCalenderIcon: true,
            componentType: COMPONENT_TYPES.ReduxDateInput,
          },
          {
            name: 'notes',
            fieldName: 'Notes',
            class: 'col-12 col-md-6 col-lg-8',
            type: 'textarea',
            style: { height: '130px' },
          },
        ];
        const formFileds2 = [
          {
            displayTitle: true,
            title: 'Subscriber Information',
          },
          {
            name: 'subcriberId',
            fieldName: 'Subcriber ID',
          },
          // {
          //   name: 'subscriberGroupNumber',
          //   fieldName: 'Group Number',
          //   isRequired: true,
          // },
          {
            name: 'subscriberFirstName',
            fieldName: 'Subscriber First Name',
          },
          {
            name: 'subscriberLastName',
            fieldName: 'Subscriber Last Name',
          },
          {
            name: 'subscriberAddressLine1',
            fieldName: 'Subscriber Address Line 1',
          },
          {
            name: 'subscriberAddressLine2',
            fieldName: 'Subscriber Address Line 2',
          },
          {
            name: 'subscriberCity',
            fieldName: 'City',
          },
          {
            name: 'subscriberStateOrProvince',
            fieldName: 'State',
            options: Filter_StateProvince,
            filterBy: 'country',
            defaultFilterBy: 'USA',
          },
          {
            name: 'subscriberPostalCode',
            fieldName: 'Zip',
            validate: [validate.usaZip],
            placeholder: 'Zipcode',
          },
          {
            name: 'subscriberDOB',
            normalize: dateofbirthNormalize,
            validate: [validate.dobStudent],
            fieldName: 'Subscriber DOB',
            title: 'Subscriber DOB',
            // placeholder: 'MM/DD/YYYY',
            displayCalenderIcon: true,
            smallInfo: 'Ex. MM/DD/YYYY',
          },
          {
            name: 'subscriberSSN',
            fieldName: true,
            // validate: [validate.ssn],
            format: ssnFormatter,
            normalize: ssnNormalize,
            placeholder: 'Subscriber SSN',
            // isRequired: true,
          },
          {
            name: 'subscriberGender',
            fieldName: 'Subscriber Gender',
            options: Gender,
            // isRequired: true,
          },
        ];
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 "> {insuranceCount[index + 1]} INSURANCE</h6>
              </div>
            </div>
            <div class="row mb-3">
              <DynamicForm formFields={formFields} mapper={`${item}`} disable={disable} />
              {ExistingInsuranceRows[index].patientRelationshipToPrimaryInsured === 20501 ? (
                <></>
              ) : (
                <DynamicForm formFields={formFileds2} mapper={`${item}`} disable={disable} />
              )}
              {ClientInsurance &&
              ClientInsurance[index] &&
              ClientInsurance[index].ClientInsuranceId ? (
                <div class="col-12">
                  <h6> Manage Eligibility Dates</h6>
                  {!disable && (
                    <ManageEligibility
                      disable={disable}
                      clientId={clientId}
                      ClientInsuranceRow={ClientInsurance[index]}
                      ClientInsuranceEligibility={
                        ClientInsurance[index].ClientInsuranceEligibility || []
                      }
                    />
                  )}
                </div>
              ) : null}
            </div>
            {!disable && (
              <div class="row mt-5 mb-5">
                {fields.length > 1 && (
                  <div class={`col-12 col-md-6`}>
                    <a
                      class="delete-contact"
                      style={{ margin: '0px', fontSize: '16px', fontWeight: '800' }}
                      disabled={disable}
                      onClick={() => fields.remove(index)}
                    >
                      <i class="fas fa-trash" /> Delete Information
                    </a>
                  </div>
                )}

                {fields.length == 1 || index == fields.length - 1 ? (
                  <div class={fields.length > 1 ? `col-12 col-md-6 px-0` : `col-12 col-md-12 px-0`}>
                    <a
                      class="add-contact"
                      style={{ marginRight: '23px' }}
                      disabled={disable}
                      onClick={() => fields.push({ patientRelationshipToPrimaryInsured: 20501 })}
                    >
                      {fields.length >= 1 && <label> + Add Another Insurance</label>}
                      {fields.length == 0 && <label> + Add Insurance</label>}
                    </a>
                  </div>
                ) : null}

                {/* {index == fields.length - 1 && fields.length != 1 && (
                <div class={`col-12 col-md-4 px-0`}>
                  <a
                    class="add-contact"
                    disabled={disable}
                    onClick={() => fields.push({ country: 'USA' })}
                  >
                    {fields.length >= 1 && <label> + Add Another Insurance</label>}
                    {fields.length == 0 && <label> + Add Insurance</label>}
                  </a>
                </div>
              )} */}

                {/* <div
                // className={
                //   'col-12 text-right pt-2 pb-1 d-none' +
                //   (index != 0 && index != fields[fields.length - 1] && 'd-block')
                // }
                className={'col-12 text-right pt-2 pb-1'}
              >
                {fields.length == 1 && (
                  <a
                    class="add-contact"
                    disabled={disable}
                    onClick={() => fields.push({ country: 'USA' })}
                  >
                    {fields.length >= 1 && <label> + Add Another Insurance Source</label>}
                    {fields.length == 0 && <label> + Add Insurance Source</label>}
                  </a>
                )}
                {index == fields.length - 1 && fields.length != 1 && (
                  <a
                    class="add-contact"
                    disabled={disable}
                    onClick={() => fields.push({ country: 'USA' })}
                  >
                    {fields.length >= 1 && <label> + Add Another Insurance Source</label>}
                    {fields.length == 0 && <label> + Add Insurance Source</label>}
                  </a>
                )}
              </div> */}
              </div>
            )}
          </>
        );
      })}
    </>
  );
};
const HealthInsuranceUI = props => {
  const {
    handleSubmit,
    onSaveClick,
    handleNext,
    name,
    pristine,
    submitting,
    onLastSubmit,
    HealthInsuranceType,
    StateProvince,
    Filter_StateProvince,
    Gender,
    CountryISO,
    style,
    isHealthInsurance,
    goToPreviuos,
    PatientRelationshipToInsured,
    InsuredAuthorization,
    disable,
    existingInsuranceRows,
    ClientInsurance,
    PayerInsurance,
    reset,
    isFetching,
  } = props;

  return (
    <form onSubmit={handleSubmit(onSaveClick)} style={style}>
      <div class="row rcm-tab mb-3">
        {/* <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Do you have Health Insurance?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isHealthInsurance" component={ReduxToggle} disabled={disable} />
          </div>
        </div> */}

        <div class="col-12">
          <FieldArray
            name="SubscriberInformation"
            component={HealthInsurance}
            clientId={props.clientId}
            HealthInsuranceType={HealthInsuranceType}
            ClientInsurance={ClientInsurance}
            PatientRelationshipToInsured={PatientRelationshipToInsured}
            InsuredAuthorization={InsuredAuthorization}
            ExistingInsuranceRows={existingInsuranceRows}
            StateProvince={StateProvince}
            Filter_StateProvince={Filter_StateProvince}
            Gender={Gender}
            Country={CountryISO}
            disable={disable}
            dispatch={props.dispatch}
            PayerInsurance={PayerInsurance}
          />
        </div>
      </div>

      <div
        class="row pagination-button"
        style={{ marginRight: '8px', bottom: 'unset', marginTop: '100px' }}
      >
        {!disable && (
          <div class="col-12 text-right">
            <input
              type="button"
              class="btn btn-outline-primary mr-2"
              value="Cancel"
              onClick={reset}
              disabled={disable}
            />
            {/* <button
              // onClick={() => {
              //   this.props.remoteSubmit();
              // }}
              // onClick2={() => {
              //   activeTabs.toString().indexOf('false') == -1
              //     ? this.props.remoteSubmit()
              //     : this.setState({ showError: true }, () =>
              //         setTimeout(() => this.setState({ showError: false }), 2000),
              //       );
              // }}
              class="btn"
              // disabled={this.getDischarged()}
            >
              Save
            </button> */}
            <SubmitButton value="Save" disabled={disable} loading={isFetching} />
          </div>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const { combos, medicate_combos, upsert_client_insurance } = state;
  const { data: medicateCombos } = medicate_combos;
  const { isFetching } = upsert_client_insurance; 

  return {
    PayerInsurance: combos.data.RCM_PayerInsurance,
    StateProvince: combos.data.StateProvince,
    Filter_StateProvince: combos.data.Filter_StateProvince,
    Gender: combos.data.Gender,
    HealthInsuranceType: combos.data.HealthInsuranceType,
    CountryISO: combos.data.Country,
    PatientRelationshipToInsured:
      (medicateCombos && medicateCombos.PatientRelationshipToInsured) || [],
    InsuredAuthorization: (medicateCombos && medicateCombos.InsuredAuthorization) || [],
    isHealthInsurance: selector(state, 'isHealthInsurance'),
    // relationshipToPrimaryInsuredValue: selector(state, 'patientRelationshipToPrimaryInsured'),
    existingInsuranceRows: selector(state, 'SubscriberInformation'),
    isFetching,
  };
};

const ReduxHealthInsuranceUI = reduxForm({
  form: 'healthInsuranceForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(HealthInsuranceUI)));

const mapStateToPropsParent = ({ upsert_client_insurance, get_patient_detail }) => {
  const { data: clientInfo } = get_patient_detail;
  let isInserted = false;
  let isDeActivated = false;
  let clientData = {};
  if (
    upsert_client_insurance &&
    upsert_client_insurance.data &&
    upsert_client_insurance.data.success
  ) {
    isInserted = true;
  }
  if (clientInfo && clientInfo.ClientInsurance) {
    clientData.ClientInsurance = clientInfo.ClientInsurance;
    clientData.ClientId = clientInfo.ClientId;
  }
  if (clientInfo && clientInfo.ClientId) {
    isDeActivated = clientInfo.IsDeActivated;
  }
  return {
    isInserted,
    clientData: clientData,
    isDeActivated,
  };
};
export default connect(mapStateToPropsParent)(withRouter(HealthInsuranceStep));
const selector = formValueSelector('healthInsuranceForm');
