import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal2 from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { upcomingAdmissions, admissionCancel } from '../../../../store/actions';
import PersistTableSort from '../../../../component/persistTableSort';
import blank_user from '../../../../images/blank-profile.png';
import AntdModal from '../../../../Components/CustomModal';
import { Tooltip } from 'antd';
import PageTitle from '../../../../Components/PageTitle';

class UpcomingAdmissions extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  refreshGrid = () => {
    const { searchStr } = this.state;
    this.setState({
      searchStr,
    });
    // this.props.dispatch(
    //   upcomingAdmissions({
    //     json: JSON.stringify({
    //       Group: [
    //         {
    //           Search: searchStr,
    //         },
    //       ],
    //     }),
    //   }),
    // );
    // sessionStorage.setItem(
    //   'appliedFilter',
    //   JSON.stringify({
    //     // Filter: filterType && filterType.toString(),
    //     searchStr: searchStr,
    //     // startDate,
    //     // endDate,
    //     // filterCenterId,
    //     // FilterCounserlorId,
    //   }),
    // );
  };

  componentDidMount() {
    // let medicateComboTypes = {
    //   ComboTypes: 'ServiceCode',
    // };
    // this.props.dispatch(medicateCombos(medicateComboTypes));
    this.props.dispatch(upcomingAdmissions({}));
  }
  componentWillReceiveProps({ isDeleted, deletedMsg }) {
    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.setState({
        inviteMsg: deletedMsg,
        isModelOpen: true,
      });
    }
  }
  fnAdmissionCancel = BedAssigmentId => {
    this.props.dispatch(
      admissionCancel({
        json: JSON.stringify({
          BedMangement: [
            {
              BedAssigmentId: BedAssigmentId,
              CancelReason: 'Plan Changed',
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { groupEventModal } = this.state;
    const { isFetching, UpcomingAdmissionsList } = this.props;
    const columns = [
      {
        title: 'Profile',
        dataIndex: 'ProfileUrl',
        key: 'ProfileUrl',
        render: (text, record) => (
          <div class="student-list-pic">
            <img
              src={text || blank_user}
              class="img-circle"
              height="40"
              width="40"
              style={{ borderRadius: '50%', objectFit: 'cover' }}
            />
          </div>
        ),
      },

      {
        title: 'Patient Name',
        dataIndex: 'PatientName',
        key: 'PatientName',
        sorter: (a, b) => isComparer(a.PatientName, b.PatientName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Admit Date',
        dataIndex: 'AdmitDate',
        key: 'AdmitDate',
        sorter: (a, b) => isDateComparer(a.AdmitDateForSort, b.AdmitDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Admit Time',
        dataIndex: 'AdmitTime',
        key: 'AdmitTime',
        sorter: (a, b) => isComparer(a.AdmitTime, b.AdmitTime),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Bed Number',
        dataIndex: 'BedNumber',
        key: 'BedNumber',
        sorter: (a, b) => isNumericComparer(a.BedNumber, b.BedNumber),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Room Number',
        dataIndex: 'RoomNumber',
        key: 'RoomNumber',
        sorter: (a, b) => isNumericComparer(a.RoomNumber, b.RoomNumber),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Discharge Date',
        dataIndex: 'DischargeDate',
        key: 'DischargeDate',
        sorter: (a, b) => isDateComparer(a.DischargeDateForSort, b.DischargeDateForSort),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Program Type',
        dataIndex: 'ProgramType',
        key: 'ProgramType',
        sorter: (a, b) => isComparer(a.ProgramType, b.ProgramType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        sorter: (a, b) => isComparer(a.Status, b.Status),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        render: (text, record, index) => (
          <>
            {!record.IsCancelled ? (
              <div class="actions-button d-flex justify-content-center">
                <div className="actions-button">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <Tooltip placement="top" title="Cancel Admission">
                        <span class="delete-course" onClick={onClick} style={{ cursor: 'pointer' }}>
                          <i class="fas fa-times" style={{ cursor: 'pointer' }} />
                        </span>
                      </Tooltip>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">Are you sure you want to cancel?</h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.fnAdmissionCancel(record.BedAssigmentId);
                              // this.props.onCancel();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            ) : null}
          </>
        ),
      },
    ];
    return (
      <div class="rcm-panel">
        <PageTitle TitleText="Upcoming Admissions" />
        <div class="main enrollment-page mb-5">
          <div class="container-fluid px-0">
            <div class="content-area">
              <div class="col-md-12">
                {/* <div class="row top-table-search mt-3 mb-3 align-items-center">
                  <div class="col-12 col-md-4 col-lg-3">
                    <h3 class="page-title mb-3 mb-md-0 text-uppercase">Upcoming Admissions</h3>
                  </div>
                  <div class="col-12 col-md-8 col-lg-9 text-md-right d-flex justify-content-end">
                    <div class="search-box d-inline-flex w-100">
                      <div class="form-group has-search w-100">
                        <input
                          type="text"
                          class="form-control shadow-sm"
                          placeholder="Search..."
                          // onChange={this.onSearchChange}
                        />
                        <button class="btn search-icon " type="button">
                          <i class="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="row">
                  <div class="col-12 mb-5 px-0">
                    <div class="card border-0">
                      <Loader2 loading={isFetching} />
                      <div class="row card-body">
                        <div class="col-12">
                          <PersistTableSort
                            name="grp-list1"
                            columns={columns}
                            dataSource={UpcomingAdmissionsList}
                            size={'middle'}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div class="dataTables_wrapper no-footer">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a class="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a class="paginate_button next">Next</a>
                                      ) : (
                                        <a class="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              showSizeChanger: true,
                              // hideOnSinglePage: true,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomModal2
              isOpen={this.state.isModelOpen}
              header={this.state.inviteMsg}
              yesAction={() => {
                this.setState({
                  isModelOpen: false,
                  inviteMsg: '',
                });
                this.props.dispatch(upcomingAdmissions({}));
              }}
              yesText="OK"
              hideCancel={true}
              changeMaxWidth="100%"
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ upcoming_admissions, admission_cancel }) => {
  const { data, isFetching } = upcoming_admissions;

  let UpcomingAdmissionsList = [];
  let isDeleted = false;
  let deletedMsg = false;
  if (data && data.BedMangement) {
    UpcomingAdmissionsList = data.BedMangement || [];
  }
  if (admission_cancel.data && admission_cancel.data.success) {
    isDeleted = admission_cancel.data.success;
    deletedMsg = JSON.parse(admission_cancel.data.data)[0].MSG;
  }
  return {
    UpcomingAdmissionsList,
    isFetching,
    isDeleted,
    deletedMsg,
  };
};
export default connect(mapStateToProps)(UpcomingAdmissions);
