import React, { Component } from 'react';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

class ReactRangePicker extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { handleChange, value, bordered, separator, format, defaultValue } = this.props;
    const dateFormat = 'YYYY-MM-DD';
    return (
      <div>
        <RangePicker
          value={value}
          // value={[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]}
          defaultValue={defaultValue}
          onChange={handleChange}
          style={{ paddingBottom: '15px' }}
          className="range-picker text-left"
          format={format || ['MM-DD-YYYY', 'MM/DD/YYYY']}
          bordered={bordered || false}
          showToday
          separator={separator}
          suffixIcon={<i class="far fa-calendar-alt form-control-icon" />}
          allowClear={false}
          placeholder={['MM-DD-YYYY', 'MM-DD-YYYY']}
        />
      </div>
    );
  }
}

export default ReactRangePicker;
