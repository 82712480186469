import React, { Component } from 'react';
// import { ReduxSelect } from '../../../component/custom-redux-components';

class PaginationPrevNext extends Component {
  constructor(props) {
    super(props);
    const { pageNumber, pageRows } = props;
    this.state = {
      PageNumber: pageNumber,
      PageRows: pageRows,
      pageOptionCount: [5, 10, 25, 50, 100, 250],
    };
  }
  componentWillReceiveProps({ pageNumber, pageRows }) {
    if (pageNumber && pageNumber != this.props.pageNumber) {
      this.setState({
        PageNumber: pageNumber,
      });
    }
  }
  handelAction = ({ btnId, value }) => {
    // let btnId = event.target.id;
    let pageNumber = this.state.PageNumber;
    if (btnId === 'next') {
      pageNumber = pageNumber + 1;
      this.setState({
        PageNumber: pageNumber,
      });
      this.props.getPagination({
        PageNumber: pageNumber,
        PageRows: this.state.PageRows,
      });
    } else if (btnId === 'pre' && pageNumber > 1) {
      pageNumber = pageNumber - 1;
      this.setState({
        PageNumber: pageNumber,
      });
      this.props.getPagination({
        PageNumber: pageNumber,
        PageRows: this.state.PageRows,
      });
    } else if (btnId === 'page-count') {
      this.setState({
        PageNumber: 1,
        PageRows: value,
      });
      this.props.getPagination({
        PageNumber: 1,
        PageRows: value,
      });
    }
  };
  handelCountAction = event => {
    let btnId = 'page-count';
    let value = parseInt(event.target.value);
    this.handelAction({
      btnId,
      value,
    });
  };
  handelPreAction = event => {
    let btnId = 'pre';
    this.handelAction({
      btnId,
    });
  };
  handelNextAction = event => {
    let btnId = 'next';
    this.handelAction({
      btnId,
    });
  };

  render() {
    const { PageNumber, PageRows, pageOptionCount } = this.state;
    const { rowsLength } = this.props;
    let pageFrom = (PageNumber - 1) * PageRows + 1;
    let pageTo = pageFrom - 1 + PageRows;
    let isDisableNext = PageRows > rowsLength;
    if (isDisableNext) {
      pageTo = pageFrom + rowsLength - 1;
    }
    return (
      <div className="pagination-custom py-2">
        <div className="d-flex align-items-center">
          <span className="mx-3">
            {pageFrom} - {pageTo} of {isDisableNext ? pageTo : 'many'}
          </span>
          <button
            id="pre"
            className="action"
            // onClick={this.handelAction}
            onClick={this.handelPreAction}
            disabled={PageNumber <= 1}
          >
            {/* <i class="fa fa-less-than"></i> */}
            <img src="images/icons/arrow-left.svg" />
          </button>

          <button
            id="next"
            className="action"
            onClick={this.handelNextAction}
            // onClick={this.handelAction}
            disabled={isDisableNext}
          >
            {/* <i class="fa fa-greater-than"></i> */}
            <img src="images/icons/arrow-right.svg" />
          </button>
          <div className="page-count mx-1">
            <div className="page-count-dropdown">
              <label for="page-count" className="mr-2 mb-0">
                Show
              </label>
              <select
                name="page-count"
                id="page-count"
                className="size-changer"
                onChange={this.handelCountAction}
                // onChange={this.handelAction}
              >
                {pageOptionCount.map(count => {
                  if (PageRows === count) {
                    return <option value={count} selected>{`${count}/Page`}</option>;
                  } else {
                    return <option value={count}>{`${count}/Page`}</option>;
                  }
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaginationPrevNext;
