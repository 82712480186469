import React, { Component } from 'react';
import PropTypes, { array } from 'prop-types';
import { connect } from 'react-redux';
import {
  getStudentSurveyDashboardData,
  resetSurveyAnswersGet,
  surveyAnswersGet,
} from '../store/actions';
import { Polar, Line } from 'react-chartjs-2';
import { Table } from 'antd';
import { Tabs } from 'antd';
import CustomModal from '../core/modal';
import { isComparer, isNumericComparer } from '../util/handler';
import Loader from '../Components/Loader';

const TabPane = Tabs.TabPane;

class StudentSurvey extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      surveyanswersdata: [],
      columns: [
        {
          title: 'Survey Category',
          dataIndex: 'SurveyCategory',
          key: 'SurveyCategory',
          sorter: (a, b) => isComparer(a.SurveyCategory, b.SurveyCategory),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Survey SubCategory',
          dataIndex: 'SurveySubCategory',
          key: 'SurveySubCategory',
          sorter: (a, b) => isComparer(a.SurveySubCategory, b.SurveySubCategory),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Completed Date',
          dataIndex: 'CompletedDt',
          key: 'CompletedDt',
          sorter: (a, b) => isNumericComparer(a.CompletedDt, b.CompletedDt),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Total Score',
          dataIndex: 'TotalScore',
          key: 'TotalScore',
          sorter: (a, b) => a.TotalScore - b.TotalScore,
          sortDirections: ['descend', 'ascend'],
        },
      ],
      surveyanswersdatacol: [
        { title: 'Question Number', dataIndex: 'QuestionNumber', key: 'QuestionNumber' },
        { title: 'Question', dataIndex: 'Question', key: 'Question' },
        { title: 'Answer', dataIndex: 'Answer', key: 'Answer' },
        { title: 'Responded Date Time', dataIndex: 'RespondedDateTime', key: 'RespondedDateTime' },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(getStudentSurveyDashboardData(this.getStudentId()));
  }

  componentWillUnmount() {
    this.props.dispatch(resetSurveyAnswersGet());
  }

  componentWillReceiveProps({ surveyanswersdata }) {
    if (surveyanswersdata.length > 0) {
      this.setState({ surveyanswersdata, modal: true });
    }
  }

  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/student-survey/', ''));
  }

  callback = key => {
    console.log(key);
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  onrowclick = record => {
    this.props.dispatch(
      surveyAnswersGet({ studentId: this.getStudentId(), surveyId: record.SurveyId }),
    );
  };

  render() {
    const { records, surveryData, lineCharts, polarCharts, isFetching } = this.props;
    const { surveyanswersdata, surveyanswersdatacol } = this.state;

    var renderItems = null;
    var tabs = [];

    if (polarCharts && polarCharts.length > 0) {
      for (var index in polarCharts) {
        var chart = polarCharts[index];
        var item = {
          type: 'Polar',
          title: chart.LadderChartHeader,
          data: {
            datasets: [
              {
                data: chart.ChartData.split(','),
                backgroundColor: [
                  'rgba(0,123,255,0.6)',
                  'rgba(253,126,20,0.6)',
                  'rgba(40,167,69,0.6)',
                  'rgba(232,62,140,0.6)',
                  'rgba(255,193,7,0.6)',
                  'rgba(220,53,69,0.6)',
                  'rgba(102,160,242,0.6)',
                  'rgba(0,0,255,0.6)',
                  'rgba(0,255,0,0.6)',
                  'rgba(51,0,0,0.6)',
                  'rgba(138,163,123,0.6)',
                  'rgba((204,0,255,0.6)',
                ],
              },
            ],
            labels: chart.ChartLabels.split(','),
          },
          option: {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              position: 'bottom',
            },
            title: {
              display: false,
              text: chart.LadderChartHeader,
              fontSize: 20,
              lineHeight: 1.4,
            },
            scale: {
              ticks: {
                display: true,
                beginAtZero: true,
                max: 100,
              },
              reverse: false,
            },
            animation: {
              animateRotate: false,
              animateScale: true,
            },
          },
        };

        tabs.push(item);
      }
    }

    /*if (polarCharts && polarCharts.length > 0) {
      renderItems = [];
      for (var chart of polarCharts) {
        var item = {
          type: 'Polar',
          title: chart.LadderChartHeader,
          data: {
            datasets: [
              {
                data: chart.ChartData.split(','),
                backgroundColor: [
                  'rgba(0,123,255,0.6)',
                  'rgba(253,126,20,0.6)',
                  'rgba(40,167,69,0.6)',
                  'rgba(232,62,140,0.6)',
                  'rgba(255,193,7,0.6)',
                  'rgba(220,53,69,0.6)',
                  'rgba(102,160,242,0.6)',
                ],
              },
            ],
            labels: chart.ChartLabels.split(','),
          },
          option: {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              position: 'bottom',
            },
            title: {
              display: false,
              text: chart.LadderChartHeader,
              fontSize: 20,
              lineHeight: 1.4,
            },
            scale: {
              ticks: {
                display: true,
                beginAtZero: true,
                max: 100,
              },
              reverse: false,
            },
            animation: {
              animateRotate: false,
              animateScale: true,
            },
          },
        };

        renderItems.push(item);
      }
    }*/

    if (lineCharts && lineCharts.length > 0 && lineCharts[0].XAxisLabels) {
      if (!renderItems) {
        renderItems = [];
      }
      var item = {
        type: 'Line',
        title: 'Survey Time Series',
        data: {
          labels: lineCharts[0].XAxisLabels.split(','),
          datasets: [
            {
              data: lineCharts[0].Scores.split(','),
              borderColor: '#3e95cd',
              label: lineCharts[0].ScoreLabels.split(','),
              fill: false,
            },
          ],
        },
        option: {
          tooltips: {
            enabled: false,
            custom: function(tooltipModel) {
              var Index = tooltipModel.dataPoints && tooltipModel.dataPoints[0].index;
              var scores = lineCharts[0].Scores.split(',');
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines[0].split(',');
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function(body, i) {
                  var colors = tooltipModel.labelColors[i];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  var span = '<span style="' + style + '"></span>';
                  innerHtml +=
                    '<tr><td>' +
                    span +
                    body[Index] +
                    '<br/>' +
                    'Score:' +
                    scores[Index] +
                    '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.background = 'rgba(0, 0, 0, 0.63)';
              tooltipEl.style.color = '#ffffff';
              tooltipEl.style.border = '1px solid grey';
              tooltipEl.style.borderRadius = '4px';
            },
          },
          legend: { display: true, position: 'bottom' },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontSize: 14,
                  fontColor: '#232c3d',
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontSize: 14,
                  fontColor: '#232c3d',
                },
              },
            ],
          },
          title: {
            display: false,
            text: 'World population per region (in millions)',
          },
          maintainAspectRatio: false,
          responsive: true,
        },
      };
      renderItems.push(item);
    }

    if (records) {
      var item = {
        type: 'Table',
        title: 'Survey List',
        data: records,
        option: this.state.columns,
      };
      renderItems.push(item);
    }

    return (
      <div class="container-fluid">
        <Loader loading={isFetching} />
        <div class="content-area">
          <div class="row justify-content-between">
            <div class="col col-12 col-md mar-top-15">
              <input
                type="submit"
                class="form-control btn black-btn"
                value="BACK"
                color="blue"
                onClick={() => this.props.history.goBack()}
              />
            </div>
            <div class="col col-12 col-md mar-top-15 text-right">
              <div class="survey-stuent-details">
                <div class="profile-pic">
                  <img src={surveryData.StudentImageUrl} alt="student name" />
                </div>
                <span>{surveryData.FullName}</span>
              </div>
            </div>
          </div>

          <div class="row">
            {renderItems && (
              <Tabs defaultActiveKey="0" onChange={this.callback}>
                {tabs.map((item, index) => (
                  <TabPane tab={item.title} key={index}>
                    <div class="col-lg-12 mb-3 student-profile-smiley-chart ">
                      <div class="shadow bg-white polar-chart-wrap rounded">
                        <div>
                          <h4 class="text-center">{item.title} </h4>
                          <div class="mar-top-30">
                            <Polar data={item.data} options={item.option} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            )}
          </div>
          <div class="row">
            {renderItems &&
              renderItems.map(item => {
                var component = null;
                if (item.type == 'Polar') {
                  component = <Polar data={item.data} options={item.option} />;
                } else if (item.type == 'Line') {
                  component = <Line data={item.data} options={item.option} />;
                } else {
                  component = (
                    <Table
                      onRow={(record, rowIndex) => {
                        return { onClick: e => this.onrowclick(record, rowIndex) };
                      }}
                      dataSource={item.data}
                      columns={item.option}
                    />
                  );
                }
                return (
                  <div class="col-lg-6 mb-3 student-profile-smiley-chart ">
                    <div class="shadow bg-white polar-chart-wrap rounded">
                      <div>
                        <h4 class="text-center">{item.title} </h4>
                        <div class="mar-top-30">{component}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <CustomModal
            isOpen={this.state.modal}
            yesAction={() => {
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="600px"
          >
            <div class="col-lg-12 mb-3 student-profile-smiley-chart" style={{ width: '100%' }}>
              <h4 class="text-center">Survey Answers</h4>
              <div class="mar-top-30">
                <Table dataSource={surveyanswersdata} columns={surveyanswersdatacol} />
              </div>
            </div>
          </CustomModal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_student_survery_dashboard_data, survey_answers_get }) => {
  var { data, isFetching, error } = get_student_survery_dashboard_data;
  var records = null;
  var surveryData = {};
  var lineCharts = [];
  var polarCharts = [],
    surveyanswersdata = [];
  if (data) {
    surveryData = data;
    records = surveryData.SurveyList;
    polarCharts = surveryData.LadderPolarCharts;
    lineCharts = surveryData.SurveyLineChart;
  }

  if (survey_answers_get.data) {
    surveyanswersdata = Object.values(survey_answers_get.data);
  }

  return {
    records,
    surveryData,
    lineCharts,
    polarCharts,
    isFetching,
    surveyanswersdata,
  };
};
export default connect(mapStateToProps)(StudentSurvey);
