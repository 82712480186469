import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { tokensCounselorTransactionHistoryGetAll } from '../../../store/actions';
// import Loader, { Loader2 } from '../../../Components/Loader';
import { currencyFormatter2, numberFormat } from '../../../util/formatter';

class CounselorsTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(
      tokensCounselorTransactionHistoryGetAll({
        JsonInput: JSON.stringify({
          TokenManagement: {
            StaffId: this.props.StaffId,
          },
        }),
      }),
    );
  }
  render() {
    const { listData, headerInfo } = this.props;
    const { StaffProfileImage, StaffName, TotalAmount, TotalTokensDebited } = headerInfo || {};
    const columns = [
      {
        title: 'Transaction date',
        dataIndex: 'PurchasedDate',
        key: 'PurchasedDate',
        sorter: (a, b) => isDateComparer(a.PurchasedDateForSort, b.PurchasedDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Client',
        dataIndex: 'Client',
        key: 'Client',
        sorter: (a, b) => isComparer(a.Client, b.Client),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span className="d-flex align-items-center">
            <img
              style={{ height: '30px', width: '30px', borderRadius: '50%', objectFit: 'cover' }}
              src={record.ClientProfileImage || '/images/blank-profile.png'}
            />
            <span className="ml-1">{text}</span>
          </span>
        ),
      },
      {
        title: 'Total Amount',
        dataIndex: 'TotalAmount',
        key: 'TotalAmount',
        sorter: (a, b) => isNumericComparer(a.TotalAmount, b.TotalAmount),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          text && (
            <span>
              <img src="/images/smallCoin.svg" />
              <span
                style={{ color: '#FF2C2C', fontWeight: '600', marginLeft: '5px' }}
              >{`-${text}`}</span>
            </span>
          ),
      },
    ];
    return (
      <div class="border-0 token-management">
        <div class="row">
          <div class="col-12 mb-2">
            <span className="d-flex align-items-center">
              <img
                style={{ height: '60px', width: '60px', borderRadius: '50%', objectFit: 'cover' }}
                src={StaffProfileImage || '/images/blank-profile.png'}
              />
              <span className="ml-1">{StaffName}</span>
            </span>
          </div>
          <div class="col-6">
            <div className="status-card status-blue">
              <span className="title">Total Tokens Debited</span>
              <span className="count">
                <img className="mr-1" src="/images/bigCoin.svg" />
                {TotalTokensDebited && numberFormat.format(parseInt(TotalTokensDebited))}
              </span>
            </div>
          </div>
          <div class="col-6">
            <div className="status-card status-blue">
              <span className="title">Total Debited in $</span>
              <span className="count">
                {TotalAmount && currencyFormatter2.format(TotalAmount || 0)}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            {/* <Loader2 loading={isFetching} /> */}
            <div className="">
              <Table columns={columns} dataSource={listData} pagination={false} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ token_management_counselor_transaction_history_get_all }) => {
  const {
    data: counselorTokenData,
    isFetching,
    error,
  } = token_management_counselor_transaction_history_get_all;

  let listData = [];
  let headerInfo = [];

  if (counselorTokenData && counselorTokenData.TokenManagement) {
    listData = counselorTokenData.TokenManagement[0].History;
    headerInfo = counselorTokenData.TokenManagement[0].Header[0];
  }

  return {
    isFetching,
    listData,
    headerInfo,
  };
};
export default connect(mapStateToProps)(CounselorsTransactions);
