import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import AppointmentFormFields from './AppointmentFormFields';

class DetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      handleSubmit,
      handleAppointment,
      OptionData,
      AppointmentId,
      disable,
      change,
    } = this.props;
    return (
      <div className="prospect-appointments-form mt-2">
        <form onSubmit={handleSubmit(handleAppointment)}>
          <div class="enrollment-form">
            <AppointmentFormFields {...OptionData} disable={disable} change={change} />
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'detailEventForm',
  enableReinitialize: true,
})(connect()(withRouter(DetailsForm)));
