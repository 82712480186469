import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { resetYouuniverseChatGet, resetChatGet } from '../../store/actions';
import { Modal, Drawer } from 'antd';
import AskYOUUniverse from './AskYOUUniverse';
import AskYOUUHomepage from './AskYOUUHomepage';
import CloseIcon from '../../component/closeIcon';

class YOUUniverse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAIDrawerOpen: false,
      isAIHistoryDrawerOpen: false,
      showChatPage: false,
    };
  }
  toggleDrawer = () => {
    let url = this.props.location.pathname;
    this.setState({ isAIDrawerOpen: !this.state.isAIDrawerOpen });
    // this.props.dispatch(resetYouuniverseChatGet({}));
    this.props.dispatch(resetChatGet({}));
  };
  toggleHistoryDrawer = () => {
    this.setState({ isAIHistoryDrawerOpen: !this.state.isAIHistoryDrawerOpen });
  };

  ShowHomepage = (value) => { 
    this.setState({
      showChatPage: value,
    });
  }
  render() {
    const { isAIDrawerOpen, isAIHistoryDrawerOpen } = this.state;
    return (
      <div className="ask-you-universe">
        <div className="you-universe-btn" onClick={this.toggleDrawer}>
          <img src="images/AIStarBG.svg" />
          <span className="px-2">Ask YOUUniverse</span>
          <img src="images/search-icon.svg" />
        </div>
        <Drawer
          title={
            <div className="you-universe-drawer-title">
              <div className="d-flex align-items-center">
                <img src="images/gpt-ai.svg" />
                <span className="title">Ask YOUUniverse</span>
              </div>
              <div className="d-flex">
                {this.state.showChatPage && <span className="chat-history" onClick={this.toggleHistoryDrawer}>
                  Chat History
                </span>}
                <img
                  src="images/expended.svg"
                  className="mx-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push('/ask-youuniverse');
                    this.toggleDrawer();
                  }}
                />
              </div>
            </div>
          }
          placement="right"
          visible={isAIDrawerOpen}
          open={isAIDrawerOpen}
          width={800}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          onClose={() => {
            this.toggleDrawer();
            this.setState({
              showChatPage: false,
            });
            sessionStorage.removeItem('homePageMsg');
          }}
          closeIcon={<CloseIcon />}
          className="you-universe-drawer"
          rootClassName="you-universe-drawer-root"
          // push={{ distance: 180 }}
          // extra={
          //   <div>
          //     <span>sdads</span>
          //     <span>sdads</span>
          //   </div>
          // }
          // headerStyle={{ background: ' #4ab0fe' }}
        >
          {this.state.showChatPage ? (
            <AskYOUUniverse
              toggleDrawer={this.toggleDrawer}
              toggleHistoryDrawer={this.toggleHistoryDrawer}
              isAIHistoryDrawerOpen={isAIHistoryDrawerOpen}
              ShowHomepage={this.ShowHomepage}
              isDrawerView={true}
            />
          ) : (
            <AskYOUUHomepage
              toggleDrawer={this.toggleDrawer}
              toggleHistoryDrawer={this.toggleHistoryDrawer}
              isAIHistoryDrawerOpen={isAIHistoryDrawerOpen}
              ShowHomepage={this.ShowHomepage}
            />
          )}
          {/* <AskYOUUHomepage
            toggleDrawer={this.toggleDrawer}
            toggleHistoryDrawer={this.toggleHistoryDrawer}
            isAIHistoryDrawerOpen={isAIHistoryDrawerOpen}
            ShowHomepage={this.ShowHomepage}
          /> */}
        </Drawer>
      </div>
    );
  }
}

export default withRouter(connect()(YOUUniverse));
