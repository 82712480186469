import React, { Component } from 'react';
import {
  Progress,
  Tooltip,
  Table,
  Slider,
  Collapse,
  Spin,
  Modal,
  Switch,
  Button,
  Drawer,
} from 'antd';
import ListRenderer from '../Components/listRenderer';
import ChatBot from '../Components/ChatBot';
import DictationComponent from '../Components/DictationComponent';
import CustomModal from '../Components/CustomModal';
import CustomModal2 from '../core/modal';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
// import { DatePicker } from '../component/custom-redux-components';
import GoogleMapReact from 'google-map-react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { ReduxSelect } from '../component/custom-redux-components';
import NotesFromUser from './customScreens/notesFromUser';
import {
  getAppSubscribersDashboard,
  getAppSubscribersChatCoach,
  getAppSubscribersActivities,
  spListCombos,
  addAppSubscribersSurvey,
  resetAppSubscribersSurvey,
  appSubscribersUserSearch,
  deleteAppSubscribersTask,
  resetAppSubscribersTask,
  deleteAppSubscribersSurvey,
  addAppSubscribersTask,
  resetAppSubscribersUnassignSurvey,
  AppSubscribersCompleteTask,
  resetDeleteAppSubscribersTask,
  resetAppSubscribersCompleteTask,
  addAppSubscribersNote,
  resetAddAppSubscribersNote,
  addAppSubscribersCourse,
  deleteAppSubscribersCourse,
  appSubscribersCompleteCourse,
  resetAddAppSubscribersCourse,
  resetDeleteAppSubscribersCourse,
  resetAppSubscribersCompleteCourse,
  appSubscribersMapHistory,
  getCallSessionVideo,
  getCallSessionVideoUrl,
  appMarkSystemObservation,
  getUserNotePatient,
  getBreathalyzerSchedule,
  breathalyzerPushATest,
  breathalyzerEnable,
  locationDayHistory,
  appUserLiveLocationGet,
  appSubSurveyAnsGet,
  resetAppSubSurveyAnsGet,
} from '../store/actions';
import { DatePicker as DatePickerAntd } from 'antd';
import { connect } from 'react-redux';
import AntdModal from '../Components/CustomModal';
import { Link } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import DatePicker from 'react-datepicker';
import Loader, { Loader2 } from '../Components/Loader';
import Select from '../component/select/index';
import Paragraph from 'antd/lib/typography/Paragraph';
import renderHTML from 'react-render-html';
import { LoadingOutlined } from '@ant-design/icons';
import ReduxBreathalyzerTimeline from './BAC/breathalyzerTimelineModal';
import { getDate, stringDate } from '../util/date';
import TimePicker from 'rc-time-picker';
import { ReduxAssignTaskModal } from './customScreens/subscribersModal/assignTask';
import BreathalyzerTest from './customScreens/subscribersModal/playVideo';
import ProfileHeader from './customScreens/profileHeader';
import ManageCounselorService from './customScreens/manageCounselorService';
import SurveysQuestionPreview from './customScreens/surveyQuestionPreview';
import Timeline2 from '../Screens/timelineComponent';
import ConcernedPersonLocation from './staffProfileComponents/concernedPersonLocation';
import { CommonLicenceScreens } from './licenceScreens';
import SurveyResponseDrawer from './customScreens/surveyResponse';
import AugmentedSummary from './EnterpriseClient/AugmentedSummary';
import EmotionalAndTrendGraph from './subscriber/emotionalGraph';
import Mood from './clientAndUserDashboardCommonComponents/mood';
import RecoveryMeasureGraph from './clientAndUserDashboardCommonComponents/recoveryMeasureGraph';
import PerformanceCharts from './clientAndUserDashboardCommonComponents/performanceCharts';
import PageTitle from '../Components/PageTitle';
import RecoveryJourneyComponent from './clientAndUserDashboardCommonComponents/recoveryJourney';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

var activePanel = 1;
var activePanel1 = 1;
const { Panel } = Collapse;
const { RangePicker } = DatePickerAntd;
const Close = props => (
  <span {...props}>
    <svg
      height="14"
      width="14"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="css-19bqh2r"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
  </span>
);

class AnyReactComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  _onChildClick = (key, childProps) => {
    this.setState({ show: !this.state.show });
  };
  render() {
    const {
      VisitCount,
      TriggerTypeValue,
      TriggerTypeImgUrl,
      OtherUsersTaggedThisPlaceCount,
      FullAddress,
      IsPositive,
      showIsPositive,
      TriggerSign,
    } = this.props;
    return (
      <div className="custom-marker">
        {!showIsPositive && <div className="number-circle">{this.props.VisitCount}</div>}

        {/* {
          showIsPositive && <div className="isPositive" style={IsPositive ? { background: 'yellow' } : { background: 'red' }}>{ IsPositive?<span>+</span>:<span>-</span>}</div>
        }  */}
        <InfoWindow
          show={this.state.show}
          close={this._onChildClick}
          TriggerTypeValue={TriggerTypeValue}
          VisitCount={VisitCount}
          TriggerTypeImgUrl={TriggerTypeImgUrl}
          OtherUsersTaggedThisPlaceCount={OtherUsersTaggedThisPlaceCount}
          FullAddress={FullAddress}
        />
        {showIsPositive && (
          <div
            className="d-flex flex-column"
            // onClick={this._onChildClick}
            style={
              TriggerSign == 1
                ? {
                    border: '3px solid #FCD400',
                    // height: "50%",
                    background: '#FAFFC1',
                    // padding: "5px",
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : TriggerSign == 0
                ? {
                    border: '3px solid #FF3B3B',
                    background: '#FFD4D4',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : {
                    border: '3px solid #828282',
                    background: '#d9d9d9',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
            }
          >
            {<img src={TriggerTypeImgUrl} width={'25px'} />}
          </div>
        )}
        {!showIsPositive && (
          <div
            className="d-flex flex-column"
            style={
              TriggerSign == 1
                ? {
                    border: '3px solid #FCD400',
                    // height: "50%",
                    background: '#FAFFC1',
                    // padding: "5px",
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : TriggerSign == 0
                ? {
                    border: '3px solid #FF3B3B',
                    background: '#FFD4D4',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : {
                    border: '3px solid #828282',
                    background: '#d9d9d9',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
            }
            onClick={this._onChildClick}
          >
            {<img src={TriggerTypeImgUrl} width={'25px'} />}
          </div>
        )}
      </div>
    );
  }
}

const InfoWindow = props =>
  props.show ? (
    <div className="map-info-popup">
      <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={props.close}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="icon-area">
          {/* <i className="fa fa-medkit" aria-hidden="true"></i> */}
          {
            <img
              className="url-icon"
              src={props.TriggerTypeImgUrl}
              style={{ width: '20px', height: '20px' }}
            />
          }
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            Visited this place <b>{props.VisitCount} times</b>
          </span>
          <span className="subText">{props.TriggerTypeValue}</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          marginTop: '10px',
          padding: '10px 0px',
        }}
      >
        <span className="popText">
          <b>{props.OtherUsersTaggedThisPlaceCount} other</b> users have tagged this place.{' '}
        </span>
        <span className="popText2">{props.FullAddress}</span>
      </div>
    </div>
  ) : null;

class SimpleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      // center: {
      //   lat: 59.8456,
      //   lng: 30.33,
      // },
      zoom: 16,
    };
  }

  // componentWillReceiveProps({ TriggeredHistory }) {
  //   if (
  //     TriggeredHistory.length > 0 &&
  //     TriggeredHistory.length !== this.props.TriggeredHistory.length
  //   ) {
  //     this.setState({
  //       center: { lat: TriggeredHistory[0].Latitude, lng: TriggeredHistory[0].Longitude },
  //     });
  //   }
  // }

  _onChildClick = (key, childProps) => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { TriggeredHistory } = this.props;
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        {TriggeredHistory && TriggeredHistory.length > 0 && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo' }}
            // center={this.props.center}
            center={{ lat: TriggeredHistory[0].Latitude, lng: TriggeredHistory[0].Longitude }}
            // zoom={2}
            zoom={this.state.zoom}
          >
            {TriggeredHistory &&
              TriggeredHistory.map((history, index) => {
                return (
                  <AnyReactComponent
                    lat={history.Latitude}
                    lng={history.Longitude}
                    VisitCount={history.VisitCount}
                    TriggerTypeImgUrl={history.TriggerTypeImgUrl}
                    OtherUsersTaggedThisPlaceCount={history.OtherUsersTaggedThisPlaceCount}
                    FullAddress={history.FullAddress}
                    TriggerTypeValue={history.TriggerTypeValue}
                    TriggerSign={history.TriggerSign}
                  />
                );
              })}
          </GoogleMapReact>
        )}
      </div>
    );
  }
}

class MapViewComponent extends Component {
  constructor() {
    super();
    this.state = {
      seven: true,
      thirty: false,
      range: false,
    };
  }

  onDateFieldChange = date => {
    this.props.getLocationHisData(date.format('YYYY-MM-DD'));
    if (date) {
      this.setState({ selectedDate: date.format('YYYY-MM-DD') });
    }
  };

  onChange = (date, dateString) => {
    this.props.lastDays({ date });
    this.handleDate1(false, false, false);
  };
  // lastDays = days => {
  //   let lastday = days || 7;
  //   let dateTo = moment().format('YYYY-MM-DD');
  //   let dateFrom = moment()
  //     .subtract(lastday, 'd')
  //     .format('YYYY-MM-DD');
  //   ;
  // };
  handleDate1 = (seven, thirty, range) => {
    this.setState({ seven, thirty, range });
  };

  render() {
    const {
      TriggeredHistory,
      lastDays,
      lastDaysBtnVisible,
      last30DaysBtnVisible,
      rangePickerVisible,
      datePickerVisible,
    } = this.props;
    return (
      <>
        {lastDaysBtnVisible && (
          <button
            className={
              this.state.seven ? 'trigger-map-btn-simple-active' : 'trigger-map-btn-simple'
            }
            onClick={() => {
              lastDays({ dayCount: 7 });
              this.handleDate1(true, false, false);
            }}
          >
            Last 7 Days
          </button>
        )}
        {last30DaysBtnVisible && (
          <button
            className={
              this.state.thirty ? 'trigger-map-btn-simple-active' : 'trigger-map-btn-simple'
            }
            onClick={() => {
              lastDays({ dayCount: 30 });
              this.handleDate1(false, true, false);
            }}
          >
            Last 30 Days
          </button>
        )}
        {rangePickerVisible && (
          <RangePicker
            onChange={this.onChange}
            className="antdDate-modifier"
            style={{ margin: '0 10px' }}
          />
        )}
        {datePickerVisible && (
          <DatePicker
            date={this.state.selectedDate}
            onChange={this.onDateFieldChange}
            className="antdDate-modifier"
            style={{ margin: '0 10px' }}
          />
        )}

        {/* <button className="trigger-map-btn-simple">
          Custom &nbsp; <i className="fa fa-calendar" aria-hidden="true"></i>
        </button> */}
        <div className="trigger-content-section">
          {TriggeredHistory && TriggeredHistory.length > 0 ? (
            <div id="map-canvas" className="myMap">
              <SimpleMap TriggeredHistory={TriggeredHistory} />
            </div>
          ) : (
            <h5 className="font-weight-normal text-capitalize text-center">Data Not Available</h5>
          )}
        </div>
      </>
    );
  }
}
class ListViewComponent extends Component {
  constructor(props) {
    super(props);
    let me = this;
    this.state = {
      triggerListModalVisible: false,
      modalItem: {
        occurance: '',
        subText: '',
        address: '',
        icon: '',
        triggerVisit: [],
      },
    };
  }
  setTriggerListModalVisible = (triggerListModalVisible, modalItem) => {
    this.setState({ triggerListModalVisible, modalItem });
  };

  render() {
    var TriggeredHistory = this.props.TriggeredHistory;
    var ListViewArray = [];
    var listObj = {};
    TriggeredHistory &&
      TriggeredHistory.map((LVItem, index) => {
        listObj = {
          occurance: `Visited this place ${LVItem.VisitCount} Times`,
          subText: LVItem.TriggerTypeValue,
          address: LVItem.FullAddress,
          icon: LVItem.TriggerTypeImgUrl,
          triggerVisit: LVItem.TriggerVisit,
        };

        // this.ListViewArray.push(listObj);
        ListViewArray.push(listObj);
      });
    // const modifiedTitle=`<i className="fa fa-medkit" aria-hidden="true"></i> ${this.state.modalItem.occurance}`;
    //  {renderHTML(modifiedTitle)}
    return (
      <div className="modalListView">
        <Modal
          className="cus-mod-wid"
          title=" "
          style={{ justifyContent: 'flex-start', width: '750px' }}
          visible={this.state.triggerListModalVisible}
          footer={null}
          onOk={() => this.setTriggerListModalVisible(false)}
          onCancel={() => this.setTriggerListModalVisible(false)}
        >
          <div className="modalTriggerTitle">
            <div className="modalTriggerHeader">
              <div className="head">
                <div className="icon-area">
                  <i className="fa fa-medkit" aria-hidden="true"></i>
                  {/* <img
                    className="url-icon"
                    src={this.state.modalItem.icon + '.png'}
                    style={{ width: '50px' }}
                  /> */}
                </div>
                <h5>{this.state.modalItem && this.state.modalItem.occurance}</h5>
              </div>
              <div style={{ color: 'black' }}>
                {this.state.modalItem && this.state.modalItem.address}
              </div>
            </div>
            <div className="modalTriggerBody">
              <div className="dateTimeHeader">
                <div className="font-weight-bold">Date</div>
                <div className="font-weight-bold">Time</div>
                <div className="font-weight-bold">Time Spent</div>
                <div className="font-weight-bold">Distance From Trigger</div>
              </div>
              {this.state.modalItem &&
                this.state.modalItem.triggerVisit &&
                this.state.modalItem.triggerVisit.map(item => {
                  return (
                    <>
                      <div className="dateTime">
                        <div>{moment(item.DateInserted).format('DD-MMM-YYYY')}</div>
                        <div>{moment(item.DateInserted).format('hh:mm A')}</div>
                        <div>{item.MinutesSpent}</div>
                        <div>{item.DistanceFromTrigger}</div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </Modal>

        <div className="listView-Container">
          {ListViewArray && ListViewArray.length > 0 ? (
            ListViewArray &&
            ListViewArray.map(item => {
              return (
                <>
                  <div
                    className="listView-item d-block"
                    onClick={() => this.setTriggerListModalVisible(true, item)}
                  >
                    <div className="row">
                      <div className="col-md-1">
                        <img className="url-icon" src={item.icon} style={{ width: '20px' }} />
                      </div>
                      <div className="col-md-4">
                        <span>{item.occurance}</span>
                        <span className="subText">{item.subText}</span>
                      </div>
                      <div className="col-md-6">
                        <span>{item.address}</span>
                      </div>
                      <div className="col-md-1">
                        <div className="icon-area">
                          <i className="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="listView-item d-block">
              <h5 className="font-weight-normal text-capitalize text-center">Data Not Available</h5>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class CoursesModalContent extends Component {
  constructor(props) {
    super(props);
    const { CourseId, DueDate, isEdit } = props;
    let course = [];
    course.push({ Value: CourseId });
    this.state = {
      selectedCourse: (CourseId && course) || '',
      dueDate: (isEdit && new Date(DueDate)) || '',
    };
  }

  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  //   if (selectedCourse.length > 0) {
  //     var selectedCourses = [];
  //     for (var item of selectedCourse) {
  //       selectedCourses.push(item.Value);
  //     }
  //     onOk();
  //     assignCourse(selectedCourses.toString(), dueDate);
  //   } else {
  //     this.setState({ msg: true });
  //   }
  handelNewCourses = () => {
    let { selectedCourse, dueDate } = this.state;
    if (!dueDate || !selectedCourse) {
      if (!selectedCourse) {
        this.setState({
          isCourseReq: true,
        });
      } else if (!dueDate) {
        this.setState({
          isDueDateReq: true,
        });
      }
    } else {
      this.props.onOk();
      var selectedCourses = [];
      for (var item of selectedCourse) {
        selectedCourses.push(item.Value);
      }
      this.props.assignCourse(selectedCourses.toString(), dueDate);
    }
  };
  render() {
    const { onOk, onCancel, courseOptions, assignCourse, isEdit } = this.props;
    const { selectedCourse, dueDate } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        className="form-control datepicker"
        placeholder="Courses due date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">{isEdit ? 'Edit course end date' : 'List of Courses'} </h4>
        </div>
        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div className="form-group">
            {!isEdit && (
              <>
                <ReduxSelect
                  options={courseOptions}
                  isMulti
                  value={selectedCourse}
                  onChange={value =>
                    this.setState({ selectedCourse: value, msg: false, isCourseReq: false })
                  }
                  placeholder="Select"
                  allowDefault={true}
                />
                {this.state.isCourseReq && <span className="error-text">Course Required</span>}
              </>
            )}
          </div>
          <div className="form-group">
            <DatePicker
              selected={dueDate}
              onChange={this.handleChange}
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              minDate={new Date()}
            />
            <i className="far fa-calendar-alt form-control-icon" />
            {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
          </div>
        </div>

        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" className="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
          <button
            type="button"
            className="btn "
            onClick={this.handelNewCourses}
            // onClick={() => {
            //   if (selectedCourse.length > 0) {
            //     var selectedCourses = [];
            //     for (var item of selectedCourse) {
            //       selectedCourses.push(item.Value);
            //     }
            //     onOk();
            //     assignCourse(selectedCourses.toString(), dueDate);
            //   } else {
            //     this.setState({ msg: true });
            //   }
            // }}
          >
            {isEdit ? 'Update' : 'Assign'}
          </button>
        </div>
      </div>
    );
  }
}

CoursesModalContent = connect(({ sp_list_combos }) => ({
  courseOptions: (sp_list_combos.data && sp_list_combos.data.EntCourses) || [],
}))(withRouter(CoursesModalContent));

class NotesExtraComponent extends Component {
  state = {
    noteType: '',
    note: '',
    toggleActive: false,
    msg: false,
    isDischarged: false,
  };

  getUserGuid() {
    return this.props.location.pathname.replace('/subscriberProfile/', '');
  }

  handleDictate = result => {
    if (result) {
      // this.props.change('note', this.props.presentNoteValue + ' ' + result.result.transcript);
      let data;
      if (this.state.note) {
        data = `${this.state.note} ${result}`;
      } else {
        data = result;
      }
      this.setState({ note: data });
    }
  };

  render() {
    const { noteType, note, toggleActive, msg, isDischarged } = this.state;
    const {
      entNoteOptions,
      addEntNote,
      FeatureFlags: { enableDictation },
    } = this.props;
    return (
      !isDischarged && (
        <div>
          <form className="add-note-form enrollment-form" style={{ marginTop: '5px' }}>
            <div className="form-group">
              <ReduxSelect
                options={entNoteOptions}
                isMulti
                value={noteType}
                onChange={value => this.setState({ noteType: value, msg: false })}
                placeholder="Note Type....."
                classNamePrefix="note-type-select"
              />
              {msg && <span className="error-text">Required</span>}
            </div>
            <div className="form-group">
              <textarea
                className={`form-control ${note && 'not-empty'}`}
                value={note}
                onChange={event => {
                  this.setState({ note: event.target.value });
                }}
                rows="3"
                key="addANote"
              />
              <label className="placeholder-label">Add a note..</label>
            </div>

            <div className="row justify-content-between align-items-center">
              <div className="col-auto mb-3 mb-sm-2">
                <div className="custom-control" style={{ padding: 'unset' }}>
                  <label className="custom-control-label">
                    <Switch
                      checked={toggleActive}
                      onClick={() => this.setState({ toggleActive: !toggleActive })}
                      size="small"
                    />
                    &nbsp; Is Confidential
                  </label>
                </div>
              </div>

              <div className="col-auto d-flex align-items-start">
                {enableDictation && (
                  <div
                    className="dictation-space"
                    style={{ display: 'inline-flex', paddingRight: '10px' }}
                  >
                    <DictationComponent handleDictate={this.handleDictate} />
                  </div>
                )}
                <input
                  type="button"
                  onClick={() => {
                    if (noteType.length > 0) {
                      var noteTypeIds = '';
                      for (var item of noteType) {
                        noteTypeIds += item.Value + ',';
                      }
                      addEntNote(noteTypeIds, note, toggleActive);
                    } else {
                      this.setState({ msg: true });
                    }
                  }}
                  className="btn mr-1"
                  value="Add Note"
                  disabled={isDischarged}
                />
                <Link
                  className="btn"
                  to={{
                    pathname: '/printNotes',
                    search: 'userGuid=' + this.getUserGuid(),
                  }}
                  target="_blank"
                >
                  <i className="fas fa-print fa-lg"></i>
                </Link>
              </div>
            </div>
          </form>
        </div>
      )
    );
  }
}

NotesExtraComponent = connect(({ sp_list_combos }) => ({
  entNoteOptions: (sp_list_combos.data && sp_list_combos.data.EntNoteType) || [],
}))(withRouter(NotesExtraComponent));

class SurveysModalContent extends Component {
  state = {
    dueDate: '',
    surveyQuestionCategoryId: '',
    isDrawerOpen: false,
  };

  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  showDrawer = () => {
    this.setState({
      isDrawerOpen: true,
    });
  };
  onClose = () => {
    this.setState({
      isDrawerOpen: false,
    });
  };
  render() {
    const { dueDate, surveyQuestionCategoryId, isDrawerOpen, msg } = this.state;
    const { onOk, onCancel, entSurveyOptions, onAssignSurvey } = this.props;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        className="form-control datepicker"
        placeholder="Survey due date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <form>
        <div className="modal-content border-0">
          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
            <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
              &times;
            </button>
            <h4 className="modal-title col-12">Assign Survey</h4>
          </div>
          <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
            <div className="form-group isMultiHeight">
              <ReduxSelect
                options={entSurveyOptions}
                isMulti
                value={surveyQuestionCategoryId}
                onChange={value => this.setState({ surveyQuestionCategoryId: value, msg: false })}
                placeholder="Select"
                allowDefault={true}
              />
              {msg && <span className="error-text">Survey Required</span>}
            </div>
            <div className="form-group">
              <DatePicker
                selected={dueDate}
                onChange={this.handleChange}
                className="form-control datepicker"
                minDate={new Date()}
                customInput={<ExampleCustomInput />}
                // dateFormat="DD-MMM-YYYY"
              />
              <i className="far fa-calendar-alt form-control-icon" />
              {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
            </div>
            <div class="form-group text-right">
              {surveyQuestionCategoryId && surveyQuestionCategoryId.length > 0 && (
                <span
                  style={{ color: 'rgb(74, 176, 254)', cursor: 'pointer' }}
                  onClick={this.showDrawer}
                >
                  View selected Survey Questions
                </span>
              )}
            </div>
          </div>

          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
            <button type="button" className="btn black-btn" data-dismiss="modal" onClick={onCancel}>
              Close
            </button>
            <button
              type="button"
              className="btn "
              onClick={() => {
                if (surveyQuestionCategoryId.length > 0) {
                  var surveyQuestionCategoryIds = [];
                  for (var item of surveyQuestionCategoryId) {
                    surveyQuestionCategoryIds.push(item.Value);
                  }
                  if (!dueDate) {
                    this.setState({
                      isDueDateReq: true,
                    });
                  } else {
                    onOk();
                    onAssignSurvey(surveyQuestionCategoryIds.toString(), dueDate);
                  }
                } else {
                  this.setState({ msg: true });
                }
              }}
            >
              Assign
            </button>
          </div>
        </div>
        {isDrawerOpen && (
          <Drawer
            className="custom-drawer"
            title="View selected Survey Questions"
            placement="right"
            onClose={this.onClose}
            visible={isDrawerOpen}
            width="600"
          >
            <SurveysQuestionPreview surveyQuestionCategoryId={surveyQuestionCategoryId} />
          </Drawer>
        )}
      </form>
    );
  }
}

SurveysModalContent = connect(({ sp_list_combos }) => ({
  entSurveyOptions: (sp_list_combos.data && sp_list_combos.data.EntSurveyOptions) || [],
}))(withRouter(SurveysModalContent));

class NewTasksModalContent extends Component {
  constructor(props) {
    var DisplayName = sessionStorage.getItem('displayName');
    super(props);
    const { dueDate, taskDueTime, activeDate, activeTaskTime, isEdit } = props;
    this.input = React.createRef();
    this.state = {
      activeTaskTime: (isEdit && moment(activeTaskTime, 'HH:mm:ss')) || '',
      activeDate: (isEdit && new Date(activeDate)) || '',
      taskDueTime: (isEdit && moment(taskDueTime, 'HH:mm:ss')) || '',
      title: '',
      dueDate: (isEdit && new Date(dueDate)) || '',
      studentData: [{ UserGuid: this.getUserId(), DisplayName }],
    };
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: '' }));
  }
  getUserId() {
    return this.props.location.pathname.replace('/subscriberProfile/', '');
  }

  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  handleChange2 = date => {
    this.setState({
      activeDate: date,
    });
  };
  handleValueChange = time => {
    this.setState({
      activeTaskTime: time,
    });
  };
  handleTaskDueTime = time => {
    this.setState({
      taskDueTime: time,
    });
  };
  handelAssignExistingTask = () => {
    let {
      title,
      repeatFrequencyCode,
      dueDate,
      activeDate,
      activeTaskTime,
      taskDueTime,
      searchStr,
      studentData,
    } = this.state;
    if (this.props.isEdit) {
      this.props.onOk();
      this.props.onAssignNewTask(
        this.props.taskName,
        dueDate,
        activeDate,
        activeTaskTime,
        taskDueTime,
        this.props.UserId,
        this.props.AppTaskId,
        this.props.TaskUserId,
        this.props.RepeatFrequencyCode,
        this.props.RepeatDayOfWeek,
      );
    } else {
      if (!title || !dueDate || !studentData.length > 0) {
        if (!title) {
          this.setState({
            isTitleReq: true,
          });
        } else if (!dueDate) {
          this.setState({
            isDueDateReq: true,
          });
        } else if (!studentData.length > 0) {
          this.setState({
            isStudentDataReq: true,
          });
        }
      } else {
        this.props.onOk();
        this.props.onAssignNewTask(
          title,
          dueDate,
          activeDate,
          activeTaskTime,
          taskDueTime,
          studentData.map(item => item.UserGuid).toString(),
        );
      }
    }

    // onClick={() => {
    //   if (isEdit) {
    //     onOk();
    //     onAssignNewTask(
    //       this.props.taskName,
    //       dueDate,
    //       this.props.UserId,
    //       this.props.AppTaskId,
    //       this.props.TaskUserId,
    //       this.props.RepeatFrequencyCode,
    //       this.props.RepeatDayOfWeek,
    //     );
    //   } else {
    //     onOk();
    //     onAssignNewTask(title, dueDate, studentData.map(item => item.UserGuid).toString());
    //   }
    // }}
  };
  render() {
    const { onOk, onCancel, onAssignNewTask, searchOption, isEdit } = this.props;
    const {
      title,
      dueDate,
      activeDate,
      activeTaskTime,
      taskDueTime,
      searchStr,
      studentData,
    } = this.state;
    const me = this;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        className="form-control datepicker"
        placeholder="Task due date*"
        value={value}
        onClick={onClick}
      />
    );
    const ExampleCustomInput2 = ({ value, onClick }) => (
      <input
        type="text"
        className="form-control datepicker"
        placeholder="Task trigger date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" className="close" data-dismis="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 className="modal-title col-12">
            {isEdit ? 'Edit task due date' : 'Assign New Task'}
          </h4>
        </div>
        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5 enrollment-form">
          {!isEdit && (
            <div className="form-group">
              <input
                className={`form-control ${title && 'not-empty'}`}
                value={title}
                onChange={e => this.setState({ title: e.target.value, isTitleReq: false })}
              />
              <label className="placeholder-label">Enter Task Name*</label>
              {this.state.isTitleReq && <span className="error-text">Task Name Required</span>}
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  selected={dueDate}
                  onChange={this.handleChange}
                  className="form-control datepicker"
                  customInput={<ExampleCustomInput />}
                  // minDate={new Date()}
                />
                <i className="far fa-calendar-alt form-control-icon" />
                {this.state.isDueDateReq && (
                  <span className="error-text">Due Date Required</span>
                )}{' '}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label class="placeholder-label datepicker-label">Time</label>
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  showSecond={false}
                  className={`form-control`}
                  value={taskDueTime}
                  onChange={this.handleTaskDueTime}
                />
                <i className="far fa-clock form-control-icon" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  selected={activeDate}
                  onChange={this.handleChange2}
                  className="form-control datepicker"
                  customInput={<ExampleCustomInput2 />}
                  // minDate={new Date()}
                />
                <i className="far fa-calendar-alt form-control-icon" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label class="placeholder-label datepicker-label">Time</label>
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  showSecond={false}
                  className={`form-control`}
                  value={activeTaskTime}
                  onChange={this.handleValueChange}
                />
                {/* <ReduxTimePicker
            defaultValue={moment('12:08:23', 'HH:mm:ss')}
            placeholder="Active Task Time"
            onChange={this.handleTimeChange}
            value={this.state.activeTaskTime} /> */}
                <i className="far fa-clock form-control-icon" />
              </div>
            </div>
          </div>

          {!isEdit && (
            <div className="form-group">
              <Autocomplete
                getItemValue={item => `${item.UserGuid}`}
                items={
                  searchOption &&
                  searchOption.map(item => {
                    return {
                      label: item.DisplayName,
                      ...item,
                    };
                  })
                }
                renderItem={(item, isHighlighted) => {
                  return (
                    <div
                      key={item.UserGuid}
                      style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                    >
                      {item.label}
                    </div>
                  );
                }}
                onChange={e => {
                  this.setState({ searchStr: e.target.value, isStudentData: false });
                  this.props.dispatch(appSubscribersUserSearch({ SearchStr: e.target.value }));
                }}
                onSelect={value => {
                  const st = [...this.state.studentData];
                  for (let item of studentData) if (item.UserGuid == value) return;
                  for (let item of searchOption) if (item.UserGuid == value) st.push(item);

                  this.setState({ studentData: st, searchStr: '' });
                  this.props.dispatch(appSubscribersUserSearch({ SearchStr: '' }));
                  // this.props.history.push(`/studentProfile/${value}`);
                }}
                // value={searchStr}
                wrapperStyle={{ display: 'unset' }}
                renderInput={props => {
                  return (
                    <div
                      {...props}
                      style={{
                        borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                        paddingBottom: '2px',
                      }}
                    >
                      <ul
                        style={{ height: 40, overflowY: 'auto', padding: 'unset', margin: 'unset' }}
                        onClick={() => me.input.current.focus()}
                      >
                        {studentData.map((item, index) => (
                          <li className="multi-auto">
                            {item.DisplayName}
                            <Close
                              onClick={event => {
                                event.preventDefault();
                                studentData.splice(index, 1);
                                me.setState({ studentData });
                              }}
                            />
                          </li>
                        ))}
                        <li className="multi-auto">
                          <input
                            type="text"
                            onClick={event => {
                              event.preventDefault();
                            }}
                            ref={me.input}
                            value={searchStr}
                            style={{
                              border: 'none',
                              width: (searchStr && searchStr.length * 13) || '0.75em',
                              maxWidth: '100%',
                              backgroundColor: 'inherit',
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                  );
                }}
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 4px',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'auto',
                  maxHeight: '50%',
                  zIndex: 1,
                  width: 'inherit',
                }}
              />
              {this.state.isStudentDataReq && (
                <span className="error-text">Please select atleast one student</span>
              )}
            </div>
          )}
        </div>

        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" className="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
          <button
            type="button"
            className="btn "
            onClick={this.handelAssignExistingTask}
            // onClick={() => {
            //   if (isEdit) {
            //     onOk();
            //     onAssignNewTask(
            //       this.props.taskName,
            //       dueDate,
            //       this.props.UserId,
            //       this.props.AppTaskId,
            //       this.props.TaskUserId,
            //       this.props.RepeatFrequencyCode,
            //       this.props.RepeatDayOfWeek,
            //     );
            //   } else {
            //     onOk();
            //     onAssignNewTask(title, dueDate, studentData.map(item => item.UserGuid).toString());
            //   }
            // }}
          >
            Assign
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsNewTasks = ({ app_subscribers_user_search }) => {
  const { data, error, isFetching } = app_subscribers_user_search;
  return {
    searchOption: (data && Object.values(data)) || [],
  };
};

const ReduxNewTasksModalContent = connect(mapStateToPropsNewTasks)(
  withRouter(NewTasksModalContent),
);

class TasksModalContent extends Component {
  constructor(props) {
    var DisplayName = sessionStorage.getItem('displayName');
    super(props);
    this.input = React.createRef();
    this.state = {
      taskId: '',
      repeatFrequencyCode: '',
      dueDate: '',
      studentData: [{ UserGuid: this.getUserId(), DisplayName }],
    };
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: '' }));
  }
  getUserId() {
    return this.props.location.pathname.replace('/subscriberProfile/', '');
  }

  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  handelAssignExistingTask = () => {
    let {
      taskId,
      repeatFrequencyCode,
      dueDate,
      activeDate,
      activeTaskTime,
      taskDueTime,
      searchStr,
      studentData,
    } = this.state;
    if (!taskId || !repeatFrequencyCode || !dueDate || !studentData.length > 0) {
      if (!taskId) {
        this.setState({
          istaskReq: true,
        });
      } else if (!repeatFrequencyCode) {
        this.setState({
          isRepeatFrequencyReq: true,
        });
      } else if (!dueDate) {
        this.setState({
          isDueDateReq: true,
        });
      } else if (!studentData.length > 0) {
        this.setState({
          isStudentDataReq: true,
        });
      }
    } else {
      this.props.onOk();
      this.props.onAssignExistingTask(
        taskId,
        repeatFrequencyCode,
        dueDate,
        activeDate,
        activeTaskTime,
        taskDueTime,
        studentData.map(item => item.UserGuid).toString(),
      );
      //   onAssignExistingTask(
      //     taskId,
      //     repeatFrequencyCode,
      //     dueDate,
      //     studentData.map(item => item.UserGuid).toString(),
      //   );
    }
  };
  render() {
    const { onOk, onCancel, taskOptions, onAssignExistingTask, searchOption } = this.props;
    const {
      taskId,
      repeatFrequencyCode,
      dueDate,
      activeDate,
      activeTaskTime,
      taskDueTime,
      searchStr,
      studentData,
    } = this.state;
    const me = this;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        className="form-control datepicker"
        placeholder="Task due date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 className="modal-title col-12">Assign Pre-defined Task</h4>
        </div>
        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div className="form-group">
            <Select
              data={taskOptions}
              allowDefault={true}
              value={taskId}
              onChange={event => {
                this.setState({ taskId: event.target.value, istaskReq: false });
              }}
            />
            <i className="fas fa-angle-down form-control-icon" />
            {this.state.istaskReq && <span className="error-text">Task is Required</span>}
          </div>
          <div className="form-group">
            <Select
              data={[
                { Value: 'W', Text: 'Weekly' },
                { Value: 'O', Text: 'One Time' },
                { Value: 'D', Text: 'Daily' },
              ]}
              allowDefault={true}
              value={repeatFrequencyCode}
              onChange={event =>
                this.setState({
                  repeatFrequencyCode: event.target.value,
                  isRepeatFrequencyReq: false,
                })
              }
            />
            <i className="fas fa-angle-down form-control-icon" />
            {this.state.isRepeatFrequencyReq && (
              <span className="error-text">Frequency is Required</span>
            )}
          </div>

          <div className="form-group">
            <DatePicker
              selected={dueDate}
              onChange={this.handleChange}
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              minDate={new Date()}
            />
            <i className="far fa-calendar-alt form-control-icon" />
            {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
          </div>

          <div className="form-group">
            <Autocomplete
              getItemValue={item => `${item.UserGuid}`}
              items={searchOption.map(item => {
                return {
                  label: item.DisplayName,
                  ...item,
                };
              })}
              renderItem={(item, isHighlighted) => {
                return (
                  <div
                    key={item.UserGuid}
                    style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                  >
                    {item.label}
                  </div>
                );
              }}
              onChange={e => {
                this.setState({ searchStr: e.target.value });
                this.props.dispatch(appSubscribersUserSearch({ SearchStr: e.target.value }));
              }}
              onSelect={value => {
                const st = [...this.state.studentData];
                for (let item of studentData) if (item.UserGuid == value) return;
                for (let item of searchOption) if (item.UserGuid == value) st.push(item);

                this.setState({ studentData: st, searchStr: '', isStudentDataReq: false });
                this.props.dispatch(appSubscribersUserSearch({ SearchStr: '' }));
                // this.props.history.push(`/subscriberProfile/${value}`);
              }}
              // value={searchStr}
              wrapperStyle={{ display: 'unset' }}
              renderInput={props => {
                return (
                  <div
                    {...props}
                    style={{
                      borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                      paddingBottom: '2px',
                    }}
                  >
                    <ul
                      style={{ height: 40, overflowY: 'auto', padding: 'unset', margin: 'unset' }}
                      onClick={() => me.input.current.focus()}
                    >
                      {studentData.map((item, index) => (
                        <li className="multi-auto">
                          {item.DisplayName}
                          <Close
                            onClick={event => {
                              event.preventDefault();
                              studentData.splice(index, 1);
                              me.setState({ studentData });
                            }}
                          />
                        </li>
                      ))}
                      <li className="multi-auto">
                        <input
                          type="text"
                          onClick={event => {
                            event.preventDefault();
                          }}
                          ref={me.input}
                          value={searchStr}
                          style={{
                            border: 'none',
                            width: (searchStr && searchStr.length * 13) || '0.75em',
                            maxWidth: '100%',
                            backgroundColor: 'inherit',
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                );
              }}
              menuStyle={{
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '2px 4px',
                fontSize: '90%',
                position: 'fixed',
                overflow: 'auto',
                maxHeight: '50%',
                zIndex: 1,
                width: 'inherit',
              }}
            />
            {this.state.isStudentDataReq && (
              <span className="error-text">Please select atleast one student</span>
            )}
          </div>
        </div>

        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" className="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
          <button
            type="button"
            className="btn "
            onClick={this.handelAssignExistingTask}
            // onClick={() => {
            //   onOk();
            //   onAssignExistingTask(
            //     taskId,
            //     repeatFrequencyCode,
            //     dueDate,
            //     studentData.map(item => item.UserGuid).toString(),
            //   );
            // }}
          >
            Assign
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsTasks = ({ app_subscribers_user_search, sp_list_combos }) => {
  const { data, error, isFetching } = app_subscribers_user_search;
  let taskOptions = [];

  if (sp_list_combos && sp_list_combos.data && sp_list_combos.data.EntTaskOptions) {
    taskOptions = sp_list_combos.data.EntTaskOptions || [];
  }
  return {
    searchOption: (data && Object.values(data)) || [],
    taskOptions,
  };
};

const ReduxTasksModalContent = connect(mapStateToPropsTasks)(withRouter(TasksModalContent));

class VideoModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(
      getCallSessionVideoUrl({
        VMCallId: this.props.VMCallId,
      }),
    );
  }
  render() {
    const { onOk, onCancel, VideoCallUrl, Title, CallDate } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-1">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 className="modal-title col-12">{Title}</h4>
          <h6>Call Date :{CallDate}</h6>
        </div>
        <div className="modal-body" style={{ padding: '2px 50px', paddingBottom: '12px' }}>
          <Loader2 loading={!VideoCallUrl} />
          {/* <iframe
            width="100%"
            height="315"
            src={VideoCallUrl}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          /> */}
          {VideoCallUrl.includes('https') ? (
            <video
              style={{ border: '3px solid #EEE' }}
              width="100%"
              height="auto"
              autoplay="true"
              controls
              disablePictureInPicture="true"
              controlsList="nodownload"
            >
              <source src={VideoCallUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div className="text-center">
              <h5>{VideoCallUrl}</h5>
            </div>
          )}
        </div>

        <div className="modal-footer justify-content-center border-top-0 py-2">
          <button type="button" className="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsGetUrl = ({ get_call_session_video_url }) => {
  const { data, error, isFetching } = get_call_session_video_url;
  let VideoCallUrl = '';

  if (get_call_session_video_url && get_call_session_video_url.data) {
    VideoCallUrl = data.result;
  }
  return {
    VideoCallUrl,
  };
};

VideoModalContent = connect(mapStateToPropsGetUrl)(withRouter(VideoModalContent));

class PanelHeader extends Component {
  render() {
    const { headerData } = this.props;
    let color = headerData.Color === 'blue' ? 'blue' : 'default';
    const statusText = {
      default: {
        text: 'Up Next',
        class: 'fa fa-lock mr-1',
      },
      blue: {
        text: 'Current Level',
        class: '',
      },
      green: {
        text: 'Level Achieved',
        class: 'fa fa-check',
      },
    };
    return (
      <div className="panel-header row">
        <div className="col-3 px-0">
          <div className="panel-header-left">
            <div className="outcome-icon">
              <img src={`images/Outcomes@2x_${color}.png`} alt="Outcomes" />
              <span>{headerData.Level}</span>
            </div>
            <span className="day-count">{headerData.Range}</span>
          </div>
        </div>
        <div className="col-9">
          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap">
              <span className="panel-title mr-2">{headerData.Heading}</span>
              <div className="mr-2 panel-title-status">
                <i className={statusText[headerData.Color].class} />{' '}
                {statusText[headerData.Color].text}
              </div>
              {headerData.CompletedDate && (
                <span className="panel-date">{headerData.CompletedDate}</span>
              )}
            </div>
            <span className="panel-desc">{headerData.LevelDefinition}</span>
          </div>
        </div>
      </div>
    );
  }
}

class FullDetailModal extends Component {
  render() {
    const { onOk, onCancel, RecoveryJourney } = this.props;

    return (
      <div className="modal-content border-0">
        <div className="modal-header  border-bottom-0 mb-2">
          {/* <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button> */}
          <h4 className="modal-title">Ladders & Measures</h4>
        </div>
        <div className="modal-body pt-3 mx-2 pb-0 landM">
          <div className="row">
            <div className="col-12">
              <Collapse
                accordion
                bordered={false}
                defaultActiveKey={activePanel + 'blue'}
                expandIconPosition="right"
                // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                {RecoveryJourney &&
                  RecoveryJourney.map((item, index) => {
                    activePanel = index;
                    return (
                      <Panel
                        header={<PanelHeader headerData={item} />}
                        key={activePanel + item.Color}
                        className={`site-collapse-custom-panel-${item.Color}`}
                        showArrow={false}
                      >
                        <div className="panel-text">
                          <ul>
                            {item.Question && item.Question.map(ques => <li>{ques.Question}</li>)}
                          </ul>
                        </div>
                      </Panel>
                    );
                  })}
              </Collapse>
            </div>
          </div>
        </div>

        <div className="modal-footer justify-content-center border-top-0 pb-3">
          <button type="button" className="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

function getDefaultTab(props) {
  const query = new URLSearchParams(props.location.search);
  let tabName = query.get('tab_name');
  if (tabName === 'location') {
    return 0;
  } else if (tabName === 'chats') {
    return 1;
  } else if (tabName === 'journal') {
    return 2;
  } else if (tabName === 'videocalllogs') {
    return 3;
  } else if (tabName === 'activities') {
    return 4;
  }
}

function getDefaultSubTab(props) {
  const query = new URLSearchParams(props.location.search);
  let tabName = query.get('stab_name');
  if (getDefaultTab(props)) {
    if (tabName === 'survey' || tabName === 'addnote') {
      return 0;
    } else if (tabName === 'task') {
      return 1;
    } else if (tabName === 'course') {
      return 2;
    } else if (tabName === 'smallgroup') {
      return 3;
    }
  }
}
class SubscriberProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestData: false,
      isToggleOn: true,
      isToggleOn2: true,
      isMapOn: false,
      isMapOn2: false,
      subTabKey: getDefaultSubTab(props),
      subTabKey1: 0,
      activeKey: getDefaultTab(props),
      activeKeyChanged: false,
      viewTimelineModal: false,
      lastDayLat: '',
      lastDayLng: '',
      triggerTypeImgUrl: '',
      selectedDate: '',
    };
  }
  setRequestData = () => {
    this.setState(prevState => ({
      requestData: !prevState.requestData,
    }));
  };
  loadNewMap = data => {
    const {
      Duration,
      DurationMins,
      EndTime,
      FullAddress,
      IsPositive,
      Latitude,
      Longitude,
      Time,
      TriggerGuid,
      TriggerSign,
      TriggerTypeId,
      TriggerTypeImgUrl,
      TriggerTypeValue,
      cardDetailedText,
      cardSubtitle,
      cardTitle,
    } = data;

    this.setState({
      lastDayLat: Latitude,
      lastDayLng: Longitude,
      triggerTypeImgUrl: TriggerTypeImgUrl,
      isPositive: IsPositive,
      triggerSign: TriggerSign,
    });
  };
  getLocationHisData = date => {
    let url = window.location.href;
    let uId = url.split('/')[url.split('/').length - 1];
    let data = {
      TriggerMap: [{ UserGuid: uId, FromDate: date }],
    };

    this.props.dispatch(locationDayHistory({ Json: JSON.stringify(data) }));
  };

  handleClick = () => {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      isMapOn: !prevState.isMapOn,
    }));
  };
  handleClick2 = () => {
    this.setState(prevState => ({
      isToggleOn2: !prevState.isToggleOn2,
      isMapOn2: !prevState.isMapOn2,
    }));
  };

  addEntNote = (noteType, note, toggleActive) => {
    // this.state.requestData = true;
    if (note) {
      this.props.dispatch(
        addAppSubscribersNote({
          note,
          NoteTypeIds: noteType,
          UserGuid: this.getUserGuid(),
          isConfidential: toggleActive == true ? 1 : 0,
        }),
      );
      this.setState({ note: '', noteType: '' });
    }
  };

  onAssignSurvey = (SurveyQuestionCategoryIds, DueDate) => {
    this.state.requestData = true;
    var obj = {
      UserGuid: this.getUserGuid(),
      SurveyQuestionCategoryIds,
      DueDate,
    };
    this.props.dispatch(addAppSubscribersSurvey(obj));
  };

  onTaskUnAssign = TaskUserId => {
    this.state.requestData = true;
    var data = {
      TaskUserId,
    };
    this.props.dispatch(deleteAppSubscribersTask(data));
  };

  onTaskInComplete = surveyId => {
    this.state.requestData = true;
    var data = {
      surveyId,
    };
    this.props.dispatch(deleteAppSubscribersSurvey(data));
  };

  onAssignExistingTask = (
    TaskId,
    RepeatFrequencyCode,
    DueDate,
    activeDate,
    activeTaskTime,
    taskDueTime,
    UserGuids,
  ) => {
    this.state.requestData = true;
    var json = {
      Task: [
        {
          TaskId,
          TaskUser: [
            {
              RepeatFrequencyCode,
              // AssignedDate: '2019-01-23T00:00:00',
              RepeatDayOfWeek: null,
              DueDate: moment(DueDate).format('YYYY-MM-DD'),
            },
          ],
        },
      ],
    };
    var data = {
      UserGuids,
      json: JSON.stringify(json),
    };
    this.props.dispatch(addAppSubscribersTask(data));
  };

  onAssignNewTask = (
    Title,
    DueDate,
    ActiveDate,
    ActiveTaskTime,
    TaskDueTime,
    UserGuids,
    AppTaskId,
    TaskUserId,
    RepeatFrequencyCode,
    RepeatDayOfWeek,
  ) => {
    this.state.requestData = true;
    let json = {
      Task: [
        {
          TaskId: AppTaskId || null,
          Title,
          Detail: null,
          TaskUser: [
            {
              TaskUserId: TaskUserId || null,
              RepeatFrequencyCode: RepeatFrequencyCode || null,
              RepeatDayOfWeek: RepeatDayOfWeek || null,
              // RepeatDayOfWeek: null,
              DueDate: moment(DueDate).format('YYYY-MM-DD'),
              TaskDueTime: moment(TaskDueTime._d).format('HH:mm:ss'),
              TaskActivatedDate: moment(ActiveDate).format('YYYY-MM-DD'),
              TaskActivatedTime: moment(ActiveTaskTime._d).format('HH:mm:ss'),
            },
          ],
        },
      ],
    };
    var data = {
      UserGuids,
      json: JSON.stringify(json),
    };
    this.props.dispatch(addAppSubscribersTask(data));
  };

  onTaskComplete = TaskUserId => {
    this.state.requestData = true;
    var data = { TaskUserId };
    this.props.dispatch(AppSubscribersCompleteTask(data));
  };

  assignCourse = (CourseIds, DueDate) => {
    this.state.requestData = true;
    let data = {
      UserGuid: this.getUserGuid(),
      CourseIds,
      DueDate: stringDate(DueDate),
    };
    this.props.dispatch(addAppSubscribersCourse(data));
  };

  onUnAssign(CourseUserId) {
    this.state.requestData = true;
    var data = {
      CourseUserId,
    };

    this.props.dispatch(deleteAppSubscribersCourse(data));
  }

  onMarkComplete(CourseUserId) {
    this.state.requestData = true;
    var data = {
      CourseUserId,
    };

    this.props.dispatch(appSubscribersCompleteCourse(data));
  }

  getUserGuid() {
    return this.props.location.pathname.replace('/subscriberProfile/', '');
  }
  // componentWillReceiveProps(nextProps) {
  //   const { SubscriberData } = nextProps;
  //   this.setState(SubscriberData);
  // }
  lastDays = ({ date, dayCount }) => {
    var fromDate = null;
    var toDate = null;

    if (date) {
      fromDate = date[0].format('YYYY-MM-DD');
      toDate = date[1].format('YYYY-MM-DD');
      // toDate = date[1].format('YYYY-MM-DD  hh:mm:ss.mmm');
    } else if (dayCount) {
      fromDate = moment()
        .subtract(dayCount, 'd')
        .format('YYYY-MM-DD');
      toDate = moment().format('YYYY-MM-DD');
      // toDate = moment().format('YYYY-MM-DD  hh:mm:ss.mmm');
    }

    this.props.dispatch(
      appSubscribersMapHistory({
        userGuid: this.getUserGuid(),
        fromDate,
        toDate,
      }),
    );
  };
  // fnGetVideoUrl = VMCallId => {
  //   this.props.dispatch(
  //     getCallSessionVideoUrl({
  //       VMCallId: VMCallId,
  //     }),
  //   );
  // };

  handelObservation = data => {
    let { SystemObservation } = this.state;
    let { isAccepted, observationId } = data;
    let json = {
      User: [
        { UserGuid: this.getUserGuid(), ObservationId: observationId, IsAccepted: isAccepted },
      ],
    };

    var data = {
      json: JSON.stringify(json),
    };
    this.props.dispatch(appMarkSystemObservation(data));
    let objIndex = SystemObservation.findIndex(obj => obj.ObservationId === observationId);
    SystemObservation[objIndex].IsAccepted = isAccepted;
    this.setState({
      SystemObservation,
    });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };

  viewTimeline = activeTab => {
    this.setState({
      viewTimelineModal: true,
      UserGuid: this.getUserGuid(),
      activeTab: activeTab,
    });
    this.props.dispatch(
      getBreathalyzerSchedule({
        Json: JSON.stringify({
          BreathalyzerSchedule: [
            {
              UserGuid: this.getUserGuid(),
            },
          ],
        }),
      }),
    );
  };

  onDateFieldChange = date => {
    this.getLocationHisData(date.format('YYYY-MM-DD'));
    if (date) {
      this.setState({ selectedDate: date });
    }
  };
  componentDidMount() {
    if (getDefaultTab(this.props)) {
      window.scrollTo({
        top: 500,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, 0);
    }
    let todaysDate = moment().format('DD/MM/YYYY');
    this.setState({ selectedDate: todaysDate });
    this.getLocationHisData(moment().format('YYYY-MM-DD'));
    this.props.dispatch(getCallSessionVideo({ ClientUserGuid: this.getUserGuid() }));
    this.props.dispatch(getAppSubscribersDashboard({ UserGuid: this.getUserGuid() }));
    this.props.dispatch(getAppSubscribersActivities({ UserGuid: this.getUserGuid() }));
    this.props.dispatch(getAppSubscribersChatCoach({ UserGuid: this.getUserGuid() }));
    this.props.dispatch(getUserNotePatient({ UserGuid: this.getUserGuid() }));

    let data = {
      Location: [{ UserGuid: this.getUserGuid() }],
    };

    this.props.dispatch(appUserLiveLocationGet({ Json: JSON.stringify(data) }));

    // this.props.dispatch(
    //   appSubscribersMapHistory({
    //     userGuid: this.getUserGuid(),
    //     fromDate: moment()
    //       .subtract(7, 'd')
    //       .format('YYYY-MM-DD'),
    //     toDate: moment().format('YYYY-MM-DD hh:mm:ss.mmm'),
    //   }),
    // );
    this.props.dispatch(
      appSubscribersMapHistory({
        userGuid: this.getUserGuid(),
        fromDate: moment()
          .subtract(7, 'd')
          .format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD hh:mm:ss.mmm'),
      }),
    );
    this.props.dispatch(
      spListCombos({
        comboTypes: 'EntTaskOptions,EntNoteType,EntSurveyOptions,EntCourses',
        UserGuid: this.getUserGuid(),
      }),
    );
  }

  componentWillReceiveProps({
    isSurveyAssigned,
    isTaskUnAssigned,
    isTaskAssigned,
    isSurveyUnAssigned,
    isTaskCompleted,
    isNoteAdded,
    isCourseAdded,
    isCourseCompleted,
    isCourseDeleted,
    SubscriberData: { Name },
    UserMisc,
    isMarkObservation,
    isPushTest,
    IsBreathalyzerUser,
    IsBreathalyzerUserEnable,
    isBreathalyzerInserted,
    // VideoCallUrl,
    locationHistoryDataArray,
    surveyanswersdata,
    SmallGroupStatusData,
  }) {
    if (
      surveyanswersdata &&
      surveyanswersdata.length > 0 &&
      JSON.stringify(surveyanswersdata) != JSON.stringify(this.props.surveyanswersdata)
    ) {
      this.setState({ surveyanswersdata, isResultDrawerOpen: true });
    }
    if (
      locationHistoryDataArray &&
      locationHistoryDataArray != this.props.locationHistoryDataArray
    ) {
      if (locationHistoryDataArray.length > 0) {
        this.setState({
          // lastDayLng:-96.961318076,
          //   lastDayLat:32.945373535,
          lastDayLat: locationHistoryDataArray[0].Latitude,
          lastDayLng: locationHistoryDataArray[0].Longitude,
          triggerTypeImgUrl: locationHistoryDataArray[0].TriggerTypeImgUrl,
          isPositive: locationHistoryDataArray[0].IsPositive,
          triggerSign: locationHistoryDataArray[0].TriggerSign,
        });
      }
    }
    // if (VideoCallUrl && VideoCallUrl != this.props.VideoCallUrl) {
    //   window.open(VideoCallUrl, '_blank');
    // }
    if (isPushTest && isPushTest != this.props.isPushTest) {
      this.toggle('Breathalyzer test has been initiated');
    }
    if (isBreathalyzerInserted && isBreathalyzerInserted != this.props.isBreathalyzerInserted) {
      this.toggle('Breathalyzer test has been initiated');
    }
    if (IsBreathalyzerUser && IsBreathalyzerUser != this.props.IsBreathalyzerUser) {
      this.setState({
        IsBreathalyzerUser: IsBreathalyzerUser,
      });
    }
    if (
      IsBreathalyzerUserEnable &&
      IsBreathalyzerUserEnable != this.props.IsBreathalyzerUserEnable
    ) {
      this.setState({
        IsBreathalyzerUser: IsBreathalyzerUserEnable,
      });
    }
    if (UserMisc && UserMisc.SystemObservation) {
      this.setState({
        SystemObservation: UserMisc.SystemObservation,
      });
    }
    if (Name !== this.props.SubscriberData.Name && Name) {
      sessionStorage.setItem('displayName', Name);
    }
    if (isSurveyAssigned) {
      this.props.dispatch(resetAppSubscribersSurvey());
      this.props.dispatch(
        spListCombos({
          comboTypes: 'EntTaskOptions,EntNoteType,EntSurveyOptions,EntCourses',
          UserGuid: this.getUserGuid(),
        }),
      );
    }
    if (isTaskUnAssigned) this.props.dispatch(resetDeleteAppSubscribersTask());
    if (isTaskAssigned) this.props.dispatch(resetAppSubscribersTask());
    if (isSurveyUnAssigned) {
      this.props.dispatch(resetAppSubscribersUnassignSurvey());
      this.props.dispatch(
        spListCombos({
          comboTypes: 'EntTaskOptions,EntNoteType,EntSurveyOptions,EntCourses',
          UserGuid: this.getUserGuid(),
        }),
      );
    }
    if (isTaskCompleted) this.props.dispatch(resetAppSubscribersCompleteTask());
    if (isNoteAdded) {
      this.props.dispatch(resetAddAppSubscribersNote());
      this.props.dispatch(getAppSubscribersDashboard({ UserGuid: this.getUserGuid() }));
    }
    if (isCourseAdded) this.props.dispatch(resetAddAppSubscribersCourse());
    if (isCourseCompleted) this.props.dispatch(resetAppSubscribersCompleteCourse());
    if (isCourseDeleted) this.props.dispatch(resetDeleteAppSubscribersCourse());
    if (
      (isSurveyAssigned ||
        isTaskUnAssigned ||
        isTaskAssigned ||
        isSurveyUnAssigned ||
        isTaskCompleted ||
        isCourseAdded ||
        isCourseCompleted ||
        isCourseDeleted) &&
      this.state.requestData
    ) {
      if (isSurveyAssigned) {
        this.toggle('Survey is assigned Successfully');
      } else {
        this.state.requestData = false;
        this.props.dispatch(getAppSubscribersActivities({ UserGuid: this.getUserGuid() }));
      }
    }
    if (
      SmallGroupStatusData &&
      JSON.stringify(SmallGroupStatusData) != JSON.stringify(this.props.SmallGroupStatusData)
    ) {
      this.setState({ SmallGroupStatusData });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(nextProps.ActivitiesSurveyUser) !=
        JSON.stringify(this.props.ActivitiesSurveyUser) ||
      JSON.stringify(nextProps.ActivitiesTasks) != JSON.stringify(this.props.ActivitiesTasks) ||
      JSON.stringify(nextProps.ActivitiesSmallGroup) !=
        JSON.stringify(this.props.ActivitiesSmallGroup) ||
      JSON.stringify(nextProps.SubscriberData) != JSON.stringify(this.props.SubscriberData) ||
      JSON.stringify(nextProps.triggeredHistory) != JSON.stringify(this.props.triggeredHistory) ||
      JSON.stringify(nextProps.BreathalyzerData) != JSON.stringify(this.props.BreathalyzerData) ||
      JSON.stringify(nextProps.surveyanswersdata) != JSON.stringify(this.props.surveyanswersdata) ||
      nextProps.isFetchingPushTest != this.props.isFetchingPushTest ||
      nextProps.isFetchingEnable != this.props.isFetchingEnable ||
      this.state.isToggleOn != nextState.isToggleOn ||
      this.state.isToggleOn2 != nextState.isToggleOn2 ||
      this.props.mapData == null ||
      this.state.viewTimelineModal != nextState.viewTimelineModal ||
      this.state.isModalOpen != nextState.isModalOpen ||
      this.state.IsBreathalyzerUser != nextState.IsBreathalyzerUser ||
      nextProps.isMarkObservation != this.props.isMarkObservation ||
      JSON.stringify(nextProps.locationHistoryDataArray) !=
        JSON.stringify(this.props.locationHistoryDataArray) ||
      this.state.lastDayLat != nextState.lastDayLat ||
      this.state.lastDayLng != nextState.lastDayLng ||
      this.state.triggerTypeImgUrl != nextState.triggerTypeImgUrl ||
      this.state.isPositive != nextState.isPositive ||
      nextState.isResultDrawerOpen != this.state.isResultDrawerOpen ||
      this.state.triggerSign != nextState.triggerSign
      // ||
      // JSON.stringify(nextProps.locationHistoryDataArray) != JSON.stringify(this.props.locationHistoryDataArray)
    ) {
      return true;
    } else {
      return false;
    }
  }
  toggleDrawer = () => {
    this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
  };
  render() {
    const {
      UserMisc,
      SubscriberData,
      isFetchingDashboard,
      isFetchingChat,
      ChatCoach,
      NoteUser,
      ActivitiesSurveyUser,
      ActivitiesTasks,
      ActivitiesSmallGroup,
      ActivitiesCourse,
      triggeredHistory,
      isFetchingMap,
      VideoCallLogs,
      Breathalyzer,
      BreathalyzerData,
      // IsBreathalyzerUser,
      ShowBreathalyzer,
      ShowSubscriptionService,
      isFetchingEnable,
      availableDatesArray,
      FeatureFlags: { enableDictation },
    } = this.props;
    const {
      Name,
      Age,
      AppJoiningDate,
      CurrentSubscription,
      UserProfileImageUrl,
      ASIScore,
      GatewayScore,
      PsychometricScore,
      SWBScore,
      Milestone,
      RecoveryJourney,
      AppLastOpened,
      NotilLastDelivered,
      LocationLastCaptured,
      BadgeId,
    } = SubscriberData;
    const {
      LadderChartData,
      LadderChartLabels,
      BehaviorData,
      BehaviorLabels,
      // SystemObservation,
    } = UserMisc;
    const StudentOutcomeData = RecoveryJourney && RecoveryJourney.slice(0, 3);
    const { SystemObservation, IsBreathalyzerUser } = this.state;
    const { IsBreathalyzer, BreathalyzerTestResult, LastTestResult, NextTest } = Breathalyzer;
    const { TestDate, ResultClassBlock, ResultClassText, ResultText } =
      (LastTestResult && LastTestResult[0]) || {};
    // const {
    //   LadderChartData,
    //   LadderChartLabels,
    //   BehaviorData,
    //   BehaviorLabels,
    //   SystemObservation,
    // } = UserMisc[0];
    const barData = {
      labels: LadderChartLabels && LadderChartLabels.split(','),
      datasets: [
        {
          data: LadderChartData && LadderChartData.split(','),
          backgroundColor: ['#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd'],
        },
      ],
    };
    const behaviourData = {
      labels: BehaviorLabels && BehaviorLabels.split(','),
      datasets: [
        {
          data: BehaviorData && BehaviorData.split(','),
        },
      ],
    };
    const barOptions = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            maxBarThickness: 66,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    // List2 For Acticities
    const list2 = [
      {
        Name: 'Survey',
        ExtraComponent: props => (
          <>
            <CustomModal
              ModalButton={({ onClick }) => (
                <div className="modal-section text-center mb-4">
                  <button className="btn" onClick={onClick}>
                    Assign Survey
                  </button>
                </div>
              )}
              ModalContent={props => (
                <SurveysModalContent onAssignSurvey={this.onAssignSurvey} {...props} />
              )}
            />
          </>
        ),
        Component: props => {
          const {
            SurveyQuestionCategoryId,
            SurveyName,
            AssignedDate,
            DueDate,
            StartedDate,
            CompletedDate,
            DiscardedDate,
            DueStatus,
            DueDateFontColor,
            FirstName,
            LastName,
            SurveyStatus,
            SurveyId,
          } = props;
          const surveyStatus = {
            '-1': { color: '#fef2f2', hide: true, date: DiscardedDate, name: 'Discarded' },
            '0': {
              color: '#f8f8f8',
              hide: false,
              date: DueDate,
              name: 'Due',
              dateFontColor: DueDateFontColor,
            },
            '1': {
              color: 'rgb(239, 247, 254)',
              hide: true,
              date: CompletedDate,
              name: 'Completed',
              sendRequest: true,
            },
          };
          return (
            <>
              <div
                style={{
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              >
                <div
                  className="task-box"
                  style={{
                    cursor: surveyStatus[SurveyStatus].sendRequest ? 'pointer' : '',
                    backgroundColor: surveyStatus[SurveyStatus]['color'],
                  }}
                >
                  <div className="row no-gutters w-100 justify-content-between align-items-center">
                    <div
                      className="col-12 col-md-10"
                      onClick={() => {
                        if (!surveyStatus[SurveyStatus].sendRequest) {
                          return;
                        }
                        let data = {
                          Survey: [
                            {
                              UserGuid: this.getUserGuid(),
                              SurveyId: SurveyId,
                            },
                          ],
                        };
                        let jsonData = {
                          Json: JSON.stringify(data),
                        };
                        surveyStatus[SurveyStatus].sendRequest &&
                          this.props.dispatch(appSubSurveyAnsGet(jsonData));
                        this.state.answerRecord = {
                          UserGuid: this.getUserGuid(),
                          SurveyId: SurveyId,
                          SurveyCategory: SurveyName,
                          CompletedDt: CompletedDate,
                        };
                      }}
                      // onClick={() => {
                      //   !surveyStatus[SurveyStatus]['hide'] &&
                      //     this.props.history.push(
                      //       '/validate?surveyId=' + SurveyId + '&studentId=' + StudentId,
                      //     );
                      //   surveyStatus[SurveyStatus].sendRequest &&
                      //     this.props.dispatch(
                      //       surveyAnswersGet({
                      //         studentId: this.getUserGuid(),
                      //         surveyId: SurveyId,
                      //       }),
                      //     );
                      // }}
                    >
                      <div className="course-name">
                        <Paragraph
                          ellipsis={{ rows: 1, expandable: false }}
                          textAlign="justify"
                          style={{ color: '#000000', fontSize: '17px', marginBottom: '2px' }}
                        >
                          {SurveyName}
                        </Paragraph>
                        <div className="" style={{ display: 'flex' }}>
                          <div className="">
                            <span className="due-date">
                              <em>Assigned:</em> {AssignedDate}
                            </span>
                          </div>
                          <div className="">
                            <span
                              className="due-date"
                              style={{ color: surveyStatus[SurveyStatus].dateFontColor }}
                            >
                              <em>{surveyStatus[SurveyStatus].name} : </em>{' '}
                              {surveyStatus[SurveyStatus].date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="actions-button justify-content-xl-end">
                        {!surveyStatus[SurveyStatus]['hide'] && (
                          <CustomModal
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title={'Incomplete'}>
                                <span className="delete-course" onClick={onClick}>
                                  <i className="fas fa-times" />
                                </span>
                              </Tooltip>
                            )}
                            ModalContent={({ onOk, onCancel }) => (
                              <div className="modal-content border-0">
                                <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    &times;
                                  </button>
                                  <h4 className="modal-title col-12">
                                    Do you want to mark this survey as incomplete?
                                  </h4>
                                </div>

                                <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                  <button
                                    type="button"
                                    className="btn black-btn"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className="btn "
                                    onClick={() => {
                                      onOk();
                                      this.onTaskInComplete(SurveyId);
                                    }}
                                  >
                                    Ok
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        },
        Content: ActivitiesSurveyUser || [],
      },
      {
        Name: 'Tasks',
        ExtraComponent: props => {
          return (
            <div className="d-flex m-1" style={{ justifyContent: 'space-around' }}>
              <div className="">
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <div className="modal-section text-center mb-4">
                      <button className="btn" onClick={onClick}>
                        Assign Pre-defined Task
                      </button>
                    </div>
                  )}
                  ModalContent={props => (
                    <ReduxAssignTaskModal
                      title={'Assign Pre-defined Task'}
                      isPreDefinedTask={true}
                      setRequestData={this.setRequestData}
                      {...props}
                    />
                    // <ReduxTasksModalContent
                    //   onAssignExistingTask={this.onAssignExistingTask}
                    //   {...props}
                    // />
                  )}
                />
              </div>
              <div className="">
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <div className="modal-section text-center mb-4">
                      <button className="btn" onClick={onClick}>
                        Assign Task
                      </button>
                    </div>
                  )}
                  ModalContent={props => (
                    <ReduxAssignTaskModal
                      title={'Assign New Task'}
                      isPreDefinedTask={false}
                      setRequestData={this.setRequestData}
                      {...props}
                    />
                    //  <ReduxNewTasksModalContent
                    //  onAssignNewTask={this.onAssignNewTask}
                    //  {...props} />
                  )}
                />
              </div>
            </div>
          );
        },
        Component: props => {
          const {
            DueDateString,
            DueDateForSort,
            RepeatFrequencyCode,
            RepeatDayOfWeek,
            TaskDueTime,
            TaskActivatedTime,
            TodoType,
            TodoTitle,
            AppTaskId,
            TaskUserId,
            UserId,
            FirstName,
            LastName,
            AssignedDate,
            AssignedDateEdit,
            DueDate,
            DueDateEdit,
            TaskDueTimeEdit,
            DueDateTimeString,
            FinishedDate,
            FinishedDateEdit,
            UnassignDate,
            UnassignDateEdit,
            TaskActivatedDate,
            TaskActivatedDateTimeString,
            TaskActivatedDateTimeSort,
            TaskActivatedDateEdit,
            TaskActivatedTimeEdit,
            DueDateFontColor,
            TaskStatus,
            IsLocation,
            Latitude,
            Longitude,
            IsVideoRequired,
            ListPriority,
            MediaType,
            MediaUrl,
            MediaGuid,
          } = props;
          const taskStatus = {
            '-1': { color: '#fef2f2', hideButtons: true, date: UnassignDate, name: 'Unassigned' },
            '0': {
              color: '#f8f8f8',
              hideButtons: false,
              date: DueDateString,
              dateFontColor: DueDateFontColor,
              name: 'Due',
            },
            '1': {
              color: '#f8f8f8',
              hideButtons: true,
              date: FinishedDate,
              name: 'Completed',
            },
          };
          return (
            <div
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <div
                className="col-md-12 text-right pt-2 pb-2 "
                style={{ color: 'white', background: '#26dbfe', borderRadius: '10px 10px 0px 0px' }}
              >
                <span className="due-date">
                  <em>
                    <i class="far fa-calendar-check"></i> Assigned:
                  </em>{' '}
                  {AssignedDate}
                </span>
              </div>
              <div
                className="task-box"
                style={{
                  padding: '10px 15px 10px 15px',
                  borderRadius: '0px 0px 10px 10px',
                  backgroundColor: taskStatus[TaskStatus].color,
                }}
              >
                <div className="row no-gutters w-100 justify-content-between align-items-center">
                  <div className="col-md-12">
                    <h6>{TodoTitle}</h6>
                  </div>
                  <div className="col-md-12">
                    {TaskActivatedDateTimeString && (
                      <div className="row">
                        <div className="col-12">
                          <em>
                            {' '}
                            <i class="fas fa-calendar-alt"></i> Trigger:{' '}
                          </em>{' '}
                          {TaskActivatedDateTimeString}
                        </div>
                        {/* <div className="col-6 text-right">
                        <em> <i class="far fa-clock"></i> Activated Time : </em> {TaskActivatedTime}
                      </div> */}
                      </div>
                    )}
                    {IsVideoRequired && (
                      <div className="row">
                        <div className="col-12">
                          <em>
                            {' '}
                            <i class="fas fa-video"></i> Video required for this task{' '}
                          </em>{' '}
                        </div>
                      </div>
                    )}
                    {!TaskActivatedDateTimeString && (
                      <div className="row">
                        <div
                          className="col-12 due-date "
                          style={{ color: taskStatus[TaskStatus].dateFontColor }}
                        >
                          <em>{taskStatus[TaskStatus].name} : </em> {DueDateTimeString}
                          <br />
                        </div>
                        {/* <div className="col-6 due-date text-right" style={{ color: taskStatus[TaskStatus].dateFontColor }}>
                        <em>Due Time : </em> {TaskDueTime}<br/>
                    </div> */}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 mt-4">
                    <div className="row">
                      <div className="col-6 text-left d-flex align-items-center">
                        <div className="btn-link mx-1" style={{ cursor: 'pointer' }}>
                          {MediaGuid && (
                            <div className="actions-button justify-content-end">
                              {' '}
                              {/* <span className="extraAction ">
                                <Tooltip
                                  placement="top"
                                  title="Check video"
                                  className="d-flex align-item-center justify-content-center"
                                >
                                  <img
                                    src={`images/timeline/locationAndVideo/video_icon.svg`}
                                    style={{ cursor: 'pointer', width: '100%', height: '15px' }}
                                  />
                                </Tooltip>
                              </span> */}
                              <CustomModal
                                ModalButton={({ onClick }) => (
                                  <Tooltip placement="top" title="Check Video">
                                    <span className="select-course extraAction" onClick={onClick}>
                                      <img
                                        src={`images/timeline/locationAndVideo/video_icon.svg`}
                                        style={{ cursor: 'pointer', width: '100%', height: '15px' }}
                                      />
                                    </span>
                                  </Tooltip>
                                )}
                                ModalContent={props => {
                                  return (
                                    <>
                                      <BreathalyzerTest MediaGuid={MediaGuid} />
                                    </>
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className="btn-link mx-1"
                          style={{ cursor: 'pointer' }}
                          // onClick={(item) => {
                          // this.loadNewMap(data.Latitude, data.Longitude);
                          //                 }}
                        >
                          {Latitude && Longitude && (
                            <a
                              href={`http://www.google.com/maps/place/${Latitude},${Longitude}`}
                              target="_blank"
                            >
                              <div className="actions-button justify-content-end">
                                {' '}
                                <span className="extraAction">
                                  <Tooltip placement="top" title="See Event on the Map">
                                    {' '}
                                    <img
                                      src={`images/timeline/locationAndVideo/location_icon.svg`}
                                      // className="ml-2"
                                      // title="See Event on the Map"
                                      style={{ cursor: 'pointer', width: '100%', height: '18px' }}
                                    />
                                  </Tooltip>
                                </span>
                              </div>
                            </a>
                          )}
                        </div>
                      </div>

                      <div className="col-6 text-right">
                        <div className="actions-button justify-content-end">
                          {!taskStatus[TaskStatus].hideButtons && (
                            <CustomModal
                              ModalButton={({ onClick }) => (
                                <Tooltip placement="top" title="Un-assign">
                                  <span className="delete-course" onClick={onClick}>
                                    <i className="fas fa-times" />
                                  </span>
                                </Tooltip>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div className="modal-content border-0">
                                  <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      &times;
                                    </button>
                                    <h4 className="modal-title col-12">
                                      Do you want to un-assign this task?
                                    </h4>
                                  </div>

                                  <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      type="button"
                                      className="btn black-btn"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn "
                                      onClick={() => {
                                        onOk();
                                        this.onTaskUnAssign(TaskUserId);
                                      }}
                                    >
                                      Ok
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          )}
                          {!taskStatus[TaskStatus].hideButtons && (
                            <CustomModal
                              ModalButton={({ onClick }) => (
                                <Tooltip placement="top" title="Complete">
                                  <span className="select-course" onClick={onClick}>
                                    <i className="select-icon" />
                                  </span>
                                </Tooltip>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div className="modal-content border-0">
                                  <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      &times;
                                    </button>
                                    <h4 className="modal-title col-12">
                                      Do you want to mark this task as complete?
                                    </h4>
                                  </div>

                                  <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      type="button"
                                      className="btn black-btn"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn "
                                      onClick={() => {
                                        onOk();
                                        this.onTaskComplete(TaskUserId);
                                      }}
                                    >
                                      Ok
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          )}

                          <CustomModal
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title="Edit">
                                <span className="select-course" onClick={onClick}>
                                  <img src="images/edit-profile-icon.svg" alt="" width="23" />
                                </span>
                              </Tooltip>
                            )}
                            ModalContent={props => {
                              return (
                                <>
                                  <ReduxAssignTaskModal
                                    setRequestData={this.setRequestData}
                                    title={'Edit due date and time'}
                                    initialValues={{
                                      taskId: AppTaskId,
                                      taskName: TodoTitle,
                                      taskDueDate: DueDateEdit,
                                      taskDueTime: TaskDueTimeEdit,
                                      taskTriggerDate: TaskActivatedDateEdit,
                                      taskTriggerTime: TaskActivatedTimeEdit,
                                      frequency: RepeatFrequencyCode,
                                      RepeatDayOfWeek: RepeatDayOfWeek,
                                      isVideoRequired: IsVideoRequired,
                                    }}
                                    TaskUserId={TaskUserId}
                                    isEdit={true}
                                    onAssignNewTask={this.onAssignNewTask}
                                    UserId={UserId}
                                    {...props}
                                  />
                                  {/* <ReduxNewTasksModalContent
                              isEdit={true}
                              onAssignNewTask={this.onAssignNewTask}
                              AppTaskId={AppTaskId}
                              TaskUserId={TaskUserId}
                              taskName={TodoTitle}
                              dueDate={DueDateEdit}
                              taskDueTime={TaskDueTimeEdit}
                              activeDate={TaskActivatedDateEdit}
                              activeTaskTime={TaskActivatedTimeEdit}
                              UserId={UserId}
                              RepeatFrequencyCode={RepeatFrequencyCode}
                              RepeatDayOfWeek={RepeatDayOfWeek}
                              {...props}
                            /> */}
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className=""></div>
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
            </div>
          );
        },
        Content: ActivitiesTasks || [],
      },
      {
        Name: 'Course',
        ExtraComponent: props => {
          return (
            <div>
              <CustomModal
                ModalButton={({ onClick }) => (
                  <div className="modal-section text-center mb-4">
                    <button className="btn" onClick={onClick}>
                      Assign Course
                    </button>
                  </div>
                )}
                ModalContent={props => (
                  <CoursesModalContent assignCourse={this.assignCourse} {...props} />
                )}
              />
            </div>
          );
        },
        Component: props => {
          const {
            CourseUserId,
            CourseId,
            UserId,
            EnrollDate,
            DueDate,
            DueDateString,
            CompletedDate,
            UnassignDate,
            DueDateForSort,
            DueDateFontColor,
            CourseName,
            CourseDescription,
            CourseStatus,
          } = props;
          const courseStatus = {
            '-1': {
              color: '#fef2f2',
              hideButtons: true,
              date: UnassignDate,
              name: 'Unassigned On',
            },
            '0': {
              color: '#f8f8f8',
              hideButtons: false,
              date: DueDateString,
              name: 'To be Finished',
              dateFontColor: DueDateFontColor,
            },
            '1': {
              color: 'rgb(239, 247, 254)',
              hideButtons: true,
              date: CompletedDate,
              name: 'Completed On',
            },
          };
          return (
            <div
              className="course-box"
              style={{ backgroundColor: courseStatus[CourseStatus].color }}
            >
              <div className="row no-gutters w-100 justify-content-between mb-2 align-items-center">
                <div className="course-info w-75">
                  <span className="course-name">{CourseName}</span> {CourseDescription}
                  <div className="row no-gutters w-100">
                    <div className="enroll-date course-date mb-1 col-12 col-md-6">
                      Enrolled on <em className="date">{EnrollDate}</em>
                    </div>
                    <div className="Finish-date course-date mb-1 col-12 col-md-6">
                      {courseStatus[CourseStatus].name}
                      <em
                        className="date"
                        style={{ color: courseStatus[CourseStatus].dateFontColor }}
                      >
                        {courseStatus[CourseStatus].date}
                      </em>
                    </div>
                  </div>
                </div>
                <div className="actions-button justify-content-xl-end">
                  {!courseStatus[CourseStatus].hideButtons && (
                    <>
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Un-assign">
                            <span className="delete-course" onClick={onClick}>
                              <i className="fas fa-times" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div className="modal-content border-0">
                            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 className="modal-title col-12">
                                Are you sure you want to unassign?
                              </h4>
                            </div>

                            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                className="btn black-btn"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn "
                                onClick={() => {
                                  onOk();
                                  this.onUnAssign(CourseUserId);
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                      />
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Complete">
                            <span className="select-course" onClick={onClick}>
                              <i className="select-icon" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div className="modal-content border-0">
                            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 className="modal-title col-12">
                                Are you sure you want to Complete?
                              </h4>
                            </div>

                            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                className="btn black-btn"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn "
                                onClick={() => {
                                  onOk();
                                  this.onMarkComplete(CourseUserId);
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                      />
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Edit">
                            <span className="select-course" onClick={onClick}>
                              <img src="images/edit-profile-icon.svg" alt="" width="23" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={props => (
                          <CoursesModalContent
                            isEdit={true}
                            assignCourse={this.assignCourse}
                            CourseId={CourseId}
                            DueDate={DueDate}
                            {...props}
                          />
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        },
        Content: ActivitiesCourse || [],
      },
      {
        Name: 'Small Group',
        ExtraComponent: props => {
          const { SmallGroupGuid, GroupName, GroupLeader, NextMeeting } = props;
          return (
            <div>
              <CommonLicenceScreens
                StatusData={this.state.SmallGroupStatusData}
                moduleName={'SmallGroup'}
                isSubscriberProfileScreen={true}
              />
            </div>
          );
        },
        Component: props => {
          const { SmallGroupGuid, GroupName, GroupLeader, NextMeeting } = props;
          return (
            <>
              {this.state.SmallGroupStatusData &&
                this.state.SmallGroupStatusData.LicenseStatusId != 1 &&
                this.state.SmallGroupStatusData.LicenseStatusId != 3 &&
                this.state.SmallGroupStatusData.LicenseStatusId != 5 &&
                this.state.SmallGroupStatusData.LicenseStatusId != 2 && (
                  <>
                    {' '}
                    <div
                      style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                    >
                      <div
                        className="task-box"
                        style={{
                          cursor: 'pointer',
                          backgroundColor: '#f8f8f8',
                        }}
                        onClick={() =>
                          this.props.history.push(
                            `/manage/small-group-form?smallGroupGuid=${SmallGroupGuid}`,
                          )
                        }
                      >
                        <div className="row no-gutters w-100 justify-content-between align-items-center">
                          <div className="col-12">
                            <div className="">
                              <Paragraph
                                ellipsis={{ rows: 1, expandable: false }}
                                textAlign="justify"
                                style={{ color: '#000000', fontSize: '17px', marginBottom: '2px' }}
                              >
                                {GroupName}
                              </Paragraph>
                              <div
                                className=""
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                              >
                                <div className="">
                                  <span className="due-date">
                                    <em>Leader </em> : {GroupLeader}
                                  </span>
                                </div>
                                <div className="">
                                  <span
                                    className="due-date"
                                    // style={{ color: surveyStatus[SurveyStatus].dateFontColor }}
                                  >
                                    <em>Next Meeting </em> : {NextMeeting}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </>
          );
        },
        Content: ActivitiesSmallGroup || [],
      },
      // {
      //   Name: 'Growth Plan',
      //   Component: props => {
      //     return (
      //       <div className="note-box">
      //         <div className="">
      //           <span>Growth Plan</span>
      //         </div>
      //       </div>
      //     );
      //   },
      //   Content: [{}],
      // },
    ];
    // List2 For Acticities
    const journalList = [
      {
        Name: 'My Notes',
        ExtraComponent: () => (
          <NotesExtraComponent
            addEntNote={this.addEntNote}
            FeatureFlags={this.props.FeatureFlags}
          />
        ),
        Component: props => {
          const { Note, NoteTypes, IsConfidential, DateEntered, EnteredBy, FeatureFlags } = props;
          return (
            <div className="note-box">
              <div className="row">
                <div className="col-12 col-md-8">
                  <h6 className="note-type">
                    <Paragraph
                      ellipsis={{ rows: 1, expandable: false }}
                      textAlign="justify"
                      className="mb-0"
                      style={{ color: '#000000', display: 'inline-block' }}
                    >
                      {NoteTypes}
                    </Paragraph>
                    {IsConfidential && (
                      <span className="alert alert-danger confidential-alert border-0 text-uppercase ml-2">
                        <i className="fas fa-lock" /> Confidential
                      </span>
                    )}
                  </h6>
                  <span className="note-author">by {EnteredBy}</span>
                </div>
                <div className="col-12 col-md-4">
                  <span className="note-date">{DateEntered}</span>
                </div>
                <div className="col-12 pt-2">
                  <p className="note-text mb-0">{Note}</p>
                </div>
              </div>
            </div>
          );
        },
        Content: NoteUser || [],
      },
      {
        Name: 'Journal Entries',
        ExtraComponent: props => <NotesFromUser UserGuid={this.getUserGuid()} {...this.props} />,
        noContent: true,
        shouldRender: false,
      },
    ];
    // List
    const list = [
      {
        Name: 'Location',
        ExtraComponent: props => (
          <>
            {ChatCoach && (
              <ConcernedPersonLocation
              // ChatMessages={ChatCoach || []}
              // UserGuid={this.getUserGuid()}
              />
            )}
          </>
        ),
        noContent: true,
        shouldRender: ChatCoach,
      },
      {
        Name: 'Chats',
        ExtraComponent: props => (
          <>
            {ChatCoach && <ChatBot ChatMessages={ChatCoach || []} UserGuid={this.getUserGuid()} />}
          </>
        ),
        noContent: true,
        shouldRender: ChatCoach,
      },
      {
        Name: 'Journal',
        ExtraComponent: () => {
          return (
            <div className="Activities">
              <ListRenderer
                list={journalList}
                activeKey={this.state.subTabKey1}
                getActiveKey={subTabKey1 => this.setState({ subTabKey1 })}
              />
            </div>
          );
        },
      },
      {
        Name: 'Video Call Logs',
        Component: props => {
          const { CallDate, Duration, StartEndTime, MeetingTitle, VMCallId } = props;
          return (
            <div className="">
              <CustomModal
                ModalButton={({ onClick }) => (
                  <div className="modal-section mb-4">
                    <div
                      className="video-log-box"
                      onClick={onClick}
                      // onClick={() => {
                      //   this.fnGetVideoUrl(VMCallId);
                      // }}
                    >
                      <div className="video-info">
                        <span className="title">{MeetingTitle} </span>{' '}
                        <div>
                          <span className="date">{CallDate} </span>{' '}
                          <span className="dur">Duration: </span>
                          <span className="time"> {Duration}</span>
                        </div>
                        <div>
                          <span className="dur">Start/End time: </span>
                          <span className="time"> {StartEndTime}</span>
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <img
                          src="images/vid-logo.png"
                          alt={MeetingTitle}
                          style={{ height: '27' }}
                        />
                        <span style={{ marginTop: '-18px' }}>Play session</span>
                      </div>
                    </div>
                  </div>
                )}
                ModalContent={props => (
                  <VideoModalContent
                    VMCallId={VMCallId}
                    Title={MeetingTitle}
                    CallDate={CallDate}
                    {...props}
                  />
                )}
              />
            </div>
            // <div
            //   className="video-log-box mb-4"
            //   onClick={() => {
            //     this.fnGetVideoUrl(VMCallId);
            //   }}
            // >
            //   <div className="video-info">
            //     <span className="title">{MeetingTitle} </span>{' '}
            //     <div>
            //       <span className="date">{CallDate} </span> <span className="dur">Duration: </span>
            //       <span className="time"> {Duration}</span>
            //     </div>
            //     <div>
            //       <span className="dur">Start/End time: </span>
            //       <span className="time"> {StartEndTime}</span>
            //     </div>
            //   </div>
            //   <div className="d-flex flex-column">
            //     <img src="images/vid-logo.svg" alt={MeetingTitle} style={{ height: '27' }} />
            //     <span style={{ marginTop: '-18px' }}>Play session</span>
            //   </div>
            // </div>
          );
        },
        Content: VideoCallLogs || [{}],
      },
      {
        Name: 'Activities',
        noContent: true,
        shouldRender:
          ActivitiesSurveyUser.length + ActivitiesTasks.length + ActivitiesCourse.length,
        ExtraComponent: () => {
          return (
            <div className="Activities">
              <ListRenderer
                list={list2}
                activeKey={this.state.subTabKey}
                getActiveKey={subTabKey => this.setState({ subTabKey })}
              />
            </div>
          );
        },
      },
    ];

    const surveyanswersdatacol = [
      { title: 'Question Number', dataIndex: 'QuestionNumber', key: 'QuestionNumber' },
      { title: 'Question', dataIndex: 'Question', key: 'Question' },
      { title: 'Answer', dataIndex: 'Answer', key: 'Answer' },
      {
        title: 'Responded Date Time',
        dataIndex: 'RespondedDateTime',
        key: 'RespondedDateTime',
      },
    ];

    return (
      <div>
        <PageTitle TitleText="Subscriber" />
        {/* <Loader loading={isFetchingDashboard || isFetchingChat} /> */}
        <Loader loading={isFetchingDashboard} />
        <div className="main students-page">
          <div className="container-fluid">
            <div className="row top-table-search mt-1 mb-1 align-items-center">
              <div className="col-12 col-md-4 col-lg-4">
                <a
                  className="back-link d-flex align-items-center"
                  onClick={() => this.props.history.goBack()}
                >
                  <img src="images/back-arrow.svg" /> Back
                </a>
              </div>
            </div>
            <div className="row">
              {/* NAME DETAIL */}
              <div className="col-12 mb-2 pt-2">
                <ProfileHeader showTimeLineBtn={this.props.location.pathname.split('/')[1]} />
              </div>
              {/* NAME DETAIL */}
              {/* SubscriptionService */}
              <div className="col-12 my-2">
                {false && ShowSubscriptionService && (
                  <ManageCounselorService UserGuid={this.getUserGuid()} />
                )}
              </div>
              {/* SubscriptionService */}

              {/* LEFT SIDE */}
              {BadgeId == 'F' ? (
                <div className="col-lg-6 col-xl-7 mb-4 students-performance-col">
                  <Timeline2 history={this.props.history} hideHeader={true} />
                </div>
              ) : (
                <>
                  <div className="col-lg-6 col-xl-7 mb-4 students-performance-col">
                    <div className="row">
                      {/* <div className="col-12  mb-3">
                        <EmotionalAndTrendGraph UserGuid={this.getUserGuid()} />
                      </div> */}
                      {this.props.AugmentedAIStatusData && (
                        <div className="col-12 p-0">
                          <CommonLicenceScreens
                            StatusData={this.props.AugmentedAIStatusData}
                            moduleName={'AugmentedAI'}
                          />
                        </div>
                      )}
                      {this.props.AugmentedAIStatusData &&
                        this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
                        this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
                        this.props.AugmentedAIStatusData.LicenseStatusId != 5 && (
                          <div className="col-12 p-0">
                            <AugmentedSummary UserGuid={this.getUserGuid()} isEnrolled={true} />
                          </div>
                        )}

                      {/* <div className="col-12">
                        <AugmentedSummary UserGuid={this.getUserGuid()} isEnrolled={true} />
                      </div> */}
                      <div className="row">
                        <div className="col-6 mb-3">
                          <div className="card border-0 p-3" style={{ height: '100%' }}>
                            <PerformanceCharts
                              FirstName={Name}
                              history={this.props.history}
                              performaceScores={SubscriberData}
                              UserGuid={this.getUserGuid()}
                              AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                              dispatch={this.props.dispatch}
                            />
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <div className="card border-0 p-3">
                            <RecoveryMeasureGraph
                              AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                              dispatch={this.props.dispatch}
                              UserGuid={this.getUserGuid()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3 p-0">
                        <div className="card border-0 ">
                          <div className="row card-body">
                            {/* <div className="col-12">
                              <AugmentedSummary UserGuid={this.getUserGuid()} isEnrolled={true} />
                            </div> */}
                            <div className="col-12  mb-3">
                              <EmotionalAndTrendGraph
                                UserGuid={this.getUserGuid()}
                                AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                              />
                            </div>
                            {/* <div className="col-12 mb-5">
                              <div className="journey-title">
                                <h5>Recovery Journey</h5>
                              </div>
                              {RecoveryJourney && RecoveryJourney.length > 0 ? (
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      <Collapse
                                        accordion
                                        bordered={false}
                                        defaultActiveKey={activePanel1 + 'blue'}
                                        className="site-collapse-custom-collapse"
                                      >
                                        {StudentOutcomeData &&
                                          StudentOutcomeData.map((item, index) => {
                                            activePanel1 = index;
                                            return (
                                              <Panel
                                                header={<PanelHeader headerData={item} />}
                                                key={activePanel1 + item.Color}
                                                className={`site-collapse-custom-panel-${item.Color}`}
                                                showArrow={false}
                                              >
                                                <div className="panel-text">
                                                  <ul>
                                                    {item.Question &&
                                                      item.Question.map(ques => (
                                                        <li>{ques.Question}</li>
                                                      ))}
                                                  </ul>
                                                </div>
                                              </Panel>
                                            );
                                          })}
                                      </Collapse>
                                    </div>
                                    <div className="col-12 text-right">
                                      {RecoveryJourney && RecoveryJourney.length > 2 && (
                                        <AntdModal
                                          modalWidth={700}
                                          ModalButton={({ onClick }) => (
                                            <button className="btn m-1" onClick={onClick}>
                                              See Full Detail
                                            </button>
                                          )}
                                          ModalContent={props => (
                                            <FullDetailModal
                                              RecoveryJourney={RecoveryJourney}
                                              {...props}
                                            />
                                          )}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="text-center">
                                    <h5>No Data</h5>
                                  </div>
                                </>
                              )}
                            </div> */}
                            <RecoveryJourneyComponent
                              AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                              StudentOutcome={RecoveryJourney}
                              StudentOutcomeData={StudentOutcomeData}
                              UserGuid={this.getUserGuid()}
                              psychosocialSurveys={this.props.psychosocialSurveys}
                            />
                            <div className="col-12 mb-2 mb-lg-1">
                              <h5 className="pt-4 font-weight-normal">System Observation</h5>
                            </div>
                            <div
                              className="col-12"
                              style={{ overflowY: 'auto', maxHeight: '650px' }}
                            >
                              {SystemObservation ? (
                                SystemObservation.map((item, index) => {
                                  const { Content, Heading, IsAccepted, ObservationId } = item;
                                  const style = { width: 140, height: 35, paddingTop: 5 };
                                  const status = {
                                    null: (
                                      <>
                                        <button
                                          type="button"
                                          className="btn"
                                          style={style}
                                          onClick={() => {
                                            this.handelObservation({
                                              isAccepted: true,
                                              observationId: ObservationId,
                                            });
                                          }}
                                        >
                                          Agree
                                        </button>
                                        &nbsp;
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary"
                                          data-dismiss="modal"
                                          style={style}
                                          onClick={() => {
                                            this.handelObservation({
                                              isAccepted: false,
                                              observationId: ObservationId,
                                            });
                                          }}
                                        >
                                          Disagree
                                        </button>{' '}
                                      </>
                                    ),
                                    true: (
                                      <a style={{ color: '#12c475', fontWeight: 700 }}>
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                          style={{ paddingRight: 5 }}
                                        />
                                        Agreed
                                      </a>
                                    ),
                                    false: (
                                      <a
                                        style={{
                                          fontWeight: '700',
                                          letterSpacing: '0.1px',
                                          color: '#ff3b3b',
                                        }}
                                      >
                                        <i
                                          className="fas fa-thumbs-down"
                                          style={{ paddingRight: 5 }}
                                        ></i>
                                        Disagreed
                                      </a>
                                    ),
                                  };
                                  const color = {
                                    null: '#f9f7fa',
                                    true: 'rgb(239, 247, 254)',
                                    false: 'rgb(254, 242, 242)',
                                  };
                                  return (
                                    <div
                                      style={{
                                        backgroundColor: color[IsAccepted],
                                        padding: 20,
                                        borderRadius: 15,
                                        marginBottom: 25,
                                      }}
                                    >
                                      <div style={{ fontWeight: 600 }}>{renderHTML(Heading)}</div>
                                      <div style={{ textAlign: 'justify', paddingTop: 10 }}>
                                        {renderHTML(Content)}
                                      </div>
                                      <br />
                                      {status[IsAccepted]}
                                    </div>
                                  );
                                })
                              ) : (
                                <p style={{ backgroundColor: '#f0f9ff', padding: '15px' }}>
                                  A System Observation cannot be generated for the client at this
                                  time. Please have the client complete additional surveys to
                                  generate an observation.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* TriggerMap Start */}
                      <div className="col-12 mb-3">
                        <div className="card border-0 ">
                          <div className="row card-body">
                            <div className="col-12 ">
                              <div className="row justify-content-between align-items-center mb-1 pt-4 ">
                                <div className="col-12 trigger-view">
                                  <h5 className="font-weight-normal text-capitalize">
                                    Trigger Map
                                  </h5>
                                  <div>
                                    <button
                                      onClick={this.handleClick}
                                      style={
                                        !this.state.isMapOn
                                          ? { padding: '8px 20px' }
                                          : { padding: '7px 20px' }
                                      }
                                      className={
                                        !this.state.isMapOn
                                          ? 'btn btn-trigger-map'
                                          : 'btn-trigger-map-off btn-trigger-map'
                                      }
                                    >
                                      Map View
                                    </button>
                                    <button
                                      onClick={this.handleClick}
                                      style={
                                        !this.state.isMapOn
                                          ? { padding: '7px 20px' }
                                          : { padding: '8px 20px' }
                                      }
                                      className={
                                        !this.state.isMapOn
                                          ? 'btn-trigger-list-off btn-trigger-list'
                                          : 'btn btn-trigger-list'
                                      }
                                    >
                                      List View
                                    </button>
                                    {/* <button className={"btn"+ this.state.isMapOn ? 'btn-trigger-map' : 'hidden' +"} onClick={this.handleClick}>
                        MapView
                        </button>&nbsp;
                        <button className={"btn"+ !this.state.isMapOn ? 'btn-trigger-map' : 'hidden' +"} onClick={this.handleClick}>ListView</button> */}
                                  </div>
                                </div>
                                <div className="col-12">
                                  <Loader2 loading={isFetchingMap} />
                                  {this.state.isToggleOn ? (
                                    <MapViewComponent
                                      TriggeredHistory={triggeredHistory}
                                      lastDays={this.lastDays}
                                      lastDaysBtnVisible={true}
                                      last30DaysBtnVisible={true}
                                      rangePickerVisible={true}
                                      datePickerVisible={false}
                                    />
                                  ) : (
                                    <ListViewComponent TriggeredHistory={triggeredHistory} />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* TriggerMap End */}

                      {/* TriggerMap Start */}
                      <div className="col-12 mb-3 d-none">
                        <div className="card border-0 d-none ">
                          <div className="row card-body">
                            <div className="col-12 ">
                              <div className="row justify-content-between align-items-center mb-1 pt-4 ">
                                <div className="col-12 trigger-view">
                                  <h5 className="font-weight-normal text-capitalize">
                                    Location History is Last Day
                                  </h5>
                                  <div>
                                    <button
                                      onClick={this.handleClick2}
                                      style={
                                        !this.state.isMapOn2
                                          ? { padding: '8px 20px' }
                                          : { padding: '7px 20px' }
                                      }
                                      className={
                                        !this.state.isMapOn2
                                          ? 'btn btn-trigger-map'
                                          : 'btn-trigger-map-off btn-trigger-map'
                                      }
                                    >
                                      Map View
                                    </button>
                                    <button
                                      onClick={this.handleClick2}
                                      style={
                                        !this.state.isMapOn2
                                          ? { padding: '7px 20px' }
                                          : { padding: '8px 20px' }
                                      }
                                      className={
                                        !this.state.isMapOn2
                                          ? 'btn-trigger-list-off btn-trigger-list'
                                          : 'btn btn-trigger-list'
                                      }
                                    >
                                      List View
                                    </button>
                                    {/* <button className={"btn"+ this.state.isMapOn2 ? 'btn-trigger-map' : 'hidden' +"} onClick={this.handleClick}>
                        MapView
                        </button>&nbsp;
                        <button className={"btn"+ !this.state.isMapOn2 ? 'btn-trigger-map' : 'hidden' +"} onClick={this.handleClick}>ListView</button> */}
                                  </div>
                                </div>
                                <div className="col-12">
                                  <Loader2 loading={this.props.isFetchingLocationHis} />
                                  {this.state.isToggleOn2 ? (
                                    <MapViewComponent
                                      TriggeredHistory={this.props.locationHistoryDataArray}
                                      lastDays={this.lastDays}
                                      datePickerVisible={true}
                                      getLocationHisData={this.getLocationHisData}
                                    />
                                  ) : (
                                    <ListViewComponent
                                      TriggeredHistory={this.props.locationHistoryDataArray}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* TriggerMap End */}
                    </div>
                  </div>
                </>
              )}

              {/* RIGHT SIDE */}

              <div className="col-lg-6 col-xl-5 mb-4 students-tab-col mt-4">
                <Mood
                  Mood={this.props.userMood}
                  UserGuid={this.getUserGuid()}
                  history={this.props.history}
                  AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                />
                {BadgeId == 'F' ? (
                  <></>
                ) : (
                  <>
                    {' '}
                    <CommonLicenceScreens
                      StatusData={this.props.BreathalyzerStatusData}
                      moduleName={'Breathalyzer'}
                    />
                    {this.props.BreathalyzerStatusData &&
                      this.props.BreathalyzerStatusData.LicenseStatusId != 1 &&
                      this.props.BreathalyzerStatusData.LicenseStatusId != 3 &&
                      this.props.BreathalyzerStatusData.LicenseStatusId != 5 &&
                      this.props.BreathalyzerStatusData.LicenseStatusId != 2 &&
                      ShowBreathalyzer && (
                        <div className="border-0 mb-3 breathalyzer-block">
                          <div
                            // class="breathalyzer-test-list breathalyzer-blur-block"
                            className={
                              IsBreathalyzerUser
                                ? 'breathalyzer-test-list'
                                : 'breathalyzer-test-list breathalyzer-blur-block'
                            }
                          >
                            <div className="test-card row mx-0 mb-3">
                              <div className="col-12 mb-2">
                                <div className="d-flex justify-content-between">
                                  <div className="patient">
                                    <div className="patient-name">
                                      <span>{'Breathalyzer'}</span>
                                      <div style={{ margin: '-5px 0px' }}>
                                        {BreathalyzerTestResult &&
                                          BreathalyzerTestResult.map(item => {
                                            return <span className={item.ResultClassBlock}></span>;
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="download">
                                    <span style={{ cursor: 'pointer' }} title="Push a test now">
                                      {this.props.isFetchingPushTest ? (
                                        <Spin indicator={antIcon} />
                                      ) : (
                                        <img
                                          src="images/icons/breathalyzer.svg"
                                          alt="download"
                                          width="35"
                                          onClick={() => {
                                            this.props.dispatch(
                                              breathalyzerPushATest({
                                                Json: JSON.stringify({
                                                  Breathalyzer: [
                                                    {
                                                      UserGuid: this.getUserGuid(),
                                                    },
                                                  ],
                                                }),
                                              }),
                                            );
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="last-test">
                                    <div className="d-flex">
                                      <span className="test-name">Last Test</span>
                                      <span
                                        className="view-schedule"
                                        onClick={() => {
                                          this.viewTimeline(0);
                                        }}
                                      >
                                        View Timeline
                                      </span>
                                    </div>
                                    <div className="patient-name d-flex align-items-center">
                                      <img src="images/icons/grey_calendar.svg" width="18" />
                                      <span className="test-date">{TestDate}</span>
                                      <div style={{ display: 'inline' }}>
                                        <span className={ResultClassBlock}></span>
                                        <span className={ResultClassText}>{ResultText}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="next-test">
                                    <div className="d-flex">
                                      <span className="test-name">Next Test</span>
                                      <span
                                        className="view-schedule"
                                        onClick={() => {
                                          this.viewTimeline(2);
                                        }}
                                      >
                                        View Schedule
                                      </span>
                                    </div>
                                    <div className="patient-name d-flex align-items-center">
                                      <img src="images/icons/grey_calendar.svg" width="18" />
                                      <span className="test-date">{NextTest}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {!IsBreathalyzerUser && (
                            <div class="breathalyzer-blur-block-btn">
                              {isFetchingEnable ? (
                                <Spin indicator={antIcon} />
                              ) : (
                                <button
                                  type="button"
                                  className="btn black-btn"
                                  onClick={() => {
                                    this.props.dispatch(
                                      breathalyzerEnable({
                                        Json: JSON.stringify({
                                          Breathalyzer: [
                                            {
                                              UserGuid: this.getUserGuid(),
                                            },
                                          ],
                                        }),
                                      }),
                                    );
                                  }}
                                >
                                  Enable breathalyzer
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                  </>
                )}

                <div className="card border-0">
                  <div className="card-body center-tabs">
                    <ListRenderer
                      list={list}
                      activeKey={this.state.activeKey}
                      getActiveKey={activeKey => this.setState({ activeKey })}
                    />
                    {/* <CustomModal2
                      isOpen={this.props.surveyanswersdata.length > 0}
                      yesAction={() => {
                        this.props.dispatch(resetSurveyAnswersGet());
                      }}
                      yesText="OK"
                      hideCancel={true}
                      changeMaxWidth="600px"
                    >
                      <div
                        className="col-lg-12 mb-3 student-profile-smiley-chart"
                        style={{ width: '1000%' }}
                      >
                        <h4 className="text-center">Survey Answers</h4>
                        <div className="mar-top-30">
                          <Table
                            dataSource={this.props.surveyanswersdata}
                            columns={surveyanswersdatacol}
                          />
                        </div>
                      </div>
                    </CustomModal2> */}
                  </div>
                </div>
              </div>
            </div>

            {BadgeId == 'F' ? (
              <></>
            ) : (
              <div className="row">
                {/* TriggerMap Start */}

                <div className="col-12 mb-3">
                  <div className="card border-0 ">
                    <div className="row card-body">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="font-weight-normal text-capitalize">
                              Location History{' '}
                            </h5>
                          </div>

                          <div className="col-md-6 text-right">
                            Select date to load location history :
                            <DatePickerAntd
                              date={this.state.selectedDate}
                              onChange={this.onDateFieldChange}
                              className="antdDate-modifier"
                              style={{ margin: '0 10px' }}
                              defaultValue={moment()}
                              format={'DD-MMM-YYYY'}
                              dateRender={current => {
                                const style = {};
                                let circleDate = availableDatesArray.map(item => {
                                  return item.RecordedDateTime;
                                });
                                if (circleDate.includes(current.format('YYYY-MM-DD'))) {
                                  style.border = '1px solid #1890ff';
                                  style.borderRadius = '50%';
                                  style.background = '#46b4fe';
                                  style.color = '#fff';
                                  style.width = '5px';
                                  style.height = '5px';
                                  style.position = 'absolute';
                                  style.right = '0px';
                                }
                                return (
                                  <div className="ant-picker-cell-inner">
                                    <div style={style}></div>

                                    {current.date()}
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="row justify-content-center align-items-center mb-1 pt-4 ">
                          <Loader2 loading={this.props.isFetchingLocationHis} />
                          {this.props.locationHistoryDataArray != null ? (
                            this.props.locationHistoryDataArray.length > 0 ? (
                              <>
                                <div className="col-md-7">
                                  <div style={{ height: '400px', width: '100%' }}>
                                    <GoogleMapReact
                                      bootstrapURLKeys={{
                                        key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo',
                                      }}
                                      center={{
                                        lat: this.state.lastDayLat,
                                        lng: this.state.lastDayLng,
                                      }}
                                      zoom={16}
                                    >
                                      <AnyReactComponent
                                        lat={this.state.lastDayLat}
                                        lng={this.state.lastDayLng}
                                        TriggerTypeImgUrl={this.state.triggerTypeImgUrl}
                                        IsPositive={this.state.isPositive}
                                        TriggerSign={this.state.triggerSign}
                                        showIsPositive={true}
                                      />
                                    </GoogleMapReact>
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '400px',
                                      overflow: 'scroll',
                                      // padding: "50px 0px",
                                      // margin: "100px 0px"
                                    }}
                                  >
                                    {this.props.locationHistoryDataArray &&
                                      this.props.locationHistoryDataArray.map((data, index) => {
                                        return (
                                          <Timeline>
                                            <TimelineEvent
                                              title={
                                                <div
                                                  className="row mt-2"
                                                  onClick={item => {
                                                    this.loadNewMap(data);
                                                  }}
                                                >
                                                  <div className="col-md-4">
                                                    <i class="far fa-address-card"></i> Place
                                                  </div>
                                                  <div className="col-md-4">
                                                    <i class="far fa-clock"></i> Start Time
                                                  </div>
                                                  <div className="col-md-4">
                                                    <i class="fas fa-history"></i> Duration
                                                  </div>
                                                  <div className="col-md-4 font-weight-bold">
                                                    {data.TriggerTypeValue}
                                                  </div>
                                                  <div className="col-md-4 font-weight-bold">
                                                    {data.Time}
                                                  </div>
                                                  <div className="col-md-4 font-weight-bold">
                                                    {data.Duration}
                                                  </div>
                                                </div>
                                              }
                                              // createdAt={data.title}
                                              container="card"
                                              collapsible
                                              style={{
                                                // border: '1px solid #777',
                                                borderRadius: 3,
                                              }}
                                              bubbleStyle={
                                                data.TriggerSign == 1
                                                  ? {
                                                      border: '2px solid #FCD400',
                                                      background: '#FAFFC1',
                                                    }
                                                  : data.TriggerSign == 0
                                                  ? {
                                                      border: '2px solid #FF3B3B',
                                                      background: '#FFD4D4',
                                                    }
                                                  : {
                                                      border: '2px solid #828282',
                                                      background: '#d9d9d9',
                                                    }
                                              }
                                              cardHeaderStyle={
                                                data.TriggerSign == 1
                                                  ? {
                                                      backgroundColor: '#FAFFC1',
                                                      color: '#000',
                                                    }
                                                  : data.TriggerSign == 0
                                                  ? {
                                                      backgroundColor: 'rgb(255, 212, 212)',
                                                      color: '#000',
                                                    }
                                                  : {
                                                      backgroundColor: '#d9d9d9',
                                                      color: '#000',
                                                    }
                                              }
                                              icon={
                                                <i className="material-icons md-18">
                                                  <img
                                                    className="url-icon"
                                                    src={data.TriggerTypeImgUrl}
                                                    style={{ width: '20px', height: '20px' }}
                                                    onClick={item => {
                                                      this.loadNewMap(data);
                                                    }}
                                                  />
                                                </i>
                                              }
                                            >
                                              <div className="row mt-2">
                                                <div className="col-md-8 mt-2">
                                                  {' '}
                                                  <i class="far fa-address-card"></i> Address
                                                </div>
                                                <div className="col-md-4 mt-2">
                                                  <i class="far fa-clock"></i> End Time
                                                </div>
                                                <div className="col-md-8 font-weight-bold">
                                                  {data.FullAddress}
                                                </div>
                                                <div className="col-md-4 font-weight-bold">
                                                  {data.EndTime}
                                                </div>
                                              </div>
                                              {/* <div
        className="btn-link mt-3 text-right"
        style={{ cursor: "pointer" }}
        onClick={(item) => {
        this.loadNewMap(data.Latitude, data.Longitude);
        }}>
        <i class="fas fa-map-marked-alt" style={{ cursor: "pointer" }}></i> Click to load location
      </div> */}
                                            </TimelineEvent>
                                          </Timeline>
                                        );
                                      })}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <p style={{ color: 'grey', textAlign: 'center' }}>
                                Location history not available for{' '}
                                {moment(this.state.selectedDate, 'DD/MM/YYYY').format(
                                  'DD-MMM-YYYY',
                                )}{' '}
                                , please select another date.
                              </p>
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* TriggerMap End */}
              </div>
            )}
          </div>
        </div>
        <Modal
          zIndex={100}
          // visible={true}
          visible={this.state.viewTimelineModal}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose={true}
        >
          <ReduxBreathalyzerTimeline
            initialValues={{
              startDate: BreathalyzerData.StartDate,
              endDate: BreathalyzerData.EndDate,
              repeatType: BreathalyzerData.RepeatFrequency,
              dayOfWeek:
                BreathalyzerData.DaysOfWeek &&
                BreathalyzerData.DaysOfWeek.split(',').map(item => {
                  return parseInt(item);
                }),
              isRandom: BreathalyzerData.IsRandom,
              startTime: BreathalyzerData.StartTimeSlotId,
              endTime: BreathalyzerData.EndTimeSlotId,
              testsPerDay: BreathalyzerData.TestsPerDay,
            }}
            BreathalyzerData={BreathalyzerData}
            activeTab={this.state.activeTab}
            onCancel={() => {
              this.setState({
                viewTimelineModal: false,
              });
            }}
            UserGuid={this.getUserGuid()}
            {...this.props}
          />
        </Modal>
        <CustomModal2
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            if (this.state.header == 'Survey is assigned Successfully') {
              this.state.requestData = false;
              this.props.dispatch(getAppSubscribersActivities({ UserGuid: this.getUserGuid() }));
            }
          }}
          yesText="OK"
          hideCancel={true}
        />
        <Drawer
          className="custom-drawer"
          title="Survey Response"
          placement="right"
          visible={this.state.isResultDrawerOpen}
          width={800}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          destroyOnClose={true}
          onClose={() => {
            this.props.dispatch(resetAppSubSurveyAnsGet());
            this.toggleDrawer();
          }}
        >
          <SurveyResponseDrawer
            // ClientId={this.getStudentId()}
            UserGuid={this.getUserGuid()}
            toggleDrawer={this.toggleDrawer}
            answerRecord={this.state.answerRecord}
            ColData={surveyanswersdatacol}
            RowData={this.props.surveyanswersdata}
          />
        </Drawer>
      </div>
    );
  }
}
// getAppSubscriberProfile, getAppSubscriberChat;
const mapStateToProps = ({
  get_app_subscribers_dashboard,
  get_app_subscribers_activities,
  get_app_subscribers_chat_coach,
  add_app_subscribers_survey_assign,
  delete_app_subscribers_task,
  add_app_subscribers_task,
  delete_app_subscribers_survey,
  add_app_subscribers_note,
  app_subscribers_complete_task,
  add_app_subscribers_course,
  app_subscribers_complete_course,
  delete_app_subscribers_course,
  app_subscribers_map_history,
  get_call_session_video,
  get_call_session_video_url,
  app_mark_system_observation,
  get_breathalyzer_schedule,
  breathalyzer_push_test,
  breathalyzer_enable,
  upsert_breathalyzer_schedule,
  location_day_history,
  user_feature_flag_get,
  app_sub_survey_ans_get,
}) => {
  const { isFetching: isFetchingDashboard, error, data } = get_app_subscribers_dashboard;
  const { isFetching: isFetchingChat } = get_app_subscribers_chat_coach;
  const { isFetching: isFetchingMap, data: mapData } = app_subscribers_map_history;
  const { isFetching: isFetchingEnable, data: breathalyzerEnableData } = breathalyzer_enable;
  const { data: fFData } = user_feature_flag_get;
  const {
    data: BreathalyzerScheduleData,
    isFetching: isFetchingBreathalyzer,
  } = get_breathalyzer_schedule;
  const { data: BreathalyzerPushTestData, isFetching: isFetchingPushTest } = breathalyzer_push_test;
  const { data: locationHistoryData, isFetching: isFetchingLocationHis } = location_day_history;
  var { data: appSubSurveryAnsData } = app_sub_survey_ans_get;

  let FeatureFlags = {};
  var SubscriberData = {};
  var ActivitiesSurveyUser = [],
    ActivitiesTasks = [],
    ActivitiesCourse = [],
    ActivitiesSmallGroup = [],
    ActivitiesGrowthPlan = [],
    triggeredHistory = [],
    isSurveyAssigned = false,
    isSurveyUnAssigned = false,
    isTaskUnAssigned = false,
    isTaskAssigned = false,
    isTaskCompleted = false,
    isNoteAdded = false,
    isCourseAdded = false,
    isCourseCompleted = false,
    isCourseDeleted = false;
  let isMarkObservation = false;
  var UserMisc = {};
  var NoteUser = [];
  var ChatCoach = [];
  var VideoCallLogs = [];
  let Breathalyzer = {};
  let BreathalyzerData = [];
  let isPushTest = false;
  let IsBreathalyzerUser = false;
  let IsBreathalyzerUserEnable = false;
  let ShowBreathalyzer = false;
  let ShowSubscriptionService = false;
  let isBreathalyzerInserted = false;
  let locationHistoryDataArray = null;
  let availableDatesArray = [];
  let BreathalyzerStatusData = {};
  let AugmentedAIStatusData = {};
  let SmallGroupStatusData = null;
  let surveyanswersdata = [];
  let userMood = [];
  let psychosocialSurveys;

  // let VideoCallUrl = '';

  // if (get_call_session_video_url && get_call_session_video_url.data) {
  //   VideoCallUrl = get_call_session_video_url.data.result;
  // }
  if (
    app_mark_system_observation &&
    app_mark_system_observation.data &&
    app_mark_system_observation.data.success
  ) {
    isMarkObservation = true;
  }
  if (get_app_subscribers_dashboard.data) {
    SubscriberData = data || {};
    UserMisc = data && data.UserMisc ? data.UserMisc[0] : {};
    NoteUser = (data && data.NoteUser) || [];
    IsBreathalyzerUser = data.IsBreathalyzerUser;
    ShowBreathalyzer = data.ShowBreathalyzer;
    ShowSubscriptionService = data.ShowSubscriptionService;
    userMood = data.Mood;
    psychosocialSurveys = data.psychosocialSurveys;
  }
  if (breathalyzerEnableData && breathalyzerEnableData.success) {
    IsBreathalyzerUserEnable = breathalyzerEnableData.success;
  }
  if (get_app_subscribers_dashboard.data && data.Breathalyzer) {
    Breathalyzer = data.Breathalyzer[0] || {};
  }
  if (get_call_session_video && get_call_session_video.data) {
    VideoCallLogs = get_call_session_video.data.Advisor || [];
  }
  if (get_app_subscribers_activities.data) {
    ActivitiesSurveyUser = get_app_subscribers_activities.data.User[0].SurveyUser || [];
    ActivitiesTasks = get_app_subscribers_activities.data.User[0].TaskUser || [];
    ActivitiesCourse = get_app_subscribers_activities.data.User[0].CourseUser || [];
    ActivitiesSmallGroup = get_app_subscribers_activities.data.User[0].SmallGroup || [];
  }
  if (get_app_subscribers_chat_coach.data) {
    ChatCoach = get_app_subscribers_chat_coach.data.ChatCoach || [];
  }
  if (add_app_subscribers_survey_assign && add_app_subscribers_survey_assign.data) {
    isSurveyAssigned = add_app_subscribers_survey_assign.data.success;
  }
  if (delete_app_subscribers_task && delete_app_subscribers_task.data) {
    isTaskUnAssigned = delete_app_subscribers_task.data.success;
  }
  if (add_app_subscribers_task && add_app_subscribers_task.data) {
    isTaskAssigned = add_app_subscribers_task.data.success;
  }
  if (delete_app_subscribers_survey && delete_app_subscribers_survey.data) {
    isSurveyUnAssigned = delete_app_subscribers_survey.data.success;
  }
  if (app_subscribers_complete_task && app_subscribers_complete_task.data) {
    isTaskCompleted = app_subscribers_complete_task.data.success;
  }
  if (add_app_subscribers_note && add_app_subscribers_note.data) {
    isNoteAdded = add_app_subscribers_note.data.success;
  }
  if (add_app_subscribers_course && add_app_subscribers_course.data) {
    isCourseAdded = add_app_subscribers_course.data.success;
  }
  if (app_subscribers_complete_course && app_subscribers_complete_course.data) {
    isCourseCompleted = app_subscribers_complete_course.data.success;
  }
  if (delete_app_subscribers_course && delete_app_subscribers_course.data) {
    isCourseDeleted = delete_app_subscribers_course.data.success;
  }
  if (app_subscribers_map_history && app_subscribers_map_history.data) {
    triggeredHistory = app_subscribers_map_history.data.TriggerMap || [];
  }
  if (BreathalyzerScheduleData && BreathalyzerScheduleData.BreathalyzerSchedule) {
    BreathalyzerData = BreathalyzerScheduleData.BreathalyzerSchedule[0];
  }
  if (BreathalyzerPushTestData && BreathalyzerPushTestData.success) {
    isPushTest = BreathalyzerPushTestData.success;
  }

  if (upsert_breathalyzer_schedule && upsert_breathalyzer_schedule.data) {
    isBreathalyzerInserted = upsert_breathalyzer_schedule.data.success;
  }
  if (location_day_history && location_day_history.data) {
    locationHistoryDataArray =
      (location_day_history.data && location_day_history.data[0].TriggerMap) || [];
    availableDatesArray =
      (location_day_history.data && location_day_history.data[0].DateMarked) || [];
  }
  if (appSubSurveryAnsData) {
    surveyanswersdata = Object.values(appSubSurveryAnsData);
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
    BreathalyzerStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'Breathalyzer')[0]) ||
      {};
    SmallGroupStatusData =
      fFData.FeatureFlag &&
      fFData.FeatureFlag[0] &&
      fFData.FeatureFlag[0].License &&
      fFData.FeatureFlag[0].License.length > 0 &&
      fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'SmallGroup')[0];
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }

  return {
    UserMisc,
    SubscriberData,
    ChatCoach,
    isFetchingDashboard,
    isFetchingChat,
    isFetchingMap,
    NoteUser,
    ActivitiesSurveyUser,
    ActivitiesTasks,
    ActivitiesCourse,
    ActivitiesSmallGroup,
    isSurveyAssigned,
    isTaskUnAssigned,
    isTaskAssigned,
    isSurveyUnAssigned,
    isTaskCompleted,
    isNoteAdded,
    isCourseAdded,
    isCourseCompleted,
    isCourseDeleted,
    triggeredHistory,
    VideoCallLogs,
    isMarkObservation,
    mapData,
    Breathalyzer,
    BreathalyzerData,
    isFetchingPushTest,
    isPushTest,
    isFetchingBreathalyzer,
    IsBreathalyzerUser,
    ShowBreathalyzer,
    ShowSubscriptionService,
    isFetchingEnable,
    IsBreathalyzerUserEnable,
    isBreathalyzerInserted,
    locationHistoryDataArray,
    isFetchingLocationHis,
    availableDatesArray,
    FeatureFlags,
    BreathalyzerStatusData,
    SmallGroupStatusData,
    surveyanswersdata,
    AugmentedAIStatusData,
    userMood,
    psychosocialSurveys,
    // surveyanswersdata:
    //   (app_sub_survey_ans_get.data && Object.values(app_sub_survey_ans_get.data)) || [],
    // VideoCallUrl,
  };
};
export default connect(mapStateToProps)(SubscriberProfile);
