import React, { Component } from 'react';
import { Button } from 'antd';
import user from '../images/blank-profile.png';
import { connect } from 'react-redux';
import moment from 'moment';
class ChatBot extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { UserGuid, LastUpdatedDateTime, ChatMessages } = this.props;
    const chatStatus = { '1': 'fa-hourglass', '2': 'fa-check-double', '3': 'fa-check' };
    return (
      <div className="chat-container">
        <div style={{ overflowY: 'scroll', height: '750px' }}>
          {ChatMessages &&
            ChatMessages.map((item, index) => {
              return (
                <>
                  {item.FromUserGuid == UserGuid && (
                    <>
                      <div className="chatBox c-reply">
                        <div className="chat-title">
                          <img alt="img" src={user} />
                          <div>
                            <span className="chat-name">{item.FromName}</span>
                            <span className="chat-time">
                              {/* {moment(item.ChatDate).format('ll hh:mm A')} */}
                              {typeof item.ChatDate == 'string'
                                ? item.ChatDate
                                : moment(item.ChatDate).format('ll hh:mm A')}
                            </span>
                          </div>
                        </div>
                        <div className="chat-text">
                          <span>{item.Msg}</span>
                        </div>
                        {/* DateDelivered ,DateSeen,ParentChatGuid,ChatStatus */}
                        <div className="chat-status">
                          {}
                          <i class={`fas ${chatStatus[item.ChatStatus]}`} />
                        </div>
                      </div>
                    </>
                  )}
                  {item.FromUserGuid != UserGuid && (
                    <>
                      <div className="chatBox c-comment">
                        <div className="chat-title">
                          <img alt="img" src={user} />
                          <div>
                            <span className="chat-name">{item.FromName}</span>
                            <span className="chat-time">
                              {/* {moment(item.ChatDate).format('ll hh:mm A')} */}
                              {typeof item.ChatDate == 'string'
                                ? item.ChatDate
                                : moment(item.ChatDate).format('ll hh:mm A')}
                            </span>
                          </div>
                        </div>
                        <div className="chat-text" style={{ color: 'rgb(71, 71, 71)' }}>
                          <span>{item.Msg}</span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              );
            })}
        </div>
        {false && (
          <div className="chat-form">
            <div className="form-group" style={{ flexGrow: '1', textAlign: 'center' }}>
              <textArea
                // placeholder="Type here..."
                className="form-control"
                value={'value'}
                rows="3"
                key="Type here"
              />
            </div>
            <div className="form-group" style={{ width: '50px', textAlign: 'center' }}>
              {/* <Button className="" shape="circle-outline" htmlType="submit" type="primary">
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </Button> */}
              <Button
                type="primary"
                shape="circle"
                size="large"
                style={{ backgroundColor: 'rgb(0, 121, 253)', borderColor: '#91d5ff' }}
              >
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ChatBot;
