import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import BundleServiceCategory from './bundleServiceCategory';

class ContractBundles extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { smartContractArray } = this.props;
    const columns = [
      {
        title: 'Bundle Name',
        dataIndex: 'ServiceCodeBundleName',
        key: 'ServiceCodeBundleName',
        sorter: (a, b) => isComparer(a.ServiceCodeBundleName, b.ServiceCodeBundleName),
      },
      {
        title: 'Duration',
        dataIndex: 'DurationWithFrequency',
        key: 'DurationWithFrequency',
        sorter: (a, b) => isComparer(a.DurationWithFrequency, b.DurationWithFrequency),
      },
      {
        title: 'Total Services',
        dataIndex: 'TotalServices',
        key: 'TotalServices',
        sorter: (a, b) => isComparer(a.TotalServices, b.TotalServices),
      },
    ];

    return (
      <div class="row">
        <div class="col-12 mb-5">
          <div class="card border-0 ">
            <div class="row card-body">
              <div class="col-12">
                <div className="row-hover-enabled">
                  <Table
                    columns={columns}
                    expandable={{
                      //   expandedRowRender: BundleServiceCategory,
                      expandedRowRender: record => (
                        <BundleServiceCategory
                          bundleRecord={record}
                          actionAllowed={this.props.actionAllowed}
                          getServiceCodeDetails={this.props.getServiceCodeDetails}
                          isReadOnly={this.props.isReadOnly}
                        />
                      ),
                      expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                          <img
                            src="images/icons/expand_grid.svg"
                            onClick={e => {
                              onExpand(record, e);
                              e.stopPropagation();
                            }}
                          />
                        ) : (
                          <img
                            src="images/icons/collapse_grid.svg"
                            onClick={e => {
                              onExpand(record, e);
                              e.stopPropagation();
                            }}
                          />
                        ),
                    }}
                    dataSource={smartContractArray}
                    size={'middle'}
                    pagination={false}
                    className="nested-tables"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContractBundles;
