import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
// import Loader, { Loader2 } from '../../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
} from '../../../../../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class EligibilityForm extends Component {
  constructor(props) {
    super(props);
    const { StartDate, EndDate, InsurancePriorityId, InsurancePriority } = props.recordValues || {};
    this.state = {
      StartDate:
        props.viewModalType === 'update' ? (StartDate == null ? null : new Date(StartDate)) : null,
      EndDate:
        props.viewModalType === 'update' ? (EndDate == null ? null : new Date(EndDate)) : null,
      InsurancePriorityId: props.viewModalType === 'update' ? InsurancePriorityId : null,
    };
  }
  handlePriorityChange = InsurancePriorityId => {
    this.setState({ InsurancePriorityId });
  };
  handleChange = (key, date) => {
    this.setState({
      [key]: date,
      endDateError: '',
      startDateError: '',
    });
  };
  render() {
    const {
      onSaveClick,
      disable,
      onOk,
      onCancel,
      InsurancePriority,
      viewModalType,
      recordValues,
    } = this.props;
    const { StartDate, EndDate, InsurancePriorityId } = this.state;

    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
        {!this.state.StartDate && <span style={{ color: 'red' }}>{this.state.startDateError}</span>}
      </>
    );
    const ExampleCustomInput2 = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={() => {
            onClick();
          }}
        />
        <label class="placeholder-label">End date</label>
        {!this.state.EndDate && <span style={{ color: 'red' }}>{this.state.endDateError}</span>}
      </>
    );

    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">
            {viewModalType === 'update' ? 'Manage' : 'Add'} Eligibility Dates
          </h4>
          {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
        </div>
        <div class="modal-body rcm-panel enrollment-form">
          <div class="row mb-4">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="form-group">
                  <div style={{ marginTop: '-12px' }}>
                    <DatePicker
                      selected={StartDate}
                      onChange={date => this.handleChange('StartDate', date)}
                      className="form-control datepicker"
                      customInput={<ExampleCustomInput />}
                    />
                    <i class="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="form-group">
                  <div style={{ marginTop: '-12px' }}>
                    <DatePicker
                      selected={EndDate}
                      onChange={date => this.handleChange('EndDate', date)}
                      className="form-control datepicker"
                      customInput={<ExampleCustomInput2 />}
                    />
                    <i class="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <ReduxSelect
                  options={InsurancePriority}
                  onChange={this.handlePriorityChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={InsurancePriorityId}
                  placeholder="Insurance Priority"
                />
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 text-center">
              <input
                type="button"
                class="btn px-5"
                value={viewModalType === 'update' ? 'Update' : 'Save'}
                onClick={() => {
                  if (!StartDate && !EndDate) {
                    if (!StartDate) {
                      this.setState({
                        startDateError: 'Start Date or End Date Required',
                      });
                    }
                    if (!EndDate)
                      this.setState({
                        endDateError: 'End Date or Start Date Required',
                      });
                  } else {
                    onSaveClick(
                      StartDate,
                      EndDate,
                      InsurancePriorityId,
                      viewModalType,
                      recordValues,
                    );
                    onOk();
                    this.setState({
                      endDateError: '',
                      startDateError: '',
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EligibilityForm;
