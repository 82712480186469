import React from 'react';
import 'rc-time-picker/assets/index.css';
// import { TimePicker } from 'antd';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

const AntdTimePicker = ({
  value,
  onChange,
  label,
  checkedChildren,
  unCheckedChildren,
  disabled,
  meta,
  minuteStep,
  // defaultValue,
}) => {
  const { touched, error, warning, active } = meta || {};
  return (
    <div className={disabled ? 'timePickerDisable' : 'timePickerActive'}>
      <label
        class="placeholder-label datepicker-label"
        style={{
          zIndex: 1,
          fontSize: !!disabled || value || active ? '11px' : '16px',
          top: !!disabled || value || active ? '-5px' : '15px',
        }}
      >
        {label}
      </label>
      <TimePicker
        use12Hours
        format="h:mm a"
        onChange={onChange}
        // defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
        className={`form-control`}
        bordered={false}
        placeholder={false}
        //below default value works
        // defaultValue={moment('00:00:00', 'HH:mm:ss')}
        // value={defaultValue || moment('00:00:00', 'HH:mm:ss')}
        value={moment(value, 'HH:mm:ss')}
        disabled={disabled}
        minuteStep={minuteStep}
        showSecond={false}
      />
    </div>
  );
};

const ReduxTimePicker = ({
  input,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  tabIndex,
  fieldName,
  label,
  minuteStep,
}) => {
  var { touched, error, warning } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value || '00:00:00';
    onChange = input.onChange;
    onBlur = input.onBlur;
  }
  return (
    <div>
      <AntdTimePicker
        {...input}
        // className={`d-flex align-items-center ${className}`}
        value={value}
        checked={value}
        label={label}
        name={name}
        id={id}
        minuteStep={minuteStep}
        // className={className}
        disabled={!!disabled}
        // defaultValue={moment(value, 'HH:mm:ss')}
      />
      {/* {touched &&
        ((error && <span className="required-color-red">{error}</span>) ||
          (warning && <span className="warning-color">{warning}</span>))} */}
      {touched &&
        ((error && (
          <span className="error-text">
            {fieldName} {error}
          </span>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

export default ReduxTimePicker;
