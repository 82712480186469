import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { BGContent, BGContentInternal } from '../commonSection';
import { startFreeLicense, userFeatureFlagGet } from '../../../../store/actions';
const labels = [
  'Active/Inactive Clients',
  'Referal Sources',
  'Time In Treatment',
  'Demographic Profile',
  'Mapping',
  'Score Improvement',
  'SP Behavioral Ratings',
  'Standard Cost of Care',
  'Service Units Delivered',
  'Trigger Location Activity',
];

const FamilyProgram = props => {
  const { StatusData, isSupportGroup, dispatch, isLicenseStart } = props;

  useEffect(() => {
    if (isLicenseStart) {
      dispatch(userFeatureFlagGet({}));
    }
  }, [isLicenseStart]);

  const handleStartTrial = () => {
    let moduleData = {
      License: [{ ModuleId: StatusData.ModuleId, IsFreeLicense: true }],
    };
    dispatch(startFreeLicense({ JsonInput: JSON.stringify(moduleData) }));
  };

  return (
    <>
      {!isSupportGroup && (
        <div
          className="row bg-white p-4 m-1"
          style={{
            borderRadius: '12px',
            backgroundImage: `url("images/license/bg_white.svg")`,
            backgroundPosition: 'upset',
          }}
        >
          <div className="col-12 h5">Family Program</div>
          <div className="col-12">
            <div className="row">
              {' '}
              <div className="col-6">
                Family Program Feature License is a great way to keep loved ones connected and
                informed about their health. It allows patients to share information with concerned
                family members, who can then provide support and assistance as needed. This license
                is a valuable tool for ensuring that everyone stays healthy and happy.
              </div>
              <div className="col-2"></div>
              <div className="col-4">
                <div className="d-flex align-item-center justify-content-center">
                  <button className="btn" onClick={handleStartTrial} style={{ cursor: 'pointer' }}>
                    Start your 14-day trial
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isSupportGroup && (
        <BGContent
          moduleName={'Family Program'}
          backGroundImage={`images/license/familyBG.svg`}
          moduleLogoImage={`images/license/fanilyProgram.svg`}
          labelTitle={' What you will get:'}
          labels={labels}
          tagLine={
            'Family Program Feature License is a great way to keep loved ones connected and informed about their health.'
          }
          trialButtonText={' Start your 14-day trial'}
          handleStartTrial={handleStartTrial}
          buyLinkText={'Purchase Family Program License'}
        />
      )}
      {isSupportGroup && (
        <BGContentInternal
          moduleName={'Family Program'}
          backGroundImage={`images/license/supportGroupBG.png`}
          moduleLogoImage={`images/license/fanilyProgram.svg`}
          labelTitle={' What you will get:'}
          labels={labels}
          showLabels={true}
          tagLine={
            'Family Program Feature License is a great way to keep loved ones connected and informed about their health.'
          }
          trialButtonText={' Start your 14-day trial'}
          buyLinkText={'Purchase Family Program License'}
          extraclassName={'trialNotStarted_SupportGroup'}
          handleStartTrial={handleStartTrial}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { user_feature_flag_get, start_free_license } = state;
  const { data, isFetching, error } = start_free_license;

  let isLicenseStart = false;

  if (data && data.success) {
    isLicenseStart = data.success;
  }
  return {
    isLicenseStart,
  };
};

export default connect(mapStateToProps)(FamilyProgram);
