import React, { useState, useEffect } from 'react';
import { submit, formValueSelector, change } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { Button, Drawer } from 'antd';
import AntdModal from '../../../../../../Components/CustomModal';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
} from '../../../../../../component/custom-redux-components';
import NewCostSummary from '../calCostSummary';
import ReduxCreateContracForm from './createContracForm';
import {
  smartContractUpsert,
  smartContractUpdate,
  listSmartCombos,
} from '../../../../../../store/actions';
import CloseIcon from '../../../../../../component/closeIcon';
import { currencyFormatter, numberFormat } from '../../../../../../util/formatter';
const CreateContract = props => {
  const {
    goalLibrary,
    serviceCodeBundle,
    existingRows,
    handleDeleteContract,
    contractType,
    setContractType,
    enableCreateButton,
    finalSubmit,
    setFinalSubmit,
    setEnableCreateButton,
    centerServiceTypeCenter,
  } = props;

  const { SmartContractId, Status, IsContractCreated } = props.smartContractData || {};

  // const [bundle, setBundle] = useState([]);
  const [open, setOpen] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [fieldChanged, setFieldChanged] = useState(null);
  const [serviceCodeInfo, setServiceCodeInfo] = useState([{}]);
  const [indexOfNetMarginChange, setIndexOfNetMarginChange] = useState(null);
  const [currentServiceTypeIndex, setCurrentServiceTypeIndex] = useState(null);
  const [lastExistingRows, setLastExistingRows] = useState([]);
  const [stateInitialValues, setStateInitialValues] = useState({});

  const dispatch = useDispatch();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const reGenServiceCode = existingRows => {
    let temp = existingRows.map((bundleItem, goalItemIndex) => {
      let updatedBundles =
        bundleItem &&
        bundleItem.Bundles &&
        bundleItem.Bundles.map((serviceTypeItem, bundleItemIndex) => {
          let updatedServiceTypes =
            serviceTypeItem &&
            serviceTypeItem.ServiceType &&
            serviceTypeItem.ServiceType.map((buildServiceBundleItem, serviceTypeItemIndex) => {
              const { BuildServiceBundle } = buildServiceBundleItem || {};

              if (BuildServiceBundle) {
                let newArray = BuildServiceBundle.map(arrayItem => {
                  if (arrayItem) {
                    const { StandardCost, NetMargin } = arrayItem;
                    let tempNetMarginCost = (StandardCost * NetMargin) / 100;
                    let tempTotalServiceCode = tempNetMarginCost + StandardCost;

                    return {
                      ...arrayItem,
                      NetMargin: NetMargin,
                      NetMarginCost: tempNetMarginCost.toFixed(2),
                      NetAmount: tempNetMarginCost.toFixed(2),
                      TotalServiceCost: tempTotalServiceCode.toFixed(2),
                      BillingRate: tempTotalServiceCode.toFixed(2),
                    };
                  }
                  return null; // Return a placeholder value to avoid undefined in newArray
                });

                return {
                  ...buildServiceBundleItem,
                  BuildServiceBundle: newArray,
                };
              } else {
                return buildServiceBundleItem; // No changes needed if BuildServiceBundle is missing
              }
            });

          dispatch(
            change(
              'contractForm',
              `Goal[${goalItemIndex}]Bundles[${bundleItemIndex}]ServiceType`,
              updatedServiceTypes,
            ),
          );

          return {
            ...serviceTypeItem,
            ServiceType: updatedServiceTypes,
          };
        });

      return {
        ...bundleItem,
        Bundles: updatedBundles,
      };
    });
    return temp;
  };

  //check this same funtion to remove in future from here because we can import from parent compare each key.
  const setGoalArrayInitialData = goal => {
    return goal.map(goalItem => {
      const {
        Bundle,
        Description,
        GoalLibraryId,
        GoalTimePeriod,
        GoalType,
        Milestone,
        Note,
        SmartContractGoalId,
        ThresholdValue,
        TimePeriod,
        TimePeriodId,
        key,
        Duration,
      } = goalItem;
      return {
        SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
        GoalLibraryId: GoalLibraryId ? GoalLibraryId : null,
        Duration: Duration,
        Bundles:
          Bundle &&
          Bundle.map(bundleItem => {
            const {
              BillingRate,
              CategoriesCount,
              DaysInTreatment,
              EndDate,
              NetAmount,
              NetMargin,
              OverHeadPercentage,
              Program,
              ServiceCodeBundleId,
              ServiceCodeBundleName,
              Services,
              SmartContractId,
              SmartContractServiceCodeBundleId,
              StartDate,
              TotalCostOfCare,
              TotalOverHead,
              key,
            } = bundleItem;
            return {
              SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
              SmartContractServiceCodeBundleId: SmartContractServiceCodeBundleId
                ? SmartContractServiceCodeBundleId
                : null,
              ServiceCodeBundleId: ServiceCodeBundleId ? ServiceCodeBundleId : null,
              ServiceCodeBundleName: ServiceCodeBundleName ? ServiceCodeBundleName : null,
              DaysInTreatment:DaysInTreatment?DaysInTreatment:null,
              ServiceType:
                Services &&
                Services.map(ServiceTypeItem => {
                  const { CenterServiceTypeCenterId, ServiceCode, Services } = ServiceTypeItem;
                  return {
                    ProgramId: CenterServiceTypeCenterId,
                    BuildServiceBundle:
                      ServiceCode &&
                      ServiceCode.map(serviceCodeItem => {
                        const {
                          CodeId,
                          ServiceCodeId,
                          ServiceCodeDescription,
                          NoOfService,
                          LaberCost,
                          ConsumableCost,
                          OverHeadCost,
                          StandardCost,
                          NetMargin,
                          BillingRate,
                          NetAmount,
                          OverHeadCostPercentage,
                          BundleServiceCodeId,
                          UnitOfMeasurement,
                          ServiceCodeConsumable,
                          ServiceCodeLaberCost,
                          PractitionerId,
                          ClientInsuranceId,
                          CodeName,
                          IsActionAllowed,
                          IsRequestPending,
                          IsServiceCompleted,
                          ServiceDelivedDate,
                          ServiceStatus,
                          SmartContractServiceBundleServiceCodeId,
                          StatusId,
                        } = serviceCodeItem;
                        return {
                          CodeId,
                          ServiceCodeId,
                          ServiceCodeDescription,
                          NoOfServices: NoOfService,
                          LaberCost,
                          ConsumableCost,
                          OverHeadCost,
                          StandardCost,
                          NetMargin,
                          BillingRate,
                          NetAmount,
                          OverHeadCostPercentage,
                          BundleServiceCodeId,
                          UnitOfMeasurement,
                          ServiceCodeConsumable,
                          ServiceCodeLaberCost,
                          PractitionerId,
                          ClientInsuranceId,
                          CodeName,
                          IsActionAllowed,
                          IsRequestPending,
                          IsServiceCompleted,
                          ServiceDelivedDate,
                          ServiceStatus,
                          SmartContractServiceBundleServiceCodeId,
                          StatusId,
                        };
                      }),
                  };
                }),
            };
          }),
        Milestone: Milestone,
      };
    });
  };

  useEffect(() => {
    if (props.smartContractData && props.smartContractData.SmartContractId) {
      const {
        SmartContractId,
        ClientId,
        PaymentContractTypeId,
        ContractName,
        StartDate,
        EndDate,
        TotalOverHead,
        TotalCostOfCare,
        TotalNetMargin,
        TotalBillingRate,
        TotalNetMarginAmount,
        PaymentStatusId,
        Goal,
        TermsCondition,
      } = props.smartContractData;

      const { Title, TermsCategory, Condition, TermsConditionId, TermId } =
        (TermsCondition && TermsCondition[0]) || {};

      let tempInitialValues = {
        SmartContractId,
        ClientId,
        PaymentContractTypeId,
        ContractName,
        StartDate,
        EndDate,
        TotalOverHead,
        TotalCostOfCare,
        TotalNetMargin,
        TotalBillingRate,
        TotalNetMarginAmount,
        PaymentStatusId,
        Goal: Goal ? setGoalArrayInitialData(Goal) : null,
        TermsConditionId,
        TermId,
        Title,
        Terms: TermsCategory,
        Conditions: Condition,
        TermsConditionId,
      };
      // setStateInitialValues(tempInitialValues);
      // setBundle(props.smartContractData.Bundle);
      // setContractType(props.smartContractData.PaymentContractTypeId);
    }
  }, [props.smartContractData]);

  useEffect(() => {
    dispatch(
      listSmartCombos({
        comboTypes:
          'UnitOfMeasurement,CenterProgramType,ServiceCodeBundle,SmartContractMeasure,GoalLibrary,GoalTimePeriod,CenterServiceTypeCenter',
        json: JSON.stringify({
          Combo: [
            {
              ClientId: props.clientId,
            },
          ],
        }),
      }),
    );
  }, []);

  useEffect(() => {
    if (
      existingRows &&
      existingRows[0] != undefined &&
      JSON.stringify(existingRows) != JSON.stringify(lastExistingRows)
    ) {
      let isExistingDefind =
        existingRows &&
        existingRows.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });

      let reGenServiceCodeArray;
      if (fieldChanged == 'ProgramId' || fieldChanged == 'ServiceCodeId' || fieldChanged == null) {
        reGenServiceCodeArray = existingRows;
      } else {
        reGenServiceCodeArray = reGenServiceCode(existingRows);
      }

      if (isExistingDefind) {
        let xyz = [];

        reGenServiceCodeArray &&
          reGenServiceCodeArray.map(item => {
            if (item && item.Bundles) {
              xyz = [...xyz, ...item.Bundles];
            }
          });
        let checkUndefindValues = xyz.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });
        if (checkUndefindValues) {
          setServiceCodeInfo(xyz);
          // calCostSummary(xyz);
        }
      }
      setLastExistingRows(existingRows);
    }
  }, [existingRows]);

  return (
    <>
      {/* In below condition i need to check once more thing that SmartContractId == null  */}
      {/* once old episodes will have contracts we need to show them */}
      {props.clientInfo &&
      props.clientInfo.Episode[props.clientInfo.Episode.length - 1].EpisodeId ==
        props.clientInfo.EpisodeId ? (
        <>
          <div
            className="container-fluid mt-1 px-0"
            style={{
              minHeight: '300px',
              // overflow: 'auto'
            }}
          >
            <div className="p-2">
              {contractType == '1' && (
                <div className="row mt-1 mb-3">
                  <div className="col-md-8">
                    <div
                      // className="row payer-details pt-3 pb-3"
                      className="payer-details pt-3 pb-3"
                    >
                      <div className="row px-2">
                        <div className="col-md-8 text-left">
                          <div className="label mt-2 mb-2">Contract Details</div>
                        </div>
                        {props.bundle && props.bundle.length > 0 && (
                          <div className="col-md-4 text-right">
                            <span
                              style={{
                                color: 'rgba(0, 0, 0, 0.85)',
                                fontWeight: '900',
                                justifyContent: 'end',
                                fontSize: '16px',
                              }}
                              // className="mr-4"
                            >
                              Total Billing Rate:{' '}
                              {currencyFormatter.format(summaryData.totalBillingRate || 0)}
                            </span>
                            <span
                              onClick={showDrawer}
                              style={{
                                color: '#46b4fe',
                                cursor: 'pointer',
                                fontWeight: '900',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                              }}
                            >
                              Summary{' '}
                              <img
                                src="images/expandedIconBlue2.svg"
                                // className="mx-2"
                                style={{ cursor: 'pointer', width: '18px' }}
                              />
                            </span>
                          </div>
                        )}
                      </div>

                      <ReduxCreateContracForm
                        smartContractData={props.smartContractData}
                        setBundle={props.setBundle}
                        onContractSubmit={props.onContractSubmit}
                        clientId={props.clientId}
                        setEnableCreateButton={setEnableCreateButton}
                        summaryData={summaryData}
                        enableCreateButton={enableCreateButton}
                        finalSubmit={finalSubmit}
                        goalLibrary={goalLibrary}
                        centerServiceTypeCenter={centerServiceTypeCenter}
                        serviceCodeBundle={serviceCodeBundle}
                        // initialValues={stateInitialValues}
                        setFieldChanged={setFieldChanged}
                        setIndexOfNetMarginChange={setIndexOfNetMarginChange}
                        setCurrentServiceTypeIndex={setCurrentServiceTypeIndex}
                        existingRows={existingRows}
                        initialValues={props.initialValues}
                      />
                    </div>
                  </div>
                  {/* <Drawer
                    closeIcon={<CloseIcon />}
                    width="600"
                    title="Cost Summary"
                    placement="right"
                    onClose={onClose}
                    open={open}
                  >
                    <CostSummary bundle={bundle} setSummaryData={setSummaryData} />
                  </Drawer> */}
                  <div className="col-md-4">
                    <NewCostSummary
                      bundle={props.bundle}
                      setSummaryData={setSummaryData}
                      existingRows={existingRows}
                      serviceCodeBundle={serviceCodeBundle}
                    />
                  </div>

                  {/* <div className="col-md-3">
                <CostSummary bundle={bundle} setSummaryData={setSummaryData} />
              </div> */}
                </div>
              )}
              {contractType == '2' && (
                <div className="row mt-1 mb-3 fee-for-service">
                  {' '}
                  <div className="col-md-12 d-none">
                    <p className="p-4">
                      Amet minim mollit non deserunt ullamco est sit. Amet minim mollit non deserunt
                      ullamco est sit....Amet minim mollit non deserunt ullamco est sit....Amet
                      minim mollit non deserunt ullamco est sit....Amet minim mollit non deserunt
                      ullamco est sit....
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <p style={{ fontSize: '16px' }}>
            <b>Contract can not be created for old episode.</b>
          </p>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const {
    combos,
  } = state;
  let serviceCodeBundle = [];
  let goalLibrary = [];
  let centerServiceTypeCenter = [];

  if (combos.data) {
    const {
      ServiceCodeBundle,
      GoalLibrary,
      CenterServiceTypeCenter,
    } = combos.data;
    if (ServiceCodeBundle) {
      serviceCodeBundle = ServiceCodeBundle;
    }
    if (GoalLibrary) {
      goalLibrary = GoalLibrary;
    }
    if (CenterServiceTypeCenter) {
      centerServiceTypeCenter = CenterServiceTypeCenter;
    }
  }

  return {
    serviceCodeBundle,
    centerServiceTypeCenter,
    goalLibrary,
    existingRows: formValueSelector('contractForm')(state, 'Goal'),
  };
};

export default connect(mapStateToProps)(CreateContract);
