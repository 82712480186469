import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate, {
  dateofbirthNormalize,
  MobileFormatter,
  MobileNormalize,
  ssnFormatter,
  ssnNormalize,
} from '../../validation/validate';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
  ReduxToggle,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { listCombos } from '../../../../store/actions';

const OfficeDetails = ({
  fields,
  name,
  meta: { touched, error },
  HealthInsuranceType,
  StateProvince,
  Country,
  disable,
  dispatch,
}) => {
  const countryChange = (countryISO, currentIndex) => {
    if (countryISO) {
      dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
  };
  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Detail {index + 1}</h6>
              </div>
              <div class={`col-12 col-md-4`}>
                <a class="delete-contact" disabled={disable} onClick={() => fields.remove(index)}>
                  <i class="fas fa-trash" /> Delete Detail
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.officeID`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Office ID'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Office ID</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.office/FacilityName`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Office/Facility Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Office/Facility Name</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.line1`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Permanent Address Line 1'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Address Line 1</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.line2`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Permanent Address Line 2'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Line 2</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.stateOrProvince`}
                    type="text"
                    component={ReduxSelect}
                    options={StateProvince}
                    placeholder="State/Province/Territories"
                    fieldName="State/Province/Territories"
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field name={`${item}.city`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              {/* <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.country`}
                    type="text"
                    errorMessage="Country Required"
                    component={ReduxSelect}
                    options={Country}
                    // validate={Validator.required}
                    onChange={countryISO => countryChange(countryISO, index)}
                    placeholder="Country"
                    fieldName={'Country'}
                    disabled={disable}
                  />
                </div>
              </div> */}

              {/* <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.phone`}
                    component={ReduxInput}
                    validate={validate.NumericMobile}
                    format={MobileFormatter}
                    normalize={MobileNormalize}
                    fieldName={'Phone'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div> */}
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.zip`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Postal/Zip Code</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.contactFirstName`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Contact First Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Contact First Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.contactLastName`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Contact Last Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Contact Last Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.ContactTitle`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Contact Title'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Contact Title</label>
                </div>
              </div>
              {/* <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    component={ReduxInput}
                    name="dateofBirth"
                    normalize={dateofbirthNormalize}
                    validate={[validate.required, validate.dobStudent]}
                    placeholder="MM/DD/YYYY"
                    fieldName={'Date of Birth'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Subscriber DOB</label>
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div> */}
              {/* <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`ssn`}
                    type="text"
                    component={ReduxInput}
                    validate={validate.ssn}
                    format={ssnFormatter}
                    normalize={ssnNormalize}
                    disabled={disable}
                    fieldName={true}
                  />
                  <label class="placeholder-label">Subscriber SSN</label>
                </div>
              </div> */}

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.contactPhone`}
                    component={ReduxInput}
                    fieldName={'Contact Phone'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.email`}
                    component={ReduxInput}
                    fieldName={'contact Email'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Email Address</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`contactNPI`}
                    type="text"
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'contactNPI'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">NPI</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" disabled={disable} onClick={() => fields.push({ country: 'USA' })}>
            {fields.length >= 1 && <label> + Add Another Details</label>}
            {fields.length == 0 && <label> + Add Details</label>}
          </a>
        </div>
      </div>
    </>
  );
};

const HealthInsuranceUI = props => {
  const {
    handleSubmit,
    name,
    pristine,
    submitting,
    onLastSubmit,
    HealthInsuranceType,
    StateProvince,
    CountryISO,
    style,
    isHealthInsurance,
    goToPreviuos,
    disable,
  } = props;
  return (
    <form onSubmit={handleSubmit(onLastSubmit)} style={style}>
      <div class="row mb-3">
        {/* <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Do you have Health Insurance?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isHealthInsurance" component={ReduxToggle} disabled={disable} />
          </div>
        </div> */}
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`facilityID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Facility ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Facility ID</label>
          </div>
        </div>
        <div class="col-12">
          <h6>Office Details</h6>
        </div>
        <div class="col-12">
          <FieldArray
            name="officeDetails"
            component={OfficeDetails}
            HealthInsuranceType={HealthInsuranceType}
            StateProvince={StateProvince}
            Country={CountryISO}
            disable={disable}
            dispatch={props.dispatch}
          />
        </div>
      </div>

      <div class="row pagination-button">
        <div class="col-12 text-right">
          <button
            onClick={goToPreviuos}
            class="btn btn-outline-primary btn-eForm-Prev mr-2"
            disabled={disable}
          >
            Previous
          </button>
          <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  const isHealthInsurance = selector(state, 'isHealthInsurance');
  return {
    StateProvince: combos.data.StateProvince,
    HealthInsuranceType: combos.data.HealthInsuranceType,
    CountryISO: combos.data.Country,
    isHealthInsurance,
  };
})(
  reduxForm({
    form: 'providerEnrollmentForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(HealthInsuranceUI),
);

const selector = formValueSelector('providerEnrollmentForm');
