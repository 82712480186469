import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, reset } from 'redux-form';
import moment from 'moment';
import { Table, Button } from 'antd';
import { PlusCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import { timeConvert } from '../../../../../util/time';
import { stringDate } from '../../../../../util/date';
import CustomModal from '../../../../../core/modal';

import { currencyFormatter, numberFormat } from '../../../../../util/formatter';

import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  ReduxTimePicker,
  CheckboxGroup,
} from '../../../../../component/custom-redux-components';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import { ProcessCombos } from '../../../../../store/comboConfig';
import { upsertServiceLine } from '../../../../../store/actions';
import PersistTableSort from '../../../../../component/persistTableSort';

class BundleCodeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceCodeBundle: {},
      ServiceCodeBundleInfo: {},
      ServiceCodeBundleList: [],
      capacityCount: 0,
      TotalCost: 0,
      costPerUnit: 0,
      isModelOpen: false,
    };
  }
  componentDidMount() {
    var result = ProcessCombos('ServiceLineStatus');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentWillReceiveProps({ BundleCodeInfo, isInserted }) {
    if (
      BundleCodeInfo &&
      JSON.stringify(BundleCodeInfo) != JSON.stringify(this.props.BundleCodeInfo)
    ) {
      this.setState({
        ServiceCodeBundle: BundleCodeInfo.ServiceCodeBundle[0],
        costPerUnit: parseFloat(BundleCodeInfo.ServiceCodeBundle[0].CostPerUnit) || 0,
        capacityCount: parseInt(BundleCodeInfo.ServiceCodeBundle[0].UnitsId) || 0,
        TotalCost: BundleCodeInfo.ServiceCodeBundle[0].TotalCost || 0,
        ServiceCodeBundleInfo: BundleCodeInfo.ServiceCodeBundleInfo[0],
        ServiceCodeBundleList: BundleCodeInfo.ServiceCodeBundleList,
      });
    }
    if (isInserted && isInserted != this.props.isInserted) {
      this.toggle('Bundled Service Line Saved Successfully');
      this.props.dispatch(reset('ApprovedForBillingForm'));
    }
  }
  getCapacityCount = e => {
    let inputNum = e.target.value;
    let pat = /^[0-9]{0,1}$/;
    if (inputNum.match(pat) && inputNum > 1) {
      inputNum = parseInt(inputNum);
      this.setState({
        capacityCount: inputNum || 0,
      });
    }
  };
  getTotalCost = (event, costPerUnit) => {
    let unitVal = parseInt(event.target.value);
    let TotalCost = (unitVal * costPerUnit).toFixed(2) || 0;

    this.setState({
      TotalCost: TotalCost,
    });
  };
  onSubmit = values => {
    const { IsApprovedForBilling, IsApprovedForBatching, BatchSentDate, StatusId } = values;
    let ServiceLineData = {
      json: JSON.stringify({
        ServiceLine: [
          {
            ServiceLineId: this.state.ServiceCodeBundle.ServiceLineId,
            ClientId: this.state.ServiceCodeBundle.ClientId,
            IsApprovedForBilling: IsApprovedForBilling,
            IsApprovedForBatching: IsApprovedForBatching,
            StatusId: StatusId,
            BatchSentDate: BatchSentDate && stringDate(BatchSentDate),
          },
        ],
      }),
    };

    this.props.dispatch(upsertServiceLine(ServiceLineData));
  };
  render() {
    const { toggleView, isFetching, ServiceLineStatus } = this.props;
    const {
      ServiceCodeBundle,
      ServiceCodeBundleInfo,
      ServiceCodeBundleList,
      capacityCount,
      TotalCost,
      costPerUnit,
    } = this.state;
    const {
      BundleCost,
      ClientId,
      DisplayClientId,
      ClientName,
      EndDate,
      PlaceOfService,
      PractitionerName,
      ProgramName,
      ProviderName,
      ServiceCode,
      ServiceLineId,
      StaffName,
      StartDate,
      ServiceCodeDescription,
      UnitsId,
      CostPerUnit,
      UnitOfMeasurementDescription,
    } = ServiceCodeBundle;
    const { FacilityName, PlaceOfServiceName, ProgramDescription } = ServiceCodeBundleInfo;
    const columns = [
      {
        title: 'Date of service',
        dataIndex: 'SessionDate',
        key: 'SessionDate',
        sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },

      // {
      //   title: 'Start time',
      //   dataIndex: 'StartTime',
      //   key: 'StartTime',
      //   sorter: (a, b) => isComparer(a.StartTime, b.StartTime),
      //   // sorter: (a, b) => isNumericComparer(a.StartTime, b.StartTime),
      //   sortDirections: ['descend', 'ascend'],
      //   // width: 150,
      // },
      // {
      //   title: 'End time',
      //   dataIndex: 'EndTime',
      //   key: 'EndTime',
      //   sorter: (a, b) => isComparer(a.EndTime, b.EndTime),
      //   sortDirections: ['descend', 'ascend'],
      //   // width: 150,
      // },
      {
        title: 'Start / End Time',
        dataIndex: 'SessionTime',
        key: 'SessionTime',
        sorter: (a, b) => isNumericComparer(a.SessionTime, b.SessionTime),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Provider Name',
        dataIndex: 'PractionerName',
        key: 'PractionerName',
        sorter: (a, b) => isComparer(a.PractionerName, b.PractionerName),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Duration',
        dataIndex: 'Duration',
        key: 'Duration',
        sorter: (a, b) => isComparer(a.Duration, b.Duration),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Units',
        dataIndex: 'UnitsId',
        key: 'UnitsId',
        sorter: (a, b) => isNumericComparer(a.UnitsId, b.UnitsId),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Cost Per Unit',
        dataIndex: 'CostPerUnit',
        key: 'CostPerUnit',
        sorter: (a, b) => isNumericComparer(a.CostPerUnit, b.CostPerUnit),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Total Cost',
        dataIndex: 'TotalCost',
        key: 'TotalCost',
        sorter: (a, b) => isNumericComparer(a.TotalCost, b.TotalCost),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Service code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Description',
        dataIndex: 'ServiceCodeDescription',
        key: 'ServiceCodeDescription',
        sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      {
        title: 'Diagnosis',
        dataIndex: 'DiagnosisInfo',
        key: 'DiagnosisInfo',
        sorter: (a, b) => isComparer(a.DiagnosisInfo, b.DiagnosisInfo),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      // {
      //   title: 'Cost',
      //   dataIndex: 'Cost',
      //   key: 'Cost',
      //   sorter: (a, b) => isComparer(a.Cost, b.Cost),
      //   sortDirections: ['descend', 'ascend'],
      //   // width: 150,
      // },
      // {
      //   title: 'Action',
      //   // width: 300,
      // },
    ];
    const isDeactivated = true;
    return (
      <div className="row">
        <Loader2 loading={isFetching} />
        <div className="col-12">
          <div className="bundle-code-detail">
            <div className="bundle-detail-header">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  onClick={() => {
                    toggleView();
                  }}
                  className="text-left"
                  style={{ cursor: 'pointer', display: 'flex' }}
                >
                  <img src="images/back-arrow.svg" className="mr-2" /> Back to Bundled service
                </div>
              </div>
              <div className="bundle-code-header">
                <div className="row">
                  <div className="col-12">
                    <h6>Manage Bundled Service</h6>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <span className="text">{DisplayClientId}</span>
                        <br />
                        <span className="value">{ClientName}</span>
                      </div>
                      <div className="">
                        <span className="text">Program</span>
                        <br />
                        <span className="value">{ProgramDescription}</span>
                      </div>
                      <div className="">
                        <span className="text">Start Date</span>
                        <br />
                        <span className="value">{StartDate}</span>
                      </div>
                      <div className="">
                        <span className="text">End Date</span>
                        <br />
                        <span className="value">{EndDate}</span>
                      </div>

                      {/* <div className="">
                        <span className="text">Staff</span>
                        <br />
                        <span className="value">{StaffName}</span>
                      </div> */}

                      <div className="">
                        <span className="text">Provider</span>
                        <br />
                        <span className="value">{PractitionerName}</span>
                      </div>
                      <div className="">
                        <span className="text">Place of Service</span>
                        <br />
                        <span className="value">{PlaceOfServiceName}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bundle-code">
              <div className="bundle-code-info">
                <div className="row">
                  {/* <div className="col-12">
                  <h6>Bundled Service</h6>
                </div> */}
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4">
                        <span className="text">Service Code</span>
                        <br />
                        <span className="value">{ServiceCode}</span>
                      </div>
                      <div className="col-8 mb-2">
                        <span className="text">Code Description</span>
                        <br />
                        <span className="value">{ServiceCodeDescription}</span>
                      </div>
                      <div class="col-4">
                        <div class="license-quantity d-flex  justify-content-center align-items-baseline">
                          <label class="placeholder-label mr-3 text">Units</label>
                          <div class="quantity-btn flex-grow-1">
                            <div class="numbers-row">
                              <input
                                name={'groupCapacity'}
                                class="number-icon"
                                type="text"
                                min="1"
                                max="99"
                                value={capacityCount}
                                onChange={this.getCapacityCount}
                                //   validate={Validator.minValue}
                                tabindex="0"
                                style={{
                                  textAlign: 'center',
                                  margin: '0px 5px',
                                  height: '32px',
                                  width: '56px',
                                  fontSize: '16px',
                                }}
                                onBlur={event => {
                                  this.getTotalCost(event, costPerUnit);
                                }}
                                disabled={isDeactivated}
                              />
                              <div
                                style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                                class="add quantity-button"
                                onClick={() => {
                                  if (!isDeactivated)
                                    if (capacityCount <= 99) {
                                      let count = capacityCount + 1;
                                      let TotalCost = count * costPerUnit || 0;
                                      return this.setState(
                                        {
                                          capacityCount: count,
                                          TotalCost: TotalCost,
                                        },
                                        // this.onGroupCapacity(capacityCount + 1),
                                      );
                                    } else {
                                      return;
                                    }
                                }}
                              >
                                +
                              </div>
                              <div
                                style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                                class="remove quantity-button"
                                onClick={() => {
                                  const val = capacityCount - 1;
                                  let TotalCost = val * costPerUnit || 0;
                                  if (val < 1) return;
                                  if (!isDeactivated)
                                    this.setState(
                                      {
                                        capacityCount: val,
                                        TotalCost: TotalCost,
                                      },
                                      // this.onGroupCapacity(val),
                                    );
                                }}
                              >
                                -
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <span className="text">Unit of measurement</span>
                        <br />
                        <span className="value">{UnitOfMeasurementDescription}</span>
                      </div>
                      <div className="col-2">
                        <span className="text">Cost per unit</span>
                        <br />
                        <span className="value">{currencyFormatter.format(CostPerUnit)}</span>
                      </div>
                      <div className="col-2"></div>
                      <div className="col-2">
                        <span className="text">Total Cost</span>
                        <br />
                        <span className="value font-weight-bold">
                          {currencyFormatter.format(TotalCost)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-detail-body">
              <div className="attendees">
                <div className="">
                  <div className="">
                    <PersistTableSort
                      name="bundle-code-detail"
                      columns={columns}
                      dataSource={ServiceCodeBundleList}
                      size={'middle'}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <ApprovedForBillingForm
            onSubmit={this.onSubmit}
            ServiceLineStatus={ServiceLineStatus}
            initialValues={{
              IsApprovedForBilling: ServiceCodeBundle.IsApprovedForBilling,
              IsApprovedForBatching: ServiceCodeBundle.IsApprovedForBatching,
              BatchSentDate: moment(ServiceCodeBundle.BatchSentDate).format('YYYY-MM-DD'),
              StatusId: ServiceCodeBundle.StatusId,
            }}
          />
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            toggleView();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const ApprovedForBilling = props => {
  const { handleSubmit, onSubmit, ServiceLineStatus } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '15px 30px' }}>
      <div className="row enrollment-form align-items-center">
        <div class="col-3">
          <div class="form-group">
            <Field
              name={`IsApprovedForBilling`}
              type="text"
              component={ReduxCheckbox}
              label={'Approved For Billing '}
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <Field
              name={`IsApprovedForBatching`}
              type="text"
              component={ReduxCheckbox}
              label={'Approved For Batching '}
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <Field
              name="StatusId"
              type="text"
              placeholder="Status"
              component={ReduxSelect}
              options={ServiceLineStatus}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <Field
              name={`BatchSentDate`}
              type="text"
              component={ReduxDateInput}
              label={'Batch Sent Date '}
            />
            <i class="far fa-calendar-alt form-control-icon" />
          </div>
        </div>
        <div class="col-12 mt-2 text-right">
          <input type="submit" class="btn px-5" value={'Update'} />
        </div>
      </div>
    </form>
  );
};
const ApprovedForBillingForm = reduxForm({
  form: 'ApprovedForBillingForm',
  enableReinitialize: true,
})(connect()(withRouter(ApprovedForBilling)));

const mapStateToProps = state => {
  const { combos, get_service_code_bundle, upsert_service_line } = state;
  const { data: bundleCodeInfoData, isFetching } = get_service_code_bundle;

  let BundleCodeInfo = null;
  let ServiceLineStatus = [];
  let isInserted = false;

  if (bundleCodeInfoData && bundleCodeInfoData.ServiceCodeBundle) {
    BundleCodeInfo = bundleCodeInfoData || {};
  }
  if (combos.data && combos.data['ServiceLineStatus']) {
    ServiceLineStatus = combos.data['ServiceLineStatus'] || [];
  }
  if (upsert_service_line.data && upsert_service_line.data.success) {
    isInserted = true;
  }
  return {
    isFetching,
    BundleCodeInfo,
    ServiceLineStatus,
    isInserted,
  };
};

export default connect(mapStateToProps)(withRouter(BundleCodeDetail));
