import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Badge, Dropdown, Space } from 'antd';
import { currencyFormatter } from '../../../util/formatter';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
// import ContractRowRender from './contractRowRender';
const categoryServiceCodes = (categoryRecord, index, indent, expanded) => {
  const {
    categoryRecord: serviceCodesRecord,
    showRequest,
    isReadOnly,
    actionAllowed,
    getServiceCodeDetails,
    bundleName,
  } = categoryRecord;
  const { ServiceCode, Services } = serviceCodesRecord;

  let columns;

  // columns = [
  //   {
  //     title: 'Service Code',
  //     dataIndex: 'CodeName',
  //     key: 'CodeName',
  //     sorter: (a, b) => isComparer(a.CodeName, b.CodeName),
  //   },
  //   {
  //     title: 'Description',
  //     dataIndex: 'ServiceCodeDescription',
  //     key: 'ServiceCodeDescription',
  //     sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
  //   },
  //   {
  //     title: 'Pricing',
  //     dataIndex: 'BillingRate',
  //     key: 'BillingRate',
  //     align: 'right',
  //     sorter: (a, b) => isNumericComparer(a.BillingRate, b.BillingRate),
  //     render: (text, record) => (
  //       <div>
  //         <p className="mb-0"> {text && currencyFormatter.format(text)} </p>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: 'Action',
  //     dataIndex: 'operation',
  //     key: 'operation',
  //     render: (text, record) => {
  //       if (!record.IsRequestPending && !isReadOnly) {
  //         return (
  //           <p
  //             className="mb-0"
  //             style={{
  //               color: '#009EF7',
  //               fontWeight: '600',
  //               fontSize: '15px',
  //               cursor: 'pointer',
  //             }}
  //             onClick={() =>
  //               getServiceCodeDetails(
  //                 bundleName,
  //                 Services,
  //                 record.CodeName,
  //                 record.SmartContractBundleServiceCodeId,
  //               )
  //             }
  //           >
  //             Request Changes
  //           </p>
  //         );
  //       } else if (record.IsRequestPending && !isReadOnly) {
  //         return (
  //           <p
  //             className="mb-0"
  //             style={{
  //               color: '#00DC31',
  //               fontSize: '15px',
  //               fontWeight: '600',
  //             }}
  //           >
  //             Changes Requested
  //           </p>
  //         );
  //       }
  //     },
  //   },
  // ];

  if (actionAllowed) {
    columns = [
      {
        title: 'Service Code',
        dataIndex: 'CodeName',
        key: 'CodeName',
        sorter: (a, b) => isComparer(a.CodeName, b.CodeName),
      },
      {
        title: 'Description',
        dataIndex: 'ServiceCodeDescription',
        key: 'ServiceCodeDescription',
        sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
      },
      {
        title: 'Pricing',
        dataIndex: 'BillingRate',
        key: 'BillingRate',
        align: 'right',
        sorter: (a, b) => isNumericComparer(a.BillingRate, b.BillingRate),
        render: (text, record) => (
          <div>
            <p className="mb-0"> {text && currencyFormatter.format(text)} </p>
          </div>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (text, record) => {
          if (!record.IsRequestPending && !isReadOnly) {
            return (
              <p
                className="mb-0"
                style={{
                  color: '#009EF7',
                  fontWeight: '600',
                  fontSize: '15px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  getServiceCodeDetails(
                    bundleName,
                    Services,
                    record.CodeName,
                    record.SmartContractBundleServiceCodeId,
                  )
                }
              >
                Request Changes
              </p>
            );
          } else if (record.IsRequestPending && !isReadOnly) {
            return (
              <p
                className="mb-0"
                style={{
                  color: '#00DC31',
                  fontSize: '15px',
                  fontWeight: '600',
                }}
              >
                Changes Requested
              </p>
            );
          }
        },
      },
    ];
  } else {
    columns = [
      {
        title: 'Service Code',
        dataIndex: 'CodeName',
        key: 'CodeName',
        sorter: (a, b) => isComparer(a.CodeName, b.CodeName),
      },
      {
        title: 'Description',
        dataIndex: 'ServiceCodeDescription',
        key: 'ServiceCodeDescription',
        sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
      },
      {
        title: 'Pricing',
        dataIndex: 'BillingRate',
        key: 'BillingRate',
        align: 'right',
        sorter: (a, b) => isNumericComparer(a.BillingRate, b.BillingRate),
        render: (text, record) => (
          <div>
            <p className="mb-0"> {text && currencyFormatter.format(text)} </p>
          </div>
        ),
      },
    ];
  }

  return (
    <Table
      columns={columns}
      dataSource={ServiceCode}
      pagination={false}
      className="nested-tables"
    />
  );
};

export default categoryServiceCodes;
