import React from 'react';
import { connect } from 'react-redux';
import {
  centerOverHeadCostGet,
  centerLaberCostGet,
  listCombos,
  centerLaberCostGetUpsert,
} from '../../../store/actions';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import CustomModal from '../../../core/modal';
import { Loader2 } from '../../../Components/Loader';
import { currencyFormatter, numberFormat } from '../../../util/formatter';
import LaborBurden from './laborBurden';
import PageTitle from '../../../Components/PageTitle';
class LaborCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PayFrequency: 0,
      currentLaborBurden: 0,
    };
  }

  handlePayFrequencyChange = (RoleDescription, value) => {
    let newArray = this.state.centerLaberCostValue.map((item, index) => {
      if (item.RoleDescription == RoleDescription) {
        return {
          ...item,
          PayFrequency: value,
        };
      } else {
        return item;
      }
    });
    this.setState({ centerLaberCostValue: newArray }, () => {
      let modifiedArray = [
        ...newArray.filter(item => {
          return item.RoleDescription == RoleDescription;
        }),
      ];
      modifiedArray = modifiedArray.map(item => {
        const { CenterLaborCostId, Cost, PayFrequency, RoleDescription, StaffRoleId } = item;
        return {
          CenterLaborCostId,
          Cost,
          PayFrequency,
          RoleDescription,
          StaffRoleId,
        };
      });
      this.props.dispatch(
        centerLaberCostGetUpsert({
          jsonInput: JSON.stringify({
            CenterLaborCost: modifiedArray[0],
          }),
        }),
      );
    });
  };

  handlePlusMinus = (value, indexValue) => {
    let newArray = this.state.centerLaberCostValue.map((item, index) => {
      if (
        index == indexValue &&
        /^[0-9]{0,6}$/.test(value == 'Plus' ? item.Cost + 1 : item.Cost == 0 ? 0 : item.Cost - 1)
      ) {
        let newItem = {
          ...item,
          Price: value == 'Plus' ? item.Price + 1 : item.Price == 0 ? 0 : item.Price - 1,
          Cost: value == 'Plus' ? item.Cost + 1 : item.Cost == 0 ? 0 : item.Cost - 1,
        };
        return newItem;
      } else {
        return item;
      }
    });

    this.setState({ centerLaberCostValue: newArray }, () => {
      let modifiedArray = [
        ...newArray.filter((item, index) => {
          return index == indexValue;
        }),
      ];
      modifiedArray = modifiedArray.map(item => {
        const { CenterLaborCostId, Cost, PayFrequency, RoleDescription, StaffRoleId } = item;
        return {
          CenterLaborCostId,
          Cost,
          PayFrequency,
          RoleDescription,
          StaffRoleId,
        };
      });

      this.props.dispatch(
        centerLaberCostGetUpsert({
          jsonInput: JSON.stringify({
            CenterLaborCost: modifiedArray[0],
          }),
        }),
      );
    });
  };

  handleOnChange = (event, indexValue) => {
    let currentValue = parseFloat(event.target.value) ? parseFloat(event.target.value) : null;
    let newArray;
    if (currentValue != null) {
      newArray = this.state.centerLaberCostValue.map((item, index) => {
        if (index == indexValue && /^[0-9]{0,6}$/.test(currentValue)) {
          return {
            ...item,
            Cost: currentValue,
          };
        } else {
          return item;
        }
      });
    } else {
      newArray = this.state.centerLaberCostValue.map((item, index) => {
        if (index == indexValue) {
          return {
            ...item,
            Cost: currentValue,
          };
        } else {
          return item;
        }
      });
    }
    this.setState({ centerLaberCostValue: newArray });
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  // updateFinalPriceOnBurdenChange = (currentLaborBurden) => {
  //   let newArray = this.state.centerLaberCostValue.map((item, index) => {
  //     let ValueAfterPercentagePriceChange;
  //     ValueAfterPercentagePriceChange = (item.Cost * currentLaborBurden) / 100;
  //      return {
  //        ...item,
  //        Price: parseFloat(ValueAfterPercentagePriceChange)
  //          ? item.Cost+parseFloat(ValueAfterPercentagePriceChange)
  //          : null,
  //      };
  //    });
  //   // newArray.map((item,index) => {
  //   //    this.props.dispatch(
  //   //      centerLaberCostGetUpsert({
  //   //        jsonInput: JSON.stringify({
  //   //          CenterLaborCost: item
  //   //        }),
  //   //      }),
  //   //    );
  //   // });
  //    this.setState({ centerLaberCostValue: newArray });
  // };

  getCurrentLaborBurden = currentLaborBurden => {
    this.setState({ currentLaborBurden });
    // this.updateFinalPriceOnBurdenChange(currentLaborBurden);
  };

  componentDidMount() {
    this.props.dispatch(centerOverHeadCostGet({}));
    this.props.dispatch(centerLaberCostGet({}));
    this.props.dispatch(listCombos({ comboTypes: 'PayFrequency' }));
  }

  componentWillReceiveProps({ centerLaberCostValue, isCenterLaberCostGetUpdated }) {
    if (
      centerLaberCostValue &&
      JSON.stringify(centerLaberCostValue) != JSON.stringify(this.props.centerLaberCostValue)
    ) {
      this.setState({ centerLaberCostValue });
    }
    if (
      isCenterLaberCostGetUpdated &&
      isCenterLaberCostGetUpdated != this.props.isCenterLaberCostGetUpdated
    ) {
      this.setState({
        ...this.state,
        isCenterLaberCostGetUpdated,
      });
      // this.toggle('Variable Labor Cost updated Successfully');
    }
  }

  render() {
    const { centerLaberCostValue } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Labor Cost | Master Bill of Services" />
        <Loader2 loading={this.props.isFetching} />
        <div className="container-fluid mt-5 labor-cost">
          <h4>Labor Rates</h4>
          <p style={{ color: '#959595' }}>
            Labor cost refers to the expenses incurred in paying the wages and benefits of
            healthcare workers who provide direct patient care.
          </p>
          <LaborBurden getCurrentLaborBurden={this.getCurrentLaborBurden} />

          <div
            className="d-flex justify-content-between align-items-center p-2 py-4"
            style={{ background: '#F8F8F8', margin: '10px 0px' }}
          >
            <div className="" style={{ width: '300px', fontWeight: '700', fontSize: '20px' }}>
              {' '}
              &nbsp;&nbsp;&nbsp;&nbsp; Contractor
            </div>
            <div>
              Contractor’s cost can be defined while creating the Service
              Code.&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>

          {centerLaberCostValue &&
            centerLaberCostValue.length > 0 &&
            centerLaberCostValue.map((item, index) => {
              const { PayFrequency, Price, RoleDescription, StaffRoleId } = item;
              if (RoleDescription != 'Contractor') {
                return (
                  <div
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{ background: '#F8F8F8', margin: '10px 0px' }}
                  >
                    {' '}
                    <div
                      className=""
                      style={{ width: '300px', fontWeight: '700', fontSize: '20px' }}
                    >
                      {' '}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {RoleDescription}
                    </div>
                    <div
                      className="row d-flex justify-content-around"
                      style={{
                        width: '900px',
                        // border: '1px solid #d9d9d9',
                        // background: 'white',
                        padding: '10px 5px',
                        paddingTop: '30px',
                        margin: '15px',
                      }}
                    >
                      <div class="form-group mb-0 w-100 col-md-4">
                        <div class="license-quantity d-flex  justify-content-center align-items-center">
                          <div style={{ fontSize: '15px' }}>Set Cost $</div>&nbsp;&nbsp;
                          {/* <label class="placeholder-label flex-grow-1"></label> */}
                          <div class="quantity-btn flex-grow-1">
                            <div class="numbers-row">
                              <input
                                name={RoleDescription}
                                class="number-icon numbers-only"
                                type="number"
                                min="0"
                                max="3"
                                value={
                                  this.state.centerLaberCostValue &&
                                  this.state.centerLaberCostValue[index] &&
                                  this.state.centerLaberCostValue[index].Cost
                                }
                                onChange={event => {
                                  this.handleOnChange(event, index);
                                }}
                                onWheel={event => {
                                  window.document.activeElement.blur();
                                }}
                                onBlur={() => {
                                  let modifiedArray = [
                                    ...this.state.centerLaberCostValue.filter(
                                      (item, indexValue) => {
                                        return indexValue == index;
                                      },
                                    ),
                                  ];

                                  modifiedArray = modifiedArray.map(item => {
                                    const {
                                      CenterLaborCostId,
                                      Cost,
                                      PayFrequency,
                                      RoleDescription,
                                      StaffRoleId,
                                    } = item;
                                    return {
                                      CenterLaborCostId,
                                      Cost: Cost || 0,
                                      PayFrequency,
                                      RoleDescription,
                                      StaffRoleId,
                                    };
                                  });

                                  this.props.dispatch(
                                    centerLaberCostGetUpsert({
                                      jsonInput: JSON.stringify({
                                        CenterLaborCost: modifiedArray[0],
                                      }),
                                    }),
                                  );
                                }}
                                onKeyDown={evt =>
                                  ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                //   validate={Validator.minValue}
                                tabindex="0"
                                style={{
                                  textAlign: 'center',
                                  margin: '0px 5px',
                                  height: '32px',
                                  width: '65px',
                                  fontSize: '16px',
                                }}
                                //   disabled={isDeactivated}
                              />
                              <div
                                style={{
                                  borderRadius: '0px',
                                  height: '32px',
                                  width: '32px',
                                  cursor: 'pointer',
                                }}
                                class="add quantity-button"
                                onClick={() => {
                                  this.handlePlusMinus('Plus', index);
                                }}
                              >
                                +
                              </div>
                              <div
                                style={{
                                  borderRadius: '0px',
                                  height: '32px',
                                  width: '32px',
                                  cursor: 'pointer',
                                }}
                                class="remove quantity-button"
                                onClick={() => {
                                  this.handlePlusMinus('Minus', index);
                                }}
                              >
                                -
                              </div>
                            </div>{' '}
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-0 w-100 col-md-3">
                        <label
                          className="placeholder-label"
                          style={{
                            zIndex: 1,
                            fontSize: '11px',
                            top: '-5px',
                            // left: !!disabled || value || active ? '0' : '0',
                            position: 'absolute',
                          }}
                        >
                          Burden Cost
                        </label>
                        <span
                          className=""
                          style={{
                            marginTop: '13px',
                            fontSize: '16px',
                            color: '#000',
                            padding: '3px',
                            display: 'inline-block',
                          }}
                        >
                          {this.state.centerLaberCostValue &&
                          this.state.centerLaberCostValue[index] &&
                          this.state.centerLaberCostValue[index].Cost
                            ? currencyFormatter.format(
                                (this.state.centerLaberCostValue &&
                                  this.state.centerLaberCostValue[index] &&
                                  this.state.centerLaberCostValue[index].Cost *
                                    this.state.currentLaborBurden) / 100,
                              )
                            : '$0.00'}
                        </span>
                      </div>

                      <div class="form-group mb-0 w-100 col-md-3">
                        <label
                          className="placeholder-label"
                          style={{
                            zIndex: 1,
                            fontSize: '11px',
                            top: '-5px',
                            // left: !!disabled || value || active ? '0' : '0',
                            position: 'absolute',
                          }}
                        >
                          Total Cost
                        </label>
                        <span
                          className=""
                          style={{
                            marginTop: '13px',
                            fontSize: '16px',
                            color: '#000',
                            padding: '3px',
                            display: 'inline-block',
                          }}
                        >
                          {this.state.centerLaberCostValue &&
                          this.state.centerLaberCostValue[index] &&
                          this.state.centerLaberCostValue[index].Cost
                            ? currencyFormatter.format(
                                this.state.centerLaberCostValue &&
                                  this.state.centerLaberCostValue[index] &&
                                  this.state.centerLaberCostValue[index].Cost +
                                    (this.state.centerLaberCostValue &&
                                      this.state.centerLaberCostValue[index] &&
                                      this.state.centerLaberCostValue[index].Cost *
                                        this.state.currentLaborBurden) /
                                      100,
                              )
                            : '$0.00'}
                        </span>
                      </div>

                      <div class="form-group call-schedule d-flex col-md-2">
                        <div style={{ width: '150px' }}>
                          {' '}
                          <ReduxSelect
                            options={this.props.PayFrequency}
                            onChange={event => {
                              this.handlePayFrequencyChange(RoleDescription, event);
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            value={
                              this.state.centerLaberCostValue &&
                              this.state.centerLaberCostValue[index] &&
                              this.state.centerLaberCostValue[index].PayFrequency
                            }
                            placeholder="Select"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
        </div>
        {/* <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // this.props.history.push('/manage/courses');
          }}
          yesText="OK"
          hideCancel={true}
        /> */}
      </React.Fragment>
    );
  }
}

const mapStateToPropsParent = ({ center_laber_cost_get, center_laber_cost_get_upsert, combos }) => {
  let centerLaberCostValue = null;

  let PayFrequency = [];
  let isCenterLaberCostGetUpdated = null;
  let isFetching = false;

  if (center_laber_cost_get && center_laber_cost_get.data) {
    centerLaberCostValue = center_laber_cost_get.data.CenterLaborCost;
  }

  if (center_laber_cost_get && center_laber_cost_get.isFetching) {
    isFetching = center_laber_cost_get.isFetching;
  }

  if (combos.data && combos.data['PayFrequency']) {
    PayFrequency = combos.data['PayFrequency'] || [];
  }

  if (
    center_laber_cost_get_upsert &&
    center_laber_cost_get_upsert.data &&
    center_laber_cost_get_upsert.data.success
  ) {
    isCenterLaberCostGetUpdated = center_laber_cost_get_upsert.data.success;
  }

  return {
    centerLaberCostValue,
    PayFrequency,
    isCenterLaberCostGetUpdated,
    isFetching,
  };
};
export default connect(mapStateToPropsParent)(LaborCost);
