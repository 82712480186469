import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
  ReduxTextarea,
} from '../../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../../core/modal';
import WarningComponent from '../../core/warning-component';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  isDirty,
} from 'redux-form';
import {
  getStudent,
  addStudent,
  resetAddRequest,
  appInviteDashboard,
  userAppLinkValidate,
  resetUserAppLinkValidate,
  resetGetStudent,
  listSurveyQuestionAssessment,
  surveyAnswerUpsert,
  preIntakeHistoryGet,
  resetPreIntakeHistoryGet,
} from '../../store/actions';
import DictationComponent from '../../Components/DictationComponent';

import { ProcessCombos } from '../../store/comboConfig';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../../app-config';
import { get } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import { getPhoneNumber, getCountryCalling, isValidMobileNumber } from '../../util/phoneFormatter';
import validate, {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from './validation/validate';
import Loader, { Loader2 } from '../../Components/Loader';
import PageTitle from '../../Components/PageTitle';

class ActivityBase extends React.Component {}

class PreIntakeForm extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
      studentData: {
        country: 'USA',
      },
    };
  }
  // need to work on it always return zero for me
  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('studentId') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }

  componentDidMount() {
    if (this.getIntakeId() > 0) {
      this.props.dispatch(getStudent(this.getIntakeId()));
      this.props.dispatch(
        preIntakeHistoryGet({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: this.getIntakeId(),
              },
            ],
          }),
        }),
      );
    }

    var result = ProcessCombos(
      'ReferenceSource,Country,Filter_StateProvince,PreIntakeClientStatus,PreIntakeRelationship,RelationShip,ClientTherapy',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  // toggle = header => {
  //   this.setState(prevState => ({
  //     isModelOpen: !prevState.isModelOpen,
  //     header,
  //   }));
  // };
  componentWillUnmount() {
    this.props.dispatch(resetGetStudent());
    this.props.dispatch(resetPreIntakeHistoryGet());
  }
  componentWillReceiveProps({
    studentInfo,
    isInserted,
    enrollStatus,
    isInvitationSent,
    formVal,
    isValidPhone,
    primaryCNValue,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      // this.setState({
      //   enrollStatus,
      // });
      // this.state.enrollStatus = enrollStatus;
      // this.toggle(Upsert_Sucess_Msg);  //As per suggetion disabling success popup.
      if (enrollStatus.enrollStatusId === 3 && enrollStatus.PreSurveyId) {
        this.props.history.push(
          `/client-screening/?studentid=${enrollStatus.studentId}&surveyid=${enrollStatus.PreSurveyId}`,
        );
      } else if (enrollStatus.enrollStatusId === 0) {
        this.props.history.push('/enrollment/?studentid=' + enrollStatus.studentId);
      } else {
        this.props.history.push('/clients/pre-intake-legacy');
      }
      this.props.dispatch(resetAddRequest());
    }

    if (studentInfo && studentInfo.StudentId > 0 && this.getIntakeId() > 0) {
      const data = {
        studentId: studentInfo.StudentId,
        firstName: studentInfo.FirstName,
        middleName: studentInfo.MiddleName,
        lastName: studentInfo.LastName,
        primaryCN: studentInfo.PrimaryPhone
          ? `+${studentInfo.PrimaryPhoneCountryCode}${studentInfo.PrimaryPhone}`
          : null,
        email: studentInfo.Email,
        line1: studentInfo.AddressLine1,
        city: studentInfo.City,
        zip: studentInfo.ZipCode,
        stateOrProvince: studentInfo.StateOrProvince,
        country: studentInfo.CountryISO,
        whoIsCalling: studentInfo.StudentMore[0].PreIntakeRelationshipId,
        referenceSourceId: studentInfo.StudentMore[0].ReferenceSourceId,
        therapyId: studentInfo.StudentMore[0].TherapyId,
        preIntakeNotes: studentInfo.PreIntakeNotes,
        preIntakeClientStatus: studentInfo.PreIntakeClientStatus,
      };
      if (!this.state.isValidated) {
        this.props.dispatch(
          userAppLinkValidate({
            Json: JSON.stringify({
              Client: [
                {
                  PhoneNumber: getPhoneNumber(data.primaryCN),
                  Email: data.email,
                  FirstName: data.firstName,
                  LastName: data.lastName,
                  CountryISOCode: data.country,
                },
              ],
            }),
          }),
        );
      }
      this.setState({
        studentData: data,
        isValidated: true,
      });
    }
    if (isInvitationSent && isInvitationSent != this.props.isInvitationSent) {
      this.checkAppStatus();
      toast.success('Invitation Sent Successfully', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (isValidPhone && isValidPhone != this.props.isValidPhone) {
      const { firstName, lastName, primaryCN, email, country } = formVal || {};
      if (primaryCN) {
        this.props.dispatch(
          userAppLinkValidate({
            Json: JSON.stringify({
              Client: [
                {
                  PhoneNumber: getPhoneNumber(primaryCN),
                  Email: email,
                  FirstName: firstName,
                  LastName: lastName,
                  CountryISOCode: country,
                },
              ],
            }),
          }),
        );
      }
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      firstName,
      middleName,
      lastName,
      primaryCN,
      email,
      whoIsCalling,
      referenceSourceId,
      preIntakeNotes,
      therapyId,
      line1,
      city,
      zip,
      isIntake,
      country,
      stateOrProvince,
      preIntakeClientStatus,
    } = values;
    var studentId = null;

    var paramStudentId = this.getIntakeId();
    if (paramStudentId && paramStudentId > 0) {
      studentId = paramStudentId;
    }
    const data = {
      Student: [
        {
          StudentId: studentId,
          EnrollStatusId: isIntake ? 0 : 3,
          FirstName: firstName,
          MiddleName: middleName,
          LastName: lastName,
          PrimaryPhone: getPhoneNumber(primaryCN),
          // PrimaryPhone: getPhoneNumber(primaryCN && primaryCN.replace(/\D/g, '')),
          // PrimaryPhone: primaryCN,
          PrimaryPhoneCountryCode: getCountryCalling(primaryCN),
          Email: email,
          AddressLine1: line1,
          City: city,
          ZipCode: zip,
          StateOrProvince: stateOrProvince,
          CountryISO: country,
          PreIntakeNotes: preIntakeNotes,
          PreIntakeClientStatus: preIntakeClientStatus,
          StudentMore: [
            {
              PreIntakeRelationshipId: whoIsCalling,
              ReferenceSourceId: referenceSourceId,
              TherapyId: therapyId,
            },
          ],
        },
      ],
    };
    var studentInfo = {
      userId: 1,
      centerId: this.props.defaultCenter,
      json: JSON.stringify(data),
    };
    if (this.getIntakeId() > 0) {
      studentInfo.studentId = this.getIntakeId();
    }
    this.props.dispatch(addStudent(studentInfo));
  }
  checkAppStatus = () => {
    const { firstName, lastName, primaryCN, email, country } = this.props.formVal || {};
    if (this.props.isValidPhone) {
      this.props.dispatch(
        userAppLinkValidate({
          Json: JSON.stringify({
            Client: [
              {
                PhoneNumber: getPhoneNumber(primaryCN),
                Email: email,
                FirstName: firstName,
                LastName: lastName,
                CountryISOCode: country,
              },
            ],
          }),
        }),
      );
    }
  };
  sendAppInvit = () => {
    const { firstName, lastName, primaryCN, email, country } = this.props.formVal || {};
    this.props.dispatch(
      appInviteDashboard({
        MobileNumber: getPhoneNumber(primaryCN),
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        CountryCode: country,
      }),
    );
    // this.props.dispatch(
    //   userAppLinkValidate({
    //     Json: JSON.stringify({
    //       Client: [
    //         {
    //           PhoneNumber: primaryCN && primaryCN.replace(/\D/g, ''),
    //           Email: email,
    //           FirstName: firstName,
    //           LastName: lastName,
    //           CountryISOCode: country,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  };
  render() {
    let me = this;
    return (
      <React.Fragment>
        <PageTitle TitleText="Prospect" />
        <ReduxPreIntakeForm
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.state.studentData}
          onCancel={() => this.props.history.goBack()}
          getIntakeId={this.getIntakeId()}
          isValidPhone={this.props.isValidPhone}
          sendAppInvite={() => {
            me.sendAppInvit();
          }}
          checkAppStatus={() => {
            me.checkAppStatus();
          }}
        />

        {/* <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            if (
              this.state.enrollStatus.enrollStatusId === 3 &&
              this.state.enrollStatus.PreSurveyId
            ) {
              this.props.history.push(
                `/client-screening/?studentid=${this.state.enrollStatus.studentId}&surveyid=${this.state.enrollStatus.PreSurveyId}`,
              );
              this.toggle();
            } else {
              this.props.history.push(
                '/enrollment/?studentid=' + this.state.enrollStatus.studentId,
              );
            }
          }}
          yesText="OK"
          hideCancel={true}
        /> */}

        <WarningComponent
          dirtyFlag={this.props.isDirty}
          submitSucceeded={this.props.submitSucceeded}
        />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

let PreIntakeEnrollForm = ({
  onSaveClick,
  onSubmit,
  handleSubmit,
  initialize,
  getIntakeId,
  initialValues,
  onCancel,
  Delete,
  history,
  dispatch,
  change,
  ReferenceSource,
  TherapyTypes,
  Country,
  Filter_State,
  sendAppInvite,
  checkAppStatus,
  isValidPhone,
  inviteStatus,
  PreIntakeClientStatus,
  PreIntakeRelationship,
  whoIsCallingValue,
  isFetching,
  NoteHistory,
  FeatureFlags: { enableDictation },
  presetNotes,
}) => {
  const handleDictate = result => {
    let data;
    if (result) {
      if (presetNotes) {
        data = `${presetNotes} ${result}`;
        change('preIntakeNotes', data);
      } else {
        data = result;
        change('preIntakeNotes', data);
      }
    }
  };

  const handleClear = () => {
    if (presetNotes) {
      change('preIntakeNotes', null);
    }
  };
  return (
    <div class="main enrollment-page mb-5">
      <div className="container-fluid">
        <Loader2 loading={isFetching} />
        <form onSubmit={handleSubmit(onSaveClick)}>
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <a
                class="back-link d-flex"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/clients/pre-intake-legacy`)}
              >
                <img src="images/back-arrow.svg" className="mr-2" /> Back to List
              </a>
              <h4 class="form-main-head">{getIntakeId > 0 ? 'Edit Prospect' : 'New Prospect'}</h4>
            </div>
            <div class="col-12 col-md-8 col-lg-9 text-right">
              <input
                type="button"
                class=" btn btn-outline-primary"
                value="Cancel"
                onClick={onCancel}
              />
              &nbsp;
              <input
                type="submit"
                class="btn"
                value="Save & Next"
                //onClick={onSaveClick}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-2">
              <div class="card border-0 rounded-0">
                <div class="card-body enrollment-form">
                  <div class="row py-2 px-3">
                    <div class="col-12 mb-3">
                      <h6>Who is calling?</h6>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`whoIsCalling`}
                          type="text"
                          component={ReduxSelect}
                          placeholder="Who is calling?"
                          options={PreIntakeRelationship}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-2">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form">
                  {false && whoIsCallingValue === 1 && (
                    <div class="row pb-3 pl-3 pr-3 pt-3">
                      <div class="col-12">
                        <h6>Concerned Person Details</h6>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`RelationtoClient`}
                            type="text"
                            component={ReduxSelect}
                            placeholder="Relation to Client"
                            options={ReferenceSource}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`firstName`}
                            type="text"
                            component={ReduxInput}
                            validate={validate.required}
                            fieldName={'First Name'}
                            // disabled={disable}
                          />
                          <label class="placeholder-label">First Name*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`middleName`}
                            type="text"
                            component={ReduxInput}
                            // disabled={disable}
                          />
                          <label class="placeholder-label">Middle Name</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`lastName`}
                            type="text"
                            component={ReduxInput}
                            validate={validate.required}
                            fieldName={'Last Name'}
                            // disabled={disable}
                          />
                          <label class="placeholder-label">Last Name*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group w-100">
                          <Field
                            name={`primaryCN1`}
                            type="text"
                            component={ReduxInput}
                            validate={[validate.isValidMobile, validate.required]}
                            fieldName={'Phone1'}
                            onBlur={checkAppStatus}
                            // validate={validate.allowNumericMobile}

                            // disabled={disable}
                          />
                          <label class="placeholder-label">Phone*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`email`}
                            type="text"
                            component={ReduxInput}
                            validate={[validate.email]}
                            fieldName={'Email'}
                            // disabled={disable}
                          />
                          <label class="placeholder-label">E-mail</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`line1`}
                            type="text"
                            component={ReduxInput}
                            // disabled={disable}
                          />
                          <label class="placeholder-label">
                            Permanent Address Line(Last Known Address)
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div class="row pb-3 pl-3 pr-3 pt-3">
                    <div class="col-12 mb-3">
                      <h6>Client details</h6>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`firstName`}
                          type="text"
                          component={ReduxInput}
                          validate={validate.required}
                          fieldName={'First Name'}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">First Name*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`middleName`}
                          type="text"
                          component={ReduxInput}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">Middle Name</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`lastName`}
                          type="text"
                          component={ReduxInput}
                          validate={validate.required}
                          fieldName={'Last Name'}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">Last Name*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group w-100">
                        <Field
                          name={`primaryCN`}
                          type="text"
                          component={ReduxPhoneInput}
                          validate={[validate.isValidMobile, validate.required]}
                          fieldName={'Phone'}
                          // onBlur={checkAppStatus}
                          // disabled={disable}
                        />
                        <label class="placeholder-label" style={{ top: '-5px', fontSize: '14px' }}>
                          Phone*
                        </label>
                        {isValidPhone && inviteStatus && (
                          <span class="send-app-invite" onClick={sendAppInvite}>
                            {inviteStatus.Msg || 'SEND APP INVITE '}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`email`}
                          type="text"
                          component={ReduxInput}
                          validate={[validate.email]}
                          fieldName={'Email'}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">E-mail</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`line1`}
                          type="text"
                          component={ReduxInput}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">Address</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`city`}
                          type="text"
                          component={ReduxInput}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">City</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="country"
                          type="text"
                          errorMessage="Country Required"
                          component={ReduxSelect}
                          options={Country}
                          placeholder="Country"
                          fieldName={'Country'}
                          // disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`stateOrProvince`}
                          type="text"
                          component={ReduxSelect}
                          options={Filter_State}
                          placeholder="State/Province/Territories"
                          fieldName="State/Province/Territories"
                          // disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`zip`}
                          type="text"
                          component={ReduxInput}
                          //  disabled={disable}
                          // validate={validate.usaZip}
                          // validate={[validate.usaZip, validate.required]}
                        />
                        <label class="placeholder-label">Postal/Zip Code</label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`referenceSourceId`}
                          type="text"
                          component={ReduxSelect}
                          options={ReferenceSource}
                          placeholder="Referral Source"

                          // disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`therapyId`}
                          type="text"
                          component={ReduxSelect}
                          options={TherapyTypes}
                          placeholder="What Type of Therapy Are You Looking For?"

                          // disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-8">
                      <div class="form-group">
                        <Field
                          name="preIntakeNotes"
                          class="form-control textarea-box"
                          type="textarea"
                          rows="4"
                          component={ReduxTextarea}
                          style={{
                            minHeight: '100px',
                          }}
                        />
                        <label class="placeholder-label">Notes</label>
                      </div>
                      {enableDictation && (
                        <div
                          className="dictation-space"
                          style={{ display: 'inline-flex', paddingRight: '0', width: '100%' }}
                        >
                          <DictationComponent handleDictate={handleDictate} />
                          <button type="button" className="clearButton" onClick={handleClear}>
                            <img src="images/clear_icon.svg" />
                            <p style={{ marginBottom: '0', marginLeft: '7px', color: '#ff7875' }}>
                              Clear
                            </p>
                          </button>
                        </div>
                      )}
                    </div>

                    {NoteHistory && (
                      <>
                        <div class="col-12 mt-3">
                          <h6>Previous Notes</h6>
                        </div>
                        <div class="col-12">
                          <div class="note-history mb-2">
                            {NoteHistory.map(notes => (
                              <div class="col-12 note-section mb-2">
                                <div className="updated-by">
                                  <span className="date-time">{notes.UpdatedOn}</span>
                                  <div className="added-by">
                                    <span className="added-text mr-2">Updated By</span>
                                    <img src={notes.ProfileImageUrl} />
                                    <div className="name-box">
                                      <span className="name">{notes.StaffName}</span>
                                      <span className="role">{notes.Role}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="note">
                                  <span>{notes.PreIntakeNotes}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    <div class="col-12">
                      <div class="form-group">
                        <Field
                          className="mr-2"
                          name="isIntake"
                          component={ReduxCheckbox}
                          fieldName={'isIntake'}
                        />
                        <label class="placeholder-label" style={{ top: '5px' }}>
                          Convert this prospect client to intake
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 mb-2">
              <div class="card border-0 rounded-0">
                <div class="card-body enrollment-form">
                  <div class="row py-2 px-3">
                    <div class="col-12 mb-3">
                      <h6>Status of Client</h6>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`preIntakeClientStatus`}
                          type="text"
                          component={ReduxSelect}
                          placeholder="Select Status of Client"
                          options={PreIntakeClientStatus}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    combos,
    user_app_link_validate,
    user_feature_flag_get,
    student_add,
    pre_intake_history_get,
  } = state;
  const { isFetching } = student_add;
  const { data: HistoryData, isFetching: isHistoryFetching } = pre_intake_history_get;
  const { data: fFData } = user_feature_flag_get;
  let countryISO = selector(state, 'country');
  let whoIsCallingValue = selector(state, 'whoIsCalling');
  let presetNotes = selector(state, 'preIntakeNotes');
  let NoteHistory = null;
  let inviteStatus = null;
  let FeatureFlags = {};
  let ReferenceSource = [],
    Country = [],
    Filter_State = [],
    PreIntakeRelationship = [],
    PreIntakeClientStatus = [],
    TherapyTypes = [];
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
  }
  if (combos.data && combos.data.ReferenceSource) {
    ReferenceSource = combos.data.ReferenceSource;
    Country = combos.data.Country;
    Filter_State = combos.data.Filter_StateProvince[countryISO];
    PreIntakeRelationship = combos.data.PreIntakeRelationship;
    PreIntakeClientStatus = combos.data.PreIntakeClientStatus;
    TherapyTypes = combos.data.ClientTherapy;
  }
  if (user_app_link_validate.data && user_app_link_validate.data) {
    inviteStatus = user_app_link_validate.data;
  }
  if (HistoryData && HistoryData.PreIntakeNotes) {
    NoteHistory = HistoryData.PreIntakeNotes;
  }
  return {
    ReferenceSource: ReferenceSource,
    Country: Country,
    Filter_State: Filter_State,
    inviteStatus,
    PreIntakeClientStatus,
    PreIntakeRelationship,
    TherapyTypes,
    whoIsCallingValue,
    isFetching,
    NoteHistory,
    isHistoryFetching,
    FeatureFlags,
    presetNotes,
  };
};

const ReduxPreIntakeForm = reduxForm({
  form: 'intakeform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(PreIntakeEnrollForm)));
const selector = formValueSelector('intakeform');

const mapStateToPropsParent = state => {
  const { student_get, student_add, default_values, app_invite_dashboard } = state;
  const { data, isFetching, error } = student_get;

  let formVal = selector(state, 'country', 'firstName', 'lastName', 'primaryCN', 'email');
  let primaryCNValue = selector(state, 'primaryCN');
  let isValidPhone = false;
  let studentInfo = [];
  var isInserted = false;
  var isInvitationSent = false;
  let defaultCenter = null;
  let enrollStatus = null;
  if (default_values && default_values.data) {
    defaultCenter = default_values.data.centerId;
  }
  if (data) {
    studentInfo = data;
  }
  if (student_add && student_add.data && student_add.data.success) {
    enrollStatus = student_add.data;
    isInserted = true;
  }
  if (app_invite_dashboard.data && app_invite_dashboard.data.Success) {
    isInvitationSent = app_invite_dashboard.data.Success;
  }
  if (primaryCNValue && primaryCNValue.length > 2) {
    let isValidMobile = isValidMobileNumber(primaryCNValue);
    if (isValidMobile === true) {
      isValidPhone = true;
    } else {
      isValidPhone = false;
    }
  }

  return {
    defaultCenter,
    studentInfo,
    isInserted,
    enrollStatus,
    isInvitationSent,
    formVal: formVal,
    isDirty: isDirty('intakeform')(state),
    submitSucceeded: get(state, 'form.intakeform.submitSucceeded', false),
    isValidPhone,
    primaryCNValue,
  };
};

export default connect(mapStateToPropsParent)(PreIntakeForm);
