import React, { useState, useEffect } from 'react';
import { ReduxSelect } from '../../../../../../../component/custom-redux-components/index.js';
import _blank from '../../../../../../../images/blank-profile.png';
import { ProfileInOption } from '../../../../../../../Components/profileInOption.js';
export const AssignPaymentStatus = props => {
  const { onChange, recordData, paymentStatusCombo, rowIndex } = props;
  return (
    <div
      className={
        recordData.PaymentStatusId == 1
          ? 'form-group assign-payment-status-green mb-0'
          : 'form-group assign-payment-status-red mb-0'
      }
      style={{
        borderBottom:"unset",
        border: '1px solid hsl(0, 0%, 80%)',
        paddingLeft: "5px",
        borderRadius:"5px"
      }}
    >
      <ReduxSelect
        options={paymentStatusCombo}
        // formatOptionLabel={careOption => (
        //   <ProfileInOption OptionData={careOption} isValue={true} valueText="Provider ID" />
        // )}
        onChange={value => {
          onChange(value, recordData, rowIndex);
        }}
        className="basic-single"
        // class={`basic-single form-control ${counsellor && 'not-empty'}`}
        classNamePrefix="select"
        value={recordData.PaymentStatusId}
        //   placeholder="Counselor"
      />
    </div>
  );
};
