import React from 'react';
import { Checkbox } from 'antd';
const CheckboxGroup = Checkbox.Group;
const plainOptionsTest = ['Graduated/Completed', 'Discharged', 'Enrolled', 'Intake', 'Pre-Intake'];

const AntdCheckbox = ({
  indeterminateTest,
  value,
  onChange,
  label,
  checkedChildren,
  unCheckedChildren,
  disabled,
  // plainOptionsTest,
  checkedListTest,
  onCheckboxChange,
}) => (
  <>
    <Checkbox indeterminate={indeterminateTest} checked={value} onChange={onChange}>
      All
    </Checkbox>
    <CheckboxGroup options={plainOptionsTest} value={checkedListTest} onChange={onCheckboxChange} />
  </>

  // <Checkbox
  //   indeterminate={indeterminateTest}
  //   // onChange={this.onCheckAllChange}
  //   // checked={this.state.checkAllTest}
  //   checked={value}
  //   onChange={onChange}
  //   size="small"
  //   checkedChildren="Yes"
  //   unCheckedChildren="No"
  //   disabled={disabled}
  //   className="redux-checkbox"
  // >
  //   {label}
  // </Checkbox>
);

class ReduxCheckboxAll extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const {
      input,
      name,
      id,
      value,
      onChange,
      onBlur,
      placeholder,
      defaultValue,
      meta,
      className,
      disabled,
      tabIndex,
      label,
    } = this.props;
    var { touched, error, warning } = meta || {};
    // if (input) {
    //   defaultValue = input.defaultValue;
    //   value = input.value;
    //   onChange = input.onChange;
    //   onBlur = input.onBlur;
    // }
    return (
      <div style={{ display: 'inline-block', marginRight: '10px' }}>
        <AntdCheckbox
          {...input}
          className={`d-flex align-items-center ${className}`}
          value={value}
          checked={value}
          label={label}
          name={name}
          id={id}
          // className={className}
          disabled={!!disabled}
        />
        {touched &&
          ((error && <span className="required-color-red">{error}</span>) ||
            (warning && <span className="warning-color">{warning}</span>))}
      </div>
    );
  }
}

export default ReduxCheckboxAll;
