import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgetPassword } from '../store/actions';
import CustomModal from '../core/modal';
import Loader from '../Components/Loader';
import hostGrabber from '../util/hostGrabber';

class ForgotPass extends Component {
  state = {
    email: '',
    modal: false,
    content: '',
    error: '',
  };

  onClick() {
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let reg = /^([A-Za-z0-9._%+\-+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.state.email)) {
      this.props.dispatch(
        forgetPassword({
          email: this.state.email,
        }),
      );
    } else {
      this.setState({ error: 'You have entered an invalid email address!' });
      return false;
    }
  }

  componentWillReceiveProps({ isSuccess, errorMessage }) {
    if (isSuccess) {
      this.setState({
        modal: true,
        content: 'An email has been sent with password reset instructions.',
      });
    }
    if (errorMessage) {
      this.setState({ error: errorMessage });
    }
  }
  enterButton = event => {
    if (event.key == 'Enter') {
      this.onClick();
    }
  };
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const { modal, content, error } = this.state;
    const { isFetching } = this.props;
    return (
      <React.Fragment>
        <div class="login-page">
          <Loader loading={isFetching} />
          <div class="container-fluid">
            <div class="col-12 col-md-5 col-lg-4 offset-md-1">
              <div class="login-box-main">
                <figure class="login-logo">
                  <img
                    src={hostGrabber() ? 'images/youu_Logo.svg' : 'images/logo.svg'}
                    width={hostGrabber() ? '250' : '120'}
                    alt=""
                  />
                </figure>
                <h5 class="font-weight-semibold mb-3 mb-md-5">
                  Enter your email id to retrieve the password.
                </h5>
                <form class="login-form">
                  <div class="form-group mb-4">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="yourname@domain.com"
                      onChange={event => (this.state.email = event.target.value)}
                      onKeyPress={this.enterButton}
                    />
                    {error && <span className="text-danger">{error}</span>}
                  </div>
                  <input
                    type="button"
                    class="btn btn-login"
                    value="SUBMIT"
                    onClick={this.onClick.bind(this)}
                  />
                </form>
                {this.state.modal && (
                  <CustomModal
                    isOpen={this.state.modal}
                    header={content}
                    yesAction={() => {
                      this.toggle();
                      this.props.history.push('/');
                    }}
                    yesText="OK"
                    hideCancel={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ forget_password }) => {
  const { data, isFetching, error } = forget_password;
  var isSuccess = false;
  var errorMessage = '';

  if (data) {
    if (data.success) {
      isSuccess = true;
    }
    if (data.error) {
      errorMessage = data.error;
    }
  } else if (error) {
    errorMessage = error.Message;
  }

  return {
    isSuccess: isSuccess,
    errorMessage: errorMessage,
    isFetching,
  };
};

export default connect(mapStateToProps)(ForgotPass);
