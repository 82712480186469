import React, { Component } from 'react';
import renderHTML from 'react-render-html';

class UnSupported extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { survey, sequenceNum } = this.props;

    const { Question, IsArchived } = survey;

    return (
      <div className="type-border col-12 mb-3 toggle-radio">
        <div className="d-flex justify-content-between align-items-start pb-2">
          <div className="">
            {Question && Question.length > 0 && (
              <h5 className="survey-question rcm-question text-left mb-2">
                {`${sequenceNum + 1}.`} {renderHTML(Question)}
              </h5>
            )}

            <div className="d-flex align-items-center" style={{ marginTop: '1em' }}></div>
          </div>
          <div>
            <span>Invalid Type Question</span>
          </div>
        </div>
      </div>
    );
  }
}
export default UnSupported;
