import React, { useState, useEffect } from 'react';
import { Collapse, Button, Drawer, Divider, Space, Tag, Tooltip } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { Field, reduxForm, formValueSelector, submit, reset, FieldArray } from 'redux-form';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  ReduxSelect,
  ReduxInput,
  ReduxTextarea,
  ReduxDateInput,
} from '../../../../../../component/custom-redux-components';
import validate from '../../../../../../core/generic-form/validation';
import ReduxGoals from './goals';
import ReduxTasks from './tasks';
import { smartContractUpdate } from '../../../../../../store/actions';
import getParam from '../../../../../../util/getParam';
import { ProcessCombos } from '../../../../../../store/comboConfig';
const { Panel } = Collapse;

const AddRemarkForm = props => {
  const [remarkSubmit, setRemarkSubmit] = useState(null);
  const [textAreaInputData, setTextAreaInputData] = useState(null);
  const { handleSubmit, drawerData, onClose } = props;
  const { Milestone } = drawerData && drawerData.milestoneData;
  const dispatch = useDispatch();

  const { remarkFieldData } = useSelector(state => {
    return {
      remarkFieldData: formValueSelector('addRemarkFormMilestone')(state, 'remarks'),
    };
  }, shallowEqual);

  useEffect(() => {
    if (remarkSubmit != null) {
      dispatch(submit('addRemarkFormMilestone'));
      setRemarkSubmit(null);
    }
  }, [remarkSubmit]);

  useEffect(() => {
    setTextAreaInputData(remarkFieldData);
  }, [remarkFieldData]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Milestone
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {Milestone}
          </span>
        </div>
      </div>
      <div class="col-12 enrollment-form mt-3">
        <div class="form-group">
          <Field
            name="remarks"
            type="text"
            errorMessage="remark Required"
            component={ReduxTextarea}
            validate={validate.required}
            fieldName={'Remarks'}
          />
          <label class="placeholder-label">Add Remarks*</label>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        // data-dismiss="modal"
        onClick={() => {
          setRemarkSubmit('Remarks');
          onClose();
        }}
        disabled={!textAreaInputData}
      >
        Add Remarks
      </button>
    </form>
  );
};

const ReduxAddRemarkForm = reduxForm({
  form: 'addRemarkFormMilestone',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    const { drawerData } = props;
    const {
      Description,
      Duration,
      IsCompleted,
      MeasureCreatedOn,
      Milestone,
      SmartContractMeasureId,
      StatusId,
      StatusText,
      Summary,
      SurveyId,
      SurveyQuestionCategoryId,
      Task,
      TotalScore,
    } = drawerData && drawerData.milestoneData;

    let data = {
      SmartContract: [
        {
          SmartContractMeasureId: SmartContractMeasureId,
          Remarks: values.remarks,
          IsCompleted: drawerData.IsCompleted,
        },
      ],
    };
    dispatch(
      smartContractUpdate({
        json: JSON.stringify(data),
        markAsComplete: true,
      }),
    );
  },
})(connect()(withRouter(AddRemarkForm)));

const Milestone = props => {
  const dispatch = useDispatch();
  const SMART_CONTRACT_ID = getParam(props.history, 'smartContractId') || null;
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState();

  const { DurationFrequencyCombo, isFetching } = useSelector(({ combos }) => {
    return {
      DurationFrequencyCombo: combos && combos.data['DurationFrequency'],
      isFetching,
    };
  }, shallowEqual);

  const {
    fields,
    smartContractMeasure,
    mesuareValues,
    initialValues,
    goalLibrary,
    goalTimePeriod,
    goalIndex,
    existingRows,
  } = props;

  const onChange = key => {
    // console.log(key);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getBaseLineScore = index => {
    let baseLineScore =
      initialValues &&
      initialValues[index] &&
      initialValues[index].SurveyQuestionCategoryId &&
      smartContractMeasure.filter(
        item => item.Value == initialValues[index].SurveyQuestionCategoryId,
      )[0] &&
      smartContractMeasure.filter(
        item => item.Value == initialValues[index].SurveyQuestionCategoryId,
      )[0].BaselineScore;
    return baseLineScore;
  };

  const showDrawer = (milestoneData, IsCompleted) => {
    setOpen(true);
    setDrawerData({ milestoneData, IsCompleted });
  };

  useEffect(() => {
    // var result = ProcessCombos('DurationFrequency');
    // if (result.isProcess) {
    //   dispatch(result.processItems);
    // }
    if (props.fields && props.fields.length < 1) {
      props.fields.push();
    }
  }, []);

  return (
    <>
      <div className="row buildServiceBundle">
        <div className="col-12 col-md-12 col-lg-12 my-1">
          <div className="">
            <Collapse
              // defaultActiveKey={['0']}
              // expandIcon={({ isActive }) => (
              //   <RightCircleOutlined
              //     rotate={isActive ? -90 : 90}
              //     style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
              //   />
              // )}
              defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
              onChange={onChange}
            >
              {fields.length > 0 ? (
                fields.map((item, index) => {
                  const { StatusId, MeasureStatusText, IsCompleted, SmartContractMeasureId } =
                    (existingRows && existingRows[index]) || {};

                  return (
                    <>
                      <Panel
                        showArrow={true}
                        key={index}
                        className="milestone-panel-border"
                        header={
                          <>
                            <div className="row">
                              <div className="col-md-3 panel-header-text">{`Milestone ${index +
                                1}`}</div>
                              <div className="col-md-9 text-right d-flex">
                                {!props.disabled && (
                                  <button
                                    type="button"
                                    class="btn-newCon"
                                    style={{
                                      position: 'unset',
                                      width: 'unset',
                                      background: 'unset',
                                    }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      fields.push();
                                    }}
                                  >
                                    + Add {fields.length == 0 ? 'Milestone' : 'Another Milestone'}
                                  </button>
                                )}
                                {fields.length > 1 && (
                                  <div>
                                    {!props.disabled && (
                                      <a
                                        class="col-1 delete-contract"
                                        onClick={() => {
                                          if (fields.length && fields.length > 0) {
                                            fields.remove(index);
                                          }
                                        }}
                                      >
                                        <i class="fas fa-trash" />
                                      </a>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        }
                      >
                        <div className="mt-3 d-none">
                          {' '}
                          {SMART_CONTRACT_ID && SmartContractMeasureId && !StatusId ? (
                            <>
                              {' '}
                              <div
                                className={
                                  SMART_CONTRACT_ID ? 'col-12 text-right' : 'col-12 text-right'
                                }
                              >
                                <div
                                  className="btn-deny"
                                  style={{
                                    display: 'inline-block',
                                    cursor: 'pointer',
                                    margin: '0px',
                                  }}
                                  onClick={() => {
                                    showDrawer(existingRows[index], '2');
                                  }}
                                >
                                  Mark as Failed
                                </div>
                                <div
                                  className="btn-approved"
                                  style={{ display: 'inline-block' }}
                                  onClick={() => {
                                    showDrawer(existingRows[index], '1');
                                  }}
                                >
                                  Mark as Completed
                                </div>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                          <>
                            {SMART_CONTRACT_ID && StatusId && MeasureStatusText ? (
                              <>
                                <div className="col-12 text-right d-flex align-items-center justify-content-end ">
                                  <span style={{ marginBottom: '29px' }}>
                                    <Tag
                                      icon={
                                        IsCompleted ? (
                                          <CheckCircleOutlined />
                                        ) : (
                                          <CloseCircleOutlined />
                                        )
                                      }
                                      color={IsCompleted ? 'success' : 'error'}
                                    >
                                      <Tooltip title="Service Status">{MeasureStatusText}</Tooltip>
                                    </Tag>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        </div>

                        <div
                          class="mx-4"
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          {' '}
                          <div class="row p-2">
                            <div className={SMART_CONTRACT_ID ? 'col-12' : 'col-12'}>
                              <div class="form-group">
                                <Field
                                  name={`${item}Milestone`}
                                  type="text"
                                  component={ReduxInput}
                                  // component={ReduxSelect}
                                  // options={smartContractMeasure}
                                  validate={validate.required}
                                  // placeholder={`Milestone ${index + 1}*`}
                                  fieldName={'Milestone'}
                                  class="form-control"
                                  disabled={props.disabled}
                                  style={{ background: 'unset' }}
                                />
                                <label class="placeholder-label">{`Milestone ${index + 1}*`}</label>
                              </div>
                            </div>

                            {/* <div className="col-3 d-flex align-items-center">
                                {getBaseLineScore(index) != undefined && (
                                  <span
                                    className="p-2"
                                    style={{
                                      border: '1px solid grey',
                                      background: 'white',
                                      color: 'black',
                                      fontSize: '16px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    Baseline Score:{' '}
                                    <span style={{ fontWeight: 700 }}>
                                      {getBaseLineScore(index)}
                                    </span>
                                  </span>
                                )}
                              </div> */}
                          </div>
                        </div>

                        <div className="mx-4">
                          <div className="row p-2">
                            <div className="col-6">
                              <div class="form-group">
                                <Field
                                  name={`${item}StartDate`}
                                  type="text"
                                  component={ReduxDateInput}
                                  validate={validate.required}
                                  fieldName={'Start Date'}
                                  label="Start Date*"
                                  minDate={new Date()}
                                  // disabled={
                                  //   props.initialValues && props.initialValues.IsContractCreated
                                  // }
                                  disabled={props.disabled}
                                />
                                {/* <Field
                                  name={`${item}Duration`}
                                  type="number"
                                  component={ReduxInput}
                                  // component={ReduxSelect}
                                  // options={smartContractMeasure}
                                  validate={validate.required}
                                  // placeholder={`Milestone ${index + 1}*`}
                                  fieldName={'Duration'}
                                  className="form-control numbers-only"
                                  disabled={props.disabled}
                                  style={{ background: 'unset' }}
                                  disableOnPaste={true}
                                />
                                <label class="placeholder-label">{`Duration*`}</label> */}
                              </div>
                            </div>
                            <div className="col-6">
                              <div class="form-group">
                                <Field
                                  name={`${item}EndDate`}
                                  type="text"
                                  component={ReduxDateInput}
                                  validate={[validate.required]}
                                  fieldName={'End Date'}
                                  label="Expected End Date*"
                                  minDate={new Date()}
                                  // disabled={
                                  //   props.initialValues && props.initialValues.IsContractCreated
                                  // }
                                  disabled={props.disabled}
                                />
                                {/* <Field
                                  name={`${item}FrequencyId`}
                                  type="text"
                                  component={ReduxSelect}
                                  options={DurationFrequencyCombo}
                                  validate={validate.required}
                                  placeholder={`Frequency*`}
                                  fieldName={'Frequency'}
                                  class="form-control"
                                  disabled={props.disabled}
                                /> */}
                                {/* <label class="placeholder-label">Select Consumable</label> */}
                                {/* <span class="small-info">Provide a specific list of goal(s) to be achieved</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <FieldArray
                          name={`${item}Task`}
                          component={ReduxTasks}
                          taskIndex={index}
                          goalIndex={goalIndex}
                          goalLibrary={goalLibrary}
                          SmartContractId={props.SmartContractId}
                          isMeasuresPresent={props.initialValues && props.initialValues[index]}
                          initialValues={
                            props.initialValues &&
                            props.initialValues[index] &&
                            props.initialValues[index].Task
                          }
                          goalTimePeriod={goalTimePeriod}
                          disabled={props.disabled}
                          existingRows={
                            existingRows && existingRows[index] && existingRows[index].Task
                          }
                        />
                      </Panel>
                    </>
                  );
                })
              ) : (
                <div class="row"></div>
              )}
            </Collapse>
          </div>
        </div>
      </div>
      <Drawer
        title="Add Remarks"
        placement="right"
        onClose={onClose}
        open={open}
        width="600"
        destroyOnClose={true}
      >
        <ReduxAddRemarkForm drawerData={drawerData} onClose={onClose} />
      </Drawer>
    </>
  );
};

const ReduxMilestone = withRouter(Milestone);
export default ReduxMilestone;
