export const isComparer = (v1, v2) => {
  if (!v1) {
    return -1;
  } else if (!v2) {
    return 1;
  }
  if (v1.trim) {
    return v1.trim().localeCompare(v2.trim());
  } else {
    return v1.localeCompare(v2);
  }
};

export const isNumericComparer = (v1, v2) => {
  if (!v1) {
    return -1;
  } else if (!v2) {
    return 1;
  }

  if (v1 > v2) {
    return 1;
  } else if (v1 == v2) {
    return 0;
  } else {
    return -1;
  }
};

export const isDateComparer = (v1, v2) => {
  if (!v1) {
    return -1;
  }

  if (!v2) {
    return 1;
  }

  var date1 = new Date(v1).getTime();
  var date2 = new Date(v2).getTime();

  if (date1 > date2) {
    return 1;
  } else if (date1 == date2) {
    return 0;
  } else {
    return -1;
  }
};

export const isDateSort = (a, b, order) => {
  if (order === 'asc') {
    return Date.parse(a) - Date.parse(b);
  } else if (order === 'desc') {
    return Date.parse(b) - Date.parse(a);
  }
};
