import React, { Component } from 'react';
import ListRenderer from '../Components/listRenderer';
import { Table, Slider } from 'antd';
import { Line, Bar } from 'react-chartjs-2';
import CustomModal from '../core/modal';
import AntdModal from '../Components/CustomModal';
import renderHTML from 'react-render-html';
import { isComparer, isNumericComparer } from '../util/handler';
import { connect } from 'react-redux';
import {
  getStudentSurveyDashboardData,
  resetSurveyAnswersGet,
  surveyAnswersGet,
} from '../store/actions';
import PageTitle from '../Components/PageTitle';
class OtherParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyanswersdata: [],
      activeKey: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.props.dispatch(getStudentSurveyDashboardData(this.getStudentId()));
    // document.getElementsByClassName('ant-tabs-nav-scroll')[0].style.justifyContent = 'normal';
  }

  componentWillUnmount() {
    // this.props.dispatch(resetSurveyAnswersGet());
  }

  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/otherParameter/', ''));
  }

  componentWillReceiveProps({ surveyanswersdata }) {
    if (surveyanswersdata.length > 0) {
      this.setState({ surveyanswersdata, modal: true });
    }
  }

  onrowclick = record => {
    this.props
      .dispatch
      // surveyAnswersGet({ studentId: this.getStudentId(), surveyId: record.SurveyId }),
      ();
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const {
      surveryData: { StudentImageUrl, FullName, FirstName, LastName, MiddleName },
      lineCharts,
      polarCharts,
      isFetching,
      reasonOptions,
      EnrollStatus,
      isDischarged,
      EnrollExitNote,
      timeSeriesCharts,
      stackedBarCharts,
    } = this.props;
    const {
      StudentData: { AdmissionDate },
      StudentMisc,
    } = this.props;
    const { activeKey } = this.state;
    const list = [
      {
        Name: 'Composite Measures',
        Component: props => {
          return (
            <div class="row">
              <div class="col-12">
                <div className="measure">
                  <div class="progress_bar">
                    <div class="progress_bar_label">
                      <span class="progress_bar_label_text">Addiction Index</span>
                    </div>
                    <Slider className="two-way-slider" value={[55, 90]} reverse={false} range />
                    <div class="indicator-text">
                      <span class="indicator-left-text">{!false ? 0 : 100}</span>
                      <span class="indicator-right-text">{!false ? 100 : 0}</span>
                    </div>
                  </div>
                  <div class="progress_bar">
                    <div class="progress_bar_label">
                      <span class="progress_bar_label_text">Faith</span>
                    </div>
                    <Slider className="two-way-slider" value={[60, 95]} reverse={true} range />
                    <div class="indicator-text">
                      <span class="indicator-left-text">{!true ? 0 : 100}</span>
                      <span class="indicator-right-text">{!true ? 100 : 0}</span>
                    </div>
                  </div>
                  <div class="progress_bar">
                    <div class="progress_bar_label">
                      <span class="progress_bar_label_text">Psychosocial</span>
                    </div>
                    <Slider className="two-way-slider" value={[20, 50]} reverse={true} range />
                    <div class="indicator-text">
                      <span class="indicator-left-text">{!true ? 0 : 100}</span>
                      <span class="indicator-right-text">{!true ? 100 : 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: [{}],
      },
      {
        Name: 'Other Measures',
        Component: props => {
          return (
            <div class="row">
              <div class="col-6">
                <div className="measure other_measure">
                  <div class="progress_bar">
                    <div class="progress_bar_label">
                      <span class="progress_bar_label_text">
                        Streaks (App usage without a break)
                      </span>
                    </div>
                    <Slider
                      value={80}
                      className="one-way-slider"
                      marks={{ 0: 0, 80: 80, 100: 100 }}
                    />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div className="measure other_measure">
                  <div class="progress_bar">
                    <div class="progress_bar_label">
                      <span class="progress_bar_label_text">Chat with Coach</span>
                    </div>
                    <Slider
                      value={70}
                      className="one-way-slider"
                      marks={{ 0: 0, 70: 70, 100: 100 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: [{}],
      },
    ];
    return (
      <div>
        <PageTitle TitleText="Students" />

        <div class="main students-page ">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 mb-4 pt-4">
                <div class="card border-0 students-profile ">
                  <div class="row card-body align-items-center">
                    <div class="col-12 col-xl-6 mb-3 mb-xl-0">
                      <div class="row">
                        <div class="col-12 col-sm-auto pr-sm-0 mb-3 mb-md-0 text-center">
                          <div class="EditImage">
                            <div
                              class="images-placeholder2"
                              style={{
                                backgroundImage: 'url(&quot;images/surveys-img1.jpg&quot;)',
                              }}
                            >
                              <img
                                src={StudentMisc.StudentImageUrl}
                                class="students-profile-img images-placeholder2"
                                alt=""
                                width="84"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-auto">
                          <h4 class="students-name">
                            {FirstName} {MiddleName} {LastName}
                          </h4>
                          <div class="students-action-button">
                            <button
                              // onClick={() => {
                              //   this.props.history.push(
                              //     `/subscriberViewProfile/?subscriberId=${this.getStudentId()}`,
                              //   );
                              // }}
                              title="View Profile"
                              class="btn black-btn"
                              // disabled={isDischarged}
                            >
                              View Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-xl-6">
                      <div class="row students-detail   text-md-left  justify-content-md-around">
                        <div class="col-4 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Age</span>
                          <h5 class="info-detail m-md-0">{StudentMisc.Age} Years</h5>
                        </div>
                        <div class="col-4 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Joining Date</span>
                          <h5 class="info-detail m-md-0">{AdmissionDate}</h5>
                        </div>
                        <div class="col-4 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Current Subscription</span>
                          <h5 class="info-detail m-md-0">${StudentMisc.CostofCareToDate}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 mb-3">
                <a onClick={() => this.props.history.goBack()} class="back-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    class="back-arrow"
                  >
                    <path
                      fill="#000"
                      fill-rule="nonzero"
                      d="M14.2 25.956a.886.886 0 0 1-.63-.261l-7.79-7.81a2.67 2.67 0 0 1 0-3.77l7.79-7.81a.889.889 0 0 1 1.259 1.256l-7.791 7.81a.89.89 0 0 0-.001 1.258l7.792 7.81a.889.889 0 0 1-.63 1.517zM27.755 16a.889.889 0 0 0-.89-.889H10.733a.889.889 0 1 0 0 1.778h16.135a.889.889 0 0 0 .889-.889z"
                    />
                  </svg>{' '}
                  Back
                </a>
              </div>
              <div class="col-12 mb-5">
                <div class="card border-0 " style={{ overflow: 'hidden' }}>
                  <div class="row card-body">
                    <div style={{ marginLeft: '20px' }} class="center-tabs w-100 otherParams">
                      {activeKey == 0 ? (
                        <div class="arrow">
                          <a class="starting">Starting</a>
                          <a class="current">Current</a>
                        </div>
                      ) : null}
                      <ListRenderer
                        list={list}
                        activeKey={activeKey}
                        getActiveKey={activeKey => this.setState({ activeKey })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  get_student_survery_dashboard_data,
  survey_answers_get,
  student_get_profile_dashboard_data,
}) => {
  var { data, isFetching, error } = get_student_survery_dashboard_data;
  var records = null;
  var surveryData = {};
  var lineCharts = [],
    timeSeriesCharts = [],
    stackedBarCharts = [];
  var polarCharts = [],
    surveyanswersdata = [],
    StudentData = {},
    StudentMisc = {},
    isDischarged = false;
  var EnrollStatus = null,
    EnrollExitNote = null;
  if (data) {
    surveryData = data;
    records = surveryData.SurveyList;
    polarCharts = surveryData.LadderPolarCharts;
    lineCharts = surveryData.SurveyLineChart;
    timeSeriesCharts = data.TimeSeriesCharts;
    stackedBarCharts = data.StackedBarCharts;
  }

  if (student_get_profile_dashboard_data.data) {
    StudentData = student_get_profile_dashboard_data.data;
    StudentMisc = StudentData.StudentMisc[0];
    EnrollStatus = StudentMisc.EnrollStatus;
    EnrollExitNote = StudentMisc.DischargeReason;
    isDischarged = EnrollStatus === 'Discharged';
  }

  if (survey_answers_get.data) {
    surveyanswersdata = Object.values(survey_answers_get.data);
  }
  return {
    records,
    surveryData,
    lineCharts,
    polarCharts,
    isFetching,
    surveyanswersdata,
    StudentData,
    StudentMisc,
    EnrollStatus,
    isDischarged,
    EnrollExitNote,
    timeSeriesCharts,
    stackedBarCharts,
  };
};
export default connect(mapStateToProps)(OtherParameters);
