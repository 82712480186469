import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import TextareaAutosize from 'react-autosize-textarea';

class TextareaType extends Component {
  constructor(props) {
    super(props);
    const {
      survey: { Response },
    } = this.props;
    this.state = {
      value: Response || '',
      Desc_chars_left: 0,
      Max_chars: 1000,
    };

    if (Response) {
      this.state.value = Response;
    }
  }

  onClick() {
    if (this.state.value) {
      const {
        survey: { SurveyQuestionId },
        setValue,
        scope,
      } = this.props;
      setValue.call(scope, { response: this.state.value, surveyQuestionId: SurveyQuestionId });
    }
    $('.main-banner').slick('slickNext');
  }

  // onChange = (event, click) => {
  //   const { value } = event.target;
  //   const {
  //     survey: { SurveyQuestionId, MaxValue, MinValue },
  //     setValue,
  //     scope,
  //   } = this.props;
  //   if (value) {
  //     if (value >= MinValue && value <= MaxValue) {
  //       this.setState({ value: value });
  //       setValue.call(scope, { response: value, surveyQuestionId: SurveyQuestionId });
  //     }
  //   } else {
  //     if (value >= MinValue && value <= MaxValue) this.setState({ value: value });
  //   }
  // };
  onChange = (event, click) => {
    const { value } = event.target;
    // const { SurveyQuestionId } = this.props.survey;
    let valueCount = value && value.length;
    if (valueCount <= this.state.Max_chars) {
      this.setState({
        value: value,
        Desc_chars_left: valueCount || 0,
      });
    }
  };
  componentWillReceiveProps(props) {
    const {
      survey: { Response },
    } = this.props;
    if (Response) {
      this.setState({ value: Response });
    }
  }

  render() {
    const {
      survey: {
        Question,
        DoesNotApplyText,
        DoesNotApplyValue,
        SurveyId,
        SurveyQuestionId,
        MaxValue,
        MinValue,
      },
      setValue,
      scope,
      index,
      total,
    } = this.props;
    return (
      <div
        className=" card border-0"
        // style={{ overflow: 'scroll', maxHeight: '400px' }}
      >
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <div className="d-flex flex-column">
            <h5 className="survey-question">
              {Question && renderHTML(Question)}
              {/* <span class="d-md-block">these psychological or emotional problems?</span> */}
            </h5>
            <div
              class="answer-options mb-4 "
              style={{
                // maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
                textAlign: 'left',
              }}
            >
              <div class="quantity-btn">
                <div class="print-textarea-component">
                  <TextareaAutosize
                    // disabled={isReadOnly}
                    style={{ paddingLeft: '10px', width: '100%', maxHeight: 180 }}
                    value={this.state.value}
                    id="question"
                    name="question"
                    rows="2"
                    className={this.state.value ? `textarea-type` : `print-time-textarea-novalue`}
                    onChange={this.onChange}
                  />
                  <div className="remaining-char">
                    <span>
                      {this.state.Desc_chars_left} / {this.state.Max_chars}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                // maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'absolute',
                bottom: '20px',
                textAlign: 'center',
                display: 'inline',
              }}
            >
              <button class="btn px-5" onClick={this.onClick.bind(this)} tabindex="0">
                Next
              </button>
            </div>
            {DoesNotApplyText && (
              <div className="not-apply-to-me">
                <a
                  name="current-situation"
                  value={DoesNotApplyText}
                  onClick={this.props.onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: DoesNotApplyValue,
                  })}
                >
                  {DoesNotApplyText}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TextareaType;
