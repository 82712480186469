import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  youuniverseConversationDelete,
  resetYouuniverseConversationDelete,
  youuniverseConversationGet,
} from '../../store/actions';
import AntdModal from '../../Components/CustomModal';

const YOUUniverseChatHistory = props => {
  const dispatch = useDispatch();
  const [listHistory, setListHistory] = useState([]);

  const { ConversationsList, isDeleted } = useSelector(
    ({
      youuniverse_conversation_get: { data },
      youuniverse_conversation_delete: { data: deleteData, isFetching, error },
    }) => {
      return {
        ConversationsList: data && data.data,
        isDeleted: deleteData && deleteData.success,
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (ConversationsList) {
      setListHistory(ConversationsList);
    }
  }, [ConversationsList]);
  useEffect(() => {
    if (isDeleted) {
      dispatch(youuniverseConversationGet());
      dispatch(resetYouuniverseConversationDelete());
    }
  }, [isDeleted]);

  useEffect(() => {
    if (props.isDeleteAll) {
      deleteConversation({}, true);
    }
  }, [props.isDeleteAll]);
  const deleteConversation = (history, isDeleteAll) => {
    dispatch(
      youuniverseConversationDelete({
        JSON: JSON.stringify({
          Conversation: [
            {
              ConversationGuid: history.ConversationGuid,
              IsDeleteAll: isDeleteAll,
            },
          ],
        }),
      }),
    );
  };
  return (
    <>
      <div className="row conversations-history">
        <div className="col-12">
          <ul className="conversations">
            {listHistory.map((history, index) => {
              return (
                <li className="conversations-list">
                  <div className="conversation-item">
                    <div
                      className="text"
                      onClick={() => {
                        props.getConversationsGuid(history);
                      }}
                    >
                      <img src="images/icons/conversations.svg" className="mr-2" />
                      <span className="">{history.Name || 'New Conversations'}</span>
                    </div>
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <div
                          className="action"
                          data-toggle="modal"
                          data-target="#delete-chat-model"
                          onClick={onClick}
                        >
                          <img src="images/deletetp.svg" />
                        </div>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to remove <br />
                              <strong>{history.Name}</strong> chat history?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button class="btn btn-outline-primary black-btn" onClick={onCancel}>
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                deleteConversation(history, false, index);
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* {listHistory.length > 0 && (
        <div className="row chat-history-footer">
          <div className="col-12 footer">
            <img src="images/deletetp.svg" />
            <span
              className="px-2"
              onClick={() => {
                deleteConversation({}, true);
              }}
            >
              Clear All Conversations
            </span>
          </div>
        </div>
      )} */}
    </>
  );
};

export default YOUUniverseChatHistory;
