import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm, reset, formValueSelector, FieldArray } from 'redux-form';
import moment from 'moment';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../core/dynamic-form';
import { gridFilterGet, resetGridFilterGet } from '../../store/actions';

class DynamicFilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: {},
    };
  }
  componentDidMount() {
    let tableName = `table_${this.props.ScreenName}`;
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[tableName]) {
      let existingFilters = JSON.parse(pageFilter)[tableName].filtersStored;
      this.setState({
        initData: { ...existingFilters },
      });
    }
    // this.props.dispatch(
    //   gridFilterGet({
    //     Json: JSON.stringify({
    //       ComponentGrid: [
    //         {
    //           Name: this.props.ScreenName,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }

  render() {
    const {
      onOk,
      onCancel,
      getCustomFilter,
      FilterFormData,
      ScreenName,
      isFetching,
      resetFilterData,
    } = this.props;

    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div class="m-0 filter-modal dynamic-grid-modal">
            <ReduxFilterForm
              {...this.props}
              onSubmit={values => {
                let valueArray = Object.keys(values);
                let obj = {};
                for (const key of valueArray) {
                  if (values[key] instanceof Date) {
                    obj[key] = moment(values[key]).format('MM/DD/YYYY');
                  } else if (values[key] != null && typeof values[key] == 'object') {
                    obj[key] =
                      values[key] && values[key].length > 0
                        ? values[key].map(item => item.Value).toString()
                        : null;
                  } else {
                    obj[key] = values[key];
                  }
                }
                getCustomFilter(obj);
                onOk();
              }}
              initialValues={this.state.initData}
              resetForm={() => {
                this.props.dispatch(reset('dynamicFilterForm'));
                this.setState({
                  initData: {},
                });
                resetFilterData();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

class FilterForm extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      onSubmit,
      handleSubmit,
      dispatch,
      history,
      disable,
      onCancel,
      FilterFormData,
      resetForm,
    } = this.props;

    return (
      <div className="main authorization-page mb-5">
        <div class="container-fluid px-0">
          <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '16px 20px 20px 20px' }}>
            <div class="enrollment-form">
              <div className="row">
                {FilterFormData && <DynamicForm formFields={FilterFormData} disable={disable} />}
              </div>
              <div class="row pagination-button pt-5" style={{ right: '40px' }}>
                <div class="col-12 text-right">
                  <a class="link2 font-weight-bold mx-5" onClick={resetForm}>
                    Reset
                  </a>
                  {/* <button
                    onClick={onCancel}
                    type="button"
                    class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  >
                    Cancel
                  </button> */}
                  <button type="submit" class="btn">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ grid_filter_get }) => {
  const { data, isFetching, error } = grid_filter_get;

  let FilterFormData;

  if (data && data.Filter) {
    FilterFormData = data.Filter;
  }
  return {
    FilterFormData,
    isFetching,
  };
};

const ReduxFilterForm = reduxForm({
  form: 'dynamicFilterForm',
  enableReinitialize: true,
  // onSubmitFail: errors => {
  //   const firstError = Object.keys(errors)[0];
  //   let el = document.querySelector(`[name="${firstError}"]`);
  //   if (el) {
  //     el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  // },
})(connect(mapStateToProps)(FilterForm));
// const selector = formValueSelector('dynamicFilterForm');

export default connect()(DynamicFilterModal);
