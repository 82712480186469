import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Checkbox, Radio, Modal } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { PopoverMenu } from '../../../Components/PopoverMenu';

class RecommendationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getPopOverData = record => {
    return (
      <>
        <p
          onClick={() => {
            this.props.history.push(record.OnClickToDashboard);
          }}
        >
          View Detail
        </p>
        <p
          onClick={() => {
            this.props.history.push(record.OnClick);
            if (record.OnClick.includes('pre-intake-form')) {
              window.location.reload();
            }
          }}
        >
          Edit Detail
        </p>
      </>
    );
  };
  render() {
    const { onCancel, recommendations, history } = this.props;
    const columns = [
      {
        title: 'Image',
        dataIndex: 'StudentImageUrl',
        key: 'StudentImageUrl',
        render: (text, record) => (
          <div class="student-list-pic">
            <img
              src={text ? text : 'images/blank.jpg'}
              class="img-circle"
              height="40"
              width="40"
              style={{ borderRadius: '50%' }}
            />
          </div>
        ),
      },
      {
        title: 'Id',
        dataIndex: 'StudentId',
        key: 'StudentId',
        sorter: (a, b) => isNumericComparer(a.StudentId, b.StudentId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Full Name',
        dataIndex: 'FullName',
        key: 'FullName',
        sorter: (a, b) => isComparer(a.FullName, b.FullName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Primary Phone',
        dataIndex: 'PrimaryPhone',
        key: 'PrimaryPhone',
        sorter: (a, b) => isComparer(a.PrimaryPhone, b.PrimaryPhone),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'DOB',
        dataIndex: 'DateofBirth',
        key: 'DateofBirth',
        sorter: (a, b) => isComparer(a.DateofBirth, b.DateofBirth),
        sortDirections: ['descend', 'ascend'],
        render: text => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        key: 'Email',
        sorter: (a, b) => isComparer(a.Email, b.Email),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'EnrollStatus',
        key: 'EnrollStatus',
        sorter: (a, b) => isComparer(a.EnrollStatus, b.EnrollStatus),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        dataIndex: 'Tags',
        key: 'Tags',
        render: (text, record) => {
          return (
            <div className="tools-grid mx-2" onClick={event => event.stopPropagation()}>
              <PopoverMenu
                OptionData={this.getPopOverData(record)}
                placement="left"
                trigger="hover"
              />
            </div>
          );
        },
      },
    ];
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-start flex-wrap  border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 className="modal-title col-12">
            Matched Record(s) <br />
            <small> The following client(s) match the searched criteria.</small>
          </h4>
        </div>
        <div className="modal-body enrollment-form">
          <div className="row mt-2">
            <div className="col-12">
              <Table
                dataSource={recommendations || []}
                columns={columns}
                bordered
                pagination={false}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" className="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(RecommendationsModal));
