// import EmotionGraph from './emotionCatGraph';

// export { EmotionGraph };

import React, { Component, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Drawer, Switch } from 'antd';
import EmotionGraph from './emotionCatGraph';
import TrendGraph from './trendChartJsGraph';
import AIAugmentedSummary from '../../customScreens/surveyResponse/AIAugmentedSummary';

function EmotionalAndTrendGraph(props) {
  const [isTrendGraph, setIsTrendGraph] = useState(false);
  const [isMoodDrawerOpen, setIsMoodDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    // this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
    setIsMoodDrawerOpen(prevState => !prevState);
  };
  const onGraphChange = checked => {
    setIsTrendGraph(checked);
  };
  const { AugmentedAIStatusData, isLicenseNotify } = props;
  return (
    <div className="emotion-graph-card">
      <div className="row">
        <div className="col-12">
          <div className="card border-0 p-3">
            <div className="row card-body">
              <div className="col-12 mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="emotion-graph-card-title">
                    <span className="emotion-graph-card-title-text">Emotional Trends</span>
                  </div>
                  <div className="d-flex">
                    <div className="emotion-graph-card-action d-flex align-items-center">
                      <Switch onChange={onGraphChange} />
                      <p className="mb-0" style={{ fontSize: '17px' }}>
                        Trend Graph
                      </p>
                    </div>
                    {AugmentedAIStatusData &&
                      AugmentedAIStatusData.LicenseStatusId != 1 &&
                      AugmentedAIStatusData.LicenseStatusId != 3 &&
                      AugmentedAIStatusData.LicenseStatusId != 5 && (
                        <img
                          src="images/gpt-ai.svg"
                          style={{ cursor: 'pointer' }}
                          onClick={toggleDrawer}
                          className="ml-3"
                        />
                      )}
                  </div>
                </div>
              </div>
              {isTrendGraph ? (
                <TrendGraph UserGuid={props.UserGuid} />
              ) : (
                <EmotionGraph UserGuid={props.UserGuid} />
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        className="custom-drawer journal-augmenting"
        title="Augment with AI"
        placement="right"
        open={isMoodDrawerOpen}
        width={800}
        bodyStyle={{ paddingBottom: 80 }}
        closable={true}
        destroyOnClose={true}
        onClose={() => {
          // this.props.dispatch(resetSurveyAnswersGet());
          toggleDrawer();
        }}
      >
        <AIAugmentedSummary
          toggleDrawer={toggleDrawer}
          isMoodAugment={true}
          UserGuid={props.UserGuid}
          // ColData={surveyanswersdatacol}
          // augmentId={this.state.augmentId}
          // changeAugmentId={changeAugmentId}
          // activekey={activekey}
          // UserGuid={props.UserGuid}
        />
      </Drawer>
    </div>
  );
}

export default EmotionalAndTrendGraph;
