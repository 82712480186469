import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { BGContent } from '../commonSection';
import { startFreeLicense, userFeatureFlagGet } from '../../../../store/actions';
const labels = [
  'Active/Inactive Clients',
  'Referal Sources',
  'Time In Treatment',
  'Demographic Profile',
  'Mapping',
  'Score Improvement',
  'SP Behavioral Ratings',
  'Standard Cost of Care',
  'Service Units Delivered',
  'Trigger Location Activity',
];

const Analytics = props => {
  const { StatusData, dispatch, isLicenseStart } = props;

  useEffect(() => {
    if (isLicenseStart) {
      dispatch(userFeatureFlagGet({}));
    }
  }, [isLicenseStart]);

  const handleStartTrial = () => {
    let moduleData = {
      License: [{ ModuleId: StatusData.ModuleId, IsFreeLicense: true }],
    };
    dispatch(startFreeLicense({ JsonInput: JSON.stringify(moduleData) }));
  };

  return (
    <>
      <div
        className="row bg-white p-4 m-1"
        style={{
          borderRadius: '12px',
          backgroundImage: `url("images/license/bg_white.svg")`,
          backgroundPosition: 'upset',
        }}
      >
        <div className="col-12 h5">Analytics</div>
        <div className="col-12">
          <div className="row">
            {' '}
            <div className="col-6">
              With an analytics license, a center can view all of its speeds and feeds.It provides
              center-wide analytics, enabling the center administrator to gain a deeper
              comprehension of the business and improve it.
            </div>
            <div className="col-2"></div>
            <div className="col-4">
              <div className="d-flex align-item-center justify-content-center">
                <button className="btn" onClick={handleStartTrial} style={{ cursor: 'pointer' }}>
                  Start your 14-day trial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BGContent
        moduleName={'Analytics'}
        backGroundImage={`images/license/analyticsBG.svg`}
        moduleLogoImage={`images/license/analytics.svg`}
        labelTitle={'What you will get:'}
        labels={labels}
        tagLine={
          ' With an analytics license, a center can view all of its speeds and feeds.It provides center-wide analytics, enabling the center administrator to gain a deeper comprehension of the business and improve it'
        }
        handleStartTrial={handleStartTrial}
        trialButtonText={'Start your Free Trial'}
        buyLinkText={'Purchase Small Groups License'}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { user_feature_flag_get, start_free_license } = state;
  const { data, isFetching, error } = start_free_license;

  let isLicenseStart = false;

  if (data && data.success) {
    isLicenseStart = data.success;
  }
  return {
    isLicenseStart,
  };
};

export default connect(mapStateToProps)(Analytics);
