import React, { Component } from 'react';
import { Modal, Checkbox, Tag } from 'antd';
import termsconditions from '../data/termsconditions.pdf';
class TermsAndConditionModal extends Component {
  constructor() {
    super();
    this.state = {
      checked: false,
    };
  }

  render() {
    const { onOk, onCancel, handleSubmit, onConditionModalCancel } = this.props;
    const title = {
      boxShadow: '5px 4px 4px -2px #f3f3f3',
      padding: '5px',
    };
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap pb-0 border-bottom-0 ">
          {/* <h4 class="modal-title col-12">Welcome {localStorage.getItem('userName')}</h4> */}
          <div className="w-100 text-center mb-3">
            <img src="images/SoberPeer_Logo.png" alt="asas" style={{ height: '70px' }} />
          </div>
          <div className="w-100">
            <h3 align="CENTER">Terms & Conditions</h3>
            <div align="RIGHT">
              <Tag
                // icon={<TwitterOutlined />}
                color="#55acee"
              >
                <a href={termsconditions} download="Terms & Conditions.pdf" target="_blank">
                  Download As PDF
                </a>
              </Tag>
            </div>
          </div>
        </div>
        <div class="modal-body col-md-12 pr-0 enrollment-form pb-2" id="privacy">
          <div style={{ height: '400px', overflowY: 'scroll', paddingRight: '10px' }}>
            <div class="container privacy-policy">
              <p>
                These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;) are an
                agreement between Sober Peer, LLC (&quot;Sober Peer, LLC&quot;, &quot;us&quot;,
                &quot;we&quot; or &quot;our&quot;) and you (&quot;User&quot;, &quot;you&quot; or
                &quot;your&quot;). This Agreement sets forth the general terms and conditions of
                your use of the provider.soberpeer.com
                {/* <a target="_blank" rel="nofollow" href="https://provider.soberpeer.com/">
                  provider.soberpeer.com
                </a>{' '} */}
                website and any of its products or services (collectively, &quot;Website&quot; or
                &quot;Services&quot;).
              </p>
              <h5>Accounts and membership</h5>
              <p>
                You must be at least 18 years of age to use this Website. By using this Website and
                by agreeing to this Agreement you warrant and represent that you are at least 18
                years of age. If you create an account on the Website, you are responsible for
                maintaining the security of your account and you are fully responsible for all
                activities that occur under the account and any other actions taken in connection
                with it. We may monitor and review new accounts before you may sign in and use our
                Services. Providing false contact information of any kind may result in the
                termination of your account. You must immediately notify us of any unauthorized uses
                of your account or any other breaches of security. We will not be liable for any
                acts or omissions by you, including any damages of any kind incurred as a result of
                such acts or omissions. We may suspend, disable, or delete your account (or any part
                thereof) if we determine that you have violated any provision of this Agreement or
                that your conduct or content would tend to damage our reputation and goodwill. If we
                delete your account for the foregoing reasons, you may not re-register for our
                Services. We may block your email address and Internet protocol address to prevent
                further registration.
              </p>
              <h5>User content</h5>
              <p>
                We do not own any data, information or material (&quot;Content&quot;) that you
                submit on the Website in the course of using the Service. You shall have sole
                responsibility for the accuracy, quality, integrity, legality, reliability,
                appropriateness, and intellectual property ownership or right to use of all
                submitted Content. We may monitor and review Content on the Website submitted or
                created using our Services by you. Unless specifically permitted by you, your use of
                the Website does not grant us the license to use, reproduce, adapt, modify, publish
                or distribute the Content created by you or stored in your user account for
                commercial, marketing or any similar purpose. But you grant us permission to access,
                copy, distribute, store, transmit, reformat, display and perform the Content of your
                user account solely as required for the purpose of providing the Services to you.
                Without limiting any of those representations or warranties, we have the right,
                though not the obligation, to, in our own sole discretion, refuse or remove any
                Content that, in our reasonable opinion, violates any of our policies or is in any
                way harmful or objectionable.
              </p>
              <h5>Billing and payments</h5>
              <p>
                You shall pay all fees or charges to your account in accordance with the fees,
                charges, and billing terms in effect at the time a fee or charge is due and payable.
                If auto-renewal is enabled for the Services you have subscribed for, you will be
                charged automatically in accordance with the term you selected. If, in our judgment,
                your purchase constitutes a high-risk transaction, we will require you to provide us
                with a copy of your valid government-issued photo identification, and possibly a
                copy of a recent bank statement for the credit or debit card used for the purchase.
                We reserve the right to change product pricing at any time. We also reserve the
                right to refuse any order you place with us. We may, in our sole discretion, limit
                or cancel quantities purchased per person, per household or per order. These
                restrictions may include orders placed by or under the same customer account, the
                same credit card, and/or orders that use the same billing and/or shipping address.
                In the event that we make a change to or cancel an order, we may attempt to notify
                you by contacting the e-mail and/or billing address/phone number provided at the
                time the order was made.
              </p>
              <h5>Accuracy of information</h5>
              <p>
                Occasionally there may be information on the Website that contains typographical
                errors, inaccuracies or omissions that may relate to product descriptions, pricing,
                availability, promotions and offers. We reserve the right to correct any errors,
                inaccuracies or omissions, and to change or update information or cancel orders if
                any information on the Website or on any related Service is inaccurate at any time
                without prior notice (including after you have submitted your order). We undertake
                no obligation to update, amend or clarify information on the Website including,
                without limitation, pricing information, except as required by law. No specified
                update or refresh date applied on the Website should be taken to indicate that all
                information on the Website or on any related Service has been modified or updated.
              </p>
              <h5>Uptime guarantee</h5>
              <p>
                We offer a Service uptime guarantee of 99% of available time per month. The service
                uptime guarantee does not apply to service interruptions caused by: (1) periodic
                scheduled maintenance or repairs we may undertake from time to time; (2)
                interruptions caused by you or your activities; (3) outages that do not affect core
                Service functionality; (4) causes beyond our control or that are not reasonably
                foreseeable; and (5) outages related to the reliability of certain programming
                environments.
              </p>
              <h5>Backups</h5>
              <p>
                We perform regular backups of the Website and Content and will do our best to ensure
                completeness and accuracy of these backups. In the event of the hardware failure or
                data loss we will restore backups automatically to minimize the impact and downtime.
              </p>
              <h5>Links to other websites</h5>
              <p>
                Although this Website may link to other websites, we are not, directly or
                indirectly, implying any approval, association, sponsorship, endorsement, or
                affiliation with any linked website, unless specifically stated herein. We are not
                responsible for examining or evaluating, and we do not warrant the offerings of, any
                businesses or individuals or the content of their websites. We do not assume any
                responsibility or liability for the actions, products, services, and content of any
                other third-parties. You should carefully review the legal statements and other
                conditions of use of any website which you access through a link from this Website.
                Your linking to any other off-site websites is at your own risk.
              </p>
              <h5>Changes and amendments</h5>
              <p>
                We reserve the right to modify this Agreement or its policies relating to the
                Website or Services at any time, effective upon posting of an updated version of
                this Agreement on the Website. When we do, we will revise the updated date at the
                bottom of this page. Continued use of the Website after any such changes shall
                constitute your consent to such changes.
              </p>
              <h5>Acceptance of these terms</h5>
              <p>
                You acknowledge that you have read this Agreement and agree to all its terms and
                conditions. By using the Website or its Services you agree to be bound by this
                Agreement. If you do not agree to abide by the terms of this Agreement, you are not
                authorized to use or access the Website and its Services.
              </p>
              <h5>Contacting us</h5>
              <p>
                If you would like to contact us to understand more about this Agreement or wish to
                contact us concerning any matter relating to it, you may send an email to
                s&#117;&#112;p&#111;rt&#64;&#115;o&#98;&#101;rp&#101;er&#46;com
              </p>
              <p>This document was last updated on March 29, 2020</p>
              <h2 style={title}>Acceptable use policy</h2>
              <p>
                These acceptable use policy (&quot;Acceptable Use Policy&quot;, &quot;AUP&quot;,
                &quot;Policy&quot;) is an agreement between Sober Peer, LLC (&quot;Sober Peer,
                LLC&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you
                (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This Policy sets forth the
                general guidelines and acceptable and prohibited uses of the provider.soberpeer.com
                {/* <a target="_blank" rel="nofollow" href="https://provider.soberpeer.com/">
                  provider.soberpeer.com
                </a>{' '} */}
                website and any of its products or services (collectively, &quot;Website&quot; or
                &quot;Services&quot;).
              </p>
              <h5>Prohibited activities and uses</h5>
              <p>
                You may not use the Services to publish content or engage in activity that is
                illegal under applicable law, that is harmful to others, or that would subject us to
                liability, including, without limitation, in connection with any of the following,
                each of which is prohibited under this Policy:
              </p>
              <ul>
                <li>Distributing malware or other malicious code.</li>
                <li>Disclosing sensitive personal information about others.</li>
                <li>
                  Collecting, or attempting to collect, personal information about third parties
                  without their knowledge or consent.
                </li>
                <li>Distributing pornography or adult related content.</li>
                <li>Promoting or facilitating prostitution or any escort services.</li>
                <li>
                  Hosting, distributing or linking to child pornography or content that is harmful
                  to minors.
                </li>
                <li>
                  Promoting or facilitating gambling, violence, terrorist activities or selling
                  weapons or ammunition.
                </li>
                <li>
                  Engaging in the unlawful distribution of controlled substances, drug contraband or
                  prescription medications.
                </li>
                <li>
                  Managing payment aggregators or facilitators such as processing payments on behalf
                  of other businesses or charities.
                </li>
                <li>
                  Facilitating pyramid schemes or other models intended to seek payments from public
                  actors.
                </li>
                <li>Threatening harm to persons or property or otherwise harassing behavior.</li>
                <li>Infringing the intellectual property or other proprietary rights of others.</li>
                <li>
                  Facilitating, aiding, or encouraging any of the above activities through our
                  Services.
                </li>
              </ul>
              <h5>System abuse</h5>
              <p>
                Any User in violation of our Services security is subject to criminal and civil
                liability, as well as immediate account termination. Examples include, but are not
                limited to the following:
              </p>
              <ul>
                <li>
                  Use or distribution of tools designed for compromising security of the Services.
                </li>
                <li>
                  Intentionally or negligently transmitting files containing a computer virus or
                  corrupted data.
                </li>
                <li>
                  Accessing another network without permission, including to probe or scan for
                  vulnerabilities or breach security or authentication measures.
                </li>
                <li>
                  Unauthorized scanning or monitoring of data on any network or system without
                  proper authorization of the owner of the system or network.
                </li>
              </ul>
              <h5>Service resources</h5>
              <p>
                You may not consume excessive amounts of the Services or use the Services in any way
                which results in performance issues or which interrupts the services for other
                Users. Prohibited activities that contribute to excessive use, include without
                limitation:
              </p>
              <ul>
                <li>
                  Deliberate attempts to overload the Services and broadcast attacks (i.e. denial of
                  service attacks).
                </li>
                <li>
                  Engaging in any other activities that degrade the usability and performance of our
                  Services.
                </li>
                <li>
                  Hosting or running malicious code or other scripts or processes that adversely
                  impact our Services.
                </li>
                <li>
                  Operating a file sharing site or scripts for BitTorrent or similar, which includes
                  sending or receiving files containing these mechanisms.
                </li>
                <li>
                  Web proxy scripts, such as those that allow anyone to browse to a third-party
                  website anonymously, are prohibited.
                </li>
              </ul>
              <h5>No spam policy</h5>
              <p>
                You may not use our Services to send spam or bulk unsolicited messages. We maintain
                a zero tolerance policy for use of our Services in any manner associated with the
                transmission, distribution or delivery of any bulk e-mail, including unsolicited
                bulk or unsolicited commercial e-mail, or the sending, assisting, or commissioning
                the transmission of commercial e-mail that does not comply with the U.S. CAN-SPAM
                Act of 2003 (&quot;SPAM&quot;).
              </p>
              <p>
                Your products or services advertised via SPAM (i.e. Spamvertised) may not be used in
                conjunction with our Services. This provision includes, but is not limited to, SPAM
                sent via fax, phone, postal mail, email, instant messaging, or newsgroups.
              </p>
              <p>
                Sending emails through our Services to purchased email lists (&quot;safe
                lists&quot;) will be treated as SPAM.
              </p>
              <h5>Defamation and objectionable content</h5>
              <p>
                We value the freedom of expression and encourage Users to be respectful with the
                content they post. We are not a publisher of User content and are not in a position
                to investigate the veracity of individual defamation claims or to determine whether
                certain material, which we may find objectionable, should be censored. However, we
                reserve the right to moderate, disable or remove any content to prevent harm to
                others or to us or our Services, as determined in our sole discretion.
              </p>
              <h5>Copyrighted content</h5>
              <p>
                Copyrighted material must not be published via our Services without the explicit
                permission of the copyright owner or a person explicitly authorized to give such
                permission by the copyright owner. Upon receipt of a claim for copyright
                infringement, or a notice of such violation, we will immediately run full
                investigation and, upon confirmation, will promptly remove the infringing material
                from the Services. We may terminate the Service of Users with repeated copyright
                infringements. Further procedures may be carried out if necessary. We will assume no
                liability to any User of the Services for the removal of any such material.
              </p>
              <p>
                If you believe your copyright is being infringed by a person or persons using our
                Services, please send a report of the copyright infringement to the contact details
                listed at the end of this Policy.
              </p>
              <h5>Security</h5>
              <p>
                You take full responsibility for maintaining reasonable security precautions for
                your account. You are responsible for protecting and updating any login account
                provided to you for our Services. You must protect the confidentiality of your login
                details, and you should change your password periodically. You are responsible for
                ensuring all User provided software installed by you on our Services is updated and
                patched following industry best practice.
              </p>
              <h5>Enforcement</h5>
              <p>
                We reserve our right to be the sole arbiter in determining the seriousness of each
                infringement and to immediately take corrective actions, including but not limited
                to:
              </p>
              <ul>
                <li>
                  Suspending or terminating your Service with or without notice upon any violation
                  of this Policy. Any violations may also result in the immediate suspension or
                  termination of your account.
                </li>
                <li>
                  Disabling or removing any content which is prohibited by this Policy, including to
                  prevent harm to others or to us or our Services, as determined by us in our sole
                  discretion.
                </li>
                <li>
                  Reporting violations to law enforcement as determined by us in our sole
                  discretion.
                </li>
                <li>
                  A failure to respond to an email from our abuse team within 2 days, or as
                  otherwise specified in the communication to you, may result in the suspension or
                  termination of your Services.
                </li>
              </ul>
              <p>
                Suspended and terminated User accounts due to violations will not be reactivated. A
                backup of User’s data may be requested, however, we may not be able to provide you
                with one and, as such, you are strongly encouraged to take your own backups.
              </p>
              <p>
                Nothing contained in this Policy shall be construed to limit our actions or remedies
                in any way with respect to any of the prohibited activities. We reserve the right to
                take any and all additional actions we may deem appropriate with respect to such
                activities, including without limitation taking action to recover the costs and
                expenses of identifying offenders and removing them from our Services, and levying
                cancellation charges to cover our costs. In addition, we reserve at all times all
                rights and remedies available to us with respect to such activities at law or in
                equity.
              </p>
              <h5>Reporting violations</h5>
              <p>
                If you have discovered and would like to report a violation of this Policy, please
                contact us immediately. We will investigate the situation and provide you with full
                assistance.
              </p>
              <h5>Changes and amendments</h5>
              <p>
                We reserve the right to modify this Policy or its terms relating to the Website or
                Services at any time, effective upon posting of an updated version of this Policy on
                the Website. When we do, we will revise the updated date at the bottom of this page.
                Continued use of the Website after any such changes shall constitute your consent to
                such changes.
              </p>
              <h5>Acceptance of this policy</h5>
              <p>
                You acknowledge that you have read this Policy and agree to all its terms and
                conditions. By using the Website or its Services you agree to be bound by this
                Policy. If you do not agree to abide by the terms of this Policy, you are not
                authorized to use or access the Website and its Services.
              </p>
              <h5>Contacting us</h5>
              <p>
                If you would like to contact us to understand more about this Policy or wish to
                contact us concerning any matter relating to it, you may send an email to
                su&#112;&#112;ort&#64;s&#111;&#98;&#101;r&#112;&#101;&#101;r&#46;c&#111;m
              </p>
              <p>This document was last updated on March 29, 2020</p>
              <h2 style={title}>Cookie policy</h2>
              <p>
                This cookie policy (&quot;Policy&quot;) describes what cookies are and how Sober
                Peer, LLC (&quot;Sober Peer, LLC&quot;, &quot;we&quot;, &quot;us&quot; or
                &quot;our&quot;) uses them on the provider.soberpeer.com
                {/* <a target="_blank" rel="nofollow" href="https://provider.soberpeer.com/">
                  provider.soberpeer.com
                </a>{' '} */}
                website and any of its products or services (collectively, &quot;Website&quot; or
                &quot;Services&quot;).
              </p>
              <p>
                You should read this Policy so you can understand what type of cookies we use, the
                information we collect using cookies and how that information is used. It also
                describes the choices available to you regarding accepting or declining the use of
                cookies. For further information on how we use, store and keep your personal data
                secure, see our{' '}
                <a target="_blank" rel="nofollow" href="http://www.soberpeer.com/privacy">
                  Privacy Policy
                </a>
                .
              </p>
              <h5>What are cookies?</h5>
              <p>
                Cookies are small pieces of data stored in text files that are saved on your
                computer or other devices when websites are loaded in a browser. They are widely
                used to remember you and your preferences, either for a single visit (through a
                &quot;session cookie&quot;) or for multiple repeat visits (using a &quot;persistent
                cookie&quot;).
              </p>
              <p>
                Session cookies are temporary cookies that are used during the course of your visit
                to the Website, and they expire when you close the web browser.
              </p>
              <p>
                Persistent cookies are used to remember your preferences within our Website and
                remain on your desktop or mobile device even after you close your browser or restart
                your computer. They ensure a consistent and efficient experience for you while
                visiting our Website or using our Services.
              </p>
              <p>
                Cookies may be set by the Website (&quot;first-party cookies&quot;), or by third
                parties, such as those who serve content or provide advertising or analytics
                services on the website (&quot;third party cookies&quot;). These third parties can
                recognize you when you visit our website and also when you visit certain other
                websites.
              </p>
              <h5>What type of cookies do we use?</h5>
              <h5>Necessary cookies</h5>
              <p>
                Necessary cookies allow us to offer you the best possible experience when accessing
                and navigating through our Website and using its features. For example, these
                cookies let us recognize that you have created an account and have logged into that
                account to access the content.
              </p>
              <h5>Functionality cookies</h5>
              <p>
                Functionality cookies let us operate the Website and our Services in accordance with
                the choices you make. For example, we will recognize your username and remember how
                you customized the Website and Services during future visits.
              </p>
              <h5>What are your cookie options?</h5>
              <p>
                If you don't like the idea of cookies or certain types of cookies, you can change
                your browser's settings to delete cookies that have already been set and to not
                accept new cookies. To learn more about how to do this or to learn more about
                cookies, visit{' '}
                <a target="_blank" href="https://www.internetcookies.org">
                  internetcookies.org
                </a>
              </p>
              <h5>Changes and amendments</h5>
              <p>
                We reserve the right to modify this Policy relating to the Website or Services at
                any time, effective upon posting of an updated version of this Policy on the
                Website. When we do we will revise the updated date at the bottom of this page.
                Continued use of the Website after any such changes shall constitute your consent to
                such changes.
              </p>
              <h5>Acceptance of this policy</h5>
              <p>
                You acknowledge that you have read this Policy and agree to all its terms and
                conditions. By using the Website or its Services you agree to be bound by this
                Policy. If you do not agree to abide by the terms of this Policy, you are not
                authorized to use or access the Website and its Services.
              </p>
              <h5>Contacting us</h5>
              <p>
                If you would like to contact us to understand more about this Policy or wish to
                contact us concerning any matter relating to our use of cookies, you may send an
                email to
                &#115;u&#112;&#112;or&#116;&#64;&#115;&#111;b&#101;r&#112;&#101;&#101;r&#46;co&#109;
              </p>
              <p>This document was last updated on March 29, 2020</p>
              <h2 style={title}>Refund policy</h2>
              <p>
                Since our Website offers non-tangible, irrevocable goods we do not provide refunds
                after the product is purchased, which you acknowledge prior to purchasing any
                product on the Website. Please make sure that you've carefully read service
                description before making a purchase.
              </p>
              <h5>Contacting us</h5>
              <p>
                If you would like to contact us concerning any matter relating to this Refund
                Policy, you may send an email to
                su&#112;&#112;&#111;&#114;&#116;&#64;s&#111;&#98;er&#112;&#101;&#101;r.&#99;&#111;m
              </p>
              <p>This document was last updated on March 29, 2020</p>
              <h2 style={title}>Disclaimer</h2>
              <p>
                This disclaimer (&quot;Disclaimer&quot;, &quot;Agreement&quot;) is an agreement
                between Sober Peer, LLC (&quot;Sober Peer, LLC&quot;, &quot;us&quot;, &quot;we&quot;
                or &quot;our&quot;) and you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;).
                This Disclaimer sets forth the general guidelines, terms and conditions of your use
                of the provider.soberpeer.com
                {/* <a target="_blank" rel="nofollow" href="https://provider.soberpeer.com/">
                  provider.soberpeer.com
                </a>{' '} */}
                website and any of its products or services (collectively, &quot;Website&quot; or
                &quot;Services&quot;).
              </p>
              <h5>Representation</h5>
              <p>
                Any views or opinions represented in this Website reflect the views and opinions of
                Sober Peer, LLC, its affiliates, Content creators or employees. Any views or
                opinions are not intended to malign any religion, ethnic group, club, organization,
                company, or individual.
              </p>
              <h5>Content and postings</h5>
              <p>
                You may not modify, print or copy any part of the Website. Inclusion of any part of
                this Website in another work, whether in printed or electronic or another form or
                inclusion of any part of the Website in another website by embedding, framing or
                otherwise without the express permission of Sober Peer, LLC is prohibited.{' '}
              </p>
              <p>
                You may submit comments for the Content available on the Website. By uploading or
                otherwise making available any information to Sober Peer, LLC, you grant Sober Peer,
                LLC the unlimited, perpetual right to distribute, display, publish, reproduce, reuse
                and copy the information contained therein. You may not impersonate any other person
                through the Website. You may not post content that is defamatory, fraudulent,
                obscene, threatening, invasive of another person's privacy rights or that is
                otherwise unlawful. You may not post content that infringes on the intellectual
                property rights of any other person or entity. You may not post any content that
                includes any computer virus or other code designed to disrupt, damage, or limit the
                functioning of any computer software or hardware. By submitting or posting Content
                on the Website, you grant Sober Peer, LLC the right to edit and, if necessary,
                remove any Content at any time and for any reason.
                <h5>Indemnification and warranties</h5>
              </p>
              <p>
                While we have made every attempt to ensure that the information contained on the
                Website is correct, Sober Peer, LLC is not responsible for any errors or omissions,
                or for the results obtained from the use of this information. All information on the
                Website is provided &quot;as is&quot;, with no guarantee of completeness, accuracy,
                timeliness or of the results obtained from the use of this information, and without
                warranty of any kind, express or implied. In no event will Sober Peer, LLC, or its
                partners, employees or agents, be liable to you or anyone else for any decision made
                or action taken in reliance on the information on the Website or for any
                consequential, special or similar damages, even if advised of the possibility of
                such damages. Furthermore, information contained on the Website and any pages linked
                to and from it are subject to change at any time and without warning.
              </p>
              <p>
                We reserve the right to modify this Disclaimer relating to the Website or Services
                at any time, effective upon posting of an updated version of this Disclaimer on the
                Website. When we do we will revise the updated date at the bottom of this page.
                Continued use of the Website after any such changes shall constitute your consent to
                such changes.
              </p>
              <h5>Acceptance of this disclaimer</h5>
              <p>
                You acknowledge that you have read this Disclaimer and agree to all its terms and
                conditions. By accessing the Website you agree to be bound by this Disclaimer. If
                you do not agree to abide by the terms of this Disclaimer, you are not authorized to
                use or access the Website.
              </p>
              <h5>Contacting us</h5>
              <p>
                If you would like to contact us to understand more about this Disclaimer or wish to
                contact us concerning any matter relating to it, you may send an email to
                &#115;&#117;p&#112;&#111;&#114;&#116;&#64;s&#111;be&#114;&#112;&#101;er&#46;&#99;om
              </p>
              <p>This document was last updated on March 29, 2020</p>
              <h2 style={title}>Privacy policy</h2>
              <p>
                This privacy policy (&quot;Policy&quot;) describes how Sober Peer, LLC (&quot;Sober
                Peer, LLC&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;) collects,
                protects and uses the personally identifiable information (&quot;Personal
                Information&quot;) you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) may
                provide on the provider.soberpeer.com
                {/* <a target="_blank" rel="nofollow" href="https://provider.soberpeer.com/">
                  provider.soberpeer.com
                </a>{' '} */}
                website and any of its products or services (collectively, &quot;Website&quot; or
                &quot;Services&quot;).
              </p>
              <p>
                It also describes the choices available to you regarding our use of your Personal
                Information and how you can access and update this information. This Policy does not
                apply to the practices of companies that we do not own or control, or to individuals
                that we do not employ or manage.
              </p>
              <h5>Automatic collection of information</h5>
              <p>
                When you visit the Website our servers automatically record information that your
                browser sends. This data may include information such as your device's IP address,
                browser type and version, operating system type and version, language preferences or
                the webpage you were visiting before you came to our Website, pages of our Website
                that you visit, the time spent on those pages, information you search for on our
                Website, access times and dates, and other statistics.
              </p>
              <p>
                Information collected automatically is used only to identify potential cases of
                abuse and establish statistical information regarding Website usage. This
                statistical information is not otherwise aggregated in such a way that would
                identify any particular user of the system.
              </p>
              <h5>Collection of personal information</h5>
              <p>
                You can visit the Website without telling us who you are or revealing any
                information by which someone could identify you as a specific, identifiable
                individual. If, however, you wish to use some of the Website's features, you will be
                asked to provide certain Personal Information (for example, your name and e-mail
                address). We receive and store any information you knowingly provide to us when you
                create an account, publish content, make a purchase, or fill any online forms on the
                Website. When required, this information may include the following:
              </p>
              <ul>
                <li>Personal details such as name, country of residence, etc.</li>
                <li>Contact information such as email address, address, etc.</li>
                <li>Account details such as user name, unique user ID, password, etc.</li>
                <li>Proof of identity such as photocopy of a government ID.</li>
                <li>
                  Any other materials you willingly submit to us such as articles, images, feedback,
                  etc.
                </li>
              </ul>
              <p>
                You can choose not to provide us with your Personal Information, but then you may
                not be able to take advantage of some of the Website's features. Users who are
                uncertain about what information is mandatory are welcome to contact us.
              </p>
              <h5>Managing personal information</h5>
              <p>
                You are able to delete certain Personal Information we have about you. The Personal
                Information you can delete may change as the Website or Services change. When you
                delete Personal Information, however, we may maintain a copy of the unrevised
                Personal Information in our records for the duration necessary to comply with our
                obligations to our affiliates and partners, and for the purposes described below. If
                you would like to delete your Personal Information or permanently delete your
                account, you can do so on the settings page of your account on the Website.
              </p>
              <h5>Storing personal information</h5>
              <p>
                We will retain and use your Personal Information for the period necessary to comply
                with our legal obligations, resolve disputes, and enforce our agreements unless a
                longer retention period is required or permitted by law. We may use any aggregated
                data derived from or incorporating your Personal Information after you update or
                delete it, but not in a manner that would identify you personally. Once the
                retention period expires, Personal Information shall be deleted. Therefore, the
                right to access, the right to erasure, the right to rectification and the right to
                data portability cannot be enforced after the expiration of the retention period.
              </p>
              <h5>Use and processing of collected information</h5>
              <p>
                In order to make our Website and Services available to you, or to meet a legal
                obligation, we need to collect and use certain Personal Information. If you do not
                provide the information that we request, we may not be able to provide you with the
                requested products or services. Some of the information we collect is directly from
                you via our Website. However, we may also collect Personal Information about you
                from other sources. Any of the information we collect from you may be used for the
                following purposes:
              </p>
              <ul>
                <li>Create and manage user accounts</li>
                <li>Fulfill and manage orders</li>
                <li>Deliver products or services</li>
                <li>Improve products and services</li>
                <li>Send administrative information</li>
                <li>Respond to inquiries and offer support</li>
                <li>Improve user experience</li>
                <li>Enforce terms and conditions and policies</li>
                <li>Protect from abuse and malicious users</li>
                <li>Respond to legal requests and prevent harm</li>
                <li>Run and operate our Website and Services</li>
              </ul>
              <p>
                Processing your Personal Information depends on how you interact with our Website,
                where you are located in the world and if one of the following applies: (i) You have
                given your consent for one or more specific purposes. This, however, does not apply,
                whenever the processing of Personal Information is subject to California Consumer
                Privacy Act or European data protection law; (ii) Provision of information is
                necessary for the performance of an agreement with you and/or for any
                pre-contractual obligations thereof; (iii) Processing is necessary for compliance
                with a legal obligation to which you are subject; (iv) Processing is related to a
                task that is carried out in the public interest or in the exercise of official
                authority vested in us; (v) Processing is necessary for the purposes of the
                legitimate interests pursued by us or by a third party.
              </p>
              <p>
                {' '}
                Note that under some legislations we may be allowed to process information until you
                object to such processing (by opting out), without having to rely on consent or any
                other of the following legal bases below. In any case, we will be happy to clarify
                the specific legal basis that applies to the processing, and in particular whether
                the provision of Personal Information is a statutory or contractual requirement, or
                a requirement necessary to enter into a contract.
              </p>
              <h5>Information transfer and storage</h5>
              <p>
                Depending on your location, data transfers may involve transferring and storing your
                information in a country other than your own. You are entitled to learn about the
                legal basis of information transfers to a country outside the European Union or to
                any international organization governed by public international law or set up by two
                or more countries, such as the UN, and about the security measures taken by us to
                safeguard your information. If any such transfer takes place, you can find out more
                by checking the relevant sections of this document or inquire with us using the
                information provided in the contact section.
              </p>
              <h5>The rights of users</h5>
              <p>
                You may exercise certain rights regarding your information processed by us. In
                particular, you have the right to do the following: (i) you have the right to
                withdraw consent where you have previously given your consent to the processing of
                your information; (ii) you have the right to object to the processing of your
                information if the processing is carried out on a legal basis other than consent;
                (iii) you have the right to learn if information is being processed by us, obtain
                disclosure regarding certain aspects of the processing and obtain a copy of the
                information undergoing processing; (iv) you have the right to verify the accuracy of
                your information and ask for it to be updated or corrected; (v) you have the right,
                under certain circumstances, to restrict the processing of your information, in
                which case, we will not process your information for any purpose other than storing
                it; (vi) you have the right, under certain circumstances, to obtain the erasure of
                your Personal Information from us; (vii) you have the right to receive your
                information in a structured, commonly used and machine readable format and, if
                technically feasible, to have it transmitted to another controller without any
                hindrance. This provision is applicable provided that your information is processed
                by automated means and that the processing is based on your consent, on a contract
                which you are part of or on pre-contractual obligations thereof.
              </p>
              <h5>The right to object to processing</h5>
              <p>
                Where Personal Information is processed for the public interest, in the exercise of
                an official authority vested in us or for the purposes of the legitimate interests
                pursued by us, you may object to such processing by providing a ground related to
                your particular situation to justify the objection. You must know that, however,
                should your Personal Information be processed for direct marketing purposes, you can
                object to that processing at any time without providing any justification. To learn,
                whether we are processing Personal Information for direct marketing purposes, you
                may refer to the relevant sections of this document.
              </p>
              <h5>How to exercise these rights</h5>
              <p>
                Any requests to exercise User rights can be directed to the Owner through the
                contact details provided in this document. These requests can be exercised free of
                charge and will be addressed by the Owner as early as possible.
              </p>
              <h5>California privacy rights</h5>
              <p>
                In addition to the rights as explained in this Privacy Policy, California residents
                who provide Personal Information (as defined in the statute) to obtain products or
                services for personal, family, or household use are entitled to request and obtain
                from us, once a calendar year, information about the Personal Information we shared,
                if any, with other businesses for marketing uses. If applicable, this information
                would include the categories of Personal Information and the names and addresses of
                those businesses with which we shared such personal information for the immediately
                prior calendar year (e.g., requests made in the current year will receive
                information about the prior year). To obtain this information please contact us.
              </p>
              <h5>Billing and payments</h5>
              <p>
                We use third-party payment processors to assist us in processing your payment
                information securely. Such third-party processors' use of your Personal Information
                is governed by their respective privacy policies which may or may not contain
                privacy protections as protective as this Privacy Policy. We suggest that you review
                their respective privacy policies.
              </p>
              <h5>Product and service providers</h5>
              <p>
                We may contract with other companies to provide certain products and services. These
                service providers are not authorized to use or disclose the information except as
                necessary to perform services on our behalf or comply with legal requirements. We
                may share Personal Information for these purposes only with third-parties whose
                privacy policies are consistent with ours or who agree to abide by our policies with
                respect to Personal Information Our service providers are given the information they
                need to perform their designated functions, and we do not authorize them to use or
                disclose Personal Information for their own marketing or other purposes.
              </p>
              <h5>Privacy of children</h5>
              <p>
                We do not knowingly collect any Personal Information from children under the age of
                13. If you are under the age of 13, please do not submit any Personal Information
                through our Website or Service. We encourage parents and legal guardians to monitor
                their children's Internet usage and to help enforce this Policy by instructing their
                children never to provide Personal Information through our Website or Service
                without their permission.
              </p>
              <p>
                If you have reason to believe that a child under the age of 13 has provided Personal
                Information to us through our Website or Service, please contact us. You must also
                be at least 16 years of age to consent to the processing of your Personal
                Information in your country (in some countries we may allow your parent or guardian
                to do so on your behalf).
              </p>
              <h5>Newsletters</h5>
              <p>
                We offer electronic newsletters to which you may voluntarily subscribe at any time.
                We are committed to keeping your e-mail address confidential and will not disclose
                your email address to any third parties except as allowed in the information use and
                processing section or for the purposes of utilizing a third-party provider to send
                such emails. We will maintain the information sent via e-mail in accordance with
                applicable laws and regulations.
              </p>
              <p>
                In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who
                the e-mail is from and provide clear information on how to contact the sender. You
                may choose to stop receiving our newsletter or marketing emails by following the
                unsubscribe instructions included in these emails or by contacting us. However, you
                will continue to receive essential transactional emails.
              </p>
              <h5>Cookies</h5>
              <p>
                The Website uses &quot;cookies&quot; to help personalize your online experience. A
                cookie is a text file that is placed on your hard disk by a web page server. Cookies
                cannot be used to run programs or deliver viruses to your computer. Cookies are
                uniquely assigned to you, and can only be read by a web server in the domain that
                issued the cookie to you.
              </p>
              <p>
                We may use cookies to collect, store, and track information for statistical purposes
                to operate our Website and Services. You have the ability to accept or decline
                cookies. Most web browsers automatically accept cookies, but you can usually modify
                your browser setting to decline cookies if you prefer. If you choose to decline
                cookies, you may not be able to fully experience the features of the Website and
                Services. To learn more about cookies and how to manage them, visit{' '}
                <a target="_blank" href="https://www.internetcookies.org">
                  internetcookies.org
                </a>
              </p>
              <h5>Do Not Track signals</h5>
              <p>
                Some browsers incorporate a Do Not Track feature that signals to websites you visit
                that you do not want to have your online activity tracked. Tracking is not the same
                as using or collecting information in connection with a website. For these purposes,
                tracking refers to collecting personally identifiable information from consumers who
                use or visit a website or online service as they move across different websites over
                time. Our Website does not track its visitors over time and across third party
                websites. However, some third party sites may keep track of your browsing activities
                when they serve you content, which enables them to tailor what they present to you.
              </p>
              <h5>Affiliates</h5>
              <p>
                We may disclose information about you to our affiliates for the purpose of being
                able to offer you related or additional products and services. Any information
                relating to you that we provide to our affiliates will be treated by those
                affiliates in accordance with the terms of this Privacy Policy.
              </p>
              <h5>Links to other websites</h5>
              <p>
                Our Website contains links to other websites that are not owned or controlled by us.
                Please be aware that we are not responsible for the privacy practices of such other
                websites or third-parties. We encourage you to be aware when you leave our Website
                and to read the privacy statements of each and every website that may collect
                Personal Information.
              </p>
              <h5>Information security</h5>
              <p>
                We secure information you provide on computer servers in a controlled, secure
                environment, protected from unauthorized access, use, or disclosure. We maintain
                reasonable administrative, technical, and physical safeguards in an effort to
                protect against unauthorized access, use, modification, and disclosure of Personal
                Information in its control and custody. However, no data transmission over the
                Internet or wireless network can be guaranteed. Therefore, while we strive to
                protect your Personal Information, you acknowledge that (i) there are security and
                privacy limitations of the Internet which are beyond our control; (ii) the security,
                integrity, and privacy of any and all information and data exchanged between you and
                our Website cannot be guaranteed; and (iii) any such information and data may be
                viewed or tampered with in transit by a third-party, despite best efforts.
              </p>
              <h5>Data breach</h5>
              <p>
                In the event we become aware that the security of the Website has been compromised
                or users Personal Information has been disclosed to unrelated third parties as a
                result of external activity, including, but not limited to, security attacks or
                fraud, we reserve the right to take reasonably appropriate measures, including, but
                not limited to, investigation and reporting, as well as notification to and
                cooperation with law enforcement authorities. In the event of a data breach, we will
                make reasonable efforts to notify affected individuals if we believe that there is a
                reasonable risk of harm to the user as a result of the breach or if notice is
                otherwise required by law. When we do, we will post a notice on the Website, send
                you an email.
              </p>
              <h5>Changes and amendments</h5>
              <p>
                We may update this Privacy Policy from time to time in our discretion and will
                notify you of any material changes to the way in which we treat Personal
                Information. When changes are made, we will revise the updated date at the bottom of
                this page. We may also provide notice to you in other ways in our discretion, such
                as through contact information you have provided. Any updated version of this
                Privacy Policy will be effective immediately upon the posting of the revised Privacy
                Policy unless otherwise specified. Your continued use of the Website or Services
                after the effective date of the revised Privacy Policy (or such other act specified
                at that time) will constitute your consent to those changes. However, we will not,
                without your consent, use your Personal Data in a manner materially different than
                what was stated at the time your Personal Data was collected.
              </p>
              <h5>Acceptance of this policy</h5>
              <p>
                You acknowledge that you have read this Policy and agree to all its terms and
                conditions. By using the Website or its Services you agree to be bound by this
                Policy. If you do not agree to abide by the terms of this Policy, you are not
                authorized to use or access the Website and its Services.
              </p>
              <h5>Contacting us</h5>
              <p>
                If you would like to contact us to understand more about this Policy or wish to
                contact us concerning any matter relating to individual rights and your Personal
                Information, you may send an email to
                supp&#111;&#114;t&#64;s&#111;be&#114;&#112;&#101;e&#114;&#46;com
              </p>
              <p>This document was last updated on March 29, 2020</p>
            </div>
            <div class="col-12 col-md-6 col-lg-12 mt-2">
              <div class="form-group text-center">
                <Checkbox
                  checked={this.state.checked}
                  onChange={e => {
                    this.setState({
                      checked: e.target.checked,
                    });
                  }}
                  style={{ marginRight: '8px' }}
                />
                <span>I accept the Terms & Conditions Agreement.</span>
              </div>
            </div>
          </div>
          <div class="justify-content-center border-top-1  mt-2 text-center">
            <button
              disabled={!this.state.checked}
              type="button"
              class="btn black-btn font-weight-bold mr-2"
              onClick={onConditionModalCancel}
            >
              Accept
            </button>
            <button
              disabled={this.state.checked}
              type="button"
              class="btn btn-outline-primary mr-2"
              onClick={onConditionModalCancel}
            >
              Reject
            </button>
          </div>
        </div>
        {/* <div class="modal-footer justify-content-center border-top-0">
          <button
            disabled={!this.state.checked}
            type="button"
            class="btn black-btn font-weight-bold mr-2"
            onClick={onConditionModalCancel}
          >
            Accept
          </button>
        </div> */}
      </div>
    );
  }
}
export default TermsAndConditionModal;
