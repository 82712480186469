import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Tabs, Button, Drawer, Tooltip } from 'antd';
import {
  upsertAppointment,
  getClientAppointment,
  resetGetClientAppointment,
  practitionerCombos,
  medicateCombos,
  getComboFacility,
  practitionerCombosOffice,
  getAppointment,
  comboServiceCodeGet,
  studentTwilioTranscriptGet,
  upsertAppointmentReset,
  listSmartCombos,
} from '../../../../store/actions';
import { isComparer, isDateComparer, isNumericComparer } from '../../../../util/handler';
import { getMMDDYYYYFormat } from '../../../../util/dateFormater';
import PersistTableSort from '../../../../component/persistTableSort';
import CloseIcon from '../../../../Components/CloseIcon';
import ManageAppointment from './AppointmentsScreens/ManageAppointment';
import AppointmentDetails from './AppointmentsScreens/AppointmentDetails';
import CallEndedForm from './AppointmentsScreens/CallEnded';
import { ProcessCombos } from '../../../../store/comboConfig';
import { mapTime } from '../../../../util/time';
import { ProfilePicComponent } from '../../../../Components/profilePicComponent';
import ReactAudioPlayer from '../../../../Components/reactAudioPlayer';
import CustomModal from '../../../../core/modal';
import Loader, { Loader2 } from '../../../../Components/Loader';

class Appointments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAppointmentDrawer: false,
      isAppointmentDetailDrawer: false,
      isCallAppointmentDrawer: false,
      isAppointmentNoteDrawer: false,
      initialData: {
        // ServiceCode: 'Auto Generated',
      },
    };
  }
  getAppointmentList = ClientId => {
    this.props.dispatch(
      getClientAppointment({
        Json: JSON.stringify({
          Appointment: [
            {
              IsPeerRecovery: true,
              ClientId: ClientId,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    const { CurrentClientId } = this.props.BannerData || {};
    this.props.dispatch(resetGetClientAppointment());

    this.getAppointmentList(CurrentClientId);

    // if (CurrentClientId) {
    //   this.getAppointmentList(CurrentClientId);
    // }

    // var result = ProcessCombos('RCM_PlaceOfService,RCM_Program');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
    this.props.dispatch(
      listSmartCombos({
        comboTypes: 'RCM_PlaceOfService,RCM_Program',
        Json: JSON.stringify({
          Combo: [
            {
              IsPeerRecovery: true,
            },
          ],
        }),
      }),
    );
    let medicateComboTypes = {
      ComboTypes: 'Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    this.props.dispatch(practitionerCombos({}));
    this.props.dispatch(practitionerCombosOffice({}));
    this.props.dispatch(getComboFacility({}));
    this.props.dispatch(
      comboServiceCodeGet({
        Json: JSON.stringify({
          Combo: [
            {
              IsPeerRecovery: true,
            },
          ],
        }),
      }),
    );
  }
  
  componentWillUnmount() {
    this.props.dispatch(resetGetClientAppointment({}));
  }

  componentWillReceiveProps({ BannerData, AppointmentData }) {
    if (
      AppointmentData &&
      JSON.stringify(AppointmentData) != JSON.stringify(this.props.AppointmentData)
    ) {
      let appoinmentValues = {
        ...this.state.initialData,
        ...AppointmentData,
        ClientId: AppointmentData.ClientId,
        StudentId: AppointmentData.StudentId,
        AppointmentId: AppointmentData.AppointmentId,
        EventTitle: AppointmentData.Title,
        AppointmentDate: AppointmentData.AppointmentDate,
        Program: parseInt(AppointmentData.ProgramId),
        StartTime: AppointmentData.AppointmentTime && mapTime(AppointmentData.AppointmentTime),
        EndTime: AppointmentData.AppointmentEndTime && mapTime(AppointmentData.AppointmentEndTime),
        ServiceCode: AppointmentData.ServiceCode,
        PractitionerId: AppointmentData.PractitionerId,
        PlaceOfServiceId: AppointmentData.PlaceOfServiceId,
        OfficeName: AppointmentData.OfficeName,
        Status: parseInt(AppointmentData.Status),
        ReasonForVisit: AppointmentData.ReasonForVisit,
        TimerDurationInSec: AppointmentData.TimerDurationInSec,
        Notes: AppointmentData.Notes,
      };
      this.setState({
        initialData: appoinmentValues,
      });
    }
    if (BannerData && JSON.stringify(BannerData) != JSON.stringify(this.props.BannerData)) {
      const { CurrentClientId } = BannerData;
      this.getAppointmentList(CurrentClientId);
    }
  }
  toggleDrawer = () => {
    this.setState({ isAppointmentDrawer: !this.state.isAppointmentDrawer });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  getAppointmentData = AppointmentId => {
    this.props.dispatch(
      getAppointment({
        json: JSON.stringify({
          Appointment: [
            {
              AppointmentId: AppointmentId,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      comboServiceCodeGet({
        Json: JSON.stringify({
          Combo: [
            {
              IsPeerRecovery: true,
              AppointmentId: AppointmentId,
            },
          ],
        }),
      }),
    );
  };
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  handleAppointment = val => {
    let data = {
      Appointment: [
        {
          IsPeerRecovery: true,
          ClientId: this.props.BannerData.CurrentClientId,
          StudentId: this.props.BannerData.StudentId,
          AppointmentId: val.AppointmentId,
          Title: val.EventTitle,
          AppointmentDate: getMMDDYYYYFormat(val.AppointmentDate),
          ProgramId: val.Program,
          AppointmentTime: val.StartTime && mapTime(val.StartTime),
          AppointmentEndTime: val.EndTime && mapTime(val.EndTime),
          ServiceCode: this.getStringType(val.ServiceCode),
          // ServiceCode: val.ServiceCode,
          PractitionerId: val.PractitionerId,
          PlaceOfServiceId: val.PlaceOfServiceId,
          OfficeName: val.OfficeName,
          Status: val.Status,
          ReasonForVisit: val.ReasonForVisit,
          Notes: val.Notes,
        },
      ],
    };
    var appointmentData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(upsertAppointment(appointmentData));
  };
  handleAppointmentDetail = (record, tabEvent, IsVoipCallAppointmet) => {
    this.setState({
      isAppointmentDetailDrawer: true,
      tabId: tabEvent.target.id,
      IsVoipCallAppointmet: IsVoipCallAppointmet,
      IsCallRecordExist: record.IsCallRecordExist,
      rowAppointmentId: record.AppointmentId,
      initialData: {
        AppointmentId: record.AppointmentId,
        ClientId: record.ClientId,
      },
    });
    this.getAppointmentData(record.AppointmentId);
    this.props.dispatch(
      studentTwilioTranscriptGet({
        json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
              AppointmentId: record.AppointmentId,
            },
          ],
        }),
      }),
    );
  };
  handleAppointmentNote = record => {
    this.setState({
      isAppointmentNoteDrawer: true,
      rowAppointmentId: record.AppointmentId,
      tabId: 1,
      initialData: {
        AppointmentId: record.AppointmentId,
        ClientId: record.ClientId,
      },
    });
    this.getAppointmentData(record.AppointmentId);
  };
  columns = [
    {
      title: 'Session Date',
      dataIndex: 'SessionDate',
      key: 'SessionDate',
      sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
      sorter: (a, b) => isComparer(a.Title, b.Title),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Provider',
      dataIndex: 'PractitionerName',
      key: 'PractitionerName',
      sorter: (a, b) => isComparer(a.PractitionerName, b.PractitionerName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div class="d-flex align-items-center">
          <ProfilePicComponent src={record.AttachmentUrl} alt={text} />
          <span className="mx-2">{text}</span>
        </div>
      ),
    },
    {
      title: 'Start/End Time',
      dataIndex: 'SessionTime',
      key: 'SessionTime',
      sorter: (a, b) => isComparer(a.SessionTime, b.SessionTime),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Session Type',
      dataIndex: 'SessionType',
      key: 'SessionType',
      sorter: (a, b) => isComparer(a.SessionType, b.SessionType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Service Code',
      dataIndex: 'ServiceCodeDescription',
      key: 'ServiceCodeDescription',
      sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Program',
      dataIndex: 'ProgramDescription',
      key: 'ProgramDescription',
      sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => isComparer(a.Status, b.Status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        let color = {
          // 1: '#1DD63F',
          2: '#FF2C2C',
        };
        return <span style={{ color: color[record.AppointmentStatus] || '#182838' }}>{text}</span>;
      },
    },
    {
      title: 'Action',
      key: 'x',
      width: '50px',
      fixed: 'right',
      render: (text, record) => {
        let isKept = record.Status === 'Kept';
        // IsthisPeerRecoveryClient;
        // IsVoipCallAppointmet;
        return (
          <div className="grid-action">
            {record.AppointmentStatus != 2 && (
              <>
                <Tooltip placement="top" title="Edit">
                  <img
                    src="images/icons/peer_edit.svg"
                    onClick={event => {
                      let isCallTimer = record.IsTimerAppointment || record.IsVoipCallAppointmet;
                      this.getAppointmentData(record.AppointmentId);
                      this.setState({
                        isCallAppointmentDrawer: isCallTimer,
                        isAppointmentDrawer: !isCallTimer,
                        isEdit: true,
                        rowAppointmentId: record.AppointmentId,
                      });
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
                {isKept && (
                  <Tooltip placement="top" title="Notes">
                    <img
                      src="images/icons/peer_manage_notes.svg"
                      id="2"
                      onClick={event => {
                        record.IsVoipCallAppointmet
                          ? this.handleAppointmentDetail(record, event)
                          : this.handleAppointmentNote(record, event);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {record.IsVoipCallAppointmet && (
              <>
                <Tooltip
                  placement="top"
                  title={
                    record.IsCallRecordExist ? 'Play Recording' : 'Call Recording is being prepared'
                  }
                >
                  {record.IsCallRecordExist ? (
                    <img
                      src={`images/icons/call_recording.svg`}
                      id="0"
                      onClick={event => {
                        this.handleAppointmentDetail(record, event, record.IsVoipCallAppointmet);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  ) : (
                    <img src="images/icons/call_recording_processing.svg" />
                  )}
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="View Transcript"
                  // title="AI is processing the transcript"
                >
                  <img
                    src="images/icons/call_transcript.svg"
                    id="1"
                    onClick={event => {
                      this.handleAppointmentDetail(record, event, record.IsVoipCallAppointmet);
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                  {/* <img src="images/icons/call_transcript_processing.svg" /> */}
                </Tooltip>
              </>
            )}

            {/* {!record.IsVoipCallAppointmet && !isKept && (
                <span
                  className="assign-queue-action"
                  onClick={event => {
                    this.props.dispatch(
                      getAppointment({
                        json: JSON.stringify({
                          Appointment: [
                            {
                              AppointmentId: record.AppointmentId,
                            },
                          ],
                        }),
                      }),
                    );
                    this.toggleDrawer();
                  }}
                >
                  Manage Appointment
                </span>
              )} */}
          </div>
        );
      },
    },
  ];
  getCallEndDetails = (isRecored, callDetails, twilioData) => {
    let SID = callDetails.parameters.CallSid;
    this.setState({
      isCallAppointmentDrawer: isRecored ? true : false,
      CallSid: SID,
      twilioData: twilioData,
      isTwilioAppointment: true,
      initialData: {},
      isAppointmentDrawer: false,
    });
  };
  // call_recording_processing.svg
  // call_transcript_processing.svg
  // peer_manage_notes.svg
  // call_recording.svg
  // call_transcript.svg
  render() {
    const {
      ClientId,
      AppointmentList,
      Practitioner,
      ServiceCodeList,
      ProgramType,
      PlaceOfService,
      BannerData,
      Status,
      PractitionerOffice,
      TranscriptData,
      RecordingUrl,
      isFetching,
    } = this.props;
    const { PreItakeSurveyId, EnrollStatusId, IsPreIntakeArchived } = BannerData || {};
    let optionData = {
      Practitioner: Practitioner,
      ServiceCodeList: ServiceCodeList,
      ProgramType: ProgramType,
      PlaceOfService: PlaceOfService,
      Status: Status,
      PractitionerOffice: PractitionerOffice,
    };
    return (
      <div className="prospect-appointments">
        <div className="row p-5" style={{ position: 'relative' }}>
          <Loader2 loading={isFetching} />
          <div className="col-12 text-right">
            <button className="btn" onClick={this.toggleDrawer}>
              Add Appointment
            </button>
          </div>
          <div className="col-12">
            <PersistTableSort
              name="prospect-appointment"
              columns={this.columns}
              dataSource={AppointmentList}
              size={'middle'}
            />
          </div>
        </div>
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title">
                {this.state.initialData.AppointmentId ? `Edit` : `Add`} Appointment
              </span>
            </div>
          }
          placement="right"
          open={this.state.isAppointmentDrawer}
          width={700}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={() => {
            this.setState({
              initialData: {},
              isAppointmentDrawer: false,
            });
          }}
        >
          <ManageAppointment
            handleAppointment={this.handleAppointment}
            initialValues={this.state.initialData}
            AppointmentId={this.state.initialData.AppointmentId}
            ClientId={ClientId}
            BannerData={BannerData}
            OptionData={optionData}
            getCallEndDetails={this.getCallEndDetails}
            onClose={() => {
              this.setState({
                initialData: {},
                isAppointmentDrawer: false,
              });
              this.props.dispatch(upsertAppointmentReset());
            }}
            getAppointmentList={this.getAppointmentList}
          />
        </Drawer>
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title">Appointment Details</span>
            </div>
          }
          placement="right"
          open={this.state.isAppointmentDetailDrawer}
          width={700}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={() => {
            this.setState({
              initialData: {},
              isAppointmentDetailDrawer: false,
            });
          }}
          footer={
            <div className="drawer-footer">
              {RecordingUrl ? (
                <div className="col-12 player-footer">
                  <ReactAudioPlayer src={RecordingUrl} />
                </div>
              ) : (
                <div className="call-prepared-footer">
                  <span className="footer-text">
                    Call Recording is being prepared... it will be made available once the
                    preparation is successfully completed.
                  </span>
                </div>
              )}
            </div>
          }
        >
          <AppointmentDetails
            // initialData={this.state.initialData}
            AppointmentId={this.state.rowAppointmentId}
            TabId={this.state.tabId}
            ClientId={ClientId}
            BannerData={BannerData}
            OptionData={optionData}
            TranscriptData={TranscriptData}
            IsPeerRecovery={true}
          />
        </Drawer>
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title">
                {this.state.rowAppointmentId ? 'Edit Appointment' : 'Add Appointment'}
              </span>
            </div>
          }
          placement="right"
          open={this.state.isCallAppointmentDrawer}
          width={700}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={() => {
            this.setState({
              initialData: {},
              isCallAppointmentDrawer: false,
              CallSid: null,
              twilioData: null,
              isTwilioAppointment: false,
              isEdit: false,
            });
          }}
        >
          <CallEndedForm
            initialData={this.state.initialData}
            DurationInSec={this.state.initialData.TimerDurationInSec}
            AppointmentId={this.state.rowAppointmentId || this.state.initialData.AppointmentId}
            ClientId={ClientId}
            CallSid={this.state.CallSid}
            twilioData={this.state.twilioData}
            isTwilioAppointment={this.state.isTwilioAppointment}
            isEdit={this.state.isEdit}
            onClose={() => {
              this.setState({
                initialData: {},
                isCallAppointmentDrawer: false,
                CallSid: null,
                twilioData: null,
                isTwilioAppointment: false,
                isEdit: false,
              });
            }}
          />
        </Drawer>
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title">Appointment Details</span>
            </div>
          }
          placement="right"
          open={this.state.isAppointmentNoteDrawer}
          width={700}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={() => {
            this.setState({
              initialData: {},
              isAppointmentNoteDrawer: false,
            });
          }}
        >
          <AppointmentDetails
            initialData={this.state.initialData}
            AppointmentId={this.state.rowAppointmentId}
            TabId={this.state.tabId}
            ClientId={ClientId}
            BannerData={BannerData}
            OptionData={optionData}
            IsPeerRecovery={false}
          />
        </Drawer>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            const { CurrentClientId } = this.props.BannerData;
            this.getAppointmentList(CurrentClientId);
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    student_banner,
    get_client_appointment,
    get_appointment,
    practitioner_combos,
    combos,
    medicate_combos,
    get_combo_facility,
    combo_service_code_get,
    student_twilio_transcript_get,
  } = state;
  const { data, isFetching: isBannerFetching } = student_banner;
  const { data: appointmentData, isFetching } = get_client_appointment;
  const { data: practitionerData } = practitioner_combos;
  const { data: medicateCombos } = medicate_combos;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: ComboServiceCodeData } = combo_service_code_get;
  const { data: TwilioTranscriptData } = student_twilio_transcript_get;
  const {
    data: getAppointmentData,
    isFetching: isFetchingGetAppointment,
    error: errorGetAppointment,
  } = get_appointment;

  let BannerData = null;
  let AppointmentList = [],
    Practitioner = [],
    ServiceCodeList = [],
    ProgramType = [],
    PlaceOfService = [],
    PractitionerOffice = [],
    Status = [];
  let AppointmentData = null;
  let ClientId;
  let messageContentAppointmentSuccess;
  let warningContent;
  let TranscriptData;
  let RecordingUrl;
  if (data) {
    BannerData = data;
  }
  if (appointmentData && appointmentData.data) {
    AppointmentList = appointmentData.data || [];
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner =
      practitionerData.PractitionerList.filter(provider => provider.IsArchived === false) || [];
  }
  if (combos.data && combos.data['RCM_Program']) {
    // ServiceCodeList = combos.data['RCM_ServiceCode'] || [];
    ProgramType = combos.data['RCM_Program'] || [];
    PlaceOfService = combos.data['RCM_PlaceOfService'] || [];
  }
  if (medicateCombos) {
    Status = medicateCombos.Status;
  }
  if (ComboServiceCodeData && ComboServiceCodeData.data) {
    ServiceCodeList = JSON.parse(ComboServiceCodeData.data).Combo || [];
  }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (getAppointmentData && getAppointmentData.Appointment) {
    AppointmentData = getAppointmentData.Appointment[0];
    // warningContent = getAppointmentData.Warning;
    // ClientId = getAppointmentData.Appointment[0].DisplayClientId;
  }

  if (TwilioTranscriptData && TwilioTranscriptData.Transcripts) {
    TranscriptData = TwilioTranscriptData.Transcripts;
    RecordingUrl = TwilioTranscriptData.RecordingUrl;
  }
  return {
    AppointmentList,
    Practitioner,
    ServiceCodeList,
    ProgramType,
    PlaceOfService,
    Status,
    PractitionerOffice,
    AppointmentData,
    TranscriptData,
    RecordingUrl,
    isFetching: isFetching || isBannerFetching,
  };
};
export default connect(mapStateToProps)(withRouter(Appointments));
