import parsePhoneNumber from 'libphonenumber-js';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

export const getPhoneNumber = value => {
  let PhoneNumber = value && parsePhoneNumber(value);
  if (PhoneNumber) return PhoneNumber.nationalNumber;
};
// export const getCountryCode = value => {
//   let CountryCode = parsePhoneNumber(value);
//   return if (CountryCode) CountryCode.country;
// };
export const getCountryCalling = value => {
  let CallingCode = value && parsePhoneNumber(value);
  if (CallingCode) return parseInt(CallingCode.countryCallingCode);
};

export const isValidMobileNumber = value => {
  if (value && value.length > 2) {
    let newValue = value.includes('+') ? value : `+${value}`;
    let CallingCode = newValue && parsePhoneNumber(newValue);
    let nationalNumber = CallingCode && CallingCode.nationalNumber;
    if (nationalNumber) {
      let isValid = CallingCode && isValidPhoneNumber(newValue, CallingCode.country);
      let isValidLength = CallingCode && validatePhoneNumberLength(newValue, CallingCode.country);
      if (isValid) {
        return true;
      }
    } else return false;
  } else {
    return false;
  }
};

export const formatInternationalPhone = value => {
  let PhoneNumber = value && parsePhoneNumber(value);
  if (PhoneNumber) return PhoneNumber.formatInternational;
};

// const phoneNumber = parsePhoneNumber('+12133734253');

// phoneNumber.formatInternational() === '+1 213 373 4253';
// phoneNumber.formatNational() === '(213) 373-4253';
// phoneNumber.getURI() === 'tel:+12133734253';
