import moment from 'moment';

export const formatedTime = durationInSec => {
  return durationInSec && moment.utc(durationInSec * 1000).format('HH:mm:ss');
};
export const timeConvert = (value, formated) => {
  let num = value;
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  if (formated) return rhours + 'hour  ' + rminutes + 'minutes ';
  else return rhours + 'h ' + rminutes + 'm ';
};

export const mapTime = time => {
  if (typeof time === 'string') {
    return time;
  } else {
    return moment(time._d).format('HH:mm');
  }
};

//if start time and end time available and you want to calculate duration.

export const DurationInMinCalc = (startTime, endTime) => {
  let diff = moment(endTime, 'hh:mm').diff(moment(startTime, 'hh:mm'));
  let d = moment.duration(diff);
  let durationInMin = d._milliseconds / 60000;
  //future use code below
  // let hours =  Math.floor(d.asHours());
  // let minutes = moment.utc(diff).format("mm");
  // this.totalAmount = hours * hourlyCharge + parseInt(minutes) * hourlyCharge/60;
  // this.total =hours +" hours " + minutes + " minutes";

  return durationInMin;
};
