import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import { ProfilePicComponent } from '../../../../../../Components/profilePicComponent';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';

class ExternalCareTeamMemberView extends Component {
  constructor() {
    super();
    this.state = {};
  }
  columns = [
    {
      title: 'Facility',
      dataIndex: 'FacilityName',
      key: 'FacilityName',
      sorter: (a, b) => isComparer(a.FacilityName, b.FacilityName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Facility Phone Number',
      dataIndex: 'PhoneNumber',
      key: 'PhoneNumber',
      sorter: (a, b) => isComparer(a.PhoneNumber, b.PhoneNumber),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Facility Location',
      dataIndex: 'Location',
      key: 'Location',
      sorter: (a, b) => isComparer(a.Location, b.Location),
      sortDirections: ['descend', 'ascend'],
      // render: (text, record, index) => {
      //   return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>;
      // },
    },
  ];
  render() {
    const { ExternalProviderData, IsAdmin } = this.props;
    const {
      FirstName,
      LastName,
      NPI,
      Gender,
      EmailWork,
      Location,
      Specialty,
      ExternalProviderId,
      Facility,
      GenderText,
      SpecialtyText,
      CountryISO,
      State,
      City,
      ZipCode,
      PhoneNumber,
      Status,
    } = ExternalProviderData;
    return (
      <div className="row enrollment-form mt-2 external-care-team-member-view">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <div className="d-flex">
              <ProfilePicComponent />
              <div className="view ml-2">
                <span className="text">
                  {FirstName} {LastName}
                </span>
                <span className="label">External Member</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div className="view">
              <span className="label">Gender</span>
              <span className="text">{GenderText}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div className="view">
              <span className="label">Email</span>
              <span className="text">{EmailWork}</span>
            </div>
          </div>
        </div>

        {IsAdmin && (
          <div className="col-12">
            <div className="row">
              {/* <div class="col-12 col-md-6">
                <div class="form-group">
                  <div className="view">
                    <span className="label">Country</span>
                    <span className="text">{CountryISO}</span>
                  </div>
                </div>
              </div> */}
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div className="view">
                    <span className="label">State</span>
                    <span className="text">{State}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div className="view">
                    <span className="label">City</span>
                    <span className="text">{City}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div className="view">
                    <span className="label">Zip</span>
                    <span className="text">{ZipCode}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div className="view">
                    <span className="label">Phone Number</span>
                    <span className="text">{PhoneNumber}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div className="view">
                    <span className="label">Status</span>
                    <span className="text">{Status}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div className="view">
              <span className="label">National Provider Identifier Number</span>
              <span className="text">{NPI}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div className="view">
              <span className="label">Specialities</span>
              <span className="text">{SpecialtyText}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <span
            style={{
              color: '#7F8188',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            Facility and Facility Locations
          </span>
          <Table
            columns={this.columns}
            dataSource={Facility}
            pagination={false}
            scroll={{
              y: 450,
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect()(ExternalCareTeamMemberView);
