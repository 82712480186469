import React from 'react';
import _blank from '../images/blank-profile.png';

export const ProfileInOption = ({ OptionData, isValue, valueText }) => {
  const { Text, Value, ImageUrl } = OptionData;
  return (
    <div className="profile-in-option">
      <div className="profile">
        <img
          src={ImageUrl || _blank}
          className="mr-1"
          height="40"
          width="40"
          style={{
            borderRadius: '50%',
            objectFit: 'cover',
            minHeight: '40px',
            minWidth: '40px',
          }}
        />
      </div>

      <div className="detail">
        <span className="text">{Text}</span>
        {isValue && (
          <span className="value">
            {`${valueText} : `}
            {Value}
          </span>
        )}
      </div>
    </div>
  );
};
