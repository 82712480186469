import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Tooltip, Tag } from 'antd';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import {
  upsertServiceCode,
  resetUpsertServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
  serviceCodeBundleGetAll,
  resetGetServiceCode,
  serviceCodeBundleGet,
  serviceCodeBundleDelete,
} from '../../../store/actions';
import CustomModal from '../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import AntdModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { ProcessCombos } from '../../../store/comboConfig';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../../../app-config';
import PersistTableSort from '../../../component/persistTableSort';
import { currencyFormatter, numberFormat } from '../../../util/formatter';
import PageTitle from '../../../Components/PageTitle';
class ServiceCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceCodeList: [],
      ServiceCodeInfo: {},
      createAndEditGroupModal: false,
      codeDetails: '',
      codeModifierId1: '',
      codeModifierId2: '',
      codeModifierId3: '',
      detailDescription: '',
      searchStr: '',
    };
  }
  // need to work on it always return zero for me
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  refreshGrid = () => {
    const { searchStr } = this.state;
    this.setState({
      searchStr,
    });

    this.props.dispatch(
      serviceCodeBundleGetAll({
        JsonInput: JSON.stringify({
          ServiceCodeBundle: [
            {
              Search: searchStr,
            },
          ],
        }),
      }),
    );

    sessionStorage.setItem(
      'appliedFilter',
      JSON.stringify({
        // Filter: filterType && filterType.toString(),
        searchStr: searchStr,
        // startDate,
        // endDate,
        // filterCenterId,
        // FilterCounserlorId,
      }),
    );
  };

  componentDidMount() {
    this.props.dispatch(serviceCodeBundleGetAll({}));
    this.props.dispatch(comboCode({}));
    var result = ProcessCombos('UnitOfMeasurement,CenterProgramType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  resetcodeIdandModifiers = () => {
    this.setState({
      codeDetails: '',
      codeModifierId1: '',
      codeModifierId2: '',
      codeModifierId3: '',
      ServiceCodeInfo: {},
    });
  };
  componentWillReceiveProps({
    isInserted,
    isDeleted,
    isRemoved,
    serviceCodeList,
    // serviceCodeinfo,
    codeDetails,
    codeId,
    codeModifierValue,
    codeModifierIndex,
  }) {
    if (codeDetails) {
      if (codeDetails) {
        var data = {
          ...this.state.ServiceCodeInfo,
          detailDescription: codeDetails,
          code: codeId,
        };
        // this.setState({ codeDetails, detailDescription: codeDetails, ServiceCodeInfo: data });
        this.setState({
          codeDetails,
          detailDescription: codeDetails,
          ServiceCodeInfo: data,
        });
      } else {
        this.setState({ codeDetails });
      }
    }
    if (codeModifierIndex) {
      if (codeModifierIndex == 'codeModifier1') {
        this.setState({ codeModifierId1: codeModifierValue });
      }
      if (codeModifierIndex == 'codeModifier2') {
        this.setState({ codeModifierId2: codeModifierValue });
      }
      if (codeModifierIndex == 'codeModifier3') {
        this.setState({ codeModifierId3: codeModifierValue });
      }
    }
    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditGroupModal: false });
      this.toggle('Service Code Saved Successfully');
      // this.props.dispatch(resetUpsertServiceCode());
      // this.props.dispatch(reset('serviceCodeForm'));
      // this.setState({
      //   ServiceCodeInfo: {},
      // });
    }
    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.props.dispatch(serviceCodeBundleGetAll({}));
    }
    if (isRemoved && isRemoved != this.props.isRemoved) {
      this.toggle('Service Code Deleted Successfully');
    }
    if (
      serviceCodeList &&
      JSON.stringify(serviceCodeList) != JSON.stringify(this.props.serviceCodeList)
    ) {
      this.setState({
        ServiceCodeList: serviceCodeList,
      });
    }
    // if (
    //   serviceCodeinfo &&
    //   JSON.stringify(serviceCodeinfo) != JSON.stringify(this.props.serviceCodeinfo)
    // ) {
    //   let data = {
    //     ServiceCodeId: serviceCodeinfo.ServiceCodeId,
    //     serviceCodeName: serviceCodeinfo.ServiceCodeName,
    //     code: serviceCodeinfo.CodeName,
    //     modifier1: serviceCodeinfo.CodeModifierId1,
    //     modifier2: serviceCodeinfo.CodeModifierId2,
    //     modifier3: serviceCodeinfo.CodeModifierId3,
    //     // codeDetails: serviceCodeinfo.CodeDescription,
    //     detailDescription: serviceCodeinfo.ServiceCodeDescription,
    //     UnitOfMeasurementId: serviceCodeinfo.UnitOfMeasurementId,
    //     // detailDescription: serviceCodeinfo.CodeDescription,
    //     ShortServiceDescription: serviceCodeinfo.ShortServiceDescription,
    //     IsPack: serviceCodeinfo.IsPack,
    //   };
    //   this.setState({
    //     codeDetails: serviceCodeinfo.CodeDescription,
    //     codeModifierId1: serviceCodeinfo.CodeModifierDescription1,
    //     codeModifierId2: serviceCodeinfo.CodeModifierDescription2,
    //     codeModifierId3: serviceCodeinfo.CodeModifierDescription3,
    //     // detailDescription: serviceCodeinfo.ServiceCodeDescription,
    //     ServiceCodeInfo: data,
    //   });

    //   // this.setState({
    //   //   ServiceCodeInfo: data,
    //   // });
    // }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      ServiceCodeId,
      code,
      modifier1,
      modifier2,
      modifier3,
      detailDescription,
      UnitOfMeasurementId,
      ShortServiceDescription,
      IsPack,
    } = values;
    // let patientId = null;
    // let paramClientId = this.props.clientId;
    // if (paramClientId && paramClientId > 0) {
    //   patientId = paramClientId;
    // }
    const data = {
      ServiceCode: [
        {
          // ClientId: patientId,
          ServiceCodeId: this.state.ServiceCodeInfo.ServiceCodeId || null,
          CodeId: code,
          CodeModifierId1: modifier1,
          CodeModifierId2: modifier2,
          CodeModifierId3: modifier3,
          ServiceCodeDescription: detailDescription,
          UnitOfMeasurementId: UnitOfMeasurementId,
          ShortServiceDescription: ShortServiceDescription,
          IsPack: IsPack,
        },
      ],
    };
    let Data = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertServiceCode(Data));
  }

  render() {
    const tempColumns = [
      {
        title: 'Name',
        dataIndex: 'Description',
        key: 'Description',
        sorter: (a, b) => isComparer(a.Name, b.Name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Days in Treatment',
        dataIndex: 'DaysInTreatment',
        key: 'DaysInTreatment',
        sorter: (a, b) => isNumericComparer(a.DaysInTreatment, b.DaysInTreatment),
        sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => {
          return <div>{record.DaysInTreatment ? record.DaysInTreatment : '0'} Day(s) </div>;
        },
      },
      {
        title: 'Standard Cost',
        dataIndex: 'StandardCost',
        key: 'StandardCost',
        sorter: (a, b) => isNumericComparer(a.StandardCost, b.StandardCost),
        sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => {
          return <div>{currencyFormatter.format(record.StandardCost || 0)}</div>;
        },
      },
      {
        title: 'Billing Rate',
        dataIndex: 'BillingRate',
        key: 'BillingRate',
        sorter: (a, b) => isNumericComparer(a.BillingRate, b.BillingRate),
        sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => {
          return <div>{currencyFormatter.format(record.BillingRate || 0)}</div>;
        },
      },
      {
        title: 'Total Net Margin %',
        dataIndex: 'NetMargin',
        key: 'NetMargin',
        sorter: (a, b) => isNumericComparer(a.NetMargin, b.NetMargin),
        sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => {
          return <div>{record.NetMargin ? `${record.NetMargin}%` : null}</div>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'IsSuspended',
        key: 'IsSuspended',
        sorter: (a, b) => isNumericComparer(a.IsSuspended, b.IsSuspended),
        sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => {
          return (
            <div>
              {record.IsSuspended ? (
                <Tag bordered={false} color="error">
                  Suspended
                </Tag>
              ) : (
                <Tag bordered={false} color="success">
                  Active
                </Tag>
              )}
            </div>
          );
        },
      },
      {
        title: 'Updated on',
        dataIndex: 'UpdatedOn',
        key: 'UpdatedOn',
        sorter: (a, b) => isDateComparer(a.UpdatedOnForSort, b.UpdatedOnForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        width: 100,
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <>
                {!record.IsSuspended ? (
                  <div class="tools-grid">
                    <div className="mr-4 mt-1">
                      <span
                        className="start-btn"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Tooltip placement="top" title="Edit" arrow="true">
                          {' '}
                          {/* suspend RestoreBlack */}
                          <img
                            src="images/shape.svg"
                            width="14px"
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={event => {
                              // this.setState({
                              //   createAndEditGroupModal: true,
                              // });
                              this.props.history.push(
                                `/cw/manage/service-bundle?bundleId=${record.ServiceCodeBundleId}`,
                              );
                              // this.props.dispatch(
                              //   serviceCodeBundleGet({
                              //     JsonInput: JSON.stringify({
                              //       ServiceCodeBundle: [
                              //         {
                              //           ServiceCodeBundleId: record.ServiceCodeBundleId,
                              //         },
                              //       ],
                              //     }),
                              //   }),
                              // );
                            }}
                          />
                        </Tooltip>
                      </span>
                    </div>
                    <div className="">
                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <span
                            class="delete-btn"
                            onClick={onClick}
                            data-toggle="modal"
                            data-target="#delete-Screen-Tool"
                          >
                            <Tooltip placement="top" title="Suspend">
                              <p>
                                {/* <i class="far fa-trash-alt"></i> */}
                                <img
                                  src="images/suspend.svg"
                                  width="20px"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </p>
                            </Tooltip>
                          </span>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 class="modal-title col-12 mb-3">
                                Are you sure you want to suspend this bundle for future use?
                              </h4>
                            </div>
                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                class="btn btn-outline-primary black-btn"
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                No
                              </button>
                              <button
                                class="btn"
                                onClick={() => {
                                  onOk();
                                  this.props.dispatch(
                                    serviceCodeBundleDelete({
                                      JsonInput: JSON.stringify({
                                        ServiceCode: [
                                          {
                                            ServiceCodeBundleId: record.ServiceCodeBundleId,
                                            IsArchived: 1,
                                          },
                                        ],
                                      }),
                                    }),
                                  );
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="text-center">
                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <span
                            class="delete-btn"
                            onClick={onClick}
                            data-toggle="modal"
                            data-target="#delete-Screen-Tool"
                          >
                            <Tooltip placement="top" title="Restore">
                              <p>
                                {/* <i class="far fa-trash-alt"></i> */}
                                <img
                                  src="images/RestoreBlack.svg"
                                  width="20px"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </p>
                            </Tooltip>
                          </span>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 class="modal-title col-12 mb-3"> Restore
                                bundle? Once restored, it can be added to contracts as an active
                                option.
                              </h4>
                            </div>
                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                class="btn btn-outline-primary black-btn"
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                class="btn"
                                onClick={() => {
                                  onOk();
                                  this.props.dispatch(
                                    serviceCodeBundleDelete({
                                      JsonInput: JSON.stringify({
                                        ServiceCode: [
                                          {
                                            ServiceCodeBundleId: record.ServiceCodeBundleId,
                                            IsArchived: 0,
                                          },
                                        ],
                                      }),
                                    }),
                                  );
                                }}
                              >
                                Restore
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </>
                )}
              </>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const { ServiceCodeList } = this.state;
    const { isReadOnlyUser } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Service Bundle | Master Bill of Services" />
        <div className="container-fluid mt-5 masterBillOfService">
          <div className="">
            <div className="row px-0 mt-5 mb-2 d-flex align-items-end">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 px-0">
                {' '}
                <h4 className="">SERVICE BUNDLE</h4>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5 px-0 d-flex align-items-center">
                <div class="search-box d-inline-flex w-100">
                  <div class="has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 px-0 d-flex align-items-center justify-content-end">
                {/* <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  value={'Filter'}
                /> */}
                {!isReadOnlyUser && (
                  <input
                    type="button"
                    style={{ width: '250px' }}
                    class="btn"
                    value={'Add New Service Bundle'}
                    onClick={event => {
                      this.props.history.push('/cw/manage/service-bundle');
                      this.props.dispatch(resetGetServiceCode({}));
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* <ReduxServiceCodeForm
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.ServiceCodeInfo}
            ServiceCodeId={this.state.ServiceCodeInfo.ServiceCodeId}
            onCancel={this.props.onCancel}
            Delete={() => {
              this.Removetoggle();
              //this.props.history.push('/manage/courses')
            }}
          /> */}
          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table rcm-panel">
                  <PersistTableSort
                    name="service-code-list"
                    // bordered
                    columns={tempColumns}
                    dataSource={ServiceCodeList}
                    size={'middle'}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(serviceCodeBundleGetAll({}));
            this.toggle();
            // this.props.history.push('/manage/courses');
            this.props.dispatch(resetUpsertServiceCode());
            this.props.dispatch(reset('serviceCodeForm'));
            this.resetcodeIdandModifiers();
            // this.setState({
            //   ServiceCodeInfo: {},
            // });
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(courseDelete({ CourseId: this.getClientId() }));
            // this.props.history.push('/manage/courses');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            // this.props.history.push('/manage/courses');
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToPropsParent = ({
  upsert_service_code,
  service_code_bundle_get_all,
  delete_service_code,
  get_service_code,
  combo_code,
  get_code_description,
  service_code_bundle_delete,
}) => {
  const { data: serviceCodeListData, isFetching } = service_code_bundle_get_all;
  // const { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode } = get_service_code;
  const { data: comboCode } = combo_code;
  const { data: codeDesc } = get_code_description;
  let codeDetails;
  let codeId;
  let codeModifierValue;
  let codeModifierIndex;
  let code;
  let codeModifier;
  let isInserted = false;
  let isRemoved = false;
  let serviceCodeList = [];
  let isDeleted = false;
  // let serviceCodeinfo = {};
  if (upsert_service_code && upsert_service_code.data && upsert_service_code.data.success) {
    isInserted = true;
  }
  if (delete_service_code && delete_service_code.data && delete_service_code.data.success) {
    isRemoved = true;
  }
  if (service_code_bundle_get_all && service_code_bundle_get_all.data) {
    serviceCodeList = serviceCodeListData.ServiceCodeBundle || [];
  }
  if (
    service_code_bundle_delete &&
    service_code_bundle_delete.data &&
    service_code_bundle_delete.data.success
  ) {
    isDeleted = service_code_bundle_delete.data.success || [];
  }

  // if (serviceCodeinfoData && serviceCodeinfoData.ServiceCode) {
  //   serviceCodeinfo = serviceCodeinfoData.ServiceCode[0];
  // }

  if (comboCode && comboCode[0].Code) {
    code = comboCode[0].Code;
  }
  if (comboCode && comboCode[0].CodeModifier) {
    codeModifier = comboCode[0].CodeModifier;
  }
  if (codeDesc && codeDesc) {
    codeDetails = codeDesc[0].Code && codeDesc[0].Code[0] && codeDesc[0].Code[0].CodeDescription;
    codeId = codeDesc[0].Code && codeDesc[0].Code[0] && codeDesc[0].Code[0].CodeId;
    codeModifierValue =
      codeDesc[0].CodeModifier &&
      codeDesc[0].CodeModifier[0] &&
      codeDesc[0].CodeModifier[0].CodeModifierDescription;
    codeModifierIndex =
      codeDesc[0].CodeModifier &&
      codeDesc[0].CodeModifier[0] &&
      codeDesc[0].CodeModifier[0].CodeModifierIndex;
  }
  return {
    isInserted,
    isRemoved,
    serviceCodeList: serviceCodeList,
    isFetching: isFetching,
    // serviceCodeinfo: serviceCodeinfo,
    code,
    codeModifier,
    codeDetails,
    codeId,
    codeModifierValue,
    codeModifierIndex,
    isDeleted,
    // isFetchingGetServiceCode,
  };
};
export default connect(mapStateToPropsParent)(ServiceCode);
const selector = formValueSelector('serviceCodeForm');
