import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SuggestCPTCode from './SuggestCPTCode';
class Transcript extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { TranscriptData, AppointmentData, StudentId, IsServiceCodeApproved } = this.props;
    return (
      <div className="transcript mt-2">
        {TranscriptData ? (
          <div className="row">
            <div className="col-12 mb-2">
              <span className="transcript-title">Call Transcript</span>
            </div>
            {TranscriptData.map((record, index) => {
              const { Speaker, Transcript } = record;
              return (
                <div className="col-12">
                  <div className="transcript-row row">
                    <div className="col-3 pr-1">
                      <span className="speaker-text">{Speaker} : </span>
                    </div>
                    <div className="col-9 pl-0">
                      <span className="transcript-text">{Transcript}</span>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="col-12 mb-2">
              <SuggestCPTCode
                AppointmentId={AppointmentData.AppointmentId}
                StudentId={StudentId}
                IsServiceCodeApproved={IsServiceCodeApproved}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="transcript-preparing">
                <span className="transcript-preparing-text">
                  Call Transcript is being prepared...we will notify you when it is completed
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect()(withRouter(Transcript));
