import React, { Component } from 'react';
import { Input } from 'antd';
import SubmitButton from '../../../../../Components/submitButton';
import { payerPortalRequestUpsert } from '../../../../../store/actions';

const { TextArea } = Input;

export default class SeriveCodeRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  addNoteChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  onRequestSubmit = () => {
    this.props.dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: null,
              SmartContractId: this.props.contractId,
              RequestType: '30201',
              Id:
                this.props.serviceCodeRequestDetails.smartContractServiceBundleServiceCodeId ||
                null,
              Note: this.state.value,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { bundleName, categoryName, serviceCode } = this.props.serviceCodeRequestDetails || {};
    return (
      <div>
        <p
          className='warning-info-text'
        >
          You can request changes only for the services you've been assigned to.
        </p>
        <div className="row flex-wrap">
          <div className="client-detail-box col-5 m-0">
            <p className="client-label mb-0">Select Bundle Name*</p>
            <p className="client-text mb-0">{`${bundleName}`}</p>
          </div>
          <div className="client-detail-box col-5 m-0">
            <p className="client-label mb-0">Service Category*</p>
            <p className="client-text mb-0">{`${categoryName}`}</p>
          </div>
          <div className="client-detail-box col-5 m-0">
            <p className="client-label mb-0">Service Code*</p>
            <p className="client-text mb-0">{`${serviceCode}`}</p>
          </div>
        </div>

        <div className="mt-3">
          <h6>Add Note*</h6>
          <TextArea rows={5} value={this.state.value} onChange={this.addNoteChange} />
        </div>

        <div className="d-flex justify-content-end mt-5 align-items-center  w-100">
          <button
            type="button"
            className="btn mr-3 btn-outline-primary"
            onClick={this.props.openAndCloseModal}
          >
            Cancel
          </button>
          {/* <button type="button" className="btn btn-primary" onClick={this.onRequestSubmit}>
            Send Request
          </button> */}
          <SubmitButton
            value={'Send Request'}
            loading={this.props.isReqFetching}
            // className="btn btn-primary"
            style={{ height: '45px' }}
            onClick={this.onRequestSubmit}
            disabled={!(this.state.value && this.state.value.length > 0)}
          />
        </div>
      </div>
    );
  }
}
