import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';

function MergeClientsComponent(props) {
  const { item, mIndex, primaryStudentDetailsText, secondaryStudentDetailsText, onSelect } = props;
  const [value, setValue] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [primaryStudentText, setPrimaryStudentText] = useState(null);
  const [secondaryStudentText, setSecondaryStudentText] = useState(null);
  const [selectClient, setSelectClient] = useState(null);

  useEffect(() => {
    setValue(primaryStudentDetailsText[mIndex] ? primaryStudentDetailsText[mIndex].Text : null);

    onSelect(
      primaryStudentDetailsText[mIndex] ? primaryStudentDetailsText[mIndex].Value : null,
      mIndex,
    );

    if (primaryStudentDetailsText.length) {
      setPrimaryStudentText(primaryStudentDetailsText[mIndex].Text);
      setSelectClient(1);
      if (secondaryStudentDetailsText.length) {
        setSecondaryStudentText(secondaryStudentDetailsText[mIndex].Text);
        if (primaryStudentDetailsText[mIndex].Value == secondaryStudentDetailsText[mIndex].Value) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }
    }
  }, [primaryStudentDetailsText, secondaryStudentDetailsText]);

  const onChange = e => {
    setValue(e.target.value);
    onSelect(e.target.value, mIndex);
  };

  useEffect(() => {
    let array = {};
    if (selectClient == 1) {
      array =
        primaryStudentDetailsText && primaryStudentDetailsText[mIndex].Text == value
          ? primaryStudentDetailsText[mIndex]
          : null;
      // primaryStudentDetailsText.filter((item, index) => {
      //   if (item.Text == value) {
      //     return item;
      //   }
      // });
    } else if (selectClient == 2) {
      array =
        secondaryStudentDetailsText && secondaryStudentDetailsText[mIndex].Text == value
          ? secondaryStudentDetailsText[mIndex]
          : null;
      // secondaryStudentDetailsText.filter((item, index) => {
      //   if (item.Text == value) {
      //     return item;
      //   }
      // });
    }

    if (Object.keys(array).length) {
      onSelect(array.Value, mIndex);
    }
  }, [selectClient]);

  return (
    <div className="d-flex justify-content-center row">
      <p className="merge-clients-field-name d-flex align-items-center mb-0 col-12 col-md-3 text-uppercase">
        {item.Text}
      </p>
      <Radio.Group
        className="d-flex merge-clients-radio-group col-12 col-md-5"
        onChange={onChange}
        value={value}
        disabled={isDisabled}
      >
        <div className="merge-clients-radio col-12 col-md-6 p-0">
          {primaryStudentDetailsText.length && (
            <Radio
              className="merge-radio"
              // style={{ alignItems: 'flex-start', wordBreak: 'break-word', height: '100%' }}
              //   defaultChecked={true}
              value={primaryStudentText}
              onChange={() => setSelectClient(1)}
            >
              {primaryStudentText}
            </Radio>
          )}
        </div>
        <div className="merge-clients-radio col-12 col-md-6 p-0">
          {secondaryStudentDetailsText.length && (
            <Radio
              className="merge-radio"
              // style={{ alignItems: 'flex-start', wordBreak: 'break-word', height: '100%' }}
              value={secondaryStudentText}
              onChange={() => setSelectClient(2)}
              //   disabled={
              //     secondaryStudentDetailsText && secondaryStudentDetailsText.length ? false : true
              //     // !(secondaryStudentDetailsText[mIndex] && secondaryStudentDetailsText[mIndex].Text)
              //     //   ? false
              //     //   : true
              //   }
            >
              {secondaryStudentText}
            </Radio>
          )}
        </div>
      </Radio.Group>
      <p className="merge-clients-preview mb-0 col-12 col-md-3">{value}</p>
    </div>
  );
}

export default MergeClientsComponent;
