import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { Loader2 } from '../../Components/Loader';
import SubmitButton from '../../Components/submitButton';
import ManageFields from './manageFields';
import { listCombos, centerFieldGet, centerFieldUpsert } from '../../store/actions';

class CustomFieldsParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setOrderModal: false,
    };
  }
  getCenterId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var centerId = param.get('centerId') || param.get('centerid');
    if (centerId) {
      return (centerId = parseInt(centerId));
    } else {
      centerId = -1;
    }
    return centerId;
  }

  componentDidMount() {
    let data = { Center: { CenterId: this.getCenterId() } };
    this.props.dispatch(
      listCombos({
        comboTypes: 'CenterField',
        centerId: this.getCenterId(),
        Json: JSON.stringify(data),
      }),
    );
    this.props.dispatch(
      centerFieldGet({
        JsonInput: JSON.stringify({
          CenterField: {
            CenterId: this.getCenterId(),
          },
        }),
      }),
    );
  }

  componentWillUnmount() {
    this.props.dispatch(reset('centerCustomFieldsForm'));
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  getFieldValue = (fieldValue, componentType) => {
    if (componentType == 'ReduxToggle') {
      return fieldValue == '0' ? false : true;
    } else {
      return fieldValue;
    }
  };
  mapCustomFieldsToState = () => {
    let { centerField } = this.props;
    let CenterFieldsData = centerField && centerField;
    let CenterFieldInformations = [];
    if (CenterFieldsData) {
      CenterFieldInformations = [];
      for (let item of CenterFieldsData) {
        let centerFieldInformation = {};
        centerFieldInformation.centerFieldName = item.FieldName;
        centerFieldInformation.text = item.Text;
        centerFieldInformation.centerFieldValue = this.getFieldValue(
          item.FieldValue,
          item.ComponentType,
        );
        // item.FieldValue == '0' ? false : item.FieldValue == '1' ? true : false;
        centerFieldInformation.allowChildCenter = item.AllowChildCenter;
        centerFieldInformation.isParentData = item.IsParentData;
        centerFieldInformation.componentType = item.ComponentType;
        CenterFieldInformations.push(centerFieldInformation);
      }
    }
    return CenterFieldInformations;
  };
  onSubmit(values) {
    const { CenterCustomFields } = values;

    const data = {
      CenterId: this.getCenterId(),
      CenterField:
        CenterCustomFields &&
        CenterCustomFields.map(item => {
          return {
            FieldName: item.centerFieldName,
            FieldValue:
              item.centerFieldValue == true
                ? '1'
                : typeof item.centerFieldValue == 'string'
                ? item.centerFieldValue
                : '0',
            AllowChildCenter: item.allowChildCenter,
          };
        }),
    };
    let fields = {
      JsonInput: JSON.stringify(data),
    };
    this.props.dispatch(centerFieldUpsert(fields));
  }
  render() {
    return (
      <React.Fragment>
        <ReduxCenterFieldsUI
          onSaveClick={this.onSubmit.bind(this)}
          centerId={this.getCenterId()}
          initialValues={{
            CenterCustomFields: this.mapCustomFieldsToState(),
          }}
          disable={this.props.isDeActivated || this.props.isReadOnlyUser}
          onCancel={this.props.onCancel}
          isCenterFieldUpsert={this.props.isCenterFieldUpsert}
          centerFieldsData={this.props.centerFieldsData}
          isFetching={this.props.isFetching}
          style={{ padding: '36px 20px 150px 20px' }}
        />
      </React.Fragment>
    );
  }
}

const CenterFieldsUI = props => {
  const {
    handleSubmit,
    onSaveClick,
    handleNext,
    name,
    pristine,
    submitting,
    onLastSubmit,
    style,
    disable,
    existingFieldRows,
    reset,
    centerFieldsData,
    change,
    onCancel,
    isFetching,
    isCenterFieldUpsert,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSaveClick)} style={style}>
      <Loader2 loading={isFetching} />
      <div class="row rcm-tab mb-3 enrollment-form">
        <div class="col-12">
          <FieldArray
            name="CenterCustomFields"
            component={ManageFields}
            existingFieldRows={existingFieldRows}
            disable={disable}
            dispatch={props.dispatch}
            centerFieldsData={centerFieldsData}
            change={change}
          />
        </div>
      </div>

      {existingFieldRows && existingFieldRows.length > 0 && (
        <div
          class="row pagination-button"
          style={{ marginTop: '100px', position: 'relative', right: '-19px' }}
        >
          {!disable && (
            <div class="col-12 text-right">
              <input
                type="button"
                class="btn btn-outline-primary mr-2"
                value="Cancel"
                onClick={onCancel}
                disabled={disable}
              />
              {/* <input type="submit" class="btn" value="Save" disabled={disable} /> */}
              <SubmitButton loading={isCenterFieldUpsert} value="Save" disabled={disable} />
            </div>
          )}
        </div>
      )}
    </form>
  );
};

const mapStateToProps = (state, props) => {
  const { combos } = state;
  return {
    existingFieldRows: selector(state, 'CenterCustomFields'),
  };
};

const ReduxCenterFieldsUI = reduxForm({
  form: 'centerCustomFieldsForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(CenterFieldsUI)));

const mapStateToPropsParent = (state, props) => {
  const { combos, center_field_get } = state;
  const { data: combosData } = combos;
  const { data: fieldsInfo, isFetching } = center_field_get;
  let centerFieldsData = [];
  if (combosData && combosData.CenterField) {
    centerFieldsData = combosData.CenterField;
  }
  let centerField = [];

  if (fieldsInfo && fieldsInfo.CenterField) {
    centerField = fieldsInfo.CenterField;
  }
  return {
    centerField,
    centerFieldsData,
    isFetching,
  };
};
export default connect(mapStateToPropsParent)(withRouter(CustomFieldsParent));
const selector = formValueSelector('centerCustomFieldsForm');
