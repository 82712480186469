import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoCompleteText,
  ReduxDollarInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../component/custom-redux-components';

const COMPONENT_TYPES = {
  ReduxSelect: ReduxSelect,
  ReduxInput: ReduxInput,
  ReduxDateInput: ReduxDateInput,
  ReduxToggle: ReduxToggle,
  ReduxAutoComplete: ReduxAutoComplete,
  ReduxPhoneInput: ReduxPhoneInput,
  ReduxDollarInput: ReduxDollarInput,
  ReduxTextarea: ReduxTextarea,
};

const Validator = {
  required: value => (value ? undefined : 'Required'),
};

const ManageFields = ({
  fields,
  name,
  meta: { touched, error },
  disable,
  dispatch,
  existingFieldRows,
  centerFieldsData,
}) => {
  useEffect(() => {
    if (fields && fields.length == 0) {
      return fields.push({});
    }
  }, []);
  let array = [];
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentFieldName, setCurrentFieldName] = useState(null);
  const [existingCustomFields, setExistingCustomFields] = useState([]);
  const [opd, setOpd] = useState([]); // Opd stands for override parrent data

  useEffect(() => {
    if (currentIndex >= 0 && currentFieldName) {
      centerFieldsData &&
        centerFieldsData.filter(item => {
          if (item.Value == currentFieldName) {
            array.push(item);
          }
        });
      if (existingFieldRows[currentIndex]) {
        existingFieldRows[currentIndex].componentType = array && array[0].ComponentType;
        existingFieldRows[currentIndex].text = array && array[0].Text;
      }
    }
    setExistingCustomFields(existingFieldRows);
  }, [currentIndex, currentFieldName, existingFieldRows]);

  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3" style={{ backgroundColor: '#F7F7F7' }}>
              <div className="d-flex align-items-center mt-3 col-12">
                <div class="col-12 col-md-8">
                  <div class="form-group mb-0">
                    <Field
                      name={`${item}.centerFieldName`}
                      type="text"
                      errorMessage="Select Custom Field"
                      component={ReduxSelect}
                      onChange={fieldName => {
                        setCurrentFieldName(fieldName);
                        setCurrentIndex(index);
                        existingCustomFields[index].centerFieldValue = null;
                      }}
                      options={centerFieldsData}
                      placeholder="Select Custom Field*"
                      validate={Validator.required}
                      fieldName={'Select Custom Field'}
                      disabled={
                        disable ||
                        (existingCustomFields &&
                          existingCustomFields.length > 0 &&
                          existingCustomFields[index] &&
                          existingCustomFields[index].isParentData &&
                          !existingCustomFields[index].opdd)
                        // (existingCustomFields &&
                        //   existingCustomFields.length > 0 &&
                        //   existingCustomFields[index] &&
                        //   existingCustomFields[index].isParentData &&
                        //   existingCustomFields[index].opd)
                      }
                    />
                  </div>
                </div>
                {!disable && (
                  <div className="col-12 col-md-4">
                    {/* {fields.length > 1 && ( */}
                    <a
                      class="delete-contact"
                      style={{ margin: '0px', fontSize: '16px', fontWeight: '800' }}
                      disabled={
                        disable ||
                        (existingCustomFields &&
                          existingCustomFields.length > 0 &&
                          existingCustomFields[index] &&
                          existingCustomFields[index].isParentData &&
                          !existingCustomFields[index].opdd)
                      }
                      onClick={() =>
                        disable ||
                        (existingCustomFields &&
                          existingCustomFields.length > 0 &&
                          existingCustomFields[index] &&
                          existingCustomFields[index].isParentData &&
                          !existingCustomFields[index].opdd)
                          ? null
                          : fields.remove(index)
                      }
                    >
                      <i class="fas fa-trash" /> Remove
                    </a>
                    {/* )} */}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center mt-4 col-12">
                {existingCustomFields &&
                  existingCustomFields.length > 0 &&
                  existingCustomFields[index] &&
                  existingCustomFields[index].componentType && (
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        {String(existingCustomFields[index].componentType) == 'ReduxToggle' ? (
                          <label class="custom-control-label" for>
                            <Field
                              name={`${item}.centerFieldValue`}
                              type="text"
                              component={COMPONENT_TYPES[existingCustomFields[index].componentType]}
                              disabled={
                                disable ||
                                (existingCustomFields &&
                                  existingCustomFields.length > 0 &&
                                  existingCustomFields[index] &&
                                  existingCustomFields[index].isParentData &&
                                  !existingCustomFields[index].opdd)
                              }
                            />
                            {existingCustomFields[index].text}
                          </label>
                        ) : String(existingCustomFields[index].componentType) ==
                          'ReduxPhoneInput' ? (
                          <div class="form-group">
                            <Field
                              name={`${item}.centerFieldValue`}
                              type="text"
                              validate={Validator.required}
                              fieldName={existingCustomFields[index].text}
                              component={COMPONENT_TYPES[existingCustomFields[index].componentType]}
                              disabled={
                                disable ||
                                (existingCustomFields &&
                                  existingCustomFields.length > 0 &&
                                  existingCustomFields[index] &&
                                  existingCustomFields[index].isParentData &&
                                  !existingCustomFields[index].opdd)
                              }
                            />
                            <label
                              class="placeholder-label"
                              style={{ top: '-5px', fontSize: '14px' }}
                            >
                              {`${existingCustomFields[index].text}*`}
                            </label>
                          </div>
                        ) : (
                          <div class="form-group">
                            <Field
                              name={`${item}.centerFieldValue`}
                              type="text"
                              validate={Validator.required}
                              component={COMPONENT_TYPES[existingCustomFields[index].componentType]}
                              fieldName={existingCustomFields[index].text}
                              disabled={
                                disable ||
                                (existingCustomFields &&
                                  existingCustomFields.length > 0 &&
                                  existingCustomFields[index] &&
                                  existingCustomFields[index].isParentData &&
                                  !existingCustomFields[index].opdd)
                              }
                            />
                            <label class="placeholder-label">
                              {`${existingCustomFields[index].text}*`}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="custom-control-label" for>
                      <Field
                        name={`${item}.allowChildCenter`}
                        type="text"
                        component={ReduxToggle}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        disabled={
                          disable ||
                          (existingCustomFields &&
                            existingCustomFields.length > 0 &&
                            existingCustomFields[index] &&
                            existingCustomFields[index].isParentData &&
                            !existingCustomFields[index].opdd)
                        }
                      />
                      Apply to child center(s) if any
                    </label>
                  </div>
                </div>
              </div>
              {existingCustomFields &&
                existingCustomFields.length > 0 &&
                existingCustomFields[index] &&
                existingCustomFields[index].isParentData && (
                  <div className="col-12">
                    {/* <Checkbox
                      style={{ padding: '0 15px', fontWeight: '600' }}
                      onChange={e => onChange(e, index)}
                    >
                      Override parent center changes
                    </Checkbox> */}
                    <div class="form-group ml-3">
                      <Field
                        className="mr-2"
                        name={`${item}.opdd`}
                        component={ReduxCheckbox}
                        // fieldName={'isPrivate'}
                        // disabled={isDeactivated}
                      />
                      <label class="placeholder-label" style={{ top: '5px' }}>
                        Override parent center changes
                      </label>
                    </div>
                  </div>
                )}
            </div>
          </>
        );
      })}
      {!disable && (
        <div class="row">
          {/* {fields.length == 1 || index == fields.length - 1 ? ( */}
          <div class={fields.length > 1 ? `col-12 col-md-12 px-0` : `col-12 col-md-12 px-0`}>
            <a
              class="add-contact"
              // style={{ marginRight: '23px' }}
              disabled={disable}
              onClick={() => fields.push({})}
            >
              {fields.length >= 1 && (
                <label style={{ cursor: 'pointer' }}> + Add Another Field</label>
              )}
              {fields.length == 0 && <label style={{ cursor: 'pointer' }}> + Add Field</label>}
            </a>
          </div>
          {/* ) : null} */}
        </div>
      )}
    </>
  );
};

export default ManageFields;
