import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import moment from 'moment';
// import validate, {
//   dateofbirthNormalize,
//   ssnFormatter,
//   ssnNormalize,
//   MobileFormatter,
//   MobileNormalize,
// } from '../validation/validate';
import { Table, Tooltip } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';

import CustomModal from '../../../../core/modal';
import AntdModal from '../../../../Components/CustomModal';
import Axios from 'axios';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxCheckbox,
  ReduxTimePicker,
  ReduxTextarea,
} from '../../../../component/custom-redux-components';
import { stringDate } from '../../../../util/date';
import {
  listCombos,
  medicateCombos,
  getDiagnosis,
  upsertMedicalAnalysis,
  resetUpsertMedicalAnalysis,
  getDiagnosisDescription,
  getDiagnosisCode,
  listClientDiagnosis,
  getClientDiagnosis,
  deleteClientDiagnosis,
  cloneDiagnosis,
  unsignForm,
  clientBannerGet,
} from '../../../../store/actions';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
  Archived_Sucess_Msg,
} from '../../../../app-config';
import PersistTableSort from '../../../../component/persistTableSort';

import ValidatePin from '../../../../Components/validatePinModal';
import SubmitButton from '../../../../Components/submitButton';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

class MedicalAnalysis extends Component {
  constructor() {
    super();
    this.state = {
      medicalAnalysisData: {
        Diagnosis: [{}],
        // diagnosisTime: moment().format('HH:mm:ss'),
      },
      desc: {},
      diagnosisApiObj: {},
      validPinModalVisible: false,
      isSign: false,
      diagnosisListView: true,
      disable: false,
      DiagnosisList: [],
      isDescriptionError: false,
    };
  }

  resetValidPinModalVisible = () => {
    this.setState({ validPinModalVisible: !this.state.validPinModalVisible });
  };

  backButton = bool => {
    this.setState({ diagnosisListView: !this.state.diagnosisListView });
    if (bool) {
      this.props.dispatch(
        listClientDiagnosis({
          Json: JSON.stringify({
            Diagnosis: [
              {
                ClientId: this.props.clientId || this.getClientId(),
              },
            ],
          }),
        }),
      );
    }
  };
  setIsSign = boolean => {
    this.setState({ isSign: boolean });
  };

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    this.props.dispatch(listCombos({ comboTypes: 'CenterProgramType' }));
    this.props.dispatch(
      medicateCombos({
        ComboTypes: 'Category,DiagnosisType,Ranking,StatusMedAnalysis',
      }),
    );
    let ComboTypes = {
      ComboTypes: 'Diagnosis',
    };
    this.props.dispatch(getDiagnosis(ComboTypes));
    // Json:"{"Diagnosis":[{"ClientId":20000159}]}"
    this.props.dispatch(
      listClientDiagnosis({
        Json: JSON.stringify({
          Diagnosis: [
            {
              ClientId: this.props.clientId || this.getClientId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({
    isInserted,
    clientData,
    DiagnosisDescription,
    validateStaffPinData,
    DiagnosisList,
    isDiagnosisDeactivated,
    isClone,
    cloneId,
    genratedDiagnosisId,
    unsignFormStatus,
  }) {
    // if (course && course.CourseId > 0 && this.getClientId() > 0) {

    if (unsignFormStatus && unsignFormStatus != this.props.unsignFormStatus) {
      this.setState({ disable: false });
      this.props.dispatch(
        getClientDiagnosis({
          Json: JSON.stringify({
            Diagnosis: [
              {
                DiagnosisId: this.state.clickedDiagnosis,
              },
            ],
          }),
        }),
      );
    }
    if (
      genratedDiagnosisId &&
      JSON.stringify(genratedDiagnosisId) != JSON.stringify(this.props.genratedDiagnosisId)
    ) {
      this.setState({ genratedDiagnosisId });
    }
    if (
      clientData &&
      clientData.DiagnosisId > 0 &&
      JSON.stringify(clientData) != JSON.stringify(this.props.clientData)
    ) {
      let data = {
        diagnosisId: clientData.DiagnosisId,
        diagnosisDate: clientData.DiagnosisDate,
        diagnosisTime: clientData.DiagnosisTime,
        practitioner: clientData.PractitionerId,
        program: clientData.ProgramId,
      };
      if (clientData.DiagnosisList && clientData.DiagnosisList.length > 0) {
        data.Diagnosis = this.mapClientMedicalAnalysis(clientData.DiagnosisList);
      }
      this.setState({
        medicalAnalysisData: data,
        // diagnosisListView: false,
      });
    }
    if (isInserted) {
      if (!this.state.isSign) {
        this.toggle(Upsert_Sucess_Msg);
      }
      this.props.dispatch(resetUpsertMedicalAnalysis());
    }
    if (isDiagnosisDeactivated && isDiagnosisDeactivated != this.props.isDiagnosisDeactivated) {
      this.deleteToggle(Archived_Sucess_Msg);
      this.props.dispatch(
        listClientDiagnosis({
          Json: JSON.stringify({
            Diagnosis: [
              {
                ClientId: this.props.clientId || this.getClientId(),
              },
            ],
          }),
        }),
      );
    }
    if (isClone && isClone != this.props.isClone) {
      // this.setState({
      //   isCloneModelOpen: true,
      //   header: 'Cloned Successfully',
      // });
      this.props.dispatch(
        getClientDiagnosis({
          Json: JSON.stringify({
            Diagnosis: [
              {
                DiagnosisId: cloneId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(
        listClientDiagnosis({
          Json: JSON.stringify({
            Diagnosis: [
              {
                ClientId: this.props.clientId || this.getClientId(),
              },
            ],
          }),
        }),
      );
      this.setState({
        diagnosisListView: false,
        disable: false,
        genratedDiagnosisId: cloneId,
      });
    }
    // DiagnosisDescription.DiagnosisDescription
    if (
      DiagnosisDescription &&
      JSON.stringify(DiagnosisDescription) != JSON.stringify(this.props.DiagnosisDescription)
    ) {
      let newObj = {
        ...this.state.desc,
        [DiagnosisDescription.Index]: DiagnosisDescription.DiagnosisDescription,
      };
      let descValues = Object.values(newObj);
      this.setState({
        desc: {
          ...this.state.desc,
          [DiagnosisDescription.Index]: DiagnosisDescription.DiagnosisDescription,
        },
        isDescriptionError: descValues.includes(null),
      });
    }
    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      if (validateStaffPinData.IsValid) {
        this.toggle('Diagnosis signed successfully');
        let DiagnosisData = {
          json: JSON.stringify({
            Diagnosis: [
              {
                ...this.state.diagnosisApiObj,
                DiagnosisId: this.state.genratedDiagnosisId
                  ? this.state.genratedDiagnosisId
                  : this.state.diagnosisApiObj.DiagnosisId
                  ? this.state.diagnosisApiObj.DiagnosisId
                  : null,
                StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId,
                IsSign: this.state.isSign,
              },
            ],
          }),
        };
        this.props.dispatch(upsertMedicalAnalysis(DiagnosisData));
      }
    }
    if (
      DiagnosisList &&
      JSON.stringify(DiagnosisList) != JSON.stringify(this.props.DiagnosisList)
    ) {
      this.setState({
        DiagnosisList,
      });
    }
  }

  mapClientMedicalAnalysis(data) {
    let Diagnosis = [];
    if (data) {
      let index = 0;
      for (let item of data) {
        let diagnosisData = {};
        diagnosisData.diagnosisListId = item.DiagnosisListId;
        diagnosisData.diagnosis = item.DiagnosisDictDesc;
        // diagnosisData.diagnosisDescription = item.DiagnosisDescription;
        diagnosisData.category = item.CategoryId;
        diagnosisData.diagnosisStatus = item.StatusId;
        diagnosisData.resolveDate = item.ResolvedDate;
        diagnosisData.presentOn = item.IsAdmission;
        diagnosisData.ranking = item.RankingId;
        diagnosisData.billOrder = item.BillOrder;
        diagnosisData.diagnosisType = item.DiagnosisTypeId;
        diagnosisData.specifier = item.Specifier;
        diagnosisData.specifierNotes = item.SpecifierNote;
        diagnosisData.severity = item.Severity;
        diagnosisData.severityNotes = item.SeverityNote;
        diagnosisData.diagnosisNotes = item.Note;
        this.state.desc[index] = item.DiagnosisDescription;
        index = index + 1;
        Diagnosis.push(diagnosisData);
      }
    }
    return Diagnosis;
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  deleteToggle = header => {
    this.setState(prevState => ({
      isDeleted: !prevState.isDeleted,
      header,
    }));
  };

  onSubmit(values) {
    const { medicalAnalysisData } = this.state;
    let patientId = null;
    let paramClientId = this.props.clientId;
    if (paramClientId && paramClientId > 0) {
      patientId = paramClientId;
    }
    const { diagnosisDate, diagnosisTime, practitioner, program, Diagnosis } = values;

    let diagnosisApiObj = {
      DiagnosisId: medicalAnalysisData.diagnosisId || null,
      ClientId: this.getClientId(),
      PractitionerId: practitioner,
      DiagnosisDate: stringDate(diagnosisDate),
      DiagnosisTime: diagnosisTime && moment(diagnosisTime).format('HH:mm:ss'),
      ProgramId: program,
      DiagnosisList:
        Diagnosis &&
        Diagnosis.map(item => {
          return {
            DiagnosisListId: item.diagnosisListId || null,
            // DiagnosisDictId: item.diagnosis,
            DiagnosisDictDesc: item.diagnosis,
            // DiagnosisDictDesc: sessionStorage.getItem('medicalCode'),
            CategoryId: item.category,
            StatusId: item.diagnosisStatus,
            ResolvedDate: stringDate(item.resolveDate),
            IsAdmission: item.presentOn,
            RankingId: item.ranking,
            BillOrder: item.billOrder,
            DiagnosisTypeId: item.diagnosisType,
            Specifier: item.specifier,
            SpecifierNote: item.specifierNotes,
            Severity: item.severity,
            SeverityNote: item.severityNotes,
            Note: item.diagnosisNotes,
          };
        }),
      IsSign: this.state.isSign,
    };
    if (this.state.isSign) {
      this.setState({
        diagnosisApiObj: diagnosisApiObj,
        // validPinModalVisible: true,
      });

      // let DiagnosisData = {
      //   json: JSON.stringify({ Diagnosis: [{ ...diagnosisApiObj }] }),
      // };
      // console.log('line 394', DiagnosisData);
      // this.props.dispatch(upsertMedicalAnalysis(DiagnosisData));
      // Need to remove this code because it is calling api without pin verification.
      // Because of this one extra record got created.

      let data = {
        Json: JSON.stringify({
          Staff: [
            {
              FormType: 'Diagnosis',
              FormId: medicalAnalysisData.diagnosisId,
            },
          ],
        }),
      };
      let me = this;
      Axios.post('Medicaid/PreValidatePin', data).then(response => {
        let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
        let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
        let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;
        if (isValid) {
          this.setState({ IsRealSignature: IsRealSignature });
          if (!this.state.isModelOpen) {
            this.resetValidPinModalVisible();
          }
          // me.props.setIsSign(true);
        } else {
          me.toggle(failMsg);
          me.setIsSign(true);
          // me.setIsSign(false);
        }
      });
    } else {
      let DiagnosisData = {
        json: JSON.stringify({ Diagnosis: [{ ...diagnosisApiObj }] }),
      };
      this.props.dispatch(upsertMedicalAnalysis(DiagnosisData));
    }
  }

  render() {
    const { diagnosisListView, DiagnosisList } = this.state;
    const { cloneBtn, isReadOnlyUser, isDeActivated, isUpsertFetching } = this.props;
    const tempColumns = [
      // {
      //   title: 'Diagnosis Code',
      //   dataIndex: 'DiagnosisCode',
      //   key: 'DiagnosisCode',
      //   sorter: (a, b) => isComparer(a.DiagnosisCode, b.DiagnosisCode),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Diagnosis Date',
        dataIndex: 'DiagnosisDate',
        key: 'DiagnosisDate',
        sorter: (a, b) => isDateComparer(a.DiagnosisDateUTC, b.DiagnosisDateUTC),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Primary Diagnosis',
        dataIndex: 'PrimaryDiagnosisDescription',
        key: 'PrimaryDiagnosisDescription',
        sorter: (a, b) => isComparer(a.PrimaryDiagnosisDescription, b.PrimaryDiagnosisDescription),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Diagnosis Date',
      //   dataIndex: 'DiagnosisDate',
      //   key: 'DiagnosisDate',
      //   sorter: (a, b) => isComparer(a.DiagnosisDate, b.DiagnosisDate),
      //   sortDirections: ['descend', 'ascend'],
      // },

      // {
      //   title: 'Diagnosis Description',
      //   dataIndex: 'DiagnosisDescription',
      //   key: 'DiagnosisDescription',
      //   sorter: (a, b) => isComparer(a.DiagnosisDescription, b.DiagnosisDescription),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Is Signed',
        dataIndex: 'IsSigned',
        key: 'IsSigned',
        sorter: (a, b) => isNumericComparer(a.IsSigned, b.IsSigned),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span className="">{text ? 'Yes' : 'No'}</span>
            </div>
          );
        },
      },
      {
        title: 'Signed Date',
        dataIndex: 'SignedDate',
        key: 'SignedDate',
        sorter: (a, b) => isDateComparer(a.SignedDateUTC, b.SignedDateUTC),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Created Date',
        dataIndex: 'CreatedDate',
        key: 'CreatedDate',
        sorter: (a, b) => isDateComparer(a.CreatedDateUTC, b.CreatedDateUTC),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        render: (text, record, index) => (
          <div class="tools-grid" style={{ padding: '0px' }}>
            <>
              <div className={record.IsSigned ? 'mr-4' : 'mr-5'} style={{ cursor: 'pointer' }}>
                <span
                  className="start-btn"
                  onClick={event => {
                    this.setState({
                      diagnosisListView: false,
                      disable: record.IsSigned,
                      clickedDiagnosis: record.DiagnosisId,
                    });
                    this.props.dispatch(
                      getClientDiagnosis({
                        Json: JSON.stringify({
                          Diagnosis: [
                            {
                              DiagnosisId: record.DiagnosisId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  {record.IsSigned ? 'View' : <>{!isReadOnlyUser && !isDeActivated && 'Edit'}</>}
                </span>
              </div>
              {!record.IsArchived ? (
                <div className="">
                  {record.IsDelete && (
                    <>
                      {!this.props.isReadOnlyUser && !isDeActivated && (
                        <AntdModal
                          ModalButton={({ onClick }) => (
                            <span
                              class="delete-btn"
                              onClick={onClick}
                              data-toggle="modal"
                              data-target="#delete-Screen-Tool"
                            >
                              {!record.IsSigned ? (
                                // <Tooltip placement="top" title="Delete">
                                <i class="far fa-trash-alt"></i>
                              ) : (
                                // <img src="images/delete_new.svg" className="mr-2" />

                                // </Tooltip>
                                <Tooltip placement="top" title="Archive">
                                  <img
                                    src="images/archive_new.svg"
                                    className="mr-2"
                                    style={{ height: '22px', marginLeft: '11px' }}
                                  />
                                </Tooltip>
                              )}
                            </span>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                              <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                <h4 class="modal-title col-12 mb-3">
                                  {record.IsSigned
                                    ? 'Are you sure you want to archive?'
                                    : 'Are you sure you want to delete?'}
                                </h4>
                              </div>
                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  class="btn btn-outline-primary black-btn"
                                  onClick={() => {
                                    onCancel();
                                  }}
                                >
                                  No
                                </button>
                                <button
                                  class="btn"
                                  onClick={() => {
                                    onOk();
                                    this.props.dispatch(
                                      deleteClientDiagnosis({
                                        json: JSON.stringify({
                                          Diagnosis: [
                                            {
                                              DiagnosisId: record.DiagnosisId,
                                            },
                                          ],
                                        }),
                                      }),
                                    );
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      )}
                    </>
                  )}
                </div>
              ) : (
                <span style={{ color: '#FF2C2C' }}>Archived</span>
              )}
            </>
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        <div className="rcm-tab row" style={{ paddingRight: '24px', padding: '0 20px' }}>
          <div class="col-6 px-0 pt-5" style={{ top: '10px' }}>
            <h4 class=""> Medical Analysis </h4>
          </div>
          {diagnosisListView ? (
            <>
              <div className="py-2 pt-5 col-6 text-right">
                {!this.props.isReadOnlyUser && !isDeActivated && cloneBtn && (
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#4ab0fe',
                      cursor: 'pointer',
                    }}
                    className="start-btn"
                    onClick={event => {
                      this.props.dispatch(
                        cloneDiagnosis({
                          Json: JSON.stringify({
                            Diagnosis: [
                              {
                                ClientId: this.props.clientId || this.getClientId(),
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        // diagnosisListView: false,
                        isCloneing: true,
                      });
                    }}
                  >
                    Copy and create most recently signed diagnosis
                  </span>
                )}
                {!this.props.isReadOnlyUser && !isDeActivated && (
                  <input
                    type="button"
                    class="btn btn-eForm-Next mx-2"
                    value="New Diagnosis"
                    onClick={event => {
                      this.setState({
                        diagnosisListView: false,
                        disable: false,
                        medicalAnalysisData: {
                          Diagnosis: [{}],
                        },
                        desc: {},
                        genratedDiagnosisId: null,
                      });
                    }}
                  />
                )}
              </div>
              <div className="rcm-subtab-table-block col-12" style={{ position: 'relative' }}>
                {/* <Loader2 loading={this.props.isFetchingList} /> */}
                <PersistTableSort
                  name="DiagnosisList"
                  loading={this.props.isFetchingList}
                  columns={tempColumns}
                  dataSource={DiagnosisList}
                  size={'middle'}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div class="dataTables_wrapper no-footer">
                          <div class="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a class="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a class="paginate_button next">Next</a>
                            ) : (
                              <a class="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                  }}
                  style={{ padding: '20px' }}
                />
              </div>
            </>
          ) : (
            <div>
              <div className="assessment-main-title d-flex align-items-center justify-content-between">
                <div
                  onClick={() => {
                    this.backButton(true);
                  }}
                  style={{ cursor: 'pointer' }}
                  className="text-left d-flex"
                >
                  <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                </div>
                <div></div>
              </div>
              <div>
                <ReduxMedicalAnalysis
                  {...this.props}
                  setIsSign={this.setIsSign}
                  validPinModalVisible={this.state.validPinModalVisible}
                  resetValidPinModalVisible={this.resetValidPinModalVisible}
                  DiagnosisId={this.props.clientData && this.props.clientData.DiagnosisId}
                  onSubmit={this.onSubmit.bind(this)}
                  // onSaveClick={this.onSubmit.bind(this)}
                  initialValues={this.state.medicalAnalysisData}
                  // initialValues={this.mapClientMedicalAnalysis()}
                  onCancel={this.props.onCancel}
                  resetForm={() => {
                    this.props.dispatch(reset('medicalAnalysisForm'));
                    this.setState({
                      medicalAnalysisData: {
                        Diagnosis: [{}],
                      },
                    });
                  }}
                  history={this.props.history}
                  isUpsertFetching={isUpsertFetching}
                  descriptionList={this.state.desc}
                  disable={this.state.disable}
                  toggle={this.toggle}
                  clientData={this.props.clientData}
                  IsRealSignature={this.state.IsRealSignature}
                  isDescriptionError={this.state.isDescriptionError}
                  isReadOnlyUser={this.props.isReadOnlyUser}
                />
              </div>
            </div>
          )}
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // this.props.onCancel();
            this.backButton(true);
            this.props.dispatch(
              clientBannerGet({
                json: JSON.stringify({
                  Client: [
                    {
                      ClientId: this.getClientId(),
                    },
                  ],
                }),
              }),
            );
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isDeleted}
          header={this.state.header}
          yesAction={() => {
            this.deleteToggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isCloneModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.setState({
              isCloneModelOpen: false,
              header: '',
            });
            this.props.dispatch(
              listClientDiagnosis({
                Json: JSON.stringify({
                  Diagnosis: [
                    {
                      ClientId: this.props.clientId || this.getClientId(),
                    },
                  ],
                }),
              }),
            );
            this.props.onCancel();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const Diagnosis = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  StateProvince,
  Country,
  change,
  disable,
  existingRows,
  dispatch,
  Category,
  DiagnosisType,
  Ranking,
  StatusMedAnalysis,
  Diagnosis,
  DiagnosisDescription,
  DiagnosisCode,
  DiagnosisValue,
  descriptionList,
}) => {
  return (
    <>
      {fields.map((diagnosis, index) => {
        return (
          <>
            <div class="row">
              <div class="col-12 col-md-6">
                <h6 style={{ fontSize: '18px' }}>Diagnosis {index + 1}</h6>
              </div>
              {!disable && (
                <div class="col-12 col-md-6">
                  <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                    <i class="fas fa-trash" /> Delete Diagnosis
                  </a>
                </div>
              )}
            </div>
            <div class="row mb-4 py-3 border rounded">
              <div class="col-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.diagnosis`}
                    errorMessage="Diagnosis Code Required"
                    validate={Validator.required}
                    type="text"
                    component={ReduxAutoComplete}
                    onChange={event => {
                      let data = '';
                      if (typeof event == 'string' && event !== '') {
                        data = event;
                      } else if (typeof event == 'object') {
                        data = event.target.value;
                      }
                      // var aValue = sessionStorage.getItem('medicalCode');
                      dispatch(
                        getDiagnosisCode({
                          json: JSON.stringify({
                            Diagnosis: [
                              {
                                Search: data,
                              },
                            ],
                          }),
                        }),
                      );
                      // Json: "{"Diagnosis":[{"Search":"F0"}]}"
                      dispatch(
                        getDiagnosisDescription({
                          json: JSON.stringify({
                            Diagnosis: [
                              {
                                Index: index,
                                DiagnosisDictDesc: data,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                    options={DiagnosisCode}
                    label={'Diagnosis Code*'}
                    fieldName={'Diagnosis Code'}
                    disabled={disable}
                  />
                </div>
              </div>
              {/* <div class="col-12 col-md-8 col-lg-8"></div> */}
              <div class="col-12 col-md-8 col-lg-8 mb-5">
                <span className="font-weight-bold">Description : </span>
                <span>
                  {/* {DiagnosisDescription && DiagnosisDescription.Index === index
                    ? DiagnosisDescription.DiagnosisDescription
                    : existingRows && existingRows[index].diagnosisDescription} */}
                  {/* {existingRows && existingRows[index].diagnosisDescription} */}
                  {descriptionList && descriptionList[index] ? (
                    descriptionList[index]
                  ) : DiagnosisValue && DiagnosisValue[index] && DiagnosisValue[index].diagnosis ? (
                    <span style={{ color: 'red' }}>Invalid Diagnosis Code</span>
                  ) : (
                    ''
                  )}
                </span>
                <br />
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.category`}
                    type="text"
                    component={ReduxSelect}
                    options={Category}
                    validate={Validator.required}
                    // onChange={countryISO => countryChange(countryISO)}
                    placeholder="Category*"
                    fieldName={'Category'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.diagnosisStatus`}
                    validate={Validator.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Status*"
                    options={StatusMedAnalysis}
                    fieldName={'Status'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    component={ReduxDateInput}
                    name={`${diagnosis}.resolveDate`}
                    label="Resolved Date"
                    // validate={validate.required}
                    fieldName={'Resolve Date'}
                    // minDate={new Date()}
                    // maxDate={addMonths(new Date(), 5)}
                    // maxDate={new Date()}
                    disabled={disable}
                    minDate={new Date()}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              {/* <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.presentOn`}
                    type="text"
                    component={ReduxSelect}
                    options={[
                      {
                        Value: '0',
                        Text: 'No',
                      },
                      {
                        Value: '1',
                        Text: 'Yes',
                      },
                    ]}
                    // validate={Validator.required}
                    placeholder="Present on Admission ?"
                    fieldName={'present on Admission'}
                    // disabled={disable}
                  />
                </div>
              </div> */}
              <div class="col-12 col-md-6 col-lg-4" style={{ alignSelf: 'center' }}>
                <div class="form-group">
                  <label class="custom-control-label" for="Present-on-Admission">
                    Present on Admission? {'   '}
                    <Field
                      name={`${diagnosis}.presentOn`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                      disabled={disable}
                    />
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.ranking`}
                    type="text"
                    component={ReduxSelect}
                    options={Ranking}
                    validate={Validator.required}
                    placeholder="Ranking*"
                    fieldName={'Ranking'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.billOrder`}
                    type="number"
                    component={ReduxInput}
                    disabled={disable}
                    min={1}
                    max={3}
                    negativeNumberBlocked={true}
                    // validate={Validator.required}
                  />
                  <label class="placeholder-label">Bill Order</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.diagnosisType`}
                    validate={Validator.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Diagnosis Type*`}
                    options={DiagnosisType}
                    fieldName={'Diagnosis Type'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6"></div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.specifier`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Specifier</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.specifierNotes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.severity`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Severity</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.severityNotes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.diagnosisNotes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      {!disable && (
        <div class="row">
          <div class="col-12 text-right pt-1 pb-1">
            {/* <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}> */}
            <a class="add-contact" onClick={() => fields.push({})} disabled={disable}>
              {fields.length >= 1 && <label> + Add Another Diagnosis</label>}
              {fields.length == 0 && <label> + Add Diagnosis</label>}
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const ReduxDiagnosis = connect(state => {
  const { get_diagnosis_description, get_diagnosis_code } = state;
  const DiagnosisValue = selector(state, 'Diagnosis');
  const { data: diagnosisCodeData } = get_diagnosis_code;
  let DiagnosisCode = [];
  if (diagnosisCodeData && diagnosisCodeData.Diagnosis) {
    DiagnosisCode = diagnosisCodeData.Diagnosis;
  }
  return {
    DiagnosisCode,
    DiagnosisValue,
  };
})(Diagnosis);

class MedicalAnalysisForm extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  unsignFormFun = () => {
    let data = {
      Json: JSON.stringify({
        UnSign: [
          {
            FormType: 'Diagnosis',
            FormId: this.props.clientData.DiagnosisId,
            // TreatmentPlanId: this.props.clikedTreatmentPlanId,
          },
        ],
      }),
    };

    this.props.dispatch(unsignForm(data));
  };

  render() {
    const {
      onSubmit,
      handleSubmit,
      dispatch,
      history,
      name,
      disable,
      existingDiagnosisRows,
      Category,
      DiagnosisType,
      Ranking,
      Practitioner,
      centerProgramType,
      StatusMedAnalysis,
      Diagnosis,
      descriptionList,
      resetForm,
      DiagnosisId,
      setIsSign,
      clientData,
      isReadOnlyUser,
      isUpsertFetching,
    } = this.props;
    return (
      <div className="main medicalAnalysis-page mb-5">
        <div class="container-fluid px-0">
          <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '36px 20px 20px 20px' }}>
            {/* <div class="content-area "> */}
            {/* <Loader2 loading={isFetching} /> */}
            <div class="enrollment-form medicalAnalysis-form">
              <div className="row pb-3 pl-3 pr-3 pt-3">
                {/* {getCenterId > 0 && (
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="paymentID"
                            type="text"
                            component={ReduxInput}
                            disabled={true}
                          />
                          <label class="placeholder-label">Payment ID</label>
                        </div>
                      </div>
                      )} */}
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      component={ReduxDateInput}
                      name="diagnosisDate"
                      label="Date of Diagnosis*"
                      validate={Validator.required}
                      fieldName={'Date of Diagnosis'}
                      disabled={disable}
                      // minDate={new Date()}
                    />

                    <i class="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="diagnosisTime"
                      type="text"
                      component={ReduxTimePicker}
                      fieldName={'Start Time'}
                      // validate={Validator.required}
                      label={'Time of Diagnosis'}
                      minuteStep={5}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="program"
                      type="text"
                      errorMessage="Program enrolled Required"
                      component={ReduxSelect}
                      options={centerProgramType}
                      validate={Validator.required}
                      placeholder="Program*"
                      fieldName={'Program'}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="practitioner"
                      type="text"
                      component={ReduxSelect}
                      options={Practitioner}
                      validate={Validator.required}
                      placeholder="Diagnosis Practitioner*"
                      fieldName={'Diagnosis Practitioner'}
                      disabled={disable}
                    />
                  </div>
                </div>

                {/* <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            component={ReduxDateInput}
                            name="diagnosisDate"
                            label="Date of Diagnosis*"
                            // validate={validate.required}
                            fieldName={'Date of Diagnosis'}
                            // disabled={disable}
                          />
                          <i class="far fa-calendar-alt form-control-icon" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`paymentID`}
                            type="text"
                            // validate={validate.required}
                            component={ReduxInput}
                            fieldName={'Payment ID'}
                            // disabled={disable}
                          />
                          <label class="placeholder-label">Payment ID*</label>
                        </div>
                      </div> */}
                <div class="col-12 py-2">
                  <h5>Diagnosis List</h5>
                </div>
                {/* Addmore */}
                <div class="col-12 p-4">
                  <FieldArray
                    name={`Diagnosis`}
                    component={ReduxDiagnosis}
                    Category={Category}
                    DiagnosisType={DiagnosisType}
                    Ranking={Ranking}
                    Practitioner={Practitioner}
                    StatusMedAnalysis={StatusMedAnalysis}
                    Diagnosis={Diagnosis}
                    existingRows={existingDiagnosisRows}
                    descriptionList={descriptionList}
                    disable={disable}
                  />
                </div>

                {/* <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`providerSignature`}
                            type="text"
                            // validate={validate.required}
                            component={ReduxCheckbox}
                            fieldName={'providerSignature'}
                            label={'Provider Signature with Credentials'}
                            disabled={disable}
                          />
                        </div>
                      </div> */}
              </div>
            </div>
            {/* </div> */}
            <div class="row" style={{ right: '40px', bottom: '6rem' }}>
              {disable && (
                <div class="col-6 pl-4">
                  {clientData && (
                    <h5 style={{ textAlign: 'left', fontSize: '17px', fontWeight: '700' }}>
                      {' '}
                      Signed By:-{clientData.SignedMessage}
                    </h5>
                  )}
                </div>
              )}
              {!disable && (
                <div class="col-12 text-right">
                  <button
                    onClick={() => {
                      resetForm();
                    }}
                    type="button"
                    class="btn btn-outline-primary btn-eForm-Prev mr-2"
                    disabled={disable}
                  >
                    Reset
                  </button>
                  <button
                    onClick={() => {
                      this.props.setIsSign(false);
                    }}
                    type="submit"
                    class="btn btn-eForm-Next"
                    disabled={disable}
                  >
                    Save
                  </button>{' '}
                  <SubmitButton
                    loading={isUpsertFetching}
                    onClick={() => {
                      this.props.setIsSign(true);
                      // let data = {
                      //   Json: JSON.stringify({
                      //     Staff: [
                      //       {
                      //         FormType: 'Diagnosis',
                      //         FormId: DiagnosisId,
                      //       },
                      //     ],
                      //   }),
                      // };
                      // let me = this;
                      // Axios.post('Medicaid/PreValidatePin', data).then(response => {
                      //   let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
                      //   let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
                      //   if (isValid) {
                      //     this.props.resetValidPinModalVisible();
                      //     // me.props.setIsSign(true);
                      //   } else {
                      //     me.props.toggle(failMsg);
                      //     me.props.setIsSign(false);
                      //   }
                      // });
                    }}
                    type="submit"
                    disabled={disable || this.props.isDescriptionError}
                    // class="btn btn-eForm-Next ml-2"
                    style={{ height: '45px' }}
                    value="Sign and Submit"
                  />
                </div>
              )}

              {!isReadOnlyUser && disable && (
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <div className="col-6 text-right" style={{ top: '-4px' }}>
                      <Tooltip placement="top" title="Un-sign">
                        <span class="delete-course" onClick={onClick}>
                          {clientData && clientData.IsEligibleForUnSign && (
                            <span className="mt-2 mb-2 mr-2 danger-custom-btn">Un-sign</span>
                          )}
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                          &times;
                        </button>
                        <h4 class="modal-title col-12">Are you sure you want to Un-sign?</h4>
                      </div>

                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          type="button"
                          class="btn black-btn"
                          data-dismiss="modal"
                          onClick={onCancel}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn "
                          onClick={() => {
                            onOk();
                            this.unsignFormFun();
                            // this.cancelGroupCall(Data.VMCallSessionId);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              )}
            </div>
            <ValidatePin
              validPinModalVisible={this.props.validPinModalVisible}
              resetValidPinModalVisible={this.props.resetValidPinModalVisible}
              FormType={'Diagnosis'}
              FormId={DiagnosisId}
              IsRealSignature={this.props.IsRealSignature}
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    combos,
    medicate_combos,
    practitioner_combos,
    get_diagnosis,
    get_diagnosis_description,
    combo_practitioner_center_get,
  } = state;
  const { data: medicateCombos } = medicate_combos;
  const { data: practitionerData } = combo_practitioner_center_get;
  const { data: diagnosisData } = get_diagnosis;
  let Practitioner = [];
  let Diagnosis = [];

  let stateProvince = [],
    centerProgramType = [];
  const existingDiagnosisRows = selector(state, `Diagnosis`);
  if (combos.data) {
    const { StateProvince, Country, CenterProgramType } = combos.data;

    if (StateProvince) {
      stateProvince = StateProvince;
    }
    if (CenterProgramType) {
      centerProgramType = CenterProgramType;
    }
  }
  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList || [];
  }
  if (diagnosisData) {
    Diagnosis = diagnosisData.Diagnosis;
  }

  return {
    stateProvince,
    existingDiagnosisRows,
    Category: (medicateCombos && medicateCombos.Category) || [],
    DiagnosisType: (medicateCombos && medicateCombos.DiagnosisType) || [],
    Ranking: (medicateCombos && medicateCombos.Ranking) || [],
    StatusMedAnalysis: (medicateCombos && medicateCombos.StatusMedAnalysis) || [],
    Practitioner: Practitioner,
    centerProgramType: centerProgramType,
    Diagnosis,
  };
};

const ReduxMedicalAnalysis = reduxForm({
  form: 'medicalAnalysisForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(MedicalAnalysisForm)));
const selector = formValueSelector('medicalAnalysisForm');

const mapStateToPropsParent = state => {
  const {
    upsert_medical_analysis,
    get_patient_detail,
    get_diagnosis_description,
    validate_staff_pin,
    list_client_diagnosis,
    get_client_diagnosis,
    delete_client_diagnosis,
    clone_diagnosis,
    unsign_form,
  } = state;
  const { data: clientInfo, isFetching } = get_patient_detail;
  const { data: clientdiagnosisData } = get_client_diagnosis;
  const { data: diagnosisDescData } = get_diagnosis_description;
  const { data: diagnosisListData, isFetching: isFetchingList } = list_client_diagnosis;
  var { data: validateDataPin } = validate_staff_pin;
  const { data: unsignForm } = unsign_form;
  const { isFetching: isUpsertFetching } = upsert_medical_analysis;

  let validateStaffPinData = {};

  let isInserted = false;
  let isClone = false;
  let cloneId = null;
  let isDiagnosisDeactivated = false;
  let clientData = [];
  let DiagnosisList = [];
  let ClientDiagnosis = [];
  let DiagnosisDescription = null;
  var cloneBtn = false;
  let genratedDiagnosisId;
  var unsignFormStatus = false;
  if (
    upsert_medical_analysis &&
    upsert_medical_analysis.data &&
    upsert_medical_analysis.data.success
  ) {
    isInserted = true;
    genratedDiagnosisId = upsert_medical_analysis.data.result;
  }
  if (clientInfo && clientInfo.Diagnosis) {
    clientData = clientInfo.Diagnosis[0];
  }
  if (diagnosisDescData && diagnosisDescData.Diagnosis) {
    DiagnosisDescription = diagnosisDescData.Diagnosis[0];
  }
  if (diagnosisListData && diagnosisListData.Diagnosis) {
    DiagnosisList = diagnosisListData.Diagnosis;
    cloneBtn = diagnosisListData.Diagnosis.some(item => {
      return item.IsSigned;
    });
  }
  if (clientdiagnosisData && clientdiagnosisData.Diagnosis) {
    ClientDiagnosis = clientdiagnosisData.Diagnosis[0] || [];
  }
  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (delete_client_diagnosis.data && delete_client_diagnosis.data.success) {
    isDiagnosisDeactivated = delete_client_diagnosis.data.success;
  }
  if (clone_diagnosis.data && clone_diagnosis.data.success) {
    isClone = clone_diagnosis.data.success;
    cloneId = clone_diagnosis.data.result;
  }
  if (unsignForm && unsignForm.success) {
    unsignFormStatus = unsignForm.success;
  }
  return {
    isInserted,
    isFetching: isFetching,
    isFetchingList,
    clientData: ClientDiagnosis,
    DiagnosisDescription,
    validateStaffPinData,
    DiagnosisList,
    isDiagnosisDeactivated,
    isClone,
    cloneId,
    cloneBtn,
    genratedDiagnosisId,
    unsignFormStatus,
    isUpsertFetching,
  };
};

export default connect(mapStateToPropsParent)(withRouter(MedicalAnalysis));
