import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ReduxSelect,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxPlusMinusPercentageInput,
  ReduxInput,
  ReduxNonBillableInput,
} from '../../../../component/custom-redux-components';
import {
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
  centerOverHeadCostGet,
} from '../../../../store/actions';
import validate from '../../../../core/generic-form/validation';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { ConsumableCostDetails } from '../consumableCostDetails';
import { LaborCostDetails } from '../laborCostDetails';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
import { CalculateMaterialCost } from './calculateMaterialCost';
import { CalculateLaborCost } from './calculateLaborCost';
import SubmitButton from '../../../../Components/submitButton';
export const FormComponent = props => {
  const {
    code,
    unitOfMeasurement,
    codeModifier,
    CenterConsumable,
    ConsumableFormData,
    currentServiceFormValues,
    setTotalConsumableCost,
    dispatch,
    handleSubmit,
    initialValues,
    CenterLaborCost,
    setTotalLaborCost,
    // LaberCostType,
    LaborCostFormData,
    ServiceCodeCostType,
    totalConsumableCost,
    totalLaborCost,
    totalCost,
    totalServiceCost,
    overHeadCost,
    defaultCenterOverHeadCostPercentage,
    onSaveClick,
    PayFrequency,
    isUpsertFetching,
  } = props;

 

  const handleServiceCodeChange = event => {
    let data = '';
    if (typeof event == 'string' && event !== '') {
      data = event;
    } else if (typeof event == 'object') {
      data = event.target.value;
    }
    dispatch(
      comboCode({
        json: JSON.stringify({
          Code: [
            {
              Search: data,
            },
          ],
        }),
      }),
    );
    // Json: "{"Code":[{"Search":"EME"}]}"
    dispatch(
      getCodeDescription({
        json: JSON.stringify({
          Code: [
            {
              CodeId: data,
            },
          ],
        }),
      }),
    );
  };

  const handleModifierChange = (value, field) => {
    let data;
    switch (field) {
      case 'code':
        data = {
          Code: [
            {
              CodeId: value,
            },
          ],
        };
        break;
      case 'codeModifier1':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      case 'codeModifier2':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      case 'codeModifier3':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      default:
        break;
    }

    let Data = {
      json: JSON.stringify(data),
    };

    props.dispatch(getCodeDescription(Data));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSaveClick)}>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name="serviceCodeType"
                type="text"
                component={ReduxSelect}
                validate={validate.required}
                fieldName={'Service Code Type'}
                placeholder={'Service Code Type*'}
                options={ServiceCodeCostType}
                // options={[
                //   { Value: 1, Text: 'Billable' },
                //   { Value: 2, Text: 'Non-Billable' },
                // ]}
              />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          {currentServiceFormValues && currentServiceFormValues.serviceCodeType == 1 ? (
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group">
                <Field
                  name={`serviceCode`}
                  type="text"
                  component={ReduxAutoComplete}
                  validate={validate.required}
                  options={code}
                  fieldName={'Service Code'}
                  label={'Select Service Code*'}
                  onChange={handleServiceCodeChange}
                />
              </div>
            </div>
          ) : (
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group">
                <Field
                  name={`serviceCode`}
                  // type="text"
                  component={ReduxNonBillableInput}
                  validate={[validate.required, validate.maxAlphaNumeric5Allowed]}
                  fieldName={'Service Code'}
                  dollarPlaceHolder={'Service Code*'}
                />
              </div>
            </div>
          )}

          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name="unitOfMeasurementId"
                type="text"
                component={ReduxSelect}
                validate={validate.required}
                fieldName={'Unit of Measurement'}
                placeholder={'Unit of Measure*'}
                options={unitOfMeasurement}
              />
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group">
              <Field
                name="ShortServiceDescription"
                type="text"
                component={ReduxTextarea}
                validate={validate.required}
                fieldName={'Short Description'}
                // placeholder={'Short Description*'}
                // disabled={disable}
              />
              <label class="placeholder-label" style={{ paddingLeft: '5px' }}>
                Short Description*
              </label>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group">
              <Field
                name={`detailDescription`}
                type="textarea"
                component={ReduxTextarea}
                validate={validate.required}
                fieldName={'Detail Description'}
                label=" Detailed Description*"
              />
            </div>
          </div>

          {currentServiceFormValues && currentServiceFormValues.serviceCodeType == 1 && (
            <>
              <div class="col-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`modifier1`}
                    type="text"
                    component={ReduxSelect}
                    fieldName={'Modifier1'}
                    options={codeModifier}
                    placeholder="Modifier 1"
                    onChange={value => {
                      handleModifierChange(value, 'codeModifier1');
                    }}
                  />
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`modifier2`}
                    type="text"
                    component={ReduxSelect}
                    fieldName={'Modifier2'}
                    options={codeModifier}
                    placeholder="Modifier 2"
                    onChange={value => {
                      handleModifierChange(value, 'codeModifier2');
                    }}
                  />
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`modifier3`}
                    type="text"
                    component={ReduxSelect}
                    fieldName={'Modifier3'}
                    options={codeModifier}
                    placeholder="Modifier 3"
                    onChange={value => {
                      handleModifierChange(value, 'codeModifier3');
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <div div class="col-12 col-md-12 col-lg-12">
            <FieldArray
              name="LaborCost"
              component={CalculateLaborCost}
              CenterLaborCost={CenterLaborCost}
              // LaberCostType={LaberCostType}
              LaborCostFormData={LaborCostFormData}
              setTotalLaborCost={setTotalLaborCost}
              unitOfMeasurement={unitOfMeasurement}
              PayFrequency={PayFrequency}
            />
          </div>

          <div div class="col-12 col-md-12 col-lg-12">
            <FieldArray
              name="Consumable"
              component={CalculateMaterialCost}
              CenterConsumable={CenterConsumable}
              ConsumableFormData={ConsumableFormData}
              setTotalConsumableCost={setTotalConsumableCost}
            />
          </div>

          <div class="col-12 col-md-12 col-lg-12 mt-2">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 my-3">
                <div className="p-4" style={{ background: '#F8F8F8', borderRadius: '4px' }}>
                  <div className="d-flex align-items-center justify-content-between pt-3">
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#182838',
                      }}
                    >
                      Total Material Cost
                    </div>
                    <div
                      style={{
                        fontSize: '20px',
                        fontWeight: '700',
                      }}
                    >
                      {currencyFormatter.format(totalConsumableCost || 0)}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pt-3">
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#182838',
                      }}
                    >
                      Total Labor Cost/Burden
                    </div>
                    <div
                      style={{
                        fontSize: '20px',
                        fontWeight: '700',
                      }}
                    >
                      {currencyFormatter.format(totalLaborCost || 0)}
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center justify-content-between pt-3"
                    style={{
                      borderBottom: '0.5px solid #959595',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#182838',
                      }}
                    >
                      Overhead Cost
                    </div>
                    <div className="">
                      <div
                        class="license-quantity d-flex  justify-content-left"
                        style={{ fontWeight: '600' }}
                      >
                        <label class="placeholder-label flex-grow-1" style={{ fontSize: '16px' }}>
                          Set Overhead Cost %
                        </label>
                        <div class="">
                          <Field
                            name={`OverHeadCostPercentage`}
                            type="text"
                            errorMessage="State Required"
                            min={0}
                            max={100}
                            component={ReduxPlusMinusPercentageInput}
                            disableOnPaste={true}
                            validate={validate.requiredZeroAtleast}
                            fieldName={'Overhead Cost Percentage'}
                            className="number-icon"
                            style={{
                              textAlign: 'center',
                              margin: '0px 5px',
                              height: '32px',
                              width: '56px',
                              fontSize: '16px',
                              background: 'white',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-none">
                    <div
                      className="d-flex align-items-center justify-content-between pt-3 pb-3"
                      style={{
                        borderBottom: '0.5px solid #959595',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '16px',
                          color: '#182838',
                        }}
                      >
                        Overhead Cost ({`${defaultCenterOverHeadCostPercentage}%`})
                      </div>

                      <div
                        style={{
                          fontSize: '20px',
                          fontWeight: '700',
                        }}
                      >
                        {currencyFormatter.format(overHeadCost || 0)}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between pt-3">
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#182838',
                        fontWeight: '600',
                      }}
                    >
                      Total Cost
                    </div>
                    <div
                      style={{
                        fontSize: '20px',
                        fontWeight: '700',
                      }}
                    >
                      {/* {totalCost ? `$${parseFloat(totalCost).toFixed(2)}` : '$0'} */}
                      {currencyFormatter.format(totalCost || 0)}
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center justify-content-between pt-3"
                    style={{
                      borderBottom: '0.5px solid #959595',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#182838',
                      }}
                    >
                      Net Margin
                    </div>
                    <div className="">
                      <div
                        class="license-quantity d-flex  justify-content-left"
                        style={{ fontWeight: '600' }}
                      >
                        <label class="placeholder-label flex-grow-1" style={{ fontSize: '16px' }}>
                          Set Net Margin %
                        </label>
                        <div class="">
                          <Field
                            name={`NetMarginPercentage`}
                            type="text"
                            errorMessage="State Required"
                            min={0}
                            max={100}
                            component={ReduxPlusMinusPercentageInput}
                            disableOnPaste={true}
                            validate={validate.requiredZeroAtleast}
                            fieldName={'Net Margin'}
                            className="number-icon"
                            style={{
                              textAlign: 'center',
                              margin: '0px 5px',
                              height: '32px',
                              width: '56px',
                              fontSize: '16px',
                              background: 'white',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between pt-3">
                    <div
                      style={{
                        fontSize: '18px',
                        color: '#182838',
                        fontWeight: '600',
                      }}
                    >
                      Total Service Cost
                    </div>
                    <div
                      style={{
                        fontSize: '28px',
                        fontWeight: '700',
                      }}
                    >
                      {currencyFormatter.format(totalServiceCost || 0)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-12 col-lg-12 text-center">
            <SubmitButton loading={isUpsertFetching} style={{ height: '45px' }} value={'Submit'} />
            {/* <input
              type="submit"
              class="btn px-5"
              //  value={ServiceCodeId ? ` UPDATE` : `ADD`}
              //  disabled={totalServiceCost ? false : true}
            /> */}
          </div>
        </div>
      </form>
    </>
  );
};
