import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import Axios from 'axios';
import {
  updateStaffCenter,
  loadDefaultValues,
  resetStaffCenter,
  listCombos,
  userFeatureFlagGet,
  centerOptionGet,
  centerConfigGet,
} from '../../store/actions';
import hostGrabber from '../../util/hostGrabber';
import { CACHE_CONFIG } from '../../store/cacheConfig';
import { ReduxSelect } from '../../component/custom-redux-components/index.js';
import { LIST_ROUTES } from '../../store/menuConfig';
import CustomModal from '../../core/modal';
import { blockRoutesDuringCenterChange } from './disabledUrl';;


let ChangeDefaultCenterForm = ({
  onSubmit,
  handleSubmit,
  Center,
  initialValues,
  isData,
  initialize,
  dispatch,
  history,
}) => {
  const handleCenterChange = centerVal => {
    dispatch(updateStaffCenter({ centerId: centerVal }));
  };
  if (!isData) initialize(initialValues);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex align-items-center dynamic-center">
        <div className="form-group mb-0" style={{ width: '280px' }}>
          <Field
            name={`centerId`}
            component={ReduxSelect}
            options={Center}
            onChange={centerVal => {
              if (blockRoutesDuringCenterChange.includes(history.location.pathname)) {
                window.alert(
                  'Changing the center is not allowed while actively working on a form.',
                );
                dispatch(loadDefaultValues({}));
                return;
              } else {
                return handleCenterChange(centerVal);
              }
            }}
          />
        </div>
      </div>
      {/* <div className="row setting-gap">
        <div className="col-12 col-md-6 col-lg-4 mt-2">
          <input type="submit" value="Save" className="btn btn-sm stn-btn" />
        </div>
      </div> */}
    </form>
  );
};

const ReduxChangeDefaultCenterForm = connect(state => ({
  isData: formValueSelector('DefaultCenterForm')(state, 'centerId'),
}))(
  reduxForm({
    form: 'DefaultCenterForm',
  })(ChangeDefaultCenterForm),
);

class DefaultCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerVal: '',
    };
  }

  componentWillReceiveProps({ isCenterUpdated, defaultCenter, loaderName }) {
    const { dispatch } = this.props;

    if (
      defaultCenter.toString() &&
      defaultCenter.toString() != this.props.defaultCenter.toString()
    ) {
      localStorage.setItem('centerId', defaultCenter);
      sessionStorage.setItem('currentCenter', defaultCenter);
      if (loaderName == 'false' || loaderName == false) {
        sessionStorage.setItem('loaderName', 'loader');
      } else { 
        if (hostGrabber() && loaderName=="loader") {
          sessionStorage.setItem('loaderName', 'youuloader');
        } else {
          sessionStorage.setItem('loaderName', loaderName);
        }
      
      }
    }
    if (isCenterUpdated && isCenterUpdated != this.props.isCenterUpdated) {
      // localStorage.setItem('centerId', defaultCenter);
      // this.props.dispatch(userFeatureFlagGet({}));
      // this.props.dispatch(loadDefaultValues({}));

      /*Axios.post('user/ListMenu', {}).then(response => {
        const menus = [...response.data.data.splice(0, 1), ...response.data.data];
        localStorage.setItem('userName', response.data.UserName);
        localStorage.setItem('userRole', response.data.UserRole);
        localStorage.setItem('centerName', response.data.CenterName);
        localStorage.setItem('dr:menu', JSON.stringify(menus));
        localStorage.setItem('profileImgUrl', response.data.ProfileImgUrl);
        localStorage.setItem('email', response.data.Email);
        localStorage.setItem('adminUserInfo', JSON.stringify(response.data.AdminUserInfo));
      });*/
      window.location.reload();
    }
  }
  componentDidMount() {
    this.props.dispatch(userFeatureFlagGet({}));
    //this.props.dispatch(listCombos({ comboTypes: 'Center' }));
    //this.props.dispatch(loadDefaultValues({}));
    localStorage.setItem('centerId', this.props.defaultCenter);

    if (!CACHE_CONFIG['Center'] || !CACHE_CONFIG['Center'].loaded) {
      CACHE_CONFIG['Center'] = { loaded: true };
      this.props.dispatch(centerOptionGet({}));
      // this.props.dispatch(listCombos({ comboTypes: 'Center' }));
    }
    if (!CACHE_CONFIG['CenterConfig'] || !CACHE_CONFIG['CenterConfig'].loaded) {
      CACHE_CONFIG['CenterConfig'] = { loaded: true };
      this.props.dispatch(centerConfigGet({}));
      // this.props.dispatch(listCombos({ comboTypes: 'Center' }));
    }
  }

  onCenterChange = values => {
    // this.props.dispatch(updateStaffCenter({ centerId: values.centerId }));
    // this.props.dispatch(reset('ReduxChangeDefaultCenterForm'));
  };
  //   handleCenterChange = centerVal => {
  //     debugger;
  //     this.props.dispatch(updateStaffCenter({ centerId: centerVal }));
  //     this.setState({ centerVal });
  //   };
  render() {
    const {
      // combos: { Center },
      defaultCenter,
      centersList,
    } = this.props;

    return (
      <ReduxChangeDefaultCenterForm
        dispatch={this.props.dispatch}
        initialValues={{ centerId: defaultCenter }}
        Center={centersList}
        onSubmit={this.onCenterChange}
        // handleCenterChange={this.handleCenterChange}
        enableReinitialize
        history={this.props.history}
      />
    );
  }
}

const mapStateToProps = ({
  combos,
  default_values,
  update_staff_center,
  user_feature_flag_get,
  center_option_get,
}) => {
  var defaultCenter = 1;
  let loaderName;
  var isFetching = false;
  var isCenterUpdated = false;
  let featureFlagData = {};
  //   let countryISO = selector(state, 'country') || 'USA';
  const { data: fFData } = user_feature_flag_get;
  const { data: centers } = center_option_get;
  let centersList = [];
  if (!combos.data) {
    combos.data = {
      Center: [],
    };
  }
  if (centers && centers.result) {
    centersList = centers.result.comboType;
  }

  if (default_values && default_values.data) {
    // debugger;
    defaultCenter = default_values.data.centerId;
    loaderName = default_values.data.LoaderName;
  }

  if (update_staff_center) {
    if (update_staff_center.isFetching) {
      if (!isFetching) {
        isFetching = update_staff_center.isFetching;
      }
    } else if (update_staff_center.data && update_staff_center.data.success) {
      isCenterUpdated = true;
    }
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    featureFlagData = fFData.FeatureFlag[0];
  }
  return {
    // combos: combos.data,
    defaultCenter,
    isFetching,
    isCenterUpdated,
    featureFlagData,
    centersList,
    loaderName,
    // initialValues: {
    //   centerId: 2,
    // },
  };
};

export default connect(mapStateToProps)(withRouter(DefaultCenter));

// const selector = formValueSelector('DefaultCenterForm');
