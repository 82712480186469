import React, { Component } from 'react';
import { Bar, HorizontalBar, Line, Doughnut, Pie } from 'react-chartjs-2';
import {
  // barData,
  barOptions,
  // horizontalBarData,
  horizontalBarOptions,
  // lineData,
  // lineData2,
  lineOptions,
  // pieData,
  pieOptions,
  // doughnutData,
  doughnutOptions,
} from './chartData';

import { ChartJs, Title } from 'chart.js';
import {
  getDashboardLoadAppUsers,
  getAdvisorMemberTrends,
  getAdvisorMemberTrendsHistory,
} from '../store/actions';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader, { Loader2 } from '../Components/Loader';
import { Table, Typography, Tooltip, Progress, Slider } from 'antd';
import ReactApexChart from 'react-apexcharts';
import AppSubscribers from './appSubscribersList';
import { currencyFormatter, numberFormat } from '../util/formatter';
import CustomModal from '../core/modal';
import { Upload, message, Modal, Popconfirm, Icon } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import _blank from '../images/blank-profile.png';
import { data } from 'jquery';
import MobileAdvisorTabs from './MobileAdvisors';

const ForbiddenUsers = ({ userData, history }) => {
  const {
    UserGuid,
    DisplayName,
    ProfileImgUrl,
    ForbiddenWord,
    Context,
    WordUsedDate,
    WordUsedTime,
  } = userData;
  return (
    <>
      <div
        style={{ background: '#FFF4F4', borderRadius: '8px' }}
        className="row pt-2 pb-2 mt-1 mb-1"
      >
        <div class="col-6 d-flex px-0 align-items-center">
          <img
            className="autoSelectImage"
            src={ProfileImgUrl ? ProfileImgUrl : 'images/blank-profile.png'}
            style={{ marginLeft: '5px' }}
          />
          <div>
            <div className="col-12" style={{ fontSize: '13px' }}>
              {DisplayName}
            </div>
            <div className="col-12 pl-3">
              <i class="far fa-comments"></i>{' '}
              <span
                style={{
                  borderRadius: '2px',
                  background: '#FF7979',
                  color: 'white',
                  padding: '2px',
                  fontSize: '12px',
                }}
              >
                {ForbiddenWord}
              </span>
            </div>
          </div>
        </div>
        <div class="col-4 px-0 d-flex align-items-end justify-content-end flex-column">
          <span style={{ fontSize: '14px', color: '#9A9A9A' }}>{WordUsedDate}</span>
          <span style={{ fontSize: '14px', color: '#9A9A9A' }}>{WordUsedTime}</span>
        </div>
        <div class="col-2 d-flex align-items-center">
          <img
            src="images/timeline/timeline.svg"
            width="35px"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              // sessionStorage.setItem('timelineFilterMultiCheckboxInteger', '25');
              // sessionStorage.setItem(
              //   'timelineFilterMultiCheckbox',
              //   JSON.stringify({ '0': ['Suspicious Terms', 'Video Checkin'], '1': ['Chat'] }),
              // );
              // history.push(
              //   `/timetravel/?subscriberId=${UserGuid}&TTFilter=25,17&TTItem=0_Suspicious%Terms:Video%Checkin,1_Chat`,
              // );
              history.push(
                `/timetravel/?subscriberId=${UserGuid}&TTFilter=25&TTItem=0_Suspicious%Terms`,
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
const TrendingUsers = ({ userData, history }) => {
  const {
    // UserGuid,
    // DisplayName,
    // ProfileImgUrl,
    // ForbiddenWord,
    // Context,
    // WordUsedDate,
    // WordUsedTime,

    LastTrendUpdateDate,
    MemberName,
    ProfileImageUrl,
    Reason,
    Tags,
    TrendLevel,
    UserGuid,
  } = userData;
  return (
    <>
      <div
        style={{ background: '#FFF4F4', borderRadius: '8px' }}
        className="row pt-2 pb-2 mt-1 mb-1"
      >
        <div class="col-6 d-flex px-0 align-items-center">
          <img
            className="autoSelectImage"
            src={ProfileImageUrl ? ProfileImageUrl : 'images/blank-profile.png'}
            style={{ marginLeft: '5px' }}
          />
          <div>
            <div className="col-12" style={{ fontSize: '13px' }}>
              {MemberName}
            </div>
            <div className="col-12 px-0">
              {/* <i class="far fa-comments"></i>{' '} */}
              <span
                style={{
                  // borderRadius: '2px',
                  // background: '#FF7979',
                  // color: 'white',
                  // padding: '2px',
                  fontSize: '12px',
                }}
              >
                {LastTrendUpdateDate}
              </span>
            </div>
          </div>
        </div>
        <div class="col-4 px-0 d-flex align-items-end justify-content-end flex-column">
          <span style={{ fontSize: '14px', color: '#9A9A9A' }}>{Reason}</span>
          <span style={{ fontSize: '14px', color: '#9A9A9A' }}>{Tags}</span>
        </div>
        {/* <div class="col-2 d-flex align-items-center">
          <img
            src="images/timeline/timeline.svg"
            width="35px"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem('timelineFilterMultiCheckboxInteger', '25');
              sessionStorage.setItem(
                'timelineFilterMultiCheckbox',
                JSON.stringify({ '0': ['Suspicious Terms'] }),
              );
              history.push(`/timetravel/?subscriberId=${UserGuid}`);
            }}
          />
        </div> */}
      </div>
    </>
  );
};
class AppUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRecord: {},
      barSeries: [
        {
          name: 'Total Revenue',
          type: 'column',
          data: [],
        },
        {
          name: 'Revenue Per Client',
          type: 'line',
          data: [],
        },
      ],
      barOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
            columnWidth: '25%',
            // distributed: true
          },
        },
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 4],
        },
        // title: {
        //   text: 'Legacy . New',
        // },
        dataLabels: {
          enabled: true,
          // enabledOnSeries: [1]
          formatter: function(val, opt) {
            return currencyFormatter.format(val);
          },
        },
        labels: ['Coaches', 'Counselors', 'Guides'],
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            title: {
              text: 'Total',
              style: {
                fontSize: '13px',
              },
            },
            labels: {
              formatter: function(value) {
                return currencyFormatter.format(value);
              },
            },
          },
          {
            opposite: true,
            title: {
              text: 'Average Revenue Per Client',
              style: {
                fontSize: '13px',
              },
            },
            labels: {
              formatter: function(value) {
                return currencyFormatter.format(value);
              },
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(getDashboardLoadAppUsers());
    this.props.dispatch(getAdvisorMemberTrends({}));
  }
  componentWillReceiveProps({ appUsersInfo }) {
    if (
      appUsersInfo &&
      JSON.stringify(appUsersInfo) != JSON.stringify(this.props.appUsersInfo) &&
      appUsersInfo.MonthlyRevenue
    ) {
      let labelText = Object.keys(appUsersInfo.MonthlyRevenue[0]);
      labelText.pop();
      let SeriesFirst = [];
      let SeriesSecond = [];
      labelText &&
        labelText.map(item => {
          SeriesFirst.push(
            appUsersInfo.MonthlyRevenue && appUsersInfo.MonthlyRevenue[0][item][0].TotalRevenue,
          );
          SeriesSecond.push(
            appUsersInfo.MonthlyRevenue && appUsersInfo.MonthlyRevenue[0][item][0].RevenuePerClient,
          );
        });
      // appUsersInfo.MonthlyRevenue.map(item => {
      //   SeriesFirst.push(item.PublishedCost, item.PrivateCost);
      //   SeriesSecond.push(item.AvgPublishedRevenuewPerClient, item.AvgPrivateRevenuewPerClient);
      // });
      let barSeriesFirst = { ...this.state.barSeries[0], data: SeriesFirst };
      let barSeriesSecond = { ...this.state.barSeries[1], data: SeriesSecond };
      let newBarSeries = [barSeriesFirst, barSeriesSecond];
      this.setState({
        barOptions: { ...this.state.barOptions, labels: labelText },
        barSeries: newBarSeries,
      });
    }
  }
  triggerModal = () => {
    this.setState(prevState => {
      return { showModal: !prevState.showModal };
    });
  };
  trendingModal = record => {
    debugger;
    this.props.dispatch(
      getAdvisorMemberTrendsHistory({
        json: JSON.stringify({
          Trends: [
            {
              UserGuid: record.UserGuid,
            },
          ],
        }),
      }),
    );
    this.setState(prevState => {
      return { showTrenModal: !prevState.showTrenModal, userRecord: record };
    });
  };
  render() {
    const {
      appUsersInfo,
      isFetching,
      DashboardMenu,
      Trend,
      TrendHistory,
      isFetchingHistory,
    } = this.props;
    const { userRecord } = this.state;
    // let dashboardTrendUser = (Trend && Trend.slice(0, 4)) || [];
    const {
      AdvisorType,
      AdvisorTypeCount,
      ClientEngaged,
      ClientEngagedCount,
      ClientAvgScore,
      ClientMisc,
      BadgeScore,
      ForbiddenWordsData,
    } = appUsersInfo;
    const { IsAppUserRevenueCard, IsAdvisorListCard, IsSuspiciousWordsCard } = DashboardMenu || {};
    let scoreArr = BadgeScore && Object.keys(BadgeScore[0]);
    var earnedFee = '';
    var earnedFeeChart = [];
    var avgSevIndex = '';
    var avgSevIndexChart = [];
    var paid = null;
    var free = null;
    var activeUsers = null;
    var inactiveUsers = null;
    var invitedUsers = null;
    var totalConnections = 0;
    var totalConnectionsChart = [10, 20, 30, 10];
    var newStudentEnroll = 0;
    var newStudentEnrollChart = [10, 20, 30, 10];
    var doughnutData = {
      // labels: [],
      labels: ['Free', 'Paid'],
      datasets: [
        {
          data: [0, 0],
          backgroundColor: ['#36A2EB', '#FF6384'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384'],
          borderWidth: 0,
        },
      ],
    };
    var pieData = {
      // labels: [],
      labels: ['Active', 'Inactive', 'Invited Users'],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: ['#36A2EB', '#FF6384', '#aed9f7'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384', '#aed9f7'],
          borderWidth: 0,
        },
      ],
    };
    var barData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd'],
        },
      ],
    };
    var horizontalBarData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0, 0],
          backgroundColor: ['#ff5d5e', '#ff5d5e', '#30e191', '#48b4ff', '#48b4ff', '#30e191'],
        },
      ],
    };
    var lineData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['gradientFill'],
        },
      ],
    };

    var lineData2 = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['gradientFill'],
        },
      ],
    };
    if (appUsersInfo && appUsersInfo.BlocksData) {
      for (var block of appUsersInfo.BlocksData) {
        if (block.Label == 'EARNED FEE') {
          earnedFee = block.Score;
        } else if (block.Label == 'AVG SEVERITY INDEX') {
          avgSevIndex = block.Score;
        } else if (block.Label == 'Paid Users') {
          paid = parseInt(block.Score);
          doughnutData.datasets[0].data[1] = paid;
        } else if (block.Label == 'Free Users') {
          free = parseInt(block.Score);
          doughnutData.datasets[0].data[0] = free;
        } else if (block.Label == 'Active Users') {
          activeUsers = numberFormat.format(parseInt(block.Score));
          if (isNaN(activeUsers)) {
            activeUsers = 0;
          }
          pieData.datasets[0].data[0] = activeUsers;
        } else if (block.Label == 'Inactive Users') {
          inactiveUsers = numberFormat.format(parseInt(block.Score));
          if (isNaN(inactiveUsers)) {
            inactiveUsers = 0;
          }
          pieData.datasets[0].data[1] = inactiveUsers;
        } else if (block.Label == 'Invited Users') {
          invitedUsers = numberFormat.format(parseInt(block.Score));
          if (isNaN(invitedUsers)) {
            invitedUsers = 0;
          }
          pieData.datasets[0].data[2] = invitedUsers;
        } else if (block.Label == 'TOTAL CONNECTIONS') {
          totalConnections = parseInt(block.Score);
        } else if (block.Label == 'NEW USERS ENROLLED') {
          newStudentEnroll = parseInt(block.Score);
        }
      }

      for (var chartData of appUsersInfo.LineChartData) {
        if (chartData.DataFor == 'EARNED FEE') {
          earnedFeeChart = chartData.DataAgg.split(',');
        } else if (chartData.DataFor == 'AVG SEVERITY INDEX') {
          avgSevIndexChart = chartData.DataAgg.split(',');
        } else if (chartData.DataFor == 'TOTAL CONNECTIONS') {
          totalConnectionsChart = chartData.DataAgg.split(',');
        } else if (chartData.DataFor == 'NEW STUDENTS ENROLLED') {
          newStudentEnrollChart = chartData.DataAgg.split(',');
        }
      }

      for (var chartData of appUsersInfo.ChartData) {
        if (chartData.Title == 'SOBER PEER BEHAVIORAL RATING') {
          barData.labels = chartData.Labels.split(',');
          barData.datasets[0].data = chartData.DataValues.split(',');
        } else if (chartData.Title == 'SOBRIETY METRICS') {
          horizontalBarData.labels = chartData.Labels.split(',');
          horizontalBarData.datasets[0].data = chartData.DataValues.split(',');
        } else if (chartData.Title == 'ENGAGEMENT METRICS') {
          lineData.labels = chartData.Labels.split(',');
          lineData.datasets[0].data = chartData.DataValues.split(',');
        } else if (chartData.Title == 'INTERACTION METRICS') {
          lineData2.labels = chartData.Labels.split(',');
          lineData2.datasets[0].data = chartData.DataValues.split(',');
        }
      }
    }
    const AdviserTypeColumns = [
      {
        title: '',
        dataIndex: 'Header',
        key: 'Header',
        align: 'right',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && text}</span>
            </div>
          );
        },
      },
      {
        title: 'Coaches',
        dataIndex: 'CoachCount',
        key: 'CoachCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Counselors',
        dataIndex: 'CounselorCount',
        key: 'CounselorCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Guides',
        dataIndex: 'GuideCount',
        key: 'GuideCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
    ];
    const ClientEngagedColumns = [
      {
        title: '',
        dataIndex: 'Header',
        key: 'Header',
        align: 'right',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && text}</span>
            </div>
          );
        },
      },
      {
        title: 'Coaches',
        dataIndex: 'CoachCount',
        key: 'CoachCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Counselors',
        dataIndex: 'CounselorCount',
        key: 'CounselorCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Guides',
        dataIndex: 'GuideCount',
        key: 'GuideCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
    ];
    const DashboardTrendUserColumns = [
      {
        title: (
          <span>
            Trend <br />
            Level
          </span>
        ),
        dataIndex: 'TrendLevel',
        key: 'TrendLevel',
        width: 180,
        sorter: (a, b) => isNumericComparer(a.TrendLevel, b.TrendLevel),
        sortDirections: ['descend', 'ascend'],
        render: (text, student) => {
          return (
            <div className="d-flex align-items-center" style={{width: '62px'}}>
              <div
                className={
                  text == 0
                    ? 'pic-circle-neutral'
                    : text == 1
                    ? 'pic-circle-arrow-up'
                    : 'pic-circle-arrow-down'
                }
              >
                <div class="student-list-pic" style={{ flex: '0 0 29%' }}>
                  <img
                    src={student.ProfileImageUrl || _blank}
                    alt="student"
                    class="img-circle"
                    height="40"
                    width="40"
                    style={{
                      borderRadius: '50%',
                      padding: '2px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Name',
        dataIndex: 'MemberName',
        key: 'MemberName',
        sorter: (a, b) => isComparer(a.MemberName, b.MemberName),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span
                onClick={() => {
                  this.props.history.push(`/subscriberProfile/${record.UserGuid}`);
                }}
                style={{ color: '#46b4fe', cursor: 'pointer', fontWeight: '700' }}
              >
                {text && text}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Last Trend Update',
        dataIndex: 'LastTrendUpdateDate',
        key: 'LastTrendUpdateDate',
        sorter: (a, b) =>
          isDateComparer(a.LastTrendUpdateDateForSort, b.LastTrendUpdateDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Activity',
        dataIndex: 'Reason',
        key: 'Reason',
        sorter: (a, b) => isComparer(a.Reason, b.Reason),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Remarks',
        dataIndex: 'Tags',
        key: 'Tags',
        sorter: (a, b) => isComparer(a.Tags, b.Tags),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div>
              {text && text.length > 0 ? (
                <span
                  className="text-uppercase"
                  style={{
                    borderRadius: '2px',
                    background: record.IsRedColor ? '#FF3B3B' : '#2FE192',
                    color: 'white',
                    padding: '0px 4px',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                  }}
                  // className={record.TrendLevel > 0 ? 'trend-level-possitive' : 'trend-level-negative'}
                >
                  {text}
                </span>
              ) : (
                text
              )}
            </div>
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'Tags',
        key: 'Tags',
        render: (text, record) => {
          return (
            <div
              className=""
              onClick={() => {
                this.trendingModal(record);
              }}
              style={{ cursor: 'pointer' }}
            >
              <img src="images/icons/trend.svg" alt="trend" />
            </div>
          );
        },
      },
    ];
    const TrendUserColumns = [
      {
        title: 'Last Trend Update',
        dataIndex: 'LastTrendUpdateDate',
        key: 'LastTrendUpdateDate',
        sorter: (a, b) =>
          isDateComparer(a.LastTrendUpdateDateForSort, b.LastTrendUpdateDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Date',
      //   dataIndex: 'Date',
      //   key: 'Date',
      //   sorter: (a, b) => isComparer(a.Date, b.Date),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Time',
      //   dataIndex: 'Time',
      //   key: 'Time',
      //   sorter: (a, b) => isComparer(a.Time, b.Time),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Activity',
        dataIndex: 'Activity',
        key: 'Activity',
        sorter: (a, b) => isComparer(a.Activity, b.Activity),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Remarks',
        dataIndex: 'Remarks',
        key: 'Remarks',
        sorter: (a, b) => isComparer(a.Remarks, b.Remarks),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div>
              {text && text.length > 0 ? (
                <>
                  <span
                    className="text-uppercase"
                    style={{
                      borderRadius: '2px',
                      background: record.TrendLevel > 0 ? '#FF3B3B' : '#2FE192',
                      color: 'white',
                      padding: '0px 4px',
                      fontSize: '12px',
                    }}
                    // className={record.TrendLevel > 0 ? 'trend-level-possitive' : 'trend-level-negative'}
                  >
                    {text}
                  </span>
                </>
              ) : (
                text
              )}
            </div>
          );
        },
      },
    ];

    // const { Coach, Counselor, Guide } = BadgeScore && BadgeScore[0];
    return (
      <>
        {/* <h3 class="page-title mb-4">Quick Scores</h3> */}
        <Loader loading={isFetching} />

        <MobileAdvisorTabs />
        <div className="row">
          <div className="col-sm-12 d-none">
            <div className="purchase-analytics-package-body">
              <div className="purchase-analytics-package">
                <div className="analytics-package">
                  <div className="list-item">
                    <div>
                      <ul>
                        <li>Active/Inactive Clients</li>
                        <li>SP Behavioral Ratings</li>
                        <li>Time In Treatment</li>
                        <li>Standard Cost of Care</li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li>Trigger Location Activity</li>
                        <li>Score Improvement</li>
                        <li>Referal Sources</li>
                        <li>Demographic Profile</li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li>Service Units Delivered</li>
                        <li>Mapping</li>
                      </ul>
                    </div>
                  </div>
                  <div className="purchase-button">
                    <button type="button" class="btn">
                      Purchase Analytics Package
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {IsSuspiciousWordsCard && (
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3 mb-4">
              <div class="card border-0 h-100 inner-content">
                <div class="row" style={{ padding: '1.25rem' }}>
                  <div class="col-12 pt-3">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h5 class="text-uppercase font-weight-bold mb-3">{'suspicious Term'}</h5>
                      <div className="d-flex align-items-center" onClick={this.triggerModal}>
                        <span
                          style={{
                            fontSize: '18px',
                            color: '#FF7979',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                        >
                          {ForbiddenWordsData && numberFormat.format(ForbiddenWordsData.length)}
                        </span>
                        &nbsp;
                        <i
                          class="fas fa-external-link-alt"
                          style={{ color: '#46b4fe', cursor: 'pointer' }}
                        ></i>
                      </div>
                      <Modal
                        zIndex={100}
                        visible={this.state.showModal}
                        footer={null}
                        closable={false}
                        width={500}
                        destroyOnClose={true}
                      >
                        <div class="modal-content border-0">
                          <div class="modal-header flex-wrap border-bottom-0 ">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              onClick={() => {
                                this.setState({
                                  showModal: false,
                                });
                              }}
                            >
                              ×
                            </button>
                          </div>
                          <div class="modal-body rcm-panel">
                            <h5 style={{ textTransform: 'uppercase' }}>suspicious Term(s)</h5>
                            <h6 style={{ fontSize: '13px' }}>
                              {ForbiddenWordsData && ForbiddenWordsData.length} of your client(s)
                              have used suspicious term(s).
                            </h6>

                            <div
                              style={{
                                width: '436px',
                                height: '400px',
                                margin: '0 auto',
                                padding: '20px',
                                overflowX: 'auto',
                              }}
                            >
                              {ForbiddenWordsData &&
                                ForbiddenWordsData.map((item, index) => {
                                  return (
                                    <ForbiddenUsers history={this.props.history} userData={item} />
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="col-12">
                    {ForbiddenWordsData && ForbiddenWordsData[0] && (
                      <ForbiddenUsers
                        triggerModal={this.triggerModal}
                        history={this.props.history}
                        userData={ForbiddenWordsData && ForbiddenWordsData[0]}
                      />
                    )}
                    {ForbiddenWordsData && ForbiddenWordsData[1] && (
                      <ForbiddenUsers
                        triggerModal={this.triggerModal}
                        history={this.props.history}
                        userData={ForbiddenWordsData && ForbiddenWordsData[1]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              IsSuspiciousWordsCard
                ? 'col-sm-12 col-md-12 col-lg-6 col-xl-3 mb-4'
                : 'col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4'
            }
          >
            <div class="card border-0 h-100 inner-content">
              <div class="row" style={{ padding: '15px' }}>
                <div class="col-12 pt-3">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h5 class="text-uppercase font-weight-bold mb-3">{'Average Scores'}</h5>
                    <div></div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3 px-0 text-center">
                  <div class="mb-2">
                    <Progress
                      type="circle"
                      strokeWidth={8}
                      strokeColor="#2fe192"
                      width={60}
                      percent={(ClientAvgScore && ClientAvgScore[0].SPBehavioral) || 0}
                      format={percent => (
                        <div class="progress-textbox" style={{ fontSize: '13px' }}>
                          <strong style={{ fontSize: '16px' }}>{percent}</strong>
                          score
                        </div>
                      )}
                      trailColor={'#eff7fe'}
                    />
                  </div>
                  <h6 class="font-weight-semibold" style={{ fontSize: '12px' }}>
                    SP Rating
                  </h6>
                </div>
                <div className="col-sm-4 col-lg-3 px-0 text-center">
                  <div class="mb-2">
                    <Progress
                      type="circle"
                      strokeWidth={8}
                      strokeColor="#46b4fe"
                      width={60}
                      percent={(ClientAvgScore && ClientAvgScore[0].Faith) || 0}
                      format={percent => (
                        <div class="progress-textbox" style={{ fontSize: '13px' }}>
                          <strong style={{ fontSize: '16px' }}>{percent}</strong>
                          score
                        </div>
                      )}
                      trailColor={'#eefdf8'}
                    />
                  </div>
                  <h6 class="font-weight-semibold" style={{ fontSize: '12px' }}>
                    Faith
                  </h6>
                </div>
                <div className="col-sm-4 col-lg-3 px-0 text-center">
                  <div class="mb-2">
                    <Progress
                      type="circle"
                      strokeWidth={8}
                      width={60}
                      strokeColor="#ff5d5d"
                      percent={(ClientAvgScore && ClientAvgScore[0].Addiction) || 0}
                      format={percent => (
                        <div class="progress-textbox" style={{ fontSize: '13px' }}>
                          <strong style={{ fontSize: '16px' }}>{percent}</strong>
                          score
                        </div>
                      )}
                      trailColor={'#fef2f2'}
                    />
                  </div>
                  <h6
                    class="font-weight-semibold"
                    style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                  >
                    Addiction
                  </h6>
                </div>
                <div className="col-sm-4 col-lg-3 px-0 text-center">
                  <div class="mb-2">
                    <Progress
                      type="circle"
                      strokeWidth={8}
                      width={60}
                      strokeColor="#FFBF00"
                      percent={(ClientAvgScore && ClientAvgScore[0].Gateway) || 0}
                      format={percent => (
                        <div class="progress-textbox" style={{ fontSize: '13px' }}>
                          <strong style={{ fontSize: '16px' }}>{percent}</strong>
                          score
                        </div>
                      )}
                      trailColor={'#FFF9E5'}
                    />
                  </div>
                  <h6
                    class="font-weight-semibold"
                    style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                  >
                    Gateway
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              IsSuspiciousWordsCard
                ? 'col-sm-12 col-md-12 col-lg-6 col-xl-3 mb-4'
                : 'col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4'
            }
          >
            <div class="card border-0 h-100 inner-content">
              <div class="row" style={{ padding: '1.25rem' }}>
                <div class="col-12 pt-3">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h5 class="text-uppercase font-weight-bold mb-3">{'Active Paid VS FREE'}</h5>

                    <div></div>
                  </div>
                </div>
                <div class="col-sm-8 col-12 ">
                  <ul class="mt-2 p-0 chart-bullet">
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.props.history.push('/appSubscribers?filter=PU');
                      }}
                    >
                      {' '}
                      {paid} Paid Users
                    </li>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.props.history.push('/appSubscribers?filter=FU');
                      }}
                    >
                      {' '}
                      {free} Free Users
                    </li>
                  </ul>
                </div>
                <div class="col-sm-4 col-12" style={{ padding: 0 }}>
                  {!paid && !free ? (
                    ''
                  ) : (
                    <Doughnut
                      data={doughnutData}
                      height={144}
                      width={144}
                      options={doughnutOptions}
                      className="h-100"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              IsSuspiciousWordsCard
                ? 'col-sm-12 col-md-12 col-lg-6 col-xl-3 mb-4'
                : 'col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4'
            }
          >
            <div class="card border-0 h-100 inner-content">
              <div class="row" style={{ padding: '1.25rem' }}>
                <div class="col-12 pt-3">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h5 class="text-uppercase font-weight-bold mb-3">{'App Subscribers'}</h5>
                    <div></div>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <ul class="mt-2 p-0 chart-bullet" style={{ width: '150px' }}>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.props.history.push('/appSubscribers?filter=IU');
                      }}
                    >
                      {' '}
                      {inactiveUsers} Inactive Users
                    </li>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.props.history.push('/appSubscribers?filter=AU');
                      }}
                    >
                      {' '}
                      {activeUsers} Active Users{' '}
                    </li>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.props.history.push('/appSubscribers?filter=INU');
                      }}
                    >
                      {' '}
                      {invitedUsers} Invited Users
                    </li>
                  </ul>
                </div>
                <div
                  class="col-sm-6 col-12"
                  style={{ paddingTop: 0, paddingRight: 20, paddingLeft: 20 }}
                >
                  {!activeUsers && !inactiveUsers ? (
                    ''
                  ) : (
                    <Pie data={pieData} height={401} width={401} options={pieOptions} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-none">
          <div class="col-sm-6 col-xl-3 mb-4">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-12 pt-3">
                  <h6 class="text-uppercase font-weight-bold mb-3">Earned fee</h6>
                </div>
                <div class="col-xl-8 col-8">
                  {earnedFee ? (
                    <span class="value">{currencyFormatter.format(earnedFee)}</span>
                  ) : (
                    <span class="value">{currencyFormatter.format(0)}</span>
                  )}
                </div>
                <div class="col-xl-4 col-4">
                  <div class="graph-img">
                    {/* <Sparklines data={earnedFeeChart}>
                      <SparklinesLine color="#179bef" />
                    </Sparklines> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-3 mb-4">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-12 pt-3">
                  <h6 class="text-uppercase font-weight-bold mb-3">Avg. Severity Index</h6>
                </div>
                <div class="col-xl-8 col-8">
                  <span class="value">{Math.floor(avgSevIndex)}</span>
                </div>
                <div class="col-xl-4 col-4">
                  <div class="graph-img">
                    {/* <Sparklines data={avgSevIndexChart}>
                      <SparklinesLine color="#179bef" />
                    </Sparklines> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xl-3 mb-4 ">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-sm-8 col-12 ">
                  <div class="pt-1">
                    <h6 class="text-uppercase font-weight-bold mb-3">Paid vs Free</h6>
                  </div>
                  <ul class="mt-2 p-0 chart-bullet">
                    <li> {paid} Paid Users</li>
                    <li> {free} Free Users</li>
                  </ul>
                </div>
                <div class="col-sm-4 col-12">
                  {!paid && !free ? (
                    ''
                  ) : (
                    <Doughnut
                      data={doughnutData}
                      height={144}
                      width={144}
                      options={doughnutOptions}
                      className="h-100"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-3 mb-4">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-sm-8 col-12">
                  <div class="pt-1">
                    <h6 class="text-uppercase font-weight-bold mb-3">Users</h6>
                  </div>
                  <ul class="mt-2 p-0 chart-bullet">
                    <li> {activeUsers} Active Users </li>
                    <li> {inactiveUsers} Inactive Users</li>
                  </ul>
                </div>
                <div class="col-sm-4 col-12">
                  {!activeUsers || !inactiveUsers ? (
                    ''
                  ) : (
                    <Pie data={pieData} height={401} width={401} options={pieOptions} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          {/* trending user */}
          <div class="col-sm-12 col-md-6 col-lg-6 mb-4 trend-user">
            <div class="card border-0">
              <div class="row" style={{ padding: '1.25rem' }}>
                <div class="col-12 pt-3">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h5 class="text-uppercase font-weight-bold mb-3">{'Member Trends'}</h5>
                  </div>
                </div>
                <div className="col-12 px-0 member-trend-table">
                  <Table
                    dataSource={Trend || []}
                    columns={DashboardTrendUserColumns}
                    bordered
                    pagination={false}
                    style={{
                      height: '495px',
                      maxHeight: '495px',
                      overflowY: 'scroll',
                    }}
                  />
                </div>
              </div>
            </div>
            <Modal
              zIndex={100}
              visible={this.state.showTrenModal}
              footer={null}
              closable={false}
              width={500}
              destroyOnClose={true}
            >
              <div class="modal-content border-0">
                <div class="modal-header flex-wrap border-bottom-0 ">
                  <h5>Member Trend History</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        showTrenModal: false,
                      });
                    }}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body rcm-panel">
                  {/* <h6 style={{ fontSize: '13px' }}>Trending Users</h6> */}
                  <div className="d-flex align-items-center">
                    <div
                      className={
                        userRecord.TrendLevel > 0 ? 'pic-circle-arrow-up' : 'pic-circle-arrow-down'
                      }
                      style={{ marginRight: '10px', marginLeft: '0px' }}
                    >
                      <div class="student-list-pic" style={{ flex: '0 0 29%' }}>
                        <img
                          src={userRecord.ProfileImageUrl || _blank}
                          alt="student"
                          class="img-circle"
                          height="40"
                          width="40"
                          style={{
                            borderRadius: '50%',
                            padding: '2px',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    </div>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      {userRecord.MemberName}
                    </span>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <Table
                          dataSource={TrendHistory || []}
                          columns={TrendUserColumns}
                          bordered
                          pagination={false}
                          scroll={{ y: 350 }}
                        />
                      </div>
                    </div>
                    {/* {TrendHistory &&
                      TrendHistory.map((item, index) => {
                        return <TrendingUsers history={this.props.history} userData={item} />;
                      })} */}
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="col-6">
            <div className="row dashboard-analytics">
              <div class="col-lg-12  mb-4">
                <div class="card border-0 h-100 pt-3 pb-3 ">
                  <div class="row align-items-center card-body px-3">
                    <div class="col-6 py-3">
                      <h5 class="text-uppercase font-weight-bold mb-0">{'Advisor Types'}</h5>
                    </div>
                    <div class="col-6 py-3">
                      {/* <input
                    type="button"
                    class="btn btn-outline-primary mr-2"
                    value="Purchase Analytics Package"
                  /> */}
                    </div>
                    <div class="col-12">
                      <div className="custom-header">
                        <Table
                          dataSource={AdvisorType || []}
                          columns={AdviserTypeColumns}
                          bordered
                          pagination={false}
                        />
                      </div>
                      <div className="app-client-type-count mt-2">
                        <div className="mr-2">
                          <span>Total Advisors</span>{' '}
                          <span className="mr-1 bold" style={{ color: '#4AB0FE' }}>
                            {AdvisorTypeCount &&
                              numberFormat.format(AdvisorTypeCount[0].TotalCount || 0)}
                          </span>
                        </div>
                        <div className="mr-2">
                          <span>Total Active Advisors</span>{' '}
                          <span className="mr-1 bold" style={{ color: '#2FE192' }}>
                            {AdvisorTypeCount &&
                              numberFormat.format(AdvisorTypeCount[0].ActiveCount || 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12  mb-4">
                <div class="card border-0 h-100 pt-3 pb-3 ">
                  <div class="row align-items-center card-body px-3">
                    <div class="col-6 py-3">
                      <h5 class="text-uppercase font-weight-bold mb-0">{'Clients Engaged'}</h5>
                    </div>
                    <div class="col-6 py-3">
                      {/* <input
                    type="button"
                    class="btn btn-outline-primary mr-2"
                    value="Purchase Analytics Package"
                  /> */}
                    </div>
                    <div class="col-12">
                      {/* <Loader2 loading={isFetchingHistory} /> */}
                      <div className="custom-header">
                        <Table
                          dataSource={ClientEngaged || []}
                          columns={ClientEngagedColumns}
                          bordered
                          pagination={false}
                          loading={isFetchingHistory}
                        />
                      </div>
                      <div className="app-client-type-count mt-2">
                        <div className="mr-2">
                          <span>Total Clients</span>{' '}
                          <span className="mr-1 bold" style={{ color: '#4AB0FE' }}>
                            {ClientEngagedCount &&
                              numberFormat.format(ClientEngagedCount[0].TotalCount || 0)}
                          </span>{' '}
                        </div>
                        <div className="mr-2">
                          <span>Total Active Clients</span>{' '}
                          <span className="mr-1 bold" style={{ color: '#2FE192' }}>
                            {ClientEngagedCount &&
                              numberFormat.format(ClientEngagedCount[0].ActiveCount || 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6  mb-4">
            <div class="card border-0 h-100 pt-3 pb-3 ">
              <div class="row align-items-center card-body">
                <div class="col-lg-2 col-sm-2  text-md-center ">
                  <img src="images/connection.svg" width="59" class="" alt="" />
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="student-info">
                    <h2>
                      {ClientMisc &&
                        numberFormat.format(ClientMisc[0].TotalConnectedPopulation || 0)}
                    </h2>
                    <span class="text-uppercase h6">Total Connected Population</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 text-md-right ">
                  {/* <Sparklines data={totalConnectionsChart}>
                    <SparklinesLine color="#179bef" />
                  </Sparklines> */}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6  mb-4">
            <div class="card border-0 h-100 pt-3 pb-3 ">
              <div class="row align-items-center card-body">
                <div class="col-lg-2  col-sm-2 text-md-center">
                  <img src="images/new-students.svg" alt="" width="58" />
                </div>
                <div class="col-lg-6  col-sm-6">
                  <div class="student-info">
                    <h2>
                      {ClientMisc && numberFormat.format(ClientMisc[0].TotalInteractions || 0)}
                    </h2>
                    <span class="text-uppercase h6">Total Interactions</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 text-md-right">
                  {/* <Sparklines data={newStudentEnrollChart}>
                    <SparklinesLine color="#179bef" />
                  </Sparklines> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-6">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-6">
                  <h5 class="text-uppercase pt-4">Score</h5>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <div className="score-legend">
                    <ul class="mt-2 p-0 score-bullet">
                      <li> SP Behavioral</li>
                      <li> Faith</li>
                      <li> Addiction</li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div className="measure ">
                    {scoreArr &&
                      scoreArr.map(item => {
                        let StartSPBehavioral =
                          BadgeScore && BadgeScore[0][item][0].StartSPBehavioral;
                        let StartFaith = BadgeScore && BadgeScore[0][item][0].StartFaith;
                        let StartAddiction = BadgeScore && BadgeScore[0][item][0].StartAddiction;
                        let SPBehavioral = BadgeScore && BadgeScore[0][item][0].SPBehavioral;
                        let Faith = BadgeScore && BadgeScore[0][item][0].Faith;
                        let Addiction = BadgeScore && BadgeScore[0][item][0].Addiction;
                        return (
                          <div class="progress_bar">
                            <div class="progress_bar_label">
                              <span class="progress_bar_label_text">{item}</span>
                            </div>
                            <div className={`line`}>
                              <Slider
                                value={SPBehavioral}
                                // defaultValue={SPBehavioral}
                                className={`progress-slider-green`}
                                // className={`progress-slider-${MilestoneColor}`}
                                marks={{
                                  // 0: 0,
                                  [StartSPBehavioral]: StartSPBehavioral,
                                  [SPBehavioral]: SPBehavioral,
                                }}
                              />
                            </div>
                            <div className={`line`}>
                              <Slider
                                value={Faith}
                                className={`progress-slider-blue`}
                                marks={{
                                  // 0: 0,
                                  [StartFaith]: StartFaith,
                                  [Faith]: Faith,
                                }}
                              />
                            </div>
                            <div className={`line`}>
                              <Slider
                                value={Addiction}
                                className={`progress-slider-red`}
                                marks={{
                                  // 0: 0,
                                  [StartAddiction]: StartAddiction,
                                  [Addiction]: Addiction,
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {IsAppUserRevenueCard && (
            <div class="col-md-12 col-lg-6 col-xl-6">
              <div class="card border-0">
                <div class="row  card-body px-3">
                  <div class="col-12">
                    <h3>
                      {appUsersInfo &&
                        appUsersInfo.MonthlyRevenue &&
                        currencyFormatter.format(
                          appUsersInfo.MonthlyRevenue[0].TotalMonthlyRevenue || 0,
                        )}
                    </h3>
                    <h5>Total Monthly Revenue</h5>
                    <div>
                      <ReactApexChart
                        options={this.state.barOptions}
                        series={this.state.barSeries}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {IsAdvisorListCard && (
          <div class="mt-5 pt-2" style={{ backgroundColor: 'white' }}>
            <div class="col-md-12">{<AppSubscribers {...this.props} isDashboard={true} />}</div>
          </div>
        )}

        <div class="row mt-3 d-none">
          <div class="col-12 ">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 mb-lg-3">
                  <h5 class="text-uppercase pt-4">MAP</h5>
                  <div>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-none">
          <div class="col-md-6 mb-4 ">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 mb-lg-3">
                  <h5 class="text-uppercase pt-4">SOBER PEER BEHAVIORAL RATING</h5>
                </div>
                <div class="col-12">
                  <Bar data={barData} options={barOptions} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 ">
                  <h5 class="text-uppercase pt-4">Sobriety Metrics</h5>
                </div>
                <div class="col-12">
                  <HorizontalBar data={horizontalBarData} options={horizontalBarOptions} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 mb-lg-3">
                  <div class="row pt-4">
                    <div class="col-12 col-lg-8">
                      <h5 class="text-uppercase ">Engagement Metrics</h5>
                    </div>
                    <div class="form-group col-lg-4">
                      <select class="custom-select">
                        <option selected="">Weekly </option>
                        <option value="">Monthly</option>
                        <option value="">Last week</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <Line data={lineData} options={lineOptions} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 mb-lg-3">
                  <div class="row pt-4">
                    <div class="col-12 col-lg-8">
                      <h5 class="text-uppercase ">Interaction Metrics</h5>
                    </div>
                    <div class="form-group col-lg-4">
                      <select class="custom-select">
                        <option selected="">Monthly</option>
                        <option value="">Last week</option>
                        <option value="">Weekly</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <Line data={lineData2} options={lineOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  get_dashboard_load_app_users,
  get_advisor_member_trends,
  get_advisor_member_trends_history,
}) => {
  var appUsersInfo = {};
  let Trend = [];
  let TrendHistory = [];
  var { isFetching } = get_dashboard_load_app_users;
  var { data: trendsData } = get_advisor_member_trends;
  const {
    data: trendsHistoryData,
    isFetching: isFetchingHistory,
  } = get_advisor_member_trends_history;
  if (get_dashboard_load_app_users && get_dashboard_load_app_users.data) {
    appUsersInfo =
      get_dashboard_load_app_users.data.DashboardAppUserMetrics &&
      get_dashboard_load_app_users.data.DashboardAppUserMetrics[0];
  }
  if (trendsData && trendsData.Data) {
    Trend = trendsData.Data;
  }
  if (trendsHistoryData && trendsHistoryData.TrendHistory) {
    TrendHistory = trendsHistoryData.TrendHistory || [];
  }

  return {
    appUsersInfo,
    Trend,
    TrendHistory,
    isFetching,
    isFetchingHistory,
  };
};

export default connect(mapStateToProps)(withRouter(AppUsers));
