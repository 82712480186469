import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* appSubscribers(action) {
  try {
    const { payload } = action;
    const response = yield API.listAppSubscribers(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_FAILURE,
    });
  }
}

export function* getAppSubscriberProfileDashboard(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberProfileDashboard(payload);

    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data) && JSON.parse(response.data.data).AppUser[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_FAILURE,
    });
  }
}

export function* getAppSubscribersProfile(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscribersProfile(payload);

    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_SUCCESS,
      payload: JSON.parse(response.data.data).AppUserProfile[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_FAILURE,
    });
  }
}

export function* getAppSubscribersChat(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscribersChat(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_FAILURE,
    });
  }
}

export function* getAppSubscriberChatCoach(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberChatCoach(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_FAILURE,
    });
  }
}

export function* getAppSubscriberProfileActivities(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppSubscriberProfileActivities(payload);
    yield put({
      type: ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_SUCCESS,
      // payload: response.data,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_FAILURE,
    });
  }
}

export function* addAppSubscribersSurvey(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscribersSurvey(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_FAILURE,
    });
  }
}

export function* addAppSubscriberNote(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscriberNote(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_FAILURE,
    });
  }
}

export function* addAppSubscriberTask(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscriberTask(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_FAILURE,
    });
  }
}

export function* deleteAppSubscriberTask(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteAppSubscriberTask(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_FAILURE,
    });
  }
}

export function* deleteAppSubscribersSurvey(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteAppSubscribersSurvey(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_FAILURE,
    });
  }
}

export function* appSubscribersUserSearch(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscribersUserSearch(payload);
    if (payload.saveSearch) {
      yield put({
        type: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_SUCCESS,
        payload: {
          data: (response.data.data && JSON.parse(response.data.data).AppUserSearch) || [],
          searchStr: payload.SearchStr,
        },
      });
    } else {
      yield put({
        type: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_SUCCESS,
        payload: JSON.parse(response.data.data || '{}').AppUserSearch,
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_FAILURE,
    });
  }
}

export function* appSubscriberCompleteTask(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscriberCompleteTask(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_FAILURE,
    });
  }
}

export function* addAppSubscriberCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.addAppSubscriberCourse(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_FAILURE,
    });
  }
}

export function* appSubscribersupdateCase(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscribersupdateCase(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_FAILURE,
    });
  }
}

export function* deleteAppSubscriberCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteAppSubscriberCourse(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_FAILURE,
    });
  }
}

export function* appSubscriberCompleteCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscriberCompleteCourse(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_FAILURE,
    });
  }
}

export function* appSubscriberMapHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubscriberMapHistory(payload);
    yield put({
      type: ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data || '{}'),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_FAILURE,
    });
  }
}

export function* appMarkSystemObservation(action) {
  try {
    const { payload } = action;
    const response = yield API.appMarkSystemObservation(payload);
    yield put({
      type: ActionTypes.APP_MARK_SYSTEM_OBSERVATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_MARK_SYSTEM_OBSERVATION_FAILURE,
    });
  }
}

export function* loadAppSubSurveyDashboard(action) {
  try {
    const { payload } = action;
    const response = yield API.loadAppSubSurveyDashboard(payload);
    yield put({
      type: ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data).User[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_FAILURE,
    });
  }
}

export function* appSubSurveyAnsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appSubSurveyAnsGet(payload);
    yield put({
      type: ActionTypes.APP_SUB_SURVEY_ANS_GET_SUCCESS,
      payload: JSON.parse(response.data.result),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_SUB_SURVEY_ANS_GET_FAILURE,
    });
  }
}

export function* loadCaseProfile(action) {
  try {
    const { payload } = action;
    const response = yield API.loadCaseProfile(payload);
    yield put({
      type: ActionTypes.LOAD_CASE_PROFILE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_CASE_PROFILE_FAILURE,
    });
  }
}

export function* loadLocationAuthHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.loadLocationAuthHistory(payload);
    yield put({
      type: ActionTypes.LOAD_LOCATION_AUTH_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_LOCATION_AUTH_HISTORY_FAILURE,
    });
  }
}

export function* getUserNotePatient(action) {
  try {
    const { payload } = action;
    const response = yield API.getUserNotePatient(payload);
    yield put({
      type: ActionTypes.GET_USER_NOTE_PATIENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_USER_NOTE_PATIENT_FAILURE,
    });
  }
}

export function* getUserNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.getUserNoteComment(payload);
    yield put({
      type: ActionTypes.GET_USER_NOTE_COMMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_USER_NOTE_COMMENT_FAILURE,
    });
  }
}

export function* addUserNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.addUserNoteComment(payload);
    yield put({
      type: ActionTypes.ADD_USER_NOTE_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_USER_NOTE_COMMENT_FAILURE,
    });
  }
}

export function* getUserNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.getUserNoteEmoji(payload);
    yield put({
      type: ActionTypes.GET_USER_NOTE_EMOJI_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_USER_NOTE_EMOJI_FAILURE,
    });
  }
}

export function* addUserNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.addUserNoteEmoji(payload);
    yield put({
      type: ActionTypes.ADD_USER_NOTE_EMOJI_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_USER_NOTE_EMOJI_FAILURE,
    });
  }
}

export function* getBreathalyzerSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.getBreathalyzerSchedule(payload);
    yield put({
      type: ActionTypes.GET_BREATHALYZER_SCHEDULE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BREATHALYZER_SCHEDULE_FAILURE,
    });
  }
}

export function* listBreathalyzerSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.listBreathalyzerSchedule(payload);
    yield put({
      type: ActionTypes.LIST_BREATHALYZER_SCHEDULE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_BREATHALYZER_SCHEDULE_FAILURE,
    });
  }
}

export function* upsertBreathalyzerSchedule(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertBreathalyzerSchedule(payload);
    yield put({
      type: ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_FAILURE,
    });
  }
}

export function* getMediaVideoUrl(action) {
  try {
    const { payload } = action;
    const response = yield API.getMediaVideoUrl(payload);
    yield put({
      type: ActionTypes.GET_MEDIA_VIDEO_URL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_MEDIA_VIDEO_URL_FAILURE,
    });
  }
}

export function* breathalyzerPushATest(action) {
  try {
    const { payload } = action;
    const response = yield API.breathalyzerPushATest(payload);
    yield put({
      type: ActionTypes.BREATHALYZER_PUSH_A_TEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BREATHALYZER_PUSH_A_TEST_FAILURE,
    });
  }
}

export function* breathalyzerEnable(action) {
  try {
    const { payload } = action;
    const response = yield API.breathalyzerEnable(payload);
    yield put({
      type: ActionTypes.BREATHALYZER_ENABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BREATHALYZER_ENABLE_FAILURE,
    });
  }
}

export function* getBreathalyzerTestResult(action) {
  try {
    const { payload } = action;
    const response = yield API.getBreathalyzerTestResult(payload);
    yield put({
      type: ActionTypes.GET_BREATHALYZER_TEST_RESULT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BREATHALYZER_TEST_RESULT_FAILURE,
    });
  }
}

export function* getBreathalyzerTestUpcoming(action) {
  try {
    const { payload } = action;
    const response = yield API.getBreathalyzerTestUpcoming(payload);
    yield put({
      type: ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_FAILURE,
    });
  }
}

export function* breathalyzerTestUpcomingToggle(action) {
  try {
    const { payload } = action;
    const response = yield API.breathalyzerTestUpcomingToggle(payload);
    yield put({
      type: ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_FAILURE,
    });
  }
}

export function* getAppUserSubscription(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppUserSubscription(payload);
    yield put({
      type: ActionTypes.GET_APP_USER_SUBSCRIPTION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_USER_SUBSCRIPTION_FAILURE,
    });
  }
}

export function* getServiceOptions(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceOptions(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_OPTION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_OPTION_FAILURE,
    });
  }
}

export function* getServiceAdvisorList(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceAdvisorList(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_ADVISOR_LIST_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_ADVISOR_LIST_FAILURE,
    });
  }
}

export function* setServiceAdvisor(action) {
  try {
    const { payload } = action;
    const response = yield API.setServiceAdvisor(payload);
    yield put({
      type: ActionTypes.SET_SERVICE_ADVISOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SET_SERVICE_ADVISOR_FAILURE,
    });
  }
}

export function* updateCancelService(action) {
  try {
    const { payload } = action;
    const response = yield API.updateCancelService(payload);
    yield put({
      type: ActionTypes.UPDATE_CANCEL_SERVICE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_CANCEL_SERVICE_FAILURE,
    });
  }
}

export function* setMembership(action) {
  try {
    const { payload } = action;
    const response = yield API.setMembership(payload);
    yield put({
      type: ActionTypes.SET_MEMBERSHIP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SET_MEMBERSHIP_FAILURE,
    });
  }
}

export function* cancelMembership(action) {
  try {
    const { payload } = action;
    const response = yield API.cancelMembership(payload);
    yield put({
      type: ActionTypes.CANCEL_MEMBERSHIP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CANCEL_MEMBERSHIP_FAILURE,
    });
  }
}

export function* setServiceSubscription(action) {
  try {
    const { payload } = action;
    const response = yield API.setServiceSubscription(payload);
    yield put({
      type: ActionTypes.SET_SERVICE_SUBSCRIPTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SET_SERVICE_SUBSCRIPTION_FAILURE,
    });
  }
}

export function* updateUserMembership(action) {
  try {
    const { payload } = action;
    const response = yield API.updateUserMembership(payload);
    yield put({
      type: ActionTypes.UPDATE_USER_MEMBERSHIP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_USER_MEMBERSHIP_FAILURE,
    });
  }
}

export function* appUserDemographicUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.appUserDemographicUpsert(payload);
    yield put({
      type: ActionTypes.APP_USER_DEMOGRAPHIC_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_USER_DEMOGRAPHIC_UPSERT_FAILURE,
    });
  }
}

export function* appUserDemographicGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appUserDemographicGet(payload);
    yield put({
      type: ActionTypes.APP_USER_DEMOGRAPHIC_GET_SUCCESS,
      payload: JSON.parse(response.data.data).AppUser[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_USER_DEMOGRAPHIC_GET_FAILURE,
    });
  }
}

export function* removeSupportGroup(action) {
  try {
    const { payload } = action;
    const response = yield API.removeSupportGroup(payload);
    yield put({
      type: ActionTypes.REMOVE_SUPPORT_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.REMOVE_SUPPORT_GROUP_FAILURE,
    });
  }
}

export function* concernedPersonList(action) {
  try {
    const { payload } = action;
    const response = yield API.concernedPersonList(payload);
    yield put({
      type: ActionTypes.CONCERNED_PERSON_LIST_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CONCERNED_PERSON_LIST_FAILURE,
    });
  }
}

export function* concernedPersonDasboardGraphGet(action) {
  try {
    const { payload } = action;
    const response = yield API.concernedPersonDasboardGraphGet(payload);
    yield put({
      type: ActionTypes.CONCERNED_PERSON_DASBOARD_GRAPH_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CONCERNED_PERSON_DASBOARD_GRAPH_GET_FAILURE,
    });
  }
}

export function* appUserLiveLocationGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appUserLiveLocationGet(payload);
    yield put({
      type: ActionTypes.APP_USER_LIVE_LOCATION_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_USER_LIVE_LOCATION_GET_FAILURE,
    });
  }
}

export function* dashboardMobileUserGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.dashboardMobileUserGetAll(payload);
    yield put({
      type: ActionTypes.DASHBOARD_MOBILE_USER_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_MOBILE_USER_GET_ALL_FAILURE,
    });
  }
}

export function* entMobileUserDashboardGet(action) {
  try {
    const { payload } = action;
    const response = yield API.entMobileUserDashboardGet(payload);
    yield put({
      type: ActionTypes.ENT_MOBILE_USER_DASHBOARD_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ENT_MOBILE_USER_DASHBOARD_GET_FAILURE,
    });
  }
}

export function* recoveryCapitalQuadrantGraphGet(action) {
  try {
    const { payload } = action;
    const response = yield API.recoveryCapitalQuadrantGraphGet(payload);
    yield put({
      type: ActionTypes.RECOVERY_CAPITAL_QUADRANT_GRAPH_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RECOVERY_CAPITAL_QUADRANT_GRAPH_GET_FAILURE,
    });
  }
}

export function* recoveryCapitalTrendGraphGet(action) {
  try {
    const { payload } = action;
    const response = yield API.recoveryCapitalTrendGraphGet(payload);
    yield put({
      type: ActionTypes.RECOVERY_CAPITAL_TREND_GRAPH_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RECOVERY_CAPITAL_TREND_GRAPH_GET_FAILURE,
    });
  }
}

export function* broadCastUserListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.broadCastUserListGet(payload);
    yield put({
      type: ActionTypes.BROADCAST_USER_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data || '{}').AppUserSearch,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.BROADCAST_USER_LIST_GET_FAILURE,
    });
  }
}

export function* appUserJournalAugmentGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appUserJournalAugmentGet(payload);
    yield put({
      type: ActionTypes.APP_USER_JOURNAL_AUGMENT_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_USER_JOURNAL_AUGMENT_GET_FAILURE,
    });
  }
}

export function* appUserJournalAugmentation(action) {
  try {
    const { payload } = action;
    const response = yield API.appUserJournalAugmentation(payload);
    yield put({
      type: ActionTypes.APP_USER_JOURNAL_AUGMENTATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_USER_JOURNAL_AUGMENTATION_FAILURE,
    });
  }
}

export function* dashboardRadarGraphGet(action) {
  try {
    const response = yield API.dashboardRadarGraphGet(action.payload);
    yield put({
      type: ActionTypes.DASHBOARD_RADAR_GRAPH_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_RADAR_GRAPH_GET_FAILURE,
    });
  }
}

export function* appUserJournalHistoryGet(action) {
  try {
    const response = yield API.appUserJournalHistoryGet(action.payload);
    yield put({
      type: ActionTypes.APP_USER_JOURNAL_HISTORY_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_USER_JOURNAL_HISTORY_GET_FAILURE,
    });
  }
}

export function* feedbackFormAnalyticsGet(action) {
  try {
    const response = yield API.feedbackFormAnalyticsGet(action.payload);
    yield put({
      type: ActionTypes.FEEDBACK_FORM_ANALYTICS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.FEEDBACK_FORM_ANALYTICS_GET_FAILURE,
    });
  }
}

export function* feedbackFormGet(action) {
  try {
    const response = yield API.feedbackFormGet(action.payload);
    yield put({
      type: ActionTypes.FEEDBACK_FORM_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.FEEDBACK_FORM_GET_FAILURE,
    });
  }
}

export function* feedbackFormDelete(action) {
  try {
    const response = yield API.feedbackFormDelete(action.payload);
    yield put({
      type: ActionTypes.FEEDBACK_FORM_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.FEEDBACK_FORM_DELETE_FAILURE,
    });
  }
}

export function* feedbackFormListGet(action) {
  try {
    const response = yield API.feedbackFormListGet(action.payload);
    yield put({
      type: ActionTypes.FEEDBACK_FORM_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.FEEDBACK_FORM_LIST_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_APP_SUBSCRIBERS_REQUEST, appSubscribers),
    takeLatest(ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_REQUEST, getAppSubscriberProfileDashboard),
    takeLatest(ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_REQUEST, getAppSubscribersProfile),
    takeLatest(ActionTypes.GET_APP_SUBSCRIBERS_CHAT_REQUEST, getAppSubscribersChat),
    takeLatest(ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_REQUEST, getAppSubscriberChatCoach),
    takeLatest(
      ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_REQUEST,
      getAppSubscriberProfileActivities,
    ),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_REQUEST, addAppSubscribersSurvey),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_REQUEST, addAppSubscriberNote),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_ADD_TASK_REQUEST, addAppSubscriberTask),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_REQUEST, deleteAppSubscriberTask),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_REQUEST, deleteAppSubscribersSurvey),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_REQUEST, appSubscribersUserSearch),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_REQUEST, appSubscriberCompleteTask),

    takeLatest(ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_REQUEST, addAppSubscriberCourse),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_REQUEST, appSubscribersupdateCase),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_REQUEST, deleteAppSubscriberCourse),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_REQUEST, appSubscriberCompleteCourse),
    takeLatest(ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_REQUEST, appSubscriberMapHistory),
    takeLatest(ActionTypes.APP_MARK_SYSTEM_OBSERVATION_REQUEST, appMarkSystemObservation),
    takeLatest(ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_REQUEST, loadAppSubSurveyDashboard),
    takeLatest(ActionTypes.APP_SUB_SURVEY_ANS_GET_REQUEST, appSubSurveyAnsGet),
    takeLatest(ActionTypes.LOAD_CASE_PROFILE_REQUEST, loadCaseProfile),
    takeLatest(ActionTypes.LOAD_LOCATION_AUTH_HISTORY_REQUEST, loadLocationAuthHistory),
    takeLatest(ActionTypes.GET_USER_NOTE_PATIENT_REQUEST, getUserNotePatient),
    takeLatest(ActionTypes.GET_USER_NOTE_COMMENT_REQUEST, getUserNoteComment),
    takeLatest(ActionTypes.ADD_USER_NOTE_COMMENT_REQUEST, addUserNoteComment),
    takeLatest(ActionTypes.GET_USER_NOTE_EMOJI_REQUEST, getUserNoteEmoji),
    takeLatest(ActionTypes.ADD_USER_NOTE_EMOJI_REQUEST, addUserNoteEmoji),
    takeLatest(ActionTypes.GET_BREATHALYZER_SCHEDULE_REQUEST, getBreathalyzerSchedule),
    takeLatest(ActionTypes.LIST_BREATHALYZER_SCHEDULE_REQUEST, listBreathalyzerSchedule),
    takeLatest(ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_REQUEST, upsertBreathalyzerSchedule),
    takeLatest(ActionTypes.GET_MEDIA_VIDEO_URL_REQUEST, getMediaVideoUrl),
    takeLatest(ActionTypes.BREATHALYZER_PUSH_A_TEST_REQUEST, breathalyzerPushATest),
    takeLatest(ActionTypes.BREATHALYZER_ENABLE_REQUEST, breathalyzerEnable),
    takeLatest(ActionTypes.GET_BREATHALYZER_TEST_RESULT_REQUEST, getBreathalyzerTestResult),
    takeLatest(ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_REQUEST, getBreathalyzerTestUpcoming),
    takeLatest(
      ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_REQUEST,
      breathalyzerTestUpcomingToggle,
    ),
    takeLatest(ActionTypes.GET_APP_USER_SUBSCRIPTION_REQUEST, getAppUserSubscription),
    takeLatest(ActionTypes.GET_SERVICE_OPTION_REQUEST, getServiceOptions),
    takeLatest(ActionTypes.GET_SERVICE_ADVISOR_LIST_REQUEST, getServiceAdvisorList),
    takeLatest(ActionTypes.SET_SERVICE_ADVISOR_REQUEST, setServiceAdvisor),
    takeLatest(ActionTypes.UPDATE_CANCEL_SERVICE_REQUEST, updateCancelService),
    takeLatest(ActionTypes.SET_MEMBERSHIP_REQUEST, setMembership),
    takeLatest(ActionTypes.CANCEL_MEMBERSHIP_REQUEST, cancelMembership),
    takeLatest(ActionTypes.SET_SERVICE_SUBSCRIPTION_REQUEST, setServiceSubscription),
    takeLatest(ActionTypes.UPDATE_USER_MEMBERSHIP_REQUEST, updateUserMembership),
    takeLatest(ActionTypes.APP_USER_DEMOGRAPHIC_UPSERT_REQUEST, appUserDemographicUpsert),
    takeLatest(ActionTypes.APP_USER_DEMOGRAPHIC_GET_REQUEST, appUserDemographicGet),
    takeLatest(ActionTypes.REMOVE_SUPPORT_GROUP_REQUEST, removeSupportGroup),
    takeLatest(ActionTypes.CONCERNED_PERSON_LIST_REQUEST, concernedPersonList),
    takeLatest(
      ActionTypes.CONCERNED_PERSON_DASBOARD_GRAPH_GET_REQUEST,
      concernedPersonDasboardGraphGet,
    ),
    takeLatest(ActionTypes.APP_USER_LIVE_LOCATION_GET_REQUEST, appUserLiveLocationGet),
    takeLatest(ActionTypes.DASHBOARD_MOBILE_USER_GET_ALL_REQUEST, dashboardMobileUserGetAll),
    takeLatest(ActionTypes.ENT_MOBILE_USER_DASHBOARD_GET_REQUEST, entMobileUserDashboardGet),
    takeLatest(
      ActionTypes.RECOVERY_CAPITAL_QUADRANT_GRAPH_GET_REQUEST,
      recoveryCapitalQuadrantGraphGet,
    ),
    takeLatest(ActionTypes.RECOVERY_CAPITAL_TREND_GRAPH_GET_REQUEST, recoveryCapitalTrendGraphGet),
    takeLatest(ActionTypes.BROADCAST_USER_LIST_GET_REQUEST, broadCastUserListGet),
    takeLatest(ActionTypes.APP_USER_JOURNAL_AUGMENT_GET_REQUEST, appUserJournalAugmentGet),
    takeLatest(ActionTypes.APP_USER_JOURNAL_AUGMENTATION_REQUEST, appUserJournalAugmentation),
    takeLatest(ActionTypes.DASHBOARD_RADAR_GRAPH_GET_REQUEST, dashboardRadarGraphGet),
    takeLatest(ActionTypes.APP_USER_JOURNAL_HISTORY_GET_REQUEST, appUserJournalHistoryGet),
    takeLatest(ActionTypes.FEEDBACK_FORM_ANALYTICS_GET_REQUEST, feedbackFormAnalyticsGet),
    takeLatest(ActionTypes.FEEDBACK_FORM_GET_REQUEST, feedbackFormGet),
    takeLatest(ActionTypes.FEEDBACK_FORM_DELETE_REQUEST, feedbackFormDelete),
    takeLatest(ActionTypes.FEEDBACK_FORM_LIST_GET_REQUEST, feedbackFormListGet),
  ]);
}
