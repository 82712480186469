import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* goalLibraryUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.goalLibraryUpsert(payload);
    yield put({
      type: ActionTypes.GOAL_LIBRARY_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GOAL_LIBRARY_UPSERT_FAILURE,
    });
  }
}
export function* goalLibraryArchive(action) {
  try {
    const { payload } = action;
    const response = yield API.goalLibraryArchive(payload);
    yield put({
      type: ActionTypes.GOAL_LIBRARY_ARCHIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GOAL_LIBRARY_ARCHIVE_FAILURE,
    });
  }
}
export function* goalLibraryGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.goalLibraryGetAll(payload);
    yield put({
      type: ActionTypes.GOAL_LIBRARY_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GOAL_LIBRARY_GET_ALL_FAILURE,
    });
  }
}
export function* goalLibraryGet(action) {
  try {
    const { payload } = action;
    const response = yield API.goalLibraryGet(payload);
    yield put({
      type: ActionTypes.GOAL_LIBRARY_GET_SUCCESS,
      payload: {
        ...JSON.parse(response.data.data),
        goalIndex: payload.goalIndex,
        bundleIndex:payload.bundleIndex
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GOAL_LIBRARY_GET_FAILURE,
    });
  }
}
export function* smartContractLedgerGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractLedgerGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_LEDGER_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_LEDGER_GET_FAILURE,
    });
  }
}
export function* smartContractUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractUpdate(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_UPDATE_SUCCESS,
      payload: {
        ...response.data,
        markAsComplete: payload.markAsComplete,
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_UPDATE_FAILURE,
    });
  }
}

export function* smartContractPatientListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractPatientListGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_PATIENT_LIST_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_PATIENT_LIST_GET_FAILURE,
    });
  }
}

export function* smartContractListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractListGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_LIST_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_LIST_GET_FAILURE,
    });
  }
}

export function* termsConditionListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.termsConditionListGet(payload);
    yield put({
      type: ActionTypes.TERMS_CONDITION_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TERMS_CONDITION_LIST_GET_FAILURE,
    });
  }
}

export function* termsConditionUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.termsConditionUpsert(payload);
    yield put({
      type: ActionTypes.TERMS_CONDITION_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TERMS_CONDITION_UPSERT_FAILURE,
    });
  }
}

export function* termsConditionGetAllInfoJson(action) {
  try {
    const { payload } = action;
    const response = yield API.termsConditionGetAllInfoJson(payload);
    yield put({
      type: ActionTypes.TERMS_CONDITION_GET_ALL_INFO_JSON_SUCCESS,
      payload: { ...JSON.parse(response.data.data), termIndex: payload.termIndex },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TERMS_CONDITION_GET_ALL_INFO_JSON_FAILURE,
    });
  }
}

export function* clientInsuranceComboGet(action) {
  try {
    const { payload } = action;
    const response = yield API.clientInsuranceComboGet(payload);
    yield put({
      type: ActionTypes.CLIENT_INSURANCE_COMBO_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_INSURANCE_COMBO_GET_FAILURE,
    });
  }
}

export function* termsConditionDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.termsConditionDelete(payload);
    yield put({
      type: ActionTypes.TERMS_CONDITION_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TERMS_CONDITION_DELETE_FAILURE,
    });
  }
}

export function* smartContractPayersListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractPayersListGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_PAYERS_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_PAYERS_LIST_GET_FAILURE,
    });
  }
}

export function* smartContractTermsConditionUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractTermsConditionUpsert(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_TERMS_CONDITION_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_TERMS_CONDITION_UPSERT_FAILURE,
    });
  }
}

export function* careTeamAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.careTeamAssign(payload);
    yield put({
      type: ActionTypes.CARE_TEAM_ASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CARE_TEAM_ASSIGN_FAILURE,
    });
  }
}

export function* serviceCodeCareTeamGet(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeCareTeamGet(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_CARE_TEAM_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_CARE_TEAM_GET_FAILURE,
    });
  }
}

export function* smartContractTabGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractTabGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_TAB_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_TAB_GET_FAILURE,
    });
  }
}

export function* smartContractDistributionGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractDistributionGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_DISTRIBUTION_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_DISTRIBUTION_GET_FAILURE,
    });
  }
}

export function* smartContractBundleSubmitToPayer(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractBundleSubmitToPayer(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_BUNDLE_SUBMIT_TO_PAYER_SUCCESS,
      payload: {
        ...response.data,
        ClientId: payload.ClientId,
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_BUNDLE_SUBMIT_TO_PAYER_FAILURE,
    });
  }
}

export function* smartContractPayerBundleGetToSubmit(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractPayerBundleGetToSubmit(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_PAYER_BUNDLE_GET_TO_SUBMIT_SUCCESS,
      payload: JSON.parse(response.data.result),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_PAYER_BUNDLE_GET_TO_SUBMIT_FAILURE,
    });
  }
}

export function* vbcLedgerRemarkUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.vbcLedgerRemarkUpsert(payload);
    yield put({
      type: ActionTypes.VBC_LEDGER_REMARK_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.VBC_LEDGER_REMARK_UPSERT_FAILURE,
    });
  }
}

export function* distributionPaymentUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.distributionPaymentUpsert(payload);
    yield put({
      type: ActionTypes.DISTRIBUTION_PAYMENT_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DISTRIBUTION_PAYMENT_UPSERT_FAILURE,
    });
  }
}

export function* smartContractDocumentRequestGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractDocumentRequestGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_DOCUMENT_REQUEST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_DOCUMENT_REQUEST_GET_FAILURE,
    });
  }
}

export function* taskComboGet(action) {
  try {
    const { payload } = action;
    const response = yield API.taskComboGet(payload);
    yield put({
      type: ActionTypes.TASK_COMBO_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TASK_COMBO_GET_FAILURE,
    });
  }
}

export function* recoveryCategoryComboGet(action) {
  try {
    const { payload } = action;
    const response = yield API.recoveryCategoryComboGet(payload);
    yield put({
      type: ActionTypes.RECOVERY_CATEGORY_COMBO_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RECOVERY_CATEGORY_COMBO_GET_FAILURE,
    });
  }
}

export function* smartContractClone(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractClone(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_CLONE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_CLONE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GOAL_LIBRARY_UPSERT_REQUEST, goalLibraryUpsert),
    takeLatest(ActionTypes.GOAL_LIBRARY_ARCHIVE_REQUEST, goalLibraryArchive),
    takeLatest(ActionTypes.GOAL_LIBRARY_GET_ALL_REQUEST, goalLibraryGetAll),
    takeLatest(ActionTypes.GOAL_LIBRARY_GET_REQUEST, goalLibraryGet),
    takeLatest(ActionTypes.SMART_CONTRACT_LEDGER_GET_REQUEST, smartContractLedgerGet),
    takeLatest(ActionTypes.SMART_CONTRACT_UPDATE_REQUEST, smartContractUpdate),
    takeLatest(ActionTypes.SMART_CONTRACT_PATIENT_LIST_GET_REQUEST, smartContractPatientListGet),
    takeLatest(ActionTypes.SMART_CONTRACT_LIST_GET_REQUEST, smartContractListGet),
    takeLatest(ActionTypes.TERMS_CONDITION_LIST_GET_REQUEST, termsConditionListGet),
    takeLatest(ActionTypes.TERMS_CONDITION_UPSERT_REQUEST, termsConditionUpsert),
    takeLatest(ActionTypes.TERMS_CONDITION_GET_ALL_INFO_JSON_REQUEST, termsConditionGetAllInfoJson),
    takeLatest(ActionTypes.CLIENT_INSURANCE_COMBO_GET_REQUEST, clientInsuranceComboGet),
    takeLatest(ActionTypes.TERMS_CONDITION_DELETE_REQUEST, termsConditionDelete),
    takeLatest(ActionTypes.SMART_CONTRACT_PAYERS_LIST_GET_REQUEST, smartContractPayersListGet),
    takeLatest(
      ActionTypes.SMART_CONTRACT_TERMS_CONDITION_UPSERT_REQUEST,
      smartContractTermsConditionUpsert,
    ),
    takeLatest(ActionTypes.CARE_TEAM_ASSIGN_REQUEST, careTeamAssign),
    takeLatest(ActionTypes.SERVICE_CODE_CARE_TEAM_GET_REQUEST, serviceCodeCareTeamGet),
    takeLatest(ActionTypes.SMART_CONTRACT_TAB_GET_REQUEST, smartContractTabGet),
    takeLatest(ActionTypes.SMART_CONTRACT_DISTRIBUTION_GET_REQUEST, smartContractDistributionGet),
    takeLatest(
      ActionTypes.SMART_CONTRACT_BUNDLE_SUBMIT_TO_PAYER_REQUEST,
      smartContractBundleSubmitToPayer,
    ),
    takeLatest(
      ActionTypes.SMART_CONTRACT_PAYER_BUNDLE_GET_TO_SUBMIT_REQUEST,
      smartContractPayerBundleGetToSubmit,
    ),
    takeLatest(ActionTypes.VBC_LEDGER_REMARK_UPSERT_REQUEST, vbcLedgerRemarkUpsert),
    takeLatest(ActionTypes.DISTRIBUTION_PAYMENT_UPSERT_REQUEST, distributionPaymentUpsert),
    takeLatest(
      ActionTypes.SMART_CONTRACT_DOCUMENT_REQUEST_GET_REQUEST,
      smartContractDocumentRequestGet,
    ),
      takeLatest(
      ActionTypes.TASK_COMBO_GET_REQUEST,
      taskComboGet ,
    ),
    takeLatest(
      ActionTypes.RECOVERY_CATEGORY_COMBO_GET_REQUEST,
      recoveryCategoryComboGet ,
    ),
    takeLatest(
      ActionTypes.SMART_CONTRACT_CLONE_REQUEST,
      smartContractClone ,
    ),     
  ]);
}
