import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import CustomModal from '../../../../core/modal';
import DrawerContent from './drawer/drawerContent';
import { listGroupAppointmentNote } from '../../../../store/actions';
import PersistTableSort from '../../../../component/persistTableSort';
import GridSearch from '../../../../Components/gridSearch';

class NotesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupDrawerVisible: false,
      currentRowData: '',
      isModelOpen: false,
      columns: [
        {
          title: 'Group Name',
          dataIndex: 'GroupName',
          key: 'GroupName',
          sorter: (a, b) => isComparer(a.GroupName, b.GroupName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Session Date',
          dataIndex: 'SessionDate',
          key: 'SessionDate',
          sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Start Time / End Time',
          dataIndex: 'SessionTime',
          key: 'SessionTime',
          sorter: (a, b) => isComparer(a.SessionTime, b.SessionTime),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Program',
          dataIndex: 'ProgramDescription',
          key: 'ProgramDescription',
          sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
          sorter: (a, b) => isComparer(a.Status, b.Status),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => {
            if (text == 'UnSigned') {
              return <span style={{ color: '#E87A7A' }}>{text}</span>;
            } else {
              return <span>{text}</span>;
            }
          },
        },
        {
          title: 'Addendums',
          dataIndex: 'AddendumCount',
          key: 'AddendumCount',
          sorter: (a, b) => isComparer(a.Addendum, b.Addendum),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Action',
          render: (text, record, index) => {
            if (!props.isReadOnlyUserSession) {
              return (
                <>
                  {record.IsAppointmentAsKept ? (
                    <div class="tools-grid">
                      <>
                        <span
                          className="start-btn"
                          onClick={() => {
                            this.showDrawer(record);
                          }}
                        >
                          Manage Notes{' '}
                          <span style={{ color: '#E87A7A' }}>{record.NoteSignHoursLeft}</span>
                        </span>
                      </>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              );
            } else {
              return null;
            }
          },
        },
      ],
    };
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  showDrawer = record => {
    this.setState({
      groupDrawerVisible: !this.props.isReadOnlyUserSession,
      currentRowData: record,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      groupDrawerVisible: false,
    });
    // this.props.history.push(
    //   `/cw/group-management/group-details?tab=group-information&gId=${this.getGroupId()}`,
    // );
  };

  getGroupId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      groupId = parseInt(groupId);
    } else {
      groupId = null;
    }
    return groupId;
  };

  componentDidMount() {
    this.props.dispatch(
      listGroupAppointmentNote({
        json: JSON.stringify({
          GroupAppointmentNote: [
            {
              GroupId: this.getGroupId(),
            },
          ],
        }),
      }),
    );
    let param = new URLSearchParams(this.props.history.location.search);
    let groupId = parseInt(param.get('gId'));
    let groupAppointmentId = param.get('groupAppointmentId');
    // let isGroup = groupAppointmentId ? true : false;
    if (groupId && groupAppointmentId) {
      this.showDrawer({ GroupAppointmentId: groupAppointmentId });
    }
  }
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listGroupAppointmentNote({
        json: JSON.stringify({
          ClientGroupServiceLine: [
            {
              Search: value,
              GroupId: this.getGroupId(),
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { columns, groupDrawerVisible, currentRowData, isModelOpen } = this.state;

    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page mb-5">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 mb-5">
                <div class="border-0">
                  <div className="mb-3">
                    <div className="row mt-5 d-flex align-items-center">
                      <div class="col-6">
                        {' '}
                        <h4 className="">NOTES LIST</h4>
                      </div>
                      <div class="col-6 d-flex align-items-center">
                        <GridSearch onSearchChange={this.onSearchChange} />
                      </div>
                    </div>
                  </div>
                  <div class="row pt-3 pb-5 rcm-subtab-table-block">
                    <div class="col-12">
                      <PersistTableSort
                        name="group-notes-list"
                        columns={columns}
                        dataSource={this.props.appointmentListData}
                        size={'middle'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {groupDrawerVisible && (
            <DrawerContent
              groupDrawerVisible={groupDrawerVisible}
              onCloseDrawer={this.onCloseDrawer}
              currentRowData={currentRowData}
              toggle={this.toggle}
            />
          )}
          {isModelOpen && (
            <CustomModal
              isOpen={this.state.isModelOpen}
              header={this.state.header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ list_group_appointment_note }) => {
  const { data: listGroupAppointmentNote } = list_group_appointment_note;

  let appointmentListData = [];

  if (listGroupAppointmentNote) {
    appointmentListData = listGroupAppointmentNote.GroupAppointmentNote;
  }

  return {
    appointmentListData,
  };
};

export default connect(mapStateToProps)(withRouter(NotesList));
