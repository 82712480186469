import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export default function TaskCollapse(props) {
  const { task, keyIndex } = props;
  const [defaultActivePanels, setDefaultActivePanels] = useState([]);

  useEffect(() => {
    if (task && task.length > 0) {
      let defaultOpenPanels =
        task && task.length > 0 && task.map((item, index) => `${item.Task}-${index + 1}`);
      setDefaultActivePanels(defaultOpenPanels);
    }
  }, [task]);
  return (
    <div>
      {defaultActivePanels && defaultActivePanels.length > 0 && (
        <Collapse defaultActiveKey={defaultActivePanels} expandIconPosition={'end'}>
          {/* <Panel header="This is panel header 1" key="1">
          <div>{'text'}</div>
        </Panel> */}
          {task &&
            task.length > 0 &&
            task.map((item, index) => {
              const { Task } = item;
              return (
                <Panel
                  // header={`Task ${index + 1}`}
                  header={
                    <>
                      <div className="row">
                        <div className="col-md-3 panel-header-text">{`Task ${index + 1}`}</div>
                      </div>
                    </>
                  }
                  key={`${Task}-${index + 1}`}
                  className="task-panel-border mb-3"
                >
                  <p className="mb-0 my-3" style={{ color: '#182838', fontSize: '16px' }}>
                    {Task}
                  </p>
                </Panel>
              );
            })}
        </Collapse>
      )}
    </div>
  );
}
