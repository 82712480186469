import React from 'react';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  CheckboxGroup,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  upsertServiceLine,
  listServiceLine,
  getServiceCode,
  getServiceLine,
  medicateCombos,
} from '../../../../store/actions';
import { ProcessCombos } from '../../../../store/comboConfig';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import moment from 'moment';
import AntdModal from '../../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { timeConvert } from '../../../../util/time';
import ReduxServiceLineModal from '../../CustomModals/serviceLineModal';

import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import { Table, Modal, Tooltip } from 'antd';
import PersistTableSort from '../../../../component/persistTableSort';

class ServiceLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineInfo: {},
      createAndEditGroupModal: false,
      ServiceDetails: [],
    };
  }
  // need to work on it always return zero for me

  componentDidMount() {
    window.scrollTo(150, 150);
    var result = ProcessCombos('RCM_ServiceCode,Supervisor,UnitOfMeasurement');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }

    this.props.dispatch(
      listServiceLine({
        json: JSON.stringify({
          ServiceLine: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({
    isInserted,
    isRemoved,
    ServiceList,
    ServiceInfo,
    ServiceCodeInfo,
    SupervisorIdValue,
    DiagnosisCodeValue,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditGroupModal: false });
      this.toggle('Service Line Saved Successfully');
      this.props.dispatch(reset('ClientserviceLineForm'));
      this.setState({
        ServiceLineInfo: {},
        ServiceInfoInit: {},
      });
    }
    if (ServiceList && JSON.stringify(ServiceList) != JSON.stringify(this.props.ServiceList)) {
      this.setState({
        ServiceList: ServiceList,
      });
    }
    if (ServiceInfo && JSON.stringify(ServiceInfo) != JSON.stringify(this.props.ServiceInfo)) {
      this.setState({
        ServiceLineInfo: ServiceInfo,
        ServiceDetails: ServiceInfo.ServiceDetails && JSON.parse(ServiceInfo.ServiceDetails),
      });
    }
    if (SupervisorIdValue && SupervisorIdValue != this.props.SupervisorIdValue) {
      this.state.ServiceLineInfo.SupervisorId = SupervisorIdValue;
    }
    if (DiagnosisCodeValue && DiagnosisCodeValue != this.props.DiagnosisCodeValue) {
      this.state.ServiceLineInfo.DiagnosisCode = DiagnosisCodeValue;
    }
    if (
      ServiceCodeInfo &&
      JSON.stringify(ServiceCodeInfo) != JSON.stringify(this.props.ServiceCodeInfo)
    ) {
      let code = this.props.existingServiceCodesRows;
      if (code && code.length > ServiceCodeInfo.ReactIndex) {
        code[ServiceCodeInfo.ReactIndex].ServiceCodeId = ServiceCodeInfo.ServiceCodeId;
        code[ServiceCodeInfo.ReactIndex].UnitOfMeasurementId = ServiceCodeInfo.UnitOfMeasurementId;
        code[ServiceCodeInfo.ReactIndex].UnitsId = ServiceCodeInfo.UpdatedBy;
        code[ServiceCodeInfo.ReactIndex].ServiceCodeDescription =
          ServiceCodeInfo.ServiceCodeDescription;
      }
      this.setState({
        ServiceDetails: code,
      });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit = values => {
    const { DiagnosisCode, ServiceCodes, SupervisorId } = values;
    // let patientId = null;
    // let paramClientId = this.props.clientId;
    // if (paramClientId && paramClientId > 0) {
    //   patientId = paramClientId;
    // }
    let ServiceCodeId = [];
    let unitsId = [];
    // let DiagnosisListId = [];
    ServiceCodes.map(item => {
      ServiceCodeId.push(item.ServiceCodeId);
      unitsId.push(item.UnitsId);
    });
    // DiagnosisCode.map(item => {
    //   DiagnosisListId.push(item.DiagnosisListId);
    // });
    let ServiceLineData = {
      json: JSON.stringify({
        ServiceLine: [
          {
            AppointmentId: this.state.ServiceLineInfo.AppointmentId,
            ServiceLineId: this.state.ServiceLineInfo.ServiceLineId,
            ClientId: this.state.ServiceLineInfo.ClientId,
            GroupAppointmentId: this.state.ServiceLineInfo.GroupAppointmentId,
            ServiceCodeId: ServiceCodeId.join(),
            UnitsId: unitsId.join(),
            DiagnosisListId: DiagnosisCode && DiagnosisCode.join(),
            SupervisorId: SupervisorId,
          },
        ],
      }),
    };
    this.props.dispatch(upsertServiceLine(ServiceLineData));
  };
  deleteMoreCode = index => {
    let ServiceDetails = this.state.ServiceDetails;
    ServiceDetails.splice(index, 1);
    this.setState({
      ServiceDetails,
    });
  };
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listServiceLine({
        json: JSON.stringify({
          ServiceLine: [
            {
              Search: value,
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { isReadOnlyUser } = this.props;
    const tempColumns = [
      {
        title: 'Service Code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Appointment Date',
        dataIndex: 'SessionDate',
        key: 'SessionDate',
        sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Type',
        dataIndex: 'AppointmentType',
        key: 'AppointmentType',
        sorter: (a, b) => isComparer(a.AppointmentType, b.AppointmentType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Time',
        dataIndex: 'StartTime',
        key: 'StartTime',
        sorter: (a, b) => isNumericComparer(a.StartTimeSortValue, b.StartTimeSortValue),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Time',
        dataIndex: 'EndTime',
        key: 'EndTime',
        sorter: (a, b) => isNumericComparer(a.EndTimeSortValue, b.EndTimeSortValue),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Supervisor',
        dataIndex: 'SupervisorName',
        key: 'SupervisorName',
        sorter: (a, b) => isComparer(a.SupervisorName, b.SupervisorName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Program',
        dataIndex: 'ProgramDescription',
        key: 'ProgramDescription',
        sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Duration',
        dataIndex: 'DurationInMin',
        key: 'DurationInMin',
        sorter: (a, b) => isNumericComparer(a.DurationInMin, b.DurationInMin),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span>
                {timeConvert(text)}
                {/* {moment.utc(moment.duration(text * 60, 'seconds').asMilliseconds()).format('HH:mm')} */}
              </span>{' '}
              {/* <span>Hours</span> */}
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'x',
        width: 80,
        render: (text, record, index) => {
          if (!isReadOnlyUser) {
            return (
              <div class="tools-grid">
                <div className="mr-4">
                  <span className="start-btn">
                    <Tooltip placement="top" title="Edit">
                      <img
                        src="images/shape.svg"
                        width="14px"
                        style={{ cursor: 'pointer' }}
                        onClick={event => {
                          this.setState({
                            createAndEditGroupModal: true,
                            AppointmentId: record.AppointmentId,
                            ServiceLineId: record.ServiceLineId,
                            ClientId: record.ClientId,
                          });
                          // this.props.dispatch(
                          //   getServiceLine({
                          //     json: JSON.stringify({
                          //       ServiceLine: [
                          //         {
                          //           AppointmentId: record.AppointmentId,
                          //         },
                          //       ],
                          //     }),
                          //   }),
                          // );
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const { ServiceList, ServiceLineInfo } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid  rcm-tab">
          <div className="">
            <div className="row px-0 d-flex align-items-center">
              <div class="col-3 px-0 align-self-baseline">
                {' '}
                <h4 className="pt-5">SERVICES</h4>
              </div>
              <div class="col-9 px-0 d-flex align-items-center justify-content-end">
                <div class="search-box d-inline-flex w-100">
                  <div class="form-group has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
              {/* <div class="col-1 px-0 d-flex align-items-center align-self-baseline justify-content-end"></div> */}

              {/* <div class="col-4 px-0 d-flex align-items-center align-self-baseline justify-content-end">
                <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  value={'Filter'}
                />
                <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn"
                  value={'New Payer'}
                  onClick={event => {
                    this.setState({
                      createAndEditGroupModal: true,
                    });
                  }}
                />
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name="patient-ServiceList"
                    // bordered
                    columns={tempColumns}
                    dataSource={ServiceList}
                    size={'middle'}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      // hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.dispatch(
              listServiceLine({
                json: JSON.stringify({
                  ServiceLine: [
                    {
                      ClientId: this.props.clientId,
                    },
                  ],
                }),
              }),
            );
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
          }}
        />
        <Modal
          zIndex={100}
          visible={this.state.createAndEditGroupModal}
          footer={null}
          closable={false}
          width={980}
          destroyOnClose={true}
        >
          <ReduxServiceLineModal
            // ServiceCodeList={this.props.ServiceCodeList}
            // serviceCodeInfo={this.state.ServiceDetails}
            // ServiceDetails={this.state.ServiceDetails}
            // deleteMoreCode={this.deleteMoreCode}
            // onSaveClick={this.onSubmit}
            // isFetchingServiceLine={this.props.isFetchingServiceLine}
            // initialValues={{
            //   ServiceCodes: this.state.ServiceDetails,
            //   DiagnosisCode:
            //     ServiceLineInfo.DiagnosisListId &&
            //     ServiceLineInfo.DiagnosisListId.split(',') &&
            //     ServiceLineInfo.DiagnosisListId.split(',').map(Number),
            //   SupervisorId: ServiceLineInfo.SupervisorId,
            // }}
            // ServiceLineInfo={ServiceLineInfo}
            // Supervisor={this.props.Supervisor}
            // UnitOfMeasurement={this.props.UnitOfMeasurement}
            // AppointmentId={ServiceLineInfo.AppointmentId}
            // Delete={() => {
            //   this.Removetoggle();
            // }}
            AppointmentId={this.state.AppointmentId}
            ServiceLineId={this.state.ServiceLineId}
            ClientId={this.state.ClientId}
            onCancel={() => {
              this.setState({
                createAndEditGroupModal: false,
              });
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}
const AddMoreCode = ({
  fields,
  meta: { touched, error },
  disable,
  existingRows,
  ServiceCodeList,
  ServiceCodes,
  UnitOfMeasurement,
  deleteMoreCode,
  dispatch,
}) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <>
            <div
              class="row"
              style={{
                background: '#F8F8F8',
                margin: '5px',
                padding: '10px 0px',
                alignItems: 'center',
              }}
            >
              <div class="col-4">
                <div class="form-group service_line_code">
                  <Field
                    name={`${field}.ServiceCodeId`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Code`}
                    options={ServiceCodeList}
                    fieldName={'Code'}
                    disabled={disable}
                    onChange={value => {
                      dispatch(
                        getServiceCode({
                          json: JSON.stringify({
                            ServiceCode: [
                              {
                                ServiceCodeId: value,
                                ReactIndex: index,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div class="col-8">
                <div className="service-code p-2">
                  <p className="code-description-lable">Description</p>
                  <p className="code-description-val">
                    {ServiceCodes &&
                      ServiceCodes[index] &&
                      ServiceCodes[index].ServiceCodeDescription}
                  </p>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group service_line_code">
                  <Field
                    name={`${field}.UnitOfMeasurementId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Unit of Measure`}
                    options={UnitOfMeasurement}
                    fieldName={'Unit of Measure'}
                    disabled={true}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${field}.UnitsId`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Units'}
                    min={0}
                    max={999}
                    negativeNumberBlocked={true}
                    controlledMaxAge={true}
                    style={{ background: '#F8F8F8', paddingRight: '15px' }}
                  />
                  <label class="placeholder-label">Units</label>
                </div>
              </div>
              <div class="col-3"></div>
              <div class="col-1">
                {fields.length > 1 && (
                  <a
                    class="delete-contact"
                    onClick={() => {
                      fields.remove(index);
                      deleteMoreCode(index);
                    }}
                    disabled={disable}
                  >
                    <i class="fas fa-trash" />
                  </a>
                )}
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-1 pb-1">
          {/* <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}> */}
          <a
            class="add-contact"
            onClick={() => fields.push({})}
            disabled={disable}
            style={{ marginLeft: 'unset' }}
          >
            <label style={{ cursor: 'pointer' }}>
              + Add {fields.length == 0 ? 'Code' : 'Another Code'}
            </label>
          </a>
        </div>
      </div>
    </>
  );
};
const ServiceLineForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    disable,
    onCancel,
    ServiceCodeList,
    ServiceLineInfo,
    dispatch,
    serviceCodeInfo,
    isFetchingServiceLine,
    Supervisor,
    deleteMoreCode,
  } = props;
  const {
    AppointmentId,
    DiagnosisInfo,
    EndTime,
    PlaceOfServiceId,
    ProgramDescription,
    ServiceCodeDescription,
    ServiceCodeId,
    SessionDate,
    SessionTime,
    SessionType,
    StartTime,
    SupervisorId,
    SupervisorName,
    PractitionerName,
    ClientName,
    ClientId,
    PlaceOfServiceName,
    FacilityName,
    StatusDescription,
    Diagnosis,
    DurationInMin,
    ServiceDetails,
    Note,
    TypeOfNoteDescription,
    ReasonForVisit,
  } = ServiceLineInfo;
  const DiagnosisOption =
    (Diagnosis &&
      Diagnosis.map(item => {
        return {
          label: item.DiagnosisDictDesc,
          value: item.DiagnosisListId,
          description: item.FullDescription,
        };
      })) ||
    [];
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      {/* <div class="modal-header flex-wrap border-bottom-0 "></div> */}
      <div class="modal-body rcm-panel enrollment-form py-0">
        <Loader2 loading={isFetchingServiceLine} />
        <div className="row">
          <div className="col-4" style={{ background: '#F8F8F8' }}>
            <div className="service-line" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
              <h4 class="title col-12 text-left">View/edit service</h4>
              <div className="block">
                <span className="name">{ClientName}</span>
                <p className="enrollment"> {ClientId}</p>
              </div>
              <div className="service-info-block px-3">
                <div className="d-flex align-items-center">
                  <img src="images/icons/grey_calendar.svg" width="24px" className="mr-2" />
                  {SessionDate}
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/icons/grey_clock.svg" width="24px" className="mr-2" />
                  <span className="mr-2">{SessionTime} </span>{' '}
                  <span
                    style={{
                      border: ' 1px solid #D8D8D8',
                      boxSizing: 'border-box',
                      borderRadius: '4px',
                      color: '#828282',
                      padding: '0px 5px',
                    }}
                  >
                    {timeConvert(DurationInMin)}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/icons/location_black.svg" width="24px" className="mr-2" />
                  {FacilityName}
                </div>
                <div className="d-flex align-items-center">
                  <img src="images/icons/user.svg" width="24px" className="mr-2" />
                  {SessionType}
                </div>
                <div className="d-flex align-items-center">
                  <img
                    src="images/icons/grommet-icons_status-good.svg"
                    width="22px"
                    className="mr-2"
                  />
                  {StatusDescription}
                </div>
              </div>

              <div className="info-block">
                <div className="block">
                  <span className="label">{'Place of Service'}</span>
                  <p className="value"> {PlaceOfServiceName}</p>
                </div>
                <div className="block">
                  <span className="label">{'Program'}</span>
                  <p className="value"> {ProgramDescription}</p>
                </div>
                <div className="block">
                  <span className="label">{'Staff'}</span>
                  <p className="value"> {PractitionerName}</p>
                </div>
              </div>
              <div className="col-md-12">
                <div style={{ color: '#959595', fontSize: '14px' }}>{'Note'}</div>
                <p className="value"> {Note && Note}</p>
              </div>
              <div className="col-md-12">
                <div style={{ color: '#959595', fontSize: '14px' }}>{'Type of Note'}</div>
                <p className="value"> {TypeOfNoteDescription && TypeOfNoteDescription}</p>
              </div>
              <div className="col-md-12">
                <div style={{ color: '#959595', fontSize: '14px' }}>{'Reason For Visit'}</div>
                <p className="value"> {ReasonForVisit && ReasonForVisit}</p>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="" style={{ paddingTop: '40px', paddingBottom: '30px' }}>
              <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                ×
              </button>
              <div class="row my-4">
                <div class="col-12 ">
                  <h4>Service Details</h4>
                </div>
                {/* <div class="col-4">
                  <div class="form-group">
                    <Field
                      name={`ServiceCodeId`}
                      // validate={validate.required}
                      type="text"
                      component={ReduxSelect}
                      placeholder={`Code`}
                      options={ServiceCodeList}
                      fieldName={'Code'}
                      disabled={disable}
                      onChange={value => {
                        dispatch(
                          getServiceCode({
                            json: JSON.stringify({
                              ServiceCode: [
                                {
                                  ServiceCodeId: value,
                                },
                              ],
                            }),
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <Field
                      name="ServiceCodeUnit"
                      type="number"
                      component={ReduxInput}
                      fieldName={'Unit'}
                      min={0}
                      max={5}
                      controlledMaxAge={true}
                    />
                    <label class="placeholder-label">Unit</label>
                  </div>
                </div>
                <div class="col-6">
                  <div style={{ background: '#F8F8F8', height: '80px' }} className="p-2">
                    <p
                      style={{
                        color: '#959595',
                        fontSize: '16px',
                        marginBottom: '0px',
                        fontWeight: '400',
                      }}
                    >
                      Description
                    </p>
                    <p>{serviceCodeInfo.ServiceCodeDescription || ServiceCodeDescription}</p>
                  </div>
                </div> */}

                <div class="col-12">
                  <FieldArray
                    name="ServiceCodes"
                    disable={disable}
                    component={AddMoreCode}
                    deleteMoreCode={deleteMoreCode}
                    ServiceCodeList={ServiceCodeList}
                    ServiceCodes={props.ServiceDetails}
                    UnitOfMeasurement={props.UnitOfMeasurement}
                    // existingRows={existingServiceCodesRows}
                    {...props}
                  />
                </div>
                <div class="col-12 my-3">
                  <h6>Diagnosis</h6>
                  <div
                    class="col-12"
                    style={{
                      overflowY: 'scroll',
                      maxHeight: '350px',
                    }}
                  >
                    <div
                      class="form-group"
                      style={{
                        flexFlow: 'wrap',
                        display: 'flex',
                      }}
                    >
                      <Field
                        name="DiagnosisCode"
                        component={CheckboxGroup}
                        col={24}
                        widthClassName={'w-75'}
                        options={DiagnosisOption}
                        fullDescription={true}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <Field
                      name="SupervisorId"
                      type="text"
                      placeholder="Supervisor"
                      component={ReduxSelect}
                      options={Supervisor}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 mt-2 text-right">
                  <input type="submit" class="btn px-5" value={'Update Changes'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
// const mapStateToProps = state => {
//   const { combos, medicate_combos } = state;
//   let ServiceCodeIdValue = selector(state, 'ServiceCodeId');
//   let SupervisorIdValue = selector(state, 'SupervisorId');
//   return {
//     ServiceCodeIdValue,
//     SupervisorIdValue,
//   };
// };
const ReduxServiceLineForm = reduxForm({
  form: 'ClientserviceLineForm',
  enableReinitialize: true,
})(connect()(withRouter(ServiceLineForm)));

const mapStateToPropsParent = state => {
  const {
    upsert_service_line,
    get_service_code,
    list_service_line,
    get_service_line,
    combos,
  } = state;
  const { data: serviceCodeInfoData } = get_service_code;
  const { data: serviceListData, isFetching } = list_service_line;
  const { data: serviceInfoData, isFetching: isFetchingServiceLine } = get_service_line;

  let ServiceCodeList = [];
  let Supervisor = [];
  let UnitOfMeasurement = [];

  if (combos.data && combos.data['RCM_ServiceCode']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'];
    Supervisor = combos.data['Supervisor'];
    UnitOfMeasurement = combos.data['UnitOfMeasurement'] || [];
  }

  let ServiceList = [];
  let ServiceInfo = null;
  let serviceCodeInfo = null;

  let isInserted = false;
  let isRemoved = false;

  if (serviceListData && serviceListData.ServiceLine) {
    ServiceList = serviceListData.ServiceLine || [];
  }

  if (serviceInfoData) {
    ServiceInfo = serviceInfoData || {};
  }
  if (serviceCodeInfoData && serviceCodeInfoData.ServiceCode) {
    serviceCodeInfo = serviceCodeInfoData.ServiceCode[0] || [];
  }
  if (upsert_service_line.data && upsert_service_line.data.success) {
    isInserted = true;
  }
  const existingServiceCodesRows = selector(state, 'ServiceCodes');
  const SupervisorIdValue = selector(state, 'SupervisorId');
  const DiagnosisCodeValue = selector(state, 'DiagnosisCode');

  return {
    ServiceCodeList: ServiceCodeList,
    ServiceCodeInfo: serviceCodeInfo,
    ServiceList: ServiceList,
    ServiceInfo: ServiceInfo,
    isInserted,
    isFetching,
    isFetchingServiceLine,
    Supervisor,
    existingServiceCodesRows,
    UnitOfMeasurement,
    SupervisorIdValue,
    DiagnosisCodeValue,
  };
};
export default connect(mapStateToPropsParent)(ServiceLine);
const selector = formValueSelector('ClientserviceLineForm');
