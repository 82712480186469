import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  CheckboxGroup,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
const diagnosed = [
  { label: 'Bipolar disorder', value: 'Bipolardisorder' },
  { label: 'Depression', value: 'Depression' },
  { label: 'Anxiety', value: 'Anxiety' },
  { label: 'Anger', value: 'Anger' },
  { label: 'Suicide', value: 'Suicide' },
  { label: 'Schizophrenia', value: 'Schizophrenia' },
  { label: 'Post-traumatic stress', value: 'Post-traumatic stress' },
  { label: 'Alcoholabuse', value: 'Alcoholabuse' },
];
const Demographic = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    isEmployedValue,
    style,
    goToPreviuos,
    disable,
  } = props;
  return (
    <form onSubmit={handleSubmit(handleNext)} style={{ padding: '36px 20px 200px 20px' }}>
      <div class="row mb-4">
        <div class="col-12">
          <h6>Billing Provider</h6>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`providerID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Provider ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Provider ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`providerName`}
              type="text"
              component={ReduxInput}
              fieldName={'Provider Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Provider Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pAddLine1`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Permanent Address Line 1'}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 1</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pAddLine2`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Permanent Address Line 2'}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 2</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`city`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'City'}
              disabled={disable}
            />
            <label class="placeholder-label">City</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`State/Province/Territories`}
              type="text"
              component={ReduxSelect}
              placeholder="State/Province/Territories"
              // options={StateProvince}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`postal/Zip`}
              type="text"
              component={ReduxInput}
              fieldName={'Postal/Zip Code'}
              disabled={disable}
            />
            <label class="placeholder-label">Postal/Zip Code</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`billingPhone`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Billing Phone'}
              disabled={disable}
            />
            <label class="placeholder-label">Billing Phone</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`taxID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Tax ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Tax ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`groupNumber`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Group Number'}
              disabled={disable}
            />
            <label class="placeholder-label">Group Number</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`NPI`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'NPI'}
              disabled={disable}
            />
            <label class="placeholder-label">NPI</label>
          </div>
        </div>
        <div class="col-12">
          <h6>Referring Provider</h6>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringProviderID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Provider ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Provider ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringFirstName`}
              type="text"
              component={ReduxInput}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">First Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringMiddleName`}
              type="text"
              component={ReduxInput}
              fieldName={'Middle Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Middle Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringLastName`}
              type="text"
              component={ReduxInput}
              fieldName={'Last Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Last Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringAddLine1`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Permanent Address Line 1'}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 1</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringAddLine2`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Permanent Address Line 2'}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 2</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringCity`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'City'}
              disabled={disable}
            />
            <label class="placeholder-label">City</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringState/Province/Territories`}
              type="text"
              component={ReduxSelect}
              placeholder="State/Province/Territories"
              // options={StateProvince}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringPostal/Zip`}
              type="text"
              component={ReduxInput}
              fieldName={'Postal/Zip Code'}
              disabled={disable}
            />
            <label class="placeholder-label">Postal/Zip Code</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringPhone`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Phone'}
              disabled={disable}
            />
            <label class="placeholder-label">Phone</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`referringTaxID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Tax ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Tax ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`Speciality`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Speciality'}
              disabled={disable}
            />
            <label class="placeholder-label">Speciality</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`Fax`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Fax'}
              disabled={disable}
            />
            <label class="placeholder-label">Fax</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`emailAddress`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Email Address'}
              disabled={disable}
            />
            <label class="placeholder-label">Email Address</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`SSN`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'SSN'}
              disabled={disable}
            />
            <label class="placeholder-label">SSN</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`stateLicenseID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'State License ID'}
              disabled={disable}
            />
            <label class="placeholder-label">State License ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`NPI`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'NPI'}
              disabled={disable}
            />
            <label class="placeholder-label">NPI</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`PIN`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'PIN'}
              disabled={disable}
            />
            <label class="placeholder-label">PIN</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`groupID`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Group ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Group ID</label>
          </div>
        </div>

        <div class="row pagination-button">
          <div class="col-12 text-right">
            <button
              onClick={goToPreviuos}
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  return {
    MaritalStatus: combos.data.MaritalStatus,
    EthnicRace: combos.data.EthnicRace,
    Gender: combos.data.Gender,
    HousingType: combos.data.HousingType,
    EyeColor: combos.data.EyeColor,
    Language: combos.data.Language,
    EducationType: combos.data.EducationType,
    WorkSkill: combos.data.WorkSkill,
    isEmployedValue: selector(state, 'isEmployed'),
  };
})(
  reduxForm({
    form: 'providerEnrollmentForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Demographic),
);

const selector = formValueSelector('providerEnrollmentForm');
