import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListRenderer from '../../../Components/listRenderer';
import ViewScreeningAnswer from '../../EnterpriseClient/components/ViewScreeningAnswer';
import FlaggedStatus from '../../EnterpriseClient/components/FlaggedStatus';
import ClientRiskChart from '../../EnterpriseClient/components/ClientRiskChart';
import renderHTML from 'react-render-html';

import { studentBanner, listSurveyQuestionAssessment } from '../../../store/actions';

function getDefaultTab(props) {
  const query = new URLSearchParams(props.location.search);
  let tabName = query.get('tab_name');
  if (tabName === 'notes') {
    return 0;
  } else if (tabName === 'surveys') {
    return 1;
  } else if (tabName === 'tasks') {
    return 2;
  } else if (tabName === 'courses') {
    return 3;
  } else if (tabName === 'growthplan') {
    return 4;
  }
}
class ViewAnswerDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      tabList: null,
      //   activeKey: getDefaultTab(props),
    };
  }
  getScreeningTab = surveyQuestion => {
    let tabList = [];
    surveyQuestion &&
      surveyQuestion.map(survey => {
        survey.Questions &&
          tabList.push({
            Name: renderHTML(survey.Tab),
            ExtraComponent: props => {
              return (
                <div className="">
                  <ViewScreeningAnswer Questions={survey.Questions} />
                </div>
              );
            },
          });
      });
    return tabList;
  };
  componentDidMount() {
    if (this.props.DrawerJsonData) {
      let tabList = this.getScreeningTab(this.props.DrawerJsonData);
      this.setState({ tabList: tabList });
    } else {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: this.props.ClientId,
                SurveyId: this.props.PreItakeSurveyId || this.props.SurveyId,
                SurveyType: 'ClientScreening',
              },
            ],
          }),
        }),
      );
    }
  }

  componentWillReceiveProps({ surveyQuestion, bannerInfo }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      let tabList = this.getScreeningTab(surveyQuestion.SurveyQuestion);
      // surveyQuestion.SurveyQuestion.map(survey => {
      //   tabList.push({
      //     Name: survey.Tab,
      //     ExtraComponent: props => {
      //       return (
      //         <div className="">
      //           <ViewScreeningAnswer Questions={survey.Questions} />
      //         </div>
      //       );
      //     },
      //   });
      // });

      this.setState({ SurveyQuestionList: surveyQuestion, tabList: tabList });
    }
  }
  render() {
    const { bannerInfo, DrawerEventJsonData, SurveyType } = this.props;
    const { tabList } = this.state;
    const { UpdatedOn, FinalRisk, FlaggedCount } = DrawerEventJsonData;
    // const list = [
    //   {
    //     Name: 'Legal',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Physical',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Medical',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'DNR',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Financial',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    // ];

    return (
      <div className="">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-5">
            <h6>Updated on {UpdatedOn}</h6>
          </div>
          <div class="col-sm-12 col-md-7">
            {SurveyType === 'Client Screening' && (
              <div className="p-3">
                <FlaggedStatus
                  FlaggedStatus={{
                    ...bannerInfo,
                    FlaggedCount,
                  }}
                />
              </div>
            )}
            {SurveyType === 'Program Alignment' && (
              <div className="py-3 d-flex justify-content-end">
                <ClientRiskChart ClientRisk={(FinalRisk && FinalRisk.toString()) || '0'} />
              </div>
            )}
          </div>
        </div>
        <div className="center-tabs show-tabs mt-3">
          {tabList && (
            <ListRenderer
              list={tabList}
              activeKey={this.state.activeKey}
              // getActiveKey={activeKey => this.setState({ activeKey })}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { student_banner, list_survey_question_assessment } = state;
  const { data, isFetching, error } = student_banner;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = list_survey_question_assessment;

  let bannerInfo = null;
  let preItakeSurveyId = null;
  let surveyQuestion;

  if (data) {
    bannerInfo = data || {};
    preItakeSurveyId = data.PreItakeSurveyId;
  }
  if (surveyQuestionData && surveyQuestionData.SurveyQuestion) {
    surveyQuestion = surveyQuestionData;
  }
  return {
    bannerInfo,
    preItakeSurveyId,
    surveyQuestion,
  };
};
export default connect(mapStateToProps)(withRouter(ViewAnswerDrawer));
