import React, { Component } from 'react';
import { Collapse, Checkbox, Row, Col } from 'antd';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
// import Loader, { Loader2 } from '../../../../../Components/Loader';
// import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { upsertAppointmentNoteIntervention } from '../../../../../../store/actions';
import { connect } from 'react-redux';

const { Panel } = Collapse;
class TreeDataComponent extends Component {
  constructor(props) {
    super(props);
    let keys = props.checkedInterventions;
    let object = Object.assign({}, ...Object.entries({ ...keys }).map(([a, b]) => ({ [b]: true })));
    this.state = {
      checkedInterventions: object,
    };
  }

  interventionAPI = (e, treatmentPlanInterventionId) => {
    if (e.target.checked) {
      var someProperty = { ...this.state.checkedInterventions };
      someProperty[treatmentPlanInterventionId] = true;
      this.setState({ checkedInterventions: someProperty });

      this.props.dispatch(
        upsertAppointmentNoteIntervention({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                AppointmentNoteId: this.props.AppointmentNoteId,
                IsTreatmentPlanAdd: true,
                TreatmentPlanInterventionId: treatmentPlanInterventionId,
                AppointmentId: this.props.AppointmentId,
                GroupAppointmentId: this.props.GroupAppointmentId,
                ClientId: this.props.ClientId,
              },
            ],
          }),
        }),
      );
    } else {
      var someProperty = { ...this.state.checkedInterventions };
      someProperty[treatmentPlanInterventionId] = false;
      this.setState({ checkedInterventions: someProperty });

      this.props.dispatch(
        upsertAppointmentNoteIntervention({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                AppointmentNoteId: this.props.AppointmentNoteId,
                IsTreatmentPlanAdd: false,
                TreatmentPlanInterventionId: treatmentPlanInterventionId,
                AppointmentId: this.props.AppointmentId,
                GroupAppointmentId: this.props.GroupAppointmentId,
                ClientId: this.props.ClientId,
              },
            ],
          }),
        }),
      );
    }
  };

  render() {
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <div className="text-right mt-2 mb-5">
              {' '}
              <button style={{ border: 'none' }}>
                <i class="fas fa-print"></i>
              </button>
            </div>
          )}
          content={() => this.componentRef}
          documentTitle="Treatment Plan"
        />
        <div ref={el => (this.componentRef = el)} className="pageStyle">
          {/* <Loader2 loading={this.props.isFetching} /> */}

          {this.props.treeData &&
            this.props.treeData.map((item, index) => {
              const dimensionTitle = item.Description;
              return (
                <>
                  <Collapse defaultActiveKey={`${index}`}>
                    <Panel
                      header={
                        <div
                          style={{
                            fontWeight: '800',
                          }}
                        >
                          {item.AccordinTitle}
                        </div>
                      }
                      // header={`DIMENSION${index + 1} : ${item.Description}`}
                      key={index}
                      className="page-break dimension-panel-border"
                      style={{
                        fontWeight: '800',
                      }}
                    >
                      {item.Description}
                      {item.TreatmentPlanProblem &&
                        item.TreatmentPlanProblem.map((item, index2) => {
                          const problemTitle = item.ProblemTitle;
                          return (
                            <>
                              <Collapse defaultActiveKey={`${index2}`}>
                                <Panel
                                  header={
                                    <div
                                      style={{
                                        fontWeight: '800',
                                      }}
                                    >
                                      {item.AccordinTitle}
                                    </div>
                                  }
                                  // header={`PROBLEM${index2 + 1} : ${item.Description}`}
                                  key={index2}
                                  className="problem-panel-border"
                                >
                                  {item.Description}

                                  {item.TreatmentPlanGoal &&
                                    item.TreatmentPlanGoal.map((item, index3) => {
                                      const goalTitle = item.GoalTitle;
                                      return (
                                        <>
                                          <Collapse defaultActiveKey={`${index3}`}>
                                            <Panel
                                              header={
                                                <div
                                                  style={{
                                                    fontWeight: '800',
                                                  }}
                                                >
                                                  {item.AccordinTitle}
                                                </div>
                                              }
                                              // header={`GOAL${index3 + 1} : ${item.Description}`}
                                              key={index3}
                                              className="goal-panel-border"
                                            >
                                              {item.Description}
                                              {item.TreatmentPlanObjective &&
                                                item.TreatmentPlanObjective.map((item, index4) => {
                                                  const objectiveTitle = item.ObjectiveTitle;
                                                  return (
                                                    <>
                                                      <Collapse defaultActiveKey={`${index4}`}>
                                                        <Panel
                                                          header={
                                                            <div
                                                              style={{
                                                                fontWeight: '800',
                                                              }}
                                                            >
                                                              {item.AccordinTitle}
                                                            </div>
                                                          }
                                                          // header={`OBJECTIVE${index4 + 1} : ${
                                                          //   item.Description
                                                          // }`}
                                                          key={index4}
                                                          className="objective-panel-border"
                                                        >
                                                          <div className="p-4">
                                                            {item.Description}
                                                            {item.TreatmentPlanIntervention &&
                                                              item.TreatmentPlanIntervention.map(
                                                                (item, index5) => {
                                                                  const interventionTitle =
                                                                    item.InterventionTitle;
                                                                  const mergedTitle = `${dimensionTitle} - ${problemTitle} - ${goalTitle} - ${objectiveTitle} - ${interventionTitle}`;
                                                                  return (
                                                                    <>
                                                                      {/* {item.Description} */}

                                                                      <div className="row justify-content-start mt-2 mb-2">
                                                                        <Checkbox
                                                                          disabled={
                                                                            this.props.IsSign
                                                                          }
                                                                          onChange={event => {
                                                                            this.interventionAPI(
                                                                              event,
                                                                              item.TreatmentPlanInterventionId,
                                                                            );
                                                                          }}
                                                                          value={
                                                                            item.TreatmentPlanInterventionId
                                                                          }
                                                                          checked={
                                                                            this.state
                                                                              .checkedInterventions[
                                                                              item
                                                                                .TreatmentPlanInterventionId
                                                                            ]
                                                                          }
                                                                          // checked={
                                                                          //   this.state
                                                                          //     .checkedInterventions &&
                                                                          //   this.state.checkedInterventions.includes(
                                                                          //     item.TreatmentPlanInterventionId,
                                                                          //   )
                                                                          // }
                                                                        >
                                                                          {item.Description}
                                                                        </Checkbox>

                                                                        {/* <input
                                                                        type="checkbox"
                                                                        id="vehicle1"
                                                                        onChange={e => {
                                                                          this.props.insertInCheckedInterventionsArray(
                                                                            parseInt(
                                                                              e.target.value,
                                                                            ),
                                                                          );
                                                                        }}
                                                                        value={
                                                                          item.TreatmentPlanInterventionId
                                                                        }
                                                                        checked={this.props.checkedInterventions.includes(
                                                                          item.TreatmentPlanInterventionId,
                                                                        )}
                                                                      />
                                                                      <label>
                                                                        {' '}
                                                                        {item.InterventionTitle}
                                                                      </label>
                                                                      <br></br> */}
                                                                      </div>
                                                                    </>
                                                                  );
                                                                },
                                                              )}
                                                            {/* {item.TreatmentPlanIntervention && (
                                                            <Field
                                                              name="comboTreatmentPlanIntervention"
                                                              component={CheckboxGroup}
                                                              col={24}
                                                              widthClassName={'w-75'}
                                                              // offset={1}
                                                              // justify={'start'}
                                                              options={
                                                                item.TreatmentPlanIntervention &&
                                                                item.TreatmentPlanIntervention.map(
                                                                  (item, index) => {
                                                                    return {
                                                                      label: item.InterventionTitle,
                                                                      value:
                                                                        item.TreatmentPlanInterventionId,
                                                                    };
                                                                  },
                                                                )
                                                              }
                                                            />
                                                          )} */}

                                                            {/* {item.TreatmentPlanIntervention &&
                                                            item.TreatmentPlanIntervention.map(
                                                              (item, index) => {
                                                                return (
                                                                  <>{item.InterventionTitle}</>
                                                                );
                                                              },
                                                            )} */}
                                                          </div>
                                                        </Panel>
                                                      </Collapse>
                                                    </>
                                                  );
                                                })}
                                            </Panel>
                                          </Collapse>
                                        </>
                                      );
                                    })}
                                </Panel>
                              </Collapse>
                            </>
                          );
                        })}
                    </Panel>
                  </Collapse>
                </>
              );
            })}
        </div>
        {/* <Collapse onChange={this.callback}>
          <Panel header="This is panel header 1" key="1">
            <Collapse defaultActiveKey="1">
              <Panel header="This is panel nest panel" key="1">
                <p>{this.text}</p>
              </Panel>
            </Collapse>
          </Panel>
          <Panel header="This is panel header 2" key="2">
            <p>{this.text}</p>
          </Panel>
          <Panel header="This is panel header 3" key="3">
            <p>{this.text}</p>
          </Panel>
        </Collapse> */}
        ,
      </>
    );
  }
}
const mapStateToProps = state => {
  const { upsert_appointment_note_intervention } = state;
  var { data, isFetching, error } = upsert_appointment_note_intervention;

  return {
    isFetching,
  };
};

TreeDataComponent = connect(mapStateToProps)(TreeDataComponent);
export { TreeDataComponent };
