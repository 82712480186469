import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import ReduxMedicationForm from './medicationCustom/medicationForm';
import { Table, Checkbox, Radio, Modal } from 'antd';
import moment from 'moment';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import PersistTableSort from '../../../../component/persistTableSort';
import { getMedicationLogList, resetUpsertMedicationLog } from '../../../../store/actions';
import { ProcessCombos, ProcessFilterCombos } from '../../../../store/comboConfig';
import CustomModal from '../../../../core/modal';

class MedicationLogs extends Component {
  constructor() {
    super();
    this.state = {
      isMedicationModal: false,
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentDidMount() {
    var result = ProcessCombos('Medication,FrequencyOfUse,Dosage,DrugInteractionResponse,Route');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(
      getMedicationLogList({
        Json: JSON.stringify({
          MedicationLog: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ medicationLog, isUpsert }) {
    if (isUpsert && isUpsert != this.props.isUpsert) {
      this.setState({
        isMedicationModal: false,
        medicationLogData: {},
        medicationLogId: null,
      });
      this.props.dispatch(reset('medicationForm'));
      this.toggle('Medication Log Added successfully');
    }
    if (
      medicationLog &&
      JSON.stringify(medicationLog) != JSON.stringify(this.props.medicationLog)
    ) {
      let data = {
        MedicationLogId: medicationLog.MedicationLogId,
        medication: medicationLog.MedicationId,
        prescriber: medicationLog.PrescriberId,
        dosage: medicationLog.DosageId,
        frequency: medicationLog.Frequency,
        route: medicationLog.RouteId,
        instruction: medicationLog.Instruction,
        drugInteractionResponse: medicationLog.DrugInteractionResponseId,
        startDate: moment(medicationLog.StartDate).format('YYYY-MM-DD'),
        endDate: moment(medicationLog.StopDate).format('YYYY-MM-DD'),
        intakeTime: medicationLog.IntakeTime,
        notes: medicationLog.Notes,
        daysSupply: medicationLog.DaysSupply,
      };
      this.setState({
        medicationLogData: data,
      });
    }
  }
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      getMedicationLogList({
        json: JSON.stringify({
          MedicationLog: [
            {
              Search: value,
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  };

  columns = [
    {
      title: 'Medication/Order Type',
      dataIndex: 'Medicine',
      key: 'Medicine',
      sorter: (a, b) => isComparer(a.Medicine, b.Medicine),
    },
    {
      title: 'Prescriber',
      dataIndex: 'Prescriber',
      key: 'Prescriber',
      sorter: (a, b) => isComparer(a.Prescriber, b.Prescriber),
    },
    {
      title: 'Dosage',
      dataIndex: 'Dosage',
      key: 'Dosage',
      sorter: (a, b) => isComparer(a.Dosage, b.Dosage),
    },
    {
      title: 'Frequency',
      dataIndex: 'Frequency',
      key: 'Frequency',
      sorter: (a, b) => isComparer(a.Frequency, b.Frequency),
    },
    {
      title: 'Intake Time',
      dataIndex: 'IntakeTime',
      key: 'IntakeTime',
      sorter: (a, b) => isComparer(a.IntakeTime, b.IntakeTime),
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
      sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
    },
    {
      title: 'Stop Date',
      dataIndex: 'StopDate',
      key: 'StopDate',
      sorter: (a, b) => isDateComparer(a.StopDateForSort, b.StopDateForSort),
    },
    {
      title: 'Action',
      key: 'x',
      render: (text, record, index) => {
        if (!this.props.isReadOnlyUser) { return <>
        <div class="tools-grid">
          <div className="mr-4">
            <span className="start-btn">
              <img
                src="images/shape.svg"
                width="14px"
                style={{ cursor: 'pointer' }}
                onClick={event => {
                  this.setState({
                    isMedicationModal: true,
                    medicationLogId: record.MedicationLogId,
                  });
                  // this.props.dispatch(
                  //   getPayer({
                  //     json: JSON.stringify({
                  //       Payer: [
                  //         {
                  //           PayerId: record.PayerId,
                  //         },
                  //       ],
                  //     }),
                  //   }),
                  // );
                }}
              />
            </span>
          </div>
        </div></>} else {}
    },
    },
  ];

  render() {
    const { isReadOnlyUser } = this.props;
    return (
      <div class="main survery-question-page mb-5">
        <div className="container-fluid rcm-tab">
          <div className="row">
            <div className="col-12">
              <div className="border-0">
                <div className="row px-0 mt-5 d-flex align-items-center">
                  <div className="col-12 col-xl-3 px-0">
                    <h4 className="">Medication Log</h4>
                  </div>
                  <div className="col-9 col-xl-9 px-0 d-flex align-items-center justify-content-end">
                    <div className="search-box d-inline-flex w-100">
                      <div className="form-group has-search w-100 mb-0">
                        <input
                          type="text"
                          className="form-control 
                   shadow-sm rcm-document-search"
                          placeholder="Search..."
                          onChange={this.onSearchChange}
                        />
                        <button className="btn search-icon " type="button">
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                    {!isReadOnlyUser && (
                      <button
                        style={{ cursor: 'pointer', marginLeft: '25px' }}
                        class="btn black-btn"
                        color="blue"
                        onClick={event => {
                          this.setState({
                            isMedicationModal: true,
                            medicationLogData: {},
                            medicationLogId: null,
                          });
                        }}
                      >
                        Add Medication
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 pt-5 pb-5 rcm-subtab-table-block">
            <div className="col-12">
              <div className="">
                <PersistTableSort
                  name="MedicationLogList"
                  columns={this.columns}
                  dataSource={this.props.medicationLogList}
                  size={'middle'}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div class="dataTables_wrapper no-footer">
                          <div class="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a class="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a class="paginate_button next">Next</a>
                            ) : (
                              <a class="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          zIndex={100}
          visible={this.state.isMedicationModal}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose
        >
          <ReduxMedicationForm
            initialValues={this.state.medicationLogData}
            MedicationLogId={this.state.medicationLogId}
            ClientId={this.getClientId()}
            onCancel={() => {
              this.setState({
                isMedicationModal: false,
              });
            }}
          />
        </Modal>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(
              getMedicationLogList({
                Json: JSON.stringify({
                  MedicationLog: [
                    {
                      ClientId: this.getClientId(),
                    },
                  ],
                }),
              }),
            );
            this.props.dispatch(resetUpsertMedicationLog());
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  get_medication_log_list,
  get_medication_log,
  upsert_medication_log,
}) => {
  const { data, isFetching } = get_medication_log_list;
  const { data: medicationData } = get_medication_log;

  let medicationLogList = [];
  let medicationLog = null;
  let isUpsert = false;

  if (data && data.MedicationLog) {
    medicationLogList = data.MedicationLog || [];
  }
  if (medicationData && medicationData.MedicationLog) {
    medicationLog = medicationData.MedicationLog[0];
  }
  if (upsert_medication_log.data && upsert_medication_log.data.success) {
    isUpsert = true;
  }
  return {
    medicationLogList,
    isFetching,
    medicationLog,
    isUpsert,
  };
};
export default connect(mapStateToProps)(withRouter(MedicationLogs));
