import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import AIAugmentedSummary from './AIAugmentedSummary';
import AIAugmentedSummary from '../../customScreens/surveyResponse/AIAugmentedSummary';
import ListRenderer from '../../../Components/listRenderer';
import SurveyList from './surveyList';
import { Tabs } from 'antd';
const activeKey = 0;

class SurveyResponseDrawer extends Component {
  constructor(props) {
    super(props);
    const { ConcatenatedName } = props.answerRecord || {};
    this.state = {
      isASAM: ConcatenatedName === 'ASAM',
      activeKey: 0,
    };
  }

  tabList = [
    {
      Name: 'AI Augmented Summary',
      tabName: 'AI-Augmented-Summary',
      ComponentName: AIAugmentedSummary,
    },
    {
      Name: 'Survey List',
      tabName: 'Survey-List',
      ComponentName: SurveyList,
    },
  ];

  onTabClick = key => {
    this.setState({
      activeKey: key,
    });
  };

  componentWillReceiveProps({ augmentId }) {
    if (augmentId) {
      this.setState({
        activeKey: 0,
      });
    }
  }
  render() {
    const {
      ClientId,
      toggleDrawer,
      answerRecord,
      surveyanswersdata,
      isAugmentedSummary,
      activekey,
    } = this.props;
    const { isASAM, activeKey } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 center-tabs">
            <Tabs
              // defaultActiveKey="1"
              centered
              activeKey={String(activeKey)}
              defaultActiveKey="0"
              onTabClick={this.onTabClick}
              forceRender={true}
              destroyInactiveTabPane={true}
            >
              {this.tabList &&
                this.tabList.map((item, keyIndex) => {
                  const { Name, ComponentName } = item;
                  const DynamicComponentConversion = ComponentName;
                  return (
                    <Tabs.TabPane tab={Name} key={keyIndex}>
                      {
                        <DynamicComponentConversion
                          studentId={this.props.studentId}
                          changeAugmentId={this.props.changeAugmentId}
                          ClientId={this.props.studentId}
                          UserGuid={this.props.UserGuid}
                          SurveyIds={this.props.augmentId}
                          toggleDrawer={this.props.toggleDrawer}
                          // isSurveyBanner={true}
                        />
                      }
                    </Tabs.TabPane>
                  );
                })}
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ user_feature_flag_get }) => {
  const { data: fFData, isFetching, error } = user_feature_flag_get;

  let isAugmentedSummary = false;

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    isAugmentedSummary =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  return {
    isAugmentedSummary,
  };
};
export default connect(mapStateToProps)(withRouter(SurveyResponseDrawer));
