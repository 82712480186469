import React, { Component } from 'react';

export const Qualifier = (
  <div id="Qualifier">
    <img src="images/icons/tick-green.svg" />
    <span className="text">Qualifier</span>
  </div>
);

export const Flagged = (
  <div id="Flagged">
    <img src="images/icons/red-flag.svg" />
    <span className="text">Flagged</span>
  </div>
);
