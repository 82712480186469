import React, { Component } from 'react';

class Welcome extends Component {
  render() {
    return (
      <div>
        <div class="container-fluid ">
          <img
            src="images/NewdashboardReport.jpg"
            alt="Workplace"
            usemap="#workmap"
            width="1366"
            // height="768"
          />
          <map name="workmap">
            <area
              shape="rect"
              coords="914,430,1290,1067"
              //   coords="2790,2239,1914,892"
              alt="Computer"
              target="_blank"
              href="https://app.powerbi.com/groups/me/reports/74bc8346-04a0-4a2a-b262-f6f707691905/ReportSection"
            />
          </map>
        </div>
      </div>
    );
  }
}

export default Welcome;
