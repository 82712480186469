import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate, { MobileFormatter, MobileNormalize } from '../validation/validate';

import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';

const AdolescentInformation = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    StudentChildrenCare,
    isEmployedValue,
    style,
    goToPreviuos,
    FaithType,
    HairColor,
    IdentifyMark,
    SuicidalTendencies,
    OtherIssue,
    disable,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
    isBoiseGirlsAcademyCenter,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={{ padding: '36px 20px 200px 20px' }}
    >
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`AdolescentName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Called By'}
              disabled={disable}
            />
            <label class="placeholder-label">Name you like to be called by</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="ParentMaritalStatusId"
              type="text"
              placeholder="Parent Marital Status"
              options={MaritalStatus}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Parent Marital Status'}
              disabled={disable}
            />
          </div>
        </div>
        {/* <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="Education"
              type="text"
              placeholder="Education"
              options={EducationType}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Education'}
              disabled={disable}
            />
          </div>
        </div> */}
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="NaturalHairColorId"
              type="text"
              placeholder="Natural Hair color"
              options={HairColor}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Natural Hair color'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="CurrentHairColorId"
              type="text"
              placeholder="Current Hair color"
              options={HairColor}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Current Hair color'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-2">
          <div class="form-group">
            <Field
              name={`AdolescentWeight`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Weight'}
              disabled={disable}
            />
            <label class="placeholder-label">Weight (pounds)</label>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-2">
          <div class="form-group">
            <Field
              name={`AdolescentHeight`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Height'}
              disabled={disable}
            />
            <label class="placeholder-label">Height (inches)</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="IdentifyMarkId"
              type="text"
              placeholder="Identifying Markers"
              options={IdentifyMark}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Identifying Markers'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8">
          <div class="form-group">
            <Field
              name={`MarkersDescription`}
              type="textarea"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Markers Description</label>
          </div>
        </div>
        {!isBoiseGirlsAcademyCenter && (
          <>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label class="custom-control-label" for="Signed Release available">
                  Signed Release available{'   '}
                  <Field
                    name={`IsSignedRelease`}
                    type="text"
                    class="custom-control-input not-empty"
                    component={ReduxToggle}
                    disabled={disable}
                  />
                </label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label class="custom-control-label" for="isCrime">
                  Have you ever been convicted of a crime?{'   '}
                  <Field
                    name={`IsConvictedCrime`}
                    type="text"
                    class="custom-control-input not-empty"
                    component={ReduxToggle}
                    disabled={disable}
                  />
                </label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label class="custom-control-label" for="isCrime">
                  Have you received help before?{'   '}
                  <Field
                    name={`IsHelpReceived`}
                    type="text"
                    class="custom-control-input not-empty"
                    component={ReduxToggle}
                    disabled={disable}
                  />
                </label>
              </div>
            </div>
          </>
        )}
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`ForWhatIssue`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'For what issue?'}
              disabled={disable}
            />
            <label class="placeholder-label">For what issue?</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="AdolescentParentFaithId"
              options={FaithType}
              type="text"
              placeholder="Parent Faith"
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Parent Faith'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="ChildFaith"
              options={FaithType}
              type="text"
              placeholder="What faith does your child claim now?"
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'child Faith'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="SuicidalTendenciesID"
              type="text"
              placeholder="Suicidal Tendencies"
              component={ReduxSelect}
              options={SuicidalTendencies}
              disabled={disable}
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-8">
          <div class="form-group">
            <Field
              name={`IdeationNotes`}
              type="textarea"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Ideation Notes</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`NoOfAttemptsMethod`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'attempts'}
              disabled={disable}
            />
            <label class="placeholder-label">No. of attempts and Method</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name="OtherIssueId"
              type="text"
              placeholder="Other Issue(s)"
              fieldName={'Other Issue(s)'}
              component={ReduxSelect}
              // validate={Validator.required}
              options={OtherIssue}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        {isBoiseGirlsAcademyCenter && (
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name={`DispositionOfAbuse`}
                type="textarea"
                component={ReduxInput}
                disabled={disable}
              />
              <label class="placeholder-label">Disposition of abuse situation?</label>
            </div>
          </div>
        )}
        <div class="col-12 col-md-6 col-lg-12">
          <div class="form-group">
            <label class="custom-control-label" for="isCrime">
              parent– prohibited contacts?{'   '}
              <Field
                name={`IsParentProhibitedContact`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name={`GuardianshipInformation`}
              type="textarea"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Guardianship information</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name={`MedicalDecisionMaking`}
              type="textarea"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Who holds medical decision-making per decree</label>
          </div>
        </div>
        {isBoiseGirlsAcademyCenter && (
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name={`AdolescentNotes`}
                type="textarea"
                component={ReduxInput}
                disabled={disable}
              />
              <label class="placeholder-label">Notes</label>
            </div>
          </div>
        )}
        <div className="col-12">
          <div class="row mb-3">
            <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
              <h6 class="d-inline-flex mb-0 mr-3 ">Education</h6>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`CurrentGrade`}
              type="text"
              component={ReduxInput}
              fieldName={'Current Grade'}
              disabled={disable}
            />
            <label class="placeholder-label">Current Grade</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8">
          <div class="form-group">
            <label class="custom-control-label" for="isCrime">
              Previous School Information?{'   '}
              <Field
                name={`IsPreviousSchool`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
            </label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`SchoolName`}
              type="text"
              component={ReduxInput}
              fieldName={'Name of School'}
              disabled={disable}
            />
            <label class="placeholder-label">Name of School</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`SchoolAddress`}
              type="text"
              component={ReduxInput}
              fieldName={'Address'}
              disabled={disable}
            />
            <label class="placeholder-label">Address</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`SchoolPhoneNumber`}
              type="text"
              component={ReduxInput}
              fieldName={'Phone Number'}
              disabled={disable}
              validate={validate.NumericMobile}
              format={MobileFormatter}
              normalize={MobileNormalize}
            />
            <label class="placeholder-label">Phone Number</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`SchoolAdminName`}
              type="text"
              component={ReduxInput}
              fieldName={'Administrator/Counselor Contact Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Administrator/Counselor Contact Name</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`LearningDisabilities`}
              type="text"
              component={ReduxInput}
              fieldName={'Learning disabilities'}
              disabled={disable}
            />
            <label class="placeholder-label">Learning disabilities</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`IEPor504`}
              type="text"
              component={ReduxInput}
              fieldName={'IEP or 504'}
              disabled={disable}
            />
            <label class="placeholder-label">IEP or 504</label>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <Field
              name={`NotesSection`}
              type="textarea"
              component={ReduxTextarea}
              fieldName={'Notes section'}
              disabled={disable}
            />
            <label class="placeholder-label">Notes section</label>
          </div>
        </div>
        <div class="row pagination-button">
          <div class="col-12 text-right">
            {currentTab > 0 && (
              <button
                onClick={goToPreviuos}
                type="button"
                class="btn btn-outline-primary btn-eForm-Prev mr-2"
                disabled={disable}
              >
                Previous
              </button>
            )}
            {currentTab + 1 != tabsCount && (
              <button
                type="submit"
                // onClick={handleNext}
                class="btn btn-eForm-Next"
                disabled={disable}
              >
                Next
              </button>
            )}
            {currentTab + 1 == tabsCount && (
              <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  return {
    MaritalStatus: combos.data.MaritalStatus,
    EthnicRace: combos.data.EthnicRace,
    Gender: combos.data.Gender,
    HousingType: combos.data.HousingType,
    EyeColor: combos.data.EyeColor,
    Language: combos.data.Language,
    EducationType: combos.data.EducationType,
    WorkSkill: combos.data.WorkSkill,
    StudentChildrenCare: combos.data.StudentChildrenCare,
    FaithType: combos.data.FaithType,
    HairColor: combos.data.HairColor,
    IdentifyMark: combos.data.IdentifyMark,
    SuicidalTendencies: combos.data.SuicidalTendencies,
    OtherIssue: combos.data.OtherIssue,

    isEmployedValue: selector(state, 'isEmployed'),
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(AdolescentInformation),
);

const selector = formValueSelector('wizard');
