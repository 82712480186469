import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Drawer } from 'antd';
import { getAppointmentNote } from '../../../../../store/actions';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import DrawerInternalContent from './drawerInternalContent';
class NotesDrawer extends Component {
  constructor() {
    super();
    this.state = {};
  }
  callDataAPI = () => {
    const { IsGroup, ClientId, GroupAppointmentId, dispatch, AppointmentId } = this.props;
    if (IsGroup) {
      dispatch(
        getAppointmentNote({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                ClientId: ClientId,
                GroupAppointmentId: GroupAppointmentId,
              },
            ],
          }),
        }),
      );
    } else {
      dispatch(
        getAppointmentNote({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                AppointmentId: AppointmentId,
              },
            ],
          }),
        }),
      );
    }
  };
  getDirtySubmitSucceededStatus = (isDirty, submitSucceeded) => {
    this.setState({ isDirty, submitSucceeded });
  };

  componentDidMount() {
    this.callDataAPI();
  }

  render() {
    const { isFetchingGetAppointmentNote, toggle, OnCloseDrawer, hideInterventions } = this.props;
    return (
      <React.Fragment>
        <Drawer
          className="custom-drawer"
          title="NOTE"
          placement="right"
          onClose={() => {
            if (this.state.isDirty && !this.state.submitSucceeded) {
              let result = window.confirm('Are you sure you dont want to save your changes?');
              if (result == true) {
                this.props.OnCloseDrawer();
              }
            }
            if (!this.state.isDirty) {
              this.props.OnCloseDrawer();
            }
            if (this.state.isDirty && this.state.submitSucceeded) {
              this.props.OnCloseDrawer();
            }
          }}
          visible={this.props.NotesDrawerVisible}
          width={700}
          bodyStyle={{ paddingBottom: 80,background:"white" }}
          closable={true}
          destroyOnClose={true}
        >
          <Loader2 loading={isFetchingGetAppointmentNote} />
          <DrawerInternalContent
            ClientId={this.props.ClientId}
            toggle={toggle}
            OnCloseDrawer={this.props.OnCloseDrawer}
            callDataAPI={this.callDataAPI}
            getDirtySubmitSucceededStatus={this.getDirtySubmitSucceededStatus}
            hideInterventions={hideInterventions}
            // isDrawerClosing={this.props.isDrawerClosing}
            // setIsDrawerClosing={this.props.setIsDrawerClosing}
            // setNotesDrawerVisible={this.props.setNotesDrawerVisible}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ get_appointment_note }) => {
  const {
    data: getAppointmentNote,
    isFetching: isFetchingGetAppointmentNote,
  } = get_appointment_note;

  let AppointmentNote = [];

  if (getAppointmentNote) {
    AppointmentNote = getAppointmentNote[0].AppointmentNote[0] || [];
  }

  return {
    AppointmentNote,
    isFetchingGetAppointmentNote,
  };
};
export default connect(mapStateToProps)(withRouter(NotesDrawer));
