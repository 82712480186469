import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { ReduxInput, ReduxPhoneInput } from '../../../../../../component/custom-redux-components';
import SubmitButton from '../../../../../../Components/submitButton';
import Validator from '../../../../../Validation';
import { ProfilePicComponent } from '../../../../../../Components/profilePicComponent';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';

class ExternalCareTeamMember extends Component {
  constructor() {
    super();
    this.state = {};
  }
  columns = [
    {
      title: 'Facility',
      dataIndex: 'FacilityName',
      key: 'FacilityName',
      sorter: (a, b) => isComparer(a.FacilityName, b.FacilityName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Facility Phone Number',
      dataIndex: 'PhoneNumber',
      key: 'PhoneNumber',
      sorter: (a, b) => isComparer(a.PhoneNumber, b.PhoneNumber),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Facility Location',
      dataIndex: 'Location',
      key: 'Location',
      sorter: (a, b) => isComparer(a.Location, b.Location),
      sortDirections: ['descend', 'ascend'],
      // render: (text, record, index) => {
      //   return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>;
      // },
    },
  ];
  render() {
    const {
      handleSubmit,
      onSaveClick,
      disable,
      style,
      careTeamCenter,
      careTeamMember,
      comboServiceCodelist,
      isFetchingUpsert,
      onClose,
      Country,
      Filter_StateProvince,
      PractitionerOffice,
      ExternalProviderSpecialtyType,
      ExternalProviderData,
    } = this.props;
    const {
      FirstName,
      LastName,
      NPI,
      Gender,
      EmailWork,
      Location,
      Specialty,
      ExternalProviderId,
      Facility,
      GenderText,
      SpecialtyText,
      CountryISO,
      State,
      City,
      ZipCode,
      PhoneNumber,
      Status,
    } = ExternalProviderData;
    return (
      <form onSubmit={handleSubmit(onSaveClick)} style={style}>
        <div className="row enrollment-form external-care-team-member-view mt-2">
          <div class="col-12 col-md-12">
            <div class="form-group">
              <div className="d-flex">
                <ProfilePicComponent />
                <div className="view ml-2">
                  <span className="text">
                    {FirstName} {LastName}
                  </span>
                  <span className="label">External Member</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div className="view">
                <span className="label">Gender</span>
                <span className="text">{GenderText}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div className="view">
                <span className="label">National Provider Identifier Number</span>
                <span className="text">{NPI}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <div className="view">
                <span className="label">Specialities</span>
                <span className="text">{SpecialtyText}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <Field
                name="EmailWork"
                type="text"
                component={ReduxInput}
                errorMessage="Email Id"
                fieldName={'Email Id'}
                validate={Validator.required}
              />
              <label class="placeholder-label">Email Id*</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`PhoneNumber`}
                component={ReduxPhoneInput}
                validate={[Validator.isValidMobile, Validator.required]}
                placeholder="Phone Number*"
                fieldName={'Phone Number'}
              />
            </div>
          </div>
          <div class="col-12 col-md-12">
            <span
              style={{
                color: '#7F8188',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              Facility and Facility Locations
            </span>
            <Table
              columns={this.columns}
              dataSource={Facility}
              pagination={false}
              scroll={{
                y: 450,
              }}
            />
          </div>
          <div className="col-12 text-right mt-2">
            <button
              className="btn btn-outline-primary px-5 mr-2"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>

            <SubmitButton
              // value={ClientCareTeamId ? 'Save' : 'Add'}
              value={'Save'}
              loading={isFetchingUpsert}
              style={{
                height: '44px',
                width: '100px',
              }}
            />
          </div>
        </div>
      </form>
    );
  }
}
const ReduxExternalCareTeamMember = reduxForm({
  form: 'ExternalCareTeamFormAssign',
  enableReinitialize: true,
})(connect()(withRouter(ExternalCareTeamMember)));

const mapStateToProps = state => {
  const {
    organization_center_family_combo,
    care_team_member_combo,
    combo_service_code,
    combos,
    get_combo_facility,
  } = state;
  const { data: careTeamCenterData } = organization_center_family_combo;
  const { data: careTeamMemberData } = care_team_member_combo;
  const { data: comboServiceCodeData } = combo_service_code;
  const { data: ComboFacilityData } = get_combo_facility;

  let careTeamCenter = [];
  let careTeamMember = [];
  let comboServiceCodelist = [];
  let Gender = [],
    Country = [],
    PractitionerOffice = [],
    ExternalProviderSpecialtyType = [],
    Filter_StateProvince = [];
  const CountryISO = formValueSelector('ExternalCareTeamFormAssign')(state, 'Country') || 'USA';

  if (careTeamCenterData && careTeamCenterData.Center) {
    careTeamCenter = careTeamCenterData.Center;
  }
  if (careTeamMemberData && careTeamMemberData.CareTeam) {
    careTeamMember = careTeamMemberData.CareTeam;
  }
  if (comboServiceCodeData && comboServiceCodeData.ServiceCode) {
    comboServiceCodelist = comboServiceCodeData.ServiceCode;
  }
  if (combos.data && combos.data['Gender']) {
    ExternalProviderSpecialtyType = combos.data['ExternalProviderSpecialtyType'];
    Gender = combos.data['Gender'];
    Country = combos.data['Country'];
    Filter_StateProvince = combos.data['Filter_StateProvince'][CountryISO];
  }
  if (ComboFacilityData && ComboFacilityData.Facility) {
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  return {
    careTeamCenter,
    careTeamMember,
    comboServiceCodelist,
    Gender,
    Country,
    Filter_StateProvince,
    PractitionerOffice,
    ExternalProviderSpecialtyType,
  };
};
export default connect(mapStateToProps)(ReduxExternalCareTeamMember);
