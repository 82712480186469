import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { ReduxToggle, ReduxTextarea } from '../../component/custom-redux-components';

export default class ToggleNote extends Component {
  render() {
    const {
      isToggleOn,
      toggleQuestion,
      toggleFiledName,
      toggleNoteFieldName,
      disable,
      notesPlaceholder,
    } = this.props;
    return (
      <div
        className={isToggleOn ? 'col-12 p-0 mb-3 toggle-notes' : 'col-12 p-0 toggle-notes'}
        style={{
          backgroundColor: isToggleOn ? '#F6F6F6' : 'transparent',
        }}
      >
        <div
          className={isToggleOn ? 'col-12 col-md-6 col-lg-12 mt-2' : 'col-12 col-md-6 col-lg-12'}
        >
          <div className="form-group">
            <label className="custom-control-label" for="is-convicted-sexual">
              {toggleQuestion}
              {' '}
              <Field
                name={toggleFiledName}
                type="text"
                className={`custom-control-input not-empty`}
                component={ReduxToggle}
                disabled={disable}
              />
            </label>
          </div>
        </div>

        {isToggleOn && (
          <div class="col-12 col-md-6 col-lg-12">
            <div class="form-group">
              <Field
                name={toggleNoteFieldName}
                type="textarea"
                component={ReduxTextarea}
                disabled={disable}
                className={`toggle-notes-textarea`}
              />
              <label class="placeholder-label">{notesPlaceholder || 'Notes'}</label>
            </div>
          </div>
        )}
      </div>
    );
  }
}
