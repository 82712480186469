import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Table, Typography } from 'antd';
import { clientRiskLevelGraphGet } from '../../../store/actions';
import { ReduxSelect } from '../../../component/custom-redux-components';
import colors from 'react-multi-date-picker/plugins/colors';
import { Loader2 } from '../../../Components/Loader';

export default class ClientRiskLevels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // riskSeries: [44, 16, 12],
      filterId: 'Last 3 Months',
      riskSeries: [],
      datesDropDown: [],
      riskOptions: {
        chart: {
          width: 480,
          type: 'pie',
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        colors: ['#6DEAB3', '#FFE194', '#FF6B6B'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              //   chart: {
              //     width: 200,
              //   },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  Columns = [
    {
      title: 'Risk Level',
      dataIndex: 'CategoryName',
      key: 'CategoryName',
    },
    {
      title: 'Contracts',
      dataIndex: 'TotalContracts',
      key: 'TotalContracts',
      align: 'right',
      render: (text, record) => {
        return (
          <div className="">
            <span>
              {new Intl.NumberFormat('en-US', {
                maximumSignificantDigits: 7,
              }).format(text)}
            </span>
          </div>
        );
      },
    },
  ];

  componentWillReceiveProps({ riskLevel }) {
    if (riskLevel && riskLevel != this.props.riskLevel) {
      const { Risks, DatesDropDown } = riskLevel;
      let optionLabels = [];
      let values = [];
      let colors = [];
      Risks &&
        Risks.map((item, index) => {
          optionLabels.push(item.CategoryName);
          values.push(item.TotalContracts);
          colors.push(item.ColorCode);
        });
      this.setState({
        riskSeries: [...values],
        riskOptions: { ...this.state.riskOptions, labels: optionLabels, colors: colors },
      });

      // if (DatesDropDown && DatesDropDown.length > 0) {
      //      this.handleContractPeriod(
      //        DatesDropDown[0] ? DatesDropDown[0].StartDate : null,
      //        DatesDropDown[0] ? DatesDropDown[0].EndDate : null,
      //      );
      // }

      this.setState({
        // datesDropDown: DatesDropDown,
        // filterId: DatesDropDown && DatesDropDown.length > 0 ? DatesDropDown[0].Value : null,
      });
    }
  }

  handleFilter = filterId => {
    const { datesDropdown } = this.props;
    this.setState({ filterId });
    let dates =
      datesDropdown &&
      datesDropdown.filter((item, index) => {
        return item.Value == filterId;
      });
    this.handleContractPeriod(
      dates[0] ? dates[0].StartDate : null,
      dates[0] ? dates[0].EndDate : null,
    );
  };

  handleContractPeriod = (startDate, endDate) => {
    this.props.dispatch(
      clientRiskLevelGraphGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: this.props.centerId || null,
              StartDate: startDate,
              EndDate: endDate,
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { riskLevel, datesDropdown, isRiskFetching } = this.props;
    const { datesDropDown } = this.state;
    return (
      <div className="col-12 payer-contracts-period">
        <div className="card border-0 alerts mb-5">
          <div className="card-body dashboard-analytics">
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              style={{ borderBottom: '1px solid #CCC', padding: '16px' }}
            >
              <p className="heading">Client Risk Levels</p>

              <div className="d-flex align-items-center payer-portal-center">
                <div className="form-group mb-0" style={{ width: '160px' }}>
                  <ReduxSelect
                    name={`filterId`}
                    // component={ReduxSelect}
                    options={datesDropdown}
                    onChange={this.handleFilter}
                    value={this.state.filterId}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center" style={{ position: 'relative' }}>
              <Loader2 loading={isRiskFetching} />
              <ReactApexChart
                options={this.state.riskOptions}
                series={this.state.riskSeries}
                type="pie"
                width={385}
              />
              <div className="mt-2 custom-header">
                <Table
                  dataSource={riskLevel ? riskLevel.Risks : []}
                  columns={this.Columns}
                  bordered
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
