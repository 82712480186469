import React from 'react';
import { Checkbox } from 'antd';

const AntdCheckbox = ({ value, onChange, label, checkedChildren, unCheckedChildren, disabled }) => (
  <Checkbox
    checked={value}
    onChange={event => onChange(event.target.checked)}
    size="small"
    checkedChildren="Yes"
    unCheckedChildren="No"
    disabled={disabled}
    className="redux-checkbox"
  >
    {label}
  </Checkbox>
);

const ReduxCheckbox = ({
  input,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  tabIndex,
  label,
}) => {
  var { touched, error, warning } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onBlur = input.onBlur;
  }
  return (
    <div style={{ display: 'inline-block', marginRight: '10px' }}>
      <AntdCheckbox
        {...input}
        className={`d-flex align-items-center ${className}`}
        value={value}
        checked={value}
        label={label}
        name={name}
        id={id}
        // className={className}
        disabled={!!disabled}
      />
      {touched &&
        ((error && <span className="required-color-red">{error}</span>) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

export default ReduxCheckbox;
