import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import TaskCollapse from './taskCollapse';

const { Panel } = Collapse;

export default function MilestoneCollapse(props) {
  const { milestone, keyIndex } = props;
  const [defaultActivePanels, setDefaultActivePanels] = useState([]);

  useEffect(() => {
    if (milestone && milestone.length > 0) {
      let defaultOpenPanels =
        milestone &&
        milestone.length > 0 &&
        milestone.map((item, index) => `${item.Milestone}-${index + 1}`);
      setDefaultActivePanels(defaultOpenPanels);
    }
  }, [milestone]);
  return (
    <div>
      {defaultActivePanels && defaultActivePanels.length > 0 && (
        <Collapse defaultActiveKey={defaultActivePanels} expandIconPosition={'end'}>
          {/* <Panel header="This is panel header 1" key="1">
          <div>{'text'}</div>
        </Panel> */}
          {milestone &&
            milestone.length > 0 &&
            milestone.map((item, index) => {
              const { Milestone, Duration, Frequency, Task, CountInfo } = item;
              return (
                <Panel
                  // header={`Milestone ${index + 1}`}
                  header={
                    <>
                      <div className="row">
                        <div className="col-12 panel-header-text">{CountInfo && CountInfo}</div>
                      </div>
                    </>
                  }
                  key={`${Milestone}-${index + 1}`}
                  className="milestone-panel-border mb-3"
                >
                  <p className="mb-0 my-3" style={{ color: '#182838', fontSize: '16px' }}>
                    {Milestone}
                  </p>
                  <p className="mb-0" style={{ color: '#7F8188', fontSize: '14px' }}>
                    Duration
                  </p>
                  <p style={{ fontSize: '16px' }}>{`${Duration} ${Frequency}`}</p>
                  <TaskCollapse task={Task} />
                </Panel>
              );
            })}
        </Collapse>
      )}
    </div>
  );
}
