import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Avatar, Tooltip } from 'antd';
class MultiImageRenderer extends Component {
  render() {
    const { ClientsConnected } = this.props;
    return (
      <>
        {ClientsConnected &&
        JSON.parse(ClientsConnected) &&
        JSON.parse(ClientsConnected).length > 0 ? (
          <>
            <div>
              <Avatar.Group
                maxCount={2}
                size="large"
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                  cursor: 'pointer',
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
                className="family-program"
              >
                {JSON.parse(ClientsConnected) &&
                  JSON.parse(ClientsConnected).map((item, index) => {
                    const { Name, ProfileImageUrl, UserGuid } = item;
                    return (
                      <>
                        <Tooltip placement="topLeft" title={Name}>
                          <Avatar
                            src={ProfileImageUrl || 'images/blank-profile.png'}
                            onClick={event => {
                              event.stopPropagation();
                              this.props.history.push(`/subscriberProfile/${UserGuid}`);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </>
                    );
                  })}
              </Avatar.Group>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
export default withRouter(MultiImageRenderer);
