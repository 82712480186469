import React, { Component } from 'react';
import RcmListRenderer from '../../Components/rcmListRenderer';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import { INITIAL_EVENTS, createEventId } from './event-utils';
import { Modal, Button, Menu } from 'antd';
import { connect } from 'react-redux';
import CustomModal from '../../Components/CustomModal';
import Loader, { Loader2 } from '../../Components/Loader';
import { Link, withRouter, Switch } from 'react-router-dom';
import { Table } from 'antd';
import { practitionerCombos } from '../../store/actions';
import PatientEnrollment from './patientEnrollmentForm';
import ClientList from './patientEnrollmentForm/clientList';
import ProviderEnrollment from './providerEnrollmentForm';
import Claims from './claimsForm';
import Group from './Group/index';
import Accounting from './accounting';
import Appointments from './appointments';
import './rcm-panel.css';
import PageTitle from '../../Components/PageTitle';

// const rcmActiveKey = 5;

class RevenueCycleManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current:
        (this.props.location.pathname == '/cw' && 'appointments') ||
        this.props.location.pathname.replace('/cw/', '') ||
        'appointments',
      // current: 'appointments',
    };

    // var currentPath = props.location.pathname.split('/')[
    //   props.location.pathname.split('/').length - 1
    // ];
    var params = new URLSearchParams(props.location.search);
    var search = params.get('tab');

    let tempActiveKey = search => {
      switch (search) {
        case 'Appointments':
          return 0;
        case 'PatientManagement/patientList':
          return 1;
        case 'PatientManagement/patientProfile':
          return 1;
        case 'GroupManagement/groupList':
          return 2;
        case 'GroupManagement/groupDetails':
          return 2;
        case 'Claims':
          return 3;
        case 'Accounting':
          return 4;
        case 'Provider/providerList':
          return 5;
        case 'Provider/providerProfile':
          return 5;
        // case 'ProviderEnrollment':
        //   return 6;
        default:
          return 0;
      }
    };
  }
  handleMenuClick = e => {
    let setCurrent;
    setCurrent = `${e.key}`;
    // debugger;
    // let setCurrent;
    // if (this.props.location.pathname == '/clinicalWorkflow' || '/clinicalWorkflow/appointments') {
    //   setCurrent = 'appointments';
    // } else {
    //   setCurrent = this.props.location.pathname.replace('/clinicalWorkflow/', '');
    // }
    this.setState({ current: setCurrent });
    this.props.history.push(`/cw/${e.key}`);
  };

  componentDidMount() {
    this.props.dispatch(practitionerCombos({}));
  }
  componentWillReceiveProps({}) {}
  render() {
    const { current } = this.state;

    return (
      <div className="rcm-panel main  mb-5">
        <PageTitle TitleText="Clinical Workflow" />
        <div className="rcm-tab ">
          <div className="row mx-0" style={{ background: '#fff' }}>
            <ul>
              <li>
                <Link to="/cw/appointments">appointments</Link>
              </li>
              <li>
                <Link to="/cw/patient-management/patient-list">patient management</Link>
              </li>
              <li>
                <Link to="/cw/patient-management/patient-list">patient management</Link>
              </li>
            </ul>

            <div className="col-xs-12" style={{ width: '100%' }}>
              <Menu
                onClick={this.handleMenuClick}
                selectedKeys={[current]}
                mode="horizontal"
                className="dashboard-menu"
                style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}
              >
                {/* <SubMenu key="SubMenu" title="More">
                  <Menu.ItemGroup title="Item 1">
                    <Menu.Item key="setting:1">Option 1</Menu.Item>
                    <Menu.Item key="setting:2">Option 2</Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu> */}
                <Menu.Item key="appointments">Appointments</Menu.Item>
                <Menu.Item key="patient-management/patient-list">Patient Management</Menu.Item>
                <Menu.Item key="groups">Groups</Menu.Item>
                <Menu.Item key="claims">Claims</Menu.Item>
                <Menu.Item key="accounting">Accounting</Menu.Item>
                <Menu.Item key="provider/provider-list">Provider Management</Menu.Item>
                {/* <Menu.Item key="alipay">
                  <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
                    Navigation Four - Link
                  </a>
                </Menu.Item> */}
              </Menu>
            </div>
            {/* {current === 'appointments' && <Appointments />}
            {current === 'patient-management/patient-list' && <PatientEnrollment />}
            {current === 'patient-management/patient-profile&pId=20000188' && <PatientEnrollment />}
            {current === 'groups' && <Group />}
            {current === 'claims' && <Claims />}
            {current === 'accounting' && <Accounting />} */}
            {/* {current === 'providerManagement' && <ProviderEnrollment />} */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ add_note_type }) => {
  const { data: addNodeTypeData, error, isFetching } = add_note_type;
  let isAddNodeType = false;
  if (addNodeTypeData && addNodeTypeData.success == true) {
    isAddNodeType = addNodeTypeData.success;
  }
  return {
    isAddNodeType,
  };
};
// export default connect(mapStateToProps)(PatientEnrollment);
export default connect(mapStateToProps)(RevenueCycleManagement);
