import React, { Component } from 'react';
import { Drawer, Progress } from 'antd';
import { connect } from 'react-redux';
// import SurveyResponseDrawer from '../customScreens/surveyResponse';
import SurveyResponseDrawer from './performanceChartDrawerContent';
import { GPT3EnrollSurveys, resetSurveyAnswersGet, startFreeLicense } from '../../store/actions';
import renderHTML from 'react-render-html';
import AugmentAIExpired from '../licenceScreens/commonLicenceScreens/trialExpired/augmentAIDrawerContent';
import AugmentAIExpSoon from '../licenceScreens/commonLicenceScreens/trialExpiredSoon/augmentAIDrawerContent';
import AugmentAIFreeTrail from '../licenceScreens/commonLicenceScreens/trialNotStarted/augmentAIDrawerContent';
import AugmentAILicenseExpired from '../licenceScreens/commonLicenceScreens/licenceExpired/augmentAIDrawerContent';
import { HTMLContent } from '../licenceScreens/commonLicenceScreens/commonSection';

class PerformanceCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResultDrawerOpen: false,
      augmentId: null,
      activekey: 0,
      LicenseComponent: null,
      licenseDrawer: false,
      licenseId: null,
      moduleId: null,
    };
  }

  toggleDrawer = () => {
    this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
  };
  changeAugmentId = id => {
    this.setState({
      augmentId: id,
      activekey: 0,
    });
  };

  onClose = () => {
    this.setState({
      licenseDrawer: false,
    });
  };

  handleStartTrial = () => {
    this.setState({
      licenseDrawer: false,
    });
    let moduleData = {
      License: [{ ModuleId: this.state.moduleId, IsFreeLicense: true }],
    };

    this.props.dispatch(startFreeLicense({ JsonInput: JSON.stringify(moduleData) }));
  };

  componentWillReceiveProps({ AugmentedAIStatusData, isLicenseNotify }) {
    if (AugmentedAIStatusData && AugmentedAIStatusData != this.props.AugmentedAIStatusData) {
      if (AugmentedAIStatusData && Object.keys(AugmentedAIStatusData).length > 0) {
        this.setState({ licenseId: AugmentedAIStatusData.LicenseStatusId });
        if (AugmentedAIStatusData.LicenseStatusId == 1) {
          this.setState({
            LicenseComponent: AugmentAIFreeTrail,
            moduleId: AugmentedAIStatusData.ModuleId,
          });
        } else if (AugmentedAIStatusData.LicenseStatusId == 2) {
          this.setState({
            LicenseComponent: AugmentAIExpSoon,
            moduleId: AugmentedAIStatusData.ModuleId,
          });
        } else if (AugmentedAIStatusData.LicenseStatusId == 3) {
          this.setState({
            LicenseComponent: AugmentAIExpired,
            moduleId: AugmentedAIStatusData.ModuleId,
          });
        } else if (AugmentedAIStatusData.LicenseStatusId == 5) {
          this.setState({
            LicenseComponent: AugmentAILicenseExpired,
            moduleId: AugmentedAIStatusData.ModuleId,
          });
        }
      }
    }
    if (isLicenseNotify && isLicenseNotify != this.props.isLicenseNotify) {
      this.setState({
        licenseDrawer: false,
      });
    }
  }
  render() {
    const {
      FirstName,
      history,
      performaceScores,
      studentId,
      UserGuid,
      AugmentedAIStatusData,
    } = this.props;
    const { LicenseComponent, licenseId, isResultDrawerOpen } = this.state;
    let scoresArray = [
      {
        score: performaceScores && performaceScores.PsychoMetricScore,
        name: 'SPBR',
        strokeColor: '#46b4fe',
        trailColor: '#eff7fe',
        augmentId: performaceScores && performaceScores.LatestPsychoMetricSurveyId,
        trend:
          performaceScores && performaceScores.IsPsychoMetricPositive == true
            ? 'pic-circle-arrow-up'
            : performaceScores.IsPsychoMetricPositive == false
            ? 'pic-circle-arrow-down'
            : null,
      },
      {
        score: performaceScores && performaceScores.FaithScore,
        name: 'Faith',
        strokeColor: '#2fe192',
        trailColor: '#eefdf8',
        augmentId: performaceScores && performaceScores.LatestFaithSurveyId,
        trend:
          performaceScores && performaceScores.IsFaithPositive == true
            ? 'pic-circle-arrow-up'
            : performaceScores.IsFaithPositive == false
            ? 'pic-circle-arrow-down'
            : null,
      },
      {
        score: performaceScores && performaceScores.ASIScore,
        name: 'Addiction',
        strokeColor: '#ff5d5d',
        trailColor: '#fef2f2',
        augmentId: performaceScores && performaceScores.LatestASISurveyId,
        trend:
          performaceScores && performaceScores.IsASIPositive == true
            ? 'pic-circle-arrow-up'
            : performaceScores.IsASIPositive == false
            ? 'pic-circle-arrow-down'
            : null,
      },
      {
        score: performaceScores && performaceScores.GatewayScore,
        name: 'Gateway',
        strokeColor: '#FFBF00',
        trailColor: '#FFF9E5',
        augmentId: performaceScores && performaceScores.LatestGatewaySurveyId,
        trend:
          performaceScores && performaceScores.IsGatewayPositive == true
            ? 'pic-circle-arrow-up'
            : performaceScores.IsGatewayPositive == false
            ? 'pic-circle-arrow-down'
            : null,
      },
    ];
    return (
      <div className="d-flex flex-wrap">
        <div class="col-12 px-0">
          <div class="d-flex">
            <div class="flex-grow-1 mr-2">
              <p
                class="font-weight-normal"
                style={{
                  fontSize: '18px',
                }}
              >
                {FirstName}’s Performance
              </p>
            </div>
            <div class="">
              <button
                class="btn"
                style={{
                  minWidth: '180px',
                }}
                onClick={() => {
                  if (UserGuid) {
                    return this.props.history.push('/subscribers-survery/' + UserGuid);
                  } else {
                    return this.props.history.push('/students-survey/' + studentId);
                  }
                }}
                // disabled={isDischarged}
              >
                Survey Dashboard
              </button>
            </div>
          </div>
        </div>

        {scoresArray &&
          scoresArray.map((item, index) => {
            const { strokeColor, score, trailColor, name, augmentId, trend } = item;
            return (
              <div class="col-6 text-center mb-2 performance-charts">
                <div class={`mb-2 ${trend}`}>
                  <Progress
                    type="circle"
                    strokeWidth={7}
                    strokeColor={strokeColor}
                    width={100}
                    percent={score || 0}
                    format={percent => (
                      <div class="progress-textbox">
                        <strong>{percent}</strong>
                        score
                      </div>
                    )}
                    trailColor={trailColor}
                  />
                </div>
                <div className="d-flex" style={{ justifyContent: 'center' }}>
                  <h5 class="font-weight-semibold mb-0">{name}</h5>
                  <img
                    className="ml-2"
                    onClick={() => {
                      if (licenseId && licenseId != 1 && licenseId != 3 && licenseId != 5) {
                        this.toggleDrawer();
                        this.setState({
                          augmentId: augmentId,
                        });
                      } else {
                        this.setState({
                          licenseDrawer: true,
                        });
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                    src="images/openIcon.svg"
                  />
                </div>
              </div>
            );
          })}
        {isResultDrawerOpen && (
          <Drawer
            className="custom-drawer"
            title="Survey Response"
            placement="right"
            open={isResultDrawerOpen}
            width={800}
            bodyStyle={{ paddingBottom: 80 }}
            closable={true}
            destroyOnClose={true}
            onClose={() => {
              // this.props.dispatch(resetSurveyAnswersGet());
              this.toggleDrawer();
            }}
          >
            <SurveyResponseDrawer
              studentId={studentId}
              toggleDrawer={this.toggleDrawer}
              // ColData={surveyanswersdatacol}
              augmentId={this.state.augmentId}
              changeAugmentId={this.changeAugmentId}
              activekey={this.state.activekey}
              UserGuid={this.props.UserGuid}
            />
          </Drawer>
        )}
        {LicenseComponent && (
          <div className="ai-licence-drawer">
            <LicenseComponent
              open={this.state.licenseDrawer}
              HTMLContent={HTMLContent}
              handleStartTrial={this.handleStartTrial}
              onClose={this.onClose}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ default_values, license_notify_center_admin }) => {
  var canRoleByLicense = false;
  var isLicenseNotify = false;
  if (default_values && default_values.data) {
    canRoleByLicense = default_values.data && default_values.data.IsPurchaseEligible;
  }

  if (
    license_notify_center_admin &&
    license_notify_center_admin.data &&
    license_notify_center_admin.data.success
  ) {
    isLicenseNotify = license_notify_center_admin.data.success;
  }
  return {
    canRoleByLicense,
    isLicenseNotify,
  };
};

export default connect(mapStateToProps)(PerformanceCharts);
