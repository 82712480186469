import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, FieldArray, change } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusPercentageInput,
} from '../../../component/custom-redux-components';
import BuildServiceBundle from './serviceBundleForm/buildServiceBundle';
import { ProcessCombos } from '../../../store/comboConfig';
import {
  centerOverHeadCostGet,
  serviceCodeBundleUpsert,
  resetServiceCodeBundleUpsert,
  serviceCodeBundleGetAll,
  resetServiceCodeBundleGet,
  serviceCodeBundleGet,
  serviceCodeBundleDelete,
} from '../../../store/actions';
import validate from '../../../core/generic-form/validation';
import CustomModal from '../../../core/modal';
import { currencyFormatter, numberFormat } from '../../../util/formatter';
import { getStringType } from '../../../util/multiSelectDropDownStringConverter';
import ReduxForm2 from './serviceBundleForm';

const NewServiceBundle = props => {
  const [centerOverHeadCostState, setCenterOverHeadCostState] = useState(0);
  const [initStateValues, setInitStateValues] = useState({
    TotalOverHead: props.centerOverHeadCost,
    NetMargin: 0,
    ServiceType: [],
    // NoOfServices: 1,
  });
  const [totalCostOfCare, setTotalCostOfCare] = useState(0);
  const [bundleOverheadPecentageValue, setBundleOverheadPecentageValue] = useState(0);
  const [billingRate, setBillingRate] = useState(0);
  const [masterNetMarginPercentage, setMasterNetMarginPercentage] = useState(0);
  const [netMarginAmount, setNetMarginAmount] = useState(0);
  const [serviceCodeInfo, setServiceCodeInfo] = useState([{}]);
  const [indexOfNetMarginChange, setIndexOfNetMarginChange] = useState(null);
  const [currentServiceTypeIndex, setCurrentServiceTypeIndex] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [fieldChanged, setFieldChanged] = useState(null);
  const [modalResData, setModalResData] = useState(null);
  const [avgOverHeadPercentage, setAvgOverHeadPercentage] = useState(0);
  const [avgOverHeadValue, setAvgOverHeadValue] = useState(0);
  const [avgNetMarginPercentage, setAvgNetMarginPercentage] = useState(0);
  const [avgNetMarginValue, setAvgNetMarginValue] = useState(0);
  const [bundleBillingRate, setBundleBillingRate] = useState(0);
  const [lastExistingRows, setLastExistingRows] = useState([]);

  const {
    handleSubmit,
    centerProgramType,
    centerServiceTypeCenter,
    isInserted,
    modalRes,
    existingRows,
    serviceTypeFieldValueSelector,
    // comboServiceCode
  } = props;
  const dispatch = useDispatch();

  const getBundleId = () => {
    var param = new URLSearchParams(props.history.location.search);
    var bundleId = param.get('bundleId');
    if (bundleId) {
      return (bundleId = parseInt(bundleId));
    } else {
      bundleId = -1;
    }
    return bundleId;
  };

  const reGenServiceCode = existingRows => {
    // let temp = [...existingRows];
    let temp = existingRows.map(item => {
      const { BuildServiceBundle } = item || {};
      let newArray =
        BuildServiceBundle &&
        BuildServiceBundle.map(arrayItem => {
          if (arrayItem) {
            const { StandardCost, NetMargin } = arrayItem;
            let tempNetMarginCost = (StandardCost * NetMargin) / 100;
            let tempTotalServiceCode = tempNetMarginCost + StandardCost;
            return {
              ...arrayItem,
              NetMargin: NetMargin,
              NetMarginCost: tempNetMarginCost && tempNetMarginCost.toFixed(2),
              NetAmount: tempNetMarginCost && tempNetMarginCost.toFixed(2),
              TotalServiceCost: tempTotalServiceCode && tempTotalServiceCode.toFixed(2),
              BillingRate: tempTotalServiceCode && tempTotalServiceCode.toFixed(2),
            };
          } else {
            return;
          }
        });
      if (newArray) {
        return {
          ...item,
          BuildServiceBundle: [...newArray],
        };
      } else {
        return {
          ...item,
        };
      }
    });
    return temp;
  };

  useEffect(() => {
    dispatch(
      serviceCodeBundleGet({
        JsonInput: JSON.stringify({
          ServiceCodeBundle: [
            {
              ServiceCodeBundleId: getBundleId(),
            },
          ],
        }),
      }),
    );
    return () => {
      dispatch(resetServiceCodeBundleGet({}));
    };
  }, []);

  const calcMasterNetMarginAmount = (totalCostOfCare, NetMarginValue) => {
    let tempNetMarginAmount = (totalCostOfCare * NetMarginValue) / 100;
    setNetMarginAmount(parseFloat(tempNetMarginAmount && tempNetMarginAmount.toFixed(2)));
    // setInitStateValues({
    //   ...initStateValues,
    //   NetMargin: NetMarginValue,
    // });
  };

  const calcMasterBillingRate = totalCostOfCare => {
    let tempBillngRate = totalCostOfCare + netMarginAmount;
    setBillingRate(tempBillngRate && tempBillngRate.toFixed(2));
  };

  const calcMasterTotalCostOfCare = () => {
    // Total Cost of Care = Total Overhead % of Sum of all billing rate + Sum of all billing rate;
    let arrayOfTotalServiceCostofAllServiceCodes =
      serviceCodeInfo &&
      serviceCodeInfo.length > 0 &&
      Object.keys(serviceCodeInfo[0]).length &&
      serviceCodeInfo
        .filter(item => item != undefined)
        .map((item, index) => {
          const { TotalServiceCost, NoOfServices, StandardCost, BillingRate } = item || {};
          if (TotalServiceCost) {
            return TotalServiceCost * NoOfServices;
          } else {
            // return StandardCost * NoOfServices;
            return BillingRate * NoOfServices;
          }
        });

    let sumOfTotalServiceCostofAllServiceCodes =
      arrayOfTotalServiceCostofAllServiceCodes &&
      arrayOfTotalServiceCostofAllServiceCodes
        .filter(item => !isNaN(item))
        .reduce(
          (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue),
          0,
        );
    let tempBundleOverheadPecentageValue =
      (sumOfTotalServiceCostofAllServiceCodes * centerOverHeadCostState) / 100;
    // set state issue in this line
    setBundleOverheadPecentageValue(tempBundleOverheadPecentageValue);

    let tempMasterTotalCostOfCare =
      sumOfTotalServiceCostofAllServiceCodes +
      (sumOfTotalServiceCostofAllServiceCodes * centerOverHeadCostState) / 100;

    if (
      arrayOfTotalServiceCostofAllServiceCodes &&
      arrayOfTotalServiceCostofAllServiceCodes.some(item => item != undefined)
    ) {
      setTotalCostOfCare(tempMasterTotalCostOfCare.toFixed(2));
      calcMasterBillingRate(parseFloat(tempMasterTotalCostOfCare.toFixed(2)));
    } else {
      setTotalCostOfCare(null);
      calcMasterBillingRate(null);
    }
  };

  useEffect(() => {
    if (props && `${props.NetMarginValue}` && props.NetMarginValue != undefined) {
      calcMasterNetMarginAmount(totalCostOfCare, props.NetMarginValue || 0);
      setMasterNetMarginPercentage(props.NetMarginValue);
    }
  }, [props.NetMarginValue]);

  useEffect(() => {
    if (totalCostOfCare) {
      calcMasterNetMarginAmount(totalCostOfCare, masterNetMarginPercentage || 0);
    }
  }, [totalCostOfCare]);

  useEffect(() => {
    if (totalCostOfCare) {
      calcMasterBillingRate(parseFloat(totalCostOfCare));
    }
  }, [netMarginAmount]);

  useEffect(() => {
    if (props.centerOverHeadCost != null) {
      if (initStateValues.ServiceCodeBundleId) {
        setCenterOverHeadCostState(
          props.totalOverHeadChangeValue != initStateValues.TotalOverHead
            ? props.totalOverHeadChangeValue
            : initStateValues.TotalOverHead,
        );
        // setInitStateValues({
        //   ...initStateValues,
        //   TotalOverHead:
        //     props.totalOverHeadChangeValue != initStateValues.TotalOverHead
        //       ? props.totalOverHeadChangeValue
        //       : initStateValues.TotalOverHead,
        // });
      } else {
        setCenterOverHeadCostState(props.centerOverHeadCost);

        // setInitStateValues({ ...initStateValues, TotalOverHead: props.centerOverHeadCost });
      }
    }
  }, [props.centerOverHeadCost]);

  useEffect(() => {
    if (props.centerOverHeadCost != null) {
      if (initStateValues.ServiceCodeBundleId) {
        setCenterOverHeadCostState(
          props.totalOverHeadChangeValue != initStateValues.TotalOverHead
            ? props.totalOverHeadChangeValue
            : initStateValues.TotalOverHead,
        );
        // setInitStateValues({
        //   ...initStateValues,
        //   TotalOverHead:
        //     props.totalOverHeadChangeValue != initStateValues.TotalOverHead
        //       ? props.totalOverHeadChangeValue
        //       : initStateValues.TotalOverHead,
        // });
      } else {
        setCenterOverHeadCostState(props.totalOverHeadChangeValue);

        // setInitStateValues({
        //   ...initStateValues,
        //   TotalOverHead: props.totalOverHeadChangeValue,
        // });
      }
    }
  }, [props.totalOverHeadChangeValue]);

  //because of below code even if you delete data is coming back
  // above and this function i need to merge in future.
  // useEffect(() => {
  //   setCenterOverHeadCostState(props.totalOverHeadChangeValue);
  //   setInitStateValues({ ...initStateValues, TotalOverHead: props.totalOverHeadChangeValue });
  // }, [props.totalOverHeadChangeValue]);

  useEffect(() => {
    if (centerOverHeadCostState) {
      if (serviceCodeInfo && serviceCodeInfo[0] && Object.keys(serviceCodeInfo[0]).length) {
        calcMasterTotalCostOfCare();
      }
    } else {
      calcMasterTotalCostOfCare();
    }
  }, [centerOverHeadCostState]);

  useEffect(() => {
    if (props.serviceCodeBundleData && Object.keys(props.serviceCodeBundleData).length) {
      let tempInitialValues = props.serviceCodeBundleData;
      tempInitialValues['BuildServiceBundle'] =
        (props.serviceCodeBundleData && props.serviceCodeBundleData.ServiceType) || [];
      let two = tempInitialValues.ServiceType.map(item => {
        return {
          ...item,
          ProgramId: item.CenterServiceTypeCenterId,
          BuildServiceBundle: item.ServiceCodeBundle,
        };
      });
      setInitStateValues({
        ...tempInitialValues,
        ServiceType: two,
      });
      // setServiceCodeInfo(tempInitialValues.BuildServiceBundle);
      let xyz = [];
      two &&
        two.BuildServiceBundle &&
        two.BuildServiceBundle.map(item => {
          xyz = [...xyz, ...item.BuildServiceBundle];
        });
      setServiceCodeInfo(xyz);
      calCostSummary(xyz);
      setTotalCostOfCare(tempInitialValues.TotalCostOfCare);
      setMasterNetMarginPercentage(tempInitialValues.NetMargin);
      setNetMarginAmount(tempInitialValues.NetMarginAmount);
      setBillingRate(tempInitialValues.BillingRate);
      setCenterOverHeadCostState(tempInitialValues.TotalOverHead);
    }
  }, [props.serviceCodeBundleData]);

  useEffect(() => {
    // setInitStateValues({
    //   ...initStateValues,
    //   Description: props.BundleName || '',
    //   DaysInTreatment: props.DaysInTreatment || '',
    //   ProgramId: props.ProgramId || '',
    //   ServiceType: props.ServiceType || null,
    // });
  }, [props.BundleName, props.DaysInTreatment, props.ProgramId, props.ServiceType]);

  useEffect(() => {
    if (props.serviceCodeInfo && props.serviceCodeInfo.ServiceCode) {
      const { ServiceCode, index, serviceTypeIndex } = props.serviceCodeInfo;
      dispatch(
        change(
          'serviceBundle',
          `ServiceType[${serviceTypeIndex}]BuildServiceBundle[${index}]`,
          ServiceCode && ServiceCode[0],
        ),
      );
      //we can remove ServiceType:[] if it is breacking.
      // var serviceTypeInitValues = {
      //   ...initStateValues,
      //   ServiceType: [],
      // };

      // serviceTypeInitValues.ServiceType[serviceTypeIndex].BuildServiceBundle[index] =
      //   ServiceCode[0];

      var updateServiceCodesAtIndexValue = Object.assign(
        {},
        initStateValues.ServiceType &&
          initStateValues.ServiceType[serviceTypeIndex] &&
          initStateValues.ServiceType[serviceTypeIndex].BuildServiceBundle,
      );

      updateServiceCodesAtIndexValue[index] = ServiceCode[0];

      let FirstInitialValues = {
        ...initStateValues,
      };
      const keyToRead = 'BuildServiceBundle';
      if (
        FirstInitialValues.ServiceType.length > 0 &&
        FirstInitialValues.ServiceType[serviceTypeIndex]
      ) {
        if (!(keyToRead in FirstInitialValues.ServiceType[serviceTypeIndex])) {
          // If the key is not present, add it with an array
          FirstInitialValues.ServiceType[serviceTypeIndex]['BuildServiceBundle'] = Object.values(
            updateServiceCodesAtIndexValue,
          );
        } else {
          FirstInitialValues.ServiceType[serviceTypeIndex]['BuildServiceBundle'] = Object.values(
            updateServiceCodesAtIndexValue,
          );
        }
      }

      let xyz = [];
      FirstInitialValues.ServiceType.map(item => {
        xyz = [...xyz, ...item.BuildServiceBundle];
      });
      setServiceCodeInfo(xyz);
      // first time we call calCostSummary
      calCostSummary(xyz);
      // setInitStateValues({
      //   ...FirstInitialValues,
      // });
    }
  }, [props.serviceCodeInfo]);

  useEffect(() => {
    if (serviceCodeInfo && serviceCodeInfo[0] && Object.keys(serviceCodeInfo[0]).length) {
      calcMasterTotalCostOfCare();
    } else {
      //this is added so when you delete everything ,it will remove all data.
      calcMasterTotalCostOfCare();
    }
  }, [serviceCodeInfo]);

  useEffect(() => {
    var result = ProcessCombos(
      'UnitOfMeasurement,CenterProgramType,CenterServiceTypeCenter,DurationFrequency',
    );
    if (result.isProcess) {
      dispatch(result.processItems);
    }
    dispatch(centerOverHeadCostGet({}));
  }, []);

  useEffect(() => {
    if (isInserted) {
      toggle(modalRes);
    }
  }, [isInserted]);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
    setModalResData(modalRes);
  };

  const modifiyServiceType = ServiceType => {
    const tempServiceType = ServiceType.map(item => {
      const { ProgramId, BuildServiceBundle } = item;

      return {
        CenterServiceTypeCenterId: ProgramId,
        ServiceCodeBundle:
          BuildServiceBundle &&
          BuildServiceBundle.map(Item => {
            const {
              BundleServiceCodeId,
              StandardCost,
              TotalServiceCost,
              BillingRate,
              NetMarginCost,
              NetAmount,
            } = Item;
            return {
              ...Item,
              BundleServiceCodeId: BundleServiceCodeId ? BundleServiceCodeId : null,
              TotalCostOfCare: StandardCost,
              BillingRate: TotalServiceCost ? TotalServiceCost : BillingRate,
              NetAmount: NetMarginCost ? NetMarginCost : NetAmount,
            };
          }),
      };
    });

    return tempServiceType;
  };
  const onSaveClick = values => {
    const {
      ServiceType,
      DaysInTreatment,
      Description,
      NetMargin,
      // NoOfServices,
      ProgramId,
      TotalOverHead,
      FrequencyId,
    } = values;
    const data = {
      ServiceCodeBundle: [
        {
          ServiceCodeBundleId: (initStateValues && initStateValues.ServiceCodeBundleId) || null,
          // ProgramId: getStringType(ProgramId),
          Description,
          DaysInTreatment,
          NetMarginAmount: netMarginAmount,
          TotalOverHead,
          TotalCostOfCare: totalCostOfCare,
          BillingRate: billingRate,
          NetMargin,
          NetMarginCost: netMarginAmount,
          FrequencyId,
          ServiceType: modifiyServiceType(ServiceType),
        },
      ],
    };

    let Data = {
      JsonInput: JSON.stringify(data),
    };

    dispatch(serviceCodeBundleUpsert(Data));
  };

  const onChange = key => {
    console.log(key);
  };

  useEffect(() => {
    if (existingRows && JSON.stringify(existingRows) != JSON.stringify(lastExistingRows)) {
      let isExistingDefind =
        existingRows &&
        existingRows.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });

      let reGenServiceCodeArray;
      if (fieldChanged == 'ProgramId' || fieldChanged == 'ServiceCodeId' || fieldChanged == null) {
        reGenServiceCodeArray = existingRows;
      } else {
        reGenServiceCodeArray = reGenServiceCode(existingRows);
      }

      if (isExistingDefind) {
        let xyz = [];

        reGenServiceCodeArray &&
          reGenServiceCodeArray.map(item => {
            xyz = [...xyz, ...item.BuildServiceBundle];
          });
        let checkUndefindValues = xyz.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });
        if (checkUndefindValues) {
          setServiceCodeInfo(xyz);
          calCostSummary(xyz);
        }
        dispatch(change('serviceBundle', `ServiceType`, reGenServiceCodeArray));
        // setInitStateValues({
        //   ...initStateValues,
        //   ServiceType: reGenServiceCodeArray,
        // });
      }
      setLastExistingRows(existingRows);
    }
  }, [existingRows]);

  const calAvgOverheadPercentage = serviceCodeArray => {
    //Cal total no of services in bundle
    let NoOfServicesArray =
      serviceCodeArray.length > 0 && serviceCodeArray.map(item => item.NoOfServices);

    let countOfServices =
      NoOfServicesArray.length > 0 &&
      NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);

    //Cal sum of overhead percentage for all service code inclunded in bundle.
    let serviceLevelOverHeadCostPercentageArray = serviceCodeArray.map(
      item => item.NoOfServices * item.OverHeadCostPercentage,
    );

    let sumOfServiceLevelOverHeadCostPercentageArray =
      serviceLevelOverHeadCostPercentageArray.length > 0 &&
      serviceLevelOverHeadCostPercentageArray.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );

    // Cal avg overhead percentage for the bundle.
    let AvgOverheadPercentage = sumOfServiceLevelOverHeadCostPercentageArray / countOfServices;
    if (isNaN(parseFloat(AvgOverheadPercentage.toFixed(2)))) {
      return 0;
    } else {
      return parseFloat(AvgOverheadPercentage.toFixed(2));
    }
  };

  const calAvgOverheadValue = serviceCodeArray => {
    //Cal total no of services in bundle
    let NoOfServicesArray = serviceCodeArray.map(item => item.NoOfServices);

    let countOfServices =
      NoOfServicesArray.length > 0 &&
      NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);
    //Cal sum of overhead percentage for all service code inclunded in bundle.
    let serviceLevelOverHeadCostArray = serviceCodeArray.map(
      item => item.NoOfServices * item.OverHeadCost,
    );
    let sumOfServiceLevelOverHeadCostArray =
      serviceLevelOverHeadCostArray.length > 0
        ? serviceLevelOverHeadCostArray.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
          )
        : 0;
    // Cal avg overhead percentage for the bundle.
    // let AvgOverheadCost = sumOfServiceLevelOverHeadCostArray / countOfServices;

    // Ticket 5756=>Commenting above code because now requirement got change to show total.
    //this is not average anymore but i am not changing the name of the variable.

    let AvgOverheadCost = sumOfServiceLevelOverHeadCostArray;

    if (isNaN(parseFloat(AvgOverheadCost.toFixed(2)))) {
      return 0;
    } else {
      return parseFloat(AvgOverheadCost.toFixed(2));
    }
  };

  const calAvgNetMarginPercentage = serviceCodeArray => {
    //Cal total no of services in bundle
    let NoOfServicesArray = serviceCodeArray.map(item => item.NoOfServices);

    let countOfServices =
      NoOfServicesArray.length > 0 &&
      NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);

    //Cal sum of overhead percentage for all service code inclunded in bundle.
    let serviceLevelNetMarginPercentageArray = serviceCodeArray.map(
      item => item.NoOfServices * item.NetMargin,
    );

    let sumOfServiceLevelNetMarginPercentageArray =
      serviceLevelNetMarginPercentageArray.length > 0 &&
      serviceLevelNetMarginPercentageArray.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
      );

    // Cal avg overhead percentage for the bundle.
    let AvgNetMarginPercentage = sumOfServiceLevelNetMarginPercentageArray / countOfServices;

    if (isNaN(parseFloat(AvgNetMarginPercentage.toFixed(2)))) {
      return 0;
    } else {
      return parseFloat(AvgNetMarginPercentage.toFixed(2));
    }
  };

  const calAvgNetMarginValue = serviceCodeArray => {
    //Cal total no of services in bundle
    let NoOfServicesArray = serviceCodeArray.map(item => item.NoOfServices);

    let countOfServices =
      NoOfServicesArray.length > 0 &&
      NoOfServicesArray.reduce((previousValue, currentValue) => previousValue + currentValue);

    //Cal sum of overhead percentage for all service code inclunded in bundle.
    let serviceLevelStandardCostArray = serviceCodeArray.map(
      item => item.NoOfServices * item.StandardCost,
    );
    let sumOfServiceLevelStandardCostArray =
      serviceLevelStandardCostArray.length > 0
        ? serviceLevelStandardCostArray.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
          )
        : 0;


    // Cal avg NetMargin percentage for the bundle.
    // let AvgNetMarginValue =
    //   (sumOfServiceLevelStandardCostArray / countOfServices) *
    //   parseFloat(calAvgNetMarginPercentage(serviceCodeArray) / 100);

    // Ticket 5756=>Commenting above code because now requirement got change to show total.
    //this is not average anymore but i am not changing the name of the variable.

    let serviceLevelNetMarginCostArray = serviceCodeArray.map(
      item => {
        if (item.hasOwnProperty('NetMarginCost')) {
          return item.NetMarginCost * item.NoOfServices;
        } else {
          return item.NetAmount * item.NoOfServices;
        }
      },
    );


    let AvgNetMarginValue =
      serviceLevelNetMarginCostArray.length > 0
        ? serviceLevelNetMarginCostArray.reduce(
            (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue),
          )
        : 0;
    if (isNaN(parseFloat(AvgNetMarginValue && AvgNetMarginValue.toFixed(2)))) {
      return 0;
    } else {
      return parseFloat(AvgNetMarginValue && AvgNetMarginValue.toFixed(2));
    }
  };

  const calBundleBillingRate = serviceCodeArray => {
    let BillingRateArray = serviceCodeArray.map(item => {
      if (item.TotalServiceCost) {
        return item.NoOfServices * parseFloat(item.TotalServiceCost);
      } else {
        return item.NoOfServices * parseFloat(item.BillingRate);
      }
    });

    let sumOfAllBillingRate =
      BillingRateArray.length > 0 &&
      BillingRateArray.reduce((previousValue, currentValue) => previousValue + currentValue);

    return sumOfAllBillingRate && parseFloat(sumOfAllBillingRate);
  };

  const calCostSummary = serviceCodeArray => {
    //     let checkUndefindValues = serviceCodeArray.every(item => {
    //       if (item != undefined) {
    //         if (Object.keys(item).length > 1) {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       }
    //     });

    // if (serviceCodeArray  && checkUndefindValues) {

    // }
    setAvgOverHeadPercentage(calAvgOverheadPercentage(serviceCodeArray));
    //Comment below line if you want to old Total Overhead % Value
    // setCenterOverHeadCostState(tempAvgOverheadPercentage);
    setAvgOverHeadValue(calAvgOverheadValue(serviceCodeArray));
    setAvgNetMarginPercentage(calAvgNetMarginPercentage(serviceCodeArray));
    setAvgNetMarginValue(calAvgNetMarginValue(serviceCodeArray));
    setBundleBillingRate(calBundleBillingRate(serviceCodeArray));
  };

  return (
    <>
      <div className="container-fluid">
        <ReduxForm2
          initialValues={initStateValues}
          totalCostOfCare={totalCostOfCare}
          setTotalCostOfCare={setTotalCostOfCare}
          onSaveClick={onSaveClick}
          centerProgramType={centerProgramType}
          centerServiceTypeCenter={centerServiceTypeCenter}
          netMarginAmount={netMarginAmount}
          billingRate={billingRate}
          setIndexOfNetMarginChange={setIndexOfNetMarginChange}
          setCurrentServiceTypeIndex={setCurrentServiceTypeIndex}
          centerOverHeadCostState={centerOverHeadCostState}
          bundleOverheadPecentageValue={bundleOverheadPecentageValue}
          isFetchingBundleUpsert={props.isFetchingBundleUpsert}
          existingRows={existingRows}
          setFieldChanged={setFieldChanged}
          avgOverHeadPercentage={avgOverHeadPercentage}
          avgOverHeadValue={avgOverHeadValue}
          avgNetMarginPercentage={avgNetMarginPercentage}
          avgNetMarginValue={avgNetMarginValue}
          bundleBillingRate={bundleBillingRate}
        />
        <CustomModal
          isOpen={isModelOpen}
          // header={
          //   initStateValues && initStateValues.ServiceCodeBundleId
          //     ? 'Service Bundle updated Successfully'
          //     : 'Service Bundle added Successfully'
          // }
          header={(modalResData && modalResData.Msg) || ''}
          yesAction={() => {
            dispatch(serviceCodeBundleGetAll({}));
            dispatch(resetServiceCodeBundleUpsert());
            toggle();
            if (modalResData.flag == false) {
              if (modalResData.OldServiceCodeBundleId) {
                props.history.push(
                  `/cw/manage/service-bundle?bundleId=${modalResData.OldServiceCodeBundleId}`,
                );
                dispatch(
                  serviceCodeBundleGet({
                    JsonInput: JSON.stringify({
                      ServiceCodeBundle: [
                        {
                          ServiceCodeBundleId: modalResData.OldServiceCodeBundleId,
                        },
                      ],
                    }),
                  }),
                );
                dispatch(
                  serviceCodeBundleDelete({
                    JsonInput: JSON.stringify({
                      ServiceCode: [
                        {
                          ServiceCodeBundleId: modalResData.OldServiceCodeBundleId,
                          IsArchived: 0,
                        },
                      ],
                    }),
                  }),
                );
              } else {
                props.history.push('/cw/manage/master-bill-services?tab=service-bundles');
              }
            } else {
              props.history.push('/cw/manage/master-bill-services?tab=service-bundles');
            }

            // this.props.dispatch(reset('serviceCodeForm'));
            // this.resetcodeIdandModifiers();
            // this.setState({
            //   ServiceCodeInfo: {},
            // });
          }}
          yesText={
            modalResData && modalResData.flag == false
              ? modalResData.OldServiceCodeBundleId
                ? 'Restore and Edit'
                : 'OK'
              : 'OK'
          }
          noText="Cancel"
          noAction={() => {
            toggle();
            dispatch(resetServiceCodeBundleUpsert());
          }}
          hideCancel={true}
        />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const {
    combos,
    get_service_code,
    center_over_head_cost_get,
    service_code_bundle_upsert,
    service_code_bundle_get,
  } = state;
  const { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode } = get_service_code;
  const { isFetching: isFetchingBundleUpsert } = service_code_bundle_upsert;
  let centerProgramType = [];
  let centerServiceTypeCenter = [];
  let serviceCodeInfo = {};
  let centerOverHeadCost = null;
  let NetMarginValue = null;
  let NetMarginPercetangeValue = null;
  let updatedNoOfServicesRequired = null;
  let isInserted = false;
  let serviceCodeBundleData = null;
  let BundleName = null;
  let DaysInTreatment = null;
  let ProgramId = null;
  let totalOverHeadChangeValue = null;
  let serviceTypeFieldValueSelector = null;
  let modalRes = null;

  if (
    service_code_bundle_upsert &&
    service_code_bundle_upsert.data &&
    service_code_bundle_upsert.data.success
  ) {
    const { Msg, flag, OldServiceCodeBundleId } =
      service_code_bundle_upsert.data &&
      service_code_bundle_upsert.data.data &&
      service_code_bundle_upsert.data.data[0];
    modalRes = { Msg, flag, OldServiceCodeBundleId };
    isInserted = true;
  }

  if (combos.data) {
    const { CenterProgramType, CenterServiceTypeCenter, Center } = combos.data;
    if (CenterProgramType) {
      centerProgramType = CenterProgramType;
    }
    if (CenterServiceTypeCenter) {
      centerServiceTypeCenter = CenterServiceTypeCenter;
    }
  }
  if (serviceCodeinfoData && serviceCodeinfoData.ServiceCode) {
    // serviceCodeInfo = serviceCodeinfoData.ServiceCode[0];
    serviceCodeInfo = serviceCodeinfoData;
  }
  if (center_over_head_cost_get && center_over_head_cost_get.data) {
    centerOverHeadCost = center_over_head_cost_get.data.CenterOverHead[0].OverHeadValue;
  }
  if (service_code_bundle_get && service_code_bundle_get.data) {
    serviceCodeBundleData =
      service_code_bundle_get.data.ServiceCodeBundle &&
      service_code_bundle_get.data.ServiceCodeBundle[0];
  }
  BundleName = formValueSelector('serviceBundle')(state, 'Description');
  DaysInTreatment = formValueSelector('serviceBundle')(state, 'DaysInTreatment');
  ProgramId = formValueSelector('serviceBundle')(state, 'ProgramId');
  NetMarginValue = formValueSelector('serviceBundle')(state, 'NetMargin');
  NetMarginPercetangeValue = formValueSelector('serviceBundle')(state, 'BuildServiceBundle');
  updatedNoOfServicesRequired = formValueSelector('serviceBundle')(state, 'BuildServiceBundle');
  totalOverHeadChangeValue = formValueSelector('serviceBundle')(state, 'TotalOverHead');
  serviceTypeFieldValueSelector = formValueSelector('serviceBundle')(state, 'ServiceType');

  return {
    centerProgramType,
    centerServiceTypeCenter,
    serviceCodeInfo,
    centerOverHeadCost,
    NetMarginValue,
    NetMarginPercetangeValue,
    updatedNoOfServicesRequired,
    serviceCodeBundleData,
    BundleName,
    DaysInTreatment,
    ProgramId,
    totalOverHeadChangeValue,
    isFetchingBundleUpsert,
    existingRows: formValueSelector('serviceBundle')(state, 'ServiceType'),
    serviceTypeFieldValueSelector,
    isInserted,
    modalRes,
  };
};

const ReduxNewServiceBundle = connect(mapStateToProps)(withRouter(NewServiceBundle));

export default ReduxNewServiceBundle;
