import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { BGContent } from '../commonSection';
import { startFreeLicense, userFeatureFlagGet } from '../../../../store/actions';
const labels = [
  'Active/Inactive Clients',
  'Referal Sources',
  'Time In Treatment',
  'Demographic Profile',
  'Mapping',
  'Score Improvement',
  'SP Behavioral Ratings',
  'Standard Cost of Care',
  'Service Units Delivered',
  'Trigger Location Activity',
];

const PreIntake = props => {
  const { StatusData, dispatch, isLicenseStart } = props;

  useEffect(() => {
    if (isLicenseStart) {
      dispatch(userFeatureFlagGet({}));
    }
  }, [isLicenseStart]);

  const handleStartTrial = () => {
    let moduleData = {
      License: [{ ModuleId: StatusData.ModuleId, IsFreeLicense: true }],
    };
    dispatch(startFreeLicense({ JsonInput: JSON.stringify(moduleData) }));
  };

  return (
    <>
      <div
        className="row bg-white p-4 m-1 mt-5"
        style={{
          borderRadius: '12px',
          backgroundImage: `url("images/license/bg_white.svg")`,
          backgroundPosition: 'upset',
        }}
      >
        <div className="col-12 h5">Prospect</div>
        <div className="col-12">
          <div className="row">
            {' '}
            <div className="col-6">
              Prospect feature license allows counselors and centers to create leads for centers
              and filter patients who are fit for the center's treatment. This is a great tool for
              those in the counseling or mental health field as it can help to ensure that patients
              are getting the care that they need.
            </div>
            <div className="col-2"></div>
            <div className="col-4">
              <div className="d-flex align-item-center justify-content-center">
                <button className="btn" onClick={handleStartTrial} style={{ cursor: 'pointer' }}>
                  Start your 14-day trial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BGContent
        moduleName={'Prospect'}
        backGroundImage={`images/license/preIntakeBG.svg`}
        moduleLogoImage={`images/license/preIntake.svg`}
        labelTitle={'What you will get :'}
        labels={labels}
        tagLine={
          "Prospect feature license allows counselors and centers to create leads for centers and filter patients who are fit for the center's treatment. This is a great tool for those in the counseling or mental health field as it can help to ensure that patients are getting the care that they need."
        }
        trialButtonText={'Start your 14-day trial'}
        handleStartTrial={handleStartTrial}
        buyLinkText={'Purchase Prospect License'}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { user_feature_flag_get, start_free_license } = state;
  const { data, isFetching, error } = start_free_license;

  let isLicenseStart = false;

  if (data && data.success) {
    isLicenseStart = data.success;
  }
  return {
    isLicenseStart,
  };
};

export default connect(mapStateToProps)(PreIntake);
