import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../component/custom-redux-components';
import { loadLocationAuthHistory } from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';

class LocationHistory extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(loadLocationAuthHistory({ UserGuid: this.props.UserGuid }));
  }

  render() {
    const { onOk, onCancel, isFetching, locationHistory, LocationServices, Name } = this.props;
    const columns = [
      {
        title: 'Location Last Updated',
        dataIndex: 'LocationAuthorizationLastUpdated',
        key: 'LocationAuthorizationLastUpdated',
        sorter: (a, b) =>
          isDateComparer(
            a.LocationAuthorizationLastUpdatedForSort,
            b.LocationAuthorizationLastUpdatedForSort,
          ),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Location Status',
        dataIndex: 'LocationAuthorizationStatus',
        key: 'LocationAuthorizationStatus',
        sorter: (a, b) => isComparer(a.LocationAuthorizationStatus, b.LocationAuthorizationStatus),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Trigger Notification',
        dataIndex: 'TriggerNotificationDateTime',
        key: 'TriggerNotificationDateTime',
        sorter: (a, b) =>
          isDateComparer(
            a.TriggerNotificationDateTimeForSort,
            b.TriggerNotificationDateTimeForSort,
          ),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return (
      <div class="modal-content border-0">
        <div class="modal-header  flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 mb-3" style={{ textAlign: 'left' }}>
            Location Status History
          </h4>
          {/* <p className="text-center">
            Make changes to the case details of <br />
            <strong>{'Name'}</strong>
          </p> */}
          <div className="ml-3">
            <strong className="mr-2">{Name}</strong>
            <span style={{ color: '#2FE192', fontWeight: 'bold' }}>
              <i class="fas fa-map-marker-alt"></i> {LocationServices}
            </span>
          </div>
        </div>

        <div class="modal-body col-md-12 px-4 enrollment-form pb-0">
          <Loader2 loading={isFetching} />

          <div class="row">
            <div class="col-12">
              <Loader2 loading={isFetching} />
              <Table
                columns={columns}
                dataSource={locationHistory}
                size={'middle'}
                pagination={{
                  itemRender: (page, type, originalElement) => {
                    return (
                      <div class="dataTables_wrapper no-footer">
                        <div class="dataTables_paginate paging_simple_numbers">
                          {type == 'prev' ? (
                            <a class="paginate_button previous">Previous</a>
                          ) : type == 'next' ? (
                            <a class="paginate_button next">Next</a>
                          ) : (
                            <a class="paginate_button ">{page}</a>
                          )}
                        </div>
                      </div>
                    );
                  },
                  showSizeChanger: true,
                  // hideOnSinglePage: true,
                }}
              />
            </div>
          </div>
          <div class="modal-footer justify-content-center border-top-0 w-100">
            <input
              type="button"
              class="btn btn-outline-primary m-2"
              value="Close"
              onClick={onCancel}
            />
            {/* <input
              type="submit"
              class="btn black-btn font-weight-bold mr-2"
              value=" Update Changes  "
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { load_location_auth_history } = state;
  const { data, isFetching } = load_location_auth_history;

  let locationHistory = null;

  if (data && data) {
    locationHistory = data.LocationAuthorizationHistory || [];
  }

  return {
    locationHistory,
    isFetching: isFetching,
  };
};

export default connect(mapStateToProps)(LocationHistory);
