import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* addLicenses(action) {
  try {
    const { payload } = action;
    const response = yield API.addLicenses(payload);
    yield put({
      type: ActionTypes.ADD_LICENSES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_LICENSES_FAILURE,
    });
  }
}

export function* startFreeLicense(action) {
  try {
    const { payload } = action;
    const response = yield API.startFreeLicense(payload);
    yield put({
      type: ActionTypes.START_FREE_LICENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.START_FREE_LICENSE_FAILURE,
    });
  }
}
export function* getStripeApiKey(action) {
  try {
    const { payload } = action;
    const response = yield API.getStripeApiKey(payload);
    yield put({
      type: ActionTypes.GET_STRIPE_API_KEY_SUCCESS,
      payload: response.data,
      // payload: JSON.parse(response.data.stripeKey),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STRIPE_API_KEY_FAILURE,
    });
  }
}
export function* getPaymentInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.getPaymentInfo(payload);
    let centerId = parseInt(localStorage.getItem('centerId'));
    if (!response.data.result && centerId) {
      yield put({
        type: ActionTypes.CENTER_GET_REQUEST,
        payload: { CenterId: centerId },
      });
    }
    yield put({
      type: ActionTypes.GET_PAYMENT_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PAYMENT_INFO_FAILURE,
    });
  }
}
export function* isAchValidation(action) {
  try {
    const { payload } = action;
    const response = yield API.isAchValidation(payload);
    yield put({
      type: ActionTypes.IS_ACH_VALIDATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.IS_ACH_VALIDATION_FAILURE,
    });
  }
}
export function* achValidate(action) {
  try {
    const { payload } = action;
    const response = yield API.achValidate(payload);
    yield put({
      type: ActionTypes.ACH_VALIDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ACH_VALIDATE_FAILURE,
    });
  }
}
export function* getLicensesInvoice(action) {
  try {
    const { payload } = action;
    const response = yield API.getLicensesInvoice(payload);
    yield put({
      type: ActionTypes.GET_LICENSES_INVOICE_SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LICENSES_INVOICE_FAILURE,
    });
  }
}
export function* getLicensesReceipt(action) {
  try {
    const { payload } = action;
    const response = yield API.getLicensesReceipt(payload);
    yield put({
      type: ActionTypes.GET_LICENSES_RECEIPT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LICENSES_RECEIPT_FAILURE,
    });
  }
}
export function* getBillingInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.getBillingInfo(payload);
    yield put({
      type: ActionTypes.GET_BILLING_INFO_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BILLING_INFO_FAILURE,
    });
  }
}
export function* updatePaymentMethode(action) {
  try {
    const { payload } = action;
    const response = yield API.updatePaymentMethode(payload);
    yield put({
      type: ActionTypes.UPDATE_PAYMENT_METHODE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_PAYMENT_METHODE_FAILURE,
    });
  }
}

export function* licenseNotifyCenterAdmin(action) {
  try {
    const { payload } = action;
    const response = yield API.licenseNotifyCenterAdmin(payload);
    yield put({
      type: ActionTypes.LICENSE_NOTIFY_CENTER_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LICENSE_NOTIFY_CENTER_ADMIN_FAILURE,
    });
  }
}

export function* centerTokenAssignUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.centerTokenAssignUpdate(payload);
    yield put({
      type: ActionTypes.CENTER_TOKEN_ASSIGN_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_TOKEN_ASSIGN_UPDATE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.ADD_LICENSES_REQUEST, addLicenses),
    takeLatest(ActionTypes.START_FREE_LICENSE_REQUEST, startFreeLicense),
    takeLatest(ActionTypes.GET_STRIPE_API_KEY_REQUEST, getStripeApiKey),
    takeLatest(ActionTypes.GET_PAYMENT_INFO_REQUEST, getPaymentInfo),
    takeLatest(ActionTypes.IS_ACH_VALIDATION_REQUEST, isAchValidation),
    takeLatest(ActionTypes.ACH_VALIDATE_REQUEST, achValidate),
    takeLatest(ActionTypes.GET_LICENSES_INVOICE_REQUEST, getLicensesInvoice),
    takeLatest(ActionTypes.GET_LICENSES_RECEIPT_REQUEST, getLicensesReceipt),
    takeLatest(ActionTypes.GET_BILLING_INFO_REQUEST, getBillingInfo),
    takeLatest(ActionTypes.UPDATE_PAYMENT_METHODE_REQUEST, updatePaymentMethode),
    takeLatest(ActionTypes.LICENSE_NOTIFY_CENTER_ADMIN_REQUEST, licenseNotifyCenterAdmin),
    takeLatest(ActionTypes.CENTER_TOKEN_ASSIGN_UPDATE_REQUEST, centerTokenAssignUpdate),
  ]);
}
