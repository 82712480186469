import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import CustomModal from '../../../core/modal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { isComparer, isDateComparer } from '../../../util/handler';
import { stringDate } from '../../../util/date';
import AntdModal from '../../../Components/CustomModal';
import {
  noteTypeUpsert,
  userTagTypeInsert,
  resetUserTagTypeInsert,
  userTagTypeListGet,
  userTagTypeUpdate,
  resetUserTagTypeUpdate,
  getNoteTypeArchive,
  getNoteTypeList,
} from '../../../store/actions';
import { ReduxSelect } from '../../../component/custom-redux-components';
import SubmitButton from '../../../Components/submitButton';
import PageTitle from '../../../Components/PageTitle';

class NoteType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTag: '',
      filterBy: 1,
      isModelOpen: false,
      header: '',
      deleteAction: '',
    };
  }
  componentDidMount() {
    let data = { UserTagType: { IsArchived: false } };
    this.props.dispatch(userTagTypeListGet({ JsonInput: JSON.stringify(data) }));
  }

  componentWillReceiveProps({ isUserTagAdded, isDeleteNodeType, operation, isSuccess }) {
    if (
      (isUserTagAdded && isUserTagAdded != this.props.isUserTagAdded) 
    ) {
      this.setState({ userTag: '' });
      this.toggle('User Tag Added Successfully');
      this.props.dispatch(resetUserTagTypeInsert());
      let data = { UserTagType: { IsArchived: false } };
      this.props.dispatch(userTagTypeListGet({ JsonInput: JSON.stringify(data) }));
    }

    if (
      isSuccess && isSuccess != this.props.isSuccess
    ) {
      this.setState({ userTag: '', deleteAction: '', filterBy: 1 });
      this.toggle(`User Tag ${this.state.deleteAction} Successfully`);
      this.props.dispatch(resetUserTagTypeUpdate());
      let data = { UserTagType: { IsArchived: false } };
      this.props.dispatch(userTagTypeListGet({ JsonInput: JSON.stringify(data) }));
    }
   
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const { userTag, filterBy } = this.state;
    const { archivedData, userTags, isListFetching } = this.props;

    const tagColumns = [
      {
        title: 'User Tags',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        // sorter: (a, b) => isComparer(a.Title, b.Title),
        // sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => (
          <div class="tools-grid">
            {record.IsActionAllowed && (
              <div className="">
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      class="delete-btn"
                      onClick={onClick}
                      data-toggle="modal"
                      data-target="#delete-Screen-Tool"
                      style={{ cursor: 'pointer' }}
                    >
                      {record.IsArchived ? (
                        <img src="images/restore_icon.svg" width="85" />
                      ) : (
                        <Tooltip placement="top" title="Archive">
                          <img src="images/archive.svg" width="25" />
                        </Tooltip>
                      )}

                      {/* <i class="far fa-trash-alt"></i> */}
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          {record.IsArchived
                            ? 'Are you sure you want to restore this User Tag?'
                            : 'Are you sure you want to archive this User Tag?'}
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn btn-outline-primary black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            this.props.dispatch(
                              userTagTypeUpdate({
                                JsonInput: JSON.stringify({
                                  UserTagType: [
                                    {
                                      UserTagTypeId: record.Value,
                                      IsExcluded: this.state.filterBy == 1 ? true : false,
                                    },
                                  ],
                                }),
                              }),
                            );
                            this.setState({
                              deleteAction: this.state.filterBy == 1 ? 'archived' : 'restored',
                            });
                            // this.state.deleteAction = 'archived';
                            onOk();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        ),
      },
    ];
    const archivedNoteColumns = [
      {
        title: 'User Tags Archived',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        // sorter: (a, b) => isComparer(a.Title, b.Title),
        // sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => (
          <div class="tools-grid">
            {record.IsActionAllowed && (
              <div className="">
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      class="delete-btn"
                      onClick={onClick}
                      data-toggle="modal"
                      data-target="#delete-Screen-Tool"
                      style={{ cursor: 'pointer' }}
                    >
                      <img src="images/restore_icon.svg" width="85" />
                      {/* <i class="far fa-trash-alt"></i> */}
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to restore this User Tag?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn btn-outline-primary black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            this.props.dispatch(
                              userTagTypeUpdate({
                                JsonInput: JSON.stringify({
                                  UserTagType: [
                                    {
                                      UserTagTypeId: record.Value,
                                      IsExcluded: true,
                                    },
                                  ],
                                }),
                              }),
                            );
                            this.state.deleteAction = 'restored';

                            onOk();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        ),
      },
    ];
    return (
      <div>
        <PageTitle TitleText="Miscellaneous" />
        <div class="content-area  setting-gap pl-0">
          <div class="mb-3">
            <h6>User Tags</h6>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="enrollment-form">
                <div class="form-group">
                  <input
                    className={`form-control ${userTag && 'not-empty'}`}
                    value={userTag}
                    onChange={e => this.setState({ userTag: e.target.value })}
                  />
                  <label className="placeholder-label">User Tags</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              {/* <button
                type="button"
                class="btn px-5"
                disabled={!note}
                onClick={() => {
                  this.props.dispatch(addNoteType({ Note: note }));
                }}
              >
                Add
              </button> */}
              <SubmitButton
                loading={this.props.isNoteUpsert}
                onClick={() => {
                  // this.props.dispatch(addNoteType({ Note: note }));
                  let data = { UserTagType: { Description: userTag } };
                  this.props.dispatch(userTagTypeInsert({ JsonInput: JSON.stringify(data) }));
                }}
                disabled={!userTag.trim()}
                value={'Add'}
                style={{ height: '45px', padding: '0 45px' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <div className="text-left app-subscriber-page d-inline-block">
              <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                <ReduxSelect
                  options={[
                    { Text: 'Active', Value: 1 },
                    { Text: 'Archived', Value: 2 },
                  ]}
                  onChange={val => {
                    this.setState({
                      filterBy: val,
                    });

                    let data = { UserTagType: { IsArchived: val == 1 ? false : true } };
                    this.props.dispatch(userTagTypeListGet({ JsonInput: JSON.stringify(data) }));
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterBy}
                  placeholder="Filter By"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 mb-5">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12">
                  <Loader2 loading={isListFetching} />
                  <Table
                    columns={tagColumns}
                    dataSource={userTags}
                    //   onRow={(record, rowIndex) => {
                    //     return {
                    //       onClick: event => this.onRowClick(record.AnnouncementId),
                    //     };
                    //   }}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div class="dataTables_wrapper no-footer">
                            <div class="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a class="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a class="paginate_button next">Next</a>
                              ) : (
                                <a class="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      // hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToPropsNoteType = state => {
  const {
    add_note_type,
    delete_note_type,
    user_tag_type_list_get,
    get_note_type_archive,
    user_tag_type_insert,
    user_tag_type_update,
  } = state;
  const { data, isFetching } = get_note_type_archive;
  const { data: userTagUpsertData, isFetching: isNoteUpsert, error } = user_tag_type_insert;
  const { data: userTagData, isFetching: isListFetching } = user_tag_type_list_get;
  const { data: userTagUpdateData } = user_tag_type_update;

  let isUserTagAdded = false;
  let isDeleteNodeType = false;
  let userTags = [];
  let archivedData = [];
  let operation = null;
  let isSuccess = null;
  if (userTagData && userTagData.UserTagType) {
    userTags = userTagData.UserTagType;
  }
  if (userTagUpsertData && userTagUpsertData.success == true) {
    isUserTagAdded = userTagUpsertData.success;
  }
   if (userTagUpdateData && userTagUpdateData.success) {
     isSuccess = userTagUpdateData.success;
   }
  return {
    isUserTagAdded,
    operation,
    error,
    isDeleteNodeType,
    userTags,
    archivedData: archivedData || [],
    isNoteUpsert,
    isListFetching,
    isSuccess,
  };
};
const ReduxNoteType = connect(mapStateToPropsNoteType)(withRouter(NoteType));

export default connect()(ReduxNoteType);
