import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { labOrderLabelGet } from '../store/actions';
import { Loader2 } from '../Components/Loader';

class LabLabelTest extends Component {
  constructor(props) {
    super(props);
  }

  getLabOrderId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var labOrderId = param.get('lab-order-id');
    if (labOrderId) {
      labOrderId = parseInt(labOrderId);
    } else {
      labOrderId = null;
    }
    return labOrderId;
  }

  componentDidMount() {
    this.props.dispatch(
      labOrderLabelGet({
        Json: JSON.stringify({
          LabOrder: [
            {
              LabOrderId: this.getLabOrderId(),
            },
          ],
        }),
      }),
    );
  }

    componentWillReceiveProps({ downloadUrl }) {
        if (downloadUrl && downloadUrl != this.props.downloadUrl) {
            // window.print();
            setTimeout(window.print, 3000);
        }
  }
  render() {
    return (
      <div
        style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {/* <DocViewerComponent file={this.props.downloadUrl} type={'png'} /> */}
        <Loader2 loading={this.props.isFetching} />
        <img src={`${this.props.downloadUrl}`} />
      </div>
    );
  }
}


const mapStateToProps = ({ lab_order_label_get }) => {
  const { data: LabTestLabel, isFetching } = lab_order_label_get;

  let downloadUrl;
  if (LabTestLabel && LabTestLabel.result) {
    downloadUrl = LabTestLabel.result.labelUrl;
  }
  return {
    downloadUrl,
    isFetching,
  };
};

export default connect(mapStateToProps)(withRouter(LabLabelTest));
