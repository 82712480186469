import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* youuniverseConversationGet(action) {
  try {
    const { payload } = action;
    const response = yield API.youuniverseConversationGet(payload);
    yield put({
      type: ActionTypes.YOUUNIVERSE_CONVERSATION_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUUNIVERSE_CONVERSATION_GET_FAILURE,
    });
  }
}
export function* youuniverseChatGet(action) {
  try {
    const { payload } = action;
    const response = yield API.youuniverseChatGet(payload);
    yield put({
      type: ActionTypes.YOUUNIVERSE_CHAT_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUUNIVERSE_CHAT_GET_FAILURE,
    });
  }
}

export function* youuniverseChatPost(action) {
  try {
    const { payload } = action;
    const response = yield API.youuniverseChatPost(payload);
    yield put({
      type: ActionTypes.YOUUNIVERSE_CHAT_POST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUUNIVERSE_CHAT_POST_FAILURE,
    });
  }
}

export function* youuniverseBookMarkQuestionUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.youuniverseBookMarkQuestionUpsert(payload);
    yield put({
      type: ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_UPSERT_FAILURE,
    });
  }
}

export function* youuniverseBookMarkQuestionListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.youuniverseBookMarkQuestionListGet(payload);
    yield put({
      type: ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_LIST_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_LIST_GET_FAILURE,
    });
  }
}

export function* youuniverseChatAttributeUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.youuniverseChatAttributeUpdate(payload);
    yield put({
      type: ActionTypes.YOUUNIVERSE_CHAT_ATTRIBUTE_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUUNIVERSE_CHAT_ATTRIBUTE_UPDATE_FAILURE,
    });
  }
}

export function* youuniverseConversationDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.youuniverseConversationDelete(payload);
    yield put({
      type: ActionTypes.YOUUNIVERSE_CONVERSATION_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUUNIVERSE_CONVERSATION_DELETE_FAILURE,
    });
  }
}

export function* youuChatEditedAnswerUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.youuChatEditedAnswerUpsert(payload);
    yield put({
      type: ActionTypes.YOUU_CHAT_EDITED_ANSWER_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.YOUU_CHAT_EDITED_ANSWER_UPSERT_FAILURE,
    });
  }
}

export function* askFollowUpInitiate(action) {
  try {
    const { payload } = action;
    const response = yield API.askFollowUpInitiate(payload);
    yield put({
      type: ActionTypes.ASK_FOLLOW_UP_INITIATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ASK_FOLLOW_UP_INITIATE_FAILURE,
    });
  }
}

export function* callNotesAugment(action) {
  try {
    const { payload } = action;
    const response = yield API.callNotesAugment(payload);
    yield put({
      type: ActionTypes.CALL_NOTE_AUGMENTATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CALL_NOTE_AUGMENTATION_FAILURE,
    });
  }
}

export function* callSummaryAugment(action) {
  try {
    const { payload } = action;
    const response = yield API.callSummaryAugment(payload);
    yield put({
      type: ActionTypes.CALL_SUMMARY_AUGMENT_SUCCESS,
      payload: JSON.parse(response.data.data) && JSON.parse(response.data.data).TwilioSummary[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CALL_SUMMARY_AUGMENT_FAILURE,
    });
  }
}

export function* gptAugmentationUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.gptAugmentationUpdate(payload);
    yield put({
      type: ActionTypes.GPT_AUGMENTATION_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CALL_SUMMARY_AUGMENT_FAILURE,
    });
  }
}

export function* serviceCodeSuggestionsGenerate(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeSuggestionsGenerate(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_SUGGESTIONS_GENERATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_SUGGESTIONS_GENERATE_FAILURE,
    });
  }
}

export function* serviceCodeSuggestionListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeSuggestionListGet(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_SUGGESTION_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_SUGGESTION_LIST_GET_FAILURE,
    });
  }
}

export function* peerRecoveryServiceCodeUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.peerRecoveryServiceCodeUpdate(payload);
    yield put({
      type: ActionTypes.PEER_RECOVERY_SERVICE_CODE_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PEER_RECOVERY_SERVICE_CODE_UPDATE_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.YOUUNIVERSE_CONVERSATION_GET_REQUEST, youuniverseConversationGet),
    takeLatest(ActionTypes.YOUUNIVERSE_CHAT_GET_REQUEST, youuniverseChatGet),
    takeLatest(ActionTypes.YOUUNIVERSE_CHAT_POST_REQUEST, youuniverseChatPost),
    takeLatest(
      ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_UPSERT_REQUEST,
      youuniverseBookMarkQuestionUpsert,
    ),
    takeLatest(
      ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_LIST_GET_REQUEST,
      youuniverseBookMarkQuestionListGet,
    ),
    takeLatest(
      ActionTypes.YOUUNIVERSE_CHAT_ATTRIBUTE_UPDATE_REQUEST,
      youuniverseChatAttributeUpdate,
    ),
    takeLatest(ActionTypes.YOUUNIVERSE_CONVERSATION_DELETE_REQUEST, youuniverseConversationDelete),
    takeLatest(ActionTypes.YOUU_CHAT_EDITED_ANSWER_UPSERT_REQUEST, youuChatEditedAnswerUpsert),
    takeLatest(ActionTypes.ASK_FOLLOW_UP_INITIATE_REQUEST, askFollowUpInitiate),
    takeLatest(ActionTypes.CALL_NOTE_AUGMENTATION_REQUEST, callNotesAugment),
    takeLatest(ActionTypes.CALL_SUMMARY_AUGMENT_REQUEST, callSummaryAugment),
    takeLatest(ActionTypes.GPT_AUGMENTATION_UPDATE_REQUEST, gptAugmentationUpdate),
    takeLatest(
      ActionTypes.SERVICE_CODE_SUGGESTIONS_GENERATE_REQUEST,
      serviceCodeSuggestionsGenerate,
    ),
    takeLatest(ActionTypes.SERVICE_CODE_SUGGESTION_LIST_GET_REQUEST, serviceCodeSuggestionListGet),
    takeLatest(
      ActionTypes.PEER_RECOVERY_SERVICE_CODE_UPDATE_REQUEST,
      peerRecoveryServiceCodeUpdate,
    ),
  ]);
}
