import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { getStudent } from '../store/actions';

import { connect } from 'react-redux';
import Paragraph from 'antd/lib/typography/Paragraph';
import Loader from '../Components/Loader';
import validate, {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from './enrollmentForm/validation/validate';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';

import {
  ReduxInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxDateInput,
  ReduxToggle,
  FrequencyReduxSelect,
} from '../component/custom-redux-components';
import { ProcessCombos } from '../store/comboConfig';
import { getDate } from '../util/date';

const StudentInformation = props => {
  const { disable, comboData } = props;
  const {
    NamePrefix,
    NameSuffix,
    StateProvince,
    Country,
    HomeAddressType,
    Counsellor,
    ReferenceSource,
    Center,
  } = comboData;
  return (
    <form>
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <Field
              name={`namePrefix`}
              type="text"
              component={ReduxSelect}
              placeholder="Prefix"
              options={NamePrefix}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name={`firstName`}
              type="text"
              component={ReduxInput}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">First Name*</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field name={`middleName`} type="text" component={ReduxInput} disabled={disable} />
            <label class="placeholder-label">Middle Name</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name={`lastName`}
              type="text"
              component={ReduxInput}
              fieldName={'Last Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Last Name*</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name={`nameSuffix`}
              type="text"
              placeholder={'Suffix'}
              component={ReduxSelect}
              options={NameSuffix}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name={`homeAddressType`}
              type="text"
              placeholder={'Home Address Type'}
              component={ReduxSelect}
              options={HomeAddressType}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field name={`line1`} type="text" component={ReduxInput} disabled={disable} />
            <label class="placeholder-label">Permanent Address Line 1(Last Known Address)</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field name={`line2`} type="text" component={ReduxInput} disabled={disable} />
            <label class="placeholder-label">Permanent Address Line 2</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field name={`city`} type="text" component={ReduxInput} disabled={disable} />
            <label class="placeholder-label">City</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="country"
              type="text"
              errorMessage="Country Required"
              component={ReduxSelect}
              options={Country}
              placeholder="Country"
              fieldName={'Country'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name={`stateOrProvince`}
              type="text"
              component={ReduxSelect}
              options={StateProvince}
              placeholder="State/Province/Territories"
              fieldName="State/Province/Territories"
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field name={`zip`} type="text" component={ReduxInput} disabled={disable} />
            <label class="placeholder-label">Postal/Zip Code</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              component={ReduxDateInput}
              name="enrollDate"
              label="Admission Date*"
              fieldName={'Admission Date'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="counselorStaffId"
              component={ReduxSelect}
              errorMessage="Field Required"
              options={Counsellor}
              placeholder="Counselor's Name"
              fieldName={"Counselor's Name"}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="center"
              component={ReduxSelect}
              placeholder="Center"
              options={Center}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="referenceSourceId"
              component={ReduxSelect}
              placeholder="Referral Source*"
              options={ReferenceSource}
              fieldName={'Reference Source'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name={`ssn`}
              type="text"
              component={ReduxInput}
              validate={validate.ssn}
              format={ssnFormatter}
              normalize={ssnNormalize}
              disabled={disable}
              fieldName={true}
            />
            <label class="placeholder-label">National ID (SSN)*</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              component={ReduxInput}
              name="dateofBirth"
              normalize={dateofbirthNormalize}
              placeholder="MM/DD/YYYY"
              fieldName={'Date of Birth'}
              disabled={disable}
            />
            <label class="placeholder-label">Date of Birth*</label>
          </div>
        </div>
        <div class="col-4 d-flex">
          <div class="form-group w-100">
            <Field
              name={`primaryCN`}
              type="text"
              component={ReduxInput}
              format={MobileFormatter}
              normalize={MobileNormalize}
              disabled={disable}
            />
            <label class="placeholder-label">Phone</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field name={`email`} type="text" component={ReduxInput} disabled={disable} />
            <label class="placeholder-label">E-mail</label>
          </div>
        </div>

        {/* <div class="col-12 col-md-6 col-lg-8">
          <div class="form-group">
            <Field
              name={`notes`}
              type="textarea"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div> */}
      </div>
    </form>
  );
};
const ReduxStudentInformation = reduxForm({
  form: 'wizard',
  enableReinitialize: true,
})(StudentInformation);
// Contact Information
const SupportContact = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  StateProvince,
  Country,
  change,
  disable,
  isEmergencyContactValue,
  dispatch,
}) => {
  return (
    <>
      {fields.map((supportContact, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Support Contact {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.firstName`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'First Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">First Name*</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.middleName`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Middle Name</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.lastName`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Last Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Last Name*</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label class="custom-control-label" for="is-emergency-contact">
                    <Field
                      name={`${supportContact}.isEmergencyContact`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                      disabled={disable}
                    />
                    {'   '}
                    Is Emergency Contact
                  </label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.relationshipId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Relationship*"
                    options={RelationShip}
                    fieldName={'Relationship'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.line1`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Address Line 1</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.line2`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Address Line 2</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.city`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.country`}
                    type="text"
                    errorMessage="Country Required"
                    component={ReduxSelect}
                    options={Country}
                    placeholder="Country"
                    fieldName={'Country'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.stateOrProvince`}
                    type="text"
                    component={ReduxSelect}
                    options={StateProvince}
                    placeholder="State/Province/Territories"
                    fieldName="State/Province/Territories"
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.zip`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Postal/Zip Code</label>
                </div>
              </div>

              {isEmergencyContactValue && (
                <div class="col-4 d-flex">
                  <div class="form-group w-100">
                    <Field
                      name={`${supportContact}.primaryCN`}
                      type="text"
                      fieldName="Home"
                      component={ReduxInput}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Home*</label>
                  </div>
                </div>
              )}
              {!isEmergencyContactValue && (
                <div class="col-4 d-flex">
                  <div class="form-group w-100">
                    <Field
                      name={`${supportContact}.primaryCN`}
                      type="text"
                      validate={null}
                      component={ReduxInput}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Home</label>
                  </div>
                </div>
              )}

              <div class="col-4 d-flex">
                <div class="form-group w-100">
                  <Field
                    name={`${supportContact}.secondaryCN`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Office</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.email`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">E-mail</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
const ContactInformation = props => {
  const { disable, comboData } = props;
  return (
    <form>
      <FieldArray
        name={'supportContacts'}
        component={SupportContact}
        RelationShip={comboData.RelationShip}
        StateProvince={comboData.StateProvince}
        Country={comboData.Country}
        disable={disable}
      />
    </form>
  );
};
const ReduxContactInformation = reduxForm({
  form: 'contact',
  enableReinitialize: true,
})(ContactInformation);

// Demographic Tab
const Demographic = props => {
  const { isEmployedValue, style, disable, comboData } = props;
  const {
    MaritalStatus,
    EthnicRace,
    Gender,
    HousingType,
    EyeColor,
    Language,
    EducationType,
    WorkSkill,
  } = comboData;
  return (
    <form>
      <div class="row mb-4">
        <div class="col-4">
          <div class="form-group">
            <Field
              name="maritalStatus"
              type="text"
              placeholder="Marital Status*"
              options={MaritalStatus}
              component={ReduxSelect}
              fieldName={'Marital Status'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="ethnicRaceId"
              type="text"
              placeholder="Race*"
              options={EthnicRace}
              component={ReduxSelect}
              fieldName={'Race'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="gender"
              type="text"
              placeholder="Gender at Birth*"
              options={Gender}
              component={ReduxSelect}
              fieldName={'Gender at Birth'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="housingTypeId"
              type="text"
              placeholder="Current Housing*"
              component={ReduxSelect}
              options={HousingType}
              fieldName={'Current Housing'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="eyeColorId"
              options={EyeColor}
              type="text"
              placeholder="Eye Color*"
              component={ReduxSelect}
              fieldName={'Eye Color'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="languageType"
              type="text"
              placeholder="Primary Language"
              component={ReduxSelect}
              options={Language}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="educationTypeId"
              type="text"
              placeholder="Education Level Completed*"
              component={ReduxSelect}
              options={EducationType}
              fieldName={'Education Level Completed'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <Field
              name="workSkillId"
              type="text"
              placeholder="Work Skill*"
              component={ReduxSelect}
              options={WorkSkill}
              fieldName={'Work Skill'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="custom-control-label" for="is-emergency-contact">
              <Field
                name={`isEmployed`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
              {'   '}
              Employed
            </label>
          </div>
        </div>
        {isEmployedValue && (
          <div class="col-4">
            <div class="form-group">
              <Field
                component={ReduxDateInput}
                name="employmentStartDate"
                label="Employment Start Date*"
                fieldName={'Employment Start Date'}
                disabled={disable}
              />
            </div>
          </div>
        )}
        {!isEmployedValue && (
          <div class="col-4">
            <div class="form-group">
              <Field
                component={ReduxDateInput}
                name="lastEmployedDate"
                label="Date Last Worked"
                disabled={disable}
              />
            </div>
          </div>
        )}
        <div class="col-8">
          <div class="form-group">
            <Field
              name={`demographicNotes`}
              type="textarea"
              component={ReduxInput}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>
      </div>
    </form>
  );
};
const ReduxDemographic = connect(state => {
  return { isEmployedValue: DemographicSelector(state, 'isEmployed') };
})(
  reduxForm({
    form: 'demographic',
    enableReinitialize: true,
  })(Demographic),
);
const DemographicSelector = formValueSelector('demographic');

// Addiction Profile
const substanceUseProfile = ({
  fields,
  name,
  meta: { touched, error },
  AddictionProfileType,
  AddictionType,
  DrugRouteOfAdmin,
  existingRows,
  disable,
}) => {
  return (
    <>
      {fields.map((substanceUseProfile, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Substance Use Profile {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4 d-none">
                <div class="form-group ">
                  <Field
                    name={`${substanceUseProfile}.studentAddictionTypeId`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'studentAddictionTypeId'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${substanceUseProfile}.addictionProfileTypeId`}
                    type="text"
                    placeholder="Addiction Profile Type"
                    options={AddictionProfileType}
                    component={ReduxSelect}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows &&
                existingRows[index] &&
                existingRows[index].addictionProfileTypeId == 1 && (
                  <>
                    <div class="col-4">
                      <div class="form-group">
                        <Field
                          name={`${substanceUseProfile}.addictionTypeId`}
                          type="text"
                          placeholder="Drug Use"
                          component={ReduxSelect}
                          options={AddictionType}
                          disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <Field
                          name={`${substanceUseProfile}.startDate`}
                          type="text"
                          component={ReduxDateInput}
                          label="Drug Use Start Date*"
                          fieldName={'Drug Use Start Date'}
                          disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <Field
                          name={`${substanceUseProfile}.endDate`}
                          type="text"
                          component={ReduxDateInput}
                          label="Drug Use Date (Last Used)*"
                          fieldName={'Drug Use'}
                          disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group">
                        <Field
                          name={`${substanceUseProfile}.drugRouteOfAdminId`}
                          type="text"
                          placeholder="How Is/Was Drug Administered?*"
                          component={ReduxSelect}
                          options={DrugRouteOfAdmin}
                          fieldName={'How Is/Was Drug Administered?'}
                          disabled={disable}
                          isMulti
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-8">
                      <div class="form-group">
                        <Field
                          name={`${substanceUseProfile}.notes`}
                          type="textarea"
                          component={ReduxInput}
                          disabled={disable}
                        />
                        <label class="placeholder-label">Notes</label>
                      </div>
                    </div>
                  </>
                )}
              {existingRows &&
              existingRows[index] &&
              existingRows[index].addictionProfileTypeId == 5 ? (
                <div class="col-md-6 col-lg-3 mar-top-15">
                  <div class="form-group">
                    <Field
                      name={`${substanceUseProfile}.addictionProfileTypeOther`}
                      type="text"
                      component={ReduxInput}
                      fieldName={'Addiction Profile Type'}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Describe Addiction Profile Type</label>
                  </div>
                </div>
              ) : null}

              {existingRows &&
              existingRows[index] &&
              existingRows[index].addictionProfileTypeId != 1 ? (
                <>
                  <div class="col-md-6 col-lg-3 mar-top-15">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.startDate`}
                        type="text"
                        component={ReduxDateInput}
                        label="Addiction Start Date"
                        fieldName={'Addiction Start Date'}
                        disabled={disable}
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3 mar-top-15">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.endDate`}
                        type="text"
                        component={ReduxDateInput}
                        label="Addiction End Date"
                        disabled={disable}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        );
      })}
    </>
  );
};

const AddictionProfiles = props => {
  const { disable, comboData } = props;
  return (
    <form>
      {props.existingRows && (
        <FieldArray
          name={'substanceUseProfiles'}
          component={substanceUseProfile}
          AddictionProfileType={comboData.AddictionProfileType}
          AddictionType={comboData.AddictionType}
          DrugRouteOfAdmin={comboData.DrugRouteOfAdmin}
          existingRows={props.existingRows}
          disable={disable}
        />
      )}
    </form>
  );
};
const ReduxAddictionProfiles = connect(state => {
  return {
    existingRows: AddictionProfilesSelector(state, 'substanceUseProfiles'),
  };
})(
  reduxForm({
    form: 'addiction',
    enableReinitialize: true,
  })(AddictionProfiles),
);
const AddictionProfilesSelector = formValueSelector('addiction');

// Previous Treatments
const PreviousTreatment = ({
  fields,
  name,
  meta: { touched, error },
  CenterProgramType,
  existingRows,
  disable,
}) => {
  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Previous Treatment {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.programTypeId`}
                    component={ReduxSelect}
                    placeholder="Program Type"
                    options={CenterProgramType}
                    fieldName={'Program Type'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.startDate`}
                    component={ReduxDateInput}
                    label="Treatment Start Date*"
                    fieldName={'Treatment Start Date'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.endDate`}
                    component={ReduxDateInput}
                    validate={validate.endDate}
                    label="Treatment End Date*"
                    disabled={!existingRows[index].startDate || disable}
                    fieldName={'Treatment End Date'}
                    // disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.centerName`}
                    component={ReduxInput}
                    fieldName={'Center Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Center Name*</label>
                </div>
              </div>

              <div class="col-8">
                <div class="form-group">
                  <Field
                    name={`${item}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

const PreviousTreatments = props => {
  const { disable, comboData, existingRows } = props;
  return (
    <form>
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Have you been in treatment for addiction before?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isAdditionTreatBefore" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      {existingRows && existingRows.length > 0 && (
        <FieldArray
          name={'previousTreatments'}
          component={PreviousTreatment}
          CenterProgramType={comboData.CenterProgramType}
          existingRows={existingRows}
          disable={disable}
        />
      )}
    </form>
  );
};
const ReduxPreviousTreatments = connect(state => {
  return {
    existingRows: PreviousTreatmentsSelector(state, 'previousTreatments') || [],
  };
})(
  reduxForm({
    form: 'previousTreatment',
    enableReinitialize: true,
  })(PreviousTreatments),
);
const PreviousTreatmentsSelector = formValueSelector('previousTreatment');
//  \Previous Treatments/

// Student Prior Abuse
const PriorAbuse = ({
  fields,
  name,
  meta: { touched, error },
  CenterProgramType,
  PriorAbuseType,
  PriorAbuseRelationship,
  Gender,
  AbuseFrequency,
  existingRows,
  disable,
  dateofBirth,
}) => {
  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Student Prior Abuse {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.priorAbuseTypeId`}
                    placeholder="Type of Abuse*"
                    component={ReduxSelect}
                    options={PriorAbuseType}
                    fieldName={'Type of Abuse'}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows && existingRows[index] && existingRows[index].priorAbuseTypeId == 5 ? (
                <div class="col-4">
                  <div class="form-group">
                    <Field
                      name={`${item}.priorAbuseTypeOther`}
                      component={ReduxInput}
                      fieldName={'Prior Abuse Type'}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Describe Type Of Abuse</label>
                  </div>
                </div>
              ) : null}
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.ageWhenAbused`}
                    type="number"
                    component={ReduxInput}
                    fieldName={' '}
                    disabled={disable}
                    // min={1}
                    // max={Number(
                    //   moment(`${dateofBirth}`, 'MM/DD/YYYY')
                    //     .month(0)
                    //     .from(moment().month(0))
                    //     .split(' ')[0] || 99,
                    // )}
                    // controlledMaxAge={true}
                    // validate={value => {
                    //   let maxAge = Number(
                    //     moment(`${dateofBirth}`, 'MM/DD/YYYY')
                    //       .month(0)
                    //       .from(moment().month(0))
                    //       .split(' ')[0] || 99,
                    //   );
                    //   if (value) {
                    //     if (value > maxAge) {
                    //       return `Age can not be greater than present age`;
                    //     }
                    //     if (value < 1) {
                    //       return `Age can not be less than one`;
                    //     }
                    //   }
                    // }}
                  />
                  <label class="placeholder-label">Your Age (in Years) When Abused?</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.abusedByRelationshipId`}
                    type="text"
                    placeholder="Who Abused You?"
                    component={ReduxSelect}
                    options={PriorAbuseRelationship}
                    fieldName={'Who Abused You?'}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows &&
              existingRows[index] &&
              existingRows[index].abusedByRelationshipId == 10 ? (
                <div class="col-4">
                  <div class="form-group">
                    <Field
                      name={`${item}.abusedByRelationshipOther`}
                      type="text"
                      errorMessage="Field required"
                      component={ReduxInput}
                      fieldName={'Abused By Relationship'}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Relation with Abuser</label>
                  </div>
                </div>
              ) : null}

              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.abuserAge`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Abuser Age'}
                    disabled={disable}
                    min={1}
                    max={99}
                    negativeNumberBlocked={true}
                  />
                  <label class="placeholder-label">Age of Abuser (In Years) when abused?</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.abuserGender`}
                    type="text"
                    placeholder="Gender of Abuser"
                    component={ReduxSelect}
                    options={Gender}
                    fieldName={'Gender of Abuser'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.howOften`}
                    type="text"
                    placeholder="How often did the abuse occur?"
                    component={FrequencyReduxSelect}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows && existingRows[index] && existingRows[index].howOften === 2 && (
                <>
                  <div class="col-4">
                    <div class="form-group">
                      <Field name={`${item}.howOftenActual`} type="number" component={ReduxInput} />
                      <label class="placeholder-label">How long did the abuse last?</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <Field
                        name={`${item}.abuseFrequencyCode`}
                        type="text"
                        placeholder="Abuse Frequency"
                        component={ReduxSelect}
                        options={AbuseFrequency}
                        disabled={disable}
                      />
                    </div>
                  </div>
                </>
              )}

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${item}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

const PriorAbuses = props => {
  const { disable, comboData, existingRows, isAbused, dateofBirth } = props;

  return (
    <form>
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">
            Have You Experienced Any Abuse: Physical , Mental , Emotional Or Sexual?
          </h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isAbused" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      {existingRows && existingRows.length > 0 && (
        <FieldArray
          name={'priorAbuses'}
          component={PriorAbuse}
          existingRows={existingRows}
          PriorAbuseType={comboData.PriorAbuseType}
          PriorAbuseRelationship={comboData.PriorAbuseRelationship}
          Gender={comboData.Gender}
          dateofBirth={dateofBirth}
          AbuseFrequency={comboData.AbuseFrequency}
          disable={disable}
        />
      )}
    </form>
  );
};
const ReduxPriorAbuses = connect(state => {
  return {
    existingRows: PriorAbusesSelector(state, 'priorAbuses') || [],
  };
})(
  reduxForm({
    form: 'priorAbuses',
    enableReinitialize: true,
  })(PriorAbuses),
);
const PriorAbusesSelector = formValueSelector('priorAbuses');
// Student Prior Abuse /

// FaithProfile Tab
const FaithProfile = props => {
  const {
    isCurrentChurchValue,
    faithTypeValue,
    isFaithAffiliationValue,
    disable,
    comboData,
  } = props;
  const { FaithType, ChurchDenomination, Country, StateProvince } = comboData;
  return (
    <form>
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Do You Have A Faith Or Religious Affiliation?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isFaithAffiliation" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      <div class="row mb-4">
        {isFaithAffiliationValue && (
          <>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`faithTypeId`}
                  placeholder="Faith Type"
                  component={ReduxSelect}
                  options={FaithType}
                  disabled={disable}
                />
              </div>
            </div>
            {faithTypeValue == 1 && (
              <>
                <div class="col-4">
                  <div class="form-group">
                    <Field
                      name={`churchDenominationId`}
                      placeholder="Denominations"
                      component={ReduxSelect}
                      options={ChurchDenomination}
                      fieldName={'Denominations'}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      <Field
                        name={`isBornAgain`}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                      {'   '}
                      Is Born Again?
                    </label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      <Field
                        name={`isSpiritFilled`}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                      {'   '}
                      Is Spirit Filled?
                    </label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="custom-control-label">
                      <Field
                        name={`isChurchGoing`}
                        type="text"
                        class="custom-control-input not-empty"
                        component={ReduxToggle}
                        disabled={disable}
                      />
                      {'   '}
                      Current Church
                    </label>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {isCurrentChurchValue && faithTypeValue == 1 && (
          <>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`churchName`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">Name*</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`churchCity`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'City'}
                  disabled={disable}
                />
                <label class="placeholder-label">City*</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchCountry`}
                  type="text"
                  errorMessage="Country Required"
                  component={ReduxSelect}
                  options={Country}
                  placeholder="Country"
                  fieldName={'Country'}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`churchState`}
                  // type="text"
                  component={ReduxSelect}
                  options={StateProvince}
                  placeholder="State/Province/Territories"
                  fieldName="State/Province/Territories"
                  disabled={disable}
                />
                {/* <label class="placeholder-label">State*</label> */}
              </div>
            </div>
          </>
        )}
      </div>
      <div class="col-12 col-md-6 col-lg-8">
        <div class="form-group">
          <Field name={`faithNotes`} type="textarea" component={ReduxInput} disabled={disable} />
          <label class="placeholder-label">Notes</label>
        </div>
      </div>
    </form>
  );
};
const ReduxfaithProfile = connect(state => {
  return {
    isCurrentChurchValue: FaithProfileSelector(state, 'isChurchGoing'),
    faithTypeValue: FaithProfileSelector(state, 'faithTypeId'),
    isFaithAffiliationValue: FaithProfileSelector(state, 'isFaithAffiliation'),
  };
})(
  reduxForm({
    form: 'faithProfile',
    enableReinitialize: true,
  })(FaithProfile),
);
const FaithProfileSelector = formValueSelector('faithProfile');

// LevelOfCares
const LevelOfCare = ({
  fields,
  name,
  meta: { touched, error },
  CenterServiceTypeCenter,
  Frequency,
  existingRows,
  disable,
}) => {
  return (
    <>
      {fields.map((levelOfCare, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Service Type {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.centerServiceTypeCenterId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Service Type*"
                    options={CenterServiceTypeCenter}
                    fieldName={'Service Type'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.startDate`}
                    type="text"
                    label={'Start Date*'}
                    component={ReduxDateInput}
                    fieldName={'Start Date'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  {existingRows[index] && !existingRows[index].isEndDateEstimated ? (
                    <Field
                      name={`${levelOfCare}.endDate`}
                      type="text"
                      label={'End Date*'}
                      component={ReduxDateInput}
                      fieldName={'End Date'}
                      validate={validate.endDate}
                      disabled={disable}
                    />
                  ) : (
                    <Field
                      name={`${levelOfCare}.endDate`}
                      type="text"
                      label={'End Date'}
                      component={ReduxDateInput}
                      fieldName={'End Date'}
                      disabled={disable}
                    />
                  )}
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label class="custom-control-label" for="is-emergency-contact">
                    <Field
                      name={`${levelOfCare}.isEndDateEstimated`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      disabled={disable}
                    />
                    {'   '}
                    Is End Date Estimated?
                  </label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.estimatedCostFrequencyCode`}
                    type="text"
                    component={ReduxSelect}
                    options={Frequency}
                    placeholder="Cost of Care Frequency*"
                    fieldName={'Cost of Care Frequency'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.estimatedCost`}
                    component={ReduxDollarInput}
                    fieldName={'Estimated Cost'}
                    disabled={disable}
                    dollarPlaceHolder={'Standard Cost of Care*'}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${levelOfCare}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
const LevelOfCares = props => {
  const { disable, comboData } = props;
  return (
    <form>
      {props.existingRows && (
        <FieldArray
          name={'financials'}
          component={LevelOfCare}
          CenterServiceTypeCenter={comboData.CenterServiceTypeCenter}
          Frequency={comboData.Frequency}
          existingRows={props.existingRows}
          disable={disable}
        />
      )}
    </form>
  );
};
const ReduxLevelOfCares = connect(state => {
  return {
    existingRows: LevelOfCaresSelector(state, 'financials'),
  };
})(
  reduxForm({
    form: 'financial',
    enableReinitialize: true,
  })(LevelOfCares),
);
const LevelOfCaresSelector = formValueSelector('financial');
// LevelOfCares\

// StudentFundSource
const PaymentForCare = ({
  fields,
  name,
  meta: { touched, error },
  FinancialResponsibilityType,
  Frequency,
  existingRows,
  disable,
}) => {
  return (
    <>
      {fields.map((pamentForCare, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Funding Source {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${pamentForCare}.responsibilityTypeId`}
                    type="text"
                    component={ReduxSelect}
                    options={FinancialResponsibilityType}
                    placeholder="How Do You Plan To Help Pay For The Program?*"
                    fieldName={'How Do You Plan To Help Pay For The Program'}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows &&
              existingRows[index] &&
              existingRows[index].responsibilityTypeId == 7 ? (
                <div class="col-4">
                  <div class="form-group">
                    <Field
                      name={`${pamentForCare}.responsibilityTypeOther`}
                      type="text"
                      component={ReduxInput}
                      fieldName={'Responsibility Type'}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Describe Support Type</label>
                  </div>
                </div>
              ) : null}
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${pamentForCare}.contributionAmt`}
                    component={ReduxDollarInput}
                    fieldName={'Contribution Amount'}
                    style={{ width: 'auto' }}
                    disabled={disable}
                    dollarPlaceHolder={' Contribution Dollar*'}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${pamentForCare}.contributionFrequencyCode`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Contribution Frequency"
                    options={Frequency}
                    fieldName={'Contribution Frequency'}
                    disabled={disable}
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${pamentForCare}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
const PaymentForCareUI = props => {
  const { disable, comboData } = props;
  return (
    <form>
      {props.existingRows && (
        <FieldArray
          name={'fundSources'}
          component={PaymentForCare}
          FinancialResponsibilityType={comboData.FinancialResponsibilityType}
          Frequency={comboData.Frequency}
          existingRows={props.existingRows}
          disable={disable}
        />
      )}
    </form>
  );
};
const ReduxPaymentForCare = connect(state => {
  return {
    existingRows: PaymentForCareSelector(state, 'fundSources'),
  };
})(
  reduxForm({
    form: 'fundSource',
    enableReinitialize: true,
  })(PaymentForCareUI),
);
const PaymentForCareSelector = formValueSelector('fundSource');

// Legal Issues Tab
const LegalIssues = props => {
  const {
    isUnderLegalSupervisionValue,
    isLegallyRequiredTreatmentValue,
    disable,
    comboData,
  } = props;
  const { FaithType, ChurchDenomination } = comboData;
  return (
    <form>
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">
            Are you legally required to enter a recovery or treatment program?
          </h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isLegallyRequiredTreatment" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      <div class="row mb-4">
        {isLegallyRequiredTreatmentValue && (
          <>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`legallyRequiredOfficer`}
                  placeholder="By Whom?"
                  component={ReduxSelect}
                  options={[
                    { Value: '1', Text: 'Probation Officer' },
                    { Value: '2', Text: 'Court (jurisdiction)' },
                  ]}
                  disabled={disable}
                  fieldName={'by Whom??'}
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`legallyRequiredOfficerDetails`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Legally Required Officers Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">Legally Required officers Name*</label>
              </div>
            </div>
          </>
        )}
      </div>
      <>
        <>
          <div class="row mb-3">
            <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
              <h6 class="d-inline-flex mb-0 mr-3 ">Are you under legal supervision?</h6>
              <div class="custom-control custom-switch d-inline-flex ">
                <Field
                  name={`isUnderLegalSupervision`}
                  type="text"
                  class="custom-control-input not-empty"
                  component={ReduxToggle}
                  disabled={disable}
                />
              </div>
            </div>
          </div>
        </>
      </>

      {isUnderLegalSupervisionValue && (
        <>
          <div class="row mb-4">
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`supervisionApproach`}
                  placeholder="Method of reporting to that supervision"
                  component={ReduxSelect}
                  options={[
                    { Value: '1', Text: 'Phone' },
                    { Value: '2', Text: 'Letter' },
                    { Value: '3', Text: 'In Person' },
                  ]}
                  disabled={disable}
                  fieldName={'Method of reporting to that supervision?'}
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={'reportToSupervision'}
                  type="text"
                  placeholder="How often do you have to report in?"
                  component={ReduxSelect}
                  options={[
                    {
                      Value: '1',
                      Text: 'Daily',
                      ValueColumnName: 'reportIn',
                    },
                    {
                      Value: '2',
                      Text: 'Weekly',
                      ValueColumnName: 'reportIn',
                    },
                    {
                      Value: '3',
                      Text: 'Monthly',
                      ValueColumnName: 'reportIn',
                    },
                  ]}
                  fieldName={'How often do you have to report in?'}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`probationOfficerName`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Probation officers Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">Probation officers Name*</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`probationOfficerJurisdiction`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Jursidiction'}
                  disabled={disable}
                />
                <label class="placeholder-label">Jursidiction*</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`probationOfficerEmail`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Email'}
                  disabled={disable}
                />
                <label class="placeholder-label">Email</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`probationOfficerPhone`}
                  type="text"
                  component={ReduxInput}
                  format={MobileFormatter}
                  normalize={MobileNormalize}
                  fieldName={'Phone'}
                  disabled={disable}
                />
                <label class="placeholder-label">Phone</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`superVisionContact`}
                  type="textarea"
                  component={ReduxInput}
                  disabled={disable}
                  fieldName={'History of arrest and convictions'}
                />
                <label class="placeholder-label">Supervision Contact Address</label>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
              <h6 class="d-inline-flex mb-0 mr-3 ">Attorney's contact information</h6>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`attorneyName`}
                  type="text"
                  component={ReduxInput}
                  fieldName={"Attorney's Name"}
                  disabled={disable}
                />
                <label class="placeholder-label">Attorney's Name</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`attorneyEmail`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Contact Email'}
                  disabled={disable}
                />
                <label class="placeholder-label">Contact Email</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <Field
                  name={`attorneyPhone`}
                  type="text"
                  component={ReduxInput}
                  format={MobileFormatter}
                  normalize={MobileNormalize}
                  fieldName={'Contact Phone'}
                  disabled={disable}
                />
                <label class="placeholder-label">Contact Phone</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <Field
                  name={'pendingCases'}
                  type="text"
                  placeholder="Are any of the following pending?"
                  component={ReduxSelect}
                  options={[
                    { Value: '1', Text: 'Warrant for arrest' },
                    { Value: '2', Text: 'Court Appearance' },
                    { Value: '3', Text: 'Criminal charges' },
                    { Value: '4', Text: 'Sentencing' },
                  ]}
                  fieldName={'Are any of the following pending?'}
                  disabled={disable}
                  isMulti
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <Field
                  name={`pastArrestandConvictions`}
                  type="textarea"
                  component={ReduxInput}
                  disabled={disable}
                  fieldName={'History of arrest and convictions'}
                />
                <label class="placeholder-label">History of arrest and convictions</label>
              </div>
            </div>
          </div>
        </>
      )}
      <div class="col-12 col-md-6 col-lg-8">
        <div class="form-group">
          <Field
            name={`legalIssuesNotes`}
            type="textarea"
            component={ReduxInput}
            disabled={disable}
          />
          <label class="placeholder-label">Notes</label>
        </div>
      </div>
    </form>
  );
};
const ReduxlegalIssues = connect(state => {
  return {
    isUnderLegalSupervisionValue: LegalIssueSelector(state, 'isUnderLegalSupervision'),
    isLegallyRequiredTreatmentValue: LegalIssueSelector(state, 'isLegallyRequiredTreatment'),
  };
})(
  reduxForm({
    form: 'legalIssue',
    enableReinitialize: true,
  })(LegalIssues),
);
const LegalIssueSelector = formValueSelector('legalIssue');

// Medical History
const MedicalHistory = ({ fields, name, meta: { touched, error }, disable }) => {
  return (
    <>
      {fields.map((medicalPast, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Medical History {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.relation`}
                    type="text"
                    component={ReduxSelect}
                    options={[
                      { Value: '1', Text: 'Self' },
                      { Value: '2', Text: 'Parents ' },
                      { Value: '3', Text: 'Grandparents' },
                      { Value: '4', Text: 'Siblings' },
                    ]}
                    placeholder="Family medical history for*"
                    fieldName={'Family medical history for'}
                    disabled={disable}
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.familyMedicalPast`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">
                    History should include Drug use, Alcohol use, Physical ailments, Mental Health
                    issues
                  </label>
                </div>
              </div>

              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.currentMedications`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Current Medications</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.devlopmentalIssuesPast`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">
                    History of developmental issues such as learning disabilities{' '}
                  </label>
                </div>
              </div>

              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.allergiesMedicines`}
                    type="text"
                    component={ReduxInput}
                    //
                    fieldName={'Allergies Medicines Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Allergies Medicines Name</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.allergiesFood`}
                    type="text"
                    component={ReduxInput}
                    //
                    fieldName={'Allergies Food Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Allergies Food Names</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.allergiesOthers`}
                    type="text"
                    component={ReduxInput}
                    //
                    fieldName={'Allergies Others Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Allergies Others Name</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.currentPhysicanName`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Physician Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Physician Name*</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.currentPhysicanPhone`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Physician Phone'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Physician Phone*</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.currentPhysicanAddress`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Physician Address'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Physician Address*</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${medicalPast}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
const MedicalHistoryUI = props => {
  const { disable, comboData } = props;
  return (
    <form>
      <FieldArray name={'medicalPast'} component={MedicalHistory} disable={disable} />
    </form>
  );
};
const ReduxMedicalHistory = reduxForm({
  form: 'medicalPast',
  enableReinitialize: true,
})(MedicalHistoryUI);

// Health Insurances
const HealthInsurance = ({
  fields,
  name,
  meta: { touched, error },
  HealthInsuranceType,
  StateProvince,
  Country,
  disable,
}) => {
  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Health Insurance {index + 1}</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.insuranceCompany`}
                    component={ReduxInput}
                    fieldName={'Provider'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Provider*</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.policyNumber`}
                    component={ReduxInput}
                    fieldName={'ID'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">ID*</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.groupNumber`}
                    component={ReduxInput}
                    fieldName={'Group'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Group*</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.healthInsuranceTypeId`}
                    placeholder="Health Insurance Type*"
                    component={ReduxSelect}
                    options={HealthInsuranceType}
                    fieldName={'Health Insurance Type'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field name={`${item}.line1`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">Address</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field name={`${item}.city`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.country`}
                    type="text"
                    component={ReduxSelect}
                    options={Country}
                    placeholder="Country"
                    fieldName={'Country'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field
                    name={`${item}.stateOrProvince`}
                    type="text"
                    component={ReduxSelect}
                    options={StateProvince}
                    placeholder="State/Province/Territories"
                    fieldName="State/Province/Territories"
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field name={`${item}.phone`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field name={`${item}.fax`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">Fax</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <Field name={`${item}.email`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">E-mail</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${item}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

const HealthInsuranceUI = props => {
  const { disable, comboData, existingRows, isHealthInsurance } = props;

  return (
    <form>
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Do you have Health Insurance?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isHealthInsurance" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      {existingRows && existingRows.length > 0 && (
        <FieldArray
          name={'healthInsurances'}
          component={HealthInsurance}
          existingRows={existingRows}
          HealthInsuranceType={comboData.HealthInsuranceType}
          StateProvince={comboData.StateProvince}
          Country={comboData.Country}
          disable={disable}
        />
      )}
    </form>
  );
};
const ReduxHealthInsurance = connect(state => {
  return {
    existingRows: HealthInsuranceSelector(state, 'healthInsurances') || [],
  };
})(
  reduxForm({
    form: 'healthInsurance',
    enableReinitialize: true,
  })(HealthInsuranceUI),
);
const HealthInsuranceSelector = formValueSelector('healthInsurance');
// Health Insurances  /

// Print Emroll
class PrintEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StudentInformationData: {},
      ContactInformationData: [],
      DemographicData: {},
      AddictionData: [{}],
      PreviousTreatmentsData: [{}],
      PriorAbusesData: [{}],
      StudentMoreData: {},
      MedicalPastData: [{}],
      LegalIssuesData: [],
      FundSourcesData: [{}],
      StudentFinancialData: [{}],
      HealthInsurancesData: [{}],
    };
  }
  formatDOB = val => {
    if (val) {
      // var date = getDate(val);
      var date = new Date(val.substring(0, 4), val.substring(5, 7), val.substring(8, 10));
      var dateString = '';
      var month = date.getMonth();
      if (month < 10) {
        dateString = '0' + month + '/';
      } else {
        dateString = month + '/';
      }
      var day = date.getDate();
      if (day < 10) {
        dateString += '0' + day + '/';
      } else {
        dateString += day + '/';
      }

      dateString += date.getFullYear();

      return dateString;
    }
    return val;
  };
  componentDidMount() {
    if (this.getStudentId()) {
      this.props.dispatch(getStudent(this.getStudentId()));

      var result = ProcessCombos(
        'HealthInsuranceType,PriorAbuseType,StudentChildrenCare,CenterProgramType,Country,StateProvince,CenterServiceTypeCenter,FinancialResponsibilityType,RelationShip,PriorAbuseRelationship,FaithType,ReferenceSource,Frequency,MaritalStatus,EthnicRace,Gender,HousingType,EyeColor,Language,EducationType,WorkSkill,SurveyQuestionCategory,SurveyQuestionType,AddictionType,AddictionProfileType,DrugRouteOfAdmin,NameSuffix,NamePrefix,HomeAddressType,ChurchDenomination,Center,Counsellor,AbuseFrequency',
      );
      if (result.isProcess) {
        this.props.dispatch(result.processItems);
      }
    }
  }
  mapStudentContactToState(items) {
    var contacts = [];
    if (items) {
      for (var item of items) {
        var contact = {};
        contact.contactId = item.ContactId;
        contact.firstName = item.Contact[0].FirstName;
        contact.middleName = item.Contact[0].MiddleName;
        contact.lastName = item.Contact[0].LastName;
        contact.line1 = item.Contact[0].AddressLine1;
        contact.line2 = item.Contact[0].AddressLine2;
        contact.line3 = item.Contact[0].AddressLine3;
        contact.city = item.Contact[0].City;
        contact.stateOrProvince = item.Contact[0].StateOrProvince;
        contact.country = item.Contact[0].CountryISO;
        contact.zip = item.Contact[0].ZipCode;
        contact.primaryCN = item.Contact[0].PrimaryPhone;
        contact.secondaryCN = item.Contact[0].SecondaryPhone;
        contact.email = item.Contact[0].Email;
        contact.relationshipId = item.Contact[0].RelationShipId;
        contact.isEmergencyContact = item.Contact[0].IsEmergencyContact;
        contact.notes = item.Contact[0].Notes;
        contacts.push(contact);
      }
    }
    return contacts;
  }
  mapStudentAddictionToState(items) {
    var addictions = [];

    if (items) {
      for (var item of items) {
        var addiction = {};

        addiction.addictionProfileTypeId = item.AddictionProfileTypeId;
        addiction.studentAddictionTypeId = item.StudentAddictionTypeId;
        if (addiction.addictionProfileTypeId == 5) {
          addiction.addictionProfileTypeOther = item.AddictionProfileTypeOther;
        } else {
          addiction.addictionProfileTypeOther = null;
        }

        addiction.addictionTypeId = item.AddictionTypeId;
        addiction.drugRouteOfAdminId = '' + item.DrugRouteOfAdminId + '';
        addiction.reportedDate = item.ReportedDate;
        addiction.startDate = item.StartDate;
        addiction.endDate = item.EndDate;
        addiction.notes = item.Notes;
        addictions.push(addiction);
      }
    } else {
      addictions.push({
        addictionTypeId: this.props.defaultAddictionProfileTypeId,
        addictionProfileTypeId: 1,
      });
    }

    return addictions;
  }
  mapStudentPreviousTreatmentsToState(items) {
    var previousTreatments = [];

    if (items) {
      for (var item of items) {
        var previousTreatment = {};
        previousTreatment.studentPreviousTreatmentId = item.StudentPreviousTreatmentId;
        previousTreatment.startDate = item.StartDate;
        previousTreatment.endDate = item.EndDate;
        previousTreatment.centerName = item.CenterName;
        previousTreatment.notes = item.Notes;
        previousTreatment.programTypeId = item.ProgramTypeId;

        previousTreatments.push(previousTreatment);
      }
    }
    return previousTreatments;
  }
  mapStudentPriorAbusesToState(items) {
    var priorAbuses = [];

    if (items) {
      for (var item of items) {
        var priorAbuse = {};
        priorAbuse.studentPriorAbuseId = item.StudentPriorAbuseId;
        priorAbuse.notes = item.Notes;
        priorAbuse.priorAbuseTypeId = item.PriorAbuseTypeId;
        if (priorAbuse.priorAbuseTypeId == 5) {
          priorAbuse.priorAbuseTypeOther = item.PriorAbuseTypeOther;
        } else {
          priorAbuse.priorAbuseTypeOther = null;
        }

        priorAbuse.ageWhenAbused = item.AgeWhenAbused;
        priorAbuse.abusedByRelationshipId = item.AbusedByRelationshipId;
        if (priorAbuse.abusedByRelationshipId == 10) {
          priorAbuse.abusedByRelationshipOther = item.AbusedByRelationshipOther;
        } else {
          priorAbuse.abusedByRelationshipOther = null;
        }

        priorAbuse.abuserAge = item.AbuserAge;
        priorAbuse.abuserGender = item.AbuserGender;

        if (item.HowOften == 1) {
          priorAbuse.howOften = 1;
          priorAbuse.abuseFrequencyCode = null;
        } else if (item.HowOften > 1) {
          priorAbuse.howOften = 2;
          priorAbuse.howOftenActual = item.HowOften;
          priorAbuse.abuseFrequencyCode = item.AbuseFrequencyCode;
        }

        priorAbuses.push(priorAbuse);
      }
    }

    return priorAbuses;
  }
  mapStudentMore(item) {
    let data = {};
    data.referenceSourceId = item.ReferenceSourceId;
    data.isEmployed = item.IsEmployed;
    data.lastEmployedDate = item.LastEmployedDate;
    data.employmentStartDate = item.EmploymentStartDate;
    data.faithTypeId = item.FaithTypeId;
    data.isChurchGoing = item.IsChurchGoing;
    data.churchName = item.ChurchName;
    data.faithNotes = item.FaithNotes;
    data.churchCity = item.ChurchCity;
    data.churchState = item.ChurchState;
    data.churchCountry = item.ChurchCountry;
    data.churchDenominationId = item.ChurchDenominationId;
    data.isBornAgain = item.IsBornAgain;
    data.isSpiritFilled = item.IsSpiritFilled;
    data.exitReasonNotes = item.ExitReasonNotes;
    data.housingTypeId = item.HousingTypeId;
    data.demographicNotes = item.Notes;
    return data;
  }
  mapStudentFinancialsToState(items) {
    var financials = [];

    if (items) {
      for (var item of items) {
        var financial = {};
        financial.studentFinancialId = item.StudentFinancialId;
        financial.centerServiceTypeCenterId = item.CenterServiceTypeCenterId;
        financial.estimatedCost = item.EstimatedCost;
        financial.estimatedCostFrequencyCode = item.EstimatedCostFrequencyCode;
        financial.startDate = item.StartDate;
        financial.endDate = item.EndDate;
        financial.notes = item.Notes;
        financial.isEndDateEstimated = item.IsEndDateEstimated;

        financials.push(financial);
      }
    }

    return financials;
  }
  mapStudentFundSourcesToState(items) {
    var fundSources = [];

    if (items) {
      for (var item of items) {
        var fundSource = {};
        fundSource.studentFundSourceId = item.StudentFundSourceId;
        fundSource.responsibilityTypeId = item.ResponsibilityTypeId;
        if (fundSource.responsibilityTypeId == 7) {
          fundSource.responsibilityTypeOther = item.ResponsibilityTypeOther;
        } else {
          fundSource.responsibilityTypeOther = null;
        }

        fundSource.contributionAmt = item.ContributionAmt;
        fundSource.contributionFrequencyCode = item.ContributionFrequencyCode;
        fundSource.notes = item.Notes;
        fundSources.push(fundSource);
      }
    }

    return fundSources;
  }
  mapStudentLegalIssuesToState(items) {
    let data = {};
    data.isLegallyRequiredTreatment = items.IsLegallyRequiredTreatment;
    data.isUnderLegalSupervision = items.IsUnderLegalSupervision;
    data.legallyRequiredOfficer = items.LegallyRequiredOfficer;
    data.legallyRequiredOfficerDetails = items.LegallyRequiredOfficerDetails;
    data.supervisionApproach = items.SupervisionApproach;
    data.reportToSupervision = items.ReportToSupervision;
    data.probationOfficerName = items.ProbationOfficerName;
    data.probationOfficerJurisdiction = items.ProbationOfficerJurisdiction;
    data.probationOfficerEmail = items.ProbationOfficerEmail;
    data.probationOfficerPhone = items.ProbationOfficerPhone;
    data.attorneyName = items.AttorneyName;
    data.attorneyEmail = items.AttorneyEmail;
    data.attorneyPhone = items.AttorneyPhone;
    data.pendingCases = '' + items.PendingCases + '';
    data.pastArrestandConvictions = items.PastArrestandConvictions;
    data.superVisionContact = items.SuperVisionContactAddress;
    data.legalIssuesNotes = items.Notes;
    return data;
  }
  mapStudentMedicalPastToState(items) {
    var medicalPasts = [];
    if (items && items.length > 0) {
      for (var item of items) {
        var medicalPast = {};
        medicalPast.relation = item.Relation;
        medicalPast.familyMedicalPast = item.FamilyMedicalPast;
        medicalPast.devlopmentalIssuesPast = item.DevlopmentalIssuesPast;
        medicalPast.currentMedications = item.CurrentMedications;
        medicalPast.allergiesMedicines = item.AllergiesMedicines;
        medicalPast.allergiesFood = item.AllergiesFood;
        medicalPast.allergiesOthers = item.AllergiesOthers;
        medicalPast.currentPhysicanName = item.CurrentPhysicanName;
        medicalPast.currentPhysicanPhone = item.CurrentPhysicanPhone;
        medicalPast.currentPhysicanAddress = item.CurrentPhysicanAddress;
        medicalPast.notes = item.Notes;
        medicalPasts.push(medicalPast);
      }
    }
    return medicalPasts;
  }
  mapStudentInsuranceToState(items) {
    var healthInsurances = [];
    if (items && items.length > 0) {
      for (var item of items) {
        var healthInsurance = {};
        healthInsurance.studentInsuranceId = item.StudentInsuranceId;
        healthInsurance.insuranceCompany = item.InsuranceCompany;
        healthInsurance.policyNumber = item.PolicyNumber;
        healthInsurance.groupNumber = item.GroupNumber;
        healthInsurance.healthInsuranceTypeId = item.HealthInsuranceTypeId;
        healthInsurance.notes = item.Notes;
        healthInsurance.line1 = item.AddressLine1;
        healthInsurance.city = item.City;
        healthInsurance.stateOrProvince = item.StateOrProvince;
        healthInsurance.country = item.CountryISO;
        healthInsurance.phone = item.Phone;
        healthInsurance.fax = item.Fax;
        healthInsurance.email = item.Email;
        healthInsurances.push(healthInsurance);
      }
    }

    return healthInsurances;
  }
  componentWillReceiveProps({ studentInfo }) {
    if (studentInfo && studentInfo.StudentId) {
      let studentInfoData = {
        firstName: studentInfo.FirstName,
        middleName: studentInfo.MiddleName,
        lastName: studentInfo.LastName,
        nameSuffix: studentInfo.NameSuffix,
        namePrefix: studentInfo.NamePrefix,
        homeAddressType: studentInfo.HomeAddressType,
        line1: studentInfo.AddressLine1,
        line2: studentInfo.AddressLine2,
        line3: studentInfo.AddressLine3,
        city: studentInfo.City,
        stateOrProvince: studentInfo.StateOrProvince,
        country: studentInfo.CountryISO,
        counselorStaffId: studentInfo.CounselorStaffId,
        zip: studentInfo.ZipCode,
        // enrollDate: studentInfo.EnrollDateDDMMMYYYY,
        enrollDate: studentInfo.EnrollDate,
        ssn: studentInfo.NationalId,
        referenceSourceId: studentInfo.StudentMore[0].ReferenceSourceId,
        dateofBirth: this.formatDOB(studentInfo.DateofBirth),
        center: studentInfo.CenterId,
        primaryCN: studentInfo.PrimaryPhone,
        secondaryCN: studentInfo.SecondaryPhone,
        email: studentInfo.Email,
      };
      let contactInfoData = this.mapStudentContactToState(studentInfo.StudentContact);
      let demographicData = {
        maritalStatus: studentInfo.MaritalStatus,
        ethnicRaceId: studentInfo.EthnicRaceId,
        gender: studentInfo.Gender,
        eyeColorId: studentInfo.EyeColorId,
        languageType: studentInfo.LanguageISO,
        educationTypeId: studentInfo.EducationTypeId,
        workSkillId: studentInfo.WorkSkillId,
        isEmployed: studentInfo.StudentMore[0].IsEmployed,
        employmentStartDate: studentInfo.StudentMore[0].EmploymentStartDate,
        lastEmployedDate: studentInfo.StudentMore[0].LastEmployedDate,
        housingTypeId: studentInfo.StudentMore[0].HousingTypeId,
        demographicNotes: studentInfo.StudentMore[0].Notes,
      };
      let substanceUseProfiles = this.mapStudentAddictionToState(studentInfo.StudentAddictionType);
      let previousTreatments = this.mapStudentPreviousTreatmentsToState(
        studentInfo.StudentPreviousTreatment,
      );
      let priorAbuses = this.mapStudentPriorAbusesToState(studentInfo.StudentPriorAbuse);
      let studentMore = this.mapStudentMore(studentInfo.StudentMore[0]);
      // For Financial
      let financials = this.mapStudentFinancialsToState(studentInfo.StudentFinancial);
      // For FundSource
      let fundSources = this.mapStudentFundSourcesToState(studentInfo.StudentFundSource);

      // For Legal Issue
      let studentLegalIssues = [{}];
      if (studentInfo.StudentLegalIssues && studentInfo.StudentLegalIssues[0]) {
        studentLegalIssues = this.mapStudentLegalIssuesToState(studentInfo.StudentLegalIssues[0]);
      }
      // For Medical Past
      let medicalPast = this.mapStudentMedicalPastToState(studentInfo.StudentMedicalPast);
      // For HealthInsurance
      let healthInsurances = this.mapStudentInsuranceToState(studentInfo.StudentInsurance);
      this.setState({
        StudentInformationData: studentInfoData,
        ContactInformationData: contactInfoData,
        DemographicData: demographicData,
        AddictionData: substanceUseProfiles,
        PreviousTreatmentsData: previousTreatments,
        PriorAbusesData: priorAbuses,
        StudentMoreData: studentMore,
        StudentFinancialData: financials,
        FundSourcesData: fundSources,
        LegalIssuesData: studentLegalIssues,
        MedicalPastData: medicalPast,
        HealthInsurancesData: healthInsurances,
      });
    }
  }
  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('studentId') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }
  // getStudentId() {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   return param.get('studentid');
  // }
  // getUserGuid() {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   return param.get('userGuid');
  // }

  render() {
    const { studentInfo, isFetching, comboData } = this.props;
    const {
      StudentInformationData,
      ContactInformationData,
      DemographicData,
      AddictionData,
      PreviousTreatmentsData,
      PriorAbusesData,
      StudentMoreData,
      StudentFinancialData,
      FundSourcesData,
      LegalIssuesData,
      MedicalPastData,
      HealthInsurancesData,
    } = this.state;
    return (
      <div class="main enrollment-page">
        <Loader loading={isFetching} />
        <div className="client-center-info">
          <div className="item">
            <span className="text">Center Id : </span>
            <span className="value">{studentInfo.CenterId}</span>
          </div>
          <div className="item">
            <span className="text">Center Name : </span>
            <span className="value">{studentInfo.CenterName}</span>
          </div>
        </div>
        <div className="card-print card-body enrollment-form print-enrollment">
          <div>
            <h4 class="students-name text-sm-center">
              <span>{`${studentInfo.FirstName ? studentInfo.FirstName : ''} ${
                studentInfo.MiddleName ? studentInfo.MiddleName : ''
              } ${studentInfo.LastName ? studentInfo.LastName : ''}`}</span>
              {/* <span className="print-btn">Student Profile</span> */}
              <button onClick={() => window.print()} className="btn black-btn px-4 print-btn">
                <i class="fas fa-print mr-2"></i>
                Print
              </button>
            </h4>
          </div>
          <div class="">
            <div className="row py-2">
              <div className="col-sm-12">
                <h5 className="mb-4">Student Profile</h5>
              </div>
              <div className="col-sm-12 py-3">
                <ReduxStudentInformation
                  {...this.props}
                  disable={true}
                  comboData={comboData}
                  initialValues={StudentInformationData}
                />
              </div>
            </div>
            <div class="page-break"></div>
            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Contact Information</h5>
              </div>
              <div className="col-12 py-3">
                <div className="w-100 ">
                  {ContactInformationData && ContactInformationData.length > 0 ? (
                    <>
                      <ReduxContactInformation
                        {...this.props}
                        disable={true}
                        comboData={comboData}
                        initialValues={{ supportContacts: ContactInformationData }}
                      />
                      <div class="page-break"></div>
                    </>
                  ) : (
                    <>
                      <div class="mx-auto print-no-data">No Data</div> <hr />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Demographic</h5>
              </div>
              <div className="col-12">
                <div className="w-100 py-3">
                  <ReduxDemographic
                    {...this.props}
                    disable={true}
                    comboData={comboData}
                    initialValues={DemographicData}
                  />
                </div>
              </div>
            </div>
            <div class="page-break"></div>
            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Addiction Profile</h5>
              </div>
              <div className="col-12  py-3">
                <div className="w-100">
                  {AddictionData && AddictionData.length > 0 ? (
                    <>
                      <ReduxAddictionProfiles
                        {...this.props}
                        disable={true}
                        comboData={comboData}
                        initialValues={{ substanceUseProfiles: AddictionData }}
                      />
                      <div class="page-break"></div>
                    </>
                  ) : (
                    <>
                      <div class="mx-auto print-no-data">No Data</div> <hr />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Previous Treatments</h5>
              </div>
              <div className="col-12 py-3">
                <div className="w-100">
                  {PreviousTreatmentsData && PreviousTreatmentsData.length > 0 ? (
                    <>
                      <ReduxPreviousTreatments
                        {...this.props}
                        disable={true}
                        comboData={comboData}
                        initialValues={{
                          previousTreatments: PreviousTreatmentsData,
                          isAdditionTreatBefore: PreviousTreatmentsData.length > 0,
                        }}
                      />
                      <div class="page-break"></div>
                    </>
                  ) : (
                    <>
                      <div class="mx-auto print-no-data">No Data</div> <hr />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Prior Abuse </h5>
              </div>
              <div className="col-12">
                <div className="w-100 py-3">
                  {PriorAbusesData && PriorAbusesData.length > 0 ? (
                    <>
                      <ReduxPriorAbuses
                        {...this.props}
                        disable={true}
                        comboData={comboData}
                        initialValues={{
                          priorAbuses: PriorAbusesData,
                          isAbused: PriorAbusesData.length > 0,
                        }}
                      />
                      <div class="page-break"></div>
                    </>
                  ) : (
                    <>
                      <div class="mx-auto print-no-data">No Data</div> <hr />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Faith Profile </h5>
              </div>
              <div className="col-12 py-3">
                <div className="w-100 ">
                  <ReduxfaithProfile
                    {...this.props}
                    disable={true}
                    comboData={comboData}
                    initialValues={{
                      ...StudentMoreData,
                      isFaithAffiliation: StudentMoreData.faithTypeId,
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="page-break"></div>
            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Level Of Care</h5>
              </div>
              <div className="col-12 py-3">
                <div className="w-100 ">
                  {StudentFinancialData && StudentFinancialData.length > 0 ? (
                    <>
                      <ReduxLevelOfCares
                        {...this.props}
                        disable={true}
                        comboData={comboData}
                        initialValues={{ financials: StudentFinancialData }}
                      />
                      <div class="page-break"></div>
                    </>
                  ) : (
                    <>
                      <div class="mx-auto print-no-data">No Data</div> <hr />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Payment Of Care</h5>
              </div>
              <div className="col-12 py-3">
                <div className="w-100 ">
                  {FundSourcesData && FundSourcesData.length > 0 ? (
                    <>
                      <ReduxPaymentForCare
                        {...this.props}
                        disable={true}
                        comboData={comboData}
                        initialValues={{ fundSources: FundSourcesData }}
                      />
                      <div class="page-break"></div>
                    </>
                  ) : (
                    <>
                      <div class="mx-auto print-no-data">No Data</div> <hr />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Legal Issues </h5>
              </div>
              <div className="col-12 py-3">
                <div className="w-100 ">
                  <ReduxlegalIssues
                    {...this.props}
                    disable={true}
                    comboData={comboData}
                    initialValues={{ ...LegalIssuesData }}
                  />
                </div>
              </div>
            </div>
            <div class="page-break"></div>
            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Medical History</h5>
              </div>
              <div className="col-12 py-3">
                <div className="w-100 ">
                  {MedicalPastData && MedicalPastData.length > 0 ? (
                    <>
                      <ReduxMedicalHistory
                        {...this.props}
                        disable={true}
                        comboData={comboData}
                        initialValues={{ medicalPast: MedicalPastData }}
                      />
                      <div class="page-break"></div>
                    </>
                  ) : (
                    <>
                      <hr />
                      <div class="mx-auto print-no-data">No Data</div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row py-2 ">
              <div className="col-12">
                <h5 className="mb-4">Health Insurance</h5>
              </div>
              <div className="col-12">
                <div className="w-100 py-3">
                  <ReduxHealthInsurance
                    {...this.props}
                    disable={true}
                    comboData={comboData}
                    initialValues={{
                      healthInsurances: HealthInsurancesData,
                      isHealthInsurance: HealthInsurancesData.length > 0,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ student_get, combos }) => {
  const { data, isFetching, error } = student_get;
  const { data: comboData } = combos;
  return {
    studentInfo: data || {},
    comboData: comboData || {},
    isFetching,
    // initialValues={ studentData }
  };
};

export default connect(mapStateToProps)(PrintEnrollment);
