import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getStudent, resetGetStudent } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import Loader from '../../Components/Loader';

class StudentImage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }

  componentDidMount() {
    this.props.dispatch(getStudent(this.getStudentId()));
  }

  componentWillUnmount() {
    this.props.dispatch(resetGetStudent());
  }

  render() {
    const { student, isFetching } = this.props;

    var imageUrl = 'images/blank-profile.png';
    if (student && student.StudentMisc && student.StudentMisc.length > 0) {
      imageUrl = student.StudentMisc[0].StudentImageUrl;
    }
    return (
      <>
        <Loader loading={isFetching} />
        <div class="col-12 col-sm-6 d-flex flex-wrap align-items-center mb-3 mb-md-0">
          <figure class="students-profile-img mr-3">
            <img src={imageUrl} alt="" width="50" height="50" class="img-background" />
          </figure>
          <h5 class="mb-0 font-weight-semibold">
            {student.FirstName} {student.LastName}
          </h5>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ student_get }) => {
  var { data, isFetching } = student_get;
  return {
    student: data || {},
    isFetching,
  };
};

export default connect(mapStateToProps)(withRouter(StudentImage));
