import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../component/custom-redux-components';
import {
  appSubscribersupdateCase,
  getAppSubscribersProfile,
  loadCaseProfile,
  listCombos,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';

let CaseDetailForm = ({
  onSaveClick,
  onSubmit,
  handleSubmit,
  caseDetail,
  onCancel,
  dispatch,
  AppAdvisor,
  isCenterChanged,
  handelCenterChange,
}) => {
  const {
    ProgramTypeList,
    AdvisorList,
    ExternalIdLabel,
    AdvisorIdLabel,
    ShowProgramType,
    ParticipationStatusList,
    UserStatusList,
    CenterList,
  } = caseDetail;
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field name="CaseID" type="text" component={ReduxInput} fieldName={ExternalIdLabel} />
            <label class="placeholder-label">{ExternalIdLabel}</label>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="Status"
              type="text"
              placeholder={'Status'}
              options={UserStatusList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Status'}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="ParticipationStatus"
              type="text"
              placeholder={'Participation Status'}
              options={ParticipationStatusList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Participation Status'}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field
              name="LegalFirstName"
              type="text"
              component={ReduxInput}
              // validate={Validator.required}
              fieldName={'Legal First Name'}
            />
            <label class="placeholder-label">Legal First Name</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field
              name="LegalMiddleName"
              type="text"
              component={ReduxInput}
              // validate={Validator.required}
              fieldName={'Legal Middle Name'}
            />
            <label class="placeholder-label">Legal Middle Name</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field
              name="LegalLastName"
              type="text"
              component={ReduxInput}
              // validate={Validator.required}
              fieldName={'Legal Last Name'}
            />
            <label class="placeholder-label">Legal Last Name</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field
              name="DOB"
              type="text"
              component={ReduxDateInput}
              label={'Date of Birth'}
              disabled
              // minDate={new Date(2016, 0, 1)}
            />
            <i class="far fa-calendar-alt form-control-icon" />
          </div>
        </div>
        {ShowProgramType && (
          <div class="col-12 col-md-4">
            <div class="form-group">
              <Field
                name="ProgramType"
                type="text"
                placeholder="Program"
                options={ProgramTypeList || []}
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'Program'}
                // disabled={disable}
              />
            </div>
          </div>
        )}
        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="CenterId"
              type="text"
              placeholder={'Center'}
              options={CenterList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Center'}
              // disabled={disable}
              onChange={centerId => {
                dispatch(
                  listCombos({
                    comboTypes: 'AppAdvisor',
                    Json: JSON.stringify({ AppAdvisor: [{ CenterId: centerId }] }),
                  }),
                );
                handelCenterChange(true);
              }}
            />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="ProbationOfficer"
              type="text"
              placeholder={AdvisorIdLabel}
              options={isCenterChanged ? AppAdvisor : AdvisorList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={AdvisorIdLabel}
              // disabled={disable}
            />
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center border-top-0 w-100">
        <input
          type="button"
          class="btn btn-outline-primary mr-2"
          value="Cancel"
          onClick={() => {
            handelCenterChange(false);
            onCancel();
          }}
        />
        <input
          type="submit"
          class="btn black-btn font-weight-bold mr-2"
          value=" Update Changes  "
        />
      </div>
    </form>
  );
};
const ReduxCaseDetailForm = reduxForm({
  form: 'caseForm',
  enableReinitialize: true,
})(connect()(withRouter(CaseDetailForm)));

class CaseDetailModal extends Component {
  constructor() {
    super();
    this.state = {
      caseDetail: {},
      isCenterChanged: false,
    };
  }
  onhandleSubmit = val => {
    const {
      CaseID,
      LegalFirstName,
      LegalMiddleName,
      LegalLastName,
      ProgramType,
      ProbationOfficer,
      ParticipationStatus,
      Status,
      CenterId,
    } = val;
    this.props.dispatch(
      appSubscribersupdateCase({
        UserGuid: this.props.UserGuid,
        json: JSON.stringify({
          Misc: [
            {
              ProgramTypeId: ProgramType,
              ExternalId: CaseID,
              LegalFirstname: LegalFirstName,
              LegalMiddlename: LegalMiddleName,
              LegalLastname: LegalLastName,
              AdvisorStaffId: ProbationOfficer,
              ParticipationStatus: ParticipationStatus,
              Status: Status,
              CenterId: CenterId,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    this.props.dispatch(loadCaseProfile({ UserGuid: this.props.UserGuid }));
  }
  componentWillReceiveProps({ isSuccess, caseDetail }) {
    if (isSuccess && isSuccess !== this.props.isSuccess) {
      // success();
      this.props.dispatch(getAppSubscribersProfile({ UserGuid: this.props.UserGuid }));
      this.props.onCancel();
      this.props.dispatch(reset('caseForm'));
    }
    if (caseDetail && JSON.stringify(caseDetail) != JSON.stringify(this.props.caseDetail)) {
      let initData = {
        ProgramType: caseDetail.ProgramTypeId,
        CaseID: caseDetail.ExternalId,
        LegalFirstName: caseDetail.LegalFirstname,
        LegalMiddleName: caseDetail.LegalMiddlename,
        LegalLastName: caseDetail.LegalLastname,
        ProbationOfficer: caseDetail.AdvisorStaffId,
        ParticipationStatus: caseDetail.ParticipationStatus,
        Status: caseDetail.Status,
        CenterId: caseDetail.CenterId,
        DOB: caseDetail.DOB,
      };
      this.setState({
        caseDetail,
        initData,
      });
    }
  }
  handelCenterChange = isUpdated => {
    this.setState({
      isCenterChanged: isUpdated,
    });
  };
  render() {
    const { onOk, onCancel, Name, isFetching, CenterProgramType } = this.props;
    const { caseDetail, initData } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 mb-3">EDIT CASE DETAILS</h4>
          <p className="text-center">
            Make changes to the case details of <br />
            <strong>{Name}</strong>
          </p>
          <h6></h6>
        </div>

        <div class="modal-body col-md-12 px-5 enrollment-form">
          <Loader2 loading={isFetching} />
          <ReduxCaseDetailForm
            onSaveClick={this.onhandleSubmit.bind(this)}
            onCancel={onCancel}
            caseDetail={caseDetail}
            initialValues={initData}
            AppAdvisor={this.props.AppAdvisor}
            isCenterChanged={this.state.isCenterChanged}
            handelCenterChange={this.handelCenterChange}
            dispatch={this.props.dispatch}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { load_case_profile, update_app_subscribers_case, combos } = state;
  const { data: caseData, isFetching: isCaseFetching } = load_case_profile;
  const { data, isFetching } = update_app_subscribers_case;
  let isSuccess = false;
  let caseDetail = null;
  let AppAdvisor = null;

  if (caseData && caseData.AppUserMiscInfo) {
    caseDetail = caseData.AppUserMiscInfo[0] || {};
  }
  if (update_app_subscribers_case.data && update_app_subscribers_case.data.success) {
    isSuccess = data.success;
  }

  return {
    caseDetail,
    isSuccess,
    isFetching: isFetching || isCaseFetching,
    AppAdvisor: combos.data && combos.data.AppAdvisor,

    // name: selector(state, 'firstName'),
  };
};

export default connect(mapStateToProps)(withRouter(CaseDetailModal));
