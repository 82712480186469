import React, { useState } from 'react';
import { Button } from 'antd';
import Axios from 'axios';
import downloadPdf from '../../../../../util/downloadPDF';

export default function DownloadContractPdf(props) {
  const [isDocFetching, setIsDocFetching] = useState(false);

  const printContractDetailsPdf = async () => {
    let data = {
      JsonInput: JSON.stringify({
        SmartContractId: props.smartContractId,
        ClientId: props.clientId,
      }),
    };
    setIsDocFetching(true);
    const response = await Axios.post('Survey/SmartContractPdf', data);

    if (response.status == 200) {
      const res = await fetch(response.data.pdfUrl);
      // const res = await fetch(
      //   'https://drdevattach.blob.core.windows.net/doc-uploads/05fc0a14-378e-4dac-943f-2b6cf750c00b.pdf?sp=r&st=2023-07-27T08:23:26Z&se=2023-07-28T16:23:26Z&spr=https&sv=2022-11-02&sr=b&sig=mWwBiJECqfy0HRRH2Tp78fihaM39Hr9aNeDbkbLTugM%3D',
      // );

      downloadPdf(res, '');
      setIsDocFetching(false);
    } else if (response.status != 200) {
      setIsDocFetching(false);
    }
    // }
  };

  return (
    <>
      {props && props.children ? (
        <div
          className="col-md-3 d-flex align-items-center"
          style={{ cursor: 'pointer' }}
          onClick={() => printContractDetailsPdf()}
        >
          {props.children}
        </div>
      ) : (
        <Button
          type="button"
          className="btn btn-outline-primary ml-3"
          style={{
            cursor: 'pointer',
            height: '45px',
            width: 'fit-content',
          }}
          onClick={() => printContractDetailsPdf()}
          loading={isDocFetching}
        >
          Download as PDF
        </Button>
      )}
    </>
  );
}
