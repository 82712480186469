import React, { Component } from 'react';
import { Collapse, Tooltip, Tag, Drawer } from 'antd';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import Milestone from './milestone';
import { ReduxSelect, ReduxInput, ReduxTextarea } from '../../../component/custom-redux-components';
const { Panel } = Collapse;
const Goal = props => {
  const { fields, existingRows } = props;
  return (
    <>
      <Collapse defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}>
        {fields && fields.length > 0 ? (
          fields.map((item, index) => {
            return (
              <>
                <Panel
                  showArrow={true}
                  key={index}
                  className="goal-panel-border"
                  header={
                    <>
                      <div className="row">
                        <div className="col-md-3 panel-header-text">{`Goal`}</div>
                        {/* <div className="col-md-9 text-right d-flex">
                          {!props.disabled && (
                            <button
                              type="button"
                              class="btn-newCon"
                              style={{
                                position: 'unset',
                                width: 'unset',
                                background: 'unset',
                              }}
                              onClick={event => {
                                event.stopPropagation();
                                fields.push();
                              }}
                            >
                              + Add {fields.length == 0 ? 'Goal' : 'Another Goal'}
                            </button>
                          )}
                          {fields.length > 1 && (
                            <div>
                              {!props.disabled && (
                                <a
                                  class="col-1 delete-contract"
                                  onClick={() => {
                                    if (fields.length && fields.length > 0) {
                                      fields.remove(index);
                                    }
                                  }}
                                >
                                  <i class="fas fa-trash" />
                                </a>
                              )}
                            </div>
                          )}
                        </div> */}
                      </div>
                    </>
                  }
                >
                  <div className="col-md-12 mb-3 mt-2">
                    <div class="form-group">
                      <FieldArray
                        name={`${item}Milestone`}
                        type="text"
                        component={Milestone}
                        fieldName={'Milestone'}
                        taskCombo={props.taskCombo}
                      />
                    </div>
                  </div>
                </Panel>
              </>
            );
          })
        ) : (
          <></>
        )}
      </Collapse>

      {/* <Collapse>
        <Panel
          showArrow={true}
          className="goal-panel-border"
          header={
            <>
              <div className="row">
                <div className="col-md-3 panel-header-text">{`Goal`}</div>
              </div>
            </>
          }
        >
          <div className="col-md-12 mb-3 mt-2">
            <div class="form-group">
              <FieldArray
                name={`${item}Milestone`}
                type="text"
                component={Milestone}
                fieldName={'Milestone'}
              />{' '}
              <label class="placeholder-label">Milestone*</label>
            </div>
          </div>
        </Panel>
      </Collapse> */}
    </>
  );
};
export default Goal;
