import React, { Component } from 'react';

export const HasMandatoryQuestion = (surveyQuestionAssessment, SignatureCanvas) => {
  // const mandatoryQuestion =
  //   surveyQuestionAssessment &&
  //   surveyQuestionAssessment.SurveyQuestion.some(qitem => {
  //     return qitem.IsRequired && !qitem.Response;
  //   });
  const SignatureCanvasList = SignatureCanvas ? Object.keys(SignatureCanvas) : [];
  const mandatoryQuestion =
    surveyQuestionAssessment &&
    surveyQuestionAssessment.SurveyQuestion &&
    surveyQuestionAssessment.SurveyQuestion.some(qitem => {
      if (qitem.IsRequired) {
        if (qitem.SurveyQuestionTypeId == 14) {
          let hasSignature = SignatureCanvasList.includes(`${qitem.SurveyQuestionId}`);
          return !qitem.Response && !hasSignature;
        } else {
          return qitem.Response == 0 || qitem.Response ? false : true;
        }
      } else {
        return false;
      }
    });
  return mandatoryQuestion;
};

export const HasMandatoryQuestionWarning = ({ hasMandatory }) => {
  return (
    hasMandatory && (
      <span className="text-danger">
        Kindly respond to all the required questions in order to proceed with your submission.
      </span>
    )
  );
};

export const UpdateResponse = ({
  assessmentQuestion,
  response,
  index,
  level,
  subLevel,
  subCatLevel,
}) => {
  let AssessmentQuestion = assessmentQuestion;
  if (level === 0) {
    AssessmentQuestion.SurveyQuestion[index].Response = response;
  } else if (level === 1) {
    AssessmentQuestion.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
  } else if (level === 2) {
    AssessmentQuestion.SurveyCategory[subLevel].SurveySubCategory[subCatLevel].SurveyQuestion[
      index
    ].Response = response;
  }
  return AssessmentQuestion;
};
