import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

export default props => {
  const { survey } = props;
  const { QuestionHelp } = survey;
  return (
    <div className="type-border col-12">
      <div>
        <SurveyQuestion survey={survey} ClassName={'instruction-type'} />
        <span className="m-2">{QuestionHelp && renderHTML(QuestionHelp)} </span>
      </div>
    </div>
  );
};
