import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button, Drawer, Tooltip } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import {
  announcementGetAll,
  appAnnouncementListGetAll,
  appAnnouncementUpsert,
  appAnnouncementGet,
} from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import PageTitle from '../Components/PageTitle';
import CustomModal from '../core/modal';
import ListRenderer from '../Components/listRenderer';
import { stringDate } from '../util/date';
import renderHTML from 'react-render-html';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoComplete,
  ReduxCheckbox,
} from '../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  submit,
} from 'redux-form';

// import {  } from '../store/actions';

import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditorComponent from 'react-ckeditor-component';

const activeKey = 0;

const announcementColumns = [
  {
    title: 'Image URL',
    dataIndex: 'ImageUrl',
    key: 'imageUrl',
    render: (text, record) => (
      <div class="student-list-pic">
        <img
          src={text ? text : 'images/announcements-img2.jpg'}
          class="img-circle"
          height="40"
          width="40"
          style={{ borderRadius: '50%' }}
        />
      </div>
    ),
  },
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'title',
    sorter: (a, b) => isComparer(a.Title, b.Title),
    sortDirections: ['descend', 'ascend'],
  },
  /*{
    title: 'Abstract',
    dataIndex: 'Abstract',
    key: 'abstract',
    sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => <div style={{ width: '200px' }}>{text}</div>,
  },*/
  {
    title: 'Start Date',
    dataIndex: 'StartDate',
    key: 'startDate',
    sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'End Date',
    dataIndex: 'EndDate',
    key: 'endDate',
    sorter: (a, b) => isDateComparer(a.EndDateForSort, b.EndDateForSort),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Lead Story',
    dataIndex: 'IsMain',
    key: 'isMain',
    sorter: (a, b) => (a.IsMain, b.IsMain),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => text && <div style={{ width: '200px' }}>{`${text}`}</div>,
  },
];

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  componentWillMount() {}

  componentDidMount() {
    this.props.dispatch(announcementGetAll({ filter: null, searchStr: this.state.searchStr }));
  }

  onSearchChange = control => {
    var value = control.target.value;
    this.state.searchStr = value;
    if (value.length > 1) {
      this.props.dispatch(announcementGetAll({ filter: null, searchStr: this.state.searchStr }));
    } else if (value.length == 0) {
      this.props.dispatch(announcementGetAll({ filter: null, searchStr: null }));
    }
  };

  onRowClick = announcementId => {
    this.props.history.push('/manage/announcement-form?announcementId=' + announcementId);
  };

  onManageClick = announcementId => {
    this.props.history.push('/manage/staff?announcementId=' + announcementId);
  };

  render() {
    const { isFetching, announcements } = this.props;

    return (
      <div class="main  mb-5">
        <div class="container-fluid">
          <PageTitle TitleText="Announcement List" />
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12  text-right d-flex justify-content-between align-items-center">
              <div class="search-box w-100">
                <div class="form-group has-search w-75">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>
              <Link to="/manage/announcement-form/" class="btn" title="Add a Announcement">
                Add Announcement
              </Link>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="row-hover-enabled">
                      <Table
                        columns={announcementColumns}
                        dataSource={announcements}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: event => this.onRowClick(record.AnnouncementId),
                          };
                        }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropsAnnouncement = ({ announcement_get_all }) => {
  var { data, isFetching, error } = announcement_get_all;
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }
  return {
    announcements: data || [],
    isFetching,
  };
};

Announcement = connect(mapStateToPropsAnnouncement)(Announcement);

class ActivityBase extends React.Component {}

const Validator = {
  required: value => (value ? undefined : 'Required'),
  characterLimit: value =>
    value ? (value.length > 100 ? 'cannot exceed 100 characters' : undefined) : 'Required',
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

class AppAnnouncementform extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      announcementData: {},
      isModalOpen: false,
      header: '',
      isRemoveModelOpen: false,
      appAnnouncementId: null,
    };
  }

  getAnnouncementId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var announcementId = param.get('appannouncementId') || param.get('appannouncementId');
    if (announcementId) {
      return (announcementId = parseInt(announcementId));
    } else {
      announcementId = -1;
    }
    return announcementId;
  }

  componentWillReceiveProps({ GetAnnouncementData, existingAnnouncement }) {
    const { AppAnnouncementId, CenterId, HtmlContent, IsPublished, Title } = GetAnnouncementData;
    if (existingAnnouncement) {
      const {
        AppAnnouncementId: appId,
        Title: appTitle,
        HtmlContent: appContent,
      } = existingAnnouncement;
    }
    if (GetAnnouncementData) {
      var announcement = {
        title: Title,
        htmlContent: HtmlContent,
        isPublished: IsPublished,
      };
      this.setState({ announcementData: announcement });
    }
    if (
      existingAnnouncement &&
      JSON.stringify(existingAnnouncement) != JSON.stringify(this.props.existingAnnouncement)
    ) {
      this.setState({ appAnnouncementId: existingAnnouncement.AppAnnouncementId });
      var data = {
        AppAnnouncement: [
          {
            AppAnnouncementId: existingAnnouncement.AppAnnouncementId,
          },
        ],
      };
      var announcementData = {
        jsonInput: JSON.stringify(data),
      };
      this.props.dispatch(appAnnouncementGet(announcementData));
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  componentDidMount() {
    // var data = {
    //   AppAnnouncement: [
    //     {
    //       AppAnnouncementId: this.state.appAnnouncementId,
    //     },
    //   ],
    // };
    // var announcementData = {
    //   jsonInput: JSON.stringify(data),
    // };
    // this.props.dispatch(appAnnouncementGet(announcementData));
    this.props.dispatch(
      appAnnouncementListGetAll({ filter: null, searchStr: this.state.searchStr }),
    );
  }

  onSubmit(values) {
    //
    const {
      abstract,
      centerId,
      endDate,
      imageUrl,
      isMain,
      startDate,
      tags,
      title,
      updatedBy,
      link,
    } = values;

    // var announcementId = null;

    // var paramAnnouncementId = this.getAnnouncementId();
    // if (paramAnnouncementId && paramAnnouncementId > 0) {
    //   announcementId = paramAnnouncementId;
    // }

    var data = {
      Announcement: [
        {
          Abstract: abstract,
          // AnnouncementId: announcementId,
          CenterId: centerId,
          EndDate: endDate,
          ImageUrl: imageUrl,
          IsMain: isMain,
          StartDate: startDate,
          Tags: tags,
          Title: title,
          UpdatedBy: updatedBy,
          Link: link,
        },
      ],
    };
    var announcementData = {
      jsonInput: JSON.stringify(data),
    };
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle TitleText="App Announcement" />
        <ReduxAnnouncementForm
          onSubmit={this.onSubmit.bind(this)}
          initialValues={this.state.announcementData}
          onCancel={() => this.props.history.push('/manage/announcements')}
          Delete={() => {
            this.Removetoggle();
          }}
          history={this.props.history}
          htmlContent={this.state.announcementData.htmlContent}
          isFetching={this.props.isFetching}
          // PublishedUser = {existingAnnouncement.PublishedUser}
        />

        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/announcements');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(announcementDelete({ AnnouncementId: this.getAnnouncementId() }));
            // this.props.history.push('/manage/announcements');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            this.props.history.push('/manage/announcements');
          }}
        />
      </React.Fragment>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: null,
      showLoader: false,
      isModelOpen123: false,
      appAnnouncementId: null,
      publishedUser: null,
      searchStr: null,
      showContentDrawer: false,
      showAnnouncementForm: false,
      columns: [
        {
          title: 'Title',
          dataIndex: 'Title',
          key: 'title',
          sorter: (a, b) => isComparer(a.Title, b.Title),
          sortDirections: ['descend', 'ascend'],
          width: 400,
        },

        {
          title: 'Publisher',
          dataIndex: 'PublishedUser',
          key: 'PublishedUser',
          sorter: (a, b) => isComparer(a.PublishedUser, b.PublishedUser),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          dataIndex: 'IsPublished',
          key: 'IsPublished',
          sorter: (a, b) => isComparer(a.IsPublished.toString(), b.IsPublished.toString()),
          sortDirections: ['descend', 'ascend'],
          render: (text, record) => (text ? 'Active' : 'Inactive'),
        },
        {
          title: 'Last Updated',
          dataIndex: 'ModifiedDate',
          key: 'ModifiedDate',
          sorter: (a, b) => isComparer(a.ModifiedDateForSort, b.ModifiedDateForSort),
          sortDirections: ['descend', 'ascend'],
          render: (text, record) => text && <div>{`${text}`}</div>,
        },

        // {
        //   title: 'Date Published',
        //   dataIndex: 'DatePublished',
        //   key: 'datePublished',
        //   sorter: (a, b) => isDateComparer(a.DatePublishedForSort, b.DatePublishedForSort),
        //   sortDirections: ['descend', 'ascend'],
        //   render: (text, record) => text && <div>{`${text}`}</div>,
        // },
        // {
        //   title: 'Content',
        //   dataIndex: 'HtmlContent',
        //   key: 'htmlContent',
        //   sorter: (a, b) => isComparer(a.HtmlContent, b.HtmlContent),
        //   sortDirections: ['descend', 'ascend'],
        //   render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
        // },
        {
          title: 'Action',
          key: 'x',
          // sorter: (a, b) => isComparer(a.Title, b.Title),
          // sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => (
            <div
              class="tools-grid"
              style={{
                justifyContent: 'space-evenly',
                display: 'flex',
                alignItems: 'center',
                height: '39px',
                width: '66px',
              }}
            >
              <span
                onClick={e => {
                  e.preventDefault();
                  this.toggleContentDrawer(record.HtmlContent);
                }}
                style={{ display: 'flex', cursor: 'pointer' }}
              >
                <Tooltip placement="top" title={'View'}>
                  <img src="images/view.svg" />
                </Tooltip>
              </span>
              <span
                onClick={e => {
                  e.preventDefault();
                  this.props.history.push('/manage/app-announcement-form?indexId=' + index);
                }}
                style={{ display: 'flex', cursor: 'pointer' }}
              >
                <Tooltip placement="top" title={'Clone'}>
                  <img src="images/cloneIcon.svg" />
                </Tooltip>
              </span>
            </div>
          ),
        },
      ],
    };
  }

  toggleContentDrawer = appAnnouncementContent => {
    this.setState({ showContentDrawer: true, appAnnouncementContent });
  };

  getAnnouncementId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var announcementId = param.get('appannouncementId') || param.get('appannouncementId');
    if (announcementId) {
      return (announcementId = parseInt(announcementId));
    } else {
      announcementId = -1;
    }
    return announcementId;
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen123: !prevState.isModelOpen123,
      header,
    }));
  };

  componentWillReceiveProps({ isSuccess, htmlContent, existingAnnouncement }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.toggle(Upsert_Sucess_Msg);
    }
    if (htmlContent != this.props.htmlContent) {
      this.setState({ content: htmlContent });
    }
    if (existingAnnouncement) {
      const {
        AppAnnouncementId: appId,
        Title: appTitle,
        HtmlContent: appContent,
        PublishedUser,
      } = existingAnnouncement;
      this.setState({ appAnnouncementId: appId, publishedUser: PublishedUser });
    }
  }

  componentDidMount() {
    this.props.dispatch(
      appAnnouncementListGetAll({ filter: null, searchStr: this.state.searchStr }),
    );
    this.props.dispatch(appAnnouncementGet({}));
  }
  onAnnouncementSubmit = values => {
    const { title, isPublished } = values;
    var announcementId = null;

    var paramAnnouncementId = this.getAnnouncementId();

    if (paramAnnouncementId && paramAnnouncementId > 0) {
      announcementId = paramAnnouncementId;
    }
    var data = {
      AppAnnouncement: [
        {
          AppAnnouncementId: this.state.appAnnouncementId,
          Title: title,
          HtmlContent: this.state.content,
          IsPublished: isPublished,
        },
      ],
    };
    // if (this.getAnnouncementId() > 0) {
    //   data.News[0].NewsId = this.getAnnouncementId();
    // }
    var announcementData = {
      AppAnnouncementId: announcementId,
      jsonInput: JSON.stringify(data),
    };
    this.props.dispatch(appAnnouncementUpsert(announcementData));
    // this.setState({ showLoader: true });
    this.props.dispatch(
      appAnnouncementListGetAll({ filter: null, searchStr: this.state.searchStr }),
    );
  };
  onSearchChange = control => {
    var value = control.target.value;
    this.state.searchStr = value;
    if (value.length > 1) {
      this.props.dispatch(
        appAnnouncementListGetAll({ filter: null, searchStr: this.state.searchStr }),
      );
    } else if (value.length == 0) {
      this.props.dispatch(appAnnouncementListGetAll({ filter: null, searchStr: null }));
    }
  };
  updateContent = newContent => {
    this.setState({
      content: newContent,
    });
  };

  onChange = evt => {
    let newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  // onRowClick = index => {
  //   this.props.history.push('/manage/app-announcement-form?indexId=' + index);
  // };

  render() {
    const { isFetching, historyAnnouncements } = this.props;
    return (
      <div class="main enrollment-page mb-5">
        <div class="container-fluid">
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-12 col-lg-12 text-left d-flex align-items-center">
              {this.state.showAnnouncementForm && (
                <a
                  class="back-link"
                  onClick={() => {
                    this.setState({
                      showAnnouncementForm: false,
                    });
                  }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img src="images/back-arrow.svg" /> &nbsp; Back
                </a>
              )}
            </div>
            <div class="col-12 col-md-12 col-lg-12 text-right">
              {/* {!this.state.showAnnouncementForm && (
                <button
                  className="btn black-btn p-2"
                  onClick={() => {
                    this.setState({
                      showAnnouncementForm: true,
                    });
                  }}
                >
                  Add App Announcement
                </button>
              )} */}
              <Link
                to="/manage/app-announcement-form"
                class="btn"
                title="Add a Announcement"
                style={{ padding: '9.5px 30px' }}
              >
                Add App Announcement
              </Link>
            </div>
          </div>
          {this.state.showAnnouncementForm && (
            <form onSubmit={this.props.handleSubmit(this.onAnnouncementSubmit)}>
              <Loader2 loading={isFetching} />

              <div class="mb-4 col-12 col-md-12 col-lg-12 d-flex justify-content-between">
                <h3 class="form-main-head mb-3 mb-md-0">
                  {this.state.appAnnouncementId > 0
                    ? `${this.state.appAnnouncementId}Edit App Announcement`
                    : 'Add App Announcement'}
                </h3>
              </div>

              <div class="row  mb-2">
                <div class="col-12 mb-4">
                  <div class="card border-0 rounded-0 ">
                    <div class="card-body enrollment-form">
                      <div class="row pb-5 pl-3 pr-3 pt-3">
                        <div class="col-12 col-md-6 col-lg-6">
                          <div class="form-group">
                            <Field
                              name="title"
                              type="text"
                              errorMessage="Title Required"
                              component={ReduxInput}
                              validate={[Validator.characterLimit]}
                              fieldName={'Title'}
                            />
                            <label class="placeholder-label">Title*</label>
                          </div>
                        </div>

                        <div class="col-12 col-md-12 col-lg-12">
                          <CKEditorComponent
                            activeClass="p10"
                            content={this.state.content}
                            events={{
                              blur: this.onBlur,
                              afterPaste: this.afterPaste,
                              change: this.onChange,
                            }}
                            validate={[Validator.required]}
                          />
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 mt-3">
                          <div class="form-group">
                            <Field
                              className="mr-2"
                              name="isPublished"
                              component={ReduxCheckbox}
                              fieldName={'isPublished'}
                            />
                            <label class="placeholder-label" style={{ top: '5px' }}>
                              Active
                            </label>
                          </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end align-items-center">
                          <button
                            class="btn btn-outline-primary mr-2"
                            onClick={() => {
                              this.props.history.push('/manage');
                            }}
                          >
                            Cancel
                          </button>
                          {/* )} */}
                          &nbsp;
                          <Button
                            disabled={!this.state.content}
                            className="btn save-button"
                            htmlType="submit"
                            value="submit"
                            style={{ padding: '0 24px', height: '44px' }}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
          {!this.state.showAnnouncementForm && (
            <div>
              <div class="col-12 mt-1 justify-content-between">
                <div class="col-6 align-self-center">
                  {/* <p class = "d-inline" style={{fontSize: '17px'}}>  :</p> */}
                  <h5 class="d-inline">App Announcement History</h5>
                </div>
                {/* <div class="search-box col-6 w-100">
                <div class="form-group has-search w-100 announcement-search">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div> */}
                <div className="w-100">
                  <Table
                    columns={this.state.columns}
                    dataSource={historyAnnouncements}
                    // onRow={(record, rowIndex) => {
                    //   return {
                    //     onClick: event => this.onRowClick(rowIndex),
                    //   };
                    // }}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div class="dataTables_wrapper no-footer">
                            <div class="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a class="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a class="paginate_button next">Next</a>
                              ) : (
                                <a class="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      // hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <CustomModal
            isOpen={this.state.isModelOpen123}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              this.props.history.push('/manage/Announcements');
            }}
            yesText="OK"
            hideCancel={true}
          />

          <Drawer
            className="custom-drawer"
            title="Announcement Content"
            placement="right"
            width="600px"
            closable={true}
            onClose={() => {
              this.setState({ showContentDrawer: false });
            }}
            open={this.state.showContentDrawer}
          >
            <div style={{ width: '300px' }}>
              {this.state.appAnnouncementContent && renderHTML(this.state.appAnnouncementContent)}
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  app_announcement_upsert,
  app_announcement_list_get_all,
  app_announcement_get,
}) => {
  var { data: appData } = app_announcement_list_get_all;
  let existingAnnouncement;
  if (appData && appData.AppAnnouncement) {
    existingAnnouncement = appData.AppAnnouncement[0];
  }
  let { data: history } = app_announcement_get;
  let historyAnnouncements;
  if (history && history.AppAnnouncement_History) {
    historyAnnouncements = history.AppAnnouncement_History;
  }

  var { data, isFetching, error } = app_announcement_upsert;
  let isSuccess = false;
  if (data && data.success) {
    isSuccess = data.success;
  }
  //shubham
  return {
    isSuccess: isSuccess,
    existingAnnouncement,
    historyAnnouncements,
  };
};

const ReduxAnnouncementForm = reduxForm({
  form: 'AppAnnouncementform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(Example)));

const selector = formValueSelector('AppAnnouncementform');

const mapStateToPropsParent = ({ app_announcement_get, app_announcement_list_get_all }) => {
  var { data: getAnnouncementData, isFetching, error } = app_announcement_get;
  let { data: appData } = app_announcement_list_get_all;
  let existingAnnouncement;
  if (appData && appData.AppAnnouncement) {
    existingAnnouncement = appData.AppAnnouncement[0];
  }

  if (app_announcement_get && app_announcement_get.data) {
    getAnnouncementData = app_announcement_get.data.AppAnnouncement_History;
  }

  return {
    GetAnnouncementData:
      app_announcement_get && app_announcement_get.data && app_announcement_get.data.AppAnnouncement
        ? app_announcement_get.data.AppAnnouncement[0]
        : {},
    isFetching: isFetching ? isFetching : false,
    existingAnnouncement,
  };
};

AppAnnouncementform = withRouter(connect(mapStateToPropsParent)(AppAnnouncementform));
class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddNodeModelOpen: false,
      AddCourseModelOpen: false,
    };
  }
  toggle = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  list = [
    {
      Name: <span className="border-image">Web Announcements</span>,
      ExtraComponent: () => <Announcement {...this.props} />,
    },
    {
      Name: <span className="border-image">App Announcement</span>,
      ExtraComponent: () => <AppAnnouncementform {...this.props} />,
    },
  ];
  render() {
    return (
      <div className="main  mb-5 Announcements-page">
        {/* <Loader loading={loading} /> */}
        <div className="container-fluid">
          <div className="row top-table-search mt-5 mb-3 align-items-center">
            <div className="col-12">
              <Link to="/manage" className="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link>
              <h3 className="form-main-head mb-3 mb-md-0">Announcements</h3>
            </div>
          </div>
          <div className="row mb-4">
            <div class="col-12 mb-5">
              <div className="vertical-tabs">
                <ListRenderer
                  list={this.list}
                  activeKey={activeKey}
                  tabPosition="left"
                  destroyInactiveTabPane={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Announcements);
