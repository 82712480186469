import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusPercentageInput,
} from '../../../../component/custom-redux-components';
import ServiceType from './serviceType';
import BuildServiceBundle from './buildServiceBundle';
import {
  centerOverHeadCostGet,
  serviceCodeBundleUpsert,
  resetServiceCodeBundleUpsert,
  serviceCodeBundleGetAll,
  resetServiceCodeBundleGet,
  serviceCodeBundleGet,
} from '../../../../store/actions';
import validate from '../../../../core/generic-form/validation';
import CustomModal from '../../../../core/modal';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
import { getStringType } from '../../../../util/multiSelectDropDownStringConverter';
import SubmitButton from '../../../../Components/submitButton';
const Form2 = props => {
  const {
    handleSubmit,
    onSaveClick,
    centerProgramType,
    centerServiceTypeCenter,
    totalCostOfCare,
    netMarginAmount,
    billingRate,
    centerOverHeadCostState,
    setTotalCostOfCare,
    initialValues,
    isFetchingBundleUpsert,
    existingRows,
  } = props;

  const { DurationFrequencyCombo, isFetching } = useSelector(({ combos }) => {
    return {
      DurationFrequencyCombo: combos && combos.data['DurationFrequency'],
      isFetching,
    };
  }, shallowEqual);

  return (
    <>
      <form onSubmit={handleSubmit(onSaveClick)}>
        {' '}
        <div className="row mt-3">
          <div className="col-md-6">
            <h5>
              {initialValues && initialValues.ServiceCodeBundleId ? 'Edit' : 'New'} Service Bundle
            </h5>
          </div>
          <div className="col-md-6 text-right">
            <span
              className="btn btn-outline-primary mr-2"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.history.push('/cw/manage/master-bill-services?tab=service-bundles')
              }
            >
              Cancel
            </span>
            {existingRows &&
            existingRows.length > 0 &&
            existingRows.every(
              item =>
                item &&
                item.BuildServiceBundle &&
                item.BuildServiceBundle.length > 0 &&
                item.BuildServiceBundle.every(item => item != undefined),
            ) ? (
              <SubmitButton
                // type="submit"
                style={{ height: '45px' }}
                // class="btn"
                value={
                  initialValues && initialValues.ServiceCodeBundleId
                    ? 'Update Bundle'
                    : 'Create Bundle'
                }
                loading={props.isFetchingBundleUpsert}
              />
            ) : (
              <input
                style={{ width: '160px', backgroundColor: '#954141', marginBottom: 'unset' }}
                class="btn"
                value={
                  initialValues && initialValues.ServiceCodeBundleId
                    ? 'Update Bundle'
                    : 'Create Bundle'
                }
                disabled={true}
              />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-9">
            <div className="card" style={{ border: 'none' }}>
              <div className="card-body enrollment-form">
                <h5>BUNDLE DETAILS</h5>
                <div class="row">
                  <div class="col-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <Field
                        name="Description"
                        type="text"
                        component={ReduxInput}
                        //   errorMessage="Center Name Required"
                        validate={validate.required}
                        fieldName={'Bundle Name'}
                      />
                      <label class="placeholder-label">Bundle Name*</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <Field
                        name="DaysInTreatment"
                        type="number"
                        component={ReduxInput}
                        //   errorMessage="Center Name Required"
                        validate={validate.required}
                        fieldName={'Number Of Days'}
                        className="numbers-only"
                        max={365}
                        disableOnPaste={true}
                      />
                      <label class="placeholder-label">Duration*</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`FrequencyId`}
                        type="text"
                        component={ReduxSelect}
                        options={DurationFrequencyCombo}
                        validate={validate.required}
                        placeholder={`Frequency*`}
                        fieldName={'Frequency'}
                        class="form-control"
                        disabled={props.disabled}
                      />
                    </div>
                  </div>
                  {/* <div class="col-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <Field
                        name="ProgramId"
                        type="text"
                        component={ReduxSelect}
                        placeholder="Select Service Type*"
                        //   errorMessage="Center Name Required"
                        validate={validate.required}
                        fieldName={'Service Type'}
                        // options={centerProgramType}
                        options={centerServiceTypeCenter}
                        isMulti={true}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card mt-2" style={{ border: 'none' }}>
              <div className="card-body enrollment-form">
                <h5>BUILD SERVICE BUNDLE</h5>
                <div div class="col-12 col-md-12 col-lg-12">
                  <FieldArray
                    name="ServiceType"
                    component={ServiceType}
                    initialValues={
                      props.initialValues && props.initialValues.ServiceType
                      // initialValues={
                      //   (props.initialValues && props.initialValues.BuildServiceBundle) ||
                      //   (props.initialValues && props.initialValues.Service)
                      // }
                    }
                    setIndexOfNetMarginChange={props.setIndexOfNetMarginChange}
                    setCurrentServiceTypeIndex={props.setCurrentServiceTypeIndex}
                    centerOverHeadCostState={centerOverHeadCostState}
                    setTotalCostOfCare={setTotalCostOfCare}
                    existingRows={existingRows}
                    centerServiceTypeCenter={centerServiceTypeCenter}
                    setFieldChanged={props.setFieldChanged}
                  />
                  {/* <FieldArray
                    name="BuildServiceBundle"
                    component={BuildServiceBundle}
                    initialValues={
                      (props.initialValues && props.initialValues.BuildServiceBundle) ||
                      (props.initialValues && props.initialValues.Service)
                    }
                    setIndexOfNetMarginChange={props.setIndexOfNetMarginChange}
                    centerOverHeadCostState={centerOverHeadCostState}
                    setTotalCostOfCare={setTotalCostOfCare}
                    existingRows={existingRows}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 text-left">
            <div className="card" style={{ border: 'none' }}>
              <div className="card-body row">
                <h5 className="col-12">COST SUMMARY</h5>

                {/* new details to show */}
                <div className="col-12">
                  <div
                    class="license-quantity justify-content-left"
                    style={{ fontWeight: '600', display: 'none' }}
                  >
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '16px', whiteSpace: 'nowrap' }}
                    >
                      Total Overhead %
                    </label>
                    <div class="">
                      <div className="d-flex justify-content">
                        <span>{props.initialValues && props.initialValues.TotalOverHead}</span>
                        <span>%</span>
                      </div>
                      {/* <Field
                        name={`TotalOverHead`}
                        type="text"
                        min={0}
                        max={100}
                        component={ReduxPlusMinusPercentageInput}
                        disableOnPaste={true}
                        validate={validate.requiredZeroAtleast}
                        fieldName={'Total Over Head'}
                        className="number-icon"
                        style={{
                          textAlign: 'center',
                          margin: '0px 5px',
                          height: '32px',
                          width: '56px',
                          fontSize: '16px',
                        }}
                      /> */}
                    </div>
                  </div>

                  <div
                    class="justify-content-left"
                    style={{
                      fontWeight: '600',
                      borderBottom: '1px solid #D8D8D8',
                      display: 'none',
                    }}
                  >
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '16px', whiteSpace: 'nowrap' }}
                    >
                      Total Cost of Care
                    </label>
                    <div class="">
                      {/* {!isNaN(totalCostOfCare)
                        ? totalCostOfCare
                          ? `$${totalCostOfCare}`
                          : '$0'
                        : '$0'} */}
                      {!isNaN(totalCostOfCare)
                        ? currencyFormatter.format(totalCostOfCare || 0)
                        : '$0'}
                    </div>
                  </div>

                  <div
                    class="license-quantity  justify-content-left mt-4"
                    style={{ fontWeight: '600', display: 'none' }}
                  >
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '16px', whiteSpace: 'nowrap' }}
                    >
                      Net Margin %
                    </label>
                    <div class="">
                      <div className="d-flex justify-content">
                        <span>{props.initialValues && props.initialValues.NetMargin}</span>
                        <span>%</span>
                      </div>
                      {/* <Field
                        name={`NetMargin`}
                        type="text"
                        min={0}
                        max={100}
                        component={ReduxPlusMinusPercentageInput}
                        disableOnPaste={true}
                        // validate={validate.required}
                        fieldName={'Net Margin Needed'}
                        className="number-icon"
                        style={{
                          textAlign: 'center',
                          margin: '0px 5px',
                          height: '32px',
                          width: '56px',
                          fontSize: '16px',
                        }}
                      /> */}
                    </div>
                  </div>

                  <div
                    class="justify-content-left"
                    style={{
                      fontWeight: '600',
                      borderBottom: '1px solid #D8D8D8',
                      display: 'none',
                    }}
                  >
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '16px', whiteSpace: 'nowrap' }}
                    >
                      Net Margin Amount
                    </label>
                    <div class="">
                      {netMarginAmount ? currencyFormatter.format(netMarginAmount || 0) : '$0'}
                    </div>
                  </div>

                  <div
                    class="license-quantity d-flex  justify-content-left mt-4"
                    style={{ fontWeight: '600' }}
                  >
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
                    >
                      Average Overhead %
                    </label>
                    <div class="">
                      <div className="d-flex justify-content">
                        <span>{props.avgOverHeadPercentage}</span>
                        <span>%</span>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex  justify-content-left" style={{ fontWeight: '600' }}>
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
                    >
                      {/* Total Overhead % Value */}
                      {/* Average Overhead % Value */}
                      Total Overhead $
                    </label>
                    <div class="">
                      {/* {!isNaN(props.bundleOverheadPecentageValue)
                        ? currencyFormatter.format(props.bundleOverheadPecentageValue || 0)
                        : '$0'} */}
                      {!isNaN(props.avgOverHeadValue)
                        ? currencyFormatter.format(props.avgOverHeadValue || 0)
                        : '$0'}
                    </div>
                  </div>

                  <div
                    class="license-quantity d-flex  justify-content-left mt-4"
                    style={{ fontWeight: '600' }}
                  >
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
                    >
                      Average Net Margin %
                    </label>
                    <div class="">
                      <div className="d-flex justify-content">
                        <span>{props.avgNetMarginPercentage}</span>
                        <span>%</span>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex  justify-content-left" style={{ fontWeight: '600' }}>
                    <label
                      class="placeholder-label flex-grow-1 w-100"
                      style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
                    >
                      {/* Average Net Margin % Value */}
                      Total Net Margin $
                    </label>
                    <div class="">
                      {/* {!isNaN(props.bundleOverheadPecentageValue)
                        ? props.bundleOverheadPecentageValue
                          ? currencyFormatter.format(props.bundleOverheadPecentageValue || 0)
                          : '$0'
                        : '$0'} */}
                      {!isNaN(props.avgNetMarginValue)
                        ? currencyFormatter.format(props.avgNetMarginValue || 0)
                        : '$0'}
                    </div>
                  </div>

                  <div className="mt-3 d-flex align-items-center justify-content-between">
                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>Billing Rate</span>
                    {/* <span>{billingRate ? currencyFormatter.format(billingRate || 0) : '$0'}</span> */}
                    <span>{currencyFormatter.format(props.bundleBillingRate || 0)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const ReduxForm2 = reduxForm({
  form: 'serviceBundle',
  enableReinitialize: true,
})(connect()(withRouter(Form2)));

export default ReduxForm2;
