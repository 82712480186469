import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import { FormGroup, Input } from 'reactstrap';
import { Switch, Checkbox, Radio } from 'antd';
import TextareaAutosize from 'react-autosize-textarea';
import DictationComponent from '../../../../../../Components/DictationComponent';
import { Qualifier, Flagged } from '../flag';
import ToggleNote from '../toggleNote';

let noteTimer;

class ToggleRadioOptionsType extends React.Component {
  constructor(props) {
    super(props);
    let { Response, Note } = props.survey;
    this.state = {
      value: Response || null,
      noteValue: Note,
      toggleActive: Note ? true : false,
    };
  }
  getDicatationStatus = value => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    let timer;
    return () => {
      clearTimeout(timer);
      setTimeout(() => {
        setValue.call(scope, {
          response: this.state.noteValue,
          surveyQuestionId: SurveyQuestionId,
          level: level,
          subLevel: subLevel,
          subCatLevel: subCatLevel,
          index: index,
        });
      }, 3000);
    };
  };
  onChange = (event, click) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    const { value } = event.target;
    this.setState({
      noteValue: value,
    });
    clearTimeout(noteTimer);
    noteTimer = setTimeout(() => {
      setValue.call(scope, {
        response: this.state.value,
        note: value,
        surveyQuestionId: SurveyQuestionId,
        level: level,
        subLevel: subLevel,
        subCatLevel: subCatLevel,
        index: index,
      });
    }, 1000);
  };
  getNoteChanges = () => {
    this.setState({ noteValue: '' });
  };
  handleDictate = result => {
    if (result) {
      let data;
      if (this.state.noteValue) {
        data = `${this.state.noteValue} ${result}`;
      } else {
        data = result;
      }
      this.setState({ noteValue: data });
    }
  };
  onChangeRadio = event => {
    let val = event.target.value;
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    this.setState({
      value: val,
    });
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: survey.Response == val ? survey.DoesNotApplyValue : val,
      note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: index,
    })();
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option1QF,
      Option2Value,
      Option2Text,
      Option2QF,
      Option3Value,
      Option3Text,
      Option3QF,
      Option4Value,
      Option4Text,
      Option4QF,
      Option5Value,
      Option5Text,
      Option5QF,
      Option6Value,
      Option6Text,
      Option6QF,
      Option7Value,
      Option7Text,
      Option7QF,
      Option8Value,
      Option8Text,
      Option8QF,
      Option9Value,
      Option9Text,
      Option9QF,
      Option10Value,
      Option10Text,
      Option10QF,
      Response,
      Note,
      SurveyQuestionId,
      AddNote,
    } = survey;
    const textArray = [
        Option1Text,
        Option2Text,
        Option3Text,
        Option4Text,
        Option5Text,
        Option6Text,
        Option7Text,
        Option8Text,
        Option9Text,
        Option10Text,
      ],
      valueArray = [
        Option1Value,
        Option2Value,
        Option3Value,
        Option4Value,
        Option5Value,
        Option6Value,
        Option7Value,
        Option8Value,
        Option9Value,
        Option10Value,
      ],
      flagArray = [
        Option1QF,
        Option2QF,
        Option3QF,
        Option4QF,
        Option5QF,
        Option6QF,
        Option7QF,
        Option8QF,
        Option9QF,
        Option10QF,
      ];
    let responseValue = this.state.value ? valueArray.indexOf(this.state.value) : null;
    return (
      <div className="type-border col-12 mb-3 toggle-radio">
        <div>
          {Question && Question.length > 0 && (
            <h5 className="survey-question rcm-question text-left mb-2">
              {QuestionNumber && `${QuestionNumber}.`} {renderHTML(Question)}
            </h5>
          )}

          <Radio.Group onChange={this.onChangeRadio} value={this.state.value}>
            <div className="row mb-2">
              {textArray.map((item, qIndex) => {
                let val = valueArray[qIndex];
                return (
                  item && (
                    <div className="col-auto text-left">
                      <Radio
                        value={val}
                        style={{
                          padding: '10px 20px 10px 0px',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {item}
                      </Radio>
                    </div>
                  )
                );
              })}

              {responseValue != null ? flagArray[responseValue] == false && Flagged : null}
            </div>
          </Radio.Group>

          {AddNote && (
            <div className="text-left">
              <div className="custom-control" style={{ padding: 'unset' }}>
                <label className="custom-control-label mb-3">
                  <Switch
                    checked={this.state.toggleActive}
                    onClick={() => this.setState({ toggleActive: !this.state.toggleActive })}
                    size="small"
                  />
                  Add Note
                </label>
              </div>
            </div>
          )}
          {AddNote && this.state.toggleActive && (
            <>
              <ToggleNote
                handleDictate={this.handleDictate}
                getDicatationStatus={this.getDicatationStatus}
                noteValue={this.state.noteValue}
                getNoteChanges={this.getNoteChanges}
                onChange={this.onChange}
                isReadOnly={isReadOnly}
              />
            </>
          )}

          {DoesNotApplyText && (
            <div className="not-apply-to-me">
              <a
                name="current-situation"
                value={DoesNotApplyText}
                // onClick={onClick.bind(scope, {
                //   surveyQuestionId: SurveyQuestionId,
                //   response: DoesNotApplyValue,
                //   level: level,
                //   subLevel: subLevel,
                //   subCatLevel: subCatLevel,
                //   index: index,
                // })}
              >
                {DoesNotApplyText}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ToggleRadioOptionsType;
