import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Badge, Dropdown, Space } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
const authorizationTable = (authRecord, index, indent, expanded) => {
  const { authRecord: authorizationRecord, getAuthDetails } = authRecord;
  const { Authorization, SmartContractId } = authorizationRecord;

  let columns = [
    {
      title: 'Authorization Number',
      dataIndex: 'AuthNumber',
      key: 'AuthNumber',
      sorter: (a, b) => isNumericComparer(a.AuthNumber, b.AuthNumber),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Service Code',
      dataIndex: 'ServiceCode',
      key: 'ServiceCode',
      sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
      sorter: (a, b) => isDateComparer(a.StartDateSort, b.StartDateSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      key: 'EndDate',
      sorter: (a, b) => isDateComparer(a.EndDateSort, b.EndDateSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Review Date',
      dataIndex: 'AuthReviewDate',
      key: 'AuthReviewDate',
      sorter: (a, b) => isDateComparer(a.AuthReviewDateSort, b.AuthReviewDateSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Visits Approved',
      dataIndex: 'VisitApproved',
      key: 'VisitApproved',
      sorter: (a, b) => isNumericComparer(a.AuthNumber, b.AuthNumber),
      sortDirections: ['descend', 'ascend'],
      align: 'right',
    },
    {
      title: 'Visits Remaining',
      dataIndex: 'VisitRemaining',
      key: 'VisitRemaining',
      sorter: (a, b) => isNumericComparer(a.AuthNumber, b.AuthNumber),
      sortDirections: ['descend', 'ascend'],
      align: 'right',
    },
    {
      title: 'Notes',
      dataIndex: 'AuthNote',
      key: 'AuthNote',
      sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'StatusText',
      key: 'StatusText',
      sorter: (a, b) => isComparer(a.AuthNumber, b.AuthNumber),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span class={`client-info-text ${record.StatusClassName}`}>{text}</span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'x',
      key: 'x',
      render: (text, record) => (
        <div
          className="text-center"
          onClick={() => {
            getAuthDetails(record, SmartContractId);
          }}
        >
          <img src={`images/openIcon.svg`} />
        </div>
      ),
    },
  ];

  return Authorization ? (
    <Table
      columns={columns}
      dataSource={Authorization}
      pagination={false}
      className="nested-tables"
    />
  ) : (
    <div className="text-center border-1">
      <span style={{ color: '#828282', fontSize: '16px', fontWeight: '400' }}>
        No Authorizations Found.
      </span>
    </div>
  );
};

export default authorizationTable;
