import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Table } from 'antd';
import TaskCompleted from './customScreens/TaskCompleted';
import SurveyCompleted from './customScreens/SurveyCompleted';
import TeleHealth from './customScreens/TeleHealth';
import ProfilePictureChange from './customScreens/ProfilePictureChange';
import Friend from './customScreens/Friend';
import BreathalyzerTest from './customScreens/BreathalyzerTest';
import TriggerAdded from './customScreens/TriggerAdded';
import Mood from './customScreens/Mood';
import PanasScale from './customScreens/PanasScale';
import EmotionalTrends from './customScreens/EmotionalTrends';
import Post from './customScreens/Post';
import ForbiddenWords from './customScreens/ForbiddenWords';
import Chat from './customScreens/Chat';
import Document from './customScreens/Document';

class TimeTravelEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.dispatch(loadLocationAuthHistory({ UserGuid: this.props.UserGuid }));
  }

  render() {
    const { onCancel, EventTitle, EventTypeName, EventTypeId, EventJsonData } = this.props;

    return (
      <div class="modal-content border-0">
        <div class="modal-header  flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 mb-3" style={{ textAlign: 'left' }}>
            {EventTitle}
          </h4>
        </div>

        <div class="modal-body col-md-12 px-4 enrollment-form pb-0">
          <div class="border-0">
            {EventTypeId === 2 && <TriggerAdded EventJsonData={EventJsonData} />}
            {EventTypeId === 3 && <SurveyCompleted EventJsonData={EventJsonData} />}
            {EventTypeId === 4 && <TaskCompleted EventJsonData={EventJsonData} />}
            {EventTypeId === 6 && <Post EventJsonData={EventJsonData} />}
            {EventTypeId === 7 && <BreathalyzerTest EventJsonData={EventJsonData} />}
            {EventTypeId === 8 && <TeleHealth EventJsonData={EventJsonData} />}
            {EventTypeId === 9 && <TriggerAdded EventJsonData={EventJsonData} />}
            {EventTypeId === 13 && <ProfilePictureChange EventJsonData={EventJsonData} />}
            {EventTypeId === 14 && <Mood EventJsonData={EventJsonData} />}
            {EventTypeId === 15 && <Friend EventJsonData={EventJsonData} />}
            {EventTypeId === 24 && <BreathalyzerTest EventJsonData={EventJsonData} />}
            {EventTypeId === 25 && (
              <ForbiddenWords EventJsonData={EventJsonData} onCancel={onCancel} />
            )}{' '}
            {EventTypeId === 17 && <Chat EventJsonData={EventJsonData} onCancel={onCancel} />}
            {EventTypeId === 215 && <EmotionalTrends EventJsonData={EventJsonData} />}
            {EventTypeId === 26 && <PanasScale EventJsonData={EventJsonData} />}
            {EventTypeId === 27 && <Post EventJsonData={EventJsonData} />}
            {EventTypeId === 36 && <Document EventJsonData={EventJsonData} />}
            {/* {EventTypeId === 15 && <ForbiddenWords EventJsonData={EventJsonData} />} */}
          </div>
          {/* <div class="modal-footer justify-content-center border-top-0 w-100">
            <input
              type="button"
              class="btn btn-outline-primary m-2"
              value="Close"
              onClick={onCancel}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   const { load_location_auth_history } = state;
//   const { data, isFetching } = load_location_auth_history;

//   let locationHistory = null;

//   if (data && data) {
//     locationHistory = data.LocationAuthorizationHistory || [];
//   }

//   return {
//     locationHistory,
//     isFetching: isFetching,
//   };
// };

export default connect()(TimeTravelEventModal);
