import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetYouuniverseChatGet, resetAskFollowUpInitiate } from '../../store/actions';
import { Drawer, Tabs } from 'antd';
import Chat from './chat';
import Write from './write';
import Bookmarks from './bookmarks';
import { CommonLicenceScreens } from '../../Screens/licenceScreens';
import ChatHistory from './ChatHistory';
import CloseIcon from '../../component/closeIcon';

const { TabPane } = Tabs;
class AskYOUUniverse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      historyConversationGuid: null,
      isDeleteAll: false,
    };
    this.myRef = React.createRef();
  }
  updateStep = (activeKey, ConversationGuid) => {
    this.setState({
      activeKey: activeKey || 0,
      historyConversationGuid: null,
      chatGuid: ConversationGuid,
    });
    this.props.dispatch(resetAskFollowUpInitiate({}));
  };

  list = [
    {
      Name: 'Chat',
      Component: props => {
        return <Chat historyConversationGuid={this.state.historyConversationGuid} {...props} />;
      },
    },
    // {
    //   Name: ' Write ',
    //   ExtraComponent: props => {
    //     return <div className="">Write</div>;
    //   },
    // },
    {
      Name: 'Bookmarks',
      Component: props => {
        return <Bookmarks {...props} updateStep={this.updateStep} />;
      },
    },
  ];
  getConversationsGuid = history => {
    this.setState({ historyConversationGuid: history.ConversationGuid, activeKey: 0 });
    this.getConversation(null);
    this.props.dispatch(resetYouuniverseChatGet({}));
    this.props.toggleHistoryDrawer();
  };
  chatInit = () => {
    this.setState({ historyConversationGuid: null, activeKey: 0 });
    this.getConversation(null);
    this.props.dispatch(resetYouuniverseChatGet({}));
    this.props.toggleHistoryDrawer();
  };
  onTabClick = key => {
    this.setState({
      activeKey: parseInt(key),
    });
  };
  footerAction = () => {
    this.setState({
      isDeleteAll: true,
    });
  };

  getConversation = guid => { 
    this.setState({
      chatGuid: guid,
    })
  }
  render() {
    const { toggleHistoryDrawer, isAIHistoryDrawerOpen } = this.props;
    const { activeKey } = this.state;
    return (
      <div className="row">
        <div class="col-12 center-tabs">
          {this.props.AugmentedAIStatusData && (
            <CommonLicenceScreens
              StatusData={this.props.AugmentedAIStatusData}
              moduleName={'AugmentedAI'}
            />
          )}
          {this.props.AugmentedAIStatusData &&
            this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
            this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
            this.props.AugmentedAIStatusData.LicenseStatusId != 5 && (
              <>
                {/* <ListRenderer
                  list={this.list}
                  activeKey={activeKey}
                  // getActiveKey={activeKey => this.setState({ activeKey })}
                  destroyInactiveTabPane={true}
                  // scuShouldReturnTrue={true}
                /> */}
                <Tabs
                  activeKey={String(activeKey)}
                  defaultActiveKey="0"
                  onTabClick={this.onTabClick}
                >
                  {this.list.map((item, index) => {
                    const { Component, Name } = item;
                    return (
                      <TabPane tab={Name} key={`${index}`} className="tab-content enrollment-form">
                        {activeKey == index && (
                          <Component
                            history={this.props.history}
                            isDrawerView={this.props.isDrawerView}
                            style={{ padding: '36px 0px' }}
                            getConversation={this.getConversation}
                            chatGuid={this.state.chatGuid}
                          />
                        )}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </>
            )}
        </div>
        <Drawer
          title={<span className="conversations-history-title">Chat History</span>}
          width={450}
          closable={true}
          onClose={() => {
            // this.setState({ ConversationGuid: null });
            // this.props.dispatch(resetChatGet({}));
            toggleHistoryDrawer();
          }}
          closeIcon={<CloseIcon />}
          open={isAIHistoryDrawerOpen}
          extra={
            <div className="new-conversation">
              <span onClick={this.chatInit}>+ New Chat</span>
            </div>
          }
          footer={
            this.props.ConversationsHistory ? (
              <div className="row chat-history-footer">
                <div className="col-12 footer">
                  <img src="images/deletetp.svg" />
                  <span className="px-2" onClick={this.footerAction}>
                    Clear All Conversations
                  </span>
                </div>
              </div>
            ) : (
              false
            )
          }
        >
          <ChatHistory
            isDeleteAll={this.state.isDeleteAll}
            toggleHistoryDrawer={toggleHistoryDrawer}
            getConversationsGuid={this.getConversationsGuid}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ user_feature_flag_get, youuniverse_conversation_get }) => {
  const { data: fFData } = user_feature_flag_get;
  const { data } = youuniverse_conversation_get;
  let AugmentedAIStatusData = null;
  let ConversationsHistory = null;

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  if (data && data.data) {
    ConversationsHistory = data.data;
  }

  return {
    AugmentedAIStatusData,
    ConversationsHistory,
  };
};

export default withRouter(connect(mapStateToProps)(AskYOUUniverse));
