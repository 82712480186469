import React, { Component } from 'react';
import MultipleCategoryCheckboxAll from './../../../Components/multipleCategoryCheckbox4';

class PrintClientChart extends Component {
  constructor(props) {
    super(props);
    this.state = { checkedItems: [], items: [] };
  }
  render() {
    const { onCancel, printClientChart, getClientId } = this.props;
    return (
      <div>
        <div className="card" style={{ border: 'none' }}>
          <div className="card-body">
            {/* <h4>Print Client Chart</h4>
            <Divider /> */}
            <div className="row mb-4">
              {/* <Loader2 loading={isFetching} /> */}
              <div className="col-12">
                <div className="">
                  <div className="client-chart">
                    {/* <span style={{ fontSize: '17px', fontWeight: '600' }}>
                      Select informations to be printed
                    </span> */}
                    {printClientChart.map((item, index) => {
                      return (
                        <MultipleCategoryCheckboxAll
                          filterList={item}
                          index={index}
                          getCheckItems={this.getCheckItems}
                          defaultValue={printClientChart}
                          onCancel={onCancel}
                          getClientId={getClientId}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrintClientChart;
