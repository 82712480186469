import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getFormFieldMetaData(action) {
  try {
    const { payload } = action;
    const response = yield API.getFormFieldMetaData(payload);
    yield put({
      type: ActionTypes.GET_FORM_FIELD_META_DATA_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_FORM_FIELD_META_DATA_FAILURE,
    });
  }
}
export function* upsertFormData(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertFormData(payload);
    yield put({
      type: ActionTypes.UPSERT_FORM_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_FORM_DATA_FAILURE,
    });
  }
}

export function* dynamicGridList(action) {
  try {
    const { payload } = action;
    const response = yield API.dynamicGridList(payload);
    yield put({
      type: ActionTypes.DYNAMIC_GRID_LIST_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DYNAMIC_GRID_LIST_FAILURE,
    });
  }
}
export function* getGrid(action) {
  try {
    const { payload } = action;
    const response = yield API.getGrid(payload);
    yield put({
      type: ActionTypes.GET_GRID_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GRID_FAILURE,
    });
  }
}
export function* getGridData(action) {
  try {
    const { payload } = action;
    const response = yield API.getGridData(payload);
    yield put({
      type: ActionTypes.GET_GRID_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GRID_DATA_FAILURE,
    });
  }
}

export function* gridOnRowClickGet(action) {
  try {
    const { payload } = action;
    const response = yield API.gridOnRowClickGet(payload);
    yield put({
      type: ActionTypes.GRID_ON_ROW_CLICK_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GRID_ON_ROW_CLICK_GET_FAILURE,
    });
  }
}
export function* formDataGet(action) {
  try {
    const { payload } = action;
    const response = yield API.formDataGet(payload);
    yield put({
      type: ActionTypes.FORM_DATA_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.FORM_DATA_GET_FAILURE,
    });
  }
}
export function* gridFilterGet(action) {
  try {
    const { payload } = action;
    const response = yield API.gridFilterGet(payload);
    yield put({
      type: ActionTypes.GRID_FILTER_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GRID_FILTER_GET_FAILURE,
    });
  }
}
export function* studentRecommendationsListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentRecommendationsListGet(payload);
    yield put({
      type: ActionTypes.STUDENT_RECOMMENDATIONS_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_RECOMMENDATIONS_LIST_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_FORM_FIELD_META_DATA_REQUEST, getFormFieldMetaData),
    takeLatest(ActionTypes.UPSERT_FORM_DATA_REQUEST, upsertFormData),
    takeLatest(ActionTypes.FORM_DATA_GET_REQUEST, formDataGet),
    takeLatest(ActionTypes.DYNAMIC_GRID_LIST_REQUEST, dynamicGridList),
    takeLatest(ActionTypes.GET_GRID_REQUEST, getGrid),
    takeLatest(ActionTypes.GET_GRID_DATA_REQUEST, getGridData),
    takeLatest(ActionTypes.GRID_ON_ROW_CLICK_GET_REQUEST, gridOnRowClickGet),
    takeLatest(ActionTypes.GRID_FILTER_GET_REQUEST, gridFilterGet),
    takeLatest(ActionTypes.STUDENT_RECOMMENDATIONS_LIST_GET_REQUEST, studentRecommendationsListGet),
  ]);
}
