import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { Modal, Tooltip } from 'antd';
import EmotionalGraphModaContent from '../../subscriber/emotionalGraph/emotionalGraphModalContent';

import CloseIcon from '../../../Components/CloseIcon';

const EmotionGraph = props => {
  const dispatch = useDispatch();
  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);
  const [modalContent, setModalContent] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialSeries, setInitialSeries] = useState([]);
  const [key, setKey] = useState(0);
  const [isSeriesNull, setIsSeriesNull] = useState(false);

  const options = {
    chart: {
      height: 350,
      type: 'scatter',
      zoom: {
        type: 'xy',
      },
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
          if (series.length > 0) {
            groupData(seriesIndex, dataPointIndex, w, true);
          }
        },
        legendClick: (chartContext, seriesIndex) => handleLegendClick(chartContext, seriesIndex),
      },
      animations: {
        enabled: false, // Disable all animations
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      formatter: function(val, opt) {
        const { seriesIndex, dataPointIndex, w } = opt;
        if (series.length > 0) {
          let totalArray = [];
          let totalLength = 0;

          const overlappingData = groupData(seriesIndex, dataPointIndex, w);
          let labelTest =
            overlappingData && overlappingData.length > 0 && overlappingData[0].name.split(',')[0];
          if (overlappingData.length > 1) {
            overlappingData &&
              overlappingData.map((item, index) => {
                return totalArray.push(item.data.length);
              });
            totalArray.forEach(item => {
              totalLength += item;
            });
            return totalLength ? totalLength.toString() : '';
          } else if (overlappingData.length == 1) {
            overlappingData &&
              overlappingData.map((item, index) => {
                return totalArray.push(item.data.length);
              });

            if (totalArray[0] > 1) {
              totalArray.forEach(item => {
                totalLength += item;
              });
              return totalLength;
            } else return labelTest;
          }
        }

        // return "Label Text"; // The text you want to add inside the dot
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        colors: colors,
        height: 150,
        width: 50,
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        // padding: 5,
        borderRadius: 30,
        // borderWidth: 1,
        // borderColor: "#fff",
        opacity: 0.9,
        // dropShadow: {
        //   enabled: false,
        //   top: 1,
        //   left: 1,
        //   blur: 1,
        //   color: "#000",
        //   opacity: 0.45,
        // },
      },
    },
    tooltip: {
      enabled: false,
      // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      //   if (series.length > 0) {
      //     const overlappingData = groupData(seriesIndex, dataPointIndex, w);
      //     return customToolTip(overlappingData);
      //   }
      // },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
      position: 'right',
      showForSingleSeries: true,
      showForNullSeries: true,
      fontSize: '16px',
      onItemClick: {
        toggleDataSeries: !isSeriesNull ? true : false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
      markers: {
        width: 16,
        height: 16,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      tickAmount: 10,
      max: 10,
      min: 0,
      labels: {
        show: true,
      },
    },
    yaxis: {
      max: 5,
      min: -5,
      tickAmount: 2,
      labels: {
        show: false,
      },
    },
  };
  useEffect(() => {
    if (props.EventJsonData && props.EventJsonData[0]) {
      let MoodsMapData = props.EventJsonData[0].MoodsMap;
      let CategoryLegendData = props.EventJsonData[0].CategoryLegend;
      debugger;

      if (MoodsMapData && MoodsMapData.length > 0) {
        setIsSeriesNull(false);
        let seriesData =
          CategoryLegendData &&
          CategoryLegendData.map((item, index) => {
            setColors(prevColors => [...prevColors, item.ColorCode]);
            let xydata = generateSeries(item.MoodReasonId, MoodsMapData, 1);
            let remainingData = generateSeries(item.MoodReasonId, MoodsMapData, 2);
            return {
              name: item.Shortdescription + ', ' + item.Name,
              data: xydata,
              remainingData: remainingData,
            };
          });

        let initialData =
          CategoryLegendData &&
          CategoryLegendData.map((item, index) => {
            setColors(prevColors => [...prevColors, item.ColorCode]);
            let xydata = generateSeries(item.MoodReasonId, MoodsMapData, 1);
            let remainingData = generateSeries(item.MoodReasonId, MoodsMapData, 2);
            return {
              name: item.Shortdescription + ', ' + item.Name,
              data: xydata,
              remainingData: remainingData,
            };
          });
        setSeries([...seriesData]);
        setKey(prevKey => prevKey + 1);
        setInitialSeries([...initialData]);
      } else {
        setIsSeriesNull(true);
        let seriesData =
          CategoryLegendData &&
          CategoryLegendData.map((item, index) => {
            setColors(prevColors => [...prevColors, item.ColorCode]);
            return {
              name: item.Shortdescription + ', ' + item.Name,
              data: [],
              remainingData: [],
            };
          });
        setSeries(seriesData);
        setKey(prevKey => prevKey + 1);
        setInitialSeries(seriesData);
      }
    }
  }, [props.EventJsonData]);
  const generateSeries = (MoodReasonId, MoodsMap, id) => {
    let series = MoodsMap && MoodsMap.filter(item => MoodReasonId == item.MoodReasonId);
    let newdata =
      series &&
      series.map((item, index) => {
        return id == 1
          ? [item.Y, item.X <= 5 ? -item.X : item.X - 5]
          : [
              item.Shortdescription,
              item.Name,
              item.SelectedUrl,
              item.Description,
              item.CreatedDate,
              item.ColorCode,
            ];
      });
    return newdata;
  };
  const handleLegendClick = (chartContext, seriesIndex) => {
    let dataSeries = [...series];
    let initialDataSeries = [...initialSeries];
    if (dataSeries[seriesIndex].name == initialDataSeries[seriesIndex].name) {
      if (dataSeries[seriesIndex].data.length == initialDataSeries[seriesIndex].data.length) {
        dataSeries[seriesIndex].data = [];
        setSeries(dataSeries);
      } else {
        dataSeries[seriesIndex].data = [...initialDataSeries[seriesIndex].data];
        setSeries(dataSeries);
      }
    }
  };
  const groupData = (seriesIndex, dataPointIndex, w, openModal) => {
    let groupDataPoints = [];
    if (series && series.length > 0) {
      groupDataPoints = series
        .filter((obj, index) =>
          obj.data.some(
            point =>
              point[0] === w.globals.seriesX[seriesIndex][dataPointIndex] &&
              point[1] === w.globals.series[seriesIndex][dataPointIndex],
          ),
        )
        .map(obj => ({
          name: obj.name,
          data: obj.data.filter((point, index) => {
            return (
              point[0] === w.globals.seriesX[seriesIndex][dataPointIndex] &&
              point[1] === w.globals.series[seriesIndex][dataPointIndex]
            );
          }),
          remainingData: obj.data
            .map((point, index) => {
              if (
                point[0] === w.globals.seriesX[seriesIndex][dataPointIndex] &&
                point[1] === w.globals.series[seriesIndex][dataPointIndex]
              ) {
                return obj.remainingData[index];
              }
            })
            .filter((item, index) => {
              return item != undefined;
            }),
        }));
    }
    if (openModal) {
      setIsModalOpen(true);
      setModalContent(groupDataPoints);
    }
    return groupDataPoints;
  };
  return (
    <div className="col-12" style={{ position: 'relative' }}>
      <div className="row justify-content-between">
        <div className="emotional-container p-0">
          <div className="emotional-arrow"></div>
          <div className="emotional-line"></div>
        </div>
        <div key={key} className="col-11 p-0 ml-3 emotional-graph">
          <ReactApexChart
            options={options}
            series={series.length > 0 ? series : []}
            type="scatter"
            height={500}
            // ref={chartRef}
          />
        </div>
      </div>
      <Modal
        open={isModalOpen}
        className="merge-clients-modal"
        onCancel={() => {
          setModalContent([]);
          setIsModalOpen(false);
        }}
        closeIcon={<CloseIcon />}
        maskClosable={true}
        width={450}
        footer={null}
      >
        <EmotionalGraphModaContent modalContent={modalContent} />
      </Modal>
    </div>
  );
};

export default EmotionGraph;
