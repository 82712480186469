import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactApexChart from 'react-apexcharts';

import moment from 'moment';

class SparkLineComponent extends Component {
  constructor(props) {
    super(props);
    const { MiscData } = props;
    let ScoreData = [];
    let MoodDayData = [];
    let Dates = [];
    MiscData &&
      MiscData.map(item => {
        ScoreData.push(parseInt(item.Score));
        Dates.push(item.CreatedDate);
        let imgTag = `<div class='d-flex align-items-center'><img src=${item.MoodEmoji}  width='50' height='50'/> <span class='text-bold pl-2'>${item.MoodType}</span></div>`;
        MoodDayData.push(imgTag);
      });
    this.state = {
      series: [
        {
          name: 'Emotional Affect Mood',
          data: ScoreData,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          // height: 350,
          // width: 380,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 0,
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: -100,
                  to: 0,
                  color: '#FF7979',
                },
                {
                  from: 1,
                  to: 100,
                  color: '#2FE192',
                },
              ],
            },
            columnWidth: '80%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: 'Negative / Positive',
          },
          labels: {
            show: false,
          },
          // labels: {
          //   formatter: function(y) {
          //     return y.toFixed(0) + '%';
          //   },
          // },
        },
        xaxis: {
          // type: 'datetime',
          categories: MoodDayData,
          labels: {
            // rotate: -90,
            show: false,
          },
        },
      },
    };
  }

  render() {
    const { MiscData } = this.props;

    return (
      <div className="row sparkline flex-column">
        {/* <Sparklines data={MoodScoreData} width={90} height={20}>
          <SparklinesLine color="#179bef" />
          <SparklinesSpots style={{ fill: '#179bef' }} />
        </Sparklines>
        {SparkLineScale && (
          <span className="desc" style={{ fontSize: '16px' }}>{`${SparkLineScale}`}</span>
        )} */}
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={150}
          />
        </div>
      </div>
    );
  }
}

export default connect()(SparkLineComponent);
