import React, { Component } from 'react';
import { connect } from 'react-redux';

class ViewTranscriptDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: ['Depression', 'Trauma', 'Toxic', 'Violence', 'Unhappiness'],
    };
  }

  render() {
    return (
      <>
        <div className="">
          <div className="row py-4" style={{ background: '#F8F8F8' }}>
            <div className="col-md-4" style={{ fontWeight: 'bold' }}>
              Related Behavioural Factor(s)
              <div className="d-flex flex-column">
                <div className="mt-2 p-2">
                  <span
                    style={{
                      background: '#4D93F3',
                      borderRadius: '4px',
                      textTransform: 'uppercase',
                      color: 'white',
                      padding: '3px',
                    }}
                  >
                    Relationship
                  </span>
                </div>
                <div className="mt-2 p-2">
                  <span
                    style={{
                      background: '#4D93F3',
                      borderRadius: '4px',
                      textTransform: 'uppercase',
                      color: 'white',
                      padding: '3px',
                    }}
                  >
                    Work
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <span style={{ fontWeight: 'bold' }}>Diagnosis</span>
              <p>
                In eu mauris et neque sodales porta eu eget dui. Nunc eu quam sit amet justo
                elementum mollis. Orci varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Sed laoreet metus nulla, in gravida urna rhoncus in. Proin
                laoreet semper tortor ac posuere. Cras non leo at ipsum fringilla ullamcorper.{' '}
              </p>
            </div>
            <div className="col-md-12">
              <div style={{ fontWeight: 'bold' }}>Suggested Keyword</div>
              <div className="d-flex mt-2 justify-content-between">
                {this.state.keywords.map(item => {
                  return (
                    <div
                      style={{
                        border: '1px solid #9A9A9A',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        padding: '5px 10px',
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-2">
          <p>
            Maecenas quam nunc, sagittis non condimentum at, rutrum sit amet eros. Fusce rutrum,
            lectus in blandit sagittis, mi tortor ullamcorper mi, vitae vestibulum libero quam a
            nisi. In eu mauris et neque sodales porta eu eget dui. Nunc eu quam sit amet justo
            elementum mollis. Orci varius natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Sed laoreet metus nulla,us fermentum.
          </p>
          <p>
            Maecenas quam nunc, sagittis non condimentum at, rutrum sit amet eros. Fusce rutrum,
            lectus in blandit sagittis, mi tortor ullamcorper mi, vitae vestibulum libero t purus
            quis lectus maximus fermentum.
          </p>
          <p>
            Maecenas quam nunc, sagittis non condimentum at, rutrum sit amet eros. Fusce rutrum,
            lectus in blandit sagittis, mi tortor ullamcorper mi, vitae vestibulum libero quam a
            nisi. In eu mauris et neque sodales porta eu eget dui. Nunc eu quam sit amet justo
            elementum mollis. Orci varius natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Sed laoreet metus nulla, in gravida urna rhoncus in. Proin
            laoreet semper tortor ac posuere. Cras non leo at ipsum fringilla ullamcorper. Etiam
            velit est, tempor id lobortis eu, lacinia id sem. Nam ornare mattis dui a porta. Aliquam
            a ullamcorper velit, et hendrerit eros. Etiam accumsan porta neque in viverra. Proin
            eleifend, eros in tristique hendrerit, nisi purus cursus sapien, id ultrices nunc tellus
            a ipsum. Donec et fringilla neque. Aenean consequat purus quis lectus maximus fermentum.
          </p>
        </div>
      </>
    );
  }
}

export default connect()(ViewTranscriptDrawer);
