import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import SignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-canvas';
import { Button } from 'antd';
import {
  clearSignature,
  resetClearSignature,
  resetSurveyAnswerUpsert,
} from '../../../../../../store/actions';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

function toDataURL(src, callback, outputFormat) {
  let image = new Image();
  image.crossOrigin = 'Anonymous';
  image.onload = function() {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let dataURL;
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  image.src = src;
  if (image.complete || image.complete === undefined) {
    // image.src = 'data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
    image.src = src;
  }
}

class SignatureType extends React.Component {
  constructor(props) {
    super(props);
    let { Response, MediaUrl } = props.survey;
    MediaUrl && this.getBase64fromURL(MediaUrl);
    this.state = {
      value: Response,
      HtmlTemplate: null,
      trimmedDataURL: null,
    };
  }
  sigPadObj = {};
  clear = (index, SurveyQuestionId) => {
    this.sigPadObj[index].clear();
    this.setState({
      QuestionId: SurveyQuestionId,
      signatureIndex: index,
    });
  };
  disableAllEvent = index => {
    this.sigPadObj[index].off();
  };
  // trim = (signOne, index) => {
  //   let data = this.sigPadObj[index].getTrimmedCanvas().toDataURL('image/png');
  //   debugger;
  //   this.setState({
  //     SignatureCanvas: {
  //       ...this.state.SignatureCanvas,
  //       [signOne]: data,
  //     },
  //   });
  // };
  componentWillReceiveProps({
    isUpdated,
    failMessage,
    SurveyQuestionId,
    SurveyQuestionIndex,
    isCleared,
    clearMessage,
    successResult,
  }) {
    if (isUpdated && successResult && successResult != -1 && isUpdated != this.props.isUpdated) {
      this.props.saveResponse({
        response: successResult.Response,
        surveyQuestionId: SurveyQuestionId,
        level: this.props.level,
        subLevel: this.props.subLevel,
        subCatLevel: this.props.subCatLevel,
        index: SurveyQuestionIndex,
      });
      this.setState(
        {
          Msg: 'Saved successfully.',
          QuestionId: SurveyQuestionId,
        },
        () => {
          setTimeout(() => {
            this.setState({
              Msg: '',
              QuestionId: null,
            });
            this.props.dispatch(resetSurveyAnswerUpsert());
          }, 2000);
        },
      );
    }
    if (isCleared && isCleared != this.props.isCleared) {
      this.props.saveResponse({
        response: null,
        surveyQuestionId: SurveyQuestionId,
        level: this.props.level,
        subLevel: this.props.subLevel,
        subCatLevel: this.props.subCatLevel,
        index: this.props.index,
      });
      this.setState(
        {
          Msg: clearMessage || 'Removed successfully.',
          QuestionId: SurveyQuestionId,
        },
        () => {
          setTimeout(() => {
            this.setState({
              Msg: '',
              QuestionId: null,
            });
            this.props.dispatch(resetClearSignature());
          }, 2000);
        },
      );
    }
  }
  getBase64fromURL = MediaUrl => {
    let me = this;
    toDataURL(MediaUrl, function(dataUrl) {
      me.sigPadObj[me.props.index].fromDataURL(dataUrl, {
        ratio: 1,
        height: 140,
        width: 400,
        xOffset: 100,
        yOffset: 50,
      });
    });
    this.props.saveSignature(
      `${me.props.survey.SurveyQuestionId}`,
      false,
      me.props.survey.SurveyQuestionId,
      me.props.index,
      me.props.survey.Response,
      true,
      false,
      true,
    );
  };
  save = () => {
    let {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
    } = this.props;
    const { Response, isReadOnly } = survey;
    // .getTrimmedCanvas()
    let isEmptyPad = this.sigPadObj[index].isEmpty();
    if (isEmptyPad) return;
    let base64String = !isEmptyPad
      ? this.sigPadObj[index].toDataURL().replace('data:image/png;base64,', '')
      : null;
    setValue.call(scope, {
      response: Response,
      surveyQuestionId: survey.SurveyQuestionId,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: index,
      IsSignature: true,
      isSignatureSaved: true,
      Signature: {
        Base64String: base64String,
        IsUpdateSignature: Response ? true : false,
        MediaGuid: Response ? Response : null,
      },
    });
    this.setState({
      QuestionId: survey.SurveyQuestionId,
      signatureIndex: index,
    });
  };

  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      saveSignature,
      SurveyId,
      isFetching,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
      // isReadOnly,
      MediaUrl,
      QuestionHelp,
      Note,
    } = survey;
    // this.disableAllEvent(index);
    return (
      <>
        <div className="type-border col-12">
          <div>
            <SurveyQuestion survey={survey} ClassName={'mb-3'} />
            <div class="answer-options text-left">
              <div class="quantity-btn">
                <div className="" style={{ width: '410px' }}>
                  {this.state.Msg && this.state.QuestionId == SurveyQuestionId && (
                    <h6
                      style={{
                        fontSize: '14px',
                        color: 'rgb(18, 196, 117)',
                      }}
                    >
                      {this.state.Msg}
                    </h6>
                  )}
                  {!isReadOnly ? (
                    <>
                      <p>{Note}</p>
                      <div className="sigContainer">
                        <SignaturePad
                          canvasProps={{ className: 'sigPad', height: 150, width: 410 }}
                          ref={ref => {
                            Object.assign(this.sigPadObj, { [index]: ref });
                          }}
                          onEnd={() => {
                            // this.trim(`sign+${SurveyQuestionId}`, index);
                            // .getTrimmedCanvas()
                            saveSignature(
                              `${SurveyQuestionId}`,
                              this.sigPadObj[index].toDataURL('image/png'),
                              SurveyQuestionId,
                              index,
                              Response,
                              false,
                            );
                          }}
                        />
                      </div>
                      <div className="text-right mt-2">
                        <span className="mr-2" style={{ fontSize: '13px' }}>
                          {QuestionHelp}
                        </span>
                        <Button
                          // disabled={this.sigPadObj[index] && this.sigPadObj[index].isEmpty()} // this.sigPadObj[index].isEmpty()
                          loading={isFetching && this.state.QuestionId == SurveyQuestionId}
                          type="primary"
                          style={{
                            height: '30px',
                            border: '1px solid ghostwhite',
                            color: '#fff',
                            background: '#4ab0fe',
                          }}
                          onClick={this.save}
                        >
                          {isFetching && this.state.QuestionId == SurveyQuestionId
                            ? 'Saving'
                            : 'Save'}
                        </Button>
                        {/* <button
                      className="can-buttons d-inline mr-2"
                      onClick={this.save}
                      // disabled={!this.sigPadObj[index].getTrimmedCanvas().toDataURL('image/png')}
                    >
                      {isFetching ? 'Save' : 'Save'}
                    </button> */}
                        <button
                          className="can-buttons d-inline"
                          onClick={() => {
                            let isEmptyPad =
                              this.sigPadObj[index] && this.sigPadObj[index].isEmpty();
                            if (isEmptyPad) return;
                            this.clear(index, SurveyQuestionId);
                            if (Response)
                              this.props.dispatch(
                                clearSignature({
                                  json: JSON.stringify({
                                    Survey: [
                                      {
                                        SurveyId: SurveyId,
                                        SurveyQuestionId: SurveyQuestionId,
                                        SurveyQuestionIndex: index,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            saveSignature(
                              `${SurveyQuestionId}`,
                              false,
                              SurveyQuestionId,
                              index,
                              Response,
                              false,
                              true,
                              level,
                              subLevel,
                              subCatLevel,
                            );
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <p>{Note}</p>
                      <div>
                        <img src={MediaUrl} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ survey_answer_upsert, clear_signature }) => {
  const { data, isFetching, error } = survey_answer_upsert;
  const { data: clearData } = clear_signature;
  let isUpdated = false;
  let failMessage = null;
  let SurveyQuestionId = null;
  let SurveyQuestionIndex = null;
  let isCleared = false;
  let clearMessage = null;
  let successResult = null;

  if (data && data.data.success) {
    isUpdated = data.data.success;
    successResult = data.data.result;
    SurveyQuestionId = data.SurveyQuestionId;
    SurveyQuestionIndex = data.SurveyQuestionIndex;
    failMessage = data.data.failMessage;
  }
  if (clearData && clearData.data.success) {
    isCleared = clearData.data.success;
    SurveyQuestionId = clearData.SurveyQuestionId;
    SurveyQuestionIndex = clearData.SurveyQuestionIndex;
    clearMessage = clearData.data.result;
  }
  return {
    isFetching,
    isUpdated,
    failMessage,
    SurveyQuestionId,
    isCleared,
    clearMessage,
    successResult,
    SurveyQuestionIndex,
  };
};
export default connect(mapStateToProps)(withRouter(SignatureType));
