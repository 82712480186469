import React, { Component } from 'react';
// import './modal.css';
import renderHTML from 'react-render-html';

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }
  componentWillReceiveProps({ isOpen }) {
    if (isOpen) this.focus();
  }

  focus = () => {
    if (this.textInput && this.textInput.current) this.textInput.current.focus();
  };

  render() {
    const {
      isOpen,
      closeModal,
      header,
      headerTop,
      content,
      yesText,
      yesAction,
      noText,
      noAction,
      hideOk,
      hideCancel,
      children,
      zIndex,
      changeMaxWidth,
      className,
      msgContentArray,
      width,
      isCustomClass,
    } = this.props;
    return (
      <div style={{ display: isOpen === true ? 'flex' : 'none' }} role="dialog">
        <div className="custom-modal modal-dialog-centered" style={{ zIndex: zIndex }}>
          <div
            className="modal-content border-0"
            style={{ maxWidth: changeMaxWidth || '550px', width: width }}
          >
            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
              <button
                type="button"
                className="close"
                data-dismis="modal"
                onClick={closeModal || noAction || yesAction}
              >
                &times;
              </button>

              {headerTop && <>{renderHTML(headerTop)}</>}
              {header && (
                <h4
                  className={`modal-title ${
                    isCustomClass ? 'custom-modal-text' : 'modal-text'
                  } col-12`}
                >
                  {renderHTML(header)}
                </h4>
              )}
            </div>
            <div
              className={
                className
                  ? `modal-body ${className}`
                  : 'modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5'
              }
            >
              {/* {header && <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                  <h4 className="modal-title text-center">{header}</h4>
                </div>} */}

              {children}
              <div className="row">
                <div className="col-md-12">
                  {msgContentArray &&
                    msgContentArray.map((item, index) => {
                      return renderHTML(item.WarningMessage);
                    })}
                </div>
              </div>

              <div
                className={
                  hideOk
                    ? 'modal-footer justify-content-center border-top-0 pt-2'
                    : 'modal-footer justify-content-center border-top-0 pb-5 pt-2'
                }
              >
                {!content && hideCancel && !hideOk && (
                  <div className="row mar-top-30">
                    <div className="col-12 text-center m-auto">
                      <input
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        value={yesText || 'Submit'}
                        onClick={yesAction}
                        ref={this.textInput}
                      />
                    </div>
                  </div>
                )}
                {!content && !hideCancel && (
                  <div className="row mar-top-30">
                    {' '}
                    <div className="col-sm-6 col-12 text-center mar-top-15">
                      <input
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-outline-primary"
                        value={noText || 'Cancel'}
                        onClick={noAction || closeModal}
                        ref={input => {
                          this.nameInput = input;
                        }}
                      />
                    </div>
                    <div className="col-sm-6 col-12 text-center mar-top-15">
                      <input
                        type="button"
                        className="btn"
                        value={yesText || 'Submit'}
                        onClick={yesAction}
                        ref={this.textInput}
                      />
                    </div>
                  </div>
                )}
                {content && (
                  <div>
                    <h4 className="modal-title text-center mt-3">{content}</h4>
                    <div className="col-sm-6 col-md-12 text-center m-auto mt-2 mar-top-15">
                      <input
                        type="button"
                        data-dismiss="modal"
                        className="btn"
                        value="OK"
                        onClick={closeModal}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CustomModal;
