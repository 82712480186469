import React, { Component } from 'react';
import { Avatar, Tooltip, Popover, Button } from 'antd';
import moment from 'moment';
import { panasTagDelete } from '../../store/actions';
class CurrentPanasTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: [],
    };
  }

  render() {
    const { moodsList, PANASTag } = this.props;
    const { PANASMoodId, PANASTagId, DateInserted } = PANASTag;

    let presentMoods;
    if (PANASMoodId) {
      presentMoods = this.props.moodsList.filter(item => {
        if (PANASMoodId && PANASMoodId.includes(item.MoodId)) {
          return item;
        }
      });
    }

    return (
      <>
        {' '}
        <Avatar.Group
          //   maxCount={3}
          size="large"
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
        >
          {' '}
          {presentMoods && presentMoods[0] && (
            <Tooltip title={presentMoods.length > 0 && presentMoods[0].Name} placement="bottom">
              <Avatar src={presentMoods.length > 0 && presentMoods[0].src} />
            </Tooltip>
          )}
          {presentMoods && presentMoods[1] && (
            <Tooltip title={presentMoods[1].Name} placement="bottom">
              <Avatar src={presentMoods[1].src} />
            </Tooltip>
          )}
          {presentMoods && presentMoods[2] && (
            <Tooltip title={presentMoods[2].Name} placement="bottom">
              <Avatar src={presentMoods[2].src} />
            </Tooltip>
          )}
          {/* {presentMoods &&
            presentMoods.map(item => {
              const { IsPositive, MoodId, Name, Url, src, value } = item;

              return (
                <>
                  <Tooltip title={`${Name}`} placement="bottom">
                    <Avatar src={src} />
                  </Tooltip>
                </>
              );
            })} */}
        </Avatar.Group>
        {presentMoods && (
          <Popover
            title={
              <span
                style={{ fontSize: '16px', fontWeight: '800' }}
              >{`Selected Moods (${presentMoods &&
                presentMoods != undefined &&
                presentMoods.length > 0 &&
                presentMoods.length})`}</span>
            }
            //   visible={true}
            overlayStyle={{
              width: '175px',
            }}
            content={
              <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                {' '}
                {presentMoods &&
                  presentMoods.map(item => {
                    const { IsPositive, MoodId, Name, Url, src, value } = item;

                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          <div
                            style={{
                              background: '#EBEFF2',
                              padding: '9px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                              margin: '3px 5px',
                              borderRadius: '32px',
                              fontWeight: '600',
                              width: '100%',
                            }}
                          >
                            <div style={{ width: '130px' }}>
                              <Tooltip title={`${Name}`} placement="bottom">
                                <Avatar src={src} /> &nbsp;
                                <span style={{ fontWeight: '800' }}>{`${Name}`}</span>
                              </Tooltip>{' '}
                            </div>

                            <span style={{ color: '#7F8188', fontSize: '12px' }}>
                              {moment(DateInserted).format('DD/MMM/YYYY')}
                            </span>
                            <span style={{ color: '#7F8188', fontSize: '12px' }}>
                              {moment(DateInserted).format('hh:mm A')}
                            </span>
                          </div>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={event => {
                              let x = MoodId;
                              let jsonData;

                              if (this.props.formType == 'AppointmentNoteId') {
                                jsonData = {
                                  PANASTag: [
                                    {
                                      PANASTagId: PANASTagId,
                                      PANASMoodId: MoodId,
                                      FormType: this.props.formType,
                                      FormId: this.props.formId,
                                      ClientId: this.props.ClientId,
                                    },
                                  ],
                                };
                              } else {
                                jsonData = {
                                  PANASTag: [
                                    {
                                      PANASTagId: PANASTagId,
                                      PANASMoodId: MoodId,
                                      FormType: this.props.formType,
                                      FormId: this.props.formId,
                                    },
                                  ],
                                };
                              }

                              this.props.dispatch(
                                panasTagDelete({
                                  Json: JSON.stringify(jsonData),
                                }),
                              );
                            }}
                          >
                            <i
                              class="far fa-trash-alt"
                              style={{ color: '#FF2C2C', marginRight: '15px' }}
                            ></i>
                          </span>
                        </div>
                      </>
                    );
                  })}
              </div>
            }
          >
            {PANASTag && PANASTag.PANASMoodId && (
              // <Button
              //   type="primary"
              //   style={{
              //     background: 'white',
              //     color: 'rgb(74, 176, 254)',
              //     border: 'none',
              //     boxShadow: 'none',
              //     fontWeight: '800',
              //   }}
              // >
              <img
                src="images/panas/morePanas.svg"
                style={{
                  width: '32px',
                  height: '32px',
                  cursor: 'pointer',
                  position: 'relative',
                  left: '-15px',
                }}
              />
              // </Button>
            )}
          </Popover>
        )}
      </>
    );
  }
}

export default CurrentPanasTag;
