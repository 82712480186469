import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import UploadProfile from '../../Components/uploadProfilePicture';
import { studentBanner } from '../../store/actions';
import Twilio from '../../Components/twilio';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
          },
        ],
      }),
    };
    this.props.dispatch(studentBanner(data));
  }
  capture = imageSrc => {
    const me = this;
    Axios.post('student/UploadImage', {
      image: imageSrc,
      studentId: this.props.ClientId,
      IsProfilePic: true,
    })
      .then(response => {
        if (response.data.success) {
          me.props.dispatch(
            studentBanner({
              Json: JSON.stringify({
                Student: [
                  {
                    StudentId: this.props.ClientId,
                  },
                ],
              }),
            }),
          );
        }
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  render() {
    const {
      StudentId,
      PrefferedName,
      PrimaryPhone,
      Email,
      StateOrProvince,
      PreIntakeClientStatus,
      City,
      PreItakeSurveyId,
      StudentImageUrl,
      IsVoiceCallAllowed,
      EditActionUrl,
    } = this.props.studentInfo;
    return (
      <div class="card border-0 students-profile mb-3">
        <div class="row card-body align-items-center">
          <div class="col-12 col-xl-5 mb-4 mb-xl-0">
            <div class="row align-items-center">
              <UploadProfile imgSrc={StudentImageUrl} captureProfile={this.capture} />

              <div class="col-12 col-sm-auto">
                <h4 class="students-name text-xs-center text-md-left">{PrefferedName}</h4>
                {IsVoiceCallAllowed > 0 ? (
                  <span
                    className="students-phone"
                    style={{ color: '#46B4FE', cursor: 'pointer', display: 'flex' }}
                    onClick={event => {
                      this.setState({
                        callModelVisible: true,
                        clickedRecord: {
                          Phone: PrimaryPhone,
                          Name: PrefferedName,
                          ProfileImageUrl: StudentImageUrl,
                        },
                      });
                      event.stopPropagation();
                    }}
                  >
                    <img
                      src={`images/twilioPhoneAllowed.svg`}
                      alt="Calling Allowed"
                      width="15px"
                      className="mr-1"
                    />
                    {PrimaryPhone}
                  </span>
                ) : (
                  <span style={{ color: '#000' }} className="students-phone">
                    {PrimaryPhone}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="row students-detail text-md-left justify-content-md-between">
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">Email</span>
                <h6
                  class="info-detail m-md-0"
                  // style={{
                  //   textOverflow: 'ellipsis',
                  //   whiteSpace: 'nowrap',
                  //   overflow: 'hidden',
                  //   width: '120px',
                  // }}
                >
                  {Email}
                </h6>
              </div>
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">State</span>
                <h6 class="info-detail m-md-0">{StateOrProvince}</h6>
              </div>
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">City</span>
                <h6 class="info-detail m-md-0">{City}</h6>
              </div>
              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                <span class="info-label">Status</span>
                <h6 class="info-detail m-md-0">{PreIntakeClientStatus}</h6>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-1">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                border: '2px solid #46B4FE',
                borderRadius: '20px',
                padding: '4px',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.history.push(EditActionUrl);
              }}
            >
              <img src="/images/editIcon.svg" />
              <span style={{ color: '#46B4FE', marginLeft: '7px' }}>Edit</span>
            </div>
          </div>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { student_banner } = state;
  const { data, isFetching, error } = student_banner;

  let studentInfo = {};
  let preItakeSurveyId = null;

  if (data) {
    studentInfo = data;
    preItakeSurveyId = data.PreItakeSurveyId;
  }

  return {
    studentInfo,
    preItakeSurveyId,
  };
};

export default connect(mapStateToProps)(withRouter(Banner));
