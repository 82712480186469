import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoCompleteText,
} from '../../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Autocomplete from 'react-autocomplete';
import CustomModal from '../../core/modal';
import AntdModal from '../../Components/CustomModal';
import moment from 'moment';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import { getFormFieldMetaData, upsertFormData, formDataGet } from '../../store/actions';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Deactivate_Sucess_Msg,
} from '../../app-config';

import validate, {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from '../enrollmentForm/validation/validate';

import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../core/generic-form';
import { ProcessCombos } from '../../store/comboConfig';
import PageTitle from '../../Components/PageTitle';
import jsPDF from 'jspdf';
let namedExports = {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
};

export const FORMATTER_TYPES = {
  dateofbirthNormalize: dateofbirthNormalize,
  ssnFormatter: ssnFormatter,
  ssnNormalize: ssnNormalize,
  MobileFormatter: MobileFormatter,
  MobileNormalize: MobileNormalize,
};

const EmergencyContact = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  Filter_StateProvince,
  Country,
  emergencyContacts,
  change,
  disable,
  dispatch,
}) => {
  const countryChange = (countryISO, currentIndex) => {
    /*if (countryISO) {
      dispatch(
        ProcessFilterCombos({
          combo: 'StateProvince',
          CountryISO: countryISO,
          filterBy: countryISO,
        }),
      );
    }*/
  };

  var formFields = [
    {
      name: 'line1',
      // fieldName: 'Line 1',
      fieldName: 'Permanent Address Line 1',
    },
    {
      name: 'line2',
      // fieldName: 'Line 2',
      fieldName: 'Permanent Address Line 2',
    },
  ];

  return (
    <DynamicSection
      sectionKey={'EmergencyContact'}
      sections={fields}
      title={'Address'}
      deleteTitle={'Delete Address'}
      addTitle={'Address'}
      formFields={formFields}
      onAdd={() => fields.push({ country: 'USA' })}
      disable={disable}
      rows={emergencyContacts}
    />
  );
};
const EmergencyContact1 = connect(state => {
  const emergencyContacts = selector(state, 'EmergencyContact');
  return {
    emergencyContacts,
  };
})(EmergencyContact);

class GerericForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
    };
  }

  sendFormData = values => {
    let valueArray = Object.keys(values);
    const obj = {};
    debugger;
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else {
        obj[key] = values[key];
      }
    }
    let jsonData = {
      DynamicForm: [
        {
          FormName: 'Announcement',
          FormDataId: this.props.getAnnouncementId ? this.props.getAnnouncementId : null,
          FormData: {
            Announcement: [
              {
                ...obj,
              },
            ],
          },
        },
      ],
    };
    this.props.dispatch(
      upsertFormData({
        Json: JSON.stringify(jsonData),
      }),
    );
  };

  componentDidMount() {
    // let optionsList = this.state.formFields.map(item => {
    //   item.options;
    // });
    // this.setState({ optionsList });

    var result = ProcessCombos(
      'Gender,StateProvince,EthnicRace,FaithType,EducationType,MaritalStatus,Veteran,ReferenceSource,CenterProgramType,Ethnicity',
    );
    // ,Practitioner
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let jsonData = {
      DynamicForm: [
        {
          FormName: 'Announcement',
        },
      ],
    };
    this.props.dispatch(
      getFormFieldMetaData({
        Json: JSON.stringify(jsonData),
      }),
    );
  }

  componentWillReceiveProps({ genericFormData, comboData }) {
    // if (comboData && JSON.stringify(comboData) != JSON.stringify(this.props.comboData)) {
    //   if (genericFormData) {
    //     debugger;
    //     let genericFormDataModified = genericFormData.map(item => {
    //       return { ...item, options: comboData[item.options] };
    //     });
    //     this.setState({ formFields: genericFormDataModified });
    //   }
    // }
    if (
      genericFormData &&
      JSON.stringify(genericFormData) != JSON.stringify(this.props.genericFormData)
    ) {
      // let validationConversion = genericFormData.map(item => {
      //   debugger;
      //   let v = JSON.parse(item.validate);
      //   v = v.map(item => {
      //     return validate[item];
      //   });
      //   return {
      //     ...item,
      //   };
      // });
      let validationConversion = genericFormData;

      this.setState({ formFields: validationConversion });
      let jsonData2 = {
        DynamicForm: [
          {
            FormName: 'Announcement',
            FormDataId: this.props.getAnnouncementId,
          },
        ],
      };

      this.props.dispatch(
        formDataGet({
          Json: JSON.stringify(jsonData2),
        }),
      );
    }
  }

  render() {
    const { formFields, optionsList } = this.state;
    const { handleSubmit, comboData, onCancel } = this.props;
    debugger;
    // let
    // const [...optionsList] = object.keys(comboData);
    return (
      <React.Fragment>
        <PageTitle TitleText="Generic Form" />
        <div className="main enrollment-page  mb-5">
          <div className="container-fluid">
            <form
              onSubmit={handleSubmit(values => {
                debugger;
                this.sendFormData(values);
              })}
            >
              <div class="row top-heading-row mt-5 mb-3 align-items-center">
                <div class="col-12 col-md-4 col-lg-3">
                  <h3 class="form-main-head mb-3 mb-md-0">
                    {this.props.getAnnouncementId > 0 ? 'Edit Announcement' : 'Add a Announcement'}
                  </h3>
                </div>
                <div class="col-12 col-md-8 col-lg-9 text-right">
                  <button onClick={onCancel} class="btn  btn-outline-primary mr-2">
                    Cancel
                  </button>
                  {/* <button
                  onClick={() =>
                    this.props.dispatch(() => {
                      debugger;
                    })
                  }
                  class="btn"
                >
                  Save
                </button> */}
                  <input
                    type="submit"
                    class="btn"
                    value="Submit"
                    // disabled={IsDeActivated}
                  />
                </div>
              </div>

              <div className="">
                <div className="enrollment-form pt-2 pb-2">
                  <div className="row pb-5 px-3 pt-3">
                    {' '}
                    <DynamicForm
                      formFields={formFields}

                      // disable={disable}
                    />
                  </div>
                  {/* <div className="row">
                <div class="col-12">
                  <FieldArray
                    name="EmergencyContact"
                    component={EmergencyContact1}
                    // RelationShip={RelationShip}
                    // Filter_StateProvince={Filter_StateProvince}
                    // Country={Country}
                    // change={change}
                    // disable={IsDeActivated}
                    dispatch={this.props.dispatch}
                  />
                </div>
              </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { combos, get_form_field_meta_data } = state;
  const { data: getGenericFormFields } = get_form_field_meta_data;

  let comboData = {};
  let genericFormData = [];
  if (combos && combos.data) {
    comboData = combos.data || {};
  }
  if (getGenericFormFields) {
    genericFormData = getGenericFormFields.FormFieldMetaData;

    // genericFormData = genericFormData.map(item => {
    //   let a;
    //   if (item.validate) {
    //     a = item.validate.split(',');
    //   } else {
    //     a = ['required'];
    //   }

    //   return { ...item, validate: a };
    // });
  }

  return {
    genericFormData,
    comboData,
  };
};

const ReduxGenericForm = reduxForm({
  form: 'genericForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(GerericForm)));

const selector = formValueSelector('genericForm');

class GenericFormParent extends React.Component {
  getAnnouncementId = () => {
    debugger;
    var param = new URLSearchParams(this.props.history.location.search);
    var announcementId = param.get('announcementId') || param.get('announcementId');
    if (announcementId) {
      return (announcementId = parseInt(announcementId));
    } else {
      announcementId = -1;
    }
    return announcementId;
  };

  render() {
    return (
      <>
        <ReduxGenericForm
          initialValues={this.props.initialValues}
          getAnnouncementId={this.getAnnouncementId()}
          onCancel={() => this.props.history.push('/ds-announcement')}
        />
      </>
    );
  }
}

const mapStateToParent = state => {
  const { form_data_get } = state;
  const { data: announcementGetData } = form_data_get;
  let announcementData = {};

  if (announcementGetData && announcementGetData.Announcement) {
    debugger;
    announcementData = announcementGetData.Announcement[0];
  }
  return {
    initialValues: announcementData,
  };
};

export default connect(mapStateToParent)(GenericFormParent);
