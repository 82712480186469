import React, { Component } from 'react';
import { Checkbox } from 'antd';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';
import $ from 'jquery';

export default props => {
  const { survey, onClick, scope, index, total } = props;
  const {
    Question,
    QuestionNumber,
    DoesNotApplyText,
    DoesNotApplyValue,
    Option1Value,
    Option1Text,
    Option2Value,
    Option2Text,
    Option3Value,
    Option3Text,
    Option4Value,
    Option4Text,
    Option5Value,
    Option5Text,
    Option6Value,
    Option6Text,
    Response,
    SurveyQuestionId,
  } = survey;
  const textArray = [Option1Text, Option2Text, Option3Text, Option4Text, Option5Text, Option6Text],
    valueArray = [
      Option1Value,
      Option2Value,
      Option3Value,
      Option4Value,
      Option5Value,
      Option6Value,
    ];
  return (
    <div className="type-border col-12">
      <div>
        <SurveyQuestion survey={survey} ClassName={'instruction-type'} />
        <DoesNotApplyOption surveyProps={props} />
      </div>
    </div>
  );
};
