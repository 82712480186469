import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../../validation/validate';
import moment from 'moment';
import { mapTime } from '../../../../../util/time';
import SearchPatient from '../../../../../Components/searchPatient';
import { withRouter } from 'react-router-dom';
import {
  ReduxInput,
  ReduxCheckbox,
  ReduxSelect,
  ReduxDateInput,
  ReduxTimePicker,
  ReduxToggle,
  ReduxTextarea,
} from '../../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { bedAssignmentUpsert } from '../../../../../store/actions';
// import { ProcessCombos } from '../../../../../store/comboConfig';
// import Loader, { Loader2 } from '../../../../../Components/Loader';

class AdmitForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setSearchClientValues = data => {
    this.props.updateInitValuesForExistingPaitents(data);
  };

  getAdmitFormValues = values => {
    const {
      CenterRehabRoomId,
      FirstName,
      LastName,
      Phone,
      AdmitDate,
      AdmitTime,
      NumofDays,
      DischargeDate,
      BedAssigmentStatus,
      Comment,
    } = values;

    let data;

    if (this.props.initialValues && this.props.initialValues.ClientId) {
      data = {
        BedMangement: [
          {
            BedAssigmentId: null,
            CenterRehabRoomId,
            CenterRehabRoomBedId: this.props.initialValues.CenterRehabRoomBedId,
            ClientId: this.props.initialValues.ClientId,
            FirstName,
            LastName,
            Phone,
            AdmitDate: moment(AdmitDate).format('YYYY-MM-DD'),
            AdmitTime: mapTime(AdmitTime),
            NumofDays,
            DischargeDate: DischargeDate ? moment(DischargeDate).format('YYYY-MM-DD') : null,
            BedAssigmentStatus,
            Comment,
          },
        ],
      };
    } else {
      data = {
        BedMangement: [
          {
            BedAssigmentId: null,
            CenterRehabRoomId,
            CenterRehabRoomBedId: this.props.initialValues.CenterRehabRoomBedId,
            ClientId: null,
            FirstName,
            LastName,
            Phone,
            AdmitDate: moment(AdmitDate).format('YYYY-MM-DD'),
            AdmitTime: mapTime(AdmitTime),
            NumofDays,
            DischargeDate: DischargeDate ? moment(DischargeDate).format('YYYY-MM-DD') : null,
            BedAssigmentStatus,
            Comment,
          },
        ],
      };
    }

    var admitData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(bedAssignmentUpsert(admitData));
  };

  // handleCalcDuration = () => {
  //   const { admitDate, dischargeDate } = this.props;
  //   if (admitDate) {
  //     if (dischargeDate) {
  //       let a = `${moment(admitDate).format('MM/DD/YYYY')}`;
  //       let b = `${moment(dischargeDate).format('MM/DD/YYYY')}`;
  //       var diff = moment.duration(moment(b).diff(moment(a)));
  //       let days = parseInt(diff.asDays()) + 1;
  //       this.props.change('NumofDays', days);
  //     }
  //   }
  // };

  componentWillReceiveProps({ days, existingPaitent }) {
    if (existingPaitent != this.props.existingPaitent) {
      if (existingPaitent == false) {
        this.props.updateInitValuesForExistingPaitents({
          FirstName: '',
          LastName: '',
          Phone: '',
          ClientId: '',
        });
      }
    }
    if (days && this.props.days != days) {
      this.props.change('NumofDays', days);
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className="container mt-3 enrollment-form">
          <form onSubmit={handleSubmit(this.getAdmitFormValues)}>
            <div class="row mb-4">
              <div
                className={
                  this.props.existingPaitent
                    ? 'col-6 col-md-4 col-lg-4'
                    : 'col-6 col-md-12 col-lg-12'
                }
              >
                <div class="form-group">
                  <label class="control-label">Is Existing Patient?</label>
                  <br />
                  <Field
                    name="ExistingPaitent"
                    component={ReduxToggle}
                    // onChange={bool => toogleChange(bool, index)}
                  />
                </div>
              </div>
              {this.props.existingPaitent && (
                <div class="col-12 col-md-8 col-lg-8">
                  <div class="form-group">
                    <SearchPatient setSearchClientValues={this.setSearchClientValues} />
                  </div>
                </div>
              )}
              {/* <div class="col-12 col-md-12 col-lg-12 d-flex align-items-center mb-3">
                {' '}
                {this.props.initialValues && this.props.initialValues.ClientId && (
                  <>
                    {' '}
                    <img src="images/idP.svg" style={{ height: '27' }} />
                    &nbsp;
                    <span>Client ID: </span>
                  </>
                )}
                <b
                  onClick={val => {
                    this.props.history.push(
                      `/cw/patient-management/patient-profile?tab=demographic&pId=${this.props.initialValues.ClientId}`,
                    );
                  }}
                >
                  {' '}
                  {this.props.initialValues && this.props.initialValues.ClientId}
                </b>
              </div> */}
              {this.props.existingPaitent && (
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`ClientId`}
                      type="text"
                      component={ReduxInput}
                      // validate={validate.required}
                      fieldName={'room'}
                      // options={[
                      //   { Value: 101, Text: '101' },
                      //   { Value: 102, Text: '102' },
                      // ]}
                      placeholder="Room"
                      disabled={true}
                    />
                    <label class="placeholder-label">Patient Id</label>
                  </div>
                </div>
              )}

              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`CenterRehabRoomId`}
                    type="text"
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'room'}
                    // options={[
                    //   { Value: 101, Text: '101' },
                    //   { Value: 102, Text: '102' },
                    // ]}
                    placeholder="Room"
                    disabled={true}
                  />
                  <label class="placeholder-label">Room Number</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`bedNumber`}
                    type="text"
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'bed'}
                    // options={[
                    //   { Value: 1, Text: '1' },
                    //   { Value: 2, Text: '2' },
                    //   { Value: 3, Text: '3' },
                    //   { Value: 4, Text: '4' },
                    //   { Value: 5, Text: '5' },
                    //   { Value: 6, Text: '6' },
                    //   { Value: 7, Text: '7' },
                    //   { Value: 8, Text: '8' },
                    // ]}
                    placeholder="Bed"
                    disabled={true}
                  />
                  <label class="placeholder-label">Bed Number</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`FirstName`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'First Name'}
                  />
                  <label class="placeholder-label">First Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`LastName`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Last Name'}
                  />
                  <label class="placeholder-label">Last Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`AdmitDate`}
                    component={ReduxDateInput}
                    validate={validate.required}
                    fieldName={'Admit Date'}
                    label="Admit Date *"
                    minDate={new Date()}
                    // onChange={this.handleCalcDuration}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`DischargeDate`}
                    component={ReduxDateInput}
                    // validate={validate.required}
                    fieldName={'Discharge Date'}
                    label="Expected Discharge Date"
                    minDate={new Date()}
                    // onChange={this.handleCalcDuration}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`AdmitTime`}
                    component={ReduxTimePicker}
                    validate={validate.required}
                    fieldName={'Admit Time'}
                    label={'Admit Time *'}
                    minuteStep={5}
                  />
                  {/* <label class="placeholder-label">Admit Time</label> */}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`Phone`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Phone'}
                  />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`NumofDays`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Number of days'}
                    disabled={true}
                  />
                  <label class="placeholder-label">Number of days</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <Field
                    name={`BedAssigmentStatus`}
                    type="text"
                    component={ReduxSelect}
                    validate={validate.required}
                    fieldName={'Status'}
                    options={[
                      { Value: 1, Text: 'Admitted' },
                      { Value: 2, Text: 'Reserved' },
                      { Value: 3, Text: 'Pending' },
                    ]}
                    placeholder="Status*"
                  />
                  {/* <label class="placeholder-label">Payer Type</label> */}
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <Field
                    name="Comment"
                    type="textarea"
                    component={ReduxTextarea}
                    fieldName={'note'}
                    style={{
                      minHeight: '100px',
                      borderRadius: '12px',
                      border: 'none',
                      padding: '10px',
                    }}
                    placeholder="Notes..."
                    showPlaceHolder={true}
                  />
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4">
                <input type="submit" class="btn px-5" value={'Submit'} />
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  let days = 0;
  const existingPaitent = formValueSelector('admissionForm')(state, 'ExistingPaitent');
  const admitDate = formValueSelector('admissionForm')(state, 'AdmitDate');
  const admitTime = formValueSelector('admissionForm')(state, 'AdmitTime');
  const dischargeDate = formValueSelector('admissionForm')(state, 'DischargeDate');
  if (admitDate && dischargeDate) {
    let a = `${moment(admitDate).format('MM/DD/YYYY')}`;
    console.log(a);
    let b = `${moment(dischargeDate).format('MM/DD/YYYY')}`;
    console.log(b);
    var diff = moment.duration(moment(b).diff(moment(a)));
    // var diff = moment(b).diff(moment(a));
    days = parseInt(diff.asDays()) + 1;
    // var diff = moment.duration(moment(b).diff(moment(a)));

    // console.log(diff);
    console.log(days);
  }

  return {
    existingPaitent,
    admitDate,
    dischargeDate,
    days,
  };
};

const ReduxBedManagementForm = reduxForm({
  form: 'admissionForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(AdmitForm)));

export default ReduxBedManagementForm;
