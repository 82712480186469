import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Table } from 'antd';

class TaskCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { EventJsonData } = this.props;
    let {
      TaskStatus,
      FirstName,
      LastName,
      TodoTitle,
      AssignedDate,
      UnassignDate,
      DueDateString,
      FinishedDate,
      DueDateFontColor,
    } = EventJsonData.TaskUser && EventJsonData.TaskUser[0];
    const taskStatus = {
      '-1': {
        color: '#fef2f2',
        hideButtons: true,
        date: UnassignDate,
        name: 'Unassigned',
      },
      '0': {
        color: '#f8f8f8',
        hideButtons: false,
        date: DueDateString,
        dateFontColor: DueDateFontColor,
        name: 'Due',
      },
      '1': {
        color: 'rgb(239, 247, 254)',
        hideButtons: true,
        date: FinishedDate,
        name: 'Completed',
      },
    };
    // AppTaskId: 2;
    // AssignedDate: '21-Dec-2020';
    // AssignedDateForSort: '20201221';
    // DueDate: '28-Dec-2020';
    // DueDateFontColor: '#b60c0c';
    // DueDateForSort: '20201228';
    // DueDateString: '28-Dec-2020';
    // FinishedDate: '22-Mar-2021';
    // FinishedDateForSort: '2021-03-22T10:10:00';
    // FirstName: '(Dev)';
    // LastName: 'Lutfa (Dev) (Dev) (Dev)';
    // TaskStatus: 1;
    // TaskUserId: 59;
    // TodoTitle: 'Mow the lawn';
    // TodoType: 'Task';
    // UnassignDate: null;
    // UnassignDateForSort: null;
    // UserId: 100000532;
    return (
      <div class="border-0">
        <div class="row">
          <div className="col-12">
            <div className="task-box" style={{ backgroundColor: taskStatus[TaskStatus].color }}>
              <div className="row no-gutters w-100 justify-content-between align-items-center">
                <div className="col-12 col-md-auto mb-1">
                  <div className="course-name">
                    <h6>{TodoTitle}</h6>
                    <div className="d-flex">
                      <div className="">
                        <span className="due-date">
                          <em>Assigned: : </em> {AssignedDate}
                        </span>
                      </div>
                      <div className="">
                        <span
                          className="due-date"
                          style={{ color: taskStatus[TaskStatus].dateFontColor }}
                        >
                          <em>{taskStatus[TaskStatus].name} : </em> {taskStatus[TaskStatus].date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TaskCompleted;
