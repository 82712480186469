import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import ReduxVitalForm from './medicationCustom/vitalForm';
import { Table, Checkbox, Radio, Modal } from 'antd';
import moment from 'moment';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import PersistTableSort from '../../../../component/persistTableSort';
import { getClientVitalList, restUpsertClientVital } from '../../../../store/actions';
import { ProcessCombos, ProcessFilterCombos } from '../../../../store/comboConfig';
import CustomModal from '../../../../core/modal';

class VitalList extends Component {
  constructor() {
    super();
    this.state = {
      isVitalModal: false,
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentDidMount() {
    var result = ProcessCombos('GlucoseReadingType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(
      getClientVitalList({
        Json: JSON.stringify({
          ClientVital: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ vitalInfo, isUpsert }) {
    if (isUpsert && isUpsert != this.props.isUpsert) {
      this.setState({
        isVitalModal: false,
        vitalInfoData: {},
        ClientVitalId: null,
      });
      this.props.dispatch(reset('vitalForm'));
      this.toggle('Vital added successfully');
    }
    if (vitalInfo && JSON.stringify(vitalInfo) != JSON.stringify(this.props.vitalInfo)) {
      let data = {
        ClientVitalId: vitalInfo.ClientVitalId,
        ClientId: vitalInfo.ClientId,
        counsultingCounselor: vitalInfo.CounselorId,
        bloodPressureReading: vitalInfo.BloodPressure,
        temperatureReading: vitalInfo.Temperature,
        pulseReading: vitalInfo.Pulse,
        respirationReading: vitalInfo.Respiration,
        glucoseReading: vitalInfo.Glucose,
        glucoseReadingType: vitalInfo.GlucoseReadingType,
        weightReading: vitalInfo.Weight,
        oxygenSaturationReading: vitalInfo.OxygenSaturation,
        bmiReading: vitalInfo.BMI,
        testDate: moment(vitalInfo.TestDate).format('YYYY-MM-DD'),
        testTime: vitalInfo.TestTime,
        // a: vitalInfo.TestDateTime,
        intervention: vitalInfo.Intervention,
        notes: vitalInfo.Notes,
        // a: vitalInfo.DateInserted,
        // a: vitalInfo.IsArchived,
        // a: vitalInfo.ArchivedDate,
        // a: vitalInfo.LastUpdatedBy,
      };
      this.setState({
        vitalInfoData: data,
      });
    }
  }
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      getClientVitalList({
        json: JSON.stringify({
          ClientVital: [
            {
              Search: value,
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  };

  columns = [
    {
      title: 'Counselor',
      dataIndex: 'Counsellor',
      key: 'Counsellor',
      sorter: (a, b) => isComparer(a.Counsellor, b.Counsellor),
    },
    {
      title: 'BloodPressure',
      dataIndex: 'BloodPressure',
      key: 'BloodPressure',
      sorter: (a, b) => isComparer(a.BloodPressure, b.BloodPressure),
    },
    {
      title: 'Temperature (degrees)',
      dataIndex: 'Temperature',
      key: 'Temperature',
      sorter: (a, b) => isComparer(a.Temperature, b.Temperature),
    },
    {
      title: 'Pulse',
      dataIndex: 'Pulse',
      key: 'Pulse',
      sorter: (a, b) => isComparer(a.Pulse, b.Pulse),
    },
    {
      title: 'Respiration',
      dataIndex: 'Respiration',
      key: 'Respiration',
      sorter: (a, b) => isComparer(a.Respiration, b.Respiration),
    },
    {
      title: 'Weight (lb(s))',
      dataIndex: 'Weight',
      key: 'Weight',
      sorter: (a, b) => isComparer(a.Weight, b.Weight),
    },
    {
      title: 'Oxygen Saturation',
      dataIndex: 'OxygenSaturation',
      key: 'OxygenSaturation',
      sorter: (a, b) => isComparer(a.OxygenSaturation, b.OxygenSaturation),
    },
    {
      title: 'Glucose',
      dataIndex: 'Glucose',
      key: 'Glucose',
      sorter: (a, b) => isComparer(a.Glucose, b.Glucose),
    },
    {
      title: 'BMI',
      dataIndex: 'BMI',
      key: 'BMI',
      sorter: (a, b) => isComparer(a.BMI, b.BMI),
    },
    {
      title: 'Test Time',
      dataIndex: 'TestTime',
      key: 'TestTime',
      sorter: (a, b) => isComparer(a.TestTime, b.TestTime),
    },
    {
      title: 'Test Date',
      dataIndex: 'TestDate',
      key: 'TestDate',
      sorter: (a, b) => isDateComparer(a.TestDateForSort, b.TestDateForSort),
    },

    {
      title: 'Action',
      key: 'x',
      render: (text, record, index) => {
        if (!this.props.isReadOnlyUser) {
          return (
            <div class="tools-grid">
              <div className="mr-4">
                <span className="start-btn">
                  <img
                    src="images/shape.svg"
                    width="14px"
                    style={{ cursor: 'pointer' }}
                    onClick={event => {
                      this.setState({
                        isVitalModal: true,
                        ClientVitalId: record.ClientVitalId,
                      });
                    }}
                  />
                </span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];

  render() {
    const { isReadOnlyUser } = this.props;
    return (
      <div class="main survery-question-page mb-5">
        <div className="container-fluid rcm-tab">
          <div className="row">
            <div className="col-12">
              <div className="border-0">
                <div className="row px-0 mt-5 d-flex align-items-center">
                  <div className="col-12 col-xl-3 px-0">
                    <h4 className="">Vital Log</h4>
                  </div>
                  <div className="col-9 col-xl-9 px-0 d-flex align-items-center justify-content-end">
                    <div className="search-box d-inline-flex w-100">
                      <div className="form-group has-search w-100 mb-0">
                        <input
                          type="text"
                          className="form-control 
                   shadow-sm rcm-document-search"
                          placeholder="Search..."
                          onChange={this.onSearchChange}
                        />
                        <button className="btn search-icon " type="button">
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                    {!isReadOnlyUser && (
                      <button
                        style={{ cursor: 'pointer', marginLeft: '25px' }}
                        class="btn black-btn"
                        color="blue"
                        onClick={event => {
                          this.setState({
                            isVitalModal: true,
                            vitalInfoData: {},
                            ClientVitalId: null,
                          });
                        }}
                      >
                        Add Vital Details
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 pt-5 pb-5 rcm-subtab-table-block">
            <div className="col-12">
              <div className="">
                <PersistTableSort
                  name="VitalList"
                  columns={this.columns}
                  dataSource={this.props.ClientVital}
                  size={'middle'}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div class="dataTables_wrapper no-footer">
                          <div class="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a class="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a class="paginate_button next">Next</a>
                            ) : (
                              <a class="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          zIndex={100}
          visible={this.state.isVitalModal}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose
        >
          <ReduxVitalForm
            initialValues={this.state.vitalInfoData}
            ClientVitalId={this.state.ClientVitalId}
            ClientId={this.getClientId()}
            onCancel={() => {
              this.setState({
                isVitalModal: false,
              });
            }}
          />
        </Modal>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(
              getClientVitalList({
                Json: JSON.stringify({
                  ClientVital: [
                    {
                      ClientId: this.getClientId(),
                    },
                  ],
                }),
              }),
            );
            this.props.dispatch(restUpsertClientVital());
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ get_client_vital_list, get_client_vital, upsert_client_vital }) => {
  const { data, isFetching } = get_client_vital_list;
  const { data: vitalData } = get_client_vital;

  let ClientVital = [];
  let vitalInfo = null;
  let isUpsert = false;

  if (data && data.ClientVital) {
    ClientVital = data.ClientVital || [];
  }
  if (vitalData && vitalData.ClientVital) {
    vitalInfo = vitalData.ClientVital[0];
  }
  if (upsert_client_vital.data && upsert_client_vital.data.success) {
    isUpsert = true;
  }
  return {
    ClientVital,
    isFetching,
    vitalInfo,
    isUpsert,
  };
};
export default connect(mapStateToProps)(withRouter(VitalList));
