import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { Drawer, Tabs } from 'antd';
import { getMMMYYYYFormat } from '../../../../../util/dateFormater';
import Details from './Details';
import Transcript from './Transcript';
import NotesAI from './NotesAI';
import Notes from './Notes';
import { mapTime } from '../../../../../util/time';
import { callSummaryAugment } from '../../../../../store/actions';
const { TabPane } = Tabs;
class AppointmentDetail extends React.Component {
  constructor(props) {
    super(props);
    let tabId = parseInt(props.TabId);
    const { StudentId } = props.BannerData || {};
    this.state = {
      activeKey: tabId || 0,
      initialData: {
        AppointmentId: props.AppointmentId,
        ClientId: props.ClientId,
      },
      TranscriptData: null,
      StudentId: StudentId,
      list: [
        {
          Name: 'Details',
          Component: props => {
            return (
              <Details
                initialValues={this.state.initialData}
                OptionData={this.props.OptionData}
                handleAppointment={() => {}}
                disable={true}
              />
            );
          },
        },
      ],
    };
  }
  peerTabs = [
    {
      Name: 'Transcript',
      Component: props => {
        return (
          <Transcript
            TranscriptData={this.state.TranscriptData}
            AppointmentData={this.state.initialData}
            StudentId={this.state.StudentId}
            IsServiceCodeApproved={this.state.IsServiceCodeApproved}
          />
        );
      },
    },
    {
      Name: 'Notes',
      Component: props => {
        return (
          <NotesAI
            TranscriptData={this.state.TranscriptData}
            AppointmentData={this.state.initialData}
            AppointmentId={this.state.initialData.AppointmentId}
            StudentId={this.state.StudentId}
            IsServiceCodeApproved={this.state.IsServiceCodeApproved}
          />
        );
      },
    },
  ];
  notesTab = [
    {
      Name: 'Notes',
      Component: props => {
        return (
          <Notes
            AppointmentData={this.state.initialData}
            StudentId={this.state.StudentId}
            AppointmentId={this.state.initialData.AppointmentId}
            IsServiceCodeApproved={this.state.IsServiceCodeApproved}
          />
        );
      },
    },
  ];
  getCallSummaryAugment = () => {
    const { ClientId, AppointmentId } = this.state.initialData || {};
    this.props.dispatch(
      callSummaryAugment({
        Json: JSON.stringify({
          Student: [
            {
              AppointmentId: AppointmentId,
              StudentId: this.state.StudentId,
              AugmentType: 1,
              IsRegenerate: false,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    const { IsPeerRecovery } = this.props;
    let newList;
    if (IsPeerRecovery) {
      newList = [...this.state.list, ...this.peerTabs];
    } else {
      newList = [...this.state.list, ...this.notesTab];
    }
    this.setState({
      list: newList,
    });
  }
  componentWillReceiveProps({ initialData, TranscriptData, AppointmentData }) {
    // if (initialData && JSON.stringify(initialData) != JSON.stringify(this.props.initialData)) {
    //   this.setState({
    //     initialData: initialData,
    //   });
    // }
    if (
      AppointmentData &&
      JSON.stringify(AppointmentData) != JSON.stringify(this.props.AppointmentData)
    ) {
      let appoinmentValues = {
        ...this.state.initialData,
        ...AppointmentData,
        ClientId: AppointmentData.ClientId,
        StudentId: AppointmentData.StudentId,
        AppointmentId: AppointmentData.AppointmentId,
        EventTitle: AppointmentData.Title,
        AppointmentDate: AppointmentData.AppointmentDate,
        Program: parseInt(AppointmentData.ProgramId),
        StartTime: AppointmentData.AppointmentTime && mapTime(AppointmentData.AppointmentTime),
        EndTime: AppointmentData.AppointmentEndTime && mapTime(AppointmentData.AppointmentEndTime),
        ServiceCode: AppointmentData.ServiceCode,
        PractitionerId: AppointmentData.PractitionerId,
        PlaceOfServiceId: AppointmentData.PlaceOfServiceId,
        OfficeName: AppointmentData.OfficeName,
        Status: parseInt(AppointmentData.Status),
        ReasonForVisit: AppointmentData.ReasonForVisit,
        TimerDurationInSec: AppointmentData.TimerDurationInSec,
        Notes: AppointmentData.Notes,
      };
      this.setState({
        initialData: appoinmentValues,
        IsServiceCodeApproved: AppointmentData.IsServiceCodeApproved,
      });
    }
    if (
      TranscriptData &&
      JSON.stringify(TranscriptData) != JSON.stringify(this.props.TranscriptData)
    ) {
      this.setState({
        TranscriptData: TranscriptData,
      });
      this.getCallSummaryAugment();
    }
  }
  onTabClick = key => {
    this.setState({
      activeKey: parseInt(key),
    });
  };
  render() {
    const { activeKey, initialData, list } = this.state;
    const { IsPeerRecovery, callSummary } = this.props;
    return (
      <div className="peer-recovery prospect-appointments-detail mt-2">
        <div class="row call-end-summary">
          <div className="col-12 my-2">
            <span className="appointment-date">
              {getMMMYYYYFormat(initialData.AppointmentDate)}
            </span>
          </div>
          {IsPeerRecovery && !this.state.TranscriptData && (
            <div className="col-12">
              <div className="call-summary">
                <span>Augmented Call Summary is being prepared we will notify you</span>
              </div>
            </div>
          )}
          {IsPeerRecovery && this.state.TranscriptData && callSummary && (
            <div className="col-12">
              <div className="mb-2 call-summary">
                <span className="summary-title">Augmented Call Summary</span>
                <span className="call-summary-text-desc">
                  {callSummary && renderHTML(callSummary)}
                </span>
              </div>
            </div>
          )}
          <div className="col-12 center-tabs peer-recovery-tab">
            <Tabs activeKey={String(activeKey)} defaultActiveKey="0" onTabClick={this.onTabClick}>
              {list.map((item, index) => {
                const { Component, Name } = item;
                return (
                  <TabPane tab={Name} key={`${index}`} className="tab-content enrollment-form">
                    {activeKey == index && <Component style={{ padding: '36px 0px' }} />}
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ call_summary_augment, get_appointment }) => {
  const { data: summaryData } = call_summary_augment;
  const {
    data: getAppointmentData,
    isFetching: isFetchingGetAppointment,
    error: errorGetAppointment,
  } = get_appointment;

  let callSummary = null;
  let AppointmentData = null;

  if (summaryData && summaryData.Answer) {
    callSummary = summaryData.Answer;
  }
  if (getAppointmentData && getAppointmentData.Appointment) {
    AppointmentData = getAppointmentData.Appointment[0];
  }

  return {
    callSummary,
    AppointmentData,
  };
};
export default connect(mapStateToProps)(withRouter(AppointmentDetail));
