import React from 'react';
import { Tabs, Modal, Tooltip, Button, Popover } from 'antd';
import { connect } from 'react-redux';
import { submit, reset, destroy, Field, reduxForm, isDirty } from 'redux-form';
import Webcam from 'react-webcam';
import { get } from 'lodash';
import renderHTML from 'react-render-html';
import {
  addStudent,
  resetAddRequest,
  getStudent,
  studentStaffAccessedTabGet,
  resetGetStudent,
  addNewApplicant,
  getClientCounselor,
  getDynamicComos,
  getStudentProfileDashboardData,
  listSmartCombos,
  rcmClientMerge,
  centerConfigGetById,
} from '../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import StudentInformation from './formSteps/studentInformation';
import ContactInformation from './formSteps/contactInformation';
import Demographic from './formSteps/demographic';
import AdolescentInfo from './formSteps/adolescentInfo';
import AddictionProfile from './formSteps/addictionProfile';
import PreviousTreatments from './formSteps/previousTreatments';
import PriorAbuse from './formSteps/priorAbuse';
import FaithProfile from './formSteps/faithProfile';
import LevelOfCare from './formSteps/levelOfCare';
import PaymentForCare from './formSteps/paymentForCare';
import HealthInsurance from './formSteps/healthInsurance';
import LegalIssues from './formSteps/legalIssues';
import MedicalHistory from './formSteps/medicalHistory';
import Associations from './formSteps/Associations';
import ViewAnswerTab from './formSteps/screening';
import Financial from './formSteps/financial';
import LabOrder from './formSteps/labOrder';
import CustomModal from '../../core/modal';
import CustomModal2 from '../../core/modal';
import Loader from '../../Components/Loader';
import { getDate, stringDate } from '../../util/date';
import AntdModal from '../../Components/CustomModal';
import { COMBOS_LOADED } from '../../store/comboConfig';
import WarningComponent from '../../core/warning-component';
import UploadProfile from '../../Components/uploadProfilePicture';
import { getPhoneNumber, getCountryCalling } from '../../util/phoneFormatter';
import ActionableIcon from '../../Components/ActionableIcon';
import PageTitle from '../../Components/PageTitle';
import ReduxNewApplicantForm from '../enrollmentForm/newApplicant';
import Axios from 'axios';
import moment from 'moment';
const { TabPane } = Tabs;

const Components = {
  StudentInformation,
  LevelOfCare,
  ContactInformation,
  Demographic,
  HealthInsurance,
  AddictionProfile,
  PreviousTreatments,
  PriorAbuse,
  FaithProfile,
  PaymentForCare,
  LegalIssues,
  MedicalHistory,
  AdolescentInfo,
  Associations,
  ViewAnswerTab,
  LabOrder,
  Financial,
};

const formatDOB = val => {
  if (val) {
    var date = getDate(val);
    var dateString = '';
    var month = date.getMonth() + 1;
    if (month < 10) {
      dateString = '0' + month + '/';
    } else {
      dateString = month + '/';
    }
    var day = date.getDate();
    if (day < 10) {
      dateString += '0' + day + '/';
    } else {
      dateString += day + '/';
    }

    dateString += date.getFullYear();

    return dateString;
  }
  return val;
};

class SlidingTabs extends React.Component {
  constructor(props) {
    super(props);
    props.reset();
    this.state = {
      isProcessed: false,
      tabNames: [],
      tabLabels: [
        // {
        //   Name: 'Client Information',
        //   Component: props => <StudentInformation {...props} />,
        // },
        // {
        //   Name: 'Level of Care',
        //   Component: props => <LevelOfCare name="financials" {...props} />,
        // },
        // {
        //   Name: 'Contact Information',
        //   Component: props => <ContactInformation name="supportContacts" {...props} />,
        // },
        // {
        //   Name: 'Demographic',
        //   Component: props => <Demographic {...props} />,
        // },
        // {
        //   Name: 'Health Insurance',
        //   Component: props => <HealthInsurance name="healthInsurances" {...props} />,
        // },
        // {
        //   Name: 'Addiction Profile',
        //   Component: props => <AddictionProfile name="substanceUseProfiles" {...props} />,
        // },
        // {
        //   Name: 'Previous Treatments',
        //   Component: props => <PreviousTreatments name="previousTreatments" {...props} />,
        // },
        // {
        //   Name: 'Prior Abuse',
        //   Component: props => <PriorAbuse name="priorAbuses" {...props} />,
        // },
        // {
        //   Name: 'Faith Profile',
        //   Component: props => <FaithProfile name="FaithProfile" {...props} />,
        // },
        // {
        //   Name: 'Payment for Care',
        //   Component: props => <PaymentForCare name="fundSources" {...props} />,
        // },
        // {
        //   Name: 'Legal Issues',
        //   Component: props => <LegalIssues name="legalIssues" {...props} />,
        // },
        // {
        //   Name: 'Medical History',
        //   Component: props => <MedicalHistory name="medicalPast" {...props} />,
        // },
        // {
        //   Name: 'Adolescent Info',
        //   Component: props => <AdolescentInfo {...props} />,
        // },
      ],

      activeKey: this.getStudentId() === -1 ? 0 : null,
      activeTabs: [],
      studentData: {
        counselorStaffId: props.defaultCounsellor,
        languageType: props.defaultLanguage,
        faithTypeId: props.defaultFaithType,
        homeAddressType: props.defaultHomeAddressType,
        clientTypeId: props.defaultClientType,
        supportContacts: [{ country: 'USA' }],
        medicalPast: [{}],
        legalIssues: [],
        substanceUseProfiles: [
          { addictionTypeId: props.defaultAddictionProfileTypeId, addictionProfileTypeId: 1 },
        ],
        previousTreatments: [{}],
        fundSources: [{}],
        priorAbuses: [{}],
        financials: [
          {
            isEndDateEstimated: true,
            estimatedCostFrequencyCode: 'D',
            estimatedCost: Number(191.2).toFixed(2),
          },
        ],
        healthInsurances: [{ country: 'USA' }],
        center: props.defaultCenter,
        country: 'USA',
        churchCountry: 'USA',
        enrollDate: props.defaultEnrollDate,
      },
      isModelOpen: false,
      isModelOpen2: false,
      isNewSaveModelOpen: false,
      isDeleteModelOpen: false,
      deleteModelMessage: null,
      yesCallBack: null,
      showError: false,
      modalVisible: false,
      message: null,
    };
  }

  onAddNewApplicant = values => {
    this.props.addNewApplicant({});
  };

  activeTabGet = tabName => {
    const { tabLabels, tabNames, activeTabs } = this.state;
    let tabIndex;
    if (tabNames.includes(tabName)) {
      tabIndex = tabNames.indexOf(tabName);
    } else {
      tabIndex = -1;
    }
    return tabIndex;
  };

  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }

  viewHistory = (key, filterVal, filterText) => {
    // history.push(`/timetravel/?subscriberId=${UserGuid}&TTFilter=25&TTItem=0_Suspicious%Terms`);
    if (this.getIntakeId()) {
      // let keyData = parseInt(key);
      // let clickedTab = this.state.tabMapping[keyData];
      this.props.history.push(
        `/client-timetravel/?studentid=${this.getStudentId()}&TTFilter=${filterVal}&TTItem=${filterText}`,
      );
      // this.setState({
      //   activeKey: parseInt(key),
      // });
    } else {
      return;
    }
  };

  onSubmit(values) {
    // if (this.state.isProcessed) {
    //   return;
    // }
    //if (this.getDischarged()) return;
    // this.state.isProcessed = true;
    const { activeTabs, studentData } = this.state;
    const {
      // start - student information
      firstName,
      middleName,
      lastName,
      nameSuffix,
      namePrefix,
      country,
      homeAddressType,
      churchDenomination,
      line1,
      line2,
      city,
      stateOrProvince,
      counselorStaffId,
      clientTypeId,
      zip,
      ssn,
      dateofBirth,
      enrollDate,
      exitReason,
      exitDate,
      center,
      referenceSourceId,
      primaryCN,
      phaseId,
      emergencyCN,
      email,
      secondaryCN, // not used in screen
      line3, // not used in screen
      // end - student information
      // start - demographic
      maritalStatus,
      ethnicRaceId,
      countryOfBirth,
      gender,
      housingTypeId,
      eyeColorId,
      languageType,
      educationTypeId,
      //birthCountryId,
      //nationalityId,
      workSkillId,
      childrenDependent,
      expectedDischargeDate,
      childrenCareId,
      isEmployed,
      lastEmployedDate,
      employmentStartDate,
      demographicNotes,
      preIntakeClientStatus,
      // end - demographic
      // start - faith profile
      faithTypeId,
      isChurchGoing,
      churchName,
      churchCity,
      churchState,
      churchPhone,
      churchCountry,
      faithNotes,
      // end - faith profile
      // start - care type
      centerServiceTypeCenterId,
      estimatedOrActualEndDate,
      estimatedCost,
      estimatedCostFrequencyCode,
      outcomeField,
      // end - care type
      supportContacts,
      medicalPast,
      healthInsurances,
      substanceUseProfiles,
      previousTreatments,
      fundSources,
      priorAbuses,
      isAbused,
      financials,
      churchDenominationId,
      isBornAgain,
      isSpiritFilled,
      isAdditionTreatBefore,
      isFaithAffiliation,
      isHealthInsurance,
      groupNumber,
      notes,
      isLegallyRequiredTreatment,
      isUnderLegalSupervision,
      legallyRequiredOfficer,
      legallyRequiredOfficerDetails,
      supervisionApproach,
      reportToSupervision,
      probationOfficerName,
      probationOfficerJurisdiction,
      probationOfficerEmail,
      probationOfficerPhone,
      attorneyName,
      attorneyEmail,
      attorneyPhone,
      pendingCases,
      pastArrestandConvictions,
      impContactInfoId,
      superVisionContact,
      legalIssuesNotes,
      hasDriversLicense,
      physicalWithin30Days,
      noIncomeFromVocationalTraining,
      awareNoIncome,
      awareOfPolicy,
      isArrestedForSexualOffense,
      isArrestedForSexualOffenseNote,
      isLegallyRequiredTreatmentNote,
      requiredToRegisterSexualOffender,
      hasAttorneyAssistanceNote,
      hasSafetyConcernsNote,
      hasViolentOffensesConvictionsNote,
      hasArsonConvictionsNote,
      requiredToRegisterSexualOffenderNote,
      hasArsonConvictions,
      hasViolentOffensesConvictions,
      legalObligations,
      hasSafetyConcerns,
      hasAttorneyAssistance,
      attorneyAttorneyLine1,
      attorneyAttorneyLine2,
      attorneyCountry,
      attorneyCity,
      attorneyStateOrProvince,
      attorneyZip,
      AdolescentName,
      ParentMaritalStatusId,
      MiddleSchoolId,
      NaturalHairColorId,
      CurrentHairColorId,
      IdentifyMarkId,
      MarkersDescription,
      AdolescentWeight,
      AdolescentHeight,
      IsSignedRelease,
      IsConvictedCrime,
      IsHelpReceived,
      ForWhatIssue,
      AdolescentParentFaithId,
      ChildFaith,
      SuicidalTendenciesID,
      IdeationNotes,
      NoOfAttemptsMethod,
      OtherIssueId,
      DispositionOfAbuse,
      IsParentProhibitedContact,
      GuardianshipInformation,
      MedicalDecisionMaking,
      AdolescentNotes,
      CurrentGrade,
      IsPreviousSchool,
      SchoolName,
      SchoolAddress,
      SchoolPhoneNumber,
      SchoolAdminName,
      LearningDisabilities,
      IEPor504,
      NotesSection,
      preIntakeNotes,
      lastTreatmentDate,
      treatmentForAddictionNote,
      howManyDay,
      healthInsurancesNotes,
      relationToClient,
      contactFirstName,
      contactLastName,
      contactPrimaryPhone,
      contactEmail,
      contactAddress,
      organizationType,
      organizationName,
      callerName,
      prospectContractId,
      costAndFamilyDiscussed,
      costAndFamilyDiscussedNote,
      ableToCoverIntakeFee,
      ableToCoverIntakeFeeNote,
      supportForMonthlyContributions,
      supportForMonthlyContributionsNote,
    } = values;
    var studentId = null;

    if (this.getStudentId() > 0) {
      studentId = this.getStudentId();
    }

    var dateItem = dateofBirth ? dateofBirth.split('/') : null;
    var dob = dateItem && dateItem[2] + '-' + dateItem[0] + '-' + dateItem[1];
    let studentDemographic = {};
    if (
      eyeColorId ||
      maritalStatus ||
      ethnicRaceId ||
      countryOfBirth ||
      educationTypeId ||
      workSkillId ||
      childrenDependent ||
      expectedDischargeDate ||
      childrenCareId ||
      hasDriversLicense ||
      awareNoIncome ||
      awareOfPolicy ||
      physicalWithin30Days ||
      noIncomeFromVocationalTraining ||
      (languageType && !activeTabs[2])
    ) {
      studentDemographic = {
        MaritalStatus: maritalStatus,
        EthnicRaceId: ethnicRaceId,
        EyeColorId: eyeColorId,
        EducationTypeId: educationTypeId,
        WorkSkillId: workSkillId,
        ChildrenDependent: childrenDependent,
        ExpectedDischargeDate: expectedDischargeDate && stringDate(expectedDischargeDate),
        ChildrenCareId: childrenCareId,
        LanguageISO: languageType,
        DemographicNotes: demographicNotes,
        HasDriversLicense: hasDriversLicense,
        AwareNoIncome: awareNoIncome,
        AwareOfPolicy: awareOfPolicy,
        CountryOfBirth: countryOfBirth,
        PhysicalWithin30Days: physicalWithin30Days,
        NoIncomeFromVocationalTraining: noIncomeFromVocationalTraining,
      };
    }

    const generateFinancials = financials => {
      let mappedArray = financials.map(item => {
        if (item.centerServiceTypeCenterId) {
          return {
            StudentFinancialId: item.studentFinancialId,
            CenterServiceTypeCenterId: item.centerServiceTypeCenterId,
            EstimatedCost: item.estimatedCost,
            EstimatedCostFrequencyCode:
              item.estimatedCostFrequencyCode != 0 ? item.estimatedCostFrequencyCode : null,

            Notes: item.notes,
            IsEndDateEstimated: item.isEndDateEstimated,
            StartDate: stringDate(item.startDate),
            EndDate: stringDate(item.endDate),
            OutcomeField: item.outcomeField,
            PhaseId: item.phaseId,
          };
        }
      });
      let filteredArray = mappedArray.filter(item => {
        if (typeof item == 'object') {
          return item;
        }
      });
      return filteredArray;
    };
    var data = {
      Student: [
        {
          StudentId: studentId,
          CenterId: center,
          CounselorStaffId: counselorStaffId,
          FirstName: firstName,
          MiddleName: middleName,
          LastName: lastName,
          DateofBirth: dob,
          AddressLine1: line1,
          AddressLine2: line2,
          AddressLine3: line3,
          City: city,
          Notes: notes,
          StateOrProvince: stateOrProvince,
          ZipCode: zip,
          CountryISO: country || 'USA',
          EmergencyPhone: emergencyCN && emergencyCN.replace(/\D/g, ''),
          PrimaryPhone: getPhoneNumber(primaryCN),
          PrimaryPhoneCountryCode: getCountryCalling(primaryCN),
          SecondaryPhone: secondaryCN,
          // PhaseId: phaseId,
          PreIntakeNotes: preIntakeNotes,
          Email: email,
          FamilyContactName: studentData.FamilyContactName || null,
          Gender: gender,
          HeightInches: studentData.HeightInches || null,
          Weight: studentData.Weight || null,
          WeightUnitId: studentData.WeightUnitId || null,
          EnrollDate: enrollDate ? moment(enrollDate).format('YYYY-MM-DD') : null,
          ExitDate: exitDate ? moment(exitDate).format('YYYY-MM-DD') : null,
          ExitReasonId: exitReason || null,
          FamilySupport: studentData.FamilySupport || null,
          AddictSince: studentData.AddictSince || null,
          AddictionSeverity: studentData.AddictionSeverity || null,
          PriorRecoveryAttemptDate: studentData.PriorRecoveryAttemptDate || null,
          EnrollStatusId: studentData.EnrollStatusId || null,
          ProfileImgAttachmentId: studentData.ProfileImgAttachmentId || null,

          NameSuffix: nameSuffix,
          NamePrefix: namePrefix,
          HomeAddressType: homeAddressType,
          IsFaithAffiliation: isFaithAffiliation,
          IsHealthInsurance: isHealthInsurance,
          HealthInsurancesNotes: healthInsurancesNotes,
          IsLegallyRequiredTreatment: isLegallyRequiredTreatment,
          IsUnderLegalSupervision: isUnderLegalSupervision,
          GroupNumber: groupNumber,
          PreIntakeClientStatus: preIntakeClientStatus,
          ...studentDemographic,
          StudentMore: [
            {
              ReferenceSourceId: referenceSourceId,
              IsEmployed: isEmployed,
              LastEmployedDate: isEmployed != 1 ? lastEmployedDate : null,
              EmploymentStartDate: isEmployed == 1 ? employmentStartDate : null,
              FaithTypeId: faithTypeId,
              GroupNumber: groupNumber,
              FaithNotes: faithNotes,
              IsChurchGoing: faithTypeId == 1 ? isChurchGoing : null,
              ChurchName: faithTypeId == 1 ? (isChurchGoing == 1 ? churchName : null) : null,
              ChurchCity: faithTypeId == 1 ? (isChurchGoing == 1 ? churchCity : null) : null,
              ChurchPhone: faithTypeId == 1 ? (isChurchGoing == 1 ? churchPhone : null) : null,
              ChurchState: faithTypeId == 1 ? (isChurchGoing == 1 ? churchState : null) : null,
              ChurchCountry: faithTypeId == 1 ? (isChurchGoing == 1 ? churchCountry : null) : null,
              ChurchDenominationId: faithTypeId == 1 ? churchDenominationId : null,
              IsBornAgain: faithTypeId == 1 ? isBornAgain : null,
              IsSpiritFilled: faithTypeId == 1 ? isSpiritFilled : null,
              ExitReasonNotes: null,
              HousingTypeId: housingTypeId,
              Notes: demographicNotes,
              ClientTypeId: clientTypeId,
            },
          ],

          ProspectContact: [
            {
              ContactId: prospectContractId || null,
              Contact: [
                {
                  ContactFirstName: contactFirstName,
                  ContactLastName: contactLastName,
                  ContactAddressLine1: contactAddress,
                  ContactPrimaryPhone: contactPrimaryPhone,
                  ContactEmail: contactEmail,
                  StudentRelationShipId: relationToClient,
                  StudentOrganizationType: organizationType,
                  OrganizationName: organizationName,
                  CallerName: callerName,
                },
              ],
            },
          ],
          NationalId: ssn ? ssn.replace('-', '').replace('-', '') : null,
          BirthCountryISO: 'USA',
          NationalityCountryISO: 'USA',

          StudentFundSource: !activeTabs[this.activeTabGet('fundSources')]
            ? null
            : fundSources.map(item => {
                return {
                  StudentFundSourceId: item.studentFundSourceId,
                  ResponsibilityTypeId: item.responsibilityTypeId,
                  ResponsibilityTypeOther:
                    item.responsibilityTypeId == 7 ? item.responsibilityTypeOther : null,
                  ContributionAmt: item.contributionAmt,
                  ContributionFrequencyCode: item.contributionFrequencyCode,
                  CostAndFamilyDiscussed: item.costAndFamilyDiscussed,
                  AbleToCoverIntakeFee: item.ableToCoverIntakeFee,
                  SupportForMonthlyContributions: item.supportForMonthlyContributions,
                  SupportForMonthlyContributionsNote: item.supportForMonthlyContributionsNote,
                  AbleToCoverIntakeFeeNote: item.ableToCoverIntakeFeeNote,
                  CostAndFamilyDiscussedNote: item.costAndFamilyDiscussedNote,
                  Notes: item.notes,
                };
              }),
          FinancialSupportData: !activeTabs[this.activeTabGet('financialsupport')]
            ? null
            : [
                {
                  StudentFundSourceId: values.StudentFundSourceId || null,
                  CostAndFamilyDiscussed: costAndFamilyDiscussed,
                  AbleToCoverIntakeFee: ableToCoverIntakeFee,
                  SupportForMonthlyContributions: supportForMonthlyContributions,
                  SupportForMonthlyContributionsNote: supportForMonthlyContributionsNote,
                  AbleToCoverIntakeFeeNote: ableToCoverIntakeFeeNote,
                  CostAndFamilyDiscussedNote: costAndFamilyDiscussedNote,
                  Notes: null,
                },
              ],
          // StudentInsurance: [
          //   {
          //     StudentInsuranceId: studentHealthInsurance.studentInsuranceId,
          //     InsuranceCompany: studentHealthInsurance.insuranceCompany,
          //     PolicyNumber: studentHealthInsurance.policyNumber,
          //     GroupNumber: studentHealthInsurance.groupNumber,
          //   },
          // ] isUnderLegalSupervision
          // ,
          StudentLegalIssues: !activeTabs[this.activeTabGet('legalIssues')]
            ? null
            : [
                {
                  IsLegallyRequiredTreatment: isLegallyRequiredTreatment || false,
                  IsUnderLegalSupervision: isUnderLegalSupervision || false,
                  LegallyRequiredOfficer: legallyRequiredOfficer,
                  LegallyRequiredOfficerDetails: legallyRequiredOfficerDetails,
                  SupervisionApproach: supervisionApproach,
                  ReportToSupervision: reportToSupervision,
                  ProbationOfficerName: probationOfficerName,
                  ProbationOfficerJurisdiction: probationOfficerJurisdiction,
                  ProbationOfficerEmail: probationOfficerEmail,
                  ProbationOfficerPhone:
                    probationOfficerPhone && probationOfficerPhone.replace(/\D/g, ''),
                  AttorneyName: attorneyName,
                  AttorneyEmail: attorneyEmail,
                  AttorneyAddressLine1: attorneyAttorneyLine1,
                  AttorneyAddressLine2: attorneyAttorneyLine2,
                  AttorneyCity: attorneyCity,
                  AttorneyStateOrProvince: attorneyStateOrProvince,
                  AttorneyZipCode: attorneyZip,
                  AttorneyCountryISO: attorneyCountry,
                  AttorneyPhone: attorneyPhone && attorneyPhone.replace(/\D/g, ''),
                  PendingCases:
                    typeof pendingCases == 'object'
                      ? pendingCases.map(item => item.Value).toString()
                      : pendingCases || '',
                  PastArrestandConvictions: pastArrestandConvictions,
                  ImpContactInfoId: impContactInfoId,
                  SuperVisionContactAddress: superVisionContact,
                  IsArrestedForSexualOffense: isArrestedForSexualOffense,
                  IsArrestedForSexualOffenseNote: isArrestedForSexualOffenseNote,
                  IsLegallyRequiredTreatmentNote: isLegallyRequiredTreatmentNote,
                  RequiredToRegisterSexualOffender: requiredToRegisterSexualOffender,
                  HasArsonConvictions: hasArsonConvictions,
                  HasViolentOffensesConvictions: hasViolentOffensesConvictions,
                  LegalObligations: legalObligations,
                  HasSafetyConcerns: hasSafetyConcerns,
                  HasAttorneyAssistance: hasAttorneyAssistance,
                  RequiredToRegisterSexualOffenderNote: requiredToRegisterSexualOffenderNote,
                  HasArsonConvictionsNote: hasArsonConvictionsNote,
                  HasViolentOffensesConvictionsNote: hasViolentOffensesConvictionsNote,
                  HasSafetyConcernsNote: hasSafetyConcernsNote,
                  HasAttorneyAssistanceNote: hasAttorneyAssistanceNote,
                  Notes: legalIssuesNotes,
                },
              ],

          StudentMedicalPast: !activeTabs[this.activeTabGet('medicalPast')]
            ? null
            : medicalPast.map(item => {
                return {
                  // Medical: [
                  //   {
                  Relation: item.relation,
                  FamilyMedicalPast: item.familyMedicalPast,
                  DevlopmentalIssuesPast: item.devlopmentalIssuesPast,
                  CurrentMedications: item.currentMedications,
                  AllergiesMedicines: item.allergiesMedicines,
                  AllergiesFood: item.allergiesFood,
                  AllergiesOthers: item.allergiesOthers,
                  CurrentPhysicanName: item.currentPhysicanName,
                  CurrentPhysicanPhone: item.currentPhysicanPhone,
                  CurrentPhysicanAddress: item.currentPhysicanAddress,
                  IsTobaccoUser: item.isTobaccoUser,
                  NeedTobaccoDetox: item.needTobaccoDetox,
                  NeedAccommodation: item.needAccommodation,
                  AccommodationNote: item.accommodationNote,
                  IsUnderMedication: item.isUnderMedication,
                  MedicationList: item.medicationList,
                  HasAllergies: item.hasAllergies,
                  HasMentalHealthDiagnosis: item.hasMentalHealthDiagnosis,
                  DiagnosisNote: item.diagnosisNote,
                  // PhysicalWithin30Days: item.physicalWithin30Days,
                  // NoIncomeFromVocationalTraining: item.noIncomeFromVocationalTraining,
                  IsPreviouslyEnrolled: item.isPreviouslyEnrolled,
                  HasAllergiesNote: item.hasAllergiesNote,
                  IsPreviouslyEnrolledNote: item.isPreviouslyEnrolledNote,
                  TreatmentForAddiction: item.treatmentForAddiction,
                  HasSuicidalThoughts: item.hasSuicidalThoughts,
                  ConditionofTeeth: item.conditionofTeeth,
                  Notes: item.notes,
                  //   },
                  // ],
                };
              }),
          StudentAdolescent: [
            {
              AdolescentName,
              ParentMaritalStatusId,
              MiddleSchoolId,
              NaturalHairColorId,
              CurrentHairColorId,
              IdentifyMarkId,
              MarkersDescription,
              AdolescentWeight,
              AdolescentHeight,
              IsSignedRelease,
              IsConvictedCrime,
              IsHelpReceived,
              ForWhatIssue,
              AdolescentParentFaithId,
              ChildFaith,
              SuicidalTendenciesID,
              IdeationNotes,
              NoOfAttemptsMethod,
              OtherIssueId:
                typeof OtherIssueId == 'object'
                  ? OtherIssueId.map(item => item.Value).toString()
                  : OtherIssueId || '',
              DispositionOfAbuse,
              IsParentProhibitedContact,
              GuardianshipInformation,
              MedicalDecisionMaking,
              AdolescentNotes,
              CurrentGrade,
              IsPreviousSchool,
              SchoolName,
              SchoolAddress,
              SchoolPhoneNumber,
              SchoolAdminName,
              LearningDisabilities,
              IEPor504,
              NotesSection,
            },
          ],
          StudentInsurance: !activeTabs[this.activeTabGet('healthInsurances')]
            ? null
            : isHealthInsurance != 1
            ? null
            : healthInsurances.map(item => {
                return {
                  StudentInsuranceId: item.studentInsuranceId,
                  InsuranceCompany: item.insuranceCompany,
                  PolicyNumber: item.policyNumber,
                  GroupNumber: item.groupNumber,
                  HealthInsuranceTypeId: item.healthInsuranceTypeId,
                  Notes: item.notes,
                  AddressLine1: item.line1,
                  City: item.city,
                  StateOrProvince: item.stateOrProvince,
                  ZipCode: item.zip,
                  CountryISO: item.country,
                  Phone: item.phone && item.phone.replace(/\D/g, ''),
                  Fax: item.fax,
                  Email: item.email,
                };
              }),
          HealthInsurancesNotes: healthInsurancesNotes,
          HowManyDay: howManyDay,
          LastTreatmentDate: stringDate(lastTreatmentDate),
          TreatmentForAddictionNote: treatmentForAddictionNote,
          TreatmentForAddiction: isAdditionTreatBefore,
          StudentPreviousTreatment: !activeTabs[this.activeTabGet('previousTreatments')]
            ? null
            : isAdditionTreatBefore != 1
            ? null
            : previousTreatments.map(item => {
                return {
                  StudentPreviousTreatmentId: item.studentPreviousTreatmentId,
                  StartDate: stringDate(item.startDate),
                  EndDate: stringDate(item.endDate),
                  Notes: item.notes,
                  CenterName: item.centerName,
                  ProgramTypeId: item.programTypeId,
                };
              }),
          StudentPriorAbuse: !activeTabs[this.activeTabGet('priorAbuses')]
            ? null
            : isAbused != 1
            ? null
            : priorAbuses.map(item => {
                return {
                  StudentPriorAbuseId: item.studentPriorAbuseId,
                  PriorAbuseTypeId: item.priorAbuseTypeId,
                  PriorAbuseTypeOther: item.priorAbuseTypeId == 5 ? item.priorAbuseTypeOther : null,
                  AgeWhenAbused: item.ageWhenAbused,
                  AbusedByRelationshipId: item.abusedByRelationshipId,
                  AbusedByRelationshipOther:
                    item.abusedByRelationshipId == 10 ? item.abusedByRelationshipOther : null,
                  AbuserAge: item.abuserAge,
                  Notes: item.notes,
                  AbuserGender: item.abuserGender,
                  HowOften: item.howOften == 1 ? 1 : item.howOftenActual,
                  AbuseFrequencyCode: item.howOften == 1 ? null : item.abuseFrequencyCode,
                };
              }),
          StudentFinancial: !activeTabs[this.activeTabGet('financials')]
            ? null
            : generateFinancials(financials).length > 0
            ? generateFinancials(financials)
            : null,
          StudentServiceType: [
            {
              CenterServiceTypeCenterId: centerServiceTypeCenterId,
              EstimatedOrActualEndDate: estimatedOrActualEndDate,
              StartDate: '2019-01-01T00:00:00',
              EndDate: null,
            },
          ],
          // SUBSTANCE USE PROFILE
          StudentAddictionType: !activeTabs[this.activeTabGet('substanceUseProfiles')]
            ? null
            : this.constructStudentAddictionType(substanceUseProfiles),
          StudentContact: !activeTabs[this.activeTabGet('supportContacts')]
            ? null
            : supportContacts.map(item => {
                return {
                  ContactId: item.contactId || null,
                  Contact: [
                    {
                      FirstName: item.firstName,
                      MiddleName: item.middleName,
                      LastName: item.lastName,
                      AddressLine1: item.line1,
                      AddressLine2: item.line2,
                      AddressLine3: item.line3,
                      City: item.city,
                      Notes: item.notes,
                      StateOrProvince: item.stateOrProvince,
                      ZipCode: item.zip,
                      CountryISO: item.country || 'USA',
                      EmergencyPhone: item.emergencyCN,
                      PrimaryPhone: item.primaryCN,
                      SecondaryPhone: item.secondaryCN,
                      Email: item.email,
                      RelationShipId: item.relationshipId,
                      IsEmergencyContact: item.isEmergencyContact,
                    },
                  ],
                };
              }),
          StudentMentalHealthDisorder: [
            {
              MentalHealthDisorderId: 1,
              StartDate: '2019-01-01T00:00:00',
            },
            {
              MentalHealthDisorderId: 2,
              StartDate: '2019-01-01T00:00:00',
            },
          ],
        },
      ],
    };
    var studentInfo = {
      userId: 1,
      centerId: center,
      json: JSON.stringify(data),
    };
    if (this.getStudentId() > 0) {
      studentInfo.studentId = this.getStudentId();
    }
    this.props.addStudent(studentInfo);
  }

  constructStudentAddictionType = substanceUseProfiles => {
    var returnItem = [];
    for (var item of substanceUseProfiles) {
      if (item.addictionProfileTypeId) {
        if (item.addictionProfileTypeId == 1) {
          if (!item.ageOfFirstUse) {
            continue;
          }
        }
        returnItem.push({
          StudentAddictionTypeId: item.studentAddictionTypeId || null,
          AddictionProfileTypeId: item.addictionProfileTypeId,
          AddictionProfileTypeOther:
            item.addictionProfileTypeId == 5 ? item.addictionProfileTypeOther : null,
          // AddictionFrequency: item.addictionProfileTypeId != 1 ? item.addictionFrequency : null,
          AddictionTypeId: item.addictionTypeId,
          DrugRouteOfAdminId:
            typeof item.drugRouteOfAdminId == 'object'
              ? item.drugRouteOfAdminId.map(item => item.Value).toString()
              : item.drugRouteOfAdminId || '',
          ReportedDate: null, // To Do: Need to Add
          StartDate: stringDate(item.startDate),
          AgeOfFirstUse: item.ageOfFirstUse,
          EndDate: stringDate(item.endDate),
          Notes: item.notes,
          FrequencyOfUseId: item.frequencyOfUseId,
          IsDrugOfChoice: item.isDrugOfChoice,
          LastInjected: item.lastInjection,
        });
      } else {
        returnItem.push({
          StudentAddictionTypeId: item.studentAddictionTypeId || null,
          AddictionProfileTypeId: item.addictionProfileTypeId,
          AddictionProfileTypeOther:
            item.addictionProfileTypeId == 5 ? item.addictionProfileTypeOther : null,
          FrequencyOfUseId: item.frequencyOfUseId,
          // AddictionFrequency: item.addictionProfileTypeId != 1 ? item.addictionFrequency : null,
          StartDate: stringDate(item.startDate),
          AgeOfFirstUse: item.ageOfFirstUse,
          EndDate: stringDate(item.endDate),
          LastInjected: item.lastInjection,
        });
      }
    }
    return returnItem;
  };

  handleNext = index => {
    let isDisableSave = this.isFnDisableSave(index, 'labOrder');
    const { activeTabs } = this.state;
    activeTabs[index] = true;
    activeTabs[index + 1] = true;
    this.setState({
      activeKey: `${index + 1}`,
      activeTabs,
      isDisableSave,
    });
  };

  handleSubmitAndNext = (data, currentTab, tabsCount) => {
    if (currentTab + 1 != tabsCount) {
      this.handleNext(currentTab);
    } else {
      this.onSubmit(data);
    }
  };
  isFnDisableSave = (key, tab) => {
    const { activeTabs, tabNames } = this.state;
    let disableSave = tabNames[key] == tab;
    return disableSave;
  };
  goToPreviuos = index => {
    /*window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });*/
    let isDisableSave = this.isFnDisableSave(index, 'labOrder');
    this.setState({ activeKey: index - 1, isDisableSave });
  };

  onTabClick = key => {
    const { activeTabs, tabNames } = this.state;
    let isDisableSave = this.isFnDisableSave(key, 'labOrder');
    if (activeTabs[key]) {
      this.setState({ activeKey: key, isDisableSave });
    }
    /*if (this.getDischarged()) {
      this.hideButton();
    } else {
    }*/
  };

  getDischarged = () => {
    if (this.props.isView || this.props.isReadOnlyUser) {
      return true;
    }
  };

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('studentId') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }

  hideButton = () => {
    //for (let item of document.getElementsByClassName('btn')) item.disabled = true;
  };

  componentDidMount(props) {
    var comboItems =
      'PreIntakeClientStatus,ClientType,Filter_StateProvince,RelationShip,ClientOrganizatonType,StudentPartnerOrganizationName,CenterProgramTypeAll,OutcomeField,FrequencyOfUse,HealthInsuranceType,PriorAbuseType,StudentChildrenCare,CenterProgramType,Country,StateProvince,CenterServiceTypeCenter,FinancialResponsibilityType,PriorAbuseRelationship,FaithType,ReferenceSource,Frequency,MaritalStatus,EthnicRace,Gender,HousingType,EyeColor,Language,EducationType,WorkSkill,SurveyQuestionCategory,SurveyQuestionType,AddictionType,AddictionProfileType,DrugRouteOfAdmin,NameSuffix,NamePrefix,HomeAddressType,ChurchDenomination,Center,Counsellor,AbuseFrequency,HairColor,IdentifyMark,SuicidalTendencies,OtherIssue,Phase,ImpContactInfo,ClientLegalDays,ClientLegalSupervisionType,ClientLegalPending,ClientMedicalHistory,ClientLegalAllergies,ClientLegalTreatment,ClientLegalSpecialtiesOptions,ClientLegalWeekDay,HowManyDay,ExitReason';

    COMBOS_LOADED['Center'] = { isLoaded: false };
    COMBOS_LOADED['StateProvince'] = { isLoaded: false };
    this.props.dispatch(
      studentStaffAccessedTabGet({
        JsonInput: JSON.stringify({
          Student: [
            {
              StudentId: this.getStudentId(),
            },
          ],
        }),
      }),
    );
    var combos = [];
    comboItems.split(',').forEach(item => {
      if (COMBOS_LOADED[item]) {
        if (COMBOS_LOADED[item].alwaysLoaded) {
          combos.push(item);
        } else {
          if (!COMBOS_LOADED[item].isLoaded) {
            combos.push(item);
          }
        }
      } else {
        combos.push(item);
      }
    });
    if (combos.length > 0) {
      this.props.listSmartCombos({
        comboTypes: combos.join(','),
      });
    }

    let comboTypes = {
      ComboTypes: 'CenterServiceTypeCenter',
      Json: JSON.stringify({
        Combo: [
          {
            StudentId: this.getStudentId(),
            StudentCenterId: null,
          },
        ],
      }),
    };
    this.props.dispatch(
      centerConfigGetById({
        JsonInput: JSON.stringify({
          Center: [
            {
              StudentId: this.getStudentId(),
            },
          ],
        }),
      }),
    );
    if (this.getStudentId() < 0) {
      this.props.getDynamicComos(comboTypes);
    }

    if (this.getStudentId() > 0) {
      this.props.getStudent(this.getStudentId());
    } else {
      /*const initData = {
        supportContacts: [{}],
      };
      this.props.initialize(initData);*/
    }
    /*if (this.getDischarged()) {
      this.hideButton();
    } else {
    }*/

    // if (this.props.isAdolescent && this.getStudentId() < 0) {
    //   let data = this.state.tabLabels;
    //   data.splice(1, 0, {
    //     Name: 'Adolescent Info',
    //     Component: props => <AdolescentInfo {...props} />,
    //   });
    //   this.setState({
    //     tabLabels: data,
    //   });
    // }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  toggle2 = header => {
    this.setState(prevState => ({
      isModelOpen2: !prevState.isModelOpen2,
      header,
    }));
  };
  error = msg => {
    Modal.error({
      content: msg,
    });
  };
  deleteModelToggle = () => {
    this.state.yesCallBack();
    this.setState({ isDeleteModelOpen: !this.state.isDeleteModelOpen });
  };

  updateDeleteModel = (isDeleteModelOpen, deleteModelMessage, yesCallBack) => {
    this.setState({
      isDeleteModelOpen: isDeleteModelOpen,
      deleteModelMessage: deleteModelMessage,
      yesCallBack: yesCallBack,
    });
  };

  launchSurvey = () => {
    this.props.history.push('/surveyer');
  };

  componentWillUnmount() {
    this.props.resetGetStudent();
    this.props.resetAddRequest();
    this.props.reset();
  }

  componentWillReceiveProps({
    isInserted,
    errorMessage,
    studentInfo,
    recordInfo,
    defaultCounsellor,
    defaultLanguage,
    defaultFaithType,
    defaultHomeAddressType,
    defaultClientType,
    defaultAddictionProfileTypeId,
    defaultCenter,
    defaultEnrollDate,
    rcmMergeSuccess,
    studentMatchedWithClient,
    studentTabs,
  }) {
    if (rcmMergeSuccess && rcmMergeSuccess != this.props.rcmMergeSuccess) {
      this.props.getStudent(this.getStudentId());
      this.toggle2('Client copied to clinical workflow');
    }
    if (studentTabs && studentTabs != this.props.studentTabs) {
      let tabs = [];
      let activeTabsArray = [];
      let tabNamesArray = [];
      studentTabs &&
        studentTabs.map((item, index) => {
          const { Text, Component, Name } = item;
          const SelectedComponent = Components[Component];
          if (SelectedComponent) {
            tabs.push({
              Name: Text,
              Component: props => <SelectedComponent name={Name} {...props} />,
            });
            tabNamesArray.push(Name);
            if (this.getStudentId() > 0) {
              activeTabsArray.push(true);
            } else {
              activeTabsArray.push(false);
            }
          }
        });
      this.setState({
        tabLabels: tabs,
        activeTabs: activeTabsArray,
        tabNames: tabNamesArray,
      });
    }
    if (isInserted) {
      this.props.resetAddRequest();
      if (
        this.getStudentId() > 0 &&
        !(recordInfo.oldEnrollStatusId == 0 && recordInfo.enrollStatusId == 1)
      ) {
        this.toggle();
      } else {
        const me = this;
        this.setState(
          { modalMessage: 'Enrollment was successfully saved.', isNewSaveModelOpen: true },
          () => {
            setTimeout(() => {
              me.setState({ modalMessage: '', isNewSaveModelOpen: false }, () => {
                var searchStr =
                  'surveyId=' + recordInfo.surveyId + '&studentId=' + recordInfo.studentId;
                const isWebCam = localStorage.getItem('isWebCam') === '0' ? false : true;
                if (recordInfo.enrollStatusId == 1) {
                  if (isWebCam) {
                    me.props.history.push({
                      pathname: '/camera',
                      search: searchStr,
                    });
                  } else {
                    me.props.history.push({
                      pathname: '/pin',
                      search: searchStr,
                    });
                  }
                } else this.props.history.push('/clients/list');
              });
            }, 1000);
          },
        );
      }
    }

    if (errorMessage) {
      var me = this;
      this.setState({ isModelOpen: true, errorMessage: errorMessage }, () => {
        me.state.isModelOpen = false;
        me.state.errorMessage = '';
      });
    }
    if (studentInfo && this.state.studentData.firstName != studentInfo.FirstName)
      if (studentInfo && studentInfo.StudentId) {
        this.props.getClientCounselor(studentInfo.CenterId);
        this.props.getDynamicComos({
          ComboTypes: 'CenterServiceTypeCenter',
          Json: JSON.stringify({
            Combo: [
              {
                StudentId: this.getStudentId(),
                StudentCenterId: studentInfo.CenterId,
              },
            ],
          }),
        });
        const { activeTabs } = this.state;
        const data = {
          firstName: studentInfo.FirstName,
          middleName: studentInfo.MiddleName,
          lastName: studentInfo.LastName,
          nameSuffix: studentInfo.NameSuffix,
          namePrefix: studentInfo.NamePrefix,
          homeAddressType: studentInfo.HomeAddressType,
          churchDenomination: studentInfo.ChurchDenomination,
          line1: studentInfo.AddressLine1,
          line2: studentInfo.AddressLine2,
          line3: studentInfo.AddressLine3,
          city: studentInfo.City,
          stateOrProvince: studentInfo.StateOrProvince,
          country: studentInfo.CountryISO,
          counselorStaffId: studentInfo.CounselorStaffId,
          zip: studentInfo.ZipCode,
          ssn: studentInfo.NationalId,
          preIntakeNotes: studentInfo.PreIntakeNotes,
          // dateofBirth: formatDOB(studentInfo.DateofBirth),
          dateofBirth: studentInfo.DateofBirth ? moment(studentInfo.DateofBirth).format('L') : null,
          phaseId: studentInfo.PhaseId,
          enrollDate: moment(studentInfo.EnrollDate).format('YYYY-MM-DD'),
          exitDate: moment(studentInfo.ExitDate).format('YYYY-MM-DD'),
          exitReason: studentInfo.ExitReasonId,
          center: studentInfo.CenterId,
          emergencyCN: studentInfo.EmergencyPhone,
          primaryCN: `+${studentInfo.PrimaryPhoneCountryCode}${studentInfo.PrimaryPhone}`,
          secondaryCN: studentInfo.SecondaryPhone,
          email: studentInfo.Email,
          maritalStatus: studentInfo.MaritalStatus,
          ethnicRaceId: studentInfo.EthnicRaceId,
          countryOfBirth: studentInfo.CountryOfBirth,
          gender: studentInfo.Gender,
          eyeColorId: studentInfo.EyeColorId,
          languageType: studentInfo.LanguageISO,
          educationTypeId: studentInfo.EducationTypeId,
          //birthCountryId: studentInfo.BirthCountryISO,
          //nationalityId: studentInfo.NationalityCountryISO,
          workSkillId: studentInfo.WorkSkillId,
          childrenDependent: studentInfo.ChildrenDependent,
          expectedDischargeDate: studentInfo.ExpectedDischargeDate,
          childrenCareId: studentInfo.ChildrenCareId,
          preIntakeClientStatus: studentInfo.PreIntakeClientStatus,
          isEmployed: studentInfo.IsEmployed,
          lastEmployedDate: studentInfo.LastEmployedDate,
          faithTypeId: studentInfo.FaithTypeId,
          hasDriversLicense: studentInfo.HasDriversLicense,
          physicalWithin30Days: studentInfo.PhysicalWithin30Days,
          noIncomeFromVocationalTraining: studentInfo.NoIncomeFromVocationalTraining,
          awareNoIncome: studentInfo.AwareNoIncome,
          awareOfPolicy: studentInfo.AwareOfPolicy,
          FamilyContactName: studentInfo.FamilyContactName,
          HeightInches: studentInfo.HeightInches,
          Weight: studentInfo.Weight,
          WeightUnitId: studentInfo.WeightUnitId,
          // ExitDate: studentInfo.ExitDate,
          // ExitReasonId: studentInfo.ExitReasonId,
          FamilySupport: studentInfo.FamilySupport,
          AddictSince: studentInfo.AddictSince,
          AddictionSeverity: studentInfo.AddictionSeverity,
          PriorRecoveryAttemptDate: studentInfo.PriorRecoveryAttemptDate,
          EnrollStatusId: studentInfo.EnrollStatusId,
          ProfileImgAttachmentId: studentInfo.ProfileImgAttachmentId,
          healthInsurancesNotes: studentInfo.HealthInsurancesNotes,
          /*isChurchGoing: studentInfo.IsChurchGoing,
        churchName: studentInfo.ChurchName,
        churchCity: studentInfo.ChurchCity,
        churchState: studentInfo.ChurchState,*/
        };
        if (studentInfo.ProspectContact && studentInfo.ProspectContact[0]) {
          const { Contact } = studentInfo.ProspectContact[0];
          data.prospectContractId = studentInfo.ProspectContact[0].ContactId || null;
          if (Contact && Contact[0]) {
            data.relationToClient = Contact[0].StudentRelationShipId;
            data.contactFirstName = Contact[0].ContactFirstName;
            data.contactLastName = Contact[0].ContactLastName;
            data.contactPrimaryPhone = Contact[0].ContactPrimaryPhone;
            data.contactEmail = Contact[0].ContactEmail;
            data.contactAddress = Contact[0].ContactAddressLine1;
            data.organizationType = Contact[0].StudentOrganizationType;
            data.organizationName = Contact[0].OrganizationName;
            data.callerName = Contact[0].CallerName;
            // data.prospectContractId = studentInfo.ProspectContact[0].ContactId || null;
          }
        }
        if (
          studentInfo.IsAdolescent &&
          studentInfo.StudentAdolescent &&
          studentInfo.StudentAdolescent[0]
        ) {
          data.groupNumber = studentInfo.StudentAdolescent[0].GroupNumber;
          data.AdolescentName = studentInfo.StudentAdolescent[0].AdolescentName;
          data.ParentMaritalStatusId = studentInfo.StudentAdolescent[0].ParentMaritalStatusId;
          data.MiddleSchoolId = studentInfo.StudentAdolescent[0].MiddleSchoolId;
          data.NaturalHairColorId = studentInfo.StudentAdolescent[0].NaturalHairColorId;
          data.CurrentHairColorId = studentInfo.StudentAdolescent[0].CurrentHairColorId;
          data.IdentifyMarkId = studentInfo.StudentAdolescent[0].IdentifyMarkId;
          data.MarkersDescription = studentInfo.StudentAdolescent[0].MarkersDescription;
          data.AdolescentWeight = studentInfo.StudentAdolescent[0].AdolescentWeight;
          data.AdolescentHeight = studentInfo.StudentAdolescent[0].AdolescentHeight;
          data.IsSignedRelease = studentInfo.StudentAdolescent[0].IsSignedRelease;
          data.IsConvictedCrime = studentInfo.StudentAdolescent[0].IsConvictedCrime;
          data.IsHelpReceived = studentInfo.StudentAdolescent[0].IsHelpReceived;
          data.ForWhatIssue = studentInfo.StudentAdolescent[0].ForWhatIssue;
          data.AdolescentParentFaithId = studentInfo.StudentAdolescent[0].AdolescentParentFaithId;
          data.ChildFaith = studentInfo.StudentAdolescent[0].ChildFaith;
          data.SuicidalTendenciesID = studentInfo.StudentAdolescent[0].SuicidalTendenciesID;
          data.IdeationNotes = studentInfo.StudentAdolescent[0].IdeationNotes;
          data.NoOfAttemptsMethod = studentInfo.StudentAdolescent[0].NoOfAttemptsMethod;
          data.OtherIssueId = '' + studentInfo.StudentAdolescent[0].OtherIssueId + '';
          data.DispositionOfAbuse = studentInfo.StudentAdolescent[0].DispositionOfAbuse;
          data.IsParentProhibitedContact =
            studentInfo.StudentAdolescent[0].IsParentProhibitedContact;
          data.IsAdolescent = studentInfo.StudentAdolescent[0].IsAdolescent;
          data.GuardianshipInformation = studentInfo.StudentAdolescent[0].GuardianshipInformation;
          data.MedicalDecisionMaking = studentInfo.StudentAdolescent[0].MedicalDecisionMaking;
          data.AdolescentNotes = studentInfo.StudentAdolescent[0].AdolescentNotes;
          data.CurrentGrade = studentInfo.StudentAdolescent[0].CurrentGrade;
          data.IsPreviousSchool = studentInfo.StudentAdolescent[0].IsPreviousSchool;
          data.SchoolName = studentInfo.StudentAdolescent[0].SchoolName;
          data.SchoolAddress = studentInfo.StudentAdolescent[0].SchoolAddress;
          data.SchoolPhoneNumber = studentInfo.StudentAdolescent[0].SchoolPhoneNumber;
          data.SchoolAdminName = studentInfo.StudentAdolescent[0].SchoolAdminName;
          data.LearningDisabilities = studentInfo.StudentAdolescent[0].LearningDisabilities;
          data.IEPor504 = studentInfo.StudentAdolescent[0].IEPor504;
          data.NotesSection = studentInfo.StudentAdolescent[0].NotesSection;
        }
        // For Demographic
        // For UseProfile

        // For Student Information
        if (studentInfo.StudentServiceType && studentInfo.StudentServiceType.length > 0) {
          data.centerServiceTypeCenterId =
            studentInfo.StudentServiceType[0].CenterServiceTypeCenterId;
        }
        data.substanceUseProfiles = this.mapStudentAddictionToState(
          studentInfo.StudentAddictionType,
        );
        // For PreviousTreatment
        data.previousTreatments = this.mapStudentPreviousTreatmentsToState(
          studentInfo.StudentPreviousTreatment,
        );
        data.howManyDay = studentInfo.HowManyDay;
        data.treatmentForAddictionNote = studentInfo.TreatmentForAddictionNote;
        data.lastTreatmentDate = studentInfo.LastTreatmentDate
          ? new Date(
              `${moment(studentInfo.LastTreatmentDate, 'MM/DD/YYYY').format(
                'YYYY-MM-DD',
              )}T00:00:00`,
            )
          : null;

        data.isAdditionTreatBefore =
          data.previousTreatments.length > 0 ||
          studentInfo.HowManyDay ||
          studentInfo.TreatmentForAddiction;

        // if (data.previousTreatments.length == 0) {
        //   data.previousTreatments.push({});
        // }

        // For PriorAbuse(It is working Correctly)
        data.priorAbuses = this.mapStudentPriorAbusesToState(studentInfo.StudentPriorAbuse);

        data.isAbused = data.priorAbuses.length > 0;

        if (data.priorAbuses.length == 0) {
          data.priorAbuses.push({});
        }

        // For FundSource
        data.fundSources = this.mapStudentFundSourcesToState(studentInfo.StudentFundSource);

        // For Financial
        data.financials = this.mapStudentFinancialsToState(studentInfo.StudentFinancial);
        data.reInstateStudentFinancial = this.mapStudentReInstateFinancialsToState(
          studentInfo.ReInstateStudentFinancial,
        );
        // FinancialSupportData
        if (studentInfo.FinancialSupportData && studentInfo.FinancialSupportData[0]) {
          data.StudentFundSourceId = studentInfo.FinancialSupportData[0].StudentFundSourceId;
          data.ableToCoverIntakeFee = studentInfo.FinancialSupportData[0].AbleToCoverIntakeFee;
          data.supportForMonthlyContributions =
            studentInfo.FinancialSupportData[0].SupportForMonthlyContributions;
          data.costAndFamilyDiscussed = studentInfo.FinancialSupportData[0].CostAndFamilyDiscussed;
          data.costAndFamilyDiscussedNote =
            studentInfo.FinancialSupportData[0].CostAndFamilyDiscussedNote;
          data.ableToCoverIntakeFeeNote =
            studentInfo.FinancialSupportData[0].AbleToCoverIntakeFeeNote;
          data.supportForMonthlyContributionsNote =
            studentInfo.FinancialSupportData[0].SupportForMonthlyContributionsNote;
        }
        // For StudentContact(Support Contact)
        data.supportContacts = this.mapStudentContactToState(studentInfo.StudentContact);

        // For Legal Issue
        // data.legalIssues = this.mapStudentLegalIssuesToState(studentInfo.StudentLegalIssues);

        if (studentInfo.StudentLegalIssues && studentInfo.StudentLegalIssues[0]) {
          this.mapStudentLegalIssuesToState(data, studentInfo.StudentLegalIssues[0]);
        }
        // For Medical Past
        data.medicalPast = this.mapStudentMedicalPastToState(studentInfo.StudentMedicalPast);

        // For HealthInsurance
        data.healthInsurances = this.mapStudentInsuranceToState(studentInfo.StudentInsurance);
        data.isHealthInsurance = data.healthInsurances && data.healthInsurances.length > 0;

        if (data.healthInsurances.length == 0) {
          data.healthInsurances.push({});
        }

        if (studentInfo.StudentMore && studentInfo.StudentMore[0]) {
          this.mapStudentMore(data, studentInfo.StudentMore[0]);
          // activeTabs[6] = true;
        }

        data.studentImageUrl = studentInfo.StudentMisc[0].StudentImageUrl;
        // For FaithProfile
        if (data.faithTypeId) {
          data.isFaithAffiliation = true;
        }
        // if (data.fundSources) activeTabs[this.activeTabGet('fundSources')] = true;
        // if (data.legalIssues) activeTabs[this.activeTabGet('legalIssues')] = true;
        // if (data.medicalPast) activeTabs[this.activeTabGet('medicalPast')] = true;
        // if (data.healthInsurances) activeTabs[this.activeTabGet('healthInsurances')] = true;
        // if (data.previousTreatments) activeTabs[this.activeTabGet('previousTreatments')] = true;
        // if (data.priorAbuses) activeTabs[this.activeTabGet('priorAbuses')] = true;
        // if (data.financials) activeTabs[this.activeTabGet('financials')] = true;
        // if (data.substanceUseProfiles) activeTabs[this.activeTabGet('substanceUseProfiles')] = true;
        // if (data.supportContacts) activeTabs[this.activeTabGet('supportContacts')] = true;
        // if (data.maritalStatus) activeTabs[this.activeTabGet('legalIssues')] = true;
        // if (data.IsAdolescent) activeTabs[1] = true;

        // let tabLabelsData = this.state.tabLabels;
        let tabNamesArray = this.state.tabNames;
        if (data) {
          tabNamesArray &&
            tabNamesArray.map((item, index) => {
              if (data[item]) {
                activeTabs[this.activeTabGet(item)] = true;
              }
            });
          if (data.maritalStatus) {
            activeTabs[this.activeTabGet('Demographic')] = true;
          } else if (data.IsAdolescent) {
            activeTabs[this.activeTabGet('adolescentInfo')] = true;
          }
        }
        // if (studentInfo.IsAdolescent) {
        //   tabLabelsData.splice(1, 0, {
        //     Name: 'Adolescent Info',
        //     Component: props => <AdolescentInfo {...props} />,
        //   });
        // }
        this.setState({
          studentData: data,
          // tabLabels: tabLabelsData,
          activeKey: this.state.activeKey || 0,
        });
      } else {
        this.setState({
          activeKey: this.getStudentId() > -1 ? null : this.state.activeKey || 0,
          studentData: {
            counselorStaffId: defaultCounsellor,
            languageType: defaultLanguage,
            faithTypeId: defaultFaithType,
            homeAddressType: defaultHomeAddressType,
            clientType: defaultClientType,
            supportContacts: [{}],
            medicalPast: [{}],
            legalIssues: [],
            substanceUseProfiles: [
              { addictionTypeId: defaultAddictionProfileTypeId, addictionProfileTypeId: 1 },
            ],
            previousTreatments: [{}],
            fundSources: [{}],
            priorAbuses: [{}],
            financials: [{ isEndDateEstimated: true }],
            healthInsurances: [],
            center: defaultCenter,
            enrollDate: defaultEnrollDate,
            country: 'USA',
          },
        });
      }
  }

  mapStudentMore(data, item) {
    data.referenceSourceId = item.ReferenceSourceId;
    data.isEmployed = item.IsEmployed;
    data.lastEmployedDate = item.LastEmployedDate;
    data.employmentStartDate = item.EmploymentStartDate;
    data.faithTypeId = item.FaithTypeId;
    data.isChurchGoing = item.IsChurchGoing;
    data.churchName = item.ChurchName;
    data.churchPhone = item.ChurchPhone;
    data.faithNotes = item.FaithNotes;
    data.churchCity = item.ChurchCity;
    data.churchState = item.ChurchState;
    data.churchCountry = item.ChurchCountry;
    data.churchDenominationId = item.ChurchDenominationId;
    data.isBornAgain = item.IsBornAgain;
    data.isSpiritFilled = item.IsSpiritFilled;
    data.exitReasonNotes = item.ExitReasonNotes;
    data.housingTypeId = item.HousingTypeId;
    data.clientTypeId = item.ClientTypeId;
    data.demographicNotes = item.Notes;
  }

  mapStudentLegalIssuesToState(data, items) {
    data.isLegallyRequiredTreatment = items.IsLegallyRequiredTreatment;
    data.isUnderLegalSupervision = items.IsUnderLegalSupervision;
    data.legallyRequiredOfficer = items.LegallyRequiredOfficer;
    data.legallyRequiredOfficerDetails = items.LegallyRequiredOfficerDetails;
    data.supervisionApproach = items.SupervisionApproach;
    data.reportToSupervision = items.ReportToSupervision;
    data.probationOfficerName = items.ProbationOfficerName;
    data.probationOfficerJurisdiction = items.ProbationOfficerJurisdiction;
    data.probationOfficerEmail = items.ProbationOfficerEmail;
    data.probationOfficerPhone = items.ProbationOfficerPhone;
    data.attorneyName = items.AttorneyName;
    data.attorneyEmail = items.AttorneyEmail;
    data.attorneyPhone = items.AttorneyPhone;
    data.attorneyAttorneyLine1 = items.AttorneyAddressLine1;
    data.attorneyAttorneyLine2 = items.AttorneyAddressLine2;
    data.attorneyCity = items.AttorneyCity;
    data.attorneyStateOrProvince = items.AttorneyStateOrProvince;
    data.attorneyZip = items.AttorneyZipCode;
    data.attorneyCountry = items.AttorneyCountryISO;
    data.pendingCases = '' + items.PendingCases + '';
    data.pastArrestandConvictions = items.PastArrestandConvictions;
    data.impContactInfoId = items.ImpContactInfoId;
    data.superVisionContact = items.SuperVisionContactAddress;
    data.requiredToRegisterSexualOffender = items.RequiredToRegisterSexualOffender;
    data.hasArsonConvictions = items.HasArsonConvictions;
    data.hasViolentOffensesConvictions = items.HasViolentOffensesConvictions;
    data.legalObligations = items.LegalObligations;
    data.hasSafetyConcerns = items.HasSafetyConcerns;
    data.hasAttorneyAssistance = items.HasAttorneyAssistance;
    data.requiredToRegisterSexualOffenderNote = items.RequiredToRegisterSexualOffenderNote;
    data.hasArsonConvictionsNote = items.HasArsonConvictionsNote;
    data.hasViolentOffensesConvictionsNote = items.HasViolentOffensesConvictionsNote;
    data.hasSafetyConcernsNote = items.HasSafetyConcernsNote;
    data.hasAttorneyAssistanceNote = items.HasAttorneyAssistanceNote;
    data.isArrestedForSexualOffense = items.IsArrestedForSexualOffense;
    data.isArrestedForSexualOffenseNote = items.IsArrestedForSexualOffenseNote;
    data.isLegallyRequiredTreatmentNote = items.IsLegallyRequiredTreatmentNote;
    data.legalIssuesNotes = items.Notes;
  }

  mapStudentMedicalPastToState(items) {
    var medicalPasts = [];
    if (items && items.length > 0) {
      for (var item of items) {
        var medicalPast = {};
        medicalPast.relation = item.Relation;
        medicalPast.familyMedicalPast = item.FamilyMedicalPast;
        medicalPast.devlopmentalIssuesPast = item.DevlopmentalIssuesPast;
        medicalPast.currentMedications = item.CurrentMedications;
        medicalPast.allergiesMedicines = item.AllergiesMedicines;
        medicalPast.allergiesFood = item.AllergiesFood;
        medicalPast.allergiesOthers = item.AllergiesOthers;
        medicalPast.currentPhysicanName = item.CurrentPhysicanName;
        medicalPast.currentPhysicanPhone = item.CurrentPhysicanPhone;
        medicalPast.currentPhysicanAddress = item.CurrentPhysicanAddress;
        medicalPast.isTobaccoUser = item.IsTobaccoUser;
        medicalPast.needTobaccoDetox = item.NeedTobaccoDetox;
        medicalPast.needAccommodation = item.NeedAccommodation;
        medicalPast.accommodationNote = item.AccommodationNote;
        medicalPast.isUnderMedication = item.IsUnderMedication;
        medicalPast.medicationList = item.MedicationList;
        medicalPast.hasAllergies = item.HasAllergies;
        medicalPast.hasMentalHealthDiagnosis = item.HasMentalHealthDiagnosis;
        medicalPast.diagnosisNote = item.DiagnosisNote;
        // medicalPast.physicalWithin30Days = item.PhysicalWithin30Days;
        // medicalPast.noIncomeFromVocationalTraining = item.NoIncomeFromVocationalTraining;
        medicalPast.isPreviouslyEnrolled = item.IsPreviouslyEnrolled;
        medicalPast.hasAllergiesNote = item.HasAllergiesNote;
        medicalPast.isPreviouslyEnrolledNote = item.IsPreviouslyEnrolledNote;
        medicalPast.treatmentForAddiction = item.TreatmentForAddiction;
        medicalPast.hasSuicidalThoughts = item.HasSuicidalThoughts;
        medicalPast.conditionofTeeth = item.ConditionofTeeth;
        medicalPast.notes = item.Notes;
        medicalPasts.push(medicalPast);
      }
    }
    return medicalPasts;
  }

  mapStudentInsuranceToState(items) {
    var healthInsurances = [];
    if (items && items.length > 0) {
      for (var item of items) {
        var healthInsurance = {};
        healthInsurance.studentInsuranceId = item.StudentInsuranceId;
        healthInsurance.insuranceCompany = item.InsuranceCompany;
        healthInsurance.policyNumber = item.PolicyNumber;
        healthInsurance.groupNumber = item.GroupNumber;
        healthInsurance.healthInsuranceTypeId = item.HealthInsuranceTypeId;
        healthInsurance.notes = item.Notes;
        healthInsurance.line1 = item.AddressLine1;
        healthInsurance.city = item.City;
        healthInsurance.stateOrProvince = item.StateOrProvince;
        healthInsurance.zip = item.ZipCode;
        healthInsurance.country = item.CountryISO;
        healthInsurance.phone = item.Phone;
        healthInsurance.fax = item.Fax;
        healthInsurance.email = item.Email;
        healthInsurances.push(healthInsurance);
      }
    }

    return healthInsurances;
  }

  mapStudentPreviousTreatmentsToState(items) {
    var previousTreatments = [];

    if (items) {
      for (var item of items) {
        var previousTreatment = {};
        previousTreatment.studentPreviousTreatmentId = item.StudentPreviousTreatmentId;
        previousTreatment.startDate = item.StartDate;
        previousTreatment.endDate = item.EndDate;
        previousTreatment.centerName = item.CenterName;
        previousTreatment.notes = item.Notes;
        previousTreatment.programTypeId = item.ProgramTypeId;

        previousTreatments.push(previousTreatment);
      }
    }
    return previousTreatments;
  }

  mapStudentFundSourcesToState(items) {
    var fundSources = [];

    if (items) {
      for (var item of items) {
        var fundSource = {};
        fundSource.studentFundSourceId = item.StudentFundSourceId;
        fundSource.responsibilityTypeId = item.ResponsibilityTypeId;
        if (fundSource.responsibilityTypeId == 7) {
          fundSource.responsibilityTypeOther = item.ResponsibilityTypeOther;
        } else {
          fundSource.responsibilityTypeOther = null;
        }

        fundSource.contributionAmt = item.ContributionAmt;
        fundSource.contributionFrequencyCode = item.ContributionFrequencyCode;
        fundSource.costAndFamilyDiscussed = item.CostAndFamilyDiscussed;
        fundSource.ableToCoverIntakeFee = item.AbleToCoverIntakeFee;
        fundSource.supportForMonthlyContributions = item.SupportForMonthlyContributions;
        fundSource.costAndFamilyDiscussedNote = item.CostAndFamilyDiscussedNote;
        fundSource.ableToCoverIntakeFeeNote = item.AbleToCoverIntakeFeeNote;
        fundSource.supportForMonthlyContributionsNote = item.SupportForMonthlyContributionsNote;

        fundSource.notes = item.Notes;
        fundSources.push(fundSource);
      }
    } else {
      fundSources.push({});
    }

    return fundSources;
  }

  mapStudentPriorAbusesToState(items) {
    var priorAbuses = [];

    if (items) {
      for (var item of items) {
        var priorAbuse = {};
        priorAbuse.studentPriorAbuseId = item.StudentPriorAbuseId;
        priorAbuse.notes = item.Notes;
        priorAbuse.priorAbuseTypeId = item.PriorAbuseTypeId;
        if (priorAbuse.priorAbuseTypeId == 5) {
          priorAbuse.priorAbuseTypeOther = item.PriorAbuseTypeOther;
        } else {
          priorAbuse.priorAbuseTypeOther = null;
        }

        priorAbuse.ageWhenAbused = item.AgeWhenAbused;
        priorAbuse.abusedByRelationshipId = item.AbusedByRelationshipId;
        if (priorAbuse.abusedByRelationshipId == 10) {
          priorAbuse.abusedByRelationshipOther = item.AbusedByRelationshipOther;
        } else {
          priorAbuse.abusedByRelationshipOther = null;
        }

        priorAbuse.abuserAge = item.AbuserAge;
        priorAbuse.abuserGender = item.AbuserGender;

        if (item.HowOften == 1) {
          priorAbuse.howOften = 1;
          priorAbuse.abuseFrequencyCode = null;
        } else if (item.HowOften > 1) {
          priorAbuse.howOften = 2;
          priorAbuse.howOftenActual = item.HowOften;
          priorAbuse.abuseFrequencyCode = item.AbuseFrequencyCode;
        }

        priorAbuses.push(priorAbuse);
      }
    }

    return priorAbuses;
  }

  mapStudentFinancialsToState(items) {
    var financials = [];

    if (items) {
      for (var item of items) {
        var financial = {};
        financial.studentFinancialId = item.StudentFinancialId;
        financial.centerServiceTypeCenterId = item.CenterServiceTypeCenterId;
        financial.estimatedCost = item.EstimatedCost;
        financial.estimatedCostFrequencyCode = item.EstimatedCostFrequencyCode;
        financial.startDate = item.StartDate;
        financial.endDate = item.EndDate;
        financial.notes = item.Notes;
        financial.isEndDateEstimated = item.IsEndDateEstimated;
        financial.outcomeField = item.OutcomeField;
        financial.phaseId = item.PhaseId;
        financials.push(financial);
      }
    }

    return financials;
  }

  mapStudentReInstateFinancialsToState(items) {
    var reInstateFinancials = [];

    if (items) {
      for (var item of items) {
        var financial = {};
        financial.studentFinancialId = item.StudentFinancialId;
        financial.centerServiceTypeCenterId = item.CenterServiceTypeCenterId;
        financial.estimatedCost = item.EstimatedCost;
        financial.estimatedCostFrequencyCode = item.EstimatedCostFrequencyCode;
        financial.startDate = item.StartDate;
        financial.endDate = item.EndDate;
        financial.notes = item.Notes;
        financial.isEndDateEstimated = item.IsEndDateEstimated;
        financial.outcomeField = item.OutcomeField;
        financial.phaseId = item.PhaseId;
        reInstateFinancials.push(financial);
      }
    }

    return reInstateFinancials;
  }

  mapStudentAddictionToState(items) {
    var addictions = [];

    if (items) {
      for (var item of items) {
        var addiction = {};

        addiction.addictionProfileTypeId = item.AddictionProfileTypeId;
        addiction.studentAddictionTypeId = item.StudentAddictionTypeId;
        if (addiction.addictionProfileTypeId == 5) {
          addiction.addictionProfileTypeOther = item.AddictionProfileTypeOther;
        } else {
          addiction.addictionProfileTypeOther = null;
        }
        // if (addiction.addictionProfileTypeId != 1) {
        //   addiction.addictionFrequency = item.AddictionFrequency;
        // } else {
        //   addiction.addictionFrequency = null;
        // }

        addiction.addictionTypeId = item.AddictionTypeId;
        addiction.drugRouteOfAdminId = '' + item.DrugRouteOfAdminId + '';
        addiction.reportedDate = item.ReportedDate;
        addiction.startDate = item.StartDate;
        addiction.ageOfFirstUse = item.AgeOfFirstUse;
        addiction.endDate = item.EndDate;
        addiction.notes = item.Notes;
        addiction.frequencyOfUseId = item.FrequencyOfUseId;
        addiction.isDrugOfChoice = item.IsDrugOfChoice;
        addiction.lastInjection = item.LastInjected;
        addictions.push(addiction);
      }
    } else {
      addictions.push({
        addictionTypeId: this.props.defaultAddictionProfileTypeId,
        addictionProfileTypeId: 1,
      });
    }

    return addictions;
  }

  mapStudentContactToState(items) {
    var contacts = [];

    if (items) {
      for (var item of items) {
        var contact = {};
        contact.contactId = item.ContactId;
        contact.firstName = item.Contact[0].FirstName;
        contact.middleName = item.Contact[0].MiddleName;
        contact.lastName = item.Contact[0].LastName;
        contact.line1 = item.Contact[0].AddressLine1;
        contact.line2 = item.Contact[0].AddressLine2;
        contact.line3 = item.Contact[0].AddressLine3;
        contact.city = item.Contact[0].City;
        contact.stateOrProvince = item.Contact[0].StateOrProvince;
        contact.country = item.Contact[0].CountryISO;
        contact.zip = item.Contact[0].ZipCode;
        contact.emergencyCN = item.Contact[0].EmergencyPhone;
        contact.primaryCN = item.Contact[0].PrimaryPhone;
        contact.secondaryCN = item.Contact[0].SecondaryPhone;
        contact.email = item.Contact[0].Email;
        contact.relationshipId = item.Contact[0].RelationShipId;
        contact.isEmergencyContact = item.Contact[0].IsEmergencyContact;
        contact.notes = item.Contact[0].Notes;
        contacts.push(contact);
      }
    }

    return contacts;
  }
  setRef = webcam => {
    this.webcam = webcam;
  };
  capture = imageSrc => {
    // const imageSrc = this.webcam.getScreenshot();
    const me = this;
    Axios.post('student/UploadImage', {
      image: imageSrc,
      studentId: this.getStudentId(),
      IsProfilePic: true,
    })
      .then(response => {
        // me.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
        me.props.getStudent(this.getStudentId());
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };

  render() {
    const {
      handleSubmit,
      isFetching,
      isUpserting,
      defaultAddictionProfileTypeId,
      defaultCounsellor,
      defaultHomeAddressType,
      defaultClientType,
      defaultLanguage,
      defaultFaithType,
      errorMessage,
      defaultCenter,
      defaultEnrollDate,
      isDirty,
      submitSucceeded,
      StudentImageUrl,
      studentMatchedWithClient,
      isReInstated,
      preItakeSurveyId,
      haveAccessToProspectField,
      isAllowToEditClosed,
    } = this.props;
    const { onSubmit } = this;
    const { modalMessage, showError, modalVisible, isDisableSave } = this.state;
    const {
      StateProvince,
      Center,
      Counsellor,
      ReferenceSource,
      NameSuffix,
      NamePrefix,
      Country,
      HomeAddressType,
      ChurchDenomination,
      ClientType,
    } = this.props.combos;
    const { activeKey, studentData, tabLabels, activeTabs } = this.state;

    return (
      <>
        <PageTitle TitleText="Edit client profile" />
        <div class="main enrollment-page mb-3">
          <Loader loading={activeKey === null ? true : false} />
          <div class="container-fluid">
            <div class="row top-heading-row mt-1 mb-3 align-items-center">
              <div class="col-12">
                <a
                  class="back-link"
                  // onClick={() => this.props.history.goBack()}
                  onClick={() => {
                    if (this.state.studentData.EnrollStatusId === 0) {
                      this.props.history.push('/clients/intake');
                    } else {
                      this.props.history.goBack();
                    }
                  }}
                  style={{ display: 'flex' }}
                >
                  <img src="images/back-arrow.svg" class="mr-2" /> Back
                </a>
              </div>
              <div class="col-12 pb-2">
                {this.getStudentId() > 0 ? (
                  <h3 class="page-title mb-3 mb-md-0">
                    {this.props.isView ? 'View Profile' : `Edit Profile`}
                  </h3>
                ) : (
                  <h3 class="page-title  mr-2 pb-0 d-inline ">New Enrollment</h3>
                )}
              </div>
              <div class="col-12 col-md-8 col-lg-4">
                {this.getStudentId() > 0 && (
                  <h5 class="font-weight-semibold mb-3 mb-md-0 students-profile">
                    <div className="d-flex align-items-center">
                      {/* <div style={{ width: '57px', height: '57px', marginRight: '10px' }}>
                        <img
                          src={studentData.studentImageUrl}
                          // src="/images/surveys-img5.jpg"
                          height="100%"
                          style={{ borderRadius: '10px', objectFit: 'cover' }}
                        />
                      </div> */}
                      <UploadProfile imgSrc={StudentImageUrl} captureProfile={this.capture} />

                      <div style={{ marginLeft: '5px' }}>
                        <div class="d-flex ml-1">
                          <span className="d-block">{`${studentData.firstName} ${studentData.lastName}
                          `}</span>
                          <span className="d-block">
                            ({this.getStudentId()}-{studentData.center})
                          </span>
                        </div>
                        {this.getStudentId() > 0 && (
                          <div className="mt-1">
                            {/* {this.props.IsClient && (
                              <>
                                <button
                                  onClick={() => {
                                    this.props.history.push(
                                      `/cw/patient-management/patient-profile?tab=demographic&pId=${this.props.ClientId}`,
                                    );
                                  }}
                                  class="btn btn-outline-primary mt-md-2"
                                  style={{
                                    display: 'inline-block',
                                  }}
                                >
                                  <figure
                                    class="action-button-icon"
                                    style={{
                                      display: 'inline-block',
                                      marginRight: '2px',
                                      padding: '0px 5px',
                                    }}
                                  >
                                    Clinical
                                  </figure>
                                </button>
                              </>
                            )} */}

                            {/* {this.props.IsMobileUser && (
                              <>
                                <button
                                  onClick={() => {
                                    this.props.history.push(
                                      '/subscriberProfile/' + this.props.UserGuid,
                                    );
                                  }}
                                  class="btn btn-outline-primary mt-md-2"
                                  style={{
                                    display: 'inline-block',
                                  }}
                                >
                                  <figure
                                    class="action-button-icon"
                                    style={{
                                      display: 'inline-block',
                                      marginRight: '2px',
                                      padding: '0px 5px',
                                    }}
                                  >
                                    Subscriber
                                  </figure>
                                </button>
                              </>
                            )} */}
                          </div>
                        )}
                      </div>
                    </div>
                  </h5>
                )}
                {false && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <button onClick={onClick} class="btn black-btn">
                        Send Link to Applicants
                      </button>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            &times;
                          </button>
                          <h4 class="modal-title col-12 " style={{ marginLeft: '-155px' }}>
                            New Applicant
                          </h4>
                          {/* <ReduxNewApplicantForm/> */}
                          <ReduxNewApplicantForm onSubmit={this.onAddNewApplicant} />
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
              <div class="col-12 col-md-4 col-lg-8 text-right d-flex justify-content-end">
                {this.getStudentId() > 0 && (
                  <div className="d-flex align-items-center" style={{ marginTop: '10px' }}>
                    {/* {this.props.clientCopyLinkVisibility && (
                      <>
                        {!this.props.IsClientCopiedToRCM && (
                          <>
                            <AntdModal
                              modalWidth={800}
                              ModalButton={({ onClick }) => (
                                <span
                                  className="px-3"
                                  style={{ color: 'rgb(74, 176, 254)', cursor: 'pointer' }}
                                  onClick={() => {
                                    onClick();
                                    // this.props.dispatch(
                                    //   rcmClientMerge({ StudentId: this.getStudentId() }),
                                    // );
                                  }}
                                >
                                  Copy this client to clinical workflow
                                </span>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                  <div class="modal-body pb-0 pr-2 pl-2  pr-xl-5 pl-xl-5">
                                    <span
                                      class="modal-title user-merging d-flex align-items-center col-12 mb-3"
                                      style={{ fontSize: '22px' }}
                                    >
                                      {this.props.studentMatchedWithClient
                                        ? renderHTML(studentMatchedWithClient.Client[0].Message)
                                        : 'Are you sure you want to copy this client to clinical?'}
                                      <br />
                                    </span>
                                  </div>
                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      class="btn btn-outline-primary black-btn"
                                      onClick={() => {
                                        onCancel();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="btn"
                                      onClick={() => {
                                        onOk();
                                        // dispatch(announcementDelete({ AnnouncementId: getAnnouncementId }));
                                        this.props.dispatch(
                                          rcmClientMerge({
                                            StudentId: this.getStudentId(),
                                            ClientId:
                                              (this.props.studentMatchedWithClient &&
                                                this.props.studentMatchedWithClient.Client &&
                                                this.props.studentMatchedWithClient.Client[0]
                                                  .PatientId) ||
                                              null,
                                          }),
                                        );
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          </>
                        )}
                      </>
                    )} */}
                    {this.props.IsClient && (
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        onClick={() => {
                          this.props.history.push(
                            `/cw/patient-management/patient-profile?tab=demographic&pId=${this.props.ClientId}`,
                          );
                        }}
                      >
                        Switch to Clinical
                      </button>
                    )}
                    {this.props.IsMobileUser && (
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        onClick={() => {
                          this.props.history.push('/subscriberProfile/' + this.props.UserGuid);
                        }}
                      >
                        Switch to Subscriber
                      </button>
                    )}

                    {this.state.studentData &&
                      this.state.studentData.EnrollStatusId != null &&
                      this.state.studentData.EnrollStatusId != undefined &&
                      this.state.studentData.EnrollStatusId == 0 &&
                      !this.props.IsClient && (
                        <AntdModal
                          ModalButton={({ onClick }) => (
                            <ActionableIcon
                              title=" Move to Clinical"
                              iconSrc="images/move-to-prospect.svg"
                              onClick={onClick}
                            />
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                              <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                <span
                                  class="modal-title user-merging d-flex align-items-center col-12 mb-3"
                                  style={{ fontSize: '22px' }}
                                >
                                  Are you sure you want to enroll this client into clinical?
                                  <br />
                                </span>
                              </div>
                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  class="btn btn-outline-primary"
                                  onClick={() => {
                                    onCancel();
                                  }}
                                >
                                  No
                                </button>
                                <button
                                  class="btn"
                                  onClick={() => {
                                    onOk();
                                    this.props.dispatch(
                                      rcmClientMerge({
                                        StudentId: this.getIntakeId(),
                                        ClientId: null,
                                      }),
                                    );
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      )}
                    {this.props.clientCopyLinkVisibility &&
                      this.state.studentData.EnrollStatusId != 0 && (
                        <>
                          {!this.props.IsClientCopiedToRCM && (
                            <>
                              <AntdModal
                                modalWidth={800}
                                ModalButton={({ onClick }) => (
                                  <ActionableIcon
                                    title="Copy to clinical"
                                    iconSrc="images/copy-to.svg"
                                    onClick={() => {
                                      onClick();
                                    }}
                                  />
                                )}
                                ModalContent={({ onOk, onCancel }) => (
                                  <div class="modal-content border-0">
                                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                    <div class="modal-body pb-0 pr-2 pl-2  pr-xl-5 pl-xl-5">
                                      <span
                                        class="modal-title user-merging d-flex align-items-center col-12 mb-3"
                                        style={{ fontSize: '22px' }}
                                      >
                                        {this.props.studentMatchedWithClient
                                          ? renderHTML(studentMatchedWithClient.Client[0].Message)
                                          : 'Are you sure you want to copy this client to clinical?'}
                                        <br />
                                      </span>
                                    </div>
                                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                      <button
                                        class="btn btn-outline-primary black-btn"
                                        onClick={() => {
                                          onCancel();
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        class="btn"
                                        onClick={() => {
                                          onOk();
                                          // dispatch(announcementDelete({ AnnouncementId: getAnnouncementId }));
                                          this.props.dispatch(
                                            rcmClientMerge({
                                              StudentId: this.getStudentId(),
                                              ClientId:
                                                (this.props.studentMatchedWithClient &&
                                                  this.props.studentMatchedWithClient.Client &&
                                                  this.props.studentMatchedWithClient.Client[0]
                                                    .PatientId) ||
                                                null,
                                            }),
                                          );
                                        }}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            </>
                          )}
                        </>
                      )}
                    <ActionableIcon
                      title="Pin"
                      iconSrc="images/pin.svg"
                      onClick={() => {
                        const isWebCam = localStorage.getItem('isWebCam') === '0' ? false : true;
                        if (isWebCam) {
                          this.props.history.push({
                            pathname: '/camera',
                            search: 'studentId=' + this.getStudentId(),
                            param: {
                              message: 'Changing PIN requires new profile picture to be taken.',
                            },
                          });
                        } else {
                          this.props.history.push({
                            pathname: '/pin',
                            search: 'studentId=' + this.getStudentId(),
                          });
                        }
                      }}
                      disabled={this.getDischarged()}
                    />

                    <Link
                      class="mr-2"
                      to={{
                        pathname: '/printStudentInfo',
                        search: 'studentId=' + this.getStudentId(),
                      }}
                      target="_blank"
                    >
                      <figure
                        class="action-button-icon"
                        style={{ display: 'inline-block', position: 'relative', marginTop: '5px' }}
                      >
                        <Tooltip placeholder="top" title="Print">
                          <img src="images/print_action.svg" alt="" width="50" />
                        </Tooltip>
                      </figure>
                    </Link>
                  </div>
                )}

                {/* <div class="d-flex align-items-center">
                  <button
                    onClick={() => this.props.history.push('/students')}
                    class="btn btn-outline-primary mr-3"
                    // disabled={this.getDischarged()}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      this.props.remoteSubmit();
                    }}
                    onClick2={() => {
                      activeTabs.toString().indexOf('false') == -1
                        ? this.props.remoteSubmit()
                        : this.setState({ showError: true }, () =>
                            setTimeout(() => this.setState({ showError: false }), 2000),
                          );
                    }}
                    class="btn"
                    disabled={this.getDischarged()}
                  >
                    Save
                  </button>
                </div> */}
              </div>
            </div>
            {showError && (
              <div class="alert alert-danger alert-panel" role="alert">
                Please fill the entire form
              </div>
            )}
            <div class="row">
              <div class="col-12 mb-5 vertical-tabs enrollment">
                <Tabs
                  activeKey={String(activeKey)}
                  tabPosition="left"
                  defaultActiveKey="0"
                  onTabClick={this.onTabClick}
                >
                  {tabLabels &&
                    tabLabels.map((item, index) => {
                      const { Component, Name } = item;
                      const tabsCount = tabLabels.length;
                      return (
                        <TabPane
                          tab={Name}
                          key={`${index}`}
                          className="tab-content enrollment-form"
                        >
                          {activeKey == index && (
                            <Component
                              style={{ padding: '36px 20px 150px 20px' }}
                              handleNext={() => this.handleNext(index)}
                              initialValues={studentData}
                              defaultCounsellor={defaultCounsellor}
                              currentTab={index}
                              tabsCount={tabsCount}
                              isReInstated={isReInstated}
                              defaultCenter={defaultCenter}
                              defaultEnrollDate={defaultEnrollDate}
                              whoIsCalling={this.props.whoIsCalling}
                              hideQuestions={this.props.hideQuestions}
                              isBoiseGirlsAcademyCenter={this.props.isBoiseGirlsAcademyCenter}
                              centerWhoIsCalling={this.props.centerWhoIsCalling}
                              defaultLanguage={defaultLanguage}
                              defaultFaithType={defaultFaithType}
                              defaultHomeAddressType={defaultHomeAddressType}
                              defaultClientType={defaultClientType}
                              defaultAddictionProfileTypeId={defaultAddictionProfileTypeId}
                              StateProvince={StateProvince}
                              studentId={this.getStudentId()}
                              Center={Center}
                              Counsellor={Counsellor}
                              ReferenceSource={ReferenceSource}
                              NameSuffix={NameSuffix}
                              ClientId={this.getStudentId()}
                              NamePrefix={NamePrefix}
                              viewHistory={this.viewHistory}
                              PreItakeSurveyId={preItakeSurveyId}
                              Country={Country}
                              HomeAddressType={HomeAddressType}
                              ChurchDenomination={ChurchDenomination}
                              ClientType={ClientType}
                              handleSubmitAndNext={this.handleSubmitAndNext}
                              dispatch={this.props.dispatch}
                              onCancel={() => this.props.history.goBack()}
                              onChangePin={() => {
                                const isWebCam =
                                  localStorage.getItem('isWebCam') === '0' ? false : true;
                                if (isWebCam) {
                                  this.props.history.push({
                                    pathname: '/camera',
                                    search: 'studentId=' + this.getStudentId(),
                                    param: {
                                      message:
                                        'Changing PIN requires new profile picture to be taken.',
                                    },
                                  });
                                } else {
                                  this.props.history.push({
                                    pathname: '/pin',
                                    search: 'studentId=' + this.getStudentId(),
                                  });
                                }
                              }}
                              isFetching={isFetching}
                              isUpserting={isUpserting}
                              updateDeleteModel={this.updateDeleteModel}
                              onLastSubmit={onSubmit.bind(this)}
                              goToPreviuos={() => this.goToPreviuos(index)}
                              onSubmit={onSubmit.bind(this)}
                              enableReinitialize={true}
                              keepDirtyOnReinitialize={true}
                              disable={this.getDischarged()}
                              haveAccessToProspectField={haveAccessToProspectField}
                              isAllowToEditClosed={this.props.isAllowToEditClosed}
                            />
                          )}
                        </TabPane>
                      );
                    })}
                </Tabs>
              </div>
              <div class="col-12 position-sticky" style={{ bottom: 0, zIndex: '1' }}>
                <div
                  className="row enrollment-page top-heading-row"
                  style={{
                    backgroundColor: '#ffffff',
                    boxShadow: 'rgba(0, 0, 0, 0.03)',
                  }}
                >
                  <div class="col-12 px-5 py-3">
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        onClick={() => this.props.history.push('/clients/list')}
                        class="btn btn-outline-primary mr-3"
                        // disabled={this.getDischarged()}
                      >
                        Cancel
                      </button>
                      <Button
                        loading={isUpserting}
                        onClick={() => {
                          this.props.remoteSubmit();
                        }}
                        onClick2={() => {
                          activeTabs.toString().indexOf('false') == -1
                            ? this.props.remoteSubmit()
                            : this.setState({ showError: true }, () =>
                                setTimeout(() => this.setState({ showError: false }), 2000),
                              );
                        }}
                        className="save-button"
                        disabled={
                          isAllowToEditClosed
                            ? !isAllowToEditClosed
                            : this.getDischarged() || isDisableSave
                        }
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CustomModal
            isOpen={this.state.isModelOpen}
            header={errorMessage || 'Your changes have been saved successfully'}
            // header={'Your Changes have been saved successfully'}
            yesAction={() => {
              this.toggle();
              if (this.state.studentData.EnrollStatusId === 0) {
                this.props.history.push('/clients/intake');
              } else {
                this.props.history.push('/clients/list');
              }
              // this.props.history.goBack(); as per requirement sending on list
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
          <CustomModal
            isOpen={this.state.isNewSaveModelOpen}
            header={modalMessage}
            hideCancel={true}
            hideOk={true}
          />
          <CustomModal
            isOpen={this.state.isDeleteModelOpen}
            header={this.state.deleteModelMessage}
            yesAction={() => {
              this.deleteModelToggle();
            }}
            yesText="OK"
            noText="Cancel"
            noAction={() => this.setState({ isDeleteModelOpen: false })}
          />
          <WarningComponent dirtyFlag={isDirty} submitSucceeded={submitSucceeded} />
        </div>
        <CustomModal2
          isOpen={this.state.isModelOpen2}
          // header={errorMessage || 'Your changes have been saved successfully'}
          header={this.state.header}
          yesAction={() => {
            // this.toggle();
            // this.toggleView();
            // this.props.onCancel();
            // this.props.history.goBack();
            this.setState({
              isModelOpen2: false,
              header: '',
            });
          }}
          yesText="OK"
          hideCancel={true}
          changeMaxWidth="100%"
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    student_add,
    student_get,
    combos,
    default_values,
    add_new_applicant,
    rcm_client_merge,
    student_staff_accessed_tab_get,
    center_config_get_by_id,
  } = state;
  var defaultCounsellor = null;
  var defaultLanguage = null;
  var defaultFaithType = null;
  var defaultHomeAddressType = null;
  var defaultClientType = null;
  var defaultAddictionProfileTypeId = null;
  var defaultCenter = null;
  var isAdolescent = null;
  var defaultEnrollDate = null;
  var rcmMergeSuccess = false;
  var IsClientCopiedToRCM = false;
  var clientCopyLinkVisibility = false;
  let IsClient = false;
  let ClientId = null;
  let IsMobileUser = false;
  let UserGuid = null;
  let StudentImageUrl = null;
  let haveAccessToProspectField = null;
  let isView = false;
  let isReadOnlyUser = false;
  let studentMatchedWithClient = null;
  let studentTabs = [];
  let whoIsCalling = null;
  let hideQuestions = null;
  let isBoiseGirlsAcademyCenter = null;
  let centerWhoIsCalling = null;
  let isAllowToEditClosed = false;

  if (student_staff_accessed_tab_get.data && student_staff_accessed_tab_get.data.Tabs) {
    studentTabs = student_staff_accessed_tab_get.data.Tabs;
  }

  if (default_values && default_values.data) {
    if (default_values.data.centerId === default_values.data.UserDefaultCenterId) {
      defaultCounsellor = default_values.data.userId;
    }
    defaultLanguage = default_values.data.language;
    defaultFaithType = default_values.data.faithTypeId;
    defaultHomeAddressType = default_values.data.homeAddressType;
    defaultClientType = default_values.data.clientTypeId;
    defaultAddictionProfileTypeId = default_values.data.addictionProfileTypeId;
    defaultCenter = default_values.data.centerId;
    isAdolescent = default_values.data.isAdolescent;
    defaultEnrollDate = default_values.data.enrollDate;
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  // var submitErrors = getFormSyncErrors('EnrollmentForm')(state);
  var { data, error, isFetching: isUpserting } = student_add;
  const { data: studentInfo, isFetching } = student_get;
  const { data: centerConfigById } = center_config_get_by_id;

  var isInserted = false;
  var recordInfo = null;
  let isReInstated = null;
  let preItakeSurveyId = null;
  var errorMessage = '';
  if (student_add && student_add.data) {
    if (data && data.success) {
      isInserted = true;
      recordInfo = data;
    }
  }

  if (error) {
    //errorMessage = error.Message;
  }

  if (centerConfigById && centerConfigById.CenterConfig) {
    centerWhoIsCalling =
      centerConfigById.CenterConfig[0] && centerConfigById.CenterConfig[0].WhoIsCalling;
    hideQuestions =
      centerConfigById.CenterConfig[0] && centerConfigById.CenterConfig[0].HideQuestions;
    isBoiseGirlsAcademyCenter =
      centerConfigById.CenterConfig[0] &&
      centerConfigById.CenterConfig[0].IsBoiseGirlsAcademyCenter;
  }

  if (!combos.data) {
    combos.data = {
      CenterServiceTypeCenter: [],
      FinancialResponsibilityType: [],
      RelationShip: [],
      PriorAbuseRelationship: [],
      FaithType: [],
      ReferenceSource: [],
      Frequency: [],
      MaritalStatus: [],
      EthnicRace: [],
      Gender: [],
      HousingType: [],
      EyeColor: [],
      Language: [],
      EducationType: [],
      WorkSkill: [],
      SurveyQuestionCategory: [],
      SurveyQuestionType: [],
      AddictionType: [],
      AddictionProfileType: [],
      DrugRouteOfAdmin: [],
      NameSuffix: [],
      NamePrefix: [],
      HomeAddressType: [],
      ChurchDenomination: [],
      Center: [],
      Counsellor: [],
      AbuseFrequency: [],
      OutcomeField: [],
    };
  }
  if (rcm_client_merge && rcm_client_merge.data && rcm_client_merge.data.success) {
    rcmMergeSuccess = rcm_client_merge.data.success;
  }
  if (studentInfo) {
    IsClientCopiedToRCM = studentInfo.IsClient;
    isReInstated = studentInfo.IsReInstated;
    clientCopyLinkVisibility = studentInfo.IsClinicalWorkflow;
    studentMatchedWithClient = studentInfo.StudentMatchedWithClient;
    preItakeSurveyId = studentInfo.PreItakeSurveyId;
    IsClient = studentInfo.IsClient;
    ClientId = studentInfo.ClientId;
    IsMobileUser = studentInfo.IsMobileUser;
    UserGuid = studentInfo.UserGuid;
    StudentImageUrl = studentInfo.StudentMisc[0].StudentImageUrl;
    haveAccessToProspectField = studentInfo.HaveAccessToProspectField;
    whoIsCalling = studentInfo.WhoIsCalling;
    isAllowToEditClosed = studentInfo.IsAllowToEditClosed;
    isView =
      studentInfo.StudentMisc &&
      studentInfo.StudentMisc[0] &&
      studentInfo.StudentMisc[0].EnrollStatus == 'Discharged'
        ? true
        : false;
  }
  return {
    isInserted: isInserted,
    studentInfo: studentInfo,
    StudentImageUrl: StudentImageUrl,
    combos: combos.data,
    recordInfo: recordInfo,
    errorMessage: errorMessage,
    // submitErrors: submitErrors,
    isFetching,
    isUpserting,
    defaultCounsellor,
    defaultLanguage,
    defaultFaithType,
    defaultHomeAddressType,
    defaultClientType,
    defaultAddictionProfileTypeId,
    defaultCenter,
    isAdolescent,
    defaultEnrollDate,
    rcmMergeSuccess,
    IsClientCopiedToRCM,
    clientCopyLinkVisibility,
    IsClient,
    ClientId,
    IsMobileUser,
    UserGuid,
    isDirty: isDirty('wizard')(state),
    submitSucceeded: get(state, 'form.wizard.submitSucceeded', false),
    isView,
    isReadOnlyUser,
    studentMatchedWithClient,
    studentTabs,
    isReInstated,
    preItakeSurveyId,
    haveAccessToProspectField,
    whoIsCalling,
    centerWhoIsCalling,
    hideQuestions,
    isBoiseGirlsAcademyCenter,
    isAllowToEditClosed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addStudent: student => dispatch(addStudent(student)),
    getStudent: studentId => dispatch(getStudent(studentId)),
    getClientCounselor: CenterId => dispatch(getClientCounselor({ CenterId })),
    getDynamicComos: ComboTypes => dispatch(getDynamicComos(ComboTypes)),
    resetAddRequest: () => dispatch(resetAddRequest()),
    resetGetStudent: () => dispatch(resetGetStudent()),
    remoteSubmit: () => dispatch(submit('wizard')),
    reset: () => dispatch(destroy('wizard')),
    // clearSubmit: () => dispatch(clearSubmit('wizard')),
    addNewApplicant: payload => dispatch(addNewApplicant(payload)),
    listSmartCombos: payload => dispatch(listSmartCombos(payload)),
    dispatch,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlidingTabs));
