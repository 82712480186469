import React, { Component } from 'react';
import user from '../images/blank-profile.png';
import { connect } from 'react-redux';
import ByteToSize from '../util/ByteToSize';
import Dropzone from 'react-dropzone';
import { Tooltip, Avatar, Checkbox, Button, Radio } from 'antd';
import Icon from '@ant-design/icons';
import Axios from 'axios';
import Webcam from 'react-webcam';
import AntdModal from './CustomModal';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class UploadProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      value: 'Capture',
    };
  }
  setRef = webcam => {
    this.webcam = webcam;
  };
  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.props.captureProfile(imageSrc);
  };
  upload = () => {
    const { files } = this.state;
    let blob = files && files[0].uploadedData;
    this.props.captureProfile(blob);
    this.remove(0);
  };
  onDrop = files => {
    let me = this;
    let dataList = [];
    files.map((obj, index) => {
      Object.assign(obj, {
        preview: URL.createObjectURL(obj),
      });
      getBase64(obj, uploadedData => {
        dataList.push({
          name: obj.name,
          size: obj.size,
          uploadedData: uploadedData,
          preview: obj.preview,
        });
        me.setState({ files: dataList, existing: false, upload: true, groupLeaderErrorMsg: '' });
      });
    });
  };
  onChange = e => this.setState({ value: e.target.value });
  remove = index => {
    const newFiles = [...this.state.files]; // make a var for the new array
    newFiles.splice(index, 1); // remove the file from the array
    this.setState({
      files: [],
      mediaguid: null,
      existing: true,
      upload: false,
    });
  };
  render() {
    const { imgSrc } = this.props;

    const thumbs = this.state.files.map((file, index) => {
      return (
        <div className="d-flex">
          <div className="" key={file.name}>
            <div className="" style={{ height: '200px' }}>
              <img src={file.preview} className="img" />
            </div>
            <div className="">
              <span classname="leader-img-name d-block"> {file.name ? file.name : ''}</span>
              <br />
              <span className="leader-img-size">{ByteToSize(file.size ? file.size : '')}</span>
            </div>
          </div>
          <Tooltip placement="top" title="Remove">
            <span class="delete-course " onClick={() => this.remove(index)}>
              <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                <img src="images/circle-cross.svg" alt="" width="55" />
              </figure>
            </span>
          </Tooltip>
        </div>
      );
    });
    return (
      <div class="students-profile mr-2">
        <div class="EditImage">
          <div
            class="images-placeholder2"
            style={{
              backgroundImage: 'url(&quot;images/surveys-img1.jpg&quot;)',
            }}
          >
            <img
              src={imgSrc || user}
              class="students-profile-img images-placeholder2"
              alt=""
              width="84"
            />
            {!this.props.isReadOnlyUser && (
              <AntdModal
                ModalButton={({ onClick }) => (
                  <div class="EditImageHover">
                    <div class="group">
                      <button
                        title="Edit Image"
                        class="btn"
                        onClick={onClick}
                        // disabled={isDischarged}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                        &times;
                      </button>
                      <h4 class="modal-title col-12">Take a picture</h4>
                    </div>
                    <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
                      <div class="checkbox-to-radio">
                        <div
                          class="nav nav-tabs border-0 font-weight-bold justify-content-center"
                          role="tablist"
                        >
                          <Radio.Group
                            options={['Capture', 'Upload']}
                            onChange={this.onChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      <div className="upload-profile-modal">
                        {this.state.value === 'Capture' ? (
                          <div className="">
                            <Webcam
                              audio={false}
                              ref={this.setRef}
                              style={{
                                height: '400px',
                                width: '400px',
                                margin: 'auto',
                              }}
                              screenshotFormat="image/jpeg"
                            />
                          </div>
                        ) : (
                          <div className="clearfix mt-4">
                            <Dropzone
                              onDrop={this.onDrop}
                              noDrag={true}
                              accept=".jpg,.jpeg,.png"
                              maxSize={2097152}
                              maxFiles={1}
                              multiple={false}
                              // disabled={isDeactivated}
                            >
                              {({ getRootProps, getInputProps, acceptedFiles, fileRejections }) => (
                                <section className="container">
                                  {this.state.files.length < 1 ? (
                                    <div
                                      {...getRootProps({
                                        className:
                                          'dropzone upload-leader-profile d-flex justify-content-center align-items-center flex-column',
                                      })}
                                      style={{ width: '375px' }}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="d-flex align-items-baseline justify-content-center">
                                        {/* <p className="dropzone-upload-drag-icon mr-2 mb-0">
                                          <Icon type="upload" />
                                        </p> */}
                                        <p className="dropzone-upload-text mb-0"> Upload New</p>
                                      </div>
                                      <p className="mb-0">Browse Files</p>
                                    </div>
                                  ) : (
                                    <aside className="profile-thumbs-Container">
                                      <div className="profile-container">{thumbs}</div>
                                    </aside>
                                  )}
                                  {fileRejections && fileRejections.length > 0 && (
                                    <div className="">
                                      {fileRejections.map(({ file, errors }) => {
                                        return (
                                          <div>
                                            {errors.map(e => (
                                              <span key={e.code} className="validation-error">
                                                {e.message}
                                              </span>
                                            ))}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  <span className="validation-error">
                                    {this.state.groupLeaderErrorMsg}
                                  </span>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Close
                      </button>
                      {this.state.value === 'Capture' ? (
                        <button
                          type="button"
                          class="btn"
                          onClick={() => {
                            onOk();
                            this.capture();
                          }}
                        >
                          Capture
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn"
                          disabled={this.state.files.length == 0}
                          // this.state.errorList.length > 0 ||
                          onClick={() => {
                            onOk();
                            this.upload();
                          }}
                        >
                          Upload
                        </button>
                      )}
                    </div>
                  </div>
                )}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UploadProfile;
