import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { Button } from 'antd';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  smartContractDocumentRequestGet,
  resetPayerPortalRequestUpsert,
  resetSmartContractDocumentRequestGet,
} from '../../../../../../store/actions';
import RequestedDocumentsUpload from './documentsUpload';
import Documentslist from './documentslist';
import SelfDocumentsUpload from './selfDocumentsUpload';

const RequestedDocuments = props => {
  const dispatch = useDispatch();
  const [requestedFiles, setRequestedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [noOfReqDoc, setNoOfReqDoc] = useState(null);

  const { smartContractId, clientId } = props;
  const { RequestGet, isReqSuccess, isUploaded, uploadedDataGuid } = useSelector(
    ({
      smart_contract_document_request_get: { data: requestGetData, isFetching, error },
      payer_portal_request_upsert: { data: requestUpsert },
    }) => {
      return {
        RequestGet: requestGetData && requestGetData.Request,
        isReqSuccess: requestUpsert && requestUpsert.success,
      };
    },
    shallowEqual,
  );
  const getLatestData = () => {
    let Data = {
      JSON: JSON.stringify({
        Request: [{ SmartContractId: smartContractId, RequestType: '30212' }],
      }),
    };
    dispatch(smartContractDocumentRequestGet(Data));
  };
  useEffect(() => {
    getLatestData();
      return () => {
        dispatch(resetSmartContractDocumentRequestGet({}));
      };
  }, []);
  useEffect(() => {
    if (smartContractId && isReqSuccess) {
      dispatch(resetPayerPortalRequestUpsert());
      getLatestData();
    }
  }, [isReqSuccess]);
  useEffect(() => {
    if (RequestGet && RequestGet.length > 0) {
      let uploaded = [];
      let requested = [];
      RequestGet.map((request, index) => {
        if (request.Id) {
          uploaded.push(request);
        } else {
          requested.push(request);
        }
      });
      setUploadedFiles(uploaded);
      setRequestedFiles(requested);
      setNoOfReqDoc(
        RequestGet.filter(item => item.IsPayerRequested && item.DocumentGuid == null).length,
      );
    }
  }, [RequestGet]);
  return (
    <div className="requested-documents p-3">
      <div className="row">
        <div className="col-12">
          {/* {noOfReqDoc ? (
            <>
              <p style={{ color: 'rgb(255, 72, 105)', fontWeight: '600', fontSize: '16px' }}>
                Number of Pending Document Request: {noOfReqDoc}
              </p>
            </>
          ) : (
            <></>
          )} */}
          <SelfDocumentsUpload smartContractId={smartContractId} clientId={clientId} />
          {requestedFiles.length > 0 && (
            <RequestedDocumentsUpload
              RequestedFiles={requestedFiles}
              smartContractId={smartContractId}
              clientId={clientId}
            />
          )}
          {uploadedFiles.length > 0 && (
            <Documentslist
              UploadedFiles={uploadedFiles}
              smartContractId={smartContractId}
              clientId={clientId}
            />
          )}
        </div>
        <div className="col-12 text-right mt-4">
          <div className="">
            <Button
              type="primary"
              onClick={() => {
                props.goToPreviuos();
              }}
              className={`btn btn-outline-primary`}
              style={{ height: '42px' }}
            >
              Previous
            </Button>{' '}
            &nbsp;
            <Button
              type="primary"
              onClick={() => {
                props.handleNext();
              }}
              className={`btn save-button`}
              style={{ height: '42px' }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestedDocuments;
