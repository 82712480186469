import React from 'react';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { riskChartGet } from '../../store/actions';

import ClientRiskChart from './components/ClientRiskChart';
import ViewAnswersScreening from './components/ViewAnswersScreeningModal';
import ReactApexRiskChart from './components/ReactApexRiskChart';

class RiskChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskResponse: null,
      // barSeries: [
      //   {
      //     data: [0,50,100,0,100,50],
      //   },
      // ],
    };
  }
  getRisk = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
            IsDefaultPreIntakeSurvey: true,
          },
        ],
      }),
    };
    Axios.post('Survey/ClientRiskGet', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            riskResponse: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  componentDidMount() {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
          },
        ],
      }),
    };
    this.props.dispatch(riskChartGet(data));
    this.getRisk();
  }

  render() {
    const { viewHistory } = this.props;
    return (
      <div>
        {this.props.riskChartInfo && (
          <div class="card border-0 mb-3">
            <div class="card-body block">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <h6>Risk Factors</h6>
                  {viewHistory && (
                    <span
                      onClick={viewHistory}
                      style={{
                        color: '#009EF7',
                        cursor: 'pointer',
                        fontSize: '14px',
                        margin: '0px 5px',
                      }}
                    >
                      View History
                    </span>
                  )}
                </div>
                <div className="d-flex">
                  <ClientRiskChart
                    ClientId={this.props.ClientId}
                    ClientRisk={this.state.riskResponse}
                  />
                  <ViewAnswersScreening ClientId={this.props.ClientId} />
                </div>
              </div>

              <div>
                <ReactApexRiskChart riskChartInfo={this.props.riskChartInfo} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { risk_chart_get } = state;
  const { data: RiskChartData } = risk_chart_get;

  let riskChartInfo = null;

  if (RiskChartData && RiskChartData.RiskChart) {
    riskChartInfo = RiskChartData.RiskChart;
  }

  return {
    riskChartInfo,
  };
};

export default connect(mapStateToProps)(RiskChart);
