import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { resetLogin, logOut } from '../../store/actions';
import AntdModal from '../../Components/CustomModal';
import { userFeedback, removeUserFeedback, staffAccessedTabGet } from '../../store/actions';
import { Modal, Menu } from 'antd';
import DefaultCenter from '../defaultCenter';
import { LIST_ROUTES } from '../../store/menuConfig';
import AskYOUUniverse from '../../Screens/YOUUniverse';
import hostGrabber from '../../util/hostGrabber';

// const { SubMenu } = Menu;

// const URLs = {
//   0: 'dashboard',
//   1: 'patient-management',
//   // 2: 'bed-management',
//   2: 'appointments',
//   3: 'group-management',
//   4: 'claims',
//   5: 'accounting',
//   6: 'rcm-reports',
//   7: 'provider',
// };

// const menuURL = {
//   0: 'dashboard',
//   1: 'patient-management/patient-list',
//   // 2: 'bed-management?tab=bed-management',
//   2: 'appointments',
//   3: 'group-management/group-list',
//   4: 'claims?tab=service-line',
//   5: 'accounting?tab=payer',
//   6: 'rcm-reports',
//   7: 'provider/provider-list',
// };

// const cw_menu_obj = [
//   { menuKey: 0, text: "Dashboard" },
//   { menuKey: 1, text: "Patient Management" },
//   { menuKey: 2, text: "Appointments" },
//   { menuKey: 3, text: "Groups" },
//   { menuKey: 4, text: "Claims" },
//   { menuKey: 5, text: "Accounting" },
//   { menuKey: 6, text: "Reports" },
// ];

class SupportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myText: '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.myText !== this.state.myText) return true;
    return false;
  }

  render() {
    const { onOk, getUserFeedBack, onCancel } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 className="modal-title col-12">Please share your feedback here</h4>
        </div>
        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div className="form-group">
            <textarea
              className={`form-control newcontract-textarea`}
              type="text"
              placeholder="Feedback"
              rows="4"
              value={this.state.myText}
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ myText: newValue });
              }}
            />
          </div>
        </div>

        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            className="btn"
            onClick={() => {
              getUserFeedBack(this.state.myText);
              onOk();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

class CWMenu extends Component {
  state = { showSubMenu: false, showMenu: false };

  constructor(props) {
    super(props);

    // let val = (this.state.URLs && Object.values(this.state.URLs)) || [];

    // let urlKey = val.indexOf(props.location.pathname.replace('/cw/', ''));

    // let urlKey = val.indexOf(props.location.pathname.split('/')[2]);

    this.state = {
      menu: true,
      menus: [],
      centerName: '',
      userName: '',
      userRole: '',
      profileImgUrl: null,
      email: '',
      // current: urlKey >= 0 ? urlKey.toString() : '0',
      current: '0',
      URLs: {},
      menuURL: {},
    };
    var menu = localStorage.getItem('dr:menu');
    if (menu !== undefined) {
      this.state.menus = JSON.parse(menu);
    }

    localStorage.setItem('isWebCam', 1);
  }

  getActiveUrl = path => {
    const { pathname } = this.props.location;
    var exists = pathname.includes(path);
    if (exists > 0) return true;
    else return false;
  };

  onLogOut() {
    this.props.dispatch(resetLogin);
    this.props.dispatch(logOut({}));
    this.props.logOutBroadcaster();
    // localStorage.clear();
    // sessionStorage.clear();
    //this.props.history.push('/login');
    // window.location.href = '/';
    console.log('master4 menu=>logout executed');
  }

  componentDidMount() {
    // this.props.dispatch(staffAccessedTabGet({}));
    const me = this;
    var menu = JSON.parse(localStorage.getItem('dr:menu'));
    let inHouseMenu = [];
    if (menu) {
      for (let menuItem of menu) {
        if (menuItem.IsInHouse) {
          inHouseMenu.push(menuItem);
        }
      }
    }
    if (menu) {
      me.setState({
        menus: inHouseMenu,
        userName: localStorage.getItem('userName'),
        userRole: localStorage.getItem('userRole'),
        centerName: localStorage.getItem('centerName'),
        profileImgUrl: localStorage.getItem('profileImgUrl'),
        hasClinicalAccess: JSON.parse(localStorage.getItem('hasClinicalAccess')),
        email: localStorage.getItem('email'),
        adminUserInfo: localStorage.getItem('adminUserInfo'),
      });
    } else {
      /*Axios.post('user/ListMenu', {}).then(response => {
        localStorage.setItem('userName', response.data.UserName);
        localStorage.setItem('userRole', response.data.UserRole);
        localStorage.setItem('centerName', response.data.CenterName);
        localStorage.setItem('profileImgUrl', response.data.ProfileImgUrl);
        localStorage.setItem('email', response.data.Email);
        localStorage.setItem('adminUserInfo', JSON.stringify(response.data.AdminUserInfo));
        if (response.data.data) {
          for (let menuItem of response.data.data) {
            if (menuItem.IsInHouse) {
              inHouseMenu.push(menuItem);
            }
          }
        }
        me.setState({
          menus: inHouseMenu,
          userName: response.data.UserName,
          userRole: response.data.UserRole,
          centerName: response.data.CenterName,
          profileImgUrl: response.data.ProfileImgUrl,
          email: response.data.Email,
        });
      });*/
    }
  }

  componentWillReceiveProps({ CWTabs }) {
    if (CWTabs) {
      let tempUrls = {};
      let tempMenuURL = {};
      CWTabs.map((item, index) => {
        const { menuKey, url, menuUrl } = item || {};
        tempUrls[index] = url;
        tempMenuURL[index] = menuUrl;
      });
      let val = Object.values(tempUrls);
      let urlKey = val.indexOf(this.props.location.pathname.split('/')[2]);
      let tempCurrent = urlKey >= 0 ? urlKey.toString() : '0';
      // current: urlKey >= 0 ? urlKey.toString() : '0',
      this.setState({
        URLs: tempUrls,
        menuURL: tempMenuURL,
        current: tempCurrent,
        cwMenuState: CWTabs,
      });
    }
  }

  getUserFeedBack = Text => {
    this.props.dispatch(
      userFeedback({
        Notes: Text,
      }),
    );
  };
  handleMenuClick = e => {
    this.props.history.push(`/cw/${this.state.menuURL[e.key]}`);
  };
  render() {
    const {
      showSubMenu,
      showMenu,
      userName,
      menus,
      userRole,
      profileImgUrl,
      current,
      hasClinicalAccess,
      cwMenuState,
    } = this.state;
    const { CustomLogo, isFetching, isGlobalChatEnabled } = this.props;
    return (
      <header className="header bg-white">
        <nav className="navbar navbar-light navbar-expand-md static-top pl-0 pr-0">
          <div className="container-fluid new-header-css">
            {/* <a className="navbar-brand" title="Sober peer">
              <img src="images/logo.svg" />
            </a> */}
            <Link className="navbar-brand" to={`${this.props.defaultDashboard}`}>
              <img
                src={
                  !isFetching &&
                  (CustomLogo
                    ? `images/${CustomLogo}`
                    : hostGrabber()
                    ? 'images/youulogo.png'
                    : 'images/logo.png')
                }
                width={hostGrabber() ? !CustomLogo && '150px' : !CustomLogo && '48px'}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() =>
                this.setState(prevState => {
                  return { showMenu: !prevState.showMenu };
                })
              }
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse header-nav justify-content-end ${showMenu &&
                'show'} align-item-center`}
              id="navbarResponsive"
            >
              <ul
                className="navbar-nav mr-auto  align-items-md-center"
                // style={{ visibility: 'hidden' }}
                style={{ display: 'none' }}
              >
                {menus.map(menuItem => {
                  if (menuItem.Text && !menuItem.hideMenu) {
                    return (
                      <li
                        className={`nav-item ${this.getActiveUrl(menuItem.Path) ? 'active' : ''}`}
                      >
                        <Link className="nav-link" to={menuItem.Path}>
                          {menuItem.Text} <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
              {isGlobalChatEnabled && <AskYOUUniverse />}
              <div className="d-flex navbar-nav  align-items-center">
                <div className="nav-item">
                  <div className="dashboard-center">
                    <span
                      style={{
                        fontWeight: '600',
                        margin: '0px 10px',
                        // borderLeft: '2px solid #D8D8D8',
                        paddingLeft: '10px',
                      }}
                    >
                      Center:
                    </span>
                    <DefaultCenter
                    // initialValues={ { centerId: 2 } }
                    />
                  </div>
                </div>
                <div
                  className="nav-item dropdown user-info"
                  onMouseEnter={() => this.setState({ showSubMenu: true })}
                  onMouseLeave={() => this.setState({ showSubMenu: false })}
                >
                  <a className="nav-link dropdown-toggle d-flex align-items-center">
                    <img
                      src={
                        profileImgUrl && profileImgUrl != 'null'
                          ? profileImgUrl
                          : 'images/blank-profile.png'
                      }
                      className="rounded-circle face-fit-cover"
                      width="47"
                      height="47"
                      alt=""
                    />
                    <span className="ml-2">
                      {userName}
                      <span className="user-name">{userRole}</span>
                    </span>
                  </a>
                  <div
                    className={`dropdown-menu ${showSubMenu && 'show'}`}
                    style={{ marginTop: '-5px' }}
                  >
                    {hasClinicalAccess && (
                      <Link
                        className="dropdown-item"
                        to={`${this.props.dashboardEnterprise}`}
                        style={{ background: '#4ab0fe', color: '#fff' }}
                      >
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L1 12L7.5 12L8.5 12M12 12L10.5 12"
                            stroke="white"
                            stroke-linecap="round"
                          />
                          <path
                            d="M11.4114 6.64401C11.1659 7.22444 10.8514 7.66725 10.2933 8.1337C9.69674 8.63223 9.26731 8.87307 8.5 8.99805C7.82148 9.10856 7.40229 9.04423 6.74711 8.83605C6.05387 8.61577 5.59375 8.32805 5.1456 7.88499C4.69746 7.44194 4.36466 6.89584 4.17633 6.29446C3.98799 5.69307 3.94983 5.0547 4.0652 4.43517C4.18057 3.81563 4.44595 3.23379 4.83813 2.7405C5.23031 2.24722 5.73737 1.85752 6.31495 1.60547"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 4.858V1C8.50664 1 9.00832 1.09979 9.47639 1.29367C9.94446 1.48755 10.3698 1.77173 10.728 2.12998C11.0863 2.48823 11.3704 2.91353 11.5643 3.3816C11.7582 3.84968 11.858 4.35136 11.858 4.858H10"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{' '}
                        Switch to Enterprise
                      </Link>
                    )}
                    <Link className="dropdown-item" to="/settings">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M7.742 10.934a.742.742 0 1 1 0-1.485h8.647a.742.742 0 1 1 0 1.485H7.742zm13.1 2.412a2.23 2.23 0 0 1-2.227-2.227V9.227A2.23 2.23 0 0 1 20.842 7a2.23 2.23 0 0 1 2.226 2.227v.222h2.19a.742.742 0 1 1 0 1.485h-2.19v.185a2.23 2.23 0 0 1-2.226 2.227zm-.742-4.12v1.893a.743.743 0 0 0 1.484 0V9.227a.743.743 0 0 0-1.484 0zm-3.713 12.878a.742.742 0 1 1 0 1.484H7.742a.742.742 0 1 1 0-1.484h8.645zm-8.645-4.866a.742.742 0 1 1 0-1.485h2.115a.742.742 0 1 1 0 1.485H7.742zm17.516-1.485a.742.742 0 0 1 0 1.485h-8.72v.19a2.23 2.23 0 0 1-2.227 2.226 2.23 2.23 0 0 1-2.227-2.226v-1.93a2.23 2.23 0 0 1 2.227-2.227 2.23 2.23 0 0 1 2.226 2.227v.255h8.72zm-10.205 1.675v-1.93a.743.743 0 0 0-1.485 0v1.93a.743.743 0 0 0 1.485 0zm10.205 4.676a.742.742 0 1 1 0 1.484h-2.191v.185A2.23 2.23 0 0 1 20.84 26a2.23 2.23 0 0 1-2.226-2.227v-1.892a2.23 2.23 0 0 1 2.226-2.227 2.23 2.23 0 0 1 2.227 2.227v.223h2.19zm-3.676 1.67V21.88a.743.743 0 0 0-1.484 0v1.892a.743.743 0 0 0 1.484 0z"
                        />
                      </svg>
                      Settings
                    </Link>
                    <a
                      className="dropdown-item"
                      href="https://support.soberpeer.com"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M15.96 21.664a.781.781 0 1 1 0-1.562.781.781 0 0 1 0 1.562zm.782-3.516V17.1a3.256 3.256 0 0 0 2.498-3.285c-.065-1.688-1.45-3.06-3.152-3.124a3.276 3.276 0 0 0-2.419.917 3.199 3.199 0 0 0-.99 2.323.781.781 0 1 0 1.563 0c0-.455.182-.88.511-1.198.341-.328.794-.499 1.276-.48.891.033 1.616.745 1.65 1.622a1.695 1.695 0 0 1-1.341 1.715 1.46 1.46 0 0 0-1.158 1.433v1.126a.781.781 0 1 0 1.562 0zm4.432 6.41a.781.781 0 1 0-.81-1.336A8.423 8.423 0 0 1 16 24.437c-4.652 0-8.438-3.785-8.438-8.437 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.437 3.786 8.437 8.438a8.404 8.404 0 0 1-1.43 4.683.781.781 0 0 0 1.299.868A9.96 9.96 0 0 0 26 16a9.935 9.935 0 0 0-2.929-7.071A9.935 9.935 0 0 0 16 6a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 6 16a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 16 26a9.984 9.984 0 0 0 5.174-1.441z"
                        />
                      </svg>
                      Help
                    </a>

                    <span className="dropdown-item">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M15.96 21.664a.781.781 0 1 1 0-1.562.781.781 0 0 1 0 1.562zm.782-3.516V17.1a3.256 3.256 0 0 0 2.498-3.285c-.065-1.688-1.45-3.06-3.152-3.124a3.276 3.276 0 0 0-2.419.917 3.199 3.199 0 0 0-.99 2.323.781.781 0 1 0 1.563 0c0-.455.182-.88.511-1.198.341-.328.794-.499 1.276-.48.891.033 1.616.745 1.65 1.622a1.695 1.695 0 0 1-1.341 1.715 1.46 1.46 0 0 0-1.158 1.433v1.126a.781.781 0 1 0 1.562 0zm4.432 6.41a.781.781 0 1 0-.81-1.336A8.423 8.423 0 0 1 16 24.437c-4.652 0-8.438-3.785-8.438-8.437 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.437 3.786 8.437 8.438a8.404 8.404 0 0 1-1.43 4.683.781.781 0 0 0 1.299.868A9.96 9.96 0 0 0 26 16a9.935 9.935 0 0 0-2.929-7.071A9.935 9.935 0 0 0 16 6a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 6 16a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 16 26a9.984 9.984 0 0 0 5.174-1.441z"
                        />
                      </svg>

                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <a
                            onClick={() => {
                              this.setState({ showSubMenu: false });
                              onClick();
                            }}
                          >
                            Support/Feedback
                          </a>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <SupportForm
                            onOk={onOk}
                            onCancel={onCancel}
                            getUserFeedBack={this.getUserFeedBack}
                          />
                        )}
                      />
                    </span>
                    <Modal
                      width={600}
                      visible={this.props.modalOpen}
                      footer={null}
                      closeIcon={null}
                      onCancel={() => this.props.dispatch(removeUserFeedback())}
                      closable={false}
                    >
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => this.props.dispatch(removeUserFeedback())}
                          >
                            &times;
                          </button>
                          <h4 className="modal-title col-12">Thank you for the feedback.</h4>
                        </div>
                        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
                          <div className="form-group" />
                        </div>

                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            className="btn"
                            onClick={() => this.props.dispatch(removeUserFeedback())}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </Modal>
                    <a className="dropdown-item" onClick={this.onLogOut.bind(this)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <g fill="#9A9A9A" fill-rule="nonzero">
                          <path d="M15.521 6.388l-1.509 2.14a.586.586 0 0 0 .48.924h.922v10.275a.586.586 0 0 0 1.172 0V9.452h.923c.477 0 .75-.539.479-.924l-1.51-2.14a.587.587 0 0 0-.957 0z" />
                          <path d="M19.762 9.325a.586.586 0 0 0 .214.801 7.952 7.952 0 0 1 3.977 6.89c0 4.385-3.568 7.953-7.953 7.953S8.047 21.4 8.047 17.016a7.952 7.952 0 0 1 3.977-6.89.586.586 0 0 0-.587-1.014 9.124 9.124 0 0 0-4.562 7.904A9.12 9.12 0 0 0 16 26.14a9.12 9.12 0 0 0 9.125-9.125 9.124 9.124 0 0 0-4.562-7.904.586.586 0 0 0-.8.213z" />
                        </g>
                      </svg>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="row mx-0">
          <div className="col-12 d-flex justify-content-center">
            <Menu
              onClick={this.handleMenuClick}
              selectedKeys={[current]}
              mode="horizontal"
              className="cw-menu"
            >
              {cwMenuState &&
                cwMenuState.map((item, index) => {
                  const { menuKey, text, menuUrl } = item;
                  return (
                    <Menu.Item key={index.toString()}>
                      <a href={`/#/cw/${menuUrl}`}>{text}</a>
                    </Menu.Item>
                  );
                })}

              {/* <Menu.Item key="0">Dashboard</Menu.Item>
              <Menu.Item key="1">Patient Management</Menu.Item>
              <Menu.Item key="2">Appointments</Menu.Item>
              <Menu.Item key="3">Groups</Menu.Item>
              <Menu.Item key="4">Claims</Menu.Item>
              <Menu.Item key="5">Accounting</Menu.Item>
              <Menu.Item key="6">Reports</Menu.Item> */}

              {/* <Menu.Item key="8">Provider</Menu.Item> */}
            </Menu>
          </div>
        </div>

        {/* <ul style={{ display: 'flex', justifyContent: 'space-around', listStyleType: 'none' }}>
          <li>
            <Link to="/cw/appointments">Appointments</Link>
          </li>
          <li>
            <Link to="/cw/patient-management/patient-list">Patient Management</Link>
          </li>
          <li>
            <Link to="/cw/group-management/group-list">Group</Link>
          </li>
          <li>
            <Link to="/cw/claims?tab=service-line">Claims</Link>
          </li>
          <li>
            <Link to="/cw/accounting?tab=fee-schedule">Accounting</Link>
          </li>
          
        </ul> */}
      </header>
    );
  }
}

const mapStateToProps = ({ user_feedback, default_values, staff_accessed_tab_get }) => {
  const { data, isFetching } = default_values;
  const { data: accessedTab } = staff_accessed_tab_get;
  let defaultDashboard;
  let isGlobalChatEnabled;
  let dashboardEnterprise;
  let CustomLogo;
  let CWTabs = null;

  if (data) {
    defaultDashboard = data.defaultDashboard;
    isGlobalChatEnabled = data.IsGlobalChatEnabled;
    dashboardEnterprise = data.dashboardEnterprise;
    CustomLogo = data.CustomLogo;
  }
  var modalOpen = false;
  if (user_feedback && user_feedback.data) {
    modalOpen = (user_feedback && user_feedback.data && user_feedback.data.success) || false;
  }

  if (accessedTab && accessedTab.Tabs) {
    CWTabs = accessedTab.Tabs[0].CWTabs;
  }
  return {
    modalOpen,
    defaultDashboard,
    dashboardEnterprise,
    CustomLogo,
    isFetching,
    CWTabs,
    isGlobalChatEnabled,
  };
};

export default withRouter(connect(mapStateToProps)(CWMenu));
