import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* listCombos(action) {
  try {
    const response = yield API.listCombos(action.payload);
    yield put({
      type: ActionTypes.COMBOS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_FAILURE,
    });
  }
}

export function* listFilterCombo(action) {
  try {
    const response = yield API.listFilterCombo(action.payload);
    var comboType = action.payload.comboTypes;
    var filterComboType = 'Filter_' + comboType;
    var filterBy = action.payload.filterBy;
    var filterResponse = {
      [filterComboType]: { [filterBy]: response.data.data[comboType] },
      mapper: filterComboType,
    };
    yield put({
      type: ActionTypes.FILTER_COMBO_SUCCESS,
      payload: filterResponse,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_FAILURE,
    });
  }
}

export function* listSmartCombos(action) {
  try {
    const response = yield API.listSmartCombos(action.payload);
    yield put({
      type: ActionTypes.COMBOS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_FAILURE,
    });
  }
}

export function* listFullCombos(action) {
  try {
    const response = yield API.listCombos(action.payload);
    yield put({
      type: 'COMBOS_FULL_SUCCESS',
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_FAILURE,
    });
  }
}
export function* listMobileAppRoleGet(action) {
  try {
    const response = yield API.listMobileAppRoleGet(action.payload);
    yield put({
      type: ActionTypes.GET_LIST_MOBILE_APP_ROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LIST_MOBILE_APP_ROLE_FAILURE,
    });
  }
}
export function* spListCombos(action) {
  try {
    const response = yield API.spListCombos(action.payload);
    yield put({
      type: ActionTypes.SP_LIST_COMBOS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SP_LIST_COMBOS_FAILURE,
    });
  }
}
export function* addNoteType(action) {
  try {
    const response = yield API.addNoteType(action.payload);
    yield put({
      type: ActionTypes.ADD_NOTE_TYPE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_NOTE_TYPE_FAILURE,
    });
  }
}
export function* getDynamicComos(action) {
  try {
    const response = yield API.getDynamicComos(action.payload);
    yield put({
      type: ActionTypes.COMBOS_DYNAMIC_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_DYNAMIC_FAILURE,
    });
  }
}
export function* medicateCombos(action) {
  try {
    const response = yield API.medicateCombos(action.payload);
    yield put({
      type: ActionTypes.COMBOS_MEDICATE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBOS_MEDICATE_FAILURE,
    });
  }
}
export function* getDiagnosis(action) {
  try {
    const response = yield API.getDiagnosis(action.payload);
    yield put({
      type: ActionTypes.GET_DIAGNOSIS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DIAGNOSIS_FAILURE,
    });
  }
}
export function* getDiagnosisCode(action) {
  try {
    const response = yield API.getDiagnosisCode(action.payload);
    yield put({
      type: ActionTypes.GET_DIAGNOSIS_CODE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DIAGNOSIS_CODE_FAILURE,
    });
  }
}
export function* comboPlaceOfService(action) {
  try {
    const response = yield API.comboPlaceOfService(action.payload);
    yield put({
      type: ActionTypes.COMBO_PLACE_OF_SERVICE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_PLACE_OF_SERVICE_FAILURE,
    });
  }
}
export function* comboDiagnosisList(action) {
  try {
    const response = yield API.comboDiagnosisList(action.payload);
    yield put({
      type: ActionTypes.COMBO_DIAGNOSIS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_DIAGNOSIS_LIST_FAILURE,
    });
  }
}
export function* comboSupervisor(action) {
  try {
    const response = yield API.comboSupervisor(action.payload);
    yield put({
      type: ActionTypes.COMBO_SUPERVISOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_SUPERVISOR_FAILURE,
    });
  }
}
export function* comboAppAdvisor(action) {
  try {
    const response = yield API.comboAppAdvisor(action.payload);
    yield put({
      type: ActionTypes.COMBO_APP_ADVISOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_APP_ADVISOR_FAILURE,
    });
  }
}
export function* centerOptionGet(action) {
  try {
    const response = yield API.centerOptionGet(action.payload);
    yield put({
      type: ActionTypes.CENTER_OPTION_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_OPTION_GET_FAILURE,
    });
  }
}

export function* comboPractitionerCenterGet(action) {
  try {
    const response = yield API.comboPractitionerCenterGet(action.payload);
    yield put({
      type: ActionTypes.COMBO_PRACTITIONER_CENTER_GET_SUCCESS,
      payload: JSON.parse(response.data.result)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_PRACTITIONER_CENTER_GET_FAILURE,
    });
  }
}

export function* comboServiceCode(action) {
  try {
    const response = yield API.comboServiceCode(action.payload);
    yield put({
      type: ActionTypes.COMBO_SERVICE_CODE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_SERVICE_CODE_FAILURE,
    });
  }
}

export function* comboServiceCodeGet(action) {
  try {
    const response = yield API.comboServiceCodeGet(action.payload);
    yield put({
      type: ActionTypes.COMBO_SERVICE_CODE_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COMBO_SERVICE_CODE_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.SMARTCOMBOS_REQUEST, listSmartCombos),
    takeLatest(ActionTypes.COMBOS_REQUEST, listCombos),
    takeLatest(ActionTypes.FILTER_COMBO_REQUEST, listFilterCombo),
    takeLatest('COMBOS_FULL_REQUEST', listFullCombos),
    takeLatest(ActionTypes.SP_LIST_COMBOS_REQUEST, spListCombos),
    takeLatest(ActionTypes.GET_LIST_MOBILE_APP_ROLE_REQUEST, listMobileAppRoleGet),
    takeLatest(ActionTypes.ADD_NOTE_TYPE_REQUEST, addNoteType),
    takeLatest(ActionTypes.COMBOS_DYNAMIC_REQUEST, getDynamicComos),
    takeLatest(ActionTypes.COMBOS_MEDICATE_REQUEST, medicateCombos),
    takeLatest(ActionTypes.GET_DIAGNOSIS_REQUEST, getDiagnosis),
    takeLatest(ActionTypes.GET_DIAGNOSIS_CODE_REQUEST, getDiagnosisCode),
    takeLatest(ActionTypes.COMBO_PLACE_OF_SERVICE_REQUEST, comboPlaceOfService),
    takeLatest(ActionTypes.COMBO_DIAGNOSIS_LIST_REQUEST, comboDiagnosisList),
    takeLatest(ActionTypes.COMBO_SUPERVISOR_REQUEST, comboSupervisor),
    takeLatest(ActionTypes.COMBO_APP_ADVISOR_REQUEST, comboAppAdvisor),
    takeLatest(ActionTypes.CENTER_OPTION_GET_REQUEST, centerOptionGet),
    takeLatest(ActionTypes.COMBO_PRACTITIONER_CENTER_GET_REQUEST, comboPractitionerCenterGet),
    takeLatest(ActionTypes.COMBO_SERVICE_CODE_REQUEST, comboServiceCode),
    takeLatest(ActionTypes.COMBO_SERVICE_CODE_GET_REQUEST, comboServiceCodeGet),
  ]);
}
