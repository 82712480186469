import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import {
  ReduxInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxPhoneInput,
} from '../../../../../../component/custom-redux-components';
import SubmitButton from '../../../../../../Components/submitButton';
import Validator from '../../../../../Validation';
// import { CenterOption } from './centerOption';
// import { CareTeamMemberOption } from './careTeamMemberOption';
import {
  organizationCenterFamilyCombo,
  comboServiceCode,
  careTeamMemberCombo,
  resetCareTeamMemberCombo,
  externalProviderListGet,
  externalProviderGet,
  externalProviderUpsert,
  getComboFacility,
} from '../../../../../../store/actions';
import validate from '../../../../validation/validate';
import { ProcessCombos, ProcessFilterCombos } from '../../../../../../store/comboConfig';

class ExternalCareTeamMember extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    var result = ProcessCombos('Gender,Filter_StateProvince,Country,ExternalProviderSpecialtyType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(getComboFacility({}));
  }
  // componentWillReceiveProps({ careTeamTypeValue }) {}

  render() {
    const {
      handleSubmit,
      onSaveClick,
      disable,
      style,
      careTeamCenter,
      careTeamMember,
      comboServiceCodelist,
      isFetchingUpsert,
      onClose,
      initialValues: { ExternalProviderId },
      Gender,
      Country,
      Filter_StateProvince,
      PractitionerOffice,
      ExternalProviderSpecialtyType,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(onSaveClick)} style={style}>
        <div className="row enrollment-form mt-2">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <Field
                name="FirstName"
                type="text"
                component={ReduxInput}
                errorMessage="First Name Required"
                fieldName={'First Name'}
                validate={Validator.required}
              />
              <label class="placeholder-label">First Name*</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <Field
                name="LastName"
                type="text"
                component={ReduxInput}
                errorMessage="Last Name Required"
                fieldName={'Last Name'}
                validate={Validator.required}
              />
              <label class="placeholder-label">Last Name*</label>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`Gender`}
                type="text"
                component={ReduxSelect}
                placeholder="Gender*"
                options={Gender}
                // validate={Validator.required}
                fieldName={'Gender'}
                disabled={disable}
                // formatOptionLabel={option => <CareTeamMemberOption OptionData={option} />}
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <Field
                name="EmailWork"
                type="text"
                component={ReduxInput}
                errorMessage="Email Id"
                fieldName={'Email Id'}
                validate={Validator.required}
              />
              <label class="placeholder-label">Email Id*</label>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`PhoneNumber`}
                component={ReduxPhoneInput}
                validate={[Validator.isValidMobile, Validator.required]}
                // options={country}
                // validate={validate.required}
                placeholder="Phone Number*"
                fieldName={'Phone Number'}
              />
            </div>
          </div>
          {/* <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`CountryISO`}
                type="text"
                component={ReduxSelect}
                placeholder="Country*"
                options={Country}
                validate={Validator.required}
                fieldName={'Country'}
                disabled={disable}
                // formatOptionLabel={option => <CareTeamMemberOption OptionData={option} />}
              />
            </div>
          </div> */}
          <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`State`}
                type="text"
                component={ReduxSelect}
                placeholder="State*"
                options={Filter_StateProvince}
                validate={Validator.required}
                fieldName={'State'}
                disabled={disable}
                // formatOptionLabel={option => <CareTeamMemberOption OptionData={option} />}
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <Field
                name="City"
                type="text"
                component={ReduxInput}
                validate={Validator.required}
                fieldName={'City'}
              />
              <label class="placeholder-label">City*</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <Field
                name="ZipCode"
                type="text"
                component={ReduxInput}
                errorMessage="Zip Code Required"
                fieldName={'Zip Code'}
                validate={[Validator.usaZip, Validator.required]}
              />
              <label class="placeholder-label">Zip Code*</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <Field
                name="NPI"
                type="text"
                component={ReduxInput}
                errorMessage="NPI Required"
                fieldName={'NPI'}
                validate={[Validator.allowNumericNPI, Validator.required]}
              />
              <label class="placeholder-label">NPI*</label>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="form-group">
              <Field
                name={`Specialty`}
                type="text"
                component={ReduxSelect}
                placeholder="Specialty*"
                options={ExternalProviderSpecialtyType}
                validate={Validator.required}
                fieldName={'Specialty'}
                disabled={disable}
                isMulti
              />
              {/* <Field
                name="Specialty"
                type="text"
                component={ReduxInput}
                errorMessage="Specialty Required"
                fieldName={'Specialty'}
                validate={Validator.required}
              />
              <label class="placeholder-label">Specialty*</label> */}
            </div>
          </div>
          {/* <div class="col-12 col-md-12">
            <div class="form-group">
              <Field
                name="FacilityName"
                type="text"
                component={ReduxInput}
                errorMessage="Facility Name Required"
                fieldName={'Facility Name'}
                validate={Validator.required}
              />
              <label class="placeholder-label">Facility Name*</label>
            </div>
          </div> */}

          {!disable && (
            <div className="col-12 text-right">
              <button
                className="btn btn-outline-primary px-5 mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>

              <SubmitButton
                // value={ClientCareTeamId ? 'Save' : 'Add'}
                value={'Save'}
                loading={isFetchingUpsert}
                style={{
                  height: '44px',
                  width: '100px',
                }}
              />
            </div>
          )}
        </div>
      </form>
    );
  }
}
const ReduxExternalCareTeamMember = reduxForm({
  form: 'ExternalCareTeamForm',
  enableReinitialize: true,
})(connect()(withRouter(ExternalCareTeamMember)));

const mapStateToProps = state => {
  const {
    organization_center_family_combo,
    care_team_member_combo,
    combo_service_code,
    combos,
    get_combo_facility,
  } = state;
  const { data: careTeamCenterData } = organization_center_family_combo;
  const { data: careTeamMemberData } = care_team_member_combo;
  const { data: comboServiceCodeData } = combo_service_code;
  const { data: ComboFacilityData } = get_combo_facility;

  let careTeamCenter = [];
  let careTeamMember = [];
  let comboServiceCodelist = [];
  let Gender = [],
    Country = [],
    PractitionerOffice = [],
    ExternalProviderSpecialtyType = [],
    Filter_StateProvince = [];
  const CountryISO = formValueSelector('ExternalCareTeamForm')(state, 'Country') || 'USA';

  if (careTeamCenterData && careTeamCenterData.Center) {
    careTeamCenter = careTeamCenterData.Center;
  }
  if (careTeamMemberData && careTeamMemberData.CareTeam) {
    careTeamMember = careTeamMemberData.CareTeam;
  }
  if (comboServiceCodeData && comboServiceCodeData.ServiceCode) {
    comboServiceCodelist = comboServiceCodeData.ServiceCode;
  }
  if (combos.data && combos.data['Gender']) {
    ExternalProviderSpecialtyType = combos.data['ExternalProviderSpecialtyType'];
    Gender = combos.data['Gender'];
    Country = combos.data['Country'];
    Filter_StateProvince = combos.data['Filter_StateProvince'][CountryISO];
  }
  if (ComboFacilityData && ComboFacilityData.Facility) {
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  return {
    careTeamCenter,
    careTeamMember,
    comboServiceCodelist,
    Gender,
    Country,
    Filter_StateProvince,
    PractitionerOffice,
    ExternalProviderSpecialtyType,
  };
};
export default connect(mapStateToProps)(ReduxExternalCareTeamMember);
