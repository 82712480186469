import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Progress, Tooltip, Popover } from 'antd';
import GoogleMapReact from 'google-map-react';
import AugmentedSummary from '../../EnterpriseClient/AugmentedSummary';
import { Loader2 } from '../../../Components/Loader';
import Twilio from '../../../Components/twilio';
import { AnyReactComponent } from '../../staffProfileComponents/concernedPersonLocation';

class SubscriberReelInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callModelVisible: false,
      clickedRecord: {},
    };
  }

  componentDidMount() {
    // this.props.dispatch(
    //   entMobileUserDashboardGet({
    //     Json: JSON.stringify({
    //       Member: [{ UserGuid: this.props.UserGuid }],
    //     }),
    //   }),
    // );

    let data = {
      Location: [{ UserGuid: this.props.UserGuid }],
    };

    // this.props.dispatch(appUserLiveLocationGet({ Json: JSON.stringify(data) }));
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  render() {
    const { subscriberData, isDataFetching, UserGuid, currentTabIndex, totalTabCount } = this.props;
    const {
      TrendLevel,
      Survey,
      Service,
      ProfileImageUrl,
      Phone,
      Mood,
      MemberName,
      Location,
      LastTrendUpdateDateForSort,
      IsRedColor,
      DisplayName,
    } = subscriberData || {};
    const { Latitude, Longitude } = Location ? Location[0] : {};
    const location = {
      Latitude: Location && Location[0].Latitude,
      Longitude: Location && Location[0].Longitude,
      ProfileImgUrl: ProfileImageUrl && ProfileImageUrl,
      DisplayName: MemberName && MemberName,
    };
    const STYLES = {
      img: { width: '30px', height: '30px', cursor: 'pointer', height: '37px' },
      imgDisabled: { width: '30px', height: '30px', cursor: 'not-allowed', height: '37px' },
    };
    const enrollContent = (
      <div className="sort-by-list">
        <p
          onClick={
            () => {
              if (Phone) {
                return this.setState({
                  callModelVisible: true,
                  clickedRecord: subscriberData || {},
                });
              }
            }
            // this.setState({ callModelVisible: true, clickedRecord: subscriberData || {} })
          }
        >
          Call {`${!Phone ? '(Not Available)' : ''}`}
        </p>
        <p
          onClick={() =>
            this.props.history.push(
              `/subscriberProfile/${UserGuid}?tab_name=activities&stab_name=task`,
            )
          }
        >
          Assign Task
        </p>

        <p
          onClick={() =>
            this.props.history.push(
              `/subscriberProfile/${UserGuid}?tab_name=activities&stab_name=survey`,
            )
          }
        >
          Assign Survey
        </p>
        <p
          onClick={() =>
            this.props.history.push(
              `/subscriberProfile/${UserGuid}?tab_name=activities&stab_name=course`,
            )
          }
        >
          Assign Course
        </p>

        <p
          onClick={() =>
            this.props.history.push(
              `/subscriberProfile/${UserGuid}?tab_name=journal&stab_name=addnote`,
            )
          }
        >
          Add Note
        </p>
      </div>
    );
    return (
      <div className="client-reel-subscriber p-3" style={{position: 'relative'}}>
        <Loader2 loading={isDataFetching} />
        <div className="pagination mb-3">
          <h5>Last Updates</h5>
          <div className="d-flex align-items-center">
            <Popover
              overlayClassName="alert-box-style"
              content={enrollContent}
              title={false}
              trigger="hover"
              placement="bottom"
              class="alert-sort"
            >
              <button
                className="btn btn-outline-primary mr-2"
                style={{ display: 'flex', alignItems: 'center', height: '37px' }}
              >
                Actions <img src="images/icons/3dot_blue.svg" className="mx-2" />
              </button>
            </Popover>
            <div>
              {currentTabIndex != 1 ? (
                <Tooltip placement="top" title="Previous">
                  <img
                    src="images/prev_enabled.svg"
                    onClick={() => this.props.goToPrev()}
                    style={STYLES.img}
                    className="mr-2"
                  />
                </Tooltip>
              ) : (
                <img src="images/prev_disabled.svg" style={STYLES.imgDisabled} className="mr-2" />
              )}
            </div>

            <div>
              {currentTabIndex != totalTabCount ? (
                <Tooltip placement="top" title="Next">
                  <img
                    src="images/next_enabled.svg"
                    onClick={() => this.props.goToNext()}
                    style={STYLES.img}
                  />
                </Tooltip>
              ) : (
                <img src="images/next_disabled.svg" style={STYLES.imgDisabled} />
              )}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">
          {/* {UserGuid && <AugmentedSummary UserGuid={UserGuid} isEnrolled={true} />} */}
          <AugmentedSummary UserGuid={UserGuid} isEnrolled={true} />

          <div className="d-flex row">
            <div className="col-12 col-md-6">
              <div>
                {Mood ? (
                  Mood.map((item, index) => {
                    const { CreatedDate, ImageUrl, Mood, Reason } = item;
                    return (
                      <div
                        style={{ backgroundColor: 'white', borderRadius: '7px' }}
                        className="p-3 d-flex justify-content-between align-items-center mb-3"
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <img src={ImageUrl} alt="Mood" />
                          <div className="ml-3">
                            <h6 className="mb-0">{Reason}</h6>
                            <p className="mb-0">{Mood}</p>
                            <p className="mb-0">{CreatedDate}</p>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: '#E8E8E8',
                            padding: '10px',
                            height: 'fit-content',
                            cursor: 'pointer',
                          }}
                          className="d-flex justify-content-center align-items-center"
                          onClick={() =>
                            this.props.history.push(
                              `/timetravel/?subscriberId=${UserGuid}&TTFilter=26`,
                            )
                          }
                        >
                          <img
                            src="images/timeline/timeline.svg"
                            style={{ width: '19px', height: '18px' }}
                            alt="timeline"
                          />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center mb-3"
                    style={{ backgroundColor: '#F8F8F8', height: '102px', borderRadius: '7px' }}
                  >
                    <p className="mb-0 text-center" style={{ color: '#9A9A9A' }}>
                      No Emotions Data have been Selected Yet
                    </p>
                  </div>
                )}
              </div>
              {Survey && Survey.length > 0 ? (
                Survey.map((item, index) => {
                  const { TotalScore, Description, CompletedDate } = item;
                  return (
                    <div
                      style={{ backgroundColor: 'white', borderRadius: '7px' }}
                      className="p-3 d-flex justify-content-between align-items-center"
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <Progress
                          type="circle"
                          strokeWidth={8}
                          width={60}
                          strokeColor="#ff5d5d"
                          percent={TotalScore || 0}
                          format={percent => (
                            <div class="progress-textbox" style={{ fontSize: '13px' }}>
                              <strong style={{ fontSize: '16px' }}>{percent}</strong>
                              score
                            </div>
                          )}
                          trailColor={'#fef2f2'}
                        />
                        <div className="ml-3">
                          <h6 className="mb-0">{Description}</h6>
                          <p className="mb-0">{CompletedDate}</p>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: '#E8E8E8',
                          padding: '10px',
                          height: 'fit-content',
                          cursor: 'pointer',
                        }}
                        className="d-flex justify-content-center align-items-center"
                        onClick={() =>
                          this.props.history.push(
                            `/timetravel/?subscriberId=${UserGuid}&TTFilter=3`,
                          )
                        }
                      >
                        <img
                          src="images/timeline/timeline.svg"
                          style={{ width: '19px', height: '18px' }}
                          alt="timeline"
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: '#F8F8F8', height: '102px', borderRadius: '7px' }}
                >
                  <p className="mb-0 text-center" style={{ color: '#9A9A9A' }}>
                    No Surveys or Assessments have been <br /> Conducted to Calculate the Score.
                  </p>
                </div>
              )}
            </div>
            <div className="client-reel-map col-12 col-md-6">
              {Latitude && Longitude ? (
                <div className="concerned-person-location" style={{ height: '500px' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo' }}
                    defaultCenter={{
                      lat: Latitude,
                      lng: Longitude,
                    }}
                    defaultZoom={11}
                  >
                    <AnyReactComponent
                      lat={Latitude}
                      lng={Longitude}
                      name={MemberName}
                      image={ProfileImageUrl}
                    />
                  </GoogleMapReact>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center mb-3"
                  style={{ backgroundColor: '#F8F8F8', height: '100%', borderRadius: '7px' }}
                >
                  <p className="mb-0 text-center" style={{ color: '#9A9A9A' }}>
                    No location available
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ ent_mobile_user_dashboard_get }) => {
  const { data: subsData, isFetching: isDataFetching } = ent_mobile_user_dashboard_get;

  let subscriberData = {};
  if (subsData && JSON.parse(subsData.data)) {
    subscriberData = subsData && JSON.parse(subsData.data) && JSON.parse(subsData.data).Data && JSON.parse(subsData.data).Data[0];
  }

  return { subscriberData, isDataFetching };
};

export default connect(mapStateToProps)(withRouter(SubscriberReelInfo));
