import React from 'react';
import { Avatar, Image } from 'antd';
import blank_user from '../../../../../images/blank-profile.png';

class BedComponent extends React.Component {
  render() {
    const { bed } = this.props;
    const {
      CenterRehabRoomBedId,
      bedNumber,
      bedStatus,
      daysToDischarge,
      patientName,
      patientImage,
    } = bed;
    let middleText;

    switch (bedStatus) {
      case 'available':
        middleText = 'BOOK NOW';
        break;
      case 'occupied':
        middleText = '';
        break;
      case 'reserved':
        middleText = 'Reserved';
        break;
      case 'notavailable':
        middleText = 'Out Of Order';
        break;

      default:
    }

    return (
      <React.Fragment>
        <div className={`${bedStatus} bed-card`}>
          <div className="col-md-12 d-flex justify-content-between pt-2 pb-4">
            <span className="font-weight-bold">Bed&nbsp;{bedNumber}</span>
            <span className={`d-flex align-items-baseline ${bedStatus}Status `}>
              {' '}
              <img
                src={`images/bed/${bedStatus}.svg`}
                style={{
                  width: '22px',
                  height: '14px',
                }}
              />
              &nbsp;
              {bedStatus == 'notavailable' ? 'Not Available' : bedStatus}
            </span>
          </div>
          <div className="col-md-12">
            {bedStatus == 'available' ? (
              <span
                style={{ color: '#009EF7', fontWeight: '800', cursor: 'pointer' }}
                onClick={() => {
                  this.props.setAdmissionFormVisiblity(
                    CenterRehabRoomBedId,
                    this.props.roomNumber,
                    bedNumber,
                  );
                }}
              >
                {middleText}
              </span>
            ) : bedStatus == 'occupied' ? (
              <span>{middleText}</span>
            ) : bedStatus == 'reserved' ? (
              <>
                <span>
                  <Avatar
                    src={
                      <Image
                        src={patientImage || blank_user}
                        style={{
                          width: '32px',
                          height: '32px',
                          objectFit: 'cover',
                        }}
                      />
                    }
                  />
                  &nbsp;
                </span>
                <span style={{ color: '#182838', fontWeight: '600' }}>{patientName}</span>
              </>
            ) : (
              <span style={{ color: '#7E8299', fontWeight: '600' }}>{middleText}</span>
            )}

            {bedStatus == 'occupied' ? (
              <div className="d-flex align-items-center">
                <span>
                  <Avatar
                    src={
                      <Image
                        src={patientImage || blank_user}
                        style={{
                          width: '32px',
                          height: '32px',
                          objectFit: 'cover',
                        }}
                      />
                    }
                  />
                  &nbsp;
                </span>
                <span style={{ color: '#182838', fontWeight: '600' }}>{patientName}</span>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 pt-2 pb-2">
            {bedStatus != 'available' && `${daysToDischarge} day(s) to discharge`}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BedComponent;
