import React from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
} from './index';

const CustomReduxInput = ({
  name,
  type,
  fieldName,
  validate,
  onBlur,
  labelText,
  format,
  normalize,
  disabled,
}) => (
  <div className="form-group mb-2">
    <Field
      name={name}
      type={type || 'text'}
      component={ReduxInput}
      fieldName={fieldName}
      validate={validate}
      onBlur={onBlur}
      format={format}
      normalize={normalize}
      disabled={disabled}
    />
    <label className="placeholder-label">{labelText}</label>
  </div>
);

const CustomReduxTextarea = ({ name, type, fieldName, validate, onBlur, labelText, disabled }) => (
  <div className="form-group mb-2">
    <Field
      name={name}
      type={type || 'textarea'}
      component={ReduxTextarea}
      fieldName={fieldName}
      validate={validate}
      disabled={disabled}
    />
    <label className="placeholder-label">{labelText}</label>
  </div>
);

export { CustomReduxInput, CustomReduxTextarea };
