import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Table } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { appSubSurveyAnsGet } from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';
import PersistTableSort from '../../../component/persistTableSort';
import renderHTML from 'react-render-html';

class SurveyCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyanswersdata: [],
      surveyanswersdatacol: [
        {
          title: (
            <>
              Question
              <br /> Number
            </>
          ),
          dataIndex: 'QuestionNumber',
          key: 'QuestionNumber',
        },
        {
          title: 'Question',
          dataIndex: 'Question',
          key: 'Question',
          render: (text, record) => <div>{text && renderHTML(text)}</div>,
        },
        { title: 'Answer', dataIndex: 'Answer', key: 'Answer' },
        { title: 'Responded Date Time', dataIndex: 'RespondedDateTime', key: 'RespondedDateTime' },
      ],
    };
  }
  componentDidMount() {
    let { EventJsonData } = this.props;
    let { SurveyId, UserGuid, SurveyQuestionCategoryId } =
      EventJsonData.SurveyUser && EventJsonData.SurveyUser[0];

    let jsonData = {
      Json: JSON.stringify({
        Survey: [
          {
            UserGuid: UserGuid,
            SurveyId: SurveyId,
          },
        ],
      }),
    };
    this.props.dispatch(appSubSurveyAnsGet(jsonData));
  }
  componentWillReceiveProps({ surveyanswersdata }) {
    if (surveyanswersdata.length > 0) {
      this.setState({ surveyanswersdata, modal: true });
    }
  }
  render() {
    const { EventJsonData, isFetching } = this.props;
    const { surveyanswersdata, surveyanswersdatacol } = this.state;

    let {
      SurveyId,
      SurveyQuestionCategoryId,
      SurveyName,
      AssignedDate,
      DueDate,
      StartedDate,
      CompletedDate,
      DiscardedDate,
      DueStatus,
      DueDateFontColor,
      FirstName,
      LastName,
      SurveyStatus,
      TotalScore,
    } = EventJsonData.SurveyUser && EventJsonData.SurveyUser[0];
    const surveyStatus = {
      '-1': { color: '#fef2f2', hide: true, date: DiscardedDate, name: 'Discarded' },
      '0': {
        color: '#f8f8f8',
        hide: false,
        date: DueDate,
        name: 'Due',
        dateFontColor: DueDateFontColor,
      },
      '1': {
        color: 'rgb(239, 247, 254)',
        hide: true,
        date: CompletedDate,
        name: 'Completed',
        sendRequest: true,
      },
    };
    return (
      <div class="border-0">
        <div class="row">
          <div className="col-12">
            <div
              className="task-box"
              style={{ backgroundColor: surveyStatus[SurveyStatus]['color'] }}
            >
              <div className="row no-gutters w-100 justify-content-between align-items-center">
                <div className="col-12 col-md-auto mb-1">
                  <div className="course-name">
                    <Paragraph
                      ellipsis={{ rows: 1, expandable: false }}
                      textAlign="justify"
                      style={{ color: '#000000', fontSize: '17px', marginBottom: '2px' }}
                    >
                      {SurveyName}
                    </Paragraph>
                    <div className="" style={{ display: 'flex' }}>
                      <div className="">
                        <span className="due-date">
                          <em>Assigned:</em> {AssignedDate}
                        </span>
                      </div>
                      <div className="">
                        <span
                          className="due-date"
                          style={{ color: surveyStatus[SurveyStatus].dateFontColor }}
                        >
                          <em>{surveyStatus[SurveyStatus].name} : </em>{' '}
                          {surveyStatus[SurveyStatus].date}
                        </span>
                      </div>
                      <div className="">
                        <span className="due-date">
                          <em>Total Score:</em> {TotalScore}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mb-3 student-profile-smiley-chart">
            <Loader2 loading={isFetching} />

            <h4 class="text-center">Survey Answers</h4>
            <div class="mar-top-30">
              <PersistTableSort
                name="surveyanswersdata"
                dataSource={surveyanswersdata}
                columns={surveyanswersdatacol}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ app_sub_survey_ans_get }) => {
  const { data, error, isFetching } = app_sub_survey_ans_get;
  let surveyanswersdata = [];

  if (data) {
    surveyanswersdata = Object.values(data);
  }
  return {
    surveyanswersdata,
    isFetching,
  };
};
export default connect(mapStateToProps)(SurveyCompleted);
