import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import { panasScaleMoodsGet } from '../../store/actions';
import Gallery from './gallery';
import CurrentPanasTag from './currentPanasTag';

class Mood extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoodDrawer: false,
      visible: false,
      content: <div></div>,
    };
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  };
  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  toggleMoodDrawer = () => {
    this.setState({ showMoodDrawer: !this.state.showMoodDrawer });
  };
  onClose = () => {
    this.setState({ showMoodDrawer: !this.state.showMoodDrawer });
  };

  componentDidMount() {
    this.props.dispatch(panasScaleMoodsGet({}));
  }

  render() {
    const { formType, formId, PANASTag, moodsList, dispatch } = this.props;
    const { showMoodDrawer } = this.state;
    return (
      <div className="moods d-flex align-items-center justify-content-start">
        {formId && (
          <Popover
            trigger="click"
            content={
              <div>
                {this.state.visible ? (
                  <Gallery
                    moodsList={this.props.moodsList}
                    dispatch={dispatch}
                    formType={formType}
                    formId={formId}
                    PANASTag={PANASTag ? PANASTag : []}
                    hide={this.hide}
                    ClientId={this.props.ClientId}
                  />
                ) : (
                  <></>
                )}
              </div>
            }
            open={this.state.visible}
            onOpenChange={this.handleVisibleChange}
          >
            {' '}
            <button
              onClick={() => {
                this.toggleMoodDrawer();
              }}
              className="rolling"
            >
              <img src="images/panas/btn.svg" alt="Barcelona Bootcamp" width="25" />
              <span style={{ fontSize: '14px', color: 'white', fontWeight: '600', margin: '5px' }}>
                Tag Panas
              </span>
            </button>
          </Popover>
        )}

        <CurrentPanasTag
          PANASTag={PANASTag ? PANASTag : []}
          moodsList={moodsList}
          formType={formType}
          formId={formId}
          dispatch={dispatch}
          ClientId={this.props.ClientId}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ panas_scale_moods_get }) => {
  const { data } = panas_scale_moods_get;
  let moodsList = [];

  if (data) {
    moodsList = data.PANASScale || [];
    moodsList = moodsList.map((item, index) => {
      return {
        ...item,
        src: item.Url,
        value: index,
      };
    });
  }
  return {
    moodsList,
  };
};

export default connect(mapStateToProps)(withRouter(Mood));
