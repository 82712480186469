import React, { Component } from 'react';
import { Avatar, Divider, Tooltip } from 'antd';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { BGContent2 } from '../commonSection';
const Analytics = props => {
  const { StatusData } = props;
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ backgroundColor: '#FC2E20', borderRadius: '12px', padding: '2px' }}
      >
        <div className="d-flex align-items-center">
          {' '}
          <img src={`images/license/hour_glass.svg`} width="86" height="79" alt="" />
          <div style={{ fontSize: '26px', fontWeight: '600', color: '#fff' }}>
            {' '}
            Uh-Oh! Your Trial has ended.{' '}
            <span style={{ fontSize: '20px', fontWeight: '600' }}>
              Purchase license to enjoy hassle-free usage.
            </span>
          </div>
        </div>

        <span
          style={{
            fontSize: '16px',
            fontWeight: '600',
            background: '#fff',
            color: '#0070E0',
            borderRadius: '25px',
            padding: '8px',
            marginRight: '50px',
            cursor: 'pointer',
          }}
          onClick={() => {
            props.history.push('/manage/maintain-license');
          }}
        >
          Purchase Analytics License
        </span>
      </div>

      <BGContent2
        moduleName={'Uh-oh! Your free trial has ended.'}
        backGroundImage={`images/license/analyticsBG.svg`}
        moduleLogoImage={`images/license/analytics.svg`}
        subHeader={' View your center’s and clients’ growth and progress.'}
        tagLine={`With an analytics license, a center can view all of its speeds and feeds.It provides
            center-wide analytics, enabling the center administrator to gain a deeper comprehension
            of the business and improve it.`}
        buyButtonText={'Purchase Analytics License'}
        // interaction={[
        //   {
        //     image: `images/license/user.svg`,
        //     tagNumber:
        //       (StatusData && StatusData.Interaction && StatusData.Interaction[0].PeopleConnected) ||
        //       '0',
        //   },
        //   {
        //     image: `images/license/chat.svg`,
        //     tagNumber:
        //       (StatusData && StatusData.Interaction && StatusData.Interaction[0].NewMessages) ||
        //       '0',
        //   },
        // ]}
        // peopleConnected={
        //   (StatusData && StatusData.Interaction && StatusData.Interaction[0].PeopleConnected) || '0'
        // }
        // newMessages={
        //   (StatusData && StatusData.Interaction && StatusData.Interaction[0].NewMessages) || '0'
        // }
      />
    </>
  );
};
export default Analytics;
