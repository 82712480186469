import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cascader } from 'antd';
import { Drawer, Button } from 'antd';
import {
  ReduxDateInput,
  ReduxSelect,
  ReduxInput,
  ReduxTextarea,
} from '../../../../../component/custom-redux-components';
import { Field, reduxForm } from 'redux-form';
import {
  upsertTreatmentPlanIntervention,
  getTreatmentPlan,
  comboInterventionTemplate,
  getProgressNoteForIntervention,
} from '../../../../../store/actions';
import { ProcessCombos } from '../../../../../store/comboConfig';
import { stringDate } from '../../../../../util/date';
import SubmitButton from '../../../../../Components/submitButton';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (beginningTime.isBefore(endTime)) {
    } else {
      return 'should be greater than Start time';
    }
  },
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.interventionTargetDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return 'has to be greater than Start Date';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class AddInterventionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comboProblemDataState: [],
      visible: false,
      noteList: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(comboInterventionTemplate({}));
    let data = {
      Json: JSON.stringify({
        ProgressNote: [
          {
            ClientId: this.props.clientId,
            TreatmentPlanInterventionId: this.props.treatmentPlanInterventionId,
          },
        ],
      }),
    };
    this.props.dispatch(getProgressNoteForIntervention(data));
    var result = ProcessCombos('TreatmentFrequency');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  componentWillReceiveProps({
    upsertTreatmentPlanInterventionDataSuccess,
    comboProblemData,
    GetProgressNoteForInterventionData,
  }) {
    if (
      upsertTreatmentPlanInterventionDataSuccess !=
      this.props.upsertTreatmentPlanInterventionDataSuccess
    ) {
      this.props.toggleInterventionModal();
      this.props.resetInitialValues();
      this.props.resetMessageText(
        `Intervention ${this.props.treatmentPlanInterventionId ? 'Updated' : 'Added'} Successfully`,
        {
          TreatmentPlan: [
            {
              TreatmentPlanObjectiveId: this.props.treatmentPlanObjectiveId,
            },
          ],
        },
      );
    }
    if (comboProblemData != this.props.comboProblemData) {
      let temp = comboProblemData.map(item => {
        return {
          label: item.Text,
          value: item.Value,
        };
      });
      this.setState({
        comboProblemDataState: temp,
      });
    }
    if (
      GetProgressNoteForInterventionData &&
      JSON.stringify(GetProgressNoteForInterventionData) !=
        JSON.stringify(this.props.GetProgressNoteForInterventionData)
    ) {
      this.setState({
        noteList: GetProgressNoteForInterventionData,
      });
    }
  }

  onhandleSubmit = val => {
    const { treatmentPlanObjectiveId, treatmentPlanInterventionId } = this.props;
    let data;
    if (treatmentPlanObjectiveId && treatmentPlanInterventionId) {
      data = {
        Json: JSON.stringify({
          TreatmentPlanIntervention: [
            {
              TreatmentPlanInterventionId: treatmentPlanInterventionId,
              TreatmentPlanObjectiveId: treatmentPlanObjectiveId,
              Description: val.interventionDescription,
              Amount: val.interventionAmount,
              Frequency: val.interventionFrequency,
              TargetDate: stringDate(val.interventionTargetDate),
              CompletedDate: stringDate(val.interventionCompletedDate),
              InterventionNote: val.interventionNote,
            },
          ],
        }),
      };
    } else {
      data = {
        Json: JSON.stringify({
          TreatmentPlanIntervention: [
            {
              TreatmentPlanInterventionId: null,
              TreatmentPlanObjectiveId: treatmentPlanObjectiveId,
              Description: val.interventionDescription,
              Amount: val.interventionAmount,
              Frequency: val.interventionFrequency,
              TargetDate: stringDate(val.interventionTargetDate),
              CompletedDate: stringDate(val.interventionCompletedDate),
              InterventionNote: val.interventionNote,
            },
          ],
        }),
      };
    }

    this.props.dispatch(upsertTreatmentPlanIntervention(data, this.props.treatmentPlanDimensionId));
    // this.props.toggleInterventionModal();
    // this.props.resetInitialValues();
  };
  onChange = (value, selectedOptions) => {
    // this.setState({
    //   text: selectedOptions.map(o => o.label).join(', '),
    // });

    this.props.setTemplateValue({
      interventionDescription: selectedOptions.map(o => o.label).join(', '),
    });
    this.props.change('interventionDescription', selectedOptions.map(o => o.label).join(', '));
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { onCancel, TreatmentFrequency, isFetching } = this.props;
    const { comboProblemDataState, noteList, selectedNote } = this.state;
    return (
      <>
        <div class="modal-content border-0 ">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={() => {
                onCancel();
              }}
            >
              ×
            </button>

            {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
          </div>
          <div class="modal-body rcm-panel">
            <div className="px-4 enrollment-form">
              <form onSubmit={this.props.handleSubmit(this.onhandleSubmit)}>
                <div className="row px-0 d-flex pb-5 ">
                  <div class="col-6">
                    {' '}
                    <h4 class="text-left">Intervention</h4>
                  </div>
                  <div class="col-6 text-right px-0">
                    {' '}
                    <Button type="link" size={'large'} onClick={this.showDrawer}>
                      Show Associated Notes
                    </Button>
                  </div>
                </div>

                <div className="d-flex">
                  <div class="form-group mb-2" style={{ flexGrow: '9' }}>
                    <Field
                      name="interventionDescription"
                      type="textarea"
                      component={ReduxTextarea}
                      fieldName={'Intervention Description'}
                      validate={Validator.required}
                      style={{ minHeight: '92px' }}
                    />
                    <div
                      style={{
                        flexGrow: '0.1',
                        marginLeft: '5px',
                        marginLeft: '5px',
                        position: 'absolute',
                        right: '-18px',
                        zIndex: '999',
                        top: '0px',
                      }}
                    >
                      <Cascader options={comboProblemDataState} onChange={this.onChange}>
                        <a href="#" style={{ color: 'grey' }}>
                          <i class="fas fa-chevron-circle-down"></i>
                        </a>
                      </Cascader>
                    </div>
                    <label class="placeholder-label" style={{ background: '#fefefe' }}>
                      Intervention Description*
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-2 col-md-6">
                    <Field
                      name="interventionAmount"
                      type="number"
                      component={ReduxInput}
                      fieldName={'Intervention Duration'}
                      validate={Validator.required}
                      min={1}
                      max={999999}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">Intervention Duration in (hours/ each)*</label>
                  </div>
                  <div class="form-group mb-2 col-md-6">
                    <Field
                      name="interventionFrequency"
                      type="number"
                      component={ReduxSelect}
                      fieldName={'Intervention Frequency'}
                      validate={Validator.required}
                      placeholder={'Intervention Frequency*'}
                      options={TreatmentFrequency}
                      negativeNumberBlocked={true}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-2 col-md-6">
                    <Field
                      name="interventionTargetDate"
                      type="text"
                      component={ReduxDateInput}
                      fieldName={'Start Date'}
                      validate={Validator.required}
                      label="Start Date*"
                      minDate={new Date()}
                    />
                  </div>
                  <div class="form-group mb-2 col-md-6">
                    <Field
                      name="interventionCompletedDate"
                      type="text"
                      component={ReduxDateInput}
                      fieldName={'Completion Date'}
                      // validate={[Validator.required, Validator.endDate]}
                      validate={[Validator.required]}
                      label="Completion Date*"
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div class="form-group mb-2">
                  <Field
                    name="interventionNote"
                    type="textarea"
                    component={ReduxTextarea}
                    fieldName={'Intervention Note'}
                    // validate={Validator.required}
                    style={{ minHeight: '92px' }}
                  />
                  <label class="placeholder-label" style={{ background: '#fefefe' }}>
                    Intervention Note
                  </label>
                </div>

                <div class="modal-footer  border-top-0 pt-2 px-0">
                  <SubmitButton
                    loading={isFetching}
                    style={{ padding: '0 24px', height: '44px' }}
                    value={
                      this.props.treatmentPlanInterventionId
                        ? 'Update Intervention'
                        : ' Add Intervention'
                    }
                  />
                </div>
                {this.state.visible && (
                  <Drawer
                    className="custom-drawer"
                    width={640}
                    title="Associated Notes"
                    placement="right"
                    closable={false}
                    onClose={this.onCloseDrawer}
                    open={this.state.visible}
                  >
                    {this.props.initialValues &&
                      this.props.initialValues.AppointmentNote &&
                      this.props.initialValues.AppointmentNote.map(item => {
                        return (
                          <div
                            style={{
                              background: 'white',
                              borderRadius: '11px',
                              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 16px',
                              padding: '5px 10px',
                              margin: '5px 0px',
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              {item.AppointmentDate && (
                                <p>
                                  <span style={{ fontWeight: 'bold' }}>Appointment Date: </span>
                                  {item.AppointmentDate}
                                </p>
                              )}

                              {item.SignedDate && (
                                <p>
                                  <span style={{ fontWeight: 'bold' }}>Signed Date: </span>
                                  {item.SignedDate}
                                </p>
                              )}
                            </div>

                            {item.Note && (
                              <>
                                <p>
                                  <span style={{ fontWeight: 'bold' }}>Note:</span> {item.Note}
                                </p>
                              </>
                            )}
                          </div>
                        );
                      })}
                    {this.props.initialValues && this.props.initialValues.AppointmentNote == null
                      ? 'No Associated Note Found !'
                      : null}
                  </Drawer>
                )}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({
  upsert_treatment_plan_intervention,
  combo_intervention_template,
  get_progress_note_for_intervention,
  combos,
}) => {
  const {
    data: upsertTreatmentPlanInterventionData,
    isFetching,
  } = upsert_treatment_plan_intervention;
  const { data: comboProblemTemplateData } = combo_intervention_template;
  const { data: getProgressNoteForInterventionData } = get_progress_note_for_intervention;
  let GetProgressNoteForInterventionData;
  let comboProblemData;
  let upsertTreatmentPlanInterventionDataSuccess;

  if (upsertTreatmentPlanInterventionData && upsertTreatmentPlanInterventionData.success) {
    upsertTreatmentPlanInterventionDataSuccess = upsertTreatmentPlanInterventionData.success;
  }

  if (comboProblemTemplateData) {
    comboProblemData = comboProblemTemplateData[0].InterventionTemplate;
  }

  if (getProgressNoteForInterventionData) {
    GetProgressNoteForInterventionData = getProgressNoteForInterventionData[0].ProgressNote;
  }

  return {
    comboProblemData: comboProblemData || [],
    upsertTreatmentPlanInterventionDataSuccess: upsertTreatmentPlanInterventionDataSuccess || false,
    GetProgressNoteForInterventionData,
    TreatmentFrequency: combos.data.TreatmentFrequency,
    isFetching,
  };
};

const ReduxAddInterventionModal = reduxForm({
  form: 'AddInterventionModal',
  enableReinitialize: true,
})(connect(mapStateToProps)(AddInterventionModal));

export { ReduxAddInterventionModal };
