export const Delete_Confirmation_Msg = 'Are you sure you want to deactivate ?';
export const Delete_Sucess_Msg = 'Record deactivated successfully';
export const Archived_Sucess_Msg = 'Record archived successfully';
export const Upsert_Sucess_Msg = 'Your changes have been saved successfully';
export const STRIPE_KEY = 'REMOVED';
export const Deactivate_Sucess_Msg = 'deactivated successfully';
export const Deactivate_Fail_Msg =
  'Before deactivating please move the client to a different counselor';
export const InstrumentationKey = '50cd29b4-31ad-4fa0-b476-191bfbf79bd6';
export const PermissionsErrorMsg =
  'You do not have the necessary permissions to access this screen.';
