export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
export const currencyFormatter2 = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const numberFormat = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 7 });

// export const NumberShorten = Intl.NumberFormat('en-US', {
//   notation: 'compact',
//   maximumFractionDigits: 1,
// }).format(2500);
