import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getDashboard(action) {
  try {
    const response = yield API.getDashboard();
    /*yield put({
      type: ActionTypes.TODO_GET_ALL_REQUEST,
    });*/
    yield put({
      type: ActionTypes.DASHBOARD_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Dashboard[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARDT_GET_FAILURE,
    });
  }
}

export function* getStudentsSearch(action) {
  try {
    const response = yield API.getStudentsSearch(action.payload);
    yield put({
      type: ActionTypes.STUDENTS_SEARCH_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENTS_SEARCH_FAILURE,
    });
  }
}

export function* getDashboardLoadAppUsers(action) {
  try {
    const response = yield API.getDashboardLoadAppUsers();
    yield put({
      type: ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_FAILURE,
    });
  }
}
export function* loadSmallGroupDashboard(action) {
  try {
    const response = yield API.loadSmallGroupDashboard();
    yield put({
      type: ActionTypes.LOAD_SMALLGROUP_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_SMALLGROUP_DASHBOARD_FAILURE,
    });
  }
}
export function* getReports(action) {
  try {
    const response = yield API.getReports(action.payload);
    yield put({
      type: ActionTypes.GET_REPORTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_REPORTS_FAILURE,
    });
  }
}

export function* loadJudicialDashboard(action) {
  try {
    const response = yield API.loadJudicialDashboard(action.payload);
    yield put({
      type: ActionTypes.LOAD_JUDICIAL_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_JUDICIAL_DASHBOARD_FAILURE,
    });
  }
}

export function* getJudicialUserList(action) {
  try {
    const response = yield API.getJudicialUserList(action.payload);
    yield put({
      type: ActionTypes.GET_JUDICIAL_USER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_JUDICIAL_USER_LIST_FAILURE,
    });
  }
}
export function* getAppAdvisor(action) {
  try {
    const response = yield API.getAppAdvisor(action.payload);
    yield put({
      type: ActionTypes.GET_APP_ADVISOR_SUCCESS,
      payload: JSON.parse(response.data.data)[0].AppAdvisor,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APP_ADVISOR_FAILURE,
    });
  }
}
export function* getDashboardMenu(action) {
  try {
    const response = yield API.getDashboardMenu(action.payload);
    yield put({
      type: ActionTypes.GET_DASHBOARD_MENU_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DASHBOARD_MENU_FAILURE,
    });
  }
}
export function* suspiciousTermAck(action) {
  try {
    const response = yield API.suspiciousTermAck(action.payload);
    yield put({
      type: ActionTypes.SUSPICIOUS_TERM_ACK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SUSPICIOUS_TERM_ACK_FAILURE,
    });
  }
}
export function* getAdvisorMemberTrends(action) {
  try {
    const response = yield API.getAdvisorMemberTrends(action.payload);
    yield put({
      type: ActionTypes.GET_ADVISOR_MEMBER_TRENDS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ADVISOR_MEMBER_TRENDS_FAILURE,
    });
  }
}
export function* getAdvisorMemberTrendsHistory(action) {
  try {
    const response = yield API.getAdvisorMemberTrendsHistory(action.payload);
    yield put({
      type: ActionTypes.GET_ADVISOR_MEMBER_TRENDS_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ADVISOR_MEMBER_TRENDS_HISTORY_FAILURE,
    });
  }
}
export function* upsertStaffViewedActivity(action) {
  try {
    const response = yield API.upsertStaffViewedActivity(action.payload);
    yield put({
      type: ActionTypes.UPSERT_STAFF_VIEWED_ACTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_STAFF_VIEWED_ACTIVITY_FAILURE,
    });
  }
}

export function* addSupportGroup(action) {
  try {
    const response = yield API.addSupportGroup(action.payload);
    yield put({
      type: ActionTypes.ADD_SUPPORT_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_SUPPORT_GROUP_FAILURE,
    });
  }
}

export function* loadSupportGroup(action) {
  try {
    const response = yield API.loadSupportGroup(action.payload);
    yield put({
      type: ActionTypes.LOAD_SUPPORT_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_SUPPORT_GROUP_FAILURE,
    });
  }
}

export function* loadPeer(action) {
  try {
    const response = yield API.loadPeer(action.payload);
    yield put({
      type: ActionTypes.LOAD_PEER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_PEER_FAILURE,
    });
  }
}

export function* loadUserEngagement(action) {
  try {
    const response = yield API.loadUserEngagement(action.payload);
    yield put({
      type: ActionTypes.LOAD_USER_ENGAGEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_USER_ENGAGEMENT_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.DASHBOARD_GET_REQUEST, getDashboard),
    takeLatest(ActionTypes.STUDENTS_SEARCH_REQUEST, getStudentsSearch),
    takeLatest(ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_REQUEST, getDashboardLoadAppUsers),
    takeLatest(ActionTypes.LOAD_SMALLGROUP_DASHBOARD_REQUEST, loadSmallGroupDashboard),
    takeLatest(ActionTypes.GET_REPORTS_REQUEST, getReports),
    takeLatest(ActionTypes.LOAD_JUDICIAL_DASHBOARD_REQUEST, loadJudicialDashboard),
    takeLatest(ActionTypes.GET_JUDICIAL_USER_LIST_REQUEST, getJudicialUserList),
    takeLatest(ActionTypes.GET_APP_ADVISOR_REQUEST, getAppAdvisor),
    takeLatest(ActionTypes.GET_DASHBOARD_MENU_REQUEST, getDashboardMenu),
    takeLatest(ActionTypes.SUSPICIOUS_TERM_ACK_REQUEST, suspiciousTermAck),
    takeLatest(ActionTypes.GET_ADVISOR_MEMBER_TRENDS_REQUEST, getAdvisorMemberTrends),
    takeLatest(
      ActionTypes.GET_ADVISOR_MEMBER_TRENDS_HISTORY_REQUEST,
      getAdvisorMemberTrendsHistory,
    ),
    takeLatest(ActionTypes.UPSERT_STAFF_VIEWED_ACTIVITY_REQUEST, upsertStaffViewedActivity),
    takeLatest(ActionTypes.ADD_SUPPORT_GROUP_REQUEST, addSupportGroup),
    takeLatest(ActionTypes.LOAD_SUPPORT_GROUP_REQUEST, loadSupportGroup),
    takeLatest(ActionTypes.LOAD_PEER_REQUEST, loadPeer),
    takeLatest(ActionTypes.LOAD_USER_ENGAGEMENT_REQUEST, loadUserEngagement),
  ]);
}
