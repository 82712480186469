import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Friend extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { EventJsonData } = this.props;
    let { UserGuid, DisplayName, ProfilePictureURL, MyStory } =
      EventJsonData.Friend && EventJsonData.Friend[0];
    return (
      <div class="border-0">
        <div class="row">
          <div className="col-12">
            <div className="task-box" style={{ backgroundColor: '#f8f8f8' }}>
              <div className="row no-gutters w-100 justify-content-between align-items-center">
                <div className="col-12 mb-1">
                  <div className="d-flex align-items-start">
                    <div className="mr-2 flex-shrink-0">
                      <div className="d-flex flex-column">
                        <img
                          src={ProfilePictureURL}
                          height="150"
                          className="rounded"
                          alt={`${DisplayName} Profile Picture`}
                        />
                        <span className="due-date">
                          <em>Friend Name :</em> {DisplayName}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <span className="due-date">
                        <strong>Story :</strong> {MyStory}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Friend;
