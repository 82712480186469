import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import UploadProfile from '../../../../Components/uploadProfilePicture';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { getGroupBanner } from '../../../../store/actions';

class GroupBanner extends React.Component {

  capture = imageSrc => {
    // const imageSrc = this.webcam.getScreenshot();
    const me = this;
    Axios.post('Student/UploadImage', {
      image: imageSrc,
      GroupId: (this.props.BannerDetails && this.props.BannerDetails.GroupId) || null,
      IsGroupImage: true,
      IsProfilePic: true,
    })
      .then(response => {
        // me.props.dispatch(getGroupBanner({ StudentId: this.getStudentId() }));
        me.props.dispatch(
          getGroupBanner({
            json: JSON.stringify({
              Group: [
                {
                  GroupId: (this.props.BannerDetails && this.props.BannerDetails.GroupId) || null,
                },
              ],
            }),
          }),
        );
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };
  render() {
    const {
      GroupName,
      GroupId,
      PractitionerName,
      ServiceCode,
      GroupStatus,
      ClientCount,
      GroupImageUrl,
    } = this.props.BannerDetails;
    return (
      <>
        <div
          className=" col-12 top-heading-row my-3 align-items-center mr-0 p-2"
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: 'rgba(0, 0, 0, 0.03)',
          }}
        >
          <Loader2 loading={this.props.isFetching} />
          <div className="row align-items-center">
            <div class="col-12 col-md-6 col-lg-5">
              <h5 class="font-weight-semibold mb-3 mb-md-0 students-profile">
                <div className="d-flex align-items-center group-banner">
                  {/* <div style={{ width: '57px', height: '57px', marginRight: '10px' }}>
                        <img
                          src={studentData.studentImageUrl}
                          // src="/images/surveys-img5.jpg"
                          height="100%"
                          style={{ borderRadius: '10px', objectFit: 'cover' }}
                        />
                      </div> */}

                  <UploadProfile
                    captureProfile={this.capture}
                    isReadOnlyUser={this.props.isReadOnlyUser}
                    imgSrc={GroupImageUrl}
                  />
                  <div>
                    <span className="d-block" style={{ fontWeight: 'bold', fontSize: '24px' }}>
                      {GroupName}
                    </span>
                    <span className="d-block" style={{ fontSize: '19px', color: '#979797' }}>
                      {/* {GroupId} */}
                    </span>
                  </div>
                </div>
              </h5>
            </div>
            <div class="col-12 col-md-6 col-lg-7">
              <div class="row students-detail   text-md-left  justify-content-between">
                <div class="col-6 col-sm-auto mb-2 md-sm-0">
                  <span class="info-label">Counselor</span>
                  <h6
                    // title={clientData.banner.PractitionerName}
                    class="info-detail m-md-0"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '180px',
                    }}
                  >
                    {PractitionerName}
                  </h6>
                </div>
                <div class="col-6 col-sm-auto mb-2 md-sm-0">
                  <span class="info-label">Service</span>
                  <h6 class="info-detail m-md-0">{ServiceCode} </h6>
                </div>
                <div class="col-6 col-sm-auto mb-2 md-sm-0">
                  <span class="info-label">Status</span>
                  <h6 class="info-detail m-md-0">{GroupStatus}</h6>
                </div>
                <div class="col-6 col-sm-auto mb-2 md-sm-0">
                  <span class="info-label">Patients</span>
                  <h6
                    // title={clientData.banner.AllergiesText}
                    class="info-detail m-md-0"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '180px',
                    }}
                  >
                    {ClientCount}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(connect()(GroupBanner));
