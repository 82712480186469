import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { withRouter } from 'react-router-dom';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import BundleRowRender from './bundleRowRender';
import ExpandIcon from '../../../../util/expandIcon';
const ContractRowRender = ({ record, index, indent, expanded }) => {
  return <ReduxRowRender />;
};

const RowRender = ({ smartContractList, isFetching, history }) => {
  const columns = [
    {
      title: 'Contract Name',
      dataIndex: 'ContractName',
      key: 'ContractName',
      sorter: (a, b) => isComparer(a.ContractName, b.ContractName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
      sorter: (a, b) => isDateComparer(a.StartDate, b.StartDate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      key: 'EndDate',
      sorter: (a, b) => isDateComparer(a.EndDate, b.EndDate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Total Bundles',
      dataIndex: 'TotalBundles',
      key: 'TotalBundles',
      sorter: (a, b) => isNumericComparer(a.TotalBundles, b.TotalBundles),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div className="text-right">{text}</div>,
    },
    {
      title: 'Total Services',
      dataIndex: 'TotalServices',
      key: 'TotalServices',
      sorter: (a, b) => isNumericComparer(a.TotalServices, b.TotalServices),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div className="text-right">{text}</div>,
    },
    {
      title: 'Payer',
      dataIndex: 'PayerCount',
      key: 'PayerCount',
      sorter: (a, b) => isNumericComparer(a.PayerCount, b.PayerCount),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <div className="text-right">{text}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'state',
      sorter: (a, b) => isComparer(a.state, b.state),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        let status = {
          1: 'incomplete',
          2: 'pending',
          3: 'active',
          4: 'terminated',
          5: 'expired',
          6: 'delivered',
        };
        return (
          <div className="contract-status">
            {text && <span className={status[record.StatusId]}>{text}</span>}
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => {
        if (record.StatusId) {
          return (
            <span
              style={{
                color: '#4FACFE',
                fontSize: '14px',
                fontWeight: '700',
              }}
              onClick={() => {
                history.push(
                  `/cw/patient-management/patient-profile?tab=smart-contract&smartContractId=${record.SmartContractId}&pId=${record.ClientId}`,
                );
              }}
            >
              <i class="far fa-eye form-control-icon" />&nbsp;View Contract
            </span>
          );
        } else {
          return;
        }
      },
    },
  ];

  return smartContractList ? (
    <Table
      columns={columns}
      dataSource={smartContractList}
      pagination={false}
      expandable={{
        expandedRowRender: BundleRowRender,
        expandIcon: ExpandIcon,
      }}
      className="nested-tables"
    />
  ) : (
    <div className="text-center border-1">
      <span style={{ color: '#828282', fontSize: '16px', fontWeight: '400' }}>
        No active contracts found.
      </span>
    </div>
  );
};
const mapStateToProps = state => {
  const { smart_contract_patient_list_get } = state;
  const { data, isFetching, error } = smart_contract_patient_list_get;

  let smartContractList = null;

  if (data && data.data) {
    smartContractList = JSON.parse(data.data).Contract;
  }
  return {
    smartContractList,
    isFetching,
  };
};
const ReduxRowRender = connect(mapStateToProps)(withRouter(RowRender));

export default ContractRowRender;
