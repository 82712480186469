import React, { Component } from 'react';
import { Collapse } from 'antd';
import { RightCircleOutlined, RightOutlined } from '@ant-design/icons';
import ClientMilestoneMeasures from './measures';

const { Panel } = Collapse;

class IndividualMilestones extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { measureDetails, activeKey } = this.props || [];
    return (
      <div className="payer-milestone mt-4 row" style={{ width: '100%' }}>
        <p
          className="time"
          style={{
            backgroundColor: '#E3F4FF',
            fontWeight: '600',
            height: 'fit-content',
            padding: '5px 25px',
            margin: '0 25px',
            width: '125px',
            zIndex: '2',
          }}
        >
          {measureDetails && measureDetails.MeasureCreatedOn}
        </p>
        <Collapse
          expandIcon={({ isActive }) => (
            <div className="d-flex align-items-center">
              <p className="mb-0 mr-3 mt-1" style={{ fontSize: '16px', color: '#46B4FE' }}>
                {isActive ? 'COLLAPSE' : 'EXPAND'}
              </p>
              <RightOutlined
                rotate={isActive ? -90 : 90}
                style={{
                  fontSize: '20px',
                  marginTop: '4px',
                  color: '#46B4FE',
                }}
              />
              {/* <RightOutlined /> */}
            </div>
          )}
          key={activeKey}
          defaultActiveKey={activeKey}
          // className="col-10"
          style={{ flexGrow: '1', width: '85%' }}
          //   onChange={onChange}
        >
          <Panel
            header={
              <span
                style={{
                  color: 'black',
                  fontSize: '20px',
                  fontWeight: '600',
                }}
              >{`Milestone ${activeKey + 1} - (${measureDetails &&
                measureDetails.Description})`}</span>
            }
            key={activeKey}
          >
            <div className="row">
              <div className="col-12 mb-2 pt-4">
                <div className="card border-0 students-profile" style={{ padding: '20px' }}>
                  <ClientMilestoneMeasures measureDetails={measureDetails} />
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default IndividualMilestones;
