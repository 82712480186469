import React, { lazy, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Drawer, Table, Tooltip } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';
import { ProfilePicComponent } from '../../Components/profilePicComponent';
import CloseIcon from '../../Components/CloseIcon';
import { ViewFeedback } from './customScreens';
import {
  feedbackFormListGet,
  feedbackFormGet,
  feedbackFormDelete,
  resetFeedbackFormDelete,
} from '../../store/actions';
import { Loader2 } from '../../Components/Loader';
import AntdModal from '../../Components/CustomModal';
import CustomModal from '../../core/modal';

const FeedbackList = props => {
  const dispatch = useDispatch();
  const [isToggleDrawer, setToggleDrawer] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const {
    isFetchingList,
    feedbackList,
    isFetching,
    feedbackGetResponse,
    isFeedBackDeleted,
  } = useSelector(
    ({
      feedback_form_delete: { data: feedbackDeleteData },
      feedback_form_get: { data: feedbackGetData, isFetching },
      feedback_form_list_get: { data: ListGetData, isFetching: isFetchingList },
    }) => {
      return {
        feedbackList: ListGetData && ListGetData.Feedback,
        feedbackGetResponse: feedbackGetData && feedbackGetData.Feedback[0],
        isFetching: isFetching,
        isFetchingList: isFetchingList,
        isFeedBackDeleted: feedbackDeleteData && feedbackDeleteData.success,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    dispatch(feedbackFormListGet({}));
  }, []);
  useEffect(() => {
    if (isFeedBackDeleted) {
      setIsModelOpen(true);
    }
  }, [isFeedBackDeleted]);
  const toggleDrawer = record => {
    if (record && record.FeedbackId) {
      dispatch(
        feedbackFormGet({
          JsonInput: JSON.stringify({
            Feedback: [
              {
                FeedbackId: record.FeedbackId,
              },
            ],
          }),
        }),
      );
    }
    setToggleDrawer(true);
  };
  const onDrawerClose = () => {
    setToggleDrawer(false);
    // this.props.dispatch(resetClientCareTeamGet({}));
  };
  const columns = [
    {
      title: 'Patient Name',
      dataIndex: 'ClientName',
      key: 'ClientName',
      sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="d-flex align-items-center">
            <div className="student-list-pic">
              <ProfilePicComponent src={record.StaffImgUrl} width={40} />
            </div>
            <span className="px-2">{text}</span>
          </div>
        );
      },
    },
    {
      title: 'Rating',
      dataIndex: 'Score',
      key: 'Score',
      sorter: (a, b) => isComparer(a.Score, b.Score),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        text && (
          <span className="d-flex align-items-center">
            <img src="/images/icons/star_rating.svg" />
            <span className="ml-1">{text}</span>
          </span>
        ),
    },
    {
      title: 'Reason',
      dataIndex: 'Reason',
      key: 'Reason',
      sorter: (a, b) => isComparer(a.Reason, b.Reason),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <span className="ml-1">{text}</span>,
    },
    {
      title: 'Care Team Member',
      dataIndex: 'StaffName',
      key: 'StaffName',
      sorter: (a, b) => isComparer(a.StaffName, b.StaffName),
      sortDirections: ['descend', 'ascend'],
      //   render: (text, record) => {
      //     return (
      //       <div className="d-flex align-items-center">
      //         <div className="student-list-pic">
      //           <ProfilePicComponent src={record.ProfileImageUrl} />
      //         </div>
      //       </div>
      //     );
      //   },
    },
    {
      title: 'Comment',
      dataIndex: 'Comment',
      key: 'Comment',
      sorter: (a, b) => isComparer(a.Comment, b.Comment),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Updated on',
      dataIndex: 'UpdatedOn',
      key: 'UpdatedOn',
      sorter: (a, b) => isDateComparer(a.UpdatedOnForSort, b.UpdatedOnForSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => (
        <div class="tools-grid d-flex align-items-center">
          <span
            style={{ cursor: 'pointer', marginRight: '5px' }}
            onClick={() => {
              toggleDrawer(record);
            }}
          >
            <Tooltip placement="top" title="View">
              <img src="images/openIcon.svg" alt="" />
            </Tooltip>
          </span>
          <AntdModal
            ModalButton={({ onClick }) => (
              <span
                class="delete-btn"
                onClick={onClick}
                data-toggle="modal"
                data-target="#delete-Screen-Tool"
              >
                <Tooltip placement="top" title="Delete">
                  <img
                    src="images/icons/delete_grid.svg"
                    style={{
                      verticalAlign: 'unset',
                    }}
                  />
                </Tooltip>
              </span>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div class="modal-content border-0">
                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                <div class="modal-body pr-2 pl-2 pr-xl-5 pl-xl-5">
                  <h4 class="modal-title col-12 mb-3">
                    Are you sure you want to delete this Feedback?
                  </h4>
                </div>
                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    class="btn btn-outline-primary px-5 mr-2"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No
                  </button>
                  <button
                    class="btn px-5"
                    onClick={() => {
                      onOk();
                      dispatch(
                        feedbackFormDelete({
                          JsonInput: JSON.stringify({
                            Feedback: [
                              {
                                FeedbackId: record.FeedbackId,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="card border-0 rounded-0">
      <div className="row card-body summary-block">
        <div className="col-12">
          <h5 className="">Feedback List</h5>
        </div>
        <div className="col-12">
          <Loader2 loading={isFetchingList} />
          <div className="">
            <Table
              columns={columns}
              dataSource={feedbackList}
              pagination={{
                itemRender: (page, type, originalElement) => {
                  return (
                    <div class="dataTables_wrapper no-footer">
                      <div class="dataTables_paginate paging_simple_numbers">
                        {type == 'prev' ? (
                          <a class="paginate_button previous">Previous</a>
                        ) : type == 'next' ? (
                          <a class="paginate_button next">Next</a>
                        ) : (
                          <a class="paginate_button ">{page}</a>
                        )}
                      </div>
                    </div>
                  );
                },
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div>
      {isToggleDrawer && (
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title pl-0">View Feedback</span>
            </div>
          }
          placement="right"
          open={isToggleDrawer}
          width={650}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={onDrawerClose}
        >
          <ViewFeedback
            onClose={onDrawerClose}
            ResponseData={feedbackGetResponse}
            isFetching={isFetching}
          />
        </Drawer>
      )}
      {isModelOpen && (
        <CustomModal
          isOpen={isModelOpen}
          header={'Feedback Deleted Successfully'}
          yesAction={() => {
            setIsModelOpen(false);
            dispatch(feedbackFormListGet({}));
            dispatch(resetFeedbackFormDelete());
          }}
          yesText="OK"
          hideCancel={true}
        />
      )}
    </div>
  );
};
export default FeedbackList;
