import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { listGroupServiceLine, getGroupServiceLine } from '../../../../store/actions';
import ServiceLineDetail from './customScreens/groupServiceLineDetail';
import PersistTableSort from '../../../../component/persistTableSort';
import GridSearch from '../../../../Components/gridSearch';

class ManageGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineData: {},
      tabView: true,
      columns: [
        {
          title: 'Session Date',
          dataIndex: 'SessionDate',
          key: 'SessionDate',
          sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Service Code',
          dataIndex: 'ServiceCode',
          key: 'ServiceCode',
          sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Start / End Time',
          dataIndex: 'SessionTime',
          key: 'SessionTime',
          sorter: (a, b) => isNumericComparer(a.SessionTime, b.SessionTime),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Program',
          dataIndex: 'ProgramDescription',
          key: 'ProgramDescription',
          sorter: (a, b) => isNumericComparer(a.ProgramDescription, b.ProgramDescription),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Attendees Count',
          dataIndex: 'AttendeesCount',
          key: 'AttendeesCount',
          sorter: (a, b) => isNumericComparer(a.AttendeesCount, b.AttendeesCount),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Action',
          render: (text, record, index) => {
            if (!props.isReadOnlyUserSession) {
              return (
                <>
                  {' '}
                  <div class="tools-grid">
                    <>
                      <span
                        className="start-btn"
                        onClick={() => {
                          props.dispatch(
                            getGroupServiceLine({
                              json: JSON.stringify({
                                GroupServiceLine: [
                                  {
                                    GroupAppointmentId: record.GroupAppointmentId,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.setState({
                            GroupAppointmentId: record.GroupAppointmentId,
                            // tabView: false,
                          });
                        }}
                      >
                        Manage
                      </span>
                    </>
                  </div>
                </>
              );
            } else {
              return null;
            }
          },

          // width: 300,
        },
      ],
    };
  }
  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = -1;
    }
    return groupId;
  }
  toggleView = getLatest => {
    this.setState(prevState => ({
      tabView: !prevState.tabView,
    }));
    // if (getLatest) {
    //   this.props.dispatch(
    //     listSurveyQuestionCategory({
    //       json: JSON.stringify({
    //         Survey: [
    //           {
    //             ClientId: this.props.clientId,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  };
  componentDidMount() {
    this.props.dispatch(
      listGroupServiceLine({
        json: JSON.stringify({
          GroupServiceLine: [
            {
              GroupId: this.getGroupId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ ServiceLineData, isCreated }) {
    if (
      ServiceLineData &&
      JSON.stringify(ServiceLineData[0]) != JSON.stringify(this.props.ServiceLineData[0])
    ) {
      let data = {
        ...ServiceLineData[0],
        ServiceCodeId: ServiceLineData[0] && parseInt(ServiceLineData[0].ServiceCodeId),
      };
      this.setState({
        ServiceLineData: data,
        tabView: false,
      });
    }
    if (isCreated && isCreated != this.props.isCreated) {
      this.props.dispatch(
        getGroupServiceLine({
          json: JSON.stringify({
            GroupServiceLine: [
              {
                GroupAppointmentId: this.state.GroupAppointmentId,
              },
            ],
          }),
        }),
      );
    }
  }
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listGroupServiceLine({
        json: JSON.stringify({
          GroupServiceLine: [
            {
              Search: value,
              GroupId: this.getGroupId(),
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { columns, tabView, ServiceLineData } = this.state;
    const { isFetching, ListServiceLine, isReadOnlyUser } = this.props;

    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page">
          {tabView ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="border-0">
                    <div className="mb-3">
                      <div className="row mt-5 d-flex align-items-center">
                        <div className="col-6">
                          {' '}
                          <h4 className="">SERVICES LIST</h4>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                          <GridSearch onSearchChange={this.onSearchChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row pt-3 pb-5 rcm-subtab-table-block">
                      <div className="col-12">
                        <Loader2 loading={isFetching} />
                        <PersistTableSort
                          name="ListServiceLine"
                          columns={columns}
                          dataSource={ListServiceLine}
                          size={'middle'}
                          // onRow={(record, rowIndex) => {
                          //   return {
                          //     onClick: event => this.onRowClick(record.ClientId),
                          //   };
                          // }}
                          // scroll={{ x: 1500, y: 300 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid px-0">
              <ServiceLineDetail
                initialValues={ServiceLineData}
                ServiceLineData={ServiceLineData}
                GroupAppointmentId={this.state.GroupAppointmentId}
                toggleView={this.toggleView}
                isCreated={this.props.isCreated}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  list_group_service_line,
  get_group_service_line,
  upsert_service_line,
}) => {
  const { data: listServiceLineData, isFetching } = list_group_service_line;
  const { data: getServiceLineData } = get_group_service_line;

  let ListServiceLine = [];
  let ServiceLineData = [];
  let isCreated = false;

  if (listServiceLineData && listServiceLineData.GroupServiceLine) {
    ListServiceLine = listServiceLineData.GroupServiceLine || [];
  }
  if (getServiceLineData && getServiceLineData.GroupServiceLine) {
    ServiceLineData = getServiceLineData.GroupServiceLine || [];
  }
  if (upsert_service_line && upsert_service_line.data && upsert_service_line.data.success) {
    isCreated = upsert_service_line.data.success;
  }
  return {
    isCreated,
    isFetching,
    ListServiceLine: ListServiceLine,
    ServiceLineData: ServiceLineData,
  };
};

export default connect(mapStateToProps)(withRouter(ManageGroup));
