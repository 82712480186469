import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class DateType extends Component {
  constructor(props) {
    super(props);
    const {
      survey: { Response },
    } = this.props;
    this.state = {
      dateValue: Response ? moment(Response).toDate() : '',
    };
  }

  onClick() {
    if (this.state.dateValue) {
      const {
        survey: { SurveyQuestionId },
        setValue,
        scope,
      } = this.props;
      setValue.call(scope, {
        response: moment(this.state.dateValue).format('YYYY-MM-DD'),
        surveyQuestionId: SurveyQuestionId,
      });
    }
    $('.main-banner').slick('slickNext');
  }
  handleChange = dateValue => {
    this.setState({
      dateValue: dateValue,
    });
  };
  // onChange = (event, click) => {
  //   const { value } = event.target;
  //   const {
  //     survey: { SurveyQuestionId, MaxValue, MinValue },
  //     setValue,
  //     scope,
  //   } = this.props;
  //   if (value) {
  //     if (value >= MinValue && value <= MaxValue) {
  //       this.setState({ value: value });
  //       setValue.call(scope, { response: value, surveyQuestionId: SurveyQuestionId });
  //     }
  //   } else {
  //     if (value >= MinValue && value <= MaxValue) this.setState({ value: value });
  //   }
  // };

  componentWillReceiveProps(props) {
    const {
      survey: { Response },
    } = this.props;
    if (Response) {
      this.setState({ value: Response });
    }
  }

  render() {
    const {
      survey: {
        Question,
        DoesNotApplyText,
        DoesNotApplyValue,
        SurveyId,
        SurveyQuestionId,
        MaxValue,
        MinValue,
      },
      setValue,
      scope,
      index,
      total,
    } = this.props;
    const ExampleCustomInput = ({ value, onClick }) => {
      return (
        <input
          type="text"
          class="form-control datepicker"
          placeholder="Select Date"
          value={value}
          onClick={onClick}
        />
      );
    };
    return (
      <div className=" card border-0">
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <div className="d-flex flex-column">
            <h5 className="survey-question">
              {Question && renderHTML(Question)}
              {/* <span class="d-md-block">these psychological or emotional problems?</span> */}
            </h5>
            <div class="form-group survey-question-date">
              <DatePicker
                showYearDropdown
                // disabled={isReadOnly}
                selected={this.state.dateValue}
                // onChange={this.handleChange}
                placeholder="Select Date"
                dateFormat="dd-MMM-yyyy"
                // dateFormat="MMMM d, yyyy"
                // placeholderText="Select Date"
                className="form-control datepicker "
                customInput={<ExampleCustomInput />}
                onChange={dateValue => {
                  this.handleChange(dateValue);
                  // setValue.call(scope, {
                  //   response: moment(dateValue).format('YYYY-MM-DD'),
                  //   surveyQuestionId: SurveyQuestionId,
                  //   level: level,
                  //   subLevel: subLevel,
                  //   subCatLevel: subCatLevel,
                  //   index: index,
                  // });
                }}
                // minDate={new Date()}
              />
              <i class="far fa-calendar-alt form-control-icon" />
            </div>
            <div
              style={{
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
              }}
            >
              <button class="btn px-5" onClick={this.onClick.bind(this)} tabindex="0">
                Next
              </button>
            </div>
            {DoesNotApplyText && (
              <div className="not-apply-to-me">
                <a
                  name="current-situation"
                  value={DoesNotApplyText}
                  onClick={this.props.onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: DoesNotApplyValue,
                  })}
                >
                  {DoesNotApplyText}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DateType;
