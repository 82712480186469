import React, { Component } from 'react';

import { Table } from 'antd';
import renderHTML from 'react-render-html';
import _blank from '../../images/blank-profile.png';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';
import ExportTableCSV from '../../Components/ExportTableCSV';
class CustomTable extends Component {
  constructor() {
    super();
    this.state = {
      tableColumns: [],
      tableDataSource: [],
    };
  }

  componentDidMount() {
    if (this.props.rowData) {
      this.getTableData(this.props.rowData);
    }
  }

  componentWillReceiveProps({ rowData }) {
    if (rowData && JSON.stringify(rowData) != JSON.stringify(this.props.rowData)) {
      this.getTableData(rowData);
    }
  }

  getTableData = rowData => {
    let keys = Object.keys(rowData[0]);
    let reportArr = Object.values(rowData[0]);
    let columns = keys.map((key, index) => {
      // let requiredSortingPattern = 'isComparer';
      // let requiredSortingPattern = isNaN(reportArr[index]) ? 'isNumericComparer' : 'isComparer';
      // rowData.map((row, index) => {
      //   if (row.ReactSortType) {
      //     var mystring = new String(`${row.ReactSortType}`);
      //     mystring = mystring.substring(1, mystring.length - 1);
      //     requiredSortingPattern = mystring;
      //   }
      //   return;
      // });
      // let requiredSortingPattern;
      // if (typeof reportArr[index] === 'string') {
      //   requiredSortingPattern = this.props.selectSorter && this.props.selectSorter[0];
      // } else if (typeof reportArr[index] === 'number') {
      //   requiredSortingPattern = this.props.selectSorter && this.props.selectSorter[1];
      // }
      let requiredSortingPattern =
        typeof reportArr[index] === 'string'
          ? this.props.selectSorter[0]
          : this.props.selectSorter[1];
      let sorterPattern;
      switch (requiredSortingPattern) {
        case 'isComparer':
          sorterPattern = (a, b) => isComparer(a[key], b[key]);
          break;
        case 'isNumericComparer':
          sorterPattern = (a, b) => isNumericComparer(a[key], b[key]);
          break;
        case 'isDateComparer':
          sorterPattern = (a, b) => isDateComparer(a[key], b[key]);
          break;
        default:
          sorterPattern = false;
      }
      if (key == 'ReactSortType') {
        // requiredSortingPattern = {};
        return {};
      } else {
        return {
          title: key.charAt(0).toUpperCase() + key.slice(1),
          align: typeof reportArr[index] === 'string' ? 'left' : 'right',
          dataIndex: key,
          key: key,
          sorter: sorterPattern,
          // sorter: (a, b) => isComparer(a[key], b[key]),
          // The result of this.props.selectSorter() => { }(a, b) => isComparer(a[key], b[key]),
          // responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
        };
      }
    });

    let dataSource = rowData.map((row, index) => {
      if (row.ReactSortType) {
      }
      let k = keys[index];
      return {
        ...row,
        // key: index,
        [k]: row[k],
      };
    });
    columns = columns.map(item => {
      if (item.key == 'Photo') {
        return {
          ...item,
          render: (text, student) => (
            <div className="d-flex align-items-center">
              <div class="student-list-pic" style={{ flex: '1 0 29%' }}>
                <img
                  src={student.Photo || _blank}
                  alt="student"
                  class="img-circle"
                  height="40"
                  width="40"
                  style={{ borderRadius: '50%', marginRight: '10px', objectFit: 'cover' }}
                />
              </div>
            </div>
          ),
        };
      } else if (item.key == 'Note' || item.key == 'Notes') {
        return {
          ...item,
          render: text => text && text.length > 0 && renderHTML(text),
        };
      } else if (item.key.includes('Date')) {
        return {
          ...item,
          render: text => <span className="text-nowrap">{text}</span>,
        };
      } else {
        return item;
      }
    });
    this.setState({
      tableColumns: (columns && columns) || [],
      tableDataSource: (dataSource && dataSource) || [],
    });
  };
  render() {
    let { tableColumns, tableDataSource } = this.state;
    // if (tableColumns.length) {
    //   if (Object.keys(tableColumns[tableColumns.length - 1]).length == 0) {
    //     tableColumns.pop();
    //   }
    // }
    const { size, heading, pagination, bordered, scroll, pageSize, reportName } = this.props;
    return (
      <div className="report-table">
        <Table
          dataSource={tableDataSource}
          columns={tableColumns}
          size={size || 'default'}
          pagination={pagination}
          bordered={bordered}
          title={data => {
            return (
              <div className="d-flex align-items-center justify-content-between">
                <div>{heading}</div>
                {!this.props.isReadOnlyUser && data && data.length > 0 && (
                  <div>
                    <ExportTableCSV
                      reportData={data}
                      labelText={'CSV'}
                      isHideLable={false}
                      filename={reportName}
                    />
                  </div>
                )}
              </div>
            );
          }}
          scroll={{ y: scroll }}
          // pagination={{ pageSize: pageSize }}
        />
      </div>
    );
  }
}

export default CustomTable;
