import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Slider, Switch } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { getAdvisorCost, upsertAdvisorCost, resetUpsertAdvisorCost } from '../../store/actions';
import { currencyFormatter, numberFormat } from '../../util/formatter';
import Loader, { Loader2 } from '../../Components/Loader';
import CustomModal from '../../core/modal';

import SessionBlock from './custom/sessionBlock';

class IndividualPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      costPerValue: 0,
      costMonthlyValue: 0,
      upsertModelOpen: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(getAdvisorCost({}));
  }

  componentWillReceiveProps({
    Advisors,
    CostPerSession,
    FilterBy,
    Messages,
    MonthlyCost,
    isUpsert,
  }) {
    if (
      CostPerSession &&
      JSON.stringify(CostPerSession) != JSON.stringify(this.props.CostPerSession)
    ) {
      this.setState({
        costPerValue: CostPerSession.CurrentCost || 0,
      });
      // this.handleCostPer(CostPerSession.CurrentCost || 0);
    }
    if (MonthlyCost && JSON.stringify(MonthlyCost) != JSON.stringify(this.props.MonthlyCost)) {
      this.setState({
        costMonthlyValue: MonthlyCost.CurrentCost || 0,
      });
      // this.handleCostMonthly(MonthlyCost.CurrentCost || 0);
    }
    if (isUpsert && JSON.stringify(isUpsert) != JSON.stringify(this.props.isUpsert)) {
      this.setState({ upsertModelOpen: true });
      this.props.dispatch(resetUpsertAdvisorCost({}));
    }
  }

  handleCostPer = value => {
    const { CostPerSession, Messages } = this.props;
    let { GreenRangeStart, GreenRangeEnd } = CostPerSession;
    let costPerColorCls = '';
    let costPerNotificationText = '';
    if (value < GreenRangeStart) {
      costPerNotificationText = Messages[0].PriceLevelMessage;
      costPerColorCls = 'warning-notification-block';
    }
    if (value > GreenRangeStart && value < GreenRangeEnd) {
      costPerNotificationText = Messages[2].PriceLevelMessage;
      costPerColorCls = 'success-notification-block';
    }
    if (value > GreenRangeEnd) {
      costPerNotificationText = Messages[1].PriceLevelMessage;
      costPerColorCls = 'warning-notification-block';
    }
    this.setState({ costPerValue: value, costPerColorCls, costPerNotificationText });
  };

  handleCostMonthly = value => {
    const { MonthlyCost, Messages } = this.props;
    let { GreenRangeStart, GreenRangeEnd } = MonthlyCost;
    let monthlyColorCls = '';
    let monthlyNotificationText = '';
    if (value < GreenRangeStart) {
      monthlyNotificationText = Messages[0].PriceLevelMessage;
      monthlyColorCls = 'warning-notification-block';
    }
    if (value > GreenRangeStart && value < GreenRangeEnd) {
      monthlyNotificationText = Messages[2].PriceLevelMessage;
      monthlyColorCls = 'success-notification-block';
    }
    if (value > GreenRangeEnd) {
      monthlyNotificationText = Messages[1].PriceLevelMessage;
      monthlyColorCls = 'warning-notification-block';
    }
    this.setState({ costMonthlyValue: value, monthlyColorCls, monthlyNotificationText });
  };

  SavePerSessionCost = () => {
    const { costPerValue, costMonthlyValue } = this.state;
    const { Advisors } = this.props;
    this.props.dispatch(
      upsertAdvisorCost({
        Json: JSON.stringify({
          AdvisorCost: [
            {
              PerSessionCost: costPerValue,
              MonthlyCost: costMonthlyValue,
              Advisors: Advisors,
            },
          ],
        }),
      }),
    );
    // {"AdvisorCost":[{"PerSessionCost":50,"MonthlyCost":180,"Advisors":[{"UserGuid":"EB3E9EC2-4348-4754-A027-58AF9AE538D0"},{"UserGuid":"3D8CABA3-8237-416D-AF67-BB1342621AFC"},{"UserGuid":"4833E380-FF86-4360-A0A0-80CE95BE8C5B"}]}]}
  };

  render() {
    const { CostPerSession, MonthlyCost, isFetching } = this.props;
    const {
      costPerValue,
      costMonthlyValue,
      costPerColorCls,
      costPerNotificationText,
      monthlyColorCls,
      monthlyNotificationText,
    } = this.state;

    const CostPerSessionMarks = {
      [CostPerSession.GreenRangeStart]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(CostPerSession.GreenRangeStart)}</strong>,
      },
      [CostPerSession.GreenRangeEnd]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(CostPerSession.GreenRangeEnd)}</strong>,
      },
      // [CostPerSession.RecommendedCost]: {
      //   style: {
      //     fontSize: '12px',
      //     color: '#979797',
      //   },
      //   label: (
      //     <strong>{currencyFormatter.format(CostPerSession.RecommendedCost)} Recommended</strong>
      //   ),
      // },
    };
    const MonthlyCostMarks = {
      [MonthlyCost.GreenRangeStart]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(MonthlyCost.GreenRangeEnd)}</strong>,
      },
      [MonthlyCost.GreenRangeEnd]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(MonthlyCost.GreenRangeEnd)}</strong>,
      },
      // [MonthlyCost.RecommendedCost]: {
      //   style: {
      //     fontSize: '12px',
      //     color: '#979797',
      //   },
      //   label: (
      //     <span style={{ color: 'red' }}>
      //       {currencyFormatter.format(MonthlyCost.RecommendedCost)} Recommended
      //     </span>
      //   ),
      // },
    };
    return (
      <div class="">
        <div className="individual-pricing p-5">
          <Loader2 loading={isFetching} />
          <h4 className="pricing-heading">Define your pricing for loremipsum</h4>
          <div className="pricing-section">
            <div>
              <SessionBlock
                labelText={'Cost per session'}
                icon={'clock.svg'}
                included={false}
                handleCostPer={this.handleCostPer}
                costPerValue={costPerValue}
                costPerColorCls={costPerColorCls}
                tooltipVisible={true}
                costPerNotificationText={costPerNotificationText}
                marks={CostPerSessionMarks}
                max={CostPerSession.MaximumCost}
                min={CostPerSession.MinimumCost}
              />
            </div>
            <div>
              <SessionBlock
                labelText={'Monthly Cost'}
                icon={'calender.svg'}
                included={false}
                handleCostPer={this.handleCostMonthly}
                costPerValue={costMonthlyValue}
                costPerColorCls={monthlyColorCls}
                tooltipVisible={true}
                costPerNotificationText={monthlyNotificationText}
                marks={MonthlyCostMarks}
                max={MonthlyCost.MaximumCost}
                min={MonthlyCost.MinimumCost}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right mt-2">
              <input
                type="button"
                value="Save"
                class="btn btn-sm"
                style={{ minWidth: '146px', padding: '5px 20px' }}
                onClick={this.SavePerSessionCost}
              />
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.upsertModelOpen}
          header={'Your changes have been saved successfully'}
          yesAction={() => {
            this.setState({ upsertModelOpen: false });
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { get_advisor_cost, upsert_advisor_cost } = state;
  const { data, isFetching, error } = get_advisor_cost;
  const { data: upsertData, isFetching: isFetchingUpsert } = upsert_advisor_cost;

  let Advisors = [];
  let CostPerSession = {};
  let FilterBy = [];
  let Messages = [];
  let MonthlyCost = {};
  let isUpsert = false;

  if (data && data.Advisors) {
    Advisors = data.Advisors || [];
  }
  if (data && data.PerSessionCost) {
    CostPerSession = data.PerSessionCost[0] || {};
  }
  if (data && data.Messages) {
    Messages = data.Messages || [];
  }
  if (data && data.FilterBy) {
    FilterBy = data.FilterBy || [];
  }
  if (data && data.MonthlyCost) {
    MonthlyCost = data.MonthlyCost[0] || {};
  }
  if (upsertData && upsertData.success) {
    isUpsert = upsertData.success;
  }
  return {
    Advisors,
    CostPerSession,
    FilterBy,
    Messages,
    MonthlyCost,
    isFetching: isFetching || isFetchingUpsert,
    isUpsert,
  };
};
export default connect(mapStateToProps)(IndividualPricing);
