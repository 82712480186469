import React, { Component } from 'react';
import { Tabs, Progress, Slider } from 'antd';
import { barData, barOptions } from './chartData';
import { Bar } from 'react-chartjs-2';

const { TabPane } = Tabs;

class ProgressPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const value = 40;
    return (
      <div class="progress-tabs">
        <div class="main_progress">
          <h3>Progress</h3>
        </div>
        <Tabs tabPosition={'top'}>
          <TabPane tab="Primary Measures" key="1">
            <div class="card border-0 shadow_progress" style={{ margin: '0px 15px' }}>
              <div class=" row card-body">
                <div class="col-sm-4 text-center">
                  <div class="mb-4">
                    <Progress
                      type="circle"
                      strokeWidth={7}
                      strokeColor="#77B1FF"
                      width={150}
                      percent={90}
                      format={percent => (
                        <div class="progress-textbox">
                          <strong>{percent}</strong>
                          score
                        </div>
                      )}
                      trailColor={'#eff7fe'}
                    />
                  </div>
                  <h5 class="font-weight-semibold">Psychosocial</h5>
                </div>
                <div class="col-sm-4 text-center">
                  <div class="mb-4">
                    <Progress
                      type="circle"
                      strokeWidth={7}
                      strokeColor="#8ACB91"
                      width={150}
                      percent={63}
                      format={percent => (
                        <div class="progress-textbox">
                          <strong>{percent}</strong>
                          score
                        </div>
                      )}
                      trailColor={'#eefdf8'}
                    />
                  </div>
                  <h5 class="font-weight-semibold">Faith</h5>
                </div>
                <div class="col-sm-4 text-center">
                  <div class="mb-4">
                    <Progress
                      type="circle"
                      strokeWidth={7}
                      width={150}
                      strokeColor="#FFB37F"
                      percent={32}
                      format={percent => (
                        <div class="progress-textbox">
                          <strong>{percent}</strong>
                          score
                        </div>
                      )}
                      trailColor={'#fef2f2'}
                    />
                  </div>
                  <h5 class="font-weight-semibold">Addiction Index</h5>
                </div>
                <div class="col-12 mb-5">
                  <h5 class="pt-4 font-weight-normal"> Ladders & Measures</h5>
                  <div class="" style={{ overflow: 'hidden' }}>
                    <Bar data={barData} options={barOptions} width={400} height={200} />
                  </div>
                </div>
              </div>
              <h3 class="system_title">System Observation</h3>
              <div class="system_observation">
                <div class="system_sec">
                  <h5>Admission of Need</h5>
                  <p>
                    Nec dubitamus multa iter quae et nos invenerat. Quisque placerat facilisis
                    egestas cillum dolore. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed
                    nibh.{' '}
                  </p>
                </div>
                <div class="system_sec">
                  <h5>Motivation to Change</h5>
                  <p>
                    Nec dubitamus multa iter quae et nos invenerat. Quisque placerat facilisis
                    egestas cillum dolore. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed
                    nibh.{' '}
                  </p>
                </div>
                <div class="system_sec">
                  <h5>Presence of Support</h5>
                  <p>
                    Nec dubitamus multa iter quae et nos invenerat. Quisque placerat facilisis
                    egestas cillum dolore. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed
                    nibh.{' '}
                  </p>
                </div>
                <div class="system_sec">
                  <h5>Structure and Skills</h5>
                  <p>
                    Nec dubitamus multa iter quae et nos invenerat. Quisque placerat facilisis
                    egestas cillum dolore. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed
                    nibh.{' '}
                  </p>
                </div>
                <div class="system_sec">
                  <h5>Proof of Change</h5>
                  <p>
                    Nec dubitamus multa iter quae et nos invenerat. Quisque placerat facilisis
                    egestas cillum dolore. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed
                    nibh.{' '}
                  </p>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Other Measures" key="2">
            <div class="shadow_progress">
              <div class="measure">
                <h5>Composite Measures</h5>
                <div class="arrow">
                  <a href="#" class="starting">
                    Starting
                  </a>
                  <a href="#" class="current">
                    Current
                  </a>
                </div>
                <div class="progress_bar">
                  <p>Addiction Index</p>
                  <Slider className="two-way-slider" value={[20, 30]} range reverse={false} />
                  <div class="indicator-text">
                    <span class="indicator-left-text">100</span>
                    <span class="indicator-right-text">0</span>
                  </div>
                </div>
                <div class="progress_bar progress-2">
                  <p>Faith</p>
                  <div class="progress">
                    <div class="progress-label" style={{ left: '0', right: '70%' }}>
                      <span class="progress-label-start-indicator">0</span>
                      <span class="progress-label-current-indicator">70</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Psychosocial</p>
                  <div class="progress">
                    <div class="progress-label" style={{ left: '65%', right: '9%' }}>
                      <span class="progress-label-start-indicator">65</span>
                      <span class="progress-label-current-indicator">84</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">100</span>
                    <span class="indicator-right-text">0</span>
                  </div>
                </div>
              </div>
              <div class="measure other_measure">
                <h5>Other Measures</h5>
                <div class="progress_bar">
                  <p>Streaks (App usage without a break)</p>
                  <Slider
                    value={value}
                    className="one-way-slider"
                    marks={{ 0: 0, [value]: value, 100: 100 }}
                  />
                  <div class="progress">
                    <div class="progress-label" style={{ right: '60%' }}>
                      <span class="progress-label-current-indicator">40</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Sobriety Update</p>
                  <div class="progress">
                    <div class="progress-label" style={{ right: '42%' }}>
                      <span class="progress-label-current-indicator">58</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Didn’t go into frowny places</p>
                  <div class="progress">
                    <div class="progress-label" style={{ right: '60%' }}>
                      <span class="progress-label-current-indicator">40</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Posts and comments</p>
                  <div class="progress">
                    <div class="progress-label" style={{ right: '68%' }}>
                      <span class="progress-label-current-indicator">32</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Chat with Coach</p>
                  <div class="progress">
                    <div class="progress-label" style={{ right: '42%' }}>
                      <span class="progress-label-current-indicator">58</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Chat with Friends</p>
                  <div class="progress">
                    <div class="progress-label" style={{ right: '42%' }}>
                      <span class="progress-label-current-indicator">58</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Surveys responded frequently</p>
                  <div class="progress">
                    <div class="progress-label" style={{ right: '60%' }}>
                      <span class="progress-label-current-indicator">40</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
                <div class="progress_bar">
                  <p>Measure the days feeling good/bad</p>
                  <div class="progress">
                    <div class="progress-label" style={{ right: '60%' }}>
                      <span class="progress-label-current-indicator">40</span>
                    </div>
                  </div>
                  <div class="indicator-text">
                    <span class="indicator-left-text">0</span>
                    <span class="indicator-right-text">100</span>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ProgressPage;
