import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Drawer, Table, Modal, Tooltip } from 'antd';
import {
  careTeamAssign,
  serviceCodeCareTeamGet,
  comboPractitionerAll,
  resetCareTeamAssign,
} from '../../../../../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../../util/handler';
import { AssignCareTeamMember } from './assignCareTeamMember';
import SubmitContract from '../submitContract';
import Loader, { Loader2 } from '../../../../../../../Components/Loader';
const AssignCareTeam = props => {
  const { smartContractId, clientId } = props;
  const dispatch = useDispatch();
  const [CareList, setCareList] = useState();
  const [CareTeamAssignedMsg, setCareTeamAssignedMsg] = useState();

  const { serviceCodeCareList, practitioner, isCareTeamAssigned,listFetching } = useSelector(
    ({
      service_code_care_team_get: { data: serviceCodeCareData, isFetching, error },
      combo_practitioner_all: { data: practitionerDataAll },
      care_team_assign: { data: careTeamAssignData },
    }) => {
      return {
        serviceCodeCareList: serviceCodeCareData && serviceCodeCareData.SmartContract,
        practitioner: practitionerDataAll && practitionerDataAll.PractitionerList,
        isCareTeamAssigned: careTeamAssignData && careTeamAssignData.success,
        listFetching: isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    let Data = {
      JSON: JSON.stringify({
        // SmartContract: [{ ClientId: clientId, SmartContractId: 685 }],
        SmartContract: [{ ClientId: clientId, SmartContractId: smartContractId }],
      }),
    };

    dispatch(serviceCodeCareTeamGet(Data));
    dispatch(
      comboPractitionerAll({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: false,
            },
          ],
        }),
      }),
    );
    return () => { 
      dispatch(resetCareTeamAssign({}));
    }
  }, []);
  useEffect(() => {
    if (serviceCodeCareList) {
      setCareList(serviceCodeCareList);
    }
  }, [serviceCodeCareList]);
  useEffect(() => {
    if (isCareTeamAssigned) {
      toast.success('Care Team Member Assigned Successfully', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [isCareTeamAssigned]);
  const handlePractitionerChange = (value, recordData, rowIndex) => {
    let Data = {
      JSON: JSON.stringify({
        SmartContract: [
          {
            SmartContractBundleServiceCodeId: recordData.SmartContractBundleServiceCodeId,
            PractitionerId: value,
          },
        ],
      }),
    };
    dispatch(careTeamAssign(Data));
    let updatedList = CareList;
    updatedList[rowIndex].PractitionerId = value;
    setCareList(updatedList);
  };
  const Columns = [
    {
      title: 'Bundle Name',
      dataIndex: 'ServiceCodeBundleName',
      key: 'ServiceCodeBundleName',
      sorter: (a, b) => isComparer(a.ServiceCodeBundleName, b.ServiceCodeBundleName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Service Type',
      dataIndex: 'ServiceType',
      key: 'ServiceType',
      sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Service Code',
      dataIndex: 'ServiceCode',
      key: 'ServiceCode',
      sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Code Description',
      dataIndex: 'CodeDescription',
      key: 'CodeDescription',
      sorter: (a, b) => isComparer(a.CodeDescription, b.CodeDescription),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Role',
      dataIndex: 'RoleDescription',
      key: 'RoleDescription',
      sorter: (a, b) => isComparer(a.RoleDescription, b.RoleDescription),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Assign Care Team Member',
      dataIndex: 'x',
      key: 'x',
      render: (text, record, index) => {
        return (
          <AssignCareTeamMember
            recordData={record}
            practitioner={practitioner}
            rowIndex={index}
            onChange={handlePractitionerChange}
          />
        );
      },
      //   sorter: (a, b) => isComparer(a.PercentageDistribution, b.PercentageDistribution),
      //   sortDirections: ['descend', 'ascend'],
    },
  ];
  return (
    <>
      <div style={listFetching ? { height: '200px', position: 'relative' } : { height: '0px' }}>
        <Loader2 loading={listFetching} />
      </div>
      {!listFetching && (
        <div className="assign-care-team p-3">
          <span className="label mb-2">Assign Care team MEMBER</span>
          <div className="row">
            <div className="col-12">
              <Table columns={Columns} dataSource={CareList} pagination={false} />
            </div>
            <div className="col-12 text-right mt-4 d-flex align-items-center justify-content-between">
              <div>
                {/* <SubmitContract smartContractId={smartContractId} clientId={clientId} /> */}
              </div>
              <div className="">
                <Button
                  type="primary"
                  onClick={() => {
                    props.goToPreviuos();
                  }}
                  className={`btn btn-outline-primary`}
                  style={{ height: '42px' }}
                >
                  Previous
                </Button>{' '}
                &nbsp;
                <Button
                  type="primary"
                  onClick={() => {
                    props.handleNext();
                  }}
                  className={`btn save-button`}
                  style={{ height: '42px' }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AssignCareTeam;
