import React, { Component } from 'react';
import _blank from '../../../images/blank-profile.png';
import { connect } from 'react-redux';
import { advisorSearch } from '../../../store/actions';
import SetReminderCheckbox from '../../../Components/setReminderCheckbox';
var timer;

class ReduxSelectGroupStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      searchStr: '',
      selectedStudent: [],
      searchedStudent: [],
      checkedItems: [],
      studentData: [
        {
          value: '100',
          label: 'Select All',
          children: [
            // {
            //   FirstName: 'John',
            //   LastName: 'Smith',
            //   UserGuid: '8CB7D774-A5E7-4261-A17D-C58EDD91A763',
            //   DisplayName: 'JohnSmith',
            //   ProfileImgUrl:
            //     'https://drattach.blob.core.windows.net/dr-blob-container-v1/920109c2-1ec8-4207-8c35-e90f4c34fed6.jpeg',
            //   JoinedDate: '01-Aug-2019',
            //   value: '8CB7D774-A5E7-4261-A17D-C58EDD91A763',
            //   label: 'JohnSmith',
            // },
          ],
        },
      ],
    };
  }

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  componentDidMount() {
    this.props.dispatch(advisorSearch({}));
    let userId = localStorage.getItem('userId');
    this.setState({
      userId: parseInt(userId),
    });
  }

  componentWillReceiveProps({ Advisors }) {
    if (JSON.stringify(Advisors) != JSON.stringify(this.props.Advisors)) {
      let newAdvisors;
      if (Advisors && Advisors.length > 0) {
        newAdvisors = Advisors.map(item => {
          return { ...item, value: item.StaffId };
        });
      } else {
        newAdvisors = [];
      }
      this.state.studentData = [
        {
          value: '100',
          label: 'Select All',
          children: newAdvisors,
        },
      ];
    }
  }

  onCheckBoxChange(e, item) {
    // current array of selected
    const selected = this.state.selected;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to selected array
      selected.push(item);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = selected.indexOf(item);
      selected.splice(index, 1);
    }
    // update the state with the new array of selected
    this.setState({ selected: selected });
    // this.props.updatedSelectedPersons(selected);
  }
  clearSelection = item => {
    let stateArr = this.state.selected;
    let index;
    index = stateArr.indexOf(item);
    stateArr.splice(index, 1);
    this.setState({ selected: stateArr });
  };
  getCheckItems = (data, index) => {
    let checkedItems = {
      ...this.state.checkedItems,
      [index]: data,
    };
    this.setState({ checkedItems: checkedItems[0] });
    this.props.updatedSelectedPersons(checkedItems);
  };
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      advisorSearch({
        Json: JSON.stringify({
          Staff: {
            SearchStr: value,
          },
        }),
      }),
    );
  };
  fnDebounce = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.onSearchChange(value);
    }, 300);
  };
  render() {
    const { onOk, onCancel, isFetching } = this.props;
    const { searchStr, selected, studentData, userId } = this.state;
    return (
      <div class="border-0">
        <div className="m-0 p-2">
          {studentData &&
            studentData.map((item, index) => {
              return (
                <SetReminderCheckbox
                  data={item}
                  index={index}
                  getCheckItems={this.getCheckItems}
                  getSelectAllValue={this.props.getSelectAllValue}
                  selectedPersons={this.props.selectedPersons}
                  isFetching={isFetching}
                  defaultValue={[userId]}
                  fnDebounce={this.fnDebounce}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToPropsGroupStudent = ({ advisor_search }) => {
  let Advisors = null;
  let searchStr;
  const { data, isFetching } = advisor_search;
  if (data && data.Advisors) {
    Advisors = data.Advisors;
  }

  return {
    Advisors,
    isFetching,
    searchStr,
  };
};

export default connect(mapStateToPropsGroupStudent)(ReduxSelectGroupStudentModal);
