import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import CustomTable from '../component/table';
// import { ReduxSelect } from '../component/custom-redux-components/index.js';
import { List, Typography, Divider, DatePicker as AntdDatePicker, Table } from 'antd';
import moment from 'moment';
import Reports from '../../report';
import { medicateCombos } from '../../../store/actions';

class RCMReports extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes: 'Status',
      ShowAll:true
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
  }
  CWReportData = {
    Json: JSON.stringify({
      Report: [
        {
          ReportSection: 'CW',
        },
      ],
    }),
  };
  render() {
    return <Reports CWReportData={this.CWReportData} isCWReport={true} />;
  }
}
export default withRouter(connect()(RCMReports));
