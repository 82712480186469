import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { clientInsuranceGet } from '../../../../../store/actions';

const PayerDetails = props => {
  const {
    ClientInsuranceId,
    PrimaryInsuranceId,
    InsuranceName,
    InsurerFirstName,
    InsurerLastName,
    InsurerMiddleName,
    PatientRelationshipToPrimaryInsured,
    InsurerGroupNumber,
    InsurerPlanName,
    InsuredAuthorization,
    InsurerDeductible,
    PatientCoPayment,
    InsurerReleaseOfInformation,
    InsurerSignatureDate,
    ClientSubscriberId,
    SubscriberGroupNumber,
    SubscriberFirstName,
    SubscriberLastName,
    SubscriberMiddleName,
    SubscriberAddressLine1,
    SubscriberAddressLine2,
    SubscriberCity,
    SubscriberStateOrProvince,
    SubscriberPostalCode,
    SubscriberDOB,
    SubscriberSSN,
    SubscriberGender,
    SubscriberMedicaidId,
    Notes,
    PriorityOrder,
    ClientInsuranceEligibility,
    PatientRelationshipToPrimaryInsuredText,
    InsuredAuthorizationText,
  } = (props && props.iData) || {};
  const dispatch = useDispatch();
  const [isPayerScreen, setIsPayerScreen] = useState(window.location.href.includes('payer'));
  const labelValue = [
    { label: isPayerScreen ? 'Payer Name' : 'Insurance Provider Name', value: InsuranceName },
    { label: 'Insurance Name', value: InsuranceName },
    { label: 'Plan Name', value: InsurerPlanName },
    {
      label: 'Patient Relationship to Primary Insured',
      value: PatientRelationshipToPrimaryInsuredText,
    },
    { label: 'Insured Authorization', value: InsuredAuthorizationText },
    { label: 'Insurance ID', value: PrimaryInsuranceId },
    { label: 'Medicaid ID', value: SubscriberMedicaidId },
    { label: 'Group Number', value: SubscriberGroupNumber },
  ];

  const getClientId = () => {
    var param = new URLSearchParams(props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  };

  // React.useEffect(() => {
  //   dispatch(
  //     clientInsuranceGet({ Json: JSON.stringify({ Client: [{ ClientId: getClientId() }] }) }),
  //   );
  // }, []);

  return (
    <>
      <div className={isPayerScreen ? '' : 'container mt-5'}>
        <div className="payer-details p-3 mx-2">
          <div className="row">
            <div className="col-md-12">
              <div className="label mt-2 mb-2">
                {isPayerScreen ? 'INSURANCE DETAILS' : 'Payer Details'}
              </div>
            </div>
          </div>
          <div className="row details mt-1 mb-3">
            {labelValue.map(item => {
              return (
                <div className="col-md-3 mt-2 mb-2">
                  <div className="label">{item.label}</div>
                  <div className="value">{item.value}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ client_insurance_get }) => {
  const { data } = client_insurance_get;
  let iData = null;
  if (data && data.ClientInsurance) {
    iData = data.ClientInsurance.filter(item => {
      return item.PriorityOrder == 1;
    })[0];
  }
  return {
    iData,
  };
};

export default connect(mapStateToProps)(withRouter(PayerDetails));
