import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isComparer, isDateComparer } from '../util/handler';
import { adminstrationListCourse } from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomModal from '../Components/CustomModal';
import { Checkbox, Radio } from 'antd';
import PageTitle from '../Components/PageTitle';
import DynamicGrid from '../Components/DynamicGrid';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import { ProcessCombos } from '../store/comboConfig';
import { DashboardAppSubscribers } from '../app-config/screenName';
// import MultiStep from './staffform/multistep';
class GridData extends Component {
  state = {
    searchStr: null,
  };

  render() {
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Grid Data" />
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.goBack()}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Grid Data</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end"></div>
          </div>
          <div className="dynamic-grid">
            <DynamicGrid ScreenName={DashboardAppSubscribers} />
            {/* <MultiStep /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(GridData);
