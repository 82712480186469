import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Emergency extends Component {
  state = {};
  render() {
    return (
      <div class="container-fluid">
        <div class="content-area">
          <div class="row">
            <div class="col-md-8 mar-top-30">
              <h1 class="main-head">NEW ENROLLMENT</h1>
            </div>
            <div class="col-md-4 mar-top-30">
              {/* <!--<Link to="#" class="dashboard-btns">NEW ENROLLMENT</Link>--> */}
            </div>
          </div>
          <div class="row">
            <div class="col-12 mar-top-15">
              <div class="inner-form">
                <div class="row">
                  <div class="col-md-4 mar-top-30">
                    <h2 class="sub-head text-uppercase">emergency contact</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5 mar-top-15">
                    <label class="control-label">Full Name</label>
                    <input name="textinput" type="text" class="form-control" />

                    <div class="mar-top-15">
                      <Link to="#" class="add-emergency-more">
                        Add More Emergency Contact
                      </Link>
                    </div>
                  </div>
                  <div class="col-md-3 mar-top-15">
                    <label class="control-label">Phone Number</label>
                    <input name="textinput" type="text" class="form-control" />
                  </div>
                  <div class="col-md-4 mar-top-15">
                    <label class="control-label">E-mail</label>
                    <input name="textinput" type="email" class="form-control" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mar-top-50">
                    <h2 class="sub-head text-uppercase">Insurance Details</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5 mar-top-15">
                    <label class="control-label">Primary Insurance</label>
                    <input name="textinput" type="text" class="form-control" />
                  </div>
                  <div class="col-md-3 mar-top-15">
                    <label class="control-label">ID</label>
                    <input name="textinput" type="text" class="form-control" />
                  </div>
                  <div class="col-md-4 mar-top-15">
                    <label class="control-label">Group</label>
                    <input name="textinput" type="text" class="form-control" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mar-top-30 text-right">
                    <input type="button" class="form-control btn " value="Cancel" /> &nbsp;
                    <input type="button" class="form-control btn black-btn" value="Submit" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Emergency;
