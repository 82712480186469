import React, { Component } from 'react';
import { Tooltip } from 'antd';

const ActionableIcon = props => {
  const { onClick, iconSrc, title, disabled, style } = props;
  return (
    <figure
      className="action-button-icon mx-1"
      style={{
        display: 'inline-block',
        position: 'relative',
        // marginTop: '5px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
      onClick={!disabled && onClick}
    >
      <Tooltip placeholder="top" title={title}>
        <img src={iconSrc} alt="" width="50" />
      </Tooltip>
    </figure>
  );
};

export default ActionableIcon;
