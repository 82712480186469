import React, { Component } from 'react';
import { Radio, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { v4 as uuid } from 'uuid';
import { ReduxInput, ReduxSelect } from '../component/custom-redux-components';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import CustomModal from '../Components/CustomModal';
import PageTitle from '../Components/PageTitle';

import {
  addLicenses as setLicenses,
  getStripeApiKey,
  listCombos,
  getPaymentInfo,
  resetAddLicenses,
  getBillingInfo,
  userFeatureFlagGet,
  resetSetLicenses,
} from '../store/actions';
// import Validator from './enrollmentForm/validation/validate';
import Loader, { Loader2 } from '../Components/Loader';
import LicenseModal from './licenseAgreementModal';
import TermsAndConditionModal from './termsAndConditionModal';
import { ProcessCombos } from '../store/comboConfig';
import { numberFormat } from '../util/formatter';
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
const Validator = {
  required: value => (value ? undefined : 'Field Required'),
  accountName: value =>
    value && !/^.{1,50}$/.test(value) ? ' cannot exceed 50 characters' : undefined,
  routingNumber: value =>
    value && !/^[0-9]{9}$/.test(value) ? ' is a 9 digit numeric Value' : undefined,
  accountNumber: value =>
    value && !/^[0-9]{4,20}$/.test(value)
      ? ' has a minimum of 4 and a maximum of 20 digits'
      : undefined,
  email: value => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Address Invalid ';
      } else {
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  confirmEmail: (value, values) => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        if (value != values.email) {
          return 'Email and Confirm Email must be same';
        }
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? ' numeric values only' : undefined,
  usaZip: (value, values, props, name) =>
    value && !/^[0-9]{5}$/.test(value) ? 'code is invalid for USA' : undefined,
  // zip: value => (value && !/^[a-zA-Z0-9_ ]{5,9}$/.test(value) ? 'code is invalid' : undefined),
  //allowing hyphens also in zip code now
  // zip: (value, values, props, name) => {
  //   if (values.billingCountry === 'USA') {
  //     return value && !/^[0-9]{5}$/.test(value) ? 'code is invalid for USA' : undefined;
  //   } else {
  //     return value && !/^[\-a-zA-Z0-9_ ]{5,9}$/.test(value) ? 'code is invalid' : undefined;
  //   }
  // },
};
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class AddLicenseModal extends Component {
  render() {
    const { onOk, onCancel, handleSubmit, onModalCancel, addLicenseResult, history } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          {/* <h4 class="modal-title col-12">Welcome {localStorage.getItem('userName')}</h4> */}
        </div>
        <div class="modal-body col-md-10 offset-md-1 enrollment-form pb-2">
          <div style={{ textAlign: 'justify', fontWeight: 600 }}>
            <h5>{addLicenseResult}</h5>
          </div>
        </div>
        <div class="modal-footer justify-content-center border-top-0">
          <button
            type="button"
            class="btn black-btn font-weight-bold mr-2"
            onClick={() => {
              this.props.history.push('/manage/staff-management/staff-list');
              onModalCancel();
            }}
          >
            Ok
          </button>
          {/* <input type="submit" class="btn black-btn font-weight-bold mr-2" value=" Send  " /> */}
        </div>
      </div>
    );
  }
}
class Completepurchase extends Component {
  constructor(props) {
    super(props);
    const { checked } = props;
    this.state = {
      checked: checked || '',
      CreditDebitCard: true,
      Checking: false,
      value: 'Credit/Debit',
      errorMessage: '',
      addLicenseModal: false,
      isEditPaymentMode: true,
      termsCondition: false,
      licenseAgreementModal: false,
      termsAndConditionModal: false,
    };
  }
  componentDidMount() {
    var result = ProcessCombos('Country,StateProvince');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.setState({
      errorMessage: '',
    });
  }

  componentWillReceiveProps({ isAddLicense, paymentInfo, countryISO, addLicenseResult }) {
    if (isAddLicense != this.props.isAddLicense) {
      this.props.dispatch(userFeatureFlagGet({}));
      this.props.dispatch(resetAddLicenses());
      this.setState({
        addLicenseModal: isAddLicense,
        errorMessage: '',
      });
      sessionStorage.removeItem('state');
      sessionStorage.removeItem('isBack');
    }
    if (addLicenseResult && addLicenseResult != 'SUCCESS' && !isAddLicense) {
      this.props.getIdempotencyKey();
    }
    if (countryISO && countryISO != this.props.countryISO) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
    if (JSON.stringify(paymentInfo) != JSON.stringify(this.props.paymentInfo)) {
      this.setState({
        isEditPaymentMode: false,
      });
    }
  }
  onToggle = () => {
    this.setState({
      isEditPaymentMode: !this.state.isEditPaymentMode,
    });
  };
  onModalCancel = () => {
    this.setState({
      addLicenseModal: false,
    });
  };
  handleChange = VALUES => {
    const { error } = VALUES;
    if (error) {
      this.setState({ errorMessage: error.message });
    } else {
      this.setState({ errorMessage: '' });
    }
  };
  handleSubmit = async evt => {
    const { stripe, elements, handleResult, paymentInfo } = this.props;
    const { isEditPaymentMode } = this.state;
    let paymentResponse = {};
    if (!paymentInfo || (paymentInfo && isEditPaymentMode)) {
      if (stripe) {
        let number = elements.getElement(CardNumberElement);
        let cvc = elements.getElement(CardCvcElement);
        let exp = elements.getElement(CardExpiryElement);
        if (this.state.value === 'Credit/Debit') {
          let creditRes = await stripe.createPaymentMethod({
            type: 'card',
            card: number,
            billing_details: {
              name: evt.billingName,
              email: evt.billingEmail,
              address: {
                city: evt.city,
                line1: evt.billingAddress,
                postal_code: evt.postalCode,
                state: evt.billingState,
                // country: evt.billingCountry,
              },
            },
          });
          handleResult(creditRes, evt, 'card', isEditPaymentMode);
        }
        if (this.state.value === 'Checking') {
          // stripe.createToken().then(res => handleResult(res, evt));

          let BankRes = await stripe.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: evt.routingNumber,
            account_number: evt.accountNumber,
            account_holder_name: evt.accountName,
            account_holder_type: evt.accountType,
          });
          //  paymentType: 'card',
          handleResult(BankRes, evt, 'ach', isEditPaymentMode);
        }
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    } else {
      handleResult(paymentResponse, evt, 'same', isEditPaymentMode);
    }
  };

  onChange = e => this.setState({ value: e.target.value });
  resetState = () => this.setState({ checked: '' });
  makeAddCopy = e => {
    const { billingDetails } = this.props;
    if (e.target.checked) {
      this.props.autofill('shippingName', billingDetails.billingName);
      this.props.autofill('shippingEmail', billingDetails.billingEmail);
      this.props.autofill('shippingAddress', billingDetails.billingAddress);
      this.props.autofill('shippingPostalCode', billingDetails.postalCode);
      this.props.autofill('shippingCity', billingDetails.city);
      this.props.autofill('shippingState', billingDetails.billingState);
      this.props.autofill('billingCountry', billingDetails.billingCountry);
    } else {
      this.props.autofill('shippingName', '');
      this.props.autofill('shippingEmail', '');
      this.props.autofill('shippingAddress', '');
      this.props.autofill('shippingPostalCode', '');
      this.props.autofill('shippingCity', '');
      this.props.autofill('billingState', '');
      this.props.autofill('billingCountry', '');
    }
  };
  termsCondition = () => {
    this.setState({
      termsCondition: !this.state.termsCondition,
    });
  };
  error = msg => {
    Modal.error({
      content: msg,
    });
  };
  onLicenseModalCancel = () => {
    this.setState({
      licenseAgreementModal: false,
    });
    sessionStorage.setItem('firstLogin', false);
  };
  onConditionModalCancel = () => {
    this.setState({
      termsAndConditionModal: false,
    });
    sessionStorage.setItem('firstLogin', false);
  };
  render() {
    const {
      CurrentLicenseData,
      LicenseCounts,
      Total,
      salesTax,
      handleSubmit,
      billingDetails,
      submitting,
      StateProvince,
      isFetching,
      addLicenseResult,
      isAddLicense,
      paymentInfo,
      ProRatedCostTotal,
      TotalNewTokenCount,
      TotalNewTokenCost,
      Country,
    } = this.props;
    const { isEditPaymentMode } = this.state;
    const { StateLicenseData } = LicenseCounts;
    return (
      <div class="main">
        <PageTitle TitleText="Complete Your Purchase" />
        <Loader loading={isFetching} />
        <div class="admin-maintain">
          <div class="container-fluid">
            <div class="row top-table-search mt-5 mb-4 align-items-center">
              <div class="col-12">
                <a
                  class="back-link"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <img src="images/back-arrow.svg" /> Back
                </a>
                <h3 class="page-title mb-3 mb-md-0">Complete Your Purchase</h3>
              </div>
            </div>
            <div class="payment-dashboard">
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                <div class="row">
                  <div class="col-md-12 col-lg-7 col-xl-8">
                    <div class="payment-dashboard-detail enrollment-form">
                      <div class="payment-label" style={{ textAlign: 'left' }}>
                        Billing Address
                      </div>
                      <div class="row">
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingName"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              className="form-control"
                              fieldName="Name"
                            />
                            <label class="placeholder-label">Name*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingEmail"
                              type="email"
                              component={ReduxInput}
                              validate={[Validator.required, Validator.email]}
                              placeholder="someone@domain.com"
                              className="form-control"
                              fieldName="Email"
                            />
                            <label class="placeholder-label">Email*</label>
                          </div>
                        </div>
                        <div class="col-12  col-lg-12 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingAddress"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              // placeholder="Address"
                              className="form-control"
                              fieldName=" Address"
                            />
                            <label class="placeholder-label">Billing Address*</label>
                          </div>
                        </div>

                        <div class="col-12 col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingCountry"
                              type="text"
                              errorMessage="Country Required"
                              component={ReduxSelect}
                              // options={Country}
                              options={[
                                { Value: 'USA', Text: 'United States of America' },
                                { Value: 'CAN', Text: 'Canada' },
                                { Value: 'AUS', Text: 'Australia' },
                              ]}
                              validate={Validator.required}
                              placeholder="Country*"
                              fieldName={'Country'}
                            />
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="city"
                              type="text"
                              errorMessage=""
                              component={ReduxInput}
                              validate={Validator.required}
                              // placeholder="City"
                              className="form-control"
                              fieldName="City"
                            />
                            <label class="placeholder-label">City*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group combo-dropdown">
                            <Field
                              name={`billingState`}
                              type="text"
                              placeholder={`State/Province/Territories*`}
                              component={ReduxSelect}
                              validate={Validator.required}
                              options={StateProvince}
                            />
                            {/* <label class="placeholder-label">City</label> */}
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="postalCode"
                              type="text"
                              component={ReduxInput}
                              validate={[Validator.required]}
                              // placeholder="Postal Code"
                              className="form-control"
                              fieldName="Postal Code"
                            />
                            <label class="placeholder-label">Postal Code*</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      open={this.state.addLicenseModal}
                      // visible={true}
                      footer={null}
                      mask
                      maskClosable={false}
                      onCancel={this.onModalCancel}
                    >
                      <AddLicenseModal
                        onModalCancel={this.onModalCancel}
                        addLicenseResult={addLicenseResult}
                        history={this.props.history}
                        // addLicenseResult={addLicenseResult}
                      />
                    </Modal>

                    {isEditPaymentMode ? (
                      <div class="payment-dashboard-detail enrollment-form">
                        <div className="d-flex justify-content-between">
                          <div class="payment-label">Payment Details</div>
                          {paymentInfo && (
                            <div>
                              <button
                                type="button"
                                class="btn btn-sm black-btn mr-2"
                                onClick={this.onToggle}
                              >
                                Switch Saved Payment
                              </button>
                            </div>
                          )}
                        </div>
                        <div class="credit-card-detail">
                          <div class="nav nav-tabs  border-0  font-weight-bold" role="tablist">
                            <Radio.Group
                              options={['Credit/Debit', 'Checking']}
                              // options={['CreditDebit']}
                              onChange={this.onChange}
                              value={this.state.value}
                            />
                          </div>
                        </div>
                        {this.state.value === 'Credit/Debit' ? (
                          <div class="tab-pane fade show active" id="card-tab">
                            <div class="row ">
                              <div class="col-12">
                                <div class="row ">
                                  <div class="col-6  pb-lg-3">
                                    <div class="form-group">
                                      <label class="col-12">
                                        Card number
                                        <CardNumberElement
                                          className="form-control"
                                          placeholder="XXXX XXXX XXXX XXXX"
                                          {...createOptions()}
                                          onChange={this.handleChange}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-3 pb-lg-3">
                                    <div class="form-group">
                                      <label class="col-12">
                                        {' '}
                                        Exp date
                                        <CardExpiryElement
                                          className="form-control"
                                          {...createOptions()}
                                          onChange={this.handleChange}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-3 pb-lg-3">
                                    <div class="form-group">
                                      <label class="col-12">
                                        CVC
                                        <CardCvcElement
                                          className="form-control"
                                          placeholder="123"
                                          {...createOptions()}
                                          onChange={this.handleChange}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="error" role="alert">
                                    {this.state.errorMessage}
                                  </div>
                                  <div>
                                    {!isAddLicense &&
                                      addLicenseResult &&
                                      addLicenseResult != 'SUCCESS' && (
                                        <span className="text-danger">{addLicenseResult}</span>
                                      )}
                                  </div>
                                  {/* <div class="col-12 pb-lg-3 pt-1">
                                  <label class="custom-checkbox d-flex align-items-center">
                                    <input type="checkbox" />
                                    <em class="checkmark mr-2" />
                                    <span class="font-weight-bold status-label">
                                      Save the card for future purchase
                                    </span>
                                  </label>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="tab-pane" id="checking-tab">
                            <div class="row">
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group call-schedule">
                                  <Field
                                    name="accountType"
                                    type="text"
                                    placeholder="Account Type"
                                    component={ReduxSelect}
                                    validate={Validator.required}
                                    // className="form-control"
                                    options={[
                                      { Value: 'Company', Text: 'Company' },
                                      { Value: 'Individual', Text: 'Individual' },
                                    ]}
                                  />
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="bankName"
                                    type="text"
                                    component={ReduxInput}
                                    validate={Validator.required}
                                    className="form-control"
                                    fieldName="Bank Name"
                                  />
                                  <label class="placeholder-label">Bank Name*</label>
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="accountName"
                                    type="text"
                                    component={ReduxInput}
                                    validate={[Validator.accountName, Validator.required]}
                                    className="form-control"
                                    fieldName="Account Name "
                                  />
                                  <label class="placeholder-label">Account Name*</label>
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="routingNumber"
                                    type="text"
                                    component={ReduxInput}
                                    validate={[Validator.routingNumber, Validator.required]}
                                    className="form-control"
                                    fieldName="Routing Number"
                                  />
                                  <label class="placeholder-label">Routing Number*</label>
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="accountNumber"
                                    type="text"
                                    component={ReduxInput}
                                    validate={[Validator.accountNumber, Validator.required]}
                                    className="form-control"
                                    fieldName="Account Number"
                                  />
                                  <label class="placeholder-label">Account Number*</label>
                                </div>
                              </div>
                            </div>
                            <div>
                              {!isAddLicense &&
                                addLicenseResult &&
                                addLicenseResult != 'SUCCESS' && (
                                  <span className="text-danger">{addLicenseResult}</span>
                                )
                              // this.error(addLicenseResult)
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div class="payment-dashboard-detail enrollment-form">
                        <div className="d-flex justify-content-between">
                          <div class="payment-label">Payment Details</div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-sm black-btn mr-2"
                              onClick={this.onToggle}
                            >
                              Change Payment Method
                            </button>
                          </div>
                        </div>

                        {paymentInfo && paymentInfo.paymentType == 'card' && (
                          <div
                            className=" w-50 p-2 d-flex justify-content-between align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div className="">
                              <img
                                src={`images/brand/${paymentInfo.paymentMethod.card.brand}.png`}
                                alt={paymentInfo.paymentMethod.card.brand}
                                style={{ height: '50px' }}
                              />
                            </div>

                            <div
                              className="d-flex flex-column align-items-end"
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                                textTransform: 'uppercase',
                              }}
                            >
                              <span>**** **** **** {paymentInfo.paymentMethod.card.last4}</span>
                              <span>{paymentInfo.paymentMethod.card.brand}</span>
                            </div>
                          </div>
                        )}
                        {paymentInfo && paymentInfo.paymentType == 'card_default_source' && (
                          <div
                            className=" w-50 p-2 d-flex justify-content-between align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div className="">
                              <img
                                src={`images/brand/${paymentInfo.paymentMethod.brand}.png`}
                                alt={paymentInfo.paymentMethod.brand}
                                style={{ height: '50px' }}
                              />
                            </div>

                            <div
                              className="d-flex flex-column align-items-end"
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                                textTransform: 'uppercase',
                              }}
                            >
                              <span>**** **** **** {paymentInfo.paymentMethod.last4}</span>
                              <span>{paymentInfo.paymentMethod.brand}</span>
                            </div>
                          </div>
                        )}

                        {paymentInfo && paymentInfo.paymentType == 'ach' && (
                          <div
                            className=" w-75 p-2 d-flex  align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: '45px',
                                width: '80px',
                                backgroundColor: '#831f65',
                                color: '#ffffff',
                                marginRight: '20px',
                              }}
                            >
                              <span> ACH</span>
                            </div>
                            <div
                              className="d-flex flex-column "
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                              }}
                            >
                              <span>Account Holder : {paymentInfo.holderName}</span>
                              <span>Account ending with *{paymentInfo.last4}</span>
                              <span style={{ textTransform: 'uppercase' }}>
                                {paymentInfo.bankName}
                              </span>
                            </div>
                          </div>
                        )}
                        {paymentInfo && paymentInfo.paymentType == 'bank_account_default_source' && (
                          <div
                            className=" w-75 p-2 d-flex  align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: '45px',
                                width: '80px',
                                backgroundColor: '#831f65',
                                color: '#ffffff',
                                marginRight: '20px',
                              }}
                            >
                              <span> ACH</span>
                            </div>
                            <div
                              className="d-flex flex-column "
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                              }}
                            >
                              <span>Account Holder : {paymentInfo.holderName}</span>
                              <span>Account ending with *{paymentInfo.last4}</span>
                              <span style={{ textTransform: 'uppercase' }}>
                                {paymentInfo.bankName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* <div class="payment-dashboard-detail enrollment-form">
                      <div class="billing-detail">
                        <div class="payment-label">Shipping Details</div>
                        <label class="custom-checkbox d-flex align-items-center mb-3 mb-sm-0">
                          <input type="checkbox" onClick={this.makeAddCopy} />
                          <em class="checkmark mr-2" />
                          <span class="font-weight-bold status-label">Same as Billing Details</span>
                        </label>
                      </div>
                      <div class="row">
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingName"
                              type="text"
                              component={ReduxInput}
                              // validate={Validator.required}
                              className="form-control"
                              fieldName="Name"
                            />
                            <label class="placeholder-label">Name</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingEmail"
                              type="email"
                              component={ReduxInput}
                              // validate={[Validator.required, Validator.email]}
                              placeholder="someone@domain.com"
                              className="form-control"
                              fieldName="Shipping Email"
                            />
                            <label class="placeholder-label">Email</label>
                          </div>
                        </div>
                        <div class="col-12  col-lg-12 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingAddress"
                              type="text"
                              component={ReduxInput}
                              // validate={Validator.required}
                              placeholder="Address"
                              className="form-control"
                              fieldName="Shipping Address"
                            />
                            <label class="placeholder-label">Billing Address</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingCity"
                              type="text"
                              errorMessage=""
                              component={ReduxInput}
                              // validate={Validator.required}
                              placeholder="City"
                              className="form-control"
                              fieldName="Shipping City"
                            />
                            <label class="placeholder-label">City</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group combo-dropdown">
                            <Field
                              name={`shippingState`}
                              type="text"
                              placeholder={`State*`}
                              component={ReduxSelect}
                              // validate={Validator.required}
                              options={StateProvince}
                            />
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingPostalCode"
                              type="text"
                              component={ReduxInput}
                              // validate={Validator.required}
                              placeholder="Postal Code"
                              className="form-control"
                              fieldName="Shipping Postal Code"
                            />
                            <label class="placeholder-label">Postal Code</label>
                          </div>
                        </div>
                      </div>
                    </div>
                   */}
                  </div>
                  <div class="col-md-12 col-lg-5 col-xl-4">
                    <div class="payment-dashboard-detail order-summary enrollment-form">
                      <div class="payment-label">Total cost for the purchases today</div>
                      {/* pawan */}
                      <div class="total-price">
                        <div class="sub-total">
                          {StateLicenseData &&
                            StateLicenseData.map((item, index) => {
                              return (
                                <>
                                  {item.ChangedLicenseCount != 0 && (
                                    <span>{item.LicenseShortName}</span>
                                  )}
                                </>
                              );
                            })}
                        </div>
                        <div class="sales-price">
                          {StateLicenseData &&
                            StateLicenseData.map((item, index) => (
                              <>
                                {item.ChangedLicenseCount != 0 && (
                                  <span>
                                    {formatter.format(item.UnitCostMonthly)} x{' '}
                                    {item.ChangedLicenseCount}
                                  </span>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                      <div class="total-price">
                        <div class="sub-total">
                          <span>Recurring monthly cost of license(s):</span>
                        </div>
                        <div class="sales-price">
                          <span>{formatter.format(Total)}</span>
                        </div>
                      </div>
                      {ProRatedCostTotal > 0 && (
                        <>
                          <div class="total-price pt-0">
                            <div class="sub-total">
                              <span>Prorated amount for license(s) added now:</span>
                            </div>
                            <div class="sales-price">
                              <span>{formatter.format(ProRatedCostTotal)}</span>
                            </div>
                          </div>
                        </>
                      )}

                      {/* <div class="total-amount">
                        <span>Total Amount:</span>
                        <span class="amount-price">{formatter.format(Total)}</span>
                      </div> */}
                      {TotalNewTokenCount > 0 && (
                        <div class="total-price token-info pt-0">
                          <div class="sub-total">
                            <span>
                              Total cost for{' '}
                              {numberFormat.format(parseInt(TotalNewTokenCount || 0))} tokens:
                            </span>
                          </div>
                          <div class="sales-price">
                            <span>{formatter.format(TotalNewTokenCost)}</span>
                          </div>
                        </div>
                      )}
                      <div class="text-center submitbtn">
                        <button
                          type="submit"
                          disabled={
                            !this.state.termsCondition || (Total === 0 && TotalNewTokenCount === 0)
                              ? true
                              : false
                          }
                          class="btn submitbtn "
                          name="pay"
                        >
                          {' '}
                          Place your Order
                        </button>
                      </div>
                    </div>
                    <div
                      class="payment-dashboard-detail enrollment-form"
                      style={{ paddingLeft: '25px' }}
                    >
                      <div class="billing-detail" style={{ alignItems: 'start' }}>
                        {/* <div class="payment-label">Shipping Details</div> */}
                        <label class="custom-checkbox d-flex align-center-start mb-sm-0">
                          <input
                            type="checkbox"
                            onClick={this.termsCondition}
                            checked={this.state.termsCondition}
                          />
                          <em class="checkmark mr-1" />
                        </label>
                        <span class="font-weight-bold" style={{ textAlign: 'justify' }}>
                          Our organization, employees, agents, subcontractors and other
                          representatives agree to use Sober Peer system, products and services in
                          accordance with the{' '}
                          <a
                            style={{ color: '#46b4fe' }}
                            onClick={() => {
                              this.setState({ licenseAgreementModal: true });
                            }}
                          >
                            License Agreement{' '}
                          </a>
                          and{' '}
                          <a
                            style={{ color: '#46b4fe' }}
                            onClick={() => {
                              this.setState({ termsAndConditionModal: true });
                            }}
                          >
                            Terms & Conditions Agreement
                          </a>
                          {/* <Link to="/termsConditions" target="_blank">
                            Terms & Condition Agreement
                          </Link> */}
                          .
                        </span>

                        <Modal
                          width={700}
                          zIndex={500}
                          closable={false}
                          mask
                          maskClosable={false}
                          centered
                          visible={this.state.licenseAgreementModal}
                          footer={null}
                          onCancel={this.onLicenseModalCancel}
                        >
                          <LicenseModal onLicenseModalCancel={this.onLicenseModalCancel} />
                        </Modal>
                        <Modal
                          width={700}
                          zIndex={500}
                          closable={false}
                          mask
                          maskClosable={false}
                          centered
                          visible={this.state.termsAndConditionModal}
                          footer={null}
                          onCancel={this.onConditionModalCancel}
                        >
                          <TermsAndConditionModal
                            onConditionModalCancel={this.onConditionModalCancel}
                          />
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    get_payment_info,
    get_current_licenses,
    get_stripe_api_key,
    combos,
    add_licenses,
    center_get,
  } = state;
  const billingDetails = selector(
    state,
    'billingName',
    'billingEmail',
    'billingAddress',
    'postalCode',
    'city',
    'billingState',
    'billingCountry',
  );
  let countryISO = selector(state, 'billingCountry');

  var CurrentLicenseData = [];
  var LicenseCounts = {},
    Total = 0,
    ProRatedCostTotal = 0,
    TotalNewTokenCount = 0,
    TotalNewTokenCost = 0,
    salesTax = 0;
  let STRIPE_KEY = '';
  let isAddLicense = false;
  let addLicenseResult = '';
  let editStateProvince = [];
  const { data, isFetching } = get_stripe_api_key;
  const { data: paymentInfoData, isFetching: isFetchingInfo } = get_payment_info;
  const { data: centerData } = center_get;

  let paymentInfo;
  let billingDetail = {};
  if (get_stripe_api_key.data) {
    STRIPE_KEY = data.stripeKey;
  }
  if (add_licenses.data && add_licenses.data) {
    isAddLicense = add_licenses.data.success;
    addLicenseResult = add_licenses.data.result;
  }
  if (paymentInfoData && paymentInfoData.result) {
    if (paymentInfoData.result.paymentType == 'card') {
      paymentInfo = {
        paymentMethod: JSON.parse(paymentInfoData.result.paymentMethod),
        paymentType: paymentInfoData.result.paymentType,
      };
      billingDetail = {
        billingName: paymentInfo.paymentMethod.billing_details.name,
        billingEmail: paymentInfo.paymentMethod.billing_details.email,
        billingAddress: paymentInfo.paymentMethod.billing_details.address.line1,
        postalCode: paymentInfo.paymentMethod.billing_details.address.postal_code,
        city: paymentInfo.paymentMethod.billing_details.address.city,
        billingState: paymentInfo.paymentMethod.billing_details.address.state,
        billingCountry: paymentInfo.paymentMethod.billing_details.address.country || 'USA',
      };
    } else if (paymentInfoData.result.paymentType == 'card_default_source') {
      paymentInfo = {
        paymentMethod: paymentInfoData.result.paymentMethod,
        paymentType: paymentInfoData.result.paymentType,
      };
      billingDetail = {
        billingName: paymentInfoData.result.paymentMethod.name || localStorage.getItem('userName'),
        billingEmail: localStorage.getItem('email'),
        billingAddress: paymentInfoData.result.paymentMethod.address_line1,
        postalCode: paymentInfoData.result.paymentMethod.address_zip,
        city: paymentInfoData.result.paymentMethod.address_city,
        billingState: paymentInfoData.result.paymentMethod.address_state,
        billingCountry: paymentInfoData.result.paymentMethod.address_country || 'USA',
      };
    } else if (paymentInfoData.result.paymentType == 'bank_account_default_source') {
      paymentInfo = {
        paymentType: paymentInfoData.result.paymentType,
        holderName: paymentInfoData.result.paymentMethod.account_holder_name,
        bankName: paymentInfoData.result.paymentMethod.bank_name,
        last4: paymentInfoData.result.paymentMethod.last4,
      };
      billingDetail = {
        billingName: localStorage.getItem('userName'),
        billingEmail: localStorage.getItem('email'),
      };
    } else if (paymentInfoData.result.paymentType == 'ach') {
      paymentInfo = {
        paymentType: paymentInfoData.result.paymentType,
        holderName: paymentInfoData.result.paymentMethod.account_holder_name,
        bankName: paymentInfoData.result.paymentMethod.bank_name,
        last4: paymentInfoData.result.paymentMethod.last4,
      };
      billingDetail = {
        billingName: localStorage.getItem('userName'),
        billingEmail: localStorage.getItem('email'),
      };
    } else {
      paymentInfo = {
        paymentType: paymentInfoData.result.paymentType,
        holderName: paymentInfoData.result.paymentMethod.account_holder_name,
        bankName: paymentInfoData.result.paymentMethod.bank_name,
        last4: paymentInfoData.result.paymentMethod.last4,
      };
      billingDetail = {
        billingName: localStorage.getItem('userName'),
        billingEmail: localStorage.getItem('email'),
      };
    }
  } else {
    billingDetail = {
      billingName: localStorage.getItem('userName'),
      billingEmail: localStorage.getItem('email'),
      billingAddress: centerData && centerData.AddressLine1,
      postalCode: centerData && centerData.ZipCode,
      city: centerData && centerData.City,
      billingState: centerData && centerData.StateOrProvince,
      billingCountry: (centerData && centerData.CountryISO) || 'USA',
    };
  }

  if (get_current_licenses.data) {
    CurrentLicenseData = get_current_licenses.data.data;
    LicenseCounts = get_current_licenses.data.LicensesCounts;
    Total = get_current_licenses.data.Total;
    salesTax = get_current_licenses.data.salesTax;
    ProRatedCostTotal = get_current_licenses.data.ProRatedCostTotal;
    TotalNewTokenCount = get_current_licenses.data.TotalNewTokenCount;
    TotalNewTokenCost = get_current_licenses.data.TotalNewTokenCost;
  }
  if (!combos.data) {
    combos.data = {
      StateProvince: [],
      Country: [],
    };
  }
  if (combos.data.StateProvince && combos.data.StateProvince.length > 0) {
    editStateProvince = combos.data.StateProvince.map(item => {
      return {
        Value: item.Text,
        Text: item.Text,
      };
    });
  }
  return {
    CurrentLicenseData,
    isAddLicense,
    addLicenseResult,
    isFetching: isFetching || add_licenses.isFetching || isFetchingInfo,
    LicenseCounts,
    Total,
    salesTax,
    billingDetails,
    STRIPE_KEY,
    initialValues: {
      accountType: 'Company',
      billingCountry: 'USA',
      ...billingDetail,
    },
    StateProvince: editStateProvince,
    Country: combos.data.Country,
    countryISO,
    paymentInfo,
    ProRatedCostTotal,
    TotalNewTokenCount,
    TotalNewTokenCost,
  };
};

Completepurchase = reduxForm({
  form: 'completePurchageForm',
  // Validator,
  enableReinitialize: true,
})(withRouter(Completepurchase));

const selector = formValueSelector('completePurchageForm');

Completepurchase = connect(mapStateToProps)(Completepurchase);

class MakePayment extends Component {
  constructor(props) {
    super(props);
    props.dispatch(getStripeApiKey({}));
    props.dispatch(getPaymentInfo({}));
    this.state = {
      tokenIdempotencyKey: null,
      licenseIdempotencyKey: null,
    };
  }
  componentDidMount() {
    this.props.dispatch(getStripeApiKey({}));
    this.props.dispatch(resetSetLicenses({}));
    this.getIdempotencyKey();
  }

  handleResult = (response, values, type, isEditPaymentMode) => {
    const {
      Total,
      salesTax,
      dispatch,
      LicenseCounts,
      paymentInfo,
      CurrentLicenseData,
    } = this.props;
    if (paymentInfo || !response.error) {
      // let ordersArr =
      //   CurrentLicenseData &&
      //   CurrentLicenseData.map((item, index) => {
      //     return {
      //       [item.LicenseShortName]: LicenseCounts[index],
      //     };
      //   });
      if (paymentInfo && !isEditPaymentMode) {
        response.billingDetails = {
          billingName: values.billingName,
          billingEmail: values.billingEmail,
          billingAddress: values.billingAddress,
          billingPostalCode: values.postalCode,
          billingCity: values.city,
          billingState: values.billingState,
          billingCountry: values.billingCountry,
        };
        // response.shippingDetails = {
        //   shippingName: values.shippingName,
        //   shippingEmail: values.shippingEmail,
        //   shippingAddress: values.shippingAddress,
        //   shippingPostalCode: values.shippingPostalCode,
        //   shippingCity: values.shippingCity,
        // };
        response.orderSummary = {
          // Userlic: CurrentLicenseData[0].QuantityCurrentActive + LicenseCounts[0],
          // Kiosklic: CurrentLicenseData[1].QuantityCurrentActive + LicenseCounts[1],
          // Telehealth: CurrentLicenseData[2].QuantityCurrentActive + LicenseCounts[2],
          // ...ordersArr,
          // ...LicenseCounts.praposed,
          salesTax,
          subTotal: Total,
          TotalAmt: (Total + (Total * salesTax) / 100).toFixed(2),
          Licenses: LicenseCounts.StateLicenseData,
        };
        response.paymentType = type;
        response.paymentMethod = null;
        response.tokenIdempotencyKey = this.state.tokenIdempotencyKey;
        response.licenseIdempotencyKey = this.state.licenseIdempotencyKey;
        // dispatch(addLicenses({ json: response.token.id }));
        dispatch(setLicenses({ json: JSON.stringify(response) }));
      } else {
        response.billingDetails = {
          billingName: values.billingName,
          billingEmail: values.billingEmail,
          billingAddress: values.billingAddress,
          billingPostalCode: values.postalCode,
          billingCity: values.city,
          billingState: values.billingState,
          billingCountry: values.billingCountry,
        };
        // response.shippingDetails = {
        //   shippingName: values.shippingName,
        //   shippingEmail: values.shippingEmail,
        //   shippingAddress: values.shippingAddress,
        //   shippingPostalCode: values.shippingPostalCode,
        //   shippingCity: values.shippingCity,
        // };
        response.orderSummary = {
          // Userlic: CurrentLicenseData[0].QuantityCurrentActive + LicenseCounts[0],
          // Kiosklic: CurrentLicenseData[1].QuantityCurrentActive + LicenseCounts[1],
          // Telehealth: CurrentLicenseData[2].QuantityCurrentActive + LicenseCounts[2],
          // ...LicenseCounts.praposed,
          salesTax,
          subTotal: Total,
          TotalAmt: (Total + (Total * salesTax) / 100).toFixed(2),
          Licenses: LicenseCounts.StateLicenseData,
        };
        response.paymentType = type;
        response.tokenIdempotencyKey = this.state.tokenIdempotencyKey;
        response.licenseIdempotencyKey = this.state.licenseIdempotencyKey;
        // dispatch(addLicenses({ json: response.token.id }));
        dispatch(setLicenses({ json: JSON.stringify(response) }));
      }
    } else {
      Modal.error({
        title: response.error.code,
        content: response.error.message,
      });
    }
    // alert(JSON.stringify(response));
    this.getIdempotencyKey();
  };
  getIdempotencyKey = () => {
    this.setState({
      tokenIdempotencyKey: uuid(),
      licenseIdempotencyKey: uuid(),
    });
  };

  render() {
    const { STRIPE_KEY } = this.props;
    const stripePromise = STRIPE_KEY && loadStripe(STRIPE_KEY);
    return (
      stripePromise && (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <Completepurchase
                handleResult={this.handleResult}
                getIdempotencyKey={this.getIdempotencyKey}
                stripe={stripe}
                elements={elements}
              />
            )}
          </ElementsConsumer>
        </Elements>
      )
    );
  }
}

export default connect(mapStateToProps)(MakePayment);
