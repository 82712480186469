import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxInput,
  ReduxTextarea,
} from '../component/custom-redux-components';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, getFormSyncErrors } from 'redux-form';
import { Delete_Sucess_Msg } from '../app-config';
import { ProcessCombos } from '../store/comboConfig';
import {
  getContract,
  upsertContract,
  resetUpsertContract,
  resetGetContract,
  deleteContract,
  resetDeleteContract,
  closeContract,
  listCombos,
} from '../store/actions';
import AntdModal from '../Components/CustomModal';
import CustomModal from '../core/modal/index';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

class ViewContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newContract: {
        studentId: this.getStudentId(),
        studentName: this.getStudentName(),
        minorThemes: [''],
        goals: [''],
        bibleMemorizations: [''],
        bibleReadings: [''],
        lessons: [''],
        specialProjects: [''],
      },
      header: null,
    };
  }

  componentDidMount() {
    var contractId = null;
    if (this.getContractId() && this.getContractId() != 'null') {
      contractId = this.getContractId();
      this.props.dispatch(
        getContract({
          StudentId: this.getStudentId(),
          StudentContractId: contractId,
        }),
      );
    }
    var result = ProcessCombos(
      'ContractLadderAssociation,ContractMajorTheme,ContractCharacterQuality,ContractCQProject,CenterCourses',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  mapCharacterData = data => {
    let cqData = [];
    if (data) {
      for (let item of data) {
        let obj = {};
        obj.studentContractCharacterQualityId = item.StudentContractCharacterQualityId;
        obj.characterQualityIds = item.CharacterQualityId;
        obj.cQProjectIds = item.CQProjectId;
        cqData.push(obj);
      }
    }
    return cqData;
  };
  mapCourseData = data => {
    let courseData = [];
    let dataArr = data && data.split(',');
    if (dataArr) {
      for (let item of dataArr) {
        let obj = {};
        obj.courseIds = parseInt(item);
        courseData.push(obj);
      }
    }
    return courseData;
  };
  componentWillReceiveProps({ contractData }) {
    if (contractData && contractData.StudentId && this.getContractId() > 0) {
      const data = {
        contractName: contractData.ContractName,
        majorThemeId: contractData.MajorThemeId,
        minorThemes: contractData.MinorThemes && contractData.MinorThemes.split('|'),
        goals: contractData.Goals && contractData.Goals.split('|'),
        bibleMemorizations:
          contractData.BibleMemorizations && contractData.BibleMemorizations.split('|'),
        bibleReadings: contractData.BibleReadings && contractData.BibleReadings.split('|'),
        lessons: contractData.Lessons && contractData.Lessons.split('|'),
        specialProjects: contractData.SpecialProjects && contractData.SpecialProjects.split('|'),
        // courseIds: contractData.CourseIds,
        courseIds: this.mapCourseData(contractData.CourseIds),
        // characterQualityIds: contractData.CharacterQualityIds,
        // cQProjectIds: contractData.CQProjectIds,
        ladderAssociationId: contractData.LadderAssociationId,
        cqProject: this.mapCharacterData(contractData.CharacterQuality),
        adminNotes: contractData.AdminNotes,
        recipients: contractData.Recipients,
        startDate: contractData.StartDate,
        dueDate: contractData.DueDate,
        studentId: contractData.StudentId,
        studentName: this.getStudentName(),
        msg: contractData.Msg,
        CenterId: contractData.CenterId,
        CenterName: contractData.CenterName,
      };
      this.setState({
        newContract: data,
      });
    }
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId' || 'StudentId');
  }

  getStudentName() {
    return localStorage.getItem('displayName');
    // return sessionStorage.getItem('displayName');
  }

  getContractId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('contractId' || 'ContractId');
  }

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  onSubmit(values) {
    // let data = {
    //   StudentContract: [
    //     {
    //       MajorThemeId: values.majorThemeId,
    //       MinorThemes: (values.minorThemes && values.minorThemes.toString().replace(/,/g, '|')) || [
    //         '',
    //       ],
    //       Goals: (values.goals && values.goals.toString().replace(/,/g, '|')) || [''],
    //       BibleMemorizations: (values.bibleMemorizations &&
    //         values.bibleMemorizations.toString().replace(/,/g, '|')) || [''],
    //       BibleReadings: (values.bibleReadings &&
    //         values.bibleReadings.toString().replace(/,/g, '|')) || [''],
    //       Lessons: (values.lessons && values.lessons.toString().replace(/,/g, '|')) || [''],
    //       SpecialProjects: (values.specialProjects &&
    //         values.specialProjects.toString().replace(/,/g, '|')) || [''],
    //       CourseIds: this.getStringType(values.courseIds),
    //       CharacterQualityIds: this.getStringType(values.characterQualityIds),
    //       CQProjectIds: this.getStringType(values.cQProjectIds),
    //       AdminNotes: values.adminNotes,
    //       Recipients: values.recipients,
    //       StartDate: values.startDate,
    //       DueDate: values.dueDate,
    //       Msg: values.msg,
    //     },
    //   ],
    // };
    // data = {
    //   StudentId: this.getStudentId() > 0 ? this.getStudentId() : null,
    //   StudentContractId: this.getContractId() > 0 ? this.getContractId() : null,
    //   Json: JSON.stringify(data),
    // };
    // this.props.dispatch(upsertContract(data));
  }

  componentWillUnmount() {
    this.props.dispatch(resetGetContract());
  }

  render() {
    return (
      <>
        {/* <ReactToPrint
          trigger={() => (
            <div className="text-right ml-2">
              {' '}
              <button style={{ border: 'none', background: '#fff' }}>
                <img src="images/printSVG.svg" />
              </button>
            </div>
          )}
          content={() => this.componentRef}
          documentTitle="Treatment Plan"
        /> */}
        <div ref={el => (this.componentRef = el)}>
          <ReduxNewContract
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.newContract}
            getContractId={this.getContractId()}
            getStudentId={this.getStudentId()}
            contractName={this.state.newContract.contractName}
            ContractData={this.state.newContract}
          />
        </div>
      </>
    );
  }
}

const MinorTheme = ({ fields, MinorThemes }) => (
  <div className="row">
    <div className="col-12 pr-xl-5">
      <div class="row">
        {fields.map((item, index) => (
          <div key={index} className="col-12 field-array-item">
            <div class="form-group">
              {/* <Field
                disabled={true}
                name={item}
                class="form-control"
                type="text"
                component={ReduxTextarea}
              /> */}
              <label class="placeholder-label" style={{ top: '-15px' }}>
                Minor Theme(s)
              </label>
              <span className="form-control" style={{ height: 'unset' }}>
                {' '}
                {MinorThemes && MinorThemes[index]}
              </span>
              <span class="small-info">Doubles as the “name” of Growth Plan.</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Goals = ({ fields, Goals }) => (
  <div className="row">
    <div className="col-12  pr-xl-5">
      <div class="row">
        {fields.map((item, index) => (
          <div key={index} className="col-12 field-array-item">
            <div class="form-group">
              {/* <Field
                // style={{ minHeight: '105px' }}
                disabled={true}
                name={item}
                class="form-control"
                type="textarea"
                rows="4"
                component={ReduxTextarea}
              /> */}
              <label class="placeholder-label" style={{ top: '-15px' }}>
                Goal(s)
              </label>
              <span className="form-control" style={{ height: 'unset' }}>
                {' '}
                {Goals && Goals[index]}
              </span>
              <span class="small-info">Provide an specific list of goals to be achieved.</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const BibleMemo = ({ fields, BibleMemorizations }) => (
  <div className="row">
    <div className="col-12  pr-xl-5">
      <div class="row">
        {fields.map((item, index) => (
          <div key={index} className="col-12 field-array-item">
            <div class="form-group">
              {/* <Field
                disabled={true}
                name={item}
                class="form-control"
                type="text"
                component={ReduxTextarea}
              /> */}
              <label class="placeholder-label" style={{ top: '-15px' }}>
                {' '}
                Bible Memorization
              </label>
              <span className="form-control" style={{ height: 'unset' }}>
                {' '}
                {BibleMemorizations && BibleMemorizations[index]}
              </span>
              <span class="small-info">Two or more scripture verses.</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const BibleReading = ({ fields, BibleReadings }) => (
  <div className="row">
    <div className="col-12 pr-xl-5">
      <div class="row">
        {fields.map((item, index) => (
          <div key={index} className="col-12 field-array-item">
            <div class="form-group">
              {/* <Field
                disabled={true}
                name={item}
                class="form-control"
                type="text"
                component={ReduxTextarea}
              /> */}
              <label className="placeholder-label" style={{ top: '-15px' }}>
                Bible Reading
              </label>
              <span className="form-control" style={{ height: 'unset' }}>
                {' '}
                {BibleReadings && BibleReadings[index]}
              </span>
              <span className="small-info">One or more books.</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Lessons = ({ fields, Lessons }) => (
  <div className="row">
    <div className="col-12 pr-xl-5">
      <div class="row">
        {fields.map((item, index) => (
          <div key={index} className="col-12 field-array-item">
            <div class="form-group">
              {/* <Field
                // style={{ minHeight: '105px' }}
                disabled={true}
                name={item}
                class="form-control"
                type="textarea"
                rows="4"
                component={ReduxTextarea}
              /> */}
              <label class="placeholder-label" style={{ top: '-15px' }}>
                Personal Reading
              </label>
              <span className="form-control" style={{ height: 'unset' }}>
                {' '}
                {Lessons && Lessons[index]}
              </span>
              <span class="small-info">One or more Personal Reading .</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const SpecialProject = ({ fields, specialProjects }) => (
  <div className="row">
    <div className="col-12 pr-xl-5">
      <div class="row">
        {fields.map((item, index) => (
          <div key={index} className="col-12 field-array-item field-height ">
            <div class="form-group">
              <label class="placeholder-label" style={{ top: '-15px' }}>
                Special Projects
              </label>
              <span className="form-control" style={{ height: 'unset' }}>
                {' '}
                {specialProjects && specialProjects[index]}
              </span>
              {/* <Field
                disabled={true}
                name={item}
                class="form-control"
                type="text"
                component={ReduxTextarea}
              /> */}

              <span class="small-info">Optional special projects.</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
const CQProject = ({ fields, ContractCharacterQuality, ContractCQProject }) => (
  <div className="row">
    <div className="col-10 pr-xl-5  my-3">
      {fields.map((item, index) => (
        <div class="row ">
          <div key={index} className="col-11 field-array-item">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  {/* <label class="placeholder-label">Character Qualities</label> */}
                  <Field
                    name={`${item}.characterQualityIds`}
                    class="custom-select enrollment-select"
                    component={ReduxSelect}
                    placeholder="Character Qualities"
                    options={ContractCharacterQuality}
                    // isMulti
                  />
                  <span class="small-info">Select the character trait focus</span>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  {/* <label class="placeholder-label">CQ Projects</label> */}
                  <Field
                    name={`${item}.cQProjectIds`}
                    class="custom-select enrollment-select"
                    component={ReduxSelect}
                    placeholder="CQ Projects"
                    options={ContractCQProject}
                    // isMulti
                  />

                  <span class="small-info">
                    Select a project type to assist in character trait development
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
const Courses = ({ fields, courseOptions }) => (
  <div className="row">
    <div className="col-12 col-md-10 col-lg-10 pr-xl-5  my-3">
      {fields.map((item, index) => (
        <div class="row ">
          <div key={index} className="col-9 field-array-item">
            <div class="form-group">
              {/* <label class="placeholder-label">Lessons</label> */}
              <Field
                name={`${item}.courseIds`}
                class="custom-select enrollment-select"
                component={ReduxSelect}
                placeholder="Lessons"
                options={courseOptions}
              />
              <span class="small-info">Select Lesson(s)</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
let NewContract = ({
  onSaveClick,
  getContractId,
  history,
  handleSubmit,
  ContractMajorTheme,
  ContractCharacterQuality,
  ContractCQProject,
  getStudentId,
  dispatch,
  courseOptions,
  contractName,
  ContractLadderAssociation,
  ContractData,
}) => {
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div class="main enrollment-page">
        <div class="container-fluid">
          <div className="client-center-info">
            <div className="item">
              <span className="text">Center Id : </span>
              <span className="value">{ContractData.CenterId}</span>
            </div>
            <div className="item">
              <span className="text">Center Name : </span>
              <span className="value">{ContractData.CenterName}</span>
            </div>
          </div>
          <div class="row top-table-search pt-5 align-items-end">
            <div class="col-12">
              <div class="card border-0 rounded-0 ">
                <div class="card-body">
                  <h5>
                    Growth Plan : <small>{contractName}</small>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1 top-contract-row">
            <div class="col-12">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form contract-form">
                  <div class="row">
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          name="studentName"
                          type="text"
                          component={ReduxInput}
                          disabled={true}
                        />
                        <label class="placeholder-label">Student*</label>
                        <span class="small-info">Select an active student</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          disabled={true}
                          name="startDate"
                          type="text"
                          class="form-control datepicker hasDatepicker"
                          id="dp1568900339471"
                          errorMessage=" Required"
                          component={ReduxDateInput}
                          label="Start Date*"
                        />
                        {/* <i class="far fa-calendar-alt form-control-icon" /> */}
                        <span class="small-info">Date Growth Plan is to begin</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          disabled={true}
                          name="dueDate"
                          type="text"
                          class="control-label datepicker hasDatepicker"
                          id="dp1568900339472"
                          errorMessage=" Required"
                          component={ReduxDateInput}
                          label="Due Date*"
                        />
                        {/* <i class="far fa-calendar-alt form-control-icon" /> */}
                        <span class="small-info">Expected date of completion</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form contract-form">
                  <div class="row pb-5 pl-3 pr-3 pt-3">
                    <div class="col-12 p-0">
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="ladderAssociationId"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Ladder Association"
                              options={ContractLadderAssociation}
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="majorThemeId"
                              type="text"
                              component={ReduxSelect}
                              placeholder="Major Theme"
                              options={ContractMajorTheme}
                              disabled={true}
                            />
                            <span class="small-info">Select general theme</span>
                          </div>
                        </div>
                      </div>
                      <FieldArray
                        name="minorThemes"
                        component={MinorTheme}
                        MinorThemes={ContractData.minorThemes}
                      />
                      <FieldArray name="goals" component={Goals} Goals={ContractData.goals} />
                      <FieldArray
                        name="bibleMemorizations"
                        component={BibleMemo}
                        BibleMemorizations={ContractData.bibleMemorizations}
                      />
                      <FieldArray
                        name="bibleReadings"
                        component={BibleReading}
                        BibleReadings={ContractData.bibleReadings}
                      />
                      <FieldArray
                        name="lessons"
                        component={Lessons}
                        Lessons={ContractData.lessons}
                      />
                      <FieldArray
                        name="cqProject"
                        component={CQProject}
                        ContractCharacterQuality={ContractCharacterQuality}
                        ContractCQProject={ContractCQProject}
                      />
                      <FieldArray
                        name="courseIds"
                        component={Courses}
                        courseOptions={courseOptions}
                      />
                      {/* Course Start Here */}
                      {/* <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="courseIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Courses"
                              options={courseOptions}
                              isMulti
                              disabled={true}
                            />
                            <span class="small-info">Select the Course</span>
                          </div>
                        </div>
                      </div> */}
                      {/* Course Start Here */}
                      {/* <div class="row">
                        <div class="col-auto pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="characterQualityIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Character Qualities"
                              options={ContractCharacterQuality}
                              isMulti
                              disabled={true}
                            />
                            <span class="small-info">Select the character trait focus.</span>
                          </div>
                        </div>
                        <div class="col-auto pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="cQProjectIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="CQ Projects"
                              isMulti
                              options={ContractCQProject}
                              disabled={true}
                            />

                            <span class="small-info">
                              Select a project type to assist in character trait development.
                            </span>
                          </div>
                        </div>
                      </div> */}
                      <FieldArray
                        name="specialProjects"
                        component={SpecialProject}
                        specialProjects={ContractData.specialProjects}
                      />

                      <div class="row">
                        <div class="col-12 col-md-12 col-lg-10 pr-xl-5">
                          <div class="form-group">
                            <span className="form-control" style={{ height: 'unset' }}>
                              {' '}
                              {ContractData && ContractData.adminNotes}
                            </span>
                            {/* <Field
                              name="adminNotes"
                              class="form-control textarea-box"
                              type="textarea"
                              rows="4"
                              component={ReduxTextarea}
                              disabled={true}
                              style={{
                                // border: 'solid 1px rgba(149, 149, 149, 0.5)',
                                // padding: '8px 30px',
                                minHeight: '300px',
                              }}
                            /> */}
                            <label class="placeholder-label" style={{ top: '-15px' }}>
                              Administrative Notes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({ combos }) => {
  const { data } = combos;

  return {
    ContractLadderAssociation: !combos.data ? [] : combos.data.ContractLadderAssociation,
    ContractMajorTheme: !combos.data ? [] : combos.data.ContractMajorTheme,
    ContractCharacterQuality: !combos.data ? [] : combos.data.ContractCharacterQuality,
    ContractCQProject: !combos.data ? [] : combos.data.ContractCQProject,
    courseOptions: (combos.data && combos.data.CenterCourses) || [],
  };
};

const ReduxNewContract = connect(mapStateToProps)(
  reduxForm({
    form: 'new-contract',
    enableReinitialize: true,
  })(withRouter(NewContract)),
);

const mapStateToParent = ({ get_contract, combos }) => {
  const { data, isFetching, error } = get_contract;

  return {
    contractData: data || {},
  };
};

export default connect(mapStateToParent)(ViewContract);
