import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

class Register extends Component {
  state = {
    email: '',
    password: '',
    errorMessage: '',
  };

  onCreate() {
    var me = this;
    const url = `login/CreateUser`;
    var postData = {
      email: this.state.email,
      password: this.state.password,
    };

    Axios.post(url, postData)
      .then(response => {
        if (response.data.success) {
          me.props.history.push('dashboard');
        } else {
          me.setState({ errorMessage: 'Registration Failed' });
        }
      })
      .catch(error => {
        me.setState({ errorMessage: 'Registration Failed' });
      });
  }
  render() {
    const { errorMessage } = this.state;
    return (
      <div class="login-wallpaper">
        <div class="login-warp">
          <div class="login-box-outer">
            <div class="login-box">
              <div class="clearfix" />
              <div class="login-box-form" style={{ marginTop: '-30px' }}>
                <label class="control-label mar-top-25">Email</label>
                <input
                  name="textinput"
                  type="text"
                  placeholder=""
                  class="form-control"
                  onChange={event => (this.state.email = event.target.value)}
                />
                <label class="control-label mar-top-25">Password</label>
                <input
                  name="textinput"
                  type="password"
                  placeholder=""
                  class="form-control"
                  onChange={event => (this.state.password = event.target.value)}
                />
                <label class="control-label mar-top-25"> confirm Password</label>
                <input name="textinput" type="password" placeholder="" class="form-control" />
                <div class="text-center">
                  <input
                    type="button"
                    class="form-control login-btn"
                    value="REGISTER"
                    onClick={this.onCreate.bind(this)}
                  />
                </div>
                {errorMessage && (
                  <div class="alert alert-warning mar-top-20" role="alert">
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div class="clearfix" />

            {/* <div class="text-center mar-top-20">
              <a href="#" class="forgot-pass trs">
                Forgot your username or password?
              </a>
            </div> */}
          </div>

          <div class="login-footer">© 2018 CE Analytics 2018</div>
        </div>
      </div>
    );
  }
}

export default Register;
