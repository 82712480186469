import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'antd';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { numberFormat, currencyFormatter, currencyFormatter2 } from '../../util/formatter';
const array = [];
var timer;
class SwitchComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      erroeMsg: null,
    };
  }
  rangeCheck(index, value) {
    if (value < 5000) {
      this.setState({ erroeMsg: 'Minimum : 5,000' });
      this.props.checkDisable(index, true);
    } else if (value >= 5000 && value <= 500000) {
      this.setState({ erroeMsg: null });
      this.props.checkDisable(index, false);
    } else if (value > 500000) {
      this.setState({ erroeMsg: 'Maximum : 5,00,000' });
      this.props.checkDisable(index, true);
    }
  }
  render() {
    const {
      CenterData: { CenterName, IsLicenseAssigned, TokenCount },
      index,
      toggleCenter,
      handelChange,
      getTokenCost,
    } = this.props;
    let refillCount = IsLicenseAssigned ? TokenCount : 0;
    let refillCost = getTokenCost(refillCount) || 0;
    return (
      <div className="center-token">
        <div className="action">
          <label className="custom-control-label token-switch">
            <Switch
              checkedChildren="Assign"
              unCheckedChildren="Assign"
              checked={IsLicenseAssigned}
              onClick={() => toggleCenter(index)}
              // onClick={() => this.setState({ toggleActive: !toggleActive })}
              // size="small"
            />
          </label>
          <span className="center-name">{CenterName}</span>
        </div>

        <div className="count">
          <div className="tokens" style={{ position: 'relative' }}>
            <p className="mb-1 label">Number of Tokens</p>
            <InputGroup
              style={{
                backgroundColor: IsLicenseAssigned ? '#ffffff' : null,
                border: '1px solid rgba(0,0,0,0.2)',
              }}
            >
              <InputGroupAddon addonType="prepend">
                <img src="/images/smallCoin.svg" style={{ padding: '5px' }} />
              </InputGroupAddon>
              <Input
                // placeholder="tokens"
                type="number"
                className="inputField"
                value={refillCount}
                style={{ width: '120px', border: 'none', fontWeight: '800', marginBottom: '0px' }}
                disabled={!IsLicenseAssigned}
                onWheel={e => e.target.blur()}
                onChange={event => {
                  handelChange(index, event.target.value);
                  // handelChange(index, 'TokenCount', event.target.value);
                  this.rangeCheck(index, event.target.value);
                }}
                onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              />
            </InputGroup>
            <p style={{ color: 'red', position: 'absolute' }}>{this.state.erroeMsg}</p>
          </div>
          <img src="/images/exchange_icon.svg" style={{ padding: '15px', marginTop: '25px' }} />
          <div className="cost">
            <p className="mb-1 label">Token Cost</p>
            <InputGroup
              style={{
                border: '1px solid rgba(0,0,0,0.2)',
              }}
            >
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <Input
                // placeholder="cost"
                type="number"
                className="inputField"
                value={refillCost}
                style={{ width: '120px', border: 'none', fontWeight: '800', marginBottom: '0px' }}
                disabled={true}
                // onChange={event => {
                //   handelChange(index, 'TokenCost', event.target.value);
                // }}
              />
            </InputGroup>
          </div>
        </div>
      </div>
    );
  }
}

class PurchaseAILicense extends Component {
  constructor() {
    super();
    this.state = {
      TokenInfo: {},
      isAssignDisabled: [],
    };
  }
  componentDidMount() {
    if (this.props.LicenseAndTokenDistribution) {
      this.setState(
        {
          AILicenseData: this.props.LicenseAndTokenDistribution,
          filteredAILicense: this.props.LicenseAndTokenDistribution,
          TokenInfo: this.props.TokenInfo[0],
        },
        () => {
          this.state.AILicenseData && this.state.AILicenseData.map(item => array.push(null));
          this.setState({ isAssignDisabled: [...array] });
        },
      );
    }
  }
  toggleCenter = index => {
    let licenseData = this.state.AILicenseData;
    licenseData[index].IsLicenseAssigned = !licenseData[index].IsLicenseAssigned;
    this.setState({
      AILicenseData: licenseData,
      filteredAILicense: licenseData,
      isModified: true,
    });
  };
  handelChange = (index, value) => {
    let licenseData = this.state.AILicenseData;
    let centerData = {
      ...licenseData[index],
      TokenCount: value,
      // [key]: value,
    };
    licenseData[index] = centerData;
    this.setState({
      AILicenseData: licenseData,
      filteredAILicense: licenseData,
      isModified: true,
    });
  };

  checkDisable(index, value) {
    // this.setState({ isAssignDisabled: value });
    this.state.isAssignDisabled[index] = value;
  }

  getTokenCost = TokenCount => {
    const { CostPerToken } = this.state.TokenInfo;
    return (CostPerToken * TokenCount).toFixed(2);
  };
  onSearchBoxChange = value => {
    let str = value.toLowerCase().trim();
    let filteredAILicense = [];
    for (const license of this.state.AILicenseData) {
      if (license.CenterName.toLowerCase().includes(str)) {
        filteredAILicense.push(license);
      }
    }
    this.setState({
      filteredAILicense: filteredAILicense,
      searchBoxValue: str,
    });
  };
  fnDebounce = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.onSearchBoxChange(value);
    }, 300);
  };
  render() {
    const { onOk, onCancel, LicenseAndTokenDistribution, updateCenterLicense, index } = this.props;
    const { AILicenseData, TokenInfo, searchBoxValue, filteredAILicense } = this.state;
    let LicenseCount = 0;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-1 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Purchase AI License</h4>
        </div>
        <div class="modal-body purchase-ai-license">
          <div class="row">
            <div className="col-12 py-2">
              <div className="ai-info">
                <span>{TokenInfo.Text1}</span>
              </div>
            </div>
            <div className="col-6 d-flex align-items-center">
              <span className="text">Select Center(s) to Assign License to:</span>
            </div>
            <div className="col-6">
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100 mb-0">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search"
                    // onChange={this.onSearchBoxChange}
                    // value={searchBoxValue}
                    onKeyUp={event => {
                      this.fnDebounce(event.target.value);
                    }}
                  />
                  <button class="btn search-icon " type="button">
                    <i class="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12">
              {filteredAILicense &&
                filteredAILicense.map((item, AIindex) => {
                  if (item.IsLicenseAssigned) {
                    LicenseCount = LicenseCount + 1;
                  }
                  return (
                    <SwitchComponent
                      index={AIindex}
                      CenterData={item}
                      TokenInfo={TokenInfo}
                      toggleCenter={this.toggleCenter}
                      handelChange={this.handelChange}
                      getTokenCost={this.getTokenCost}
                      checkDisable={this.checkDisable.bind(this)}
                    />
                  );
                })}
              {/* <div className="center-token">
                <div className="action">
                  <label className="custom-control-label">
                    <Switch
                      checkedChildren="Assign"
                      unCheckedChildren="Assign"
                      checked={toggleActive}
                      onClick={() => this.setState({ toggleActive: !toggleActive })}
                      size="small"
                    />
                  </label>
                  <span className="center-name">Hickery House Center</span>
                </div>
                <div className="count">
                  <div className="no-of-token mr-2">
                    <span className="text">Total Number of Tokens</span>
                    <div>
                      <img src="/images/smallCoin.svg" />
                      <span className="value">15000</span>
                    </div>
                  </div>
                  <div className="token-cost">
                    <span className="text">Total Token Cost</span>
                    <div>
                      <span className="value">$20000</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-12 pb-2">
              <div className="center-token-total">
                <span className="title">License for {LicenseCount} Center(s)</span>
                <div className="token-info">
                  {/* <div className="no-of-token mr-2">
                    <span className="text">Total Number of Tokens</span>
                    <div>
                      <img src="/images/smallCoin.svg" />
                      <span className="value">15000</span>
                    </div>
                  </div>
                  <div className="token-cost">
                    <span className="text">Total Token Cost</span>
                    <div>
                      <span className="value">$20000</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-12 py-2">
              <span className="ai-bottom-info">{TokenInfo.Text2}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer border-top-0 pb-4 pt-2">
          <button
            disabled={this.state.isAssignDisabled.some(item => item == true)}
            type="button"
            class="btn"
            onClick={() => {
              updateCenterLicense(
                this.state.AILicenseData,
                index,
                this.state.isModified,
                TokenInfo.CostPerToken,
              );
              onOk();
            }}
          >
            Assign
          </button>
        </div>
      </div>
    );
  }
}

export default connect()(PurchaseAILicense);
