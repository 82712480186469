import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, reset } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../component/custom-redux-components';
import Validator from '../Validation';
const CaseForm = ({ fields, name, meta: { touched, error }, change, disable, existingRows }) => {
  if (fields.length == 0) {
    fields.push({});
  }
  const allowEdit = !disable;
  return (
    <div className="row">
      <div className="col-6">
        <h5 class="d-inline-flex mb-0 mr-3">Case</h5>
      </div>
      <div class="col-6 text-right pt-1 pb-1">
        {allowEdit && (
          <a class="add-contact" onClick={() => fields.push({})}>
            {fields.length >= 1 && <label>+ Add Another Case</label>}
            {fields.length == 0 && <label>+ Add Case</label>}
          </a>
        )}
      </div>
      <div className="col-12">
        {fields.map((options, index) => {
          let sNo = index + 1;
          return (
            <div className="case-row" key={options}>
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <h6>Case {sNo}</h6>
                  </div>
                  <div className="col-6 text-right">
                    {allowEdit && fields.length > 1 && (
                      <a class="delete-contact" onClick={() => fields.remove(index)}>
                        <i class="fas fa-trash" /> Remove
                      </a>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group mb-1">
                          <Field
                            name={`${options}.CaseIf`}
                            type="text"
                            component={ReduxInput}
                            fieldName={'If'}
                            validate={Validator.required}
                            style={{ background: '#F8F8F8' }}
                            disabled={disable}
                          />
                          <label class="placeholder-label">if {sNo}*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group mb-1">
                          <Field
                            name={`${options}.CaseThen`}
                            type="text"
                            component={ReduxInput}
                            fieldName={'Then'}
                            validate={Validator.required}
                            style={{ background: '#F8F8F8' }}
                            disabled={disable}
                          />
                          <label class="placeholder-label">Then {sNo}*</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const OptionForm = ({ fields, name, meta: { touched, error }, change, disable, existingRows }) => {
  if (fields.length == 0) {
    fields.push({});
  }
  const allowEdit = !disable;
  return (
    <div className="conditions-form">
      <div class="row">
        <div className="col-6">
          <h5 class="d-inline-flex mb-0 mr-3">Condition</h5>
        </div>
        <div class="col-6 text-right pt-1 pb-1">
          {allowEdit && (
            <a class="add-contact" onClick={() => fields.push({})}>
              {fields.length >= 1 && <label>+ Add Another Term Condition</label>}
              {fields.length == 0 && <label>+ Add Term Condition</label>}
            </a>
          )}
        </div>
      </div>

      {fields.map((options, index) => {
        let sNo = index + 1;
        return (
          <div className="row mb-2" key={options}>
            <div className="col-12">
              <div className="card border-0">
                <div className="row card-body">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group mb-1">
                      <Field
                        name={`${options}.Condition`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'Condition'}
                        validate={Validator.required}
                        disabled={disable}
                      />
                      <label class="placeholder-label">Condition {sNo}*</label>
                    </div>
                  </div>
                  {allowEdit && fields.length > 1 && (
                    <div className="col-12 col-md-6 col-lg-8 text-right">
                      <a class="delete-contact" onClick={() => fields.remove(index)}>
                        <i class="fas fa-trash" />
                      </a>
                    </div>
                  )}
                  <div className="col-12">
                    <FieldArray
                      name={`${options}.ConditionCase`}
                      component={CaseForm}
                      disable={disable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
class Conditions extends Component {
  render() {
    const { name, disable } = this.props;
    return (
      <div className="row">
        <div className="col-12">
          <FieldArray name={'Conditions'} component={OptionForm} disable={disable} />
        </div>
      </div>
    );
  }
}

export default Conditions;
