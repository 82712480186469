import React, { Component, useState } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StudentImage } from '../component/student';
import { withRouter } from 'react-router-dom';
import '../component/jquery/slick.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AntdModal from '../Components/CustomModal';
import NumericType from '../component/numericType';
import CurrencyType from '../component/currencyType';
import TextareaType from '../component/textareaType';
import MultiSelectType from '../component/multiSelectType';
import DateType from '../component/dateType';
import SignatureType from '../component/signatureType';
import renderHTML from 'react-render-html';
import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  markAsComplete,
  saveConsentSign,
} from '../store/actions';
import QuestionType from './RCM/patientEnrollmentForm/formSteps/questionType/questionComponent';
import CustomModal from '../core/modal';

const SURVEYTYPES = {
  Binary: 1,
  Options: 2,
  Numeric: 3,
  AlphaNumeric: 4,
  SmileyType: 5,
  Currency: 6,
  MultiSelectType: 7,
  TextareaType: 9,
  Instruction: 12,
  Date: 13,
  Signature: 14,
};

const FeelingsType = () => {
  return (
    <div>
      <div class="survey-box">
        <h2>Alejandro, How do you feel today?</h2>
        <ul class="feel-list">
          <li>
            <a>
              <img src="images/feel-01.png" alt="feel" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="images/feel-02.png" alt="feel" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="images/feel-03.png" alt="feel" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="images/feel-04.png" alt="feel" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="images/feel-05.png" alt="feel" />
            </a>
          </li>
        </ul>

        <div class="clearfix" />
      </div>
    </div>
  );
};

const SmileyType = ({ survey, onClick, scope, index, total }) => {
  const {
    Question,
    Option1Value,
    Option1Text,
    Option2Value,
    Option2Text,
    Option3Value,
    Option3Text,
    Option4Value,
    Option5Value,
    Response,
    SurveyQuestionId,
    DoesNotApplyText,
    DoesNotApplyValue,
  } = survey;
  return (
    <div>
      <div class="card border-0">
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <h5 className="survey-question">{Question && renderHTML(Question)}</h5>
          <div class="answer-options text-center">
            <div
              class={`answer-box ${Response == '-2' ? 'selected' : ''}`}
              onClick={onClick.bind(scope, {
                surveyQuestionId: SurveyQuestionId,
                response: '-2',
              })}
            >
              <div class="icon">
                <img src="images/emotional-icon1.svg" alt="" />
              </div>
              <strong>Strongly Disagree</strong>
            </div>
            <div
              class={`answer-box ${Response == '-1' ? 'selected' : ''}`}
              onClick={onClick.bind(scope, {
                surveyQuestionId: SurveyQuestionId,
                response: '-1',
              })}
            >
              <div class="icon">
                <img src="images/emotional-icon2.svg" alt="" />
              </div>
              <strong>Disagree</strong>
            </div>
            <div
              class={`answer-box ${Response == '0' ? 'selected' : ''}`}
              onClick={onClick.bind(scope, {
                surveyQuestionId: SurveyQuestionId,
                response: '0',
              })}
            >
              <div class="icon">
                <img src="images/emotional-icon3.svg" alt="" />
              </div>
              <strong>Neutral</strong>
            </div>
            <div
              class={`answer-box ${Response == '1' ? 'selected' : ''}`}
              onClick={onClick.bind(scope, {
                surveyQuestionId: SurveyQuestionId,
                response: '1',
              })}
            >
              <div class="icon">
                <img src="images/emotional-icon4.svg" alt="" />
              </div>
              <strong>Agree</strong>
            </div>
            <div
              class={`answer-box ${Response == '2' ? 'selected' : ''}`}
              onClick={onClick.bind(scope, {
                surveyQuestionId: SurveyQuestionId,
                response: '2',
              })}
            >
              <div class="icon ">
                <img src="images/emotional-icon5.svg" alt="" />
              </div>
              <strong> Strongly Agree</strong>
            </div>
          </div>
          {DoesNotApplyText && (
            <div className="not-apply-to-me">
              <a
                name="current-situation"
                value={DoesNotApplyText}
                onClick={onClick.bind(scope, {
                  surveyQuestionId: SurveyQuestionId,
                  response: DoesNotApplyValue,
                })}
              >
                {DoesNotApplyText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AlphaNumericType = ({ survey, onClick, scope, index, total }) => {
  const { Question, DoesNotApplyText, DoesNotApplyValue, SurveyQuestionId } = survey;
  const [alphaNumeric, setAlphaNumeric] = useState();
  return (
    <>
      <div className="card border-0">
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <h5 className="survey-question">{Question && renderHTML(Question)}</h5>
          <div class="answer-options text-center">
            <div class="quantity-btn">
              <div class="numbers-row">
                <input
                  type="text"
                  id="alphaNumeric"
                  tabindex="0"
                  style={{ textAlign: 'center' }}
                  onBlur={() => {
                    setAlphaNumeric(document.getElementById('alphaNumeric').value);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            class="row"
            style={{
              position: 'absolute',
              bottom: '20px',
              textAlign: 'center',
              display: 'inline',
            }}
          >
            <div class="col-12 text-center">
              <button
                class="btn"
                onClick={onClick.bind(scope, {
                  surveyQuestionId: SurveyQuestionId,
                  response: alphaNumeric,
                })}
              >
                Next
              </button>
            </div>
          </div>
          {DoesNotApplyText && (
            <div className="not-apply-to-me">
              <a
                name="current-situation"
                value={DoesNotApplyText}
                onClick={onClick.bind(scope, {
                  surveyQuestionId: SurveyQuestionId,
                  response: DoesNotApplyValue,
                })}
              >
                {DoesNotApplyText}
              </a>
            </div>
          )}
        </div>
      </div>
      {/*     
      <div class="survey-box" style={{ width: '100%', display: 'inline-block;' }}>
        <h2>{Question}</h2>
        <div class="survey-form-box">
          <div class="survey-form-left">
            <div class="form-group survey-textinput">
              <div class="input-group">
                <input class="form-control" type="text" style={{ textAlign: 'center' }} />
              </div>
            </div>
            <span class="small-text" />
          </div>
          <div class="survey-form-right">
            <input
              type="button"
              class="form-control survey-form-btn"
              value="Next"
              tabindex="0"
              onClick={onClick.bind(this)}
            />
          </div>
          <div class="clearfix" />
        </div>
      </div> */}
    </>
  );
};

const OptionsType = ({ survey, onClick, scope, index, total }) => {
  const {
    Question,
    DoesNotApplyText,
    DoesNotApplyValue,
    Option1Value,
    Option1Text,
    Option2Value,
    Option2Text,
    Option3Value,
    Option3Text,
    Option4Value,
    Option4Text,
    Option5Value,
    Option5Text,
    Option6Value,
    Option6Text,
    Option7Value,
    Option7Text,
    Option8Value,
    Option8Text,
    Option9Value,
    Option9Text,
    Option10Value,
    Option10Text,
    Response,
    SurveyQuestionId,
  } = survey;
  const textArray = [
      Option1Text,
      Option2Text,
      Option3Text,
      Option4Text,
      Option5Text,
      Option6Text,
      Option7Text,
      Option8Text,
      Option9Text,
      Option10Text,
    ],
    valueArray = [
      Option1Value,
      Option2Value,
      Option3Value,
      Option4Value,
      Option5Value,
      Option6Value,
      Option7Value,
      Option8Value,
      Option9Value,
      Option10Value,
    ];
  return (
    <div class="card border-0">
      <div class="card-body">
        <span class="d-block question-no">
          <em>{index + 1}</em> of {total}
        </span>
        <h5 className="survey-question">{Question && renderHTML(Question)}</h5>
        <ul class="answer-options">
          {textArray.map((item, index) => {
            return (
              item && (
                <li key={index}>
                  <span className={Response == valueArray[index] ? 'selected' : ''}>
                    {' '}
                    <input
                      type="radio"
                      name="current-situation"
                      value={item}
                      onClick={onClick.bind(scope, {
                        surveyQuestionId: SurveyQuestionId,
                        response: valueArray[index],
                      })}
                    />
                    {item}
                  </span>
                </li>
              )
            );
          })}
        </ul>
        {DoesNotApplyText && (
          <div className="not-apply-to-me">
            <a
              name="current-situation"
              value={DoesNotApplyText}
              onClick={onClick.bind(scope, {
                surveyQuestionId: SurveyQuestionId,
                response: DoesNotApplyValue,
              })}
            >
              {DoesNotApplyText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const BinaryType = ({
  survey: { Question, SurveyQuestionId, Response, DoesNotApplyText, DoesNotApplyValue },
  onClick,
  scope,
  index,
  total,
}) => {
  return (
    <div class="card border-0">
      <div class="card-body">
        <span class="d-block question-no">
          <em>{index + 1}</em> of {total}
        </span>
        <h5 className="survey-question">{Question && renderHTML(Question)}</h5>
        <div class="answer-options text-center">
          <div
            class={`answer-box answer-no ${Response == 0 && 'selected'}`}
            onClick={onClick.bind(scope, { response: 0, surveyQuestionId: SurveyQuestionId })}
          >
            <div class="icon  failed-bg">
              <img src="images/thumbs-icon.svg" alt="" />
            </div>
            <h6 class="d-block"> No</h6>
          </div>
          <div
            class={`answer-box answer-yes ${Response == 1 && 'selected'}`}
            onClick={onClick.bind(scope, { response: 1, surveyQuestionId: SurveyQuestionId })}
          >
            <div class="icon success-bg">
              <img src="images/thumbs-icon.svg" alt="" />
            </div>
            <h6 class="d-block">Yes</h6>
          </div>
        </div>
        {DoesNotApplyText && (
          <div className="not-apply-to-me">
            <a
              name="current-situation"
              value={DoesNotApplyText}
              onClick={onClick.bind(scope, {
                surveyQuestionId: SurveyQuestionId,
                response: DoesNotApplyValue,
              })}
            >
              {DoesNotApplyText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const InstructionType = ({ survey, onClick, scope, index, total }) => {
  const { Question, SurveyQuestionId } = survey;
  return (
    <>
      <div className="card border-0">
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <h5 className="survey-question">{Question && renderHTML(Question)}</h5>

          <div
            class="row"
            style={{
              position: 'absolute',
              bottom: '20px',
              textAlign: 'center',
              display: 'inline',
            }}
          >
            <div class="col-12 text-center">
              <button
                class="btn"
                onClick={onClick.bind(scope, {
                  surveyQuestionId: SurveyQuestionId,
                  response: 0,
                })}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const SurveyQuestionTitle = ({ title }) => {
  return <h3 className="page-title mb-3 mb-md-5 screening-tool">{title}</h3>;
};
class Survey extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      isSubmitted: false,
      header: '',
    };
    window.scrollTo(0, 0);
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }

  getFormType() {
    var param = new URLSearchParams(this.props.history.location.search);
    if (param.get('form') === 'true') {
      return true;
    } else return false;
  }
  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }

  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(getSurveyQuestionMultiple(this.getSurveyId(), 1));
  }
  toggleView = header => {
    this.setState(prevState => ({
      isSubmitted: !prevState.isSubmitted,
      header,
    }));
  };
  componentWillReceiveProps({ surveys }) {
    if (surveys.length > 0) {
      for (var surveyIndex in surveys) {
        if (!surveys[surveyIndex].Response) {
          this.setState(
            {
              surveys: surveys,
              currentSlideIndex: parseInt(surveyIndex),
            },
            this.applySlick,
          );
          return;
        }
      }
    }
  }

  setValue({ response, surveyQuestionId }) {
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };

    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    this.setState({
      surveys: surveys,
    });

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response }) {
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };

    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    $('.main-banner').slick('slickNext');

    this.setState({
      surveys: surveys,
    });

    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }

  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('surveyId');
  }
  getIntakeType() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('intake');
  }
  onClose() {
    if (this.getIntakeType() == 'true') {
      this.props.history.push({
        pathname: '/clients/intake/dashboard',
        search: 'tab=intake-survey&id=' + this.getStudentId(),
      });
    } else {
      this.props.history.push({
        pathname: '/surveyer',
        search: 'studentId=' + this.getStudentId() + '&ignoreUpdatingPin=true',
      });
    }
    // this.props.history.push('/studentProfile/' + this.getStudentId());
  }

  onSubmit() {
    if (this.getIntakeType() == 'true') {
      this.props.history.push({
        pathname: '/clients/intake/dashboard',
        search: 'tab=intake-survey&id=' + this.getStudentId(),
      });
    } else {
      this.props.history.push({
        pathname: '/surveyer',
        search: 'studentId=' + this.getStudentId() + '&ignoreUpdatingPin=true',
      });
    }
  }
  saveSignature = (signOne, data, SurveyQuestionId, index) => {
    this.setState({
      surveyQuestionId: SurveyQuestionId,
      SignatureCanvas: {
        ...this.state.SignatureCanvas,
        [signOne]: data,
      },
    });
    this.props.dispatch(
      saveConsentSign({
        Json: JSON.stringify({
          Survey: [
            {
              ClientId: this.getStudentId(),
              SurveyId: this.getSurveyId(),
            },
          ],
        }),
        ImageSign: JSON.stringify({
          Image: [
            {
              SurveyQuestionId: SurveyQuestionId,
              Data: data,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { surveys, currentSlideIndex } = this.state;
    const { lastSlideIndex } = this.props;
    return (
      <>
        <div class="main survery-question-page  mb-5">
          <div class="container-fluid">
            <div class="row mt-5 mb-4  align-items-center">
              <StudentImage />

              <div class="col-12 col-sm-6 text-right">
                {/* <input
                  type="button"
                  class="btn black-btn"
                  value="Save &amp; Continue Later"
                  onClick={this.onSubmit.bind(this)}
                /> */}
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <button
                      className="btn black-btn"
                      data-toggle="modal"
                      data-target="#Save-Continue-Later-model"
                      onClick={onClick}
                    >
                      Save &amp; Continue Later
                    </button>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          The survey has been saved successfully
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button class="btn" onClick={this.onSubmit.bind(this)}>
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="survery-question-box">
                  {/* <h3 class="page-title mb-3 mb-md-5 screening-tool">
                    {surveys.length > 0 && surveys[0].SurveyCategory}
                  </h3> */}
                  {this.getFormType() ? (
                    <>
                      <ReduxFormTypeSurvey
                        surveys={surveys}
                        getSurveyId={this.getSurveyId()}
                        onNext={this.onNext}
                        setValue={this.setValue}
                        scope={this}
                        level={0}
                        isReadOnly={this.state.isReadOnly}
                        onSubmit={this.onSubmit.bind(this)}
                        getStudentId={this.getStudentId()}
                        saveSignature={this.saveSignature}
                      />
                    </>
                  ) : (
                    <>
                      {surveys.length > 0 && (
                        <>
                          <section class="main-banner slider survey-slider-wrap">
                            {surveys.map((survey, index) => {
                              const CONFIG = {
                                survey: survey,
                                index: index,
                                scope: this,
                                total: surveys.length - 1,
                                onClick: this.onNext,
                                setValue: this.setValue,
                              };
                              if (survey.SurveyQuestionTypeId == SURVEYTYPES.Binary) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <BinaryType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Options) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <OptionsType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Numeric) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <NumericType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Currency) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <CurrencyType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.SmileyType) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <SmileyType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.AlphaNumeric) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <AlphaNumericType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Instruction) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <InstructionType {...CONFIG} />
                                  </div>
                                );
                              } else if (
                                survey.SurveyQuestionTypeId == SURVEYTYPES.MultiSelectType
                              ) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <MultiSelectType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.TextareaType) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <TextareaType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Date) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <DateType {...CONFIG} />
                                  </div>
                                );
                              } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Signature) {
                                return (
                                  <div key={index}>
                                    <SurveyQuestionTitle title={survey.SurveyCategory} />
                                    <SignatureType
                                      onNext={this.onNext}
                                      saveSignature={this.saveSignature}
                                      {...CONFIG}
                                    />
                                  </div>
                                );
                              }
                            })}
                            <>
                              <div class="card border-0">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="completing-msg-left">
                                        <img
                                          src="images/completing-icon.png"
                                          style={{
                                            height: '330px',
                                            margin: 'auto',
                                            marginTop: '10%',
                                          }}
                                          alt="completing"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div
                                        class="completing-msg-right"
                                        style={{ paddingTop: '28%' }}
                                      >
                                        <h5>
                                          Thank you for completing the survey. Your survey has been
                                          submitted.
                                        </h5>
                                        <input
                                          type="button"
                                          class="btn mb-2 mr-2"
                                          value="Click here to take another Survey"
                                          onClick={this.onSubmit.bind(this)}
                                        />
                                        <input
                                          type="button"
                                          class="btn mb-2"
                                          value="CLOSE"
                                          onClick={this.onClose.bind(this)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          </section>
                          <section class="survey-dots-container">
                            <ul class="pagination align-items-center mt-3 mb-3 justify-content-center">
                              {this.state.surveys.map((item, index) => {
                                var display = index + 1;
                                var props = {};
                                if (index < currentSlideIndex && index <= lastSlideIndex) {
                                  if (item.Response || item.Response == 0) {
                                    props = {
                                      class: 'success-bg',
                                    };
                                  } else {
                                    props = {
                                      class: 'failed-bg',
                                    };
                                  }
                                }

                                if (currentSlideIndex < 4) {
                                  if (index == currentSlideIndex) {
                                    return (
                                      <li data-slide={display} class="page-item active">
                                        <a class="page-link">{display}</a>
                                      </li>
                                    );
                                  } else if (index >= 0 && index < 7) {
                                    return (
                                      <li data-slide={display} class="page-item ">
                                        <a class={`page-link ${props.class}`}>{display}</a>
                                      </li>
                                    );
                                  }
                                } else {
                                  if (index == currentSlideIndex) {
                                    if (display == lastSlideIndex + 2) {
                                      return (
                                        <li data-slide={display} class="page-item ">
                                          <span class={`completed-bg`}>{`Complete`}</span>
                                        </li>
                                      );
                                    } else
                                      return (
                                        <li data-slide={display} class="page-item active">
                                          <a class="page-link">{display}</a>
                                        </li>
                                      );
                                  } else if (
                                    index > currentSlideIndex - 4 &&
                                    index < currentSlideIndex + 4
                                  ) {
                                    if (display == lastSlideIndex + 2) {
                                      return (
                                        <li data-slide={display} class="page-item ">
                                          <span class={`completed-bg`}>{`Complete`}</span>
                                        </li>
                                      );
                                    } else
                                      return (
                                        <li data-slide={display} class="page-item ">
                                          <a class={`page-link ${props.class}`}>{display}</a>
                                        </li>
                                      );
                                  }
                                }
                              })}
                            </ul>
                          </section>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
class FormTypeSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      isSubmitted: false,
      header: '',
    };
  }
  toggleView = header => {
    this.setState(prevState => ({
      isSubmitted: !prevState.isSubmitted,
      header,
    }));
  };
  componentWillReceiveProps({ SuccessMSg, isSubmitted }) {
    if (isSubmitted && isSubmitted != this.props.isSubmitted) {
      // if ('Please answer all mandatory questions before submitting the survey.' === SuccessMSg)
      // this.setState(prevState => ({
      //   isSubmitted: true,
      //   header: SuccessMSg,
      // }));
      this.toggleView(SuccessMSg);
    }
  }
  render() {
    const {
      surveys,
      getSurveyId,
      onNext,
      setValue,
      scope,
      level,
      isReadOnly,
      onSubmit,
      getStudentId,
      saveSignature,
    } = this.props;
    return (
      <>
        <div class="card border-0 ent-multi-form">
          <div class="card-body">
            <div class="row">
              <div className="col-12">
                {surveys && (
                  <div
                    style={{
                      padding: '25px',
                      margin: '0px 25px',
                    }}
                  >
                    <QuestionType
                      SurveyQuestion={surveys}
                      SurveyId={getSurveyId}
                      onNext={onNext}
                      setValue={setValue}
                      scope={scope}
                      level={level}
                      isReadOnly={isReadOnly}
                      saveSignature={saveSignature}
                    />
                  </div>
                )}
              </div>
              {surveys && (
                <div class="col-12 col-md-12 col-lg-12 text-right mt-5">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <button
                        className="btn black-btn mr-2"
                        data-toggle="modal"
                        data-target="#Save-Continue-Later-model"
                        onClick={onClick}
                      >
                        Save &amp; Continue Later
                      </button>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            The survey has been saved successfully
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button class="btn" onClick={onSubmit}>
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  />
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <button
                        className="btn black-btn"
                        data-toggle="modal"
                        data-target="#Save-Continue-Later-model"
                        onClick={onClick}
                      >
                        Submit
                      </button>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure want to submit this survey?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button class="btn btn-outline-primary" onClick={onCancel}>
                            Cancel
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              this.props.dispatch(
                                markAsComplete({
                                  SurveyId: getSurveyId,
                                }),
                              );
                              onOk();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isSubmitted}
          header={this.state.header}
          yesAction={() => {
            this.toggleView();
            if (
              this.state.header.includes('mandatory')
              // 'Please answer all mandatory questions before submitting the survey.' ===
              // this.state.header
            )
              return;
            this.props.history.push({
              pathname: '/surveyer',
              search: 'studentId=' + getStudentId + '&ignoreUpdatingPin=true',
            });
          }}
          yesText="OK"
          hideCancel={true}
          changeMaxWidth="100%"
        />
      </>
    );
  }
}
const mapStateToPropsForm = ({ mark_as_complete }) => {
  let SuccessMSg = null;
  let isSubmitted = false;
  if (mark_as_complete.data && mark_as_complete.data.success) {
    SuccessMSg = mark_as_complete.data.result;
    isSubmitted = mark_as_complete.data.success;
  }
  return {
    SuccessMSg,
    isSubmitted,
  };
};
const ReduxFormTypeSurvey = connect(mapStateToPropsForm)(withRouter(FormTypeSurvey));

const mapStateToProps = ({ survery_question_get_multiple, mark_as_complete }) => {
  var { data, isFetching, error } = survery_question_get_multiple;
  var lastSlideIndex = 1;
  let SuccessMSg = null;
  let isSubmitted = false;
  if (mark_as_complete.data && mark_as_complete.data.success) {
    SuccessMSg = mark_as_complete.data.result;
    isSubmitted = mark_as_complete.data.success;
  }
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    //array = array.slice(0, 10);

    array.push({});
    data = [...array];
    lastSlideIndex = data.length - 2;
  }
  return {
    surveys: data || [],
    lastSlideIndex: lastSlideIndex,
    SuccessMSg,
    isSubmitted,
  };
};

export default connect(mapStateToProps, null, null, {
  areStatesEqual: (next, prev) => {
    var isEqual = prev.survery_question_get_multiple === next.survery_question_get_multiple;
    return isEqual;
  },
})(Survey);
