import React, { Component } from 'react';
// import AppUsers from './appUsers';
// import InHouse from './inHouse';
// import SMDashboard from './smDashboard';
// import Autocomplete from 'react-autocomplete';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import {
  getStudentsSearch,
  resetStudentsSearch,
  appSubscribersUserSearch,
  appInviteDashboard,
  getStaffShiftLog,
  addStaffShiftLog,
  resetStaffShiftLog,
  isAchValidation,
  achValidate,
  getDashboardMenu,
  mobileInviteOptionsLoad,
} from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
// import AntdModal from '../Components/CustomModal';
// import CustomModal from '../Components/CustomModal';
// import DatePicker from 'react-datepicker';
import { Modal, Button, Menu } from 'antd';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
} from '../../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { getPhoneNumber } from '../../util/phoneFormatter';
// import LicenseModal from './licenseAgreementModal';
// import ConfirmACHPayment from './confirmACHPaymentModal';
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Mobile app referral sent successfully',
  });
};

class SendAppInviteModal extends Component {
  constructor(props) {
    super(props);
    props.dispatch(mobileInviteOptionsLoad({}));
  }
  onhandleSubmit = val => {
    const { MobileNumber, Email, FirstName, LastName, IsStaffCenter, CountryCode } = val;
    if (MobileNumber || Email) {
      this.props.dispatch(
        appInviteDashboard({
          MobileNumber: getPhoneNumber(MobileNumber),
          Email,
          FirstName,
          LastName,
          IsStaffCenter,
          CountryCode,
        }),
      );
    } else {
      error();
    }
  };

  componentWillReceiveProps({ isSuccess, val, DefaultCountryCode }) {
    if (isSuccess && isSuccess !== this.props.isSuccess) {
      this.props.onCancel();
      success();
      this.props.dispatch(reset('inviteForm'));
    }
    if (
      DefaultCountryCode &&
      JSON.stringify(DefaultCountryCode) != JSON.stringify(this.props.DefaultCountryCode)
    ) {
      this.props.change('CountryCode', DefaultCountryCode.Value);
    }
  }
  render() {
    const { onOk, onCancel, handleSubmit, CountryCode, ReferralCenter } = this.props;

    const { ReferralCenterText, ShowReferralCenter } = ReferralCenter || {};

    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">Send Mobile Invite</h4>
        </div>

        <div class="modal-body col-md-10 offset-md-1 enrollment-form">
          <form onSubmit={handleSubmit(this.onhandleSubmit)}>
            <div class="form-group mb-2">
              <Field
                name="FirstName"
                type="text"
                component={ReduxInput}
                validate={Validator.required}
                fieldName={'First Name'}
              />
              <label class="placeholder-label">First Name*</label>
            </div>
            <div class="form-group mb-2">
              <Field
                name="LastName"
                type="text"
                component={ReduxInput}
                validate={Validator.required}
                fieldName={'Last Name'}
              />
              <label class="placeholder-label">Last Name*</label>
            </div>
            <div className="row">
              {/* <div className="col-4">
                <div class="form-group mb-2">
                  <Field
                    name="CountryCode"
                    type="text"
                    component={ReduxSelect}
                    options={CountryCode}
                    placeholder="Country"
                    // validate={Validator.required}
                    fieldName={'Country'}
                  />
                </div>
              </div> */}
              <div className="col-12">
                <div class="form-group mb-2">
                  <Field
                    name="MobileNumber"
                    type="text"
                    component={ReduxPhoneInput}
                    // validate={Validator.allowNumericMobile}
                    fieldName={'Mobile Number'}
                  />
                  <label class="placeholder-label" style={{ top: '-5px', fontSize: '14px' }}>
                    Mobile Number
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group mb-2">
              <Field
                name="Email"
                type="text"
                component={ReduxInput}
                validate={Validator.email}
                fieldName={'Email'}
              />
              <label class="placeholder-label">Email</label>
            </div>
            {ShowReferralCenter && (
              <div class="form-group">
                <Field
                  className="mr-2"
                  name="IsStaffCenter"
                  component={ReduxCheckbox}
                  fieldName={'isShowReferralCenter'}
                />
                <label class="placeholder-label" style={{ top: '5px' }}>
                  {' '}
                  {ReferralCenterText}
                </label>
              </div>
            )}

            <div class="modal-footer justify-content-center border-top-0">
              <input type="submit" class="btn black-btn font-weight-bold mr-2" value=" Send  " />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToPropsModal = state => {
  const { app_invite_dashboard, mobile_invite_options_load } = state;
  const { data } = app_invite_dashboard;
  const { data: inviteData } = mobile_invite_options_load;
  let isSuccess = false;
  let CountryCode = [],
    ReferralCenter = {},
    DefaultCountryCode = {};
  if (app_invite_dashboard.data && app_invite_dashboard.data.Success) {
    isSuccess = data.Success;
  }
  if (inviteData && inviteData.MobileInvite && inviteData.MobileInvite[0]) {
    CountryCode = inviteData.MobileInvite[0].CountryCode || [];
    ReferralCenter = inviteData.MobileInvite[0].ReferralCenter[0] || {};
    DefaultCountryCode = inviteData.MobileInvite[0].DefaultCountryCode[0] || {};
  }
  return {
    isSuccess,
    CountryCode,
    ReferralCenter,
    DefaultCountryCode,
    // name: selector(state, 'firstName'),
  };
};

const SendAppInviteReduxModal = reduxForm({
  form: 'inviteForm',
})(connect(mapStateToPropsModal)(withRouter(SendAppInviteModal)));

export default SendAppInviteReduxModal;
