import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cascader } from 'antd';
import moment from 'moment';
import { ReduxTextarea } from '../../../../../component/custom-redux-components';
import { Field, reduxForm } from 'redux-form';
import {
  upsertTreatmentPlanGoal,
  getTreatmentPlan,
  comboGoalTemplate,
} from '../../../../../store/actions';
import SubmitButton from '../../../../../Components/submitButton';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    // if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
    // } else {
    //   return 'should be greater than Start time';
    // }
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class AddGoalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      comboGoalDataState: [],
    };
  }

  onChange = (value, selectedOptions) => {
    // this.setState({
    //   text: selectedOptions.map(o => o.label).join(', '),
    // });
    this.props.setTemplateValue({
      goalDescription: selectedOptions.map(o => o.label).join(', '),
    });
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  componentDidMount() {
    this.props.dispatch(comboGoalTemplate({}));
  }
  componentWillReceiveProps({ upsertTreatmentPlanGoalDataSuccess, comboGoalData }) {
    if (
      upsertTreatmentPlanGoalDataSuccess &&
      upsertTreatmentPlanGoalDataSuccess != this.props.upsertTreatmentPlanGoalDataSuccess
    ) {
      this.props.toggleGoalModal();
      this.props.resetMessageText(
        `Goal ${this.props.treatmentPlanGoalId ? 'Updated' : 'Added'} Successfully`,
        {
          Json: JSON.stringify({
            TreatmentPlan: [
              {
                // TreatmentPlanId: this.props.clikedTreatmentPlanId,
                TreatmentPlanProblemId: this.props.treatmentPlanProblemId,
              },
            ],
          }),
        },
      );
      // let data = {
      //   Json: JSON.stringify({
      //     TreatmentPlan: [
      //       {
      //         // TreatmentPlanId: this.props.clikedTreatmentPlanId,
      //         TreatmentPlanProblemId: this.props.treatmentPlanProblemId,
      //       },
      //     ],
      //   }),
      // };
      // this.props.dispatch(getTreatmentPlan(data));
    }
    if (comboGoalData != this.props.comboGoalData) {
      let temp = comboGoalData.map(item => {
        return {
          label: item.Text,
          value: item.Value,
        };
      });
      this.setState({
        comboGoalDataState: temp,
      });
    }
  }

  onhandleSubmit = val => {
    const { treatmentPlanProblemId, treatmentPlanGoalId } = this.props;
    let data;
    if (treatmentPlanProblemId && treatmentPlanGoalId) {
      data = {
        Json: JSON.stringify({
          TreatmentPlanGoal: [
            {
              TreatmentPlanGoalId: treatmentPlanGoalId,
              TreatmentPlanProblemId: treatmentPlanProblemId,
              Description: val.goalDescription,
              // GoalTemplateId: null,
              // SubText: val.subText,
              // SubValue: val.subValue ? val.subValue : false,
            },
          ],
        }),
      };
    } else {
      data = {
        Json: JSON.stringify({
          TreatmentPlanGoal: [
            {
              TreatmentPlanGoalId: null,
              TreatmentPlanProblemId: this.props.treatmentPlanProblemId,
              Description: val.goalDescription,
              // GoalTemplateId: null,
              // SubText: val.subText,
              // SubValue: val.subValue ? val.subValue : false,
            },
          ],
        }),
      };
    }

    this.props.dispatch(upsertTreatmentPlanGoal(data, this.props.treatmentPlanDimensionId));
    // this.props.toggleGoalModal();
    // this.props.resetInitialValues();
  };

  render() {
    const { onCancel, isFetching } = this.props;
    const { comboGoalDataState } = this.state;
    return (
      <>
        <div class="modal-content border-0">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={() => {
                onCancel();

                this.props.resetInitialValues();
              }}
            >
              ×
            </button>
            <h4 class="modal-title col-12 text-left">Goal</h4>
            {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
          </div>
          <div class="modal-body rcm-panel">
            <div className="px-4 enrollment-form">
              <form onSubmit={this.props.handleSubmit(this.onhandleSubmit)}>
                <div className="d-flex">
                  <div class="form-group mb-2 " style={{ flexGrow: '8' }}>
                    <Field
                      name="goalDescription"
                      type="textarea"
                      component={ReduxTextarea}
                      fieldName={'Goal Description'}
                      validate={Validator.required}
                      style={{ minHeight: '92px' }}
                    />
                    <label class="placeholder-label" style={{ background: '#fefefe' }}>
                      Goal Description*
                    </label>
                  </div>
                  <div style={{ flexGrow: '1', marginLeft: '5px' }}>
                    {/* <Popover
                      content={<a onClick={this.hide}>Close</a>}
                      title="Title"
                      trigger="click"
                      visible={this.state.visible}
                      onVisibleChange={this.handleVisibleChange}
                    > */}
                    <Cascader options={comboGoalDataState} onChange={this.onChange}>
                      <a href="#" style={{ color: 'grey' }}>
                        {/* <Icon type="down-circle" /> */}
                        <i class="fas fa-chevron-circle-down"></i>
                      </a>
                    </Cascader>

                    {/* </Popover> */}
                  </div>
                </div>
                {/* <div className="d-flex">
                  <div class="form-group mb-2" style={{ flexGrow: '8' }}>
                    <Field
                      name="subText"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Sub Text'}
                    />
                    <label class="placeholder-label">Sub Text</label>
                  </div>
                  <div style={{ flexGrow: '1', marginLeft: '5px' }}>&nbsp;</div>
                </div> */}
                <br />
                {/* <div class="form-group mb-2">
                  <label class="" for="Present-on-Admission">
                    Sub Value {'   '}
                    <br />
                    <Field
                      name="subValue"
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                      fieldName={'Sub Value'}
                    />
                  </label>
                </div> */}

                <div class="modal-footer  border-top-0 pt-2 px-0 mr-0">
                  <SubmitButton
                    loading={isFetching}
                    style={{ padding: '0 24px', height: '44px' }}
                    value={this.props.treatmentPlanGoalId ? 'Update Goal' : ' Add Goal'}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ upsert_treatment_plan_goal, combo_goal_template }) => {
  const { data: upsertTreatmentPlanGoalData, isFetching } = upsert_treatment_plan_goal;
  const { data: comboGoalTemplateData } = combo_goal_template;
  let upsertTreatmentPlanGoalDataSuccess;
  let comboGoalData;
  if (upsert_treatment_plan_goal && upsert_treatment_plan_goal.data) {
    upsertTreatmentPlanGoalDataSuccess = upsert_treatment_plan_goal.data.success;
  }
  if (comboGoalTemplateData) {
    comboGoalData = comboGoalTemplateData.GoalTemplate;
  }
  return {
    comboGoalData: comboGoalData || [],
    upsertTreatmentPlanGoalDataSuccess: upsertTreatmentPlanGoalDataSuccess || false,
    isFetching,
  };
};

const ReduxAddGoalModal = reduxForm({
  form: 'AddGoalModal',
  enableReinitialize: true,
})(connect(mapStateToProps)(AddGoalModal));

export { ReduxAddGoalModal };
