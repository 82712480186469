import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

const ReduxTextarea = ({
  input,
  type,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  maxLength,
  mobileFormat,
  tabIndex,
  style,
  fieldName,
  min,
  max,
  controlledMaxAge,
  negativeNumberBlocked,
  showPlaceHolder,
  label,
}) => {
  var { touched, error, warning, active } = meta || {};
  let lineRows;
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onBlur = input.onBlur;
    lineRows = value.split('\n');
  }

  /*if(mobileFormat){
        value = Util.formatPhoneNumber(value);
    }*/

  return (
    <>
      {label && (
        <label
          class="placeholder-label datepicker-label"
          style={{
            zIndex: 1,
            fontSize: !!disabled || value || active ? '11px' : '16px',
            top: !!disabled || value || active ? '-5px' : '10px',
          }}
        >
          {label}
        </label>
      )}
      {!controlledMaxAge ? (
        !negativeNumberBlocked ? (
          <TextareaAutosize
            {...input}
            style={{ resize: 'vertical' }}
            tabIndex={tabIndex}
            type={type}
            name={name}
            id={id}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            rows={lineRows && lineRows.length}
            // placeholder={active && placeholder}
            placeholder={showPlaceHolder ? placeholder : active && placeholder}
            // className={`${className} ${value && 'not-empty'}`}
            className={`form-control ${className} ${value && 'not-empty'}`}
            disabled={!!disabled}
            maxLength={maxLength}
            autocomplete="off"
          />
        ) : (
          <TextareaAutosize
            {...input}
            style={style}
            tabIndex={tabIndex}
            type={type}
            name={name}
            id={id}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            rows={lineRows && lineRows.length}
            onBlur={onBlur}
            placeholder={active && placeholder}
            // className={`${className} ${value && 'not-empty'}`}
            disabled={!!disabled}
            maxLength={maxLength}
            autocomplete="off"
            min={min}
            max={max}
          />
        )
      ) : (
        <TextareaAutosize
          {...input}
          style={style}
          tabIndex={tabIndex}
          type={type}
          name={name}
          id={id}
          defaultValue={defaultValue}
          value={value}
          rows={lineRows && lineRows.length}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={active && placeholder}
          className={`${className} ${value && 'not-empty'}`}
          disabled={!!disabled}
          maxLength={maxLength}
          autocomplete="off"
          min={min}
          max={max}
          //   onKeyPress={function(evt) {
          //     evt.preventDefault();
          //   }
          // }
        />
      )}

      {touched &&
        ((error && fieldName && (
          <span className="error-text">
            {fieldName} {error}
          </span>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </>
  );
};

export { ReduxTextarea };
