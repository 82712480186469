import React, { Component, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Slider from 'react-slick';
import { Table, Avatar, Cascader, Button, Popover, Drawer, Tag, Tooltip } from 'antd';
import { appointmentNoteList } from '../../store/actions';
import { StarOutlined, StarFilled, StarTwoTone, DeleteOutlined } from '@ant-design/icons';
import TextareaAutosize from 'react-autosize-textarea';
import {
  clientSharedMemoGetAll,
  clientSharedMemoUpdate,
  clientSharedMemoUpsert,
  resetClientSharedMemoUpdate,
  resetClientSharedMemoUpsert,
  resetClientSharedMemoGetAll,
} from '../../store/actions';
import CustomModal from '../../core/modal';
import Loader, { Loader2 } from '../../Components/Loader';
import DictationComponent from '../../Components/DictationComponent';
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const { CheckableTag } = Tag;

const SharedNote = props => {
  const { sharedMemoList, searchedClient, ClientId } = props;

  const {
    ClientSharedMemoId,
    CreatedBy,
    CreatedOn,
    DateInserted,
    IsArchived,
    IsFavourite,
    SharedMemo,
  } = (sharedMemoList && sharedMemoList[0]) || {};
  const [isSharedNoteDrawerOpen, setIsSharedNoteDrawerOpen] = useState(false);
  const [memo, setMemo] = useState('');
  const dispatch = useDispatch();

  const showDrawer = () => {
    setIsSharedNoteDrawerOpen(true);
  };

  const onClose = () => {
    setIsSharedNoteDrawerOpen(false);
  };

  const getLatestData = searchedClient => {
    let data = {
      Json: JSON.stringify({
        SharedMemo: [
          {
            ClientId: (searchedClient && searchedClient.ClientId) || ClientId,
          },
        ],
      }),
    };
    dispatch(clientSharedMemoGetAll(data));
  };

  const makeFav = ClientSharedMemoId => {
    let data = {
      Json: JSON.stringify({
        SharedMemo: [
          {
            ClientSharedMemoId: ClientSharedMemoId,
            IsFavourite: true,
            IsArchived: false,
          },
        ],
      }),
    };
    dispatch(clientSharedMemoUpdate(data));
  };

  const removeFav = ClientSharedMemoId => {
    let data = {
      Json: JSON.stringify({
        SharedMemo: [
          {
            ClientSharedMemoId: ClientSharedMemoId,
            IsFavourite: false,
            IsArchived: false,
          },
        ],
      }),
    };
    dispatch(clientSharedMemoUpdate(data));
  };

  const makeArchive = ClientSharedMemoId => {
    let data = {
      Json: JSON.stringify({
        SharedMemo: [
          {
            ClientSharedMemoId: ClientSharedMemoId,
            IsArchived: true,
          },
        ],
      }),
    };
    dispatch(clientSharedMemoUpdate(data));
  };

  const addMemo = () => {
    let data = {
      Json: JSON.stringify({
        SharedMemo: [
          {
            ClientId: (searchedClient && searchedClient.ClientId) || ClientId,
            SharedMemo: memo,
          },
        ],
      }),
    };
    dispatch(clientSharedMemoUpsert(data));
  };

  const handleDictate = result => {
    if (result) {
      let string = memo + ' ' + result;
      setMemo(string);
    }
  };

  useEffect(() => {
    if (searchedClient && searchedClient.ClientId) {
      getLatestData(searchedClient);
    } else if (ClientId) {
      getLatestData(ClientId);
    }
  }, [searchedClient, ClientId]);

  useEffect(() => {
    if (searchedClient && searchedClient.ClientId) {
      let data3 = {
        json: JSON.stringify({
          AppointmentNote: [
            {
              ClientId: searchedClient && searchedClient.ClientId,
            },
          ],
        }),
      };
      dispatch(appointmentNoteList(data3));
    } else {
      let data3 = {
        json: JSON.stringify({
          AppointmentNote: [
            {
              ClientId: ClientId,
            },
          ],
        }),
      };
      dispatch(appointmentNoteList(data3));
    }
  }, [searchedClient, ClientId]);

  useEffect(() => {
    if (props.isMemoUpdate) {
      toast.success(props.memoMsg, {
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          getLatestData(searchedClient);
          dispatch(resetClientSharedMemoUpdate({}));
          dispatch(resetClientSharedMemoUpsert({}));
          dispatch(resetClientSharedMemoGetAll({}));
        },
      });
    }
  }, [props.isMemoUpdate]);

  useEffect(() => {
    if (props.isMemoAdded) {
      toast.success('Shared Memo added successfully', {
        position: 'top-center',
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          getLatestData(searchedClient);
          dispatch(resetClientSharedMemoUpdate({}));
          dispatch(resetClientSharedMemoUpsert({}));
          dispatch(resetClientSharedMemoGetAll({}));
        },
      });
    }
  }, [props.isMemoAdded]);

  useEffect(() => {
    if (props.isFetching) {
      setMemo('');
    }
  }, [props.isFetching]);

  useEffect(() => {
    dispatch(resetClientSharedMemoGetAll({}));
  }, []);

  return (
    <>
      <div className="mt-4 mb-1 w-100 appointment-slider" style={{ margin: '0 auto' }}>
        {(searchedClient && searchedClient.ClientId) || ClientId ? (
          <div className="row">
            {' '}
            <div
              className="col-md-12 bold text-center"
              style={{
                color: '#80808078',
                fontSize: '18px',
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <span
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={showDrawer}
                >
                  Shared Memos &nbsp;
                  <i
                    class="fas fa-external-link-alt"
                    style={{ color: '#46b4fe', cursor: 'pointer' }}
                  ></i>
                </span>
                &nbsp;
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {SharedMemo ? (
          <div class="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {/* <div className="col-12 text-right">
                    <span className="" style={{ fontSize: '12px', color: '#959595' }}>
                      {CreatedOn}
                    </span>
                  </div>{' '} */}
                  <div className="col-12 text-justify">{SharedMemo}</div>
                </div>
              </div>
              <div className="col-12" style={{ fontSize: '12px', color: '#959595' }}>
                Created By : {CreatedBy} ({CreatedOn})
              </div>
            </div>
          </div>
        ) : (
          <>
            {(searchedClient && searchedClient.ClientId) || ClientId ? (
              <div className="text-center" style={{ color: 'rgb(149, 149, 149)' }}>
                <i class="fa fa-info-circle" aria-hidden="true"></i> Note : No memo available for
                now.
              </div>
            ) : (
              ''
            )}
          </>
        )}

        <Drawer
          className="custom-drawer shared-memo-drawer"
          title="Shared Memos"
          placement="right"
          onClose={onClose}
          open={isSharedNoteDrawerOpen}
          width="800px"
        >
          {props.isFetching ? (
            <Loader2 loading={props.isFetching} />
          ) : (
            <>
              {' '}
              <div className="container">
                <div className="row mt-1">
                  {' '}
                  <h5>Add Memo</h5>
                  <TextareaAutosize
                    style={{ height: '100px' }}
                    className={`form-control not-empty`}
                    value={memo}
                    onChange={event => {
                      setMemo(event.target.value);
                    }}
                    rows="3"
                    key="addANote"
                    placeholder="Add a memo.."
                  />
                  <div class="col-md-12 text-right mt-3 px-0">
                    {' '}
                    {memo ? (
                      <>
                        <div
                          className="dictation-space"
                          style={{
                            display: 'inline-flex',
                            paddingRight: '10px',
                          }}
                        >
                          <DictationComponent
                            handleDictate={handleDictate}
                            style={{
                              marginTop: '10px',
                            }}
                          />
                        </div>
                        <button
                          type="button"
                          class="btn btn-outline-primary px-5 mr-2"
                          onClick={() => {
                            setMemo('');
                          }}
                        >
                          Clear
                        </button>
                        <button className="btn px-5 mr-2" onClick={addMemo}>
                          Add
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className="dictation-space"
                          style={{ display: 'inline-flex', paddingRight: '10px' }}
                        >
                          <DictationComponent handleDictate={handleDictate} />
                        </div>
                        <button
                          type="button"
                          class="btn btn-outline-primary px-5 mr-2"
                          onClick={() => {
                            setMemo('');
                          }}
                        >
                          Clear
                        </button>
                        <button
                          class="btn px-5"
                          style={{
                            position: 'unset',
                            width: 'unset',
                            background: '#80808038',
                            color: '#f0f0f0',
                            cursor: 'not-allowed',
                          }}
                        >
                          Add
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="row mt-1">
                  <div class="col-12 col-md-12" style={{ textAlign: 'right' }}></div>
                </div>
                <div
                  className="row"
                  // style={{ height: '00px', overflow: 'auto' }}
                >
                  <div
                    className="col-md-12"
                    // style={{
                    //   fontSize: '24px',
                    // }}
                  >
                    {sharedMemoList &&
                      sharedMemoList.map((item1, index1) => {
                        const {
                          ClientSharedMemoId,
                          CreatedBy,
                          CreatedOn,
                          DateInserted,
                          IsArchived,
                          IsFavourite,
                          SharedMemo,
                        } = item1;
                        return (
                          <div className="row  mt-2 mb-2" style={{ background: 'white' }}>
                            <div className="col-12">
                              <div className="row">
                                {/* <div className="col-12 text-right">
                                  <span className="" style={{ fontSize: '12px', color: '#959595' }}>
                                    {CreatedOn}
                                  </span>
                                </div>{' '} */}
                                <div className="col-12">
                                  <span className="">{SharedMemo}</span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6" style={{ fontSize: '12px', color: '#959595' }}>
                              Created By : {CreatedBy} ({CreatedOn})
                            </div>
                            <div className="col-6 text-right">
                              <span style={{ cursor: 'pointer' }}>
                                {IsFavourite ? (
                                  <>
                                    <Tooltip placement="top" title="Remove from favourite">
                                      <StarFilled
                                        style={{ fontSize: '16px', color: 'yellow' }}
                                        onClick={() => {
                                          removeFav(ClientSharedMemoId);
                                        }}
                                      />{' '}
                                      &nbsp;
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip placement="top" title="Add to favourite">
                                      {' '}
                                      <StarOutlined
                                        style={{ fontSize: '16px' }}
                                        onClick={() => {
                                          makeFav(ClientSharedMemoId);
                                        }}
                                      />{' '}
                                      &nbsp;
                                    </Tooltip>
                                  </>
                                )}
                              </span>
                              <span style={{ cursor: 'pointer' }}>
                                <Tooltip placement="top" title="Archive memo">
                                  <DeleteOutlined
                                    style={{ fontSize: '16px' }}
                                    onClick={() => {
                                      makeArchive(ClientSharedMemoId);
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          )}
        </Drawer>
      </div>
    </>
  );
};

const mapStateToPropsOneStudent = state => {
  const {
    client_shared_memo_get_all,
    client_shared_memo_update,
    client_shared_memo_upsert,
  } = state;

  const { data: memoList } = client_shared_memo_get_all;
  const { data: memoUpdate } = client_shared_memo_update;
  const { data, isFetching } = client_shared_memo_upsert;

  let sharedMemoList = [];
  let isMemoUpdate = null;
  let memoMsg = '';
  let isMemoAdded = false;
  if (data && data.success) {
    isMemoAdded = data.success;
  }

  if (memoList) {
    sharedMemoList = memoList.SharedMemo || [];
  }
  if (memoUpdate && memoUpdate.success) {
    isMemoUpdate = memoUpdate.success || [];
    memoMsg = memoUpdate.result || '';
  }

  return {
    sharedMemoList,
    isMemoUpdate,
    memoMsg,
    isMemoAdded,
    isFetching,
  };
};

const SharedNoteComponent = connect(mapStateToPropsOneStudent)(SharedNote);

export default SharedNoteComponent;
