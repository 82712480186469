import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  upsertPayer,
  resetUpsertPayer,
  listPayer,
  deletePayer,
  getPayer,
  medicateCombos,
  getComboFacility,
} from '../../../../store/actions';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import moment from 'moment';
import AntdModal from '../../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../../Components/Loader';

import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import { Table, Modal } from 'antd';
import PersistTableSort from '../../../../component/persistTableSort';
import { Tooltip } from 'antd';
import SubmitButton from '../../../../Components/submitButton';

class PayerStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PayerData: [],
      PayerInfo: {},
      createAndEditGroupModal: false,
    };
  }
  // need to work on it always return zero for me

  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes: 'PayerType,InvoiceDisplay,PayerOrg',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    this.props.dispatch(listPayer({}));
    this.props.dispatch(getComboFacility({}));
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({ isInserted, isRemoved, payerData, payerinfo }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditGroupModal: false });
      this.toggle('Payer saved successfully');
      this.props.dispatch(resetUpsertPayer());
      this.props.dispatch(reset('payerForm'));
      // this.props.dispatch(listPayer({}));
      this.setState({
        PayerInfo: {},
      });
    }
    if (isRemoved && isRemoved != this.props.isRemoved) {
      this.toggle('Payer Deleted Successfully');
      // this.props.dispatch(listPayer({}));
    }
    if (payerData && JSON.stringify(payerData) != JSON.stringify(this.props.payerData)) {
      this.setState({
        PayerData: payerData,
      });
    }
    if (payerinfo && JSON.stringify(payerinfo) != JSON.stringify(this.props.payerinfo)) {
      let data = {
        PayerId: payerinfo.PayerId,
        payerName: payerinfo.PayerName,
        payerTypeId: payerinfo.PayerTypeId,
        payerOrgId: payerinfo.PayerOrgId,
        invoiceDisplayId: payerinfo.InvoiceDisplayId,
        facilityId: JSON.stringify(payerinfo.FacilityId),
        insurancePayerId: payerinfo.InsurancePayerId,
        phone: payerinfo.Phone,
        limsabcCode: payerinfo.LimsabcCode,
        clearingHousePayerId: payerinfo.ClearingHousePayerId,
      };
      this.setState({
        PayerInfo: data,
      });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      PayerId,
      payerName,
      payerTypeId,
      invoiceDisplayId,
      facilityId,
      phone,
      insurancePayerId,
      payerOrgId,
      clearingHousePayerId,
      limsabcCode,
    } = values;
    // let patientId = null;
    // let paramClientId = this.props.clientId;
    // if (paramClientId && paramClientId > 0) {
    //   patientId = paramClientId;
    // }
    const data = {
      Payer: [
        {
          // ClientId: patientId,
          PayerId: this.state.PayerInfo.PayerId || null,
          PayerName: payerName,
          PayerTypeId: parseInt(payerTypeId),
          PayerOrgId: parseInt(payerOrgId),
          InvoiceDisplayId: parseInt(invoiceDisplayId),
          FacilityId: parseInt(facilityId),
          InsurancePayerId: insurancePayerId,
          Phone: phone,
          LimsabcCode: limsabcCode,
          ClearingHousePayerId: clearingHousePayerId,
        },
      ],
    };
    let PayerData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertPayer(PayerData));
  }
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listPayer({
        json: JSON.stringify({
          Payer: [
            {
              Search: value,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const tableCoumns = [
      {
        title: 'Payer Name',
        dataIndex: 'PayerName',
        key: 'PayerName',
        sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Patient Id',
        // dataIndex: 'ClientId',
        dataIndex: 'DisplayClientId',
        key: 'ClientId',
        // sorter: (a, b) => isComparer(a.ClientId, b.ClientId),
        sorter: (a, b) => isComparer(a.DisplayClientId, b.DisplayClientId),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
      },
      {
        title: 'Full Name',
        dataIndex: 'FullName',
        key: 'FullName',
        sorter: (a, b) => isComparer(a.FullName, b.FullName),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Insurance Profile',
        dataIndex: 'ClientId',
        // dataIndex: 'DisplayClientId',
        key: 'ClientId',
        // sorter: (a, b) => isComparer(a.FullName, b.FullName),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
        render: ClientId => {
          return (
            <>
              <i
                class="fas fa-eye"
                onClick={() => {
                  this.props.history.push(
                    '/cw/patient-management/patient-profile?tab=insurance-information&pId=' +
                      ClientId,
                    // DisplayClientId,
                  );
                }}
              ></i>
            </>
          );
        },
      },
      {
        title: 'Action',
        key: 'x',
        width: '30px',
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <>
                <div class="tools-grid">
                  <div className="mr-4">
                    <span className="start-btn">
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={event => {
                          this.setState({
                            createAndEditGroupModal: true,
                            PayerInfo: {
                              payerName: record.PayerName,
                            },
                          });
                        }}
                      >
                        Add this Payer
                      </span>
                      {/* <img
                  src="images/shape.svg"
                  width="14px"
                  style={{ cursor: 'pointer' }}
                  onClick={event => {
                    this.setState({
                      createAndEditGroupModal: true,
                      PayerInfo: {
                        payerName: record.PayerName,
                      },
                    });

                    // this.props.dispatch(
                    //   getPayer({
                    //     json: JSON.stringify({
                    //       Payer: [
                    //         {
                    //           PayerId: record.PayerId,
                    //         },
                    //       ],
                    //     }),
                    //   }),
                    // );
                  }}
                /> */}
                    </span>
                  </div>
                  <div className="">
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn d-none"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Delete">
                            <p>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to delete this Payer?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  deletePayer({
                                    json: JSON.stringify({
                                      Payer: [
                                        {
                                          PayerId: record.PayerId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const tempColumns = [
      {
        title: 'Payer Name',
        dataIndex: 'PayerName',
        key: 'PayerName',
        sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Payer Type',
        dataIndex: 'PayerType',
        key: 'PayerType',
        sorter: (a, b) => isComparer(a.PayerType, b.PayerType),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Invoice Display',
      //   dataIndex: 'InvoiceDisplay',
      //   key: 'InvoiceDisplay',
      //   sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Insurance Payer Id',
        dataIndex: 'InsurancePayerId',
        key: 'InsurancePayerId',
        sorter: (a, b) => isComparer(a.InsurancePayerId, b.InsurancePayerId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'CH Payer Id',
        dataIndex: 'ClearingHousePayerId',
        key: 'ClearingHousePayerId',
        sorter: (a, b) => isNumericComparer(a.ClearingHousePayerId, b.ClearingHousePayerId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Billing Location',
        dataIndex: 'FacilityName',
        key: 'FacilityName',
        sorter: (a, b) => isComparer(a.FacilityName, b.FacilityName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Phone Number',
        dataIndex: 'Phone',
        key: 'Phone',
        sorter: (a, b) => isComparer(a.Phone, b.Phone),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Insurance Code',
        dataIndex: 'LimsabcCode',
        key: 'LimsabcCode',
        sorter: (a, b) => isComparer(a.LimsabcCode, b.LimsabcCode),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <>
                {' '}
                <div class="tools-grid">
                  <div className="mr-4">
                    <span className="start-btn">
                      <Tooltip placement="top" title="Edit">
                        <img
                          src="images/shape.svg"
                          width="14px"
                          style={{ cursor: 'pointer' }}
                          onClick={event => {
                            this.setState({
                              createAndEditGroupModal: true,
                            });

                            this.props.dispatch(
                              getPayer({
                                json: JSON.stringify({
                                  Payer: [
                                    {
                                      PayerId: record.PayerId,
                                    },
                                  ],
                                }),
                              }),
                            );
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="">
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Delete">
                            <p>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to delete this Payer?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  deletePayer({
                                    json: JSON.stringify({
                                      Payer: [
                                        {
                                          PayerId: record.PayerId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const { PayerData } = this.state;
    const { NonPayersList, isReadOnlyUser } = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid mt-5">
          <div className="">
            <div className="row px-0 mt-5 d-flex align-items-center">
              <div class="col-md-12 col-xl-4 px-0 align-self-baseline">
                {' '}
                <h4 className="">PAYER LIST</h4>
              </div>
              <div class="col-md-6 col-xl-6 px-0 d-flex align-items-center">
                <div class="search-box d-inline-flex w-100">
                  <div class="form-group has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-2 px-0 d-flex align-items-center align-self-baseline justify-content-end">
                {/* <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  value={'Filter'}
                /> */}
                {!isReadOnlyUser && (
                  <input
                    type="button"
                    style={{ width: '150px' }}
                    class="btn"
                    value={'New Payer'}
                    onClick={event => {
                      this.setState({
                        createAndEditGroupModal: true,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <ReduxPayerForm
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.PayerInfo}
            onCancel={this.props.onCancel}
            PayerId={this.state.PayerInfo.PayerId}
            Delete={() => {
              this.Removetoggle();
              //this.props.history.push('/manage/courses')
            }}
          /> */}
          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name="payer-list"
                    // bordered
                    columns={tempColumns}
                    dataSource={PayerData}
                    size={'middle'}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row px-0 mt-5 d-flex align-items-center">
              <div class="col-12 px-0 align-self-baseline">
                {' '}
                <h4 className="">PAYERS NOT YET ADDED</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name="non-payer-list"
                    // bordered
                    columns={tableCoumns}
                    dataSource={NonPayersList}
                    size={'small'}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(listPayer({}));
            this.toggle();
            // this.props.history.push('/manage/courses');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(courseDelete({ CourseId: this.getClientId() }));
            // this.props.history.push('/manage/courses');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            // this.props.history.push('/manage/courses');
          }}
        />
        <Modal
          zIndex={100}
          visible={this.state.createAndEditGroupModal}
          footer={null}
          closable={false}
          width={600}
          destroyOnClose={true}
        >
          <ReduxPayerForm
            practitionerOffice={this.props.practitionerOffice}
            PayerType={this.props.PayerType}
            payerOrg={this.props.payerOrg}
            InvoiceDisplay={this.props.InvoiceDisplay}
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.PayerInfo}
            isUpsertFetching={this.props.isUpsertFetching}
            // onCancel={this.props.onCancel}
            PayerId={this.state.PayerInfo.PayerId}
            Delete={() => {
              this.Removetoggle();
              //this.props.history.push('/manage/courses')
            }}
            onCancel={() => {
              // this.resetSearchClientValues();
              this.setState({
                createAndEditGroupModal: false,
                PayerInfo: {},
              });
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const PayerForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    disable,
    PayerId,
    onCancel,
    PayerType,
    payerOrg,
    InvoiceDisplay,
    practitionerOffice,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div class="modal-header flex-wrap border-bottom-0 ">
        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        <h4 class="modal-title col-12 text-left">{PayerId ? 'Update Payer' : 'ADD NEW PAYER'}</h4>
        {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
      </div>
      <div class="modal-body rcm-panel enrollment-form">
        <div class="row mb-4">
          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              <Field
                name={`payerName`}
                component={ReduxInput}
                validate={validate.required}
                fieldName={'Payer'}
                // disabled={disable}
              />
              <label class="placeholder-label">Payer Name*</label>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              <Field
                name={`payerTypeId`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'Payer Type'}
                disabled={disable}
                options={PayerType}
                placeholder="Payer Type"
              />
              {/* <label class="placeholder-label">Payer Type</label> */}
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              <Field
                name={`payerOrgId`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'Payer Organization'}
                disabled={disable}
                options={payerOrg}
                placeholder="Payer Organization"
              />
              {/* <label class="placeholder-label">Payer Type</label> */}
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              {/* <Field
                name={`invoiceDisplayId`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'Invoice Display'}
                disabled={disable}
                options={InvoiceDisplay}
                placeholder="Invoice Display"
              /> */}
              <Field
                name={`insurancePayerId`}
                component={ReduxInput}
                // validate={validate.required}
                fieldName={'Insurance Payer Id'}
                disabled={disable}
              />
              <label class="placeholder-label">Insurance Payer Id</label>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              {/* <Field
                name={`invoiceDisplayId`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'Invoice Display'}
                disabled={disable}
                options={InvoiceDisplay}
                placeholder="Invoice Display"
              /> */}
              <Field
                name={`clearingHousePayerId`}
                component={ReduxInput}
                // validate={validate.required}
                fieldName={'Clearing House Payer Id'}
                disabled={disable}
              />
              <label class="placeholder-label">Clearing House Payer Id</label>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              <Field
                name={`facilityId`}
                type="text"
                component={ReduxSelect}
                // validate={validate.required}
                fieldName={'Billing Location'}
                disabled={disable}
                options={practitionerOffice}
                placeholder="Billing Location"
              />
              {/* <label class="placeholder-label">Billing Location</label> */}
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              <Field
                name={`phone`}
                type="text"
                component={ReduxInput}
                // validate={validate.required}
                fieldName={'Phone'}
                disabled={disable}
              />
              <label class="placeholder-label">Phone</label>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-6">
            <div class="form-group">
              <Field
                name={`limsabcCode`}
                type="text"
                component={ReduxInput}
                // validate={validate.required}
                fieldName={'Insurance Code'}
                disabled={disable}
              />
              <label class="placeholder-label">Insurance Code</label>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-12 text-right">
            <input
              type="button"
              class="btn btn-outline-primary px-5 mr-2"
              value={'Cancel'}
              onClick={onCancel}
            />
            {/* <input type="submit" class="btn px-5" value={PayerId ? 'Update' : 'Add'} /> */}
            <SubmitButton
              className="px-5"
              loading={props.isUpsertFetching}
              style={{height: '45px'}}
              value={PayerId ? 'Update' : 'Add'}
            />
          </div>

          {/* <div class="col-12 col-md-12 col-lg-6 text-center">
            <input type="submit" class="btn px-5" value={PayerId ? 'Update':'Add'} />
          </div>
          <div class="col-12 col-md-12 col-lg-6 text-center">
            <input type="button" class="btn btn-outline-primary px-5" value={'Cancel'} onClick={onCancel} />
          </div> */}
        </div>
      </div>
    </form>
  );
};
// const mapStateToProps = state => {
//   const { combos, medicate_combos } = state;
//   return {
//     // WorkSkill: combos.data.WorkSkill,
//     // isEmployedValue: selector(state, 'isEmployed'),
//   };
// };
const ReduxPayerForm = reduxForm({
  form: 'payerForm',
  enableReinitialize: true,
})(connect()(withRouter(PayerForm)));

const mapStateToPropsParent = ({
  upsert_payer,
  list_payer,
  delete_payer,
  get_payer,
  medicate_combos,
  get_combo_facility,
  combos,
}) => {
  const { data: payerListData, isFetching } = list_payer;
  const { data: payerinfoData } = get_payer;
  const { data: medicateCombos } = medicate_combos;
  const { data: ComboFacilityData } = get_combo_facility;
  const { isFetching: isUpsertFetching } = upsert_payer;

  let isInserted = false;
  let isRemoved = false;
  let payerList = [];
  let NonPayersList = [];
  let payerinfo = {};
  let PractitionerOffice = [];
  let payerOrg = [];

  if (upsert_payer && upsert_payer.data && upsert_payer.data.success) {
    isInserted = true;
  }
  if (delete_payer && delete_payer.data && delete_payer.data.success) {
    isRemoved = true;
  }
  if (payerListData && payerListData.Payer) {
    payerList = payerListData.Payer || [];
    NonPayersList = payerListData.NonPayer || [];
    // clientData.ClientId = payerInfo.ClientId;
  }
  if (payerinfoData && payerinfoData.Payer) {
    payerinfo = payerinfoData.Payer[0];
  }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  return {
    isInserted,
    isRemoved,
    payerData: payerList,
    isFetching: isFetching,
    payerinfo: payerinfo,
    PayerType: (medicateCombos && medicateCombos.PayerType) || [],
    InvoiceDisplay: (medicateCombos && medicateCombos.InvoiceDisplay) || [],
    practitionerOffice: PractitionerOffice,
    NonPayersList,
    payerOrg: (medicateCombos && medicateCombos.PayerOrg) || [],
    isUpsertFetching,
  };
};
export default connect(mapStateToPropsParent)(withRouter(PayerStep));
const selector = formValueSelector('payerForm');
