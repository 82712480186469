import React, { Component } from 'react';
class CustomImageComponent extends Component {
  constructor(props) {
    super(props);
    const { PANASMoodId, PANASTagId, DateInserted } = props.PANASTag;
    let panasArrray = PANASMoodId ? PANASMoodId.split(',') : [];
    panasArrray = panasArrray.map(item => {
      return parseInt(item);
    });
    let preImages = this.props.images.filter(item => {
      if (panasArrray.includes(item.MoodId)) {
        return item;
      }
    });
    preImages = preImages.map(item => {
      return item.value;
    });
    this.state = {
      selectedImages: [...preImages],
    };
  }
  componentDidMount() {
    for (let i = 0; i < this.state.selectedImages.length; i++) {
      let ele = document.getElementsByClassName('customImageComponent')[0].children[
        this.state.selectedImages[i]
      ];
      if (ele) {
        ele.classList.add('img-selected');
      }
    }
  }
  handleImageClick = (e, item) => {
    const { IsPositive, MoodId, Name, Url, src, value } = item;
    if (this.state.selectedImages.includes(item.value)) {
      let temp = [...this.state.selectedImages];
      let index = this.state.selectedImages.indexOf(item.value);

      if (index > -1) {
        temp.splice(index, 1);
      }
      this.setState({ selectedImages: temp });
      this.props.updateSelectedMoods([...temp]);
      let ele = document.getElementsByClassName('customImageComponent')[0].children[item.value];
      if (ele) {
        ele.classList.remove('img-selected');
      }
    } else {
      this.setState({ selectedImages: [...this.state.selectedImages, item.value] });
      this.props.updateSelectedMoods([...this.state.selectedImages, item.value]);
      let ele = document.getElementsByClassName('customImageComponent')[0].children[item.value];
      if (ele) {
        ele.classList.add('img-selected');
      }
    }
  };
  render() {
    return (
      <div className="customImageComponent">
        {this.props.images.map((item, index) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={e => {
                this.handleImageClick(e, item);
              }}
            >
              <img src={item.src} style={{ width: '80px', height: '80px' }} />
            </span>
          );
        })}
      </div>
    );
  }
}

export default CustomImageComponent;
