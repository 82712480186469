import React, { useState, useEffect } from 'react';
import { ReduxSelect } from '../../../../../../../component/custom-redux-components/index.js';
import _blank from '../../../../../../../images/blank-profile.png';
import { ProfileInOption } from '../../../../../../../Components/profileInOption';
export const AssignCareTeamMember = props => {
  const { onChange, recordData, practitioner, rowIndex } = props;
  return (
    <div class="form-group assign-care-team-option mb-0">
      <ReduxSelect
        options={practitioner}
        formatOptionLabel={careOption => (
          <ProfileInOption OptionData={careOption} isValue={true} valueText="Provider ID" />
        )}
        onChange={value => {
          onChange(value, recordData, rowIndex);
        }}
        className="basic-single"
        // class={`basic-single form-control ${counsellor && 'not-empty'}`}
        classNamePrefix="select"
        value={recordData.PractitionerId}

        //   placeholder="Counselor"
      />
    </div>
  );
};
