import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Drawer, Checkbox, Input, Modal, Button } from 'antd';
import ServiceCode from './changeRequest/serviceCode';
import TargetPrice from './changeRequest/targetPrice';
import {
  payerPortalRequestGet,
  payerPortalRequestUpsert,
  resetPayerPortalRequestUpsert,
} from '../../../../../../store/actions';
import ConfirmationPopup from '../../../../../../Components/ConfirmationPopup';
import { Loader2 } from '../../../../../../Components/Loader';
const { TextArea } = Input;

const ContractDetails = ({ clientId, smartContractId, onClose, getLatestData, setFieldDisabled }) => {
  const [noteValue, setNoteValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (smartContractId) {
      getRequestData();
    }
  }, []);
  const { RequestGet, isReqSuccess, isFetching } = useSelector(
    ({
      payer_portal_request_get: { data: requestGetData, isFetching, error },
      payer_portal_request_upsert: { data: requestUpsert },
    }) => {
      return {
        RequestGet: requestGetData && requestGetData.Request,
        isReqSuccess: requestUpsert && requestUpsert.success,
        isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    if (smartContractId && isReqSuccess) {
      dispatch(resetPayerPortalRequestUpsert());
      getRequestData();
      getLatestData();
      onClose();
    }
  }, [isReqSuccess]);

  const getRequestData = () => {
    dispatch(
      payerPortalRequestGet({
        Json: JSON.stringify({
          Request: [
            {
              SmartContractId: smartContractId,
              Id: null,
              IsContractRequest: true,
              IsTermsRequest: false,
            },
          ],
        }),
      }),
    );
  };
  const onDeny = record => {
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30311}]} Approve
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30312}]} decline
    dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: record.PayerPortalRequestId,
              StatusId: 30312,
            },
          ],
        }),
      }),
    );
  };
  const onGoTo = (record, statusId) => {
    dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: record.PayerPortalRequestId,
              StatusId: statusId,
              ChangeNotes: noteValue,
            },
          ],
        }),
      }),
    );
    // onClose();
  };

  const addNoteChange = e => {
    setNoteValue(e.target.value);
  };
  return (
    <div className="contract-details">
      <Loader2 loading={isFetching} />
      {RequestGet &&
        RequestGet.map((item, index) => {
          const { RequestType, StatusId, StatusUpdatedOn } = item;
          debugger;
          return (
            <div className="sections">
              <div className="section-item">
                {RequestType == 30201 && (
                  <>
                    <ServiceCode RequestItem={item} index={index} />

                    {StatusUpdatedOn == null && (
                      <>
                        {StatusId == 30330 && (
                          <div className="">
                            <span className="label">
                              Please mention the changes done by you in service code.
                            </span>
                            <span className="des-value">
                              {' '}
                              <TextArea
                                rows={5}
                                value={noteValue}
                                onChange={e => {
                                  addNoteChange(e);
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {RequestType == 30200 && (
                  <>
                    <TargetPrice RequestItem={item} index={index} />{' '}
                    {StatusUpdatedOn == null && (
                      <>
                        {StatusId == 30330 && (
                          <div className="">
                            <span className="label">
                              Please mention the changes done by you in target price.
                            </span>
                            <span className="des-value">
                              {' '}
                              <TextArea
                                rows={5}
                                value={noteValue}
                                onChange={e => {
                                  addNoteChange(e);
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="sections-action">
                {StatusId ? (
                  <div>
                    {StatusId == 30312 && <span className="denied">Request Denied</span>}
                    {StatusId == 30311 && (
                      <span className="approved">{StatusUpdatedOn && StatusUpdatedOn}</span>
                    )}
                    {StatusId == 30330 && (
                      <div>
                        <button
                          type="button"
                          className="btn-approved px-4 py-2"
                          onClick={() => {
                            onGoTo(item, 30311);
                            setFieldDisabled(true);
                          }}
                        >
                          Changes Done
                        </button>
                        <ConfirmationPopup
                          className={'btn-deny px-4 py-2 mr-0'}
                          yesAction={() => {
                            onDeny(item);
                          }}
                          buttonText="Deny"
                          title="Are you sure you want to deny this request."
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary px-4 py-2"
                      onClick={() => {
                        onGoTo(item, 30330);
                        setFieldDisabled(false);
                        // onClose();
                      }}
                    >
                      Go to Contract
                    </button>
                    <ConfirmationPopup
                      className={'btn-deny px-4 py-2 mr-0'}
                      yesAction={() => {
                        onDeny(item);
                      }}
                      buttonText="Deny"
                      title="Are you sure you want to deny this request."
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}

      {/* <div className="sections">
        <div className="section-item">
          <TargetPrice RequestGet={RequestGet} />
        </div>
        <div className="sections-action">
          <button type="button" className="btn btn-outline-primary px-4 py-2">
            Go to Contract
          </button>
          <button type="button" className="btn-deny px-4 py-2 mr-0">
            Deny
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default ContractDetails;
