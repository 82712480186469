import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import { FormGroup, Input } from 'reactstrap';
import { Switch, Checkbox, Radio } from 'antd';
import TextareaAutosize from 'react-autosize-textarea';
import AntdModal from '../../../../Components/CustomModal';
import QuestionConfigAction from '../questionConfigAction';
class Binary extends React.Component {
  constructor(props) {
    super(props);

    let { Response, Note, IsMandatory, AddNote } = props.survey;
    this.state = {
      value: Response || null,
      radioValue: null,
      mandateChecked: IsMandatory,
      noteCheck: AddNote,
    };
  }

  onChecked = (response, key) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      setToggle,
    } = this.props;
    if (key == 'IsMandatory') {
      this.setState({
        mandateChecked: response,
      });
    } else if (key == 'AddNote') {
      this.setState({
        noteCheck: response,
      });
    }
    setToggle.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: response,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      // OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: key,
    })();
  };

  onChangeRadio = (val, OIndex) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      isAdmin,
    } = this.props;
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: !val,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: `Option${OIndex}QF`,
    })();
  };

  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      SurveyQuestionList,
      activeKey,
      onQuestionStateChange,
      isAdmin,
    } = this.props;
    let radioValue;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option1QF,
      Option2Value,
      Option2Text,
      Option2QF,
      Option3Value,
      Option3Text,
      Option3QF,
      Option4Value,
      Option4Text,
      Option4QF,
      Option5Value,
      Option5Text,
      Option5QF,
      Option6Value,
      Option6Text,
      Option6QF,
      Option7Value,
      Option7Text,
      Option7QF,
      Option8Value,
      Option8Text,
      Option8QF,
      Option9Value,
      Option9Text,
      Option9QF,
      Option10Value,
      Option10Text,
      Option10QF,
      Response,
      Note,
      SurveyQuestionId,
      SectionId,
      DisplayOrder,
      ResponseThreshold,
      ThresholdSign,
      IsArchived,
      SurveyQuestionCategoryConfigId,
    } = survey;
    const textArray = [
        Option1Text,
        Option2Text,
        Option3Text,
        Option4Text,
        Option5Text,
        Option6Text,
        Option7Text,
        Option8Text,
        Option9Text,
        Option10Text,
      ],
      valueArray = [
        Option1Value,
        Option2Value,
        Option3Value,
        Option4Value,
        Option5Value,
        Option6Value,
        Option7Value,
        Option8Value,
        Option9Value,
        Option10Value,
      ],
      flagArray = [
        Option1QF,
        Option2QF,
        Option3QF,
        Option4QF,
        Option5QF,
        Option6QF,
        Option7QF,
        Option8QF,
        Option9QF,
        Option10QF,
      ];
    // const RArray = ['Mark it as ‘Qualifier’', 'Mark it as ‘Flagged’'];
    const RArray = ['Mark it as ‘Flagged’'];
    const VArray = [true, false];
    // let responseValue = this.state.value ? parseInt(this.state.value) - 1 : null;
    // let responseValue = this.state.value ? valueArray.indexOf(this.state.value) : null;
    return (
      <div className="type-border col-12 toggle-radio">
        <div className="d-flex justify-content-between align-items-start pb-2">
          <div className="">
            {Question && Question.length > 0 && (
              <h5 className="survey-question rcm-question text-left mb-2">
                {!IsArchived && `${sequenceNum + 1}.`} {renderHTML(Question)}
              </h5>
            )}

            <ul
              className="mb-0"
              style={{ listStyle: 'none', marginTop: '1em', paddingLeft: '0px' }}
            >
              {textArray.map((option, OIndex) => {
                return option ? (
                  <li>
                    <div className="d-flex align-items-center">
                      <span className="mr-3" style={{ minWidth: '100px' }}>
                        Option{OIndex + 1}: <strong>{option}</strong>
                      </span>
                      <div>
                        <div className="row">
                          <div className="col-auto text-left">
                            <Checkbox
                              checked={flagArray[OIndex] === false}
                              onChange={event => {
                                let val = event.target.checked;
                                this.onChangeRadio(val, OIndex + 1);
                              }}
                              disabled={IsArchived}
                              style={{
                                padding: '5px 5px 5px 0px',
                                alignItems: 'center',
                                display: 'flex',
                              }}
                              className="mb-0"
                            >
                              'Mark it as ‘Flagged’
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : null;
              })}
            </ul>
            {/* )} */}
            {!isAdmin && (
              <div className="d-flex mt-4">
                <label class="custom-control-label mr-2" for="is-mandatory">
                  <Switch
                    name="isQuestionMandatory"
                    // checked={value}
                    // onChange={onChange}
                    unCheckedChildren="No"
                    checkedChildren="Yes"
                    size="small"
                    disabled={IsArchived}
                    checked={this.state.mandateChecked}
                    onChange={e => this.onChecked(e, 'IsMandatory')}
                  />
                  Mark as mandatory
                </label>
                <label class="custom-control-label" for="isAddNote">
                  <Switch
                    name="isAddNote"
                    // checked={value}
                    // onChange={onChange}
                    unCheckedChildren="No"
                    checkedChildren="Yes"
                    size="small"
                    disabled={IsArchived}
                    checked={this.state.noteCheck}
                    onChange={e => this.onChecked(e, 'AddNote')}
                  />
                  Enable Add Note
                </label>
              </div>
            )}
          </div>
          <div>
            <QuestionConfigAction {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
export default Binary;
