import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getSurveys(action) {
  try {
    const { payload } = action;
    const response = yield API.getSurveys(payload);
    yield put({
      type: ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_FAILURE,
    });
  }
}

export function* getSurveyQuestionMultiple(action) {
  try {
    const { payload } = action;
    const response = yield API.getSurveyQuestionMultiple(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_FAILURE,
    });
  }
}

export function* surveyAnswerUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAnswerUpsert(payload);
    // Testing this code to load the survey again with the latest data as soon as you anser the question.
    // if (response && response.data && response.data.result && response.data.result.SurveyId) {
    //   let data = {
    //     json: JSON.stringify({
    //       Survey: [
    //         {
    //           SurveyId: response.data.result.SurveyId,
    //         },
    //       ],
    //     }),
    //   };
    //   yield API.listSurveyQuestionAssessment(data);
    // }
    yield put({
      type: ActionTypes.SURVEY_ANSWER_UPSERT_SUCCESS,
      payload: {
        data: response.data,
        SurveyQuestionId: payload.SurveyQuestionId,
        SurveyQuestionIndex: payload.SurveyQuestionIndex,
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ANSWER_UPSERT_FAILURE,
    });
  }
}

export function* surveyAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAssign(payload);
    yield put({
      type: ActionTypes.SURVEY_ASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ASSIGN_FAILURE,
    });
  }
}

export function* surveyGetToAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyGetToAssign(payload);
    yield put({
      type: ActionTypes.SURVEY_GET_TO_ASSIGN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_GET_TO_ASSIGN_FAILURE,
    });
  }
}

export function* surveyTaskMarkInComplete(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyTaskMarkInComplete(payload);
    yield put({
      type: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_FAILURE,
    });
  }
}

export function* getStudentSurveyDashboardData(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentSurveyDashboardData(payload);
    yield put({
      type: ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_FAILURE,
    });
  }
}

export function* surveyAnswersGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyAnswersGet(payload);
    yield put({
      type: ActionTypes.SURVEY_ANSWERS_GET_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_ANSWERS_GET_FAILURE,
    });
  }
}

export function* getSurveyCategoryQuestion(action) {
  try {
    const { payload } = action;
    const response = yield API.getSurveyCategoryQuestion(payload);
    yield put({
      type: ActionTypes.GET_SURVEY_CATEGORY_QUESTIONS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SURVEY_CATEGORY_QUESTIONS_FAILURE,
    });
  }
}

export function* preIntakeSurveyAnswersGet(action) {
  try {
    const { payload } = action;
    const response = yield API.preIntakeSurveyAnswersGet(payload);
    yield put({
      type: ActionTypes.PRE_INTAKE_SURVEY_ANSWERS_GET_SUCCESS,
      payload: JSON.parse(response.data.data).PreIntake[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRE_INTAKE_SURVEY_ANSWERS_GET_FAILURE,
    });
  }
}

export function* preIntakeHistoryGet(action) {
  try {
    const { payload } = action;
    const response = yield API.preIntakeHistoryGet(payload);
    yield put({
      type: ActionTypes.PRE_INTAKE_HISTORY_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRE_INTAKE_HISTORY_GET_FAILURE,
    });
  }
}

export function* surveyLatestAnswerDateUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyLatestAnswerDateUpdate(payload);
    yield put({
      type: ActionTypes.SURVEY_LATEST_ANSWER_DATE_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_LATEST_ANSWER_DATE_UPDATE_FAILURE,
    });
  }
}
export function* studentSurveyListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentSurveyListGet(payload);
    yield put({
      type: ActionTypes.STUDENT_SURVEY_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_SURVEY_LIST_GET_FAILURE,
    });
  }
}

export function* augmentWithAI(action) {
  try {
    const { payload } = action;
    const response = yield API.augmentWithAI(payload);
    yield put({
      type: ActionTypes.AUGMENT_WITH_AI_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.AUGMENT_WITH_AI_FAILURE,
    });
  }
}
export function* augmentWithAIGet(action) {
  try {
    const { payload } = action;
    const response = yield API.augmentWithAIGet(payload);
    yield put({
      type: ActionTypes.AUGMENT_WITH_AI_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.AUGMENT_WITH_AI_GET_FAILURE,
    });
  }
}
export function* surveyQuestionLibraryGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionLibraryGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_LIBRARY_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_LIBRARY_GET_FAILURE,
    });
  }
}
export function* surveyQuestionConfigGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionConfigGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_CONFIG_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_CONFIG_GET_FAILURE,
    });
  }
}
export function* surveyQuestionSectionUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionSectionUpsert(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_SECTION_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_SECTION_UPSERT_FAILURE,
    });
  }
}
export function* surveyQuestionSectionComboGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionSectionComboGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_SECTION_COMBO_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_SECTION_COMBO_GET_FAILURE,
    });
  }
}
export function* surveyQuestionConfigUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionConfigUpsert(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_CONFIG_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_CONFIG_UPSERT_FAILURE,
    });
  }
}

export function* surveyQuestionCategoryConfigUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionCategoryConfigUpsert(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_CATEGORY_CONFIG_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_CATEGORY_CONFIG_UPSERT_FAILURE,
    });
  }
}
export function* surveyQuestionConfigBannerGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionConfigBannerGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_CONFIG_BANNER_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_CONFIG_BANNER_GET_FAILURE,
    });
  }
}
export function* clientScreeningStatusUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.clientScreeningStatusUpdate(payload);
    yield put({
      type: ActionTypes.CLIENT_SCREENING_STATUS_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_SCREENING_STATUS_UPDATE_FAILURE,
    });
  }
}

export function* studentAssociateInsert(action) {
  try {
    const { payload } = action;
    const response = yield API.studentAssociateInsert(payload);
    yield put({
      type: ActionTypes.STUDENT_ASSOCIATE_INSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_ASSOCIATE_INSERT_FAILURE,
    });
  }
}

export function* studentAssociateListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentAssociateListGet(payload);
    yield put({
      type: ActionTypes.STUDENT_ASSOCIATE_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_ASSOCIATE_LIST_GET_FAILURE,
    });
  }
}

export function* studentAssociateDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.studentAssociateDelete(payload);
    yield put({
      type: ActionTypes.STUDENT_ASSOCIATE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_ASSOCIATE_DELETE_FAILURE,
    });
  }
}

export function* prospectArchive(action) {
  try {
    const { payload } = action;
    const response = yield API.prospectArchive(payload);
    yield put({
      type: ActionTypes.PROSPECT_ARCHIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PROSPECT_ARCHIVE_FAILURE,
    });
  }
}

export function* dashboardPendingSurveyListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.dashboardPendingSurveyListGet(payload);
    yield put({
      type: ActionTypes.DASHBOARD_PENDING_SURVEY_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_PENDING_SURVEY_LIST_GET_FAILURE,
    });
  }
}

export function* surveyQuestionLibraryQuestionListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionLibraryQuestionListGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data).SurveyQuestion[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_LIST_GET_FAILURE,
    });
  }
}

export function* surveyQuestionLibraryQuestionDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionLibraryQuestionDelete(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_DELETE_FAILURE,
    });
  }
}

export function* surveyQuestionLibrarySectionUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionLibrarySectionUpdate(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_LIBRARY_SECTION_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_LIBRARY_SECTION_UPDATE_FAILURE,
    });
  }
}

export function* surveyQuestionLibraryQuestionUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionLibraryQuestionUpsert(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_UPSERT_FAILURE,
    });
  }
}

export function* studentNoteGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.studentNoteGetAll(payload);
    yield put({
      type: ActionTypes.STUDENT_NOTE_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_NOTE_GET_ALL_FAILURE,
    });
  }
}

export function* surveyQuestionLibraryQuestionGet(action) {
  try {
    const { payload } = action;
    const response = yield API.surveyQuestionLibraryQuestionGet(payload);
    yield put({
      type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_GET_SUCCESS,
      payload:
        JSON.parse(response.data.data) &&
        JSON.parse(response.data.data).SurveyQuestionLibrary &&
        JSON.parse(response.data.data).SurveyQuestionLibrary[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield takeLatest(ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_REQUEST, getSurveys);
  yield takeLatest(ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_REQUEST, getSurveyQuestionMultiple);
  yield takeLatest(ActionTypes.SURVEY_ANSWER_UPSERT_REQUEST, surveyAnswerUpsert);
  yield takeLatest(ActionTypes.SURVEY_ASSIGN_REQUEST, surveyAssign);
  yield takeLatest(ActionTypes.SURVEY_GET_TO_ASSIGN_REQUEST, surveyGetToAssign);
  yield takeLatest(ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_REQUEST, surveyTaskMarkInComplete);
  yield takeLatest(
    ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_REQUEST,
    getStudentSurveyDashboardData,
  );
  yield takeLatest(ActionTypes.SURVEY_ANSWERS_GET_REQUEST, surveyAnswersGet);
  yield takeLatest(ActionTypes.GET_SURVEY_CATEGORY_QUESTIONS_REQUEST, getSurveyCategoryQuestion);
  yield takeLatest(ActionTypes.PRE_INTAKE_SURVEY_ANSWERS_GET_REQUEST, preIntakeSurveyAnswersGet);
  yield takeLatest(ActionTypes.PRE_INTAKE_HISTORY_GET_REQUEST, preIntakeHistoryGet);
  yield takeLatest(
    ActionTypes.SURVEY_LATEST_ANSWER_DATE_UPDATE_REQUEST,
    surveyLatestAnswerDateUpdate,
  );
  yield takeLatest(ActionTypes.STUDENT_SURVEY_LIST_GET_REQUEST, studentSurveyListGet);
  yield takeLatest(ActionTypes.AUGMENT_WITH_AI_REQUEST, augmentWithAI);
  yield takeLatest(ActionTypes.AUGMENT_WITH_AI_GET_REQUEST, augmentWithAIGet);
  yield takeLatest(ActionTypes.SURVEY_QUESTION_LIBRARY_GET_REQUEST, surveyQuestionLibraryGet);
  yield takeLatest(ActionTypes.SURVEY_QUESTION_CONFIG_GET_REQUEST, surveyQuestionConfigGet);
  yield takeLatest(ActionTypes.SURVEY_QUESTION_SECTION_UPSERT_REQUEST, surveyQuestionSectionUpsert);
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_SECTION_COMBO_GET_REQUEST,
    surveyQuestionSectionComboGet,
  );
  yield takeLatest(ActionTypes.SURVEY_QUESTION_CONFIG_UPSERT_REQUEST, surveyQuestionConfigUpsert);
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_CATEGORY_CONFIG_UPSERT_REQUEST,
    surveyQuestionCategoryConfigUpsert,
  );
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_CONFIG_BANNER_GET_REQUEST,
    surveyQuestionConfigBannerGet,
  );
  yield takeLatest(ActionTypes.CLIENT_SCREENING_STATUS_UPDATE_REQUEST, clientScreeningStatusUpdate);
  yield takeLatest(ActionTypes.STUDENT_ASSOCIATE_INSERT_REQUEST, studentAssociateInsert);
  yield takeLatest(ActionTypes.STUDENT_ASSOCIATE_LIST_GET_REQUEST, studentAssociateListGet);
  yield takeLatest(ActionTypes.STUDENT_ASSOCIATE_DELETE_REQUEST, studentAssociateDelete);
  yield takeLatest(ActionTypes.PROSPECT_ARCHIVE_REQUEST, prospectArchive);
  yield takeLatest(
    ActionTypes.DASHBOARD_PENDING_SURVEY_LIST_GET_REQUEST,
    dashboardPendingSurveyListGet,
  );
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_LIST_GET_REQUEST,
    surveyQuestionLibraryQuestionListGet,
  );
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_DELETE_REQUEST,
    surveyQuestionLibraryQuestionDelete,
  );
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_LIBRARY_SECTION_UPDATE_REQUEST,
    surveyQuestionLibrarySectionUpdate,
  );
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_UPSERT_REQUEST,
    surveyQuestionLibraryQuestionUpsert,
  );
  yield takeLatest(ActionTypes.STUDENT_NOTE_GET_ALL_REQUEST, studentNoteGetAll);
  yield takeLatest(
    ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_GET_REQUEST,
    surveyQuestionLibraryQuestionGet,
  );
}
