import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListRenderer from '../Components/listRenderer';
import { updateStaffPassword, resetStaffPassword, listCombos } from '../store/actions';
import { ReduxSelect, ReduxInput } from '../component/custom-redux-components';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import CustomModal from '../core/modal';
import Loader from '../Components/Loader';
import Webcam from 'react-webcam';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  confirmEmail: (value, values) => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        if (value != values.email) {
          return 'Email and Confirm Email must be same';
        }
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  password: value => {
    // if (value) {
    //   if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/i.test(value)) {
    //     return 'Invalid password';
    //   } else {
    //     return undefined;
    //   }
    // } else {
    //   return 'Field Required';
    // }
    if (value) {
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/.test(value)) {
        return 'Please ensure your password has minimum 8 characters which includes a Capital Letter,Small Letter,Special Symbol & a Number.Example : Hello@123 ';
      } else {
        return undefined;
      }
    } else {
      return 'Required';
    }
  },
  confirmPassword: (value, values) => {
    if (value) {
      if (value != values.newPassword) {
        return 'Password and Confirm Password must be same';
      }
      return undefined;
    } else {
      return 'Required';
    }
  },
  pinLength: value => {
    let validPasswordLengthArray = [6, 7, 8, 9, 10];
    if (validPasswordLengthArray.includes(value.length)) {
    } else {
      return 'should be of 6-10 digits';
    }
  },
  confirmPin: (value, values) => {
    if (value) {
      if (value != values.pin) {
        return 'and Pin must be same';
      }
      return undefined;
    } else {
      return 'Required';
    }
  },
  allowNumeric: value => (value && !/^[0-9]+$/.test(value) ? 'should be numeric only' : undefined),
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

let ChangePasswordForm = ({ onSubmit, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '36px 20px 150px 20px' }}>
      <div class="enrollment-form setting-gap">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="oldPassword"
                type="password"
                component={ReduxInput}
                validate={Validator.required}
                class="form-control"
                fieldName="Old Password"
              />
              <label class="placeholder-label">Old Password*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="newPassword"
                type="password"
                component={ReduxInput}
                validate={Validator.password}
                class="form-control"
                fieldName="New Password"
              />
              <label class="placeholder-label">New Password*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name="confirmPassword"
                type="password"
                component={ReduxInput}
                validate={Validator.confirmPassword}
                class="form-control"
                fieldName="Confirm Password"
              />
              <label class="placeholder-label">Confirm Password*</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mt-2">
            <input type="submit" value="Save" class="btn btn-sm stn-btn" />
          </div>
        </div>
      </div>
    </form>
  );
};

const ReduxChangePasswordForm = reduxForm({
  form: 'ReduxChangePasswordForm',
})(ChangePasswordForm);

class ChangeProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startCamera: true,
      level: 2,
      imageSrc: null,
    };
  }
  setRef = webcam => {
    this.webcam = webcam;
  };

  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('surveyId');
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }

  getIgnoreUpdatingPin() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('ignoreUpdatingPin') == 'true' ? true : false;
  }

  process = () => {
    if (this.state.level == 1) {
      this.setState({ startCamera: true, level: 2 });
    } else if (this.state.level == 2) {
      const imageSrc = this.webcam.getScreenshot();
      //this.props.dispatch(uploadStaffImage({ image: imageSrc }));
      const me = this;
      Axios.post('Adminstration/UploadStaffImage', {
        image: imageSrc,
      })
        .then(response => {
          localStorage.setItem('profileImgUrl', response.data.result);
          me.setState({ startCamera: false, level: 3, imageSrc: imageSrc });
        })
        .catch(error => {
          console.log('Image Failed');
        });
    } else if (this.state.level == 3) {
    }
  };

  // onUserMediaError = err => {
  //   var searchStr = 'surveyId=' + this.getSurveyId() + '&studentId=' + this.getStudentId();
  //   const errorMsg = err.name == 'NotFoundError' ? 'not available' : 'not enabled';
  //   let me = this;
  //   Modal.error({
  //     title: 'Auto Redirecting Page to Validate Pin as Camera is ' + errorMsg,
  //     onOk() {
  //       // if (me.getIgnoreUpdatingPin()) {
  //       //   me.props.history.push({
  //       //     pathname: 'validate',
  //       //     search: searchStr,
  //       //   });
  //       // } else {
  //       //   me.props.history.push({
  //       //     pathname: 'pin',
  //       //     search: searchStr,
  //       //   });
  //       // }
  //     },
  //   });
  // };

  render() {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user',
    };

    var text = '';
    if (this.state.level == 1) {
      text = 'Start Camera';
    } else if (this.state.level == 2) {
      text = 'Capture';
      if (this.props.location && this.props.location.param && this.props.location.param.message) {
        alert(this.props.location.param.message);
      }
    } else if (this.state.level == 3) {
      text = 'Saved';
    }

    return (
      <div class="main complete-selfie-page  mb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-center">
              <div class="complete-selfie-box">
                <h3>Let’s take a selfie.</h3>
                {this.state.startCamera && (
                  <Webcam
                    audio={false}
                    onUserMediaError={this.onUserMediaError}
                    ref={this.setRef}
                    screenshotFormat="image/jpeg"
                    className="selfie-img"
                    videoConstraints={videoConstraints}
                  />
                )}
                {this.state.level == 3 && (
                  <figure class="selfie-img">
                    <img src={this.state.imageSrc} class="img-background" />
                  </figure>
                )}
                <input type="button" value={text} onClick={this.process} class="btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ReduxChangeProfilePicture = reduxForm({
  form: 'ReduxChangeDefaultCenterForm',
})(withRouter(ChangeProfilePicture));

const activeKey = 0;

class PayerSettings extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(reset('ReduxChangeDefaultCenterForm'));
    this.state = {
      emailModelOpen: false,
      passwordModelOpen: false,
      centerModelOpen: false,
      upsertPinModelOpen: false,
    };
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(listCombos({ comboTypes: 'Center' }));
  }

  componentWillReceiveProps({ isPasswordUpdated }) {
    const { dispatch } = this.props;
    if (isPasswordUpdated) {
      this.setState({ passwordModelOpen: true });
      dispatch(resetStaffPassword());
    }
  }

  toggle = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };

  onPasswordChange = values => {
    this.props.dispatch(
      updateStaffPassword({
        oldPassWord: values.oldPassword,
        newPassword: values.newPassword,
      }),
    );
  };

  onPictureChange = values => {};

  render() {
    const { isFetching } = this.props;

    const list = [
      {
        Name: 'Password',
        ExtraComponent: () => <ReduxChangePasswordForm onSubmit={this.onPasswordChange} />,
      },
      {
        Name: 'Profile Picture',
        ExtraComponent: () => <ReduxChangeProfilePicture onSubmit={this.onPictureChange} />,
      },
    ];

    return (
      <>
        <Loader loading={isFetching} />
        <div class="main   mb-5">
          <div class="container-fluid">
            <div class="row top-heading-row mt-5 mb-3 align-items-center">
              <div class="col-12 col-md-4 col-lg-3">
                <h3 class="page-title mb-3 mb-md-0">Settings</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-5">
                <div class="vertical-tabs enrollment-form">
                  <ListRenderer list={list} activeKey={activeKey} tabPosition="left" />
                </div>
              </div>
            </div>
          </div>

          <CustomModal
            isOpen={this.state.passwordModelOpen}
            header={'Your Password has been updated successfully'}
            yesAction={() => {
              this.toggle('passwordModelOpen');
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ combos, update_staff_password }) => {
  var isFetching = false;
  var isPasswordUpdated = false;

  if (update_staff_password && update_staff_password.isFetching) {
    if (!isFetching) {
      isFetching = update_staff_password.isFetching;
    }
  } else if (update_staff_password.data && update_staff_password.data.success) {
    isPasswordUpdated = true;
  }

  return {
    isFetching,
    isPasswordUpdated,
  };
};

export default connect(mapStateToProps)(PayerSettings);
