import React, { Component } from 'react';
import PageTitle from '../Components/PageTitle';
import { connect } from 'react-redux';
import { getInvitedList, inviteResend } from '../store/actions';
import { withRouter } from 'react-router-dom';
import PersistTableSort from '../component/persistTableSort';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import CustomModal from '../core/modal';
import CustomModal2 from '../Components/CustomModal';
// import { Modal, Button, Menu } from 'antd';
import moment from 'moment';
import { ReduxSelect } from '../component/custom-redux-components';
import { Checkbox, Radio, DatePicker, Modal, Drawer, Button } from 'antd';
import { ReduxInviteNoteModal } from './customScreens/inviteNote';
import SendAppInviteReduxModal from './customScreens/sendMobileAppInvitation';
import ReduxBroadcaseInvitationModal from './customScreens/broadcastInvitation';
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MMM-YYYY';

class AppInvitedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // StartDate: moment(new Date()).format('DD/MMM/YYYY'),
      // EndDate: moment(new Date()).format('DD/MMM/YYYY'),
      StartDate: null,
      EndDate: null,
      inviteFormModal: false,
      isModelOpen: false,
      tempColumns: [
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'Name',
          sorter: (a, b) => isComparer(a.Name, b.Name),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Phone',
          dataIndex: 'Phone',
          key: 'Phone',
          // sorter: (a, b) => isComparer(a.Name, b.Name),
          // sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'Email',
          sorter: (a, b) => isComparer(a.Email, b.Email),
          // sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Date Invited',
          dataIndex: 'DateInvited',
          key: 'DateInvited',
          sorter: (a, b) => isDateComparer(a.DateInvitedSortOrder, b.DateInvitedSortOrder),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Invited By',
          dataIndex: 'InvitedBy',
          key: 'InvitedBy',
          sorter: (a, b) => isComparer(a.InvitedBy, b.InvitedBy),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Referral Code',
          dataIndex: 'ReferralCode',
          key: 'ReferralCode',
          // sorter: (a, b) => isComparer(a.Name, b.Name),
          // sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Date Link Clicked',
          dataIndex: 'DateLinkClicked',
          key: 'DateLinkClicked',
          sorter: (a, b) => isDateComparer(a.DateLinkClickedSortOrder, b.DateLinkClickedSortOrder),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Date Joined',
          dataIndex: 'DateJoined',
          key: 'DateJoined',
          sorter: (a, b) => isDateComparer(a.DateJoinedSortOrder, b.DateJoinedSortOrder),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Date Last Active',
          dataIndex: 'DateLastActive',
          key: 'DateLastActive',
          sorter: (a, b) => isDateComparer(a.DateLastActiveSortOrder, b.DateLastActiveSortOrder),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Notification Last Delivered Date',
          dataIndex: 'NotifLastDeliveredDate',
          key: 'NotifLastDeliveredDate',
          sorter: (a, b) =>
            isDateComparer(a.NotifLastDeliveredDateSortOrder, b.NotifLastDeliveredDateSortOrder),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Note',
          key: 'x',
          hidden: true,
          render: (text, record, index) => (
            <div class="tools-grid">
              <div className="mr-4">
                <span className="start-btn">
                  <CustomModal2
                    ModalButton={({ onClick }) => (
                      <div className="modal-section text-center mb-4">
                        <span className="btn-link" style={{ cursor: 'pointer' }} onClick={onClick}>
                          Add New Note
                        </span>
                      </div>
                    )}
                    ModalContent={props => (
                      <ReduxInviteNoteModal
                        title={'Add Note'}
                        // isPreDefinedTask={true}
                        // setRequestData={this.setRequestData}
                        {...props}
                      />
                      // <ReduxTasksModalContent
                      //   onAssignExistingTask={this.onAssignExistingTask}
                      //   {...props}
                      // />
                    )}
                  />
                </span>
              </div>
            </div>
          ),
        },

        {
          title: 'Resend Invitation',
          key: 'x',
          render: (text, record, index) => (
            <div class="tools-grid">
              <div className="mr-4">
                <span className="start-btn">
                  <button
                    className="btn btn-primary"
                    onClick={event => {
                      let json = {
                        Invite: [
                          {
                            ContactReferGuid: record.ContactReferGuid,
                          },
                        ],
                      };
                      var data = {
                        json: JSON.stringify(json),
                      };

                      this.props.dispatch(inviteResend(data));
                    }}
                  >
                    Reinvite
                  </button>
                </span>
              </div>
            </div>
          ),
        },
      ],
    };
  }

  handleChange = (key, date) => {
    let data = {
      InvitedList: [
        {
          FilterBy: this.state.inviteFilter || null,
          FromDate: moment(date[0]).format('MM/DD/YYYY'),
          ToDate: moment(date[1]).format('MM/DD/YYYY'),
          Search: this.state.searchStr || null,
        },
      ],
    };

    this.props.dispatch(
      getInvitedList({
        Json: JSON.stringify(data),
      }),
    );
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });
  };
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });

    let data = {
      InvitedList: [
        {
          FilterBy: this.state.inviteFilter || null,
          FromDate: moment(this.state.StartDate).format('MM/DD/YYYY') || null,
          ToDate: moment(this.state.EndDate).format('MM/DD/YYYY') || null,
          Search: value,
        },
      ],
    };

    this.props.dispatch(
      getInvitedList({
        Json: JSON.stringify(data),
      }),
    );
  };
  onFilterChange = value => {
    this.setState({
      inviteFilter: value,
    });
    let data = {
      InvitedList: [
        {
          FilterBy: value,
          // FromDate: moment(this.state.StartDate).format('MM/DD/YYYY') || null,
          // ToDate: moment(this.state.EndDate).format('MM/DD/YYYY') || null,
          Search: this.state.searchStr || null,
        },
      ],
    };

    this.props.dispatch(
      getInvitedList({
        Json: JSON.stringify(data),
      }),
    );
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentDidMount() {
    this.props.dispatch(getInvitedList({}));
    window.scrollTo(0, 0);
  }
  componentWillReceiveProps({ inviteSuccess, inviteMsg }) {
    if (inviteSuccess && inviteSuccess != this.props.inviteSuccess) {
      this.toggle(inviteMsg);
    }
  }
  render() {
    const { StartDate, EndDate, FilterBy, eventTriggerModal } = this.state;
    const { inviteSuccess, inviteMsg } = this.props;
    return (
      <div class="container-fluid">
        <PageTitle TitleText="App Invites" />
        <div class="row top-table-search mt-5 mb-4 align-items-center">
          <div class="col-12 col-md-4 col-lg-4 ml-4">
            <a class="back-link" onClick={() => this.props.history.goBack()}>
              <img src="images/back-arrow.svg" /> Back
            </a>
            {/* <h3 class="page-title mb-3 mb-md-0">Promo Code</h3> */}
            {/* <Link to="/report" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link> */}
          </div>
        </div>

        <div class="content-area">
          <div className="row">
            <div class="col-12 mb-5">
              <div className="col-12">
                <div className="row">
                  <div class="col-3 d-flex align-items-center  text-center">
                    {' '}
                    <h5 class="pl-2 pb-2">App Invitation List</h5>
                  </div>{' '}
                </div>
                <div className="row">
                  <div class="col-4 d-flex align-items-center text-center">
                    <RangePicker
                      // style={{}}
                      // value={[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]}
                      // defaultValue={[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]}
                      onChange={this.handleChange}
                      className="range-picker w-75 text-left"
                      format={['MM-DD-YYYY', 'MM/DD/YYYY']}
                      bordered={false}
                      showToday
                      // separator="to"
                      allowClear={false}
                      suffixIcon={<i class="far fa-calendar-alt form-control-icon" />}
                    />
                  </div>
                  <div class="col-4 d-flex align-items-center justify-content-center text-center">
                    {' '}
                    <div className="search-box d-inline-flex w-100 ">
                      <div className="form-group has-search w-100 d-flex align-items-center mb-0">
                        <input
                          type="text"
                          class="form-control 
                   shadow-sm rcm-document-search"
                          placeholder="Search"
                          id="filterbox"
                          onChange={this.onSearchChange}
                          style={{ minHeight: '46px' }}
                        />
                        <button class="btn search-icon " type="button">
                          {' '}
                          <i class="fa fa-search" />{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 d-flex align-items-center justify-content-center text-center">
                    {' '}
                    <div class="form-group mb-0 w-100 app-subscriber-page">
                      <ReduxSelect
                        options={this.props.filterDropDown}
                        onChange={this.onFilterChange}
                        className="basic-single"
                        classNamePrefix="select"
                        value={this.state.inviteFilter}
                        type="text"
                        placeholder="Filter By"
                      />
                    </div>
                  </div>
                  <div class="col-2 d-flex align-items-center justify-content-center text-center flex-column">
                    <a
                      style={{ marginBottom: '10px' }}
                      onClick={() => {
                        this.setState({
                          inviteFormModal: true,
                        });
                      }}
                    >
                      <span style={{ color: '#46b4fe', marginRight: '15px', fontWeight: '600' }}>
                        Send Invitation
                      </span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({
                          reduxBroadcaseInvitationModal: true,
                        });
                      }}
                    >
                      <span style={{ color: '#46b4fe', marginRight: '15px', fontWeight: '600' }}>
                        Broadcast Message
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="border-0 promo_codes p-4">
                <PersistTableSort
                  name="payer-list"
                  // bordered
                  columns={this.state.tempColumns.filter(item => !item.hidden)}
                  dataSource={this.props.appInviteData}
                  size={'middle'}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div className="dataTables_wrapper no-footer">
                          <div className="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a className="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a className="paginate_button next">Next</a>
                            ) : (
                              <a className="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                  }}
                />
                <CustomModal
                  isOpen={this.state.isModelOpen}
                  header={this.state.header}
                  yesAction={() => {
                    this.toggle();
                  }}
                  yesText="Close"
                  noText="Cancel"
                  hideCancel={true}
                  zIndex={999999}
                />
                <Modal
                  visible={this.state.inviteFormModal}
                  footer={null}
                  closable={false}
                  destroyOnClose={true}
                  // onCancel={() => {
                  //   this.setState({
                  //     inviteFormModal: false,
                  //   });
                  // }}
                >
                  <SendAppInviteReduxModal
                    onCancel={() => {
                      this.setState({
                        inviteFormModal: false,
                      });
                    }}
                  />
                </Modal>
                <Modal
                  open={this.state.reduxBroadcaseInvitationModal}
                  footer={null}
                  closable={false}
                  width={702}
                  destroyOnClose={true}
                  style={{ height: '100vh' }}
                >
                  <ReduxBroadcaseInvitationModal
                    onCancel={() => {
                      this.setState({
                        reduxBroadcaseInvitationModal: false,
                      });
                    }}
                  />
                </Modal>

                {/* <CustomModal
                  isOpen={this.state.isModelOpen}
                  header={this.state.header}
                  yesAction={() => {
                    // this.Removetoggle();
                  }}
                  yesText="submit"
                  noText="cancel"
                  noAction={() => {
                    // this.Removetoggle();
                    // this.props.history.push(staffURL);
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ get_invited_list, invite_resend }) => {
  var { data, isFetching, error } = get_invited_list;
  var { data: inviteSuccessData, isFetching, error } = invite_resend;

  let appInviteData = [];
  let filterDropDown = [];
  let inviteSuccess;
  let inviteMsg;
  if (data && data[0].InvitedList) {
    appInviteData = data[0].InvitedList || [];
  }
  if (data && data[0].FilterBy) {
    filterDropDown = data[0].FilterBy || [];
  }
  if (inviteSuccessData && inviteSuccessData.success) {
    inviteSuccess = inviteSuccessData.success;
    inviteMsg = inviteSuccessData.data;
  }
  return {
    appInviteData,
    inviteSuccess,
    inviteMsg,
    filterDropDown,
  };
};

export default connect(mapStateToProps)(withRouter(AppInvitedList));
