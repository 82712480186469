import React from 'react';
var timer;

const GridSearch = props => {
  const fnDebounce = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      props.onSearchChange(value);
    }, 300);
  };

  return (
    <div className="search-box d-inline-flex w-100 mx-2">
      <div className="form-group has-search w-100 d-flex align-items-center mb-0">
        <input
          type="text"
          class="form-control 
                   shadow-sm rcm-document-search"
          placeholder="Search"
          id="filterbox"
          onKeyUp={event => {
            let val = event.target.value.length > 0 ? event.target.value : null;
            fnDebounce(val);
          }}
          style={{ minHeight: '46px' }}
        />
        <button class="btn search-icon " type="button">
          <i class="fa fa-search" />
        </button>
      </div>
    </div>
  );
};
export default GridSearch;
