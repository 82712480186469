import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import getParam from '../../../../util/getParam';
import ContractBundles from '../../components/contractBundles';
import { smartContractGet } from '../../../../store/actions';



class ContractDetailsDrawer extends Component {
  getClientId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  };

  componentDidMount() {
    let data = {
      SmartContract: [{ ClientId: this.getClientId(), SmartContractId: this.props.smartContractId }],
    };

    var smartContractData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(smartContractGet(smartContractData));
  }
  render() {
    const {
      contractDetailsOverView,
      smartContractDetailArray,
      smartContractMilestones,
    } = this.props;
    const {
      ClaimAmount,
      DaysRemaininginTreatment,
      ExpectedEndDate,
      StartDate,
      TotalGoalsDefined,
      TotalMilestones,
      TotalServicesAssigned,
      TotalServicesDelivered,
      TotalServiceBundles,
      TotalServices,
    } = contractDetailsOverView || {};
    const { Goal, Bundle } = smartContractDetailArray;
    // const { ServiceCode, ServiceCodeBundleName, DaysInTreatment } = Bundle ? Bundle[0] : [];
    return (
      <div className="payer-authorizations">
        <div className="d-flex flex-wrap client-authorization">
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Claim Amount</p>
            <p className="mb-0 client-authorization-value">{ClaimAmount && ClaimAmount}</p>
          </div>
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Total Services Delivered</p>
            <p className="mb-0 client-authorization-value">{TotalServices && TotalServices}</p>
          </div>
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Total Services Assigned</p>
            <p className="mb-0 client-authorization-value">
              {TotalServicesAssigned && TotalServicesAssigned}
            </p>
          </div>
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Days remaining in Treatment</p>
            <p className="mb-0 client-authorization-value">
              {DaysRemaininginTreatment && DaysRemaininginTreatment}
            </p>
          </div>
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Total Measures Defined</p>
            <p className="mb-0 client-authorization-value">{TotalMilestones && TotalMilestones}</p>
          </div>
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Total Goals Defined</p>
            <p className="mb-0 client-authorization-value">
              {TotalGoalsDefined && TotalGoalsDefined}
            </p>
          </div>
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Start Date</p>
            <p className="mb-0 client-authorization-value">{StartDate && StartDate}</p>
          </div>
          <div className="col-12 d-flex client-authorization-container col-md-6">
            <p className="mb-0 client-authorization-label">Expected End Date</p>
            <p className="mb-0 client-authorization-value">{ExpectedEndDate && ExpectedEndDate}</p>
          </div>
        </div>
        {/* {Goal &&
          Goal.map(item => {
            const { Milestone, OverAllProgress } = item;
            return (
              
            );
          })} */}

        <div className="">
          <div
            className="mt-3"
            style={{
              width: '100%',
              marginLeft: '10px',
              backgroundColor: '#F8F8F8',
              padding: '20px',
              border: ' 1px solid #EAEAEA',
            }}
          >
            {/* <div className="d-flex">
              <div className="client-detail-box col-4 p-0 m-0">
                <p className="client-label mb-0">Goal*</p>
                <p className="client-text mb-0">{item.Description}</p>
              </div>
              <div className="client-detail-box col-4 p-0 m-0">
                <p className="client-label mb-0">Duration*</p>
                <p className="client-text mb-0">{item.Duration}</p>
              </div>
            </div> */}
            <h5 className="text-uppercase mt-3">Bundles</h5>
            <ContractBundles smartContractArray={Bundle} isReadOnly={true} actionAllowed={false} />

            <h5 className="mt-5">Milestones</h5>
            <div className="card border-0 p-4">
              {smartContractMilestones &&
                smartContractMilestones.map((item, index) => {
                  return (
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: '#F8F8F8',
                        padding: '20px',
                        border: ' 1px solid #EAEAEA',
                        marginBottom: '15px',
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-start mb-3">
                        <div className="client-detail-box p-0 m-0">
                          <p className="client-label mb-0">Bundle {index + 1}*</p>
                          <p className="client-text mb-0">{item.BundleName && item.BundleName}</p>
                        </div>

                        <div className="client-detail-box col-3 p-0 m-0">
                          <p className="client-label mb-0">Goal*</p>
                          <p className="client-text mb-0">{item.Goal && item.Goal}</p>
                        </div>
                        <div className="client-detail-box col-3 p-0 m-0">
                          {/* <p className="client-label mb-0">Status</p> */}
                          <p
                            className={`mb-0 client-text client-info-text ${item.StatusClassName}`}
                            style={{ width: 'fit-content' }}
                          >
                            {item.Status && item.Status}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-start">
                        <div className="client-detail-box p-0 m-0">
                          <p className="client-label mb-0">Milestone {index + 1}*</p>
                          <p className="client-text mb-0">{item.Milestone && item.Milestone}</p>
                        </div>

                        <div className="client-detail-box col-3 p-0 m-0">
                          <p className="client-label mb-0">Duration*</p>
                          <p className="client-text mb-0">{item.Duration && item.Duration}</p>
                        </div>
                        {/* <div className="client-detail-box col-3 p-0 m-0">
                          <p className="client-label mb-0">Progress</p>
                          <p
                            className={`mb-0 client-text client-info-text ${item.StatusClassName}`}
                            style={{ width: 'fit-content' }}
                          >
                            {item.Progress && item.Progress}
                          </p>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* <div class="">
          <div className="row">
            <div className="col-12 mb-2 pt-4">
              <div className="card border-0 students-profile" style={{ padding: '20px' }}>
                <div className="row card-body align-items-center">
                  <h4 style={{ width: '100%' }}>{`${ServiceCodeBundleName}`}</h4>
                  <div className="d-flex">
                    <div className="">
                      <p className="client-label mb-0">Program</p>
                      <p className="client-text mb-0">{ServiceCodeBundleName}</p>
                    </div>
                    <div className="" style={{ marginLeft: '15px' }}>
                      <p className="client-label mb-0">Days in Treatment</p>
                      <p className="client-text mb-0">{DaysInTreatment}</p>
                    </div>
                  </div>

                  <h4 className="mt-5" style={{ width: '100%' }}>
                    {'Services'}
                  </h4>
                  {ServiceCode &&
                    ServiceCode.map((item, index) => {
                      return (
                        <div className="payer-card mt-2" style={{ border: '1px solid #EAEAEA' }}>
                          <div
                            className="d-flex justify-content-between"
                            style={{ height: '150px ' }}
                          >
                            <div className="d-flex flex-column justify-content-between">
                              <div>
                                <p className="client-label mb-0">Service Code*</p>
                                <p className="client-text mb-0">{item.CodeName}</p>
                              </div>
                              <div>
                                <p className="client-label mb-0">Code Description</p>
                                <p className="client-text mb-0">{item.ServiceCodeDescription}</p>
                              </div>
                            </div>

                            <div className="d-flex align-items-start">
                              <div>
                                <p className="client-text mb-0">Number of services</p>
                                <p className="client-label mb-0">{item.UnitOfMeasurement}</p>
                              </div>
                              <p
                                className="text-center mb-0 ml-2"
                                style={{
                                  width: '20%',
                                  backgroundColor: 'white',
                                  padding: '5px 15px',
                                }}
                              >
                                {item.NoOfServices}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <h4 className="mt-5" style={{ width: '100%' }}>
                    {'Measures'}
                  </h4>
                  {Measure &&
                    Measure.map((item, index) => {
                      const { Description, TotalScore, Goal, Summary } = item;

                      return (
                        <div className="payer-card mt-2" style={{ border: ' 1px solid #EAEAEA' }}>
                          <h5>Measure {index + 1}</h5>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ width: '60%' }}
                          >
                            <p className="mb-0">{item.Description}</p>
                            <p
                              className="text-center mb-0"
                              style={{
                                backgroundColor: 'white',
                                padding: '5px 15px',
                                marginLeft: '10px',
                                border: '1px solid #A1A5B7',
                              }}
                            >
                              Baseline Score: {item.TotalScore}
                            </p>
                          </div>
                          {Goal &&
                            Goal.map((item, index) => {
                              const {
                                GoalType,
                                Description,
                                Note,
                                GoalCreatedOn,
                                GoalTimePeriod,
                                TimePeriod,
                                StatusText,
                                StatusClassName,
                                GoalCompletedId,
                                DaysToAchieveGoal,
                                WeeksToAchieveGoal,
                              } = item;
                              return (
                                <div
                                  className="card border-0 students-profile mt-4"
                                  style={{ padding: '20px' }}
                                >
                                  <div className="d-flex justify-content-between">
                                    <p
                                      className="mb-0"
                                      style={{ fontWeight: '700', fontSize: '18px' }}
                                    >
                                      Goal:
                                    </p>
                                    <div
                                      className={`d-flex align-items-center client-info-text ${StatusClassName}`}
                                    >
                                      <img
                                        src={`images/${
                                          GoalCompletedId == 1
                                            ? `white_bg_approve.svg`
                                            : `white_bg_deny.svg`
                                        }`}
                                      />
                                      <p
                                        className={`text-uppercase mb-0 ml-2`}
                                        style={{ fontSize: '18px', fontWeight: '500' }}
                                      >
                                        {StatusText && StatusText}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="mt-3">
                                    <p className="mb-0 client-label">Goal Type</p>
                                    <p className="mb-0 client-text">{GoalType && GoalType}</p>
                                  </div>

                                  <div className="mt-3">
                                    <p className="mb-0 client-label">Goal</p>
                                    <p className="mb-0 client-text">{Description && Description}</p>
                                  </div>

                                  <div className="d-flex mt-3">
                                    <div>
                                      <p className="mb-0 client-label">Goal Added Date</p>
                                      <p className="mb-0 client-text">
                                        {GoalCreatedOn && GoalCreatedOn}
                                      </p>
                                    </div>
                                    <div className="ml-5">
                                      <p className="mb-0 client-label">
                                        Time Period to Achieve Goal
                                      </p>
                                      <p className="mb-0 client-text">
                                        {DaysToAchieveGoal && DaysToAchieveGoal}
                                      </p>
                                    </div>
                                    <div className="ml-5">
                                      <p className="mb-0 client-label">Date to Achieve Goal</p>
                                      <p className="mb-0 client-text">
                                        {WeeksToAchieveGoal && WeeksToAchieveGoal}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="mt-3">
                                    <p className="mb-0 client-label">Note</p>
                                    <p className="mb-0 client-text">{Note && Note}</p>
                                  </div>

                                  <div className="mt-3 payer-card" style={{ borderRadius: '10px' }}>
                                    <p className="mb-0 client-label">Remarks</p>
                                    <p className="mb-0 client-text">Goal</p>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}

                  
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

// export default ContractDetailsDrawer;

export default connect()(withRouter(ContractDetailsDrawer));

