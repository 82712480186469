import React, { Component } from 'react';
import { Avatar, Divider, Tooltip } from 'antd';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { BGContent2 } from '../commonSection';
 const PreIntake = props => {
   const { StatusData } = props;
   return (
     <>
       <div
         className="d-flex align-items-center justify-content-between mt-5"
         style={{ backgroundColor: '#FC2E20', borderRadius: '12px', padding: '2px' }}
       >
         <div className="d-flex align-items-center">
           {' '}
           <img src={`images/license/hour_glass.svg`} width="86" height="79" alt="" />
           <div style={{ fontSize: '26px', fontWeight: '600', color: '#fff' }}>
             {' '}
             Uh-Oh! Your license has expired.{' '}
             <span style={{ fontSize: '20px', fontWeight: '600' }}>
               Purchase license to enjoy hassle-free usage.
             </span>
           </div>
         </div>

         <span
           style={{
             fontSize: '16px',
             fontWeight: '600',
             background: '#fff',
             color: '#0070E0',
             borderRadius: '25px',
             padding: '8px',
             marginRight: '50px',
             cursor: 'pointer',
           }}
           onClick={() => {
             props.history.push('/manage/maintain-license');
           }}
         >
           Purchase Prospect License
         </span>
       </div>

       <BGContent2
         moduleName={'Uh-oh! Your license has expired.'}
         backGroundImage={`images/license/preIntakeBG.svg`}
         tagLine={`Prospect feature license allows counselors and centers to create leads for centers
             and filter patients who are fit for the center's treatment. This is a great tool for
             those in the counseling or mental health field as it can help to ensure that patients
             are getting the care that they need.`}
         buyButtonText={'Purchase Prospect License'}
         clientsWaiting={
           (StatusData && StatusData.Interaction && StatusData.Interaction[0].PreIntakeClientCnt) ||
           0
         }
         avatar={true}
       />
     </>
   );
 };
export default PreIntake;