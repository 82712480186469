import React, { useEffect, useState, useRef } from 'react';
import ClipboardCopy from '../../../util/ClipboardCopy';
import TextToSpeech from '../../../Components/TextToSpeech';
const Response = ({
  ResponseData,
  ResponseBookmark,
  Index,
  ResponseLikeDislike,
  EditedText,
  IsShowAction,
  SetModifiedChat,
}) => {
  return (
    <>
      <div className="messages">
        <span>{ResponseData.Response}</span>
        <div className="messages-actions">
          <div>
            <span className="chat-time">{ResponseData.ResponseTime}</span>
            {/* {ResponseData.IsEditedResponse && <span className="chat-edited">{EditedText}</span>} */}
          </div>
          <div className="events">
            <span
              className=""
              onClick={() => {
                if (ResponseData.LikedDisliked != 0) ResponseLikeDislike(ResponseData, Index, 0);
              }}
            >
              <img
                src={`images/icons/${
                  ResponseData.LikedDisliked === 0 ? 'chat-liked' : 'chat-like'
                }.svg`}
              />
            </span>
            <span
              className=""
              onClick={() => {
                if (ResponseData.LikedDisliked != 1) ResponseLikeDislike(ResponseData, Index, 1);
              }}
            >
              <img
                src={`images/icons/${
                  ResponseData.LikedDisliked === 1 ? 'chat-disliked' : 'chat-dislike'
                }.svg`}
              />
            </span>
            {IsShowAction && (
              <span className="">
                <TextToSpeech speechText={ResponseData.Response} />
              </span>
            )}

            <span className="">
              <ClipboardCopy copyText={ResponseData.Response} />
            </span>
            {false && IsShowAction && (
              <span
                className=""
                onClick={event => {
                  SetModifiedChat({
                    index: Index,
                    response: ResponseData.Response,
                  });
                }}
              >
                <img src="images/icons/chat-edit.svg" />
              </span>
            )}
          </div>
        </div>
      </div>
      {ResponseData.ChatId && (
        <div
          className="mx-2 bookmark"
          onClick={() => {
            ResponseBookmark(ResponseData, Index);
          }}
        >
          <img
            src={`images/icons/${
              ResponseData.IsResponseBookmarked ? 'chat-bookmarked' : 'chat-bookmark'
            }.svg`}
          />
        </div>
      )}
    </>
  );
};

export default Response;
