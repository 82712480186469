import React, { Component } from 'react';
import {
  AlphaNumericType,
  BinaryType,
  CurrencyType,
  NumericType,
  OptionType,
  SmileyType,
  ToggleType,
  FeelingType,
  MultiSelectType,
  TextareaType,
  RadioOptionsType,
  MultiSelectOneLineType,
  InstructionType,
  ScoreType,
  DateType,
  DateTimeType,
  SignatureType,
  SmallText,
  AlphaNumericOneLine,
  InstructionType2,
  ToggleRadioOptionsType,
  ToggleAlphaNumeric,
  ToggleNumeric,
  ScoreTextType,
  ToggleMultiSelectOneLineType,
  ToggleBinary,
  ToggleDate,
  ToggleTextarea,
  AlphaNumericMultipleInputs,
  SingleSelectOneLineColored,
  NumericOneLine,
  SingleSelectOneLineType,
} from './index';

const SURVEYTYPES = {
  Binary: 1,
  Options: 2,
  Numeric: 3,
  AlphaNumeric: 4,
  SmileyType: 5,
  Currency: 6,
  MultiSelect: 7,
  OptionsMultiLine: 8,
  Textarea: 9,
  Score: 10,
  MultiselectOneLine: 11,
  InstructionNoResponse: 12,
  Date: 13,
  Signature: 14,
  SmallText: 15,
  AlphaNumericOneLine: 18,
  // InstructionNoResponseOneLine: 21,
  ToggleRadioOptionsType: 19,
  ToggleAlphaNumeric: 20,
  ScoreTextType: 21,
  ToggleTextarea: 23,
  // 22,23 used for question bank configuration
  ToggleNumeric: 24,
  ToggleMultiSelectOneLineType: 25,
  ToggleBinary: 26,
  ToggleDate: 27,
  AlphaNumericMultipleInputs: 28,
  SingleSelectOneLineColored: 29,
  NumericOneLine: 30,
  SingleSelectOneLineType: 31,
  DateTime:33,
};

class QuestionType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      SurveyQuestion,
      SurveyId,
      onNext,
      setValue,
      saveResponse,
      scope,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      saveSignature,
      isAnswerUpsert,
      isAnserFetching,
    } = this.props;
    return (
      <div className="row rcm-question rcm-panel">
        {SurveyQuestion &&
          SurveyQuestion.map((survey, index) => {
            let questionProps = {
              survey: survey,
              SurveyId: SurveyId,
              index: index,
              onClick: onNext,
              scope: scope,
              total: SurveyQuestion.length,
              level: level,
              subLevel: subLevel,
              subCatLevel: subCatLevel,
              isReadOnly: isReadOnly,
              isAnswerUpsert: isAnswerUpsert,
              isAnserFetching: isAnserFetching,
              setValue: setValue,
              saveResponse: saveResponse,
            };
            if (survey.SurveyQuestionTypeId == SURVEYTYPES.Binary) {
              return <BinaryType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Options) {
              return <OptionType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Numeric) {
              return <NumericType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Currency) {
              return <CurrencyType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.SmileyType) {
              return <SmileyType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.AlphaNumeric) {
              return <AlphaNumericType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.AlphaNumericOneLine) {
              return <AlphaNumericOneLine {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.MultiSelect) {
              return <MultiSelectType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.OptionsMultiLine) {
              return <RadioOptionsType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Textarea) {
              return <TextareaType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.MultiselectOneLine) {
              return <MultiSelectOneLineType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.InstructionNoResponse) {
              return <InstructionType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.InstructionNoResponseOneLine) {
              return <InstructionType2 {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Score) {
              return <ScoreType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ScoreTextType) {
              return <ScoreTextType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Date) {
              return <DateType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.DateTime) {
              return <DateTimeType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.Signature) {
              return <SignatureType saveSignature={saveSignature} {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.SmallText) {
              return <SmallText {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ToggleRadioOptionsType) {
              return <ToggleRadioOptionsType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ToggleAlphaNumeric) {
              return <ToggleAlphaNumeric {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ToggleNumeric) {
              return <ToggleNumeric {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ToggleTextarea) {
              return <ToggleTextarea {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ToggleBinary) {
              return <ToggleBinary {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ToggleDate) {
              return <ToggleDate {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.AlphaNumericMultipleInputs) {
              return <AlphaNumericMultipleInputs {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.SingleSelectOneLineColored) {
              return <SingleSelectOneLineColored {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.NumericOneLine) {
              return <NumericOneLine {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.SingleSelectOneLineType) {
              return <SingleSelectOneLineType {...questionProps} />;
            } else if (survey.SurveyQuestionTypeId == SURVEYTYPES.ToggleMultiSelectOneLineType) {
              return <ToggleMultiSelectOneLineType {...questionProps} />;
            }
          })}
      </div>
    );
  }
}
export default QuestionType;
