import React, { Component } from 'react';
import { Radio, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ReduxInput, ReduxSelect } from '../component/custom-redux-components';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { addLicenses, getStripeApiKey, listCombos } from '../store/actions';
// import Validator from './enrollmentForm/validation/validate';

const Validator = {
  required: value => (value ? undefined : 'Field Required'),
  accountName: value =>
    value && !/^.{1,50}$/.test(value) ? ' cannot exceed 50 characters' : undefined,
  routingNumber: value =>
    value && !/^[0-9]{9}$/.test(value) ? ' is a 9 digit numeric Value' : undefined,
  accountNumber: value =>
    value && !/^[0-9]{4,20}$/.test(value)
      ? ' has a minimum of 4 and a maximum of 20 digits'
      : undefined,
  email: value => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  confirmEmail: (value, values) => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        if (value != values.email) {
          return 'Email and Confirm Email must be same';
        }
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class Completepurchase extends Component {
  constructor(props) {
    super(props);
    const { checked } = props;
    this.state = {
      checked: checked || '',
      CreditDebitCard: true,
      Checking: false,
      value: 'CreditDebit',
      errorMessage: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'StateProvince',
      }),
    );
  }
  handleChange = VALUES => {
    const { error } = VALUES;
    if (error) {
      this.setState({ errorMessage: error.message });
    } else {
      this.setState({ errorMessage: '' });
    }
  };

  handleSubmit = async evt => {
    const { stripe, elements, handleResult } = this.props;
    if (stripe) {
      let number = elements.getElement(CardNumberElement);
      let cvc = elements.getElement(CardCvcElement);
      let exp = elements.getElement(CardExpiryElement);
      if (this.state.value === 'CreditDebit') {
        let creditRes = await stripe.createPaymentMethod({
          type: 'card',
          card: number,
          billing_details: {
            name: evt.billingName,
            email: evt.billingEmail,
            address: {
              city: evt.city,
              line1: evt.billingAddress,
              postal_code: evt.postalCode,
            },
          },
        });
        handleResult(creditRes, evt, 'card');
      }
      if (this.state.value === 'Checking') {
        // stripe.createToken().then(res => handleResult(res, evt));
        // stripe
        //   .createToken('bank_account', {
        //     country: 'US',
        //     currency: 'usd',
        //     routing_number: evt.routingNumber,
        //     account_number: evt.accountNumber,
        //     account_holder_name: evt.accountName,
        //     account_holder_type: evt.accountType,
        //   })
        //   .then(res => {

        //   });

        let BankRes = await stripe.createToken('bank_account', {
          country: 'US',
          currency: 'usd',
          routing_number: evt.routingNumber,
          account_number: evt.accountNumber,
          account_holder_name: evt.accountName,
          account_holder_type: evt.accountType,
        });
        //  paymentType: 'card',
        handleResult(BankRes, evt, 'ach');
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  onChange = e => this.setState({ value: e.target.value });
  resetState = () => this.setState({ checked: '' });

  makeAddCopy = e => {
    const { billingDetails } = this.props;
    if (e.target.checked) {
      this.props.autofill('shippingName', billingDetails.billingName);
      this.props.autofill('shippingEmail', billingDetails.billingEmail);
      this.props.autofill('shippingAddress', billingDetails.billingAddress);
      this.props.autofill('shippingPostalCode', billingDetails.postalCode);
      this.props.autofill('shippingCity', billingDetails.city);
    } else {
      this.props.autofill('shippingName', '');
      this.props.autofill('shippingEmail', '');
      this.props.autofill('shippingAddress', '');
      this.props.autofill('shippingPostalCode', '');
      this.props.autofill('shippingCity', '');
    }
  };
  render() {
    const {
      CurrentLicenseData,
      LicenseCounts,
      Total,
      salesTax,
      handleSubmit,
      billingDetails,
      submitting,
      StateProvince,
    } = this.props;

    return (
      <div class="main">
        <div class="admin-maintain">
          <div class="container-fluid">
            <div class="row top-table-search mt-5 mb-4 align-items-center">
              <div class="col-12">
                <a class="back-link" onClick={() => this.props.history.goBack()}>
                  <img src="images/back-arrow.svg" /> Back
                </a>
                <h3 class="page-title mb-3 mb-md-0">Checkbox Your Order</h3>
              </div>
            </div>
            <div class="payment-dashboard">
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                <div class="row">
                  <div class="col-md-12 col-lg-7 col-xl-8">
                    <div class="payment-dashboard-detail enrollment-form">
                      <div class="payment-label">Billing Summary</div>
                      <div class="row">
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingName"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              className="form-control"
                              fieldName="Name"
                            />
                            <label class="placeholder-label">Name*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingEmail"
                              type="email"
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="someone@domain.com"
                              className="form-control"
                              fieldName="Email"
                            />
                            <label class="placeholder-label">Email*</label>
                          </div>
                        </div>
                        <div class="col-12  col-lg-12 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingAddress"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="Address"
                              className="form-control"
                              fieldName=" Address"
                            />
                            <label class="placeholder-label">Billing Address*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="postalCode"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="Postal Code"
                              className="form-control"
                              fieldName="Postal Code"
                            />
                            <label class="placeholder-label">Postal Code*</label>
                          </div>
                        </div>

                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group combo-dropdown">
                            <Field
                              name={`billingState`}
                              type="text"
                              placeholder={`State*`}
                              component={ReduxSelect}
                              validate={Validator.required}
                              options={StateProvince}
                            />
                            {/* <label class="placeholder-label">City</label> */}
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="city"
                              type="text"
                              errorMessage=""
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="City"
                              className="form-control"
                              fieldName="City"
                            />
                            <label class="placeholder-label">City*</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="payment-dashboard-detail enrollment-form">
                      <div class="payment-label">Payment Details</div>
                      <div class="credit-card-detail">
                        <div class="nav nav-tabs  border-0  font-weight-bold" role="tablist">
                          <Radio.Group
                            options={['CreditDebit', 'Checking']}
                            // options={['CreditDebit']}
                            onChange={this.onChange}
                            value={this.state.value}
                          />
                        </div>
                      </div>
                      {this.state.value === 'CreditDebit' ? (
                        <div class="tab-pane fade show active" id="card-tab">
                          <div class="row ">
                            <div class="col-12">
                              <div class="row ">
                                <div class="col-6  pb-lg-3">
                                  <div class="form-group">
                                    <label class="col-12">
                                      Card number
                                      <CardNumberElement
                                        className="form-control"
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div class="col-3 pb-lg-3">
                                  <div class="form-group">
                                    <label class="col-12">
                                      {' '}
                                      Exp date
                                      <CardExpiryElement
                                        className="form-control"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div class="col-3 pb-lg-3">
                                  <div class="form-group">
                                    <label class="col-12">
                                      CVC
                                      <CardCvcElement
                                        className="form-control"
                                        placeholder="123"
                                        {...createOptions()}
                                        onChange={this.handleChange}
                                      />
                                    </label>
                                  </div>
                                </div>
                                {/* <div class="col-6 pb-lg-3">
                                  <div class="form-group">
                                    <label class="col-12">
                                      Postal code
                                      <input
                                        {...createOptions()}
                                        name="name"
                                        type="text"
                                        placeholder="00000"
                                        className="form-control"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div> */}
                                <div className="error" role="alert">
                                  {this.state.errorMessage}
                                </div>
                                {/* <div class="col-12 pb-lg-3 pt-1">
                                  <label class="custom-checkbox d-flex align-items-center">
                                    <input type="checkbox" />
                                    <em class="checkmark mr-2" />
                                    <span class="font-weight-bold status-label">
                                      Save the card for future purchase
                                    </span>
                                  </label>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="tab-pane" id="checking-tab">
                          <div class="row">
                            <div class="col-12  col-md-6 pb-lg-3">
                              <div class="form-group call-schedule">
                                {/* <input type="text" class="form-control" /> */}
                                {/* <Field
                                  name="accountType"
                                  type="text"
                                  component={ReduxInput}
                                  validate={Validator.required}
                                  className="form-control"
                                  fieldName="Bank Name"
                                /> */}
                                <Field
                                  name="accountType"
                                  type="text"
                                  placeholder="Account Type"
                                  component={ReduxSelect}
                                  validate={Validator.required}
                                  // className="form-control"
                                  options={[
                                    { Value: 'Company', Text: 'Company' },
                                    { Value: 'Individual', Text: 'Individual' },
                                  ]}
                                />
                              </div>
                            </div>
                            <div class="col-12  col-md-6 pb-lg-3">
                              <div class="form-group">
                                {/* <input type="text" class="form-control" /> */}
                                <Field
                                  name="bankName"
                                  type="text"
                                  component={ReduxInput}
                                  validate={Validator.required}
                                  className="form-control"
                                  fieldName="Bank Name"
                                />
                                <label class="placeholder-label">Bank Name*</label>
                              </div>
                            </div>
                            <div class="col-12  col-md-6 pb-lg-3">
                              <div class="form-group">
                                {/* <input type="text" class="form-control" /> */}
                                <Field
                                  name="accountName"
                                  type="text"
                                  component={ReduxInput}
                                  validate={[Validator.accountName, Validator.required]}
                                  className="form-control"
                                  fieldName="Account Name "
                                />
                                <label class="placeholder-label">Account Name*</label>
                              </div>
                            </div>
                            <div class="col-12  col-md-6 pb-lg-3">
                              <div class="form-group">
                                {/* <input type="text" class="form-control" /> */}
                                <Field
                                  name="routingNumber"
                                  type="text"
                                  component={ReduxInput}
                                  validate={[Validator.routingNumber, Validator.required]}
                                  className="form-control"
                                  fieldName="Routing Number"
                                />
                                <label class="placeholder-label">Routing Number*</label>
                              </div>
                            </div>
                            <div class="col-12  col-md-6 pb-lg-3">
                              <div class="form-group">
                                {/* <input type="text" class="form-control" /> */}
                                <Field
                                  name="accountNumber"
                                  type="text"
                                  component={ReduxInput}
                                  validate={[Validator.accountNumber, Validator.required]}
                                  className="form-control"
                                  fieldName="Account Number"
                                />
                                <label class="placeholder-label">Account Number*</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div class="payment-dashboard-detail enrollment-form">
                      <div class="billing-detail">
                        <div class="payment-label">Shipping Details</div>
                        <label class="custom-checkbox d-flex align-items-center mb-3 mb-sm-0">
                          <input type="checkbox" onClick={this.makeAddCopy} />
                          <em class="checkmark mr-2" />
                          <span class="font-weight-bold status-label">Same as Billing Details</span>
                        </label>
                      </div>
                      <div class="row">
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingName"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              className="form-control"
                              fieldName="Name"
                            />
                            <label class="placeholder-label">Name*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingEmail"
                              type="email"
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="someone@domain.com"
                              className="form-control"
                              fieldName="Shipping Email"
                            />
                            <label class="placeholder-label">Email*</label>
                          </div>
                        </div>
                        <div class="col-12  col-lg-12 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingAddress"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="Address"
                              className="form-control"
                              fieldName="Shipping Address"
                            />
                            <label class="placeholder-label">Billing Address*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingPostalCode"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="Postal Code"
                              className="form-control"
                              fieldName="Shipping Postal Code"
                            />
                            <label class="placeholder-label">Postal Code*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-3">
                          <div class="form-group">
                            <Field
                              name="shippingCity"
                              type="text"
                              errorMessage=""
                              component={ReduxInput}
                              validate={Validator.required}
                              placeholder="City"
                              className="form-control"
                              fieldName="Shipping City"
                            />
                            <label class="placeholder-label">City*</label>
                          </div>
                        </div>
                      </div>
                    </div>
                   */}
                  </div>
                  <div class="col-md-12 col-lg-5 col-xl-4">
                    <div class="payment-dashboard-detail order-summary enrollment-form">
                      <div class="payment-label">Order Summary</div>
                      <div class="total-price">
                        <div class="sub-total">
                          {CurrentLicenseData &&
                            CurrentLicenseData.map(item => <span>{item.LicenseShortName}</span>)}
                        </div>
                        <div class="sales-price">
                          {CurrentLicenseData &&
                            CurrentLicenseData.map((item, index) => (
                              <span>
                                {item.UnitCostMonthly} x {LicenseCounts && LicenseCounts[index]}
                              </span>
                            ))}
                        </div>
                      </div>
                      <div class="total-price">
                        <div class="sub-total">
                          <span>Subtotal:</span>
                          {/* <span>Sales Tax {salesTax}:</span> */}
                        </div>
                        <div class="sales-price">
                          <span>${Total}</span>
                          {/* <span>${(Total * salesTax) / 100}</span> */}
                        </div>
                      </div>
                      <div class="total-amount">
                        <span>Total Amount:</span>
                        <span class="amount-price">
                          {/* ${(Total + (Total * salesTax) / 100).toFixed(2)} */}${Total}
                        </span>
                      </div>
                      <div class="text-center">
                        <button type="submit" disabled={submitting} class="btn" name="pay">
                          {' '}
                          Place your Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { get_current_licenses, get_stripe_api_key, combos } = state;
  const billingDetails = selector(
    state,
    'billingName',
    'billingEmail',
    'billingAddress',
    'postalCode',
    'city',
  );
  var CurrentLicenseData = [];
  var LicenseCounts = {},
    Total = 0,
    salesTax = 0;
  let STRIPE_KEY = 'pk_test_rbMiI6BgdQRd52AnDdsdpdYH00Z0ukIk9v';
  let editStateProvince = [];
  if (get_stripe_api_key.data) {
    STRIPE_KEY = get_stripe_api_key.data.stripeKey;
  }
  if (get_current_licenses.data) {
    CurrentLicenseData = get_current_licenses.data.data;
    LicenseCounts = get_current_licenses.data.LicensesCounts;
    Total = get_current_licenses.data.Total;
    salesTax = get_current_licenses.data.salesTax;
  }
  if (!combos.data) {
    combos.data = {
      StateProvince: [],
    };
  }
  if (combos.data.StateProvince && combos.data.StateProvince.length > 0) {
    editStateProvince = combos.data.StateProvince.map(item => {
      return {
        Value: item.Text,
        Text: item.Text,
      };
    });
  }
  return {
    CurrentLicenseData,
    LicenseCounts,
    Total,
    salesTax,
    billingDetails,
    STRIPE_KEY,
    initialValues: { accountType: 'Company' },
    StateProvince: editStateProvince,
  };
};

Completepurchase = reduxForm({
  form: 'completePurchageForm',
  // Validator,
  enableReinitialize: true,
})(withRouter(Completepurchase));

const selector = formValueSelector('completePurchageForm');

Completepurchase = connect(mapStateToProps)(Completepurchase);

class MakePayment extends Component {
  constructor(props) {
    super(props);
    props.dispatch(getStripeApiKey({}));
  }
  handleResult = (response, values, type) => {
    const { Total, salesTax, dispatch, LicenseCounts } = this.props;
    if (!response.error) {
      response.billingDetails = {
        billingName: values.billingName,
        billingEmail: values.billingEmail,
        billingAddress: values.billingAddress,
        billingPostalCode: values.postalCode,
        billingCity: values.city,
      };
      response.shippingDetails = {
        shippingName: values.shippingName,
        shippingEmail: values.shippingEmail,
        shippingAddress: values.shippingAddress,
        shippingPostalCode: values.shippingPostalCode,
        shippingCity: values.shippingCity,
      };
      response.orderSummary = {
        Userlic: LicenseCounts[0],
        Kiosklic: LicenseCounts[1],
        salesTax,
        subTotal: Total,
        TotalAmt: (Total + (Total * salesTax) / 100).toFixed(2),
      };
      response.paymentType = type;
      // dispatch(addLicenses({ json: response.token.id }));
      dispatch(addLicenses({ json: JSON.stringify(response) }));
    } else {
      Modal.error({
        title: response.error.code,
        content: response.error.message,
      });
    }
    // alert(JSON.stringify(response));
  };

  render() {
    const { STRIPE_KEY } = this.props;
    const stripePromise = loadStripe(STRIPE_KEY);
    return (
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <Completepurchase
              handleResult={this.handleResult}
              stripe={stripe}
              elements={elements}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}

export default connect(mapStateToProps)(MakePayment);
