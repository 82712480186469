import React, { Component } from 'react';
import { Tabs, Modal, Table, Button } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import OverHeadCost from "./overHeadCost";
import LaborCost from "./laborCost";
import Consumables from './consumables';
import ServiceBundles from './serviceBundles';
import ServiceCodes from './serviceCodes';
const { TabPane } = Tabs;
class MasterBillOfService extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      AddNodeModelOpen: false,
      AddCourseModelOpen: false,
      activeKey: 0,
      tabMapping: {
        0: 'overhead-cost',
        1: 'labor-rates',
        2: 'materials',
        3: 'service-codes',
        4: 'service-bundles',
      },
      tabLabels: [
        {
          Name: <span className="border-image">Overhead Cost</span>,
          Component: () => <OverHeadCost {...this.props} name={'overhead-cost'} />,
        },
        {
          Name: <span className="border-image">Labor Rates</span>,
          Component: () => <LaborCost {...this.props} name={'labor-rates'} />,
        },
        {
          Name: <span className="border-image">Materials</span>,
          Component: () => <Consumables {...this.props} name={'materials'} />,
        },
        {
          Name: <span className="border-image">Service Codes</span>,
          Component: () => <ServiceCodes {...this.props} name={'service-codes'} />,
        },
        {
          Name: <span className="border-image">Service Bundles</span>,
          Component: () => <ServiceBundles {...this.props} name={'service-bundles'} />,
        },
      ],
    };
  }

  toggle = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  onTabClick = key => {
    let keyData = parseInt(key);
    let clickedTab = this.state.tabMapping[keyData];
    this.props.history.push(`/cw/manage/master-bill-services?tab=${clickedTab}`);
    this.setState({
      activeKey: parseInt(key),
    });
  };

  componentDidMount(props) {
    const { tabMapping } = this.state;

    var params = new URLSearchParams(this.props.location.search);
    var tab = params.get('tab');
    if (tabMapping) {
      this.setState({
        activeKey: Object.values(tabMapping).indexOf(tab),
      });
    }
  }


  render() {
    const { tabLabels, activeKey } = this.state;
    return (
      <div className="main  mb-5 Announcements-page enrollment-page masterBillOfService">
        {/* <Loader loading={loading} /> */}
        <div className="container-fluid">
          <div className="row top-table-search mt-5 mb-3 align-items-center">
            <div className="col-12">
              <Link to="/cw/manage" className="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link>
              <h3 className="form-main-head mb-3 mb-md-0">Master Bill of Services</h3>
            </div>
          </div>
          <div className="row mb-4">
            <div class="col-12 mb-5">
              <div className="vertical-tabs rcm-group-tab">
                <Tabs
                  activeKey={String(activeKey)}
                  tabPosition="left"
                  defaultActiveKey="0"
                  onTabClick={this.onTabClick}
                >
                  {tabLabels.map((item, index) => {
                    const { Component, Name } = item;
                    return (
                      <TabPane tab={Name} key={`${index}`} className="tab-content">
                        {activeKey == index && (
                          <Component
                            // style={{ padding: '36px 20px 150px 20px' }}
                            dispatch={this.props.dispatch}
                            enableReinitialize={true}
                          />
                        )}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect()(MasterBillOfService));
