import React, { Component } from 'react';
import { Modal } from 'antd';
import TimelineComponent from '../../../timelineComponent';
import FilterModal from '../../timeLineFIlters';
import AntdModal from '../../../../Components/CustomModal';
import { payerPortalAuthorizationStatusUpsert } from '../../../../store/actions';

class AuthorizationDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { isTimelineModalOpen: false };
  }

  openAndCloseModal = () => {
    this.setState({ isTimelineModalOpen: !this.state.isTimelineModalOpen });
    if (this.state.isTimelineModalOpen) {
      this.props.openAndCloseModal();
    }
  };

  authorizationStatusUpsert = (ClientAuthorizationId, IsApproved) => {
    this.props.dispatch(
      payerPortalAuthorizationStatusUpsert({
        JSON: JSON.stringify({
          Authorization: [
            {
              ClientAuthorizationId: ClientAuthorizationId,
              IsApproved: IsApproved,
              SmartContractId: this.props.contractId,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const {
      AuthNumber,
      ServiceCode,
      StartDate,
      EndDate,
      AuthReviewDate,
      VisitApproved,
      VisitRemaining,
      StatusClassName,
      StatusText,
      AuthNote,
      IsApproved,
      ClientAuthorizationId,
    } = this.props.authDetails || {};
    const { smartContractId } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">Authorization Number</p>
            <p className="client-text">{AuthNumber}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">Service Code</p>
            <p className="client-text">{ServiceCode}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">Start Date</p>
            <p className="client-text">{StartDate}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">End Date</p>
            <p className="client-text">{EndDate}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">Review Date</p>
            <p className="client-text">{AuthReviewDate}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">Visits Approved</p>
            <p className="client-text">{VisitApproved}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">Visits Remaining</p>
            <p className="client-text ">{VisitRemaining}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="client-label mb-0">Status</p>
            <p
              className={`client-text client-info-text  ${StatusClassName}`}
              style={{ width: 'fit-content' }}
            >
              {StatusText}
            </p>
          </div>
          <div className="col-12">
            <p className="client-label mb-0">Notes</p>
            <p className="client-text mb-0">{AuthNote}</p>
          </div>
        </div>
        {IsApproved == null && (
          <div
            className="d-flex justify-content-end align-items-center mt-5"
            style={{ textAlign: 'end' }}
          >
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={this.openAndCloseModal}
              style={{ margin: '0 10px' }}
            >
              Request More Information
            </button>

            <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="delete-btn btn-deny d-flex align-items-center"
                  onClick={e => {
                    onClick();
                    e.stopPropagation();
                  }}
                  data-toggle="modal"
                  data-target="#delete-Screen-Tool"
                  style={{ cursor: 'pointer', width: 'fit-content' }}
                >
                  <img src="images/deny.svg" />
                  <p className="mb-0 ml-2">Deny</p>
                </span>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                    <h4 class="modal-title col-12 mb-3">
                      Are you sure you want deny this Authorization?
                    </h4>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      class="btn btn-outline-primary black-btn"
                      onClick={e => {
                        onCancel();
                        e.stopPropagation();
                      }}
                    >
                      No
                    </button>
                    <button
                      class="btn"
                      onClick={() => {
                        this.authorizationStatusUpsert(ClientAuthorizationId, false);
                        onOk();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />

            <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="btn-approved d-flex align-items-center"
                  onClick={e => {
                    onClick();
                    e.stopPropagation();
                  }}
                  data-toggle="modal"
                  data-target="#delete-Screen-Tool"
                  style={{ cursor: 'pointer', width: 'fit-content' }}
                >
                  <img src="images/approve.svg" />
                  <p className="mb-0 ml-2">Approve</p>
                </span>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                    <h4 class="modal-title col-12 mb-3">
                      Are you sure you want approve this Authorization?
                    </h4>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      class="btn btn-outline-primary black-btn"
                      onClick={e => {
                        onCancel();
                        e.stopPropagation();
                      }}
                    >
                      No
                    </button>
                    <button
                      class="btn"
                      onClick={() => {
                        this.authorizationStatusUpsert(ClientAuthorizationId, true);
                        onOk();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        )}

        {IsApproved == true && (
          <div
            className="d-flex justify-content-end align-items-center mt-5"
            style={{ textAlign: 'end' }}
          >
            <a
              type="button"
              className="btn-approved d-flex align-items-center"
              style={{
                width: 'fit-content',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'inherit',
              }}
            >
              <img src="images/approve.svg" />
              <p className="mb-0 ml-2">Approved</p>
            </a>
          </div>
        )}

        {IsApproved == false && (
          <div
            className="d-flex justify-content-end align-items-center mt-5"
            style={{ textAlign: 'end' }}
          >
            <a
              type="button"
              className="btn-deny d-flex align-items-center"
              style={{
                width: 'fit-content',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'inherit',
              }}
            >
              <img src="images/deny.svg" />
              <p className="mb-0 ml-2">Denied</p>
            </a>
          </div>
        )}

        <Modal
          open={this.state.isTimelineModalOpen}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose={true}
        >
          <FilterModal
            ClientId={this.props.ClientId}
            ClientAuthorizationId={ClientAuthorizationId}
            openAndCloseModal={this.openAndCloseModal}
            smartContractId={smartContractId}
          />
        </Modal>
      </div>
    );
  }
}

export default AuthorizationDetailDrawer;
