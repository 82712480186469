import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import { Table, Avatar, Cascader, Button, Popover, Drawer } from 'antd';
import { Checkbox, Radio, Tooltip, Modal, Spin, Alert } from 'antd';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Loader, { Loader2 } from '../../../Components/Loader';
import CustomModal from '../../../core/modal';
import renderHTML from 'react-render-html';
import { Link, withRouter } from 'react-router-dom';
import { mapTime } from '../../../util/time';
import { currencyFormatter } from '../../../util/formatter';
import validate, {
  dateofbirthNormalize,
  normalizeToYYYYMMDD,
  MobileFormatter,
  MobileNormalize,
} from '../validation/validate';
import moment from 'moment';
import AntdModal from '../../../Components/CustomModal';
import { ProcessCombos } from '../../../store/comboConfig';
import {
  CustomReduxInput,
  CustomReduxTextarea,
} from '../../../component/custom-redux-components/customReduxFields';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
  ReduxAutoComplete,
} from '../../../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  // appSubscribersUserSearch,
  upsertAppointment,
  getPatientList,
  practitionerCombosOffice,
  upsertGroupAppointment,
  getClientGroup,
  listCombos,
  resetGetClientGroup,
  recurringCount,
  medicateCombos,
  getGroup,
  validateClient,
  upsertBlockAppointment,
  comboPractitionerAll,
  comboFacilityAll,
  getComboFacility,
  practitionerCombos,
  listSmartCombos,
  resetGetComboFacility,
  appointmentGetClientUpcomingSchedule,
  appointmentNoteList,
  listClientOutstandingBalanceGet,
  appointmentAutoSelectGet,
} from '../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import PersistTableSort from '../../../component/persistTableSort';
import SharedNote from '../../../Components/sharedNotes';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

let eventGuid = 0;

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime =
      values.g_startTime ||
      values.startTime ||
      values.existingPatientStartTime ||
      values.b_StartTime;
    let endTime =
      values.g_endTime || values.endTime || values.existingPatientEndTime || values.b_EndTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  // endTime: (value, values, props, name) => {
  //   let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
  //   let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
  //   if (beginningTime) {
  //     if (beginningTime.isBefore(endTime)) {
  //     } else {
  //       return 'should be greater than Start time';
  //     }
  //   } else {
  //     return 'Not Applicable';
  //   }
  // },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};
const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Mobile app referral sent successfully',
  });
};
const weekDays = [
  { label: 'Mon', value: '2' },
  { label: 'Tue', value: '3' },
  { label: 'Wed', value: '4' },
  { label: 'Thu', value: '5' },
  { label: 'Fri', value: '6' },
  { label: 'Sat', value: '7' },
  { label: 'Sun', value: '1' },
];
const radioStyle = {
  width: '20px',
  height: '20px',
  border: 'solid 1px #bcbcbc',
  marginRight: '5px',
};
const APPOINTMENT_TYPE = [
  {
    type: 'New Patient',
    value: 'R',
  },
  {
    type: 'Existing Patient',
    value: 'V',
  },
  {
    type: 'Group Appointment',
    value: 'G',
  },
  {
    type: 'Block Appointment',
    value: 'B',
  },
];

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

class CreateEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      selected: null,
      searchStr: '',
      selectedStudent: '',
      isExistMsg: null,
      isClientView: true,
      searchedStudent: [],
      searchedClient: {},
      patientRemoved: [],
      comboAppointmentType: [],
      attendeeStatus: {},
      //   todaysDate: new Date(),
      columns: [
        {
          title: 'Image',
          dataIndex: 'ProfileImgUrl',
          key: 'ProfileImgUrl',
          width: '20px',
          render: (text, record) => (
            <div className="student-list-pic">
              <img
                src={text ? text : 'images/announcements-img2.jpg'}
                className="img-circle"
                height="40"
                width="40"
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
            </div>
          ),
        },
        {
          title: 'Patient ID',
          // dataIndex: 'ClientId',
          dataIndex: 'DisplayClientId',

          key: 'ClientId',
          width: '30px',
          // sorter: (a, b) => isComparer(a.ClientId, b.ClientId),
          sorter: (a, b) => isComparer(a.DisplayClientId, b.DisplayClientId),

          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Patient Name',
          dataIndex: 'DisplayName',
          key: 'DisplayName',
          width: '35px',
          sorter: (a, b) => isComparer(a.DisplayName, b.DisplayName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          width: '55px',
          render: (text, record, index) => {
            return (
              <>
                {!this.state.patientRemoved.includes(record.ClientId) ? (
                  <div className="form-group mb-2">
                    <ReduxSelect
                      options={this.props.medicateCombos.Status}
                      onChange={attendeeStatusId => {
                        this.handleAttendeeStatusChange(record.ClientId, attendeeStatusId);
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      value={this.state.attendeeStatus[record.ClientId]}
                      placeholder="Status"
                      disabled={true}
                    />
                  </div>
                ) : (
                  <>Not Applicable</>
                )}
              </>
            );
          },
        },
        {
          title: () => {
            return (
              <>
                <span>
                  Include/
                  <br />
                  Exclude
                </span>
              </>
            );
          },
          dataIndex: 'IsAppointment',
          key: 'IsAppointment',
          width: '20px',
          render: (text, record, index) => {
            return (
              <div style={{ display: 'inline-block', marginTop: '10px' }}>
                {record.isClientInvited ? (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        title="Click to Exclude"
                        style={{ color: '#FF3D57', cursor: 'pointer' }}
                        onClick={onClick}
                      >
                        {!this.state.isDisable && (
                          <img
                            src={'images/circle-minus.svg'}
                            className="img-circle"
                            height="40"
                            width="40"
                            style={{ borderRadius: '50%' }}
                          />
                        )}
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 className="modal-title col-12 mb-3">
                            {`Are you sure you want to remove member from this appointment?`}
                          </h4>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            className="btn black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              let tempGroupClientListState = this.state.GroupClientListState.map(
                                item => {
                                  if (item.ClientId == record.ClientId) {
                                    return { ...item, isClientInvited: false };
                                  } else {
                                    return { ...item };
                                  }
                                },
                              );
                              this.setState({
                                patientRemoved: [...this.state.patientRemoved, record.ClientId],
                                GroupClientListState: tempGroupClientListState,
                              });
                              onOk();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                ) : (
                  <span
                    title="Click to Include"
                    style={{ color: '#09B66D', cursor: 'pointer' }}
                    onClick={() => {
                      let tempGroupClientListState = this.state.GroupClientListState.map(item => {
                        if (item.ClientId == record.ClientId) {
                          return { ...item, isClientInvited: true };
                        } else {
                          return { ...item };
                        }
                      });
                      let newArray = this.state.patientRemoved;

                      newArray.splice(newArray.indexOf(record.ClientId), 1);
                      this.setState({
                        patientRemoved: [...newArray],
                        GroupClientListState: tempGroupClientListState,
                      });
                      // onOk();
                    }}
                  >
                    {!this.state.isDisable && (
                      <img
                        src={'images/circle-plus.svg'}
                        className="img-circle"
                        height="40"
                        width="40"
                        style={{ borderRadius: '50%' }}
                      />
                    )}
                  </span>
                )}

                {/* {!this.state.patientRemoved.includes(record.ClientId) ? (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        title="Click to Exclude"
                        style={{ color: '#FF3D57', cursor: 'pointer' }}
                        onClick={onClick}
                      >
                        {!this.state.isDisable && (
                          <img
                            src={'images/circle-minus.svg'}
                            className="img-circle"
                            height="40"
                            width="40"
                            style={{ borderRadius: '50%' }}
                          />
                        )}
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 className="modal-title col-12 mb-3">
                            {`Are you sure you want to remove member from this appointment?`}
                          </h4>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            className="btn black-btn"
                            onClick={() => {
                              // let invitationStatusOfClients = this.state
                              //   .invitationStatusOfClients;
                              // invitationStatusOfClients[index].IsAppointment = false;
                              // this.setState({
                              //   // patientRemoved: [
                              //   //   ...this.state.patientRemoved,
                              //   //   item.ClientId,
                              //   // ],
                              // });
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              this.setState({
                                patientRemoved: [...this.state.patientRemoved, record.ClientId],
                              });
                              onOk();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                ) : (
                  <span
                    title="Click to Include"
                    style={{ color: '#09B66D', cursor: 'pointer' }}
                    // onClick={() => {
                    //   let newArray = this.state.patientRemoved;

                    //   newArray.splice(newArray.indexOf(item.ClientId), 1);
                    //   this.setState({
                    //     patientRemoved: [...newArray],
                    //   });
                    // }}
                    onClick={() => {
                      let newArray = this.state.patientRemoved;

                      newArray.splice(newArray.indexOf(record.ClientId), 1);
                      this.setState({
                        patientRemoved: [...newArray],
                      });
                      // onOk();
                    }}
                  >
                    {!this.state.isDisable && (
                      <img
                        src={'images/circle-plus.svg'}
                        className="img-circle"
                        height="40"
                        width="40"
                        style={{ borderRadius: '50%' }}
                      />
                    )}
                  </span>
                )} */}
              </div>
            );
          },
        },
      ],
    };
  }
  handleAttendeeStatusChange = (ClientId, attendeeStatusId) => {
    this.setState({
      attendeeStatus: { ...this.state.attendeeStatus, [ClientId]: attendeeStatusId },
    });
  };

  onChangeCascader = (value, selectedOptions) => {
    let extractedData = selectedOptions[0].label;
    this.props.change(`existingPatientReasonForVisit`, extractedData);
  };

  showDrawer = () => {
    this.setState({ drawerState: true });
  };

  onClose = () => {
    this.setState({ drawerState: false });
  };

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  handelGui = () => {
    this.props.GetStudentGui(this.state.selectedStudent);
  };
  componentDidMount() {
    this.props.dispatch(listCombos({ comboTypes: 'AppointmentTypeCenter' }));
    var result = ProcessCombos(
      'RCM_PayerInsurance,RCM_ServiceCode,RCM_PlaceOfService,RCM_Program,CenterServiceType,AppointmentEligibility,AppointmentCoPay',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    // this.props.dispatch(appSubscribersUserSearch({}));
    this.props.dispatch(
      getPatientList({
        Json: JSON.stringify({
          Client: [
            {
              FilterBy: 'Active',
            },
          ],
        }),
      }),
    );
    this.props.dispatch(reset('createEventForm'));
    this.props.dispatch(recurringCount({}));
    this.props.dispatch(practitionerCombosOffice({}));
    this.props.dispatch(getComboFacility({}));

    let medicateComboTypes = {
      ComboTypes: 'Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));

    // this.input.focus();
  }
  componentWillReceiveProps({
    searchedStudent,
    isSuccess,
    errorMessage,
    referralTypeValue,
    AppointmentType,
    isExist,
    isExistMsg,
    GroupClientList,
    placeOfServiceForIndivisualClient,
    placeOfServiceForExistingIndivisualClient,
    placeOfServiceForGroup,
    officeNameForExistingIndividualClient,
    officeNameForNewClient,
    providerNameForExistingIndivisualClient,
    insuranceValue,
    appointmentDateValue,
    newPStartTime,
    newPEndTime,
  }) {
    // if (placeOfServiceForIndivisualClient == 1) {
    //   this.setState({ telehealthMsg1: 'Provider meeting link will be available.' });
    //   setTimeout(() => {
    //     this.setState({
    //       telehealthMsg1: '',
    //     });
    //   }, 2000);
    // }
    // if (placeOfServiceForExistingIndivisualClient == 1) {
    //   this.setState({ telehealthMsg2: 'Provider meeting link will be available.' });
    //   setTimeout(() => {
    //     this.setState({
    //       telehealthMsg2: '',
    //     });
    //   }, 2000);
    // }
    // if (placeOfServiceForGroup == 1) {
    //   this.setState({ telehealthMsg3: 'Provider meeting link will be available.' });
    //   setTimeout(() => {
    //     this.setState({
    //       telehealthMsg3: '',
    //     });
    //   }, 2000);
    // }
    if (
      GroupClientList &&
      JSON.stringify(GroupClientList) != JSON.stringify(this.props.GroupClientList)
    ) {
      let attArray =
        GroupClientList &&
        GroupClientList.map(i => {
          return i.ClientId;
        });
      const tempAttendeeObj = {};
      for (const key of attArray) {
        tempAttendeeObj[key] = 19701;
      }
      this.setState({
        attendeeStatus: tempAttendeeObj,
        GroupClientListState: GroupClientList,
      });
    }
    if (searchedStudent && searchedStudent != this.props.searchedStudent) {
      this.setState({ searchedStudent });
    }
    if (
      !isExist
      // isExist != this.props.isExist
      // JSON.stringify(isExistMsg) != JSON.stringify(this.props.isExistMsg)
    ) {
      this.setState({ isExistMsg });
    }

    if (errorMessage && errorMessage != this.props.errorMessage) {
      this.setState(
        {
          errorMessage: errorMessage,
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorMessage: '',
            });
          }, 5000);
        },
      );
      this.props.dispatch(reset('createEventForm'));
    }
    if (referralTypeValue != this.props.referralTypeValue && this.props.referralTypeValue == 'V') {
      //Group checkbox not got checked if we uncomment reset
      this.setState({ searchedClient: {} });
      this.props.dispatch(
        listSmartCombos({
          comboTypes: 'RCM_ServiceCode,RCM_PayerInsurance',
          json: JSON.stringify({
            Combo: [
              {
                FacilityId:
                  (this.props.initialValues && this.props.initialValues.officeName) || null,
              },
            ],
          }),
        }),
      );
    }
    if (
      AppointmentType &&
      JSON.stringify(AppointmentType) != JSON.stringify(this.props.AppointmentType)
    ) {
      let temp = AppointmentType.map(item => {
        return {
          label: item.Text,
          value: item.Value,
        };
      });
      this.setState({
        comboAppointmentType: temp,
      });
    }
    if (
      (officeNameForExistingIndividualClient &&
        officeNameForExistingIndividualClient !=
          this.props.officeNameForExistingIndividualClient) ||
      (officeNameForNewClient && officeNameForNewClient != this.props.officeNameForNewClient) ||
      (insuranceValue &&
        JSON.stringify(insuranceValue) != JSON.stringify(this.props.insuranceValue)) ||
      (appointmentDateValue &&
        JSON.stringify(appointmentDateValue) != JSON.stringify(this.props.appointmentDateValue)) ||
      (newPStartTime &&
        JSON.stringify(newPStartTime) != JSON.stringify(this.props.newPStartTime)) ||
      (newPEndTime && JSON.stringify(newPEndTime) != JSON.stringify(this.props.newPEndTime))
    ) {
      let data = {
        json: JSON.stringify({
          Combo: [
            {
              FacilityId:
                this.props.initialValues.referralType == 'V'
                  ? officeNameForExistingIndividualClient
                  : officeNameForNewClient,
              PayerId: insuranceValue ? insuranceValue : null,
              IsNewPatient: 1,
              AppointmentDate: moment(appointmentDateValue).format('YYYY-MM-DD'),
              // AppointmentTime: (newPStartTime && moment(newPStartTime).format('HH:mm:ss')) || '',
              AppointmentTime: (newPStartTime && mapTime(newPStartTime)) || '',
              AppointmentEndTime: (newPEndTime && mapTime(newPEndTime)) || '',
            },
          ],
        }),
      };
      this.props.dispatch(practitionerCombos(data));
      this.props.dispatch(
        listSmartCombos({
          comboTypes: 'RCM_ServiceCode',
          json: JSON.stringify({
            Combo: [
              {
                FacilityId: officeNameForExistingIndividualClient || officeNameForNewClient,
              },
            ],
          }),
        }),
      );
    }
    if (
      providerNameForExistingIndivisualClient &&
      providerNameForExistingIndivisualClient != this.props.providerNameForExistingIndivisualClient
    ) {
      let data = {
        json: JSON.stringify({
          Combo: [
            {
              PractitionerId: providerNameForExistingIndivisualClient,
            },
          ],
        }),
      };
      this.props.dispatch(getComboFacility(data));
    }
  }

  getSerachedStudent = () => {
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  // studentSearch = e => {
  //   let { value } = e.target;
  //   this.setState({
  //     searchStr: value,
  //   });
  //   this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  // };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    var data = {
      Client: [
        {
          Search: value,
          FilterBy: 'Active',
        },
      ],
    };
    var appointmentData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(getPatientList(appointmentData));

    // Json - {"Client":[{"Search":"SHARMA"}]}
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };
  createEventId = () => {
    return String(eventGuid++);
  };
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  onhandleSubmit = val => {
    let title = 'Please enter a new title for your event';
    let calendarApi = this.props.selectInfo.view.calendar;
    let dateToBook = moment(val.appointmentDate).format('YYYY-MM-DD');
    // let timeToBook = moment(val.startTime._d).format('HH:mm:ss');
    calendarApi.unselect(); // clear date selection
    // if (val.patientFirstName) {
    //   calendarApi.addEvent({
    //     id: this.createEventId(),
    //     title: `${val.patientFirstName} ${val.patientLastName}`,
    //     start: `${dateToBook}T${timeToBook}`,
    //     backgroundColor: '#4AB0FE',
    //     // end: this.props.selectInfo.endStr,
    //     // allDay: this.props.selectInfo.allDay,
    //   });
    // }
    var data;
    if (val.referralType == 'V') {
      data = {
        Appointment: [
          {
            // AppointmentId: this.createEventId(),
            // Episode: 123,
            // DoctorName: val.patientFirstName,
            ClientId: this.props.initialValues.clientId,
            Title: `${val.existingPatientFirstName}`,
            AppointmentDate: moment(val.appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime:
              (val.existingPatientStartTime &&
                moment(val.existingPatientStartTime._d).format('HH:mm:ss')) ||
              '',
            // AppointmentEndTime:
            //   (val.existingPatientEndTime &&
            //     moment(val.existingPatientEndTime._d).format('HH:mm:ss')) ||
            //   '',
            AppointmentEndTime: mapTime(val.existingPatientEndTime),
            PractitionerId: val.existingPatientProviderName,
            DurationInMin: val.existingPatientDuration,
            OfficeName: val.existingPatientOfficeName,
            ServiceCode: val.existingPatientServiceCode,
            ReasonForVisit: val.existingPatientReasonForVisit,
            Status: val.existingPatientStatus,
            PrimaryPhone: val.existingPatientMobileNumber,
            MiddleName: val.existingPatientMiddleName,
            FirstName: val.existingPatientFirstName,
            LastName: val.existingPatientLastName,
            ProgramId: val.existingPatientProgram,
            AppTypeId: val.existingPatientAppointmentTypeDropdown,
            PlaceOfServiceId: val.existingPlaceOfService,
            ServiceTypeId: val.existingCenterServiceType,
            DateOfBirth: this.state.searchedClient.DateOfBirth,
            // Insurance: val.existingPatientInsurance,
            Eligibility: val.existingPatientEligibility,
            CoPay: val.existingPatientCoPay,
            // SharedNote: val.existingPatientSharedNote,
            // AppointmentTypeId: val.existingAppointmentType,
            IsRecurring: val.recurringAppointment,
            GroupAppointmentRecurring: [
              {
                GroupAppointmentRecurringId: null,
                RecurringType: val.appointmentType,
                WeekDays: val.dayOfWeek && val.dayOfWeek.toString(),
                MonthlyRecurring: val.recurringOccurance || null,
                RecurringCount: val.recurringCount,
              },
            ],
          },
        ],
      };
    } else if (val.referralType == 'R') {
      data = {
        Appointment: [
          {
            // AppointmentId: this.createEventId(),
            // Episode: 123,
            // DoctorName: val.patientFirstName,
            ClientId: null,
            Title: `${val.patientFirstName}`,
            AppointmentDate: moment(val.appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime: (val.startTime && moment(val.startTime._d).format('HH:mm:ss')) || '',
            // AppointmentEndTime: (val.endTime && moment(val.endTime._d).format('HH:mm:ss')) || '',
            DateOfBirth: val.dateOfBirth,
            PayerId: val.payerId,
            Eligibility: val.eligibility,
            CoPay: val.coPay,
            SharedNote: val.sharedNote,
            AppointmentEndTime: mapTime(val.endTime),
            PractitionerId: val.providerName,
            DurationInMin: val.duration,
            OfficeName: val.officeName,
            ServiceCode: val.serviceCode,
            ReasonForVisit: val.reasonForVisit,
            Status: val.status,
            PrimaryPhone: val.mobileNumber,
            MiddleName: val.middleName,
            FirstName: val.patientFirstName,
            LastName: val.patientLastName,
            ProgramId: val.program,
            AppTypeId: val.appointmentTypeDropdown,
            PlaceOfServiceId: val.placeOfService,
            ServiceTypeId: val.centerServiceType,
            IsRecurring: val.recurringAppointment,
            GroupAppointmentRecurring: [
              {
                GroupAppointmentRecurringId: null,
                RecurringType: val.appointmentType,
                WeekDays: val.dayOfWeek && val.dayOfWeek.toString(),
                MonthlyRecurring: val.recurringOccurance || null,
                RecurringCount: val.recurringCount,
              },
            ],
          },
        ],
      };
    } else if (val.referralType == 'G') {
      let removedPatientArray = this.state.patientRemoved.map(item => {
        return {
          ClientId: item,
          IsAppointment: false,
        };
      });
      data = {
        GroupAppointment: [
          {
            // AppointmentId: this.createEventId(),
            // Episode: 123,
            // DoctorName: val.patientFirstName,
            GroupAppointmentId: null,
            GroupId: val.g_group,
            Title: `${val.g_title}`,
            AppointmentDate: moment(val.appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime:
              (val.g_startTime && moment(val.g_startTime._d).format('HH:mm:ss')) || '',
            AppointmentEndTime: mapTime(val.g_endTime),
            // AppointmentEndTime:
            //   (val.g_endTime && moment(val.g_endTime._d).format('HH:mm:ss')) || '',
            PractitionerId: val.g_providerName,
            DurationInMin: val.g_duration,
            OfficeName: val.g_officeName,
            ServiceCode: val.g_serviceCode,
            ReasonForVisit: val.g_reasonForVisit,
            Status: val.g_status,
            ProgramId: val.g_program,
            PrimaryPhone: val.g_mobileNumber,
            PlaceOfServiceId: val.g_placeOfService,
            ClientGroupAppointment: removedPatientArray,
            IsRecurring: val.recurringAppointment,
            GroupAppointmentRecurring: [
              {
                GroupAppointmentRecurringId: null,
                RecurringType: val.appointmentType,
                WeekDays: val.dayOfWeek && val.dayOfWeek.toString(),
                MonthlyRecurring: val.recurringOccurance || null,
                RecurringCount: val.recurringCount,
              },
            ],
          },
        ],
      };
    } else {
      data = {
        GroupAppointment: [
          {
            // AppointmentId: this.createEventId(),
            // Episode: 123,
            // DoctorName: val.patientFirstName,
            GroupAppointmentId: null,
            GroupId: 0,
            Title: `${val.b_title}`,
            AppointmentDate: moment(val.b_appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime:
              (val.b_StartTime && moment(val.b_StartTime._d).format('HH:mm:ss')) || '',
            AppointmentEndTime: mapTime(val.b_EndTime),
            // AppointmentEndTime:
            //   (val.g_endTime && moment(val.g_endTime._d).format('HH:mm:ss')) || '',
            PractitionerId: val.b_providerName,
            OfficeName: val.b_officeLocation,
            Status: 19701,
            IsRecurring: val.b_recurringAppointment,
            GroupAppointmentRecurring: [
              {
                GroupAppointmentRecurringId: null,
                RecurringType: val.b_appointmentType,
                WeekDays: val.b_dayOfWeek && val.b_dayOfWeek.toString(),
                MonthlyRecurring: val.b_recurringOccurance || null,
                RecurringCount: val.b_recurringCount,
              },
            ],
          },
        ],
      };
    }

    var appointmentData = {
      json: JSON.stringify(data),
    };
    this.props.reset('createEventForm');
    this.props.resetSearchClientValues();
    // this.setState({ searchedClient: {} });
    if (val.referralType == 'G') {
      this.props.dispatch(upsertGroupAppointment(appointmentData));
    } else if (val.referralType == 'B') {
      this.props.dispatch(upsertBlockAppointment(appointmentData));
    } else {
      this.props.dispatch(upsertAppointment(appointmentData));
    }
    // this.props.dispatch(reset('createEventForm'));

    this.props.onCancel();
    this.setState({ patientRemoved: [] });
  };

  toggleView = () => {
    this.setState(prevState => ({
      isClientView: !prevState.isClientView,
      inviteMsg: '',
      errorMessage: '',
    }));
  };
  isUserExist = () => {
    const { patientFirstNameValue, middleNameValue, patientLastNameValue } = this.props;
    if (patientFirstNameValue && patientLastNameValue) {
      this.props.dispatch(
        validateClient({
          json: JSON.stringify({
            Client: [
              {
                ClientId: null,
                FirstName: patientFirstNameValue,
                MiddleName: middleNameValue,
                LastName: patientLastNameValue,
              },
            ],
          }),
        }),
      );
    } else {
      return;
    }
  };
  render() {
    const { columns, isExistMsg } = this.state;
    const {
      onOk,
      onCancel,
      isFetching,
      isFetchingVoucher,
      handleSubmit,
      MobileNumberValue,
      EmailValue,
      referralTypeValue,
      dateClicked,
      medicateCombos,
      practitioner,
      practitionerOffice,
      GroupClientList,
      ProgramType,
      recurringAppointmentValue,
      block_recurringAppointmentValue,
      appointmentTypeValue,
      block_appointmentTypeValue,
      appointmentDateValue,
      recurringCount,
      isMedicalComboFetching,
      groupNameCurrentValue2,
      ServiceCodeList,
      PlaceOfService,
      CenterServiceType,
      AppointmentType,
      AppointmentEligibility,
      AppointmentCoPay,
      change,
    } = this.props;
    const { IsGroupHasChildCenterPatient } = this.props.initialValues;
    const { searchStr, searchedStudent, isClientView } = this.state;
    const { Status } = medicateCombos;
    const toogleChange = bool => {
      if (bool) {
        change(`appointmentType`, 'Weekly');
        change(`recurringCount`, '2');
        // fields.forEach((field, index) => {
        //   if (currentIndex !== index) {
        //     change(`substanceUseProfiles[${index}].isDrugOfChoice`, false);
        //   } else {
        //     change(`substanceUseProfiles[${index}].isDrugOfChoice`, true);
        //   }
        // });
      }
    };
    const toogleChange2 = bool => {
      if (bool) {
        change(`b_appointmentType`, 'Weekly');
        change(`b_recurringCount`, '2');
        // fields.forEach((field, index) => {
        //   if (currentIndex !== index) {
        //     change(`substanceUseProfiles[${index}].isDrugOfChoice`, false);
        //   } else {
        //     change(`substanceUseProfiles[${index}].isDrugOfChoice`, true);
        //   }
        // });
      }
    };

    return (
      <div className="modal-content border-0">
        <div className="modal-header flex-wrap border-bottom-0 ">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              this.setState({ patientRemoved: [] });
              onCancel();
            }}
          >
            ×
          </button>
          <h4 className="modal-title col-12 text-left">Appointment</h4>
          <h4 className="modal-title col-12 text-left"></h4>
        </div>
        <div className="modal-body rcm-panel">
          <div style={{ textAlign: 'center' }}>
            {/* <h6 style={{ fontSize: '16px', color: 'rgb(18, 196, 117)' }}>{this.state.inviteMsg}</h6> */}
            <h6 style={{ fontWeight: 'normal', fontSize: '16px', color: 'rgb(253, 191, 94)' }}>
              {isExistMsg}
            </h6>
          </div>
          <div>
            <Loader2 loading={isMedicalComboFetching} />
            <form onSubmit={handleSubmit(this.onhandleSubmit)}>
              {/* <Loader2 loading={isFetchingVoucher} /> */}
              <div className="form-group mb-2 custom-radio px-3">
                {APPOINTMENT_TYPE.map((radioOpt, index) => (
                  <label className="mr-5">
                    {radioOpt.type}
                    <Field
                      name="referralType"
                      component={ReduxInput}
                      type="radio"
                      value={radioOpt.value}
                      style={radioStyle}
                    />
                    <span className="checkmark"></span>
                  </label>
                ))}
              </div>
              {referralTypeValue == 'G' ? (
                <div>
                  <div className="newclientview col-md-12 enrollment-form">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="g_group"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Group Name'}
                            validate={Validator.required}
                            placeholder={'Group Name*'}
                            options={this.props.groupComboData}
                            onChange={val => {
                              this.props.getGroupName(val);
                              this.props.dispatch(
                                getGroup({
                                  json: JSON.stringify({
                                    Group: [
                                      {
                                        GroupId: val,
                                      },
                                    ],
                                  }),
                                }),
                              );
                              this.props.dispatch(
                                getClientGroup({
                                  json: JSON.stringify({
                                    ClientGroup: [
                                      {
                                        GroupId: val,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <CustomReduxInput
                          name="g_title"
                          fieldName={' Group Event Name'}
                          validate={Validator.required}
                          labelText="Group Event Name"
                        />
                      </div>

                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="appointmentDate"
                            type="text"
                            component={ReduxDateInput}
                            errorMessage="Appointment Date Required"
                            label={'Appointment Date*'}
                            validate={Validator.required}
                            minDate={new Date()}
                          />
                          <i className="far fa-calendar-alt form-control-icon" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="g_program"
                            type="text"
                            component={ReduxSelect}
                            errorMessage="Appointment Date Required"
                            placeholder={'Program*'}
                            validate={Validator.required}
                            fieldName={'Program'}
                            options={ProgramType}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="g_startTime"
                            type="text"
                            component={ReduxTimePicker}
                            fieldName={'Start Time'}
                            validate={Validator.required}
                            label={'Start Time*'}
                            minuteStep={5}
                            onChange={val => {
                              change(
                                'g_endTime',
                                moment(val._d)
                                  .add(1, 'hours')
                                  .format('HH:mm:ss'),
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="g_endTime"
                            type="text"
                            component={ReduxTimePicker}
                            fieldName={'End Time'}
                            validate={[Validator.required, Validator.endTime]}
                            label={'End Time*'}
                            minuteStep={5}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="g_officeName"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Office Location'}
                            placeholder={'Office Location*'}
                            validate={Validator.required}
                            options={practitionerOffice}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="g_providerName"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Provider Name'}
                            placeholder={'Provider Name*'}
                            validate={Validator.required}
                            options={practitioner}
                            disabled={IsGroupHasChildCenterPatient}
                            // onChange={val => {
                            //   this.props.dispatch(
                            //     practitionerCombosOffice({
                            //       json: JSON.stringify({
                            //         PractitionerOffice: [
                            //           {
                            //             PractitionerId: val,
                            //           },
                            //         ],
                            //       }),
                            //     }),
                            //   );
                            // }}
                          />
                        </div>
                      </div>
                      <div className="col-6" style={{ position: 'relative' }}>
                        <div className="form-group mb-2">
                          <Field
                            name="g_serviceCode"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Service Code'}
                            placeholder={'Service Code*'}
                            options={ServiceCodeList}
                            validate={Validator.required}
                            disabled={IsGroupHasChildCenterPatient}
                          />
                        </div>
                      </div>
                      <div className="col-6" style={{ position: 'relative' }}>
                        <div className="form-group mb-2">
                          <Field
                            name="g_status"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Status'}
                            placeholder={'Status'}
                            options={Status}
                            onChange={a => {
                              let gAttendeeList = this.props.GroupClientList;
                              let attArray = gAttendeeList.map(i => {
                                return i.ClientId;
                              });
                              const tempAttendeeObj = {};
                              for (const key of attArray) {
                                tempAttendeeObj[key] = a;
                              }
                              this.setState({
                                attendeeStatus: tempAttendeeObj,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group mb-2">
                          <Field
                            name="g_placeOfService"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Place of Service'}
                            placeholder={'Place of Service*'}
                            validate={Validator.required}
                            options={PlaceOfService}
                            disabled={IsGroupHasChildCenterPatient}
                          />
                        </div>
                        {/* { this.state.telehealthMsg3} */}
                      </div>
                      <div className="col-6 mt-3">
                        <div className="form-group mb-2">
                          <Field
                            name="g_reasonForVisit"
                            type="textarea"
                            component={ReduxTextarea}
                            fieldName={'Reason For Visit'}
                          />
                          <label className="placeholder-label" style={{ background: '#fefefe' }}>
                            Reason for Visit
                          </label>
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <div className="form-group">
                          <label
                            className="custom-control-label"
                            for="Recurring-Appointment"
                            style={{ fontSize: '18px' }}
                          >
                            Schedule Multiple Appointments {'   '}
                            <Field
                              name={`recurringAppointment`}
                              type="text"
                              className="custom-control-input not-empty"
                              component={ReduxToggle}
                              onChange={bool => toogleChange(bool)}

                              // disabled={disable}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {referralTypeValue == 'R' ? (
                    <div className="newclientview col-md-12 enrollment-form">
                      <div className="row">
                        <div className="col-sm-6 col-md-4">
                          <CustomReduxInput
                            name="patientFirstName"
                            fieldName={'Patient First Name'}
                            validate={[Validator.required, Validator.requiredInput]}
                            onBlur={this.isUserExist}
                            labelText="Patient First Name*"
                          />
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <CustomReduxInput
                            name="middleName"
                            fieldName={'Middle Name'}
                            labelText="Middle Name"
                            onBlur={this.isUserExist}
                          />
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <CustomReduxInput
                            name="patientLastName"
                            fieldName={'Patient Last Name'}
                            onBlur={this.isUserExist}
                            validate={Validator.required}
                            labelText="Patient Last Name*"
                          />
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <CustomReduxInput
                            name="mobileNumber"
                            validate={validate.NumericMobile}
                            format={MobileFormatter}
                            normalize={MobileNormalize}
                            fieldName={'Mobile Number'}
                            labelText="Mobile Number"
                          />
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="dateOfBirth"
                              type="text"
                              component={ReduxDateInput}
                              errorMessage="Date of Birth Required"
                              label={'Date of Birth *'}
                              validate={Validator.required}
                              maxDate={new Date()}
                            />
                            <i className="far fa-calendar-alt form-control-icon" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="payerId"
                              type="text"
                              errorMessage="Insurance Required"
                              placeholder={'Insurance'}
                              fieldName={'Insurance'}
                              component={ReduxAutoComplete}
                              options={this.props.PayerInsurance}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="eligibility"
                              type="text"
                              component={ReduxSelect}
                              errorMessage="Eligibility Required"
                              placeholder={'Eligibility'}
                              // label={'Eligibility*'}
                              fieldName={'Eligibility'}
                              // validate={Validator.required}
                              options={AppointmentEligibility}
                              // fieldName={'Appointment Date'}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="coPay"
                              type="text"
                              component={ReduxSelect}
                              errorMessage="Co-pay of Patient Required"
                              placeholder={'Co-pay'}
                              // label={'Co-pay*'}
                              fieldName={'Co-pay'}
                              // validate={Validator.required}
                              options={AppointmentCoPay}
                              // fieldName={'Appointment Date'}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="appointmentDate"
                              type="text"
                              component={ReduxDateInput}
                              errorMessage="Appointment Date Required"
                              label={'Appointment Date*'}
                              validate={Validator.required}
                              minDate={new Date()}
                            />
                            <i
                              className="far fa-calendar-alt form-control-icon"
                              // style={{ paddingRight: '11px' }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="program"
                              type="text"
                              component={ReduxSelect}
                              errorMessage="Appointment Date Required"
                              placeholder={'Program*'}
                              // label={'Program*'}
                              fieldName={'Program'}
                              validate={Validator.required}
                              options={ProgramType}
                              // fieldName={'Appointment Date'}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="appointmentTypeDropdown"
                              type="text"
                              component={ReduxSelect}
                              placeholder={'Appointment Type'}
                              // label={'Program*'}
                              fieldName={'Appointment Type'}
                              // validate={Validator.required}
                              options={this.props.AppointmentTypeDropdown}
                              onChange={val => {
                                this.props.dispatch(
                                  appointmentAutoSelectGet({
                                    Json: JSON.stringify({
                                      Appointment: [
                                        {
                                          AppointmentTypeId: val,
                                        },
                                      ],
                                    }),
                                    newAppointment: true,
                                  }),
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="startTime"
                              type="text"
                              component={ReduxTimePicker}
                              fieldName={'Start Time'}
                              validate={Validator.required}
                              label={'Start Time*'}
                              minuteStep={5}
                              onChange={val => {
                                change(
                                  'endTime',
                                  moment(val._d)
                                    .add(1, 'hours')
                                    .format('HH:mm:ss'),
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="endTime"
                              type="text"
                              component={ReduxTimePicker}
                              fieldName={'End Time'}
                              validate={[Validator.required, Validator.endTime]}
                              label={'End Time*'}
                              minuteStep={5}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="officeName"
                              type="text"
                              component={ReduxSelect}
                              fieldName={'Office Location'}
                              placeholder={'Office Location*'}
                              validate={Validator.required}
                              options={practitionerOffice}
                            />
                            {/* <label className="placeholder-label"></label> */}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="providerName"
                              type="text"
                              component={ReduxSelect}
                              fieldName={'Provider Name'}
                              placeholder={'Provider Name*'}
                              validate={Validator.required}
                              options={practitioner}
                              // onChange={val => {
                              //   this.props.dispatch(
                              //     practitionerCombosOffice({
                              //       json: JSON.stringify({
                              //         PractitionerOffice: [
                              //           {
                              //             PractitionerId: val,
                              //           },
                              //         ],
                              //       }),
                              //     }),
                              //   );
                              // }}
                            />
                            {/* <label className="placeholder-label"></label> */}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="serviceCode"
                              type="text"
                              component={ReduxSelect}
                              fieldName={'Service Code'}
                              placeholder={'Service Code*'}
                              validate={Validator.required}
                              options={ServiceCodeList}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="status"
                              type="text"
                              component={ReduxSelect}
                              fieldName={'Status'}
                              placeholder={'Status'}
                              options={Status}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="placeOfService"
                              type="text"
                              component={ReduxSelect}
                              fieldName={'Place of Service'}
                              placeholder={'Place of Service*'}
                              validate={Validator.required}
                              options={PlaceOfService}
                            />
                          </div>

                          {/* { this.state.telehealthMsg1} */}
                        </div>
                        {/* <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="centerServiceType"
                              type="text"
                              component={ReduxSelect}
                              fieldName={'Service Type'}
                              placeholder={'Service Type*'}
                              validate={Validator.required}
                              options={CenterServiceType}
                            />
                          </div>
                        </div> */}
                        <div className="col-6 mt-3">
                          <div className="form-group mb-2">
                            <Field
                              name="reasonForVisit"
                              type="textarea"
                              component={ReduxTextarea}
                              fieldName={'Reason For Visit'}
                            />
                            <label className="placeholder-label" style={{ background: '#fefefe' }}>
                              Reason for Visit
                            </label>
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <div className="form-group mb-2">
                            <Field
                              name="sharedNote"
                              type="textarea"
                              component={ReduxTextarea}
                              fieldName={'Shared Note'}
                            />
                            <label className="placeholder-label" style={{ background: '#fefefe' }}>
                              Shared Memo
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <div className="form-group">
                            <label
                              className="custom-control-label"
                              for="Recurring-Appointment"
                              style={{ fontSize: '18px' }}
                            >
                              Schedule Multiple Appointments {'   '}
                              <Field
                                name={`recurringAppointment`}
                                type="text"
                                className="custom-control-input not-empty"
                                component={ReduxToggle}
                                onChange={bool => toogleChange(bool)}
                                // disabled={disable}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {referralTypeValue == 'V' ? (
                        <div className="subscriberview col-md-12 enrollment-form ">
                          <div className="w-100">
                            <div
                              className="form-group has-search searchDiv"
                              style={{ width: '75%', margin: '0 auto' }}
                            >
                              <Autocomplete
                                // getItemValue={item => `${item[SearchOption[TabName].idKey]}`}
                                getItemValue={item => {
                                  return JSON.stringify(item.DisplayClientId);
                                }}
                                // items={SearchOption[TabName].SearchOption.map(item => {
                                //   return {
                                //     label: item.DisplayName,
                                //     ...item,
                                //   };
                                // })}
                                items={this.props.client.map(item => {
                                  return {
                                    label: ` ${item.FirstName} ( ${item.DisplayClientId} )`,
                                    ...item,
                                  };
                                })}
                                // renderItem={(item, isHighlighted) => {
                                //   return (
                                //     <div
                                //       key={item[SearchOption[TabName].idKey]}
                                //       style={{
                                //         margin: '5px',
                                //         background: isHighlighted ? 'lightgray' : 'white',
                                //       }}
                                //     >
                                //       {item.label}
                                //     </div>
                                //   );
                                // }}
                                renderItem={(item, isHighlighted) => (
                                  <div
                                    style={{
                                      background: isHighlighted ? 'lightgray' : 'white',
                                      padding: '10px 5px',
                                      fontSize: '16px',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {renderHTML(
                                      `<img className="autoSelectImage" src=${
                                        item.ProfileImgUrl
                                          ? item.ProfileImgUrl
                                          : 'images/blank-profile.png'
                                      }  width="40" height="40">`,
                                    )}
                                    &nbsp; &nbsp;{' '}
                                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                                      {item.LastName}
                                    </span>
                                    ,&nbsp; <span style={{ color: 'black' }}>{item.FirstName}</span>{' '}
                                    &nbsp;
                                    <span style={{ color: 'grey' }}>({item.DisplayClientId})</span>
                                  </div>
                                )}
                                // value={value}
                                // onChange={e => (value = e.target.value)}
                                // onSelect={val => (value = val)}

                                onChange={this.studentSearch}
                                ref={ref => (this.input = ref)}
                                // onChange={e => {
                                //   this.setState({ searchStr: e.target.value });
                                //   this.props.dispatch(
                                //     SearchOption[TabName].dispatchMethod({ SearchStr: e.target.value }),
                                //   );
                                // }}

                                onSelect={value => {
                                  if (this.props.client.length > 0) {
                                    let searchResult = this.props.client.filter(item => {
                                      let Value = value.replace(/['"]+/g, '');
                                      if (item.DisplayClientId == Value) {
                                        return item;
                                      } else {
                                        return null;
                                      }
                                    });
                                    this.props.setSearchClientValues(searchResult[0]);
                                    let data = {
                                      json: JSON.stringify({
                                        Combo: [
                                          {
                                            ClientId: searchResult[0].ClientId,
                                          },
                                        ],
                                      }),
                                    };
                                    this.props.dispatch(practitionerCombos(data));
                                    let data2 = {
                                      json: JSON.stringify({
                                        Appointment: [
                                          {
                                            ClientId: searchResult[0].ClientId,
                                          },
                                        ],
                                      }),
                                    };
                                    this.props.dispatch(
                                      appointmentGetClientUpcomingSchedule(data2),
                                    );

                                    let data4 = {
                                      json: JSON.stringify({
                                        Client: [
                                          {
                                            ClientId: searchResult[0].ClientId,
                                          },
                                        ],
                                      }),
                                    };
                                    // this.props.dispatch(listClientOutstandingBalanceGet(data4));
                                    this.props.dispatch(getComboFacility(data));
                                    this.props.dispatch(
                                      listSmartCombos({
                                        comboTypes:
                                          'RCM_ServiceCode,RCM_PlaceOfService,RCM_Program,CenterServiceType,AppointmentEligibility,AppointmentCoPay',
                                        json: JSON.stringify({
                                          Combo: [
                                            {
                                              ClientId: searchResult[0].ClientId,
                                            },
                                          ],
                                        }),
                                      }),
                                    );
                                    //  this.props.dispatch(comboPractitionerAll(data));
                                    //  this.props.dispatch(comboFacilityAll(data));
                                    this.setState({
                                      searchedClient: searchResult[0],
                                    });
                                  } else {
                                    return [];
                                  }

                                  // this.props.history.push(SearchOption[TabName].pushKey + value);
                                }}
                                value={this.state.searchStr}
                                // wrapperStyle={{ display: 'unset' }}
                                renderInput={props => (
                                  <input
                                    type="text"
                                    {...props}
                                    className="form-control shadow-sm search-placeholder"
                                    placeholder="Search Patient"
                                    style={{
                                      paddingLeft: '30px',
                                      background: 'rgb(255, 255, 255)',
                                      boxShadow: 'rgba(0, 0, 0, 0.043) 0px 7px 28px',
                                      borderRadius: ' 26px',
                                      minHeight: '46px',
                                    }}
                                  />
                                )}
                                menuStyle={{
                                  borderRadius: '3px',
                                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                  background: 'rgba(255, 255, 255, 0.9)',
                                  padding: searchStr ? '4px 4px' : '0px 4px',
                                  fontSize: '90%',
                                  position: 'fixed',
                                  overflow: 'auto',
                                  maxHeight: '300px',
                                  zIndex: 1,
                                  position: 'absolute',
                                  left: 'unset',
                                  top: 'unset',
                                }}
                              />
                              <i
                                className="fas fa-search form-control-icon"
                                style={{ top: '15px', right: '10px' }}
                              />
                            </div>
                          </div>

                          <SharedNote searchedClient={this.state.searchedClient} />
                          {this.state.searchedClient && this.state.searchedClient.ClientId ? (
                            <div className="row mt-1 mb-1">
                              <div className="col-md-6">
                                <div
                                  className="mt-4 mb-4 appointment-slider"
                                  style={{ margin: '0 auto', width: '90%' }}
                                >
                                  <div className="row">
                                    {' '}
                                    <div
                                      className="col-md-12 bold text-center"
                                      style={{
                                        color: '#80808078',
                                        fontSize: '18px',
                                        marginBottom: '18px',
                                      }}
                                    >
                                      Previous Appointments
                                    </div>
                                  </div>

                                  <Slider {...settings}>
                                    {this.props.oldAppointmentList &&
                                    this.props.oldAppointmentList.length > 0 ? (
                                      this.props.oldAppointmentList.map((item, index) => {
                                        const {
                                          AppointmentDate,
                                          AppointmentEndTime,
                                          AppointmentTime,
                                          ProviderName,
                                          Text,
                                        } = item;
                                        return (
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-12 pb-4">{Text}</div>
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="text-center">
                                        <span style={{ color: 'rgb(149, 149, 149)' }}>
                                          <i class="fa fa-info-circle" aria-hidden="true"></i> Note
                                          : No Previous Appointments available.
                                        </span>
                                      </div>
                                    )}
                                  </Slider>
                                </div>
                              </div>
                              <div
                                className="col-md-6"
                                style={{ borderLeft: '2px solid rgba(128, 128, 128, 19%)' }}
                              >
                                <div
                                  className="mt-4 mb-5 appointment-slider"
                                  style={{ margin: '0 auto', width: '90%' }}
                                >
                                  <div className="row">
                                    {' '}
                                    <div
                                      className="col-md-12 bold text-center"
                                      style={{
                                        color: '#80808078',
                                        fontSize: '18px',
                                        marginBottom: '18px',
                                      }}
                                    >
                                      Upcoming Appointments
                                    </div>
                                  </div>

                                  <Slider {...settings}>
                                    {this.props.upcomingAppointmentList &&
                                    this.props.upcomingAppointmentList.length > 0 ? (
                                      this.props.upcomingAppointmentList.map((item, index) => {
                                        const {
                                          AppointmentDate,
                                          AppointmentEndTime,
                                          AppointmentTime,
                                          ProviderName,
                                          Text,
                                        } = item;
                                        return (
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-12 pb-4">{Text}</div>
                                            </div>
                                            {/* <div className="row">
                                          <div className="col-md-6">
                                            {' '}
                                            <div className="row">
                                              <div className="col-md-6 bold">Provider Name</div>
                                              <div className="col-md-6">{ProviderName}</div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="row">
                                              <div className="col-md-6 bold">Appointment Date</div>
                                              <div className="col-md-6">{AppointmentDate}</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            {' '}
                                            <div className="row">
                                              <div className="col-md-6 bold">Start Time</div>
                                              <div className="col-md-6">{AppointmentTime}</div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="row">
                                              <div className="col-md-6 bold">End Time</div>
                                              <div className="col-md-6">{AppointmentEndTime}</div>
                                            </div>
                                          </div>
                                        </div> */}
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="text-center">
                                        <span style={{ color: 'rgb(149, 149, 149)' }}>
                                          {' '}
                                          <i class="fa fa-info-circle" aria-hidden="true"></i> Note
                                          : No Upcoming Appointments available.
                                        </span>
                                      </div>
                                    )}
                                  </Slider>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="row">
                            {
                              <div
                                className="col-12 col-md-12 d-none"
                                style={{ height: '200px', overflowY: 'auto' }}
                              >
                                <Loader2 loading={isFetching} />
                                {searchedStudent.length > 0 ? (
                                  searchedStudent.map(item => {
                                    return (
                                      <div className="col-6 d-inline-block">
                                        <div
                                          className="d-flex justify-content-between m-1 align-items-center"
                                          style={{
                                            boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.06)',
                                            paddingBottom: '10px',
                                            marginBottom: '5px',
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            <Avatar
                                              size="large"
                                              className="mr-2"
                                              // icon={<i className="fas fa-users"></i>}
                                              // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                              src={item.ProfileImageUrl}
                                            />
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1,
                                              }}
                                            >
                                              <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                                {item.DisplayName}
                                              </span>
                                              {/* <span style={{ fontSize: '12px', color: 'gray' }}>March 25, 2020</span> */}
                                            </div>
                                          </div>
                                          <div>
                                            <Checkbox
                                              checked={this.state.selected === item.UserGuid}
                                              onChange={() => {
                                                this.setState({
                                                  selected: item.UserGuid,
                                                  selectedStudent: item,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <>
                                    <div className="col-12">
                                      <h5 className="text-center">No Record </h5>
                                    </div>
                                  </>
                                )}
                              </div>
                            }
                          </div>
                          {this.state.searchedClient && this.state.searchedClient.ClientId && (
                            <div
                              className="row pb-4 pt-4"
                              style={{
                                transition: 'width 2s',
                              }}
                            >
                              <div className="col-3 d-flex justify-content-start align-items-center">
                                <img
                                  src="images/userP.svg"
                                  style={{ height: '27', padding: '10px 0px', cursor: 'pointer' }}
                                />
                                &nbsp;
                                {this.state.searchedClient && this.state.searchedClient.FirstName}
                              </div>
                              <div className="col-3 d-flex justify-content-start align-items-center">
                                <img
                                  src="images/idP.svg"
                                  style={{ height: '27', padding: '10px 0px', cursor: 'pointer' }}
                                />
                                &nbsp;
                                {this.state.searchedClient &&
                                  this.state.searchedClient.DisplayClientId}
                              </div>
                              <div className="col-3 d-flex justify-content-start align-items-center">
                                <img
                                  src="images/icons/birthday.svg"
                                  style={{ height: '27', padding: '10px 0px' }}
                                />
                                &nbsp;
                                {this.state.searchedClient &&
                                  this.state.searchedClient.DateOfBirth && (
                                    <>
                                      {moment(this.state.searchedClient.DateOfBirth).format(
                                        'DD MMM, YYYY',
                                      )}
                                    </>
                                  )}
                              </div>
                              <div className="col-3 d-flex justify-content-start align-items-center">
                                <Tooltip placement="top" title="Payer Name">
                                  <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                      src="images/insurance-svg.svg"
                                      style={{
                                        height: '27',
                                        padding: '10px 0px',
                                        cursor: 'pointer',
                                        width: '24px',
                                      }}
                                    />
                                    &nbsp;
                                    {this.state.searchedClient &&
                                      this.state.searchedClient.PayerName}
                                  </span>
                                </Tooltip>
                              </div>
                              <div className="col-3 d-none">
                                <img
                                  src="images/idP.svg"
                                  style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                                />
                                {this.state.searchedClient && this.state.searchedClient.BalanceDue && (
                                  <>
                                    Balance Due &nbsp;{' '}
                                    <span
                                      style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                      }}
                                      onClick={this.showDrawer}
                                    >
                                      {this.state.searchedClient.BalanceDue &&
                                        currencyFormatter.format(
                                          this.state.searchedClient.BalanceDue,
                                        )}
                                      <i class="fa fa-external-link" aria-hidden="true"></i>
                                    </span>
                                    <Drawer
                                      className="custom-drawer"
                                      title="Outstanding Balance of Patient"
                                      placement="right"
                                      onClose={this.onClose}
                                      open={this.state.drawerState}
                                      width="800px"
                                    >
                                      <div className="container">
                                        <div className="row">
                                          <div
                                            className="col-md-12"
                                            style={{
                                              fontSize: '24px',
                                            }}
                                          >
                                            {this.props.clientOutStandingBalanceData.ClientName}
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-md-4">
                                            <i
                                              class="fa fa-phone"
                                              aria-hidden="true"
                                              style={{
                                                transform: 'scaleX(-1)',
                                                MozTransform: 'scaleX(-1)',
                                                WebkitTransform: 'scaleX(-1)',
                                                MsTransform: 'scaleX(-1)',
                                              }}
                                            ></i>{' '}
                                            &nbsp;
                                            {this.props.clientOutStandingBalanceData.PrimaryPhone}
                                          </div>{' '}
                                          <div className="col-md-4">
                                            <i class="fa fa-birthday-cake" aria-hidden="true"></i>{' '}
                                            &nbsp;
                                            <span style={{ fontSize: '14px', color: 'grey' }}>
                                              {this.props.clientOutStandingBalanceData.DateOfBirth
                                                ? this.props.clientOutStandingBalanceData
                                                    .DateOfBirth
                                                : 'Not Available'}
                                            </span>
                                          </div>{' '}
                                          <div className="col-md-4">
                                            Eligibility &nbsp;
                                            <span style={{ fontSize: '14px', color: 'grey' }}>
                                              {this.props.clientOutStandingBalanceData.Eligibilit
                                                ? this.props.clientOutStandingBalanceData.Eligibilit
                                                : 'Not Available'}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="row mt-2 mb-2">
                                          <div
                                            className="col-md-8 bold"
                                            style={{ fontSize: '18px' }}
                                          >
                                            Details
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-8">Total Medical Expenses</div>
                                          <div className="col-md-4">
                                            {this.props.clientOutStandingBalanceData
                                              .TotalMedicalExpenses &&
                                              currencyFormatter.format(
                                                this.props.clientOutStandingBalanceData
                                                  .TotalMedicalExpenses,
                                              )}
                                          </div>
                                        </div>{' '}
                                        <div className="row">
                                          <div className="col-md-8">Payment Done By Payer</div>
                                          <div className="col-md-4">
                                            {this.props.clientOutStandingBalanceData
                                              .PaymentDoneByPayer &&
                                              currencyFormatter.format(
                                                this.props.clientOutStandingBalanceData
                                                  .PaymentDoneByPayer,
                                              )}
                                          </div>
                                        </div>{' '}
                                        <div className="row">
                                          <div className="col-md-8">Co-Pay Payment</div>
                                          <div className="col-md-4">
                                            {this.props.clientOutStandingBalanceData.CoPayPayment &&
                                              currencyFormatter.format(
                                                this.props.clientOutStandingBalanceData
                                                  .CoPayPayment,
                                              )}
                                          </div>
                                        </div>{' '}
                                        <div
                                          className="row mt-2 pt-2"
                                          style={{ borderTop: '2px solid #0000003d' }}
                                        >
                                          <div className="col-md-8 bold">Balance Due</div>
                                          <div
                                            className="col-md-4 bold"
                                            style={{ fontSize: '16px' }}
                                          >
                                            {this.props.clientOutStandingBalanceData.BalanceDue &&
                                              currencyFormatter.format(
                                                this.props.clientOutStandingBalanceData.BalanceDue,
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </Drawer>
                                  </>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="row">
                            <div className="col-sm-6 col-md-4">
                              <CustomReduxInput
                                name="existingPatientFirstName"
                                fieldName={'Patient First Name'}
                                validate={Validator.required}
                                disabled={true}
                                labelText="Patient First Name*"
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <CustomReduxInput
                                name="existingPatientMiddleName"
                                fieldName={'Middle Name'}
                                disabled={true}
                                labelText="Middle Name"
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <CustomReduxInput
                                name="existingPatientLastName"
                                fieldName={'Patient Last Name'}
                                validate={Validator.required}
                                disabled={true}
                                labelText="Patient Last Name*"
                              />
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <CustomReduxInput
                                name="existingPatientMobileNumber"
                                fieldName={'Mobile Number'}
                                disabled={true}
                                validate={validate.NumericMobile}
                                format={MobileFormatter}
                                normalize={MobileNormalize}
                                labelText="Mobile Number"
                              />
                            </div>
                            {/* <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientInsurance"
                                  type="text"
                                  errorMessage="Insurance Required"
                                  placeholder={'Insurance'}
                                  fieldName={'existingPatientInsurance'}
                                  component={ReduxAutoComplete}
                                  options={this.props.PayerInsurance}
                                />
                              </div>
                            </div> */}
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientEligibility"
                                  type="text"
                                  component={ReduxSelect}
                                  errorMessage="Eligibility Required"
                                  placeholder={'Eligibility'}
                                  // label={'Eligibility*'}
                                  fieldName={'Eligibility'}
                                  //validate={Validator.required}
                                  options={AppointmentEligibility}
                                  // fieldName={'Appointment Date'}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientCoPay"
                                  type="text"
                                  component={ReduxSelect}
                                  errorMessage="Co-pay of Patient Required"
                                  placeholder={'Co-pay'}
                                  // label={'Co-pay*'}
                                  fieldName={'Co-pay'}
                                  //validate={Validator.required}
                                  options={AppointmentCoPay}
                                  // fieldName={'Appointment Date'}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="appointmentDate"
                                  type="text"
                                  component={ReduxDateInput}
                                  errorMessage="Appointment Date Required"
                                  label={'Appointment Date*'}
                                  validate={Validator.required}
                                  // fieldName={'Appointment Date'}
                                  minDate={new Date()}
                                />
                                <i
                                  className="far fa-calendar-alt form-control-icon"
                                  // style={{ paddingRight: '11px' }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientProgram"
                                  type="text"
                                  component={ReduxSelect}
                                  errorMessage="Appointment Date Required"
                                  placeholder={'Program*'}
                                  // label={'Program*'}
                                  fieldName={'Program'}
                                  validate={Validator.required}
                                  options={ProgramType}
                                  // fieldName={'Appointment Date'}
                                  // minDate={new Date(2016, 0, 1)}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientAppointmentTypeDropdown"
                                  type="text"
                                  component={ReduxSelect}
                                  placeholder={'Appointment Type'}
                                  // label={'Program*'}
                                  fieldName={'Appointment Type'}
                                  // validate={Validator.required}
                                  options={this.props.AppointmentTypeDropdown}
                                  // fieldName={'Appointment Date'}
                                  onChange={val => {
                                    this.props.dispatch(
                                      appointmentAutoSelectGet({
                                        Json: JSON.stringify({
                                          Appointment: [
                                            {
                                              AppointmentTypeId: val,
                                              ClientId:
                                                this.props.initialValues &&
                                                this.props.initialValues.clientId,
                                            },
                                          ],
                                        }),
                                        newAppointment: false,
                                      }),
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientStartTime"
                                  type="text"
                                  component={ReduxTimePicker}
                                  fieldName={'Start Time'}
                                  validate={Validator.required}
                                  label={'Start Time*'}
                                  minuteStep={5}
                                  onChange={val => {
                                    change(
                                      'existingPatientEndTime',
                                      moment(val._d)
                                        .add(1, 'hours')
                                        .format('HH:mm:ss'),
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientEndTime"
                                  type="text"
                                  component={ReduxTimePicker}
                                  fieldName={'End Time'}
                                  validate={[Validator.required, Validator.endTime]}
                                  label={'End Time*'}
                                  minuteStep={5}
                                />
                                {/* <label className="placeholder-label">Start Time</label> */}
                                {/* <i className="far fa-clock form-control-icon" /> */}
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientOfficeName"
                                  type="text"
                                  component={ReduxSelect}
                                  fieldName={'Office Location'}
                                  placeholder={'Office Location*'}
                                  validate={Validator.required}
                                  options={practitionerOffice}
                                />
                                {/* <label className="placeholder-label"></label> */}
                              </div>
                            </div>{' '}
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientProviderName"
                                  type="text"
                                  component={ReduxSelect}
                                  fieldName={'Provider Name'}
                                  placeholder={'Provider Name*'}
                                  validate={Validator.required}
                                  options={practitioner}
                                  // onChange={val => {
                                  //   this.props.dispatch(
                                  //     practitionerCombosOffice({
                                  //       json: JSON.stringify({
                                  //         PractitionerOffice: [
                                  //           {
                                  //             PractitionerId: val,
                                  //           },
                                  //         ],
                                  //       }),
                                  //     }),
                                  //   );
                                  // }}
                                />
                                {/* <label className="placeholder-label"></label> */}
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientServiceCode"
                                  type="text"
                                  component={ReduxSelect}
                                  fieldName={'Service Code'}
                                  placeholder={'Service Code*'}
                                  validate={Validator.required}
                                  options={ServiceCodeList}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientStatus"
                                  type="text"
                                  component={ReduxSelect}
                                  fieldName={'Status'}
                                  placeholder={'Status'}
                                  // validate={Validator.required}
                                  options={Status}
                                />
                                {/* <label className="placeholder-label">Status</label> */}
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPlaceOfService"
                                  type="text"
                                  component={ReduxSelect}
                                  fieldName={'Place of Service'}
                                  placeholder={'Place of Service*'}
                                  validate={Validator.required}
                                  options={PlaceOfService}
                                />
                              </div>
                              {/* { this.state.telehealthMsg2} */}
                            </div>
                            {/* <div className="col-sm-6 col-md-4">
                          <div className="form-group mb-2">
                            <Field
                              name="existingCenterServiceType"
                              type="text"
                              component={ReduxSelect}
                              fieldName={'Service Type'}
                              placeholder={'Service Type*'}
                              validate={Validator.required}
                              options={CenterServiceType}
                            />
                          </div>
                        </div> */}
                            <div className="col-sm-6 col-md-4">
                              {' '}
                              <div className="d-flex">
                                <div className="form-group mb-2" style={{ flexGrow: '8' }}>
                                  <Field
                                    name="existingPatientReasonForVisit"
                                    type="textarea"
                                    component={ReduxTextarea}
                                    fieldName={'Reason For Visit'}
                                  />
                                  <label
                                    className="placeholder-label"
                                    style={{ background: '#fefefe' }}
                                  >
                                    Reason for Visit
                                  </label>
                                </div>
                                <div style={{ flexGrow: '1', marginLeft: '5px' }}>
                                  <Cascader
                                    options={
                                      this.props.AppointmentTypeDropDown
                                        ? this.props.AppointmentTypeDropDown
                                        : []
                                    }
                                    onChange={this.onChangeCascader}
                                  >
                                    <a href="#" style={{ color: 'grey' }}>
                                      {/* <Icon type="down-circle" /> */}
                                      <i className="fas fa-chevron-circle-down"></i>
                                    </a>
                                  </Cascader>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-8">
                              <div className="form-group mb-2">
                                <Field
                                  name="existingPatientSharedNote"
                                  type="textarea"
                                  component={ReduxTextarea}
                                  fieldName={'Shared Note'}
                                />
                                <label
                                  className="placeholder-label"
                                  style={{ background: '#fefefe' }}
                                >
                                  Shared Note
                                </label>
                              </div>
                            </div> */}
                            <div className="col-sm-6 col-md-4">
                              <div className="form-group mb-2">
                                {this.state.searchedClient &&
                                  this.state.searchedClient.CenterServiceTypeText && (
                                    <>
                                      <div
                                        style={{
                                          zIndex: '1',
                                          fontSize: '11px',
                                          top: '-5px',
                                          position: 'absolute',
                                        }}
                                      >
                                        {' '}
                                        Service Type
                                      </div>

                                      <div
                                        className="mt-2 mb-2"
                                        style={{
                                          fontSize: '16px',
                                          color: 'black',
                                          position: 'relative',
                                          top: '15px',
                                          left: '4px',
                                        }}
                                      >
                                        {' '}
                                        {this.state.searchedClient.CenterServiceTypeText}
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                            <div className="col-12 mt-3">
                              <div className="form-group">
                                <label
                                  className="custom-control-label"
                                  for="Recurring-Appointment"
                                  style={{ fontSize: '18px' }}
                                >
                                  Schedule Multiple Appointments {'   '}
                                  <Field
                                    name={`recurringAppointment`}
                                    type="text"
                                    className="custom-control-input not-empty"
                                    component={ReduxToggle}
                                    onChange={bool => toogleChange(bool)}
                                    // disabled={disable}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {' '}
                          <div className="enrollment-form block-appointment">
                            {' '}
                            <div className="row">
                              <div className="col-6">
                                <CustomReduxInput
                                  name="b_title"
                                  fieldName={'Title'}
                                  validate={Validator.required}
                                  labelText="Title*"
                                />
                              </div>
                              <div className="col-6">
                                <div className="form-group mb-2">
                                  <Field
                                    name="b_appointmentDate"
                                    type="text"
                                    component={ReduxDateInput}
                                    errorMessage="Appointment Date Required"
                                    label={'Appointment Date*'}
                                    validate={Validator.required}
                                    // fieldName={'Appointment Date'}
                                    minDate={new Date()}
                                  />
                                  <i
                                    className="far fa-calendar-alt form-control-icon"
                                    // style={{ paddingRight: '11px' }}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group mb-2">
                                  <Field
                                    name="b_StartTime"
                                    type="text"
                                    component={ReduxTimePicker}
                                    fieldName={'Start Time'}
                                    validate={Validator.required}
                                    label={'Start Time*'}
                                    minuteStep={5}
                                    onChange={val => {
                                      change(
                                        'b_EndTime',
                                        moment(val._d)
                                          .add(1, 'hours')
                                          .format('HH:mm:ss'),
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group mb-2">
                                  <Field
                                    name="b_EndTime"
                                    type="text"
                                    component={ReduxTimePicker}
                                    fieldName={'End Time'}
                                    validate={[Validator.required, Validator.endTime]}
                                    label={'End Time*'}
                                    minuteStep={5}
                                  />
                                  {/* <label className="placeholder-label">Start Time</label> */}
                                  {/* <i className="far fa-clock form-control-icon" /> */}
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group mb-2">
                                  <Field
                                    name="b_officeLocation"
                                    type="text"
                                    component={ReduxSelect}
                                    fieldName={'Office Location'}
                                    placeholder={'Office Location*'}
                                    validate={Validator.required}
                                    options={practitionerOffice}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group mb-2">
                                  <Field
                                    name="b_providerName"
                                    type="text"
                                    component={ReduxSelect}
                                    fieldName={'Provider Name'}
                                    placeholder={'Provider Name*'}
                                    validate={Validator.required}
                                    options={practitioner}
                                    // onChange={val => {
                                    //   this.props.dispatch(
                                    //     practitionerCombosOffice({
                                    //       json: JSON.stringify({
                                    //         PractitionerOffice: [
                                    //           {
                                    //             PractitionerId: val,
                                    //           },
                                    //         ],
                                    //       }),
                                    //     }),
                                    //   );
                                    // }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 mt-3">
                                <div className="form-group">
                                  <label
                                    className="custom-control-label"
                                    for="Recurring-Appointment"
                                    style={{ fontSize: '18px' }}
                                  >
                                    Block Multiple Appointments {'   '}
                                    <Field
                                      name={`b_recurringAppointment`}
                                      type="text"
                                      className="custom-control-input not-empty"
                                      component={ReduxToggle}
                                      onChange={bool => toogleChange2(bool)}
                                      // disabled={disable}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}{' '}
                    </>
                  )}
                </>
              )}

              {recurringAppointmentValue && referralTypeValue != 'B' && (
                <>
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-9">
                        <div className="form-group mb-2 custom-radio px-3">
                          <label className="mr-4">
                            Weekly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="Weekly"
                              style={radioStyle}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="mr-4">
                            Bi-weekly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="BiWeekly"
                              style={radioStyle}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label>
                            Monthly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="MonthlyOnce"
                              style={radioStyle}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {appointmentTypeValue === 'Weekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block mt-2">
                            <span className="recurring-block-label">Day(s) of the week</span>
                            <p className="recurring-block-value">
                              {/* {moment(g_appointmentDateValue).format('dddd')} */}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <div className="week-days">
                              <div className="col-12">
                                <div
                                  style={{
                                    flexFlow: 'wrap',
                                    display: 'flex',
                                  }}
                                >
                                  <Field
                                    name="dayOfWeek"
                                    component={CheckboxGroup}
                                    col={4}
                                    widthClassName={'100'}
                                    blockDays={JSON.stringify(
                                      parseInt(moment(appointmentDateValue).format('d')) + 1,
                                    )}
                                    // justify={'start'}
                                    options={weekDays}
                                  />
                                </div>
                              </div>
                              {/* <ul
                                    className="answer-options d-flex flex-row flex-wrap text-left"
                                    style={{ listStyle: 'none' }}
                                  >
                                    {weekDays.map((item, qIndex) => {
                                      return (
                                        item && (
                                          <li>
                                            <Checkbox
                                              // disabled={isReadOnly}
                                              name="current-situation"
                                              // checked={this.state.result[val]}
                                              value={item}
                                              // onClick={e => {
                                              //   let result = this.state.result;
                                              //   result[val] = e.target.checked;
                                              //   let keys = Object.keys(result);
                                              //   let filtered = keys.filter(key => {
                                              //     return result[key];
                                              //   });
                                              //   this.setState({
                                              //     result,
                                              //     filtered,
                                              //   });
                                              //   onClick.bind(scope, {
                                              //     surveyQuestionId: SurveyQuestionId,
                                              //     response: JSON.stringify(filtered),
                                              //     level: level,
                                              //     subLevel: subLevel,
                                              //     subCatLevel: subCatLevel,
                                              //     index: index,
                                              //   })();
                                              // }}
                                            >
                                              {' '}
                                              {item}
                                            </Checkbox>
                                          </li>
                                        )
                                      );
                                    })}
                                  </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {appointmentTypeValue === 'BiWeekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day of the week</span>
                            <p className="recurring-block-value" style={{ marginTop: '1em' }}>
                              {moment(appointmentDateValue).format('dddd')}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <div className="bi-week-note">
                              <span className="note-text">
                                <strong>Note</strong>: Bi-weekly appointments are automatically
                                scheduled to reoccur every alternate week.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {appointmentTypeValue === 'MonthlyOnce' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day of the week</span>
                            <p className="recurring-block-value" style={{ marginTop: '1em' }}>
                              {moment(appointmentDateValue).format('dddd')}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <span className="recurring-block-label">Occurrence in the month</span>
                            {/* <p className="recurring-block-value">{'Second'}</p> */}
                            {/* Week1 Week2 WeekLast */}
                            <div className="form-group mb-2">
                              <Field
                                name="recurringOccurance"
                                type="text"
                                component={ReduxSelect}
                                fieldName={'Occurance in the month'}
                                // placeholder={'Occurance in the month'}
                                options={[
                                  {
                                    Value: 'Week1',
                                    Text: 'First Week',
                                  },
                                  {
                                    Value: 'Week2',
                                    Text: 'Second Week',
                                  },
                                  {
                                    Value: 'Week3',
                                    Text: 'Third Week',
                                  },
                                  {
                                    Value: 'Week4',
                                    Text: 'Fourth Week',
                                  },
                                  {
                                    Value: 'WeekLast',
                                    Text: 'Last Week',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-4 enrollment-form">
                      <div className="form-group mb-2">
                        <Field
                          name="recurringCount"
                          type="text"
                          component={ReduxSelect}
                          fieldName={'Count of Appointment(s)'}
                          placeholder={'Count of Appointment(s)'}
                          options={recurringCount}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {referralTypeValue == 'G' && (
                <div className="col-12 col-md-12 mt-1">
                  <h6>Group Members</h6>
                  <div style={{ position: 'relative' }}>
                    <Loader2 loading={this.props.isFetchingGroupClient} />
                    <div
                    // style={{ height: '176px', overflowX: 'scroll', overflowY: 'scroll' }}
                    >
                      <PersistTableSort
                        name="event-group-member"
                        columns={columns}
                        dataSource={this.state.GroupClientListState || []}
                        size={'middle'}
                        pagination={false}
                        scroll={{ x: 170, y: 400 }}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: event => this.onRowClick(record.ClientId),
                        //   };
                        // }}
                        // pagination={{
                        //   itemRender: (page, type, originalElement) => {
                        //     return (
                        //       <div className="dataTables_wrapper no-footer">
                        //         <div className="dataTables_paginate paging_simple_numbers">
                        //           {type == 'prev' ? (
                        //             <a className="paginate_button previous">Previous</a>
                        //           ) : type == 'next' ? (
                        //             <a className="paginate_button next">Next</a>
                        //           ) : (
                        //             <a className="paginate_button ">{page}</a>
                        //           )}
                        //         </div>
                        //       </div>
                        //     );
                        //   },
                        //   pageSize: 3,
                        // }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {block_recurringAppointmentValue && referralTypeValue == 'B' && (
                <>
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-9">
                        <div className="form-group mb-2 custom-radio px-3">
                          <label className="mr-4">
                            Weekly
                            <Field
                              name="b_appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="Weekly"
                              style={radioStyle}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="mr-4">
                            Bi-weekly
                            <Field
                              name="b_appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="BiWeekly"
                              style={radioStyle}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label>
                            Monthly
                            <Field
                              name="b_appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="MonthlyOnce"
                              style={radioStyle}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {block_appointmentTypeValue === 'Weekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block mt-2">
                            <span className="recurring-block-label">Day(s) of the week</span>
                            <p className="recurring-block-value">
                              {/* {moment(g_appointmentDateValue).format('dddd')} */}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <div className="week-days">
                              <div className="col-12">
                                <div
                                  style={{
                                    flexFlow: 'wrap',
                                    display: 'flex',
                                  }}
                                >
                                  <Field
                                    name="b_dayOfWeek"
                                    component={CheckboxGroup}
                                    col={6}
                                    widthClassName={'w-75'}
                                    blockDays={JSON.stringify(
                                      parseInt(moment(appointmentDateValue).format('d')) + 1,
                                    )}
                                    // justify={'start'}
                                    options={weekDays}
                                  />
                                </div>
                              </div>
                              {/* <ul
                                    className="answer-options d-flex flex-row flex-wrap text-left"
                                    style={{ listStyle: 'none' }}
                                  >
                                    {weekDays.map((item, qIndex) => {
                                      return (
                                        item && (
                                          <li>
                                            <Checkbox
                                              // disabled={isReadOnly}
                                              name="current-situation"
                                              // checked={this.state.result[val]}
                                              value={item}
                                              // onClick={e => {
                                              //   let result = this.state.result;
                                              //   result[val] = e.target.checked;
                                              //   let keys = Object.keys(result);
                                              //   let filtered = keys.filter(key => {
                                              //     return result[key];
                                              //   });
                                              //   this.setState({
                                              //     result,
                                              //     filtered,
                                              //   });
                                              //   onClick.bind(scope, {
                                              //     surveyQuestionId: SurveyQuestionId,
                                              //     response: JSON.stringify(filtered),
                                              //     level: level,
                                              //     subLevel: subLevel,
                                              //     subCatLevel: subCatLevel,
                                              //     index: index,
                                              //   })();
                                              // }}
                                            >
                                              {' '}
                                              {item}
                                            </Checkbox>
                                          </li>
                                        )
                                      );
                                    })}
                                  </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {block_appointmentTypeValue === 'BiWeekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day of the week</span>
                            <p className="recurring-block-value" style={{ marginTop: '1em' }}>
                              {moment(appointmentDateValue).format('dddd')}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <div className="bi-week-note">
                              <span className="note-text">
                                <strong>Note</strong>: Bi-weekly appointments are automatically
                                scheduled to reoccur every alternate week.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {block_appointmentTypeValue === 'MonthlyOnce' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day of the week</span>
                            <p className="recurring-block-value" style={{ marginTop: '1em' }}>
                              {moment(appointmentDateValue).format('dddd')}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <span className="recurring-block-label">Occurrence in the month</span>
                            {/* <p className="recurring-block-value">{'Second'}</p> */}
                            {/* Week1 Week2 WeekLast */}
                            <div className="form-group mb-2">
                              <Field
                                name="b_recurringOccurance"
                                type="text"
                                component={ReduxSelect}
                                fieldName={'Occurance in the month'}
                                // placeholder={'Occurance in the month'}
                                options={[
                                  {
                                    Value: 'Week1',
                                    Text: 'First Week',
                                  },
                                  {
                                    Value: 'Week2',
                                    Text: 'Second Week',
                                  },
                                  {
                                    Value: 'Week3',
                                    Text: 'Third Week',
                                  },
                                  {
                                    Value: 'Week4',
                                    Text: 'Fourth Week',
                                  },
                                  {
                                    Value: 'WeekLast',
                                    Text: 'Last Week',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-4 enrollment-form">
                      <div className="form-group mb-2">
                        <Field
                          name="b_recurringCount"
                          type="text"
                          component={ReduxSelect}
                          fieldName={'Count of Appointment(s)'}
                          placeholder={'Count of Appointment(s)'}
                          options={recurringCount}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* <div className="text-right" style={{ borderBottom: '2px solid darkgray' }}>
              <h6 style={{ color: '#46b4fe', cursor: 'pointer' }} onClick={this.toggleView}>
                {isClientView ? 'Add a new client' : 'Select an existing client'}
              </h6>
            </div> */}
              <div className="col-12">
                <div className="modal-footer  border-top-0 pt-2 mt-2">
                  <input
                    type="submit"
                    className="btn black-btn font-weight-bold mr-2"
                    value={
                      referralTypeValue == 'G'
                        ? 'Create Group Visit'
                        : referralTypeValue == 'B'
                        ? 'Block'
                        : 'Create Visit'
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <Modal
          width={600}
          zIndex={100}
          visible={this.state.isSendAnotherInvitation}
          footer={null}
          onCancel={() => {
            this.setState({
              isSendAnotherInvitation: false,
            });
          }}
          closable={false}
        >
          <div className="modal-content border-0">
            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
            <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
              <h4 className="modal-title col-12 mb-3">
                {this.state.inviteMsg}
                <br />
              </h4>
            </div>
          </div>
        </Modal> */}
      </div>
    );
  }
}
const mapStateToPropsOneStudent = state => {
  const {
    app_subscribers_user_search,
    send_voucher_code,
    get_patient_list,
    practitioner_combos,
    practitioner_combos_office,
    get_client_group,
    patient_combo_group,
    combos,
    recurring_count,
    get_combo_facility,
    medicate_combos,
    validate_client,
    upsert_block_appointment,
    appointment_get_client_upcoming_schedule,
    list_client_outstanding_balance_get,
  } = state;
  const { data: getPatientListData } = get_patient_list;
  const { isFetching: isFetchingVoucher } = send_voucher_code;
  const { data, isFetching } = app_subscribers_user_search;
  const { data: practitionerData } = practitioner_combos;
  const { data: practitionerOfficeData } = practitioner_combos_office;
  const { data: patientComboGroupData } = patient_combo_group;
  const { data: getClientListData, isFetching: isFetchingGroupClient } = get_client_group;
  const { data: recurringCountData } = recurring_count;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: medicateCombos, isFetching: isMedicalComboFetching } = medicate_combos;
  const { data: validateClientData } = validate_client;
  const { data: upcomingAppointmentData } = appointment_get_client_upcoming_schedule;
  const { data: outStandingBalanceData } = list_client_outstanding_balance_get;
  let searchedStudent = [];
  let searchStr;
  let isSuccess = false;
  let isExist = false;
  let isExistMsg = null;
  let Client = [];
  let Practitioner = [];
  let PractitionerOffice = [];
  let GroupClientList = [];
  let errorMessage;
  let groupComboData = [];
  let ProgramType = [];
  let PayerInsurance = [];
  let AppointmentType = [];
  let AppointmentTypeDropdown = [];
  let recurringCount = [];
  let medicateCombosObj = {
    Status: [],
  };
  let ServiceCodeList = [],
    PlaceOfService = [],
    AppointmentEligibility = [],
    AppointmentCoPay = [],
    CenterServiceType = [],
    upcomingAppointmentList = [],
    oldAppointmentList = [],
    clientOutStandingBalanceData = [];
  if (validateClientData && validateClientData.Client) {
    isExist = validateClientData.Client[0].IsValid;
    isExistMsg = validateClientData.Client[0].FailMessage;
  }
  if (patientComboGroupData) {
    groupComboData = patientComboGroupData.Group || [];
  }
  if (medicateCombos) {
    medicateCombosObj = {
      Status: medicateCombos.Status,
    };
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner =
      practitionerData.PractitionerList.filter(provider => provider.IsArchived === false) || [];
  }
  if (practitionerOfficeData) {
    PractitionerOffice = practitionerOfficeData.PractitionerOfficeList || [];
  }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    if (app_subscribers_user_search.data && app_subscribers_user_search.data.data) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else {
      searchedStudent = Object.values(data) || [];
    }
  }
  if (send_voucher_code.data && send_voucher_code.data.success) {
    isSuccess = send_voucher_code.data.success;
  } else if (send_voucher_code && send_voucher_code.error) {
    errorMessage = send_voucher_code.error.Message;
  }
  if (getPatientListData && getPatientListData.data) {
    Client = JSON.parse(getPatientListData.data)[0].Client;
  }
  if (getClientListData) {
    GroupClientList = getClientListData.ClientGroup || [];
  }

  if (combos.data && combos.data['RCM_Program']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'] || [];
    ProgramType = combos.data['RCM_Program'] || [];
    PlaceOfService = combos.data['RCM_PlaceOfService'] || [];
    CenterServiceType = combos.data['CenterServiceType'] || [];
    AppointmentEligibility = combos.data['AppointmentEligibility'] || [];
    AppointmentCoPay = combos.data['AppointmentCoPay'] || [];
    PayerInsurance = combos.data['RCM_PayerInsurance'];
    AppointmentTypeDropdown = combos.data['AppointmentTypeCenter'] || [];
    // AppointmentType = combos.data['AppointmentType'] || [];
  }
  if (recurringCountData && recurringCountData.Recurring) {
    recurringCount = recurringCountData.Recurring || [];
  }
  if (upcomingAppointmentData && upcomingAppointmentData.Appointment) {
    upcomingAppointmentList =
      (upcomingAppointmentData.Appointment[0] &&
        upcomingAppointmentData.Appointment[0].UpcomingAppointment) ||
      [];
    oldAppointmentList =
      (upcomingAppointmentData.Appointment[0] &&
        upcomingAppointmentData.Appointment[0].PastAppointment) ||
      [];
  }
  if (outStandingBalanceData && outStandingBalanceData.Client) {
    clientOutStandingBalanceData =
      outStandingBalanceData.Client && outStandingBalanceData.Client[0];
  }
  const MobileNumberValue = formValueSelector('createEventForm')(state, 'MobileNumber');
  const insuranceValue = formValueSelector('createEventForm')(state, 'payerId');
  const EmailValue = formValueSelector('createEventForm')(state, 'Email');
  const referralTypeValue = formValueSelector('createEventForm')(state, 'referralType');
  let groupNameCurrentValue = formValueSelector('createEventForm')(state, 'g_group');
  const recurringAppointmentValue = formValueSelector('createEventForm')(
    state,
    'recurringAppointment',
  );
  const block_recurringAppointmentValue = formValueSelector('createEventForm')(
    state,
    'b_recurringAppointment',
  );
  const appointmentTypeValue = formValueSelector('createEventForm')(state, 'appointmentType');
  const block_appointmentTypeValue = formValueSelector('createEventForm')(
    state,
    'b_appointmentType',
  );
  const appointmentDateValue = formValueSelector('createEventForm')(state, 'appointmentDate');
  const patientFirstNameValue = formValueSelector('createEventForm')(state, 'patientFirstName');
  const middleNameValue = formValueSelector('createEventForm')(state, 'middleName');
  const patientLastNameValue = formValueSelector('createEventForm')(state, 'patientLastName');
  // let initData = {
  //   dayOfWeek: ['2', 3],
  // };
  const groupStatusValue = formValueSelector('createEventForm')(state, 'Status');
  const placeOfServiceForIndivisualClient = formValueSelector('createEventForm')(
    state,
    'placeOfService',
  );
  const placeOfServiceForExistingIndivisualClient = formValueSelector('createEventForm')(
    state,
    'existingPlaceOfService',
  );

  const officeNameForExistingIndividualClient = formValueSelector('createEventForm')(
    state,
    'existingPatientOfficeName',
  );

  const officeNameForNewClient = formValueSelector('createEventForm')(state, 'officeName');

  const providerNameForExistingIndivisualClient = formValueSelector('createEventForm')(
    state,
    'existingPatientProviderName',
  );

  const placeOfServiceForGroup = formValueSelector('createEventForm')(state, 'g_placeOfService');
  const newPStartTime = formValueSelector('createEventForm')(state, 'startTime');
  const newPEndTime = formValueSelector('createEventForm')(state, 'endTime');

  let groupNameCurrentValue2;
  groupNameCurrentValue2 = groupComboData.filter(item => {
    if (item.Value == groupNameCurrentValue) {
      return item;
    }
  });

  return {
    // initialValues={this.state.initGroupEventValues}
    // initialValues: { initData },
    searchedStudent,
    isFetching: isFetching || isFetchingVoucher,
    // isFetchingVoucher,
    searchStr,
    isSuccess,
    errorMessage,
    MobileNumberValue,
    EmailValue,
    referralTypeValue,
    recurringAppointmentValue,
    block_recurringAppointmentValue,
    appointmentTypeValue,
    block_appointmentTypeValue,
    client: Client || [],
    practitioner: Practitioner,
    practitionerOffice: PractitionerOffice,
    GroupClientList:
      GroupClientList &&
      GroupClientList.map(item => {
        return { ...item, isClientInvited: true };
      }),
    groupComboData,
    isFetchingGroupClient,
    ProgramType,
    PayerInsurance,
    appointmentDateValue,
    recurringCount,
    medicateCombosObj: medicateCombosObj,
    isMedicalComboFetching,
    groupNameCurrentValue2,
    ServiceCodeList,
    PlaceOfService,
    AppointmentType,
    AppointmentTypeDropdown,
    patientFirstNameValue,
    middleNameValue,
    patientLastNameValue,
    isExist,
    isExistMsg,
    groupStatusValue: groupStatusValue,
    placeOfServiceForIndivisualClient,
    placeOfServiceForExistingIndivisualClient,
    placeOfServiceForGroup,
    CenterServiceType,
    officeNameForExistingIndividualClient,
    officeNameForNewClient,
    providerNameForExistingIndivisualClient,
    AppointmentEligibility,
    AppointmentCoPay,
    upcomingAppointmentList,
    oldAppointmentList,
    clientOutStandingBalanceData,
    insuranceValue,
    newPStartTime,
    newPEndTime,
  };
};
// const ReduxSelectOneStudentModal = connect(mapStateToPropsOneStudent)(SelectOneStudentModal);
const ReduxCreateEventModal = reduxForm({
  form: 'createEventForm',
  enableReinitialize: true,
})(connect(mapStateToPropsOneStudent)(withRouter(CreateEventModal)));

export { ReduxCreateEventModal };
