import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import { ProcessCombos } from '../../../../../store/comboConfig';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  ReduxTimePicker,
  CheckboxGroup,
} from '../../../../../component/custom-redux-components';
import PersistTableSort from '../../../../../component/persistTableSort';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import {
  listServiceLinePayment,
  upsertServiceLinePayment,
  deleteServiceLinePayment,
  getServiceLinePayment,
} from '../../../../../store/actions';
import AntdModal from '../../../../../Components/CustomModal';
import CustomModal from '../../../../../core/modal';
import { Table, Modal, Tooltip } from 'antd';
import validate from '../../../validation/validate';
// import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';

class PaymentServiceLineDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Payments: [],
      isModelOpen: false,
      PaymentRecord: {},
    };
  }
  componentDidMount() {
    var result = ProcessCombos('PaymentSource,CWPaymentMethod');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  componentWillReceiveProps({ PaymentRecord, isUpsert, isDeleted }) {
    if (
      PaymentRecord &&
      JSON.stringify(PaymentRecord) != JSON.stringify(this.props.PaymentRecord)
    ) {
      let paymentdata = {
        PaymentDate: PaymentRecord.PaymentDate,
        PaymentSourceId: PaymentRecord.PaymentSourceId,
        PaymentMethodId: PaymentRecord.PaymentMethodId,
        Amount: PaymentRecord.Amount,
        ServiceLineId: PaymentRecord.ServiceLineId,
        ServiceLinePaymentId: PaymentRecord.ServiceLinePaymentId,
        PaymentNote: PaymentRecord.Note,
      };
      this.setState({
        PaymentRecordInitVal: paymentdata,
        createAndEditPaymentModal: true,
      });
    }
    if (isUpsert && isUpsert != this.props.isUpsert) {
      this.setState({ PaymentRecordInitVal: {}, createAndEditPaymentModal: false });
      this.toggle('Payment Saved Successfully');
      this.props.dispatch(reset('PaymentForm'));
      this.props.dispatch(
        listServiceLinePayment({
          json: JSON.stringify({
            ServiceLinePayment: [
              {
                ServiceLineId: this.props.ServiceLineId,
              },
            ],
          }),
        }),
      );
    }
    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.toggle('Payment Archived Successfully');
      this.props.dispatch(
        listServiceLinePayment({
          json: JSON.stringify({
            ServiceLinePayment: [
              {
                ServiceLineId: this.props.ServiceLineId,
              },
            ],
          }),
        }),
      );
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const {
      PayerRecord,
      toggleView,
      PaymentData,
      ServiceLineId,
      isFetching,
      Payment,
      CWPaymentMethod,
    } = this.props;

    const columns = [
      {
        title: (
          <span>
            Applied <br />
            Date
          </span>
        ),
        dataIndex: 'AppliedDate',
        key: 'AppliedDate',
        sorter: (a, b) => isDateComparer(a.AppliedDateSort, b.AppliedDateSort),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },

      {
        title: (
          <span>
            Applied <br />
            Type
          </span>
        ),
        dataIndex: 'AppliedType',
        key: 'AppliedType',
        sorter: (a, b) => isComparer(a.AppliedType, b.AppliedType),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },

      {
        title: (
          <span>
            Applied <br />
            Amount
          </span>
        ),
        dataIndex: 'AppliedAmount',
        key: 'AppliedAmount',
        sorter: (a, b) => isNumericComparer(a.AppliedAmountSort, b.AppliedAmountSort),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record, index) => {
        //   return <>{text}</>;
        // },
        // width: 150,
      },
      {
        title: (
          <span>
            Payment <br />
            Date
          </span>
        ),
        dataIndex: 'PaymentDate',
        key: 'PaymentDate',
        sorter: (a, b) => isDateComparer(a.PaymentDateSort, b.PaymentDateSort),
        sortDirections: ['descend', 'ascend'],
        // width: 150,
      },
      // {
      //   title: 'Payment ID',
      //   dataIndex: 'PaymentId',
      //   key: 'PaymentId',
      //   sorter: (a, b) => isNumericComparer(a.PaymentId, b.PaymentId),
      //   sortDirections: ['descend', 'ascend'],
      //   // width: 150,
      // },
      // {
      //   title: 'Account',
      //   dataIndex: 'Account ',
      //   key: 'Account ',
      //   sorter: (a, b) => isComparer(a.Account, b.Account),
      //   sortDirections: ['descend', 'ascend'],
      //   render: (text, record, index) => {
      //     debugger;
      //     return <>{text}</>;
      //   },
      // },
      // {
      //   title: (
      //     <span>
      //       Payer <br />
      //       Type
      //     </span>
      //   ),
      //   dataIndex: 'IsApprovedForBilling',
      //   key: 'IsApprovedForBilling',
      //   sorter: (a, b) => isComparer(a.IsApprovedForBilling, b.IsApprovedForBilling),
      //   sortDirections: ['descend', 'ascend'],
      //   render: (text, record) => {
      //     return (
      //       <div className="">
      //         <span>{record.IsApprovedForBilling ? 'Yes' : 'No'}</span>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   title: <span>Payer</span>,
      //   dataIndex: 'IsApprovedForBatching',
      //   key: 'IsApprovedForBatching',
      //   sorter: (a, b) => isComparer(a.IsApprovedForBatching, b.IsApprovedForBatching),
      //   sortDirections: ['descend', 'ascend'],
      //   render: (text, record) => {
      //     return (
      //       <div className="">
      //         <span>{record.IsApprovedForBatching ? 'Yes' : 'No'}</span>
      //       </div>
      //     );
      //   },
      // },
      {
        title: 'Action',
        // width: 300,
        render: (text, record, index) => (
          <div class="tools-grid d-flex align-items-center" style={{ border: '0px solid red' }}>
            <div className="mr-2">
              <span className="start-btn">
              <Tooltip placement = "top" title = "Edit"> 
                <img
                  src="images/shape.svg"
                  width="14px"
                  style={{ cursor: 'pointer' }}
                  onClick={event => {
                    this.setState({
                      // createAndEditPaymentModal: true,
                      ServiceLineId: record.ServiceLineId,
                      ServiceLinePaymentId: record.ServiceLinePaymentId,
                    });
                    this.props.dispatch(
                      getServiceLinePayment({
                        json: JSON.stringify({
                          ServiceLinePayment: [
                            {
                              ServiceLinePaymentId: record.ServiceLinePaymentId,
                            },
                          ],
                        }),
                      }),
                      );
                    }}
                    />
                    </Tooltip> 
              </span>
            </div>
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  

                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Payment"
                    >
                    <Tooltip placement = "top" title = "Delete"> 
                    <p>

                    <i class="far fa-trash-alt"></i>
                    </p>
                    </Tooltip> 
                  </span>
                   
                    )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to archive this Payment?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          onOk();
                          this.props.dispatch(
                            deleteServiceLinePayment({
                              json: JSON.stringify({
                                ServiceLinePayment: [
                                  {
                                    ServiceLinePaymentId: record.ServiceLinePaymentId,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    return (
      <div className="row">
        <div className="col-12">
          <div className="group-service-detail">
            <div className="service-detail-header">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div
                  onClick={() => {
                    toggleView();
                  }}
                  className="text-left d-flex"
                  style={{ cursor: 'pointer' }}
                >
                  <img src="images/back-arrow.svg" /> Back to Payment List
                </div>
              </div>
              <div className="service-header">
                <div className="row">
                  <div className="col-2">
                    <span>Client ID</span>
                    <h6>{PayerRecord.ClientId}</h6>
                  </div>
                  <div className="col-2">
                    <span>Client Name</span>
                    <h6>{PayerRecord.ClientName}</h6>
                  </div>
                  <div className="col-2">
                    <span>Service Code</span>
                    <h6>{PayerRecord.ServiceCode}</h6>
                  </div>
                  <div className="col-2">
                    <span>Practioner Name</span>
                    <h6>{PayerRecord.PractionerName}</h6>
                  </div>
                  <div className="col-2">
                    <span>Insurance Name</span>
                    <h6>{PayerRecord.InsuranceName}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right pt-3 pb-0">
                <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn"
                  value={'New Payment'}
                  onClick={event => {
                    this.setState({
                      createAndEditPaymentModal: true,
                      PaymentRecordInitVal: {},
                    });
                  }}
                />
                {/* <ReduxPaymentForm
                      initialValues={this.state.PaymentRecordInitVal}
                      PaymentRecordVal={this.state.PaymentRecordInitVal}
                      ServiceLineId={ServiceLineId}
                      PaymentOption={Payment}
                    /> */}
              </div>
            </div>
            <div className="service-detail-body">
              <div className="">
                <h6>Applied Payments : </h6>
                <div className="rcm-subtab-table-block">
                  <div className="">
                    {/* <Loader2 loading={isFetching} /> */}
                    <PersistTableSort
                      name="grp-service-line-detail"
                      columns={columns}
                      dataSource={PaymentData}
                      size={'middle'}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div className="dataTables_wrapper no-footer">
                              <div className="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a className="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a className="paginate_button next">Next</a>
                                ) : (
                                  <a className="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          <Modal
            zIndex={100}
            visible={this.state.createAndEditPaymentModal}
            footer={null}
            closable={false}
            width={800}
          >
            <ReduxPaymentForm
              initialValues={this.state.PaymentRecordInitVal}
              PaymentRecordVal={this.state.PaymentRecordInitVal}
              PayerRecord={PayerRecord}
              ServiceLineId={ServiceLineId}
              PaymentOption={Payment}
              CWPaymentMethodOption={CWPaymentMethod}
              // onSaveClick={this.onSubmit.bind(this)}
              onCancel={() => {
                this.setState({
                  createAndEditPaymentModal: false,
                  PayerInfo: {},
                });
              }}
            />
          </Modal>
        }
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              // toggleView();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    combos,
    list_service_line_payment,
    upsert_service_line_payment,
    delete_service_line_payment,
    get_service_line_payment,
  } = state;
  const { data: ServiceLinePaymentData, isFetching } = list_service_line_payment;
  const { data: deleteServicePayment, isFetching: isFetchingDelete } = delete_service_line_payment;
  const { data: GetServicePaymentData, isFetching: isFetchingGet } = get_service_line_payment;

  let Payment = [];
  let CWPaymentMethod = [];
  let isUpsert = false;
  let isDeleted = false;
  let PaymentData = [];
  let PaymentRecord = {};

  if (combos.data && combos.data['PaymentSource']) {
    Payment = combos.data['PaymentSource'];
    CWPaymentMethod = combos.data['CWPaymentMethod'];
  }
  if (ServiceLinePaymentData && ServiceLinePaymentData.data) {
    PaymentData = ServiceLinePaymentData.data || [];
  }
  if (GetServicePaymentData && GetServicePaymentData.data) {
    PaymentRecord = GetServicePaymentData.data[0] || {};
  }
  if (upsert_service_line_payment.data && upsert_service_line_payment.data.success) {
    isUpsert = upsert_service_line_payment.data.success;
  }
  if (deleteServicePayment && deleteServicePayment.success) {
    isDeleted = deleteServicePayment.success;
  }
  return {
    PaymentData,
    Payment,
    CWPaymentMethod,
    isUpsert,
    isFetching,
    isFetchingDelete,
    isDeleted,
    isFetchingGet,
    PaymentRecord,
  };
};

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineInfo: {},
    };
  }
  onSaveClick = values => {
    const {
      Amount,
      CheckInfo,
      Payer,
      PayerType,
      PaymentDate,
      PaymentMethode,
      PaymentSourceId,
      PaymentMethodId,
      PaymentNote,
    } = values;
    const { PaymentRecordVal, ServiceLineId } = this.props;
    let data = {
      json: JSON.stringify({
        ServiceLinePayment: [
          {
            ServiceLinePaymentId:
              (PaymentRecordVal && PaymentRecordVal.ServiceLinePaymentId) || null,
            ServiceLineId: (PaymentRecordVal && PaymentRecordVal.ServiceLineId) || ServiceLineId,
            // ServiceLineId: this.props.ServiceLineId,
            PaymentDate: PaymentDate,
            // PaymentMethodId: PaymentMethode,
            PaymentSourceId: PaymentSourceId,
            PaymentMethodId: PaymentMethodId,
            Amount: Amount,
            Note: PaymentNote,
          },
        ],
      }),
    };
    this.props.dispatch(upsertServiceLinePayment(data));
  };
  render() {
    const {
      handleSubmit,
      disable,
      isFetching,
      PaymentOption,
      CWPaymentMethodOption,
      onCancel,
      ServiceLineId,
      PaymentRecordVal,
      PayerRecord,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSaveClick)}>
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-center">
            {/* {PaymentRecordVal.ServiceLinePaymentId ? `Update Payment` : `Add New Payment`} */}
            Payment
          </h4>
          <hr />
          <h5 className="col-12 text-left">
            Patient :
            <small>
              {` ${PayerRecord.ClientName} ( ${PayerRecord.ClientId} ) `} <br />
            </small>
          </h5>
          <hr />
          {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
        </div>
        <div class="modal-body service-row enrollment-form px-4">
          <div className="row mb-4">
            <div class="col-12 col-md-12 col-lg-6">
              <div class="form-group">
                <Field
                  name={`PaymentDate`}
                  validate={validate.required}
                  fieldName={'Payment Date'}
                  type="text"
                  component={ReduxDateInput}
                  label={'Payment Date '}
                />
              </div>
            </div>
            {/* <div class="col-4">
            <div class="form-group">
              <Field
                name={`PayerType`}
                // validate={validate.required}
                type="text"
                component={ReduxSelect}
                placeholder={`Payer Type`}
                options={[{ Value: '1', Text: 'Patient' }]}
                fieldName={'Payer Type'}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <Field
                name={`Payer`}
                // validate={validate.required}
                type="text"
                component={ReduxSelect}
                placeholder={`Payer`}
                options={[{ Value: '1', Text: 'Payer Name' }]}
                fieldName={'Payer'}
                disabled={disable}
              />
            </div>
          </div> */}
            <div class="col-12 col-md-12 col-lg-6">
              <div class="form-group">
                <Field
                  name={`PaymentSourceId`}
                  validate={validate.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Payment Source`}
                  options={PaymentOption}
                  fieldName={'Payment Source'}
                  // disabled={disable}
                />
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6">
              <div class="form-group">
                <Field
                  name={`PaymentMethodId`}
                  validate={validate.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Payment Method`}
                  options={CWPaymentMethodOption}
                  fieldName={'Payment Methode'}
                  // disabled={disable}
                />
              </div>
            </div>
            {/* <div class="col-4">
            <div class="form-group">
              <Field
                name={`CheckInfo`}
                type="text"
                component={ReduxInput}
                fieldName={'Check Info'}
                disabled={disable}
              />
              <label class="placeholder-label">Check Info </label>
            </div>
          </div> */}
            <div class="col-12 col-md-12 col-lg-6">
              <div class="form-group">
                <Field
                  name={`Amount`}
                  type="text"
                  component={ReduxInput}
                  validate={[validate.required, validate.allowAmount]}
                  fieldName={'Amount'}
                />
                <label class="placeholder-label">Amount</label>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group">
                <Field
                  name={`PaymentNote`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Payment Note'}
                  disabled={disable}
                />
                <label class="placeholder-label">Payment Note</label>
              </div>
            </div>
            <div className="col-12 text-right pr-4">
              <button type="submit" class="btn px-3 py-1">
                Add Payment
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
const ReduxPaymentForm = reduxForm({
  form: 'PaymentForm',
  enableReinitialize: true,
})(connect()(withRouter(PaymentForm)));
const selector = formValueSelector('PaymentForm');

export default connect(mapStateToProps)(PaymentServiceLineDetail);
