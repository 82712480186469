const barData = {
  labels: [
    'Deciding to change',
    'Discovering home',
    'Finding Purpose',
    'Living Health',
    // 'Thinking Differently',
  ],
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: ['#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd'],
    },
  ],
};
const barOptions = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        maxBarThickness: 48,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

const barData2 = {
  labels: [
    'Physical Health',
    'Physical Health',
    'Physical Health',
    'Relationships',
    'Thinking Differently',
  ],
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: ['#ff8397', '#56d798', '#46b4fe', '#f38b4a', '#6970d5'],
    },
  ],
};
const barOptions2 = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        maxBarThickness: 48,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

const barData3 = {
  labels: [
    'Deciding to Change',
    'Discovering Home',
    'Finding Purpose',
    'Living Health',
    'Thinking Differently',
  ],
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: ['#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd'],
    },
  ],
};
const barOptions3 = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        maxBarThickness: 66,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const horizontalBarData = {
  labels: ['', '1+ Year', '1 Year', '6 Months', '1 Month', '1 Week'],
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: ['#ff5d5e', '#ff5d5e', '#30e191', '#48b4ff', '#48b4ff', '#30e191'],
    },
  ],
};

const horizontalBarOptions = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        maxBarThickness: 18,
        padding: '150px',
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const lineOptions = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        backgroundColor: 'red',
        borderColor: 'red',
        gridLines: {
          display: true,
          gridLines: {
            drawTicks: false,
            display: false,
          },
        },
      },
    ],
  },
};

const lineData = {
  labels: ['1st week', '2nd week', '3rd Week', '4th week', '5th week'],
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: ['gradientFill'],
    },
  ],
};

const lineData2 = {
  labels: ['1st week', '2nd week', '3rd Week', '4th week', '5th week'],
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: ['gradientFill'],
    },
  ],
};


// numberFormat.format()

const pieOptions = {
  legend: {
    display: false,
  }
};

const pieData = {
  labels: ['Active', 'Inactive'],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ['#FF6384', '#36A2EB'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      borderWidth: 0,
    },
  ],
};

const doughnutData = {
  labels: ['Free', 'Paid'],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ['#36A2EB', '#FF6384'],
      hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      borderWidth: 0,
    },
  ],
};

const doughnutOptions = {
  legend: {
    display: false,
  },
  cutoutPercentage: 80,
  plugins:{
    datalabels:{
      display: false
    }
  }
};

const lineData1 = {
  labels: ['jan 1', 'jan 2', 'jan 3', 'jan 4', 'jan 5', 'jan 6'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: '#098938',
      borderColor: '#098938',
      data: [180, 100, 150, 200, 250],
      fill: false,
    },
    {
      label: 'My Second dataset',
      fill: false,
      backgroundColor: '#0ed1f1',
      borderColor: '#0ed1f1',
      data: [140, 160, 200, 140, 250],
    },
    {
      label: 'My Second dataset',
      fill: false,
      backgroundColor: '#ff0004',
      borderColor: '#ff0004',
      data: [100, 120, 135, 135, 120, 175],
    },
  ],
};

const lineOptions1 = {
  legend: {
    display: false,
    labels: {
      boxWidth: -50,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },

        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export {
  barData,
  barOptions,
  barData2,
  barOptions2,
  barData3,
  barOptions3,
  horizontalBarData,
  horizontalBarOptions,
  lineData,
  lineData2,
  lineOptions,
  pieData,
  pieOptions,
  doughnutData,
  doughnutOptions,
  lineOptions1,
  lineData1,
};
