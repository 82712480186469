import React, { Component } from 'react';
import { Table, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import ExpandIcon from '../../../../util/expandIcon';
import ServiceRowRender from './serviceRowRender';
import { smartContractBundleSubmitToPayer } from '../../../../store/actions';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
const BundleRowRender = (record, index, indent, expanded) => {
  const dispatch = useDispatch();
  const columns = [
    {
      title: 'Payer Name',
      dataIndex: 'PayerName',
      key: 'PayerName',
      sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Contract Id',
      dataIndex: 'ContractId',
      key: 'ContractId',
      sorter: (a, b) => isNumericComparer(a.ContractId, b.ContractId),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="text-right">
            <span className="">{text}</span>
          </div>
        );
      },
    },
    {
      title: 'Amount To Be Claimed',
      dataIndex: 'AmountToBeClaimed',
      key: 'AmountToBeClaimed',
      sorter: (a, b) => isNumericComparer(a.AmountToBeClaimed, b.AmountToBeClaimed),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="license-invoice text-right">
            <span className="">{currencyFormatter.format(text)}</span>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => isComparer(a.Status, b.Status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        if (record.PayerName != 'Self Pay') {
          return (
            <>
              <div className="text-left payer-status">
                <span className="shared">{text}</span>
              </div>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => {
        if (record.PayerName == 'Self Pay') {
          return <></>;
        } else {
          if (record.IsTermsConditionAvailableForPayer) {
            if (record.IsSubmittedToPayer == false || record.IsSubmittedToPayer == null) {
              return (
                <>
                  <span
                    style={{
                      color: '#4FACFE',
                      fontSize: '14px',
                      fontWeight: '700',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      cursor: 'pointer',
                      color: 'rgb(79, 172, 254)',
                    }}
                    className=""
                    onClick={() => {
                      dispatch(
                        smartContractBundleSubmitToPayer({
                          Json: JSON.stringify({
                            SmartContract: [
                              {
                                SmartContractServiceCodeBundleId:
                                  record.SmartContractServiceCodeBundleId,
                              },
                            ],
                          }),
                          ClientId: record.ClientId,
                        }),
                      );
                    }}
                  >
                    {/* <img src="images/edittp.svg" /> */}
                    Submit Contract
                  </span>
                </>
              );
            } else {
              return (
                <span
                  style={{
                    color: '#4FACFE',
                    fontSize: '14px',
                    fontWeight: '700',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'green',
                  }}
                >
                  <i class="fa fa-check-circle" aria-hidden="true"></i> &nbsp; Submitted
                </span>
              );
            }
          } else {
            return (
              <>
                <Tooltip
                  placement="top"
                  title={
                    <>
                      <p>
                        Please add Terms and Condition to respective payers before submit the
                        contract to payers.
                      </p>
                    </>
                  }
                >
                  {/* &nbsp;
                  <i
                    class="fa fa-info"
                    aria-hidden="true"
                    width="5px"
                    style={{ color: 'red', cursor: 'pointer' }}
                  ></i> */}
                  <span
                    style={{
                      color: 'grey',
                      fontSize: '14px',
                      fontWeight: '700',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      cursor: 'not-allowed',
                    }}
                  >
                    Submit Contract
                  </span>
                </Tooltip>
              </>
            );
          }
        }
      },
    },
  ];

  return record.Payer ? (
    <Table
      columns={columns}
      dataSource={record.Payer}
      pagination={false}
      // expandable={{
      //   expandedRowRender: ServiceRowRender,
      //   expandIcon: ExpandIcon,
      // }}
      // className="nested-tables"
    />
  ) : (
    <div className="text-center border-1">
      <span style={{ color: '#828282', fontSize: '16px', fontWeight: '400' }}>
        No active bundle found.
      </span>
    </div>
  );
};

export default BundleRowRender;
