import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Tooltip } from 'antd';
import PageTitle from '../../../Components/PageTitle';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import {
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
  listCombos,
  centerConsumableGetUpsert,
  centerConsumableGetAll,
  centerConsumableGet,
  resetCenterConsumableGet,
  centerConsumableDelete,
} from '../../../store/actions';
import CustomModal from '../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import AntdModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { ProcessCombos } from '../../../store/comboConfig';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../../../app-config';
import PersistTableSort from '../../../component/persistTableSort';
import ReduxServiceCodeForm from './addUpdateConsumable';
import { currencyFormatter, numberFormat } from '../../../util/formatter';

class ServiceCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceCodeList: [],
      createAndEditGroupModal: false,
      tempColumns: [
        {
          title: 'Item Code',
          dataIndex: 'ItemCode',
          key: 'ItemCode',
          sorter: (a, b) => isComparer(a.ItemCode, b.ItemCode),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Item Category',
          dataIndex: 'Category',
          key: 'Category',
          sorter: (a, b) => isComparer(a.Category, b.Category),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Item Name',
          dataIndex: 'ItemName',
          key: 'ItemName',
          sorter: (a, b) => isComparer(a.ItemName, b.ItemName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Price',
          dataIndex: 'Price',
          key: 'Price',
          sorter: (a, b) => isNumericComparer(a.Price, b.Price),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => {
            return (
              <div>
                {currencyFormatter.format(record.Price || 0)}
              </div>
            );
          },
        },
        {
          title: 'Unit of Measure',
          dataIndex: 'UnitOfMeasure',
          key: 'UnitOfMeasure',
          sorter: (a, b) => isComparer(a.UnitOfMeasure, b.UnitOfMeasure),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Date Added',
          dataIndex: 'DateAdded',
          key: 'DateAdded',
          sorter: (a, b) => isDateComparer(a.DateAddedForSort, b.DateAddedForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Last Updated',
          dataIndex: 'LastUpdated',
          key: 'LastUpdated',
          sorter: (a, b) => isDateComparer(a.LastUpdatedForSort, b.LastUpdatedForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Action',
          dataIndex: 'Action',
          key: 'Action',
          key: 'x',
          width: 100,
          render: (text, record, index) => {
            if (!this.props.isReadOnlyUser) {
              return (
                <div class="tools-grid">
                  <div className="mr-4 mt-1">
                    <span
                      className="start-btn"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Tooltip placement="top" title="Edit">
                        {' '}
                        <img
                          src="images/shape.svg"
                          width="14px"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={event => {
                            this.props.dispatch(
                              centerConsumableGet({
                                jsonInput: JSON.stringify({
                                  CenterConsumable: [
                                    {
                                      CenterConsumableId: record.CenterConsumableId,
                                    },
                                  ],
                                }),
                              }),
                            );
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="">
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Delete">
                            <p style={{ cursor: 'pointer' }}>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to delete this Consumable?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  centerConsumableDelete({
                                    JsonInput: JSON.stringify({
                                      CenterConsumable: [
                                        {
                                          CenterConsumableId: record.CenterConsumableId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          },
        },
      ],
    };
  }
  // need to work on it always return zero for me
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  refreshGrid = () => {
    const { searchStr } = this.state;
    this.setState({
      searchStr,
    });
    this.props.dispatch(
      centerConsumableGetAll({
        jsonInput: JSON.stringify({
          Search: searchStr,
        }),
      }),
    );
    sessionStorage.setItem(
      'appliedFilter',
      JSON.stringify({
        searchStr: searchStr,
      }),
    );
  };

  componentDidMount() {
    this.props.dispatch(centerConsumableGetAll({}));
    this.props.dispatch(listCombos({ comboTypes: 'UnitOfMeasure,ConsumableCategory' }));
    this.props.dispatch(listServiceCode({}));
    this.props.dispatch(comboCode({}));
  }

  componentWillReceiveProps({
    centerConsumableList,
    individualConsumableData,
    isUpsertSuccess,
    isDeleted,
  }) {
    if (
      centerConsumableList &&
      JSON.stringify(centerConsumableList) != JSON.stringify(this.props.centerConsumableList)
    ) {
      var result = ProcessCombos(
        'UnitOfMeasurement,CenterConsumable,CenterLaborCost,LaberCostType',
      );
      if (result.isProcess) {
        this.props.dispatch(result.processItems);
      }
      this.setState({
        ...this.state,
        centerConsumableList: centerConsumableList,
      });
    }

    if (
      individualConsumableData &&
      JSON.stringify(individualConsumableData) !=
        JSON.stringify(this.props.individualConsumableData)
    ) {
      this.setState({
        ...this.state,
        individualConsumableData: individualConsumableData,
        createAndEditGroupModal: true,
      });
    }

    if (isUpsertSuccess && isUpsertSuccess != this.props.isUpsertSuccess) {
      this.setState(
        {
          ...this.state,
          createAndEditGroupModal: false,
        },
        () => {
          let CenterConsumableId =
            (this.state.individualConsumableData &&
              this.state.individualConsumableData.CenterConsumableId) ||
            null;
          if (CenterConsumableId) {
            this.toggle('Consumable updated successfully');
          } else {
            this.toggle('Consumable added successfully');
          }
        },
      );
      this.props.dispatch(resetCenterConsumableGet({}));
    }

    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.toggle('Consumable deleted successfully');
    }
  }

  toggle = header => {
    this.setState(
      prevState => {
        return {
          ...this.state,
          isModelOpen: !prevState.isModelOpen,
          header,
          individualConsumableData: null,
        };
      },
      () => {
        if (this.state.isModelOpen == false) {
          this.props.dispatch(centerConsumableGetAll({}));
        }
      },
    );
  };

  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  if(errorMessage) {
    this.setState({ ...this.state, isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  onSubmit(values) {
    const { Price, ItemCode, ItemName, UnitOfMeasure, CategoryId } = values;
    const data = {
      CenterConsumable: [
        {
          CenterConsumableId:
            (this.props.individualConsumableData &&
              this.props.individualConsumableData.CenterConsumableId) ||
            null,
          ItemCode,
          ItemName,
          Price,
          UnitOfMeasure,
          CategoryId,
        },
      ],
    };

    let Data = {
      jsonInput: JSON.stringify(data),
    };

    this.props.dispatch(centerConsumableGetUpsert(Data));
  }

  render() {
    const { centerConsumableList } = this.state;
    const { isReadOnlyUser } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Materials | Master Bill of Services" />
        <div className="container-fluid mt-5">
          <div className="">
            <div className="row px-0 mt-5 mb-2 d-flex align-items-end">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 px-0">
                {' '}
                <h4 className="">Materials</h4>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5 px-0 d-flex align-items-center">
                <div class="search-box d-inline-flex w-100">
                  <div class="has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 px-0 d-flex align-items-center justify-content-end">
                {!isReadOnlyUser && (
                  <input
                    type="button"
                    class="btn"
                    value={'Add New Materials'}
                    onClick={event => {
                      this.setState({
                        ...this.state,
                        createAndEditGroupModal: true,
                      });
                    }}
                    disabled={this.props.isFetching}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                {/* <Loader2 loading={this.props.isFetching} /> */}
                <div className="col-12 rcm-tools-table rcm-panel">
                  <PersistTableSort
                    name="service-code-list"
                    // bordered
                    columns={this.state.tempColumns}
                    dataSource={centerConsumableList}
                    size={'middle'}
                    loader={this.props.isFetching}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            // this.props.dispatch(centerConsumableGetAll({}));
            this.setState({ ...this.state, individualConsumableData: null }, () => {
              this.toggle();
            });
          }}
          yesText="OK"
          hideCancel={true}
        />
        {/* <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(courseDelete({ CourseId: this.getClientId() }));
            // this.props.history.push('/manage/courses');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            // this.props.history.push('/manage/courses');
          }}
        /> */}
        <Modal
          zIndex={100}
          visible={this.state.createAndEditGroupModal}
          footer={null}
          closable={false}
          width={1000}
          destroyOnClose={true}
        >
          <ReduxServiceCodeForm
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.individualConsumableData}
            // onCancel={this.props.onCancel}
            onCancel={() => {
              this.setState(
                {
                  ...this.state,
                  createAndEditGroupModal: false,
                  individualConsumableData: null,
                },
                () => {
                  this.props.dispatch(resetCenterConsumableGet({}));
                },
              );
            }}
            Delete={() => {
              this.Removetoggle();
              //this.props.history.push('/manage/courses')
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  center_consumable_get_all,
  center_consumable_get,
  center_consumable_get_upsert,
  center_consumable_delete,
}) => {
  const { data, isFetching } = center_consumable_get_all;
  const { data: isUpsertSuccessData } = center_consumable_get_upsert;
  // const { data: individualConsumable } = center_consumable_Get;
  let centerConsumableList = [];
  let individualConsumableData = null;
  let isUpsertSuccess = null;
  let isDeleted = null;
  if (center_consumable_get_all && center_consumable_get_all.data) {
    centerConsumableList = data.CenterConsumable || [];
  }
  if (center_consumable_get && center_consumable_get.data) {
    individualConsumableData =
      (center_consumable_get.data &&
        center_consumable_get.data.CenterConsumable &&
        center_consumable_get.data.CenterConsumable[0]) ||
      {};
  }
  if (
    center_consumable_get_upsert &&
    center_consumable_get_upsert.data &&
    center_consumable_get_upsert.data.success
  ) {
    isUpsertSuccess = isUpsertSuccessData && isUpsertSuccessData.success;
  }
  if (
    center_consumable_delete &&
    center_consumable_delete.data &&
    center_consumable_delete.data.success
  ) {
    isDeleted = center_consumable_delete.data.success;
  }
  return {
    centerConsumableList,
    isFetching,
    individualConsumableData,
    isUpsertSuccess,
    isDeleted,
  };
};
export default connect(mapStateToProps)(ServiceCode);
const selector = formValueSelector('serviceCodeForm');
