const days = [
  {
    Value: '1',
    Text: 'Daily',
    ValueColumnName: 'reportIn',
  },
  {
    Value: '2',
    Text: 'Weekly',
    ValueColumnName: 'reportIn',
  },
  {
    Value: '3',
    Text: 'Monthly',
    ValueColumnName: 'reportIn',
  },
];
const supervisionType = [
  { Value: '1', Text: 'Phone', ValueColumnName: 'Phone' },
  { Value: '2', Text: 'Letter', ValueColumnName: 'Letter' },
  { Value: '3', Text: 'In Person', ValueColumnName: 'In Person' },
];
const pending = [
  { Value: '1', Text: 'Warrant for arrest', ValueColumnName: 'Warrant for arrest' },
  { Value: '2', Text: 'Court Appearance', ValueColumnName: 'Court Appearance' },
  { Value: '3', Text: 'Criminal charges', ValueColumnName: 'Criminal charges' },
  { Value: '4', Text: 'Sentencing', ValueColumnName: 'Sentencing' },
];
const medicalHistory = [
  { Value: '1', Text: 'Self', ValueColumnName: 'Self' },
  { Value: '2', Text: 'Parents ', ValueColumnName: 'Parents' },
  { Value: '3', Text: 'Grandparents', ValueColumnName: 'Grandparents' },
  { Value: '4', Text: 'Siblings', ValueColumnName: 'Siblings' },
];
const allergies = [
  { Value: '1', Text: 'Medicines', ValueColumnName: 'allergies' },
  { Value: '2 ', Text: 'Food', ValueColumnName: 'allergies' },
  { Value: '3', Text: 'Other', ValueColumnName: 'allergies' },
];
const treatment = [
  { Value: '1', Text: 'Probation Officer', ValueColumnName: 'Probation Officer' },
  { Value: '2', Text: 'Court (jurisdiction)', ValueColumnName: 'Court (jurisdiction)' },
];
const specialtiesOptions = [
  { Text: 'ADHD', Value: 'ADHD' },
  { Text: 'Couples', Value: 'Couples' },
  { Text: 'Life coaching', Value: 'Life coaching' },
  { Text: 'Alcohol use', Value: 'Alcohol use' },
  { Text: 'Depression', Value: 'Depression' },
  {
    Text: 'Partner of a person with a substance user disorder',
    Value: 'Partner of a person with a substance user disorder',
  },
  { Text: 'Anger management', Value: 'Anger management' },
  { Text: 'Drug abuse', Value: 'Drug abuse' },
  { Text: 'Sexual addiction', Value: 'Sexual addiction' },
  { Text: 'Anxiety', Value: 'Anxiety' },
  { Text: 'Eating disorders', Value: 'Eating disorders' },
  { Text: 'Shopping', Value: 'Shopping' },
  { Text: 'Body dysmorphia', Value: 'Body dysmorphia' },
  { Text: 'Faith', Value: 'Faith' },
  { Text: 'Smoking', Value: 'Smoking' },
  { Text: 'Cognitive behavioral therapy', Value: 'Cognitive behavioral therapy' },
  { Text: 'Family conflict', Value: 'Family conflict' },
  { Text: 'Substance Abuse', Value: 'Substance Abuse' },
  { Text: 'Codependency', Value: 'Codependency' },
  { Text: 'Gambling / Gaming', Value: 'Gambling / Gaming' },
  // { Text: 'Other', Value: 'Other' },
  { Text: 'Coping skills', Value: 'Coping skills' },
  { Text: 'Grief', Value: 'Grief' },
];
const weekDay = [
  { Value: '1', Text: 'SUNDAY', ValueColumnName: 'SUNDAY' },
  { Value: '2', Text: 'MONDAY', ValueColumnName: 'MONDAY' },
  { Value: '2', Text: 'TUESDAY', ValueColumnName: 'TUESDAY' },
  { Value: '2', Text: 'WEDNESDAY', ValueColumnName: 'WEDNESDAY' },
  { Value: '2', Text: 'THURSDAY', ValueColumnName: 'THURSDAY' },
  { Value: '2', Text: 'FRIDAY', ValueColumnName: 'FRIDAY' },
  { Value: '2', Text: 'SATURDAY', ValueColumnName: 'SATURDAY' },
];
const staticData = {
  days,
  supervisionType,
  pending,
  treatment,
  medicalHistory,
  allergies,
  specialtiesOptions,
};
export default staticData;
