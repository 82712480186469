import React, { useEffect, useState } from 'react';
import { Collapse, Checkbox } from 'antd';

const { Panel } = Collapse;
const CheckboxGroup = Checkbox.Group;

export default function CollapseWithCheckbox(props) {
  let [plainFilterData, setPlainFilterData] = useState([]);
  let [initialPlainFilterData, setInitialPlainFilterData] = useState([]);

  useEffect(() => {
    let options;
    if (props.filterData.data) {
      options =
        props.filterData &&
        props.filterData.data &&
        props.filterData.data.map(item => {
          return { label: item.Text, value: item.Value };
        });
    } else if (props.filterData) {
      options =
        props.filterData &&
        props.filterData.map(item => {
          return { label: item.Text, value: item.Value };
        });
    }
    setPlainFilterData(options);
    setInitialPlainFilterData(options);
  }, [props.filterData]);

  const plainOptions = plainFilterData;
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    props.handleCheck(list, filterData.name || props.name);
  };
  const onCheckAllChange = e => {
    let checkedOptions = e.target.checked
      ? plainOptions.map(item => {
          return item.value;
        })
      : [];
    setCheckedList(checkedOptions);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    props.handleCheck(checkedOptions, filterData.name || props.name);
  };
  const onSearch = e => {
    let value = e.target.value;
    let options = [...initialPlainFilterData];
    let filteredOptions = [];
    if (value && value.length > 0) {
      filteredOptions =
        options &&
        options.length > 0 &&
        options.filter(item => {
          if (item.label.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        });
      setPlainFilterData(filteredOptions);
    } else {
      setPlainFilterData([...initialPlainFilterData]);
    }
  };

  const { filterData, collapsekey } = props;
  return (
    <div className="mb-3 broadcast-filter">
      <Collapse accordion={true}>
        <Panel header={filterData.name || props.name} key={collapsekey}>
          <input
            type="text"
            style={{ margin: '15px 0', border: '1px solid #959595', width: '135px' }}
            placeholder={`Search ${filterData.name || props.name}`}
            onChange={onSearch}
          />
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            Select all
          </Checkbox>
          <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
        </Panel>
      </Collapse>
    </div>
  );
}
