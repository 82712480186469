import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Badge, Dropdown, Space, Tooltip, Tag } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
// import { isComparer } from '../../../util/handler';
// import ContractRowRender from './contractRowRender';
const patientRowRender = (precord, index, indent, expanded) => {
  const columns = [
    {
      title: 'Contract Name',
      dataIndex: 'ContractName',
      key: 'ContractName',
      sorter: (a, b) => isComparer(a.ContractName, b.ContractName),
      render: (text, record) => {
        return (
          <div className="contract-status">
            <span>
              {text}
              {record.IsLastetSmartContract && (
                <Tooltip placeholder="top" title={'New Contract Available'}>
                  <Tag className="ml-2" color="green">
                    NEW
                  </Tag>
                </Tooltip>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
      sorter: (a, b) => isDateComparer(a.StartDate, b.StartDate),
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      key: 'EndDate',
      sorter: (a, b) => isDateComparer(a.EndDate, b.EndDate),
    },
    {
      title: 'Total Bundles',
      dataIndex: 'BundlesCount',
      key: 'BundlesCount',
      align: 'right',
      sorter: (a, b) => isNumericComparer(a.BundlesCount, b.BundlesCount),
    },
    {
      title: 'Total Services',
      dataIndex: 'BundleServicesCount',
      key: 'BundleServicesCount',
      align: 'right',
      sorter: (a, b) => isNumericComparer(a.BundleServicesCount, b.BundleServicesCount),
    },
    {
      title: 'Payer',
      dataIndex: 'PayersCount',
      key: 'PayersCount',
      align: 'right',
      sorter: (a, b) => isNumericComparer(a.PayersCount, b.PayersCount),
      //   render: (text, record) => <span>{text}</span>,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'Status',
    //   key: 'state',
    //   //   render: (text, record) => <span>{text}</span>,
    // },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => isComparer(a.Status, b.Status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        let status = {
          1: 'incomplete',
          2: 'pending',
          3: 'active',
          4: 'terminated',
          5: 'expired',
          6: 'delivered',
          7: 'declined',
        };
        return (
          <div className="contract-status">
            {text && <span className={record.StatusId && status[record.StatusId]}>{text}</span>}
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => (
        <span
          style={{
            color: '#4FACFE',
            fontSize: '14px',
            fontWeight: '700',
            cursor: 'pointer',
          }}
        >
          <Link
            to={`/payer/contract/client-profile?pId=${precord.ClientId}&cId=${record.SmartContractId}`}
          >
            <i class="far fa-eye form-control-icon" />
            &nbsp;View Contract
          </Link>
        </span>
      ),
    },
  ];

  let data = precord.SmartContracts ? JSON.parse(precord.SmartContracts) : [];
  return <Table columns={columns} dataSource={data} pagination={false} className="nested-tables" />;
};

export default patientRowRender;
