import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicGrid from '../Components/DynamicGrid';
import { Staff_List } from '../app-config/screenName';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import PageTitle from '../Components/PageTitle';
class Staff extends Component {
  state = {
    searchStr: null,
    filterBy: {
      FilterBy: 'Active',
      PractitionerId: '0',
    },
  };

  componentDidMount() {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.msg
    ) {
      toast.success(this.props.history.location.state.msg, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // onClose: () => {
        //   this.props.history.push('/manage/staff-management/staff-list');
        // },
      });
    }
  }

  render() {
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Staff" />
        <div class="content-area">
          <div class="row top-table-search mt-1 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Staff</h3>
            </div>
          </div>
          <div className="dynamic-grid">
            <DynamicGrid ScreenName={Staff_List} FilterBy={this.state.filterBy} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(Staff));
