import React, { Component } from 'react';

const FamilyProgram = props => {
  const { StatusData } = props;
  return (
    <>
      <div
        style={{
          // borderRadius: '12px',
          position: 'relative',
          marginTop: '-0.5rem',
        }}
        // className="mt-5"
      >
        <img
          src={`images/license/BreathalyzerSmallBG.png`}
          alt=""
          style={{ width: '100%', borderRadius: '12px' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '0',
            textAlign: 'center',
            top: '13%',
            //  left: '33%',
            //  width: '33%',
          }}
        >
          <div className="d-flex justify-content-center">
            <span style={{ position: 'relative', marginRight: '20px' }}>
              <img
                src={`images/license/Breathlyzer.svg`}
                alt=""
                style={{ width: '100%', height: '35px' }}
              />
            </span>
          </div>

          <p
            style={{
              fontWeight: '700',
              fontSize: '20px',
              margin: '-5px',
            }}
          >
            Breathlyzer
          </p>

          <p style={{ fontWeight: '600', fontSize: '14px', margin: '14px auto', width: '80%' }}>
            Breathlyzer feature license is an add-on to the existing patient management software. It
            enables users to check if a patient has consumed alcohol or not and provides rehab
            treatment accordingly.
          </p>
          <button
            className="btn text-center"
            onClick={() => {
              props.history.push('/manage/maintain-license');
            }}
          >
            Purchase Breathlyzer License
          </button>
        </div>
      </div>
    </>
  );
};
export default FamilyProgram;
