import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Table, Typography } from 'antd';
import { ReduxSelect } from '../../../component/custom-redux-components';
import { contractPeriodGraphGet } from '../../../store/actions';
import { Loader2 } from '../../../Components/Loader';

export default class ContractPeriods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterId: 'Last 3 Months',
      riskSeries: [],
      datesDropDown: [],
      riskOptions: {
        chart: {
          width: '700px',
          type: 'donut',
        },
        plotOptions: {
          pie: {
            //  startAngle: 0,
            //  endAngle: 270,
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Total Contracts',
                  color: '#000',
                  formatter: function(w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        legend: {
          position: 'bottom',
          formatter: function(val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
          },
        },
        stroke: {
          width: 0,
        },

        tooltip: {
          enabled: true,
        },
        colors: ['#6DEAB3', '#FFE194', '#FF6B6B'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  Columns = [
    {
      title: 'Risk Level',
      dataIndex: 'TimeInProgram',
      key: 'TimeInProgram',
    },
    {
      title: 'Contracts',
      dataIndex: 'Total',
      key: 'Total',
      align: 'right',
      render: (text, record) => {
        return (
          <div className="">
            <span>
              {new Intl.NumberFormat('en-US', {
                maximumSignificantDigits: 7,
              }).format(text)}
            </span>
          </div>
        );
      },
    },
  ];

  componentWillReceiveProps({ periodGraph }) {
    if (periodGraph && periodGraph != this.props.periodGraph) {
      const { ContractPeriods, DatesDropDown } = periodGraph;
      let optionLabels = [];
      let values = [];
      ContractPeriods &&
        ContractPeriods.map((item, index) => {
          optionLabels.push(item.Description);
          values.push(item.TotalContracts);
        });
      this.setState({
        riskSeries: [...values],
        riskOptions: { ...this.state.riskOptions, labels: optionLabels },
      });

      // if (DatesDropDown && DatesDropDown.length > 0) {
      //      this.handleContractPeriod(
      //        DatesDropDown[0] ? DatesDropDown[0].StartDate : null,
      //        DatesDropDown[0] ? DatesDropDown[0].EndDate : null,
      //      );
      // }

      this.setState({
        // datesDropDown: DatesDropDown,
        // filterId: DatesDropDown && DatesDropDown.length > 0 ? DatesDropDown[0].Value : null,
      });
    }
  }

  handleFilter = filterId => {
    const { datesDropdown } = this.props;
    this.setState({ filterId });
    let dates =
      datesDropdown &&
      datesDropdown.filter((item, index) => {
        return item.Value == filterId;
      });
    this.handleContractPeriod(dates[0] ? dates[0].StartDate : null, dates[0] ? dates[0].EndDate : null);

  };

  handleContractPeriod = (startDate, endDate) => {
    this.props.dispatch(
      contractPeriodGraphGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: this.props.centerId || null,
              StartDate: startDate,
              EndDate: endDate,
            },
          ],
        }),
      }),
    );

  }

  render() {
    const { riskLevel, periodGraph, datesDropdown, isPeriodFetching } = this.props;
    const { riskOptions, riskSeries, filterId } = this.state;
    return (
      <div className="col-12 payer-contracts-period">
        <div className="card border-0 alerts mb-5">
          <div className="card-body dashboard-analytics">
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              style={{ borderBottom: '1px solid #CCC', padding: '16px' }}
            >
              <p className="heading">Contract Periods</p>

              <div className="d-flex align-items-center payer-portal-center">
                <div className="form-group mb-0" style={{ width: '160px' }}>
                  <ReduxSelect
                    name={`filterId`}
                    // component={ReduxSelect}
                    options={datesDropdown}
                    onChange={this.handleFilter}
                    value={this.state.filterId}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ position: 'relative' }}
            >
              <Loader2 loading={isPeriodFetching} />
              <ReactApexChart options={riskOptions} series={riskSeries} type="donut" width={400} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
