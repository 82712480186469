import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { Switch, Checkbox, Radio } from 'antd';
import QuestionConfigAction from '../questionConfigAction';

class AlphaNumeric extends React.Component {
  constructor(props) {
    super(props);

    let { Response, Note, ResponseThreshold, IsMandatory, AddNote } = props.survey;
    this.state = {
      value: ResponseThreshold,
      selectedValue: null,
      radioSelectedValue: Response || null,
      mandateChecked: IsMandatory,
      noteCheck: AddNote,
    };
  }

  onTextChange = (event, click) => {
    const { value } = event.target;
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
    } = this.props;
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: value,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      // OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: `ResponseThreshold`,
    })();
  };

  onChecked = (response, key) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      setToggle,
    } = this.props;
    if (key == 'IsMandatory') {
      this.setState({
        mandateChecked: response,
      });
    } else if (key == 'AddNote') {
      this.setState({
        noteCheck: response,
      });
    }
    setToggle.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: response,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      // OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: key,
    })();
  };

  onChangeRadio = (val, OIndex) => {
    // let val = event.target.value;
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
    } = this.props;
    this.setState({
      radioSelectedValue: val,
    });
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: !val,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: `Option${OIndex}QF`,
    })();
  };

  handleChange = value => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
    } = this.props;
    this.setState({
      selectedValue: value,
    });
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: value,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      // OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: `ThresholdSign`,
    })();
  };
  onChange = (event, click) => {
    const { value } = event.target;
    this.setState({
      value: value,
    });
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      SurveyQuestionList,
      activeKey,
      onQuestionStateChange,
      isAdmin,
    } = this.props;
    let radioValue;

    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option1QF,
      Option2Value,
      Option2Text,
      Option2QF,
      Option3Value,
      Option3Text,
      Option3QF,
      Option4Value,
      Option4Text,
      Option4QF,
      Option5Value,
      Option5Text,
      Option5QF,
      Option6Value,
      Option6Text,
      Option6QF,
      Option7Value,
      Option7Text,
      Option7QF,
      Option8Value,
      Option8Text,
      Option8QF,
      Option9Value,
      Option9Text,
      Option9QF,
      Option10Value,
      Option10Text,
      Option10QF,
      Response,
      Note,
      SurveyQuestionId,
      SectionId,
      DisplayOrder,
      ResponseThreshold,
      ThresholdSign,
      IsArchived,
      SurveyQuestionCategoryConfigId,
      IsNumeric,
      
    } = survey;

    const flagArray = [
      Option1QF,
      Option2QF,
      Option3QF,
      Option4QF,
      Option5QF,
      Option6QF,
      Option7QF,
      Option8QF,
      Option9QF,
      Option10QF,
    ];
    // const RArray = ['Mark it as ‘Qualifier’', 'Mark it as ‘Flagged’'];
    const RArray = ['Mark it as ‘Flagged’'];

    return (
      <div className="type-border col-12 mb-3 toggle-radio">
        <div className="d-flex justify-content-between align-items-start pb-2">
          <div className="">
            {Question && Question.length > 0 && (
              <h5 className="survey-question rcm-question text-left mb-2">
                {!IsArchived && `${sequenceNum + 1}.`} {renderHTML(Question)}
              </h5>
            )}

            <div className="d-flex align-items-center" style={{ marginTop: '1em' }}>
              <div className="numbers-row disabled-field">
                <input
                  type={'text'}
                  value={this.state.value}
                  tabindex="0"
                  // maxlength="3"
                  // placeholder="Enter Answer"
                  style={{
                    textAlign: 'center',
                    padding: '2px',
                    maxWidth: '250px',
                    border: '1px solid gray',
                    minHeight: '30px',
                    marginRight: '5px',
                  }}
                  className="alphanumeric-type"
                  onChange={this.onChange}
                  onBlur={this.onTextChange}
                  disabled={true}
                />
              </div>
            </div>
           { !isAdmin && <div className="d-flex mt-4">
              <label class="custom-control-label mr-2" for="is-mandatory">
                <Switch
                  name="isQuestionMandatory"
                  // checked={value}
                  // onChange={onChange}
                  unCheckedChildren="No"
                  checkedChildren="Yes"
                  size="small"
                  disabled={IsArchived}
                  checked={this.state.mandateChecked}
                  onChange={e => this.onChecked(e, 'IsMandatory')}
                />
                Mark as mandatory
              </label>
              <label class="custom-control-label" for="isAddNote">
                <Switch
                  name="isAddNote"
                  // checked={value}
                  // onChange={onChange}
                  unCheckedChildren="No"
                  checkedChildren="Yes"
                  size="small"
                  disabled={IsArchived}
                  checked={this.state.noteCheck}
                  onChange={e => this.onChecked(e, 'AddNote')}
                />
                Enable Add Note
              </label>
            </div>}
          </div>
          <div>
            <QuestionConfigAction {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
export default AlphaNumeric;
