import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import renderHTML from 'react-render-html';
import { connect } from 'react-redux';
import { getPatientList } from '../store/actions';

class SearchPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: '',
      searchedClient: {},
    };
  }

  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    var data = {
      Client: [
        {
          Search: value,
          FilterBy: 'Active',
        },
      ],
    };
    var appointmentData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(getPatientList(appointmentData));

    // Json - {"Client":[{"Search":"SHARMA"}]}
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };

  componentDidMount() {
    this.props.dispatch(
      getPatientList({
        Json: JSON.stringify({
          Client: [
            {
              FilterBy: 'Active',
            },
          ],
        }),
      }),
    );
  }

  render() {
    const { searchStr } = this.state;
    return (
      <div class="main survery-question-page  mb-5">
        <Autocomplete
          // getItemValue={item => `${item[SearchOption[TabName].idKey]}`}
          getItemValue={item => {
            return JSON.stringify(item.ClientId);
          }}
          // items={SearchOption[TabName].SearchOption.map(item => {
          //   return {
          //     label: item.DisplayName,
          //     ...item,
          //   };
          // })}
          items={this.props.client.map(item => {
            return {
              label: ` ${item.FirstName} ( ${item.ClientId} )`,
              ...item,
            };
          })}
          // renderItem={(item, isHighlighted) => {
          //   return (
          //     <div
          //       key={item[SearchOption[TabName].idKey]}
          //       style={{
          //         margin: '5px',
          //         background: isHighlighted ? 'lightgray' : 'white',
          //       }}
          //     >
          //       {item.label}
          //     </div>
          //   );
          // }}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? 'lightgray' : 'white',
                padding: '10px 5px',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {renderHTML(
                `<img class="autoSelectImage" src=${
                  item.ProfileImgUrl ? item.ProfileImgUrl : 'images/blank-profile.png'
                }  width="40" height="40">`,
              )}
              &nbsp; &nbsp;{' '}
              <span style={{ color: 'black', fontWeight: 'bold' }}>{item.LastName}</span>
              {item.LastName && ','}&nbsp; <span style={{ color: 'black' }}>{item.FirstName}</span>{' '}
              &nbsp; <span style={{ color: 'grey' }}>({item.ClientId})</span>
            </div>
          )}
          // value={value}
          // onChange={e => (value = e.target.value)}
          // onSelect={val => (value = val)}

          onChange={this.studentSearch}
          ref={ref => (this.input = ref)}
          // onChange={e => {
          //   this.setState({ searchStr: e.target.value });
          //   this.props.dispatch(
          //     SearchOption[TabName].dispatchMethod({ SearchStr: e.target.value }),
          //   );
          // }}

          onSelect={value => {
            if (this.props.client.length > 0) {
              let searchResult = this.props.client.filter(item => {
                if (item.ClientId == parseInt(value)) {
                  return item;
                } else {
                  return null;
                }
              });
              this.props.setSearchClientValues(searchResult[0]);
              this.setState({ searchedClient: searchResult[0] });
            } else {
              return [];
            }

            // this.props.history.push(SearchOption[TabName].pushKey + value);
          }}
          value={this.state.searchStr}
          // wrapperStyle={{ display: 'unset' }}
          renderInput={props => (
            <input
              type="text"
              {...props}
              class="form-control shadow-sm search-placeholder"
              placeholder="Search Patient"
              style={{
                paddingLeft: '30px',
                background: 'rgb(255, 255, 255)',
                boxShadow: 'rgba(0, 0, 0, 0.043) 0px 7px 28px',
                borderRadius: ' 26px',
              }}
            />
          )}
          menuStyle={{
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: searchStr ? '4px 4px' : '0px 4px',
            fontSize: '90%',
            position: 'fixed',
            overflow: 'auto',
            maxHeight: '50%',
            zIndex: 2,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { get_patient_list } = state;
  const { data: getPatientListData } = get_patient_list;

  let Client = [];

  if (getPatientListData && getPatientListData.data) {
    Client = JSON.parse(getPatientListData.data)[0].Client;
  }

  return {
    client: Client || [],
  };
};

export default connect(mapStateToProps)(SearchPatient);
