import React, { Component } from 'react';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class NumericOneLine extends React.Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      value: Response,
    };
  }
  onChange = (event, click) => {
    let value = event.target.value;
    value = value.length > 0 ? parseInt(value) : null;
    let { MaxValue, MinValue } = this.props.survey;
    if (MinValue <= value && value <= MaxValue) {
      this.setState({
        value: value,
      });
    } else if (value == null) {
      this.setState({
        value: value,
      });
    } else {
      return;
    }
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    return (
      <>
        <div className="type-border  pl-3">
          <div class="answer-options text-left mb-0 pb-0">
            <SurveyQuestion survey={survey} ClassName={'d-inline'} />
            <div class="quantity-btn d-inline alpha-numeric-oneline">
              <div class="numbers-row">
                <input
                  disabled={isReadOnly}
                  type="number"
                  value={this.state.value}
                  tabindex="0"
                  min="0"
                  placeholder="Enter Answer"
                  style={{ textAlign: 'left', paddingLeft: '10px', paddingBottom: '0px' }}
                  className="alphanumeric-type numbers-only"
                  onChange={this.onChange}
                  onBlur={() => {
                    setValue.call(scope, {
                      response: this.state.value,
                      surveyQuestionId: SurveyQuestionId,
                      level: level,
                      subLevel: subLevel,
                      subCatLevel: subCatLevel,
                      index: index,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <DoesNotApplyOption surveyProps={this.props} />
        </div>
      </>
    );
  }
}
export default NumericOneLine;
