import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  smartContractRequestGet,
  smartContractRequestUpdate,
  resetSmartContractRequestUpdate,
} from '../../../../../../store/actions';
import { Loader2 } from '../../../../../../Components/Loader';

const RequestDetailsDrawer = props => {
  const [parentDrawerData, setParentdrawerData] = useState(null);
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     let Data = {
  //       JSON: JSON.stringify({
  //         TimeLine: [{ ClientId: props.clientId, PayerStaffRequestId: props.payerStaffId }],
  //       }),
  //     };
  //     dispatch(smartContractRequestGet(Data));
  //   }, []);



  useEffect(() => {
      if (
        props.drawerData &&
        JSON.stringify(parentDrawerData) != JSON.stringify(props.drawerData)
      ) {
        setParentdrawerData(props.drawerData);
      }
    }, [props.drawerData]);


  useEffect(() => {
    if (props.isSuccess) {
      let Data = {
        JSON: JSON.stringify({
          TimeLine: [
            {
              ClientId: props.clientId,
              PayerStaffRequestId: props.payerStaffId,
              SmartContractId: props.smartContractId,
            },
          ],
        }),
      };
      dispatch(smartContractRequestGet(Data));
      dispatch(resetSmartContractRequestUpdate({}));
    }
  }, [props.isSuccess]);

  const individualEventRequestUpdate = (eventTypeId, payerStaffTimeLineId, isAccepted) => {
    let Data = {
      JSON: JSON.stringify({
        TimeLine: [
          {
            ClientId: props.clientId,
            EventTypeId: eventTypeId,
            PayerPortalRequestId: props.payerPortalRequestId,
            PayerStaffTimeLineId: payerStaffTimeLineId,
            IsAccepted: isAccepted,
          },
        ],
      }),
    };
    dispatch(smartContractRequestUpdate(Data));
  };

  const eventAllRequestUpdate = isAccepted => {
    let Data = {
      JSON: JSON.stringify({
        TimeLine: [
          {
            ClientId: props.clientId,
            PayerPortalRequestId: props.payerPortalRequestId,
            IsAll: true,
            IsAccepted: isAccepted,
          },
        ],
      }),
    };
    dispatch(smartContractRequestUpdate(Data));
  };
  const { AttachmentUrl, TimeLine, RequestedOn, RequestedInformation, RequestedBy } =
    parentDrawerData || [];

  return (
    <div className="container-fluid">
      <Loader2 loading={props.isUpdateFetching} />
      <div className="d-flex justify-content-between">
        <div>
          <p className="client-label mb-0">Requested By</p>
          <p className="client-text mb-0">{RequestedBy && RequestedBy}</p>
        </div>
        <div>
          <p className="client-label mb-0">Requested On</p>
          <p className="client-text mb-0">{RequestedOn && RequestedOn}</p>
        </div>
      </div>
      <>
        {' '}
        <div className="d-flex justify-content-between" style={{ margin: '27px 0' }}>
          <p className="mb-0" style={{ color: 'rgba(0,0,0,0.4)', fontWeight: '800' }}>
            Request Information
          </p>
          <div className="d-flex">
            <p
              className="mb-0"
              style={{ color: 'red', fontWeight: '600', cursor: 'pointer' }}
              onClick={() => eventAllRequestUpdate(false)}
            >
              Deny All Requests
            </p>
            <p
              className="mb-0 ml-3"
              style={{ color: '#52c41a', fontWeight: '600', cursor: 'pointer' }}
              onClick={() => eventAllRequestUpdate(true)}
            >
              Approve All Requests
            </p>
          </div>
        </div>
      </>

      {TimeLine &&
        TimeLine.map((item, index) => {
          const { EventTypeName, IsAccepted, EventTypeId, PayerStaffTimeLineId } = item;
          return (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ backgroundColor: 'white', width: '100%', margin: '10px 0', padding: '10px' }}
            >
              <p className="mb-0" style={{ fontWeight: '600', fontSize: '18px' }}>
                {EventTypeName}
              </p>
              {IsAccepted == null && (
                <div className="d-flex">
                  <a
                    className="btn-deny d-flex align-items-center"
                    style={{ cursor: 'pointer', width: 'fit-content' }}
                    onClick={() =>
                      individualEventRequestUpdate(EventTypeId, PayerStaffTimeLineId, false)
                    }
                  >
                    <img src="images/deny.svg" />
                    <p className="mb-0 ml-2">Deny</p>
                  </a>

                  <a
                    className="btn-approved d-flex align-items-center"
                    style={{ cursor: 'pointer', width: 'fit-content' }}
                    onClick={() =>
                      individualEventRequestUpdate(EventTypeId, PayerStaffTimeLineId, true)
                    }
                  >
                    <img src="images/approve.svg" />
                    <p className="mb-0 ml-2">Approve</p>
                  </a>
                </div>
              )}
              {IsAccepted == true && (
                <div className="d-flex">
                  <a
                    className="btn-approved d-flex align-items-center"
                    style={{ width: 'fit-content', border: 'none' }}
                  >
                    <img src="images/approve.svg" />
                    <p className="mb-0 ml-2">Approved</p>
                  </a>
                </div>
              )}

              {IsAccepted == false && (
                <div className="d-flex">
                  <a
                    className="btn-deny d-flex align-items-center"
                    style={{ width: 'fit-content', border: 'none' }}
                  >
                    <img src="images/deny.svg" />
                    <p className="mb-0 ml-2">Denied</p>
                  </a>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = state => {
  const { smart_contract_request_update, smart_contract_request_get } = state;
  const { data: updateData, isFetching: isUpdateFetching } = smart_contract_request_update;
  const { data, isFetching } = smart_contract_request_get;

  return {
    isSuccess: updateData && updateData.success,
    isUpdateFetching: isUpdateFetching || isFetching,
  };
};

export default connect(mapStateToProps)(RequestDetailsDrawer);
