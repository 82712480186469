import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import renderHTML from 'react-render-html';
import Legal from './tabs/legal';
import Physical from './tabs/physical';
import Medical from './tabs/medical';
import PriorCare from './tabs/PriorCare';
import Financial from './tabs/financial';
import ProspectBanner from '../ProspectBanner';
// import ConfigutationTab from './tabs/ConfigutationTab';
import ConfigutationTab from '../ConfigutationTab';

import {
  listSurveyQuestionAssessment,
  surveyAnswerUpsert,
  augmentedClientInsightsGet,
  surveyLatestAnswerDateUpdate,
  studentBanner,
} from '../../../../store/actions';
import { MandatoryWarning } from './mandatoryWarning';
import { Loader2 } from '../../../../Components/Loader';

const { TabPane } = Tabs;

class ClientScreening extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskResponse: null,
      isMandatory: false,
      tabMapping: {
        0: 'legal',
        1: 'physical',
        2: 'medical',
        3: 'prior-care',
        4: 'financial',
      },
      tabLabels: [
        {
          Name: 'Legal',
          Component: props => <Legal {...props} name={'Legal'} />,
        },
        {
          Name: 'Physical',
          Component: props => <Physical {...props} name={'Physical'} />,
        },
        {
          Name: 'Medical',
          Component: props => <Medical {...props} name={'Medical'} />,
        },
        {
          Name: 'Prior Care',
          Component: props => <PriorCare {...props} name={'PriorCare'} />,
        },
        {
          Name: 'Financial',
          Component: props => <Financial {...props} name={'Financial'} />,
        },
      ],
      activeKey: 0,
    };
  }
  getIntakeId = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentId = param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = null;
    }
    return studentId;
  };
  getSurveyId = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let surveyid = param.get('surveyid');
    if (surveyid) {
      surveyid = parseInt(surveyid);
    } else {
      surveyid = null;
    }
    return surveyid;
  };
  isMandatoryQuestion = index => {
    let QuestionList = this.state.SurveyQuestionList.SurveyQuestion[index].Questions;
    let isAnalyze =
      QuestionList &&
      QuestionList.some(item => {
        return !item.Response && item.IsRequired === true;
      });
    return isAnalyze;
  };
  onTabClick = (key, abc) => {
    let keyData = parseInt(key);
    let clickedTab = this.state.tabMapping[keyData];
    // this.setState({
    //   activeKey: parseInt(key),
    //   isMandatory: false,
    // });
    // this.props.history.push(
    //   `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
    //     this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    // );
    // window.scrollTo(0, 0);

    // the below commented code maybe useful in future that's why i am not removing it

    // if (parseInt(key) < this.state.activeKey) {
    //   this.setState({
    //     activeKey: parseInt(key),
    //     isMandatory: false,
    //   });
    //   this.props.history.push(
    //     `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
    //     this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    //   );
    // } else {
    //   if (this.isMandatoryQuestion(this.state.activeKey)) {
    //     this.setState({
    //       isMandatory: true,
    //     });
    //     window.scrollTo(0, 0);
    //   } else {
    //     this.setState({
    //       activeKey: parseInt(key),
    //       isMandatory: false,
    //     });
    //     this.props.history.push(
    //       `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
    //         this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    //     );
    //   }
    // }

    if (this.isMandatoryQuestion(this.state.activeKey)) {
      this.setState({
        isMandatory: true,
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        activeKey: parseInt(key),
        isMandatory: false,
      });
      this.props.history.push(
        `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
          this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
      );
    }
  };
  getRisk = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            IsDefaultPreIntakeSurvey: true,
          },
        ],
      }),
    };
    Axios.post('Survey/ClientRiskGet', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            riskResponse: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  handleNext = index => {
    // let QuestionList = this.state.SurveyQuestionList.SurveyQuestion[index].Questions;
    // let isAnalyze = QuestionList.some(item => {
    //   return !item.Response && item.IsRequired === true;
    // });
    let keyData = index + 1;
    let clickedTab = this.state.tabMapping[keyData];
    // this.setState({
    //   activeKey: `${index + 1}`,
    //   isMandatory: false,
    // });
    // window.scrollTo(0, 0);
    // this.props.history.push(
    //   `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
    //     this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    // );
    if (this.isMandatoryQuestion(index)) {
      this.setState({
        activeKey: `${index}`,
        isMandatory: true,
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        activeKey: `${index + 1}`,
        isMandatory: false,
      });
      this.props.history.push(
        `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
          this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
      );
    }
  };

  goToPreviuos = index => {
    /*window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });*/
    let keyData = index - 1;
    let clickedTab = this.state.tabMapping[keyData];
    // this.setState({
    //   activeKey: `${index - 1}`,
    //   isMandatory: false,
    // });
    // this.props.history.push(
    //   `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
    //     this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    // );
    // window.scrollTo(0, 0);
    // if (this.isMandatoryQuestion(index)) {
    // this.setState({
    //   activeKey: `${index}`,
    //   isMandatory: true,
    // });
    // window.scrollTo(0, 0);
    // } else {
    this.setState({
      activeKey: `${index - 1}`,
      isMandatory: false,
    });
    this.props.history.push(
      `/pre-intake-form?step=client-screening&tab=${clickedTab}&studentid=${this.props.ID ||
        this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    );
    // }
    // this.setState({ activeKey: index - 1 });
  };
  componentDidMount(props) {
    // const { tabMapping } = this.state;
    // var params = new URLSearchParams(this.props.location.search);
    // var tab = params.get('tab') || tabMapping[0];
    // if (tabMapping) {
    //   this.setState({
    //     activeKey: Object.values(tabMapping).indexOf(tab),
    //   });
    // }
    this.props.dispatch(
      listSurveyQuestionAssessment({
        json: JSON.stringify({
          Survey: [
            {
              StudentId: this.getIntakeId(),
              SurveyId: this.getSurveyId(),
              SurveyType: 'ClientScreening',
            },
          ],
        }),
      }),
    );
    this.getRisk();
  }
  componentWillReceiveProps({ surveyQuestion, isAnswerUpsert }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      this.setState({ SurveyQuestionList: surveyQuestion, tabMapping: {} }, () => {
        this.state.SurveyQuestionList &&
          this.state.SurveyQuestionList.SurveyQuestion &&
          this.state.SurveyQuestionList.SurveyQuestion.map((item, index) => {
            const { Tab } = item;
            this.state.tabMapping[index] = Tab && Tab.toLowerCase().replace(' ', '-');
          });
        const { tabMapping } = this.state;
        var params = new URLSearchParams(this.props.location.search);
        var tab = params.get('tab') || tabMapping[0];
        let exists = Object.values(tabMapping).includes(tab);

        if (!exists) {
          tab = tabMapping[0];
          this.props.history.push(
            `/pre-intake-form?step=client-screening&tab=${tab}&studentid=${this.props.ID ||
              this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
          );
        }

        if (tabMapping) {
          this.setState({
            activeKey: Object.values(tabMapping).indexOf(tab),
          });
        }
      });
      this.props.getQuestionList(surveyQuestion);
    }
    if (isAnswerUpsert && isAnswerUpsert != this.props.isAnswerUpsert) {
      let data = {
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.getIntakeId(),
            },
          ],
        }),
      };
      this.props.dispatch(studentBanner(data));
    }
  }

  setValue = ({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
    note,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    // let tabName = tabLabels[activeKey].Name;
    var json = {
      StudentId: this.getIntakeId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      Note: note,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    if (level === 0) {
      let isRequired = response
        ? false
        : SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].IsRequired;

      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Response = response;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Note = note;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].IsRequired = isRequired;
      this.setState({ SurveyQuestionList, isMandatory: false });
      this.props.getQuestionList(SurveyQuestionList);
    }
    // const surveys = [...this.state.surveys];
    // for (var survey of surveys) {
    //   if (survey.SurveyQuestionId == surveyQuestionId) {
    //     survey.Response = response;
    //   }
    // }
    // if (IsSignature) {
    //   this.setState({
    //     surveys: surveys,
    //     Signature: IsSignature && Signature,
    //     SignatureCanvas: IsSignature && {
    //       ...this.state.SignatureCanvas,
    //       signatureDataList: {
    //         ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
    //         [surveyQuestionId]: {
    //           SurveyQuestionId: surveyQuestionId,
    //           isSignatureSaved: isSignatureSaved,
    //         },
    //       },
    //     },
    //   });
    // } else {
    //   this.setState({
    //     surveys: surveys,
    //   });
    // }

    this.props.dispatch(surveyAnswerUpsert(json));
  };
  onNext = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    isResetAnswer,
    note,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    // let tabName = tabLabels[activeKey].Name;
    let json = {
      StudentId: this.getIntakeId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      Note: note,
    };

    if (level === 0) {
      let isRequired = response
        ? false
        : SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].IsRequired;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Response = response;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Note = note;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].IsRequired = isRequired;

      this.setState({ SurveyQuestionList, isMandatory: false });
      this.props.getQuestionList(SurveyQuestionList);
    }
    // const surveys = [...this.state.surveys];
    // for (let survey of surveys) {
    //   if (survey.SurveyQuestionId == surveyQuestionId) {
    //     survey.Response = response;
    //   }
    // }

    // $('.main-banner').slick('slickNext');
    // this.setState({
    //   surveys: surveys,
    // });
    // if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
  };

  render() {
    const {
      activeKey,
      studentData,
      tabLabels,
      activeTabs,
      SurveyQuestionList,
      tabMapping,
    } = this.state;

    return (
      <div class="main enrollment-form">
        <div class="col-12">
          <ProspectBanner ClientId={this.props.ID || this.getIntakeId()} isflagged={true} />
        </div>
        {this.state.isMandatory && (
          // <span style={{ color: 'red' }}>Please answer all mandatory questions. abc</span>
          <MandatoryWarning />
        )}

        <div class="col-12 mb-2 vertical-tabs">
          <Loader2 loading={this.props.isFetchingSurveyQuestion} />
          {SurveyQuestionList && SurveyQuestionList.SurveyQuestion ? (
            <Tabs
              activeKey={String(activeKey)}
              tabPosition="left"
              defaultActiveKey="0"
              onTabClick={this.onTabClick}
              className="client-screening"
            >
              {SurveyQuestionList &&
                SurveyQuestionList.SurveyQuestion &&
                SurveyQuestionList.SurveyQuestion.map((item, index) => {
                  const { Tab, Sequence, Questions } = item;
                  const { SurveyQuestion, SurveyId } = SurveyQuestionList || {};
                  const tabsCount = SurveyQuestion.length;
                  // let Component = () => (
                  //   <ConfigutationTab
                  //     style={{ padding: '36px 20px 150px 20px' }}
                  //     dispatch={this.props.dispatch}
                  //     SurveyId={SurveyId}
                  //     SurveyQuestion={Questions}
                  //     onNext={this.onNext}
                  //     setValue={this.setValue}
                  //     isMandatory={this.state.isMandatory}
                  //     scope={this}
                  //     tabsCount={tabsCount}
                  //     index={index}
                  //     goToPreviuos={() => this.goToPreviuos(index)}
                  //     handleNext={() => this.handleNext(index)}
                  //     isAnswerUpsert={this.props.isAnswerUpsert}
                  //     isAnserFetching={this.props.isAnserFetching}
                  //     // onLastSubmit={onSubmit.bind(this)}
                  //     //   isReadOnlyUser={isReadOnlyUser}
                  //   />
                  // );
                  let isTab = Tab && Tab.length;
                  return (
                    <TabPane
                      tab={isTab && renderHTML(Tab)}
                      key={`${index}`}
                      className="tab-content"
                      style={{ overflow: 'inherit' }}
                    >
                      {activeKey == index && (
                        <ConfigutationTab
                          style={{ padding: '36px 20px 150px 20px' }}
                          dispatch={this.props.dispatch}
                          SurveyId={SurveyId}
                          SurveyQuestion={Questions}
                          onNext={this.onNext}
                          setValue={this.setValue}
                          isMandatory={this.state.isMandatory}
                          scope={this}
                          tabsCount={tabsCount}
                          index={index}
                          goToPreviuos={() => this.goToPreviuos(index)}
                          handleNext={() => this.handleNext(index)}
                          isAnswerUpsert={this.props.isAnswerUpsert}
                          isAnserFetching={this.props.isAnserFetching}
                          // onLastSubmit={onSubmit.bind(this)}
                          //   isReadOnlyUser={isReadOnlyUser}
                        />
                      )}
                    </TabPane>
                  );
                })}
            </Tabs>
          ) : (
            <div
              className="d-flex justify-content-center text-center flex-column align-items-center"
              style={{
                height: '500px',
                backgroundColor: 'white',
              }}
            >
              {/* <img src="images/category.svg" /> */}
              <h4 className="mt-4">
                Looks like there are no pre-configured questions available for the chosen service
                type or center in our Question Library. For additional support, kindly contact the
                center administrator and have them add questions under Admin &gt; Screening
                Questions Library. Please reach out to our chat agent if you have any questions!
              </h4>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { list_survey_question_assessment, survey_answer_upsert } = state;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = list_survey_question_assessment;
  const { data: upsertData, isFetching: isAnserFetching } = survey_answer_upsert;
  let surveyQuestion;
  let augmentedAnswer;
  let isAnswerUpsert = false;

  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }

  if (upsertData && upsertData.data && upsertData.data.success) {
    isAnswerUpsert = true;
  }

  return {
    surveyQuestion,
    isFetchingSurveyQuestion: isFetchingSurveyQuestion,
    augmentedAnswer,
    isAnswerUpsert,
    isAnserFetching,
  };
};

export default connect(mapStateToProps)(withRouter(ClientScreening));
