import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  termsConditionUpsert,
  resetTermsConditionUpsert,
  termsConditionGetAllInfoJson,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import AntdModal from '../../Components/CustomModal';
import CustomModal from '../../core/modal';
import TermsConditionsForm from './termsConditionsForm';
import PageTitle from '../../Components/PageTitle';
class TermsConditionsLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: {},
      isModelOpen: false,
    };
  }
  getConditionsId() {
    const param = new URLSearchParams(this.props.history.location.search);
    let termsConditionId = param.get('tcid');
    if (termsConditionId) {
      termsConditionId = parseInt(termsConditionId);
    } else {
      termsConditionId = null;
    }
    return termsConditionId;
  }
  getViewStatus() {
    const param = new URLSearchParams(this.props.history.location.search);
    let viewOnly = param.get('view');
    if (viewOnly) {
      viewOnly = JSON.parse(viewOnly);
    }
    return viewOnly;
  }
  componentDidMount() {
    if (this.getConditionsId()) {
      this.props.dispatch(
        termsConditionGetAllInfoJson({
          JsonInput: JSON.stringify({
            TermsCondition: [
              {
                TermsConditionId: this.getConditionsId(),
              },
            ],
          }),
        }),
      );
    }
  }
  componentWillReceiveProps({ termsConditionInfo, isUpsertTermsCondition, upsertMsg }) {
    if (
      termsConditionInfo &&
      JSON.stringify(termsConditionInfo) != JSON.stringify(this.props.termsConditionInfo)
    ) {
      let data = {
        TermsConditionId: termsConditionInfo.TermsConditionId,
        Title: termsConditionInfo.Title,
        TermId: termsConditionInfo.TermId,
        Conditions: termsConditionInfo.Condition,
        Terms: termsConditionInfo.TermsCategory,
        PayerId: termsConditionInfo.PayerId,
      };
      this.setState({
        initData: data,
      });
    }
    if (isUpsertTermsCondition && isUpsertTermsCondition != this.props.isUpsertTermsCondition) {
      this.toggle(upsertMsg);
      this.props.dispatch(resetTermsConditionUpsert());
    }
  }
  handleForm = values => {
    const { PayerId,TermId, Title, Terms, Conditions, TermsList } = values;
    const genrateTerms = terms => {
      let list = terms.map((termItem, index) => {
        let termDesc = TermsList && TermsList[index].descList;
        return {
          Category: termItem.Category,
          TermsCategoryId: termItem.TermsCategoryId || null,
          Terms: termDesc,
        };
      });

      return list;
    };
    const genrateCondition = condition => {
      let conditionList = condition.map((item, index) => {
        return {
          Condition: item.Condition,
          ConditionId: item.ConditionId || null,
          ConditionCase: item.ConditionCase.map((caseItem, caseIndex) => {
            return {
              CaseId: caseIndex + 1,
              CaseIf: caseItem.CaseIf,
              CaseThen: caseItem.CaseThen,
              ConditionCaseId: caseItem.ConditionCaseId || null,
            };
          }),
        };
      });

      return conditionList;
    };
    let data = {
      TermsCondition: [
        {
          PayerId,
          TermsConditionId: this.getConditionsId(),
          TermId: TermId,
          Title: Title,
          TermsCategory: genrateTerms(Terms),
          Condition: genrateCondition(Conditions),
        },
      ],
    };
    this.props.dispatch(
      termsConditionUpsert({
        JsonInput: JSON.stringify(data),
      }),
    );
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const { isModelOpen, header } = this.state;
    return (
      <div class="container-fluid pb-5">
        <PageTitle TitleText="Terms & Conditions" />
        <div class="content-area">
          <div class="row top-table-search mt-3 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/cw/manage/terms-conditions-list')}
                // onClick={() => this.props.history.goBack()}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">
                {this.getConditionsId() ? 'Edit' : 'Add'} Terms & Conditions
              </h3>
            </div>
          </div>
          <div className="card border-0 ">
            <div className="row card-body">
              <Loader2 loading={this.props.isFetching} />
              <div className="col-12">
                <TermsConditionsForm
                  handleForm={this.handleForm}
                  TermsConditionId={this.getConditionsId()}
                  disable={this.getViewStatus()}
                  initialValues={this.state.initData}
                  isFetching={this.props.isFetching}
                />
              </div>
            </div>
          </div>
        </div>
        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            header={header}
            yesAction={() => {
              this.toggle();
              this.props.history.push('/cw/manage/terms-conditions-list');
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ combos, terms_condition_get_all_info_json, terms_condition_upsert }) => {
  let termsConditionInfo = null;
  let isUpsertTermsCondition = null;
  let upsertMsg = null;

  const { data, isFetching, error } = terms_condition_get_all_info_json;
  const { data: upsertData, isFetching: isFetchingUpsert } = terms_condition_upsert;

  if (data && data.TermsCondition) {
    termsConditionInfo = data.TermsCondition[0];
    termsConditionInfo = {
      ...termsConditionInfo,
      TermId: termsConditionInfo.TermsConditionId,
    };
  }
  if (upsertData && upsertData.success) {
    isUpsertTermsCondition = upsertData.success;
    upsertMsg =
      (upsertData.data && upsertData.data[0].Msg) || 'Your changes have been saved successfully';
  }

  return {
    termsConditionInfo,
    isFetching: isFetching || isFetchingUpsert,
    isUpsertTermsCondition,
    upsertMsg,
  };
};

export default connect(mapStateToProps)(withRouter(TermsConditionsLibrary));
