import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Table, Badge, Dropdown, Space } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { smartContractListGet, smartContractClone } from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';
import { ReduxSelect } from '../../../component/custom-redux-components/index.js';
import GridSearch from '../../../Components/gridSearch';
import PageTitle from '../../../Components/PageTitle';
import CustomModal from '../../../core/modal';

// import DynamicGrid from '../../../Components/DynamicGrid';

class ContractList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
      isModelOpen123: false,
      FilterBy: [
        {
          Text: 'All',
          Value: 'All',
        },
        {
          Text: 'Active',
          Value: 1,
        },
        {
          Text: 'Confirmation Pending',
          Value: 2,
        },
        {
          Text: 'Declined',
          Value: 3,
        },
        {
          Text: 'Contract Incomplete',
          Value: 4,
        },
      ],
      filterBy: 'All',
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string,
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen123: !prevState.isModelOpen123,
      header,
    }));
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(smartContractListGet({}));
  }

  componentWillReceiveProps({ clonedMessage }) {
    if (clonedMessage && clonedMessage != this.props.clonedMessage) {
      this.toggle(clonedMessage);
    }
  }

  onSearchChange = value => {
    const { dispatch } = this.props;
    this.state.searchStr = value;
    let filterVal = this.state.filterBy === 'All' ? null : this.state.filterBy;
    dispatch(
      smartContractListGet({
        json: JSON.stringify({
          SmartContract: [
            {
              Filter: filterVal,
              Search: this.state.searchStr,
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { isFetching, contracts } = this.props;
    const { FilterBy, filterBy } = this.state;
    const columns = [
      {
        title: 'Contract Name',
        dataIndex: 'ContractName',
        key: 'ContractName',
        sorter: (a, b) => isComparer(a.ContractName, b.ContractName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Date',
        dataIndex: 'StartDate',
        key: 'StartDate',
        sorter: (a, b) => isDateComparer(a.StartDate, b.StartDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date',
        dataIndex: 'EndDate',
        key: 'EndDate',
        sorter: (a, b) => isDateComparer(a.EndDate, b.EndDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Total Bundles',
        dataIndex: 'BundleCount',
        key: 'BundleCount',
        sorter: (a, b) => isComparer(a.BundleCount, b.BundleCount),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div className="text-right">{text}</div>,
      },
      {
        title: 'Total Services',
        dataIndex: 'ServicesCount',
        key: 'ServicesCount',
        sorter: (a, b) => isNumericComparer(a.ServicesCount, b.ServicesCount),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div className="text-right">{text}</div>,
      },
      {
        title: 'Patient’s Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Payers',
        key: 'PayersCount',
        dataIndex: 'PayersCount',
        sorter: (a, b) => isNumericComparer(a.PayersCount, b.PayersCount),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div className="text-right">{text}</div>,
      },
      {
        title: 'Status',
        key: 'Status',
        dataIndex: 'Status',
        sorter: (a, b) => isComparer(a.Status, b.Status),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          let status = {
            1: 'incomplete',
            2: 'pending',
            3: 'active',
            4: 'terminated',
            5: 'expired',
            6: 'delivered',
          };
          return (
            <div className="contract-status">
              {text && <span className={status[record.StatusId]}>{text}</span>}
            </div>
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'Manage',
        key: 'Manage',
        render: (text, record) => {
          if (record.StatusId) {
            return (
              <>
                <span
                  style={{
                    color: '#4FACFE',
                    fontSize: '14px',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.props.history.push(
                      `/cw/patient-management/patient-profile?tab=smart-contract&smartContractId=${record.SmartContractId}&pId=${record.ClientId}`,
                    );
                  }}
                >
                  <i class="far fa-eye form-control-icon" />
                  &nbsp;View Contract
                </span>
                &nbsp; &nbsp;
                <span
                  style={{
                    color: '#4FACFE',
                    fontSize: '14px',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.props.dispatch(
                      smartContractClone({
                        Json: JSON.stringify({
                          SmartContract: [
                            {
                              SmartContractId: record.SmartContractId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  <i class="fa fa-clone" aria-hidden="true"></i> Clone
                </span>
              </>
            );
          } else {
            return;
          }
          // render: () => <button class="btn">View Contract</button>,
          // onCell: (record, rowIndex) => {
          //   return {
          //     onClick: event => this.onManageClick(record.CenterId),
          //   };
          // },
        },
      },
    ];
    return (
      <div class="w-100 rcm-panel rcm-patient-table">
        <PageTitle TitleText="Patient Management" />
        <div class="main enrollment-page mb-5">
          <div class="container-fluid  px-2 py-4">
            <div class="content-area">
              <div class="row top-table-search mt-1 mb-2 align-items-center">
                <div class="col-12 col-md-12 text-right d-flex align-items-center justify-content-end">
                  <div className="text-left app-subscriber-page d-inline-block">
                    <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                      <ReduxSelect
                        options={FilterBy}
                        onChange={val => {
                          this.props.dispatch(
                            smartContractListGet({
                              json: JSON.stringify({
                                SmartContract: [
                                  {
                                    Filter: val === 'All' ? null : val,
                                    Search: this.state.searchStr,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.setState({
                            filterBy: val,
                          });
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        value={filterBy}
                        placeholder="Filter By"
                      />
                    </div>
                  </div>
                  {/* <div class="search-box d-inline-flex w-100">
                    <div class="form-group has-search w-100">
                      <input
                        type="text"
                        class="form-control shadow-sm"
                        placeholder="Search"
                        id="filterbox"
                        onChange={this.onSearchChange}
                      />
                      <button class="btn search-icon " type="button">
                        {' '}
                        <i class="fa fa-search" />{' '}
                      </button>
                    </div>
                  </div> */}
                  <GridSearch onSearchChange={this.onSearchChange} />
                  {/* <button class="btn black-btn" type="button">
                    Add New Contract
                  </button> */}
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-5">
                  <div class="card border-0 ">
                    <div class="row card-body">
                      <div class="col-12">
                        {/* <Loader2 loading={isFetching} /> */}
                        <Table
                          columns={columns}
                          dataSource={contracts}
                          size={'middle'}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div class="dataTables_wrapper no-footer">
                                  <div class="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a class="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a class="paginate_button next">Next</a>
                                    ) : (
                                      <a class="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen123}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.dispatch(smartContractListGet({}));
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { default_values, smart_contract_list_get,smart_contract_clone } = state;
  const { data, isFetching, error } = smart_contract_list_get;
  const { data: smartContractCloneAction } = smart_contract_clone;
  let contracts = [];
  let isReadOnlyUser = false;
  let clonedMessage = null;
  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  if (data && data.data) {
    contracts = JSON.parse(data.data).SmartContract;
  }
  if (smartContractCloneAction && smartContractCloneAction.data) {
    clonedMessage =
      JSON.parse(smartContractCloneAction.data) &&
      JSON.parse(smartContractCloneAction.data).SmartContract &&
      JSON.parse(smartContractCloneAction.data).SmartContract[0] && 
    JSON.parse(
        smartContractCloneAction.data,
      ).SmartContract[0].Message;
  }
  return {
    isReadOnlyUser,
    contracts,
    isFetching,
    clonedMessage,
  };
};

export default withRouter(connect(mapStateToProps)(ContractList));
