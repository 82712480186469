import React, { Component } from 'react';
import ListRenderer from '../Components/listRenderer';
import PropTypes from 'prop-types';

let ContactUs = () => {
  return (
    <div class="contact-box help-contact">
      <div class="contact-time text-center">
        <h2>Mon - Fri</h2>
        <h5 class="d-block">8am - 8pm</h5>
      </div>
      <div class="card border-0 contact-number">
        <div class="card-body">
          <span class="d-block contact-label">Help line number</span>
          <a href="tel:855-800-8886" class="link2">
            855-800-8886
          </a>
        </div>
      </div>
      <div class="separator">
        <span>or</span>
      </div>
      <div class="card border-0 contact-email">
        <div class="card-body">
          <span class="d-block contact-label">Support Email</span>
          <a href="mailto:support@soberpeer.com" class="link2">
            support@soberpeer.com
          </a>
        </div>
      </div>
    </div>
  );
};
let SystemHelpVideos = () => {
  return (
    <div class="help-videos-grid help-vid">
      {/* <div class="video-col">
        <iframe
          src="https://www.youtube.com/embed/9xwazD5SyVg?rel=0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <div class="video-col">
        <iframe
          src="https://www.youtube.com/embed/9xwazD5SyVg?rel=0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <div class="video-col">
        <iframe
          src="https://www.youtube.com/embed/9xwazD5SyVg?rel=0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div> */}
    </div>
  );
};
let ELearningVideos = () => {
  return (
    <div class="tab-content">
      <div class="help-videos-grid help-vid">
        <br />
        {/* <div class="video-col">
          <iframe
            src="https://www.youtube.com/embed/9xwazD5SyVg?rel=0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div class="video-col">
          <iframe
            src="https://www.youtube.com/embed/9xwazD5SyVg?rel=0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div class="video-col">
          <iframe
            src="https://www.youtube.com/embed/9xwazD5SyVg?rel=0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div> */}
      </div>
    </div>
  );
};
let FAQs = () => {
  return (
    <div class="row help-faq">
      <div class="col-12">
        <ol class="faqs-list">
          <li>
            <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget?</h6>
            <p>
              Nihilne te nocturnum praesidium Palati, nihil urbis vigiliae. Inmensae subtilitatis,
              obscuris et malesuada fames. Tu quoque, Brute, fili mi, nihil timor populi, nihil!
              Plura mihi bona sunt, inclinet, amari petere vellent. Gallia est omnis divisa in
              partes tres, quarum. Idque Caesaris facere voluntate liceret: sese habere. Quis aute
              iure reprehenderit in voluptate velit esse. Donec sed odio operae, eu vulputate felis
              rhoncus. Ut enim ad minim veniam, quis nostrud exercitation.
            </p>
          </li>
          <li>
            <h6>Morbi fringilla convallis sapien, id pulvinar odio volutpat.</h6>
            <p>
              Contra legem facit qui id facit quod lex prohibet. Hi omnes lingua, institutis,
              legibus inter se differunt. Salutantibus vitae elit libero, a pharetra augue. Integer
              legentibus erat a ante historiarum dapibus. Magna pars studiorum, prodita quaerimus.
              Vivamus sagittis lacus vel augue laoreet rutrum faucibus. Unam incolunt Belgae, aliam
              Aquitani, tertiam. Fictum, deserunt mollit anim laborum astutumque! Idque Caesaris
              facere voluntate liceret: sese habere. Cum ceteris in veneratione tui montes, nascetur
              mus. Cras mattis iudicium purus sit amet fermentum.
            </p>
          </li>
          <li>
            <h6>Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh.</h6>
            <p>
              Qui ipsorum lingua Celtae, nostra Galli appellantur. Ab illo tempore, ab est sed
              immemorabili. Quam diu etiam furor iste tuus nos eludet? Prima luce, cum quibus mons
              aliud consensu ab eo. Mercedem aut nummos unde unde extricat, amaras. Quid securi
              etiam tamquam eu fugiat nulla pariatur. Magna pars studiorum, prodita quaerimus. Quam
              temere in vitiis, legem sancimus haerentia.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

const activeKey = 0;

class Help extends Component {
  constructor(props) {
    super();
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const list = [
      {
        Name: 'Contact Us',
        ExtraComponent: props => <ContactUs />,
      },

      // {
      //   Name: 'System Help Videos',
      //   ExtraComponent: props => <SystemHelpVideos />,
      // },

      // {
      //   Name: 'E-Learning Videos',
      //   ExtraComponent: props => <ELearningVideos />,
      // },

      // {
      //   Name: 'FAQ`s',
      //   ExtraComponent: props => <FAQs />,
      // },
      {
        Name: 'Knowledge Base',
        ExtraComponent: props => <></>,
      },
    ];

    // {
    //       window.open('https://soberpeer.atlassian.net/servicedesk/customer/portals');
    //       return null;
    //     },
    // window.location.href = 'https://soberpeer.atlassian.net/servicedesk/customer/portals';

    return (
      <div class="main   mb-5">
        <div class="container-fluid">
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-6">
              <h3 class="page-title mb-3 mb-md-0">Help</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-6 text-right">
              <div class="search-box w-100 d-inline-flex">
                <div class="form-group has-search w-100">
                  <input type="text" class="form-control shadow-sm" placeholder="Search" />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5 vertical-tabs">
              <ListRenderer
                list={list}
                activeKey={activeKey}
                tabPosition="left"
                getActiveKey={key => {
                  if (key == 1)
                    window.open('https://support.soberpeer.com');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Help;
