import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tag, Modal, Tooltip, Avatar } from 'antd';
import Loader, { Loader2 } from '../../../Components/Loader';
import { getMediaVideoUrl } from '../../../store/actions';
import BreathlyzerVideoModal from '../../PopupModals/BreathlyzerVideoModal';
import GoogleMapReact from 'google-map-react';

class BreathalyzerTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewVideoModal: false,
    };
  }
  componentDidMount() {
    const { MediaGuid } = this.props;
    this.props.dispatch(
      getMediaVideoUrl({
        Json: JSON.stringify({
          Media: [
            {
              MediaGuid: MediaGuid,
            },
          ],
        }),
      }),
    );
  }
  render() {
    const { VideoCallUrl, isFetching } = this.props;

    return (
      <div class="border-0">
        <div className="row no-gutters w-100 justify-content-between align-items-center">
          <div className="col-12">
            <Loader2 loading={isFetching} />
            <div className="d-flex flex-column">
              <div className="row">
                <div className="col-12">
                  <div className="my-2">
                    <span className="due-date d-flex align-items-center">
                      <em>Recorded Video </em>{' '}
                    </span>
                  </div>
                </div>

                <div className="col-12">
                  {VideoCallUrl.includes('https') ? (
                    <video
                      style={{ border: '1px solid #EEE' }}
                      width="100%"
                      height="auto"
                      src={VideoCallUrl}
                      autoplay="true"
                      controls
                      disablePictureInPicture="true"
                      controlsList="nodownload"
                    />
                  ) : (
                    <div className="text-center">
                      <h5>{VideoCallUrl}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_media_video_url }) => {
  const { data, error, isFetching } = get_media_video_url;
  let VideoCallUrl = '';

  if (get_media_video_url && get_media_video_url.data) {
    VideoCallUrl = data.result;
  }
  return {
    VideoCallUrl,
    isFetching,
  };
};

export default connect(mapStateToProps)(BreathalyzerTest);
