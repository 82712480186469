import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PersistTableSort from '../../../../../component/persistTableSort';
import {
  getClientCareTeamList,
  clientCareTeamGet,
  resetClientCareTeamGet,
  clientCareTeamDelete,
  resetClientCareTeamDelete,
  careTeamMemberCombo,
  upsertClientCareTeam,
  resetUpsertClientCareTeam,
} from '../../../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import { ProfilePicComponent } from '../../../../../Components/profilePicComponent';
import AntdModal from '../../../../../Components/CustomModal';
import CustomModal from '../../../../../core/modal';
import SuccessModal from '../../../../../core/modal/SuccessModal';

import { Tooltip, Drawer } from 'antd';
import CloseIcon from '../../../../../Components/CloseIcon';
import CareTeamMember from './careTeamMember';
import { CustomToolTip } from '../../../../../Components/CustomTooltip';
import Loader, { Loader2 } from '../../../../../Components/Loader';

class CareTeamList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {
        memberType: 'Internal',
      },
      isReadOnly: false,
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  getGridData = () => {
    this.props.dispatch(
      getClientCareTeamList({
        Json: JSON.stringify({
          ClientCareTeam: [
            {
              ClientId: this.getClientId(),
              // ClientId: 20009125001,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    this.getGridData();
  }
  componentWillReceiveProps({
    clientCareInfo,
    isDeleted,
    upsertMsg,
    upsertMsgInfo,
    isUpsertSuccess,
  }) {
    if (
      clientCareInfo &&
      JSON.stringify(clientCareInfo) != JSON.stringify(this.props.clientCareInfo)
    ) {
      this.centerChange(clientCareInfo.CenterId);
      let data = {
        memberType: clientCareInfo.StaffTypeId == 1 ? 'Internal' : 'External',
        ...clientCareInfo,
      };
      this.setState({
        initialData: data,
      });
    }
    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.toggle('Care Team Deleted Successfully');
    }
    if (isUpsertSuccess && isUpsertSuccess != this.props.isUpsertSuccess) {
      this.toggleDrawer();
      this.successToggle(upsertMsg, upsertMsgInfo);
    }
  }
  onDrawerClose = () => {
    this.setState({
      initialData: {
        memberType: 'Internal',
      },
      isCareTeamDrawer: false,
    });
    this.props.dispatch(resetClientCareTeamGet({}));
  };
  toggleDrawer = (record, actionType) => {
    if (record && record.ClientCareTeamId) {
      this.props.dispatch(
        clientCareTeamGet({
          Json: JSON.stringify({
            ClientCareTeam: [
              {
                ClientCareTeamId: record.ClientCareTeamId,
              },
            ],
          }),
        }),
      );
    }
    this.setState({
      isCareTeamDrawer: !this.state.isCareTeamDrawer,
      isReadOnly: actionType == 'View' ? true : false,
      actionType: actionType,
      initialData: {
        memberType: 'Internal',
      },
    });
  };
  toggle = (header, headerInfo) => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
      headerInfo,
    }));
  };
  successToggle = (header, headerInfo) => {
    this.setState(prevState => ({
      isSuccessModelOpen: !prevState.isSuccessModelOpen,
      header,
      headerInfo,
    }));
  };
  tableCoumns = [
    {
      title: 'Care Team Member',
      dataIndex: 'CareTeamMemberName',
      key: 'CareTeamMemberName',
      sorter: (a, b) => isComparer(a.CareTeamMemberName, b.CareTeamMemberName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <div className="d-flex align-items-center">
          <ProfilePicComponent src={record.ProfileImageUrl} alt={record.altText} />
          <div className="care-team-member-info">
            <span className="name">{text}</span>
            {record.CareTeamStatus && (
              <div>
                <span className="care-team-status">{record.CareTeamStatus}</span>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Center',
      dataIndex: 'CenterName',
      key: 'CenterName',
      sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Services',
      dataIndex: 'ServiceCode',
      key: 'ServiceCode',
      // sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const ServiceCode = text;
        return (
          <div className="">
            {ServiceCode && ServiceCode.length > 1 ? (
              <span className="mx-1">
                <CustomToolTip
                  // trigger="hover"
                  placement="left"
                  optionData={ServiceCode}
                  titleKey={'ServiceCode'}
                  title={'Service Code'}
                />
              </span>
            ) : (
              <span className="mx-1">{record.ServiceCodeText && record.ServiceCodeText}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
      sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      key: 'EndDate',
      sorter: (a, b) => isDateComparer(a.EndDateForSort, b.EndDateForSort),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>;
      },
    },
    {
      title: 'Care Team',
      dataIndex: 'StaffType',
      key: 'StaffType',
      sorter: (a, b) => isComparer(a.StaffType, b.StaffType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Consent Status',
      dataIndex: 'ConsentStatus',
      key: 'ConsentStatus',
      sorter: (a, b) => isComparer(a.ConsentStatus, b.ConsentStatus),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        let status = {
          1: 'pending',
          2: 'appoved',
          3: 'denied',
        };
        return (
          <div className="consent-status">
            {text && <span className={status[record.ConsentStatusId]}>{text}</span>}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'x',
      fixed: 'right',
      render: (text, record, index) => {
        return (
          <div className="tools-grid">
            {record.ConsentStatusId == 1 ? (
              <span className="mx-2">
                <Tooltip placement="top" title="View">
                  <img
                    src="images/openIcon.svg"
                    width="16px"
                    style={{ cursor: 'pointer' }}
                    onClick={event => {
                      this.toggleDrawer(record, 'View');
                    }}
                  />
                </Tooltip>
              </span>
            ) : (
              <span className="mx-2">
                <Tooltip placement="top" title="Edit">
                  <img
                    src="images/editIcon.svg"
                    width="16px"
                    style={{ cursor: 'pointer' }}
                    onClick={event => {
                      this.toggleDrawer(record, 'Edit');
                    }}
                  />
                </Tooltip>
              </span>
            )}

            <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="delete-btn"
                  onClick={e => {
                    onClick();
                    e.stopPropagation();
                  }}
                  data-toggle="modal"
                  data-target="#delete-Screen-Tool"
                  style={{ cursor: 'pointer' }}
                >
                  <Tooltip placement="top" title="Delete">
                    <img
                      src="images/icons/delete_grid.svg"
                      width="16px"
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </span>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                    <h4 class="modal-title col-12 mb-3">Are you sure you want to delete?</h4>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      class="btn btn-outline-primary black-btn"
                      onClick={() => {
                        onCancel();
                      }}
                    >
                      No
                    </button>
                    <button
                      class="btn"
                      onClick={e => {
                        this.props.dispatch(
                          clientCareTeamDelete({
                            Json: JSON.stringify({
                              ClientCareTeam: [
                                {
                                  ClientCareTeamId: record.ClientCareTeamId,
                                },
                              ],
                            }),
                          }),
                        );
                        e.stopPropagation();
                        onOk();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        );
      },
    },
  ];
  centerChange = val => {
    this.props.dispatch(
      careTeamMemberCombo({
        Json: JSON.stringify({
          CareTeam: [
            {
              CenterId: val,
            },
          ],
        }),
      }),
    );
  };
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  handleCareTeam = val => {
    let data = {
      CareTeam: [
        {
          ClientCareTeamId: val.ClientCareTeamId || null,
          StaffTypeId: val.memberType == 'Internal' ? 1 : 2,
          ClientId: this.getClientId(),
          CenterId: val.CenterId,
          PractitionerId: val.PractitionerId,
          StartDate: val.StartDate ? moment(val.StartDate).format('YYYY-MM-DD') : null,
          EndDate: val.EndDate ? moment(val.EndDate).format('YYYY-MM-DD') : null,
          ServiceCodeId: this.getStringType(val.ServiceCodeId),
        },
      ],
    };
    this.props.dispatch(
      upsertClientCareTeam({
        Json: JSON.stringify(data),
      }),
    );
  };
  render() {
    const { isFetchingUpsert, isFetchingList, isFetchingGet } = this.props;
    const {
      isCareTeamDrawer,
      isReadOnly,
      initialData,
      isModelOpen,
      header,
      headerInfo,
      actionType,
      isSuccessModelOpen,
    } = this.state;
    return (
      <div className="container-fluid mt-5 pb-4">
        <div className="row mb-2">
          <div class="col-md-6 col-xl-8">
            <span className="grid-page-title">Additional Care Team Member(s)</span>
          </div>
          <div class="col-md-6 col-xl-4 text-right">
            <input
              type="button"
              class="btn"
              value={'Select Care Team Member'}
              onClick={this.toggleDrawer}
            />
          </div>
        </div>
        <div className="row">
          <Loader2 loading={isFetchingList} />
          <div className="col-12 rcm-tools-table">
            <PersistTableSort
              name="non-payer-list"
              columns={this.tableCoumns}
              dataSource={this.props.clientCareTeam}
              size={'small'}
            />
          </div>
          {/* <div className="col-12 text-right mt-5">
            <span
              className=""
              style={{
                color: '#4AB0FE',
                fontSize: '16px',
                fontWeight: 600,
                textTransform: 'uppercase',
                cursor: 'pointer',
              }}
            >
              + Add NEW Care team member
            </span>
          </div> */}
        </div>
        {isCareTeamDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">{actionType ? actionType : 'Select'} Care Team Member</span>
              </div>
            }
            placement="right"
            open={isCareTeamDrawer}
            width={800}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={this.onDrawerClose}
          >
            <CareTeamMember
              initialValues={initialData}
              ClientCareTeamId={initialData.ClientCareTeamId}
              ConsentStatusId={initialData.ConsentStatusId}
              disable={isReadOnly}
              onSaveClick={this.handleCareTeam}
              centerChange={this.centerChange}
              onClose={this.onDrawerClose}
              isFetchingUpsert={isFetchingUpsert}
              isFetchingGet={isFetchingGet}
            />
            {/* <ManageAppointment
            handleAppointment={this.handleAppointment}
            initialValues={this.state.initialData}
            AppointmentId={this.state.initialData.AppointmentId}
            ClientId={ClientId}
            BannerData={BannerData}
            OptionData={optionData}
            getCallEndDetails={this.getCallEndDetails}
            onClose={() => {
              this.setState({
                initialData: {},
                isCareTeamDrawer: false,
              });
              this.props.dispatch(upsertAppointmentReset());
            }}
            getAppointmentList={this.getAppointmentList}
          /> */}
          </Drawer>
        )}
        {isSuccessModelOpen && (
          <SuccessModal
            isOpen={isSuccessModelOpen}
            headerText={header}
            headerInfo={headerInfo}
            yesAction={() => {
              this.getGridData();
              this.successToggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            header={header}
            yesAction={() => {
              this.getGridData();
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  get_client_care_team_list,
  client_care_team_get,
  client_care_team_delete,
  upsert_client_care_team,
}) => {
  const { data: careTeamData, isFetching: isFetchingList } = get_client_care_team_list;
  const { data: careTeamGetData, isFetching: isFetchingGet } = client_care_team_get;
  const { data: upsertServiceCodeData, isFetching } = upsert_client_care_team;
  const { data: deleteServiceCodeData } = client_care_team_delete;

  let clientCareTeam = [];
  let clientCareInfo = null;
  let isDeleted = false;
  let upsertMsg = false;
  let upsertMsgInfo = false;
  let isUpsertSuccess = false;
  if (careTeamData && careTeamData.ClientCareTeam) {
    clientCareTeam = careTeamData.ClientCareTeam;
  }
  if (careTeamGetData && careTeamGetData.ClientCareTeam) {
    clientCareInfo = careTeamGetData.ClientCareTeam[0];
  }
  if (deleteServiceCodeData && deleteServiceCodeData.success) {
    isDeleted = deleteServiceCodeData.success;
  }
  if (upsertServiceCodeData && upsertServiceCodeData.success) {
    upsertMsg = upsertServiceCodeData.result.Msg;
    upsertMsgInfo = upsertServiceCodeData.result.MsgInfo;
    isUpsertSuccess = upsertServiceCodeData.success;
  }
  return {
    clientCareTeam,
    clientCareInfo,
    isDeleted,
    isFetchingUpsert: isFetching,
    upsertMsg,
    upsertMsgInfo,
    isUpsertSuccess,
    isFetchingList,
    isFetchingGet,
  };
};
export default connect(mapStateToProps)(CareTeamList);
