import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import WarningComponent from '../core/warning-component';
import AntdModal from '../Components/CustomModal';
import moment from 'moment';
import { Table, Button } from 'antd';

import { isComparer, isDateComparer, isNumericComparer } from '../util/handler';
import { stringDate } from '../util/date';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  isDirty,
} from 'redux-form';
import {
  adminstrationGetCourse,
  adminstrationCourseUpsert,
  resetAdminstrationCourseUpsert,
  courseDelete,
  resetCourseDelete,
} from '../store/actions';
import { ProcessCombos } from '../store/comboConfig';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';
import PageTitle from '../Components/PageTitle';
import { get } from 'lodash';

class ActivityBase extends React.Component {}

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

class CoursesForm extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      courseData: {},
    };
  }
  // need to work on it always return zero for me
  getCourseId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var courseId = param.get('courseId') || param.get('courseid');
    if (courseId) {
      courseId = parseInt(courseId);
    } else {
      courseId = -1;
    }
    return courseId;
  }

  componentDidMount() {
    if (this.getCourseId() > 0) {
      this.props.dispatch(adminstrationGetCourse({ CourseId: this.getCourseId() }));
    }

    var result = ProcessCombos(
      'Ladder,ContractLadderAssociation,CourseType,CourseTopic,Staff,Language',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({ course, isInserted, isRemoved }) {
    if (isInserted) {
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(resetAdminstrationCourseUpsert());
      //this.props.history.push('/manage/courses');
    }
    if (isRemoved) {
      this.toggle(Delete_Sucess_Msg);
      this.props.dispatch(resetCourseDelete());
    }
    if (course && course.CourseId > 0 && this.getCourseId() > 0) {
      const data = {
        courseType: course.CourseTypeId,
        courseTopic: course.CourseTopicId,
        name: course.Name,
        description: course.Description,
        abstract: course.Abstract,
        publishedDate: course.PublishedDate,
        ladderAssociation: course.LadderAssociation,
        contractLadderAssociationId: course.ContractLadderAssociationId,
        authorStaffId: course.AuthorStaffId,
        authorName: course.AuthorName,
        copyrightMsg: course.CopyrightMsg,
        language: course.LanguageISO,
        inactivatedDate: course.InactivatedDate,
        microEnterprises: course.MicroEnterprises,
        courseUrl: course.CourseUrl,
      };
      this.setState({ courseData: data });
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      courseType,
      courseTopic,
      name,
      description,
      abstract,
      publishedDate,
      ladderAssociation,
      authorStaffId,
      authorName,
      copyrightMsg,
      courseUrl,
      language,
      inactivatedDate,
      microEnterprises,
      contractLadderAssociationId,
    } = values;
    var courseId = null;

    var paramCourseId = this.getCourseId();
    if (paramCourseId && paramCourseId > 0) {
      courseId = paramCourseId;
    }

    const data = {
      Course: [
        {
          CourseId: courseId,
          Abstract: abstract,
          AuthorName: authorName,
          AuthorStaffId: authorStaffId,
          CopyrightMsg: copyrightMsg,
          CourseCode: null,
          CourseLength: null,
          CourseLengthUnit: null,
          CourseTopicId: courseTopic,
          CourseTypeId: courseType,
          CourseUrl: courseUrl,
          Description: description,
          InactivatedDate: stringDate(inactivatedDate),
          LadderAssociation: this.getStringType(values.ladderAssociation),
          ContractLadderAssociationId: this.getStringType(values.contractLadderAssociationId),
          LanguageISO: language,
          Name: name,
          PublishedDate: stringDate(publishedDate),
        },
      ],
    };
    var courseData = {
      courseId: courseId,
      jsonInput: JSON.stringify(data),
    };

    this.props.dispatch(adminstrationCourseUpsert(courseData));
  }
  columns = [
    {
      title: 'Student Id',
      dataIndex: 'StudentId',
      key: 'StudentId',
      sorter: (a, b) => isNumericComparer(a.StudentId, b.StudentId),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'FirstName',
      dataIndex: 'FirstName',
      key: 'FirstName',
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last Name',
      dataIndex: 'LastName',
      key: 'LastName',
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isComparer(a.LastName, b.LastName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Due Date',
      dataIndex: 'DueDate',
      key: 'DueDate',
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isDateComparer(a.DueDate, b.DueDate),
      sortDirections: ['descend', 'ascend'],
      render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
    },
    {
      title: 'Unassign Date',
      dataIndex: 'UnassignDate',
      key: 'UnassignDate',
      // width: 300,
      sorter: (a, b) => isDateComparer(a.UnassignDate, b.UnassignDate),
      sortDirections: ['descend', 'ascend'],
      render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
    },
    {
      title: 'Completed Date',
      dataIndex: 'CompletedDate',
      key: 'CompletedDate',
      sorter: (a, b) => isDateComparer(a.CompletedDate, b.CompletedDate),
      sortDirections: ['descend', 'ascend'],
      render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
    },
    {
      title: 'Enroll Date',
      key: 'EnrollDate',
      dataIndex: 'EnrollDate',
      sorter: (a, b) => isDateComparer(a.EnrollDate, b.EnrollDate),
      sortDirections: ['descend', 'ascend'],
      render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
    },
    {
      title: 'Score',
      key: 'Score',
      dataIndex: 'Score',
      sorter: (a, b) => isComparer(a.Score, b.Score),
      sortDirections: ['descend', 'ascend'],
    },
  ];
  render() {
    return (
      <React.Fragment>
        <PageTitle TitleText="Courses" />
        <ReduxEnrollmentForm
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.state.courseData}
          onCancel={() => this.props.history.push('/manage/courses')}
          getCourseId={this.getCourseId()}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/courses')
          }}
        />
        {this.getCourseId() > 0 && (
          <div class="main enrollment-page mb-5 pb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h4 class="form-main-head">{this.getCourseId() > 0 && 'Enrolled Client List'}</h4>
                </div>
                <div className="col-12">
                  <div class="card border-0 ">
                    <div class="row card-body">
                      <div class="col-12">
                        {/* <Loader2 loading={isFetching} /> */}
                        <div className="">
                          <Table
                            columns={this.columns}
                            dataSource={this.props.studentCourse}
                            size={'middle'}
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/courses');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            this.props.dispatch(courseDelete({ CourseId: this.getCourseId() }));
            // this.props.history.push('/manage/courses');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            this.props.history.push('/manage/courses');
          }}
        />
        <WarningComponent
          dirtyFlag={this.props.isDirty}
          submitSucceeded={this.props.submitSucceeded}
        />
      </React.Fragment>
    );
  }
}

let EnrollmentForm = ({
  onSaveClick,
  onSubmit,
  handleSubmit,
  course,
  initialize,
  CourseType,
  CourseTopic,
  Staff,
  Language,
  getCourseId,
  initialValues,
  onCancel,
  Delete,
  history,
  dispatch,
  courseTopic,
  ladder,
  contractLadderAssociation,
  isUpserting,
}) => {
  return (
    <div class="main enrollment-page mb-2">
      <div className="container-fluid">
        <form onSubmit={handleSubmit(onSaveClick)}>
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h4 class="form-main-head">{getCourseId > 0 ? 'Edit Course' : 'Add a Course'}</h4>
            </div>
            <div class="col-12 col-md-8 col-lg-9 text-right">
              <input
                type="button"
                class=" btn btn-outline-primary"
                value="Cancel"
                onClick={onCancel}
              />
              &nbsp;
              {/* <input
                type="submit"
                class="btn"
                value="Save"
                //onClick={onSaveClick}
              /> */}
              <Button
                loading={isUpserting}
                htmlType="submit"
                type="primary"
                className="save-button"
                // disabled={IsDeActivated}
              >
                Save
              </Button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form">
                  <div class="row pb-5 pl-3 pr-3 pt-3">
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="courseType"
                          type="text"
                          errorMessage="Course Type Required"
                          component={ReduxSelect}
                          options={CourseType}
                          placeholder="Course Type*"
                          validate={Validator.required}
                          fieldName={'Course Type'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="courseTopic"
                          type="text"
                          errorMessage="Course Topic Required"
                          component={ReduxSelect}
                          options={CourseTopic}
                          placeholder="Course Topic*"
                          validate={Validator.required}
                          fieldName={'Course Topic'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="name"
                          type="text"
                          component={ReduxInput}
                          errorMessage="Course Name Required"
                          validate={Validator.required}
                          fieldName={'Course Name'}
                        />
                        <label class="placeholder-label">Course Name*</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <Field
                          name="description"
                          type="textarea"
                          component={ReduxInput}
                          validate={Validator.required}
                          rows="4"
                          fieldName={'Description'}
                        />
                        <label class="placeholder-label">Description*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="abstract"
                          type="textarea"
                          component={ReduxInput}
                          // errorMessage="Abstract Required"
                          // validate={Validator.required}
                          fieldName={'Abstract'}
                        />
                        <label class="placeholder-label">Abstract</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="publishedDate"
                          type="text"
                          component={ReduxDateInput}
                          errorMessage="Published Date Required"
                          validate={Validator.required}
                          label={'Published Date*'}
                          // minDate={new Date(2016, 0, 1)}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      {/* <div class="form-group">
                        <Field name="ladderAssociation" type="text" component={ReduxInput} />
                        <label class="placeholder-label">Ladder Association</label>
                      </div> */}

                      <div class="form-group">
                        <Field
                          name="ladderAssociation"
                          class="custom-select enrollment-select"
                          component={ReduxSelect}
                          placeholder="Ladder Association"
                          options={ladder}
                          // validate={Validator.required}
                          fieldName={'Ladder Association'}
                          isMulti
                        />
                        {/* <span class="small-info">Select the character trait focus.</span> */}
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="contractLadderAssociationId"
                          class="custom-select enrollment-select"
                          component={ReduxSelect}
                          placeholder="Ladder category*"
                          options={contractLadderAssociation}
                          validate={Validator.required}
                          fieldName={'Ladder Category'}
                          isMulti
                        />
                        {/* <span class="small-info">Select the character trait focus.</span> */}
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="authorStaffId"
                          type="text"
                          errorMessage="authorStaffId  Required"
                          component={ReduxSelect}
                          options={Staff}
                          validate={Validator.required}
                          placeholder="Staff Member*"
                          fieldName={'Staff Member'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        {/* <label class="control-label">Author Name</label> */}
                        <Field
                          name="authorName"
                          type="text"
                          errorMessage="Author Name Required"
                          component={ReduxInput}
                          // validate={Validator.required}
                        />
                        <label class="placeholder-label">Author Name</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          type="textarea"
                          name="copyrightMsg"
                          errorMessage="Copyright Msg Required"
                          component={ReduxInput}
                          // validate={Validator.required}
                        />
                        <label class="placeholder-label">Copyright Msg</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="courseUrl"
                          errorMessage="Course Url Required"
                          component={ReduxInput}

                          // validate={Validator.required}
                        />
                        <label class="placeholder-label">Course Url</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="language"
                          errorMessage="Language Required"
                          component={ReduxSelect}
                          options={Language}
                          validate={Validator.required}
                          placeholder="Language*"
                          fieldName={'Language'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="inactivatedDate"
                          errorMessage="Inactivated Date Required"
                          component={ReduxDateInput}
                          // validate={Validator.required}
                          label={'Inactivated Date'}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-right">
              {getCourseId > 0 && (
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <a
                      class="deactivate-link"
                      data-toggle="modal"
                      data-target="#deactivate-staff-model"
                      onClick={onClick}
                    >
                      <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                    </a>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to Deactivate <br />
                          <strong>{courseTopic}</strong> course?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn black-btn"
                          onClick={() => {
                            onCancel();
                            history.push('/manage/courses');
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            onOk();
                            dispatch(courseDelete({ CourseId: getCourseId }));
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { combos, course_upsert } = state;
  const { isFetching: isUpserting } = course_upsert;
  if (!combos.data) {
    combos.data = {
      CourseType: [],
      CourseTopic: [],
      Staff: [],
      Language: [],
      ContractLadderAssociation: [],
    };
  }
  const courseTopic = formValueSelector('coursesform')(state, 'name');

  return {
    ladder: combos.data.Ladder,
    contractLadderAssociation: combos.data.ContractLadderAssociation,
    CourseType: combos.data.CourseType,
    CourseTopic: combos.data.CourseTopic,
    Staff: combos.data.Staff,
    Language: combos.data.Language,
    courseTopic,
    isUpserting,
  };
};

const ReduxEnrollmentForm = reduxForm({
  form: 'coursesform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(EnrollmentForm)));

const mapStateToPropsParent = state => {
  const { course_get, course_upsert, course_delete } = state;
  const { data, isFetching, error } = course_get;
  let course = [];
  let studentCourse = [];
  var isInserted = false;
  var isRemoved = false;
  var recordInfo = null;
  if (data) {
    course = data;
    studentCourse = data.StudentCourse || [];
  }
  if (course_upsert && course_upsert.data && course_upsert.data.success) {
    isInserted = true;
  }
  if (course_delete && course_delete.data && course_delete.data.success) {
    isRemoved = true;
    recordInfo = course_delete.data;
  }
  return {
    course,
    isInserted,
    isRemoved,
    isDirty: isDirty('coursesform')(state),
    submitSucceeded: get(state, 'form.coursesform.submitSucceeded', false),
    studentCourse,
  };
};

export default connect(mapStateToPropsParent)(CoursesForm);
