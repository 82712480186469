import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import {
  getFormFieldMetaData,
  upsertFormData,
  formDataGet,
  appInvite,
  adminstrationStaffUpsert,
  listCombos,
} from '../../../store/actions';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../core/generic-form';
import { StaffMobileAppDetails } from '../../../app-config/screenName';
import { ToastContainer, toast } from 'react-toastify';
import Loader, { Loader2 } from '../../../../src/Components/Loader';
import { ProcessCombos } from '../../../store/comboConfig';
import PageTitle from '../../../Components/PageTitle';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'allow numeric values only' : undefined,
};
const ProviderCentersForm = ({
  fields,
  name,
  meta: { touched, error },
  Centers,
  providerCenterRow,
  change,
  disable,
  dispatch,
  Center,
  ClinicalCenter,
  SupervisorList,
  PayerInsurance,
}) => {
  let rcopiaCenterArray = [];
  return (
    <>
      {fields.map((providerCenters, index) => {
        let superVisorList = [];
        let rcopiaCenter;
        if (providerCenterRow[index].CenterId && SupervisorList) {
          superVisorList =
            SupervisorList.filter(item => item.CenterId == providerCenterRow[index].CenterId)[0] &&
            JSON.parse(
              SupervisorList.filter(item => item.CenterId == providerCenterRow[index].CenterId)[0]
                .SuperVisorList,
            );
        }
        if (providerCenterRow && providerCenterRow[index] && providerCenterRow[index].CenterId) {
          let clinicalCenterId = providerCenterRow[index].CenterId;
          if (clinicalCenterId) {
            rcopiaCenter = ClinicalCenter.filter(item => {
              if (item.Value == clinicalCenterId) return item;
            })[0];
          }
          rcopiaCenterArray.push(rcopiaCenter);
        }
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Center {index + 1}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${providerCenters}.CenterId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Center*"
                    options={ClinicalCenter}
                    fieldName={'Center'}
                    disabled={disable}
                    validate={Validator.required}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${providerCenters}.HireDate`}
                    type="text"
                    // validate={Validator.required}
                    label="Hire Date"
                    component={ReduxDateInput}
                    fieldName={'Hire Date'}
                    disabled={disable}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${providerCenters}.TerminationDate`}
                    type="text"
                    // validate={Validator.required}
                    label="Termination Date"
                    component={ReduxDateInput}
                    fieldName={'Termination Date'}
                    disabled={disable}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div className="row">
                  {' '}
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="custom-control-label" for="is-emergency-contact">
                        Is Supervisor{'   '}
                        <Field
                          name={`${providerCenters}.IsSupervisor`}
                          type="text"
                          class="custom-control-input not-empty"
                          component={ReduxToggle}
                          disabled={disable}
                        />
                      </label>
                    </div>
                  </div>
                  <div class="col-6 col-md-3 col-lg-6">
                    <div class="form-group">
                      <label class="custom-control-label" for="is-emergency-contact">
                        Is Read Only{'   '}
                        <Field
                          name={`${providerCenters}.IsReadOnly`}
                          type="text"
                          class="custom-control-input not-empty"
                          component={ReduxToggle}
                          disabled={disable}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {providerCenterRow && !providerCenterRow[index].IsSupervisor && (
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="form-group">
                    <Field
                      name={`${providerCenters}.SupervisorStaffId`}
                      type="text"
                      component={ReduxSelect}
                      placeholder="Supervisor"
                      options={superVisorList}
                      fieldName={'Supervisor '}
                      disabled={disable}
                      // validate={Validator.required}
                    />
                  </div>
                </div>
              )}
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${providerCenters}.PayerId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Insurance"
                    options={PayerInsurance}
                    fieldName={'Insurance'}
                    disabled={disable}
                    isMulti={true}
                    // validate={Validator.required}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mt-3">
                <div class="form-group">
                  <Field
                    className="mr-2"
                    name={`${providerCenters}.IsClaimSubmittedUnderSupervisor`}
                    component={ReduxCheckbox}
                    fieldName={'IsClaimSubmittedUnderSupervisor'}
                  />
                  <label class="placeholder-label" style={{ top: '0px', marginTop: '4px' }}>
                    Claims must be submitted under the supervisor
                  </label>
                </div>
              </div>
              {rcopiaCenterArray &&
                rcopiaCenterArray[index] &&
                rcopiaCenterArray[index].IseRXCenter && (
                  <div className="col-12 row">
                    <div class="col-12 mb-3">
                      <h6>ePrescribe Details </h6>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <Field
                          name={`${providerCenters}.RcopiaId`}
                          type="text"
                          component={ReduxInput}
                          fieldName={'RcopiaId'}
                        />
                        <label class="placeholder-label">Rcopia ID</label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <Field
                          name={`${providerCenters}.RcopiaUserName`}
                          type="text"
                          component={ReduxInput}
                          fieldName={'RcopiaUserName'}
                        />
                        <label class="placeholder-label">Rcopia Username</label>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" onClick={() => fields.push({})} disabled={disable}>
            {fields.length >= 1 && (
              <label>
                <a> + Add Another Center</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                {' '}
                <a> + Add Center</a>
              </label>
            )}
          </a>
          {fields && fields.length == 0 && (
            <span className="staff-info-msg">
              <img className="mt-1 mr-1" src="images/warning.svg" />
              Please assign a center to this staff member in order to use Sober Peer's clinical
              workflow.
            </span>
          )}
        </div>
      </div>
    </>
  );
};
const ProviderCentersForm1 = connect(state => {
  const { combos } = state;
  const providerCenterRow = formValueSelector('staffForm_practitioner')(state, 'Center');
  return {
    providerCenterRow,
    ClinicalCenter: combos.data.ClinicalCenter || [],
    SupervisorList: combos.data.SupervisorList,
    PayerInsurance: combos.data.PayerInsurance || [],
  };
})(ProviderCentersForm);
class GerericForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      showMobileAppUserForm: props.IsProvider,
    };
  }

  sendFormData = values => {
    let valueArray = Object.keys(values);
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object') {
         obj[key] =
           values[key] && values[key].length > 0
             ? values[key].map(item => item.Value).toString()
             : null;
      } else {
        obj[key] = values[key];
      }
    }
    let jsonData = {
      DynamicForm: [
        {
          FormName: StaffMobileAppDetails,
          FormDataId: this.props.getStaffId ? this.props.getStaffId : null,
          FormData: {
            Staff_MobileAppDetails: [
              {
                StaffId: this.props.getStaffId,
                IsProvider: values.IsProvider,
                Misc: [
                  {
                    ...obj,
                  },
                ],
              },
            ],
          },
        },
      ],
    };
    this.props.dispatch(
      upsertFormData({
        Json: JSON.stringify(jsonData),
      }),
    );
  };

  componentDidMount() {
    // let jsonData = {
    //   DynamicForm: [
    //     {
    //       FormName: 'StaffPractitioner',
    //       Id: this.props.getStaffId,
    //     },
    //   ],
    // };
    // this.props.dispatch(
    //   getFormFieldMetaData({
    //     Json: JSON.stringify(jsonData),
    //   }),
    // );
    this.props.dispatch(
      listCombos({
        comboTypes: 'ClinicalCenter,Center,SupervisorList,PayerInsurance',
      }),
    );
  }

  componentWillReceiveProps({
    genericFormData,
    comboData,
    IsProviderValue,
    isUpsertSuccess,
    inviteDetails,
    isAppInviteSent,
    preInviteDetails,
    upsertSucessMsg,
  }) {
    if (isUpsertSuccess && isUpsertSuccess != this.props.isUpsertSuccess) {
      // toast.dismiss();
      // toast.success(upsertSucessMsg || 'Well done! Staff has been added successfully.', {
      //   position: 'top-center',
      //   // autoClose: 4000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   // onClose: () => {
      //   //   this.props.history.push('/manage/staff-management/staff-list');
      //   // },
      // });
      // this.props.history.push({
      //   pathname: this.props.ListURL,
      //   state: { msg: upsertSucessMsg || 'Well done! Staff has been added successfully.' },
      // });
      this.props.history.push(`${this.props.ListURL}`);
    }
    if (IsProviderValue != this.props.IsProviderValue) {
      this.setState({ showMobileAppUserForm: IsProviderValue });
    }
    if (
      genericFormData &&
      JSON.stringify(genericFormData) != JSON.stringify(this.props.genericFormData)
    ) {
      let validationConversion = genericFormData;
      this.setState({ formFields: validationConversion });
      if (this.props.getStaffId) {
        let jsonData2 = {
          DynamicForm: [
            {
              FormName: StaffMobileAppDetails,
              FormDataId: this.props.getStaffId,
            },
          ],
        };
        this.props.dispatch(
          formDataGet({
            Json: JSON.stringify(jsonData2),
          }),
        );
      }
    }
  }

  render() {
    const { formFields, optionsList } = this.state;
    const { handleSubmit, comboData, onCancel, inviteDetails, isDeactivated, isPhone } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Staff" />
        <div className="main enrollment-page  mb-5">
          <div className="container-fluid">
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className=" enrollment-form pt-2 pb-2">
                  <div className="row">
                    {this.state.showMobileAppUserForm && (
                      <div class="col-12 col-md-6 col-lg-6 text-right top-heading-row align-items-center d-none">
                        <button onClick={onCancel} class="btn  btn-outline-primary mr-2">
                          Cancel
                        </button>
                        <input
                          type="submit"
                          class="btn"
                          value="Submit"
                          // disabled={IsDeActivated}
                        />
                      </div>
                    )}
                  </div>

                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    {' '}
                    <div className="col-md-12 staff-form">
                      <div className="row">
                        <Loader2 loading={this.props.isFetchingStaff} />

                        {/* <DynamicForm formFields={formFields} disable={isDeactivated} /> */}
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="form-group">
                            <Field
                              name={`Credentials`}
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              fieldName={'Credentials'}
                              // disabled={IsDeActivated}
                            />
                            <label class="placeholder-label">Credentials*</label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="form-group">
                            <Field
                              name={`NPI`}
                              type="text"
                              component={ReduxInput}
                              // validate={Validator.required}
                              fieldName={'NPI'}
                              // disabled={IsDeActivated}
                            />
                            <label class="placeholder-label">NPI</label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="form-group">
                            <Field
                              name={`Taxonomy`}
                              type="text"
                              component={ReduxInput}
                              fieldName={'Taxonomy'}
                              // disabled={IsDeActivated}
                            />
                            <label class="placeholder-label">Taxonomy</label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                          <div class="form-group">
                            <Field
                              name={`ClearingHouseProviderId`}
                              type="text"
                              component={ReduxInput}
                              validate={Validator.allowNumeric}
                              fieldName={'CH Provider Id'}
                              // disabled={IsDeActivated}
                            />
                            <label class="placeholder-label">Clearing House Provider Id</label>
                          </div>
                        </div>
                        {/* <div class="col-12 col-md-6 col-lg-3">
                          <div class="form-group">
                            <Field
                              name={`IsSupervisor`}
                              type="text"
                              component={ReduxToggle}
                              fieldName={'Is Supervisor'}
                              // disabled={IsDeActivated}
                            />
                            <label class="placeholder-label">Is Supervisor</label>
                          </div>
                        </div> */}
                        <div class="col-12">
                          <FieldArray
                            name="Center"
                            component={ProviderCentersForm1}
                            // disable={IsDeActivated}
                            // dispatch={props.dispatch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer
              position="top-center"
              autoClose={50000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { combos, get_form_field_meta_data, staff_upsert, form_data_get } = state;
  const { data: getGenericFormFields } = get_form_field_meta_data;
  const { data: upsertData, isFetching: isFetchingStaff } = staff_upsert;
  const { data } = form_data_get;

  const IsProviderValue = formValueSelector('staffForm_practitioner')(state, 'IsProvider');
  const mobileAppRoleValue = formValueSelector('staffForm_practitioner')(state, 'MobileAppRole');
  const providerCenterRow = formValueSelector('staffForm_practitioner')(state, 'Center');
  let comboData = {};
  let genericFormData = [];
  let isUpsertSuccess;

  let upsertSucessMsg;
  if (combos && combos.data) {
    comboData = combos.data || {};
  }
  if (getGenericFormFields) {
    genericFormData = getGenericFormFields.FormFieldMetaData;
  }
  if (upsertData && upsertData.success) {
    upsertSucessMsg = JSON.parse(upsertData.data).Staff[0].InviteDetails[0].SucessMsg;
    isUpsertSuccess = upsertData.success;
  }

  return {
    genericFormData,
    comboData,
    isUpsertSuccess,
    upsertSucessMsg,
    isFetchingStaff,
    providerCenterRow,
  };
};

const ReduxGenericForm = reduxForm({
  form: 'staffForm_practitioner',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    let valueArray = Object.keys(values);
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object') {
         obj[key] =
           values[key] && values[key].length > 0
             ? values[key].map(item => item.Value).toString()
             : null;
      } else {
        obj[key] = values[key];
      }
    }

    const getConvertedValue = centerValues => {
      let centerValueArray = Object.keys(centerValues);
      const obj = {};
      for (const key of centerValueArray) {
        if (centerValues[key] instanceof Date) {
          obj[key] = moment(centerValues[key]).format('MM/DD/YYYY');
        } else if (centerValues[key] != null && typeof centerValues[key] == 'object') {
            obj[key] =
              centerValues[key] && centerValues[key].length > 0
                ? centerValues[key].map(item => item.Value).toString()
                : null;
        } else {
          obj[key] = centerValues[key];
        }
      }
      return obj;
    };

    let Practitionerdata = {
      Staff: [
        {
          StaffId: props.getStaffId,
          IsFinalSubmit: true,
          Practitioner: [
            {
              ...values,
              Center:
                values && values.Center && values.Center.length > 0
                  ? values.Center.map(item => {
                      return getConvertedValue(item);
                    })
                  : null,
              ClearingHouseProviderId: parseInt(values.ClearingHouseProviderId),
            },
          ],
        },
      ],
    };
    let staffData = {
      staffId: props.getStaffId,
      email: props.EmailWork,
      jsonInput: JSON.stringify(Practitionerdata),
    };
    dispatch(adminstrationStaffUpsert(staffData));
  },
})(connect(mapStateToProps)(withRouter(GerericForm)));

// const selector = formValueSelector('staffForm_practitioner');

class GenericFormParent extends React.Component {
  constructor(props) {
    super(props);
    const isPhone = props.PhoneMobile.length > 0;
    this.state = {
      initialValues: {
        Center: [{}],
      },
    };
  }
  componentDidMount() {
    this.setState({
      practitioner: this.props.Practitioner,
      initialValues: {
        ...this.props.Practitioner,
      },
    });
  }
  componentWillReceiveProps({ staffForm, IsFinalSubmit, Practitioner }) {
    // if (IsFinalSubmit && JSON.stringify(staffForm) != JSON.stringify(this.props.staffForm)) {
    //   if (staffForm.IsProvider) {
    //     let appData = (staffForm.Misc && staffForm.Misc[0]) || {};
    //     this.setState({
    //       staffForm: staffForm,
    //       initialValues: {
    //         IsProvider: staffForm.IsProvider,
    //         ...appData,
    //       },
    //     });
    //   } else {
    //     this.setState({
    //       staffForm: staffForm,
    //       // initialValues: {
    //       //   IsProvider: staffForm.IsProvider,
    //       // },
    //     });
    //   }
    // }
    if (Practitioner && JSON.stringify(Practitioner) != JSON.stringify(this.props.Practitioner)) {
      this.setState({
        practitioner: Practitioner,
        initialValues: {
          ...Practitioner,
        },
      });
    }
  }
  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };

  render() {
    const ListURL = `${this.props.baseURL}${this.props.listUrl}`;
    return (
      <>
        <ReduxGenericForm
          initialValues={this.state.initialValues}
          getStaffId={this.getStaffId()}
          onCancel={() => this.props.history.push(ListURL)}
          IsProvider={this.props.IsProvider}
          EmailWork={this.props.EmailWork}
          PhoneMobile={this.props.PhoneMobile}
          isPhone={this.props.PhoneMobile.length > 0}
          isDeactivated={this.props.isDeactivated}
          inviteDetails={this.props.inviteDetails}
          ListURL={ListURL}
        />
      </>
    );
  }
}

const mapStateToParent = state => {
  const { form_data_get } = state;
  const { data, isFetching, error } = form_data_get;
  let inviteDetails = {};
  let EmailWork;
  let isDeactivated;
  let IsFinalSubmit = false;
  let staffForm;
  let IsProvider = false;
  let PhoneMobile = '';
  let Practitioner;
  if (data && data.Staff[0]) {
    staffForm = data.Staff[0];
    IsProvider = data.Staff[0].IsProvider;
    EmailWork = data.Staff[0].EmailWork;
    isDeactivated = data.Staff[0].IsDeactivated;
    inviteDetails = data.Staff[0].InviteDetails[0];
    IsFinalSubmit = data.Staff[0].IsFinalSubmit;
    PhoneMobile = data.Staff[0].PhoneMobile;
    Practitioner = data.Staff[0].Practitioner && data.Staff[0].Practitioner[0];
  }

  return {
    isFetching,
    staffForm,
    IsProvider,
    inviteDetails,
    EmailWork,
    isDeactivated,
    IsFinalSubmit,
    PhoneMobile,
    Practitioner,
  };
};

export default connect(mapStateToParent)(withRouter(GenericFormParent));
