import React, { Component } from 'react';
import { Collapse, Checkbox, Row, Col } from 'antd';
import Loader, { Loader2 } from '../../../Components/Loader';

const { Panel } = Collapse;

const onChange = key => {
  console.log(key);
};

const Engagement = props => {
  const { userEngagementData, userEngagementIsFetching } = props;

  const genExtra = (Score, CompletedCnt) => (
    <>
      <span
        style={{
          borderRadius: '20px',
          backgroundColor: '#F2F2F2',
          padding: '5px 20px',
          color: '#182838',
          fontWeight: '700',
          fontSize: '20px',
        }}
      >
        Assessments Completed:{' '}
        <span style={{ borderRight: '1px solid #959595', paddingRight: '20px' }}>
          <b>{CompletedCnt}</b>
        </span>{' '}
        &nbsp; Score:
        <span>
          {' '}
          <b>{Score}</b>
        </span>
      </span>
    </>
  );

  return (
    <>
      {' '}
      <Loader2 loading={userEngagementIsFetching} />
      {/* <Collapse defaultActiveKey={['0']} onChange={onChange}>
        <Panel
          header={
            <span style={{ color: '#182838', fontWeight: '700', fontSize: '20px' }}>
              {'Summary'}
            </span>
          }
          key={'0'}
        >
          <p>
            {userEngagementData &&
              userEngagementData.Engagement &&
              userEngagementData.Engagement[0].Summary}
          </p>
        </Panel>
      </Collapse> */}
      {/* Will need this panels later once data is avilable. */}
      {/* {userEngagementData &&
          userEngagementData.Engagement &&
          userEngagementData.Engagement[0].LeaderBoard.map((item, index) => {
            const { Activity, CompletedCnt, Score } = item;
            return (
              <Panel
                header={
                  <span style={{ color: '#182838', fontWeight: '700', fontSize: '20px' }}>
                    {Activity}
                  </span>
                }
                key={index + 1}
                extra={genExtra(Score, CompletedCnt)}
              >
                <p>Charts will be coming soon.....</p>
              </Panel>
            );
          })} */}
      <div
        className="row p-1"
        // style={{ background: "#fff" }}
      >
        {userEngagementData &&
          userEngagementData.Engagement &&
          userEngagementData.Engagement[0] &&
          userEngagementData.Engagement[0].LeaderBoard &&
          userEngagementData.Engagement[0].LeaderBoard.map((item, index) => {
            const { Activity, CompletedCnt, Score, Description, Title } = item;
            return (
              <div className="col-md-6 mt-2 mb-2">
                <div
                  style={{
                    // border: '1px solid #ccc8c8',
                    background: '#fff',
                    padding: '5px',
                    boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.03)',
                    borderRadius: '12px',
                  }}
                >
                  {' '}
                  <div
                    style={{
                      color: '#182838',
                      fontWeight: '700',
                      fontSize: '16px',
                      textAlign: 'center',
                      margin: '10px 0px',
                    }}
                  >
                    {Activity}
                  </div>
                  {/* <div
                    style={{
                      color: 'grey',
                      fontWeight: '700',
                      fontSize: '12px',
                      textAlign: 'center',
                      margin: '10px 0px',
                    }}
                  >
                    {Description}
                  </div> */}
                  <div className="text-center pb-4">
                    <span
                      style={{
                        borderRadius: '20px',
                        backgroundColor: '#F2F2F2',
                        padding: '5px 20px',
                        color: '#182838',
                        fontWeight: '700',
                        fontSize: '14px',
                        textAlign: 'center',
                        whiteSpace: 'break-spaces',
                      }}
                    >
                      <span className="px-2">{Title}</span>
                      <span className="px-1">:</span>
                      <span className="px-2" style={{ borderRight: '1px solid #959595' }}>
                        <b>{CompletedCnt}</b>
                      </span>
                      <span className="px-2">Points</span>
                      <span className="px-1">:</span>
                      <span className="px-2">
                        <b>{Score}</b>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Engagement;
