import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetGetStudent, clientBannerGet } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import Loader from '../../Components/Loader';

class ClientImage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('clientId');
  }

  componentDidMount() {
      this.props.dispatch(
        clientBannerGet({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.getClientId(),
              },
            ],
          }),
        }),
    );
    

  }

  componentWillUnmount() {
    this.props.dispatch(resetGetStudent());
  }

  render() {
    const { FullName, ProfileImg, isFetching } = this.props;

    var imageUrl = 'images/blank-profile.png';

    if (ProfileImg) {
      imageUrl = ProfileImg;
    }
    return (
      <>
        <Loader loading={isFetching} />
        <div class="col-12 col-sm-6 d-flex flex-wrap align-items-center mb-3 mb-md-0">
          <figure class="students-profile-img mr-3">
            <img src={imageUrl} alt="" width="50" height="50" class="img-background" />
          </figure>
          <h5 class="mb-0 font-weight-semibold">{FullName}</h5>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ client_banner_get }) => {
  const { data, isFetching } = client_banner_get;
  let FullName,
    ProfileImg = null;

  if (data && data.Banner) {
    FullName = data.Banner[0].ClientFullName;
    ProfileImg = data.Banner[0].ProfileImgAttachmentUrl;
  }
  return {
    FullName,
    ProfileImg,
    isFetching,
  };
};

export default connect(mapStateToProps)(withRouter(ClientImage));
