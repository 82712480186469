import React, { Component } from 'react';
import { resetValidatePin, validateStudentPin } from '../store/actions';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import { StudentImage } from '../component/student';

class ValidatePin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: '',
      modalIsOpen: false,
    };
  }

  componentWillReceiveProps({ isValidated, inInvalid }) {
    if (isValidated) {
      this.props.resetValidatePin();
      var searchStr =
        'surveyId=' +
        this.getSurveyId() +
        '&form=' +
        this.getFormType() +
        '&studentId=' +
        this.getStudentId() +
        '&intake=' +
        this.getIntakeType();
      this.props.history.push({
        pathname: 'survey',
        search: searchStr,
      });
    }
    if (inInvalid) {
      this.setState({ modalIsOpen: true });
    }
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('surveyId');
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }
  getFormType() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('form');
  }
  getIntakeType() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('intake');
  }
  onValidate = () => {
    this.props.validateStudentPin({
      studentId: this.getStudentId(),
      pin: this.state.pin,
    });
  };

  enterButton = event => {
    if (event.key == 'Enter') {
      this.onValidate();
    }
  };

  render() {
    return (
      <>
        <div class="main survery-question-page  mb-5">
          <div class="container-fluid">
            <div class="row mt-5 mb-4  align-items-center">
              <StudentImage />
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="validate-pin-box">
                  <h5>Please Enter PIN</h5>
                  <input
                    type="password"
                    onChange={event => (this.state.pin = event.target.value)}
                    class="text-box"
                    onKeyDown={this.enterButton}
                  />
                  <button onClick={this.onValidate} class="btn val-pin">
                    Validate
                  </button>
                  <CustomModal
                    content="Please enter correct Pin"
                    isOpen={this.state.modalIsOpen}
                    closeModal={this.closeModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ student_validate_pin }) => {
  var { data, error } = student_validate_pin;
  var isValidated = false;
  var inInvalid = false;
  var errorMessage = '';
  if (data && data.IsValid) {
    isValidated = true;
  }
  if (data && !data.IsValid) {
    inInvalid = true;
  }
  if (error) {
    //errorMessage = error.Message;
  }

  return {
    isValidated: isValidated,
    errorMessage: errorMessage,
    inInvalid: inInvalid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetValidatePin: () => dispatch(resetValidatePin()),
    validateStudentPin: payload => dispatch(validateStudentPin(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidatePin);
