import React, { Component } from 'react';
import { connect } from 'react-redux';
import { featchDataActionCreator } from '../store/reducers/FeatchUserData';

class StudentsNew extends Component {
  state = {
    student: [],
  };
  componentDidMount = () => {
    this.props.dispatch(featchDataActionCreator());
  };
  render() {
    return (
      <div class="container-fluid">
        <div class="content-area">
          <div class="row">
            <div class="col-md-8 mar-top-30">
              <h1 class="main-head text-uppercase">Student list</h1>
            </div>
            <div class="col-md-4 mar-top-30">
              <input
                type="text"
                class="searchbox"
                name="s"
                placeholder="Student Search"
                id="filterbox"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mar-top-15">
              <div class="inner-form">
                <div class="row">
                  <div class="col-md-12 mar-top-15">
                    <table
                      id="example"
                      class="table dt-responsive nowrap"
                      style={{ width: '100%' }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Date of Birth/Age</th>
                          <th>Gender</th>
                          <th>Admission Date</th>
                          <th>Discharge Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.students.map(det => (
                          <tr>
                            <td key={det.id}>
                              <div class="student-list-pic">
                                <img src={det.image} alt="student" />
                              </div>
                              <div class="student-list-name">
                                <b>
                                  {det.firstName}
                                  {'   '}
                                </b>
                                {det.lastName}
                              </div>
                            </td>
                            <td>{det.dob}</td>
                            <td>{det.gender}</td>
                            <td>{det.admissionDate}</td>
                            <td>{det.dismissionDate} </td>
                            <td>
                              <span class={det.statusColor}>{det.status}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    students: state.featchuser.students,
  };
};

export default connect(mapStateToProps)(StudentsNew);
