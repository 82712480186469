const hostGrabber = () => {
  let isYouuDomain = false;
  const currentDomain = window.location.hostname;
  if (
    currentDomain == 'betaprovider.soberpeer.com' ||
    currentDomain == 'provider.youu.com' ||
    currentDomain == 'platform.youu.com' ||
    currentDomain == 'betaplatform.youu.com'
  ) {
    isYouuDomain = true;
  }
  return isYouuDomain;
};

export default hostGrabber;
