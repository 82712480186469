import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { Button } from 'antd';
import renderHTML from 'react-render-html';
import ListRenderer from '../../../../Components/listRenderer';
import ViewScreeningAnswer from '../../components/ViewScreeningAnswer';
import FlaggedStatus from '../../components/FlaggedStatus';
import { studentBanner, listSurveyQuestionAssessment } from '../../../../store/actions';
import { Loader2 } from '../../../../Components/Loader';

function getDefaultTab(props) {
  const query = new URLSearchParams(props.location.search);
  let tabName = query.get('tab_name');
  if (tabName === 'notes') {
    return 0;
  } else if (tabName === 'surveys') {
    return 1;
  } else if (tabName === 'tasks') {
    return 2;
  } else if (tabName === 'courses') {
    return 3;
  } else if (tabName === 'growthplan') {
    return 4;
  }
}
class ViewAnswerTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      tabList: null,
      //   activeKey: getDefaultTab(props),
      isDocFetching: false,
    };
  }
  componentDidMount() {
    if (this.props.PreItakeSurveyId) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: this.props.ClientId,
                SurveyId: this.props.PreItakeSurveyId,
                SurveyType: 'ClientScreening',
              },
            ],
          }),
        }),
      );
    }
  }
  componentWillReceiveProps({ surveyQuestion, bannerInfo }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      let tabList = [];
      surveyQuestion.SurveyQuestion &&
        surveyQuestion.SurveyQuestion.map(survey => {
          if (survey.Questions && survey.Questions.length > 0) {
            tabList.push({
              Name: renderHTML(survey.Tab),
              ExtraComponent: props => {
                return (
                  <div className="">
                    <ViewScreeningAnswer Questions={survey.Questions} />
                  </div>
                );
              },
            });
          }
        });

      this.setState({ SurveyQuestionList: surveyQuestion, tabList: tabList });
    }
    if (bannerInfo && JSON.stringify(bannerInfo) != JSON.stringify(this.props.bannerInfo)) {
      if (bannerInfo.PreItakeSurveyId) {
        this.props.dispatch(
          listSurveyQuestionAssessment({
            json: JSON.stringify({
              Survey: [
                {
                  StudentId: bannerInfo.ClientId,
                  SurveyId: bannerInfo.PreItakeSurveyId,
                  SurveyType: 'ClientScreening',
                },
              ],
            }),
          }),
        );
      }
    }
  }
  viewHistory = () => {
    this.props.viewHistory('3', 42, '7_ClientScreening');
  };

  printClientScreeningPdf = async () => {
    if (this.props.PreItakeSurveyId) {
      let data = {
        JsonInput: JSON.stringify({
          ClientScreeing: [
            {
              StudentId: this.props.ClientId,
              SurveyId: this.props.PreItakeSurveyId,
            },
          ],
        }),
      };
      this.setState({
        isDocFetching: true,
      });
      const response = await Axios.post('Survey/ScreeningQuestionExport', data);

      if (response.status == 200) {
        const res = await fetch(response.data.ExportUrl);
        // const res = await fetch(
        //   'https://drdevattach.blob.core.windows.net/doc-uploads/05fc0a14-378e-4dac-943f-2b6cf750c00b.pdf?sp=r&st=2023-07-27T08:23:26Z&se=2023-07-28T16:23:26Z&spr=https&sv=2022-11-02&sr=b&sig=mWwBiJECqfy0HRRH2Tp78fihaM39Hr9aNeDbkbLTugM%3D',
        // );

        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        this.setState({
          isDocFetching: false,
        });
      } else if (response.status != 200) {
        this.setState({
          isDocFetching: false,
        });
      }
    }
  };
  render() {
    const { bannerInfo, isFetching } = this.props;
    const { tabList, isDocFetching } = this.state;
    // const list = [
    //   {
    //     Name: 'Legal',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Physical',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Medical',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'DNR',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Financial',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    // ];

    return (
      <div className="">
        <div className="row align-items-center mt-3">
          <div className="col-sm-12 col-md-6 mt-2">
            <div className="d-flex">
              <h6>Client Screening</h6>
              <span
                onClick={this.viewHistory}
                style={{
                  color: '#009EF7',
                  cursor: 'pointer',
                  fontSize: '14px',
                  margin: '0px 5px',
                }}
              >
                {this.props.ClientId > 0 && 'View History'}
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 d-flex justify-content-end align-items-center">
            {tabList && tabList.length > 0 && (
              <Button
                loading={isDocFetching}
                icon={<img style={{ marginRight: '5px' }} src="images/export_pdf.svg" />}
                onClick={() => this.printClientScreeningPdf()}
                className="btn save-button export-pdf"
              >
                Export as PDF
              </Button>
            )}
            {tabList && tabList.length > 0 && (
              <div className="p-3">
                <FlaggedStatus FlaggedStatus={bannerInfo} />
              </div>
            )}
          </div>
        </div>
        <div>
          <Loader2 loading={isFetching} />
          <div className="forms show-tabs center-tabs nested-tabs-content">
            {tabList && tabList.length > 0 && (
              <ListRenderer
                list={tabList}
                activeKey={this.state.activeKey}
                // getActiveKey={activeKey => this.setState({ activeKey })}
              />
            )}
          </div>
          <div class="col-sm-12 text-center mt-5">
            {(!tabList || (tabList && tabList.length == 0)) && <h6>No Data Available</h6>}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { student_banner, list_survey_question_assessment } = state;
  const { data, isFetching, error } = student_banner;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = list_survey_question_assessment;

  let bannerInfo = null;
  let preItakeSurveyId = null;
  let surveyQuestion;

  if (data) {
    bannerInfo = data || {};
    preItakeSurveyId = data.PreItakeSurveyId;
  }
  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }
  return {
    bannerInfo,
    preItakeSurveyId,
    surveyQuestion,
    isFetching: isFetching || isFetchingSurveyQuestion,
  };
};
export default connect(mapStateToProps)(withRouter(ViewAnswerTab));
