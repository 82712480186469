import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { resetLogin, logOut } from '../../store/actions';
import AntdModal from '../../Components/CustomModal';
import { userFeedback, removeUserFeedback } from '../../store/actions';
import { Modal } from 'antd';
import DefaultCenter from '../defaultCenter';
import { LIST_ROUTES } from '../../store/menuConfig';
import AskYOUUniverse from '../../Screens/YOUUniverse';
import { MenuItem, SubMenuItem } from './menuItems';
import hostGrabber from '../../util/hostGrabber';
class SupportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myText: '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.myText !== this.state.myText) return true;
    return false;
  }

  render() {
    const { onOk, getUserFeedBack, onCancel } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 className="modal-title col-12">Please share your feedback here</h4>
        </div>
        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div className="form-group">
            <textarea
              className={`form-control newcontract-textarea`}
              type="text"
              placeholder="Feedback"
              rows="4"
              value={this.state.myText}
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ myText: newValue });
              }}
            />
          </div>
        </div>

        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            className="btn"
            onClick={() => {
              getUserFeedBack(this.state.myText);
              onOk();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

class Menu extends Component {
  state = { showSubMenu: false, showMenu: false };

  constructor(props) {
    super(props);
    this.state = {
      menu: true,
      menus: [],
      centerName: '',
      userName: '',
      userRole: '',
      profileImgUrl: null,
      email: '',
    };
    var menu = localStorage.getItem('dr:menu');
    if (menu !== undefined) {
      this.state.menus = JSON.parse(menu);
    }

    localStorage.setItem('isWebCam', 1);
  }

  getActiveUrl = path => {
    const { pathname } = this.props.location;
    var exists = pathname.includes(path);
    if (exists > 0) return true;
    else return false;
  };
  getChildActiveUrl = subMenu => {
    const { pathname } = this.props.location;
    const checkPath = value => pathname.includes(value.Path);
    let exists = subMenu.some(checkPath);
    return exists;
  };

  onLogOut() {
    this.props.dispatch(resetLogin);
    this.props.dispatch(logOut({}));
    this.props.logOutBroadcaster();
    // localStorage.clear();
    // sessionStorage.clear();
    //this.props.history.push('/login');
    // window.location.href = '/';
    console.log('master2 menu=>logout executed');
  }

  componentDidMount() {
    const me = this;
    var menu = JSON.parse(localStorage.getItem('dr:menu'));
    let inHouseMenu = [];
    if (menu) {
      for (let menuItem of menu) {
        if (menuItem.IsInHouse) {
          inHouseMenu.push(menuItem);
        }
      }
    }
    if (menu) {
      me.setState({
        menus: inHouseMenu,
        userName: localStorage.getItem('userName'),
        userRole: localStorage.getItem('userRole'),
        centerName: localStorage.getItem('centerName'),
        profileImgUrl: localStorage.getItem('profileImgUrl'),
        hasClinicalAccess: JSON.parse(localStorage.getItem('hasClinicalAccess')),
        email: localStorage.getItem('email'),
        adminUserInfo: localStorage.getItem('adminUserInfo'),
      });
    } else {
      /*Axios.post('user/ListMenu', {}).then(response => {
        localStorage.setItem('userName', response.data.UserName);
        localStorage.setItem('userRole', response.data.UserRole);
        localStorage.setItem('centerName', response.data.CenterName);
        localStorage.setItem('profileImgUrl', response.data.ProfileImgUrl);
        localStorage.setItem('email', response.data.Email);
        localStorage.setItem('adminUserInfo', JSON.stringify(response.data.AdminUserInfo));
        if (response.data.data) {
          for (let menuItem of response.data.data) {
            if (menuItem.IsInHouse) {
              inHouseMenu.push(menuItem);
            }
          }
        }
        me.setState({
          menus: inHouseMenu,
          userName: response.data.UserName,
          userRole: response.data.UserRole,
          centerName: response.data.CenterName,
          profileImgUrl: response.data.ProfileImgUrl,
          email: response.data.Email,
        });
      });*/
    }
  }

  getUserFeedBack = Text => {
    this.props.dispatch(
      userFeedback({
        Notes: Text,
      }),
    );
  };
  render() {
    const {
      showSubMenu,
      showMenu,
      userName,
      menus,
      userRole,
      profileImgUrl,
      hasClinicalAccess,
    } = this.state;
    const { CustomLogo, isFetching, isGlobalChatEnabled, FeatureFlag } = this.props;
    return (
      <header className="header mb-3 bg-white" style={{ minHeight: '100px' }}>
        <nav className="navbar navbar-light navbar-expand-md static-top pl-0 pr-0">
          <div className="container-fluid new-header-css">
            {/* <a className="navbar-brand" title="Sober peer">
              <img src="images/logo.svg" />
            </a> */}
            <Link className="navbar-brand" to={`${this.props.defaultDashboard}`}>
              <img
                src={
                  !isFetching &&
                  (CustomLogo
                    ? `images/${CustomLogo}`
                    : hostGrabber()
                    ? 'images/youulogo.png'
                    : 'images/logo.png')
                }
                width={hostGrabber() ? !CustomLogo && '150px' : !CustomLogo && '48px'}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() =>
                this.setState(prevState => {
                  return { showMenu: !prevState.showMenu };
                })
              }
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse header-nav ${showMenu &&
                'show'} align-item-center`}
              id="navbarResponsive"
            >
              <ul className="navbar-nav mr-auto  align-items-md-center">
                {menus.map((menuItem, index) => {
                  if (menuItem.Text && !menuItem.hideMenu) {
                    return menuItem.SubMenu ? (
                      <SubMenuItem
                        menuItem={menuItem}
                        index={index}
                        getChildActiveUrl={this.getChildActiveUrl}
                        SubMenu={menuItem.SubMenu}
                        FeatureFlag={FeatureFlag}
                      />
                    ) : (
                      <MenuItem
                        menuItem={menuItem}
                        index={index}
                        getActiveUrl={this.getActiveUrl}
                        location={this.props.location}
                      />
                    );
                  }
                })}
              </ul>
              {isGlobalChatEnabled && <AskYOUUniverse />}
              <div className="d-flex navbar-nav  align-items-center">
                <div className="nav-item">
                  <div className="dashboard-center">
                    <span
                      style={{
                        fontWeight: '600',
                        margin: '0px 10px',
                        borderLeft: '2px solid #D8D8D8',
                        paddingLeft: '10px',
                      }}
                    >
                      Center:
                    </span>
                    <DefaultCenter
                    // initialValues={ { centerId: 2 } }
                    />
                  </div>
                </div>
                <div
                  className="nav-item dropdown user-info"
                  onMouseEnter={() => this.setState({ showSubMenu: true })}
                  onMouseLeave={() => this.setState({ showSubMenu: false })}
                >
                  <a className="nav-link dropdown-toggle d-flex align-items-center">
                    <img
                      src={
                        profileImgUrl && profileImgUrl != 'null'
                          ? profileImgUrl
                          : 'images/blank-profile.png'
                      }
                      className="rounded-circle face-fit-cover"
                      width="47"
                      height="47"
                      alt=""
                    />
                    <span className="ml-2">
                      {userName}
                      <span className="user-name">{userRole}</span>
                    </span>
                  </a>
                  <div
                    className={`dropdown-menu ${showSubMenu && 'show'}`}
                    style={{ marginTop: '-5px' }}
                  >
                    {this.props.dashboardClinical && hasClinicalAccess && (
                      <Link
                        className="dropdown-item"
                        to={`${this.props.dashboardClinical}`}
                        style={{ background: '#4ab0fe', color: '#fff' }}
                      >
                        <svg
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 5C11.7239 5 11.5 5.22386 11.5 5.5C11.5 5.77614 11.7239 6 12 6L12 8C12 8.39782 11.842 8.77935 11.5607 9.06066C11.2794 9.34196 10.8978 9.5 10.5 9.5C10.1022 9.5 9.72065 9.34196 9.43934 9.06066C9.15804 8.77935 9 8.39782 9 8L9 6C9.27614 6 9.5 5.77614 9.5 5.5C9.5 5.22386 9.27614 5 9 5L8.75 5C8.33579 5 8 5.33579 8 5.75L8 8C8.00075 8.57604 8.20009 9.13421 8.56441 9.58041C8.92873 10.0266 9.43575 10.3336 10 10.4495L10 11C10 11.5304 9.78929 12.0391 9.41422 12.4142C9.03914 12.7893 8.53044 13 8 13C7.46957 13 6.96086 12.7893 6.58579 12.4142C6.21072 12.0391 6 11.5304 6 11L6 9.908C6.33361 9.79005 6.61478 9.55796 6.79382 9.25276C6.97286 8.94755 7.03824 8.58888 6.9784 8.24013C6.91856 7.89138 6.73737 7.57502 6.46683 7.34695C6.1963 7.11888 5.85385 6.99379 5.5 6.99379C5.14616 6.99379 4.80371 7.11888 4.53317 7.34695C4.26264 7.57502 4.08144 7.89138 4.02161 8.24013C3.96177 8.58888 4.02715 8.94755 4.20619 9.25276C4.38523 9.55796 4.6664 9.79005 5 9.908L5 11C5 11.7956 5.31607 12.5587 5.87868 13.1213C6.44129 13.6839 7.20435 14 8 14C8.79565 14 9.55871 13.6839 10.1213 13.1213C10.6839 12.5587 11 11.7956 11 11L11 10.4495C11.5643 10.3336 12.0713 10.0266 12.4356 9.58041C12.7999 9.13421 12.9993 8.57604 13 8L13 5.75C13 5.33579 12.6642 5 12.25 5L12 5ZM5.5 8C5.59889 8 5.69556 8.02932 5.77779 8.08426C5.86001 8.1392 5.9241 8.21729 5.96194 8.30866C5.99979 8.40002 6.00969 8.50055 5.9904 8.59754C5.9711 8.69454 5.92348 8.78363 5.85356 8.85355C5.78363 8.92348 5.69454 8.9711 5.59755 8.99039C5.50056 9.00968 5.40002 8.99978 5.30866 8.96194C5.2173 8.9241 5.13921 8.86001 5.08427 8.77778C5.02933 8.69556 5 8.59889 5 8.5C5 8.36739 5.05268 8.24021 5.14645 8.14645C5.24022 8.05268 5.36739 8 5.5 8Z"
                            fill="white"
                          />
                          <path
                            d="M11 1L9 1L9 0.5C9 0.223858 8.77614 0 8.5 0C8.22386 0 8 0.223858 8 0.5L8 1L4 1L4 0.5C4 0.223858 3.77614 0 3.5 0C3.22386 0 3 0.223858 3 0.5L3 1L1 1C0.734783 1 0.480429 1.10536 0.292893 1.29289C0.105357 1.48043 0 1.73478 0 2L0 12C0 12.2652 0.105357 12.5196 0.292893 12.7071C0.480429 12.8946 0.734783 13 1 13L2.5 13C2.77614 13 3 12.7761 3 12.5C3 12.2239 2.77614 12 2.5 12L1 12L1 2L3 2L3 2.5C3 2.77614 3.22386 3 3.5 3C3.77614 3 4 2.77614 4 2.5L4 2L8 2L8 2.5C8 2.77614 8.22386 3 8.5 3C8.77614 3 9 2.77614 9 2.5L9 2L11 2L11 3.5C11 3.77614 11.2239 4 11.5 4C11.7761 4 12 3.77614 12 3.5L12 2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1Z"
                            fill="white"
                          />
                        </svg>{' '}
                        Switch to Clinical
                      </Link>
                    )}
                    <Link className="dropdown-item" to="/settings">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M7.742 10.934a.742.742 0 1 1 0-1.485h8.647a.742.742 0 1 1 0 1.485H7.742zm13.1 2.412a2.23 2.23 0 0 1-2.227-2.227V9.227A2.23 2.23 0 0 1 20.842 7a2.23 2.23 0 0 1 2.226 2.227v.222h2.19a.742.742 0 1 1 0 1.485h-2.19v.185a2.23 2.23 0 0 1-2.226 2.227zm-.742-4.12v1.893a.743.743 0 0 0 1.484 0V9.227a.743.743 0 0 0-1.484 0zm-3.713 12.878a.742.742 0 1 1 0 1.484H7.742a.742.742 0 1 1 0-1.484h8.645zm-8.645-4.866a.742.742 0 1 1 0-1.485h2.115a.742.742 0 1 1 0 1.485H7.742zm17.516-1.485a.742.742 0 0 1 0 1.485h-8.72v.19a2.23 2.23 0 0 1-2.227 2.226 2.23 2.23 0 0 1-2.227-2.226v-1.93a2.23 2.23 0 0 1 2.227-2.227 2.23 2.23 0 0 1 2.226 2.227v.255h8.72zm-10.205 1.675v-1.93a.743.743 0 0 0-1.485 0v1.93a.743.743 0 0 0 1.485 0zm10.205 4.676a.742.742 0 1 1 0 1.484h-2.191v.185A2.23 2.23 0 0 1 20.84 26a2.23 2.23 0 0 1-2.226-2.227v-1.892a2.23 2.23 0 0 1 2.226-2.227 2.23 2.23 0 0 1 2.227 2.227v.223h2.19zm-3.676 1.67V21.88a.743.743 0 0 0-1.484 0v1.892a.743.743 0 0 0 1.484 0z"
                        />
                      </svg>
                      Settings
                    </Link>
                    <a
                      className="dropdown-item"
                      href="https://support.soberpeer.com"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M15.96 21.664a.781.781 0 1 1 0-1.562.781.781 0 0 1 0 1.562zm.782-3.516V17.1a3.256 3.256 0 0 0 2.498-3.285c-.065-1.688-1.45-3.06-3.152-3.124a3.276 3.276 0 0 0-2.419.917 3.199 3.199 0 0 0-.99 2.323.781.781 0 1 0 1.563 0c0-.455.182-.88.511-1.198.341-.328.794-.499 1.276-.48.891.033 1.616.745 1.65 1.622a1.695 1.695 0 0 1-1.341 1.715 1.46 1.46 0 0 0-1.158 1.433v1.126a.781.781 0 1 0 1.562 0zm4.432 6.41a.781.781 0 1 0-.81-1.336A8.423 8.423 0 0 1 16 24.437c-4.652 0-8.438-3.785-8.438-8.437 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.437 3.786 8.437 8.438a8.404 8.404 0 0 1-1.43 4.683.781.781 0 0 0 1.299.868A9.96 9.96 0 0 0 26 16a9.935 9.935 0 0 0-2.929-7.071A9.935 9.935 0 0 0 16 6a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 6 16a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 16 26a9.984 9.984 0 0 0 5.174-1.441z"
                        />
                      </svg>
                      Help
                    </a>

                    <span className="dropdown-item">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M15.96 21.664a.781.781 0 1 1 0-1.562.781.781 0 0 1 0 1.562zm.782-3.516V17.1a3.256 3.256 0 0 0 2.498-3.285c-.065-1.688-1.45-3.06-3.152-3.124a3.276 3.276 0 0 0-2.419.917 3.199 3.199 0 0 0-.99 2.323.781.781 0 1 0 1.563 0c0-.455.182-.88.511-1.198.341-.328.794-.499 1.276-.48.891.033 1.616.745 1.65 1.622a1.695 1.695 0 0 1-1.341 1.715 1.46 1.46 0 0 0-1.158 1.433v1.126a.781.781 0 1 0 1.562 0zm4.432 6.41a.781.781 0 1 0-.81-1.336A8.423 8.423 0 0 1 16 24.437c-4.652 0-8.438-3.785-8.438-8.437 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.437 3.786 8.437 8.438a8.404 8.404 0 0 1-1.43 4.683.781.781 0 0 0 1.299.868A9.96 9.96 0 0 0 26 16a9.935 9.935 0 0 0-2.929-7.071A9.935 9.935 0 0 0 16 6a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 6 16a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 16 26a9.984 9.984 0 0 0 5.174-1.441z"
                        />
                      </svg>

                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <a
                            onClick={() => {
                              this.setState({ showSubMenu: false });
                              onClick();
                            }}
                          >
                            Support/Feedback
                          </a>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <SupportForm
                            onOk={onOk}
                            onCancel={onCancel}
                            getUserFeedBack={this.getUserFeedBack}
                          />
                        )}
                      />
                    </span>
                    <Modal
                      width={600}
                      visible={this.props.modalOpen}
                      footer={null}
                      closeIcon={null}
                      onCancel={() => this.props.dispatch(removeUserFeedback())}
                      closable={false}
                    >
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => this.props.dispatch(removeUserFeedback())}
                          >
                            &times;
                          </button>
                          <h4 className="modal-title col-12">Thank you for the feedback.</h4>
                        </div>
                        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
                          <div className="form-group" />
                        </div>

                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            className="btn"
                            onClick={() => this.props.dispatch(removeUserFeedback())}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </Modal>
                    <a className="dropdown-item" onClick={this.onLogOut.bind(this)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <g fill="#9A9A9A" fill-rule="nonzero">
                          <path d="M15.521 6.388l-1.509 2.14a.586.586 0 0 0 .48.924h.922v10.275a.586.586 0 0 0 1.172 0V9.452h.923c.477 0 .75-.539.479-.924l-1.51-2.14a.587.587 0 0 0-.957 0z" />
                          <path d="M19.762 9.325a.586.586 0 0 0 .214.801 7.952 7.952 0 0 1 3.977 6.89c0 4.385-3.568 7.953-7.953 7.953S8.047 21.4 8.047 17.016a7.952 7.952 0 0 1 3.977-6.89.586.586 0 0 0-.587-1.014 9.124 9.124 0 0 0-4.562 7.904A9.12 9.12 0 0 0 16 26.14a9.12 9.12 0 0 0 9.125-9.125 9.124 9.124 0 0 0-4.562-7.904.586.586 0 0 0-.8.213z" />
                        </g>
                      </svg>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = ({ user_feedback, default_values, user_feature_flag_get }) => {
  const { data, isFetching } = default_values;
  const { data: preIntakeStatus } = user_feature_flag_get;

  var modalOpen = false;
  let defaultDashboard;
  let dashboardClinical;
  let isGlobalChatEnabled;
  let CustomLogo;
  let FeatureFlag = null;

  if (data) {
    defaultDashboard = data.defaultDashboard;
    dashboardClinical = data.dashboardClinical;
    isGlobalChatEnabled = data.IsGlobalChatEnabled;
    CustomLogo = data.CustomLogo;
  }

  if (user_feedback && user_feedback.data) {
    modalOpen = (user_feedback && user_feedback.data && user_feedback.data.success) || false;
  }
  if (
    preIntakeStatus &&
    preIntakeStatus.FeatureFlag &&
    preIntakeStatus.FeatureFlag.length > 0 &&
    preIntakeStatus.FeatureFlag[0]
  ) {
    FeatureFlag = preIntakeStatus.FeatureFlag[0];
  }
  return {
    modalOpen,
    defaultDashboard,
    dashboardClinical,
    CustomLogo,
    isFetching,
    isGlobalChatEnabled,
    FeatureFlag,
  };
};

export default withRouter(connect(mapStateToProps)(Menu));
