import React, { Component } from 'react';

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { QuestionFormatId, name, answerOptions } = this.props;
    const { SurveyNumericInputCondition } = answerOptions || {};
    return (
      <div class="row mb-3 options-row">
        <span
          style={{
            border: '1px solid #AAACB5',
            borderRadius: '4px',
            height: '100px',
            width: '100%',
            padding: '10px',
            color: '#A1A5B7',
          }}
        >
          User will type the answer here
        </span>
      </div>
    );
  }
}
export default Textarea;
