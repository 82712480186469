import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* payerPortalDashBoard(action) {
  try {
    const response = yield API.payerPortalDashBoard(action.payload);
    yield put({
      type: ActionTypes.PAYER_PORTAL_DASHBOARD_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_PORTAL_DASHBOARD_FAILURE,
    });
  }
}

export function* payerPortalAuthorizationGet(action) {
  try {
    const response = yield API.payerPortalAuthorizationGet(action.payload);
    yield put({
      type: ActionTypes.PAYER_PORTAL_AUTHORIZATION_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_PORTAL_AUTHORIZATION_GET_FAILURE,
    });
  }
}

export function* payerPortalAuthorizationStatusUpsert(action) {
  try {
    const response = yield API.payerPortalAuthorizationStatusUpsert(action.payload);
    yield put({
      type: ActionTypes.PAYER_PORTAL_AUTHORIZATION_STATUS_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_PORTAL_AUTHORIZATION_STATUS_UPSERT_FAILURE,
    });
  }
}

export function* payerPortalRequestInsert(action) {
  try {
    const response = yield API.payerPortalRequestInsert(action.payload);
    yield put({
      type: ActionTypes.PAYER_PORTAL_REQUEST_INSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_PORTAL_REQUEST_INSERT_FAILURE,
    });
  }
}

export function* payerPriceDetailsGet(action) {
  try {
    const response = yield API.payerPriceDetailsGet(action.payload);
    yield put({
      type: ActionTypes.PAYER_PRICE_DETAILS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_PRICE_DETAILS_GET_FAILURE,
    });
  }
}

export function* payerPortalRequestUpsert(action) {
  try {
    const response = yield API.payerPortalRequestUpsert(action.payload);
    yield put({
      type: ActionTypes.PAYER_PORTAL_REQUEST_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_PORTAL_REQUEST_UPSERT_FAILURE,
    });
  }
}

export function* smartContractTermsConditionGetAllInfoJson(action) {
  try {
    const response = yield API.smartContractTermsConditionGetAllInfoJson(action.payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_TERMS_CONDITION_GETALLINFO_JSON_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_TERMS_CONDITION_GETALLINFO_JSON_FAILURE,
    });
  }
}

export function* payerPortalRequestGet(action) {
  try {
    const response = yield API.payerPortalRequestGet(action.payload);
    yield put({
      type: ActionTypes.PAYER_PORTAL_REQUEST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_PORTAL_REQUEST_GET_FAILURE,
    });
  }
}

export function* groupServiceLineApproveAllBillingOrBatching(action) {
  try {
    const response = yield API.groupServiceLineApproveAllBillingOrBatching(action.payload);
    yield put({
      type: ActionTypes.GROUP_SERVICE_LINE_APPROVE_ALL_BILLING_OR_BATCHING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GROUP_SERVICE_LINE_APPROVE_ALL_BILLING_OR_BATCHING_FAILURE,
    });
  }
}

export function* payerTermsConditionsStatusUpsert(action) {
  try {
    const response = yield API.payerTermsConditionsStatusUpsert(action.payload);
    yield put({
      type: ActionTypes.PAYER_TERMS_CONDITIONS_STATUS_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PAYER_TERMS_CONDITIONS_STATUS_UPSERT_FAILURE,
    });
  }
}

export function* clientCareTeamListGet(action) {
  try {
    const response = yield API.clientCareTeamListGet(action.payload);
    yield put({
      type: ActionTypes.CLIENT_CARE_TEAM_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_CARE_TEAM_LIST_GET_FAILURE,
    });
  }
}

export function* clientReportGet(action) {
  try {
    const response = yield API.clientReportGet(action.payload);
    yield put({
      type: ActionTypes.CLIENT_REPORT_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_REPORT_GET_FAILURE,
    });
  }
}

export function* clientRiskLevelGraphGet(action) {
  try {
    const response = yield API.clientRiskLevelGraphGet(action.payload);
    yield put({
      type: ActionTypes.CLIENT_RISK_LEVEL_GRAPH_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_RISK_LEVEL_GRAPH_GET_FAILURE,
    });
  }
}

export function* contractPeriodGraphGet(action) {
  try {
    const response = yield API.contractPeriodGraphGet(action.payload);
    yield put({
      type: ActionTypes.CONTRACT_PERIOD_GRAPH_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CONTRACT_PERIOD_GRAPH_GET_FAILURE,
    });
  }
}

export function* dashboardClaimsGet(action) {
  try {
    const response = yield API.dashboardClaimsGet(action.payload);
    yield put({
      type: ActionTypes.DASHBOARD_CLAIMS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_CLAIMS_GET_FAILURE,
    });
  }
}

export function* dashboardCardsGet(action) {
  try {
    const response = yield API.dashboardCardsGet(action.payload);
    yield put({
      type: ActionTypes.DASHBOARD_CARDS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_CARDS_GET_FAILURE,
    });
  }
}

export function* riskProfileDataGraphGet(action) {
  try {
    const response = yield API.riskProfileDataGraphGet(action.payload);
    yield put({
      type: ActionTypes.RISK_PROFILE_DATA_GRAPH_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RISK_PROFILE_DATA_GRAPH_GET_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.PAYER_PORTAL_DASHBOARD_REQUEST, payerPortalDashBoard),
    takeLatest(ActionTypes.PAYER_PORTAL_AUTHORIZATION_GET_REQUEST, payerPortalAuthorizationGet),
    takeLatest(
      ActionTypes.PAYER_PORTAL_AUTHORIZATION_STATUS_UPSERT_REQUEST,
      payerPortalAuthorizationStatusUpsert,
    ),
    takeLatest(ActionTypes.PAYER_PORTAL_REQUEST_INSERT_REQUEST, payerPortalRequestInsert),
    takeLatest(ActionTypes.PAYER_PRICE_DETAILS_GET_REQUEST, payerPriceDetailsGet),
    takeLatest(ActionTypes.PAYER_PORTAL_REQUEST_UPSERT_REQUEST, payerPortalRequestUpsert),
    takeLatest(
      ActionTypes.SMART_CONTRACT_TERMS_CONDITION_GETALLINFO_JSON_REQUEST,
      smartContractTermsConditionGetAllInfoJson,
    ),
    takeLatest(ActionTypes.PAYER_PORTAL_REQUEST_GET_REQUEST, payerPortalRequestGet),
    takeLatest(
      ActionTypes.GROUP_SERVICE_LINE_APPROVE_ALL_BILLING_OR_BATCHING_REQUEST,
      groupServiceLineApproveAllBillingOrBatching,
    ),
    takeLatest(
      ActionTypes.PAYER_TERMS_CONDITIONS_STATUS_UPSERT_REQUEST,
      payerTermsConditionsStatusUpsert,
    ),
    takeLatest(ActionTypes.CLIENT_CARE_TEAM_LIST_GET_REQUEST, clientCareTeamListGet),
    takeLatest(ActionTypes.CLIENT_REPORT_GET_REQUEST, clientReportGet),
    takeLatest(ActionTypes.CLIENT_RISK_LEVEL_GRAPH_GET_REQUEST, clientRiskLevelGraphGet),
    takeLatest(ActionTypes.CONTRACT_PERIOD_GRAPH_GET_REQUEST, contractPeriodGraphGet),
    takeLatest(ActionTypes.DASHBOARD_CLAIMS_GET_REQUEST, dashboardClaimsGet),
    takeLatest(ActionTypes.DASHBOARD_CARDS_GET_REQUEST, dashboardCardsGet),
    takeLatest(ActionTypes.RISK_PROFILE_DATA_GRAPH_GET_REQUEST, riskProfileDataGraphGet),
  ]);
}
