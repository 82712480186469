import React from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { Upload, message, Modal, Popconfirm, Tooltip, Drawer, Button, Input } from 'antd';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import TextareaAutosize from 'react-autosize-textarea';
import DictationComponent from '../../../../../Components/DictationComponent';
import {
  listCombos,
  medicateCombos,
  practitionerCombosOffice,
  upsertGroup,
  listGroupMembership,
  resetAddCallSession,
  deleteGroup,
  getPatientList,
  getClientGroup,
  upsertClientGroup,
  getListClientGroup,
  appointmentNoteList,
  getAppointmentNote,
  upsertAppointmentNote,
  upsertAppointmentNoteAddendum,
  getTreatmentPlanGraph,
  unsignForm,
  callNotesAugment,
  resetCallNotesAugment,
  resetUpsertAppointmentNote,
  gptAugmentationUpdate,
} from '../../../../../store/actions';
import ValidatePin from '../../../../../Components/validatePinModal';
import AntdModal from '../../../../../Components/CustomModal';
import { Loader2 } from '../../../../../Components/Loader';
import SuggestCPTCode from './SuggestCPTCode';

class AppointmentNotesAI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: null,
      appointmentNoteId: null,
      validPinModalVisible: false,
    };
  }
  handleDictate = result => {
    if (result) {
      let data;
      if (this.state.notes) {
        data = this.state.notes + ' ' + result;
      } else {
        data = result;
      }
      this.setState({ notes: data });
    }
  };
  handleAINoteDictate = result => {
    if (result) {
      let data;
      if (this.state.value) {
        data = this.state.value + ' ' + result;
      } else {
        data = result;
      }
      this.setState({ value: data });
    }
  };

  getAppointmentNoteData = () => {
    const { IsGroup, ClientId, AppointmentId } = this.props.AppointmentData || {};
    this.props.dispatch(
      getAppointmentNote({
        Json: JSON.stringify({
          AppointmentNote: [
            {
              AppointmentId: AppointmentId,
            },
          ],
        }),
      }),
    );
  };

  handleAugmentation = () => {
    const { StudentId, AppointmentData } = this.props;
    const { IsGroup, ClientId, AppointmentId } = AppointmentData || {};
    this.props.dispatch(
      callNotesAugment({
        Json: JSON.stringify({
          Student: [
            {
              AppointmentId: AppointmentId,
              StudentId: StudentId,
              AugmentType: 2,
              IsRegenerate: false,
            },
          ],
        }),
      }),
    );
  };

  preValidatePin = genratedAppointmentNoteId => {
    let data = {
      Json: JSON.stringify({
        Staff: [
          {
            FormType: 'AppointmentNote',
            FormId: genratedAppointmentNoteId,
          },
        ],
      }),
    };
    let me = this;
    Axios.post('Medicaid/PreValidatePin', data).then(response => {
      let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
      let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
      let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;
      if (isValid) {
        me.setState({
          // note: '',
          validPinModalVisible: true,
          IsRealSignature: IsRealSignature,
          FormType: 'AppointmentNote',
          FormId: genratedAppointmentNoteId,
          isSign: false,
        });
      } else {
        // me.props.toggle(failMsg);
        me.setState({
          isSign: false,
        });
      }
    });
    debugger;
  };
  componentDidMount() {
    this.getAppointmentNoteData();
  }
  componentWillReceiveProps({
    appointmentNote,
    upsertNoteSuccess,
    genratedAppointmentNoteId,
    validateStaffPinData,
    unsignFormStatus,
  }) {
    if (
      appointmentNote &&
      JSON.stringify(appointmentNote) != JSON.stringify(this.props.appointmentNote)
    ) {
      this.setState({
        notes: appointmentNote.Note,
        appointmentNoteId: appointmentNote.AppointmentNoteId,
        appointmentNote: appointmentNote,
      });
    }
    if (upsertNoteSuccess && upsertNoteSuccess != this.props.upsertNoteSuccess) {
      if (this.state.isSign) {
        this.preValidatePin(genratedAppointmentNoteId);
      } else {
        this.setState(
          {
            showNoteSucess: 'Note Saved Successfully',
            appointmentNoteId: genratedAppointmentNoteId,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showNoteSucess: '',
              });
            }, 2000);
          },
        );
      }
      this.getAppointmentNoteData();
    }
    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      if (validateStaffPinData.IsValid) {
        const { AppointmentId, ClientId } = this.props.AppointmentData || {};

        this.props.dispatch(
          upsertAppointmentNote({
            Json: JSON.stringify({
              AppointmentNote: [
                {
                  AppointmentId: AppointmentId,
                  AppointmentNoteId: this.state.appointmentNoteId,
                  Note: this.state.notes,
                  IsSign: true,
                  ClientId: ClientId,
                  TypeOfNoteId: 21101,
                  StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId,
                  SupervisorStaffPinHistoryId: validateStaffPinData.SupervisorStaffPinHistoryId,
                },
              ],
            }),
          }),
        );
      }
    }
    if (unsignFormStatus && unsignFormStatus != this.props.unsignFormStatus) {
      this.getAppointmentNoteData();
    }
  }
  resetValidPinModalVisible = () => {
    this.setState({ validPinModalVisible: !this.state.validPinModalVisible });
  };
  offTheModal = () => {
    this.setState({ ...this.state, validPinModalVisible: false });
  };
  unsignFormFun = (FormType, FormId) => {
    let data = {
      Json: JSON.stringify({
        UnSign: [
          {
            FormType,
            FormId,
          },
        ],
      }),
    };

    this.props.dispatch(unsignForm(data));
  };
  saveNote = isSign => {
    const { AppointmentId, ClientId } = this.props.AppointmentData || {};
    this.props.dispatch(
      upsertAppointmentNote({
        Json: JSON.stringify({
          AppointmentNote: [
            {
              AppointmentId: AppointmentId,
              AppointmentNoteId: this.state.appointmentNoteId,
              Note: this.state.notes,
              IsSign: false,
              ClientId: ClientId,
              TypeOfNoteId: 21101,
            },
          ],
        }),
      }),
    );
    this.setState({
      isSign: isSign,
    });
  };
  onChangeNote = event => {
    const { value } = event.target;
    this.setState({
      value: value,
    });
  };
  saveSummary = () => {
    this.props.dispatch(
      gptAugmentationUpdate({
        GPTAugmentationId: this.props.augmentationNote.GPTAugmentationId,
        StudentId: this.props.StudentId,
        EditedAnswer: this.state.value,
        IsAgreed: true,
      }),
    );

    this.setState({
      notes: this.state.value,
      isEditMode: false,
      isAgreed: true,
    });
    this.props.dispatch(resetCallNotesAugment());
  };
  render() {
    const {
      AppointmentData,
      augmentationNote,
      TranscriptData,
      isFetching,
      isFetchingGetNote,
      AugmentedAIStatusData,
    } = this.props;
    const { appointmentNote } = this.state;
    const {
      IsSign,
      Note,
      IsEligibleForUnSign,
      SignedMessage,
      SupervisorSignedMessage,
      AppointmentNoteId,
    } = appointmentNote || {};
    let signedMsg = SignedMessage || SupervisorSignedMessage;
    let isAILicense =
      AugmentedAIStatusData &&
      (AugmentedAIStatusData.LicenseStatusId == 2 ||
        AugmentedAIStatusData.LicenseStatusId == 4 ||
        AugmentedAIStatusData.LicenseStatusId == 7);
    return (
      <div className="peer-recovery-notes mt-2">
        {/* <Loader2 loading={isFetchingGetNote} /> */}
        {IsSign ? (
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <h5
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '13px',
                    lineHeight: '16px',
                    color: '#b8b8b8',
                  }}
                >
                  NOTE
                </h5>
              </div>
              <p
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '22px',
                  marginBottom: '55px',
                }}
              >
                {Note}
              </p>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center">
                  {signedMsg && renderHTML(signedMsg)}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div class="d-flex"></div>
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <Tooltip placement="top" title="Un-sign">
                      <span class="delete-course" onClick={onClick}>
                        {IsEligibleForUnSign && (
                          <span
                            className="mt-2 mb-2 mr-2"
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              wordBreak: 'keep-all',
                            }}
                          >
                            Un-sign
                          </span>
                        )}
                      </span>
                    </Tooltip>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                          &times;
                        </button>
                        <h4 class="modal-title col-12">Are you sure you want to Un-sign?</h4>
                      </div>

                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          data-dismiss="modal"
                          onClick={onCancel}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn "
                          onClick={() => {
                            onOk();
                            this.unsignFormFun('AppointmentNote', AppointmentNoteId);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="note-lable">
                <span className="add">Add Note</span>
                <div>
                  <span style={{ color: '#24bd4c' }}>{this.state.showNoteSucess}</span>
                  <span
                    className="clear"
                    onClick={() => {
                      this.setState({
                        notes: '',
                      });
                    }}
                  >
                    Clear
                  </span>
                  <span
                    className="save"
                    onClick={() => {
                      this.saveNote(false);
                    }}
                  >
                    Save For Now
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12">
              <TextareaAutosize
                name="desc"
                placeholder="Add New Note"
                style={{
                  width: '100%',
                  border: '1px solid #AAACB5',
                  borderRadius: '4px',
                  overflow: 'hidden',
                  paddingLeft: '10px',
                  minHeight: 100,
                }}
                onChange={e => {
                  this.setState({ notes: e.target.value });
                }}
                value={this.state.notes}
              />
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                {isAILicense && (
                  <>
                    <Button
                      loading={isFetching}
                      title="Augment with AI"
                      className="ai-btn"
                      type="primary"
                      onClick={this.handleAugmentation}
                      style={{
                        display: 'inline',
                        padding: '5px 20px',
                      }}
                      disabled={!TranscriptData || this.state.isEditMode || augmentationNote}
                    >
                      <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                        <img src="images/AIStar.svg" alt="" width="23" />
                      </figure>
                      {this.state.isAgreed ? 'Regenerate Note' : 'Generate Note'}
                    </Button>
                  </>
                )}

                <div className="">
                  <div
                    className="dictation-space"
                    style={{ display: 'inline-flex', paddingRight: '10px' }}
                  >
                    <DictationComponent handleDictate={this.handleDictate} />
                  </div>
                  <button
                    type="primary"
                    className="btn"
                    onClick={() => {
                      this.saveNote(true);
                    }}
                    disabled={isFetchingGetNote}
                  >
                    Sign and Submit
                  </button>
                </div>
              </div>
            </div>
            {augmentationNote && (
              <div className="gpt-answers col-12">
                <div className="col-12">
                  <div className="augmented-note-box">
                    <span className="output-title">AI Output</span>
                    {this.state.isEditMode ? (
                      <div class="col-12">
                        <TextareaAutosize
                          style={{ height: '250px', width: '100%' }}
                          value={this.state.value}
                          id="question"
                          name="question"
                          rows="4"
                          placeholder="Enter Answer"
                          className="textarea-type"
                          onChange={this.onChangeNote}
                        />
                        <div
                          className="d-flex justify-content-end ai-action"
                          style={{ paddingRight: '0px' }}
                        >
                          <DictationComponent
                            handleDictate={event => {
                              this.handleAINoteDictate(event);
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-primary mx-2"
                            onClick={() => {
                              this.setState({
                                isEditMode: false,
                                value: augmentationNote.Answer,
                              });
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn"
                            disabled={this.state.value.trim().length < 1}
                            onClick={() => {
                              this.saveSummary();
                            }}
                          >
                            Save & Agree
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="augmented-note">
                          <span>{augmentationNote.Answer}</span>
                        </div>
                        <div className="ai-action">
                          <button
                            type="button"
                            class="btn"
                            style={{ background: '#00DC31' }}
                            onClick={() => {
                              this.setState({
                                notes: augmentationNote.Answer,
                                isAgreed: true,
                              });
                              this.props.dispatch(resetCallNotesAugment());
                            }}
                          >
                            <i class="fas fa-thumbs-up" style={{ paddingRight: 5 }}></i>
                            Agree
                          </button>
                          <button
                            type="button"
                            class="btn"
                            style={{
                              border: '1px solid #FF2C2C',
                              color: '#FF2C2C',
                              background: '#fff',
                            }}
                            onClick={() => {
                              this.setState({
                                isEditMode: true,
                                value: augmentationNote.Answer,
                              });
                            }}
                          >
                            <i class="fas fa-thumbs-down" style={{ paddingRight: 5 }}></i>
                            Disagree
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <ValidatePin
          validPinModalVisible={this.state.validPinModalVisible}
          resetValidPinModalVisible={this.resetValidPinModalVisible}
          offTheModal={this.props.offTheModal}
          FormType={this.state.FormType}
          FormId={this.state.FormId}
          IsRealSignature={this.state.IsRealSignature}
        />
        <SuggestCPTCode
          AppointmentId={AppointmentData.AppointmentId}
          StudentId={this.props.StudentId}
          IsServiceCodeApproved={this.props.IsServiceCodeApproved}
        />
      </div>
    );
  }
}
const mapStateToProps = ({
  call_note_augmentation,
  get_appointment_note,
  upsert_appointment_note,
  validate_staff_pin,
  unsign_form,
  user_feature_flag_get,
}) => {
  const { data: augmentationData, isFetching } = call_note_augmentation;
  const { data: appointmentData, isFetching: isFetchingGetNote } = get_appointment_note;
  var { data: validateDataPin } = validate_staff_pin;
  const { data: unsignForm } = unsign_form;
  const { data: fFData } = user_feature_flag_get;

  let augmentationNote = null;
  let appointmentNote = null;
  let upsertNoteSuccess = false;
  let genratedAppointmentNoteId = null;
  let validateStaffPinData = {};
  var unsignFormStatus = false;
  let AugmentedAIStatusData = null;

  if (augmentationData && augmentationData.result) {
    augmentationNote = augmentationData.result;
  }

  if (appointmentData && appointmentData[0]) {
    appointmentNote =
      (appointmentData[0].AppointmentNote && appointmentData[0].AppointmentNote[0]) || [];
  }
  if (upsert_appointment_note && upsert_appointment_note.data) {
    upsertNoteSuccess = upsert_appointment_note.data.success;
    genratedAppointmentNoteId = upsert_appointment_note.data.result;
  }
  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (unsignForm && unsignForm.success) {
    unsignFormStatus = unsignForm.success;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  return {
    augmentationNote,
    appointmentNote,
    upsertNoteSuccess,
    genratedAppointmentNoteId,
    validateStaffPinData,
    unsignFormStatus,
    isFetching,
    isFetchingGetNote,
    AugmentedAIStatusData,
  };
};
export default connect(mapStateToProps)(withRouter(AppointmentNotesAI));
