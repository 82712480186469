import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import CustomModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';

const newTextContent = (value, TaggedUser) => {
  let newTaggedUser = Array.isArray(TaggedUser) ? TaggedUser : JSON.parse(TaggedUser);
  let newVal = value;
  if (newTaggedUser) {
    newTaggedUser &&
      newTaggedUser.map(item => {
        newVal = newVal.replace(
          `@${item.UserGuid}@`,
          `<span style="color:white; background:#46b4fe;padding:2px;margin:0px 2px;font-size:18px;">${item.DisplayName}</span>`,
        );
      });
  } else {
    newVal = value;
  }

  let modifiedValue = () => {
    if (value.toLowerCase().startsWith('www')) {
      return 'http://' + `${value.toLowerCase()}`;
    } else {
      return value.toLowerCase();
    }
  };

  var tester = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

  let newdata = modifiedValue().replace(tester, function(url) {
    return '<a  href="' + url + '" target="_blank">' + url + '</a>';
  });
  return newVal;

  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
};

const Posts = props => {
  const { UserProfileImageUrl, Name, Post } = props && props.appSubscriberData;
  return (
    <div className="container-fluid p-3">
      {' '}
      <div className="row" style={{ background: '#fff', borderRadius: '12px', paddingTop: '10px' }}>
        {Post &&
          Post.map((item, index) => {
            const {
              DatePosted,
              TextContent,
              PostMedia,
              LikesCount,
              CommentsCount,
              PostComment,
              PostLike,
              TaggedUser,
            } = item;
            let result = newTextContent(TextContent, TaggedUser);
            return (
              <>
                {' '}
                <div class="col-12 mb-3">
                  <div className="posts-list-item">
                    <span>{DatePosted}</span>
                    <p className="post-text">{result ? renderHTML(result) : result}</p>
                    {PostMedia &&
                      PostMedia.map((item, index) => {
                        return <img src={item.MediaUrl} alt="No Image" />;
                      })}

                    <div className="modal-container">
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <input
                            type="button"
                            class="btn mr-2"
                            value={LikesCount + ' likes'}
                            onClick={LikesCount ? onClick : null}
                          />
                        )}
                        ModalContent={({ props, onCancel }) => (
                          <div class="modal-content p-2">
                            <div class="modal-header">
                              <div class="col-12">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <div style={{ display: 'flex' }}>
                                  <figure>
                                    <img
                                      style={{
                                        boxShadow: '0px 11px 6px #f2f2f2',
                                      }}
                                      src={
                                        UserProfileImageUrl ||
                                        `images/familyProgram/blank-profile.png`
                                      }
                                      class="rounded-circle "
                                      width="40"
                                      height="40"
                                      alt=""
                                    />
                                  </figure>
                                  <div style={{ marginLeft: '15px' }}>
                                    <span
                                      style={{
                                        fontWeight: '700',
                                        color: 'rgb(0, 0, 0)',
                                      }}
                                    >
                                      {Name}
                                    </span>
                                    <div className="stu-duration">
                                      {/* <span class="text-muted">1m ago </span> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="stu-duration" style={{ marginTop: '20px' }}>
                                  <span className="text-muted">
                                    <i class="far fa-thumbs-up"></i> {LikesCount + ' likes'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="" style={{ height: '350px', overflow: 'scroll' }}>
                                {PostLike &&
                                  PostLike.PostLike.map((likeitem, index) => {
                                    return (
                                      <>
                                        <div className="col-12">
                                          <div className="like-btn-modal-item">
                                            <figure>
                                              <img
                                                style={{
                                                  boxShadow: '0px 11px 6px #f2f2f2',
                                                }}
                                                src={
                                                  likeitem.ProfileImageUrl ||
                                                  `images/familyProgram/blank-profile.png`
                                                }
                                                class="rounded-circle "
                                                width="40"
                                                height="40"
                                                alt=""
                                              />
                                            </figure>
                                            <div style={{ flexGrow: '1' }}>
                                              <span className="frnd-name">
                                                {likeitem.DisplayName}
                                              </span>
                                              <span className="frnd-badge guide">
                                                {likeitem.Badge}
                                              </span>
                                            </div>
                                            <span class="text-muted">
                                              {likeitem.InsertedDateTime}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        )}
                      />

                      <CustomModal
                        modalWidth={800}
                        ModalButton={({ onClick }) => (
                          <input
                            type="button"
                            class="btn  mr-2"
                            value={CommentsCount + ' comments'}
                            onClick={CommentsCount ? onClick : null}
                            //commented because data not coming
                          />
                        )}
                        ModalContent={({ props, onCancel }) => (
                          <div class="modal-content p-2">
                            <div class="modal-header">
                              <div class="col-12">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <div style={{ display: 'flex' }}>
                                  <figure>
                                    <img
                                      style={{
                                        boxShadow: '0px 11px 6px #f2f2f2',
                                      }}
                                      src={
                                        UserProfileImageUrl ||
                                        `images/familyProgram/blank-profile.png`
                                      }
                                      class="rounded-circle "
                                      width="40"
                                      height="40"
                                      alt=""
                                    />
                                  </figure>
                                  <div style={{ marginLeft: '15px' }}>
                                    <span
                                      style={{
                                        fontWeight: '700',
                                        color: 'rgb(0, 0, 0)',
                                      }}
                                    >
                                      {Name}
                                    </span>
                                    <div className="stu-duration">
                                      {/* <span class="text-muted">1m ago </span> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="stu-duration" style={{ marginTop: '20px' }}>
                                  <span className="text-muted">
                                    <i class="far fa-comment"></i> {CommentsCount} comments
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="modal-body">
                              <div className="" style={{ height: '380px', overflow: 'scroll' }}>
                                {PostComment &&
                                  PostComment.PostComment.map((item, index) => {
                                    let { PostCommentChild } = item.PostCommentChild || {};
                                    return (
                                      <>
                                        <div className="col-12">
                                          <div className="comment-btn-modal-item">
                                            <figure className="flex-shrink-0">
                                              <img
                                                style={{
                                                  boxShadow: '0px 11px 6px #f2f2f2',
                                                }}
                                                src={
                                                  item.ProfileImageUrl ||
                                                  `images/familyProgram/blank-profile.png`
                                                }
                                                class="rounded-circle "
                                                width="40"
                                                height="40"
                                                alt=""
                                              />
                                            </figure>
                                            <div style={{ flexGrow: '1' }}>
                                              <span className="frnd-name">{item.DisplayName}</span>

                                              <span className="frnd-badge guide">{item.Badge}</span>
                                              <div className="stu-duration">
                                                <span
                                                  style={{ marginLeft: '10px' }}
                                                  className="text-justify"
                                                >
                                                  {item.Comment}
                                                </span>
                                              </div>
                                            </div>
                                            <span class="text-muted flex-shrink-0">
                                              {item.InsertedDateTime}
                                            </span>
                                          </div>
                                          <div className="pl-5">
                                            {PostCommentChild &&
                                              PostCommentChild.length > 0 &&
                                              PostCommentChild.map((childItem, index) => {
                                                return (
                                                  <div className="comment-btn-modal-item">
                                                    <figure className="flex-shrink-0">
                                                      <img
                                                        style={{
                                                          boxShadow: '0px 11px 6px #f2f2f2',
                                                        }}
                                                        src={
                                                          childItem.ProfileImageUrl ||
                                                          `images/familyProgram/blank-profile.png`
                                                        }
                                                        class="rounded-circle "
                                                        width="40"
                                                        height="40"
                                                        alt=""
                                                      />
                                                    </figure>
                                                    <div style={{ flexGrow: '1' }}>
                                                      <span className="frnd-name">
                                                        {childItem.DisplayName}
                                                      </span>

                                                      <span className="frnd-badge guide">
                                                        {childItem.Badge}
                                                      </span>
                                                      <div className="stu-duration">
                                                        <span
                                                          style={{
                                                            marginLeft: '10px',
                                                          }}
                                                          className="text-justify"
                                                        >
                                                          {childItem.Comment}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <span class="text-muted flex-shrink-0">
                                                      {childItem.InsertedDateTime}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default Posts;
