import React, { Component } from 'react';
import { Radio, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ReduxInput, ReduxSelect } from '../component/custom-redux-components';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import CustomModal from '../core/modal';

import {
  getStripeApiKey,
  listCombos,
  getPaymentInfo,
  resetPaymentMethode,
  updatePaymentMethode,
  adminstrationGetCenter,
} from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
const Validator = {
  required: value => (value ? undefined : 'Field Required'),
  accountName: value =>
    value && !/^.{1,50}$/.test(value) ? ' cannot exceed 50 characters' : undefined,
  routingNumber: value =>
    value && !/^[0-9]{9}$/.test(value) ? ' is a 9 digit numeric Value' : undefined,
  accountNumber: value =>
    value && !/^[0-9]{4,20}$/.test(value)
      ? ' has a minimum of 4 and a maximum of 20 digits'
      : undefined,
  email: value => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Address Invalid ';
      } else {
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  confirmEmail: (value, values) => {
    if (value) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid Email Address';
      } else {
        if (value != values.email) {
          return 'Email and Confirm Email must be same';
        }
        return undefined;
      }
    } else {
      return 'Field Required';
    }
  },
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? ' numeric values only' : undefined,
};
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class UpdatePayment extends Component {
  constructor(props) {
    super(props);
    const { checked } = props;
    this.state = {
      checked: checked || '',
      CreditDebitCard: true,
      Checking: false,
      value: 'Credit/Debit',
      errorMessage: '',
      isEditPaymentMode: true,
    };
  }
  getCenterBilling = CenterId => {
    if (CenterId > 0) {
      this.props.dispatch(adminstrationGetCenter({ CenterId }));
    }
  };
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'StateProvince',
      }),
    );
    if (this.props.defaultCenter) {
      this.getCenterBilling(this.props.defaultCenter);
    }
  }

  componentWillReceiveProps({
    isUpdate,
    updatePaymentResult,
    paymentInfo,
    countryISO,
    defaultCenter,
  }) {
    if (defaultCenter && defaultCenter != this.props.defaultCenter) {
      this.getCenterBilling(defaultCenter);
    }
    if (isUpdate && isUpdate != this.props.isUpdate) {
      this.setState({
        isUpdateModal: isUpdate,
        successMessage: updatePaymentResult,
      });
      this.props.dispatch(resetPaymentMethode());
      this.props.dispatch(getPaymentInfo({}));
    }
    if (countryISO && countryISO != this.props.countryISO) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
    if (JSON.stringify(paymentInfo) != JSON.stringify(this.props.paymentInfo)) {
      this.setState({
        isEditPaymentMode: false,
      });
    }
  }
  onToggle = () => {
    this.setState({
      isEditPaymentMode: !this.state.isEditPaymentMode,
    });
  };

  handleChange = VALUES => {
    const { error } = VALUES;
    if (error) {
      this.setState({ errorMessage: error.message });
    } else {
      this.setState({ errorMessage: '' });
    }
  };
  handleSubmit = async evt => {
    const { stripe, elements, handleResult, paymentInfo } = this.props;
    const { isEditPaymentMode } = this.state;
    let paymentResponse = {};
    if (!paymentInfo || (paymentInfo && isEditPaymentMode)) {
      if (stripe) {
        let number = elements.getElement(CardNumberElement);
        let cvc = elements.getElement(CardCvcElement);
        let exp = elements.getElement(CardExpiryElement);
        if (this.state.value === 'Credit/Debit') {
          let creditRes = await stripe.createPaymentMethod({
            type: 'card',
            card: number,
            billing_details: {
              name: evt.billingName,
              email: evt.billingEmail,
              address: {
                city: evt.city,
                line1: evt.billingAddress,
                postal_code: evt.postalCode,
                state: evt.billingState,
                // country: evt.billingCountry,
              },
            },
          });
          handleResult(creditRes, evt, 'card', isEditPaymentMode);
        }
        if (this.state.value === 'Checking') {
          let BankRes = await stripe.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: evt.routingNumber,
            account_number: evt.accountNumber,
            account_holder_name: evt.accountName,
            account_holder_type: evt.accountType,
          });
          handleResult(BankRes, evt, 'ach', isEditPaymentMode);
        }
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    } else {
      handleResult(paymentResponse, evt, 'same', isEditPaymentMode);
    }
  };

  onChange = e => this.setState({ value: e.target.value });
  resetState = () => this.setState({ checked: '' });

  error = msg => {
    Modal.error({
      content: msg,
    });
  };

  render() {
    const {
      LicenseCounts,
      Total,
      salesTax,
      handleSubmit,
      billingDetails,
      submitting,
      StateProvince,
      isFetching,
      updatePaymentResult,
      isUpdate,
      paymentInfo,
      Country,
    } = this.props;
    const { isEditPaymentMode } = this.state;
    // const { StateLicenseData } = LicenseCounts;
    return (
      <div class="main">
        <Loader loading={isFetching} />
        <div class="admin-maintain">
          <div class="container-fluid">
            <div class="row top-table-search mt-5 mb-4 align-items-center">
              <div class="col-12">
                <a
                  class="back-link"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <img src="images/back-arrow.svg" /> Back
                </a>
                <h3 class="page-title mb-3 mb-md-0">Payment Method</h3>
              </div>
            </div>
            <div class="payment-dashboard">
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                <div class="row">
                  <div class="col-md-12 col-lg-7 col-xl-8">
                    <div class="payment-dashboard-detail enrollment-form">
                      <div class="payment-label" style={{ textAlign: 'left' }}>
                        Billing Address
                      </div>
                      <div class="row">
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingName"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              className="form-control"
                              fieldName="Name"
                            />
                            <label class="placeholder-label">Name*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingEmail"
                              type="email"
                              component={ReduxInput}
                              validate={[Validator.required, Validator.email]}
                              placeholder="someone@domain.com"
                              className="form-control"
                              fieldName="Email"
                            />
                            <label class="placeholder-label">Email*</label>
                          </div>
                        </div>
                        <div class="col-12  col-lg-12 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingAddress"
                              type="text"
                              component={ReduxInput}
                              validate={Validator.required}
                              // placeholder="Address"
                              className="form-control"
                              fieldName=" Address"
                            />
                            <label class="placeholder-label">Billing Address*</label>
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="city"
                              type="text"
                              errorMessage=""
                              component={ReduxInput}
                              validate={Validator.required}
                              // placeholder="City"
                              className="form-control"
                              fieldName="City"
                            />
                            <label class="placeholder-label">City*</label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="billingCountry"
                              type="text"
                              errorMessage="Country Required"
                              component={ReduxSelect}
                              // options={Country}
                              options={[
                                { Value: 'USA', Text: 'United States of America' },
                                { Value: 'CAN', Text: 'Canada' },
                              ]}
                              validate={Validator.required}
                              placeholder="Country*"
                              fieldName={'Country'}
                            />
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group combo-dropdown">
                            <Field
                              name={`billingState`}
                              type="text"
                              placeholder={`State/Province/Territories*`}
                              component={ReduxSelect}
                              validate={Validator.required}
                              options={StateProvince}
                            />
                            {/* <label class="placeholder-label">City</label> */}
                          </div>
                        </div>
                        <div class="col-12  col-md-6 pb-lg-2">
                          <div class="form-group">
                            <Field
                              name="postalCode"
                              type="text"
                              component={ReduxInput}
                              validate={[Validator.required]}
                              // placeholder="Postal Code"
                              className="form-control"
                              fieldName="Postal Code"
                            />
                            <label class="placeholder-label">Postal Code*</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isEditPaymentMode ? (
                      <div class="payment-dashboard-detail enrollment-form">
                        <div className="d-flex justify-content-between">
                          <div class="payment-label">Payment Details</div>
                          {paymentInfo && (
                            <div>
                              <button
                                type="button"
                                class="btn btn-sm black-btn mr-2"
                                onClick={this.onToggle}
                              >
                                Back To Saved Payment Method
                              </button>
                            </div>
                          )}
                        </div>
                        <div class="credit-card-detail">
                          <div class="nav nav-tabs  border-0  font-weight-bold" role="tablist">
                            <Radio.Group
                              options={['Credit/Debit', 'Checking']}
                              // options={['CreditDebit']}
                              onChange={this.onChange}
                              value={this.state.value}
                            />
                          </div>
                        </div>
                        {this.state.value === 'Credit/Debit' ? (
                          <div class="tab-pane fade show active" id="card-tab">
                            <div class="row ">
                              <div class="col-12">
                                <div class="row ">
                                  <div class="col-6  pb-lg-3">
                                    <div class="form-group">
                                      <label class="col-12">
                                        Card Number
                                        <CardNumberElement
                                          className="form-control"
                                          placeholder="XXXX XXXX XXXX XXXX"
                                          {...createOptions()}
                                          onChange={this.handleChange}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-3 pb-lg-3">
                                    <div class="form-group">
                                      <label class="col-12">
                                        {' '}
                                        Exp date
                                        <CardExpiryElement
                                          className="form-control"
                                          {...createOptions()}
                                          onChange={this.handleChange}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-3 pb-lg-3">
                                    <div class="form-group">
                                      <label class="col-12">
                                        CVC
                                        <CardCvcElement
                                          className="form-control"
                                          placeholder="123"
                                          {...createOptions()}
                                          onChange={this.handleChange}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="error" role="alert">
                                    {this.state.errorMessage}
                                  </div>
                                  <div>
                                    {!isUpdate &&
                                      updatePaymentResult &&
                                      updatePaymentResult != 'SUCCESS' && (
                                        <span className="text-danger">{updatePaymentResult}</span>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="tab-pane" id="checking-tab">
                            <div class="row">
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group call-schedule">
                                  <Field
                                    name="accountType"
                                    type="text"
                                    placeholder="Account Type"
                                    component={ReduxSelect}
                                    validate={Validator.required}
                                    // className="form-control"
                                    options={[
                                      { Value: 'Company', Text: 'Company' },
                                      { Value: 'Individual', Text: 'Individual' },
                                    ]}
                                  />
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="bankName"
                                    type="text"
                                    component={ReduxInput}
                                    validate={Validator.required}
                                    className="form-control"
                                    fieldName="Bank Name"
                                  />
                                  <label class="placeholder-label">Bank Name*</label>
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="accountName"
                                    type="text"
                                    component={ReduxInput}
                                    validate={[Validator.accountName, Validator.required]}
                                    className="form-control"
                                    fieldName="Account Name "
                                  />
                                  <label class="placeholder-label">Account Name*</label>
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="routingNumber"
                                    type="text"
                                    component={ReduxInput}
                                    validate={[Validator.routingNumber, Validator.required]}
                                    className="form-control"
                                    fieldName="Routing Number"
                                  />
                                  <label class="placeholder-label">Routing Number*</label>
                                </div>
                              </div>
                              <div class="col-12  col-md-6 pb-lg-3">
                                <div class="form-group">
                                  <Field
                                    name="accountNumber"
                                    type="text"
                                    component={ReduxInput}
                                    validate={[Validator.accountNumber, Validator.required]}
                                    className="form-control"
                                    fieldName="Account Number"
                                  />
                                  <label class="placeholder-label">Account Number*</label>
                                </div>
                              </div>
                            </div>
                            <div>
                              {!isUpdate &&
                                updatePaymentResult &&
                                updatePaymentResult != 'SUCCESS' && (
                                  <span className="text-danger">{updatePaymentResult}</span>
                                )
                              // this.error(updatePaymentResult)
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div class="payment-dashboard-detail enrollment-form">
                        <div className="d-flex justify-content-between">
                          <div class="payment-label">Payment Details</div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-sm black-btn mr-2"
                              onClick={this.onToggle}
                            >
                              Change Payment Method
                            </button>
                          </div>
                        </div>

                        {paymentInfo && paymentInfo.paymentType == 'card' && (
                          <div
                            className=" w-50 p-2 d-flex justify-content-between align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div className="">
                              <img
                                src={`images/brand/${paymentInfo.paymentMethod.card.brand}.png`}
                                alt={paymentInfo.paymentMethod.card.brand}
                                style={{ height: '50px' }}
                              />
                            </div>

                            <div
                              className="d-flex flex-column align-items-end"
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                                textTransform: 'uppercase',
                              }}
                            >
                              <span>**** **** **** {paymentInfo.paymentMethod.card.last4}</span>
                              <span>{paymentInfo.paymentMethod.card.brand}</span>
                            </div>
                          </div>
                        )}
                        {paymentInfo && paymentInfo.paymentType == 'card_default_source' && (
                          <div
                            className=" w-50 p-2 d-flex justify-content-between align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div className="">
                              <img
                                src={`images/brand/${paymentInfo.paymentMethod.brand}.png`}
                                alt={paymentInfo.paymentMethod.brand}
                                style={{ height: '50px' }}
                              />
                            </div>

                            <div
                              className="d-flex flex-column align-items-end"
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                                textTransform: 'uppercase',
                              }}
                            >
                              <span>**** **** **** {paymentInfo.paymentMethod.last4}</span>
                              <span>{paymentInfo.paymentMethod.brand}</span>
                            </div>
                          </div>
                        )}

                        {paymentInfo && paymentInfo.paymentType == 'ach' && (
                          <div
                            className=" w-75 p-2 d-flex  align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: '45px',
                                width: '80px',
                                backgroundColor: '#831f65',
                                color: '#ffffff',
                                marginRight: '20px',
                              }}
                            >
                              <span> ACH</span>
                            </div>
                            <div
                              className="d-flex flex-column "
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                              }}
                            >
                              <span>Account Holder : {paymentInfo.holderName}</span>
                              <span>Account ending with *{paymentInfo.last4}</span>
                              <span style={{ textTransform: 'uppercase' }}>
                                {paymentInfo.bankName}
                              </span>
                            </div>
                          </div>
                        )}
                        {paymentInfo && paymentInfo.paymentType == 'bank_account_default_source' && (
                          <div
                            className=" w-75 p-2 d-flex  align-items-center"
                            style={{ boxShadow: '0px 1px 5px #f1f1f1d1' }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: '45px',
                                width: '80px',
                                backgroundColor: '#831f65',
                                color: '#ffffff',
                                marginRight: '20px',
                              }}
                            >
                              <span> ACH</span>
                            </div>
                            <div
                              className="d-flex flex-column "
                              style={{
                                fontWeight: '600',
                                color: 'gray',
                              }}
                            >
                              <span>Account Holder : {paymentInfo.holderName}</span>
                              <span>Account ending with *{paymentInfo.last4}</span>
                              <span style={{ textTransform: 'uppercase' }}>
                                {paymentInfo.bankName}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div class="col-md-12 col-lg-5 col-xl-4">
                    <div class="payment-dashboard-detail order-summary enrollment-form">
                      <div class="text-center submitbtn">
                        <button
                          type="submit"
                          class="btn submitbtn "
                          name="pay"
                          disabled={!this.state.isEditPaymentMode}
                        >
                          Update Payment Method
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isUpdateModal}
          header={this.state.successMessage}
          yesAction={() => {
            this.setState({
              isUpdateModal: false,
            });
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    get_payment_info,
    get_stripe_api_key,
    combos,
    update_payment_methode,
    default_values,
    center_get,
  } = state;
  const billingDetails = selector(
    state,
    'billingName',
    'billingEmail',
    'billingAddress',
    'postalCode',
    'city',
    'billingState',
    'billingCountry',
  );
  let countryISO = selector(state, 'billingCountry');

  let STRIPE_KEY = '';
  let isUpdate = false;
  let updatePaymentResult = '';
  let editStateProvince = [];
  let defaultCenter = null;
  let centerData = {};
  const { data, isFetching } = get_stripe_api_key;
  const { data: paymentInfoData, isFetching: isFetchingInfo } = get_payment_info;
  let paymentInfo;
  let billingDetail = {};
  if (get_stripe_api_key.data) {
    STRIPE_KEY = data.stripeKey;
  }
  if (default_values && default_values.data) {
    defaultCenter = default_values.data.centerId;
  }
  if (center_get && center_get.data) {
    centerData = center_get.data;
  }
  if (update_payment_methode.data && update_payment_methode.data) {
    isUpdate = update_payment_methode.data.success;
    updatePaymentResult = update_payment_methode.data.result;
  }
  if (paymentInfoData && paymentInfoData.result) {
    if (paymentInfoData.result.paymentType == 'card') {
      paymentInfo = {
        paymentMethod: JSON.parse(paymentInfoData.result.paymentMethod),
        paymentType: paymentInfoData.result.paymentType,
      };
      billingDetail = {
        billingName: paymentInfo.paymentMethod.billing_details.name,
        billingEmail: paymentInfo.paymentMethod.billing_details.email,
        billingAddress: paymentInfo.paymentMethod.billing_details.address.line1,
        postalCode: paymentInfo.paymentMethod.billing_details.address.postal_code,
        city: paymentInfo.paymentMethod.billing_details.address.city,
        billingState: paymentInfo.paymentMethod.billing_details.address.state,
        billingCountry: paymentInfo.paymentMethod.billing_details.address.country || 'USA',
      };
    } else if (paymentInfoData.result.paymentType == 'card_default_source') {
      paymentInfo = {
        paymentMethod: paymentInfoData.result.paymentMethod,
        paymentType: paymentInfoData.result.paymentType,
      };
      billingDetail = {
        billingName: paymentInfoData.result.paymentMethod.name || centerData.BillingName,
        billingEmail: centerData.Email || localStorage.getItem('email'),
        billingAddress: paymentInfoData.result.paymentMethod.address_line1,
        postalCode: paymentInfoData.result.paymentMethod.address_zip,
        city: paymentInfoData.result.paymentMethod.address_city,
        billingState: paymentInfoData.result.paymentMethod.address_state,
        billingCountry: paymentInfoData.result.paymentMethod.address_country || 'USA',
      };
    } else if (paymentInfoData.result.paymentType == 'bank_account_default_source') {
      paymentInfo = {
        paymentType: paymentInfoData.result.paymentType,
        holderName: paymentInfoData.result.paymentMethod.account_holder_name,
        bankName: paymentInfoData.result.paymentMethod.bank_name,
        last4: paymentInfoData.result.paymentMethod.last4,
      };
      billingDetail = {
        billingName: centerData.BillingName || localStorage.getItem('userName'),
        billingEmail: centerData.Email || localStorage.getItem('email'),
        billingAddress: centerData.BillingStreet,
        postalCode: centerData.BillingZip,
        city: centerData.BillingCity,
        billingState: centerData.BillingState,
        billingCountry: 'USA',
      };
    } else if (paymentInfoData.result.paymentType == 'ach') {
      paymentInfo = {
        paymentType: paymentInfoData.result.paymentType,
        holderName: paymentInfoData.result.paymentMethod.account_holder_name,
        bankName: paymentInfoData.result.paymentMethod.bank_name,
        last4: paymentInfoData.result.paymentMethod.last4,
      };
      billingDetail = {
        billingName: centerData.BillingName || localStorage.getItem('userName'),
        billingEmail: centerData.Email || localStorage.getItem('email'),
        billingAddress: centerData.BillingStreet,
        postalCode: centerData.BillingZip,
        city: centerData.BillingCity,
        billingState: centerData.BillingState,
        billingCountry: 'USA',
      };
    } else {
      paymentInfo = {
        paymentType: paymentInfoData.result.paymentType,
        holderName: paymentInfoData.result.paymentMethod.account_holder_name,
        bankName: paymentInfoData.result.paymentMethod.bank_name,
        last4: paymentInfoData.result.paymentMethod.last4,
      };
      billingDetail = {
        billingName: centerData.BillingName || localStorage.getItem('userName'),
        billingEmail: centerData.Email || localStorage.getItem('email'),
        billingAddress: centerData.BillingStreet,
        postalCode: centerData.BillingZip,
        city: centerData.BillingCity,
        billingState: centerData.BillingState,
        billingCountry: 'USA',
      };
    }
  } else {
    billingDetail = {
      billingName: centerData.BillingName || localStorage.getItem('userName'),
      billingEmail: centerData.Email || localStorage.getItem('email'),
      billingAddress: centerData.BillingStreet,
      postalCode: centerData.BillingZip,
      city: centerData.BillingCity,
      billingState: centerData.BillingState,
      billingCountry: 'USA',
    };
  }

  if (!combos.data) {
    combos.data = {
      StateProvince: [],
      Country: [],
    };
  }
  if (combos.data.StateProvince && combos.data.StateProvince.length > 0) {
    editStateProvince = combos.data.StateProvince.map(item => {
      return {
        Value: item.Text,
        Text: item.Text,
      };
    });
  }
  return {
    isUpdate,
    updatePaymentResult,
    isFetching: isFetching || update_payment_methode.isFetching || isFetchingInfo,
    defaultCenter,

    billingDetails,
    STRIPE_KEY,
    initialValues: {
      accountType: 'Company',
      billingCountry: 'USA',
      ...billingDetail,
    },
    StateProvince: editStateProvince,
    Country: combos.data.Country,
    countryISO,
    paymentInfo,
  };
};

UpdatePayment = reduxForm({
  form: 'completePurchageForm',
  enableReinitialize: true,
})(withRouter(UpdatePayment));

const selector = formValueSelector('completePurchageForm');

UpdatePayment = connect(mapStateToProps)(UpdatePayment);

class DefaultPayment extends Component {
  constructor(props) {
    super(props);
    props.dispatch(getStripeApiKey({}));
    props.dispatch(getPaymentInfo({}));
  }
  handleResult = (response, values, type, isEditPaymentMode) => {
    const { Total, salesTax, dispatch, LicenseCounts, paymentInfo } = this.props;
    if (paymentInfo || !response.error) {
      if (paymentInfo && !isEditPaymentMode) {
        response.billingDetails = {
          billingName: values.billingName,
          billingEmail: values.billingEmail,
          billingAddress: values.billingAddress,
          billingPostalCode: values.postalCode,
          billingCity: values.city,
          billingState: values.billingState,
          billingCountry: values.billingCountry,
        };

        // response.orderSummary = {
        //   salesTax,
        //   subTotal: Total,
        //   TotalAmt: (Total + (Total * salesTax) / 100).toFixed(2),
        //   Licenses: LicenseCounts.StateLicenseData,
        // };
        response.paymentType = type;
        response.paymentMethod = null;
        // dispatch(addLicenses({ json: response.token.id }));
        dispatch(updatePaymentMethode({ json: JSON.stringify(response) }));
      } else {
        response.billingDetails = {
          billingName: values.billingName,
          billingEmail: values.billingEmail,
          billingAddress: values.billingAddress,
          billingPostalCode: values.postalCode,
          billingCity: values.city,
          billingState: values.billingState,
          billingCountry: values.billingCountry,
        };

        // response.orderSummary = {
        //   salesTax,
        //   subTotal: Total,
        //   TotalAmt: (Total + (Total * salesTax) / 100).toFixed(2),
        //   Licenses: LicenseCounts.StateLicenseData,
        // };
        response.paymentType = type;
        dispatch(updatePaymentMethode({ json: JSON.stringify(response) }));
      }
    } else {
      Modal.error({
        title: response.error.code,
        content: response.error.message,
      });
    }
    // alert(JSON.stringify(response));
  };
  componentDidMount() {
    this.props.dispatch(getStripeApiKey({}));
  }
  render() {
    const { STRIPE_KEY } = this.props;
    const stripePromise = STRIPE_KEY && loadStripe(STRIPE_KEY);
    return (
      stripePromise && (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <UpdatePayment handleResult={this.handleResult} stripe={stripe} elements={elements} />
            )}
          </ElementsConsumer>
        </Elements>
      )
    );
  }
}

export default connect(mapStateToProps)(DefaultPayment);
