import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomError from '../component/CustomError';
import {
  login,
  logDevice,
  loadMenu,
  resetLogin,
  twoStepAuth,
  validatePasscode,
} from '../store/actions';
import CustomModal from '../Components/CustomModal';
import AntdModal from '../Components/CustomModal';
import { browserName, deviceDetect } from 'react-device-detect';
import moment from 'moment';
import Loader from '../Components/Loader';
import { Input } from 'antd';
import {
  loadDefaultValues,
  getLoadUserInfo,
  getValidateImage,
  resetLoadUserInfo,
  resetGetValidateImage,
  userFeatureFlagGet,
} from '../store/actions';
import Webcam from 'react-webcam';
import { LIST_ROUTES } from '../store/menuConfig';
import hostGrabber from "../util/hostGrabber";
import PageTitle from '../Components/PageTitle';

const { TextArea } = Input;

class Login extends PureComponent {
  constructor(props) {
    super(props);
    var d = new Date();
    this.state = {
      username: '',
      password: '',
      errorMessage: '',
      modal: '',
      content: '',
      year: d.getFullYear(),
      modalVisible: false,
      passcode: '',
      error: false,
      passwordType: 'password',
    };
    this.LoginButtonRef = React.createRef();
    localStorage.clear();
    console.log('in Login constructor');
  }

  componentDidCatch(error) {
    this.setState({
      error: true,
      jsError: 'An error has occurred. Please try again later', //error,
    });
  }
  resetError() {
    this.setState({
      error: false,
      jsError: ' ', //error,
    });
  }

  componentDidMount() {
    try {
      localStorage.clear();
      const pulledLatest = sessionStorage.getItem('pulledLatest');
      // console.log('did mount', pulledLatest);
      if (!Boolean(pulledLatest)) {
        // console.log('pulling latest');
        sessionStorage.setItem('pulledLatest', 'true');
        window.location.reload();
        // console.log('pulled latest');
      }
      this.LoginButtonRef && this.LoginButtonRef.current && this.LoginButtonRef.current.focus();
    } catch (e) {
      // console.log('Error in did mount ', e);
    }
  }

  loginSuccess = () => {
    try {
      // console.log('login success');
      const { access_token, refresh_token, staffFirstLogin } = this.props;
      var data = deviceDetect();
      data.timeZoneOffSet = new Date().getTimezoneOffset();
      data.language = window.navigator.userLanguage || window.navigator.language;
      data.token = access_token;
      data.refreshToken = refresh_token;
      if (!this.props.DefaultData) {
        // console.log('!this.props.DefaultData called', this.props, this.state);
        this.props.dispatch(logDevice(data));
        this.props.dispatch(loadDefaultValues({}));
      }
      // this.props.history.push('/dashboard/inHouse');
    } catch (e) {
      // console.log('error in login success ', e);
    }
  };

  componentWillMount() {
    this.removeWebCam();
    this.props.dispatch(resetLoadUserInfo());
  }
  removeWebCam = () => {
    if (this.webcam) {
      this.webcam.stream.getVideoTracks().map(track => track.stop());
    }
  };
  // getCourseId() {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   var courseId = param.get('courseId') || param.get('courseid');
  //   if (courseId) {
  //     courseId = parseInt(courseId);
  //   } else {
  //     courseId = -1;
  //   }
  //   return courseId;
  // }
  loadDefaultDashboard = DefaultData => {
    try {
      const { defaultDashboard, centerId, userId } = DefaultData;

      // let param = new URLSearchParams(this.props.history.location.search);
      // let redirectPath = param.get('redirectPath');

      let redirectPath = this.props.history.location.search.replace('?redirectPath=', '');
      let dashboard = '';
      if (redirectPath && redirectPath != 'undefined') {
        dashboard = redirectPath;
      } else {
        dashboard = defaultDashboard;
      }
      this.props.history.push(`${dashboard}`);
    } catch (e) {
      // console.log('error in load default dashboard ', e);
    }
  };
  componentWillReceiveProps(nextProps) {
    try {
      const {
        isSuccess,
        errorMessage,
        access_token,
        expires_in,
        refresh_token,
        isFacialAuthenticated,
        isFacialAuthenticationFailed,
        authenticationType,
        staffFirstLogin,
        showTrialPopup,
        isPasscodevarify,
        isPasscodeRequired,
        isTwoStepAPICallFinished,
        counselorId,
        DefaultData,
        authRoute,
        menuJson,
        hasClinicalAccess,
        userId,
        isStaffMobileRegistered,
        isSpecialSuperAdmin,
        // featureFlagData,
      } = nextProps;
      sessionStorage.setItem('firstLogin', staffFirstLogin);
      sessionStorage.setItem('greetingModal', showTrialPopup);
      sessionStorage.setItem('counselor', counselorId);
      if (DefaultData && JSON.stringify(DefaultData) != JSON.stringify(this.props.DefaultData)) {
        sessionStorage.setItem('defaultCenter', JSON.stringify(DefaultData.defaultDashboard));
        this.loadDefaultDashboard(DefaultData);
      }
      if (isSuccess && isSuccess != this.props.isSuccess) {
        var token = {
          expires_in: expires_in,
          access_token: access_token,
          refresh_token: refresh_token,
          created: Date.now(),
          date_exp: Date.now() + expires_in * 1000,
        };
        localStorage.setItem('dr:token', JSON.stringify(token));
        if (!this.props.UserData) {
          // console.log('getLoadUserInfo called line 169', this.props, this.state);
          this.props.dispatch(getLoadUserInfo({}));
        }
        this.props.dispatch(userFeatureFlagGet({}));
        //this.props.history.push('/dashboard');
        // this.setState(
        //   {
        //     modal: true,
        //     content: (
        //       <div>
        //         You are about to authorize a secure system
        //         <br /> Are you sure you want to proceed?
        //         <br />
        //         <br />
        //         <TextArea
        //           placeholder="You are accessing an authorised system"
        //           autosize={{ minRows: 4, maxRows: 6 }}
        //           style={{ width: '100%' }}
        //         />
        //         {/* <p
        //         className="text-center mt-3"
        //         style={{
        //           fontSize: '16px',
        //           wordWrap: 'break-word',
        //           fontWeight: 'normal',
        //         }}
        //       >
        //         You are accessing an authorised system
        //       </p> */}
        //       </div>
        //     ),
        //   },
        //   () => {
        //     setTimeout(() => {
        //       this.button && this.button.focus();
        //     }, 1);
        //   },
        // );
      }
      if (isFacialAuthenticated && isFacialAuthenticated != this.props.isFacialAuthenticated) {
        this.removeWebCam();
        this.setState(
          {
            modalVisible: false,
          },
          () => {
            this.loginSuccess();
          },
        );
      }
      const me = this;
      if (authenticationType != this.props.authenticationType) {
        localStorage.setItem('authRoute', authRoute);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userName', menuJson.UserName);
        localStorage.setItem('userRole', menuJson.UserRole);
        localStorage.setItem('centerName', menuJson.CenterName);
        localStorage.setItem('dr:menu', JSON.stringify(menuJson.data));
        localStorage.setItem('profileImgUrl', menuJson.ProfileImgUrl);
        localStorage.setItem('hasClinicalAccess', hasClinicalAccess);
        localStorage.setItem('email', menuJson.Email);
        localStorage.setItem('adminUserInfo', JSON.stringify(menuJson.AdminUserInfo));
        localStorage.setItem('isStaffMobileRegistered', JSON.stringify(isStaffMobileRegistered));
        localStorage.setItem('isSpecialSuperAdmin', JSON.stringify(isSpecialSuperAdmin));
      }
      if (authenticationType === 13002 && authenticationType != this.props.authenticationType) {
        navigator.getUserMedia =
          navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        if (navigator.getUserMedia) {
          navigator.getUserMedia(
            { video: true },
            function() {
              me.setState(
                {
                  modalVisible: true,
                },
                () =>
                  setTimeout(e => {
                    me.props.dispatch(getValidateImage({ imageSrc: me.webcam.getScreenshot() }));
                  }, 3000),
              );
            },
            function() {
              me.loginSuccess();
              // webcam is not available
            },
          );
        }
      } else if (
        isSuccess &&
        authenticationType === 13001 &&
        authenticationType != this.props.authenticationType
      ) {
        this.loginSuccess();
      } else if (
        (authenticationType === 13005 || authenticationType === 13003) &&
        authenticationType != this.props.authenticationType
      ) {
        this.props.dispatch(twoStepAuth({}));
      }
      if (
        (authenticationType === 13005 || authenticationType === 13003) &&
        isSuccess &&
        isTwoStepAPICallFinished &&
        !isPasscodeRequired
      ) {
        this.loginSuccess();
      }
      if (isSuccess && isPasscodevarify) {
        this.loginSuccess();
      }
      if (errorMessage && errorMessage != this.props.errorMessage) {
        this.removeWebCam();
        this.setState({ errorMessage: errorMessage, modalVisible: false });
        if (isFacialAuthenticationFailed) {
          this.props.dispatch(resetLoadUserInfo());
        } else {
          var data = deviceDetect();
          data.timeZoneOffSet = new Date().getTimezoneOffset();
          data.language = window.navigator.userLanguage || window.navigator.language;

          this.props.dispatch(logDevice(data));
        }
        localStorage.clear();
      }
    } catch (e) {
      // console.log('error in willReceive props ', e);
    }
  }

  onLogin = (event, isFacialAuthentication) => {
    this.props.dispatch(resetGetValidateImage());
    if (isFacialAuthentication || this.state.username) {
      const imageSrc = this.webcam ? this.webcam.getScreenshot() : '';
      this.props.dispatch(
        login({
          imageSrc: imageSrc,
          username: isFacialAuthentication ? 'xxx' : this.state.username,
          password: isFacialAuthentication ? 'xxx' : this.state.password,
          grant_type: 'password',
          scope: 'offline_access',
          isFacialAuthentication: isFacialAuthentication || 0,
        }),

        // this.props.history.push('/dashboard')
      );
    }
  };

  // okMethod = () => {
  //   //this.props.dispatch(loadMenu({}));
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   var redirectPath = param.get('redirectPath');
  //   if (redirectPath) {
  //     this.props.history.push(redirectPath);
  //     //window.location.href = '/' + redirectPath;
  //   } else {
  //     this.props.history.push('/dashboard');
  //   }
  // };

  cancelMethod = () => {
    localStorage.clear();
    window.location.reload();
  };

  enterButton = event => {
    if (event.key == 'Enter') {
      this.onLogin();
    }
  };

  setRef = webcam => {
    this.webcam = webcam;
  };
  getPassCode = () => {
    // this.setState({ passcode: e.target.value });
    return document.getElementById('passcode').value;
  };
  onUserMediaError = err => {};
  onEnterPassValidation = event => {
    if (event.key == 'Enter') {
      this.passCodeValidation();
    }
  };
  passCodeValidation = () => {
    let x = this.getPassCode();
    this.props.dispatch(
      validatePasscode({
        Passcode: x || 0,
      }),
    );
  };
  togglePassword = () => {
    if (this.state.passwordType === 'password') {
      this.setState({ passwordType: 'text' });
      return;
    }
    this.setState({ passwordType: 'password' });
  };

  render() {
    const {
      errorMessage,
      isFetching,
      isPasscodeRequired,
      passCodeDisplay,
      isPasscodevarify,
      isPasscodeError,
    } = this.props;
    const { year, modalVisible, passcode } = this.state;
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user',
    };
    return (
      <>
        {this.state.error ? (
          <CustomError
            displayJSError={this.state.error}
            jsError={this.state.jsError}
            resetError={this.resetError.bind(this)}
          />
        ) : (
          <React.Fragment>
            <PageTitle TitleText="Login" />
            <Loader loading={isFetching} />
            <div className="login-page">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-5 col-lg-4 offset-md-1">
                    <div className="login-box-main">
                      <figure className="login-logo">
                        <img
                          src={hostGrabber() ? 'images/youu_Logo.svg' : 'images/logo.svg'}
                          width={hostGrabber() ? '250' : '120'}
                          alt=""
                        />
                      </figure>
                      <h5 className="font-weight-semibold mb-3 mb-md-5">Login to your account</h5>
                      <form className="login-form">
                        <div className="form-group mb-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username/Email"
                            onChange={event => (this.state.username = event.target.value)}
                          />
                        </div>
                        <div className="form-group mb-4 position-relative">
                          <input
                            type={this.state.passwordType}
                            className="form-control"
                            placeholder="Password"
                            onKeyPress={this.enterButton}
                            onChange={event => (this.state.password = event.target.value)}
                          />
                          <span
                            className="position-absolute"
                            style={{
                              border: 'none',
                              top: '9px',
                              right: '3px',
                              backgroundColor: 'transparent',
                              cursor: 'pointer',
                            }}
                          >
                            {this.state.passwordType === 'password' ? (
                              <img src="images/view_password.svg" onClick={this.togglePassword} />
                            ) : (
                              <img src="images/hide_password.svg" onClick={this.togglePassword} />
                            )}
                          </span>
                        </div>
                        <Link to="/forgotpassword" className="forgot-password">
                          Forgot Password?
                        </Link>
                        <input
                          type="button"
                          className="btn btn-login"
                          value="Login"
                          onClick={this.onLogin}
                          ref={this.LoginButtonRef}
                        />
                        &nbsp;
                        <CustomModal
                          // modalWidth={}
                          modalVisible={modalVisible}
                          // ModalButton={({ onClick, onCancel }) => (
                          //   <button
                          //     type="button"
                          //     className="btn btn-login"
                          //     onClick={e => {
                          //       onClick();
                          //     }}
                          //   >
                          //     Facial Authentication
                          //   </button>
                          // )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div className="modal-content border-0">
                              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  onClick={this.cancelMethod}
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title col-12">Facial Authentication</h4>
                              </div>
                              <div className="modal-body">
                                {modalVisible && (
                                  <Webcam
                                    audio={false}
                                    ref={this.setRef}
                                    style={{ height: '200px', width: '550px', margin: 'auto' }}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        />
                        <CustomModal
                          modalWidth={500}
                          modalVisible={isPasscodeRequired}
                          ModalContent={({ onOk, onCancel }) => (
                            <div className="modal-content border-0">
                              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  onClick={this.cancelMethod}
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title col-12">{passCodeDisplay}</h4>
                              </div>
                              <div className="modal-body justify-content-center pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
                                <div className="form-group" style={{ marginBottom: '0px' }}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="passcode"
                                    onKeyPress={this.onEnterPassValidation}
                                    autoFocus
                                    // value={passcode}
                                    // onChange={this.getPassCode}
                                    // placeholder="*******"
                                  />
                                </div>
                                <span style={{ fontWeight: 'lighter', color: 'red' }}>
                                  {isPasscodeError}
                                </span>
                              </div>
                              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  className="btn black-btn"
                                  data-dismiss="modal"
                                  onClick={this.cancelMethod}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  className="btn "
                                  onClick={this.passCodeValidation}
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          )}
                        />
                        {/* <CustomModal
                      isOpen={this.state.modalVisible}
                      header={this.state.content}
                      yesAction={this.okMethod}
                      yesText="OK"
                      noText="Close"
                      noAction={this.cancelMethod}
                      setRef={btn => (this.button = btn)}
                    /> */}
                        {errorMessage && (
                          <div
                            className="alert alert-danger mar-top-20"
                            role="alert"
                            style={{ marginTop: '10px' }}
                          >
                            {errorMessage}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="text-center text-lg-start bg-light text-muted"
                style={{ position: 'fixed', bottom: '0px', display: 'inline-block', width: '100%' }}
              >
                <div className="footer-bottom-link">
                  <ul className="d-flex align-items-center justify-content-center flex-wrap">
                    <li>
                      <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/refund-policy">Refund Policy</Link>
                    </li>
                    <li>
                      <Link to="/disclaimer">Disclaimer</Link>
                    </li>
                    <li>
                      <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>
                    </li>
                    <li>
                      <Link to="/cookie-policy">Cookie Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    login,
    get_load_user_info,
    get_validate_image,
    two_step_auth,
    validate_passcode,
    default_values,
    user_feature_flag_get,
  } = state;
  const { data, isFetching, error } = login;
  const { data: UserData, isFetching: isUserInfoFetching } = get_load_user_info;
  const { data: fFData } = user_feature_flag_get;
  const { data: validData } = get_validate_image;
  const {
    data: twoStepAuthData,
    isFetching: twoStepAuthFetching,
    error: twoStepAuthError,
  } = two_step_auth;
  const { data: passcodeData, isFetching: passcodeDataFetching } = validate_passcode;
  const { isFetching: isDefaultDataFetching } = default_values;

  var isSucess = false;
  var errorMessage = '';
  var access_token = '';
  var expires_in = '';
  var refresh_token = '';
  var authenticationType = 0;
  let staffFirstLogin = '';
  let authRoute = '';
  let menuJson = '';
  let showTrialPopup = false;
  let counselorId = '';
  var isFacialAuthenticated = false;
  var isFacialAuthenticationFailed = false;
  var isPasscodeRequired = false;
  var passCodeDisplay = '';
  var isPasscodevarify = false;
  var hasClinicalAccess = false;
  var isPasscodeError = '';
  var isTwoStepAPICallFinished = false;
  let defaultData = null;
  let featureFlagData = {};
  let userId = 0;
  let isStaffMobileRegistered = false;
  let isSpecialSuperAdmin = false;
  if (data) {
    if (data.access_token) {
      isSucess = true;
      access_token = data.access_token;
      expires_in = data.expires_in;
      refresh_token = data.refresh_token;
    } else {
      errorMessage = 'Login Failed';
    }
  } else if (error) {
    errorMessage = error.errorDescription || error.error_description;
  }
  if (UserData) {
    authenticationType = UserData.data[0].AuthenticationType;
    userId = UserData.data[0].CounselorId;
    staffFirstLogin = UserData.data[0].StaffFirstLogin;
    showTrialPopup = UserData.data[0].ShowTrialPopup;
    counselorId = UserData.data[0].CounselorId;
    hasClinicalAccess = UserData.data[0].HasClinicalAccess;
    authRoute = UserData.authRoute;
    menuJson = UserData.menuJson;
    isStaffMobileRegistered = UserData.data[0].IsStaffMobileRegistered;
    isSpecialSuperAdmin = UserData.data[0].IsSpecialSuperAdmin;
  }

  if (validData && validData.data) {
    if (validData.data.isValidUser) {
      isFacialAuthenticated = true;
    } else {
      errorMessage = 'Facial Authentication Failed';
      isFacialAuthenticationFailed = true;
    }
  }
  if (twoStepAuthData) {
    isTwoStepAPICallFinished = true;
    isPasscodeRequired = twoStepAuthData.IsPasscodeRequired;
    passCodeDisplay = twoStepAuthData.PasscodeDisplay;
  } else if (twoStepAuthError) {
    errorMessage = twoStepAuthError.Message;
  }

  if (passcodeData) {
    // isPasscodevarify = passcodeData.result;
    if (passcodeData.result) {
      isPasscodevarify = true;
    } else {
      isPasscodeError = 'Invalid Passcode...!';
    }
  }
  if (default_values && default_values.data) {
    defaultData = default_values.data || {};
  }

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    featureFlagData = fFData.FeatureFlag[0];
  }

  return {
    authRoute,
    menuJson,
    hasClinicalAccess,
    isTwoStepAPICallFinished,
    isFacialAuthenticated,
    isFacialAuthenticationFailed,
    isSuccess: isSucess,
    errorMessage: errorMessage,
    access_token: access_token,
    expires_in: expires_in,
    refresh_token: refresh_token,
    isFetching:
      isFetching ||
      isUserInfoFetching ||
      passcodeDataFetching ||
      twoStepAuthFetching ||
      isDefaultDataFetching,
    authenticationType: authenticationType,
    staffFirstLogin,
    showTrialPopup,
    isPasscodeRequired: isPasscodeRequired,
    passCodeDisplay: passCodeDisplay,
    isPasscodevarify: isPasscodevarify,
    isPasscodeError: isPasscodeError,
    counselorId,
    // passcodeDataFetching,
    DefaultData: defaultData,
    featureFlagData,
    UserData,
    userId,
    isStaffMobileRegistered,
    isSpecialSuperAdmin,
  };
};

const ReduxLogin = withRouter(connect(mapStateToProps)(Login));

export default () => (
  <React.Fragment>
    <ReduxLogin />
  </React.Fragment>
);
