import React, { Component } from 'react';
import {
  getConsentFormTemplate,
  saveClientConsent,
  listClientConsent,
} from '../../../store/actions';
import { connect } from 'react-redux';
import Loader, { Loader2 } from '../../../Components/Loader';
import { Table, Typography } from 'antd';
import CustomModal from '../../../core/modal';

import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import SignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-canvas';
import PageTitle from '../../../Components/PageTitle';
class SignConsent extends Component {
  constructor() {
    super();
    this.state = {
      HtmlTemplate: null,
      trimmedDataURL: null,
    };
  }

  sigPadObj = {};
  clear = index => {
    this.sigPadObj[index].clear();
  };
  trim = (signOne, index) => {
    let data = this.sigPadObj[index].getTrimmedCanvas().toDataURL('image/png');
    this.setState({
      SignatureCanvas: {
        ...this.state.SignatureCanvas,
        [signOne]: data,
      },
    });
  };
  save = () => {
    const { SignatureCanvas, HtmlTemplate } = this.state;
    let imgByteArr = Object.values(SignatureCanvas);
    this.props.dispatch(
      saveClientConsent({
        Json: JSON.stringify({
          ClientConsent: [
            {
              ClientId: this.getClientId(),
              ClientConsentId: this.getClientConsentId(),
            },
          ],
        }),
        Html: JSON.stringify(HtmlTemplate),
        ImageSign: JSON.stringify({
          Image: imgByteArr.map(item => {
            return { Data: item };
          }),
        }),
      }),
    );
  };
  getConsentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('consentId');
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('Cid');
  }
  getClientConsentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('ccid');
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      getConsentFormTemplate({
        json: JSON.stringify({
          ConsentForm: [
            {
              CenterConsentFormId: this.getConsentId(),
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentWillReceiveProps({ FormData, isSaved }) {
    if (FormData) {
      this.setState({
        HtmlTemplate: FormData,
      });
    }
    if (isSaved && isSaved != this.props.isSaved) {
      this.toggle('Saved successfully');
    }
  }

  render() {
    const { isFetching, FormData } = this.props;
    const { HtmlTemplate } = this.state;
    let { trimmedDataURL } = this.state;
    return (
      <div className="container">
        <PageTitle TitleText="Consent Authorization Form" />
        <Loader loading={isFetching} />
        <div className=" py-5">
          <div className="">
            {/* <div>{HtmlTemplate && renderHTML(HtmlTemplate)}</div> */}
            {HtmlTemplate &&
              HtmlTemplate.html &&
              HtmlTemplate.html.map((htmlItem, index) => {
                return htmlItem.isSign ? (
                  <div key={index}>
                    {renderHTML(htmlItem.data)}{' '}
                    <div className="" style={{ width: '400px' }}>
                      <div className="sigContainer">
                        <SignaturePad
                          canvasProps={{ className: 'sigPad', height: 140, width: 400 }}
                          ref={ref => {
                            Object.assign(this.sigPadObj, { [index]: ref });
                          }}
                          onEnd={() => {
                            this.trim(`signOne+${index}`, index);
                          }}
                        />
                      </div>
                      <div className="text-right mt-2">
                        {/* <button
                        className="can-buttons d-inline"
                        onClick={() => {
                          // this.trim(`signOne+${index}`);
                        }}
                      >
                        Save
                      </button> */}
                        <button
                          className="can-buttons d-inline"
                          onClick={() => {
                            this.clear(index);
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={index}>{renderHTML(htmlItem.data)} </div>
                );
              })}
          </div>
          <div className="row mt-5 mb-3 align-items-center ">
            {/* <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                backgroundColor="green"
              /> */}
            {/* <div className="">
              <div className="sigContainer">
                <SignaturePad
                  canvasProps={{ className: 'sigPad', height: 100 }}
                  ref={ref => {
                    this.sigPad = ref;
                  }}
                />
              </div>
              <div>
                <button className="can-buttons d-inline" onClick={this.clear}>
                  Clear
                </button>
              </div>
            </div> */}
          </div>
          <div className="row mb-5 align-items-center justify-content-end">
            <button className="btn table-btn" onClick={this.save}>
              Submit
            </button>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            window.open('', '_self').close();
            // this.props.history.push('/manage/courses');
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ get_consent_form_template, save_client_consent }) => {
  const { data, isFetching, error } = get_consent_form_template;
  const { data: saveData, isFetching: saveIsFetching } = save_client_consent;
  let isSaved = false;

  let FormData;
  if (data && data.ConsentForm) {
    FormData = JSON.parse(data.ConsentForm[0].HtmlTemplate);
  }
  if (saveData)
    if (saveData && saveData.success) {
      isSaved = true;
    }
  return {
    FormData: FormData,
    isFetching: isFetching || saveIsFetching,
    isSaved,
  };
};

export default connect(mapStateToProps)(SignConsent);
