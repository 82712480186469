import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  reset,
  FieldArray,
  change,
  isDirty,
} from 'redux-form';
import { get } from 'lodash';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusPercentageInput,
} from '../../../../../../component/custom-redux-components';
import BuildServiceBundle from './bundleService';
import { ProcessCombos } from '../../../../../../store/comboConfig';
import {
  centerOverHeadCostGet,
  serviceCodeBundleUpsert,
  resetServiceCodeBundleUpsert,
  serviceCodeBundleGetAll,
  resetServiceCodeBundleGet,
  smartContractUpsert,
  listSmartCombos,
  clientInsuranceComboGet,
  practitionerCombos,
} from '../../../../../../store/actions';
import validate from '../../../../../../core/generic-form/validation';
import CustomModal from '../../../../../../core/modal';
// import ReduxGoals from './goals';
import ReduxAddBundle from './addBundle';
import { currencyFormatter, numberFormat } from '../../../../../../util/formatter';
import NewCostSummary from '../calCostSummary';
import SubmitButton from '../../../../../../Components/submitButton';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
import WarningComponent from '../../../../../../core/warning-component';
import DownloadContractPdf from '../downloadContractPdf';

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var startDate = values.StartDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return ' has to be greater than Start Date';
    }
  },
};

const CreateContracForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    centerProgramType,
    smartContractMeasure,
    totalCostOfCare,
    netMarginAmount,
    billingRate,
    centerOverHeadCostState,
    setTotalCostOfCare,
    initialValues,
    enableCreateButton,
    goalTimePeriod,
    setIndexOfNetMarginChange,
    setCurrentServiceTypeIndex,
    contractType,
    setEnableCreateButton,
    handleSubmitAndNext,
    currentTab,
    tabsCount,
    getLatestData,
    disabled,
    // change,
    // serviceCodeBundleData,
    // centerServiceTypeCenter,
    // existingRows,
    // serviceCodeBundle,
    // goalLibrary,
  } = props;
  const [summaryData, setSummaryData] = useState({});
  const [fieldChanged, setFieldChanged] = useState(null);
  const [open, setOpen] = useState(false);
  const [lastExistingRows, setLastExistingRows] = useState([]);
  const [serviceCodeInfo, setServiceCodeInfo] = useState([{}]);
  const {
    serviceCodeBundle,
    centerServiceTypeCenter,
    goalLibrary,
    serviceCodeBundleData,
    serviceCodeInfoData,
    existingRows,
    isDirtyValue,
    submitSucceeded,
  } = useSelector(state => {
    const {
      combos,
      service_code_bundle_get,
      get_service_code: { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode },
    } = state;
    return {
      serviceCodeBundle: combos && combos.data && combos.data.ServiceCodeBundle,
      centerServiceTypeCenter: combos && combos.data && combos.data.CenterServiceTypeCenter,
      goalLibrary: combos && combos.data && combos.data.GoalLibrary,
      serviceCodeBundleData: service_code_bundle_get && service_code_bundle_get.data,
      serviceCodeInfoData: serviceCodeinfoData,
      existingRows: formValueSelector('contractForm')(state, 'Bundles'),
      isDirtyValue: isDirty('contractForm')(state),
      submitSucceeded: get(state, 'form.contractForm.submitSucceeded', false),
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  const modifiyBackendKeys = ServiceType => {
    let temp =
      ServiceType &&
      ServiceType.map(item => {
        return {
          ...item,
          ProgramId: item.CenterServiceTypeCenterId,
          BuildServiceBundle: item.ServiceCodeBundle,
        };
      });
    return temp;
  };

  const getClientId = () => {
    var param = new URLSearchParams(props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const reGenServiceCode = existingRows => {
    let temp = existingRows.map((bundleItem, bundleIndex) => {
      const { ServiceType } = bundleItem;
      let updatedServiceType = ServiceType.map((serviceTypeItem, serviceIndex) => {
        if (serviceTypeItem && serviceTypeItem.BuildServiceBundle) {
          const { BuildServiceBundle } = serviceTypeItem;
          let updatedBuildServiceBundle = BuildServiceBundle.map(
            (serviceCodeItem, serviceCodeIndex) => {
              if (serviceCodeItem && serviceCodeItem.StandardCost) {
                const { StandardCost, NetMargin } = serviceCodeItem;
                let tempNetMarginCost = (StandardCost * NetMargin) / 100;
                let tempTotalServiceCode = tempNetMarginCost + StandardCost;
                return {
                  ...serviceCodeItem,
                  NetMargin: NetMargin,
                  NetMarginCost: tempNetMarginCost.toFixed(2),
                  NetAmount: tempNetMarginCost.toFixed(2),
                  TotalServiceCost: tempTotalServiceCode.toFixed(2),
                  BillingRate: tempTotalServiceCode.toFixed(2),
                };
              }
            },
          );

          return {
            ...serviceTypeItem,
            BuildServiceBundle: updatedBuildServiceBundle,
          };
        }
      });

      dispatch(change('contractForm', `Bundles[${bundleIndex}]ServiceType`, updatedServiceType));
      return {
        ...bundleItem,
        ServiceType: updatedServiceType,
      };
    });
    return temp;
  };

  useEffect(() => {
    if (
      serviceCodeBundleData &&
      serviceCodeBundleData.ServiceCodeBundle &&
      serviceCodeBundleData.ServiceCodeBundle.length > 0
    ) {
      const { goalIndex, bundleIndex, ServiceCodeBundle } = serviceCodeBundleData;
      const { ServiceType, DaysInTreatment, FrequencyId } =
        ServiceCodeBundle && ServiceCodeBundle[0];
      let temp = modifiyBackendKeys(ServiceType);
      dispatch(change('contractForm', `Bundles[${bundleIndex}]Duration`, DaysInTreatment));
      dispatch(change('contractForm', `Bundles[${bundleIndex}]FrequencyId`, FrequencyId));
      // dispatch(
      //   change('contractForm', `Goal[${goalIndex}]Bundles[${bundleIndex}]ServiceType`, temp),
      // );
      dispatch(change('contractForm', `Bundles[${bundleIndex}]ServiceType`, temp));
    }
  }, [serviceCodeBundleData]);

  useEffect(() => {
    if (serviceCodeInfoData && serviceCodeInfoData.ServiceCode) {
      const { goalIndex, bundleIndex, serviceTypeIndex, index, ServiceCode } = serviceCodeInfoData;

      // dispatch(
      //   change(
      //     'contractForm',
      //     `Goal[${goalIndex}]Bundles[${bundleIndex}]ServiceType[${serviceTypeIndex}]BuildServiceBundle[${index}]`,
      //     ServiceCode && ServiceCode[0],
      //   ),
      // );
      dispatch(
        change(
          'contractForm',
          `Bundles[${bundleIndex}]ServiceType[${serviceTypeIndex}]BuildServiceBundle[${index}]`,
          ServiceCode && ServiceCode[0],
        ),
      );
    }
  }, [serviceCodeInfoData]);

  useEffect(() => {
    dispatch(
      clientInsuranceComboGet({
        Json: JSON.stringify({ Client: { ClientId: getClientId() } }),
      }),
    );
    dispatch(practitionerCombos({}));
    if (props.smartContractId) {
      getLatestData();
    }
  }, []);

  useEffect(() => {
    if (
      existingRows &&
      existingRows[0] != undefined &&
      JSON.stringify(existingRows) != JSON.stringify(lastExistingRows)
    ) {
      let isExistingDefind =
        existingRows &&
        // existingRows.length>0 &&
        existingRows.every(item => {
          if (item != undefined) {
            if (Object.keys(item).length > 1) {
              return true;
            } else {
              return false;
            }
          }
        });

      let reGenServiceCodeArray;
      if (fieldChanged == 'ProgramId' || fieldChanged == 'ServiceCodeId' || fieldChanged == null) {
        reGenServiceCodeArray = existingRows;
      } else {
        reGenServiceCodeArray = reGenServiceCode(existingRows);
      }
      if (isExistingDefind) {
        let xyz = [];

        reGenServiceCodeArray &&
          reGenServiceCodeArray.map(item => {
            if (item && item.Bundles) {
              xyz = [...xyz, ...item.Bundles];
            }
          });
        let checkUndefindValues =
          xyz.length > 0 &&
          xyz.every(item => {
            if (item != undefined) {
              if (Object.keys(item).length > 1) {
                return true;
              } else {
                return false;
              }
            }
          });
        if (checkUndefindValues) {
          setServiceCodeInfo(xyz);
          setEnableCreateButton(
            xyz &&
              xyz.length > 0 &&
              xyz[0].ServiceType &&
              xyz[0].ServiceType.length > 0 &&
              xyz[0].ServiceType[0] &&
              Object.keys(xyz[0].ServiceType[0]).length > 0 &&
              xyz[0].ServiceType[0].BuildServiceBundle &&
              xyz[0].ServiceType[0].BuildServiceBundle.length > 0,
          );
          // calCostSummary(xyz);
        }
      }
      setLastExistingRows(existingRows);
    }
  }, [existingRows]);

  return (
    <>
      <div
        style={
          props.isFetchingSmartContractGet
            ? { height: '200px', position: 'relative' }
            : { height: '0px' }
        }
      >
        <Loader2 loading={props.isFetchingSmartContractGet} />
      </div>
      {!props.isFetchingSmartContractGet && (
        <div>
          {/* In below condition i need to check once more thing that SmartContractId == null  */}
          {/* once old episodes will have contracts we need to show them */}
          {props.clientInfo &&
          props.clientInfo.Episode[props.clientInfo.Episode.length - 1].EpisodeId ==
            props.clientInfo.EpisodeId ? (
            <>
              <div
                className="container-fluid mt-1 px-0"
                style={{
                  minHeight: '6000px',
                  // overflow: 'auto'
                }}
              >
                <div
                  className="p-2"
                  style={{
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  {contractType == '1' && (
                    <div className="row mt-1 mb-3">
                      <div className="col-md-8">
                        <div
                          // className="row payer-details pt-3 pb-3"
                          className="payer-details pt-3 pb-3"
                        >
                          <div className="row px-2">
                            <div className="col-md-12 text-left d-flex justify-content-between">
                              <div className="label mt-2 mb-2">Contract Details</div>
                              {props.smartContractId > 0 && (
                                <DownloadContractPdf
                                  smartContractId={props.smartContractId}
                                  clientId={getClientId()}
                                />
                              )}
                            </div>
                            {props.bundle && props.bundle.length > 0 && (
                              <div className="col-md-4 text-right">
                                <span
                                  style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontWeight: '900',
                                    justifyContent: 'end',
                                    fontSize: '16px',
                                  }}
                                  // className="mr-4"
                                >
                                  Total Billing Rate:{' '}
                                  {currencyFormatter.format(summaryData.totalBillingRate || 0)}
                                </span>
                                <span
                                  onClick={showDrawer}
                                  style={{
                                    color: '#46b4fe',
                                    cursor: 'pointer',
                                    fontWeight: '900',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                  }}
                                >
                                  Summary{' '}
                                  <img
                                    src="images/expandedIconBlue2.svg"
                                    // className="mx-2"
                                    style={{ cursor: 'pointer', width: '18px' }}
                                  />
                                </span>
                              </div>
                            )}
                          </div>

                          <form
                            // onSubmit={handleSubmit}
                            onSubmit={handleSubmit(values => {
                              props.onContractFirstTabSubmit(values);
                            })}
                          >
                            <div className="container-fluid px-3">
                              <div className="row">
                                <div className="col-md-6">
                                  <h5>Build Contract</h5>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <div
                                    // className="card"
                                    style={{ border: 'none' }}
                                  >
                                    <div
                                      // className="card-body enrollment-form"
                                      className="enrollment-form"
                                    >
                                      <div class="row">
                                        <div class="col-12 col-md-6 col-lg-6">
                                          <div class="form-group">
                                            <Field
                                              name="ContractName"
                                              type="text"
                                              component={ReduxInput}
                                              validate={validate.required}
                                              fieldName={'Contract Name'}
                                              // disabled={
                                              //   props.initialValues &&
                                              //   props.initialValues.IsContractCreated
                                              // }
                                              disabled={props.disabled}
                                              style={{ background: 'unset' }}
                                            />
                                            <label class="placeholder-label">Contract Name*</label>
                                          </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                          <div class="form-group">
                                            <Field
                                              name="StartDate"
                                              type="text"
                                              component={ReduxDateInput}
                                              validate={validate.required}
                                              fieldName={'Start Date'}
                                              label="Start Date*"
                                              minDate={new Date()}
                                              // disabled={
                                              //   props.initialValues &&
                                              //   props.initialValues.IsContractCreated
                                              // }
                                              disabled={props.disabled}
                                            />
                                          </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                          <div class="form-group">
                                            <Field
                                              name="EndDate"
                                              type="text"
                                              component={ReduxDateInput}
                                              validate={[validate.required, Validator.endDate]}
                                              fieldName={'End Date'}
                                              label="Expected End Date*"
                                              minDate={new Date()}
                                              // disabled={
                                              //   props.initialValues &&
                                              //   props.initialValues.IsContractCreated
                                              // }
                                              disabled={props.disabled}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="mt-2"
                                    style={{ border: 'none' }}
                                    // className="card mt-2" style={{ border: 'none' }}
                                  >
                                    <div
                                      className="enrollment-form"
                                      // className="card-body enrollment-form"
                                    >
                                      {/* <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <FieldArray
                                        name="Goal"
                                        component={ReduxGoals}
                                        goalLibrary={goalLibrary}
                                        SmartContractId={
                                          props.initialValues && props.initialValues.SmartContractId
                                        }
                                        initialValues={
                                          props.initialValues && props.initialValues.Goal
                                        }
                                        smartContractMeasure={smartContractMeasure}
                                        serviceCodeBundle={serviceCodeBundle}
                                        // centerOverHeadCostState={centerOverHeadCostState}
                                        // setTotalCostOfCare={setTotalCostOfCare}
                                        setFieldChanged={setFieldChanged}
                                        setIndexOfNetMarginChange={setIndexOfNetMarginChange}
                                        setCurrentServiceTypeIndex={setCurrentServiceTypeIndex}
                                        existingRows={existingRows}
                                        centerServiceTypeCenter={centerServiceTypeCenter}
                                      />
                                    </div> */}

                                      <div className="col-12 col-md-12 col-lg-12 px-0">
                                        <FieldArray
                                          name="Bundles"
                                          component={ReduxAddBundle}
                                          // goalIndex={index}
                                          serviceCodeBundle={serviceCodeBundle}
                                          goalLibrary={goalLibrary}
                                          SmartContractId={
                                            props.initialValues &&
                                            props.initialValues.SmartContractId
                                          }
                                          initialValues={
                                            props.initialValues && props.initialValues.Bundles
                                          }
                                          // initialValues={
                                          //   (props.initialValues &&
                                          //     props.initialValues[index] &&
                                          //     props.initialValues[index].Bundles) ||
                                          //   (props.initialValues &&
                                          //     props.initialValues[index] &&
                                          //     props.initialValues[index].ServiceType)
                                          // }
                                          setIndexOfNetMarginChange={setIndexOfNetMarginChange}
                                          setCurrentServiceTypeIndex={setCurrentServiceTypeIndex}
                                          // centerOverHeadCostState={centerOverHeadCostState}
                                          centerOverHeadCostState={10}
                                          // setTotalCostOfCare={setTotalCostOfCare}
                                          setTotalCostOfCare={() => {}}
                                          // disabled={
                                          //   props.initialValues &&
                                          //   props.initialValues.IsContractCreated
                                          // }
                                          disabled={props.disabled}
                                          existingRows={existingRows}
                                          centerServiceTypeCenter={centerServiceTypeCenter}
                                          smartContractMeasure={smartContractMeasure}
                                          setFieldChanged={setFieldChanged}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-right">
                              {/* <button type="submit" value="submit" class="btn mt-4 mb-4 mr-2">
                              Save
                            </button> */}
                              {!props.disabled && (
                                <SubmitButton
                                  loading={props.isUpsertFetching}
                                  value={'Save & Next'}
                                  className="btn mt-4 mb-4 mr-2"
                                  type="submit"
                                  style={{ height: '42px' }}
                                />
                              )}
                              {props.disabled && (
                                <Button
                                  type="primary"
                                  onClick={props.handleNext}
                                  className={`btn save-button mb-4 mr-2 mt-4`}
                                  style={{ height: '42px' }}
                                >
                                  Next
                                </Button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* <Drawer
                    closeIcon={<CloseIcon />}
                    width="600"
                    title="Cost Summary"
                    placement="right"
                    onClose={onClose}
                    open={open}
                  >
                    <CostSummary bundle={bundle} setSummaryData={setSummaryData} />
                  </Drawer> */}
                      <div className="col-md-4">
                        <NewCostSummary
                          bundle={props.bundle}
                          setSummaryData={setSummaryData}
                          existingRows={existingRows}
                          serviceCodeBundle={serviceCodeBundle}
                          setGetBundleSummaryArray={props.setGetBundleSummaryArray}
                        />
                      </div>

                      {/* <div className="col-md-3">
                <CostSummary bundle={bundle} setSummaryData={setSummaryData} />
              </div> */}
                    </div>
                  )}
                  {contractType == '2' && (
                    <div className="row mt-1 mb-3 fee-for-service">
                      {' '}
                      <div className="col-md-12 d-none">
                        <p className="p-4">
                          Amet minim mollit non deserunt ullamco est sit. Amet minim mollit non
                          deserunt ullamco est sit....Amet minim mollit non deserunt ullamco est
                          sit....Amet minim mollit non deserunt ullamco est sit....Amet minim mollit
                          non deserunt ullamco est sit....
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <p style={{ fontSize: '16px' }}>
                <b>Contract can not be created for old episode.</b>
              </p>
            </>
          )}
        </div>
      )}
      <WarningComponent dirtyFlag={isDirtyValue} submitSucceeded={submitSucceeded} />
    </>
  );
};

// const mapStateToProps = state => {
//   const { combos, service_code_bundle_get, get_service_code } = state;
//   const { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode } = get_service_code;

//   let serviceCodeBundle = [];
//   let goalLibrary = [];
//   let centerServiceTypeCenter = [];
//   let serviceCodeBundleData = null;
//   let serviceCodeInfo = {};

//   if (service_code_bundle_get && service_code_bundle_get.data) {
//     serviceCodeBundleData = service_code_bundle_get.data;
//   }
//   if (serviceCodeinfoData && serviceCodeinfoData.ServiceCode) {
//     serviceCodeInfo = serviceCodeinfoData;
//   }
//   if (combos.data) {
//     const { ServiceCodeBundle, GoalLibrary, CenterServiceTypeCenter } = combos.data;
//     if (ServiceCodeBundle) {
//       serviceCodeBundle = ServiceCodeBundle;
//     }
//     if (GoalLibrary) {
//       goalLibrary = GoalLibrary;
//     }
//     if (CenterServiceTypeCenter) {
//       centerServiceTypeCenter = CenterServiceTypeCenter;
//     }
//   }

//   return {
//     serviceCodeBundle,
//     centerServiceTypeCenter,
//     goalLibrary,
//     serviceCodeBundleData,
//     serviceCodeInfo,
//     // existingRows: formValueSelector('contractForm')(state, 'Goal'),
//     existingRows: formValueSelector('contractForm')(state, 'Bundles'),
//   };
// };

const ReduxCreateContracForm = reduxForm({
  form: 'contractForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // keepDirtyOnReinitialize: true,
  onSubmitFail: errors => {
    if (errors) {
      setTimeout(() => {
        const element = document.querySelector('.error-text');

        // if (element) {
        //   // Scroll to the element
        //   element.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start', // Scroll to the top of the element
        //   });
        // }

        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetValue = elementPosition + window.scrollY - 200;

          window.scrollTo({
            top: offsetValue,
            behavior: 'smooth',
          });
        }
      }, 500);
    }
  },
  validate: (values, props) => {
    const errors = {};
    const bundlesArray = values.Bundles || [];
    bundlesArray.forEach((item, index) => {
      if (item && item.Goal) {
        item.Goal &&
          item.Goal.forEach((goalItem, index2) => {
            if (goalItem) {
              let startDate = new Date(goalItem.StartDate).getTime();
              let endDate = new Date(goalItem.EndDate).getTime();
              if (endDate <= startDate) {
                errors.Bundles = errors.Bundles || [];
                errors.Bundles[index] = errors.Bundles[index] || {};
                errors.Bundles[index].Goal = errors.Bundles[index].Goal || [];
                errors.Bundles[index].Goal[index2] = {
                  EndDate: 'has to be greater than Start Date',
                };
              } else {
                errors.Bundles = errors.Bundles || [];
                errors.Bundles[index] = errors.Bundles[index] || {};
                errors.Bundles[index].Goal = errors.Bundles[index].Goal || [];
                errors.Bundles[index].Goal[index2] = {};
              }
            }
            if (index2 > 0) {
              //End Date of the last index;
              const lastIndexValue = index2 - 1;
              const endDateOfPreviousGoal =
                item &&
                item.Goal &&
                item.Goal[lastIndexValue] &&
                item.Goal[lastIndexValue].EndDate &&
                new Date(item.Goal[lastIndexValue].EndDate).getTime();
              //Start Date of the current index;
              const currentIndex = index2;
              const startDateOfCurrentGoal =
                item &&
                item.Goal &&
                item.Goal[currentIndex] &&
                item.Goal[currentIndex].StartDate &&
                new Date(item.Goal[currentIndex].StartDate).getTime();
              if (startDateOfCurrentGoal <= endDateOfPreviousGoal) {
                errors.Bundles = errors.Bundles || [];
                errors.Bundles[index] = errors.Bundles[index] || {};
                errors.Bundles[index].Goal = errors.Bundles[index].Goal || [];
                errors.Bundles[index].Goal[index2] = {
                  StartDate: 'has to be greater than End Date of Previous Goal',
                };
              }
            }
          });
      }
    });
    return errors;
  },
  // onSubmit: (values, dispatch, props) => {
  //   props.onContractSubmit(values);
  // },
  // onSubmit: (values, dispatch, props) => {
  //   debugger;
  //   props.handleSubmitAndNext(values, props.currentTab, props.tabsCount);
  // },
})(withRouter(CreateContracForm));

export default ReduxCreateContracForm;
