import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../Components/Loader';

class BreathlyzerVideoModal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { onOk, onCancel, VideoCallUrl, Title, isFetching } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-1">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          {/* <h4 class="modal-title col-12">{'Title'}</h4> */}
        </div>
        <div class="modal-body" style={{ padding: '2px 50px', paddingBottom: '12px' }}>
          <Loader2 loading={isFetching} />
          {VideoCallUrl.includes('https') ? (
            <video
              style={{ border: '3px solid #EEE' }}
              width="100%"
              height="auto"
              src={VideoCallUrl}
              autoplay="true"
              controls
              disablePictureInPicture="true"
              controlsList="nodownload"
            />
          ) : (
            <div className="text-center">
              <h5>{VideoCallUrl}</h5>
            </div>
          )}
        </div>

        <div class="modal-footer justify-content-center border-top-0 py-2">
          <button type="button" class="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsGetUrl = ({ get_media_video_url }) => {
  const { data, error, isFetching } = get_media_video_url;
  let VideoCallUrl = '';

  if (get_media_video_url && get_media_video_url.data) {
    VideoCallUrl = data.result;
  }
  return {
    VideoCallUrl,
    isFetching,
  };
};

export default connect(mapStateToPropsGetUrl)(BreathlyzerVideoModal);
