import React, { Component } from 'react';
import 'antd/dist/antd.css';
import {
  getStudentProfileDashboardData,
  getAppSubscribersDashboard,
  getNotes,
  listCombos,
} from '../store/actions';
import { connect } from 'react-redux';
import Paragraph from 'antd/lib/typography/Paragraph';
import Loader from '../Components/Loader';
import { ReduxSelect } from '../component/custom-redux-components';
import renderHTML from 'react-render-html';

class PrintNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteType: '',
    };
  }

  componentDidMount() {
    if (this.getStudentId()) {
      this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
    }
    if (this.getUserGuid()) {
      this.props.dispatch(getAppSubscribersDashboard({ UserGuid: this.getUserGuid() }));
    }
    this.props.dispatch(
      listCombos({
        comboTypes: 'NoteType',
        StudentId: this.getStudentId(),
      }),
    );
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }
  getUserGuid() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('userGuid');
  }
  filterByNotes = noteType => {
    // var noteTypeIds = '';
    // if (noteType && noteType.length > 0) {
    //   for (var item of noteType) {
    //     noteTypeIds += item.Value + ',';
    //   }
    // }
    var noteTypeIds = [];
    if (noteType && noteType.length > 0) {
      for (var item of noteType) {
        noteTypeIds.push({ NoteTypeId: item.Value });
      }
    }
    let jsonData = {
      Json: JSON.stringify({ StudentNote: noteTypeIds }),
      StudentId: this.getStudentId(),
      // UserGuid: this.getUserGuid(),
    };
    this.props.dispatch(getNotes(jsonData));
  };
  render() {
    const {
      NoteData,
      FirstName,
      MiddleName,
      LastName,
      notesData,
      isFetching,
      isFetchingNotes,
      noteOptions,
      Notes,
      CenterId,
      CenterName,
    } = this.props;
    const { noteType } = this.state;
    return (
      <>
        <Loader loading={isFetching} />
        <div className="d-block text-right" style={{ margin: '5px 2em' }}>
          <button
            onClick={() => window.print()}
            className="btn btn-outline-primary black-btn px-5 print-btn"
          >
            {/* <i class="fas fa-print mr-2"></i> */}
            <img src="images/print_blue.svg" className="mr-2" alt="" width="24" />
            Print
          </button>
        </div>
        <div className="client-center-info">
          <div className="item">
            <span className="text">Center Id : </span>
            <span className="value">{CenterId}</span>
          </div>
          <div className="item">
            <span className="text">Center Name : </span>
            <span className="value">{CenterName}</span>
          </div>
        </div>
        <div className="card-print card-body">
          <div class="d-flex justify-content-between enrollment-form students-name">
            <h4 className="">Notes</h4>
            <div className="col-4 add-note-form print-btn" style={{ marginTop: '5px', flex: '1' }}>
              <div className="form-group">
                <ReduxSelect
                  options={noteOptions}
                  isMulti
                  value={noteType}
                  onChange={value => {
                    this.setState({ noteType: value });
                    this.filterByNotes(value);
                  }}
                  placeholder="Filter By Note Type....."
                  classNamePrefix="note-type-select"
                />
              </div>
            </div>
            <h4 className="">
              {FirstName && FirstName} {MiddleName && MiddleName} {LastName && LastName}
            </h4>
          </div>
          {NoteData &&
            NoteData.map(item => {
              return (
                <p>
                  <div class="">
                    <div class="note-box">
                      <div class="row">
                        <div class="col-12 col-md-7">
                          <h6 class="note-type d-flex">
                            <Paragraph
                              ellipsis={{ rows: 1, expandable: false }}
                              textAlign="justify"
                              style={{ color: '#000000' }}
                            >
                              {item.NoteTypes}
                            </Paragraph>
                            {item.IsConfidential && (
                              <span class="alert alert-danger confidential-alert border-0 text-uppercase ml-2">
                                <i class="fas fa-lock" /> Confidential
                              </span>
                            )}
                          </h6>
                          <span class="note-author">by {item.EnteredBy}</span>
                        </div>
                        <div class="col-12 col-md-5">
                          <span class="note-date">{item.DateEntered}</span>
                        </div>
                        <div class="col-12 pt-2">
                          {item.IsDeleteAllowed ? (
                            <p class="note-text mb-0">{item.Note && renderHTML(item.Note)}</p>
                          ) : (
                            <p class="note-text mb-0">{item.Note}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </p>
              );
            })}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  student_get,
  student_get_profile_dashboard_data,
  get_app_subscribers_dashboard,
  get_notes,
  combos,
}) => {
  var { data, isFetching: isFetchingStud, error } = student_get;
  var { isFetching: isFetchingApp, error } = get_app_subscribers_dashboard;
  var { data: notesData, isFetching: isFetchingNotes } = get_notes;
  var { data: combosData, isFetching: isFetchingCombos } = combos;

  var StudentData = null;
  var UserData = null;
  var NoteData = null;
  var FirstName = null;
  var MiddleName = null;
  var LastName = null;
  var Notes = null;
  var CenterId = null;
  var CenterName = null;
  if (student_get_profile_dashboard_data.data) {
    StudentData = student_get_profile_dashboard_data.data;
    NoteData = StudentData.NoteStudent || [];
    FirstName = StudentData.FirstName || [];
    MiddleName = StudentData.MiddleName || [];
    LastName = StudentData.LastName || [];
    CenterId = StudentData.CenterId;
    CenterName = StudentData.CenterName;
  }
  if (get_app_subscribers_dashboard.data) {
    UserData = get_app_subscribers_dashboard.data;
    FirstName = UserData.Name || '';
    NoteData = UserData.NoteUser || [];
  }
  if (notesData) {
    Notes = notesData.NoteStudent;
  }
  return {
    noteOptions: (combosData && combosData.NoteType) || [],
    student: data || {},
    NoteData: Notes || NoteData,
    FirstName,
    MiddleName,
    LastName,
    isFetching: isFetchingStud || isFetchingApp,
    isFetchingNotes,
    notesData,
    CenterId,
    CenterName,
  };
};

export default connect(mapStateToProps)(PrintNotes);
