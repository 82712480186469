import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

const message =
  'Are you sure you want to exit this page? Please click "Cancel" and Save your changes.';
const WarningComponent = ({ dirtyFlag, submitSucceeded = false }) => {
  // const formData = useSelector(state => state.form.demographicForm);
  useEffect(() => {
    window.onbeforeunload = dirtyFlag && (() => message);
    // console.log('formData', formData);
    return () => {
      window.onbeforeunload = null;
    };
  }, [dirtyFlag]);
  const routerPrompt = dirtyFlag && !submitSucceeded ? <Prompt message={message} /> : null;
  return routerPrompt;
};

export default WarningComponent;
