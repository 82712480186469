import React, { Component } from 'react';
import { connect } from 'react-redux';
import Twilio from '../../../Components/twilio';

// import { preIntakeSurveyAnswersGet } from '../../store/actions';
class SurveyAnswersModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  render() {
    const { onOk, onCancel, history, augmentedData } = this.props;
    const { StudentImageUrl, IsVoiceCallAllowed, PhoneNumber, Name } = augmentedData || {};
    return (
      <div class="modal-content border-1">
        <div class="modal-header justify-content-center flex-wrap mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">Program Alignment</h4>
        </div>
        <div class="modal-body px-4 enrollment-form pb-1">
          <div className="row mb-2">
            <div className="col-10">
              <div className="d-flex align-items-center">
                <img
                  src={StudentImageUrl || '/images/blank-profile.png'}
                  style={{
                    width: '60px',
                    height: '55px',
                    borderRadius: '10px',
                    objectFit: 'cover',
                    marginRight: '10px',
                  }}
                />
                <div>
                  <h6 class="students-name text-xs-center text-md-left">{Name}</h6>
                  {IsVoiceCallAllowed > 0 ? (
                    <span
                      className="students-phone"
                      style={{ color: '#46B4FE', cursor: 'pointer', display: 'flex' }}
                      onClick={event => {
                        this.setState({
                          callModelVisible: true,
                          clickedRecord: {
                            Phone: PhoneNumber,
                            Name: Name,
                            ProfileImageUrl: StudentImageUrl,
                          },
                        });
                        event.stopPropagation();
                      }}
                    >
                      <img
                        src={`images/twilioPhoneAllowed.svg`}
                        alt="Calling Allowed"
                        width="15px"
                        className="mr-1"
                      />
                      {PhoneNumber}
                    </span>
                  ) : (
                    <span style={{ color: '#000' }} className="students-phone">
                      {PhoneNumber}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row" style={{ height: '70vh', overflowY: 'scroll' }}>
            {augmentedData &&
              augmentedData.Survey &&
              augmentedData.Survey.map(surveyItem => {
                return (
                  <div className="col-12">
                    <div className="ai-survey-answer">
                      <span className="question">{surveyItem.Question}</span>
                      <span className="answer">{surveyItem.Answer}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div class="modal-footer justify-content-center border-top-0 pb-2 pt-2">
          <button type="button" class="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { pre_intake_survey_answers_get } = state;

  const { data, isFetching } = pre_intake_survey_answers_get;

  let augmentedData;

  if (data && data) {
    augmentedData = data;
  }

  return {
    augmentedData: augmentedData,
    isFetching,
  };
};
export default connect(mapStateToProps)(SurveyAnswersModal);
