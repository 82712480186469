import React, { Component } from 'react';

export const NoRisk = (
  <div id="NoRisk">
    <span className="text">Not Enough Answers Yet</span>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
  </div>
);

export const LowRisk = (
  <div id="LowRisk">
    <span className="text">Low Risk</span>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
  </div>
);

export const MediumRisk = (
  <div id="MediumRisk">
    <span className="text">Medium Risk</span>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot-gray"></div>
    <div className="dot-gray"></div>
  </div>
);

export const HighRisk = (
  <div id="HighRisk">
    <span className="text">High Risk</span>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);
