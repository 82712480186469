import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import { Drawer, Tooltip } from 'antd';
import Stopwatch from '../../Components/stopwatch';
import {
  studentTimerUpsert,
  resetStudentTimerUpsert,
  setStickeyNote,
  resetSetStickeyNote,
} from '../../store/actions';
import CloseIcon from '../../Components/CloseIcon';
import CallEndedForm from './pre-intake/dashboard/AppointmentsScreens/CallEnded';

const StickyNotes = props => {
  const dispatch = useDispatch();
  const [notesText, setNotesText] = useState();
  const [showNotes, setShowNotes] = useState();
  const [hideTextarea, setHideTextarea] = useState(false);
  const [isTimerDrawer, setTimerDrawer] = useState(false);
  const { fNotes, stickeyData, timerData } = useSelector(
    ({
      set_stickey_note: { data: stickeyData },
      student_timer_upsert: { data: timerUpsertData },
    }) => {
      return {
        fNotes: stickeyData && stickeyData.fNotes,
        stickeyData: stickeyData,
        timerData: timerUpsertData && timerUpsertData.result,
      };
    },
    shallowEqual,
  );
  const { ID, CurrentClientId, Record } = stickeyData || {};

  function dragElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    if (document.getElementById(elmnt.id + 'header')) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      if (elmnt.offsetTop - pos2 > 0 && elmnt.offsetLeft - pos1 > 0) {
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
      }
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
  useEffect(() => {
    let sesstionNote = localStorage.getItem(`fNotesText`);

    sesstionNote = sesstionNote != 'null' ? sesstionNote : null;
    setNotesText(sesstionNote);
    let isNotes = localStorage.getItem(`fNotes`);
    if (isNotes == 'true') {
      dragElement(document.getElementById('mydiv'));
      setShowNotes(true);
    }
  }, []);
  useEffect(() => {
    if (fNotes == true) {
      let name =
        Record.FirstName || Record.LastName
          ? Record.FirstName + ' ' + Record.LastName
          : Record.PrefferedName;
      dragElement(document.getElementById('mydiv'));
      setShowNotes(fNotes);
      setNotesText('');
      localStorage.setItem(`fNotes`, fNotes);
      localStorage.setItem(`fNotesFor`, name);
      localStorage.setItem(`fNotesForID`, ID);
    } else if (fNotes == false) {
      setShowNotes(fNotes);
      localStorage.removeItem(`fNotes`);
      localStorage.removeItem(`fNotesFor`);
      localStorage.removeItem(`fNotesForID`);
      setNotesText('');
    }
  }, [fNotes]);
  useEffect(() => {
    if (timerData && timerData.StudentTimerId && timerData.DurationInSec) {
      setTimerDrawer(true);
    }
  }, [timerData]);

  const handleChange = event => {
    let val = event.target.value;
    setNotesText(val);
    localStorage.setItem(`fNotesText`, val);
  };
  const TimerAction = status => {
    let fNotesForID = localStorage.getItem(`fNotesForID`);
    dispatch(
      studentTimerUpsert({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: ID || fNotesForID,
              TimerAction: status,
            },
          ],
        }),
      }),
    );
    if (status === 2) {
      localStorage.removeItem(`IsPeer${ID}`);
      localStorage.removeItem(`IsPeerToggle`);
      localStorage.removeItem(`hideTextarea`);
      dispatch(setStickeyNote({ fNotes: false }));
      setNotesText();
    }
  };
  const onCloseDrawer = () => {
    setTimerDrawer(false);
    dispatch(resetStudentTimerUpsert());
  };
  let fNotesFor = Record
    ? Record.PrefferedName || Record.FirstName + ' ' + Record.LastName
    : localStorage.getItem(`fNotesFor`);

  return (
    <div
      id="mydiv"
      style={{
        display: showNotes ? 'block' : 'none',
      }}
    >
      <div className="container-box">
        <div id="mydivheader">
          <span className="mr-2">{fNotesFor} - Note</span>
          {showNotes && (
            <Stopwatch TimerAction={TimerAction} ID={ID} CurrentClientId={CurrentClientId} />
          )}
        </div>
        <TextareaAutosize
          type="text"
          tabindex="0"
          rows="2"
          placeholder="Take a note..."
          className="textarea-type"
          value={notesText}
          onChange={handleChange}
          style={{
            borderRadius: '4px',
            border: '1px solid #DEDEDE',
            display: hideTextarea ? 'none' : 'block',
            maxHeight: '400px',
            overflow: 'auto',
          }}
          // onBlur={() => {
          //   localStorage.setItem(`fNotesText`, notesText);
          // }}
        />
        <div className="info mt-1">
          <span className="text">This will be saved in appointment notes </span>
          <img
            src={`images/icons/note-${hideTextarea ? 'open' : 'close'}.svg`}
            onClick={() => {
              setHideTextarea(hideTextarea ? false : true);
              // localStorage.setItem(`hideTextarea`, hideTextarea);
            }}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
      {isTimerDrawer && (
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title">Add Appointment</span>
            </div>
          }
          placement="right"
          open={isTimerDrawer}
          width={700}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={onCloseDrawer}
        >
          <CallEndedForm
            StudentTimerId={timerData.StudentTimerId}
            DurationInSec={timerData.DurationInSec}
            onClose={onCloseDrawer}
            isTimerAppointment={true}
            CurrentClientId={CurrentClientId}
            StudentId={ID}
          />
        </Drawer>
      )}
    </div>
  );
};

export default StickyNotes;
