import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Tooltip } from 'antd';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxDollarInput,
} from '../../../component/custom-redux-components';
import {
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
  listCombos,
  centerConsumableGetUpsert,
} from '../../../store/actions';
import CustomModal from '../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import AntdModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { ProcessCombos } from '../../../store/comboConfig';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../../../app-config';
import PersistTableSort from '../../../component/persistTableSort';
import validate, {
  dateofbirthNormalize,
  normalizeToYYYYMMDD,
  MobileFormatter,
  MobileNormalize,
} from '../../commonValidations';

const ServiceCodeForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    disable,
    ServiceCodeId,
    onCancel,
    UnitOfMeasure,
    ConCategory,
    dispatch,
    initialValues,
    // isFetchingGetServiceCode,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      {/* <Loader2 loading={isFetchingGetServiceCode} /> */}
      <div class="modal-header flex-wrap border-bottom-0 ">
        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        <h4 class="modal-title col-12 text-left px-0">
          {initialValues && initialValues.CenterConsumableId ? `EDIT` : `ADD NEW`} MATERIAL
        </h4>
      </div>
      <div class="modal-body rcm-panel enrollment-form">
        <div class="row mb-4">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name={`CategoryId`}
                type="text"
                component={ReduxSelect}
                validate={validate.required}
                fieldName={'Material Category'}
                disabled={disable}
                options={ConCategory}
                placeholder="Material Category*"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name={`ItemName`}
                type="text"
                validate={validate.required}
                component={ReduxInput}
                fieldName={'Item Name'}
              />
              <label class="placeholder-label">Item Name*</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name={`ItemCode`}
                type="text"
                // validate={validate.required}
                component={ReduxInput}
                fieldName={'Item Code'}
              />
              <label class="placeholder-label">Item Code</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name={`UnitOfMeasure`}
                type="text"
                component={ReduxSelect}
                validate={validate.required}
                fieldName={'Unit of Measure'}
                disabled={disable}
                options={UnitOfMeasure}
                placeholder="Unit of Measure*"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <Field
                name={`Price`}
                type="number"
                validate={validate.required}
                // component={ReduxInput}
                component={ReduxDollarInput}
                fieldName={'Price'}
                className="numbers-only"
                dollarPlaceHolder={'Enter Price*'}
              />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-12 col-lg-12 text-center">
            <input
              type="submit"
              class="btn px-5"
              value={initialValues && initialValues.CenterConsumableId ? ` UPDATE` : `ADD`}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
const mapStateToProps = state => {
  const { combos } = state;
  let UnitOfMeasure = [];
  let ConCategory=[];
  if (combos.data && combos.data['UnitOfMeasure']) {
    UnitOfMeasure = combos.data['UnitOfMeasure'] || [];
  }
   if (combos.data && combos.data['ConsumableCategory']) {
     ConCategory = combos.data['ConsumableCategory'] || [];
   }
  return {
    UnitOfMeasure,
    ConCategory,
  };
};
const ReduxServiceCodeForm = reduxForm({
  form: 'serviceCodeForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(ServiceCodeForm)));

export default ReduxServiceCodeForm;