import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getStudent } from '../../../store/actions';
import AugmentedSummary from '../AugmentedSummary';
import RiskChart from '../RiskChart';
import ClientScreeningCard from '../ClientScreeningCard';
import UpdateHistory from '../UpdateHistory';
import { CommonLicenceScreens } from '../../licenceScreens';
import TimeTravel from '../../timelineComponent';
import PageTitle from '../../../Components/PageTitle';
class IntakeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // getIntakeId() {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   var studentId = param.get('studentId') || param.get('studentid');
  //   if (studentId) {
  //     studentId = parseInt(studentId);
  //   } else {
  //     studentId = -1;
  //   }
  //   return studentId;
  // }
  componentDidMount() {
    // if (this.getIntakeId() > 0) {
    //   this.props.dispatch(getStudent(this.getIntakeId()));
    // }
    // var result = ProcessCombos('ReferenceSource,Country,Filter_StateProvince');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
  }
  render() {
    const { PreItakeSurveyId, ClientId } = this.props;

    return (
      <React.Fragment>
        <PageTitle TitleText="Intake" />
        <div class="main students-page mb-5 pt-5 container-fluid">
          <div className="row">
            <div className="col-sm-12">
              {this.props.AugmentedAIStatusData && (
                <CommonLicenceScreens
                  StatusData={this.props.AugmentedAIStatusData}
                  moduleName={'AugmentedAI'}
                />
              )}

              <>
                {this.props.AugmentedAIStatusData &&
                  this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
                  this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
                  this.props.AugmentedAIStatusData.LicenseStatusId != 5 && (
                    <>
                      <AugmentedSummary ClientId={ClientId} SurveyId={PreItakeSurveyId} />
                    </>
                  )}
              </>
            </div>
            <div className="col-sm-12">
              <RiskChart ClientId={ClientId} SurveyId={PreItakeSurveyId} />
            </div>

            {/* <div className="col-sm-12">
              <ClientScreeningCard
                ClientId={ClientId}
                SurveyId={PreItakeSurveyId}
                history={this.props.history}
              />
            </div> */}
            {/* <div className="col-sm-12">
              <UpdateHistory ClientId={ClientId} SurveyId={PreItakeSurveyId} />
            </div> */}
            <div className="prospect-time-travel">
              <TimeTravel
                hideHeader={true}
                ProspectTimeline={true}
                StudentId={ClientId}
                SurveyId={PreItakeSurveyId}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { student_banner, user_feature_flag_get } = state;
  const { data, isFetching, error } = student_banner;
  const { data: fFData } = user_feature_flag_get;

  let PreItakeSurveyId = null;
  let AugmentedAIStatusData = null;

  if (data) {
    PreItakeSurveyId = data.PreItakeSurveyId;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }

  return {
    PreItakeSurveyId,
    AugmentedAIStatusData,
  };
};

export default connect(mapStateToProps)(IntakeDashboard);
