import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { listStudents, getClientCounselor, listCombos } from '../store/actions';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { currencyFormatter, numberFormat } from '../util/formatter';
import { ReduxSelect } from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import DynamicGrid from '../Components/DynamicGrid';


const CheckboxGroup = Checkbox.Group;

const columns = [
  {
    title: 'Profile',
    dataIndex: 'StudentImageUrl',
    key: 'StudentImageUrl',
    render: (text, record) => (
      <div class="student-list-pic">
        <img
          src={text}
          class="img-circle"
          height="40"
          width="40"
          style={{ borderRadius: '50%', objectFit: 'cover' }}
        />
      </div>
    ),
  },
  {
    title: 'Client Id',
    dataIndex: 'StudentCenterId',
    key: 'studentCenterId',
    sorter: (a, b) => {
      var v1 = parseInt(a.StudentCenterId.split('-')[0]);
      var v2 = parseInt(b.StudentCenterId.split('-')[0]);
      if (!v1) {
        return -1;
      } else if (!v2) {
        return 1;
      }

      if (v1 > v2) {
        return 1;
      } else if (v1 == v2) {
        return 0;
      } else {
        return -1;
      }
    },
  },
  {
    title: 'Name',
    dataIndex: 'FirstName',
    key: 'firstName',
    sorter: (a, b) => isComparer(a.LastName, b.LastName),
    sortDirections: ['descend', 'ascend'],
    render: (text, student) => (
      <>
        {/* <div class="student-list-pic">
          <img src={student.StudentImageUrl} alt="student" />
        </div> */}
        <div class="student-list-name">
          <b>{student.LastName}</b>,{' ' + student.FirstName}
        </div>
      </>
    ),
  },

  {
    title: 'Age',
    dataIndex: 'Age',
    key: 'age',
    sorter: (a, b) => isNumericComparer(a.Age, b.Age),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Gender',
    dataIndex: 'Gender',
    key: 'Gender',
    sorter: (a, b) => isComparer(a.Gender, b.Gender),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Counselor',
    dataIndex: 'Counselor',
    key: 'counselor',
    sorter: (a, b) => isComparer(a.Counselor, b.Counselor),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Admission Date',
    dataIndex: 'EnrollDate',
    key: 'EnrollDate',
    sorter: (a, b) => isDateComparer(a.EnrollDateForSort, b.EnrollDateForSort),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'SPB Rating',
    dataIndex: 'LadderScore',
    key: 'LadderScore',
    sorter: (a, b) => isNumericComparer(a.LadderScore, b.LadderScore),
    sortDirections: ['descend', 'ascend'],
  },
  {
    // title: 'Last Survey Completed Date',
    title: (
      <span>
        Last Survey <br /> Completed Date
      </span>
    ),
    dataIndex: 'LastCompletedDate',
    key: 'LastCompletedDate',
    sorter: (a, b) => isDateComparer(a.LastCompletedDateForSort, b.LastCompletedDateForSort),
    sortDirections: ['descend', 'ascend'],
  },
  // {
  //   title: 'PHQ9Score',
  //   dataIndex: 'PHQ9Score',
  //   key: 'PHQ9Score',
  //   sorter: (a, b) => isNumericComparer(a.PHQ9Score, b.PHQ9Score),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'GAD7Score',
  //   dataIndex: 'GAD7Score',
  //   key: 'GAD7Score',
  //   sorter: (a, b) => isNumericComparer(a.GAD7Score, b.GAD7Score),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'CSSRScale',
  //   dataIndex: 'CSSRScale',
  //   key: 'CSSRScale',
  //   sorter: (a, b) => isNumericComparer(a.CSSRScale, b.CSSRScale),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'PCLCScore',
  //   dataIndex: 'PCLCScore',
  //   key: 'PCLCScore',
  //   sorter: (a, b) => isNumericComparer(a.PCLCScore, b.PCLCScore),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Faith Score',
  //   dataIndex: 'SWBScore',
  //   key: 'SWBScore',
  //   sorter: (a, b) => isNumericComparer(a.SWBScore, b.SWBScore),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'ASI Score',
  //   dataIndex: 'ASIScore',
  //   key: 'ASIScore',
  //   sorter: (a, b) => isNumericComparer(a.ASIScore, b.ASIScore),
  //   sortDirections: ['descend', 'ascend'],
  // },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    sorter: (a, b) => isComparer(a.Status, b.Status),
    sortDirections: ['descend', 'ascend'],
  },
  // {
  //   title: 'Center',
  //   dataIndex: 'CenterName',
  //   key: 'CenterName',
  //   sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
  //   sortDirections: ['descend', 'ascend'],
  // },
];

const options = [
  { Value: null, Text: 'Please Select' },
  { Value: 'Enrolled', Text: 'All' },
  { Value: 'Graduated', Text: 'Graduated' },
  { Value: 'Discharged', Text: 'Discharged' },
  { Value: 'Active', Text: 'Active' },
  { Value: 'Intake', Text: 'Intake' },
];

const plainOptions = ['Graduated', 'Discharged', 'Enrolled', 'Intake'];

const plainOptionsTest = ['Graduated', 'Discharged', 'Enrolled', 'Intake'];
const defaultCheckedListTest = ['Enrolled', 'Intake'];

class FilterModal extends Component {
  constructor(props) {
    super(props);
    const {
      checked,
      startDate,
      endDate,
      filterCenterId,
      appliedFilter,
      FilterCounserlorId,
    } = props;
    this.state = {
      // checked: (appliedFilter && appliedFilter.Filter) || 'Enrolled,Intake',
      startDate: startDate || null,
      endDate: endDate || null,
      filterCenterId: (appliedFilter && appliedFilter.filterCenterId) || null,
      FilterCounserlorId: (appliedFilter && appliedFilter.FilterCounserlorId) || null,
      // checkedListTest: defaultCheckedListTest,
      checkedListTest: (appliedFilter && appliedFilter.Filter) || 'Enrolled,Intake',
      indeterminateTest: true,
      checkAllTest: false,
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  onChangeTest = checkedListTest => {
    this.setState({
      checkedListTest,
      indeterminateTest:
        !!checkedListTest.length && checkedListTest.length < plainOptionsTest.length,
      checkAllTest: checkedListTest.length === plainOptionsTest.length,
    });
  };

  onCheckAllChangeTest = e => {
    this.setState({
      checkedListTest: e.target.checked ? plainOptionsTest : [],
      indeterminateTest: false,
      checkAllTest: e.target.checked,
    });
  };

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  componentDidMount() {
    this.props.dispatch(
      getClientCounselor({
        CenterId: this.state.filterCenterId,
      }),
    );
  }
  handleCenterChange = filterCenterId => {
    if (filterCenterId) {
      this.props.dispatch(
        getClientCounselor({
          CenterId: filterCenterId,
        }),
      );
    }
    this.setState({ filterCenterId, FilterCounserlorId: '' });
  };
  handleCounsellorChange = FilterCounserlorId => {
    this.setState({ FilterCounserlorId });
  };
  resetState = () =>
    this.setState({
      // checked: 'Enrolled,Intake',
      checkedListTest: 'Enrolled,Intake',
      startDate: '',
      endDate: '',
      filterCenterId: '',
      FilterCounserlorId: '',
      checkAllTest: false,
    });
  handelCheckbox = checkedValues => {
    let filter = checkedValues.toString();
    // this.setState({ checked: filter });
    this.setState({ checkedListTest: filter });
  };
  render() {
    const { onOk, onCancel, Centers, Counsellors, refreshGrid, appliedFilter } = this.props;
    const {
      startDate,
      endDate,
      filterCenterId,
      // checked,
      checkedListTest,
      FilterCounserlorId,
    } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
      </>
    );
    const ExampleCustomInput2 = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">End date</label>
      </>
    );

    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0 filter-modal">
            <div class="col-12 text-left mb-3">
              <span className="filter-status">Status</span>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mr-2 mb-2 filter-check">
                  {/* <Radio.Group name="radiogroup" onChange={this.onChange} value={checked}>
                    <Radio value="Enrolled">All</Radio>
                    <Radio value="Graduated">Graduated</Radio>
                    <Radio value="Discharged">Discharged</Radio>
                    <Radio value="Active">Active</Radio>
                    <Radio value="Intake">Intake</Radio>
                  </Radio.Group> */}
                  <div class="form-group">
                    <div className="site-checkbox-all-wrapper">
                      {/* <Checkbox
                        indeterminate={this.state.indeterminateTest}
                        onChange={this.onCheckAllChangeTest}
                        checked={this.state.checkAllTest}
                      >
                        Check all
                      </Checkbox> */}
                    </div>
                    <Checkbox
                      indeterminate={this.state.indeterminateTest}
                      onChange={this.onCheckAllChangeTest}
                      checked={this.state.checkAllTest}
                    >
                      All
                    </Checkbox>
                    <CheckboxGroup
                      options={plainOptionsTest}
                      value={this.state.checkedListTest}
                      onChange={this.onChangeTest}
                    />
                    {/* <Checkbox.Group
                      value={checked.split(',')}
                      className="filter-check"
                      options={[
                        { label: 'All', value: '' },
                        { label: 'Graduated', value: 'Graduated' },
                        { label: 'Discharged', value: 'Discharged' },
                        { label: 'Enrolled', value: 'Enrolled' },
                        { label: 'Intake', value: 'Intake' },
                      ]}
                      defaultValue={['Enrolled,Intake']}
                      onChange={this.handelCheckbox}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <span className="filter-status">Date</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => this.handleChange('startDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => this.handleChange('endDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput2 />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="row filter-modal pb-5">
            <div className="col-12 col-md-6">
              <div class="col-12 text-left mb-3">
                <span className="filter-status"> Centers</span>
              </div>
              <div class="col-12 enrollment-form">
                <div class="form-group">
                  <ReduxSelect
                    options={Centers}
                    // onChange={this.handleCenterChange}
                    onChange={centerId => this.handleCenterChange(centerId)}
                    className="basic-single"
                    classNamePrefix="select"
                    value={filterCenterId}
                    placeholder="Please Select Center"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div class="col-12 text-left mb-3">
                <span className="filter-status"> Counselor</span>
              </div>
              <div class="col-12 enrollment-form">
                <div class="form-group">
                  <ReduxSelect
                    options={Counsellors}
                    onChange={this.handleCounsellorChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={FilterCounserlorId}
                    placeholder="Please Select Counselor"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-5 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              this.resetState();
            }}
          >
            Reset
          </a>
          <button
            type="button"
            class="btn"
            onClick={() => {
              onOk();
              refreshGrid(checkedListTest, startDate, endDate, filterCenterId, FilterCounserlorId);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToPropsFilter = ({ get_client_counselor }) => {
  var { data, isFetching, error } = get_client_counselor;
  var Counsellors = [];

  if (data && data.data) {
    let CounsellorData = JSON.parse(data.data);
    Counsellors =
      CounsellorData &&
      CounsellorData.map(item => {
        return {
          Value: item.StaffId,
          Text: item.StaffName,
        };
      });
    Counsellors.unshift({ Value: null, Text: 'Please Select' });
  }
  return {
    Counsellors: Counsellors,
  };
};
FilterModal = connect(mapStateToPropsFilter)(FilterModal);

class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
      filterType: this.getFilterType(),
      students: [],
      startDate: null,
      endDate: null,
      filterCenterId: null,
      FilterCounserlorId: null,
    };
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getFilterType = () => {
    const { history } = this.props;
    var param = new URLSearchParams(history.location.search);
    return param.get('Filter') || param.get('filter');
  };

  componentDidMount(
    { searchStr, startDate, endDate, filterCenterId, FilterCounserlorId } = this.state,
  ) {
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );
    const { dispatch } = this.props;
    this.state.filterType = this.getFilterType();
    let appliedFilter = sessionStorage.getItem('appliedFilter');
    appliedFilter = JSON.parse(appliedFilter);
    if (appliedFilter && appliedFilter.Filter) {
      dispatch(
        listStudents({
          Filter: appliedFilter.Filter,
          // Filter: this.state.filterType,
          searchStr: searchStr,
          startDate: appliedFilter.startDate,
          endDate: appliedFilter.endDate,
          filterCenterId: appliedFilter.filterCenterId,
          FilterCounserlorId: appliedFilter.FilterCounserlorId,
        }),
      );
    } else {
      dispatch(
        listStudents({
          Filter: this.state.filterType || 'Enrolled,Intake',
          searchStr: searchStr,
          startDate,
          endDate,
          filterCenterId,
          FilterCounserlorId,
        }),
      );
    }

    // console.log('appliedFilter: ', JSON.parse(appliedFilter));
  }

  componentWillReceiveProps({
    history: {
      location: { search },
    },
    students,
  }) {
    if (students && JSON.stringify(this.props.students) != JSON.stringify(students)) {
      this.setState({ students });
    }
  }

  refreshGrid = (filterType, startDate, endDate, filterCenterId, FilterCounserlorId) => {
    const { searchStr } = this.state;
    this.setState({
      // checked: filterType,
      checkedListTest: filterType,
      startDate,
      endDate,
      filterCenterId,
      FilterCounserlorId,
    });
    this.props.dispatch(
      listStudents({
        Filter: filterType ? filterType.toString() : null,
        searchStr: searchStr,
        startDate,
        endDate,
        filterCenterId,
        FilterCounserlorId,
      }),
    );
    sessionStorage.setItem(
      'appliedFilter',
      JSON.stringify({
        Filter: filterType && filterType.toString(),
        searchStr: searchStr,
        startDate,
        endDate,
        filterCenterId,
        FilterCounserlorId,
      }),
    );
  };

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  onRowClick(studentId) {
    const { history } = this.props;
    history.push('/studentProfile/' + studentId);
  }

  startDateChange = date => {
    this.state.startDate = date;
    this.refreshGrid();
  };

  endDateChange = date => {
    this.state.endDate = date;
    this.refreshGrid();
  };

  handleFilterChange = filterType => {
    this.state.filterType = filterType;
    this.refreshGrid();
    this.props.history.push(`/clients?Filter=${filterType}`);
  };

  handleCenterChange = filterCenterId => {
    this.state.filterCenterId = filterCenterId;
    this.refreshGrid();
  };

  render() {
    const { isFetching, Centers } = this.props;
    const { students, checkedListTest, startDate, endDate, filterCenterId } = this.state;
    const FilterModalData = { checkedListTest, startDate, endDate, filterCenterId };
    return (
      <div class="container-fluid">
        {/* <Loader loading={isFetching} /> */}
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h3 class="page-title mb-3 mb-md-0">Client Details</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-9 text-md-right d-flex justify-content-end">
              {/* <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search..."
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    <i class="fa fa-search" />
                  </button>
                </div>
              </div> */}
              {/* <CustomModal
                ModalButton={({ onClick }) => (
                  <input
                    type="button"
                    class="btn btn-outline-primary px-5"
                    value="Filter"
                    onClick={onClick}
                  />
                )}
                ModalContent={props => (
                  <FilterModal
                    {...props}
                    {...FilterModalData}
                    refreshGrid={this.refreshGrid}
                    Centers={Centers}
                    appliedFilter={JSON.parse(sessionStorage.getItem('appliedFilter'))}
                  />
                )}
              /> */}

              {/* <button
                onClick={() =>
                  this.props.history.push(
                    '/newTemplate?studentId=' + this.getStudentId() + '&contractTemplateId=null',
                  )
                }
                class="btn"
                title="New Template"
              >
                New Template
              </button> */}
            </div>
          </div>
          <div />
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 " style={{ backgroundColor: 'unset', boxShadow: 'none' }}>
                <div className="dynamic-grid">
                  <DynamicGrid ScreenName={'StudentDashboard'}   />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ students, combos }) => {
  var { data, isFetching, error } = students;
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }

  var Centers = [{ Value: null, Text: 'Please Select' }];

  if (combos.data) {
    if (combos.data.Center)
      for (var center of combos.data.Center) {
        Centers.push(center);
      }
  }

  return {
    students: data || [],
    isFetching,
    Centers: Centers,
  };
};

export default connect(mapStateToProps, null, null, {
  areStatesEqual: (next, prev) => {
    var isEqual = prev.students === next.students;
    var isCombosEqual = prev.combos === next.combos;
    return isEqual && isCombosEqual;
  },
})(Students);
