import React, { Component } from 'react';
import { shallowEqual, useDispatch, useSelector, connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import ProfileSection from './profileSecition';
import TabsSection from './tabsSection';

export class Index extends Component {
  // componentDidMount() {
  //   this.props.dispatch(
  //     listCombos({
  //       comboTypes: 'RelationShip,SupportGroup',
  //     }),
  //   );
  // }

  getSubscriberUserGUID = () => {
    if (window.location.href.includes('concernedPerson')) {
      let url = window.location.href;
      let uId = url.split('/')[url.split('/').length - 1];
      return uId;
    } else {
      return this.props.history.location.search.split('=')[1];
    }
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row top-table-search mt-2 align-items-center">
            <div className="col-12 col-md-4 col-lg-4">
              <a className="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              {/* <h3 className="page-title mb-3 mb-md-0">Profile</h3> */}
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <ProfileSection
                {...this.props}
                getSubscriberUserGUID={this.getSubscriberUserGUID}
                isConcernedPerson={window.location.href.includes('concernedPerson')}
              />
            </div>
            <div className="col-9">
              <TabsSection
                {...this.props}
                getSubscriberUserGUID={this.getSubscriberUserGUID}
                isConcernedPerson={window.location.href.includes('concernedPerson')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    get_app_subscribers_profile,
    load_user_engagement,
    load_support_group,
    load_peer,
    get_app_subscribers_chat,
    combos,
  } = state;
  var { isFetching, data: appSubscriberData } = get_app_subscribers_profile;
  var { isFetching: userEngagementIsFetching, data: userEngagementData } = load_user_engagement;
  var { isFetching: loadSupportGroupIsFetching, data: loadSupportGroupData } = load_support_group;
  var { data: loadPeerData } = load_peer;
  var {
    isFetching: appSubscribersChatIsFetching,
    data: appSubscribersChatData,
  } = get_app_subscribers_chat;
  var relationOptions = [];
  var supportGroup = [];

  const selector = formValueSelector('SupportGroupMemberForm');

  if (combos.data) {
    if (combos.data.RelationShip) {
      relationOptions = combos.data.RelationShip;
    }
    if (combos.data.SupportGroup) {
      supportGroup = combos.data.SupportGroup;
    }
  }
  let memberType = selector(state, 'memberType');

  return {
    isFetching,
    appSubscriberData: appSubscriberData || {},
    userEngagementIsFetching,
    userEngagementData:
      (userEngagementData && userEngagementData.data && JSON.parse(userEngagementData.data)) || {},
    loadSupportGroupIsFetching,
    loadSupportGroupData:
      (loadSupportGroupData &&
        loadSupportGroupData.data &&
        JSON.parse(loadSupportGroupData.data)) ||
      {},
    appSubscribersChatData: (appSubscribersChatData && appSubscribersChatData.ChatList) || [],
    appSubscribersChatIsFetching,
    relationOptions,
    supportGroup,
    memberType,
    loadPeerData: (loadPeerData && loadPeerData.data && JSON.parse(loadPeerData.data)) || {},
  };
};

export default connect(mapStateToProps)(Index);
