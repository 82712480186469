import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { v4 as uuid } from 'uuid';
import { Drawer } from 'antd';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { GPT3AnswerStatusUpsert, augmentedSummaryGet } from '../../../../store/actions';
import AIAugmentedSummary from '../../../customScreens/surveyResponse/AIAugmentedSummary';
import DictationComponent from '../../../../Components/DictationComponent';
import TextareaAutosize from 'react-autosize-textarea';
import SubmitButton from '../../../../Components/submitButton';
import Axios from 'axios';
import { AILoader2 } from '../../../../Components/Loader';

const AugmentedSummary = props => {
  const dispatch = useDispatch();
  const [isAIDrawerOpen, setIsAIDrawerOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedNotes, setEditedNotes] = useState(null);

  const { clientId } = props;
  const { augmentedSummaryHistory, augmentedSummary, isUpsert, isFetching } = useSelector(
    ({
      augmented_summary_get: { data: AugmentedSummaryData, isFetching, error },
      gpt3_answer_status_upsert: { data: upserData, isFetching: isFetchingUpsert },
    }) => {
      // const { data: upserData, isFetching: isFetchingUpsert } = gpt3_answer_status_upsert;

      return {
        augmentedSummary:
          (AugmentedSummaryData &&
            AugmentedSummaryData.Summary &&
            AugmentedSummaryData.Summary[0]) ||
          {},
        augmentedSummaryHistory: AugmentedSummaryData && AugmentedSummaryData.History,
        isFetching: isFetching,
        error: error,
        isUpsert: upserData && upserData.success,
      };
    },
    shallowEqual,
  );
  const getAugmentedSummary = () => {
    dispatch(
      augmentedSummaryGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: clientId,
            },
          ],
        }),
      }),
    );
  };
  useEffect(() => {
    if (isUpsert) {
      getAugmentedSummary();
    }
  }, [isUpsert]);

  useEffect(() => {
    if (clientId) {
      getAugmentedSummary();
    }
  }, [clientId]);

  const toggleDrawer = () => {
    setIsAIDrawerOpen(!isAIDrawerOpen);
  };
  const actionTrigered = () => {
    getAugmentedSummary();
    toggleDrawer();
  };

  const handleDictate = (result, index) => {
    if (result) {
      let data;
      if (editedNotes) {
        data = `${editedNotes} ${result}`;
      } else {
        data = result;
      }
      setEditedNotes(data);
    }
  };
  const handleAgree = (GPT3QuestionId, GPT3QuestionAnswerId, GPT3QuestionAnswerIds) => {
    let data = {
      Json: JSON.stringify({
        GPT3: [
          {
            SurveyId: props.SurveyId,
            SurveyIds: props.SurveyIds,
            GPT3QuestionAnswerId: GPT3QuestionAnswerId,
            IsSummary: true,
            GPT3QuestionId: GPT3QuestionId,
            IsAgreed: true,
          },
        ],
        StudentId: clientId,
        UserGuid: props.UserGuid,
        SurveyIds: props.SurveyId,
        SurveyIds: props.SurveyIds,
        GPT3QuestionAnswerIds: GPT3QuestionAnswerIds,
      }),
    };
    dispatch(GPT3AnswerStatusUpsert(data));
  };

  const {
    Answer,
    GPTAugmentationId,
    AllowRegenration,
    LastUpdatedOn,
    IsAgreed,
    DateRange,
    GPT3QuestionId,
    GPT3QuestionAnswerId,
  } = augmentedSummary;

  useEffect(() => {
    setEditedNotes(Answer);
  }, [Answer]);
  const onChangeNote = event => {
    const { value } = event.target;
    setEditedNotes(value);
  };

  const saveSummary = (GPT3QuestionId, GPT3QuestionAnswerId, GPT3QuestionAnswerIds) => {
    let data = {
      Json: JSON.stringify({
        StudentId: props.ClientId,
        UserGuid: props.UserGuid,
        SurveyIds: props.SurveyId,
        SurveyIds: props.SurveyIds,
        GPT3QuestionAnswerIds: GPT3QuestionAnswerIds,
        GPT3: [
          {
            SurveyIds: props.SurveyId,
            SurveyIds: props.SurveyIds,
            GPT3QuestionAnswerId: GPT3QuestionAnswerId,
            GPT3QuestionId: GPT3QuestionId,
            IsAgreed: true,
            EditedAnswer: editedNotes,
          },
        ],
      }),
    };
    Axios.post('Survey/GPT3AnswerUpsert', data)
      .then(response => {
        if (response.data.success) {
          // this.setState({
          //   GPT3Response: response.data.data,
          // });
          // this.getLatestData();
          // this.props.isActionTrigered();
          getAugmentedSummary();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    setEditMode(false);
  };
  return (
    <div className="rcm-augmented-summary">
      {/* <AILoader2 loading={true} /> */}
      <div className="row">
        {/* {augmentedSummary && (
          <div className="col-12">
            <div className="p-5 d-flex align-items-center flex-column">
              <img src="images/icons/survey.svg" />
              <h6 className="mt-2">Oops!</h6>
              <span>No surveys assigned or completed</span>
            </div>
          </div>
        )} */}
        <div className="col-12">
          {augmentedSummary ? (
            <div className="ai-summary">
              {/* <div className="ai-summary-action">
                <span className="title mr-2">
                  {(augmentedSummary && augmentedSummary.ShortQuestion) ||
                    'Augmented Client Summary'}
                </span>
                <button
                  title="Augment with AI"
                  class="ai-btn"
                  onClick={toggleDrawer}
                  style={{
                    display: 'inline',
                    padding: '5px 20px',
                  }}
                >
                  <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                    <img src="images/AIStar.svg" alt="" width="23" />
                  </figure>
                  Augment with AI
                </button>
              </div>
              {augmentedSummary && (
                <div className="ai-summary-text">
                  <span>{augmentedSummary.Answer && renderHTML(augmentedSummary.Answer)}</span>
                </div>
              )} */}
              {editMode ? (
                <div className="col-12 p-0">
                  <span className="editing">Editing Now</span>
                  <div class="print-textarea-component">
                    <TextareaAutosize
                      style={{ height: '250px', width: '100%' }}
                      value={editedNotes}
                      id="question"
                      name="question"
                      rows="4"
                      placeholder="Enter Answer"
                      className="textarea-type"
                      onChange={event => {
                        onChangeNote(event);
                      }}
                    />
                    <div
                      className="dictation-space d-flex justify-content-end ai-action p-0"
                      style={{ paddingRight: '10px' }}
                    >
                      <DictationComponent
                        handleDictate={event => {
                          handleDictate(event);
                        }}
                        // getDicatationStatus={this.getDicatationStatus}
                      />
                      <button
                        style={{
                          marginLeft: '10px',
                          marginRight: '8px',
                          cursor: 'pointer',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          background: 'transparent',
                          border: '2px solid #ff7875',
                          color: 'rgb(191, 191, 191)',
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: '4px',
                        }}
                        onClick={() => {
                          setEditedNotes('');
                        }}
                      >
                        <img src="images/clear_icon.svg" />
                        <p
                          style={{
                            marginBottom: '0',
                            marginLeft: '7px',
                            color: '#ff7875',
                          }}
                        >
                          Clear
                        </p>
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          setEditMode(false);
                        }}
                      >
                        Cancel
                      </button>
                      <SubmitButton
                        type="button"
                        className="btn ml-2"
                        // disabled={this.state[index + 'value'].trim().length < 1}
                        onClick={() => {
                          saveSummary(GPT3QuestionId, GPT3QuestionAnswerId);
                        }}
                        // loading={isAugmentFetching}
                        style={{ height: '42px' }}
                        value={'Save & Agree'}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="ai-summary-action">
                    <span className="title mr-2">
                      {(augmentedSummary && augmentedSummary.ShortQuestion) ||
                        'Augmented Client Summary'}
                    </span>
                    <button
                      title="Augment with AI"
                      class="ai-btn"
                      onClick={toggleDrawer}
                      style={{
                        display: 'inline',
                        padding: '5px 20px',
                      }}
                    >
                      <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                        <img src="images/AIStar.svg" alt="" width="23" />
                      </figure>
                      Augment with AI
                    </button>
                  </div>
                  {augmentedSummary && (
                    <div className="ai-summary-text">
                      <span>{Answer && renderHTML(Answer)}</span>
                    </div>
                  )}
                </>
              )}
              {!editMode && IsAgreed === null && (
                <div className="d-flex col-12 justify-content-end p-0">
                  <button
                    type="button"
                    class="btn d-flex justify-content-center align-items-center mr-3"
                    style={{
                      border: '1px solid #00DC31',
                      color: '#00DC31',
                      background: '#fff',
                    }}
                    onClick={() => handleAgree(GPT3QuestionId, GPT3QuestionAnswerId)}
                  >
                    <img
                      src="images/outline_like.svg"
                      style={{
                        marginBottom: '3px',
                        marginRight: '2px',
                      }}
                    />
                    Agree
                  </button>
                  <button
                    type="button"
                    class="btn"
                    style={{
                      border: '1px solid #FF2C2C',
                      color: '#FF2C2C',
                      background: '#fff',
                    }}
                    onClick={() => {
                      setEditMode(true);
                      setEditedNotes(Answer);
                    }}
                  >
                    <i class="fas fa-thumbs-down" style={{ paddingRight: 5 }}></i>
                    Disagree
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '50px',
              }}
            >
              <h6>Let’s get started with AI</h6>
              <span className="mb-2">
                Augment the client inputs to view the summary about the client
              </span>
              <button
                title="Augment with AI"
                class="ai-btn"
                onClick={toggleDrawer}
                style={{
                  display: 'inline',
                  padding: '5px 20px',
                }}
              >
                <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                  <img src="images/AIStar.svg" alt="" width="23" />
                </figure>
                Augment with AI
              </button>
            </div>
          )}
        </div>
        <div className="col-12">
          {augmentedSummaryHistory && (
            <div className="ai-summary-timeline">
              <div className="ai-timeline-action mb-2">
                <span className="title mr-2">AI Summary Timeline</span>
                <span></span>
              </div>
              <div
                className="update-history-card"
                style={{ maxHeight: '1000px', overflowY: 'auto', overflowX: 'hidden' }}
              >
                {augmentedSummaryHistory.map(summary => {
                  const {
                    Answer,
                    ShortQuestion,
                    UpdatedBy,
                    UpdatedOn,
                    ProfileImageURL,
                    Question,
                    UpdatedInfo,
                    UpdatedDate,
                    UpdatedTime,
                  } = summary;
                  return (
                    <div className="box" key={uuid()}>
                      <div key={uuid()} className={`row`}>
                        <div className="col-md-2">
                          <div className="d-flex flex-column">
                            <span className="date">{UpdatedDate}</span>
                            <span className="time">{UpdatedTime}</span>
                          </div>
                        </div>
                        <div className="col-md-1 px-0 d-flex justify-content-center">
                          <div className="icon">
                            <img src={`images/AIStarWBG.svg`} width="54" />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="gpt-answers">
                            <div className="ai-block pt-1">
                              <span className="ai-question">{ShortQuestion || Question}</span>
                              <span className="ai-agreed">
                                <i class="fas fa-thumbs-up" style={{ paddingRight: 5 }}></i>
                                Agreed
                              </span>
                              <span className="ai-updated-info">{UpdatedInfo}</span>
                              <p className="ai-answer">{renderHTML(Answer)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {isAIDrawerOpen && (
        <Drawer
          className="custom-drawer client-augment"
          title="Augment with AI"
          placement="right"
          open={isAIDrawerOpen}
          width={800}
          bodyStyle={{ paddingBottom: 80, background: '#fff' }}
          closable={true}
          destroyOnClose={true}
          onClose={() => {
            toggleDrawer();
          }}
        >
          <AIAugmentedSummary
            ClientId={clientId}
            toggleDrawer={toggleDrawer}
            isActionTrigered={actionTrigered}
            isSurveyBanner={true}
          />
        </Drawer>
      )}
    </div>
  );
};

const mapStateToProps = ({ user_feature_flag_get }) => {
  const { data: fFData } = user_feature_flag_get;
  let AugmentedAIStatusData = null;

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  return {
    AugmentedAIStatusData,
  };
};

export default connect()(AugmentedSummary);
