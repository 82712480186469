import React, { Component } from 'react'
import AutorizationList from './autorizationList';

export default class Authorizations extends Component {
  render() {
    return (
      <div>
        <AutorizationList />
      </div>
    );
  }
}
