import React, { useState, useEffect } from 'react';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = false;
mic.interimResults = true;
mic.lang = 'en-US';

function SpeechToText({ handleDictate }) {
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    try {
      if (isListening) {
        mic.start();
        mic.onend = () => {
          console.log('continue..');
          // mic.start();
          setIsListening(prevState => !prevState);
        };
      } else {
        mic.stop();
        mic.onend = () => {
          console.log('Stopped Mic on Click');
        };
      }
      mic.onstart = () => {
        console.log('Mics on');
      };

      mic.onresult = event => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('');
        const isFinal = Array.from(event.results)
          .map(result => result.isFinal)
          .join('');
        handleDictate && handleDictate(transcript, isFinal);
        mic.onerror = event => {
          console.log(event.error);
        };
      };
    } catch (e) {}
  };

  return (
    <>
      <div
        onClick={() => {
          setIsListening(prevState => !prevState);
        }}
        style={
          {
            // display: 'inline-block',
            // position: 'relative',
            // right: '62px',
          }
        }
      >
        {isListening ? (
          <div
          //   style={{ width: '50px', border: '0', padding: '0', borderRadius: '0' }}
          >
            <img
              src="./images/chatMicRecording.gif"
              style={{ height: '18px', cursor: 'pointer' }}
              alt="listening"
            />
          </div>
        ) : (
          <div
          // style={{
          //   border: '2px solid #46b4fe',
          //   borderRadius: '4px',
          //   padding: '3px 4px 0',
          //   width: '25px',
          // }}
          >
            <img className={`mic-icon`} src="./images/chatMic.svg" alt="Start Dictating" />
          </div>
        )}
      </div>
    </>
  );
}

export default SpeechToText;
