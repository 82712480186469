import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Button } from 'antd';
import renderHTML from 'react-render-html';
import TextareaAutosize from 'react-autosize-textarea';
import {
  preIntakeHistoryGet,
  preIntakeSurveyAnswersGet,
  addStudentNote,
  resetStudentNote,
} from '../../store/actions';
import ClientRiskChart from './ClientRiskChart';
import DictationComponent from '../../Components/DictationComponent';
import Loader, { Loader2 } from '../../Components/Loader';
class UpdateHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
    };
  }

  getLatestData = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
          },
        ],
      }),
    };
    this.props.dispatch(preIntakeHistoryGet(data));
  };
  componentDidMount() {
    this.getLatestData();
  }
  componentWillReceiveProps({ isNoteAdded }) {
    if (isNoteAdded && isNoteAdded != this.props.isNoteAdded) {
      this.getLatestData();
      this.setState({ note: '', showNoteBox: false });
      this.props.dispatch(resetStudentNote());
    }
  }
  handleDictate = result => {
    let data;
    if (result) {
      if (this.state.note) {
        data = `${this.state.note} ${result}`;
      } else {
        data = result;
      }
      this.setState({ note: data });
    }
  };
  addNote = () => {
    let validNote = this.state.note.replace(/[</>]/g, '');
    let data = {
      Json: JSON.stringify({
        StudentNote: [
          {
            IsProspectNote: false,
            IsIntakeNote: true,
            NoteTypeIds: null,
            StudentId: this.props.StudentId,
            IsConfidential: 0,
            Note: validNote,
          },
        ],
      }),
    };
    this.props.dispatch(addStudentNote(data));
  };
  render() {
    const {
      UpdateHistory,
      FeatureFlags: { enableDictation },
      isNoteFetching,
      isFetching,
    } = this.props;
    const { note, showNoteBox } = this.state;
    return (
      <div class="card border-0 update-history-card mb-3">
        <div class="card-body block">
          <Loader2 loading={isFetching} />
          <div className="add-pre-intake-note">
            <div className="add-note">
              <h6 className="title">Updates</h6>
              <div>
                {!showNoteBox && (
                  <button
                    className="btn py-1 px-4"
                    onClick={() => {
                      this.setState({
                        showNoteBox: true,
                      });
                    }}
                  >
                    Add Note
                  </button>
                )}
              </div>
            </div>
            {showNoteBox && (
              <div className="add-note-section enrollment-form">
                <div className="form-group" style={{ flexGrow: '1' }}>
                  <TextareaAutosize
                    style={{ height: '150px' }}
                    className={`form-control not-empty`}
                    value={note}
                    onChange={event => {
                      this.setState({ note: event.target.value });
                    }}
                    rows="3"
                    key="addANote"
                    placeholder="Add a note.."
                  />
                  {/* <label class="placeholder-label">Add a note..</label> */}
                </div>
                <div className="action">
                  {enableDictation && (
                    <div
                      className="dictation-space mb-0"
                      style={{ display: 'inline-flex', paddingRight: '5px' }}
                    >
                      <DictationComponent handleDictate={this.handleDictate} />
                    </div>
                  )}
                  <button
                    className="clear-btn"
                    onClick={() => {
                      this.setState({ note: '' });
                    }}
                  >
                    <img src="images/clear_icon.svg" />
                    <span>Clear</span>
                  </button>
                  <button
                    className="btn btn-outline-primary mr-2 py-1 px-4"
                    onClick={() => {
                      this.setState({
                        showNoteBox: false,
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <Button
                    loading={isNoteFetching}
                    type="primary"
                    className="btn save-button"
                    style={{ padding: '0 29px' }}
                    onClick={this.addNote}
                    disabled={!note.trim().length > 0}
                  >
                    Add Note
                  </Button>
                </div>
              </div>
            )}
          </div>
          {UpdateHistory &&
            UpdateHistory.map((history, index) => {
              const {
                UpdatedDate,
                UpdatedTime,
                ProfileImageUrl,
                PreIntakeNotes,
                Role,
                StaffName,
                IsSurvey,
                DateForSort,
                QuestionsCount,
                ClientRisk,
              } = history;
              return (
                <div className="box" key={uuid()}>
                  <div key={uuid()} className={`row align-items-center mb-3`}>
                    <div className="col-md-3 col-xl-1">
                      <div className="d-flex flex-column">
                        <span className="date">{UpdatedDate}</span>
                        <span className="time">{UpdatedTime}</span>
                      </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-center">
                      <div className="icon">
                        {IsSurvey ? (
                          <img src={`images/timeline/survey-forms_b.svg`} />
                        ) : (
                          <img src={`images/timeline/journal.svg`} />
                        )}
                      </div>
                    </div>
                    <div className="col-md-8 col-lg-10 event-details">
                      {IsSurvey ? (
                        <div className="screening-questions-answered-event">
                          <div className="note-event">
                            <div className="added-by">
                              <span className="added-text">Added by</span>
                              <img src={ProfileImageUrl} />
                              <div className="name-box">
                                <span className="name">{StaffName}</span>
                                <span className="role">{Role}</span>
                              </div>
                            </div>
                            <div className="event-note-box">
                              <h6> {PreIntakeNotes && renderHTML(PreIntakeNotes)}</h6>
                              <span className="added-text">Number of Questions Answered</span>
                              <span>{QuestionsCount}</span>
                            </div>
                          </div>
                          <div>
                            <ClientRiskChart
                              ClientId={this.props.ClientId}
                              DateForSort={DateForSort}
                              ClientRisk={(ClientRisk && ClientRisk.toString()) || '0'}
                              IsHistory={true}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="note-event">
                          <div className="added-by">
                            <span className="added-text">Updated By</span>
                            <img src={ProfileImageUrl} />
                            <div className="name-box">
                              <span className="name">{StaffName}</span>
                              <span className="role">{Role}</span>
                            </div>
                          </div>
                          <div className="event-note-box">
                            <span className="note-title">Note</span>
                            <span className="text">
                              {PreIntakeNotes && renderHTML(PreIntakeNotes)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { pre_intake_history_get, user_feature_flag_get, student_note_add } = state;
  const { data: HistoryData, isFetching } = pre_intake_history_get;
  const { data: fFData } = user_feature_flag_get;
  const { data: noteData, isFetching: isNoteFetching } = student_note_add;
  let UpdateHistory = null;
  let FeatureFlags = {};
  let isNoteAdded = false;
  if (HistoryData && HistoryData.PreIntakeHistory) {
    UpdateHistory = HistoryData.PreIntakeHistory;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
  }
  if (noteData && noteData.success) {
    isNoteAdded = true;
  }
  return {
    UpdateHistory,
    FeatureFlags,
    isNoteFetching,
    isNoteAdded,
    isFetching,
  };
};

export default connect(mapStateToProps)(UpdateHistory);
