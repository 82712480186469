import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Checkbox, Radio, Modal } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';

var settings = {
  className: 'slider variable-width',
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  variableWidth: true,
  // nextArrow: <SampleNextArrow />,
  // prevArrow: <SamplePrevArrow />,
};

class BedsAvailable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: {
        active: { Text: 'All(12)', Value: 'All' },
      },
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  componentDidMount() {
    this.setState({ active: { Text: 'All(12)', Value: 'All' } });
  }
  render() {
    const { onCancel } = this.props;
    const filters = [
      {
        Text: 'All(12)',
        Value: 'All',
      },
      {
        Text: 'PHP (4)',
        Value: 'PHP',
      },
      {
        Text: 'Detox (2)',
        Value: 'Detox',
      },
      {
        Text: 'Residential (3)',
        Value: 'Residential',
      },
      {
        Text: 'OP (3)',
        Value: 'OP',
      },
      {
        Text: 'PHP (4)',
        Value: 'PHP',
      },
      {
        Text: 'Detox (2)',
        Value: 'Detox',
      },
      {
        Text: 'Residential (3)',
        Value: 'Residential',
      },
      {
        Text: 'OP (3)',
        Value: 'OP',
      },
      {
        Text: 'PHP (4)',
        Value: 'PHP',
      },
      {
        Text: 'Detox (2)',
        Value: 'Detox',
      },
      {
        Text: 'Residential (3)',
        Value: 'Residential',
      },
    ];
    const bedsAvailabeColumns = [
      {
        title: 'Program',
        dataIndex: 'Program',
        key: 'Program',
        sorter: (a, b) => isComparer(a.Program, b.Program),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Number of Beds Availabe',
        dataIndex: 'NumberOfBedsAvailabe',
        key: 'NumberOfBedsAvailabe',
        sorter: (a, b) => isNumericComparer(a.NumberOfBedsAvailabe, b.NumberOfBedsAvailabe),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Upcoming Discharge',
        dataIndex: 'UpcomingDischarge',
        key: 'UpcomingDischarge',
        sorter: (a, b) => isDateComparer(a.UpcomingDischarge, b.UpcomingDischarge),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-start flex-wrap  border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 className="modal-title col-12">Beds Available</h4>
        </div>
        <div className="modal-body col-12 enrollment-form horizontal-slider">
          <div className="row">
            <div className="col-1 d-flex align-items-center pr-0">
              <img src="images/whiteLeftArrow.svg" onClick={this.previous} className="previous" />
            </div>
            <div className="col-10 px-0">
              <Slider ref={c => (this.slider = c)} {...settings}>
                {filters &&
                  filters.map((items, index) => {
                    return (
                      <li className="d-flex max-content" key={index}>
                        <span
                          className={
                            JSON.stringify(this.state.active) === JSON.stringify(items)
                              ? 'item-btn-active'
                              : 'item-btn'
                          }
                          onClick={event => {
                            if (this.props.SmallGroupGuid) {
                              let data = {
                                Broadcast: [
                                  {
                                    SmallGroupGuid: this.props.SmallGroupGuid,
                                    FilterBy: items.Value,
                                  },
                                ],
                              };
                              let searchData = {
                                Json: JSON.stringify(data),
                              };
                              //   this.props.dispatch(appSubscribersUserSearch(searchData));
                            } else {
                              let data = {
                                Broadcast: [{ FilterBy: items.Value }],
                              };
                              let searchData = {
                                Json: JSON.stringify(data),
                              };
                              //   this.props.dispatch(appSubscribersUserSearch(searchData));
                            }
                            this.setState({ active: items });
                          }}
                        >
                          {items.Text}
                        </span>
                      </li>
                    );
                  })}
              </Slider>
            </div>
            <div className="col-1 d-flex align-items-center px-0">
              <img onClick={this.next} src="images/whiteLeftArrow.svg" className="next" />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <Table dataSource={[]} columns={bedsAvailabeColumns} bordered pagination={false} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(BedsAvailable);
