import React, { Component } from 'react';
import { connect } from 'react-redux';

class AudioComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="">
          <div
            className="row"
            style={{
              background: '#4AB0FE',
              boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.06)',
            }}
          >
            <div
              className="col-md-8 mt-4"
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '30px',
                lineHeight: '41px',
                color: '#fff',
              }}
            >
              Exercitation veniam consequat
              <div
                className="d-flex justify-content-between "
                style={{ fontSize: '12px', width: '300px' }}
              >
                <div>
                  <span style={{ borderRadius: '4px', background: '#72C2FF', padding: '3px' }}>
                    {' '}
                    Telehealth
                  </span>
                </div>
                <div>25th March, 2019</div>
                <div>06:00 PM</div>
              </div>
            </div>
            <div
              className="col-md-4 mt-4 text-right"
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '30px',
                lineHeight: '41px',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '18px',
                  lineHeight: '41px',
                  color: '#000',
                  background: '#fff',
                  padding: '0px 10px',
                  margin: '5px',
                  display: 'inline-flex',
                  borderRadius: '5px',
                }}
                onClick={this.props.showDrawer}
              >
                {' '}
                <img src={`images/timeline/transcript.svg`} style={{ paddingRight: '15px' }} /> View
                Transcript
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect()(AudioComponent);
