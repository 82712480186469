import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Table } from 'antd';
import { getCallSessionVideoUrl } from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';
import { timeConvert } from '../../../util/time';

class TeleHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.EventJsonData.VMCall[0].VMCallId)
      this.props.dispatch(
        getCallSessionVideoUrl({
          VMCallId: this.props.EventJsonData.VMCall[0].VMCallId,
        }),
      );
  }
  render() {
    const { EventJsonData, VideoCallUrl, isFetching } = this.props;
    let { VMCallId, IsVideo, DurationSeconds } = EventJsonData.VMCall && EventJsonData.VMCall[0];
    return (
      <div class="border-0">
        {VMCallId ? (
          <div class="row">
            <Loader2 loading={!VideoCallUrl} />
            {/* {DurationSeconds && (
              <div className="desc mt-2">
                <span>Meeting Duration {timeConvert(DurationSeconds)}</span>
              </div>
            )} */}
            {VideoCallUrl.includes('https') ? (
              <video
                style={{ border: '3px solid #EEE' }}
                width="100%"
                height="auto"
                src={VideoCallUrl}
                autoplay="true"
                controls
                disablePictureInPicture="true"
                controlsList="nodownload"
              />
            ) : (
              <div className="text-center">
                <h5>{VideoCallUrl}</h5>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center">
            <h5>No Video Available</h5>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ get_call_session_video_url }) => {
  const { data, error, isFetching } = get_call_session_video_url;
  let VideoCallUrl = '';

  if (get_call_session_video_url && get_call_session_video_url.data) {
    VideoCallUrl = data.result;
  }
  return {
    VideoCallUrl,
    isFetching,
  };
};
export default connect(mapStateToProps)(TeleHealth);
