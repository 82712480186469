import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import renderHTML from 'react-render-html';
import { Tabs, Modal, Popover, Button, Dropdown, Space } from 'antd';
// import Summary from './intakeProfile';
import IntakeSurveys from './surveys';
import Banner from '../Banner';
import AntdModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import AssignSurveysModal from '../AssignSurveysModal';
import AddProspectNote from '../pre-intake/AddNoteModal';
import DocumentUpload from '../pre-intake/dashboard/DocumentUpload';
import { userFeatureFlagGet, rcmClientMerge, getStudent } from '../../../store/actions';
// import ProgramMatch from '../pre-intake/dashboard/ProgramMatch';
import ProspectTimeTravel from '../pre-intake/dashboard/ProspectTimeTravel';
import ProgramMatch from '../pre-intake/dashboard/ProgramMatch';
// import DocumentUpload from '../pre-intake/dashboard/DocumentUpload';
import Summary from '../pre-intake/dashboard/preintakeProfile';
import ViewAnswerTab from '../pre-intake/dashboard/ViewAnswerTab';
import Associations from '../pre-intake/dashboard/Associations';
const { TabPane } = Tabs;

class IntakeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabMapping: {
        0: 'summary',
        1: 'intake-survey',
        2: 'screening',
        3: 'time-travel',
        4: 'associations',
        5: 'document-upload',
        6: 'program-match',
      },
      tabLabels: [
        {
          Name: 'Summary',
          Component: props => <Summary {...props} viewHistory={this.viewHistory} />,
        },
        {
          Name: 'Surveys',
          Component: props => <IntakeSurveys {...props} />,
        },
        {
          Name: 'Screening',
          Component: props => <ViewAnswerTab {...props} viewHistory={this.viewHistory} />,
        },
        {
          Name: 'Time Travel',
          Component: props => <ProspectTimeTravel {...props} />,
        },
        {
          Name: 'Associations',
          Component: props => <Associations {...props} />,
        },
        {
          Name: 'Document Upload',
          Component: props => <DocumentUpload {...props} />,
        },
        // {
        //   Name: 'Surveys',
        //   Component: props => <IntakeSurveys {...props} />,
        // },
      ],
      activeKey: 0,
      activeTabs: [true, true],
      isNoteAddModal: false,
    };
  }
  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }
  onTabClick = key => {
    if (this.getIntakeId()) {
      let keyData = parseInt(key);
      let clickedTab = this.state.tabMapping[keyData];
      this.props.history.push(
        `/clients/intake/dashboard?tab=${clickedTab}&id=${this.getIntakeId()}`,
      );
      this.setState({
        activeKey: parseInt(key),
      });
    } else {
      return;
    }
  };
  viewHistory = (key, filterVal, filterText) => {
    // history.push(`/timetravel/?subscriberId=${UserGuid}&TTFilter=25&TTItem=0_Suspicious%Terms`);
    if (this.getIntakeId()) {
      let keyData = parseInt(key);
      let clickedTab = this.state.tabMapping[keyData];
      this.props.history.push(
        `/clients/intake/dashboard?tab=${clickedTab}&id=${this.getIntakeId()}&TTFilter=${filterVal}&TTItem=${filterText}`,
      );
      this.setState({
        activeKey: parseInt(key),
      });
    } else {
      return;
    }
  };
  backButtonImplimentation = nextProps => {
    let upComingTab = new URLSearchParams(nextProps.history.location.search);
    upComingTab = upComingTab.get('tab');
    let upComingTabIndex = Object.values(this.state.tabMapping).indexOf(upComingTab);
    let pastTabs = new URLSearchParams(this.props.location.search);
    pastTabs = pastTabs.get('tab');
    let pastTabsIndex = Object.values(this.state.tabMapping).indexOf(pastTabs);
    if (upComingTabIndex != pastTabsIndex) {
      this.setState({
        activeKey: upComingTabIndex,
      });
    }
  };
  componentDidMount(props) {
    const { tabMapping } = this.state;
    var params = new URLSearchParams(this.props.location.search);
    var tab = params.get('tab');
    if (tabMapping) {
      this.setState({
        activeKey: Object.values(tabMapping).indexOf(tab),
      });
    }
    if (this.getIntakeId() > 0) {
      this.props.dispatch(getStudent(this.getIntakeId()));
    }
  }
  componentWillReceiveProps(nextProps, state) {
    this.backButtonImplimentation(nextProps);
    if (
      JSON.stringify(nextProps.AugmentedAIStatusData) !=
      JSON.stringify(this.props.AugmentedAIStatusData)
    ) {
      if (nextProps.AugmentedAIStatusData && nextProps.AugmentedAIStatusData.StudentId == null) {
        this.props.dispatch(
          userFeatureFlagGet({
            JsonInput: JSON.stringify({ Center: { StudentId: this.getIntakeId() } }),
          }),
        );
      }
    }
    if (nextProps.rcmMergeSuccess && nextProps.rcmMergeSuccess != this.props.rcmMergeSuccess) {
      // this.props.dispatch(getStudent(this.getIntakeId()));
      this.toggleSuccess('Client enrolled to clinical workflow');
    }
  }
  toggleSuccess = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  moveToProspect = record => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            EnrollStatusId: 3,
          },
        ],
      }),
    };
    Axios.post('Student/StudentEnrollStatusUpdate', data)
      .then(response => {
        if (response.data.success) {
          this.toggleSuccess('Client moved from Intake to Prospect Successfully.');
          if (this.state.fetchLatestData) {
            this.state.fetchLatestData();
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  render() {
    const { activeKey, tabLabels, isNoteAddModal } = this.state;
    const {
      IsClientCopiedToRCM,
      clientCopyLinkVisibility,
      studentMatchedWithClient,
      ClientId,
      IsProspectUser,
      history,
      PreItakeSurveyId,
      IsClientUser,
      isClinicalCenter,
    } = this.props;
    const content = (
      <div
        className="sort-by-list"
        onClick={() => {
          this.setState({
            isVisible: false,
          });
        }}
      >
        <p
          onClick={() => {
            this.setState({
              isNoteAddModal: true,
            });
          }}
        >
          Add Note
        </p>
        <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#move-intake-model">
              Assign Survey
            </p>
          )}
          ModalContent={props => <AssignSurveysModal {...props} />}
        />
        {IsProspectUser && (
          <AntdModal
            ModalButton={({ onClick }) => (
              <p onClick={onClick} data-target="#move-intake-model">
                Move to Prospect
              </p>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div className="modal-content border-0">
                <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                  <h4 className="modal-title col-12 mb-3">
                    Are you sure you want to move to prospect?
                  </h4>
                </div>
                <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      onOk();
                      this.moveToProspect();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          />
        )}
      </div>
    );
    const enrollContent = (
      <div className="sort-by-list">
        <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-toggle="modal" data-target="#enterprise-workflow">
              Move to Enterprise
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div class="modal-content border-0">
              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <span
                  class="modal-title user-merging d-flex align-items-center col-12 mb-3"
                  style={{ fontSize: '22px' }}
                >
                  Are you sure you want to enroll this client into enterprise?
                  <br />
                </span>
              </div>
              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  class="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No
                </button>
                <button
                  class="btn"
                  onClick={() => {
                    onOk();
                    history.push(`/enrollment/?studentid=${this.getIntakeId()}`);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
        {!IsClientUser && isClinicalCenter && (
          <AntdModal
            ModalButton={({ onClick }) => (
              <p onClick={onClick} data-toggle="modal" data-target="#Copy-client-clinical-workflow">
                Move to Clinical Workflow
              </p>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div class="modal-content border-0">
                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                  <span
                    class="modal-title user-merging d-flex align-items-center col-12 mb-3"
                    style={{ fontSize: '22px' }}
                  >
                    Are you sure you want to enroll this client into clinical?
                    <br />
                  </span>
                </div>
                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    class="btn btn-outline-primary"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No
                  </button>
                  <button
                    class="btn"
                    onClick={() => {
                      onOk();
                      this.props.dispatch(
                        rcmClientMerge({
                          StudentId: this.getIntakeId(),
                          ClientId: null,
                        }),
                      );
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          />
        )}
      </div>
    );
    return (
      <div class="main enrollment-page mb-5">
        <div class="container-fluid">
          <div class="row top-table-search align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a class="back-link" onClick={() => history.push('/clients/intake')}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h5>Intake details</h5>
            </div>
            <div class="col-12 col-md-8 col-lg-8">
              <div className="d-flex align-items-center justify-content-end">
                <Popover
                  overlayClassName="alert-box-style"
                  content={content}
                  title={false}
                  trigger="hover"
                  placement="bottom"
                  class="alert-sort"
                  open={this.state.isVisible}
                  onOpenChange={visible => {
                    this.setState({
                      isVisible: visible,
                    });
                  }}
                >
                  <button
                    className="btn btn-outline-primary mr-2"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    More <img src="images/icons/3dot_blue.svg" className="mx-2" />
                  </button>
                </Popover>
                <Popover
                  overlayClassName="alert-box-style"
                  content={enrollContent}
                  title={false}
                  trigger="hover"
                  placement="bottom"
                  class="alert-sort"
                >
                  <button className="btn mr-2" style={{ display: 'flex', alignItems: 'center' }}>
                    Enroll Now <img src="images/icons/3dot_white.svg" className="mx-2" />
                  </button>
                </Popover>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 pt-2">
              <Banner
                ClientId={this.getIntakeId()}
                // EditActionUrl={`/pre-intake-form/?step=demographics&studentid=${this.getIntakeId()}`}
              />
            </div>
          </div>
          <div class="content-area">
            <div class="col-12 pb-5 vertical-tabs px-0">
              <Tabs
                activeKey={String(activeKey)}
                tabPosition="left"
                defaultActiveKey="0"
                onTabClick={this.onTabClick}
              >
                {tabLabels.map((item, index) => {
                  const { Component, Name } = item;
                  return (
                    <TabPane tab={Name} key={`${index}`} className="tab-content enrollment-form">
                      {activeKey == index && (
                        <Component
                          style={{ padding: '36px 0px' }}
                          ClientId={this.getIntakeId()}
                          PreItakeSurveyId={PreItakeSurveyId}
                        />
                      )}
                    </TabPane>
                  );
                })}
              </Tabs>
            </div>
          </div>
          {isNoteAddModal && (
            <Modal open={isNoteAddModal} footer={null} closable={false} destroyOnClose={true}>
              <AddProspectNote
                StudentId={this.getIntakeId()}
                onCancel={() => {
                  this.setState({
                    isNoteAddModal: false,
                  });
                }}
                NoteTypeIds={36} //36 for Intake Notes
                IsIntakeNote={true}
              />
            </Modal>
          )}
          {this.state.isModalOpen && (
            <CustomModal
              isOpen={this.state.isModalOpen}
              header={this.state.header}
              yesAction={() => {
                this.setState({
                  isModalOpen: false,
                  header: '',
                });
                history.replace(`/clients/intake`);
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  user_feature_flag_get,
  student_banner,
  student_get,
  rcm_client_merge,
  center_config_get,
}) => {
  const { data: fFData } = user_feature_flag_get;
  const { data, isFetching, error } = student_banner;
  const { data: studentInfo } = student_get;
  const { data: centerConfig } = center_config_get;

  let AugmentedAIStatusData = null,
    IsProspectUser = null,
    IsClientUser = null,
    PreItakeSurveyId = null,
    IsClientCopiedToRCM = false,
    clientCopyLinkVisibility = false,
    studentMatchedWithClient = null,
    ClientId = null,
    rcmMergeSuccess = false,
    isClinicalCenter = null;

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  if (centerConfig && centerConfig.CenterConfig) {
    isClinicalCenter =
      centerConfig.CenterConfig[0] && centerConfig.CenterConfig[0].IsClinicalCenter;
  }
  if (data && data.IsProspectUser) {
    IsProspectUser = data.IsProspectUser;
    PreItakeSurveyId = data.PreItakeSurveyId;
  }
  if (data && data.IsClient) {
    IsClientUser = data.IsClient;
  }
  if (studentInfo) {
    clientCopyLinkVisibility = studentInfo.IsClinicalWorkflow;
    IsClientCopiedToRCM = studentInfo.IsClient;
    studentMatchedWithClient = studentInfo.StudentMatchedWithClient;
    ClientId = studentInfo.ClientId;
  }
  if (rcm_client_merge && rcm_client_merge.data && rcm_client_merge.data.success) {
    rcmMergeSuccess = rcm_client_merge.data.success;
  }
  return {
    AugmentedAIStatusData,
    IsProspectUser,
    PreItakeSurveyId,
    IsClientCopiedToRCM,
    clientCopyLinkVisibility,
    studentMatchedWithClient,
    ClientId,
    rcmMergeSuccess,
    IsClientUser,
    isClinicalCenter,
  };
};

export default connect(mapStateToProps)(withRouter(IntakeDashboard));
