import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import CustomModal from '../../../core/modal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { isComparer, isDateComparer } from '../../../util/handler';
import AntdModal from '../../../Components/CustomModal';
import {
  regionUpsert,
  resetRegionUpsert,
  regionGet,
  regionDelete,
  resetRegionDelete,
} from '../../../store/actions';
import { ReduxSelect } from '../../../component/custom-redux-components';
import ListRenderer from '../../../Components/listRenderer';
import SubmitButton from '../../../Components/submitButton';
const activeKey = 0;

class Region extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: '',
      filterBy: 'Active',
    };
  }
  componentDidMount() {
    this.getRegionList(0);
  }
  componentWillReceiveProps({ isAdded, isDeleted }) {
    if (isAdded && isAdded != this.props.isAdded) {
      this.setState({ region: '' });
      this.toggle('Region Added Successfully');
      this.props.dispatch(resetRegionUpsert());
      this.getRegionList(0);
      this.setState({ filterBy: 'Active' });
    }
    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.toggle(`Region ${this.state.deleteAction} Successfully`);
      let type = this.state.deleteAction === 'archived' ? 0 : 1;
      this.getRegionList(type);
      this.props.dispatch(resetRegionDelete());
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  getRegionList = isArchived => {
    this.props.dispatch(
      regionGet({
        JsonInput: JSON.stringify({
          Region: [
            {
              RegionType: 'R',
              IsArchived: isArchived || 0,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { region, filterBy } = this.state;
    const { dataList } = this.props;
    const columns = [
      {
        title: 'Region',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <Tooltip placement="top" title="Archive">
                      <img src="images/archive.svg" width="25" />
                    </Tooltip>
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to archive this region?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            regionDelete({
                              JsonInput: JSON.stringify({
                                Region: [
                                  {
                                    RegionId: record.Value,
                                    IsArchived: 1,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'archived';
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    const archivedColumn = [
      {
        title: 'Region Archived',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="images/restore_icon.svg" width="85" />
                    {/* <i class="far fa-trash-alt"></i> */}
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to restore this region?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            regionDelete({
                              JsonInput: JSON.stringify({
                                Region: [
                                  {
                                    RegionId: record.Value,
                                    IsArchived: 0,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'restored';
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    return (
      <div>
        <div class="content-area  setting-gap pl-0">
          <div className="center-tabs p-4">
            <div class="row mb-2">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="enrollment-form">
                  <div class="form-group">
                    <input
                      className={`form-control ${region && 'not-empty'}`}
                      value={region}
                      onChange={e => this.setState({ region: e.target.value })}
                    />
                    <label className="placeholder-label">Region</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <SubmitButton
                  loading={this.props.isFetching}
                  onClick={() => {
                    this.props.dispatch(
                      regionUpsert({
                        JsonInput: JSON.stringify({
                          Region: [
                            {
                              RegionType: 'R',
                              Description: region.trim(),
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                  disabled={!region.trim()}
                  value={'Add'}
                  style={{ height: '45px', padding: '0 45px' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <div className="text-left app-subscriber-page d-inline-block">
                  <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                    <ReduxSelect
                      options={[
                        { Text: 'Active', Value: 'Active' },
                        { Text: 'Archived', Value: 'Archived' },
                      ]}
                      onChange={val => {
                        this.setState({
                          filterBy: val,
                        });
                        this.getRegionList(val === 'Active' ? 0 : 1);
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      value={filterBy}
                      placeholder="Filter By"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {filterBy === 'Active' ? (
              <div class="row">
                <div class="col-12 mb-5">
                  <div class="card border-0 ">
                    <div class="row card-body">
                      <div class="col-12">
                        {/* <Loader2 loading={isFetching} /> */}
                        <Table
                          columns={columns}
                          dataSource={dataList}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div class="dataTables_wrapper no-footer">
                                  <div class="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a class="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a class="paginate_button next">Next</a>
                                    ) : (
                                      <a class="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-12 mb-5">
                  <div class=" border-0 mt-5 mx-2 my-2">
                    <div class="row">
                      <div class="col-12">
                        {/* <Loader2 loading={isFetching} /> */}
                        <Table
                          columns={archivedColumn}
                          dataSource={dataList || []}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div class="dataTables_wrapper no-footer">
                                  <div class="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a class="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a class="paginate_button next">Next</a>
                                    ) : (
                                      <a class="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <CustomModal
              isOpen={this.state.isModelOpen}
              header={this.state.header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { region_insert, region_get, region_delete } = state;
  const { data: addRegionData, isFetching, error } = region_insert;
  const { data: listRegionData } = region_get;
  const { data: deleteRegionData } = region_delete;

  let isAdded = false;
  let isDeleted = false;
  let dataList = [];

  if (listRegionData && listRegionData.data) {
    dataList = listRegionData.data;
  }
  if (addRegionData && addRegionData.data) {
    debugger;
    isAdded = addRegionData.success;
  }
  if (deleteRegionData && deleteRegionData.data) {
    isDeleted = deleteRegionData.success;
  }

  return {
    dataList,
    isAdded,
    isDeleted,
    isFetching,
  };
};
const ReduxRegion = connect(mapStateToProps)(withRouter(Region));

class SubRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subRegion: '',
      filterBy: 'Active',
    };
  }
  componentDidMount() {
    this.getSubRegionList(0);
  }
  componentWillReceiveProps({ isAdded, isDeleted }) {
    if (isAdded && isAdded != this.props.isAdded) {
      debugger;
      this.setState({ subRegion: '' });
      this.toggle('Subregion Added Successfully');
      this.props.dispatch(resetRegionUpsert());
      this.getSubRegionList(0);
      this.setState({ filterBy: 'Active' });
    }
    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.toggle(`Subregion ${this.state.deleteAction} Successfully`);
      let type = this.state.deleteAction === 'archived' ? 0 : 1;
      this.getSubRegionList(type);
      this.props.dispatch(resetRegionDelete());
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  getSubRegionList = isArchived => {
    this.props.dispatch(
      regionGet({
        JsonInput: JSON.stringify({
          Region: [
            {
              RegionType: 'S',
              IsArchived: isArchived || 0,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { subRegion, filterBy } = this.state;
    const { dataList } = this.props;
    const columns = [
      {
        title: 'Subregion',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <Tooltip placement="top" title="Archive">
                      <img src="images/archive.svg" width="25" />
                    </Tooltip>
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to archive this subregion?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            regionDelete({
                              JsonInput: JSON.stringify({
                                Region: [
                                  {
                                    RegionId: record.Value,
                                    IsArchived: 1,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'archived';
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    const archivedColumn = [
      {
        title: 'SubRegion Archived',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="images/restore_icon.svg" width="85" />
                    {/* <i class="far fa-trash-alt"></i> */}
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to restore this subregion?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            regionDelete({
                              JsonInput: JSON.stringify({
                                Region: [
                                  {
                                    RegionId: record.Value,
                                    IsArchived: 0,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'restored';
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    return (
      <div>
        <div class="content-area setting-gap pl-0">
          <div className="center-tabs p-4">
            <div class="row mb-2">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="enrollment-form">
                  <div class="form-group">
                    <input
                      className={`form-control ${subRegion && 'not-empty'}`}
                      value={subRegion}
                      onChange={e => this.setState({ subRegion: e.target.value.trim() })}
                    />
                    <label className="placeholder-label">Subregion</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                {/* <button
                  type="button"
                  class="btn px-5"
                  disabled={!subRegion}
                  onClick={() => {
                    this.props.dispatch(
                      regionUpsert({
                        JsonInput: JSON.stringify({
                          Region: [
                            {
                              RegionType: 'S',
                              Description: subRegion,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Add
                </button> */}

                <SubmitButton
                  loading={this.props.isFetching}
                  onClick={() => {
                    this.props.dispatch(
                      regionUpsert({
                        JsonInput: JSON.stringify({
                          Region: [
                            {
                              RegionType: 'S',
                              Description: subRegion,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                  disabled={!subRegion}
                  value={'Add'}
                  style={{ height: '45px', padding: '0 45px' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <div className="text-left app-subscriber-page d-inline-block">
                  <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                    <ReduxSelect
                      options={[
                        { Text: 'Active', Value: 'Active' },
                        { Text: 'Archived', Value: 'Archived' },
                      ]}
                      onChange={val => {
                        this.setState({
                          filterBy: val,
                        });
                        this.getSubRegionList(val === 'Active' ? 0 : 1);
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      value={filterBy}
                      placeholder="Filter By"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {filterBy === 'Active' ? (
              <div class="row">
                <div class="col-12 mb-5">
                  <div class="card border-0 ">
                    <div class="row card-body">
                      <div class="col-12">
                        {/* <Loader2 loading={isFetching} /> */}
                        <Table
                          columns={columns}
                          dataSource={dataList}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div class="dataTables_wrapper no-footer">
                                  <div class="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a class="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a class="paginate_button next">Next</a>
                                    ) : (
                                      <a class="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="row">
                <div class="col-12 mb-5">
                  <div class="border-0 mt-5 mx-2 my-2">
                    <div class="row">
                      <div class="col-12">
                        {/* <Loader2 loading={isFetching} /> */}
                        <Table
                          columns={archivedColumn}
                          dataSource={dataList || []}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div class="dataTables_wrapper no-footer">
                                  <div class="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a class="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a class="paginate_button next">Next</a>
                                    ) : (
                                      <a class="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <CustomModal
              isOpen={this.state.isModelOpen}
              header={this.state.header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const ReduxSubRegion = connect(mapStateToProps)(withRouter(SubRegion));

class ManageRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  list = [
    {
      Name: `Region`,
      ExtraComponent: () => <ReduxRegion {...this.props} />,
    },
    {
      Name: `Subregion`,
      ExtraComponent: () => <ReduxSubRegion {...this.props} />,
    },
  ];
  render() {
    return (
      <div>
        <div class="content-area  setting-gap pl-0">
          <ListRenderer list={this.list} activeKey={activeKey} destroyInactiveTabPane={true} />
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(ManageRegion));
