import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset, reduxForm, formValueSelector } from 'redux-form';
import { Modal, Tooltip, Drawer } from 'antd';
import { Loader2 } from '../../../Components/Loader';
import CustomModal from '../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { stringDate } from '../../../util/date';
import AntdModal from '../../../Components/CustomModal';
import GridSearch from '../../../Components/gridSearch';
import { ProcessCombos } from '../../../store/comboConfig';
import PersistTableSort from '../../../component/persistTableSort';
import CloseIcon from '../../../Components/CloseIcon';
import ReduxLabOrderForm from '../../RCM/patientEnrollmentForm/formSteps/labOrder/LabOrderForm';
import LabOrderResult from '../../RCM/patientEnrollmentForm/formSteps/labOrder/LabOrderResult';
import LabTest from '../../RCM/patientEnrollmentForm/formSteps/labOrder/LabTest';
import {
  listLabOrder,
  upsertLabOrder,
  comboPractitionerAll,
  comboPractitionerCenterGet,
  comboFacilityAll,
  comboDiagnosisList,
  getLabOrder,
  resetGetLabOrder,
  getStaffPractitioner,
  getMedicationNote,
  labOrderCancel,
  labResultGet,
  labResultDocumentGet,
  labOrderDelete,
  labOrderPanelCodeComboGet,
  comboLabOrderPractitioner,
  labOrderLabelGet,
} from '../../../store/actions';

class LabOrder extends Component {
  constructor() {
    super();
    this.state = {
      isModelOpen: '',
      OrderInfo: {},
      selectedLabTests: {},
      header: '',
      isLabOrderResult: false,
      isDownloadClicked: true,
      columns: [
        {
          title: 'Entry Date',
          dataIndex: 'EntryDateTimeDisplay',
          key: 'EntryDateTimeDisplay',
          sorter: (a, b) => isDateComparer(a.EntryDateTimeDisplaySort, b.EntryDateTimeDisplaySort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Lab Test',
          dataIndex: 'LabTestCode',
          key: 'LabTestCode',
          sorter: (a, b) => isComparer(a.LabTestCode, b.LabTestCode),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Laboratory',
          dataIndex: 'LabOrgName',
          key: 'LabOrgName',
          sorter: (a, b) => isComparer(a.LabOrgName, b.LabOrgName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Provider',
          dataIndex: 'Provider',
          key: 'Provider',
          sorter: (a, b) => isComparer(a.Provider, b.Provider),
          sortDirections: ['descend', 'ascend'],
        },

        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
          sorter: (a, b) => isComparer(a.Status, b.Status),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => {
            if (record.StatusCode == 1) {
              return (
                <span
                  onClick={e => {
                    this.setState({
                      isLabOrderResult: true,
                    });
                    e.stopPropagation();
                    this.props.dispatch(
                      labResultGet({
                        LabOrderId: record.LabOrderId,
                        IsEnterprise: true,
                      }),
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {text}
                </span>
              );
            } else {
              return <span>{text}</span>;
            }
          },
        },
        {
          title: 'Action',
          width: 150,
          dataIndex: 'StatusCode',
          render: (text, record, index) => {
            return (
              <div className="d-flex justify-content-around">
                {/* <AntdModal
                  // mask={true}
                  modalWidth={800}
                  maskClosable={false}
                  ModalButton={({ onClick }) => (
                    // <span className="btn px-3" value="View" onClick={onClick} />
                    <span
                      style={{
                        color: '#FA8602',
                        fontSize: '14px',
                        cursor: 'pointer',
                        color: 'rgb(70, 180, 254)',
                        alignSelf: 'end',
                      }}
                      className="mr-3 d-flex align-self-center "
                      data-toggle="modal"
                      data-target="#view-model"
                      onClick={e => {
                        e.stopPropagation();
                        this.props.dispatch(
                          labOrderLabelGet({
                            Json: JSON.stringify({
                              LabOrder: [
                                {
                                  LabOrderId: record.LabOrderId,
                                },
                              ],
                            }),
                          }),
                        );
                        this.state.isDownloadClicked = false;
                        onClick();
                      }}
                    >
                      <Tooltip placement="top" title={'View'}>
                        <img src="images/view.svg" />
                      </Tooltip>
                    </span>
                  )}
                  ModalContent={props => (
                    <DocView
                      {...props}
                      dispatch={this.props.dispatch}
                      LabOrderId={record.LabOrderId}
                      reset={() => {
                        this.state.isDownloadClicked = true;
                      }}
                    />
                  )}
                /> */}
                <span
                  style={{
                    color: '#FA8602',
                    fontSize: '14px',
                    cursor: 'pointer',
                    color: 'rgb(70, 180, 254)',
                    alignSelf: 'end',
                  }}
                  // href={`#/labLabelTest?lab-order-id=${record.LabOrderId}`}
                  // student-id=${this.getClientId()}&
                  className="mr-3 d-flex align-self-center "
                  // target="_blank"
                  onClick={e => {
                    e.stopPropagation();
                    // window.open()
                    this.props.dispatch(
                      labOrderLabelGet({
                        Json: JSON.stringify({
                          LabOrder: [
                            {
                              LabOrderId: record.LabOrderId,
                            },
                          ],
                        }),
                      }),
                    );
                    // this.state.isDownloadClicked = false;
                    // onClick();
                  }}
                >
                  <Tooltip placement="top" title={'Print'}>
                    <img src="images/print_blue.svg" />
                  </Tooltip>
                </span>
                {text != 1 && text != 2 && !record.IsCanceled && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={e => {
                          onClick();
                          e.stopPropagation();
                        }}
                        data-toggle="modal"
                        data-target="#delete-Screen-Tool"
                        style={{ cursor: 'pointer' }}
                      >
                        {!this.props.isReadOnlyUser && !this.props.isDeActivated && (
                          <Tooltip placement="top" title="Cancel">
                            <img
                              src="images/icons/cancel.svg"
                              alt=""
                              style={{ marginLeft: '6px', width: '20px' }}
                            />
                          </Tooltip>
                        )}
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to cancel this Lab Order?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary black-btn"
                            onClick={e => {
                              onCancel();
                              e.stopPropagation();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={e => {
                              e.stopPropagation();

                              onOk();
                              this.props.dispatch(
                                labOrderCancel({
                                  json: JSON.stringify({
                                    LabOrder: [
                                      {
                                        LabOrderId: record.LabOrderId,
                                        IsEnterprise: true,
                                      },
                                    ],
                                  }),
                                }),
                              );
                              // this.deleteGroup(record.GroupId);
                              // this.props.onCancel();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
                {text == 1 && (
                  <span
                    onClick={e => {
                      e.stopPropagation();

                      this.props.dispatch(
                        labResultDocumentGet({
                          LabOrderId: record.LabOrderId,
                          IsEnterprise: true,
                        }),
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                    download
                  >
                    <Tooltip placement="top" title="Download">
                      <img src="images/icons/download.svg" alt="" />
                    </Tooltip>
                  </span>
                )}
                {text == 2 && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={e => {
                          onClick();
                          e.stopPropagation();
                        }}
                        data-toggle="modal"
                        data-target="#delete-Screen-Tool"
                        style={{ cursor: 'pointer' }}
                      >
                        {!this.props.isReadOnlyUser && !this.props.isDeActivated && (
                          <Tooltip placement="top" title="Delete">
                            <img src="images/deleteEvent.svg" alt="" />
                          </Tooltip>
                        )}
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to delete this Lab Order?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary black-btn"
                            onClick={e => {
                              e.stopPropagation();
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={e => {
                              e.stopPropagation();

                              onOk();
                              this.props.dispatch(
                                labOrderDelete({
                                  Json: JSON.stringify({
                                    LabOrder: [
                                      {
                                        LabOrderId: record.LabOrderId,
                                        IsEnterprise: true,
                                      },
                                    ],
                                  }),
                                }),
                              );
                              // this.deleteGroup(record.GroupId);
                              // this.props.onCancel();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
            );
          },
        },
      ],
      isListView: true,
      isDrawerOpen: false,
      panelIndex: 0,
    };
  }
  backButton = bool => {
    this.setState({ isListView: !this.state.isListView, selectedLabTests: {}, OrderInfo: {} });
    this.resetOrder();
  };
  resetOrder = () => {
    this.props.dispatch(resetGetLabOrder({}));
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentDidMount() {
    this.props.dispatch(getStaffPractitioner({}));
    this.props.dispatch(
      labOrderPanelCodeComboGet({
        Json: JSON.stringify({
          LabOrder: [
            {
              ClientId: this.props.ClientId,
              IsEnterprise: true,
            },
          ],
        }),
      }),
    );
    var result = ProcessCombos('LabOrg,LabTestCode,LabTestPriority');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    // this.props.dispatch(
    //   comboPractitionerCenterGet({
    //     json: JSON.stringify({
    //       Combo: [
    //         {
    //           IsAll: true,
    //           IsEnterprise: true,
    //         },
    //       ],
    //     }),
    //   }),
    // );

    this.props.dispatch(comboLabOrderPractitioner({}));

    this.props.dispatch(
      listLabOrder({
        Json: JSON.stringify({
          LabOrder: [
            {
              ClientId: this.props.ClientId,
              IsEnterprise: true,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      comboDiagnosisList({
        Json: JSON.stringify({
          Client: [
            {
              ClientId: this.props.ClientId,
              IsEnterprise: true,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      getMedicationNote({
        Json: JSON.stringify({
          Client: [
            {
              ClientId: this.props.ClientId,
              IsEnterprise: true,
            },
          ],
        }),
      }),
    );
  }

  componentWillReceiveProps({
    DiagnosisList,
    isInserted,
    labOrder,
    getStaffPractitionerValue,
    MedicationNote,
    labOrderDownload,
    isLabOrderDeleted,
    isLabOrderCancelled,
    labTests,
    labelTestUrl,
  }) {
    let selectedLabTests = {};
    let array = [];
    labTests &&
      labTests.map((lab, labIndex) => {
        lab.children &&
          lab.children.map((lab1, lab1Index) => {
            lab1.children &&
              lab1.children.map((lab2, lab2Index) => {
                if (lab2.SelectByDefault) {
                  let Index = array.indexOf(lab2.label);
                  if (Index < 0) {
                    array.push(lab2.label);
                  }
                  selectedLabTests = {
                    ...this.state.selectedLabTests,
                    [labIndex]: {
                      ...this.state.selectedLabTests[labIndex],
                      [lab1Index]: [...array],
                    },
                  };
                  this.setState({ selectedLabTests });
                }
              });
          });
      });

    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({
        isListView: true,
        selectedLabTests: {},
      });
      this.props.dispatch(reset('wizard'));
      this.props.dispatch(
        listLabOrder({
          Json: JSON.stringify({
            LabOrder: [
              {
                ClientId: this.props.ClientId,
                IsEnterprise: true,
              },
            ],
          }),
        }),
      );
      this.toggle('Lab Order added successfully');
    }
    if (
      (isLabOrderDeleted && isLabOrderDeleted != this.props.isLabOrderDeleted) ||
      (isLabOrderCancelled && isLabOrderCancelled != this.props.isLabOrderCancelled)
    ) {
      this.props.dispatch(
        listLabOrder({
          Json: JSON.stringify({
            LabOrder: [
              {
                ClientId: this.props.ClientId,
                IsEnterprise: true,
              },
            ],
          }),
        }),
      );
    }
    if (labOrderDownload && labOrderDownload != this.props.labOrderDownload) {
      window.open(labOrderDownload);
    }
    if (labelTestUrl && labelTestUrl != this.props.labelTestUrl) {
      window.open(labelTestUrl, '_blank');
      // const a = document.createElement('a');
      // a.href = labelTestUrl;
      // a.setAttribute('download', '');
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
    }

    if (
      getStaffPractitionerValue &&
      JSON.stringify(getStaffPractitionerValue) !=
        JSON.stringify(this.props.getStaffPractitionerValue)
    ) {
      if (getStaffPractitionerValue.data && getStaffPractitionerValue.data.length > 0) {
        this.setState({
          facilityValue:
            (getStaffPractitionerValue.data[0].FacilityId &&
              JSON.stringify(getStaffPractitionerValue.data[0].FacilityId)) ||
            null,
          practitionerValue:
            (getStaffPractitionerValue.data[0].PractitionerId &&
              JSON.stringify(getStaffPractitionerValue.data[0].PractitionerId)) ||
            null,
        });
      }
    }
    if (labOrder && JSON.stringify(labOrder) != JSON.stringify(this.props.labOrder)) {
      let labTest = {
        ...JSON.parse(labOrder.LabTest)[0],
      };
      let OrderInfo = {
        ClientId: labOrder.ClientId,
        Comments: labOrder.Comments,
        DiagnosisListId: labOrder.DiagnosisListId,
        EntryDateTime: labOrder.EntryDateTime,
        FacilityId: labOrder.FacilityId,
        // Instructions: labOrder.Instructions,
        IsFasting: labOrder.IsFasting && labOrder.IsFasting.toString(),
        LabOrderId: labOrder.LabOrderId,
        SampleType: labOrder.SampleType,
        LabOrgId: labOrder.LabOrgId,
        LabTestCodeId: labOrder.LabTestCodeId,
        LabTestDescription: labOrder.LabTestDescription,
        LabTestPriority: labOrder.LabTestPriority,
        Medications: labOrder.Medications,
        SpeciminId: labOrder.SpeciminId,
        PractitionerId: labOrder.PractitionerId,
        VisitDate: labOrder.VisitDate,
        LabOrder: JSON.parse(labOrder.LabTest),
        ...labTest,
      };
      this.setState({
        OrderInfo: OrderInfo,
        isViewMode: true,
        isListView: false,
        selectedLabTests: {},
      });
    }
    if (
      DiagnosisList &&
      JSON.stringify(DiagnosisList) != JSON.stringify(this.props.DiagnosisList)
    ) {
      let DiagnosisOption =
        (DiagnosisList &&
          DiagnosisList.map(item => {
            return item.Value;
          })) ||
        [];
      // let data = DiagnosisOption.join();
      this.setState({
        OrderInfo: {
          DiagnosisListId: DiagnosisOption,
        },
      });
    }
    if (MedicationNote) {
      this.setState({
        MedicationNote: MedicationNote,
      });
    }
    // DiagnosisListId: [128, 208, 206, 212, 196],
  }
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listLabOrder({
        json: JSON.stringify({
          LabOrder: [
            {
              Search: value,
              ClientId: this.props.ClientId,
              IsEnterprise: true,
            },
          ],
        }),
      }),
    );
  };
  onSampleTypeChange = () => {
    this.setState({
      selectedLabTests: {},
    });
  };
  getLabTest = (tests, panelItem) => {
    let keys = Object.keys(tests);
    let filterValue = [];
    keys.map(item => {
      let data = this.props.labTests[parseInt(panelItem)].children;
      let childrenData = data[parseInt(item)].children;
      let selectedItems = tests[parseInt(item)];
      childrenData.filter(filterItem => {
        if (selectedItems.includes(filterItem.label)) {
          filterValue.push(filterItem.value);
          return filterItem.value;
        }
      });
    });
    return filterValue;
  };
  onSubmit(values) {
    const {
      PractitionerId,
      FacilityId,
      VisitDate,
      // LabTestCodeId,
      LabTestDescription,
      IsFasting,
      SpeciminId,
      DiagnosisListId,
      LabOrgId,
      Instructions,
      Comments,
      Medications,
      LabOrder,
      ObservationDate,
      LabTestPriority,
      LabInstructions,
      SampleType,
    } = values;

    let LabOrders = Object.keys(this.state.selectedLabTests);
    let LabTestCode = [];
    LabOrders.map((panelItem, panelIndex) => {
      LabTestCode = [
        ...LabTestCode,
        ...this.getLabTest(this.state.selectedLabTests[panelItem], panelItem),
      ];
    });
    let LabOrderList = [
      {
        ObservationDate: stringDate(ObservationDate),
        LabTestPriority: LabTestPriority,
        LabInstructions: LabInstructions,
        LabTestCodeId: LabTestCode.toString(),
      },
    ];
    const data = {
      LabOrder: [
        {
          ClientId: this.props.ClientId,
          IsEnterprise: true,
          PractitionerId: PractitionerId,
          SpeciminId: SpeciminId,
          LabOrgId: LabOrgId,
          Medications: Medications,
          DiagnosisListId: DiagnosisListId,
          IsFasting: IsFasting,
          Notes: Comments,
          LabTestDescription: LabTestDescription,
          // Instructions: Instructions,
          LabTest: LabOrderList,
          SampleType: SampleType,
        },
      ],
    };
    let LabOrderData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertLabOrder(LabOrderData));
  }
  onRowClick(LabOrderId) {
    const { dispatch, history } = this.props;
    dispatch(
      getLabOrder({
        json: JSON.stringify({
          LabOrder: [
            {
              LabOrderId: LabOrderId,
              IsEnterprise: true,
            },
          ],
        }),
      }),
    );
  }
  showDrawer = () => {
    this.setState({
      isDrawerOpen: true,
      // testIndex: testIndex,
    });
  };
  onClose = () => {
    this.setState({
      isDrawerOpen: false,
      // testIndex: null,
    });
  };
  getExpandedkeys = panelIndex => {
    this.setState({
      panelIndex: panelIndex,
    });
  };
  getCheckItems = (data, index, ParentIndex) => {
    // let panelIndex = this.state.panelIndex;
    let selectedLabTests = {
      ...this.state.selectedLabTests,
      [ParentIndex]: {
        ...this.state.selectedLabTests[ParentIndex],
        [index]: data,
      },
    };
    this.setState({ selectedLabTests });
  };

  render() {
    const {
      columns,
      notesDrawerVisible,
      appointmentId,
      isGroup,
      groupAppointmentId,
      isModelOpen,
      header,
      practitionerValue,
      facilityValue,
      isListView,
      isDrawerOpen,
    } = this.state;
    const {
      clientId,
      labOrderList,
      isFetching,
      isFetchingLabTest,
      isLabOrderFetching,
      labTests,
    } = this.props;
    return (
      <div className="main medicalAnalysis-page mb-5 lab-order">
        {isListView ? (
          <div className="container-fluid rcm-tab">
            <div className="row">
              <div className="col-12 mb-5">
                <div className="border-0">
                  <div className="">
                    <div className="row px-0 my-4 d-flex align-items-center">
                      <div className="col-2 col-xl-4 px-0">
                        <h4 className="mb-0">LAB ORDER</h4>
                      </div>
                      <div className="col-10 col-xl-8 px-1 d-flex align-items-center align-self-baseline justify-content-end">
                        <GridSearch onSearchChange={this.onSearchChange} />
                        {!this.props.isReadOnlyUser && !this.props.isDeActivated && (
                          <input
                            type="button"
                            style={{ width: '150px' }}
                            className="btn"
                            value={'New Order'}
                            onClick={event => {
                              this.setState({
                                OrderInfo: {
                                  ...this.state.OrderInfo,
                                  PractitionerId: parseInt(practitionerValue),
                                  IsFasting: 'false',
                                  Medications: this.state.MedicationNote,
                                  SampleType: 'Urine',
                                },
                                isViewMode: false,
                                isListView: false,
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row pt-5 pb-5 rcm-subtab-table-block">
                    <div className="col-12" style={{ position: 'relative' }}>
                      <Loader2 loading={isFetching} />
                      <PersistTableSort
                        name="labOrderList"
                        className="row-hover-enabled"
                        columns={columns}
                        dataSource={labOrderList}
                        size={'middle'}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: event => this.onRowClick(record.LabOrderId),
                          };
                        }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div className="dataTables_wrapper no-footer">
                                <div className="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a className="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a className="paginate_button next">Next</a>
                                  ) : (
                                    <a className="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="container-fluid">
            <div className="row top-table-search mt-3 mb-4">
              <div className="col-12">
                <a
                  class="back-link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.backButton();
                  }}
                >
                  <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                </a>
                <h4 class="page-title mb-3 mb-md-0">LAB ORDER</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ReduxLabOrderForm
                  onSaveClick={this.onSubmit.bind(this)}
                  initialValues={this.state.OrderInfo}
                  OrderInfo={this.state.OrderInfo}
                  selectedLabTests={this.state.selectedLabTests}
                  // panelIndex={this.state.panelIndex}
                  labTestsLists={this.props.labTests}
                  DiagnosisList={this.props.DiagnosisList}
                  isLabOrderFetching={this.props.isLabOrderFetching}
                  disable={this.state.isViewMode}
                  backButton={this.backButton}
                  showDrawer={this.showDrawer}
                  isEnterprise={true}
                  onCancel={this.backButton}
                  ClientId={this.props.ClientId}
                  onSampleTypeChange={this.onSampleTypeChange}
                />
              </div>
            </div>
          </div>
        )}

        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            zIndex={9999}
            header={header}
            yesAction={() => {
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
        <Modal
          zIndex={100}
          visible={this.state.isLabOrderResult}
          footer={null}
          closable={false}
          width={800}
        >
          <LabOrderResult
            onSaveClick={this.onSubmit.bind(this)}
            labResultArray={this.props.labResultArray}
            disable={this.state.isViewMode}
            isResultFetching={this.props.isResultFetching}
            onCancel={() => {
              this.setState({
                isLabOrderResult: false,
              });
            }}
          />
        </Modal>
        {isDrawerOpen && (
          <Drawer
            className="custom-drawer lab-test"
            title="LAB ORDER"
            placement="right"
            onClose={this.onClose}
            visible={isDrawerOpen}
            width="900"
            closeIcon={<CloseIcon />}
          >
            <LabTest
              labTests={labTests}
              getCheckItems={this.getCheckItems}
              getExpandedkeys={this.getExpandedkeys}
              panelIndex={this.state.panelIndex}
              selectedLabTests={this.state.selectedLabTests}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

// const mapStateToProps = ({
//   list_lab_order,
//   upsert_lab_order,
//   get_lab_order,
//   get_staff_practitioner,
//   combo_diagnosis_list,
//   get_medication_note,
//   lab_result_get,
//   lab_result_document_get,
//   lab_order_delete,
//   lab_order_cancel,
//   lab_order_panel_code_combo_get,
//   // upsert_lab_order,
// }) => {
//   const { data, isFetching } = list_lab_order;
//   const { data: labOrderData, isFetching: isFetchingOrder } = get_lab_order;
//   const { data: getStaffPractitionerValue } = get_staff_practitioner;
//   const { data: diagnosisData } = combo_diagnosis_list;
//   const { data: medicationNote } = get_medication_note;
//   const { data: resultArray, isFetching: isResultFetching } = lab_result_get;
//   const { data: downloadLabOrder } = lab_result_document_get;
//   const { data: LabOrderDeleted } = lab_order_delete;
//   const { data: LabOrderCancelled } = lab_order_cancel;
//   const { isFetching: isLabFetching } = upsert_lab_order;
//   const { data: LabTestData, isFetching: isFetchingLabTest } = lab_order_panel_code_combo_get;
//   let labOrderList = [];
//   let DiagnosisList = [];
//   let MedicationNote = null;
//   let labResultArray = [];
//   let labOrder;
//   let isInserted = false;
//   let labOrderDownload;
//   let isLabOrderDeleted = false;
//   let isLabOrderCancelled = false;
//   let isLabOrderFetching = false;
//   let labTests = [];

//   if (LabOrderDeleted && LabOrderDeleted.success) {
//     isLabOrderDeleted = true;
//   }
//   if (LabOrderCancelled && LabOrderCancelled.success) {
//     isLabOrderCancelled = true;
//   }
//   if (isLabFetching) {
//     isLabOrderFetching = true;
//   }
//   if (data && data.data) {
//     labOrderList = data.data || [];
//   }
//   if (downloadLabOrder && downloadLabOrder.result && downloadLabOrder.result.fileUrl) {
//     labOrderDownload = downloadLabOrder.result.fileUrl;
//   }
//   if (labOrderData && labOrderData.data) {
//     labOrder = labOrderData.data[0] || {};
//   }
//   if (upsert_lab_order.data && upsert_lab_order.data.success) {
//     isInserted = upsert_lab_order.data.success;
//   }
//   if (resultArray && resultArray.data) {
//     labResultArray = resultArray.data;
//   }
//   if (diagnosisData && diagnosisData.data) {
//     DiagnosisList = diagnosisData.data || [];
//   }
//   if (medicationNote && medicationNote.data) {
//     MedicationNote = medicationNote.data[0].MedicationNote || '';
//   }
//   if (LabTestData && LabTestData.LabOrder) {
//     labTests = LabTestData.LabOrder;
//   }
//   return {
//     labOrderList,
//     isFetching,
//     isFetchingOrder,
//     isInserted,
//     labOrder,
//     getStaffPractitionerValue,
//     DiagnosisList,
//     MedicationNote,
//     labResultArray,
//     isResultFetching,
//     labOrderDownload,
//     isLabOrderDeleted,
//     isLabOrderCancelled,
//     isLabOrderFetching,
//     isFetchingLabTest,
//     labTests,
//   };
// };

// export default connect(mapStateToProps)(withRouter(ListLabOrder));

export default connect(state => {
  const {
    list_lab_order,
    upsert_lab_order,
    get_lab_order,
    get_staff_practitioner,
    combo_diagnosis_list,
    get_medication_note,
    lab_result_get,
    lab_result_document_get,
    lab_order_delete,
    lab_order_cancel,
    lab_order_panel_code_combo_get,
    lab_order_label_get,
  } = state;
  const { data, isFetching } = list_lab_order;
  const { data: labOrderData, isFetching: isFetchingOrder } = get_lab_order;
  const { data: getStaffPractitionerValue } = get_staff_practitioner;
  const { data: diagnosisData } = combo_diagnosis_list;
  const { data: medicationNote } = get_medication_note;
  const { data: resultArray, isFetching: isResultFetching } = lab_result_get;
  const { data: downloadLabOrder } = lab_result_document_get;
  const { data: LabOrderDeleted } = lab_order_delete;
  const { data: LabOrderCancelled } = lab_order_cancel;
  const { isFetching: isLabFetching } = upsert_lab_order;
  const { data: LabTestData, isFetching: isFetchingLabTest } = lab_order_panel_code_combo_get;
  const { data: LabTestLabel } = lab_order_label_get;
  let labOrderList = [];
  let DiagnosisList = [];
  let MedicationNote = null;
  let labResultArray = [];
  let labOrder;
  let isInserted = false;
  let labOrderDownload;
  let isLabOrderDeleted = false;
  let isLabOrderCancelled = false;
  let isLabOrderFetching = false;
  let labTests = [];
  let labelTestUrl = null;

  if (LabTestLabel && LabTestLabel.result) {
    labelTestUrl = LabTestLabel.result.labelUrl;
  }
  if (LabOrderDeleted && LabOrderDeleted.success) {
    isLabOrderDeleted = true;
  }
  if (LabOrderCancelled && LabOrderCancelled.success) {
    isLabOrderCancelled = true;
  }
  if (isLabFetching) {
    isLabOrderFetching = true;
  }
  if (data && data.data) {
    labOrderList = data.data || [];
  }
  if (downloadLabOrder && downloadLabOrder.result && downloadLabOrder.result.fileUrl) {
    labOrderDownload = downloadLabOrder.result.fileUrl;
  }
  if (labOrderData && labOrderData.data) {
    labOrder = labOrderData.data[0] || {};
  }
  if (upsert_lab_order.data && upsert_lab_order.data.success) {
    isInserted = upsert_lab_order.data.success;
  }
  if (resultArray && resultArray.data) {
    labResultArray = resultArray.data;
  }
  if (diagnosisData && diagnosisData.data) {
    DiagnosisList = diagnosisData.data || [];
  }
  if (medicationNote && medicationNote.data) {
    MedicationNote = medicationNote.data[0].MedicationNote || '';
  }
  if (LabTestData && LabTestData.LabOrder) {
    labTests = LabTestData.LabOrder;
  }
  return {
    labOrderList,
    isFetching,
    isFetchingOrder,
    isInserted,
    labOrder,
    getStaffPractitionerValue,
    DiagnosisList,
    MedicationNote,
    labResultArray,
    isResultFetching,
    labOrderDownload,
    isLabOrderDeleted,
    isLabOrderCancelled,
    isLabOrderFetching,
    isFetchingLabTest,
    labTests,
    labelTestUrl,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(LabOrder),
);

const selector = formValueSelector('wizard');
