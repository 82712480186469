import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getAnalytics(action) {
  try {
    const { payload } = action;
    const response = yield API.getAnalytics(payload);
    yield put({
      type: ActionTypes.GET_ANALYTICS_SUCCESS,
      payload: JSON.parse(response.data.data).Analytics[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ANALYTICS_FAILURE,
    });
  }
}
export function* getActiveClient(action) {
  try {
    const { payload } = action;
    const response = yield API.getActiveClient(payload);
    yield put({
      type: ActionTypes.GET_ACTIVE_CLIENT_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_ACTIVE_CLIENT_FAILURE,
    });
  }
}
export function* getCategoryClient(action) {
  try {
    const { payload } = action;
    const response = yield API.getCategoryClient(payload);
    yield put({
      type: ActionTypes.GET_CATEGORY_CLIENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CATEGORY_CLIENT_FAILURE,
    });
  }
}
export function* getClientDemographic(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientDemographic(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_DEMOGRAPHIC_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_DEMOGRAPHIC_FAILURE,
    });
  }
}
export function* getCostOfCare(action) {
  try {
    const { payload } = action;
    const response = yield API.getCostOfCare(payload);
    yield put({
      type: ActionTypes.GET_COST_OF_CARE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_COST_OF_CARE_FAILURE,
    });
  }
}
export function* getBehavioralRating(action) {
  try {
    const { payload } = action;
    const response = yield API.getBehavioralRating(payload);
    yield put({
      type: ActionTypes.GET_BEHAVIORAL_RATING_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_BEHAVIORAL_RATING_FAILURE,
    });
  }
}
export function* getScoreImprovement(action) {
  try {
    const { payload } = action;
    const response = yield API.getScoreImprovement(payload);
    yield put({
      type: ActionTypes.GET_SCORE_IMPROVEMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SCORE_IMPROVEMENT_FAILURE,
    });
  }
}
export function* getProviderPerformance(action) {
  try {
    const { payload } = action;
    const response = yield API.getProviderPerformance(payload);
    yield put({
      type: ActionTypes.GET_PROVIDER_PERFORMANCE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PROVIDER_PERFORMANCE_FAILURE,
    });
  }
}
export function* getTriggerLocationActivity(action) {
  try {
    const { payload } = action;
    const response = yield API.getTriggerLocationActivity(payload);
    yield put({
      type: ActionTypes.GET_TRIGGER_LOCATION_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_TRIGGER_LOCATION_FAILURE,
    });
  }
}
export function* getServiceDelivered(action) {
  try {
    const { payload } = action;
    const response = yield API.getServiceDelivered(payload);
    yield put({
      type: ActionTypes.GET_SERVICE_DELIVERED_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_SERVICE_DELIVERED_FAILURE,
    });
  }
}
export function* getReferralSource(action) {
  try {
    const { payload } = action;
    const response = yield API.getReferralSource(payload);
    yield put({
      type: ActionTypes.GET_REFERRAL_SOURCE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_REFERRAL_SOURCE_FAILURE,
    });
  }
}
export function* getEmbedInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.getEmbedInfo(payload);
    yield put({
      type: ActionTypes.GET_EMBED_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_EMBED_INFO_FAILURE,
    });
  }
}
export function* getGenderPieChartEmbedInfo(action) {
  try {
    const { payload } = action;
    const response = yield API.getGenderPieChartEmbedInfo(payload);
    yield put({
      type: ActionTypes.GET_GENDER_PIE_CHART_EMBED_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GENDER_PIE_CHART_EMBED_INFO_FAILURE,
    });
  }
}
export function* getRefernceSourceTimeInTreatmentReport(action) {
  try {
    const { payload } = action;
    const response = yield API.getRefernceSourceTimeInTreatmentReport(payload);
    yield put({
      type: ActionTypes.GET_REFERNCE_SOURCE_TIME_IN_TREATMENT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_REFERNCE_SOURCE_TIME_IN_TREATMENT_REPORT_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    // takeLatest(ActionTypes.APP_INVITE_REQUEST, appInvite),
    takeLatest(ActionTypes.GET_ANALYTICS_REQUEST, getAnalytics),
    takeLatest(ActionTypes.GET_ACTIVE_CLIENT_REQUEST, getActiveClient),
    takeLatest(ActionTypes.GET_CATEGORY_CLIENT_REQUEST, getCategoryClient),
    takeLatest(ActionTypes.GET_CLIENT_DEMOGRAPHIC_REQUEST, getClientDemographic),
    takeLatest(ActionTypes.GET_COST_OF_CARE_REQUEST, getCostOfCare),
    takeLatest(ActionTypes.GET_BEHAVIORAL_RATING_REQUEST, getBehavioralRating),
    takeLatest(ActionTypes.GET_SCORE_IMPROVEMENT_REQUEST, getScoreImprovement),
    takeLatest(ActionTypes.GET_PROVIDER_PERFORMANCE_REQUEST, getProviderPerformance),
    takeLatest(ActionTypes.GET_TRIGGER_LOCATION_REQUEST, getTriggerLocationActivity),
    takeLatest(ActionTypes.GET_SERVICE_DELIVERED_REQUEST, getServiceDelivered),
    takeLatest(ActionTypes.GET_REFERRAL_SOURCE_REQUEST, getReferralSource),
    takeLatest(ActionTypes.GET_EMBED_INFO_REQUEST, getEmbedInfo),
    takeLatest(ActionTypes.GET_GENDER_PIE_CHART_EMBED_INFO_REQUEST, getGenderPieChartEmbedInfo),
    takeLatest(
      ActionTypes.GET_REFERNCE_SOURCE_TIME_IN_TREATMENT_REPORT_REQUEST,
      getRefernceSourceTimeInTreatmentReport,
    ),
  ]);
}
