import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class BinaryType extends Component {
  constructor() {
    super();
    this.state = {};
  }

  radioHandel = (object, e) => {
    // let val = e.target.value === 'range' ? null : e.target.value;
    let val = e.target.value;
    // let bool = e.target.value === 'range' ? false : true;
    this.setState({
      filterRadio: val,
      // showCal: bool,
    });
  };

  render() {
    const {
      survey: {
        Question,
        QuestionNumber,
        SurveyQuestionId,
        Response,
        DoesNotApplyText,
        DoesNotApplyValue,
        Option1Text,
        Option1Value,
        Option2Text,
        Option2Value,
      },
      onClick,
      scope,
      index,
      total,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    return (
      <>
        <div className="type-bordercol-sm-12 col-lg-7">
          <div>
            <SurveyQuestion survey={this.props.survey} ClassName={'mb-3'} />
            <ul class="answer-options d-flex flex-wrap" style={{ marginTop: '20px' }}>
              <li className="mr-2" style={{ display: 'inherit' }}>
                <span
                  className={Response == Option2Value ? 'selected' : 'normal'}
                  style={{ padding: '10px 20px', display: 'flex !important', alignItems: 'center' }}
                >
                  {' '}
                  <input
                    disabled={isReadOnly}
                    type="radio"
                    name="current-situation"
                    value={Option2Text}
                    onClick={onClick.bind(scope, {
                      response:
                        Response == Option2Value ? DoesNotApplyValue : parseInt(Option2Value),
                      surveyQuestionId: SurveyQuestionId,
                      level: level,
                      subLevel: subLevel,
                      subCatLevel: subCatLevel,
                      index: index,
                    })}
                  />
                  <img
                    style={{ marginTop: '4px', marginRight: '5px' }}
                    src={
                      Response == Option2Value
                        ? './images/white_cross.svg'
                        : './images/black_cross.svg'
                    }
                  />
                  {Option2Text && renderHTML(Option2Text)}
                </span>
              </li>
              <li className="mr-2" style={{ display: 'inherit' }}>
                <span
                  className={Response == Option1Value ? 'selected' : 'normal'}
                  style={{ padding: '10px 20px' }}
                >
                  {' '}
                  <input
                    disabled={isReadOnly}
                    type="radio"
                    name="current-situation"
                    value={Option1Text}
                    onClick={onClick.bind(scope, {
                      response:
                        Response == Option1Value ? DoesNotApplyValue : parseInt(Option1Value),
                      surveyQuestionId: SurveyQuestionId,
                      level: level,
                      subLevel: subLevel,
                      subCatLevel: subCatLevel,
                      index: index,
                    })}
                  />
                  <img
                    style={{ marginTop: '4px', marginRight: '5px' }}
                    src={
                      Response == Option1Value
                        ? './images/white_tick.svg'
                        : './images/black_tick.svg'
                    }
                  />
                  {Option1Text && renderHTML(Option1Text)}
                </span>
              </li>
            </ul>

            <DoesNotApplyOption surveyProps={this.props} />
          </div>
        </div>
      </>
    );
  }
}

// export default ({
//   survey: { Question, SurveyQuestionId, Response, DoesNotApplyText, DoesNotApplyValue },
//   onClick,
//   scope,
//   index,
//   total,
// }) => {
//   // radioHandel = e => {
//   //   // let val = e.target.value === 'range' ? null : e.target.value;
//   //   let val = e.target.value;
//   //   // let bool = e.target.value === 'range' ? false : true;
//   //   this.setState({
//   //     filterRadio: val,
//   //     // showCal: bool,
//   //   });
//   // };

//   return (
//     <div
//       // class="card border-0"
//       className="type-border"
//     >
//       <div
//       // class="card-body"
//       >
//         <h5 className="survey-question rcm-question text-left">
//           {' '}
//           {/* <span>
//             <em>. </em>
//             of {total}
//           </span> */}
//           {index + 1}. {Question}
//         </h5>
//         <div class="answer-options">
//           <div class="col-12 text-left px-0">
//             <div class="row no-gutters">
//               <div className="col-1">
//                 <div class="form-group custom-radio">
//                   <label className="">
//                     <h6 class="d-block">Yes</h6>
//                     <input
//                       name="filterRadio"
//                       type="radio"
//                       // onChange={this.radioHandel}
//                       value="yes"
//                     />
//                     <span className="checkmark" />
//                   </label>
//                 </div>
//               </div>
//               <div className="col-1">
//                 <div class="form-group custom-radio">
//                   <label className="">
//                     <h6 class="d-block">No</h6>
//                     <input
//                       checked
//                       name="filterRadio"
//                       // onChange={this.radioHandel}
//                       type="radio"
//                       value="no"
//                     />
//                     <span className="checkmark" />
//                   </label>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div
//             class={`answer-box answer-no ${Response == 0 && 'selected'}`}
//             onClick={onClick.bind(scope, { response: 0, surveyQuestionId: SurveyQuestionId })}
//           >
//             <div class="icon  failed-bg">
//               <img src="images/thumbs-icon.svg" alt="" />
//             </div>
//             <h6 class="d-block"> No</h6>
//           </div>
//           <div
//             class={`answer-box answer-yes ${Response == 1 && 'selected'}`}
//             onClick={onClick.bind(scope, { response: 1, surveyQuestionId: SurveyQuestionId })}
//           >
//             <div class="icon success-bg">
//               <img src="images/thumbs-icon.svg" alt="" />
//             </div>
//             <h6 class="d-block">Yes</h6>
//           </div>
//         </div>
//         {DoesNotApplyText && (
//           <div className="not-apply-to-me">
//             <a
//               name="current-situation"
//               value={DoesNotApplyText}
//               onClick={onClick.bind(scope, {
//                 surveyQuestionId: SurveyQuestionId,
//                 response: DoesNotApplyValue,
//               })}
//             >
//               {DoesNotApplyText}
//             </a>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

export default BinaryType;
