import React from 'react';
import { Input } from 'reactstrap';

class CustomInput extends Input {
  constructor(props) {
    super(props);
  }
}

export default CustomInput;

const ReduxSsnInput = ({
  input,
  type,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  maxLength,
  mobileFormat,
  tabIndex,
  style,
  fieldName,
  min,
  max,
  controlledMaxAge,
  negativeNumberBlocked,
  showPlaceHolder,
}) => {
  var { touched, error, warning, active } = meta || {};
  if (input) {
    debugger;
    defaultValue = input.defaultValue;
    value = input.value.toString();
    onChange = input.onChange;
    onBlur = input.onBlur;
    name = input.name;
  }

  /*if(mobileFormat){
        value = Util.formatPhoneNumber(value);
    }*/

  return (
    <>
      {!controlledMaxAge ? (
        !negativeNumberBlocked ? (
          <CustomInput
            {...input}
            style={style}
            tabIndex={tabIndex}
            type={type}
            name={name}
            id={id}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            // placeholder={active && placeholder}
            placeholder={showPlaceHolder ? placeholder : active && placeholder}
            className={`${className} ${value && 'not-empty'} ${type == 'textarea' &&
              'textarea-height'}`}
            disabled={!!disabled}
            maxLength={maxLength}
            autocomplete="off"
          />
        ) : (
          <CustomInput
            {...input}
            style={style}
            tabIndex={tabIndex}
            type={type}
            name={name}
            id={id}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={active && placeholder}
            className={`${className} ${value && 'not-empty'}`}
            disabled={!!disabled}
            maxLength={maxLength}
            autocomplete="off"
            min={min}
            max={max}
          />
        )
      ) : (
        <CustomInput
          {...input}
          style={style}
          tabIndex={tabIndex}
          type={type}
          name={name}
          id={id}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={active && placeholder}
          className={`${className} ${value && 'not-empty'}`}
          disabled={!!disabled}
          maxLength={maxLength}
          autocomplete="off"
          min={min}
          max={max}
          //   onKeyPress={function(evt) {
          //     evt.preventDefault();
          //   }
          // }
        />
      )}

      {touched &&
        ((error && fieldName && (
          <span className="error-text">
            {fieldName} {error}
          </span>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </>
  );
};

export { ReduxSsnInput };
