import React, { Component } from 'react';
import { Switch, Checkbox, Radio, Popover } from 'antd';
import renderHTML from 'react-render-html';
import AntdModal from '../../../Components/CustomModal';

class QuestionConfigAction extends Component {
  constructor(props) {
    super(props);
    // this.state = {};
  }

  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      SurveyQuestionList,
      activeKey,
      onQuestionStateChange,
      isAdmin,
      onEditAction,
    } = this.props;
    let moveToArray = [];
    SurveyQuestionList &&
      SurveyQuestionList.SurveyQuestion &&
      SurveyQuestionList.SurveyQuestion.map((item, index) => {
        const { IsArchived } = item;
        if (activeKey != index) {
          moveToArray.push(IsArchived);
        }
      });

    const isMoveAllowed = moveToArray.some(item => item === false);
    let radioValue;
    const { IsArchived, SurveyQuestionCategoryConfigId, IsQuestionUsed, CenterNames } = survey;
    const content = (
      <div className="sort-by-list">
        {!IsQuestionUsed ? (
          <p
            onClick={() => {
              onEditAction({
                survey: survey,
                index: sequenceNum,
              });
            }}
          >
            Edit
          </p>
        ) : (
          <AntdModal
            ModalButton={({ onClick }) => (
              <p
                onClick={() => {
                  onClick();
                }}
              >
                Edit
              </p>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div className="modal-content border-0">
                <div className="modal-header flex-column align-items-center justify-content-center flex-wrap border-bottom-0 mb-2">
                  <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                    &times;
                  </button>
                  <h4 className="modal-title col-12" style={{ fontWeight: '600' }}>
                    Attention: Please note that modifying this question will affect the preferences
                    of other centers using it.
                  </h4>

                  <div style={{ width: '100%', paddingLeft: '22px' }}>
                    <p
                      className="mt-3 mb-2"
                      style={{
                        width: ' 100%',
                        fontSize: '19px',
                        fontWeight: '600',
                        textDecoration: 'underline',
                      }}
                    >
                      Center(s) using the question (
                      {`${CenterNames && CenterNames.split(',').length}`}) :
                    </p>
                    <div
                      style={{
                        maxHeight: '150px',
                        overflowX: 'auto',
                        // marginTop: '16px',
                        marginBottom: '16px',
                        width: '100%',
                      }}
                    >
                      {CenterNames &&
                        CenterNames.split(',').map((centerName, index) => (
                          <p
                            className="mb-0"
                            style={{
                              fontSize: '17px',
                              fontWeight: '600',
                              marginBottom: '9px',
                            }}
                          >
                            {`${index + 1}. ${centerName}`}
                          </p>
                        ))}
                      <br />
                    </div>
                  </div>

                  <h4 style={{ fontWeight: '600' }}>Do you want to proceed?</h4>
                </div>
                <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    type="button"
                    className="btn btn-outline-primary py-1"
                    data-dismiss="modal"
                    onClick={onCancel}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn py-1"
                    onClick={() => {
                      onOk();
                      onEditAction({
                        survey: survey,
                        index: sequenceNum,
                      });
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          />
        )}

        {/* <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#move-to">
              Move to
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                  &times;
                </button>
                <h4 className="modal-title col-12">Move Question</h4>
                <span>Select a category to move the question:</span>
              </div>
              <div className="modal-body m-4 pb-0 pt-2" style={{ backgroundColor: '#F4F4F4' }}>
                <div>
                  <Radio.Group
                    onChange={event => {
                      radioValue = event.target.value;
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        height: '350px',
                        overflowY: 'scroll',
                      }}
                    >
                      {SurveyQuestionList.Questions.map((item, index) => {
                        const { Tab, Sequence, Questions } = item;
                        if (activeKey != index) {
                          return (
                            <div
                              className="col-12"
                              style={{
                                borderBottom: '1px solid #E6E6E6',
                              }}
                            >
                              <Radio
                                value={Tab}
                                style={{
                                  padding: '15px',
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              >
                                {renderHTML(Tab)}
                              </Radio>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Radio.Group>
                </div>
              </div>
              <div className="modal-footer justify-content-end border-top-0 pb-4">
                <button
                  type="button"
                  className="btn btn-outline-primary py-1"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn py-1"
                  onClick={() => {
                    onOk();
                    this.props.onMoveTo(radioValue, survey);
                  }}
                >
                  Move
                </button>
              </div>
            </div>
          )}
        />
        <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#remove">
              Archive
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                  &times;
                </button>
                <h4 className="modal-title col-12">Are you sure you want to archive this?</h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  type="button"
                  className="btn btn-outline-primary py-1"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  No, keep it
                </button>
                <button
                  type="button"
                  className="btn py-1"
                  onClick={() => {
                    onOk();

                    onQuestionStateChange.bind(scope, {
                      survey: survey,
                      surveyQuestionId: survey.SurveyQuestionId,
                      response: true,
                      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
                      level: level,
                      subLevel: subLevel,
                      subCatLevel: subCatLevel,
                      index: sequenceNum,
                      TabName: TabName,
                      Sequence: Sequence,
                      SurveyQuestionListItems: [SurveyQuestionListItems],

                      OptKeyName: `IsArchived`,
                    })();
                  }}
                >
                  Archive
                </button>
              </div>
            </div>
          )}
        /> */}
      </div>
    );
    return (
      <div className="question-config-action">
        {isAdmin ? (
          IsArchived ? (
            <AntdModal
              ModalButton={({ onClick }) => (
                <button onClick={onClick} className="btn mx-2 mr-4">
                  Restore
                </button>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div className="modal-content border-0">
                  <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                    <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                      &times;
                    </button>
                    <h4 className="modal-title col-12">Are you sure you want to unarchive this?</h4>
                  </div>
                  <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      type="button"
                      className="btn btn-outline-primary py-1"
                      data-dismiss="modal"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn py-1"
                      onClick={() => {
                        onOk();
                        onQuestionStateChange.bind(scope, {
                          survey: survey,
                          surveyQuestionId: survey.SurveyQuestionId,
                          response: false,
                          level: level,
                          subLevel: subLevel,
                          subCatLevel: subCatLevel,
                          index: sequenceNum,
                          TabName: TabName,
                          Sequence: Sequence,
                          SurveyQuestionListItems: [SurveyQuestionListItems],
                          OptKeyName: `IsArchived`,
                        })();
                      }}
                    >
                      Restore
                    </button>
                  </div>
                </div>
              )}
            />
          ) : (
            <div>
              <Popover
                overlayClassName="alert-box-style"
                content={content}
                title={false}
                trigger="hover"
                placement="left"
                class="alert-sort"
              >
                <button
                  className="btn btn-outline-primary mr-2"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Actions <img src="images/icons/3dot_blue.svg" className="mx-2" />
                </button>
              </Popover>
            </div>
          )
        ) : IsArchived ? (
          <button
            onClick={() => {
              onQuestionStateChange.bind(scope, {
                survey: survey,
                surveyQuestionId: survey.SurveyQuestionId,
                response: false,
                level: level,
                subLevel: subLevel,
                subCatLevel: subCatLevel,
                index: sequenceNum,
                TabName: TabName,
                Sequence: Sequence,
                SurveyQuestionListItems: [SurveyQuestionListItems],
                OptKeyName: `IsArchived`,
              })();
            }}
            className="btn mx-2 mr-4"
          >
            Add
          </button>
        ) : (
          //   (
          // <AntdModal
          //   ModalButton={({ onClick }) => (
          //     <button onClick={onClick} className="btn mx-2 mr-4">
          //       Add
          //     </button>
          //   )}
          //   ModalContent={({ onOk, onCancel }) => (
          //     <div className="modal-content border-0">
          //       <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          //         <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
          //           &times;
          //         </button>
          //         <h4 className="modal-title col-12">
          //           Are you sure you want to add this question?
          //         </h4>
          //       </div>
          //       <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          //         <button
          //           type="button"
          //           className="btn btn-outline-primary py-1"
          //           data-dismiss="modal"
          //           onClick={onCancel}
          //         >
          //           Cancel
          //         </button>
          //         <button
          //           type="button"
          //           className="btn py-1"
          //           onClick={() => {
          //             onOk();
          //             onQuestionStateChange.bind(scope, {
          //               survey: survey,
          //               surveyQuestionId: survey.SurveyQuestionId,
          //               response: false,
          //               level: level,
          //               subLevel: subLevel,
          //               subCatLevel: subCatLevel,
          //               index: sequenceNum,
          //               TabName: TabName,
          //               Sequence: Sequence,
          //               SurveyQuestionListItems: [SurveyQuestionListItems],
          //               OptKeyName: `IsArchived`,
          //             })();
          //           }}
          //         >
          //           Add
          //         </button>
          //       </div>
          //     </div>
          //   )}
          // />
          //   )

          <div>
            <AntdModal
              ModalButton={({ onClick }) =>
                isMoveAllowed && (
                  <button
                    onClick={onClick}
                    // disabled={!isMoveAllowed}
                    className="btn btn-outline-primary mx-2"
                  >
                    Move to
                  </button>
                )
              }
              ModalContent={({ onOk, onCancel }) => (
                <div className="modal-content border-0">
                  <div className="modal-header justify-content-center flex-wrap border-bottom-0">
                    <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                      &times;
                    </button>
                    <h4 className="modal-title col-12">Move Question</h4>
                    <span>Select a category to move the question:</span>
                  </div>
                  <div
                    className="modal-body mx-4 mb-3 pb-0 pt-2"
                    style={{ backgroundColor: '#F4F4F4' }}
                  >
                    <div
                      className="checkbox-to-radio"
                      style={{ maxHeight: '400px', overflowY: 'auto' }}
                    >
                      <Radio.Group
                        onChange={event => {
                          radioValue = event.target.value;
                          // this.onChangeRadio(val, OIndex + 1);
                          // this.setState({ radioValue : val});
                        }}
                        // className="justify-content-center"
                        // value={flagArray[OIndex]}
                      >
                        <div className="row" style={{ flexDirection: 'column' }}>
                          {SurveyQuestionList.SurveyQuestion.map((item, index) => {
                            const { Tab, Sequence, Questions, IsArchived } = item;
                            // return (
                            if (activeKey != index && !IsArchived) {
                              return (
                                <Radio
                                  value={Tab}
                                  style={{
                                    padding: '15px',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  {Tab}
                                </Radio>
                              );
                            }
                            // );
                          })}
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-end border-top-0 pb-5 pt-2">
                    <button
                      type="button"
                      className="btn btn-outline-primary py-1"
                      data-dismiss="modal"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn py-1"
                      onClick={() => {
                        onOk();
                        this.props.onMoveTo(radioValue, survey);
                      }}
                    >
                      Move
                    </button>
                  </div>
                </div>
              )}
            />

            <button
              onClick={() => {
                onQuestionStateChange.bind(scope, {
                  survey: survey,

                  surveyQuestionId: survey.SurveyQuestionId,
                  response: true,
                  // response: survey.Response == val ? survey.DoesNotApplyValue : val,
                  level: level,
                  subLevel: subLevel,
                  subCatLevel: subCatLevel,
                  index: sequenceNum,
                  TabName: TabName,
                  Sequence: Sequence,
                  SurveyQuestionListItems: [SurveyQuestionListItems],

                  OptKeyName: `IsArchived`,
                })();
              }}
              className="btn btn-outline-danger mx-2"
            >
              Remove
            </button>

            {/* <AntdModal
              ModalButton={({ onClick }) => (
                <button onClick={onClick} className="btn btn-outline-danger mx-2">
                  Remove
                </button>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div className="modal-content border-0">
                  <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                    <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                      &times;
                    </button>
                    <h4 className="modal-title col-12">
                      Are you sure you want to remove this question?
                    </h4>
                  </div>
                  <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      type="button"
                      className="btn btn-outline-primary py-1"
                      data-dismiss="modal"
                      onClick={onCancel}
                    >
                      No, keep it
                    </button>
                    <button
                      type="button"
                      className="btn py-1"
                      onClick={() => {
                        onOk();

                        onQuestionStateChange.bind(scope, {
                          survey: survey,

                          surveyQuestionId: survey.SurveyQuestionId,
                          response: true,
                          // response: survey.Response == val ? survey.DoesNotApplyValue : val,
                          level: level,
                          subLevel: subLevel,
                          subCatLevel: subCatLevel,
                          index: sequenceNum,
                          TabName: TabName,
                          Sequence: Sequence,
                          SurveyQuestionListItems: [SurveyQuestionListItems],

                          OptKeyName: `IsArchived`,
                        })();
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
            /> */}
          </div>
        )}
      </div>
    );
  }
}
export default QuestionConfigAction;
