import React, { Component } from 'react';
import { SparklinesLine, Sparklines } from 'react-sparklines';
import ListRenderer from '../Components/listRenderer';
import Loader from '../Components/Loader';
import {
  getDashboard,
  announcementGetAll,
  surveyTaskMarkInComplete,
  // studentTaskMarkComplete,
  AppSubscribersCompleteTask,
  resetAppSubscribersCompleteTask,
  deleteAppSubscribersTask,
  studentMarkComplete,
  studentUnAssign,
  resetDeleteAppSubscribersTask,
  // resetStudentTaskMarkComplete,
  resetStudentMarkComplete,
  resetStudentUnAssign,
  resetSurveyTaskMarkInComplete,
  loadJudicialDashboard,
  getStaffShiftLog,
  addStaffShiftLog,
  appInviteDashboard,
  resetStaffShiftLog,
  centerProgramTypeGet,
} from '../store/actions';
import { connect } from 'react-redux';
import { Typography, Tooltip, Progress } from 'antd';
import CustomModal from '../Components/CustomModal';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import moment from 'moment';
import { Modal, Button, Input } from 'antd';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import ReactApexChart from 'react-apexcharts';
import { Bar, HorizontalBar, Line, Doughnut, Pie } from 'react-chartjs-2';

const { Paragraph } = Typography;
const { TextArea } = Input;
const activeKey = 0;

const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Mobile app referral sent successfully',
  });
};

const ForbiddenUsers = ({ userData, history }) => {
  const {
    UserGuid,
    DisplayName,
    ProfileImgUrl,
    ForbiddenWord,
    Context,
    WordUsedDate,
    WordUsedTime,
  } = userData;
  return (
    <>
      <div
        style={{ background: '#FFF4F4', borderRadius: '8px' }}
        className="row pt-2 pb-2 mt-1 mb-1"
      >
        <div class="col-6 d-flex px-0 align-items-center">
          <img
            className="autoSelectImage"
            src={ProfileImgUrl ? ProfileImgUrl : 'images/blank-profile.png'}
            style={{ marginLeft: '5px' }}
          />
          <div>
            <div className="col-12" style={{ fontSize: '13px' }}>
              {DisplayName}
            </div>
            <div className="col-12 px-0">
              <i class="far fa-comments"></i>{' '}
              <span
                style={{
                  borderRadius: '2px',
                  background: '#FF7979',
                  color: 'white',
                  padding: '2px',
                  fontSize: '12px',
                }}
              >
                {ForbiddenWord}
              </span>
            </div>
          </div>
        </div>
        <div class="col-4 px-0 d-flex align-items-end justify-content-end flex-column">
          <span style={{ fontSize: '14px', color: '#9A9A9A' }}>{WordUsedDate}</span>
          <span style={{ fontSize: '14px', color: '#9A9A9A' }}>{WordUsedTime}</span>
        </div>
        <div class="col-2 d-flex align-items-center">
          <img
            src="images/timeline/timeline.svg"
            width="35px"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem('timelineFilterMultiCheckboxInteger', '25');
              history.push(`/timetravel/?subscriberId=${UserGuid}`);
            }}
          />
        </div>
      </div>
    </>
  );
};

class GreetingModal extends Component {
  render() {
    const { onOk, onCancel, handleSubmit, onModalCancel } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          {/* <h4 className="modal-title col-12">Welcome {localStorage.getItem('userName')}</h4> */}
        </div>
        <div className="modal-body col-md-10 offset-md-1 enrollment-form pb-2">
          <h6>Hi {localStorage.getItem('userName')}</h6>
          <div style={{ textAlign: 'justify', fontWeight: 600 }}>
            <p>Great to have you onboard. Your subscription to SoberPeer begins on </p>
            <p className="text-center">
              <span className="trial-start">1-May-2020</span>
            </p>
            <p>
              and your method of payment will be billed on{' '}
              <span className="trial-end">7-May-2020</span> for the month of May.
            </p>
            {/* <p>
              We hope you are enjoying the free trial and got to see the potential impact of the
              complete capabilities of SoberPeer.
            </p> */}
          </div>
          <div className="d-flex flex-column text-right">
            <h6>Team SoberPeer</h6>
          </div>
        </div>
        <div className="modal-footer justify-content-center border-top-0">
          <button
            type="button"
            className="btn black-btn font-weight-bold mr-2"
            onClick={onModalCancel}
          >
            Close
          </button>
          {/* <input type="submit" className="btn black-btn font-weight-bold mr-2" value=" Send  " /> */}
        </div>
      </div>
    );
  }
}

class SendAppInviteModal extends Component {
  onhandleSubmit = val => {
    const { MobileNumber, Email, FirstName, LastName, CenterProgramTypeId } = val;
    if (MobileNumber || Email) {
      this.props.dispatch(
        appInviteDashboard({
          MobileNumber,
          Email,
          FirstName,
          LastName,
          CenterProgramTypeId,
        }),
      );
    } else {
      error();
    }
  };

  componentWillReceiveProps({ isSuccess, val }) {
    if (isSuccess && isSuccess !== this.props.isSuccess) {
      success();
      this.props.dispatch(reset('inviteForm'));
    }
  }
  render() {
    const { onOk, onCancel, handleSubmit, centerData } = this.props;

    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 className="modal-title col-12">Send App Invitation</h4>
        </div>

        <div className="modal-body col-md-10 offset-md-1 enrollment-form">
          <form onSubmit={handleSubmit(this.onhandleSubmit)}>
            <div className="form-group mb-2">
              <Field
                name="FirstName"
                type="text"
                component={ReduxInput}
                validate={Validator.required}
                fieldName={'First Name'}
              />
              <label className="placeholder-label">First Name*</label>
            </div>
            <div className="form-group mb-2">
              <Field
                name="LastName"
                type="text"
                component={ReduxInput}
                validate={Validator.required}
                fieldName={'Last Name'}
              />
              <label className="placeholder-label">Last Name*</label>
            </div>
            <div className="form-group mb-2">
              <Field
                name="MobileNumber"
                type="text"
                component={ReduxInput}
                validate={Validator.allowNumericMobile}
                fieldName={'Mobile Number'}
              />
              <label className="placeholder-label">Mobile Number</label>
            </div>
            <div className="form-group mb-2">
              <Field
                name="Email"
                type="text"
                component={ReduxInput}
                validate={Validator.email}
                fieldName={'Email'}
              />
              <label className="placeholder-label">Email</label>
            </div>
            <div className="form-group mb-2">
              <Field
                name="CenterProgramTypeId"
                type="text"
                component={ReduxSelect}
                fieldName={'CenterProgramType'}
                placeholder={'Program'}
                options={centerData}
              />
            </div>
            <div className="modal-footer justify-content-center border-top-0">
              <input
                type="submit"
                className="btn black-btn font-weight-bold mr-2"
                value=" Send  "
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToPropsModal = state => {
  const { app_invite_dashboard } = state;
  const { data } = app_invite_dashboard;
  let isSuccess = false;
  if (app_invite_dashboard.data && app_invite_dashboard.data.Success) {
    isSuccess = data.Success;
  }
  return {
    isSuccess,
    // name: selector(state, 'firstName'),
  };
};

const SendAppInviteReduxModal = reduxForm({
  form: 'inviteForm',
})(connect(mapStateToPropsModal)(withRouter(SendAppInviteModal)));

// const SendAppInviteReduxModal = reduxForm({
//   form: 'inviteForm',
// })(connect(null)(withRouter(SendAppInviteModal)));
class StaffLogModal extends Component {
  constructor(props) {
    super(props);
    const { startDate } = props;
    this.state = {
      startDate: startDate || null,
      staffLogNotes: '',
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  resetState = () => this.setState({ startDate: '', filterCenterId: '' });
  addNote = () => {
    this.props.dispatch(addStaffShiftLog({ Note: this.state.staffLogNotes }));
    this.props.onOk();
  };
  render() {
    const { onOk, onCancel, arrShiftLog } = this.props;
    const { startDate } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          className={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label className="placeholder-label">Start date</label>
      </>
    );
    return (
      <div className="modal-content border-0">
        <div className="modal-header flex-wrap border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 className="modal-title col-12 text-left">Shift Log</h4>
        </div>
        <div className="modal-body">
          <div className="row m-0">
            {/* <div className="col-12 text-left mb-3">
              <span className="font-weight-bold "> Staff Log Notes</span>
            </div> */}
            <div className="col-12 col-md-12 enrollment-form">
              <div className="form-group">
                {/* <label className="patient-label">Staff Log</label> */}
                {/* <textarea
                  style={{ minHeight: '75px' }}
                  className="form-control"
                  placeholder="Add Shift Note "
                  rows="3"
                  value={this.state.staffLogNotes}
                  onChange={event => this.setState({ staffLogNotes: event.target.value })}
                /> */}
                <TextArea
                  showCount
                  maxLength={2000}
                  style={{ minHeight: '75px' }}
                  class="form-control"
                  placeholder="Add Shift Note"
                  rows="3"
                  value={this.state.staffLogNotes}
                  onChange={event => this.setState({ staffLogNotes: event.target.value })}
                />
              </div>
            </div>
            <div className="col-12 col-md-12" style={{ textAlign: 'right' }}>
              <button type="button" className="btn black-btn" onClick={this.addNote}>
                Add
              </button>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12 text-left mb-3">
              <span className="font-weight-bold ">Shift Notes</span>
            </div>
            <div
              className="col-12 col-md-12 enrollment-form"
              style={{ height: '200px', overflowY: 'scroll' }}
            >
              {arrShiftLog &&
                arrShiftLog.map(item => {
                  return (
                    <div className="shift-log-detail">
                      <div className="text-left mb-1 d-flex justify-content-between">
                        <div>
                          <span className="font-weight-bold ">Staff Name : </span>
                          <span>{item.StaffName} </span>
                        </div>
                        <div>
                          <span className="font-weight-bold ">Created Date : </span>
                          <span>{item.CreatedDate} </span>
                        </div>
                      </div>
                      <div className="text-left mb-1">
                        <span className="font-weight-bold ">Shift Note : </span>
                        <span>{item.ShigtLogNote} </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Filter By Date</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => this.handleChange('startDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="col-12 text-left mb-1">
                <span class="font-weight-bold ">Filtered By Date : </span>
                <span>10 jan 2020 </span>
              </div>
            </div>
            <div class="col-12 col-md-12 enrollment-form">
              <div class="col-12 col-md-6 enrollment-form">
                <div class="text-left mb-1">
                  <span class="font-weight-bold ">Created By : </span>
                  <span>Pawan </span>
                </div>
              </div>
              <div class="col-12 text-left mb-1">
                <span class="font-weight-bold ">Staff Log Note :</span>
              </div>
              <div class="form-group">
                It is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout. It is a long established fact that a
                reader will be distracted by the readable content of a page when looking at its
                layout
              </div>
            </div>
          </div>
        */}
        </div>
        {/* <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
        </div> */}
      </div>
    );
  }
}

class JudicialDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      requestData: false,
      inviteFormModal: false,
      greetingModal: false,
      ClientSeries: [],
      clientOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10,
              minAngleToShowLabel: 20,
            },
          },
        },

        tooltip: {
          enabled: false,
        },
        labels: [], //mouse over text
        colors: ['#D85C5C', '#4F99D2', '#2FBB7D', '#1A45DF', '#FFA800'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  }
  triggerModal = () => {
    this.setState(prevState => {
      return { showModal: !prevState.showModal };
    });
  };
  onTaskInComplete = surveyId => {
    this.state.requestData = true;
    var data = {
      surveyId,
    };
    this.props.dispatch(surveyTaskMarkInComplete(data));
  };

  onTaskComplete = TaskUserId => {
    this.state.requestData = true;
    var data = {
      TaskUserId,
    };
    // this.props.dispatch(studentTaskMarkComplete(data));
    this.props.dispatch(AppSubscribersCompleteTask(data));
  };

  // TaskUserId: 88

  onTaskUnAssign = TaskUserId => {
    this.state.requestData = true;
    var data = {
      TaskUserId,
    };
    this.props.dispatch(deleteAppSubscribersTask(data));
  };

  onUnAssign(studentId, courseCenterStudentId) {
    this.state.requestData = true;
    var data = {
      studentId,
      courseCenterStudentId,
    };

    this.props.dispatch(studentUnAssign(data));
  }

  onMarkComplete(studentId, courseCenterStudentId) {
    this.state.requestData = true;
    var data = {
      studentId,
      courseCenterStudentId,
    };

    this.props.dispatch(studentMarkComplete(data));
  }

  onAnnouncementClick = url => {
    return <Redirect push to={url} target="_blank" />;
  };

  getMulti = array => {
    for (let item of array) {
      item.count = 0;
      for (let checkItem of array) {
        if (item.StudentId == checkItem.StudentId) {
          item.count += 1;
        }
      }
    }
    let indexArray = [...new Set(array.map(item => item.StudentId))],
      newArray = [];
    for (let item of indexArray) {
      newArray.push(array.find(aItem => aItem.StudentId == item));
    }
    return newArray;
  };
  getDuration(fromdate) {
    var day = moment(moment().diff(fromdate));
    return `${day.month()} Month ${day.dates()} days`;
  }

  addNote = () => {
    this.props.dispatch(addStaffShiftLog({ Note: this.state.staffLogNotes }));
    this.props.onOk();
  };

  componentDidMount() {
    this.props.dispatch(getDashboard());
    this.props.dispatch(loadJudicialDashboard());
    this.props.dispatch(getStaffShiftLog({}));
    this.props.dispatch(announcementGetAll({ filter: null }));
    this.props.dispatch(centerProgramTypeGet({}));
  }

  componentWillReceiveProps({
    isMarkComplete,
    isUnAssigned,
    isTaskCompleted,
    isTaskUnAssigned,
    isTaskMarkInComplete,
    isStaffShiftLog,
    judicialData,
  }) {
    if (isTaskUnAssigned) {
      this.props.dispatch(resetDeleteAppSubscribersTask());

      this.props.dispatch(loadJudicialDashboard());
    }
    if (isTaskCompleted) {
      this.props.dispatch(resetAppSubscribersCompleteTask());

      this.props.dispatch(loadJudicialDashboard());
    }

    if (isMarkComplete) this.props.dispatch(resetStudentMarkComplete());
    if (isUnAssigned) this.props.dispatch(resetStudentUnAssign());
    if (isTaskMarkInComplete) this.props.dispatch(resetSurveyTaskMarkInComplete());
    if (
      (isMarkComplete ||
        isUnAssigned ||
        isTaskCompleted ||
        isTaskUnAssigned ||
        isTaskMarkInComplete) &&
      this.state.requestData
    ) {
      this.state.requestData = false;
      this.props.dispatch(getDashboard());
    }
    if (isStaffShiftLog) {
      this.props.dispatch(resetStaffShiftLog());
      this.props.dispatch(getStaffShiftLog({}));
    }
    if (judicialData && JSON.stringify(judicialData) != JSON.stringify(this.props.judicialData)) {
      const { ActiveClients, DashboardBlocks, ForbiddenWordsData } = judicialData;
      // let ActiveClientCountHeader = Header;
      let ActiveClientCount =
        ActiveClients &&
        ActiveClients[0] &&
        ActiveClients[0].Detail &&
        ActiveClients[0].Detail[0].ActiveClientCount;
      let InactiveClientsCount =
        ActiveClients &&
        ActiveClients[0] &&
        ActiveClients[0].Detail &&
        ActiveClients[0].Detail[0].InactiveClientsCount;
      let SPBRatingHeader =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].SPBRating &&
        DashboardBlocks[0].SPBRating[0].Header;
      let SPBRatingScore =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].SPBRating &&
        DashboardBlocks[0].SPBRating[0].Detail[0].Score;
      let SPBRatingName =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].SPBRating &&
        DashboardBlocks[0].SPBRating[0].Detail[0].Name;
      let LocationServicesHeader =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].LocationServices &&
        DashboardBlocks[0].LocationServices[0].Header;
      let LocationServicesDetail =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].LocationServices &&
        DashboardBlocks[0].LocationServices[0].Detail;
      let barData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['	#fb4f4f', '	#fbc93d', '#6cc0e5'],
          },
        ],
      };
      LocationServicesDetail &&
        LocationServicesDetail.map(item => {
          barData.labels.push(item.Name);
          barData.datasets[0].data.push(parseInt(item.Score));
        });

      let SurveysUptoDateHeader =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].SurveysUptoDate &&
        DashboardBlocks[0].SurveysUptoDate[0].Header;
      let SurveysUptoDateDetail =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].SurveysUptoDate &&
        DashboardBlocks[0].SurveysUptoDate[0].Detail;
      let SurveysUptoDateScore = [];
      SurveysUptoDateDetail &&
        SurveysUptoDateDetail.map(item => {
          SurveysUptoDateScore.unshift(parseInt(item.Score));
        });
      let TriggerWarningsHeader =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].TriggerWarnings &&
        DashboardBlocks[0].TriggerWarnings[0].Header;
      let TriggerWarningsDetail =
        DashboardBlocks &&
        DashboardBlocks[0] &&
        DashboardBlocks[0].TriggerWarnings &&
        DashboardBlocks[0].TriggerWarnings[0].Detail;
      this.setState({
        ClientSeries: [parseInt(InactiveClientsCount), parseInt(ActiveClientCount)],
        ActiveClientCount: parseInt(ActiveClientCount),
        InactiveClientsCount: parseInt(InactiveClientsCount),
        SPBRatingHeader,
        SPBRatingScore,
        SPBRatingName,
        LocationServicesHeader,
        LocationServicesDetail,
        LocationServicesBarData: barData,
        SurveysUptoDateHeader,
        SurveysUptoDateDetail,
        SurveysUptoDateScore,
        TriggerWarningsHeader,
        TriggerWarningsDetail,
        ForbiddenWordsData,
      });
    }
  }

  render() {
    const {
      activeTab,
      inviteFormModal,
      greetingModal,
      ClientSeries,
      ActiveClientCount,
      InactiveClientsCount,
      SPBRatingHeader,
      SPBRatingScore,
      SPBRatingName,
      LocationServicesHeader,
      LocationServicesDetail,
      LocationServicesBarData,
      SurveysUptoDateHeader,
      SurveysUptoDateDetail,
      SurveysUptoDateScore,
      TriggerWarningsHeader,
      TriggerWarningsDetail,
      ForbiddenWordsData,
    } = this.state;
    const {
      dashboardData,
      announcementData,
      isFetching,
      judicialData,
      isFetchingJudicial,
      lastShiftLog,
      staffShiftLog,
    } = this.props;
    const { CourseStudent, SurveyStudent, TaskStudent, StudentContract } = dashboardData || {};
    // let { SurveyUser, TaskUser } = judicialData || {};
    let SurveyUser =
      judicialData && judicialData.SurveyUser && judicialData.SurveyUser.length > 0
        ? judicialData.SurveyUser
        : [];
    let TaskUser =
      judicialData && judicialData.TaskUser && judicialData.TaskUser.length > 0
        ? judicialData.TaskUser
        : [];
    if (dashboardData) {
      const { ChartData, DashboardBlocks } = dashboardData;
    }
    const barOptions = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            maxBarThickness: 48,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              // max: 5,
              min: 0,
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          align: function(context) {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
            var invert = Math.abs(value) <= 1;
            return value < 1 ? 'end' : 'start';
          },
          anchor: 'end',
          backgroundColor: null,
          borderColor: null,
          borderRadius: 4,
          borderWidth: 1,
          color: '#fff',
          font: {
            size: 11,
            weight: 600,
          },
          offset: 4,
          padding: 0,
          formatter: function(value) {
            return `${Math.round(value * 10) / 10}%`;
          },
        },
      },
    };

    // {
    //   "TodoTitle": "Make your bed",
    //   "TaskId": 26,
    //   "TaskUserId": 63,
    //   "UserGuid": "569F657E-E7D9-4D89-B272-75FE90A8B3DF",
    //   "DisplayName": "Vishnu",
    //   "ProfileImageMediaId": 406,
    //   "UserImageUrl": "https://drattach.blob.core.windows.net/dr-blob-container-v1/dd22be7b-f7b7-475c-ae22-528c83e87755.j",
    //   "AssignedDate": "Yesterday",
    //   "AssignedDateForSort": "20201221",
    //   "DueDate": "Yesterday",
    //   "DueDateForSort": "20201221",
    //   "DueDateFontColor": "#b60c0c",
    //   "TaskCount": "6"
    // },

    const list = [
      {
        Name: `Tasks`,
        Badge: TaskUser && TaskUser.length > 0 ? TaskUser[0].TaskCount : '0',
        Component: props => {
          const {
            TodoType,
            StudentId,
            FirstName,
            LastName,
            ProfileImgAttachmentId,
            AssignedDate,
            AssignedDateForSort,
            DisplayName,
            DueDate,
            DueDateFontColor,
            DueDateForSort,
            ProfileImageMediaId,
            TaskCount,
            TaskId,
            TaskUserId,
            TodoTitle,
            UserGuid,
            UserImageUrl,
            index,
          } = props;
          return (
            <div className="task-box dashboard-tabs">
              <div className="row no-gutters w-100 justify-content-between align-items-center">
                <div className="col-5 mb-1">
                  <div className="course-name" style={{ width: 'unset' }}>
                    <h6 className="text-capitalize">{TodoTitle}</h6>
                    <span className="due-date" style={{ minWidth: 'unset' }}>
                      <em>Due : </em>
                      {DueDate}
                    </span>
                  </div>
                </div>
                <div className="col-5 mb-1">
                  <div className="studentinfo">
                    <figure>
                      <img
                        src={UserImageUrl ? UserImageUrl : 'images/announcements-img2.jpg'}
                        className="rounded-circle face-fit-cover"
                        width="47"
                        height="47"
                        alt=""
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.props.history.push('/subscriberProfile/' + UserGuid);
                        }}
                      />
                    </figure>
                    <span
                      style={{ cursor: 'pointer' }}
                      className="student-name"
                      onClick={() => {
                        this.props.history.push('/subscriberProfile/' + UserGuid);
                      }}
                    >
                      {DisplayName}
                    </span>
                  </div>
                </div>
                <div className="col-2">
                  <div className="actions-button justify-content-xl-end">
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Un-assign">
                          <span className="delete-course" onClick={onClick}>
                            <i class="fas fa-times" />
                          </span>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div className="modal-content border-0">
                          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              &times;
                            </button>
                            <h4 className="modal-title col-12">
                              Do you want to un-assign this task?
                            </h4>
                          </div>

                          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              type="button"
                              className="btn black-btn"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn "
                              onClick={() => {
                                onOk();
                                this.onTaskUnAssign(TaskUserId);
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Complete">
                          <span className="select-course" onClick={onClick}>
                            <i class="select-icon" />
                          </span>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div className="modal-content border-0">
                          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              &times;
                            </button>
                            <h4 className="modal-title col-12">
                              Do you want to mark this task as complete?
                            </h4>
                          </div>

                          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              type="button"
                              className="btn black-btn"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn "
                              onClick={() => {
                                onOk();
                                this.onTaskComplete(TaskUserId);
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: TaskUser,
      },
      {
        Name: 'Surveys',
        Badge: SurveyUser && SurveyUser.length ? SurveyUser[0].SurveyCount : '0',
        Component: props => {
          const {
            StudentId,
            FirstName,
            LastName,
            ProfileImgAttachmentId,
            StudentImageUrl,
            count,
            AssignedByStaffId,
            AssignedDate,
            DisplayName,
            DueDate,
            DueDateFontColor,
            DueDateForSort,
            DueStatus,
            ProfileImageMediaId,
            StartedDate,
            SurveyCount,
            SurveyId,
            SurveyName,
            SurveyQuestionCategoryId,
            UserGuid,
            UserImageUrl,
            index,
          } = props;
          return (
            <div className="task-box">
              <div className="row no-gutters w-100 justify-content-between align-items-center">
                <div className="col-12 col-md-5 mb-1">
                  <div
                    style={{ width: 'unset', cursor: 'pointer' }}
                    className="course-name"
                    onClick={() => {
                      // this.props.history.push(
                      //   count == 1
                      //     ? '/validate?surveyId=' + SurveyId + '&studentId=' + UserGuid
                      //     : `/surveyer/?studentId=${UserGuid}`,
                      // );
                      this.props.history.push('/subscriberProfile/' + UserGuid);
                    }}
                  >
                    <h6>{count > 1 ? 'Multiple Surveys(' + count + ')' : SurveyName}</h6>
                    <span className="due-date" style={{ minWidth: 'unset' }}>
                      <em>Due : </em> {DueDate}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-5 mb-1">
                  <div
                    className="studentinfo"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.props.history.push('/subscriberProfile/' + UserGuid);
                    }}
                  >
                    <figure>
                      <img
                        src={UserImageUrl ? UserImageUrl : 'images/announcements-img2.jpg'}
                        className="rounded-circle face-fit-cover"
                        width="47"
                        height="47"
                        alt=""
                      />
                    </figure>
                    <span className="student-name">{DisplayName}</span>
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="actions-button justify-content-xl-end">
                    {count == 1 ? (
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title={'Incomplete'}>
                            <span className="delete-course" onClick={onClick}>
                              <i class="fas fa-times" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div className="modal-content border-0">
                            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>

                              <h4 className="modal-title col-12">
                                Do you want to mark this survey as incomplete?
                              </h4>
                            </div>

                            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                className="btn black-btn"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn "
                                onClick={() => {
                                  onOk();
                                  this.onTaskInComplete(SurveyId);
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    ) : (
                      <span className="delete-course" style={{ visibility: 'hidden' }}>
                        <i class="fas fa-times" />
                      </span>
                    )}
                  </div>
                </div>
                {DueDateFontColor && <span className="alert-danger-dot" />}
              </div>
            </div>
          );
        },
        Content: SurveyUser,
      },
    ];

    var role = localStorage.getItem('userRole');
    return (
      <div className="main coach-dashboard">
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-12 h2 text-center pb-4">{judicialData.CenterName}</div>
          </div>
          <div className="row pt-3 pb-3 pt-lg-4 pb-lg-4 justify-content-center">
            <div className="col-12 col-md-5"></div>
            <div className="col-12 col-md-7">
              <div className="d-flex justify-content-end">
                <div className="mr-2 position-relative">
                  <CustomModal
                    ModalButton={({ onClick }) => (
                      <>
                        {lastShiftLog && lastShiftLog.NewNote == 1 && (
                          <span className="new-badge">New</span>
                        )}
                        <button
                          type="button"
                          className="btn btn-outline-primary px-5"
                          onClick={e => {
                            onClick();
                          }}
                        >
                          Shift Log
                        </button>
                      </>
                    )}
                    ModalContent={props => (
                      <StaffLogModal
                        {...props}
                        dispatch={this.props.dispatch}
                        arrShiftLog={staffShiftLog}
                      />
                    )}
                  />
                </div>
                <div className="">
                  <button
                    className="btn px-4"
                    type="button"
                    onClick={() => {
                      this.setState({
                        inviteFormModal: true,
                      });
                    }}
                  >
                    Send Mobile App Referral
                  </button>
                  <Modal
                    open={inviteFormModal}
                    footer={null}
                    closable={false}
                    // onCancel={() => {
                    //   this.setState({
                    //     inviteFormModal: false,
                    //   });
                    // }}
                  >
                    <SendAppInviteReduxModal
                      onCancel={() => {
                        this.setState({
                          inviteFormModal: false,
                        });
                      }}
                      centerData={this.props.centerData}
                    />
                  </Modal>
                  {sessionStorage.getItem('greetingModal') == 'true'
                    ? localStorage.getItem('userName') && (
                        <Modal
                          visible={greetingModal}
                          footer={null}
                          zIndex={100}
                          onCancel={this.onModalCancel}
                        >
                          <GreetingModal onModalCancel={this.onModalCancel} />
                        </Modal>
                      )
                    : null}
                  {/* {isAchVarification.required ? (
                    <Modal
                      visible={achModal}
                      footer={null}
                      zIndex={99}
                      closable={false}
                      mask
                      maskClosable={false}
                      onCancel={this.onModalCancel}
                    >
                      <ConfirmACHPayment
                        onModalCancel={this.onachModalCancel}
                        info={isAchVarification.info}
                      />
                    </Modal>
                  ) : null} */}
                  {/* {this.state.firstLogin == 1 && (
                    <Modal
                      width={700}
                      zIndex={500}
                      closable={false}
                      mask
                      maskClosable={false}
                      centered
                      visible={this.state.firstLoginModal}
                      footer={null}
                      onCancel={this.onLicenseModalCancel}
                    >
                      <LicenseModal onLicenseModalCancel={this.onLicenseModalCancel} />
                    </Modal>
                  )} */}
                </div>
                <Link to="/sessionDashboard" className="btn btn-outline-primary mx-2" color="blue">
                  Manage Telehealth Sessions
                </Link>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <h3 className="page-title">Statistics</h3>
            {role === 'Super Admin' && (
              <Link
                className="btn px-4"
                to={{
                  pathname: '/dashboardAnalytics',
                }}
                target="_blank"
              >
                Analytics
              </Link>
            )}
            <Loader loading={isFetching} />
            <Loader loading={isFetchingJudicial} />
            <div className="text-right">
              {'Dollars Saved' + ': '}
              {'  '}
              <span style={{ fontWeight: 900, fontSize: '21px' }}>{judicialData.DollarsSaved}</span>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xl-7 dashboard-col-left">
              <div className="row">
                {ForbiddenWordsData && ForbiddenWordsData.length > 0 && (
                  <div className="col-sm-6  mb-4">
                    <div className="card jud-card border-0 inner-content">
                      <div className="row  align-items-center card-body">
                        {/* <div className="col-12 pt-3 px-0">
                      <h6 className="text-uppercase font-weight-bold mb-3">
                        {TriggerWarningsHeader}
                      </h6>
                    </div> */}
                        <div className="col-xl-12 col-12">
                          <div class="row" style={{ padding: '0.25rem' }}>
                            <div class="col-12">
                              <div className="d-flex justify-content-between align-items-baseline">
                                <h6 class="text-uppercase font-weight-bold mb-3">
                                  {'suspicious Term'}
                                </h6>
                                <div
                                  className="d-flex align-items-center"
                                  onClick={this.triggerModal}
                                >
                                  <span
                                    style={{
                                      fontSize: '18px',
                                      color: '#FF7979',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {ForbiddenWordsData && ForbiddenWordsData.length}
                                  </span>
                                  &nbsp;
                                  <i
                                    class="fas fa-external-link-alt"
                                    style={{ color: '#46b4fe', cursor: 'pointer' }}
                                  ></i>
                                </div>
                                <Modal
                                  zIndex={100}
                                  visible={this.state.showModal}
                                  footer={null}
                                  closable={false}
                                  width={500}
                                  destroyOnClose={true}
                                >
                                  <div class="modal-content border-0">
                                    <div class="modal-header flex-wrap border-bottom-0 ">
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        onClick={() => {
                                          this.setState({
                                            showModal: false,
                                          });
                                        }}
                                      >
                                        ×
                                      </button>
                                    </div>
                                    <div class="modal-body rcm-panel">
                                      <h5 style={{ textTransform: 'uppercase' }}>
                                        suspicious Term(s)
                                      </h5>
                                      <h6 style={{ fontSize: '13px' }}>
                                        {ForbiddenWordsData && ForbiddenWordsData.length} of your
                                        client(s) have used suspicious term(s).
                                      </h6>

                                      <div
                                        style={{
                                          width: '436px',
                                          height: '400px',
                                          margin: '0 auto',
                                          padding: '20px',
                                          overflowX: 'auto',
                                        }}
                                      >
                                        {ForbiddenWordsData &&
                                          ForbiddenWordsData.map((item, index) => {
                                            return (
                                              <ForbiddenUsers
                                                history={this.props.history}
                                                userData={item}
                                              />
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </Modal>
                              </div>
                            </div>
                            <div className="col-12">
                              {ForbiddenWordsData && ForbiddenWordsData[0] && (
                                <ForbiddenUsers
                                  triggerModal={this.triggerModal}
                                  history={this.props.history}
                                  userData={ForbiddenWordsData && ForbiddenWordsData[0]}
                                />
                              )}
                              {ForbiddenWordsData && ForbiddenWordsData[1] && (
                                <ForbiddenUsers
                                  triggerModal={this.triggerModal}
                                  history={this.props.history}
                                  userData={ForbiddenWordsData && ForbiddenWordsData[1]}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-6  mb-4">
                  <div className="card border-0 inner-content">
                    <div className="row card-body align-items-center">
                      <div className="col-12">
                        <h6 className="text-uppercase">
                          {judicialData.ActiveClients && judicialData.ActiveClients[0].Header}
                        </h6>
                      </div>
                      <div className="col-6">
                        <ul
                          className="mt-2 p-0 judicial-chart-bullet"
                          key={'judicial-chart-bullet'}
                        >
                          <li>{ActiveClientCount} Active</li>
                          <li>{InactiveClientsCount} Inactive</li>
                        </ul>
                      </div>
                      <div className="col-6">
                        {SurveysUptoDateScore && (
                          <ReactApexChart
                            options={this.state.clientOptions}
                            // series={[InactiveClientsCount, ActiveClientCount]}
                            series={ClientSeries || [0, 0]}
                            type="pie"
                            width={150}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6  mb-4">
                  <div className="card border-0 inner-content">
                    <div className="row card-body">
                      <div className="col-sm-6">
                        <h6>{SPBRatingHeader}</h6>
                      </div>
                      <div className="col-sm-6 text-center">
                        <div className="mb-2">
                          <Progress
                            type="circle"
                            strokeWidth={7}
                            strokeColor="#2FE192"
                            width={105}
                            percent={SPBRatingScore || 0}
                            format={percent => (
                              <div className="progress-textbox">
                                <strong>{percent}</strong>
                              </div>
                            )}
                            trailColor={'#eff7fe'}
                          />
                        </div>
                        <span className="">{SPBRatingName}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6  mb-4">
                  <div className="card border-0 inner-content">
                    <div className="row card-body">
                      <div className="col-12">
                        <h6 className="text-uppercase pt-2">{LocationServicesHeader}</h6>
                      </div>
                      <div className="col-12">
                        <Bar data={LocationServicesBarData || []} options={barOptions} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6  mb-4">
                  <div className="card border-0 inner-content">
                    <div className="row card-body align-items-center">
                      <div className="col-12">
                        <h6 className="text-uppercase">{SurveysUptoDateHeader}</h6>
                      </div>
                      <div className="col-6">
                        <ul className="mt-2 p-0 judicial-chart-bullet">
                          {SurveysUptoDateDetail &&
                            SurveysUptoDateDetail.map((item, index) => {
                              return (
                                <li key={index}>
                                  {item.Score} {item.Name}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="col-6">
                        {SurveysUptoDateScore && (
                          <ReactApexChart
                            options={this.state.clientOptions}
                            series={[SurveysUptoDateScore[0], SurveysUptoDateScore[1]]}
                            // series={SurveysUptoDateScore}
                            type="pie"
                            width={150}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6  mb-4">
                  <div className="card jud-card border-0 inner-content">
                    <div className="row  align-items-center card-body px-5">
                      <div className="col-12 pt-3 px-0">
                        <h6 className="text-uppercase font-weight-bold mb-3">
                          {TriggerWarningsHeader}
                        </h6>
                      </div>
                      <div className="col-xl-12 col-12">
                        <span>
                          {TriggerWarningsDetail &&
                            TriggerWarningsDetail.map(item => {
                              return (
                                <>
                                  <div className="row">
                                    <div className="col-md-4 border p-2">{item.Score}</div>
                                    <div className="col-md-8 border p-2"> {item.Name}</div>
                                  </div>
                                </>
                              );
                            })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-between align-items-baseline mb-4 announcements-row">
                <div className="col-12 col-sm-auto ">
                  <h3 className="page-title mb-0">Announcements</h3>
                </div>
              </div>

              <div className="row">
                {announcementData &&
                  announcementData.map((item, index) => {
                    const {
                      Abstract,
                      AnnouncementId,
                      CenterId,
                      EndDate,
                      ImageUrl,
                      IsMain,
                      Link,
                      RankId,
                      StartDate,
                      Title,
                      DaysAgo,
                      Tense,
                    } = item;
                    if (Tense == 'Current' || role == 'Super Admin') {
                      return (
                        <div className="col-sm-6 col-xl-4 mb-4">
                          <div
                            className="card announcements-card border-0 "
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (Link) {
                                const win = window.open(Link, '_blank');
                                if (win != null) {
                                  win.focus();
                                }
                              }
                            }}
                          >
                            <figure
                              className="images-placeholder"
                              style={{
                                backgroundImage: `url(${ImageUrl})`,
                              }}
                            >
                              <img
                                className="card-img-top img-background"
                                src="images/announcements-img1.jpg"
                                alt=""
                              />
                            </figure>
                            <div className="announcement-card-body">
                              {DaysAgo === 0 ? (
                                <span className="date">Today</span>
                              ) : (
                                <span className="date">{DaysAgo} days ago</span>
                              )}
                              {/* <span>{this.getDuration(SubscriberProfile.AppJoiningDate)}</span> */}
                              <h5 className="card-title mb-2 h5">{Title}</h5>
                              <Paragraph
                                // ellipsis={{ rows: 3, expandable: false }}
                                className="card-body-text"
                                // style={{ color: '#828282' }}
                              >
                                {Abstract}
                              </Paragraph>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className="col-lg-6 col-xl-5 dashboard-tabcol dashboard-col-right mb-5 res-update">
              <div className="card border-0">
                <div className="card-body center-tabs">
                  <ListRenderer list={list} activeKey={activeKey} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  dashboard_get,
  announcement_get_all,
  survey_task_mark_in_complete,
  // student_task_mark_complete,
  app_subscribers_complete_task,
  delete_app_subscribers_task,
  student_unassign,
  student_mark_complete,
  load_judicial_dashboard,
  get_staff_shift_log,
  add_staff_shift_log,
  center_program_type_get,
}) => {
  const { data, error, isFetching } = dashboard_get;
  const { data: loadData } = get_staff_shift_log;
  const { data: loadCenterData } = center_program_type_get;
  const { data: judicialDashboardData, isFetching: isFetchingJudicial } = load_judicial_dashboard;

  let dashboardData = null,
    announcementData = null,
    isMarkComplete = false,
    isUnAssigned = false,
    isTaskCompleted = false,
    isTaskMarkInComplete = false,
    judicialData = {};
  var StaffShiftLog = [];
  var LastShiftLog = {};
  let isStaffShiftLog = false;
  let isTaskUnAssigned = false;
  let centerData = {};

  if (loadData && loadData.StaffShiftLog) {
    StaffShiftLog = loadData.StaffShiftLog;
    LastShiftLog = loadData.StaffShiftLog[0];
  }
  if (loadCenterData) {
    centerData = loadCenterData.data;
  }

  if (judicialDashboardData) {
    judicialData = judicialDashboardData.DashboardJudicialMetrics[0];
  }

  if (data) {
    for (let i = 0; i < data.DashboardBlocks.length; i++) {
      if (data.ChartData[i] && data.ChartData[i].DataAgg)
        data.DashboardBlocks[i].chartData = data.ChartData[i].DataAgg.split(',');
    }
    dashboardData = data;
  }

  if (announcement_get_all.data) {
    announcementData = Object.values(announcement_get_all.data).filter(
      item => item.IsAnnouncementActive,
    );
  }

  if (student_mark_complete && student_mark_complete.data) {
    isMarkComplete = true;
  }

  if (student_unassign && student_unassign.data) {
    isUnAssigned = true;
  }

  // if (student_task_mark_complete && student_task_mark_complete.data) {
  //   isTaskMarkComplete = true;
  // }

  if (app_subscribers_complete_task && app_subscribers_complete_task.data) {
    isTaskCompleted = app_subscribers_complete_task.data.success;
  }

  if (survey_task_mark_in_complete && survey_task_mark_in_complete.data) {
    isTaskMarkInComplete = true;
  }
  if (add_staff_shift_log.data) {
    isStaffShiftLog = add_staff_shift_log.data.success;
  }
  if (delete_app_subscribers_task && delete_app_subscribers_task.data) {
    isTaskUnAssigned = delete_app_subscribers_task.data.success;
  }

  return {
    dashboardData,
    announcementData,
    isMarkComplete,
    isUnAssigned,
    isTaskCompleted,
    isTaskUnAssigned,
    isTaskMarkInComplete,
    isFetching,
    judicialData,
    isFetchingJudicial,
    staffShiftLog: StaffShiftLog,
    lastShiftLog: LastShiftLog,
    isStaffShiftLog,
    centerData,
  };
};

export default connect(mapStateToProps)(withRouter(JudicialDashboard));
