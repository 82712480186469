import React, { Component } from 'react';
import CustomImageComponent from './customImageComponent';
import { panasTagUpsert } from '../../store/actions';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedMoodIds: [] };
  }
  updateSelectedMoods = value => {
    let tempSelection = this.props.moodsList.filter(item => {
      if (value && value.includes(item.value)) {
        return item;
      }
    });
    let result = tempSelection.map((item, index) => {
      return item.MoodId;
    });
    this.setState({ selectedMoodIds: result });
  };
  onPick(image) {
    this.setState({ image });
  }

  render() {
    return (
      <div>
        <CustomImageComponent
          images={this.props.moodsList}
          PANASTag={this.props.PANASTag}
          updateSelectedMoods={this.updateSelectedMoods}
        />

        <button
          type="button"
          className="btn"
          style={{ margin: '5px auto', display: 'block' }}
          onClick={() => {
            const { PANASMoodId, PANASTagId } = this.props.PANASTag;
            let moodData;
            if (this.state.selectedMoodIds.length > 0) {
              moodData = this.state.selectedMoodIds.toString();
            } else {
              moodData = this.props.PANASTag.PANASMoodId;
            }
            let jsonData;
            if (this.props.formType == 'AppointmentNoteId') {
              jsonData = {
                PANASTag: [
                  {
                    PANASTagId: PANASTagId ? parseInt(PANASTagId) : null,
                    PANASMoodId: moodData,
                    FormType: this.props.formType,
                    FormId: this.props.formId,
                    ClientId: this.props.ClientId,
                  },
                ],
              };
            } else {
              jsonData = {
                PANASTag: [
                  {
                    PANASTagId: PANASTagId ? parseInt(PANASTagId) : null,
                    PANASMoodId: moodData,
                    FormType: this.props.formType,
                    FormId: this.props.formId,
                  },
                ],
              };
            }

            this.props.dispatch(
              panasTagUpsert({
                Json: JSON.stringify(jsonData),
              }),
            );
            this.props.hide();
          }}
        >
          Apply Moods
        </button>
      </div>
    );
  }
}

export default App;
