import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import {
  ReduxInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../../component/custom-redux-components';
import SubmitButton from '../../../../../Components/submitButton';
import { CenterOption } from './centerOption';
import { CareTeamMemberOption } from './careTeamMemberOption';
import {
  organizationCenterFamilyCombo,
  comboServiceCode,
  careTeamMemberCombo,
  resetCareTeamMemberCombo,
} from '../../../../../store/actions';
import validate from '../../../validation/validate';
import Loader, { Loader2 } from '../../../../../Components/Loader';

class CareTeamMember extends Component {
  constructor() {
    super();
    this.state = {};
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentDidMount() {
    this.props.dispatch(
      comboServiceCode({
        Json: JSON.stringify({
          ServiceCode: [
            {
              ServiceCodeTypeId: 2,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(organizationCenterFamilyCombo({}));
  }
  componentWillReceiveProps({ careTeamTypeValue }) {
    if (careTeamTypeValue && careTeamTypeValue != this.props.careTeamTypeValue) {
      if (careTeamTypeValue == 'Internal') {
        this.props.dispatch(resetCareTeamMemberCombo({}));
      }
      if (careTeamTypeValue == 'External') {
        this.props.dispatch(
          careTeamMemberCombo({
            Json: JSON.stringify({
              CareTeam: [
                {
                  StaffTypeId: 2,
                },
              ],
            }),
          }),
        );
      }
    }
  }
  handleNewCareTeamMember = () => {
    //  this.props.careTeamTypeValue == 'Internal'
    //    ? this.props.history.push(`/manage/staff-management/new-registration`)
    //    : this.props.history.push('/cw/staff-management/new-registration');
    // this.props.history.push(
    //   `/cw/patient-management/staff-management/new-registration?pId=${this.getClientId()}`,
    // );
    this.props.careTeamTypeValue == 'Internal'
      ? this.props.history.push(
          `/cw/patient-management/staff-management/new-registration?pId=${this.getClientId()}`,
        )
      : this.props.history.push(
          `/cw/patient-management/external-care-team/list?pId=${this.getClientId()}`,
        );
  };
  render() {
    const {
      handleSubmit,
      onSaveClick,
      disable,
      style,
      careTeamCenter,
      careTeamMember,
      comboServiceCodelist,
      centerChange,
      isFetchingUpsert,
      onClose,
      initialValues: { ConsentStatusId, ClientCareTeamId, ConsentStatus },
      careTeamTypeValue,
      isFetchingGet,
    } = this.props;
    let status = {
      1: 'pending',
      2: 'appoved',
      3: 'denied',
    };
    return (
      <form onSubmit={handleSubmit(onSaveClick)} style={style}>
        <Loader2 loading={isFetchingGet} />
        <div className="row enrollment-form mt-2">
          <div className="col-12">
            {!ClientCareTeamId && (
              <div className="form-group  custom-radio">
                <label className="mr-5">
                  Internal
                  <Field
                    name="memberType"
                    component={ReduxInput}
                    type="radio"
                    value="Internal"
                    style={{
                      width: '20px',
                      height: '20px',
                      border: 'solid 1px #bcbcbc',
                      marginRight: '5px',
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="mr-5">
                  External
                  <Field
                    name="memberType"
                    component={ReduxInput}
                    type="radio"
                    value="External"
                    style={{
                      width: '20px',
                      height: '20px',
                      border: 'solid 1px #bcbcbc',
                      marginRight: '5px',
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
          </div>
          {careTeamTypeValue == 'Internal' && (
            <div className="col-12 col-md-6">
              <div className="form-group">
                <Field
                  name={`CenterId`}
                  type="text"
                  component={ReduxSelect}
                  placeholder="Select Center*"
                  options={careTeamCenter}
                  validate={validate.required}
                  fieldName={'Select Center'}
                  disabled={disable}
                  formatOptionLabel={option => <CenterOption OptionData={option} />}
                  onChange={centerChange}
                />
              </div>
            </div>
          )}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`PractitionerId`}
                type="text"
                component={ReduxSelect}
                placeholder="Care Team Member*"
                options={careTeamMember}
                validate={validate.required}
                fieldName={'Care Team Member'}
                disabled={disable}
                formatOptionLabel={option => <CareTeamMemberOption OptionData={option} />}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <Field
                name={`ServiceCodeId`}
                type="text"
                component={ReduxSelect}
                placeholder="Select Services"
                options={comboServiceCodelist}
                // validate={validate.required}
                fieldName={'Select Services'}
                disabled={disable}
                isMulti
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`StartDate`}
                type="text"
                label={'Start Date'}
                component={ReduxDateInput}
                // validate={validate.required}
                fieldName={'Start Date'}
                disabled={disable}
              />
              <span className="small-info">{'MM/DD/YYYY'}</span>
              <i className="far fa-calendar-alt form-control-icon" />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <Field
                name={`EndDate`}
                type="text"
                label={'End Date'}
                component={ReduxDateInput}
                // validate={Validator.endTime}
                fieldName={'End Date'}
                disabled={disable}
              />
              <span className="small-info">{'MM/DD/YYYY'}</span>
              <i className="far fa-calendar-alt form-control-icon" />
            </div>
          </div>

          {ConsentStatusId && (
            <div className="col-12 col-md-6 mt-2">
              <div className="form-group mt-2">
                <label
                  class="placeholder-label"
                  style={{
                    top: '-25px',
                  }}
                >
                  Consent Status*
                </label>
                <div className="consent-status">
                  {<span className={status[ConsentStatusId]}>{ConsentStatus}</span>}
                </div>
              </div>
            </div>
          )}
          {!ClientCareTeamId && careTeamTypeValue && (
            <div className="col-12 mt-2">
              <span
                className=""
                style={{
                  color: '#4AB0FE',
                  fontSize: '16px',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
                onClick={this.handleNewCareTeamMember}
              >
                + Add NEW Care team member
              </span>
            </div>
          )}

          {!disable && (
            <div className="col-12 text-right">
              <button
                className="btn btn-outline-primary px-5 mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>

              <SubmitButton
                value={ClientCareTeamId ? 'Save' : 'Add'}
                loading={isFetchingUpsert}
                style={{
                  height: '44px',
                  width: '100px',
                }}
              />
            </div>
          )}
        </div>
      </form>
    );
  }
}
const ReduxCareTeamMember = reduxForm({
  form: 'CareTeamForm',
  enableReinitialize: true,
})(connect()(withRouter(CareTeamMember)));

const mapStateToProps = state => {
  const { organization_center_family_combo, care_team_member_combo, combo_service_code } = state;
  const { data: careTeamCenterData } = organization_center_family_combo;
  const { data: careTeamMemberData } = care_team_member_combo;
  const { data: comboServiceCodeData } = combo_service_code;

  let careTeamCenter = [];
  let careTeamMember = [];
  let comboServiceCodelist = [];
  const careTeamTypeValue = formValueSelector('CareTeamForm')(state, 'memberType');

  if (careTeamCenterData && careTeamCenterData.Center) {
    careTeamCenter = careTeamCenterData.Center;
  }
  if (careTeamMemberData && careTeamMemberData.CareTeam) {
    careTeamMember = careTeamMemberData.CareTeam;
  }
  if (comboServiceCodeData && comboServiceCodeData.ServiceCode) {
    comboServiceCodelist = comboServiceCodeData.ServiceCode;
  }
  return {
    careTeamCenter,
    careTeamMember,
    comboServiceCodelist,
    careTeamTypeValue,
  };
};
export default connect(mapStateToProps)(ReduxCareTeamMember);
