import React, { Component } from 'react';
import '../core/modal/modal.css';

export default class ModelDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return (
      <div>
        <button onClick={this.openModal}>Open Modal</button>
        <div style={{ display: this.state.modalIsOpen === true ? 'flex' : 'none' }} role="dialog">
          <div class="custom-modal modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">Are you ready to take the survey</h4>
              </div>
              <div class="modal-body">
                <div class="mark-complete-content inner-form">
                  <div class="row mar-top-30">
                    <div class="col-sm-6 col-12 text-center mar-top-15">
                      <input
                        type="button"
                        data-dismiss="modal"
                        class="form-control btn "
                        value="No"
                        onClick={this.closeModal}
                      />
                    </div>
                    <div class="col-sm-6 col-12 text-center mar-top-15">
                      <input
                        type="button"
                        class="form-control btn black-btn"
                        data-dismiss="modal"
                        value="Yes"
                        color="blue"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
