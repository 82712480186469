import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Collapse, Button } from 'antd';
import { get } from 'lodash';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  reset,
  change,
  isDirty,
} from 'redux-form';
import { ReduxInput, ReduxSelect } from '../../../../../../../component/custom-redux-components';
import Validator from '../../../../../../Validation';
import TermsForm from '../../../../../../termsConditions/terms';
import ConditionsForm from '../../../../../../termsConditions/conditions';
import CustomModal from '../../../../../../../Components/CustomModal';
import {
  termsConditionListGet,
  termsConditionGetAllInfoJson,
  termsConditionComboGet,
  clientInsuranceComboGet,
  smartContractTermsConditionGetAllInfoJson,
  resetTermsConditionGetAllInfoJson,
} from '../../../../../../../store/actions';
import ReduxAddTerms from './terms';
import SubmitButton from '../../../../../../../Components/submitButton';
import Loader, { Loader2 } from '../../../../../../../Components/Loader';
import WarningComponent from '../../../../../../../core/warning-component';

const { Panel } = Collapse;
class TermsConditionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // TermsCategory: (props.initialValues && props.initialValues.TermsCondition) || [],
      TermsCategory:
        (props.initialValues && Object.assign({}, props.initialValues.TermsCondition)) || [],
    };
  }

  getClientId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  };

  getTerms = (terms, termsCategoryIndex, termIndex) => {
    // this.props.dispatch(change('termsAndConditionForm', 'TermsListItem', terms));
    // this.props.dispatch(
    //   change('termsAndConditionForm', `TermsListItem[${termsCategoryIndex}]ServiceType`, temp),
    // );
    //   this.props.dispatch(
    //     change(
    //       'termsAndConditionForm',
    //       `TermsCondition[${termsCategoryIndex}]TermsListItem`,
    //       terms,
    //     ),
    // );
    this.props.dispatch(
      change(
        'termsAndConditionForm',
        `TermsCondition[${termsCategoryIndex}]TermsCategory[${termIndex}]Terms`,
        terms && terms[termIndex] && terms[termIndex].descList,
      ),
    );
  };
  componentDidMount() {
    window.scrollTo(0, 300);
    const { smartContractData } = this.props;
    if (smartContractData && smartContractData.TermsCondition) {
      this.setState({
        TermsCategory: smartContractData.TermsCondition[0].TermsCategory,
      });
    }
    let json = { Client: { ClientId: this.getClientId() } };
    var data = {
      Json: JSON.stringify(json),
    };
    this.props.dispatch(resetTermsConditionGetAllInfoJson({}));
    this.props.dispatch(termsConditionComboGet(data));
    this.props.dispatch(clientInsuranceComboGet(data));
    this.props.dispatch(
      smartContractTermsConditionGetAllInfoJson({
        Json: JSON.stringify({ TermsCondition: [{ SmartContractId: this.props.smartContractId }] }),
      }),
    );
  }
  componentWillReceiveProps({ termsConditionInfo, tcCombo, contractTermsAndConditionList }) {
    if (
      termsConditionInfo &&
      JSON.stringify(termsConditionInfo) != JSON.stringify(this.props.termsConditionInfo)
    ) {
      // this.props.dispatch(change('termsAndConditionForm', 'TermId', termsConditionInfo.TermId));
      // this.props.dispatch(change('termsAndConditionForm', 'Title', termsConditionInfo.Title));
      // this.props.dispatch(
      //   change('termsAndConditionForm', 'Conditions', termsConditionInfo.Condition),
      // );
      // this.props.dispatch(
      //   change('termsAndConditionForm', 'Terms', termsConditionInfo.TermsCategory),
      // );

      // this.setState({
      //   TermsCategory: termsConditionInfo.TermsCategory,
      // });

      this.setState({
        ...this.state.TermsCategory,
        TermsCategory: {
          [termsConditionInfo.termIndex]: termsConditionInfo,
        },
      });

      this.props.dispatch(
        change(
          'termsAndConditionForm',
          `TermsCondition[${termsConditionInfo.termIndex}]`,
          termsConditionInfo,
        ),
      );

      //   let data = {
      //     TermsConditionId: termsConditionInfo.TermsConditionId,
      //     Title: termsConditionInfo.Title,
      //     TermId: termsConditionInfo.TermId,
      //     Conditions: termsConditionInfo.Condition,
      //     Terms: termsConditionInfo.TermsCategory,
      //   };
    }
    if (
      contractTermsAndConditionList &&
      JSON.stringify(contractTermsAndConditionList) !=
        JSON.stringify(this.props.contractTermsAndConditionList)
    ) {
      this.setState({
        TermsCategory:
          (contractTermsAndConditionList && Object.assign({}, contractTermsAndConditionList)) || [],
      });
    }
  }
  render() {
    const {
      handleSubmit,
      onSubmit,
      disable,
      termsConditionCombo,
      termsConditionInfo,
      handleSubmitAndNext,
      currentTab,
      tabsCount,
      smartContractId,
      PayerCombo,
      tcCombo,
      initialValues,
      existingRows,
      isDirtyValue,
      submitSucceeded,
    } = this.props;
    return (
      <>
        <div
          style={
            this.props.isFetching ? { height: '200px', position: 'relative' } : { height: '0px' }
          }
        >
          <Loader2 loading={this.props.isFetching} />
        </div>

        <div>
          {!this.props.isFetching && (
            <form
              onSubmit={handleSubmit(values => {
                this.props.onContractSecondTabSubmit(values);
              })}
            >
              <div class="rcm-panel enrollment-form">
                <FieldArray
                  name={`TermsCondition`}
                  component={ReduxAddTerms}
                  termsConditionCombo={termsConditionCombo}
                  PayerCombo={PayerCombo}
                  tcCombo={tcCombo}
                  termsConditionInfo={termsConditionInfo}
                  smartContractId={smartContractId}
                  // disable={false}
                  getTerms={this.getTerms}
                  TermsCategory={this.state.TermsCategory}
                  existingRows={existingRows}
                  initialValues={initialValues}
                  disabled={this.props.disabled}
                />
              </div>
              <div className="text-right">
                {/* <button type="submit" value="submit" class="btn mt-4 mb-4 mr-2">
              Save
            </button> */}
                <Button
                  type="primary"
                  onClick={() => {
                    this.props.goToPreviuos();
                  }}
                  className={`btn btn-outline-primary`}
                  style={{ height: '42px' }}
                >
                  Previous
                </Button>
                &nbsp;
                {!this.props.disabled && (
                  <SubmitButton
                    loading={this.props.isTermsAndConditionUpsertFetching}
                    value={'Save & Next'}
                    className="btn mt-4 mb-4 mr-2"
                    type="submit"
                    style={{ height: '42px' }}
                  />
                )}
                {this.props.disabled && (
                  <Button
                    type="primary"
                    onClick={this.props.handleNext}
                    className={`btn save-button mb-4 mr-2 mt-4`}
                    style={{ height: '42px' }}
                  >
                    Next
                  </Button>
                )}
              </div>
            </form>
          )}
        </div>
        <WarningComponent dirtyFlag={isDirtyValue} submitSucceeded={submitSucceeded} />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    terms_condition_list_get,
    terms_condition_get_all_info_json,
    smart_contract_get,
    client_insurance_combo_get,
    terms_condition_combo_get,
    smart_contract_terms_condition_getallinfo_json,
  } = state;
  const { data } = terms_condition_list_get;
  const { data: termsInfoData } = terms_condition_get_all_info_json;
  const { data: contractData } = smart_contract_get;
  const { data: tcTitleData } = terms_condition_combo_get;
  const { data: payerDropdown } = client_insurance_combo_get;
  const { data: smartContractTAndC,isFetching } = smart_contract_terms_condition_getallinfo_json;

  let termsConditionCombo = [];
  let termsConditionInfo = null;
  let smartContractData = [];
  let tcCombo = [];
  let PayerCombo = [];
  let existingRows = [];
  let contractTermsAndConditionList = null;

  if (data && data.TermsCondition) {
    termsConditionCombo = data.TermsCondition;
  }

  if (termsInfoData && termsInfoData.TermsCondition) {
    termsConditionInfo = termsInfoData.TermsCondition[0];
    termsConditionInfo = {
      ...termsConditionInfo,
      TermId: termsConditionInfo.TermsConditionId,
      termIndex: termsInfoData.termIndex,
    };
  }

  if (contractData && contractData.SmartContract) {
    smartContractData = contractData.SmartContract[0];
  }

  if (payerDropdown && payerDropdown.Client) {
    PayerCombo = payerDropdown.Client || [];
  }

  if (tcTitleData && tcTitleData.TermsCondition) {
    tcCombo = tcTitleData.TermsCondition;
  }
  if (smartContractTAndC && smartContractTAndC.TermsCondition) {
    contractTermsAndConditionList = smartContractTAndC.TermsCondition;
  }
  return {
    termsConditionCombo,
    termsConditionInfo,
    smartContractData,
    PayerCombo,
    tcCombo,
    existingRows: formValueSelector('termsAndConditionForm')(state, 'TermsCondition'),
    contractTermsAndConditionList,
    isFetching,
    isDirtyValue: isDirty('termsAndConditionForm')(state),
    submitSucceeded: get(state, 'form.termsAndConditionForm.submitSucceeded', false),
  };
};

const ReduxTermsConditionsForm = reduxForm({
  // form: 'contractForm',
  form: 'termsAndConditionForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // keepDirtyOnReinitialize: true,
  onSubmitFail: errors => {
    if (errors) {
      setTimeout(() => {
        const element = document.querySelector('.error-text');

        // if (element) {
        //   // Scroll to the element
        //   element.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start', // Scroll to the top of the element
        //   });
        // }

        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetValue = elementPosition + window.scrollY - 200;

          window.scrollTo({
            top: offsetValue,
            behavior: 'smooth',
          });
        }
      }, 500);
    }
  },
  // onSubmit: (values, dispatch, props) => {
  //   props.onContractSubmit(values);
  // },
  onSubmit: (values, dispatch, props) => {
    props.handleSubmitAndNext(values, props.currentTab, props.tabsCount);
  },
})(connect(mapStateToProps)(withRouter(TermsConditionsForm)));
const selector = formValueSelector('termsAndConditionForm');

export default ReduxTermsConditionsForm;
