import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { Switch, Checkbox, Radio, Select } from 'antd';
import QuestionConfigAction from '../questionConfigAction';

class Numeric extends React.Component {
  constructor(props) {
    super(props);
    let { Response, Note, ResponseThreshold, IsMandatory, AddNote } = props.survey;
    this.state = {
      selectedValue: null,
      mandateChecked: IsMandatory,
      noteCheck: AddNote,
    };
  }

  onTextChange = (event, click) => {
    const { value } = event.target;
    // this.setState({
    //   value: value,
    // });
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
    } = this.props;
    //  this.setState({
    //    value: value,
    //  });
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: value,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      // OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: `ResponseThreshold`,
    })();
  };

  onChecked = (response, key) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      setToggle,
    } = this.props;
    if (key == 'IsMandatory') {
      this.setState({
        mandateChecked: response,
      });
    } else if (key == 'AddNote') {
      this.setState({
        noteCheck: response,
      });
    }
    setToggle.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: response,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      // OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: key,
    })();
  };

  handelFlaggedChange = (val, OIndex) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
    } = this.props;
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: !val,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      OptKeyName: `Option${OIndex}QF`,
      SurveyQuestionListItems: [SurveyQuestionListItems],
    })();
  };

  handleChange = value => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
    } = this.props;
    this.setState({
      selectedValue: value,
    });
    onClick.bind(scope, {
      surveyQuestionId: survey.SurveyQuestionId,
      response: value,
      // response: survey.Response == val ? survey.DoesNotApplyValue : val,
      // note: this.state.noteValue,
      level: level,
      subLevel: subLevel,
      subCatLevel: subCatLevel,
      index: sequenceNum,
      // OIndex: OIndex,
      TabName: TabName,
      Sequence: Sequence,
      SurveyQuestionListItems: [SurveyQuestionListItems],
      OptKeyName: `ThresholdSign`,
    })();
  };
  onChange = (event, click) => {
    const { value } = event.target;
    this.setState({
      value: Math.abs(value),
    });
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
      TabName,
      Sequence,
      sequenceNum,
      SurveyQuestionListItems,
      SurveyQuestionList,
      activeKey,
      onQuestionStateChange,
      questionsListOptions,
      isAdmin,
    } = this.props;

    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option1QF,
      Option2Value,
      Option2Text,
      Option2QF,
      Option3Value,
      Option3Text,
      Option3QF,
      Option4Value,
      Option4Text,
      Option4QF,
      Option5Value,
      Option5Text,
      Option5QF,
      Option6Value,
      Option6Text,
      Option6QF,
      Option7Value,
      Option7Text,
      Option7QF,
      Option8Value,
      Option8Text,
      Option8QF,
      Option9Value,
      Option9Text,
      Option9QF,
      Option10Value,
      Option10Text,
      Option10QF,
      Response,
      Note,
      SurveyQuestionId,
      SectionId,
      DisplayOrder,
      ResponseThreshold,
      MaxResponseThreshold,
      ThresholdSign,
      IsArchived,
      SurveyQuestionCategoryConfigId,
      IsNumeric,
    } = survey;
    const { SurveyNumericInputCondition } = questionsListOptions || {};
    let NumericInputCondition = [];
    SurveyNumericInputCondition &&
      SurveyNumericInputCondition.map(
        condition =>
          (NumericInputCondition = [
            ...NumericInputCondition,
            { value: condition.Value, label: condition.Text },
          ]),
      );
    return (
      <div className="type-border col-12 mb-3 toggle-radio">
        <div className="d-flex justify-content-between align-items-start pb-2">
          <div className="row">
            <div className="col-12">
              {Question && Question.length > 0 && (
                <h5 className="survey-question rcm-question text-left mb-2">
                  {/* {QuestionNumber && `${QuestionNumber}.`} {renderHTML(Question)} */}
                  {!IsArchived && `${sequenceNum + 1}.`} {renderHTML(Question)}
                </h5>
              )}
            </div>
            <div className="col-12 mb-2">
              <span className="text">Condition</span>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <div className="select">
                    <Select
                      defaultValue={ThresholdSign}
                      // value={this.state.selectedValue}
                      onChange={this.handleChange}
                      //   allowClear
                      options={NumericInputCondition || []}
                      style={{
                        minWidth: 200,
                      }}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-3 mt-1">
                  <div className="numbers-row">
                    <span className="threshold-value">{ResponseThreshold}</span>
                    {MaxResponseThreshold && (
                      <>
                        - <span className="threshold-value mx-2">{MaxResponseThreshold}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <Checkbox
                    checked={Option1QF === false}
                    onChange={event => {
                      let val = event.target.checked;
                      this.handelFlaggedChange(val, 1);
                    }}
                    disabled={IsArchived}
                    style={{
                      padding: '0px 5px 5px 0px',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    Mark it as Flagged
                  </Checkbox>
                </div>
              </div>
            </div>
            {!isAdmin && <div className="col-12">
              <div className="d-flex mt-4">
                <label class="custom-control-label mr-2" for="is-mandatory">
                  <Switch
                    name="isQuestionMandatory"
                    // checked={value}
                    // onChange={onChange}
                    unCheckedChildren="No"
                    checkedChildren="Yes"
                    size="small"
                    disabled={IsArchived}
                    checked={this.state.mandateChecked}
                    onChange={e => this.onChecked(e, 'IsMandatory')}
                  />
                  Mark as mandatory
                </label>
                <label class="custom-control-label" for="isAddNote">
                  <Switch
                    name="isAddNote"
                    // checked={value}
                    // onChange={onChange}
                    unCheckedChildren="No"
                    checkedChildren="Yes"
                    size="small"
                    disabled={IsArchived}
                    checked={this.state.noteCheck}
                    onChange={e => this.onChecked(e, 'AddNote')}
                  />
                  Enable Add Note
                </label>
              </div>
            </div>}
          </div>
          <div>
            <QuestionConfigAction {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
export default Numeric;
