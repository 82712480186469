import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import moment from 'moment';
import MultiSelectCheckboxes from '../../../component/custom-components/multiSelectCheckboxes';
import CustomModal2 from '../../../core/modal';
import { Popover, Button, Table, Badge, Divider } from 'antd';
import FullCalendar from '../../../Components/Calendar';
import {
  medicateCombos,
  listAppointment,
  getAppointment,
  patientComboGroup,
  upsertGroupAppointmentGroupAllInfoJson,
  practitionerCombosOffice,
  resetGetClientGroup,
  listCombos,
  getComboFacility,
  practitionerCombos,
  comboPractitionerAll,
  comboFacilityAll,
  getStaffPractitioner,
  resetUpsertBlockAppointment,
  getGroupDropdown,
  defaultPractotionerFacilityGet,
  resetAppointmentGetClientUpcomingSchedule,
} from '../../../store/actions';
import { Modal } from 'antd';
import { ReduxCreateEventModal } from './createEventModal';
import IndividualAppointmentModal from './individualAppointmentModal';
import GroupAppointmentModal from './groupAppointmentModal';
import BlockAppointmentModal from './blockAppointmentModal';
import { ReduxSelect } from '../../../component/custom-redux-components';
import { ProcessCombos } from '../../../store/comboConfig';
import PageTitle from '../../../Components/PageTitle';
import '../rcm-panel.css';

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

const INITIAL_EVENTS = [
  {
    id: 1,
    title: 'All-day event',
    start: todayStr,
    // backgroundColor: 'blue',
  },
  {
    id: 2,
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
    backgroundColor: 'pink',
  },
  {
    id: 3,
    title: 'Timed event',
    start: todayStr + 'T18:00:00',
    // backgroundColor: 'green',
  },
  { id: 4, title: 'event 2', date: '2020-11-25' },
  { id: 5, title: 'Birthday Party', start: '2020-11-22T07:00:00' },
  // {
  //   title: 'Birthday Party',
  //   start: '2020-12-08T11:00:00',
  // },
  {
    title: 'Click for Google',
    url: 'http://google.com/',
    start: '2020-11-21',
  },
  {
    groupId: 999,
    title: 'Repeating Event',
    start: '2020-11-17T16:00:00',
    eventColor: '#378006',
  },
  {
    groupId: 999,
    title: 'Repeating Event',
    start: '2020-11-19T16:00:00',
  },
];

const createEventId = () => {
  return String(eventGuid++);
};

class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      weekendsVisible: true,
      currentEvents: [],
      inviteSubscriberModal: false,
      eventViewModal: false,
      groupEventViewModal: false,
      blockAppointmentModal: false,
      dateClicked: '',
      selectInfo: '',
      facilityValue: null,
      practitionerValue: null,
      initialEvents: [],
      initEventValues: {
        referralType: 'R',
        clientId: '',
        // CountryCode: '1',
        // appointmentDate: this.state.dateClicked,
        // patientFirstName: this.state.clickInfo.event._instance.range.start,
      },
      CreateEventInitialValues: {
        referralType: 'R',
      },
      initGroupEventValues: {
        g_members_list: [],
      },
      clickedEventId: '',
    };
  }
  hide = () => {
    this.setState({
      visible: false,
    });
  };
  componentDidMount() {
    this.props.dispatch(practitionerCombos({}));
    // this.props.dispatch(getStaffPractitioner({}));
    //In cdm we stopped calling above  api because based on the ticket 4410. we are now using below one.
    this.props.dispatch(defaultPractotionerFacilityGet({}));
    // this.props.dispatch(listAppointment({}));
    this.props.dispatch(
      listAppointment({
        json: JSON.stringify({
          Appointment: [
            {
              PractitionerId: this.state.practitionerValue,
              FacilityId: this.state.facilityValue,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(getComboFacility({}));
    this.props.dispatch(
      comboPractitionerAll({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: false,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      comboFacilityAll({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: true,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(patientComboGroup({}));
    let medicateComboTypes = {
      ComboTypes: 'ServiceCode,Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    var result = ProcessCombos('CenterProgramType,AppointmentType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }

  content = tempArray => {
    return (
      <div>
        {tempArray &&
          tempArray.map(item => {
            const { color, text } = item;
            return (
              <>
                <div
                  className="d-flex align-items-center justify-content-start flex-nowrap"
                  style={{
                    width: '160px',
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                    // borderBottom: '1px solid #f0f0f0'
                  }}
                  onClick={e => {
                    this.props.dispatch(
                      listAppointment({
                        json: JSON.stringify({
                          Appointment: [
                            {
                              Filter: text,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  <div className="colorIndicators" style={{ background: color }}></div>
                  &nbsp;{' '}
                  <div
                    style={{
                      cursor: 'pointer',
                      pointerEvents: 'auto',
                    }}
                    onClick={this.hide}
                  >
                    {text}
                  </div>
                </div>
                {text == 'Block Appointment' && <Divider style={{ margin: '10px' }} />}
              </>
            );
          })}
        <Divider style={{ margin: '10px' }} />
        <Button
          type="primary"
          onClick={() => {
            this.props.dispatch(listAppointment({}));
          }}
        >
          Reset Filters
        </Button>
      </div>
    );
  };

  setSearchClientValues = data => {
    this.setState({
      CreateEventInitialValues: {
        ...this.state.CreateEventInitialValues,
        referralType: 'V',
        appointmentDate: this.state.dateClicked,
        existingPatientFirstName: data.FirstName,
        existingPatientLastName: data.LastName,
        existingPatientMiddleName: data.MiddleName,
        existingPatientMobileNumber: data.PrimaryPhone,
        existingPatientProgram: data.ProgramId,
        existingPatientProviderName: data.PractitionerId,
        clientId: data.ClientId,
      },
    });
  };
  resetSearchClientValues = () => {
    this.setState({
      CreateEventInitialValues: {
        ...this.state.CreateEventInitialValues,
        referralType: 'R',
        appointmentDate: '',
        existingPatientFirstName: '',
        existingPatientLastName: '',
        existingPatientMiddleName: '',
        existingPatientMobileNumber: '',
        clientId: '',
      },
    });
  };
  componentWillReceiveProps({
    isSuccess,
    appointments,
    getAppointment,
    isAppointmentBooked,
    isGroupAppointmentBooked,
    deleteEvent,
    deleteGroupEvent,
    getGroupAppointment,
    groupDataInfo,
    getStaffPractitionerValue,
    warningMsgIndivisualAppointment,
    warningMsgGroupAppointment,
    upsertBlockSuccess,
    getInitValuesBasedOnGroupValues,
    defaultPractotionerFacilityValues,
    initBasedOnAppointmentType,
  }) {
    if (
      Object.keys(initBasedOnAppointmentType).length > 0 &&
      JSON.stringify(initBasedOnAppointmentType) !=
        JSON.stringify(this.props.initBasedOnAppointmentType)
    ) {
      const { values, newAppointment } = initBasedOnAppointmentType;
      const { ServiceCodeId, PlaceOfServiceId } = values || {};
      if (newAppointment) {
        this.props.dispatch(change('createEventForm', 'referralType', 'R'));
        this.props.dispatch(change('createEventForm', 'serviceCode', parseInt(ServiceCodeId)));
        this.props.dispatch(
          change('createEventForm', 'placeOfService', parseInt(PlaceOfServiceId)),
        );
      } else {
        this.props.dispatch(change('createEventForm', 'referralType', 'V'));
        this.props.dispatch(
          change('createEventForm', 'existingPatientServiceCode', parseInt(ServiceCodeId)),
        );
        this.props.dispatch(
          change('createEventForm', 'existingPlaceOfService', parseInt(PlaceOfServiceId)),
        );
      }
    }

    if (
      getInitValuesBasedOnGroupValues &&
      JSON.stringify(getInitValuesBasedOnGroupValues) !=
        JSON.stringify(this.props.getInitValuesBasedOnGroupValues)
    ) {
      const { ServiceCodeId, CounsellorId, PlaceOfServiceId } = getInitValuesBasedOnGroupValues;
      this.setState({
        CreateEventInitialValues: {
          ...this.state.CreateEventInitialValues,
          g_providerName: parseInt(CounsellorId),
          g_serviceCode: parseInt(ServiceCodeId),
          g_placeOfService: PlaceOfServiceId,
        },
      });
    }
    if (
      getStaffPractitionerValue &&
      JSON.stringify(getStaffPractitionerValue) !=
        JSON.stringify(this.props.getStaffPractitionerValue)
    ) {
      //In cdm we stopped calling this api because based on the ticket 4410.
      if (
        getStaffPractitionerValue &&
        getStaffPractitionerValue.data &&
        getStaffPractitionerValue.data.length > 0
      ) {
        this.setState({
          facilityValue:
            (getStaffPractitionerValue.data[0].FacilityId &&
              JSON.stringify(getStaffPractitionerValue.data[0].FacilityId)) ||
            null,
          practitionerValue:
            (getStaffPractitionerValue.data[0].PractitionerId &&
              JSON.stringify(getStaffPractitionerValue.data[0].PractitionerId)) ||
            null,
        });

        this.props.dispatch(
          listAppointment({
            json: JSON.stringify({
              Appointment: [
                {
                  PractitionerId:
                    getStaffPractitionerValue.data[0].PractitionerId &&
                    JSON.stringify(getStaffPractitionerValue.data[0].PractitionerId),
                  FacilityId:
                    getStaffPractitionerValue.data[0].FacilityId &&
                    JSON.stringify(getStaffPractitionerValue.data[0].FacilityId),
                },
              ],
            }),
          }),
        );
      }
    }
    if (
      defaultPractotionerFacilityValues &&
      JSON.stringify(defaultPractotionerFacilityValues) !=
        JSON.stringify(this.props.defaultPractotionerFacilityValues)
    ) {
      this.setState({
        facilityValue:
          (defaultPractotionerFacilityValues.PractitionerList[0].FacilityId &&
            JSON.stringify(defaultPractotionerFacilityValues.PractitionerList[0].FacilityId)) ||
          null,
        // practitionerValue:
        //   (defaultPractotionerFacilityValues.PractitionerList[0].PractitionerId &&
        //     JSON.stringify(defaultPractotionerFacilityValues.PractitionerList[0].PractitionerId)) ||
        //   null,
      });

      this.props.dispatch(
        listAppointment({
          json: JSON.stringify({
            Appointment: [
              {
                // PractitionerId:
                //   defaultPractotionerFacilityValues.PractitionerList[0].PractitionerId &&
                //   JSON.stringify(
                //     defaultPractotionerFacilityValues.PractitionerList[0].PractitionerId,
                //   ),
                FacilityId:
                  defaultPractotionerFacilityValues.PractitionerList[0].FacilityId &&
                  JSON.stringify(defaultPractotionerFacilityValues.PractitionerList[0].FacilityId),
              },
            ],
          }),
        }),
      );
    }
    if (appointments && JSON.stringify(appointments) != JSON.stringify(this.props.appointments)) {
      this.setState({ initialEvents: appointments });
    }

    if (
      groupDataInfo &&
      JSON.stringify(groupDataInfo) != JSON.stringify(this.props.groupDataInfo)
    ) {
      if (groupDataInfo && groupDataInfo.IsGroupHasChildCenterPatient) {
        this.props.dispatch(
          getGroupDropdown({
            json: JSON.stringify({ Group: [{ GroupId: this.state.selectedGroupId }] }),
          }),
        );
      }
      this.setState({
        CreateEventInitialValues: {
          ...this.state.CreateEventInitialValues,
          referralType: 'G',
          // g_group:
          g_group: groupDataInfo.GroupId,
          g_title: groupDataInfo.GroupName,
          g_serviceCode: parseInt(groupDataInfo.ServiceCodeId),
          IsGroupHasChildCenterPatient: groupDataInfo.IsGroupHasChildCenterPatient,
        },
      });
    }
    if (isAppointmentBooked && isAppointmentBooked != this.props.isAppointmentBooked) {
      // this.props.dispatch(listAppointment({}));
      this.props.dispatch(
        listAppointment({
          json: JSON.stringify({
            Appointment: [
              {
                PractitionerId: this.state.practitionerValue,
                FacilityId: this.state.facilityValue,
              },
            ],
          }),
        }),
      );
      let localMsg = 'Appointment created successfully';
      if (
        parseInt(
          this.state.clickInfo &&
            this.state.clickInfo.event &&
            this.state.clickInfo.event._def.publicId,
        )
      ) {
        localMsg = 'Appointment updated successfully';
        this.setState({
          inviteMsg: localMsg,
          isModelOpen: true,
          // eventViewModal: false,
        });
      } else {
        this.setState({
          inviteMsg: localMsg,
          isModelOpen: true,
          warningContent: warningMsgIndivisualAppointment,
        });
      }

      // this.props.dispatch(reset('inviteForm'));
    }
    if (
      isGroupAppointmentBooked &&
      isGroupAppointmentBooked != this.props.isGroupAppointmentBooked
    ) {
      // this.props.dispatch(listAppointment({}));
      this.props.dispatch(
        listAppointment({
          json: JSON.stringify({
            Appointment: [
              {
                PractitionerId: this.state.practitionerValue,
                FacilityId: this.state.facilityValue,
              },
            ],
          }),
        }),
      );
      let localMsg = 'Your appointment is scheduled successfully.';
      if (
        parseInt(
          this.state.clickInfo &&
            this.state.clickInfo.event &&
            this.state.clickInfo.event._def.publicId,
        )
      ) {
        localMsg = 'Your appointment is updated successfully.';
        this.setState({
          inviteMsg: localMsg,
          isModelOpen: true,
          clickInfo: '',
          // eventViewModal: false,
        });
      } else {
        this.setState({
          inviteMsg: localMsg,
          isModelOpen: true,
          warningContent: warningMsgGroupAppointment,
        });
      }

      // this.setState({
      //   inviteMsg: 'Your appointment is scheduled successfully.',
      //   isModelOpen: true,
      // });

      // this.props.dispatch(reset('inviteForm'));
    }
    if (deleteEvent && deleteEvent != this.props.deleteEvent) {
      // this.props.dispatch(listAppointment({}));
      this.props.dispatch(
        listAppointment({
          json: JSON.stringify({
            Appointment: [
              {
                PractitionerId: this.state.practitionerValue,
                FacilityId: this.state.facilityValue,
              },
            ],
          }),
        }),
      );
      this.setState({
        inviteMsg: 'Appointment deactivated successfully.',
        isModelOpen: true,
      });
    }
    if (deleteGroupEvent && deleteGroupEvent != this.props.deleteGroupEvent) {
      // this.props.dispatch(listAppointment({}));
      this.props.dispatch(
        listAppointment({
          json: JSON.stringify({
            Appointment: [
              {
                PractitionerId: this.state.practitionerValue,
                FacilityId: this.state.facilityValue,
              },
            ],
          }),
        }),
      );
      this.setState({
        inviteMsg: 'Group appointment deactivated successfully.',
        isModelOpen: true,
      });
    }
    // if (isSuccess && isSuccess != this.props.isSuccess) {
    //   this.setState(
    //     {
    //       inviteMsg: 'Your appointment is scheduled successfully',
    //       isModelOpen: true,
    //     },
    //     // () => {
    //     //   setTimeout(() => {
    //     //     this.setState({
    //     //       inviteMsg: '',
    //     //     });
    //     //   }, 5000);
    //     // },
    //   );

    //   this.props.dispatch(reset('inviteForm'));
    // }

    if (upsertBlockSuccess && upsertBlockSuccess != this.props.upsertBlockSuccess) {
      // this.props.dispatch(listAppointment({}));
      this.props.dispatch(
        listAppointment({
          json: JSON.stringify({
            Appointment: [
              {
                PractitionerId: this.state.practitionerValue,
                FacilityId: this.state.facilityValue,
              },
            ],
          }),
        }),
      );
      let localMsg = upsertBlockSuccess;
      this.setState({
        inviteMsg: localMsg,
        isModelOpen: true,
        // eventViewModal: false,
      });
    }
  }
  renderEventContent = eventInfo => {
    let statusColor;
    if (eventInfo.event._def.extendedProps.statusColor) {
      statusColor = eventInfo.event._def.extendedProps.statusColor;
    }
    return (
      <div
        style={{
          // background: eventInfo.event._def.extendedProps.isGroup == false ? '#FDBF5E' : '#09B66D',
          background: 'white',
          borderLeft:
            eventInfo.event._def.extendedProps.isGroup == true
              ? '4px solid #09B66D'
              : eventInfo.event._def.extendedProps.isBlockAppoitment == false
              ? '4px solid #FDBF5E'
              : '4px solid  #B32202',
          borderRight: `4px solid ${statusColor}`,
          borderRadius: '0px',
          padding: '3px',
          overflow: 'hidden',
          color: '#4ab0fe',
          // color: eventInfo.backgroundColor == 'blue' ? '#fff' : '#fff',
          // boxShadow:
          //   eventInfo.event._def.extendedProps.isGroup == false
          //     ? '0px 6px 14px rgba(253, 191, 94, 0.25)'
          //     : '0px 6px 14px rgba(9, 182, 109, 0.25)',
        }}
      >
        <span className="event-time">{eventInfo.timeText}</span>
        &nbsp;
        <span className="event-title">{eventInfo.event.title}</span>
      </div>
    );
  };
  handleEventClick = clickInfo => {
    if (clickInfo && clickInfo.event._def.extendedProps.isGroup) {
      var data = {
        GroupAppointment: [
          {
            GroupAppointmentId: parseInt(clickInfo.event._def.publicId),
          },
        ],
      };
      var clickedEventDetails = {
        json: JSON.stringify(data),
      };
      this.props.dispatch(upsertGroupAppointmentGroupAllInfoJson(clickedEventDetails));

      const { _instance, _def } = clickInfo.event;
      let appointmentDate = this.state.dateClicked;
      let eventDate = _instance.range.start;
      let title = _def.title;
      this.setState({
        groupEventViewModal: true,
        clickInfo: clickInfo,
        initEventValues: {
          appointmentDate: appointmentDate,
          eventDate: eventDate,
          g_title: title,
          referralType: 'R',
        },
        clickedEventId: _def.publicId,

        // dateClicked: selectInfo.start,
      });

      // return;
    } else if (clickInfo && clickInfo.event._def.extendedProps.isBlockAppoitment) {
      var data = {
        GroupAppointment: [
          {
            GroupAppointmentId: parseInt(clickInfo.event._def.publicId),
          },
        ],
      };
      var clickedEventDetails = {
        json: JSON.stringify(data),
      };
      this.props.dispatch(upsertGroupAppointmentGroupAllInfoJson(clickedEventDetails));

      const { _instance, _def } = clickInfo.event;
      let appointmentDate = this.state.dateClicked;
      let eventDate = _instance.range.start;
      let title = _def.title;
      this.setState({
        blockAppointmentModal: true,
        clickInfo: clickInfo,
        initEventValues: {
          appointmentDate: appointmentDate,
          eventDate: eventDate,
          g_title: title,
          referralType: 'R',
        },
        clickedEventId: _def.publicId,

        // dateClicked: selectInfo.start,
      });

      // return;
    } else {
      // clickInfo.event._def.extendedProps
      var data = {
        Appointment: [
          {
            AppointmentId: parseInt(clickInfo.event._def.publicId),
          },
        ],
      };
      var clickedEventDetails = {
        json: JSON.stringify(data),
      };
      this.props.dispatch(getAppointment(clickedEventDetails));

      const { _instance, _def } = clickInfo.event;
      let appointmentDate = this.state.dateClicked;
      let eventDate = _instance.range.start;
      let title = _def.title;
      this.setState({
        eventViewModal: true,
        clickInfo: clickInfo,
        initEventValues: {
          appointmentDate: appointmentDate,
          eventDate: eventDate,
          patientFirstName: title,
          referralType: 'R',
          clientId: '',
        },
        clickedEventId: _def.publicId,

        // dateClicked: selectInfo.start,
      });

      // if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      //   clickInfo.event.remove();
      // }
    }
  };

  handleEvents = events => {
    this.setState({
      currentEvents: events,
    });
  };
  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleDateSelect = selectInfo => {
    //Enable Custom Modal

    //uncomment upto else to start blocking past dates.

    // let dateClicked = moment(selectInfo.start);
    // let todaysDate = moment().startOf('day');
    // if (dateClicked.isBefore(todaysDate)) {
    //   alert('Appointment cannot be created on past date.');
    // }
    // else {
    if (!this.props.isReadOnlyUser) {
      if (selectInfo.allDay) {
        this.setState({
          inviteSubscriberModal: true,
          selectInfo: selectInfo,
          dateClicked: selectInfo.start,
          CreateEventInitialValues: {
            referralType: 'V',
            providerName: this.state.practitionerValue && parseInt(this.state.practitionerValue),
            officeName: this.state.facilityValue,
            existingPatientProviderName:
              this.state.practitionerValue && parseInt(this.state.practitionerValue),
            existingPatientOfficeName: this.state.facilityValue,
            g_providerName: this.state.practitionerValue && parseInt(this.state.practitionerValue),
            g_officeName: this.state.facilityValue,
            appointmentDate: selectInfo.start,
            b_appointmentDate: selectInfo.start,
            existingPatientStatus: 19701,
            g_status: 19701,
            status: 19701,
            // existingPatientAppointmentDate: selectInfo.start,
            // g_appointmentDate: selectInfo.start,
          },
        });
      } else {
        this.setState({
          inviteSubscriberModal: true,
          selectInfo: selectInfo,
          dateClicked: selectInfo.start,
          CreateEventInitialValues: {
            referralType: 'V',
            providerName: this.state.practitionerValue && parseInt(this.state.practitionerValue),
            officeName: this.state.facilityValue,
            existingPatientProviderName:
              this.state.practitionerValue && parseInt(this.state.practitionerValue),
            existingPatientOfficeName: this.state.facilityValue,
            g_providerName: this.state.practitionerValue && parseInt(this.state.practitionerValue),
            g_officeName: this.state.facilityValue,
            appointmentDate: selectInfo.start,
            b_appointmentDate: selectInfo.start,
            existingPatientStatus: 19701,
            g_status: 19701,
            status: 19701,
            startTime: selectInfo.start && moment(selectInfo.start).format('HH:mm:ss'),
            endTime:
              selectInfo.view.type == 'resourceTimelineDay'
                ? selectInfo.end &&
                  moment(selectInfo.end)
                    .add(30, 'minutes')
                    .format('HH:mm:ss')
                : selectInfo.end && moment(selectInfo.end).format('HH:mm:ss'),
            existingPatientStartTime:
              selectInfo.start && moment(selectInfo.start).format('HH:mm:ss'),
            existingPatientEndTime:
              selectInfo.view.type == 'resourceTimelineDay'
                ? selectInfo.end &&
                  moment(selectInfo.end)
                    .add(30, 'minutes')
                    .format('HH:mm:ss')
                : selectInfo.end && moment(selectInfo.end).format('HH:mm:ss'),
            providerName:
              selectInfo &&
              selectInfo.resource &&
              selectInfo.resource.id &&
              Number(selectInfo.resource.id),
            existingPatientProviderName:
              selectInfo &&
              selectInfo.resource &&
              selectInfo.resource.id &&
              Number(selectInfo.resource.id),
            // existingPatientAppointmentDate: selectInfo.start,
            // g_appointmentDate: selectInfo.start,
          },
        });
      }
    } else {
      return;
    }

    // Enable default full calender input

    // let title = prompt('Please enter a new title for your event');
    // let calendarApi = selectInfo.view.calendar;
    //

    // calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay,
    //   });
    // }

    // } uncomment only only this line to start blocking past date appoinment creation.
  };

  handlePractitionerChange = practitionerValue => {
    this.setState({ practitionerValue });
    this.props.dispatch(
      listAppointment({
        json: JSON.stringify({
          Appointment: [
            {
              PractitionerId: practitionerValue,
              FacilityId: this.state.facilityValue,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      comboFacilityAll({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: true,
              PractitionerId: practitionerValue == '0' ? null : practitionerValue,
            },
          ],
        }),
      }),
    );
  };
  handleFacilityChange = facilityValue => {
    this.setState({ facilityValue });
    this.props.dispatch(
      listAppointment({
        json: JSON.stringify({
          Appointment: [
            {
              FacilityId: facilityValue,
              PractitionerId: this.state.practitionerValue,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      comboPractitionerAll({
        json: JSON.stringify({
          Combo: [{ IsAll: false, FacilityId: facilityValue }],
        }),
      }),
    );
  };
  resetClickInfo = () => {
    this.setState({ clickInfo: '' });
  };
  handleVisibleChange = visible => {
    this.setState({ visible });
  };
  getGroupName = value => {
    this.setState({ selectedGroupId: value });
  };

  getPractitionerName = practitionerValue => {
    // debugger;
    let values =
      practitionerValue && typeof practitionerValue == 'object'
        ? practitionerValue.map(item => item.value).toString()
        : practitionerValue || '';
    this.setState({ practitionerValue: values, practitionerOptionValue: practitionerValue });
    this.props.dispatch(
      listAppointment({
        json: JSON.stringify({
          Appointment: [
            {
              PractitionerId: values,
              FacilityId: this.state.facilityValue,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      comboFacilityAll({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: true,
              PractitionerId: values == '0' ? null : values,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const {
      isFetching,
      Practitioner,
      Facility,
      colorCoding,
      practitioners,
      isReadOnlyUser,
    } = this.props;
    const { practitionerOptionValue, facilityValue } = this.state;
    // listPlugin: There are 4 preset list views: listDay, listWeek, listMonth, and listYear.

    return (
      <div className="container-fluid rcm-panel" style={{ marginTop: '6px', position: 'relative' }}>
        <PageTitle TitleText="Appointments" />
        {/* <Loader loading={isFetching} /> */}
        <div className="row text-left pt-2 pb-1 align-items-center">
          <div className="col-md-5 text-left indicator-section">
            {/* <span>
              {' '}
              <i class="fas fa-circle" style={{ color: 'rgb(9, 182, 109)' }}></i> Group Appointment
            </span>
            &nbsp;
            <span>
              {' '}
              <i class="fas fa-circle" style={{ color: 'rgb(253, 191, 94)' }}></i> Individual
              Appointment
            </span>
            &nbsp; */}
            <Popover
              content={this.content(this.props.colorCoding)}
              // title="Indicators"
              style={{ width: '180px' }}
              open={this.state.visible}
              placement="bottom"
              onOpenChange={this.handleVisibleChange}
            >
              <div className="btn black-btn">Legend / Filter</div>
            </Popover>
          </div>
          <div className="col-7 enrollment-form ">
            <div
              className="row dashboard-analytics-centers"
              style={{
                alignItems: 'end',
              }}
            >
              <div class="col-4"></div>
              <div class="col-4">
                <div class="form-group">
                  <ReduxSelect
                    options={Facility}
                    onChange={this.handleFacilityChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={facilityValue}
                    placeholder="Office"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group multi-select-checkboxes" style={{ zIndex: 9 }}>
                  <MultiSelectCheckboxes
                    options={Practitioner}
                    // onChange={this.handlePractitionerChange}
                    onChange={this.getPractitionerName}
                    // className="basic-single"
                    // classNamePrefix="select"
                    value={practitionerOptionValue}
                    placeholderButtonLabel="Provider"
                    // getDropdownButtonLabel={Value => {
                    //   const { value, placeholderButtonLabel } = Value;
                    //   return `${value.length > 0 ? value.length : 'No'} Columns Selected`;
                    // }}
                  />
                </div>
              </div>
              {/* <div class="col-4">
                <div class="form-group">
                  <ReduxSelect
                    options={Practitioner}
                    onChange={this.handlePractitionerChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={practitionerValue}
                    placeholder="Provider"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {isFetching && (
          // <div className="preloader-box2">
          <div
            // ref={this.modalRef}
            // className="preloader2 loading"
            // style={{ transform: 'translate(-40%, -50%)', width: 'unset', height: 'unset' }}
            style={{
              marginLeft: '107px',
              textAlign: 'center',
              position: 'absolute',
              left: '40%',
              top: '72px',
            }}
          >
            Loading appointments
            <img src={`images/chatLoader.gif`} className="ml-1" alt="" />
          </div>
          // </div>
        )}
        <div className="">
          <FullCalendar
            resources={
              this.state.practitionerValue && this.state.practitionerValue.length > 0
                ? this.props.Practitioner.filter(item => {
                    // debugger;
                    return (
                      this.state.practitionerValue &&
                      this.state.practitionerValue.split(',') &&
                      this.state.practitionerValue.split(',').includes(item.id.toString())
                    );
                  })
                : this.props.Practitioner
            }
            // resources={practitioners}
            // initialEvents={this.state.initialEvents} // alternatively, use the `events` setting to fetch from a feed
            select={this.handleDateSelect}
            eventClick={this.handleEventClick}
            events={this.state.initialEvents}
            disabledTimeSlots={this.props.disabledTimeSlots}
          />
        </div>

        <Modal
          zIndex={100}
          open={this.state.inviteSubscriberModal}
          footer={null}
          closable={false}
          width={980}
          destroyOnClose={true}
        >
          <ReduxCreateEventModal
            // {...this.props}
            initialValues={this.state.CreateEventInitialValues}
            dateClicked={this.state.dateClicked}
            selectInfo={this.state.selectInfo}
            medicateCombos={this.props.medicateCombosObj}
            onCancel={() => {
              this.resetSearchClientValues();
              this.props.dispatch(resetGetClientGroup());
              this.props.dispatch(resetAppointmentGetClientUpcomingSchedule());
              this.setState({
                inviteSubscriberModal: false,
              });
            }}
            setSearchClientValues={this.setSearchClientValues}
            resetSearchClientValues={this.resetSearchClientValues}
            AppointmentTypeDropDown={this.props.AppointmentTypeDropDown}
            getGroupName={this.getGroupName}
          />
        </Modal>
        <Modal
          zIndex={100}
          open={this.state.eventViewModal}
          footer={null}
          closable={false}
          width={980}
          destroyOnClose={true}
        >
          <IndividualAppointmentModal
            isReadOnlyUser={isReadOnlyUser}
            AppointmentId={this.state.clickedEventId}
            clickInfo={this.state.clickInfo}
            resetClickInfo={this.resetClickInfo}
            // ClientId={this.props.viewId}
            // dateClicked={this.state.dateClicked}
            // deleteEventDetails={this.props.deleteEvent}
            // medicateCombos={this.props.medicateCombosObj}
            onCancel={() => {
              // this.props.dispatch(
              //   getClientAppointment({
              //     Json: JSON.stringify({
              //       Appointment: [
              //         {
              //           ClientId: this.getClientId(),
              //         },
              //       ],
              //     }),
              //   }),
              // );
              this.setState({
                eventViewModal: false,
              });
            }}
            AppointmentTypeDropDown={this.props.AppointmentTypeDropDown}
          />
        </Modal>
        <Modal
          zIndex={100}
          open={this.state.groupEventViewModal}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose={true}
        >
          <GroupAppointmentModal
            isReadOnlyUser={isReadOnlyUser}
            GroupAppointmentId={this.state.clickedEventId}
            AppointmentRecord={this.state.clickInfo}
            resetClickInfo={this.resetClickInfo}
            // initialValues={this.state.initGroupEventValues}
            // g_members_list={this.state.g_members_list}
            // dateClicked={this.state.dateClicked}
            // deleteEventDetails={this.props.deleteEvent}
            // medicateCombos={this.props.medicateCombosObj}
            onCancel={() => {
              // this.props.dispatch(
              //   getGroupAppointment({
              //     json: JSON.stringify({
              //       GroupAppointment: [
              //         {
              //           GroupId: this.getGroupId(),
              //         },
              //       ],
              //     }),
              //   }),
              // );
              this.setState({
                groupEventViewModal: false,
              });
            }}
          />
        </Modal>
        <Modal
          zIndex={100}
          open={this.state.blockAppointmentModal}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose={true}
        >
          <BlockAppointmentModal
            isReadOnlyUser={isReadOnlyUser}
            GroupAppointmentId={this.state.clickedEventId}
            AppointmentRecord={this.state.clickInfo}
            resetClickInfo={this.resetClickInfo}
            // initialValues={this.state.initGroupEventValues}
            // g_members_list={this.state.g_members_list}
            // dateClicked={this.state.dateClicked}
            // deleteEventDetails={this.props.deleteEvent}
            // medicateCombos={this.props.medicateCombosObj}
            onCancel={() => {
              // this.props.dispatch(
              //   getGroupAppointment({
              //     json: JSON.stringify({
              //       GroupAppointment: [
              //         {
              //           GroupId: this.getGroupId(),
              //         },
              //       ],
              //     }),
              //   }),
              // );
              this.setState({
                blockAppointmentModal: false,
              });
            }}
          />
        </Modal>
        <CustomModal2
          isOpen={this.state.isModelOpen}
          // header={errorMessage || 'Your changes have been saved successfully'}
          header={this.state.inviteMsg}
          msgContentArray={this.state.warningContent}
          yesAction={() => {
            // this.toggle();
            // this.toggleView();
            // this.props.onCancel();
            // this.props.history.goBack();
            this.setState({
              isModelOpen: false,
              inviteMsg: '',
              warningContent: '',
              clickInfo: '',
            });
            this.props.dispatch(resetUpsertBlockAppointment({}));
          }}
          yesText="OK"
          hideCancel={true}
          changeMaxWidth="100%"
        />
      </div>
    );
  }
}
const mapStateToProps = ({
  medicate_combos,
  list_appointment,
  upsert_appointment,
  upsert_group_appointment,
  delete_appointment,
  deactivate_group_appointment,
  upsert_group_appointment_group_all_info_json,
  combos,
  combo_practitioner_all,
  combo_facility_all,
  get_group,
  get_staff_practitioner,
  upsert_block_appointment,
  default_values,
  get_group_dropdown,
  default_practotioner_facility_get,
  appointment_auto_select_get,
}) => {
  const { data, isFetching, error } = list_appointment;
  const { data: medicateCombos } = medicate_combos;
  const { data: practitionerDataAll } = combo_practitioner_all;
  const { data: facilityDataAll } = combo_facility_all;
  const { data: getGroupData } = get_group;
  const {
    data: deleteEventData,
    isFetching: isFetchingDeleteAppointment,
    error: errorDeleteAppointment,
  } = delete_appointment;

  const {
    data: deleteGroupEventData,
    isFetching: isFetchingDeleteGroupAppointment,
    error: errorDeleteGroupAppointment,
  } = deactivate_group_appointment;

  const {
    data: getGroupAppointmentData,
    isFetching: isFetchingGetGroupAppointment,
    error: errorGetGroupAppointment,
  } = upsert_group_appointment_group_all_info_json;

  const { data: getStaffPractitionerValue } = get_staff_practitioner;
  const { data: defaultPractotionerFacilityValues } = default_practotioner_facility_get;

  const { data: upsertBlockAppointmentData } = upsert_block_appointment;
  const { data: getInitvaluesBasedOnGroupData } = get_group_dropdown;
  const { data: appTypeDefaultOptions } = appointment_auto_select_get;
  let upsertBlockSuccess;
  let appointments = [];
  let colorCoding = [];
  let practitioners = [];
  let deleteEvent = false;
  let deleteGroupEvent = false;
  let getGroupAppointment = {};
  let isAppointmentBooked = false;
  let warningMsgIndivisualAppointment = [];
  let isGroupAppointmentBooked = false;
  let warningMsgGroupAppointment = [];
  let isSuccess = false;
  let medicateCombosObj = {
    ServiceCode: [],
    Status: [],
  };
  let Practitioner = [];
  let Facility = [];
  let groupDataInfo;
  let AppointmentTypeDropDown = [];
  let isReadOnlyUser = false;
  let getInitValuesBasedOnGroupValues = {};
  let disabledTimeSlots = [];
  let initBasedOnAppointmentType = {};

  if (appTypeDefaultOptions && appTypeDefaultOptions.data) {
    initBasedOnAppointmentType = {
      values:
        JSON.parse(appTypeDefaultOptions.data) &&
        JSON.parse(appTypeDefaultOptions.data).Appointment &&
        JSON.parse(appTypeDefaultOptions.data).Appointment[0],
      newAppointment: appTypeDefaultOptions.newAppointment,
    };
  }

  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
      Status: medicateCombos.Status,
    };
  }
  if (practitionerDataAll && practitionerDataAll.PractitionerList) {
    Practitioner = practitionerDataAll.PractitionerList.map(item => {
      return {
        ...item,
        id: item.Value,
        title: item.Text,
      };
    }) || [];
  }
  if (facilityDataAll && facilityDataAll.Facility) {
    Facility = facilityDataAll.Facility || [];
  }
  if (data) {
    // console.log('practitioners', data.Practitioners);
    appointments = data.Appointment || [];
    colorCoding = data.ColorCoding || [];
    practitioners = data.Practitioners || [];
    disabledTimeSlots = data.disabledTimeSlots || [];
  }
  if (deleteEventData && deleteEventData.success) {
    deleteEvent = deleteEventData.success;
  }
  if (deleteGroupEventData && deleteGroupEventData.success) {
    deleteGroupEvent = deleteGroupEventData.success;
  }
  if (getGroupAppointmentData) {
    getGroupAppointment = getGroupAppointmentData.GroupAppointment[0];
  }
  if (upsert_appointment.data && upsert_appointment.data.success) {
    isAppointmentBooked = upsert_appointment.data.success;
    warningMsgIndivisualAppointment = JSON.parse(upsert_appointment.data.data)[0].Warning;
  }
  if (upsert_group_appointment.data && upsert_group_appointment.data.success) {
    isGroupAppointmentBooked = upsert_group_appointment.data.success;
    warningMsgGroupAppointment = JSON.parse(upsert_group_appointment.data.data)[0].Warning;
  }
  if (getGroupData && getGroupData.Group) {
    groupDataInfo = getGroupData.Group[0] || {};
  }
  if (combos.data && combos.data['AppointmentType']) {
    AppointmentTypeDropDown = combos.data['AppointmentType'].map(item => {
      return {
        label: item.Text,
        value: item.Value,
      };
    });
  }
  if (
    upsertBlockAppointmentData &&
    upsertBlockAppointmentData.success &&
    upsertBlockAppointmentData.data
  ) {
    upsertBlockSuccess =
      JSON.parse(upsertBlockAppointmentData.data)[0].SuccessMsg ||
      JSON.parse(upsertBlockAppointmentData.data)[0].ErrorMsg;
  }
  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  if (getInitvaluesBasedOnGroupData) {
    getInitValuesBasedOnGroupValues =
      getInitvaluesBasedOnGroupData &&
      getInitvaluesBasedOnGroupData.Group &&
      getInitvaluesBasedOnGroupData.Group[0];
  }

  return {
    medicateCombosObj: medicateCombosObj,
    Practitioner: Practitioner,
    Facility: Facility,
    appointments,
    deleteEvent: deleteEvent,
    deleteGroupEvent: deleteGroupEvent,
    getGroupAppointment: getGroupAppointment || {},
    isFetching: isFetching || false,
    isAppointmentBooked: isAppointmentBooked,
    warningMsgIndivisualAppointment,
    isGroupAppointmentBooked: isGroupAppointmentBooked,
    warningMsgGroupAppointment,
    groupDataInfo,
    AppointmentTypeDropDown,
    getStaffPractitionerValue,
    defaultPractotionerFacilityValues,
    colorCoding,
    practitioners,
    upsertBlockSuccess,
    isReadOnlyUser,
    getInitValuesBasedOnGroupValues,
    disabledTimeSlots,
    initBasedOnAppointmentType,
  };
};

export default connect(mapStateToProps)(Appointments);
