import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ClientProfileHeaderInPayer from '../../customScreens/clientProfileHeaderInPayer';
import PayerDetails from '../../RCM/patientEnrollmentForm/formSteps/smartContract/payerDetails';
import ClientAuthorizationInfo from './clientAuthorizationInfo';
import ClientAuthorizationsList from './clientAuthorizationsList';
import ProviderAndClientDetails from './providerAndClientDetails';
import Loader, { Loader2 } from '../../../Components/Loader';
import {
  payerPortalAuthorizationGet,
  smartContractGet,
  clientBannerGet,
  clientInsuranceGet,
} from '../../../store/actions';

class ClientAuthorizationDetails extends Component {
  constructor(props) {
    super(props);
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    this.props.dispatch(
      payerPortalAuthorizationGet({
        JSON: JSON.stringify({
          Authorizations: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );

    this.props.dispatch(
      clientInsuranceGet({ Json: JSON.stringify({ Client: [{ ClientId: this.getClientId() }] }) }),
    );

    let data = { SmartContract: [{ ClientId: this.getClientId(), IsReadOnly: true }] };

    var smartContractData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(smartContractGet(smartContractData));
  }

  // componentWillUnmount() {
  // }
  render() {
    const {
      clientDetails,
      insuranceDetails,
      authorizationOverView,
      authorizationsList,
      providerDetails,
      contractDetailsOverView,
      smartContractDetailArray,
      smartContractMilestones,
      isFetching,
      profileBanner,
      smartContractList,
    } = this.props;
    return (
      <div className="container-fluid payer-authorizations">
        {/*  */}
        <Loader loading={isFetching} />
        <div class="">
          <div className="row">
            <div className="col-12 top-table-search mb-2 pt-2">
              <div className="col-12">
                <Link to="/payer/authorizations" className="back-link">
                  <img src="images/back-arrow.svg" /> Back
                </Link>
                <h3 className="form-main-head mb-3">Authorization Details</h3>
              </div>
              <div className="card border-0 students-profile">
                {/* <h4 style={{margin: '0'}}>CLIENT DETAILS</h4> */}
                <ClientProfileHeaderInPayer />
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div className="row">
            <div className="col-12 mb-2 pt-4">
              <div className="card border-0 students-profile" style={{ padding: '18px' }}>
                <div className="row align-items-center">
                  <div
                    className="root-smart-contract"
                    style={{
                      width: '100%',
                      padding: '10px',
                    }}
                  >
                    <PayerDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <InsuranceDetails insuranceDetails={insuranceDetails} /> */}
        <div class="">
          <div className="row">
            <div className="col-12 mb-2 pt-4">
              <div className="card border-0 students-profile" style={{ padding: '20px' }}>
                <h4 className="text-uppercase">Authorizations</h4>
                <ClientAuthorizationInfo authorizationOverView={authorizationOverView} />
                <ClientAuthorizationsList
                  smartContractList={smartContractList}
                  contractDetailsOverView={contractDetailsOverView}
                  smartContractDetailArray={smartContractDetailArray}
                  smartContractMilestones={smartContractMilestones}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div className="row">
            <div className="col-12 mb-2 pt-4">
              <div className="card border-0 students-profile" style={{ padding: '20px' }}>
                <div className="row card-body align-items-center">
                  <div
                    // className="d-flex justify-content-between align-items-center"
                    style={{
                      width: '100%',
                      backgroundColor: '#F8F8F8',
                      padding: '20px',
                      border: ' 1px solid #EAEAEA',
                    }}
                  >
                    <h4 className="text-uppercase">Provider Contact Details</h4>
                    <ProviderAndClientDetails details={providerDetails} />
                  </div>

                  {/* <div
                    className="mt-5"
                    style={{
                      width: '100%',
                      backgroundColor: '#F8F8F8',
                      padding: '20px',
                      border: ' 1px solid #EAEAEA',
                    }}
                  >
                    <h4 className="text-uppercase">Client Contact Details</h4>
                    <ProviderAndClientDetails details={clientDetails} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default ClientAuthorizationDetails;

const mapStateToProps = ({
  payer_portal_authorization_get,
  smart_contract_get,
  client_banner_get,
}) => {
  const { data: authorizationData, error, isFetching } = payer_portal_authorization_get;
  const { data: smartContractDetails } = smart_contract_get;

  let authorizations = [];
  let clientDetails = [];
  let centerName = null;
  let insuranceDetails = [];
  let authorizationOverView = [];
  let providerDetails = [];

  let contractDetailsOverView = {};
  let smartContractDetailArray = [];
  let smartContractMilestones = [];
  let smartContractList = [];

  let { data: bannerDetails } = client_banner_get;
  let BannerData = {};
  let profileBanner = [];

  if (bannerDetails && bannerDetails.Banner) {
    profileBanner = bannerDetails.Banner[0];
  }

  if (smartContractDetails && smartContractDetails.SmartContract) {
    smartContractDetailArray = smartContractDetails.SmartContract[0];
    contractDetailsOverView =
      smartContractDetails.SmartContract[0] &&
        smartContractDetails.SmartContract[0].ContractDetailsOverView ?
        smartContractDetails.SmartContract[0].ContractDetailsOverView[0] : {};
    
    smartContractMilestones =
      smartContractDetails.SmartContract[0] && smartContractDetails.SmartContract[0].Milestones
        ? smartContractDetails.SmartContract[0].Milestones
        : [];
  }

  if (authorizationData && authorizationData.Authorizations) {
    authorizations = authorizationData.Authorizations[0];
    clientDetails =
      (authorizationData.Authorizations[0].ClientDetails &&
        authorizationData.Authorizations[0].ClientDetails[0]) ||
      [];
    providerDetails =
      (authorizationData.Authorizations[0].ProviderDetails &&
        authorizationData.Authorizations[0].ProviderDetails[0]) ||
      [];
    centerName = authorizationData.Authorizations[0].CenterName;
    insuranceDetails =
      (authorizationData.Authorizations[0].InsuranceDetails &&
        authorizationData.Authorizations[0].InsuranceDetails[0]) ||
      [];
    authorizationOverView =
      (authorizationData.Authorizations[0].AuthorizationOverView &&
        authorizationData.Authorizations[0].AuthorizationOverView[0]) ||
      [];

    if (authorizationData.Authorizations[0].SmartContract) {
      smartContractList = authorizationData.Authorizations[0].SmartContract;
    }
  }
  return {
    authorizations,
    clientDetails,
    centerName,
    insuranceDetails,
    authorizationOverView,
    providerDetails,
    contractDetailsOverView,
    smartContractDetailArray,
    isFetching,
    profileBanner,
    smartContractList,
    smartContractMilestones,
  };
};

export default connect(mapStateToProps)(withRouter(ClientAuthorizationDetails));
