import React, { useState, useEffect } from 'react';
import { Collapse, Button, Drawer, Divider, Space, Tag, Tooltip } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusPercentageInput,
  ReduxPlusMinusInputWithRange,
} from '../../../../../../component/custom-redux-components';
import {
  comboServiceCode,
  getServiceCode,
  smartContractUpdate,
} from '../../../../../../store/actions';
import validate from '../../../../../../core/generic-form/validation';
import { currencyFormatter, numberFormat } from '../../../../../../util/formatter';

const { Panel } = Collapse;

const AddRemarkForm = props => {
  const { handleSubmit, markAsCompleteId, drawerData } = props;
  const {
    CodeName,
    ServiceCodeDescription,
    StandardCost,
    ServiceDelivedDate,
    ServiceStatus,
  } = drawerData;
  const dispatch = useDispatch();
  return (
    <form
      onSubmit={handleSubmit(values => {
        let data = {
          SmartContract: [
            {
              SmartContractServiceBundleServiceCodeId: markAsCompleteId,
              Remarks: values.remarks,
              IsCompleted: '1',
            },
          ],
        };
        dispatch(
          smartContractUpdate({
            json: JSON.stringify(data),
          }),
        );
      })}
    >
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Code
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {CodeName}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Code Description
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {ServiceCodeDescription}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Cost
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {currencyFormatter.format(StandardCost || 0)}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Delived Date
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {ServiceDelivedDate}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Status
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {ServiceStatus}
          </span>
        </div>
      </div>
      <div class="col-12 enrollment-form mt-3">
        <div class="form-group">
          <Field
            name="remarks"
            type="text"
            errorMessage="remark Required"
            component={ReduxTextarea}
            validate={validate.required}
            fieldName={'Remarks'}
          />
          <label class="placeholder-label">Add Remarks*</label>
        </div>
      </div>
      <button type="submit" className="btn btn-primary">
        Add Remarks
      </button>
    </form>
  );
};

const ReduxAddRemarkForm = reduxForm({
  form: 'addRemarkForm',
  enableReinitialize: true,
})(connect()(withRouter(AddRemarkForm)));

const RoleArray = props => {
  const { label, value, perHour, extraRequiredUnit, payFrequency, isCurrencyFormatter } = props;
  return (
    <>
      <div class="form-group mb-0 w-100 col-md-3">
        <label
          className="placeholder-label"
          style={{
            zIndex: 1,
            fontSize: '11px',
            top: '-5px',
            // left: !!disabled || value || active ? '0' : '0',
            position: 'absolute',
          }}
        >
          {label}
        </label>
        <span
          className=""
          style={{
            marginTop: '13px',
            fontSize: '16px',
            color: '#000',
            padding: '3px',
            display: 'inline-block',
          }}
        >
          {/* {!extraRequiredUnit && (
            <>{payFrequency ? `$${value}${perHour ? payFrequency : ''}` : `${value}`}</>
          )} */}

          {!extraRequiredUnit && (
            <>
              {payFrequency
                ? `${isCurrencyFormatter ? currencyFormatter.format(value || 0) : '$'`${value}`}${
                    perHour ? payFrequency : ''
                  }`
                : `${value}`}
            </>
          )}

          {extraRequiredUnit ? `${value} ${payFrequency && payFrequency.replace('/', '')}` : null}
        </span>
      </div>
    </>
  );
};

const BuildServiceBundle = props => {
  const { disabled } = props;
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [markAsCompleteId, setMarkAsCompleteId] = useState();
  const [drawerData, setDrawerData] = useState();
  const [serviceCodeInfo, setServiceCodeInfo] = useState([
    // {
    //   CodeId: 6712,
    //   ServiceCodeId: 160,
    //   ServiceCodeDescription: '1Cervicography',
    //   NoOfServices: 2,
    //   LaberCost: 1,
    //   ConsumableCost: 1,
    //   OverHeadCost: 2,
    //   StandardCost: 4,
    //   NetMargin: 2,
    //   BillingRate: 4.08,
    //   NetAmount: 0.08,
    //   OverHeadCostPercentage: 100,
    //   BundleServiceCodeId: 60,
    // },
    // {
    //   CodeId: 6712,
    //   ServiceCodeId: 160,
    //   ServiceCodeDescription: '2Cervicography',
    //   NoOfServices: 2,
    //   LaberCost: 1,
    //   ConsumableCost: 1,
    //   OverHeadCost: 2,
    //   StandardCost: 4,
    //   NetMargin: 2,
    //   BillingRate: 4.08,
    //   NetAmount: 0.08,
    //   OverHeadCostPercentage: 100,
    //   BundleServiceCodeId: 60,
    // },
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      comboServiceCode({
        Json: JSON.stringify({
          ServiceCode: [
            {
              ServiceCodeTypeId: 2,
            },
          ],
        }),
      }),
    );
  }, []);

  useEffect(() => {
    if (props.serviceCodeInfo && Object.keys(props.serviceCodeInfo).length > 0) {
      let tempInit = props.initialValues.filter(item => item != undefined);
      setServiceCodeInfo(tempInit);
    }
  }, [props.serviceCodeInfo]);

  useEffect(() => {
    if (
      props.initialValues &&
      JSON.stringify(props.initialValues) != JSON.stringify(serviceCodeInfo)
    ) {
      let tempInit = props.initialValues.filter(item => item != undefined);
      setServiceCodeInfo(tempInit);
    }
  }, [props.initialValues]);

  const {
    fields,
    CenterConsumable,
    ConsumableFormData,
    setTotalCostOfCare,
    comboServiceCodeValue,
  } = props;

  const handleTotalChange = () => {
    if (
      ConsumableFormData &&
      ConsumableFormData.some(item => item && item.Consumable != undefined)
    ) {
      let result = ConsumableFormData.map((Item, Index) => {
        let filterV;
        let consumablePriceValue;
        if (Item != undefined && Item.Consumable != undefined) {
          filterV = CenterConsumable.filter(option => {
            return option.Value == Item.Consumable;
          });
          consumablePriceValue = filterV.length > 0 && filterV[0].Price * Item.UnitNeeded;
        }
        return consumablePriceValue;
      });
      let newTotal =
        result.filter(item => item).length > 0 &&
        result
          .filter(item => item)
          .reduce((p, c) => {
            return p + c;
          });
      if (!isNaN(newTotal)) {
        setTotal(newTotal);
        setTotalCostOfCare(newTotal);
      }
    } else {
      setTotal(0);
      setTotalCostOfCare(0);
    }
  };

  const onChange = key => {
    console.log(key);
  };

  const showDrawer = serviceData => {
    setOpen(true);
    setDrawerData(serviceData);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleTotalChange();
  }, [ConsumableFormData]);

  return (
    <div className="row buildServiceBundle">
      <div className="col-12 col-md-12 col-lg-12 my-3">
        <div className="p-2">
          <div className="d-flex justify-content-between p-2 mx-3">
            <div className="bold h5">Services</div>
            <div>
              {serviceCodeInfo && serviceCodeInfo.length > 0 && props.isButtonVisible && (
                <button
                  type="button"
                  class="btn-newCon"
                  style={{
                    position: 'unset',
                    width: 'unset',
                  }}
                  onClick={() => fields.push()}
                >
                  + Add {fields.length == 0 ? 'Service' : `Another Service`}
                </button>
              )}
            </div>
          </div>
          <Collapse
            expandIcon={({ isActive }) => (
              <RightCircleOutlined
                rotate={isActive ? -90 : 90}
                style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
              />
            )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={onChange}
          >
            {fields.length > 0 ? (
              fields.map((item, index) => {
                const {
                  LaberCost,
                  NetMargin,
                  NetMarginCost,
                  NetAmount,
                  OverHeadCost,
                  StandardCost,
                  TotalCost,
                  TotalServiceCost,
                  UnitOfMeasurementId,
                  UpdatedBy,
                  ConsumableCost,
                  ServiceCodeDescription,
                  BillingRate,
                  OverHeadCostPercentage,
                  SmartContractServiceBundleServiceCodeId,
                  IsServiceCompleted,
                  ServiceStatus,
                } = (serviceCodeInfo && serviceCodeInfo.length > 0 && serviceCodeInfo[index]) || {};
                return (
                  <>
                    <Panel
                      header={
                        <>
                          <div
                            class="mx-4"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            {' '}
                            <div class="row p-2">
                              <div className="col-6">
                                <div class="form-group">
                                  <Field
                                    name={`${item}ServiceCodeId`}
                                    type="text"
                                    errorMessage="State Required"
                                    component={ReduxSelect}
                                    options={comboServiceCodeValue}
                                    validate={validate.required}
                                    placeholder={`Service Code ${index + 1}*`}
                                    fieldName={'Service Code'}
                                    class="form-control"
                                    onChange={selectedValue => {
                                      const { bundleIndex } = props;
                                      dispatch(
                                        getServiceCode({
                                          json: JSON.stringify({
                                            ServiceCode: [
                                              {
                                                ServiceCodeId: selectedValue,
                                              },
                                            ],
                                          }),
                                          index,
                                          bundleIndex,
                                        }),
                                      );
                                    }}
                                    disabled={disabled}
                                  />
                                  {/* <label class="placeholder-label">Select Consumable</label> */}
                                  {/* <span class="small-info">Provide a specific list of goal(s) to be achieved</span> */}
                                </div>
                              </div>

                              {!props.isButtonVisible && !IsServiceCompleted && (
                                <div className="col-6 text-right">
                                  <div
                                    className="btn-approved"
                                    style={{ display: 'inline-block' }}
                                    onClick={() => {
                                      showDrawer(serviceCodeInfo[index]);
                                      setMarkAsCompleteId(SmartContractServiceBundleServiceCodeId);
                                    }}
                                  >
                                    Mark as Completed
                                  </div>
                                </div>
                              )}

                              <>
                                {IsServiceCompleted && ServiceStatus && (
                                  <div className="col-6 text-right d-flex align-items-center justify-content-end ">
                                    {' '}
                                    {/* <span
                                      style={
                                        ServiceStatus == 'Completed'
                                          ? {
                                              background: '#52c41a',
                                              color: 'white',
                                              padding: '5px',
                                              marginBottom: '15px',
                                            }
                                          : { background: 'red', color: 'white', padding: '5px' }
                                      }
                                    >
                                      Service Status: {ServiceStatus}
                                    </span> */}
                                    <span style={{ marginBottom: '15px' }}>
                                      <Tag icon={<CheckCircleOutlined />} color="success">
                                        <Tooltip title="Service Status">{ServiceStatus}</Tooltip>
                                      </Tag>
                                    </span>
                                  </div>
                                )}
                              </>

                              {props.isButtonVisible && (
                                <div className="col-6 text-right">
                                  <div className="row">
                                    <div className="col-12 mt-3 mb-2">
                                      <a
                                        class="col-1 delete-contract"
                                        onClick={() => {
                                          if (fields.length && fields.length > 0) {
                                            fields.remove(index);
                                          }
                                        }}
                                      >
                                        <i class="fas fa-trash" /> Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {ServiceCodeDescription ? (
                                <div className="col-12">
                                  <div>
                                    <div style={{ color: '#959595', fontSize: '14px' }}>
                                      Code Description
                                    </div>
                                    <div style={{ color: '#525252', fontSize: '16px' }}>
                                      {ServiceCodeDescription}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      }
                      key={index}
                    >
                      {serviceCodeInfo &&
                      serviceCodeInfo.length > 0 &&
                      serviceCodeInfo[index] &&
                      Object.keys(serviceCodeInfo[index]).length > 0 ? (
                        <>
                          <div>
                            <div
                              className="mt-4 mb-4"
                              style={{ fontWeight: '600', fontSize: '16px' }}
                            >
                              Labor Assigned(
                              {serviceCodeInfo[index] &&
                                serviceCodeInfo[index].ServiceCodeLaberCost &&
                                serviceCodeInfo[index].ServiceCodeLaberCost.length}
                              )
                            </div>
                            <div>
                              {serviceCodeInfo[index] &&
                                serviceCodeInfo[index].ServiceCodeLaberCost &&
                                serviceCodeInfo[index].ServiceCodeLaberCost.map((item, index) => {
                                  const {
                                    ServiceCodeLaberCostId,
                                    CenterLaborCostId,
                                    LaberCostTypeId,
                                    Cost,
                                    ExtraRequiredUnit,
                                    TotalCost,
                                    PayFrequency,
                                    RoleDescription,
                                    LaberCostType,
                                    UnitOfMeasurement,
                                    PayFrequency1,
                                  } = item;
                                  return (
                                    <>
                                      <div
                                        className="row"
                                        style={{
                                          background: '#F8F8F8',
                                          border: '1px solid #EAEAEA',
                                          borderRadius: '4px',
                                          padding: '10px',
                                          margin: '10px',
                                        }}
                                      >
                                        <RoleArray label={'Labor Role'} value={RoleDescription} />
                                        <RoleArray label={'Cost Type'} value={LaberCostType} />
                                        <RoleArray
                                          label={'Cost'}
                                          value={Cost}
                                          perHour={LaberCostTypeId != 1 ? true : false}
                                          payFrequency={PayFrequency}
                                          isCurrencyFormatter={true}
                                        />
                                        {LaberCostTypeId != 1 && (
                                          <RoleArray
                                            label={'Duration Required'}
                                            value={ExtraRequiredUnit}
                                            extraRequiredUnit={ExtraRequiredUnit ? true : false}
                                            LaberCostTypeId={LaberCostTypeId}
                                            payFrequency={PayFrequency1}
                                          />
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="col-8 mt-3 noOfService">
                            <div
                              class="license-quantity d-flex  justify-content-left"
                              style={{ fontWeight: '600' }}
                            >
                              <label
                                class="placeholder-label flex-grow-1"
                                style={{ fontSize: '16px' }}
                              >
                                Number of Services Required
                                <div style={{ color: '#7F8188', fontSize: '14px' }}>
                                  {serviceCodeInfo &&
                                    serviceCodeInfo[index] &&
                                    serviceCodeInfo[index].UnitOfMeasurement}
                                </div>
                              </label>
                              <div class="">
                                <Field
                                  name={`${item}NoOfServices`}
                                  type="text"
                                  component={ReduxPlusMinusInputWithRange}
                                  //   validate={validate.required}
                                  fieldName={'No Of Services Needed'}
                                  className="number-icon"
                                  min={0}
                                  max={100}
                                  style={{
                                    textAlign: 'center',
                                    margin: '0px 5px',
                                    height: '32px',
                                    width: '56px',
                                    fontSize: '16px',
                                  }}
                                  onChange={item => {
                                    props.setIndexOfNetMarginChange(index);
                                    props.setIndexOfCurrentBundle(props.bundleIndex);
                                  }}
                                  disabled={disabled}
                                  disableOnPaste={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="shubham">
                            {' '}
                            <div
                              className="d-flex align-items-center blocks"
                              //  style={{
                              //    border: '1px solid #D8D8D8',
                              //  }}
                            >
                              <div
                                className="item"
                                style={{ position: 'relative', borderLeft: '1px solid #D8D8D8' }}
                              >
                                <div className="label">Labor Cost</div>
                                <div className="value">
                                  <span className="value-container">
                                    {/* {LaberCost ? LaberCost : 0} */}
                                    {currencyFormatter.format(LaberCost || 0)}
                                  </span>
                                </div>
                                <img
                                  src={`images/masterBillService/plus.svg`}
                                  width="11"
                                  height="11"
                                  alt=""
                                  style={{
                                    position: 'absolute',
                                    top: '70%',
                                    right: '-6px',
                                    zIndex: '1',
                                  }}
                                />
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">Consumable Cost</div>
                                <div className="value">
                                  <span className="value-container">
                                    {/* ${ConsumableCost ? ConsumableCost : 0} */}
                                    {currencyFormatter.format(ConsumableCost || 0)}
                                  </span>
                                </div>
                                <img
                                  src={`images/masterBillService/plus.svg`}
                                  width="11"
                                  height="11"
                                  alt=""
                                  style={{
                                    position: 'absolute',
                                    top: '70%',
                                    right: '-6px',
                                    zIndex: '1',
                                  }}
                                />
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">Overhead ({OverHeadCostPercentage}%)</div>
                                <div className="value">
                                  <span className="value-container">
                                    {/* ${OverHeadCost ? OverHeadCost : 0} */}
                                    {currencyFormatter.format(OverHeadCost || 0)}
                                  </span>
                                </div>
                                <img
                                  src={`images/masterBillService/equalTo.svg`}
                                  width="11"
                                  height="11"
                                  alt=""
                                  style={{
                                    position: 'absolute',
                                    top: '70%',
                                    right: '-6px',
                                    zIndex: '1',
                                  }}
                                />
                              </div>
                              <div
                                className="item"
                                style={{ position: 'relative', borderRight: '1px solid #e8e8e8' }}
                              >
                                <div className="label">Standard Cost</div>
                                <div className="value">
                                  <span className="value-container">
                                    {/* ${StandardCost ? StandardCost : 0} */}
                                    {currencyFormatter.format(StandardCost || 0)}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="item"
                                style={{ position: 'relative', display: 'none' }}
                              >
                                <div className="label">Net Margin Not being used %</div>
                                <div className="value">
                                  <span
                                    //  className="value-container"
                                    style={{
                                      padding: '9px 30.9px',
                                      margin: '15px',
                                      display: 'inline-block',
                                      textAlign: 'center',
                                      border: '0.5px solid #959595',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Not being used
                                    {/* {NetMargin ? NetMargin : 0}% */}
                                  </span>
                                </div>
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">Net Margin %</div>
                                <div className="value">
                                  <div
                                    class="license-quantity d-flex  justify-content-left"
                                    style={{ fontWeight: '600' }}
                                  >
                                    <span
                                    // className="value-container"
                                    // style={{
                                    //   padding: '9px 30.9px',
                                    //   margin: '15px',
                                    //   display: 'inline-block',
                                    //   textAlign: 'center',
                                    //   border: '0.5px solid #959595',
                                    //   fontWeight: '700',
                                    // }}
                                    >
                                      <Field
                                        name={`${item}NetMargin`}
                                        type="text"
                                        min={0}
                                        max={100}
                                        component={ReduxPlusMinusPercentageInput}
                                        disableOnPaste={true}
                                        //   validate={validate.required}
                                        fieldName={'NetMargin'}
                                        className="number-icon"
                                        style={{
                                          textAlign: 'center',
                                          margin: '0px 5px',
                                          height: '32px',
                                          width: '56px',
                                          fontSize: '16px',
                                        }}
                                        onChange={item => {
                                          props.setIndexOfNetMarginChange(index);
                                          props.setIndexOfCurrentBundle(props.bundleIndex);
                                        }}
                                        disabled={disabled}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">Net Amount</div>
                                <div className="value">
                                  <span className="value-container">
                                    {/* ${NetMarginCost ? NetMarginCost : NetAmount || 0} */}
                                    {NetMarginCost
                                      ? currencyFormatter.format(NetMarginCost || 0)
                                      : currencyFormatter.format(NetAmount || 0) || 0}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="item"
                                style={{ position: 'relative', borderRight: '1px solid #D8D8D8' }}
                              >
                                <div className="label">Billing Rate</div>
                                <div className="value">
                                  <span className="value-container">
                                    {/* ${TotalServiceCost ? TotalServiceCost : BillingRate || 0} */}
                                    {TotalServiceCost
                                      ? currencyFormatter.format(TotalServiceCost || 0)
                                      : currencyFormatter.format(BillingRate || 0) || 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-md-12 mx-4 mt-2">Please Select a Service Code.</div>
                          </div>
                        </>
                      )}
                    </Panel>
                  </>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
        </div>
      </div>
      <Drawer title="Add Remarks" placement="right" onClose={onClose} open={open} width="600">
        <ReduxAddRemarkForm markAsCompleteId={markAsCompleteId} drawerData={drawerData} />
      </Drawer>
    </div>
  );
};

const mapStateToProps = state => {
  const { combo_service_code } = state;
  const { data: comboServiceCodeData } = combo_service_code;
  let comboServiceCodeValue = [];

  if (comboServiceCodeData && comboServiceCodeData.ServiceCode) {
    comboServiceCodeValue = comboServiceCodeData.ServiceCode;
  }
  return {
    comboServiceCodeValue,
  };
};

const ReduxBuildServiceBundle = connect(mapStateToProps)(BuildServiceBundle);
export default ReduxBuildServiceBundle;
