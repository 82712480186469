import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AntdModal from '../../Components/CustomModal';
import SubmitButton from '../../Components/submitButton';
import CloseIcon from '../../Components/CloseIcon';
import { surveyQuestionSectionUpsert, resetSurveyQuestionSectionUpsert } from '../../store/actions';
import { Modal } from 'antd';

class CategoryUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryUpdate: this.props.categoryName || null,
    };
  }
  handelCategory = () => {
    this.props.dispatch(
      surveyQuestionSectionUpsert({
        JSON: JSON.stringify({
          Config: [
            {
              Description: this.state.categoryUpdate,
              SurveyQuestionCategoryConfigId: this.props.SurveyQuestionCategoryConfigId,
              SurveyQuestionSectionId: this.props.SurveyQuestionSectionId,
            },
          ],
        }),
      }),
    );
  };

  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState({
        categoryUpdate: null,
      });
    }
  }

  render() {
    const { categoryUpdate } = this.state;
    return (
      <div className="row enrollment-form manage-category my-4" style={{ padding: '0 20px' }}>
        <div className="d-flex col-12 justify-content-between text-right">
          <h4>Update Category</h4>
          <p style={{ cursor: 'pointer' }} onClick={this.props.onCancel}>
            <CloseIcon />
          </p>
        </div>
        {/* <div className="d-flex mt-4" style={{ width: '100%' }}> */}
        <div className="col-12 mt-3">
          <div class="form-group mb-2">
            <input
              className={`form-control ${categoryUpdate && 'not-empty'}`}
              onChange={e => {
                this.setState({ categoryUpdate: e.target.value });
              }}
              value={categoryUpdate}
            />
            <label className="placeholder-label">Update Category</label>
          </div>
        </div>
        <div className="col-12 mt-4 text-center">
          <SubmitButton
            loading={this.props.isFetching}
            onClick={this.handelCategory}
            value={'Update'}
            style={{ height: '45px' }}
            disabled={categoryUpdate && this.props.categoryName != categoryUpdate ? false : true}
          />
        </div>
        {/* </div> */}
      </div>
    );
  }
}

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: null,
    };
  }
  handelCategory = () => {
    this.props.dispatch(
      surveyQuestionSectionUpsert({
        JSON: JSON.stringify({
          Config: [
            {
              Description: this.state.category,
              SurveyQuestionCategoryConfigId: this.props.SurveyQuestionList
                .SurveyQuestionCategoryConfigId,
              SurveyQuestionSectionId: null,
            },
          ],
        }),
      }),
    );
  };
  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState({
        category: null,
      });
    }
  }
  render() {
    const {
      SurveyQuestionSection,
      Questions,
      SurveyQuestionCategoryConfigId,
    } = this.props.SurveyQuestionList;
    const { category, categoryUpdate, isCategoryModalOpen } = this.state;
    return (
      <div class="enrollment-form manage-category">
        <div className="row my-4">
          <div className="col-8">
            <div class="form-group mb-2">
              <input
                className={`form-control ${category && 'not-empty'}`}
                onChange={e => {
                  this.setState({ category: e.target.value });
                }}
                value={category}
              />
              <label className="placeholder-label">Add New Category</label>
            </div>
          </div>
          <div className="col-4">
            <SubmitButton
              loading={this.props.isFetching}
              onClick={this.handelCategory}
              value={'Add'}
              style={{ height: '45px', padding: '10px 40px' }}
              disabled={category ? false : true}
            />
          </div>
        </div>
        <div className="row">
          <p className="small-text col-12">
            Note: Newly added categories will be shown while adding a question.
          </p>
          <div className="col-12">
            <span className="library-category-title">Category List</span>
            <ul className="library-category-list">
              {Questions &&
                Questions.map((category, index) => {
                  const { Tab, SurveyQuestionSectionId, Questions, IsArchived } = category;
                  return (
                    <li>
                      <div className="category-list-item">
                        <p className="mb-0">{`${Tab} (Questions : ${
                          Questions ? Questions.length : 0
                        })`}</p>
                        {/* <img
                          onClick={() => {
                            this.setState({ isCategoryModalOpen: true });
                          }}
                          src={`images/edit.svg`}
                          style={{ cursor: 'pointer' }}
                        /> */}
                        <AntdModal
                          maskClosable={false}
                          ModalButton={({ onClick }) => (
                            <img
                              onClick={onClick}
                              src={`images/edit.svg`}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div className="modal-content border-0">
                              <div className="modal-header flex-wrap border-bottom-0 mb-2">
                                <CategoryUpdate
                                  categoryName={Tab}
                                  SurveyQuestionSectionId={SurveyQuestionSectionId}
                                  SurveyQuestionCategoryConfigId={
                                    this.props.SurveyQuestionList.SurveyQuestionCategoryConfigId
                                  }
                                  dispatch={this.props.dispatch}
                                  onCancel={onCancel}
                                  isFetching={this.props.isFetching}
                                  isSuccess={this.props.isSuccess}
                                />
                              </div>
                              {/* <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary py-1"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn py-1"
                                  onClick={() => {
                                    onOk();
                                    this.props.dispatch(
                                      surveyQuestionSectionUpsert({
                                        JSON: JSON.stringify({
                                          Config: [
                                            {
                                              SurveyQuestionSectionId: SurveyQuestionSectionId,
                                              Description: Tab,
                                              IsArchived: IsArchived ? false : true,
                                            },
                                          ],
                                        }),
                                      }),
                                    );
                                  }}
                                  disabled={Questions && Questions.length > 0 ? true : false}
                                >
                                  Ok
                                </button>
                              </div> */}
                            </div>
                          )}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ survey_question_section_upsert }) => {
  const { data, isFetching, error } = survey_question_section_upsert;
  return {
    isFetching,
    isSuccess: data && data.success,
  };
};

export default connect(mapStateToProps)(withRouter(AddCategory));
