import React, { Component } from 'react';
import { resetClientPin, upsertClientPin } from '../store/actions';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import { ClientImage } from '../component/student';

import { ReduxInput } from '../component/custom-redux-components';
import { Field, reduxForm, formValueSelector } from 'redux-form';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  NumericPin: value => {
    if (value) {
      const numWithoutChar = value.replace(/[^0-9\-\(\)\s)]+/g, '');
      if (value == numWithoutChar) {
        // const onlyNums = value.replace(/^[\d\(\)\-\s]+$/g, '');
        const onlyNums = numWithoutChar.replace(/\D/g, '');
        if (onlyNums.length != 4) {
          return 'Please enter 4 digits';
        }
      } else {
        return 'numeric values only';
      }
    }
  },
};

class RegisterPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      modalIsOpen: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(resetClientPin());
  }

  componentWillReceiveProps({ isInserted }) {
    if (isInserted && isInserted != this.props.isInserted) {
      if (this.getSurveyId() && this.getSurveyId() > 0) {
        this.setState({ modalIsOpen: true });
      } else {
        this.props.history.push({
          pathname: `cw/patient-management/patient-list`,
        });
      }
    }
  }

  componentDidMount() {
    //   var me = this;
    //   let form = $('.bootstrap-form');
    //   // On form submit take action, like an AJAX call
    //   $(form).submit(function(e) {
    //     me.state.isValid = true;
    //     me.onSave();
    //     return false;
    //   });
    //   // On every :input focusout validate if empty
    //   $(':input').blur(function() {
    //     let fieldType = this.type;
    //     switch (fieldType) {
    //       case 'password':
    //         me.validateText($(this));
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   // On every :input focusin remove existing validation messages if any
    //   $(':input').click(function() {
    //     $(this).removeClass('is-valid is-invalid');
    //   });
    //   // On every :input focusin remove existing validation messages if any
    //   $(':input').keydown(function() {
    //     $(this).removeClass('is-valid is-invalid');
    //   });
    // }
    // // Validate Text and password
    // validateText(thisObj) {
    //   let fieldValue = thisObj.val();
    //   if (fieldValue.length > 1) {
    //     $(thisObj).addClass('is-valid');
    //   } else {
    //     $(thisObj).addClass('is-invalid');
    //   }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('surveyId');
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('clientId');
  }
  getFormType() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('form');
  }
  onSave = () => {
    /*const { pinValue, cpinValue } = this.props;
    if (pinValue === cpinValue && pinValue.length >= 2) {
      this.props.upsertClientPin({
        studentId: this.getStudentId(),
        pin: pinValue,
      });
    }*/
  };

  onSubmit = values => {
    const { pin, cpin } = values;
    if (pin === cpin && pin.length >= 2) {
      this.props.dispatch(
        upsertClientPin({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.getClientId(),
                PIN: pin,
              },
            ],
          }),
        }),
      );
    }
  };

  redirectToValidate = () => {
    var searchStr =
      'surveyId=' +
      this.getSurveyId() +
      '&form=' +
      this.getFormType() +
      '&studentId=' +
      this.getStudentId();
    this.props.history.push({
      pathname: 'validate',
      search: searchStr,
    });
  };

  render() {
    const { pristine, submitting, pinValue, cpinValue, handleSubmit } = this.props;

    return (
      <div class="main survery-question-page  mb-5">
        <form onSubmit={handleSubmit(this.onSubmit)} className="container-fluid">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <ClientImage />
            </div>
          </div>
          <div class="row validate-pin">
            <div class="col-12">
              <div class="pin-container text-center">
                <div class="validate-pin-box">
                  <h5>Please Enter Pin</h5>
                  <Field
                    name="pin"
                    type="password"
                    errorMessage="Pin Required"
                    component={ReduxInput}
                    validate={[Validator.required, Validator.NumericPin]}
                    className="text-box"
                    fieldName={'Pin'}
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="pin-container text-center">
                <div class="validate-pin-box" style={{ marginTop: '20px' }}>
                  <br />
                  <h5>Confirm Pin</h5>
                  <Field
                    name="cpin"
                    type="password"
                    errorMessage="Confirm Pin Required"
                    component={ReduxInput}
                    validate={[Validator.required, Validator.NumericPin]}
                    className="text-box"
                    fieldName={'Confirm Pin'}
                  />
                  {pinValue !== cpinValue && cpinValue && cpinValue.length > 0 ? (
                    <div
                      style={{
                        width: '100%',
                        marginTop: '.25rem',
                        fontSize: '80%',
                        color: '#dc3545',
                      }}
                    >
                      Pin and Confirm Pin did not match.
                    </div>
                  ) : null}
                  <br />
                  <br />
                  <button onClick={this.onSave} class="btn btn-block">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <CustomModal
          header="Are you ready to take the survey"
          isOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          noAction={() => this.props.history.push('/clients/list')}
          yesAction={this.redirectToValidate}
          noText="No"
          yesText="Yes"
        />
      </div>
    );
  }
}

const selector = formValueSelector('PinForm');

const ReduxRegisterPin = reduxForm({
  form: 'PinForm',
  enableReinitialize: true,
})(RegisterPin);

const mapStateToProps = state => {
  var { upsert_client_pin } = state;
  var { data, error } = upsert_client_pin;
  var isInserted = false;
  var errorMessage = '';
  if (data && data.success) {
    isInserted = true;
  }
  if (error) {
    errorMessage = error.Message;
  }
  const pinValue = selector(state, 'pin');
  const cpinValue = selector(state, 'cpin');

  return {
    isInserted: isInserted,
    errorMessage: errorMessage,
    pinValue: pinValue,
    cpinValue: cpinValue,
  };
};

export default connect(mapStateToProps)(ReduxRegisterPin);
