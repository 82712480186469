import React, { Component } from 'react';

import Loader, { Loader2 } from '../../../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Avatar } from 'antd';
import { Checkbox, Radio, Tooltip, Modal, Drawer } from 'antd';
import AntdModal from '../../../Components/CustomModal';
import DrawerContent from '../Group/formSteps/drawer/drawerContent';
import CustomModal from '../../../core/modal';
import moment from 'moment';
import renderHTML from 'react-render-html';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  getSmallGroupList,
  getSmallGroup,
  sendVoucherCode,
  // appSubscribersUserSearch,
  upsertAppointment,
  deleteAppointment,
  practitionerCombos,
  practitionerCombosOffice,
  getClientGroup,
  upsertGroupAppointment,
  deactivateGroupAppointment,
  listCombos,
  recurringCount,
  medicateCombos,
  upsertClientGroupAppointment,
  upsertGroupAppointmentGroupAllInfoJson,
  listAppointment,
  getComboFacility,
  comboFacilityAll,
  patientComboGroup,
} from '../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { ProcessCombos } from '../../../store/comboConfig';
import { mapTime } from '../../../util/time';
import PersistTableSort from '../../../component/persistTableSort';
import AddNewMember from './addNewMember';

let eventGuid = 0;

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};
const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Mobile app referral sent successfully',
  });
};
const weekDays = [
  { label: 'Mon', value: '2' },
  { label: 'Tue', value: '3' },
  { label: 'Wed', value: '4' },
  { label: 'Thu', value: '5' },
  { label: 'Fri', value: '6' },
  { label: 'Sat', value: '7' },
  { label: 'Sun', value: '1' },
];

class GroupAppointmentModal extends Component {
  constructor(props) {
    super();
    this.state = {
      isModelOpen: false,
      initGroupEventValues: {
        g_members_list: [],
      },
    };
  }
  toggle = (header, messageContentGroupAppointmentSuccess) => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
      msgContentArray: messageContentGroupAppointmentSuccess,
    }));
  };
  componentWillReceiveProps({
    getGroupAppointment,
    isGroupAppointmentBooked,
    messageContentGroupAppointmentSuccess,
  }) {
    if (
      getGroupAppointment &&
      JSON.stringify(getGroupAppointment) != JSON.stringify(this.props.getGroupAppointment)
    ) {
      // this.props.dispatch(
      //   practitionerCombosOffice({
      //     json: JSON.stringify({
      //       PractitionerOffice: [
      //         {
      //           PractitionerId: getGroupAppointment.PractitionerId,
      //         },
      //       ],
      //     }),
      //   }),
      // );
      this.setState({
        initGroupEventValues: {
          ...this.state.initGroupEventValues,
          g_title: getGroupAppointment.Title,
          g_appointmentDate: getGroupAppointment.AppointmentDate,
          g_startTime: getGroupAppointment.AppointmentTime,
          g_endTime: getGroupAppointment.AppointmentEndTime,
          g_duration: parseInt(getGroupAppointment.DurationInMin),
          g_providerName: getGroupAppointment.PractitionerId,
          g_officeName: getGroupAppointment.OfficeName,
          g_serviceCode: parseInt(getGroupAppointment.ServiceCode),
          g_status: parseInt(getGroupAppointment.Status),
          g_reasonForVisit: getGroupAppointment.ReasonForVisit,
          g_group: getGroupAppointment.GroupId,
          g_members: getGroupAppointment.ClientGroupAppointment,
          g_program: parseInt(getGroupAppointment.ProgramId),
          g_placeOfService: getGroupAppointment.PlaceOfServiceId,
          recurringAppointment: getGroupAppointment.IsRecurring,
          isEditAll: getGroupAppointment.IsRecurring && false,
          showDeleteAll: getGroupAppointment.IsMultipleAppointment,
          appointmentType: getGroupAppointment.GroupAppointmentRecurring
            ? getGroupAppointment.GroupAppointmentRecurring[0].RecurringType
            : 'Weekly',
          dayOfWeek: getGroupAppointment.GroupAppointmentRecurring
            ? getGroupAppointment.GroupAppointmentRecurring[0].WeekDays &&
              getGroupAppointment.GroupAppointmentRecurring[0].WeekDays.split(',')
            : '',
          recurringOccurance:
            getGroupAppointment.GroupAppointmentRecurring &&
            getGroupAppointment.GroupAppointmentRecurring[0].MonthlyRecurring,
          recurringCount: getGroupAppointment.GroupAppointmentRecurring
            ? JSON.stringify(getGroupAppointment.GroupAppointmentRecurring[0].RecurringCount)
            : '2',
          GroupAppointmentRecurringId: getGroupAppointment.GroupAppointmentRecurring
            ? getGroupAppointment.GroupAppointmentRecurring[0].GroupAppointmentRecurringId
            : null,
          ShowNotes: getGroupAppointment.ShowNotes,
          isParentAppointmnet: getGroupAppointment.IsParentAppointmnet,
        },
        ShowNotes: getGroupAppointment.ShowNotes,
        g_members_list: getGroupAppointment.ClientGroupAppointment,
        enableAddGroupMember: getGroupAppointment.EnableAddGroupMember,
      });
    }

    if (
      isGroupAppointmentBooked &&
      isGroupAppointmentBooked != this.props.isGroupAppointmentBooked
    ) {
      this.props.onCancel();
    }

    // if (
    //   isGroupAppointmentBooked &&
    //   isGroupAppointmentBooked != this.props.isGroupAppointmentBooked
    // ) {
    //   this.props.dispatch(listAppointment({}));
    //   this.props.dispatch(reset('viewEventForm'));
    //   this.toggle(
    //     'Your appointment is scheduled successfully.',
    //     messageContentGroupAppointmentSuccess,
    //   );
    // }
  }

  componentDidMount() {
    let data = {
      json: JSON.stringify({
        Combo: [
          {
            GroupAppointmentId: this.props.GroupAppointmentId,
          },
        ],
      }),
    };

    this.props.dispatch(
      upsertGroupAppointmentGroupAllInfoJson({
        json: JSON.stringify({
          GroupAppointment: [
            {
              GroupAppointmentId: this.props.GroupAppointmentId,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(patientComboGroup(data));
    this.props.dispatch(getComboFacility(data));
    this.props.dispatch(comboFacilityAll(data));
  }
  render() {
    const { onOk, onCancel, GroupAppointmentId, isReadOnlyUser } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header flex-wrap border-bottom-0 ">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              // this.props.resetClickInfo();
              onCancel();
              this.setState({
                isDisable: true,
              });
            }}
          >
            ×
          </button>
        </div>
        <div className="modal-body rcm-panel">
          <ReduxGroupEventForm
            initialValues={this.state.initGroupEventValues}
            ShowNotes={this.state.ShowNotes}
            g_members_list={this.state.g_members_list}
            enableAddGroupMember={this.state.enableAddGroupMember}
            GroupAppointmentId={GroupAppointmentId}
            onCancel={onCancel}
            warningContent={this.props.warningContent}
            isReadOnlyUser={isReadOnlyUser}
            // deleteEventDetails={this.props.deleteEvent}
          />
        </div>
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            msgContentArray={this.state.msgContentArray}
            yesAction={() => {
              this.toggle();
              onCancel();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}

class GroupEventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      searchStr: '',
      selectedStudent: '',
      isClientView: true,
      searchedStudent: [],
      isDisable: true,
      clientId: props.clientId,
      invitationStatusOfClients: [],
      patientRemoved: [],
      isModelOpen: false,
      attendeeStatus: {},
      StatusChangeMsg: '',
      medicateCombosObjState: {},
      columns: [
        {
          title: 'Image',
          dataIndex: 'ProfileImgUrl',
          key: 'ProfileImgUrl',
          width: '20px',
          render: (text, record) => (
            <div className="student-list-pic">
              <img
                src={text ? text : 'images/announcements-img2.jpg'}
                className="img-circle"
                height="40"
                width="40"
                style={{ borderRadius: '50%', objectFit: 'cover' }}
                alt={'image'}
              />
            </div>
          ),
        },
        {
          title: 'Patient ID',
          // dataIndex: 'ClientId',
          dataIndex: 'DisplayClientId',
          key: 'ClientId',
          width: '30px',
          sorter: (a, b) => isComparer(a.DisplayClientId, b.DisplayClientId),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Patient Name',
          dataIndex: 'DisplayName',
          key: 'DisplayName',
          width: '40px',
          sorter: (a, b) => isComparer(a.DisplayName, b.DisplayName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          width: '55px',
          render: (text, record, index) => {
            return (
              <>
                {record.IsAppointment ? (
                  <div className="form-group mb-2">
                    <ReduxSelect
                      options={
                        this.state.medicateCombosObjState &&
                        this.state.medicateCombosObjState.Status
                      }
                      onChange={attendeeStatusId => {
                        this.handleAttendeeStatusChange(record.ClientId, attendeeStatusId, index);
                        this.props.dispatch(
                          upsertClientGroupAppointment({
                            Json: JSON.stringify({
                              ClientGroupAppointment: [
                                {
                                  GroupAppointmentId: this.props.GroupAppointmentId,
                                  ClientId: record.ClientId,
                                  Status: attendeeStatusId,
                                  IsAppointment: record.IsAppointment,
                                },
                              ],
                            }),
                          }),
                        );
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      value={parseInt(
                        this.state.invitationStatusOfClients.filter(
                          item => item.ClientId == record.ClientId,
                        )[0].Status,
                      )}
                      placeholder="Status"
                      disabled={
                        this.props.isReadOnlyUser || this.props.initialValues.isParentAppointmnet
                      }
                    />
                  </div>
                ) : (
                  <>Not Applicable</>
                )}
              </>
            );
          },
        },
        {
          title: () => {
            return (
              <>
                <span>
                  Include/
                  <br />
                  Exclude
                </span>
              </>
            );
          },
          dataIndex: 'IsAppointment',
          key: 'IsAppointment',
          width: '25px',
          render: (text, record, index) => {
            if (!this.props.isReadOnlyUser && !this.props.initialValues.isParentAppointmnet) {
              return (
                <div style={{ display: 'inline-block', marginTop: '10px' }}>
                  {text ? (
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          title="Click to Exclude"
                          style={{ color: '#FF3D57', cursor: 'pointer' }}
                          onClick={onClick}
                        >
                          <img
                            src={'images/circle-minus.svg'}
                            className="img-circle"
                            height="40"
                            width="40"
                            style={{ borderRadius: '50%' }}
                          />
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div className="modal-content border-0">
                          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 className="modal-title col-12 mb-3">
                              {`Are you sure you want to exclude this patient from
this appointment?`}
                            </h4>
                          </div>
                          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              className="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                this.props.dispatch(
                                  upsertClientGroupAppointment({
                                    Json: JSON.stringify({
                                      ClientGroupAppointment: [
                                        {
                                          GroupAppointmentId: this.props.GroupAppointmentId,
                                          ClientId: record.ClientId,
                                          Status: this.state.attendeeStatus[record.ClientId],
                                          IsAppointment: false,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                var invitationStatusOfClients2 = this.state.invitationStatusOfClients.map(
                                  item => {
                                    if (item.ClientId === record.ClientId) {
                                      return { ...item, IsAppointment: false };
                                    } else return item;
                                  },
                                );
                                this.setState({
                                  invitationStatusOfClients: invitationStatusOfClients2,
                                });
                                onOk();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  ) : (
                    <span
                      title="Click to Include"
                      style={{ color: '#09B66D', cursor: 'pointer' }}
                      onClick={() => {
                        this.props.dispatch(
                          upsertClientGroupAppointment({
                            Json: JSON.stringify({
                              ClientGroupAppointment: [
                                {
                                  GroupAppointmentId: this.props.GroupAppointmentId,
                                  ClientId: record.ClientId,
                                  Status:
                                    this.state.attendeeStatus[record.ClientId] ||
                                    (this.props.initialValues && this.props.initialValues.g_status),
                                  IsAppointment: true,
                                },
                              ],
                            }),
                          }),
                        );
                        var invitationStatusOfClients2 = this.state.invitationStatusOfClients.map(
                          item => {
                            if (item.ClientId === record.ClientId) {
                              return { ...item, IsAppointment: true };
                            } else return item;
                          },
                        );
                        this.setState(
                          { invitationStatusOfClients: invitationStatusOfClients2 },
                          () => {
                            this.handleAttendeeStatusChange(
                              record.ClientId,
                              this.props.g_statusValue,
                              index,
                            );
                          },
                        );
                      }}
                    >
                      <img
                        src={'images/circle-plus.svg'}
                        className="img-circle"
                        height="40"
                        width="40"
                        style={{ borderRadius: '50%' }}
                      />
                    </span>
                  )}
                </div>
              );
            } else {
              return null;
            }
          },
        },
      ],
    };
  }

  handleAttendeeStatusChange = (ClientId, attendeeStatusId, index) => {
    var invitationStatusOfClients2 = this.state.invitationStatusOfClients.map(item => {
      if (item.ClientId === ClientId) {
        return { ...item, Status: attendeeStatusId.toString() };
      } else return item;
    });

    this.setState(
      {
        invitationStatusOfClients: invitationStatusOfClients2,
        StatusChangeMsg: 'Status changed successfully',
      },
      () => {
        setTimeout(() => {
          this.setState({
            StatusChangeMsg: '',
          });
        }, 2000);
      },
    );
  };
  showDrawer = record => {
    this.setState({
      groupDrawerVisible: true,
      currentRowData: record,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      groupDrawerVisible: false,
    });
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  handelGui = () => {
    this.props.GetStudentGui(this.state.selectedStudent);
  };
  componentWillUnmount() {
    this.setState({
      isDisable: true,
    });
  }
  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes: 'Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    var result = ProcessCombos('RCM_ServiceCode,RCM_PlaceOfService,RCM_Program');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.setState({
      isDisable: true,
    });
    this.props.dispatch(practitionerCombos({}));
    // this.props.dispatch(appSubscribersUserSearch({}));
    this.props.dispatch(recurringCount({}));
    // this.input.focus();
  }
  componentWillReceiveProps({
    searchedStudent,
    isSuccess,
    errorMessage,
    g_members_list,
    medicateCombosObj,
  }) {
    if (
      medicateCombosObj &&
      JSON.stringify(this.props.medicateCombosObj) != JSON.stringify(medicateCombosObj)
    ) {
      this.setState({ medicateCombosObjState: medicateCombosObj });
    }
    if (
      g_members_list &&
      g_members_list.length > 0 &&
      JSON.stringify(this.props.g_members_list) != JSON.stringify(g_members_list)
    ) {
      let ClientIdArray = g_members_list.map(i => {
        return i.ClientId;
      });
      let ClientStatusArray = g_members_list.map(i => {
        return i.Status;
      });
      var result = {};
      ClientIdArray.forEach((key, i) => {
        result = {
          ...result,
          [key]: ClientStatusArray[i] ? parseInt(ClientStatusArray[i]) : ClientStatusArray[i],
        };
      });
      this.setState({
        invitationStatusOfClients: g_members_list,
        attendeeStatus: result,
      });
    }
    if (!g_members_list) {
      this.setState({
        invitationStatusOfClients: g_members_list || [],
      });
    }
    if (searchedStudent && searchedStudent != this.props.searchedStudent) {
      this.setState({ searchedStudent });
    }

    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState(
        {
          inviteMsg: 'Mobile app invitation sent successfully.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              inviteMsg: '',
            });
          }, 5000);
        },
      );

      this.props.dispatch(reset('inviteForm'));
    }
    if (errorMessage && errorMessage != this.props.errorMessage) {
      this.setState(
        {
          errorMessage: errorMessage,
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorMessage: '',
            });
          }, 5000);
        },
      );
      this.props.dispatch(reset('inviteForm'));
    }
  }

  getSerachedStudent = () => {
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };
  createEventId = () => {
    return String(eventGuid++);
  };
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onhandleSubmit = val => {
    let title = 'Please enter a new title for your event';
    let dateToBook = moment(val.appointmentDate).format('YYYY-MM-DD');
    let data;
    if (val.recurringAppointment) {
      data = {
        GroupAppointment: [
          {
            GroupAppointmentId: this.props.GroupAppointmentId,
            GroupId: val.g_group,
            Title: `${val.g_title}`,
            AppointmentDate: moment(val.g_appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime: mapTime(val.g_startTime),
            // val.g_startTime && typeof val.g_startTime == 'string'
            //   ? val.g_startTime
            //   : moment(val.g_startTime._d).format('HH:mm:ss'),
            AppointmentEndTime: mapTime(val.g_endTime),
            // val.g_endTime && typeof val.g_endTime == 'string'
            //   ? val.g_endTime
            //   : moment(val.g_endTime._d).format('HH:mm:ss'),
            // AppointmentEndTime: (val.endTime && moment(val.endTime._d).format('HH:mm:ss')) || '',
            PractitionerId: val.g_providerName,
            DurationInMin: val.g_duration,
            OfficeName: val.g_officeName,
            ServiceCode: val.g_serviceCode,
            ReasonForVisit: val.g_reasonForVisit,
            Status: val.g_status,
            PrimaryPhone: val.g_mobileNumber,
            ProgramId: val.g_program,
            PlaceOfServiceId: val.g_placeOfService,
            ClientGroupAppointment: this.state.invitationStatusOfClients,
            IsRecurring: val.recurringAppointment,
            IsEditAll: val.recurringAppointment,
            GroupAppointmentRecurring: [
              {
                GroupAppointmentRecurringId: val.GroupAppointmentRecurringId,
                RecurringType: val.appointmentType,
                // WeekDays:
                //   val.recurringAppointment && !typeof val.dayOfWeek == 'string'
                //     ? val.dayOfWeek.join()
                //     : val.dayOfWeek,
                WeekDays:
                  val.recurringAppointment &&
                  (typeof val.dayOfWeek == 'string' ? val.dayOfWeek : val.dayOfWeek.join()),
                // WeekDays: val.dayOfWeek && val.dayOfWeek.join(),
                MonthlyRecurring: val.recurringOccurance || null,
                RecurringCount: val.recurringCount,
              },
            ],
          },
        ],
      };
    } else {
      data = {
        GroupAppointment: [
          {
            GroupAppointmentId: this.props.GroupAppointmentId,
            GroupId: val.g_group,
            Title: `${val.g_title}`,
            AppointmentDate: moment(val.g_appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime: mapTime(val.g_startTime),
            // val.g_startTime && typeof val.g_startTime == 'string'
            //   ? val.g_startTime
            //   : moment(val.g_startTime._d).format('HH:mm:ss'),
            AppointmentEndTime: mapTime(val.g_endTime),
            // val.g_endTime && typeof val.g_endTime == 'string'
            //   ? val.g_endTime
            //   : moment(val.g_endTime._d).format('HH:mm:ss'),
            // AppointmentEndTime: (val.endTime && moment(val.endTime._d).format('HH:mm:ss')) || '',
            PractitionerId: val.g_providerName,
            DurationInMin: val.g_duration,
            OfficeName: val.g_officeName,
            ServiceCode: val.g_serviceCode,
            ReasonForVisit: val.g_reasonForVisit,
            Status: val.g_status,
            PrimaryPhone: val.g_mobileNumber,
            ProgramId: val.g_program,
            PlaceOfServiceId: val.g_placeOfService,
            ClientGroupAppointment: this.state.invitationStatusOfClients,
            IsRecurring: val.recurringAppointment,
            IsEditAll: val.recurringAppointment,
          },
        ],
      };
    }

    var appointmentData = {
      json: JSON.stringify(data),
    };
    this.setState({
      isDisable: true,
    });
    this.props.dispatch(upsertGroupAppointment(appointmentData));
    // this.props.onCancel();
  };

  toggleView = () => {
    this.setState(prevState => ({
      isClientView: !prevState.isClientView,
      inviteMsg: '',
      errorMessage: '',
    }));
  };
  handleMasterStatusChange = newStatus => {
    this.props.dispatch(
      upsertClientGroupAppointment({
        Json: JSON.stringify({
          ClientGroupAppointment: [
            {
              GroupAppointmentId: this.props.GroupAppointmentId,
              // ClientId: record.ClientId,
              Status: newStatus,
              // IsAppointment:record.IsAppointment,
            },
          ],
        }),
      }),
    );
    let changedNewStatusArray = this.state.invitationStatusOfClients.map((item, index) => {
      if (parseInt(item.Status) === this.props.g_statusValue) {
        return {
          ...item,
          Status: newStatus.toString(),
        };
      } else {
        return {
          ...item,
        };
      }
    });

    this.setState(
      {
        invitationStatusOfClients: changedNewStatusArray,
        StatusChangeMsg: 'Status changed successfully',
      },
      () => {
        setTimeout(() => {
          this.setState({
            StatusChangeMsg: '',
          });
        }, 2000);
      },
    );
  };

  render() {
    const { groupDrawerVisible, currentRowData, isModelOpen } = this.state;
    const { isReadOnlyUser } = this.props;

    let newDate = new Date();
    const {
      onOk,
      onCancel,
      isFetching,
      isFetchingVoucher,
      handleSubmit,
      MobileNumberValue,
      EmailValue,
      referralTypeValue,
      dateClicked,
      clickInfo,
      GroupAppointmentId,
      dispatch,
      medicateCombos,
      practitioner,
      practitionerOffice,
      isFetchingGroupAppointmentData,
      ProgramType,
      recurringAppointmentValue,
      appointmentTypeValue,
      g_appointmentDateValue,
      recurringCount,
      isMedicalComboFetching,
      ServiceCodeList,
      PlaceOfService,
      g_groupValue,
      change,
      medicateCombosObj,
      initialValues,
      enableAddGroupMember,
    } = this.props;
    const { invitationStatusOfClients } = this.state;
    const { Status, ServiceCode } = medicateCombosObj;
    // const toogleChange = bool => {
    //   if (bool) {
    //     change(`appointmentType`, 'Weekly');
    //     change(`recurringCount`, '2');
    //   }
    // };
    return (
      <div className="">
        <div className="">
          <div style={{ textAlign: 'center' }}>
            <h6 style={{ fontSize: '16px', color: 'rgb(18, 196, 117)' }}>{this.state.inviteMsg}</h6>
            <h6 style={{ fontSize: '16px', color: 'rgb(255, 0, 0)' }}>{this.state.errorMessage}</h6>
          </div>
          <form onSubmit={handleSubmit(this.onhandleSubmit)}>
            <Loader2 loading={isFetchingGroupAppointmentData || isMedicalComboFetching} />
            <div className="form-group mb-2 custom-radio px-3"></div>

            <div className="newclientview col-md-12 enrollment-form">
              <div className="row">
                <div className="col-12 mb-4 d-flex justify-content-between">
                  {' '}
                  <h4 className="modal-title text-left">
                    <span>Group Appointment</span>
                  </h4>
                  {/* {this.props.clickInfo.el.className.split(' ')[
                    this.props.clickInfo.el.className.split(' ').length - 1
                  ] == 'fc-event-future' && ( 
                  <div>
                    <img
                      src="images/shape.svg"
                      style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ isDisable: false });
                      }}
                    />
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <img
                          src="images/deleteEvent.svg"
                          style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                          data-toggle="modal"
                          data-target="#deactivate-staff-model"
                          onClick={onClick}
                        />
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div className="modal-content border-0">
                          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 className="modal-title col-12 mb-3">
                              Are you sure you want to deactivate this group appointment?
                            </h4>
                          </div>
                          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              className="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                onOk();
                                let data = {
                                  GroupAppointment: [
                                    {
                                      GroupAppointmentId: parseInt(clickedEventId),
                                    },
                                  ],
                                };
                                let clickedEventDetails = {
                                  json: JSON.stringify(data),
                                };
                                dispatch(deactivateGroupAppointment(clickedEventDetails));
                                this.props.onCancel();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  )}  */}
                  {/* {this.props.clickInfo.el.className.split(' ')[
                    this.props.clickInfo.el.className.split(' ').length - 1
                  ] == 'fc-event-today' && ( */}
                  {!this.props.initialValues.isParentAppointmnet && (
                    <>
                      {!isReadOnlyUser && (
                        <div className="d-flex align-items-center">
                          {(this.props.ShowNotes ||
                            this.props.g_statusValue == 19707 ||
                            this.props.g_statusValue == 19709) && (
                            <div
                              onClick={() => {
                                this.showDrawer(this.props.GroupAppointmentId);
                              }}
                              style={{
                                color: ' rgb(74, 176, 254)',
                                cursor: 'pointer',
                                background: 'transparent',
                                border: 'none',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src="images/appNoteIcon.svg"
                                style={{
                                  width: '35px',
                                  padding: '10px 0px 10px 10px',
                                  cursor: 'pointer',
                                }}
                              />
                              Notes
                            </div>
                          )}
                          <Tooltip placement="top" title={'Edit'}>
                            <img
                              src="images/shape.svg"
                              style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                              onClick={() => {
                                this.setState({ isDisable: false });
                              }}
                            />
                          </Tooltip>
                          <AntdModal
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title={'Delete'}>
                                <img
                                  src="images/deleteEvent.svg"
                                  style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                                  data-toggle="modal"
                                  data-target="#deactivate-staff-model"
                                  onClick={onClick}
                                />
                              </Tooltip>
                            )}
                            ModalContent={({ onOk, onCancel }) => (
                              <div className="modal-content border-0">
                                <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                  <h4 className="modal-title col-12 mb-3">
                                    Are you sure you want to deactivate this group appointment?
                                  </h4>
                                </div>
                                <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                  <button
                                    className="btn black-btn"
                                    onClick={() => {
                                      onCancel();
                                    }}
                                  >
                                    No
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      onOk();
                                      dispatch(
                                        deactivateGroupAppointment({
                                          json: JSON.stringify({
                                            GroupAppointment: [
                                              {
                                                GroupAppointmentId: parseInt(GroupAppointmentId),
                                              },
                                            ],
                                          }),
                                        }),
                                      );
                                      this.props.onCancel();
                                    }}
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {/* )} */}
                </div>

                <div className="col-6">
                  {!this.props.initialValues.isParentAppointmnet ? (
                    <div
                      className="form-group mb-2 group-name"
                      style={{
                        cursor: 'pointer',
                        // backgroundColor: '#f2f2f2',
                      }}
                      onClick={val => {
                        this.props.history.push(
                          `/cw/group-management/group-details?tab=group-information&gId=${g_groupValue}`,
                        );
                      }}
                    >
                      <Field
                        name="g_group"
                        type="text"
                        component={ReduxSelect}
                        fieldName={'group name'}
                        disabled={true}
                        placeholder={'Group Name'}
                        options={this.props.groupComboData}
                        onChange={val => {
                          this.props.dispatch(
                            getClientGroup({
                              json: JSON.stringify({
                                ClientGroup: [
                                  {
                                    GroupId: val,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <Field
                      name="g_group"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'group name'}
                      disabled={true}
                      placeholder={'Group Name'}
                      options={this.props.groupComboData}
                      onChange={val => {
                        this.props.dispatch(
                          getClientGroup({
                            json: JSON.stringify({
                              ClientGroup: [
                                {
                                  GroupId: val,
                                },
                              ],
                            }),
                          }),
                        );
                      }}
                    />
                  )}
                </div>

                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_title"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Group Event Name'}
                      validate={Validator.required}
                      disabled={this.state.isDisable}
                    />
                    <label className="placeholder-label">Group Event Name</label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_appointmentDate"
                      type="text"
                      component={ReduxDateInput}
                      errorMessage="Published Date Required"
                      label={'Appointment Date*'}
                      disabled={this.state.isDisable}
                      validate={Validator.required}
                      minDate={new Date()}
                    />
                    <i className="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_program"
                      type="text"
                      component={ReduxSelect}
                      errorMessage="Appointment Date Required"
                      placeholder={'Program*'}
                      validate={Validator.required}
                      disabled={this.state.isDisable}
                      options={ProgramType}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_startTime"
                      type="text"
                      component={ReduxTimePicker}
                      fieldName={'Start Time'}
                      validate={Validator.required}
                      label={'Start Time*'}
                      disabled={this.state.isDisable}
                      minuteStep={5}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_endTime"
                      type="text"
                      component={ReduxTimePicker}
                      fieldName={'End Time'}
                      validate={[Validator.required, Validator.endTime]}
                      label={'End Time*'}
                      disabled={this.state.isDisable}
                      minuteStep={5}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_providerName"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Provider Name'}
                      placeholder={'Provider Name*'}
                      validate={Validator.required}
                      options={practitioner}
                      disabled={this.state.isDisable}
                      // onChange={val => {
                      //   this.props.dispatch(
                      //     practitionerCombosOffice({
                      //       json: JSON.stringify({
                      //         PractitionerOffice: [
                      //           {
                      //             PractitionerId: val,
                      //           },
                      //         ],
                      //       }),
                      //     }),
                      //   );
                      // }}
                    />
                    {/* <label className="placeholder-label"></label> */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_officeName"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Office Location'}
                      placeholder={'Office Location*'}
                      validate={Validator.required}
                      options={practitionerOffice}
                      disabled={this.state.isDisable}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_serviceCode"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Service Code'}
                      placeholder={'Service Code*'}
                      options={ServiceCodeList}
                      disabled={this.state.isDisable}
                      validate={Validator.required}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_status"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Status'}
                      placeholder={'Status'}
                      options={Status}
                      disabled={this.state.isDisable}
                      onChange={newStatus => {
                        // Commenting based on ticket number 3993;
                        this.handleMasterStatusChange(newStatus);
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="g_placeOfService"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Place of Service'}
                      placeholder={'Place of Service*'}
                      validate={Validator.required}
                      options={PlaceOfService}
                      disabled={this.state.isDisable}
                    />
                  </div>
                </div>
                <div className="col-6 mt-3">
                  <div className="form-group mb-2">
                    <Field
                      name="g_reasonForVisit"
                      type="textarea"
                      component={ReduxTextarea}
                      fieldName={'Reason For Visit'}
                      disabled={this.state.isDisable}
                    />
                    <label className="placeholder-label" style={{ background: '#fefefe' }}>
                      Reason for Visit
                    </label>
                  </div>
                </div>
                {this.props.warningContent && this.props.warningContent.length > 0 && (
                  <div className="col-12">
                    <div className="">
                      <div>
                        <label
                          className="custom-control-label"
                          for="Recurring-Appointment"
                          style={{ fontSize: '18px' }}
                        >
                          Notifications
                        </label>
                      </div>
                      <div
                        className="col-md-12 mt-3 pt-3 pb-3"
                        style={
                          this.props.initialValues.isParentAppointmnet
                            ? { background: '#F8F8F8', pointerEvents: 'none' }
                            : { background: '#F8F8F8' }
                        }
                      >
                        {this.props.warningContent &&
                          this.props.warningContent.map((item, index) => {
                            return renderHTML(item.WarningMessage);
                          })}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12 mt-3">
                  <div className="form-group mb-2 d-flex align-items-center">
                    <label
                      className="custom-control-label"
                      for="Recurring-Appointment"
                      style={{ fontSize: '18px' }}
                    >
                      Edit Multiple Appointments {'   '}
                      <Field
                        name={`recurringAppointment`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        // onChange={bool => toogleChange(bool)}
                        disabled={this.state.isDisable}
                      />{' '}
                      {!this.state.isDisable &&
                        this.props.initialValues.g_group != null &&
                        enableAddGroupMember && (
                          <span
                            onClick={() => {
                              this.setState({ open: true });
                            }}
                            style={{ color: '#3996f4', fontSize: '12px', cursor: 'pointer' }}
                          >
                            Modify Members
                          </span>
                        )}
                    </label>
                    {recurringAppointmentValue && (
                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title={'Delete All'}>
                            {this.props.initialValues.showDeleteAll && (
                              <img
                                src="images/icons/delete-all.svg"
                                style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                                data-toggle="modal"
                                data-target="#deactivate-staff-model"
                                onClick={onClick}
                              />
                            )}
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div className="modal-content border-0">
                            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 className="modal-title col-12 mb-3">
                                Are you sure you want to deactivate all future group appointments?
                              </h4>
                            </div>
                            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                className="btn black-btn"
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                No
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  onOk();
                                  dispatch(
                                    deactivateGroupAppointment({
                                      json: JSON.stringify({
                                        GroupAppointment: [
                                          {
                                            GroupAppointmentId: parseInt(GroupAppointmentId),
                                            IsDeleteAll: true,
                                          },
                                        ],
                                      }),
                                    }),
                                  );
                                  this.props.onCancel();
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
                {/* {this.props.initialValues.isEditAll && (
                  <div className="col-12">
                    {' '}
                    <p style={{ color: '#b5b4b4' }}>
                      <i className="fa fa-info-circle" aria-hidden="true"></i> This appointment is part
                      of recurring appointments, please click Yes on Edit Multiple Appointments to
                      edit all future appointments of this series.
                    </p>
                  </div>
                )} */}
                {recurringAppointmentValue && (
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-9">
                        <div className="form-group mb-2 custom-radio px-3">
                          <label className="mr-4">
                            Weekly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="Weekly"
                              disabled={this.state.isDisable}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: 'solid 1px #bcbcbc',
                                marginRight: '5px',
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="mr-4">
                            Bi-weekly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="BiWeekly"
                              disabled={this.state.isDisable}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: 'solid 1px #bcbcbc',
                                marginRight: '5px',
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label>
                            Monthly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="MonthlyOnce"
                              disabled={this.state.isDisable}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: 'solid 1px #bcbcbc',
                                marginRight: '5px',
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {appointmentTypeValue === 'Weekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block mt-2">
                            <span className="recurring-block-label">Day(s) of the week</span>
                            <p className="recurring-block-value"></p>
                          </div>
                          <div className="recurring-block">
                            <div className="week-days">
                              <div className="col-12">
                                <div
                                  style={{
                                    flexFlow: 'wrap',
                                    display: 'flex',
                                  }}
                                >
                                  <Field
                                    name="dayOfWeek"
                                    component={CheckboxGroup}
                                    col={6}
                                    widthClassName={'w-75'}
                                    disabled={this.state.isDisable}
                                    blockDays={JSON.stringify(
                                      parseInt(moment(g_appointmentDateValue).format('d')) + 1,
                                    )}
                                    // justify={'start'}
                                    options={weekDays}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {appointmentTypeValue === 'BiWeekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day(s) of the week</span>
                            <p className="recurring-block-value">
                              {moment(g_appointmentDateValue).format('dddd')}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <div className="bi-week-note">
                              <span className="note-text">
                                <strong>Note</strong>: Bi-weekly appointments are automatically
                                scheduled to reoccur every alternate week.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {appointmentTypeValue === 'MonthlyOnce' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day of the month</span>
                            <p className="recurring-block-value" style={{ marginTop: '1em' }}>
                              {moment(g_appointmentDateValue).format('dddd')}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <span className="recurring-block-label"> Occurance in the month</span>
                            {/* <p className="recurring-block-value">{'Second'}</p> */}
                            {/* Week1 Week2 WeekLast */}
                            <div className="form-group mb-2">
                              <Field
                                name="recurringOccurance"
                                type="text"
                                component={ReduxSelect}
                                fieldName={'Occurance in the month'}
                                // placeholder={'Occurance in the month'}
                                disabled={this.state.isDisable}
                                options={[
                                  {
                                    Value: 'Week1',
                                    Text: 'First Week',
                                  },
                                  {
                                    Value: 'Week2',
                                    Text: 'Second Week',
                                  },
                                  {
                                    Value: 'Week3',
                                    Text: 'Third Week',
                                  },
                                  {
                                    Value: 'Week4',
                                    Text: 'Fourth Week',
                                  },
                                  {
                                    Value: 'WeekLast',
                                    Text: 'Last Week',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-4 enrollment-form">
                        <div className="form-group mb-2">
                          <Field
                            name="recurringCount"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Count of Appointment(s)'}
                            placeholder={'Count of Appointment(s)'}
                            disabled={this.state.isDisable}
                            options={recurringCount}
                          />
                        </div>
                      </div>
                      {/* {!this.state.isDisable && (
                        <div className="col-7 mt-3">
                          <div className="form-group">
                            <label
                              className="custom-control-label"
                              for="Recurring-Appointment"
                              style={{ fontSize: '18px' }}
                            >
                              Edit all Appointments {'   '}
                              <Field
                                name={`isEditAll`}
                                type="text"
                                className="custom-control-input not-empty"
                                component={ReduxToggle}
                                disabled={this.state.isDisable}
                              />
                            </label>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                )}
                <div className="col-8 mt-3" style={{ color: '#52c41a' }}>
                  {this.state.StatusChangeMsg}
                </div>
                {true && (
                  <div className="col-12 col-md-12 mt-4">
                    <div className="appointment-group-member">
                      <span className="group-member-label">Group Members</span>
                      <div className="icon-container">
                        <div className="include">
                          <img src="images/icons/add-member.svg" className="mr-1" />
                          <span>
                            {invitationStatusOfClients &&
                              invitationStatusOfClients.filter(item => {
                                if (item.IsAppointment) return item;
                              }).length}
                          </span>
                        </div>{' '}
                        |
                        <div className="exclude">
                          <img src="images/icons/remove-member.svg" className="mr-1" />
                          <span>
                            {invitationStatusOfClients &&
                              invitationStatusOfClients.filter(item => {
                                if (item.IsAppointment == false) return item;
                              }).length}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                    // style={{ height: '176px', overflowY: 'scroll' }}
                    >
                      {this.state.medicateCombosObjState &&
                        this.state.medicateCombosObjState.Status &&
                        this.state.medicateCombosObjState.Status.length > 0 && (
                          <PersistTableSort
                            name="grp-appointent"
                            columns={this.state.columns}
                            dataSource={invitationStatusOfClients || []}
                            size={'middle'}
                            pagination={false}
                            scroll={{ x: 170, y: 400 }}
                          />
                        )}
                    </div>
                  </div>
                )}

                <div className="col-12 col-md-12 mt-1 d-none">
                  <h6>Group Members</h6>
                  <div
                    style={{
                      // border: '1px solid grey',
                      height: '250px',
                      overflowY: 'scroll',
                      position: 'relative',
                      border: '5px solid #f2f8f9',
                      borderRadius: '4px',
                      padding: '10px',
                    }}
                  >
                    {/* <Loader2 loading={this.props.isFetchingGroupClient} /> */}

                    {invitationStatusOfClients.length > 0 &&
                      invitationStatusOfClients.map((item, index) => {
                        return (
                          <div
                            className="align-items-center"
                            style={{
                              // borderTop: '1px solid grey',
                              borderBottom: '1px solid grey',
                              padding: '10px 5px',
                              display: 'flex',
                              margin: '5px',
                              justifyContent: 'space-between',
                              boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                              transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                            }}
                          >
                            <div className="d-flex" style={{ flexDirection: 'column' }}>
                              <div className="student-list-pic d-flex align-items-center pb-2">
                                <img
                                  src={
                                    item.ProfileImgUrl
                                      ? item.ProfileImgUrl
                                      : 'images/announcements-img2.jpg'
                                  }
                                  className="img-circle"
                                  height="40"
                                  width="40"
                                  style={{ borderRadius: '50%', objectFit: 'cover' }}
                                  alt={item.DisplayName}
                                />
                                &nbsp;
                                <span
                                  style={{
                                    border: '1px solid #faad14',
                                    borderRadius: '20px',
                                    padding: '0 12px',
                                  }}
                                >
                                  {item.ClientId}
                                </span>
                                &nbsp;
                              </div>
                              <div>
                                {' '}
                                <span>{item.DisplayName}</span>
                              </div>
                            </div>
                            {/* {item.IsAppointment &&
                            !this.state.patientRemoved.includes(item.ClientId) ? ( */}
                            {item.IsAppointment ? (
                              <AntdModal
                                ModalButton={({ onClick }) => (
                                  <span
                                    title="Click to Exclude"
                                    style={{ color: '#FF3D57', cursor: 'pointer' }}
                                    onClick={onClick}
                                  >
                                    {!this.state.isDisable && (
                                      <i className="fas fa-user-minus fa-lg"></i>
                                    )}
                                  </span>
                                )}
                                ModalContent={({ onOk, onCancel }) => (
                                  <div className="modal-content border-0">
                                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                    <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                      <h4 className="modal-title col-12 mb-3">
                                        {`Are you sure you want to remove member from this appointment?`}
                                      </h4>
                                    </div>
                                    <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                      <button
                                        className="btn black-btn"
                                        onClick={() => {
                                          onCancel();
                                        }}
                                      >
                                        No
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          // let invitationStatusOfClients = this.state
                                          //   .invitationStatusOfClients;
                                          // invitationStatusOfClients[
                                          //   item.ClientId
                                          // ].IsAppointment = false;
                                          // this.setState({ invitationStatusOfClients });

                                          let invitationStatusOfClients3 = this.state.invitationStatusOfClients.map(
                                            objects => {
                                              if (objects.ClientId === item.ClientId) {
                                                return { ...item, IsAppointment: false };
                                              } else return item;
                                            },
                                          );
                                          this.setState({
                                            invitationStatusOfClients: invitationStatusOfClients3,
                                          });

                                          onOk();
                                        }}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            ) : (
                              <span
                                title="Click to Include"
                                style={{ color: '#09B66D', cursor: 'pointer' }}
                                onClick={() => {
                                  // let invitationStatusOfClients = this.state
                                  //   .invitationStatusOfClients;
                                  // invitationStatusOfClients[item.ClientId].IsAppointment = true;
                                  // this.setState({ invitationStatusOfClients });

                                  let invitationStatusOfClients4 = this.state.invitationStatusOfClients.map(
                                    objects => {
                                      if (objects.ClientId === item.ClientId) {
                                        return { ...item, IsAppointment: true };
                                      } else return item;
                                    },
                                  );

                                  this.setState({
                                    invitationStatusOfClients: invitationStatusOfClients4,
                                  });
                                }}
                              >
                                {' '}
                                {!this.state.isDisable && (
                                  <i className="fas fa-user-plus fa-lg"></i>
                                )}
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 pt-5">
                  <div className="modal-footer  border-top-0 pt-2">
                    {!this.state.isDisable && (
                      <input
                        type="submit"
                        className="btn black-btn font-weight-bold mr-2"
                        value=" Update Visit"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>

          {groupDrawerVisible && (
            <DrawerContent
              groupDrawerVisible={groupDrawerVisible}
              onCloseDrawer={this.onCloseDrawer}
              // currentRowData={currentRowData}
              toggle={this.toggle}
              GroupAppointmentId={this.props.GroupAppointmentId}
              clickedGroupId={initialValues.g_group}
            />
          )}

          {isModelOpen && (
            <CustomModal
              isOpen={this.state.isModelOpen}
              header={this.state.header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}

          <Drawer
            className="custom-drawer"
            title="Modify Members"
            placement="right"
            visible={this.state.open}
            width={1300}
            bodyStyle={{ paddingBottom: 80 }}
            closable={true}
            destroyOnClose={true}
            onClose={() => {
              this.setState({ open: false }, () => {
                this.props.dispatch(
                  upsertGroupAppointmentGroupAllInfoJson({
                    json: JSON.stringify({
                      GroupAppointment: [
                        {
                          GroupAppointmentId: this.props.GroupAppointmentId,
                        },
                      ],
                    }),
                  }),
                );
                // this.props.dispatch(
                //   getClientGroup({
                //     json: JSON.stringify({
                //       ClientGroup: [
                //         {
                //           GroupId: this.props.initialValues.g_group,
                //         },
                //       ],
                //     }),
                //   }),
                // );
              });
            }}
          >
            <AddNewMember groupId={this.props.initialValues.g_group} />
          </Drawer>
        </div>
      </div>
    );
  }
}
const mapStateToPropsGroupEventForm = state => {
  const {
    app_subscribers_user_search,
    send_voucher_code,
    practitioner_combos,
    practitioner_combos_office,
    patient_combo_group,
    upsert_group_appointment_group_all_info_json,
    combos,
    recurring_count,
    get_combo_facility,
    medicate_combos,
  } = state;
  const { isFetching: isFetchingVoucher } = send_voucher_code;
  const { data, isFetching } = app_subscribers_user_search;
  const { data: practitionerData } = practitioner_combos;
  const { data: practitionerOfficeData } = practitioner_combos_office;
  const { data: patientComboGroupData } = patient_combo_group;
  const { data: recurringCountData } = recurring_count;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: medicateCombos, isFetching: isMedicalComboFetching } = medicate_combos;

  const {
    data: groupAppointmentData,
    isFetching: isFetchingGroupAppointmentData,
  } = upsert_group_appointment_group_all_info_json;

  let medicateCombosObj = {
    Status: [],
    ServiceCode: [],
  };
  let ServiceCodeList = [];
  let PlaceOfService = [];
  let searchedStudent = [];
  let searchStr;
  let isSuccess = false;
  let errorMessage;
  let Practitioner = [];
  let PractitionerOffice = [];
  let groupComboData = [];
  let ProgramType = [];
  let recurringCount = [];

  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList;
  }
  if (patientComboGroupData) {
    groupComboData = patientComboGroupData.Group;
  }
  if (practitionerOfficeData) {
    // PractitionerOffice = practitionerOfficeData.PractitionerOfficeList || [];
  }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    if (app_subscribers_user_search.data && app_subscribers_user_search.data.data) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else {
      searchedStudent = Object.values(data) || [];
    }
  }
  if (send_voucher_code.data && send_voucher_code.data.success) {
    isSuccess = send_voucher_code.data.success;
  } else if (send_voucher_code && send_voucher_code.error) {
    errorMessage = send_voucher_code.error.Message;
  }
  if (combos.data && combos.data['RCM_Program']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'] || [];
    ProgramType = combos.data['RCM_Program'] || [];
    PlaceOfService = combos.data['RCM_PlaceOfService'] || [];
  }
  if (recurringCountData && recurringCountData.Recurring) {
    recurringCount = recurringCountData.Recurring || [];
  }
  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
      Status: medicateCombos.Status,
    };
  }
  const MobileNumberValue = formValueSelector('inviteForm')(state, 'MobileNumber');
  const EmailValue = formValueSelector('inviteForm')(state, 'Email');
  const referralTypeValue = formValueSelector('inviteForm')(state, 'referralType');
  const g_groupValue = formValueSelector('viewEventForm')(state, 'g_group');
  const recurringAppointmentValue = formValueSelector('viewEventForm')(
    state,
    'recurringAppointment',
  );
  const appointmentTypeValue = formValueSelector('viewEventForm')(state, 'appointmentType');
  const g_appointmentDateValue = formValueSelector('viewEventForm')(state, 'g_appointmentDate');
  const g_statusValue = formValueSelector('viewEventForm')(state, 'g_status');
  return {
    searchedStudent,
    isFetching: isFetching || isFetchingVoucher,
    // isFetchingVoucher,
    searchStr,
    isSuccess,
    errorMessage,
    MobileNumberValue,
    EmailValue,
    referralTypeValue,
    practitioner: Practitioner,
    practitionerOffice: PractitionerOffice,
    groupComboData,
    isFetchingGroupAppointmentData,
    ProgramType,
    recurringAppointmentValue,
    appointmentTypeValue,
    g_appointmentDateValue,
    recurringCount,
    medicateCombosObj: medicateCombosObj,
    isMedicalComboFetching,
    ServiceCodeList,
    PlaceOfService,
    g_groupValue,
    g_statusValue,
  };
};
const ReduxGroupEventForm = reduxForm({
  form: 'viewEventForm',
  enableReinitialize: true,
})(connect(mapStateToPropsGroupEventForm)(withRouter(GroupEventForm)));

const mapStateToProps = ({
  combos,
  get_group,
  upsert_group_appointment,
  upsert_group_appointment_group_all_info_json,
}) => {
  const { data: getGroupData } = get_group;
  const {
    data: getGroupAppointmentData,
    isFetching: isFetchingGetGroupAppointment,
    error: errorGetGroupAppointment,
  } = upsert_group_appointment_group_all_info_json;

  let groupDataInfo;
  let isGroupAppointmentBooked = false;
  let getGroupAppointment = {};
  let messageContentGroupAppointmentSuccess;
  let warningContent;

  if (getGroupAppointmentData && getGroupAppointmentData.GroupAppointment) {
    getGroupAppointment = getGroupAppointmentData.GroupAppointment[0];
    warningContent = getGroupAppointmentData.Warning;
  }
  if (upsert_group_appointment.data && upsert_group_appointment.data.success) {
    isGroupAppointmentBooked = upsert_group_appointment.data.success;
    messageContentGroupAppointmentSuccess = JSON.parse(upsert_group_appointment.data.data)[0]
      .Warning;
  }
  if (getGroupData && getGroupData.Group) {
    groupDataInfo = getGroupData.Group[0] || {};
  }
  return {
    groupDataInfo,
    getGroupAppointment: getGroupAppointment || {},
    isGroupAppointmentBooked,
    messageContentGroupAppointmentSuccess,
    warningContent,
  };
};
export default connect(mapStateToProps)(GroupAppointmentModal);
