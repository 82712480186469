import React, { Component } from 'react';
import { Checkbox } from 'antd';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

const COLOR_HEADER = {
  0: '#61B546',
  1: '#9FC63B',
  2: '#F7CD00',
  3: '#F99D1C',
  4: '#D31820',
};
const COLOR_BODY = {
  0: '#DDECD3',
  1: '#EBF1D7',
  2: '#FEF4DA',
  3: '#FFE8CB',
  4: '#F5CDBC',
};

class SingleSelectOneLineColored extends Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      result: Response,
    };
  }

  render() {
    const {
      survey,
      onClick,
      setValue,
      scope,
      index,
      total,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      ClassName,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option2Value,
      Option2Text,
      Option3Value,
      Option3Text,
      Option4Value,
      Option4Text,
      Option5Value,
      Option5Text,
      Option6Value,
      Option6Text,
      Option7Value,
      Option7Text,
      Option8Value,
      Option8Text,
      Option9Value,
      Option9Text,
      Option10Value,
      Option10Text,
      Option11Value,
      Option11Text,
      Response,
      SurveyQuestionId,
      Option1Hint,
      Option2Hint,
      Option3Hint,
      Option4Hint,
      Option5Hint,
      Option6Hint,
      Option7Hint,
      Option8Hint,
      Option9Hint,
      Option10Hint,
      Option11Hint,
    } = survey;
    const textArray = [
        Option1Text,
        Option2Text,
        Option3Text,
        Option4Text,
        Option5Text,
        Option6Text,
        Option7Text,
        Option8Text,
        Option9Text,
        Option10Text,
        Option11Text,
      ],
      valueArray = [
        Option1Value,
        Option2Value,
        Option3Value,
        Option4Value,
        Option5Value,
        Option6Value,
        Option7Value,
        Option8Value,
        Option9Value,
        Option10Value,
        Option11Value,
      ],
      hintArray = [
        Option1Hint,
        Option2Hint,
        Option3Hint,
        Option4Hint,
        Option5Hint,
        Option6Hint,
        Option7Hint,
        Option8Hint,
        Option9Hint,
        Option10Hint,
        Option11Hint,
      ];
    // let ActualHintArray = hintArray.filter(item => item != null);

    return (
      <div className="type-border col-12">
        <div>
          <SurveyQuestion survey={survey} ClassName={'mb-3'} />
          <ul className={`answer-options d-flex ${ClassName}`}>
            {textArray.map((item, qIndex) => {
              let val = valueArray[qIndex];
              let hint = hintArray[qIndex];
              // let cssIndex = ActualHintArray.length == 5 ? qIndex : qIndex + 1;
              return (
                item && (
                  <li
                    className="answer-options-checked"
                    style={{
                      background: '#F9F9F9',
                      // color: '#ffffff',
                    }}
                  >
                    <Checkbox
                      disabled={isReadOnly}
                      name="current-situation"
                      checked={this.state.result == val}
                      value={val}
                      onClick={e => {
                        const { checked, value } = e.target;
                        let result = checked ? value : null;
                        this.setState({
                          result: result,
                        });
                        onClick.bind(scope, {
                          surveyQuestionId: SurveyQuestionId,
                          response: result,
                          level: level,
                          subLevel: subLevel,
                          subCatLevel: subCatLevel,
                          index: index,
                        })();
                      }}
                      style={{
                        background: '#EAEAEA',
                        color: '#000',
                      }}
                    >
                      {item}
                    </Checkbox>
                    <p className="p-2">{hint && renderHTML(hint)}</p>
                  </li>
                )
              );
            })}
          </ul>
          <DoesNotApplyOption surveyProps={this.props} />
        </div>
      </div>
    );
  }
}
export default SingleSelectOneLineColored;
