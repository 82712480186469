import React from 'react';
import { Tabs, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import {
  getDashboardAlert,
  prescribeNotificationGet,
  prescribeUpdate,
} from '../../../store/actions';
import { SparklinesBars } from 'react-sparklines';
import renderHTML from 'react-render-html';
import { Popover, Button } from 'antd';
import Axios from 'axios';

class Alerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      alertsArray: [],
    };
  }
  componentDidMount() {
    let param = new URLSearchParams(this.props.history.location.search);
    var isSinglePatientMode = param.has('isSinglePatientMode');
    if (isSinglePatientMode) {
      let isSinglePatientMode = param.get('isSinglePatientMode');
      let reason = param.get('reason');
      let centerId = param.get('centerId');
      let patient_external_id = param.get('pId');
      let eRXSSOLogId = param.get('eRXSSOLogId');
      this.props.dispatch(
        prescribeUpdate({
          ClientId: patient_external_id,
          IsSinglePatientMode: isSinglePatientMode,
          CenterId: centerId,
          SessionOverReason: reason,
          eRXSSOLogId: eRXSSOLogId,
        }),
      );
    }
    this.props.dispatch(getDashboardAlert({}));
    this.props.dispatch(prescribeNotificationGet({}));
  }
  handleVisibleChange = visible => {
    this.setState({ visible });
  };
  componentWillReceiveProps({ PractitionerValue, alertTypes, List }) {
    if (List && List != this.props.List) {
      let alerts = [];
      alertTypes &&
        alertTypes.length > 0 &&
        alertTypes.map((item, index) => {
          let newArray = [];
          List &&
            List.length > 0 &&
            List.map((aItem, aIndex) => {
              if (item.AlertType == aItem.AlertType) {
                newArray.push(aItem);
              }
            });
          alerts.push({
            name: item.AlertType,
            alerts: [...newArray],
          });
        });
      this.setState({ alertsArray: [...alerts] });
    }
    if (PractitionerValue && PractitionerValue != this.props.PractitionerValue) {
      this.props.dispatch(
        getDashboardAlert({
          Json: JSON.stringify({
            Alert: [
              {
                AlertType: this.state.alertType,
                PractitionerId: PractitionerValue,
              },
            ],
          }),
        }),
      );
    }
  }
  sortAlert = item => {
    // Text: 'Group Appointment';
    // Value: 'Group Appointment';
    this.props.dispatch(
      getDashboardAlert({
        Json: JSON.stringify({
          Alert: [
            {
              AlertType: item.Value,
            },
          ],
        }),
      }),
    );

    this.setState({
      alertType: item,
      visible: false,
    });
  };

  handleRcopia = notificationType => {
    let param = new URLSearchParams(this.props.history.location.search);

    let data = {
      Json: JSON.stringify({
        RedirectUrl: window.location.href,
        NotificationType: notificationType ? notificationType : '',
      }),
    };
    this.setState({
      isLoading: true,
    });
    Axios.post('EPrescribe/LimitedMultiPatientModeSingleSignOn', data)
      .then(response => {
        this.setState({
          isLoading: false,
        });
        if (response && response.data && response.data.success) {
          window.open(response.data.result.url, '_blank');
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  render() {
    const { List, AlertFilter, prescribeNotification, isReadOnlyUser } = this.props;
    const { alertsArray } = this.state;
    return (
      <div class="row">
        {prescribeNotification && (
          <>
            <div className="col-12 mb-2">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0" style={{ marginTop: '4px' }}>
                  Prescription(s)
                </h6>
                {/* {!isReadOnlyUser && (
                  <Button
                    loading={this.state.isLoading}
                    type="primary"
                    className="btn save-button e-rescription"
                    onClick={() => {
                      let param = new URLSearchParams(this.props.history.location.search);

                      let data = {
                        Json: JSON.stringify({ RedirectUrl: window.location.href }),
                      };
                      this.setState({
                        isLoading: true,
                      });
                      Axios.post('EPrescribe/LimitedMultiPatientModeSingleSignOn', data)
                        .then(response => {
                          this.setState({
                            isLoading: false,
                          });
                          if (response.data.success) {
                            window.open(response.data.result.url, '_blank');
                          }
                        })
                        .catch(function(error) {
                          console.log(error);
                        });
                    }}
                  >
                    Visit Portal
                  </Button>
                )} */}
              </div>
            </div>
            <div className="col-12">
              <ul className="alert-list">
                {prescribeNotification.map(item => {
                  let string = item && item.Msg;
                  let clickableText = item && item.ClickableText && item.ClickableText.split(',');
                  let arr = string && string.split('<ClickableText>');
                  return (
                    item &&
                    item.ClickableText && (
                      <li className="alert-list-item">
                        <span>{arr && arr.length > 0 && arr[0]}</span>
                        <span
                          style={{ color: '#46b4fe', cursor: 'pointer', fontWeight: '700' }}
                          onClick={() => this.handleRcopia(item.NotificationType)}
                        >
                          {clickableText && clickableText[0]}
                        </span>
                        <span>{arr && arr.length > 0 && arr[1]}</span>
                        <span
                          style={{ color: '#46b4fe', cursor: 'pointer', fontWeight: '700' }}
                          onClick={() => this.handleRcopia(item.NotificationType)}
                        >
                          {clickableText && clickableText[1]}
                        </span>
                        <span>{arr && arr.length > 0 && arr[2]}</span>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </>
        )}
        <div className="col-12 mb-2">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0" style={{ marginTop: '4px' }}>
              Alerts
            </h5>
            <Popover
              overlayClassName="alert-box-style"
              title={<h6>Sort by</h6>}
              placement="left"
              content={
                <div className="sort-by-list">
                  {AlertFilter &&
                    AlertFilter.map(item => (
                      <p
                        onClick={() => {
                          this.sortAlert(item);
                        }}
                      >
                        {item.Text}
                      </p>
                    ))}
                </div>
              }
              trigger="click"
              open={this.state.visible}
              onOpenChange={this.handleVisibleChange}
              class="alert-sort"
            >
              <img src="images/icons/alert-sort.svg" style={{ cursor: 'pointer', width: '20px' }} />
            </Popover>
          </div>
        </div>
        {/* style={{ overflowY: 'auto', overflowX: 'hidden', height: '100vh' }} */}
        <div className="col-12">
          {alertsArray &&
            alertsArray.length > 0 &&
            alertsArray.map((aitem, index) => {
              const { name, alerts } = aitem;
              return (
                <>
                  <h6 className="my-2">{name}</h6>
                  <ul
                    className="alert-list"
                    style={{ maxHeight: '250px', overflowX: 'hidden', overflowY: 'auto' }}
                  >
                    {alerts &&
                      alerts.map(item => {
                        return (
                          // <li
                          //   className={item.Hyperlink && 'dashboard-alert'}
                          //   onClick={() => {
                          //     item.Hyperlink && this.props.history.push(item.Hyperlink);
                          //   }}
                          // >
                          //   <span>{item.DisplayMessage}</span>
                          // </li>
                          <li className="alert-list-item">{renderHTML(item.DisplayHtml)}</li>
                        );
                      })}
                  </ul>
                </>
              );
            })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_dashboard_alert, prescribe_notification_get }) => {
  const { data, isFetching } = get_dashboard_alert;
  const { data: prescribeData } = prescribe_notification_get;

  let List = [];
  let AlertFilter = [];
  let prescribeNotification;
  let alertTypes = [];

  if (data && data.Alerts) {
    List = data.Alerts || [];
  }
  if (data && data.AlertTypes) {
    alertTypes = data.AlertTypes || [];
  }
  if (data && data.AlertFilter) {
    AlertFilter = data.AlertFilter || [];
  }
  if (prescribeData && prescribeData.Notification) {
    prescribeNotification = prescribeData.Notification;
  }

  return {
    List,
    AlertFilter,
    prescribeNotification,
    isFetching,
    alertTypes,
  };
};
export default withRouter(connect(mapStateToProps)(Alerts));
