import React, { useEffect, useState } from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoCompleteText,
} from '../../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Drawer } from 'antd';
import CustomModal from '../../core/modal';
import AntdModal from '../../Components/CustomModal';
import { getPhoneNumber, getCountryCalling } from '../../util/phoneFormatter';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import {
  adminstrationGetCenter,
  adminstrationCenterUpsert,
  adminstrationResetCenter,
  resetAdminstrationCenterUpsert,
  centerDelete,
  resetCenterDelete,
  listCombos,
  adminstrationListCenter,
  centerReactivate,
  resetCenterReactivate,
  getCenterFieldByCenter,
  resetGetCenterFieldByCenter,
  resetCenterFieldUpsert,
} from '../../store/actions';
import { ProcessCombos } from '../../store/comboConfig';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Deactivate_Sucess_Msg,
} from '../../app-config';
import validate, {
  federalFormatter,
  federalNormalize,
  MobileFormatter,
  MobileNormalize,
} from '../enrollmentForm/validation/validate';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';
import CustomCenterFieldsDrawer from './customCenterFieldsDrawer';
import CloseIcon from '../../Components/CloseIcon';
import PageTitle from '../../Components/PageTitle';

class ActivityBase extends React.Component {}

const Validator = {
  isValidMobile: value => {
    if (value && value.length > 2) {
      let newValue = value.includes('+') ? value : `+${value}`;
      let CallingCode = newValue && parsePhoneNumber(newValue);
      let nationalNumber = CallingCode && CallingCode.nationalNumber;
      if (nationalNumber) {
        let isValid = CallingCode && isValidPhoneNumber(newValue, CallingCode.country);
        let isValidLength = CallingCode && validatePhoneNumberLength(newValue, CallingCode.country);
        if (isValidLength) {
          return isValidLength.toLowerCase().replace('_', ' ');
        } else if (!isValid) {
          return 'Invalid Phone';
        } else return undefined;
      } else return undefined;
    } else {
      return undefined;
    }
  },
  phoneRequired: value => {
    if (value && value.length > 2) {
      let newValue = value.includes('+') ? value : `+${value}`;
      let CallingCode = newValue && parsePhoneNumber(newValue);
      let nationalNumber = CallingCode && CallingCode.nationalNumber;
      if (nationalNumber) return undefined;
      else return 'Required';
    }
  },

  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  usaZip: value => (value && !/^[0-9]{5}$/.test(value) ? 'is invalid for USA' : undefined),
  ausZip: value => (value && !/^[0-9]{4}$/.test(value) ? 'is invalid for Australia' : undefined),
  zip: value => (value && !/^[a-zA-Z0-9_ ]{6,7}$/.test(value) ? 'is invalid' : undefined),
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val !== undefined && val !== null && val.toString() ? undefined : 'Required';
  },
  isMultiRequired: value => (value && value.length > 0 ? undefined : 'required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'allow numeric values only' : undefined,

  federalID: value => (value && !/^[0-9-]+$/.test(value) ? 'allow numeric values only' : undefined),

  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,

  range0to999: value => (value >= 0 && value < 1000 ? undefined : 'Value should be 0 to 999 only'),
  // value && value > 4 && value < 1000 ? undefined : 'Value should be 5 to 999 only',
  allowPositive: value =>
    value && parseInt(value) < 0
      ? 'should not be less than 0'
      : Number.isInteger(value) || !isNaN(value)
      ? undefined
      : 'allows numeric values only',
};
class CenterForm extends ActivityBase {
  constructor(props) {
    super(props);
    const defaultCenterId = parseInt(localStorage.getItem('centerId'));
    const IS_SPECIAL_SUPER_ADMIN = localStorage.getItem('isSpecialSuperAdmin');
    this.state = {
      centerData: {
        country: 'USA',
        parentCenter: IS_SPECIAL_SUPER_ADMIN == 'true' ? null : defaultCenterId,
      },
    };
  }
  getCenterId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var centerId = param.get('centerId') || param.get('centerid');
    if (centerId) {
      return (centerId = parseInt(centerId));
    } else {
      centerId = -1;
    }
    return centerId;
  }

  getStringType = data => {
    var array = [];
    let isArrayData = Array.isArray(data);
    if (isArrayData) for (let item of data) array.push(Object.values(item)[0]);
    return array.toString();
  };

  componentWillReceiveProps({ centerData, isInserted, isRemoved, isReactivated, countryISO }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.props.dispatch(resetAdminstrationCenterUpsert());
      this.toggle(Upsert_Sucess_Msg);
    }
    if (isRemoved && isRemoved != this.props.isRemoved) {
      this.toggle('Center ' + Deactivate_Sucess_Msg);
      this.props.dispatch(resetCenterDelete());
    }
    if (isReactivated && isReactivated != this.props.isReactivated) {
      this.toggle('Center Reactivated Successfully');
      this.props.dispatch(resetCenterReactivate());
    }
    if (centerData && JSON.stringify(centerData) != JSON.stringify(this.props.centerData)) {
      if (centerData.CenterId && this.getCenterId() > 0) {
        var Center = {
          centerId: centerData.CenterId,
          centerName: centerData.Name,
          parentCenter: centerData.ParentCenterId || null,
          bedsAvailable: centerData.BedsAvailable && centerData.BedsAvailable.toString(),
          CostPerDay: centerData.CostPerDay && centerData.CostPerDay.toString(),
          line1: centerData.AddressLine1,
          line2: centerData.AddressLine2,
          city: centerData.City,
          stateOrProvince: centerData.StateOrProvince,
          country: centerData.CountryISO,
          zip: centerData.ZipCode,
          subRegion: centerData.SubRegion,
          userTimeZone: centerData.TimeZoneId,
          email: centerData.Email,
          primaryCN: `+${centerData.PhoneCountryCode}${centerData.Phone}`,
          secondaryCN: `+${centerData.Phone2CountryCode}${centerData.Phone2}`,
          website: centerData.Website,
          primaryStaffId: centerData.PrimaryStaffId,
          secondaryStaffId: centerData.SecondaryStaffId,
          programType: this.getStringType(centerData.CenterProgramTypeCenter),
          populationType: this.getStringType(centerData.CenterPopulationTypeCenter),
          serviceType: this.getStringType(centerData.CenterServiceTypeCenter),
          region: centerData.Region,
          isArchived: centerData.IsArchived,
          isClinicalWorkflow: centerData.IsClinicalWorkflow,
          allowStudentToCompleteTask: centerData.AllowStudentToCompleteTask,
          billingName: centerData.BillingName,
          billingStreet: centerData.BillingStreet,
          billingCity: centerData.BillingCity,
          billingState: centerData.BillingState,
          billingZip: centerData.BillingZip,
          billingPhone: centerData.BillingPhone,
          federalID: centerData.FederalId,
          facility: centerData.Facility,
          billingFax: centerData.Fax,
          isPrivateCenter: centerData.IsPrivateCenter,
          isAdolescent: centerData.IsAdolescent,
          isJudicial: centerData.IsJudicial,
          isAllowAccessToAllAppUsers: centerData.IsAllowAccessToAllAppUsers,
          npi: centerData.NPI,
          signatureTypesSupported: centerData.SignatureTypeSupported,
          cwBundleType: centerData.CWBundleType,
          formHeaderName: centerData.FormHeaderName,
          formHeaderLinePrimary: centerData.FormHeaderLinePrimary,
          formHeaderLineSecondary: centerData.FormHeaderLineSecondary,
          formHeaderState: centerData.FormHeaderState,
          formHeaderCity: centerData.FormHeaderCity,
          formHeaderZip: centerData.FormHeaderZip,
          clearingHouseFacilityId: centerData.ClearingHouseFacilityId,
          placeOfServiceId:
            centerData.PlaceOfServiceCenter && this.getStringType(centerData.PlaceOfServiceCenter),
          medicalProgramCenter:
            centerData.MedicalProgramCenter && this.getStringType(centerData.MedicalProgramCenter),
          licensePaidBy: centerData.LicensePaidBy,
          // isAlumni: centerData.CenterField && centerData.CenterField[0].IsAlumniProgram,
          // isFamilyProgram: centerData.CenterField && centerData.CenterField[0].IsFamilyProgram,
          // whiteLabelName: centerData.CenterField && centerData.CenterField[0].WhiteLabelName,
        };
        this.setState({
          centerData: Center,
          IsShowCenterExtendedField: centerData.IsShowCenterExtendedField,
        });
      }
    }
  }

  componentDidMount() {
    var result = ProcessCombos(
      'Filter_StateProvince,CenterServiceType,CenterPopulationType,CenterProgramType,Region,SubRegion,StateProvince,Country,Staff,RCM_PlaceOfService,ClinicalCenter,ListCWBundle,ListMedicalProgram,ListPlaceOfServiceCenter,signaturetypessupported,TimeZone,RCM_Program',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let data = { Center: { CenterId: this.getCenterId() } };
    this.props.dispatch(
      listCombos({
        comboTypes: 'CenterParent,ParentCenter,CenterField',
        centerId: this.getCenterId(),
        Json: JSON.stringify(data),
      }),
    );
    if (this.getCenterId() > 0) {
      this.props.dispatch(adminstrationGetCenter({ CenterId: this.getCenterId() }));
    } else {
      this.props.dispatch(adminstrationResetCenter());
    }
    this.props.dispatch(adminstrationListCenter({}));
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  getArrayType = (data, key) => {
    let array = [];
    if (typeof data === 'string') {
      if (data.indexOf(',') > 0) {
        for (var value of data.split(',')) {
          array.push({ [key]: value });
        }
      } else {
        array = [{ [key]: data }];
      }
    } else if (data && data.length > 0) {
      for (let item of data) array.push({ [item.ValueColumnName]: item.Value });
    }
    return array;
  };

  onSubmit(values) {
    //alert(JSON.stringify(values));
    const {
      parentCenter,
      centerName,
      bedsAvailable,
      CostPerDay,
      line1,
      line2,
      city,
      stateOrProvince,
      country,
      zip,
      region,
      subRegion,
      primaryCN,
      secondaryCN,
      email,
      website,
      serviceType,
      populationType,
      programType,
      primaryStaffId,
      secondaryStaffId,
      isPrivateCenter,
      isAdolescent,
      isJudicial,
      isClinicalWorkflow,
      allowStudentToCompleteTask,
      isAllowAccessToAllAppUsers,
      cwBundleType,
      placeOfServiceId,
      clearingHouseFacilityId,
      federalID,
      facility,
      npi,
      billingName,
      billingStreet,
      billingState,
      billingCity,
      billingZip,
      billingPhone,
      billingFax,
      signatureTypesSupported,
      medicalProgramCenter,
      formHeaderName,
      formHeaderLinePrimary,
      formHeaderLineSecondary,
      formHeaderState,
      formHeaderCity,
      formHeaderZip,
      userTimeZone,
      isAlumni,
      isFamilyProgram,
      whiteLabelName,
      licensePaidBy,
    } = values;
    var centerId = null;
    // let centerField = [{
    //   IsAlumniProgram: isAlumni,
    //   IsFamilyProgram: isFamilyProgram,
    //   WhiteLabelName: whiteLabelName,
    // }]

    var paramCenterId = this.getCenterId();
    if (paramCenterId && paramCenterId > 0) {
      centerId = paramCenterId;
    }
    var data = {
      Center: [
        {
          CenterId: centerId,
          Name: centerName,
          ParentCenterId: parentCenter,
          BedsAvailable: bedsAvailable && parseInt(bedsAvailable),
          CostPerDay: CostPerDay && parseInt(CostPerDay),
          AddressLine1: line1,
          AddressLine2: line2,
          City: city,
          StateOrProvince: stateOrProvince,
          CountryISO: country,
          ZipCode: zip,
          Region: region,
          SubRegion: subRegion,
          TimeZoneId: userTimeZone,
          PrimaryStaffId: primaryStaffId,
          SecondaryStaffId: secondaryStaffId,
          Email: email,
          PhoneCountryCode: getCountryCalling(primaryCN),
          Phone: getPhoneNumber(primaryCN),
          Phone2CountryCode: getCountryCalling(secondaryCN),
          Phone2: getPhoneNumber(secondaryCN),
          Website: website,
          BillingName: billingName,
          BillingStreet: billingStreet,
          BillingCity: billingCity,
          BillingState: billingState,
          BillingZip: billingZip,
          BillingPhone: billingPhone,
          IsPrivateCenter: isPrivateCenter,
          IsAdolescent: isAdolescent,
          IsJudicial: isJudicial,
          IsClinicalWorkflow: isClinicalWorkflow,
          AllowStudentToCompleteTask: allowStudentToCompleteTask,
          IsAllowAccessToAllAppUsers: isAllowAccessToAllAppUsers,
          FederalId: federalID && federalID.replace(/[^\d]/g, ''),
          Facility: facility,
          ClearingHouseFacilityId: clearingHouseFacilityId,
          Fax: billingFax,
          NPI: npi,
          SignatureTypeSupported: signatureTypesSupported,
          CWBundleType: cwBundleType,
          PlaceOfServiceCenter: this.getArrayType(placeOfServiceId, 'PlaceOfServiceId'),
          MedicalProgramCenter: this.getArrayType(medicalProgramCenter, 'MedicalProgramId'),
          FormHeaderName: !formHeaderName ? null : formHeaderName,
          FormHeaderLinePrimary: formHeaderLinePrimary,
          FormHeaderLineSecondary: formHeaderLineSecondary,
          FormHeaderState: formHeaderState,
          FormHeaderCity: formHeaderCity,
          FormHeaderZip: formHeaderZip,
          CenterProgramTypeCenter: this.getArrayType(programType, 'CenterProgramTypeId'),
          CenterPopulationTypeCenter: this.getArrayType(populationType, 'CenterPopulationTypeId'),
          CenterServiceTypeCenter: this.getArrayType(serviceType, 'CenterServiceTypeId'),
          LicensePaidBy: licensePaidBy,
          // CenterField: centerField,
        },
      ],
    };
    var centerData = {
      centerId: centerId,
      jsonInput: JSON.stringify(data),
    };
    this.props.dispatch(adminstrationCenterUpsert(centerData));
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle TitleText="Center" />
        <ReduxCenterForm
          onSubmit={this.onSubmit.bind(this)}
          initialValues={this.state.centerData}
          isArchived={this.state.centerData.isArchived}
          isShowCenterExtendedField={this.state.IsShowCenterExtendedField}
          getCenterId={this.getCenterId()}
          onCancel={() => this.props.history.push('/manage/centers')}
          Delete={() => {
            this.Removetoggle();
          }}
          history={this.props.history}
        />
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              this.props.history.push('/manage/centers');
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
        {this.state.isRemoveModelOpen && (
          <CustomModal
            isOpen={this.state.isRemoveModelOpen}
            header={Delete_Confirmation_Msg}
            yesAction={() => {
              this.Removetoggle();
              this.props.dispatch(centerDelete({ CenterId: this.getCenterId() }));
            }}
            yesText="submit"
            noText="cancel"
            noAction={() => {
              this.Removetoggle();
              this.props.history.push('/manage/centers');
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

let CenterRegForm = ({
  onSubmit,
  handleSubmit,
  center,
  country,
  stateProvince,
  centerServiceType,
  centerPopulationType,
  centerProgramType,
  regions,
  subRegion,
  initialValues,
  getCenterId,
  onCancel,
  Delete,
  staff,
  dispatch,
  history,
  centerName,
  isArchived,
  isClinical,
  clinicalCenter,
  ListPlaceOfServiceCenter,
  ListCWBundle,
  ListMedicalProgram,
  Signaturetypessupported,
  isShowCenterExtendedField,
  isFetching,
  timeZone,
  Rcm_Program,
  parentCenter,
  isSuccess,
  isCenterFieldUpsert,
  countryISO,
  // change,
  // centerField,
  Filter_State,
}) => {
  const [userRoleValue, setUserRoleValue] = useState(0);
  const [isCustomFieldsDrawerOpen, setIsCustomFieldsDrawerOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const IS_SPECIAL_SUPER_ADMIN = localStorage.getItem('isSpecialSuperAdmin');
  const isSpecialSuperAdmin = IS_SPECIAL_SUPER_ADMIN == 'true';
  // const onCenterChange = parentId => {
  //   dispatch(
  //     getCenterFieldByCenter({
  //       JsonInput: JSON.stringify({
  //         CenterField: {
  //           ParentCenterId: parentId,
  //         },
  //       }),
  //     }),
  //   );
  // };

  // useEffect(() => {
  //   if (centerField) {
  //     change('isAlumni', centerField.IsAlumniProgram);
  //     change('isFamilyProgram', centerField.IsFamilyProgram);
  //     change('whiteLabelName', centerField.WhiteLabelName);
  //   }
  // }, [centerField]);
  useEffect(() => {
    if (isSuccess) {
      setIsModelOpen(true);
      setIsCustomFieldsDrawerOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    let userRole = localStorage.getItem('userRole');
    setUserRoleValue(userRole);
  }, []);

  return (
    <div class="main enrollment-page mb-5">
      <div class="container-fluid">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h4 class="form-main-head"> {getCenterId > 0 ? 'Edit Center' : 'Add a Center'}</h4>
            </div>
            <div class="col-12 col-md-8 col-lg-9 text-right">
              <input
                type="button"
                class=" btn btn-outline-primary"
                value="Cancel"
                onClick={onCancel}
              />
              &nbsp;
              {/* <input type="submit" class="btn" value="Save" /> */}
              <Button
                loading={isFetching}
                htmlType="submit"
                type="primary"
                className="save-button"
                // disabled={IsDeActivated}
              >
                Save
              </Button>
            </div>
          </div>
          <div class="row  mb-5">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form center-form">
                  <div class="row pb-5 pl-3 pr-3 pt-3">
                    {getCenterId > 0 && (
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="centerId"
                            type="text"
                            component={ReduxInput}
                            disabled={true}

                            // errorMessage="Center Name Required"
                            // validate={Validator.required}
                            // fieldName={'Center Name'}
                          />
                          <label class="placeholder-label">Center ID</label>
                        </div>
                      </div>
                    )}

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="centerName"
                          type="text"
                          component={ReduxInput}
                          errorMessage="Center Name Required"
                          validate={Validator.required}
                          fieldName={'Center Name'}
                        />
                        <label class="placeholder-label">Center Name*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="parentCenter"
                          type="text"
                          errorMessage="Parent Center Required"
                          component={ReduxSelect}
                          // onChange={parentCenterId => onCenterChange(parentCenterId)}
                          options={center}
                          placeholder={isSpecialSuperAdmin ? 'Parent Center' : 'Parent Center*'}
                          validate={isSpecialSuperAdmin ? null : Validator.required}
                          fieldName={'Parent Center'}
                        />
                        {/* <label class="placeholder-label">Parent Center*</label> */}
                      </div>
                    </div>
                    {/* <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="whiteLabelName"
                          type="text"
                          component={ReduxInput}
                          fieldName={'White Label Name'}
                        />
                        <label class="placeholder-label">White Label Name</label>
                      </div>
                    </div> */}
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="bedsAvailable"
                          type="text"
                          component={ReduxInput}
                          errorMessage="Center Name Required"
                          validate={[
                            // Validator.required,
                            Validator.allowNumeric,
                            Validator.requiredInput,
                          ]}
                          fieldName={'Total Number of Beds'}
                        />
                        <label class="placeholder-label">Total Number of Beds*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="CostPerDay"
                          type="text"
                          component={ReduxInput}
                          errorMessage="Treatment cost per day is required"
                          validate={[
                            // Validator.required,
                            // Validator.allowNumeric,
                            Validator.range0to999,
                            Validator.allowPositive,
                            Validator.requiredInput,
                          ]}
                          fieldName={'Treatment Cost Per Day'}
                        />
                        <label class="placeholder-label">Treatment cost per day ($)*</label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="line1"
                          type="text"
                          errorMessage="Permanent Address Line 1 Required"
                          component={ReduxInput}
                          validate={Validator.required}
                          fieldName={'Permanent Address'}
                        />
                        <label class="placeholder-label">
                          Permanent Address Line 1*
                          {/* <span class="small-text">(Last Known Address)</span> */}
                        </label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field name="line2" type="text" component={ReduxInput} />
                        <label class="placeholder-label">Permanent Address Line 2</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="country"
                          type="text"
                          errorMessage="Country Required"
                          component={ReduxSelect}
                          options={country}
                          validate={Validator.required}
                          placeholder="Country*"
                          fieldName={'Country'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="city"
                          type="text"
                          errorMessage="City Required"
                          component={ReduxInput}
                          validate={Validator.required}
                          fieldName={'City'}
                        />
                        <label class="placeholder-label">City*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="stateOrProvince"
                          type="text"
                          errorMessage="State Required"
                          component={ReduxSelect}
                          options={Filter_State}
                          validate={Validator.required}
                          placeholder={'State/Province/Territories*'}
                          fieldName={'State/Province/Territories'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="zip"
                          type="text"
                          errorMessage="Zip Code Required"
                          component={ReduxInput}
                          // validate={Validator.required}
                          validate={
                            countryISO === 'USA'
                              ? [Validator.required, Validator.usaZip]
                              : countryISO === 'AUS'
                              ? [Validator.required, Validator.ausZip]
                              : [Validator.required, Validator.zip]
                          }
                          fieldName={'Zip Code'}
                        />
                        <label class="placeholder-label">Zip Code*</label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="region"
                          type="text"
                          component={ReduxSelect}
                          // validate={Validator.required}
                          placeholder={'Region'}
                          options={regions}
                          label={'Region'}
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="subRegion"
                          errorMessage="Sub Region Required"
                          component={ReduxSelect}
                          // validate={Validator.required}
                          placeholder={'Sub Region'}
                          options={subRegion}
                          label={'Sub Region'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="userTimeZone"
                          errorMessage="Time Zone Required"
                          component={ReduxSelect}
                          validate={Validator.required}
                          placeholder={'Time Zone*'}
                          options={timeZone}
                          fieldName={'Time Zone'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 d-flex">
                      <div class="form-group w-100">
                        <Field
                          name="primaryCN"
                          component={ReduxPhoneInput}
                          validate={[Validator.isValidMobile, Validator.required]}
                          country={country}
                          placeholder="Center Phone*"
                          fieldName={'Center Phone'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 d-flex">
                      <div class="form-group w-100">
                        <Field
                          name="secondaryCN"
                          component={ReduxPhoneInput}
                          Country={country}
                          validate={Validator.isValidMobile}
                          fieldName={'Secondary Phone'}
                          placeholder="Secondary Phone"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="email"
                          type="email"
                          errorMessage="Center E-mail Required"
                          component={ReduxInput}
                          // validate={Validator.required}
                        />
                        <label class="placeholder-label">Center E-mail</label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="website"
                          errorMessage="Website Required"
                          component={ReduxInput}
                          // validate={Validator.required}
                        />
                        <label class="placeholder-label">Website</label>
                      </div>
                    </div>

                    {getCenterId > 0 ? (
                      <>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="primaryStaffId"
                              component={ReduxSelect}
                              options={staff}
                              placeholder="Primary Staff ID"
                              fieldName={'Primary StaffId'}
                            />
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="secondaryStaffId"
                              component={ReduxSelect}
                              options={staff}
                              placeholder="Secondary Staff ID"
                              fieldName={'Secondary StaffId'}
                            />
                          </div>
                        </div>

                        {isSpecialSuperAdmin && (
                          <div class="col-12 col-md-6 col-lg-4">
                            <div class="form-group">
                              <Field
                                name="licensePaidBy"
                                component={ReduxSelect}
                                options={parentCenter}
                                placeholder="License Paid By"
                                fieldName={'LicensePaidBy '}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : null}
                    <div className="col-12">
                      <div className="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group isMultiHeight">
                            <Field
                              name="populationType"
                              type="text"
                              errorMessage="Population Type Disorder Required"
                              component={ReduxSelect}
                              validate={[Validator.required, Validator.isMultiRequired]}
                              options={centerPopulationType}
                              placeholder="Population Type*"
                              fieldName={'Population Type'}
                              isMulti
                            />
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group isMultiHeight">
                            <Field
                              name="programType"
                              type="text"
                              errorMessage="Program Type Required"
                              component={ReduxSelect}
                              validate={[Validator.required, Validator.isMultiRequired]}
                              options={centerProgramType}
                              placeholder="Program Type*"
                              fieldName={'Program Type'}
                              isMulti
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group isMultiHeight">
                            <Field
                              name="serviceType"
                              type="text"
                              errorMessage="Service Type Required"
                              component={ReduxSelect}
                              validate={[Validator.required, Validator.isMultiRequired]}
                              options={centerServiceType}
                              placeholder="Service Type*"
                              fieldName={'Service Type'}
                              isMulti
                            />
                          </div>
                        </div>
                        {/* <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group isMultiHeight">
                            <label class="custom-control-label" for>
                              <Field
                                name="isAlumni"
                                type="text"
                                component={ReduxToggle}
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                // disabled={disable}
                              />
                              Is Alumni?
                            </label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group isMultiHeight">
                            <label class="custom-control-label" for>
                              <Field
                                name="isFamilyProgram"
                                type="text"
                                component={ReduxToggle}
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                // disabled={disable}
                              />
                              Is Family Program?
                            </label>
                          </div>
                        </div> */}
                      </div>
                      {isShowCenterExtendedField && (
                        <>
                          <div className="col-12">
                            <div className="row">
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group isMultiHeight">
                                  <label class="custom-control-label" for>
                                    <Field
                                      name="isPrivateCenter"
                                      type="text"
                                      component={ReduxToggle}
                                      checkedChildren="Yes"
                                      unCheckedChildren="No"
                                      // disabled={disable}
                                    />
                                    {'  '}
                                    Is Private Center?
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group isMultiHeight">
                                  <label class="custom-control-label" for>
                                    <Field
                                      name="isAdolescent"
                                      type="text"
                                      component={ReduxToggle}
                                      checkedChildren="Yes"
                                      unCheckedChildren="No"
                                      // disabled={disable}
                                    />
                                    {'  '}
                                    Is Adolescent Center?
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group isMultiHeight">
                                  <label class="custom-control-label" for>
                                    <Field
                                      name="isJudicial"
                                      type="text"
                                      component={ReduxToggle}
                                      checkedChildren="Yes"
                                      unCheckedChildren="No"
                                      // disabled={disable}
                                    />
                                    {'  '}
                                    Is Judicial Center?
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group isMultiHeight">
                                  <label class="custom-control-label" for>
                                    <Field
                                      name="isAllowAccessToAllAppUsers"
                                      type="text"
                                      component={ReduxToggle}
                                      checkedChildren="Yes"
                                      unCheckedChildren="No"
                                      // disabled={disable}
                                    />
                                    {'  '}
                                    Allow Staff for this Center to Access All Subscribers?
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group isMultiHeight">
                                  <label class="custom-control-label" for>
                                    <Field
                                      name="allowStudentToCompleteTask"
                                      type="text"
                                      component={ReduxToggle}
                                      checkedChildren="Yes"
                                      unCheckedChildren="No"
                                      // disabled={disable}
                                    />
                                    {'  '}
                                    Allow Clients to Mark Task as Complete on Kiosk?
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="col-12 col-md-6 col-lg-4">
                            <div class="form-group isMultiHeight">
                              <label class="custom-control-label" for>
                                <Field
                                  name="isClinicalWorkflow"
                                  type="text"
                                  component={ReduxToggle}
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  // disabled={disable}
                                />
                                {'  '}
                                Is Clinical Workflow?
                              </label>
                            </div>
                          </div>
                          <hr />
                          {isClinical && (
                            <>
                              <div className="col-12 clinical-workflow">
                                <div className="row">
                                  <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group isMultiHeight">
                                      <Field
                                        name={'placeOfServiceId'}
                                        type="text"
                                        component={ReduxSelect}
                                        options={ListPlaceOfServiceCenter}
                                        placeholder="Place of Service (Select Multiple)"
                                        // validate={validate.required}
                                        fieldName={'Place of Services Center'}
                                        // disabled={disable}
                                        isMulti
                                      />
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group isMultiHeight">
                                      <Field
                                        name={'medicalProgramCenter'}
                                        type="text"
                                        component={ReduxSelect}
                                        options={Rcm_Program}
                                        placeholder="Medical Program * (Select Multiple)"
                                        validate={[Validator.required, Validator.isMultiRequired]}
                                        fieldName={'Medical Program Center'}
                                        // disabled={disable}
                                        isMulti
                                      />
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                      <Field
                                        name={'cwBundleType'}
                                        type="text"
                                        component={ReduxSelect}
                                        options={ListCWBundle}
                                        placeholder="CW Bundle Type"
                                        // validate={validate.required}
                                        fieldName={'CW Bundle Type'}
                                        // disabled={disable}
                                      />
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                      <Field
                                        name={'federalID'}
                                        type="text"
                                        component={ReduxInput}
                                        // placeholder="Federal ID"

                                        validate={validate.federalId}
                                        fieldName={'Federal ID'}
                                        // disabled=CWBundleType{disable}
                                        format={federalFormatter}
                                        normalize={federalNormalize}
                                      />

                                      <label class="placeholder-label">Federal ID</label>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                      <Field
                                        name={'facility'}
                                        type="text"
                                        validate={validate.required}
                                        component={ReduxInput}
                                        fieldName={'Facility Name'}
                                      />
                                      <label class="placeholder-label">Facility Name*</label>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                      <Field
                                        name={'clearingHouseFacilityId'}
                                        type="text"
                                        component={ReduxInput}
                                        fieldName={'Clearing House Facility Id'}
                                        validate={Validator.allowNumeric}
                                      />
                                      <label class="placeholder-label">
                                        Clearing House Facility Id
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="row">
                                      <h5 className="col-12">Billing </h5>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="billingName"
                                            type="text"
                                            component={ReduxInput}
                                            // disabled={true}
                                          />
                                          <label class="placeholder-label">Billing Name</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="billingStreet"
                                            type="text"
                                            errorMessage="Street Required"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'Billing Street'}
                                          />
                                          <label class="placeholder-label">Billing Street</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="billingCity"
                                            type="text"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'Billing City'}
                                          />
                                          <label class="placeholder-label">Billing City</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="billingState"
                                            type="text"
                                            errorMessage="State Required"
                                            component={ReduxSelect}
                                            options={stateProvince}
                                            // validate={Validator.required}
                                            placeholder={'Billing State'}
                                            fieldName={'Billing State'}
                                          />
                                        </div>
                                      </div>

                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="billingZip"
                                            type="text"
                                            errorMessage="Zip Code Required"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'Billing Zip Code'}
                                          />
                                          <label class="placeholder-label">Billing Zip Code</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="billingPhone"
                                            type="text"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'Billing Phone'}
                                          />
                                          <label class="placeholder-label">
                                            Billing Phone Number
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="billingFax"
                                            type="text"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'Billing Fax'}
                                          />
                                          <label class="placeholder-label">Billing Fax</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="npi"
                                            type="text"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'NPI'}
                                          />
                                          <label class="placeholder-label">NPI</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="signatureTypesSupported"
                                            type="text"
                                            component={ReduxSelect}
                                            options={Signaturetypessupported}
                                            placeholder="Signature Types"
                                            // validate={validate.required}
                                            fieldName={'Signature Types Supported '}
                                            // disabled={disable}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <div className="row">
                                      <h5 className="col-12">Form Header</h5>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="formHeaderName"
                                            type="text"
                                            component={ReduxInput}
                                            // disabled={true}
                                          />
                                          <label class="placeholder-label">Form Header Name</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="formHeaderLinePrimary"
                                            type="text"
                                            errorMessage="Street Required"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'FormHeader Street'}
                                          />
                                          <label class="placeholder-label">
                                            Form Header Address Line 1
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="formHeaderLineSecondary"
                                            type="text"
                                            errorMessage="Street Required"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'FormHeader Street'}
                                          />
                                          <label class="placeholder-label">
                                            Form Header Address Line 2
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="formHeaderState"
                                            type="text"
                                            errorMessage="State Required"
                                            component={ReduxSelect}
                                            options={stateProvince}
                                            // validate={Validator.required}
                                            placeholder={'Form Header State'}
                                            fieldName={'Form Header State'}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="formHeaderCity"
                                            type="text"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'FormHeader City'}
                                          />
                                          <label class="placeholder-label">Form Header City</label>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="form-group">
                                          <Field
                                            name="formHeaderZip"
                                            type="text"
                                            errorMessage="Zip Code Required"
                                            component={ReduxInput}
                                            // validate={Validator.required}
                                            fieldName={'FormHeader Zip Code'}
                                          />
                                          <label class="placeholder-label">
                                            Form Header Zip Code
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {isSpecialSuperAdmin && getCenterId > 0 && (
                        <p
                          className="mb-0 center-custom-fields"
                          onClick={() => {
                            setIsCustomFieldsDrawerOpen(true);
                          }}
                        >
                          Manage Custom Fields
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getCenterId > 0 && (
              <div class="col-12 text-right">
                {isArchived ? (
                  <>
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <a
                          class="reactivate-link"
                          data-toggle="modal"
                          data-target="#reactivate-staff-model"
                          onClick={onClick}
                        >
                          <i class="fa fa-check" aria-hidden="true" /> Reactivate
                        </a>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to reactivate <br />
                              <strong>{centerName}</strong> center?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                                // var staffURL = '/manage/staff';
                                // var centerId = getCenterId;
                                // if (centerId && centerId > 0) {
                                //   staffURL = '/manage/staff?centerId=' + centerId;
                                // }
                                // // if(staffId){
                                // //   staffURL='/manage/staff?staffId='+getStaffId
                                // // }
                                // history.push(staffURL);
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                dispatch(centerReactivate({ CenterId: getCenterId }));
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <a
                          class="deactivate-link"
                          data-toggle="modal"
                          data-target="#deactivate-center-model"
                          onClick={onClick}
                        >
                          <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                        </a>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to Deactivate <br />
                              <strong>{centerName}</strong> center?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                                // var staffURL = '/manage/staff';
                                // var centerId = getCenterId;
                                // if (centerId && centerId > 0) {
                                //   staffURL = '/manage/staff?centerId=' + centerId;
                                // }
                                // // if(staffId){
                                // //   staffURL='/manage/staff?staffId='+getStaffId
                                // // }
                                // history.push(staffURL);
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                dispatch(centerDelete({ CenterId: getCenterId }));
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
      {isModelOpen && (
        <CustomModal
          isOpen={isModelOpen}
          header={'Your changes have been saved successfully'}
          yesAction={() => {
            // this.toggle();
            setIsModelOpen(false);

            dispatch(resetCenterFieldUpsert({}));
          }}
          yesText="OK"
          hideCancel={true}
        />
      )}
      <Drawer
        className="custom-drawer center-custom-fields-drawer"
        title="Manage Custom Fields"
        placement="right"
        open={isCustomFieldsDrawerOpen}
        width={800}
        bodyStyle={{ paddingBottom: 80 }}
        closable={true}
        destroyOnClose={true}
        onClose={() => {
          setIsCustomFieldsDrawerOpen(false);
        }}
        closeIcon={<CloseIcon />}
      >
        <CustomCenterFieldsDrawer
          // ClientId={this.getStudentId()}
          onCancel={() => {
            setIsCustomFieldsDrawerOpen(false);
          }}
          isCenterFieldUpsert={isCenterFieldUpsert}
        />
      </Drawer>
    </div>
  );
};
const mapStateToProps = state => {
  const {
    combos,
    center_get,
    center_upsert,
    get_center_field_by_center,
    center_field_upsert,
  } = state;
  const { isFetching } = center_upsert;
  const { data: centerFieldsUpsert, isFetching: isCenterFieldUpsert } = center_field_upsert;
  // const { isFetching: isParentFetching } = get_center_field_by_center;

  const centerName = selector(state, 'centerName');
  const isClinical = selector(state, 'isClinicalWorkflow');
  const countryISO = selector(state, 'country') || 'USA';

  let centerServiceType = [],
    centerPopulationType = [],
    centerProgramType = [],
    regions = [],
    subRegion = [],
    center = [],
    parentCenter = [],
    stateProvince = [],
    Filter_State = [],
    country = [],
    staff = [],
    clinicalCenter = [],
    PlaceOfService = [],
    listPlaceOfServiceCenter = [],
    listCWBundle = [],
    listMedicalProgram = [],
    timeZone = [],
    Signaturetypessupported = [],
    Rcm_Program = [];
  let isSuccess;
  // let isParentSuccess;

  if (centerFieldsUpsert && centerFieldsUpsert.success) {
    isSuccess = true;
  }
  if (combos.data) {
    const {
      CenterServiceType,
      CenterPopulationType,
      CenterProgramType,
      Region,
      SubRegion,
      CenterParent,
      ParentCenter,
      StateProvince,
      Country,
      Staff,
      ClinicalCenter,
      ListPlaceOfServiceCenter,
      signaturetypessupported,
      ListCWBundle,
      ListMedicalProgram,
      TimeZone,
      RCM_Program,
      Filter_StateProvince,
    } = combos.data;
    if (CenterServiceType) {
      centerServiceType = CenterServiceType;
    }
    if (CenterPopulationType) {
      centerPopulationType = CenterPopulationType;
    }
    if (CenterProgramType) {
      centerProgramType = CenterProgramType;
    }
    if (Region) {
      regions = Region;
    }
    if (SubRegion) {
      subRegion = SubRegion;
    }
    if (CenterParent) {
      center = CenterParent;
    }
    if (ParentCenter) {
      parentCenter = ParentCenter;
    }
    if (Country) {
      country = Country;
    }
    if (StateProvince) {
      stateProvince = StateProvince;
    }
    if (Staff) {
      staff = Staff;
    }
    if (ClinicalCenter) {
      clinicalCenter = ClinicalCenter;
    }
    if (ListPlaceOfServiceCenter) {
      listPlaceOfServiceCenter = ListPlaceOfServiceCenter;
    }
    if (ListCWBundle) {
      listCWBundle = ListCWBundle;
    }
    if (ListMedicalProgram) {
      listMedicalProgram = ListMedicalProgram;
    }
    if (signaturetypessupported) {
      Signaturetypessupported = signaturetypessupported;
    }
    if (TimeZone) {
      timeZone = TimeZone;
    }
    if (RCM_Program) {
      Rcm_Program = RCM_Program;
    }
    if (Filter_StateProvince) {
      Filter_State = Filter_StateProvince[countryISO];
    }
  }

  // if (get_center_field_by_center && get_center_field_by_center.data) {
  //   centerField = get_center_field_by_center.data[0];
  // }

  return {
    centerServiceType,
    centerPopulationType,
    centerProgramType,
    regions,
    subRegion,
    center,
    country,
    stateProvince,
    Filter_State,
    staff,
    centerName,
    isClinical,
    clinicalCenter,
    ListPlaceOfServiceCenter: listPlaceOfServiceCenter,
    ListCWBundle: listCWBundle,
    ListMedicalProgram: listMedicalProgram,
    Signaturetypessupported,
    isFetching,
    timeZone,
    Rcm_Program,
    parentCenter,
    isSuccess,
    isCenterFieldUpsert,
    countryISO,
    // centerField,
    // isParentSuccess,
    // isParentFetching,
  };
};

const ReduxCenterForm = reduxForm({
  form: 'centerForm',
  enableReinitialize: true,
  onSubmitFail: errors => {
    if (errors) {
      const firstError = Object.keys(errors)[0];
      let el =
        document.querySelector(`[name="${firstError}"]`) ||
        document.getElementById(`${firstError}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  },
})(connect(mapStateToProps)(withRouter(CenterRegForm)));

const selector = formValueSelector('centerForm');

const mapStateToPropsParent = state => {
  const { center_get, center_upsert, center_delete, center_reactivate } = state;
  let centerData = {};
  var isInserted = false;
  var isRemoved = false;
  var isReactivated = false;
  let countryISO = selector(state, 'country') || 'USA';
  if (center_get && center_get.data) {
    centerData = center_get.data;
  }
  if (center_upsert && center_upsert.data && center_upsert.data.success) {
    isInserted = true;
  }
  if (center_delete && center_delete.data && center_delete.data.success) {
    isRemoved = true;
  }
  if (center_reactivate.data && center_reactivate.data.success) {
    isReactivated = true;
  }
  return {
    centerData,
    isInserted,
    isRemoved,
    isReactivated,
    countryISO,
  };
};

export default connect(mapStateToPropsParent)(CenterForm);
