import React from 'react';
import { Tabs, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Fee from './formSteps/bed-management';
import UpcomingAdmissions from './formSteps/upcoming-admissions';
import PageTitle from '../../../Components/PageTitle';

const { TabPane } = Tabs;

class SlidingTabs extends React.Component {
  constructor(props) {
    super(props);
    // props.reset();
    this.state = {
      isProcessed: false,
      tabMapping: {
        0: 'bed-management',
        1: 'upcoming-admissions',
      },
      tabLabels: [
        {
          Name: 'Bed Management',
          Component: props => <Fee {...props} />,
        },
        {
          Name: 'Upcoming Admissions',
          Component: props => <UpcomingAdmissions {...props} />,
        },
      ],

      // activeKey: this.getStudentId() === -1 ? 0 : null,
      activeKey: 0,
      // activeTabs: this.getStudentId() > 0 ? [true, true, true, true] : [false, false, false, false],
      activeTabs: [true, true, true],
      isModelOpen: false,
      isNewSaveModelOpen: false,
      isDeleteModelOpen: false,
      deleteModelMessage: null,
      yesCallBack: null,
      showError: false,
      modalVisible: false,
    };
  }
  onTabClick = key => {
    let keyData = parseInt(key);
    let clickedTab = this.state.tabMapping[keyData];
    this.props.history.push(`/cw/bed-management?tab=${clickedTab}`);
    this.setState({
      activeKey: parseInt(key),
    });

    // const { activeTabs } = this.state;
    // if (activeTabs[key]) {
    //   this.setState({ activeKey: key, activeTabs });
    // }
  };

  backButtonImplimentation = nextProps => {
    let upComingTab = new URLSearchParams(nextProps.history.location.search);
    upComingTab = upComingTab.get('tab');
    let upComingTabIndex = Object.values(this.state.tabMapping).indexOf(upComingTab);
    let pastTabs = new URLSearchParams(this.props.location.search);
    pastTabs = pastTabs.get('tab');
    let pastTabsIndex = Object.values(this.state.tabMapping).indexOf(pastTabs);
    if (upComingTabIndex != pastTabsIndex) {
      this.setState({
        activeKey: upComingTabIndex,
      });
    }
  };

  componentWillReceiveProps(nextProps, state) {
    this.backButtonImplimentation(nextProps);
  }

  componentDidMount(props) {
    const { tabMapping } = this.state;

    var params = new URLSearchParams(this.props.location.search);
    var tab = params.get('tab');
    if (tabMapping) {
      this.setState({
        activeKey: Object.values(tabMapping).indexOf(tab),
      });
    }
  }
  render() {
    const { activeKey, studentData, tabLabels, activeTabs } = this.state;
    return (
      <div class="w-100 rcm-panel mt-3">
        <PageTitle TitleText="Bed Management" />
        <div class="main enrollment-page  mb-5">
          <div class="container-fluid px-5">
            <div class="row">
              <div className="mt-3 mb-3 cursor-pointer top-table-search">
                <a class="back-link" onClick={() => this.props.history.goBack()}>
                  <img src="images/back-arrow.svg" /> Back
                </a>
              </div>

              <div class="col-12 mb-5 vertical-tabs pat-tab accounting-tab">
                <Tabs
                  activeKey={String(activeKey)}
                  tabPosition="left"
                  defaultActiveKey="0"
                  onTabClick={this.onTabClick}
                >
                  {tabLabels.map((item, index) => {
                    const { Component, Name } = item;
                    return (
                      <TabPane tab={Name} key={`${index}`} className="tab-content enrollment-form">
                        {activeKey == index && (
                          <Component
                            style={{ padding: '36px 20px 150px 20px' }}
                            //   initialValues={studentData}
                            //   studentId={this.getStudentId()}
                            dispatch={this.props.dispatch}
                            // onCancel={() => this.props.history.goBack()}
                            //   isFetching={isFetching}
                            //   isUpserting={isUpserting}
                            //   updateDeleteModel={this.updateDeleteModel}
                            //   onLastSubmit={onSubmit.bind(this)}
                            //   goToPreviuos={() => this.goToPreviuos(index)}
                            //   onSubmit={onSubmit.bind(this)}
                            enableReinitialize={true}
                            //   keepDirtyOnReinitialize={true}
                            //   disable={this.getDischarged()}
                          />
                        )}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect()(SlidingTabs));
