import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getAppSubscribers,
  listCombos,
  generateCallToken,
  getBroadcastFilter,
} from '../store/actions';
import {
  Table,
  Checkbox,
  Radio,
  Typography,
  Modal,
  Avatar,
  Divider,
  Tooltip,
  Popover,
  Button,
  Drawer,
} from 'antd';
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { ReduxSelect } from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import _blank from '../images/blank-profile.png';
import PageTitle from '../Components/PageTitle';
import ReduxBroadcaseInvitationModal from './customScreens/broadcastInvitation';
import SendAppInviteReduxModal from './customScreens/sendMobileAppInvitation';

import Axios from 'axios';
import AntdModal from '../Components/CustomModal';
import { Device } from '@twilio/voice-sdk';
import CustomModal2 from '../core/modal';
import Twilio from '../Components/twilio';
import DynamicGrid from '../Components/DynamicGrid';
import { MobileAdvisor } from '../app-config/screenName';
import CloseIcon from '../Components/CloseIcon.js';

const CheckboxGroup = Checkbox.Group;

const options = [
  { Value: null, Text: 'Please Select' },
  { Value: 'Enrolled', Text: 'All' },
  { Value: 'Graduated', Text: 'Graduated' },
  { Value: 'Discharged', Text: 'Discharged' },
  { Value: 'Active', Text: 'Active' },
];

const plainOptions = ['Graduated', 'Discharged', 'Active'];
class FilterModal extends Component {
  constructor(props) {
    super(props);
    const { checked, startDate, endDate, filterCenterId } = props;
    this.state = {
      checked: checked || 'Enrolled',
      startDate: startDate || null,
      endDate: endDate || null,
      filterCenterId: filterCenterId || null,
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  handleCenterChange = filterCenterId => {
    this.setState({ filterCenterId });
  };

  resetState = () => this.setState({ checked: '', startDate: '', endDate: '', filterCenterId: '' });

  render() {
    const { onOk, onCancel, Centers, refreshGrid } = this.props;
    const { startDate, endDate, filterCenterId, checked } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
      </>
    );
    const ExampleCustomInput2 = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">End date</label>
      </>
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold d-flex ">Status</span>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mr-2 mb-2">
                  <Radio.Group name="radiogroup" onChange={this.onChange} value={checked}>
                    <Radio value="Enrolled">All</Radio>
                    <Radio value="Graduated">Graduated</Radio>
                    <Radio value="Discharged">Discharged</Radio>
                    <Radio value="Active">Active</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Date</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => this.handleChange('startDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => this.handleChange('endDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput2 />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold "> Centers</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={Centers}
                  onChange={this.handleCenterChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterCenterId}
                  placeholder="Centers"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              // onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
          <button
            type="button"
            class="btn"
            onClick={() => {
              onOk();
              refreshGrid(checked, startDate, endDate, filterCenterId);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

class AppSubscribers extends Component {
  constructor(props) {
    super(props);
    var param = new URLSearchParams(this.props.history.location.search);
    let filter = param.get('filter');
    this.state = {
      searchStr: null,
      filterType: this.getFilterType(),
      subscribers: [],
      startDate: null,
      endDate: null,
      filterCenterId: null,
      userFilter: filter ? filter : 'All',
      title: 'App Subscribers',
      twilioToken: 'Fetching Token ....',
      callModelVisible: false,
      clickedRecord: {},
      inviteFormModal: false,
    };
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  resetIsOpen = () => {
    this.setState({
      // twilioToken: '',
      callModelVisible: false,
      clickedRecord: {},
      // device: '',
    });
  };

  onFilterChange = value => {
    this.props.dispatch(
      getAppSubscribers({
        json: JSON.stringify({
          AppUser: [
            {
              FilterBy: value,
              SearchStr: this.state.searchStr,
            },
          ],
        }),
      }),
    );

    this.setState({ userFilter: value });
  };
  getFilterType = () => {
    const { history } = this.props;
    var param = new URLSearchParams(history.location.search);
    return param.get('Filter') || param.get('filter');
  };

  componentDidMount({ searchStr, startDate, endDate, filterCenterId } = this.state) {
    const { dispatch } = this.props;
    // this.props.dispatch(generateCallToken());
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );
    this.props.dispatch(getBroadcastFilter({}));

    this.state.filterType = this.getFilterType();
    // dispatch(
    //   getAppSubscribers({
    //     Filter: this.state.filterType,
    //     searchStr: searchStr,
    //     startDate,
    //     endDate,
    //     filterCenterId,
    //   }),
    // );

    // on Vishnu Reddy request commenting this unwanted API call
    // this.onFilterChange(this.state.userFilter);
  }

  componentWillReceiveProps({
    history: {
      location: { search },
    },
    subscribers,
    userFilter,
  }) {
    // if (subscribers && subscribers.length > 0 ) {
    //   debugger;
    //   this.setState({ subscribers });
    // }
    if (subscribers && subscribers != this.props.subscribers) {
      this.setState({ subscribers });
    }
    if (userFilter && userFilter.length > 0) {
      let filterObj = {};
      if (this.getFilterType()) {
        let filterValue = this.getFilterType();
        filterObj = userFilter.filter(item => {
          return item.Value == filterValue;
        });
        this.setState({ title: filterObj[0].Text });
      }
    }
  }

  refreshGrid = (filterType, startDate, endDate, filterCenterId) => {
    const { searchStr } = this.state;
    this.setState({ checked: filterType, startDate, endDate, filterCenterId });
    this.props.dispatch(
      getAppSubscribers({
        Filter: filterType ? filterType.toString() : null,
        searchStr: searchStr,
        startDate,
        endDate,
        filterCenterId,
      }),
    );
  };

  handleCenterChange = filterCenterId => {
    this.state.filterCenterId = filterCenterId;
    this.refreshGrid();
  };

  render() {
    const { isFetching, Centers } = this.props;

    const {
      subscribers,
      checked,
      startDate,
      endDate,
      filterCenterId,
      title,
      inviteFormModal,
    } = this.state;
    const FilterModalData = { checked, startDate, endDate, filterCenterId };

    return (
      <div class="container-fluid">
        <PageTitle TitleText="Subscriber List" />
        {/* <Loader loading={isFetching} /> */}
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-1 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              {!this.props.isDashboard && (
                <a class="back-link" onClick={() => this.props.history.goBack()}>
                  <img src="images/back-arrow.svg" /> Back
                </a>
              )}

              <h3 class="page-title mb-3 mb-md-0">{title}</h3>
            </div>

            <div class="col-12 col-md-8 col-lg-9  d-flex justify-content-end app-subscriber-page">
              <a
                onClick={() => {
                  this.setState({
                    inviteFormModal: true,
                  });
                }}
              >
                <span style={{ color: '#46b4fe', marginRight: '15px', fontWeight: '600' }}>
                  Send Mobile App Referral
                </span>
              </a>
              <a
                onClick={() => {
                  this.setState({
                    reduxBroadcaseInvitationModal: true,
                  });
                }}
                className="d-flex align-items-center"
              >
                <span style={{ color: '#46b4fe', marginRight: '15px', fontWeight: '600' }}>
                  Broadcast Message
                </span>
              </a>

              <CustomModal
                ModalButton={({ onClick }) => (
                  <input
                    type="button"
                    class="btn black-btn d-none"
                    value="Filter"
                    onClick={onClick}
                  />
                )}
                ModalContent={props => (
                  <FilterModal
                    {...props}
                    {...FilterModalData}
                    refreshGrid={this.refreshGrid}
                    Centers={Centers}
                  />
                )}
              />
            </div>
          </div>
          <div className="dynamic-grid">
            <DynamicGrid ScreenName={MobileAdvisor} />
          </div>
          {this.state.reduxBroadcaseInvitationModal && (
            <Drawer
              open={this.state.reduxBroadcaseInvitationModal}
              // footer={null}
              // centered
              closable={true}
              className="custom-drawer"
              width={950}
              destroyOnClose={true}
              style={{ height: '100vh' }}
              title="Broadcast"
              onClose={() =>
                this.setState({
                  reduxBroadcaseInvitationModal: false,
                })
              }
              placement="right"
              closeIcon={<CloseIcon />}
            >
              <ReduxBroadcaseInvitationModal
                onCancel={() => {
                  this.setState({
                    reduxBroadcaseInvitationModal: false,
                  });
                }}
                isAppSubscriber={true}
              />
            </Drawer>
          )}

          {this.state.inviteFormModal && (
            <Modal
              visible={inviteFormModal}
              footer={null}
              closable={false}
              destroyOnClose={true}
              // onCancel={() => {
              //   this.setState({
              //     inviteFormModal: false,
              //   });
              // }}
            >
              <SendAppInviteReduxModal
                onCancel={() => {
                  this.setState({
                    inviteFormModal: false,
                  });
                }}
                // initialValues={{
                //   CountryCode: 'USA',
                // }}
              />
            </Modal>
          )}

          {this.state.callModelVisible && (
            <Twilio
              isOpen={this.state.callModelVisible}
              // twilioToken={this.state.twilioToken}
              userData={this.state.clickedRecord}
              resetIsOpen={this.resetIsOpen}
            />
          )}

          {/* <CustomModal2
            isOpen={this.state.callModelVisible}
            yesAction={() => {
              this.setState({
                twilioToken: '',
                callModelVisible: false,
                clickedRecord: {},
                device: '',
              });
            }}
            yesText="Close"
            hideOk={true}
            hideCancel={true}
            changeMaxWidth="600px"
            className="px-5"
          >
            <div
              style={{
                width: '100%',
                marginBottom: '30px',
                textAlign: 'center',
                fontWeight: 'bold',
                wordWrap: 'wrap',
              }}
            >
              <h4 class="modal-title col-12 mb-3">{this.state.clickedRecord.Name}</h4>
              <h4 class="modal-title col-12 mb-3">{this.state.clickedRecord.Phone}</h4>
            </div>
            <button
              style={{ background: 'green', marginRight: '25px' }}
              class="btn btn-success"
              onClick={this.handleCallConnect}
            >
              Connect <i class="fas fa-phone"></i>
            </button>

            <button
              style={{ background: 'red' }}
              class="btn btn-danger"
              onClick={this.handleCallDisconnect}
            >
              Disconnect <i class="fas fa-phone-slash"></i>
            </button>
          </CustomModal2> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ get_app_subscribers, combos, get_broadcast_filter }) => {
  var { data, isFetching, error } = get_app_subscribers;
  const { data: filterArray, isFetching: isFilterFetching } = get_broadcast_filter;

  let userFilter = [];
  let filters = [];
  let subData = [];
  if (data && data.AppUser) {
    subData = data.AppUser;
  }
  if (data && data.FilterBy) {
    userFilter = data.FilterBy;
  }
  if (filterArray && filterArray.FilterBy && filterArray.FilterBy.length > 0) {
    filters = (filterArray && filterArray.FilterBy) || [];
  }

  var Centers = [{ Value: null, Text: 'Please Select' }];

  if (combos.data) {
    if (combos.data.Center)
      for (var center of combos.data.Center) {
        Centers.push(center);
      }
  }

  return {
    subscribers: subData || [],
    isFetching,
    Centers: Centers,
    userFilter,
    filters,
  };
};
export default connect(mapStateToProps, null, null, {
  areStatesEqual: (next, prev) => {
    var isEqual = prev.get_app_subscribers === next.get_app_subscribers;
    var isCombosEqual = prev.combos === next.combos;
    return isEqual && isCombosEqual;
  },
})(AppSubscribers);
