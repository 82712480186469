import React from 'react';
// import { DictateCheckbox } from 'react-dictate-button';
import SpeechToText from './SpeechToText';
// import SpeechToText2 from './SpeechToText2';
// import SpeechToTextExperiment from './SpeechToTextExperiment';

const DictationComponent = props => {
  return (
    // <DictateCheckbox
    //   className="my-dictate-checkbox"
    //   type="button"
    //   grammar="#JSGF V1.0; grammar districts; public <district> = Redmond | Bellevue;"
    //   onDictate={props.handleDictate}
    // >
    //   {({ readyState }) => {
    //     switch (readyState) {
    //       case 0:
    //         return <img className="mic-icon" src="./images/mic.png" alt="Start Dictating" />;
    //       case 1:
    //         return <div className="dictate-status">Preparing to Listen</div>;
    //       case 2:
    //         return <div className="dictate-status">Listening...</div>;
    //       case 3:
    //         if (props.getDicatationStatus) props.getDicatationStatus(readyState)();
    //         return <div className="dictate-status">Processing...</div>;
    //       default:
    //         return <div className="dictate-status">Start Dictation</div>;
    //     }
    //   }}
    // </DictateCheckbox>
    <SpeechToText handleDictate={props.handleDictate} />
    // <SpeechToTextExperiment handleDictate={props.handleDictate} />
  );
};

export default DictationComponent;
