import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Switch } from 'antd';
import TextareaAutosize from 'react-autosize-textarea';
import DictationComponent from '../../../../../../Components/DictationComponent';
import ToggleNote from '../toggleNote';

let noteTimer;

class DateType extends React.Component {
  constructor(props) {
    super(props);
    let { Response, Note } = props.survey;
    this.state = {
      dateValue:
        Response && Response === 'Invalid date' ? '' : Response ? moment(Response).toDate() : '',
      noteValue: Note,
      toggleActive: Note ? true : false,
    };
  }
  getDicatationStatus = value => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    let timer;
    return () => {
      clearTimeout(timer);
      setTimeout(() => {
        setValue.call(scope, {
          response: this.state.value,
          note: this.state.noteValue,
          surveyQuestionId: SurveyQuestionId,
          level: level,
          subLevel: subLevel,
          subCatLevel: subCatLevel,
          index: index,
        });
      }, 3000);
    };
  };

  onChange = (event, click) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    const { value } = event.target;
    this.setState({
      noteValue: value,
    });
    clearTimeout(noteTimer);
    noteTimer = setTimeout(() => {
      setValue.call(scope, {
        response: this.state.value,
        note: value,
        surveyQuestionId: SurveyQuestionId,
        level: level,
        subLevel: subLevel,
        subCatLevel: subCatLevel,
        index: index,
      });
    }, 1000);
  };
  getNoteChanges = () => {
    this.setState({ noteValue: '' });
  };
  handleDictate = result => {
    if (result) {
      let data;
      if (this.state.noteValue) {
        data = `${this.state.noteValue} ${result}`;
      } else {
        data = result;
      }
      this.setState({ noteValue: data });
    }
  };
  handleChange = dateValue => {
    this.setState({
      dateValue: dateValue,
    });
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
      AddNote,
    } = survey;
    const ExampleCustomInput = ({ value, onClick }) => {
      return (
        <input
          type="text"
          class="form-control datepicker"
          placeholder="Select Date"
          value={value}
          onClick={onClick}
        />
      );
    };
    return (
      <div className="type-border col-sm-12 col-lg-6">
        <div>
          {Question && Question.length > 0 && (
            <h5 className="survey-question rcm-question text-left mb-0">
              {QuestionNumber && `${QuestionNumber}.`} {renderHTML(Question)}
            </h5>
          )}
          <div class="form-group d-flex align-items-center" style={{ maxWidth: '371px' }}>
            <DatePicker
              showYearDropdown
              disabled={isReadOnly}
              selected={this.state.dateValue}
              placeholder="Select Date"
              dateFormat="dd-MMM-yyyy"
              isClearable
              // dateFormat="MMMM d, yyyy"
              // placeholderText="Select Date"
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              onChange={dateValue => {
                this.handleChange(dateValue);
                setValue.call(scope, {
                  response: dateValue ? moment(dateValue).format('YYYY-MM-DD') : null,
                  note: this.state.noteValue,
                  surveyQuestionId: SurveyQuestionId,
                  level: level,
                  subLevel: subLevel,
                  subCatLevel: subCatLevel,
                  index: index,
                });
              }}
              // minDate={new Date()}
            />
          </div>
          {AddNote && (
            <div className="text-left">
              <div className="custom-control" style={{ padding: 'unset' }}>
                <label className="custom-control-label mb-3">
                  <Switch
                    checked={this.state.toggleActive}
                    onClick={() => this.setState({ toggleActive: !this.state.toggleActive })}
                    size="small"
                  />
                  Add Note
                </label>
              </div>
            </div>
          )}
          {AddNote && this.state.toggleActive && (
            <>
              <ToggleNote
                handleDictate={this.handleDictate}
                getDicatationStatus={this.getDicatationStatus}
                noteValue={this.state.noteValue}
                getNoteChanges={this.getNoteChanges}
                onChange={this.onChange}
                isReadOnly={isReadOnly}
              />
            </>
          )}
          {DoesNotApplyText && (
            <div className="not-apply-to-me">
              <a
                name="current-situation"
                value={DoesNotApplyText}
                onClick={onClick.bind(scope, {
                  surveyQuestionId: SurveyQuestionId,
                  response: DoesNotApplyValue,
                  level: level,
                  subLevel: subLevel,
                  subCatLevel: subCatLevel,
                  index: index,
                })}
              >
                {DoesNotApplyText}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default DateType;
