import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Mood extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {}
  render() {
    const { EventJsonData, isFetching } = this.props;
    let { MoodEmoji, MoodType } = EventJsonData.Mood && EventJsonData.Mood[0];
    return (
      <div class="border-0">
        <div class="d-flex justify-content-center flex-column text-center">
          <img src={`images/timeline/emoji/${MoodEmoji}`} height="100" />
          <h5 className="mt-2">{MoodType}</h5>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = ({ get_call_session_video_url }) => {
//   const { data, error, isFetching } = get_call_session_video_url;
//   let VideoCallUrl = '';

//   if (get_call_session_video_url && get_call_session_video_url.data) {
//     VideoCallUrl = data.result;
//   }
//   return {
//     VideoCallUrl,
//     isFetching,
//   };
// };
export default Mood;
