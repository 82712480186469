import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Modal, Radio, Space } from 'antd';
import moment from 'moment';
import Twilio from '../../Components/twilio';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../component/custom-redux-components';
import {
  getAppUserSubscription,
  getServiceOptions,
  getServiceAdvisorList,
  setServiceAdvisor,
  updateCancelService,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import { currencyFormatter2 } from '../../util/formatter';

class ChangeCounselorModal extends Component {
  constructor(props) {
    super(props);
    const { AdvisorGuid } = props.CurrentService;
    this.state = {
      value: AdvisorGuid || null,
      data: {},
    };
  }
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    // this.refreshGrid();
  };
  componentDidMount() {
    let jsonData = {
      UserSubscription: [
        {
          UserGuid: this.props.UserGuid,
          ServiceSubscriptionTypeId: this.props.SubscriptionTypeId,
        },
      ],
    };
    this.props.dispatch(getServiceAdvisorList({ Json: JSON.stringify(jsonData) }));
  }

  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      let jsonData = {
        UserSubscription: [
          {
            UserGuid: this.props.UserGuid,
          },
        ],
      };
      this.props.dispatch(
        getAppUserSubscription({
          Json: JSON.stringify(jsonData),
        }),
      );
      this.props.onCancel();
    }
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };

  render() {
    const { onCancel, advisorList, SubscriptionTypeId, CurrentService } = this.props;
    const { data } = this.state;
    const { Advisor, SubscriptionName, ProfileImageUrl } = CurrentService || {};
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 mb-3">
            {/* Change Counselor */}
            {`${Advisor ? 'Change' : 'Assign'} ${SubscriptionTypeId === 4 ? 'Counselor' : 'Coach'}`}
          </h4>
        </div>

        <div class="modal-body col-md-12 px-5 enrollment-form">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center align-items-center">
                {Advisor ? (
                  <>
                    <div className="d-flex align-items-center flex-column">
                      <img
                        src={ProfileImageUrl || 'images/blank-profile.png'}
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          marginRight: '10px',
                          objectFit: 'cover',
                        }}
                      />
                      <span>{Advisor}</span>
                    </div>
                    <div style={{ minWidth: '50px' }}>
                      <img src="images/icons/left-arrow.svg" style={{ marginRight: '10px' }} />
                    </div>
                    <div className="d-flex align-items-center flex-column">
                      <img
                        src={data.ProfileImageUrl || 'images/blank-profile.png'}
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          marginRight: '10px',
                          objectFit: 'cover',
                        }}
                      />
                      <span>{data.DisplayName}</span>
                    </div>
                  </>
                ) : (
                  <div className="d-flex align-items-center flex-column">
                    <img
                      src={data.ProfileImageUrl || 'images/blank-profile.png'}
                      style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    <span>{data.DisplayName ? data.DisplayName : ` `}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="text-center">
                <span style={{ color: '#828282', fontSize: '18px' }}>
                  Please select a {SubscriptionTypeId === 4 ? 'Counselor' : 'Coach'}
                </span>
              </div>
              <div class="search-box d-inline-flex w-100" style={{ maxWidth: 'unset' }}>
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control"
                    placeholder={`Search ${SubscriptionTypeId === 4 ? 'Counselor' : 'Coach'}...`}
                    // onChange={this.onSearchChange}
                    style={{ paddingLeft: '10px' }}
                  />

                  <button class="btn search-icon " type="button">
                    {' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="#000"
                        fill-rule="nonzero"
                        d="M6 14.203c0 1.425.371 2.828 1.073 4.06a.781.781 0 1 0 1.357-.775 6.639 6.639 0 0 1-.868-3.285 6.648 6.648 0 0 1 6.641-6.64 6.648 6.648 0 0 1 6.64 6.64 6.642 6.642 0 0 1-9.89 5.793.781.781 0 1 0-.766 1.362 8.208 8.208 0 0 0 4.016 1.048c1.972 0 3.783-.7 5.2-1.863l5.265 5.23a.779.779 0 0 0 1.105-.004.781.781 0 0 0-.004-1.105l-5.258-5.222a8.165 8.165 0 0 0 1.895-5.239C22.406 9.68 18.726 6 14.203 6S6 9.68 6 14.203z"
                      />
                    </svg>{' '}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12" style={{ height: '250px', overflowY: 'scroll' }}>
              <div className="row">
                {advisorList &&
                  advisorList.map((item, index) => {
                    return (
                      <div className="col-12 mb-2" key={index}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Radio
                              onChange={() => {
                                this.setState({
                                  value: item.UserGuid,
                                  data: item,
                                });
                              }}
                              name={item.UserGuid}
                              value={item.UserGuid}
                              checked={item.UserGuid === this.state.value ? true : false}
                            />
                            <img
                              src={item.ProfileImageUrl || 'images/blank-profile.png'}
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                marginRight: '10px',
                                objectFit: 'cover',
                              }}
                            />
                            <div className="d-flex flex-column">
                              <span className="name" style={{ fontSize: '20px', color: '#46B4FE' }}>
                                {item.DisplayName}
                              </span>
                              <span className="info-text">Teen challenge center</span>
                            </div>
                          </div>
                          <div>
                            <span
                              title={`Call ${item.DisplayName}`}
                              style={{
                                cursor: 'pointer',
                                width: '125px',
                                marginRight: '10px',
                                color: '#46B4FE',
                              }}
                              onClick={() => {
                                this.setState({
                                  callModelVisible: true,
                                  clickedRecord: {
                                    Name: item.DisplayName,
                                    Phone: item.Phone,
                                    ProfileImageUrl: item.ProfileImageUrl,
                                  },
                                });
                              }}
                            >
                              {item.Phone}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {this.state.callModelVisible && (
            <Twilio
              isOpen={this.state.callModelVisible}
              userData={this.state.clickedRecord}
              resetIsOpen={this.resetIsOpen}
            />
          )}
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <button type="button" class="btn btn-outline-primary" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            class="btn"
            onClick={() => {
              let jsonData = {
                UserSubscription: [
                  {
                    AppUserGuid: this.props.UserGuid,
                    AdvisorUserGuid: this.state.value,
                  },
                ],
              };
              this.props.dispatch(setServiceAdvisor({ Json: JSON.stringify(jsonData) }));
            }}
            disabled={!this.state.value}
          >
            Assign {SubscriptionTypeId === 4 ? 'Counselor' : 'Coach'}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { get_service_advisor_list, set_service_advisor } = state;
  const { data, isFetching } = get_service_advisor_list;

  let advisorList = null;
  let isSuccess = false;
  if (data && data.UserSubscription) {
    advisorList = data.UserSubscription;
  }
  if (set_service_advisor.data && set_service_advisor.data.success) {
    isSuccess = true;
  }

  return {
    advisorList,
    isFetching: isFetching,
    isSuccess,
  };
};

export default connect(mapStateToProps)(withRouter(ChangeCounselorModal));
