import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import moment from 'moment';
import validate from '../validation/validate';
import {
  ReduxSelect,
  ReduxInput,
  FrequencyReduxSelect,
  ReduxToggle,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { number } from 'prop-types';

const PriorAbuse = ({
  fields,
  name,
  meta: { touched, error },
  PriorAbuseType,
  PriorAbuseRelationship,
  Gender,
  AbuseFrequency,
  existingRows,
  disable,
  dateofBirth,
}) => {
  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Prior Abuse {index + 1}</h6>
              </div>
              <div class={`col-12 col-md-4`}>
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete Abuse Experience
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.priorAbuseTypeId`}
                    placeholder="Type of Abuse*"
                    component={ReduxSelect}
                    options={PriorAbuseType}
                    validate={validate.required}
                    fieldName={'Type of Abuse'}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows[index].priorAbuseTypeId == 5 ? (
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name={`${item}.priorAbuseTypeOther`}
                      component={ReduxInput}
                      fieldName={'Prior Abuse Type'}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Describe Type Of Abuse</label>
                  </div>
                </div>
              ) : null}
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.ageWhenAbused`}
                    type="number"
                    component={ReduxInput}
                    fieldName={' '}
                    disabled={disable}
                    min={1}
                    max={Number(
                      moment(`${dateofBirth}`, 'MM/DD/YYYY')
                        .month(0)
                        .from(moment().month(0))
                        .split(' ')[0] || 99,
                    )}
                    controlledMaxAge={true}
                    validate={value => {
                      let maxAge = Number(
                        moment(`${dateofBirth}`, 'MM/DD/YYYY')
                          .month(0)
                          .from(moment().month(0))
                          .split(' ')[0] || 99,
                      );
                      if (value) {
                        if (value > maxAge) {
                          return `Age can not be greater than present age`;
                        }
                        if (value < 1) {
                          return `Age can not be less than one`;
                        }
                      }
                    }}
                  />
                  <label class="placeholder-label">Your Age (In Years) When Abused?</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.abusedByRelationshipId`}
                    type="text"
                    placeholder="Who Abused You?"
                    component={ReduxSelect}
                    options={PriorAbuseRelationship}
                    fieldName={'Who Abused You?'}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows[index].abusedByRelationshipId == 10 ? (
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name={`${item}.abusedByRelationshipOther`}
                      type="text"
                      errorMessage="Field required"
                      component={ReduxInput}
                      fieldName={'Abused By Relationship'}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Relation with Abuser</label>
                  </div>
                </div>
              ) : null}

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.abuserAge`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Abuser Age'}
                    disabled={disable}
                    min={1}
                    max={99}
                    negativeNumberBlocked={true}
                  />
                  <label class="placeholder-label">Age of Abuser (In Years) When Abused?</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.abuserGender`}
                    type="text"
                    placeholder="Gender of Abuser*"
                    component={ReduxSelect}
                    options={Gender}
                    fieldName={'Gender of Abuser'}
                    validate={validate.required}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.howOften`}
                    type="text"
                    placeholder="How often did the abuse occur?"
                    component={FrequencyReduxSelect}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows[index].howOften === 2 && (
                <>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field name={`${item}.howOftenActual`} type="number" component={ReduxInput} />
                      <label class="placeholder-label">How long did the abuse last?</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${item}.abuseFrequencyCode`}
                        type="text"
                        placeholder="Abuse Frequency"
                        component={ReduxSelect}
                        options={AbuseFrequency}
                        disabled={disable}
                      />
                    </div>
                  </div>
                </>
              )}

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${item}.notes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" onClick={() => fields.push({})} disabled={disable}>
            {fields.length >= 1 && (
              <label>
                {' '}
                <a>+ Add Another Abuse Experience</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                {' '}
                <a>+ Add Abuse Experience</a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

const PriorAbuses = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    PriorAbuseType,
    PriorAbuseRelationship,
    AbuseFrequency,
    Gender,
    style,
    isAbused,
    existingRows,
    goToPreviuos,
    disable,
    dateofBirth,
    currentTab,
    tabsCount,
    onLastSubmit,
    handleSubmitAndNext,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">
            Have You Experienced Any Abuse: Physical , Mental , Emotional Or Sexual?
          </h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isAbused" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      {isAbused && (
        <FieldArray
          name="priorAbuses"
          component={PriorAbuse}
          existingRows={existingRows}
          PriorAbuseType={PriorAbuseType}
          PriorAbuseRelationship={PriorAbuseRelationship}
          AbuseFrequency={AbuseFrequency}
          Gender={Gender}
          disable={disable}
          dateofBirth={dateofBirth}
        />
      )}
      <div class="row pagination-button">
        <div class="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              class="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  var isAbused = selector(state, 'isAbused');
  var existingRows = selector(state, 'priorAbuses');
  var dateofBirth = selector(state, 'dateofBirth');
  return {
    PriorAbuseType: combos.data.PriorAbuseType,
    PriorAbuseRelationship: combos.data.PriorAbuseRelationship,
    Gender: combos.data.Gender,
    AbuseFrequency: combos.data.AbuseFrequency,
    isAbused,
    existingRows,
    dateofBirth,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(PriorAbuses),
);

const selector = formValueSelector('wizard');
