import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal from '../../../../core/modal';
import Icon from '@ant-design/icons';

import moment from 'moment';

import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { listServiceCodeBundle, getServiceCodeBundle } from '../../../../store/actions';
import ClaimFieldForm from './customScreens/claimFieldForm';
import { ProcessCombos } from '../../../../store/comboConfig';
import PersistTableSort from '../../../../component/persistTableSort';

class BundleService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineData: {},
      tabView: false,
      columns: [
        {
          title: 'Patient ID',
          dataIndex: 'ClientId',
          key: 'ClientId',
          sorter: (a, b) => isNumericComparer(a.ClientId, b.ClientId),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Patient Name',
          dataIndex: 'ClientName',
          key: 'ClientName',
          sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Provider',
          dataIndex: 'PractitionerName',
          key: 'PractitionerName',
          sorter: (a, b) => isComparer(a.PractitionerName, b.PractitionerName),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Bundled Code',
          dataIndex: 'ServiceCode',
          key: 'ServiceCode',
          sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Start Date',
          dataIndex: 'StartDate',
          key: 'StartDate',
          sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'End Date',
          dataIndex: 'EndDate',
          key: 'EndDate',
          sorter: (a, b) => isDateComparer(a.EndDateForSort, b.EndDateForSort),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        // {
        //   title: 'Bundled Cost',
        //   dataIndex: 'BundleCost',
        //   key: 'BundleCost',
        //   sorter: (a, b) => isNumericComparer(a.BundleCost, b.BundleCost),
        //   sortDirections: ['descend', 'ascend'],
        //   // width: 150,
        // },

        {
          title: 'Action',
          render: (text, record, index) => (
            <div class="tools-grid">
              <>
                <span
                  className="start-btn"
                  onClick={() => {
                    props.dispatch(
                      getServiceCodeBundle({
                        json: JSON.stringify({
                          ServiceCodeBundle: [
                            {
                              ServiceLineId: record.ServiceLineId,
                            },
                          ],
                        }),
                      }),
                    );
                    this.setState({
                      tabView: false,
                      ServiceLineId: record.ServiceLineId,
                    });
                  }}
                >
                  Manage
                </span>
              </>
            </div>
          ),
          // width: 300,
        },
      ],
    };
  }
  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = -1;
    }
    return groupId;
  }
  toggleView = getLatest => {
    this.setState(prevState => ({
      tabView: !prevState.tabView,
    }));
    // if (getLatest) {
    //   this.props.dispatch(
    //     listSurveyQuestionCategory({
    //       json: JSON.stringify({
    //         Survey: [
    //           {
    //             ClientId: this.props.clientId,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  };
  componentDidMount() {
    this.props.dispatch(listServiceCodeBundle({}));
    // var result = ProcessCombos('RCM_ServiceCode,Supervisor,UnitOfMeasurement');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
  }
  componentWillReceiveProps({ ServiceLineData, isCreated }) {
    if (
      ServiceLineData &&
      JSON.stringify(ServiceLineData[0]) != JSON.stringify(this.props.ServiceLineData[0])
    ) {
      let data = {
        ...ServiceLineData[0],
        ServiceCodeId: ServiceLineData[0] && parseInt(ServiceLineData[0].ServiceCodeId),
      };
      this.setState({
        ServiceLineData: data,
        tabView: false,
      });
    }
    // if (isCreated && isCreated != this.props.isCreated) {
    //   this.props.dispatch(
    //     getGroupServiceLine({
    //       json: JSON.stringify({
    //         GroupServiceLine: [
    //           {
    //             GroupAppointmentId: this.state.GroupAppointmentId,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  }

  render() {
    const { columns, tabView, ServiceLineData } = this.state;
    const { isFetching, ListbundleCode } = this.props;

    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page">
          {tabView ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 px-0">
                  <div className="border-0">
                    <div className="">
                      <div className="row px-0 mt-5 d-flex align-items-center">
                        <div className="col-6 px-0">
                          {' '}
                          <h4 className="">Bundled Service </h4>
                        </div>
                        <div className="col-6 px-0 d-flex align-items-center">
                          <div className="search-box d-inline-flex w-100">
                            <div className="form-group has-search w-100 d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control 
                   shadow-sm rcm-document-search"
                                placeholder="Search"
                                id="filterbox"
                                // onChange={this.onSearchChange}
                              />
                              <button className="btn search-icon " type="button">
                                {' '}
                                <i className="fa fa-search" />{' '}
                              </button>
                            </div>
                          </div>
                          {/* <button class="rcm-sub-tab-btn">Search</button> */}
                        </div>
                      </div>
                    </div>
                    <div className="row pt-5 pb-5 rcm-subtab-table-block">
                      <div className="col-12">
                        <Loader2 loading={isFetching} />
                        <PersistTableSort
                          name="paper-claims"
                          columns={columns}
                          dataSource={ListbundleCode}
                          size={'middle'}
                          // onRow={(record, rowIndex) => {
                          //   return {
                          //     onClick: event => this.onRowClick(record.ClientId),
                          //   };
                          // }}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div className="dataTables_wrapper no-footer">
                                  <div className="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a className="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a className="paginate_button next">Next</a>
                                    ) : (
                                      <a className="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                          }}
                          // scroll={{ x: 1500, y: 300 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid px-0">
              <ClaimFieldForm
              // initialValues={ServiceLineData}
              // ServiceLineData={ServiceLineData}
              // isCreated={this.props.isCreated}
              // toggleView={this.toggleView}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  list_service_code_bundle,
  get_group_service_line,
  upsert_service_line,
}) => {
  const { data: bundleCodeData, isFetching } = list_service_code_bundle;
  const { data: getServiceLineData } = get_group_service_line;

  let ListbundleCode = [];
  let ServiceLineData = [];
  let isCreated = false;

  if (bundleCodeData && bundleCodeData.ServiceCodeBundle) {
    ListbundleCode = bundleCodeData.ServiceCodeBundle || [];
  }
  if (getServiceLineData && getServiceLineData.GroupServiceLine) {
    ServiceLineData = getServiceLineData.GroupServiceLine || [];
  }
  if (upsert_service_line && upsert_service_line.data && upsert_service_line.data.success) {
    isCreated = upsert_service_line.data.success;
  }
  return {
    isCreated,
    isFetching,
    ListbundleCode,
    ServiceLineData: ServiceLineData,
  };
};

export default connect(mapStateToProps)(withRouter(BundleService));
