import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPassword } from '../store/actions';
import { ReduxInput } from '../component/custom-redux-components';
import { Field, reduxForm } from 'redux-form';
import CustomModal from '../core/modal';
import hostGrabber from '../util/hostGrabber';

const Validator = {
  required: value => (value ? undefined : 'Field Required'),
  password: value => {
    if (value) {
      // if (!/^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[#$^+=!*()@%&]).{8,}$/g.test(value))
      // ^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$
      //below commented on 10 may 21
      // if (
      //   !/^((?=.*[a-z]){1})((?=.*[A-Z]){1})(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/g.test(
      //     value,
      //   )

      if (
        !/^((?=.*[a-z]){1})((?=.*[A-Z]){1})(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/g.test(
          value,
        )
      ) {
        return '. The Password does not meet the password policy. Please ensure your password has minimum 8 characters which includes an upper case letter, a number and a special character or symbol';
      } else {
        return undefined;
      }
    } else {
      return 'field required';
    }
  },
  confirmPassword: (value, values) => {
    if (value) {
      if (value != values.password) {
        return 'and password must be same';
      }
      return undefined;
    } else {
      return 'field required';
    }
  },
};

let ChangePasswordForm = ({ onSubmit, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} class="login-form enrollment-form">
      <div class="form-group mb-4">
        <Field
          name="password"
          className="form-control"
          type="password"
          component={ReduxInput}
          validate={Validator.password}
          fieldName="Password"
        />
        <label class="placeholder-label">Password</label>
      </div>
      <div class="form-group mb-4">
        <Field
          name="confirmPassword"
          className="form-control"
          type="password"
          component={ReduxInput}
          validate={Validator.confirmPassword}
          fieldName="Confirm Password"
        />
        <label class="placeholder-label"> Confirm Password</label>
      </div>
      <button class="btn btn-login" type="submit">
        {' '}
        SUBMIT{' '}
      </button>
    </form>
  );
};

const ReduxChangePasswordForm = reduxForm({
  form: 'ReduxChangePasswordForm',
})(ChangePasswordForm);

class ResetPass extends Component {
  state = {
    username: '',
    password: '',
    errorMessage: '',
    modal: '',
    content: '',
  };

  componentWillReceiveProps({ isSuccess, errorMessage }) {
    if (isSuccess) {
      this.setState({
        modal: true,
        content: `<div className="hipa-agreement mar-top-15">Password changed successfully </div>`,
      });
    }
    if (errorMessage) {
      alert(errorMessage);
    }
  }

  okMethod = () => {
    this.props.history.push('/login');
  };

  cancelMethod = () => {
    localStorage.clear();
    window.location.reload();
  };

  onPasswordChange = values => {
    this.props.dispatch(
      resetPassword({
        password: values.password,
        resetPasswordIdentifier: this.props.match.params.id,
      }),
    );
  };

  render() {
    return (
      <React.Fragment>
        <div class="login-page">
          <div class="container-fluid">
            <div class="col-12 col-md-5 col-lg-4 offset-md-1">
              <div class="login-box-main">
                <figure class="login-logo">
                  <img
                    src={hostGrabber() ? 'images/youu_Logo.svg' : 'images/logo.svg'}
                    width={hostGrabber() ? '250' : '120'}
                    alt=""
                  />
                </figure>
                <ReduxChangePasswordForm onSubmit={this.onPasswordChange} />
                <CustomModal
                  isOpen={this.state.modal}
                  header={this.state.content}
                  yesAction={this.okMethod}
                  yesText="OK"
                  noText="Cancel"
                  noAction={this.cancelMethod}
                  hideCancel={true}
                />
                <div class="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reset_password }) => {
  const { data, error } = reset_password;

  var isSuccess = false;
  var errorMessage = '';

  if (data) {
    if (data.success) {
      isSuccess = true;
    }

    if (data.error) {
      errorMessage = data.error;
    }
  }

  return {
    isSuccess: isSuccess,
    errorMessage: errorMessage,
  };
};

export default connect(mapStateToProps)(ResetPass);
