import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import DownloadDocument from '../../../../../customScreens/downloadDocument';
const DocumentsList = props => {
  const { smartContractId, clientId, UploadedFiles } = props;
  return (
    <div className="uploaded-documents-list p-3">
      <span className="title mb-3">Documents</span>
      {UploadedFiles &&
        UploadedFiles.map((uploaded, index) => {
          const {
            DocumentName,
            DocUploadedDate,
            Note,
            RequestedBy,
            RequestedOn,
            UploadedFileFullName,
            DocumentGuid,
            IsPayerRequested,
          } = uploaded;
          const fileExtension =
            UploadedFileFullName &&
            UploadedFileFullName.substr(UploadedFileFullName.lastIndexOf('.') + 1);

          return (
            <div className="list-item">
              <div className="doc-info">
                <div className="info">
                  <span className="label-text">Document</span>
                  <span className="value">{DocumentName}</span>
                </div>
                {IsPayerRequested && (
                  <div className="info">
                    <span className="label-text">Requested By(Payer)</span>
                    <span className="value">{RequestedBy}</span>
                  </div>
                )}

                {/* <div className="info">
                  <span className="label-text">Requested on</span>
                  <span className="value">{RequestedOn}</span>
                </div> */}
              </div>
              <div className="doc-action">
                <div className="doc-link">
                  <DownloadDocument
                    ID={clientId}
                    documentGuid={DocumentGuid}
                    downloadText={UploadedFileFullName}
                    isClinical={true}
                    isReadOnlyUser={props.isReadOnlyUser}
                    extension={fileExtension}
                    allowDownload={true}
                    isHelperIcon={true}
                    downloadIcon={false}
                  />
                </div>
                <div className="info">
                  <span className="label-text" style={{ color: '#959595' }}>
                    Uploaded on {DocUploadedDate}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DocumentsList;
