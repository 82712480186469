import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Checkbox, Radio, Pagination } from 'antd';
import Loader, { Loader2 } from '../../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { Tag, Modal, Tooltip, Avatar } from 'antd';
import _blank from '../../images/blank-profile.png';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';
import ExportExcel from '../../Components/ExportExcel';
import BreathlyzerVideoModal from '../PopupModals/BreathlyzerVideoModal';
import { ReduxSelect } from '../../component/custom-redux-components/index.js';
import { getMediaVideoUrl, getBreathalyzerTestResult } from '../../store/actions';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

class TestHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewVideoModal: false,
      BreathalyzerData: {},
      columns: [
        // {
        //   title: 'Image',
        //   dataIndex: 'ProfileImgUrl',
        //   key: 'ProfileImgUrl',
        //   render: (text, record) => (
        //     <div class="student-list-pic">
        //       <img
        //         src={text ? text : 'images/announcements-img2.jpg'}
        //         class="img-circle"
        //         height="40"
        //         width="40"
        //         style={{ borderRadius: '50%', objectFit: 'cover' }}
        //       />
        //     </div>
        //   ),
        // },
        {
          title: 'Test Date',
          dataIndex: 'TestDate',
          key: 'TestDate',
          isPrint: true,
          sorter: (a, b) => isDateComparer(a.TestDateForSort, b.TestDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Time',
          dataIndex: 'TestTime',
          key: 'TestTime',
          isPrint: true,
          sorter: (a, b) => isComparer(a.TestTime, b.TestTime),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Location',
          dataIndex: 'TestLocation',
          key: 'TestLocation',
          isPrint: true,
          sorter: (a, b) => isComparer(a.TestLocation, b.TestLocation),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => {
            return record.Latitude != null || record.Longitude != null ? (
              <>
                <a
                  href={`http://www.google.com/maps/place/${record.Latitude},${record.Longitude}`}
                  target={_blank}
                >
                  {'Map'}
                </a>
              </>
            ) : (
              <></>
            );
          },
        },
        {
          title: 'Result',
          dataIndex: 'Result',
          key: 'Result',
          isPrint: true,
          sorter: (a, b) => isComparer(a.Result, b.Result),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => (
            <>
              <span className={record.ResultClassBlock}>{text}</span>
            </>
          ),
        },
        {
          title: 'Remarks',
          dataIndex: 'TestTakenText',
          key: 'TestTakenText',
          isPrint: true,
          sorter: (a, b) => isComparer(a.TestTakenText, b.TestTakenText),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => (
            <>
              <span>{text}</span>
            </>
          ),
        },
        {
          title: 'Action',
          key: 'x',
          isPrint: false,
          render: (text, record, index) => {
            return (
              <>
                {record.IsTestTaken && (
                  <div class="tools-grid d-flex align-items-center">
                    {/* <a target="_blank" href={record.MediaUrl}>
                  <img src="images/icons/play_grey.svg" alt="download" width="30" />
                </a> */}
                    {record.MediaGuid ? (
                      <img
                        src="images/icons/play_grey.svg"
                        alt="download"
                        width="30"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({
                            viewVideoModal: true,
                            //   MediaGuid: record.MediaGuid,
                          });
                          this.props.dispatch(
                            getMediaVideoUrl({
                              Json: JSON.stringify({
                                Media: [
                                  {
                                    MediaGuid: record.MediaGuid,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      />
                    ) : (
                      <>Video not available</>
                    )}
                  </div>
                )}
              </>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(
      getBreathalyzerTestResult({
        Json: JSON.stringify({
          Breathalyzer: [
            {
              UserGuid: this.props.UserGuid,
            },
          ],
        }),
      }),
    );
  }
  exportPdf = (columns, rowData, DisplayName) => {
    const doc = new jsPDF();

    // let headData = [['Name', 'Email', 'Country']];
    let colItem = columns.map(item => {
      if (item.isPrint) {
        return item.title;
      } else return;
    });
    let rowItem = rowData.map(item => {
      return [item.TestDate, item.TestTime, item.TestLocation, item.Result, item.TestTakenText];
    });

    let headData = [colItem];
    let bodyData = rowItem;
    // let bodyData = [
    //   // ['David', 'david@example.com', 'Sweden'],
    //   // ['Castille', 'castille@example.com', 'Spain'],
    // ];
    // doc.setFontSize(18);
    // doc.text('With content With content With content With content ', 14, 22);
    // doc.setFontSize(11);
    // doc.setTextColor(100);

    var pageSize = doc.internal.pageSize;
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    var textString = `Client Name : ${DisplayName}`;
    var text = doc.splitTextToSize(textString, pageWidth - 35, {});
    doc.text(text, 12, 20);
    doc.text('Timeline', 12, 30);
    doc.autoTable({
      head: headData,
      body: bodyData,
      startY: 35,
      showHead: 'firstPage',
      theme: 'striped',
    });
    doc.save('Reports.pdf');
  };
  render() {
    const { viewVideoModal, MediaGuid, BreathalyzerResults } = this.state;
    const { isFetching, UserGuid, DisplayName, BreathalyzerTestResults, FilterBy } = this.props;
    // const { UserGuid, DisplayName } = BreathalyzerData || {};
    const isListData = BreathalyzerTestResults && BreathalyzerTestResults.length > 0;
    return (
      <div>
        <Loader2 loading={isFetching} />
        <div className="row align-items-center">
          <div class="col-6 pb-2">
            {isListData && (
              <div
                className="excel-download"
                onClick={() => {
                  this.exportPdf(this.state.columns, BreathalyzerTestResults, DisplayName);
                }}
              >
                <span className="mr-2">{'Report'}</span>
                <img className="mr-2" src="images/pdf.png" width="25" alt="Export Excel" />
              </div>
            )}
          </div>
          <div class="col-2"></div>
          <div class="col-4">
            <div class="form-group dropdown-bg">
              <ReduxSelect
                options={FilterBy}
                onChange={val => {
                  this.props.dispatch(
                    getBreathalyzerTestResult({
                      Json: JSON.stringify({
                        Breathalyzer: [
                          {
                            UserGuid: UserGuid,
                            FilterBy: val,
                          },
                        ],
                      }),
                    }),
                  );
                  this.setState({
                    filterBy: val,
                  });
                }}
                className="basic-single"
                classNamePrefix="select"
                value={this.state.filterBy}
                placeholder="Filter By"
              />
            </div>
          </div>
        </div>
        {isListData ? (
          <div className="row align-items-center">
            <div class="col-12 pb-5">
              <div style={{ height: '276px', overflowY: 'scroll' }} className="time-line-table">
                <Table
                  columns={this.state.columns}
                  dataSource={BreathalyzerTestResults}
                  size={'middle'}
                  pagination={false}
                />
              </div>
            </div>
            {viewVideoModal && (
              <Modal
                zIndex={200}
                visible={viewVideoModal}
                footer={null}
                closable={false}
                destroyOnClose={true}
              >
                <BreathlyzerVideoModal
                  //   MediaGuid={MediaGuid}
                  onCancel={() => {
                    this.setState({
                      viewVideoModal: false,
                    });
                  }}
                />
              </Modal>
            )}
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex align-items-center justify-content-center"
              style={{ height: '276px' }}
            >
              <h5 className="text-center">No data</h5>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToPropsHistory = ({ get_breathalyzer_test_result }) => {
  const { data: BreathalyzerTestData, error, isFetching } = get_breathalyzer_test_result;
  let BreathalyzerTestResults = null;
  let FilterBy = [];

  if (BreathalyzerTestData && BreathalyzerTestData.BreathalyzerTestResults) {
    BreathalyzerTestResults = BreathalyzerTestData.BreathalyzerTestResults || [];
  }
  if (BreathalyzerTestData && BreathalyzerTestData.FilterBy) {
    FilterBy = BreathalyzerTestData.FilterBy || [];
  }

  return {
    BreathalyzerTestResults: BreathalyzerTestResults,
    FilterBy: FilterBy,
    isFetching,
  };
};

export default connect(mapStateToPropsHistory)(TestHistory);
