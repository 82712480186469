import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { Button } from 'antd';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../component/custom-redux-components';
import AnswerOptions from './AnswerOptions';
import Validator from '../../Validation';

class ManageQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      handleSubmit,
      disable,
      onCancel,
      dispatch,
      initialValues,
      change,
      surveyQuestion,
      handleNewQuestion,
      questionFormatValue,
      questionFormValue,
    } = this.props;
    const { SurveyQuestionSection, SurveyQuestionType } = surveyQuestion || {};
    return (
      <form onSubmit={handleSubmit(handleNewQuestion)}>
        <div class="rcm-panel enrollment-form manage-new-question">
          <div class="row mb-4">
            <div className="col-12">
              <h6 class="d-inline-flex mb-0 mr-3 ">Category</h6>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group">
                <Field
                  name={`Category`}
                  type="text"
                  component={ReduxSelect}
                  fieldName={'Category'}
                  disabled={disable}
                  options={SurveyQuestionSection}
                  validate={Validator.required}
                  placeholder="Category*"
                />
              </div>
            </div>
            <div className="col-12">
              <h6 class="d-inline-flex mb-0 mr-3 ">Question</h6>
            </div>
            <div class="col-12">
              <div class="form-group mb-1">
                <Field
                  name={`Question`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Question'}
                  validate={Validator.required}
                />
                <label class="placeholder-label">Enter Question*</label>
              </div>
            </div>
            {/* <div class="col-12">
              <div class="form-group">
                <label class="custom-control-label" for="is-mandatory">
                  <Field
                    name={`isQuestionMandatory`}
                    type="text"
                    class="custom-control-input not-empty"
                    component={ReduxToggle}
                  />{' '}
                  Mark as mandatory
                </label>
              </div>
            </div> */}
            <div className="col-12">
              <h6 class="d-inline-flex mb-0 mr-3 ">Question Format</h6>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group">
                <Field
                  name={`QuestionFormat`}
                  type="text"
                  component={ReduxSelect}
                  fieldName={'Question Format*'}
                  disabled={disable}
                  options={SurveyQuestionType}
                  validate={Validator.required}
                  //   placeholder="Question Format"
                />
              </div>
            </div>
            {/* <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group mt-3">
                <label class="custom-control-label" for="isAddNote">
                  <Field
                    name={`isAddNote`}
                    type="text"
                    class="custom-control-input not-empty"
                    component={ReduxToggle}
                  />{' '}
                  Enable Add Note
                </label>
              </div>
            </div> */}
            <div className="col-12">
              <h6 class="d-inline-flex mb-0 mr-3 ">Answer Options</h6>
            </div>
            <div className="col-12">
              <AnswerOptions
                questionFormValue={questionFormValue}
                answerOptions={surveyQuestion}
                questionFormatValue={questionFormatValue}
                change={change}
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-12 col-lg-12 text-right">
              <Button
                loading={this.props.isQuestionUpsertFetching}
                htmlType="submit"
                type="primary"
                className="btn save-button px-5"
                style={{ padding: '7px 20px' }}
                // value={
                //   this.props.SurveyQuestionCategoryConfigId ? ` Update Question` : `Add Question`
                // }
              >
                {this.props.SurveyQuestionCategoryConfigId ? ` Update Question` : `Add Question`}
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const ReduxManageQuestionForm = reduxForm({
  form: 'manageQuestionForm',
  enableReinitialize: true,
})(connect()(withRouter(ManageQuestionForm)));
const selector = formValueSelector('manageQuestionForm');

class ManageNewQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: {
        QuestionFormat: 19,
        isAddNote: true,
        [`options${19}`]: [{}],
      },
    };
  }
  componentDidMount() {
    if (this.props.initData) {
      let question = this.props.initData;
      let optionData = {};
      const textArray = [
        question.Option1Text,
        question.Option2Text,
        question.Option3Text,
        question.Option4Text,
        question.Option5Text,
        question.Option6Text,
        question.Option7Text,
        question.Option8Text,
        question.Option9Text,
        question.Option10Text,
      ];
      if (question.Option1Text) {
        let data = [];
        textArray.map((item, index) => {
          if (item) {
            data.push({
              option: item,
              IsFlagged: question[`Option${index + 1}QF`] === false,
            });
          }
        });
        optionData = {
          [`options${question.SurveyQuestionTypeId}`]: data,
        };
      }
      // let responseThresholds = question.ResponseThreshold && question.ResponseThreshold.split(',');
      // let threshold = null;
      // let threshold1 = null;
      // if (responseThresholds) {
      //   threshold = responseThresholds[0];
      //   threshold1 = responseThresholds.length > 1 ? responseThresholds[1] : null;
      // }

      let data = {
        // ...question,
        SurveyQuestionId: question.SurveyQuestionId,
        Category: question.SurveyQuestionSubCategoryId,
        Question: question.Question,
        isQuestionMandatory: question.IsMandatory,
        isAddNote: question.AddNote,
        QuestionFormat: question.SurveyQuestionTypeId,
        ThresholdSign: question.ThresholdSign,
        ResponseThreshold: question.ResponseThreshold,
        MaxResponseThreshold: question.MaxResponseThreshold,
        Option1QF: !question.Option1QF,
        ...optionData,
      };
      this.setState({
        initData: data,
      });
    }
  }
  componentWillReceiveProps({ questionFormatValue, questionFormValue }) {
    if (questionFormatValue && questionFormatValue != this.props.questionFormatValue) {
      let data = {
        ...questionFormValue,
        QuestionFormat: questionFormatValue,
        [`options${questionFormatValue}`]: [{}],
      };
      if (questionFormatValue === 26) {
        data = {
          ...data,
          [`options${questionFormatValue}`]: [
            {
              [`Option1Text`]: 'Yes',
            },
            {
              [`Option2Text`]: 'No',
            },
          ],
        };
      }

      if (!this.props.SurveyQuestionCategoryConfigId) {
        this.setState({
          initData: data,
        });
      }
    }
  }
  render() {
    const {
      toggleDrawer,
      handleNewQuestion,
      surveyQuestion,
      questionFormatValue,
      initQuestion,
      questionFormValue,
    } = this.props;
    const { initData } = this.state;
    return (
      <div>
        <ReduxManageQuestionForm
          questionFormatValue={questionFormatValue}
          {...this.props}
          initialValues={initData}
          questionFormValue={questionFormValue}
          //   initialValues={{
          //     QuestionFormat: questionFormatValue || 22,
          //     [`options${questionFormatValue}`]: [{}],
          //   }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  // const { survey_question_library_question_get } = state;
  const questionFormatValue = selector(state, 'QuestionFormat');
  const questionFormValue = selector(
    state,
    'Category',
    'Question',
    'isQuestionMandatory',
    'isAddNote',
    'QuestionFormat',
    'ThresholdSign',
  );
  return {
    questionFormatValue,
    questionFormValue,
  };
};
export default connect(mapStateToProps)(ManageNewQuestion);
