import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal from '../../../../core/modal';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
  CheckboxGroup,
} from '../../../../component/custom-redux-components';
import { Table, Checkbox, Radio, Modal, Button } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { prescribeUpdate, prescriptionListGet } from '../../../../store/actions';
import { ProcessCombos } from '../../../../store/comboConfig';
import PersistTableSort from '../../../../component/persistTableSort';

let columns = [
  {
    title: 'Drug Description',
    dataIndex: 'DrugDescription',
    key: 'DrugDescription',
    sorter: (a, b) => isComparer(a.DrugDescription, b.DrugDescription),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Quantity',
    dataIndex: 'Quantity',
    key: 'Quantity',
    sorter: (a, b) => isNumericComparer(a.Quantity, b.Quantity),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Refills',
    dataIndex: 'Refills',
    key: 'Refills',
    sorter: (a, b) => isNumericComparer(a.Refills, b.Refills),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Modified Date',
    dataIndex: 'ModifiedDate',
    key: 'ModifiedDate',
    sorter: (a, b) => isDateComparer(a.ModifiedDate, b.ModifiedDate),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Route',
    dataIndex: 'Route',
    key: 'Route',
    sorter: (a, b) => isComparer(a.Route, b.Route),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Dose',
    dataIndex: 'Dose',
    key: 'Dose',
    sorter: (a, b) => isNumericComparer(a.Dose, b.Dose),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'DoseUnit',
    dataIndex: 'DoseUnit',
    key: 'DoseUnit',
    sorter: (a, b) => isNumericComparer(a.DoseUnit, b.DoseUnit),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Action',
    dataIndex: 'Action',
    key: 'Action',
    sorter: (a, b) => isComparer(a.Action, b.Action),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    sorter: (a, b) => isComparer(a.Status, b.Status),
    sortDirections: ['descend', 'ascend'],
  },
];
class EPrescription extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      // SearchStr: null,
    };
  }

  getPatientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var patientId = param.get('pId') || param.get('pId');
    if (patientId) {
      return (patientId = parseInt(patientId));
    } else {
      patientId = -1;
    }
    return patientId;
  }
  componentDidMount() {
    let param = new URLSearchParams(this.props.history.location.search);
    var isSinglePatientMode = param.has('isSinglePatientMode');
    if (isSinglePatientMode) {
      let isSinglePatientMode = param.get('isSinglePatientMode');
      let reason = param.get('reason');
      let centerId = param.get('centerId');
      let patient_external_id = param.get('pId');
      let eRXSSOLogId = param.get('eRXSSOLogId');

      this.props.dispatch(
        prescribeUpdate({
          ClientId: patient_external_id,
          IsSinglePatientMode: isSinglePatientMode,
          CenterId: centerId,
          SessionOverReason: reason,
          eRXSSOLogId: eRXSSOLogId,
        }),
      );
    }
    if (this.getPatientId() > 0) {
      var data = {
        ClientId: this.getPatientId(),
      };
      var ePrescriptiontData = {
        Json: JSON.stringify(data),
        ClientId: this.getPatientId(),
      };
      this.props.dispatch(prescriptionListGet(ePrescriptiontData));
    }
  }

  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      SearchStr: value,
    });
    this.props.dispatch(
      prescriptionListGet({
        json: JSON.stringify({
          SearchStr: value,
          ClientId: this.getPatientId(),
        }),
      }),
    );
  };
  render() {
    const { isReadOnlyUser, prescriptionList, isFetching } = this.props;
    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page mb-5 lab-order">
          <div className="container-fluid rcm-tab">
            <div className="row">
              <div className="col-12">
                <div className="border-0">
                  <div className="row px-0 mt-5 d-flex align-items-center">
                    <div className="col-12 col-xl-3 px-0">
                      <h4 className="">ePrescription</h4>
                    </div>
                    <div className="col-9 col-xl-9 px-0 d-flex align-items-center justify-content-end">
                      <div className="search-box d-inline-flex w-100">
                        <div className="form-group has-search w-100 mb-0">
                          <input
                            type="text"
                            className="form-control 
                   shadow-sm rcm-document-search"
                            placeholder="Search..."
                            onChange={this.onSearchChange}
                          />
                          <button className="btn search-icon " type="button">
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row card-body mt-5 px-2 rcm-subtab-table-block">
              <div class="col-12" style={{ position: 'relative' }}>
                <Loader2 loading={isFetching} />
                <PersistTableSort
                  name="prescriptionList"
                  columns={columns}
                  dataSource={prescriptionList}
                  size={'middle'}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div class="dataTables_wrapper no-footer">
                          <div class="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a class="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a class="paginate_button next">Next</a>
                            ) : (
                              <a class="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// prescription_list_get

const mapStateToProps = ({ prescription_list_get }) => {
  var { data, isFetching, error } = prescription_list_get;

  let prescriptionList;
  if (prescription_list_get && prescription_list_get.data) {
    prescriptionList = prescription_list_get.data.Prescription;
  }

  return {
    prescriptionList: prescriptionList || [],
    isFetching,
  };
};

export default withRouter(connect(mapStateToProps)(EPrescription));
