import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import Axios from 'axios';
import {
  Table,
  DatePicker,
  Col,
  Popover,
  Button,
  Checkbox,
  Tooltip,
  Avatar,
  Modal,
  Radio,
  Menu,
  Dropdown,
} from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
// import PersistTableSort from '../component/persistTableSort';
import {
  dynamicGridList,
  getGrid,
  getGridData,
  gridFilterGet,
  gridOnRowClickGet,
  resetGridOnRowClickGet,
} from '../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';
import ReactRangePicker from '../../component/Range-Picker';
import ActionableIcon from '../../Components/ActionableIcon';
// import ExportExcel from '../ExportExcel';
import _blank from '../../images/blank-profile.png';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MultiSelectCheckboxes from '../../component/custom-components/multiSelectCheckboxes';
// import CustomModal from '../../CustomModal';
import CustomModal from '../CustomModal';
import Loader, { Loader2 } from '../Loader';
import Twilio from '../twilio';
import DynamicFilterModal from './DynamicFilterModal';
// import { StudentFilterModal } from '../../Screens/customScreens/dynamicFilterModal';
import PaginationPrevNext from '../Pagination';
import MultiImageRenderer from './MultiImages';
import { getDate, getLocalDate } from '../../util/date';
import MultiActionMenu from './MultiActionMenu';
import { ProfilePicComponent } from '../profilePicComponent';
import ExpandIcon from '../../util/expandIcon';
import GridSearch from './gridSearch';
import { DropdownFilter } from './Filters';
import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import { read, write, utils } from 'xlsx';
import { currencyFormatter } from '../../util/formatter';
import { CustomToolTip } from '../../Components/CustomTooltip';
// import PreIntakeAction from '../Screens/EnterpriseClient/pre-intake/PreIntakeAction';
var timer;
const date = new Date();
const dateFormat = 'YYYY-MM-DD';

class DynamicGrid extends Component {
  constructor(props) {
    super(props);
    const { PageRowsSize } = props.tableOptions || {};
    this.state = {
      onFilter: {
        ...props.FilterBy,
      },
      Columns: [],
      RowsData: [],
      Filters: [],
      PageSize: 0,
      // dateValue: moment(date).format('YYYY-MM-DD'),
      dateValue: null,
      clickedRecord: {},
      visible: false,
      ColumnOption: [],
      TableColumns: [],
      IsRowClick: false,
      IsCustomOnRowClick: false,
      PageNumber: 1,
      PageRows: PageRowsSize || 50,
      SortColumnName: null,
      SortType: null,
      IsDefaultPagination: false,
      isShowExcelExport: false,
      isColumnSelectionEnable: false,
      IsCustomFilter: false,
      isModalOpen: false,
    };
  }
  getCenterId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var centerId = param.get('centerId');
    if (centerId) {
      return (centerId = parseInt(centerId));
    } else {
      centerId = null;
    }
    return centerId;
  };
  getData = () => {
    const {
      PageSize,
      searchStr,
      filterBy,
      StartDate,
      EndDate,
      dateValue,
      centerId,
      onFilter,
      PageNumber,
      PageRows,
      SortColumnName,
      SortType,
    } = this.state;
    this.props.dispatch(
      getGridData({
        Json: JSON.stringify({
          ComponentGrid: [
            {
              Name: this.props.ScreenName,
              SearchStr: searchStr || null,
              StartDate: StartDate || null,
              EndDate: EndDate || null,
              Date: dateValue || null,
              PageNumber: PageNumber,
              RowspPage: PageRows,
              SortColumn: SortColumnName,
              SortType: SortType,
              FilterCenterId: this.getCenterId(),
              ...onFilter,
            },
          ],
        }),
      }),
    );
  };
  onSearchChange = (value, KeyName) => {
    this.state.searchStr = value;
    this.getPagination({
      PageNumber: 1,
      PageRows: this.state.PageRows,
    });
  };
  // fnDebounce = (value, KeyName) => {
  //   clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     this.onSearchChange(value, KeyName);
  //   }, 600);
  // };

  onFilterChange = filterData => {
    let checkKeys = ['StartDate', 'EndDate', 'startDate', 'endDate'];
    let valueArray = Object.keys(filterData);
    let obj = { ...filterData };
    for (const key of valueArray) {
      if (checkKeys.includes(key)) {
        obj[key] = filterData[key] ? moment(filterData[key]).format('YYYY-MM-DD') : null;
      }
    }
    let onFilter = {
      ...this.state.onFilter,
      ...obj,
    };
    this.state.onFilter = onFilter;
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    let existingFilters, newPageFilter;
    let tableName = `table_${this.props.ScreenName}`;
    if (pageFilter && JSON.parse(pageFilter)[tableName]) {
      existingFilters = JSON.parse(pageFilter)[tableName];
      newPageFilter = {
        ...JSON.parse(pageFilter),
        [`table_${this.props.ScreenName}`]: {
          ...existingFilters,
          filtersStored: { ...onFilter },
          pagination: {
            PageNumber: 1,
            PageRows: this.state.PageRows,
          },
        },
      };
      sessionStorage.setItem(`pageFilter`, JSON.stringify(newPageFilter));
      this.setState({
        PageNumber: 1,
      });
    } else {
      newPageFilter = {
        [tableName]: {
          filtersStored: { ...onFilter },
          pagination: {
            PageNumber: 1,
            PageRows: this.state.PageRows,
          },
        },
      };
      this.setState({
        PageNumber: 1,
      });
      sessionStorage.setItem(`pageFilter`, JSON.stringify(newPageFilter));
    }
    this.getData();
    // this.props.dispatch(
    //   getGrid({
    //     Json: JSON.stringify({
    //       ComponentGrid: [
    //         {
    //           Name: this.props.ScreenName,
    //           FilterCenterId: this.getCenterId(),
    //           // FilterBy: value || null,
    //           ...onFilter,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  };
  CustomFilter = customFilter => {
    // this.setState(
    //   {
    //     PageNumber: 1,
    //     PageRows: this.state.PageRows,
    //   },
    //   () => {
    //     this.onFilterChange(customFilter);
    //   },
    // );
    this.onFilterChange(customFilter);
  };
  resetFilterData = () => {
    this.setState({
      onFilter: {
        ...this.props.FilterBy,
      },
    });
  };

  fnDateRangeChange = (key, date) => {
    this.state.StartDate = date[0];
    this.state.EndDate = date[1];
    this.getData();
  };
  onDateChange = dateValue => {
    this.state.dateValue = moment(dateValue).format('YYYY-MM-DD');
    this.getData();
  };
  OnRowAction = URL => {
    const { history } = this.props;
    if (URL) {
      history.push(`${URL}`);
    } else return;
  };
  onRowClick(record) {
    const { IsCustomOnRowClick } = this.state;
    if (IsCustomOnRowClick) {
      this.props.dispatch(
        gridOnRowClickGet({
          Json: JSON.stringify({
            ComponentGrid: [
              {
                Name: this.props.ScreenName,
                RecordId: record.RecordId,
              },
            ],
          }),
        }),
      );
    } else {
      this.OnRowAction(record.OnRowClick);
    }
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
      columnsType: null,
    });
  };
  setStoredFilter = Columns => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);

    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.ScreenName}`]) {
      if (JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].rangeFilter) {
        // let sDate = JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].rangeFilter
        //   .startDateRange;
        // let eDate = JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].rangeFilter
        //   .endDateRange;
        // this.setState({
        //   StartDate: sDate,
        //   EndDate: eDate,
        // });

        if (!JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].selectFilter) {
          return;
        } else {
          // let filterBy = JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].selectFilter;
          // this.setState({
          //   filterBy,
          // });
        }
      } else if (JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].selectFilter) {
        // let filterBy = JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].selectFilter;
        // this.setState({
        //   filterBy,
        // });
      }
    } else {
    }
  };

  storeColumnSelection = checkedColumnsOnly => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.ScreenName}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.ScreenName}`];
    }
    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.ScreenName}`]: {
        ...existingFilters,
        columnState: checkedColumnsOnly,
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };

  getColName = checkedValue => {
    const { ColumnOption } = this.state;
    let dataindex = [];
    let checkedVal = checkedValue.map(checkItem => {
      dataindex.push(checkItem.dataIndex);
      return {
        ...checkItem,
        hidden: false,
      };
    });

    // A comparer used to determine if two entries are equal.
    const isSameUser = (checkedVal, ColumnOption) =>
      checkedVal.value == ColumnOption.value && checkedVal.label == ColumnOption.label;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(leftValue => !right.some(rightValue => compareFunction(leftValue, rightValue)));
    const onlyInA = onlyInLeft(checkedVal, ColumnOption, isSameUser);
    const onlyInB = onlyInLeft(ColumnOption, checkedVal, isSameUser);

    const result = [...onlyInA, ...onlyInB];
    let ColumnKeys = [];
    ColumnKeys = result.map(item => {
      return item.key;
    });

    let ColumnOptionNew =
      ColumnOption &&
      ColumnOption.map((item, index) => {
        if (dataindex.includes(item.dataIndex)) {
          return {
            ...item,
            hidden: false,
          };
        } else
          return {
            ...item,
            hidden: true,
          };
      });
    let xyz =
      ColumnOptionNew &&
      ColumnOptionNew.map((item, index) => {
        if (ColumnKeys.includes(item.key)) {
          return {
            ...item,
            hidden: true,
          };
        } else
          return {
            ...item,
            hidden: false,
          };
      });
    this.storeColumnSelection(xyz);
    this.setState({ TableColumns: xyz });
  };

  columnModifier = Columns => {
    let newCol = [];
    let newColOption = [];
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    let sortInfofromSession = sessionStorage.getItem(`${this.props.ScreenName}_sortInfo`)
      ? JSON.parse(sessionStorage.getItem(`${this.props.ScreenName}_sortInfo`))
      : {};
    let sessionStoredColumns = null;

    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.ScreenName}`]) {
      sessionStoredColumns =
        pageFilter && JSON.parse(pageFilter)[`table_${this.props.ScreenName}`].columnState;
    }

    Columns &&
      Columns.map((item, index) => {
        let obj;
        if (item.type === 'String') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => {
              return <span className="col-max-width">{text}</span>;
            },
          };
        } else if (item.type === 'HoverDescription') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => {
              let hoverInfo = record[`${item.key}_Hover`];
              return (
                <div>
                  <p className="mb-0 d-flex align-items-center">
                    <span class="pr-2">{text}</span>
                    {hoverInfo && (
                      <Tooltip title={hoverInfo} placement="top" key={item.key}>
                        <img src={`images/TooltipIcon.svg`} height={18} />
                      </Tooltip>
                    )}
                  </p>
                </div>
              );
            },
          };
        } else if (item.type === 'CustomToolTip') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => {
              let listData = text && JSON.parse(text);
              return (
                <div className="" onClick={event => event.stopPropagation()}>
                  {listData && listData.length > 1 ? (
                    <span className="mx-1 col-max-width">
                      <CustomToolTip
                        // trigger="hover"
                        placement="left"
                        optionData={listData}
                        titleKey={'Text'}
                        title={item.key}
                      />
                    </span>
                  ) : (
                    <span className="mx-1 col-max-width">{listData && listData[0].Text}</span>
                  )}
                </div>
              );
            },
          };
        } else if (item.type === 'Integer') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isNumericComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
          };
        } else if (item.type === 'Currency') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isNumericComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => (
              <div>
                <p className="mb-0"> {text && currencyFormatter.format(text)} </p>
              </div>
            ),
          };
        } else if (item.type === 'Date') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isDateComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
          };
        } else if (item.type === 'Service') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => {
              return (
                <>
                  {record.UserSmallGroupList && record.UserSmallGroupList.length > 0 ? (
                    <>
                      <Popover
                        content={
                          <Avatar.Group
                            maxStyle={{
                              color: '#f56a00',
                              backgroundColor: '#fde3cf',
                            }}
                          >
                            {record.UserSmallGroupList.map((sgItem, sgIndex) => (
                              <Tooltip
                                title={`${sgItem.GroupName.toUpperCase()}`}
                                placement="top"
                                key={sgItem.GroupName + sgIndex}
                              >
                                <Avatar
                                  src={sgItem.GroupPictureUrl}
                                  onClick={e => {
                                    e.stopPropagation();
                                    this.props.history.push(
                                      `/manage/small-group-form?smallGroupGuid=${sgItem.SmallGroupGuid}`,
                                    );
                                  }}
                                />
                              </Tooltip>
                            ))}
                          </Avatar.Group>
                        }
                        title="Small Group List"
                      >
                        {text}
                      </Popover>
                    </>
                  ) : (
                    <span>{text}</span>
                  )}
                </>
              );
            },
          };
        } else if (item.type === 'Trend') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isNumericComparer(a.TrendLevel, b.TrendLevel),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => {
              let classname = {
                0: 'pic-circle-neutral',
                1: 'pic-circle-arrow-up',
              };
              return (
                <div className="d-flex align-items-center">
                  <div className={classname[text] || 'pic-circle-arrow-down'}>
                    <div
                      class="student-list-pic"
                      style={{
                        flex: '0 0 29%',
                        // minWidth: '50px'
                      }}
                    >
                      {/* <img
                          src={record.ProfileImageUrl || _blank}
                          alt="student"
                          class="img-circle"
                          height="40"
                          width="40"
                          style={{
                            borderRadius: '50%',
                            padding: '2px',
                            objectFit: 'cover',
                          }}
                        /> */}
                      <ProfilePicComponent src={record.ProfileImageUrl} alt={record.altText} />
                    </div>
                  </div>
                </div>
              );
            },
          };
        } else if (item.type === 'Photo') {
          obj = {
            ...item,
            dataIndex: item.key,
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => text && <img src={text} alt={record.altText} />,
          };
        } else if (item.type === 'Image') {
          obj = {
            ...item,
            dataIndex: item.key,
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => (
              <div class="student-list-pic">
                {/* <img
                    src={text || _blank}
                    alt={record.altText}
                    class="img-circle"
                    height="40"
                    width="40"
                    style={{
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  /> */}
                <ProfilePicComponent src={text} alt={record.altText} />
              </div>
            ),
          };
        } else if (item.type === 'MultiImages') {
          obj = {
            ...item,
            dataIndex: item.key,
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isNumericComparer(a[item.sortField], b[item.sortField]),
            width: 200,
            render: (text, record, index) => {
              const { ClientsConnected } = record;
              return <MultiImageRenderer ClientsConnected={ClientsConnected} />;
            },
          };
        } else if (item.type === 'Phone' || item.type === 'PhoneRecorded') {
          obj = {
            ...item,
            dataIndex: item.key,
            sorter: item.IsDisableSort
              ? false
              : (a, b) => isNumericComparer(a[item.sortField], b[item.sortField]),
            sortDirections: ['descend', 'ascend'],
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => {
              return (
                <div className="d-flex">
                  <div
                    className="tools-grid"
                    style={{
                      width: '125px',
                    }}
                  >
                    {text &&
                    text.length > 0 &&
                    record.IsVoiceCallAllowed &&
                    !this.props.isReadOnlyUser ? (
                      <p
                        className="d-flex align-items.center justify-content-center"
                        style={{ color: '#46B4FE', marginBottom: '0', cursor: 'pointer' }}
                        onClick={event => {
                          let recordData = {
                            ...record,
                            Phone: text,
                          };
                          this.setState({
                            callModelVisible: true,
                            clickedRecord: recordData,
                            columnsType: item.type,
                          });
                          event.stopPropagation();
                        }}
                      >
                        <img
                          style={{
                            display: 'block',
                            marginRight: '5px',
                          }}
                          src={`images/twilioPhoneAllowed.svg`}
                          alt="Calling Allowed"
                          width="10px"
                        />
                        {text}
                      </p>
                    ) : (
                      <p>{text}</p>
                    )}
                  </div>
                </div>
              );
            },
          };
        } else if (item.type === 'Action') {
          obj = {
            ...item,
            dataIndex: item.key,
            key: 'x',
            hidden: sessionStoredColumns && sessionStoredColumns[index].hidden,
            fixed: 'right',
            sortOrder:
              item.key == sortInfofromSession.sortKey ? sortInfofromSession.sortOrder : undefined,
            render: (text, record) => {
              let recordAction = record.Actions && JSON.parse(record.Actions);
              return (
                <div className="d-flex">
                  {recordAction &&
                    recordAction.map((recordItem, recordIndex) => {
                      if (recordItem.IsMultiAction && this.props.getPopOverData) {
                        return (
                          <div
                            key={recordItem.Text || recordIndex}
                            className="tools-grid mx-2"
                            onClick={event => event.stopPropagation()}
                          >
                            <MultiActionMenu
                              RowRecord={record}
                              getPopOverData={this.props.getPopOverData}
                            />
                          </div>
                        );
                      } else
                        return (
                          <div
                            class="tools-grid mx-2"
                            onClick={event => {
                              this.props.history.push(`${recordItem.OnClick}`);
                              event.stopPropagation();
                            }}
                            style={{
                              cursor: 'pointer',
                              width: '25px',
                              height: '25px',
                              marginRight: '10px',
                            }}
                          >
                            {recordItem.Icon ? (
                              <img
                                style={{
                                  cursor: 'pointer',
                                  display: 'block',
                                }}
                                src={`${recordItem.Icon}`}
                                alt={recordItem.Text}
                                width="25px"
                              />
                            ) : (
                              <span className={recordItem.Class}>{recordItem.Text}</span>
                            )}
                          </div>
                        );
                    })}

                  {/* {this.props.ScreenName === 'PreIntake' && (
                      <div className="tools-grid mx-2" onClick={event => event.stopPropagation()}>
                        <PreIntakeAction actionData={record} />
                      </div>
                    )} */}
                </div>
              );
            },
          };
        }
        newCol.push(obj);
        newColOption.push({
          label: item.title,
          value: item.key,
          ...obj,
        });
      });
    this.setState({
      Columns: newCol,
      TableColumns: newColOption,
      ColumnOption: newColOption,
    });
  };

  handleGridChange = (pagination, filters, sorter) => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.ScreenName}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.ScreenName}`];
    }
    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.ScreenName}`]: {
        ...existingFilters,
        lastPageNoAndPageSize: {
          pageNumber: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
    };
    this.setState(
      {
        defaultCurrent: pagination.current,
        defaultPageSize: pagination.pageSize,
        SortColumnName: sorter.columnKey,
        SortType: sorter.order,
      },
      () => {
        this.getData();
      },
    );
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));

    sessionStorage.setItem(
      `${this.props.ScreenName}_sortInfo`,
      `{
        "sortKey": "${sorter.columnKey}",
        "sortOrder": "${sorter.order}"
    }`,
    );
    this.columnModifier(this.state.TableColumns);
  };
  getPagination = ({ PageNumber, PageRows }) => {
    this.setState(
      {
        PageNumber: PageNumber,
        PageRows: PageRows,
      },
      () => {
        this.getData({ PageNumber, PageRows });
        this.storePaginationChange(PageNumber, PageRows);
      },
    );
  };
  storePaginationChange = (PageNumber, PageRows) => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    let existingFilters, newPageFilter;
    let tableName = `table_${this.props.ScreenName}`;
    if (pageFilter && JSON.parse(pageFilter)[tableName]) {
      existingFilters = JSON.parse(pageFilter)[tableName];
      newPageFilter = {
        ...JSON.parse(pageFilter),
        [`table_${this.props.ScreenName}`]: {
          ...existingFilters,
        },
      };
      sessionStorage.setItem(`pageFilter`, JSON.stringify(newPageFilter));
    }
    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.ScreenName}`]: {
        ...existingFilters,
        pagination: {
          PageNumber: PageNumber,
          PageRows: PageRows,
        },
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };
  componentDidMount() {
    let tableName = `table_${this.props.ScreenName}`;
    let filtersStored = {};
    let cachedPaginationObj = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[tableName]) {
      if (JSON.parse(pageFilter)[tableName].filtersStored) {
        filtersStored = JSON.parse(pageFilter)[tableName].filtersStored;
      }
      if (JSON.parse(pageFilter)[tableName].pagination) {
        cachedPaginationObj = JSON.parse(pageFilter)[tableName].pagination;
      }
    }

    let pageSort = sessionStorage.getItem(`${this.props.ScreenName}_sortInfo`);
    pageSort = pageSort && JSON.parse(pageSort);

    this.setState(
      {
        ...this.state,
        onFilter: { ...this.state.onFilter, ...filtersStored },
        ...cachedPaginationObj,
      },
      () => {
        this.props.dispatch(
          getGrid({
            Json: JSON.stringify({
              ComponentGrid: [
                {
                  Name: this.props.ScreenName,
                  FilterCenterId: this.getCenterId(),
                },
              ],
            }),
          }),
        );
        this.props.dispatch(
          getGridData({
            Json: JSON.stringify({
              ComponentGrid: [
                {
                  Name: this.props.ScreenName,
                  FilterCenterId: this.getCenterId(),
                  PageNumber: this.state.PageNumber,
                  RowspPage: this.state.PageRows,
                  SortColumn: pageSort && pageSort.sortKey,
                  SortType: pageSort && pageSort.sortOrder,
                  ...this.state.onFilter,
                },
              ],
            }),
          }),
        );
      },
    );
    this.props.dispatch(
      gridFilterGet({
        Json: JSON.stringify({
          ComponentGrid: [
            {
              Name: this.props.ScreenName,
            },
          ],
        }),
      }),
    );
    if (this.props.getGridLatestData) {
      this.props.getGridLatestData(this.getData);
    }
    // this.props.dispatch(
    //   dynamicGridList({
    //     // Json: JSON.stringify({
    //     //   ComponentGrid: [
    //     //     {
    //     //       Name:'',
    //     //       FilterBy: 'Active',
    //     //       SearchStr: '',
    //     //     },
    //     //   ],
    //     // }),
    //   }),
    // );
  }
  componentWillReceiveProps({
    Columns,
    RowsData,
    Filters,
    PageSize,
    IsRowClick,
    IsCustomOnRowClick,
    IsDefaultPagination,
    IsExcelExport,
    IsColumnSelectionEnable,
    IsCustomFilter,
    RowClickedURL,
  }) {
    if (RowsData && JSON.stringify(RowsData) != JSON.stringify(this.props.RowsData)) {
      this.setState({
        RowsData: RowsData,
      });
    }
    if (
      Columns &&
      Columns.length > 0 &&
      JSON.stringify(Columns) != JSON.stringify(this.props.Columns)
    ) {
      this.columnModifier(Columns);
      // this.setStoredFilter(Columns);
    }
    if (Filters && JSON.stringify(Filters) != JSON.stringify(this.props.Filters)) {
      this.setState({
        Filters: Filters,
        PageSize: PageSize,
        IsRowClick: IsRowClick,
        IsCustomOnRowClick: IsCustomOnRowClick,
        IsDefaultPagination: IsDefaultPagination,
        isShowExcelExport: IsExcelExport,
        IsCustomFilter: IsCustomFilter,
        isColumnSelectionEnable: IsColumnSelectionEnable,
        // PageNumber: IsDefaultPagination ? 1 : null,
        // RowspPage: IsDefaultPagination ? 5 : null,
      });
    }

    if (
      IsDefaultPagination &&
      JSON.stringify(IsDefaultPagination) != JSON.stringify(this.props.IsDefaultPagination)
    ) {
      this.setState({
        PageSize: PageSize,
        IsDefaultPagination: IsDefaultPagination,
      });
    }
    if (
      RowClickedURL &&
      JSON.stringify(RowClickedURL) != JSON.stringify(this.props.RowClickedURL)
    ) {
      this.props.dispatch(resetGridOnRowClickGet());
      this.OnRowAction(RowClickedURL);
    }
  }
  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  getAllRecordOnce = (visibleColKeys, TableColumns) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = this.props.ScreenName;
    let data = {
      Json: JSON.stringify({
        ComponentGrid: [
          {
            Name: this.props.ScreenName,
            FilterCenterId: this.getCenterId(),
            RowspPage: this.state.PageRows,
            SortColumn: this.state.SortColumnName,
            PageNumber: -1,
            ...this.state.onFilter,
          },
        ],
      }),
    };
    Axios.post('Component/GridDataGet', data)
      .then(response => {
        if (response.data.success) {
          let AllRecordData = response.data.data || [];
          let exportRow = [];
          let exportCol = [];
          AllRecordData.map((rowItem, index) => {
            let obj = {};
            visibleColKeys.map((colkey, colIndex) => {
              if (colkey === 'Actions' || colkey === 'StudentImageUrl') {
                return;
              }
              if (index == 0) {
                exportCol.push(TableColumns[colIndex].label);
              }
              obj = {
                ...obj,
                [colkey]: rowItem[colkey],
              };
              return obj;
            });
            exportRow.push(obj);
          });
          const ws = utils.json_to_sheet(exportRow);
          utils.sheet_add_aoa(ws, [exportCol], { origin: 'A1' });
          const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
          const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const {
      isFetching,
      PageTitle,
      FormName,
      CustomFilter,
      ScreenName,
      Twilio_ScreenName,
      getCallEndDetails,
      IsExpandable,
      ExpandedRowRender,
      OnExpand,
    } = this.props;
    const {
      Columns,
      TableColumns,
      RowsData,
      Filters,
      PageSize,
      dateValue,
      ColumnOption,
      IsRowClick,
      IsCustomOnRowClick,
      IsDefaultPagination,
      isColumnSelectionEnable,
      PageNumber,
      PageRows,
      IsCustomFilter,
      isModalOpen,
    } = this.state;
    let colx = [];
    let data =
      TableColumns &&
      TableColumns.filter(item => {
        if (!item.hidden) {
          colx.push(item.dataIndex);
          return item;
        }
      });
    return (
      <div class="dynamic-grid-component">
        <div class="row justify-content-end align-items-end mb-3">
          <div className="col-2">
            <div className="row align-items-center">
              {isColumnSelectionEnable && (
                <div class="mx-3 multi-select-checkboxes">
                  <MultiSelectCheckboxes
                    options={ColumnOption}
                    onChange={this.getColName}
                    value={data}
                    hideSearch={true}
                    // setState={setSelectedOptions}
                    // rightAligned={true}
                    getDropdownButtonLabel={Value => {
                      const { value, placeholderButtonLabel } = Value;
                      return `${value.length > 0 ? value.length : 'No'} Columns Selected`;
                    }}
                  />
                </div>
              )}

              {/* {isShowExcelExport && (
                <div className="mx-2">
                  {RowsData.length > 0 && (
                    <ExportExcel
                      reportData={{
                        'Data Grid': [...RowsData],
                      }}
                      labelText={' '}
                      filename="Report"
                    />
                  )}
                </div>
              )} */}
            </div>
          </div>
          <div className="col-10">
            <div className="row justify-content-end align-items-center">
              {Filters &&
                Filters.map(item => {
                  if (item.AdditionalFieldType === 'Search') {
                    return (
                      <div className="mx-2" key={item.AdditionalFieldType}>
                        <GridSearch item={item} onSearchChange={this.onSearchChange} />
                      </div>
                    );
                  }
                  if (item.AdditionalFieldType === 'Dropdown') {
                    return (
                      <div className="mx-2" key={item.AdditionalFieldType}>
                        <DropdownFilter
                          item={item}
                          onFilterChange={this.onFilterChange}
                          onFilter={this.state.onFilter || []}
                        />
                      </div>
                    );
                  }
                  if (item.AdditionalFieldType === 'DateRange') {
                    return (
                      <div className="mx-2" key={item.AdditionalFieldType}>
                        <ReactRangePicker
                          handleChange={this.fnDateRangeChange}
                          defaultValue={[
                            moment(item.FilterByOptions[0].Value, dateFormat),
                            moment(item.FilterByOptions[1].Value, dateFormat),
                          ]}
                        />
                      </div>
                    );
                  }
                  if (item.AdditionalFieldType === 'Date') {
                    return (
                      <div class="mx-2" key={item.AdditionalFieldType}>
                        <DatePicker
                          defaultValue={moment(
                            moment(item.FilterByOptions[0].Value).format('YYYY-MM-DD'),
                            'YYYY/MM/DD',
                          )}
                          selected={dateValue}
                          onChange={this.onDateChange}
                          placeholder="Select Date"
                          dateFormat="YYYY/MM/DD"
                          minDate={new Date()}
                        />
                      </div>
                    );
                  }
                  if (item.AdditionalFieldType === 'ExportExcel') {
                    return (
                      <ActionableIcon
                        title="Download Excel"
                        iconSrc="images/ExcelDownload.svg"
                        onClick={e => this.getAllRecordOnce(colx, data)}
                        style={{
                          marginTop: '15px',
                        }}
                      />
                    );
                  }
                  if (item.AdditionalFieldType === 'Button' && !this.props.isReadOnlyUser) {
                    return (
                      <div className="mx-2" key={item.AdditionalFieldType}>
                        <button
                          style={{ cursor: 'pointer' }}
                          class="btn black-btn"
                          color="blue"
                          onClick={() => {
                            this.props.dispatch(reset(FormName));
                            this.props.history.push(item.AdditionalFieldOnClick);
                          }}
                        >
                          {item.AdditionalFieldName}
                        </button>
                      </div>
                    );
                  }
                  if (item.AdditionalFieldType === 'DropdownButton' && !this.props.isReadOnlyUser) {
                    const AdditionalField = item.MiscJson[0];
                    return (
                      <div className="mx-2 mb-3 enroll-btn" key={item.AdditionalFieldType}>
                        <Dropdown.Button
                          type="primary"
                          icon={<DownOutlined color="red" style={{ display: 'grid' }} />}
                          onClick={() => {
                            this.props.dispatch(reset(FormName));
                            this.props.history.push(item.AdditionalFieldOnClick);
                          }}
                          overlay={
                            <Menu
                              onClick={() => {
                                this.props.history.push(AdditionalField.OnClick);
                              }}
                            >
                              <Menu.Item>{AdditionalField.Text}</Menu.Item>
                            </Menu>
                          }
                        >
                          {item.AdditionalFieldName}
                        </Dropdown.Button>
                      </div>
                    );
                  }
                  if (item.AdditionalFieldType === 'CustomFilter' && !this.props.isReadOnlyUser) {
                    return (
                      <div className="mx-2" key={item.AdditionalFieldType}>
                        <input
                          type="button"
                          class="btn btn-outline-primary px-5 mr-2"
                          value="Filter"
                          onClick={this.toggleModal}
                        />
                        <Modal
                          width={800}
                          open={isModalOpen}
                          footer={null}
                          closeIcon={null}
                          closable={false}
                          destroyOnClose
                          onCancel={this.toggleModal}
                        >
                          <DynamicFilterModal
                            getCustomFilter={this.CustomFilter}
                            ScreenName={ScreenName}
                            onOk={this.toggleModal}
                            onCancel={this.toggleModal}
                            resetFilterData={this.resetFilterData}
                          />
                        </Modal>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-5">
            <div class="card border-0">
              <div class="row card-body">
                <div class="col-12">
                  <Loader2 loading={isFetching} />
                  <div className={IsRowClick ? 'row-hover-enabled' : ''}>
                    {data && data.length > 0 && (
                      <>
                        <Table
                          name={ScreenName}
                          columns={data}
                          dataSource={RowsData || []}
                          size={'middle'}
                          onRow={
                            IsRowClick
                              ? (record, rowIndex) => {
                                  return {
                                    onClick: event => this.onRowClick(record),
                                    //   onDoubleClick: event => {}, // double click row
                                    //   onContextMenu: event => {}, // right button click row
                                    //   onMouseEnter: event => {}, // mouse enter row
                                    //   onMouseLeave: event => {}, // mouse leave row
                                  };
                                }
                              : null
                          }
                          pagination={
                            !IsDefaultPagination
                              ? {
                                  itemRender: (page, type, originalElement) => {
                                    return (
                                      <div class="dataTables_wrapper no-footer">
                                        <div class="dataTables_paginate paging_simple_numbers">
                                          {type == 'prev' ? (
                                            <a class="paginate_button previous">Previous</a>
                                          ) : type == 'next' ? (
                                            <a class="paginate_button next">Next</a>
                                          ) : (
                                            <a class="paginate_button ">{page}</a>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  },
                                  showSizeChanger: true,
                                  defaultPageSize: PageSize || 50,
                                  // pageSize: 50,
                                }
                              : false
                          }
                          scroll={{ x: 'max-content' }}
                          onChange={this.handleGridChange}
                          expandable={
                            IsExpandable
                              ? {
                                  rowExpandable: record => record.IsExpandable, //to restrict expandable
                                  expandedRowRender: ExpandedRowRender,
                                  expandIcon: ({ expanded, onExpand, record }) =>
                                    record.IsExpandable
                                      ? ExpandIcon({ expanded, onExpand, record })
                                      : null,
                                  // defaultExpandedRowKeys: ['1'],
                                  expandedRowKeys: this.state.expandedRowKeys,
                                  onExpand: (expanded, record) => {
                                    if (OnExpand) {
                                      let keys = [];
                                      if (expanded) {
                                        keys.push(record.key); // I have set my record.key as row key.
                                      }
                                      this.setState({ expandedRowKeys: keys });
                                      OnExpand(expanded, record);
                                    }
                                  },
                                }
                              : false
                          }
                        />
                        {IsDefaultPagination && (
                          <PaginationPrevNext
                            getPagination={this.getPagination}
                            pageNumber={PageNumber}
                            pageRows={PageRows}
                            rowsLength={RowsData.length}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
            ScreenName={Twilio_ScreenName}
            getCallEndDetails={getCallEndDetails}
            IsCallRecording={
              this.state.clickedRecord.IsCallRecording && this.state.columnsType === 'PhoneRecorded'
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { get_grid, get_grid_data, grid_on_row_click_get } = state;
  const { data, error } = get_grid;
  const { data: gridData, isFetching: dataIsFetching } = get_grid_data;
  const { data: rowClickedData } = grid_on_row_click_get;

  let Columns = [];
  let RowsData = [];
  let Filters = [];
  let PageSize = 0;
  let IsRowClick = false;
  let IsDefaultPagination = false;
  let IsExcelExport = false;
  let IsColumnSelectionEnable = false;
  let IsCustomFilter = false;
  let IsCustomOnRowClick = false;
  let RowClickedURL = false;

  if (data && data.Columns) {
    Columns = data.Columns;
  }
  if (gridData && gridData.data) {
    RowsData = gridData.data;
  }
  if (rowClickedData && rowClickedData.data) {
    RowClickedURL = rowClickedData.data;
  }

  if (data && data.Filters) {
    Filters = data.Filters;
    IsRowClick = data.IsRowClick;
    IsCustomOnRowClick = data.IsCustomOnRowClick;
    IsExcelExport = data.IsExcelExport;
    IsColumnSelectionEnable = data.IsColumnSelectionEnable;
    IsCustomFilter = data.IsCustomFilter;
  }

  if (data && data.IsDefaultPagination) {
    IsDefaultPagination = data.IsDefaultPagination;
    PageSize = data.PageSize;
  }

  return {
    isFetching: dataIsFetching,
    Columns,
    RowsData,
    Filters,
    PageSize,
    IsRowClick,
    IsCustomOnRowClick,
    IsDefaultPagination,
    IsExcelExport,
    IsColumnSelectionEnable,
    IsCustomFilter,
    RowClickedURL,
  };
};

export default connect(mapStateToProps)(withRouter(DynamicGrid));
