import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  smartContractPatientListGet,
  resetSmartContractPatientListGet,
} from '../../../store/actions';
import { reset } from 'redux-form';
import Twilio from '../../../Components/twilio';
import DynamicGrid from '../../../Components/DynamicGrid';
import ContractRowRender from './expandedRow/contractRowRender';
import PageTitle from '../../../Components/PageTitle';
class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: {
        FilterBy: 'A',
        PractitionerId: '0',
      },
      practitionerId: '0',
      clickedRecord: {},
      callModelVisible: false,
      fetchLatestData: null,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string,
  };

  resetIsOpen = () => {
    this.setState({
      // twilioToken: '',
      callModelVisible: false,
      clickedRecord: {},
      // device: '',
    });
  };

  // onNewPatient = () => {
  //       debugger;
  //   // this.props.history.push('/rcm?tab=PatientManagement/patientProfile');
  //   this.props.history.push('/cw/patient-management/new-registration');
  //   this.props.dispatch(reset('demographicForm'));
  //   // this.props.toggleView();
  //   // this.props.getClientId(null);
  // };
  getContractDetail = (expanded, record) => {
    this.props.dispatch(resetSmartContractPatientListGet());
    if (expanded) {
      this.props.dispatch(
        smartContractPatientListGet({
          json: JSON.stringify({
            SmartContract: [
              {
                ClientId: record.ClientId,
              },
            ],
          }),
        }),
      );
    }
  };

  getGridLatestData = getData => {
    this.setState({ fetchLatestData: getData });
  };

  componentWillReceiveProps({ submitToPayerSuccess, clientIDValue }) {
    if (submitToPayerSuccess && submitToPayerSuccess != this.props.submitToPayerSuccess) {
      // this.state.fetchLatestData();
      this.props.dispatch(
        smartContractPatientListGet({
          json: JSON.stringify({
            SmartContract: [
              {
                ClientId: clientIDValue,
              },
            ],
          }),
        }),
      );
    }
  }

  render() {
    return (
      <div class="w-100 rcm-panel rcm-patient-table">
        <PageTitle TitleText="Patient Management" />
        <div class="main enrollment-page mb-5">
          <div class="container-fluid px-2 py-4">
            <div class="content-area">
              {/* <div class="row top-table-search mt-3 mb-3 align-items-center">
                <div class="col-12">
                  <h3 class="page-title mb-3 mb-md-0 text-uppercase">Patient List</h3>
                </div>
              </div> */}
              <div className="dynamic-grid">
                <DynamicGrid
                  ScreenName={'Client'}
                  FilterBy={this.state.filterBy}
                  FormName="demographicForm"
                  isReadOnlyUser={this.props.isReadOnlyUser}
                  IsExpandable={true}
                  ExpandedRowRender={ContractRowRender}
                  OnExpand={this.getContractDetail}
                  tableOptions={{
                    PageRowsSize: 50,
                  }}
                  getGridLatestData={this.getGridLatestData}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            // twilioToken={this.state.twilioToken}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}

ClientList.defaultProps = {
  name: 'client-list',
};

const mapStateToProps = state => {
  const { default_values, smart_contract_bundle_submit_to_payer } = state;
  const { data: data2 } = smart_contract_bundle_submit_to_payer;
  let isReadOnlyUser = false;
  let submitToPayerSuccess = false;
  let clientIDValue = null;


  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  if (data2 && data2.success) {
    submitToPayerSuccess = data2.success;
    clientIDValue = data2.ClientId;
  }
  return {
    isReadOnlyUser,
    submitToPayerSuccess,
    clientIDValue,
  };
};

export default connect(mapStateToProps)(ClientList);
