import React, { useState, useEffect } from 'react';
import { Collapse, Tooltip, Tag, Drawer } from 'antd';
import {
  RightCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ReduxDateInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxInput,
} from '../../../../../../component/custom-redux-components';
import validate from '../../../../../../core/generic-form/validation';
import { smartContractUpdate, goalLibraryGet } from '../../../../../../store/actions';
import ReduxMilestone from './milestone';
import ReduxAddBundle from './addBundle';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
const { Panel } = Collapse;

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var startDate = values.StartDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();
    //  const startDate = eval(`values.${name}`);
    if (date2 > date1) {
    } else {
      return ' has to be greater than Start Date';
    }
  },
};

const AddRemarkForm = props => {
  const { handleSubmit, drawerData } = props;
  const { tempGoalData, IsCompleted, Description, TotalScore } = drawerData;
  const {
    DaysToAchieveGoal,
    Description: goalDesc,
    GoalCompletedId,
    GoalCreatedOn,
    GoalLibraryId,
    GoalStatus,
    GoalTimePeriod,
    GoalType,
    Note,
    SmartContractGoalId,
    StatusClassName,
    StatusText,
    ThresholdValue,
    TimePeriod,
    TimePeriodId,
    WeeksToAchieveGoal,
    Goal,
  } = tempGoalData;
  const dispatch = useDispatch();
  return (
    <form
      onSubmit={handleSubmit(values => {
        let data = {
          SmartContract: [
            {
              SmartContractGoalId: tempGoalData.SmartContractGoalId,
              IsCompleted: IsCompleted,
              Remarks: values.remarks,
            },
          ],
        };
        dispatch(
          smartContractUpdate({
            json: JSON.stringify(data),
          }),
        );
      })}
    >
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Measure
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {Description}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Baseline Score
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {TotalScore}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Goal Type
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {GoalType}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Goal
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {Description}
          </span>
        </div>
      </div>

      <div class="col-12 enrollment-form mt-3">
        <div class="form-group">
          <Field
            name="remarks"
            type="text"
            errorMessage="remark Required"
            component={ReduxTextarea}
            validate={validate.required}
            fieldName={'Remarks'}
          />
          <label class="placeholder-label">Add Remarks*</label>
        </div>
      </div>
      <button type="submit" className="btn btn-primary">
        Add Remarks
      </button>
    </form>
  );
};

const ReduxAddRemarkForm = reduxForm({
  form: 'addRemarkForm',
  enableReinitialize: true,
})(connect()(withRouter(AddRemarkForm)));

const Goals = props => {
  const {
    fields,
    goalLibrary,
    initialValues,
    goalTimePeriod,
    isMeasuresPresent,
    smartContractMeasure,
    serviceCodeBundle,
    setFieldChanged,
    existingRows,
    centerServiceTypeCenter,
    isGoalFetching,
  } = props;
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const dispatch = useDispatch();

  const onChange = key => {
    // console.log(key);
  };

  const getGoalType = index => {
    if (initialValues && initialValues[index] && initialValues[index].GoalLibraryId) {
      const goalLibraryId = initialValues[index].GoalLibraryId;
      const tempArray = goalLibrary.filter(item => item.Value == goalLibraryId);
      if (tempArray[0]) {
        return tempArray[0].GoalType;
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const showDrawer = (measureData, goalData, IsCompleted) => {
    setOpen(true);
    setDrawerData({ ...measureData, tempGoalData: goalData, IsCompleted });
  };

  const onClose = () => {
    setOpen(false);
  };

  const getPercentageScoreToBeImproved = index => {
    if (initialValues && initialValues[index] && initialValues[index].GoalLibraryId) {
      const goalLibraryId = initialValues[index].GoalLibraryId;
      const tempArray = goalLibrary.filter(item => item.Value == goalLibraryId);
      if (tempArray[0]) {
        return tempArray[0].ThresholdValue;
      } else {
        return;
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (props.fields && props.fields.length < 1) {
      props.fields.push();
    }
  }, []);

  return (
    <div className="row buildServiceBundle">
      <Loader2 loading={isGoalFetching} />

      <div className="col-12 col-md-12 col-lg-12">
        <div className="p-2">
          <Collapse
            // expandIcon={({ isActive }) => (
            //   <RightCircleOutlined
            //     rotate={isActive ? -90 : 90}
            //     style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
            //   />
            // )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={onChange}
          >
            {fields.length > 0 ? (
              fields.map((item, index) => {
                return (
                  <>
                    <Panel
                      showArrow={true}
                      key={index}
                      className="goalSection-panel-border"
                      header={
                        <>
                          <div className="row">
                            <div className="col-md-3 panel-header-text">{`Goal ${index + 1}`}</div>
                            <div className="col-md-9 text-right d-flex">
                              {!props.disabled && (
                                <button
                                  type="button"
                                  class="btn-newCon"
                                  style={{
                                    position: 'unset',
                                    width: 'unset',
                                  }}
                                  onClick={event => {
                                    event.stopPropagation();
                                    fields.push();
                                  }}
                                >
                                  + Add {fields.length == 0 ? 'Goal' : 'Another Goal'}
                                </button>
                              )}

                              {fields.length > 1 && (
                                <div className="text-right">
                                  {!props.disabled && (
                                    <a
                                      class="col-1 delete-contract"
                                      onClick={() => {
                                        if (fields.length && fields.length > 0) {
                                          fields.remove(index);
                                        }
                                      }}
                                    >
                                      <i class="fas fa-trash" />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    >
                      <div
                        class="mx-4"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        key={index}
                      >
                        {' '}
                        <div className="row">
                          {existingRows &&
                            existingRows[index] &&
                            existingRows[index].GoalLibraryId && (
                              <div className="col-md-12">
                                {' '}
                                <div className="shubhamrow d-none">
                                  {/* {props.disabled && initialValues[index].GoalStatus == null} */}
                                  {true && (
                                    <>
                                      {' '}
                                      <div className="mt-3 mb-3 d-flex justify-content-end">
                                        <div
                                          className="btn-deny"
                                          onClick={() => {
                                            showDrawer(
                                              isMeasuresPresent,
                                              initialValues[index],
                                              '2',
                                            );
                                            // setMarkAsCompleteId(SmartContractServiceBundleServiceCodeId);
                                          }}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          Mark as Failed
                                        </div>
                                        <div
                                          className="btn-approved"
                                          onClick={() => {
                                            showDrawer(
                                              isMeasuresPresent,
                                              initialValues[index],
                                              '1',
                                            );
                                            // setMarkAsCompleteId(SmartContractServiceBundleServiceCodeId);
                                          }}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          Mark as Completed
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {props.SmartContractId && (
                                    <>
                                      {initialValues &&
                                        initialValues[index] &&
                                        initialValues[index].GoalStatus && (
                                          <div className="col-md-12 mt-3 mb-4 text-right">
                                            <span
                                            // style={
                                            //   props.SmartContractId &&
                                            //   initialValues[index].GoalStatus == 'Completed'
                                            //     ? { background: '#52c41a', color: 'white', padding: '5px' }
                                            //     : { background: 'red', color: 'white', padding: '5px' }
                                            // }
                                            >
                                              {initialValues[index].GoalStatus == 'Completed' ? (
                                                <Tag icon={<CheckCircleOutlined />} color="success">
                                                  <Tooltip title="Goal Status">
                                                    {props.SmartContractId &&
                                                      initialValues[index].GoalStatus}
                                                  </Tooltip>
                                                </Tag>
                                              ) : (
                                                <Tag icon={<CloseCircleOutlined />} color="error">
                                                  <Tooltip title="Goal Status">
                                                    {props.SmartContractId &&
                                                      initialValues[index].GoalStatus}
                                                  </Tooltip>
                                                </Tag>
                                              )}
                                            </span>
                                          </div>
                                        )}
                                    </>
                                  )}

                                  {false && getGoalType(index) && (
                                    <div className="col-md-6 mb-3 mt-2">
                                      {' '}
                                      <div class="form-group mb-0 w-100">
                                        <label
                                          className="placeholder-label"
                                          style={{
                                            zIndex: 1,
                                            fontSize: '11px',
                                            top: '-5px',
                                            // left: !!disabled || value || active ? '0' : '0',
                                            position: 'absolute',
                                          }}
                                        >
                                          Goal Type:
                                        </label>
                                        <span
                                          className=""
                                          style={{
                                            marginTop: '13px',
                                            fontSize: '16px',
                                            color: '#000',
                                            padding: '3px',
                                            display: 'inline-block',
                                          }}
                                        >
                                          {getGoalType(index)}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {false && getGoalType(index) == 'Score Improvement' && (
                                    <div className="col-md-6 mb-3 mt-2">
                                      <div class="form-group mb-0 w-100">
                                        <label
                                          className="placeholder-label"
                                          style={{
                                            zIndex: 1,
                                            fontSize: '11px',
                                            top: '-5px',
                                            // left: !!disabled || value || active ? '0' : '0',
                                            position: 'absolute',
                                          }}
                                        >
                                          % of Score to be Improved:
                                        </label>
                                        <span
                                          className=""
                                          style={{
                                            marginTop: '13px',
                                            fontSize: '16px',
                                            color: '#000',
                                            padding: '3px',
                                            display: 'inline-block',
                                          }}
                                        >
                                          {getPercentageScoreToBeImproved(index)}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {false && (
                                    <div className="col-md-6 mb-3 mt-2">
                                      <div class="form-group">
                                        <Field
                                          name={`${item}TimePeriodId`}
                                          type="text"
                                          component={ReduxSelect}
                                          options={goalTimePeriod}
                                          validate={validate.required}
                                          placeholder={`Select Time Period to Achieve Goal*`}
                                          fieldName={'Time Period to Achieve Goal'}
                                          class="form-control"
                                          // disabled={props.SmartContractId}
                                          disabled={props.disabled}
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {false &&
                                    initialValues &&
                                    initialValues[index] &&
                                    initialValues[index].TimePeriodId == 2 && (
                                      <div className="col-md-6 mb-3 mt-2">
                                        <div class="form-group">
                                          <Field
                                            name={`${item}TimePeriod`}
                                            type="text"
                                            component={ReduxDateInput}
                                            validate={validate.required}
                                            label={`Date to Achieve Goal*`}
                                            fieldName={'Select Date to Achieve Goal'}
                                            class="form-control"
                                            // disabled={props.SmartContractId}
                                            disabled={props.disabled}
                                          />
                                        </div>
                                      </div>
                                    )}

                                  {false && (
                                    <div className="col-md-6 mb-3 mt-2">
                                      <div class="form-group">
                                        <Field
                                          name={`${item}Note`}
                                          component={ReduxTextarea}
                                          validate={validate.required}
                                          class="form-control textarea-box"
                                          type="textarea"
                                          rows="4"
                                          style={{
                                            minHeight: '100px',
                                          }}
                                          fieldName={'Notes'}
                                          // disabled={props.SmartContractId}
                                          disabled={props.disabled}
                                        />
                                        <label class="placeholder-label">Notes*</label>
                                      </div>
                                    </div>
                                  )}

                                  {/* <div className="col-md-6 mb-3 mt-2">
                              <div class="form-group">
                                <Field
                                  name={`${item}Duration`}
                                  component={ReduxInput}
                                  validate={validate.required}
                                  class="form-control textarea-box"
                                  type="text"
                                  // rows="4"
                                  // style={{
                                  //   minHeight: '100px',
                                  // }}
                                  fieldName={'Duration'}
                                  // disabled={props.SmartContractId}
                                  disabled={props.disabled}
                                />
                                <label class="placeholder-label">
                                  {`Duration for Goal ${index + 1}*`}
                                </label>
                              </div>
                            </div> */}
                                </div>
                              </div>
                            )}
                        </div>
                        <div class="row p-2">
                          <div className="col-12">
                            <div class="form-group">
                              <Field
                                name={`${item}GoalLibraryId`}
                                type="text"
                                component={ReduxSelect}
                                options={goalLibrary}
                                validate={validate.required}
                                placeholder={`Select Goal ${index + 1}*`}
                                fieldName={'Goal'}
                                class="form-control"
                                // disabled={props.SmartContractId}
                                disabled={props.disabled}
                                key={index}
                                onChange={value => {
                                  dispatch(
                                    goalLibraryGet({
                                      JsonInput: JSON.stringify({
                                        GoalLibrary: [
                                          {
                                            GoalLibraryId: value,
                                          },
                                        ],
                                      }),
                                      goalIndex: index,
                                      bundleIndex: props.bundleIndex,
                                    }),
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-6">
                            <div class="form-group">
                              <Field
                                name={`${item}StartDate`}
                                type="text"
                                component={ReduxDateInput}
                                validate={validate.required}
                                fieldName={'Start Date'}
                                label="Start Date*"
                                minDate={new Date()}
                                // disabled={
                                //   props.initialValues && props.initialValues.IsContractCreated
                                // }
                                disabled={props.disabled}
                              />
                            </div>
                          </div>
                          <div class="col-12 col-md-6 col-lg-6">
                            <div class="form-group">
                              <Field
                                name={`${item}EndDate`}
                                type="text"
                                component={ReduxDateInput}
                                validate={[validate.required]}
                                fieldName={'End Date'}
                                label="Expected End Date*"
                                minDate={new Date()}
                                // disabled={
                                //   props.initialValues && props.initialValues.IsContractCreated
                                // }
                                disabled={props.disabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {existingRows && existingRows[index] && existingRows[index].GoalLibraryId ? (
                        <>
                          <FieldArray
                            name={`${item}Milestone`}
                            component={ReduxMilestone}
                            goalIndex={index}
                            smartContractMeasure={smartContractMeasure}
                            SmartContractId={
                              props.initialValues && props.initialValues.SmartContractId
                            }
                            initialValues={
                              props.initialValues &&
                              props.initialValues[index] &&
                              props.initialValues[index].Milestone
                            }
                            mesuareValues={props.mesuareValues}
                            goalTimePeriod={goalTimePeriod}
                            existingRows={
                              existingRows && existingRows[index] && existingRows[index].Milestone
                            }
                            // disabled={props.initialValues && props.initialValues.IsContractCreated}
                            disabled={props.disabled}
                          />
                        </>
                      ) : (
                        'Please select a Goal'
                      )}
                    </Panel>
                  </>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
        </div>
      </div>
      <Drawer title="Add Goal Remarks" placement="right" onClose={onClose} open={open} width="600">
        <ReduxAddRemarkForm drawerData={drawerData} />
      </Drawer>
    </div>
  );
};

const ReduxGoals = Goals;
export default ReduxGoals;
