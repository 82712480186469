import React, { Component } from 'react';
import renderHTML from 'react-render-html';

export const SurveyQuestion = ({ survey, ClassName }) => {
  const { Question, QuestionNumber, IsRequired } = survey || {};
  return (
    <h5 className={`survey-question rcm-question text-left ${ClassName}`}>
      {QuestionNumber && `${QuestionNumber}. `}
      {Question && Question.length > 0 && renderHTML(Question)}
      {IsRequired && <span className="text-danger">*</span>}
    </h5>
  );
};
export const DoesNotApplyOption = ({ surveyProps }) => {
  const {
    survey,
    onClick,
    scope,
    index,
    total,
    setValue,
    level,
    subLevel,
    subCatLevel,
    isReadOnly,
  } = surveyProps || {};
  const { DoesNotApplyText, DoesNotApplyValue, SurveyQuestionId } = survey || {};
  return DoesNotApplyText ? (
    <div className="not-apply-to-me">
      <a
        name="current-situation"
        value={DoesNotApplyText}
        onClick={onClick.bind(scope, {
          surveyQuestionId: SurveyQuestionId,
          response: DoesNotApplyValue,
          level: level,
          subLevel: subLevel,
          subCatLevel: subCatLevel,
          index: index,
        })}
      >
        {DoesNotApplyText}
      </a>
    </div>
  ) : null;
};
