import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tag, Modal, Tooltip, Avatar } from 'antd';
// import Loader, { Loader2 } from '../../Components/Loader';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import CustomModal from '../../../../../core/modal';
import AntdModal from '../../../../../Components/CustomModal';
import { getDate, stringDate } from '../../../../../util/date';
import PersistTableSort from '../../../../../component/persistTableSort';
import {
  allergenSearch,
  allergyOptionsGet,
  clientAllergyGet,
  clientAllergyUpsert,
  clientAllergyGetAll,
  clientAllergyDelete,
  resetClientAllergyUpsert,
  resetClientAllergyDelete,
} from '../../../../../store/actions';
import ReduxAllergenFormForm from './allergenFormModal';
var timer;
class ManageAllergen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewEligibilityModal: false,
      isModelOpen: false,
      viewModalType: 'update',
    };
  }
  columns = [
    {
      title: 'Allergen',
      dataIndex: 'Allergy',
      key: 'Allergy',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Allergy, b.Allergy),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Reactions',
      dataIndex: 'Reactions',
      key: 'Reactions',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Reactions, b.Reactions),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Severity',
      dataIndex: 'Severity',
      key: 'Severity',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Severity, b.Severity),
      sortDirections: ['descend', 'ascend'],
      // render: (text, record, index) => (
      //   <>
      //     <span className={record.ResultClassBlock}>{text}</span>
      //   </>
      // ),
    },
    {
      title: 'Onset Date',
      dataIndex: 'OnsetDate',
      key: 'OnsetDate',
      isPrint: true,
      sorter: (a, b) => isComparer(a.OnsetDateForSort, b.OnsetDateForSort),
      sortDirections: ['descend', 'ascend'],
      // render: (text, record, index) => (
      //   <>
      //     <span className={record.ResultClassBlock}>{text}</span>
      //   </>
      // ),
    },
    {
      title: 'Action',
      key: 'x',
      render: (text, record, index) => {
        if (!this.props.disable) {
          return (
            <div className="d-flex">
              <div class="tools-grid">
                <div className="mr-4"></div>
                <div className="">
                  <AntdModal
                    modalWidth={800}
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-eligibility"
                      >
                        <Tooltip placement="top" title="Delete">
                          <p>
                            <i class="far fa-trash-alt"></i>
                          </p>
                        </Tooltip>
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to delete this Client Allergy?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              this.props.dispatch(
                                clientAllergyDelete({
                                  json: JSON.stringify({
                                    ClientAllergy: [
                                      {
                                        ClientAllergyId: record.ClientAllergyId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                              onOk();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div class="tools-grid">
                <div className="mr-4"></div>
                <div className="">
                  <AntdModal
                    modalWidth={800}
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-eligibility"
                      >
                        <Tooltip placement="top" title="Edit">
                          <p>
                            {/* <i class="far fa-trash-alt"></i> */}
                            <img
                              style={{ height: '17px', width: '20px' }}
                              src="images/shape.svg"
                              alt=""
                            />
                          </p>
                        </Tooltip>
                      </span>
                    )}
                    ModalContent={props => (
                      <ReduxAllergenFormForm
                        {...props}
                        onSaveClick={this.onSubmit}
                        ClientId={this.props.clientId}
                        viewModalType="new"
                        handleAllergenChange={this.handleAllergenChange}
                        allergyRecord={record}
                        disabled={true}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];
  componentDidMount() {
    this.props.dispatch(
      clientAllergyGetAll({
        Json: JSON.stringify({
          ClientAllergy: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ IsInserted, IsDeleted, IsUpdated, MSG }) {
    if (IsInserted && IsInserted != this.props.IsInserted) {
      this.props.dispatch(resetClientAllergyUpsert());
      this.toggle(MSG);
    }
    if (IsDeleted && IsDeleted != this.props.IsDeleted) {
      this.props.dispatch(resetClientAllergyDelete());
      this.toggle(MSG);
    }
    // if (IsUpdated && IsUpdated != this.props.IsUpdated) {
    //   // this.toggle('Insurance Priority Updated');
    //   this.props.dispatch(resetClientAllergyUpsert());

    //   this.toggle(MSG);
    // }
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit = ({
    ReactionId,
    SeverityId,
    OnsetDate,
    allergenValue,
    ClientAllergyId,
    rcopiaIDValue,
    FirstDataBankMedID,
    IsGroup,
    RcopiaId,
  }) => {
    this.props.dispatch(
      clientAllergyUpsert({
        Json: JSON.stringify({
          ClientAllergy: [
            {
              ClientId: this.props.clientId,
              ClientAllergyId: ClientAllergyId,
              Allergy: allergenValue,
              // Reactions: ReactionId,
              Reactions: this.getStringType(ReactionId),
              Severity: SeverityId,
              OnsetDate: stringDate(OnsetDate),
              RcopiaID: RcopiaId,
              FirstDataBankMedID: FirstDataBankMedID,
              IsGroup: IsGroup,
            },
          ],
        }),
      }),
    );
  };
  handleAllergenChange = allergenValue => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.props.isEPrescribe
        ? this.props.dispatch(
            allergenSearch({
              ClientId: this.props.ClientId,
              SearchStr: allergenValue,
            }),
          )
        : this.props.dispatch(
            allergyOptionsGet({
              json: JSON.stringify({
                ClientId: this.props.ClientId,
                SearchStr: allergenValue,
              }),
            }),
          );
    }, 500);
    // this.props.dispatch(
    //   allergenSearch({
    //     ClientId: this.props.ClientId,
    //     SearchStr: allergenValue,
    //   }),
    // );
  };
  render() {
    // const { } = this.state;
    const {
      InsurancePriority,
      AllergyReaction,
      AllergySeverity,
      ClientAllergyList,
      disable,
      isFetching,
    } = this.props;
    return (
      <div>
        {/* <Loader2 loading={isFetching} /> */}
        {!disable && (
          <AntdModal
            modalWidth={900}
            ModalButton={({ onClick }) => (
              <span
                class="delete-btn"
                onClick={onClick}
                data-toggle="modal"
                data-target="#addAllergen"
              >
                <span class="add-contact">
                  <label> + Add Allergen</label>
                </span>
              </span>
            )}
            ModalContent={props => (
              <ReduxAllergenFormForm
                {...props}
                onSaveClick={this.onSubmit}
                ClientId={this.props.clientId}
                viewModalType="new"
                handleAllergenChange={this.handleAllergenChange}
                disabled={false}
                isEPrescribe={this.props.isEPrescribe}
                isUpsertFetching={isFetching}
              />
            )}
          />
        )}

        {true ? (
          <div className="row align-items-center">
            <div class="col-12 pb-1">
              <div style={{ maxHeight: '670px', overflowY: 'scroll' }} className="time-line-table">
                <PersistTableSort
                  name="ClientAllergyList"
                  columns={this.columns}
                  dataSource={ClientAllergyList}
                  size={'middle'}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex align-items-center justify-content-center"
              style={{ height: '276px' }}
            >
              <h5 className="text-center">No data</h5>
            </div>
          </div>
        )}
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.props.dispatch(
                clientAllergyGetAll({
                  Json: JSON.stringify({
                    ClientAllergy: [
                      {
                        ClientId: this.props.clientId,
                      },
                    ],
                  }),
                }),
              );
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({
  combos,
  client_allergy_upsert,
  // edit_client_insurance_eligibility,
  // delete_client_insurance_eligibility,
  // allergen_search,
  client_allergy_get_all,
  client_allergy_delete,
}) => {
  const { data: allergenData } = client_allergy_get_all;
  const {  isFetching } = client_allergy_upsert;

  let IsInserted = false;
  let IsDeleted = false;
  // let IsUpdated = false;
  let MSG = null;
  let ClientAllergyList;

  if (allergenData && allergenData) {
    ClientAllergyList = allergenData.ClientAllergy;
  }

  if (client_allergy_upsert.data && client_allergy_upsert.data.success) {
    IsInserted = true;
    client_allergy_upsert.data.result.MSG
      ? (MSG = client_allergy_upsert.data.result.MSG)
      : (MSG = client_allergy_upsert.data.result);
  }

  if (client_allergy_delete.data && client_allergy_delete.data.success) {
    IsDeleted = true;
    MSG = client_allergy_delete.data.result;
  }

  return {
    IsInserted,
    IsDeleted,
    // IsUpdated,
    // AllergiesList: AllergiesList,
    MSG,
    ClientAllergyList,
    isFetching,
  };
};

export default connect(mapStateToProps)(ManageAllergen);
