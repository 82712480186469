import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal from '../../../../core/modal';
import { ReduxSelect } from '../../../../component/custom-redux-components';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { listServiceLine, listServiceLinePayment } from '../../../../store/actions';
import PaymentServiceLineDetail from './customScreens/paymentServiceLineDetail';
import { ProcessCombos } from '../../../../store/comboConfig';
import PersistTableSort from '../../../../component/persistTableSort';
import ReactRangePicker from '../../../../component/Range-Picker';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineData: {},
      tabView: true,
    };
  }
  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = -1;
    }
    return groupId;
  }
  toggleView = getLatest => {
    this.setState(prevState => ({
      tabView: !prevState.tabView,
    }));
    // this.props.dispatch(
    //   listServiceLine({
    //     json: JSON.stringify({
    //       GroupServiceLine: [
    //         {
    //           // GroupId: this.getGroupId(),
    //         },
    //       ],
    //     }),
    //   }),
    // );
    // if (getLatest) {
    //   this.props.dispatch(
    //     listSurveyQuestionCategory({
    //       json: JSON.stringify({
    //         Survey: [
    //           {
    //             ClientId: this.props.clientId,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  };
  componentDidMount() {
    this.props.dispatch(
      listServiceLine({
        json: JSON.stringify({
          ServiceLine: [{}],
        }),
      }),
    );
    // var result = ProcessCombos('RCM_ServiceCode,Supervisor,UnitOfMeasurement');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
  }

  handleChange = (key, date) => {
    this.props.dispatch(
      listServiceLine({
        Json: JSON.stringify({
          ServiceLine: [
            {
              Search: this.state.searchStr,
              StartDate: date[0],
              EndDate: date[1],
            },
          ],
        }),
      }),
    );
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });
  };
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listServiceLine({
        json: JSON.stringify({
          ServiceLine: [
            {
              Search: value,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { tabView, PaymentData } = this.state;
    const { isFetching, ListServiceLine, FilterClientInsurance } = this.props;
    const tempColumns = [
      {
        title: 'Patient ID',
        // dataIndex: 'ClientId',
        dataIndex: 'DisplayClientId',
        key: 'ClientId',
        // fixed: 'left',
        // sorter: (a, b) => isNumericComparer(a.ClientId, b.ClientId),
        sorter: (a, b) => isNumericComparer(a.DisplayClientId, b.DisplayClientId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        // fixed: 'left',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Provider Name',
        dataIndex: 'PractionerName',
        key: 'PractionerName',
        sorter: (a, b) => isComparer(a.PractionerName, b.PractionerName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Insurance Name ',
        dataIndex: 'InsuranceName',
        key: 'InsuranceName',
        sorter: (a, b) => isComparer(a.InsuranceName, b.InsuranceName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Service Code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        width: 150,
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: <span>Appointment</span>,
        dataIndex: 'AppointmentType',
        key: 'AppointmentType',
        sorter: (a, b) => isComparer(a.AppointmentType, b.AppointmentType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: (
          <span>
            Appointment <br />
            Date
          </span>
        ),
        dataIndex: 'SessionDate',
        key: 'SessionDate',
        sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Start Time',
      //   dataIndex: 'StartTime',
      //   key: 'StartTime',
      //   sorter: (a, b) => isComparer(a.StartTime, b.StartTime),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'End Time',
      //   dataIndex: 'EndTime',
      //   key: 'EndTime',
      //   sorter: (a, b) => isComparer(a.EndTime, b.EndTime),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Supervisor',
      //   dataIndex: 'SupervisorName',
      //   key: 'SupervisorName',
      //   sorter: (a, b) => isComparer(a.SupervisorName, b.SupervisorName),
      //   sortDirections: ['descend', 'ascend'],
      //   width: 180,
      // },
      {
        title: (
          <span>
            Approved <br />
            For Billing
          </span>
        ),
        dataIndex: 'IsApprovedForBilling',
        key: 'IsApprovedForBilling',
        sorter: (a, b) => isComparer(a.IsApprovedForBilling, b.IsApprovedForBilling),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span>{record.IsApprovedForBilling ? 'Yes' : 'No'}</span>
            </div>
          );
        },
      },
      {
        title: (
          <span>
            Approved <br />
            For Batching
          </span>
        ),
        dataIndex: 'IsApprovedForBatching',
        key: 'IsApprovedForBatching',
        sorter: (a, b) => isComparer(a.IsApprovedForBatching, b.IsApprovedForBatching),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span>{record.IsApprovedForBatching ? 'Yes' : 'No'}</span>
            </div>
          );
        },
      },
      // {
      //   title: 'Program',
      //   dataIndex: 'ProgramDescription',
      //   key: 'ProgramDescription',
      //   sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
      //   sortDirections: ['descend', 'ascend'],
      //   width: 200,
      // },
      // {
      //   title: 'Duration',
      //   dataIndex: 'DurationInMin',
      //   key: 'DurationInMin',
      //   sorter: (a, b) => isComparer(a.DurationInMin, b.DurationInMin),
      //   sortDirections: ['descend', 'ascend'],
      //   render: (text, record) => {
      //     return (
      //       <div className="">
      //         <span className={record.IsBold ? 'font-weight-bold' : ''}>
      //           {text && timeConvert(text)}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      {
        title: (
          <span>
            Total <br />
            Amount
          </span>
        ),
        dataIndex: 'TotalAmount',
        key: 'TotalAmount',
        sorter: (a, b) => isNumericComparer(a.TotalAmount, b.TotalAmount),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        // fixed: 'right',
        width: '50px',
        render: (text, record, index) => (
          <div class="tools-grid">
            <>
              <span
                className="start-btn"
                onClick={() => {
                  this.props.dispatch(
                    listServiceLinePayment({
                      json: JSON.stringify({
                        ServiceLinePayment: [
                          {
                            ServiceLineId: record.ServiceLineId,
                          },
                        ],
                      }),
                    }),
                  );
                  this.setState({
                    tabView: false,
                    ServiceLineId: record.ServiceLineId,
                    PayerRecord: record,
                  });
                }}
              >
                Manage
              </span>
            </>
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page">
          {tabView ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="border-0">
                    <div className="mb-3">
                      <div className="row mt-5 d-flex align-items-center">
                        <div className="col-12 col-xl-2">
                          {' '}
                          <h4 className="">Payment</h4>
                        </div>
                        <div className="col-md-5 col-xl-3">
                          <ReactRangePicker
                            // value={[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]}
                            // defaultValue={[moment(Startdate, dateFormat), moment(Enddate, dateFormat)]}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-4 col-xl-3">
                          <div class="form-group call-schedule">
                            <ReduxSelect
                              options={FilterClientInsurance}
                              onChange={val => {
                                this.props.dispatch(
                                  listServiceLine({
                                    Json: JSON.stringify({
                                      ServiceLine: [
                                        {
                                          ClientInsurance: val,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                this.setState({
                                  filterBy: val,
                                });
                              }}
                              className="basic-single"
                              classNamePrefix="select"
                              value={this.state.filterBy}
                              placeholder="Filter By"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-8 col-xl-4 d-flex align-items-center justify-content-end">
                          <div className="search-box d-inline-flex w-100">
                            <div className="form-group has-search w-100 d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control 
                   shadow-sm rcm-document-search"
                                placeholder="Search"
                                id="filterbox"
                                onChange={this.onSearchChange}
                              />
                              <button className="btn search-icon " type="button">
                                {' '}
                                <i className="fa fa-search" />{' '}
                              </button>
                            </div>
                          </div>
                          {/* <button class="rcm-sub-tab-btn">Search</button> */}
                        </div>
                      </div>
                    </div>
                    <div className="row pt-5 pb-5 rcm-subtab-table-block">
                      <div className="col-12">
                        <Loader2 loading={isFetching} />
                        <PersistTableSort
                          name="group-service-line-list"
                          columns={tempColumns}
                          dataSource={ListServiceLine}
                          size={'middle'}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div className="dataTables_wrapper no-footer">
                                  <div className="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a className="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a className="paginate_button next">Next</a>
                                    ) : (
                                      <a className="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                          }}
                          // scroll={{ x: 1500, y: 300 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid px-0">
              <PaymentServiceLineDetail
                toggleView={this.toggleView}
                PayerRecord={this.state.PayerRecord}
                ServiceLineId={this.state.ServiceLineId}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ list_service_line }) => {
  const { data: serviceListData, isFetching } = list_service_line;

  let ListServiceLine = [];
  let FilterClientInsurance = [];

  if (serviceListData && serviceListData.ServiceLine) {
    ListServiceLine = serviceListData.ServiceLine || [];
  }
  if (serviceListData && serviceListData.FilterClientInsurance) {
    FilterClientInsurance = serviceListData.FilterClientInsurance || [];
  }
  return {
    isFetching,
    ListServiceLine: ListServiceLine,
    FilterClientInsurance,
  };
};

export default connect(mapStateToProps)(withRouter(Payment));
