import React, { useState, useEffect } from 'react';

const TextToSpeech = props => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const synth = window.speechSynthesis;

  useEffect(() => {
    if (isSpeaking) {
      Speech(props.speechText);
    } else {
      StopSpeech(true);
    }
  }, [isSpeaking]);

  const Speech = text => {
    synth.resume();
    // const voices = synth.getVoices();
    let word = new SpeechSynthesisUtterance(text);
    synth.speak(word);
  };
  const StopSpeech = isClear => {
    if (isClear) {
      // to clear queue
      synth.cancel();
    } else {
      synth.pause();
    }
  };
  const ResumeSpeech = () => {
    synth.resume();
  };
  const toggleTextToSpeech = () => {
    setIsSpeaking(prevValue => !prevValue);
  };
  return (
    <img
      src={isSpeaking ? 'images/icons/mute-speech.svg' : 'images/icons/speech.svg'}
      onClick={toggleTextToSpeech}
      style={{ cursor: 'pointer' }}
    />
  );
};
export default TextToSpeech;
