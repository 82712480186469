import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Modal, Button } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
  change,
} from 'redux-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getFormFieldMetaData,
  upsertFormData,
  formDataGet,
  resetFormDataGet,
  staffReactivate,
  staffDelete,
  adminstrationStaffUpsert,
  resetAdminstrationStaffUpsert,
  listCombos,
} from '../../../store/actions';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../core/generic-form';
import { Payer_Staff_Form } from '../../../app-config/screenName';
import AntdModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';
import CustomModal from '../../../core/modal';
import { ReduxSelect } from '../../../component/custom-redux-components';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Deactivate_Sucess_Msg,
  Deactivate_Fail_Msg,
} from '../../../app-config';
import { StaffMobileAppDetails } from '../../../app-config/screenName';
import PageTitle from '../../../Components/PageTitle';
class GerericForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
    };
  }

  // sendFormData = values => {
  //   let valueArray = Object.keys(values);
  //   const obj = {};
  //   for (const key of valueArray) {
  //     if (values[key] instanceof Date) {
  //       obj[key] = moment(values[key]).format('MM/DD/YYYY');
  //     } else {
  //       obj[key] = values[key];
  //     }
  //   }
  //   let jsonData = {
  //     DynamicForm: [
  //       {
  //         FormName: Staff_Form,
  //         FormDataId: this.props.getStaffId ? this.props.getStaffId : null,
  //         FormData: {
  //           Staff: [
  //             {
  //               ...obj,
  //             },
  //           ],
  //         },
  //       },
  //     ],
  //   };
  //   this.props.dispatch(
  //     upsertFormData({
  //       Json: JSON.stringify(jsonData),
  //     }),
  //   );
  // };

  componentDidMount() {
    let jsonData = {
      DynamicForm: [
        {
          FormName: Payer_Staff_Form,
          Id: this.props.getStaffId || null,
        },
      ],
    };
    this.props.dispatch(
      getFormFieldMetaData({
        Json: JSON.stringify(jsonData),
      }),
    );
    this.props.dispatch(resetFormDataGet({}));
  }
  toggle = (header, isRemoved) => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
      isRemoved,
    }));
  };
  onModalCancel = () => {
    this.setState({
      isModelOpen: false,
    });
  };
  componentWillReceiveProps({
    genericFormData,
    comboData,
    upsertDataSuccess,
    GenratedStaffId,
    isRemoved,
    recordInfo,
    isActivated,
    currentCountryValue,
    formData,
    isPractitionerTabRequired,
  }) {
    if (isActivated && isActivated != this.props.isActivated) {
      toast.success('Payer Staff reactivated successfully', {
        position: 'top-center',
        // autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          // this.props.history.push(
          //   `/manage/staff/staff-form?tab=basic-detail&staffId=${GenratedStaffId}`,
          // );
          let jsonData = {
            DynamicForm: [
              {
                FormName: Payer_Staff_Form,
                Id: this.props.getStaffId || null,
              },
            ],
          };
          this.props.dispatch(
            getFormFieldMetaData({
              Json: JSON.stringify(jsonData),
            }),
          );
        },
      });
    }
    if (isRemoved && isRemoved != this.props.isRemoved) {
      if (recordInfo == 1) {
        // this.toggle('Staff Deactivate_Sucess_Msg', recordInfo);
        toast.success('Payer Staff deactivated successfully', {
          position: 'top-center',
          // autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => {
            // this.props.history.push(
            //   `/manage/staff/staff-form?tab=basic-detail&staffId=${GenratedStaffId}`,
            // );
            let jsonData = {
              DynamicForm: [
                {
                  FormName: Payer_Staff_Form,
                  Id: this.props.getStaffId || null,
                },
              ],
            };
            this.props.dispatch(
              getFormFieldMetaData({
                Json: JSON.stringify(jsonData),
              }),
            );
          },
        });
      }
      if (recordInfo == -1) {
        this.toggle(Deactivate_Fail_Msg, recordInfo);
      }
    }

    if (upsertDataSuccess && upsertDataSuccess != this.props.upsertDataSuccess) {
      // toast.success('Well done! Staff has been added successfully.', {
      //   position: 'top-center',
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {
      //     // this.props.history.push(
      //     //   `/manage/staff/staff-form?tab=basic-detail&staffId=${GenratedStaffId}`,
      //     // );
      //     this.props.updateStep(GenratedStaffId);
      //   },
      // });
      this.props.dispatch(resetAdminstrationStaffUpsert());
      // this.props.dispatch(
      //   formDataGet({
      //     Json: JSON.stringify({
      //       DynamicForm: [
      //         {
      //           FormName: StaffMobileAppDetails,
      //           FormDataId: GenratedStaffId,
      //         },
      //       ],
      //     }),
      //   }),
      // );
      this.props.updateStep(GenratedStaffId, isPractitionerTabRequired);
    }

    if (currentCountryValue && currentCountryValue != this.props.currentCountryValue) {
      // this.props.dispatch(change('payerStaffForm', 'StateOrProvince', null));
      let formFields = this.state.formFields.map((item, index) => {
        if (item.name == 'StateOrProvince') {
          let currentOptions = item.DependentOptions.filter(item => {
            return item.Parent == currentCountryValue;
          })[0].Child;
          return {
            ...item,
            options: currentOptions || [],
          };
        } else {
          return item;
        }
      });

      this.setState({ formFields });
    }
    if (
      genericFormData &&
      JSON.stringify(genericFormData) != JSON.stringify(this.props.genericFormData)
    ) {
      let validationConversion = genericFormData;
      validationConversion = validationConversion.map((item, index) => {
        if (item.name == 'StateOrProvince') {
          let currentOptions = item.DependentOptions.filter(item => {
            return item.Parent == currentCountryValue;
          })[0].Child;
          return {
            ...item,
            options: currentOptions || [],
          };
        } else {
          return item;
        }
      });

      this.setState({ formFields: validationConversion });

      if (this.props.getStaffId) {
        let jsonData2 = {
          DynamicForm: [
            {
              FormName: Payer_Staff_Form,
              FormDataId: this.props.getStaffId,
            },
          ],
        };
        this.props.dispatch(
          formDataGet({
            Json: JSON.stringify(jsonData2),
          }),
        );
      } else {
        this.props.dispatch(reset('payerStaffForm'));
      }
    }
    if (formData.IsDeactivated) {
      this.props.getStaffStatus(formData.IsDeactivated, formData.IsPractitionerTabRequired);
    }
    if (
      formData.IsPractitionerTabRequired &&
      formData.IsPractitionerTabRequired != this.props.formData.IsPractitionerTabRequired
    ) {
      this.props.getStaffStatus(formData.IsDeactivated, formData.IsPractitionerTabRequired);
    }
  }

  render() {
    const { formFields, optionsList } = this.state;
    const {
      handleSubmit,
      comboData,
      onCancel,
      isDeactivated,
      getStaffId,
      dispatch,
      formData,
      isFetching,
    } = this.props;
    const { IsDeactivated, StaffId, CenterId } = formData;
    return (
      <React.Fragment>
        <PageTitle TitleText="Payer Staff" />
        <div className="main enrollment-page  mb-2">
          <div className="container-fluid">
            <form
              // onSubmit={handleSubmit(values => {
              //   this.sendFormData(values);
              // })}
              onSubmit={handleSubmit}
            >
              <div class="row top-heading-row mb-2 align-items-center d-none">
                {/* <div class="col-12 col-md-4 col-lg-3">
                  <h3 class="form-main-head mb-3 mb-md-0">
                    {this.props.getStaffId > 0 ? 'Edit Staff' : 'Add a Staff'}
                  </h3>
                </div> */}
                <div class="col-12 col-md-12 col-lg-12 text-right">
                  <button onClick={onCancel} class="btn  btn-outline-primary mr-2">
                    Cancel
                  </button>
                  {/* <button
                  onClick={() =>
                    this.props.dispatch(() => {
                      debugger;
                    })
                  }
                  class="btn"
                >
                  Save
                </button> */}
                  <input
                    type="submit"
                    class="btn"
                    value="Submit"
                    // disabled={IsDeActivated}
                  />
                </div>
              </div>

              <div className="" style={{ position: 'relative' }}>
                <Loader2 loading={isFetching} />

                <div className="enrollment-form staff-form pt-2 pb-2">
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    <DynamicForm formFields={formFields} disable={IsDeactivated} />
                  </div>
                </div>
              </div>
              {StaffId && (
                <>
                  {IsDeactivated ? (
                    <>
                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <a
                            class="reactivate-link"
                            data-toggle="modal"
                            data-target="#reactivate-staff-model"
                            onClick={onClick}
                          >
                            <i class="fa fa-check" aria-hidden="true" /> Reactivate
                          </a>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 class="modal-title col-12 mb-3">
                                Are you sure you want to reactivate this payer staff?
                              </h4>
                            </div>
                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                class="btn btn-outline-primary black-btn"
                                onClick={() => {
                                  onCancel();
                                  // var staffURL = '/manage/staff';
                                  // var centerId = getCenterId;
                                  // if (centerId && centerId > 0) {
                                  //   staffURL = '/manage/staff?centerId=' + centerId;
                                  // }
                                  // // if(staffId){
                                  // //   staffURL='/manage/staff?staffId='+getStaffId
                                  // // }
                                  // history.push(staffURL);
                                }}
                              >
                                No
                              </button>
                              <button
                                class="btn"
                                onClick={() => {
                                  onOk();
                                  dispatch(staffReactivate({ staffId: getStaffId }));
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <a
                            class="deactivate-link"
                            data-toggle="modal"
                            data-target="#deactivate-staff-model"
                            onClick={onClick}
                          >
                            <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                          </a>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 class="modal-title col-12 mb-3">
                                Are you sure you want to deactivate this payer staff?
                              </h4>
                            </div>
                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                class="btn btn-outline-primary black-btn"
                                onClick={() => {
                                  onCancel();
                                  // var staffURL = '/manage/staff';
                                  // var centerId = getCenterId;
                                  // if (centerId && centerId > 0) {
                                  //   staffURL = '/manage/staff?centerId=' + centerId;
                                  // }
                                  // // if(staffId){
                                  // //   staffURL='/manage/staff?staffId='+getStaffId
                                  // // }
                                  // history.push(staffURL);
                                }}
                              >
                                No
                              </button>
                              <button
                                class="btn"
                                onClick={() => {
                                  onOk();
                                  dispatch(staffDelete({ staffId: getStaffId }));
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </form>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          <Modal visible={this.state.isModelOpen} footer={null} onCancel={this.onModalCancel}>
            <DeactivatingModalRedux
              header={this.state.header}
              centerIdFromParent={CenterId}
              onModalCancel={this.onModalCancel}
              isRemoved={this.state.isRemoved}
            />
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { combos, get_form_field_meta_data, staff_upsert, staff_delete, staff_reactivate } = state;
  const { data: getGenericFormFields } = get_form_field_meta_data;
  const { data: upsertData, isFetching } = staff_upsert;
  let upsertDataSuccess;
  let comboData = {};
  let genericFormData = [];
  let staffId, isPractitionerTabRequired;
  var isRemoved = '';
  var isActivated = '';
  var recordInfo = false;
  if (staff_delete && staff_delete.data && staff_delete.data.success) {
    isRemoved = staff_delete.data.success;
    recordInfo = staff_delete.data.result;
  }
  if (staff_reactivate && staff_reactivate.data && staff_reactivate.data.success) {
    isActivated = staff_reactivate.data;
  }
  if (combos && combos.data) {
    comboData = combos.data || {};
  }
  if (getGenericFormFields) {
    genericFormData = getGenericFormFields.FormFieldMetaData;
  }
  if (upsertData && upsertData.success) {
    const { StaffId, IsPractitionerTabRequired } = JSON.parse(upsertData.data).Staff[0];
    staffId = StaffId;
    isPractitionerTabRequired = IsPractitionerTabRequired;
    upsertDataSuccess = upsertData.success;
  }

  const currentCountryValue = formValueSelector('payerStaffForm')(state, 'CountryISO');
  return {
    genericFormData,
    comboData,
    upsertDataSuccess,
    GenratedStaffId: staffId,
    isPractitionerTabRequired,
    isRemoved,
    recordInfo,
    isActivated,
    currentCountryValue,
    isFetching,
  };
};

class DeactivatingModal extends React.Component {
  state = {
    newStaffValue: '',
  };

  handleStaffChange = newStaffValue => {
    this.setState({ newStaffValue });
  };

  componentDidMount() {
    this.props.dispatch(
      listCombos({
        CenterId: this.props.centerIdFromParent,
        comboTypes: 'StaffByCenterId',
      }),
    );
  }

  getStaffId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffid');
    if (staffId) {
      staffId = parseInt(staffId);
    } else {
      staffId = -1;
    }
    return staffId;
  }
  error = () => {
    Modal.error({
      content: 'Please Select New Staff',
    });
  };
  render() {
    const { onOk, onCancel, staffByCenter, header, onModalCancel, isRemoved } = this.props;
    const { newStaffValue } = this.state;
    const staffId = this.getStaffId();
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          {/* <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button> */}
          <h4 class="modal-title col-12">{header}</h4>
        </div>
        {isRemoved == -1 && (
          <div class="modal-body col-md-8 offset-md-2">
            <div class="form-group">
              <ReduxSelect
                options={staffByCenter}
                onChange={this.handleStaffChange}
                className="basic-single"
                classNamePrefix="select"
                value={newStaffValue}
                placeholder="Select New Staff "
              />
            </div>
          </div>
        )}
        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" class="btn black-btn font-weight-bold mr-2" onClick={onModalCancel}>
            {isRemoved == -1 ? 'Cancel' : 'OK'}
          </button>
          {isRemoved == -1 && (
            <button
              type="button"
              onClick={() => {
                if (newStaffValue != staffId) {
                  this.props.dispatch(
                    staffDelete({ staffId: staffId, newStaffId: this.state.newStaffValue }),
                  );
                  // onOk();
                  this.props.history.push('/manage/payer-staff-management/payer-staff-list');
                } else this.error();
              }}
              class="btn"
            >
              Move
            </button>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToPropsDeactivate = ({ combos, staff_delete }) => {
  const { data } = combos;
  let staffByCenter = [];
  if (data.StaffByCenterId) {
    staffByCenter = data.StaffByCenterId;
  }
  return {
    staffByCenter: staffByCenter,
  };
};

const DeactivatingModalRedux = connect(mapStateToPropsDeactivate)(withRouter(DeactivatingModal));

const ReduxGenericForm = reduxForm({
  form: 'payerStaffForm',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    let valueArray = Object.keys(values);
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object') {
        obj[key] =
          values[key] && values[key].length > 0
            ? values[key].map(item => item.Value).toString()
            : null;
      } else {
        obj[key] = values[key];
      }
    }
    const data = {
      Staff: [
        {
          ...obj,
          IsProvider: false,
          Misc: null,
        },
      ],
    };
    let jsonData = {
      staffId: props.getStaffId ? props.getStaffId : 0,
      email: data.Staff[0].EmailWork,
      jsonInput: JSON.stringify(data),
    };
    dispatch(adminstrationStaffUpsert(jsonData));
  },
})(connect(mapStateToProps)(withRouter(GerericForm)));

const selector = formValueSelector('payerStaffForm');

class GenericFormParent extends React.Component {
  constructor() {
    super();
    this.state = {
      initialValues: {},
    };
  }

  // componentWillReceiveProps(initialValues) {
  //   if (
  //     initialValues &&
  //     JSON.stringify(initialValues) != JSON.stringify(this.props.initialValues)
  //   ) {
  //     this.setState({
  //       initialValues: initialValues,
  //     });
  //   }
  // }

  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };

  render() {
    return (
      <>
        <ReduxGenericForm
          initialValues={this.props.initialValues}
          getStaffId={this.getStaffId()}
          onCancel={() =>
            this.props.history.push('/manage/payer-staff-management/payer-staff-list')
          }
          updateStep={this.props.updateStep}
          formData={this.props.initialValues}
          getStaffStatus={this.props.getStaffStatus}
        />
      </>
    );
  }
}

const mapStateToParent = state => {
  const { form_data_get } = state;
  const { data, isFetching, error } = form_data_get;
  let formData = {};
  if (data && data.Staff[0]) {
    formData = data.Staff[0];
  }

  return {
    isFetching,
    initialValues: { CountryISO: 'USA', ...formData },
  };
};

export default connect(mapStateToParent)(withRouter(GenericFormParent));
