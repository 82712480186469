import React, { Component } from 'react';
import LicenceExpired from './licenceExpired';
import TrialExpired from './trialExpired';
import TrialExpiredSoon from './trialExpiredSoon';
import TrialNotStarted from './trialNotStarted';

class CommonLicenceScreens extends Component {
  renderLicense = LicenseStatusId => {
    // const { StatusData, moduleName } = this.props;
    switch (LicenseStatusId) {
      case 1:
        return <TrialNotStarted {...this.props} />;
      case 2:
        return <TrialExpiredSoon {...this.props} />;
      case 3:
        return <TrialExpired {...this.props} />;
      case 5:
        return <LicenceExpired {...this.props} />;
      default:
        return null;
    }
  };

  render() {
    const { StatusData } = this.props;
    const { LicenseStatusId } = StatusData || {};
    return (
      <div className="module-licence">
        <div className="mt-4 mb-2"> {this.renderLicense(LicenseStatusId)}</div>
      </div>
    );
  }
}

export default CommonLicenceScreens;
