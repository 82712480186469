import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';
import {
  termsConditionListGet,
  termsConditionGetAllInfoJson,
  termsConditionDelete,
} from '../../store/actions';
import { isComparer,isNumericComparer } from '../../util/handler';
import { Loader2 } from '../../Components/Loader';
import { ProfilePicComponent } from '../../Components/profilePicComponent';
import { PopoverMenu } from '../../Components/PopoverMenu';
import AntdModal from '../../Components/CustomModal';
import CustomModal from '../../core/modal';
import PageTitle from '../../Components/PageTitle';
class TermsConditionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goalsData: {},
    };
  }
  fetchDataGrid = () => {
    this.props.dispatch(termsConditionListGet({}));
  };
  componentDidMount() {
    this.fetchDataGrid();
  }
  componentWillReceiveProps({ isDeleted }) {
    if (isDeleted && isDeleted != this.props.isDeleted) {
      this.toggle('Terms Condition Deleted Successfully');
    }
  }
  onSearchChange = value => {
    this.state.searchStr = value;
    this.props.dispatch(
      termsConditionListGet({
        JsonInput: JSON.stringify({
          TermsCondition: [
            {
              SearchStr: value,
            },
          ],
        }),
      }),
    );
  };
  getPopOverData = record => {
    return (
      <>
        <p
          onClick={() => {
            this.props.history.push(
              `/cw/manage/terms-conditions-form?tcid=${record.TermsConditionId}`,
            );
          }}
        >
          Edit
        </p>
        <p
          onClick={() => {
            this.props.history.push(
              `/cw/manage/terms-conditions-form?tcid=${record.TermsConditionId}&&view=true`,
            );
          }}
        >
          View
        </p>
        <AntdModal
          ModalButton={({ onClick }) => (
            <p style={{ color: '#FF3B3B' }} onClick={onClick} data-target="#move-intake-model">
              Delete
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">Are you sure you want to delete?</h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No, keep
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      termsConditionDelete({
                        JsonInput: JSON.stringify({
                          TermsCondition: [
                            {
                              TermsConditionId: record.TermsConditionId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          )}
        />
      </>
    );
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const { termsCondition, isFetching } = this.props;
    const { isModelOpen, header } = this.state;

    const columns = [
      {
        title: 'Term ID',
        dataIndex: 'TermsConditionId',
        key: 'TermsConditionId',
        sorter: (a, b) => isNumericComparer(a.TermsConditionId, b.TermsConditionId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Title',
        dataIndex: 'Title',
        key: 'Title',
        width: 280,
        sorter: (a, b) => isComparer(a.Title, b.Title),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Total Terms',
        dataIndex: 'TotalTerms',
        key: 'TotalTerms',
        sorter: (a, b) => isComparer(a.TotalTerms, b.TotalTerms),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Total Conditions',
        dataIndex: 'TotalConditions',
        key: 'TotalConditions',
        sorter: (a, b) => isComparer(a.TotalConditions, b.TotalConditions),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Added By',
        dataIndex: 'AddedBy',
        key: 'AddedBy',
        sorter: (a, b) => isComparer(a.AddedBy, b.AddedBy),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <div class="d-flex align-items-center">
            <ProfilePicComponent src={record.ProfileImage} alt={text} />
            <span className="mx-2">{text}</span>
          </div>
        ),
      },

      {
        title: 'Action',
        dataIndex: '',
        key: 'x',
        render: (text, record) => (
          <div className="tools-grid mx-2" onClick={event => event.stopPropagation()}>
            <PopoverMenu
              OptionData={this.getPopOverData(record)}
              placement="left"
              //   trigger="hover"
            />
          </div>
        ),
      },
    ];
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Terms & Conditions" />
        <div class="content-area">
          <div class="row top-table-search mt-3 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/cw/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Terms & Conditions</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control 
                   shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    onKeyUp={event => {
                      this.onSearchChange(event.target.value);
                    }}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>

              <input
                type="button"
                class="btn px-5"
                value="Add Terms & Conditions"
                onClick={() => {
                  this.props.history.push('/cw/manage/terms-conditions-form');
                }}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="">
                      <Table
                        columns={columns}
                        dataSource={termsCondition}
                        size={'middle'}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            header={header}
            yesAction={() => {
              this.toggle();
              this.fetchDataGrid();
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ combos, terms_condition_list_get, terms_condition_delete }) => {
  let termsCondition = [];
  let isDeleted = false;

  const { data, isFetching, error } = terms_condition_list_get;
  const { data: deleteData } = terms_condition_delete;

  if (data && data.TermsCondition) {
    termsCondition = data.TermsCondition || [];
  }
  if (deleteData && deleteData.success) {
    isDeleted = deleteData.success;
  }

  return {
    termsCondition,
    isFetching,
    isDeleted,
  };
};
export default connect(mapStateToProps)(withRouter(TermsConditionsList));
