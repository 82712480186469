import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tooltip, Switch, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { isComparer, isDateComparer, isNumericComparer } from '../../../../util/handler';
import {
  listClientDocument,
  archiveClientDocument,
  upsertClientDocument,
  UploadDocumentUsingMultiPart,
  downloadClientDocument,
  resetDownloadClientDocument,
  upsertClientDocumentReset,
  archiveDocumentReset,
} from '../../../../store/actions';
import Loader, { Loader2 } from '../../../../Components/Loader';
import ByteToSize from '../../../../util/ByteToSize';
import moment from 'moment';
import CustomModal from '../../../../Components/CustomModal';
import CustomModalPopup from '../../../../core/modal';
import Dropzone from 'react-dropzone';
import { Line } from 'rc-progress';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
} from '../../../../component/custom-redux-components';
import PersistTableSort from '../../../../component/persistTableSort';
import MultiSelectCheckboxes from '../../../../component/custom-components/multiSelectCheckboxes';
import DocumentView from '../../../docView';
import { ProcessCombos, ProcessFilterCombos } from '../../../../store/comboConfig';
import DownloadDocument from '../../../customScreens/downloadDocument';

class UploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploadedDataList: [],
      percent: 100,
      errorList: [],
      isUploaded: false,
      uploadError: null,
      documentGuid: '',
    };
  }

  // static getDerivedStateFromProps(props, state) {
  //   const { isUploaded, uploadError, documentGuid } = props;
  //   debugger;
  //   if (isUploaded && isUploaded != state.isUploaded) {
  //     debugger;
  //     // props.dispatch(upsertClientDocumentReset());
  //     props.toggle('Document(s) uploaded successfully');
  //     props.onOk();
  //     return { files: [] };
  //   }
  //   if (uploadError && uploadError != props.uploadError) {
  //     // props.dispatch(upsertClientDocumentReset());
  //     props.toggle(uploadError);
  //     props.onOk();
  //     return { files: [] };
  //   } else {
  //     return null;
  //   }
  // }
  // componentWillReceiveProps({ isUploaded, uploadError }) {
  //   debugger;
  //   if (isUploaded && isUploaded != this.props.isUploaded) {
  //     debugger;
  //     this.setState({ files: [] });
  //     this.props.dispatch(upsertClientDocumentReset());
  //     this.props.toggle('Document(s) uploaded successfully');
  //     this.props.onOk();
  //   }
  //   if (uploadError && uploadError != this.props.uploadError) {
  //     this.setState({ files: [] });
  //     this.props.dispatch(upsertClientDocumentReset());
  //     this.props.toggle(uploadError);
  //     this.props.onOk();
  //   }
  // }

  onDrop = files => {
    // let obj = files.length > 0 && files[0];
    let me = this;

    let dataList = [];

    files.map((obj, index) => {
      let fileName = obj.name.substr(0, obj.name.lastIndexOf('.'));
      let fileExtention = obj.name.substr(obj.name.lastIndexOf('.') + 1);
      dataList.push({
        name: obj.name,
        size: obj.size,
        //data: uploadedData,
        docName: fileName,
        fileExtention: fileExtention,
        documentType: this.props.DocTypeValue,
      });
    });
    me.setState({
      uploadedDataList: [...me.state.uploadedDataList, ...dataList],
      files: [...me.state.files, ...files],
    });
  };

  documentTypeChange = (list, index, documentType) => {
    let stateList = this.state.uploadedDataList;
    let selectedDocType =
      typeof documentType == 'object'
        ? documentType.map(item => item.Value).toString()
        : documentType || '';
    stateList[index] = { ...list, documentType: selectedDocType };
    this.setState({
      uploadedDataList: stateList,
    });
  };
  documentNameChange = (list, index, docName, inputName) => {
    let stateList = this.state.uploadedDataList;
    stateList[index] = { ...list, docName: docName };
    let errorList = this.state.errorList;
    if (docName === '') {
      errorList.push(inputName);
    } else {
      if (errorList.includes(inputName)) {
        errorList.splice(errorList.indexOf(inputName), 1);
      }
    }
    this.setState({
      uploadedDataList: stateList,
      [inputName]: docName,
      errorList: errorList,
    });
  };
  handleConfidentialDocument = (list, index, isConfidential) => {
    let stateList = this.state.uploadedDataList;
    stateList[index] = { ...list, isConfidential: isConfidential };
    this.setState({
      uploadedDataList: stateList,
    });
  };
  UploadDocument = () => {
    const { dispatch, clientId, onOk } = this.props;
    const { uploadedDataList } = this.state;
    let dataArray = [];
    uploadedDataList.map((item, index) => {
      const { data, docName, documentType, name, size, fileExtention, isConfidential } = item;
      dataArray.push({
        ClientId: clientId,
        DocumentName: docName,
        DocumentTypeId: documentType,
        UploadedFileFullName: name,
        FileSizeInByte: size,
        //UploadData: data,
        Extension: fileExtention,
        IsConfidential: isConfidential,
      });
    });
    let json = {
      Document: dataArray,
    };
    var jsonData = {
      json: JSON.stringify(json),
    };

    var param = jsonData;
    const formData = new FormData();
    for (var property in param) {
      formData.append(property, param[property]);
    }
    var files = this.state.files;
    if (files) {
      for (var file of files) {
        formData.append('file', file);
      }
    }
    dispatch(UploadDocumentUsingMultiPart(formData));
    this.props.onOk();
  };

  render() {
    const {
      onOk,
      onCancel,
      dispatch,
      DocumentType,
      Extensions,
      isFetching,
      DocTypeValue,
    } = this.props;
    const { uploadedDataList, documentName, documentType } = this.state;
    const remove = index => {
      const newFiles = [...uploadedDataList]; // make a var for the new array
      newFiles.splice(index, 1); // remove the file from the array
      this.setState({
        uploadedDataList: newFiles,
      });
    };
    const filesList = uploadedDataList.map((list, index) => {
      return (
        <div className="document-list-item rcm-tab">
          <div className="row">
            <div class="col-10 enrollment-form">
              <div class="form-group mb-2">
                <div>
                  <input
                    name={`${list}.docName`}
                    type="text"
                    class={`form-control ${list.docName && 'not-empty'}`}
                    value={list.docName}
                    onChange={event => {
                      let docName = event.target.value;
                      let inputName = `${index}.docName`;
                      this.documentNameChange(list, index, docName, inputName);
                    }}
                  />
                  <label className="placeholder-label">Document Name</label>
                  {this.state[`${index}.docName`] === '' ? (
                    <label className="text-danger">Name Required</label>
                  ) : null}
                </div>
              </div>
            </div>
            <div class="col-2 col-md-2 text-right">
              <Tooltip placement="top" title="Remove">
                <span class="delete-course " onClick={() => remove(index)}>
                  <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                    <img src="images/circle-cross.svg" alt="" width="35" />
                  </figure>
                </span>
              </Tooltip>
            </div>
            <div class="col-12 enrollment-form document-upload">
              <div class="form-group">
                <ReduxSelect
                  name={`${list}.documentType`}
                  options={DocumentType}
                  onChange={documentType => {
                    if (!documentType) {
                      this.documentTypeChange(list, index, DocTypeValue);
                    } else {
                      this.documentTypeChange(list, index, documentType);
                    }
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={list.documentType}
                  placeholder="Document Type(s)/Label(s)/Tag(s)"
                  // disabled={DocTypeValue ? true : false}
                  isMulti
                />
              </div>
            </div>
          </div>
          <div className="" style={{ marginBottom: '20px' }}>
            <p className="d-flex mb-0 justify-content-between">
              <span>{list.name}</span>
              <span>{ByteToSize(list.size)}</span>
            </p>
            <Line
              percent={this.state.percent}
              strokeWidth="1"
              strokeColor="#2FE192"
              strokeLinecap="round"
            />
          </div>
          <div class="form-group mb-2">
            <div className="custom-control" style={{ padding: 'unset' }}>
              <label className="custom-control-label">
                <Switch
                  ame={`${list}.isConfidential`}
                  checked={list.isConfidential}
                  onClick={isConfidential => {
                    this.handleConfidentialDocument(list, index, isConfidential);
                  }}
                  size="small"
                />
                &nbsp; Is Confidential
              </label>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div class="modal-content border-0">
        <Loader2 loading={isFetching} />
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Upload Document(s)</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0 upload-document-modal">
            <div className="col-12 px-0">
              <Dropzone
                onDrop={this.onDrop}
                accept={Extensions.join() || '.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf'}
                // accept={Extensions}
                maxSize={20971520}
                // maxSize={2097152}
                // minSize={}
                children={args => {}}
                // maxSize="100"
              >
                {({ getRootProps, getInputProps, acceptedFiles, fileRejections }) => (
                  <section className="container upload-document">
                    <div {...getRootProps({ className: 'dropzone' })} style={{ cursor: 'pointer' }}>
                      <input {...getInputProps()} />
                      <div className="d-flex align-items-baseline justify-content-center">
                        <p className="dropzone-upload-drag-icon mr-2">
                          <Icon type="upload" />
                        </p>
                        <p className="dropzone-upload-text"> Upload multiple documents</p>
                      </div>
                      <p className="dropzone-upload-text-2">
                        Drag and Drop Here or Browse Documents
                      </p>
                    </div>
                    <aside>
                      <div className="mt-4">{filesList}</div>
                      {/* {console.log('acceptedFiles:')}
                      {console.log(acceptedFiles)} */}
                      {fileRejections && fileRejections.length > 0 && (
                        <div className="mt-1 rejections-list">
                          <h6>Rejected Documents</h6>
                          <ul>
                            {fileRejections.map(({ file, errors }) => (
                              <li key={file.path}>
                                {file.path} - {ByteToSize(file.size)}
                                <ul>
                                  {errors.map(e => (
                                    <li key={e.code} className="text-danger">
                                      {e.message}
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </aside>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          {/* <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              // onCancel();
              this.resetState();
            }}
          >
            Reset
          </a> */}
          <button
            type="button"
            class="btn"
            onClick={this.UploadDocument}
            disabled={this.state.errorList.length > 0 || this.state.uploadedDataList.length == 0}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsUpload = ({ combos, upload_document_using_multiPart }) => {
  // const { data: UploadData, isFetching, error: errorUpload } = upload_document_using_multiPart;
  let uploadError;
  let isUploaded = false;
  let Extensions = [];
  let DocumentType = [];
  // if (UploadData && UploadData.success) {
  //   isUploaded = UploadData.success;
  // } else if (errorUpload) {
  //   uploadError = errorUpload;
  // }

  if (combos.data) {
    if (combos.data.Extension)
      for (let extension of combos.data.Extension) {
        Extensions.push(extension.Text);
      }

    if (combos.data.DocumentType)
      for (var documentType of combos.data.DocumentType) {
        DocumentType.push(documentType);
      }
  }

  return {
    DocumentType,
    Extensions,
    isFetching: false,
    isUploaded,
    uploadError,
  };
};

// const ReduxUploadModal = withRouter(UploadModal);
const ReduxUploadModal = connect(mapStateToPropsUpload)(UploadModal);

class DocumentUpload extends Component {
  state = {
    searchStr: null,
    isModelOpen: false,
    isUploadedModelOpen: false,
    StudentQuota: {
      Total: 0,
      Used: 0,
      Percent: 0,
    },
    filterTags: [],
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  getStudentId() {
    // var param = new URLSearchParams(this.props.history.location.search);
    // return param.get('studentid');
    return this.props.clientId;
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  toggleUploaded = header => {
    this.setState(prevState => ({
      isUploadedModelOpen: !prevState.isUploadedModelOpen,
      header,
    }));
  };

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount({ searchStr } = this.state) {
    const { dispatch, clientId, DocTypeValue } = this.props;
    let json = {
      ClientDocument: [
        {
          ClientId: clientId || this.getClientId(),
          DocumentType: DocTypeValue,
        },
      ],
    };
    var data = {
      json: JSON.stringify(json),
    };
    dispatch(listClientDocument(data));
    var result = ProcessCombos('DocumentType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  resetDownloads = () => {
    this.props.dispatch(resetDownloadClientDocument());
  };
  componentWillReceiveProps({
    downloadUrl,
    isDownloadClicked,
    isArchived,
    documentError,
    StudentCenterObj,
    isUploaded,
    uploadedDataGuid,
    isUpsertDocument,
  }) {
    if (isUpsertDocument && isUpsertDocument != this.props.isUpsertDocument) {
      this.props.dispatch(upsertClientDocumentReset());
      this.toggle('Document(s) Uploaded successfully');
      // this.props.dispatch(
      //   listClientDocument({
      //     json: JSON.stringify({
      //       ClientDocument: [
      //         {
      //           ClientId: this.props.clientId,
      //           DocumentType: this.props.DocTypeValue,
      //         },
      //       ],
      //     }),
      //   }),
      // );
    }
    if (isUploaded && isUploaded != this.props.isUploaded) {
      // this.props.dispatch(archiveDocumentReset());
      let data = JSON.parse(uploadedDataGuid)[0].Document.map(item => {
        return { ...item, ClientId: this.props.clientId };
      });
      this.props.dispatch(
        upsertClientDocument({
          json: JSON.stringify({
            ClientDocument: data,
          }),
        }),
      );
    }
    if (isDownloadClicked && downloadUrl && downloadUrl != this.props.downloadUrl) {
      window.open(downloadUrl);
      this.resetDownloads();
    }
    if (isArchived && isArchived != this.props.isArchived) {
      this.props.dispatch(archiveDocumentReset());
      this.toggle('Document archived successfully');
      // this.props.dispatch(
      //   listClientDocument({
      //     json: JSON.stringify({
      //       ClientDocument: [
      //         {
      //           ClientId: this.props.clientId,
      //           DocumentType: this.props.DocTypeValue,
      //         },
      //       ],
      //     }),
      //   }),
      // );
    }
    // if (downloadUrl && downloadUrl != this.props.downloadUrl) {
    //   window.open(downloadUrl);
    // }
    if (documentError && documentError != this.props.documentError) {
      this.props.dispatch(archiveDocumentReset());
      this.toggle(documentError);
    }

    if (JSON.stringify(StudentCenterObj) != JSON.stringify(this.props.StudentCenterObj)) {
      let Total = StudentCenterObj.UploadDocumentSizeLimitInByte;
      let Used = StudentCenterObj.TotalUploadedByte;
      let Percent = (Used * 100) / Total;
      this.setState({
        StudentQuota: {
          Total: ByteToSize(Total),
          Used: ByteToSize(Used),
          Percent: Number.isInteger(Percent) ? Percent : Percent.toFixed(2),
        },
      });
    }
  }
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    const { dispatch } = this.props;
    if (value.length == 0) {
      dispatch(
        listClientDocument({
          json: JSON.stringify({
            ClientDocument: [
              {
                ClientId: this.props.clientId,
                Search: this.state.searchStr,
                DocumentType: this.props.DocTypeValue,
              },
            ],
          }),
        }),
      );
    } else {
      dispatch(
        listClientDocument({
          json: JSON.stringify({
            ClientDocument: [
              {
                ClientId: this.props.clientId,
                Search: this.state.searchStr,
                DocumentType: this.props.DocTypeValue,
              },
            ],
          }),
        }),
      );
    }
  };

  // onRowClick = (event, record, rowIndex) => {
  //   const { history } = this.props;
  //   // history.push('/manage/course-form?courseId=' + record.CourseId);
  // };

  columns = [
    {
      title: 'Document Name',
      dataIndex: 'DocumentName',
      key: 'DocumentName',
      sorter: (a, b) => isComparer(a.DocumentName, b.DocumentName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div className="d-flex align-items-baseline">
          {text}
          {record.IsConfidential && (
            <Tooltip
              placement="top"
              title={`Access ${
                record.IsAuthorized ? 'Authorized' : 'Restricted '
              } : Confidential Document`}
            >
              <img
                src={`images/icons/lock_${record.IsAuthorized ? 'green' : 'red'}.svg`}
                className="mx-2"
                alt="Confidential Document"
              />
            </Tooltip>
          )}
        </div>
      ),
    },

    {
      title: 'Document Type(s)/Label(s)/Tag(s)',
      dataIndex: 'DocumentType',
      key: 'DocumentType',
      // render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isComparer(a.DocumentType, b.DocumentType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Upload Date',
      dataIndex: 'UploadedDate',
      key: 'UploadedDate',
      sorter: (a, b) =>
        isDateComparer(moment(a.UploadedDateForSort), moment(b.UploadedDateForSort)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Filename',
      dataIndex: 'UploadedFileFullName',
      key: 'UploadedFileFullName',
      // render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isComparer(a.UploadedFileFullName, b.UploadedFileFullName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Size',
      dataIndex: 'FileSize',
      key: 'FileSize',
      // render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isNumericComparer(a.FileSizeInByte, b.FileSizeInByte),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      fixed: 'right',
      render: (text, record) =>
        record.IsAuthorized && (
          <div className="d-flex align-items-end" style={{ minWidth: '120px' }}>
            {/* <DownloadDocument
              ID={this.props.clientId}
              documentGuid={record.DocumentGuid}
              // downloadText={UploadedFileFullName}
              isClinical={true}
              downloadIcon={'images/view.svg'}
              isReadOnlyUser={this.props.isReadOnlyUser}
              extension={record.Extension}
              allowDownload={false}
              isHelperIcon={false}
            /> */}
            <CustomModal
              // mask={true}
              modalWidth={800}
              maskClosable={false}
              ModalButton={({ onClick }) => (
                // <span className="btn px-3" value="View" onClick={onClick} />
                <span
                  style={{
                    color: '#FA8602',
                    fontSize: '14px',
                    cursor: 'pointer',
                    color: 'rgb(70, 180, 254)',
                  }}
                  className="mr-3"
                  data-toggle="modal"
                  data-target="#view-model"
                  onClick={() => {
                    this.props.dispatch(
                      downloadClientDocument({
                        json: JSON.stringify({
                          ClientDocument: [
                            {
                              ClientId: this.props.clientId,
                              DocumentGuid: record.DocumentGuid,
                            },
                          ],
                        }),
                        IsDownloadClicked: false,
                      }),
                    );
                    onClick();
                  }}
                >
                  <Tooltip placement="top" title="View">
                    <img src="images/view.svg" alt="" />
                  </Tooltip>
                </span>
              )}
              ModalContent={props => (
                <DocumentView
                  {...props}
                  dispatch={this.props.dispatch}
                  ClientId={this.props.clientId}
                  ClientDocumentGuid={record.DocumentGuid}
                  DocumentExtension={record.Extension}
                  isClinical={true}
                  reset={() => {
                    this.resetDownloads();
                  }}
                  isReadOnlyUser={this.props.isReadOnlyUser}
                />
              )}
            />
            {!this.props.isReadOnlyUser && (
              <span
                onClick={() => {
                  this.props.dispatch(
                    downloadClientDocument({
                      json: JSON.stringify({
                        ClientDocument: [
                          {
                            ClientId: this.props.clientId,
                            DocumentGuid: record.DocumentGuid,
                          },
                        ],
                      }),
                      IsDownloadClicked: true,
                    }),
                  );
                }}
                // {"StudentDocument":[{"StudentId":5,"StudentDocumentGuid":"E6B92DDA-B2CD-4615-B19D-521087E388E9"}]}
                // href={record.StorageLocation}
                className="mr-3"
                style={{ color: '#46B4FE', fontSize: '14px', cursor: 'pointer' }}
                download
              >
                <Tooltip placement="top" title="Download">
                  <img src="images/icons/download.svg" style={{ height: '27px' }} />
                </Tooltip>
              </span>
            )}

            {record.IsArchived ? (
              'Archived'
            ) : (
              <CustomModal
                ModalButton={({ onClick }) => (
                  <>
                    {!this.props.isReadOnlyUser && (
                      <span
                        style={{ color: '#FA8602', fontSize: '14px', cursor: 'pointer' }}
                        className="mr-2"
                        data-toggle="modal"
                        data-target="#archive-model"
                        onClick={onClick}
                      >
                        {/* {'Archive'} */}
                        <Tooltip placement="top" title="Archive">
                          <img src="images/archive.svg" alt="" />
                        </Tooltip>
                      </span>
                    )}
                  </>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to <br />
                        <strong>{'Archive'}</strong>?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            archiveClientDocument({
                              json: JSON.stringify({
                                Document: [
                                  {
                                    // ClientId: this.props.clientId,
                                    DocumentGuid: record.DocumentGuid,
                                  },
                                ],
                              }),
                            }),
                          );
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            )}

            {/* <span
              onClick={() => {
                this.props.dispatch(
                  archiveClientDocument({
                    json: JSON.stringify({
                      StudentDocument: [
                        {
                          StudentId: this.getStudentId(),
                          StudentDocumentGuid: record.StudentDocumentGuid,
                        },
                      ],
                    }),
                  }),
                );
              }}
              className=""
              className="mr-2"
              style={{ color: '#FA8602', fontSize: '14px', cursor: 'pointer' }}
            >
              Archive
            </span> */}
          </div>
        ),
    },
  ];
  getDocumentFilter = value => {
    this.setState({
      filterTags: value,
    });
    let DocTypeValue = value && value.map(item => item.value);
    this.props.dispatch(
      listClientDocument({
        json: JSON.stringify({
          ClientDocument: [
            {
              ClientId: this.props.clientId || this.getClientId(),
              DocumentType: DocTypeValue && DocTypeValue.toString(),
            },
          ],
        }),
      }),
    );
  };
  render() {
    const {
      isFetching,
      StudentInfo,
      DocumentList,
      TabName,
      DocumentTagType,
      isDeActivated,
    } = this.props;
    const { StudentQuota, filterTags } = this.state;
    // const [visible, setVisible] = useState(false);
    let strokeColor;
    // if (StudentQuota.Percent < 25) {
    //   strokeColor = '#85D262';
    // } else if (StudentQuota.Percent > 25 && StudentQuota.Percent < 85) {
    //   strokeColor = '#3FC7FA';
    // } else if (StudentQuota.Percent > 85) {
    //   strokeColor = '#e87a7a';
    // }
    return (
      <div class="main mb-5">
        <div class="container-fluid">
          {/* <Loader loading={isFetchingUpload} /> */}
          <div class="row top-table-search  mb-4 align-items-center quota-alert">
            <div class="col-12 pb-2 d-none">
              <div className="quota-alert-bar">
                <span className="bar-text">
                  You have reached your maximum storage space for uploading documents. Please see
                  your Administrator to increase your capacity.
                </span>
              </div>
            </div>
            <div class="col-12">
              {/* StudentQuota Bar */}
              <div className="">
                <div className="mb-3 user-quota-bar">
                  {StudentQuota.Total != 0 && (
                    <>
                      <div className="d-flex justify-content-between">
                        <span className="used-quota-val">
                          Used {StudentQuota.Used} / {StudentQuota.Total}
                        </span>
                        <span className="used-quota-per">{StudentQuota.Percent}%</span>
                      </div>
                      <Line
                        className="mt-2"
                        percent={StudentQuota.Percent || 0}
                        // percent={50}
                        strokeWidth="1"
                        // strokeColor={strokeColor}
                        strokeColor="#2FE192"
                        strokeLinecap="round"
                        trailColor="rgba(47, 225, 146, 0.373142)"
                        trailWidth="1"
                      />
                    </>
                  )}
                </div>
                <div className="row justify-content-between align-items-center">
                  <div className="col-2 align-self-end">
                    <h5>Document(s)</h5>
                  </div>
                  <div className="col-8 d-flex justify-content-end">
                    <div className="align-self-center">
                      <div class="px-3 multi-select-checkboxes">
                        <MultiSelectCheckboxes
                          onChange={this.getDocumentFilter}
                          value={filterTags}
                          placeholderButtonLabel="Filter By"
                          getDropdownButtonLabel={Value => {
                            const { value, placeholderButtonLabel } = Value;
                            return placeholderButtonLabel;
                            // return `${value.length > 0 ? value.length : 'Tag'} Selected`;
                          }}
                          options={DocumentTagType}
                          hideSearch={true}
                        />
                      </div>
                    </div>
                    <div className="" style={{ textAlign: 'right' }}>
                      <div class="search-box d-inline-flex w-50">
                        <div class="form-group has-search w-100" style={{ alignSelf: 'center' }}>
                          <input
                            type="text"
                            class="form-control 
                   shadow-sm rcm-document-search"
                            placeholder="Search"
                            id="filterbox"
                            onChange={this.onSearchChange}
                            style={{ minHeight: '44px' }}
                          />
                          <button class="btn search-icon " type="button">
                            {' '}
                            <i class="fa fa-search" />{' '}
                          </button>
                        </div>
                      </div>
                      {/* {!isDeActivated && ( */}
                      <CustomModal
                        // mask={true}
                        maskClosable={false}
                        destroyOnClose
                        ModalButton={({ onClick }) => (
                          <input
                            type="button"
                            class="btn black-btn px-3"
                            value="Upload Document(s)"
                            onClick={onClick}
                            style={{ alignSelf: 'center' }}
                            disabled={this.props.isReadOnlyUser}
                          />
                        )}
                        ModalContent={props => (
                          <ReduxUploadModal
                            {...props}
                            dispatch={this.props.dispatch}
                            StudentId={this.getStudentId()}
                            toggle={this.toggleUploaded}
                            DocTypeValue={
                              this.props.DocTypeValue && this.props.DocTypeValue.toString()
                            }
                          />
                        )}
                      />
                      {/* // )} */}
                    </div>
                  </div>

                  <CustomModalPopup
                    isOpen={this.state.isModelOpen}
                    header={this.state.header}
                    yesAction={() => {
                      this.toggle();
                      this.props.dispatch(
                        listClientDocument({
                          json: JSON.stringify({
                            ClientDocument: [
                              {
                                ClientId: this.props.clientId,
                                DocumentType: this.props.DocTypeValue,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                    yesText="OK"
                    hideCancel={true}
                  />
                  <CustomModalPopup
                    isOpen={this.state.isUploadedModelOpen}
                    header={this.state.header}
                    yesAction={() => {
                      this.toggleUploaded();
                      // this.props.dispatch(
                      //   listClientDocument({
                      //     json: JSON.stringify({
                      //       StudentDocument: [
                      //         {
                      //           StudentId: this.getStudentId(),
                      //         },
                      //       ],
                      //     }),
                      //   }),
                      // );
                    }}
                    yesText="OK"
                    hideCancel={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-5 pb-5 rcm-subtab-table-block">
            <div class="col-12 document-table">
              <Loader2 loading={isFetching} />
              <PersistTableSort
                name="DocumentList"
                columns={this.columns}
                dataSource={DocumentList}
                size={'middle'}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: event => this.onRowClick(event, record, rowIndex),
                //   };
                // }}
                pagination={{
                  itemRender: (page, type, originalElement) => {
                    return (
                      <div class="dataTables_wrapper no-footer">
                        <div class="dataTables_paginate paging_simple_numbers">
                          {type == 'prev' ? (
                            <a class="paginate_button previous">Previous</a>
                          ) : type == 'next' ? (
                            <a class="paginate_button next">Next</a>
                          ) : (
                            <a class="paginate_button ">{page}</a>
                          )}
                        </div>
                      </div>
                    );
                  },
                  showSizeChanger: true,
                  // hideOnSinglePage: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  list_client_document,
  download_client_document,
  archive_client_document,
  upload_document_using_multiPart,
  upsert_client_document,
  combos,
}) => {
  const { data: DocumentData, isFetching, error } = list_client_document;
  const {
    data: UploadData,
    isFetching: isFetchingUpload,
    error: errorUpload,
  } = upload_document_using_multiPart;
  const {
    data: DownloadData,
    isFetching: isFetchingDownload,
    error: errorDownload,
  } = download_client_document;
  let documentError;
  let StudentInfo = {};
  let DocumentList = [];
  let StudentCenterObj = {};
  let isArchived = false;
  let downloadUrl;
  let isDownloadClicked;
  let isUploaded = false;
  let uploadedDataGuid = '';
  let uploadError;
  let isUpsertDocument = false;
  let DocumentTagType = [];

  // if (DocumentData && DocumentData.Student) {
  //   StudentInfo = DocumentData.Student[0];
  // }
  // if (DocumentData && DocumentData.Center) {
  //   StudentCenterObj = DocumentData.Center[0];
  // }
  if (UploadData && UploadData.success) {
    isUploaded = UploadData.success;
    uploadedDataGuid = UploadData.data;
  } else if (errorUpload) {
    uploadError = errorUpload;
  }
  if (DocumentData && DocumentData.ClientDocument) {
    DocumentList = DocumentData.ClientDocument;
  }
  if (error) {
    documentError = error;
  }
  if (archive_client_document.data && archive_client_document.data.success) {
    isArchived = archive_client_document.data.success;
  } else if (archive_client_document.error) {
    documentError = archive_client_document.error.Message;
  }
  if (upsert_client_document.data && upsert_client_document.data.success) {
    isUpsertDocument = upsert_client_document.data.success;
  } else if (upsert_client_document.error) {
    documentError = upsert_client_document.error.Message;
  }
  // var Centers = [{ Value: null, Text: 'Please Select' }];

  if (DownloadData && DownloadData.success) {
    downloadUrl = DownloadData.result;
    isDownloadClicked = DownloadData.IsDownloadClicked;
  } else if (errorDownload) {
    documentError = errorDownload;
  }
  if (combos.data.DocumentType)
    for (var documentType of combos.data.DocumentType) {
      DocumentTagType.push({ label: documentType.Text, value: documentType.Value });
    }

  return {
    isFetching,
    StudentInfo,
    DocumentList,
    downloadUrl,
    isArchived,
    documentError,
    StudentCenterObj,
    isUploaded,
    uploadedDataGuid,
    uploadError,
    isUpsertDocument,
    DocumentTagType,
    isFetchingDownload,
    isDownloadClicked,
  };
};

export default connect(mapStateToProps)(withRouter(DocumentUpload));
