import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Tabs, Button, Drawer, Tooltip } from 'antd';
import * as d3 from 'd3';
import Tree from 'react-d3-tree';
import Loader, { Loader2 } from '../../../Components/Loader';
import PersistTableSort from '../../../component/persistTableSort';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import ReduxSupportGroupMemberForm from './addSupportGroupDrawer';
import {
  addSupportGroup,
  removeSupportGroup,
  loadSupportGroup,
  listCombos,
} from '../../../store/actions';
import { getPhoneNumber } from '../../../util/phoneFormatter';
import CustomModal from '../../../core/modal';
import AntdModal from '../../../Components/CustomModal';
import _blank from '../../../images/blank-profile.png';
import Twilio from '../../../Components/twilio';
import ComingSoon from '../../../component/comingSoonPage';
import OrgChartComponent from './orgChart';
import { CommonLicenceScreens } from '../../licenceScreens';
class AnyReactComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  _onChildClick = (key, childProps) => {
    this.setState({ show: !this.state.show });
  };
  render() {
    const {
      VisitCount,
      TriggerTypeValue,
      TriggerTypeImgUrl,
      OtherUsersTaggedThisPlaceCount,
      FullAddress,
      IsPositive,
      showIsPositive,
      TriggerSign,
      name,
      relation,
      phone,
      email,
    } = this.props;
    return (
      <div className="custom-marker">
        {/* {!showIsPositive && <div className="number-circle">{this.props.VisitCount}</div>} */}

        {/* {
          showIsPositive && <div className="isPositive" style={IsPositive ? { background: 'yellow' } : { background: 'red' }}>{ IsPositive?<span>+</span>:<span>-</span>}</div>
        }  */}
        <InfoWindow
          show={this.state.show}
          close={this._onChildClick}
          TriggerTypeValue={TriggerTypeValue}
          VisitCount={VisitCount}
          TriggerTypeImgUrl={TriggerTypeImgUrl}
          OtherUsersTaggedThisPlaceCount={OtherUsersTaggedThisPlaceCount}
          FullAddress={FullAddress}
          name={name}
          relation={relation}
          phone={phone}
          email={email}
        />
        {showIsPositive && (
          <div
            className="d-flex flex-column"
            // onClick={this._onChildClick}
            style={
              TriggerSign == 1
                ? {
                    border: '3px solid #FCD400',
                    // height: "50%",
                    background: '#FAFFC1',
                    // padding: "5px",
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : TriggerSign == 0
                ? {
                    border: '3px solid #FF3B3B',
                    background: '#FFD4D4',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : {
                    border: '3px solid #828282',
                    background: '#d9d9d9',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
            }
          >
            {
              <img
                src={TriggerTypeImgUrl}
                // width={'25px'}
              />
            }
          </div>
        )}
        {!showIsPositive && (
          <div
            className="d-flex flex-column"
            style={
              TriggerSign == 1
                ? {
                    border: '3px solid #FCD400',
                    // height: "50%",
                    background: '#FAFFC1',
                    // padding: "5px",
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : TriggerSign == 0
                ? {
                    border: '3px solid #FF3B3B',
                    background: '#FFD4D4',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : {
                    border: '3px solid #828282',
                    background: '#d9d9d9',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
            }
            onClick={this._onChildClick}
          >
            <img
              src={TriggerTypeImgUrl || `images/familyProgram/blank-profile.png`}
              // alt="student"
              class="img-circle"
              height="49"
              width="49"
              style={{
                borderRadius: '50%',
                padding: '2px',
                objectFit: 'cover',
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const InfoWindow = props =>
  props.show ? (
    <div className="map-info-popup d-flex flex-column">
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top: '12px' }}
        onClick={props.close}
      >
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="icon-area">
          {/* <i className="fa fa-medkit" aria-hidden="true"></i> */}
          {
            <img
              className="url-icon"
              src={props.TriggerTypeImgUrl}
              style={{ width: '40px', height: '40px', borderRadius: '50%' }}
            />
          }
        </div>
        &nbsp; &nbsp;
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'initial',
            marginLeft: '13px',
          }}
        >
          <span style={{ fontSize: '14px', fontWeight: '700' }}>{props.name}</span>
          <span style={{ fontSize: '12px' }}>{props.relation}</span>
          {/* <span style={{ fontSize: '12px' }}>{props.phone}</span>
          <span style={{ fontSize: '12px' }}>{props.email}</span> */}
        </div>
      </div>
      <span style={{ fontSize: '12px', marginTop: '19px', marginLeft: '5px', textAlign: 'left' }}>
        {props.phone && <img src="images/Vector.svg" style={{ marginRight: '5px' }} />}
        {props.phone}
      </span>
      <span style={{ fontSize: '12px', marginTop: '5px', textAlign: 'left' }}>
        {' '}
        {props.email && <img src="images/email.svg" style={{ marginRight: '5px' }} />}
        {props.email}
      </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          // marginTop: '10px',
          padding: '10px 0px',
        }}
      >
        <span className="popText2">{props.FullAddress}</span>
      </div>
    </div>
  ) : null;

const ListView = props => {
  const [callModelVisible, setCallModelVisible] = useState({});
  const [columns, setColumns] = useState([
    {
      title: 'Name',
      dataIndex: 'DisplayName',
      key: 'DisplayName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isComparer(a.DisplayName, b.DisplayName),
      render: (text, record, index) => {
        const { DisplayName, ProfileImageUrl, UserGuid } = record;
        return (
          <>
            <div
              className="d-flex align-items-center"
              style={{
                cursor: 'pointer',
              }}
            >
              <div className="mr-4">
                {' '}
                <figure>
                  <img
                    style={{
                      boxShadow: '0px 11px 6px #f2f2f2',
                      cursor: 'pointer',
                      maxWidth: '40px',
                    }}
                    src={ProfileImageUrl || 'images/blank-profile.png'}
                    class="rounded-circle "
                    width="40"
                    height="40"
                    alt=""
                    onClick={() => {
                      props.history.push(`/subscriberProfile/${UserGuid}`);
                    }}
                  />
                </figure>
              </div>
              <div className="">{DisplayName}</div>
            </div>
          </>
        );
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'Phone',
      key: 'Phone',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isNumericComparer(a.Phone, b.Phone),
      render: (text, record) => {
        return (
          <div className="d-flex">
            <div
              class="tools-grid"
              onClick={event => {
                if (record.IsVoiceCallAllowed) {
                  setCallModelVisible({
                    isCallModelVisible: true,
                    clickedRecord: record,
                  });
                  event.stopPropagation();
                } else {
                  alert('VOIP call not allowed');
                  event.stopPropagation();
                }
              }}
              style={{
                cursor: 'pointer',
                width: '125px',
                marginRight: '10px',
              }}
            >
              <p
                className="d-flex justify-content-around"
                style={
                  record.IsVoiceCallAllowed
                    ? {
                        color: '#46B4FE',
                        marginBottom: '0',
                      }
                    : {
                        color: 'black',
                        marginBottom: '0',
                      }
                }
              >
                {' '}
                {record.IsVoiceCallAllowed ? (
                  <img
                    style={{
                      cursor: 'pointer',
                      display: 'block',
                    }}
                    src={`images/twilioPhoneAllowed.svg`}
                    alt="Calling Allowed"
                    width="10px"
                  />
                ) : null}
                {record.Phone}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isComparer(a.Email, b.Email),
    },
    {
      title: 'Relation',
      dataIndex: 'Relation',
      key: 'Relation',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isComparer(a.Relation, b.Relation),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isComparer(a.Status, b.Status),
    },

    {
      title: 'Action',
      key: 'x',
      render: (text, record, index) => (
        <div class="tools-grid">
          <div className="d-flex align-items-center justify-content-between">
            <AntdModal
              ModalButton={({ onClick }) => (
                <Tooltip placement="top" title="delete">
                  <span className="start-btn" onClick={onClick}>
                    <i class="fa fa-trash" style={{ cursor: 'pointer' }}></i>
                  </span>
                </Tooltip>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                    <h4 class="modal-title col-12 mb-3">
                      Are you sure you want to delete this support member ? <br />
                    </h4>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      class="btn black-btn"
                      onClick={() => {
                        onCancel();
                      }}
                    >
                      No
                    </button>
                    <button
                      class="btn"
                      onClick={() => {
                        onOk();
                        props.handleRemoveSupportGroup(record.UserSupportGroupId);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      ),
    },
  ]);
  const { SupportGroup } =
    (props &&
      props.loadSupportGroupData &&
      props.loadSupportGroupData.SupportGroup &&
      props.loadSupportGroupData.SupportGroup[0]) ||
    [];
  return (
    <div className="container-fluid p-3">
      {' '}
      <div className="row card-body" style={{ minHeight: '300px' }}>
        <div class="col-12 ">
          {/* <Loader2 loading={isFetching} /> */}
          <PersistTableSort
            name="SupportGroupListView"
            columns={columns}
            dataSource={SupportGroup}
            size={'middle'}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: event => this.onRowClick(event, record, rowIndex),
            //   };
            // }}
            pagination={{
              itemRender: (page, type, originalElement) => {
                return (
                  <div class="dataTables_wrapper no-footer">
                    <div class="dataTables_paginate paging_simple_numbers">
                      {type == 'prev' ? (
                        <a class="paginate_button previous">Previous</a>
                      ) : type == 'next' ? (
                        <a class="paginate_button next">Next</a>
                      ) : (
                        <a class="paginate_button ">{page}</a>
                      )}
                    </div>
                  </div>
                );
              },
              showSizeChanger: true,
            }}
          />
        </div>
      </div>
      {callModelVisible.isCallModelVisible && (
        <Twilio
          isOpen={callModelVisible.isCallModelVisible}
          userData={callModelVisible.clickedRecord}
          resetIsOpen={() => {
            setCallModelVisible({
              isCallModelVisible: false,
              clickedRecord: {},
            });
          }}
        />
      )}
    </div>
  );
};

const MapView = props => {
  const { SupportGroup, Location } =
    (props.loadSupportGroupData &&
      props.loadSupportGroupData.SupportGroup &&
      props.loadSupportGroupData.SupportGroup[0]) ||
    {};
  return (
    <div className="container-fluid p-3">
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo' }}
          center={{
            lat:
              Location && Location[0] && Location[0].Latitude && parseFloat(Location[0].Latitude),
            lng:
              Location && Location[0] && Location[0].Longitude && parseFloat(Location[0].Longitude),
          }}
          // defaultCenter={{
          //   lat: 33.434839477,
          //   lng: -83.831970057,
          // }}
          defaultZoom={16}
        >
          {SupportGroup &&
            SupportGroup.map(item => {
              const {
                DisplayName,
                Email,
                Latitude,
                Longitude,
                Phone,
                Relation,
                Status,
                UserGuid,
                UserId,
                UserSupportGroupId,
                Address,
                ProfileImageUrl,
              } = item;
              return (
                <AnyReactComponent
                  lat={Latitude}
                  lng={Longitude}
                  name={DisplayName}
                  relation={Relation}
                  phone={Phone}
                  email={Email}
                  TriggerTypeImgUrl={ProfileImageUrl}
                  // OtherUsersTaggedThisPlaceCount={history.OtherUsersTaggedThisPlaceCount}
                  FullAddress={Address}
                  // TriggerTypeValue={history.TriggerTypeValue}
                  // TriggerSign={history.TriggerSign}
                />
              );
            })}
        </GoogleMapReact>
      </div>
    </div>
  );
};

const ChartView = props => {
  const { appSubscriberData, loadSupportGroupData } = props;
  const { SupportGroup } =
    (loadSupportGroupData &&
      loadSupportGroupData.SupportGroup &&
      loadSupportGroupData.SupportGroup[0]) ||
    [];

  let SupportGroup2 = [];
  SupportGroup2 =
    SupportGroup && SupportGroup.length > 0
      ? SupportGroup.map((item, index) => {
          return { ...item, id: `c-${index}`, parentId: 'p-1' };
        })
      : [];

  let addNodeChildFunc = null;

  function addNode() {
    const node = {
      nodeId: 'new Node',
      parentNodeId: 'O-6066',
    };

    addNodeChildFunc(node);
  }

  function onNodeClick(nodeId) {
    // console.log('d3', d3.event);
    alert('clicked ' + nodeId);
  }

  const nodeSize = { x: 150, y: 100 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -80, y: 50 };

  return (
    <div className="container-fluid p-3">
      {appSubscriberData.FirstName && (
        <OrgChartComponent
          setClick={click => (addNodeChildFunc = click)}
          onNodeClick={onNodeClick}
          data={[
            {
              id: 'p-1',
              parentId: '',
              DisplayName: appSubscriberData.FirstName,
              Phone: appSubscriberData.Phone,
              Email: appSubscriberData.Email,
              ProfileImageUrl: appSubscriberData.UserProfileImageUrl,
              Relation: '',
            },
            ...SupportGroup2,
          ]}
        />
      )}
    </div>
  );
};

const tabName = [
  { tabLabel: 'List View', keyIndex: 1, Content: 'ListView' },
  { tabLabel: 'Map View', keyIndex: 2, Content: 'MapView' },
  { tabLabel: 'Chart View', keyIndex: 3, Content: 'ChartView' },
];

const components = {
  ListView,
  MapView,
  ChartView,
};

class SupportGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentWillReceiveProps({
    isAdded,
    isDeleteSupportSuccess,
    memberType,
    familyProgramStatusData,
  }) {
    if (isAdded && isAdded != this.props.isAdded) {
      this.onClose();
      this.toggle('Added Successfully');
    }
    if (isDeleteSupportSuccess && isDeleteSupportSuccess != this.props.isDeleteSupportSuccess) {
      this.onClose();
      this.toggle('Removed Successfully');
    }
    if (memberType && memberType != this.props.memberType) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'RelationShip,SupportGroup',
          json: JSON.stringify({ User: { UserGuid: this.props.UserGuid, SearchStr: null } }),
        }),
      );
    }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  onhandleSubmit = values => {
    const { FirstName, LastName, Phone, Email, Relation, Address, ZipCode } = values;

    let data = {
      Json: JSON.stringify({
        User: [
          {
            UserGuid: this.props.getSubscriberUserGUID(),
            ConcernedUserGuid: null,
            RelationShipId: Relation,
            NewUser: [
              {
                MobileNumber: getPhoneNumber(Phone),
                FirstName: FirstName,
                LastName: LastName,
                Email: Email,
                Address,
                ZipCode,
              },
            ],
          },
        ],
      }),
    };

    this.props.dispatch(addSupportGroup(data));
  };

  handleExistingMember = (selectedMembers, relation) => {
    let id = this.props.getSubscriberUserGUID();
    let newDataArray =
      selectedMembers &&
      selectedMembers.map(item => {
        return {
          UserGuid: id,
          ConcernedUserGuid: item.UserGuid,
          RelationShipId: relation[item.UserGuid],
        };
      });

    let data = {
      Json: JSON.stringify({
        User: newDataArray,
      }),
    };
    if (newDataArray && newDataArray.length > 0) {
      this.props.dispatch(addSupportGroup(data));
    }
  };

  showDrawer = () => {
    this.setState({ open: true });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  handleRemoveSupportGroup = id => {
    let data = {
      Json: JSON.stringify({ SupportGroup: [{ UserSupportGroupId: id }] }),
    };

    this.props.dispatch(removeSupportGroup(data));
  };

  render() {
    const { SupportGroup } = (this.props && this.props.loadSupportGroupData) || [];
    const { relationOptions, supportGroup, memberType, familyProgramStatusData } = this.props;
    return (
      <>
        <CommonLicenceScreens
          StatusData={familyProgramStatusData}
          moduleName={'FamilyProgram'}
          isSupportGroup={true}
        />

        <div className=" px-0  pb-5">
          {familyProgramStatusData && familyProgramStatusData.LicenseStatusId == 6 ? (
            <>
              {' '}
              <ComingSoon />
            </>
          ) : (
            <>
              {familyProgramStatusData &&
                familyProgramStatusData.LicenseStatusId != 1 &&
                familyProgramStatusData.LicenseStatusId != 3 &&
                familyProgramStatusData.LicenseStatusId != 5 && (
                  <>
                    {' '}
                    <div>
                      {SupportGroup && SupportGroup.length <= 0 ? (
                        <>
                          <div>
                            {' '}
                            <p style={{ fontSize: '20px', fontWeight: '700', marginTop: '50px' }}>
                              {' '}
                              No support group members added yet
                            </p>
                            <button className="btn btn-outline mt-4 mr-4" onClick={this.showDrawer}>
                              Add Support group members
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            {' '}
                            <div className="text-right mb-3">
                              <button
                                className="btn btn-outline mt-4 mr-4"
                                onClick={this.showDrawer}
                              >
                                Add Support group members
                              </button>
                            </div>
                            <div
                              style={{
                                background: '#fff',
                                borderRadius: '12px',
                                margin: '16px 0px',
                              }}
                            >
                              <Tabs defaultActiveKey="1" centered>
                                {tabName &&
                                  tabName.map((item, index) => {
                                    const { tabLabel, keyIndex, Content } = item;
                                    const DynamicComponentConversion = components[Content];
                                    return (
                                      <Tabs.TabPane tab={tabLabel} key={keyIndex}>
                                        {
                                          <DynamicComponentConversion
                                            {...this.props}
                                            handleRemoveSupportGroup={this.handleRemoveSupportGroup}
                                          />
                                        }
                                      </Tabs.TabPane>
                                    );
                                  })}
                              </Tabs>
                            </div>
                          </div>
                          {this.state.open && (
                            <Drawer
                              className="custom-drawer"
                              title="Add Support Group Members"
                              placement="right"
                              onClose={this.onClose}
                              open={this.state.open}
                              width="600px"
                              bodyStyle={{ backgroundColor: '#fff' }}
                              visible={this.state.open}
                            >
                              <div>
                                <ReduxSupportGroupMemberForm
                                  onhandleSubmit={this.onhandleSubmit}
                                  handleExistingMember={this.handleExistingMember}
                                  relationOptions={relationOptions}
                                  supportGroup={supportGroup}
                                  memberType={memberType}
                                  UserGuid={this.props.getSubscriberUserGUID()}
                                  initialValues={{ memberType: 'ANM' }}
                                />
                              </div>
                            </Drawer>
                          )}

                          <CustomModal
                            isOpen={this.state.isModelOpen}
                            header={this.state.header}
                            yesAction={() => {
                              this.toggle();
                              // window.location.reload(true);
                              this.props.dispatch(
                                loadSupportGroup({ UserGuid: this.props.getSubscriberUserGUID() }),
                              );
                            }}
                            yesText="OK"
                            hideCancel={true}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { add_support_group, remove_support_group, user_feature_flag_get } = state;
  var { isFetching, data } = add_support_group;
  var { data: deleteSupportData } = remove_support_group;
  const { data: familyProgramStatus } = user_feature_flag_get;
  let isAdded = false;
  let isDeleteSupportSuccess = false;
  let familyProgramStatusData = {};
  if (data && data.success) {
    isAdded = data.success;
  }
  if (deleteSupportData && deleteSupportData.success) {
    isDeleteSupportSuccess = deleteSupportData.success;
  }
  if (
    familyProgramStatus &&
    familyProgramStatus.FeatureFlag &&
    familyProgramStatus.FeatureFlag.length > 0 &&
    familyProgramStatus.FeatureFlag[0]
  ) {
    familyProgramStatusData =
      (familyProgramStatus.FeatureFlag &&
        familyProgramStatus.FeatureFlag[0] &&
        familyProgramStatus.FeatureFlag[0].License &&
        familyProgramStatus.FeatureFlag[0].License[1]) ||
      {};
  }
  return {
    isAdded,
    isDeleteSupportSuccess,
    familyProgramStatusData,
  };
};

export default connect(mapStateToProps)(SupportGroup);
