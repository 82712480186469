import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table } from 'antd';
import { isComparer, isDateComparer, isNumericComparer } from '../../util/handler';
import {
  surveyQuestionLibraryGet,
  surveyQuestionCategoryConfigUpsert,
  resetSurveyQuestionCategoryConfigUpsert,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import AntdModal from '../../Components/CustomModal';
import DynamicGrid from '../../Components/DynamicGrid';
import PageTitle from '../../Components/PageTitle';
class QuestionLibraryList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { dispatch } = this.props;
    // dispatch(
    //   surveyQuestionLibraryGet({
    //     json: JSON.stringify({
    //       Survey: [
    //         {
    //           SurveyQuestionCategoryConfigId: 1,
    //         },
    //       ],
    //     }),
    //   }),
    // );
    dispatch(resetSurveyQuestionCategoryConfigUpsert({}));
  }

  componentWillReceiveProps({ surveyQuestionCategoryConfigId }) {
    if (surveyQuestionCategoryConfigId) {
      this.props.history.push(
        `/manage/question-library/config-screening-questions?qid=${surveyQuestionCategoryConfigId}`,
      );
    }
  }

  getPopOverData = record => {
    if (record.SurveyQuestionCategoryConfigId > 0 && record.QuestionsAdded > 0) {
      return (
        <>
          {/* <div className=""> */}
          <AntdModal
            modalWidth={500}
            ModalButton={({ onClick }) => (
              // <img onClick={onClick} src="images/edit.svg" style={{ cursor: 'pointer' }} />
              <p
                onClick={onClick}
                style={{ color: '#46B4FE', cursor: 'pointer', fontSize: '16px' }}
              >
                <img src="images/edit.svg" style={{ cursor: 'pointer' }} className="mt-1 mr-1" />
                EDIT
              </p>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div className="modal-content border-0">
                <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                  {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                  <h4 className="modal-title col-12">
                    Changing the question library may affect past screenings, however you can still
                    view the history in time travel. Are you sure you want to proceed?
                  </h4>
                </div>
                <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    type="button"
                    className="btn btn-outline-primary py-1"
                    data-dismiss="modal"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn py-1"
                    onClick={() => {
                      onOk();
                      this.props.history.push(
                        `/manage/question-library/config-screening-questions?qid=${record.SurveyQuestionCategoryConfigId}`,
                      );
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            )}
          />
          {/* </div> */}
        </>
      );
    } else {
      return (
        <p
          onClick={() => {
            this.props.dispatch(
              surveyQuestionCategoryConfigUpsert({
                json: JSON.stringify({
                  QuestionLibrary: [
                    {
                      CenterId: record.CenterId,
                      CenterServiceTypeId: record.CenterServiceTypeId,
                    },
                  ],
                }),
              }),
            );
          }}
          style={{ color: '#46B4FE', cursor: 'pointer' }}
        >
          + ADD
        </p>
      );
    }
  };

  render() {
    const { questionsLibrary, surveyQuestionCategoryConfigId } = this.props;
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Screening Questions Library" />
        <div class="content-area">
          <div class="row top-table-search mt-2 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Screening Questions Library</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              {/* <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control 
                   shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    // onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
            <Loader2 loading={this.props.isFetching} />
                    <Table
                      columns={columns}
                      dataSource={questionsLibrary}
                      size={'middle'}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 " style={{ backgroundColor: 'unset', boxShadow: 'none' }}>
                <div className="dynamic-grid">
                  <DynamicGrid
                    ScreenName={'ScreeningQuestionsLibrary'}
                    getPopOverData={this.getPopOverData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  survey_question_library_get,
  survey_question_category_config_upsert,
  default_values,
}) => {
  const { data, isFetching, error } = survey_question_library_get;
  const { data: upsertData } = survey_question_category_config_upsert;
  let centerId;
  let surveyQuestionCategoryConfigId;
  if (default_values && default_values.data) {
    centerId = default_values.data.centerId;
  }
  if (upsertData && upsertData.data) {
    surveyQuestionCategoryConfigId = JSON.parse(upsertData.data).QuestionLibrary[0]
      .SurveyQuestionCategoryConfigId;
  }
  let questionsLibrary = [];
  if (data && data.ScreeningQuestionsLibrary) {
    questionsLibrary = data.ScreeningQuestionsLibrary;
  }
  return {
    questionsLibrary,
    centerId,
    surveyQuestionCategoryConfigId,
    isFetching,
  };
};
export default connect(mapStateToProps)(withRouter(QuestionLibraryList));
