import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Modal } from 'antd';
import { ReduxSelect } from '../../../component/custom-redux-components';
import CloseIcon from '../../../Components/CloseIcon';
import EmotionalGraphModaContent from './emotionalGraphModalContent';
import {
  recoveryCapitalQuadrantGraphGet,
  resetRecoveryCapitalQuadrantGraphGet,
} from '../../../store/actions';
import renderHTML from 'react-render-html';
import { Loader2 } from '../../../Components/Loader';
const EmotionGraph = props => {
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState({});
  const [series, setSeries] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [timePeriod, setTimePeriod] = useState([]);
  const [sliderRef, setSliderRef] = useState(null);
  const [active, setActive] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [graphSeries, setGraphSeries] = useState([]);
  const [colors, setColors] = useState([]);
  const [modalContent, setModalContent] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialSeries, setInitialSeries] = useState([]);
  const [key, setKey] = useState(0);
  const [isSeriesNull, setIsSeriesNull] = useState(false);

  const { quadrantGraphData, isFetching } = useSelector(
    ({ recovery_capital_quadrant_graph_get: { data: quadrantGraphData, isFetching } }) => {
      return {
        quadrantGraphData: quadrantGraphData,
        isFetching: isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    let data = {
      Graph: {
        GraphType: 'Mood',
        StartDate: null,
        EndDate: null,
        // MoodReasonId: 1,         //required only when filter moodwise
      },
    };
    dispatch(
      recoveryCapitalQuadrantGraphGet({
        Json: JSON.stringify(data),
        // StaffId: null,
        UserGuid: props.UserGuid,
        // UserGuid: 'AABAF221-349F-4A3E-A69E-64B97F58D363',
        // UserGuid: '869CD961-CF05-4A18-ADDF-9224138578B0',
      }),
    );
    // return () => {
    //   dispatch(resetChatGet());
    // };
    setFilterValue('Last 7 days');
    handleFilterChange('Last 7 days');
  }, []);
  useEffect(() => {
    if (quadrantGraphData && quadrantGraphData[0]) {
      setFilters(quadrantGraphData[0].Filter ? quadrantGraphData[0].Filter : []);
      if (quadrantGraphData[0].MoodsMap && quadrantGraphData[0].MoodsMap.length > 0) {
        // if (quadrantGraphData[0].Filter && quadrantGraphData[0].Filter.length > 0) {
        //   setFilterValue('Last 7 days');
        //   handleFilterChange('Last 7 days');
        // }
        setIsSeriesNull(false);
        let seriesData =
          quadrantGraphData[0].CategoryLegend &&
          quadrantGraphData[0].CategoryLegend.map((item, index) => {
            setColors(prevColors => [...prevColors, item.ColorCode]);
            let xydata = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 1);
            let remainingData = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 2);
            return {
              name: item.Shortdescription + ', ' + item.Name,
              data: xydata,
              remainingData: remainingData,
            };
          });

        let initialData =
          quadrantGraphData[0].CategoryLegend &&
          quadrantGraphData[0].CategoryLegend.map((item, index) => {
            setColors(prevColors => [...prevColors, item.ColorCode]);
            let xydata = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 1);
            let remainingData = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 2);
            return {
              name: item.Shortdescription + ', ' + item.Name,
              data: xydata,
              remainingData: remainingData,
            };
          });
        setSeries([...seriesData]);
        setKey(prevKey => prevKey + 1);
        setInitialSeries([...initialData]);

        // let graphSeriesData = quadrantGraphData[0].CategoryLegend.map((item, index) => {
        //   let xydata = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 1);
        //   let remainingData = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 2);

        //   return {
        //     name: item.Shortdescription + ', ' + item.Name,
        //     data: xydata,
        //     remainingData: remainingData,
        //   };
        // });
        // setGraphSeries(graphSeriesData);
      } else {
        setIsSeriesNull(true);
        let seriesData =
          quadrantGraphData[0].CategoryLegend &&
          quadrantGraphData[0].CategoryLegend.map((item, index) => {
            setColors(prevColors => [...prevColors, item.ColorCode]);
            // let xydata = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 1);
            // let remainingData = generateSeries(item.MoodReasonId, quadrantGraphData[0].MoodsMap, 2);
            return {
              name: item.Shortdescription + ', ' + item.Name,
              data: [],
              remainingData: [],
            };
          });
        setSeries(seriesData);
        setKey(prevKey => prevKey + 1);
        setInitialSeries(seriesData);
        // setGraphSeries([]);
      }
    }
  }, [quadrantGraphData]);

  const generateSeries = (MoodReasonId, MoodsMap, id) => {
    let series = MoodsMap && MoodsMap.filter(item => MoodReasonId == item.MoodReasonId);
    let newdata =
      series &&
      series.map((item, index) => {
        let data = {
          x: item.Y,
          y: item.X <= 5 ? -item.X : item.X,
          label: item.Shortdescription,
        };
        // return id === 1 ? data : [item.Y, item.X <= 5 ? -item.X : item.X];
        return id == 1
          ? [item.Y, item.X <= 5 ? -item.X : item.X - 5]
          : [
              item.Shortdescription,
              item.Name,
              item.SelectedUrl,
              item.Description,
              item.CreatedDate,
              item.ColorCode,
            ];
      });
    return newdata;
  };

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  var settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    variableWidth: true,
    rtl: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  const customToolTip = overlappingData => {
    return `<div class="emotional-graph">
        ${overlappingData
          .map(obj => {
            return `<div>${obj.name}: ${obj.data
              .map(point => `(${point[0]}, ${point[1]})`)
              .join(', ')}</div>`;
          })
          .join('')}
        
      </div>`;
  };
  const handleFilterChange = value => {
    setFilterValue(value);
    setActive(null);
    let dates = filters && filters.filter(item => item.Value == value);

    let filterArray = value.split(' ');
    let num = parseInt(filterArray[1]);
    let timePeriod = filterArray[2].toLowerCase();
    setTimeStamp(timePeriod);

    let currentDate = moment();
    let startDate = currentDate.clone().subtract(num - 1, timePeriod);
    let endDate = currentDate;

    var allDatesInRange = [];

    var current = startDate.clone();
    while (current.isSameOrBefore(endDate, 'day')) {
      if (timePeriod == 'days') {
        allDatesInRange.push({
          Text: current.format('DD MMM YY'),
          Value: current.format('DD MMM YY'),
        });

        current.add(1, timePeriod);
      } else if (timePeriod == 'months') {
        allDatesInRange.push({
          Text: current.format('MMM YY'),
          Value: current.format('DD MMM YY'),
        });

        current.add(1, timePeriod);
      }
    }
    let data = {
      Graph: {
        GraphType: 'Mood',
        StartDate: dates[0] ? dates[0].StartDate : null,
        EndDate: dates[0] ? dates[0].EndDate : null,
        // MoodReasonId: 1,         //required only when filter moodwise
      },
    };
    dispatch(
      recoveryCapitalQuadrantGraphGet({
        Json: JSON.stringify(data),
        UserGuid: props.UserGuid,
        // UserGuid: 'AABAF221-349F-4A3E-A69E-64B97F58D363',
        // UserGuid: '869CD961-CF05-4A18-ADDF-9224138578B0',
      }),
    );
    setTimePeriod(allDatesInRange);
  };

  const handleDaySelection = (value, text) => {
    let startDate;
    let endDate;
    if (timeStamp == 'days') {
      startDate = moment(value).format('YYYY-MM-DD');
      endDate = moment(value).format('YYYY-MM-DD');
    } else if (timeStamp == 'months') {
      let year = moment(value).format('YYYY');
      let month = moment(value).format('MM');
      month = month - 1;
      let firstDayOfMonth = moment({ year: year, month: month });
      let lastDayOfMonth = moment({ year: year, month: month }).endOf('month');
      startDate = firstDayOfMonth.format('YYYY-MM-DD');
      endDate = lastDayOfMonth.format('YYYY-MM-DD');
    }

    let data = {
      Graph: {
        GraphType: 'Mood',
        StartDate: startDate,
        EndDate: endDate,
        // MoodReasonId: 1,         //required only when filter moodwise
      },
    };
    dispatch(
      recoveryCapitalQuadrantGraphGet({
        Json: JSON.stringify(data),
        UserGuid: props.UserGuid,
        // UserGuid: 'AABAF221-349F-4A3E-A69E-64B97F58D363',
        // UserGuid: '869CD961-CF05-4A18-ADDF-9224138578B0',
      }),
    );
  };

  const groupData = (seriesIndex, dataPointIndex, w, openModal) => {
    let groupDataPoints = [];
    if (series && series.length > 0) {
      groupDataPoints = series
        .filter((obj, index) =>
          obj.data.some(
            point =>
              point[0] === w.globals.seriesX[seriesIndex][dataPointIndex] &&
              point[1] === w.globals.series[seriesIndex][dataPointIndex],
          ),
        )
        .map(obj => ({
          name: obj.name,
          data: obj.data.filter((point, index) => {
            return (
              point[0] === w.globals.seriesX[seriesIndex][dataPointIndex] &&
              point[1] === w.globals.series[seriesIndex][dataPointIndex]
            );
          }),
          remainingData: obj.data
            .map((point, index) => {
              if (
                point[0] === w.globals.seriesX[seriesIndex][dataPointIndex] &&
                point[1] === w.globals.series[seriesIndex][dataPointIndex]
              ) {
                return obj.remainingData[index];
              }
            })
            .filter((item, index) => {
              return item != undefined;
            }),
        }));
    }
    if (openModal) {
      setIsModalOpen(true);
      setModalContent(groupDataPoints);
    }
    return groupDataPoints;
  };

  const options = {
    chart: {
      height: 350,
      type: 'scatter',
      zoom: {
        type: 'xy',
      },
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex, w }) => {
          if (series.length > 0) {
            groupData(seriesIndex, dataPointIndex, w, true);
          }
        },
        legendClick: (chartContext, seriesIndex) => handleLegendClick(chartContext, seriesIndex),
      },
      animations: {
        enabled: false, // Disable all animations
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      formatter: function(val, opt) {
        const { seriesIndex, dataPointIndex, w } = opt;
        if (series.length > 0) {
          let totalArray = [];
          let totalLength = 0;

          const overlappingData = groupData(seriesIndex, dataPointIndex, w);
          let labelTest =
            overlappingData && overlappingData.length > 0 && overlappingData[0].name.split(',')[0];
          if (overlappingData.length > 1) {
            overlappingData &&
              overlappingData.map((item, index) => {
                return totalArray.push(item.data.length);
              });
            totalArray.forEach(item => {
              totalLength += item;
            });
            return totalLength ? totalLength.toString() : '';
          } else if (overlappingData.length == 1) {
            overlappingData &&
              overlappingData.map((item, index) => {
                return totalArray.push(item.data.length);
              });

            if (totalArray[0] > 1) {
              totalArray.forEach(item => {
                totalLength += item;
              });
              return totalLength;
            } else return labelTest;
          }
        }

        // return "Label Text"; // The text you want to add inside the dot
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        colors: colors,
        height: 150,
        width: 50,
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        // padding: 5,
        borderRadius: 30,
        // borderWidth: 1,
        // borderColor: "#fff",
        opacity: 0.9,
        // dropShadow: {
        //   enabled: false,
        //   top: 1,
        //   left: 1,
        //   blur: 1,
        //   color: "#000",
        //   opacity: 0.45,
        // },
      },
    },
    tooltip: {
      enabled: false,
      // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      //   if (series.length > 0) {
      //     const overlappingData = groupData(seriesIndex, dataPointIndex, w);
      //     return customToolTip(overlappingData);
      //   }
      // },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: true,
      position: 'right',
      showForSingleSeries: true,
      showForNullSeries: true,
      fontSize: '16px',
      onItemClick: {
        toggleDataSeries: !isSeriesNull ? true : false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
      markers: {
        width: 16,
        height: 16,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      tickAmount: 10,
      max: 10,
      min: 0,
      labels: {
        show: true,
      },
    },
    yaxis: {
      max: 5,
      min: -5,
      tickAmount: 2,
      labels: {
        show: false,
      },
    },
  };

  const handleLegendClick = (chartContext, seriesIndex) => {
    let dataSeries = [...series];
    let initialDataSeries = [...initialSeries];
    if (dataSeries[seriesIndex].name == initialDataSeries[seriesIndex].name) {
      if (dataSeries[seriesIndex].data.length == initialDataSeries[seriesIndex].data.length) {
        dataSeries[seriesIndex].data = [];
        setSeries(dataSeries);
      } else {
        dataSeries[seriesIndex].data = [...initialDataSeries[seriesIndex].data];
        setSeries(dataSeries);
      }
    }
  };

  return (
    <>
      <div className="col-12 p-0" style={{ position: 'relative' }}>
        <div className="col-12 mb-2 p-0">
          <Loader2 loading={isFetching} />
          <div className="d-flex row justify-content-between">
            <div className="emotional-container p-0">
              <div className="emotional-arrow"></div>
              <div className="emotional-line"></div>
            </div>
            <div key={key} className="col-11 p-0 ml-3 emotional-graph">
              <ReactApexChart
                options={options}
                series={series.length > 0 ? series : []}
                type="scatter"
                height={500}
                // ref={chartRef}
              />
            </div>
            {isSeriesNull && (
              <div
                className="d-flex align-items-center flex-column justify-content-center mb-3"
                style={{
                  backgroundColor: '#F8F8F8',
                  borderRadius: '7px',
                  position: 'absolute',
                  top: '46%',
                  left: '40%',
                  transform: 'translate(-50%, -50%)',
                  width: '335px',
                  padding: '35px',
                }}
              >
                <img
                  src="images/sad.svg"
                  className="mb-1"
                  style={{ height: '35px', width: '35px' }}
                />
                <h4 className="mb-0" style={{ fontSize: '21px' }}>
                  Uh ohh!
                </h4>
                <p className="mb-0 text-center">
                  There is no survey data available for the selected{' '}
                  {timeStamp && timeStamp.slice(0, -1)}.
                </p>
              </div>
            )}
          </div>

          <div className="strongly-agree-container">
            <div className="strongly-agree-line"></div>
            <div className="strongly-agree-arrow"></div>
          </div>
        </div>
      </div>

      <div className="d-flex col-12 mb-3">
        <div className="col-9 d-flex align-items-center justify-content-center">
          <div className="col-1 d-flex align-items-center pr-0">
            {' '}
            <img
              src="images/whiteLeftArrow.svg"
              onClick={previous}
              style={{
                background: '#46B4FE',
                width: '30px',
                height: '30px',
                padding: '6px',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            />
          </div>
          <div className="col-10 px-0">
            <div

            // className="d-flex flex-wrap"
            >
              {/* <Loader2 loading={isFilterFetching} /> */}

              <Slider
                // ref={c => (slider = c)}
                ref={c => setSliderRef(c)}
                {...settings}
              >
                {timePeriod &&
                  timePeriod.map((items, index) => {
                    return (
                      <li
                        className="d-flex max-content"
                        key={index}

                        // className="p-4" style={{ width: 'auto' }}
                      >
                        <span
                          style={
                            JSON.stringify(active) === JSON.stringify(items)
                              ? {
                                  border: '1px solid #4AB0FE',
                                  boxSizing: 'border-box',
                                  filter: 'drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384))',
                                  borderRadius: '50px',
                                  padding: '5px',
                                  margin: '5px',
                                  fontSize: '12px',
                                  color: 'white',
                                  padding: '5px 10px',
                                  background: '#4AB0FE',
                                  cursor: 'pointer',
                                }
                              : {
                                  border: '1px solid #979797',
                                  boxSizing: 'border-box',
                                  filter: 'drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384))',
                                  borderRadius: '50px',
                                  padding: '5px',
                                  margin: '5px',
                                  fontSize: '12px',
                                  color: '#979797',
                                  padding: '5px 10px',
                                  cursor: 'pointer',
                                }
                          }
                          onClick={() => {
                            setActive(items);
                            handleDaySelection(items.Value, items.Text);
                          }}
                        >
                          {items.Text}
                        </span>
                      </li>
                    );
                  })}
              </Slider>
            </div>
          </div>

          <div className="col-1 d-flex align-items-center px-0">
            {' '}
            <img
              onClick={next}
              src="images/whiteLeftArrow.svg"
              style={{
                transform: 'rotate(180deg)',
                background: '#46B4FE',
                width: '30px',
                height: '30px',
                padding: '6px',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group mb-0">
            <ReduxSelect
              options={filters}
              onChange={handleFilterChange}
              className="basic-single"
              classNamePrefix="select"
              value={filterValue}
              placeholder="Filter"
            />
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        className="merge-clients-modal"
        onCancel={() => {
          // this.setState({ isMergeSuccess: false });
          // this.onMergeComplete();
          setModalContent([]);
          setIsModalOpen(false);
        }}
        closeIcon={<CloseIcon />}
        maskClosable={true}
        width={700}
        footer={null}
        // style={{ paddingTop: '60px', paddingBottom: '60px' }}
      >
        <EmotionalGraphModaContent modalContent={modalContent} />
      </Modal>
    </>
  );
};
export default EmotionGraph;
