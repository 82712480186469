import React from 'react';

export default class Select extends React.PureComponent {
  state = {};
  render() {
    const { onChange, data, value, required, allowDefault } = this.props;
    var props = {};
    if (required && (!value || value == 0)) {
      props = { required: true, class: 'form-control is-invalid', 'is-invalid': 'true' };
    } else {
      props = { required: false, class: 'form-control' };
    }
    return (
      <select onChange={onChange} {...props}>
        {allowDefault && (
          <option value={-1} selected="selected">
            Select
          </option>
        )}
        {data &&
          data.map(item => {
            if (value == item.Value) {
              return (
                <option value={item.Value} selected="selected">
                  {item.Text}
                </option>
              );
            } else {
              return <option value={item.Value}>{item.Text}</option>;
            }
          })}
      </select>
    );
  }
}
