import React, { Component } from 'react';
import { Tabs, Collapse } from 'antd';
import MediaQuery from 'react-responsive';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const ListRenderer = props => (
  <>
    <MediaQuery query="(min-device-width: 1024px)">
      <CustomTabs {...props} />
    </MediaQuery>
    <MediaQuery query="(max-device-width: 1024px)">
      <Accordian {...props} />
    </MediaQuery>
  </>
);

class CustomTabs extends Component {
  state = { activeKey: this.props.activeKey };
  shouldComponentUpdate(nextProps, nextState) {
    const { list } = nextProps;
    if (nextState.activeKey !== this.state.activeKey) return true;
    else if (list[0] && list[0].Content) {
      let shouldRender = list.map((item, index) =>
        this.props.list[index].Content && this.props.list[index].Content.length >= 0
          ? item.Content.length === this.props.list[index].Content.length
          : item.noContent
          ? item.shouldRender
            ? item.shouldRender === this.props.list[index].shouldRender && item.shouldRender > 0
            : true
          : false,
      );
      return shouldRender.toString().indexOf('false') >= 0;
    } else if (nextProps.list[0].Content == null && nextProps.list[0].Component == null)
      return true;
    return this.props.scuShouldReturnTrue ? true : false;
  }
  //  shouldComponentUpdateShouldReturnTrue is added so tabs will render even if data is not there in initial tabs ex inhouse issue.
  render() {
    const { activeKey } = this.state;
    const {
      list,
      tabPosition,
      getActiveKey,
      ComponentWrapper,
      destroyInactiveTabPane,
    } = this.props;
    return (
      <Tabs
        defaultActiveKey={`${activeKey}`}
        destroyInactiveTabPane={destroyInactiveTabPane}
        className=""
        size="small"
        onChange={key => {
          this.setState({ activeKey: key });
          if (typeof getActiveKey == 'function') {
            getActiveKey(key);
          }
        }}
        tabPosition={tabPosition || 'top'}
        tabBarGutter={0}
      >
        {list.map((item, index) => {
          const { Content, Component, ExtraComponent, Wrapper } = item;
          const ContentWrapper = Wrapper || ComponentWrapper;
          return (
            <TabPane tab={<Header {...item} index={index} activeKey={activeKey} />} key={index}>
              {ExtraComponent && <ExtraComponent />}
              <ContentWrapper>
                {Content &&
                  Content.map((contentObj, index) => {
                    return <Component {...contentObj} index={index} />;
                  })}
              </ContentWrapper>
            </TabPane>
          );
        })}
      </Tabs>
    );
  }
}

CustomTabs.defaultProps = {
  ComponentWrapper: ({ children }) => <>{children}</>,
};

const customPanelStyle = {
  background: '#f8f8f8',
  marginBottom: 2,
  overflow: 'hidden',
  border: '1px solid #ddd',
  fontSize: '16px',
  fontWeight: 600,
  color: '#828282',
};

class Accordian extends Component {
  state = { activeKey: this.props.activeKey };
  shouldComponentUpdate(nextProps, nextState) {
    const { list } = nextProps;
    if (nextState.activeKey !== this.state.activeKey) return true;
    else if (list[0].Content) {
      let shouldRender = list.map((item, index) =>
        this.props.list[index].Content
          ? item.Content.length == this.props.list[index].Content.length
          : item.noContent
          ? true
          : false,
      );
      return shouldRender.toString().indexOf('false') >= 0;
    } else if (nextProps.list[0].Content == null && nextProps.list[0].Component == null)
      return true;
    return false;
  }
  render() {
    const { activeKey } = this.state;
    const { list, ComponentWrapper } = this.props;
    return (
      <Collapse
        bordered={false}
        accordion={true}
        activeKey={`${activeKey}`}
        onChange={key => this.setState({ activeKey: key })}
      >
        {list.map((item, index) => {
          const { Content, Component, ExtraComponent, Wrapper } = item;
          const ContentWrapper = Wrapper || ComponentWrapper;
          return (
            <Panel
              header={<Header {...item} index={index} activeKey={activeKey} />}
              key={index}
              style={customPanelStyle}
              showArrow={false}
            >
              {ExtraComponent && <ExtraComponent />}
              <ContentWrapper>
                {Content &&
                  Content.map((contentObj, index) => {
                    return <Component {...contentObj} index={index} />;
                  })}
              </ContentWrapper>
            </Panel>
          );
        })}
      </Collapse>
    );
  }
}

Accordian.defaultProps = {
  ComponentWrapper: ({ children }) => <>{children}</>,
};

const Header = ({ Name, Badge, activeKey, index }) => (
  <>
    {Name}
    {Badge && <span class={`badge ${index == activeKey ? 'active-badge' : ''}`}>{Badge}</span>}
  </>
);

ListRenderer.defaultProps = {};

export default ListRenderer;
