import React from 'react';
import ReactDOM from 'react-dom';
import { Checkbox, Divider } from 'antd';
const CheckboxGroup = Checkbox.Group;
const MultipleCategoryCheckboxAll = ({
  getCheckItems,
  data,
  index,
  defaultValue,
  useDivider,
  ParentIndex,
}) => {
  const plainOptions = data.children.map(item => item.label);
  let checkingForSetALL = false;
  if (defaultValue && defaultValue.length > 0) {
    if (defaultValue.length == plainOptions.length) {
      checkingForSetALL = true;
    } else {
      checkingForSetALL = false;
    }
  }
  // const defaultCheckedList = ['Post Added', 'Friend', 'Chat'];
  const [checkedList, setCheckedList] = React.useState(defaultValue);
  //if you face any issue in check all uncomment below line and comment the two lines below.
  // const [indeterminate, setIndeterminate] = React.useState(!checkingForSetALL);
  let indeterminateCheck =
    checkedList && checkedList.length > 0 && checkedList.length < plainOptions.length;
  const [indeterminate, setIndeterminate] = React.useState(indeterminateCheck);
  const [checkAll, setCheckAll] = React.useState(checkingForSetALL);

  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    getCheckItems(list, index, ParentIndex);
  };

  const onCheckAllChange = e => {
    let checked = e.target.checked;
    let checkedOptions = checked ? plainOptions : [];
    setCheckedList(checkedOptions);

    setIndeterminate(false);

    setCheckAll(checked);
    getCheckItems(checkedOptions, index, ParentIndex);
  };

  return (
    <>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
        className="d-flex"
        disabled={data.IsEditNotAllowed}
      >
        {data.label}
      </Checkbox>
      <CheckboxGroup
        options={data.children.map(item => item.label)}
        value={checkedList}
        onChange={onChange}
        className="d-flex flex-wrap align-items-center py-1 px-2"
        disabled={data.IsAllowOnlyParentControl}
      />
      {useDivider && <Divider />}
    </>
  );
};
export default MultipleCategoryCheckboxAll;
