import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover, Button, Table, Badge, Drawer, Divider } from 'antd';
import FullCalendar from '../../../../Components/Calendar';
import CloseIcon from '../../../../Components/CloseIcon';
import {
  medicateCombos,
  listAppointment,
  getAppointment,
  resetGetAppointment,
  patientComboGroup,
  upsertGroupAppointmentGroupAllInfoJson,
  practitionerCombosOffice,
  resetGetClientGroup,
  listCombos,
  getComboFacility,
  practitionerCombos,
  comboPractitionerAll,
  comboFacilityAll,
  getStaffPractitioner,
  resetUpsertBlockAppointment,
  getGroupDropdown,
  defaultPractotionerFacilityGet,
  listProspectAppointment,
  upsertAppointment,
  upsertAppointmentReset,
  listSmartCombos,
  comboServiceCodeGet,
  preIntakeGetAll,
} from '../../../../store/actions';
import { ProcessCombos } from '../../../../store/comboConfig';
import ManageAppointment from '../dashboard/AppointmentsScreens/ManageAppointment';
import { getMMDDYYYYFormat } from '../../../../util/dateFormater';
import { mapTime } from '../../../../util/time';
import PageTitle from '../../../../Components/PageTitle';

class ProspectAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialEvents: [],
      initEventValues: {},
    };
  }
  getAppointmentList = ClientId => {
    this.props.dispatch(listProspectAppointment({}));
  };
  getCombos = () => {
    this.props.dispatch(
      listSmartCombos({
        comboTypes: 'RCM_PlaceOfService,RCM_Program',
        Json: JSON.stringify({
          Combo: [
            {
              IsPeerRecovery: true,
            },
          ],
        }),
      }),
    );
    let medicateComboTypes = {
      ComboTypes: 'ServiceCode,Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    this.props.dispatch(practitionerCombos({}));
    this.props.dispatch(practitionerCombosOffice({}));
    this.props.dispatch(getComboFacility({}));
    this.props.dispatch(
      comboServiceCodeGet({
        Json: JSON.stringify({
          Combo: [
            {
              IsPeerRecovery: true,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    this.getAppointmentList();
  }
  componentWillReceiveProps({ appointments, appointmentData }) {
    if (appointments && JSON.stringify(appointments) != JSON.stringify(this.props.appointments)) {
      this.setState({ initialEvents: appointments });
    }
    if (
      appointmentData &&
      JSON.stringify(appointmentData) != JSON.stringify(this.props.appointmentData)
    ) {
      this.getCombos();
      let appoinmentValues = {
        ...this.state.initEventValues,
        ...appointmentData,
        ClientId: appointmentData.ClientId,
        StudentId: appointmentData.StudentId,
        AppointmentId: appointmentData.AppointmentId,
        EventTitle: appointmentData.Title,
        AppointmentDate: appointmentData.AppointmentDate,
        Program: parseInt(appointmentData.ProgramId),
        StartTime: appointmentData.AppointmentTime && mapTime(appointmentData.AppointmentTime),
        EndTime: appointmentData.AppointmentEndTime && mapTime(appointmentData.AppointmentEndTime),
        ServiceCode: appointmentData.ServiceCode,
        PractitionerId: appointmentData.PractitionerId,
        PlaceOfServiceId: appointmentData.PlaceOfServiceId,
        OfficeName: appointmentData.OfficeName,
        Status: parseInt(appointmentData.Status),
        ReasonForVisit: appointmentData.ReasonForVisit,
        TimerDurationInSec: appointmentData.TimerDurationInSec,
        Notes: appointmentData.Notes,
      };
      let clientInfo = {
        PrefferedName: appointmentData.FirstName + appointmentData.LastName,
        CurrentClientId: appointmentData.ClientId,
        PrimaryPhone: appointmentData.PrimaryPhone,
        StudentImageUrl: '',
        IsthisPeerRecoveryClient: '',
        IsVoiceCallAllowed: '',
        StudentId: '',
      };
      this.setState({ initEventValues: appoinmentValues, clientInfo });
    }
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  content = tempArray => {
    return (
      <div>
        {tempArray &&
          tempArray.map(item => {
            const { color, text } = item;
            return (
              <>
                <div
                  className="d-flex align-items-center justify-content-start flex-nowrap"
                  style={{
                    width: '160px',
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                  }}
                  onClick={e => {
                    this.props.dispatch(
                      listProspectAppointment({
                        json: JSON.stringify({
                          Appointment: [
                            {
                              Filter: text,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  <div className="colorIndicators" style={{ background: color }}></div>
                  &nbsp;{' '}
                  <div
                    style={{
                      cursor: 'pointer',
                      pointerEvents: 'auto',
                    }}
                    onClick={this.hide}
                  >
                    {text}
                  </div>
                </div>
                {text == 'Block Appointment' && <Divider style={{ margin: '10px' }} />}
              </>
            );
          })}
        <Divider style={{ margin: '10px' }} />
        <Button
          type="primary"
          onClick={() => {
            this.props.dispatch(listProspectAppointment({}));
          }}
        >
          Reset Filters
        </Button>
      </div>
    );
  };
  handleVisibleChange = visible => {
    this.setState({ visible });
  };
  hide = () => {
    this.setState({
      visible: false,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      initEventValues: {},
      clientInfo: {},
      isAppointmentDrawer: false,
      isNewCalendarAppointment: false,
    });
    this.props.dispatch(upsertAppointmentReset());
    this.props.dispatch(resetGetAppointment());
  };
  handleDateSelect = selectInfo => {
    let dateClicked = selectInfo.start;
    this.getCombos();
    this.setState({
      isAppointmentDrawer: true,
      selectInfo: selectInfo,
      isNewCalendarAppointment: true,
      initEventValues: {
        AppointmentDate: dateClicked,
      },
    });
  };

  handleEventClick = clickInfo => {
    if (clickInfo && clickInfo.event._def.extendedProps.isGroup) {
    } else {
      let clickedEventDetails = {
        json: JSON.stringify({
          Appointment: [
            {
              AppointmentId: parseInt(clickInfo.event._def.publicId),
            },
          ],
        }),
      };
      this.props.dispatch(getAppointment(clickedEventDetails));
      this.setState({
        isAppointmentDrawer: true,
        clickInfo: clickInfo,
      });
    }
  };
  // <CreateAppointment ID={record.StudentId} ClientId={record.ClientId} isListMenu={true} />
  handleAppointment = val => {
    const { CurrentClientId, StudentId, IsthisPeerRecoveryClient } = this.state.clientInfo;
    const { appointmentData } = this.props;
    let data = {
      Appointment: [
        {
          IsPeerRecovery: true, //IsthisPeerRecoveryClient
          ClientId: (appointmentData && appointmentData.ClientId) || CurrentClientId,
          StudentId: (appointmentData && appointmentData.StudentId) || StudentId,
          AppointmentId: val.AppointmentId,
          Title: val.EventTitle,
          AppointmentDate: getMMDDYYYYFormat(val.AppointmentDate),
          ProgramId: val.Program,
          AppointmentTime: val.StartTime && mapTime(val.StartTime),
          AppointmentEndTime: val.EndTime && mapTime(val.EndTime),
          ServiceCode: this.getStringType(val.ServiceCode),
          // ServiceCode: val.ServiceCode,
          PractitionerId: val.PractitionerId,
          PlaceOfServiceId: val.PlaceOfServiceId,
          OfficeName: val.OfficeName,
          Status: val.Status,
          ReasonForVisit: val.ReasonForVisit,
          Notes: val.Notes,
        },
      ],
    };
    var jsonData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(upsertAppointment(jsonData));
  };
  getSelectedData = selectedData => {
    let data = {
      PrefferedName: selectedData.PreferredName,
      StudentId: selectedData.StudentId,
      CurrentClientId: selectedData.CurrentClientId,
      PrimaryPhone: selectedData.PrimaryPhone || selectedData.Phone,
      StudentImageUrl: '',
      IsthisPeerRecoveryClient: '',
      IsVoiceCallAllowed: '',
    };
    this.setState({
      clientInfo: data,
    });
  };
  onSearch = value => {
    this.props.dispatch(
      preIntakeGetAll({
        Json: JSON.stringify({
          ComponentGrid: [
            {
              SearchStr: value ? value : null,
              Filter: 'Active',
            },
          ],
        }),
      }),
    );
  };
  render() {
    const {
      isFetching,
      Facility,
      isReadOnlyUser,
      colorCoding,
      practitioners,
      Practitioner,
      ServiceCodeList,
      ProgramType,
      PlaceOfService,
      Status,
      PractitionerOffice,
      preIntakeList,
    } = this.props;
    let optionData = {
      Practitioner: Practitioner,
      ServiceCodeList: ServiceCodeList,
      ProgramType: ProgramType,
      PlaceOfService: PlaceOfService,
      Status: Status,
      PractitionerOffice: PractitionerOffice,
    };
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Prospect Appointment" />
        <div className="row">
          <div class="col-12 col-md-4 col-lg-3 py-2">
            <h4 class="mb-3 mb-md-0">Prospect Appointment</h4>
          </div>
          <div className="col-12 mt-2">
            <div className="row">
              <div className="col-sm-12 col-md-3 text-left indicator-section mb-2">
                <Popover
                  content={this.content(colorCoding)}
                  style={{ width: '180px' }}
                  placement="bottom"
                  open={this.state.visible}
                  onOpenChange={this.handleVisibleChange}
                >
                  <div className="btn black-btn">Legend / Filter</div>
                </Popover>
              </div>
              <div className="col-sm-12 col-md-9">
                {isFetching && (
                  <div
                    style={{
                      textAlign: 'center',
                      position: 'absolute',
                      left: '30%',
                      top: '100px',
                    }}
                  >
                    Loading appointments
                    <img src={`images/chatLoader.gif`} className="ml-1" alt="" />
                  </div>
                )}
              </div>

              <div className="col-12 mt-2">
                <FullCalendar
                  resources={practitioners}
                  select={this.handleDateSelect}
                  eventClick={this.handleEventClick}
                  events={this.state.initialEvents}
                />
              </div>
              {this.state.isAppointmentDrawer && (
                <Drawer
                  className="peer-recovery-drawer"
                  title={
                    <div className="drawer-title">
                      <span className="title">
                        {this.state.initEventValues.AppointmentId
                          ? `Edit Appointment`
                          : `New Appointment`}
                      </span>
                    </div>
                  }
                  placement="right"
                  open={this.state.isAppointmentDrawer}
                  width={700}
                  bodyStyle={{
                    paddingTop: '5px',
                    paddingBottom: '20px',
                    background: '#fff',
                  }}
                  closable={true}
                  destroyOnClose={true}
                  maskClosable={false}
                  closeIcon={<CloseIcon />}
                  onClose={this.onCloseDrawer}
                >
                  <ManageAppointment
                    handleAppointment={this.handleAppointment}
                    initialValues={this.state.initEventValues}
                    AppointmentId={this.state.initEventValues.AppointmentId}
                    // ClientId={ClientId}
                    BannerData={this.state.clientInfo}
                    OptionData={optionData}
                    // getCallEndDetails={this.getCallEndDetails}
                    onClose={this.onCloseDrawer}
                    getAppointmentList={this.getAppointmentList}
                    getSelectedData={this.getSelectedData}
                    onSearch={this.onSearch}
                    isNewCalendarAppointment={this.state.isNewCalendarAppointment}
                    clientList={preIntakeList}
                  />
                </Drawer>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  list_prospect_appointment,
  get_appointment,
  practitioner_combos,
  combos,
  combo_service_code_get,
  medicate_combos,
  get_combo_facility,
  pre_intake_get_all,
}) => {
  const { data, isFetching, error } = list_prospect_appointment;
  const {
    data: getAppointmentData,
    isFetching: isFetchingGetAppointment,
    error: errorGetAppointment,
  } = get_appointment;
  const { data: practitionerData } = practitioner_combos;
  const { data: ComboServiceCodeData } = combo_service_code_get;
  const { data: medicateCombos } = medicate_combos;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: preIntakeListData } = pre_intake_get_all;

  let appointments = [];
  let colorCoding = [];
  let practitioners = [];

  let appointmentData = null;
  let Practitioner = [];
  let ServiceCodeList = [];
  let PlaceOfService = [];
  let ProgramType = [];
  let Status = [];
  let PractitionerOffice = [];
  let preIntakeList = [];

  if (data) {
    appointments = data.Appointment || [];
    colorCoding = data.ColorCoding || [];
    practitioners = data.Practitioners || [];
  }
  if (getAppointmentData && getAppointmentData.Appointment) {
    appointmentData = getAppointmentData.Appointment[0];
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner =
      practitionerData.PractitionerList.filter(provider => provider.IsArchived === false) || [];
  }
  if (combos.data && combos.data['RCM_Program']) {
    ProgramType = combos.data['RCM_Program'];
    PlaceOfService = combos.data['RCM_PlaceOfService'];
  }
  if (ComboServiceCodeData && ComboServiceCodeData.data) {
    ServiceCodeList = JSON.parse(ComboServiceCodeData.data).Combo || [];
  }
  if (medicateCombos) {
    Status = medicateCombos.Status;
  }
  if (ComboFacilityData) {
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (preIntakeListData && preIntakeListData.data) {
    preIntakeList = preIntakeListData.data;
  }
  return {
    appointments,
    colorCoding,
    practitioners,
    isFetching,
    appointmentData,
    Practitioner,
    ProgramType,
    PlaceOfService,
    ServiceCodeList,
    Status,
    PractitionerOffice,
    preIntakeList,
  };
};
export default connect(mapStateToProps)(ProspectAppointment);
