import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Drawer } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import CustomModal from '../../../../../core/modal';
import AntdModal from '../../../../../Components/CustomModal';
import { ReduxNoteAddEditBox } from './noteAddEditBox';
import Axios from 'axios';
import {
  getGroupAppointmentNote,
  getPatientList,
  medicateCombos,
  listGroupMembership,
  appointmentNoteList,
  resetUpsertAppointmentNote,
  listGroupAppointmentNote,
  listCombos,
  getAppointmentNote,
  upsertAppointmentNote,
  upsertAppointmentNoteAddendum,
  resetAddCallSession,
  getListClientGroup,
  getClientGroup,
  practitionerCombosOffice,
  upsertClientGroup,
} from '../../../../../store/actions';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

class GroupNoteBlock extends Component {
  constructor() {
    super();
    this.state = {
      groupMsg: '',
      newAddendumBtn: true,
      visible: false,
      noteAddEditBox: true,
      editAddendumObj: {},
      note: '',
      editAppointmentId: '',
      Addendum: '',
      noteBoxInitObj: {},
      // forceRender: false,
      pinConfirmModalVisible: false,
    };
  }

  resetPinConfirmModalVisible = () => {
    this.setState({ pinConfirmModalVisible: false });
  };
  getFormTypeAndFormId = (formType, formId) => {
    this.setState({
      formType,
      formId,
    });
  };

  getGroupId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      groupId = parseInt(groupId);
    } else {
      groupId = null;
    }
    return groupId;
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  getIsSignStatus = isSignStatusValue => {
    this.setState({
      isSignStatus: isSignStatusValue,
    });
  };

  getGroupAddendumSign = isGroupAddendumSignValue => {
    this.setState({
      isGroupAddendumSignStatus: isGroupAddendumSignValue,
    });
  };

  resetEditAddendumObj = obj => {
    // this.setState({ editAddendumObj: {} });
    this.setState({ editAddendumObj: obj });
  };

  resetnoteAddEditBox = () => {
    this.setState({
      noteAddEditBox: !this.state.noteAddEditBox,
    });
  };

  resetnewAddendumBtn = () => {
    this.setState({
      editAddendumObj: {},
      newAddendumBtn: !this.state.newAddendumBtn,
    });
  };

  resetnote = value => {
    this.setState({
      note: value,
      // forceRender: true,
    });
  };

  reseteditAppointmentId = value => {
    this.setState({
      editAppointmentId: value,
    });
  };

  showDrawer = GroupAppointmentId => {
    this.props.dispatch(
      getGroupAppointmentNote({
        Json: JSON.stringify({
          GroupAppointmentNote: [
            {
              GroupAppointmentId: GroupAppointmentId,
            },
          ],
        }),
      }),
    );
    this.setState({
      visible: true,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      visible: false,
      newAddendumBtn: true,
    });
  };

  resetInitValues = () => {
    this.setState({ noteBoxInitObj: {} });
  };

  editAddendum = () => {
    this.setState({ newAddendumBtn: false });
  };

  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes: 'ServiceCode',
    };

    // this.props.dispatch(medicateCombos(medicateComboTypes));
    // this.props.dispatch(listGroupMembership({}));
    // this.props.dispatch(getPatientList({}));
    // this.props.dispatch(
    //   listGroupMembership({
    //     json: JSON.stringify({
    //       Client: [
    //         {
    //           ClientId: this.props.clientId,
    //         },
    //       ],
    //     }),
    //   }),
    // );
    // this.props.dispatch(
    //   appointmentNoteList({
    //     Json: JSON.stringify({
    //       AppointmentNote: [
    //         {
    //           ClientId: this.props.clientId,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }

  componentWillReceiveProps({
    isGroupCreated,
    ListGroup,
    // AppointmentNote,
    GroupAppointmentNote,
    upsertAppointmentNoteSuccess,
    upsertAppointmentNoteAddendumSuccess,
    validateStaffPinData,
    upsertGroupAppointmentNoteSuccessFlag,
    upsertGroupAppointmentNoteAddendumSuccessFlag,
    signGroupAppointmentNoteSuccessFlag,
    newlyGenratedGroupAppointmentNoteAddendumId,
  }) {
    if (
      upsertAppointmentNoteSuccess &&
      upsertAppointmentNoteSuccess != this.props.upsertAppointmentNoteSuccess
    ) {
      this.setState({ note: '', pinConfirmModalVisible: true });

      this.props.dispatch(
        appointmentNoteList({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(resetUpsertAppointmentNote());

      this.toggle('Addendum Updated Successfully');

      this.onCloseDrawer();
    }

    if (
      upsertAppointmentNoteAddendumSuccess &&
      upsertAppointmentNoteAddendumSuccess != this.props.upsertAppointmentNoteAddendumSuccess
    ) {
      this.onCloseDrawer();
      this.props.dispatch(
        appointmentNoteList({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
      if (this.props.GroupAppointmentNote.AppointmentNoteAddendumId == null) {
        this.toggle('Addendum Added Successfully');
      } else {
        this.toggle('Addendum Updated Successfully');
      }
    }

    if (isGroupCreated && isGroupCreated != this.props.isGroupCreated) {
      // this.props.dispatch(listGroupMembership({}));
      this.props.dispatch(getPatientList({}));
      this.props.dispatch(
        listGroupMembership({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
      this.setState({
        inviteMsg: 'Group created successfully.',
        isModelOpen: true,
      });
    }
    if (ListGroup && JSON.stringify(ListGroup) != JSON.stringify(this.props.ListGroup)) {
      this.setState({
        ListGroup,
      });
    }

    if (
      GroupAppointmentNote &&
      JSON.stringify(GroupAppointmentNote) != JSON.stringify(this.props.GroupAppointmentNote)
    ) {
      const { Note } = GroupAppointmentNote;
      if (Note && Note.length > 0) {
        this.setState({ noteAddEditBox: false });
      } else {
        this.setState({ noteAddEditBox: true });
      }
      let newObj = {
        note: GroupAppointmentNote.Note,
        startTime: GroupAppointmentNote.StartTime,
        endTime: GroupAppointmentNote.EndTime,
        comboTreatmentPlanIntervention: GroupAppointmentNote.TreatmentPlanInterventionId,
      };
      this.setState({
        noteBoxInitObj: newObj,
      });
    }

    if (
      signGroupAppointmentNoteSuccessFlag &&
      signGroupAppointmentNoteSuccessFlag != this.props.signGroupAppointmentNoteSuccessFlag
    ) {
      this.setState({
        note: '',
        pinConfirmModalVisible: false,
        failOn: false,
        groupMsg: 'Group Note and Attendee Notes signed and submitted successfully',
      });
      setTimeout(() => {
        this.setState({
          groupMsg: '',
          failOn: false,
        });
      }, 2000);
      setTimeout(() => {
        this.props.callDataAPI();
      }, 2000);
    }
    if (
      upsertGroupAppointmentNoteSuccessFlag &&
      upsertGroupAppointmentNoteSuccessFlag != this.props.upsertGroupAppointmentNoteSuccessFlag
    ) {
      this.setState({
        note: '',
        // pinConfirmModalVisible: true
      });

      if (this.state.isSignStatus) {
        let data = {
          Json: JSON.stringify({
            Staff: [
              {
                FormType: this.state.formType,
                FormId: this.state.formId,
              },
            ],
          }),
        };

        let me = this;
        Axios.post('Medicaid/PreValidatePin', data).then(response => {
          let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
          let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
          let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;
          if (isValid) {
            me.setState({
              note: '',
              newAddendumBtn: true,
              pinConfirmModalVisible: true,
              IsRealSignature: IsRealSignature,
              // groupMsg: 'Group Note and Attendee Notes signed and submitted Successfully',
            });

            // setTimeout(() => {
            //   me.setState({
            //     groupMsg: '',
            //   });
            // }, 2000);

            // this.props.callDataAPI();

            // if (me.props.AppointmentNote.AppointmentNoteId == null) {
            // } else {
            //   if (me.state.isSignStatus) {
            //     if (validateStaffPinData.IsValid) {
            //     }
            //   } else {
            //   }
            // }
          } else {
            this.setState({
              // pinConfirmModalVisible: false,
              groupMsg: failMsg,
              failOn: true,
            });
            setTimeout(() => {
              this.setState({
                groupMsg: '',
                failOn: false,
              });
            }, 5000);
          }
        });

        // if (validateStaffPinData.IsValid) {
        //   let GroupId = this.getGroupId();
        //   this.props.dispatch(
        //     listGroupAppointmentNote({
        //       json: JSON.stringify({
        //         GroupAppointmentNote: [
        //           {
        //             GroupId: GroupId,
        //           },
        //         ],
        //       }),
        //     }),
        //   );

        //   this.setState({ groupMsg: 'Group Note signed and submitted Successfully' }, () => {
        //     setTimeout(() => {
        //       this.setState({
        //         groupMsg: '',
        //       });
        //     }, 2000);
        //     this.props.callDataAPI();
        //   });
        // } else {
        // }
      } else {
        let GroupId = this.getGroupId();
        this.props.dispatch(
          listGroupAppointmentNote({
            json: JSON.stringify({
              GroupAppointmentNote: [
                {
                  GroupId: this.props.clickedGroupId || GroupId,
                },
              ],
            }),
          }),
        );
        // this.setState({ note: '' });

        // this.props.dispatch(resetUpsertAppointmentNote());
        // if (this.props.AppointmentNote.AppointmentNoteId == null) {
        //   this.toggle('Note Added Successfully');
        // } else {
        //   this.toggle('Note Updated Successfully');
        // }
        this.setState({ groupMsg: 'Group Note Added Successfully' }, () => {
          setTimeout(() => {
            this.setState({
              groupMsg: '',
              failOn: false,
            });
          }, 2000);
          setTimeout(() => {
            this.props.callDataAPI();
          }, 2000);
        });
        // this.props.toggle('Group Note Added Successfully');
        // this.props.onCloseDrawer();
      }
    }

    if (
      upsertGroupAppointmentNoteAddendumSuccessFlag &&
      upsertGroupAppointmentNoteAddendumSuccessFlag !=
        this.props.upsertGroupAppointmentNoteAddendumSuccessFlag
    ) {
      if (this.state.isGroupAddendumSignStatus) {
        let data = {
          Json: JSON.stringify({
            Staff: [
              {
                FormType: this.state.formType,
                FormId: this.state.formId,
              },
            ],
          }),
        };

        let me = this;
        Axios.post('Medicaid/PreValidatePin', data).then(response => {
          let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
          let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
          let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;
          if (isValid) {
            me.setState({
              note: '',
              newAddendumBtn: true,
              pinConfirmModalVisible: true,
              IsRealSignature: IsRealSignature,
              isGroupAddendumSignStatus: false,
              newlyGenratedGroupAppointmentNoteAddendumId: newlyGenratedGroupAppointmentNoteAddendumId,
            });
          } else {
            this.setState({
              // pinConfirmModalVisible: false,
              groupMsg: failMsg,
              failOn: true,
            });
            setTimeout(() => {
              this.setState({
                groupMsg: '',
                failOn: false,
              });
            }, 5000);
          }
        });
      } else {
           let GroupId = this.getGroupId();
        this.props.dispatch(
          listGroupAppointmentNote({
            json: JSON.stringify({
              GroupAppointmentNote: [
                {
                  GroupId: this.props.clickedGroupId || GroupId,
                },
              ],
            }),
          }),
        );
        // this.setState({ note: '' });
        // this.props.dispatch(resetUpsertAppointmentNote());
        // if (this.props.AppointmentNote.AppointmentNoteId == null) {
        //   this.toggle('Note Added Successfully');
        // } else {
        //   this.toggle('Note Updated Successfully');
        // }
        this.props.toggle('Group Note Addendum Updated Successfully');
        this.props.onCloseDrawer();
      }
    }
  }

  render() {
    const { AttendeesCount, Attendees } = this.props.GroupAppointmentNote;
    const { columns, newAddendumBtn, editAddendumObj, noteAddEditBox } = this.state;
    const {
      isFetchingGetGroupAppointmentNote,
      isFetching,
      isFetchingGetAppointmentNote,
      groupDrawerVisible,
      onCloseDrawer,
    } = this.props;

    const {
      GroupAppointmentId,
      SessionDate,
      StartTime,
      EndTime,
      SessionTime,
      DurationInMin,
      GroupName,
      SessionType,
      ServiceCode,
      ServiceCodeDescription,
      ProgramDescription,
      Status,
      AddendumCount,
      IsGroup,
      NoteSignHoursLeft,
      Note,
      SignedDate,
      IsSign,
      GroupAppointmentNoteAddendum,
      SignedMessage,
      IsEligibleForUnSign,
      IsEligibleForSign,
    } = this.props.GroupAppointmentNote;
    return (
      <React.Fragment>
        <div>
          <Loader2 loading={isFetchingGetGroupAppointmentNote} />
          {/* <p style={{
            color: this.state.failOn ? 'red' : 'green'
          }}>{this.state.groupMsg}</p> */}
          <div className="individualNotes" style={{ position: 'relative' }}>
            <div className="row">
              <div className="col-md-8">
                <h2
                  style={{
                    color: '#000',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    lineHeight: '27px',
                  }}
                >
                  {GroupName}
                  {/* {ClientName} */}
                </h2>
              </div>
              <div className="col-md-4 text-right">
                {/* <i class="far fa-clock"></i> &nbsp;
                  {SessionTime} */}
                <p
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    lineHeight: '22px',
                    textTransform: 'uppercase',
                    color: '#828282',
                  }}
                >
                  {SessionDate}
                </p>
              </div>
            </div>
          </div>

          <ReduxNoteAddEditBox
            noteAddEditBox={noteAddEditBox}
            // AppointmentNote={AppointmentNote}
            // AppointmentId={AppointmentId}
            GroupAppointmentId={GroupAppointmentId}
            SignedDate={SignedDate}
            IsSign={IsSign}
            Note={Note}
            newAddendumBtn={this.state.newAddendumBtn}
            editAddendumObj={this.state.editAddendumObj}
            GroupAppointmentNoteAddendum={GroupAppointmentNoteAddendum}
            AddendumCount={AddendumCount}
            resetnote={this.resetnote}
            resetnewAddendumBtn={this.resetnewAddendumBtn}
            resetnoteAddEditBox={this.resetnoteAddEditBox}
            localNote={this.state.note}
            editAppointmentId={this.state.editAppointmentId}
            dispatch={this.props.dispatch}
            editAddendum={this.editAddendum}
            resetEditAddendumObj={this.resetEditAddendumObj}
            ServiceCodeDescription={ServiceCodeDescription}
            SessionType={SessionType}
            ProgramDescription={ProgramDescription}
            initialValues={this.state.noteBoxInitObj}
            resetInitValues={this.resetInitValues}
            // AppointmentNoteId={AppointmentNoteId}
            // DurationInMin={DurationInMin}
            ClientId={this.props.clientId}
            ServiceCode={ServiceCode}
            onCloseDrawer={this.props.onCloseDrawer}
            SignedMessage={SignedMessage}
            NoteSignHoursLeft={NoteSignHoursLeft}
            pinConfirmModalVisible={this.state.pinConfirmModalVisible}
            getIsSignStatus={this.getIsSignStatus}
            getGroupAddendumSign={this.getGroupAddendumSign}
            getFormTypeAndFormId={this.getFormTypeAndFormId}
            resetPinConfirmModalVisible={this.resetPinConfirmModalVisible}
            IsEligibleForUnSign={IsEligibleForUnSign}
            IsEligibleForSign={IsEligibleForSign}
            callDataAPI={this.props.callDataAPI}
            IsRealSignature={this.state.IsRealSignature}
            newlyGenratedGroupAppointmentNoteAddendumId={
              this.state.newlyGenratedGroupAppointmentNoteAddendumId
            }
            groupMsg={this.state.groupMsg}
            failOn={this.state.failOn}
            getDirtySubmitSucceededStatus={this.props.getDirtySubmitSucceededStatus}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  get_group_appointment_note,
  medicate_combos,
  practitioner_combos,
  practitioner_combos_office,
  list_group_membership,
  upsert_group,
  get_patient_list,
  get_client_group,
  upsert_client_group,
  get_list_client_group,
  appointment_note_list,
  // get_appointment_note,
  upsert_appointment_note,
  upsert_appointment_note_addendum,
  upsert_group_appointment_note,
  upsert_group_appointment_note_addendum,
  sign_group_appointment_note,
  list_group_appointment_note,
  validate_staff_pin,
  combos,
}) => {
  var { data: validateDataPin } = validate_staff_pin;
  const {
    data: getGroupAppointmentNote,
    isFetching: isFetchingGetGroupAppointmentNote,
  } = get_group_appointment_note;
  const { data: medicateCombos } = medicate_combos;
  const { data: practitionerData } = practitioner_combos;
  const { data: practitionerOfficeData } = practitioner_combos_office;
  const { data: listgroup, isFetching } = list_group_membership;
  const { data: getPatientListData } = get_patient_list;
  const { data: getClientListData, isFetching: isFetchingGroupClient } = get_client_group;
  const {
    data: getListClientGroup,
    isFetching: isFetchingGetListClienGroup,
  } = get_list_client_group;
  const { data: appointmentNoteList } = appointment_note_list;
  const { data: listGroupAppointmentNote } = list_group_appointment_note;
  const { data: upsertGroupAppointmentNoteSuccess } = upsert_group_appointment_note;
  const {
    data: upsertGroupAppointmentNoteAddendumSuccess,
  } = upsert_group_appointment_note_addendum;
  const { data: signGroupAppointmentNoteSuccess } = sign_group_appointment_note;

  let validateStaffPinData = {};
  let GroupAppointmentNote = [];
  let Client = [];
  let GroupClientList = [];
  let isSuccess = false;
  let medicateCombosObj = {
    ServiceCode: [],
  };
  let Practitioner = [];
  let PractitionerOffice = [];
  let ListGroup = [];
  let isGroupCreated = false;
  let searchedClient = [];
  let noteList = [];
  let upsertAppointmentNoteSuccess = false;
  let upsertAppointmentNoteAddendumSuccess = false;
  let appointmentListData = [];
  let upsertGroupAppointmentNoteSuccessFlag = false;
  let upsertGroupAppointmentNoteAddendumSuccessFlag = false;
  let newlyGenratedGroupAppointmentNoteAddendumId;
  let signGroupAppointmentNoteSuccessFlag = false;

  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (getGroupAppointmentNote) {
    GroupAppointmentNote = getGroupAppointmentNote.GroupAppointmentNote[0] || [];
  }

  if (getClientListData) {
    GroupClientList = getClientListData.ClientGroup;
  }
  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList;
  }
  if (practitionerOfficeData) {
    PractitionerOffice = practitionerOfficeData.PractitionerOfficeList || [];
  }
  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
    };
  }

  if (listgroup && listgroup.Group) {
    ListGroup = listgroup.Group;
  }
  if (upsert_group && upsert_group.data && upsert_group.data.success) {
    isGroupCreated = upsert_group.data.success;
  }
  if (getPatientListData && getPatientListData.data) {
    Client = JSON.parse(getPatientListData.data)[0].Client;
  }
  if (upsert_client_group.data && upsert_client_group.data.success) {
    isSuccess = upsert_client_group.data.success;
  }
  if (getListClientGroup) {
    searchedClient = getListClientGroup.Client || [];
  }
  if (appointmentNoteList) {
    noteList = appointmentNoteList[0].AppointmentNote || [];
  }

  if (upsert_appointment_note && upsert_appointment_note.data) {
    upsertAppointmentNoteSuccess = upsert_appointment_note.data.success;
  }
  if (upsert_appointment_note_addendum && upsert_appointment_note_addendum.data) {
    upsertAppointmentNoteAddendumSuccess = upsert_appointment_note_addendum.data.success;
  }
  if (listGroupAppointmentNote) {
    appointmentListData = listGroupAppointmentNote.GroupAppointmentNote;
  }
  if (upsertGroupAppointmentNoteSuccess) {
    upsertGroupAppointmentNoteSuccessFlag = upsertGroupAppointmentNoteSuccess.success;
  }
  if (
    upsertGroupAppointmentNoteAddendumSuccess &&
    upsertGroupAppointmentNoteAddendumSuccess.success
  ) {
    upsertGroupAppointmentNoteAddendumSuccessFlag =
      upsertGroupAppointmentNoteAddendumSuccess.success;
    newlyGenratedGroupAppointmentNoteAddendumId = upsertGroupAppointmentNoteAddendumSuccess.result;
  }
  if (signGroupAppointmentNoteSuccess) {
    signGroupAppointmentNoteSuccessFlag = signGroupAppointmentNoteSuccess.success;
  }
  return {
    GroupAppointmentNote,
    isFetchingGetGroupAppointmentNote,
    medicateCombosObj: medicateCombosObj,
    Practitioner: Practitioner,
    PractitionerOffice: PractitionerOffice,
    ListGroup,
    isGroupCreated,
    isFetching,
    isFetchingGroupClient,
    isFetchingGetListClienGroup,
    client: Client || [],
    GroupClientList,
    isSuccess,
    searchedClient,
    noteList,
    upsertAppointmentNoteSuccess,
    upsertAppointmentNoteAddendumSuccess,
    appointmentListData,
    validateStaffPinData,
    upsertGroupAppointmentNoteSuccessFlag,
    upsertGroupAppointmentNoteAddendumSuccessFlag,
    newlyGenratedGroupAppointmentNoteAddendumId,
    signGroupAppointmentNoteSuccessFlag,
  };
};

export default connect(mapStateToProps)(withRouter(GroupNoteBlock));
