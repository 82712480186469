import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactRangePicker from '../component/Range-Picker';
import { getStaffShiftLog } from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import TextareaAutosize from 'react-autosize-textarea';
import ExportExcel from '../Components/ExportExcel';

class ShiftLogList extends Component {
  constructor(props) {
    super(props);
    const { startDate } = props;
    this.state = {
      startDate: startDate || null,
      staffLogNotes: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(getStaffShiftLog({}));
  }
  componentWillReceiveProps({ staffShiftLog }) {
    if (
      staffShiftLog &&
      JSON.stringify(staffShiftLog) != JSON.stringify(this.props.staffShiftLog)
    ) {
      let data = staffShiftLog.map((item, index) => {
        ['NewNote', 'IsStaffViewed'].forEach(e => delete item[e]);
        return item;
      });
      this.setState({
        staffShiftLogData: data,
      });
    }
  }
  handleChange = (key, date) => {
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });

    this.props.dispatch(
      getStaffShiftLog({
        JsonInput: JSON.stringify({
          StaffShiftLog: [
            {
              StartDate: moment(date[0]).format('YYYY/MM/DD'),
              EndDate: moment(date[1]).format('YYYY/MM/DD'),
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { isFetching, staffShiftLog } = this.props;
    const { staffShiftLogData } = this.state;
    return (
      <div className="col-12">
        <Loader2 loading={isFetching} />
        <div className="">
          <div className="row m-0">
            <div className="col-12 text-left mb-3">
              <span className="font-weight-bold ">Filter By Date</span>
            </div>

            <ReactRangePicker handleChange={this.handleChange} />
            <div className="col-12 mb-3 mt-2 d-flex align-items-center justify-content-between">
              <span className="font-weight-bold ">Shift Notes</span>
              <div>
                {staffShiftLogData && staffShiftLogData.length > 0 && (
                  <ExportExcel
                    reportData={{
                      'Shift Log': [...staffShiftLogData],
                    }}
                    labelText={'Excel'}
                    filename="Shift-Log"
                  />
                )}
              </div>
            </div>
            <div
              className="col-12 col-md-12 enrollment-form"
              style={{ maxHeight: '600px', overflowY: 'scroll' }}
            >
              {staffShiftLog &&
                staffShiftLog.map((item, index) => {
                  return (
                    <div className="shift-log-detail" key={item.StaffName + index}>
                      <div className="text-left mb-1 d-flex justify-content-between">
                        <div className="mb-2">
                          <span className="font-weight-bold" style={{ color: '#0000006b' }}>
                            Staff Name :{' '}
                          </span>
                          <span>{item.StaffName} </span>
                        </div>
                        <div className="mb-2">
                          <span className="font-weight-bold" style={{ color: '#0000006b' }}>
                            Created Date :{' '}
                          </span>
                          <span>{item.CreatedDate} </span>
                        </div>
                      </div>
                      <div className="text-left mb-1">
                        <span className="font-weight-bold" style={{ color: '#0000006b' }}>
                          Shift Note :{' '}
                        </span>
                        {/* <span>{item.ShigtLogNote} </span> */}
                        <div className="col-12 rcm-panel">
                          <TextareaAutosize
                            className="textarea-type"
                            rows="1"
                            name="textarea_field"
                            key="NoteBox"
                            id="NoteBox"
                            value={item.ShiftLogNote}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_staff_shift_log }) => {
  const { data: loadData, isFetching } = get_staff_shift_log;

  let StaffShiftLog = [];
  var LastShiftLog = {};

  if (loadData && loadData.StaffShiftLog) {
    StaffShiftLog = loadData.StaffShiftLog;
    LastShiftLog = loadData.StaffShiftLog[0];
  }

  return {
    staffShiftLog: StaffShiftLog,
    lastShiftLog: LastShiftLog,
    isFetching,
  };
};
export default connect(mapStateToProps)(withRouter(ShiftLogList));
