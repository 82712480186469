import React, { useState, useEffect } from 'react';
import { submit, formValueSelector, change, reset } from 'redux-form';
import renderHTML from 'react-render-html';
import { Tabs, Drawer,Tooltip } from 'antd';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContractHeader from './contractHeader';
import CreateContract from './createContract/createContracForm';
import TermsConditions from './createContract/termsAndCondition';
import PendingChangeRequest from './pendingChangeRequest';
import Ledger from './ledger';
import Payers from './payers';
import Documents from './documents';
import Authorizations from '../authorizations';
import Requests from './requests';
import AssignCareTeam from './createContract/assignCareTeam';
import Distribution from './createContract/distribution';

import {
  resetSmartContractGet,
  smartContractGet,
  resetSmartContractUpsert,
  clientInsuranceGet,
  smartContractUpdate,
  resetSmartContractUpdate,
  smartContractUpsert,
  smartContractPatientListGet,
  listSmartCombos,
  smartContractLedgerGet,
  smartContractTermsConditionUpsert,
  smartContractTermsConditionGetAllInfoJson,
  resetSmartContractTermsConditionUpsert,
  smartContractDocumentRequestGet,
  resetSmartContractDocumentRequestGet,
} from '../../../../../store/actions';
import CustomModal from '../../../../../core/modal';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import getParam from '../../../../../util/getParam';
import CloseIcon from '../../../../../component/closeIcon';
import { stringDate } from '../../../../../util/date';

const SmartContract = props => {
  const SMART_CONTRACT_ID = getParam(props.history, 'smartContractId') || null;
  const IS_NEW_CONTRACT = getParam(props.history, 'new-contract') || 'false';
  const CLIENT_ID = getParam(props.history, 'pId') || null;
  const dispatch = useDispatch();
  const [bundle, setBundle] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [activeKey, setActiveKey] = useState('0');
  const [header, setHeader] = useState(null);
  const [contractType, setContractType] = useState('1');
  const [enableCreateButton, setEnableCreateButton] = useState(false);
  const [finalSubmit, setFinalSubmit] = useState(null);
  const [getBundleSummaryArray, setGetBundleSummaryArray] = useState([]);
  // const [parentStateInitialValues, setParentStateInitialValues] = useState({
  //   Goal: [{ Bundles: [{}], Milestone: [{ Task: [{}] }] }],
  // });
  // const [parentStateInitialValues, setParentStateInitialValues] = useState({
  //   Bundles: [{ ServiceType: [], Milestone: [{ Task: [{}] }] }],
  // });
  const [parentStateInitialValues, setParentStateInitialValues] = useState({
    Bundles: [{ ServiceType: [], Goal: [] }],
  });
  const [indexOfNetMarginChange, setIndexOfNetMarginChange] = useState(null);
  const [currentServiceTypeIndex, setCurrentServiceTypeIndex] = useState(null);

  const [tabName, setTabName] = useState([
    { tabLabel: 'Contract', keyIndex: 0, Content: 'CreateContract' },
    // { tabLabel: 'Contract Terms', keyIndex: 1, Content: 'TermsConditions' },
  ]);
  const [pendingContract, setPendingContract] = useState(false);
  const [newContractIdValue, setNewContractIdValue] = useState(null);
  const [fieldDisabled, setFieldDisabled] = useState(IS_NEW_CONTRACT == "true" ? false : true);
  const [noOfReqDoc, setNoOfReqDoc] = useState(null);
  
  const components = {
    CreateContract,
    TermsConditions,
    Authorizations,
    Ledger,
    Requests,
    Payers,
    Documents,
    AssignCareTeam,
    Distribution,
  };

  // const { termsList } = useSelector(({ terms_condition_list_item: { data } }) => {
  //   return {
  //     termsList: data && data.termsList,
  //   };
  // }, shallowEqual);

  const onTabClick = key => {
    if (tabName[key]) {
      setActiveKey(key);
    }
  };

  const handleNext = () => {
    setActiveKey(String(parseInt(activeKey) + 1));
  };

  const goToPreviuos = () => {
    setActiveKey(String(parseInt(activeKey) - 1));
  };

  const handleSubmitAndNext = (data, currentTab, tabsCount) => {
    if (parseInt(currentTab) + 1 != tabsCount && finalSubmit == 'Next') {
      handleNext(currentTab);
    } else {
      // onContractSubmit(data);
      onContractFirstTabSubmit(data);
    }
  };

  useEffect(() => {
    dispatch(
      listSmartCombos({
        comboTypes:
          'UnitOfMeasurement,CenterProgramType,ServiceCodeBundle,SmartContractMeasure,GoalLibrary,GoalTimePeriod,CenterServiceTypeCenter,DurationFrequency',
        json: JSON.stringify({
          Combo: [
            {
              ClientId: props.clientId,
            },
          ],
        }),
      }),
    );
    let Data = {
      JSON: JSON.stringify({
        Request: [{ SmartContractId: SMART_CONTRACT_ID, RequestType: '30212' }],
      }),
    };
    dispatch(smartContractDocumentRequestGet(Data));
  }, []);

  useEffect(() => {
    if (props && !SMART_CONTRACT_ID && IS_NEW_CONTRACT != 'true') {
      props && props.showListView();
    }
  }, [props && props.history.location.search]);

   useEffect(() => {
     if (props.requestGetInfo && props.requestGetInfo.length > 0) {
       let a = props.requestGetInfo.filter(item => item.IsPayerRequested && item.DocumentGuid == null).length;
       setNoOfReqDoc(a);
     }
   }, [props.requestGetInfo]);

  useEffect(() => {
    dispatch(clientInsuranceGet({ Json: JSON.stringify({ Client: [{ ClientId: CLIENT_ID }] }) }));
    if (SMART_CONTRACT_ID) {
      getLatestData();
      // dispatch(
      //   smartContractTermsConditionGetAllInfoJson({
      //     Json: JSON.stringify({ TermsCondition: [{ SmartContractId: SMART_CONTRACT_ID }] }),
      //   }),
      // );
    }
    return () => {
      dispatch(resetSmartContractGet());
      dispatch(resetSmartContractDocumentRequestGet({}));
    };
  }, []);

  useEffect(() => {
    if (finalSubmit != null) {
      dispatch(submit('contractForm'));
      setFinalSubmit(null);
    }
  }, [finalSubmit]);

  const onContractSubmit = values => {
    const {
      ContractName,
      StartDate,
      EndDate,
      Goal,
      TermId,
      Terms,
      Conditions,
      Title,
      TermsListItem,
      TermsConditionId,
    } = values || {};
    const {
      totalBillingRate,
      totalCostOfCare,
      totalNetMarginAmount,
      totalNetMarginPercentage,
      totalWeightedOverheadPercentage,
    } = props.summaryData || {};

    const getGoalArray = goal => {
      return (
        goal &&
        goal.map(goalItem => {
          const { SmartContractGoalId, GoalLibraryId, Duration, Bundles, Milestone } = goalItem;
          return {
            SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
            GoalLibraryId: GoalLibraryId ? GoalLibraryId : null,
            Duration: Duration,
            Bundle:
              Bundles &&
              Bundles.map(bundleItem => {
                const {
                  SmartContractGoalId,
                  SmartContractServiceCodeBundleId,
                  ServiceCodeBundleId,
                  ServiceType,
                } = bundleItem;
                return {
                  SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
                  SmartContractServiceCodeBundleId: SmartContractServiceCodeBundleId
                    ? SmartContractServiceCodeBundleId
                    : null,
                  ServiceCodeBundleId: ServiceCodeBundleId ? ServiceCodeBundleId : null,
                  Services:
                    ServiceType &&
                    ServiceType.map(ServiceTypeItem => {
                      const {
                        BuildServiceBundle,
                        CenterServiceTypeCenterId,
                        ProgramId,
                      } = ServiceTypeItem;
                      return {
                        CenterServiceTypeCenterId: ProgramId,
                        ServiceCode:
                          BuildServiceBundle &&
                          BuildServiceBundle.map(serviceCodeItem => {
                            const { NoOfServices } = serviceCodeItem;
                            return {
                              NoOfService: NoOfServices,
                              ...serviceCodeItem,
                            };
                          }),
                      };
                    }),
                };
              }),
            Milestone: Milestone,
          };
        })
      );
    };

    const genrateTerms = terms => {
      let list =
        terms &&
        terms.map((termItem, index) => {
          let termDesc = TermsListItem && TermsListItem[index].descList;
          return {
            Category: termItem.Category,
            TermsCategoryId: termItem.TermsCategoryId || null,
            Terms: termDesc,
          };
        });

      return list;
    };
    const genrateCondition = condition => {
      let conditionList =
        condition &&
        condition.map((item, index) => {
          return {
            Condition: item && item.Condition,
            ConditionId: item && item.ConditionId ? item.ConditionId : null,
            ConditionCase:
              item &&
              item.ConditionCase &&
              item.ConditionCase.map((caseItem, caseIndex) => {
                return {
                  CaseId: caseIndex + 1,
                  CaseIf: caseItem && caseItem.CaseIf,
                  CaseThen: caseItem && caseItem.CaseThen,
                  ConditionCaseId: (caseItem && caseItem.ConditionCaseId) || null,
                };
              }),
          };
        });

      return conditionList;
    };

    let data = {
      SmartContract: [
        {
          SmartContractId: SMART_CONTRACT_ID,
          ClientId: CLIENT_ID,
          ContractName: ContractName,
          PaymentContractTypeId: '1',
          StartDate: stringDate(StartDate),
          EndDate: stringDate(EndDate),
          Goal: Goal ? getGoalArray(Goal) : null,
          TotalOverHead: totalWeightedOverheadPercentage,
          TotalCostOfCare: totalCostOfCare,
          TotalNetMargin: totalNetMarginPercentage ? totalNetMarginPercentage.toFixed(2) : null,
          TotalBillingRate: totalBillingRate,
          TotalNetMarginAmount: totalNetMarginAmount,
          PaymentStatusId: 1,
          IsContractCreated: finalSubmit == 'Create' ? true : false,
          TermsCondition: [
            {
              TermsConditionId: TermsConditionId ? TermsConditionId : null,
              TermId: TermId,
              Title: Title,
              TermsCategory: genrateTerms(Terms),
              Condition: genrateCondition(Conditions),
            },
          ],
        },
      ],
    };
    //PaymentStatusId we are sending it hardcoded as per vishnu reddy instrutions.
    let Data = {
      Json: JSON.stringify(data),
    };
    // if (!props.initialValues.IsContractCreated) {
    //   dispatch(smartContractUpsert(Data));
    // }

    dispatch(smartContractUpsert(Data));
  };

  const onContractFirstTabSubmit = values => {
    const { ContractName, StartDate, EndDate, Duration, Bundles } = values || {};
    const {
      totalBillingRate,
      totalCostOfCare,
      totalNetMarginAmount,
      totalNetMarginPercentage,
      totalWeightedOverheadPercentage,
    } = props.summaryData || {};

    const getBundleArray = Bundles => {
      return (
        Bundles &&
        Bundles.map((bundleItem, index) => {
          const {
            SmartContractServiceCodeBundleId,
            ClientInsuranceId,
            Duration,
            FrequencyId,
            Goal,
            ServiceCodeBundleId,
            ServiceType,
          } = bundleItem;
          return {
            SmartContractServiceCodeBundleId: SmartContractServiceCodeBundleId
              ? SmartContractServiceCodeBundleId
              : null,
            ServiceCodeBundleId: ServiceCodeBundleId ? ServiceCodeBundleId : null,
            Duration: Duration ? Duration : null,
            FrequencyId: FrequencyId ? FrequencyId : null,
            ClientInsuranceId: ClientInsuranceId ? ClientInsuranceId : null,
            ServiceType:
              ServiceType &&
              ServiceType.map(ServiceTypeItem => {
                const {
                  BuildServiceBundle,
                  CenterServiceTypeCenterId,
                  ProgramId,
                } = ServiceTypeItem;
                return {
                  CenterServiceTypeCenterId: ProgramId,
                  ServiceCode:
                    BuildServiceBundle &&
                    BuildServiceBundle.map(serviceCodeItem => {
                      const { NoOfServices } = serviceCodeItem;
                      return {
                        NoOfService: NoOfServices,
                        ...serviceCodeItem,
                      };
                    }),
                };
              }),
            Goal:
              Goal &&
              Goal.map(goalItem => {
                const {
                  SmartContractGoalId,
                  GoalLibraryId,
                  Milestone,
                  StartDate,
                  EndDate,
                } = goalItem;
                return {
                  SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
                  GoalLibraryId: GoalLibraryId ? GoalLibraryId : null,
                  StartDate: StartDate ? stringDate(StartDate) : null,
                  EndDate: EndDate ? stringDate(EndDate) : null,
                  Milestone:
                    Milestone &&
                    Milestone.map(MilestoneItem => {
                      const { SmartContractMeasureId, Task, StartDate, EndDate } = MilestoneItem;
                      return {
                        ...MilestoneItem,
                        SmartContractMeasureId: SmartContractMeasureId
                          ? SmartContractMeasureId
                          : null,
                        StartDate: StartDate ? stringDate(StartDate) : null,
                        EndDate: EndDate ? stringDate(EndDate) : null,
                        Task:
                          Task &&
                          Task.map(TaskItem => {
                            const { Task, SmartContractTaskId } = TaskItem;
                            return {
                              Task: Task,
                              SmartContractTaskId: SmartContractTaskId ? SmartContractTaskId : null,
                            };
                          }),
                      };
                    }),
                };
              }),
            BillingRate: getBundleSummaryArray[index].bundleBillingRate,
          };
        })
      );
    };

    let data = {
      SmartContract: [
        {
          SmartContractId: SMART_CONTRACT_ID,
          ClientId: CLIENT_ID,
          ContractName: ContractName,
          PaymentContractTypeId: '1',
          StartDate: stringDate(StartDate),
          EndDate: stringDate(EndDate),
          Bundle: Bundles ? getBundleArray(Bundles) : null,
          TotalOverHead: totalWeightedOverheadPercentage,
          TotalCostOfCare: totalCostOfCare,
          TotalNetMargin: totalNetMarginPercentage ? totalNetMarginPercentage.toFixed(2) : null,
          TotalBillingRate: totalBillingRate,
          TotalNetMarginAmount: totalNetMarginAmount,
          PaymentStatusId: 1,
          IsContractCreated: finalSubmit == 'Create' ? true : false,
        },
      ],
    };
    //PaymentStatusId we are sending it hardcoded as per vishnu reddy instrutions.
    let Data = {
      Json: JSON.stringify(data),
    };
    // if (!props.initialValues.IsContractCreated) {
    //   dispatch(smartContractUpsert(Data));
    // }
    dispatch(smartContractUpsert(Data));
  };

  const onContractSecondTabSubmit = values => {
    const { TermsCondition } = values || {};
    let temp =
      TermsCondition &&
      TermsCondition.map(item => {
        return {
          SmartContractId: SMART_CONTRACT_ID,
          ClientId: CLIENT_ID,
          ...item,
        };
      });

    let data = {
      TermsCondition: temp,
    };
    //PaymentStatusId we are sending it hardcoded as per vishnu reddy instrutions.
    let Data = {
      Json: JSON.stringify(data),
    };
    // if (!props.initialValues.IsContractCreated) {
    //   dispatch(smartContractUpsert(Data));
    // }
    dispatch(smartContractTermsConditionUpsert(Data));
  };

  useEffect(() => {
    const { SmartContractId, PaymentContractTypeId } = props.smartContractData || {};
    if (SmartContractId && PaymentContractTypeId == '1') {
      if (tabName && tabName.length < 8) {
        window.scrollTo(0, 300);
        setTabName([
          ...tabName,
          { tabLabel: 'Terms', keyIndex: 1, Content: 'TermsConditions' },
          { tabLabel: 'Ledger', keyIndex: 2, Content: 'Ledger' },
          { tabLabel: 'Payers', keyIndex: 3, Content: 'Payers' },
          { tabLabel: 'Authorizations', keyIndex: 4, Content: 'Authorizations' },
          { tabLabel: 'Requests', keyIndex: 5, Content: 'Requests' },
          { tabLabel: 'Documents', keyIndex: 6, Content: 'Documents' },
          { tabLabel: 'Care Team', keyIndex: 7, Content: 'AssignCareTeam' },
          { tabLabel: 'Distribution', keyIndex: 8, Content: 'Distribution' },
        ]);
      }
    } else if (PaymentContractTypeId == '2') {
      setContractType('2');
    }
  }, [props.smartContractData, contractType]);

  const toggle = (header, newContractId) => {
    setIsModelOpen(!isModelOpen);
    setHeader(header);
    setNewContractIdValue(newContractId);
  };

  useEffect(() => {
    if (props.isContractAddedSuccessfully) {
      if (props.typeOfContractAdded == 1) {
        toggle(
          props.isConCreated ? 'Contract added Successfully' : 'Contract saved Successfully',
          props.newContractId,
        );
      } else {
        toggle('Fee for service saved successfully', props.newContractId);
      }
      // if (finalSubmit != null) {
      //   setFinalSubmit(null);
      // }
    }
  }, [props.isContractAddedSuccessfully]);

  useEffect(() => {
    if (props.isContractEndedSuccessfully) {
      if (props.typeOfContractEnded == '1') {
        toggle(
          props.smartContractData.IsContractCreated
            ? 'Contract Ended Successfully'
            : 'Contract cancelled Successfully',
        );
      } else if (props.typeOfContractEnded == '2') {
        toggle('Fee for service ended successfully');
      } else {
        debugger;
        //  'Service Completed successfully';
        toggle(props.typeOfContractEnded || 'Operation completed successfully');
      }
    }
  }, [props.isContractEndedSuccessfully]);

  useEffect(() => {
    if (props.isTermsAndConditionAddedSuccessfully) {
      toggle('Terms and Condition saved successfully');
    }
  }, [props.isTermsAndConditionAddedSuccessfully]);

  const getLatestData = () => {
    let data = {
      SmartContract: [{ ClientId: CLIENT_ID, SmartContractId: SMART_CONTRACT_ID }],
    };

    var smartContractData = {
      json: JSON.stringify(data),
    };
    dispatch(smartContractGet(smartContractData));

    // let Data = {
    //   json: JSON.stringify({
    //     Ledger: [{ ClientId: CLIENT_ID, SmartContractId: SMART_CONTRACT_ID }],
    //   }),
    // };
    // dispatch(smartContractLedgerGet(Data));
  };

  const onChange = key => {
    console.log(key);
  };

  const handleDeleteContract = SmartContractId => {
    let data = {
      SmartContract: [
        {
          SmartContractId: SmartContractId,
        },
      ],
    };
    let Data = {
      Json: JSON.stringify(data),
    };
    dispatch(smartContractUpdate(Data));
  };

  // const setGoalArrayInitialData = goal => {
  //   return goal.map(goalItem => {
  //     const {
  //       Bundle,
  //       Description,
  //       GoalLibraryId,
  //       GoalTimePeriod,
  //       GoalType,
  //       Milestone,
  //       Note,
  //       SmartContractGoalId,
  //       ThresholdValue,
  //       TimePeriod,
  //       TimePeriodId,
  //       key,
  //     } = goalItem;
  //     return {
  //       SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
  //       GoalLibraryId: GoalLibraryId ? GoalLibraryId : null,
  //       Duration: Duration,
  //       Bundles:
  //         Bundle &&
  //         Bundle.map(bundleItem => {
  //           const {
  //             BillingRate,
  //             CategoriesCount,
  //             DaysInTreatment,
  //             EndDate,
  //             NetAmount,
  //             NetMargin,
  //             OverHeadPercentage,
  //             Program,
  //             ServiceCodeBundleId,
  //             ServiceCodeBundleName,
  //             Services,
  //             SmartContractId,
  //             SmartContractServiceCodeBundleId,
  //             StartDate,
  //             TotalCostOfCare,
  //             TotalOverHead,
  //             key,
  //             Duration,
  //           } = bundleItem;
  //           return {
  //             SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
  //             SmartContractServiceCodeBundleId: SmartContractServiceCodeBundleId
  //               ? SmartContractServiceCodeBundleId
  //               : null,
  //             ServiceCodeBundleId: ServiceCodeBundleId ? ServiceCodeBundleId : null,
  //             ServiceCodeBundleName: ServiceCodeBundleName ? ServiceCodeBundleName : null,
  //             DaysInTreatment: DaysInTreatment ? DaysInTreatment : null,
  //             ServiceType:
  //               Services &&
  //               Services.map(ServiceTypeItem => {
  //                 const { CenterServiceTypeCenterId, ServiceCode, Services } = ServiceTypeItem;
  //                 return {
  //                   ProgramId: CenterServiceTypeCenterId,
  //                   BuildServiceBundle:
  //                     ServiceCode &&
  //                     ServiceCode.map(serviceCodeItem => {
  //                       const {
  //                         CodeId,
  //                         ServiceCodeId,
  //                         ServiceCodeDescription,
  //                         NoOfService,
  //                         LaberCost,
  //                         ConsumableCost,
  //                         OverHeadCost,
  //                         StandardCost,
  //                         NetMargin,
  //                         BillingRate,
  //                         NetAmount,
  //                         OverHeadCostPercentage,
  //                         BundleServiceCodeId,
  //                         UnitOfMeasurement,
  //                         ServiceCodeConsumable,
  //                         ServiceCodeLaberCost,
  //                         PractitionerId,
  //                         ClientInsuranceId,
  //                         CodeName,
  //                         IsActionAllowed,
  //                         IsRequestPending,
  //                         IsServiceCompleted,
  //                         ServiceDelivedDate,
  //                         ServiceStatus,
  //                         SmartContractServiceBundleServiceCodeId,
  //                         StatusId,
  //                       } = serviceCodeItem;
  //                       return {
  //                         CodeId,
  //                         ServiceCodeId,
  //                         ServiceCodeDescription,
  //                         NoOfServices: NoOfService,
  //                         LaberCost,
  //                         ConsumableCost,
  //                         OverHeadCost,
  //                         StandardCost,
  //                         NetMargin,
  //                         BillingRate,
  //                         NetAmount,
  //                         OverHeadCostPercentage,
  //                         BundleServiceCodeId,
  //                         UnitOfMeasurement,
  //                         ServiceCodeConsumable,
  //                         ServiceCodeLaberCost,
  //                         PractitionerId,
  //                         ClientInsuranceId,
  //                         CodeName,
  //                         IsActionAllowed,
  //                         IsRequestPending,
  //                         IsServiceCompleted,
  //                         ServiceDelivedDate,
  //                         ServiceStatus,
  //                         SmartContractServiceBundleServiceCodeId,
  //                         StatusId,
  //                       };
  //                     }),
  //                 };
  //               }),
  //           };
  //         }),
  //       Milestone: Milestone,
  //     };
  //   });
  // };

  const setBundleArrayInitialData = Bundle => {
    return (
      Bundle &&
      Bundle.map(bundleItem => {
        const {
          BillingRate,
          CategoriesCount,
          DaysInTreatment,
          FrequencyId,
          EndDate,
          NetAmount,
          NetMargin,
          OverHeadPercentage,
          Program,
          ServiceCodeBundleId,
          ServiceCodeBundleName,
          ServiceType,
          SmartContractId,
          SmartContractServiceCodeBundleId,
          StartDate,
          TotalCostOfCare,
          TotalOverHead,
          key,
          Goal,
          ClientInsuranceId,
          Duration,
        } = bundleItem;

        return {
          //  SmartContractGoalId: SmartContractGoalId ? SmartContractGoalId : null,
          SmartContractServiceCodeBundleId: SmartContractServiceCodeBundleId
            ? SmartContractServiceCodeBundleId
            : null,
          ServiceCodeBundleId: ServiceCodeBundleId ? ServiceCodeBundleId : null,
          ServiceCodeBundleName: ServiceCodeBundleName ? ServiceCodeBundleName : null,
          Duration: Duration ? Duration : null,
          FrequencyId: FrequencyId ? FrequencyId : null,
          ClientInsuranceId: ClientInsuranceId ? ClientInsuranceId : null,
          ServiceType:
            ServiceType &&
            ServiceType.map(ServiceTypeItem => {
              const { CenterServiceTypeCenterId, ServiceCode, Services } = ServiceTypeItem;
              return {
                ProgramId: CenterServiceTypeCenterId,
                BuildServiceBundle:
                  ServiceCode &&
                  ServiceCode.map(serviceCodeItem => {
                    const {
                      CodeId,
                      ServiceCodeId,
                      ServiceCodeDescription,
                      NoOfService,
                      LaberCost,
                      ConsumableCost,
                      OverHeadCost,
                      StandardCost,
                      NetMargin,
                      BillingRate,
                      NetAmount,
                      OverHeadCostPercentage,
                      BundleServiceCodeId,
                      UnitOfMeasurement,
                      ServiceCodeConsumable,
                      ServiceCodeLaberCost,
                      PractitionerId,
                      ClientInsuranceId,
                      CodeName,
                      IsActionAllowed,
                      IsRequestPending,
                      IsServiceCompleted,
                      ServiceDelivedDate,
                      ServiceStatus,
                      SmartContractServiceBundleServiceCodeId,
                      SmartContractBundleServiceCodeId,
                      StatusId,
                    } = serviceCodeItem;
                    return {
                      CodeId,
                      ServiceCodeId,
                      ServiceCodeDescription,
                      NoOfServices: NoOfService,
                      LaberCost,
                      ConsumableCost,
                      OverHeadCost,
                      StandardCost,
                      NetMargin,
                      BillingRate,
                      NetAmount,
                      OverHeadCostPercentage,
                      BundleServiceCodeId,
                      UnitOfMeasurement,
                      ServiceCodeConsumable,
                      ServiceCodeLaberCost,
                      PractitionerId,
                      ClientInsuranceId,
                      CodeName,
                      IsActionAllowed,
                      IsRequestPending,
                      IsServiceCompleted,
                      ServiceDelivedDate,
                      ServiceStatus,
                      SmartContractServiceBundleServiceCodeId,
                      SmartContractBundleServiceCodeId,
                      StatusId,
                    };
                  }),
              };
            }),
          Goal: Goal,
        };
      })
    );
  };

  useEffect(() => {
    if (props.smartContractData && props.smartContractData.SmartContractId) {
      const {
        Bundle,
        ClientId,
        ContractName,
        EndDate,
        IsContractCreated,
        PaymentContractTypeId,
        PaymentStatusId,
        SmartContractId,
        StartDate,
        Status,
        StatusId,
        TotalBillingRate,
        TotalCostOfCare,
        TotalNetMargin,
        TotalNetMarginAmount,
        TotalOverHead,
        // TermsCondition,
      } = props.smartContractData;
      // const { Title, TermsCategory, Condition, TermsConditionId, TermId } =
      //   (TermsCondition && TermsCondition[0]) || {};

      let tempInitialValues = {
        SmartContractId,
        ClientId,
        PaymentContractTypeId,
        ContractName,
        StartDate,
        EndDate,
        TotalOverHead,
        TotalCostOfCare,
        TotalNetMargin,
        TotalBillingRate,
        TotalNetMarginAmount,
        PaymentStatusId,
        Bundles: Bundle ? setBundleArrayInitialData(Bundle) : null,
        //check this later,
        TermsCondition: [{}],
        // TermsConditionId,
        // TermId,
        // Title,
        // Terms: TermsCategory,
        // Conditions: Condition,
        // TermsConditionId,
      };
      setParentStateInitialValues(tempInitialValues);
      // setBundle(props.smartContractData.Bundle);
      setContractType(props.smartContractData.PaymentContractTypeId);
    }
  }, [props.smartContractData]);

  useEffect(() => {
    if (props.contractTermsAndConditionList) {
      setParentStateInitialValues({
        ...parentStateInitialValues,
        TermsCondition: props.contractTermsAndConditionList,
      });
    }
  }, [props.contractTermsAndConditionList]);
  const showPendingContractDrawer = () => {
    setPendingContract(true);
  };

  const onPendingContractClose = () => {
    setPendingContract(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Loader2 loading={props.isFetching || props.isFetchingSmartContractGet} />
      {!props.isFetching || !props.isFetchingSmartContractGet ? (
        <>
          {' '}
          <div className="root-smart-contract">
            {/* <PayerDetails /> */}
            <ContractHeader
              smartContractData={props.smartContractData}
              handleDeleteContract={handleDeleteContract}
              setContractType={setContractType}
              contractType={contractType}
              enableCreateButton={enableCreateButton}
              setFinalSubmit={setFinalSubmit}
              setEnableCreateButton={setEnableCreateButton}
              showPendingContractDrawer={showPendingContractDrawer}
              tabsCount={tabName.length}
              handleNext={handleNext}
              currentTab={activeKey}
              goToPreviuos={goToPreviuos}
              isUpsertFetching={props.isUpsertFetching}
              finalSubmit={finalSubmit}
              isFetchingSmartContractGet={props.isFetchingSmartContractGet}
              setFieldDisabled={setFieldDisabled}
              fieldDisabled={fieldDisabled}
              isNewContract={IS_NEW_CONTRACT}
              // {...props}
            />
            {props.clientInsuranceData && props.clientInsuranceData.ClientInsuranceId ? (
              <div className="container-fluid mt-2 px-0">
                {contractType == '1' && (
                  <Tabs
                    // defaultActiveKey="1"
                    centered
                    activeKey={String(activeKey)}
                    defaultActiveKey="0"
                    onTabClick={onTabClick}
                    forceRender={true}
                    destroyInactiveTabPane={true}
                  >
                    {tabName &&
                      tabName.map((item, index) => {
                        const { tabLabel, keyIndex, Content } = item;
                        const DynamicComponentConversion = components[Content];
                        const tabsCount = tabName.length;
                        return (
                          <Tabs.TabPane
                            tab={
                              keyIndex != 6 ? (
                                renderHTML(
                                  `<span class='tab-number'>${index + 1}</span> ${tabLabel}`,
                                )
                              ) : noOfReqDoc ? (
                                <>
                                  <span class="tab-number">{index + 1}</span> {tabLabel}
                                  <Tooltip
                                    placement="top"
                                    title="Number of Pending Document Request."
                                  >
                                    <span className="reqDocNum">{noOfReqDoc}</span>
                                  </Tooltip>
                                </>
                              ) : (
                                renderHTML(
                                  `<span class='tab-number'>${index + 1}</span> ${tabLabel}`,
                                )
                              )
                            }
                            key={keyIndex}
                          >
                            {
                              <DynamicComponentConversion
                                {...props}
                                clientId={CLIENT_ID}
                                smartContractId={SMART_CONTRACT_ID}
                                smartContractData={props.smartContractData}
                                handleDeleteContract={handleDeleteContract}
                                setContractType={setContractType}
                                contractType={contractType}
                                enableCreateButton={enableCreateButton}
                                finalSubmit={finalSubmit}
                                setFinalSubmit={setFinalSubmit}
                                setEnableCreateButton={setEnableCreateButton}
                                // onContractSubmit={onContractSubmit}
                                initialValues={parentStateInitialValues}
                                setBundle={setBundle}
                                // TermsValues={parentStateInitialValues && parentStateInitialValues.Terms}
                                clientInfo={props.clientInfo}
                                setIndexOfNetMarginChange={setIndexOfNetMarginChange}
                                setCurrentServiceTypeIndex={setCurrentServiceTypeIndex}
                                currentTab={activeKey}
                                tabsCount={tabsCount}
                                handleSubmitAndNext={handleSubmitAndNext}
                                onContractFirstTabSubmit={onContractFirstTabSubmit}
                                onContractSecondTabSubmit={onContractSecondTabSubmit}
                                isUpsertFetching={props.isUpsertFetching}
                                isTermsAndConditionUpsertFetching={
                                  props.isTermsAndConditionUpsertFetching
                                }
                                setGetBundleSummaryArray={setGetBundleSummaryArray}
                                getLatestData={getLatestData}
                                handleNext={handleNext}
                                goToPreviuos={goToPreviuos}
                                isFetchingSmartContractGet={props.isFetchingSmartContractGet}
                                disabled={fieldDisabled}
                              />
                            }
                          </Tabs.TabPane>
                        );
                      })}
                  </Tabs>
                )}
              </div>
            ) : (
              <>
                {contractType == '1' ? (
                  <div className="d-inline">
                    <div className="container mt-5 h5 d-inline-flex p-2">
                      {' '}
                      <div
                        style={{
                          borderRadius: '8px',
                          border: '1px solid #FF2C2C',
                          background: 'rgba(255, 44, 44, 0.05)',
                        }}
                      >
                        <p
                          style={{
                            color: '#FF2C2C',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            margin: '5px',
                            padding: '10px',
                          }}
                        >
                          <i class="fa fa-info-circle" aria-hidden="true"></i> Note: Value Based
                          Contract Creation Unavailable. Patient must have authorized insurance or
                          payer.
                        </p>
                      </div>{' '}
                      <button
                        className="btn btn-primary ml-2"
                        onClick={() => {
                          props.history.push(
                            '/cw/patient-management/patient-profile?tab=insurance-information&pId=' +
                              CLIENT_ID,
                          );
                        }}
                      >
                        Add Payer / Insurance
                      </button>
                    </div>
                  </div>
                ) : null}
              </>
            )}

            {isModelOpen && (
              <CustomModal
                isOpen={isModelOpen}
                header={header}
                yesAction={() => {
                  // dispatch(serviceCodeBundleGetAll({}));
                  toggle();
                  //below fnCall for submit contract btn latest data requirement.
                  getLatestData();
                  dispatch(resetSmartContractUpsert({}));
                  dispatch(resetSmartContractTermsConditionUpsert({}));
                  dispatch(resetSmartContractUpdate());
                  dispatch(resetSmartContractGet());
                  dispatch(reset('contractForm'));
                  // window.location.reload(false);
                  if (!props.isMarkAsCompleteAPI) {
                    if (SMART_CONTRACT_ID) {
                      handleNext();
                    } else {
                      props.history.push(
                        `/cw/patient-management/patient-profile?tab=smart-contract&smartContractId=${newContractIdValue}&pId=${CLIENT_ID}`,
                      );
                      let data = {
                        SmartContract: [
                          { ClientId: CLIENT_ID, SmartContractId: newContractIdValue },
                        ],
                      };

                      var smartContractData = {
                        json: JSON.stringify(data),
                      };
                      dispatch(smartContractGet(smartContractData));
                      handleNext();
                    }
                  } else {
                    getLatestData();
                  }

                  dispatch(
                    smartContractPatientListGet({
                      json: JSON.stringify({
                        SmartContract: [
                          {
                            ClientId: CLIENT_ID,
                          },
                        ],
                      }),
                    }),
                  );

                  // getLatestData();

                  // this.props.dispatch(reset('serviceCodeForm'));
                  // this.resetcodeIdandModifiers();
                  // this.setState({
                  //   ServiceCodeInfo: {},
                  // });
                  if (props.isContractEndedSuccessfully) {
                    props.history.push(
                      '/cw/patient-management/patient-profile?tab=smart-contract&pId=' + CLIENT_ID,
                    );
                  }
                }}
                yesText="OK"
                hideCancel={true}
              />
            )}
            <Drawer
              closeIcon={<CloseIcon />}
              width={800}
              title={
                <span className="contract-drawer-title">Pending Contract Change Requests</span>
              }
              placement="right"
              onClose={onPendingContractClose}
              open={pendingContract}
              maskClosable={false}
              closable={true}
              className="contract-drawer"
            >
              <PendingChangeRequest
                history={props.history}
                onClose={onPendingContractClose}
                getLatestData={getLatestData}
                setFieldDisabled={setFieldDisabled}
              />
            </Drawer>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    smart_contract_get,
    smart_contract_upsert,
    smart_contract_terms_condition_upsert,
    smart_contract_update,
    client_insurance_get,
    get_patient_detail,
    smart_contract_terms_condition_getallinfo_json,
    smart_contract_document_request_get,
  } = state;
  const { data: contractData,isFetching:isFetchingSmartContractGet } = smart_contract_get;
  const { data: ciData, isFetching } = client_insurance_get;
  const { data: clientInfo, isFetching: isFetchingClientInfo } = get_patient_detail;
  const { isFetching: isUpsertFetching } = smart_contract_upsert;
  const { isFetching: isTermsAndConditionUpsertFetching } = smart_contract_terms_condition_upsert;
  const { data: smartContractTAndC } = smart_contract_terms_condition_getallinfo_json;
  const { data: requestGetData } = smart_contract_document_request_get;

  let smartContractData = {};
  let typeOfContractAdded = null;
  let newContractId = null;
  let isConCreated = null;
  let isContractAddedSuccessfully = false;
  let isTermsAndConditionAddedSuccessfully = false;
  let typeOfContractEnded = null;
  let isContractEndedSuccessfully = false;
  let clientInsuranceData = null;
  let isMarkAsCompleteAPI = null;
  let contractTermsAndConditionList = null;
  let requestGetInfo = null;
  if (requestGetData && requestGetData.Request) {
      requestGetInfo = requestGetData && requestGetData.Request;
    }

  if (contractData && contractData.SmartContract) {
    smartContractData = contractData && contractData.SmartContract[0];
  }
  if (smart_contract_upsert) {
    isContractAddedSuccessfully = smart_contract_upsert.data && smart_contract_upsert.data.success;
    typeOfContractAdded =
      smart_contract_upsert.data &&
      smart_contract_upsert.data.result &&
      JSON.parse(smart_contract_upsert.data.result)[0] &&
      JSON.parse(smart_contract_upsert.data.result)[0].PaymentContractTypeId;
    isConCreated =
      smart_contract_upsert.data &&
      smart_contract_upsert.data.result &&
      JSON.parse(smart_contract_upsert.data.result)[0] &&
      JSON.parse(smart_contract_upsert.data.result)[0].IsContractCreated;
    newContractId =
      smart_contract_upsert.data &&
      smart_contract_upsert.data.result &&
      JSON.parse(smart_contract_upsert.data.result)[0] &&
      JSON.parse(smart_contract_upsert.data.result)[0].SmartContractId;
  }
  if (smart_contract_terms_condition_upsert) {
    isTermsAndConditionAddedSuccessfully =
      smart_contract_terms_condition_upsert.data &&
      smart_contract_terms_condition_upsert.data.success;
  }
  if (smart_contract_update) {
    isContractEndedSuccessfully = smart_contract_update.data && smart_contract_update.data.success;
    typeOfContractEnded = smart_contract_update.data && smart_contract_update.data.data;
    isMarkAsCompleteAPI = smart_contract_update.data && smart_contract_update.data.markAsComplete;
  }
  if (ciData && ciData.ClientInsurance) {
    // clientInsuranceData = ciData.ClientInsurance.filter(item => {
    //   return item.PriorityOrder == 1;
    // })[0];
    // as per reddy we are considering any insurance which is having ClientInsuranceId.
    clientInsuranceData = ciData.ClientInsurance.filter(item => {
      return item.ClientInsuranceId != null;
    })[0];
  }
  if (smartContractTAndC && smartContractTAndC.TermsCondition) {
    contractTermsAndConditionList = smartContractTAndC.TermsCondition;
  }
  return {
    smartContractData,
    isContractAddedSuccessfully,
    isContractEndedSuccessfully,
    typeOfContractAdded,
    isConCreated,
    typeOfContractEnded,
    clientInsuranceData,
    isFetching,
    clientInfo,
    isUpsertFetching,
    isTermsAndConditionUpsertFetching,
    isMarkAsCompleteAPI,
    contractTermsAndConditionList,
    isTermsAndConditionAddedSuccessfully,
    newContractId,
    isFetchingSmartContractGet,
    requestGetInfo,
  };
};

const SmartContractComponent = connect(mapStateToProps)(withRouter(SmartContract));

export default SmartContractComponent;
