import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Modal, Button, Menu, Input } from 'antd';
import ClientList from './ClientList';
import Intake from './intake';
import PreIntake, { ListLegacy } from './pre-intake';
import Queue from './Queue';
import PageTitle from '../../Components/PageTitle';

const { SubMenu } = Menu;

class EnterpriseClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // current: 'pre-intake',
      current:
        (this.props.location.pathname == '/clients' && 'pre-intake') ||
        this.props.location.pathname.replace('/clients/', '') ||
        'pre-intake',
    };
  }
  handleMenuClick = e => {
    this.props.history.push(`/clients/${e.key}`);
  };

  render() {
    const { current } = this.state;
    const { enableNewProspectFlow, isProspectEnabled } = this.props;
    return (
      <>
        <PageTitle TitleText="Student" />
        <div class="student-list-menu d-none">
          <div
            className="row mx-0 justify-content-center"
            style={{ background: '#fff', height: '50px' }}
          >
            <div className="col-4">
              <Menu
                onClick={this.handleMenuClick}
                selectedKeys={[current]}
                mode="horizontal"
                className="dashboard-menu d-flex align-item-center justify-content-center"
              >
                {/* {IsInHouse && <Menu.Item key="inHouse">Intake</Menu.Item>} */}
                {isProspectEnabled &&
                this.props.preIntakeStatusData &&
                this.props.preIntakeStatusData.LicenseStatusId &&
                this.props.preIntakeStatusData.LicenseStatusId != 8 ? (
                  <>
                    {enableNewProspectFlow ? (
                      <>
                        <Menu.Item key="pre-intake">Prospect</Menu.Item>
                        {this.props.preIntakeStatusData.LicenseStatusId == 2 ||
                        this.props.preIntakeStatusData.LicenseStatusId == 7 ||
                        this.props.preIntakeStatusData.LicenseStatusId == 4 ? (
                          <Menu.Item key="queue">Queue</Menu.Item>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <Menu.Item key="pre-intake-legacy">Prospect</Menu.Item>
                      </>
                    )}
                    <Menu.Item key="intake">Intake</Menu.Item>
                    <Menu.Item key="list">Enrolled</Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item key="intake">Intake</Menu.Item>
                    <Menu.Item key="list">Enrolled</Menu.Item>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
        {current === 'pre-intake-legacy' && <ListLegacy />}
        {current === 'pre-intake' && <PreIntake />}
        {current === 'queue' && <Queue />}
        {current === 'intake' && <Intake />}
        {current === 'list' && <ClientList />}
      </>
    );
  }
}

const mapStateToProps = ({ user_feature_flag_get }) => {
  const { data: preIntakeStatus } = user_feature_flag_get;
  let preIntakeStatusData = null;
  let enableNewProspectFlow = null;
  let isProspectEnabled = null;

  if (
    preIntakeStatus &&
    preIntakeStatus.FeatureFlag &&
    preIntakeStatus.FeatureFlag.length > 0 &&
    preIntakeStatus.FeatureFlag[0]
  ) {
    preIntakeStatusData =
      preIntakeStatus.FeatureFlag &&
      preIntakeStatus.FeatureFlag[0] &&
      preIntakeStatus.FeatureFlag[0].License &&
      preIntakeStatus.FeatureFlag[0].License.length > 0 &&
      preIntakeStatus.FeatureFlag[0].License.filter(item => item.ModuleName == 'PreIntake')[0];
    enableNewProspectFlow = preIntakeStatus.FeatureFlag[0].EnableNewProspectFlow;
    isProspectEnabled = preIntakeStatus.FeatureFlag[0].IsProspectEnabled;
  }

  return {
    preIntakeStatusData,
    enableNewProspectFlow,
    isProspectEnabled,
  };
};

export default connect(mapStateToProps)(EnterpriseClient);
