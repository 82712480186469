import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { getAppSubscribersProfile } from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';

const UserInfoComponent = props => {
  const {
    Age,
    Badge,
    Name,
    // Gender,
    UserProfileImageUrl,
    Phone,
    Service,
    ServiceTags,
    SubscribeStatus,
    NotificationAuthorizationStatus,
    TimeZone,
    Language,
  } = props.appSubscriberData || {};
  return (
    <>
      <div className="card card-custom w-100">
        <div className="card-body p-2 pb-3">
          <div className="user-basic-profile row">
            <div className="col-12">
              <div className="user-avatar">
                <div
                // className={
                //   'pic-circle-arrow-down'
                //   // TrendLevel == 0
                //   //   ? "pic-circle-neutral"
                //   //   : TrendLevel == 1
                //   //   ? "pic-circle-arrow-up"
                //   //   : "pic-circle-arrow-down"
                // }
                >
                  <div class="student-list-pic" style={{ flex: '0 0 29%' }}>
                    <img
                      src={UserProfileImageUrl || `images/familyProgram/blank-profile.png`}
                      // alt="student"
                      class="img-circle"
                      height="75"
                      width="75"
                      style={{
                        borderRadius: '50%',
                        padding: '2px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="user-basic-detail">
                <div className="basic-detail">
                  <div>
                    <span className="name">{Name}</span>
                  </div>
                  <div>
                    <span className="badge">
                      <img src={`images/familyProgram/badge.svg`} className="mr-2" />
                    </span>
                    <span className="">{Badge}</span>
                  </div>
                  <div className="d-flex mb-3 justify-content-center">
                    <div className="mr-2">
                      {/* <span className="gender">{Gender},</span> */}
                      <span className="age">{Age} Years</span>
                    </div>
                    <div>
                      <span className="phone">{Phone}</span>
                    </div>
                  </div>
                </div>

                <div className="user-notification">
                  <span className="settings">
                    <img src={`images/familyProgram/enabled.svg`} className="mr-2" />
                    {NotificationAuthorizationStatus}
                  </span>
                  <span className="settings">
                    <img src={`images/familyProgram/est.svg`} className="mr-2" />
                    {TimeZone}
                  </span>
                  <span className="settings">
                    <img src={`images/familyProgram/en.svg`} className="mr-2" />
                    {Language}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserStoryCompnent = props => {
  return (
    <>
      {' '}
      <div className="card card-custom mt-3">
        <div className="card-body p-3">
          <h1 className="mb-1" style={{ fontSize: '16px' }}>
            Story
          </h1>
          <p
            style={{
              fontSize: '14px',
              // textAlign: "justify"
            }}
          >
            {props.YourStory || ''}
          </p>
        </div>
      </div>
    </>
  );
};

const UserStatesCompnent = props => {
  const { DeviceOSVersion, DeviceType, AppVersion } = props.appSubscriberData || {};
  const data = [
    {
      label: 'Device Type',
      value: DeviceType,
    },
    {
      label: 'Device OS Version',
      value: DeviceOSVersion,
    },
    {
      label: 'App Version',
      value: AppVersion,
    },
  ];
  return (
    <>
      <div className="card card-custom mt-3">
        <div className="card-body p-3">
          <h1 className="mb-1" style={{ fontSize: '16px' }}>
            Stats
          </h1>
          <div className="row profile-section-Stats">
            {data &&
              data.map(item => {
                const { label, value } = item;
                return (
                  <div className="col-lg-12 col-xl-6 col-xxl-4" key={uuid()}>
                    <div className="profile-section-label">{label}</div>
                    {value && <div className="profile-section-label-value">{value}</div>}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

class ProfileSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(getAppSubscribersProfile({ UserGuid: this.props.getSubscriberUserGUID() }));
  }

  render() {
    const { appSubscriberData } = this.props;
    return (
      <>
        <div>
          <div className="subscriber-profile-section">
            <Loader2 loading={this.props.isFetching} />
            {appSubscriberData && (
              <div>
                {appSubscriberData && <UserInfoComponent appSubscriberData={appSubscriberData} />}
                {appSubscriberData.YourStory && (
                  <UserStoryCompnent YourStory={appSubscriberData.YourStory} />
                )}
                {appSubscriberData && <UserStatesCompnent appSubscriberData={appSubscriberData} />}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ProfileSection;
