import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tag, Modal, Tooltip, Avatar } from 'antd';
// import Loader, { Loader2 } from '../../Components/Loader';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import CustomModal from '../../../../../core/modal';
import AntdModal from '../../../../../Components/CustomModal';
import { getDate, stringDate } from '../../../../../util/date';
import PersistTableSort from '../../../../../component/persistTableSort';
import Loader, { Loader2 } from '../../../../../Components/Loader';

import {
  drugSearch,
  clientMedicationUpsert,
  clientMedicationGetAll,
  clientMedicationDelete,
  drugPackageGetAll,
  resetClientMedicationUpsert,
  resetClientMedicationDelete,
  drugDetailSearch,
  clientBannerGet,
  listCombos,
} from '../../../../../store/actions';
import ReduxMedicationFormForm from './medicationFormModal';
var timer;

let isDrugColumns;
class ManageMedication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelOpen: false,
      viewModalType: 'update',
    };
  }
  columns = [
    {
      title: 'Drug',
      dataIndex: 'Drug',
      key: 'Drug',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Drug, b.Drug),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => text && <span style={{ width: '200px' }}>{text}</span>,
    },
    {
      title: 'Dosage',
      dataIndex: 'Dosage',
      key: 'Dosage',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Dosage, b.Dosage),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Generic Name',
      dataIndex: 'GenericName',
      key: 'GenericName',
      isPrint: true,
      sorter: (a, b) => isComparer(a.GenericName, b.GenericName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Form',
      dataIndex: 'Form',
      key: 'Form',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Form, b.Form),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Strength',
      dataIndex: 'Strength',
      key: 'Strength',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Strength, b.Strength),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Action',
      key: 'x',
      render: (text, record, index) => {
        if (!this.props.disable) {
          return (
            <>
              <div class="tools-grid">
                <div className="mr-4">
                  {/* <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="start-btn"
                  onClick={onClick}
                  data-toggle="modal"
                  data-target="#updateInsurancePriority"
                >
                  <Tooltip placement="top" title="Edit">
                    <img src="images/shape.svg" width="14px" style={{ cursor: 'pointer' }} />
                  </Tooltip>
                </span>
              )}
              ModalContent={props => (
                <ReduxAllergenFormForm
                  {...props}
                  onSaveClick={this.onSubmit}
                  // initialValues={this.state.record}
                  recordValues={record}
                  viewModalType="update"
                  AllergyReaction={props.AllergyReaction}
                  AllergySeverity={props.AllergySeverity}
                />
              )}
            /> */}
                </div>
                <div className="">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-eligibility"
                      >
                        <Tooltip placement="top" title="Delete">
                          <p>
                            <i class="far fa-trash-alt"></i>
                          </p>
                        </Tooltip>
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to delete this Medication?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                clientMedicationDelete({
                                  json: JSON.stringify({
                                    ClientMedication: [
                                      {
                                        ClientMedicationId: record.ClientMedicationId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </>
          );
        } else {
          return null;
        }
      },
    },
  ];

  drugColumns = [
    {
      title: 'Drug',
      dataIndex: 'Drug',
      key: 'Drug',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Drug, b.Drug),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Quantity, b.Quantity),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Refills',
      dataIndex: 'Refills',
      key: 'Refills',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Refills, b.Refills),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Strength',
      dataIndex: 'Strength',
      key: 'Strength',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Strength, b.Strength),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Form',
      dataIndex: 'Form',
      key: 'Form',
      isPrint: true,
      sorter: (a, b) => isComparer(a.Form, b.Form),
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Patient Notes',
    //   dataIndex: 'PatientNotes',
    //   key: 'PatientNotes',
    //   isPrint: true,
    //   sorter: (a, b) => isComparer(a.PatientNotes, b.PatientNotes),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Action',
      key: 'x',
      render: (text, record, index) => {
        if (!this.props.disable) {
          return (
            <>
              <div class="tools-grid">
                <div className="mr-4">
                  {/* <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="start-btn"
                  onClick={onClick}
                  data-toggle="modal"
                  data-target="#updateInsurancePriority"
                >
                  <Tooltip placement="top" title="Edit">
                    <img src="images/shape.svg" width="14px" style={{ cursor: 'pointer' }} />
                  </Tooltip>
                </span>
              )}
              ModalContent={props => (
                <ReduxAllergenFormForm
                  {...props}
                  onSaveClick={this.onSubmit}
                  // initialValues={this.state.record}
                  recordValues={record}
                  viewModalType="update"
                  AllergyReaction={props.AllergyReaction}
                  AllergySeverity={props.AllergySeverity}
                />
              )}
            /> */}
                </div>
                <div className="">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-eligibility"
                      >
                        <Tooltip placement="top" title="Delete">
                          <p>
                            <i class="far fa-trash-alt"></i>
                          </p>
                        </Tooltip>
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to delete this Medication?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                clientMedicationDelete({
                                  json: JSON.stringify({
                                    ClientMedication: [
                                      {
                                        ClientMedicationId: record.ClientMedicationId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </>
          );
        } else {
          return null;
        }
      },
    },
  ];
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'EPrescribeForm',
      }),
    );
    this.props.dispatch(
      clientMedicationGetAll({
        Json: JSON.stringify({
          ClientMedication: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ IsInserted, IsDeleted, IsUpdated, MSG }) {
    if (IsInserted && IsInserted != this.props.IsInserted) {
      this.props.dispatch(resetClientMedicationUpsert());
      this.toggle(MSG);
    }
    if (IsDeleted && IsDeleted != this.props.IsDeleted) {
      this.props.dispatch(resetClientMedicationDelete());
      this.toggle(MSG);
    }
  }

  // if() { }
  tableColumns = isEPrescribe => {
    if (isEPrescribe) {
      return (
        <PersistTableSort
          name="MedicationList"
          columns={this.drugColumns}
          dataSource={this.props.medicationList}
          size={'middle'}
          pagination={false}
        />
      );
    } else if (isEPrescribe == false) {
      return (
        <PersistTableSort
          name="MedicationList"
          columns={this.columns}
          dataSource={this.props.medicationList}
          size={'middle'}
          pagination={false}
        />
      );
    }
  };
  onSubmit = ({
    DoseId,
    drugValue,
    doseValue,
    drugText,
    isUseText,
    formName,
    genericName,
    strength,
    OnStartDate,
    OnEndDate,
  }) => {
    this.props.dispatch(
      clientMedicationUpsert({
        Json: JSON.stringify({
          ClientMedication: [
            {
              ClientId: this.props.clientId,
              ProductNDC: drugValue,
              NDCPackageCode: DoseId,
              FreeTextMedicationName: isUseText ? drugText : null,
              FreeTextMedicationDose: isUseText ? doseValue : null,
              StartDate: stringDate(OnStartDate),
              EndDate: stringDate(OnEndDate),
              GenericName: genericName,
              Strength: strength,
              Form: formName,
            },
          ],
        }),
      }),
    );
  };
  onDrugSearchSubmit = ({
    DoseId,
    drugValue,
    doseValue,
    drugText,
    isUseText,
    medicationArray,
    refillText,
    quantityText,
    PatientNotes,
    formValue,
    OnStartDate,
    OnEndDate,
  }) => {
    this.props.dispatch(
      clientMedicationUpsert({
        Json: JSON.stringify({
          ClientMedication: [
            {
              ClientId: this.props.clientId,
              NDCPackageCode: drugValue,
              // DoseId,
              // drugValue,
              // doseValue,
              // drugText,
              // isUseText,
              Strength: medicationArray.Strength,
              RouteCode: medicationArray.RouteCode,
              RcopiaID: medicationArray.RcopiaID,
              // NDCID:      NDCID,
              GenericName: medicationArray.GenericName,
              Form: formValue,
              BrandName: medicationArray.BrandName,
              FreeTextMedicationName: isUseText ? drugText : null,
              Quantity: quantityText,
              Refills: refillText,
              PatientNotes: PatientNotes,
              StartDate: stringDate(OnStartDate),
              EndDate: stringDate(OnEndDate),
            },
          ],
        }),
      }),
    );
  };
  handleDrugChange = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.props.dispatch(
        drugSearch({
          json: JSON.stringify({
            Drug: [
              {
                ClientId: this.props.ClientId,
                SearchStr: value,
              },
            ],
          }),
        }),
      );
    }, 500);
  };
  handleDrugDetailSearch = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.props.dispatch(
        drugDetailSearch({
          ClientId: this.props.ClientId,
          SearchStr: value,
        }),
      );
    }, 500);
  };

  handleDoseSearch = (value, text, genericName, brandName, strength, formValue) => {
    this.props.dispatch(
      drugPackageGetAll({
        json: JSON.stringify({
          Drug: [
            {
              ClientId: this.props.clientId,
              ProductNDC: value,
              searched: { text, value, brandName, genericName, strength, formValue },
            },
          ],
        }),
      }),
    );
  };
  handleNonEDoseSearch = (value, text, genericName, formName, strength) => {
    this.props.dispatch(
      drugPackageGetAll({
        json: JSON.stringify({
          Drug: [
            {
              ClientId: this.props.clientId,
              ProductNDC: value,
              searched: { text, value, genericName, formName, strength },
            },
          ],
        }),
      }),
    );
  };
  render() {
    const {
      isFetching,
      medicationList,
      disable,
      isEPrescribe,
      formOptions,
      isUpsertFetching,
    } = this.props;
    return (
      <div>
        {!disable && (
          <AntdModal
            modalWidth={800}
            ModalButton={({ onClick }) => (
              <span
                class="delete-btn"
                onClick={onClick}
                data-toggle="modal"
                data-target="#addMedication"
              >
                <span class="add-contact">
                  <button
                    type="button"
                    style={{
                      border: 'none',
                      outline: 'none',
                      backgroundColor: 'transparent',
                      fontSize: 'inherit',
                      fontWeight: 'inherit',
                      textTransform: 'uppercase',
                    }}
                    disabled={isFetching}
                  >
                    + Add Medication
                  </button>
                </span>
              </span>
            )}
            ModalContent={props => (
              <ReduxMedicationFormForm
                {...props}
                onSaveClick={this.onSubmit}
                ClientId={this.props.clientId}
                viewModalType="new"
                handleDrugChange={this.handleDrugChange}
                handleDrugDetailSearch={this.handleDrugDetailSearch}
                handleDoseSearch={this.handleDoseSearch}
                handleNonEDoseSearch={this.handleNonEDoseSearch}
                isEPrescribe={this.props.isEPrescribe}
                onDrugSave={this.onDrugSearchSubmit}
                formOptions={formOptions}
                isUpsertFetching={isUpsertFetching}
              />
            )}
          />
        )}

        {true ? (
          <div className="row align-items-center">
            <div class="col-12 pb-1">
              <div style={{ maxHeight: '670px', overflowY: 'scroll' }} className="time-line-table">
                {/* <PersistTableSort
                  name="MedicationList"
                  columns={isEPrescribe && isEPrescribe ? this.drugColumns : this.columns}
                  dataSource={medicationList}
                  size={'middle'}
                  pagination={false}
                /> */}
                {this.tableColumns(isEPrescribe)}
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex align-items-center justify-content-center"
              style={{ height: '276px' }}
            >
              <h5 className="text-center">No data</h5>
            </div>
          </div>
        )}
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.props.dispatch(
                clientMedicationGetAll({
                  Json: JSON.stringify({
                    ClientMedication: [
                      {
                        ClientId: this.props.clientId,
                      },
                    ],
                  }),
                }),
              );
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({
  client_medication_upsert,
  client_medication_get_all,
  client_medication_delete,
  combos,
}) => {
  const { data: medicationData, isFetching } = client_medication_get_all;
  const { isFetching: isUpsertFetching } = client_medication_upsert;
  let IsInserted = false;
  let IsDeleted = false;
  let medicationList;
  let MSG = null;
  let formOptions = [];

  if (medicationData && medicationData.ClientMedication) {
    medicationList = medicationData.ClientMedication;
  }

  if (client_medication_upsert.data && client_medication_upsert.data.success) {
    IsInserted = true;
    MSG = client_medication_upsert.data.result;
  }

  if (client_medication_delete.data && client_medication_delete.data.success) {
    IsDeleted = true;
    MSG = client_medication_delete.data.result;
  }

  if (combos.data && combos.data.EPrescribeForm) {
    formOptions = combos.data.EPrescribeForm;
  }

  return {
    IsInserted,
    IsDeleted,
    medicationList,
    MSG,
    formOptions,
    isFetching,
    isUpsertFetching,
  };
};

export default connect(mapStateToProps)(ManageMedication);
