import React from 'react';
import { useEffect } from 'react';
import Login from '../Screens/login';
import { Route, Switch, Redirect } from 'react-router-dom';
import MasterPage from '../component/master';
import MasterPage2 from '../component/master2';
import MasterPage3 from '../component/master3';
import MasterPage4 from '../component/master4';
import MasterPage5 from '../component/master5';
import MasterPage6 from '../component/master6';
import ForgotPass from './../Screens/forgot_password';
import AuthenticatebyNPI from './../Screens/authenticatebyNPI';
import ResetPass from './../Screens/reset_password';
import checkAuthorization from '../util/check-auth';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import SubscriberMapPage from './../Screens/subscriberMapPage';
import DataAnalytics from './../Screens/dataAnalytics';
import Rcm from '../Screens/RCM';
import * as AllScreens from '../Screens/';
import TestComponent from '../Screens/testComponent';
import RCMReports from './../Screens/RCM/reports/index';
import PrintScreening from './../Screens/RCM/patientEnrollmentForm/formSteps/printScreening';
import PrintAssessment from './../Screens/RCM/patientEnrollmentForm/formSteps/printAssessmentTool';
import ExternalRolesPermissions from './../Screens/RCM/patientEnrollmentForm/formSteps/manageCareTeam/external/rolesPermissions';
import SmallGroupMeetingList from './../Screens/smGroupMeetingList';
import GridData from './../Screens/gridData';
import RegisterClientPin from './../Screens/registerClientPin';
import NewTemplateForm from './../Screens/newTemplete';
import TermsAndConditions from './../Screens/loginFooterLinks/tnc';
import PrivacyPolicy from './../Screens/loginFooterLinks/pp';
import RefundPolicy from './../Screens/loginFooterLinks/rp';
import Disclaimer from './../Screens/loginFooterLinks/disc';
import AcceptableUsePolicy from './../Screens/loginFooterLinks/aup';
import CookiePolicy from './../Screens/loginFooterLinks/cp';
import { useDispatch } from 'react-redux';
import { logError } from '../store/actions';

// import Staff from '../Screens/staff';
// import Staff_Form from '../Screens/staffform/index';
// import StaffOld from '../Screens/staff_old'; //will remove once new onces done
// import Staff_Form_Old from '../Screens/staffform'; //will remove once new onces done

const IGNORE_AUTHENTICATION = true;

let Page = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      logError({
        warning: 'This page is not accessible for the user',
        userName: window.localStorage.getItem('userName') || '',
        url: window.location.href,
      }),
    );
  }, []);
  return (
    <div className="m-5">
      <div className="row">
        <div
          className="col-md-4"
          style={{
            color: '#46b4fe',
            fontSize: '26px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          This page may not exist or you may not be authorized to view it.
        </div>
        <div className="col-md-8 text-right">
          <img src="images/login-bg.png" width="400" alt="" />
        </div>
        <button
          className="btn"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Back to Previous
        </button>
      </div>
    </div>
  );
};

let UnAuthPage = withRouter(Page);

// let Page404 = props => {
//   return (
//     <div className="m-5">
//       <div className="row">
//         <div
//           className="col-md-4"
//           style={{
//             color: '#46b4fe',
//             fontSize: '56px',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             textAlign: 'center',
//             flexDirection: 'column',
//           }}
//         >
//           <div className="row">404</div>
//           <div className="row"> Page not found</div>
//           <div className="row">
//             <button
//               className="btn"
//               onClick={() => {
//                 props.history.push('/');
//               }}
//             >
//               Back to Login
//             </button>
//           </div>
//         </div>
//         {/* <p>
//           Uh oh, we can’t seem to find the page you’re looking for. Try going back to the previous
//           page or try login again.
//         </p> */}
//         <div className="col-md-8 text-right">
//           <img src="images/login-bg.png" width="400" alt="" />
//         </div>
//       </div>
//     </div>
//   );
// };

// let PageNotFound = withRouter(Page404);

const MasterRoute = ({ component: DComponent, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <MasterPage {...props}>
        <DComponent {...props} />
      </MasterPage>
    )}
  />
);

const MasterPageAuthenticatedRoute = ({
  component: DynamicComponent,
  logOutBroadcaster,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (props && props.location && props.location.pathname.split('/')[1] == 'rcm') {
        return <Redirect to="/cw" />;
      }

      //Remove above code once /rcm is not being used so common.implimented on april21.

      if (IGNORE_AUTHENTICATION || checkAuthorization()) {
        return (
          <MasterPage2 {...props} logOutBroadcaster={logOutBroadcaster} key={props.key}>
            <DynamicComponent {...props} key={props.key} />
          </MasterPage2>
        );
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

const MasterPageAuthenticatedRoute2 = ({
  component: DynamicComponent,
  logOutBroadcaster,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (IGNORE_AUTHENTICATION || checkAuthorization()) {
        return (
          <MasterPage3 {...props} logOutBroadcaster={logOutBroadcaster}>
            <DynamicComponent {...props} />
          </MasterPage3>
        );
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

const MasterPageAuthenticatedRoute4 = ({
  component: DynamicComponent,
  logOutBroadcaster,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (IGNORE_AUTHENTICATION || checkAuthorization()) {
        return (
          <MasterPage4 {...props} key={props.key} logOutBroadcaster={logOutBroadcaster}>
            <DynamicComponent {...props} key={props.key} />
          </MasterPage4>
        );
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

const MasterPageAuthenticatedRoutePayerPortal = ({
  component: DynamicComponent,
  logOutBroadcaster,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (IGNORE_AUTHENTICATION || checkAuthorization()) {
        return (
          <MasterPage5 {...props} key={props.key}>
            <DynamicComponent {...props} key={props.key} logOutBroadcaster={logOutBroadcaster} />
          </MasterPage5>
        );
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);
const MasterPageAuthenticatedRouteExternalMember = ({
  component: DynamicComponent,
  logOutBroadcaster,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (IGNORE_AUTHENTICATION || checkAuthorization()) {
        return (
          <MasterPage6 {...props} key={props.key}>
            <DynamicComponent {...props} key={props.key} logOutBroadcaster={logOutBroadcaster} />
          </MasterPage6>
        );
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);
const AuthenticatedRoute = ({ component: DynamicComponent, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (IGNORE_AUTHENTICATION || checkAuthorization()) {
        return <DynamicComponent {...props} />;
      } else {
        return <Redirect to="/" />;
      }
    }}
  />
);

const getDynamicRoutes2 = (data, logOutBroadcaster) => {
  // let menus = data;
  let menus = data ? JSON.parse(data).AuthorizedPathList : [];
  var routes = [];
  menus &&
    menus.map(item => {
      const { Path, Component, PathId, Exact } = item;
      var Screen = AllScreens[Component.replace(/['"]+/g, '')];
      if (item.MasterPage == 'MasterPageAuthenticatedRoute') {
        routes.push(
          <MasterPageAuthenticatedRoute
            path={Path}
            component={Screen}
            key={PathId}
            exact={Exact}
            logOutBroadcaster={logOutBroadcaster}
          />,
        );
      } else if (item.MasterPage == 'MasterPageAuthenticatedRoute2') {
        routes.push(
          <MasterPageAuthenticatedRoute2
            path={Path}
            component={Screen}
            key={PathId}
            exact={Exact}
            logOutBroadcaster={logOutBroadcaster}
          />,
        );
      } else if (item.MasterPage == 'MasterPageAuthenticatedRoute4') {
        routes.push(
          <MasterPageAuthenticatedRoute4
            path={Path}
            component={Screen}
            key={PathId}
            exact={Exact}
            logOutBroadcaster={logOutBroadcaster}
          />,
        );
      } else if (item.MasterPage == 'MasterPageAuthenticatedRoutePayerPortal') {
        routes.push(
          <MasterPageAuthenticatedRoutePayerPortal
            path={Path}
            component={Screen}
            key={PathId}
            exact={Exact}
            logOutBroadcaster={logOutBroadcaster}
          />,
        );
      } else if (item.MasterPage == 'MasterPageAuthenticatedRouteExternalMember') {
        routes.push(
          <MasterPageAuthenticatedRouteExternalMember
            path={Path}
            component={Screen}
            key={PathId}
            exact={Exact}
            logOutBroadcaster={logOutBroadcaster}
          />,
        );
      } else {
        routes.push(
          <AuthenticatedRoute
            path={Path}
            component={Screen}
            key={PathId}
            exact={Exact}
            logOutBroadcaster={logOutBroadcaster}
          />,
        );
      }
    });
  return routes;
};

class MenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
    };
    // var menu = localStorage.getItem('dr:menu');
    // if (menu !== undefined) {
    //   this.state.menus = JSON.parse(menu);
    // }
  }

  loadMenus = () => {
    const me = this;
    var token = localStorage.getItem('dr:token');
    if (token) {
      clearInterval(this.intervalId);
      var menu = localStorage.getItem('dr:menu');
      if (menu !== undefined) {
        me.setState({ menus: JSON.parse(menu) });
      } else {
        /*let val = {
          Path: '/appSubscribers',
          Action: '',
          IconCls: '',
          Text: 'Subscribers',
          Key: 'subscribers',
          hideMenu: true,
        };
        // me.setState({ isFetching: true });
        Axios.post('user/ListMenu', {}).then(response => {
          const menus = [...response.data.data.splice(0, 1), val, ...response.data.data];
          localStorage.setItem('userName', response.data.UserName);
          localStorage.setItem('userRole', response.data.UserRole);
          localStorage.setItem('centerName', response.data.CenterName);
          localStorage.setItem('dr:menu', JSON.stringify(menus));
          localStorage.setItem('profileImgUrl', response.data.ProfileImgUrl);
          localStorage.setItem('email', response.data.Email);
          localStorage.setItem('adminUserInfo', JSON.stringify(response.data.AdminUserInfo));

          me.setState({ menus: menus });
        });*/
      }
    }
  };
  refreshPage = () => {
    window.location.reload(true);
    // console.log('Refreshed at', new Date());
  };
  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }
  componentDidMount() {
    if (this.state.menus && this.state.menus.length == 0) {
      this.intervalId = setInterval(this.loadMenus, 500);
    }
    this.refreshInterval = setInterval(this.refreshPage.bind(this), 36000000);

    /*this.setState({
      menus: [
        { path: '/students', action: '', iconCls: '', text: 'Students', key: 'students' },
        { path: '/surveyer', action: '', iconCls: '', text: 'Surveys', key: 'surveys' },
        { path: '/report', action: '', iconCls: '', text: 'Reports', key: 'reports' },
        { path: '/manage', action: '', iconCls: '', text: 'Admin', key: 'admin' },
        { path: '/help', action: '', iconCls: 'fa fa-question-circle-o', text: '', key: 'help' },
        { path: '/settings', action: '', iconCls: 'fa fa-cog', text: '', key: 'settings' },
        { path: '', action: 'onLogOut', iconCls: 'fa fa-power-off', text: '', key: 'logout' },
      ],
    });*/
  }
  render() {
    const { menus, isFetching } = this.state;
    return (
      <Switch>
        <Route exact path="/subscriber-map-page" component={SubscriberMapPage} />
        <Route exact path="/dataAnalytics" component={DataAnalytics} key={91} />
        <Route exact path="/" component={Login} key={96} />
        <Route exact path="/login" component={Login} key={97} />
        <Route exact path="/forgotpassword" component={ForgotPass} key={98} />
        <Route exact path="/authenticate-by-NPI" component={AuthenticatebyNPI} key={89} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditions} key={301} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} key={302} />
        <Route exact path="/refund-policy" component={RefundPolicy} key={303} />
        <Route exact path="/disclaimer" component={Disclaimer} key={304} />
        <Route exact path="/acceptable-use-policy" component={AcceptableUsePolicy} key={305} />
        <Route exact path="/cookie-policy" component={CookiePolicy} key={306} />
        <Route exact path="/resetpassword/:id" component={ResetPass} key={99} />
        {getDynamicRoutes2(localStorage.getItem('authRoute'), this.props.logOutBroadcaster)}
        <AuthenticatedRoute path="/printScreening" component={PrintScreening} key={130} />
        <AuthenticatedRoute path="/printAssessment" component={PrintAssessment} key={101} />
        <MasterPageAuthenticatedRoute
          path="/viewtemplate"
          component={withRouter(props => (
            <NewTemplateForm {...props} isView={true} />
          ))}
          key={5}
        />
        <MasterPageAuthenticatedRoute
          path="/clonetemplate"
          component={withRouter(props => (
            <NewTemplateForm {...props} isView={false} isClone={true} />
          ))}
          key={6}
        />
        <MasterPageAuthenticatedRoute
          path="/edittemplate"
          component={withRouter(props => (
            <NewTemplateForm {...props} isView={false} />
          ))}
          key={7}
        />
        {/* Will need to remove once new ones are final */}
        {/* <MasterPageAuthenticatedRoute
          path="/manage/staff-management/staff-list"
          component={Staff}
          key={1052}
        />
        <MasterPageAuthenticatedRoute
          path="/manage/staff-management/staff-form"
          component={Staff_Form}
          key={1043}
        /> */}
        {/* <MasterPageAuthenticatedRoute path="/manage/Staff" component={StaffOld} key={102} />
 path="/manage/Staff"
 component={StaffOld}

        <MasterPageAuthenticatedRoute
          path="/manage/staff-form"
          component={Staff_Form_Old}
          key={103}
        /> */}
        {/* Will need to remove once new ones are final */}
        <MasterPageAuthenticatedRoute path="/testError" component={TestComponent} key={155} />
        <MasterPageAuthenticatedRoute
          path="/manage/sg-meeting-list"
          component={SmallGroupMeetingList}
          key={360}
        />
        <MasterPageAuthenticatedRoute path="/manage/gridData" component={GridData} key={561} />;
        <MasterPageAuthenticatedRoute4
          exact={true}
          path="/cw/rcm-reports/"
          component={RCMReports}
          key={162}
        />
        <MasterPageAuthenticatedRoute4
          exact={true}
          path="/cw/patient-management/external-care-team/roles"
          component={ExternalRolesPermissions}
          key={235}
        />
        <MasterPageAuthenticatedRoute4
          exact={true}
          path="/clientPin"
          component={RegisterClientPin}
          key={662}
        />
        <MasterPageAuthenticatedRoute exact path="/rcm/:tab/:tab" component={Rcm} key={81} />
        <Route
          path="/*"
          render={routeProps => {
            let presentURL = routeProps.location.pathname + routeProps.location.search;

            var x = localStorage.getItem('authRoute');
            // let listOfAuthRoute = x && JSON.parse(x).AuthorizedPathList.map(item => item.Path);
            // if (presentURL.includes(listOfAuthRoute)) {
            if (x) {
              return <UnAuthPage />;
            } else {
              return <Redirect to={`/login?redirectPath=${presentURL.replace('/', '')}`} />;
            }
          }}
          key={80}
        />
      </Switch>
    );
  }
}

export default MenuComponent;
