import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { currencyFormatter, numberFormat } from '../../../util/formatter';
import Loader from '../../../Components/Loader';
import { ReduxSelect } from '../../../component/custom-redux-components';
import Alerts from './alerts';
import RecentClaims from './recentClaims';
import PopulationPerformance from './populationPerformance';
import ClientRiskLevels from './clientRiskLevels';
import ContractPeriods from './contractPeriods';
import RiskProfileData from './riskProfileData';
import {
  payerPortalDashboard,
  clientRiskLevelGraphGet,
  contractPeriodGraphGet,
  dashboardClaimsGet,
  dashboardCardsGet,
  riskProfileDataGraphGet,
} from '../../../store/actions';
import PageTitle from '../../../Components/PageTitle';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerId: -1,
      centerName: 'Total',
      options2: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function(val) {
            return val ? currencyFormatter.format(val) : undefined;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        colors: ['#EC8FCA', '#46B4FE'],
        fill: { colors: ['#EC8FCA', '#46B4FE'] },
        chart: {
          id: 'basic-bar',
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ['Jan'],
        },
        yaxis: [
          {
            // labels: {
            //   show: true,
            //   align: 'right',
            //   // minWidth: 0,
            //   // maxWidth: 160,
            //   style: {
            //     colors: [],
            //     fontSize: '12px',
            //     fontFamily: 'Helvetica, Arial, sans-serif',
            //     fontWeight: 400,
            //     cssClass: 'apexcharts-yaxis-label',
            //   },
            //   offsetX: 0,
            //   offsetY: 0,
            //   rotate: 0,
            //   formatter: (value, index) => {
            //
            //     let claimsInDollars = Math.round(value);
            //

            //     return claimsInDollars;
            //   },
            // },
            title: {
              text: 'Claims in $',
              style: {
                fontSize: '13px',
                fontWeight: '600',
              },
            },
          },
        ],
      },
      series2: [
        {
          name: 'This Year - 2022',
          data: [],
        },
        {
          name: 'Last Year - 2021',
          data: [],
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(payerPortalDashboard({}));
    // this.handleAPICalls();
  }

  handleAPICalls = (centerId, startDate, endDate) => {
    // this.props.dispatch(
    //   payerPortalDashboard({
    //     JSON: JSON.stringify({
    //       Dashboard: [
    //         {
    //           StartDate: startDate,
    //           EndDate: endDate,
    //         },
    //       ],
    //     }),
    //   }),
    // );
    this.props.dispatch(
      dashboardClaimsGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: centerId || null,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      dashboardCardsGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: centerId || null,
              StartDate: startDate || null,
              EndDate: endDate || null,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      clientRiskLevelGraphGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: centerId || null,
              StartDate: startDate || null,
              EndDate: endDate || null,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      contractPeriodGraphGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: centerId || null,
              StartDate: startDate || null,
              EndDate: endDate || null,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      riskProfileDataGraphGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: centerId || null,
              StartDate: startDate || null,
              EndDate: endDate || null,
            },
          ],
        }),
      }),
    );
  };

  componentWillReceiveProps({ interactions, datesDropdown }) {
    if (interactions && JSON.stringify(interactions) != JSON.stringify(this.props.interactions)) {
      let { Activity, CurrentYearClaims, LastYearClaims, CurrentYear, LastYear } =
        interactions || {};
      let newActivity = [];
      if (Activity) {
        newActivity = (Activity && Activity.substr(1, Activity.length - 2).split(',')) || '';
      }

      let series2First = {
        ...this.state.series2[0],
        name: `This Year - ${CurrentYear}`,
        data: (CurrentYearClaims && JSON.parse(CurrentYearClaims)) || [],
      };
      let series2Second = {
        ...this.state.series2[1],
        name: `Last Year - ${LastYear}`,
        data: (LastYearClaims && JSON.parse(LastYearClaims)) || [],
      };
      let newBarSeries2 = [series2First, series2Second];
      // let totalInteractions = TotalInteractions || 0;
      this.setState(prevState => {
        return {
          ...prevState,
          options2: {
            ...prevState.options2,
            xaxis: {
              ...prevState.options2.xaxis,
              categories: [...newActivity],
            },
          },
          series2: newBarSeries2,
          // TotalInteractions: totalInteractions,
        };
      });
    }
    if (datesDropdown && datesDropdown != this.props.datesDropdown) {
      // i am calling all the APIs after we are loading dropdown values because we are showing 1st value as default value in date dropdown in all the charts if we don't have dropdown values it;s pointless
      if (datesDropdown.length > 0) { 
        this.handleAPICalls(
          this.state.centerId,
          datesDropdown[0].StartDate,
          datesDropdown[0].EndDate,
        );
      }
    }
  }
  onCenterChange = centerId => {
    const { centers } = this.props;

    centers &&
      centers.map((item, index) => {
        if (item.Value == centerId) {
          return this.setState({
            centerName: item.Text,
          });
        }
      });
    this.setState({ centerId });

    this.handleAPICalls(centerId, null, null);
  };
  render() {
    const {
      alerts,
      recentClaims,
      dashboardData,
      dashboardTiles,
      isFetching,
      contracts,
      centers,
      periodGraph,
      riskLevel,
      clientRisk,
      datesDropdown,
    } = this.props;
    return (
      <div className="container-fluid payer-dashboard">
        <PageTitle TitleText="Dashboard" />
        <h3 className="my-4">Hello, {localStorage.getItem('userName')}</h3>
        <Loader loading={isFetching} />
        <div className="">
          {/* payer-portal-center-unrelated container is not related to center drop down  */}
          <div className="payer-portal-center-unrelated">
            <div className="mt-2 ">
              <div className="prospect-status row">
                {dashboardTiles &&
                  dashboardTiles.map(status => {
                    const { Status, Text, Value } = status;
                    return (
                      <div className="col-4">
                        <div className={Status || 'status-1'}>
                          <div className="status">
                            <span className="text">{Text}</span>
                            <span className="value">
                              {numberFormat.format(parseInt(Value || 0))}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="row mt-5">
              <Alerts alertsMessages={alerts} {...this.props} />
            </div>
          </div>

          {/* payer-portal-center-related container is related to center drop down  */}

          <div className="payer-portal-center-related">
            <div className="d-flex justify-content-between mb-3">
              <h4 className="col-12 col-md-6 mb-0 align-self-end">Population Performance</h4>
              <div className="dashboard-center-2 col-12 col-md-6 mr-0 align-items-center justify-content-end">
                <p
                  style={{
                    fontWeight: '600',
                    margin: '0px 10px',
                    // color: '#fff',
                    // borderLeft: '2px solid #A2D9FE',
                    paddingLeft: '10px',
                  }}
                >
                  Center:
                </p>
                <div className="d-flex align-items-center payer-portal-center">
                  <div className="form-group mb-0" style={{ width: '280px' }}>
                    <ReduxSelect
                      name={`centerId`}
                      // component={ReduxSelect}
                      options={centers}
                      onChange={this.onCenterChange}
                      value={this.state.centerId}
                      // placeholder={"Select Center"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <PopulationPerformance contracts={contracts} />
            <div className="row">
              <RiskProfileData
                clientRisk={clientRisk}
                dispatch={this.props.dispatch}
                centerId={this.state.centerId}
                datesDropdown={datesDropdown}
                isCTRiskFetching={this.props.isCTRiskFetching}
              />
            </div>
            <div className="row mt-5">
              <div className="col-6">
                <ClientRiskLevels
                  riskLevel={riskLevel}
                  dispatch={this.props.dispatch}
                  centerId={this.state.centerId}
                  datesDropdown={datesDropdown}
                  isRiskFetching={this.props.isRiskFetching}
                />
              </div>
              <div className="col-6">
                <ContractPeriods
                  periodGraph={periodGraph}
                  dispatch={this.props.dispatch}
                  centerId={this.state.centerId}
                  datesDropdown={datesDropdown}
                  isPeriodFetching={this.props.isPeriodFetching}
                />
              </div>
            </div>
            <div class="card border-0 col-12 mb-5">
              <div className="card-body d-flex align-items-center justify-content-between">
                <h4 className="p-2">
                  {this.state.centerName == 'Total'
                    ? this.state.centerName
                    : `${this.state.centerName}'s`}{' '}
                  Claims{' '}
                </h4>
              </div>

              <ReactApexChart
                options={this.state.options2}
                series={this.state.series2}
                type="bar"
                height={280}
              />
            </div>
            <div className="row mt-4">
              <RecentClaims recentClaimList={recentClaims} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  payer_portal_dashboard,
  client_risk_level_graph_get,
  contract_period_graph_get,
  dashboard_claims_get,
  dashboard_cards_get,
  risk_profile_data_graph_get,
}) => {
  const { data: dashboardData, error, isFetching } = payer_portal_dashboard;
  const { data: claimsData } = dashboard_claims_get;
  const { data: cardsData } = dashboard_cards_get;
  const { data: riskLevelData, isFetching: isRiskFetching } = client_risk_level_graph_get;
  const { data: periodGraphData, isFetching: isPeriodFetching } = contract_period_graph_get;
  const { data: clientRiskGraph, isFetching: isCTRiskFetching } = risk_profile_data_graph_get;

  let centers = [];
  let alerts = [];
  let recentClaims = [];
  let dashboardTiles = [];
  let interactions = [];
  let contracts = [];
  let periodGraph = [];
  let riskLevel = [];
  let clientRisk = [];
  let riskLevelDateDropdown = [];
  let datesDropdown = [];

  if (dashboardData && dashboardData.DashBoard) {
    alerts = dashboardData.DashBoard[0].Alerts;
    centers = dashboardData.DashBoard[0].Centers;
    dashboardTiles = dashboardData.DashBoard[0].DashboardTiles;
    datesDropdown = dashboardData.DashBoard[0].DropDown;
  }
  if (claimsData && claimsData.DashBoard) {
    recentClaims = claimsData.DashBoard[0].RecentClaims;
    interactions = claimsData.DashBoard[0].Interactions && claimsData.DashBoard[0].Interactions[0];
  }
  if (cardsData && cardsData.DashBoard) {
    contracts = cardsData.DashBoard[0].Contracts[0];
  }
  if (periodGraphData && periodGraphData.DashBoard) {
    periodGraph = periodGraphData.DashBoard[0];
  }
  if (riskLevelData && riskLevelData.Report) {
    riskLevel = riskLevelData.Report[0];
    riskLevelDateDropdown = riskLevelData.Report[0].DatesDropDown;
  }
  if (clientRiskGraph && clientRiskGraph.Report) {
    clientRisk = clientRiskGraph.Report[0];
  }
  return {
    centers,
    alerts,
    recentClaims,
    dashboardData,
    dashboardTiles,
    isFetching,
    interactions,
    contracts,
    periodGraph,
    riskLevel,
    clientRisk,
    riskLevelDateDropdown,
    datesDropdown,
    isRiskFetching,
    isPeriodFetching,
    isCTRiskFetching,
  };
};

export default connect(mapStateToProps)(withRouter(Dashboard));
