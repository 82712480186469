import React, { Component } from 'react';
import { Modal } from 'antd';
import ChatBot from '../../../Components/ChatBot';
import Loader, { Loader2 } from '../../../Components/Loader';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatModalVisible: false,
      ChatMessage: [],
      FriendName: '',
    };
  }

  setChatModalVisible = (chatModalVisible, ChatMessage, FriendName) => {
    this.setState({ chatModalVisible, ChatMessage, FriendName });
  };

  render() {
    const { appSubscribersChatData } = this.props;
    return (
      <>
        <div className="container-fluid p-3">
          <div
            className="row"
            style={{
              background: '#fff',
              borderRadius: '12px',
              paddingTop: '10px',
              minHeight: '50vh',
            }}
          >
            {appSubscribersChatData &&
              appSubscribersChatData.map((item, index) => {
                const {
                  FriendUserGuid,
                  FriendProfileImageUrl,
                  FriendName,
                  FriendBadge,
                  ChatMessage,
                  LastMessage,
                  LastMessageTime,
                } = item;
                return (
                  <>
                    <div
                      class="col-sm-12 mb-1 chat-container"
                      onClick={() => this.setChatModalVisible(true, ChatMessage, FriendName)}
                    >
                      <div class="c-box">
                        {FriendProfileImageUrl ? (
                          <div
                            class="rounded-circle friends-avatar"
                            style={{ backgroundImage: `url(${FriendProfileImageUrl})` }}
                          ></div>
                        ) : (
                          <div
                            class="rounded-circle friends-avatar"
                            style={{
                              backgroundImage: `url(images/familyProgram/blank-profile.png)`,
                            }}
                          ></div>
                        )}

                        <div className="c-containt">
                          <span class="c-friend-name">{FriendName}</span>
                          {/* <span
                          className="c-friend-badge guide"
                          style={{
                            visibility: `${FriendBadge ? 'visible' : 'hidden'}`,
                          }}
                        >
                          {FriendBadge}
                        </span> */}
                          <p className="c-last-msg">{LastMessage}</p>
                        </div>
                        <span className="c-last-msg-time">{LastMessageTime}</span>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <Modal
          title={this.state.FriendName}
          style={{ top: 10 }}
          visible={this.state.chatModalVisible}
          footer={null}
          onOk={() => this.setChatModalVisible(false)}
          onCancel={() => this.setChatModalVisible(false)}
        >
          {this.state.ChatMessage && this.state.ChatMessage.length > 0 ? (
            <div className="p-2">
              <ChatBot
                ChatMessages={this.state.ChatMessage}
                UserGuid={this.props.getSubscriberUserGUID()}
              />
            </div>
          ) : (
            <div className="p-2 text-center">
              <h4>Chat Empty</h4>
            </div>
          )}
        </Modal>
      </>
    );
  }
}

export default Chat;
