import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import { Switch } from 'antd';
import TextareaAutosize from 'react-autosize-textarea';
import DictationComponent from '../../../../../../Components/DictationComponent';
import { Qualifier, Flagged } from '../flag';
import SubmitButton from '../../../../../../Components/submitButton';
import ToggleNote from '../toggleNote';

let noteTimer;
let valueTimer;

class ToggleAlphaNumeric extends React.Component {
  constructor(props) {
    super(props);
    let { Response, Note } = props.survey;
    this.state = {
      value: Response,
      noteValue: Note,
      toggleActive: Note ? true : false,
    };
  }
  onChangeNote = (event, click) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    const { value } = event.target;
    this.setState({
      noteValue: value,
    });
    clearTimeout(noteTimer);
    noteTimer = setTimeout(() => {
      setValue.call(scope, {
        response: this.state.value,
        note: value,
        surveyQuestionId: SurveyQuestionId,
        level: level,
        subLevel: subLevel,
        subCatLevel: subCatLevel,
        index: index,
      });
    }, 1000);
  };
  getNoteChanges = () => {
    this.setState({ noteValue: '' });
  };
  getDicatationStatus = value => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    let timer;
    return () => {
      clearTimeout(timer);
      setTimeout(() => {
        setValue.call(scope, {
          response: this.state.value,
          note: this.state.noteValue,
          surveyQuestionId: SurveyQuestionId,
          level: level,
          subLevel: subLevel,
          subCatLevel: subCatLevel,
          index: index,
        });
      }, 3000);
    };
  };
  // onChange = (event, click) => {
  //   const { value } = event.target;
  //   if (value < 0 || value > 100) return;
  //   this.setState({
  //     value: value,
  //   });
  // };

  onChange = (event, click) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    const { value } = event.target;
    if (value < 0 || value > 100) return;
    this.setState({
      value: value,
    });

    clearTimeout(valueTimer);
    valueTimer = setTimeout(() => {
      setValue.call(scope, {
        response: this.state.value && this.state.value.trim(),
        note: this.state.noteValue,
        surveyQuestionId: SurveyQuestionId,
        level: level,
        subLevel: subLevel,
        subCatLevel: subCatLevel,
        index: index,
      });
    }, 1000);
  };
  handleDictate = result => {
    if (result) {
      let data;
      if (this.state.noteValue) {
        data = `${this.state.noteValue} ${result}`;
      } else {
        data = result;
      }
      this.setState({ noteValue: data });
    }
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Option1QF,
      Response,
      Note,
      ThresholdSign,
      ResponseThreshold,
      MaxResponseThreshold,
      AddNote,
    } = survey;
    let isFlagged = null;
    if (Option1QF === false && this.state.value) {
      let stateVal = parseInt(this.state.value);
      if (ThresholdSign == 'Between') {
        isFlagged = stateVal >= parseInt(ResponseThreshold) && stateVal <= MaxResponseThreshold;
      } else if (ThresholdSign == 'Maximum') {
        isFlagged = stateVal >= parseInt(ResponseThreshold);
      } else if (ThresholdSign == 'Minimum') {
        isFlagged = stateVal <= parseInt(ResponseThreshold);
      }
    }
    return (
      <>
        <div className="type-border col-sm-12">
          {Question && Question.length > 0 && (
            <h5 className="survey-question rcm-question text-left mb-2">
              {QuestionNumber && `${QuestionNumber}.`} {renderHTML(Question)}
            </h5>
          )}
          <div class="answer-options text-left mb-0 pb-0 d-flex flex-column">
            <div class="quantity-btn d-inline mb-3">
              <div class="numbers-row">
                <input
                  disabled={isReadOnly}
                  type="number"
                  value={this.state.value}
                  tabindex="0"
                  min="0"
                  // max="99"
                  placeholder="Enter Answer"
                  // style={{ textAlign: 'center', paddingLeft: '10px' }}
                  className="alphanumeric-type"
                  onChange={this.onChange}
                  onBlur={() => {
                    setValue.call(scope, {
                      response: this.state.value && this.state.value.trim(),
                      note: this.state.noteValue,
                      surveyQuestionId: SurveyQuestionId,
                      level: level,
                      subLevel: subLevel,
                      subCatLevel: subCatLevel,
                      index: index,
                    });
                  }}
                />
                {isFlagged ? Flagged : null}
                {/* <SubmitButton
                  loading={this.props.isAnserFetching}
                  value={'Save'}
                  style={{ height: '42px', padding: '10px 30px', marginLeft: '50px' }}
                  onClick={() => {
                    setValue.call(scope, {
                      response: this.state.value && this.state.value.trim(),
                      note: this.state.noteValue,
                      surveyQuestionId: SurveyQuestionId,
                      level: level,
                      subLevel: subLevel,
                      subCatLevel: subCatLevel,
                      index: index,
                    });
                  }}
                /> */}
              </div>
            </div>
            {AddNote && (
              <div className="text-left">
                <div className="custom-control" style={{ padding: 'unset' }}>
                  <label className="custom-control-label mb-3">
                    <Switch
                      checked={this.state.toggleActive}
                      onClick={() => this.setState({ toggleActive: !this.state.toggleActive })}
                      size="small"
                    />
                    Add Note
                  </label>
                </div>
              </div>
            )}
            {AddNote && this.state.toggleActive && (
              <>
                <ToggleNote
                  handleDictate={this.handleDictate}
                  getDicatationStatus={this.getDicatationStatus}
                  noteValue={this.state.noteValue}
                  getNoteChanges={this.getNoteChanges}
                  onChange={this.onChangeNote}
                  isReadOnly={isReadOnly}
                />
              </>
            )}
            {DoesNotApplyText && (
              <div className="not-apply-to-me">
                <a
                  name="current-situation"
                  value={DoesNotApplyText}
                  onClick={onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: DoesNotApplyValue,
                    level: level,
                    subLevel: subLevel,
                    subCatLevel: subCatLevel,
                    index: index,
                  })}
                >
                  {DoesNotApplyText}
                </a>
              </div>
            )}
          </div>
        </div>
        {/*     
        <div class="survey-box" style={{ width: '100%', display: 'inline-block;' }}>
          <h2>{Question}</h2>
          <div class="survey-form-box">
            <div class="survey-form-left">
              <div class="form-group survey-textinput">
                <div class="input-group">
                  <input class="form-control" type="text" style={{ textAlign: 'center' }} />
                </div>
              </div>
              <span class="small-text" />
            </div>
            <div class="survey-form-right">
              <input
                type="button"
                class="form-control survey-form-btn"
                value="Next"
                tabindex="0"
                onClick={onClick.bind(this)}
              />
            </div>
            <div class="clearfix" />
          </div>
        </div> */}
      </>
    );
  }
}
export default ToggleAlphaNumeric;
