import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* adminstrationListCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationListCourse(payload);
    yield put({
      type: ActionTypes.COURSE_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_GET_ALL_FAILURE,
    });
  }
}

export function* adminstrationListStaff(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationListStaff(payload);
    yield put({
      type: ActionTypes.STAFF_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_GET_ALL_FAILURE,
    });
  }
}

export function* adminstrationListCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationListCenter(payload);
    yield put({
      type: ActionTypes.CENTER_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_GET_ALL_FAILURE,
    });
  }
}

export function* getCenterFieldByCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.getCenterFieldByCenter(payload);
    yield put({
      type: ActionTypes.GET_CENTER_FIELD_BY_CENTER_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CENTER_FIELD_BY_CENTER_FAILURE,
    });
  }
}

export function* adminstrationGetCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationGetCourse(payload);
    yield put({
      type: ActionTypes.COURSE_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Course[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_GET_FAILURE,
    });
  }
}

export function* adminstrationGetStaff(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationGetStaff(payload);
    yield put({
      type: ActionTypes.STAFF_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Staff[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_GET_FAILURE,
    });
  }
}

export function* updateMobileAppRole(action) {
  try {
    const response = yield API.updateMobileAppRole(action.payload);
    yield put({
      type: ActionTypes.UPDATE_MOBILE_APP_ROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_MOBILE_APP_ROLE_FAILURE,
    });
  }
}
export function* adminstrationGetCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationGetCenter(payload);
    yield put({
      type: ActionTypes.CENTER_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Center[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_GET_FAILURE,
    });
  }
}

export function* adminstrationCourseUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCourseUpsert(payload);
    yield put({
      type: ActionTypes.COURSE_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_UPSERT_FAILURE,
    });
  }
}

export function* adminstrationStaffUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationStaffUpsert(payload);
    yield put({
      type: ActionTypes.STAFF_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_UPSERT_FAILURE,
    });
  }
}

export function* adminstrationCenterUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCenterUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_UPSERT_FAILURE,
    });
  }
}

export function* adminstrationCourseDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCourseDelete(payload);
    yield put({
      type: ActionTypes.COURSE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.COURSE_DELETE_FAILURE,
    });
  }
}

export function* adminstrationStaffDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationStaffDelete(payload);
    yield put({
      type: ActionTypes.STAFF_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_DELETE_FAILURE,
    });
  }
}

export function* adminstrationCenterDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.adminstrationCenterDelete(payload);
    yield put({
      type: ActionTypes.CENTER_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_DELETE_FAILURE,
    });
  }
}

export function* announcementGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementGetAll(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_GET_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_GET_ALL_FAILURE,
    });
  }
}

export function* announcementGetJson(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementGetJson(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_GET_JSON_SUCCESS,
      payload: JSON.parse(response.data.data).Announcement[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_GET_JSON_FAILURE,
    });
  }
}
export function* announcementUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementUpsert(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_UPSERT_FAILURE,
    });
  }
}
export function* announcementDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.announcementDelete(payload);
    yield put({
      type: ActionTypes.ANNOUNCEMENT_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ANNOUNCEMENT_DELETE_FAILURE,
    });
  }
}

export function* updateStaffEmail(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffEmail(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_EMAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_EMAIL_FAILURE,
    });
  }
}

export function* updateStaffPassword(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffPassword(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_PASSWORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_PASSWORD_FAILURE,
    });
  }
}

export function* updateStaffCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.updateStaffCenter(payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_CENTER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_CENTER_FAILURE,
    });
  }
}

export function* uploadStaffImage(action) {
  try {
    const { payload } = action;
    const response = yield API.uploadStaffImage(payload);
    yield put({
      type: ActionTypes.UPLOAD_STAFF_IMAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPLOAD_STAFF_IMAGE_FAILURE,
    });
  }
}

export function* isLicenseAvailable(action) {
  try {
    const { payload } = action;
    const response = yield API.isLicenseAvailable(payload);
    yield put({
      type: ActionTypes.IS_LICENSE_AVAILABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.IS_LICENSE_AVAILABLE_FAILURE,
    });
  }
}

export function* getLicenseActivites(action) {
  try {
    const { payload } = action;
    const response = yield API.getLicenseActivites(payload);
    yield put({
      type: ActionTypes.GET_LICENSE_ACTIVITES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LICENSE_ACTIVITES_FAILURE,
    });
  }
}

export function* getCurrentLicenses(action) {
  try {
    const { payload } = action;
    const response = yield API.getCurrentLicenses(payload);
    yield put({
      type: ActionTypes.GET_CURRENT_LICENSES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CURRENT_LICENSES_FAILURE,
    });
  }
}

export function* changeAppRole(action) {
  try {
    const { payload } = action;
    const response = yield API.changeAppRole(payload);
    yield put({
      type: ActionTypes.CHANGE_APP_ROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CHANGE_APP_ROLE_FAILURE,
    });
  }
}

export function* appInviteDashboard(action) {
  try {
    const { payload } = action;
    const response = yield API.appInviteDashboard(payload);
    yield put({
      type: ActionTypes.APP_INVITE_DASHBOARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_INVITE_DASHBOARD_FAILURE,
    });
  }
}
export function* getStaffShiftLog(action) {
  try {
    const { payload } = action;
    const response = yield API.getStaffShiftLog(payload);
    yield put({
      type: ActionTypes.GET_STAFF_SHIFT_LOG_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STAFF_SHIFT_LOG_FAILURE,
    });
  }
}
export function* addStaffShiftLog(action) {
  try {
    const { payload } = action;
    const response = yield API.addStaffShiftLog(payload);
    yield put({
      type: ActionTypes.ADD_STAFF_SHIFT_LOG_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_STAFF_SHIFT_LOG_FAILURE,
    });
  }
}
export function* staffReactivate(action) {
  try {
    const { payload } = action;
    const response = yield API.staffReactivate(payload);
    yield put({
      type: ActionTypes.STAFF_REACTIVATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_REACTIVATE_FAILURE,
    });
  }
}
export function* getPromoCode(action) {
  try {
    const { payload } = action;
    const response = yield API.getPromoCode(payload);
    yield put({
      type: ActionTypes.GET_PROMO_CODE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PROMO_CODE_FAILURE,
    });
  }
}

export function* isManageLicenseRequired(action) {
  try {
    const { payload } = action;
    const response = yield API.isManageLicenseRequired(payload);
    yield put({
      type: ActionTypes.IS_MANAGE_LICENSE_REQUIRED_SUCCESS,
      payload: JSON.parse(response.data.data)[0].Staff[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.IS_MANAGE_LICENSE_REQUIRED_FAILURE,
    });
  }
}
export function* newsUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.newsUpsert(payload);
    yield put({
      type: ActionTypes.NEWS_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.NEWS_UPSERT_FAILURE,
    });
  }
}
export function* getListNews(action) {
  try {
    const { payload } = action;
    const response = yield API.getListNews(payload);
    yield put({
      type: ActionTypes.GET_LIST_NEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LIST_NEWS_FAILURE,
    });
  }
}
export function* getNews(action) {
  try {
    const { payload } = action;
    const response = yield API.getNews(payload);
    yield put({
      type: ActionTypes.GET_NEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NEWS_FAILURE,
    });
  }
}
export function* deleteNews(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteNews(payload);
    yield put({
      type: ActionTypes.DELETE_NEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_NEWS_FAILURE,
    });
  }
}

export function* validatePinExists(action) {
  try {
    const { payload } = action;
    const response = yield API.validatePinExists(payload);
    yield put({
      type: ActionTypes.VALIDATE_PIN_EXISTS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.VALIDATE_PIN_EXISTS_FAILURE,
    });
  }
}
export function* getListTimeLine(action) {
  try {
    const { payload } = action;
    const response = yield API.getListTimeLine(payload);
    yield put({
      type: ActionTypes.GET_LIST_TIMELINE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LIST_TIMELINE_FAILURE,
    });
  }
}
export function* deleteNoteType(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteNoteType(payload);
    yield put({
      type: ActionTypes.DELETE_NOTE_TYPE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_NOTE_TYPE_FAILURE,
    });
  }
}

export function* getNoteTypeArchive(action) {
  try {
    const { payload } = action;
    const response = yield API.getNoteTypeArchive(payload);
    yield put({
      type: ActionTypes.GET_NOTE_TYPE_ARCHIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NOTE_TYPE_ARCHIVE_FAILURE,
    });
  }
}
export function* getNoteTypeList(action) {
  try {
    const { payload } = action;
    const response = yield API.getNoteTypeList(payload);
    yield put({
      type: ActionTypes.GET_NOTE_TYPE_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NOTE_TYPE_LIST_FAILURE,
    });
  }
}
export function* reactivateCenter(action) {
  try {
    const { payload } = action;
    const response = yield API.reactivateCenter(payload);
    yield put({
      type: ActionTypes.CENTER_REACTIVATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_REACTIVATE_FAILURE,
    });
  }
}
export function* getPredefinedTask(action) {
  try {
    const { payload } = action;
    const response = yield API.getPredefinedTask(payload);
    yield put({
      type: ActionTypes.GET_PREDEFINED_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PREDEFINED_TASK_FAILURE,
    });
  }
}
export function* getPredefinedTaskArchive(action) {
  try {
    const { payload } = action;
    const response = yield API.getPredefinedTaskArchive(payload);
    yield put({
      type: ActionTypes.GET_PREDEFINED_TASK_ARCHIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PREDEFINED_TASK_ARCHIVE_FAILURE,
    });
  }
}
export function* deletePredefinedTask(action) {
  try {
    const { payload } = action;
    const response = yield API.deletePredefinedTask(payload);
    yield put({
      type: ActionTypes.DELETE_PREDEFINED_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_PREDEFINED_TASK_FAILURE,
    });
  }
}
export function* upsertPredefinedTask(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertPredefinedTask(payload);
    yield put({
      type: ActionTypes.UPSERT_PREDEFINED_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PREDEFINED_TASK_FAILURE,
    });
  }
}
export function* getStudentTimeLine(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentTimeLine(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_TIMELINE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_TIMELINE_FAILURE,
    });
  }
}

export function* clientCenterMove(action) {
  try {
    const { payload } = action;
    const response = yield API.clientCenterMove(payload);
    yield put({
      type: ActionTypes.CLIENT_CENTER_MOVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_CENTER_MOVE_FAILURE,
    });
  }
}

export function* staffAccessedTabGet(action) {
  try {
    const { payload } = action;
    const response = yield API.staffAccessedTabGet(payload);
    yield put({
      type: ActionTypes.STAFF_ACCESSED_TAB_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_ACCESSED_TAB_GET_FAILURE,
    });
  }
}

export function* studentStaffAccessedTabGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentStaffAccessedTabGet(payload);
    yield put({
      type: ActionTypes.STUDENT_STAFF_ACCESSED_TAB_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_STAFF_ACCESSED_TAB_GET_FAILURE,
    });
  }
}

export function* appAnnouncementUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.appAnnouncementUpsert(payload);
    yield put({
      type: ActionTypes.APP_ANNOUNCEMENT_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_ANNOUNCEMENT_UPSERT_FAILURE,
    });
  }
}

export function* appAnnouncementListGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.appAnnouncementListGetAll(payload);
    yield put({
      type: ActionTypes.APP_ANNOUNCEMENT_LIST_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_ANNOUNCEMENT_LIST_GET_ALL_FAILURE,
    });
  }
}

export function* appAnnouncementGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appAnnouncementGet(payload);
    yield put({
      type: ActionTypes.APP_ANNOUNCEMENT_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APP_ANNOUNCEMENT_GET_FAILURE,
    });
  }
}

export function* tokenManagementListGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.tokenManagementListGetAll(payload);
    yield put({
      type: ActionTypes.TOKEN_MANAGEMENT_LIST_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TOKEN_MANAGEMENT_LIST_GET_ALL_FAILURE,
    });
  }
}

export function* tokenManagementStatsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.tokenManagementStatsGet(payload);
    yield put({
      type: ActionTypes.TOKEN_MANAGEMENT_STATS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TOKEN_MANAGEMENT_STATS_GET_FAILURE,
    });
  }
}

export function* resourceUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.resourceUpsert(payload);
    yield put({
      type: ActionTypes.RESOURCE_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RESOURCE_UPSERT_FAILURE,
    });
  }
}

export function* resourceListGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.resourceListGetAll(payload);
    yield put({
      type: ActionTypes.RESOURCE_LIST_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RESOURCE_LIST_GET_ALL_FAILURE,
    });
  }
}

export function* resourceGet(action) {
  try {
    const { payload } = action;
    const response = yield API.resourceGet(payload);
    yield put({
      type: ActionTypes.RESOURCE_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RESOURCE_GET_FAILURE,
    });
  }
}

export function* prescriptionListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.prescriptionListGet(payload);
    yield put({
      type: ActionTypes.PRESCRIPTION_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRESCRIPTION_LIST_GET_FAILURE,
    });
  }
}

export function* userFeatureFlagGet(action) {
  try {
    const { payload } = action;
    const response = yield API.userFeatureFlagGet(payload);
    yield put({
      type: ActionTypes.USER_FEATURE_FLAG_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_FEATURE_FLAG_GET_FAILURE,
    });
  }
}

export function* getGroupDropdown(action) {
  try {
    const { payload } = action;
    const response = yield API.getGroupDropdown(payload);
    yield put({
      type: ActionTypes.GET_GROUP_DROPDOWN_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_GROUP_DROPDOWN_FAILURE,
    });
  }
}
export function* medicalProgramUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.medicalProgramUpsert(payload);
    yield put({
      type: ActionTypes.MEDICAL_PROGRAM_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MEDICAL_PROGRAM_UPSERT_FAILURE,
    });
  }
}
export function* medicalProgramGet(action) {
  try {
    const { payload } = action;
    const response = yield API.medicalProgramGet(payload);
    yield put({
      type: ActionTypes.MEDICAL_PROGRAM_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MEDICAL_PROGRAM_GET_FAILURE,
    });
  }
}
export function* medicalProgramDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.medicalProgramDelete(payload);
    yield put({
      type: ActionTypes.MEDICAL_PROGRAM_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MEDICAL_PROGRAM_DELETE_FAILURE,
    });
  }
}
export function* regionUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.regionUpsert(payload);
    yield put({
      type: ActionTypes.REGION_INSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.REGION_INSERT_FAILURE,
    });
  }
}
export function* regionGet(action) {
  try {
    const { payload } = action;
    const response = yield API.regionGet(payload);
    yield put({
      type: ActionTypes.REGION_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.REGION_GET_FAILURE,
    });
  }
}
export function* regionDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.regionDelete(payload);
    yield put({
      type: ActionTypes.REGION_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.REGION_DELETE_FAILURE,
    });
  }
}
export function* staffAccessedTabEntGet(action) {
  try {
    const { payload } = action;
    const response = yield API.staffAccessedTabEntGet(payload);
    yield put({
      type: ActionTypes.STAFF_ACCESSED_TAB_ENT_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Tabs[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STAFF_ACCESSED_TAB_ENT_GET_FAILURE,
    });
  }
}

export function* tokensStudentTransactionHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.tokensStudentTransactionHistory(payload);
    yield put({
      type: ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_FAILURE,
    });
  }
}

export function* tokensCounselorTransactionHistory(action) {
  try {
    const { payload } = action;
    const response = yield API.tokensCounselorTransactionHistory(payload);
    yield put({
      type: ActionTypes.TOKENS_COUNSELOR_TRANSACTION_HISTORY_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TOKENS_COUNSELOR_TRANSACTION_HISTORY_FAILURE,
    });
  }
}

export function* tokensStudentTransactionHistoryGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.tokensStudentTransactionHistoryGetAll(payload);
    yield put({
      type: ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_GET_ALL_FAILURE,
    });
  }
}

export function* tokensCounselorTransactionHistoryGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.tokensCounselorTransactionHistoryGetAll(payload);
    yield put({
      type: ActionTypes.TOKEN_MANAGEMENT_COUNSELOR_TRANSACTION_HISTORY_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TOKEN_MANAGEMENT_COUNSELOR_TRANSACTION_HISTORY_GET_ALL_FAILURE,
    });
  }
}

export function* centerOverHeadCostGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerOverHeadCostGet(payload);
    yield put({
      type: ActionTypes.CENTER_OVER_HEAD_COST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_OVER_HEAD_COST_GET_FAILURE,
    });
  }
}

export function* centerOverHeadCostUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.centerOverHeadCostUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_OVER_HEAD_COST_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_OVER_HEAD_COST_UPSERT_FAILURE,
    });
  }
}

export function* centerLaberCostGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerLaberCostGet(payload);
    yield put({
      type: ActionTypes.CENTER_LABER_COST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_LABER_COST_GET_FAILURE,
    });
  }
}

export function* centerLaberCostGetUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.centerLaberCostGetUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_LABER_COST_GET_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_LABER_COST_GET_UPSERT_FAILURE,
    });
  }
}

export function* centerConsumableGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.centerConsumableGetAll(payload);
    yield put({
      type: ActionTypes.CENTER_CONSUMABLE_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_CONSUMABLE_GET_ALL_FAILURE,
    });
  }
}

export function* centerConsumableGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerConsumableGet(payload);
    yield put({
      type: ActionTypes.CENTER_CONSUMABLE_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_CONSUMABLE_GET_FAILURE,
    });
  }
}

export function* centerConsumableGetUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.centerConsumableGetUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_CONSUMABLE_GET_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_CONSUMABLE_GET_UPSERT_FAILURE,
    });
  }
}

export function* centerConsumableDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.centerConsumableDelete(payload);
    yield put({
      type: ActionTypes.CENTER_CONSUMABLE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_CONSUMABLE_DELETE_FAILURE,
    });
  }
}

export function* signedNoteGet(action) {
  try {
    const { payload } = action;
    const response = yield API.signedNoteGet(payload);
    yield put({
      type: ActionTypes.SIGNED_NOTE_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SIGNED_NOTE_GET_FAILURE,
    });
  }
}

export function* noteTypeListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.noteTypeListGet(payload);
    yield put({
      type: ActionTypes.NOTE_TYPE_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.NOTE_TYPE_LIST_GET_FAILURE,
    });
  }
}

export function* noteTypeUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.noteTypeUpsert(payload);
    yield put({
      type: ActionTypes.NOTE_TYPE_UPSERT_SUCCESS,
      payload: { ...response.data, operation: payload.operation },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.NOTE_TYPE_UPSERT_FAILURE,
    });
  }
}
export function* centerFieldGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerFieldGet(payload);
    yield put({
      type: ActionTypes.CENTER_FIELD_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_FIELD_GET_FAILURE,
    });
  }
}
export function* centerFieldUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.centerFieldUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_FIELD_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_FIELD_UPSERT_FAILURE,
    });
  }
}

export function* clientSendMobileAppInvite(action) {
  try {
    const { payload } = action;
    const response = yield API.clientSendMobileAppInvite(payload);
    yield put({
      type: ActionTypes.CLIENT_SEND_MOBILE_APP_INVITE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_SEND_MOBILE_APP_INVITE_FAILURE,
    });
  }
}

export function* centerConfigGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerConfigGet(payload);
    yield put({
      type: ActionTypes.CENTER_CONFIG_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_CONFIG_GET_FAILURE,
    });
  }
}
export function* centerConfigGetById(action) {
  try {
    const { payload } = action;
    const response = yield API.centerConfigGetById(payload);
    yield put({
      type: ActionTypes.CENTER_CONFIG_GET_BY_ID_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_CONFIG_GET_BY_ID_FAILURE,
    });
  }
}
export function* userTagTypeInsert(action) {
  try {
    const { payload } = action;
    const response = yield API.userTagTypeInsert(payload);
    yield put({
      type: ActionTypes.USER_TAG_TYPE_INSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_TAG_TYPE_INSERT_FAILURE,
    });
  }
}
export function* userTagTypeListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.userTagTypeListGet(payload);
    yield put({
      type: ActionTypes.USER_TAG_TYPE_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_TAG_TYPE_LIST_GET_FAILURE,
    });
  }
}
export function* userTagTypeComboListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.userTagTypeComboListGet(payload);
    yield put({
      type: ActionTypes.USER_TAG_TYPE_COMBO_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_TAG_TYPE_COMBO_LIST_GET_FAILURE,
    });
  }
}
export function* userTagTypeUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.userTagTypeUpdate(payload);
    yield put({
      type: ActionTypes.USER_TAG_TYPE_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_TAG_TYPE_UPDATE_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.COURSE_GET_ALL_REQUEST, adminstrationListCourse),
    takeLatest(ActionTypes.CENTER_GET_ALL_REQUEST, adminstrationListCenter),
    takeLatest(ActionTypes.GET_CENTER_FIELD_BY_CENTER_REQUEST, getCenterFieldByCenter),
    takeLatest(ActionTypes.STAFF_GET_ALL_REQUEST, adminstrationListStaff),
    takeLatest(ActionTypes.COURSE_GET_REQUEST, adminstrationGetCourse),
    takeLatest(ActionTypes.STAFF_GET_REQUEST, adminstrationGetStaff),
    takeLatest(ActionTypes.CENTER_GET_REQUEST, adminstrationGetCenter),
    takeLatest(ActionTypes.COURSE_UPSERT_REQUEST, adminstrationCourseUpsert),
    takeLatest(ActionTypes.STAFF_UPSERT_REQUEST, adminstrationStaffUpsert),
    takeLatest(ActionTypes.CENTER_UPSERT_REQUEST, adminstrationCenterUpsert),
    takeLatest(ActionTypes.STAFF_DELETE_REQUEST, adminstrationStaffDelete),
    takeLatest(ActionTypes.CENTER_DELETE_REQUEST, adminstrationCenterDelete),
    takeLatest(ActionTypes.COURSE_DELETE_REQUEST, adminstrationCourseDelete),
    takeLatest(ActionTypes.ANNOUNCEMENT_GET_ALL_REQUEST, announcementGetAll),
    takeLatest(ActionTypes.ANNOUNCEMENT_GET_JSON_REQUEST, announcementGetJson),
    takeLatest(ActionTypes.ANNOUNCEMENT_UPSERT_REQUEST, announcementUpsert),
    takeLatest(ActionTypes.ANNOUNCEMENT_DELETE_REQUEST, announcementDelete),
    takeLatest(ActionTypes.UPDATE_STAFF_EMAIL_REQUEST, updateStaffEmail),
    takeLatest(ActionTypes.UPDATE_STAFF_PASSWORD_REQUEST, updateStaffPassword),
    takeLatest(ActionTypes.UPDATE_STAFF_CENTER_REQUEST, updateStaffCenter),
    takeLatest(ActionTypes.UPLOAD_STAFF_IMAGE_REQUEST, uploadStaffImage),
    takeLatest(ActionTypes.IS_LICENSE_AVAILABLE_REQUEST, isLicenseAvailable),
    takeLatest(ActionTypes.GET_LICENSE_ACTIVITES_REQUEST, getLicenseActivites),
    takeLatest(ActionTypes.GET_CURRENT_LICENSES_REQUEST, getCurrentLicenses),
    takeLatest(ActionTypes.UPDATE_MOBILE_APP_ROLE_REQUEST, updateMobileAppRole),
    takeLatest(ActionTypes.CHANGE_APP_ROLE_REQUEST, changeAppRole),
    takeLatest(ActionTypes.APP_INVITE_DASHBOARD_REQUEST, appInviteDashboard),
    takeLatest(ActionTypes.GET_STAFF_SHIFT_LOG_REQUEST, getStaffShiftLog),
    takeLatest(ActionTypes.ADD_STAFF_SHIFT_LOG_REQUEST, addStaffShiftLog),
    takeLatest(ActionTypes.STAFF_REACTIVATE_REQUEST, staffReactivate),
    takeLatest(ActionTypes.GET_PROMO_CODE_REQUEST, getPromoCode),
    takeLatest(ActionTypes.IS_MANAGE_LICENSE_REQUIRED_REQUEST, isManageLicenseRequired),
    takeLatest(ActionTypes.NEWS_UPSERT_REQUEST, newsUpsert),
    takeLatest(ActionTypes.GET_LIST_NEWS_REQUEST, getListNews),
    takeLatest(ActionTypes.GET_NEWS_REQUEST, getNews),
    takeLatest(ActionTypes.DELETE_NEWS_REQUEST, deleteNews),
    takeLatest(ActionTypes.VALIDATE_PIN_EXISTS_REQUEST, validatePinExists),
    takeLatest(ActionTypes.GET_LIST_TIMELINE_REQUEST, getListTimeLine),
    takeLatest(ActionTypes.DELETE_NOTE_TYPE_REQUEST, deleteNoteType),
    takeLatest(ActionTypes.GET_NOTE_TYPE_ARCHIVE_REQUEST, getNoteTypeArchive),
    takeLatest(ActionTypes.GET_NOTE_TYPE_LIST_REQUEST, getNoteTypeList),
    takeLatest(ActionTypes.CENTER_REACTIVATE_REQUEST, reactivateCenter),
    takeLatest(ActionTypes.GET_PREDEFINED_TASK_REQUEST, getPredefinedTask),
    takeLatest(ActionTypes.GET_PREDEFINED_TASK_ARCHIVE_REQUEST, getPredefinedTaskArchive),
    takeLatest(ActionTypes.DELETE_PREDEFINED_TASK_REQUEST, deletePredefinedTask),
    takeLatest(ActionTypes.UPSERT_PREDEFINED_TASK_REQUEST, upsertPredefinedTask),
    takeLatest(ActionTypes.GET_STUDENT_TIMELINE_REQUEST, getStudentTimeLine),
    takeLatest(ActionTypes.CLIENT_CENTER_MOVE_REQUEST, clientCenterMove),
    takeLatest(ActionTypes.STAFF_ACCESSED_TAB_GET_REQUEST, staffAccessedTabGet),
    takeLatest(ActionTypes.STUDENT_STAFF_ACCESSED_TAB_GET_REQUEST, studentStaffAccessedTabGet),
    takeLatest(ActionTypes.APP_ANNOUNCEMENT_UPSERT_REQUEST, appAnnouncementUpsert),
    takeLatest(ActionTypes.TOKEN_MANAGEMENT_LIST_GET_ALL_REQUEST, tokenManagementListGetAll),
    takeLatest(ActionTypes.TOKEN_MANAGEMENT_STATS_GET_REQUEST, tokenManagementStatsGet),
    takeLatest(ActionTypes.APP_ANNOUNCEMENT_LIST_GET_ALL_REQUEST, appAnnouncementListGetAll),
    takeLatest(ActionTypes.APP_ANNOUNCEMENT_GET_REQUEST, appAnnouncementGet),
    takeLatest(ActionTypes.RESOURCE_UPSERT_REQUEST, resourceUpsert),
    takeLatest(ActionTypes.RESOURCE_LIST_GET_ALL_REQUEST, resourceListGetAll),
    takeLatest(ActionTypes.RESOURCE_GET_REQUEST, resourceGet),
    takeLatest(ActionTypes.PRESCRIPTION_LIST_GET_REQUEST, prescriptionListGet),
    takeLatest(ActionTypes.USER_FEATURE_FLAG_GET_REQUEST, userFeatureFlagGet),
    takeLatest(ActionTypes.GET_GROUP_DROPDOWN_REQUEST, getGroupDropdown),
    takeLatest(ActionTypes.MEDICAL_PROGRAM_UPSERT_REQUEST, medicalProgramUpsert),
    takeLatest(ActionTypes.MEDICAL_PROGRAM_GET_REQUEST, medicalProgramGet),
    takeLatest(ActionTypes.MEDICAL_PROGRAM_DELETE_REQUEST, medicalProgramDelete),
    takeLatest(ActionTypes.REGION_INSERT_REQUEST, regionUpsert),
    takeLatest(ActionTypes.REGION_GET_REQUEST, regionGet),
    takeLatest(ActionTypes.REGION_DELETE_REQUEST, regionDelete),
    takeLatest(ActionTypes.STAFF_ACCESSED_TAB_ENT_GET_REQUEST, staffAccessedTabEntGet),
    takeLatest(
      ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_REQUEST,
      tokensStudentTransactionHistory,
    ),
    takeLatest(
      ActionTypes.TOKENS_COUNSELOR_TRANSACTION_HISTORY_REQUEST,
      tokensCounselorTransactionHistory,
    ),
    takeLatest(
      ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_GET_ALL_REQUEST,
      tokensStudentTransactionHistoryGetAll,
    ),
    takeLatest(
      ActionTypes.TOKEN_MANAGEMENT_COUNSELOR_TRANSACTION_HISTORY_GET_ALL_REQUEST,
      tokensCounselorTransactionHistoryGetAll,
    ),
    takeLatest(ActionTypes.CENTER_OVER_HEAD_COST_GET_REQUEST, centerOverHeadCostGet),
    takeLatest(ActionTypes.CENTER_OVER_HEAD_COST_UPSERT_REQUEST, centerOverHeadCostUpsert),
    takeLatest(ActionTypes.CENTER_LABER_COST_GET_REQUEST, centerLaberCostGet),
    takeLatest(ActionTypes.CENTER_LABER_COST_GET_UPSERT_REQUEST, centerLaberCostGetUpsert),
    takeLatest(ActionTypes.CENTER_CONSUMABLE_GET_ALL_REQUEST, centerConsumableGetAll),
    takeLatest(ActionTypes.CENTER_CONSUMABLE_GET_REQUEST, centerConsumableGet),
    takeLatest(ActionTypes.CENTER_CONSUMABLE_GET_UPSERT_REQUEST, centerConsumableGetUpsert),
    takeLatest(ActionTypes.CENTER_CONSUMABLE_DELETE_REQUEST, centerConsumableDelete),
    takeLatest(ActionTypes.SIGNED_NOTE_GET_REQUEST, signedNoteGet),
    takeLatest(ActionTypes.NOTE_TYPE_LIST_GET_REQUEST, noteTypeListGet),
    takeLatest(ActionTypes.NOTE_TYPE_UPSERT_REQUEST, noteTypeUpsert),
    takeLatest(ActionTypes.CENTER_FIELD_GET_REQUEST, centerFieldGet),
    takeLatest(ActionTypes.CENTER_FIELD_UPSERT_REQUEST, centerFieldUpsert),
    takeLatest(ActionTypes.CLIENT_SEND_MOBILE_APP_INVITE_REQUEST, clientSendMobileAppInvite),
    takeLatest(ActionTypes.CENTER_CONFIG_GET_REQUEST, centerConfigGet),
    takeLatest(ActionTypes.CENTER_CONFIG_GET_BY_ID_REQUEST, centerConfigGetById),
    takeLatest(ActionTypes.USER_TAG_TYPE_INSERT_REQUEST, userTagTypeInsert),
    takeLatest(ActionTypes.USER_TAG_TYPE_LIST_GET_REQUEST, userTagTypeListGet),
    takeLatest(ActionTypes.USER_TAG_TYPE_COMBO_LIST_GET_REQUEST, userTagTypeComboListGet),
    takeLatest(ActionTypes.USER_TAG_TYPE_UPDATE_REQUEST, userTagTypeUpdate),
  ]);
}
