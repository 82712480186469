import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import {
  medicateCombos,
  resetUpsertAppointmentNote,
  comboTreatmentPlanIntervention,
  appointmentNoteList,
} from '../../../../../store/actions';
import { ReduxNoteAddEditBox } from './noteAddEditBox';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Axios from 'axios';
class DrawerInternalContent extends Component {
  constructor() {
    super();
    this.state = {
      newAddendumBtn: true,
      noteAddEditBox: true,
      editAddendumObj: {},
      note: '',
      editAppointmentId: '',
      Addendum: '',
      noteBoxInitObj: {},
      selectedInterventions: [],
      pinConfirmModalVisible: false,
      formType: '',
      formId: '',
      showNoteSucess: '',
      // saveForNowClicked: false,
    };
  }

  resetPinConfirmModalVisible = () => {
    // this.setState({ pinConfirmModalVisible: false });
    this.setState({
      note: '',
      newAddendumBtn: false,
      pinConfirmModalVisible: false,
      IsRealSignature: false,
    });
  };

  // saveForNowClicked = saveForNowClicked => {
  //   this.setState({ saveForNowClicked }, () => {
  //     setTimeout(() => {
  //       this.setState({ saveForNowClicked: !saveForNowClicked });
  //     }, 5000);
  //   });
  // };
  sendSelectedInterventions = selectedItems => {
    this.setState({ ...this.state, selectedInterventions: selectedItems });
  };

  getFormTypeAndFormId = (formType, formId) => {
    this.setState({
      formType,
      formId,
    });
  };

  resetEditAddendumObj = obj => {
    // this.setState({ editAddendumObj: {} });
    this.setState({ editAddendumObj: obj });
  };
  resetnoteAddEditBox = () => {
    this.setState({
      noteAddEditBox: !this.state.noteAddEditBox,
    });
  };

  resetnewAddendumBtn = () => {
    this.setState({
      editAddendumObj: {},
      newAddendumBtn: !this.state.newAddendumBtn,
    });
  };

  resetnote = value => {
    this.setState({
      note: value,
      // forceRender: true,
    });
  };

  reseteditAppointmentId = value => {
    this.setState({
      editAppointmentId: value,
    });
  };

  resetInitValues = () => {
    this.setState({ noteBoxInitObj: {} });
  };

  editAddendum = () => {
    this.setState({ newAddendumBtn: false });
  };

  getIsSignStatus = isSignStatusValue => {
    this.setState({
      isSignStatus: isSignStatusValue,
    });
  };
  componentDidMount() {
    this.props.dispatch(
      comboTreatmentPlanIntervention({
        json: JSON.stringify({
          TreatmentPlanIntervention: [
            {
              ClientId: this.props.ClientId,
            },
          ],
        }),
      }),
    );
    let medicateComboTypes = {
      ComboTypes: 'ServiceCode',
    };

    // this.props.dispatch(medicateCombos(medicateComboTypes));
  }

  componentWillReceiveProps({
    AppointmentNote,
    upsertAppointmentNoteSuccess,
    upsertAppointmentNoteAddendumSuccess,
    validateStaffPinData,
    noteList,
    newlyGenratedAttendeeAddendumAppointmentNoteId,
  }) {
    if (
      upsertAppointmentNoteSuccess &&
      upsertAppointmentNoteSuccess != this.props.upsertAppointmentNoteSuccess
    ) {
      this.setState(
        {
          showNoteSucess: 'Note Updated Successfully',
        },
        () => {
          setTimeout(() => {
            // if (!this.state.saveForNowClicked) {
            //   if (this.props.setNotesDrawerVisible) {
            //     this.props.setNotesDrawerVisible();
            //   }
            // }
            this.setState({
              showNoteSucess: '',
            });
            this.props.callDataAPI();
          }, 2000);
        },
      );
    }
    if (
      AppointmentNote &&
      JSON.stringify(AppointmentNote) != JSON.stringify(this.props.AppointmentNote)
    ) {
      const { Note } = AppointmentNote;
      if (Note && Note.length > 0) {
        this.setState({ noteAddEditBox: false });
      } else {
        this.setState({ noteAddEditBox: true });
      }

      let newObj = {
        note: AppointmentNote.Note,
        startTime: AppointmentNote.StartTime,
        endTime: AppointmentNote.EndTime,
        comboTreatmentPlanIntervention: AppointmentNote.TreatmentPlanInterventionId,
        typeOfNote: AppointmentNote.TypeOfNoteId,
        chiefComplaintNote: AppointmentNote.ChiefComplaint,
        additionalServiceCode: AppointmentNote.AdditionalServiceCodes,
        isSubjectiveNote: AppointmentNote.SubjectiveNote,
        isObjectiveNote: AppointmentNote.ObjectiveNote,
        isAssessmentNote: AppointmentNote.AssessmentNote,
        isPlanNote: AppointmentNote.PlanNote,
        isSoapNote: AppointmentNote.IsSoapNote,
      };
      this.setState({
        noteBoxInitObj: newObj,
      });
    }
    if (
      upsertAppointmentNoteSuccess &&
      upsertAppointmentNoteSuccess != this.props.upsertAppointmentNoteSuccess
    ) {
      // if (this.props.setIsDrawerClosing) {
      //   this.props.setIsDrawerClosing();
      // }

      if (this.state.isSignStatus) {
        let data = {
          Json: JSON.stringify({
            Staff: [
              {
                FormType: this.state.formType,
                FormId: this.state.formId,
              },
            ],
          }),
        };
        let me = this;
        Axios.post('Medicaid/PreValidatePin', data).then(response => {
          let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
          let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
          let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;

          if (isValid) {
            me.setState({
              note: '',
              newAddendumBtn: true,
              pinConfirmModalVisible: true,
              IsRealSignature: IsRealSignature,
            });
            me.props.dispatch(resetUpsertAppointmentNote());
            if (me.props.AppointmentNote.AppointmentNoteId == null) {
              // me.props.toggle('Note Added Successfully');
              me.setState(
                {
                  showNoteSucess: 'Note Added Successfully',
                },
                () => {
                  setTimeout(() => {
                    // if (!this.state.saveForNowClicked) {
                    //   if (me.props.setNotesDrawerVisible) {
                    //     me.props.setNotesDrawerVisible();
                    //   }
                    // }

                    me.setState({
                      showNoteSucess: '',
                    });
                  }, 2000);
                },
              );
              debugger;
              me.props.callDataAPI();
              // me.props.OnCloseDrawer();
              me.props.dispatch(
                appointmentNoteList({
                  Json: JSON.stringify({
                    AppointmentNote: [
                      {
                        ClientId: me.props.ClientId,
                      },
                    ],
                  }),
                }),
              );
            } else {
              if (me.state.isSignStatus) {
                if (validateStaffPinData.IsValid) {
                  // me.props.toggle('Note Signed Successfully');
                  me.setState(
                    {
                      showNoteSucess: 'Note Signed Successfully',
                    },
                    () => {
                      setTimeout(() => {
                        me.setState({
                          showNoteSucess: '',
                          pinConfirmModalVisible: false,
                        });
                      }, 2000);
                    },
                  );
                  me.props.callDataAPI();
                  // me.props.OnCloseDrawer();
                  me.props.dispatch(
                    appointmentNoteList({
                      Json: JSON.stringify({
                        AppointmentNote: [
                          {
                            ClientId: me.props.ClientId,
                          },
                        ],
                      }),
                    }),
                  );
                }
              } else {
                // me.props.toggle('Note Updated Successfully');
                // me.props.OnCloseDrawer();

                me.props.dispatch(
                  appointmentNoteList({
                    Json: JSON.stringify({
                      AppointmentNote: [
                        {
                          ClientId: me.props.ClientId,
                        },
                      ],
                    }),
                  }),
                );
              }
            }
          } else {
            me.props.toggle(failMsg);
            me.setState({
              isSignStatus: false,
            });
          }
        });
      } else {
        // this.props.toggle('Note Updated Successfully');
        // this.props.OnCloseDrawer();

        this.props.dispatch(
          appointmentNoteList({
            Json: JSON.stringify({
              AppointmentNote: [
                {
                  ClientId: this.props.ClientId,
                },
              ],
            }),
          }),
        );
      }
    }

    if (
      upsertAppointmentNoteAddendumSuccess &&
      upsertAppointmentNoteAddendumSuccess != this.props.upsertAppointmentNoteAddendumSuccess
    ) {
      if (this.state.isAppointmentNoteAddendumSignStatus) {
        let data = {
          Json: JSON.stringify({
            Staff: [
              {
                FormType: this.state.formType,
                FormId: this.state.formId,
              },
            ],
          }),
        };
        let me = this;
        Axios.post('Medicaid/PreValidatePin', data).then(response => {
          let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
          let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
          let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;

          if (isValid) {
            me.setState({
              note: '',
              newAddendumBtn: true,
              pinConfirmModalVisible: true,
              IsRealSignature: IsRealSignature,
              isAppointmentNoteAddendumSignStatus: false,
              newlyGenratedAttendeeAddendumAppointmentNoteId: newlyGenratedAttendeeAddendumAppointmentNoteId,
            });
          } else {
            this.setState({
              // pinConfirmModalVisible: false,
              groupMsg: failMsg,
              failOn: true,
            });
            setTimeout(() => {
              this.setState({
                groupMsg: '',
                failOn: false,
              });
            }, 5000);
          }
        });
      } else {
        this.setState({
          newAddendumBtn: true,
        });
        this.props.OnCloseDrawer();
        this.props.dispatch(
          appointmentNoteList({
            Json: JSON.stringify({
              AppointmentNote: [
                {
                  ClientId: this.props.ClientId,
                },
              ],
            }),
          }),
        );
        this.props.toggle('Addendum Updated Successfully');

        // this.props.dispatch(
        //   appointmentNoteList({
        //     Json: JSON.stringify({
        //       AppointmentNote: [
        //         {
        //           ClientId: this.props.clientId,
        //         },
        //       ],
        //     }),
        //   }),
        // );
        // this.props.callDataAPI();
        // this.toggle('Addendum Updated Successfully');
      }

      // if (this.props.AppointmentNote.AppointmentNoteAddendumId == null) {
      //   this.toggle('Addendum Added Successfully');
      // } else {
      //   this.toggle('Addendum Updated Successfully');
      // }
    }
  }
  getAppointmentNoteAddendumSign = AppointmentNoteAddendumSign => {
    this.setState({
      isAppointmentNoteAddendumSignStatus: AppointmentNoteAddendumSign,
    });
  };
  render() {
    const { columns, newAddendumBtn, editAddendumObj, noteAddEditBox } = this.state;
    const { isFetching, isFetchingGetAppointmentNote, hideInterventions } = this.props;

    const {
      AppointmentId,
      AppointmentNoteId,
      SessionDate,
      SessionTime,
      SessionType,
      ServiceCodeDescription,
      ProgramDescription,
      Status,
      AddendumCount,
      IsSign,
      ClientName,
      SignedDate,
      AppointmentNote,
      Note,
      AppointmentNoteAddendum,
      DurationInMin,
      ServiceCode,
      IsGroup,
      AppointmentNoteIntervention,
      SignedMessage,
      IsEligibleForUnSign,
      IsEligibleForSign,
      GroupNote,
    } = this.props.AppointmentNote;
    return (
      <React.Fragment>
        <ReactToPrint
          trigger={() => (
            <div className="text-right mt-2 mb-5">
              {' '}
              <button style={{ border: 'none', cursor: 'pointer' }}>
                <img src="images/printSVG.svg" style={{ cursor: 'pointer' }} />
              </button>
            </div>
          )}
          content={() => this.componentRef}
          documentTitle="Notes"
        />
        <div ref={el => (this.componentRef = el)} className="pageStyle">
          <Loader2 loading={isFetchingGetAppointmentNote} />
          <div className="individualNotes " style={{ position: 'relative' }}>
            <p style={{ color: '#24bd4c' }}>{this.state.showNoteSucess}</p>
            <div className="row">
              <div className="col-md-8">
                <h2
                  style={{
                    color: '#000',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    lineHeight: '27px',
                  }}
                >
                  {ClientName}
                </h2>
              </div>
              <div className="col-md-4 text-right">
                {/* <i class="far fa-clock"></i> &nbsp;
                  {SessionTime} */}
                <p
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    lineHeight: '22px',
                    textTransform: 'uppercase',
                    color: '#828282',
                  }}
                >
                  {SessionDate}
                </p>
              </div>
              {/* <div className="col-md-12">
                <h5>SESSION DATE</h5>
                <p style={{ fontSize: '20px', fontWeight: 'bold' }}></p>
              </div> */}
            </div>
          </div>

          <ReduxNoteAddEditBox
            // isDrawerClosing={this.props.isDrawerClosing}
            getAppointmentNoteAddendumSign={this.getAppointmentNoteAddendumSign}
            sendSelectedInterventions={this.sendSelectedInterventions}
            noteAddEditBox={noteAddEditBox}
            AppointmentNote={AppointmentNote}
            AppointmentId={AppointmentId}
            SignedDate={SignedDate}
            IsSign={IsSign}
            Note={Note}
            GroupNote={GroupNote}
            newAddendumBtn={newAddendumBtn}
            editAddendumObj={editAddendumObj}
            AppointmentNoteAddendum={AppointmentNoteAddendum}
            AddendumCount={AddendumCount}
            resetnote={this.resetnote}
            resetnewAddendumBtn={this.resetnewAddendumBtn}
            resetnoteAddEditBox={this.resetnoteAddEditBox}
            localNote={this.state.note}
            editAppointmentId={this.state.editAppointmentId}
            dispatch={this.props.dispatch}
            editAddendum={this.editAddendum}
            resetEditAddendumObj={this.resetEditAddendumObj}
            ServiceCodeDescription={ServiceCodeDescription}
            SessionType={SessionType}
            ProgramDescription={ProgramDescription}
            initialValues={this.state.noteBoxInitObj}
            resetInitValues={this.resetInitValues}
            AppointmentNoteId={AppointmentNoteId}
            DurationInMin={DurationInMin}
            ClientId={this.props.ClientId}
            ServiceCode={ServiceCode}
            IsGroup={IsGroup}
            // previousInterventions={this.props.previousInterventions}
            AppointmentNoteIntervention={AppointmentNoteIntervention}
            callDataAPI={this.props.callDataAPI}
            OnCloseDrawer={this.props.OnCloseDrawer}
            SignedMessage={SignedMessage}
            pinConfirmModalVisible={this.state.pinConfirmModalVisible}
            getIsSignStatus={this.getIsSignStatus}
            getFormTypeAndFormId={this.getFormTypeAndFormId}
            IsEligibleForUnSign={IsEligibleForUnSign}
            IsEligibleForSign={IsEligibleForSign}
            IsRealSignature={this.state.IsRealSignature}
            // genratedAttendeeAppointmentNoteId={this.props.genratedAttendeeAppointmentNoteId}

            newlyGenratedAttendeeAddendumAppointmentNoteId={
              this.props.newlyGenratedAttendeeAddendumAppointmentNoteId
            }
            getDirtySubmitSucceededStatus={this.props.getDirtySubmitSucceededStatus}
            resetPinConfirmModalVisible={this.resetPinConfirmModalVisible}
            hideInterventions={hideInterventions}
            // saveForNowClicked={this.saveForNowClicked}
          />
          {/* <ReduxNoteAddEditBox
            ClientId={this.props.viewId}
            sendSelectedInterventions={this.sendSelectedInterventions}
            noteAddEditBox={noteAddEditBox}
            // AppointmentNote={AppointmentNote}
            // AppointmentId={AppointmentId}
            // SignedDate={SignedDate}
            // IsSign={IsSign}
            // Note={Note}
            newAddendumBtn={this.state.newAddendumBtn}
            editAddendumObj={this.state.editAddendumObj}
            // AppointmentNoteAddendum={AppointmentNoteAddendum}
            // AddendumCount={AddendumCount}
            resetnote={this.resetnote}
            resetnewAddendumBtn={this.resetnewAddendumBtn}
            resetnoteAddEditBox={this.resetnoteAddEditBox}
            localNote={this.state.note}
            editAppointmentId={this.state.editAppointmentId}
            dispatch={this.props.dispatch}
            editAddendum={this.editAddendum}
            resetEditAddendumObj={this.resetEditAddendumObj}
            // ServiceCodeDescription={ServiceCodeDescription}
            // SessionType={SessionType}
            // ProgramDescription={ProgramDescription}
            initialValues={this.state.noteBoxInitObj}
            resetInitValues={this.resetInitValues}
            // AppointmentNoteId={AppointmentNoteId}
            // DurationInMin={DurationInMin}
            // ClientId={this.state.clientId}
            // ServiceCode={ServiceCode}
          /> */}
        </div>
        {this.state.selectedInterventions && this.state.selectedInterventions.length > 0 && (
          <div
            className="col-md-12 text-left mt-3 px-0"
            style={{
              color: 'rgb(130, 130, 130)',
              fontSize: '16px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            {' '}
            Selected Interventions{' '}
          </div>
        )}

        <ol>
          {this.state.selectedInterventions &&
            this.state.selectedInterventions.map(v => (
              <li style={{ color: 'rgb(130, 130, 130)' }}>{v}</li>
            ))}
        </ol>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  medicate_combos,
  get_appointment_note,
  upsert_appointment_note,
  upsert_appointment_note_addendum,
  combos,
  validate_staff_pin,
  appointment_note_list,
}) => {
  const { data: medicateCombos } = medicate_combos;
  let noteList = [];
  var { data: validateDataPin } = validate_staff_pin;
  const { data: appointmentNoteList, isFetching } = appointment_note_list;
  const {
    data: getAppointmentNote,
    isFetching: isFetchingGetAppointmentNote,
  } = get_appointment_note;

  let medicateCombosObj = {
    ServiceCode: [],
  };
  let validateStaffPinData = {};
  let AppointmentNote = [];
  let upsertAppointmentNoteSuccess = false;
  let upsertAppointmentNoteAddendumSuccess = false;
  let newlyGenratedAttendeeAddendumAppointmentNoteId;
  // let previousInterventions = '';

  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
    };
  }

  if (getAppointmentNote) {
    AppointmentNote = getAppointmentNote[0].AppointmentNote[0] || [];
    // previousInterventions = getAppointmentNote[0].AppointmentNote[0].TreatmentPlanInterventionId;
  }

  if (upsert_appointment_note && upsert_appointment_note.data) {
    upsertAppointmentNoteSuccess = upsert_appointment_note.data.success;
  }
  if (upsert_appointment_note_addendum && upsert_appointment_note_addendum.data) {
    upsertAppointmentNoteAddendumSuccess = upsert_appointment_note_addendum.data.success;
    newlyGenratedAttendeeAddendumAppointmentNoteId = upsert_appointment_note_addendum.data.result;
  }
  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (appointmentNoteList) {
    noteList = appointmentNoteList[0].AppointmentNote || [];
  }
  return {
    medicateCombosObj: medicateCombosObj,
    AppointmentNote,
    upsertAppointmentNoteSuccess,
    isFetchingGetAppointmentNote,
    upsertAppointmentNoteAddendumSuccess,
    validateStaffPinData,
    noteList,
    newlyGenratedAttendeeAddendumAppointmentNoteId,
    // previousInterventions,
  };
};

export default connect(mapStateToProps)(withRouter(DrawerInternalContent));
