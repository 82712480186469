import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import Loader, { Loader2 } from '../../Components/Loader';
import CustomModal from '../../core/modal';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Avatar, Cascader } from 'antd';
import { Checkbox, Radio, Tooltip, Modal, Spin, Alert } from 'antd';
import { mapTime } from '../../util/time';
import moment from 'moment';
import AntdModal from '../../Components/CustomModal';
import { ProcessCombos } from '../../store/comboConfig';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
} from '../../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { addAppSubscribersTask, appSubscribersUserSearch } from '../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endDate: (value, values, props, name) => {
    // if (!value) {
    //   return 'Required';
    // }
    // var index = parseInt(name.match(/\d+/), 10);
    // var objectName = name.split('[')[0];
    var taskDueDate = values.taskDueDate;
    var date1 = new Date(taskDueDate).getTime();
    var date2;
    if (typeof value == 'string') {
      var parts = value.split('-');
      // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
      // January - 0, February - 1, etc.
      var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      date2 = mydate;
    } else {
      date2 = new Date(value).getTime();
    }

    if (date2 <= date1) {
    } else {
      return 'and Time should be lesser than Task Due Date and Time';
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  triggerTime: (value, values, props, name) => {
    let beginningTime = values.taskTriggerTime;
    let endTime = values.taskDueTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'and Date should be lesser than Task Due Time and Date. ';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'and Date should be lesser than Task Due Time and Date.';
      }
    }
  },
  // endTime: (value, values, props, name) => {
  //   let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
  //   let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
  //   if (beginningTime) {
  //     if (beginningTime.isBefore(endTime)) {
  //     } else {
  //       return 'should be greater than Start time';
  //     }
  //   } else {
  //     return 'Not Applicable';
  //   }
  // },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};
const Close = props => (
  <span {...props}>
    <svg
      height="14"
      width="14"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="css-19bqh2r"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
  </span>
);

class InviteNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  addInviteNote = values => {
    debugger;
    // this.props.setRequestData();
    const { inviteNote } = values;
    var json = {
      note: inviteNote,
    };
    var data = {
      json: JSON.stringify(json),
    };
  };
  render() {
    const me = this;
    const { onCancel, handleSubmit, title, isEdit } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-center">{title}</h4>
        </div>
        <div class="modal-body rcm-panel">
          <div>
            <form onSubmit={handleSubmit(this.addInviteNote)}>
              <div className="col-md-12 enrollment-form">
                <div className="row">
                  <div className="col-12">
                    <div class="form-group mb-2">
                      <Field
                        name="inviteNote"
                        type="text"
                        component={ReduxInput}
                        fieldName={'inviteNote'}
                        validate={[Validator.required]}
                        disabled={isEdit}
                      />
                      <label class="placeholder-label">Enter Note </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div class="modal-footer  border-top-0 pt-2">
                  <input
                    type="submit"
                    class="btn black-btn font-weight-bold mr-2"
                    value={isEdit ? 'Update Note' : 'Assign Note'}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { app_subscribers_user_search } = state;
  const { data, error, isFetching } = app_subscribers_user_search;

  let taskOptions = [];
  if (data) {
    taskOptions = [];
  }

  return { taskOptions };
};

const ReduxInviteNoteModal = reduxForm({
  form: 'inviteNote',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(InviteNoteModal)));

export { ReduxInviteNoteModal };
