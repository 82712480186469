import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  NoRisk,
  LowRisk,
  MediumRisk,
  HighRisk,
} from '../RCM/patientEnrollmentForm/formSteps/questionType/risk';
import AntdModal from '../../Components/CustomModal';
import SurveyAnswersModal from './SurveyAnswersModal';
import { preIntakeHistoryGet, preIntakeSurveyAnswersGet } from '../../store/actions';

class ClientRiskChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSurveyAnswers = DateForSort => {
    this.props.dispatch(
      preIntakeSurveyAnswersGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
              IsHistory: this.props.IsHistory,
              ToDate: DateForSort,
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { DateForSort, ClientRisk } = this.props;
    return (
      <div className="d-flex align-items-center">
        <div className="d-flex risk-chart px-4">
          <span className="mr-2" style={{ fontSize: '14px', fontWeight: '400' }}>
            Client Risk Chart:
          </span>
          {ClientRisk === '0' && NoRisk}
          {ClientRisk === '1' && LowRisk}
          {ClientRisk === '2' && MediumRisk}
          {ClientRisk === '3' && HighRisk}
        </div>
        <AntdModal
          style={{ top: 20 }}
          ModalButton={({ onClick }) => (
            <button
              title="Discharge"
              class="survey-btn btn btn-outline-primary black-btn py-1"
              onClick={() => {
                this.getSurveyAnswers(DateForSort);
                onClick();
              }}
              style={{
                display: 'inline',
              }}
            >
              View Answers{' '}
              <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                <img src="images/view-answer.svg" alt="" width="21" />
              </figure>
            </button>
          )}
          ModalContent={props => <SurveyAnswersModal {...props} />}
        />
      </div>
    );
  }
}

export default connect()(withRouter(ClientRiskChart));
