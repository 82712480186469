import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { EventJsonData } = this.props;
    let { PostMedia, TextContent } = EventJsonData && EventJsonData[0];
    // let { UserGuid, MediaUrl, MediaType } = EventJsonData && EventJsonData.Post[0];
    return (
      <div class="border-0">
        <div class="row">
          <div className="col-12">
            <div className="task-box" style={{ backgroundColor: '#f8f8f8' }}>
              <div className="row no-gutters w-100 justify-content-between align-items-center">
                <div className="col-12 mb-1 desc">{TextContent && renderHTML(TextContent)}</div>
                <div className="col-12 mb-1">
                  <div className="d-flex flex-column align-items-center">
                    {PostMedia &&
                      PostMedia.map(item => {
                        let { MediaType, MediaUrl } = item;
                        return (
                          <>
                            {MediaType == 'J' && (
                              <img src={MediaUrl} className="rounded" alt="..." />
                            )}
                            {MediaType === 'm' && (
                              <video
                                style={{ border: '3px solid #EEE' }}
                                width="100%"
                                height="auto"
                                src={MediaUrl}
                                autoplay="true"
                                controls
                                disablePictureInPicture="true"
                                controlsList="nodownload"
                              />
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Post;
