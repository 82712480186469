import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';
import Loader, { Loader2 } from '../../Components/Loader';
import { currencyFormatter2, numberFormat } from '../../util/formatter';

class TokenAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isFetching, TokenStatement } = this.props;
    const columns = [
      {
        title: 'Center',
        dataIndex: 'CenterName',
        key: 'CenterName',
        sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Last Purchase',
        dataIndex: 'LastPurchasedDate',
        key: 'LastPurchasedDate',
        sorter: (a, b) => isDateComparer(a.LastPurchasedDateForSort, b.LastPurchasedDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Total Debited in $',
        dataIndex: 'TotalDebitedin$',
        key: 'TotalDebitedin$',
        sorter: (a, b) => isComparer(a.TotalDebitedin$, b.TotalDebitedin$),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span className="ml-1 font-weight-bold">{text && currencyFormatter2.format(text)}</span>
        ),
      },
      {
        title: 'Total Debited in Tokens',
        dataIndex: 'TotalDebitedTokens',
        key: 'TotalDebitedTokens',
        sorter: (a, b) => isComparer(a.TotalDebitedTokens, b.TotalDebitedTokens),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          text && (
            <span>
              <img src="/images/smallCoin.svg" />
              <span className="ml-1 font-weight-bold">{numberFormat.format(parseInt(text))}</span>
            </span>
          ),
      },
      {
        title: 'Balance',
        dataIndex: 'Balance',
        key: 'Balance',
        sorter: (a, b) => isComparer(a.Balance, b.Balance),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          text && (
            <span>
              <img src="/images/smallCoin.svg" />
              <span className="ml-1">{text && numberFormat.format(parseInt(text))}</span>
            </span>
          ),
      },
      {
        title: 'Action',
        dataIndex: '',
        key: 'x',
        render: (text, record) => (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.props.history.push(`/manage/token-management?id=${record.CenterId}`);
            }}
          >
            <Tooltip placement="top" title="View">
              <img src="images/view.svg" alt="" />
            </Tooltip>
          </span>
        ),
      },
    ];
    return (
      <div className="token-statement">
        <span className="title">Token Statement</span>
        <div className="card border-0 token-statement mt-2">
          <div class="row card-body">
            <div class="col-12">
              {/* <Loader2 loading={isFetching} /> */}
              <div className="">
                <Table
                  columns={columns}
                  dataSource={TokenStatement || []}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div class="dataTables_wrapper no-footer">
                          <div class="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a class="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a class="paginate_button next">Next</a>
                            ) : (
                              <a class="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(TokenAnalytics));
