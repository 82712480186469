import { Drawer } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { licenseNotifyCenterAdmin } from '../../../../store/actions';

export default function AugmentAIDrawerContent(props) {
  const { open, onClose, HTMLContent, handleStartTrial } = props;
  const dispatch = useDispatch();
  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={open}
      visible={open}
      headerStyle={{ backgroundColor: '#F0F9FF', borderBottom: 'unset' }}
      className="ai-licence-drawer"
      size={'large'}
    >
      <div className="" style={{ height: '80vh', overflow: 'auto' }}>
        {HTMLContent}
      </div>

      <div
        // className="row mt-3 m-2"
        className="row mt-3 p-4"
        style={{
          position: 'absolute',
          backgroundColor: 'rgb(255, 255, 255)',
          // padding: '5px',
          bottom: '0px',
          width: '100%',
        }}
      >
        <div className="col-md-4">
          <p>
            <span style={{ fontSize: '26px', fontWeight: '700' }}>
              {/* {' '}
                  <img src={`images/bigCoin.svg`} width="40" height="40" alt="" />
                  5000 */}
              $300
            </span>{' '}
            /center/month
            {/* tokens will be provided to start with */}
          </p>
        </div>
        <div className="col-md-8 text-right">
          <button
            className="ai-btn"
            onClick={() => {
              dispatch(
                licenseNotifyCenterAdmin({
                  JsonInput: JSON.stringify({ License: [{ ModuleId: 7 }] }),
                }),
              );
              // props.history.push('/manage/maintain-license');
            }}
            style={{ cursor: 'pointer' }}
          >
            {/* Purchase AI License */}
            Contact a SoberPeer Representative
          </button>
        </div>
      </div>
    </Drawer>
  );
}
