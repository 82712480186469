import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getOneCallTimeSlots,
  appSubscribersUserSearch,
  getOneCallSession,
  inviteOneCallSession,
  resetinviteOneCallSession,
  cancelOneCallSession,
  resetCancelOneCallSession,
  editGroupCallSession,
  getCallSessionVideoUrl,
} from '../store/actions';
import { Table, Checkbox, Radio, Tooltip, Avatar, DatePicker as AntdDatePicker } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { stringDate } from '../util/date';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
} from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import { Tag, Modal } from 'antd';
import { Field, reduxForm, formValueSelector, FieldArray, getFormSyncErrors } from 'redux-form';
import ListRenderer from '../Components/listRenderer';
import Select from '../component/select/index';
import renderHTML from 'react-render-html';
import CoreModal from '../core/modal';
import _blank from '../images/blank-profile.png';
import { RedoOutlined } from '@ant-design/icons';

const activeKey = 0;
const tabActiveKey = 0;
const { RangePicker } = AntdDatePicker;

// const success = message => {
//   Modal.info({
//     content: message,
//   });
// };
const removeByKey = function(arr, key, value) {
  var i = arr.length;
  while (i--) {
    if (arr[i] && arr[i].hasOwnProperty(key) && arguments.length > 2 && arr[i][key] === value) {
      arr.splice(i, 1);
    }
  }
  return arr;
};
const success = successMsg => {
  Modal.success({
    content: successMsg,
  });
};
class VideoModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(
      getCallSessionVideoUrl({
        VMCallId: this.props.VMCallId,
      }),
    );
  }
  render() {
    const { onOk, onCancel, VideoCallUrl, Title, CallDate } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-1">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">{Title}</h4>
          <h6>Call Date :{CallDate}</h6>
        </div>
        <div class="modal-body" style={{ padding: '2px 50px', paddingBottom: '12px' }}>
          <Loader2 loading={!VideoCallUrl} />
          {VideoCallUrl.includes('https') ? (
            <video
              style={{ border: '3px solid #EEE' }}
              width="100%"
              height="auto"
              src={VideoCallUrl}
              autoplay="true"
              controls
              disablePictureInPicture="true"
              controlsList="nodownload"
            />
          ) : (
            <div className="text-center">
              <h5>{VideoCallUrl}</h5>
            </div>
          )}

          {/* <video width="auto" height="250" controls autoplay>
            <source src={VideoCallUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        </div>

        <div class="modal-footer justify-content-center border-top-0 py-2">
          <button type="button" class="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsGetUrl = ({ get_call_session_video_url }) => {
  const { data, error, isFetching } = get_call_session_video_url;
  let VideoCallUrl = '';

  if (get_call_session_video_url && get_call_session_video_url.data) {
    VideoCallUrl = data.result;
  }
  return {
    VideoCallUrl,
  };
};

VideoModalContent = connect(mapStateToPropsGetUrl)(withRouter(VideoModalContent));

class GroupSessionBlock extends Component {
  constructor(props) {
    super(props);
  }
  cancelGroupCall = CallSessionId => {
    let data = {
      CallSession: [
        {
          Client: [
            {
              VMCallSessionId: CallSessionId,
            },
          ],
        },
      ],
    };
    var cancelData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(cancelOneCallSession(cancelData));
  };
  fnGetVideoUrl = VMCallId => {
    this.props.dispatch(
      getCallSessionVideoUrl({
        VMCallId: VMCallId,
      }),
    );
  };
  render() {
    const { Data } = this.props;
    const CallStatusMessageArr = Data.CallStatusMessage.split(',');
    const MeetingTimeTextArr = Data.MeetingTimeText.split(',');
    const MeetingTimeValueArr = Data.MeetingTimeValue.split(',');
    return (
      <>
        <div
          class="course-box"
          style={{
            backgroundColor: '#f8f8f8',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '10px',
            marginBottom: '15px',
          }}
        >
          <div class="row no-gutters w-100 justify-content-between  align-items-center">
            <div class="row no-gutters w-100 align-items-start justify-content-between">
              <div className="">
                <div className="d-flex align-items-start">
                  <Avatar
                    size="large"
                    className="mr-2"
                    icon={<i class="fas fa-users" />}
                    // src={Data.ProfileImageUrl}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      style={{
                        fontSize: '16px',
                        // fontWeight: '700'
                      }}
                    >
                      {Data.DisplayName}
                    </span>
                    <span style={{ fontSize: '14px', color: '#828282' }}>{Data.CallDate}</span>
                  </div>
                </div>
              </div>
              <div className="">
                <span
                  style={{
                    fontSize: '16px',
                    // fontWeight: '600',
                    color: CallStatusMessageArr[1] || 'orange',
                  }}
                >
                  {CallStatusMessageArr[2]}
                </span>
              </div>
            </div>
            <div class="course-info w-100 d-flex justify-content-between">
              <div>
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <div class="modal-section">
                      <span
                        style={{
                          textAlign: 'right',
                          fontSize: '14px',
                          fontWeight: '600',
                          color: 'orange',
                          cursor: Data.LinkURL && 'pointer',
                        }}
                        onClick={() => {
                          Data.LinkURL && Data.LinkURL != '1'
                            ? window.open(Data.LinkURL)
                            : onClick();
                        }}
                      >
                        {Data.LinkMessage}
                      </span>
                    </div>
                  )}
                  ModalContent={props => (
                    <VideoModalContent
                      VMCallId={Data.VMCallID}
                      Title={Data.MeetingTitle}
                      CallDate={Data.CallDate}
                      {...props}
                    />
                  )}
                />
                <span
                  style={{
                    fontSize: '15px',
                    color: '#007bff',
                    display: 'block',
                    margin: '10px 10px 10px 0px',
                  }}
                >
                  {renderHTML(Data.DisplayMessage)}
                </span>{' '}
                <span style={{ fontSize: '14px', fontWeight: '700', textAlign: 'justify' }}>
                  {Data.GroupCallStudentName}
                </span>
              </div>
              <div>
                <div class="row mt-2 no-gutters w-100  justify-content-between">
                  <div className="d-flex mt-1 flex-column align-items-start">
                    {MeetingTimeTextArr.map((item, index) => {
                      return (
                        <>
                          <div
                            class="enroll-date course-date mb-1"
                            style={{ width: 'unset', maxWidth: 'unset' }}
                          >
                            {item}
                            <em> {MeetingTimeValueArr[index]}</em>
                          </div>
                        </>
                      );
                    })}
                    <div class="actions-button">
                      {
                        <>
                          {CallStatusMessageArr[0] == '1' && (
                            <>
                              <CustomModal
                                ModalButton={({ onClick }) => (
                                  <Tooltip placement="top" title="Cancel">
                                    <span class="delete-course" onClick={onClick}>
                                      <i class="fas fa-times" />
                                    </span>
                                  </Tooltip>
                                )}
                                ModalContent={({ onOk, onCancel }) => (
                                  <div class="modal-content border-0">
                                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                      >
                                        &times;
                                      </button>
                                      <h4 class="modal-title col-12">
                                        Are you sure you want to cancel?
                                      </h4>
                                    </div>

                                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                      <button
                                        type="button"
                                        class="btn black-btn"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                        style={{ background: '#36cbfea6' }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        class="btn "
                                        onClick={() => {
                                          onOk();
                                          this.cancelGroupCall(Data.VMCallSessionId);
                                        }}
                                      >
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />

                              <CustomModal
                                modalWidth={700}
                                ModalButton={({ onClick }) => (
                                  <Tooltip placement="top" title="Add/Remove">
                                    <span class="select-course" onClick={onClick}>
                                      {/* <i class="select-icon" /> */}
                                      <i class="fas fa-pencil-alt" />
                                    </span>
                                  </Tooltip>
                                )}
                                ModalContent={props => (
                                  <ReduxEditGroupStudentModal
                                    VMCallID={Data.VMCallID}
                                    {...props}
                                    // GetAllSelectedStudents={this.getAllSelectedStudents}
                                  />
                                )}
                              />
                            </>
                          )}
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class EditGroupStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      searchStr: '',
      selectedStudent: [],
      searchedStudent: [],
    };
  }

  // onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  componentDidMount() {
    this.props.dispatch(editGroupCallSession({ VMCallID: this.props.VMCallID }));
    this.props.dispatch(appSubscribersUserSearch({}));
    this.input.focus();
  }

  componentWillReceiveProps({ groupCallStudent, searchedStudent }) {
    if (searchedStudent) {
      groupCallStudent.map((GCS, i) => {
        searchedStudent.map((item, index) => {
          if (GCS.UserGuid == item.UserGuid) {
            searchedStudent.splice(index, 1);
          }
        });
      });
      this.setState({ searchedStudent });
    }
    if (groupCallStudent) {
      this.setState({ selected: groupCallStudent });
    }
  }

  // addBlockDays = value => {
  //   this.props.dispatch(
  //     addVacationDays({
  //       StartDate: value.startDate,
  //       EndDate: value.endDate,
  //       Reason: value.reason,
  //     }),
  //   );
  //   // this.props.onOk();
  // };
  getSerachedStudent = () => {
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  onUserSelect(item) {
    // current array of selected
    const { selected, searchedStudent } = this.state;
    let index;
    selected.push(item);
    let searchedStu = removeByKey(searchedStudent, 'UserGuid', item.UserGuid);
    // index = searchedStudent.indexOf(item);
    // searchedStudent.splice(index, 1);
    this.setState({ selected: selected, searchedStudent, searchStr: '' });
    // if (e.target.checked) {
    //   selected.push(item);
    //   let searchedStu = removeByKey(searchedStudent, 'UserGuid', item.UserGuid);
    //   // index = searchedStudent.indexOf(item);
    //   // searchedStudent.splice(index, 1);
    //   this.setState({ selected: selected, searchedStudent });
    // } else {
    //   let selectedStu = removeByKey(selected, 'UserGuid', item.UserGuid);
    //   this.setState({ selected: selectedStu });
    //   // index = selected.indexOf(item);
    //   // selected.splice(index, 1);
    // }
    // this.setState({ ...selected, selected: selected, searchedStudent });
  }
  clearSelection = item => {
    let stateArr = this.state.selected;
    let index;
    index = stateArr.indexOf(item);
    stateArr.splice(index, 1);
    this.setState({ selected: stateArr });
  };

  SaveInviteGroupCall = () => {
    const { selected } = this.state;
    let groupCallGuid = [];
    selected.length > 0 &&
      selected.map(item => {
        groupCallGuid.push({ UserGuid: item.UserGuid, VMCallID: this.props.VMCallID });
      });
    let data = {
      CallSession: [
        {
          Client: groupCallGuid,
        },
      ],
    };
    let callData = {
      Json: JSON.stringify(data),
    };

    if (!selected) {
      this.setState({
        errorMsg: 'Please Select Atleast 1 Client',
      });
    } else {
      this.props.dispatch(inviteOneCallSession(callData));
      this.props.onOk();
    }
  };
  render() {
    const { onOk, onCancel, isFetching, groupCallStudent } = this.props;
    const { searchStr, selected, searchedStudent } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">
            Select Students ( {this.state.selected.length} )
          </h4>
        </div>
        <div class="modal-body">
          <div className=" w-100">
            <div class="col-12 mt-2" style={{ maxHeight: '80px', overflowY: 'auto' }}>
              {selected.length > 0 &&
                selected.map(item => {
                  const CallStatusArr = item.CallStatusMessage && item.CallStatusMessage.split(',');
                  const NewUserMessageArr =
                    selected[0].NewUserMessage && selected[0].NewUserMessage.split(',');
                  return (
                    <>
                      <div className="d-inline-flex mr-2">
                        <div
                          style={{
                            background: 'aliceblue',
                            borderRadius: '20px',
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar
                            // size="large"
                            className="mr-2"
                            // icon={<i class="fas fa-users"></i>}
                            src={item.ProfileImgUrl || _blank}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '14px', fontWeight: '700' }}>
                              {item.DisplayName}
                            </span>
                            <span
                              style={{
                                fontSize: '10px',
                                fontWeight: '700',
                                color:
                                  (CallStatusArr && CallStatusArr[0]) ||
                                  (NewUserMessageArr && NewUserMessageArr[1]),
                              }}
                            >
                              {(CallStatusArr && CallStatusArr[1]) ||
                                (NewUserMessageArr && NewUserMessageArr[2])}
                            </span>
                          </div>
                          <Tooltip placement="top" title="Remove">
                            <span
                              class="delete-course"
                              style={{ margin: '0px 0.5rem' }}
                              onClick={() => {
                                this.clearSelection(item);
                              }}
                            >
                              <i class="fas fa-times" />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div class="search-box w-100 mt-2" style={{ maxWidth: '95%', paddingLeft: '15px' }}>
            <div class="form-group has-search">
              <input
                type="text"
                value={searchStr}
                class="form-control shadow-sm"
                placeholder="Search"
                onChange={this.studentSearch}
                ref={ref => (this.input = ref)}
              />
            </div>
          </div>
          <div class="row m-0">
            {
              <div
                class="col-12 col-md-12 enrollment-form"
                style={{ height: '100px', overflowY: 'auto' }}
              >
                <Loader2 loading={isFetching} />
                {searchedStudent.length > 0 ? (
                  searchedStudent.map((item, index) => {
                    return (
                      <div className="col-6 d-inline-block edit-user">
                        <div
                          onClick={() => {
                            this.onUserSelect(item);
                          }}
                          className="edit-user-block"
                        >
                          <div className="d-flex align-items-center">
                            <Avatar
                              size="large"
                              className="mr-2"
                              // icon={<i class="fas fa-users"></i>}
                              // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                              src={item.ProfileImageUrl || _blank}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                {item.DisplayName}
                              </span>
                              {/* <span style={{ fontSize: '12px', color: 'gray' }}>March 25, 2020</span> */}
                            </div>
                          </div>
                          {/* <div>
                            <Checkbox
                              onChange={e => {
                                this.onCheckBoxChange(e, item);
                              }}
                              // value={index}
                              name={item.UserGuid}
                              // checked={this.state.selected === item.UserGuid}
                              // onChange={() => {
                              //   this.setState({
                              //     selected: item.UserGuid,
                              //     selectedStudent: item,
                              //   });
                              // }}
                            />
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="col-12">
                      <h5 className="text-center">No Record</h5>
                    </div>
                  </>
                )}
              </div>
            }
          </div>
        </div>

        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <input onClick={onCancel} type="button" class="btn mx-2" value="Cancel" />
          <input
            type="button"
            class="btn mx-2"
            value="Save"
            onClick={this.SaveInviteGroupCall}
            // onClick={() => {
            //   this.SaveInviteGroupCall();
            //   onOk();
            // }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToPropsEditGroupStudent = ({
  app_subscribers_user_search,
  edit_group_call_session,
}) => {
  let searchedStudent = [];
  let groupCallStudent = [];
  let searchStr;
  const { data, isFetching } = app_subscribers_user_search;
  if (edit_group_call_session && edit_group_call_session.data) {
    groupCallStudent = edit_group_call_session.data.Advisor;
  }
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    if (app_subscribers_user_search.data.searchStr) {
      // searchedStudent = Object.values(data.data) || [];
      searchedStudent = [...searchedStudent, ...Object.values(data.data)];
      searchStr = data.searchStr;
    }
  }
  return {
    searchedStudent,
    groupCallStudent,
    isFetching,
    searchStr,
  };
};
const ReduxEditGroupStudentModal = connect(mapStateToPropsEditGroupStudent)(EditGroupStudentModal);

class SelectGroupStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      searchStr: '',
      selectedStudent: [],
      searchedStudent: [],
    };
  }

  // onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  handelGui = () => {
    this.props.GetAllSelectedStudents(this.state.selected);
  };
  componentDidMount() {
    // this.props.dispatch(getAllVacation({}));
    this.props.dispatch(appSubscribersUserSearch({}));
    this.input.focus();
  }

  componentWillReceiveProps({ searchedStudent }) {
    if (searchedStudent) {
      this.setState({ searchedStudent });
    }
  }

  // addBlockDays = value => {
  //   this.props.dispatch(
  //     addVacationDays({
  //       StartDate: value.startDate,
  //       EndDate: value.endDate,
  //       Reason: value.reason,
  //     }),
  //   );
  //   // this.props.onOk();
  // };
  getSerachedStudent = () => {
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  onCheckBoxChange(e, item) {
    // current array of selected
    const selected = this.state.selected;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to selected array
      selected.push(item);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = selected.indexOf(item);
      selected.splice(index, 1);
    }
    // update the state with the new array of selected
    this.setState({ selected: selected });
  }
  clearSelection = item => {
    let stateArr = this.state.selected;
    let index;
    index = stateArr.indexOf(item);
    stateArr.splice(index, 1);
    this.setState({ selected: stateArr });
  };
  render() {
    const { onOk, onCancel, isFetching } = this.props;
    const { searchStr, selected, searchedStudent } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">
            Select Students ( {this.state.selected.length} )
          </h4>
        </div>
        <div class="modal-body">
          <div className=" w-100">
            <div class="col-12 mt-2" style={{ maxHeight: '80px', overflowY: 'auto' }}>
              {selected.length > 0 &&
                selected.map(item => {
                  return (
                    <>
                      <div className="d-inline-flex mr-2">
                        <div
                          style={{
                            background: 'aliceblue',
                            borderRadius: '20px',
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <Avatar
                            // size="large"
                            className="mr-2"
                            // icon={<i class="fas fa-users"></i>}
                            src={item.ProfileImgUrl || _blank}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '14px', fontWeight: '700' }}>
                              {item.DisplayName}
                            </span>
                          </div>
                          <Tooltip placement="top" title="Remove">
                            <span
                              class="delete-course"
                              style={{ margin: '0px 0.5rem' }}
                              onClick={() => {
                                this.clearSelection(item);
                              }}
                            >
                              <i class="fas fa-times" />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div class="search-box w-100">
            <div class="form-group has-search">
              <input
                type="text"
                value={searchStr}
                class="form-control shadow-sm"
                placeholder="Search"
                onChange={this.studentSearch}
                ref={ref => (this.input = ref)}
              />
            </div>
          </div>
          <div class="row m-0">
            {
              <div
                class="col-12 col-md-12 enrollment-form"
                style={{ height: '200px', overflowY: 'auto' }}
              >
                <Loader2 loading={isFetching} />
                {searchedStudent.length > 0 ? (
                  searchedStudent.map((item, index) => {
                    return (
                      <div className="col-6 d-inline-block">
                        <div
                          className="d-flex justify-content-between m-1 align-items-center"
                          style={{
                            boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.06)',
                            paddingBottom: '10px',
                            marginBottom: '5px',
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <Avatar
                              size="large"
                              className="mr-2"
                              // icon={<i class="fas fa-users"></i>}
                              // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                              src={item.ProfileImgUrl || _blank}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                {item.DisplayName}
                              </span>
                              {/* <span style={{ fontSize: '12px', color: 'gray' }}>March 25, 2020</span> */}
                            </div>
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                this.onCheckBoxChange(e, item);
                              }}
                              value={index}
                              // checked={this.state.selected === item.UserGuid}
                              // onChange={() => {
                              //   this.setState({
                              //     selected: item.UserGuid,
                              //     selectedStudent: item,
                              //   });
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="col-12">
                      <h5 className="text-center">No Record</h5>
                    </div>
                  </>
                )}
              </div>
            }
          </div>
        </div>

        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <input onClick={onCancel} type="button" class="btn mx-2" value="Cancel" />
          <input
            type="button"
            class="btn mx-2"
            value="Save"
            onClick={() => {
              this.handelGui();
              onOk();
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToPropsGroupStudent = ({ app_subscribers_user_search }) => {
  let searchedStudent = [];
  let searchStr;
  const { data, isFetching } = app_subscribers_user_search;
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    // searchedStudent = Object.values(data.data) || [];
    // searchStr = data.searchStr || '';
    if (app_subscribers_user_search.data && app_subscribers_user_search.data.searchStr) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else {
      let resObj;
      if ('searchStr' in data) {
        resObj = Object.values(data.data);
      } else {
        resObj = Object.values(data);
      }
      searchedStudent = resObj || [];
    }
  }
  return {
    searchedStudent,
    isFetching,
    searchStr,
  };
};

const ReduxSelectGroupStudentModal = connect(mapStateToPropsGroupStudent)(SelectGroupStudentModal);

class GroupSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: '',
      startDate: '',
      timeSlot: '',
      selectedStudent: '',
      errorMsg: '',
    };
  }
  // handleChange = date => {
  //   this.setState({
  //     dueDate: date,
  //   });
  // };
  // componentDidMount() {
  //   this.props.dispatch(getOneCallTimeSlots({}));
  //   this.props.dispatch(getOneCallSession({}));
  // }
  // shouldComponentUpdate(props) {
  //   if (
  //     JSON.stringify(props.GroupCallSessionData) !=
  //       JSON.stringify(this.props.GroupCallSessionData) ||
  //     props.dates != this.props.dates ||
  //     props.durations != this.props.durations ||
  //     props.timeSlots != this.props.timeSlots ||
  //     props.isCancelGroupCallSession != this.props.isCancelGroupCallSession ||
  //     props.isFetching != this.props.isFetching ||
  //     props.isinviteCallSession != this.props.isinviteCallSession ||
  //     props.isinviteGroupCallSession != this.props.isinviteGroupCallSession ||
  //     props.successMsg != this.props.successMsg ||
  //     props.VideoCallUrl != this.props.VideoCallUrl
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }
  componentWillReceiveProps({ isinviteGroupCallSession, isCancelGroupCallSession, VideoCallUrl }) {
    if (
      isinviteGroupCallSession &&
      isinviteGroupCallSession != this.props.isinviteGroupCallSession
    ) {
      // success();
      // this.props.dispatch(resetinviteOneCallSession());
      this.setState({
        startDate: '',
        timeSlot: '',
        duration: '',
        selectedStudent: '',
      });
      this.props.dispatch(getOneCallSession({}));
      // this.props.dispatch(
      //   getOneCallSession({
      //     Json: JSON.stringify({
      //       CallSession: [{ Client: [{ IsGroupCall: '1' }] }],
      //     }),
      //   }),
      // );
    }
    if (isCancelGroupCallSession) {
      this.props.dispatch(resetCancelOneCallSession());
      this.props.dispatch(getOneCallSession({}));
      // this.props.dispatch(
      //   getOneCallSession({
      //     Json: JSON.stringify({
      //       CallSession: [{ Client: [{ IsGroupCall: '1' }] }],
      //     }),
      //   }),
      // );
    }
    // if (VideoCallUrl && VideoCallUrl != this.props.VideoCallUrl) {
    //   window.open(VideoCallUrl, '_blank');
    // }
  }
  handleDateChange = startDate => {
    this.setState({ startDate, isDateReq: false });
  };
  handleTimeChange = timeSlot => {
    this.setState({ timeSlot, isTimeSlotReq: false });
  };
  handleDurationChange = duration => {
    this.setState({ duration, isDurationReq: false });
  };
  getAllSelectedStudents = selectedStudent => {
    this.setState({ selectedStudent: selectedStudent, errorMsg: '' });
  };
  sendInviteGroupCall = () => {
    const { selectedStudent, startDate, timeSlot, duration } = this.state;
    let groupCallGuid = [];
    selectedStudent.length > 0 &&
      selectedStudent.map(item => {
        groupCallGuid.push({ UserGuid: item.UserGuid });
      });
    let data = {
      CallSession: [
        {
          Client: groupCallGuid,
          TimeSlots: [
            {
              RequestForDate: this.state.startDate,
              TimeSlotId: this.state.timeSlot,
              Duration: this.state.duration,
              IsGroupCall: 1,
            },
          ],
        },
      ],
    };
    let callData = {
      Json: JSON.stringify(data),
    };
    if (!selectedStudent || !startDate || !timeSlot || !duration) {
      if (!selectedStudent) {
        this.setState({
          errorMsg: 'Please Select Atleast 1 Client',
        });
      } else if (!startDate) {
        this.setState({
          isDateReq: true,
        });
      } else if (!timeSlot) {
        this.setState({
          isTimeSlotReq: true,
        });
      } else if (!duration) {
        this.setState({
          isDurationReq: true,
        });
      }
    } else {
      this.props.dispatch(inviteOneCallSession(callData));
    }
    // selectedStudent.length > 0
    //   ? this.props.dispatch(inviteOneCallSession(callData))
    //   : this.setState({ errorMsg: 'Please Select Atleast 1 Student' });
    // this.setState({ errorMsg: 'Please Select min Student' });
    // this.props.dispatch(inviteOneCallSession(callData));
  };
  clearSelection = item => {
    let stateArr = this.state.selectedStudent;
    let index;
    index = stateArr.indexOf(item);
    stateArr.splice(index, 1);
    this.setState({ selectedStudent: stateArr });
  };
  render() {
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Select Date"
        value={value}
        onClick={onClick}
      />
    );

    const {
      isFetching,
      dates,
      timeSlots,
      durations,
      GroupCallSessionData,
      isFetchingGroupCall,
    } = this.props;
    const {
      startDate,
      timeSlot,
      duration,
      selectedStudent,
      errorMsg,
      isDateReq,
      isTimeSlotReq,
      isDurationReq,
    } = this.state;
    // GroupCallSessionList For Group Call Session
    const GroupCallSessionList = [
      {
        Name: 'Upcoming',
        Component: props => {
          const Data = { ...props };
          if (Data.VMCallID) {
            return (
              <div class="col-12">
                <GroupSessionBlock {...this.props} Data={Data} />
              </div>
            );
          } else
            return (
              <div class="col-12 text-center">
                <h5>Group Call Record Empty</h5>
              </div>
            );
        },
        Content: (GroupCallSessionData.length > 0 && GroupCallSessionData[0].Upcoming) || [{}],
      },
      {
        Name: 'Canceled/No Shows',
        Component: props => {
          const Data = { ...props };
          if (Data.VMCallID) {
            return (
              <div class="col-12">
                <GroupSessionBlock {...this.props} Data={Data} />
              </div>
            );
          } else
            return (
              <div class="col-12 text-center">
                <h5>Group Call Record Empty</h5>
              </div>
            );
        },
        Content: (GroupCallSessionData.length > 0 && GroupCallSessionData[0].Cancelled) || [{}],
      },
      {
        Name: 'Completed',
        Component: props => {
          const Data = { ...props };
          if (Data.VMCallID) {
            return (
              <div class="col-12">
                <GroupSessionBlock {...this.props} Data={Data} />
              </div>
            );
          } else
            return (
              <div class="col-12 text-center">
                <h5>Group Call Record Empty</h5>
              </div>
            );
        },
        Content: (GroupCallSessionData.length > 0 && GroupCallSessionData[0].Completed) || [{}],
      },
    ];
    return (
      <div class="row mb-5">
        <div class="col-lg-5">
          {/* <Loader2 loading={isFetchingGroupCall} /> */}
          <div class="card border-0 ">
            <div class="row card-body enrollment-form">
              <div class="col-12 text-left mb-3">
                <h4>Create an Invitation</h4>
              </div>
              <div class="col-12 col-md-8 col-lg-9">
                {/* <button type="button" class="btn btn-outline-primary mx-2" value="Select Student">
                  <i class="fas fa-plus form-control-icon" /> Select Student
                </button> */}
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <span className="btn btn-outline-primary" onClick={onClick}>
                      <i class="fas fa-plus form-control-icon" /> Select Client
                    </span>
                  )}
                  ModalContent={props => (
                    <ReduxSelectGroupStudentModal
                      {...props}
                      GetAllSelectedStudents={this.getAllSelectedStudents}
                    />
                  )}
                />
                {errorMsg && <p className="text-danger mt-2">{errorMsg}</p>}
              </div>
              <div class="col-12 my-2" style={{ maxHeight: '125px', overflowY: 'auto' }}>
                {selectedStudent.length > 0 &&
                  selectedStudent.map(item => {
                    return (
                      <>
                        <div className="d-inline-flex mr-2">
                          <div
                            style={{
                              background: 'aliceblue',
                              borderRadius: '20px',
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar
                              // size="large"
                              className="mr-2"
                              // icon={<i class="fas fa-users"></i>}
                              src={item.ProfileImgUrl || _blank}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                {item.DisplayName}
                              </span>
                            </div>
                            <Tooltip placement="top" title="Remove">
                              <span
                                class="delete-course"
                                style={{ margin: '0px 0.5rem' }}
                                onClick={() => {
                                  this.clearSelection(item);
                                }}
                              >
                                <i class="fas fa-times" />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>

              {/* {selectedStudent && (
                <div className="col-12">
                  <>
                    <div className="col-8">
                      <div
                        style={{
                          background: 'aliceblue',
                          borderRadius: '20px',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          size="large"
                          className="mr-2"
                          // icon={<i class="fas fa-users"></i>}
                          src={selectedStudent.ProfileImageUrl}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontSize: '14px', fontWeight: '700' }}>
                            {selectedStudent.DisplayName}
                          </span>
                        </div>
                        <Tooltip placement="top" title="Remove">
                          <span class="delete-course" onClick={this.clearSelection}>
                            <i class="fas fa-times" />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                </div>
              )} */}
              <div class="col-9">
                {/* <div class="form-group">
                  <DatePicker
                    selected={this.state.dueDate}
                    onChange={this.handleChange}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}

                    // minDate={new Date()}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div> */}
                <div class="form-group call-schedule">
                  <ReduxSelect
                    options={dates}
                    onChange={this.handleDateChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={startDate}
                    placeholder="Start Date"
                  />
                  {isDateReq && <span className="error-text">Please Select Date</span>}
                </div>
              </div>
              <div class="col-9 ">
                <div class="form-group call-schedule">
                  <ReduxSelect
                    options={timeSlots}
                    onChange={this.handleTimeChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={timeSlot}
                    placeholder="Start Time"
                  />
                  {isTimeSlotReq && <span className="error-text">Please Select TimeSlot</span>}
                </div>
              </div>
              <div class="col-9">
                <div class="form-group call-schedule">
                  <ReduxSelect
                    options={durations}
                    onChange={this.handleDurationChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={duration}
                    placeholder="Duration"
                  />
                  {isDurationReq && <span className="error-text">Please Select Duration</span>}
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-9">
                <input
                  type="button"
                  class="btn"
                  value="Send Invite"
                  onClick={this.sendInviteGroupCall}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="card border-0">
            <div class="card-body center-tabs" style={{ minHeight: '200px' }}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Session</h4>
                <CustomModal
                  modalWidth={700}
                  ModalButton={({ onClick }) => (
                    <span className="btn btn-outline-primary px-4" onClick={onClick}>
                      Filter by
                    </span>
                  )}
                  ModalContent={props => (
                    <FilterModal {...props} GetStudentGui={this.getStudentGui} />
                  )}
                />
              </div>
              <Loader2 loading={isFetching} />

              {GroupCallSessionData ? (
                <div className="Activities call-session-tab">
                  <ListRenderer
                    list={GroupCallSessionList}
                    activeKey={tabActiveKey}
                    getActiveKey={tabActiveKey => this.setState({ tabActiveKey })}
                  />
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <h5 className="text-center mt-3">Group Call Record Empty</h5>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToPropsGroupSession = ({
  get_one_call_timeSlots,
  get_one_call_session,
  invite_one_call_session,
  cancel_one_call_session,
  // get_call_session_video_url,
}) => {
  const { data } = get_one_call_timeSlots;
  const { data: GroupCallSession, isFetching } = get_one_call_session;
  const { isFetching: isFetchingGroupCall } = invite_one_call_session;

  let dates = [];
  let timeSlots = [];
  let durations = [];
  let GroupCallSessionData = [];
  let isinviteGroupCallSession = false;
  let isCancelGroupCallSession = false;
  if (data && data.Advisor) {
    dates = data.Advisor[0].Dates || [];
    timeSlots = data.Advisor[0].TimeSlots || [];
    durations = data.Advisor[0].Duration || [];
  }
  if (GroupCallSession) {
    GroupCallSessionData = GroupCallSession.Advisor[0].GroupCall;
  }
  if (invite_one_call_session.data && invite_one_call_session.data) {
    isinviteGroupCallSession = invite_one_call_session.data.success;
  }
  if (cancel_one_call_session.data && cancel_one_call_session.data.success) {
    isCancelGroupCallSession = cancel_one_call_session.data.success;
  }

  return {
    isFetching: isFetching,
    // isFetchingGroupCall,
    dates,
    timeSlots,
    durations,
    GroupCallSessionData,
    isinviteGroupCallSession,
    isCancelGroupCallSession,
  };
};
const ReduxGroupSession = connect(mapStateToPropsGroupSession)(withRouter(GroupSession));

// One Call Session Area

class OneonOneSessionBlock extends Component {
  constructor(props) {
    super(props);
  }

  cancelOneCall = CallSessionId => {
    let data = {
      CallSession: [
        {
          Client: [
            {
              VMCallSessionId: CallSessionId,
            },
          ],
        },
      ],
    };
    var cancelData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(cancelOneCallSession(cancelData));
  };
  fnGetVideoUrl = VMCallId => {
    this.props.dispatch(
      getCallSessionVideoUrl({
        VMCallId: VMCallId,
      }),
    );
  };
  render() {
    const callStatus = {
      '0': {
        color: '#ff3b3b',
        // hideButtons: true,
        // date: UnassignDate,
        // name: 'Unassigned On',
      },
      '-1': {
        color: '#fa8602',
        // hideButtons: false,
        // date: DueDate,
        // name: 'To be Finished',
        // dateFontColor: DueDateFontColor,
      },
      '1': {
        color: '#12c475',
        // hideButtons: true,
        // date: CompletedDate,
        // name: 'Completed On',
      },
    };
    const { Data } = this.props;
    const CallStatusMessageArr = Data.CallStatusMessage.split(',');
    const MeetingTimeTextArr = Data.MeetingTimeText.split(',');
    const MeetingTimeValueArr = Data.MeetingTimeValue.split(',');

    return (
      <>
        <div
          class="course-box"
          style={{
            backgroundColor: '#f8f8f8',
            paddingLeft: '10px',
            paddingRight: '10px',
            // paddingTop: '10px',
            marginBottom: '15px',
          }}
        >
          <div class="row no-gutters w-100 justify-content-between align-items-center px-3">
            <div class="row no-gutters w-100 align-items-start justify-content-between">
              <div className="">
                <div className="d-flex align-items-start">
                  <Avatar size="large" className="mr-2" src={Data.ProfileImageUrl || _blank} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      style={{
                        fontSize: '16px',
                        // fontWeight: '700'
                      }}
                    >
                      {Data.DisplayName}
                    </span>
                    <span style={{ fontSize: '14px', color: '#828282' }}>{Data.CallDate}</span>
                  </div>
                </div>
              </div>
              <div className="">
                <span
                  style={{
                    fontSize: '16px',
                    // fontWeight: '600',
                    color: CallStatusMessageArr[1] || 'orange',
                  }}
                >
                  {CallStatusMessageArr[2]}
                </span>
              </div>
            </div>
            <div class="course-info w-100">
              {/* <span
                style={{ fontSize: '15px', color: '#007bff', display: 'block', margin: '10px' }}
              >
                {'4 people invited'}
              </span>{' '}
              <span style={{ fontSize: '14px', fontWeight: '700', textAlign: 'justify' }}>
                Mathilda Hampton, Frederick Snyder,Margret ShawLaura Moreno
              </span> */}

              <div class="row no-gutters mt-2 w-100 align-items-center justify-content-between">
                <div>
                  <div className="d-flex mt-1 flex-column align-items-start">
                    {MeetingTimeTextArr.map((item, index) => {
                      return (
                        <>
                          <div
                            class="enroll-date course-date mb-1 d-flex"
                            // style={{ maxWidth: '145px', width: '135px' }}
                            style={{ width: 'unset', maxWidth: 'unset' }}
                          >
                            {MeetingTimeTextArr[index]}
                            <em> : {MeetingTimeValueArr[index]}</em>
                          </div>
                        </>
                      );
                    })}

                    {/* {Data.Duration && (
                    <div
                      class="Finish-date course-date mb-1 d-inline-block"
                      style={{ width: 'unset' }}
                    >
                      {MeetingTimeTextArr[2]}
                      <em class="date" style={{ fontWeight: 'bold' }}>
                        {MeetingTimeValueArr[2]}
                      </em>
                    </div>
                  )} */}
                  </div>
                </div>
                {/* <div
                  class="enroll-date course-date mb-1"
                  style={{ maxWidth: '135px', width: '130px' }}
                >
                  Meeting Time{' '}
                  <em class="date" style={{ fontWeight: 'bold' }}>
                    {Data.SlotTime}
                  </em>
                </div> */}
                {/* <div class="Finish-date course-date mb-1 col-12 col-md-6">
                  Duration
                  {courseStatus[CourseStatus].name }
                  <em class="date">{'40 min'}</em>
                </div> */}
                <div class="actions-button justify-content-xl-end">
                  {
                    <>
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <div class="modal-section">
                            <span
                              style={{
                                textAlign: 'right',
                                fontSize: '14px',
                                fontWeight: '600',
                                color: 'orange',
                                cursor: Data.LinkURL && 'pointer',
                              }}
                              onClick={() => {
                                Data.LinkURL && Data.LinkURL != '1'
                                  ? window.open(Data.LinkURL)
                                  : onClick();
                              }}
                            >
                              {Data.LinkMessage}
                            </span>
                          </div>
                        )}
                        ModalContent={props => (
                          <VideoModalContent
                            VMCallId={Data.VMCallID}
                            Title={Data.MeetingTitle}
                            CallDate={Data.CallDate}
                            {...props}
                          />
                        )}
                      />
                      {CallStatusMessageArr[0] == '1' && (
                        <>
                          <CustomModal
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title="Cancel">
                                <span class="delete-course" onClick={onClick}>
                                  <i class="fas fa-times" />
                                </span>
                              </Tooltip>
                            )}
                            ModalContent={({ onOk, onCancel }) => (
                              <div class="modal-content border-0">
                                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    &times;
                                  </button>
                                  <h4 class="modal-title col-12">
                                    Are you sure you want to cancel?
                                  </h4>
                                </div>

                                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                  <button
                                    type="button"
                                    class="btn black-btn"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                    style={{ background: '#36cbfea6' }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn "
                                    onClick={() => {
                                      onOk();
                                      this.cancelOneCall(Data.VMCallSessionId);
                                    }}
                                  >
                                    Ok
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                          <CustomModal
                            modalWidth={700}
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title="Add/Remove">
                                <span class="select-course" onClick={onClick}>
                                  <i class="fas fa-pencil-alt" />
                                </span>
                              </Tooltip>
                            )}
                            ModalContent={props => (
                              <EditOneonOneSessionModal
                                VMCallID={Data.VMCallID}
                                UserGuid={Data.UserGuid}
                                {...props}
                              />
                            )}
                          />
                        </>
                      )}
                      {/* <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Edit">
                            <span class="select-course" onClick={onClick}>
                              <i class="select-icon" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 class="modal-title col-12">Are you sure you want to Complete?</h4>
                            </div>

                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                class="btn black-btn"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="btn "
                                onClick={() => {
                                  onOk();
                                  // this.onMarkComplete(StudentId, CourseCenterStudentId);
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                      /> */}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class EditOneonOneSessionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      timeSlot: '',
      duration: '',
    };
  }

  // onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  componentDidMount() {
    // this.props.dispatch(editGroupCallSession({ VMCallID: this.props.VMCallID }));
  }
  handleDateChange = startDate => {
    this.setState({ startDate, isDateReq: false });
  };
  handleTimeChange = timeSlot => {
    this.setState({ timeSlot, isTimeSlotReq: false });
  };
  handleDurationChange = duration => {
    this.setState({ duration, isDurationReq: false });
  };
  OneOnOneSessionCall = () => {
    const { startDate, timeSlot, duration } = this.state;
    let data = {
      CallSession: [
        {
          Client: [
            {
              UserGuid: this.props.UserGuid,
              VMCallID: this.props.VMCallID,
            },
          ],
          TimeSlots: [
            {
              RequestForDate: stringDate(startDate),
              TimeSlotId: timeSlot,
              Duration: duration,
              IsGroupCall: 0,
            },
          ],
        },
      ],
    };
    let callData = {
      Json: JSON.stringify(data),
    };

    if (!startDate || !timeSlot || !duration) {
      if (!startDate) {
        this.setState({
          isDateReq: true,
        });
      } else if (!timeSlot) {
        this.setState({
          isTimeSlotReq: true,
        });
      } else if (!duration) {
        this.setState({
          isDurationReq: true,
        });
      }
    } else {
      this.props.dispatch(inviteOneCallSession(callData));
      this.props.onOk();
    }
  };
  render() {
    const { onOk, onCancel, isFetching, dates, timeSlots, durations } = this.props;
    const { startDate, timeSlot, duration, isDateReq, isTimeSlotReq, isDurationReq } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Edit an Invitation</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0 enrollment-form ">
            <div class="col">
              <div class="form-group call-schedule">
                <ReduxSelect
                  options={dates}
                  onChange={this.handleDateChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={startDate}
                  placeholder="Select Date"
                />
                {isDateReq && <span className="error-text">Please Select Date</span>}
              </div>
            </div>
            <div class="col">
              <div class="form-group call-schedule">
                <ReduxSelect
                  options={timeSlots}
                  onChange={this.handleTimeChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={timeSlot}
                  placeholder="Start Time"
                  style={{ paddingLeft: '0px' }}
                />
                {isTimeSlotReq && <span className="error-text">Please Select TimeSlot</span>}
              </div>
            </div>
            <div class="col">
              <div class="form-group call-schedule">
                <ReduxSelect
                  options={durations}
                  onChange={this.handleDurationChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={duration}
                  placeholder="Duration"
                />
                {isDurationReq && <span className="error-text">Please Select Duration</span>}
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <input onClick={onCancel} type="button" class="btn mx-2" value="Cancel" />
          <input type="button" class="btn mx-2" value="Save" onClick={this.OneOnOneSessionCall} />
        </div>
      </div>
    );
  }
}
const mapStateToPropsOneStudentEdit = ({ get_one_call_timeSlots }) => {
  const { data } = get_one_call_timeSlots;
  let dates = [];
  let timeSlots = [];
  let durations = [];
  if (data && data.Advisor) {
    dates = data.Advisor[0].Dates || [];
    timeSlots = data.Advisor[0].TimeSlots || [];
    durations = data.Advisor[0].Duration || [];
  }
  return {
    dates,
    timeSlots,
    durations,
  };
};
EditOneonOneSessionModal = connect(mapStateToPropsOneStudentEdit)(EditOneonOneSessionModal);

class SelectOneStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      searchStr: '',
      selectedStudent: '',
      searchedStudent: [],
    };
  }

  // onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  handelGui = () => {
    this.props.GetStudentGui(this.state.selectedStudent);
  };
  componentDidMount() {
    // this.props.dispatch(getAllVacation({}));
    //Stopping the api call because it is throwing error while calling from here.
    // this.props.dispatch(appSubscribersUserSearch({}));
    this.input.focus();
  }
  componentWillReceiveProps({ searchedStudent }) {
    if (
      searchedStudent &&
      JSON.stringify(searchedStudent) != JSON.stringify(this.props.searchedStudent)
    ) {
      this.setState({ searchedStudent });

    }
  }

  // addBlockDays = value => {
  //   this.props.dispatch(
  //     addVacationDays({
  //       StartDate: value.startDate,
  //       EndDate: value.endDate,
  //       Reason: value.reason,
  //     }),
  //   );
  //   // this.props.onOk();
  // };
  getSerachedStudent = () => {
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  render() {
    const { onOk, onCancel, isFetching } = this.props;
    const { searchStr, searchedStudent } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Select Client</h4>
        </div>
        <div class="modal-body">
          <div class="search-box w-100">
            <div class="form-group has-search">
              <input
                type="text"
                value={searchStr}
                class="form-control shadow-sm"
                placeholder="Search"
                onChange={this.studentSearch}
                ref={ref => (this.input = ref)}
              />
            </div>
          </div>
          <div class="row m-0">
            {
              <div
                class="col-12 col-md-12 enrollment-form"
                style={{ height: '200px', overflowY: 'auto' }}
              >
                <Loader2 loading={isFetching} />
                {searchedStudent.length > 0 ? (
                  searchedStudent.map(item => {
                    return (
                      <div className="col-6 d-inline-block">
                        <div
                          className="d-flex justify-content-between m-1 align-items-center"
                          style={{
                            boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.06)',
                            paddingBottom: '10px',
                            marginBottom: '5px',
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <Avatar
                              size="large"
                              className="mr-2"
                              // icon={<i class="fas fa-users"></i>}
                              // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                              src={item.ProfileImgUrl || _blank}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                {item.DisplayName}
                              </span>
                              {/* <span style={{ fontSize: '12px', color: 'gray' }}>March 25, 2020</span> */}
                            </div>
                          </div>
                          <div>
                            <Checkbox
                              checked={this.state.selected === item.UserGuid}
                              onChange={() => {
                                this.setState({
                                  selected: item.UserGuid,
                                  selectedStudent: item,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="col-12">
                      <h5
                        className="text-center"
                        onClick={() => {
                          this.props.dispatch(appSubscribersUserSearch({}));
                        }}
                        style={{
                          cursor: 'pointer',
                          color: 'blue',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        Reload &nbsp;
                        <RedoOutlined />
                      </h5>
                    </div>
                  </>
                )}
              </div>
            }
          </div>
        </div>

        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <input onClick={onCancel} type="button" class="btn mx-2" value="Cancel" />
          <input
            type="button"
            class="btn mx-2"
            value="Save"
            onClick={() => {
              this.handelGui();
              onOk();
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToPropsOneStudent = ({ app_subscribers_user_search }) => {
  let searchedStudent = [];
  let searchStr;
  const { data, isFetching } = app_subscribers_user_search;
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    // searchedStudent = Object.values(data.data) || [];
    // searchStr = data.searchStr || '';
    if (app_subscribers_user_search.data && app_subscribers_user_search.data.searchStr) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else {
      let resObj;
      if ('searchStr' in data) {
        resObj = Object.values(data.data);
      } else {
        resObj = Object.values(data);
      }
      searchedStudent = resObj || [];
    }
  }
  return {
    searchedStudent,
    isFetching,
    searchStr,
  };
};
const ReduxSelectOneStudentModal = connect(mapStateToPropsOneStudent)(SelectOneStudentModal);

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      searchStr: '',
      selectedStudent: [],
      searchedStudent: [],
      startDate: null,
      endDate: null,
      filterRadio: 'DESC',
      showCal: 'false',
    };
  }

  // onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  handelGui = () => {
    this.props.GetStudentGui(this.state.selectedStudent);
  };
  handelFilter = () => {
    const { selected, startDate, endDate, filterRadio } = this.state;
    let groupCallGuid = [];
    selected.length > 0 &&
      selected.map(item => {
        groupCallGuid.push({ UserGuid: item.UserGuid });
      });
    let data = {
      CallSession: [
        {
          Client: groupCallGuid,
          FilterBy: [
            {
              StartDate: stringDate(startDate),
              EndDate: stringDate(endDate),
              OrderByDate: filterRadio,
            },
          ],
        },
      ],
    };
    let callData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(getOneCallSession(callData));
  };
  componentDidMount() {
    // this.props.dispatch(getAllVacation({}));
    this.props.dispatch(appSubscribersUserSearch({}));
    // this.input.focus();
  }
  componentWillReceiveProps({ searchedStudent }) {
    if (searchedStudent && searchedStudent != this.props.searchedStudent) {
      this.setState({ searchedStudent });
    }
  }
  handleChange = (key, date) => {
    this.setState({
      startDate: date[0],
      endDate: date[1],
    });
  };
  radioHandel = e => {
    // let val = e.target.value === 'range' ? null : e.target.value;
    let val = e.target.value;
    // let bool = e.target.value === 'range' ? false : true;
    this.setState({
      filterRadio: val,
      // showCal: bool,
    });
  };
  getSerachedStudent = () => {
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  onCheckBoxChange(e, item) {
    // current array of selected
    let selectedItem = this.state.selected;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to selected array
      selectedItem.push(item);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = selectedItem.indexOf(item);
      selectedItem.splice(index, 1);
    }
    // update the state with the new array of selected
    this.setState({ selected: selectedItem });
  }
  render() {
    const { onOk, onCancel, isFetching } = this.props;
    const { searchStr, searchedStudent } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div className="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold d-flex ">Date</span>
            </div>
            <div class="col-12">
              <div class="row no-gutters">
                <div className="col-5">
                  <div class="form-group custom-radio d-flex">
                    {/* <label>
                      <input
                        name="filterRadio"
                        onChange={this.radioHandel}
                        type="radio"
                        value="range"
                      />
                      <span className="checkmark"></span>
                    </label> */}
                    <RangePicker
                      onChange={this.handleChange}
                      className="range-picker"
                      format={'YYYY/MM/DD'}
                      // disabled={this.state.showCal}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div class="form-group custom-radio text-center">
                    <label className="">
                      Ascending
                      <input
                        name="filterRadio"
                        type="radio"
                        onChange={this.radioHandel}
                        value="ASC"
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <div class="form-group custom-radio text-center">
                    <label className="">
                      Descending
                      <input
                        checked
                        name="filterRadio"
                        onChange={this.radioHandel}
                        type="radio"
                        value="DESC"
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold d-flex ">Client(s)</span>
            </div>
            {
              <div
                class="col-12 col-md-12 enrollment-form"
                style={{ height: '200px', overflowY: 'auto' }}
              >
                <Loader2 loading={isFetching} />
                {searchedStudent && searchedStudent.length > 0 ? (
                  searchedStudent &&
                  searchedStudent.map((item, index) => {
                    return (
                      <div className="col-6 d-inline-block">
                        <div
                          className="d-flex justify-content-between m-1 align-items-center"
                          style={{
                            boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.06)',
                            paddingBottom: '10px',
                            marginBottom: '5px',
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <Avatar
                              size="large"
                              className="mr-2"
                              src={item.ProfileImgUrl || _blank}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                {item.DisplayName}
                              </span>
                              {/* <span style={{ fontSize: '12px', color: 'gray' }}>March 25, 2020</span> */}
                            </div>
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                this.onCheckBoxChange(e, item);
                              }}
                              value={index}
                              // checked={this.state.selected === item.UserGuid}
                              // onChange={() => {
                              //   this.setState({
                              //     selected: item.UserGuid,
                              //     selectedStudent: item,
                              //   });
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="col-12">
                      <h5 className="text-center">No Record </h5>
                    </div>
                  </>
                )}
              </div>
            }
          </div>
        </div>

        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <input
            onClick={onCancel}
            type="button"
            class="btn btn-outline-primary mx-2"
            value="Cancel"
          />
          <input
            type="button"
            class="btn mx-2"
            value="Apply"
            onClick={() => {
              this.handelFilter();
              onOk();
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToPropsFilter = ({ app_subscribers_user_search }) => {
  let searchedStudent = [];
  let searchStr;
  const { data, isFetching } = app_subscribers_user_search;
  if (data) {
    if (app_subscribers_user_search.data.searchStr) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else if (app_subscribers_user_search.data && app_subscribers_user_search.data) {
      searchedStudent = Object.values(data) || [];
    }
  }
  return {
    searchedStudent,
    isFetching,
    searchStr,
  };
};
FilterModal = connect(mapStateToPropsFilter)(FilterModal);

class OneonOneSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStudent: '',
      startDate: '',
      timeSlot: '',
      duration: '',
      errorMsg: '',
    };
  }
  // shouldComponentUpdate(props) {
  //   if (
  //     JSON.stringify(props.OnecallSessionData) != JSON.stringify(this.props.OnecallSessionData) ||
  //     props.dates != this.props.dates ||
  //     props.durations != this.props.durations ||
  //     props.timeSlots != this.props.timeSlots ||
  //     props.isCancelOneCallSession != this.props.isCancelOneCallSession ||
  //     props.isFetching != this.props.isFetching ||
  //     props.isFetchingOneCall != this.props.isFetchingOneCall ||
  //     props.isinviteCallSession != this.props.isinviteCallSession ||
  //     props.isinviteOneCallSession != this.props.isinviteOneCallSession ||
  //     props.successMsg != this.props.successMsg ||
  //     props.VideoCallUrl != this.props.VideoCallUrl
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }

  // componentDidMount() {
  //   this.props.dispatch(getOneCallTimeSlots({}));
  //   this.props.dispatch(getOneCallSession({}));
  // }

  componentWillReceiveProps({ isinviteOneCallSession, isCancelOneCallSession }) {
    if (isinviteOneCallSession && isinviteOneCallSession != this.props.isinviteOneCallSession) {
      // success();
      // this.props.dispatch(resetinviteOneCallSession());
      this.setState({
        startDate: '',
        timeSlot: '',
        selectedStudent: '',
        duration: '',
        errorMsg: '',
      });
      this.props.dispatch(getOneCallSession({}));
      // this.props.dispatch(
      //   getOneCallSession({
      //     Json: JSON.stringify({
      //       CallSession: [{ Client: [{ IsGroupCall: '0' }] }],
      //     }),
      //   }),
      // );
    }
    if (isCancelOneCallSession) {
      this.props.dispatch(resetCancelOneCallSession());
      this.props.dispatch(getOneCallSession({}));
      // this.props.dispatch(
      //   getOneCallSession({
      //     Json: JSON.stringify({
      //       CallSession: [{ Client: [{ IsGroupCall: '0' }] }],
      //     }),
      //   }),
      // );
    }
  }
  handleDateChange = startDate => {
    this.setState({ startDate, isDateReq: false });
  };
  handleTimeChange = timeSlot => {
    this.setState({ timeSlot, isTimeSlotReq: false });
  };
  handleDurationChange = duration => {
    this.setState({ duration, isDurationReq: false });
  };
  getStudentGui = selectedStudent => {
    // this.state.selectedStudent = selectedStudent;
    this.setState({ selectedStudent, errorMsg: '' });
  };
  sendInviteOneCall = () => {
    const { selectedStudent, startDate, timeSlot, duration } = this.state;
    let data = {
      CallSession: [
        {
          Client: [
            {
              UserGuid: this.state.selectedStudent.UserGuid,
            },
          ],
          TimeSlots: [
            {
              RequestForDate: stringDate(this.state.startDate),
              TimeSlotId: this.state.timeSlot,
              Duration: this.state.duration,
              IsGroupCall: 0,
            },
          ],
        },
      ],
    };
    // let callData = JSON.stringify(data);
    var callData = {
      Json: JSON.stringify(data),
    };
    if (!selectedStudent || !startDate || !timeSlot || !duration) {
      if (!selectedStudent) {
        this.setState({
          errorMsg: 'Please Select Client',
        });
      } else if (!startDate) {
        this.setState({
          isDateReq: true,
        });
      } else if (!timeSlot) {
        this.setState({
          isTimeSlotReq: true,
        });
      } else if (!duration) {
        this.setState({
          isDurationReq: true,
        });
      }
    } else {
      this.props.dispatch(inviteOneCallSession(callData));
    }
    // this.props.dispatch(inviteOneCallSession(callData));
  };
  clearSelection = () => {
    this.setState({ selectedStudent: '' });
  };
  render() {
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Select Date"
        value={value}
        onClick={onClick}
      />
    );
    const {
      isFetching,
      dates,
      timeSlots,
      durations,
      OnecallSessionData,
      isFetchingOneCall,
    } = this.props;
    const {
      startDate,
      timeSlot,
      duration,
      selectedStudent,
      errorMsg,
      isDateReq,
      isTimeSlotReq,
      isDurationReq,
    } = this.state;
    // OnecallSessionList For One to one call
    const OnecallSessionList = [
      {
        Name: 'Upcoming',
        Component: props => {
          const Data = { ...props };
          if (Data.VMCallID) {
            return (
              <div class="col-12">
                <OneonOneSessionBlock {...this.props} Data={Data} />
              </div>
            );
          } else
            return (
              <div class="col-12 text-center">
                <h5>No Data</h5>
              </div>
            );
        },
        Content: (OnecallSessionData.length > 0 && OnecallSessionData[0].Upcoming) || [{}],
        // Content: [{}],
      },
      {
        Name: 'Canceled/No Shows',
        Component: props => {
          const Data = { ...props };
          if (Data.VMCallID) {
            return (
              <div class="col-12">
                <OneonOneSessionBlock {...this.props} Data={Data} />
              </div>
            );
          } else
            return (
              <div class="col-12 text-center">
                <h5>No Data</h5>
              </div>
            );
        },
        Content: (OnecallSessionData.length > 0 && OnecallSessionData[0].Cancelled) || [{}],
      },
      {
        Name: 'Completed',
        Component: props => {
          const Data = { ...props };
          if (Data.VMCallID) {
            return (
              <div class="col-12">
                <OneonOneSessionBlock {...this.props} Data={Data} />
              </div>
            );
          } else
            return (
              <div class="col-12 text-center">
                <h5>No Data</h5>
              </div>
            );
        },
        Content: (OnecallSessionData.length > 0 && OnecallSessionData[0].Completed) || [{}],
      },
    ];
    return (
      <div class="row mb-5">
        <div class="col-lg-5">
          {/* <Loader2 loading={isFetchingOneCall} /> */}
          <div class="card border-0 ">
            <div class="row card-body enrollment-form ">
              <div class="col-12 text-left mb-3">
                <h4>Create an Invitation</h4>
              </div>
              <div class="col-12 col-md-8 col-lg-9 mb-3">
                {/* <button type="button" class="btn btn-outline-primary mx-2" value="Select Student">
                  <i class="fas fa-plus form-control-icon" /> Select Student
                </button> */}
                {selectedStudent ? (
                  <>
                    <div className="d-flex">
                      <div
                        style={{
                          background: 'aliceblue',
                          borderRadius: '20px',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          size="large"
                          className="mr-2"
                          // icon={<i class="fas fa-users"></i>}
                          src={selectedStudent.ProfileImgUrl || _blank}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontSize: '14px', fontWeight: '700' }}>
                            {selectedStudent.DisplayName}
                          </span>
                        </div>
                        <Tooltip placement="top" title="Remove">
                          <span
                            class="delete-course "
                            style={{ margin: '0px 0.5rem' }}
                            onClick={this.clearSelection}
                          >
                            <i class="fas fa-times" />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                ) : (
                  <CustomModal
                    ModalButton={({ onClick }) => (
                        <span className="btn btn-outline-primary" onClick={() => { 
                           this.props.dispatch(appSubscribersUserSearch({}));
                           onClick();
                        }}>
                        <i class="fas fa-plus form-control-icon" /> Select Client
                      </span>
                    )}
                    ModalContent={props => (
                      <ReduxSelectOneStudentModal {...props} GetStudentGui={this.getStudentGui} />
                    )}
                  />
                )}
                {errorMsg && <p className="text-danger mt-2">{errorMsg}</p>}
              </div>
              {/* <div class="col-9">
                <div class="form-group">
                  <DatePicker
                    selected={this.state.dueDate}
                    onChange={this.handleChange}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                    // minDate={new Date()}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div> */}
              <div class="col-9">
                <div class="form-group call-schedule">
                  <ReduxSelect
                    options={dates}
                    onChange={this.handleDateChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={startDate}
                    placeholder="Select Date"
                  />
                  {isDateReq && <span className="error-text">Please Select Date</span>}
                </div>
              </div>
              <div class="col-9">
                <div class="form-group call-schedule">
                  <ReduxSelect
                    options={timeSlots}
                    onChange={this.handleTimeChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={timeSlot}
                    placeholder="Start Time"
                    style={{ paddingLeft: '0px' }}
                  />
                  {isTimeSlotReq && <span className="error-text">Please Select TimeSlot</span>}
                </div>
              </div>
              <div class="col-9">
                <div class="form-group call-schedule">
                  <ReduxSelect
                    options={durations}
                    onChange={this.handleDurationChange}
                    className="basic-single"
                    classNamePrefix="select"
                    value={duration}
                    placeholder="Duration"
                  />
                  {isDurationReq && <span className="error-text">Please Select Duration</span>}
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-9">
                <input
                  type="button"
                  class="btn"
                  value="Send Invite"
                  onClick={this.sendInviteOneCall}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="card border-0">
            <div class="card-body center-tabs">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="mb-0">Session</h4>
                <CustomModal
                  modalWidth={700}
                  ModalButton={({ onClick }) => (
                    <span className="btn btn-outline-primary px-4" onClick={onClick}>
                      Filter by
                    </span>
                  )}
                  ModalContent={props => (
                    <FilterModal {...props} GetStudentGui={this.getStudentGui} />
                  )}
                />
              </div>

              <Loader2 loading={isFetching} />

              {OnecallSessionData ? (
                <div className="Activities call-session-tab">
                  <ListRenderer
                    list={OnecallSessionList}
                    activeKey={tabActiveKey}
                    getActiveKey={tabActiveKey => this.setState({ tabActiveKey })}
                  />
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <h5 className="text-center mt-3">No Sessions Scheduled</h5>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToPropsOneSession = ({
  get_one_call_timeSlots,
  get_one_call_session,
  invite_one_call_session,
  cancel_one_call_session,
}) => {
  const { data } = get_one_call_timeSlots;
  const { data: callSession, isFetching } = get_one_call_session;
  const { isFetching: isFetchingOneCall } = invite_one_call_session;
  let dates = [];
  let timeSlots = [];
  let durations = [];
  let OnecallSessionData = [];
  let isinviteOneCallSession = false;
  let isCancelOneCallSession = false;
  if (data && data.Advisor) {
    dates = data.Advisor[0].Dates || [];
    timeSlots = data.Advisor[0].TimeSlots || [];
    durations = data.Advisor[0].Duration || [];
  }
  if (callSession) {
    OnecallSessionData = callSession.Advisor[0].OneCall;
  }
  if (invite_one_call_session.data && invite_one_call_session.data) {
    isinviteOneCallSession = invite_one_call_session.data.success;
  }
  if (cancel_one_call_session.data && cancel_one_call_session.data.success) {
    isCancelOneCallSession = cancel_one_call_session.data.success;
  }
  return {
    isFetching: isFetching,
    dates,
    isFetchingOneCall,
    timeSlots,
    durations,
    OnecallSessionData,
    isinviteOneCallSession,
    isCancelOneCallSession,
  };
};
export const OneonOneSessionRedux = connect(mapStateToPropsOneSession)(withRouter(OneonOneSession));

class CallSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuccessModal: false,
      successMsg: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(getOneCallTimeSlots({}));
    this.props.dispatch(getOneCallSession({}));
  }
  componentWillReceiveProps({
    isinviteCallSession,
    isCancelGroupCallSession,
    successMsg,
    VideoCallUrl,
  }) {
    if (isinviteCallSession && isinviteCallSession != this.props.isinviteCallSession) {
      this.setState({ isSuccessModal: true, successMsg });
      this.props.dispatch(resetinviteOneCallSession());
      this.props.dispatch(getOneCallSession({}));
    }
  }
  toggle = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };
  render() {
    const list = [
      {
        Name: <span className="border-image">One-on-One Sessions</span>,
        ExtraComponent: () => <OneonOneSessionRedux {...this.props} />,
        noContent: true,
      },
      {
        Name: <span className="border-image">Group Sessions</span>,
        ExtraComponent: () => <ReduxGroupSession {...this.props} />,
        noContent: true,
      },
    ];
    const xyz = {
      '0': '1',
      '1': '0',
    };
    return (
      <>
        {/* <Loader loading={loading} /> */}
        {/* <div className="container-fluid">
          <div className="row top-table-search mt-5 mb-3 align-items-center">
            <div className="col-12">
              <Link to="/manage" className="back-link">
                <i className="fas fa-arrow-left" /> Back
              </Link>
              <h3 className="page-title mb-3 mb-md-0">Call Schedule</h3>
            </div>
          </div>

        </div> */}
        {/* Json: JSON.stringify({
                      CallSession: [{ Client: [{ IsGroupCall: xyz[activeKey] }] }],
                    }), */}

        <div className="row">
          <div className="col-12 user-role-tabs call-schedule-tab">
            <ListRenderer
              list={list}
              activeKey={activeKey}
              getActiveKey={activeKey => this.setState({ activeKey })}
            />
            {/* <Notes {...this.props} isAddNodeType={isAddNodeType} /> */}
          </div>
          <CoreModal
            isOpen={this.state.isSuccessModal}
            header={this.state.successMsg}
            yesAction={() => {
              this.toggle('isSuccessModal');
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ invite_one_call_session, get_call_session_video_url }) => {
  let isinviteCallSession = false;
  // let VideoCallUrl = '';
  let successMsg = '';
  if (invite_one_call_session.data && invite_one_call_session.data) {
    isinviteCallSession = invite_one_call_session.data.success;
    successMsg = invite_one_call_session.data.result;
  }
  // if (get_call_session_video_url && get_call_session_video_url.data) {
  //   VideoCallUrl = get_call_session_video_url.data.result;
  // }
  return {
    isinviteCallSession,
    successMsg,
    // VideoCallUrl,
  };
};
export default connect(mapStateToProps)(withRouter(CallSchedule));
