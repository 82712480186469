import React, { useState, useEffect } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Table, Button, Modal, Tooltip } from 'antd';
import {
  smartContractDistributionGet,
  listCombos,
  distributionPaymentUpsert,
  resetDistributionPaymentUpsert,
} from '../../../../../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../../util/handler';
import { currencyFormatter, numberFormat } from '../../../../../../../util/formatter';
import _blank from '../../../../../../../images/blank-profile.png';
import { ProfilePicComponent } from '../../../../../../../Components/profilePicComponent';
import Loader, { Loader2 } from '../../../../../../../Components/Loader';
import { AssignPaymentStatus } from './assignPaymentStatus';
import CustomModal from '../../../../../../../core/modal';
import SubmitContract from '../submitContract';

const Distribution = props => {
  const { smartContractId, clientId } = props;
  const dispatch = useDispatch();
  const [distributionList, setDistributionList] = useState();
  const [header, setHeader] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const {
    smartContractDistributionList,
    dataFetching,
    paymentStatusCombo,
    isPaymentStatusUpdate,
    smartContractDetails,
  } = useSelector(
    ({
      smart_contract_distribution_get: { data: distributionData, isFetching },
      combos,
      distribution_payment_upsert,
      smart_contract_get,
    }) => {
      return {
        smartContractDistributionList: distributionData && distributionData.SmartContract,
        dataFetching: isFetching,
        paymentStatusCombo: combos.data.PaymentStatus,
        isPaymentStatusUpdate:
          distribution_payment_upsert.data && distribution_payment_upsert.data.success,
        smartContractDetails: smart_contract_get.data && smart_contract_get.data.SmartContract[0],
      };
    },
    shallowEqual,
  );
  
    const toggle = header => {
      setIsModelOpen(!isModelOpen);
      setHeader(header);
  };
  
  useEffect(() => {
    let Data = {
      JSON: JSON.stringify({
        // SmartContract: [{ ClientId: clientId, SmartContractId: 685 }],
        SmartContract: [{ ClientId: clientId, SmartContractId: smartContractId }],
      }),
    };

    dispatch(smartContractDistributionGet(Data));
    dispatch(
      listCombos({
        comboTypes: 'PaymentStatus',
      }),
    );
    return () => { 
      dispatch(resetDistributionPaymentUpsert({}));
    }
  }, []);
  useEffect(() => {
    if (smartContractDistributionList) {
      setDistributionList(smartContractDistributionList);
    }
  }, [smartContractDistributionList]);

  const handlePaymentStatusChange = (value, recordData, rowIndex) => {
    let data = {
      SmartContract: [
        {
          SmartContractServiceCodeLaborId: recordData.SmartContractServiceCodeLaborId,
          PaymentStatusId: value,
        },
      ],
    };
    let Data = {
      Json: JSON.stringify(data),
    };
    dispatch(distributionPaymentUpsert(Data));
  };

    useEffect(() => {
      if (isPaymentStatusUpdate) {
        toggle('Status Updated successfully.');
      }
    }, [isPaymentStatusUpdate]);

  const Columns = [
    {
      title: 'Care Team Member',
      dataIndex: 'FullName',
      key: 'FullName',
      sorter: (a, b) => isComparer(a.FullName, b.FullName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return (
          <>
            <div className="d-flex justify-content-start align-items-center">
              <ProfilePicComponent src={record.ImageUrl || _blank} alt={record.altText} /> &nbsp;
              {text}
            </div>
          </>
        );
      },
    },
    // {
    //   title: 'Company Name',
    //   dataIndex: 'ServiceType',
    //   key: 'ServiceType',
    //   sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Service Code',
      dataIndex: 'ProvideService',
      key: 'ProvideService',
      sorter: (a, b) => isComparer(a.ProvideService, b.ProvideService),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return <div className="">{text}</div>;
      },
    },
    {
      title: 'Service Provided On',
      dataIndex: 'ServiceProvidedOn',
      key: 'ServiceProvidedOn',
      sorter: (a, b) => isDateComparer(a.ServiceProvidedOnForSort, b.ServiceProvidedOnForSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Remittance',
      dataIndex: 'Amount',
      key: 'Amount',
      sorter: (a, b) => isNumericComparer(a.Amount, b.Amount),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return <div className="text-right">{currencyFormatter.format(text || 0)}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      // sorter: (a, b) => isComparer(a.Status, b.Status),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return (
          <>
            {/* <span className="contract-status">
              <span className={text == 'Paid' ? 'delivered' : 'terminated'}>{text}</span>
            </span> */}
            <AssignPaymentStatus
              recordData={record}
              paymentStatusCombo={paymentStatusCombo}
              rowIndex={index}
              onChange={handlePaymentStatusChange}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <div style={dataFetching ? { height: '200px', position: 'relative' } : { height: '0px' }}>
        <Loader2 loading={dataFetching} />
      </div>
      {!dataFetching && (
        <div className="assign-care-team p-3">
          <span className="label mb-2">Distribution Details</span>
          <div className="row">
            <div className="col-12">
              <Table columns={Columns} dataSource={distributionList} pagination={false} />
            </div>
            <div className="col-12 text-right mt-4 d-flex align-items-center justify-content-end">
              <Button
                type="primary"
                onClick={() => {
                  props.goToPreviuos();
                }}
                className={`btn btn-outline-primary mr-2`}
                style={{ height: '42px' }}
              >
                Previous
              </Button>
              {smartContractDetails &&
              smartContractDetails.Bundle && smartContractDetails.Bundle.every(
                item => item.IsTermsConditionAvailableForPayer,
              ) ? (
                <SubmitContract smartContractId={smartContractId} clientId={clientId} />
              ) : (
                <>
                  <Tooltip
                    placement="top"
                    title={
                      <>
                        <p>
                          Please add Terms and Condition to respective payers before submit the
                          contract to payers.
                        </p>
                      </>
                    }
                  >
                    &nbsp;
                    <button
                      className="btn"
                      style={{ background: '#6c757d26', cursor: 'not-allowed' }}
                    >
                      Submit Contract
                    </button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {isModelOpen && (
        <CustomModal
          isOpen={isModelOpen}
          header={header}
          yesAction={() => {
            toggle();
            let Data = {
              JSON: JSON.stringify({
                // SmartContract: [{ ClientId: clientId, SmartContractId: 685 }],
                SmartContract: [{ ClientId: clientId, SmartContractId: smartContractId }],
              }),
            };

            dispatch(smartContractDistributionGet(Data));
          }}
          yesText="OK"
          hideCancel={true}
        />
      )}
    </>
  );
};
export default Distribution;
