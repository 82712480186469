import React, { useState, useEffect } from 'react';
import { submit } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { Button, Drawer, Table, Modal, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import AntdModal from '../../../../../../Components/CustomModal';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  ReduxTextarea,
} from '../../../../../../component/custom-redux-components';
import validate from '../../../../../../core/generic-form/validation';
import {
  smartContractLedgerGet,
  smartContractUpdate,
  vbcLedgerRemarkUpsert,
  resetVbcLedgerRemarkUpsert,
} from '../../../../../../store/actions';
import PersistTableSort from '../../../../../../component/persistTableSort';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';
import { currencyFormatter, numberFormat } from '../../../../../../util/formatter';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
import CustomModal from '../../../../../../core/modal';

const AddRemarkForm = props => {
  const { handleSubmit, markId } = props;
  const dispatch = useDispatch();
  return (
    <form
      onSubmit={handleSubmit(values => {
        let data = {
          SmartContract: [
            {
              SmartContractBundleServiceCodeId: markId,
              Remarks: values.remarks,
              IsCompleted: '0',
            },
          ],
        };
        // adding markAsComplete true will stop direction to list page .
        dispatch(
          vbcLedgerRemarkUpsert({
            json: JSON.stringify(data),
            markAsComplete: true,
          }),
        );
      })}
    >
      <div class="col-12 enrollment-form mt-3">
        <div class="form-group">
          <Field
            name="remarks"
            type="text"
            errorMessage="remark Required"
            component={ReduxTextarea}
            // validate={validate.required}
            fieldName={'Remarks'}
          />
          <label class="placeholder-label">Type Remarks Here*</label>
        </div>
      </div>
      <div class="col-12 enrollment-form mt-3 text-center">
        <button type="submit" className="btn btn-primary">
          Add
        </button>
      </div>
    </form>
  );
};

const ReduxAddRemarkForm = reduxForm({
  form: 'addRemarkForm',
  enableReinitialize: true,
})(connect()(withRouter(AddRemarkForm)));

const Ledger = props => {
  const [showRemarksModal, setShowRemarksModal] = useState(false);
  const [markId, setMarkId] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [header, setHeader] = useState(null);
  const {
    AmountBilled,
    DaysRemainingInTreatment,
    NetIncome,
    Performance,
    TotalCostofCare,
    TotalServicedelivered,
    TreatmentEndDate,
    TreatmentStartDate,
    CostOfCareToDate,
    RemainingServiceCost,
    NetIncomeTodate,
    BudgetStatus,
  } = (props && props.smartContractLedgerBannerData) || {};

  const tempColumns = [
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      sorter: (a, b) => isComparer(a.Code, b.Code),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Service Delivered',
      dataIndex: 'ServiceDelivered',
      key: 'ServiceDelivered',
      sorter: (a, b) => isComparer(a.ServiceDelivered, b.ServiceDelivered),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Care Team Member',
      dataIndex: 'CareTeam',
      key: 'CareTeam',
      sorter: (a, b) => isComparer(a.careTeam, b.careTeam),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Delivered Date',
      dataIndex: 'DeliveredDate',
      key: 'DeliveredDate',
      sorter: (a, b) => isDateComparer(a.DeliveredDateForSort, b.DeliveredDateForSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Service Cost',
      dataIndex: 'ServiceCost',
      key: 'ServiceCost',
      sorter: (a, b) => isNumericComparer(a.ServiceCost, b.ServiceCost),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="text-right">
            <span>
              {record.ServiceCost ? `${currencyFormatter.format(record.ServiceCost || 0)}` : null}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Net Income',
      dataIndex: 'NetIncome',
      key: 'NetIncome',
      sorter: (a, b) => isNumericComparer(a.NetIncome, b.NetIncome),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="text-right">
            <span>
              {record.NetIncome ? `${currencyFormatter.format(record.NetIncome || 0)}` : null}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Outcome',
      dataIndex: 'Outcome',
      key: 'Outcome',
      sorter: (a, b) => isComparer(a.Outcome, b.Outcome),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Remarks',
      dataIndex: 'Remarks',
      key: 'Remarks',
      sorter: (a, b) => isComparer(a.Remarks, b.Remarks),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="">
            {record.Remarks ? (
              record.Remarks
            ) : (
              <span
                onClick={() => {
                  setShowRemarksModal(true);
                  setMarkId(record.SmartContractBundleServiceCodeId);
                }}
                style={{
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  color: '#46B4FE',
                  cursor: 'pointer',
                }}
              >
                Add Remarks
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const toggle = header => {
    setShowRemarksModal(false);
    setIsModelOpen(!isModelOpen);
    setHeader(header);
  };

  useEffect(() => {
    window.scrollTo(0, 300);
    let Data = {
      json: JSON.stringify({
        Ledger: [{ ClientId: props.clientId, SmartContractId: props.smartContractId }],
      }),
    };
    dispatch(smartContractLedgerGet(Data));
    return () => {
      dispatch(resetVbcLedgerRemarkUpsert({}));
    };
  }, []);

  useEffect(() => {
    if (props.isContractEndedSuccessfully) {
      setShowRemarksModal(false);
      let Data = {
        json: JSON.stringify({
          Ledger: [{ ClientId: props.clientId, SmartContractId: props.smartContractId }],
        }),
      };
      dispatch(smartContractLedgerGet(Data));
    }
  }, [props.isContractEndedSuccessfully]);

  useEffect(() => {
    if (props.isLedgerAdded) {
      toggle('Remarks added successfully.');
    }
  }, [props.isLedgerAdded]);
  return (
    <>
      <div style={props.isFetching ? { height: '200px', position: 'relative' } : { height: '0px' }}>
        <Loader2 loading={props.isFetching} />
      </div>
      <div>
        {!props.isFetching && (
          <div className="container-fluid mt-2" style={{ minHeight: '300px', overflow: 'auto' }}>
            <div className="p-2">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="label mt-2 mb-2 d-inline mx-2">Ledger</div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{ background: '#FFEDF0', borderRadius: '8px', height: '98px' }}
                  >
                    <div
                      style={{
                        color: '#FF4869',
                        fontWeight: '600',
                        fontSize: '16px',
                        width: '110px',
                      }}
                    >
                      Authorized Billing Amount
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '21px' }}>
                      {currencyFormatter.format(AmountBilled || 0)}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{
                      background: '#ECFCEF',
                      borderRadius: '8px',
                      height: '98px',
                    }}
                  >
                    <div
                      style={{
                        color: '#00DC31',
                        fontWeight: '600',
                        fontSize: '16px',
                        width: '110px',
                      }}
                    >
                      Cost of Care To-Date
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '21px' }}>
                      {currencyFormatter.format(CostOfCareToDate || 0)}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{
                      background: '#EAF5FC',
                      borderRadius: '8px',
                      height: '98px',
                    }}
                  >
                    <div
                      style={{
                        color: '#29A0E2',
                        fontWeight: '600',
                        fontSize: '16px',
                        width: '110px',
                      }}
                    >
                      Remaining Service Cost
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '21px' }}>
                      {currencyFormatter.format(RemainingServiceCost || 0)}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{ background: '#FFF5CC', borderRadius: '8px', height: '98px' }}
                  >
                    <div
                      style={{
                        color: '#DDAC00',
                        fontWeight: '600',
                        fontSize: '16px',
                        width: '110px',
                      }}
                    >
                      Net Income To-Date
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '21px' }}>
                      {currencyFormatter.format(NetIncomeTodate || 0)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{ background: '#F5F8FA', borderRadius: '8px' }}
                  >
                    <div style={{ fontWeight: '600', fontSize: '16px', width: '110px' }}>
                      Contract Start Date
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '16px' }}>
                      {TreatmentStartDate}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{ background: '#F5F8FA', borderRadius: '8px' }}
                  >
                    <div style={{ fontWeight: '600', fontSize: '16px', width: '110px' }}>
                      Contract End Date
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '16px' }}>
                      {TreatmentEndDate}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{ background: '#F5F8FA', borderRadius: '8px' }}
                  >
                    <div style={{ fontWeight: '600', fontSize: '16px', width: '137px' }}>
                      Day(s) Remaining in Treatment
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '16px' }}>
                      {DaysRemainingInTreatment ? `${DaysRemainingInTreatment} Day(s)` : '0 Day(s)'}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="d-flex justify-content-between align-items-center py-4 px-2"
                    style={{ background: '#F5F8FA', borderRadius: '8px' }}
                  >
                    <div style={{ fontWeight: '600', fontSize: '16px', width: '99px' }}>
                      Budget Status <span style={{ visibility: 'hidden' }}>Status</span>
                    </div>
                    <div style={{ color: '#182838', fontWeight: '700', fontSize: '16px' }}>
                      <span
                        style={{
                          borderRadius: '4px',
                          background: '#2FE192',
                          color: 'white',
                          fontSize: '14px',
                          padding: '3px',
                        }}
                      >
                        {BudgetStatus}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-3">
            <div className="col-md-6">
              <div
                className="d-flex justify-content-between align-items-center py-4 px-2"
                style={{ background: '#F5F8FA', borderRadius: '8px' }}
              >
                <div style={{ fontWeight: '600', fontSize: '18px' }}>
                  Day(s) Remaining in Treatment
                </div>
                <div style={{ color: '#182838', fontWeight: '700', fontSize: '20px' }}>
                  {DaysRemainingInTreatment ? `${DaysRemainingInTreatment} Day(s)` : ''}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="d-flex justify-content-between align-items-center py-4 px-2"
                style={{ background: '#F5F8FA', borderRadius: '8px' }}
              >
                <div style={{ fontWeight: '600', fontSize: '18px' }}>Total Service Delivered</div>
                <div style={{ color: '#182838', fontWeight: '700', fontSize: '20px' }}>
                  {TotalServicedelivered}
                </div>
              </div>
            </div>
          </div> */}
            </div>
            <div className="row">
              <div className="col-12 enrollment-form mt-1">
                <div className="row card-body px-2 rcm-subtab-table-block">
                  {/* <Loader2 loading={this.props.isFetching} /> */}
                  <div className="col-12 rcm-tools-table rcm-panel">
                    <PersistTableSort
                      name="service-code-list"
                      // bordered
                      columns={tempColumns}
                      dataSource={props.smartContractLedgerTableData || []}
                      size={'middle'}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div className="dataTables_wrapper no-footer">
                              <div className="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a className="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a className="paginate_button next">Next</a>
                                ) : (
                                  <a className="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        hideOnSinglePage: true,
                      }}
                    />
                  </div>
                  <div className="col-12 text-right mt-4">
                    <div className="">
                      <Button
                        type="primary"
                        onClick={() => {
                          props.goToPreviuos();
                        }}
                        className={`btn btn-outline-primary`}
                        style={{ height: '42px' }}
                      >
                        Previous
                      </Button>{' '}
                      &nbsp;
                      <Button
                        type="primary"
                        onClick={() => {
                          props.handleNext();
                        }}
                        className={`btn save-button`}
                        style={{ height: '42px' }}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              width={600}
              visible={showRemarksModal}
              footer={null}
              closeIcon={null}
              closable={false}
              onClose={e => {
                e.stopPropagation();
                setShowRemarksModal(false);
              }}
              destroyOnClose={true}
            >
              <div className="modal-content border-0">
                <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={e => {
                      e.stopPropagation();
                      setShowRemarksModal(false);
                    }}
                  >
                    &times;
                  </button>
                  <h4 className="modal-title col-12">Add Remarks</h4>
                </div>
                <div
                  className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5"
                  style={{ height: '300px', overflow: 'auto' }}
                >
                  <ReduxAddRemarkForm markId={markId} />
                </div>
              </div>
            </Modal>

            {isModelOpen && (
              <CustomModal
                isOpen={isModelOpen}
                header={header}
                yesAction={() => {
                  // dispatch(serviceCodeBundleGetAll({}));
                  toggle();
                  let Data = {
                    json: JSON.stringify({
                      Ledger: [
                        { ClientId: props.clientId, SmartContractId: props.smartContractId },
                      ],
                    }),
                  };
                  dispatch(smartContractLedgerGet(Data));
                }}
                yesText="OK"
                hideCancel={true}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { smart_contract_ledger_get, smart_contract_update, vbc_ledger_remark_upsert } = state;

  let smartContractLedgerBannerData = null;
  let smartContractLedgerTableData = null;
  // let typeOfContractEnded = null;
  let isContractEndedSuccessfully = false;
  let isLedgerAdded = false;
  const { isFetching } = smart_contract_ledger_get;
  if (smart_contract_update) {
    isContractEndedSuccessfully = smart_contract_update.data && smart_contract_update.data.success;
    //  typeOfContractEnded = smart_contract_update.data && smart_contract_update.data.data;
  }

  if (smart_contract_ledger_get && smart_contract_ledger_get.data) {
    smartContractLedgerBannerData =
      smart_contract_ledger_get.data &&
      smart_contract_ledger_get.data.data &&
      JSON.parse(smart_contract_ledger_get.data.data).Ledger[0].Banner[0];
    smartContractLedgerTableData =
      (smart_contract_ledger_get.data &&
        smart_contract_ledger_get.data.data &&
        JSON.parse(smart_contract_ledger_get.data.data).Ledger[0].List) ||
      [];
  }
  if (vbc_ledger_remark_upsert && vbc_ledger_remark_upsert.data) {
    isLedgerAdded = vbc_ledger_remark_upsert.data && vbc_ledger_remark_upsert.data.success;
  }
  return {
    smartContractLedgerBannerData,
    smartContractLedgerTableData,
    isContractEndedSuccessfully,
    isFetching,
    isLedgerAdded,
    // typeOfContractEnded,
  };
};

export default connect(mapStateToProps)(Ledger);
