import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';

const UserAccount = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    isEmployedValue,
    style,
    goToPreviuos,
    disable,
  } = props;
  return (
    <form onSubmit={handleSubmit(handleNext)} style={{ padding: '36px 20px 200px 20px' }}>
      <div class="row rcm-tab mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`userId`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'User ID'}
              disabled={disable}
            />
            <label class="placeholder-label">User ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`firstName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">First Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`lastName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Last Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Last Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`password`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Password'}
              disabled={disable}
            />
            <label class="placeholder-label">Password</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`phone`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Phone'}
              disabled={disable}
            />
            <label class="placeholder-label">Phone</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`email`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Email Address'}
              disabled={disable}
            />
            <label class="placeholder-label">Email Address</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`cellNumber`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Cell Number'}
              disabled={disable}
            />
            <label class="placeholder-label">Cell Number</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="userType"
              type="text"
              placeholder="User Type"
              component={ReduxSelect}
              options={Language}
              disabled={disable}
            />
          </div>
        </div>

        <div class="row pagination-button">
          <div class="col-12 text-right">
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  return {
    MaritalStatus: combos.data.MaritalStatus,
    EthnicRace: combos.data.EthnicRace,
    Gender: combos.data.Gender,
    HousingType: combos.data.HousingType,
    EyeColor: combos.data.EyeColor,
    Language: combos.data.Language,
    EducationType: combos.data.EducationType,
    WorkSkill: combos.data.WorkSkill,
    isEmployedValue: selector(state, 'isEmployed'),
  };
})(
  reduxForm({
    form: 'providerEnrollmentForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(UserAccount),
);

const selector = formValueSelector('providerEnrollmentForm');
