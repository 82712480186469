import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import TextareaAutosize from 'react-autosize-textarea';
// import MultipleCategoryCheckboxAll2 from '../../Components/multipleCategoryCheckbox2';
import SetReminderComponent from './SetReminderComponent';
import DatePicker from 'react-datepicker';
import ut from '../../../util/date';
import { stringDate } from '../../../util/date';

class SetReminderModal extends Component {
  constructor() {
    super();
    this.state = {
      selectedPersons: [],
      selectAll: false,
    };
  }
  getSelectAllValue = selectAll => {
    this.setState({ selectAll });
  };
  updatedSelectedPersons = selectedPersons => {
    this.setState({ selectedPersons });
  };
  getSelectedUsers = () => {
    const { selectedPersons } = this.state;
    let result;
    if (this.state.selectAll) {
      result =
        selectedPersons &&
        selectedPersons[0] &&
        selectedPersons[0].map(item => {
          return { StaffIds: item.value };
        });
    } else {
      result =
        selectedPersons &&
        selectedPersons[0] &&
        selectedPersons[0].map(item => {
          return { StaffIds: item };
        });
    }
    return result;
  };
  setReminder = () => {
    const { selectedPersons, Remindme, note } = this.state;
    if (!Remindme || !note) {
      this.setState({
        isRemindmeError: !Remindme,
        isNote: !note,
      });
      return;
    }
    let data = {
      Json: JSON.stringify({
        Reminder: [
          {
            StudentId: this.props.ClientId,
            StaffIds: this.state.selectedPersons[0].toString(),
            RemindOn: stringDate(this.state.Remindme),
            Note: this.state.note,
          },
        ],
      }),
    };
    Axios.post('Student/ReminderUpsert', data)
      .then(response => {
        if (response.data.success) {
          this.props.toggle(response.data.result.Msg, response.data.success);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  handleChange = date => {
    this.setState({
      Remindme: date,
      isRemindmeError: false,
    });
  };

  render() {
    const { toggle, onCancel, history, ClientId, SurveyId } = this.props;
    const me = this;
    const isvalid = me.state.selectedPersons[0] && me.state.selectedPersons[0].length;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Set Reminder Due Date*"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div class="modal-content border-1">
        <div class="modal-header justify-content-center flex-wrap mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">Set Reminder</h4>
        </div>
        <div class="modal-body px-4  update-history-card mb-1">
          <div className="row">
            <div className="col-6 enrollment-form">
              <div class="form-group">
                <DatePicker
                  selected={this.state.Remindme}
                  onChange={this.handleChange}
                  className="form-control datepicker"
                  customInput={<ExampleCustomInput />}
                />
                <i class="far fa-calendar-alt form-control-icon" />
                {this.state.isRemindmeError && (
                  <span className="error-text">Reminder date required</span>
                )}
              </div>
            </div>
            <div className="col-12 enrollment-form">
              <div class="form-group">
                <TextareaAutosize
                  name="note"
                  key="Type here"
                  id="commentBox"
                  style={{
                    width: '100%',
                    border: '1px solid #AAACB5;',
                    borderRadius: '2px',
                    overflow: 'hidden',
                    paddingLeft: '10px',
                  }}
                  onChange={e => {
                    this.setState({ note: e.target.value, isNote: false });
                  }}
                  className={`form-control ${this.state.note && 'not-empty'}`}
                >
                  {this.state.note}
                </TextareaAutosize>
                <label class="placeholder-label">Add a note*..</label>
                {this.state.isNote && <span className="error-text">Note required</span>}
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="reminder-info">
                <span className="title">A reminder will be set for you</span>
                <span className="info">You will be notified on alerts and through email.</span>
              </div>
            </div>
            <div className="col-12">
              <SetReminderComponent
                updatedSelectedPersons={this.updatedSelectedPersons}
                selectedPersons={this.state.selectedPersons}
                searchStr={this.state.searchStr}
                getSelectAllValue={this.getSelectAllValue}
              />
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-end border-top-0 pb-2 pt-2">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn black-btn"
            data-dismiss="modal"
            disabled={!isvalid}
            onClick={() => {
              this.setReminder();
            }}
          >
            Set
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { augmented_summary_get } = state;

  const { data: AugmentedSummaryData } = augmented_summary_get;

  let augmentedSummaryHistory = null;

  if (AugmentedSummaryData && AugmentedSummaryData.History) {
    augmentedSummaryHistory = AugmentedSummaryData.History;
  }

  return {
    augmentedSummaryHistory: augmentedSummaryHistory,
  };
};
export default connect(mapStateToProps)(SetReminderModal);
