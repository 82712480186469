import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Button } from 'antd';
import { getBasicProfile, getStudentProfileDashboardData } from '../../store/actions';
import moment from 'moment';
class ProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getUserGuid() {
    if (
      this.props.location.pathname.split('/')[1] == 'subscriberProfile' ||
      this.props.location.pathname.split('/')[1] == 'subscribers-survery'
    ) {
      return this.props.location.pathname.split('/')[2];
    } else {
      return this.props.location.search.split('=')[1];
    }
  }
  getStudentId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentid = param.get('studentid');
    if (studentid) {
      studentid = parseInt(studentid);
    } else {
      studentid = null;
    }
    return studentid;
  }
  componentDidMount() {
    // let checkTimelineUrl =
    //   this.props.location.pathname.split('/')[1] == 'subscriberProfile' ||
    //   this.props.location.pathname.split('/')[1] == 'subscribers-survery';
    // this.setState({ checkTimelineUrl });
    // let data = { Profile: [{ UserGuid: this.getUserGuid() }] };
    this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));

    // this.props.dispatch(
    //   getBasicProfile({
    //     Json: JSON.stringify(data),
    //   }),
    // );
  }

  render() {
    const { ProfileData } = this.props;
    const {
      Age,
      AdmissionDate,
      FirstName,
      LastName,
      MiddleName,
      StudentImageUrl,
      Phase,
      CostofCareToDate,
      ServiceType,
      EnrollStatus,
      EnrollStatusId,
    } = ProfileData;
    return (
      <div class="border-0">
        <div className="row">
          {/* NAME DETAIL */}
          <div className="col-12 mb-4 pt-4">
            <div className="card border-0 students-profile ">
              <div className="row card-body align-items-center">
                <div className="col-md-4 px-0">
                  <div className="d-flex">
                    <div className="EditImage">
                      <div
                        className="images-placeholder2"
                        style={{
                          backgroundImage: 'url(&quot;images/surveys-img1.jpg&quot;)',
                        }}
                      >
                        <img
                          src={StudentImageUrl}
                          className="students-profile-img images-placeholder2x"
                          alt=""
                          width="84"
                        />
                      </div>
                    </div>
                    <div className="name-detail">
                      <h4 className="students-name text-xs-center text-md-left text-capitalize ml-2">
                        {LastName} {FirstName}
                      </h4>
                      <div className="students-action-button ml-2 text-xs-center">
                        <button
                          onClick={() => {
                            if (EnrollStatusId == 1 || EnrollStatusId == 2) {
                              this.props.history.push(
                                `/enrollment/?studentid=${this.getStudentId()}`,
                              );
                            } else if (EnrollStatusId == 0) {
                              this.props.history.push(
                                `/clients/intake/dashboard?tab=summary&id=${this.getStudentId()}`,
                              );
                            }
                          }}
                          title="View Profile"
                          className="btn black-btn"
                        >
                          <span>View Profile</span>
                        </button>
                        &nbsp; &nbsp;
                        {/* {this.state.checkTimelineUrl && (
                      <button
                        onClick={() => {
                          this.props.history.push(
                            `/timetravel/?subscriberId=${this.getUserGuid()}`,
                          );
                        }}
                        title="View Time Travel"
                        className="btn black-btn"
                      >
                        <span>View Time Travel</span>
                      </button>
                    )} */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* student Personal */}
                <div className="col-md-12 col-xl-8 mt-4">
                  <div className="row students-detail   text-md-left  justify-content-md-between">
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 ">
                      <span className="info-label">Age</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {Age} {Age && 'Years'}
                        {!Age && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    {/* <div className="col-2 col-sm-2 mb-2 md-sm-0 px-0">
                      <span className="info-label">Last Location Received on</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {LocationLastCaptured && (
                          <a
                            href={`http://www.google.com/maps/place/${LatestCoordinate[0].Latitude},${LatestCoordinate[0].Longitude}`}
                            target="_blank"
                          >
                            {LocationLastCaptured}
                          </a>
                        )}
                        {!LocationLastCaptured && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div> */}
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 px-0">
                      <span className="info-label">Phase</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {Phase}
                        {!Phase && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>

                    <div className="col-2 col-sm-2 mb-2 md-sm-0 px-0">
                      <span className="info-label">Service Type</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {ServiceType}
                        {!ServiceType && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 px-0">
                      <span className="info-label">Cost of Care To Date</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {CostofCareToDate && `$${CostofCareToDate}`}
                        {!CostofCareToDate && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-md-2  students-detail px-0">
                      <span className="info-label">Admission Date</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {moment(AdmissionDate).format('ll')}
                      </h5>
                    </div>
                    {/* <div className="col-3 col-sm-3 mb-2 md-sm-0 px-0">
                      <span className="info-label">Notification Last Delivered or Read </span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {NotilLastDelivered}{' '}
                        {!NotilLastDelivered && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div> */}
                  </div>
                </div>
                {/* student Personal */}
              </div>
            </div>
          </div>
          {/* NAME DETAIL */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ student_get_profile_dashboard_data }) => {
  const { data, error, isFetching } = student_get_profile_dashboard_data;
  let ProfileData = {};

  if (data && data.StudentMisc) {
    let mics = data.StudentMisc[0];
    ProfileData = {
      FirstName: data.FirstName,
      LastName: data.LastName,
      MiddleName: data.MiddleName,
      AdmissionDate: data.AdmissionDate,
      ...mics,
    };
  }
  return { ProfileData };
};

export default connect(mapStateToProps)(withRouter(ProfileHeader));
