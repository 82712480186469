import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import ToggleNote from '../toggleNote';

const Financial = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    StudentChildrenCare,
    isEmployedValue,
    style,
    goToPreviuos,
    disable,
    CountryOfBirth,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
    hideQuestions,
    costAndFamilyDiscussedValue,
    ableToCoverIntakeFeeValue,
    supportForMonthlyContributionsValue,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={{ padding: '36px 20px 200px 20px' }}
    >
      <div class="row mb-4">
        <ToggleNote
          isToggleOn={costAndFamilyDiscussedValue}
          toggleQuestion={'Has cost & friends and family be discussed?'}
          toggleFiledName={`costAndFamilyDiscussed`}
          toggleNoteFieldName={`costAndFamilyDiscussedNote`}
          disable={disable}
        />
        <ToggleNote
          isToggleOn={ableToCoverIntakeFeeValue}
          toggleQuestion={' Are you able to cover the $500 Intake Fee?'}
          toggleFiledName={`ableToCoverIntakeFee`}
          toggleNoteFieldName={`ableToCoverIntakeFeeNote`}
          disable={disable}
        />
        <ToggleNote
          isToggleOn={supportForMonthlyContributionsValue}
          toggleQuestion={' Do you have support to help cover the monthly Contributions?'}
          toggleFiledName={`supportForMonthlyContributions`}
          toggleNoteFieldName={`supportForMonthlyContributionsNote`}
          disable={disable}
        />
      </div>
      <div class="row pagination-button">
        <div class="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              class="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  return {
    costAndFamilyDiscussedValue: selector(state, 'costAndFamilyDiscussed'),
    ableToCoverIntakeFeeValue: selector(state, 'ableToCoverIntakeFee'),
    supportForMonthlyContributionsValue: selector(state, 'supportForMonthlyContributions'),
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Financial),
);

const selector = formValueSelector('wizard');
