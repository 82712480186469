import React, { useState, useEffect } from 'react';

const Stopwatch = ({ TimerAction, ID }) => {
  const InitTimer = localStorage.getItem(`IsPeer${ID}`);
  let initCounter = InitTimer ? parseInt(InitTimer) : null;
  const [startTime, setStartTime] = useState(initCounter);
  const [isRunning, setIsRunning] = useState(InitTimer ? true : false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  let intervalId;
  useEffect(() => {
    if (isRunning) {
      intervalId = setInterval(() => setNewTime(), 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(() => {
    if (!isRunning) {
      const timer = new Date().getTime();
      clearInterval(intervalId);
      setStartTime(timer);
      setIsRunning(true);
      TimerAction(1, timer);
      localStorage.setItem(`IsPeer${ID}`, timer);
    }
  }, []);

  const setNewTime = () => {
    if (isRunning) {
      const currentTime = new Date().getTime();
      const distanceToDate = currentTime - startTime;
      setStartTime(timer => timer + distanceToDate);

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      days = `${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      }
      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      }
      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }
      setTimeLeft({ days: days, hours: hours, minutes, seconds });
    }
  };

  // Method to start and stop timer
  const startAndStop = () => {
    if (isRunning) {
      clearInterval(intervalId);
      TimerAction(2);
      setIsRunning(false);
      setStartTime(0);
      setTimeLeft({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }
  };

  return (
    <div className="stopwatch-container ">
      {isRunning && (
        <div className="stopwatch-buttons">
          <p className="stopwatch-time">
            <img src="images/icons/billing_clock.svg" className="pr-2" />

            <div className="countdown-wrapper d-flex">
              <div className="time-section">
                <div className="time">{(timeLeft && timeLeft.hours) || '00'}</div>
              </div>
              <div className="time-section">
                <div className="time">:</div>
              </div>
              <div className="time-section">
                <div className="time">{(timeLeft && timeLeft.minutes) || '00'}</div>
              </div>
              <div className="time-section">
                <div className="time">:</div>
              </div>
              <div className="time-section">
                <div className="time">{(timeLeft && timeLeft.seconds) || '00'}</div>
              </div>
            </div>
          </p>
          <span
            style={{ background: 'red', cursor: 'pointer', padding: '6px 16px' }}
            className="btn btn-danger"
            onClick={startAndStop}
          >
            End Session
          </span>
        </div>
      )}
    </div>
  );
};
export default Stopwatch;
