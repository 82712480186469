import React from 'react';
import { Tabs, Modal, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import CustomModal from '../../../../core/modal';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import { ProcessCombos } from '../../../../store/comboConfig';
import Loader, { Loader2 } from '../../../../Components/Loader';
import {
  ReduxInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../../../component/custom-redux-components';
import {
  upsertGroup,
  getComboFacility,
  practitionerCombos,
  getGroup,
  getServiceCode,
  getStaffPractitioner,
  listGroup,
  deleteGroup,
} from '../../../../store/actions';
import AntdModal from '../../../../Components/CustomModal';
import SubmitButton from '../../../../Components/submitButton';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

const BasicInformationForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    disable,
    GroupId,
    dispatch,
    medicateCombos,
    Practitioner,
    PractitionerOffice,
    ServiceCodeList,
    GroupDataInfo,
    ServiceCodeInfo,
    isFetchingGroupData,
    CenterServiceType,
    history,
    isReadOnlyUser,
    isUpsertFetching,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(onSaveClick)}
      style={{ padding: '36px 20px 20px 20px' }}
      // style={{ position: 'relative' }}
    >
      <Loader2 loading={isFetchingGroupData} />
      <div className="row mb-4">
        <div className="col-12 m-2">
          {GroupId > 0 ? <h6>Edit Group</h6> : <h6>Create Group</h6>}
        </div>
        <div className="col-12">
          <div className="row pb-5 pl-2 pr-3 pt-3">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <Field
                  name="groupName"
                  component={ReduxInput}
                  validate={Validator.required}
                  fieldName={'Group Name'}
                  disabled={disable}
                />
                <label class="placeholder-label">Group Name*</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-8"></div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`serviceCode`}
                  validate={Validator.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Select Service Code*`}
                  options={ServiceCodeList}
                  fieldName={'Diagnosis'}
                  onChange={value => {
                    dispatch(
                      getServiceCode({
                        json: JSON.stringify({
                          ServiceCode: [
                            {
                              ServiceCodeId: value,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12 col-md-7 pb-3">
              {ServiceCodeInfo.ServiceCodeDescription ||
                (GroupDataInfo.ServiceCodeDescription && (
                  <div style={{ height: '90px' }}>
                    <p
                      style={{
                        color: '#959595',
                        fontSize: '11px',
                        marginBottom: '0px',
                        fontWeight: '400',
                        background: '#F8F8F8',
                        paddingLeft: '5px',
                      }}
                    >
                      Description
                    </p>
                    <p
                      style={{
                        // color: '#959595',
                        // fontSize: '16px',
                        // marginBottom: '0px',
                        // fontWeight: '400',
                        background: '#F8F8F8',
                        paddingLeft: '5px',
                      }}
                    >
                      {ServiceCodeInfo.ServiceCodeDescription ||
                        GroupDataInfo.ServiceCodeDescription}
                    </p>
                  </div>
                ))}
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`counselor`}
                  validate={Validator.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Select Counselor*`}
                  options={Practitioner}
                  fieldName={'Counselor'}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`officelocation`}
                  validate={Validator.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Select Office Location*`}
                  options={PractitionerOffice}
                  fieldName={'Office Location'}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <Field
                  name={`centerServiceType`}
                  validate={Validator.required}
                  type="text"
                  component={ReduxSelect}
                  placeholder={`Select Service Type*`}
                  options={CenterServiceType}
                  fieldName={'Service Type'}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <Field
                  name="materialsLink"
                  type="textarea"
                  component={ReduxTextarea}
                  // validate={Validator.required}
                  fieldName={'Materials/Curriculum Link'}
                  // disabled={isDeactivated}
                  disabled={disable}
                />
                <label class="placeholder-label">Link to materials/curriculum </label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mt-3">
              <div class="form-group">
                <Field
                  className="mr-2"
                  name="isGroupHasChildCenterPatient"
                  component={ReduxCheckbox}
                  fieldName={'isGroupHasChildCenterPatient'}
                />
                <label class="placeholder-label" style={{ top: '5px' }}>
                  Include Child Center Patient(s).
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div className="col-6">
          {GroupId > 0 && (
            <div>
              {GroupDataInfo.IsArchived ? (
                <>
                  {!isReadOnlyUser && (
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Reactivate Group">
                          <button
                            type="button"
                            className="btn-small reactivate-link"
                            onClick={onClick}
                          >
                            Reactivate Group
                          </button>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to reactivate this Group?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                dispatch(
                                  deleteGroup({
                                    json: JSON.stringify({
                                      Group: [
                                        {
                                          GroupId: GroupId,
                                          IsActivate: true,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                dispatch(listGroup({}));
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </>
              ) : (
                <>
                  {!isReadOnlyUser && (
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Archive Group">
                          <button
                            type="button"
                            className="btn-small deactivate-link"
                            onClick={onClick}
                          >
                            <i class="fa fa-ban" aria-hidden="true"></i> Deactivate Group
                          </button>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to deactivate this Group?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                dispatch(
                                  deleteGroup({
                                    json: JSON.stringify({
                                      Group: [
                                        {
                                          GroupId: GroupId,
                                          IsActivate: false,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                dispatch(listGroup({}));
                                // resetEditGroup();
                                // onCancel();
                                // setCreateAndEditGroupModalToFalse();

                                // this.props.onCancel();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {!isReadOnlyUser && (
          <>
            {' '}
            <div class="col-6 text-right">
              {GroupId > 0 ? (
                <span
                  onClick={() => {
                    history.push('/manage/smallgroups');
                  }}
                  className="btn-link"
                  style={{ cursor: 'pointer' }}
                >
                  Manage your small groups
                </span>
              ) : null}
              &nbsp;
              <input
                type="button"
                class="btn btn-outline-primary mr-2"
                value="Reset"
                onClick={() => {
                  props.dispatch(reset('basicInformationForm'));
                }}
              />
              {/* <input type="submit" class="btn" value={GroupId > 0 ? 'Update' : 'Create Group'} /> */}
              <SubmitButton
                loading={isUpsertFetching}
                value={GroupId > 0 ? 'Update' : 'Create Group'}
                style={{ height: '45px' }}
              />
            </div>
          </>
        )}
      </div>
    </form>
  );
};

class BasicInformation extends React.Component {
  constructor() {
    super();
    this.state = {
      GroupDataInfo: {},
    };
  }
  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = null;
    }
    return groupId;
  }
  componentDidMount() {
    var result = ProcessCombos('RCM_ServiceCode,RCM_PlaceOfService,RCM_Program,CenterServiceType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(practitionerCombos({}));
    this.props.dispatch(getComboFacility({}));
    this.props.dispatch(getStaffPractitioner({}));
    this.props.dispatch(
      getGroup({
        json: JSON.stringify({
          Group: [
            {
              GroupId: this.getGroupId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({
    isGroupCreated,
    GroupDataInfo,
    groupId,
    getStaffPractitionerValue,
    isDeletedGroup,
  }) {
    if (
      getStaffPractitionerValue &&
      JSON.stringify(getStaffPractitionerValue) !=
        JSON.stringify(this.props.getStaffPractitionerValue)
    ) {
      if (
        getStaffPractitionerValue &&
        getStaffPractitionerValue.data &&
        getStaffPractitionerValue.data.length > 0
      ) {
        this.setState({
          facilityValue:
            (getStaffPractitionerValue.data[0].FacilityId &&
              JSON.stringify(getStaffPractitionerValue.data[0].FacilityId)) ||
            null,
          practitionerValue:
            (getStaffPractitionerValue.data[0].PractitionerId &&
              JSON.stringify(getStaffPractitionerValue.data[0].PractitionerId)) ||
            null,
        });
      }
    }

    if (isGroupCreated && isGroupCreated != this.props.isGroupCreated) {
      let successMsg = this.getGroupId()
        ? 'Group updated successfully.'
        : 'Group created successfully.';
      this.setState({
        successMsg: successMsg,
        isModelOpen: true,
        groupId: groupId,
      });
    }
    if (isDeletedGroup && isDeletedGroup != this.props.isDeletedGroup) {
      this.props.dispatch(listGroup({}));
      // this.setState({
      //   successMsg: 'Group updated successfully.',
      //   isModelOpen: true,
      // });
      this.props.history.push(`/cw/group-management/group-list`);
      // this.props.history.push(
      //   `/cw/group-management/group-details?tab=group-information&gId=${groupId}`,
      // );
    }
    if (GroupDataInfo && GroupDataInfo != this.props.GroupDataInfo) {
      this.setState({
        GroupDataInfo,
      });
    }
  }
  onSubmit = values => {
    var data = {
      Group: [
        {
          GroupId: this.getGroupId(),
          GroupName: values.groupName,
          ServiceCodeId: values.serviceCode,
          CounsellorId: values.counselor,
          PractitionerOfficeId: values.officelocation,
          ServiceTypeId: values.centerServiceType,
          MaterialsLink: values.materialsLink,
          IsGroupHasChildCenterPatient: values.isGroupHasChildCenterPatient,
        },
      ],
    };
    var GroupData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertGroup(GroupData));
    this.setState({ createAndEditGroupModal: false, resetEditGroup: {} });
  };

  render() {
    const { GroupDataInfo, groupId } = this.state;
    const { isReadOnlyUser } = this.props;
    let initObj;
    if (this.getGroupId()) {
      initObj = {
        groupName: GroupDataInfo.GroupName,
        serviceCode: GroupDataInfo.ServiceCodeId && parseInt(GroupDataInfo.ServiceCodeId),
        counselor: GroupDataInfo.CounsellorId && parseInt(GroupDataInfo.CounsellorId),
        officelocation: JSON.stringify(GroupDataInfo.PractitionerOfficeId),
        centerServiceType: GroupDataInfo.ServiceTypeId && parseInt(GroupDataInfo.ServiceTypeId),
        materialsLink: GroupDataInfo.MaterialsLink,
        isGroupHasChildCenterPatient:GroupDataInfo.IsGroupHasChildCenterPatient,
      };
    } else {
      initObj = {
        officelocation: this.state.facilityValue && this.state.facilityValue,
        counselor: this.state.practitionerValue && parseInt(this.state.practitionerValue),
      };
    }
    return (
      <React.Fragment>
        <div className="container-fluid">
          <ReduxBasicInformationForm
            {...this.props}
            onSaveClick={this.onSubmit}
            GroupId={this.getGroupId()}
            initialValues={initObj}
            GroupDataInfo={GroupDataInfo}
            isUpsertFetching={this.props.isUpsertFetching}
            // isReadOnlyUser={isReadOnlyUser}
          />
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.successMsg}
            yesAction={() => {
              this.props.dispatch(reset('basicInformationForm'));
              this.props.dispatch(
                getGroup({
                  json: JSON.stringify({
                    Group: [
                      {
                        GroupId: groupId,
                      },
                    ],
                  }),
                }),
              );
              this.setState({
                isModelOpen: false,
                successMsg: '',
              });
              this.props.history.push(
                `/cw/group-management/group-details?tab=group-information&gId=${groupId}`,
              );
            }}
            yesText="OK"
            hideCancel={true}
          />
          {/* <div className="main medicalAnalysis-page">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 px-0"></div>
            </div>
          </div>
        </div> */}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  const {
    combos,
    practitioner_combos,
    get_combo_facility,
    upsert_group,
    get_group,
    get_service_code,
    get_staff_practitioner,
    delete_group,
  } = state;
  const { data: practitionerData } = practitioner_combos;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: GroupData, isFetching: isFetchingGroupData } = get_group;
  const { data: serviceCodeInfoData } = get_service_code;
  const { data: getStaffPractitionerValue } = get_staff_practitioner;
  const { isFetching: isUpsertFetching } = upsert_group;


  if (getStaffPractitionerValue) {
  }
  let ServiceCodeList = [];
  let Practitioner = [];
  let PractitionerOffice = [];
  let CenterServiceType = [];
  let isGroupCreated = false;
  let isDeletedGroup = false;
  let groupId = null;
  let deleteSuccessMsg = null;
  let GroupDataInfo = {};
  let serviceCodeInfo = {};

  if (combos.data && combos.data['RCM_ServiceCode']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'] || [];
    CenterServiceType = combos.data['CenterServiceType'] || [];
  }
  if (GroupData && GroupData.Group) {
    GroupDataInfo = GroupData.Group[0];
  }
  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList || [];
  }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (upsert_group && upsert_group.data && upsert_group.data.success) {
    isGroupCreated = upsert_group.data.success;
    groupId = upsert_group.data.result;
  }

  if (delete_group.data && delete_group.data.success) {
    isDeletedGroup = delete_group.data.success;
  }
  if (serviceCodeInfoData && serviceCodeInfoData.ServiceCode) {
    serviceCodeInfo = serviceCodeInfoData.ServiceCode[0] || [];
  }
  return {
    ServiceCodeList: ServiceCodeList,
    ServiceCodeInfo: serviceCodeInfo,
    Practitioner,
    PractitionerOffice,
    isGroupCreated,
    groupId,
    GroupDataInfo: GroupDataInfo,
    isFetchingGroupData,
    CenterServiceType,
    getStaffPractitionerValue,
    isDeletedGroup,
    isUpsertFetching,
  };
};

const ReduxBasicInformationForm = reduxForm({
  form: 'basicInformationForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(BasicInformationForm)));
const selector = formValueSelector('basicInformationForm');

export default withRouter(connect(mapStateToProps)(BasicInformation));
