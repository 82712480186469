import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomModal2 from '../../core/modal';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class CustomError extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      error: '',
    };
  }

  componentWillReceiveProps(props) {
    if (props.error && props.error != this.props.error) {
      this.setState({
        displayError: true,
        error: props.error,
      });
    }
  }

  resetError() {
    this.setState({
      displayError: false,
      error: '',
    });
    this.props.dispatch({ type: 'DISPLAY_ERROR_RESET' });
  }

  render() {
    const { defaultDashboard, history } = this.props;

    return (
      <>
        {/* <CustomModal2
          isOpen={this.state.displayError}
          yesAction={() => {
            this.resetError();
          }}
          yesText="OK"
          hideCancel={true}
          changeMaxWidth="600px"
          className="px-5"
        >
          <div
            style={{ width: '100%', marginBottom: '30px', textAlign: 'center', fontWeight: 'bold' }}
          >
            {this.state.error}
          </div>
        </CustomModal2> */}
        {/* <CustomModal2
          isOpen={this.props.displayJSError}
          yesAction={() => {
            this.props.resetError();
            history.push(defaultDashboard);
          }}
          noAction={() => {
            history.goBack();
          }}
          yesText="Redirect to Dashboard"
          noText="Go Back"
          changeMaxWidth="800px"
          className="px-5"
        >
          <div
            style={{ width: '100%', marginBottom: '30px', textAlign: 'center', fontWeight: 'bold' }}
          >
            {this.props.jsError}
          </div>
        </CustomModal2> */}
        {this.props.displayJSError ? (
          <div
            style={{
              padding: '10%',
              display: 'flex',
              'flex-direction': 'column',
              'align-items': 'center',
              'justify-content': 'center',
            }}
          >
            <div>{this.props.jsError}</div>
            <div>
              <button
                type="button"
                class="btn"
                onClick={() => {
                  this.props.resetError();
                  history.goBack();
                }}
              >
                Go Back
              </button>
              <button
                type="button"
                class="btn m-2"
                onClick={() => {
                  window.localStorage.clear();
                  window.sessionStorage.clear();
                  window.location.href = `${window.location.origin}`;
                }}
              >
                Clear Cache and Refresh
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ display_error, default_values }) => {
  var { data } = display_error;
  const { data: DefaultData } = default_values;
  var defaultDashboard = 'dashboardNew';
  if (DefaultData) {
    defaultDashboard = DefaultData.defaultDashboard;
  }
  var error = '';
  if (data) {
    error = data.error;
  }
  return { error, defaultDashboard };
};

export default connect(mapStateToProps)(withRouter(CustomError));
