import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { ReduxSelect, ReduxInput } from '../../../../../component/custom-redux-components';
import { ProcessCombos } from '../../../../../store/comboConfig';
import { Field, reduxForm } from 'redux-form';
import {
  upsertTreatmentPlanDimension,
  getTreatmentPlan,
  comboProblemTemplate,
} from '../../../../../store/actions';
import SubmitButton from '../../../../../Components/submitButton';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    // if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
    // } else {
    //   return 'should be greater than Start time';
    // }

    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class AddDimensionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      comboProblemDataState: [],
    };
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  componentDidMount() {
    var result = ProcessCombos('CurrentRiskRating');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  componentWillReceiveProps({ upsertTreatmentPlanDimensionDataSuccess }) {
    if (
      upsertTreatmentPlanDimensionDataSuccess &&
      upsertTreatmentPlanDimensionDataSuccess != this.props.upsertTreatmentPlanDimensionDataSuccess
    ) {
      this.props.toggleDimensionModal();
      this.props.resetMessageText(
        `Dimension ${this.props.treatmentPlanDimensionId ? 'Updated' : 'Added'} Successfully`,
        {
          Json: JSON.stringify({
            TreatmentPlan: [
              {
                // TreatmentPlanId: this.props.clikedTreatmentPlanId,
                TreatmentPlanDimensionId: this.props.treatmentPlanDimensionId,
              },
            ],
          }),
        },
      );
      // let data = {
      //   Json: JSON.stringify({
      //     TreatmentPlan: [
      //       {
      //         // TreatmentPlanId: this.props.clikedTreatmentPlanId,
      //         TreatmentPlanDimensionId: this.props.treatmentPlanDimensionId,
      //       },
      //     ],
      //   }),
      // };
      // this.props.dispatch(getTreatmentPlan(data));
    }
  }

  onhandleSubmit = val => {
    const { treatmentPlanDimensionId } = this.props;
    let data;
    if (treatmentPlanDimensionId) {
      data = {
        Json: JSON.stringify({
          TreatmentPlanDimension: [
            {
              TreatmentPlanDimensionId: treatmentPlanDimensionId,
              InitialRiskRating: val.initialRiskRating,
              CurrentRiskRating: val.currentRiskRating,
              DimensionName: val.dimensionName,
            },
          ],
        }),
      };
    } else {
      data = {
        Json: JSON.stringify({
          TreatmentPlanDimension: [
            {
              TreatmentPlanDimensionId: treatmentPlanDimensionId,
              InitialRiskRating: val.initialRiskRating,
              CurrentRiskRating: val.currentRiskRating,
              DimensionName: val.dimensionName,
            },
          ],
        }),
      };
    }

    this.props.dispatch(upsertTreatmentPlanDimension(data));
    // this.props.toggleDimensionModal();
  };

  next = () => {
    this.carousel.next();
  };
  previous = () => {
    this.carousel.prev();
  };
  render() {
    const { onCancel, CurrentRiskRatingDropdown, IsWithoutASAM, isFetching } = this.props;
    const { comboProblemDataState } = this.state;
    return (
      <>
        <div class="modal-content border-0">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={() => {
                this.props.toggleDimensionModal();

                this.props.resetInitialValues();
              }}
            >
              ×
            </button>
            <h4 class="modal-title col-12 text-left">{this.props.initialValues.dimensionTitle}</h4>
            {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
          </div>
          <div class="modal-body rcm-panel">
            <div className="px-4 enrollment-form">
              <div style={{ background: 'white' }}>
                {/* <Slider {...settings}>
                  {this.props.comboProblemData &&
                    this.props.comboProblemData.map(item => {
                      return (
                        <div>
                          <p
                            style={{ border: '1px solid #bfbfbf', height: '50px', padding: '5px' }}
                          >
                            {item.Text}
                          </p>
                        </div>
                      );
                    })}
                </Slider> */}

                <br />
              </div>
              {/* <br />
              <br />
              <br />
              <br />
              <br /> */}

              <form onSubmit={this.props.handleSubmit(this.onhandleSubmit)}>
                <div className="">
                  <div class="form-group mb-2">
                    <Field
                      name="initialRiskRating"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Initial Risk Rating'}
                      validate={Validator.required}
                      min={0}
                      max={5}
                      controlledMaxAge={true}
                      disabled={!IsWithoutASAM}
                    />
                    <label class="placeholder-label">Initial Risk Rating</label>
                  </div>
                  {IsWithoutASAM && (
                    <div class="form-group mb-2">
                      <Field
                        name="dimensionName"
                        type="text"
                        component={ReduxInput}
                        fieldName={'Dimension Name'}
                        validate={Validator.required}
                      />
                      <label class="placeholder-label">Dimension Name</label>
                    </div>
                  )}
                  <div class="form-group">
                    <Field
                      name="currentRiskRating"
                      type="text"
                      // errorMessage="Population Type Disorder Required"
                      component={ReduxSelect}
                      validate={Validator.required}
                      options={CurrentRiskRatingDropdown}
                      placeholder="Current Risk Rating*"
                      fieldName={'Current Risk Rating'}
                    />
                  </div>

                  {/* <div class="form-group mb-2">
                    <Field
                      name="currentRiskRating"
                      type="number"
                      component={ReduxInput}
                      fieldName={'Current Risk Rating'}
                      validate={Validator.required}
                      min={0}
                      max={5}
                      controlledMaxAge={true}
                    />
                    <label class="placeholder-label">Current Risk Rating *</label>
                  </div> */}
                </div>
                <div class="modal-footer  border-top-0 pt-2 px-0 mr-0">
                  {(this.props.initialValues.initialRiskRating &&
                    this.props.initialValues.initialRiskRating == null) ||
                  0 ? (
                    <SubmitButton
                      style={{ padding: '0 24px', height: '44px' }}
                      value=" Add Rating"
                      loading={isFetching}
                    />
                  ) : (
                    <SubmitButton
                      style={{ padding: '0 24px', height: '44px' }}
                      value="Update"
                      loading={isFetching}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ upsert_treatment_plan_dimension, combos }) => {
  const { data: upsertTreatmentPlanDimensionData, isFetching } = upsert_treatment_plan_dimension;
  let upsertTreatmentPlanDimensionDataSuccess;
  let CurrentRiskRatingDropdown;
  if (upsertTreatmentPlanDimensionData && upsertTreatmentPlanDimensionData.success) {
    upsertTreatmentPlanDimensionDataSuccess = upsertTreatmentPlanDimensionData.success;
  }
  if (combos.data && combos.data['CurrentRiskRating']) {
    CurrentRiskRatingDropdown = combos.data['CurrentRiskRating'];
  }
  return {
    CurrentRiskRatingDropdown,
    upsertTreatmentPlanDimensionDataSuccess: upsertTreatmentPlanDimensionDataSuccess || false,
    isFetching,
  };
};

const ReduxAddDimensionModal = reduxForm({
  form: 'AddDimensionModal',
  enableReinitialize: true,
})(connect(mapStateToProps)(AddDimensionModal));

export { ReduxAddDimensionModal };
