import React, { Component } from 'react';
import ListRenderer from '../Components/listRenderer';
import {
  getUserRole,
  getListJobTitle,
  updateJobTitle,
  updateRole,
  deleteRole,
  deleteJobTitle,
  updateRolePermissions,
  updateJobTitleSync,
  resetUpdateJobTitle,
  resetUpdateRole,
  resetDeleteRole,
  resetDeleteJobTitle,
  resetUpdateRolePermissions,
  resetUpdateJobTitleSync,
} from '../store/actions';
import { ProcessCombos } from '../store/comboConfig';
import { connect } from 'react-redux';
import CustomModal from '../Components/CustomModal';
import Loader from '../Components/Loader';
import { Link } from 'react-router-dom';

class AddJobTitle extends Component {
  state = {
    jobName: this.props.jobTitle ? this.props.jobTitle.Name : '',
    jobDescription: this.props.jobTitle ? this.props.jobTitle.Description : '',
  };

  render() {
    const { onOk, onCancel, addJobTitle, edit, jobTitle } = this.props;
    const { jobName, jobDescription } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">{edit ? 'Edit' : 'Add'} a Job Title</h4>
        </div>
        <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              value={jobName}
              onChange={e => this.setState({ jobName: e.target.value })}
              placeholder="Enter Job Title"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              value={jobDescription}
              onChange={e => this.setState({ jobDescription: e.target.value })}
              placeholder="Enter Job Description"
            />
          </div>
        </div>
        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" class="btn black-btn font-weight-bold mr-2" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              onOk();
              addJobTitle(jobName, jobDescription, jobTitle && jobTitle.JobTitleId);
            }}
            class="btn"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

class AddRole extends Component {
  state = {
    roleName: this.props.role ? this.props.role.Name : '',
    roleDescription: this.props.role ? this.props.role.Description : '',
  };
  render() {
    const { onOk, onCancel, addRole, edit, role } = this.props;
    const { roleName, roleDescription } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">{edit ? 'Edit' : 'Add'} a Role</h4>
        </div>
        <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              value={roleName}
              onChange={e => this.setState({ roleName: e.target.value })}
              placeholder="Enter Role"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              value={roleDescription}
              onChange={e => this.setState({ roleDescription: e.target.value })}
              placeholder="Enter Role Description"
            />
          </div>
        </div>
        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" class="btn black-btn font-weight-bold mr-2" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              onOk();
              addRole(roleName, roleDescription, role && role.RoleId);
            }}
            class="btn"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

class userMangementRole extends Component {
  state = {
    activeKey: 0,
    rolePermission: [],
    jobTitleRole: [],
    roleName: '',
    roleId: '',
    permissions: null,
    roles: [],
    jobTitlePermission: [],
    jobTitleId: null,
    jobTitleName: '',
    showB: false,
  };

  componentDidMount() {
    this.props.dispatch(getUserRole());
    this.props.dispatch(getListJobTitle());

    var result = ProcessCombos('Permissions');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }

    // Manage Job Titles - Get Job Titles for left side slider menu
    // UserRole/ListJobTitle
    // Manage Roles - Get Roles for left side slider menu
    // UserRole/List
  }

  addRole = (Name, Description, RoleId) => {
    // Add +ADD after the header ROLES like in Manage Job Titles and call api
    // Display popup on click of it ADD and show Name and Description in Popup
    // Pass RoleId if selected existing item ie pencil button
    // UserRole/Upsert
    var payload = {
      RoleId,
      Json: JSON.stringify({
        Role: [{ Name, Description }],
      }),
    };
    // UserRole/Upsert
    this.props.dispatch(updateRole(payload));
  };

  addJobTitle = (Name, Description, JobTitleId) => {
    var payload = {
      JobTitleId,
      Json: JSON.stringify({ JobTitle: [{ Name, Description }] }),
    };
    // UserRole/UpsertJobTitle
    this.props.dispatch(updateJobTitle(payload));
  };

  deleteRole = RoleId => {
    var json = {
      RoleId, // replace 0 with value;
    };
    // UserRole/Delete
    this.props.dispatch(deleteRole(json));
  };

  deleteJobTitle = JobTitleId => {
    var json = {
      JobTitleId, // replace 0 with value;
    };
    // UserRole/DeleteJobTitle
    this.props.dispatch(deleteJobTitle(json));
  };

  syncRoles = () => {
    let { roleId, permissions } = this.state;
    if (permissions.length > 0) {
      permissions = permissions.filter(item => item.Value);
      for (let i = 0; i < permissions.length; i++) delete permissions[i].Value;
    }
    var payload = {
      RoleId: roleId, // pass it
      Json: JSON.stringify({ RolePermission: permissions }),
    };
    // UserRole/UpdatePermissions
    this.props.dispatch(updateRolePermissions(payload));
  };

  syncJobTitle = () => {
    let { jobTitleId, roles } = this.state;
    let jobTitleRole = [];
    if (roles.length > 0) {
      roles = roles.filter(item => item.Value);
      for (let i = 0; i < roles.length; i++) jobTitleRole.push({ RoleId: roles[i].RoleId });
    }
    var payload = {
      JobTitleId: jobTitleId, // pass it
      Json: JSON.stringify({ JobTitleRole: jobTitleRole }),
    };
    // UserRole/JobTitleRoleSync
    this.props.dispatch(updateJobTitleSync(payload));
  };

  permissionRole = (permissionId, bool) => {
    const { selectedPermissionId } = this.state;
    if (bool) {
      selectedPermissionId.push(permissionId);
    } else {
      for (let i = 0; i < selectedPermissionId.length; i++) {
        if (selectedPermissionId[i] == permissionId) {
          selectedPermissionId.splice(i, 1);
        }
      }
    }
    this.setState({ selectedPermissionId });
    // return bool;
  };

  onSave = () => {
    const { activeKey } = this.state;
    if (activeKey == 0) {
      this.syncJobTitle();
    } else if (activeKey == 1) {
      this.syncRoles();
    }
  };

  componentWillReceiveProps({
    permissions,
    roles,
    jobTitles,
    isUpdateJobTitle,
    isUpdateRole,
    isDeleteJobTitle,
    isDeleteRole,
    isRolePermissionSync,
    isJobTitleSync,
  }) {
    if (roles.length > 0) {
      this.setState({ roles });
    }
    if (permissions) this.setState({ permissions });
    if (isUpdateJobTitle || isDeleteJobTitle || isJobTitleSync)
      this.props.dispatch(getListJobTitle());
    if (isUpdateRole || isDeleteRole || isRolePermissionSync) this.props.dispatch(getUserRole());
    if (isUpdateJobTitle) this.props.dispatch(resetUpdateJobTitle());
    if (isUpdateRole) this.props.dispatch(resetUpdateRole());
    if (isDeleteJobTitle) this.props.dispatch(resetDeleteJobTitle());
    if (isDeleteRole) this.props.dispatch(resetDeleteRole());
    if (isRolePermissionSync) this.props.dispatch(resetUpdateRolePermissions());
    if (isJobTitleSync) this.props.dispatch(resetUpdateJobTitleSync());
  }

  getUnique = array => [...new Set(array.map(item => item.PermissionId))];

  render() {
    const {
      activeKey,
      roleName,
      permissions,
      roles,
      jobTitlePermission,
      jobTitleId,
      roleId,
      jobTitleName,
    } = this.state;
    const { jobTitles, loading } = this.props;
    const list = [
      {
        Name: <span class="border-image">Manage Job Titles</span>,
        ExtraComponent: () => {
          return (
            <div>
              <div>
                <p class="manage-req">
                  <i class="far fa-sticky-note" style={{ marginRight: '15px' }} /> Please select a
                  Job Title on the left and with check boxes select the roles you want to assign
                </p>
              </div>
              <div class="manage-job-row admin-manage-row">
                <div class="admin-manage-col admin-job-title">
                  <div class="title d-flex justify-content-between">
                    Job Titles
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <a class="add-job-title" onClick={onClick} style={{ color: '#46b4fe' }}>
                          + Add
                        </a>
                      )}
                      ModalContent={props => (
                        <AddJobTitle addJobTitle={this.addJobTitle} {...props} />
                      )}
                    />
                  </div>
                  <ul>
                    {jobTitles &&
                      jobTitles.map((jobTitle, index) => {
                        return (
                          <li class={jobTitleId === jobTitle.JobTitleId && 'active'}>
                            <a>
                              <div
                                onClick={() => {
                                  if (this.props.roles) {
                                    let rol = [...this.props.roles],
                                      jobTitlePermissions = [];
                                    for (let j = 0; j < rol.length; j++)
                                      if (typeof rol[j].Value === 'boolean') delete rol[j].Value;
                                    if (jobTitle.JobTitleRole && jobTitle.JobTitleRole.length > 0)
                                      for (let i = 0; i < jobTitle.JobTitleRole.length; i++) {
                                        for (let j = 0; j < rol.length; j++) {
                                          if (rol[j].RoleId === jobTitle.JobTitleRole[i].RoleId) {
                                            rol[j].Value = true;
                                          }
                                        }
                                      }
                                    for (let i = 0; i < rol.length; i++) {
                                      if (rol[i] && rol[i].Value && rol[i].RolePermission)
                                        jobTitlePermissions.push(...rol[i].RolePermission);
                                    }
                                    this.setState({
                                      jobTitleId: jobTitle.JobTitleId,
                                      roles: rol,
                                      jobTitlePermission: jobTitlePermissions,
                                      jobTitleName: jobTitle.Name,
                                      showB: jobTitle.IsLocked,
                                    });
                                  }
                                }}
                                style={{ width: '75%' }}
                              >
                                {jobTitle.Name}
                              </div>
                              {jobTitle.IsLocked !== true && (
                                <span class="actions">
                                  <CustomModal
                                    ModalButton={({ onClick }) => (
                                      <svg
                                        onClick={onClick}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 32 32"
                                      >
                                        <path
                                          fill="#FFF"
                                          fill-rule="nonzero"
                                          d="M24.828 7.172A3.975 3.975 0 0 0 21.998 6a3.976 3.976 0 0 0-2.83 1.172L7.986 18.356a.781.781 0 0 0-.2.342L6.03 25.01a.781.781 0 0 0 .966.961l6.31-1.792a.781.781 0 0 0 .34-1.303l-3.978-3.991 9.27-9.27 3.447 3.447-7.096 7.078a.781.781 0 0 0 1.103 1.106l8.437-8.414A3.975 3.975 0 0 0 26 10.001a3.975 3.975 0 0 0-1.172-2.829zM11.601 23.037l-3.694 1.05 1.035-3.717 2.66 2.667zm12.123-11.311l-.233.232-3.45-3.45.233-.231a2.423 2.423 0 0 1 1.724-.715c.652 0 1.264.254 1.725.715.46.46.715 1.073.715 1.725 0 .651-.254 1.264-.714 1.724z"
                                        />
                                      </svg>
                                    )}
                                    ModalContent={props => (
                                      <AddJobTitle
                                        edit
                                        jobTitle={jobTitle}
                                        addJobTitle={(name, description) =>
                                          this.addJobTitle(name, description, jobTitle.JobTitleId)
                                        }
                                        {...props}
                                      />
                                    )}
                                  />
                                  <CustomModal
                                    ModalButton={({ onClick }) => (
                                      <svg
                                        onClick={onClick}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 32 32"
                                      >
                                        <path
                                          fill="#FFF"
                                          fill-rule="nonzero"
                                          d="M16.219 12.367c.431 0 .781.35.781.781v8.943a.781.781 0 1 1-1.563 0v-8.943c0-.431.35-.78.782-.78zm2.735.747l-.39 8.943a.781.781 0 0 0 1.56.068l.39-8.942a.781.781 0 0 0-1.56-.069zm-6.285-.746a.781.781 0 0 0-.746.815l.39 8.942a.781.781 0 1 0 1.561-.068l-.39-8.943a.781.781 0 0 0-.815-.746zm12.768-2.345c0 .432-.35.782-.78.782h-.81l-.676 8.77a.781.781 0 0 1-1.558-.12l.667-8.65H10.157l.943 12.217a1.555 1.555 0 0 0 1.555 1.415h7.127c.814 0 1.483-.6 1.556-1.399a.781.781 0 1 1 1.556.142A3.1 3.1 0 0 1 19.782 26h-7.127c-.781 0-1.53-.29-2.107-.817a3.118 3.118 0 0 1-1.005-2.024v-.011L8.59 10.805h-.809a.781.781 0 1 1 0-1.563h4.727v-.898A2.346 2.346 0 0 1 14.852 6h2.734a2.346 2.346 0 0 1 2.344 2.344v.898h4.726c.432 0 .782.35.782.781zm-11.367-.78h4.297v-.9c0-.43-.35-.78-.781-.78h-2.734c-.431 0-.782.35-.782.78v.9z"
                                        />
                                      </svg>
                                    )}
                                    ModalContent={({ onOk, onCancel }) => (
                                      <div class="modal-content border-0">
                                        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
                                          <button type="button" class="close" onClick={onCancel}>
                                            ×
                                          </button>
                                        </div>
                                        <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
                                          <h4 class="">
                                            Are you sure you want to delete{' '}
                                            <span>{jobTitle.Name}</span> title?
                                          </h4>
                                        </div>
                                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                          <button
                                            type="button"
                                            class="btn black-btn font-weight-bold mr-2"
                                            onClick={onCancel}
                                          >
                                            No
                                          </button>
                                          <button
                                            type="button"
                                            class="btn"
                                            onClick={() => {
                                              onOk();
                                              this.deleteJobTitle(jobTitle.JobTitleId);
                                            }}
                                          >
                                            Yes
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  />
                                </span>
                              )}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div class="admin-manage-col admin-job-roles set-permissions">
                  <div class="title">Roles: {jobTitleName}</div>
                  <ul class="permissions-list">
                    {roles &&
                      roles.map((role, index) => {
                        return (
                          <li>
                            <label class="custom-checkbox">
                              <input
                                type="checkbox"
                                checked={role.Value}
                                onChange={e => {
                                  const jbRole = [...roles],
                                    jobTitlePermissions = [...this.state.jobTitlePermission];
                                  if (jobTitleId) {
                                    jbRole[index].Value = e.target.checked;
                                    if (e.target.checked && jbRole[index].RolePermission) {
                                      jobTitlePermissions.push(...jbRole[index].RolePermission);
                                    } else {
                                      if (jbRole[index].RolePermission)
                                        for (
                                          let i = 0;
                                          i < jbRole[index].RolePermission.length;
                                          i++
                                        ) {
                                          for (let j = 0; j < jobTitlePermissions.length; j++)
                                            if (
                                              jobTitlePermissions[j].PermissionId ===
                                              jbRole[index].RolePermission[i].PermissionId
                                            ) {
                                              jobTitlePermissions.splice(j, 1);
                                              break;
                                            }
                                        }
                                    }
                                    this.setState({
                                      roles: jbRole,
                                      jobTitlePermission: jobTitlePermissions,
                                    });
                                  }
                                }}
                              />
                              <em class="checkmark" />
                              <span class="permissions-title d-block">{role.Name}</span>
                              <span>
                                {role.RolePermission &&
                                  role.RolePermission.map(permissionid => {
                                    if (permissionid.PermissionId) {
                                      var permission =
                                        this.props.permissions &&
                                        this.props.permissions.filter(item => {
                                          return permissionid.PermissionId === item.PermissionId;
                                        });
                                    }
                                    if (permission) {
                                      return (
                                        <span>
                                          {permission[0].Name}
                                          <em>,</em>{' '}
                                        </span>
                                      );
                                    }
                                  })}
                              </span>
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div class="admin-manage-col admin-permissions ">
                  <div class="title">All Permissions</div>
                  <ul>
                    {this.props.permissions &&
                      this.getUnique(jobTitlePermission).map(item => {
                        return this.props.permissions.map(permission => {
                          if (item === permission.PermissionId)
                            return (
                              <li>
                                <a>{permission.Name}</a>
                              </li>
                            );
                        });
                      })}
                  </ul>
                </div>
              </div>
              <div>
                {!this.state.showB && (
                  <div class="col-12 text-right">
                    <button class="btn black-btn">Cancel</button>&nbsp;
                    <button class="btn" onClick={this.onSave}>
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      // {
      //   Name: <span class="border-image">Manage Roles</span>,
      //   ExtraComponent: () => {
      //     return (
      //       <div class="manage-roles-row admin-manage-row">
      //         <div class="admin-manage-col admin-job-title">
      //           <div class="title d-flex justify-content-between">
      //             Roles
      //             <CustomModal
      //               ModalButton={({ onClick }) => (
      //                 <a class="add-job-title" onClick={onClick} style={{ color: '#46b4fe' }}>
      //                   + Add
      //                 </a>
      //               )}
      //               ModalContent={props => <AddRole addRole={this.addRole} {...props} />}
      //             />
      //           </div>
      //           <ul>
      //             {roles &&
      //               roles.map(role => {
      //                 return (
      //                   <li class={roleId === role.RoleId && 'active'}>
      //                     <a>
      //                       <div
      //                         onClick={() => {
      //                           if (this.props.permissions) {
      //                             let par = [...this.props.permissions];
      //                             for (let i = 0; i < par.length; i++)
      //                               if (typeof par[i].Value === 'boolean') delete par[i].Value;
      //                             if (role.RolePermission)
      //                               for (let i = 0; i < role.RolePermission.length; i++) {
      //                                 for (let j = 0; j < par.length; j++) {
      //                                   if (
      //                                     par[j].PermissionId == role.RolePermission[i].PermissionId
      //                                   )
      //                                     par[j].Value = true;
      //                                 }
      //                               }
      //                             this.setState({
      //                               roleName: role.Name,
      //                               roleId: role.RoleId,
      //                               permissions: par,
      //                             });
      //                           }
      //                         }}
      //                         style={{ width: '75%' }}
      //                       >
      //                         {role.Name}
      //                       </div>
      //                       <span class="actions">
      //                         <CustomModal
      //                           ModalButton={({ onClick }) => (
      //                             <svg
      //                               xmlns="http://www.w3.org/2000/svg"
      //                               width="24"
      //                               height="24"
      //                               viewBox="0 0 32 32"
      //                             >
      //                               <path
      //                                 fill="#FFF"
      //                                 fill-rule="nonzero"
      //                                 d="M24.828 7.172A3.975 3.975 0 0 0 21.998 6a3.976 3.976 0 0 0-2.83 1.172L7.986 18.356a.781.781 0 0 0-.2.342L6.03 25.01a.781.781 0 0 0 .966.961l6.31-1.792a.781.781 0 0 0 .34-1.303l-3.978-3.991 9.27-9.27 3.447 3.447-7.096 7.078a.781.781 0 0 0 1.103 1.106l8.437-8.414A3.975 3.975 0 0 0 26 10.001a3.975 3.975 0 0 0-1.172-2.829zM11.601 23.037l-3.694 1.05 1.035-3.717 2.66 2.667zm12.123-11.311l-.233.232-3.45-3.45.233-.231a2.423 2.423 0 0 1 1.724-.715c.652 0 1.264.254 1.725.715.46.46.715 1.073.715 1.725 0 .651-.254 1.264-.714 1.724z"
      //                               />
      //                             </svg>
      //                           )}
      //                           ModalContent={props => (
      //                             <AddRole edit role={role} addRole={this.addRole} {...props} />
      //                           )}
      //                         />
      //                         <CustomModal
      //                           ModalButton={({ onClick }) => (
      //                             <svg
      //                               xmlns="http://www.w3.org/2000/svg"
      //                               width="24"
      //                               height="24"
      //                               viewBox="0 0 32 32"
      //                             >
      //                               <path
      //                                 fill="#FFF"
      //                                 fill-rule="nonzero"
      //                                 d="M16.219 12.367c.431 0 .781.35.781.781v8.943a.781.781 0 1 1-1.563 0v-8.943c0-.431.35-.78.782-.78zm2.735.747l-.39 8.943a.781.781 0 0 0 1.56.068l.39-8.942a.781.781 0 0 0-1.56-.069zm-6.285-.746a.781.781 0 0 0-.746.815l.39 8.942a.781.781 0 1 0 1.561-.068l-.39-8.943a.781.781 0 0 0-.815-.746zm12.768-2.345c0 .432-.35.782-.78.782h-.81l-.676 8.77a.781.781 0 0 1-1.558-.12l.667-8.65H10.157l.943 12.217a1.555 1.555 0 0 0 1.555 1.415h7.127c.814 0 1.483-.6 1.556-1.399a.781.781 0 1 1 1.556.142A3.1 3.1 0 0 1 19.782 26h-7.127c-.781 0-1.53-.29-2.107-.817a3.118 3.118 0 0 1-1.005-2.024v-.011L8.59 10.805h-.809a.781.781 0 1 1 0-1.563h4.727v-.898A2.346 2.346 0 0 1 14.852 6h2.734a2.346 2.346 0 0 1 2.344 2.344v.898h4.726c.432 0 .782.35.782.781zm-11.367-.78h4.297v-.9c0-.43-.35-.78-.781-.78h-2.734c-.431 0-.782.35-.782.78v.9z"
      //                               />
      //                             </svg>
      //                           )}
      //                           ModalContent={({ onOk, onCancel }) => (
      //                             <div class="modal-content border-0">
      //                               <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
      //                                 <button type="button" class="close" onClick={onCancel}>
      //                                   ×
      //                                 </button>
      //                               </div>
      //                               <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
      //                                 <h4 class="">
      //                                   Are you sure you want to delete <span>{role.Name}</span>{' '}
      //                                   role?
      //                                 </h4>
      //                               </div>
      //                               <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
      //                                 <button
      //                                   type="button"
      //                                   class="btn black-btn font-weight-bold mr-2"
      //                                   onClick={onCancel}
      //                                 >
      //                                   No
      //                                 </button>
      //                                 <button
      //                                   type="button"
      //                                   class="btn"
      //                                   onClick={() => {
      //                                     onOk();
      //                                     this.deleteRole(role.RoleId);
      //                                   }}
      //                                 >
      //                                   Yes
      //                                 </button>
      //                               </div>
      //                             </div>
      //                           )}
      //                         />
      //                       </span>
      //                     </a>
      //                   </li>
      //                 );
      //               })}
      //           </ul>
      //         </div>
      //         <div class="admin-manage-col admin-job-roles set-permissions">
      //           <div class="title">Permissions: {roleName}</div>
      //           <ul class="permissions-list">
      //             {permissions &&
      //               permissions.map((permissionItem, index) => {
      //                 // if (item.PermissionId == permissionItem.PermissionId) {
      //                 return (
      //                   <li>
      //                     <label class="custom-checkbox">
      //                       <input
      //                         type="checkbox"
      //                         checked={permissionItem.Value}
      //                         onChange={e => {
      //                           var perms = [...permissions];
      //                           perms[index].Value = e.target.checked;
      //                           this.setState({ permissions: perms });
      //                         }}
      //                       />
      //                       <em class="checkmark" />
      //                       <span class="permissions-title d-block">{permissionItem.Name}</span>
      //                       <span>{permissionItem.Name} info and detail</span>
      //                     </label>
      //                   </li>
      //                 );
      //                 // } else {
      //                 //   return null;
      //                 // }
      //               })}
      //           </ul>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
    ];

    return (
      <div class="main  mb-5 user-role-management-page">
        <Loader loading={loading} />
        <div class="container-fluid">
          <div class="row top-table-search mt-5 mb-3 align-items-center">
            <div class="col-12">
              {/* <Link to="/manage" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link> */}
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.goBack()}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="form-main-head mb-3 mb-md-0">User &amp; Role Management</h3>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 user-role-tabs">
              <ListRenderer
                list={list}
                activeKey={activeKey}
                getActiveKey={activeKey => this.setState({ activeKey })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  user_role_list,
  list_job_title,
  combos,
  update_job_title,
  update_role,
  delete_role,
  delete_job_title,
  update_role_permissions,
  update_job_title_sync,
}) => {
  var { data: roleData, isFetching: isFetchingRole, error } = user_role_list;
  var { data: jobData, isFetching: isFetchingJob, error } = list_job_title;
  var { data: combosData } = combos;
  let permissions,
    isUpdateJobTitle = false,
    isUpdateRole = false,
    isDeleteJobTitle = false,
    isDeleteRole = false,
    isJobTitleSync = false,
    isRolePermissionSync = false;
  if (combosData) {
    permissions = combosData.Permissions;
  }
  if (roleData) {
    roleData = JSON.parse(Object.values(roleData.data[0])).Role;
  }
  if (jobData) {
    jobData = JSON.parse(Object.values(jobData.data[0])).JobTitle;
  }

  if (update_job_title.data && update_job_title.data.success) {
    isUpdateJobTitle = true;
  }

  if (update_role.data && update_role.data.success) {
    isUpdateRole = true;
  }

  if (delete_role.data && delete_role.data.success) {
    isDeleteRole = true;
  }

  if (delete_job_title.data && delete_job_title.data.success) {
    isDeleteJobTitle = true;
  }

  if (update_job_title_sync.data && update_job_title_sync.data.success) {
    isJobTitleSync = true;
  }

  if (update_role_permissions.data && update_role_permissions.data.success) {
    isRolePermissionSync = true;
  }

  return {
    roles: roleData || [],
    jobTitles: jobData || [],
    permissions,
    loading: isFetchingRole || isFetchingJob,
    isUpdateJobTitle,
    isUpdateRole,
    isDeleteJobTitle,
    isDeleteRole,
    isRolePermissionSync,
    isJobTitleSync,
  };
};

export default connect(mapStateToProps)(userMangementRole);
