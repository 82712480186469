import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxTimePicker,
  ReduxInput,
  ReduxTextarea,
} from '../../../../component/custom-redux-components';
// import {} from '../../../store/actions';
// import Loader, { Loader2 } from '../../Components/Loader';

let GroupEventForm = ({ onSaveClick, onSubmit, handleSubmit, onCancel }) => {
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="template"
              type="text"
              placeholder={'Template'}
              // options={AdvisorList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Template'}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field name="title" type="text" component={ReduxInput} fieldName={'Title'} />
            <label class="placeholder-label">{'Title'}</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              component={ReduxDateInput}
              name={`sessionDate`}
              label="Session Date"
              // validate={validate.required}
              fieldName={'Session Date'}
              // disabled={disable}
            />
            <i class="far fa-calendar-alt form-control-icon" />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="serviceLine"
              type="text"
              placeholder={'Service Line'}
              // options={AdvisorList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Service Line'}
              // disabled={disable}
            />
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="Program"
              type="text"
              placeholder={'Program'}
              // options={AdvisorList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Program'}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <Field
              name="Program1"
              type="text"
              placeholder={'Program'}
              // options={AdvisorList || []}
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Program'}
              // disabled={disable}
            />
          </div>
        </div>
        <div className="col-4">
          <div class="form-group mb-2">
            <Field
              name="startTime"
              type="text"
              component={ReduxTimePicker}
              fieldName={'Start Time'}
              // validate={Validator.required}
              label={'Start Time*'}
              minuteStep={5}
            />
          </div>
        </div>
        <div className="col-4">
          <div class="form-group mb-2">
            <Field
              name="endTime"
              type="text"
              component={ReduxTimePicker}
              fieldName={'End Time'}
              // validate={Validator.required}
              label={'End Time*'}
              minuteStep={5}
            />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field
              name="groupDuration"
              type="text"
              component={ReduxInput}
              // validate={Validator.required}
              fieldName={'Group Duration'}
            />
            <label class="placeholder-label">Group Duration</label>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <div class="form-group">
            <Field
              name={`groupNote`}
              type="textarea"
              component={ReduxTextarea}
              // validate={validate.required}
              // disabled={disable}
            />
            <label class="placeholder-label">Group Note</label>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`individualNote`}
              type="textarea"
              component={ReduxTextarea}
              // validate={validate.required}
              // disabled={disable}
            />
            <label class="placeholder-label">Individual Note</label>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`individualNote`}
              type="textarea"
              component={ReduxTextarea}
              // validate={validate.required}
              // disabled={disable}
            />
            <label class="placeholder-label">Individual Note</label>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center border-top-0 w-100">
        <input
          type="button"
          class="btn btn-outline-primary mr-2"
          value="Cancel"
          onClick={onCancel}
        />
        <input
          type="submit"
          class="btn black-btn font-weight-bold mr-2"
          value=" Update Changes  "
        />
      </div>
    </form>
  );
};
const ReduxGroupEventForm = reduxForm({
  form: 'caseForm',
  enableReinitialize: true,
})(connect()(withRouter(GroupEventForm)));

class GroupEventModal extends Component {
  constructor() {
    super();
    this.state = {
      caseDetail: {},
    };
  }
  onhandleSubmit = val => {
    const {
      CaseID,
      LegalFirstName,
      LegalMiddleName,
      LegalLastName,
      ProgramType,
      ProbationOfficer,
    } = val;
    // this.props.dispatch(
    //   appSubscribersupdateCase({
    //     UserGuid: this.props.UserGuid,
    //     json: JSON.stringify({
    //       Misc: [
    //         {
    //           ProgramTypeId: ProgramType,
    //           ExternalId: CaseID,
    //           LegalFirstname: LegalFirstName,
    //           LegalMiddlename: LegalMiddleName,
    //           LegalLastname: LegalLastName,
    //           AdvisorStaffId: ProbationOfficer,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  };
  componentDidMount() {
    // this.props.dispatch(loadCaseProfile({ UserGuid: this.props.UserGuid }));
  }
  componentWillReceiveProps({ isSuccess, caseDetail }) {
    if (isSuccess && isSuccess !== this.props.isSuccess) {
      // success();
      // this.props.dispatch(getAppSubscribersProfile({ UserGuid: this.props.UserGuid }));
      this.props.onCancel();
      this.props.dispatch(reset('caseForm'));
    }
  }
  render() {
    const { onOk, onCancel, Name, isFetching, CenterProgramType } = this.props;
    const { caseDetail, initData } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 mb-3">Group Event Note</h4>
        </div>

        <div class="modal-body col-md-12 px-5 enrollment-form">
          {' '}
          {/* <Loader2 loading={isFetching} /> */}
          <ReduxGroupEventForm onSaveClick={this.onhandleSubmit.bind(this)} onCancel={onCancel} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { load_case_profile, update_app_subscribers_case, combos } = state;
  const { data: caseData, isFetching: isCaseFetching } = load_case_profile;
  const { data, isFetching } = update_app_subscribers_case;
  let isSuccess = false;
  let caseDetail = null;

  if (caseData && caseData.AppUserMiscInfo) {
    caseDetail = caseData.AppUserMiscInfo[0] || {};
  }
  if (update_app_subscribers_case.data && update_app_subscribers_case.data.success) {
    isSuccess = data.success;
  }

  return {
    caseDetail,
    isSuccess,
    isFetching: isFetching || isCaseFetching,
    CenterProgramType: (combos.data && combos.data.CenterProgramType) || [],

    // name: selector(state, 'firstName'),
  };
};

export default connect(mapStateToProps)(withRouter(GroupEventModal));
