import { Collapse, Drawer } from 'antd';
import React, { Component, useState } from 'react';
import AIAugmentedSummary from '../customScreens/surveyResponse/AIAugmentedSummary';
import AntdModal from '../../Components/CustomModal';

const { Panel } = Collapse;

var activePanel = 1;
var activePanel1 = 1;

class PanelHeader extends Component {
  render() {
    const { headerData } = this.props;
    let color = headerData.Color === 'blue' ? 'blue' : 'default';
    const statusText = {
      default: {
        text: 'Up Next',
        class: 'fa fa-lock mr-1',
      },
      blue: {
        text: 'Current Level',
        class: '',
      },
      green: {
        text: 'Level Achieved',
        class: 'fa fa-check',
      },
    };
    return (
      <div className="panel-header row">
        <div className="col-3 px-0">
          <div className="panel-header-left">
            <div className="outcome-icon">
              <img src={`images/Outcomes@2x_${color}.png`} alt="Outcomes" />
              <span>{headerData.Level}</span>
            </div>
            <span className="day-count">{headerData.Range}</span>
          </div>
        </div>
        <div className="col-9">
          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap">
              <span className="panel-title mr-2">{headerData.Heading}</span>
              <div className="mr-2 panel-title-status">
                <i class={statusText[headerData.Color].class} /> {statusText[headerData.Color].text}
              </div>
              {headerData.CompletedDate && (
                <span className="panel-date">{headerData.CompletedDate}</span>
              )}
            </div>
            <span className="panel-desc">{headerData.LevelDefinition}</span>
          </div>
        </div>
      </div>
    );
  }
}

class FullDetailModal extends Component {
  render() {
    const { onOk, onCancel, StudentOutcome } = this.props;

    return (
      <div class="modal-content border-0">
        <div class="modal-header  border-bottom-0 mb-2">
          {/* <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button> */}
          <h4 class="modal-title">Ladders & Measures</h4>
        </div>
        <div class="modal-body pt-3 mx-2 pb-0">
          <div class="row">
            <div class="col-12">
              <Collapse
                accordion
                bordered={false}
                defaultActiveKey={activePanel + 'blue'}
                expandIconPosition="right"
                // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                {StudentOutcome &&
                  StudentOutcome.map((item, index) => {
                    activePanel = index;
                    return (
                      <Panel
                        header={<PanelHeader headerData={item} />}
                        key={activePanel + item.Color}
                        className={`site-collapse-custom-panel-${item.Color}`}
                        showArrow={false}
                        style={{ height: '100%' }}
                      >
                        <div className="panel-text">
                          <ul>
                            {item.Question && item.Question.map(ques => <li>{ques.Question}</li>)}
                          </ul>
                          {this.props.AugmentedAIStatusData &&
                            this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
                            this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
                            this.props.AugmentedAIStatusData.LicenseStatusId != 5 &&
                            item.Question &&
                            item.Question.length > 0 && (
                              <div className="d-flex justify-content-end">
                                <button
                                  // title="Augment with AI"
                                  class="ai-btn"
                                  onClick={() =>
                                    // this.setState({ isAugmentDrawerOpen: true })
                                    this.props.toggleAugmentDrawer()
                                  }
                                  style={{
                                    display: 'inline',
                                    padding: '5px 20px',
                                  }}
                                >
                                  <figure
                                    class="action-button-icon"
                                    style={{ display: 'inline-block' }}
                                  >
                                    <img src="images/AIStar.svg" alt="" width="23" />
                                  </figure>
                                  Upgrade Level
                                </button>
                              </div>
                            )}
                        </div>
                      </Panel>
                    );
                  })}
              </Collapse>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center border-top-0 pb-3">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </button>
          {/* <button type="button" class="btn " onClick={onOk}>
            OK
          </button> */}
        </div>
      </div>
    );
  }
}

export default function RecoveryJourney(props) {
  const { StudentOutcome, StudentOutcomeData, AugmentedAIStatusData, UserGuid } = props;
  let [isAugmentDrawerOpen, setIsAugmentDrawerOpen] = useState(false);

  const toggleAugmentDrawer = () => {
    setIsAugmentDrawerOpen(prevState => !prevState);
  };
  return (
    <>
      <div class="col-12 mb-5">
        <div class="journey-title">
          <h5>Recovery Journey</h5>
        </div>
        {StudentOutcome && StudentOutcome.length > 0 ? (
          <>
            <div class="row">
              <div class="col-12">
                <Collapse
                  accordion
                  bordered={false}
                  defaultActiveKey={activePanel1 + 'blue'}
                  // expandIconPosition="right"
                  // expandIcon={({ isActive }) => (
                  //   <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  // )}
                  className="site-collapse-custom-collapse"
                >
                  {StudentOutcomeData &&
                    StudentOutcomeData.map((item, index) => {
                      activePanel1 = index;
                      return (
                        <Panel
                          header={<PanelHeader headerData={item} />}
                          key={activePanel1 + item.Color}
                          className={`site-collapse-custom-panel-${item.Color}`}
                          showArrow={false}
                        >
                          <div className="panel-text">
                            <ul>
                              {item.Question && item.Question.map(ques => <li>{ques.Question}</li>)}
                            </ul>
                            {props.AugmentedAIStatusData &&
                              props.AugmentedAIStatusData.LicenseStatusId != 1 &&
                              props.AugmentedAIStatusData.LicenseStatusId != 3 &&
                              props.AugmentedAIStatusData.LicenseStatusId != 5 &&
                              item.Question &&
                              item.Question.length > 0 && (
                                <div className="d-flex justify-content-end">
                                  <button
                                    // title="Augment with AI"
                                    class="ai-btn"
                                    onClick={() =>
                                      // this.setState({ isAugmentDrawerOpen: true })
                                      toggleAugmentDrawer()
                                    }
                                    style={{
                                      display: 'inline',
                                      padding: '5px 20px',
                                    }}
                                  >
                                    <figure
                                      class="action-button-icon"
                                      style={{ display: 'inline-block' }}
                                    >
                                      <img src="images/AIStar.svg" alt="" width="23" />
                                    </figure>
                                    Upgrade Level
                                  </button>
                                </div>
                              )}
                          </div>
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>
              <div class="col-12 text-right">
                {StudentOutcome.length > 2 && (
                  <AntdModal
                    modalWidth={700}
                    ModalButton={({ onClick }) => (
                      <button class="btn m-1" onClick={onClick}>
                        See Full Detail
                      </button>
                    )}
                    ModalContent={props => (
                      <FullDetailModal
                        // history={this.props.history}
                        StudentOutcome={StudentOutcome}
                        AugmentedAIStatusData={AugmentedAIStatusData}
                        toggleAugmentDrawer={toggleAugmentDrawer}
                        {...props}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-center">
              <h5>No Data</h5>
            </div>
          </>
        )}

        <Drawer
          className="custom-drawer journal-augmenting"
          title="Augment with AI"
          placement="right"
          open={isAugmentDrawerOpen}
          width={800}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          destroyOnClose={true}
          onClose={() => {
            // this.props.dispatch(resetSurveyAnswersGet());
            toggleAugmentDrawer();
          }}
        >
          <AIAugmentedSummary
            ClientId={props.studentId}
            toggleDrawer={toggleAugmentDrawer}
            // isMoodAugment={true}
            SurveyIds={props.psychosocialSurveys}
            UserGuid={UserGuid}
            // ColData={surveyanswersdatacol}
            // augmentId={this.state.augmentId}
            // changeAugmentId={changeAugmentId}
            // activekey={activekey}
            // UserGuid={props.UserGuid}
          />
        </Drawer>
      </div>
    </>
  );
}
