import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ExternalStaffList from './externalStaffList';

class ClinicalStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="">
        <ExternalStaffList />
      </div>
    );
  }
}
export default connect()(ClinicalStaff);
