import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import PageTitle from '../Components/PageTitle';

const AnyReactComponent = ({ text }) => (
  <div>
    <img src="https://appmedia.soberpeer.com/public/TriggerTypes/Abuser.png" />
  </div>
);
class SubscriberMapPage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    var param = new URLSearchParams(this.props.history.location.search);
    var lat = parseFloat(param.get('lat'));
    // var lat = 17.393508911;
    var lng = parseFloat(param.get('lng'));
    // var lng = 78.390806842;
    this.setState({
      lat,
      lng,
    });
  }

  render() {
    return (
      <div>
        <PageTitle TitleText="Map" />
        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo' }}
            defaultCenter={{
              lat: this.state.lat,
              lng: this.state.lng,
            }}
            defaultZoom={16}
          >
            <AnyReactComponent
              lat={this.state.lat}
              lng={this.state.lng}
              // text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default connect()(SubscriberMapPage);
