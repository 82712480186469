import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* loadMenu(action) {
  try {
    const response = yield API.loadMenu(action.payload);
    yield put({
      type: ActionTypes.LOAD_MENU_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.LOAD_MENU_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_MENU_FAILURE,
    });
  }
}

export function* logOut(action) {
  try {
    const response = yield API.logOut(action.payload);
    localStorage.clear();
    sessionStorage.clear();
    //this.props.history.push('/login');
    window.location.href = '/';
    yield put({
      type: ActionTypes.LOGOUT_SUCCESS,
      payload: response.data,
    });
    console.log(response.data);
  } catch (error) {
    yield put({
      type: ActionTypes.LOGOUT_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOGOUT_FAILURE,
    });
  }
}

export function* userFeedback(action) {
  try {
    const response = yield API.userFeedback(action.payload);
    yield put({
      type: ActionTypes.USER_FEEDBACK_SUCCESS,
      payload: response.data,
    });
    console.log(response.data);
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_FEEDBACK_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.LOAD_MENU_REQUEST, loadMenu),
    takeLatest(ActionTypes.LOGOUT_REQUEST, logOut),
    takeLatest(ActionTypes.USER_FEEDBACK_REQUEST, userFeedback),
  ]);
}
