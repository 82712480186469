import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Modal, Tooltip } from 'antd';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import {
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
  resetGetServiceCode,
  resetGetCodeDescription,
} from '../../../store/actions';
import CustomModal from '../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import AntdModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { ProcessCombos } from '../../../store/comboConfig';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../../../app-config';
import PersistTableSort from '../../../component/persistTableSort';
import ReduxServiceCodeForm from './serviceCodeForm';
import { currencyFormatter, numberFormat } from '../../../util/formatter';
import PageTitle from '../../../Components/PageTitle';
class ServiceCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceCodeList: [],
      ServiceCodeInfo: { NetMarginPercentage: 0, serviceCodeType: 1 },
      createAndEditGroupModal: false,
      codeDetails: '',
      codeModifierId1: '',
      codeModifierId2: '',
      codeModifierId3: '',
      detailDescription: '',
      searchStr: '',
      OverHeadCost: null,
      TotalCost: null,
      NetMargin: null,
      NetMarginCost: null,
      TotalServiceCost: null,
    };
  }

  // need to work on it always return zero for me

  updateParentComponent = object => {
    this.setState({
      ...this.state,
      OverHeadCost: object.OverHeadCost ? object.OverHeadCost : this.state.OverHeadCost,
      TotalCost: object.TotalCost ? object.TotalCost : this.state.TotalCost,
      NetMargin: object.NetMargin != null ? object.NetMargin : this.state.NetMargin,
      NetMarginCost: object.NetMarginCost != null ? object.NetMarginCost : this.state.NetMarginCost,
      TotalServiceCost: object.TotalServiceCost
        ? object.TotalServiceCost
        : this.state.TotalServiceCost,
    });
  };

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  refreshGrid = () => {
    const { searchStr } = this.state;
    this.setState({
      searchStr,
    });
    this.props.dispatch(
      listServiceCode({
        // json: JSON.stringify({
        //   ServiceCode: [
        //     {
        //       Search: searchStr,
        //     },
        //   ],
        // }),
        json: JSON.stringify({
          ServiceCode: [{ IsValueBasedPayment: true, Search: searchStr }],
        }),
      }),
    );
    sessionStorage.setItem(
      'appliedFilter',
      JSON.stringify({
        // Filter: filterType && filterType.toString(),
        searchStr: searchStr,
        // startDate,
        // endDate,
        // filterCenterId,
        // FilterCounserlorId,
      }),
    );
  };

  componentDidMount() {
    this.props.dispatch(
      listServiceCode({
        json: JSON.stringify({
          ServiceCode: [{ IsValueBasedPayment: true }],
        }),
      }),
    );
    this.props.dispatch(comboCode({}));

    var result = ProcessCombos(
      'UnitOfMeasurement,CenterConsumable,CenterLaborCost,LaberCostType,ServiceCodeCostType,PayFrequency',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  resetcodeIdandModifiers = () => {
    this.setState({
      codeDetails: '',
      codeModifierId1: '',
      codeModifierId2: '',
      codeModifierId3: '',
      ServiceCodeInfo: { NetMarginPercentage: 0 },
    });
  };
  componentWillReceiveProps({
    isInserted,
    isRemoved,
    serviceCodeList,
    serviceCodeinfo,
    codeDetails,
    codeId,
    codeModifierValue,
    codeModifierIndex,
    centerOverHeadCost,
  }) {
    if (codeDetails) {
      if (codeDetails) {
        var data = {
          ...this.state.ServiceCodeInfo,
          detailDescription: codeDetails,
          serviceCode: codeId,
        };

        // this.setState({
        //   codeDetails,
        //   detailDescription: codeDetails,
        //   ServiceCodeInfo: data,
        // });

        // Do not reinitiate the component just reset the starte values as required.

        this.setState({
          detailDescription: codeDetails,
        });
      } else {
        this.setState({
          codeDetails,
        });
      }
    }

    if (codeModifierIndex) {
      if (codeModifierIndex == 'codeModifier1') {
        this.setState({ codeModifierId1: codeModifierValue });
      }
      if (codeModifierIndex == 'codeModifier2') {
        this.setState({ codeModifierId2: codeModifierValue });
      }
      if (codeModifierIndex == 'codeModifier3') {
        this.setState({ codeModifierId3: codeModifierValue });
      }
    }

    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditGroupModal: false, detailDescription: null });
      this.toggle(
        this.state.ServiceCodeInfo && this.state.ServiceCodeInfo.ServiceCodeId
          ? 'Service Code updated Successfully'
          : 'Service Code added Successfully',
      );
      // this.props.dispatch(resetUpsertServiceCode());
      // this.props.dispatch(reset('serviceCodeForm'));
      // this.setState({
      //   ServiceCodeInfo: {},
      // });
    }

    if (isRemoved && isRemoved != this.props.isRemoved) {
      this.toggle('Service Code Deleted Successfully');
    }

    if (
      serviceCodeList &&
      JSON.stringify(serviceCodeList) != JSON.stringify(this.props.serviceCodeList)
    ) {
      this.setState({
        ServiceCodeList: serviceCodeList,
      });
    }

    if (
      serviceCodeinfo &&
      Object.keys(serviceCodeinfo).length>0 && 
      JSON.stringify(serviceCodeinfo) != JSON.stringify(this.props.serviceCodeinfo)
    ) {
      const {
        CenterId,
        CodeDescription,
        CodeId,
        CodeModifierDescription1,
        CodeModifierDescription2,
        CodeModifierDescription3,
        CodeModifierId1,
        CodeModifierId2,
        CodeModifierId3,
        CodeName,
        ConsumableCost,
        CreatedDate,
        IsArchived,
        IsPack,
        LaberCost,
        NetMargin,
        NetMarginCost,
        NoOfServices,
        OverHeadCost,
        OverHeadCostPercentage,
        ReactIndex,
        ServiceCodeConsumable,
        ServiceCodeDescription,
        ServiceCodeId,
        ServiceCodeLaberCost,
        ServiceCodeTypeId,
        ShortServiceDescription,
        StandardCost,
        TotalCost,
        TotalServiceCost,
        UnitOfMeasurementId,
        UpdatedBy,
        ServiceCodeCostTypeId,
        // ServiceCodeName,
      } = serviceCodeinfo;
      let data = {
        serviceCodeType: ServiceCodeCostTypeId,
        ServiceCodeId: ServiceCodeId,
        serviceCode: CodeName,
        modifier1: CodeModifierId1,
        modifier2: CodeModifierId2,
        modifier3: CodeModifierId3,
        detailDescription: ServiceCodeDescription,
        unitOfMeasurementId: UnitOfMeasurementId,
        ShortServiceDescription: ShortServiceDescription,
        IsPack: IsPack,
        overHeadCost: OverHeadCost,
        TotalCost: TotalCost,
        NetMarginPercentage: NetMargin,
        NetMargin: NetMargin,
        NetMarginCost: NetMarginCost,
        TotalServiceCost: TotalServiceCost,
        LaborCost:
          ServiceCodeLaberCost &&
          ServiceCodeLaberCost.map((item, index) => {
            const {
              CenterLaborCostId,
              Cost,
              ExtraRequiredUnit,
              LaberCostTypeId,
              PayFrequency,
              RoleDescription,
              ServiceCodeLaberCostId,
              TotalCost,
              UnitOfMeasurementId,
            } = item;
            return {
              ...item,
              CenterLaborCost: CenterLaborCostId,
              LaberCostType: LaberCostTypeId,
              LaborCost: Cost,
              LaborTime: ExtraRequiredUnit,
              unitOfMeasurementId: UnitOfMeasurementId,
            };
          }),
        Consumable:
          ServiceCodeConsumable &&
          ServiceCodeConsumable.map((item, index) => {
            const {
              ServiceCodeConsumableId,
              NoOfUnits,
              CostPerUnit,
              TotalCost,
              ItemName,
              CenterConsumableId,
            } = item;
            return {
              ...item,
              Consumable: CenterConsumableId,
              UnitNeeded: NoOfUnits,
            };
          }),
        LastOverHeadCostPercentage: OverHeadCostPercentage,
        // serviceCodeName: ServiceCodeName,
        // codeDetails: serviceCodeinfo.CodeDescription,
        // detailDescription: serviceCodeinfo.CodeDescription,
      };
      this.setState({
        ...this.state,
        // codeDetails: CodeDescription,
        codeModifierId1: CodeModifierDescription1,
        codeModifierId2: CodeModifierDescription2,
        codeModifierId3: CodeModifierDescription3,
        // detailDescription: serviceCodeinfo.ServiceCodeDescription,
        ServiceCodeInfo: data,
        createAndEditGroupModal: true,
      });

      // this.setState({
      //   ServiceCodeInfo: data,
      // });
    }

    if (
      centerOverHeadCost != null &&
      centerOverHeadCost != this.props.centerOverHeadCost
    ) {
      this.setState({
        ...this.state,
        ServiceCodeInfo: {
          ...this.state.ServiceCodeInfo,
          OverHeadCostPercentage:
            serviceCodeinfo && Object.keys(serviceCodeinfo).length > 0
              ? serviceCodeinfo.OverHeadCostPercentage :centerOverHeadCost,
        },
      });
    }

  }

  if(errorMessage) {
    this.setState({ ...this.state, isModelOpen: true, errorMessage: errorMessage });
  }

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  onSubmit(values) {
    const {
      ServiceCodeId,
      serviceCode,
      modifier1,
      modifier2,
      modifier3,
      detailDescription,
      unitOfMeasurementId,
      ShortServiceDescription,
      Consumable,
      LaborCost,
      serviceCodeType,
      OverHeadCostPercentage,
    } = values;

    const data = {
      ServiceCode: [
        {
          ServiceCodeId: this.state.ServiceCodeInfo
            ? this.state.ServiceCodeInfo.ServiceCodeId
            : null,
          CodeId: serviceCode,
          ServiceCodeTypeId: 2,
          ServiceCodeCostTypeId: serviceCodeType,
          CodeModifierId1: modifier1,
          CodeModifierId2: modifier2,
          CodeModifierId3: modifier3,
          ServiceCodeDescription: detailDescription,
          UnitOfMeasurementId: unitOfMeasurementId,
          ShortServiceDescription: ShortServiceDescription,
          OverHeadCost: this.state.OverHeadCost,
          TotalCost: this.state.TotalCost,
          NetMargin: this.state.NetMargin,
          NetMarginCost: this.state.NetMarginCost,
          TotalServiceCost: this.state.TotalServiceCost,
          ServiceCodeConsumable:
            Consumable &&
            Consumable.filter(item => item != undefined).length > 0 &&
            Consumable.filter(item => item != undefined).map(item => {
              return {
                ServiceCodeConsumableId: item.ServiceCodeConsumableId
                  ? item.ServiceCodeConsumableId
                  : null,
                CenterConsumableId: item.Consumable,
                NoOfUnits: item.UnitNeeded,
              };
            }),
          ServiceCodeLaberCost:
            LaborCost &&
            LaborCost.filter(item => item != undefined).length > 0 &&
            LaborCost.filter(item => item != undefined).map(item => {
              return {
                ServiceCodeLaberCostId: item.ServiceCodeLaberCostId
                  ? item.ServiceCodeLaberCostId
                  : null,
                CenterLaborCostId: item.CenterLaborCost,
                ContractTypeRoleId: item.ContractTypeRoleId,
                ExtraRequiredUnit: item.LaborTime,
                Cost: item.LaborCost,
                UnitOfMeasurementId: item.unitOfMeasurementId,
              };
            }),
          OverHeadCostPercentage: OverHeadCostPercentage,
        },
      ],
    };

    let Data = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertServiceCode(Data));
  }

  render() {
    const tempColumns = [
      {
        title: '    Service Code Type',
        dataIndex: 'ServiceCodeCostType',
        key: 'ServiceCodeCostType',
        sorter: (a, b) => isComparer(a.ServiceCodeCostType, b.ServiceCodeCostType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Code',
        dataIndex: 'Code',
        key: 'Code',
        sorter: (a, b) => isComparer(a.Code, b.Code),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Description',
        dataIndex: 'ServiceCodeDescription',
        key: 'ServiceCodeDescription',
        sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Date Added',
        dataIndex: 'DateAdded',
        key: 'DateAdded',
        sorter: (a, b) => isDateComparer(a.DateAddedForSort, b.DateAddedForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Unit of Measure',
        dataIndex: 'UnitOfMeasurementDescription',
        key: 'UnitOfMeasurementDescription',
        sorter: (a, b) =>
          isComparer(a.UnitOfMeasurementDescription, b.UnitOfMeasurementDescription),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Billing Rate',
        dataIndex: 'TotalCost',
        key: 'TotalCost',
        sorter: (a, b) => isNumericComparer(a.TotalCost, b.TotalCost),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span> {currencyFormatter.format(record.TotalCost || 0)}</span>
              {/* <span>{record.TotalCost ? `$${record.TotalCost}` : null}</span> */}
            </div>
          );
        },
      },
      {
        title: 'Net Margin %',
        dataIndex: 'NetMargin',
        key: 'NetMargin',
        sorter: (a, b) => isNumericComparer(a.NetMargin, b.NetMargin),
        sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => {
          return <div>{record.NetMargin ? `${record.NetMargin}%` : null}</div>;
        },
      },
      {
        title: 'Action',
        key: 'x',
        width: 100,
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <div class="tools-grid"
                // style={{ alignItems: 'center' }}
              >
                <div className="mr-4 mt-1">
                  <span className="start-btn">
                    <Tooltip placement="top" title="Edit">
                      {' '}
                      <img
                        src="images/shape.svg"
                        width="14px"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={event => {
                          this.props.dispatch(
                            getServiceCode({
                              json: JSON.stringify({
                                ServiceCode: [
                                  {
                                    ServiceCodeId: record.ServiceCodeId,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>
                <div className="">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-Screen-Tool"
                      >
                        <Tooltip placement="top" title="Delete">
                          <p>
                            <i class="far fa-trash-alt"></i>
                          </p>
                        </Tooltip>
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to delete this Service Code?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                deleteServiceCode({
                                  json: JSON.stringify({
                                    ServiceCode: [
                                      {
                                        ServiceCodeId: record.ServiceCodeId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const { ServiceCodeList } = this.state;
    const { isReadOnlyUser, isUpsertFetching } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Service Bundle" />
        <div className="container-fluid mt-5">
          <div className="">
            <div className="row px-0 mt-5 mb-2 d-flex align-items-end">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 px-0">
                <h4 className="">SERVICE CODES</h4>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5 px-0 d-flex align-items-center">
                <div class="search-box d-inline-flex w-100">
                  <div class="has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 px-0 d-flex align-items-center justify-content-end">
                {/* <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  value={'Filter'}
                /> */}
                {!isReadOnlyUser && (
                  <input
                    type="button"
                    style={{ width: '250px' }}
                    class="btn"
                    value={'Add New Service Code'}
                    onClick={event => {
                      this.setState({
                        ...this.state,
                        createAndEditGroupModal: true,
                        ServiceCodeInfo: { NetMarginPercentage: 0, serviceCodeType: 1 },
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table rcm-panel">
                  <PersistTableSort
                    name="service-code-list"
                    // bordered
                    columns={tempColumns}
                    dataSource={ServiceCodeList}
                    size={'middle'}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            // this.props.dispatch(listServiceCode({}));
            this.props.dispatch(
              listServiceCode({
                json: JSON.stringify({
                  ServiceCode: [{ IsValueBasedPayment: true }],
                }),
              }),
            );
            this.toggle();
            this.props.dispatch(resetUpsertServiceCode());
            this.props.dispatch(reset('serviceCodeForm'));
            this.resetcodeIdandModifiers();
            this.setState({
              ServiceCodeInfo: { NetMarginPercentage: 0 },
            });
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(courseDelete({ CourseId: this.getClientId() }));
            // this.props.history.push('/manage/courses');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            // this.props.history.push('/manage/courses');
          }}
        />
        <Modal
          zIndex={100}
          open={this.state.createAndEditGroupModal}
          footer={null}
          closable={false}
          width={1000}
          destroyOnClose={true}
          onCancel={() => {
            this.props.dispatch(resetGetServiceCode({}));
            this.props.dispatch(resetGetCodeDescription({}));
            this.setState({
              ServiceCodeInfo: { NetMarginPercentage: 0, detailDescription: null },
              createAndEditGroupModal: false,
            });
          }}
          afterClose={() => {
            this.setState({
              ServiceCodeInfo: { NetMarginPercentage: 0, detailDescription: null },
            });
          }}
        >
          <ReduxServiceCodeForm
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.ServiceCodeInfo}
            ServiceCodeId={this.state.ServiceCodeInfo.ServiceCodeId}
            isUpsertFetching={isUpsertFetching}
            // onCancel={this.props.onCancel}
            onCancel={() => {
              this.setState({ createAndEditGroupModal: false, detailDescription: null }, () => {
                this.props.dispatch(resetGetServiceCode({}));
                this.props.dispatch(resetGetCodeDescription({}));
                this.resetcodeIdandModifiers();
              });
            }}
            Delete={() => {
              this.Removetoggle();
              //this.props.history.push('/manage/courses')
            }}
            code={this.props.code}
            codeModifier={this.props.codeModifier}
            dispatch={this.props.dispatch}
            codeModifierValue={this.props.codeModifierValue}
            codeModifierIndex={this.props.codeModifierIndex}
            codeDetails={this.state.codeDetails}
            codeModifierId1={this.state.codeModifierId1}
            codeModifierId2={this.state.codeModifierId2}
            codeModifierId3={this.state.codeModifierId3}
            detailDescription={this.state.detailDescription}
            resetcodeIdandModifiers={this.resetcodeIdandModifiers}
            updateParentComponent={this.updateParentComponent}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToPropsParent = ({
  upsert_service_code,
  list_service_code,
  delete_service_code,
  get_service_code,
  combo_code,
  get_code_description,
  center_over_head_cost_get,
}) => {
  const { data: serviceCodeListData, isFetching } = list_service_code;
  const { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode } = get_service_code;
  const { data: comboCode } = combo_code;
  const { data: codeDesc } = get_code_description;
  const { isFetching: isUpsertFetching } = upsert_service_code;
  let codeDetails;
  let codeId;
  let codeModifierValue;
  let codeModifierIndex;
  let code;
  let codeModifier;
  let isInserted = false;
  let isRemoved = false;
  let serviceCodeList = [];
  let serviceCodeinfo = {};
  let centerOverHeadCost = null;
  if (upsert_service_code && upsert_service_code.data && upsert_service_code.data.success) {
    isInserted = true;
  }
  if (delete_service_code && delete_service_code.data && delete_service_code.data.success) {
    isRemoved = true;
  }
  if (serviceCodeListData && serviceCodeListData.ServiceCode) {
    serviceCodeList = serviceCodeListData.ServiceCode || [];
  }
  if (serviceCodeinfoData && serviceCodeinfoData.ServiceCode) {
    serviceCodeinfo = serviceCodeinfoData.ServiceCode[0];
  }

  if (comboCode && comboCode[0].Code) {
    code = comboCode[0].Code;
  }
  if (comboCode && comboCode[0].CodeModifier) {
    codeModifier = comboCode[0].CodeModifier;
  }
  if (codeDesc && codeDesc) {
    codeDetails = codeDesc[0].Code && codeDesc[0].Code[0] && codeDesc[0].Code[0].CodeDescription;
    codeId = codeDesc[0].Code && codeDesc[0].Code[0] && codeDesc[0].Code[0].CodeId;
    codeModifierValue =
      codeDesc[0].CodeModifier &&
      codeDesc[0].CodeModifier[0] &&
      codeDesc[0].CodeModifier[0].CodeModifierDescription;
    codeModifierIndex =
      codeDesc[0].CodeModifier &&
      codeDesc[0].CodeModifier[0] &&
      codeDesc[0].CodeModifier[0].CodeModifierIndex;
  }
    if (center_over_head_cost_get && center_over_head_cost_get.data) {
      centerOverHeadCost = center_over_head_cost_get.data.CenterOverHead[0].OverHeadValue;
    }
  return {
    isInserted,
    isRemoved,
    serviceCodeList: serviceCodeList,
    isFetching: isFetching,
    serviceCodeinfo: serviceCodeinfo,
    code,
    codeModifier,
    codeDetails,
    codeId,
    codeModifierValue,
    codeModifierIndex,
    isFetchingGetServiceCode,
    isUpsertFetching,
    centerOverHeadCost,
  };
};
export default connect(mapStateToPropsParent)(ServiceCode);
const selector = formValueSelector('serviceCodeForm');
