import React from 'react';

export const CenterOption = ({ OptionData, isValue, valueText }) => {
  const { Text, Value, StateOrProvince } = OptionData;
  return (
    <div className="center-option">
      <span className="mr-3">{Text}</span>
      <span>{StateOrProvince}</span>
    </div>
  );
};
