import React, { Component } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Modal } from 'antd';
// import MergeClientsComponent from './mergePatientsDetailsComponent';
import MergeClientsComponent from '../mergeClients/mergeClientsComponent';
import { ReduxSelect } from '../../component/custom-redux-components';
import SubmitButton from '../../Components/submitButton';
import AntdModal from '../../Components/CustomModal';
import CloseIcon from '../../Components/CloseIcon';
import CustomModal from '../../core/modal';
import { Loader2 } from '../../Components/Loader';
import {
  clientComboGetAll,
  clientMergeGet,
  clientMergeSecondaryGet,
  clientMerge,
  resetClientMergeGet,
  resetClientMergeSecondaryGet,
  resetClientMerge,
} from '../../store/actions';
import PageTitle from '../../Components/PageTitle';
class MergePatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalMergeClients: [{}],
      isMergeSuccess: false,
      mergeMsg: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(clientComboGetAll({}));
  }

  onSelect = (value, mIndex) => {
    const { finalMergeClients } = this.state;
    const { fieldKeys } = this.props;
    let mergeArray = [...finalMergeClients];

    for (let i in mergeArray[0]) {
      if (i == fieldKeys[mIndex]) {
        mergeArray[0][i] = value;
      }
    }
    this.setState({ finalMergeClients: mergeArray });
  };

  onMerge = () => {
    this.props.dispatch(
      clientMerge({
        Json: JSON.stringify({
          FromClientId: this.props.secondaryStudentId,
          ToClientId: this.props.primaryStudentId,
          Client: this.state.finalMergeClients ? this.state.finalMergeClients : [],
        }),
      }),
    );
  };

  onMergeComplete = () => {
    this.props.reset();
    // this.props.dispatch(resetStudentMergeComboListGet({}));
    this.props.dispatch(resetClientMergeGet({}));
    this.props.dispatch(resetClientMergeSecondaryGet({}));
    this.props.dispatch(resetClientMerge({}));
    // this.props.history.push('/manage');
  };

  componentWillReceiveProps({ primaryStudentId, secondaryStudentId, fieldKeys, isSuccess }) {
    if (primaryStudentId && primaryStudentId != this.props.primaryStudentId) {
      this.props.dispatch(
        clientMergeGet({
          Json: JSON.stringify({
            Client: [
              {
                ClientId: primaryStudentId,
              },
            ],
          }),
        }),
      );
    }

    if (primaryStudentId && secondaryStudentId && primaryStudentId == secondaryStudentId) {
      this.props.dispatch(resetClientMergeSecondaryGet({}));
      this.props.change('secondaryStudent', null);
    }
    if (secondaryStudentId && secondaryStudentId != this.props.secondaryStudentId) {
      this.props.dispatch(
        clientMergeSecondaryGet({
          Json: JSON.stringify({
            Client: [
              {
                ClientId: secondaryStudentId,
              },
            ],
          }),
        }),
      );
    }

    if (fieldKeys && fieldKeys != this.props.fieldKeys) {
      fieldKeys &&
        fieldKeys.map(item => {
          this.state.finalMergeClients[0][item] = null;
        });
    }
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState({
        isMergeSuccess: isSuccess,
      });
    }
  }

  componentWillUnmount() {
    this.onMergeComplete();
  }

  render() {
    const {
      studentList,
      fieldNames,
      primaryStudentDetailsText,
      primaryStudentId,
      secondaryStudentList,
      secondaryStudentDetailsText,
      isMergeFetching,
      secondaryStudentId,
      isFetching,
    } = this.props;
    const { isMergeSuccess } = this.state;

    return (
      <div class="container-fluid">
        <PageTitle TitleText="Merge Patients" />

        <div class="content-area">
          <div class="row top-table-search mt-3 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/cw/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Merge Patients</h3>
            </div>
          </div>
          <div class="row merge-clients ">
            {/* <Loader2 loading={isFetching} /> */}
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12 merge-client-container">
                    {/* <Loader2 loading={isFetching} /> */}
                    <div className="row merge-clients-header justify-content-center">
                      <div className="col-12 col-md-3 merge-clients-field-name-header"></div>
                      <div className="col-12 d-flex col-md-5" style={{ padding: '0' }}>
                        <div class="col-12 col-md-6 merge-clients-dropdown">
                          <div class="form-group">
                            <Field
                              name={`primaryStudent`}
                              component={ReduxSelect}
                              errorMessage="Field Required"
                              options={studentList}
                              placeholder="Select primary patient"
                              // validate={validate.required}
                              fieldName={'Select a primary patient'}
                              // disabled={disable}
                            />
                          </div>
                          {primaryStudentId && (
                            <a
                              // onClick={() =>
                              href={`#/cw/patient-management/patient-profile?tab=demographic&pId=${primaryStudentId}`}
                              // }
                              className="mb-0 merge-clients-view-profile"
                              target="_blank"
                            >
                              View Profile
                            </a>
                          )}
                        </div>
                        <div class="col-12 col-md-6 merge-clients-dropdown">
                          <div class="form-group">
                            <Field
                              name={`secondaryStudent`}
                              component={ReduxSelect}
                              errorMessage="Field Required"
                              options={secondaryStudentList}
                              placeholder="Select patient to merge into primary"
                              // validate={validate.required}
                              fieldName={'Select a patient to merge into primary'}
                              disabled={primaryStudentId ? false : true}
                            />
                          </div>
                          {/* {secondaryStudentId && (
                            <a href={`/studentProfile/${secondaryStudentId}`}>View Profile</a>
                          )} */}

                          {secondaryStudentId && (
                            <a
                              // onClick={() =>
                              href={`#/cw/patient-management/patient-profile?tab=demographic&pId=${secondaryStudentId}`}
                              // }
                              className="mb-0 merge-clients-view-profile"
                              target="_blank"
                            >
                              View Profile
                            </a>
                          )}
                        </div>
                      </div>
                      <p className="col-12 col-md-3 mb-0 merge-clients-preview-header d-flex justify-content-start align-items-center">
                        Preview
                      </p>
                    </div>
                    {fieldNames.map((item, index) => (
                      <MergeClientsComponent
                        key={index}
                        mIndex={index}
                        primaryStudentDetailsText={primaryStudentDetailsText}
                        secondaryStudentDetailsText={secondaryStudentDetailsText}
                        item={item}
                        onSelect={this.onSelect}
                      />
                    ))}
                  </div>
                </div>

                <div className="col-12 mb-5 mt-3 pr-5" style={{ textAlign: 'end' }}>
                  <button
                    className="btn btn-outline-primary mr-3"
                    onClick={() => this.props.history.push('/cw/manage')}
                  >
                    Cancel
                  </button>
                  <AntdModal
                    modalWidth={480}
                    ModalButton={({ onClick }) => (
                      <SubmitButton
                        loading={isMergeFetching}
                        onClick={onClick}
                        style={{ padding: '10px 40px', height: '45px', marginRight: '13px' }}
                        value={'Merge'}
                        disabled={primaryStudentId && secondaryStudentId ? false : true}
                      />
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to merge these patients?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.onMerge();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.isMergeSuccess}
          className="merge-clients-modal"
          onCancel={() => {
            this.setState({ isMergeSuccess: false });
            this.onMergeComplete();
          }}
          footer={[
            <input
              type="button"
              style={{ minWidth: '100px' }}
              className="btn mb-4"
              value="OK"
              onClick={() => {
                // this.props.history.push(`/enrollment/?studentid=${primaryStudentId}`);
                this.props.history.push(
                  `/cw/patient-management/patient-profile?tab=demographic&pId=${primaryStudentId}`,
                );
                this.onMergeComplete();
                //   this.props.history.push('/manage');
                this.setState({ isMergeSuccess: false });
                this.props.dispatch(clientComboGetAll({}));
              }}
            />,
          ]}
          closeIcon={<CloseIcon />}
          maskClosable={false}
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ padding: '76px', paddingBottom: '0', paddingTop: '60px' }}
          >
            <img src="images/successful_Icon.svg" className="mb-3" />
            <h4>{this.props.upsertMsg}</h4>
            <p className="successful-helper">The patients have been merged successfully.</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect((state, props) => {
  const {
    client_combo_get_all,
    client_merge_get,
    client_merge_secondary_get,
    client_merge,
  } = state;
  const { data: mergeComboData, isFetching: isListFetching } = client_combo_get_all;
  const { data: primaryStudentDetails, isFetching: isPrimaryFetching } = client_merge_get;
  const {
    data: secondaryStudentDetails,
    isFetching: isSecondaryFetching,
  } = client_merge_secondary_get;
  const { data: upsertData, isFetching: isMergeFetching } = client_merge;

  const primaryStudentId = selector(state, 'primaryStudent');
  const secondaryStudentId = selector(state, 'secondaryStudent');

  let fieldNames = [];
  let studentList = [],
    primaryStudentDetailsText = [],
    secondaryStudentList = [],
    secondaryStudentDetailsText = [],
    fieldKeys = [];
  let isSuccess = false;
  let upsertMsg = '';

  if (mergeComboData && mergeComboData.Student) {
    console.log(mergeComboData, 'mergeComboData');
    fieldNames =
      mergeComboData.Student[0] && mergeComboData.Student[0].FieldNames
        ? mergeComboData.Student[0].FieldNames
        : [];

    studentList =
      mergeComboData.Student[0] && mergeComboData.Student[0].StudentList
        ? mergeComboData.Student[0].StudentList
        : [];
  }

  if (primaryStudentId && mergeComboData && mergeComboData.Student) {
    secondaryStudentList =
      mergeComboData.Student[0] &&
      mergeComboData.Student[0].StudentList.filter((item, index) => {
        if (item.Value != primaryStudentId) {
          return item;
        }
      });
  }

  if (primaryStudentDetails && primaryStudentDetails.Client) {
    for (let i in primaryStudentDetails.Client[0]) {
      fieldKeys.push(i);
      primaryStudentDetailsText.push({
        Text: primaryStudentDetails.Client[0][i].Text,
        Value: primaryStudentDetails.Client[0][i].Value,
      });
    }
  }

  if (secondaryStudentDetails && secondaryStudentDetails.Client) {
    for (let i in secondaryStudentDetails.Client[0]) {
      secondaryStudentDetailsText.push({
        Text: secondaryStudentDetails.Client[0][i].Text,
        Value: secondaryStudentDetails.Client[0][i].Value,
      });
    }
  }

  if (upsertData && upsertData.success) {
    isSuccess = upsertData.success;
    upsertMsg = upsertData.result || upsertData.data;
  }
  return {
    fieldNames,
    studentList,
    primaryStudentId,
    primaryStudentDetailsText,
    secondaryStudentList,
    secondaryStudentId,
    secondaryStudentDetailsText,
    fieldKeys,
    isMergeFetching,
    isSuccess,
    upsertMsg,
    isFetching: isListFetching || isPrimaryFetching || isSecondaryFetching,
  };
})(
  reduxForm({
    form: 'mergePatients',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(MergePatients),
);

const selector = formValueSelector('mergePatients');
