import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isComparer, isDateComparer } from '../util/handler';
import { currencyFormatter, numberFormat } from '../util/formatter';
import {
  getSmallGroupList,
  getSmallGroup,
  sendVoucherCode,
  appSubscribersUserSearch,
} from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomModal from '../Components/CustomModal';
import { Checkbox, Radio, Tooltip, Modal, Table, Avatar } from 'antd';
import renderHTML from 'react-render-html';
import { FormatDate } from '../Components/FormatDate';
import AntdModal from '../Components/CustomModal';
import CustomCoreModal from '../core/modal';
import _blank from '../images/blank-profile.png';
import _smallGroup from '../images/smallgroup/sm-01.png';
import PageTitle from '../Components/PageTitle';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { ReduxSendInvitationModal } from './SendInvitation';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class SmallGroup extends Component {
  state = {
    searchStr: null,
    inviteFormModal: false,
    inviteSubscriberModal: false,
    groupFilter: 13202,
    isGridView: true,
    FiilterBy: [],
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount({ searchStr } = this.state) {
    const { dispatch } = this.props;
    let isGridVal = sessionStorage.getItem('isGridView') || true;
    let isGridView = typeof isGridVal === 'string' ? isGridVal === 'true' : isGridVal;
    this.setState({
      isGridView: isGridView,
    });
    var groupFilterValue = Number(sessionStorage.getItem('groupFilter') || null);
    // var searchStrValue = sessionStorage.getItem('searchStr') || null;
    var searchStrValue;
    if (groupFilterValue && searchStrValue) {
      let filterData = {
        Json: JSON.stringify({
          SmallGroup: [
            {
              Filterby: groupFilterValue,
              Search: searchStrValue,
            },
          ],
        }),
      };
      dispatch(getSmallGroupList(filterData));

      this.setState({
        groupFilter: groupFilterValue,
        searchStr: searchStrValue,
      });
    } else if (groupFilterValue) {
      let filterData = {
        Json: JSON.stringify({
          SmallGroup: [
            {
              Filterby: groupFilterValue,
            },
          ],
        }),
      };
      dispatch(getSmallGroupList(filterData));
      this.setState({ groupFilter: groupFilterValue });
    } else if (searchStrValue) {
      let data = {
        SmallGroup: [{ Search: searchStrValue }],
      };
      let jsonData = {
        Json: JSON.stringify(data),
      };

      this.setState({ searchStr: JSON.stringify(searchStrValue) });
      dispatch(getSmallGroupList(jsonData));
    } else {
      dispatch(getSmallGroupList({}));
    }
  }
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    const { dispatch } = this.props;
    if (value.length > 0) {
      let data = {
        SmallGroup: [
          {
            Search: value,
            Filterby: this.state.groupFilter,
          },
        ],
      };
      let jsonData = {
        Json: JSON.stringify(data),
      };
      dispatch(getSmallGroupList(jsonData));
      sessionStorage.setItem('searchStr', value);
    } else {
      let data = {
        SmallGroup: [
          {
            Search: null,
            Filterby: this.state.groupFilter,
          },
        ],
      };
      let jsonData = {
        Json: JSON.stringify(data),
      };

      dispatch(getSmallGroupList(jsonData));
    }
  };
  componentWillReceiveProps({ FiilterBy }) {
    if (FiilterBy && FiilterBy != this.props.FiilterBy) {
      this.setState({
        FiilterBy,
      });
    }
  }
  onGroupClick = (record, index) => {
    const { history } = this.props;
    history.push('/manage/small-group-form?smallGroupGuid=' + record.SmallGroupGuid);
  };

  onFilterChange = value => {
    this.setState({
      groupFilter: value,
    });
    sessionStorage.setItem('groupFilter', JSON.stringify(value));
    const { dispatch, smallGroupGuid } = this.props;
    let filterData = {
      Json: JSON.stringify({
        SmallGroup: [
          {
            Filterby: value,
            Search: this.state.searchStr || null,
          },
        ],
      }),
    };
    dispatch(getSmallGroupList(filterData));
  };

  // toggleView = () => {
  //   this.setState(
  //     prevState => ({
  //       isGridView: !prevState.isGridView,
  //     }),
  //     () => {
  //       sessionStorage.setItem('isGridView', JSON.stringify(this.state.isGridView));
  //     },
  //   );
  //   // sessionStorage.setItem('isGridView', JSON.stringify(!prevState.isGridView));
  // };
  toggleView = () => {
    this.setState(
      prevState => ({
        isToggleOn: !prevState.isToggleOn,
        isGridView: !prevState.isGridView,
      }),
      () => {
        sessionStorage.setItem('isGridView', JSON.stringify(this.state.isGridView));
      },
    );
  };
  render() {
    const { isFetching, SmallGroups, NameCourse } = this.props;
    const { isGridView, FiilterBy } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'GroupName',
        key: 'GroupName',
        sorter: (a, b) => isComparer(a.GroupName, b.GroupName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Type',
        dataIndex: 'IsPrivate',
        key: 'IsPrivate',
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{text ? 'Private' : 'Public'}</span>
            </div>
          );
        },
        // sorter: (a, b) => isComparer(a.IsPrivate, b.IsPrivate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'IsDeActivated',
        key: 'IsDeActivated',
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{text ? 'Inactive' : 'Active'}</span>
            </div>
          );
        },
        // sorter: (a, b) => isComparer(a.IsDeActivated, b.IsDeActivated),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Price',
        dataIndex: 'Cost',
        key: 'Cost',
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{currencyFormatter.format(text)}</span>
            </div>
          );
        },
        // sorter: (a, b) => isComparer(a.IsDeActivated, b.IsDeActivated),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Active Clients',
        dataIndex: 'ActiveClientCount',
        key: 'ActiveClientCount',
        // sorter: (a, b) => isComparer(a.SmallGroupDate, b.SmallGroupDate),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span className="">{numberFormat.format(text)}</span>
            </div>
          );
        },
      },
      {
        title: 'Monthly Revenue',
        dataIndex: 'MonthlyRevenue',
        key: 'MonthlyRevenue',
        // sorter: (a, b) => isComparer(a.SmallGroupDate, b.SmallGroupDate),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span className="">{currencyFormatter.format(text)}</span>
            </div>
          );
        },
      },

      {
        title: 'Start Date',
        dataIndex: 'StartDate',
        key: 'StartDate',
        // sorter: (a, b) => isComparer(a.SmallGroupDate, b.SmallGroupDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date',
        dataIndex: 'EndDate',
        key: 'EndDate',
        // sorter: (a, b) => isComparer(a.SmallGroupDate, b.SmallGroupDate),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Group Tag',
      //   dataIndex: 'GroupTag',
      //   key: 'GroupTag',
      //   // sorter: (a, b) => isComparer(a.GroupTag, b.GroupTag),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Group Leader',
      //   dataIndex: 'GroupLeader',
      //   key: 'GroupLeader',
      //   // sorter: (a, b) => isComparer(a.GroupLeader, b.GroupLeader),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Meeting Info',
      //   dataIndex: 'MeetingInfo',
      //   key: 'MeetingInfo',
      //   // sorter: (a, b) => isComparer(a.MeetingInfo, b.MeetingInfo),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Group Capacity',
      //   dataIndex: 'GroupCapacity',
      //   key: 'GroupCapacity',
      //   // sorter: (a, b) => isComparer(a.GroupCapacity, b.GroupCapacity),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Group Enrolled',
      //   dataIndex: 'GroupEnrolled',
      //   key: 'GroupEnrolled',
      //   // sorter: (a, b) => isComparer(a.GroupEnrolled, b.GroupEnrolled),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Last Meeting',
      //   dataIndex: 'LastMeeting',
      //   key: 'LastMeeting',
      //   // sorter: (a, b) => isComparer(a.LastMeeting, b.LastMeeting),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Next Meeting',
      //   dataIndex: 'NextMeeting',
      //   key: 'NextMeeting',
      //   // sorter: (a, b) => isComparer(a.NextMeeting, b.NextMeeting),
      //   sortDirections: ['descend', 'ascend'],
      // },
      // {
      //   title: 'Last Updated',
      //   dataIndex: 'LastUpdated',
      //   key: 'LastUpdated',
      //   // sorter: (a, b) => isComparer(a.LastUpdated, b.LastUpdated),
      //   sortDirections: ['descend', 'ascend'],
      // },
    ];
    return (
      <>
        <PageTitle TitleText="Small Group" />
        <div class="col-md-12">
          {/* <Loader loading={isFetching} /> */}
          <div class="content-area" style={{ maxWidth: '1366px', margin: '0 auto' }}>
            <div class="row top-table-search mt-5 mb-4 align-items-center px-3">
              <div class="col-xs-12 col-lg-4">
                <h3 class="page-title mb-3 mb-md-0">Small Group Details</h3>
              </div>

              <div class="col-xs-12 col-lg-8">
                <div className="row d-flex justify-content-end no-gutters">
                  <div className="col-sm-12 col-lg-4">
                    <div className="">
                      <div className="mb-2">
                        <div class="form-group mb-0 select-sm-filter">
                          <ReduxSelect
                            options={FiilterBy}
                            onChange={this.onFilterChange}
                            className="basic-single"
                            classNamePrefix="select"
                            value={this.state.groupFilter}
                            // placeholder="Filter By"
                            style={{ width: '230px', color: 'red' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-8">
                    <div
                      class="search-box d-inline-flex w-100"
                      style={{ maxWidth: '450px', marginBottom: '10px' }}
                    >
                      <div class="form-group has-search w-100">
                        <input
                          type="text"
                          class="form-control 
                   shadow-sm"
                          placeholder="Search"
                          id="filterbox"
                          onChange={this.onSearchChange}
                          value={this.state.searchStr}
                        />
                        <button class="btn search-icon " type="button">
                          {' '}
                          <i class="fa fa-search" />{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-5">
                <div class="row" style={{ minHeight: '300px' }}>
                  <Loader2 loading={isFetching} />
                  <>
                    {SmallGroups && SmallGroups.length > 0 && (
                      <div className="col-12">
                        <div className="card border-0 rounded-0 p-3 row-hover-enabled">
                          <Table
                            selections={false}
                            columns={columns}
                            dataSource={SmallGroups}
                            rowKey={record => record.StaffId}
                            onRow={(record, rowIndex) => {
                              return {
                                onClick: event => this.onGroupClick(record, rowIndex),
                              };
                            }}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div class="dataTables_wrapper no-footer">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a class="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a class="paginate_button next">Next</a>
                                      ) : (
                                        <a class="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              showSizeChanger: true,
                              // hideOnSinglePage: true,
                              defaultPageSize: 5,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>

                  {SmallGroups && SmallGroups.length == 0 ? (
                    <div className="text-center w-100" style={{ marginTop: '150px' }}>
                      <h4 style={{ color: 'rgb(197 190 190)' }}>No Small Groups Found</h4>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Modal
            zIndex={100}
            visible={this.state.inviteSubscriberModal}
            footer={null}
            closable={false}
          >
            <ReduxSendInvitationModal
              // {...this.props}
              // GetStudentGui={this.getStudentGui}
              SmallGroupGuid={this.state.smallGroupGuid}
              initialValues={{
                referralType: 'R',
                CountryCode: '1',
              }}
              ClientType="subscriber"
              onCancel={() => {
                this.setState({
                  inviteSubscriberModal: false,
                });
              }}
            />
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ get_small_group_list }) => {
  var { data, isFetching, error } = get_small_group_list;
  let SmallGroups = [];
  let FiilterBy;

  if (data && data.SmallGroup) {
    SmallGroups = data.SmallGroup;
  }
  if (data && data.FiilterBy) {
    FiilterBy = data.FiilterBy;
  }

  return {
    SmallGroups: SmallGroups,
    FiilterBy,
    isFetching,
  };
};

export default connect(mapStateToProps)(SmallGroup);
