import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { Tabs, Modal, Popover, Drawer, Tooltip } from 'antd';
import ReactApexChart from 'react-apexcharts';
import DynamicGrid from '../../../Components/DynamicGrid';
import { ProcessCombos } from '../../../store/comboConfig';
import { CommonLicenceScreens } from '../../licenceScreens';
import ComingSoon from '../../../component/comingSoonPage';
import { numberFormat } from '../../../util/formatter';
import Inquiries from '../components/Inquiries';
import AntdModal from '../../../Components/CustomModal';
import AddProspectNote from './AddNoteModal';
import CustomModal from '../../../core/modal';
import { Twilio_Enterprise } from '../../../app-config/screenName';
import CloseIcon from '../../../Components/CloseIcon';
import CallEndedForm from './dashboard/AppointmentsScreens/CallEnded';
import {
  getClientCounselor,
  listCombos,
  getDynamicComos,
  getProspectDashboardGraph,
  prospectArchive,
  resetProspectArchive,
  studentBanner,
} from '../../../store/actions';
import CreateAppointment from './dashboard/AppointmentsScreens/createAppointment';
import PageTitle from '../../../Components/PageTitle';

class PreIntakeList extends Component {
  constructor(props) {
    super(props);
    const me = this;
    this.state = {
      isNoteAddModal: false,
      isModalOpen: false,
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function(val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        colors: ['#29A0E2', '#FFAE49', '#3FDF62', '#FF4869'],
        fill: { colors: ['#29A0E2', '#FFAE49', '#3FDF62', '#FF4869'] },
        chart: {
          id: 'basic-bar',
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: function(val, data) {
              const { dataPointIndex, series, seriesIndex } = data || [];
              // return (
              //   <div className="prospect-tooltip">
              //     {series &&
              //       series.map((item, index) => (
              //         <div className="d-flex">
              //           <span>{me.state.series[index].name}</span>
              //           <span>{item[dataPointIndex]}</span>
              //         </div>
              //       ))}
              //   </div>
              // );
              return val;
            },
          },
        },
        xaxis: {
          categories: ['Total inquiries'],
        },
        yaxis: [
          {
            title: {
              text: 'Number of Inquiries',
            },
          },
          // {
          //   opposite: true,
          //   title: {
          //     text: 'Newly Added',
          //   },
          // },
        ],
      },
      series: [
        {
          name: 'Total inquiries',
          data: [],
        },
        {
          name: 'Total opportunities',
          data: [],
        },
        {
          name: 'Total inquiries referred to admission',
          data: [],
        },

        {
          name: 'Archived inquiries',
          data: [],
        },
      ],
    };
  }
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );
    var result = ProcessCombos('ClientType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let comboTypes = {
      ComboTypes: 'CenterServiceTypeCenter',
      Json: JSON.stringify({
        Combo: [
          {
            StudentId: null,
            StudentCenterId: null,
          },
        ],
      }),
    };
    this.props.dispatch(getDynamicComos(comboTypes));
    this.props.dispatch(
      getClientCounselor({
        CenterId: this.state.filterCenterId,
      }),
    );
    this.props.dispatch(getProspectDashboardGraph({}));
  }

  componentWillReceiveProps({
    preIntakeStatusData,
    ProspectMonthwise,
    isArchivedSuccess,
    successMsg,
  }) {
    if (
      preIntakeStatusData &&
      JSON.stringify(preIntakeStatusData) != JSON.stringify(this.props.preIntakeStatusData)
    ) {
      this.setState({ preIntakeStatusData });
    }
    if (isArchivedSuccess && isArchivedSuccess != this.props.isArchivedSuccess) {
      this.toggle(successMsg);
      this.props.dispatch(resetProspectArchive({}));
    }
    if (
      ProspectMonthwise &&
      JSON.stringify(ProspectMonthwise) != JSON.stringify(this.props.ProspectMonthwise)
    ) {
      let month = [];
      let InquiriesSeries = {
        ...this.state.series[0],
        data: [],
      };
      let OpportunitiesSeries = {
        ...this.state.series[1],
        data: [],
      };
      let InquiriesReferredToAdmissionSeries = {
        ...this.state.series[2],
        data: [],
      };
      let ArchivedInquiriesSeries = {
        ...this.state.series[3],
        data: [],
      };
      ProspectMonthwise.map((monthItem, index) => {
        month.push(`${monthItem.MonthName.substr(0, 3)} ${monthItem.Year}`);
        InquiriesSeries.data.push(monthItem.TotalInquiries);
        InquiriesReferredToAdmissionSeries.data.push(monthItem.TotalInquiriesReferedAsAdmission);
        OpportunitiesSeries.data.push(monthItem.TotalOpportunities);
        ArchivedInquiriesSeries.data.push(monthItem.ArchivedInquiries);
      });

      let newBarSeries = [
        InquiriesSeries,
        OpportunitiesSeries,
        InquiriesReferredToAdmissionSeries,
        ArchivedInquiriesSeries,
      ];
      this.setState(prevState => {
        return {
          ...prevState,
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: [...month],
            },
          },
          series: newBarSeries,
        };
      });
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  getPopOverData = record => {
    return (
      <div className="sort-by-list">
        <p
          onClick={() => {
            this.props.history.push(
              `/pre-intake-form?step=demographics&studentid=${record.StudentId}`,
            );
          }}
        >
          Edit Details
        </p>
        <p
          onClick={() => {
            this.props.history.push(
              `/clients/pre-intake/dashboard?tab=summary&id=${record.StudentId}`,
            );
          }}
        >
          View Details
        </p>
        <p
          onClick={() => {
            this.setState({
              isNoteAddModal: true,
              StudentId: record.StudentId,
            });
          }}
        >
          Add Note
        </p>
        <CreateAppointment ID={record.StudentId} ClientId={record.ClientId} isListMenu={true} />
        <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#move-intake-model">
              Move to Intake
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">
                  Are you sure you want to move to Intake?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    let data = {
                      Json: JSON.stringify({
                        Student: [
                          {
                            StudentId: record.StudentId,
                            EnrollStatusId: 0,
                          },
                        ],
                      }),
                    };
                    Axios.post('Student/StudentEnrollStatusUpdate', data)
                      .then(response => {
                        if (response.data.success) {
                          this.props.history.replace(`/enrollment/?studentid=${record.StudentId}`);
                        }
                      })
                      .catch(function(error) {
                        console.log(error);
                      });
                    onOk();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
        <AntdModal
          ModalButton={({ onClick }) => (
            <p class="" onClick={onClick}>
              {record.IsPreIntakeArchived ? 'Restore' : 'Archive'}
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                  &times;
                </button>
                <h4 className="modal-title col-12">
                  Are you sure you want to {record.IsPreIntakeArchived ? 'restore' : 'archive'} this
                  prospect?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  type="button"
                  className="btn btn-outline-primary py-1"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn py-1"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      prospectArchive({
                        Json: JSON.stringify({
                          Student: [
                            {
                              StudentId: record.StudentId,
                              Archive: !record.IsPreIntakeArchived,
                              EnrollStatusId: record.EnrollStatusId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
      </div>
    );
  };
  getCallEndDetails = (isRecored, callDetails, twilioData) => {
    let SID = callDetails.parameters.CallSid;
    this.setState({
      isCallAppointmentDrawer: isRecored ? true : false,
      CallSid: SID,
      isTwilioAppointment: true,
      twilioData,
    });
    this.props.dispatch(
      studentBanner({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: twilioData.ID,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { ProspectMonthwise, TotalProspectCount } = this.props;
    const { isNoteAddModal } = this.state;
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Prospect" />
        <div class="content-area">
          {' '}
          <CommonLicenceScreens
            StatusData={this.state.preIntakeStatusData}
            moduleName={'PreIntake'}
          />
        </div>

        {this.state.preIntakeStatusData && this.state.preIntakeStatusData.LicenseStatusId == 6 ? (
          <div>
            <div class="content-area">
              {' '}
              <ComingSoon />
            </div>

            <div className="pb-5"></div>
          </div>
        ) : (
          <>
            {this.state.preIntakeStatusData &&
              this.state.preIntakeStatusData.LicenseStatusId != 1 &&
              this.state.preIntakeStatusData.LicenseStatusId != 3 &&
              this.state.preIntakeStatusData.LicenseStatusId != 5 && (
                <>
                  <div class="content-area pb-5">
                    <div class="row">
                      <div class="col-12 col-md-4 col-lg-3 py-3">
                        <h3 class="page-title mb-3 mb-md-0">Prospect</h3>
                      </div>
                      <div class="col-12">
                        <div class="card border-0">
                          <div class="card-body">
                            <h6 class="mb-3">Inquiries</h6>
                            <Inquiries
                              Inquiries={TotalProspectCount}
                              // Inquiries={{
                              //   status1: { Text: 'Total Inquiries', Value: TotalInquiries },
                              //   status2: {
                              //     Text: 'Total Inquiries referred to admission',
                              //     Value: TotalInquiriesReferedAsAdmission,
                              //   },
                              //   status3: { Text: 'Total Opportunities', Value: TotalOpportunities },
                              //   status4: { Text: 'Archived Inquiries', Value: ArchivedInquiries },
                              // }}
                            />

                            <div className="prospect-inquiries">
                              <ReactApexChart
                                options={this.state.options}
                                series={this.state.series}
                                type="bar"
                                height={280}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row top-table-search my-4 align-items-center">
                      <div class="col-12 col-md-4 col-lg-3">
                        <h4 class="mb-3 mb-md-0">Prospect Roster</h4>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div
                          class="card border-0 "
                          style={{ backgroundColor: 'unset', boxShadow: 'none' }}
                        >
                          <div className="dynamic-grid">
                            <DynamicGrid
                              ScreenName={'PreIntake'}
                              FilterBy={{ IsPreIntakeList: true }}
                              getPopOverData={this.getPopOverData}
                              Twilio_ScreenName={Twilio_Enterprise}
                              getCallEndDetails={this.getCallEndDetails}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}{' '}
          </>
        )}

        {isNoteAddModal && (
          <Modal open={isNoteAddModal} footer={null} closable={false} destroyOnClose={true}>
            <AddProspectNote
              StudentId={this.state.StudentId}
              onCancel={() => {
                this.setState({
                  isNoteAddModal: false,
                });
              }}
              IsProspectNote={true}
            />
          </Modal>
        )}
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // this.props.onCancel();
          }}
          yesText="OK"
          hideCancel={true}
        />
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title">Add Appointment</span>
            </div>
          }
          placement="right"
          open={this.state.isCallAppointmentDrawer}
          width={700}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={() => {
            this.setState({
              isCallAppointmentDrawer: false,
              CallSid: null,
              twilioData: null,
              isTwilioAppointment: false,
            });
          }}
        >
          <CallEndedForm
            CallSid={this.state.CallSid}
            isTwilioAppointment={this.state.isTwilioAppointment}
            twilioData={this.state.twilioData}
            onClose={() => {
              this.setState({
                isCallAppointmentDrawer: false,
                CallSid: null,
                twilioData: null,
                isTwilioAppointment: false,
              });
            }}
          />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({
  user_feature_flag_get,
  get_prospect_dashboard_graph,
  prospect_archive,
}) => {
  const { data: preIntakeStatus } = user_feature_flag_get;
  const { data: prospectDashboardGraph } = get_prospect_dashboard_graph;
  const { data: archiveData } = prospect_archive;

  let preIntakeStatusData = null;
  let ProspectMonthwise = null;
  let TotalProspectCount = null;
  let isArchivedSuccess = null;
  let successMsg = '';

  if (
    preIntakeStatus &&
    preIntakeStatus.FeatureFlag &&
    preIntakeStatus.FeatureFlag.length > 0 &&
    preIntakeStatus.FeatureFlag[0]
  ) {
    preIntakeStatusData =
      preIntakeStatus.FeatureFlag &&
      preIntakeStatus.FeatureFlag[0] &&
      preIntakeStatus.FeatureFlag[0].License &&
      preIntakeStatus.FeatureFlag[0].License.length > 0 &&
      preIntakeStatus.FeatureFlag[0].License.filter(item => item.ModuleName == 'PreIntake')[0];
  }
  if (
    prospectDashboardGraph &&
    prospectDashboardGraph.Prospect &&
    prospectDashboardGraph.Prospect[0]
  ) {
    ProspectMonthwise = prospectDashboardGraph.Prospect[0].ProspectMonthwise;
    TotalProspectCount = prospectDashboardGraph.Prospect[0].TotalProspectCount;
  }
  if (archiveData && archiveData.success) {
    isArchivedSuccess = true;
    successMsg = archiveData.data && JSON.parse(archiveData.data)[0].Msg;
  }
  return {
    preIntakeStatusData,
    ProspectMonthwise,
    TotalProspectCount,
    isArchivedSuccess,
    successMsg,
  };
};

export default connect(mapStateToProps)(withRouter(PreIntakeList));
