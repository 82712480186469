import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import Axios from 'axios';
import {
  listSurveyQuestionAssessment,
  surveyAnswerUpsert,
  augmentedClientInsightsGet,
  surveyLatestAnswerDateUpdate,
} from '../../store/actions';
import QuestionType from '../RCM/patientEnrollmentForm/formSteps/questionType/questionComponent';
import {
  NoRisk,
  LowRisk,
  MediumRisk,
  HighRisk,
} from '../RCM/patientEnrollmentForm/formSteps/questionType/risk';
import { some } from 'lodash';
import Loader, { Loader2 } from '../../Components/Loader';
import PageTitle from '../../Components/PageTitle';

class ClientScreening extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskResponse: null,
      isMandatory: false,
    };
  }
  getIntakeId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentId = param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = null;
    }
    return studentId;
  }
  getSurveyId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let surveyid = param.get('surveyid');
    if (surveyid) {
      surveyid = parseInt(surveyid);
    } else {
      surveyid = null;
    }
    return surveyid;
  }
  componentDidMount() {
    this.getRisk();
    this.props.dispatch(
      listSurveyQuestionAssessment({
        json: JSON.stringify({
          Survey: [
            {
              //   StudentId: 1002191,
              // SurveyId: 15009,
              SurveyId: this.getSurveyId(),
            },
          ],
        }),
      }),
    );
    // this.props.dispatch(
    //   augmentedClientInsightsGet({
    //     StudentId: 1002191,
    //     SurveyId: 15009,
    //   }),
    // );
  }
  getRisk = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            IsDefaultPreIntakeSurvey: true,
          },
        ],
      }),
    };
    Axios.post('Survey/ClientRiskGet', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            riskResponse: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  componentWillReceiveProps({ surveyQuestion, isAnswerUpsert }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      this.setState({ SurveyQuestionList: surveyQuestion });
    }
    if (isAnswerUpsert && isAnswerUpsert != this.props.isAnswerUpsert) {
      this.getRisk();
    }
  }
  setValue({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
    note,
  }) {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    var json = {
      StudentId: this.getIntakeId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      Note: note,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    let isRequired = response ? false : true;
    if (level === 0) {
      SurveyQuestionList.SurveyQuestion[index].Response = response;
      SurveyQuestionList.SurveyQuestion[index].IsRequired = isRequired;
      this.setState({ SurveyQuestionList, isMandatory: false });
    } else if (level === 1) {
      SurveyQuestionList.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      SurveyQuestionList.SurveyCategory[subLevel].SurveyQuestion[index].IsRequired = isRequired;

      this.setState({ SurveyQuestionList, isMandatory: false });
    } else if (level === 2) {
      SurveyQuestionList.SurveyCategory[subLevel].SurveySubCategory[subCatLevel].SurveyQuestion[
        index
      ].Response = response;
      SurveyQuestionList.SurveyCategory[subLevel].SurveySubCategory[subCatLevel].SurveyQuestion[
        index
      ].IsRequired = isRequired;

      this.setState({ SurveyQuestionList, isMandatory: false });
    }
    // const surveys = [...this.state.surveys];
    // for (var survey of surveys) {
    //   if (survey.SurveyQuestionId == surveyQuestionId) {
    //     survey.Response = response;
    //   }
    // }
    // if (IsSignature) {
    //   this.setState({
    //     surveys: surveys,
    //     Signature: IsSignature && Signature,
    //     SignatureCanvas: IsSignature && {
    //       ...this.state.SignatureCanvas,
    //       signatureDataList: {
    //         ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
    //         [surveyQuestionId]: {
    //           SurveyQuestionId: surveyQuestionId,
    //           isSignatureSaved: isSignatureSaved,
    //         },
    //       },
    //     },
    //   });
    // } else {
    //   this.setState({
    //     surveys: surveys,
    //   });
    // }

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index, isResetAnswer, note }) {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    let json = {
      StudentId: this.getIntakeId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      Note: note,
    };
    let isRequired = response ? false : true;
    if (level === 0) {
      SurveyQuestionList.SurveyQuestion[index].Response = response;
      SurveyQuestionList.SurveyQuestion[index].IsRequired = isRequired;

      this.setState({ SurveyQuestionList, isMandatory: false });
    } else if (level === 1) {
      SurveyQuestionList.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      SurveyQuestionList.SurveyCategory[subLevel].SurveyQuestion[index].IsRequired = isRequired;

      this.setState({ SurveyQuestionList, isMandatory: false });
    } else if (level === 2) {
      // let newState = Object.assign({}, this.state);
      SurveyQuestionList.SurveyCategory[subLevel].SurveySubCategory[subCatLevel].SurveyQuestion[
        index
      ].Response = response;
      SurveyQuestionList.SurveyCategory[subLevel].SurveySubCategory[subCatLevel].SurveyQuestion[
        index
      ].IsRequired = isRequired;
      this.setState({ SurveyQuestionList, isMandatory: false });
    }
    // const surveys = [...this.state.surveys];
    // for (let survey of surveys) {
    //   if (survey.SurveyQuestionId == surveyQuestionId) {
    //     survey.Response = response;
    //   }
    // }

    // $('.main-banner').slick('slickNext');
    // this.setState({
    //   surveys: surveys,
    // });
    // if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
  }
  handelSaveAnalyze = () => {
    let QuestionList = this.state.SurveyQuestionList.SurveyQuestion;
    let isAnalyze = QuestionList.some(item => {
      return !item.Response && item.IsRequired === true;
    });
    if (isAnalyze) {
      this.setState({
        isMandatory: true,
      });
    } else {
      this.props.dispatch(surveyLatestAnswerDateUpdate({ Surveyid: this.getSurveyId() }));
      this.props.history.push(
        `/clients/pre-intake/summary?studentid=${this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
      );
    }
  };
  render() {
    const { SurveyQuestionList } = this.state;
    const { augmentedData, augmentedAnswer, isFetchingSurveyQuestion } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Prospect Screening" />

        <div class="container-fluid enrollment-form pre-intake-form">
          <div class="row top-table-search my-2 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.props.history.push(
                    `/pre-intake-form-legacy/?studentid=${this.getIntakeId()}`,
                  )
                }
              >
                <img src="images/back-arrow.svg" /> Back to Client details
              </a>
              <h4 class="page-title mb-3 mb-md-0">
                {this.getIntakeId() > 0 ? 'Edit Prospect' : 'New Prospect'}
              </h4>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <input
                type="button"
                class=" btn"
                value="Save & Analyze"
                onClick={this.handelSaveAnalyze}
              />
            </div>
          </div>
          <div class="row pb-5">
            <div className="col-12 mb-2">
              <div class="card border-0 rounded-0">
                <div class="card-body">
                  <Loader2 loading={isFetchingSurveyQuestion} />
                  <div class="row align-items-center">
                    <div className="col-6">
                      <h4>Client Screening</h4>
                      {this.state.isMandatory && (
                        <span style={{ color: 'red' }}>Please answer all mandatory questions.</span>
                      )}
                    </div>
                    <div className="col-6">
                      <div className="d-flex justify-content-end">
                        <div className="d-flex risk-chart border p-2 py-3 shadow-sm">
                          <span className="mr-2" style={{ fontSize: '14px' }}>
                            Client Risk Chart:
                          </span>
                          {this.state.riskResponse === '0' && NoRisk}
                          {this.state.riskResponse === '1' && LowRisk}
                          {this.state.riskResponse === '2' && MediumRisk}
                          {this.state.riskResponse === '3' && HighRisk}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row survery-question-box client-screening"
                    style={{ maxWidth: 'unset', marginTop: '0px' }}
                  >
                    <div className="col-12 mt-2" style={{ height: '100vh', overflowY: 'scroll' }}>
                      {SurveyQuestionList && SurveyQuestionList.SurveyQuestion && (
                        <div
                          style={{
                            padding: '5px',
                            margin: '0px 5px',
                          }}
                        >
                          <QuestionType
                            SurveyQuestion={SurveyQuestionList.SurveyQuestion}
                            SurveyId={SurveyQuestionList.SurveyId}
                            onNext={this.onNext}
                            setValue={this.setValue}
                            scope={this}
                            level={0}
                            isReadOnly={this.state.isReadOn}
                            // saveSignature={this.saveSignature}
                          />
                        </div>
                      )}
                      {SurveyQuestionList &&
                        SurveyQuestionList.SurveyCategory &&
                        SurveyQuestionList.SurveyCategory.map((surveyCategory, index) => {
                          return (
                            <div>
                              <div
                                className="assessment-sub-title"
                                style={{
                                  margin: '0px 25px',
                                }}
                              >
                                <span className="sub-title-text">
                                  {surveyCategory.SurveyCategory}
                                </span>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#F8F8F8',
                                  padding: '25px',
                                  margin: '0px 25px',
                                }}
                              >
                                <QuestionType
                                  SurveyQuestion={surveyCategory.SurveyQuestion}
                                  SurveyId={SurveyQuestionList.SurveyId}
                                  onNext={this.onNext}
                                  setValue={this.setValue}
                                  scope={this}
                                  level={1}
                                  subLevel={index}
                                  isReadOnly={this.state.isReadOnly}
                                  // saveSignature={this.saveSignature}
                                />

                                {surveyCategory &&
                                  surveyCategory.SurveySubCategory &&
                                  surveyCategory.SurveySubCategory.map((subCategory, subIndex) => {
                                    return (
                                      <div
                                        style={{
                                          backgroundColor: '#FFF',
                                          padding: '15px',
                                        }}
                                      >
                                        <div className="assessment-sub-title">
                                          <span className="sub-title-text">
                                            {subCategory.SurveySubCategory}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: '#FFF',
                                            padding: '15px',
                                            // margin: '0px 25px',
                                          }}
                                        >
                                          <QuestionType
                                            SurveyQuestion={subCategory.SurveyQuestion}
                                            SurveyId={SurveyQuestionList.SurveyId}
                                            onNext={this.onNext}
                                            setValue={this.setValue}
                                            scope={this}
                                            level={2}
                                            subLevel={index}
                                            subCatLevel={subIndex}
                                            isReadOnly={this.state.isReadOnly}
                                            // saveSignature={this.saveSignature}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div class="col-12 text-right d-flex justify-content-end">
                    <input
                      type="button"
                      class=" btn"
                      value="Save & Analyze"
                      onClick={this.handelSaveAnalyze}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-12">
                <div class="card border-0 rounded-0">
                  <div class="card-body">
                    <h4>Augmented Client Insights</h4>
                    <p>{augmentedAnswer && <>{renderHTML(augmentedAnswer)}</>}</p>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    list_survey_question_assessment,
    augmented_client_insights_get,
    survey_answer_upsert,
  } = state;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = list_survey_question_assessment;
  const { data, isFetching } = augmented_client_insights_get;
  const { data: upsertData } = survey_answer_upsert;
  let surveyQuestion;
  let augmentedData;
  let augmentedAnswer;
  let isAnswerUpsert = false;

  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }
  if (data && data.data) {
    augmentedData = data;
    augmentedAnswer = data.data[0].Answer;
  }
  if (upsertData && upsertData.data && upsertData.data.success) {
    isAnswerUpsert = true;
  }
  return {
    surveyQuestion,
    isFetchingSurveyQuestion: isFetchingSurveyQuestion,
    augmentedData: augmentedData,
    augmentedAnswer,
    isAnswerUpsert,
  };
};
export default connect(mapStateToProps)(ClientScreening);
