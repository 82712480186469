import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate, {
  dateofbirthNormalize,
  normalizeToYYYYMMDD,
  MobileFormatter,
  MobileNormalize,
} from '../validation/validate';
import {
  ReduxSelect,
  ReduxInput,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';
import staticData from './staticDropdown';
import { listCombos } from '../../../store/actions';
import ToggleNote from '../toggleNote';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class LegalIssues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.countryISO) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: this.props.countryISO,
        }),
      );
    }
  }

  render() {
    const {
      handleSubmit,
      handleNext,
      name,
      FaithType,
      ChurchDenomination,
      style,
      isUnderLegalSupervisionValue,
      isLegallyRequiredTreatmentValue,
      probationOfficerEmailValue,
      probationOfficerPhoneValue,
      goToPreviuos,
      disable,
      StateProvince,
      Country,
      dispatch,
      untouch,
      ImpContactInfo,
      ClientLegalDays,
      ClientLegalSupervisionType,
      ClientLegalPending,
      ClientMedicalHistory,
      ClientLegalAllergies,
      ClientLegalTreatment,
      ClientLegalSpecialtiesOptions,
      ClientLegalWeekDay,
      onLastSubmit,
      currentTab,
      tabsCount,
      handleSubmitAndNext,
      isAttorneyAssistance,
      safetyConcerns,
      convictionsViolentOffenses,
      convictionsOfArson,
      isSexualOffender,
      isArrestedForSexualOffense,
    } = this.props;
    const countryChange = (countryISO, currentIndex) => {
      if (countryISO) {
        dispatch(
          listCombos({
            comboTypes: 'StateProvince',
            CountryISO: countryISO,
          }),
        );
      }
    };
    return (
      <form
        onSubmit={handleSubmit(data => {
          handleSubmitAndNext(data, currentTab, tabsCount);
        })}
        style={style}
      >
        <div class="row mb-3">
          <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
            <h6 class="d-inline-flex mb-0 mr-3 ">
              Are you legally required to enter a recovery or treatment program?
            </h6>
            <div class="custom-control custom-switch d-inline-flex ">
              <Field name="isLegallyRequiredTreatment" component={ReduxToggle} disabled={disable} />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`legallyRequiredOfficer`}
                placeholder="By Whom?"
                component={ReduxSelect}
                options={ClientLegalTreatment}
                disabled={disable}
                fieldName={'by Whom??'}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`legallyRequiredOfficerDetails`}
                type="text"
                component={ReduxInput}
                // validate={validate.required}
                validate={
                  isLegallyRequiredTreatmentValue
                    ? validate.required
                    : () => {
                        untouch('legallyRequiredOfficerDetails');
                        return false;
                      }
                }
                fieldName={'Legally Required Officers Name'}
                disabled={disable}
              />
              <label class="placeholder-label">
                Legally Required Officers Name{isLegallyRequiredTreatmentValue && '*'}
              </label>
            </div>
          </div>

          {isLegallyRequiredTreatmentValue && (
            <div class="col-12 col-md-6 col-lg-12">
              <div class="form-group">
                <Field
                  name={`isLegallyRequiredTreatmentNote`}
                  type="textarea"
                  component={ReduxTextarea}
                  disabled={disable}
                />
                <label class="placeholder-label">Notes</label>
              </div>
            </div>
          )}
        </div>

        <div class="row mb-3">
          <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
            <h6 class="d-inline-flex mb-0 mr-3 ">Are you under legal supervision?</h6>
            <div class="custom-control custom-switch d-inline-flex ">
              <Field
                name={`isUnderLegalSupervision`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`supervisionApproach`}
                placeholder="Method of reporting to that supervision"
                component={ReduxSelect}
                options={ClientLegalSupervisionType}
                disabled={disable}
                fieldName={'Method of reporting to that supervision?'}
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={'reportToSupervision'}
                type="text"
                placeholder={
                  isUnderLegalSupervisionValue
                    ? 'How often do you have to report in?*'
                    : 'How often do you have to report in?'
                }
                component={ReduxSelect}
                // validate={validate.required}
                validate={
                  isUnderLegalSupervisionValue
                    ? validate.required
                    : () => {
                        untouch('reportToSupervision');
                        return false;
                      }
                }
                options={ClientLegalDays}
                fieldName={'How often do you have to report in?'}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`probationOfficerName`}
                type="text"
                component={ReduxInput}
                // validate={validate.required}
                validate={
                  isUnderLegalSupervisionValue
                    ? validate.required
                    : () => {
                        untouch('probationOfficerName');
                        return false;
                      }
                }
                fieldName={'Probation officers Name'}
                disabled={disable}
              />
              <label class="placeholder-label">
                Probation Officers Name{isUnderLegalSupervisionValue && '*'}
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`probationOfficerJurisdiction`}
                type="text"
                component={ReduxInput}
                // validate={validate.required}
                validate={
                  isUnderLegalSupervisionValue
                    ? validate.required
                    : () => {
                        untouch('probationOfficerJurisdiction');
                        return false;
                      }
                }
                fieldName={'Jurisdiction'}
                disabled={disable}
              />
              <label class="placeholder-label">
                Jurisdiction{isUnderLegalSupervisionValue && '*'}
              </label>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`probationOfficerEmail`}
                type="text"
                component={ReduxInput}
                // validate={[
                //   Validator.email,
                //   () => {
                //     if (!probationOfficerPhoneValue && !probationOfficerEmailValue) {
                //       return 'or Phone Required';
                //     }
                //   },
                // ]}
                validate={
                  isUnderLegalSupervisionValue
                    ? [
                        Validator.email,
                        () => {
                          if (!probationOfficerPhoneValue && !probationOfficerEmailValue) {
                            return 'or Phone Required';
                          }
                        },
                      ]
                    : () => {
                        untouch('probationOfficerEmail');
                        return false;
                      }
                }
                fieldName={'Email'}
                disabled={disable}
              />
              <label class="placeholder-label">Email*</label>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`probationOfficerPhone`}
                type="text"
                component={ReduxInput}
                // validate={[
                //   validate.NumericMobile,
                //   () => {
                //     if (!probationOfficerPhoneValue && !probationOfficerEmailValue) {
                //       return 'or Email Required';
                //     }
                //   },
                // ]}
                validate={
                  isUnderLegalSupervisionValue
                    ? [
                        validate.NumericMobile,
                        () => {
                          if (!probationOfficerPhoneValue && !probationOfficerEmailValue) {
                            return 'or Email Required';
                          }
                        },
                      ]
                    : () => {
                        untouch('probationOfficerPhone');
                        return false;
                      }
                }
                format={MobileFormatter}
                normalize={MobileNormalize}
                fieldName={'Phone'}
                disabled={disable}
              />
              <label class="placeholder-label">Phone*</label>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`superVisionContact`}
                type="textarea"
                component={ReduxInput}
                disabled={disable}
                fieldName={'History of arrest and convictions'}
              />
              <label class="placeholder-label">Supervision Contact Address</label>
            </div>
          </div>
          <ToggleNote
            isToggleOn={isArrestedForSexualOffense}
            toggleQuestion={'Have you ever been convicted of a sexual offense?'}
            toggleFiledName={`isArrestedForSexualOffense`}
            toggleNoteFieldName={`isArrestedForSexualOffenseNote`}
            disable={disable}
          />
        </div>

        <div class="row mb-3">
          <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
            <h6 class="d-inline-flex mb-0 mr-3 ">Do you have an Attorney Assisting You?</h6>
            <div class="custom-control custom-switch d-inline-flex ">
              <Field
                name={`hasAttorneyAssistance`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
            </div>
          </div>
        </div>
        {isAttorneyAssistance && (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Attorney's contact information</h6>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyName`}
                    type="text"
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={"Attorney's Name"}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Attorney's Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyEmail`}
                    type="text"
                    component={ReduxInput}
                    validate={Validator.email}
                    fieldName={'Email'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Email</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyPhone`}
                    type="text"
                    component={ReduxInput}
                    validate={validate.NumericMobile}
                    format={MobileFormatter}
                    normalize={MobileNormalize}
                    fieldName={'Phone'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyAttorneyLine1`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label"> Address Line 1</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyAttorneyLine2`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label"> Address Line 2</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyCountry`}
                    type="text"
                    errorMessage="Country Required"
                    component={ReduxSelect}
                    options={Country}
                    // validate={Validator.required}
                    onChange={countryISO => {
                      countryChange(countryISO);
                      this.props.change(`attorneyStateOrProvince`, null);
                    }}
                    placeholder="Country"
                    fieldName={'Country'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyCity`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyStateOrProvince`}
                    type="text"
                    component={ReduxSelect}
                    options={StateProvince}
                    placeholder="State/Province/Territories"
                    fieldName="State/Province/Territories"
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`attorneyZip`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Postal/Zip Code</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={'pendingCases'}
                    type="text"
                    placeholder={
                      isUnderLegalSupervisionValue
                        ? 'Are any of the following pending*'
                        : 'Are any of the following pending'
                    }
                    component={ReduxSelect}
                    validate={
                      isUnderLegalSupervisionValue
                        ? validate.multiRequired
                        : () => {
                            untouch('pendingCases');
                            return false;
                          }
                    }
                    options={ClientLegalPending}
                    fieldName={'Are any of the following pending?'}
                    disabled={disable}
                    isMulti
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`pastArrestandConvictions`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                    fieldName={'History of arrest and convictions'}
                  />
                  <label class="placeholder-label">History of arrest and convictions</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`hasAttorneyAssistanceNote`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                    fieldName={'Notes'}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row">
          <ToggleNote
            isToggleOn={isSexualOffender}
            toggleQuestion={'Are you required to register as a sexual offender?'}
            toggleFiledName={`requiredToRegisterSexualOffender`}
            toggleNoteFieldName={`requiredToRegisterSexualOffenderNote`}
            disable={disable}
          />
          <ToggleNote
            isToggleOn={convictionsOfArson}
            toggleQuestion={' Any pending charges or convictions of Arson?'}
            toggleFiledName={`hasArsonConvictions`}
            toggleNoteFieldName={`hasArsonConvictionsNote`}
            disable={disable}
          />
          <ToggleNote
            isToggleOn={convictionsViolentOffenses}
            toggleQuestion={'Any pending charges or convictions for violent offenses?'}
            toggleFiledName={`hasViolentOffensesConvictions`}
            toggleNoteFieldName={`hasViolentOffensesConvictionsNote`}
            disable={disable}
          />

          <div class="col-12 col-md-6 col-lg-8">
            <div class="form-group">
              <Field
                name={`legalObligations`}
                type="textarea"
                component={ReduxInput}
                disabled={disable}
                fieldName={'Legal Obligations'}
              />
              <label class="placeholder-label">
                Any other Legal obligations? (ie. Court appearance, Pending charges, Hearings..)
              </label>
            </div>
          </div>
          <ToggleNote
            isToggleOn={safetyConcerns}
            toggleQuestion={`Do you have safety concerns? (ie. Gang affiliations, Domestic Violence,
                  Restraining Order)`}
            toggleFiledName={`hasSafetyConcerns`}
            toggleNoteFieldName={`hasSafetyConcernsNote`}
            disable={disable}
          />

          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`impContactInfoId`}
                type="text"
                component={ReduxSelect}
                options={ImpContactInfo}
                placeholder="Important Contact Information"
                fieldName="Important Contact Information"
                disabled={disable}
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-8">
            <div class="form-group">
              <Field
                name={`legalIssuesNotes`}
                type="textarea"
                component={ReduxTextarea}
                disabled={disable}
              />
              <label class="placeholder-label">Notes</label>
            </div>
          </div>
        </div>

        <div class="row pagination-button">
          <div class="col-12 text-right">
            {currentTab > 0 && (
              <button
                onClick={goToPreviuos}
                type="button"
                class="btn btn-outline-primary btn-eForm-Prev mr-2"
                disabled={disable}
              >
                Previous
              </button>
            )}
            {currentTab + 1 != tabsCount && (
              <button
                type="submit"
                // onClick={handleNext}
                class="btn btn-eForm-Next"
                disabled={disable}
              >
                Next
              </button>
            )}
            {currentTab + 1 == tabsCount && (
              <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default connect(state => {
  const { combos } = state;
  var isUnderLegalSupervisionValue = selector(state, 'isUnderLegalSupervision');
  var isLegallyRequiredTreatmentValue = selector(state, 'isLegallyRequiredTreatment');
  var probationOfficerEmailValue = selector(state, 'probationOfficerEmail');
  var probationOfficerPhoneValue = selector(state, 'probationOfficerPhone');
  var isAttorneyAssistance = selector(state, 'hasAttorneyAssistance');
  var isArrestedForSexualOffense = selector(state, 'isArrestedForSexualOffense');

  var isSexualOffender = selector(state, 'requiredToRegisterSexualOffender');
  var convictionsOfArson = selector(state, 'hasArsonConvictions');
  var convictionsViolentOffenses = selector(state, 'hasViolentOffensesConvictions');
  var safetyConcerns = selector(state, 'hasSafetyConcerns');

  let countryISO = selector(state, 'attorneyCountry') || 'USA';
  return {
    FaithType: combos.data.FaithType,
    ChurchDenomination: combos.data.ChurchDenomination,
    StateProvince: combos.data.StateProvince || [],
    Country: combos.data.Country || [],
    ClientLegalDays: combos.data.ClientLegalDays || [],
    ClientLegalSupervisionType: combos.data.ClientLegalSupervisionType || [],
    ClientLegalPending: combos.data.ClientLegalPending || [],
    ClientMedicalHistory: combos.data.ClientMedicalHistory || [],
    ClientLegalAllergies: combos.data.ClientLegalAllergies || [],
    ClientLegalTreatment: combos.data.ClientLegalTreatment || [],
    ClientLegalSpecialtiesOptions: combos.data.ClientLegalSpecialtiesOptions || [],
    ClientLegalWeekDay: combos.data.ClientLegalWeekDay,
    ImpContactInfo: combos.data.ImpContactInfo || [],
    isUnderLegalSupervisionValue,
    isLegallyRequiredTreatmentValue,
    probationOfficerEmailValue,
    probationOfficerPhoneValue,
    countryISO,
    isAttorneyAssistance,
    safetyConcerns,
    convictionsViolentOffenses,
    convictionsOfArson,
    isSexualOffender,
    isArrestedForSexualOffense,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(LegalIssues),
);

const selector = formValueSelector('wizard');
