import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { resourceListGetAll } from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import PageTitle from '../Components/PageTitle';
import renderHTML from 'react-render-html';

const columns = [
  {
    title: 'Resource Id',
    dataIndex: 'ResourceId',
    key: 'ResourceId',
    sorter: (a, b) => isNumericComparer(a.ResourceId, b.ResourceId),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'title',
    sorter: (a, b) => isComparer(a.Title, b.Title),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
  },
  {
    title: 'Content',
    dataIndex: 'HtmlContent',
    key: 'htmlContent',
    sorter: (a, b) => isComparer(a.HtmlContent, b.HtmlContent),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
  },

  {
    title: 'Sequence',
    dataIndex: 'Sequence',
    key: 'sequence',
    sorter: (a, b) => isNumericComparer(a.sequence, b.sequence),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => text && <div>{`${text}`}</div>,
  },

  {
    title: 'Last Updated By',
    dataIndex: 'LastUpdatedBy',
    key: 'LastUpdatedBy',
    sorter: (a, b) => isComparer(a.LastUpdatedBy, b.LastUpdatedBy),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => <div style={{ width: '300px' }}>{text}</div>,
  },
  {
    title: 'Last Updated On',
    dataIndex: 'LastUpdatedOn',
    key: 'LastUpdatedOn',
    sorter: (a, b) => isDateComparer(a.LastUpdatedOnForSort, b.LastUpdatedOnForSort),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => text && <div>{`${text}`}</div>,
  },
];

class AppResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  componentWillMount() {}

  componentDidMount() {
    this.props.dispatch(resourceListGetAll({ filter: null, searchStr: this.state.searchStr }));
  }

  onSearchChange = control => {
    var value = control.target.value;
    this.state.searchStr = value;
    if (value.length > 1) {
      this.props.dispatch(resourceListGetAll({ filter: null, searchStr: this.state.searchStr }));
    } else if (value.length == 0) {
      this.props.dispatch(resourceListGetAll({ filter: null, searchStr: null }));
    }
  };

  onRowClick = resourceId => {
    this.props.history.push('/manage/app-resource-form?appresourceId=' + resourceId);
  };

  onManageClick = announcementId => {
    this.props.history.push('/manage/staff?announcementId=' + announcementId);
  };

  render() {
    const { isFetching, resources } = this.props;

    return (
      <div class="main  mb-5">
        <div class="container-fluid">
          <PageTitle TitleText="App Resource List" />
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <Link to="/manage" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link>
              <h3 class="page-title mb-3 mb-md-0">App Resource</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div class="search-box w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>
              <Link to="/manage/app-resource-form" class="btn" title="Add App Resource">
                Add App Resource
              </Link>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="row-hover-enabled">
                      <Table
                        columns={columns}
                        dataSource={resources}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: event => this.onRowClick(record.ResourceId),
                          };
                        }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ resource_list_get_all, resource_get }) => {
  var { data, isFetching, error } = resource_list_get_all;

  if (data && data.Resource) {
    data = data.Resource;
  }

  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }
  return {
    resources: data || [],
    isFetching,
  };
};

export default connect(mapStateToProps)(AppResource);
