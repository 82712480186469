import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isComparer, isDateComparer, isNumericComparer } from '../util/handler';
import {
  getSmallGroupList,
  getSmallGroup,
  sendVoucherCode,
  appSubscribersUserSearch,
} from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomModal from '../Components/CustomModal';
import { Checkbox, Radio, Tooltip, Modal, Table, Avatar } from 'antd';
import renderHTML from 'react-render-html';
import { FormatDate } from '../Components/FormatDate';
import AntdModal from '../Components/CustomModal';
import CustomCoreModal from '../core/modal';
import _blank from '../images/blank-profile.png';
import _smallGroup from '../images/smallgroup/sm-01.png';
import ReduxBroadcaseInvitationModal from './customScreens/broadcastInvitation';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { ReduxSendInvitationModal } from './SendInvitation';
import { CommonLicenceScreens } from './licenceScreens';
import ComingSoon from '../component/comingSoonPage';
import PageTitle from '../Components/PageTitle';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class SmallGroup extends Component {
  state = {
    searchStr: null,
    inviteFormModal: false,
    inviteSubscriberModal: false,
    groupFilter: 13201,
    isGridView: true,
    FiilterBy: [],
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount({ searchStr } = this.state) {
    const { dispatch } = this.props;
    let isGridVal = sessionStorage.getItem('isGridView') || true;
    let isGridView = typeof isGridVal === 'string' ? isGridVal === 'true' : isGridVal;
    this.setState({
      isGridView: isGridView,
    });
    var groupFilterValue = Number(sessionStorage.getItem('groupFilter') || null);
    var searchStrValue = sessionStorage.getItem('searchStr') || null;
    if (groupFilterValue && searchStrValue) {
      let filterData = {
        Json: JSON.stringify({
          SmallGroup: [
            {
              Filterby: groupFilterValue,
              Search: searchStrValue,
            },
          ],
        }),
      };
      dispatch(getSmallGroupList(filterData));

      this.setState({
        groupFilter: groupFilterValue,
        searchStr: searchStrValue,
      });
    } else if (groupFilterValue) {
      let filterData = {
        Json: JSON.stringify({
          SmallGroup: [
            {
              Filterby: groupFilterValue,
            },
          ],
        }),
      };
      dispatch(getSmallGroupList(filterData));
      this.setState({ groupFilter: groupFilterValue });
    } else if (searchStrValue) {
      let data = {
        SmallGroup: [{ Search: searchStrValue }],
      };
      let jsonData = {
        Json: JSON.stringify(data),
      };

      this.setState({ searchStr: JSON.stringify(searchStrValue) });
      dispatch(getSmallGroupList(jsonData));
    } else {
      let filterData = {
        Json: JSON.stringify({
          SmallGroup: [
            {
              Filterby: this.state.groupFilter,
            },
          ],
        }),
      };
      dispatch(getSmallGroupList(filterData));
    }
  }
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    const { dispatch } = this.props;
    if (value.length > 0) {
      let data = {
        SmallGroup: [
          {
            Search: value,
            Filterby: this.state.groupFilter,
          },
        ],
      };
      let jsonData = {
        Json: JSON.stringify(data),
      };
      dispatch(getSmallGroupList(jsonData));
      sessionStorage.setItem('searchStr', value);
    } else {
      let data = {
        SmallGroup: [
          {
            Search: null,
            Filterby: this.state.groupFilter,
          },
        ],
      };
      let jsonData = {
        Json: JSON.stringify(data),
      };

      dispatch(getSmallGroupList(jsonData));
    }
  };
  componentWillReceiveProps({ FiilterBy, smallGroupStatusData }) {
    if (FiilterBy && FiilterBy != this.props.FiilterBy) {
      this.setState({
        FiilterBy,
      });
    }

    if (
      smallGroupStatusData &&
      JSON.stringify(smallGroupStatusData) != JSON.stringify(this.props.smallGroupStatusData)
    ) {
      this.setState({ smallGroupStatusData });
    }
  }
  onGroupClick = (record, index) => {
    const { history } = this.props;
    history.push('/manage/small-group-form?smallGroupGuid=' + record.SmallGroupGuid);
  };

  onFilterChange = value => {
    this.setState({
      groupFilter: value,
    });
    sessionStorage.setItem('groupFilter', JSON.stringify(value));
    const { dispatch, smallGroupGuid } = this.props;
    let filterData = {
      Json: JSON.stringify({
        SmallGroup: [
          {
            Filterby: value,
            Search: this.state.searchStr || null,
          },
        ],
      }),
    };
    dispatch(getSmallGroupList(filterData));
  };

  // toggleView = () => {
  //   this.setState(
  //     prevState => ({
  //       isGridView: !prevState.isGridView,
  //     }),
  //     () => {
  //       sessionStorage.setItem('isGridView', JSON.stringify(this.state.isGridView));
  //     },
  //   );
  //   // sessionStorage.setItem('isGridView', JSON.stringify(!prevState.isGridView));
  // };
  toggleView = () => {
    this.setState(
      prevState => ({
        isToggleOn: !prevState.isToggleOn,
        isGridView: !prevState.isGridView,
      }),
      () => {
        sessionStorage.setItem('isGridView', JSON.stringify(this.state.isGridView));
      },
    );
  };
  render() {
    const { isFetching, SmallGroups, NameCourse, smallGroupStatusData } = this.props;
    const { isGridView, FiilterBy } = this.state;
    const columns = [
      {
        title: 'Group Name',
        dataIndex: 'GroupName',
        key: 'GroupName',
        sorter: (a, b) => isComparer(a.GroupName, b.GroupName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Active',
        dataIndex: 'IsDeActivated',
        key: 'IsDeActivated',
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{text ? 'Yes' : 'No'}</span>
            </div>
          );
        },
        sorter: (a, b) =>
          isComparer(JSON.stringify(a.IsDeActivated), JSON.stringify(b.IsDeActivated)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Small Group Date',
        dataIndex: 'SmallGroupDate',
        key: 'SmallGroupDate',
        sorter: (a, b) => isDateComparer(a.SmallGroupDateSort, b.SmallGroupDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Group Tag',
        dataIndex: 'GroupTag',
        key: 'GroupTag',
        sorter: (a, b) => isComparer(a.GroupTag, b.GroupTag),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Group Leader',
        dataIndex: 'GroupLeader',
        key: 'GroupLeader',
        sorter: (a, b) => isComparer(a.GroupLeader, b.GroupLeader),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Meeting Info',
        dataIndex: 'MeetingInfo',
        key: 'MeetingInfo',
        sorter: (a, b) => isComparer(a.MeetingInfo, b.MeetingInfo),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Group Capacity',
        dataIndex: 'GroupCapacity',
        key: 'GroupCapacity',
        sorter: (a, b) => isNumericComparer(a.GroupCapacity, b.GroupCapacity),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Group Enrolled',
        dataIndex: 'GroupEnrolled',
        key: 'GroupEnrolled',
        sorter: (a, b) => isNumericComparer(a.GroupEnrolled, b.GroupEnrolled),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Last Meeting',
        dataIndex: 'LastMeeting',
        key: 'LastMeeting',
        sorter: (a, b) => isDateComparer(a.LastMeetingSort, b.LastMeetingSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Next Meeting',
        dataIndex: 'NextMeeting',
        key: 'NextMeeting',
        sorter: (a, b) => isDateComparer(a.NextMeetingSort, b.NextMeetingSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Last Updated',
        dataIndex: 'LastUpdated',
        key: 'LastUpdated',
        sorter: (a, b) => isDateComparer(a.LastUpdatedSort, b.LastUpdatedSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Is Private',
        dataIndex: 'IsPrivate',
        key: 'IsPrivate',
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{text ? 'Yes' : 'No'}</span>
            </div>
          );
        },
        sorter: (a, b) => isComparer(JSON.stringify(a.IsPrivate), JSON.stringify(b.IsPrivate)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Is Published',
        dataIndex: 'IsPublished',
        key: 'IsPublished',
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{text ? 'Published' : 'Un-Published'}</span>
            </div>
          );
        },
        sorter: (a, b) => isComparer(JSON.stringify(a.IsPublished), JSON.stringify(b.IsPublished)),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    return (
      <>
        <PageTitle TitleText="Small Group" />
        <div class="container-fluid">
          <CommonLicenceScreens
            dispatch={this.props.dispatch}
            StatusData={this.state.smallGroupStatusData}
            moduleName={'SmallGroup'}
          />
        </div>

        {this.state.smallGroupStatusData && this.state.smallGroupStatusData.LicenseStatusId == 6 ? (
          <div>
            <ComingSoon />
            <div className="pb-5"></div>
          </div>
        ) : (
          <>
            {this.state.smallGroupStatusData &&
              this.state.smallGroupStatusData.LicenseStatusId != 1 &&
              this.state.smallGroupStatusData.LicenseStatusId != 3 &&
              this.state.smallGroupStatusData.LicenseStatusId != 5 && (
                <>
                  <div class="container-fluid">
                    <div
                      class="content-area"
                      // style={{ maxWidth: '1366px', margin: '0 auto' }}
                    >
                      <div class="row top-table-search mt-5 mb-4 align-items-center">
                        <div class="col-xs-12 col-lg-2">
                          <a
                            class="back-link"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.props.history.push('/manage')}
                          >
                            <img src="images/back-arrow.svg" /> Back
                          </a>

                          <h3 class="page-title mb-3 mb-md-0">Groups</h3>
                        </div>

                        <div class="col-xs-12 col-lg-10">
                          <div className="row no-gutters">
                            <div className="col-lg-12 col-xl-5 d-flex align-items-center justify-content-end mb-2">
                              <div className="d-none">
                                {' '}
                                <a
                                  onClick={() => {
                                    this.setState({
                                      reduxBroadcaseInvitationModal: true,
                                    });
                                  }}
                                >
                                  <span style={{ color: 'blue', marginRight: '15px' }}>
                                    Broadcast Message
                                  </span>
                                </a>
                              </div>
                              <div id="btnContainer" className="mr-3">
                                {/* <button class="btn" onClick={this.toggleView}>
                    {isGridView ? <i class="fa fa-list" /> : <i class="fa fa-th-large" />}
                  </button> */}

                                <div>
                                  <button
                                    onClick={this.toggleView}
                                    style={
                                      !this.state.isGridView
                                        ? { padding: '8px 20px' }
                                        : { padding: '7px 20px', color: '#9A9A9A' }
                                    }
                                    className={
                                      !this.state.isGridView
                                        ? 'btn btn-trigger-map'
                                        : 'btn-trigger-map-off btn-trigger-map'
                                    }
                                  >
                                    <i class="fa fa-list" />
                                  </button>
                                  <button
                                    onClick={this.toggleView}
                                    style={
                                      !this.state.isGridView
                                        ? { padding: '7px 20px', color: '#9A9A9A' }
                                        : { padding: '8px 20px' }
                                    }
                                    className={
                                      !this.state.isGridView
                                        ? 'btn-trigger-list-off btn-trigger-list'
                                        : 'btn btn-trigger-list ml-0'
                                    }
                                  >
                                    <i class="fa fa-th-large" />
                                  </button>
                                </div>
                              </div>

                              <div class="form-group mb-0 select-sm-filter">
                                <ReduxSelect
                                  options={FiilterBy}
                                  onChange={this.onFilterChange}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  value={this.state.groupFilter}
                                  // placeholder="Filter By"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-xl-7">
                              <div className="d-flex align-items-center justify-content-end">
                                <div
                                  class="search-box d-inline-flex w-100"
                                  style={{ maxWidth: '350px', marginBottom: '10px' }}
                                >
                                  <div class="form-group has-search w-100">
                                    <input
                                      type="text"
                                      class="form-control 
                   shadow-sm"
                                      placeholder="Search"
                                      id="filterbox"
                                      onChange={this.onSearchChange}
                                      value={this.state.searchStr}
                                    />
                                    <button class="btn search-icon " type="button">
                                      {' '}
                                      <i class="fa fa-search" />{' '}
                                    </button>
                                  </div>
                                </div>
                                <Link
                                  to="/manage/small-group-form"
                                  class="btn black-btn"
                                  color="blue"
                                >
                                  Add Small Group
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mb-5">
                          <div class="row" style={{ minHeight: '300px' }}>
                            <Loader2 loading={isFetching} />
                            {isGridView ? (
                              <>
                                {SmallGroups &&
                                  SmallGroups.map((group, index) => {
                                    return (
                                      <div class="col-xs-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                                        <div
                                          className="small-group-box"
                                          onClick={event => {
                                            event.stopPropagation();
                                            this.onGroupClick(group, index);
                                          }}
                                        >
                                          <div
                                            className="small-group-up"
                                            style={{
                                              backgroundImage: `url(${group.GroupPictureUrl ||
                                                _blank})`,
                                              // backgroundImage: `url(${_smallGroup || _blank})`,
                                            }}
                                          >
                                            <div className="small-group-info">
                                              <div className="group-info">
                                                <span className="group-name">
                                                  {group.GroupName}
                                                </span>
                                                <span className="group-tag">{group.GroupTag}</span>
                                                <span className="group-time">
                                                  {' '}
                                                  <i class="far fa-clock"></i> {group.MeetingInfo}
                                                </span>
                                              </div>
                                              <div style={{ marginBottom: ' 0.2rem' }}>
                                                {group.IsPublished ? (
                                                  <span className="published-small-group">
                                                    Published
                                                  </span>
                                                ) : (
                                                  <span className="un-published-small-group">
                                                    Un-Published
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="small-group-down" style={{}}>
                                            <div className="group-description">
                                              <div className="group-leader-profile">
                                                <img
                                                  src={
                                                    group.GroupLeaderProfilePicUrl ||
                                                    'images/blank-profile.png'
                                                  }
                                                  class="rounded-circle face-fit-cover mr-2"
                                                  width="47"
                                                  height="47"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="group-leader-desc">
                                                <span className="leader">{group.GroupLeader}</span>
                                                <span className="date">{group.SmallGroupDate}</span>
                                              </div>
                                              <div className="group-action actions-button">
                                                {/* <Tooltip placement="top" title="Edit SmallGroup">
                                    <span
                                      class="edit-group"
                                      onClick={(record, rowIndex) => {
                                        this.onGroupClick(group, index);
                                      }}
                                    >
                                      <i class="fas fa-pencil-alt" />
                                    </span>
                                  </Tooltip> */}
                                                {!group.IsDeActivated && (
                                                  <Tooltip placement="top" title="Invite">
                                                    <span
                                                      class="edit-group"
                                                      data-toggle="modal"
                                                      data-target="#deactivate-staff-model"
                                                      onClick={event => {
                                                        event.stopPropagation();
                                                        this.setState({
                                                          inviteSubscriberModal: true,
                                                          smallGroupGuid: group.SmallGroupGuid,
                                                        });
                                                      }}
                                                    >
                                                      <i class="fas fa-plus" />
                                                    </span>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            </div>
                                            <div className="group-schedule">
                                              <div className="py-2">
                                                <span className="label-text">Capacity</span>
                                                <span className="label-value">
                                                  {group.GroupCapacity}
                                                </span>
                                              </div>
                                              <div className="py-2">
                                                <span className="label-text">Enrolled</span>
                                                <span className="label-value">
                                                  {group.GroupEnrolled}
                                                </span>
                                              </div>
                                              <div className="py-2">
                                                <span className="label-text">Last Meeting</span>
                                                <span className="label-value">
                                                  {group.LastMeeting}
                                                </span>
                                              </div>
                                              <div className="py-2">
                                                <span className="label-text">Next Meeting</span>
                                                <span className="label-value">
                                                  {group.NextMeeting}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="sm-action-area">
                                              <div className="zoom-link">
                                                {!group.IsDeActivated && group.ZoomMeetingUrl && (
                                                  <div className="meeting-link-bg">
                                                    <span
                                                      onClick={event => {
                                                        event.stopPropagation();
                                                        group.ZoomMeetingUrl &&
                                                          window.open(group.ZoomMeetingUrl);
                                                      }}
                                                    >{`Launch meeting`}</span>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="zoom-link">
                                                {group.IsLastMeeting && (
                                                  <div className="past-meeting-link-bg">
                                                    <span
                                                      onClick={event => {
                                                        event.stopPropagation();
                                                        this.props.history.push(
                                                          '/manage/sg-meeting-list?smallGroupGuid=' +
                                                            group.SmallGroupGuid,
                                                        );
                                                      }}
                                                    >{`Past Meeting`}</span>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="group-status">
                                                {group.IsDeActivated ? (
                                                  <span className="in-active-small-group">
                                                    Inactive
                                                  </span>
                                                ) : (
                                                  <span className="active-small-group">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                    Active
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            <div className="sm-action-area last-updated">
                                              <div className="py-2">
                                                <span className="label-text">Last Updated : </span>
                                                <span className="label-value">
                                                  {group.LastUpdated}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                {SmallGroups && SmallGroups.length > 0 && (
                                  <div className="col-12">
                                    <div className="card border-0 rounded-0 p-3 row-hover-enabled">
                                      <Table
                                        selections={false}
                                        columns={columns}
                                        dataSource={SmallGroups}
                                        rowKey={record => record.StaffId}
                                        onRow={(record, rowIndex) => {
                                          return {
                                            onClick: event => this.onGroupClick(record, rowIndex),
                                          };
                                        }}
                                        pagination={{
                                          itemRender: (page, type, originalElement) => {
                                            return (
                                              <div class="dataTables_wrapper no-footer">
                                                <div class="dataTables_paginate paging_simple_numbers">
                                                  {type == 'prev' ? (
                                                    <a class="paginate_button previous">Previous</a>
                                                  ) : type == 'next' ? (
                                                    <a class="paginate_button next">Next</a>
                                                  ) : (
                                                    <a class="paginate_button ">{page}</a>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          },
                                          showSizeChanger: true,
                                          // hideOnSinglePage: true,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {SmallGroups && SmallGroups.length == 0 ? (
                              <div className="text-center w-100" style={{ marginTop: '150px' }}>
                                <h4 style={{ color: 'rgb(197 190 190)' }}>No Small Groups Found</h4>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      zIndex={100}
                      visible={this.state.inviteSubscriberModal}
                      footer={null}
                      closable={false}
                    >
                      <ReduxSendInvitationModal
                        // {...this.props}
                        // GetStudentGui={this.getStudentGui}
                        SmallGroupGuid={this.state.smallGroupGuid}
                        initialValues={{
                          referralType: 'R',
                          CountryCode: '1',
                        }}
                        ClientType="subscriber"
                        onCancel={() => {
                          this.setState({
                            inviteSubscriberModal: false,
                          });
                        }}
                      />
                    </Modal>
                    <Modal
                      visible={this.state.reduxBroadcaseInvitationModal}
                      footer={null}
                      closable={false}
                      width={702}
                      destroyOnClose={true}
                    >
                      <ReduxBroadcaseInvitationModal
                        onCancel={() => {
                          this.setState({
                            reduxBroadcaseInvitationModal: false,
                          });
                        }}
                      />
                    </Modal>
                  </div>
                </>
              )}{' '}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ get_small_group_list, user_feature_flag_get }) => {
  var { data, isFetching, error } = get_small_group_list;
  const { data: smallGroupStatus } = user_feature_flag_get;
  let SmallGroups = [];
  let FiilterBy;
  let smallGroupStatusData = null;
  if (data && data.SmallGroup) {
    SmallGroups = data.SmallGroup;
  }
  if (data && data.FiilterBy) {
    FiilterBy = data.FiilterBy;
  }

  if (
    smallGroupStatus &&
    smallGroupStatus.FeatureFlag &&
    smallGroupStatus.FeatureFlag.length > 0 &&
    smallGroupStatus.FeatureFlag[0]
  ) {
    smallGroupStatusData =
      (smallGroupStatus.FeatureFlag &&
        smallGroupStatus.FeatureFlag[0] &&
        smallGroupStatus.FeatureFlag[0].License &&
        smallGroupStatus.FeatureFlag[0].License.length > 0 &&
        smallGroupStatus.FeatureFlag[0].License.filter(
          item => item.ModuleName == 'SmallGroup',
        )[0]);
  }

  return {
    SmallGroups: SmallGroups,
    FiilterBy,
    isFetching,
    smallGroupStatusData,
  };
};

export default connect(mapStateToProps)(SmallGroup);
