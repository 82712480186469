import React, { Component } from 'react';
import { Modal, Checkbox, Tag } from 'antd';
import licenseagreement from '../data/licenseagreement.pdf';
class LicenseModal extends Component {
  constructor() {
    super();
    this.state = {
      checked: false,
    };
  }
  render() {
    const { onOk, onCancel, handleSubmit, onLicenseModalCancel } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap pb-0 border-bottom-0 ">
          {/* <h4 class="modal-title col-12">Welcome {localStorage.getItem('userName')}</h4> */}
          <div className="w-100 text-center mb-3">
            <img src="images/SoberPeer_Logo.png" alt="asas" style={{ height: '70px' }} />
          </div>
          <div className="w-100">
            <h5 align="CENTER">LICENSE AGREEMENT</h5>
            <div align="RIGHT">
              <Tag
                // icon={<TwitterOutlined />}
                color="#55acee"
              >
                <a href={licenseagreement} download="LICENSE AGREEMENT" target="_blank">
                  Download As PDF
                </a>
              </Tag>
            </div>
          </div>
        </div>
        <div class="modal-body col-md-12 pr-0 enrollment-form pb-2">
          <div style={{ height: '400px', overflowY: 'scroll', paddingRight: '10px' }}>
            <p align="JUSTIFY">
              This License Agreement (the “Agreement”) is entered into by and between Sober Peer,
              LLC, a Delaware limited liability company (“Licensor”), and the{' '}
              <strong>[Treatment Center]</strong> executing this Agreement as a licensee
              (“Licensee”).
            </p>
            <p align="CENTER">
              <h6>RECITALS:</h6>
            </p>
            <p align="JUSTIFY">
              A. Licensor has developed that certain enterprise, cloud-based, healthcare treatment
              platform (the “Platform”) and that certain social mobile application allowing users
              to, among other things, report to and share social data with, other users (the “App,”
              and together with the Platform, the “Property Rights”).
            </p>
            <p align="JUSTIFY">
              B.<strong> </strong>Licensee desires to receive from Licensor, and Licensor agrees to
              grant to Licensee, a license of the Property Rights and related rights pursuant to the
              terms and conditions of this Agreement.
            </p>
            <p align="CENTER">
              <h6>AGREEMENT:</h6>
            </p>
            <p align="JUSTIFY">
              Now, therefore, in consideration of the mutual covenants, terms and conditions set
              forth in this Agreement, and for other good and valuable consideration, the receipt
              and sufficiency of which are acknowledged, the parties agree as follows:
            </p>
            <p align="CENTER">
              <h6>I. DEFINITIONS</h6>
            </p>
            <p align="JUSTIFY">As used in this Agreement:</p>
            <p align="JUSTIFY">
              “Affiliate” means, with respect to a Party, at the time of determination, any Person
              which controls, is controlled by, or is under common control with the specified Party.
              For purposes of this definition, “control” (including, with correlative meanings,
              “controlled by”, “controlling” and “under common control with”) means possession,
              direct or indirect, of (a) the power to direct or cause direction of the management
              and policies of such Party (whether through ownership of securities or other ownership
              interests), or (b) greater than 50% of the voting securities (whether directly or
              pursuant to any option, warrant or other similar arrangement) or other comparable
              equity interests.
            </p>
            <p align="JUSTIFY">
              “Business Day” means a day that banks are open for business in the jurisdictions in
              which the applicable remitting and receiving banks are located.
            </p>
            <p align="JUSTIFY">“Change in Control” means</p>
            <p align="JUSTIFY">
              (a) fifty percent (50%) or more of the outstanding voting securities or voting power
              of Licensee are sold or otherwise transferred (voluntarily, by operation of law or by
              any other method) to any Third Party; or
            </p>
            <p align="JUSTIFY">
              (b) Licensee is merged, combined or consolidated, whether by (i) acquisition, (ii)
              statutory method, or (iii) by any other means with or into any Third Party, and as a
              result of such merger, combination or consolidation fifty percent (50%) or more of the
              outstanding voting securities or voting power of the surviving Person is not owned by
              the Persons which were in control of Licensee as of the Effective Date; or
            </p>
            <p align="JUSTIFY">
              (c) Licensee sells or transfers all or substantially all of its assets to a Third
              Party; or
            </p>
            <p align="JUSTIFY">
              (d) the individuals or others who constitute Licensee’s board of managers on the
              Effective Date (its “Incumbent Management”) cease for any reason to constitute at
              least a majority thereof, provided that anyone becoming a member of the Licensee’s
              governing body after that date who was nominated for such position by the Incumbent
              Management shall be deemed to be a member of the Incumbent Management
            </p>
            <p align="JUSTIFY">
              “Confidential Information” has the meaning set forth in Section 7.15.
            </p>

            <p align="JUSTIFY">
              “Derivative Works” means the following subsisting throughout the world with respect
              to, applicable to or arising out of, the Property Rights (a) for material subject to
              copyright or other similar work right protection, any work which as a whole,
              represents an original work of authorship, and is based upon one or more pre-existing
              works, such as a revision, modification, translation, abridgment, condensation,
              expansion, collection, compilation or any other form in which such pre-existing works
              may be recast, transformed or adapted, (b) for patentable materials, any adaptation,
              subset, addition, improvement or combination of such materials, (c) for material
              subject to trade secret protection, any new material, information or data relating to
              and derived from such material, including new material which may be protectable by
              copyright, patent or other proprietary rights, and (d) with respect to each of the
              above, the preparation, use and/or distribution of which, in the absence of this
              Agreement or other authorization from the owner, would constitute infringement under
              applicable law.
            </p>
            <div id="Section1" dir="LTR">
              <p align="JUSTIFY">“Effective Date” means the date of this Agreement.</p>
              <p align="JUSTIFY">
                “Improvements” means improvements, updates, new releases, enhancements and other
                modifications to the Licensed Technology and any documentation related thereto made
                by Licensee.
              </p>
              <p align="JUSTIFY">
                “Intellectual Property” means the following subsisting throughout the world with
                respect to, applicable to or arising out of, the Property Rights (a) Patent Rights,
                (b) copyrights, designs, data and database rights and registrations and applications
                for registration thereof, (c) registrations and applications for registration
                thereof and any other rights in the Property Rights under the laws of any
                jurisdiction, (d) inventions, invention disclosures, statutory invention
                registrations, trade secrets and confidential business information, know-how,
                manufacturing and product processes and techniques, research and development
                information, financial, marketing and business data, pricing and cost information,
                business and marketing plans and customer and supplier lists and information,
                whether patentable or nonpatentable, whether copyrightable or noncopyrightable and
                whether or not reduced to practice, and (e) other proprietary rights relating to any
                of the foregoing (including rights of protection of interest therein under the laws
                of all jurisdictions).
              </p>
              <p align="JUSTIFY">
                “Kiosk” means the system and user interface software designed for and used in
                conjunction with the Platform and the App.
              </p>
              <p align="JUSTIFY">
                “Licensed Technology” means (a) the Property Rights, (b) the Intellectual Property,
                (c) all Moral Rights, (d) all Derivative Works, (e) all Technology, (f) all the
                technology that has ever been or will ever be developed by Licensor relating to the
                Property Rights, and (g) all data, know-how, formulae, compositions, processes,
                designs, sketches, photographs, graphs, drawings, samples, program proposals,
                presentations, inventions and ideas, past, current, and planned research and
                development, current and planned manufacturing or distribution methods and
                processes, market studies, business plans, computer software and programs, including
                object code and source code, computer software and database technologies, systems,
                structures and architectures, and related processes, formulae, compositions,
                improvements, devices, know-how, inventions, discoveries, concepts, ideas, designs,
                methods and information related to, arising from or attributable to the Property
                Rights.
              </p>
              <p align="JUSTIFY">
                “Metadata” means any and all data that describes and gives information about other
                data (a) gathered by the Licensor from the health, medical or billing records
                retained by Licensor during the term of this Agreement and (b) gathered by the
                Licensor from the unidentifiable geographic, demographic, and social data of the App
                users.
              </p>
              <p align="JUSTIFY">
                “Moral Rights” means the following subsisting throughout the world with respect to,
                applicable to or arising out of, the Property Rights (a) any rights of paternity or
                integrity, (b) any right to claim authorship, to object to or prevent any
                distortion, mutilation or modification of, or other derogatory action in relation to
                the subject work, whether or not such would be prejudicial to the author’s honor or
                reputation, (c) any right to withdraw from circulation or control the publication or
                distribution of the subject work, and (d) any similar right, existing under judicial
                or statutory law of any country in the world, or under any treaty, regardless of
                whether or not such right is denominated or generally referred to as a “moral
                right.”
              </p>
              <p align="JUSTIFY">
                “Party” means Licensee or Licensor; “Parties” means Licensee and Licensor.
              </p>
              <p align="JUSTIFY">
                “Patent Rights” means all patents, patent applications, utility models, design
                registrations and certificates of invention and other governmental grants for the
                protection of inventions or industrial designs (including all related continuations,
                continuations-in-part, divisional, reissues and reexaminations).
              </p>
              <p align="JUSTIFY">
                “Permitted Use” means the right to (a) access, make, use, and exploit the Licensed
                Technology (or any portion thereof), and (b) maintain, preserve and support the
                Licensed Technology, including the right to make approved modifications to the
                Licensed Technology.
              </p>
              <p align="JUSTIFY">
                “Person” means a natural person, sole proprietorship, corporation, general
                partnership, limited partnership, limited liability partnership, limited liability
                company, joint venture, unincorporated organization, joint stock company, trust,
                estate, governmental entity or other legally-recognized entity.
              </p>
              <p align="JUSTIFY">
                “Product” means each product, good, service, and item of merchandise containing or
                utilizing any Licensed Technology, whether in whole or in part, that is Transferred
                by Licensee or any Affiliate of Licensee.
              </p>
              <p align="JUSTIFY">
                “Property Rights” means the Platform, the App, and the Kiosk used to access the
                Platform.
              </p>
              <p align="JUSTIFY">
                “Technology” means the following subsisting throughout the world with respect to,
                applicable to or arising out of, the Property Rights: tangible embodiments, whether
                in electronic, written or other media, of technology, and information, including,
                without limitation, the Licensor’s curriculum, data analysis and payment processing.
              </p>
              <p align="JUSTIFY">
                “Transfer” means the sale, transfer, assignment, gift, donation, or other
                disposition.
              </p>
              <p align="JUSTIFY">
                “Third Party” means any Person other than a Party or any Affiliate of a Party.
              </p>
              <p align="CENTER">
                <h6>II. LICENSE</h6>
              </p>
              <p align="JUSTIFY">
                2.1{' '}
                <u>
                  <strong>License Grant</strong>
                </u>
                .
              </p>
              <p align="JUSTIFY">
                (a) <u>License</u>. Subject to Licensee’s continuing compliance and performance of
                its obligations under this Agreement, and the other terms and conditions set forth
                in this Agreement, Licensor grants to Licensee a non-exclusive, non-transferable
                (except as otherwise expressly set forth in this Agreement), right to use and
                exploit the Licensed Technology in accordance with the Permitted Use (the
                “License”).
              </p>
              <p align="JUSTIFY">
                (b) <u>No Right to Sublicense</u>. Licensee shall not grant a sublicense of the
                Licensed Technology to any Person.
              </p>
              <p align="JUSTIFY">
                2.2{' '}
                <u>
                  <strong>Delivery; Further Assurances</strong>
                </u>
                . On the Effective Date, Licensor shall deliver to Licensee (a) the tangible
                Licensed Technology, and (b) copies of tangible embodiments of the Licensed
                Technology to the extent they are in the possession of Licensor or its Affiliates.
                If the Licensed Technology and tangible embodiments are not delivered by Licensor on
                the Effective Date, Licensor shall deliver, without charge, such Licensed Technology
                and its tangible embodiments to Licensee promptly upon discovery or written notice
                from Licensee. At any time and from time to time after the Effective Date, at the
                reasonable request of Licensee and at Licensor’s expense, but without further
                consideration, Licensor shall promptly execute and deliver such other instruments of
                license, and shall take such actions, as Licensee may reasonably request to more
                effectively transfer and license Licensee, and to confirm Licensor’s title in,
                ownership of, and other rights in, to and under, the Licensed Technology and to
                place Licensee in actual possession of the Licensed Technology.
              </p>
              <p align="JUSTIFY">
                2.3{' '}
                <u>
                  <strong>Modification of Licensed Technology</strong>
                </u>
                . Licensee represents and warrants that Licensee will not (a) alter, maintain,
                enhance, modify, disassemble, decompile, reverse engineer, distribute, make copies,
                or otherwise affect the Licensed Technology, (b) attempt in any other manner to
                obtain the source code, and (c) use or integrate the Licensed Technology (including
                communication protocols) with any software or hardware not authorized herein.
              </p>
              <p align="JUSTIFY">
                2.4{' '}
                <u>
                  <strong>Consent to Use of Data</strong>
                </u>
                . Licensee agrees that Licensor and its Affiliates may collect feedback, ratings,
                and use technical information Licensee provides in relation to support services
                related to the Licensed Technology. Licensor agrees not to use this information in a
                form that personally identifies Licensee’s end-users except to the extent necessary
                to provide such services.
              </p>
              <p align="JUSTIFY">
                2.5{' '}
                <u>
                  <strong>Licensor’s Exclusive Ownership of Metadata</strong>
                </u>
                . Notwithstanding Section 7.18, Licensee agrees that Licensor and its Affiliates
                shall own without encumbrance any and all Metadata. The Metadata shall be the
                exclusive property of Licensor and shall remain the property of Licensor upon the
                termination or expiration of this Agreement.
              </p>
              <p align="CENTER">
                <h6>III. FINANCIAL PROVISIONS</h6>
              </p>
              <p align="JUSTIFY">
                3.1{' '}
                <u>
                  <strong>Fees</strong>
                </u>
                . Throughout the term of this Agreement, Licensee shall pay to Licensor a fee (the
                “Fee”) calculated each month in an amount equal to the sum of the following:
              </p>
              <p align="JUSTIFY">
                (a) The number of the Licensee’s employees, counselors or affiliates who utilize the
                Platform, its modules and App during such month multiplied by the License Fee ; plus
              </p>
              <p align="JUSTIFY">
                (b) An amount representing the cost incurred by the Licensor in maintaining the
                Platform and App attributable to use by the Licensee’s customers, as reasonably
                determined by the Licensor, during such month (the “Maintenance Fee”), if any; plus
              </p>
              <p align="JUSTIFY">
                (c) An amount attributable to the cost incurred by the Licensor in providing the
                Licensee’s customers use of the hardware needed to access the Platform and App, as
                reasonably determined by the Licensor, during such month (the “Hardware Fee”).
              </p>
              <p align="JUSTIFY">
                (d) Licensor shall publish its fees in the administration account area of the
                platform and reserves the right to modify its pricing from time-to-time without
                prior notice.
              </p>
              <p align="JUSTIFY">
                The Fee shall be paid by Licensee to Licensor by credit card or ACH prior to the
                last day of the first calendar month following the end of such month. Interest shall
                accrue, and Licensee agrees to pay interest, at the rate of one and one-half percent
                (1.5%) per month on all overdue accounts where the applicable law permits. Licensee
                shall be responsible for all Licensor’s reasonable costs of collection including
                attorney’s fees.
              </p>
              <p align="JUSTIFY">
                3.2{' '}
                <u>
                  <strong>Revenue Sharing</strong>
                </u>
                . Throughout the term of this Agreement, Licensor shall provide Licensee each month
                seventy-two and one half (72.5%) of the sharable revenue it collects from App users
                paying the Licensor for use of the App. The Licensor shall provide the Licensee its
                share of the monthly revenue prior to last day of the first calendar month following
                the end of such month.
              </p>
              <p align="JUSTIFY">
                3.3{' '}
                <sub>
                  <u>
                    <strong>Quarterly Reports</strong>
                  </u>
                </sub>
                <sub>. </sub>On the last day of the first calendar month following the end of each
                calendar quarter, Licensee shall deliver to Licensor a quarterly statement setting
                forth (i) the number of the Licensee’s customers who utilized the Platform and App
                during the immediately preceding calendar quarter and (ii) such other information as
                Licensor reasonably requests.
              </p>
              <p align="JUSTIFY">
                3.4{' '}
                <u>
                  <strong>Right to Audit</strong>
                </u>
                . Licensee agrees to make and maintain books and records in sufficient detail to
                enable Licensor to determine the accuracy of each Fee payment. Licensor shall have
                the right to appoint an independent accounting firm, reasonably acceptable to
                Licensee (“Independent Auditor”), to make a special audit of the books and records
                of Licensee that pertain to such determination. Upon two (2) weeks prior written
                notice to Licensee, the Independent Auditor shall have access to those books and
                records of Licensee that are reasonably necessary or appropriate to confirm the
                amounts of the Fee that are due to Licensor under this Agreement. The Independent
                Auditor shall have such access during normal business hours and Licensee shall cause
                the personnel of Licensee responsible for such books and records to cooperate with
                the Independent Auditor in such audit.
              </p>
              <p align="JUSTIFY">
                3.5{' '}
                <u>
                  <strong>Responsibility for Taxes</strong>
                </u>
                . Licensor is responsible for payment of any and all taxes levied on the Fee (other
                than taxes based on Licensee’s net income, including franchise and margin taxes). If
                applicable law requires that taxes be deducted and withheld from a payment made
                pursuant to this Article III, Licensee shall (a) deduct those taxes from the
                payment, (b) pay the taxes to the proper taxing authority, and (c) send evidence of
                the obligation together with proof of payment to Licensor within forty-five (45)
                days following that payment. In such a case, Licensee will promptly provide Licensor
                with original receipts or other evidence reasonably desirable and sufficient to
                allow Licensor to document such tax withholdings adequately for purposes of claiming
                foreign tax credits and similar benefits.
              </p>
              <p align="JUSTIFY">
                3.6 <strong>Clawback Policy</strong>. The Licensee acknowledges and agrees that any
                funds payable under this Agreement are subject to the clawback policy of Sober Peer
                currently in effect or adopted in the future. This policy provides for the recovery
                of funds (a) erroneously awarded; (b) related to the material noncompliance with any
                service level requirements; or, (c) connected to client complaints that Sober Peer
                deems, in its sole discretion, legitimate. The Licensee agrees to allow Sober Peer,
                at its sole discretion, to recover such funds via the bank account, credit card or
                other methods used for making payments to the Provider or as an offset against
                future revenue sharing payments.
              </p>
              <p align="JUSTIFY">
                <br />
              </p>
              <p align="CENTER">
                <h6>IV. REPRESENTATIONS AND WARRANTIES</h6>
              </p>
              <p align="JUSTIFY">
                4.1{' '}
                <u>
                  <strong>Representations and Warranties by Licensor</strong>
                </u>
                . Licensor represents and warrants to Licensee that:
              </p>
              <p align="JUSTIFY">
                (a) it has all rights and authority required to grant the License under this
                Agreement and to enter into this Agreement;
              </p>
              <p align="JUSTIFY">
                (b) none of the Licensed Technology infringes or violates, or constitutes a
                misappropriation of, any intellectual property rights of any Third Party; and
              </p>
              <p align="JUSTIFY">
                (c) the Licensed Technology is not subject to any order, action, suit, claim,
                proceeding, settlement or “march in” right that restricts, or could reasonably be
                expected to restrict, in any manner the License.
              </p>
              <p align="JUSTIFY">
                4.2{' '}
                <u>
                  <strong>Representations and Warranties by Licensee</strong>
                </u>
                . Licensee represents and warrants to Licensor that:
              </p>
              <p align="JUSTIFY">
                (a) Licensee has the rights and authority required to enter into this Agreement;
              </p>
              <p align="JUSTIFY">
                (b) Licensee shall comply with all applicable laws in the exercise of its rights and
                performance of its obligations under this Agreement; fully disclose its permissible
                purpose to offer its services to the public and post, and keep current from
                time-to-time its credentials to its users or consumers, and
              </p>
              <p align="JUSTIFY">
                (c) Licensee shall not make any representations or warranties to any Third Party
                regarding the Licensed Technology other than those that have been approved in
                writing by Licensor.
              </p>
              <p align="JUSTIFY">
                4.3{' '}
                <u>
                  <strong>Succession</strong>
                </u>
                . If Licensor divests itself of any of the Licensed Technology or of any interest
                therein (whether by sale, merger, assignment, transfer, license, reorganization,
                spin-off, financing, contract, operation of law, court order, or any other means
                whatsoever, but excluding license agreements comparable to this Agreement), Licensor
                will first (a) put the transferee, licensee, assignee, or acquiror (the
                “Transferee”) thereof on written notice of license grants granted to Licensee in
                connection with this Agreement, and (b) cause the Transferee, to the extent
                necessary if at all, to agree to be bound by the grant of rights contained in this
                Agreement.
              </p>
              <p align="JUSTIFY">
                4.4{' '}
                <u>
                  <strong>Limitations</strong>
                </u>
                . OTHER THAN AS EXPRESSLY PROVIDED FOR IN THIS AGREEMENT AND WITHOUT PREJUDICE TO
                THE OBLIGATIONS SET FORTH IN THIS AGREEMENT, NO PARTY MAKES ANY WARRANTIES OR
                REPRESENTATIONS AS TO PERFORMANCE, QUALITY OR SUITABILITY OF ANY TANGIBLES OR
                INTANGIBLES INCLUDED WITH THE LICENSED TECHNOLOGY LICENSED UNDER THIS AGREEMENT,
                WHICH ARE PROVIDED AND ACCEPTED “AS IS.” TO THE EXTENT PERMITTED BY APPLICABLE LAW,
                ALL IMPLIED WARRANTIES INCLUDING THOSE OF DESIGN, MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT, AND WARRANTIES ARISING FROM A COURSE OF
                DEALING, USAGE, OR TRADE PRACTICE, ARE DISCLAIMED. NO PARTY WARRANTS OR REPRESENTS
                THAT ANY TANGIBLES OR INTANGIBLES INCLUDED WITH THE LICENSED TECHNOLOGY LICENSED
                HEREUNDER WILL BE ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION.
              </p>
              <p align="CENTER">
                <h6>V. INTELLECTUAL PROPERTY RIGHTS</h6>
              </p>
              <p align="JUSTIFY">
                5.1{' '}
                <u>
                  <strong>Ownership</strong>
                </u>
                . Licensee acknowledges that, notwithstanding any provisions of this Agreement to
                the contrary, Licensor is and will remain the owner of all of the Licensed
                Technology and has reserved full rights therein as such owner, subject to the rights
                granted to Licensee under the License. Licensee shall not knowingly take or fail to
                take any action that could reasonably be expected to (a) impair any right, title, or
                interest of Licensor in and to all or any portion of the Licensed Technology; or (b)
                create any right, title, or interest in or to the Licensed Technology in either
                Licensee or any other Person. Licensee acknowledges Licensor’s proprietary rights,
                and waives in favor of Licensor any right Licensee or any other Person may have in
                and to the Licensed Technology.
              </p>
              <p align="JUSTIFY">
                5.2{' '}
                <u>
                  <strong>Enforcement Right</strong>
                </u>
                . Licensor has and retains the right to enforce its rights with respect to the
                Licensed Technology directly against Licensee.
              </p>
              <p align="JUSTIFY">
                5.3{' '}
                <u>
                  <strong>Improvements/New Intangibles</strong>
                </u>
                . Licensee agrees that it shall, automatically, upon Licensee’s creation of any
                Improvements to the Licensed Technology (including, but not limited to patents,
                copyrights, and trademarks), transfer the Improvements to the Licensed Technology to
                Licensor. Licensee irrevocably and automatically expressly assigns all right, title
                and interest it may otherwise have in the Improvements to the Licensed Technology to
                Licensor, and if the foregoing cannot be so assigned, Licensee unconditionally and
                irrevocably (a) waives the enforcement of such rights, and (b) grants to Licensor an
                exclusive, irrevocable, perpetual, royalty-free, worldwide license to reproduce,
                create derivative works of, modify, license, distribute, publicly perform and
                publicly display the Improvements to the Licensed Technology by all means now known
                or later developed. Licensee shall cooperate fully to give legal effect to the
                assignment and/or waiver and grant of rights described herein. Licensee further
                agrees to give Licensor prompt written notice of the creation of the Improvements
                and such other written information as Licensor may require to record and/or file
                these items, and Licensee agrees to keep a record of any and all Improvements to the
                Licensed Technology developed by it, and to make such record available to Licensor
                at any time upon request by Licensor. In consideration for the assignment granted in
                this Section 5.3 and the Fee paid by Licensee, Licensor agrees to grant Licensee a
                license to use any Improvements to the Licensed Technology, all in accordance with
                the Permitted Use. If this Agreement terminates, such rights and license to the
                Improvements to the Licensed Technology also terminates.
              </p>
              <p align="JUSTIFY">
                <strong> </strong>
                5.4<strong> </strong>{' '}
                <u>
                  <strong>Protection of Licensed Technology</strong>
                </u>
                .
              </p>
              <p align="JUSTIFY">
                (a) Each Party shall cooperate with the other in protecting, securing, registering,
                enforcing, and maintaining the ownership rights of Licensor in and to the Licensed
                Technology. Licensee shall take all actions that Licensor may reasonably request, in
                order to protect and enforce Licensor’s rights in and to the Licensed Technology.
                Licensee shall promptly notify Licensor in writing of any patent infringement,
                misappropriation, or any other unauthorized use of the Licensed Technology that it
                becomes aware of.
              </p>
              <p align="JUSTIFY">
                (b) Licensee shall enforce all rights of, and defend against any infringement or
                alleged infringement of, the Licensed Technology and shall at all times keep
                Licensor informed as to the status thereof. Licensee shall, at its own expense,
                institute suit against any such infringer or alleged infringer and control, settle,
                enforce, pursue, and defend such suit in a manner consistent with the terms of this
                Agreement. Any recovery, judgment, damages, awards or settlements resulting from
                such legal proceedings shall be paid over to Licensor; provided, however, Licensee
                may deduct and retain an amount equal to all of its direct costs and expenses
                associated with such proceeding, including reasonable legal fees. This right to sue
                for infringement shall not be used in an arbitrary or capricious manner. Licensor
                shall reasonably cooperate in any such litigation at Licensee’s expense.
              </p>
              <p align="JUSTIFY">
                (c) If Licensee fails to stop or to initiate legal proceedings to stop any Third
                Party infringement or alleged infringement on any portion of the Licensed Technology
                within six (6) months of notice thereof, then Licensor shall have the right to
                initiate legal proceedings against such alleged infringers, to employ and engage
                qualified counsel to handle the matter, and to pursue, compromise, or settle the
                matter. Licensee shall cooperate fully with Licensor in any legal action taken by
                Licensor against such Third Parties. Licensee shall pay all expenses of such action,
                and shall promptly reimburse Licensor for all costs and expenses it incurs in such
                matter. Any recovery, judgment, damages, awards or settlements resulting from such
                legal proceedings shall be paid to Licensor; provided, however, Licensor shall pay
                to Licensee any direct costs and expenses associated with such proceeding of
                Licensor that were paid by Licensee.
              </p>
              <p align="JUSTIFY">
                5.4{' '}
                <u>
                  <strong>Section 365(n) of the Bankruptcy Code</strong>
                </u>
                . All rights and licenses granted under or pursuant to any section of this Agreement
                are, and shall otherwise be, deemed to be, for purposes of Section 365(n) of the
                U.S. Bankruptcy Code, as amended, licenses of rights to “intellectual property” as
                defined under Section 101(35A) of the Bankruptcy Code, and to the maximum extent,
                entitle Licensee as licensee under this Agreement to all of the protections provided
                therein, and under any similar laws or regulations in other jurisdictions intended
                to preserve the rights of licensees of intellectual property.
              </p>
              <p align="JUSTIFY">
                5.5{' '}
                <u>
                  <strong>Registration</strong>
                </u>
                . Licensee shall have the right, but not the obligation, to register this Agreement
                pursuant to applicable law or regulation in such jurisdictions as Licensee may
                determine necessary or useful, and Licensor shall cooperate, at Licensee’s expense,
                with any reasonable requests of Licensee in connection therewith.
              </p>
              <p align="JUSTIFY">
                5.6{' '}
                <u>
                  <strong>Product Liability</strong>
                </u>
                <strong>.</strong> Prior to the first commercial sale of any Product, Licensee shall
                establish and maintain, in each jurisdiction in which Licensee, an Affiliate shall
                test or Transfer such Product, product liability or other appropriate insurance
                coverage appropriate to the risks involved in marketing such Product and will
                annually present evidence to Licensor that such coverage is being maintained.
                Licensor shall be listed as an additional insured in such insurance policies. Upon
                Licensor’s request, Licensee will furnish Licensor with a Certificate of Insurance
                of each product liability insurance policy obtained. If such product liability
                insurance is underwritten on a ‘claims made’ basis, Licensee agrees that any change
                in underwriters during the term of this Agreement will require the purchase of
                ‘prior acts’ coverage to ensure that coverage will be continuous throughout the term
                of this Agreement.
              </p>
              <p align="JUSTIFY">
                5.7{' '}
                <u>
                  <strong>Recognition</strong>
                </u>
                . Each Product shall clearly identify in legible words that such Product utilizes
                the Licensed Technology of Licensor. The Licensor shall have the right to approve
                the manner, content, wording, coloring, presentation, and all other aspects of how
                the Licensed Technology and the Licensor are identified on the Product.
              </p>
              <p align="CENTER">
                <h6>VI. TERM</h6>
              </p>
              <p align="JUSTIFY">
                6.1{' '}
                <u>
                  <strong>Term</strong>
                </u>
                . The term of this Agreement shall commence on the Effective Date and continue until
                terminated in accordance with Section 6.2.
              </p>
              <p align="JUSTIFY">
                6.2{' '}
                <u>
                  <strong>Licensor’s Right of Termination</strong>
                </u>
                .
              </p>
              <p align="JUSTIFY">(a) If Licensee</p>
              <p align="JUSTIFY">
                (i) fails to make any payment of the Fee as required under this Agreement, and
                Licensee fails to cure such default within thirty (30) days following receipt of
                written notice from Licensor;
              </p>
              <p align="JUSTIFY">
                (ii) is in material breach of this Agreement (other than with regard to the payment
                of Fee), including without limitation providing services that would require a
                business license from a state or federal government, and Licensee fails to cure such
                material breach within sixty (60) days following receipt of written notice from
                Licensor;
              </p>
              <p align="JUSTIFY">
                (iii) is the subject of a proceeding for reorganization or liquidation of Licensee,
                whether voluntarily or involuntarily, under the United States Bankruptcy Code or
                similar state law, or a receiver is appointed for the assets of Licensee, or there
                is an assignment made for the benefit of creditors of Licensee, or any similar
                proceedings are initiated for the benefit of Licensee’s creditors;
              </p>
              <p align="JUSTIFY">(iv) dissolves, liquidates or terminates; or</p>
              <p align="JUSTIFY">(v) is subject to a Change in Control; then</p>
              <p align="JUSTIFY">
                Licensor may (in its sole discretion, but without obligation to do so) immediately
                terminate the License and this Agreement by delivery of written notice to Licensee
                of such termination.
              </p>
              <p align="JUSTIFY">
                6.3{' '}
                <u>
                  <strong>Effect of Termination</strong>
                </u>
                . If this Agreement is terminated, then the License, and the rights of Licensee to
                use and exploit the Licensed Technology, are also terminated. Upon the termination
                of this Agreement, Licensee shall cease using the Licensed Technology for any
                purpose. At such time, Licensee agrees to return to Licensor all of the tangible
                Licensed Technology, all copies of tangible embodiments of the Licensed Technology
                and all other Confidential Information to the extent they are in the possession of
                Licensee or its Affiliates, to Licensor, to permanently erase Licensor’s
                Confidential Information and all Licensed Intellectual Property from its computer
                systems, and to take such reasonable steps requested by Licensor to prevent the
                further unauthorized use or disclosure of such documents and materials. Upon the
                termination or cancellation of the License and this Agreement, Licensee agrees to
                pay Licensor all amounts due and owing to Licensor as accrued through the effective
                date of termination or cancellation. Termination shall in no event affect Licensee’s
                obligation to pay or Licensor’s right to receive any Fee. This Section 6.3 and
                Sections 3.1, 3.3, 5.1, 5.3, 7.1, 7.8, 7.12, 7.15, and 7.16 shall survive the
                termination or cancellation of this Agreement.
              </p>
              <p align="CENTER">
                <h6>VII. MISCELLANEOUS</h6>
              </p>
              <p align="JUSTIFY">
                7.1{' '}
                <u>
                  <strong>Press Releases and Announcements</strong>
                </u>
                . Neither Party shall issue any press release or public announcement relating to the
                subject matter of this Agreement without the prior written approval of the other
                Party; provided, however, that either Party may make any public disclosure it
                believes in good faith is required by applicable law, regulation or stock market
                rule (in which case the disclosing Party shall use reasonable efforts to advise the
                other Party and provide it with a copy of the proposed disclosure prior to making
                the disclosure).
              </p>
              <p align="JUSTIFY">
                7.2{' '}
                <u>
                  <strong>No Third Party Beneficiaries</strong>
                </u>
                . This Agreement shall not confer any rights or remedies upon any Person other than
                the Parties and their respective successors and permitted assigns.
              </p>
              <p align="JUSTIFY">
                7.3{' '}
                <u>
                  <strong>Entire Agreement</strong>
                </u>
                . This Agreement constitutes the entire agreement between the Parties and supersedes
                any prior understandings, agreements or representations by or between the Parties or
                their Affiliates, written or oral, with respect to the subject matter hereof.
              </p>
              <p align="JUSTIFY">
                7.4{' '}
                <u>
                  <strong>Assignment</strong>
                </u>
                . Except as expressly permitted in this Agreement, this Agreement may not be
                assigned or transferred by either Party, in whole or in part, without the prior
                written consent of the other Party. Notwithstanding the foregoing, Licensor may
                assign or transfer (by operation of law or otherwise) this Agreement and its rights
                and licenses in this Agreement without Licensee’s consent provided that, the
                assignee or transferee assumes all rights and obligations of Licensor under this
                Agreement as a condition of any such assignment or transfer. This Agreement shall be
                binding upon and inure to the benefit of the Parties and their respective
                successors, permitted transferees, and permitted assigns. For clarity, the terms and
                conditions of this Agreement shall survive and remain in full force and effect
                notwithstanding any initial public offering of stock or follow-on offering of stock
                of Licensee or any of its Affiliates.
              </p>
              <p align="JUSTIFY">
                7.5{' '}
                <u>
                  <strong>Counterparts and Facsimile Signature</strong>
                </u>
                . This Agreement may be executed in two or more counterparts, each of which shall be
                deemed an original but all of which together shall constitute one and the same
                instrument. This Agreement may be executed by facsimile signature.
              </p>
              <p align="JUSTIFY">
                7.6{' '}
                <u>
                  <strong>Headings</strong>
                </u>
                . The section headings contained in this Agreement are inserted for convenience only
                and shall not affect in any way the meaning or interpretation of this Agreement.
              </p>
              <p align="JUSTIFY">
                7.7{' '}
                <u>
                  <strong>Notices</strong>
                </u>
                . All notices, requests, demands, claims, and other communications in this Agreement
                shall be in writing. Any notice, request, demand, claim or other communication in
                this Agreement shall be deemed duly delivered or given (a) one business day after it
                is sent within either the United States by commercial one-day overnight courier
                service, or (b) two business days after it is sent internationally by commercial
                two-day international overnight courier service, in each case to the intended
                recipient as set forth on the signature pages to this Agreement. Either Party may
                give any notice, request, demand, claim, or other communication in this Agreement
                using any other means (including personal delivery, expedited courier, messenger
                service, telecopy, facsimile, ordinary mail, or electronic mail), but no such
                notice, request, demand, claim, or other communication shall be deemed to have been
                duly given unless and until it actually is received by the Party for whom it is
                intended. Either Party may change the address to which notices, requests, demands,
                claims, and other communications in this Agreement are to be delivered by giving the
                other Party notice in the manner herein set forth.
              </p>
              <p align="JUSTIFY">
                7.8{' '}
                <u>
                  <strong>Governing Law</strong>
                </u>
                . This Agreement shall be governed by and construed in accordance with the internal
                laws of the State of Texas, without giving effect to any choice or conflict of law
                provision or rule (whether of the State of Texas or any other jurisdiction) that
                would cause the application of laws of any jurisdictions other than those of the
                State of Texas.
              </p>
              <p align="JUSTIFY">
                7.9{' '}
                <u>
                  <strong>Amendments and Waivers</strong>
                </u>
                . No amendment of any provision of this Agreement shall be valid unless the same
                shall be in writing and signed by each of the Parties. No waiver by either Party of
                any right or remedy in this Agreement shall be valid unless the same shall be in
                writing and signed by the Party giving such waiver. No waiver by either Party with
                respect to any default, misrepresentation, or breach or nonperformance of or
                noncompliance with any warranty, covenant or agreement in this Agreement shall be
                deemed to extend to any prior or subsequent default, misrepresentation, or breach or
                nonperformance of or noncompliance with any warranty, covenant or agreement in this
                Agreement or affect in any way any rights arising by virtue of any prior or
                subsequent such occurrence.
              </p>
              <p align="JUSTIFY">
                7.10{' '}
                <u>
                  <strong>Severability</strong>
                </u>
                . Any term or provision of this Agreement that is invalid or unenforceable in any
                situation in any jurisdiction shall not affect the validity or enforceability of the
                remaining terms and provisions hereof or the validity or enforceability of the
                offending term or provision in any other situation or in any other jurisdiction. If
                the final judgment of a court of competent jurisdiction declares that any term or
                provision hereof is invalid or unenforceable, the Parties agree that the court
                making the determination of invalidity or unenforceability shall have the power to
                limit the term or provision, to delete specific words or phrases, or to replace any
                invalid or unenforceable term or provision with a term or provision that is valid
                and enforceable and that comes closest to expressing the intention of the invalid or
                unenforceable term or provision, and this Agreement shall be enforceable as so
                modified.
              </p>
              <p align="JUSTIFY">
                7.11{' '}
                <u>
                  <strong>Expenses</strong>
                </u>
                . Except as otherwise expressly set forth in this Agreement, each Party shall bear
                its own costs and expenses (including legal fees and expenses) incurred in
                connection with this Agreement. However, if there is a dispute between the Parties
                with respect to this Agreement, or matters governed by this Agreement, then the
                substantially prevailing Party in such dispute will be entitled to recover all
                amounts incurred in such dispute, including legal fees and expenses, from the other
                Party.
              </p>
              <p align="JUSTIFY">
                7.12{' '}
                <sub>
                  <u>
                    <strong>Dispute Resolution</strong>
                  </u>
                </sub>
                <sub>.</sub>
              </p>
              <p align="JUSTIFY">
                (a) Any dispute, controversy or claim arising out of, in connection with or relating
                to this Agreement including any question regarding its formation, existence,
                validity, enforceability, performance, interpretation, breach or termination, shall
                first be referred to the senior executives (of at least the Senior Vice President
                level) of each Party for resolution. If such senior executives are unable to resolve
                the matter within thirty (30) days after referral in writing by either Party, either
                Party may unilaterally submit such dispute, controversy or claim for final
                resolution through binding arbitration pursuant to Section 7.12(b).
              </p>
              <p align="JUSTIFY">
                (b) Any controversy or claim arising out of or in any way connected with this
                Agreement including any question regarding its formation, existence, validity,
                enforceability, performance, interpretation, breach or termination, shall be
                resolved in accordance with the Commercial Arbitration Rules of the American
                Arbitration Association then in effect in Dallas, Texas and shall be held in the
                Dallas, Texas area. Judgment upon the award rendered by the arbitrator(s) may be
                entered in any court having jurisdiction thereof. The number of arbitrators shall be
                three. Each Party shall nominate one arbitrator. The two arbitrators so nominated
                and appointed shall nominate the third arbitrator, who shall be the chair of the
                tribunal. Nothing in this provision shall prevent any Party from seeking
                conservatory or interim measures, including, but not limited to temporary
                restraining orders or preliminary injunctions, or their equivalent, from any court
                of competent jurisdiction before the arbitral tribunal is constituted or,
                thereafter, upon the order of the arbitral tribunal.
              </p>
              <p align="JUSTIFY">
                7.13{' '}
                <u>
                  <strong>Relationship of the Parties</strong>
                </u>
                . The Parties intend that the relationship between them under this Agreement shall
                be solely that between licensor and licensee. Nothing in this Agreement shall be
                construed (a) to create a partnership or joint venture, (b) to constitute either
                Party an agent or legal representative of the other Party, or (c) to create any
                fiduciary relationship between the Parties. This Agreement does not grant any Party
                any right or authority to assume or create any obligation or responsibility on
                behalf of any other Party, and no Party shall be in any way responsible for the
                debts of any other Party incurred under or pursuant to the terms of this Agreement.
              </p>
              <p align="JUSTIFY">
                7.14{' '}
                <u>
                  <strong>Equitable Remedies</strong>
                </u>
                . The Parties acknowledge that damages at law will be an inadequate remedy to
                Licensor. In addition to damages at law and other rights or remedies (including,
                without limitation, remedies at law) which may be available, Licensor shall have the
                right of specific performance, injunction or other equitable remedy (including,
                without limitation, the right to such equitable remedies prior to or pending
                arbitration) in the event of a breach or threatened breach of this Agreement by
                Licensee without obligation to post bond.
              </p>
              <p align="JUSTIFY">
                7.15{' '}
                <u>
                  <strong>Confidentiality</strong>
                </u>
                . Licensee shall keep confidential, and shall cause each of its employees,
                contractors, subcontractors, agents and representatives to keep confidential: (a)
                any and all information related to the Licensed Technology (including without
                limitation the business methods, processes, know-how and technology related
                thereto), and (b) any information provided under the terms of this Agreement
                (collectively, the “Confidential Information”), using the same degree of care
                Licensee uses to protect its own confidential information of like importance,
                provided such degree of care is no less than a reasonable degree of care. Licensee
                shall take all reasonable security measures to protect the privacy of the
                Confidential Information, and Licensee shall not disclose the Confidential
                Information, except to employees and subcontractors or agents who have agreed to
                abide by confidentiality terms at least as strict as those set forth herein or as
                authorized by Licensor in writing. Licensee shall not duplicate, use or disclose the
                Confidential Information except as otherwise permitted by this Agreement. Licensee
                shall not make the Confidential Information available for public view.
              </p>
              <p align="JUSTIFY">
                7.16{' '}
                <u>
                  <strong>Indemnification</strong>
                </u>
                .
              </p>
              <p align="JUSTIFY">
                (a) Licensor may elect to indemnify and defend Licensee from all claims, costs,
                damages, losses, liabilities and expenses (including attorneys’ fees and costs)
                finally awarded against Licensee by a court of competent jursidiction arising out of
                or in connection with a claim by a Third Party alleging that the Licensed Technology
                infringes his or her intellectual property rights. Licensor shall have full
                authority, at its option and expense, to: (a) replace or modify the Licensed
                Technology to be non-infringing; (b) obtain a license for Licensee to continue using
                the Licensed Technology in accordance with the Permitted Use; or (c) terminate this
                Agreement. Even if Licensor initially elects to indemnify Licensee, Licensee
                understands that Licensor may at any time refuse to provide indemnification
                protection, particularly if Licensee breaches the terms of this Agreement. If
                Licensor does not respond to a reasonable request by Licensee, Licensee may
                institute a suit or take action on account of such infringement to protect its
                continued use of the Licensed Technology.
              </p>
              <p align="JUSTIFY">
                (b) Licensee shall defend and hold Licensor, its managers, managing members,
                officers, employees, owners, and agents (“Licensor Parties”) harmless as against any
                judgments, fees, expenses, or other costs arising from or incidental to any product
                liability or other lawsuit, claim, demand or other action arising from, attributable
                to or associated with, the Licensed Technology, or a product or service utilizing
                all or any portion of the Licensed Technology whether or not a Licensor Party is
                named as a party defendant in any such lawsuit. The obligation of Licensee to defend
                and indemnify as set out in this Section shall survive the termination of this
                Agreement.
              </p>
              <p align="JUSTIFY">
                7.17{' '}
                <u>
                  <strong>Limitations of Liability</strong>
                </u>
                . IN NO EVENT WILL EITHER PARTY BE LIABLE UNDER THIS AGREEMENT FOR ANY
                CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES WHETHER
                ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE,
                REGARDLESS OF WHETHER SUCH DAMAGE WAS FORESEEABLE AND WHETHER EITHER PARTY HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO CASE WILL LICENSOR’S LIABILITY OF
                ANY KIND EXCEED THE TOTAL OF THE FEES WHICH HAVE ACTUALLY BEEN PAID TO LICENSOR BY
                LICENSEE AS OF THE DATE OF FILING OF ANY ACTION BY LICENSEE.
              </p>
              <p align="JUSTIFY">
                7.18{' '}
                <u>
                  <strong>Patient Data; HIPAA Compliance</strong>
                </u>
                . Licensor shall have access to patient data for patients who receive professional
                medical services from Licensee. Licensor and Licensee shall comply with all state
                and federal laws and regulations regarding the confidentiality and disclosure of
                protected patient information (as to which Licensor and Licensee agree that patient
                records shall remain the property of Licensee at all times), including, but not
                limited to, the Health Insurance Portability and Accountability Act of 1998
                (“HIPAA”), the Standards for Privacy of Individually Identifiable Health
                Information, 45 C.F.R. Parts 160 and 164 (the “Privacy Regulations”), promulgated
                thereunder, and Security Standards, 45 C.F.R. Parts 160, 162 and 164 (the “Security
                Regulations”) promulgated thereunder and the privacy and security provisions of the
                Health Information Technology for Economic and Clinical Health Act provisions of the
                American Recovery and Reinvestment Act (“HITECH”). Licensor shall not have liability
                or responsibility for any physician’s or Licensee employee’s failure to comply with
                HIPAA, the Privacy Regulations, the Security Regulations, or HITECH. Licensor hereby
                agrees to enter into a Business Associate Agreement in the form attached to this
                Agreement as <u>Exhibit A</u>, and to take such other action, as may be necessary to
                comply or to come into compliance with HIPAA, the Privacy and Security Regulations
                promulgated thereunder, and HITECH, as amended. Notwithstanding the foregoing and
                except as provided in Section 2.5, any and all health, medical or billing data
                retained by Licensor during the term of this Agreement shall be the exclusive
                property of Licensee and shall remain the property of Licensee upon the termination
                or expiration of this Agreement.
              </p>
              <p align="JUSTIFY">
                7.19{' '}
                <u>
                  <strong>Construction</strong>
                </u>
                . For purposes of this Agreement:
              </p>
              <p align="JUSTIFY">
                (a) The language used in this Agreement shall be deemed to be the language chosen by
                the Parties to express their mutual intent, and no rule of strict construction shall
                be applied against any Party. Any reference to any federal, state, local, or foreign
                statute or law shall be deemed also to refer to all rules and regulations
                promulgated thereunder, unless the context requires otherwise.
              </p>
              <p align="JUSTIFY">
                (b) References to Articles, Sections, and Exhibits are to Articles, Sections, and
                Exhibits of this Agreement unless stated otherwise. Headings of subdivisions
                (including Articles, Sections, Subsections, paragraphs, and Exhibits) used in this
                Agreement are for convenience of reference only and shall not be used in construing
                or interpreting this Agreement.
              </p>
              <p align="JUSTIFY">
                (c) References to any agreement, schedule or other instrument shall, unless the
                context otherwise requires (or the definition thereof otherwise specifies), be
                deemed references to the same as it may from time to time be amended, modified or
                extended.
              </p>
              <p align="JUSTIFY">
                (d) Technical words and phrases not otherwise defined in this Agreement shall have
                the meaning generally assigned to them in the semiconductor foundry industry.
              </p>
              <p align="JUSTIFY">
                The Parties have caused this Agreement to be executed in their names by their
                properly and duly authorized officers or representatives as of the date of
                enrollment and indication of agreement by electronic signature for all purposes as
                of the Effective Date.
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-12 mt-2">
              <div class="form-group text-center">
                <Checkbox
                  checked={this.state.checked}
                  onChange={e => {
                    this.setState({
                      checked: e.target.checked,
                    });
                  }}
                  style={{ marginRight: '8px' }}
                />
                <span>I accept the terms in the License Agreement.</span>
              </div>
            </div>
          </div>
          <div class="justify-content-center border-top-1 mt-2 text-center">
            {this.state.checked ? (
              <button
                type="button"
                class="btn black-btn font-weight-bold mr-2"
                onClick={() => {
                  onLicenseModalCancel(true);
                }}
              >
                Accept
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                onClick={() => {
                  onLicenseModalCancel(false);
                }}
              >
                Reject
              </button>
            )}
          </div>
        </div>
        {/* <div class="modal-footer justify-content-center border-top-0">
          <button
            disabled={!this.state.checked}
            type="button"
            class="btn black-btn font-weight-bold mr-2"
            onClick={onLicenseModalCancel}
          >
            Accept
          </button>
        </div> */}
      </div>
    );
  }
}
export default LicenseModal;
