import React from 'react';

const FlaggedStatus = ({ FlaggedStatus }) => {
  const { QualifiedCount, FlaggedCount, IsQualified } = FlaggedStatus || {};

  return (
    <div class="prospect-banner d-flex align-items-center justify-content-end">
      {/* <div className="qualifiers">
        <img src="images/icons/tick-green.svg" />
        <span className="text">{`Qualifiers-${QualifiedCount || 0}`}</span>
      </div> */}
      <div className="flagged">
        <img src="images/icons/red-flag.svg" />
        <span className="text">{`Flagged-${FlaggedCount || 0}`}</span>
      </div>
      {/* <div className="screening-status-block">
        {IsQualified ?
        //   (
        //   <>
        //     <div className="status-qualifier">
        //       <span className="mr-1">Status: </span>
        //       <img src="images/icons/tick-green.svg" />
        //       <span className="text">Qualifier</span>
        //     </div>
        //   </>
        // )
          null
          : (
          <>
            <div className="status-flagged">
              <span className="mr-1">Status: </span>
              <img src="images/icons/red-flag.svg" />
              <span className="text">Flagged</span>
            </div>
          </>
        )}
      </div> */}
      {/* <span className="change-qualified">Change to Qualified</span> */}
    </div>
  );
};
export default FlaggedStatus;
