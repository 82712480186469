import React, { useEffect, useState, useRef } from 'react';

const UserQuery = ({ QueryData, QueryBookmark, Index }) => {
  return (
    <div className="youu-chat-messages" style={{ width: '55%' }}>
      {QueryData.ChatId && (
        <div
          className="mx-2 bookmark"
          onClick={() => {
            QueryBookmark(QueryData, Index);
          }}
        >
          <img
            src={`images/icons/${
              QueryData.IsQueryBookmarked ? 'chat-bookmarked' : 'chat-bookmark'
            }.svg`}
          />
        </div>
      )}
      <div className="messages">
        <span>{QueryData.UserQuery}</span>
        <div className="messages-actions">
          <div className="events"></div>
          <span className="chat-time">{QueryData.UserQueryTime}</span>
        </div>
      </div>
    </div>
  );
};

export default UserQuery;
