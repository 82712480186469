import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getStudentDocumentList(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentDocumentList(payload);
    yield put({
      type: ActionTypes.GET_DOCUMENT_LIST_SUCCESS,
      payload: JSON.parse(response.data.data).StudentDocument[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_DOCUMENT_LIST_FAILURE,
    });
  }
}

export function* archiveStudentDocument(action) {
  try {
    const { payload } = action;
    const response = yield API.archiveStudentDocument(payload);
    yield put({
      type: ActionTypes.ARCHIVE_STUDENT_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ARCHIVE_STUDENT_DOCUMENT_FAILURE,
    });
  }
}
export function* uploadDocument(action) {
  try {
    const { payload } = action;
    const response = yield API.uploadDocument(payload);
    yield put({
      type: ActionTypes.UPLOAD_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPLOAD_DOCUMENT_FAILURE,
    });
  }
}
export function* downloadStudentDocument(action) {
  try {
    const { payload } = action;
    const response = yield API.downloadStudentDocument(payload);
    yield put({
      type: ActionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
      payload: {
        ...response.data,
        IsDownloadClicked: payload.IsDownloadClicked,
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DOWNLOAD_DOCUMENT_FAILURE,
    });
  }
}

export function* claimsPdfDownload(action) {
  try {
    const { payload } = action;
    const response = yield API.claimsPdfDownload(payload);
    yield put({
      type: ActionTypes.CLAIMS_PDF_DOWNLOAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLAIMS_PDF_DOWNLOAD_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_DOCUMENT_LIST_REQUEST, getStudentDocumentList),
    takeLatest(ActionTypes.ARCHIVE_STUDENT_DOCUMENT_REQUEST, archiveStudentDocument),
    takeLatest(ActionTypes.UPLOAD_DOCUMENT_REQUEST, uploadDocument),
    takeLatest(ActionTypes.DOWNLOAD_DOCUMENT_REQUEST, downloadStudentDocument),
    takeLatest(ActionTypes.CLAIMS_PDF_DOWNLOAD_REQUEST, claimsPdfDownload),
  ]);
}
