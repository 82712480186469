import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal from '../../../../core/modal';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { appointmentNoteList, clientSharedMemoGetAll } from '../../../../store/actions';
import NotesDrawer from './notesDrawer/notesDrawer';
import PersistTableSort from '../../../../component/persistTableSort';

class SessionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notesDrawerVisible: false,
      appointmentId: '',
      isGroup: '',
      groupAppointmentId: '',
      isModelOpen: '',
      header: '',
      columns: [
        {
          title: 'Session Date',
          dataIndex: 'SessionDate',
          key: 'SessionDate',
          sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Start / End Time',
          dataIndex: 'SessionTime',
          key: 'SessionTime',
          sorter: (a, b) => isComparer(a.StartEndTime, b.StartEndTime),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Session Type',
          dataIndex: 'SessionType',
          key: 'SessionType',
          sorter: (a, b) => isComparer(a.SessionType, b.SessionType),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Service Code',
          dataIndex: 'ServiceCode',
          key: 'ServiceCode',
          sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Program',
          dataIndex: 'ProgramDescription',
          key: 'ProgramDescription',
          sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
          sorter: (a, b) => isComparer(a.Status, b.Status),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => {
            if (text == 'UnSigned') {
              return <span style={{ color: '#E87A7A' }}>{text}</span>;
            } else {
              return <span>{text}</span>;
            }
          },
        },
        // {
        //   title: 'Addendums',
        //   dataIndex: 'AddendumCount',
        //   key: 'AddendumCount',
        //   sorter: (a, b) => isComparer(a.Addendum, b.Addendum),
        //   sortDirections: ['descend', 'ascend'],
        // },
        {
          title: 'Action',
          render: (text, record, index) => {
            return (
              <div class="tools-grid">
                <>
                  {!this.props.isReadOnlyUser && (
                    <span
                      className="start-btn"
                      onClick={() => {
                        this.showDrawer(
                          record.AppointmentId,
                          record.IsGroup,
                          record.GroupAppointmentId,
                        );
                      }}
                    >
                      Manage Notes <p style={{ color: '#E87A7A' }}>{record.NoteSignHoursLeft}</p>
                    </span>
                  )}
                </>
              </div>
            );
          },
        },
      ],
    };
  }

  isAuditorLogin = () => {
    let isReadOnlyUserSession = false;
    if (localStorage.getItem('giveRead') == 'true') {
      isReadOnlyUserSession = true;
    }
    return isReadOnlyUserSession;
  };

  showDrawer = (appointmentId, isGroup, groupAppointmentId) => {
    this.setState({
      notesDrawerVisible: !this.isAuditorLogin(),
      appointmentId: appointmentId,
      isGroup: isGroup,
      groupAppointmentId: groupAppointmentId,
    });
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  onCloseDrawer = () => {
    this.setState({
      notesDrawerVisible: false,
    });
    this.props.history.push(
      `/cw/patient-management/patient-profile?tab=individual-notes&pId=${this.getClientId()}`,
    );
  };
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentDidMount() {
    this.props.dispatch(
      appointmentNoteList({
        Json: JSON.stringify({
          AppointmentNote: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
    let data2 = {
      Json: JSON.stringify({
        SharedMemo: [
          {
            ClientId: this.getClientId(),
          },
        ],
      }),
    };
    this.props.dispatch(clientSharedMemoGetAll(data2));

    var param = new URLSearchParams(this.props.history.location.search);
    let clientId = parseInt(param.get('pId'));
    let appointmentId = parseInt(param.get('appointmentId'));
    let groupAppointmentId = param.get('groupAppointmentId');
    let isGroup = groupAppointmentId ? true : false;
    if (clientId && (appointmentId || groupAppointmentId)) {
      this.showDrawer(appointmentId, isGroup, groupAppointmentId, clientId);
    }
  }
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      appointmentNoteList({
        json: JSON.stringify({
          AppointmentNote: [
            {
              Search: value,
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  };
  render() {
    const {
      columns,
      notesDrawerVisible,
      appointmentId,
      isGroup,
      groupAppointmentId,
      isModelOpen,
      header,
    } = this.state;
    const { clientId, noteList, isFetching } = this.props;
    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page mb-5">
          <div class="container-fluid rcm-tab">
            <div class="row">
              <div class="col-12 mb-5 px-0">
                <div class="border-0">
                  <div className="">
                    <div className="row px-0 mt-5 d-flex align-items-center">
                      <div class="col-md-4 col-xl-6 px-0">
                        {' '}
                        <h4 className="">NOTES LIST</h4>
                      </div>
                      <div class="col-md-7 col-xl-6 px-0 d-flex align-items-center">
                        <div class="search-box d-inline-flex w-100">
                          <div class="form-group has-search w-100 d-flex align-items-center">
                            <input
                              type="text"
                              class="form-control 
                   shadow-sm rcm-document-search"
                              placeholder="Search"
                              id="filterbox"
                              onChange={this.onSearchChange}
                            />
                            <button class="btn search-icon " type="button">
                              {' '}
                              <i class="fa fa-search" />{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-5 pb-5 rcm-subtab-table-block">
                    <div class="col-12" style={{ position: 'relative' }}>
                      <Loader2 loading={isFetching} />
                      <PersistTableSort
                        name="indi-noteList"
                        columns={columns}
                        dataSource={noteList}
                        size={'middle'}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: event => this.onRowClick(record.ClientId),
                        //   };
                        // }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-5 px-0">
                <div class="border-0">
                  <div className="">
                    <div className="row px-0 mt-5 d-flex align-items-center">
                      {this.props.sharedMemoList && this.props.sharedMemoList.length > 0 ? (
                        <div class="col-md-4 col-xl-6 px-0">
                          {' '}
                          <h4 className="">Shared Note</h4>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <div class="row pt-3 pb-3">
                    <div class="col-12" style={{ position: 'relative' }}>
                      {this.props.sharedMemoList &&
                        this.props.sharedMemoList.map((item, index) => {
                          const {
                            ClientSharedMemoId,
                            CreatedBy,
                            CreatedOn,
                            DateInserted,
                            IsArchived,
                            IsFavourite,
                            SharedMemo,
                          } = item;
                          return (
                            <div>
                              <div className="row mt-2 mb-2 rcm-subtab-table-block">
                                <div
                                  className="col-md-12 text-right"
                                  style={{ fontSize: '12px', color: '#959595' }}
                                >
                                  {CreatedOn}
                                </div>
                                <div className="col-md-12 text-justify">{SharedMemo}</div>

                                <div
                                  className="col-12"
                                  style={{ fontSize: '12px', color: '#959595' }}
                                >
                                  Created By : {CreatedBy}
                                </div>
                              </div>
                            </div>
                          );
                          debugger;
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {notesDrawerVisible && !this.isAuditorLogin() && (
            <NotesDrawer
              OnCloseDrawer={this.onCloseDrawer}
              NotesDrawerVisible={!this.isAuditorLogin()}
              AppointmentId={appointmentId}
              IsGroup={isGroup}
              ClientId={this.getClientId()}
              GroupAppointmentId={groupAppointmentId}
              toggle={this.toggle}
            />
          )}
          {isModelOpen && (
            <CustomModal
              isOpen={isModelOpen}
              zIndex={9999999}
              header={header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ appointment_note_list, client_shared_memo_get_all }) => {
  const { data: appointmentNoteList, isFetching } = appointment_note_list;
  const { data: memoList } = client_shared_memo_get_all;
  let noteList = [];
  let shareNotesData = [];
  let sharedMemoList = [];
  if (appointmentNoteList) {
    noteList = appointmentNoteList[0].AppointmentNote || [];
    shareNotesData = appointmentNoteList[0].SharedNotes || [];
  }
  if (memoList) {
    sharedMemoList = memoList.SharedMemo || [];
  }
  return {
    noteList,
    isFetching,
    shareNotesData,
    sharedMemoList,
  };
};

export default connect(mapStateToProps)(withRouter(SessionList));
