import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import StaffForm from '../../../../../staffform/index';

class ManageInternal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  render() {
    return (
      <div class="container-fluid">
        <Helmet>
          <title>Internal Care Team Member Management | Sober Peer</title>
        </Helmet>
        <div class="content-area">
          <div class="row top-table-search mt-1 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.goBack()}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <StaffForm
                pageTitle="Internal Care Team Member"
                baseURL="/cw/patient-management/"
                queryProps={`&pId=${this.getClientId()}`}
                listUrl={`patient-profile?tab=care-team&pId=${this.getClientId()}`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect()(withRouter(ManageInternal));
