import React, { useState, useEffect } from 'react';
import { submit } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { Button, Drawer, Table, Modal, Tooltip } from 'antd';
import AntdModal from '../../../../../../Components/CustomModal';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
} from '../../../../../../component/custom-redux-components';
import {
  smartContractRequestGet,
  smartContractRequestUpdate,
} from '../../../../../../store/actions';
import PersistTableSort from '../../../../../../component/persistTableSort';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';
import RequestDetailsDrawer from './requestDetailsDrawer';
import { Loader2 } from '../../../../../../Components/Loader';

const Requests = props => {
  const { smartContractId } = props;
  const [isModalOpen, setIsOpenModal] = useState(false);
  // const [payerStaffId, setPayerStaffId] = useState(null);
  const [payerPortalRequestId, setPayerPortalRequestId] = useState(null);
  const [drawerData, setDrawerData] = useState(null);

  const tempColumns = [
    {
      title: 'Requested By',
      dataIndex: 'RequestedBy',
      key: 'RequestedBy',
      sorter: (a, b) => isComparer(a.RequestedBy, b.RequestedBy),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Requested On',
      dataIndex: 'RequestedOn',
      key: 'RequestedOn',
      sorter: (a, b) => isDateComparer(a.RequestedOn, b.RequestedOn),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Requested Information',
      dataIndex: 'RequestedInformation',
      key: 'RequestedInformation',
      sorter: (a, b) => isComparer(a.RequestedInformation, b.RequestedInformation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'x',
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <img
              onClick={() => {
                setIsOpenModal(true);
                // setPayerStaffId(record.PayerStaffRequestId);
                setPayerPortalRequestId(record.PayerPortalRequestId);
              }}
              style={{ width: '18px', height: '20px', cursor: 'pointer' }}
              src="images/openIcon.svg"
            />
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const onRowClick = record => {
    setDrawerData(record);
  };

  useEffect(() => {
    let Data = {
      JSON: JSON.stringify({
        TimeLine: [{ ClientId: props.clientId, SmartContractId: smartContractId }],
      }),
    };
    dispatch(smartContractRequestGet(Data));
  }, []);

  useEffect(() => {
    if (props.requestData && props.requestData.length > 0) {
      setDrawerData(props.requestData[0]);
    }
  }, [props.requestData]);

  return (
    <>
      <div className="container-fluid mt-2" style={{ minHeight: '300px', overflow: 'auto' }}>
        {/* <Loader2 loading={ props.isFetching} /> */}
        <div className="row">
          <div className="col-12 enrollment-form mt-1">
            <div className="row card-body px-2 rcm-subtab-table-block">
              <Loader2 loading={props.isFetching} />
              <div className="col-12 rcm-tools-table rcm-panel">
                <PersistTableSort
                  name="service-code-list"
                  // bordered
                  columns={tempColumns}
                  dataSource={props.requestData || []}
                  size={'middle'}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div className="dataTables_wrapper no-footer">
                          <div className="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a className="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a className="paginate_button next">Next</a>
                            ) : (
                              <a className="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => onRowClick(record),
                    };
                  }}
                />
              </div>
              <div className="col-12 text-right mt-4">
                <div className="">
                  <Button
                    type="primary"
                    onClick={() => {
                      props.goToPreviuos();
                    }}
                    className={`btn btn-outline-primary`}
                    style={{ height: '42px' }}
                  >
                    Previous
                  </Button>{' '}
                  &nbsp;
                  <Button
                    type="primary"
                    onClick={() => {
                      props.handleNext();
                    }}
                    className={`btn save-button`}
                    style={{ height: '42px' }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Drawer
          className="custom-drawer print-drawer"
          title="Request Details"
          open={isModalOpen}
          onClose={() => {
            setIsOpenModal(false);
          }}
          width={800}
          bodyStyle={{ paddingBottom: 80 }}
          destroyOnClose={true}
          placement="right"
          // className="print-drawer"
        >
          <RequestDetailsDrawer
            // payerStaffId={payerStaffId}
            payerPortalRequestId={payerPortalRequestId}
            clientId={props.clientId}
            smartContractId={smartContractId}
            drawerData={drawerData}
          />
        </Drawer>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { smart_contract_request_get } = state;

  const { data, isFetching } = smart_contract_request_get;

  let requestData = [];

  if (data && data.Request) {
    requestData = data.Request;
  }

  return { requestData, isFetching };
};

export default connect(mapStateToProps)(Requests);
