import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Tooltip, Drawer } from 'antd';
// import { StudentImage } from '../../../../component/student';
import Loader, { Loader2 } from '../../../../Components/Loader';
import '../../../../component/jquery/slick.js';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AntdModal from '../../../../Components/CustomModal';
import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  listSurveyQuestionCategory,
  surveyAssign,
  listSurveyQuestionAssessment,
  deactivateClientScreening,
  listSurveyQuestionCategoryGetAll,
  markAsComplete,
  userFeatureFlagGet,
} from '../../../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';
import QuestionType from './questionType/questionComponent';
import moment from 'moment';
import PersistTableSort from '../../../../component/persistTableSort';
import CustomModal from '../../../../core/modal';
import DocumentForm from './documentForm';
import Axios from 'axios';
import LinkAppointment from '../../rcmComponents/linkingAppointment';
import AIAugmentedSummary from '../../../customScreens/surveyResponse/AIAugmentedSummary';
import { CommonLicenceScreens } from '../../../../Screens/licenceScreens';
import {
  HasMandatoryQuestion,
  HasMandatoryQuestionWarning,
  UpdateResponse,
} from './questionType/mandatoryQuestionValidation';
class Survey extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      SurveyQuestionCategoryListData: [],
      assesmentId: '',
      screeningView: true,
      surveyListData: [],
      surveyQuestionAssessment: {},
      currentPage: 1,
      isSubmitted: false,
      header: '',
      isAssignAlreadyModal: false,
      isAssignNew: false,
      SurveyId: null,
      AISurveyId: null,
      AISurveyIds: null,
      selectedRowKeys: [],
      isSurveyBanner: false,
    };
  }

  onAssignSurvey = () => {
    var item = {
      studentIds: this.props.clientId,
      surveyQuestionCategoryIds: this.state.assesmentId,
    };
    if (this.props.SurveyListData.length == 0) {
      this.setState({
        isAssignNew: true,
      });
    } else {
      this.props.SurveyListData.map(item =>
        item.SurveyQuestionCategoryId == this.state.assesmentId &&
        item.DocumentGuid == null &&
        item.SurveyStatus == 0
          ? this.setState({
              isAssignAlreadyModal: true,
            })
          : this.setState({
              isAssignNew: true,
            }),
      );
    }
    this.props.dispatch(surveyAssign(item));
  };

  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }

  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  getLatest = () => {
    let data = {
      Survey: [
        {
          ClientId: this.props.clientId || this.getClientId(),
          SurveyTypeDesc: 'Screening Tools',
        },
      ],
    };
    var getSurveyData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
  };
  getLatestSurvey = () => {
    this.props.dispatch(
      listSurveyQuestionAssessment({
        json: JSON.stringify({
          Survey: [
            {
              SurveyId: this.state.SurveyId,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    var param = new URLSearchParams(this.props.history.location.search);
    var surveyId = param.get('surveyId');
    if (surveyId) {
      this.toggleDrawer(surveyId);
    }
    this.getLatest();
  }
  toggleView = getLatest => {
    this.setState(prevState => ({
      screeningView: !prevState.screeningView,
      isSubmitted: false,
      header: '',
    }));
    if (getLatest) {
      this.getLatest();
    }
  };
  submitToggleView = header => {
    this.setState(prevState => ({
      isSubmitted: !prevState.isSubmitted,
      header,
    }));
  };
  componentWillReceiveProps({
    surveys,
    isSurveyAssign,
    SurveyQuestionCategoryList,
    SurveyListData,
    surveyQuestionAssessment,
    isSurveyDeactivated,
    SuccessMSg,
    isSubmitted,
    AugmentedAIStatusData,
  }) {
    if (AugmentedAIStatusData && AugmentedAIStatusData.StudentId == null) {
      this.props.dispatch(
        userFeatureFlagGet({
          JsonInput: JSON.stringify({ Center: { StudentId: this.getClientId() } }),
        }),
      );
    }

    if (surveys.length > 0 && surveys.length != this.props.surveys.length) {
      this.toggleView();
      for (var surveyIndex in surveys) {
        if (!surveys[surveyIndex].Response) {
          this.setState(
            {
              surveys: surveys,
              currentSlideIndex: parseInt(surveyIndex),
            },
            this.applySlick,
          );
          return;
        }
      }
    }
    if (
      surveyQuestionAssessment &&
      JSON.stringify(surveyQuestionAssessment) !=
        JSON.stringify(this.props.surveyQuestionAssessment)
    ) {
      if (!this.state.isAppointmentLinked) {
        this.toggleView();
      }
      const hasMandatory = HasMandatoryQuestion(
        surveyQuestionAssessment,
        this.state.SignatureCanvas,
      );
      this.setState({
        surveyQuestionAssessment: surveyQuestionAssessment,
        hasMandatory: hasMandatory,
      });
    }

    if (SurveyListData.length > 0 && SurveyListData.length != this.props.SurveyListData.length) {
      this.setState({ surveyListData: SurveyListData });
    }
    if (isSurveyAssign && isSurveyAssign != this.props.isSurveyAssign) {
      this.setState({
        assesmentId: '',
        currentPage: 1,
      });
    }
    if (isSurveyDeactivated && isSurveyDeactivated != this.props.isSurveyDeactivated) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: 'Screening Tools',
              },
            ],
          }),
        }),
      );
    }

    if (isSubmitted && isSubmitted != this.props.isSubmitted) {
      this.submitToggleView(SuccessMSg);
    }
  }

  setValue({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
  }) {
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    if (IsSignature) {
      this.setState({
        surveys: surveys,
        Signature: IsSignature && Signature,
        SignatureCanvas: IsSignature && {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [surveyQuestionId]: {
              SurveyQuestionId: surveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveys: surveys,
      });
    }

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index, isResetAnswer }) {
    let json = {
      StudentId: this.props.clientId,
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
    };
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });

    const surveys = [...this.state.surveys];
    for (let survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    $('.main-banner').slick('slickNext');
    this.setState({
      surveys: surveys,
    });
    if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }
  saveResponse = ({ response, surveyQuestionId, index, level, subLevel, subCatLevel }) => {
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
  };
  saveSignature = (
    signOne,
    data,
    SurveyQuestionId,
    index,
    Response,
    isSignatureSaved,
    isClear,
    isResponceData,
  ) => {
    let me = this;
    if (isClear) {
      let signaturData = me.state.SignatureCanvas;
      let signaturDataList = me.state.SignatureCanvas && me.state.SignatureCanvas.signatureDataList;
      delete signaturData[signOne];
      delete signaturDataList[signOne];
      signaturData.signatureDataList = signaturDataList;
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
      return;
    }
    if (isResponceData) {
      let signaturData = {
        ...this.state.SignatureCanvas,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    } else {
      let signaturData = {
        ...this.state.SignatureCanvas,
        [signOne]: data,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
            Response: Response,
            IsUpdateSignature: Response ? true : false,
            MediaGuid: Response ? Response : null,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        surveyQuestionId: SurveyQuestionId,
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    }
  };

  markAsCompleteAssessment = SurveyId => {
    this.props.dispatch(
      markAsComplete({
        SurveyId: SurveyId,
      }),
    );
  };
  submitScreeningForm = () => {
    const { SurveyId, SignatureCanvas, surveyQuestionId, surveyQuestionAssessment } = this.state;
    let listData =
      SignatureCanvas &&
      SignatureCanvas.signatureDataList &&
      Object.values(SignatureCanvas.signatureDataList);
    let signatureSaveList = listData && listData.filter(item => !item.isSignatureSaved);
    let saveList = listData && listData.filter(item => item.isSignatureSaved);
    signatureSaveList &&
      signatureSaveList.map((item, index) => {
        let jsonData = {
          StudentId: this.getStudentId(),
          SurveyId: this.getSurveyId(),
          SurveyQuestionId: item.SurveyQuestionId,
          Answer: item.Response,
          IsSignature: true,
          Signature: {
            Base64String: SignatureCanvas[item.SurveyQuestionId].replace(
              'data:image/png;base64,',
              '',
            ),
            IsUpdateSignature: item.Response ? true : false,
            MediaGuid: item.MediaGuid,
          },
        };
        Axios.post('Survey/SurveyAnswerUpsert', jsonData)
          .then(response => {
            saveList.push({
              [surveyQuestionId]: {
                SurveyQuestionId: item.SurveyQuestionId,
                isSignatureSaved: true,
              },
            });
          })
          .then(response => {
            if ((listData && listData.length) == (saveList && saveList.length)) {
              this.markAsCompleteAssessment(SurveyId);
            }
          });
      });
    if ((listData && listData.length) == (saveList && saveList.length)) {
      this.markAsCompleteAssessment(SurveyId);
    }
  };
  getSurveyId() {
    const { SurveyId } = this.state;

    return SurveyId;
  }

  getStudentId = () => {
    // var param = new URLSearchParams(this.props.history.location.search);
    // return param.get('studentId');
    return this.props.clientId;
  };

  onClose() {
    this.props.history.push('/studentProfile/' + this.getStudentId());
  }
  onSubmit() {
    this.props.history.push({
      pathname: '/surveyer',
      search: 'studentId=' + this.getStudentId() + '&ignoreUpdatingPin=true',
    });
  }
  getUpdatedSurvey = () => {
    this.setState(
      {
        isAppointmentLinked: true,
      },
      () => {
        this.getLatestSurvey();
      },
    );
  };
  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  toggleDrawer = (SurveyId, SurveyIds, isSurveyBanner) => {
    this.setState({
      isAugmentedAIDrawerOpen: !this.state.isAugmentedAIDrawerOpen,
      AISurveyId: SurveyId,
      AISurveyIds: SurveyIds,
      isSurveyBanner: isSurveyBanner,
    });
  };
  augmentSelectedSurvey = () => {
    // to send selected survey from grid
    // let surveyIds = this.state.selectedRowKeys.map(item => {
    //   return {
    //     SurveyIds: item,
    //     Name: null,
    //   };
    // });
    // this.toggleDrawer(null, surveyIds);
    this.toggleDrawer(null, null, true);
  };
  render() {
    const {
      surveys,
      currentSlideIndex,
      surveyListData,
      screeningView,
      surveyQuestionAssessment,
      selectedRowKeys,
      isSurveyBanner,
      hasMandatory,
    } = this.state;
    const {
      lastSlideIndex,
      SurveyQuestionCategoryList,
      isFetchingSurveyQuestionAssessment,
      isReadOnlyUser,
      isDeActivated,
      IsEligibleForUnSign,
      isSigned,
      signInfo,
      linkAppointment,
    } = this.props;
    const tempColumns = [
      {
        title: 'Name',
        dataIndex: 'SurveyName',
        key: 'SurveyName',
        sorter: (a, b) => isComparer(a.SurveyName, b.SurveyName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Assigned Date',
        dataIndex: 'AssignedDate',
        key: 'AssignedDate',
        sorter: (a, b) => isDateComparer(a.AssignedDateForSort, b.AssignedDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Completed Date',
        dataIndex: 'CompletedDate',
        key: 'CompletedDate',
        sorter: (a, b) => isDateComparer(a.CompletedDateForSort, b.CompletedDateForSort),
      },
      {
        title: 'Discarded Date',
        dataIndex: 'DiscardedDate',
        key: 'DiscardedDate',
        sorter: (a, b) => isDateComparer(a.DiscardedDateForSort, b.DiscardedDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        render: (text, record, index) => (
          <div class="tools-grid d-flex align-items-center" style={{ border: '0px solid red' }}>
            {record.SurveyStatus === 0 && (
              <>
                <div className="mr-5" style={{ border: '0px solid red' }}>
                  <Button
                    type="link"
                    style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                    loading={this.state.index === index && isFetchingSurveyQuestionAssessment}
                    onClick={event => {
                      this.props.dispatch(
                        listSurveyQuestionAssessment({
                          json: JSON.stringify({
                            Survey: [
                              {
                                SurveyId: record.SurveyId,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        SurveyId: record.SurveyId,
                        isReadOnly: false,
                        index: index,
                      });
                    }}
                  >
                    {this.state.index === index && isFetchingSurveyQuestionAssessment ? (
                      'Loading'
                    ) : record.IsStart ? (
                      <>{!isReadOnlyUser && 'Resume'}</>
                    ) : (
                      <>{!isReadOnlyUser && 'Start'}</>
                    )}
                  </Button>
                </div>
                <div className="">
                  {!isReadOnlyUser && (
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Delete">
                            <p>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to delete this Screening Tool?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  deactivateClientScreening({
                                    json: JSON.stringify({
                                      Survey: [
                                        {
                                          SurveyId: record.SurveyId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                // this.deleteGroup(record.GroupId);
                                // this.props.onCancel();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div>
              </>
            )}
            {record.SurveyStatus === 1 && (
              <>
                <div
                  className=""
                  style={{
                    border: '0px solid red',
                    minWidth: '110px',
                    // paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Button
                    type="link"
                    style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                    loading={this.state.index === index && isFetchingSurveyQuestionAssessment}
                    onClick={event => {
                      this.props.dispatch(
                        listSurveyQuestionAssessment({
                          json: JSON.stringify({
                            Survey: [
                              {
                                SurveyId: record.SurveyId,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        SurveyId: record.SurveyId,
                        isReadOnly: true,
                        index: index,
                      });
                    }}
                  >
                    {this.state.index === index && isFetchingSurveyQuestionAssessment
                      ? 'Loading'
                      : 'View'}
                  </Button>
                </div>
                {record.IsLatestCompleted === 1 && (
                  <div className="mr-2">
                    <img
                      src="images/gpt-ai-grid.svg"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        this.toggleDrawer(record.SurveyId);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {record.SurveyStatus === -1 && null}
          </div>
        ),
      },
    ];
    // grid level selection removed as per requirement.
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    //   getCheckboxProps: record => {
    //     return {
    //       disabled:
    //         record.SurveyStatus === 0 ||
    //         record.SurveyStatus === -1 ||
    //         record.IsLatestCompleted === 0, // Column configuration not to be checked
    //       name: record.SurveyId,
    //     };
    //   },
    // };
    return (
      <div class="main survery-question-page  mb-5">
        {/* <Loader2 loading={this.props.isFetching} /> */}
        {this.props.isFetchingMarkAsComplete && (
          <div style={{ position: 'relative', height: '500px' }}>
            <Loader2 loading={this.props.isFetchingMarkAsComplete} />
          </div>
        )}
        {!this.props.isFetchingMarkAsComplete && (
          <div>
            {screeningView ? (
              <>
                <div class="container-fluid rcm-tab">
                  <div className="row">
                    {/* <h3 class="page-title pt-5 mb-3 mb-md-5 ">Screening Tool</h3> */}
                    <div className="col-12 enrollment-form mt-5">
                      {!isDeActivated && (
                        <div className="row px-0">
                          <div class="col-5 px-0">
                            <div class="form-group">
                              <ReduxSelect
                                options={SurveyQuestionCategoryList}
                                onChange={value => {
                                  this.setState({ assesmentId: value });
                                  // this.props.dispatch(getSurveyQuestionMultiple(parseInt(value), 20000019));
                                }}
                                className="basic-single"
                                classNamePrefix="select"
                                // value={defaultCenter}
                                value={this.state.assesmentId}
                                placeholder="Select"
                                disabled={this.props.isReadOnlyUser}
                              />
                            </div>
                          </div>
                          <div class="col-2 d-flex align-items-center">
                            <button
                              class={
                                this.state.assesmentId
                                  ? 'rcm-sub-tab-btn'
                                  : ' rcm-sub-tab-btn disable-cursor'
                              }
                              onClick={this.onAssignSurvey}
                              disabled={!this.state.assesmentId || this.props.isReadOnlyUser}
                            >
                              Assign
                            </button>
                          </div>
                          <div className="col-4 d-flex align-items-center justify-content-end">
                            <div className="clinical-ai-top-button">
                              <div>
                                {/* {selectedRowKeys.length > 0 && (
                                  <span className="px-2">
                                    {selectedRowKeys.length} Screening tool selected
                                  </span>
                                )} */}
                                <button
                                  title="Augment with AI"
                                  class="ai-btn"
                                  onClick={this.augmentSelectedSurvey}
                                  style={{
                                    display: 'inline',
                                    padding: '5px 20px',
                                  }}
                                  // disabled={!selectedRowKeys.length}
                                >
                                  <figure
                                    class="action-button-icon"
                                    style={{ display: 'inline-block' }}
                                  >
                                    <img src="images/AIStar.svg" alt="" width="23" />
                                  </figure>
                                  Augment with AI
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <CustomModal
                      isOpen={this.state.isAssignAlreadyModal || this.state.isAssignNew}
                      // header={this.state.header}
                      header={
                        this.state.isAssignAlreadyModal
                          ? 'This form is already assigned'
                          : 'The form has been assigned successfully'
                      }
                      yesAction={() => {
                        this.setState({
                          isAssignAlreadyModal: false,
                          isAssignNew: false,
                        });
                        let getSurveyData = {
                          json: JSON.stringify({
                            Survey: [
                              {
                                ClientId: this.props.clientId,
                                SurveyTypeDesc: 'Screening Tools',
                              },
                            ],
                          }),
                        };
                        this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
                      }}
                      yesText="OK"
                      hideCancel={true}
                      changeMaxWidth="100%"
                    />
                    <div className="col-12 enrollment-form mt-1">
                      <div class="row card-body px-2 rcm-subtab-table-block">
                        <Loader2 loading={this.props.isFetching} />
                        <div class="col-12 rcm-tools-table">
                          <PersistTableSort
                            name="surveyListData"
                            columns={tempColumns}
                            dataSource={surveyListData}
                            size={'middle'}
                            loader={this.props.isFetchingSurveyQuestionAssessment}
                            // rowSelection={{
                            //   type: 'checkbox',
                            //   ...rowSelection,
                            // }}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div class="dataTables_wrapper no-footer">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a class="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a class="paginate_button next">Next</a>
                                      ) : (
                                        <a class="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              showSizeChanger: true,
                              // hideOnSinglePage: true,
                              current: this.state.currentPage,
                              onChange: page => {
                                this.setState({
                                  currentPage: page,
                                });
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <DocumentForm
                    DocTypeValue={16018}
                    clientId={this.props.clientId}
                    isReadOnlyUser={this.props.isReadOnlyUser}
                    isDeActivated={isDeActivated}
                  />
                </div>
              </>
            ) : (
              <div class="container-fluid px-0">
                <div className="row">
                  <div class="col-12 text-center">
                    <Loader2 loading={this.props.isFetching} />
                    <div
                      class="survery-question-box "
                      style={{ maxWidth: 'unset', marginTop: '0px' }}
                    >
                      {/* <h3 class="mb-3 mb-md-5 ">{surveys.length > 0 && surveys[0].SurveyCategory}</h3> */}
                      <div className="screening-tool">
                        <div className="assessment-main-title d-flex align-items-center justify-content-between">
                          <div
                            onClick={() => {
                                this.toggleView(true);
                            }}
                            class="text-left d-flex"
                            style={{ cursor: 'pointer' }}
                          >
                            <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                          </div>
                          <span className="main-title-text">
                            {surveyQuestionAssessment.SurveyMainTitle}
                          </span>
                          <div>
                            <Link
                              class="mx-2"
                              to={{
                                pathname: '/printScreening',
                                search: 'SurveyId=' + this.state.SurveyId,
                              }}
                              target="_blank"
                            >
                              Print
                            </Link>
                          </div>
                        </div>
                        {surveyQuestionAssessment && surveyQuestionAssessment.SurveyQuestion && (
                          <div
                            style={{
                              padding: '25px',
                              margin: '0px 25px',
                            }}
                          >
                            <QuestionType
                              SurveyQuestion={surveyQuestionAssessment.SurveyQuestion}
                              SurveyId={surveyQuestionAssessment.SurveyId}
                              onNext={this.onNext}
                              setValue={this.setValue}
                              saveResponse={this.saveResponse}
                              scope={this}
                              level={0}
                              isReadOnly={this.state.isReadOnly}
                              saveSignature={this.saveSignature}
                            />
                          </div>
                        )}
                        {surveyQuestionAssessment &&
                          surveyQuestionAssessment.SurveyCategory &&
                          surveyQuestionAssessment.SurveyCategory.map((surveyCategory, index) => {
                            return (
                              <div>
                                <div
                                  className="assessment-sub-title"
                                  style={{
                                    margin: '0px 25px',
                                  }}
                                >
                                  <span className="sub-title-text">
                                    {surveyCategory.SurveyCategory}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: '#F8F8F8',
                                    padding: '25px',
                                    margin: '0px 25px',
                                  }}
                                >
                                  <QuestionType
                                    SurveyQuestion={surveyCategory.SurveyQuestion}
                                    SurveyId={surveyQuestionAssessment.SurveyId}
                                    onNext={this.onNext}
                                    setValue={this.setValue}
                                    saveResponse={this.saveResponse}
                                    scope={this}
                                    level={1}
                                    subLevel={index}
                                    isReadOnly={this.state.isReadOnly}
                                    saveSignature={this.saveSignature}
                                  />

                                  {/* {surveyCategory &&
                                    surveyCategory.SurveySubCategory &&
                                    surveyCategory.SurveySubCategory.map(
                                      (subCategory, subIndex) => {
                                        return (
                                          <div
                                            style={{
                                              backgroundColor: '#FFF',
                                              padding: '15px',
                                            }}
                                          >
                                            <div className="assessment-sub-title">
                                              <span className="sub-title-text">
                                                {subCategory.SurveySubCategory}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                backgroundColor: '#FFF',
                                                padding: '15px',
                                                // margin: '0px 25px',
                                              }}
                                            >
                                              <QuestionType
                                                SurveyQuestion={subCategory.SurveyQuestion}
                                                SurveyId={surveyQuestionAssessment.SurveyId}
                                                onNext={this.onNext}
                                                setValue={this.setValue}
                                                scope={this}
                                                level={2}
                                                subLevel={index}
                                                subCatLevel={subIndex}
                                                isReadOnly={this.state.isReadOnly}
                                                saveSignature={this.saveSignature}
                                              />
                                            </div>
                                          </div>
                                        );
                                      },
                                    )} */}
                                </div>
                              </div>
                            );
                          })}
                        <div class="col-12 col-md-12 col-lg-12 mt-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <HasMandatoryQuestionWarning hasMandatory={hasMandatory} />
                            </div>
                            <div>
                              <input
                                type="button"
                                class=" btn btn-outline-primary mr-2"
                                value="Back to list"
                                onClick={() => {
                                  this.toggleView();
                                }}
                              />
                              {!this.state.isReadOnly && (
                                <button
                                  type="button"
                                  className="btn"
                                  disabled={hasMandatory}
                                  onClick={() => {
                                    this.submitScreeningForm();
                                    window.scrollTo(0, 0);
                                    // this.props.dispatch(
                                    //   markAsComplete({
                                    //     SurveyId: surveyQuestionAssessment.SurveyId,
                                    //   }),
                                    // );
                                    // this.toggleView(true);
                                  }}
                                >
                                  Submit and Back to list
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {linkAppointment && (
                          <div className="mt-4" style={{
                            padding: "25px",
                            margin: "0px 25px",
                            textAlign: "left"
                          }}>
                          <LinkAppointment
                            clientId={this.props.clientId}
                            getLatest={this.getUpdatedSurvey}
                            formType="Screening Tools"
                            componentDataUniqueId={this.state.SurveyId}
                            linkData={{ IsSigned: this.props.isSigned }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <CustomModal
                  isOpen={this.state.isSubmitted}
                  header={this.state.header}
                  yesAction={() => {
                    this.submitToggleView();
                    if (this.state.header.includes('mandatory')) return;
                    this.toggleView(true);
                  }}
                  yesText="OK"
                  hideCancel={true}
                  changeMaxWidth="100%"
                />
              </div>
            )}
          </div>
        )}
        <Drawer
          className="custom-drawer"
          title="Augment with AI"
          placement="right"
          open={this.state.isAugmentedAIDrawerOpen}
          width={800}
          bodyStyle={{ paddingBottom: 80, background: '#fff' }}
          closable={true}
          destroyOnClose={true}
          onClose={() => {
            this.toggleDrawer();
          }}
        >
          {this.props.AugmentedAIStatusData && (
            <CommonLicenceScreens
              StatusData={this.props.AugmentedAIStatusData}
              moduleName={'AugmentedAI'}
            />
          )}
          {this.props.AugmentedAIStatusData &&
            this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
            this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
            this.props.AugmentedAIStatusData.LicenseStatusId != 5 && (
              <>
                <AIAugmentedSummary
                  ClientId={this.props.clientId}
                  SurveyIds={this.state.AISurveyId}
                  toggleDrawer={this.toggleDrawer}
                  isSurveyBanner={isSurveyBanner}
                  // ClinicalSurveys={this.state.AISurveyIds}
                />
              </>
            )}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({
  survery_question_get_multiple,
  list_survey_question_category,
  survey_assign,
  list_survey_question_assessment,
  deactivate_client_screening,
  list_survey_question_category_get_all,
  mark_as_complete,
  user_feature_flag_get,
}) => {
  const { data, isFetching, error } = survery_question_get_multiple;
  const { isFetching: isFetchingMarkAsComplete } = mark_as_complete;
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: surveyAssignData } = survey_assign;
  const {
    data: listSurveyQuestionCategoryData,
    isFetching: isFetchingListSurvey,
  } = list_survey_question_category_get_all;
  const { data: fFData } = user_feature_flag_get;
  let AugmentedAIStatusData = null;
  var lastSlideIndex = 1;
  let SurveyQuestionCategoryList;
  let SurveyListData = [];
  let surveyData = [];
  let surveyQuestionAssessment;
  let isSurveyAssign = false;
  let isSurveyDeactivated = false;
  let SuccessMSg = null;
  let isSubmitted = false;
  let IsEligibleForUnSign;
  let isSigned = false;
  let linkAppointment = false;
  let signInfo = {};

  if (data) {
    let array = [];
    for (let index in data) {
      array.push(data[index]);
    }
    //array = array.slice(0, 10);

    array.push({});
    surveyData = [...array];
    lastSlideIndex = data.length - 2;
  }
  if (surveyQuestionAssessmentData) {
    IsEligibleForUnSign = surveyQuestionAssessmentData.IsEligibleForUnSign;
    isSigned = surveyQuestionAssessmentData.IsSigned;
    linkAppointment = surveyQuestionAssessmentData.LinkAppointment;
    signInfo = surveyQuestionAssessmentData.SignInfo && surveyQuestionAssessmentData.SignInfo[0];
    surveyQuestionAssessment = surveyQuestionAssessmentData;
  }
  if (listSurveyQuestionCategoryData && listSurveyQuestionCategoryData.SurveyQuestionCategoryList) {
    SurveyQuestionCategoryList = listSurveyQuestionCategoryData.SurveyQuestionCategoryList;
  }
  if (listSurveyQuestionCategoryData && listSurveyQuestionCategoryData.SurveyList) {
    SurveyListData = listSurveyQuestionCategoryData.SurveyList;
  }
  if (surveyAssignData && surveyAssignData.success) {
    isSurveyAssign = surveyAssignData.success;
  }
  if (deactivate_client_screening.data && deactivate_client_screening.data.success) {
    isSurveyDeactivated = deactivate_client_screening.data.success;
  }
  if (mark_as_complete.data && mark_as_complete.data.success) {
    SuccessMSg = mark_as_complete.data.result;
    isSubmitted = mark_as_complete.data.success;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  return {
    surveys: surveyData,
    surveyQuestionAssessment,
    isSurveyAssign,
    isSurveyDeactivated: isSurveyDeactivated,
    lastSlideIndex: lastSlideIndex,
    SurveyQuestionCategoryList: SurveyQuestionCategoryList || [],
    SurveyListData: SurveyListData,
    // isFetchingListSurvey: isFetchingListSurvey || isFetching || false,
    isFetching: isFetching || isFetchingListSurvey,
    isFetchingSurveyQuestionAssessment: isFetchingSurveyQuestionAssessment,
    SuccessMSg,
    isSubmitted,
    isFetchingMarkAsComplete,
    IsEligibleForUnSign,
    isSigned,
    signInfo,
    linkAppointment,
    AugmentedAIStatusData,
  };
};

export default connect(mapStateToProps)(withRouter(Survey));
