import React, { Component } from 'react';
import $ from 'jquery';
import { Checkbox } from 'antd';
import renderHTML from 'react-render-html';

class MultiSelectType extends Component {
  constructor(props) {
    super(props);
    let {
      Response,
      Option1Value,
      Option2Value,
      Option3Value,
      Option4Value,
      Option5Value,
      Option6Value,
      Option7Value,
      Option8Value,
      Option9Value,
      Option10Value,
    } = props.survey;
    // const {
    //   survey: { Response },
    // } = this.props;
    let responseArr =
      Response &&
      JSON.parse(Response).map(item => {
        return {
          [item]: true,
        };
      });
    let responseObj =
      responseArr &&
      responseArr.reduce(function(result, current) {
        return Object.assign(result, current);
      }, {});

    this.state = {
      result: {
        [Option1Value]: false,
        [Option2Value]: false,
        [Option3Value]: false,
        [Option4Value]: false,
        [Option5Value]: false,
        [Option6Value]: false,
        [Option7Value]: false,
        [Option8Value]: false,
        [Option9Value]: false,
        [Option10Value]: false,
        ...responseObj,
      },
    };

    if (Response) {
      this.state.value = Response;
    }
  }

  onClick() {
    if (this.state.filtered) {
      const {
        survey: { SurveyQuestionId },
        setValue,
        scope,
      } = this.props;
      setValue.call(scope, {
        response: JSON.stringify(this.state.filtered),
        surveyQuestionId: SurveyQuestionId,
      });
    }
    $('.main-banner').slick('slickNext');
  }

  onChange = (event, click) => {
    const { value } = event.target;
    const {
      survey: { SurveyQuestionId, MaxValue, MinValue },
      setValue,
      scope,
    } = this.props;
    if (value) {
      if (value >= MinValue && value <= MaxValue) {
        this.setState({ value: value });
        setValue.call(scope, { response: value, surveyQuestionId: SurveyQuestionId });
      }
    } else {
      if (value >= MinValue && value <= MaxValue) this.setState({ value: value });
    }
  };

  componentWillReceiveProps(props) {
    const {
      survey: { Response },
    } = this.props;
    if (Response) {
      this.setState({ value: Response });
    }
  }

  render() {
    const {
      // survey: {
      //   Question,
      //   DoesNotApplyText,
      //   DoesNotApplyValue,
      //   SurveyId,
      //   SurveyQuestionId,
      //   MaxValue,
      //   MinValue,
      // },
      // onClick,
      survey,
      setValue,
      scope,
      index,
      total,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option2Value,
      Option2Text,
      Option3Value,
      Option3Text,
      Option4Value,
      Option4Text,
      Option5Value,
      Option5Text,
      Option6Value,
      Option6Text,
      Option7Value,
      Option7Text,
      Option8Value,
      Option8Text,
      Option9Value,
      Option9Text,
      Option10Value,
      Option10Text,
      Response,
      SurveyQuestionId,
    } = survey;
    const textArray = [
        Option1Text,
        Option2Text,
        Option3Text,
        Option4Text,
        Option5Text,
        Option6Text,
        Option7Text,
        Option8Text,
        Option9Text,
        Option10Text,
      ],
      valueArray = [
        Option1Value,
        Option2Value,
        Option3Value,
        Option4Value,
        Option5Value,
        Option6Value,
        Option7Value,
        Option8Value,
        Option9Value,
        Option10Value,
      ];
    return (
      <div className=" card border-0">
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <div className="d-flex flex-column">
            <h5 className="survey-question">
              {Question && renderHTML(Question)}
              {/* <span class="d-md-block">these psychological or emotional problems?</span> */}
            </h5>
            {/* <div
              class="answer-options mb-4 "
              style={{
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
                textAlign: 'left',
              }}
            >
              <div class="quantity-btn">
                <div class="numbers-row">
                  <input
                    class="number-icon"
                    type="text"
                    min="0"
                    max="30"
                    value={this.state.value}
                    tabindex="0"
                    style={{ textAlign: 'center' }}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div> */}
            <div style={{ textAlign: '-webkit-center' }}>
              <ul class="answer-options d-flex flex-column text-left">
                {textArray.map((item, qIndex) => {
                  let val = valueArray[qIndex];
                  return (
                    item && (
                      <li className="pl-3">
                        <Checkbox
                          // disabled={isReadOnly}
                          name="current-situation"
                          checked={this.state.result[val]}
                          value={item}
                          className="d-flex align-items-end"
                          onClick={e => {
                            let result = this.state.result;
                            result[val] = e.target.checked;
                            let keys = Object.keys(result);
                            let filtered = keys.filter(key => {
                              return result[key];
                            });
                            this.setState({
                              result,
                              filtered,
                            });
                            // this.props.onClick.bind(scope, {
                            //   surveyQuestionId: SurveyQuestionId,
                            //   response: JSON.stringify(filtered),
                            //   // level: level,
                            //   // subLevel: subLevel,
                            //   // subCatLevel: subCatLevel,
                            //   // index: index,
                            // })();
                          }}
                        >
                          {' '}
                          {item}
                        </Checkbox>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
            <div
              style={{
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
              }}
            >
              <button class="btn px-5" onClick={this.onClick.bind(this)} tabindex="0">
                Next
              </button>
            </div>
            {DoesNotApplyText && (
              <div className="not-apply-to-me">
                <a
                  name="current-situation"
                  value={DoesNotApplyText}
                  onClick={this.props.onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: DoesNotApplyValue,
                  })}
                >
                  {DoesNotApplyText}
                </a>
              </div>
            )}
          </div>
          {/* <div class="row pagination-button">
            <div class="col-12 text-right">
              <button class="btn" onClick={this.onClick.bind(this)}>
                Next
              </button>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default MultiSelectType;
