import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  externalProviderListGet,
  externalProviderGet,
  externalProviderUpsert,
  resetExternalProviderUpsert,
  externalStaffArchive,
  resetExternalStaffArchive,
} from '../../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import CustomModal from '../../../../core/modal';
import { Tooltip, Drawer } from 'antd';
import GridSearch from '../../../../Components/gridSearch';
import ExternalCareTeamListFilter from '../../patientEnrollmentForm/formSteps/manageCareTeam/components/externalCareTeamListFilter';
import ExternalCareTeamMemberView from '../../patientEnrollmentForm/formSteps/manageCareTeam/components/externalCareTeamMemberView';
import ExternalCareTeamMember from '../../patientEnrollmentForm/formSteps/manageCareTeam/components/externalCareTeamMember';
import CloseIcon from '../../../../Components/CloseIcon';
import { CustomToolTip } from '../../../../Components/CustomTooltip';
import { PopoverMenu } from '../../../../Components/PopoverMenu';
import AntdModal from '../../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../../Components/Loader';
import PersistTableSort from '../../../../component/persistTableSort';

class ExternalStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {
        Country: 'USA',
      },
      isReadOnly: false,
    };
  }
  getGridData = () => {
    this.props.dispatch(
      externalProviderListGet({
        Json: JSON.stringify({
          ExternalProvider: [
            {
              ...this.state.filtersData,
              IsInvited: 1,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    this.getGridData();
  }
  componentWillReceiveProps({ externalProviderInfo, isArchived, upsertMsg, isUpsertSuccess }) {
    if (
      externalProviderInfo &&
      JSON.stringify(externalProviderInfo) != JSON.stringify(this.props.externalProviderInfo)
    ) {
      // let SpecialtyData = externalProviderInfo.Specialty.map(item => item.Specialty).toString();

      this.setState({
        initialData: externalProviderInfo,
        // {
        //   ...externalProviderInfo,
        //   // Specialty: SpecialtyData,
        //   // SpecialtyData: SpecialtyData,
        // },
      });
    }
    if (isArchived && isArchived != this.props.isArchived) {
      this.toggle(
        `External Provider ${this.state.rowData.IsArchived ? 'Renewed' : 'Suspended'} Successfully`,
      );
      this.setState({
        rowData: null,
      });
    }
    if (isUpsertSuccess && isUpsertSuccess != this.props.isUpsertSuccess) {
      this.toggleDrawer();
      this.toggle(upsertMsg);
      //  this.props.dispatch(resetExternalProviderUpsert({}));
    }
  }
  toggleDrawer = (record, actionType) => {
    if (record && record.ExternalProviderId) {
      this.props.dispatch(
        externalProviderGet({
          Json: JSON.stringify({
            ExternalProvider: [
              {
                ExternalProviderId: record.ExternalProviderId,
                // ExternalProviderFacilityId: record.ExternalProviderFacilityId,
              },
            ],
          }),
        }),
      );
    }
    if (actionType == 'View') {
      this.setState({
        isCareTeamViewDrawer: !this.state.isCareTeamViewDrawer,
        actionType: actionType,
      });
    } else {
      this.setState({
        isCareTeamDrawer: !this.state.isCareTeamDrawer,
        // isReadOnly: actionType == 'View' ? true : false,
        actionType: actionType,
      });
    }
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  handleCareTeam = val => {
    let data = {
      ExternalProvider: [
        {
          ExternalProviderId: val.ExternalProviderId || null,
          // ClientId: this.getClientId(),
          FirstName: val.FirstName,
          LastName: val.LastName,
          Gender: val.Gender,
          FacilityName: val.FacilityName,
          EmailWork: val.EmailWork,
          PhoneNumber: val.PhoneNumber,
          CountryISO: val.CountryISO,
          State: val.State,
          City: val.City,
          ZipCode: val.ZipCode,
          NPI: val.NPI,
          Specialty:
            typeof val.Specialty == 'object'
              ? val.Specialty.map(item => item.Value).toString()
              : val.Specialty || null,
        },
      ],
    };
    this.props.dispatch(
      externalProviderUpsert({
        Json: JSON.stringify(data),
      }),
    );
  };
  getPopOverData = record => {
    return (
      <>
        <p
          onClick={() => {
            // this.toggleDrawer(record, 'Edit');
            this.props.history.push(
              `/cw/manage/clinical-staff/staff-management/staff-form?staffId=${record.StaffId}`,
            );
          }}
        >
          Edit
        </p>
        <p
          onClick={() => {
            this.toggleDrawer(record, 'View');
          }}
        >
          View
        </p>
        <AntdModal
          ModalButton={({ onClick }) => (
            <p
              style={{ color: record.IsArchived ? '#182838' : '#FF3B3B' }}
              onClick={onClick}
              data-target="#move-intake-model"
            >
              {record.IsArchived ? 'Renew' : 'Suspend'}
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">
                  Are you sure you want to {record.IsArchived ? 'Renew' : 'Suspend'}?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No, keep
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      externalStaffArchive({
                        Json: JSON.stringify({
                          ExternalProvider: [
                            {
                              ExternalProviderId: record.ExternalProviderId,
                              IsArchived: record.IsArchived ? 0 : 1,
                            },
                          ],
                        }),
                      }),
                    );
                    this.setState({
                      rowData: record,
                    });
                  }}
                >
                  {record.IsArchived ? 'Renew' : 'Suspend'}
                </button>
              </div>
            </div>
          )}
        />
      </>
    );
  };
  tableCoumns = [
    // {
    //   title: 'Profile',
    //   dataIndex: 'ProfileImageUrl',
    //   key: 'ProfileImageUrl',
    //   render: (text, record, index) => (
    //     <div className="d-flex align-items-center">
    //       <ProfilePicComponent src={record.ProfileImageUrl} alt={record.altText} />
    //     </div>
    //   ),
    // },

    {
      title: 'NPI',
      dataIndex: 'NPI',
      key: 'NPI',
      sorter: (a, b) => isComparer(a.NPI, b.NPI),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Member Name',
      dataIndex: 'x',
      key: 'x',
      sorter: (a, b) => isComparer(a.LastName, b.LastName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <span className="mr-1">{record.FirstName}</span>
            <span className="mx-1">{record.LastName}</span>
          </div>
          {record.IsOnboarded != 0 && (
            <div>
              <span
                className=""
                style={{
                  background: '#D8D8D8',
                  borderRadius: '2px',
                  padding: '2px 10px',
                  color: '#182838',
                }}
              >
                Onboarded
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Specialty',
      dataIndex: 'Specialty',
      key: 'Specialty',
      // sorter: (a, b) => isComparer(a.Specialty, b.Specialty),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const SpecialtyList = text;
        return (
          <div className="">
            {SpecialtyList && SpecialtyList.length > 1 ? (
              <span className="mx-1">
                <CustomToolTip
                  // trigger="hover"
                  placement="left"
                  optionData={SpecialtyList}
                  titleKey={'Specialty'}
                  title={'Specialty'}
                />
              </span>
            ) : (
              <span className="mx-1">{SpecialtyList && SpecialtyList[0].Specialty}</span>
            )}
          </div>
        );
      },
    },
    // {
    //   title: 'First Name',
    //   dataIndex: 'FirstName',
    //   key: 'FirstName',
    //   sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Last Name',
    //   dataIndex: 'LastName',
    //   key: 'LastName',
    //   sorter: (a, b) => isComparer(a.LastName, b.LastName),
    //   sortDirections: ['descend', 'ascend'],
    // },

    // {
    //   title: 'Email',
    //   dataIndex: 'EmailWork',
    //   key: 'EmailWork',
    //   sorter: (a, b) => isComparer(a.EmailWork, b.EmailWork),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Gender',
      dataIndex: 'Gender',
      key: 'Gender',
      sorter: (a, b) => isComparer(a.Gender, b.Gender),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Location',
      dataIndex: 'Locations',
      key: 'Locations',
      // sorter: (a, b) => isComparer(a.Location, b.Location),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const LocationList = text;
        return (
          <div className="d-flex align-items-center">
            {LocationList && LocationList.length > 1 ? (
              <span className="mx-1">
                <CustomToolTip
                  // trigger="hover"
                  placement="left"
                  optionData={LocationList}
                  titleKey={'Location'}
                  title={'Locations'}
                />
              </span>
            ) : (
              <span className="mx-1">{LocationList && LocationList[0].Location}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Facility Name',
      dataIndex: 'Facility',
      key: 'Facility',
      // sorter: (a, b) => isComparer(a.Facility, b.Facility),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const FacilityList = text;
        return (
          <div className="d-flex align-items-center">
            {FacilityList && FacilityList.length > 1 ? (
              <span className="mx-1">
                <CustomToolTip
                  // trigger="hover"
                  placement="left"
                  optionData={FacilityList}
                  titleKey={'FacilityName'}
                  title={'Facility'}
                />
              </span>
            ) : (
              <span className="mx-1">{FacilityList && FacilityList[0].FacilityName}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => isComparer(a.Status, b.Status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        // StatusId = 1=Active, 1=Not Onboarded, -1=Suspended
        <span
          style={{
            color: record.IsArchived ? '#E87A7A' : '#182838',
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'x',
      fixed: 'right',
      render: (text, record, index) => {
        return (
          <div className="tools-grid" onClick={event => event.stopPropagation()}>
            <PopoverMenu OptionData={this.getPopOverData(record)} placement="left" />
            {/* <span className="mx-2">
              <Tooltip placement="top" title="View">
                <img
                  src="images/openIcon.svg"
                  width="16px"
                  style={{ cursor: 'pointer' }}
                  onClick={event => {
                    this.toggleDrawer(record, 'View');
                  }}
                />
              </Tooltip>
            </span> */}
          </div>
        );
      },
    },
  ];
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });

    this.props.dispatch(
      externalProviderListGet({
        json: JSON.stringify({
          ExternalProvider: [
            {
              ...this.state.filtersData,
              IsInvited: 1,
              Search: value,
            },
          ],
        }),
      }),
    );
  };
  getFilterInfo = filters => {
    this.state.filtersData = filters;
  };
  render() {
    const { isFetchingUpsert, isFetchingList } = this.props;
    const {
      isCareTeamDrawer,
      isCareTeamViewDrawer,
      isReadOnly,
      initialData,
      isModelOpen,
      header,
      actionType,
    } = this.state;
    return (
      <div className="container-fluid py-5">
        <div className="row mb-2 top-table-search align-items-center">
          <div class="col-md-6 col-xl-8">
            <a
              class="back-link"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.history.goBack()}
            >
              <img src="images/back-arrow.svg" /> Back
            </a>
            <br />
            <span className="grid-page-title">Additional Care Team Member(s)</span>
          </div>
        </div>
        <div className="row">
          <div className="col-3 pr-1">
            <ExternalCareTeamListFilter
              getClientId={this.getClientId}
              IsInvited={true}
              getFilterInfo={this.getFilterInfo}
            />
          </div>
          <div className="col-9 rcm-tools-table pl-1">
            <div class="card border-0">
              <div class="row card-body">
                <div class="col-md-2"></div>
                <div class="col-md-10 text-right">
                  <GridSearch onSearchChange={this.onSearchChange} />
                  <input
                    type="button"
                    class="btn"
                    value={'Add New Member'}
                    onClick={this.toggleDrawer}
                  />
                </div>
                <div class="col-12 mt-2">
                  <Loader2 loading={isFetchingList} />
                  <PersistTableSort
                    name="external-care-list"
                    columns={this.tableCoumns}
                    dataSource={this.props.externalClientCareTeam}
                    size={'small'}
                    className="radio-nested-tables"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isCareTeamDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">
                  {/* {actionType ? actionType : 'Select'} */}
                  Add External Member Manually
                </span>
              </div>
            }
            placement="right"
            open={isCareTeamDrawer}
            width={800}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.setState({
                initialData: {},
                isCareTeamDrawer: false,
              });
            }}
          >
            <ExternalCareTeamMember
              initialValues={initialData}
              ExternalProviderId={initialData.ExternalProviderId}
              disable={isReadOnly}
              onSaveClick={this.handleCareTeam}
              onClose={() => {
                this.setState({
                  initialData: {},
                  isCareTeamDrawer: false,
                });
              }}
              isFetchingUpsert={isFetchingUpsert}
            />
          </Drawer>
        )}
        {isCareTeamViewDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">External Care Team Member Details</span>
              </div>
            }
            placement="right"
            open={isCareTeamViewDrawer}
            width={800}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.setState({
                initialData: {},
                isCareTeamViewDrawer: false,
              });
            }}
          >
            <ExternalCareTeamMemberView
              IsAdmin={true}
              ExternalProviderData={initialData}
              ExternalProviderId={initialData.ExternalProviderId}
              onClose={() => {
                this.setState({
                  initialData: {},
                  isCareTeamViewDrawer: false,
                });
              }}
            />
          </Drawer>
        )}
        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            header={header}
            yesAction={() => {
              this.getGridData();
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  external_provider_list_get,
  external_staff_archive,
  external_provider_upsert,
  external_provider_get,
}) => {
  const { data: externalCareTeamData, isFetching: isFetchingList } = external_provider_list_get;
  const { data: externalCareTeamGetData } = external_provider_get;
  const { data: upsertExternalProviderData, isFetching } = external_provider_upsert;
  const { data: archiveExternalStaffData } = external_staff_archive;

  let externalClientCareTeam = [];
  let externalProviderInfo = null;
  let isArchived = false;
  let upsertMsg = false;
  let isUpsertSuccess = false;
  let rowData = false;
  if (externalCareTeamData && externalCareTeamData.ExternalProvider) {
    externalClientCareTeam = externalCareTeamData.ExternalProvider;
  }
  if (externalCareTeamGetData && externalCareTeamGetData.ExternalProvider) {
    externalProviderInfo = externalCareTeamGetData.ExternalProvider[0];
  }
  if (archiveExternalStaffData && archiveExternalStaffData.success) {
    isArchived = archiveExternalStaffData.success;
  }
  if (upsertExternalProviderData && upsertExternalProviderData.success) {
    upsertMsg = upsertExternalProviderData.result.Msg;
    isUpsertSuccess = upsertExternalProviderData.success;
    // upsertMsgInfo = upsertExternalProviderData.result.MsgInfo;
  }
  return {
    externalClientCareTeam,
    externalProviderInfo,
    isArchived,
    isFetchingUpsert: isFetching,
    upsertMsg,
    isUpsertSuccess,
    isFetchingList,
  };
};
export default connect(mapStateToProps)(withRouter(ExternalStaff));
