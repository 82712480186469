import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { StudentImage } from '../../../../component/student';
import Loader, { Loader2 } from '../../../../Components/Loader';
import '../../../../component/jquery/slick.js';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Table, Button } from 'antd';
import AntdModal from '../../../../Components/CustomModal';
import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  listSurveyQuestionCategory,
  surveyAssign,
  listSurveyQuestionAssessment,
  deactivateClientScreening,
} from '../../../../store/actions';
import { Link } from 'react-router-dom';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';
import QuestionType from './questionType/questionComponent';
import moment from 'moment';

class Survey extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      SurveyQuestionCategoryListData: [],
      assesmentId: '',
      screeningView: true,
      surveyListData: [],
      surveyQuestionAssessment: {},
      currentPage: 1,
    };
  }
  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var SurveyId = param.get('SurveyId');
    if (SurveyId) {
      SurveyId = parseInt(SurveyId);
    } else {
      SurveyId = -1;
    }
    return SurveyId;
  }
  onAssignSurvey = () => {
    var item = {
      studentIds: this.props.clientId,
      surveyQuestionCategoryIds: this.state.assesmentId,
    };

    this.props.dispatch(surveyAssign(item));
  };

  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }

  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }
  componentDidMount() {
    this.props.dispatch(
      listSurveyQuestionAssessment({
        json: JSON.stringify({
          Survey: [
            {
              SurveyId: this.getSurveyId(),
            },
          ],
        }),
      }),
    );
  }
  setValue({ response, surveyQuestionId, level, subLevel, subCatLevel, index }) {
    let surveyQuestionAssessment = this.state.surveyQuestionAssessment;
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };
    if (level === 0) {
      surveyQuestionAssessment.SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 1) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 2) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveySubCategory[
        subCatLevel
      ].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    }
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    this.setState({
      surveys: surveys,
    });

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index }) {
    let surveyQuestionAssessment = this.state.surveyQuestionAssessment;
    let json = {
      StudentId: this.props.clientId,
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };
    if (level === 0) {
      surveyQuestionAssessment.SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 1) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 2) {
      // let newState = Object.assign({}, this.state);
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveySubCategory[
        subCatLevel
      ].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    }
    const surveys = [...this.state.surveys];
    for (let survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    $('.main-banner').slick('slickNext');

    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }
  componentWillReceiveProps({
    surveys,
    isSurveyAssign,
    SurveyQuestionCategoryList,
    SurveyListData,
    surveyQuestionAssessment,
  }) {
    if (surveys.length > 0 && surveys.length != this.props.surveys.length) {
      for (var surveyIndex in surveys) {
        if (!surveys[surveyIndex].Response) {
          this.setState(
            {
              surveys: surveys,
              currentSlideIndex: parseInt(surveyIndex),
            },
            this.applySlick,
          );
          return;
        }
      }
    }
    if (
      surveyQuestionAssessment &&
      JSON.stringify(surveyQuestionAssessment) !=
        JSON.stringify(this.props.surveyQuestionAssessment)
    ) {
      this.setState({ surveyQuestionAssessment: surveyQuestionAssessment });
    }
    // if (surveyQuestionAssessment) {
    //
    //   this.toggleView();
    //   this.setState({ surveyQuestionAssessment: surveyQuestionAssessment });
    // }
    if (SurveyListData.length > 0 && SurveyListData.length != this.props.SurveyListData.length) {
      this.setState({ surveyListData: SurveyListData });
    }
    if (isSurveyAssign && isSurveyAssign != this.props.isSurveyAssign) {
      let getSurveyData = {
        json: JSON.stringify({
          Survey: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      };
      this.setState({
        assesmentId: '',
        currentPage: 1,
      });
      this.props.dispatch(listSurveyQuestionCategory(getSurveyData));
    }
  }

  render() {
    const {
      surveys,
      currentSlideIndex,
      surveyListData,
      screeningView,
      surveyQuestionAssessment,
    } = this.state;
    const {
      lastSlideIndex,
      SurveyQuestionCategoryList,
      isFetchingSurveyQuestionAssessment,
    } = this.props;

    return (
      <div class="vertical-tabs pat-tab print-survery-question-page" id="pagewidth">
        <div class="main survery-question-page  mb-5">
          {/* <Loader2 loading={this.props.isFetching} /> */}
          <div class="container-fluid">
            <div className="row">
              <div class="col-12 text-center">
                <Loader2 loading={this.props.isFetching} />
                <div class="survery-question-box " style={{ maxWidth: 'unset', marginTop: '50px' }}>
                  {/* <h3 class="mb-3 mb-md-5 ">{surveys.length > 0 && surveys[0].SurveyCategory}</h3> */}
                  <div className="screening-tool" id="sitecontent">
                    <div className="assessment-main-title d-flex align-items-center justify-content-between">
                      <div></div>
                      <h3 className="main-title-text">
                        {surveyQuestionAssessment.SurveyMainTitle}
                      </h3>
                      <div>
                        <div>
                          <button
                            onClick={() => window.print()}
                            className="btn black-btn px-4 print-btn"
                          >
                            <i class="fas fa-print mr-2"></i>
                            Print Screening
                          </button>
                        </div>
                      </div>
                    </div>
                    {surveyQuestionAssessment && surveyQuestionAssessment.SurveyQuestion && (
                      <div
                        style={{
                          padding: '25px',
                          margin: '0px 25px',
                        }}
                      >
                        <QuestionType
                          SurveyQuestion={surveyQuestionAssessment.SurveyQuestion}
                          SurveyId={surveyQuestionAssessment.SurveyId}
                          scope={this}
                          onNext={this.onNext}
                          setValue={this.setValue}
                          level={0}
                          isReadOnly={true}
                        />
                      </div>
                    )}
                    {surveyQuestionAssessment &&
                      surveyQuestionAssessment.SurveyCategory &&
                      surveyQuestionAssessment.SurveyCategory.map((surveyCategory, index) => {
                        return (
                          <div>
                            <div
                              className="assessment-sub-title"
                              style={{
                                margin: '0px 25px',
                              }}
                            >
                              <h5 className="sub-title-text">{surveyCategory.SurveyCategory}</h5>
                            </div>
                            <div
                              style={{
                                backgroundColor: '#F8F8F8',
                                padding: '25px',
                                margin: '0px 25px',
                              }}
                            >
                              <QuestionType
                                SurveyQuestion={surveyCategory.SurveyQuestion}
                                SurveyId={surveyQuestionAssessment.SurveyId}
                                scope={this}
                                onNext={this.onNext}
                                setValue={this.setValue}
                                level={1}
                                subLevel={index}
                                isReadOnly={true}
                              />

                              {surveyCategory &&
                                surveyCategory.SurveySubCategory &&
                                surveyCategory.SurveySubCategory.map((subCategory, subIndex) => {
                                  return (
                                    <div
                                      style={{
                                        backgroundColor: '#FFF',
                                        padding: '15px',
                                      }}
                                    >
                                      <div className="assessment-sub-title">
                                        <h5 className="sub-title-text">
                                          {subCategory.SurveySubCategory}
                                        </h5>
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor: '#FFF',
                                          padding: '15px',
                                          // margin: '0px 25px',
                                        }}
                                      >
                                        <QuestionType
                                          SurveyQuestion={subCategory.SurveyQuestion}
                                          SurveyId={surveyQuestionAssessment.SurveyId}
                                          scope={this}
                                          onNext={this.onNext}
                                          setValue={this.setValue}
                                          level={2}
                                          subLevel={index}
                                          subCatLevel={subIndex}
                                          isReadOnly={true}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  survery_question_get_multiple,
  list_survey_question_category,
  survey_assign,
  list_survey_question_assessment,
}) => {
  const { data, isFetching, error } = survery_question_get_multiple;
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: surveyAssignData } = survey_assign;
  const {
    data: listSurveyQuestionCategoryData,
    isFetching: isFetchingListSurvey,
  } = list_survey_question_category;
  var lastSlideIndex = 1;
  let SurveyQuestionCategoryList;
  let SurveyListData = [];
  let surveyData = [];
  let surveyQuestionAssessment;
  let isSurveyAssign = false;
  if (data) {
    let array = [];
    for (let index in data) {
      array.push(data[index]);
    }
    //array = array.slice(0, 10);

    array.push({});
    surveyData = [...array];
    lastSlideIndex = data.length - 2;
  }
  if (surveyQuestionAssessmentData) {
    surveyQuestionAssessment = surveyQuestionAssessmentData;
  }
  if (listSurveyQuestionCategoryData && listSurveyQuestionCategoryData.SurveyQuestionCategoryList) {
    SurveyQuestionCategoryList = listSurveyQuestionCategoryData.SurveyQuestionCategoryList;
  }
  if (listSurveyQuestionCategoryData && listSurveyQuestionCategoryData.SurveyList) {
    SurveyListData = listSurveyQuestionCategoryData.SurveyList;
  }
  if (surveyAssignData && surveyAssignData.success) {
    isSurveyAssign = surveyAssignData.success;
  }

  return {
    surveys: surveyData,
    surveyQuestionAssessment,
    isSurveyAssign,
    lastSlideIndex: lastSlideIndex,
    SurveyQuestionCategoryList: SurveyQuestionCategoryList || [],
    SurveyListData: SurveyListData,
    // isFetchingListSurvey: isFetchingListSurvey || isFetching || false,
    isFetching: isFetching || isFetchingListSurvey,
    isFetchingSurveyQuestionAssessment: isFetchingSurveyQuestionAssessment,
  };
};

export default connect(mapStateToProps)(Survey);
