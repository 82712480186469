import React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';
import Axios from 'axios';
import {
  augmentedSummaryGet,
  GPT3AnswerStatusUpsert,
  resetGPT3AnswerStatusUpsert,
} from '../../store/actions';
import AIAugmentedSummary from '../customScreens/surveyResponse/AIAugmentedSummary';
import AISummaryTimelineModal from './AISummaryTimelineModal';
import AntdModal from '../../Components/CustomModal';
import Loader, { Loader2 } from '../../Components/Loader';
import EnrolledDrawer from './EnrolledDrawer';
import TextareaAutosize from 'react-autosize-textarea';

class AugmentedSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAugmentedAIDrawerOpen: false,
      value: '',
    };
  }
  getLatestAugmentedSummary = () => {
    this.props.dispatch(
      augmentedSummaryGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
              UserGuid: this.props.UserGuid,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    const locationState = this.props.location.state;
    if (locationState && locationState.TriggerAIDrawer) {
      // to trigger AI drawer from any location
      this.toggleDrawer();
    }
    this.getLatestAugmentedSummary();
  }
  componentWillReceiveProps({ isUpsert, AugmentedWithAI, isAugmentedWithAI, LatestSurveyIds }) {
    if (isUpsert && isUpsert != this.props.isUpsert) {
      this.getLatestAugmentedSummary();
      this.props.dispatch(resetGPT3AnswerStatusUpsert());
    }
  }
  toggleDrawer = () => {
    this.setState({ isAugmentedAIDrawerOpen: !this.state.isAugmentedAIDrawerOpen });
  };
  actionTrigered = () => {
    this.props.dispatch(
      augmentedSummaryGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
              UserGuid: this.props.UserGuid,
            },
          ],
        }),
      }),
    );
    this.toggleDrawer();
  };
  onChangeNote = event => {
    const { value } = event.target;
    this.setState({
      value: value,
    });
  };
  saveSummary = () => {
    const { ClientId, UserGuid, augmentedSummary, SurveyId, SurveyIds } = this.props;
    let data = {
      Json: JSON.stringify({
        StudentId: ClientId,
        UserGuid: UserGuid,
        SurveyIds: SurveyId,
        SurveyIds: SurveyIds,
        GPT3QuestionAnswerIds: augmentedSummary.GPT3QuestionAnswerIds,
        GPT3: [
          {
            SurveyIds: SurveyId,
            SurveyIds: SurveyIds,
            GPT3QuestionAnswerId: augmentedSummary.GPT3QuestionAnswerId,
            GPT3QuestionId: augmentedSummary.GPT3QuestionId,
            IsAgreed: true,
            EditedAnswer: this.state.value,
          },
        ],
      }),
    };
    Axios.post('Survey/GPT3AnswerUpsert', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            GPT3Response: response.data.data,
          });
          this.getLatestAugmentedSummary();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    this.setState({
      isEditMode: false,
    });
  };
  AnswerStatusUpsert = () => {
    const { ClientId, UserGuid, augmentedSummary, SurveyId, SurveyIds } = this.props;
    let data = {
      Json: JSON.stringify({
        GPT3: [
          {
            SurveyId: SurveyId,
            SurveyIds: SurveyIds,
            GPT3QuestionAnswerId: augmentedSummary.GPT3QuestionAnswerId,
            IsSummary: true,
            GPT3QuestionId: augmentedSummary.GPT3QuestionId,
            IsAgreed: true,
          },
        ],
        StudentId: ClientId,
        UserGuid: UserGuid,
        SurveyIds: SurveyId,
        SurveyIds: SurveyIds,
        GPT3QuestionAnswerIds: augmentedSummary.GPT3QuestionAnswerIds,
      }),
    };
    this.props.dispatch(GPT3AnswerStatusUpsert(data));
  };
  render() {
    const {
      augmentedSummary,
      PreItakeSurveyId,
      SurveyId,
      isHistory,
      isFetching,
      isEnrolled,
    } = this.props;
    return (
      <div class="card border-0 students-augment-card mb-3">
        <div class="card-body block">
          <Loader2 loading={isFetching} />
          {augmentedSummary && augmentedSummary.ShortQuestion ? (
            <div className="ai-summary">
              <div className="ai-summary-action">
                <div>
                  <span className="title mr-2">{augmentedSummary.ShortQuestion}</span>
                  {isHistory && (
                    <AntdModal
                      modalWidth={800}
                      style={{ top: 20 }}
                      ModalButton={({ onClick }) => (
                        <span
                          title=" View Timeline"
                          onClick={() => {
                            onClick();
                          }}
                          style={{
                            color: '#009EF7',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}
                        >
                          View Timeline
                          <figure
                            class="action-button-icon"
                            style={{ display: 'inline-block', marginLeft: '5px' }}
                          >
                            <img src="images/view-answer.svg" alt="" width="15" />
                          </figure>
                        </span>
                      )}
                      ModalContent={props => <AISummaryTimelineModal {...props} />}
                    />
                  )}
                </div>
                <button
                  title="Augment with AI"
                  class="ai-btn"
                  onClick={this.toggleDrawer}
                  style={{
                    display: 'inline',
                    padding: '5px 20px',
                  }}
                >
                  <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                    <img src="images/AIStar.svg" alt="" width="23" />
                  </figure>
                  Augment with AI
                </button>
              </div>
              <div className="updated-by">
                <img src={augmentedSummary.ProfileImageURL || '/images/blank-profile.png'} />
                <span className="name mr-2">{augmentedSummary.UpdatedBy}</span>
                <span className="date">{augmentedSummary.UpdatedOn}</span>
              </div>
              {this.state.isEditMode ? (
                <div>
                  <span className="editing">Editing Now</span>
                  <div class="print-textarea-component">
                    <TextareaAutosize
                      style={{ minHeight: 20, maxHeight: 80, width: '100%', overflow: 'auto' }}
                      value={this.state.value}
                      id="question"
                      name="question"
                      rows={3}
                      maxRows={5}
                      placeholder="Enter Answer"
                      className="textarea-type"
                      onChange={this.onChangeNote}
                    />
                    <div
                      className="dictation-space d-flex justify-content-end ai-action"
                      style={{ paddingRight: '10px' }}
                    >
                      {/* <DictationComponent
                        handleDictate={event => {
                          this.handleDictate(event, uniqueId);
                        }}
                      /> */}
                      <button
                        style={{
                          marginLeft: '10px',
                          marginRight: '8px',
                          cursor: 'pointer',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          background: 'transparent',
                          border: '2px solid #ff7875',
                          color: 'rgb(191, 191, 191)',
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: '4px',
                        }}
                        onClick={() => {
                          this.setState({ value: '' });
                        }}
                      >
                        <img src="images/clear_icon.svg" />
                        <p
                          style={{
                            marginBottom: '0',
                            marginLeft: '7px',
                            color: '#ff7875',
                          }}
                        >
                          Clear
                        </p>
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary mr-2"
                        style={{
                          padding: '5px 18px',
                        }}
                        onClick={() => {
                          this.setState({
                            isEditMode: false,
                            value: augmentedSummary.OriginalAnswer || augmentedSummary.Answer,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn"
                        style={{
                          padding: '5px 18px',
                        }}
                        disabled={this.state.value.trim().length < 1}
                        onClick={this.saveSummary}
                      >
                        Save & Agree
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="ai-summary-text">
                  <span>{augmentedSummary.Answer && renderHTML(augmentedSummary.Answer)}</span>
                  {augmentedSummary.IsAgreed === null && (
                    <div className="gpt-answers justify-content-end">
                      <div className="ai-action">
                        <button
                          type="button"
                          class="btn d-flex justify-content-center align-items-center"
                          style={{
                            border: '1px solid #00DC31',
                            color: '#00DC31',
                            background: '#fff',
                          }}
                          onClick={this.AnswerStatusUpsert}
                        >
                          <img
                            src="images/outline_like.svg"
                            style={{
                              marginBottom: '3px',
                              marginRight: '2px',
                            }}
                          />
                          Agree
                        </button>
                        <button
                          type="button"
                          class="btn"
                          style={{
                            border: '1px solid #FF2C2C',
                            color: '#FF2C2C',
                            background: '#fff',
                          }}
                          onClick={() => {
                            this.setState({
                              isEditMode: true,
                              value: augmentedSummary.OriginalAnswer || augmentedSummary.Answer,
                            });
                          }}
                        >
                          <i class="fas fa-thumbs-down" style={{ paddingRight: 5 }}></i>
                          Disagree
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="block-action">
              <h6>Let’s get started with AI</h6>
              <span className="mb-2">
                Augment the client inputs to view the summary about the client
              </span>
              <button
                title="Augment with AI"
                class="ai-btn"
                onClick={this.toggleDrawer}
                style={{
                  display: 'inline',
                  padding: '5px 20px',
                }}
              >
                <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                  <img src="images/AIStar.svg" alt="" width="23" />
                </figure>
                Augment with AI
              </button>
            </div>
          )}
        </div>
        {this.state.isAugmentedAIDrawerOpen && (
          <Drawer
            className="custom-drawer client-augment"
            title="Augment with AI"
            placement="right"
            open={this.state.isAugmentedAIDrawerOpen}
            width={800}
            bodyStyle={{ paddingBottom: 80, background: '#fff' }}
            closable={true}
            destroyOnClose={true}
            onClose={() => {
              this.toggleDrawer();
            }}
          >
            <AIAugmentedSummary
              ClientId={this.props.ClientId}
              UserGuid={this.props.UserGuid}
              SurveyIds={SurveyId}
              toggleDrawer={this.toggleDrawer}
              IsUseSummary={true}
              isSurveyBanner={isEnrolled}
              isActionTrigered={this.actionTrigered}
            />
          </Drawer>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { augmented_summary_get, gpt3_answer_status_upsert } = state;
  const { data: AugmentedSummaryData, isFetching } = augmented_summary_get;
  const { data: upserData, isFetching: isFetchingUpsert } = gpt3_answer_status_upsert;

  let augmentedSummary = null;
  let isHistory = false;
  let isUpsert = false;

  if (AugmentedSummaryData && AugmentedSummaryData.Summary) {
    augmentedSummary = AugmentedSummaryData.Summary[0];
    isHistory = AugmentedSummaryData.History && AugmentedSummaryData.History.length > 0;
  }
  if (upserData && upserData.success) {
    isUpsert = true;
  }
  return {
    augmentedSummary,
    isHistory,
    isFetching: isFetching || isFetchingUpsert,
    isUpsert,
  };
};

export default connect(mapStateToProps)(withRouter(AugmentedSummary));
