import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import CustomModal from '../../../core/modal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { isComparer, isDateComparer } from '../../../util/handler';
import AntdModal from '../../../Components/CustomModal';
import {
  medicalProgramUpsert,
  resetMedicalProgramUpsert,
  medicalProgramGet,
  medicalProgramDelete,
  resetMedicalProgramDelete,
} from '../../../store/actions';
import { ReduxSelect } from '../../../component/custom-redux-components';
import SubmitButton from '../../../Components/submitButton';
import PageTitle from '../../../Components/PageTitle';
class MedicalProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      program: '',
      filterBy: 'Active',
      isModelOpen: false,
      header: '',
      deleteAction: '',
    };
  }
  componentDidMount() {
    this.getMedicalProgramList(0);
  }
  getMedicalProgramList = isArchived => {
    this.props.dispatch(
      medicalProgramGet({
        JsonInput: JSON.stringify({
          MedicalProgram: [
            {
              IsArchived: isArchived || 0,
            },
          ],
        }),
      }),
    );
  };
  componentWillReceiveProps({ isAddMedicalProgram, isDeleteMedicalProgram }) {
    if (isAddMedicalProgram && isAddMedicalProgram != this.props.isAddMedicalProgram) {
      this.setState({ program: '' });
      this.toggle('Medical Program Added Successfully');
      this.props.dispatch(resetMedicalProgramUpsert());
      this.getMedicalProgramList(0);
    }
    if (isDeleteMedicalProgram && isDeleteMedicalProgram != this.props.isDeleteMedicalProgram) {
      this.toggle(`Medical Program ${this.state.deleteAction} Successfully`);
      let type = this.state.deleteAction === 'archived' ? 0 : 1;
      this.getMedicalProgramList(type);
      this.props.dispatch(resetMedicalProgramDelete());
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const { program, filterBy } = this.state;
    const { archivedData, medicalProgram } = this.props;

    const programColumns = [
      {
        title: 'Medical Program',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <Tooltip placement="top" title="Archive">
                      <img src="images/archive.svg" width="25" />
                    </Tooltip>
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to archive this medical program?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            medicalProgramDelete({
                              JsonInput: JSON.stringify({
                                MedicalProgram: [
                                  {
                                    MedicalProgramId: record.Value,
                                    IsArchived: 1,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'archived';
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    const archivedMedicalProgram = [
      {
        title: 'Medical Program Archived',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="images/restore_icon.svg" width="85" />
                    {/* <i class="far fa-trash-alt"></i> */}
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to restore this medical program?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            medicalProgramDelete({
                              JsonInput: JSON.stringify({
                                MedicalProgram: [
                                  {
                                    MedicalProgramId: record.Value,
                                    IsArchived: 0,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'restored';
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    return (
      <div>
        <PageTitle TitleText="Miscellaneous" />
        <div class="content-area  setting-gap pl-0">
          <div class="mb-3">
            <h6>Medical Program</h6>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="enrollment-form">
                <div class="form-group">
                  <input
                    className={`form-control ${program && 'not-empty'}`}
                    value={program}
                    onChange={e => this.setState({ program: e.target.value })}
                  />
                  <label className="placeholder-label">Medical Program</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <SubmitButton
                loading={this.props.isFetching}
                onClick={() => {
                  this.props.dispatch(
                    medicalProgramUpsert({
                      JsonInput: JSON.stringify({
                        MedicalProgram: [
                          {
                            MedicalProgramId: null,
                            Description: program.trim(),
                          },
                        ],
                      }),
                    }),
                  );
                }}
                disabled={!program.trim()}
                value={'Add'}
                style={{ height: '45px', padding: '0 45px' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <div className="text-left app-subscriber-page d-inline-block">
              <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                <ReduxSelect
                  options={[
                    { Text: 'Active', Value: 'Active' },
                    { Text: 'Archived', Value: 'Archived' },
                  ]}
                  onChange={val => {
                    this.setState({
                      filterBy: val,
                    });
                    this.props.dispatch(
                      medicalProgramGet({
                        JsonInput: JSON.stringify({
                          MedicalProgram: [
                            {
                              IsArchived: val === 'Active' ? 0 : 1,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterBy}
                  placeholder="Filter By"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        {filterBy === 'Active' ? (
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    {/* <Loader2 loading={isFetching} /> */}
                    <Table
                      columns={programColumns}
                      dataSource={medicalProgram}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col-12 mb-5">
              <div class=" border-0 mt-5 mx-2 my-2">
                <div class="row">
                  <div class="col-12">
                    {/* <Loader2 loading={isFetching} /> */}
                    <Table
                      columns={archivedMedicalProgram}
                      dataSource={medicalProgram || []}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { medical_program_upsert, medical_program_get, medical_program_delete } = state;
  const { data: addMedicalProgramData, isFetching, error } = medical_program_upsert;
  const { data: listMedicalProgramData } = medical_program_get;
  const { data: deleteMedicalProgramData } = medical_program_delete;

  let isAddMedicalProgram = false;
  let isDeleteMedicalProgram = false;
  let medicalProgram = [];
  if (listMedicalProgramData && listMedicalProgramData.data) {
    medicalProgram = listMedicalProgramData.data;
  }
  if (addMedicalProgramData && addMedicalProgramData.success == true) {
    isAddMedicalProgram = addMedicalProgramData.success;
  }
  if (deleteMedicalProgramData && deleteMedicalProgramData.success) {
    isDeleteMedicalProgram = deleteMedicalProgramData.success;
  }

  return {
    isAddMedicalProgram,
    // error,
    isDeleteMedicalProgram,
    // noteType,
    // archivedData: archivedData || [],
    medicalProgram,
    isFetching,
  };
};
const ReduxMedicalProgram = connect(mapStateToProps)(withRouter(MedicalProgram));

export default connect()(ReduxMedicalProgram);
