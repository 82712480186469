import React, { Component } from 'react';
import { Bar, HorizontalBar, Line, Doughnut, Pie } from 'react-chartjs-2';
import {
  // barData,
  barOptions,
  // horizontalBarData,
  horizontalBarOptions,
  // lineData,
  // lineData2,
  lineOptions,
  // pieData,
  pieOptions,
  // doughnutData,
  doughnutOptions,
} from './chartData';
import { getDashboardLoadAppUsers } from '../store/actions';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

class AppUsersOld extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(getDashboardLoadAppUsers());
  }

  render() {
    const { appUsersInfo, isFetching } = this.props;
    var earnedFee = '';
    var earnedFeeChart = [];
    var avgSevIndex = '';
    var avgSevIndexChart = [];
    var paid = null;
    var free = null;
    var activeUsers = null;
    var inactiveUsers = null;
    var totalConnections = 0;
    var totalConnectionsChart = [];
    var newStudentEnroll = 0;
    var newStudentEnrollChart = [];
    var doughnutData = {
      labels: [],
      datasets: [
        {
          data: [0, 0],
          backgroundColor: ['#36A2EB', '#FF6384'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384'],
          borderWidth: 0,
        },
      ],
    };
    var pieData = {
      labels: [],
      datasets: [
        {
          data: [0, 0],
          backgroundColor: ['#FF6384', '#36A2EB'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB'],
          borderWidth: 0,
        },
      ],
    };
    var barData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd'],
        },
      ],
    };
    var horizontalBarData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0, 0],
          backgroundColor: ['#ff5d5e', '#ff5d5e', '#30e191', '#48b4ff', '#48b4ff', '#30e191'],
        },
      ],
    };
    var lineData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['gradientFill'],
        },
      ],
    };

    var lineData2 = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['gradientFill'],
        },
      ],
    };
    if (appUsersInfo && appUsersInfo.BlocksData) {
      for (var block of appUsersInfo.BlocksData) {
        if (block.Label == 'EARNED FEE') {
          earnedFee = block.Score;
        } else if (block.Label == 'AVG SEVERITY INDEX') {
          avgSevIndex = block.Score;
        } else if (block.Label == 'Paid Users') {
          paid = parseInt(block.Score);
          doughnutData.datasets[0].data[1] = paid;
        } else if (block.Label == 'Free Users') {
          free = parseInt(block.Score);
          doughnutData.datasets[0].data[0] = free;
        } else if (block.Label == 'Active Users') {
          activeUsers = parseInt(block.Score);
          if (isNaN(activeUsers)) {
            activeUsers = 0;
          }
          pieData.datasets[0].data[0] = activeUsers;
        } else if (block.Label == 'Inactive Users') {
          inactiveUsers = parseInt(block.Score);
          if (isNaN(inactiveUsers)) {
            inactiveUsers = 0;
          }
          pieData.datasets[0].data[1] = inactiveUsers;
        } else if (block.Label == 'TOTAL CONNECTIONS') {
          totalConnections = parseInt(block.Score);
        } else if (block.Label == 'NEW USERS ENROLLED') {
          newStudentEnroll = parseInt(block.Score);
        }
      }

      for (var chartData of appUsersInfo.LineChartData) {
        if (chartData.DataFor == 'EARNED FEE') {
          earnedFeeChart = chartData.DataAgg.split(',');
        } else if (chartData.DataFor == 'AVG SEVERITY INDEX') {
          avgSevIndexChart = chartData.DataAgg.split(',');
        } else if (chartData.DataFor == 'TOTAL CONNECTIONS') {
          totalConnectionsChart = chartData.DataAgg.split(',');
        } else if (chartData.DataFor == 'NEW STUDENTS ENROLLED') {
          newStudentEnrollChart = chartData.DataAgg.split(',');
        }
      }

      for (var chartData of appUsersInfo.ChartData) {
        if (chartData.Title == 'SOBER PEER BEHAVIORAL RATING') {
          barData.labels = chartData.Labels.split(',');
          barData.datasets[0].data = chartData.DataValues.split(',');
        } else if (chartData.Title == 'SOBRIETY METRICS') {
          horizontalBarData.labels = chartData.Labels.split(',');
          horizontalBarData.datasets[0].data = chartData.DataValues.split(',');
        } else if (chartData.Title == 'ENGAGEMENT METRICS') {
          lineData.labels = chartData.Labels.split(',');
          lineData.datasets[0].data = chartData.DataValues.split(',');
        } else if (chartData.Title == 'INTERACTION METRICS') {
          lineData2.labels = chartData.Labels.split(',');
          lineData2.datasets[0].data = chartData.DataValues.split(',');
        }
      }
    }

    return (
      <>
        <h3 class="page-title mb-4">Quick Scores</h3>
        <Loader loading={isFetching} />
        <div class="row">
          <div class="col-sm-6 col-xl-3 mb-4">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-12 pt-3">
                  <h6 class="text-uppercase font-weight-bold mb-3">Earned fee</h6>
                </div>
                <div class="col-xl-8 col-8">
                  {earnedFee ? (
                    <span class="value">{formatter.format(earnedFee)}</span>
                  ) : (
                    <span class="value">{formatter.format(0)}</span>
                  )}
                </div>
                <div class="col-xl-4 col-4">
                  <div class="graph-img">
                    <Sparklines data={earnedFeeChart}>
                      <SparklinesLine color="#179bef" />
                    </Sparklines>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xl-3 mb-4">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-12 pt-3">
                  <h6 class="text-uppercase font-weight-bold mb-3">Avg. Severity Index</h6>
                </div>
                <div class="col-xl-8 col-8">
                  <span class="value">{Math.floor(avgSevIndex)}</span>
                </div>
                <div class="col-xl-4 col-4">
                  <div class="graph-img">
                    <Sparklines data={avgSevIndexChart}>
                      <SparklinesLine color="#179bef" />
                    </Sparklines>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xl-3 mb-4 ">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-sm-8 col-12 ">
                  <div class="pt-1">
                    <h6 class="text-uppercase font-weight-bold mb-3">Paid vs Free</h6>
                  </div>
                  <ul class="mt-2 p-0 chart-bullet">
                    <li> {paid} Paid Users</li>
                    <li> {free} Free Users</li>
                  </ul>
                </div>
                <div class="col-sm-4 col-12">
                  {!paid && !free ? (
                    ''
                  ) : (
                    <Doughnut
                      data={doughnutData}
                      height={144}
                      width={144}
                      options={doughnutOptions}
                      className="h-100"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xl-3 mb-4">
            <div class="card border-0 h-100 inner-content">
              <div class="row card-body align-items-center">
                <div class="col-sm-8 col-12">
                  <div class="pt-1">
                    <h6 class="text-uppercase font-weight-bold mb-3">Users</h6>
                  </div>
                  <ul class="mt-2 p-0 chart-bullet">
                    <li> {activeUsers} Active Users </li>
                    <li> {inactiveUsers} Inactive Users</li>
                  </ul>
                </div>
                <div class="col-sm-4 col-12">
                  {!activeUsers || !inactiveUsers ? (
                    ''
                  ) : (
                    <Pie data={pieData} height={201} width={201} options={pieOptions} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6  mb-4">
            <div class="card border-0 h-100 pt-3 pb-3 ">
              <div class="row align-items-center card-body">
                <div class="col-lg-2 col-sm-2  text-md-center ">
                  <img src="images/connection.svg" width="59" class="" alt="" />
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="student-info">
                    <h2>{totalConnections}</h2>
                    <span class="text-uppercase h5">Total connections</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 text-md-right">
                  <Sparklines data={totalConnectionsChart}>
                    <SparklinesLine color="#179bef" />
                  </Sparklines>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6  mb-4">
            <div class="card border-0 h-100 pt-3 pb-3 ">
              <div class="row align-items-center card-body">
                <div class="col-lg-2  col-sm-2 text-md-center">
                  <img src="images/new-students.svg" alt="" width="58" />
                </div>
                <div class="col-lg-6  col-sm-6">
                  <div class="student-info">
                    <h2>{newStudentEnroll}</h2>
                    <span class="text-uppercase h5">New Users Enrolled</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 text-md-right">
                  <Sparklines data={newStudentEnrollChart}>
                    <SparklinesLine color="#179bef" />
                  </Sparklines>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-4 ">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 mb-lg-3">
                  <h5 class="text-uppercase pt-4">SOBER PEER BEHAVIORAL RATING</h5>
                </div>
                <div class="col-12">
                  <Bar data={barData} options={barOptions} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 ">
                  <h5 class="text-uppercase pt-4">Sobriety Metrics</h5>
                </div>
                <div class="col-12">
                  <HorizontalBar data={horizontalBarData} options={horizontalBarOptions} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 mb-lg-3">
                  <div class="row pt-4">
                    <div class="col-12 col-lg-8">
                      <h5 class="text-uppercase ">Engagement Metrics</h5>
                    </div>
                    <div class="form-group col-lg-4">
                      <select class="custom-select">
                        <option selected="">Weekly </option>
                        <option value="">Monthly</option>
                        <option value="">Last week</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <Line data={lineData} options={lineOptions} />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card border-0 ">
              <div class="row card-body">
                <div class="col-12 mb-lg-3">
                  <div class="row pt-4">
                    <div class="col-12 col-lg-8">
                      <h5 class="text-uppercase ">Interaction Metrics</h5>
                    </div>
                    <div class="form-group col-lg-4">
                      <select class="custom-select">
                        <option selected="">Monthly</option>
                        <option value="">Last week</option>
                        <option value="">Weekly</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <Line data={lineData2} options={lineOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ get_dashboard_load_app_users }) => {
  var appUsersInfo = null;
  var { isFetching } = get_dashboard_load_app_users;
  if (get_dashboard_load_app_users && get_dashboard_load_app_users.data) {
    appUsersInfo = get_dashboard_load_app_users.data.DashboardAppUserMetrics[0];
  }

  return {
    appUsersInfo,
    isFetching,
  };
};

export default connect(mapStateToProps)(withRouter(AppUsersOld));
