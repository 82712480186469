import React, { Component, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import moment from 'moment';
import { Field, reduxForm, formValueSelector, isDirty, reset } from 'redux-form';

import Loader, { Loader2 } from '../../../Components/Loader';
import {
  appUserDemographicGet,
  appUserDemographicUpsert,
  loadCaseProfile,
  listCombos,
  userTagTypeComboListGet,
} from '../../../store/actions';
import CustomModal from '../../../core/modal';
import AntdModal from '../../../Components/CustomModal';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../core/dynamic-form';
import validate, {
  dateofbirthNormalize,
  dobStudent,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from '../../RCM/validation/validate';
import { ProcessCombos } from '../../../store/comboConfig';
import { stringDate } from '../../../util/date';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxCreatableSelect,
} from '../../../component/custom-redux-components';
import SubmitButton from '../../../Components/submitButton';
// import ProfileHeader from "../../customScreens/profileHeader2";
class DemographicsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [
        {
          name: 'State',
          fieldName: 'State Or Province',
          options: this.props.hi,
          filterBy: 'country',
          defaultFilterBy: 'USA',
          // isRequired: true,
        },
        {
          name: 'SSN',
          fieldName: 'SSN',
          // fieldName: true,
          validate: [validate.ssn],
          format: ssnFormatter,
          normalize: ssnNormalize,
          placeholder: 'SSN',
          isRequired: true,
        },
      ],
    };
  }
  componentDidMount() {
    let data = {
      JsonInput: JSON.stringify({
        UserTagType: [
          {
            UserGuid: this.props.UserGuid,
          },
        ],
      }),
    };
    this.props.dispatch(userTagTypeComboListGet(data));
    this.props.dispatch(
      listCombos({
        comboTypes: 'UserBadge',
      }),
    );
  }

  render() {
    const {
      onSaveClick,
      onSubmit,
      handleSubmit,
      initialize,
      onCancel,
      history,
      dispatch,
      isFetching,
      Filter_StateProvince,
      Center,
      ReferenceSource,
      // Country,
      HomeAddressType,
      Counsellor,
      UserGuid,
      UserStatusList,
      ParticipationStatusList,
      ExternalIdLabel,
      ShowProgramType,
      ProgramTypeList,
      // ClinicalCenter,
      handelCenterChange,
      AdvisorIdLabel,
      isCenterChanged,
      AppAdvisor,
      AdvisorList,
      ProgramType,
      userTags,
    } = this.props;
    return (
      <>
        <div class="main enrollment-page mb-2">
          <div class="">
            <form onSubmit={handleSubmit(onSaveClick)}>
              {/* <div class="row">
                <div class="col-12 mb-5">
                  <div className="card border-0">
                    <div className="card-body enrollment-form">
                      <div class="row pb-5 pl-3 pr-3 pt-3">
                        <Loader2 loading={isFetching} />
                        <DynamicForm formFields={this.state.formFields} />
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div class="row enrollment-form p-4" style={{ background: 'white' }}>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="FirstName"
                      type="text"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'First Name'}
                    />
                    <label class="placeholder-label">First Name*</label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="MiddleName"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Middle Name'}
                    />
                    <label class="placeholder-label">Middle Name</label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="LastName"
                      type="text"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Last Name'}
                    />
                    <label class="placeholder-label">Last Name*</label>
                  </div>
                </div>
                {/* <div class="col-12 col-md-4">
                  <div class="form-group">
                    <Field
                      name="HomeAddressType"
                      type="text"
                      placeholder="Home Address Type"
                      options={HomeAddressType || []}
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'Home Address Type'}
                      // disabled={disable}
                    />
                  </div>
                </div> */}
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="AddressLine1"
                      type="text"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Permanent Address Line 1(Last Known Address)'}
                    />
                    <label class="placeholder-label">
                      Permanent Address Line 1(Last Known Address)*
                    </label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="AddressLine2"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Permanent Address Line 2'}
                    />
                    <label class="placeholder-label">Permanent Address Line 2</label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="City"
                      type="text"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'City'}
                    />
                    <label class="placeholder-label">City*</label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="State"
                      type="text"
                      options={
                        (Filter_StateProvince &&
                          Filter_StateProvince.USA &&
                          Filter_StateProvince.USA) ||
                        []
                      }
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'State Or Province'}
                      placeholder="State/Province*"
                    />{' '}
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="ZipCode"
                      type="text"
                      component={ReduxInput}
                      validate={[validate.usaZip, validate.required]}
                      fieldName={'Zip Code'}
                    />
                    <label class="placeholder-label">Postal/Zip Code*</label>
                  </div>
                </div>

                {/* <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="AdmissionDate"
                      type="text"
                      component={ReduxDateInput}
                      validate={validate.required}
                      fieldName={'Admission Date'}
                      label={'Admission Date*'}
                    />
                  </div>
                </div> */}

                <div class="col-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <Field
                      name={`SSN`}
                      type="text"
                      component={ReduxInput}
                      validate={validate.ssn9DigitRequired}
                      format={ssnFormatter}
                      normalize={ssnNormalize}
                      fieldName={true}
                    />
                    <label class="placeholder-label">National ID (SSN)</label>
                  </div>
                </div>

                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="CenterId"
                      type="text"
                      placeholder={'Center'}
                      options={Center || []}
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'Center'}
                      // disabled={disable}
                      onChange={centerId => {
                        dispatch(
                          listCombos({
                            comboTypes: 'AppAdvisor',
                            Json: JSON.stringify({ AppAdvisor: [{ CenterId: centerId }] }),
                          }),
                        );
                        this.props.change(`AdvisorStaffId`, null);
                        handelCenterChange(true);
                      }}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <Field
                      // name="ProbationOfficer"
                      name="AdvisorStaffId"
                      type="text"
                      placeholder={AdvisorIdLabel}
                      options={isCenterChanged ? AppAdvisor : AdvisorList || []}
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={AdvisorIdLabel}
                      // disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="ReferralSourceId"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Referral Source'}
                      options={ReferenceSource || []}
                      placeholder="Referral Source"
                    />
                  </div>
                </div>

                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="DateOfBirth"
                      type="text"
                      component={ReduxDateInput}
                      label={'Date of Birth'}
                      normalize={dateofbirthNormalize}
                      placeholder="MM/DD/YYYY"
                      disabled
                      // minDate={new Date(2016, 0, 1)}
                    />
                    <i class="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="PrimaryPhone"
                      type="text"
                      component={ReduxInput}
                      // validate={[validate.required, validate.NumericMobile]}
                      fieldName={'Phone'}
                      format={MobileFormatter}
                      normalize={MobileNormalize}
                      disabled
                    />
                    <label class="placeholder-label">Phone</label>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="Email"
                      class="form-control"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Email'}
                    />
                    <label class="placeholder-label">Email</label>
                  </div>
                </div>
                {/* <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="AdvisorStaffId"
                      type="text"
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'Primary Counselor(s) Name'}
                      options={Counsellor || []}
                      placeholder="Primary Counselor(s) Name"
                      // options={[]}
                    />
                  </div>
                </div> */}
                {/* <div class="col-12 col-lg-4">
                  <div class="form-group">
                    <Field
                      name="SecondaryCounselors"
                      type="text"
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'Secondary Counselors'}
                      options={Counsellor || []}
                      // options={[]}
                    />
                    <label class="placeholder-label">Secondary Counselors</label>
                  </div>
                </div> */}
                <div class="col-12 col-lg-4">
                  <div class="form-group">
                    {/* <Field
                      name="CaseID"
                      type="text"
                      component={ReduxInput}
                      fieldName={ExternalIdLabel}
                    />
                    <label class="placeholder-label">{ExternalIdLabel}</label> */}
                    <Field
                      name="ExternalId"
                      type="text"
                      component={ReduxInput}
                      fieldName={'External Id'}
                    />
                    <label class="placeholder-label">{ExternalIdLabel}</label>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <Field
                      name="Status"
                      type="text"
                      placeholder={'Status'}
                      options={UserStatusList || []}
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'Status'}
                      // disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <Field
                      name="ParticipationStatus"
                      type="text"
                      placeholder={'Participation Status'}
                      options={ParticipationStatusList || []}
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'Participation Status'}
                      // disabled={disable}
                    />
                  </div>
                </div>

                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <Field
                      name="ProgramType"
                      type="text"
                      placeholder="Program"
                      options={ProgramType || []}
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'Program'}
                      // disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <Field
                      name="BadgeValue"
                      type="text"
                      placeholder="Badge*"
                      options={[
                        {
                          Value: 'P',
                          Text: 'Peer',
                          ValueColumnName: 'UserBadge',
                          Sequence: 4,
                          IsArchive: null,
                          IsDefault: null,
                        },
                        {
                          Value: 'F',
                          Text: 'Concerned',
                          ValueColumnName: 'UserBadge',
                          Sequence: 5,
                          IsArchive: null,
                          IsDefault: null,
                        },
                      ]}
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'BadgeValue'}
                      // disabled={disable}
                    />
                  </div>
                </div>

                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <Field
                      name="UserTagIds"
                      type="text"
                      placeholder="User Tags"
                      options={userTags}
                      component={ReduxCreatableSelect}
                      // validate={validate.required}
                      fieldName={'UserTagIds'}
                      isMulti={true}
                      // isCreatableSelect={true}
                      // disabled={disable}
                    />
                  </div>
                </div>

                <div class="col-12 text-right mt-5">
                  <input
                    type="button"
                    class="btn btn-outline-primary px-5"
                    value="Cancel"
                    onClick={onCancel}
                  />
                  &nbsp;
                  <SubmitButton
                    loading={isFetching}
                    type="submit"
                    style={{ height: '42px' }}
                    className="btn px-5"
                    value="Save"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPropsForm = state => {
  const { combos, user_app_link_validate, app_user_demographic_upsert } = state;
  const { isFetching } = app_user_demographic_upsert;
  return {
    isFetching,
  };
};

const ReduxDemographicsForm = reduxForm({
  form: 'subDemographicsForm',
  enableReinitialize: true,
})(connect(mapStateToPropsForm)(withRouter(DemographicsForm)));
const selector = formValueSelector('subDemographicsForm');

class SubscriberDemographics extends Component {
  constructor() {
    super();
    this.state = {
      caseDetail: {},
      isModelOpen: false,
      isCenterChanged: false,
    };
  }

  componentDidMount() {
    var result = ProcessCombos(
      'Filter_StateProvince,Center,Counsellor,ReferenceSource,Country,HomeAddressType,RelationShip,ClinicalCenter,CenterProgramType',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    if (this.props.UserGuid) {
      let data = {
        Json: JSON.stringify({
          AppUser: [
            {
              UserGuid: this.props.UserGuid,
            },
          ],
        }),
      };

      this.props.dispatch(appUserDemographicGet(data));
      this.props.dispatch(loadCaseProfile({ UserGuid: this.props.UserGuid }));
    }
  }
  componentWillReceiveProps({ isAdded, userDataInfo, currentCenterId }) {
    if (isAdded && isAdded != this.props.isAdded) {
      this.toggle('Updated Successfully');
    }
    if (userDataInfo && JSON.stringify(userDataInfo) != JSON.stringify(this.props.userDataInfo)) {
      const {
        UserGuid,
        ClientId,
        FirstName,
        LastName,
        MiddleName,
        AddressLine1,
        AddressLine2,
        City,
        State,
        ZipCode,
        AdmissionDate,
        CenterId,
        ReferralSourceId,
        SSN,
        DateOfBirth,
        PrimaryPhone,
        Email,
        PractitionerId,
        ExternalId,
        Status,
        ParticipationStatus,
        ProgramType,
        AdvisorStaffId,
        BadgeValue,
        UserTagType,
      } = userDataInfo;
      let data = {
        UserGuid,
        ClientId,
        FirstName,
        LastName,
        MiddleName,
        AddressLine1,
        AddressLine2,
        City,
        State,
        ZipCode,
        AdmissionDate,
        CenterId,
        ReferralSourceId,
        SSN,
        DateOfBirth,
        PrimaryPhone,
        Email,
        PractitionerId,
        ExternalId,
        Status,
        ParticipationStatus,
        ProgramType,
        AdvisorStaffId,
        BadgeValue,
        UserTagIds: UserTagType,
      };
      this.setState({
        userDataInfo: data,
      });
    }
    // if (currentCenterId && currentCenterId != this.props.currentCenterId) {
    //   this.props.dispatch(
    //     listCombos({
    //       comboTypes: 'AppAdvisor',
    //       Json: JSON.stringify({ AppAdvisor: [{ CenterId: currentCenterId }] }),
    //     }),
    //   );
    // }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  onSubmit(values) {
    // let userGuid = null;
    // if (this.props.UserGuid) {
    //   userGuid = this.props.UserGuid;
    // }

    const { UserTagIds } = values;
    let newUserTagArray = [];
    let valueArray = Object.keys(values);
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object' && key != 'UserTagIds') {
        obj[key] = values[key].map(item => item.Value).toString();
      } else if (key != 'UserTagIds') {
        obj[key] = values[key];
      }
    }

    if (UserTagIds && UserTagIds.length > 0) {
      UserTagIds.map(item => {
        newUserTagArray.push({
          UserTagTypeId: item.__isNew__ ? null : item.Value,
          Description: item.__isNew__ ? item.label : item.Text,
        });
      });
    }
    const data = {
      AppUser: [
        {
          UserGuid: this.props.UserGuid || null,
          ClientId: null,
          ...obj,
          UseTagType: [...newUserTagArray],
          // AdmissionDate: values.AdmissionDate && stringDate(values.AdmissionDate),
        },
      ],
    };
    var jsonData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(appUserDemographicUpsert(jsonData));
  }

  handelCenterChange = isUpdated => {
    this.setState({
      isCenterChanged: isUpdated,
    });
  };

  render() {
    const {
      Filter_StateProvince,
      Center,
      ReferenceSource,
      ClinicalCenter,
      Country,
      HomeAddressType,
      Counsellor,
      currentCenterId,
      AppAdvisor,
      ExternalIdLabel,
      ShowProgramType,
      ProgramTypeList,
      AdvisorIdLabel,
      AdvisorList,
      ProgramType,
    } = this.props;

    return (
      <React.Fragment>
        {HomeAddressType && HomeAddressType.length > 0 && (
          <ReduxDemographicsForm
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.state.userDataInfo}
            onCancel={() => this.props.history.goBack()}
            UserGuid={this.props.UserGuid}
            UserStatusList={this.props.UserStatusList}
            ParticipationStatusList={this.props.ParticipationStatusList}
            userTags={this.props.userTags}
            Filter_StateProvince={Filter_StateProvince}
            Center={Center}
            ReferenceSource={ReferenceSource}
            ClinicalCenter={ClinicalCenter}
            Country={Country}
            HomeAddressType={HomeAddressType}
            Counsellor={Counsellor}
            AppAdvisor={AppAdvisor}
            ExternalIdLabel={ExternalIdLabel}
            ShowProgramType={ShowProgramType}
            handelCenterChange={this.handelCenterChange}
            AdvisorIdLabel={AdvisorIdLabel}
            isCenterChanged={this.state.isCenterChanged}
            AdvisorList={AdvisorList}
            ProgramTypeList={ProgramTypeList}
            ProgramType={ProgramType}
          />
        )}{' '}
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            let data = {
              JsonInput: JSON.stringify({
                UserTagType: [
                  {
                    UserGuid: this.props.UserGuid,
                  },
                ],
              }),
            };
            this.props.dispatch(userTagTypeComboListGet(data));
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    app_user_demographic_upsert,
    app_user_demographic_get,
    load_case_profile,
    combos,
    user_tag_type_combo_list_get,
  } = state;
  var { data } = app_user_demographic_upsert;
  var { isFetching, data: userData } = app_user_demographic_get;
  const { data: caseData, isFetching: isCaseFetching } = load_case_profile;
  const { data: userTagData } = user_tag_type_combo_list_get;
  const currentCenterId = formValueSelector('subDemographicsForm')(state, 'CenterId');

  let isAdded = false;
  let userDataInfo = null;

  let UserStatusList = null;
  let ParticipationStatusList = null;
  let ExternalIdLabel = null;
  let ShowProgramType = null;
  let ProgramTypeList = null;
  let AdvisorIdLabel = null;
  let AdvisorList = null;
  let AppAdvisor = null;
  let userTags = [];

  let Country = [],
    ClinicalCenter = [],
    ClientStatus = [],
    Center = [],
    ReferenceSource = [],
    HomeAddressType = [],
    Counsellor = [],
    Filter_StateProvince = {},
    ProgramType = [];

  if (userTagData && userTagData.UserTagType) {
    userTags = userTagData.UserTagType;
  }

  if (data && data.success) {
    isAdded = data.success;
  }
  if (userData && userData.UserGuid) {
    userDataInfo = userData;
  }
  if (caseData && caseData.AppUserMiscInfo) {
    UserStatusList =
      (caseData.AppUserMiscInfo[0] && caseData.AppUserMiscInfo[0].UserStatusList) || [];
    ParticipationStatusList =
      (caseData.AppUserMiscInfo[0] && caseData.AppUserMiscInfo[0].ParticipationStatusList) || [];
    ExternalIdLabel =
      (caseData.AppUserMiscInfo[0] && caseData.AppUserMiscInfo[0].ExternalIdLabel) || 'External Id';
    ShowProgramType = caseData.AppUserMiscInfo[0] && caseData.AppUserMiscInfo[0].ShowProgramType;
    ProgramTypeList =
      (caseData.AppUserMiscInfo[0] && caseData.AppUserMiscInfo[0].ProgramTypeList) || [];
    AdvisorIdLabel =
      (caseData.AppUserMiscInfo[0] && caseData.AppUserMiscInfo[0].AdvisorIdLabel) || [];
    AdvisorList = (caseData.AppUserMiscInfo[0] && caseData.AppUserMiscInfo[0].AdvisorList) || [];
  }
  if (combos.data && combos.data.ReferenceSource) {
    Filter_StateProvince = combos.data['Filter_StateProvince'];
    Center = combos.data['Center'];
    ReferenceSource = combos.data['ReferenceSource'];
    ClinicalCenter = combos.data['ClinicalCenter'];
    Country = combos.data['Country'];
    HomeAddressType = combos.data['HomeAddressType'];
    Counsellor = combos.data['Counsellor'];
    ProgramType = combos.data['CenterProgramType'];
  }

  return {
    UserStatusList,
    ParticipationStatusList,
    isAdded,
    userDataInfo,
    isFetching,
    Filter_StateProvince,
    Center,
    ReferenceSource,
    ClinicalCenter,
    Country,
    HomeAddressType,
    Counsellor,
    currentCenterId,
    ExternalIdLabel,
    ShowProgramType,
    ProgramTypeList,
    AdvisorIdLabel,
    AppAdvisor: combos.data && combos.data.AppAdvisor,
    AdvisorList,
    ProgramType,
    userTags,
  };
};
export default connect(mapStateToProps)(withRouter(SubscriberDemographics));
