import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import validate from '../validation/validate';
import renderField from '../reduxFormComponent/renderField';
import {
  ReduxInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxTextarea,
  ReduxToggle,
} from '../../../component/custom-redux-components';
import ToggleNote from '../toggleNote';

const PaymentForCare = ({
  fields,
  name,
  meta: { touched, error },
  FinancialResponsibilityType,
  Frequency,
  existingRows,
  disable,
  haveAccessToProspectField,
  hideQuestions,
  isFinancialTabRequired,
}) => {
  return (
    <>
      {fields.map((pamentForCare, index) => {
        return (
          <>
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <h6>Funding Source {index + 1}</h6>
              </div>
              {fields.length > 1 && (
                <div className="col-12 col-md-6">
                  <a
                    className="delete-contact"
                    onClick={() => fields.remove(index)}
                    disabled={disable}
                  >
                    <i className="fas fa-trash" /> Delete Funding Source
                  </a>
                </div>
              )}
            </div>
            <div className="row mb-4">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <Field
                    name={`${pamentForCare}.responsibilityTypeId`}
                    type="text"
                    component={ReduxSelect}
                    options={FinancialResponsibilityType}
                    placeholder="How Do You Plan To Help Pay For The Program?*"
                    validate={validate.required}
                    fieldName={'How Do You Plan To Help Pay For The Program'}
                    disabled={disable}
                  />
                </div>
              </div>
              {existingRows && existingRows[index].responsibilityTypeId == 7 ? (
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <Field
                      name={`${pamentForCare}.responsibilityTypeOther`}
                      type="text"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Responsibility Type'}
                      disabled={disable}
                    />
                    <label className="placeholder-label">Describe Support Type</label>
                  </div>
                </div>
              ) : null}
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <Field
                    name={`${pamentForCare}.contributionAmt`}
                    component={ReduxDollarInput}
                    validate={[validate.required, validate.allowNumeric]}
                    fieldName={'Contribution amount'}
                    disabled={disable}
                    dollarPlaceHolder={'Contribution Dollar*'}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <Field
                    name={`${pamentForCare}.contributionFrequencyCode`}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Contribution Frequency*"
                    options={Frequency}
                    validate={validate.required}
                    fieldName={'Contribution Frequency'}
                    disabled={disable}
                  />
                </div>
              </div>
              {isFinancialTabRequired != true && (
                <>
                  {haveAccessToProspectField && (
                    <>
                      <ToggleNote
                        isToggleOn={existingRows && existingRows[index].costAndFamilyDiscussed}
                        toggleQuestion={'Has cost & friends and family be discussed?'}
                        toggleFiledName={`${pamentForCare}.costAndFamilyDiscussed`}
                        toggleNoteFieldName={`${pamentForCare}.costAndFamilyDiscussedNote`}
                        disable={disable}
                      />

                      {hideQuestions && (
                        <ToggleNote
                          isToggleOn={existingRows && existingRows[index].ableToCoverIntakeFee}
                          toggleQuestion={' Are you able to cover the $500 Intake Fee?'}
                          toggleFiledName={`${pamentForCare}.ableToCoverIntakeFee`}
                          toggleNoteFieldName={`${pamentForCare}.ableToCoverIntakeFeeNote`}
                          disable={disable}
                        />
                      )}
                    </>
                  )}

                  <ToggleNote
                    isToggleOn={existingRows && existingRows[index].supportForMonthlyContributions}
                    toggleQuestion={' Do you have support to help cover the monthly Contributions?'}
                    toggleFiledName={`${pamentForCare}.supportForMonthlyContributions`}
                    toggleNoteFieldName={`${pamentForCare}.supportForMonthlyContributionsNote`}
                    disable={disable}
                  />
                </>
              )}

              <div className="col-12 col-md-6 col-lg-8">
                <div className="form-group">
                  <Field
                    name={`${pamentForCare}.notes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label className="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div className="row">
        <div className="col-12 text-right pt-4 pb-4">
          <a className="add-contact" onClick={() => fields.push({})} disabled={disable}>
            {fields.length >= 1 && (
              <label>
                <a> + Add Another Payment For Care</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                {' '}
                <a>+ Add Payment For Care</a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

const PaymentForCareUI = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    FinancialResponsibilityType,
    Frequency,
    style,
    existingRows,
    goToPreviuos,
    disable,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
    haveAccessToProspectField,
    hideQuestions,
    isFinancialTabRequired,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <FieldArray
        name={name}
        component={PaymentForCare}
        FinancialResponsibilityType={FinancialResponsibilityType}
        Frequency={Frequency}
        existingRows={existingRows}
        disable={disable}
        haveAccessToProspectField={haveAccessToProspectField}
        hideQuestions={hideQuestions}
        isFinancialTabRequired={isFinancialTabRequired}
      />
      <div className="row pagination-button">
        <div className="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              className="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              className="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" className="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos, center_config_get_by_id } = state;
  const { data: centerConfigById } = center_config_get_by_id;

  let isFinancialTabRequired = null;

  const existingRows = selector(state, 'fundSources');
  if (centerConfigById && centerConfigById.CenterConfig) {
    isFinancialTabRequired =
      centerConfigById.CenterConfig[0] && centerConfigById.CenterConfig[0].IsFinancialTabRequired;
  }
  return {
    Frequency: combos.data.Frequency,
    FinancialResponsibilityType: combos.data.FinancialResponsibilityType,
    existingRows,
    isFinancialTabRequired,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(PaymentForCareUI),
);

const selector = formValueSelector('wizard');
