import React from 'react';
import moment from 'moment';
import { stringDate } from '../../../util/date';

export default function TrendGraphModalContent(props) {
  const { modalContent, cummulativeDataModalContent } = props;
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ paddingTop: '60px', paddingBottom: '60px' }}
      // key={ }
    >
      {modalContent &&
        modalContent.map((item, index) => {
          const { label, backgroundColor, xAxis, shortdescription, surveyLabel } = item;
          let average = 0;
          return (
            <div key={`${surveyLabel}-${index}`}>
              <div
                style={{
                  backgroundColor: backgroundColor || '#ffffff',
                  color: 'white',
                  fontWeight: '600',
                  padding: '15px',
                  borderRadius: '10px',
                  width: '400px',
                }}
                className="mb-3"
              >
                <div className="d-flex justify-content-center align-items-center ">
                  <p
                    className="mr-2 mb-0"
                    style={{
                      border: '1px solid white',
                      borderRadius: '50%',
                      width: '23px',
                      height: '23px',
                      fontSize: '12px',
                      lineHeight: '1.85',
                      textAlign: 'center',
                    }}
                  >
                    {shortdescription}
                  </p>
                  <p className="mb-0">{surveyLabel}</p>
                  <p
                    className="mb-0"
                    style={{ fontStyle: 'italic', fontWeight: '400' }}
                  >{`(cumulative data)`}</p>
                </div>
                <div
                  className="mt-3"
                  style={{ borderTop: '1px solid #ffffff', borderBottom: '1px solid #ffffff' }}
                >
                  {cummulativeDataModalContent &&
                    cummulativeDataModalContent.length > 0 &&
                    cummulativeDataModalContent[index].map((cItem, cIndex) => {
                      const { CreatedDate, Description, Score, SelectedUrl } = cItem;
                      average += Score ? parseInt(Score) : 0;
                      return (
                        <div
                          className="d-flex justify-content-between align-items-center py-2"
                          key={`${surveyLabel}-${index}-${cIndex}`}
                        >
                          <p className="mb-0">{CreatedDate && moment(CreatedDate).format('lll')}</p>
                          <div
                            className="d-flex justifyc-content-center align-items-center flex-wrap"
                            style={{ width: '107px' }}
                          >
                            <img
                              src={SelectedUrl}
                              style={{ height: '28px', width: '28px' }}
                              className="mr-2"
                            />
                            <p className="mb-0">{Description}</p>
                          </div>
                          <p className="mb-0">Scale: {Score}</p>
                        </div>
                      );
                    })}
                </div>
                <p className="mt-2 mb-0 text-right" style={{ fontWeight: '700', fontSize: '17px' }}>
                  Average Scale: {average / cummulativeDataModalContent[index].length}
                </p>
                {/* <p>
                  {label}
                  {xAxis}
                </p> */}
              </div>
            </div>
          );
        })}
    </div>
  );
}
