import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Slider, Switch } from 'antd';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

class SessionBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`$${value}`}
        visible={true}
        placement="top"
        key={index}
        tipFormatter={value => <span className="tooltip-format">{`$${value}`}</span>}
        // tipProps={value => <span className="tooltip-format">{`$${value}`}</span>}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  percentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  };
  render() {
    const {
      labelText,
      handleCostPer,
      costPerValue,
      costPerColorCls,
      costPerNotificationText,
      marks,
      tooltipVisible,
      included,
      icon,
      max,
      min,
      greenRangeStart,
      greenRangeEnd,
    } = this.props;
    const mystyle = {
      color: 'white',
      backgroundColor: 'DodgerBlue',
      padding: '10px',
      fontFamily: 'Arial',
    };
    let greenStart = this.percentage(greenRangeStart, max);
    let greenEnd = this.percentage(greenRangeEnd, max);

    return (
      <div className="cost-per-session">
        <div className="price-img">
          <img src={`images/icons/${icon}`} alt={labelText} width="67" />
        </div>
        <h5 className="session-block-text">{labelText}</h5>
        <div className="slider-block">
          <div className="slider">
            <div className="pricing-slider-wrapper" style={{ marginTop: '50px' }}>
              {/* <Slider
                included={included}
                marks={marks}
                // tooltipVisible={tooltipVisible}
                tipFormatter={value => <span className="tooltip-format">{`$${value}`}</span>}
                onChange={handleCostPer}
                value={costPerValue}
                min={min}
                max={max}
              /> */}
              <Slider
                min={min}
                max={max}
                marks={marks}
                included={false}
                handle={this.handle}
                value={costPerValue}
                onChange={handleCostPer}
                railStyle={{
                  height: '5px',
                  borderRadius: '2px',
                  background: '#adadad',
                  background: `linear-gradient(
                                to right,
                                #ff9a4b ${min}%,
                                #ff9a4b ${greenStart}%,
                                #4de76f ${greenStart}%,
                                #4de76f ${greenEnd}%,
                                #ff9a4b ${greenEnd}%,
                                #ff9a4b ${max}%)`,
                }}
                // trackStyle={{ color: 'red', background: 'blue' }}
                handleStyle={{
                  width: '16px',
                  height: '16px',
                  marginTop: '-7px',
                  backgroundColor: '#fff',
                  border: `solid 4px linear-gradient(
                                            90deg,
                                            #00f2fe 0%,
                                            #03effe 2.8%,
                                            #24d2fe 39.48%,
                                            #3cbdfe 74.59%,
                                            #4ab0fe 107.15%,
                                            #4facfe 134.68%
                                          )`,
                  borderRadius: '50%',
                  boxShadow: 0,
                  cursor: 'pointer',
                }}
                // activeDotStyle={{ color: 'red', background: 'green' }}
                dotStyle={{ background: '#fff' }}
              />
            </div>
          </div>
        </div>
        <div className="pricing-alert-note">
          <span className="note">Note : </span>
          <span className="note-msg">
            Changing monthly cost will change the Quarterly/Half Yearly and Annual costs too, which
            can later be adjusted.
          </span>
        </div>
        <div className={`${costPerColorCls}`}>
          <div className="notification">{costPerNotificationText}</div>
        </div>
      </div>
    );
  }
}

export default connect()(SessionBlock);
