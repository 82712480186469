import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import ListRenderer from '../../../Components/listRenderer';
import ClientList from './clientList';
import PatientList from './patientList';
import ContractList from './contractList';
class PatientEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      // current: '/cw/patient-management/patient-list',
    };
  }
  // componentDidMount() {}
  list = [
    {
      Name: 'Patient List',
      ExtraComponent: () => <PatientList />,
    },
  ];

  list2 = [
    {
      Name: 'Patient List',
      ExtraComponent: () => <PatientList />,
    },
    {
      Name: 'Contract List',
      ExtraComponent: () => <ContractList />,
    },
  ];
  // handleMenuClick = e => {
  //   debugger;
  //   let setCurrent = `/cw/patient-management/${e.key}`;
  //   this.setState({ current: setCurrent });
  //   this.props.history.push(setCurrent);
  // };
  render() {
    const { current } = this.state;
    const isSpecialSuperAdmin = localStorage.getItem('isSpecialSuperAdmin');
    const listTab = isSpecialSuperAdmin == 'true' ? this.list2 : this.list;
    return (
      <div className="container-fluid">
        <div
          className="row mt-3"
          style={{
            background: 'white',
            borderRadius: '12px',
          }}
        >
          {/* <div className="col-12">
            <Menu
              onClick={this.handleMenuClick}
              selectedKeys={[current]}
              mode="horizontal"
              className="dashboard-menu"
              // style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}
            >
              <Menu.Item key="patient-list">Patient List</Menu.Item>
              <Menu.Item key="contract-list">Contract List</Menu.Item>
            </Menu>
          </div> */}
          <div className="col-12">
            <div class="center-tabs mt-2">
              <ListRenderer activeKey={this.state.activeKey} list={listTab} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = (state, props) => {
//   return {};
// };
export default connect()(withRouter(PatientEnrollment));
