import React, { Component } from 'react';
import ManagePatients from '../../Group/formSteps/managePatients';
export default class AddNewMember extends Component {
  constructor(props) {
        super(props);
        this.state = {}
    }
    render() { 
        return (
          <>
            <ManagePatients groupId={this.props.groupId} screen={"appointments"} />
          </>
        );
    }
}