import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import moment from 'moment';
import AppointmentFormFields from './AppointmentFormFields';
import {
  upsertAppointment,
  medicateCombos,
  getComboFacility,
  practitionerCombosOffice,
  comboServiceCodeGet,
  practitionerCombos,
  upsertAppointmentReset,
  appointmentPreSelectedGet,
} from '../../../../../store/actions';
import { mapTime, formatedTime } from '../../../../../util/time';
import { ProcessCombos } from '../../../../../store/comboConfig';
import { getMMDDYYYYFormat } from '../../../../../util/dateFormater';
import SubmitButton from '../../../../../Components/submitButton';
import CustomModal from '../../../../../core/modal';
import { Loader2 } from '../../../../../Components/Loader';

class CallEndAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {},
    };
  }
  componentDidMount() {
    if (!this.props.isEdit) {
      this.props.dispatch(
        appointmentPreSelectedGet({
          Json: JSON.stringify({
            Appointment: [
              {
                StudentTimerId: this.props.StudentTimerId,
                CallSid: this.props.CallSid,
              },
            ],
          }),
        }),
      );
    }
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  componentWillReceiveProps({ isAppointmentBooked, initialData, PreSelectedValues }) {
    if (isAppointmentBooked && isAppointmentBooked != this.props.isAppointmentBooked) {
      this.setState({
        isModelOpen: true,
        header: 'Appointment created/updated successfully',
      });
    }
    if (
      this.props.isEdit &&
      JSON.stringify(initialData) != JSON.stringify(this.props.initialData)
    ) {
      this.setState({
        initialData: initialData,
        FormatedCallDuration: formatedTime(initialData.DurationInMin),
      });
    }
    if (
      !this.props.isEdit &&
      PreSelectedValues &&
      JSON.stringify(PreSelectedValues) != JSON.stringify(this.props.PreSelectedValues)
    ) {
      let CallNotes = localStorage.getItem(`fNotesText`);
      localStorage.removeItem(`fNotesText`);
      let starttime;
      let endtime;
      if (this.props.CallSid && this.props.twilioData) {
        starttime = this.props.twilioData.callStartTime;
        endtime = this.props.twilioData.callEndTime;
        CallNotes = this.props.twilioData.twilioNotes;
      } else {
        starttime = PreSelectedValues.TimerStartTime && mapTime(PreSelectedValues.TimerStartTime);
        endtime = PreSelectedValues.TimerEndTime && mapTime(PreSelectedValues.TimerEndTime);
      }

      let appoinmentValues = {
        ...this.state.initialData,
        AppointmentDate: getMMDDYYYYFormat(PreSelectedValues.CurrentDate),
        Program: PreSelectedValues.ProgramId,
        StartTime: starttime,
        EndTime: endtime,
        ServiceCode: PreSelectedValues.ServiceCodeId,
        PractitionerId: PreSelectedValues.PractitionerId,
        PlaceOfServiceId: PreSelectedValues.PlaceOfServiceId,
        OfficeName: PreSelectedValues.FacilityId && PreSelectedValues.FacilityId.toString(),
        Status: PreSelectedValues.Status && parseInt(PreSelectedValues.Status),
        Notes: CallNotes,
      };
      this.setState({
        initialData: appoinmentValues,
        FormatedCallDuration: formatedTime(
          PreSelectedValues.TimerDurationInSec || PreSelectedValues.TwilioCallDurationInSec,
        ),
      });
    }
  }
  handleAppointment = val => {
    const { CallSid, StudentTimerId, StudentId, CurrentClientId, BannerData } = this.props;
    let data = {
      Appointment: [
        {
          IsPeerRecovery: true,
          IsVoipCallAppointmet: true,
          StudentId: StudentId || (BannerData && BannerData.StudentId),
          ClientId: CurrentClientId || (BannerData && BannerData.CurrentClientId),
          AppointmentId: val.AppointmentId,
          Title: val.EventTitle,
          AppointmentDate: getMMDDYYYYFormat(val.AppointmentDate),
          ProgramId: val.Program,
          AppointmentTime: val.StartTime && mapTime(val.StartTime),
          AppointmentEndTime: val.EndTime && mapTime(val.EndTime),
          ServiceCode: this.getStringType(val.ServiceCode),
          // ServiceCode: val.ServiceCode,
          PractitionerId: val.PractitionerId,
          PlaceOfServiceId: val.PlaceOfServiceId,
          OfficeName: val.OfficeName,
          Status: val.Status,
          ReasonForVisit: val.ReasonForVisit,
          CallSid: CallSid,
          StudentTimerId: StudentTimerId,
          Notes: val.Notes,
        },
      ],
    };
    var appointmentData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(upsertAppointment(appointmentData));
  };
  render() {
    const { header, isModelOpen } = this.state;
    return (
      <>
        <ReduxCallEndForm
          {...this.props}
          initialValues={this.state.initialData}
          handleAppointment={this.handleAppointment}
          FormatedCallDuration={this.state.FormatedCallDuration}
        />
        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            header={header}
            yesAction={() => {
              this.setState({
                isModelOpen: false,
              });
              this.props.onClose();
              this.props.dispatch(upsertAppointmentReset());
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </>
    );
  }
}

class CallEndForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var result = ProcessCombos('RCM_PlaceOfService,RCM_Program');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let medicateComboTypes = {
      ComboTypes: 'Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    this.props.dispatch(practitionerCombos({}));
    this.props.dispatch(practitionerCombosOffice({}));
    this.props.dispatch(getComboFacility({}));
    this.props.dispatch(
      comboServiceCodeGet({
        Json: JSON.stringify({
          Combo: [
            {
              IsPeerRecovery: true,
              AppointmentId: this.props.AppointmentId,
            },
          ],
        }),
      }),
    );

    // const { initialize } = this.props;
    // const values = {
    //   EventTitle: '123',
    // };
    // initialize(values);
  }
  render() {
    const {
      handleSubmit,
      disable,
      onCancel,
      dispatch,
      initialValues,
      change,
      OptionData,
      DurationInSec,
      isTwilioAppointment,
      isTimerAppointment,
      handleAppointment,
      FormatedCallDuration,
      isFetchingUpsert,
      isFetchingPreSelected,
    } = this.props;
    const formatted = DurationInSec && moment.utc(DurationInSec * 1000).format('HH:mm:ss');
    return (
      <div className="peer-recovery prospect-appointments-form mt-2">
        <div class="row mb-4 call-end-summary">
          <div className="col-12">
            <img src="images/icons/call-end-success.svg" />
            {FormatedCallDuration && (
              <p className="call-end-time">
                <img src="images/icons/billing_clock.svg" className="pr-2" />
                {FormatedCallDuration}
              </p>
            )}
            {isTwilioAppointment && (
              <>
                <p className="call-end-text">The call has been ended</p>
                <div className="call-summary">
                  <span>Augmented Call Summary is being prepared we will notify you</span>
                </div>
              </>
            )}
            {isTimerAppointment && <p className="call-end-text">The session has been ended</p>}
          </div>
        </div>
        <div class="row">
          <div className="col-12">
            {/* <span className="appointment-date">26th May, 2023</span> */}
          </div>
        </div>
        <form onSubmit={handleSubmit(handleAppointment)}>
          <div class="enrollment-form">
            <Loader2 loading={isFetchingPreSelected} />
            <AppointmentFormFields {...OptionData} disable={disable} change={change} />
            <div className="row">
              <div className="col-12">
                <div class="border-top-0 pt-2 mt-2 text-center">
                  <SubmitButton
                    value={'Done'}
                    loading={isFetchingUpsert}
                    style={{
                      height: '44px',
                      width: '100px',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    student_banner,
    get_client_appointment,
    get_appointment,
    practitioner_combos,
    combos,
    medicate_combos,
    get_combo_facility,
    upsert_appointment,
    combo_service_code_get,
    appointment_pre_selected_get,
  } = state;
  const { data } = student_banner;
  const { data: appointmentData, isFetching } = get_client_appointment;
  const { data: practitionerData } = practitioner_combos;
  const { data: medicateCombos } = medicate_combos;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: ComboServiceCodeData } = combo_service_code_get;
  const { data: PreSelectedData, isFetching: isFetchingPreSelected } = appointment_pre_selected_get;
  const {
    data: getAppointmentData,
    isFetching: isFetchingGetAppointment,
    error: errorGetAppointment,
  } = get_appointment;
  const { isFetching: isFetchingUpsert } = upsert_appointment;
  let PreSelectedValues = null;
  let BannerData = null;
  let AppointmentList = [];
  let AppointmentData = null;
  let ClientId;
  let messageContentAppointmentSuccess;
  let warningContent;
  let isAppointmentBooked = false;
  let OptionData = {
    Practitioner: [],
    ServiceCodeList: [],
    ProgramType: [],
    PlaceOfService: [],
    Status: [],
    PractitionerOffice: [],
  };
  if (data) {
    BannerData = data;
  }
  if (appointmentData && appointmentData.data) {
    AppointmentList = appointmentData.data || [];
  }
  if (practitionerData && practitionerData.PractitionerList) {
    OptionData.Practitioner = practitionerData.PractitionerList;
    // practitionerData.PractitionerList.filter(provider => provider.IsArchived === false) || [];
  }
  if (combos.data && combos.data['RCM_Program']) {
    // ServiceCodeList = combos.data['RCM_ServiceCode'] || [];
    OptionData.ProgramType = combos.data['RCM_Program'] || [];
    OptionData.PlaceOfService = combos.data['RCM_PlaceOfService'] || [];
  }
  if (medicateCombos) {
    OptionData.Status = medicateCombos.Status;
  }
  if (ComboServiceCodeData && ComboServiceCodeData.data) {
    OptionData.ServiceCodeList = JSON.parse(ComboServiceCodeData.data).Combo || [];
  }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    OptionData.PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (getAppointmentData && getAppointmentData.Appointment) {
    AppointmentData = getAppointmentData.Appointment[0];
    // warningContent = getAppointmentData.Warning;
    // ClientId = getAppointmentData.Appointment[0].DisplayClientId;
  }
  if (upsert_appointment.data && upsert_appointment.data.success) {
    isAppointmentBooked = upsert_appointment.data.success;
    // messageContentAppointmentSuccess = JSON.parse(upsert_appointment.data.data)[0].Warning;
  }
  if (PreSelectedData && PreSelectedData.Appointment) {
    PreSelectedValues = PreSelectedData.Appointment[0];
  }
  return {
    AppointmentList,
    isAppointmentBooked,
    AppointmentData,
    OptionData,
    BannerData,
    PreSelectedValues,
    isFetchingUpsert,
    isFetchingPreSelected,
  };
};
const ReduxCallEndForm = reduxForm({
  form: 'callEndForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(CallEndForm)));

export default connect(mapStateToProps)(withRouter(CallEndAppointment));
