import API from './requests';
// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
export default function checkToken() {
  localStorage.setItem('istokenRefreshing', false);
  const history = createBrowserHistory();
  return ({ dispatch, getState }) => next => action => {
    var token = JSON.parse(localStorage.getItem('dr:token'));
    if (
      token &&
      token.date_exp &&
      token.date_exp < Date.now() &&
      action.type.indexOf('REQUEST') &&
      action.type !== 'HANDLE_ERRORS_REQUEST'
    ) {
      var istokenRefreshing = localStorage.getItem('istokenRefreshing');
      if (istokenRefreshing == 'true') {
        return next({ type: 'SaveActions', actions: action });
      }
      localStorage.setItem('istokenRefreshing', true);
      var refresh_token = '';
      var tokenObject = localStorage.getItem('dr:token');
      if (tokenObject) {
        refresh_token = JSON.parse(localStorage.getItem('dr:token')).refresh_token;
      } else {
        var path = window.location.hash.split('#/')[1];
        if (path) {
          history.push('/#login?redirectPath=' + path);
          window.location.reload(true);
        }
      }

      if (Boolean(refresh_token)) {
        try {
          //   const originalRequest = error.config;
          API.refreshToken({
            grant_type: 'refresh_token',
            refresh_token: refresh_token,
            // originalRequest: originalRequest,
          })
            .then(resp => {
              console.debug(resp);
              if (resp.data.access_token) {
                var token = JSON.parse(localStorage.getItem('dr:token'));
                token.access_token = resp.data.access_token;
                token.refresh_token = resp.data.refresh_token;
                token.date_exp = Date.now() + resp.data.expires_in * 1000;
                localStorage.setItem('dr:token', JSON.stringify(token));
                localStorage.setItem('istokenRefreshing', false);
                var actions = getState().actions_save.actions;
                dispatch(action);
                for (action of actions) {
                  dispatch(action);
                }
                dispatch({ type: 'ClearAction' });
              }
            })
            .catch(err => {
              localStorage.clear();
              var path = window.location.hash.split('#/')[1];
              history.push('/#login?redirectPath=' + path);
              window.location.reload(true);
            });
        } catch (error) {
          localStorage.clear();
          var path = window.location.hash.split('#/')[1];
          history.push('/#login?redirectPath=' + path);
          window.location.reload(true);
          return next(action);
        }
      }
    } else {
      return next(action);
    }
  };
}
