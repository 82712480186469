import React, { useState } from 'react';
import { Tooltip } from 'antd';

export const CustomToolTip = ({
  optionData,
  title,
  titleKey,
  placement,
  color,
  overlayStyle,
  trigger,
}) => {
  // const [isVisible, setIsVisible] = useState(false);
  // const handleVisibleChange = visible => {
  //   setIsVisible(visible);
  // };
  //  let content = (
  //    <div className="sort-by-list" onClick={() => setIsVisible(false)}>
  //      {optionData}
  //    </div>
  //  );
  return (
    <Tooltip
      placement={placement || 'top'}
      trigger={trigger || 'click'}
      color={color || '#182838'}
      overlayStyle={{
        width: '300px',
      }}
      overlayInnerStyle={{
        width: '300px',
        maxHeight: '350px',
        overflowY: 'auto',
      }}
      // key={color}
      title={
        <div className="px-1">
          <span className="pb-2" style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: '700' }}>
            {title} ({optionData.length}):
          </span>
          {optionData.map((item, index) => {
            return (
              <div className="px-2" key={index}>
                <p
                  className="px-1"
                  style={{ color: '#FFFFFF', fontSize: '14px', fontWeight: '400' }}
                >
                  {index + 1}. {item[titleKey]}
                </p>
              </div>
            );
          })}
        </div>
      }
    >
      <span
        style={{
          background: 'rgba(0, 158, 247, 0.16)',
          height: '20px',
          width: '20px',
          padding: '0px 5px',
          cursor: 'pointer',
        }}
      >
        {optionData.length}
      </span>
    </Tooltip>
  );
};
