import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import SubmitButton from '../../../../../Components/submitButton';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
  ReduxTextarea,
} from '../../../../../component/custom-redux-components/index.js';
import {
  resetDrugSearch,
  resetDrugPackageGetAll,
  resetDrugDetailSearch,
  listCombos,
} from '../../../../../store/actions';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';

let medicationArray = [];
let medicationOptions = [
  { Value: null, Text: '<Select>' },
  { Value: null, Text: 'Powder' },
  { Value: null, Text: 'Tablet' },
];
class DrugForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // drugText: null,
      drugValue: null,
      drugText: null,
      DoseId: null,
      isUseText: false,
      doseValue: null,
      quantityText: null,
      PatientNotes: null,
      refillText: null,
      refillLimit: true,
      brandName: null,
      genericName: null,
      strength: null,
      formValue: null,
      OnStartDate: null,
      OnEndDate: null,
      formName: null,
      startDateValue: null,
      showLabel: false,
    };
  }
  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  componentWillUnmount() {
    this.props.dispatch(resetDrugSearch());
    this.props.dispatch(resetDrugDetailSearch());
    this.props.dispatch(resetDrugPackageGetAll());
  }
  componentWillReceiveProps({ searched }) {
    if (searched && JSON.stringify(searched) != JSON.stringify(this.props.searched)) {
      this.setState({
        showLabel: true,
        drugText: searched.text,
        drugValue: searched.value,
        brandName: searched.brandName,
        genericName: searched.genericName,
        strength: searched.strength,
        formName: searched.formName,
        formValue: searched.formValue,
      });
    }
  }
  handleFormChange = formValue => {
    this.setState({ formValue });
  };

  isRefillLimit = value => {
    if (value > 99 || value < 0) {
      this.setState({
        refillLimit: false,
      });
    } else {
      this.setState({
        refillLimit: true,
      });
    }
  };

  render() {
    const {
      onSaveClick,
      disable,
      onOk,
      onCancel,
      viewModalType,
      DrugList,
      isFetching,
      handleDoseSearch,
      DosageList,
      isEPrescribe,
      handleDrugDetailSearch,
      isDrugFetching,
      DrugSearchList,
      onDrugSave,
      formOptions,
      handleNonEDoseSearch,
      isUpsertFetching,
    } = this.props;
    const {
      drugValue,
      drugText,
      doseList,
      DoseId,
      searched,
      isUseText,
      doseValue,
      refillText,
      quantityText,
      PatientNotes,
      brandName,
      genericName,
      strength,
      formValue,
      OnStartDate,
      OnEndDate,
      formName,
      showLabel,
    } = this.state;
    const StartDateInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
      </>
    );
    const EndDateInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">End date</label>
      </>
    );

    return (
      <div class="modal-content border-0 p-2">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            onClick={() => {
              this.props.dispatch(resetDrugSearch());
              this.props.dispatch(resetDrugDetailSearch());
              this.props.dispatch(resetDrugPackageGetAll());
              onCancel();
            }}
          >
            ×
          </button>
          <h4 class="modal-title col-12 text-left pl-0">
            {viewModalType === 'update' ? 'Manage' : 'Add'} Drug
          </h4>
          {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
        </div>
        <div class="modal-body rcm-panel enrollment-form">
          {!isEPrescribe ? (
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <input
                    name={`Drug`}
                    type="text"
                    class={`form-control ${drugText && 'not-empty'}`}
                    value={drugText}
                    onKeyUp={() => {
                      this.props.handleDrugChange(this.state.drugText);
                    }}
                    onChange={event => {
                      let drugText = event.target.value;
                      this.setState({
                        drugText: drugText,
                        isUseText: false,
                      });
                    }}
                  />
                  <label className="placeholder-label">Search for a drug</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                {!isUseText ? (
                  <div class="form-group">
                    <ReduxSelect
                      options={DosageList}
                      onChange={severity => this.handleChange('DoseId', severity)}
                      className="basic-single"
                      classNamePrefix="select"
                      value={DoseId}
                      placeholder="Dosage"
                    />
                  </div>
                ) : (
                  <div class="form-group">
                    <input
                      name={`doseValue`}
                      type="text"
                      class={`form-control ${doseValue && 'not-empty'}`}
                      value={doseValue}
                      onChange={event => {
                        let doseValue = event.target.value;
                        this.setState({
                          doseValue,
                        });
                      }}
                    />
                    <label className="placeholder-label">Dosage</label>
                  </div>
                )}
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <DatePicker
                    selected={OnStartDate}
                    onChange={date => this.handleChange('OnStartDate', date)}
                    className="form-control datepicker"
                    customInput={<StartDateInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <DatePicker
                    selected={OnEndDate}
                    onChange={date => this.handleChange('OnEndDate', date)}
                    className="form-control datepicker"
                    customInput={<EndDateInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div className="col-12">
                <h4 style={{ fontSize: '18px' }}>Other details :</h4>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group disabled-field">
                  <input
                    name={`GenericName`}
                    type="text"
                    disabled={!isUseText}
                    class={`form-control ${genericName && 'not-empty'}`}
                    value={genericName}
                    placeholder={showLabel ? '' : 'Generic Name'}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let genericName = event.target.value;
                      this.setState({
                        genericName: genericName,
                      });
                    }}
                  />
                  {showLabel && <label className="placeholder-label">Generic Name</label>}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group disabled-field">
                  <input
                    name={`FormName`}
                    type="text"
                    disabled={!isUseText}
                    class={`form-control ${formName && 'not-empty'}`}
                    value={formName}
                    placeholder={showLabel ? '' : 'Forms'}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let formName = event.target.value;
                      this.setState({
                        formName: formName,
                      });
                    }}
                  />
                  {showLabel && <label className="placeholder-label">Form</label>}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group disabled-field">
                  <input
                    name={`Strength`}
                    type="text"
                    disabled={!isUseText}
                    class={`form-control ${strength && 'not-empty'}`}
                    value={strength}
                    placeholder={showLabel ? '' : 'Strength'}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let strength = event.target.value;
                      this.setState({
                        strength: strength,
                      });
                    }}
                  />
                  {showLabel && <label className="placeholder-label">Strength</label>}
                </div>
              </div>

              <div className="col-12 my-1">
                {this.state.drugText && (
                  <>
                    Use Text :
                    <span
                      className="searched-item"
                      style={{ backgroundColor: 'rgba(74, 176, 254, 1)', color: '#fff' }}
                      onClick={() => {
                        this.setState({
                          drugText: this.state.drugText,
                          isUseText: true,
                          formName: '',
                          genericName: '',
                          strength: '',
                        });
                      }}
                      id={null}
                    >
                      {this.state.drugText}
                    </span>
                  </>
                )}
              </div>
              <div class="col-12 mt-4" style={{ position: 'relative' }}>
                <Loader2 loading={isFetching} />
              </div>
              {DrugList && (
                <div class="col-12 mt-2 mb-3 allergies-section">
                  <div className="row">
                    {DrugList.map(item => (
                      <div className="col-4 mb-3">
                        <span
                          className="searched-item"
                          onClick={() => {
                            // debugger;
                            // this.setState({
                            //   // drugValue: item.Value,
                            //   // drugText: item.Text,
                            //   // isUseText: false,
                            // });
                            handleNonEDoseSearch(
                              item.Value,
                              item.Text,
                              item.GenericName,
                              item.Form,
                              item.Strength,
                            );
                          }}
                          id={item.Value}
                        >
                          {item.Text}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div class="col-12 text-right">
                {/* <input
                  type="button"
                  class="btn px-5"
                  value={'Add'}
                  disabled={isUseText ? false : !DoseId || !drugValue}
                  style={!this.state.drugText ? { background: 'rgba(0,0,0,0.5)' } : undefined}
                  onClick={() => {
                    onSaveClick({
                      DoseId,
                      drugValue,
                      doseValue,
                      drugText,
                      isUseText,
                      formName,
                      genericName,
                      strength,
                      OnStartDate,
                      OnEndDate,
                    });
                    this.setState({
                      drugValue: null,
                      drugText: null,
                      DoseId: null,
                      isUseText: false,
                      doseValue: null,
                    });
                    this.props.dispatch(resetDrugSearch());
                    this.props.dispatch(resetDrugPackageGetAll());
                    onCancel();
                  }}
                /> */}

                <SubmitButton
                  value={'Add'}
                  loading={isUpsertFetching}
                  disabled={isUseText ? false : !DoseId || !drugValue}
                  style={!this.state.drugText ? { background: 'rgba(0,0,0,0.5)' } : undefined}
                  onClick={() => {
                    onSaveClick({
                      DoseId,
                      drugValue,
                      doseValue,
                      drugText,
                      isUseText,
                      formName,
                      genericName,
                      strength,
                      OnStartDate,
                      OnEndDate,
                    });
                    this.setState({
                      drugValue: null,
                      drugText: null,
                      DoseId: null,
                      isUseText: false,
                      doseValue: null,
                    });
                    this.props.dispatch(resetDrugSearch());
                    this.props.dispatch(resetDrugPackageGetAll());
                    onCancel();
                  }}
                />
              </div>
            </div>
          ) : (
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <input
                    name={`Drug`}
                    type="text"
                    class={`form-control ${drugText && 'not-empty'}`}
                    value={drugText}
                    onKeyUp={() => {
                      this.props.handleDrugDetailSearch(this.state.drugText);
                    }}
                    onChange={event => {
                      let drugText = event.target.value;
                      this.setState({
                        drugText: drugText,
                        isUseText: false,
                      });
                    }}
                  />
                  <label className="placeholder-label">Search for a drug</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group disabled-field">
                  <input
                    name={`GenericName`}
                    type="text"
                    disabled={!isUseText}
                    class={`form-control ${genericName && 'not-empty'}`}
                    value={genericName}
                    placeholder={showLabel ? '' : 'Generic Name'}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let genericName = event.target.value;
                      this.setState({
                        genericName: genericName,
                      });
                    }}
                  />
                  {showLabel && <label className="placeholder-label">Generic Name</label>}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group disabled-field">
                  <input
                    name={`BrandName`}
                    type="text"
                    disabled={!isUseText}
                    class={`form-control ${brandName && 'not-empty'}`}
                    value={brandName}
                    placeholder={showLabel ? '' : 'Brand Name'}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let brandName = event.target.value;
                      this.setState({
                        brandName: brandName,
                      });
                    }}
                  />
                  {showLabel && <label className="placeholder-label">Brand Name</label>}
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group disabled-field">
                  <input
                    name={`Strength`}
                    type="text"
                    disabled={!isUseText}
                    class={` form-control ${strength && 'not-empty'}`}
                    value={strength}
                    placeholder={showLabel ? '' : 'Strength'}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let strength = event.target.value;
                      this.setState({
                        strength: strength,
                      });
                    }}
                  />
                  {showLabel && <label className="placeholder-label">Strength</label>}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  {!isUseText ? (
                    <div className="disabled-field">
                      <input
                        name={`Form`}
                        type="text"
                        disabled={!isUseText}
                        class={`form-control ${strength && 'not-empty'}`}
                        value={formValue}
                        placeholder={showLabel ? '' : 'Forms'}
                        // onKeyUp={() => {
                        //   this.props.handleDrugDetailSearch(this.state.drugText);
                        // }}
                        onChange={event => {
                          let formValue = event.target.value;
                          this.setState({
                            formValue: formValue,
                          });
                        }}
                      />
                      {showLabel && <label className="placeholder-label">Form</label>}
                    </div>
                  ) : (
                    // <label className="placeholder-label">Strength</label>
                    <ReduxSelect
                      // disabled={}
                      options={formOptions}
                      onChange={this.handleFormChange}
                      className="basic-single"
                      classNamePrefix="select"
                      value={formValue}
                      placeholder="Forms"
                    />
                  )}
                  {/* <label className="placeholder-label">Form</label> */}
                </div>
              </div>

              <div className="col-12">
                <h4 style={{ fontSize: '18px' }}>Other details :</h4>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <input
                    name={`Quantity`}
                    type="number"
                    class={`form-control ${quantityText && 'not-empty'}`}
                    value={quantityText}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let quantityText = event.target.value;
                      this.setState({
                        quantityText: quantityText,
                      });
                    }}
                  />
                  <label className="placeholder-label">Quantity</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <input
                    name={`Refill`}
                    type="number"
                    class={`form-control ${refillText && 'not-empty'}`}
                    value={refillText}
                    min={0}
                    onKeyUp={event => {
                      this.isRefillLimit(event.target.value);
                    }}
                    onChange={event => {
                      let refillText = event.target.value;
                      // if (refillText > 0)
                      this.setState({
                        refillText: refillText,
                      });
                    }}
                  />
                  <label className="placeholder-label">Refills</label>
                  <span class="small-info">Refills should between 0 and 99</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <DatePicker
                    value={OnStartDate || ''}
                    selected={OnStartDate}
                    onChange={date => this.handleChange('OnStartDate', date)}
                    className="form-control datepicker"
                    customInput={<StartDateInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <DatePicker
                    value={OnEndDate || ''}
                    selected={OnEndDate}
                    onChange={date => this.handleChange('OnEndDate', date)}
                    className="form-control datepicker"
                    customInput={<EndDateInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <ReduxTextarea
                    name={`PatientNotes`}
                    type="text"
                    class={`form-control ${PatientNotes && 'not-empty'}`}
                    value={PatientNotes}
                    // onKeyUp={() => {
                    //   this.props.handleDrugDetailSearch(this.state.drugText);
                    // }}
                    onChange={event => {
                      let PatientNotes = event.target.value;
                      this.setState({
                        PatientNotes: PatientNotes,
                      });
                    }}
                  />
                  <label className="placeholder-label">Patient Notes</label>
                </div>
              </div>

              <div className="col-12 my-1">
                {this.state.drugText && (
                  <>
                    Use Text :
                    <span
                      className="searched-item"
                      style={{ backgroundColor: 'rgba(74, 176, 254, 1)', color: '#fff' }}
                      onClick={() => {
                        this.setState({
                          drugText: this.state.drugText,
                          isUseText: true,
                          brandName: '',
                          genericName: '',
                          strength: '',
                        });
                      }}
                      id={null}
                    >
                      {this.state.drugText}
                    </span>
                  </>
                )}
              </div>
              <div class="col-12 mt-4" style={{ position: 'relative' }}>
                <Loader2 loading={isDrugFetching} />
              </div>
              {DrugList && (
                <div class="col-12 mt-2 mb-3 allergies-section">
                  <div className="row">
                    {DrugList.map(item => (
                      <div className="col-4 mb-3">
                        <span
                          key={item.NDCID}
                          className="searched-item"
                          onClick={() => {
                            medicationArray = item;
                            handleDoseSearch(
                              item.NDCID,
                              item.Name,
                              item.GenericName,
                              item.BrandName,
                              item.Strength,
                              item.Form,
                            );
                          }}
                          id={item.NDCID}
                        >
                          {item.Name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div class="col-12 text-right">
                <input
                  type="button"
                  class="btn px-5"
                  value={'Add'}
                  disabled={drugText && this.state.refillLimit ? false : true}
                  style={!this.state.drugText ? { background: 'rgba(0,0,0,0.5)' } : undefined}
                  onClick={() => {
                    onDrugSave({
                      // DoseId,
                      drugValue,
                      doseValue,
                      drugText,
                      isUseText,
                      medicationArray,
                      refillText,
                      quantityText,
                      PatientNotes,
                      formValue,
                      OnStartDate,
                      OnEndDate,
                    });
                    medicationArray = [];
                    this.setState({
                      drugValue: null,
                      drugText: null,
                      DoseId: null,
                      isUseText: false,
                      doseValue: null,
                    });
                    this.props.dispatch(resetDrugDetailSearch());
                    this.props.dispatch(resetDrugPackageGetAll());
                    onCancel();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ drug_search, drug_package_get_all, drug_detail_search }) => {
  const { data: drugData, isFetching } = drug_search;
  const { data: drugSearchData, isFetching: isDrugFetching } = drug_detail_search;
  const { data: doseData } = drug_package_get_all;

  let DrugList;
  let DosageList;
  let searched;
  let DrugSearchList;

  if (drugData && drugData.Product) {
    DrugList = drugData.Product;
  }
  if (drugSearchData && drugSearchData.data) {
    DrugList = drugSearchData.data;
    DrugSearchList = drugSearchData.data;
  }
  if (doseData && doseData) {
    DosageList = doseData.data.Dosage;
    searched = doseData.searched;
  }
  return {
    DrugList: DrugList,
    DosageList,
    isFetching,
    searched,
    isDrugFetching,
    DrugSearchList,
  };
};
export default connect(mapStateToProps)(withRouter(DrugForm));
