import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* clientembeddingprepare(action) {
  try {
    const { payload } = action;
    const response = yield API.clientembeddingprepare(payload);
    yield put({
      type: ActionTypes.CLIENT_EMBEDDING_PREPARE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_EMBEDDING_PREPARE_FAILURE,
    });
  }
}
export function* askQuestion(action) {
  try {
    const { payload } = action;
    const response = yield API.askQuestion(payload);
    yield put({
      type: ActionTypes.ASK_QUESTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ASK_QUESTION_FAILURE,
    });
  }
}
export function* chatGet(action) {
  try {
    const { payload } = action;
    const response = yield API.chatGet(payload);
    yield put({
      type: ActionTypes.CHAT_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CHAT_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.CLIENT_EMBEDDING_PREPARE_REQUEST, clientembeddingprepare),
    takeLatest(ActionTypes.ASK_QUESTION_REQUEST, askQuestion),
    takeLatest(ActionTypes.CHAT_GET_REQUEST, chatGet),
  ]);
}
