import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Popover, Cascader, Icon, Checkbox } from 'antd';
import {
  staffPermission,
  updateStaffPermission,
  loadDefaultValues,
} from '../../../../../../store/actions';
import Loader, { Loader2 } from '../../../../../../Components/Loader';

class RollesPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      RolesPermissions: [],
    };
  }

  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('sId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };

  componentDidMount() {
    this.props.dispatch(staffPermission({ staffId: this.getStaffId() }));
  }
  componentWillReceiveProps({ RolesPermissions, isUpdated }) {
    if (RolesPermissions) {
      this.setState({
        RolesPermissions,
      });
    }
    if (isUpdated && isUpdated != this.props.isUpdated) {
      this.setState(
        {
          Msg: 'Saved successfully.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              Msg: '',
            });
            // this.props.dispatch(loadDefaultValues({}));
          }, 2000);
        },
      );
    }
  }

  render() {
    const { isFetching, onOk, onCancel, isDeactivated } = this.props;
    const { RolesPermissions, Msg } = this.state;

    return (
      <div class="container-fluid mt-5">
        <div className="row mb-3 top-table-search align-items-center">
          <div class="col-md-6 col-xl-8">
            <a
              class="back-link"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.history.goBack()}
            >
              <img src="images/back-arrow.svg" /> Back
            </a>
            <br />
            <span className="grid-page-title">Grant Roles and Permission</span>
          </div>
        </div>
        <div class="row filter-modal external-member-roles-permissions">
          {RolesPermissions &&
            RolesPermissions.map((item, index) => {
              return (
                <div className="col-12 mb-3">
                  <div class="card border-0">
                    <div class="row card-body">
                      <div class="col-12 external-roles-permissions-modal">
                        <span className="rolls">
                          {index + 1}. {item.Description}
                        </span>
                        {this.state.index === index && (
                          <h6
                            style={{
                              fontSize: '14px',
                              color: 'rgb(18, 196, 117)',
                              marginLeft: '1.5rem',
                              display: 'inline-block',
                            }}
                            className="mb-0"
                          >
                            {Msg}
                          </h6>
                        )}
                        <div className="permissions-block">
                          {item.Permission &&
                            item.Permission.map((permission, rollIndex) => {
                              return (
                                <div>
                                  <Checkbox
                                    disabled={permission.IsPermissionReadOnly || isDeactivated}
                                    // className="permissions"
                                    className={
                                      permission.IsStaffPermissionEnabled
                                        ? 'permissions-active'
                                        : 'permissions'
                                    }
                                    name="permissions"
                                    checked={permission.IsStaffPermissionEnabled}
                                    value={permission.Name}
                                    onClick={e => {
                                      let newPermission = permission;
                                      newPermission.IsStaffPermissionEnabled = e.target.checked;
                                      let result = item;
                                      result.Permission[rollIndex] = newPermission;
                                      let data = RolesPermissions;
                                      data[index] = result;
                                      this.setState({
                                        index: index,
                                        RolesPermissions: data,
                                      });
                                      this.props.dispatch(
                                        updateStaffPermission({
                                          staffId: this.getStaffId(),
                                          jsonInput: JSON.stringify({
                                            StaffPermission: [
                                              {
                                                StaffPermissionId: permission.StaffPermissionId,
                                                PermissionId: permission.PermissionId,
                                                IsStaffPermissionEnabled: e.target.checked,
                                              },
                                            ],
                                          }),
                                        }),
                                      );
                                    }}
                                  >
                                    {' '}
                                    {permission.Name}
                                  </Checkbox>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div class="col-12 position-sticky mt-2" style={{ bottom: 0, zIndex: '2' }}>
            <div
              className="row enrollment-page top-heading-row"
              style={{
                backgroundColor: '#ffffff',
                boxShadow: 'rgba(0, 0, 0, 0.03)',
              }}
            >
              <div class="col-12 px-5 py-3">
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    onClick={() => this.props.history.goBack()}
                    class="btn btn-outline-primary mr-3 px-5"
                    // disabled={this.getDischarged()}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() =>
                      this.props.history.push(
                        '/cw/patient-management/external-care-team/roles?pId=20009125001&&sId=1439',
                      )
                    }
                    class="btn mr-3 px-5"
                    // disabled={this.getDischarged()}
                  >
                    Send
                  </button>
                  {/* <Button
                    loading={isUpserting}
                    onClick={() => {
                      this.props.remoteSubmit();
                    }}
                    onClick2={() => {
                      activeTabs.toString().indexOf('false') == -1
                        ? this.props.remoteSubmit()
                        : this.setState({ showError: true }, () =>
                            setTimeout(() => this.setState({ showError: false }), 2000),
                          );
                    }}
                    className="save-button"
                    disabled={isAllowToEditClosed ? !isAllowToEditClosed : this.getDischarged()}
                  >
                    Save
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_staff_permission, update_staff_permission, form_data_get }) => {
  const { data: RolesPermissionsData, isFetching, error } = get_staff_permission;
  const { data } = form_data_get;

  let RolesPermissions = null;
  let isUpdated = false;
  let isDeactivated = false;

  if (RolesPermissionsData && RolesPermissionsData.StaffPermission) {
    RolesPermissions = RolesPermissionsData.StaffPermission || [];
  }
  if (update_staff_permission && update_staff_permission.data) {
    isUpdated = update_staff_permission.data.success;
  }
  if (data && data.Staff[0]) {
    isDeactivated = data.Staff[0].IsDeactivated;
  }
  return {
    RolesPermissions: RolesPermissions,
    isFetching,
    isUpdated,
    isDeactivated,
  };
};
export default connect(mapStateToProps)(withRouter(RollesPermissions));
