import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import AntdModal from '../../../../Components/CustomModal';
import { Link, withRouter } from 'react-router-dom';
import ListRenderer from '../../../../Components/listRenderer';
import AssessmentTool from './assessmentTool';
import IndividualNotes from './individualNotes';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
    };
  }
  list = [
    {
      Name: 'Clinical Notes',
      ExtraComponent: props => {
        return (
          <div className="">
            <IndividualNotes
              {...props}
              clientId={this.props.clientId}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
          </div>
        );
      },
    },
    {
      Name: 'Progress Notes',
      ExtraComponent: props => {
        return (
          <div className="">
            <AssessmentTool
              {...props}
              clientId={this.props.clientId}
              description="Progress Notes"
              DocTypeValue={'16019'}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
          </div>
        );
      },
    },

    // {
    //   Name: 'ASAM',
    //   ExtraComponent: () => {
    //     return <div className="Activities">tabs</div>;
    //   },
    // },
    // {
    //   Name: 'Assessment Tools',
    //   ExtraComponent: () => {
    //     return <div className="Activities">tabs</div>;
    //   },
    // },
  ];
  // componentDidMount() {}

  // componentWillReceiveProps({ TabData }) {}

  render() {
    return (
      <div class="mx-2">
        <div class="forms center-tabs">
          <ListRenderer
            list={this.list}
            activeKey={this.state.activeKey}
            getActiveKey={activeKey => this.setState({ activeKey })}
            destroyInactiveTabPane={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { form_tab_list_get } = state;
  const { data } = form_tab_list_get;

  let TabData = null;

  if (data && data.result) {
    TabData = data.result || [];
  }

  return {
    TabData: TabData,
  };
};
export default connect(mapStateToProps)(withRouter(Notes));
