import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import adaptivePlugin from '@fullcalendar/adaptive';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      weekendsVisible: true,
    };
  }
  renderEventContent = eventInfo => {
    let statusColor;
    if (eventInfo.event._def.extendedProps.statusColor) {
      statusColor = eventInfo.event._def.extendedProps.statusColor;
    }
    return (
      <div
        style={{
          background: 'white',
          borderLeft:
            eventInfo.event._def.extendedProps.isGroup == true
              ? '4px solid #09B66D'
              : eventInfo.event._def.extendedProps.isBlockAppoitment == false
              ? '4px solid #FDBF5E'
              : '4px solid  #B32202',
          borderRight: `4px solid ${statusColor}`,
          borderRadius: '0px',
          padding: '3px',
          overflow: 'hidden',
          color: '#4ab0fe',
        }}
      >
        <span className="event-time">{eventInfo.timeText}</span>
        &nbsp;
        <span className="event-title">{eventInfo.event.title}</span>
      </div>
    );
  };

  // disabledTimeSlots = [
  //   {
  //     resourceId: '10',
  //     start: '2024-04-11T13:00:00+05:30',
  //     end: '2024-04-11T15:00:00+05:30',
  //   }, // 1 pm to 2 pm IST
  //   {
  //     resourceId: '10',
  //     start: '2024-04-11T15:00:00+05:30',
  //     end: '2024-04-11T18:00:00+05:30',
  //   }, // 1 pm to 2 pm IST
  // ];

  selectAllowCallback = selectInfo => {
    if (selectInfo.allDay) {
      return true;
    } else {
      const { startStr, endStr } = selectInfo;
      const resourceID = selectInfo.resource._resource.id;

      for (const slot of this.props.disabledTimeSlots) {
        if (
          slot &&
          slot.resourceId &&
          slot.resourceId.toString() === resourceID &&
          startStr >= slot.start &&
          endStr <= slot.end
        ) {
          //right now this comparsion is not working properlly because start time and end time format coming differently from backend.
          // start: '2024-04-11T13:00:00+05:30',
          // end: '2024-04-11T15:00:00+05:30',
          return false;
        }
      }

      return true;
    }
  };

  render() {
    const { events, eventClick, eventContent, select, resources } = this.props;
    // console.log('resources', resources);
    const CAL_CONFIG = {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [
        listPlugin,
        dayGridPlugin,
        timeGridPlugin,
        resourceTimelinePlugin,
        resourceTimeGridPlugin,
        resourceDayGridPlugin,
        interactionPlugin,
        adaptivePlugin,
        scrollGridPlugin,
      ],
      dayMinWidth:200,
      buttonText: {
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
      },
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short',
      },
      headerToolbar: {
        // left: 'listMonth,resourceTimelineDay,resourceTimelineWeek,dayGridMonth', //listWeek ,resourceTimeGridDay,resourceDayGridDay,timeGridDay,resourceTimeline,resourceTimelineWeek,dayGridYear
        left: 'listMonth,resourceTimeGridDay,resourceTimelineWeek,dayGridMonth', //listWeek ,resourceDayGridDay,timeGridDay,resourceTimeline,resourceTimelineWeek,dayGridYear
        center: 'title',
        right: 'today,prev,next',
        // right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      initialView: 'dayGridMonth',
      // views: {
      //   resourceTimelineThreeDays: {
      //     type: 'resourceTimeline',
      //     duration: { days: 3 },
      //     buttonText: '3 day',
      //   },
      // },
      dayMaxEventRows: true, // for all non-TimeGrid views
      eventLimit: true,
      moreLinkClick: 'resourceTimelineDay',
      dayMaxEvents: 3,
      eventLimitText: function(numEvents) {
        return 'YourCustomText';
      },
      // views: {
      //   dayGridMonth: {
      //     titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
      //     dayMaxEventRows: 2,
      //     eventLimitText: 'pawan',
      //   },
      //   dayGrid: {
      //     // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
      //     titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
      //     dayMaxEventRows: 2,
      //     eventLimitText: 'pawan',
      //   },
      //   timeGrid: {
      //     // options apply to timeGridWeek and timeGridDay views
      //     eventLimit: 2,
      //     eventLimitText: 'pawan',
      //   },
      //   week: {
      //     // options apply to dayGridWeek and timeGridWeek views
      //     eventLimit: 2,
      //     eventLimitText: 'pawan',
      //   },
      //   day: {
      //     // options apply to dayGridDay and timeGridDay views
      //     eventLimit: 2,
      //     eventLimitText: 'pawan',
      //   },
      // },
      weekends: this.state.weekendsVisible,
      resourceOrder: 'SortRank',
      // filterResourcesWithEvents: true, //When this setting is activated, only resources that have associated events will be displayed.
      datesAboveResources: true,
      editable: false,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      // contentHeight: 650,
      // disableDragging={true}
    };
    // const doctors = [
    //   {
    //     id: 'doc1',
    //     title: 'Doctor 1',
    //     availability: [
    //       { daysOfWeek: [1], startTime: '07:00', endTime: '08:00' },
    //       { daysOfWeek: [1], startTime: '12:00', endTime: '24:00' },
    //     ],
    //   },
    //   {
    //     id: 'doc2',
    //     title: 'Doctor 2',
    //     availability: { daysOfWeek: [2], startTime: '08:00', endTime: '10:00' },
    //   },
    // ];
    return (
      <div className="fullCalendar rcm-panel" style={{ position: 'relative' }}>
        <FullCalendar
          {...CAL_CONFIG}
          resources={resources}
          events={events}
          eventClick={eventClick}
          eventContent={eventContent || this.renderEventContent} // custom render function
          select={select}
          resourceAreaColumns={[
            {
              field: 'title',
              headerContent: 'Providers',
            },
          ]}
          // selectAllow={this.selectAllowCallback}
          // Not Tested Possible Solutions to block resources
          // selectConstraint={info => {
          //   const doctor = doctors.find(doc => doc.id === info.resource.id);
          //   const availability = doctor.availability.find(avail =>
          //     avail.daysOfWeek.includes(info.start.getDay()),
          //   );
          //   return false;
          // }}
        />
      </div>
    );
  }
}
export default Calendar;
