import React, { Component } from 'react';
const HTMLContent = (
  <>
    <div className="ai-marketing">
      <div>
        <div className="row">
          <div className="col-md-1">
            <img src={`images/license/ai3StarPink.svg`} width="40" height="50" alt="" />
          </div>
          <div className="col-md-10 d-flex align-items-center">
            <div className="label">AI Augmentation License</div>
          </div>
          <div className="col-md-1 d-flex align-items-center">
            <div style={{ fontSize: '12px', color: '#b6b8b8' }}>
              {' '}
              <a href="https://support.soberpeer.com/enterprise/augmented-ai" rel="nofollow" target='_blank'>
                Help?
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            {/* <p>
              AI Augmentation feature license is a great tool for understanding patients and
              diagnosing treatment methods. It provides a summary of the patient's condition and
              helps to understand the best care options. Tokens are required to do the augmentation,
              which are priced at $1 per 1000 tokens. Once the set number of tokens has been used
              up, they will be renewed automatically.
            </p> */}
            <p className="text-justify">
              AI Augmentation is a great tool for understanding clients more deeply. It provides a
              summary of the client's condition and helps save you time when analyzing the next best
              care option. It also makes understanding more consistent and uncovers subtle
              inconsistencies that may be present in the client's background, approaches to care
              that are based on your client's survey data.
            </p>
            <p className="text-justify">
              To use Augmentation you need a center license and use Tokens. Tokens are required to
              run augmented summaries. A small number of Tokens are consumed each time you run an
              augmentation. When your Token balance is low they will be renewed automatically for
              you.
            </p>
            <p>
              Cost of your center license is <b>$300</b> /center/month.
            </p>
            <p>
              Cost of your tokens starts at <b>$50</b> for 5000 tokens.
            </p>
          </div>
        </div>
        <div className="row mt-3 d-none">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <p style={{ color: '#7F8188', fontSize: '16px', margin: '0px' }}>Billing Type</p>
                <p>Monthly</p>
              </div>
              <div className="col-md-4">
                <p style={{ color: '#7F8188', fontSize: '16px', margin: '0px' }}>
                  Modules Included
                </p>
                <p>4 modules</p>
              </div>
              <div className="col-md-4">
                <p style={{ color: '#7F8188', fontSize: '16px', margin: '0px' }}> User Type</p>
                <p>Admin & Staff</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <p>
              <span style={{ fontSize: '26px', fontWeight: '700' }}>$300</span> /center/month.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="row mt-3 m-2 d-none">
          <div className="col-md-12 p-4" style={{ border: '1px solid #EAEAEA' }}>
            <h5>What you will get:</h5>
            <ul>
              <li>
                <p className="m-0">Feature 1:</p>
                <p style={{ color: '#7F8188' }}>
                  AI can help identify patterns in patient symptoms and recommend the most effective
                  treatment options based on their individual needs.
                </p>
              </li>
              <li>
                <p className="m-0">Feature 2:</p>
                <p style={{ color: '#7F8188' }}>
                  AI-powered chatbots and virtual assistants can provide round-the-clock mental
                  health support and counseling to patients, providing them with access to help when
                  they need it most. Chatbots and virtual assistants can also help triage patients
                  and direct them to the appropriate level of care.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-3 m-2 d-none">
          <div className="col-md-12 p-4" style={{ border: '1px solid #EAEAEA' }}>
            <h5>Payment Policy</h5>
            <p>
              Quid securi etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res
              quas ex communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid
              securi etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid securi
              etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid securi
              etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid securi
              etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh.
            </p>
          </div>
        </div>
        <div className="row mt-3 m-2 d-none">
          <div className="col-md-12 p-4" style={{ border: '1px solid #EAEAEA' }}>
            <h5>Terms & Conditions</h5>
            <p>
              Quid securi etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res
              quas ex communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid
              securi etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid securi
              etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid securi
              etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh. Quid securi
              etiam tamquam eu fugiat nulla pariatur. Praeterea iter est quasdam res quas ex
              communi. Morbi odio eros, volutpat ut pharetra vitae, lobortis sed nibh.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);
export default HTMLContent;