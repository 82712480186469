import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';

const Insurance = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    isEmployedValue,
    style,
    goToPreviuos,
    disable,
  } = props;
  return (
    <form onSubmit={handleSubmit(handleNext)} style={{ padding: '36px 20px 200px 20px' }}>
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`payorName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Payor Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Payor Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`PayorId`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Payor ID'}
              disabled={disable}
            />
            <label class="placeholder-label">Payor ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`insuranceAddLine1`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Permanent Address Line 1'}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 1</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`insuranceAddLine2`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Permanent Address Line 2'}
              disabled={disable}
            />
            <label class="placeholder-label">Permanent Address Line 2</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`insuranceCity`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'City'}
              disabled={disable}
            />
            <label class="placeholder-label">City</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`insuranceState/Province/Territories`}
              type="text"
              component={ReduxSelect}
              placeholder="State/Province/Territories"
              // options={StateProvince}
              // disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`insurancePostal/Zip`}
              type="text"
              component={ReduxInput}
              fieldName={'Postal/Zip Code'}
              disabled={disable}
            />
            <label class="placeholder-label">Postal/Zip Code</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`contactPerson`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Contact Person'}
              disabled={disable}
            />
            <label class="placeholder-label">Contact Person</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`insurancePhone`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Phone'}
              disabled={disable}
            />
            <label class="placeholder-label">Phone</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`contactEmailAddress`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Email Address'}
              disabled={disable}
            />
            <label class="placeholder-label">Email Address</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`Type`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Type'}
              disabled={disable}
            />
            <label class="placeholder-label">Type</label>
          </div>
        </div>
        <div class="row pagination-button">
          <div class="col-12 text-right">
            <button
              onClick={goToPreviuos}
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  return {
    MaritalStatus: combos.data.MaritalStatus,
    EthnicRace: combos.data.EthnicRace,
    Gender: combos.data.Gender,
    HousingType: combos.data.HousingType,
    EyeColor: combos.data.EyeColor,
    Language: combos.data.Language,
    EducationType: combos.data.EducationType,
    WorkSkill: combos.data.WorkSkill,
    isEmployedValue: selector(state, 'isEmployed'),
  };
})(
  reduxForm({
    form: 'providerEnrollmentForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Insurance),
);

const selector = formValueSelector('providerEnrollmentForm');
