import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import {
  getFormFieldMetaData,
  upsertFormData,
  formDataGet,
  appInvite,
  adminstrationStaffUpsert,
} from '../../../store/actions';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../core/generic-form';
import { StaffMobileAppDetails } from '../../../app-config/screenName';
import { ReduxToggle } from '../../../component/custom-redux-components';
import { ToastContainer, toast } from 'react-toastify';
import Loader, { Loader2 } from '../../../../src/Components/Loader';
import PageTitle from '../../../Components/PageTitle';
class GerericForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      showMobileAppUserForm: props.IsProvider,
      inviteData: {},
    };
  }
  //  typeof values.specialties == 'object'
  //                     ? values.specialties
  //                       ? values.specialties.map(item => item.Text).toString()
  //                       : ''
  //                     : values.specialties || '',
  sendFormData = values => {
    let valueArray = Object.keys(values);
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object') {
         obj[key] =
           values[key] && values[key].length > 0
             ? values[key].map(item => item.Value).toString()
             : null;
      } else {
        obj[key] = values[key];
      }
    }
    let jsonData = {
      DynamicForm: [
        {
          FormName: StaffMobileAppDetails,
          FormDataId: this.props.getStaffId ? this.props.getStaffId : null,
          FormData: {
            Staff_MobileAppDetails: [
              {
                StaffId: this.props.getStaffId,
                IsProvider: values.IsProvider,
                Misc: [
                  {
                    ...obj,
                  },
                ],
              },
            ],
          },
        },
      ],
    };
    this.props.dispatch(
      upsertFormData({
        Json: JSON.stringify(jsonData),
      }),
    );
  };

  componentDidMount() {
    let jsonData = {
      DynamicForm: [
        {
          FormName: StaffMobileAppDetails,
          Id: this.props.getStaffId,
        },
      ],
    };
    this.props.dispatch(
      getFormFieldMetaData({
        Json: JSON.stringify(jsonData),
      }),
    );
  }

  componentWillReceiveProps({
    genericFormData,
    comboData,
    IsProviderValue,
    isUpsertSuccess,
    inviteDetails,
    isAppInviteSent,
    preInviteDetails,
    upsertSucessMsg,
    IsPractitionerTabRequired,
  }) {
    if (
      preInviteDetails &&
      JSON.stringify(preInviteDetails) != JSON.stringify(this.props.preInviteDetails)
    ) {
      this.setState({ inviteData: preInviteDetails });
    }

    if (
      inviteDetails &&
      JSON.stringify(inviteDetails) != JSON.stringify(this.props.inviteDetails)
    ) {
      this.setState({ inviteData: inviteDetails });
    }
    if (isAppInviteSent && isAppInviteSent != this.props.isAppInviteSent) {
      if (this.props.getStaffId) {
        let jsonData2 = {
          DynamicForm: [
            {
              FormName: StaffMobileAppDetails,
              FormDataId: this.props.getStaffId,
            },
          ],
        };
        this.props.dispatch(
          formDataGet({
            Json: JSON.stringify(jsonData2),
          }),
        );
      }
      toast.dismiss();
      toast.success(this.state.inviteData.InviteMsg, {
        position: 'top-center',
        // autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (isUpsertSuccess && isUpsertSuccess != this.props.isUpsertSuccess) {
      // toast.dismiss();
      // toast.success(upsertSucessMsg || 'Well done! Staff has been added successfully.', {
      //   position: 'top-center',
      //   // autoClose: 4000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   // onClose: () => {
      //   //   this.props.history.push('/manage/staff-management/staff-list');
      //   // },
      // });
      if (IsPractitionerTabRequired) {
        this.props.updateStep(this.props.getStaffId, IsPractitionerTabRequired);
      } else {
        // this.props.history.push({
        //   pathname: `${this.props.baseURL}${this.props.listUrl}`,
        //   state: { msg: upsertSucessMsg || 'Well done! Staff has been added successfully.' },
        // });
        this.props.history.push(`${this.props.baseURL}${this.props.listUrl}`);
      }
    }
    if (IsProviderValue != this.props.IsProviderValue) {
      this.setState({ showMobileAppUserForm: IsProviderValue });
    }
    if (
      genericFormData &&
      JSON.stringify(genericFormData) != JSON.stringify(this.props.genericFormData)
    ) {
      let validationConversion = genericFormData;

      this.setState({ formFields: validationConversion });
      if (this.props.getStaffId) {
        let jsonData2 = {
          DynamicForm: [
            {
              FormName: StaffMobileAppDetails,
              FormDataId: this.props.getStaffId,
            },
          ],
        };
        this.props.dispatch(
          formDataGet({
            Json: JSON.stringify(jsonData2),
          }),
        );
      }
    }
  }

  render() {
    const { formFields, optionsList, inviteData } = this.state;
    const {
      handleSubmit,
      comboData,
      onCancel,
      inviteDetails,
      isDeactivated,
      isPhone,
      IsProviderValue,
    } = this.props;
    const { InviteMsg, IsInviteSent, MobileAppInviteMsg, SuccessMsg } = inviteData;
    return (
      <React.Fragment>
        <PageTitle TitleText="Staff" />
        <div className="main enrollment-page  mb-5">
          <div className="container-fluid">
            <form
              // onSubmit={handleSubmit(values => {
              //   this.sendFormData(values);
              // })}
              onSubmit={handleSubmit}
            >
              <div class="row top-heading-row mt-2 mb-3 align-items-center">
                {/* <div class="col-12 col-md-4 col-lg-3">
                  <h3 class="form-main-head mb-3 mb-md-0">
                    {this.props.getStaffId > 0 ? 'Edit Staff' : 'Add a Staff'}
                  </h3>
                </div> */}
              </div>

              <div className="">
                <div className=" enrollment-form pt-2 pb-2">
                  <div className="row">
                    <div class="col-12 col-md-6 col-lg-6">
                      {!isPhone && (
                        <span className="">
                          {' '}
                          <i class="fas fa-info-circle"></i> Please enter mobile phone number in
                          basic details to enable.
                        </span>
                      )}
                      <div class="form-group mt-2">
                        <label class="custom-control-label">
                          <Field
                            name={`IsProvider`}
                            type="text"
                            class="custom-control-input not-empty"
                            component={ReduxToggle}
                            disabled={isDeactivated || !isPhone}
                          />
                          {'   '}
                          Mobile platform user?
                        </label>
                      </div>
                    </div>
                    {this.state.showMobileAppUserForm && (
                      <div class="col-12 col-md-6 col-lg-6 text-right top-heading-row align-items-center d-none">
                        <button onClick={onCancel} class="btn  btn-outline-primary mr-2">
                          Cancel
                        </button>
                        {/* <button
                  onClick={() =>
                    this.props.dispatch(() => {
                      debugger;
                    })
                  }
                  class="btn"
                >
                  Save
                </button> */}
                        <input
                          type="submit"
                          class="btn"
                          value="Submit"
                          // disabled={IsDeActivated}
                        />
                      </div>
                    )}
                  </div>

                  {this.props.IsProviderValue && (
                    <div className="row pb-5 pl-3 pr-3 pt-3">
                      {' '}
                      <div className="col-12 mb-4">
                        <div className="row p-4" style={{ backgroundColor: '#F8F8F8' }}>
                          <Loader2 loading={this.props.isFetching} />
                          <div className="col-md-8">
                            <h5 className="col-12 col-md-6 col-lg-4">Mobile App Invite</h5>
                            <div className="col-12">
                              {MobileAppInviteMsg && <img src="images/icons/info.svg" />}{' '}
                              {MobileAppInviteMsg}
                            </div>
                          </div>
                          <div className="col-md-4 text-center">
                            {!isDeactivated && this.props.mobileAppRoleValue && (
                              <>
                                {IsInviteSent ? (
                                  <div className="d-flex align-items-end flex-column">
                                    <span style={{ color: '#2FE192' }}>Invitation sent</span>
                                    <span
                                      style={{
                                        color: '#009EF7',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                      }}
                                      onClick={() => {
                                        this.props.dispatch(
                                          appInvite({
                                            StaffId: this.props.getStaffId,
                                            MobileAppRole: this.props.mobileAppRoleValue,
                                            // PhoneNumber: '214-555-7868',
                                          }),
                                        );
                                      }}
                                    >
                                      Click here to resend the invite
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <span
                                      className="btn btn-outline-primary"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        this.props.dispatch(
                                          appInvite({
                                            StaffId: this.props.getStaffId,
                                            MobileAppRole: this.props.mobileAppRoleValue,
                                            // PhoneNumber: '214-555-7868',
                                          }),
                                        );
                                      }}
                                    >
                                      Invite
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 staff-form">
                        <div className="row">
                          <Loader2 loading={this.props.isFetchingStaff} />

                          <DynamicForm formFields={formFields} disable={isDeactivated} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
            <ToastContainer
              position="top-center"
              autoClose={50000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { combos, get_form_field_meta_data, staff_upsert, app_invite, form_data_get } = state;
  const { data: getGenericFormFields } = get_form_field_meta_data;
  const { data: upsertData, isFetching: isFetchingStaff } = staff_upsert;
  const { data: InviteData, error, isFetching } = app_invite;
  const { data } = form_data_get;

  const IsProviderValue = formValueSelector('staffForm_appDetail')(state, 'IsProvider');
  const mobileAppRoleValue = formValueSelector('staffForm_appDetail')(state, 'MobileAppRole');
  const LevelOfCaresSelector = formValueSelector('financial');
  let comboData = {};
  let genericFormData = [];
  let isUpsertSuccess;
  let isAppInviteSent;
  let inviteDetails;
  let preInviteDetails = {};
  let upsertSucessMsg;
  if (combos && combos.data) {
    comboData = combos.data || {};
  }
  if (getGenericFormFields) {
    genericFormData = getGenericFormFields.FormFieldMetaData;
  }
  if (upsertData && upsertData.success) {
    upsertSucessMsg = JSON.parse(upsertData.data).Staff[0].InviteDetails[0].SucessMsg;
    isUpsertSuccess = upsertData.success;
  }
  if (InviteData && InviteData.success) {
    isAppInviteSent = InviteData.success;
    inviteDetails = JSON.parse(InviteData.data).Staff[0].InviteDetails[0];
  }
  if (data && data.Staff[0]) {
    preInviteDetails = data.Staff[0].InviteDetails[0];
  }
  return {
    genericFormData,
    comboData,
    IsProviderValue,
    isUpsertSuccess,
    mobileAppRoleValue,
    isAppInviteSent,
    inviteDetails,
    isFetching,
    preInviteDetails,
    upsertSucessMsg,
    isFetchingStaff,
  };
};

const ReduxGenericForm = reduxForm({
  form: 'staffForm_appDetail',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    let valueArray = Object.keys(values);
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object') {
         obj[key] =
           values[key] && values[key].length > 0
             ? values[key].map(item => item.Value).toString()
             : null;
      } else {
        obj[key] = values[key];
      }
    }

    let data = {
      Staff: [
        {
          StaffId: props.getStaffId,
          IsProvider: values.IsProvider,
          IsFinalSubmit: true,
          Misc: values.IsProvider
            ? [
                {
                  ...obj,
                },
              ]
            : null,
        },
      ],
    };
    let staffData = {
      staffId: props.getStaffId,
      email: props.EmailWork,
      jsonInput: JSON.stringify(data),
    };
    if (props.EmailWork) {
      dispatch(adminstrationStaffUpsert(staffData));
    }
  },
})(connect(mapStateToProps)(withRouter(GerericForm)));

// const selector = formValueSelector('staffForm_appDetail');

class GenericFormParent extends React.Component {
  constructor(props) {
    super(props);
    const isPhone = props.PhoneMobile.length > 0;
    this.state = {
      initialValues: {
        IsProvider: isPhone,
        MobileAppRole: 'P',
      },
    };
  }
  componentWillReceiveProps({ staffForm, IsFinalSubmit }) {
    if (staffForm && JSON.stringify(staffForm) != JSON.stringify(this.props.staffForm)) {
      if (staffForm.IsProvider) {
        let appData = (staffForm.Misc && staffForm.Misc[0]) || {};
        this.setState({
          staffForm: staffForm,
          initialValues: {
            IsProvider: staffForm.IsProvider,
            ...appData,
          },
        });
      } else {
        this.setState({
          staffForm: staffForm,
          initialValues: {
            IsProvider: staffForm.IsProvider,
          },
        });
      }
    }
  }
  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };

  render() {
    return (
      <>
        <ReduxGenericForm
          initialValues={this.state.initialValues}
          getStaffId={this.getStaffId()}
          onCancel={() => this.props.history.push(`${this.props.baseURL}${this.props.listUrl}`)}
          IsProvider={this.props.IsProvider}
          IsPractitionerTabRequired={this.props.IsPractitionerTabRequired}
          EmailWork={this.props.EmailWork}
          PhoneMobile={this.props.PhoneMobile}
          isPhone={this.props.PhoneMobile.length > 0}
          isDeactivated={this.props.isDeactivated}
          updateStep={this.props.updateStep}
          baseURL={this.props.baseURL}
          listUrl={this.props.listUrl}

          // inviteDetails={this.props.inviteDetails}
        />
      </>
    );
  }
}

const mapStateToParent = state => {
  const { form_data_get } = state;
  const { data, isFetching, error } = form_data_get;
  let inviteDetails = {};
  let EmailWork;
  let isDeactivated;
  let IsFinalSubmit = false;
  let staffForm;
  let IsProvider = false;
  let IsPractitionerTabRequired = false;
  let PhoneMobile = '';
  if (data && data.Staff[0]) {
    staffForm = data.Staff[0];
    IsProvider = data.Staff[0].IsProvider;
    EmailWork = data.Staff[0].EmailWork;
    isDeactivated = data.Staff[0].IsDeactivated;
    inviteDetails = data.Staff[0].InviteDetails[0];
    IsFinalSubmit = data.Staff[0].IsFinalSubmit;
    PhoneMobile = data.Staff[0].PhoneMobile;
    IsPractitionerTabRequired = data.Staff[0].IsPractitionerTabRequired;
  }

  return {
    isFetching,
    staffForm,
    IsProvider,
    inviteDetails,
    EmailWork,
    isDeactivated,
    IsFinalSubmit,
    PhoneMobile,
    IsPractitionerTabRequired,
  };
};

export default connect(mapStateToParent)(withRouter(GenericFormParent));
