import React from 'react';
import { connect } from 'react-redux';
import { clientReportGet, smartContractGet } from '../../../../store/actions';
import ReactApexRiskChart from './ReactApexRiskChart';
import BundleCollapse from '../../components/milestonesCollapseView/bundleCollapse';

class RiskChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskResponse: null,
      riskChartInfo: [],
    };
  }
  componentDidMount() {
    let data = {
      Json: JSON.stringify({
        SmartContract: [
          {
            ClientId: this.props.clientId,
            // ClientId: 20000275010,
            SmartContractId: this.props.contractId,
          },
        ],
      }),
    };
    this.props.dispatch(clientReportGet(data));

    let RiskData = {
      SmartContract: [{ ClientId: this.props.clientId, SmartContractId: this.props.contractId }],
    };

    var smartContractData = {
      json: JSON.stringify(RiskData),
    };
    this.props.dispatch(smartContractGet(smartContractData));
  }

  render() {
    const {
      viewHistory,
      riskChartInfo,
      smartContractMilestones,
      tabsCount,
      goToPreviuos,
      currentTab,
      disable,
      goToNext,
      smartContractBundles,
    } = this.props;
    return (
      <div>
        {riskChartInfo && riskChartInfo.length > 0 ? (
          <div class="card border-0 mb-3">
            <div class="card-body block">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <h6>Risk Factors</h6>
                  {/* {viewHistory && (
                      <span
                        onClick={viewHistory}
                        style={{
                          color: '#009EF7',
                          cursor: 'pointer',
                          fontSize: '14px',
                          margin: '0px 5px',
                        }}
                      >
                        View History
                      </span>
                    )} */}
                </div>
                {/* <div className="d-flex">
                    <ClientRiskChart
                      ClientId={this.props.ClientId}
                      ClientRisk={this.state.riskResponse}
                    />
                  </div> */}
              </div>

              <div>
                {/* { debugger} */}
                <ReactApexRiskChart riskChartInfo={riskChartInfo} />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '300px' }}
          >
            <div
              className="d-flex align-items-center flex-column justify-content-center mb-3"
              style={{
                backgroundColor: '#F8F8F8',
                borderRadius: '7px',
                position: 'relative',
                // top: '46%',
                // left: '40%',
                // transform: 'translate(-50%, -50%)',
                width: '335px',
                padding: '35px',
              }}
            >
              <img
                src="images/sad.svg"
                className="mb-1"
                style={{ height: '35px', width: '35px' }}
              />
              <h4 className="mb-0" style={{ fontSize: '21px' }}>
                Uh ohh!
              </h4>
              <p className="mb-0 text-center">No data available for the risk factor graph.</p>
            </div>
          </div>
        )}
        <h5 className="mt-5"> Milestones</h5>
        <div className="card border-0 p-4">
          <BundleCollapse bundles={smartContractBundles} />
        </div>
        <div class="col-12 text-right mt-3">
          {(currentTab > 0 || currentTab + 1 == tabsCount) && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="button"
              onClick={goToNext}
              class="btn btn-eForm-Next"
              disabled={disable}
              style={{ padding: '10px 39px' }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { risk_chart_get, client_report_get, smart_contract_get } = state;
  const { data: RiskChartData } = client_report_get;
  const { data: smartContractDetails, isFetching } = smart_contract_get;

  let riskChartInfo = [];
  let smartContractMilestones = [];
  let smartContractBundles = [];

  if (RiskChartData && RiskChartData.ClientReport) {
    let riskFactors = RiskChartData.ClientReport[0].RiskFactors;
    // let riskArray = []
    riskFactors &&
      riskFactors.map((item, index) => {
        riskChartInfo.push({
          Value: item.ScoreCategory,
          Risk:
            item.ScoreCategory == 1
              ? 'Low Risk'
              : item.ScoreCategory == 3
              ? 'Medium Risk'
              : 'High Risk',
          ShortQuestion: item.SurveyName,
        });
      });

    smartContractMilestones =
      RiskChartData.ClientReport[0] && RiskChartData.ClientReport[0].Milestones
        ? RiskChartData.ClientReport[0].Milestones
        : [];
  }
  if (smartContractDetails && smartContractDetails.SmartContract) {
    smartContractBundles =
      smartContractDetails.SmartContract[0] && smartContractDetails.SmartContract[0].Bundle
        ? smartContractDetails.SmartContract[0].Bundle
        : [];
  }

  return {
    riskChartInfo,
    smartContractMilestones,
    smartContractBundles,
  };
};

export default connect(mapStateToProps)(RiskChart);
