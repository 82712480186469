import React, { useState, useEffect } from 'react';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = false;
mic.interimResults = false;
mic.lang = 'en-US';

function SpeechToText({ handleDictate }) {
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    try {
      if (isListening) {
        mic.start();
        mic.onend = () => {
          console.log('continue..');
          mic.start();
        };
      } else {
        mic.stop();
        mic.onend = () => {
          console.log('Stopped Mic on Click');
        };
      }
      mic.onstart = () => {
        console.log('Mics on');
      };

      mic.onresult = event => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('');
        console.log(transcript);
        // console.log('event', event.results);
        handleDictate && handleDictate(transcript);
        // setNote(transcript);
        mic.onerror = event => {
          console.log(event.error);
        };
      };
    } catch (e) {}
  };

  // const handleSaveNote = () => {
  //   setSavedNotes([...savedNotes, note]);
  //   setNote('');
  // };

  return (
    <>
      <div
        onClick={() => {
          setIsListening(prevState => !prevState);
        }}
        className="mic-container"
        style={{ position: 'relative', top: '3px' }}
      >
        {isListening ? (
          <div style={{ width: '50px', padding: '3px 2px 0 3px' }}>
            <img
              src="./images/sound.gif"
              style={{ width: '25px', height: '18px', 'padding-right': '5px' }}
              alt="listening"
            />
            <img className="mic-icon" src="./images/StopButton_1.png" alt="Stop Dictating" />
          </div>
        ) : (
          <div className={isListening ? undefined : 'mic-notes'}>
            <img className={`mic-icon`} src="./images/Group.svg" alt="Start Dictating" />
          </div>
        )}
      </div>
    </>
  );
}

export default SpeechToText;
