import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
const BGContent = props => {
  const {
    labels,
    backGroundImage,
    moduleLogoImage,
    moduleName,
    tagLine,
    buyButtonText,
    labelTitle,
    trialButtonText,
    handleStartTrial,
    buyLinkText,
  } = props;
  return (
    <>
      <div className="row bg-white mt-4  m-1 trialNotStarted">
        <div
          //   className="col-12 mt-2 mb-2 px-0"
          className="col-12 px-0"
        >
          <div style={{ position: 'relative' }}>
            <img src={backGroundImage} alt="" style={{ width: '100%' }} />
            <div className="section">
              <div className="d-flex justify-content-center">
                <span style={{ position: 'relative', marginRight: '20px' }}>
                  <img src={moduleLogoImage} alt="" style={{ width: '100%', height: '64px' }} />
                </span>
              </div>

              <p className="heading">{moduleName}</p>
              <p className="tagLine">{tagLine}</p>
              {labels && (
                <p style={{ fontWeight: '700', fontSize: '24px', margin: '0px' }}>{labelTitle}</p>
              )}

              {labels &&
                labels.map(item => {
                  return <span className="labels">{item}</span>;
                })}

              {buyButtonText && (
                <div>
                  <button
                    className="btn text-center mt-3 mb-3"
                    onClick={() => {
                      props.history.push('/manage/maintain-license');
                    }}
                  >
                    {buyButtonText}
                  </button>
                </div>
              )}

              {trialButtonText && (
                <div>
                  <button
                    className="btn text-center mt-3 mb-3"
                    onClick={() => {
                      handleStartTrial();
                    }}
                  >
                    {trialButtonText}
                  </button>
                </div>
              )}
              {buyLinkText && (
                <div>
                  <span
                    className="btn-link text-center mt-3 mb-3"
                    onClick={() => {
                      props.history.push('/manage/maintain-license');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {buyLinkText}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(BGContent);
