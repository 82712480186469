import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
} from '../../../../../component/custom-redux-components';
import Validator from '../../../../Validation';

const AppointmentForm = ({
  disable,
  Practitioner,
  ServiceCodeList,
  ProgramType,
  PlaceOfService,
  Status,
  PractitionerOffice,
  change,
}) => {
  return (
    <div class="row mb-4">
      <div class="col-6">
        <div class="form-group">
          <Field
            name={`EventTitle`}
            type="text"
            component={ReduxInput}
            fieldName={'Event Title'}
            validate={Validator.required}
            disabled={disable}
          />
          <label class="placeholder-label">Add Title*</label>
        </div>
      </div>
      <div class="col-6"></div>
      <div className="col-6">
        <div class="form-group">
          <Field
            name="AppointmentDate"
            type="text"
            component={ReduxDateInput}
            errorMessage="Appointment Date Required"
            label={'Appointment Date*'}
            validate={Validator.required}
            minDate={new Date()}
            disabled={disable}
          />
          <i class="far fa-calendar-alt form-control-icon" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <Field
            name={`Program`}
            type="text"
            component={ReduxSelect}
            fieldName={'Program'}
            options={ProgramType}
            // validate={Validator.required}
            placeholder="Program"
            disabled={disable}
          />
        </div>
      </div>
      <div className="col-6">
        <div class="form-group">
          <Field
            name="StartTime"
            type="text"
            component={ReduxTimePicker}
            fieldName={'Start Time'}
            validate={Validator.required}
            label={'Start Time*'}
            disabled={disable}
            onChange={val => {
              change(
                'EndTime',
                moment(val._d)
                  .add(30, 'minutes')
                  .format('HH:mm:ss'),
              );
            }}
          />
        </div>
      </div>
      <div className="col-6">
        <div class="form-group">
          <Field
            name="EndTime"
            type="text"
            component={ReduxTimePicker}
            fieldName={'End Time'}
            validate={[Validator.required, Validator.endTime]}
            label={'End Time*'}
            // minuteStep={5}
            disabled={disable}
          />
        </div>
      </div>
      {/* <div class="col-12 col-md-6">
        <div class="form-group">
          <Field
            name={`ServiceCode`}
            type="text"
            component={ReduxInput}
            fieldName={'Service Code'}
            // validate={Validator.required}
            disabled={true}
          />
          <label class="placeholder-label">Service Code</label>
        </div>
      </div> */}
      <div class="col-12">
        <div class="form-group">
          <Field
            name={`ServiceCode`}
            type="text"
            component={ReduxSelect}
            fieldName={'Service Code'}
            options={ServiceCodeList}
            validate={Validator.required}
            placeholder="Service Code*"
            disabled={disable}
            isMulti
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <Field
            name={`PractitionerId`}
            type="text"
            component={ReduxSelect}
            fieldName={'Provider Name'}
            options={Practitioner}
            validate={Validator.required}
            placeholder="Provider Name*"
            disabled={disable}
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <Field
            name={`PlaceOfServiceId`}
            type="text"
            component={ReduxSelect}
            fieldName={'Place of Service'}
            options={PlaceOfService}
            validate={Validator.required}
            placeholder="Place of Service*"
            disabled={disable}
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <Field
            name={`ReasonForVisit`}
            type="text"
            component={ReduxInput}
            fieldName={'Reason for Visit'}
            // validate={Validator.required}
            disabled={disable}
          />
          <label class="placeholder-label">Reason for Visit</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <Field
            name={`OfficeName`}
            type="text"
            component={ReduxSelect}
            fieldName={'Office Name'}
            options={PractitionerOffice}
            disabled={disable}
            validate={Validator.required}
            placeholder="Office Name*"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <Field
            name={`Status`}
            type="text"
            component={ReduxSelect}
            fieldName={'Status'}
            options={Status}
            disabled={disable}
            validate={Validator.required}
            placeholder="Status*"
          />
        </div>
      </div>
      <div className="col-12">
        <div class="form-group mb-2">
          <Field
            name="Notes"
            type="textarea"
            component={ReduxTextarea}
            fieldName={'Notes'}
            disabled={disable}
          />
          <label class="placeholder-label" style={{ background: '#fefefe' }}>
            Notes
          </label>
        </div>
      </div>
    </div>
  );
};

export default AppointmentForm;
