import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* serviceCodeBundleUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeBundleUpsert(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_BUNDLE_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_BUNDLE_UPSERT_FAILURE,
    });
  }
}

export function* serviceCodeBundleDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeBundleDelete(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_BUNDLE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_BUNDLE_DELETE_FAILURE,
    });
  }
}

export function* serviceCodeBundleGetAll(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeBundleGetAll(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_BUNDLE_GET_ALL_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_BUNDLE_GET_ALL_FAILURE,
    });
  }
}

export function* serviceCodeBundleGet(action) {
  try {
    const { payload } = action;
    const response = yield API.serviceCodeBundleGet(payload);
    yield put({
      type: ActionTypes.SERVICE_CODE_BUNDLE_GET_SUCCESS,
      payload: {
        ...JSON.parse(response.data.data),
        bundleIndex: payload.bundleIndex,
        goalIndex: payload.goalIndex},
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SERVICE_CODE_BUNDLE_GET_FAILURE,
    });
  }
}

export function* smartContractUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractUpsert(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_UPSERT_FAILURE,
    });
  }
}

export function* smartContractGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_GET_FAILURE,
    });
  }
}

export function* clientInsuranceGet(action) {
  try {
    const { payload } = action;
    const response = yield API.clientInsuranceGet(payload);
    yield put({
      type: ActionTypes.CLIENT_INSURANCE_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_INSURANCE_GET_FAILURE,
    });
  }
}

export function* smartContractRequestGet(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractRequestGet(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_REQUEST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_REQUEST_GET_FAILURE,
    });
  }
}

export function* smartContractRequestUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.smartContractRequestUpdate(payload);
    yield put({
      type: ActionTypes.SMART_CONTRACT_REQUEST_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SMART_CONTRACT_REQUEST_UPDATE_FAILURE,
    });
  }
}

export function* centerLaborBurdenGet(action) {
  try {
    const { payload } = action;
    const response = yield API.centerLaborBurdenGet(payload);
    yield put({
      type: ActionTypes.CENTER_LABOR_BURDEN_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_LABOR_BURDEN_GET_FAILURE,
    });
  }
}

export function* centerLaborBurdenUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.centerLaborBurdenUpsert(payload);
    yield put({
      type: ActionTypes.CENTER_LABOR_BURDEN_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CENTER_LABOR_BURDEN_UPSERT_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.SERVICE_CODE_BUNDLE_UPSERT_REQUEST, serviceCodeBundleUpsert),
    takeLatest(ActionTypes.SERVICE_CODE_BUNDLE_GET_ALL_REQUEST, serviceCodeBundleGetAll),
    takeLatest(ActionTypes.SERVICE_CODE_BUNDLE_GET_REQUEST, serviceCodeBundleGet),
    takeLatest(ActionTypes.SMART_CONTRACT_UPSERT_REQUEST, smartContractUpsert),
    takeLatest(ActionTypes.SMART_CONTRACT_GET_REQUEST, smartContractGet),
    takeLatest(ActionTypes.SMART_CONTRACT_REQUEST_GET_REQUEST, smartContractRequestGet),
    takeLatest(ActionTypes.SMART_CONTRACT_REQUEST_UPDATE_REQUEST, smartContractRequestUpdate),
    takeLatest(ActionTypes.CLIENT_INSURANCE_GET_REQUEST, clientInsuranceGet),
    takeLatest(ActionTypes.SERVICE_CODE_BUNDLE_DELETE_REQUEST, serviceCodeBundleDelete),
    takeLatest(ActionTypes.CENTER_LABOR_BURDEN_GET_REQUEST, centerLaborBurdenGet),
    takeLatest(ActionTypes.CENTER_LABOR_BURDEN_UPSERT_REQUEST, centerLaborBurdenUpsert),
  ]);
}