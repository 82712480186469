import React from 'react';
import { connect } from 'react-redux';
import {
  centerLaborBurdenGet,
  centerLaborBurdenUpsert,
} from '../../../store/actions';
import CustomModal from '../../../core/modal';
import { Loader2 } from '../../../Components/Loader';
class OverHeadCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = { centerLaborBurden :0};
  }
  componentDidMount() {
    this.props.dispatch(centerLaborBurdenGet({}));
  }

  componentWillReceiveProps({ centerLaborBurden, iscenterLaborBurdenUpdated }) {
    if (centerLaborBurden && centerLaborBurden != this.props.centerLaborBurden) {
      this.setState({ centerLaborBurden });
      this.props.getCurrentLaborBurden(centerLaborBurden);
    }

    if (
      iscenterLaborBurdenUpdated &&
      iscenterLaborBurdenUpdated != this.props.iscenterLaborBurdenUpdated
    ) {
      this.setState({
        ...this.state,
        iscenterLaborBurdenUpdated,
      });
      // this.toggle('Center Overhead Cost updated Successfully');
    }
  }

  handleOverHeadChange = value => {
    let number = this.state.centerLaborBurden;
    if (number >= 0 && number <= 100) {

      let tempCenterLaborBurden;

      if (value == 'Plus') {
        if (this.state.centerLaborBurden + 1 == 101) {
          tempCenterLaborBurden = 100;
        } else {
          tempCenterLaborBurden = this.state.centerLaborBurden + 1;
        }
      } else {
        if (this.state.centerLaborBurden == 0 || this.state.centerLaborBurden == null) {
          tempCenterLaborBurden = 0;
        } else {
          tempCenterLaborBurden = this.state.centerLaborBurden - 1;
        }
      }
      this.props.getCurrentLaborBurden(tempCenterLaborBurden);
      this.setState({
        centerLaborBurden: tempCenterLaborBurden,
      });
      this.props.dispatch(
        centerLaborBurdenUpsert({
          jsonInput: JSON.stringify({
            CenterLaborBurden: [
              {
                BurdenValue: tempCenterLaborBurden,
              },
            ],
          }),
        }),
      );
    }
  };

  handleOnChange = event => {
    let number = event.target.value ? parseInt(event.target.value) : 0;
    if (number >= 0 && number <= 100) {
      let tempCenterLaborBurden = number ? number : null;
      this.setState({
        centerLaborBurden: tempCenterLaborBurden,
      });
      this.props.getCurrentLaborBurden(tempCenterLaborBurden);
    }
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Loader2 loading={this.props.isFetching} />
        <div className="container-fluid mt-1">
          <div className="row">
            {' '}
            <div class="col-12 col-xl-12 mt-3 text-right">
              <div class="license-quantity d-flex  justify-content-end align-items-center">
                <label class="placeholder-label" style={{marginBottom:"unset",fontWeight:"800"}}>Set Labor Burden %</label>
                <div class="quantity-btn">
                  <div class="numbers-row">
                    <input
                      name={'centerLaborBurden'}
                      class="number-icon numbers-only"
                      type="number"
                      min="0"
                      max="3"
                      value={this.state.centerLaborBurden}
                      onChange={this.handleOnChange}
                      onBlur={() => {
                        this.props.dispatch(
                          centerLaborBurdenUpsert({
                            jsonInput: JSON.stringify({
                              CenterLaborBurden: [
                                {
                                  BurdenValue:
                                    this.state.centerLaborBurden &&
                                    this.state.centerLaborBurden.toString()
                                      ? this.state.centerLaborBurden
                                      : 0,
                                },
                              ],
                            }),
                          }),
                        );
                      }}
                      onWheel={event => {
                        window.document.activeElement.blur();
                      }}
                      onKeyDown={evt =>
                        ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()
                      }
                      // onChange={this.getCapacityCount}
                      //   validate={Validator.minValue}
                      tabindex="0"
                      style={{
                        textAlign: 'center',
                        margin: '0px 5px',
                        height: '32px',
                        width: '56px',
                        fontSize: '16px',
                      }}
                    />
                    <div
                      style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                      class="add quantity-button"
                      onClick={() => {
                        this.handleOverHeadChange('Plus');
                      }}
                    >
                      +
                    </div>
                    <div
                      style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                      class="remove quantity-button"
                      onClick={() => {
                        this.handleOverHeadChange('Minus');
                      }}
                    >
                      -
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToPropsParent = ({
  center_labor_burden_upsert,
  center_labor_burden_get,
}) => {
  let centerLaborBurden = null;
  let iscenterLaborBurdenUpdated = null;
  let isFetching = false;
  if (center_labor_burden_get && center_labor_burden_get.data) {
    centerLaborBurden =
      center_labor_burden_get.data.data &&
      center_labor_burden_get.data.data[0] && center_labor_burden_get.data.data[0].BurdenValue;
  }

  if (center_labor_burden_get && center_labor_burden_get.isFetching) {
    isFetching = center_labor_burden_get.isFetching;
  }

  if (
    center_labor_burden_upsert &&
    center_labor_burden_upsert.data &&
    center_labor_burden_upsert.data.success
  ) {
    iscenterLaborBurdenUpdated = center_labor_burden_upsert.data.success;
  }

  return {
    centerLaborBurden,
    iscenterLaborBurdenUpdated,
    isFetching,
  };
};
export default connect(mapStateToPropsParent)(OverHeadCost);
