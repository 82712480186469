import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Drawer, Table, Modal, Tooltip } from 'antd';
import CloseIcon from '../../../../../Components/CloseIcon';
import ManageAppointment from './ManageAppointment';
import CustomModal from '../../../../../core/modal';
import {
  studentBanner,
  medicateCombos,
  practitionerCombos,
  practitionerCombosOffice,
  getComboFacility,
  comboServiceCodeGet,
  upsertAppointment,
  upsertAppointmentReset,
  listSmartCombos,
  getClientAppointment,
} from '../../../../../store/actions';
import { ProcessCombos } from '../../../../../store/comboConfig';
import { getMMDDYYYYFormat } from '../../../../../util/dateFormater';
import { mapTime } from '../../../../../util/time';

const CreateAppointment = props => {
  const dispatch = useDispatch();
  const [isAppointmentDrawer, setAppointmentDrawer] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [optionData, setOptionData] = useState({});
  const { ID, ClientId, isListMenu } = props;
  const {
    BannerData,
    PractitionerList,
    ServiceCodeList,
    combosDataList,
    Status,
    PractitionerOffice,
  } = useSelector(
    ({
      practitioner_combos: { data: practitionerData },
      combo_service_code_get: { data: comboServiceCodeData },
      combos: { data: combosData },
      medicate_combos: { data: medicateCombos },
      get_combo_facility: { data: comboFacilitydData },
      student_banner: { data: bannerData },
    }) => {
      return {
        BannerData: bannerData,
        PractitionerList: practitionerData && practitionerData.PractitionerList,
        ServiceCodeList: comboServiceCodeData && comboServiceCodeData.data,
        combosDataList: combosData && combosData,
        Status: medicateCombos && medicateCombos.Status,
        PractitionerOffice: comboFacilitydData && comboFacilitydData.Facility,
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (isAppointmentDrawer) {
      dispatch(
        studentBanner({
          Json: JSON.stringify({
            Student: [
              {    
                StudentId: ID,
              },
            ],
          }),
        }),
      );
      // var result = ProcessCombos('RCM_PlaceOfService,RCM_Program');
      // if (result.isProcess) {
      //   dispatch(result.processItems);
      // }
       dispatch(
         listSmartCombos({
           comboTypes: 'RCM_PlaceOfService,RCM_Program',
           Json: JSON.stringify({
             Combo: [
               {
                 IsPeerRecovery: true,
               },
             ],
           }),
         }),
       );
      dispatch(
        medicateCombos({
          ComboTypes: 'Status',
        }),
      );
      dispatch(practitionerCombos({}));
      dispatch(practitionerCombosOffice({}));
      dispatch(getComboFacility({}));
      dispatch(
        comboServiceCodeGet({
          Json: JSON.stringify({
            Combo: [
              {
                IsPeerRecovery: true,
              },
            ],
          }),
        }),
      );
    }
    if (combosDataList) {
      setOptionData({
        ...optionData,
        ProgramType: combosDataList.RCM_Program || [],
        PlaceOfService: combosDataList.RCM_PlaceOfService || [],
      });
    }
  }, [isAppointmentDrawer]);
  useEffect(() => {
    if (PractitionerList) {
      let Practitioner = PractitionerList.filter(provider => provider.IsArchived === false) || [];
      setOptionData({
        ...optionData,
        Practitioner: Practitioner,
      });
    }
  }, [PractitionerList]);
  useEffect(() => {
    if (ServiceCodeList) {
      let serviceCode = JSON.parse(ServiceCodeList).Combo || [];
      setOptionData({
        ...optionData,
        ServiceCodeList: serviceCode || [],
      });
    }
  }, [ServiceCodeList]);
  useEffect(() => {
    if (combosDataList) {
      setOptionData({
        ...optionData,
        ProgramType: combosDataList.RCM_Program || [],
        PlaceOfService: combosDataList.RCM_PlaceOfService || [],
      });
    }
  }, [combosDataList]);
  useEffect(() => {
    if (Status) {
      setOptionData({
        ...optionData,
        Status: Status || [],
      });
    }
  }, [Status]);
  useEffect(() => {
    if (PractitionerOffice) {
      setOptionData({
        ...optionData,
        PractitionerOffice: PractitionerOffice || [],
      });
    }
  }, [PractitionerOffice]);
  const scheduleAppointments = () => {
    setAppointmentDrawer(true);
  };
  const handleAppointment = val => {
    let data = {
      Appointment: [
        {
          IsPeerRecovery: true,
          ClientId: BannerData.CurrentClientId,
          StudentId: BannerData.StudentId,
          AppointmentId: val.AppointmentId,
          Title: val.EventTitle,
          AppointmentDate: getMMDDYYYYFormat(val.AppointmentDate),
          ProgramId: val.Program,
          AppointmentTime: val.StartTime && mapTime(val.StartTime),
          AppointmentEndTime: val.EndTime && mapTime(val.EndTime),
          ServiceCode: val.ServiceCode,
          PractitionerId: val.PractitionerId,
          PlaceOfServiceId: val.PlaceOfServiceId,
          OfficeName: val.OfficeName,
          Status: val.Status,
          ReasonForVisit: val.ReasonForVisit,
          Notes: val.Notes,
        },
      ],
    };
    var appointmentData = {
      json: JSON.stringify(data),
    };
    dispatch(upsertAppointment(appointmentData));
  };

  const getAppointmentList = ClientId => {
    dispatch(
      getClientAppointment({
        Json: JSON.stringify({
          Appointment: [
            {
              IsPeerRecovery: true,
              ClientId: ClientId,
            },
          ],
        }),
      }),
    );
  };

  return (
    <>
      <p
        className={isListMenu ? '' : 'btn mb-0'}
        onClick={scheduleAppointments}
        style={{
          cursor: 'pointer',
          display: 'block',
        }}
      >
        Add Appointment
      </p>

      {isAppointmentDrawer && (
        <Drawer
          className="peer-recovery-drawer"
          title={
            <div className="drawer-title">
              <span className="title">
                {initialData.AppointmentId ? `Edit` : `Add`} Appointment
              </span>
            </div>
          }
          placement="right"
          open={isAppointmentDrawer}
          width={700}
          bodyStyle={{
            paddingTop: '5px',
            paddingBottom: '20px',
            background: '#fff',
          }}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={() => {
            setAppointmentDrawer(false);
            setInitialData({});
            dispatch(upsertAppointmentReset());
          }}
        >
          <ManageAppointment
            handleAppointment={handleAppointment}
            initialValues={initialData}
            AppointmentId={initialData.AppointmentId}
            ClientId={ClientId}
            BannerData={BannerData}
            OptionData={optionData}
            onClose={() => {
              setAppointmentDrawer(false);
              setInitialData({});
              dispatch(upsertAppointmentReset());
            }}
            getAppointmentList={getAppointmentList}
            //   getCallEndDetails={this.getCallEndDetails}
          />
        </Drawer>
      )}
    </>
  );
};

export default CreateAppointment;
