import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import Loader, { Loader2 } from '../../../Components/Loader';
import CustomModal from '../../../core/modal';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Avatar, Cascader } from 'antd';
import { Checkbox, Radio, Tooltip, Modal, Spin, Alert } from 'antd';
import { mapTime } from '../../../util/time';
import moment from 'moment';
import AntdModal from '../../../Components/CustomModal';
import { ProcessCombos } from '../../../store/comboConfig';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { addAppSubscribersTask, appSubscribersUserSearch } from '../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endDate: (value, values, props, name) => {
    // if (!value) {
    //   return 'Required';
    // }
    // var index = parseInt(name.match(/\d+/), 10);
    // var objectName = name.split('[')[0];
    var taskDueDate = values.taskDueDate;
    var date1 = new Date(taskDueDate).getTime();
    var date2;
    if (typeof value == 'string') {
      var parts = value.split('-');
      // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
      // January - 0, February - 1, etc.
      var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      date2 = mydate;
    } else {
      date2 = new Date(value).getTime();
    }

    if (date2 <= date1) {
    } else {
      return 'and Time should be lesser than Task Due Date and Time';
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  triggerTime: (value, values, props, name) => {
    let beginningTime = values.taskTriggerTime;
    let endTime = values.taskDueTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'and Date should be lesser than Task Due Time and Date. ';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'and Date should be lesser than Task Due Time and Date.';
      }
    }
  },
  // endTime: (value, values, props, name) => {
  //   let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
  //   let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
  //   if (beginningTime) {
  //     if (beginningTime.isBefore(endTime)) {
  //     } else {
  //       return 'should be greater than Start time';
  //     }
  //   } else {
  //     return 'Not Applicable';
  //   }
  // },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};
const Close = props => (
  <span {...props}>
    <svg
      height="14"
      width="14"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="css-19bqh2r"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
  </span>
);

class AssignTaskModal extends Component {
  constructor(props) {
    super(props);
    var DisplayName = sessionStorage.getItem('displayName');
    this.input = React.createRef();
    this.state = {
      searchStr: '',
      studentData: [{ UserGuid: this.getUserId(), DisplayName }],
    };
  }
  getUserId() {
    return this.props.location.pathname.replace('/subscriberProfile/', '');
  }
  assignTask = values => {
    this.props.setRequestData();
    const {
      taskId,
      frequency,
      taskName,
      taskDueDate,
      taskDueTime,
      taskTriggerDate,
      taskTriggerTime,
      isVideoRequired,
    } = values;
    const { studentData, searchStr } = this.state;
    let userGuids = studentData.map(item => item.UserGuid).toString();
    var json = {
      Task: [
        {
          TaskId: taskId ? taskId : null,
          Title: taskName ? taskName : '',
          TaskUser: [
            {
              TaskUserId: this.props.TaskUserId ? this.props.TaskUserId : null,
              RepeatFrequencyCode: frequency ? frequency : null,
              // RepeatDayOfWeek: frequency,
              DueDate: taskDueDate ? moment(taskDueDate).format('YYYY-MM-DD') : null,
              TaskDueTime: moment.isMoment(taskDueTime)
                ? moment(taskDueTime._d).format('HH:mm:ss')
                : taskDueTime,
              TaskActivatedDate: taskTriggerDate
                ? moment(taskTriggerDate).format('YYYY-MM-DD')
                : null,
              TaskActivatedTime: moment.isMoment(taskTriggerTime)
                ? moment(taskTriggerTime._d).format('HH:mm:ss')
                : taskTriggerTime,
              IsVideoRequired: isVideoRequired,
            },
          ],
        },
      ],
    };
    var data = {
      UserGuids: userGuids,
      json: JSON.stringify(json),
    };
    if (studentData.length > 0) {
      this.setState({ minimumStudentSelected: false });
      this.props.dispatch(addAppSubscribersTask(data));
    } else {
      this.setState({ minimumStudentSelected: true });
    }
  };
  render() {
    const me = this;
    const { studentData, searchStr } = this.state;
    const {
      onCancel,
      handleSubmit,
      searchOption,
      taskOptions,
      title,
      isPreDefinedTask,
      isEdit,
      TaskUserId,
      taskTriggerDateValue,
      taskTriggerTimeValue,
      taskDueDateValue,
      taskDueTimeValue,
      durationString,
    } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-center">{title}</h4>
        </div>
        <div class="modal-body rcm-panel">
          <div>
            <form onSubmit={handleSubmit(this.assignTask)}>
              <div className="col-md-12 enrollment-form">
                <div className="row">
                  {isPreDefinedTask && (
                    <>
                      <div className="col-12">
                        <div class="form-group mb-2">
                          <Field
                            name="taskId"
                            type="text"
                            component={ReduxSelect}
                            // options={Country}
                            options={taskOptions}
                            validate={Validator.required}
                            placeholder="Select Task*"
                            fieldName={'Task'}
                            disabled={isEdit}
                          />
                          {/* <label class="placeholder-label">Select Existing Task*</label> */}
                        </div>
                      </div>
                      <div className="col-12">
                        <div class="form-group mb-2">
                          <Field
                            name="frequency"
                            type="text"
                            component={ReduxSelect}
                            options={[
                              { Value: 'W', Text: 'Weekly' },
                              { Value: 'O', Text: 'One Time' },
                              { Value: 'D', Text: 'Daily' },
                            ]}
                            validate={Validator.required}
                            placeholder="Select Frequency*"
                            fieldName={'Frequency'}
                            disabled={isEdit}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {!isPreDefinedTask && !isEdit && (
                    <>
                      <div className="col-12">
                        <div class="form-group mb-2">
                          <Field
                            name="taskName"
                            type="text"
                            component={ReduxInput}
                            fieldName={'Task Name'}
                            validate={[Validator.required]}
                            disabled={isEdit}
                          />
                          <label class="placeholder-label">Enter Task Name*</label>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {isEdit ? (
                    <>
                      <div className="col-6">
                        <div class="form-group mb-2">
                          <Field
                            name="taskDueDate"
                            type="text"
                            component={ReduxDateInput}
                            fieldName={"Task Due Date"}
                            label={"Task Due Date*"}
                            validate={[Validator.required]}
                            minDate={new Date()}
                          />
                          <i
                            class="far fa-calendar-alt form-control-icon"
                            style={{ paddingRight: "11px" }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div class="form-group mb-2">
                          <Field
                            name="taskDueTime"
                            type="text"
                            component={ReduxTimePicker}
                            fieldName={"Task Due Time"}
                            // validate={[Validator.required]}
                            label={"Task Due Time*"}
                            minuteStep={5}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                   
                  )} */}
                  <>
                    <div className="col-6">
                      <div class="form-group mb-2">
                        <Field
                          name="taskTriggerDate"
                          type="text"
                          component={ReduxDateInput}
                          label={'Task Initiate Date'}
                          fieldName={'Task Trigger Date'}
                          validate={this.props.taskTriggerDateValue ? Validator.endDate : false}
                          minDate={new Date()}
                          disabled={isEdit}
                        />
                        <i
                          class="far fa-calendar-alt form-control-icon"
                          style={{ paddingRight: '11px' }}
                        />
                      </div>
                    </div>
                    {this.props.taskTriggerDateValue ? (
                      <>
                        <div className="col-6">
                          <div class="form-group mb-2">
                            <Field
                              name="taskTriggerTime"
                              type="text"
                              component={ReduxTimePicker}
                              fieldName={'Task Trigger Time'}
                              label={'Task Initiate Time*'}
                              validate={
                                taskTriggerDateValue == taskDueDateValue
                                  ? [Validator.required, Validator.triggerTime]
                                  : [Validator.required]
                              }
                              minuteStep={5}
                              defaultValue={moment()
                                .hour(0)
                                .minute(0)}
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-6">
                          <div class="form-group mb-2">
                            <Field
                              name="taskTriggerTime"
                              type="text"
                              component={ReduxTimePicker}
                              fieldName={'Task Trigger Time'}
                              label={'Task Initiate Time'}
                              minuteStep={5}
                              defaultValue={moment()
                                .hour(0)
                                .minute(0)}
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-6">
                      <div class="form-group mb-2">
                        <Field
                          name="taskDueDate"
                          type="text"
                          component={ReduxDateInput}
                          fieldName={'Task Due Date'}
                          label={'Task Due Date*'}
                          validate={[Validator.required]}
                          minDate={new Date()}
                        />
                        <i
                          class="far fa-calendar-alt form-control-icon"
                          style={{ paddingRight: '11px' }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-group mb-2">
                        <Field
                          name="taskDueTime"
                          type="text"
                          component={ReduxTimePicker}
                          fieldName={'Task Due Time'}
                          // validate={[Validator.required]}
                          label={'Task Due Time'}
                          minuteStep={5}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <Autocomplete
                          getItemValue={item => `${item.UserGuid}`}
                          items={
                            searchOption &&
                            searchOption.map(item => {
                              return {
                                label: item.DisplayName,
                                ...item,
                              };
                            })
                          }
                          renderItem={(item, isHighlighted) => {
                            return (
                              <div
                                key={item.UserGuid}
                                style={{
                                  background: isHighlighted ? 'lightgray' : 'white',
                                }}
                              >
                                {item.label}
                              </div>
                            );
                          }}
                          onChange={e => {
                            this.setState({
                              searchStr: e.target.value,
                              isStudentData: false,
                            });
                            this.props.dispatch(
                              appSubscribersUserSearch({
                                SearchStr: e.target.value,
                              }),
                            );
                          }}
                          onSelect={value => {
                            const st = [...this.state.studentData];
                            for (let item of studentData) if (item.UserGuid == value) return;
                            for (let item of searchOption)
                              if (item.UserGuid == value) st.push(item);

                            this.setState({ studentData: st, searchStr: '' });
                            this.props.dispatch(appSubscribersUserSearch({ SearchStr: '' }));
                            // this.props.history.push(`/studentProfile/${value}`);
                          }}
                          // value={searchStr}
                          wrapperStyle={{ display: 'unset' }}
                          renderInput={props => {
                            return (
                              <div
                                {...props}
                                style={{
                                  borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                                  paddingBottom: '2px',
                                }}
                              >
                                <ul
                                  style={{
                                    height: 40,
                                    overflowY: 'auto',
                                    padding: 'unset',
                                    margin: 'unset',
                                  }}
                                  onClick={() => me.input.current.focus()}
                                >
                                  {studentData.map((item, index) => (
                                    <li className="multi-auto">
                                      {item.DisplayName}
                                      <Close
                                        onClick={event => {
                                          event.preventDefault();
                                          studentData.splice(index, 1);
                                          me.setState({ studentData });
                                        }}
                                      />
                                    </li>
                                  ))}
                                  <li className="multi-auto">
                                    <input
                                      type="text"
                                      onClick={event => {
                                        event.preventDefault();
                                      }}
                                      ref={me.input}
                                      value={searchStr}
                                      style={{
                                        border: 'none',
                                        width: (searchStr && searchStr.length * 13) || '0.75em',
                                        maxWidth: '100%',
                                        backgroundColor: 'inherit',
                                      }}
                                    />
                                  </li>
                                </ul>
                              </div>
                            );
                          }}
                          menuStyle={{
                            borderRadius: '3px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            background: 'rgba(255, 255, 255, 0.9)',
                            padding: '2px 4px',
                            fontSize: '90%',
                            position: 'fixed',
                            overflow: 'auto',
                            maxHeight: '50%',
                            zIndex: 1,
                            width: 'inherit',
                          }}
                        />
                        {this.state.minimumStudentSelected ||
                          (this.state.studentData.length <= 0 && (
                            <span className="error-text">Please select atleast one student</span>
                          ))}
                      </div>
                    </div>
                  </>
                  <div className="col-6">
                    <div class="form-group">
                      <Field
                        className="mr-2"
                        name="isVideoRequired"
                        component={ReduxCheckbox}
                        fieldName={'Is Video'}
                        label="Video required for this task"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 text-center">
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  {taskTriggerDateValue &&
                  taskTriggerTimeValue &&
                  taskDueDateValue &&
                  taskDueTimeValue ? (
                    new Date(taskDueDateValue).getTime() >=
                    new Date(taskTriggerDateValue).getTime() ? (
                      <p>{durationString}</p>
                    ) : null
                  ) : null}
                </div>
              </div>

              <div className="col-12">
                <div class="modal-footer  border-top-0 pt-2">
                  <input
                    type="submit"
                    class="btn black-btn font-weight-bold mr-2"
                    value={isEdit ? 'Update Task' : 'Assign Task'}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { app_subscribers_user_search, sp_list_combos } = state;
  const { data, error, isFetching } = app_subscribers_user_search;
  let taskTriggerDateValue = formValueSelector('assignTask')(state, 'taskTriggerDate');
  let taskTriggerTimeValue = formValueSelector('assignTask')(state, 'taskTriggerTime');
  let taskDueDateValue = formValueSelector('assignTask')(state, 'taskDueDate');
  let taskDueTimeValue = formValueSelector('assignTask')(state, 'taskDueTime');

  let taskOptions = [];
  if (sp_list_combos && sp_list_combos.data && sp_list_combos.data.EntTaskOptions) {
    taskOptions = sp_list_combos.data.EntTaskOptions || [];
  }
  if (taskTriggerDateValue) {
    taskTriggerDateValue = moment(taskTriggerDateValue).format('YYYY-MM-DD') || '';
  }
  if (taskTriggerTimeValue) {
    taskTriggerTimeValue = moment.isMoment(taskTriggerTimeValue)
      ? moment(taskTriggerTimeValue._d).format('HH:mm:ss')
      : taskTriggerTimeValue;
  }
  if (taskDueDateValue) {
    taskDueDateValue = moment(taskDueDateValue).format('YYYY-MM-DD') || '';
  }
  if (taskDueTimeValue) {
    taskDueTimeValue = moment.isMoment(taskDueTimeValue)
      ? moment(taskDueTimeValue._d).format('HH:mm:ss')
      : taskDueTimeValue;
  }
  // let a = `${moment(taskDueDateValue).format("DD/MM/YYYY")} ${taskDueTimeValue}`;
  // let b = `${moment(taskTriggerDateValue).format("DD/MM/YYYY")} ${taskTriggerTimeValue}` ;

  // let ms = moment(a,"DD/MM/YYYY HH:mm:ss").diff(moment(b,"DD/MM/YYYY HH:mm:ss"));
  // var d = moment.duration(ms);
  // var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

  let a = `${moment(taskTriggerDateValue).format('MM/DD/YYYY')} ${taskTriggerTimeValue}`;
  let b = `${moment(taskDueDateValue).format('MM/DD/YYYY')} ${taskDueTimeValue}`;
  var diff = moment.duration(moment(b).diff(moment(a)));
  var days = parseInt(diff.asDays());
  var hours = parseInt(diff.asHours());
  hours = hours - days * 24;
  var minutes = parseInt(diff.asMinutes());
  minutes = minutes - (days * 24 * 60 + hours * 60);
  let durationString;
  if (days != 0 && hours != 0 && minutes != 0) {
    durationString = (
      <span>
        <i class="fas fa-info-circle"></i> &nbsp; Note: The duration limit for this task is &nbsp;
        <span style={{ color: '#37c9fe' }}>
          {days}d {hours}h {minutes}m
        </span>
      </span>
    );
  } else if (days == 0 && hours != 0 && minutes != 0) {
    durationString = (
      <span>
        <i class="fas fa-info-circle"></i> &nbsp; Note: The duration limit for this task is &nbsp;
        <span
          style={{
            color: '#37c9fe',
          }}
        >
          {hours}h {minutes}m
        </span>
      </span>
    );
  } else if (days != 0 && hours == 0 && minutes != 0) {
    durationString = (
      <span>
        <i class="fas fa-info-circle"></i> &nbsp; Note: The duration limit for this task is &nbsp;
        <span
          style={{
            color: '#37c9fe',
          }}
        >
          {days}d 0h {minutes}m
        </span>
      </span>
    );
  } else if (days != 0 && hours != 0 && minutes == 0) {
    durationString = (
      <span>
        <i class="fas fa-info-circle"></i> &nbsp; Note: The duration limit for this task is &nbsp;
        <span
          style={{
            color: '#37c9fe',
          }}
        >
          {days}d {hours}h
        </span>
      </span>
    );
  } else if (days != 0 && hours == 0 && minutes == 0) {
    durationString = (
      <span>
        <i class="fas fa-info-circle"></i> &nbsp; Note: The duration limit for this task is &nbsp;
        <span
          style={{
            color: '#37c9fe',
          }}
        >
          {days}d
        </span>
      </span>
    );
  } else if (days == 0 && hours == 0 && minutes != 0) {
    durationString = (
      <span>
        <i class="fas fa-info-circle"></i> &nbsp; Note: The duration limit for this task is &nbsp;
        <span
          style={{
            color: '#37c9fe',
          }}
        >
          {minutes}m
        </span>
      </span>
    );
  } else if (days == 0 && hours == 0 && minutes == 0) {
    durationString = ``;
  }

  // let durationString = `${days} Day(s),${hours} Hour(s),${minutes} Minute(s)`;

  return {
    searchOption: (data && Object.values(data)) || [],
    taskOptions,
    taskTriggerDateValue,
    taskTriggerTimeValue,
    taskDueDateValue,
    taskDueTimeValue,
    durationString,
    // durationCalObj: {
    //   triggerStamp:`${taskTriggerDateValue} ${taskTriggerTimeValue}`,
    //   dueDateStamp:`${taskDueDateValue} ${taskDueTimeValue}`,
    // }
  };
};

const ReduxAssignTaskModal = reduxForm({
  form: 'assignTask',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(AssignTaskModal)));

export { ReduxAssignTaskModal };
