import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Modal, List } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { listPastMeetingInfo, pastMeetingInfo, updateSmallGroupAttendence } from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import { saveAs } from 'file-saver';
import { data } from '../config';
import { Switch } from 'antd';
import PageTitle from '../Components/PageTitle';
class SmallGroupMeetingList extends Component {
  constructor() {
    super();
    this.state = {
      searchStr: null,
      smallGroupModal: false,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  getSmallGroupGuid() {
    var param = new URLSearchParams(this.props.history.location.search);
    var smallGroupGuid = param.get('smallGroupGuid');
    if (smallGroupGuid) {
      smallGroupGuid = smallGroupGuid;
    } else {
      smallGroupGuid = null;
    }
    return smallGroupGuid;
  }

  componentDidMount({ searchStr } = this.state) {
    this.props.dispatch(
      listPastMeetingInfo({
        Json: JSON.stringify({
          SmallGroup: [
            {
              SmallGroupGuid: this.getSmallGroupGuid(),
            },
          ],
        }),
      }),
    );
  }

  onSearchChange = control => {
    var { value } = control.target;
    const { dispatch } = this.props;
    this.state.searchStr = value;
    // if (value.length == 0) {
    //   dispatch(adminstrationListCenter({ searchStr: this.state.searchStr }));
    // } else {
    //   dispatch(adminstrationListCenter({ searchStr: this.state.searchStr }));
    // }
  };

  onRowClick = (event, record, rowIndex) => {
    const { history } = this.props;
    this.props.dispatch(
      pastMeetingInfo({
        Json: JSON.stringify({
          SmallGroup: [
            {
              SmallGroupGuid: this.getSmallGroupGuid(),
              VMCallGuid: record.VMCallGuid,
            },
          ],
        }),
      }),
    );
    this.setState({
      smallGroupModal: true,
      SmallGroupRecord: record,
    });
  };

  render() {
    const { isFetching, SmallGroup, SmallGroupInfo, isFetchingInfo } = this.props;
    const { GroupName, MeetingStartTime, MeetingEndTime, MeetingDate } =
      (SmallGroup && SmallGroup)[0] || {};

    const columns = [
      {
        title: 'Member Count',
        dataIndex: 'MemberCount',
        key: 'MemberCount',
        sorter: (a, b) => isNumericComparer(a.MemberCount, b.MemberCount),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Attended Count',
        dataIndex: 'AttendeeCount',
        key: 'AttendeeCount',
        sorter: (a, b) => isNumericComparer(a.AttendeeCount, b.AttendeeCount),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Meeting Date',
        dataIndex: 'MeetingDate',
        key: 'MeetingDate',
        sorter: (a, b) => isDateComparer(a.MeetingDateForSort, b.MeetingDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Meeting Start Time',
        dataIndex: 'MeetingStartTime',
        key: 'MeetingStartTime',
        sorter: (a, b) => isDateComparer(a.MeetingStartTime, b.MeetingStartTime),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Meeting End Time',
        dataIndex: 'MeetingEndTime',
        key: 'MeetingEndTime',
        sorter: (a, b) => isDateComparer(a.MeetingEndTime, b.MeetingEndTime),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Day Of Week',
        dataIndex: 'MeetingWeek',
        key: 'MeetingWeek',
        sorter: (a, b) => isComparer(a.MeetingWeek, b.MeetingWeek),
        sortDirections: ['descend', 'ascend'],
      },

      // {
      //   title: 'Action',
      //   key: 'x',
      //   dataIndex: 'x',
      //   render: (text, record, index) => (
      //     <div class="tools-grid">
      //       <div className="mr-2">
      //         <span className="start-btn">View</span>
      //       </div>
      //     </div>
      //   ),
      // },
    ];
    return (
      <div className="container-fluid">
        <PageTitle TitleText="Small Group Meeting List" />
        {/* <Loader loading={isFetching} /> */}
        <div className="content-area">
          <div className="row top-table-search mt-5 mb-3 align-items-center">
            <div className="col-12 col-md-6 col-lg-4">
              <div
                onClick={() => {
                  this.props.history.goBack();
                }}
                style={{ cursor: 'pointer' }}
                className="text-left d-flex"
              >
                <img src="images/back-arrow.svg" className="mr-2" /> Back
              </div>
              {GroupName && (
                <h3 className="page-title mb-3 mb-md-0">{`${GroupName} Past Meetings`}</h3>
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-8 text-right">
              <div className="search-box d-inline-flex w-100">
                <div className="form-group has-search w-100">
                  <input
                    type="text"
                    className="form-control shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    onChange={this.onSearchChange}
                  />
                  <button className="btn search-icon " type="button">
                    {' '}
                    <i className="fa fa-search" />{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-5">
              <div className="card border-0 ">
                <div className="row card-body">
                  <div className="col-12 row-hover-enabled">
                    <Loader2 loading={isFetching} />
                    <Table
                      columns={columns}
                      dataSource={SmallGroup}
                      size={'middle'}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: event => this.onRowClick(event, record, rowIndex),
                        };
                      }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div className="dataTables_wrapper no-footer">
                              <div className="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a className="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a className="paginate_button next">Next</a>
                                ) : (
                                  <a className="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <Modal
          zIndex={100}
          visible={this.state.smallGroupModal}
          footer={null}
          closable={false}
          width={800}
          destroyOnClose={true}
        >
          <SmallGroupAttendeeModal
            SmallGroupRecord={this.state.SmallGroupRecord}
            SmallGroupGuid={this.getSmallGroupGuid()}
            SmallGroupInfo={SmallGroupInfo}
            isFetchingInfo={isFetchingInfo}
            dispatch={this.props.dispatch}
            onCancel={() => {
              this.setState({
                smallGroupModal: false,
              });
            }}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ list_past_meeting_info, past_meeting_info }) => {
  var { data, isFetching, error } = list_past_meeting_info;
  var { data: infoData, isFetching: isFetchingInfo } = past_meeting_info;
  let SmallGroup = [];
  let SmallGroupInfo = [];

  if (data && data.SmallGroup) {
    SmallGroup = data.SmallGroup || [];
  }
  if (infoData && infoData.SmallGroup) {
    SmallGroupInfo = infoData.SmallGroup[0] || [];
  }
  return {
    SmallGroup: SmallGroup,
    SmallGroupInfo: SmallGroupInfo,
    isFetching: isFetching,
    isFetchingInfo: isFetchingInfo,
  };
};

class SmallGroupAttendeeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleActive: false,
    };
  }
  // componentDidMount() {
  //   this.props.dispatch(
  //     pastMeetingInfo({
  //       Json: JSON.stringify({
  //         SmallGroup: [
  //           {
  //             SmallGroupGuid: this.props.SmallGroupGuid,
  //             VMCallGuid: this.props.SmallGroupRecord.VMCallGuid,
  //           },
  //         ],
  //       }),
  //     }),
  //   );
  // }
  componentWillReceiveProps({ SmallGroupInfo }) {
    if (
      SmallGroupInfo &&
      JSON.stringify(SmallGroupInfo) != JSON.stringify(this.props.SmallGroupInfo)
    ) {
      this.setState({
        SmallGroupInfo: SmallGroupInfo,
        SmallGroupAttendee: SmallGroupInfo.SmallGroupAttendee,
      });
    }
  }
  onToggle = (MemberName, record, index) => {
    this.props.dispatch(
      updateSmallGroupAttendence({
        Json: JSON.stringify({
          SmallGroup: [
            {
              VMCallSessionGuid: record.VMCallSessionGuid,
              IsPresent: !record.IsPresent,
            },
          ],
        }),
      }),
    );
    // let SmallGroupAttendee = this.state.SmallGroupAttendee;
    // SmallGroupAttendee[index].Status =
    //   SmallGroupAttendee[index].Status === 'Attended' ? 'Missesd' : 'Attended';
    this.setState({
      [MemberName]: !this.state[MemberName],
      // SmallGroupAttendee: SmallGroupAttendee,
    });
    // this.state.toggleActive = active;
  };
  render() {
    const { onCancel, SmallGroupRecord, SmallGroupInfo, isFetchingInfo } = this.props;
    const {
      SmallGroupAttendee,
      GroupLeader,
      GroupName,
      MeetingStartTime,
      MeetingEndTime,
      MeetingDate,
    } = SmallGroupInfo || {};

    const columns = [
      {
        title: 'Member Name',
        dataIndex: 'MemberName',
        key: 'MemberName',
        sorter: (a, b) => isComparer(a.MemberName, b.MemberName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Member Type',
        dataIndex: 'MemberType',
        key: 'MemberType',
        sorter: (a, b) => isComparer(a.MemberType, b.MemberType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Time',
        dataIndex: 'StartTime',
        key: 'StartTime',
        sorter: (a, b) => isComparer(a.StartTime, b.StartTime),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Time',
        dataIndex: 'EndTime',
        key: 'EndTime',
        sorter: (a, b) => isComparer(a.EndTime, b.EndTime),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Duration',
        dataIndex: 'Duration',
        key: 'Duration',
        sorter: (a, b) => isComparer(a.Duration, b.Duration),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'Status',
      //   key: 'Status',
      //   sorter: (a, b) => isComparer(a.Status, b.Status),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: (
          <span>
            Attended The Meeting
            <br />( Yes/No )
          </span>
        ),
        dataIndex: 'IsPresent',
        key: 'IsPresent',
        sorter: (a, b) => isComparer(a.IsPresent, b.IsPresent),
        sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => (
          <div class="col-md-3  col-lg-3 mar-top-30 meeting-checkbox text-right">
            {/* <label class="checkbox-inline">
              {this.state[record.MemberName + index] ? 'Attended' : 'Missed'}
            </label> */}
            <Switch
              style={{ display: 'block', marginTop: '5px' }}
              checked={this.state[record.MemberName + index]}
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={record.IsPresent}
              onChange={() => {
                this.onToggle(record.MemberName + index, record, index);
              }}
            />
          </div>
        ),
      },
      // {
      //   title: 'Action',
      //   key: 'x',
      //   dataIndex: 'x',
      //   render: (text, record, index) => (
      //     <div class="col-md-3  col-lg-3 mar-top-30 survey-checkbox text-right">
      //       <label class="checkbox-inline">
      //         {this.state[record.MemberName + index] ? 'Attended' : 'Missed'}
      //       </label>
      //       <Switch
      //         style={{ display: 'block', marginTop: '5px' }}
      //         checked={this.state[record.MemberName + index]}
      //         onChange={() => {
      //           this.onToggle(record.MemberName + index);
      //         }}
      //       />
      //       {/* <Switch checkedChildren="1" unCheckedChildren="0" /> */}
      //     </div>
      //   ),
      // },
    ];

    return (
      <div className="modal-content border-0">
        <div className="modal-header flex-wrap border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h6 className="modal-title col-12 text-left">{`Attendees`}</h6>
          <div className="row w-100">
            <h6 className="modal-title col-6 text-left">
              <small>Group Leader : {GroupLeader}</small>
            </h6>
            <h6 className="modal-title col-6 text-left">
              {MeetingDate && (
                <small>{`${MeetingDate} ${MeetingStartTime} - ${MeetingEndTime} `}</small>
              )}
            </h6>
          </div>
        </div>
        <div className="modal-body">
          <div className="row filter-modal px-5">
            <div className="col-12">
              <Loader2 loading={isFetchingInfo} />

              <Table
                columns={columns}
                dataSource={this.state.SmallGroupAttendee}
                size={'middle'}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SmallGroupMeetingList);
