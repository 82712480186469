import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

const MenuItem = ({ menuItem, index, getActiveUrl }) => {
  return (
    <li key={index} className={`nav-item ${getActiveUrl(menuItem.Path) ? 'active' : ''}`}>
      <Link className="nav-link" to={menuItem.Path}>
        {menuItem.Text} <span className="sr-only">(current)</span>
      </Link>
    </li>
  );
};
const SubMenuItem = ({ menuItem, index, getChildActiveUrl, SubMenu, FeatureFlag }) => {
  const [isMenuOpen, setDropDownMenu] = useState(false);
  const { EnableNewProspectFlow, IsProspectEnabled, License } = FeatureFlag || {};
  const { LicenseStatusId } =
    (License && License.filter(item => item.ModuleName == 'PreIntake')[0]) || {};
  let subMenu = [];
  if (SubMenu) {
    const subMenuData = JSON.parse(SubMenu);
    subMenuData.map((item, index) => {
      if (item.Text == 'Enrolled' || item.Text == 'Intake') {
        subMenu.push(item);
      } else {
        if (IsProspectEnabled && LicenseStatusId != 8) {
          if (EnableNewProspectFlow) {
            if (item.Path == '/clients/pre-intake' || item.Text == 'Queue') {
              subMenu.push(item);
            } else if (item.MenuKey === 'appointments') {
              subMenu.push(item);
            }
          } else {
            if (item.Path == '/clients/pre-intake-legacy') {
              subMenu.push(item);
            }
          }
          if (item.Text == 'Prospect' || item.Text == 'Queue') {
          }
        }
      }
    });
  }
  const menuClass = `dropdown-menu${isMenuOpen ? ' show' : ''}`;
  return (
    <li
      class={`nav-item dropdown sub-menu ${getChildActiveUrl(subMenu) ? 'active' : ''}`}
      key={index}
      onMouseEnter={() => setDropDownMenu(true)}
      onMouseLeave={() => setDropDownMenu(false)}
    >
      <span
        class="nav-link dropdown-toggle"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        to={menuItem.Path}
      >
        {menuItem.Text}
      </span>
      <div className={menuClass} aria-labelledby="navbarDropdown" style={{ marginTop: '-5px' }}>
        {subMenu.map((subMenuItem, Subindex) => {
          return (
            <Link className="dropdown-item" to={subMenuItem.Path}>
              {subMenuItem.Text} <span className="sr-only">(current)</span>
            </Link>
          );
        })}
        {/* <div class="dropdown-divider"></div> */}
      </div>
    </li>
  );
};

export { MenuItem, SubMenuItem };
