const DashboardAppSubscribers = 'Dashboard_AppSubscribers';
const Staff_List = 'Staff';
const Staff_Form = 'Staff';
const Payer_Staff_Form = 'PayerStaff';
const StaffMobileAppDetails = 'Staff_MobileAppDetails';
const MobileAdvisor = 'MobileAdvisor';
const PreIntake = 'PreIntake';
const Twilio_Enterprise = 'Enterprise';
const Twilio_Clinical = 'Clinical';

export {
  DashboardAppSubscribers,
  Staff_List,
  Staff_Form,
  StaffMobileAppDetails,
  MobileAdvisor,
  PreIntake,
  Payer_Staff_Form,
  Twilio_Enterprise,
  Twilio_Clinical,
};
