import React, { Component } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import DictationComponent from '../../../../../Components/DictationComponent';

export default class ToggleNote extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div class="print-textarea-component">
          <TextareaAutosize
            disabled={this.props.isReadOnly}
            // style={{ paddingLeft: '10px' }}
            style={{ height: '150px' }}
            value={this.props.noteValue}
            id="question"
            name="question"
            rows="2"
            placeholder="Enter Answer"
            className="textarea-type"
            onChange={this.props.onChange}
          />
          <div
            className="dictation-space d-flex justify-content-end"
            style={{ paddingRight: '10px' }}
          >
            <DictationComponent
              handleDictate={this.props.handleDictate}
              getDicatationStatus={this.props.getDicatationStatus}
            />
            <button
              style={{
                marginLeft: '10px',
                marginRight: '8px',
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: 'bold',
                background: 'transparent',
                border: '2px solid #ff7875',
                color: 'rgb(191, 191, 191)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
              }}
              onClick={() => {
                this.props.getNoteChanges();
                this.props.getDicatationStatus();
              }}
            >
              <img src="images/clear_icon.svg" />
              <p style={{ marginBottom: '0', marginLeft: '7px', color: '#ff7875' }}>Clear</p>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
