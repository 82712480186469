import React from 'react';
import { Flagged } from './flag';

const ViewScreeningAnswer = props => {
  const { Questions } = props;
  return (
    <div className="survey-answer-view">
      {Questions &&
        Questions.map(question => {
          const { QuestionNumber, Question, Note, Response, ResponseText, IsFlagged, AddNote } = question;
          return (
            <div className="survey">
              <div className="question d-flex">
                {QuestionNumber && <span>{QuestionNumber}. </span>}
                <span className="text mr-3">{Question}</span>
                {IsFlagged
                  ? Flagged
                  : // ? Qualifier
                    null}
              </div>
              <div className="answer">
                {Response ? (
                  <span className="text">A: {ResponseText || Response}</span>
                ) : (
                  <span className="not-answered">Not Answered</span>
                )}
              </div>
              {AddNote && Note && (
                <div className="note">
                  <span className="text-t">Note : </span>
                  <span className="text">{Note}</span>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};
export default ViewScreeningAnswer;
