import React from 'react';

import _blank from '../images/blank-profile.png';

export const ProfilePicComponent = props => {
  const { src, altText, width } = props;
  return (
    <div
      style={{
        minWidth: width ? `${width}px` : '50px',
      }}
    >
      <img
        src={src || _blank}
        alt={altText}
        class="img-circle"
        height={width ? `${width}` : '40'}
        width={width ? `${width}` : '40'}
        style={{
          borderRadius: '50%',
          objectFit: 'cover',
          minHeight: width ? `${width}px` : '40px',
          width: width ? `${width}px` : '40px',
          height: width ? `${width}px` : '40px',
        }}
      />
    </div>
  );
};
