import React, { Component } from 'react';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import { Table, Collapse } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import MultipleCategoryCheckboxAll from '../../../../../Components/multipleCategoryCheckbox';
const { Panel } = Collapse;

class LabTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: [0, 1, 2, 3],
    };
  }
  //   componentDidMount() {}
  captureExpandedkeys = (key, panelIndex) => {
    this.setState({ activeKey: key });
    this.props.getExpandedkeys(panelIndex);
  };
  render() {
    const { labTests, getCheckItems, selectedLabTests } = this.props;

    return (
      <div className="lab-order-panel">
        {labTests &&
          labTests.map((panel, panelIndex) => {
            return (
              <Collapse
                defaultActiveKey={`${panelIndex}`}
                key={`${panelIndex}`}
                activeKey={this.state.activeKey}
                onChange={index => this.captureExpandedkeys(index, panelIndex)}
                style={{
                  marginBottom: '10px',
                }}
              >
                <Panel
                  header={
                    <span
                      style={{
                        fontWeight: '800',
                      }}
                    >
                      {panel.label}
                    </span>
                  }
                  key={`${panelIndex}`}
                  style={{
                    borderLeft: `3.5px solid ${panel.ColorCoding || 'rgb(74, 175, 253)'}`,
                  }}
                  className="page-break lab-panel-border"
                >
                  <div className="row p-2">
                    {panel.children &&
                      panel.children.map((test, testIndex) => {
                        if (test.children) {
                          return (
                            <div className="panel-children">
                              <MultipleCategoryCheckboxAll
                                data={test}
                                index={testIndex}
                                ParentIndex={panelIndex}
                                getCheckItems={getCheckItems}
                                defaultValue={
                                  selectedLabTests[panelIndex]
                                    ? selectedLabTests[panelIndex][testIndex]
                                    : []
                                }
                              />
                            </div>
                          );
                        } else return null;
                      })}
                  </div>
                </Panel>
              </Collapse>
            );
          })}
      </div>
    );
  }
}
export default LabTest;
