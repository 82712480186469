import React, { useState, useEffect } from 'react';
import { Collapse, Table } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { Field, reduxForm, formValueSelector, reset, FieldArray, change } from 'redux-form';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../../../../../component/custom-redux-components';
import {
  comboServiceCode,
  getServiceCode,
  serviceCodeBundleGet,
  termsConditionListGet,
  termsConditionGetAllInfoJson,
  termsConditionComboGet,
  clientInsuranceComboGet,
} from '../../../../../../../store/actions';
import validate from '../../../../../../../core/generic-form/validation';
import Validator from '../../../../../../Validation';
import TermsForm from './termsForm';
import ConditionsForm from './conditionsForm';
import CustomModal from '../../../../../../../Components/CustomModal';
import { isComparer } from '../../../../../../../util/handler';
import { ProfilePicComponent } from '../../../../../../../Components/profilePicComponent';
const { Panel } = Collapse;

const AddFromLibrary = props => {
  const { onOk, onCancel, termsConditionCombo, getSelectedTerm } = props;
  const columns = [
    {
      title: 'Terms ID',
      dataIndex: 'TermId',
      key: 'TermId',
      sorter: (a, b) => isComparer(a.TermId, b.TermId),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
      sorter: (a, b) => isComparer(a.Title, b.Title),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Added By',
      dataIndex: 'AddedBy',
      key: 'AddedBy',
      sorter: (a, b) => isComparer(a.AddedBy, b.AddedBy),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div class="d-flex align-items-center">
          <ProfilePicComponent src={record.ProfileImage} alt={text} />
          <span className="mx-2">{text}</span>
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => (
        <div className="tools-grid mx-2">
          <span
            style={{
              color: 'rgb(79, 172, 254)',
              fontSize: '14px',
              fontWeight: '700',
              cursor: 'pointer',
            }}
            onClick={() => {
              getSelectedTerm(record);
            }}
          >
            Select Term
          </span>
        </div>
      ),
    },
  ];
  return (
    <div className="modal-content border-0">
      <div className="modal-header flex-wrap border-bottom-0 ">
        <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        <h4
          className="modal-title col-12 "
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <span className="text-left">Select Terms & Conditions</span>
        </h4>
      </div>
      <div className="modal-body">
        <div class="row">
          <div class="col-12">
            <Table
              columns={columns}
              dataSource={termsConditionCombo}
              size={'middle'}
              pagination={{
                itemRender: (page, type, originalElement) => {
                  return (
                    <div class="dataTables_wrapper no-footer">
                      <div class="dataTables_paginate paging_simple_numbers">
                        {type == 'prev' ? (
                          <a class="paginate_button previous">Previous</a>
                        ) : type == 'next' ? (
                          <a class="paginate_button next">Next</a>
                        ) : (
                          <a class="paginate_button ">{page}</a>
                        )}
                      </div>
                    </div>
                  );
                },
                showSizeChanger: true,
                defaultPageSize: 5,
              }}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer border-top-0 pb-4 pt-2">
        <button
          onClick={() => {
            onOk();
          }}
          type="button"
          className="btn"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const AddTerms = props => {
  const {
    termsConditionCombo,
    termsConditionInfo,
    PayerCombo,
    tcCombo,
    smartContractId,
    disable,
    getTerms,
    TermsCategory,
    existingRows,
    initialValues,
  } = props;
  let existingRowsWithPayerId =
    (existingRows && existingRows.filter(item => item && item.PayerId)) || [];
  let NewPayerCombo = PayerCombo || [];
  let payerDropDownOptions = [];
  existingRowsWithPayerId.map((mainItem, index) => {
    NewPayerCombo =
      NewPayerCombo &&
      NewPayerCombo.filter(item => {
        return item.Value != mainItem.PayerId;
      });
  });
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const dispatch = useDispatch();

  const { fields } = props;

  const onChange = key => {
    console.log(key);
  };

  return (
    <div className="row buildServiceBundle">
      <div className="col-12 col-md-12 col-lg-12 my-1">
        <div className="">
          <Collapse
            // expandIcon={({ isActive }) => (
            //   <RightCircleOutlined
            //     rotate={isActive ? -90 : 90}
            //     style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
            //   />
            // )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            // onChange={onChange}
          >
            {fields.length > 0 ? (
              fields.map((item, index) => {
                let temp = [];
                if (existingRowsWithPayerId[index]) {
                  temp =
                    PayerCombo && PayerCombo.length > 0
                      ? PayerCombo.filter(
                          code => code.Value == existingRowsWithPayerId[index].PayerId,
                        )
                      : [];
                }
                payerDropDownOptions = [...NewPayerCombo, ...temp];
                return (
                  <Panel
                    key={index}
                    className="bundle-panel-border"
                    header={
                      <>
                        <div className="row" key={index}>
                          <div className="col-md-3 panel-header-text">{`Terms & Conditions ${index +
                            1}`}</div>
                          <div className="col-md-9 text-right d-flex">
                            {isButtonVisible && (
                              <>
                                {!props.disabled && (
                                  <button
                                    type="button"
                                    class="btn-newCon"
                                    style={{
                                      position: 'unset',
                                      width: 'unset',
                                    }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      fields.push();
                                    }}
                                  >
                                    + Add{' '}
                                    {fields.length == 0
                                      ? 'Terms & Conditions'
                                      : 'Another Terms & Conditions'}
                                  </button>
                                )}
                              </>
                            )}
                            {fields.length > 1 && (
                              <div className="text-right">
                                {isButtonVisible && (
                                  <>
                                    {!props.disabled && (
                                      <a
                                        class="col-1 delete-contract"
                                        onClick={e => {
                                          e.stopPropagation();
                                          if (fields.length && fields.length > 0) {
                                            fields.remove(index);
                                          }
                                        }}
                                      >
                                        <i class="fas fa-trash" />
                                      </a>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    }
                  >
                    <div class="terms-condition">
                      <div className="row terms-condition-detail">
                        {/* <div className="col-12 mb-2">
                            <span class="title">Terms & Conditions Detail</span>
                          </div> */}

                        {(termsConditionInfo && termsConditionInfo.TermId) ||
                        (initialValues.TermsCondition &&
                          initialValues.TermsCondition[index] &&
                          initialValues.TermsCondition[index].TermId) ? (
                          <>
                            {' '}
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group mb-1">
                                <span
                                  className="not-empty false form-control"
                                  style={props.disabled?{ paddingTop: '17px', background: 'unset',borderBottom:'unset' } :{ paddingTop: '17px', background: 'unset' }}
                                >
                                  {' '}
                                  {(termsConditionInfo && termsConditionInfo.TermId) ||
                                    (initialValues.TermsCondition &&
                                      initialValues.TermsCondition[index] &&
                                      initialValues.TermsCondition[index].TermId)}
                                </span>

                                {/* <Field
                                  name={`${item}TermId`}
                                  type="text"
                                  component={ReduxInput}
                                  fieldName={'Terms ID'}
                                  validate={Validator.required}
                                  style={{ background: '#F8F8F8' }}
                                  disabled={true}
                                /> */}
                                <label class="placeholder-label">Terms ID*</label>
                              </div>
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group mb-1">
                            <Field
                              name={`${item}PayerId`}
                              type="text"
                              errorMessage="Payer Required"
                              component={ReduxSelect}
                              options={
                                payerDropDownOptions &&
                                payerDropDownOptions.filter(item => item.Value != -1)
                              }
                              placeholder="Payer*"
                              validate={Validator.required}
                              fieldName={'Payer'}
                              disabled={props.disabled}
                            />
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group mb-1">
                            <Field
                              name={`${item}TermsConditionId`}
                              type="text"
                              component={ReduxSelect}
                              options={
                                tcCombo &&
                                existingRows &&
                                existingRows[index] &&
                                existingRows[index].PayerId
                                  ? tcCombo.filter(
                                      item => item.Value == existingRows[index].PayerId,
                                    )[0] &&
                                    tcCombo.filter(
                                      item => item.Value == existingRows[index].PayerId,
                                    )[0].Options
                                    ? tcCombo.filter(
                                        item => item.Value == existingRows[index].PayerId,
                                      )[0].Options
                                    : []
                                  : []
                              }
                              fieldName={'Title'}
                              validate={Validator.required}
                              style={{ background: '#F8F8F8' }}
                              disabled={props.disabled}
                              placeholder="Title*"
                              onChange={value => {
                                let json = {
                                  TermsCondition: [
                                    {
                                      TermsConditionId: value,
                                    },
                                  ],
                                };
                                var data = {
                                  JsonInput: JSON.stringify(json),
                                  termIndex: index,
                                };
                                dispatch(termsConditionGetAllInfoJson(data));
                              }}
                            />
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 text-right d-none">
                          {/* <button
                            //   onClick={() => {
                            //     this.props.history.push('/cw/manage/terms-conditions-list');
                            //   }}
                            class="btn btn-outline-primary mr-2"
                          >
                            Save to Library
                          </button> */}

                          <CustomModal
                            modalWidth={800}
                            maskClosable={false}
                            ModalButton={({ onClick }) => (
                              <input
                                type="button"
                                data-toggle="modal"
                                data-target="#view-model"
                                onClick={() => {
                                  dispatch(termsConditionListGet({}));
                                  onClick();
                                }}
                                class="btn mr-2"
                                value={
                                  smartContractId ? 'Replace from Library' : 'Add from Library'
                                }
                              />
                            )}
                            ModalContent={props => (
                              <AddFromLibrary
                                {...props}
                                termsConditionCombo={termsConditionCombo}
                                getSelectedTerm={data => {
                                  dispatch(
                                    termsConditionGetAllInfoJson({
                                      JsonInput: JSON.stringify({
                                        TermsCondition: [
                                          {
                                            TermsConditionId: data.TermsConditionId,
                                          },
                                        ],
                                      }),
                                    }),
                                  );
                                  props.onCancel();
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row terms-detail">
                        <div className="col-12">
                          <FieldArray
                            name={`${item}TermsCategory`}
                            getTerms={getTerms}
                            component={TermsForm}
                            disable={props.disabled}
                            // dataRecord={TermsCategory}
                            termsCategory={
                              TermsCategory &&
                              TermsCategory[index] &&
                              TermsCategory[index].TermsCategory
                            }
                            isButtonVisible={isButtonVisible}
                            termsCategoryIndex={index}
                          />
                        </div>
                      </div>
                      <div className="row conditions-detail">
                        <div className="col-12">
                          <FieldArray
                            name={`${item}Condition`}
                            component={ConditionsForm}
                            disable={props.disabled}
                            isButtonVisible={isButtonVisible}
                          />
                        </div>
                      </div>
                    </div>
                  </Panel>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
        </div>
      </div>
    </div>
  );
};
const ReduxAddTerms = AddTerms;
export default ReduxAddTerms;
