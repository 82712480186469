import React, { Component } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
// import { Document, Page } from 'react-pdf';
class DocViewerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
    };
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { file, type } = this.props;
    const docs = [
      {
        uri: file,
        fileType: type,
      },
    ];

    return (
      <>
        {file && (
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            className=""
            style={{ height: '100vh' }}
            theme={{
              primary: '#5296d8',
              secondary: '#ffffff',
              tertiary: '#5296d899',
              text_primary: '#ffffff',
              text_secondary: '#5296d8',
              text_tertiary: '#00000099',
              disableThemeScrollbar: false,
            }}
            config={{
              header: {
                disableHeader: false,
                // disableFileName: false,
                retainURLParams: false,
              },
            }}
          />
          // <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
          //   <Page pageNumber={this.state.pageNumber} />
          // </Document>
          // <a href={file}>link</a>
        )}
      </>
    );
  }
}

export default DocViewerComponent;
