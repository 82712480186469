import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, reset, change } from 'redux-form';
import { ReduxInput, ReduxSelect } from '../../component/custom-redux-components';
import Validator from '../Validation';
import TermsForm from './terms';
import ConditionsForm from './conditions';
import SubmitButton from '../../Components/submitButton';
import { comboPayer } from '../../store/actions';

class TermsConditionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getTerms = terms => {
    this.props.dispatch(change('termsConditionsForm', 'TermsList', terms));
  };
  componentDidMount() {
    this.props.dispatch(comboPayer({}));
  }
  render() {
    const {
      handleSubmit,
      disable,
      change,
      handleForm,
      isFetching,
      TermsConditionId,
      termsConditionInfo,
      Payer,
      initialValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit(handleForm)}>
        <div class="rcm-panel enrollment-form">
          <div class="terms-condition">
            <div className="row terms-condition-detail">
              <div className="col-12 mb-2">
                <span class="title">Terms & Conditions Detail</span>
              </div>
              {initialValues && initialValues.TermId ? <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group mb-1">
                  <Field
                    name={`TermId`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Terms ID'}
                    validate={Validator.required}
                    style={{ background: '#F8F8F8' }}
                    disabled={true}
                  />
                  <label class="placeholder-label">Terms ID*</label>
                </div>
              </div>:""}
              
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group mb-1">
                  <Field
                    name="PayerId"
                    type="text"
                    errorMessage="Payer Required"
                    component={ReduxSelect}
                    options={Payer}
                    placeholder="Payer*"
                    validate={Validator.required}
                    fieldName={'Payer'}
                  />
                  {/* <label class="placeholder-label">Payer*</label> */}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group mb-1">
                  <Field
                    name={`Title`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Title'}
                    validate={Validator.required}
                    style={{ background: '#F8F8F8' }}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Title*</label>
                </div>
              </div>
              {disable ? (
                <div class="col-12 col-md-6 col-lg-12 mt-4 text-right">
                  <input
                    type="button"
                    onClick={() => {
                      this.props.history.replace(
                        `/cw/manage/terms-conditions-form?tcid=${TermsConditionId}`,
                      );
                    }}
                    class="btn mr-2"
                    value="Edit Terms & Conditions"
                  />
                </div>
              ) : (
                <div class="col-12 col-md-6 col-lg-12 mt-4 text-right">
                  <button
                    onClick={() => {
                      this.props.history.push('/cw/manage/terms-conditions-list');
                    }}
                    class="btn btn-outline-primary mr-2"
                  >
                    Cancel
                  </button>
                  <SubmitButton
                    loading={isFetching}
                    value={'Save to Library'}
                    style={{ height: '45px' }}
                  />
                </div>
              )}
            </div>
            <div className="row terms-detail">
              <div className="col-12">
                <TermsForm
                  getTerms={this.getTerms}
                  disable={disable}
                  dataRecord={termsConditionInfo && termsConditionInfo.TermsCategory}
                />
              </div>
            </div>
            <div className="row conditions-detail">
              <div className="col-12">
                <ConditionsForm disable={disable} />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { terms_condition_get_all_info_json, combo_payer } = state;
  const { data, isFetching, error } = terms_condition_get_all_info_json;
  let termsConditionInfo = null;
  let Payer = [];
  if (data && data.TermsCondition) {
    termsConditionInfo = data.TermsCondition[0];
  }
  if (combo_payer.data && combo_payer.data.Payer) {
    Payer = combo_payer.data.Payer || [];
  }
  return {
    termsConditionInfo,
    Payer,
  };
};

const ReduxTermsConditionsForm = reduxForm({
  form: 'termsConditionsForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(TermsConditionsForm)));
const selector = formValueSelector('termsConditionsForm');

export default ReduxTermsConditionsForm;
