import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetGetPatientDetail } from '../../../store/actions';
import { reset } from 'redux-form';
import Twilio from '../../../Components/twilio';
import DynamicGrid from '../../../Components/DynamicGrid';
import PageTitle from '../../../Components/PageTitle';
class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: {
        FilterBy: 'Active',
        PractitionerId: '0',
      },
      practitionerId: '0',
      clickedRecord: {},
      callModelVisible: false,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string,
  };

  resetIsOpen = () => {
    this.setState({
      // twilioToken: '',
      callModelVisible: false,
      clickedRecord: {},
      // device: '',
    });
  };

  // onNewPatient = () => {
  //       debugger;
  //   // this.props.history.push('/rcm?tab=PatientManagement/patientProfile');
  //   this.props.history.push('/cw/patient-management/new-registration');
  //   this.props.dispatch(reset('demographicForm'));
  //   // this.props.toggleView();
  //   // this.props.getClientId(null);
  // };

  render() {
    return (
      <div class="w-100 rcm-panel rcm-patient-table">
        <PageTitle TitleText="Patient Management" />
        <div class="main enrollment-page mb-5">
          <div class="container-fluid px-5">
            <div class="content-area">
              <div class="row top-table-search mt-3 mb-3 align-items-center">
                <div class="col-12">
                  <h3 class="page-title mb-3 mb-md-0 text-uppercase">Patient List</h3>
                </div>
              </div>
              <div className="dynamic-grid">
                <DynamicGrid
                  ScreenName={'Client'}
                  FilterBy={this.state.filterBy}
                  FormName="demographicForm"
                  isReadOnlyUser={this.props.isReadOnlyUser}
                  tableOptions={{
                    PageRowsSize: 50,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            // twilioToken={this.state.twilioToken}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}

ClientList.defaultProps = {
  name: 'client-list',
};

const mapStateToProps = state => {
  const { default_values } = state;

  let isReadOnlyUser = false;
  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  return {
    isReadOnlyUser,
  };
};

export default connect(mapStateToProps)(ClientList);
