import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate from '../validation/validate';
import renderField from '../reduxFormComponent/renderField';
import {
  ReduxSelect,
  ReduxInput,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';

const multiRequired = {
  required: value => (value && Object.keys(value).length == 0 ? 'required' : undefined),
};
const Validator = {
  required: value => (value ? undefined : 'required'),
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'cannot be greater than 2 digits' : undefined,
  allowAge: value =>
    value && (parseInt(value) <= 0 || parseInt(value) >= 100)
      ? 'cannot be greater than 2 digits'
      : undefined,
};
const substanceUseProfile = ({
  fields,
  name,
  meta: { touched, error },
  AddictionProfileType,
  AddictionType,
  Frequency,
  DrugRouteOfAdmin,
  // AddictionProfileLastInjection,
  existingRows,
  change,
  disable,
}) => {
  const toogleChange = (bool, currentIndex) => {
    if (bool) {
      fields.forEach((field, index) => {
        if (currentIndex !== index) {
          change(`substanceUseProfiles[${index}].isDrugOfChoice`, false);
        } else {
          change(`substanceUseProfiles[${index}].isDrugOfChoice`, true);
        }
      });
    }
  };
  return (
    <>
      {fields.map((substanceUseProfile, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Substance Use Profile {index + 1}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete Addiction Profile
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4 d-none">
                <div class="form-group ">
                  <Field
                    name={`${substanceUseProfile}.studentAddictionTypeId`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'studentAddictionTypeId'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${substanceUseProfile}.addictionProfileTypeId`}
                    type="text"
                    placeholder="Addiction Profile Type*"
                    options={AddictionProfileType}
                    component={ReduxSelect}
                    disabled={disable}
                    validate={validate.required}
                    fieldName={'Addiction Profile Type'}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${substanceUseProfile}.frequencyOfUseId`}
                    type="text"
                    placeholder="Frequency of use*"
                    component={ReduxSelect}
                    options={Frequency}
                    disabled={disable}
                    validate={validate.required}
                    fieldName={'Frequency Of Use'}
                  />
                </div>
              </div>

              {existingRows[index].addictionProfileTypeId == 1 && (
                <>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.addictionTypeId`}
                        type="text"
                        placeholder="Drug Use"
                        component={ReduxSelect}
                        options={AddictionType}
                        disabled={disable}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="custom-control-label" for="is-emergency-contact">
                        Drug of choice?{' '}
                        <Field
                          name={`${substanceUseProfile}.isDrugOfChoice`}
                          type="text"
                          class="custom-control-input not-empty"
                          component={ReduxToggle}
                          onChange={bool => toogleChange(bool, index)}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          disabled={disable}
                        />
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.ageOfFirstUse`}
                        type="number"
                        component={ReduxInput}
                        validate={[
                          Validator.required,
                          Validator.allowNumeric,
                          Validator.allowAge,
                          validate.ageOfUse,
                        ]}
                        fieldName={'Age of first use'}
                        disabled={disable}
                        className="numeric-only-field"
                        onChange={evt =>
                          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                        }
                      />
                      <label class="placeholder-label">
                        Age of first use (or approximate age)*
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.endDate`}
                        type="text"
                        // disabled={!existingRows[index].startDate}
                        component={ReduxDateInput}
                        validate={validate.required}
                        label="Drug Use Date (Last Used)*"
                        fieldName={'Drug Last Used '}
                        disabled={disable}
                        maxDate={new Date()}
                      />
                      <i class="far fa-calendar-alt form-control-icon" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.drugRouteOfAdminId`}
                        type="text"
                        placeholder="How Is/Was Drug Administered?*"
                        component={ReduxSelect}
                        options={DrugRouteOfAdmin}
                        disabled={disable}
                        validate={[Validator.required, multiRequired.required]}
                        fieldName={'How Is/Was Drug Administered?'}
                        isMulti
                      />
                    </div>
                  </div>
                  {/* <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.lastInjection`}
                        type="text"
                        placeholder="Last Injection"
                        // options={AddictionProfileLastInjection}
                        component={ReduxSelect}
                        disabled={disable}
                        fieldName={'Last Injection'}
                      />
                    </div>
                  </div> */}
                  <div class="col-12 col-md-6 col-lg-8">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.notes`}
                        type="textarea"
                        component={ReduxTextarea}
                        disabled={disable}
                      />
                      <label class="placeholder-label">Notes</label>
                    </div>
                  </div>
                </>
              )}
              {existingRows[index].addictionProfileTypeId == 5 ? (
                <div class="col-md-6 col-lg-3 mar-top-15">
                  <div class="form-group">
                    <Field
                      name={`${substanceUseProfile}.addictionProfileTypeOther`}
                      type="text"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Addiction Profile Type'}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Describe Addiction Profile Type</label>
                  </div>
                </div>
              ) : null}

              {existingRows[index].addictionProfileTypeId != 1 ? (
                <>
                  {/* <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.addictionFrequency`}
                        type="text"
                        component={ReduxSelect}
                        options={Frequency}
                        placeholder="Frequency*"
                        validate={validate.required}
                        fieldName={'addictionFrequency'}
                        disabled={disable}
                      />
                    </div>
                  </div> */}
                  <div class="col-md-6 col-lg-3 mar-top-15">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.startDate`}
                        type="text"
                        component={ReduxDateInput}
                        validate={validate.required}
                        label="Addiction Start Date*"
                        fieldName={'Addiction Start Date'}
                        disabled={disable}
                      />
                      <i class="far fa-calendar-alt form-control-icon" />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3 mar-top-15">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.endDate`}
                        type="text"
                        component={ReduxDateInput}
                        validate={[validate.required, validate.endDate]}
                        // disabled={!existingRows[index].startDate} creating issue in new enrollment unable to select the date
                        label="Addiction End Date*"
                        fieldName={'Addiction End Date'}
                        disabled={disable}
                      />
                      <i class="far fa-calendar-alt form-control-icon" />
                    </div>
                  </div>
                  {/* <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <Field
                        name={`${substanceUseProfile}.lastInjection`}
                        type="text"
                        placeholder="Last Injection"
                        // options={AddictionProfileLastInjection}
                        component={ReduxSelect}
                        disabled={disable}
                        fieldName={'Last Injection'}
                      />
                    </div>
                  </div> */}
                </>
              ) : null}
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" onClick={() => fields.push({})} disabled={disable}>
            {fields.length >= 1 && (
              <label>
                {' '}
                <a>+ Add Another Addiction Profile</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                {' '}
                <a>+ Add Addiction Profile</a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

const AddictionProfiles = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    AddictionProfileType,
    AddictionType,
    // AddictionProfileLastInjection,
    Frequency,
    DrugRouteOfAdmin,
    style,
    change,
    existingRows,
    goToPreviuos,
    disable,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
    isBoiseGirlsAcademyCenter,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <FieldArray
        name="substanceUseProfiles"
        component={substanceUseProfile}
        AddictionProfileType={AddictionProfileType}
        AddictionType={AddictionType}
        // AddictionProfileLastInjection={AddictionProfileLastInjection}
        Frequency={Frequency}
        DrugRouteOfAdmin={DrugRouteOfAdmin}
        existingRows={existingRows}
        change={change}
        disable={disable}
      />
      <div class="row pagination-button">
        <div class="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              class="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos, default_values } = state;
  var defaultAddictionProfileTypeId = null;
  if (default_values && default_values.data) {
    defaultAddictionProfileTypeId = default_values.data.addictionProfileTypeId;
  }
  var existingRows = selector(state, 'substanceUseProfiles');
  return {
    AddictionProfileType: combos.data.AddictionProfileType,
    AddictionType: combos.data.AddictionType,
    Frequency: combos.data.FrequencyOfUse,
    // AddictionProfileLastInjection: combos.data.AddictionProfileLastInjection,
    hasPriorDisorderProgramValue: selector(state, ''),
    DrugRouteOfAdmin: combos.data.DrugRouteOfAdmin,
    defaultAddictionProfileTypeId,
    existingRows,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(AddictionProfiles),
);

const selector = formValueSelector('wizard');
