import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { currencyFormatter, numberFormat } from '../../../../../../../util/formatter';
import { currencyFormatter } from '../../../../util/formatter';

const TargetPrice = ({ RequestItem, index }) => {
  const dispatch = useDispatch();
  const {
    RequestType,
    StatusId,
    Note,
    CodeId,
    ServiceCategory,
    ServiceCodeDescription,
    TargetPrice,
    ChangeNotes,
  } = RequestItem;
  return (
    <div className="target-price p-1">
      <div className="request-info">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <span className="request-sr-no">{index + 1}.</span>
            <div>
              <div className="code-details">
                <div className="item-block">
                  <span className="label">Target Price</span>
                  <span className="value">{currencyFormatter.format(TargetPrice || 0)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="request-detail">
        <span className="label">Details</span>
        <span className="details">{Note}</span>
      </div>

      {ChangeNotes && (
        <div className="request-detail mt-3">
          <span className="label">Notes</span>
          <span className="details">{ChangeNotes}</span>
        </div>
      )}
    </div>
  );
};

export default TargetPrice;
