import React, { Component } from 'react';
import $ from 'jquery';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class NumericType3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };

    const {
      survey: { Response },
    } = this.props;
    if (Response) {
      this.state.value = parseInt(Response);
    }
  }

  onIncrement = (event, Question, SurveyId, SurveyQuestionId, setValue, scope, MaxValue) => {
    let value = this.state.value;
    if (++value <= MaxValue) {
      this.setState({ value: value });
      setValue.call(scope, {
        response: value,
        surveyQuestionId: SurveyQuestionId,
        level: this.props.level,
        subLevel: this.props.subLevel,
        subCatLevel: this.props.subCatLevel,
        index: this.props.index,
      });
    }
  };

  onDecrement = (event, Question, SurveyId, SurveyQuestionId, setValue, scope, MinValue) => {
    let value = this.state.value;
    if (--value >= MinValue) {
      this.setState({ value: value });
      setValue.call(scope, {
        response: value,
        surveyQuestionId: SurveyQuestionId,
        level: this.props.level,
        subLevel: this.props.subLevel,
        subCatLevel: this.props.subCatLevel,
        index: this.props.index,
      });
    }
  };

  onClick() {
    if (this.state.value == 1) {
      const {
        survey: { SurveyQuestionId },
        setValue,
        scope,
      } = this.props;
      setValue.call(scope, { response: 1, surveyQuestionId: SurveyQuestionId });
    }
    $('.main-banner').slick('slickNext');
  }

  onChange = (event, click) => {
    const { value } = event.target;
    const {
      survey: { SurveyQuestionId, MaxValue, MinValue },
      setValue,
      scope,
    } = this.props;
    if (value) {
      if (value >= MinValue && value <= MaxValue) {
        this.setState({ value: value });
        setValue.call(scope, {
          response: value,
          surveyQuestionId: SurveyQuestionId,
          level: this.props.level,
          subLevel: this.props.subLevel,
          subCatLevel: this.props.subCatLevel,
          index: this.props.index,
        });
      }
    } else {
      if (value >= MinValue && value <= MaxValue) this.setState({ value: value });
    }
  };

  componentWillReceiveProps(props) {
    const {
      survey: { Response },
    } = this.props;
    if (Response) {
      this.setState({ value: parseInt(Response) });
    }
  }

  render() {
    const {
      survey: {
        Question,
        QuestionNumber,
        DoesNotApplyText,
        DoesNotApplyValue,
        SurveyId,
        SurveyQuestionId,
        MaxValue,
        MinValue,
      },
      setValue,
      scope,
      index,
      total,
    } = this.props;
    return (
      <div className="col-sm-12 col-lg-6">
        <div
        // class="card-body"
        >
          {/* <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span> */}
          <div className="d-flex flex-column">
            <SurveyQuestion survey={this.props.survey} />

            <div
              class="answer-options mb-4 "
              style={{
                // maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
                textAlign: 'left',
              }}
            >
              <div class="quantity-btn">
                <div class="numbers-row">
                  <input
                    class="number-icon"
                    type="text"
                    min="0"
                    max="30"
                    value={this.state.value}
                    tabindex="0"
                    style={{ textAlign: 'center', height: '50px', fontSize: '18px' }}
                    onChange={this.onChange}
                  />
                  <div
                    class="add quantity-button"
                    style={{ height: '50px', borderRadius: '0px' }}
                    onClick={event =>
                      this.onIncrement(
                        event,
                        Question,
                        SurveyId,
                        SurveyQuestionId,
                        setValue,
                        scope,
                        MaxValue,
                      )
                    }
                  >
                    +
                  </div>
                  <div
                    class="remove quantity-button"
                    style={{ height: '50px', borderRadius: '0px' }}
                    onClick={event =>
                      this.onDecrement(
                        event,
                        Question,
                        SurveyId,
                        SurveyQuestionId,
                        setValue,
                        scope,
                        MinValue,
                      )
                    }
                  >
                    -
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
                textAlign: 'left',
              }}
            >
              {/* <button class="btn px-5" onClick={this.onClick.bind(this)} tabindex="0">
                Next
              </button> */}
            </div>
            <DoesNotApplyOption surveyProps={this.props} />
          </div>
          {/* <div class="row pagination-button">
            <div class="col-12 text-right">
              <button class="btn" onClick={this.onClick.bind(this)}>
                Next
              </button>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default NumericType3;
