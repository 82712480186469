import React from 'react';
import { Tooltip } from 'antd';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxInput,
  DatePicker,
  ReduxTextarea,
} from '../component/custom-redux-components';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, getFormSyncErrors } from 'redux-form';
import { Delete_Sucess_Msg } from '../app-config';
import moment from 'moment';
import {
  getContract,
  upsertContract,
  resetUpsertContract,
  resetGetContract,
  deleteContract,
  resetDeleteContract,
  closeContract,
  listCombos,
} from '../store/actions';
import AntdModal from '../Components/CustomModal';
import CustomModal from '../core/modal/index';
import { ProcessCombos } from '../store/comboConfig';
import { getDate, stringDate } from '../util/date';
import SubmitButton from '../Components/submitButton';

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 >= date1) {
    } else {
      return 'has to be greater than Start Date';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

class CloseContractForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Notes: '',
      Score: '',
      Receipients: '',
      studentId: '',
      closeDate: new Date(),
    };
  }
  onDateFieldChange(date) {
    if (date) {
      this.setState({ closeDate: date.format('YYYY-MM-DD') });
    }
  }
  render() {
    const { onOk } = this.props;
    const { Score } = this.state;
    return (
      <div style={{ marginLeft: '48px', marginRight: '48px' }}>
        <div>
          <div className="form-group">
            <textarea
              className={`form-control newcontract-textarea`}
              type="text"
              placeholder="Notes"
              rows="4"
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ Notes: newValue });
              }}
            />
          </div>
          <div className="form-group">
            <input
              className={`form-control`}
              placeholder="Score"
              type="number"
              value={Score}
              onChange={({ target: { value } }) => {
                if (value >= 0 && value <= 100) {
                  this.setState({ Score: value });
                }
              }}
            />
          </div>
          <div className="form-group">
            <input
              className={`form-control`}
              placeholder="Recipients"
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ Receipients: newValue });
              }}
            />
          </div>
          <div className="form-group">
            <label> Closed Date </label>
            <DatePicker
              date={this.state.closeDate}
              minDate={new Date()}
              onChange={this.onDateFieldChange.bind(this)}
            />
          </div>
          <div className="text-center">
            <button
              class="btn"
              onClick={() => {
                onOk();
              }}
            >
              Cancel
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              class="btn"
              onClick={() => {
                this.props.dispatch(
                  closeContract({
                    StudentId: this.props.studentId,
                    StudentContractId: this.props.StudentContractId,
                    IsCompleted: 1,
                    Score: this.state.Score,
                    AdminNotes: this.state.Notes,
                    ClosedDate: this.state.closeDate,
                  }),
                );
                onOk();
                this.props.fnModalOpen();
              }}
            >
              Complete
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class NewContractForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newContract: {
        studentId: this.getStudentId(),
        studentName: this.getStudentName(),
        // minorThemes: [''],
        // goals: [''],
        // bibleMemorizations: [''],
        // bibleReadings: [''],
        // lessons: [''],
        // specialProjects: [''],
      },
      isModelOpen: false,
      isModelOpen2: false,
      header: null,
    };
  }

  componentDidMount() {
    var result = ProcessCombos(
      'ContractLadderAssociation,ContractMajorTheme,ContractCharacterQuality,ContractCQProject,CenterCourses',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }

    var contractId = null;
    if (this.getContractId() && this.getContractId() != 'null') {
      contractId = this.getContractId();
      this.props.dispatch(
        getContract({
          StudentId: this.getStudentId(),
          StudentContractId: contractId,
        }),
      );
    }
  }
  mapCharacterData = data => {
    let cqData = [];
    if (data) {
      for (let item of data) {
        let obj = {};
        obj.studentContractCharacterQualityId = item.StudentContractCharacterQualityId;
        obj.characterQualityIds = item.CharacterQualityId;
        obj.cQProjectIds = item.CQProjectId;
        cqData.push(obj);
      }
    }
    return cqData;
  };
  mapCourseData = data => {
    let courseData = [];
    let dataArr = data && data.split(',');
    if (dataArr) {
      for (let item of dataArr) {
        let obj = {};
        obj.courseIds = parseInt(item);
        courseData.push(obj);
      }
    }
    return courseData;
  };
  componentWillReceiveProps({ contractData, updateSuccess, deleteSuccess }) {
    if (updateSuccess) {
      this.toggle();
      this.props.dispatch(resetUpsertContract());
    }
    if (deleteSuccess) {
      this.toggle(Delete_Sucess_Msg);
      this.props.dispatch(resetDeleteContract());
    }
    if (contractData && contractData.StudentId && this.getContractId() > 0) {
      const data = {
        majorThemeId: contractData.MajorThemeId,
        minorThemes: contractData.MinorThemes && contractData.MinorThemes.split('|'),
        goals: contractData.Goals && contractData.Goals.split('|'),
        bibleMemorizations:
          contractData.BibleMemorizations && contractData.BibleMemorizations.split('|'),
        bibleReadings: contractData.BibleReadings && contractData.BibleReadings.split('|'),
        lessons: contractData.Lessons && contractData.Lessons.split('|'),
        specialProjects: contractData.SpecialProjects && contractData.SpecialProjects.split('|'),
        courseIds: this.mapCourseData(contractData.CourseIds),
        ladderAssociationId: contractData.LadderAssociationId,
        cqProject: this.mapCharacterData(contractData.CharacterQuality),
        adminNotes: contractData.AdminNotes,
        recipients: contractData.Recipients,
        startDate: contractData.StartDate,
        dueDate: contractData.DueDate,
        studentId: contractData.StudentId,
        studentName: this.getStudentName(),
        msg: contractData.Msg,
      };
      this.setState({ newContract: data });
    }
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId' || 'StudentId');
  }

  getStudentName() {
    return sessionStorage.getItem('displayName');
  }

  getContractId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('contractId' || 'ContractId');
  }

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.courseIds);
    }
    return array.toString();
  };

  fnModalOpen = () => {
    this.setState({
      isModelOpen2: true,
    });
  };

  onSubmit(values) {
    let data = {
      StudentContract: [
        {
          MajorThemeId: values.majorThemeId,
          MinorThemes: (values.minorThemes && values.minorThemes.toString().replace(/,/g, '|')) || [
            '',
          ],
          Goals: (values.goals && values.goals.toString().replace(/,/g, '|')) || [''],
          BibleMemorizations: (values.bibleMemorizations &&
            values.bibleMemorizations.toString().replace(/,/g, '|')) || [''],
          BibleReadings: (values.bibleReadings &&
            values.bibleReadings.toString().replace(/,/g, '|')) || [''],
          Lessons: (values.lessons && values.lessons.toString().replace(/,/g, '|')) || [''],
          SpecialProjects: (values.specialProjects &&
            values.specialProjects.toString().replace(/,/g, '|')) || [''],
          CourseIds: this.getStringType(values.courseIds),
          LadderAssociationId: values.ladderAssociationId,
          AdminNotes: values.adminNotes,
          Recipients: values.recipients,
          StartDate: stringDate(values.startDate),
          DueDate: stringDate(values.dueDate),
          Msg: values.msg,
          CharacterQuality:
            values.cqProject &&
            values.cqProject.map(item => {
              return {
                StudentContractCharacterQualityId:
                  (item.studentContractCharacterQualityId &&
                    item.studentContractCharacterQualityId) ||
                  null,
                CharacterQualityId: item.characterQualityIds,
                CQProjectId: item.cQProjectIds,
              };
            }),
        },
      ],
    };
    data = {
      StudentId: this.getStudentId() > 0 ? this.getStudentId() : null,
      StudentContractId: this.getContractId() > 0 ? this.getContractId() : null,
      Json: JSON.stringify(data),
    };
    this.props.dispatch(upsertContract(data));
  }

  toggle = header => this.setState(ps => ({ isModelOpen: !ps.isModelOpen, header }));
  toggle = header => this.setState(ps => ({ isModelOpen2: !ps.isModelOpen2, header }));

  componentWillUnmount() {
    this.props.dispatch(resetGetContract());
  }

  render() {
    return (
      <>
        <ReduxNewContract
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.state.newContract}
          getContractId={this.getContractId()}
          getStudentId={this.getStudentId()}
          fnModalOpen={this.fnModalOpen}
          studentName={this.getStudentName()}
          upadateFetching={this.props.upadateFetching}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header || 'Your changes have been saved successfully'}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/studentProfile/' + this.getStudentId());
          }}
          yesText="OK"
          hideCancel={true}
        />

        <CustomModal
          isOpen={this.state.isModelOpen2}
          header={this.state.header || 'Saved successfully'}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/studentProfile/' + this.getStudentId());
          }}
          yesText="OK"
          hideCancel={true}
        />
      </>
    );
  }
}

const MinorTheme = ({ fields }) => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-12 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-11 field-array-item">
              <div class="form-group">
                <Field name={item} class="form-control" type="text" component={ReduxTextarea} />
                <label class="placeholder-label">Minor Theme(s)</label>
                <span class="small-info">Doubles as the “name” of Growth Plan</span>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Minor Theme Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'MINOR THEME' : 'ANOTHER MINOR THEME'}
      </button>
    </div>
  </div>
);

const Goals = ({ fields }) => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-12 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-11 field-array-item">
              <div class="form-group">
                <Field name={item} class="form-control" type="text" component={ReduxTextarea} />
                <label class="placeholder-label">Goal(s)</label>
                <span class="small-info">Provide a specific list of goal(s) to be achieved</span>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Goal Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'Goal' : 'Another Goal'}
      </button>
    </div>
  </div>
);

const BibleMemo = ({ fields }) => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-12 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-11 field-array-item">
              <div class="form-group">
                <Field name={item} class="form-control" type="text" component={ReduxTextarea} />
                <label class="placeholder-label">Bible Memorization</label>
                <span class="small-info">Two or more scripture verses</span>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Bible Memorization Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'Bible Memorization' : 'Another Bible Memorization'}
      </button>
    </div>
  </div>
);

const BibleReading = ({ fields }) => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-12 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-11 field-array-item">
              <div class="form-group">
                <Field name={item} class="form-control" type="text" component={ReduxTextarea} />
                <label class="placeholder-label">Bible Reading</label>
                <span class="small-info">One or more books</span>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Bible Reading Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {fields.length == 0 ? 'Bible Reading' : 'Another Bible Reading'}
      </button>
    </div>
  </div>
);

const Lessons = ({ fields }) => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-12 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-11 field-array-item">
              <div class="form-group">
                <Field name={item} class="form-control" type="text" component={ReduxTextarea} />
                <label class="placeholder-label">Personal Reading</label>
                <span class="small-info">One or more Personal Reading</span>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Personal Reading Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'PERSONAL READING' : 'ANOTHER PERSONAL READING'}
      </button>
    </div>
  </div>
);

const SpecialProject = ({ fields }) => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-12 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row">
            <div key={index} className="col-11 field-array-item">
              <div class="form-group">
                <Field name={item} class="form-control" type="text" component={ReduxTextarea} />
                <label class="placeholder-label">Special Project(s)</label>
                <span class="small-info">Special project (optional)</span>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Special Projects Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'Special Project' : 'Another Special Project'}
      </button>
    </div>
  </div>
);

const CQProject = ({ fields, ContractCharacterQuality, ContractCQProject }) => (
  <div className="row">
    <div className="col-9 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row ">
            <div key={index} className="col-10 field-array-item">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    {/* <label class="placeholder-label">Character Qualities</label> */}
                    <Field
                      name={`${item}.characterQualityIds`}
                      class="custom-select enrollment-select"
                      component={ReduxSelect}
                      placeholder="Character Qualities"
                      options={ContractCharacterQuality}
                      // isMulti
                    />
                    <span class="small-info">Select the character trait focus</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    {/* <label class="placeholder-label">CQ Projects</label> */}
                    <Field
                      name={`${item}.cQProjectIds`}
                      class="custom-select enrollment-select"
                      component={ReduxSelect}
                      placeholder="CQ Projects"
                      options={ContractCQProject}
                      // isMulti
                    />

                    <span class="small-info">
                      Select a project type to assist in character trait development
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>CQ Project Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'CQ Project' : 'ANOTHER CQ Project'}
      </button>
    </div>
  </div>
);
const Courses = ({ fields, courseOptions }) => (
  <div className="row">
    <div className="col-12 col-md-12 col-lg-12 pr-xl-5  my-3">
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <div class="row ">
            <div key={index} className="col-11 field-array-item">
              <div class="form-group">
                {/* <label class="placeholder-label">Lessons</label> */}
                <Field
                  name={`${item}.courseIds`}
                  class="custom-select enrollment-select"
                  component={ReduxSelect}
                  placeholder="Lessons"
                  options={courseOptions}
                />
                <span class="small-info">Select Lesson(s)</span>
              </div>
            </div>
            <a
              class="col-1 delete-contract"
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  fields.remove(index);
                }
              }}
            >
              <i class="fas fa-trash" /> Delete
            </a>
          </div>
        ))
      ) : (
        <div class="row">
          <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Lesson Record (empty)</h6>
            </div>
          </div>
        </div>
      )}
    </div>
    <div class="w-100" style={{ position: 'relative', top: '85px', left: '10px' }}>
      <button type="button" class="btn-newCon" onClick={() => fields.push()}>
        + Add {!fields.length ? 'Lesson' : 'ANOTHER Lesson'}
      </button>
    </div>
  </div>
);

let NewContract = ({
  onSaveClick,
  getContractId,
  history,
  handleSubmit,
  ContractMajorTheme,
  ContractCharacterQuality,
  ContractCQProject,
  getStudentId,
  dispatch,
  fnModalOpen,
  courseOptions,
  ContractLadderAssociation,
  studentName,
  upadateFetching,
}) => {
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div class="main enrollment-page mb-5">
        <div class="container-fluid">
          <div class="row top-table-search mt-5 mb-3 align-items-end">
            <div class="col-12 col-md-6 col-lg-3">
              {/* <a class="back-link" onClick={() => history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a> */}
              <AntdModal
                modalWidth={420}
                ModalButton={({ onClick }) => (
                  <a class="back-link" style={{ cursor: 'pointer' }} onClick={onClick}>
                    <img src="images/back-arrow.svg" /> Back
                  </a>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div className="modal-content border-0">
                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                      <h4 className="modal-title col-12">
                        Are you sure you want to exit this page? Any unsaved changes will be lost.
                      </h4>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pb-4 pt-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary py-1"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn py-1"
                        onClick={() => {
                          onOk();
                          history.goBack();
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
              />
              <div class="d-flex align-items-center flex-wrap">
                <h3 class="page-title mb-3 mb-md-0 ">
                  {getContractId > 0 ? 'Edit Growth Plan' : 'New Growth Plan'}{' '}
                  {getContractId > 0 ? (
                    ''
                  ) : (
                    <button
                      onClick={() =>
                        history.push({
                          pathname: '/templates',
                          search: 'studentId=' + getStudentId,
                        })
                      }
                      type="button"
                      class="btn mt-2 mb-3 mb-md-0 ml-0 ml-sm-3 cft"
                    >
                      Create from Template
                    </button>
                  )}
                </h3>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-9 text-right d-flex justify-content-end align-items-start">
              {getContractId > 0 && (
                <Link
                  // class="btn black-btn"
                  to={{
                    pathname: '/printContract',
                    search: `studentId=${getStudentId}&contractId=${getContractId}`,
                  }}
                  onClick={() => {
                    localStorage.setItem('displayName', studentName);
                  }}
                  target="_blank"
                  // className = "print-tooltip"
                  // style = {{position: 'relative'}}
                >
                  <Tooltip placement="top" title="Print">
                    <figure
                      class="action-button-icon"
                      style={{ display: 'inline-block', position: 'relative' }}
                    >
                      <img src="images/print_action.svg" alt="" width="50" />
                      {/* Print */}
                    </figure>
                  </Tooltip>
                </Link>
              )}
              {/* <Link
                to={'/studentProfile/' + getStudentId}
                class="btn btn-outline-primary black-btn"
              >
                Cancel
              </Link> */}
              <AntdModal
                modalWidth={420}
                ModalButton={({ onClick }) => (
                  <button
                    type="button"
                    class="btn btn-outline-primary black-btn"
                    style={{ cursor: 'pointer' }}
                    onClick={onClick}
                  >
                    Cancel
                  </button>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div className="modal-content border-0">
                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                      <h4 className="modal-title col-12">
                        Are you sure you want to exit this page? Any unsaved changes will be lost.
                      </h4>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pb-4 pt-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary py-1"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn py-1"
                        onClick={() => {
                          onOk();
                          history.push('/studentProfile/' + getStudentId);
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
              />
              {/* <button type="submit" class="btn ml-3">
                Save
              </button> */}
              <SubmitButton loading={upadateFetching} style={{ height: '45px' }} value={'Save'} />
              {/* <Link
                // class="btn black-btn"
                to={{
                  pathname: '/printContract',
                  search: `studentId=${getStudentId}&contractId=${getContractId}`,
                }}
                onClick={() => {
                  localStorage.setItem('displayName', studentName);
                }}
                target="_blank"
              >
                 Print 
                <img src="images/print.svg" alt="" width="70"></img>
              </Link> */}
            </div>
          </div>
          <div class="row mb-4 top-contract-row">
            <div class="col-12">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form growth-plan">
                  <div class="row">
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          name="studentName"
                          type="text"
                          errorMessage="Required"
                          component={ReduxInput}
                          disabled={true}
                        />
                        <label class="placeholder-label">Client</label>
                        {/* <span class="small-info">Active student</span> */}
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          name="startDate"
                          type="text"
                          class="form-control datepicker hasDatepicker"
                          id="dp1568900339471"
                          errorMessage="Start Date Required"
                          component={ReduxDateInput}
                          autoComplete="off"
                          label="Start Date*"
                          validate={Validator.required}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                        <span class="small-info">Date, Growth Plan is to begin</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pr-xl-5">
                      <div class="form-group">
                        <Field
                          name="dueDate"
                          type="text"
                          class="control-label datepicker hasDatepicker"
                          id="dp1568900339472"
                          // errorMessage="Due Date Required"
                          component={ReduxDateInput}
                          label="Due Date*"
                          fieldName={'Due Date'}
                          autoComplete="off"
                          // validate={Validator.required}
                          validate={[Validator.required, Validator.endDate]}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                        <span class="small-info">Expected date of completion</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row  mb-5">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form">
                  <div class="row pb-5 pl-3 pr-3 pt-3">
                    <div class="col-12 p-0">
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="ladderAssociationId"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Ladder Association"
                              options={ContractLadderAssociation}
                              validate={Validator.required}
                              fieldName={'Ladder Association'}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            {/* <label class="placeholder-label">Major Theme*</label> */}
                            <Field
                              name="majorThemeId"
                              type="text"
                              component={ReduxSelect}
                              placeholder="Major Theme*"
                              options={ContractMajorTheme}
                              validate={Validator.required}
                              fieldName={'Major Theme'}
                            />
                            <span class="small-info">Select general theme</span>
                          </div>
                        </div>
                      </div>
                      <FieldArray name="minorThemes" component={MinorTheme} />
                      <FieldArray name="goals" component={Goals} />
                      <FieldArray name="bibleMemorizations" component={BibleMemo} />
                      <FieldArray name="bibleReadings" component={BibleReading} />
                      <FieldArray name="lessons" component={Lessons} />
                      <FieldArray
                        name="cqProject"
                        component={CQProject}
                        ContractCharacterQuality={ContractCharacterQuality}
                        ContractCQProject={ContractCQProject}
                      />
                      <FieldArray
                        name="courseIds"
                        component={Courses}
                        courseOptions={courseOptions}
                      />

                      {/* Course Start Here */}
                      {/* <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <Field
                              name="courseIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Lessons"
                              options={courseOptions}
                              isMulti
                            />
                            <span class="small-info">Select Lesson(s)</span>
                          </div>
                        </div>
                      </div> */}

                      {/* Course Start Here */}
                      {/* <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <label class="placeholder-label">Character Qualities</label>
                            <Field
                              name="characterQualityIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Character Qualities"
                              options={ContractCharacterQuality}
                              isMulti
                            />
                            <span class="small-info">Select the character trait focus.</span>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 pr-xl-5">
                          <div class="form-group">
                            <label class="placeholder-label">CQ Projects</label>
                            <Field
                              name="cQProjectIds"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="CQ Projects"
                              isMulti
                              options={ContractCQProject}
                            />

                            <span class="small-info">
                              Select a project type to assist in character trait development.
                            </span>
                          </div>
                        </div>
                      </div> */}
                      <FieldArray name="specialProjects" component={SpecialProject} />

                      <div class="row">
                        <div class="col-12 col-md-12 col-lg-8 pr-xl-5 mt-4">
                          <div class="form-group">
                            <Field
                              name="adminNotes"
                              class="form-control textarea-box"
                              type="textarea"
                              rows="4"
                              component={ReduxTextarea}
                              style={{
                                minHeight: '100px',
                              }}
                            />
                            <label class="placeholder-label">Administrative Notes</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 text-right">
              {getContractId > 0 && (
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <a
                      class="deactivate-link"
                      data-toggle="modal"
                      data-target="#deactivate-staff-model"
                      onClick={onClick}
                    >
                      <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                    </a>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to Deactivate <br />
                          this Growth Plan?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn black-btn"
                          onClick={() => {
                            onCancel();
                            history.push('/studentProfile/' + getStudentId);
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            onOk();
                            dispatch(
                              deleteContract({
                                StudentId: getStudentId,
                                StudentContractId: getContractId,
                              }),
                            );
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              )}
              &nbsp;&nbsp;&nbsp;
              {getContractId > 0 && (
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <a
                      class="reactivate-link"
                      data-toggle="modal"
                      data-target="#deactivate-staff-model"
                      onClick={onClick}
                    >
                      {' '}
                      Complete
                    </a>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5" />
                      <CloseContractForm
                        onOk={onOk}
                        studentId={getStudentId}
                        StudentContractId={getContractId}
                        dispatch={dispatch}
                        fnModalOpen={fnModalOpen}
                      />
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2" />
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const { combos } = state;
  return {
    ContractLadderAssociation: !combos.data ? [] : combos.data.ContractLadderAssociation,
    ContractMajorTheme: !combos.data ? [] : combos.data.ContractMajorTheme,
    ContractCharacterQuality: !combos.data ? [] : combos.data.ContractCharacterQuality,
    ContractCQProject: !combos.data ? [] : combos.data.ContractCQProject,
    courseOptions: (combos.data && combos.data.CenterCourses) || [],
  };
};

const ReduxNewContract = connect(mapStateToProps)(
  reduxForm({
    form: 'new-contract',
    enableReinitialize: true,
  })(withRouter(NewContract)),
);

const mapStateToParent = ({ get_contract, upsert_contract, delete_contract }) => {
  const { data, isFetching, error } = get_contract;
  const { data: updateData, isFetching: upadateFetching, error: upadateError } = upsert_contract;
  const { data: deleteData } = delete_contract;
  let updateSuccess = false,
    deleteSuccess = false;
  if (updateData && updateData.success) updateSuccess = true;
  if (deleteData && deleteData.success) deleteSuccess = true;
  return {
    contractData: data || {},
    updateSuccess,
    deleteSuccess,
    upadateFetching,
  };
};

export default connect(mapStateToParent)(NewContractForm);
