import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Drawer, Checkbox } from 'antd';
import {
  serviceCodeSuggestionsGenerate,
  resetServiceCodeSuggestionsGenerate,
  serviceCodeSuggestionListGet,
  peerRecoveryServiceCodeUpdate,
  resetPeerRecoveryServiceCodeUpdate,
  getAppointment,
  comboServiceCodeGet,
} from '../../../../../store/actions';
import SubmitButton from '../../../../../Components/submitButton';
import CustomModal from '../../../../../core/modal';
import CloseIcon from '../../../../../component/closeIcon';
import SelectCPTCode from './SelectCPTCode';

const SuggestCPTCode = props => {
  const dispatch = useDispatch();

  const [isCPTDrawer, setCPTDrawer] = useState(false);
  const [isCPTAugmented, setIsCPTAugmented] = useState(false);
  const [isSaveConfirm, setIsSaveConfirm] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [header, setHeader] = useState();
  const [selectedCPT, setSelectedCPT] = useState({});
  const [isError, setIsError] = useState(false);
  const {
    isFetchingSuggestion,
    isSuggestionsGenerated,
    suggestionsResponse,
    isServiceCodeUpdated,
    serviceCodeUpdateMsg,
    EnableSuggestCPTCodes,
  } = useSelector(
    ({
      service_code_suggestions_generate: { data: suggestionsGenerateData, isFetching, error },
      service_code_suggestion_list_get: { data: suggestionsData },
      peer_recovery_service_code_update: { data: serviceCodeUpdateData },
      center_config_get: { data: centerConfig },
    }) => {
      return {
        isSuggestionsGenerated: suggestionsGenerateData && suggestionsGenerateData.success,
        isFetchingSuggestion: isFetching,
        suggestionError: error,
        EnableSuggestCPTCodes: centerConfig && centerConfig.CenterConfig[0].EnableSuggestCPTCodes,
        suggestionsResponse: suggestionsData && suggestionsData.ServiceCodes,
        isServiceCodeUpdated: serviceCodeUpdateData && serviceCodeUpdateData.success,
        serviceCodeUpdateMsg:
          serviceCodeUpdateData &&
          serviceCodeUpdateData.data &&
          JSON.parse(serviceCodeUpdateData.data) &&
          JSON.parse(serviceCodeUpdateData.data)[0] &&
          JSON.parse(serviceCodeUpdateData.data)[0].Msg
            ? JSON.parse(serviceCodeUpdateData.data)[0].Msg
            : '',
      };
    },
    shallowEqual,
  );
  const { AppointmentId, StudentId, IsServiceCodeApproved } = props;
  // useEffect(() => {
  //   getCPTSuggestions();
  // }, []);
  useEffect(() => {
    if (isSuggestionsGenerated) {
      getCPTSuggestions();
      dispatch(resetServiceCodeSuggestionsGenerate());
    }
  }, [isSuggestionsGenerated]);
  useEffect(() => {
    if (isServiceCodeUpdated) {
      dispatch(
        comboServiceCodeGet({
          Json: JSON.stringify({
            Combo: [
              {
                IsPeerRecovery: true,
                AppointmentId: AppointmentId,
              },
            ],
          }),
        }),
      );
      dispatch(
        getAppointment({
          json: JSON.stringify({
            Appointment: [
              {
                AppointmentId: AppointmentId,
              },
            ],
          }),
        }),
      );
      dispatch(resetPeerRecoveryServiceCodeUpdate());
      toggle(serviceCodeUpdateMsg);
    }
  }, [isServiceCodeUpdated]);
  useEffect(() => {
    if (suggestionsResponse && suggestionsResponse[0]) {
      setSuggestionsList(suggestionsResponse[0].Codes);
      setIsCPTAugmented(suggestionsResponse[0].IsAugmented);
      if (!suggestionsResponse[0].IsAugmented) {
        generateCPTSuggestions();
      }
    }
  }, [suggestionsResponse]);
  const generateCPTSuggestions = () => {
    dispatch(
      serviceCodeSuggestionsGenerate({
        AppointmentId: AppointmentId,
        StudentId: StudentId,
      }),
    );
  };

  const getCPTSuggestions = () => {
    dispatch(
      serviceCodeSuggestionListGet({
        Json: JSON.stringify({
          Appointment: [
            {
              // AppointmentId: 6726,
              AppointmentId: AppointmentId,
            },
          ],
        }),
      }),
    );
  };

  const assignCPTSuggestions = () => {
    let selectedCPTData = Object.keys(selectedCPT).filter(key => selectedCPT[key] === true);
    dispatch(
      peerRecoveryServiceCodeUpdate({
        Json: JSON.stringify({
          Student: [
            {
              AppointmentId: AppointmentId,
              CodeName: selectedCPTData.toString(),
            },
          ],
        }),
      }),
    );
    setSelectedCPT({});
  };

  const toggle = header => {
    setHeader(header);
    setIsModelOpen(!isModelOpen);
  };
  const toggleDrawer = () => {
    setCPTDrawer(!isCPTDrawer);
    if (!isCPTDrawer) {
      getCPTSuggestions();
    }
  };
  const selectCPTCode = () => {
    let selectedCPTData = Object.keys(selectedCPT).filter(key => selectedCPT[key] === true);
    if (selectedCPTData.length > 0) {
      toggleDrawer();
      setIsSaveConfirm(true);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };
  const onChange = e => {
    let isChecked = e.target.checked;
    // let selectedData = selectedCPT;
    // if (isChecked) {
    //   selectedData = {
    //     ...selectedData,
    //     [e.target.name]: e.target.checked,
    //   };
    // } else {
    //   delete selectedData[e.target.name];
    // }
    let selectedData = {
      ...selectedCPT,
      [e.target.name]: isChecked,
    };
    setSelectedCPT(selectedData);
    setIsError(false);
  };
  return (
    <div className="row suggest-cpt-code">
      <div className="col-12 text-right">
        {/* <div className="">
          <SubmitButton
            type="button"
            style={{ height: '45px' }}
            loading={isFetchingSuggestion}
            value="Suggest CPT Code"
            onClick={generateCPTSuggestions}
            // disabled={isCPTAugmented}
          />
        </div> */}
        {EnableSuggestCPTCodes && (
          <div className="">
            <button
              type="button"
              class="btn"
              onClick={toggleDrawer}
              disabled={IsServiceCodeApproved}
            >
              Suggest CPT Code
            </button>
          </div>
        )}
      </div>

      {isSaveConfirm && (
        <CustomModal
          isOpen={isSaveConfirm}
          header={`<div className="cpt-warning-title">
              <span className="t1">
                The selected CPT code will be auto populated in the Service Code field on the
                Details tab.
              </span>
              <br /> <br />
              <span className="t2">Are you sure you want to proceed with this change?</span>
            </div>`}
          yesAction={() => {
            assignCPTSuggestions();
            setIsSaveConfirm(false);
          }}
          yesText="Yes"
          noAction={() => {
            setIsSaveConfirm(false);
          }}
          noText="No"
        />
      )}
      {isModelOpen && (
        <CustomModal
          isOpen={isModelOpen}
          header={header}
          yesAction={() => {
            toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      )}
      {isCPTDrawer && (
        <Drawer
          className="suggest-cpt-drawer"
          open={isCPTDrawer}
          title={
            <div className="drawer-title">
              <span className="title">Please select CPT code(s)</span>
            </div>
          }
          placement="right"
          width={450}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<CloseIcon />}
          onClose={() => {
            toggleDrawer();
          }}
          extra={() => {
            debugger;
          }}
        >
          <div className="col-12">
            {/* <SelectCPTCode suggestionsList={suggestionsList} /> */}
            <div className="row">
              <div className="col-12">
                {isError && <p className="text-danger"> Please select atleast one CPT code</p>}
                <div className="suggested-cpt-code-list">
                  <ul>
                    {suggestionsList &&
                      suggestionsList.map((cptCode, index) => {
                        const { CodeName, Text } = cptCode;
                        return (
                          <li key={CodeName}>
                            <div className="item">
                              <span className="selection">
                                <Checkbox
                                  onChange={onChange}
                                  name={CodeName}
                                  checked={selectedCPT[CodeName]}
                                ></Checkbox>
                              </span>
                              <div className="detail">
                                <span className="code">{CodeName}</span>
                                <span className="desc">{Text}</span>
                              </div>
                              {/* <div className="action">
                            <span
                              onClick={() => {
                                assignCPTSuggestions(cptCode);
                              }}
                            >
                              Select CPT Code
                            </span>
                          </div> */}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="col-12 text-right">
                <button
                  type="button"
                  class="btn"
                  onClick={selectCPTCode}
                  // disabled={Object.keys(selectedCPT).length}
                >
                  Select CPT Code
                </button>
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};
export default SuggestCPTCode;
