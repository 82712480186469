import React from 'react';
import BedComponent from './bedComponent';
import Slider from 'react-slick';

const SampleNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      // style={{ ...style, display: 'block', background: 'red' }}
      style={{
        ...style,
        height: '25px',
        width: '25px',
        fontSize: '0',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        backgroundSize: '22px 22px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        position: 'absolute',
        top: '-23%',
        left: '96%',
        transform: 'translateY(-50%)',
        backgroundImage: 'url(../images/sliderArrows/scroll-right.svg)',
        cursor: 'pointer',
        backgroundSize: '19px 45px',
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: 'block',
        background: 'green',
        height: '25px',
        width: '25px',
        fontSize: '0',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        backgroundSize: '22px 22px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        position: 'absolute',
        top: '-23%',
        right: '4%',
        transform: 'translateY(-50%)',
        backgroundImage: 'url(../images/sliderArrows/scroll-left.svg)',
        cursor: 'pointer',
        backgroundSize: '19px 45px',
      }}
      onClick={onClick}
    />
  );
};

class RoomComponent extends React.Component {
  render() {
    const { roomData } = this.props;
    const { roomNumber, category, bedStatus } = roomData;
    const { total, available, reserved, occupied, notavailable, beds } = bedStatus[0];
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <React.Fragment>
        <div className="row bedSlider mt-1 mb-5">
          {' '}
          <div className="col-md-12">
            <p>
              <span className="room-number">
                Room {roomNumber} ({category})
              </span>{' '}
              <span className="room-total">Total {total} beds</span>
              <span className="room-status">
                {available} Available, {reserved} Reserved, {occupied} Occupied, {notavailable} Not
                Available
              </span>{' '}
            </p>
          </div>
          <div className="col-md-12">
            <div>
              <Slider {...settings}>
                {beds.map((bed, item) => {
                  return (
                    <BedComponent
                      bed={bed}
                      setAdmissionFormVisiblity={this.props.setAdmissionFormVisiblity}
                      roomNumber={roomNumber}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RoomComponent;
