import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
// import { currencyFormatter, numberFormat } from '../../../../../../../util/formatter';
import { currencyFormatter } from '../../../../util/formatter';

const ServiceCode = ({ RequestItem, index }) => {
  const dispatch = useDispatch();
  const {
    RequestType,
    StatusId,
    Note,
    CodeId,
    ServiceCategory,
    ServiceCodeDescription,
    BillingRate,
    ChangeNotes,
  } = RequestItem;
  return (
    <div className="service-code p-1">
      <div className="request-info">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <span className="request-sr-no">{index + 1}.</span>
            <div>
              <div className="code-details">
                <div className="item-block">
                  <span className="label">Service Code</span>
                  <span className="value">{CodeId}</span>
                </div>
                <div className="item-block">
                  <span className="label">Service Category</span>
                  <span className="value">{ServiceCategory}</span>
                </div>
              </div>
              <div className="item-block">
                <span className="label">Code Description</span>
                <span className="des-value">{ServiceCodeDescription}</span>
              </div>
            </div>
          </div>

          <div className="total">
            <span>Total: {currencyFormatter.format(BillingRate || 0)}</span>
          </div>
        </div>
      </div>
      <div className="request-detail">
        <span className="label">Details</span>
        <span className="details">{Note}</span>
      </div>

      {ChangeNotes && (
        <div className="request-detail mt-3">
          <span className="label">Notes</span>
          <span className="details">{ChangeNotes}</span>
        </div>
      )}
    </div>
  );
};

export default ServiceCode;
