import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import renderHTML from 'react-render-html';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { Loader2 } from '../../../Components/Loader';
import AntdModal from '../../../Components/CustomModal';
// import CustomModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import { signedNoteGet, resetSignedNoteGet, unsignForm } from '../../../store/actions';
import PageTitle from '../../../Components/PageTitle';



class ClinicalAndGroupNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
      isModalOpen: false,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  componentWillMount() {
    this.props.dispatch(resetSignedNoteGet({}));
  }

  componentDidMount() {
    this.props.dispatch(
      signedNoteGet({
        JsonInput: JSON.stringify({
          Admin: [
            {
              IsGroupNote: this.props.isClinicalNotes ? false : true,
            },
          ],
        }),
      }),
    );
  }

  componentWillReceiveProps({ unsignFormStatus }) {
    if (unsignFormStatus && unsignFormStatus != this.props.unsignFormStatus) {
      this.setState({
        isModalOpen: true,
      });
    }
  }


  render() {
    const { isFetching, clinicalAndGroupNotes } = this.props;
    const clinicalColumns = [
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Center Name',
        dataIndex: 'CenterName',
        key: 'CenterName',
        sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
      },
      {
        title: 'Session Date',
        dataIndex: 'SessionDate',
        key: 'SessionDate',
        sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
      },

      {
        title: 'Start/End Time',
        dataIndex: 'StartEndTime',
        key: 'StartEndTime',
        sorter: (a, b) => isComparer(a.StartEndTime, b.StartEndTime),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => text && <div>{`${text}`}</div>,
      },

      {
        title: 'Service Code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => <div style={{ width: '300px' }}>{text}</div>,
      },
      {
        title: 'Program',
        dataIndex: 'Program',
        key: 'Program',
        sorter: (a, b) => isComparer(a.Program, b.Program),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => text && <div>{`${text}`}</div>,
      },
      {
        title: 'Signed By',
        dataIndex: 'SignedBy',
        key: 'SignedBy',
        sorter: (a, b) => isComparer(a.SignedBy, b.SignedBy),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => text && <span style={{ color: '#979797' }}>{`${text}`}</span>,
      },
      {
        title: 'Action',
        dataIndex: 'x',
        render: (text, record, index) => {
          return (
            <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="delete-btn"
                  onClick={e => {
                    onClick();
                    e.stopPropagation();
                  }}
                  data-toggle="modal"
                  data-target="#delete-Screen-Tool"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap', color: '#FE4F4F' }}
                >
                  Un-sign
                </span>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                    <h4 class="modal-title col-12 mb-3">
                      Are you sure you want to Un-sign this note?
                    </h4>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      class="btn btn-outline-primary black-btn"
                      onClick={e => {
                        onCancel();
                        e.stopPropagation();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      class="btn"
                      onClick={e => {
                        e.stopPropagation();

                        onOk();
                        let data = {
                          Json: JSON.stringify({
                            UnSign: [
                              {
                                FormType: record.FormType,
                                FormId: record.FormId,
                                // TreatmentPlanId: this.props.clikedTreatmentPlanId,
                              },
                            ],
                          }),
                        };

                        this.props.dispatch(unsignForm(data));
                        // this.deleteGroup(record.GroupId);
                        // this.props.onCancel();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          );
        },
      },
    ];

    const groupColumns = [
      {
        title: 'Center Name',
        dataIndex: 'CenterName',
        key: 'CenterName',
        sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div style={{ width: '250px' }}>{text && text}</div>,
      },
      {
        title: 'Group Name',
        dataIndex: 'GroupName',
        key: 'GroupName',
        sorter: (a, b) => isComparer(a.GroupName, b.GroupName),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
      },
      {
        title: 'Session Date',
        dataIndex: 'SessionDate',
        key: 'SessionDate',
        sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => <div style={{ width: '300px' }}>{text && renderHTML(text)}</div>,
      },

      {
        title: 'Start/End Time',
        dataIndex: 'StartEndTime',
        key: 'StartEndTime',
        sorter: (a, b) => isComparer(a.StartEndTime, b.StartEndTime),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => text && <div>{`${text}`}</div>,
      },
      {
        title: 'Program',
        dataIndex: 'Program',
        key: 'Program',
        sorter: (a, b) => isComparer(a.Program, b.Program),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => <div style={{ width: '250px' }}>{text && text}</div>,
      },
      {
        title: 'Addendums',
        dataIndex: 'Addendums',
        key: 'Addendums',
        sorter: (a, b) => isNumericComparer(a.Addendums, b.Addendums),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => text && <div>{`${text}`}</div>,
      },
      {
        title: 'Signed By',
        dataIndex: 'SignedBy',
        key: 'SignedBy',
        sorter: (a, b) => isComparer(a.SignedBy, b.SignedBy),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) =>
          // text && <div style={{ color: '#979797', width: '200px' }}>{`${text}`}</div>,
      },
      {
        title: 'Action',
        dataIndex: 'x',
        // fixed: 'right',
        render: (text, record, index) => {
          return (
            <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="delete-btn"
                  onClick={e => {
                    onClick();
                    e.stopPropagation();
                  }}
                  data-toggle="modal"
                  data-target="#delete-Screen-Tool"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap', color: '#FE4F4F' }}
                >
                  Un-sign
                </span>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                    <h4 class="modal-title col-12 mb-3">
                      Are you sure you want to Un-sign this note?
                    </h4>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      class="btn btn-outline-primary black-btn"
                      onClick={e => {
                        onCancel();
                        e.stopPropagation();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      class="btn"
                      onClick={e => {
                        e.stopPropagation();

                        onOk();
                        let data = {
                          Json: JSON.stringify({
                            UnSign: [
                              {
                                FormType: record.FormType,
                                FormId: record.FormId,
                                // TreatmentPlanId: this.props.clikedTreatmentPlanId,
                              },
                            ],
                          }),
                        };

                        this.props.dispatch(unsignForm(data));
                        // this.deleteGroup(record.GroupId);
                        // this.props.onCancel();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          );
        },
      },
    ];

    return (
      <div class="main  mb-5">
        <div class="container-fluid">
          <PageTitle TitleText="Un-sign Notes" />
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="row-hover-enabled">
                      <Table
                        columns={this.props.isClinicalNotes ? clinicalColumns : groupColumns}
                        dataSource={clinicalAndGroupNotes}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: event => this.onRowClick(record.ResourceId),
                        //   };
                        // }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomModal
            isOpen={this.state.isModalOpen}
            header={'Note un-signed successfully'}
            className="unsign-modal"
            yesAction={() => {
              // this.deleteToggle();
              this.setState({
                isModalOpen: false,
              });
              this.props.dispatch(
                signedNoteGet({
                  JsonInput: JSON.stringify({
                    Admin: [
                      {
                        IsGroupNote: this.props.isClinicalNotes ? false : true,
                      },
                    ],
                  }),
                }),
              );
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ signed_note_get, unsign_form }) => {
  var { data, isFetching, error } = signed_note_get;
  const { data: unsignForm } = unsign_form;

  let clinicalAndGroupNotes = [];
  let unsignFormStatus = false;

  if (data && data.Notes) {
    clinicalAndGroupNotes = data.Notes;
  }
  if (unsignForm && unsignForm.success) {
    unsignFormStatus = unsignForm.success;
  }
  return {
    clinicalAndGroupNotes,
    isFetching,
    unsignFormStatus,
  };
};

export default connect(mapStateToProps)(ClinicalAndGroupNotes);
