import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import CustomModal from '../../../core/modal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { isComparer, isDateComparer } from '../../../util/handler';
import { stringDate } from '../../../util/date';
import AntdModal from '../../../Components/CustomModal';
import {
  addNoteType,
  noteTypeUpsert,
  resetAddNoteType,
  listCombos,
  adminstrationCourseUpsert,
  resetAdminstrationCourseUpsert,
  deleteNoteType,
  getNoteTypeArchive,
  getNoteTypeList,
  getPredefinedTask,
  getPredefinedTaskArchive,
  deletePredefinedTask,
  upsertPredefinedTask,
  resetPredefinedTask,
} from '../../../store/actions';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../../component/custom-redux-components';
import SubmitButton from '../../../Components/submitButton';
import PageTitle from '../../../Components/PageTitle';
class NoteType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
      filterBy: 1,
      isModelOpen: false,
      header: '',
      deleteAction: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(getNoteTypeList({}));
    this.props.dispatch(getNoteTypeArchive({}));
  }
  componentWillReceiveProps({ isAddNodeType, isDeleteNodeType, operation }) {
    if (isAddNodeType && isAddNodeType != this.props.isAddNodeType) {
      // this.setState({ AddNodeModelOpen: true });
      this.setState({ note: '' });
      if (operation == 'add') {
        this.toggle('Note Type Added Successfully');
      } else if (operation == 'restored') {
        this.toggle('Note Type restored Successfully');
      } else {
        this.toggle('Note Type archived Successfully');
      }
      this.props.dispatch(resetAddNoteType());
      this.props.dispatch(getNoteTypeList({}));
      this.props.dispatch(getNoteTypeArchive({}));
    }
    if (isDeleteNodeType && isDeleteNodeType != this.props.isDeleteNodeType) {
      // this.setState({ AddNodeModelOpen: true });
      this.toggle(`Note Type ${this.state.deleteAction} Successfully`);
      this.props.dispatch(getNoteTypeList({}));
      this.props.dispatch(getNoteTypeArchive({}));
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const { note, filterBy } = this.state;
    const { archivedData, noteType } = this.props;

    const noteColumns = [
      {
        title: 'Note Type Title',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        // sorter: (a, b) => isComparer(a.Title, b.Title),
        // sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => (
          <div class="tools-grid">
            {record.IsActionAllowed && (
              <div className="">
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      class="delete-btn"
                      onClick={onClick}
                      data-toggle="modal"
                      data-target="#delete-Screen-Tool"
                      style={{ cursor: 'pointer' }}
                    >
                      <Tooltip placement="top" title="Archive">
                        <img src="images/archive.svg" width="25" />
                      </Tooltip>
                      {/* <i class="far fa-trash-alt"></i> */}
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to archive this Note Type?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn btn-outline-primary black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            this.props.dispatch(
                              noteTypeUpsert({
                                JsonInput: JSON.stringify({
                                  NoteType: [
                                    {
                                      NoteTypeId: record.Value,
                                      // IsDelete: 1,
                                      IsExcluded: true,
                                    },
                                  ],
                                }),
                                operation: 'archived',
                              }),
                            );
                            this.state.deleteAction = 'archived';
                            onOk();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        ),
      },
    ];
    const archivedNoteColumns = [
      {
        title: 'Note Type Archived',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        // sorter: (a, b) => isComparer(a.Title, b.Title),
        // sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => (
          <div class="tools-grid">
            {record.IsActionAllowed && (
              <div className="">
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      class="delete-btn"
                      onClick={onClick}
                      data-toggle="modal"
                      data-target="#delete-Screen-Tool"
                      style={{ cursor: 'pointer' }}
                    >
                      <img src="images/restore_icon.svg" width="85" />
                      {/* <i class="far fa-trash-alt"></i> */}
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to restore this Note Type?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn btn-outline-primary black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            this.props.dispatch(
                              noteTypeUpsert({
                                JsonInput: JSON.stringify({
                                  NoteType: [
                                    {
                                      NoteTypeId: record.Value,
                                      IsExcluded: false,
                                    },
                                  ],
                                }),
                                operation: 'restored',
                              }),
                            );
                            this.state.deleteAction = 'restored';

                            onOk();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            )}
          </div>
        ),
      },
    ];
    return (
      <div>
        <PageTitle TitleText="Miscellaneous" />
        <div class="content-area  setting-gap pl-0">
          <div class="mb-3">
            <h6>Note Type</h6>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="enrollment-form">
                <div class="form-group">
                  <input
                    className={`form-control ${note && 'not-empty'}`}
                    value={note}
                    onChange={e => this.setState({ note: e.target.value })}
                  />
                  <label className="placeholder-label">Note Type</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              {/* <button
                type="button"
                class="btn px-5"
                disabled={!note}
                onClick={() => {
                  this.props.dispatch(addNoteType({ Note: note }));
                }}
              >
                Add
              </button> */}
              <SubmitButton
                loading={this.props.isNoteUpsert}
                onClick={() => {
                  // this.props.dispatch(addNoteType({ Note: note }));
                  let data = { NoteType: { Description: note.trim() } };
                  this.props.dispatch(
                    noteTypeUpsert({ JsonInput: JSON.stringify(data), operation: 'add' }),
                  );
                }}
                disabled={!note.trim()}
                value={'Add'}
                style={{ height: '45px', padding: '0 45px' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <div className="text-left app-subscriber-page d-inline-block">
              <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                <ReduxSelect
                  options={[
                    { Text: 'Active', Value: 1 },
                    { Text: 'Archived', Value: 2 },
                  ]}
                  onChange={val => {
                    this.setState({
                      filterBy: val,
                    });
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterBy}
                  placeholder="Filter By"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        {filterBy === 1 ? (
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    {/* <Loader2 loading={isFetching} /> */}
                    <Table
                      columns={noteColumns}
                      dataSource={noteType}
                      //   onRow={(record, rowIndex) => {
                      //     return {
                      //       onClick: event => this.onRowClick(record.AnnouncementId),
                      //     };
                      //   }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col-12 mb-5">
              <div class=" border-0 mt-5 mx-2 my-2">
                <div class="row">
                  <div class="col-12">
                    {/* <Loader2 loading={isFetching} /> */}
                    <Table
                      columns={archivedNoteColumns}
                      dataSource={archivedData || []}
                      //   onRow={(record, rowIndex) => {
                      //     return {
                      //       onClick: event => this.onRowClick(record.AnnouncementId),
                      //     };
                      //   }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToPropsNoteType = state => {
  const {
    add_note_type,
    delete_note_type,
    get_note_type_list,
    get_note_type_archive,
    note_type_upsert,
  } = state;
  const { data, isFetching } = get_note_type_archive;
  const { data: addNodeTypeData, isFetching: isNoteUpsert, error } = note_type_upsert;
  const { data: noteTypeData } = get_note_type_list;
  let isAddNodeType = false;
  let isDeleteNodeType = false;
  let noteType = [];
  let archivedData = [];
  let operation = null;
  if (noteTypeData && noteTypeData.data) {
    noteType = noteTypeData.data;
  }
  if (addNodeTypeData && addNodeTypeData.success == true) {
    isAddNodeType = addNodeTypeData.success;
    operation = addNodeTypeData.operation;
  }
  if (delete_note_type.data && delete_note_type.data.success) {
    isDeleteNodeType = delete_note_type.data.success;
  }
  if (get_note_type_archive.data && get_note_type_archive.data.data) {
    archivedData = data.data;
  }
  return {
    isAddNodeType,
    operation,
    error,
    isDeleteNodeType,
    noteType,
    archivedData: archivedData || [],
    isNoteUpsert,
  };
};
const ReduxNoteType = connect(mapStateToPropsNoteType)(withRouter(NoteType));

export default connect()(ReduxNoteType);
