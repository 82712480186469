import React, { Component, useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { connect } from 'react-redux';
import { BGContent, HTMLContent } from '../commonSection';
import { startFreeLicense, userFeatureFlagGet } from '../../../../store/actions';
import AugmentAIDrawerContent from './augmentAIDrawerContent';

// const labels = [
//   'Active/Inactive Clients',
//   'Referal Sources',
//   'Time In Treatment',
//   'Demographic Profile',
//   'Mapping',
//   'Score Improvement',
//   'SP Behavioral Ratings',
//   'Standard Cost of Care',
//   'Service Units Delivered',
//   'Trigger Location Activity',
// ];

const AugmentedAI = props => {
  const [open, setOpen] = useState(false);
  const { StatusData, dispatch, isLicenseStart } = props;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isLicenseStart) {
      dispatch(userFeatureFlagGet({}));
      // dispatch(resetStartFreeLicense({}));

    }
  }, [isLicenseStart]);

  const handleStartTrial = () => {
    setOpen(false);
    let moduleData = {
      License: [{ ModuleId: StatusData.ModuleId, IsFreeLicense: true }],
    };

    dispatch(startFreeLicense({ JsonInput: JSON.stringify(moduleData) }));
  };

  const handleDrawer = () => {};

  return (
    <>
      <div
        className="row bg-white p-4 m-1"
        style={{
          borderRadius: '12px',
          backgroundImage: `url("images/tri.svg")`,
          backgroundPosition: 'upset',
        }}
      >
        <div className="col-12">
          <div className="row">
            {' '}
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center ">
                  <img src={`images/license/ai3StarPink.svg`} width="40" height="40" alt="" />
                </div>
                <div className="col-12 text-center h5">AI Augmentation</div>
                <div className="col-12 text-center">
                  <div className="w-75" style={{ margin: '0 auto' }}>
                    AI Augmentation feature license is a great tool for understanding patients and
                    suggesting treatment methods. It provides a summary of the patient's condition
                    and helps to understand the best care options.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="">
                {/* <button className="btn" onClick={handleStartTrial} style={{ cursor: 'pointer' }}>
                  Start your 14-day trial
                </button> */}
                <button
                  className="ai-btn mt-3"
                  onClick={showDrawer}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  Start Free Trial Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ai-licence-drawer">
          <AugmentAIDrawerContent
            open={open}
            HTMLContent={HTMLContent}
            handleStartTrial={handleStartTrial}
            onClose={onClose}
          />
        </div>
      </div>

      {/* <BGContent
        moduleName={'Prospect'}
        backGroundImage={`images/license/preIntakeBG.svg`}
        moduleLogoImage={`images/license/preIntake.svg`}
        labelTitle={'What you will get :'}
        // labels={labels}
        tagLine={
          "Prospect feature license allows counselors and centers to create leads for centers and filter patients who are fit for the center's treatment. This is a great tool for those in the counseling or mental health field as it can help to ensure that patients are getting the care that they need."
        }
        trialButtonText={'Start your 14-day trial'}
        handleStartTrial={handleStartTrial}
        buyLinkText={'Purchase Prospect License'}
      /> */}

      {/* {props.canRoleByLicense ? (
        <>
          <button
            title="Augment with AI"
            class="ai-btn"
            onClick={() => {
              props.history.push('/manage/maintain-license');
            }}
            style={{
              display: 'inline',
              padding: '5px 20px',
            }}
          >
            <figure class="action-button-icon" style={{ display: 'inline-block' }}>
              <img src="images/AIStar.svg" alt="" width="23" />
            </figure>
            Purchase AI License
          </button>
        </>
      ) : (
        <p>Please contact your admin to purchase license.</p>
      )} */}
    </>
  );
};

const mapStateToProps = state => {
  const { user_feature_flag_get, start_free_license, default_values } = state;
  const { data, isFetching, error } = start_free_license;

  let isLicenseStart = false;
  var canRoleByLicense = false;

  if (data && data.success) {
    isLicenseStart = data.success;
  }
  if (default_values && default_values.data) {
    canRoleByLicense = default_values.data && default_values.data.IsPurchaseEligible;
  }
  return {
    isLicenseStart,
    canRoleByLicense,
  };
};

export default connect(mapStateToProps)(AugmentedAI);
