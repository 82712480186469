import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Collapse } from 'antd';
import {
  payerPortalRequestGet,
  payerPortalRequestUpsert,
  resetPayerPortalRequestUpsert,
} from '../../../store/actions';
import ConfirmationPopup from '../../../Components/ConfirmationPopup';
import { RightCircleOutlined, RightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const ContractTerms = ({ clientId, smartContractId, onClose, getLatestData }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (smartContractId) {
      getRequestData();
    }
  }, []);
  const { TermsRequest, isReqSuccess } = useSelector(
    ({
      payer_portal_request_get: { data: requestGetData, isFetching, error },
      payer_portal_request_upsert: { data: requestUpsert },
    }) => {
      return {
        TermsRequest: requestGetData && requestGetData.TermsRequest,
        isReqSuccess: requestUpsert && requestUpsert.success,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    if (smartContractId && isReqSuccess) {
      dispatch(resetPayerPortalRequestUpsert());
      getRequestData();
      getLatestData();
    }
  }, [isReqSuccess]);
  const getRequestData = () => {
    dispatch(
      payerPortalRequestGet({
        Json: JSON.stringify({
          Request: [
            {
              SmartContractId: smartContractId,
              Id: null,
              IsContractRequest: false,
              IsTermsRequest: true,
              IsNotes: false,
            },
          ],
        }),
      }),
    );
  };
  const onDeny = record => {
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30311}]} Approve
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30312}]} decline
    dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: record.PayerPortalRequestId,
              StatusId: 30312,
            },
          ],
        }),
      }),
    );
  };
  return (
    <div className="contract-terms contract-details">
      {TermsRequest &&
        TermsRequest.map((item, index) => {
          const { RequestType, StatusId, OverAllDetails, Reason, ChangeNotes } = item;
          const { Category, Terms } = OverAllDetails[0] || {};
          return (
            <div className="sections">
              <div className="section-item">
                <div className="p-1">
                  <Collapse
                    expandIcon={({ isActive }) => (
                      <div className="d-flex align-items-center">
                        <p
                          className="mb-0 mr-3 mt-1"
                          style={{ fontSize: '12px', color: '#46B4FE' }}
                        >
                          {isActive ? 'COLLAPSE' : 'EXPAND'}
                        </p>
                        <RightOutlined
                          rotate={isActive ? -90 : 90}
                          style={{
                            fontSize: '12px',
                            marginTop: '4px',
                            color: '#46B4FE',
                          }}
                        />
                        {/* <RightOutlined /> */}
                      </div>
                    )}
                    style={{ border: 'none' }}
                    accordion
                    className="payer-contract-terms"
                  >
                    <Panel
                      style={{ backgroundColor: 'white', border: 'none' }}
                      header={
                        <div className="d-flex flex-column">
                          <span style={{ color: '#959595' }}>Term Category {index + 1}</span>
                          <span
                            style={{
                              color: 'black',
                              fontSize: '15px',
                              // fontWeight: '700',
                            }}
                          >
                            {Category}
                          </span>
                        </div>
                      }
                      key={`${Category}-${index}`}
                    >
                      <h6>Terms Details</h6>
                      <div style={{ backgroundColor: '#F2F2F2', padding: '12px' }}>
                        {Terms ? (
                          Terms.map((tItem, tIndex) => {
                            // return <p>{tItem.Description}</p>;
                            return (
                              <div
                                style={{
                                  backgroundColor: 'white',
                                  padding: '10px 5px',
                                  margin: '10px 5px',
                                }}
                              >
                                <span>
                                  {tIndex + 1}. {tItem.Description}
                                </span>
                              </div>
                            );
                          })
                        ) : (
                          <span>No terms details to view</span>
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                  <div className="request-detail">
                    <span className="label">Changes Request</span>
                    <span className="details">{item.Note}</span>
                  </div>

                  {ChangeNotes && (
                    <div className="request-detail mt-3">
                      <span className="label">Notes</span>
                      <span className="details">{ChangeNotes}</span>
                    </div>
                  )}

                  {Reason && (
                    <div className="request-detail mt-3">
                      <span className="label">Reason for Denial</span>
                      <span className="details">{Reason}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="sections-action">
                {StatusId ? (
                  <div>
                    {StatusId == 30312 && <span className="denied">Request Denied</span>}
                    {StatusId == 30311 && <span className="approved">Request Approved</span>}
                  </div>
                ) : (
                  <span className="denied">Pending</span>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ContractTerms;
