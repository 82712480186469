import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import { downloadClientDocument } from '../../store/actions';
import CustomModal from '../../Components/CustomModal';
import DocView from '../docView';

const DownloadDocument = props => {
  const dispatch = useDispatch();
  const [isDownloadClicked, setDownloadClicked] = useState(false);

  const {
    ID,
    documentGuid,
    downloadText,
    isClinical,
    isReadOnlyUser,
    extension,
    allowDownload,
    isHelperIcon,
    downloadIcon,
  } = props;
  const fileExtension = downloadText && downloadText.substr(downloadText.lastIndexOf('.') + 1);
  const { downloadUrl, isFetching, error } = useSelector(
    ({ download_client_document: { data, isFetching, error } }) => {
      return {
        downloadUrl: data && data.result,
        error: error,
        isFetching: isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    if (props.onViewClick) {
      props.onViewClick();
    }
    if (isDownloadClicked && downloadUrl) {
      window.open(downloadUrl);
    }
  }, [downloadUrl]);
  const downloadDocument = () => {
    dispatch(
      downloadClientDocument({
        json: JSON.stringify({
          ClientDocument: [
            {
              ClientId: ID,
              DocumentGuid: documentGuid,
            },
          ],
        }),
      }),
    );
  };

  const STYLES = {
    textStyles: {
      color: '#FA8602',
      fontSize: '18px',
      cursor: 'pointer',
      color: 'rgb(70, 180, 254)',
    },
    iconStyles: {
      color: '#FA8602',
      fontSize: '12px',
      cursor: 'pointer',
      color: 'rgb(70, 180, 254)',
    },
  };
  return (
    <div className="d-flex justify-content-center align-items-center">
      <CustomModal
        modalWidth={800}
        maskClosable={false}
        ModalButton={({ onClick }) => (
          <span
            style={downloadIcon ? STYLES.iconStyles : STYLES.textStyles}
            className="mr-3"
            data-toggle="modal"
            data-target="#document-view-model"
            onClick={() => {
              downloadDocument();
              setDownloadClicked(false);
              onClick();
            }}
          >
            {isHelperIcon && <img src="images/icons/attachment.svg" />}
            {downloadText && <span className="doc-name">{downloadText}</span>}
            {downloadIcon && (
              <Tooltip placement="top" title="View">
                <img src={downloadIcon} alt="View" />
              </Tooltip>
            )}
          </span>
        )}
        ModalContent={props => (
          <DocView
            {...props}
            dispatch={dispatch}
            ClientId={ID}
            ClientDocumentGuid={documentGuid}
            DocumentExtension={extension || fileExtension}
            isClinical={isClinical}
            reset={() => {
              setDownloadClicked(true);
            }}
            isReadOnlyUser={isReadOnlyUser}
          />
        )}
      />
      {allowDownload && (
        <span
          onClick={() => {
            setDownloadClicked(true);
            downloadDocument();
          }}
          className="mr-3"
          style={{ color: '#46B4FE', fontSize: '14px', cursor: 'pointer' }}
          download
        >
          <Tooltip placement="top" title="Download">
            <img src="images/icons/download-document.svg" />
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default DownloadDocument;
