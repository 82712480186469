import React, { useState, useEffect } from 'react';
const ReduxPlusMinusInput = ({
  input,
  type,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  maxLength,
  mobileFormat,
  tabIndex,
  style,
  fieldName,
  min,
  max,
  controlledMaxAge,
  negativeNumberBlocked,
  showPlaceHolder,
  disableOnPaste,
}) => {
  const [inputValue, setInputValue] = React.useState(0);

  useEffect(() => {
    if (input && input.value != null) {
      setInputValue(input.value || 0);
      // if (input.value >= min && input.value <= max) {
      //how to make it more proper
      //   setInputValue(input.value);
      // }
    }
  }, [input.value]);

  const handleInputChange = value => {
    if (value == 'Plus') {
      setInputValue(inputValue ? parseInt(inputValue) + 1 : 1);
      onChange(inputValue ? parseInt(inputValue) + 1 : 1);
    } else {
      setInputValue(parseInt(inputValue) == 0 ? 0 : parseInt(inputValue) - 1);
      onChange(parseInt(inputValue) == 0 ? 0 : parseInt(inputValue) - 1);
    }
  };

  const handleOnChange = event => {
    setInputValue(
      event.target.value != NaN
        ? parseInt(event.target.value) >= 0
          ? parseInt(event.target.value)
          : null
        : null,
    );
  };

  var { touched, error, warning, active } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value && input.value.toString();
    //above line is not accepting "0" as value because of && condition and field label is overlpapping because of that
    // value = input.value.toString();
    // need to check causing issues in fieldArray form.
    // if (input.value || input.value === 0) {
    //   value = input.value.toString();
    // }
    onChange = input.onChange;
    onBlur = input.onBlur;
    name = input.name;
  }

  /*if(mobileFormat){
        value = Util.formatPhoneNumber(value);
    }*/

  return (
    <>
      <>
        {!controlledMaxAge ? (
          !negativeNumberBlocked ? (
            <div class="quantity-btn flex-grow-1 text-right">
              <div class="numbers-row">
                <input
                  {...input}
                  style={style}
                  tabIndex={tabIndex}
                  type={'number'}
                  name={name}
                  id={id}
                  defaultValue={defaultValue}
                  value={inputValue}
                  onChange={inputValue => {
                    handleOnChange(inputValue);
                    onChange(inputValue);
                  }}
                  onBlur={onBlur}
                  // placeholder={active && placeholder}
                  placeholder={showPlaceHolder ? placeholder : active && placeholder}
                  className={`${className} numbers-only ${value && 'not-empty'} ${type ==
                    'textarea' && 'textarea-height'}`}
                  disabled={!!disabled}
                  maxLength={maxLength}
                  min={min}
                  max={max}
                  autocomplete="off"
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()
                  }
                  onWheel={event => {
                    window.document.activeElement.blur();
                  }}
                  onPaste={e => {
                    if (disableOnPaste) {
                      e.preventDefault();
                    }
                  }}
                />{' '}
                {disabled ? null : (
                  <>
                    {' '}
                    <div
                      style={{
                        borderRadius: '0px',
                        height: '32px',
                        width: '32px',
                      }}
                      class="add quantity-button"
                      onClick={() => {
                        handleInputChange('Plus');
                      }}
                    >
                      +
                    </div>
                    <div
                      style={{
                        borderRadius: '0px',
                        height: '32px',
                        width: '32px',
                      }}
                      class="remove quantity-button"
                      onClick={() => {
                        handleInputChange('Minus');
                      }}
                    >
                      -
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div class="quantity-btn flex-grow-1 text-right">
              <div class="numbers-row">
                <input
                  {...input}
                  style={style}
                  tabIndex={tabIndex}
                  type={'number'}
                  name={name}
                  id={id}
                  defaultValue={defaultValue}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={active && placeholder}
                  className={`${className} numbers-only ${value && 'not-empty'}`}
                  disabled={!!disabled}
                  maxLength={maxLength}
                  autocomplete="off"
                  min={min}
                  max={max}
                  onWheel={event => {
                    window.document.activeElement.blur();
                  }}
                  // onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                />{' '}
                <div
                  style={{
                    borderRadius: '0px',
                    height: '32px',
                    width: '32px',
                  }}
                  class="add quantity-button"
                  onClick={() => {
                    handleInputChange('Plus');
                  }}
                >
                  +
                </div>
                <div
                  style={{
                    borderRadius: '0px',
                    height: '32px',
                    width: '32px',
                  }}
                  class="remove quantity-button"
                  onClick={() => {
                    handleInputChange('Minus');
                  }}
                >
                  -
                </div>
              </div>
            </div>
          )
        ) : (
          <div class="quantity-btn flex-grow-1 text-right">
            <div class="numbers-row">
              <input
                {...input}
                style={style}
                tabIndex={tabIndex}
                type={'number'}
                name={name}
                id={id}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={active && placeholder}
                className={`${className} numbers-only ${value && 'not-empty'}`}
                disabled={!!disabled}
                maxLength={maxLength}
                autocomplete="off"
                min={min}
                max={max}
                onWheel={event => {
                  window.document.activeElement.blur();
                }}
                //   onKeyPress={function(evt) {
                //     evt.preventDefault();
                //   }
                // }
                // onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              />{' '}
              <div
                style={{
                  borderRadius: '0px',
                  height: '32px',
                  width: '32px',
                }}
                class="add quantity-button"
                onClick={() => {
                  handleInputChange('Plus');
                }}
              >
                +
              </div>
              <div
                style={{
                  borderRadius: '0px',
                  height: '32px',
                  width: '32px',
                }}
                class="remove quantity-button"
                onClick={() => {
                  handleInputChange('Minus');
                }}
              >
                -
              </div>
            </div>
          </div>
        )}
        {touched &&
          ((error && fieldName && (
            <span className="error-text">
              {fieldName} {error}
            </span>
          )) ||
            (warning && <span className="warning-color">{warning}</span>))}
      </>
    </>
  );
};

export default ReduxPlusMinusInput;
