import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Button } from 'antd';
import { getBasicProfile } from '../../store/actions';
import moment from 'moment';
import _blank from '../../images/blank-profile.png';
import { ToolTip } from '../../util/toolTip';
class ProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getUserGuid() {
    if (
      this.props.location.pathname.split('/')[1] == 'subscriberProfile' ||
      this.props.location.pathname.split('/')[1] == 'subscribers-survery'
    ) {
      return this.props.location.pathname.split('/')[2];
    } else {
      let indexOfEqual = this.props.location.search.indexOf('=');
      let indexOfAnd = this.props.location.search.indexOf('&');
      if (indexOfAnd > 0) {
        return this.props.location.search.slice(indexOfEqual + 1, indexOfAnd);
      } else {
        return this.props.location.search.split('=')[1];
      }
    }
  }
  componentDidMount() {
    let checkTimelineUrl =
      this.props.location.pathname.split('/')[1] == 'subscriberProfile' ||
      this.props.location.pathname.split('/')[1] == 'subscribers-survery';
    this.setState({ checkTimelineUrl });
    let data = { Profile: [{ UserGuid: this.getUserGuid() }] };

    this.props.dispatch(
      getBasicProfile({
        Json: JSON.stringify(data),
      }),
    );
  }

  render() {
    const { ProfileData } = this.props;
    const {
      Age,
      AppJoiningDate,
      AppLastOpened,
      CurrentSubscription,
      LatestCoordinate,
      LocationLastCaptured,
      Name,
      NotilLastDelivered,
      Phone,
      ProfileImageUrl,
      UserGuid,
      StaffName,
      IsStudent,
      StudentId,
      IsClient,
      ClientId,
      Address,
      LocationAuthorizationStatus,
      NotificationAuthorizationStatus,
      TimeZone,
      Language,
      BadgeId,
      EnterpriseLink,
    } = ProfileData;
    const enrollContent = (
      <div className="sort-by-list">
        {IsClient && (
          <p
            className="px-3"
            style={{
              cursor: 'pointer',
              margin: '0 10px',
              height: '40px',
              padding: '10px',
            }}
            onClick={() => {
              this.props.history.push(
                `/cw/patient-management/patient-profile?tab=demographic&pId=${ClientId}`,
              );
            }}
          >
            Switch to view Clinical Profile
          </p>
        )}
        {IsStudent && EnterpriseLink && (
          <p
            className="px-3"
            style={{
              cursor: 'pointer',
              margin: '0 10px',
              height: '40px',
              padding: '10px',
            }}
            onClick={() => {
              this.props.history.push(EnterpriseLink);
            }}
          >
            Switch to view Enterprise Profile
          </p>
        )}
      </div>
    );
    return (
      <div class="border-0">
        <div className="row">
          {/* NAME DETAIL */}
          <div className="col-12 mb-2 pt-4">
            <div className="card border-0 students-profile">
              <div className="row card-body align-items-center">
                <div className="col-md-1">
                  <div className="EditImage" style={{ margin: '0 auto' }}>
                    <div
                      className="images-placeholder2"
                      style={{
                        backgroundImage: 'url(&quot;images/surveys-img1.jpg&quot;)',
                      }}
                    >
                      <img
                        src={ProfileImageUrl || _blank}
                        className="students-profile-img images-placeholder2"
                        alt=""
                        width="84"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10 px-0">
                  <h4 className="students-name text-xs-center text-md-left text-capitalize">
                    {Name}
                  </h4>
                  <div
                    className="students-action-button text-xs-center d-flex align-items-center"
                    style={{ maxWidth: 'unset' }}
                  >
                    <button
                      onClick={() => {
                        // if (EnrollStatus && EnrollStatus == 'Discharged') {
                        //   this.props.history.push(
                        //     `/subscriberViewProfile/?studentid=${this.getUserGuid()}`,
                        //   );
                        // }
                        if (BadgeId == 'F') {
                          this.props.history.push(`/concernedPerson/${this.getUserGuid()}`);
                        } else {
                          this.props.history.push(
                            `/subscriberViewProfile/?subscriberId=${this.getUserGuid()}`,
                          );
                        }
                      }}
                      title="View Profile"
                      className="btn black-btn"
                    >
                      <span>Engagement</span>
                      {/* <span>Profile</span> */}
                    </button>
                    {this.state.checkTimelineUrl && (
                      <button
                        onClick={() => {
                          this.props.history.push(
                            `/timetravel/?subscriberId=${this.getUserGuid()}`,
                          );
                        }}
                        title="Time Travel"
                        className="btn black-btn"
                      >
                        <span style={{ whiteSpace: 'nowrap' }}>Time Travel</span>
                      </button>
                    )}
                    <button
                      onClick={() => {
                        this.props.history.push(
                          `/subscriber-demographics?subscriberId=${this.getUserGuid()}`,
                        );
                      }}
                      title="Demographics"
                      className="btn black-btn"
                    >
                      <span>Demographics</span>
                    </button>
                    {/* {IsClient && (
                      <button
                        onClick={() => {
                          this.props.history.push(
                            `/cw/patient-management/patient-profile?tab=demographic&pId=${ClientId}`,
                          );
                        }}
                        title="Clinical"
                        className="btn black-btn"
                      >
                        <span>Clinical</span>
                      </button>
                    )}
                    {IsStudent && EnterpriseLink && (
                      <button
                        onClick={() => {
                          this.props.history.push(EnterpriseLink);
                        }}
                        title="Enterprise"
                        className="btn black-btn"
                      >
                        <span>Enterprise</span>
                      </button>
                    )} */}
                    {(IsClient || IsStudent || EnterpriseLink) && (
                      <Popover
                        overlayClassName="alert-box-style"
                        content={enrollContent}
                        title={false}
                        trigger="hover"
                        placement="bottom"
                        class="alert-sort"
                      >
                        <button
                          className="btn mr-2"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          Actions <img src="images/icons/3dot_white.svg" className="mx-2" />
                        </button>
                      </Popover>
                    )}
                    <div
                      className="d-flex align-items-center"
                      style={{
                        width: '452px',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <span className="d-flex">
                        {' '}
                        {Address && (
                          <img src="./images/profile_location.svg" alt=" Image Not Available" />
                        )}{' '}
                        &nbsp;
                        {Address}
                      </span>

                      <span className="d-flex">
                        {' '}
                        {LocationAuthorizationStatus && (
                          <img
                            src="./images/profile_googleLocastion.svg"
                            alt=" Image Not Available"
                          />
                        )}{' '}
                        &nbsp;
                        {LocationAuthorizationStatus}
                      </span>

                      <span className="d-flex">
                        {' '}
                        {NotificationAuthorizationStatus && (
                          <img src="./images/profile_notification.svg" alt=" Image Not Available" />
                        )}{' '}
                        &nbsp;
                        {NotificationAuthorizationStatus}
                      </span>

                      <span className="d-flex">
                        {' '}
                        {TimeZone && (
                          <img src="./images/profile_earth.svg" alt=" Image Not Available" />
                        )}{' '}
                        &nbsp;
                        {TimeZone}
                      </span>

                      <span className="d-flex">
                        {' '}
                        {Language && (
                          <img src="./images/profile_lang.svg" alt=" Image Not Available" />
                        )}{' '}
                        &nbsp;
                        {Language}
                      </span>
                    </div>
                  </div>
                </div>
                {/* student Personal */}
                <div className="col-12 col-xl-12 mt-4">
                  <div className="row students-detail   text-md-left  justify-content-md-between px-3">
                    {/* <div className="col-1 col-sm-1 mb-2 md-sm-0 ">
                      <span className="info-label">Age</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {Age} {Age && 'Years'}
                        {!Age && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div> */}
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 px-0">
                      <span className="info-label">Last Location Received on</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {LocationLastCaptured && (
                          <a
                            href={`http://www.google.com/maps/place/${LatestCoordinate[0].Latitude},${LatestCoordinate[0].Longitude}`}
                            target="_blank"
                          >
                            {LocationLastCaptured}
                          </a>
                        )}
                        {!LocationLastCaptured && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">App Joining Date</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {AppJoiningDate && moment(AppJoiningDate).format('ll')}
                      </h5>
                    </div>

                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">App Last Opened Date</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {AppLastOpened}
                        {!AppLastOpened && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">Current Subscription</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {CurrentSubscription}
                      </h5>
                    </div>

                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">Notification Last Delivered or Read </span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {NotilLastDelivered}{' '}
                        {!NotilLastDelivered && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">Advisor</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {/* {ToolTip(StaffName)} */}
                        {StaffName}
                        {!StaffName && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
                {/* student Personal */}
              </div>
            </div>
          </div>
          {/* NAME DETAIL */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ get_basic_profile }) => {
  const { data, error, isFetching } = get_basic_profile;
  let ProfileData = {};

  if (data && data.Profile) {
    ProfileData = data.Profile[0];
  }
  return { ProfileData };
};

export default connect(mapStateToProps)(withRouter(ProfileHeader));
