import React, { Component } from 'react';
import Loader, { Loader2 } from '../../../Components/Loader';

const Friends = props => {
  const { Friend } = props && props.appSubscriberData;
  return (
    <div className="container-fluid p-3">
      {' '}
      <div className="row" style={{ background: '#fff', borderRadius: '12px', paddingTop: '10px' }}>
        {Friend &&
          Friend.map((item, index) => {
            const { FriendProfileImageUrl, FriendName, FriendBadge, FriendUserGuid } = item;
            return (
              <div class="col-md-4 col-sm-6 mb-1 ">
                <div class="friend-list">
                  {FriendProfileImageUrl ? (
                    <div
                      class="rounded-circle friends-avatar"
                      style={{ backgroundImage: `url(${FriendProfileImageUrl})` }}
                      onClick={() => props.history.push(`/subscriberProfile/${FriendUserGuid}`)}
                    ></div>
                  ) : (
                    <div
                      class="rounded-circle friends-avatar"
                      style={{
                        backgroundImage: `url(images/familyProgram/blank-profile.png)`,
                      }}
                      onClick={() => props.history.push(`/subscriberProfile/${FriendUserGuid}`)}
                    ></div>
                  )}

                  <div className="f-friend-name-box">
                    <div
                      class="f-friend-name"
                      style={{
                        cursor:"pointer"
                      }}
                      onClick={() => props.history.push(`/subscriberProfile/${FriendUserGuid}`)}
                    >
                      {FriendName}
                    </div>
                    <div
                      className="f-friend-badge guide"
                      style={{
                        visibility: `${FriendBadge ? 'visible' : 'hidden'}`,
                      }}
                    >
                      {FriendBadge}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Friends;
