import React, { Component } from 'react';
import { Collapse } from 'antd';
import { RightCircleOutlined, RightOutlined } from '@ant-design/icons';
import TargetPriceBundleService from './targetPriceBundleService';
import { Loader2 } from '../../../../Components/Loader';

const { Panel } = Collapse;

export default class TargetPriceBundle extends Component {
  render() {
    const { BundleData, activeKey } = this.props || [];
    const { ServiceTypes } = BundleData || [];
     return (
       <div className="payer-milestone mt-4 row" style={{ width: '100%' }}>
         {/* <p
          className="time"
          style={{
            backgroundColor: '#E3F4FF',
            fontWeight: '600',
            height: 'fit-content',
            padding: '5px 25px',
            margin: '0 25px',
            width: '125px',
            zIndex: '2',
          }}
        >
          {measureDetails && measureDetails.MeasureCreatedOn}
        </p> */}
         <Loader2 loading={this.props.isTargettFetching} />
         <Collapse
           expandIcon={({ isActive }) => (
             <div className="d-flex align-items-center m-0">
               {/* <p className="mb-0 mr-3 mt-1" style={{ fontSize: '16px', color: '#46B4FE' }}>
                {isActive ? 'COLLAPSE' : 'EXPAND'}
              </p> */}
               <RightCircleOutlined
                 rotate={isActive ? -90 : 90}
                 style={{
                   fontSize: '20px',
                   marginTop: '4px',
                   color: '#7F8188',
                 }}
               />
               {/* <RightOutlined /> */}
             </div>
           )}
           key={activeKey}
           defaultActiveKey={activeKey}
           // className="col-10"
           style={{ flexGrow: '1', width: '85%' }}
           //   onChange={onChange}
         >
           <Panel
             header={
               <div className="d-flex flex-column">
                 <span
                   style={{
                     color: 'black',
                     fontSize: '16px',
                     fontWeight: '600',
                   }}
                 >{`Bundle ${activeKey}`}</span>
                 <span
                   style={{
                     color: 'black',
                     fontSize: '20px',
                     fontWeight: '700',
                   }}
                 >
                   {BundleData.BundleName}
                 </span>
               </div>
             }
             key={activeKey}
           >
             <div className="row">
               <div className="col-12 p-0">
                 <div className="border-0 students-profile">
                   {ServiceTypes &&
                     ServiceTypes.map((item, index) => (
                       <div>
                         <p className="mb-0" style={{ fontWeight: '700', fontSize: '16px' }}>
                           {item.ServiceType}
                         </p>
                         {item.ServiceCodes &&
                           item.ServiceCodes.map((item, index) => (
                             <TargetPriceBundleService
                               key={index}
                               activeKey={index + 1}
                               serviceCodes={item}
                             />
                           ))}
                       </div>
                     ))}
                 </div>
               </div>
             </div>
           </Panel>
         </Collapse>
       </div>
     );
  }
}
