import moment from 'moment';

export const FormatDate = date => {
  return moment(date).format('DD-MMM-YYYY');
};

export const ChatFormatDate = date => {
  return moment(date).format('DD MMMM, YYYY');
};

// export { FormatDate };
