import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate, {
  dateofbirthNormalize,
  MobileFormatter,
  MobileNormalize,
  ssnFormatter,
  ssnNormalize,
} from '../../validation/validate';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
  ReduxToggle,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { listCombos } from '../../../../store/actions';

const CPTCode = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  StateProvince,
  Country,
  change,
  disable,
  existingRows,
  dispatch,
}) => {
  // const countryChange = (countryISO, currentIndex) => {
  //   if (countryISO) {
  //     dispatch(
  //       listCombos({
  //         comboTypes: 'StateProvince',
  //         CountryISO: countryISO,
  //       }),
  //     );
  //   }
  // };
  return (
    <>
      {fields.map((cpt, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>CPT {index + 1}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete CPT
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${cpt}.cpt`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`CPT code ${index + 1}`}
                    // options={RelationShip}
                    fieldName={'CPT code'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${cpt}.description`}
                    type="text"
                    // validate={validate.required}
                    component={ReduxInput}
                    fieldName={'Description'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Description</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${cpt}.placeofService`}
                    type="text"
                    // validate={validate.required}
                    component={ReduxInput}
                    fieldName={'Place of Service'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Place of Service</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${cpt}.ModifierA`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Modifier A</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${cpt}.ModifierB`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Modifier B</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${cpt}.ModifierC`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Modifier C</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${cpt}.ModifierD`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Modifier D</label>
                </div>
              </div>
              {/* <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${cpt}.notes`}
                    type="textarea"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div> */}
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-1 pb-1">
          {/* <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}> */}
          <a class="add-contact" onClick={() => fields.push({})} disabled={disable}>
            {fields.length >= 1 && <label> + Add Another CPT</label>}
            {fields.length == 0 && <label> + Add CPT</label>}
          </a>
        </div>
      </div>
    </>
  );
};
const ReduxCPTCode = connect(state => {
  // const isEmergencyContactValue = selector(state, 'isEmergencyContact');
  return {
    // isEmergencyContactValue,
  };
})(CPTCode);

const Diagnosis = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  StateProvince,
  Country,
  change,
  disable,
  existingRows,
  dispatch,
}) => {
  // const countryChange = (countryISO, currentIndex) => {
  //   if (countryISO) {
  //     dispatch(
  //       listCombos({
  //         comboTypes: 'StateProvince',
  //         CountryISO: countryISO,
  //       }),
  //     );
  //   }
  // };
  return (
    <>
      {fields.map((diagnosis, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Diagnosis {index + 1}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete Diagnosis
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.ICD10Code`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`ICD 10 Code ${index + 1}`}
                    // options={RelationShip}
                    fieldName={'ICD 10 Code'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <Field
                    name={`${diagnosis}.Description`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Description {index + 1}</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-1 pb-1">
          {/* <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}> */}
          <a class="add-contact" onClick={() => fields.push({})} disabled={disable}>
            {fields.length >= 1 && <label> + Add Another Diagnosis</label>}
            {fields.length == 0 && <label> + Add Diagnosis</label>}
          </a>
        </div>
      </div>
    </>
  );
};
const ReduxDiagnosis = connect(state => {
  // const isEmergencyContactValue = selector(state, 'isEmergencyContact');
  return {
    // isEmergencyContactValue,
  };
})(Diagnosis);

const HealthInsuranceUI = props => {
  const {
    handleSubmit,
    name,
    pristine,
    submitting,
    onLastSubmit,
    handleNext,
    HealthInsuranceType,
    StateProvince,
    CountryISO,
    style,
    isHealthInsurance,
    goToPreviuos,
    disable,
  } = props;
  return (
    <form onSubmit={handleSubmit(handleNext)} style={style}>
      <div class="row mb-3">
        {/* <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Do you have Health Insurance?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isHealthInsurance" component={ReduxToggle} disabled={disable} />
          </div>
        </div> */}
        <div class="col-12">
          <FieldArray
            name="diagnosisCodes"
            component={ReduxCPTCode}
            HealthInsuranceType={HealthInsuranceType}
            StateProvince={StateProvince}
            Country={CountryISO}
            disable={disable}
            dispatch={props.dispatch}
          />
        </div>
        <div class="col-12">
          <h6>Office Details</h6>
        </div>
        <div class="col-12">
          <FieldArray
            name="CPTCodes"
            component={ReduxDiagnosis}
            HealthInsuranceType={HealthInsuranceType}
            StateProvince={StateProvince}
            Country={CountryISO}
            disable={disable}
            dispatch={props.dispatch}
          />
        </div>
      </div>

      <div class="row pagination-button">
        <div class="col-12 text-right">
          <button
            onClick={goToPreviuos}
            class="btn btn-outline-primary btn-eForm-Prev mr-2"
            disabled={disable}
          >
            Previous
          </button>
          <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
            Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  const isHealthInsurance = selector(state, 'isHealthInsurance');
  return {
    StateProvince: combos.data.StateProvince,
    HealthInsuranceType: combos.data.HealthInsuranceType,
    CountryISO: combos.data.Country,
    isHealthInsurance,
  };
})(
  reduxForm({
    form: 'providerEnrollmentForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(HealthInsuranceUI),
);

const selector = formValueSelector('providerEnrollmentForm');
