import React, { Component } from 'react';
import { Table } from 'antd';
import { stringDate } from '../../../util/date';
import { currencyFormatter } from '../../../util/formatter';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';

export default class RecentClaims extends Component {
  constructor(props) {
    super(props);
  }
  ClaimsColumns = [
    {
      title: 'Patient’s Name',
      dataIndex: 'ClientName',
      key: 'ClientName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
      render: (text, record) => {
        return <div className="">{text}</div>;
      },
    },
    {
      title: 'Center Name',
      dataIndex: 'CenterName',
      key: 'CenterName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
      render: (text, record) => {
        return <div className="">{text}</div>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'NetAmount',
      key: 'NetAmount',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isNumericComparer(a.NetAmount, b.NetAmount),
      render: (text, record) => {
        return <div className="">{text && currencyFormatter.format(text)}</div>;
      },
      align: 'right',
    },
    {
      title: 'Claim Date',
      dataIndex: 'LastUpdatedOn',
      key: 'LastUpdatedOn',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => isDateComparer(a.LastUpdatedOn, b.LastUpdatedOn),
      render: (text, record) => {
        return <div className="">{text}</div>;
      },
    },
  ];
  render() {
    const { recentClaimList } = this.props;
    return (
      <div className="col-12">
        <div className="card border-0 recent-claims mb-5">
          <div className="card-body">
            <h5 class="card-title">Recent Claims</h5>
            <div className="">
              <Table
                dataSource={recentClaimList}
                columns={this.ClaimsColumns}
                bordered
                pagination={false}
              />
            </div>
            {/* <p className="heading">RECENT CLAIMS</p> */}
            {/* <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
              {recentClaimList &&
                recentClaimList.map(item => {
                  return (
                    <div
                      className="d-flex align-items-center p-3 payer-item"
                      // style={{ borderBottom: '1px solid #D8D8D8' }}
                    >
                      <img src="images/blank-profile.png" className="payee-img" />
                      <div
                        className="d-flex justify-content-between ml-2"
                        style={{ width: '100%' }}
                      >
                        <div>
                          <p className="payee-name">{item.ClientName}</p>
                          <p className="payee-center">{item.CenterName}</p>
                        </div>
                        <div>
                          <p className="payee-cost">
                            {item.NetAmount && currencyFormatter.format(item.NetAmount)}
                          </p>
                          <p className="payee-date">{item.LastUpdatedOn && item.LastUpdatedOn}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
