import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import Dropzone from 'react-dropzone';
import ByteToSize from '../../../../../../util/ByteToSize';
import { Tooltip } from 'antd';
import { Line } from 'rc-progress';
import {
  upsertClientDocument,
  UploadDocumentUsingMultiPart,
  resetUploadDocumentUsingMultiPart,
  upsertClientDocumentReset,
  payerPortalRequestUpsert,
} from '../../../../../../store/actions';
import SubmitButton from '../../../../../../Components/submitButton';

const MAX_FILE_SIZE = 5242880;
const RequestedDocumentsUpload = props => {
  const dispatch = useDispatch();
  const [uploadedFiles, setFiles] = useState({});
  const [docRequested, setDocRequested] = useState([]);
  const [payerPortalRequestId, setPayerPortalRequestId] = useState([]);
  const [isRequestedSection, setRequestedSection] = useState(false);
  const { smartContractId, clientId, RequestedFiles } = props;
  const {
    isUploaded,
    uploadedDataGuid,
    uploadError,
    isUpsertDocument,
    documentError,
    upsertDocumentData,
    isFetching,
  } = useSelector(({ // payer_portal_request_get: { data: requestGetData, isFetching, error },
    upsert_client_document: { data: documentUpsert, error: errorUpload }, upload_document_using_multiPart: { data: uploadData, isFetching, error: documentError } }) => {
    return {
      isUploaded: uploadData && uploadData.success,
      uploadedDataGuid: uploadData && uploadData.data,
      isFetching: isFetching,
      uploadError: errorUpload,
      isUpsertDocument: documentUpsert && documentUpsert.success,
      upsertDocumentData: documentUpsert && documentUpsert.data,
      documentError: documentError && documentError.Message,
    };
  }, shallowEqual);
  useEffect(() => {
    if (RequestedFiles) {
      setDocRequested(RequestedFiles);
      setFiles({});
    }
  }, [RequestedFiles]);

  useEffect(() => {
    if (isUploaded && isRequestedSection) {
      let data = JSON.parse(uploadedDataGuid)[0].Document.map(item => {
        return { ...item, ClientId: clientId };
      });
      dispatch(
        upsertClientDocument({
          json: JSON.stringify({
            ClientDocument: data,
          }),
        }),
      );
      dispatch(resetUploadDocumentUsingMultiPart());
    }
  }, [isUploaded]);

  useEffect(() => {
    if (isUpsertDocument && isRequestedSection) {
      let clientDocumentId = JSON.parse(upsertDocumentData)[0].ClientDocument[0].ClientDocumentId;
      dispatch(
        payerPortalRequestUpsert({
          Json: JSON.stringify({
            Request: [
              {
                PayerPortalRequestId: payerPortalRequestId,
                SmartContractId: smartContractId,
                Id: clientDocumentId,
              },
            ],
          }),
        }),
      );

      dispatch(upsertClientDocumentReset());
      setRequestedSection(false);
    }
  }, [isUpsertDocument]);

  const onDrop = ({ files, docItem, index }) => {
    let dataList = [];
    files.map((obj, index) => {
      let fileName = obj.name.substr(0, obj.name.lastIndexOf('.'));
      let fileExtention = obj.name.substr(obj.name.lastIndexOf('.') + 1);
      let fileInfo = {
        name: obj.name,
        size: obj.size,
        docName: fileName,
        fileExtention: fileExtention,
      };
      dataList.push(fileInfo);
      // let currentSize = (obj.size / MAX_FILE_SIZE) * 100;
      // setpercent(currentSize);
    });
    let newList = docRequested;
    newList[index].dataList = dataList;
    let filesList = {
      ...uploadedFiles,
      [index]: files,
    };
    setFiles(filesList);
    setDocRequested(newList);
  };
  const remove = index => {
    let files = { ...uploadedFiles };
    let requestDocList = [...docRequested];
    delete files[index];
    delete requestDocList[index].dataList;
    setFiles(files);
    setDocRequested(requestDocList);
  };
  const UploadDocument = (index, data, docItem) => {
    let dataArray = [
      {
        ClientId: clientId,
        DocumentName: data.docName,
        DocumentTypeId: '16007',
        UploadedFileFullName: data.name,
        FileSizeInByte: data.size,
        Extension: data.fileExtention,
        IsConfidential: false,
      },
    ];
    let jsonData = {
      json: JSON.stringify({
        Document: dataArray,
      }),
    };
    let param = jsonData;
    const formData = new FormData();
    for (var property in param) {
      formData.append(property, param[property]);
    }
    formData.append('file', uploadedFiles[index][0]);
    dispatch(UploadDocumentUsingMultiPart(formData));
    setPayerPortalRequestId(docItem.PayerPortalRequestId);
    setRequestedSection(true);
  };
  return (
    <div className="requested-documents-list p-3">
      <span className="title mb-3">Requested Documents</span>
      {docRequested &&
        docRequested.map((docItem, index) => {
          const { Note, DocumentName, RequestedBy, RequestedOn, dataList } = docItem;
          const files = uploadedFiles[index] || {};
          return (
            <div className="list-item">
              <div className="doc-info">
                <div className="info">
                  <span className="label-text">Document</span>
                  <span className="value">{Note || ''}</span>
                </div>
                <div className="info">
                  <span className="label-text">Requested By(Payer)</span>
                  <span className="value">{RequestedBy}</span>
                </div>
                <div className="info">
                  <span className="label-text">Requested on</span>
                  <span className="value">{RequestedOn}</span>
                </div>
              </div>
              <div className="doc-action">
                {files && files[0] ? (
                  <aside className="profile-thumbs-Container" key={'file.name'}>
                    <div className="profile-container">
                      <div className="d-flex align-items-center">
                        <div
                          className=""
                          style={{
                            width: '500px',
                            marginRight: '10px',
                          }}
                        >
                          <p className="d-flex justify-content-between">
                            <span classname="leader-img-name">{files[0].name}</span>
                            <span className="leader-img-size">
                              {ByteToSize(files[0].size || 0)} / {ByteToSize(MAX_FILE_SIZE || 0)}
                            </span>
                          </p>
                          <Line
                            percent={(files[0].size / MAX_FILE_SIZE) * 100}
                            strokeWidth="1"
                            strokeColor="#2FE192"
                            strokeLinecap="round"
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <SubmitButton
                            type="button"
                            style={{ height: '45px' }}
                            loading={isFetching}
                            value="Upload"
                            onClick={() => UploadDocument(index, dataList[0], docItem)}
                          />
                          <Tooltip placement="top" title="Remove">
                            <span class="delete-course " onClick={() => remove(index)}>
                              <figure
                                class="action-button-icon"
                                style={{ display: 'inline-block' }}
                              >
                                <img src="images/circle-cross.svg" alt="" width="35" />
                              </figure>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </aside>
                ) : (
                  <div className="clearfix w-100">
                    <Dropzone
                      onDrop={data => {
                        onDrop({ files: data, docItem, index });
                      }}
                      // noDrag={false}
                      accept={'.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf'}
                      maxSize={MAX_FILE_SIZE}
                      maxFiles={1}
                      multiple={false}
                      // disabled={isDeactivated}
                    >
                      {({ getRootProps, getInputProps, acceptedFiles, fileRejections }) => (
                        <section className="container upload-document">
                          <div
                            {...getRootProps({
                              className:
                                'dropzone d-flex justify-content-center align-items-center flex-column',
                            })}
                              style={{
                                // width: '375px',
                                cursor: 'pointer'
                              }}
                          >
                            <input {...getInputProps()} />
                            <div className="d-flex align-items-baseline justify-content-center">
                              <p className="dropzone-upload-drag-icon mr-2">
                                <img src="images/icons/upload-doc.svg" />
                              </p>
                              <p className="dropzone-upload-text mb-0">Upload</p>
                            </div>
                            <p className="mb-0">Drag and Drop Here or Browse Files</p>
                          </div>
                          {fileRejections && fileRejections.length > 0 && (
                            <div className="">
                              {fileRejections.map(({ file, errors }) => {
                                return (
                                  <div>
                                    {errors.map(e => (
                                      <span key={e.code} className="validation-error">
                                        {/* {e.message} */}
                                        File is larger than {ByteToSize(MAX_FILE_SIZE || 0)}
                                      </span>
                                    ))}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {/* <span className="validation-error">{this.state.groupLeaderErrorMsg}</span> */}
                        </section>
                      )}
                    </Dropzone>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default RequestedDocumentsUpload;
