import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Tabs, Button, Drawer, Tooltip } from 'antd';
import {
  appointmentNoteListGet,
  getAppointment,
  practitionerCombos,
  medicateCombos,
  getComboFacility,
  practitionerCombosOffice,
  comboServiceCodeGet,
  resetAppointmentNoteListGet,
} from '../../../../store/actions';
import { isComparer, isDateComparer, isNumericComparer } from '../../../../util/handler';
import PersistTableSort from '../../../../component/persistTableSort';
import Loader, { Loader2 } from '../../../../Components/Loader';
import AntdModal from '../../../../Components/CustomModal';
import AppointmentDetails from './AppointmentsScreens/AppointmentDetails';
import CloseIcon from '../../../../Components/CloseIcon';
import { ProcessCombos } from '../../../../store/comboConfig';

class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAppointmentDetailDrawer: false,
    };
  }
  getData = CurrentClientId => {
    this.props.dispatch(
      appointmentNoteListGet({
        Json: JSON.stringify({
          AppointmentNote: [
            {
              StudentId: this.props.ClientId,
              ClientId: CurrentClientId,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    const { CurrentClientId } = this.props.BannerData || {};
    this.getData(CurrentClientId);
    // if (CurrentClientId) {
    //   this.getData(CurrentClientId);
    // }
    var result = ProcessCombos('RCM_PlaceOfService,RCM_Program');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let medicateComboTypes = {
      ComboTypes: 'Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    this.props.dispatch(practitionerCombos({}));
    this.props.dispatch(practitionerCombosOffice({}));
    this.props.dispatch(getComboFacility({}));
    this.props.dispatch(
      comboServiceCodeGet({
        Json: JSON.stringify({
          Combo: [
            {
              IsPeerRecovery: true,
            },
          ],
        }),
      }),
    );
  }
  componentWillUnmount() {
    this.props.dispatch(resetAppointmentNoteListGet({}));
  }
  componentWillReceiveProps({ isNoteAdded, BannerData, AppointmentData }) {
    if (isNoteAdded && isNoteAdded != this.props.isNoteAdded) {
      const { CurrentClientId } = BannerData;
      this.getData(CurrentClientId);
    }
    if (BannerData && JSON.stringify(BannerData) != JSON.stringify(this.props.BannerData)) {
      const { CurrentClientId } = BannerData;
      this.getData(CurrentClientId);
    }
  }
  columns = [
    {
      title: 'Date Added',
      dataIndex: 'SessionDate',
      key: 'SessionDate',
      sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Note Types',
      dataIndex: 'NoteType',
      key: 'NoteType',
      sorter: (a, b) => isComparer(a.NoteType, b.NoteType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Added By',
      dataIndex: 'AddedBy',
      key: 'AddedBy',
      sorter: (a, b) => isComparer(a.AddedBy, b.AddedBy),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Time',
      dataIndex: 'SessionTime',
      key: 'SessionTime',
      sorter: (a, b) => isComparer(a.SessionTime, b.SessionTime),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => isComparer(a.Status, b.Status),
      sortDirections: ['descend', 'ascend'],
      render: text => <div>{text}</div>,
    },
    {
      title: 'Action',
      key: 'x',
      width: '50px',
      fixed: 'right',
      render: (text, record) => {
        return record.IsAppointmentNote ? (
          <span
            className="assign-queue-action"
            onClick={event => {
              this.setState({
                isAppointmentNoteDrawer: true,
                rowAppointmentId: record.AppointmentId,
                tabId: 1,
                ClientId: record.ClientId,
              });
              this.props.dispatch(
                getAppointment({
                  json: JSON.stringify({
                    Appointment: [
                      {
                        AppointmentId: record.AppointmentId,
                      },
                    ],
                  }),
                }),
              );
              this.props.dispatch(
                comboServiceCodeGet({
                  Json: JSON.stringify({
                    Combo: [
                      {
                        IsPeerRecovery: true,
                        AppointmentId: record.AppointmentId,
                      },
                    ],
                  }),
                }),
              );
            }}
          >
            Manage Notes
          </span>
        ) : (
          // <span
          //   className="assign-queue-action"
          //   onClick={event => {
          //     //   this.props.dispatch(
          //     //     studentQueueInsert({
          //     //       json: JSON.stringify({
          //     //         Queue: [
          //     //           {
          //     //             CenterProgramTypeId: parseInt(this.state.selectedTag),
          //     //             StudentId: this.props.ClientId,
          //     //             Priority: this.state.riskId,
          //     //             CenterId: record.CenterId,
          //     //           },
          //     //         ],
          //     //       }),
          //     //     }),
          //     //   );
          //   }}
          // >
          //   View Notes
          //   </span>
          <AntdModal
            ModalButton={({ onClick }) => (
              <span
                className="assign-queue-action"
                onClick={e => {
                  onClick();
                  e.stopPropagation();
                }}
                data-toggle="modal"
                data-target="#view-notes"
                style={{ cursor: 'pointer' }}
              >
                View Notes
              </span>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div class="modal-content border-0">
                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                  <h4 class="modal-title col-12 mb-3">View Notes</h4>
                </div>
                <div class="modal-body px-2 px-xl-5">
                  <div
                    className=""
                    style={{
                      color: '#182838',
                      fontSize: '16px',
                      // textAlign: 'justify',
                    }}
                  >
                    <span>{record.Note}</span>
                  </div>
                </div>
                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    class="btn btn-outline-primary black-btn"
                    onClick={() => {
                      onOk();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          />
        );
      },
    },
  ];
  render() {
    const {
      ClientId,
      noteList,
      isFetching,
      Practitioner,
      ServiceCodeList,
      ProgramType,
      PlaceOfService,
      BannerData,
      Status,
      PractitionerOffice,
    } = this.props;
    let optionData = {
      Practitioner: Practitioner,
      ServiceCodeList: ServiceCodeList,
      ProgramType: ProgramType,
      PlaceOfService: PlaceOfService,
      Status: Status,
      PractitionerOffice: PractitionerOffice,
    };
    return (
      <div className="prospect-notes">
        <div className="row p-5">
          <Loader2 loading={isFetching} />
          <div className="col-12">
            <PersistTableSort
              name="prospect-appointment"
              columns={this.columns}
              dataSource={noteList}
              size={'middle'}
              pagination={{
                itemRender: (page, type, originalElement) => {
                  return (
                    <div class="dataTables_wrapper no-footer">
                      <div class="dataTables_paginate paging_simple_numbers">
                        {type == 'prev' ? (
                          <a class="paginate_button previous">Previous</a>
                        ) : type == 'next' ? (
                          <a class="paginate_button next">Next</a>
                        ) : (
                          <a class="paginate_button ">{page}</a>
                        )}
                      </div>
                    </div>
                  );
                },
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
        {this.state.isAppointmentNoteDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">Appointment Details</span>
              </div>
            }
            placement="right"
            open={this.state.isAppointmentNoteDrawer}
            width={700}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.setState({
                initialData: {},
                isAppointmentNoteDrawer: false,
              });
            }}
          >
            <AppointmentDetails
              initialData={this.state.initialData}
              AppointmentId={this.state.rowAppointmentId || this.state.initialData.AppointmentId}
              TabId={this.state.tabId}
              ClientId={ClientId}
              OptionData={optionData}
              IsPeerRecovery={false}
            />
          </Drawer>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({
  appointment_note_list_get,
  student_note_add,
  practitioner_combos,
  combos,
  medicate_combos,
  get_combo_facility,
  upsert_appointment,
  combo_service_code_get,
}) => {
  const { data, isFetching, error } = appointment_note_list_get;
  const { data: noteData } = student_note_add;
  const { data: practitionerData } = practitioner_combos;
  const { data: medicateCombos } = medicate_combos;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: ComboServiceCodeData } = combo_service_code_get;

  let noteList = null;
  let isNoteAdded = null;
  let Practitioner = [],
    ServiceCodeList = [],
    ProgramType = [],
    PlaceOfService = [],
    PractitionerOffice = [],
    Status = [];
  if (data && data.AppointmentNote) {
    noteList = data.AppointmentNote;
  }

  if (noteData && noteData.success) {
    isNoteAdded = true;
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner =
      practitionerData.PractitionerList.filter(provider => provider.IsArchived === false) || [];
  }
  if (combos.data && combos.data['RCM_Program']) {
    ProgramType = combos.data['RCM_Program'] || [];
    PlaceOfService = combos.data['RCM_PlaceOfService'] || [];
  }
  if (medicateCombos) {
    Status = medicateCombos.Status;
  }
  if (ComboServiceCodeData && ComboServiceCodeData.data) {
    ServiceCodeList = JSON.parse(ComboServiceCodeData.data).Combo || [];
  }
  if (ComboFacilityData) {
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  return {
    noteList,
    isFetching,
    isNoteAdded,
    Practitioner,
    ServiceCodeList,
    ProgramType,
    PlaceOfService,
    Status,
    PractitionerOffice,
  };
};
export default connect(mapStateToProps)(withRouter(Notes));
