const getParam = (history, value) => {
  var param = new URLSearchParams(history.location.search);
  var id = param.get(value) || param.get('Id');
  if (id) {
    id = id;
  } else {
    id = null;
  }
  return id;
};
export default getParam;
