import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button, Drawer, Tooltip } from 'antd';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import Axios from 'axios';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import AntdModal from '../../../../../Components/CustomModal';
import validate from '../../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
  CheckboxGroup,
} from '../../../../../component/custom-redux-components';
import { taskClientUpsert, taskClientListGet } from '../../../../../store/actions';
import { ProcessCombos } from '../../../../../store/comboConfig';
import { stringDate } from '../../../../../util/date';

let Validator = {
  required: value => (value ? undefined : 'Required'),
  text: value => (value && value.trim().length == 0 ? 'Required' : 'Dummy Text'),
};

class NewTaskForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getClientId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  };

  addTask = values => {
    const { Title, DueDate, IsPredefinedTask } = values;
    let data = {
      Task: [
        {
          TaskId: null,
          ClientId: this.getClientId(),
          DueDate: stringDate(DueDate),
          Title: Title,
          IsPredefinedTask: IsPredefinedTask ? IsPredefinedTask : false,
        },
      ],
    };
    let Data = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(taskClientUpsert(Data));
  };

  render() {
    const { isReadOnlyUser, prescriptionList, isFetching, handleSubmit, dispatch } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(this.addTask)}>
          <div className="row mt-3 justify-content-between align-items-center enrollment-form">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group">
                <Field
                  name="Title"
                  fieldName="Task Name"
                  type="text"
                  component={ReduxInput}
                  validate={Validator.required}
                  style={{ backgroundColor: 'unset' }}
                />
                <label class="placeholder-label">Enter Task Name*</label>
              </div>
            </div>

            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group">
                <Field
                  name="DueDate"
                  fieldName="Due Date"
                  type="text"
                  component={ReduxDateInput}
                  validate={Validator.required}
                  label={'Task Due Date*'}
                  // minDate={new Date(2016, 0, 1)}
                />
                <i class="far fa-calendar-alt form-control-icon" />
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group">
                <label class="custom-control-label" for>
                  {'  '}
                  Would you like to add this to a library of tasks ? &nbsp;
                  <Field
                    name="IsPredefinedTask"
                    type="text"
                    component={ReduxToggle}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    // disabled={disable}
                  />
                </label>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 text-right mt-3 mb-3">
              <button type="submit" class="btn">
                Assign
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const ReduxNewTaskForm = reduxForm({
  form: 'newTaskForm',
  // Validator,
  enableReinitialize: true,
})(withRouter(NewTaskForm));

export default connect()(ReduxNewTaskForm);
