import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { BGContent, BGContentInternal } from '../commonSection';
import { startFreeLicense, userFeatureFlagGet } from '../../../../store/actions';
const labels = [
  'Active/Inactive Clients',
  'Referal Sources',
  'Time In Treatment',
  'Demographic Profile',
  'Mapping',
  'Score Improvement',
  'SP Behavioral Ratings',
  'Standard Cost of Care',
  'Service Units Delivered',
  'Trigger Location Activity',
];

const SmallGroup = props => {
  const { StatusData, isSubscriberProfileScreen, dispatch, isLicenseStart } = props;

  useEffect(() => {
    if (isLicenseStart) {
      dispatch(userFeatureFlagGet({}));
    }
  }, [isLicenseStart]);

  const handleStartTrial = () => {
    let moduleData = {
      License: [{ ModuleId: StatusData && StatusData.ModuleId, IsFreeLicense: true }],
    };
    dispatch(startFreeLicense({ JsonInput: JSON.stringify(moduleData) }));
  };
  return (
    <>
      {!isSubscriberProfileScreen && (
        <div
          className="row bg-white p-4 m-1"
          style={{
            borderRadius: '12px',
            backgroundImage: `url("images/license/bg_white.svg")`,
            backgroundPosition: 'upset',
          }}
        >
          <div className="col-12 h5">Small Groups</div>
          <div className="col-12">
            <div className="row">
              {' '}
              <div className="col-6">
                Small Groups feature license creates small groups meeting sessions for patients.
                This is a great way to get patients together to discuss their progress and
                challenges, and to support each other in their recovery.
              </div>
              <div className="col-2"></div>
              <div className="col-4">
                <div className="d-flex align-item-center justify-content-center">
                  <button className="btn" onClick={handleStartTrial} style={{ cursor: 'pointer' }}>
                    Start your 14-day trial
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isSubscriberProfileScreen && (
        <BGContent
          moduleName={'Small Groups'}
          backGroundImage={`images/license/smallGroupBG.svg`}
          moduleLogoImage={`images/license/smallGroup.svg`}
          labelTitle={'What you will get :'}
          labels={labels}
          tagLine={
            ' Small Groups feature license creates small groups meeting sessions for patients. This is a great way to get patients together to discuss their progress and challenges, and to support each other in their recovery.'
          }
          trialButtonText={'Start your 14-day trial'}
          handleStartTrial={handleStartTrial}
          buyLinkText={'Purchase Small Groups License'}
        />
      )}

      {isSubscriberProfileScreen && (
        <BGContentInternal
          moduleName={'Small Groups'}
          backGroundImage={`images/license/smallGroupBGInternal.png`}
          moduleLogoImage={`images/license/smallGroup.svg`}
          labels={labels}
          tagLine={
            ' Small Groups feature license creates small groups meeting sessions for patients. This is a great way to get patients together to discuss their progress and challenges, and to support each other in their recovery.'
          }
          buyLinkText={'Purchase Small Groups License'}
          trialButtonText={'Start your 14-day trial'}
          extraclassName={'trialNotStarted_SubscriberProfile'}
          handleStartTrial={handleStartTrial}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { user_feature_flag_get, start_free_license } = state;
  const { data, isFetching, error } = start_free_license;

  let isLicenseStart = false;

  if (data && data.success) {
    isLicenseStart = data.success;
  }
  return {
    isLicenseStart,
  };
};

export default connect(mapStateToProps)(SmallGroup);
