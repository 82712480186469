import { all, fork } from 'redux-saga/effects';
import student from './student';
import survey from './survey';
import login from './login';
import combo from './combo';
import todo from './todo';
import activitylog from './activitylog';
import dashboard from './dashboard';
import report from './report';
import administration from './adminstration';
import errorhandling from './errorhandling';
import menu from './menu';
import userRole from './userRole';
import licensing from './licensing';
import appSubscribers from './subscribers';
import appInvite from './appInvite';
import callSession from './callSession';
import smallGroup from './smallGroup';
import newDashboard from './newDashboard';
import documentsUpload from './documentsUpload';
import rcm from './rcm';
import counselor from './counselor';
import genericForm from './genericForm';
import couponCode from './couponCode';
import generateCallToken from './call';
import badge from './badge';
import misc from './misc';
import bed from './bed';
import chatsp from './chatsp';
import youuniverse from './youuniverse';
import vbc from './vbc';
import masterService from './masterService';
import payerPortal from './payerPortal';
import powerBiReports from './powerBiReports';
import clincicalTask from './clinicalTask';
export default function* root() {
  yield all([
    fork(errorhandling),
    fork(login),
    fork(student),
    fork(survey),
    fork(combo),
    fork(todo),
    fork(activitylog),
    fork(dashboard),
    fork(report),
    fork(administration),
    fork(menu),
    fork(userRole),
    fork(licensing),
    fork(appSubscribers),
    fork(appInvite),
    fork(callSession),
    fork(smallGroup),
    fork(newDashboard),
    fork(documentsUpload),
    fork(rcm),
    fork(counselor),
    fork(genericForm),
    fork(couponCode),
    fork(generateCallToken),
    fork(badge),
    fork(misc),
    fork(bed),
    fork(chatsp),
    fork(youuniverse),
    fork(vbc),
    fork(masterService),
    fork(payerPortal),
    fork(powerBiReports),
    fork(clincicalTask)
  ]);
}
