import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReports } from '../store/actions';
import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Loader from '../component/loader';
import CustomTable from '../component/table';
// import CustomTable from '../component/custom-components/customTable';

class ReportTable extends Component {
  constructor() {
    super();
    this.state = {
      isFetching: false,
      isHTMLLoaded: false,
      html: '',
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // const { ReportType, ReportId } = this.props;
    // let data = {
    //   ReportType: ReportType,
    //   Json: JSON.stringify({ Report: [{ ReportId: ReportId }] }),
    // };
    // this.props.dispatch(getReports(data));
  }

  render() {
    const { reportData, isFetching } = this.props;

    const reportArr = Object.values(reportData);
    const reportName = Object.keys(reportData);
    return (
      <div class="container-fluid">
        <div class="content-area">
          <div class="row  mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              {/* <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a> */}
              <Link to="/report" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link>
            </div>
          </div>
          <div className="row">
            <Loader loading={isFetching} />

            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div className="row card-body">
                  {reportArr && reportArr[0] != null ? (
                    reportArr.map((arrData, index) => {
                      console.log(reportArr);
                      return (
                        <div className="col-12 px-2">
                          <CustomTable
                            heading={<h6>{reportName[index]}</h6>}
                            rowData={arrData}
                            // pagination={false}
                            // scroll={900}
                            // size={'small'}
                            bordered={true}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div class="dataTables_wrapper no-footer">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a class="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a class="paginate_button next">Next</a>
                                      ) : (
                                        <a class="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              showSizeChanger: true,
                              // hideOnSinglePage: true,
                            }}
                          />

                          <br />
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12">
                      <h6 className="text-center">No Records</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToPropsTable = ({ get_reports }) => {
  var { data, isFetching, error } = get_reports;
  let reportData = [];
  if (data) {
    reportData = get_reports.data[0];
  }
  return {
    isFetching: isFetching,
    reportData,
  };
};

export default connect(mapStateToPropsTable)(withRouter(ReportTable));
