import React, { Component } from 'react';
import ListRenderer from '../../Components/listRenderer';
import ClientReels from './tabs/clientReels';

export default class MobileAdvisorTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabList: [],
    };
  }

  componentDidMount() {
    let tabList = [
      {
        Name: 'Client Reels',
        ExtraComponent: props => <ClientReels />,
      },
    ];
    this.setState({ tabList });
  }
  render() {
    const { tabList } = this.state;
    return (
      <div className="mb-3">
        <div className="card border-0">
          <div className="card-body">
            <div className="row mobile-advisor">
              <ListRenderer list={tabList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
