import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Drawer, Tabs } from 'antd';
import Axios from 'axios';
import { ReduxSelect } from '../../../component/custom-redux-components';
import CustomModal from '../../../core/modal';
import { currencyFormatter } from '../../../util/formatter';
import ClientProfileHeaderInPayer from '../../customScreens/clientProfileHeaderInPayer';
import ListRenderer from '../../../Components/listRenderer';
import ContractDetails from './components/contractDetails';
import TermsAndConditions from './components/termsAndConditions';
import TargetPriceBundle from './components/targetPriceBundle';
import { Loader2 } from '../../../Components/Loader';
import TargetPriceRequest from './components/requestDrawerForAll/targetPriceRequest';
import ServiceCodeRequest from './components/requestDrawerForAll/seriveCodeRequest';
import ContractAuthorization from './components/authorizations';
import PendingChangeRequest from '../pendingChangeRequest';
import ClientReports from './components/clientReports';
import RiskChart from './components/RiskChart';
// import CareTeam from './components/careTeam';
// import Documents from './components/documents';
import Documents from './documents';
import getParam from '../../../util/getParam';
import CloseIcon from '../../../Components/CloseIcon';
import {
  smartContractGet,
  resetSmartContractGet,
  payerPriceDetailsGet,
  resetClientInsuranceComboGet,
  listCombos,
  payerPortalAuthorizationGet,
  smartContractTermsConditionGetAllInfoJson,
  payerPortalRequestGet,
} from '../../../store/actions';
import downloadPdf from '../../../util/downloadPDF';
import DownloadContractPdf from '../../RCM/patientEnrollmentForm/formSteps/smartContract/downloadContractPdf';
import renderHTML from 'react-render-html';

const { TabPane } = Tabs;

let statusArray = {
  1: 'incomplete',
  2: 'pending',
  3: 'active',
  4: 'terminated',
  5: 'expired',
  6: 'delivered',
  7: 'declined',
};
class PayerClientContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null,
      activeKey: 0,
      tabList: null,
      showRequest: false,
      showRequestDrawer: false,
      serviceCodeRequestDetails: {},
      RequestedDrawer: null,
      drawerTitle: null,
      showModal: false,
      isDocFetching: false,
      clientId: getParam(this.props.history, 'pId'),
      contractId: getParam(this.props.history, 'cId'),
      tabNamesList: {
        0: 'contract-details',
        1: 'contract-terms',
        2: 'authorization',
        3: 'report',
        4: 'documents',
      },
    };
  }

  // tabNamesList = {
  //   0: 'contract-details',
  //   1: 'contract-terms',
  //   2: 'authorization',
  //   3: 'report',
  //   4: 'documents',
  // };

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  getContractId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var cId = param.get('cId');
    if (cId) {
      cId = parseInt(cId);
    } else {
      cId = null;
    }
    return cId;
  }

  getTabName() {
    const { tabNamesList } = this.state;
    var param = new URLSearchParams(this.props.history.location.search);
    var name = param.get('tab');
    if (name) {
      name = name;
      let tabId = Object.keys(tabNamesList).find(key => tabNamesList[key] === name);
      this.setState({
        activeKey: tabId,
      });
    } else {
      name = null;
    }
    return name;
  }

  openAndCloseModal = () => {
    this.setState({ showRequestDrawer: !this.state.showRequestDrawer });
  };

  handleContractChange = contract => {
    this.setState({ contract });
    let data = {
      SmartContract: [{ ClientId: this.getClientId(), SmartContractId: contract }],
    };

    var smartContractData = {
      json: JSON.stringify(data),
    };
    this.props.history.replace(
      `/payer/contract/client-profile?pId=${this.getClientId()}&cId=${contract}`,
    );
    this.props.dispatch(smartContractGet(smartContractData));

    this.props.dispatch(
      payerPriceDetailsGet({
        Json: JSON.stringify({
          SmartContract: [
            {
              SmartContractId: contract,
            },
          ],
        }),
      }),
    );

    this.props.dispatch(
      payerPortalAuthorizationGet({
        JSON: JSON.stringify({
          Authorizations: [
            {
              ClientId: this.getClientId(),
              SmartContractId: contract,
            },
          ],
        }),
      }),
    );

    this.props.dispatch(
      smartContractTermsConditionGetAllInfoJson({
        Json: JSON.stringify({
          TermsCondition: [
            {
              SmartContractId: contract,
            },
          ],
        }),
      }),
    );

    this.props.dispatch(
      payerPortalRequestGet({
        Json: JSON.stringify({
          Request: [
            {
              SmartContractId: contract,
              Id: null,
              RequestType: '30212 ',
            },
          ],
        }),
      }),
    );
  };

  handleShowRequest = () => {
    this.setState({
      showRequest: false,
    });
  };

  componentDidMount() {
    this.props.dispatch(
      payerPriceDetailsGet({
        Json: JSON.stringify({
          SmartContract: [
            {
              SmartContractId: getParam(this.props.history, 'cId'),
            },
          ],
        }),
      }),
    );

    this.setState({
      contract: this.getContractId(),
    });

    let dataCombo = {
      ComboTypes: 'SmartContractList',
      Json: JSON.stringify({
        SmartContract: [
          {
            ClientId: this.getClientId(),
          },
        ],
      }),
      // Json: '{"SmartContract":{"ClientId":20008484001}}',
    };

    this.props.dispatch(listCombos(dataCombo));
    let tabList = [
      {
        Name: 'Contract Details',
        tabName: 'contract-details',
        ComponentName: ContractDetails,
        ExtraComponent: props => (
          <ContractDetails
            showRequest={this.state.showRequest}
            contractId={this.getContractId()}
            clientId={this.getClientId()}
            getServiceCodeDetails={this.getServiceCodeDetails}
          />
        ),
      },
      {
        Name: 'Contract Terms',
        tabName: 'contract-terms',
        ComponentName: TermsAndConditions,
        ExtraComponent: () => <TermsAndConditions showRequest={this.state.showRequest} />,
      },
      {
        Name: 'Authorizations',
        tabName: 'authorization',
        ComponentName: ContractAuthorization,
        ExtraComponent: () => (
          <ContractAuthorization contractId={this.getContractId()} clientId={this.getClientId()} />
        ),
      },
      {
        Name: 'Report',
        tabName: 'risk-chart',
        ComponentName: RiskChart,

        ExtraComponent: () => (
          <RiskChart contractId={this.getContractId()} clientId={this.getClientId()} />
        ),
      },
      // {
      //   Name: 'Care Team',
      //   ExtraComponent: () => (
      //     <CareTeam contractId={this.getContractId()} clientId={this.getClientId()} />
      //   ),
      // },
      {
        Name: 'Documents',
        tabName: 'documents',
        ComponentName: Documents,

        ExtraComponent: () => (
          <Documents contractId={this.getContractId()} clientId={this.getClientId()} />
        ),
      },
    ];

    this.setState({ tabList });
    this.getTabName();
  }

  getServiceCodeDetails = (
    bundleName,
    categoryName,
    serviceCode,
    smartContractServiceBundleServiceCodeId,
  ) => {
    let objectDetails = {
      bundleName,
      categoryName,
      serviceCode,
      smartContractServiceBundleServiceCodeId,
    };
    this.setState({
      serviceCodeRequestDetails: objectDetails,
      RequestedDrawer: ServiceCodeRequest,
      showRequestDrawer: true,
      drawerTitle: 'Service Change Request',
    });
  };

  componentWillUnmount() {
    this.props.dispatch(resetSmartContractGet());
  }

  componentWillReceiveProps({ isReqSuccess, firstName }) {
    if (isReqSuccess && isReqSuccess != this.props.isReqSuccess) {
      this.setState({ showModal: true });
      this.props.dispatch(
        payerPriceDetailsGet({
          Json: JSON.stringify({
            SmartContract: [
              {
                SmartContractId: this.getContractId(),
              },
            ],
          }),
        }),
      );

      // let data = {
      //   SmartContract: [{ ClientId: this.getClientId(), SmartContractId: this.getContractId() }],
      // };

      // var smartContractData = {
      //   json: JSON.stringify({
      //   SmartContract: [{ ClientId: this.getClientId(), SmartContractId: this.getContractId() }],
      // }),
      // };
      this.props.dispatch(
        smartContractGet({
          json: JSON.stringify({
            SmartContract: [
              { ClientId: this.getClientId(), SmartContractId: this.getContractId() },
            ],
          }),
        }),
      );
    }

    // if (firstName && firstName != this.props.firstName) {
    //   tabList[3].Name = firstName + ' Report';
    //   this.setState({ tabList });
    // }
  }

  onPendingContractClose = () => {
    this.setState({ pendingContract: false });
  };

  getLatestData = () => {
    let data = {
      SmartContract: [{ ClientId: this.getClientId(), SmartContractId: this.getContractId() }],
    };

    var smartContractData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(smartContractGet(smartContractData));
  };

  onTabClick = value => {
    const { tabNamesList } = this.state;
    this.setState({
      activeKey: parseInt(value),
    });
    // let tabName = this.state.tabList[parseInt(value)].tabName;
    let tabName = tabNamesList[value];
    this.props.history.push(
      // `/cw/patient-management/patient-profile?tab=demographic&pId=${ClientId}`,
      `/payer/contract/client-profile?tab=${tabName}&pId=${this.getClientId()}&cId=${this.getContractId()}`,
    );
  };

  goToPreviuos = index => {
    /*window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });*/
    this.setState({ activeKey: index - 1 });
  };

  goToNext = index => {
    /*window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });*/
    this.setState({ activeKey: index + 1 });
  };

  render() {
    const {
      targetPriceBundle,
      targetPriceDetails,
      contractDropdownList,
      isTargetPriceRequest,
      statusId,
      status,
      isReadOnly,
      firstName,
    } = this.props;
    const {
      showRequest,
      RequestedDrawer,
      serviceCodeRequestDetails,
      drawerTitle,
      tabList,
      activeKey,
      isDocFetching,
      pendingContract,
    } = this.state;
    return (
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12 top-table-search mb-2 pt-2">
            <div className="col-12">
              <Link to="/payer/contracts" className="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link>
              <h3 className="form-main-head mb-3">Contract Details</h3>
            </div>
            <div className="card border-0 students-profile">
              <ClientProfileHeaderInPayer />
            </div>
          </div>
        </div>

        <div className=" card border-0  mx-0 mt-3 p-3">
          <h5>SELECT CONTRACT</h5>
          <div className="row flex-row">
            <div className="col-6 mb-2 pt-0 d-flex align-items-center">
              <div className="students-profile col-6" style={{ padding: '18px' }}>
                <div class="">
                  <div class="form-group mb-0">
                    <ReduxSelect
                      // options={[
                      //   { Text: 'All', Value: 10 },
                      //   { Text: 'Guest', Value: 75 },
                      //   { Text: 'Active', Value: 45 },
                      //   { Text: 'Canceled', Value: 12 },
                      //   { Text: 'ReferralCode', Value: 99 },
                      //   { Text: 'VoucherCode', Value: 150 },
                      // ]}
                      options={contractDropdownList}
                      onChange={this.handleContractChange}
                      className="basic-single"
                      classNamePrefix="select"
                      value={this.state.contract}
                      placeholder="Select Contract"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 mt-2 enrollment-form">
                {status && (
                  <div class="form-group mb-0 w-100">
                    <label
                      className="placeholder-label"
                      style={{
                        zIndex: 1,
                        fontSize: '15px',
                        top: '-15px',
                        // left: !!disabled || value || active ? '0' : '0',
                        position: 'absolute',
                      }}
                    >
                      Status
                    </label>
                    <div className="contract-status">
                      {status && (
                        <span
                          className={statusArray[statusId]}
                          style={{
                            marginTop: '13px',
                            textTransform: 'uppercase',
                            display: 'inline-block',
                          }}
                        >
                          {status}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center col-6">
              <button
                className="btn btn-outline-primary"
                onClick={() => this.setState({ pendingContract: true })}
              >
                Notes/Changes from Provider
              </button>
            </div>
          </div>
        </div>

        {/* {this.props.smartContractArray && this.props.smartContractArray.length > 0 && ( */}
        <div className="row mt-4">
          <div className="col-9">
            <div className="card border-0 payer-authorizations center-tabs p-3">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="text-uppercase mb-0">Treatment Contract</h4>
                <div className="d-flex align-items-center justify-content-between ">
                  <DownloadContractPdf
                    clientId={this.getClientId()}
                    smartContractId={this.getContractId()}
                  />
                </div>
              </div>
              {/* <ListRenderer
                list={this.state.tabList ? this.state.tabList : []}
                destroyInactiveTabPane={true}
              /> */}
              <Tabs
                // defaultActiveKey="1"
                centered
                activeKey={String(activeKey)}
                defaultActiveKey="0"
                onTabClick={this.onTabClick}
                forceRender={true}
                destroyInactiveTabPane={true}
              >
                {firstName &&
                  tabList &&
                  tabList.map((item, keyIndex) => {
                    const { Name, ComponentName } = item;
                    const DynamicComponentConversion = ComponentName;
                    const tabsCount = tabList.length;
                    let tabName =
                      Name && Name == 'Report' && firstName ? `${firstName}'s Report` : Name;
                    return (
                      <Tabs.TabPane
                        tab={renderHTML(
                          `<span class='tab-number' style="display: flex; justify-content: center; align-items: center; margin-right: 3px; padding-top: 2.5px; margin-bottom: 3px;">${keyIndex +
                            1}</span> ${tabName}`,
                        )}
                        key={keyIndex}
                      >
                        {
                          <DynamicComponentConversion
                            showRequest={this.state.showRequest}
                            contractId={this.getContractId()}
                            clientId={this.getClientId()}
                            getServiceCodeDetails={this.getServiceCodeDetails}
                            isReadOnly={isReadOnly}
                            goToPreviuos={() => this.goToPreviuos(keyIndex)}
                            currentTab={keyIndex}
                            tabsCount={tabList.length}
                            goToNext={() => this.goToNext(keyIndex)}
                          />
                        }
                      </Tabs.TabPane>
                    );
                  })}
              </Tabs>
            </div>
          </div>
          <div className="col-3">
            <Loader2 loading={this.props.isTargettFetching} />
            <div className="card border-0 p-3 d-flex flex-column align-items-center">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: '100%', height: '46px' }}
              >
                <h5 className="text-uppercase mb-0">Target Price</h5>
                {!isTargetPriceRequest && !isReadOnly && (
                  // <button
                  //   className="btn btn-outline-primary"
                  //   onClick={() => {
                  //     this.setState({
                  //       showRequestDrawer: true,
                  //       RequestedDrawer: TargetPriceRequest,
                  //       drawerTitle: 'Target Price Change Request',
                  //     });
                  //   }}
                  // >
                  //   Request
                  // </button>
                  <p
                    className="mb-0"
                    style={{
                      color: '#009EF7',
                      fontWeight: '600',
                      fontSize: '15px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      this.setState({
                        showRequestDrawer: true,
                        RequestedDrawer: TargetPriceRequest,
                        drawerTitle: 'Target Price Change Request',
                      });
                    }}
                  >
                    Request Changes
                  </p>
                )}

                {isTargetPriceRequest && !isReadOnly && (
                  <p
                    className="mb-0"
                    style={{
                      color: '#00DC31',
                      fontSize: '15px',
                      fontWeight: '600',
                    }}
                  >
                    Changes Requested
                  </p>
                )}
              </div>
              {targetPriceBundle &&
                targetPriceBundle.map((item, index) => (
                  <TargetPriceBundle
                    BundleData={item}
                    isTargettFetching={this.props.isTargettFetching}
                    key={index}
                    activeKey={index + 1}
                  />
                ))}
              <div
                className="d-flex mt-4 justify-content-between row align-items-center"
                style={{ width: '100%' }}
              >
                <p className="mb-0 col-6 p-0" style={{ fontSize: '16px', fontWeight: '600' }}>
                  Total Billing Amount
                </p>
                <p
                  className="mb-0 col-5 p-0"
                  style={{
                    color: '#46B4FE',
                    fontWeight: '700',
                    fontSize: '18px',
                    textAlign: 'right',
                  }}
                >
                  {`${
                    targetPriceDetails && targetPriceDetails.ClaimAmount
                      ? currencyFormatter.format(targetPriceDetails.ClaimAmount)
                      : ''
                  }`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* // )} */}

        {RequestedDrawer && (
          <Drawer
            className="custom-drawer"
            title={drawerTitle}
            placement="right"
            open={this.state.showRequestDrawer}
            width={800}
            bodyStyle={{ paddingBottom: 80, backgroundColor: 'transparent' }}
            closable={true}
            destroyOnClose={true}
            onClose={() => {
              this.setState({
                showRequestDrawer: false,
                RequestedDrawer: null,
              });
            }}
          >
            <RequestedDrawer
              totalPrice={
                targetPriceDetails && targetPriceDetails.ClaimAmount
                  ? targetPriceDetails.ClaimAmount
                  : 0
              }
              serviceCodeRequestDetails={serviceCodeRequestDetails}
              dispatch={this.props.dispatch}
              isReqFetching={this.props.isReqFetching}
              contractId={this.getContractId()}
              openAndCloseModal={this.openAndCloseModal}
            />
          </Drawer>
        )}

        <Drawer
          closeIcon={<CloseIcon />}
          width={800}
          title={<span className="contract-drawer-title">Pending Contract Change Requests</span>}
          placement="right"
          onClose={this.onPendingContractClose}
          open={pendingContract}
          maskClosable={false}
          closable={true}
          className="contract-drawer"
        >
          <PendingChangeRequest
            history={this.props.history}
            onClose={this.onPendingContractClose}
            getLatestData={this.getLatestData}
            clientId={this.getClientId()}
            contractId={this.getContractId()}
          />
        </Drawer>
        <CustomModal
          isOpen={this.state.showModal}
          header={'Request sent successfully'}
          yesAction={() => {
            this.setState({ showModal: false, showRequestDrawer: false });
            this.props.dispatch(resetClientInsuranceComboGet({}));
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  smart_contract_get,
  payer_price_details_get,
  payer_portal_request_upsert,
  combos,
  client_banner_get,
}) => {
  const { data: smartContractDetails, isFetching } = smart_contract_get;
  const { data: payerPriceDetails, isFetching: isTargettFetching } = payer_price_details_get;
  const { data: requestUpsert, isFetching: isReqFetching } = payer_portal_request_upsert;
  let { data: bannerDetails } = client_banner_get;

  let firstName = null;

  if (bannerDetails && bannerDetails.Banner) {
    firstName = bannerDetails.Banner[0] && bannerDetails.Banner[0].FirstName;
  }

  let targetPriceDetails = [];
  let targetPriceBundle = [];
  let contractDropdownList = [];
  let isReqSuccess = false;
  let isTargetPriceRequest = false;
  let status;
  let statusId;
  let isReadOnly = false;

  if (payerPriceDetails && payerPriceDetails.SmartContract && payerPriceDetails.SmartContract[0]) {
    targetPriceDetails = payerPriceDetails.SmartContract[0];
    targetPriceBundle = payerPriceDetails.SmartContract[0].Bundle;
    isTargetPriceRequest = payerPriceDetails.SmartContract[0].IsRequestPending;
  }
  if (smartContractDetails && smartContractDetails.SmartContract) {
    status = smartContractDetails.SmartContract[0] && smartContractDetails.SmartContract[0].Status;
    statusId =
      smartContractDetails.SmartContract[0] && smartContractDetails.SmartContract[0].StatusId;
    if (statusId && statusId != 1 && statusId != 2) {
      isReadOnly = true;
    }
    // smartContractArray = smartContractDetails.SmartContract;
  }

  if (requestUpsert && requestUpsert.success) {
    isReqSuccess = true;
  }

  if (combos && combos.data) {
    contractDropdownList = combos.data.SmartContractList;
  }
  return {
    statusId,
    status,
    isFetching,
    targetPriceBundle,
    targetPriceDetails,
    isReqSuccess,
    isReqFetching,
    contractDropdownList,
    isTargetPriceRequest,
    isTargettFetching,
    firstName,
    isReadOnly,
  };
};

export default connect(mapStateToProps)(withRouter(PayerClientContract));
