import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Table } from 'antd';
import { withRouter } from 'react-router-dom';
import { Field, formValueSelector, FieldArray } from 'redux-form';
import { ReduxInput } from '../../../../../../../component/custom-redux-components';
import Validator from '../../../../../../Validation';
const { Panel } = Collapse;

class TermsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      termsData: {},
    };
  }
  handleTermsChange = (val, index) => {
    let terms = this.state.termsData;
    terms[index] = {
      ...terms[index],
      desc: val,
    };
    this.setState({
      termsData: terms,
    });
  };
  handleAddTerms = (index, listIndex, termsCategoryIndex) => {
    let termsData = this.state.termsData;
    let desc = termsData[index].desc;
    if (!listIndex && listIndex != 0) {
      let list = termsData[index].descList || [];
      list.push({
        Description: desc,
        TermsId: null,
      });
      termsData[index] = { desc: '', descList: list };
    } else {
      termsData[index].descList[listIndex].Description = desc;
      termsData[index].desc = '';
    }
    this.setState({
      termsData: termsData,
      listIndex: undefined,
    });
    this.props.getTerms(termsData, termsCategoryIndex, index);
  };
  handleEditTerms = (index, listIndex) => {
    let termsData = this.state.termsData;
    termsData[index].desc = termsData[index].descList[listIndex].Description;
    this.setState({
      termsData: termsData,
      listIndex: listIndex,
    });
  };
  handleDeleteTerms = (index, listIndex) => {
    let termsData = this.state.termsData;
    let list = termsData[index].descList;
    list.splice(listIndex, 1);
    this.setState({
      termsData: termsData,
    });
    this.props.getTerms(termsData);
  };
  handleCancelTerms = (index, listIndex) => {
    let termsData = this.state.termsData;
    termsData[index].desc = '';
    this.setState({
      termsData: termsData,
      listIndex: undefined,
    });
  };
  genrateListItem = termData => {
    let data =
      termData.map((item, index) => {
        return {
          descList: item.Terms,
        };
      }) || [];
    this.setState({
      termsData: data,
    });
    this.props.getTerms(data);
  };
  componentDidMount() {
    if (this.props.termsCategory) {
      this.genrateListItem(this.props.termsCategory);
    }
  }
  componentWillReceiveProps({ termsCategory }) {
    if (
      termsCategory &&
      JSON.stringify(termsCategory) != JSON.stringify(this.props.termsCategory)
    ) {
      this.genrateListItem(termsCategory);
    }
  }
  render() {
    const {
      fields,
      meta: { touched, error, submitFailed },
      disable,
      isButtonVisible,
    } = this.props;
    const allowEdit = !disable;
    if (fields && fields.length == 0) {
      fields.push({});
    }
    const { termsData, listIndex } = this.state;
    return (
      <>
        <div className="row buildServiceBundle">
          <div className="col-12 col-md-12 col-lg-12 my-1">
            <div className="terms-form">
              <Collapse
                // expandIcon={({ isActive }) => (
                //   <RightCircleOutlined
                //     rotate={isActive ? -90 : 90}
                //     style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
                //   />
                // )}
                defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                // onChange={onChange}
              >
                {fields.length > 0 ? (
                  fields.map((options, index) => {
                    let sNo = index + 1;
                    const { descList, desc } = (termsData[index] && termsData[index]) || {};
                    return (
                      <Panel
                        key={index}
                        className="bundle-panel-border"
                        header={
                          <>
                            <div className="row">
                              <div className="col-md-3 panel-header-text">{`Terms Category ${index +
                                1}`}</div>
                              <div className="col-md-9 text-right d-flex">
                                {/* {allowEdit && (
                                    <div class="col-6 text-right pt-1 pb-1">
                                      <a class="add-contact" onClick={() => fields.push({})}>
                                        {fields.length >= 1 && (
                                          <label>+ Add Another Term Category</label>
                                        )}
                                        {fields.length == 0 && <label> + Add Term Category</label>}
                                      </a>
                                    </div>
                                  )} */}

                                {isButtonVisible && (
                                  <>
                                    {!this.props.disable && (
                                      <button
                                        type="button"
                                        class="btn-newCon"
                                        style={{
                                          position: 'unset',
                                          width: 'unset',
                                        }}
                                        onClick={event => {
                                          event.stopPropagation();
                                          fields.push();
                                        }}
                                      >
                                        + Add {fields.length == 0 ? 'Term' : 'Another Term'}
                                      </button>
                                    )}
                                  </>
                                )}
                                {fields.length > 1 && (
                                  <div className="text-right">
                                    {isButtonVisible && (
                                      <>
                                        {!this.props.disable && (
                                          <a
                                            class="col-1 delete-contract"
                                            onClick={e => {
                                              e.stopPropagation();
                                              if (fields.length && fields.length > 0) {
                                                fields.remove(index);
                                              }
                                            }}
                                          >
                                            <i class="fas fa-trash" />
                                          </a>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        }
                      >
                        <>
                          <div className="row mb-2" key={options}>
                            <div className="col-12">
                              <div className="card border-0" style={{ borderRadius: '0.25rem' }}>
                                <div className="row card-body">
                                  <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group mb-1">
                                      <Field
                                        name={`${options}.Category`}
                                        type="text"
                                        component={ReduxInput}
                                        fieldName={'Terms Category'}
                                        validate={Validator.required}
                                        disabled={disable}
                                      />
                                      <label class="placeholder-label">Terms Category {sNo}*</label>
                                    </div>
                                  </div>
                                  <div class="col-12 col-md-5 col-lg-7"></div>
                                  {allowEdit && fields.length > 1 && (
                                    <div className="col-1 text-right">
                                      <a
                                        class="delete-contact"
                                        onClick={() => fields.remove(index)}
                                        style={{ marginLeft: 'unset' }}
                                      >
                                        <i class="fas fa-trash" />
                                      </a>
                                    </div>
                                  )}
                                  <div className="col-12 py-3">
                                    <span class="term-detail-title">Term Details</span>
                                  </div>
                                  <div className="col-12">
                                    <div className="term-detail-section">
                                      <div className="row">
                                        {allowEdit && (
                                          <>
                                            <div class="col-10 pr-0">
                                              <div class="form-group">
                                                <input
                                                  className={`form-control ${desc && 'not-empty'}`}
                                                  onChange={e => {
                                                    this.handleTermsChange(e.target.value, index);
                                                  }}
                                                  value={desc}
                                                  style={{ background: '#F8F8F8' }}
                                                />
                                                <label className="placeholder-label">
                                                  Enter Terms
                                                </label>
                                              </div>
                                            </div>
                                            <div class="col-2 pl-0">
                                              <input
                                                type="button"
                                                onClick={() => {
                                                  this.handleAddTerms(
                                                    index,
                                                    listIndex,
                                                    this.props.termsCategoryIndex,
                                                  );
                                                }}
                                                className="btn mr-2 px-4"
                                                value={listIndex >= 0 ? 'Update' : 'Add'}
                                                disabled={!desc}
                                              />
                                            </div>
                                          </>
                                        )}

                                        {descList && (
                                          <div className="col-12">
                                            <div className="terms-list">
                                              <ul>
                                                {descList.map((item, itemIndex) => {
                                                  return (
                                                    <li
                                                      className="px-2 terms-list-item"
                                                      key={itemIndex + 1}
                                                    >
                                                      <div className="d-flex align-items-center justify-content-between">
                                                        <div className="description">
                                                          <span
                                                            style={{
                                                              fontWeight: 'bold',
                                                              marginRight: '5px',
                                                            }}
                                                          >
                                                            {itemIndex + 1}.
                                                          </span>
                                                          <span>{item.Description} </span>
                                                        </div>
                                                        {allowEdit && (
                                                          <div className="action">
                                                            {listIndex == itemIndex ? (
                                                              <span
                                                                className="edit"
                                                                // className="btn btn-outline-primary"
                                                                onClick={() => {
                                                                  this.handleCancelTerms(
                                                                    index,
                                                                    itemIndex,
                                                                  );
                                                                }}
                                                              >
                                                                Cancel
                                                              </span>
                                                            ) : (
                                                              <>
                                                                <span
                                                                  className="delete"
                                                                  onClick={() => {
                                                                    this.handleDeleteTerms(
                                                                      index,
                                                                      itemIndex,
                                                                    );
                                                                  }}
                                                                >
                                                                  Delete
                                                                </span>
                                                                <span
                                                                  className="edit"
                                                                  onClick={() => {
                                                                    this.handleEditTerms(
                                                                      index,
                                                                      itemIndex,
                                                                    );
                                                                  }}
                                                                >
                                                                  Edit
                                                                </span>
                                                              </>
                                                            )}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </Panel>
                    );
                  })
                ) : (
                  <div class="row"></div>
                )}
              </Collapse>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// const Terms = props => {
//   const { name, getTerms, dataRecord, disable, initialValues, isButtonVisible } = props;
//   return (
//     <div className="row">
//       <div className="col-12">
//         <FieldArray
//           name={'Terms123'}
//           component={TermsForm}
//           getTerms={getTerms}
//           termsCategory={dataRecord}
//           disable={disable}
//           isButtonVisible={isButtonVisible}
//         />
//       </div>
//     </div>
//   );
// };

const mapStateToProps = (state, props) => {
  const existingRows = selector(state, 'Terms');
  return {
    existingRows,
  };
};
const selector = formValueSelector('termsConditionsForm');

export default connect()(withRouter(TermsForm));
