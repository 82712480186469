import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getUserRole(action) {
  try {
    const response = yield API.getUserRole();
    yield put({
      type: ActionTypes.USER_ROLE_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_ROLE_LIST_FAILURE,
    });
  }
}

export function* getListJobTitle(action) {
  try {
    const response = yield API.getListJobTitle();
    yield put({
      type: ActionTypes.LIST_JOB_TITLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LIST_JOB_TITLE_FAILURE,
    });
  }
}

export function* updateJobTitle(action) {
  try {
    const response = yield API.updateJobTitle(action.payload);
    yield put({
      type: ActionTypes.UPDATE_JOB_TITLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_JOB_TITLE_FAILURE,
    });
  }
}

export function* updateRole(action) {
  try {
    const response = yield API.updateRole(action.payload);
    yield put({
      type: ActionTypes.UPDATE_ROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_ROLE_FAILURE,
    });
  }
}

export function* deleteRole(action) {
  try {
    const response = yield API.deleteRole(action.payload);
    yield put({
      type: ActionTypes.DELETE_ROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_ROLE_FAILURE,
    });
  }
}

export function* deleteJobTitle(action) {
  try {
    const response = yield API.deleteJobTitle(action.payload);
    yield put({
      type: ActionTypes.DELETE_JOB_TITLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_JOB_TITLE_FAILURE,
    });
  }
}

export function* updateRolePermissions(action) {
  try {
    const response = yield API.updateRolePermissions(action.payload);
    yield put({
      type: ActionTypes.UPDATE_ROLE_PERMISSIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_ROLE_PERMISSIONS_FAILURE,
    });
  }
}

export function* updateJobTitleSync(action) {
  try {
    const response = yield API.updateJobTitleSync(action.payload);
    yield put({
      type: ActionTypes.UPDATE_JOB_TITLE_SYNC_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_JOB_TITLE_SYNC_FAILURE,
    });
  }
}

export function* getLoadUserInfo(action) {
  try {
    const response = yield API.getLoadUserInfo(action.payload);
    yield put({
      type: ActionTypes.GET_LOAD_USER_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_LOAD_USER_INFO_FAILURE,
    });
  }
}

export function* getValidateImage(action) {
  try {
    const response = yield API.getValidateImage(action.payload);
    yield put({
      type: ActionTypes.GET_VALIDATE_IMAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_VALIDATE_IMAGE_FAILURE,
    });
  }
}

export function* staffPermission(action) {
  try {
    const response = yield API.staffPermission(action.payload);
    yield put({
      type: ActionTypes.GET_STAFF_PERMISSION_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STAFF_PERMISSION_FAILURE,
    });
  }
}
export function* updateStaffPermission(action) {
  try {
    const response = yield API.updateStaffPermission(action.payload);
    yield put({
      type: ActionTypes.UPDATE_STAFF_PERMISSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPDATE_STAFF_PERMISSION_FAILURE,
    });
  }
}

export function* screenAuthorization(action) {
  try {
    const response = yield API.screenAuthorization(action.payload);
    yield put({
      type: ActionTypes.SCREEN_AUTHORIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.SCREEN_AUTHORIZATION_FAILURE,
    });
  }
}

export function* mobileInviteOptionsLoad(action) {
  try {
    const response = yield API.mobileInviteOptionsLoad(action.payload);
    yield put({
      type: ActionTypes.MOBILE_INVITE_OPTIONS_LOAD_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MOBILE_INVITE_OPTIONS_LOAD_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_ROLE_LIST_REQUEST, getUserRole),
    takeLatest(ActionTypes.LIST_JOB_TITLE_REQUEST, getListJobTitle),
    takeLatest(ActionTypes.UPDATE_JOB_TITLE_REQUEST, updateJobTitle),
    takeLatest(ActionTypes.UPDATE_ROLE_REQUEST, updateRole),
    takeLatest(ActionTypes.DELETE_ROLE_REQUEST, deleteRole),
    takeLatest(ActionTypes.DELETE_JOB_TITLE_REQUEST, deleteJobTitle),
    takeLatest(ActionTypes.UPDATE_ROLE_PERMISSIONS_REQUEST, updateRolePermissions),
    takeLatest(ActionTypes.UPDATE_JOB_TITLE_SYNC_REQUEST, updateJobTitleSync),
    takeLatest(ActionTypes.GET_LOAD_USER_INFO_REQUEST, getLoadUserInfo),
    takeLatest(ActionTypes.GET_VALIDATE_IMAGE_REQUEST, getValidateImage),
    takeLatest(ActionTypes.GET_STAFF_PERMISSION_REQUEST, staffPermission),
    takeLatest(ActionTypes.UPDATE_STAFF_PERMISSION_REQUEST, updateStaffPermission),
    takeLatest(ActionTypes.SCREEN_AUTHORIZATION_REQUEST, screenAuthorization),
    takeLatest(ActionTypes.MOBILE_INVITE_OPTIONS_LOAD_REQUEST, mobileInviteOptionsLoad),
  ]);
}
