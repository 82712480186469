import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Modal, Steps, Radio, Space } from 'antd';
import moment from 'moment';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../component/custom-redux-components';
import {
  getAppUserSubscription,
  getServiceOptions,
  getServiceAdvisorList,
  setServiceAdvisor,
  updateCancelService,
  setMembership,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import { currencyFormatter2 } from '../../util/formatter';

const { Step } = Steps;

class ModifyMembershipModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      steps: [
        {
          title: 'Choose Plan',
          content: (
            <ChoosePlan
              UserGuid={props.UserGuid}
              getMembershipValue={this.getMembershipValue}
              {...props}
            />
          ),
        },
        {
          title: 'Payment ',
          content: <Payment UserGuid={props.UserGuid} {...props} />,
        },
      ],
    };
  }
  handleNext = () => {
    let currentKey = this.state.current + 1;
    if (this.state.current === 0) {
      let jsonData = {
        UserSubscription: [
          {
            UserGuid: this.props.UserGuid,
            SubscriptionTypeId: this.state.value,
          },
        ],
      };
      this.props.dispatch(
        setMembership({
          Json: JSON.stringify(jsonData),
        }),
      );
    }
    this.setState({
      current: currentKey,
    });
  };
  handlePrevious = () => {
    let currentKey = this.state.current - 1;
    debugger;
    this.setState({
      current: currentKey,
    });
  };
  componentDidMount() {
    let jsonData = {
      UserSubscription: [
        {
          AppUserGuid: this.props.UserGuid,
        },
      ],
    };
    // this.props.dispatch(getServiceAdvisorList({}));
    // this.props.dispatch(
    //   getServiceAdvisorList({
    //     Json: JSON.stringify(jsonData),
    //   }),
    // );
  }
  getMembershipValue = value => {
    this.state.value = value;
  };
  render() {
    const { onOk, onCancel } = this.props;
    const { steps, current } = this.state;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 className="modal-title col-12 mb-3">Modify Membership</h4>
        </div>

        <div className="modal-body col-md-12 px-5 enrollment-form modify-service">
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content mb-5">{steps[current].content}</div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          {current > 0 && (
            <button onClick={this.handlePrevious} className="btn btn-outline-primary mr-2 px-4">
              Back
            </button>
          )}
          {current < steps.length - 1 && (
            <button onClick={this.handleNext} className="btn px-5">
              Next
            </button>
          )}
          {current > 0 && (
            <button
              // onClick={this.handleNext}
              className="btn px-5"
            >
              Pay
            </button>
          )}
        </div>
      </div>
    );
  }
}
class ChoosePlan extends Component {
  constructor() {
    super();
    this.state = {
      value: 1,
    };
  }

  render() {
    const onChange = e => {
      this.setState({
        value: e.target.value,
      });
      this.props.getMembershipValue(e.target.value);
    };
    const { manageMembership } = this.props;

    return (
      <>
        <div className="row mt-5">
          <Radio.Group onChange={onChange} value={this.state.value} style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {manageMembership &&
                manageMembership.map(item => {
                  return (
                    <div
                      className={`col-12 ${
                        item.Badge === 'Premium' ? 'counselor-box' : 'coach-box'
                      }`}
                    >
                      <div className="c-info">
                        <div className="d-flex align-items-center">
                          <div>
                            <Radio value={item.SubscriptionTypeId} />
                          </div>
                          <img
                            src={item.AdvisorIconUrl || 'images/blank-profile.png'}
                            style={{
                              width: '80px',
                              height: '80px',
                              borderRadius: '8px',
                              marginRight: '10px',
                              objectFit: 'cover',
                            }}
                          />
                          <div className="">
                            <div className="">
                              <span className="name" style={{ fontSize: '20px', color: '#46B4FE' }}>
                                {item.SubscriptionName}
                              </span>
                            </div>
                            <div className="date-info">
                              <span className="text">Renews on</span>
                              <span className="date">{'13-July-2022'}</span>
                              {/* <span className="date">{moment(item.RenewalDate).format('ll')}</span> */}
                            </div>
                            <div className="fee-info">
                              <span className="fee">
                                {currencyFormatter2.format(item.Cost || 0)}
                              </span>
                              <span className="text">/month</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* <div className="col-12 coach-box">
                <div className="c-info">
                  <div className="d-flex align-items-center">
                    <div>
                      <Radio value={2} />
                    </div>
                    <img
                      src="images/icons/star_blue.svg"
                      style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '8px',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    <div className="">
                      <div className="">
                        <span className="name">Plus Membership</span>
                      </div>
                      <div>
                        <span className="text">Renews on</span>
                        <span className="date">{'13-July-2022'}</span>
                      </div>
                      <div className="fee-info">
                        <span className="fee">$49</span>
                        <span className="text">/month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  }
}

class Payment extends Component {
  constructor() {
    super();
    this.state = {
      value: null,
      data: {},
    };
  }

  render() {
    const {} = this.props;
    const { data } = this.state;
    return (
      <>
        <div className="row mt-5">
          <h4>Payment</h4>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { get_service_advisor_list, get_service_option } = state;
  const { data, isFetching } = get_service_advisor_list;
  const { data: optionsData } = get_service_option;

  let advisorList = null;
  let manageMembership;

  if (data && data.UserSubscription) {
    advisorList = data.UserSubscription;
  }
  if (optionsData && optionsData.UserSubscription) {
    manageMembership = optionsData.UserSubscription[0].ManageMembership;
  }
  // if (update_app_subscribers_case.data && update_app_subscribers_case.data.success) {
  //   isSuccess = data.success;
  // }

  return {
    advisorList,
    isFetching: isFetching,
    manageMembership,
  };
};

export default connect(mapStateToProps)(withRouter(ModifyMembershipModal));
