import React, { Component } from 'react';
import { Table, Drawer, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import PayerProfileHeader from '../../customScreens/clientProfileHeaderInPayer';
import TimelineComponent from '../../timelineComponent';
import { clientBannerGet } from '../../../store/actions';

class ClientAuditDetails extends Component {
  constructor(props) {
    super(props);
  }

  getClientId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    this.props.dispatch(
      clientBannerGet({
        json: JSON.stringify({
          Client: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  }
  render() {
    const { profileBanner } = this.props;
    return (
      <div className="payer-audits">
        <div class="container-fluid">
          <div className="row">
            <div className="col-12  top-table-search mb-2 pt-4">
              <div className="col-12">
                <Link to="/payer/audits" className="back-link">
                  <img src="images/back-arrow.svg" /> Back
                </Link>
                <h3 className="form-main-head mb-3">Audits</h3>
              </div>
              <div className="card border-0 students-profile" style={{ padding: '20px' }}>
                {/* <h4>CLIENT DETAILS</h4> */}
                <PayerProfileHeader clientDetails={profileBanner} />
              </div>
              <TimelineComponent hideHeader={true} Ispayer={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default ClientAuditDetails;

const mapStateToProps = ({ client_banner_get }) => {
  let { data: bannerDetails } = client_banner_get;
  let profileBanner = [];

  if (bannerDetails && bannerDetails.Banner) {
    profileBanner = bannerDetails.Banner[0];
  }
  return { profileBanner };
};

export default connect(mapStateToProps)(withRouter(ClientAuditDetails));
