import React, { Component } from 'react';
import Binary from './Binary';
import Date from './Date';
import ToggleWithNumeric from './Numeric';
import OptionsWithToggle from './Options';
import AlphaNumeric from './AlphaNumeric';
import Textarea from './Textarea';
import MultiSelectMultipleChoice from './MultiSelectMultipleChoice';
import SingleSelectMultipleChoice from './SingleSelectMultipleChoice';

// const QUESTION_TYPES = {
//   Options: 19,
//   MultiSelectMultipleChoice: 25,
//   Numeric: 24,
//   SingleSelectMultipleChoice: 22,
// };

const QUESTION_TYPES = {
  // 19: OptionsWithToggle,
  19: SingleSelectMultipleChoice,
  20: AlphaNumeric,
  23: Textarea,
  24: ToggleWithNumeric,
  25: MultiSelectMultipleChoice,
  26: Binary,
  27: Date,
};

class AnswerOptions extends Component {
  constructor(props) {
    super(props);
    const { change, questionFormatValue } = props;
    this.state = {};
    // change(`manageQuestionForm].isEmergencyContact`, true);
  }
  render() {
    const { questionFormatValue, answerOptions, name, questionFormValue } = this.props;
    const AnswerOptionForm = QUESTION_TYPES[questionFormatValue];
    return (
      <div className="answer-options">
        {questionFormatValue ? (
          <AnswerOptionForm
            answerOptions={answerOptions}
            name={`options${questionFormatValue}`}
            questionFormValue={questionFormValue}
          />
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}
export default AnswerOptions;
