import React, { useEffect, useState } from 'react';
import { ReduxSelect } from '../../../component/custom-redux-components';
import { CareTeamMemberOption } from './careTeamMemberOption';

const SelectCareTeamMember = props => {
  const [careTeamValue, setCareTeamValue] = useState();
  //   useEffect(() => {}, []);
  onchange = value => {
    setCareTeamValue(value);
    props.getSelectedMember(value);
  };
  return (
    <div className="select-care-team-member">
      <div className="d-flex justify-content-end align-items-center">
        <span className="hint">Select Care Team Member:</span>
        <div>
          <div className="form-group mb-0" style={{ width: '250px' }}>
            <ReduxSelect
              name={`careTeam`}
              options={props.careTeamMember}
              onChange={onchange}
              value={careTeamValue}
              formatOptionLabel={option => <CareTeamMemberOption OptionData={option} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectCareTeamMember;
