import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import ReduxDocumentsModal from './documentsModal';
import { payerPortalRequestGet } from '../../../../store/actions';
import { Loader2 } from '../../../../Components/Loader';
import DownloadDocument from '../../../customScreens/downloadDocument';

class DocumentsUploaded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimelineModalOpen: false,
    };
  }

  componentDidMount() {
    // this.props.dispatch(
    //   payerPortalRequestGet({
    //     Json: JSON.stringify({
    //       Request: [
    //         {
    //           SmartContractId: this.props.contractId,
    //           Id: null,
    //           RequestType: '30212 ',
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }

    componentWillReceiveProps({ downloadUrl, isSuccess }) {
    if (this.state.isDownloadClicked && downloadUrl && downloadUrl != this.props.downloadUrl) {
      window.open(downloadUrl);
    }
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState({
        isTimelineModalOpen: false,
      });
      this.props.dispatch(
        payerPortalRequestGet({
          Json: JSON.stringify({
            Request: [
              {
                SmartContractId: this.props.contractId,
                Id: null,
                RequestType: '30212 ',
              },
            ],
          }),
        }),
      );
    }
  }
  render() {
    const { documentUpload, isFetching } = this.props;
    return (
      <div style={{ backgroundColor: '#F8F8F8' }} className="p-4 mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Document(s)</h4>
          <button
            className="btn btn-primary"
            onClick={() => this.setState({ isTimelineModalOpen: true })}
          >
            Request Document(s)
          </button>
        </div>

        <div className="mt-3">
          {/* <Loader2 loading={isFetching} /> */}
          {documentUpload &&
            documentUpload.map((item, index) => {
              const {
                BlobUrl,
                DocumentName,
                RequestedOn,
                RequestedBy,
                UploadedFileFullName,
                DocumentGuid,
                DocUploadedDate,
              } = item;
              const fileExtension =
                UploadedFileFullName &&
                UploadedFileFullName.substr(UploadedFileFullName.lastIndexOf('.') + 1);
              return (
                <div className="requested-documents">
                  <div className="d-flex text-center my-3" style={{ backgroundColor: 'white' }}>
                    <div className="d-flex col-12 my-3 justify-content-between">
                      <div className="client-detail-box col-4 p-0 m-0 text-left">
                        <p className="client-label mb-0">Document Name</p>
                        <p className="client-text mb-0">{DocumentName && DocumentName}</p>
                      </div>
                      <div className="client-detail-box col-4 p-0 m-0 text-center">
                        <p className="client-label mb-0">Requested By(Payer)</p>
                        <p className="client-text mb-0">{RequestedBy && RequestedBy}</p>
                      </div>
                      <div className="client-detail-box col-4 p-0 m-0 text-right">
                        <p className="client-label mb-0">Requested on</p>
                        <p className="client-text mb-0">{RequestedOn && RequestedOn}</p>
                      </div>
                    </div>
                  </div>

                  <div className="doc-action">
                    <div className="doc-link">
                      <DownloadDocument
                        ID={this.props.clientId}
                        documentGuid={DocumentGuid}
                        downloadText={UploadedFileFullName}
                        isClinical={true}
                        isReadOnlyUser={this.props.isReadOnlyUser}
                        extension={fileExtension}
                        allowDownload={true}
                        isHelperIcon={true}
                        downloadIcon={false}
                      />
                    </div>
                    <div className="info">
                      <span
                        className="label-text"
                        style={{ color: '#959595', fontSize: '15px', fontStyle: 'italic' }}
                      >
                        Uploaded on :- {DocUploadedDate && DocUploadedDate}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <Modal
          open={this.state.isTimelineModalOpen}
          footer={null}
          closable={true}
          maskClosable={false}
          width={900}
          destroyOnClose={true}
          onCancel={() => this.setState({ isTimelineModalOpen: false })}
          bodyStyle={{ padding: '80px' }}
        >
          <ReduxDocumentsModal contractId={this.props.contractId} />
        </Modal>
      </div>
    );
  }
}

export default DocumentsUploaded;
