import React from 'react';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

import { riskChartGet } from '../../store/actions';

class RiskChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // barSeries: [
      //   {
      //     data: [0,50,100,0,100,50],
      //   },
      // ],
      barOptions: {
        chart: {
          type: 'bar',
          // height: 300,
          // width: '100%',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        annotations: {
          position: 'front',
          xaxis: [
            {
              x: 15,
              borderColor: '#6DEAB3',
              label: {
                borderColor: '#fff',
                offsetX: 0,
                offsetY: -5,
                style: {
                  color: '#00DC31',
                  fontSize: '12px',
                  fontWeight: 600,
                  // background: '#6DEAB3',
                },

                orientation: 'horizontal',
                text: 'Low Risk',
              },
            },
            {
              x: 50,
              borderColor: '#66C5FA',
              label: {
                borderColor: '#fff',
                offsetX: 0,
                offsetY: -5,
                style: {
                  color: '#FFAE49',
                  fontSize: '12px',
                  fontWeight: 600,
                  // background: '#66C5FA',
                },
                orientation: 'horizontal',
                text: 'Medium Risk',
              },
            },
            {
              x: 100,
              borderColor: '#FF6B6B',
              label: {
                borderColor: '#fff',
                offsetX: 0,
                offsetY: -5,
                style: {
                  color: '#FF2C2C',
                  fontSize: '12px',
                  fontWeight: 600,
                  // background: '#FF6B6B',
                },
                orientation: 'horizontal',
                text: 'High Risk',
              },
            },
          ],
        },
        plotOptions: {
          bar: {
            borderRadius: 1,
            horizontal: true,
            distributed: true,
            dataLabels: {
              enabled: false,
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        colors: [],
        dataLabels: {
          enabled: false,
        },
        // title: {
        //   text: 'Risk Factors',
        //   align: 'left',
        //   floating: true,
        // },
        xaxis: {
          tooltip: {
            enabled: false,
          },
          labels: {
            show: false,
          },
          categories: [],
        },
        tooltip: {
          enabled: false, // to hide mouse hover tolltip on bar
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return '';
              },
            },
          },
        },
      },
    };
  }
  componentDidMount() {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
          },
        ],
      }),
    };
    this.props.dispatch(riskChartGet(data));
  }
  componentWillReceiveProps({ riskChartInfo }) {
    if (
      riskChartInfo &&
      JSON.stringify(riskChartInfo) != JSON.stringify(this.props.riskChartInfo)
    ) {
      let Risk = {
        0: '#6DEAB3',
        50: '#66C5FA',
        100: '#FF6B6B',
      };

      let color = [];
      let categories = [];
      let series = [];
      riskChartInfo.map(item => {
        if (item.Value === null) return;
        categories.push(item.ShortQuestion || '');
        color.push(Risk[item.Value ? item.Value : 0]);
        series.push(item.Value === 0 ? 15 : item.Value);
        // item.Value && series.push(item.Value === 0 ? 15 : item.Value);
      });
      let barOptions = this.state.barOptions;
      let seriesData = null;
      if (series.length > 0) {
        seriesData = [{ data: [...series] }];
      }
      barOptions.xaxis.categories = categories;
      barOptions.colors = [...color];
      this.setState({
        barOptions: barOptions,
        barSeries: seriesData,
      });
    }
  }
  render() {
    return (
      <div className="col-sm-12 col-md-12 col-lg-6">
        {this.state.barSeries && this.state.barOptions && (
          <div class="card border-0 students-risk-chart-card mb-3">
            <div class="card-body block">
              <h6>Risk Factors</h6>
              <div style={{ height: '250px', overflowY: 'scroll' }}>
                <ReactApexChart
                  options={this.state.barOptions}
                  series={this.state.barSeries}
                  type="bar"
                  // height={280}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { risk_chart_get } = state;
  const { data: RiskChartData } = risk_chart_get;

  let riskChartInfo = null;

  if (RiskChartData && RiskChartData.RiskChart) {
    riskChartInfo = RiskChartData.RiskChart;
  }

  return {
    riskChartInfo,
  };
};

export default connect(mapStateToProps)(RiskChart);
