import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomModal from '../../../../../core/modal';
import AttendeesModule from './attendeesModule';
import { getGroupAppointmentNote, listGroupAppointmentNote } from '../../../../../store/actions';
import { Table, Drawer } from 'antd';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import GroupNoteBlock from './groupNoteBlock';
import AttendeeNotes from './attendeeNoteModule/individualNotes';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
class DrawerContent extends Component {
  constructor() {
    super();
    this.state = {
      manageAttendeeNote: false,
      attendeeId: '',
      groupAppointmentId: '',
    };
  }

  getDirtySubmitSucceededStatus = (isDirty, submitSucceeded) => {
    this.setState({ isDirty, submitSucceeded });
  };

  resetManageAttendeeNote = (value, GroupAppointmentId) => {
    this.setState(prevState => ({
      manageAttendeeNote: !prevState.manageAttendeeNote,
      attendeeId: value,
      groupAppointmentId: GroupAppointmentId,
    }));
    this.callDataAPI();
  };

  getGroupId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      groupId = parseInt(groupId);
    } else {
      groupId = null;
    }
    return groupId;
  };

  callDataAPI = () => {
    const { currentRowData, GroupAppointmentId } = this.props;
    this.props.dispatch(
      getGroupAppointmentNote({
        Json: JSON.stringify({
          GroupAppointmentNote: [
            {
              GroupAppointmentId: GroupAppointmentId
                ? GroupAppointmentId
                : currentRowData.GroupAppointmentId,
            },
          ],
        }),
      }),
    );
  };

  componentDidMount() {
    this.callDataAPI();
  }

  render() {
    const { manageAttendeeNote } = this.state;
    const {
      isFetchingGetGroupAppointmentNote,
      isFetching,
      isFetchingGetAppointmentNote,
      groupDrawerVisible,
      onCloseDrawer,
    } = this.props;
    const { AttendeesCount, Attendees } = this.props.GroupAppointmentNote;

    const disablePrev =
      Attendees &&
      Attendees.length &&
      Attendees.findIndex(v => v.ClientId === this.state.attendeeId) === 0;
    const disableNext =
      Attendees &&
      Attendees.length &&
      Attendees.findIndex(v => v.ClientId === this.state.attendeeId) === Attendees.length - 1;

    return (
      <React.Fragment>
        <div>
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />

          <Drawer
            className="custom-drawer"
            title="GROUP NOTE"
            placement="right"
            closable={true}
            // onClose={onCloseDrawer}
            onClose={() => {
              if (this.state.isDirty && !this.state.submitSucceeded) {
                let result = window.confirm('Are you sure you dont want to save your changes?');
                if (result == true) {
                  this.props.onCloseDrawer();
                }
              }
              if (!this.state.isDirty) {
                this.props.onCloseDrawer();
              }
              if (this.state.isDirty && this.state.submitSucceeded) {
                this.props.onCloseDrawer();
              }
            }}
            visible={groupDrawerVisible}
            width={700}
            bodyStyle={{ paddingBottom: 80 }}
            destroyOnClose={true}
            // forceRender={this.state.forceRender}
          >
            {!manageAttendeeNote && (
              <ReactToPrint
                trigger={() => (
                  <div className="text-right mt-2 mb-5">
                    {' '}
                    <button style={{ border: 'none' }}>
                      <img src="images/printSVG.svg" />
                    </button>
                  </div>
                )}
                content={() => this.componentRef}
                documentTitle="Notes"
              />
            )}
            {!manageAttendeeNote && (
              <div ref={el => (this.componentRef = el)} className="pageStyle">
                <Loader2 loading={isFetchingGetGroupAppointmentNote} />
                <GroupNoteBlock
                  onCloseDrawer={this.props.onCloseDrawer}
                  toggle={this.props.toggle}
                  callDataAPI={this.callDataAPI}
                  getDirtySubmitSucceededStatus={this.getDirtySubmitSucceededStatus}
                />
                {Attendees && Attendees.length > 0 && (
                  <AttendeesModule
                    AttendeesCount={AttendeesCount}
                    Attendees={Attendees}
                    resetManageAttendeeNote={this.resetManageAttendeeNote}
                    GroupAppointmentId={this.props.GroupAppointmentNote.GroupAppointmentId}
                  />
                )}{' '}
              </div>
            )}
            {manageAttendeeNote && (
              <div>
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '22px',
                    color: '#000000',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    onClick={() => {
                      if (this.state.isDirty && !this.state.submitSucceeded) {
                        let result = window.confirm(
                          'Are you sure you dont want to save your changes?',
                        );
                        if (result == true) {
                          this.resetManageAttendeeNote();
                        }
                      } else {
                        this.resetManageAttendeeNote();
                      }
                    }}
                  >
                    <img src="images/backArrow.svg" />
                    &nbsp;&nbsp;&nbsp; Back to Group Notes
                  </div>
                  <div>
                    <img
                      style={{ display: disablePrev ? 'none' : 'inline-block' }}
                      onClick={e => {
                        if (this.state.isDirty && !this.state.submitSucceeded) {
                          let result = window.confirm(
                            'Are you sure you dont want to save your changes?',
                          );
                          if (result == true) {
                            e.stopPropagation();
                            const currentId = this.state.attendeeId;
                            const indexOfId = Attendees.findIndex(v => v.ClientId === currentId);
                            this.setState({
                              ...this.state,
                              attendeeId: Attendees[indexOfId - 1].ClientId,
                              manageAttendeeNote: true,
                              isDirty: false,
                            });
                          }
                        } else {
                          e.stopPropagation();
                          const currentId = this.state.attendeeId;
                          const indexOfId = Attendees.findIndex(v => v.ClientId === currentId);
                          this.setState({
                            ...this.state,
                            attendeeId: Attendees[indexOfId - 1].ClientId,
                            manageAttendeeNote: true,
                            isDirty: false,
                          });
                        }
                      }}
                      src="images/sliderArrows/scroll-left.svg"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <img
                      style={{ display: disableNext ? 'none' : 'inline-block' }}
                      onClick={e => {
                        if (this.state.isDirty && !this.state.submitSucceeded) {
                          let result = window.confirm(
                            'Are you sure you dont want to save your changes?',
                          );
                          if (result == true) {
                            e.stopPropagation();
                            const currentId = this.state.attendeeId;
                            const indexOfId = Attendees.findIndex(v => v.ClientId === currentId);
                            this.setState({
                              ...this.state,
                              attendeeId: Attendees[indexOfId + 1].ClientId,
                              manageAttendeeNote: true,
                              isDirty: false,
                            });
                          }
                        } else {
                          e.stopPropagation();
                          const currentId = this.state.attendeeId;
                          const indexOfId = Attendees.findIndex(v => v.ClientId === currentId);
                          this.setState({
                            ...this.state,
                            attendeeId: Attendees[indexOfId + 1].ClientId,
                            manageAttendeeNote: true,
                            isDirty: false,
                          });
                        }
                      }}
                      src="images/sliderArrows/scroll-right.svg"
                    />
                  </div>
                </div>
                <AttendeeNotes
                  clientId={this.state.attendeeId}
                  groupAppointmentId={this.state.groupAppointmentId}
                  onCloseDrawer={this.props.onCloseDrawer}
                  resetManageAttendeeNote={this.resetManageAttendeeNote}
                  callDataAPI={this.callDataAPI}
                  getDirtySubmitSucceededStatus={this.getDirtySubmitSucceededStatus}
                />
              </div>
            )}
          </Drawer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ get_group_appointment_note }) => {
  const {
    data: getGroupAppointmentNote,
    isFetching: isFetchingGetGroupAppointmentNote,
  } = get_group_appointment_note;

  let GroupAppointmentNote = [];
  if (getGroupAppointmentNote) {
    GroupAppointmentNote = getGroupAppointmentNote.GroupAppointmentNote[0] || [];
  }

  return {
    GroupAppointmentNote,
    isFetchingGetGroupAppointmentNote,
  };
};

export default connect(mapStateToProps)(withRouter(DrawerContent));
