import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import TextareaAutosize from 'react-autosize-textarea';
import CustomModal from '../../../core/modal';
import SubmitButton from '../../../Components/submitButton';

import { addStudentNote, resetStudentNote } from '../../../store/actions';
class AddNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: null,
      isModalOpen: false,
    };
  }
  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  componentWillReceiveProps({ isNoteAdded }) {
    if (isNoteAdded && isNoteAdded != this.props.isNoteAdded) {
      this.toggle('Note Added Successfully');
      this.props.dispatch(resetStudentNote());
    }
  }

  addNote = data => {
    let validNote = data.replace(/[</>]/g, '');
    let jsonData = {
      Json: JSON.stringify({
        StudentNote: [
          {
            IsProspectNote: this.props.IsProspectNote,
            IsIntakeNote: this.props.IsIntakeNote,
            NoteTypeIds: null,
            StudentId: this.props.StudentId,
            IsConfidential: 0,
            Note: validNote,
          },
        ],
      }),
    };
    this.props.dispatch(addStudentNote(jsonData));
  };
  render() {
    const { onCancel } = this.props;
    return (
      <div class="modal-content border-1">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">Add Note</h4>
        </div>

        <div class="modal-body col-md-12 enrollment-form">
          <div class="form-group">
            <TextareaAutosize
              name="note"
              placeholder="Add Note"
              style={{
                width: '100%',
                border: '1px solid #AAACB5;',
                borderRadius: '2px',
                overflow: 'hidden',
                paddingLeft: '10px',
              }}
              onChange={e => {
                this.setState({ note: e.target.value });
              }}
              className={`form-control ${this.state.note && 'not-empty'}`}
              value={this.state.note}
            />
          </div>
        </div>
        <div class="modal-footer justify-content-end border-top-0 pb-5">
          {/* <button
            type="button"
            class="btn"
            disabled={!this.state.note}
            onClick={() => {
              this.addNote(this.state.note);
              // onCancel();
            }}
          >
            Add
          </button> */}
          <SubmitButton
            loading={this.props.isFetching}
            value="Add"
            disabled={!this.state.note}
            onClick={() => {
              this.addNote(this.state.note);
              // onCancel();
            }}
          />
        </div>
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.onCancel();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { student_note_add } = state;
  const { data, isFetching, error } = student_note_add;

  let isNoteAdded = null;

  if (data && data.success) {
    isNoteAdded = true;
  }

  return {
    isNoteAdded,
    isFetching,
  };
};
export default connect(mapStateToProps)(AddNoteModal);
