import React from 'react';
import { ReduxSelect } from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { getClientService } from '../../../../store/actions';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import moment from 'moment';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { stringDate } from '../../../../util/date';
import { Delete_Confirmation_Msg } from '../../../../app-config';
import { Table, Modal, Space, Spin, DatePicker, Tooltip } from 'antd';
import { saveAs } from 'file-saver';
import { LoadingOutlined } from '@ant-design/icons';
import PersistTableSort from '../../../../component/persistTableSort';
import ReactRangePicker from '../../../../component/Range-Picker';
import GridSearch from '../../../../Components/gridSearch';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { RangePicker } = DatePicker;

class ServicesByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createAndEditGroupModal: false,
      ServiceList: [],
      FilterBy: [],
      StartDate: moment(new Date())
        .subtract(7, 'd')
        .format('MM-DD-YYYY'),
      EndDate: moment(new Date()).format('MM-DD-YYYY'),
    };
  }
  setStoredFilter = () => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);

    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      if (JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter) {
        let sDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.startDateRange;
        let eDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.endDateRange;
        this.setState({
          StartDate: sDate,
          EndDate: eDate,
        });
        if (!JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter) {
          this.props.dispatch(
            getClientService({
              json: JSON.stringify({
                ClientServices: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                  },
                ],
              }),
            }),
          );
          return;
        } else {
          let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
          this.setState({
            filterBy,
          });
          this.props.dispatch(
            getClientService({
              json: JSON.stringify({
                ClientServices: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                    FilterBy: filterBy,
                  },
                ],
              }),
            }),
          );
        }
      } else if (
        JSON.parse(pageFilter)[`table_${this.props.name}`] &&
        JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter
      ) {
        let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
        this.setState({
          filterBy,
        });
        this.props.dispatch(
          getClientService({
            json: JSON.stringify({
              ClientServices: [
                {
                  StartDate: this.state.StartDate,
                  EndDate: this.state.EndDate,
                  FilterBy: filterBy,
                },
              ],
            }),
          }),
        );
      }
    } else {
      this.props.dispatch(
        getClientService({
          json: JSON.stringify({
            ClientServices: [
              {
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate,
              },
            ],
          }),
        }),
      );
    }
  };
  componentDidMount() {
    this.setStoredFilter();
    // var result = ProcessCombos('RCM_ServiceCode,Supervisor,UnitOfMeasurement');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }

    // this.props.dispatch(
    //   getClientService({
    //     json: JSON.stringify({
    //       ClientServices: [
    //         {
    //           Search: '',
    //           StartDate: this.state.StartDate,
    //           EndDate: this.state.EndDate,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }
  // {"ClientServices":[{"Search" :"", "StartDate":"04/01/2021", "EndDate":"04/30/2021"}]}
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({ Params, FilterBy }) {
    //Commenting params date logic to impliment last 7 days logic.
    // if (Params && JSON.stringify(Params) != JSON.stringify(this.props.Params)) {
    //   this.setState({
    //     StartDate: Params.StartDate,
    //     EndDate: Params.EndDate,
    //   });
    // }
    if (FilterBy && JSON.stringify(FilterBy) != JSON.stringify(this.props.FilterBy)) {
      this.setState({
        FilterBy,
      });
    }
  }

  downloadAsPdf = record => {
    const { dispatch } = this.props;
    this.setState({ isFileDownloading: true, isFileDownloadingId: record.ClientId });
    const me = this;
    var fileName;
    const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
    var hearders = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Accept: 'application/json, text/plain, */*',
    };

    fetch(`/api/v1/Claims/PDFDownload`, {
      method: 'POST',
      headers: hearders,
      body: JSON.stringify({
        Json: JSON.stringify({
          PDFForm: [
            {
              FormName: 'Claims1500',
              ClientId: record.ClientId,
              StartDate: record.StartDate && stringDate(record.StartDate),
              EndDate: record.EndDate && stringDate(record.EndDate),
              PractitionerId: record.PractitionerId,
            },
          ],
        }),
      }),
      // responseType: 'arraybuffer',
    })
      .then(response => {
        if (response.ok) {
          fileName = response.headers.get('FileName');
          return response.blob();
        }
      })
      .then(blob => {
        me.setState({ isFileDownloading: false });
        saveAs(blob, fileName || 'Claim.pdf');
      })
      .catch(error => {
        console.log(error);
        alert(JSON.stringify(error));
      });
  };
  // handleStartdate = Startdate => {
  //   this.setState({
  //     Startdate: Startdate,
  //   });
  // };
  // handleEnddate = Enddate => {
  //   this.setState({
  //     Enddate: Enddate,
  //   });
  // };
  storeRangeFilter = date => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && !JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        rangeFilter: { startDateRange: `${date[0]}`, endDateRange: `${date[1]}` },
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };

  storeSelectFilter = val => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        selectFilter: val,
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };

  handleChange = (key, date) => {
    this.storeRangeFilter(date);
    this.props.dispatch(
      getClientService({
        Json: JSON.stringify({
          ClientServices: [
            {
              Search: this.state.str,
              FilterBy: this.state.filterBy,
              StartDate: date[0],
              EndDate: date[1],
            },
          ],
        }),
      }),
    );
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });
  };
  onSearchChange = value => {
    this.props.dispatch(
      getClientService({
        Json: JSON.stringify({
          ClientServices: [
            {
              Search: value,
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
              FilterBy: this.state.filterBy,
            },
          ],
        }),
      }),
    );
    this.setState({
      str: value,
      StartDate: this.state.StartDate,
      EndDate: this.state.EndDate,
    });
  };
  render() {
    const tempColumns = [
      {
        title: 'Patient Id',
        // dataIndex: 'ClientId',
        dataIndex: 'DisplayClientId',
        key: 'ClientId',
        // sorter: (a, b) => isNumericComparer(a.ClientId, b.ClientId),
        sorter: (a, b) => isNumericComparer(a.DisplayClientId, b.DisplayClientId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Provider Name',
        dataIndex: 'PractionerName',
        key: 'PractionerName',
        sorter: (a, b) => isComparer(a.PractionerName, b.PractionerName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Insurance Name ',
        dataIndex: 'InsuranceName',
        key: 'InsuranceName',
        sorter: (a, b) => isComparer(a.InsuranceName, b.InsuranceName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Date',
        dataIndex: 'StartDateForDisplay',
        key: 'StartDateForDisplay',
        sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
            </div>
          );
        },
      },
      {
        title: 'End Date',
        dataIndex: 'EndDateForDisplay',
        key: 'EndDateForDisplay',
        sorter: (a, b) => isDateComparer(a.EndDateForSort, b.EndDateForSort),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
            </div>
          );
        },
      },
      {
        title: (
          <span>
            Services <br />
            Count
          </span>
        ),
        dataIndex: 'ServiceLinesCount',
        key: 'ServiceLinesCount',
        sorter: (a, b) => isNumericComparer(a.ServiceLinesCount, b.ServiceLinesCount),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        // title: 'Approved Billing Count',
        title: (
          <span>
            Approved <br />
            Billing Count
          </span>
        ),
        dataIndex: 'ApprovedForBillingCount',
        key: 'ApprovedForBillingCount',
        sorter: (a, b) => isNumericComparer(a.ApprovedForBillingCount, b.ApprovedForBillingCount),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => {
        //   return (
        //     <div className="">
        //       <span>{record.ApprovedForBillingCount ? 'Yes' : 'No'}</span>
        //     </div>
        //   );
        // },
      },
      {
        title: (
          <span>
            Approved <br />
            Batching Count
          </span>
        ),
        dataIndex: 'ApprovedForBatchingCount',
        key: 'ApprovedForBatchingCount',
        sorter: (a, b) => isNumericComparer(a.ApprovedForBatchingCount, b.ApprovedForBatchingCount),
        sortDirections: ['descend', 'ascend'],
        // render: (text, record) => {
        //   return (
        //     <div className="">
        //       <span>{record.ApprovedForBatchingCount ? 'Yes' : 'No'}</span>
        //     </div>
        //   );
        // },
      },
      // {
      //   title: 'Appointment Date',
      //   dataIndex: 'SessionDate',
      //   key: 'SessionDate',
      //   // sorter: (a, b) => isComparer(a.SessionDate, b.SessionDate),
      //   sortDirections: ['descend', 'ascend'],
      // },

      {
        title: 'Action',
        key: 'x',
        // fixed: 'right',
        width: '50px',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="mr-2">
              {this.state.isFileDownloading && this.state.isFileDownloadingId == record.ClientId ? (
                <Spin indicator={antIcon} />
              ) : (
                <span className="start-btn">
                  <Tooltip placement="top" title="Download">
                    <img
                      src="images/icons/download.svg"
                      class="service-download"
                      minWidth="90px"
                      alt="download"
                      title="download services by date"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.downloadAsPdf(record);
                      }}
                    />
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
        ),
      },
    ];
    const { StartDate, EndDate, FilterBy } = this.state;

    const { ServiceList } = this.props;
    const ExampleCustomInput = ({ value, onClick }) => {
      return (
        <input
          type="text"
          class="form-control datepicker"
          placeholder="Start Date"
          value={value}
          onClick={onClick}
        />
      );
    };
    const ExampleCustomInput2 = ({ value, onClick }) => {
      return (
        <input
          type="text"
          class="form-control datepicker"
          placeholder="End Date"
          value={value}
          onClick={onClick}
        />
      );
    };
    const dateFormat = 'YYYY-MM-DD';
    return (
      <React.Fragment>
        <div className="container-fluid my-5">
          <div className="mb-3">
            <div className="row mt-5 d-flex align-items-center">
              <div class="col-md-6 col-xl-2">
                {' '}
                <h4 className="">SERVICES</h4>
              </div>
              <div class="col-md-6 col-xl-3">
                <ReactRangePicker
                  value={[
                    moment(`${this.state.StartDate}`, 'MM-DD-YYYY'),
                    moment(`${this.state.EndDate}`, 'MM-DD-YYYY'),
                  ]}
                  format={'MM-DD-YYYY'}
                  // defaultValue={[moment(Startdate, dateFormat), moment(Enddate, dateFormat)]}
                  handleChange={this.handleChange}
                />
              </div>
              <div class="col-md-4 col-xl-3">
                <div class="form-group call-schedule service-filter">
                  <ReduxSelect
                    options={FilterBy}
                    onChange={val => {
                      this.storeSelectFilter(val);
                      this.props.dispatch(
                        getClientService({
                          Json: JSON.stringify({
                            ClientServices: [
                              {
                                Search: this.state.str,
                                FilterBy: val,
                                StartDate: this.state.StartDate,
                                EndDate: this.state.EndDate,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        filterBy: val,
                      });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={this.state.filterBy}
                    placeholder="Filter By"
                  />
                </div>
              </div>
              <div class="col-md-8 col-xl-4 d-flex align-items-center justify-content-end">
                <GridSearch onSearchChange={this.onSearchChange} />
              </div>

              {/* <div class="col-3">
                <div class="form-group">
                  <DatePicker
                    showYearDropdown
                    selected={this.state.Startdate}
                    onChange={this.handleStartdate}
                    placeholder="Start Date"
                    // placeholderText="Select Date"
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                    onChange={Startdate => {
                      this.handleStartdate(Startdate);
                    }}
                    // minDate={new Date()}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <DatePicker
                    showYearDropdown
                    selected={this.state.Enddate}
                    onChange={this.handleEnddate}
                    placeholder="End Date"
                    // placeholderText="Select Date"
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput2 />}
                    onChange={Enddate => {
                      this.handleEnddate(Enddate);
                    }}
                    // minDate={new Date()}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div> */}

              {/* <div class="col-1 px-0 d-flex align-items-center align-self-baseline justify-content-end"></div> */}

              {/* <div class="col-4 px-0 d-flex align-items-center align-self-baseline justify-content-end">
                <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  value={'Filter'}
                />
                <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn"
                  value={'New Payer'}
                  onClick={event => {
                    this.setState({
                      createAndEditGroupModal: true,
                    });
                  }}
                />
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name={this.props.name}
                    // bordered
                    columns={tempColumns}
                    dataSource={ServiceList}
                    size={'middle'}
                    scroll={{ x: 1500 }}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      // hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { get_client_service } = state;
  const { data: serviceListData, isFetching } = get_client_service;

  let ServiceList = [];
  let FilterBy = [];
  let Params = {};
  let isInserted = false;
  let isRemoved = false;

  if (serviceListData && serviceListData.ClientServices) {
    ServiceList = serviceListData.ClientServices || [];
  }
  if (serviceListData && serviceListData.FilterBy) {
    FilterBy = serviceListData.FilterBy || [];
  }
  if (serviceListData && serviceListData.Params) {
    Params = serviceListData.Params[0] || [];
  }

  return {
    ServiceList: ServiceList,
    FilterBy,
    Params,
    isFetching,
  };
};
export default connect(mapStateToProps)(ServicesByDate);
