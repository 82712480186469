import ReduxHandler from '../../core/handler';

var keys = [
  'HANDLE_ERRORS',
  'DISPLAY_ERROR',
  'LOGIN',
  'FORGET_PASSWORD',
  'RESET_PASSWORD',
  'COMBOS',
  'FILTER_COMBO',
  'SMARTCOMBOS',
  'DASHBOARD_GET',
  'STUDENTS',
  'STUDENT_ADD',
  'STUDENT_GET',
  'SURVEY_STUDENTS_GET_ALL_PENDING',
  'SURVEY_QUESTION_GET_MULTIPLE',
  'SURVEY_ANSWER_UPSERT',
  'TODO_GET_ALL',
  'TODO_GET_ALL_BY_STUDENT',
  'TODO_ADD',
  'STUDENT_TASK_UPSERT',
  'STUDENT_PIN_UPSERT',
  'STUDENT_VALIDATE_PIN',
  'ACTIVITY_GET_ALL',
  'ACTIVITY_GET_ALL_BY_STUDENT',
  'SURVEY_ASSIGN',
  'SURVEY_GET_TO_ASSIGN',
  'STUDENT_GET_ALL_COURSES',
  'STUDENT_MARK_COMPLETE',
  'STUDENT_UNASSIGN',
  'STUDENT_ADD_EXISTING_TASK',
  'STUDENT_DISCHARGE',
  'STUDENT_REINSTATE',
  'STUDENT_NOTE_ADD',
  'STUDENT_TASK_UNASSIGN',
  'STUDENT_TASK_MARK_COMPLETE',
  'SURVEY_TASK_MARK_IN_COMPLETE',
  'STUDENT_COURSE_ASSIGN',
  'GET_REPORT_DATA',
  'COURSE_GET_ALL',
  'COURSE_GET',
  'COURSE_UPSERT',
  'COURSE_DELETE',
  'STAFF_GET_ALL',
  'STAFF_GET',
  'STAFF_UPSERT',
  'STAFF_DELETE',
  'CENTER_GET_ALL',
  'CENTER_GET',
  'GET_CENTER_FIELD_BY_CENTER',
  'CENTER_UPSERT',
  'CENTER_DELETE',
  'STUDENT_SURVEY_DASHBOARD_GET_JSON',
  'LOG_DEVICE',
  'LOG_ERROR',
  'ANNOUNCEMENT_GET_ALL',
  'ANNOUNCEMENT_GET_JSON',
  'ANNOUNCEMENT_UPSERT',
  'ANNOUNCEMENT_DELETE',
  'UPDATE_STAFF_EMAIL',
  'UPDATE_STAFF_PASSWORD',
  'UPDATE_STAFF_CENTER',
  'LOAD_DEFAULT_VALUES',
  'LOAD_MENU',
  'LOGOUT',
  'SURVEY_ANSWERS_GET',
  'STUDENT_GET_PROFILE_DASHBOARD_DATA',
  'STUDENTS_SEARCH',
  'USER_ROLE_LIST',
  'LIST_JOB_TITLE',
  'UPDATE_JOB_TITLE',
  'UPDATE_ROLE',
  'DELETE_ROLE',
  'DELETE_JOB_TITLE',
  'UPDATE_ROLE_PERMISSIONS',
  'UPDATE_JOB_TITLE_SYNC',
  'GET_CONTRACT',
  'UPSERT_CONTRACT',
  'DELETE_CONTRACT',
  'GET_CONTRACT_TEMPLATE',
  'UPSERT_CONTRACT_TEMPLATE',
  'GET_STUDENT_CONTRACT_TEMPLATE',
  'UPLOAD_STAFF_IMAGE',
  'CREATE_CONTRACT_FROM_TEMPLATE',
  'ADD_FAVORITE_TEMPLATE',
  'REMOVE_FAVORITE_TEMPLATE',
  'CLOSE_CONTRACT',
  'USER_FEEDBACK',
  'IS_LICENSE_AVAILABLE',
  'GET_DASHBOARD_LOAD_APP_USERS',
  'GET_LOAD_USER_INFO',
  'GET_VALIDATE_IMAGE',
  'GET_LICENSE_ACTIVITES',
  'GET_CURRENT_LICENSES',
  'GET_LICENSES_INVOICE',
  'GET_LICENSES_RECEIPT',
  'ADD_LICENSES',
  'START_FREE_LICENSE',
  'GET_PAYMENT_INFO',
  'IS_ACH_VALIDATION',
  'ACH_VALIDATE',
  'GET_BILLING_INFO',
  'UPDATE_PAYMENT_METHODE',
  'ADD_NEW_APPLICANT',
  'GET_APP_SUBSCRIBERS',
  'GET_APP_SUBSCRIBERS_DASHBOARD',
  'GET_APP_SUBSCRIBERS_PROFILE',
  'GET_APP_SUBSCRIBERS_CHAT',
  'GET_APP_SUBSCRIBERS_CHAT_COACH',
  'GET_APP_SUBSCRIBERS_ACTIVITIES',
  'APP_SUBSCRIBERS_SURVEY_ASSIGN',
  'APP_SUBSCRIBERS_SURVEY_UNASSIGN',
  'APP_SUBSCRIBERS_INSERT_NOTE',
  'APP_SUBSCRIBERS_ADD_TASK',
  'APP_SUBSCRIBERS_DELETE_TASK',
  'APP_SUBSCRIBERS_USER_SEARCH',
  'APP_SUBSCRIBERS_COMPLETE_TASK',
  'APP_SUBSCRIBERS_ADD_COURSE',
  'APP_SUBSCRIBERS_DELETE_COURSE',
  'APP_SUBSCRIBERS_COMPLETE_COURSE',
  'APP_SUBSCRIBERS_UPDATE_CASE',
  'GET_LIST_MOBILE_APP_ROLE',
  'SP_LIST_COMBOS',
  'APP_SUBSCRIBERS_MAP_HISTORY',
  'APP_INVITE',
  'GET_MOBILE_APP_DOWNLOAD_STATUS',
  'UPDATE_MOBILE_APP_ROLE',
  'ADD_NOTE_TYPE',
  'CHANGE_APP_ROLE',
  'TWO_STEP_AUTH',
  'VALIDATE_PASS_CODE',
  'APP_INVITE_DASHBOARD',
  'GET_STAFF_SHIFT_LOG',
  'ADD_STAFF_SHIFT_LOG',
  'GET_ALL_CALL_SESSION',
  'ADD_CALL_SESSION',
  'GET_ALL_VACATION',
  'ADD_VACATION_DAYS',
  'GET_ONE_CALL_TIMESLOTS',
  'GET_ONE_CALL_SESSION',
  'INVITE_ONE_CALL_SESSION',
  'CANCEL_ONE_CALL_SESSION',
  'GET_STRIPE_API_KEY',
  'EDIT_GROUP_CALL_SESSION',
  'GET_CALL_SESSION_VIDEO',
  'GET_CALL_SESSION_VIDEO_URL',
  'STAFF_REACTIVATE',
  'GET_REPORTS',
  'GET_PROMO_CODE',
  'GET_SMALLGROUP',
  'ADD_SMALLGROUP',
  'GET_COUNSELOR',
  'GET_CLIENT_COUNSELOR',
  'COMBOS_DYNAMIC',
  'SEND_VOUCHER_CODE',
  'GET_SMALL_GROUP_LIST',
  'UPLOAD_LEADER_PHOTO',
  'MARK_SYSTEM_OBSERVATION',
  'APP_MARK_SYSTEM_OBSERVATION',
  'GET_ANALYTICS',
  'GET_ACTIVE_CLIENT',
  'IS_MANAGE_LICENSE_REQUIRED',
  'GET_SMALL_GROUP_USERS',
  'CHECK_STAFF_PRO_BIO',
  'UPDATE_STAFF_PRO_BIO',
  'GET_NOTES',
  'GET_CATEGORY_CLIENT',
  'GET_CLIENT_DEMOGRAPHIC',
  'GET_COST_OF_CARE',
  'GET_BEHAVIORAL_RATING',
  'GET_SCORE_IMPROVEMENT',
  'GET_PROVIDER_PERFORMANCE',
  'GET_TRIGGER_LOCATION',
  'GET_SERVICE_DELIVERED',
  'GET_REFERRAL_SOURCE',
  'GET_DOCUMENT_LIST',
  'ARCHIVE_STUDENT_DOCUMENT',
  'UPLOAD_DOCUMENT',
  'DOWNLOAD_DOCUMENT',
  'MENU_CONTRACT_TEMPLATE',
  'ARCHIVE_CONTRACT_TEMPLATE',
  'NEWS_UPSERT',
  'GET_LIST_NEWS',
  'GET_NEWS',
  'DELETE_NEWS',
  'GET_EMBED_INFO',
  'GET_GENDER_PIE_CHART_EMBED_INFO',
  'GET_REFERNCE_SOURCE_TIME_IN_TREATMENT_REPORT',
  'GET_CLIENT_LIST',
  'GET_CLIENT_DETAIL',
  'CLIENT_CHART_LIST_GET',
  'CLIENT_CHART_REPORT',
  'UPSERT_PATIENT_ENROLLMENT',
  'UPSERT_CLIENT_INSURANCE',
  'UPSERT_CLIENT_MEDICAL',
  'UPSERT_CLIENT_PAYMENT',
  'UPSERT_PROVIDER_ENROLLMENT',
  'UPSERT_APPOINTMENT',
  'LIST_APPOINTMENT',
  'GET_APPOINTMENT',
  'DELETE_APPOINTMENT',
  'COMBOS_MEDICATE',
  'LIST_SURVEY_QUESTION_CATEGORY',
  'LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT',
  'LIST_SURVEY_QUESTION_ASSESSMENT',
  'COMBOS_PRACTITIONER',
  'COMBOS_PRACTITIONER_OFFICE',
  'UPSERT_GROUP',
  'LIST_GROUP',
  'GET_GROUP',
  'DELETE_GROUP',
  'UPSERT_CLIENT_GROUP',
  'GET_CLIENT_GROUP',
  'UPSERT_GROUP_APPOINTMENT',
  'UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON',
  'GET_LIST_CLIENT_GROUP',
  'PATIENT_COMBO_GROUP',
  'DEACTIVATE_GROUP_APPOINTMENT',
  'COMBO_CENTER_CONSENT',
  'UPSERT_CLIENT_CONSENT',
  'LIST_CLIENT_CONSENT',
  'DEACTIVATE_CLIENT_CONSENT',
  'DEACTIVATE_CLIENT_SCREENING',
  'LOAD_JUDICIAL_DASHBOARD',
  'GET_JUDICIAL_USER_LIST',
  'UPLOAD_DOCUMENT_USING_MULTIPART',
  'UPSERT_CLIENT_DOCUMENT',
  'LIST_CLIENT_DOCUMENT',
  'DOWNLOAD_CLIENT_DOCUMENT',
  'ARCHIVE_DOCUMENT',
  'LIST_GROUP_MEMBERSHIP',
  'GET_SURVEY_SCORE',
  'GET_CONSENT_FORM_TEMPLATE',
  'GET_COMBO_FACEILITY',
  'LOAD_APP_SUB_SURVEY_DASHBOARD',
  'APP_SUB_SURVEY_ANS_GET',
  'CENTER_PROGRAM_TYPE_GET',
  'DOWNLOAD_CLIENT_CONSENT',
  'COMBO_PRACTITIONER_ALL',
  'COMBO_FACILITY_ALL',
  'SAVE_CLIENT_CONSENT',
  'GET_TREATMENT_PLAN',
  'GET_DIAGNOSIS',
  'UPSERT_TREATMENT_PLAN_PROBLEM',
  'UPSERT_MEDICAL_ANALYSIS',
  'UPSERT_TREATMENT_PLAN_GOAL',
  'UPSERT_TREATMENT_PLAN_OBJECTIVE',
  'UPSERT_TREATMENT_PLAN_INTERVENTION',
  'DELETE_TREATMENT_PLAN',
  'GET_DIAGNOSIS_DESCRIPTION',
  'GET_DIAGNOSIS_CODE',
  'LIST_TREATMENT_PLAN',
  'COMBO_PROBLEM_TEMPLATE',
  'COMBO_GOAL_TEMPLATE',
  'RECURRING_COUNT',
  'GET_STAFF_PERMISSION',
  'UPDATE_STAFF_PERMISSION',
  'LOAD_CASE_PROFILE',
  'LOAD_LOCATION_AUTH_HISTORY',
  'SCREEN_AUTHORIZATION',
  'LIST_CONSENT_FORM',
  'UPSERT_TREATMENT_PLAN_DIMENSION',
  'SAVE_CONSENT_SIGN',
  'CONSENT_FORM_DOWNLOAD',
  'LIST_MEETING',
  'UPSERT_PROGRESS_GROUP_NOTE',
  'GET_PROGRESS_NOTE_GROUP',
  'GET_USER_NOTE_PATIENT',
  'GET_USER_NOTE_COMMENT',
  'ADD_USER_NOTE_COMMENT',
  'GET_USER_NOTE_EMOJI',
  'ADD_USER_NOTE_EMOJI',
  'COMBO_INTERVENTION_TEMPLATE',
  'GET_PROGRESS_NOTE_FOR_INTERVENTION',
  'UPSERT_PAYER',
  'LIST_PAYER',
  'DELETE_PAYER',
  'GET_PAYER',
  'COMBO_PAYER',
  'UPSERT_SERVICE_CODE',
  'LIST_SERVICE_CODE',
  'DELETE_SERVICE_CODE',
  'GET_SERVICE_CODE',
  'COMBO_CODE',
  'GET_CODE_DESCRIPTION',
  'APPOINTMENT_NOTE_LIST',
  'UPSERT_APPOINTMENT_NOTE',
  'GET_APPOINTMENT_NOTE',
  'COMBO_PLACE_OF_SERVICE',
  'LIST_SERVICE_LINE',
  'GET_SERVICE_LINE',
  'UPSERT_SERVICE_LINE',
  'UPSERT_APPOINTMENT_NOTE_ADDENDUM',
  'LIST_GROUP_APPOINTMENT_NOTE',
  'GET_GROUP_APPOINTMENT_NOTE',
  'COMBO_TREATMENT_PLAN_INTERVENTION',
  'LIST_FEE_SCHEDULE',
  'GET_FEE_SCHEDULE',
  'UPSERT_FEE_SCHEDULE',
  'GET_TREATMENT_PLAN_GRAPH',
  'LIST_GROUP_SERVICE_LINE',
  'GET_GROUP_SERVICE_LINE',
  'GET_GROUP_BANNER',
  'UPSERT_GROUP_SERVICE_LINE',
  'UPSERT_GROUP_APPOINTMENT_NOTE',
  'UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM',
  'LIST_SURVEY_QUESTION_DISCHARGE',
  'UPSERT_APPOINTMENT_NOTE_INTERVENTION',
  'MARK_AS_COMPLETE',
  'GET_SERVICE_LINE_COST',
  'UPSERT_PIN',
  'VALIDATE_STAFF_PIN',
  'LOAD_SMALLGROUP_DASHBOARD',
  'UPSERT_TREATMENT_PLAN',
  'LIST_CLIENT_DIAGNOSIS',
  'GET_CLIENT_DIAGNOSIS',
  'DELETE_CLIENT_DIAGNOSIS',
  'VALIDATE_PIN_EXISTS',
  'CLONE_DIAGNOSIS',
  'CLONE_TREATMENT_PLAN_DIAGNOSIS',
  'GET_BREATHALYZER_SCHEDULE',
  'LIST_BREATHALYZER_SCHEDULE',
  'UPSERT_BREATHALYZER_SCHEDULE',
  'RCM_CLIENT_MERGE',
  'PRE_VALIDATE_PIN',
  'VALIDATE_CLIENT',
  'GET_APP_ADVISOR',
  'GET_MEDIA_VIDEO_URL',
  'GET_DASHBOARD_MENU',
  'BREATHALYZER_PUSH_A_TEST',
  'GET_SERVICE_CODE_BUNDLE',
  'LIST_SERVICE_CODE_BUNDLE',
  'GET_STUDENT_NOTE',
  'GET_STUDENT_NOTE_COMMENT',
  'ADD_STUDENT_NOTE_COMMENT',
  'ADD_STUDENT_NOTE_EMOJI',
  'GET_STUDENT_NOTE_EMOJI',
  'GET_TREATMENT_PLAN_ACCORDIN',
  'BREATHALYZER_ENABLE',
  'GET_BREATHALYZER_TEST_RESULT',
  'GET_BREATHALYZER_TEST_UPCOMING',
  'BREATHALYZER_TEST_UPCOMING_TOGGLE',
  'GET_ADVISOR_COST',
  'UPSERT_ADVISOR_COST',
  'CLAIMS_PDF_DOWNLOAD',
  'GET_CLIENT_SERVICE',
  'GET_LIST_TIMELINE',
  'GET_STUDENT_BREATHALYZER_TEST_RESULT',
  'GET_DASHBOARD_DAILY_SCHEDULES',
  'GET_DASHBOARD_DAILY_CASE_LOAD',
  'GET_DASHBOARD_ALERT',
  'GET_CENTER_ADVISOR_DEFAULT_COSTS',
  'UPDATE_CENTER_ADVISOR_DEFAULT_COST',
  'ADD_CLIENT_INSURANCE_ELIGIBILITY',
  'EDIT_CLIENT_INSURANCE_ELIGIBILITY',
  'DELETE_CLIENT_INSURANCE_ELIGIBILITY',
  'GET_STAFF_PRACTITIONER',
  'DISCHARGE_SUMMERY_SAVE',
  'GET_CLIENT_APPOINTMENT',
  'GET_GROUP_APPOINTMENT',
  'UNSIGN_FORM',
  'GET_FILTERS',
  'LIST_LAB_ORDER',
  'GET_LAB_ORDER',
  'UPSERT_LAB_ORDER',
  'LAB_ORDER_LABEL_GET',
  'COMBO_DIAGNOSIS_LIST',
  'GET_MEDICATION_NOTE',
  'UPSERT_CLIENT_GROUP_APPOINTMENT',
  'SIGN_GROUP_APPOINTMENT_NOTE',
  'LIST_SERVICE_LINE_PAYMENT',
  'GET_SERVICE_LINE_PAYMENT',
  'UPSERT_SERVICE_LINE_PAYMENT',
  'DELETE_SERVICE_LINE_PAYMENT',
  'COMBO_SUPERVISOR',
  'LOCATION_DAY_HISTORY',
  'UPSERT_PAYMENT_CLIENT',
  'GET_INSURANCE_PRIORITY_ORDER',
  'UPSERT_INSURANCE_PRIORITY_ORDER',
  'LIST_CW_PAYMENT',
  'GET_CW_PAYMENT',
  'DELETE_CW_PAYMENT',
  'PAST_MEETING_INFO',
  'LIST_PAST_MEETING_INFO',
  'COMBO_APPOINTMENT_TREATMENT_PLAN',
  'LINK_APPOINTMENT',
  'APPOINTMENT_LINK_GET',
  'GET_BASIC_PROFILE',
  'GET_INVITED_LIST',
  'INVITE_RESEND',
  'SUSPICIOUS_TERM_ACK',
  'GET_BROADCAST_FILTER',
  'SEND_BROADCAST',
  'GET_SERVICE_LINE_DIAGNOSIS',
  'UPSERT_SERVICE_LINE_DIAGNOSIS',
  'COMBO_BROADCAST_TEMPLATE',
  'UPSERT_BROADCAST_TEMPLATE',
  'DELETE_NOTE_TYPE',
  'GET_FORM_FIELD_META_DATA',
  'LIST_CLIENT_AUTHORIZATION',
  'GET_CLIENT_AUTHORIZATION',
  'DELETE_CLIENT_AUTHORIZATION',
  'UPSERT_CLIENT_AUTHORIZATION',
  'GET_NOTE_TYPE_ARCHIVE',
  'GET_NOTE_TYPE_LIST',
  'UPSERT_COUPON_CODE',
  'DELETE_COUPON_CODE',
  'GET_COUPON_CODE',
  'CENTER_REACTIVATE',
  'GENERATE_CALL_TOKEN',
  'BADGE_COMBO_GET',
  'BADGE_UPDATE',
  'PANAS_SCALE_MOODS_GET',
  'PANAS_TAG_UPSERT',
  'BED_ASSIGNMENT_GET_ALL',
  'BED_ASSIGNMENT_UPSERT',
  'UPCOMING_DISCHARGES_GET_ALL',
  'UPCOMING_ADMISSIONS',
  'ADMISSION_CANCEL',
  'PANAS_TAG_DELETE',
  'DYNAMIC_GRID_LIST',
  'GET_GRID',
  'GET_GRID_DATA',
  'GRID_ON_ROW_CLICK_GET',
  'UPSERT_FORM_DATA',
  'FORM_DATA_GET',
  'GET_PREDEFINED_TASK',
  'GET_PREDEFINED_TASK_ARCHIVE',
  'DELETE_PREDEFINED_TASK',
  'UPSERT_PREDEFINED_TASK',
  'LIST_SURVEY_QUESTION_CATEGORY_GET_ALL',
  'GET_ADVISOR_MEMBER_TRENDS',
  'UPDATE_SMALL_GROUP_ATTENDENCE',
  'UPDATE_USER_PROFILE',
  'GET_ADVISOR_MEMBER_TRENDS_HISTORY',
  'MOBILE_INVITE_OPTIONS_LOAD',
  'GET_APP_USER_SUBSCRIPTION',
  'GET_SERVICE_OPTION',
  'GET_SERVICE_ADVISOR_LIST',
  'SET_SERVICE_ADVISOR',
  'UPDATE_CANCEL_SERVICE',
  'SET_MEMBERSHIP',
  'SET_SERVICE_SUBSCRIPTION',
  'CANCEL_MEMBERSHIP',
  'UPSERT_CLIENT_PIN',
  'GET_STUDENT_TIMELINE',
  'CLIENT_STUDENT_SYNCHRONIZE',
  'UPDATE_USER_MEMBERSHIP',
  'GET_CLIENT_TIMELINE',
  'COMBO_APP_ADVISOR',
  'GET_SURVEY_CATEGORY_QUESTIONS',
  'UPSERT_BLOCK_APPOINTMENT',
  'UPSERT_CLIENT_MEMO',
  'GET_CLIENT_MEMO',
  'DELETE_CLIENT_MEMO',
  'VALIDATE_SSN',
  'USER_APP_LINK_VALIDATE',
  'CLIENT_CENTER_MOVE',
  'FORM_TAB_LIST_GET',
  'UPSERT_CLIENT_CARE_TEAM',
  'CLIENT_CARE_TEAM_GET',
  'GET_CLIENT_CARE_TEAM_LIST',
  'CLIENT_CARE_TEAM_DELETE',
  'CARE_TEAM_MEMBER_COMBO',
  'EXTERNAL_PROVIDER_LIST_GET',
  'EXTERNAL_PROVIDER_GET',
  'EXTERNAL_PROVIDER_UPSERT',
  'EXTERNAL_PROVIDER_FILTER_GET',
  'EXTERNAL_PROVIDER_AUTHENTICATION',
  'EXTERNAL_STAFF_ARCHIVE',
  'ORGANIZATION_CENTER_FAMILY_COMBO',
  'UPSERT_CLIENT_VITAL',
  'UPSERT_MEDICATION_LOG',
  'GET_MEDICATION_LOG',
  'GET_CLIENT_VITAL',
  'GET_MEDICATION_LOG_LIST',
  'GET_CLIENT_VITAL_LIST',
  'GET_CH_PATIENT_LIST',
  'GET_CH_PATIENT_SERVICE_SEND',
  'PUSH_CLAIMS_TO_CH',
  'PUSH_SINGLE_CLAIM_TO_CH',
  'GET_BILLABLE_CLAIMS',
  'CLONE_FORM',
  'CLEAR_SIGNATURE',
  'UPSERT_STAFF_VIEWED_ACTIVITY',
  'DELETE_STUDENT_NOTE',
  'STAFF_ACCESSED_TAB_GET',
  'STUDENT_STAFF_ACCESSED_TAB_GET',
  'APP_ANNOUNCEMENT_UPSERT',
  'APP_ANNOUNCEMENT_LIST_GET_ALL',
  'APP_ANNOUNCEMENT_GET',
  'TOKEN_MANAGEMENT_LIST_GET_ALL',
  'TOKEN_MANAGEMENT_STATS_GET',
  'RESOURCE_UPSERT',
  'RESOURCE_LIST_GET_ALL',
  'RESOURCE_GET',
  'ALLERGEN_SEARCH',
  'DRUG_SEARCH',
  'CLIENT_ALLERGY_GET',
  'ALLERGY_OPTIONS_GET',
  'CLIENT_ALLERGY_UPSERT',
  'CLIENT_ALLERGY_GET_ALL',
  'CLIENT_ALLERGY_DELETE',
  'CLIENT_MEDICATION_UPSERT',
  'CLIENT_MEDICATION_GET_ALL',
  'CLIENT_MEDICATION_DELETE',
  'DRUG_PACKAGE_GET_ALL',
  'PRESCRIBE_NOTIFICATION_GET',
  'PRESCRIBE_UPDATE',
  'PATIENT_SIGNATURE_SAVE',
  'PRESCRIPTION_LIST_GET',
  'USER_FEATURE_FLAG_GET',
  'AUGMENTED_CLIENT_INSIGHTS_GET',
  'RISK_CHART_GET',
  'AUGMENTED_SUMMARY_GET',
  'EPRESCRIBE_SINGLE_PATIENT_MODE_SSO',
  'CLIENT_REACTIVATE',
  'CLIENT_BANNER_GET',
  'LAB_ORDER_CANCEL',
  'DRUG_DETAIL_SEARCH',
  'LAB_RESULT_GET',
  'LAB_RESULT_DOCUMENT_GET',
  'LAB_ORDER_DELETE',
  'ADD_SUPPORT_GROUP',
  'LOAD_SUPPORT_GROUP',
  'LOAD_PEER',
  'LOAD_USER_ENGAGEMENT',
  'STUDENT_BANNER',
  'PRE_INTAKE_SURVEY_ANSWERS_GET',
  'PRE_INTAKE_HISTORY_GET',
  'SURVEY_LATEST_ANSWER_DATE_UPDATE',
  'APP_USER_DEMOGRAPHIC_UPSERT',
  'APP_USER_DEMOGRAPHIC_GET',
  'REMOVE_SUPPORT_GROUP',
  'CONCERNED_PERSON_LIST',
  'CONCERNED_PERSON_DASBOARD_GRAPH_GET',
  'ADVISOR_SEARCH',
  'LIST_CLIENT_GET_ALL_WITH_PAGINATION',
  'APP_USER_LIVE_LOCATION_GET',
  'STUDENT_SURVEY_LIST_GET',
  'GPT3_ENROLL_SURVEYS',
  'GET_MULTIPLE_SURVEY_AUGMENTED_SUMMARY',
  'STUDENT_NOTE_LIST_GET',
  'GPT3_ANSWER_STATUS_UPSERT',
  'AUGMENT_WITH_AI',
  'AUGMENT_WITH_AI_GET',
  'GRID_FILTER_GET',
  'GET_GROUP_DROPDOWN',
  'GET_PROSPECT_DASHBOARD_GRAPH',
  'MEDICAL_PROGRAM_UPSERT',
  'MEDICAL_PROGRAM_GET',
  'MEDICAL_PROGRAM_DELETE',
  'REGION_INSERT',
  'REGION_GET',
  'REGION_DELETE',
  'STAFF_ACCESSED_TAB_ENT_GET',
  'SURVEY_QUESTION_LIBRARY_GET',
  'SURVEY_QUESTION_CONFIG_GET',
  'SURVEY_QUESTION_SECTION_UPSERT',
  'SURVEY_QUESTION_SECTION_COMBO_GET',
  'SURVEY_QUESTION_CONFIG_UPSERT',
  'SURVEY_QUESTION_CATEGORY_CONFIG_UPSERT',
  'SURVEY_QUESTION_CONFIG_BANNER_GET',
  'STUDENT_QUEUE_LIST_GET',
  'STUDENT_QUEUE_DELETE',
  'STUDENT_AVAILABLE_SPACES_LIST_GET',
  'STUDENT_QUEUE_INSERT',
  'CLIENT_SCREENING_STATUS_UPDATE',
  'STUDENT_ASSOCIATE_INSERT',
  'STUDENT_ASSOCIATE_LIST_GET',
  'STUDENT_ASSOCIATE_DELETE',
  'CENTER_OPTION_GET',
  'COMBO_PRACTITIONER_CENTER_GET',
  'PROSPECT_ARCHIVE',
  'TOKENS_STUDENT_TRANSACTION_HISTORY',
  'TOKENS_COUNSELOR_TRANSACTION_HISTORY',
  'TOKENS_STUDENT_TRANSACTION_HISTORY_GET_ALL',
  'TOKEN_MANAGEMENT_COUNSELOR_TRANSACTION_HISTORY_GET_ALL',
  'CENTER_OVER_HEAD_COST_GET',
  'CENTER_OVER_HEAD_COST_UPSERT',
  'CENTER_LABER_COST_GET',
  'CENTER_LABER_COST_GET_UPSERT',
  'CENTER_CONSUMABLE_GET_ALL',
  'CENTER_CONSUMABLE_GET',
  'CENTER_CONSUMABLE_GET_UPSERT',
  'CENTER_CONSUMABLE_DELETE',
  'DASHBOARD_PENDING_SURVEY_LIST_GET',
  'CLIENT_EMBEDDING_PREPARE',
  'ASK_QUESTION',
  'CHAT_GET',
  'YOUUNIVERSE_CONVERSATION_GET',
  'YOUUNIVERSE_CHAT_GET',
  'YOUUNIVERSE_CHAT_POST',
  'LAB_ORDER_PANEL_CODE_COMBO_GET',
  'DEFAULT_PRACTOTIONER_FACILITY_GET',
  'LICENSE_NOTIFY_CENTER_ADMIN',
  'GOAL_LIBRARY_UPSERT',
  'GOAL_LIBRARY_ARCHIVE',
  'GOAL_LIBRARY_GET_ALL',
  'GOAL_LIBRARY_GET',
  'COMBO_SERVICE_CODE',
  'CENTER_TOKEN_ASSIGN_UPDATE',
  'SERVICE_CODE_BUNDLE_UPSERT',
  'SERVICE_CODE_BUNDLE_DELETE',
  'SERVICE_CODE_BUNDLE_GET_ALL',
  'SERVICE_CODE_BUNDLE_GET',
  'SMART_CONTRACT_UPSERT',
  'VBC_LEDGER_REMARK_UPSERT',
  'SMART_CONTRACT_GET',
  'SMART_CONTRACT_REQUEST_GET',
  'SMART_CONTRACT_REQUEST_UPDATE',
  'CLIENT_INSURANCE_GET',
  'PAYER_PORTAL_DASHBOARD',
  'PAYER_PORTAL_AUTHORIZATION_GET',
  'PAYER_PORTAL_AUTHORIZATION_STATUS_UPSERT',
  'PAYER_PORTAL_REQUEST_INSERT',
  'STUDENT_RECOMMENDATIONS_LIST_GET',
  'YOUUNIVERSE_BOOKMARK_QUESTION_UPSERT',
  'YOUUNIVERSE_BOOKMARK_QUESTION_LIST_GET',
  'YOUUNIVERSE_CHAT_ATTRIBUTE_UPDATE',
  'YOUUNIVERSE_CONVERSATION_DELETE',
  'YOUU_CHAT_EDITED_ANSWER_UPSERT',
  'SMART_CONTRACT_LEDGER_GET',
  'SMART_CONTRACT_UPDATE',
  'CLIENT_STATUS_REPORT',
  'USER_TRIGGER_REPORT',
  'SURVEY_QUESTION_LIBRARY_QUESTION_LIST_GET',
  'SURVEY_QUESTION_LIBRARY_QUESTION_DELETE',
  'SURVEY_QUESTION_LIBRARY_SECTION_UPDATE',
  'SURVEY_QUESTION_LIBRARY_QUESTION_UPSERT',
  'STUDENT_NOTE_GET_ALL',
  'ASK_FOLLOW_UP_INITIATE',
  'SURVEY_QUESTION_LIBRARY_QUESTION_GET',
  'SIGNED_NOTE_GET',
  'DASHBOARD_TASK_LIST_GET',
  'DASHBOARD_COURSE_LIST_GET',
  'DASHBOARD_CONTRACT_LIST_GET',
  'DASHBOARD_ALERT_LIST_GET',
  'CENTER_LABOR_BURDEN_GET',
  'CENTER_LABOR_BURDEN_UPSERT',
  'STUDENT_TIMER_UPSERT',
  'GET_APPOINTMENT_CLIENT',
  'APPOINTMENT_NOTE_LIST_GET',
  'COMBO_SERVICE_CODE_GET',
  'NOTE_TYPE_LIST_GET',
  'NOTE_TYPE_UPSERT',
  'STUDENT_MERGE_COMBO_LIST_GET',
  'STUDENT_MERGE_DETAILS_GET',
  'STUDENT_MERGE_SECONDARY_DETAILS_GET',
  'STUDENT_MERGE',
  'STUDENT_TWILIO_TRANSCRIPT_GET',
  'POWER_BI_REPORT_LIST_GET',
  'APPOINTMENT_PRE_SELECTED_GET',
  'CALL_NOTE_AUGMENTATION',
  'CALL_SUMMARY_AUGMENT',
  'GPT_AUGMENTATION_UPDATE',
  'CENTER_FIELD_GET',
  'CENTER_FIELD_UPSERT',
  'CLIENT_SEND_MOBILE_APP_INVITE',
  'SMART_CONTRACT_PATIENT_LIST_GET',
  'SMART_CONTRACT_LIST_GET',
  'TERMS_CONDITION_LIST_GET',
  'TERMS_CONDITION_UPSERT',
  'TERMS_CONDITION_GET_ALL_INFO_JSON',
  'CENTER_CONFIG_GET',
  'CENTER_CONFIG_GET_BY_ID',
  'PAYER_PRICE_DETAILS_GET',
  'PAYER_PORTAL_REQUEST_UPSERT',
  'CLIENT_INSURANCE_COMBO_GET',
  'SMART_CONTRACT_TERMS_CONDITION_GETALLINFO_JSON',
  'TERMS_CONDITION_DELETE',
  'TERMS_CONDITION_COMBO_GET',
  'COMBO_LAB_ORDER_PRACTITIONER',
  'SMART_CONTRACT_PAYERS_LIST_GET',
  'GROUP_SERVICE_LINE_APPROVE_ALL_BILLING_OR_BATCHING',
  'PAYER_PORTAL_REQUEST_GET',
  'PAYER_TERMS_CONDITIONS_STATUS_UPSERT',
  'CLIENT_CARE_TEAM_LIST_GET',
  'DASHBOARD_MOBILE_USER_GET_ALL',
  'ENT_MOBILE_USER_DASHBOARD_GET',
  'STICKEY_NOTE',
  'RECOVERY_CAPITAL_QUADRANT_GRAPH_GET',
  'CLIENT_MERGE_GET',
  'CLIENT_MERGE_SECONDARY_GET',
  'CLIENT_COMBO_GET_ALL',
  'CLIENT_MERGE',
  'CLINICAL_TO_INTAKE_MOVE_PATIENT',
  'STAFF_LICENSE_AGREEMENT_UPDATE',
  'SMART_CONTRACT_TERMS_CONDITION_UPSERT',
  'USER_TAG_TYPE_INSERT',
  'USER_TAG_TYPE_LIST_GET',
  'USER_TAG_TYPE_COMBO_LIST_GET',
  'USER_TAG_TYPE_UPDATE',
  'LIST_PROSPECT_APPOINTMENT',
  'RECOVERY_CAPITAL_TREND_GRAPH_GET',
  'CARE_TEAM_ASSIGN',
  'SERVICE_CODE_CARE_TEAM_GET',
  'SMART_CONTRACT_TAB_GET',
  'SMART_CONTRACT_DISTRIBUTION_GET',
  'SMART_CONTRACT_BUNDLE_SUBMIT_TO_PAYER',
  'SMART_CONTRACT_PAYER_BUNDLE_GET_TO_SUBMIT',
  'SERVICE_CODE_SUGGESTIONS_GENERATE',
  'SERVICE_CODE_SUGGESTION_LIST_GET',
  'PEER_RECOVERY_SERVICE_CODE_UPDATE',
  'BROADCAST_USER_LIST_GET',
  'CLIENT_REPORT_GET',
  'CLIENT_RISK_LEVEL_GRAPH_GET',
  'CONTRACT_PERIOD_GRAPH_GET',
  'DASHBOARD_CLAIMS_GET',
  'DASHBOARD_CARDS_GET',
  'RISK_PROFILE_DATA_GRAPH_GET',
  'DISTRIBUTION_PAYMENT_UPSERT',
  'APP_USER_JOURNAL_AUGMENT_GET',
  'APP_USER_JOURNAL_AUGMENTATION',
  'STUDENT_JOURNAL_AUGMENT_GET',
  'STUDENT_JOURNAL_AUGMENTATION',
  'STUDENT_COURSE_AUGMENTATION_GET',
  'STUDENT_COURSE_AUGMENTATION',
  'DASHBOARD_RADAR_GRAPH_GET',
  'SMART_CONTRACT_DOCUMENT_REQUEST_GET',
  'GPT_AUGMENTATION_GET',
  'RECOVERY_CATEGORY_COMBO_GET',
  'TASK_COMBO_GET',
  'GPT_AUGMENTATION_WITH_AI',
  'SMART_CONTRACT_CLONE',
  'EXTERNAL_STAFF_CLIENT_INSURANCE_GET',
  'EXTERNAL_STAFF_REFERRAL_UPSERT',
  'CLIENT_REFERRAL_TO_CENTER',
  'EXTERNAL_STAFF_REFERRAL_FORM_GET',
  'TASK_CLIENT_UPSERT',
  'TASK_CLIENT_LIST_GET',
  'TASK_CLIENT_UPDATE',
  'TASK_CLIENT_REASSIGN',
  'STUDENT_NOTE_HISTORY_GET',
  'APP_USER_JOURNAL_HISTORY_GET',
  'APPOINTMENT_GET_CLIENT_UPCOMING_SCHEDULE',
  'LIST_CLIENT_OUTSTANDING_BALANCE_GET',
  'PRE_INTAKE_GET_ALL',
  'CLIENT_SHARED_MEMO_UPSERT',
  'CLIENT_SHARED_MEMO_GET_ALL',
  'CLIENT_SHARED_MEMO_UPDATE',
  'FEEDBACK_FORM_ANALYTICS_GET',
  'FEEDBACK_FORM_GET',
  'FEEDBACK_FORM_DELETE',
  'FEEDBACK_FORM_LIST_GET',
  'APPOINTMENT_AUTO_SELECT_GET',
];

var handler = new ReduxHandler(keys);

var ActionTypes = handler.getActionTypes();

var Actions = handler.getActions();

export { ActionTypes, Actions };
