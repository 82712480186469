import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, reset, destroy, Field, reduxForm } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import { Steps, Button, message } from 'antd';
import BasicDetail from './formSteps/basicDetail';
import RolesPermissions from './formSteps/rolesPermissions';
import { updateUserProfile } from '../../store/actions';
import PageTitle from '../../Components/PageTitle';
const { Step } = Steps;

class MultiStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      IsDeactivated: false,
      isPractitionerTabRequired: false,
      stepMapping: {
        0: 'basic-detail',
        1: 'roles-and-permissions',
      },
      steps: [
        {
          title: 'Basic Details',
          content: (
            <BasicDetail updateStep={this.updateStep} getStaffStatus={this.getStaffStatus} />
          ),
        },
        {
          title: 'Roles & Permissions',
          content: <RolesPermissions updateStep={this.updateStep} />,
        },
      ],
    };
  }
  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };
  componentDidMount() {
    const { activeTabs, stepMapping } = this.state;
    var params = new URLSearchParams(this.props.location.search);
    let step = params.get('step') || params.get('tab');
    if (step && stepMapping) {
      // if (Object.values(stepMapping).indexOf(step) == 3) {
      //   this.setState({
      //     steps: [
      //       ...this.state.steps,
      //       {
      //         title: 'Provider',
      //         content: <Provider />,
      //       },
      //     ],
      //     current: 3,
      //   });
      // } else {
        this.setState({
          current: Object.values(stepMapping).indexOf(step),
        });
      // }
    } else {
      this.setState({
        current: 0,
      });
    }
  }
  componentWillUnmount() {
    this.props.dispatch(updateUserProfile({ userProfile: false }));
  }
  updateStepUrl = (currentKey, GenratedStaffId) => {
    let clickedStep = this.state.stepMapping[currentKey];
    this.props.history.push(
      `/manage/payer-staff-management/payer-staff-form?step=${clickedStep}&staffId=${this.getStaffId() ||
        GenratedStaffId}`,
    );
    this.setState({
      current: currentKey,
    });
  };
  handleNext = () => {
    if (this.state.current === 0) {
      this.props.dispatch(submit('payerStaffForm'));
    } else {
      let currentKey = this.state.current + 1;
      this.updateStepUrl(currentKey);
    }
  };
  updateStep = (GenratedStaffId, isPractitionerTabRequired) => {
    let me = this;
    let currentKey = me.state.current + 1;
    // if (isPractitionerTabRequired) {
    //   let newStep = me.state.steps;
    //   newStep.length === 3 &&
    //     newStep.push({
    //       title: 'Provider',
    //       content: <Provider />,
    //     });
    //   this.setState({
    //     current: currentKey,
    //     steps: newStep,
    //     isPractitionerTabRequired: isPractitionerTabRequired,
    //   });
    // } else {
      let newSteps = [...me.state.steps];
      newSteps.length === 4 && newSteps.pop();
      me.setState({
        current: currentKey,
        steps: newSteps,
        isPractitionerTabRequired: isPractitionerTabRequired,
      });
    // }
    me.updateStepUrl(currentKey, GenratedStaffId);
  };
  handlePrevious = () => {
    let currentKey = this.state.current - 1;
    this.updateStepUrl(currentKey);
  };
  onStepClick = current => {
    if (this.getStaffId()) {
      let currentKey = current;
      this.updateStepUrl(currentKey);
    } else {
      return;
    }
  };
  getStaffStatus = (status, IsPractitionerTabRequired) => {
    // if (IsPractitionerTabRequired) {
    //   let newStep = this.state.steps;
    //   newStep.length === 3 &&
    //     newStep.push({
    //       title: 'Provider',
    //       content: <Provider />,
    //     });
    //   this.setState({
    //     IsDeactivated: status,
    //     steps: newStep,
    //   });
    // } else {
    //   this.setState({
    //     IsDeactivated: status,
    //   });
    // }
  };
  render() {
    const { current, steps, IsDeactivated } = this.state;
    return (
      <div>
        <PageTitle TitleText="Payer Staff Management" />
        <div className="w-100 rcm-panel">
          <div className="main enrollment-page mb-5">
            <div className="container-fluid px-5">
              <div className="content-area">
                <>
                  <div className="top-table-search">
                    <div className="mt-3 cursor-pointer" style={{ display: 'inline-block' }}>
                      <a
                        class="back-link"
                        onClick={
                          () =>
                            this.props.history.push(
                              `/manage/payer-staff-management/payer-staff-list`,
                            )
                          // this.props.history.goBack()
                        }
                      >
                        <img src="images/back-arrow.svg" /> Back to Payer Staff list
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="multi-step-form invite-multi">
                        <div className="steps-action d-flex align-items-center justify-content-between mb-5 ">
                          <div className="text-left">
                            {!this.getStaffId() ? (
                              <h5 className="d-inline">Add New Payer Staff</h5>
                            ) : (
                              <h5 className="d-inline">Update Payer Staff</h5>
                            )}
                          </div>
                          {!this.props.isFetching && !IsDeactivated && (
                            <div>
                              {current > 0 && (
                                <button
                                  onClick={
                                    () =>
                                      this.props.history.push(
                                        `/manage/payer-staff-management/payer-staff-list`,
                                      )
                                    // this.props.history.goBack()
                                  }
                                  class="btn mr-2 px-4"
                                >
                                  Back to List
                                </button>
                              )}
                              {current < steps.length - 1 && (
                                <button onClick={this.handleNext} class="btn px-5">
                                  Save & Next
                                </button>
                              )}
                              {/* {current === steps.length - 1 && (
                                <button
                                  onClick={() => {
                                    if (steps.length === 3) {
                                      this.props.dispatch(submit('staffForm_appDetail'));
                                    } else {
                                      this.props.dispatch(submit('staffForm_practitioner'));
                                    }
                                  }}
                                  class="btn px-5"
                                >
                                  Submit
                                </button>
                              )} */}
                            </div>
                          )}
                        </div>
                        <Steps current={current} onChange={this.onStepClick}>
                          {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                          ))}
                        </Steps>
                        <div className="steps-content mb-5">
                          {steps[current] && steps[current].content}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ staff_upsert, update_user_profile, get_form_field_meta_data }) => {
  // const { data, isFetching, error } = staff_upsert;
  const { data: getGenericFormFields, isFetching } = get_form_field_meta_data;

  // let isUserProfile = false;
  // if (update_user_profile && update_user_profile.data) {
  //   isUserProfile = update_user_profile.data.userProfile;
  // }
  return {
    isFetching,
  };
};
export default connect(mapStateToProps)(withRouter(MultiStepForm));
