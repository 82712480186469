import React, { Component } from 'react';

export default class ClientMilestoneMeasures extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { measureDetails } = this.props;
    const { Description, TotalScore, Goal, Summary } = measureDetails;
    return (
      <div>
        {Summary && <div
          style={{
            backgroundColor: 'rgba(0,255,250,0.05)',
            padding: '12px 20px',
            borderRadius: '10px',
          }}
          className="mb-4"
        >
          <h4>Milestone Summary</h4>
          <p style={{ fontSize: '17px' }}>{Summary}</p>
        </div>}
        <div
          className="payer-card milestone-measure mt-2"
          style={{ border: ' 1px solid #EAEAEA', borderRadius: '10px' }}
        >
          <h5>Measure</h5>
          <div className="d-flex align-items-center" style={{ width: '60%' }}>
            <p className="mb-0 client-text">{Description && Description}</p>
            <p
              className="text-center mb-0 ml-5"
              style={{
                backgroundColor: 'white',
                padding: '5px 15px',
                marginLeft: '10px',
                border: '1px solid #A1A5B7',
              }}
            >
              Baseline Score: {TotalScore && TotalScore}
            </p>
          </div>

          {Goal &&
            Goal.map((item, index) => {
              const {
                GoalType,
                Description,
                Note,
                GoalCreatedOn,
                GoalTimePeriod,
                TimePeriod,
                StatusText,
                StatusClassName,
                GoalCompletedId,
                DaysToAchieveGoal,
                WeeksToAchieveGoal,
              } = item;
              return (
                <div className="card border-0 students-profile mt-4" style={{ padding: '20px' }}>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0" style={{ fontWeight: '700', fontSize: '18px' }}>
                      Goal { index + 1 }:
                    </p>
                    <div
                      className={`d-flex align-items-center client-info-text ${StatusClassName}`}
                    >
                      {/* {GoalCompletedId == 1 ? (
                        <img src="images/white_bg_approve.svg" />
                      ) : ( */}
                      <img
                        src={`images/${
                          GoalCompletedId == 1 ? `white_bg_approve.svg` : `white_bg_deny.svg`
                        }`}
                      />
                      {/* )} */}
                      <p
                        className={`text-uppercase mb-0 ml-2`}
                        style={{ fontSize: '18px', fontWeight: '500' }}
                      >
                        {StatusText && StatusText}
                      </p>
                    </div>
                  </div>

                  <div className="mt-3">
                    <p className="mb-0 client-label">Goal Type</p>
                    <p className="mb-0 client-text">{GoalType && GoalType}</p>
                  </div>

                  <div className="mt-3">
                    <p className="mb-0 client-label">Goal</p>
                    <p className="mb-0 client-text">{Description && Description}</p>
                  </div>

                  <div className="d-flex mt-3">
                    <div>
                      <p className="mb-0 client-label">Goal Added Date</p>
                      <p className="mb-0 client-text">{GoalCreatedOn && GoalCreatedOn}</p>
                    </div>
                    <div className="ml-5">
                      <p className="mb-0 client-label">Time Period to Achieve Goal</p>
                      <p className="mb-0 client-text">{DaysToAchieveGoal && DaysToAchieveGoal}</p>
                    </div>
                    <div className="ml-5">
                      <p className="mb-0 client-label">Date to Achieve Goal</p>
                      <p className="mb-0 client-text">{WeeksToAchieveGoal && WeeksToAchieveGoal}</p>
                    </div>
                  </div>

                  <div className="mt-3">
                    <p className="mb-0 client-label">Note</p>
                    <p className="mb-0 client-text">{Note && Note}</p>
                  </div>

                  <div className="mt-3 payer-card" style={{ borderRadius: '10px' }}>
                    <p className="mb-0 client-label">Remarks</p>
                    <p className="mb-0 client-text">Goal</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
