import React, { Component } from 'react';
import ListRenderer from '../Components/listRenderer';
import PropTypes from 'prop-types';
import { Table, Divider, Tag, Popover, Button } from 'antd';
import { isComparer } from '../util/handler';
import { connect } from 'react-redux';
import {
  getContractTemplate,
  createContractFromTemplate,
  resetCreateContractFromTemplate,
  addFavoriteTemplate,
  resetAddFavoriteTemplate,
  removeFavoriteTemplate,
  resetRemoveFavoriteTemplate,
  archiveContractTemplate,
  resetArchiveContractTemplate,
  menuContractTemplate,
} from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import { withRouter } from 'react-router-dom';
import CustomModal from '../Components/CustomModal';
import PageTitle from '../Components/PageTitle';
var timer;

class PopoverContent extends React.Component {
  render() {
    const { ContractTemplateMenu, IsFavorite, tabActiveKey } = this.props;
    // let viewContent = [];
    // let contentText = {};
    // for (let menu of ContractTemplateMenu) {
    //   viewContent.push(menu.Value);
    //   contentText.push(menu.Text);
    // }
    return (
      <div className=''>
        {/* <p onClick={()=>{this.changePath()}}>View Details</p> */}
        {ContractTemplateMenu &&
          ContractTemplateMenu.map(item => {
            if (item.Value === 'View') {
              return (
                <p className="mb-0" style={{ padding: '10px' }} onClick={this.props.onViewDetails}>
                  {item.Text}
                </p>
              );
            }
            if (item.Value === 'Edit') {
              return (
                <p className="mb-0" style={{ padding: '10px' }} onClick={this.props.editContract}>
                  {item.Text}
                </p>
              );
            }
            if (item.Value === 'Archive') {
              return (
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <p
                      className="mb-0"
                      style={{ padding: '10px' }}
                      data-toggle="modal"
                      data-target="#archive-model"
                      onClick={onClick}
                    >
                      {item.Text}
                    </p>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Do you want to archive this Template?
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn btn-outline-primary black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          // onClick={() => {
                          //   onOk();
                          // }}
                          onClick={this.props.removeContract}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              );
            }
            if (item.Value === 'Clone') {
              return (
                <p className="mb-0" style={{ padding: '10px' }} onClick={this.props.cloneContract}>
                  {item.Text}
                </p>
              );
            }
            if (item.Value === 'Create') {
              return (
                <p className="mb-0" style={{ padding: '10px' }} onClick={this.props.createContract}>
                  {item.Text}
                </p>
              );
            }
            if (item.Value === 'Remove' && tabActiveKey === '2') {
              return (
                <p className="mb-0" style={{ padding: '10px' }} onClick={this.props.removeFavorite}>
                  {item.Text}
                </p>
              );
            }
            if (item.Value === 'Add' && tabActiveKey != '2') {
              return (
                <p className='mb-0' style={{ padding: '10px' }} onClick={this.props.addFavorite}>
                  {item.Text}
                </p>
              );
            }
          })}
        {/* {viewContent.includes('View') && <p onClick={this.props.onViewDetails}>View Details</p>}
        {viewContent.includes('Edit') && <p onClick={this.props.editContract}>Edit Template</p>}
        {viewContent.includes('Archive') && (
          // <p onClick={this.props.removeContract}>Delete Contract</p>
          <CustomModal
            ModalButton={({ onClick }) => (
              <p data-toggle="modal" data-target="#archive-model" onClick={onClick}>
                {'Delete Template'}
              </p>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div class="modal-content border-0">
                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                  <h4 class="modal-title col-12 mb-3">
                    Are you sure you want to <br />
                    <strong>{'Delete Template '}</strong> ?
                  </h4>
                </div>
                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    class="btn black-btn"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No
                  </button>
                  <button
                    class="btn"
                    // onClick={() => {
                    //   onOk();
                    // }}
                    onClick={this.props.removeContract}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          />
        )}
        {viewContent.includes('Clone') && <p onClick={this.props.cloneContract}>Clone Template</p>}
        {viewContent.includes('Create') && (
          <p onClick={this.props.createContract}>Create a Contract</p>
        )}
        {viewContent.includes('Add') && <p onClick={this.props.addFavorite}>Add Favorite</p>}
        {viewContent.includes('Remove') && (
          <p onClick={this.props.removeFavorite}>Remove Favorite</p>
        )} */}
      </div>
    );
  }
}

const mapStateToPropsPop = ({ menu_contract_template }) => {
  return {
    ContractTemplateMenu:
      (menu_contract_template.data && menu_contract_template.data.ContractTemplateMenu) || [],
  };
};

const Content = connect(mapStateToPropsPop)(withRouter(PopoverContent));

class BaseTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.onViewDetails = this.props.onViewDetails;
    this.createContract = this.props.createContract;
    this.addFavorite = this.props.addFavorite;
    this.removeFavorite = this.props.removeFavorite;
    this.editContract = this.props.editContract;
    this.removeContract = this.props.removeContract;
    this.cloneContract = this.props.cloneContract;
    this.columns = [
      {
        dataIndex: 'IsFavorite',
        render: (IsFavorite, record, { ContractTemplateId: id }) =>
          IsFavorite && (
            <Popover
              placement="bottomLeft"
              style={{ width: '10px' }}
              trigger="click"
              content={
                <Content
                  id={id}
                  IsFavorite={IsFavorite}
                  tabActiveKey={this.props.tabActiveKey}
                  onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                  createContract={this.createContract.bind(null, record.ContractTemplateId)}
                  addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                  removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                  editContract={this.editContract.bind(null, record.ContractTemplateId)}
                  removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                  cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                />
              }
            >
              <div>
                <span class="favourites" />
              </div>
            </Popover>
          ),
      },
      {
        title: 'Name',
        dataIndex: 'TemplateName',
        key: 'name',
        render: (text, record, { ContractTemplateId: id }) => {
          if (record.IsPublic) {
            if (record.IsPublicTemplateEditable) {
              return (
                <Popover
                  placement="bottomLeft"
                  style={{ width: '10px' }}
                  trigger="click"
                  content={
                    <Content
                      id={id}
                      // IsFavorite={IsFavorite}
                      tabActiveKey={this.props.tabActiveKey}
                      onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                      createContract={this.createContract.bind(null, record.ContractTemplateId)}
                      addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                      removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                      editContract={this.editContract.bind(null, record.ContractTemplateId)}
                      removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                      cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                    />
                  }
                >
                  <a href="javascript:;">{text}</a>
                </Popover>
              );
            } else {
              return <div>{text}</div>;
            }
          } else {
            return (
              <Popover
                placement="bottomLeft"
                style={{ width: '10px' }}
                className="admin-template"
                trigger="click"
                content={
                  <Content
                    id={id}
                    // IsFavorite={IsFavorite}
                    tabActiveKey={this.props.tabActiveKey}
                    onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                    createContract={this.createContract.bind(null, record.ContractTemplateId)}
                    addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                    removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                    editContract={this.editContract.bind(null, record.ContractTemplateId)}
                    removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                    cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                  />
                }
              >
                <a href="javascript:;">{text}</a>
              </Popover>
            );
          }
        },
        onFilter: (value, record) => record.TemplateName.indexOf(value) === 0,
        sorter: (a, b) => isComparer(a.TemplateName - b.TemplateName),
        // sortDirections: ['descend'],
      },
      {
        title: 'Theme',
        dataIndex: 'MajorTheme',
        key: 'theme',
        render: (text, record, { ContractTemplateId: id }) => {
          if (record.IsPublic) {
            if (record.IsPublicTemplateEditable) {
              return (
                <Popover
                  placement="bottomLeft"
                  style={{ width: '10px' }}
                  trigger="click"
                  content={
                    <Content
                      id={id}
                      // IsFavorite={IsFavorite}
                      tabActiveKey={this.props.tabActiveKey}
                      onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                      createContract={this.createContract.bind(null, record.ContractTemplateId)}
                      addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                      removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                      editContract={this.editContract.bind(null, record.ContractTemplateId)}
                      removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                      cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                    />
                  }
                >
                  <div>{text}</div>
                </Popover>
              );
            } else {
              return <div>{text}</div>;
            }
          } else {
            return (
              <Popover
                placement="bottomLeft"
                style={{ width: '10px' }}
                trigger="click"
                content={
                  <Content
                    id={id}
                    // IsFavorite={IsFavorite}
                    tabActiveKey={this.props.tabActiveKey}
                    onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                    createContract={this.createContract.bind(null, record.ContractTemplateId)}
                    addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                    removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                    editContract={this.editContract.bind(null, record.ContractTemplateId)}
                    removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                    cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                  />
                }
              >
                <div>{text}</div>
              </Popover>
            );
          }
        },
        sorter: (a, b) => isComparer(a.MajorTheme - b.MajorTheme),
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'descrition',
        render: (text, record, { ContractTemplateId: id }) => {
          if (record.IsPublic) {
            if (record.IsPublicTemplateEditable) {
              return (
                <Popover
                  placement="bottomLeft"
                  style={{ width: '10px' }}
                  trigger="click"
                  content={
                    <Content
                      id={id}
                      // IsFavorite={IsFavorite}
                      tabActiveKey={this.props.tabActiveKey}
                      onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                      createContract={this.createContract.bind(null, record.ContractTemplateId)}
                      addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                      removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                      editContract={this.editContract.bind(null, record.ContractTemplateId)}
                      removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                      cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                    />
                  }
                >
                  <div>{text}</div>
                </Popover>
              );
            } else {
              return <div>{text}</div>;
            }
          } else {
            return (
              <Popover
                placement="bottomLeft"
                style={{ width: '10px' }}
                trigger="click"
                content={
                  <Content
                    id={id}
                    // IsFavorite={IsFavorite}
                    tabActiveKey={this.props.tabActiveKey}
                    onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                    createContract={this.createContract.bind(null, record.ContractTemplateId)}
                    addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                    removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                    editContract={this.editContract.bind(null, record.ContractTemplateId)}
                    removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                    cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                  />
                }
              >
                <div>{text}</div>
              </Popover>
            );
          }
        },
        sorter: (a, b) => isComparer(a.Description - b.Description),
      },
      {
        title: 'Created By',
        dataIndex: 'CreatedBy',
        key: 'CreatedBy',
        render: (text, record, { ContractTemplateId: id }) => {
          if (record.IsPublic) {
            if (record.IsPublicTemplateEditable) {
              return (
                <Popover
                  placement="bottomLeft"
                  style={{ width: '10px' }}
                  trigger="click"
                  content={
                    <Content
                      id={id}
                      // IsFavorite={IsFavorite}
                      tabActiveKey={this.props.tabActiveKey}
                      onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                      createContract={this.createContract.bind(null, record.ContractTemplateId)}
                      addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                      removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                      editContract={this.editContract.bind(null, record.ContractTemplateId)}
                      removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                      cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                    />
                  }
                >
                  <div>{text}</div>
                </Popover>
              );
            } else {
              return <div>{text}</div>;
            }
          } else {
            return (
              <Popover
                placement="bottomLeft"
                style={{ width: '10px' }}
                trigger="click"
                content={
                  <Content
                    id={id}
                    // IsFavorite={IsFavorite}
                    tabActiveKey={this.props.tabActiveKey}
                    onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                    createContract={this.createContract.bind(null, record.ContractTemplateId)}
                    addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                    removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                    editContract={this.editContract.bind(null, record.ContractTemplateId)}
                    removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                    cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                  />
                }
              >
                <div>{text}</div>
              </Popover>
            );
          }
        },
        sorter: (a, b) => isComparer(a.CreatedBy - b.CreatedBy),
      },
      {
        title: 'Last Edited By',
        dataIndex: 'LastEditedBy',
        key: 'LastEditedBy',
        render: (text, record, { ContractTemplateId: id }) => {
          if (record.IsPublic) {
            if (record.IsPublicTemplateEditable) {
              return (
                <Popover
                  placement="bottomLeft"
                  style={{ width: '10px' }}
                  trigger="click"
                  content={
                    <Content
                      id={id}
                      // IsFavorite={IsFavorite}
                      tabActiveKey={this.props.tabActiveKey}
                      onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                      createContract={this.createContract.bind(null, record.ContractTemplateId)}
                      addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                      removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                      editContract={this.editContract.bind(null, record.ContractTemplateId)}
                      removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                      cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                    />
                  }
                >
                  <div>{text}</div>
                </Popover>
              );
            } else {
              return <div>{text}</div>;
            }
          } else {
            return (
              <Popover
                placement="bottomLeft"
                style={{ width: '10px' }}
                trigger="click"
                content={
                  <Content
                    id={id}
                    // IsFavorite={IsFavorite}
                    tabActiveKey={this.props.tabActiveKey}
                    onViewDetails={this.onViewDetails.bind(null, record.ContractTemplateId)}
                    createContract={this.createContract.bind(null, record.ContractTemplateId)}
                    addFavorite={this.addFavorite.bind(null, record.ContractTemplateId)}
                    removeFavorite={this.removeFavorite.bind(null, record.ContractTemplateId)}
                    editContract={this.editContract.bind(null, record.ContractTemplateId)}
                    removeContract={this.removeContract.bind(null, record.ContractTemplateId)}
                    cloneContract={this.cloneContract.bind(null, record.ContractTemplateId)}
                  />
                }
              >
                <div>{text}</div>
              </Popover>
            );
          }
        },
        sorter: (a, b) => isComparer(a.LastEditedBy - b.LastEditedBy),
      },
    ];
  }
}

class MyTemplates extends BaseTemplate {
  render() {
    const data = this.props.data.filter(item => item.IsMyTemplate);
    return (
      <div
        class="col-md-12 dataTables_wrapper no-footer tab-content "
        style={{ overflow: 'hidden' }}
      >
        <Table
          columns={this.columns}
          getPopupContainer={() => <div>hello</div>}
          dataSource={data}
          pagination={{
            itemRender: (page, type, originalElement) => {
              return (
                <div class="dataTables_wrapper no-footer">
                  <div class="dataTables_paginate paging_simple_numbers">
                    {type == 'prev' ? (
                      <a class="paginate_button previous">Previous</a>
                    ) : type == 'next' ? (
                      <a class="paginate_button next">Next</a>
                    ) : (
                      <a class="paginate_button ">{page}</a>
                    )}
                  </div>
                </div>
              );
            },
            showSizeChanger: true,
            // hideOnSinglePage: true,
          }}
        />
      </div>
    );
  }
}

class PublicTemplates extends BaseTemplate {
  render() {
    const data = this.props.data.filter(item => item.IsPublic);
    return (
      <div
        class="col-md-12 dataTables_wrapper no-footer tab-content "
        style={{ overflow: 'hidden' }}
      >
        <Table
          columns={this.columns}
          dataSource={data}
          pagination={{
            itemRender: (page, type, originalElement) => {
              return (
                <div class="dataTables_wrapper no-footer">
                  <div class="dataTables_paginate paging_simple_numbers">
                    {type == 'prev' ? (
                      <a class="paginate_button previous">Previous</a>
                    ) : type == 'next' ? (
                      <a class="paginate_button next">Next</a>
                    ) : (
                      <a class="paginate_button ">{page}</a>
                    )}
                  </div>
                </div>
              );
            },
            showSizeChanger: true,
            // hideOnSinglePage: true,
          }}
        />
      </div>
    );
  }
}
class Favourites extends BaseTemplate {
  render() {
    const data = this.props.data.filter(item => item.IsFavorite);
    return (
      <div
        class="col-md-12 dataTables_wrapper no-footer tab-content "
        style={{ overflow: 'hidden' }}
      >
        <Table
          columns={this.columns}
          dataSource={data}
          pagination={{
            itemRender: (page, type, originalElement) => {
              return (
                <div class="dataTables_wrapper no-footer">
                  <div class="dataTables_paginate paging_simple_numbers">
                    {type == 'prev' ? (
                      <a class="paginate_button previous">Previous</a>
                    ) : type == 'next' ? (
                      <a class="paginate_button next">Next</a>
                    ) : (
                      <a class="paginate_button ">{page}</a>
                    )}
                  </div>
                </div>
              );
            },
            showSizeChanger: true,
            // hideOnSinglePage: true,
          }}
        />
      </div>
    );
  }
}

const activeKey = 0;

class Templates extends Component {
  constructor(props) {
    super();
    this.state = {
      tabActiveKey: 0,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  onViewDetails = id => {
    this.props.history.push('/viewtemplate?contractTemplateId=' + id);
  };

  editContract = id => {
    this.props.history.push('/edittemplate?contractTemplateId=' + id);
  };
  cloneContract = id => {
    this.props.history.push('/clonetemplate?contractTemplateId=' + id);
  };
  createContract = id => {
    this.props.dispatch(
      createContractFromTemplate({ StudentId: this.getStudentId(), ContractTemplateId: id }),
    );
  };

  addFavorite = id => {
    this.props.dispatch(
      addFavoriteTemplate({
        ContractTemplateId: id,
      }),
    );
  };

  removeFavorite = id => {
    this.props.dispatch(
      removeFavoriteTemplate({
        ContractTemplateId: id,
      }),
    );
  };
  removeContract = id => {
    this.props.dispatch(
      archiveContractTemplate({
        ContractTemplateId: id,
      }),
    );
  };

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId' || 'StudentId');
  }

  componentWillReceiveProps({
    createContractProgress,
    isContractCreated,
    newContractId,
    isFavorateAdded,
    isFavorateRemoved,
    isArchiveContract,
  }) {
    if (isContractCreated) {
      this.props.dispatch(resetCreateContractFromTemplate());
      this.props.history.push(
        '/newcontract?studentId=' + this.getStudentId() + '&contractId=' + newContractId,
      );
    } else if (isFavorateAdded) {
      this.props.dispatch(resetAddFavoriteTemplate());
      this.props.dispatch(getContractTemplate({ ContractTemplateId: null }));
    } else if (isFavorateRemoved) {
      this.props.dispatch(resetRemoveFavoriteTemplate());
      this.props.dispatch(getContractTemplate({ ContractTemplateId: null }));
    } else if (isArchiveContract) {
      this.props.dispatch(resetArchiveContractTemplate());
      this.props.dispatch(getContractTemplate({ ContractTemplateId: null }));
    }
  }
  componentDidMount() {
    this.props.dispatch(getContractTemplate({ ContractTemplateId: null }));
    this.props.dispatch(
      menuContractTemplate({
        json: JSON.stringify({
          Contract: [
            {
              StudentId: this.getStudentId(),
            },
          ],
        }),
      }),
    );
  }
  onSearchChange = value => {
    this.state.searchStr = value;
    const { dispatch } = this.props;
    dispatch(
      getContractTemplate({
        json: JSON.stringify({
          Template: [
            {
              SearchStr: this.state.searchStr,
            },
          ],
        }),
      }),
    );
  };

  fnDebounce = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.onSearchChange(value);
    }, 500);
  };
  render() {
    const { data, isFetching } = this.props;
    const list = [
      {
        Name: 'My Templates',
        ExtraComponent: () => (
          <MyTemplates
            data={data}
            tabActiveKey={this.state.tabActiveKey}
            onViewDetails={this.onViewDetails}
            createContract={this.createContract}
            addFavorite={this.addFavorite}
            removeFavorite={this.removeFavorite}
            editContract={this.editContract}
            removeContract={this.removeContract}
            cloneContract={this.cloneContract}
          />
        ),
      },

      {
        Name: 'Public Templates',
        ExtraComponent: () => (
          <PublicTemplates
            data={data}
            tabActiveKey={this.state.tabActiveKey}
            onViewDetails={this.onViewDetails}
            createContract={this.createContract}
            addFavorite={this.addFavorite}
            removeFavorite={this.removeFavorite}
            editContract={this.editContract}
            removeContract={this.removeContract}
            cloneContract={this.cloneContract}
          />
        ),
      },

      {
        Name: 'Favorites',
        ExtraComponent: () => (
          <Favourites
            data={data}
            tabActiveKey={this.state.tabActiveKey}
            onViewDetails={this.onViewDetails}
            createContract={this.createContract}
            addFavorite={this.addFavorite}
            removeFavorite={this.removeFavorite}
            editContract={this.editContract}
            removeContract={this.removeContract}
            cloneContract={this.cloneContract}
          />
        ),
      },
    ];
    return (
      <div class="main   mb-5">
        <PageTitle TitleText="Templates" />
        <div class="container-fluid">
          <div class="row top-table-search mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Templates</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right">
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search"
                    onKeyUp={event => {
                      this.fnDebounce(event.target.value);
                    }}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>
              <button
                onClick={() =>
                  this.props.history.push(
                    '/newTemplate?studentId=' + this.getStudentId() + '&contractTemplateId=null',
                  )
                }
                class="btn"
                title="New Template"
              >
                New Template
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5 vertical-tabs">
              <Loader2 loading={isFetching} />
              <ListRenderer
                list={list}
                activeKey={activeKey}
                tabPosition="left"
                getActiveKey={tabActiveKey => this.setState({ tabActiveKey })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  get_contract_template,
  create_contract_from_template,
  add_favorite_template,
  remove_favorite_template,
  archive_contract_template,
}) => {
  return {
    data: (get_contract_template.data && Object.values(get_contract_template.data)) || [],
    isFetching: get_contract_template.isFetching,
    createContractProgress: create_contract_from_template.isFetching,
    isContractCreated:
      (create_contract_from_template.data && create_contract_from_template.data.success) || false,
    newContractId:
      (create_contract_from_template.data && create_contract_from_template.data.result) || -1,
    isFavorateAdded: (add_favorite_template.data && add_favorite_template.data.success) || false,
    isFavorateRemoved:
      (remove_favorite_template.data && remove_favorite_template.data.success) || false,
    isArchiveContract:
      (archive_contract_template.data && archive_contract_template.data.success) || false,
  };
};

export default connect(mapStateToProps)(Templates);
