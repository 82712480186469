import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { appUserJournalHistoryGet } from '../../store/actions';
import CustomModal from '../../Components/CustomModal';
import Loader, { Loader2 } from '../../Components/Loader';

const SubscriberNoteHistory = props => {
  const dispatch = useDispatch();
  const { rowRecord } = props;
  const { noteHistoryResponse, isFetching } = useSelector(
    ({ app_user_journal_history_get: { data: noteHistoryData, isFetching, error } }) => {
      return {
        noteHistoryResponse: noteHistoryData && noteHistoryData.data,
        isFetching: isFetching,
      };
    },
    shallowEqual,
  );
  const viewHistory = () => {
    dispatch(
      appUserJournalHistoryGet({
        Json: JSON.stringify({
          UserNote: [
            {
              UserNoteId: rowRecord.UserNoteId,
            },
          ],
        }),
      }),
    );
  };

  return (
    <div>
      <CustomModal
        modalWidth={700}
        ModalButton={({ onClick }) => (
          <span
            className="mr-2 text-right d-block"
            data-target="#student-note-history-model"
            onClick={() => {
              viewHistory();
              onClick();
            }}
            style={{
              color: '#3773E8',
              cursor: 'pointer',
              fontWeight: '800',
            }}
          >
            Edit History
          </span>
        )}
        ModalContent={({ onOk, onCancel }) => (
          <div className="modal-content border-0">
            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
              <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                &times;
              </button>
              <h4 class="modal-title col-12">Journal Edit’s History</h4>
            </div>
            <div class="modal-body px-5">
              <Loader2 loading={isFetching} />
              <div className="note-history-list">
                {noteHistoryResponse &&
                  noteHistoryResponse.map(historyItem => (
                    <div className="list-box">
                      {historyItem.IsConfidential && (
                        <span className="alert alert-danger confidential-alert border-0 text-uppercase pl-0">
                          <i className="fas fa-lock" /> Confidential
                        </span>
                      )}
                      <div className="date-modified">
                        <span className="text">Date Modified : </span>
                        <span className="date">{historyItem.DateModified}</span>
                        <span className=" ml-2 text">by</span>
                        <span className="modified-by">
                          {historyItem.UpdateByDisplayName || historyItem.UpdatedBy}
                        </span>
                      </div>
                      <div className="history-note">
                        <span className="text">Note : </span>
                        <span className="note">{historyItem.Note}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
export default SubscriberNoteHistory;
