import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';

export default ({
  survey,
  onClick,
  scope,
  index,
  total,
  level,
  subLevel,
  subCatLevel,
  isReadOnly,
}) => {
  const {
    Question,
    QuestionNumber,
    Option1Value,
    Option1Text,
    Option2Value,
    Option2Text,
    Option3Value,
    Option3Text,
    Option4Value,
    Option5Value,
    Response,
    SurveyQuestionId,
    DoesNotApplyText,
    DoesNotApplyValue,
  } = survey;
  return (
    <div>
      <div className="type-border col-12">
        <div
        // class="card-body"
        >
          {Question && Question.length > 0 && (
            <h5 className="survey-question rcm-question text-left">
              {QuestionNumber && `${QuestionNumber}.`} {renderHTML(Question)}
            </h5>
          )}

          <ul class="answer-options d-flex">
            <li className="mr-2">
              <span
                className={Response == '-2' ? 'selected' : 'normal'}
                style={{ padding: '10px 20px' }}
              >
                {' '}
                <input
                  disabled={isReadOnly}
                  type="radio"
                  name="current-situation"
                  value={'Strongly Disagree'}
                  onClick={onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: '-2',
                    level: level,
                    subLevel: subLevel,
                    subCatLevel: subCatLevel,
                    index: index,
                  })}
                />
                Strongly Disagree
              </span>
            </li>
            <li className="mr-2">
              <span
                className={Response == '-1' ? 'selected' : 'normal'}
                style={{ padding: '10px 20px' }}
              >
                {' '}
                <input
                  disabled={isReadOnly}
                  type="radio"
                  name="current-situation"
                  value={'Disagree'}
                  onClick={onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: '-1',
                    level: level,
                    subLevel: subLevel,
                    subCatLevel: subCatLevel,
                    index: index,
                  })}
                />
                Disagree
              </span>
            </li>
            <li className="mr-2">
              <span
                className={Response == '0' ? 'selected' : 'normal'}
                style={{ padding: '10px 20px' }}
              >
                {' '}
                <input
                  disabled={isReadOnly}
                  type="radio"
                  name="current-situation"
                  value={'Neutral'}
                  onClick={onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: '0',
                    level: level,
                    subLevel: subLevel,
                    subCatLevel: subCatLevel,
                    index: index,
                  })}
                />
                Neutral
              </span>
            </li>
            <li className="mr-2">
              <span
                className={Response == '1' ? 'selected' : 'normal'}
                style={{ padding: '10px 20px' }}
              >
                {' '}
                <input
                  disabled={isReadOnly}
                  type="radio"
                  name="current-situation"
                  value={'Agree'}
                  onClick={onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: '1',
                    level: level,
                    subLevel: subLevel,
                    subCatLevel: subCatLevel,
                    index: index,
                  })}
                />
                Agree
              </span>
            </li>
            <li className="mr-2">
              <span
                className={Response == '2' ? 'selected' : 'normal'}
                style={{ padding: '10px 20px' }}
              >
                {' '}
                <input
                  disabled={isReadOnly}
                  type="radio"
                  name="current-situation"
                  value={'Strongly Agree'}
                  onClick={onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: '2',
                    level: level,
                    subLevel: subLevel,
                    subCatLevel: subCatLevel,
                    index: index,
                  })}
                />
                Strongly Agree
              </span>
            </li>
          </ul>

          {DoesNotApplyText && (
            <div className="not-apply-to-me">
              <a
                name="current-situation"
                value={DoesNotApplyText}
                onClick={onClick.bind(scope, {
                  surveyQuestionId: SurveyQuestionId,
                  response: DoesNotApplyValue,
                  level: level,
                  subLevel: subLevel,
                  subCatLevel: subCatLevel,
                  index: index,
                })}
              >
                {DoesNotApplyText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
