import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ReduxSelect,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxPlusMinusPercentageInput,
} from '../../../../component/custom-redux-components';
import {
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  comboCode,
  getCodeDescription,
  centerOverHeadCostGet,
} from '../../../../store/actions';
import validate from '../../../../core/generic-form/validation';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { ConsumableCostDetails } from '../consumableCostDetails';
import { LaborCostDetails } from '../laborCostDetails';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
import { FormComponent } from './form';
const ServiceCodeForm = props => {
  const { updateParentComponent } = props;
  const [totalConsumableCost, setTotalConsumableCost] = useState(0);
  const [totalLaborCost, setTotalLaborCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalServiceCost, setTotalServiceCost] = useState(0);
  const [netMarginPercentage, setNetMarginPercentage] = useState(0);
  const [defaultCenterOverHeadCostPercentage, setDefaultCenterOverHeadCostPercentage] = useState(0);
  const [overHeadCost, setOverHeadCost] = useState(0);

  const {
    onCancel,
    ServiceCodeId,
    code,
    detailDescription,
    codeModifier,
    UnitOfMeasurement,
    dispatch,
    initialize,
    currentServiceFormValues,
    change,
    CenterConsumable,
    ConsumableFormData,
    CenterLaborCost,
    handleSubmit,
    // LaberCostType,
    LaborCostFormData,
    ServiceCodeCostType,
    onSaveClick,
    PayFrequency,
    isUpsertFetching,
  } = props;

  useEffect(() => {
    props.dispatch(centerOverHeadCostGet({}));
  }, []);

  useEffect(() => {
    if (detailDescription) {
      change('detailDescription', detailDescription);
    }
  }, [detailDescription]);

  useEffect(() => {
    if (props.NetMarginPercentageValue != null) {
      let netMarginCalcValue =
        ((((totalConsumableCost + totalLaborCost) * defaultCenterOverHeadCostPercentage) / 100 +
          totalConsumableCost +
          totalLaborCost) *
          props.NetMarginPercentageValue) /
        100;
      let tempTotalServiceCost = netMarginCalcValue + totalCost;
      setNetMarginPercentage(props.NetMarginPercentageValue);
      if (tempTotalServiceCost) {
        setTotalServiceCost(tempTotalServiceCost.toFixed(2));
      }
      updateParentComponent({
        NetMargin:
          props.NetMarginPercentageValue.toString().length > 0 ? props.NetMarginPercentageValue : 0,
        TotalServiceCost: tempTotalServiceCost ? tempTotalServiceCost.toFixed(2) : 0,
        NetMarginCost: netMarginCalcValue ? netMarginCalcValue.toFixed(2) : 0,
      });
    }
  }, [props.NetMarginPercentageValue]);

  useEffect(() => {
    //  let tempOverHeadCost =
    //     ((totalConsumableCost + totalLaborCost) * defaultCenterOverHeadCostPercentage) / 100;

    let tempOverHeadCost;
    let tempTotalCost;
    let netMarginCalcValue;
    let tempTotalServiceCost;

    if (totalConsumableCost != null && totalLaborCost != null) {
      tempOverHeadCost =
        ((totalConsumableCost + totalLaborCost) * defaultCenterOverHeadCostPercentage) / 100;
      tempTotalCost = tempOverHeadCost + totalConsumableCost + totalLaborCost;
      netMarginCalcValue = (tempTotalCost * netMarginPercentage) / 100;
      tempTotalServiceCost = netMarginCalcValue + tempTotalCost;
    } else if (totalConsumableCost != null) {
      tempOverHeadCost = (totalConsumableCost * defaultCenterOverHeadCostPercentage) / 100;
      tempTotalCost = tempOverHeadCost + totalConsumableCost;
      netMarginCalcValue = (tempTotalCost * netMarginPercentage) / 100;
      tempTotalServiceCost = netMarginCalcValue + tempTotalCost;
    } else if (totalLaborCost != null) {
      tempOverHeadCost = (totalLaborCost * defaultCenterOverHeadCostPercentage) / 100;
      tempTotalCost = tempOverHeadCost + totalLaborCost;
      netMarginCalcValue = (tempTotalCost * netMarginPercentage) / 100;
      tempTotalServiceCost = netMarginCalcValue + tempTotalCost;
    }

    setOverHeadCost(tempOverHeadCost);
    setTotalCost(tempTotalCost);
    setTotalServiceCost(tempTotalServiceCost && parseFloat(tempTotalServiceCost).toFixed(2));
    updateParentComponent({
      OverHeadCost: tempOverHeadCost,
      TotalCost: tempTotalCost,
      TotalServiceCost: tempTotalServiceCost && parseFloat(tempTotalServiceCost).toFixed(2),
    });
  }, [totalConsumableCost, totalLaborCost, defaultCenterOverHeadCostPercentage]);

  useEffect(() => {
    if (props.initialValues && Object.values(props.initialValues).length > 0) {
      const {
        ConsumableCost,
        LaberCost,
        OverHeadCost,
        TotalCost,
        TotalServiceCost,
        NetMargin,
        NetMarginCost,
        NoOfServices,
        LastOverHeadCostPercentage,
      } = props.initialValues;
      if (ConsumableCost) {
        setTotalConsumableCost(parseFloat(ConsumableCost));
      }
      if (LaberCost) {
        setTotalLaborCost(parseFloat(LaberCost));
      }
      if (OverHeadCost) {
        setOverHeadCost(parseFloat(OverHeadCost));
      }
      if (TotalCost) {
        setTotalCost(parseFloat(TotalCost));
      }
      if (TotalServiceCost) {
        setTotalServiceCost(parseFloat(TotalServiceCost));
      }
      if (NetMargin) {
        setNetMarginPercentage(NetMargin);
      }

      if (props.ServiceCodeId && LastOverHeadCostPercentage) {
        setDefaultCenterOverHeadCostPercentage(LastOverHeadCostPercentage);
      }
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (props.ServiceCodeId == null && props.centerOverHeadCost) {
      setDefaultCenterOverHeadCostPercentage(props.centerOverHeadCost);
    }
  }, [props.centerOverHeadCost]);

  useEffect(() => {
    if (currentServiceFormValues) {
      setDefaultCenterOverHeadCostPercentage(currentServiceFormValues.OverHeadCostPercentage);
    }
  }, [currentServiceFormValues && currentServiceFormValues.OverHeadCostPercentage]);

  const handleCodeChange = (value, field) => {
    let data;
    switch (field) {
      case 'code':
        data = {
          Code: [
            {
              CodeId: value,
            },
          ],
        };
        break;
      case 'codeModifier1':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      case 'codeModifier2':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      case 'codeModifier3':
        data = {
          Code: [
            {
              CodeModifierId: value,
              CodeModifierIndex: field,
            },
          ],
        };
        break;
      default:
        break;
    }

    let Data = {
      json: JSON.stringify(data),
    };

    props.dispatch(getCodeDescription(Data));
  };

  return (
    <>
      <div class="modal-header flex-wrap border-bottom-0 ">
        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        <h4 class="modal-title col-12 text-left px-0">
          {ServiceCodeId ? `EDIT` : `ADD NEW`} SERVICE CODE
        </h4>
      </div>
      <div class="modal-body rcm-panel enrollment-form masterBillOfService">
        <FormComponent
          dispatch={dispatch}
          code={code}
          unitOfMeasurement={UnitOfMeasurement}
          PayFrequency={PayFrequency}
          detailDescription={detailDescription}
          codeModifier={codeModifier}
          currentServiceFormValues={currentServiceFormValues}
          CenterConsumable={CenterConsumable}
          ConsumableFormData={ConsumableFormData}
          setTotalConsumableCost={setTotalConsumableCost}
          handleSubmit={handleSubmit}
          CenterLaborCost={CenterLaborCost}
          setTotalLaborCost={setTotalLaborCost}
          // LaberCostType={LaberCostType}
          LaborCostFormData={LaborCostFormData}
          ServiceCodeCostType={ServiceCodeCostType}
          totalConsumableCost={totalConsumableCost}
          totalLaborCost={totalLaborCost}
          totalServiceCost={totalServiceCost}
          totalCost={totalCost}
          defaultCenterOverHeadCostPercentage={defaultCenterOverHeadCostPercentage}
          overHeadCost={overHeadCost}
          onSaveClick={onSaveClick}
          isUpsertFetching={isUpsertFetching}
        />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const {
    combos,
    // get_service_code,
    center_over_head_cost_get,
  } = state;
  const currentServiceFormValues = selector(
    state,
    'serviceCodeType',
    'serviceCode',
    'OverHeadCostPercentage',
  );
  // const { data: serviceCodeinfoData, isFetching: isFetchingGetServiceCode } = get_service_code;
  let UnitOfMeasurement = [];

  let CenterConsumable = [];
  let CenterLaborCost = [];
  let ServiceCodeCostType = [];
  // let LaberCostType = [];
  let centerOverHeadCost = null;
  let PayFrequency = [];

  const ConsumableFormData = formValueSelector('serviceCodeForm')(state, 'Consumable') || [];
  const LaborCostFormData = formValueSelector('serviceCodeForm')(state, 'LaborCost') || [];
  const NetMarginPercentageValue =
    formValueSelector('serviceCodeForm')(state, 'NetMarginPercentage') || '';

  if (combos.data && combos.data['UnitOfMeasurement']) {
    UnitOfMeasurement = combos.data['UnitOfMeasurement'] || [];
  }
  if (combos.data && combos.data['CenterConsumable']) {
    CenterConsumable = combos.data['CenterConsumable'] || [];
  }
  if (combos.data && combos.data['CenterLaborCost']) {
    CenterLaborCost = combos.data['CenterLaborCost'] || [];
  }
  if (combos.data && combos.data['ServiceCodeCostType']) {
    ServiceCodeCostType = combos.data['ServiceCodeCostType'] || [];
  }
  // if (combos.data && combos.data['LaberCostType']) {
  //   LaberCostType = combos.data['LaberCostType'] || [];
  // }
  if (center_over_head_cost_get && center_over_head_cost_get.data) {
    centerOverHeadCost = center_over_head_cost_get.data.CenterOverHead[0].OverHeadValue;
  }
  if (combos.data && combos.data['PayFrequency']) {
    PayFrequency = combos.data['PayFrequency'] || [];
  }
  return {
    UnitOfMeasurement,
    currentServiceFormValues,
    CenterConsumable,
    CenterLaborCost,
    // isFetchingGetServiceCode,
    ConsumableFormData,
    LaborCostFormData,
    // LaberCostType,
    centerOverHeadCost,
    NetMarginPercentageValue,
    ServiceCodeCostType,
    PayFrequency,
  };
};

const selector = formValueSelector('serviceCodeForm');
const ReduxServiceCodeForm = reduxForm({
  form: 'serviceCodeForm',
  enableReinitialize: true,
  // initialValues: { serviceCodeType: 1, Consumable: [{ Food: 'Chinese', Consumable: 36 }] },
})(connect(mapStateToProps)(withRouter(ServiceCodeForm)));

export default ReduxServiceCodeForm;
