import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
// import CustomModal2 from '../../../../../../core/modal';
import CustomModal from '../../../../../../core/modal';
import { Upload, message, Modal, Popconfirm, Tooltip, Drawer, Button } from 'antd';
import Icon from '@ant-design/icons';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import Axios from 'axios';

import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
// import validate, {
//   dateofbirthNormalize,
//   ssnFormatter,
//   ssnNormalize,
//   MobileFormatter,
//   MobileNormalize,
// } from '../../../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
} from '../../../../../../component/custom-redux-components';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';
import {
  listCombos,
  medicateCombos,
  practitionerCombosOffice,
  upsertGroup,
  listGroupMembership,
  resetAddCallSession,
  deleteGroup,
  getPatientList,
  getClientGroup,
  upsertClientGroup,
  getListClientGroup,
  appointmentNoteList,
  getAppointmentNote,
  upsertAppointmentNote,
  upsertAppointmentNoteAddendum,
  resetUpsertAppointmentNote,
  comboTreatmentPlanIntervention,
  getTreatmentPlanGraph,
} from '../../../../../../store/actions';
import AntdModal from '../../../../../../Components/CustomModal';
import { image } from 'faker';
import { ReduxNoteAddEditBox } from './noteAddEditBox';
// const { Button } = antd;
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

class AttendeeNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editGroupData: {},
      createAndEditGroupModal: false,
      manageGroupsModal: false,
      newAddendumBtn: true,
      visible: false,
      noteAddEditBox: true,
      editAddendumObj: {},
      note: '',
      editAppointmentId: '',
      Addendum: '',
      noteBoxInitObj: {},
      selectedInterventions: [],
      showNoteSucess: '',
      // forceRender: false,
    };
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  sendSelectedInterventions = selectedItems => {
    this.setState({ ...this.state, selectedInterventions: selectedItems });
  };

  resetEditAddendumObj = obj => {
    // this.setState({ editAddendumObj: {} });
    this.setState({ editAddendumObj: obj });
  };
  resetnoteAddEditBox = () => {
    this.setState({
      noteAddEditBox: !this.state.noteAddEditBox,
    });
  };

  resetnewAddendumBtn = () => {
    this.setState({
      editAddendumObj: {},
      newAddendumBtn: !this.state.newAddendumBtn,
    });
  };

  getFormTypeAndFormId = (formType, formId) => {
    this.setState({
      formType,
      formId,
    });
  };
  resetnote = value => {
    this.setState({
      note: value,
      // forceRender: true,
    });
  };

  reseteditAppointmentId = value => {
    this.setState({
      editAppointmentId: value,
    });
  };

  callAppDataAPI = () => {
    this.props.dispatch(
      getAppointmentNote({
        Json: JSON.stringify({
          AppointmentNote: [
            {
              // AppointmentId: AppointmentId,
              ClientId: this.props.clientId,
              GroupAppointmentId: this.props.groupAppointmentId,
            },
          ],
        }),
      }),
    );
  };
  showDrawer = AppointmentId => {
    this.callAppDataAPI();
    this.setState({
      visible: true,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      visible: false,
      newAddendumBtn: true,
    });
  };
  resetInitValues = () => {
    this.setState({ noteBoxInitObj: {} });
  };
  onChange = (record, index, e) => {
    // console.log(`checked = ${e.target.checked}`);
    let result = this.state.ListGroup;
    result[index].IsGroupMember = !record.IsGroupMember;
    // let keys = Object.keys(result);
    // let filtered = keys.filter(key => {
    //   return result[key];
    // });
    this.setState({
      ListGroup: result,
    });
    if (record.IsGroupMember) {
      this.props.dispatch(
        upsertClientGroup({
          json: JSON.stringify({
            ClientGroup: [
              {
                ClientGroupId: null,
                GroupId: record.GroupId,
                ClientId: this.props.clientId,
                IsAdd: true,
              },
            ],
          }),
        }),
      );
    } else {
      this.props.dispatch(
        upsertClientGroup({
          json: JSON.stringify({
            ClientGroup: [
              {
                ClientGroupId: record.ClientGroupId,
                GroupId: record.GroupId,
                ClientId: this.props.clientId,
                IsAdd: false,
              },
            ],
          }),
        }),
      );
    }
    if (e.target.checked == false) {
      // this.props.dispatch(
      //   upsertClientGroup({
      //     json: JSON.stringify({
      //       ClientGroup: [
      //         {
      //           ClientGroupId: record.ClientGroupId,
      //           GroupId: record.GroupId,
      //           ClientId: this.props.clientId,
      //           IsAdd: false,
      //         },
      //       ],
      //     }),
      //   }),
      // );
    }
  };

  editAddendum = () => {
    this.setState({ newAddendumBtn: false });
  };

  getIsSignStatus = isSignStatusValue => {
    this.setState({
      isSignStatus: isSignStatusValue,
    });
  };

  componentDidMount() {
    this.props.dispatch(
      getAppointmentNote({
        Json: JSON.stringify({
          AppointmentNote: [
            {
              // AppointmentId: AppointmentId,
              ClientId: this.props.clientId,
              GroupAppointmentId: this.props.groupAppointmentId,
            },
          ],
        }),
      }),
    );

    this.props.dispatch(
      comboTreatmentPlanIntervention({
        json: JSON.stringify({
          TreatmentPlanIntervention: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
    let medicateComboTypes = {
      ComboTypes: 'ServiceCode',
    };

    // this.props.dispatch(medicateCombos(medicateComboTypes));
    // this.props.dispatch(listGroupMembership({}));
    this.props.dispatch(getPatientList({}));
    this.props.dispatch(
      listGroupMembership({
        json: JSON.stringify({
          Client: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      appointmentNoteList({
        Json: JSON.stringify({
          AppointmentNote: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  }

  componentWillReceiveProps({
    isGroupCreated,
    ListGroup,
    AppointmentNote,
    upsertAppointmentNoteSuccess,
    upsertAppointmentNoteAddendumSuccess,
    validateStaffPinData,
    newlyGenratedAttendeeAddendumAppointmentNoteId,
    clientId,
  }) {
    if (clientId && clientId != this.props.clientId) {
      this.props.dispatch(
        getAppointmentNote({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                // AppointmentId: AppointmentId,
                ClientId: clientId,
                GroupAppointmentId: this.props.groupAppointmentId,
              },
            ],
          }),
        }),
      );

      this.props.dispatch(
        comboTreatmentPlanIntervention({
          json: JSON.stringify({
            TreatmentPlanIntervention: [
              {
                ClientId: clientId,
              },
            ],
          }),
        }),
      );
      let medicateComboTypes = {
        ComboTypes: 'ServiceCode',
      };

      // this.props.dispatch(medicateCombos(medicateComboTypes));
      // this.props.dispatch(listGroupMembership({}));
      this.props.dispatch(getPatientList({}));
      this.props.dispatch(
        listGroupMembership({
          json: JSON.stringify({
            Client: [
              {
                ClientId: clientId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(
        appointmentNoteList({
          Json: JSON.stringify({
            AppointmentNote: [
              {
                ClientId: clientId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(
        getTreatmentPlanGraph({
          Json: JSON.stringify({ TreatmentPlan: [{ ClientId: clientId }] }),
        }),
      );
      this.setState({ ...this.state, pinConfirmModalVisible: false });
    }
    if (isGroupCreated && isGroupCreated != this.props.isGroupCreated) {
      // this.props.dispatch(listGroupMembership({}));
      this.props.dispatch(getPatientList({}));
      this.props.dispatch(
        listGroupMembership({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
      this.setState({
        inviteMsg: 'Group created successfully.',
        isModelOpen: true,
      });
    }
    if (ListGroup && JSON.stringify(ListGroup) != JSON.stringify(this.props.ListGroup)) {
      this.setState({
        ListGroup,
      });
    }
    if (
      AppointmentNote &&
      JSON.stringify(AppointmentNote) != JSON.stringify(this.props.AppointmentNote)
    ) {
      const { Note } = AppointmentNote;
      if (Note && Note.length > 0) {
        this.setState({ noteAddEditBox: false });
      } else {
        this.setState({ noteAddEditBox: true });
      }
      let newObj = {
        note: AppointmentNote.Note,
        startTime: AppointmentNote.StartTime,
        endTime: AppointmentNote.EndTime,
        comboTreatmentPlanIntervention: AppointmentNote.TreatmentPlanInterventionId,
      };
      this.setState({
        noteBoxInitObj: newObj,
      });
    }
    if (
      upsertAppointmentNoteSuccess &&
      upsertAppointmentNoteSuccess != this.props.upsertAppointmentNoteSuccess
    ) {
      if (this.state.isSignStatus) {
        this.callAppDataAPI();
        let data = {
          Json: JSON.stringify({
            Staff: [
              {
                FormType: this.state.formType,
                FormId: this.state.formId,
              },
            ],
          }),
        };
        let me = this;
        Axios.post('Medicaid/PreValidatePin', data).then(response => {
          let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
          let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
          let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;
          if (isValid) {
            me.setState({
              note: '',
              newAddendumBtn: true,
              pinConfirmModalVisible: true,
              // isModelOpen: true,
              IsRealSignature: IsRealSignature,
            });
            // me.toggle('Note Added Successfully');
            me.props.dispatch(resetUpsertAppointmentNote());
            if (me.props.AppointmentNote.AppointmentNoteId == null) {
              // me.props.OnCloseDrawer();
              me.props.dispatch(
                appointmentNoteList({
                  Json: JSON.stringify({
                    AppointmentNote: [
                      {
                        ClientId: me.props.ClientId,
                      },
                    ],
                  }),
                }),
              );
            } else {
              if (me.state.isSignStatus) {
                if (validateStaffPinData.IsValid) {
                  me.setState({ note: '' });

                  me.props.dispatch(resetUpsertAppointmentNote());

                  me.setState(
                    {
                      showNoteSucess: 'Note Updated Successfully',
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          showNoteSucess: '',
                        });
                      }, 2000);
                    },
                  );
                  me.props.callDataAPI();

                  me.callAppDataAPI();

                  // me.props.resetManageAttendeeNote();
                  //commented above line to stop it from moving to group note section after signing attendee note.

                  // me.props.toggle('Note Signed Successfully');
                  // me.props.OnCloseDrawer();
                  me.props.dispatch(
                    appointmentNoteList({
                      Json: JSON.stringify({
                        AppointmentNote: [
                          {
                            ClientId: me.props.ClientId,
                          },
                        ],
                      }),
                    }),
                  );
                }
              } else {
                // me.props.toggle('Note Updated Successfully');
                // me.props.OnCloseDrawer();

                me.props.dispatch(
                  appointmentNoteList({
                    Json: JSON.stringify({
                      AppointmentNote: [
                        {
                          ClientId: me.props.ClientId,
                        },
                      ],
                    }),
                  }),
                );
              }
            }
          } else {
            me.props.toggle(failMsg);
            me.setState({
              isSignStatus: false,
            });
          }
        });
      } else {
        // this.props.toggle('Note Updated Successfully');
        // this.props.OnCloseDrawer();

        this.setState(
          {
            showNoteSucess: 'Note Updated Successfully',
          },
          () => {
            setTimeout(() => {
              this.setState({
                showNoteSucess: '',
              });
            }, 2000);
          },
        );
        this.props.callDataAPI();
        this.callAppDataAPI();
        this.props.dispatch(
          appointmentNoteList({
            Json: JSON.stringify({
              AppointmentNote: [
                {
                  ClientId: this.props.ClientId,
                },
              ],
            }),
          }),
        );
      }

      // this.setState({ note: '' });

      // this.props.dispatch(
      //   appointmentNoteList({
      //     Json: JSON.stringify({
      //       AppointmentNote: [
      //         {
      //           ClientId: this.props.clientId,
      //         },
      //       ],
      //     }),
      //   }),
      // );
      // this.props.dispatch(resetUpsertAppointmentNote());

      // this.setState(
      //   {
      //     showNoteSucess: 'Note Updated Successfully',
      //   },
      //   () => {
      //     setTimeout(() => {
      //       this.setState({
      //         showNoteSucess: '',
      //       });
      //     }, 2000);
      //   },
      // );
      // this.props.callDataAPI();
    }

    if (
      upsertAppointmentNoteAddendumSuccess &&
      upsertAppointmentNoteAddendumSuccess != this.props.upsertAppointmentNoteAddendumSuccess
    ) {
      if (this.state.isAppointmentNoteAddendumSignStatus) {
        let data = {
          Json: JSON.stringify({
            Staff: [
              {
                FormType: this.state.formType,
                FormId: this.state.formId,
              },
            ],
          }),
        };
        let me = this;
        Axios.post('Medicaid/PreValidatePin', data).then(response => {
          let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
          let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
          let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;

          if (isValid) {
            me.setState({
              note: '',
              newAddendumBtn: true,
              pinConfirmModalVisible: true,
              IsRealSignature: IsRealSignature,
              isAppointmentNoteAddendumSignStatus: false,
              newlyGenratedAttendeeAddendumAppointmentNoteId: newlyGenratedAttendeeAddendumAppointmentNoteId,
            });
          } else {
            this.setState({
              // pinConfirmModalVisible: false,
              groupMsg: failMsg,
              failOn: true,
            });
            setTimeout(() => {
              this.setState({
                groupMsg: '',
                failOn: false,
              });
            }, 5000);
          }
        });
      } else {
        this.props.dispatch(
          appointmentNoteList({
            Json: JSON.stringify({
              AppointmentNote: [
                {
                  ClientId: this.props.clientId,
                },
              ],
            }),
          }),
        );
        this.props.callDataAPI();
        this.toggle('Addendum Updated Successfully');
        // if (this.props.AppointmentNote.AppointmentNoteAddendumId == null) {
        //   debugger;
        //   this.toggle('Addendum Added Successfully');
        // } else {
        //   this.toggle('Addendum Updated Successfully');
        // }
        // this.props.onCloseDrawer();
      }
    }
  }
  resetEditGroup = () => {
    this.setState({ editGroupData: {} });
  };

  onSubmit = values => {
    const { groupId } = this.state.editGroupData;
    var data = {
      Group: [
        {
          GroupId: groupId || null,
          GroupName: values.groupName,
          ServiceCodeId: values.serviceCode,
          CounsellorId: values.counselor,
          PractitionerOfficeId: values.officelocation,
        },
      ],
    };
    var GroupData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertGroup(GroupData));
    this.setState({ createAndEditGroupModal: false, resetEditGroup: {} });
  };

  deleteGroup = groupId => {
    this.props.dispatch(
      deleteGroup({
        json: JSON.stringify({
          Group: [
            {
              GroupId: groupId,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(listGroupMembership({}));
    // let editRecord = {
    //   groupId: record.GroupId,
    //   groupName: record.GroupName,
    //   serviceCode: parseInt(record.ServiceCodeId),
    //   counselor: record.CounsellorId,
    //   officelocation: JSON.stringify(record.PractitionerOfficeId),
    // };
    // this.setState({ editGroupData: editRecord });
  };

  editGroup = record => {
    this.props.dispatch(
      practitionerCombosOffice({
        json: JSON.stringify({
          PractitionerOffice: [
            {
              PractitionerId: record.CounsellorId,
            },
          ],
        }),
      }),
    );

    let editRecord = {
      groupId: record.GroupId,
      groupName: record.GroupName,
      serviceCode: parseInt(record.ServiceCodeId),
      counselor: record.CounsellorId,
      officelocation: JSON.stringify(record.PractitionerOfficeId),
    };
    this.setState({ editGroupData: editRecord });
  };

  onGroupAssign = values => {
    const { groupId } = this.state.editGroupData;
    var data = {
      Group: [
        {
          GroupId: groupId || null,
          GroupName: values.groupName,
          ServiceCodeId: values.serviceCode,
          CounsellorId: values.counselor,
          PractitionerOfficeId: values.officelocation,
        },
      ],
    };
    var GroupData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertGroup(GroupData));
    this.setState({ createAndEditGroupModal: false, resetEditGroup: {} });
  };
  getAppointmentNoteAddendumSign = AppointmentNoteAddendumSign => {
    this.setState({
      isAppointmentNoteAddendumSignStatus: AppointmentNoteAddendumSign,
    });
  };

  offTheModal = () => {
    this.setState({ ...this.state, pinConfirmModalVisible: false });
  };

  render() {
    const { columns, newAddendumBtn, editAddendumObj, noteAddEditBox } = this.state;
    const { isFetching, isFetchingGetAppointmentNote } = this.props;

    const {
      AppointmentId,
      AppointmentNoteId,
      SessionDate,
      SessionTime,
      SessionType,
      ServiceCodeDescription,
      ProgramDescription,
      Status,
      AddendumCount,
      IsSign,
      ClientName,
      SignedDate,
      AppointmentNote,
      Note,
      AppointmentNoteAddendum,
      DurationInMin,
      ServiceCode,
      AppointmentNoteIntervention,
      SignedMessage,
      NoteSignHoursLeft,
      IsEligibleForUnSign,
    } = this.props.AppointmentNote;
    return (
      <React.Fragment>
        <ReactToPrint
          trigger={() => (
            <div className="text-right mt-2 mb-5">
              {' '}
              <button style={{ border: 'none' }}>
                <img src="images/printSVG.svg" />
              </button>
            </div>
          )}
          content={() => this.componentRef}
          documentTitle="Notes"
        />
        <div ref={el => (this.componentRef = el)} className="pageStyle">
          <Loader2 loading={isFetchingGetAppointmentNote} />
          <div className="individualNotes" style={{ position: 'relative' }}>
            <p style={{ color: '#24bd4c' }}>{this.state.showNoteSucess}</p>
            <div className="row">
              <div className="col-md-8">
                <h2
                  style={{
                    color: '#000',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    lineHeight: '27px',
                  }}
                >
                  {ClientName}
                </h2>
              </div>
              <div className="col-md-4 text-right">
                {/* <i class="far fa-clock"></i> &nbsp;
                  {SessionTime} */}
                <p
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    lineHeight: '22px',
                    textTransform: 'uppercase',
                    color: '#828282',
                  }}
                >
                  {SessionDate}
                </p>
              </div>
              {/* <div className="col-md-12">
                <h5>SESSION DATE</h5>
                <p style={{ fontSize: '20px', fontWeight: 'bold' }}></p>
              </div> */}
            </div>
          </div>
          <ReduxNoteAddEditBox
            getAppointmentNoteAddendumSign={this.getAppointmentNoteAddendumSign}
            sendSelectedInterventions={this.sendSelectedInterventions}
            noteAddEditBox={noteAddEditBox}
            AppointmentNote={AppointmentNote}
            AppointmentId={AppointmentId}
            SignedDate={SignedDate}
            IsSign={IsSign}
            Note={Note}
            newAddendumBtn={this.state.newAddendumBtn}
            editAddendumObj={this.state.editAddendumObj}
            AppointmentNoteAddendum={AppointmentNoteAddendum}
            AddendumCount={AddendumCount}
            resetnote={this.resetnote}
            resetnewAddendumBtn={this.resetnewAddendumBtn}
            resetnoteAddEditBox={this.resetnoteAddEditBox}
            localNote={this.state.note}
            editAppointmentId={this.state.editAppointmentId}
            dispatch={this.props.dispatch}
            editAddendum={this.editAddendum}
            resetEditAddendumObj={this.resetEditAddendumObj}
            ServiceCodeDescription={ServiceCodeDescription}
            SessionType={SessionType}
            ProgramDescription={ProgramDescription}
            initialValues={this.state.noteBoxInitObj}
            resetInitValues={this.resetInitValues}
            AppointmentNoteId={AppointmentNoteId}
            DurationInMin={DurationInMin}
            ClientId={this.props.clientId}
            ServiceCode={ServiceCode}
            GroupAppointmentId={this.props.groupAppointmentId}
            AppointmentNoteIntervention={AppointmentNoteIntervention}
            callAppDataAPI={this.callAppDataAPI}
            resetManageAttendeeNote={this.props.resetManageAttendeeNote}
            SignedMessage={SignedMessage}
            NoteSignHoursLeft={NoteSignHoursLeft}
            IsEligibleForUnSign={IsEligibleForUnSign}
            getIsSignStatus={this.getIsSignStatus}
            getFormTypeAndFormId={this.getFormTypeAndFormId}
            IsRealSignature={this.state.IsRealSignature}
            pinConfirmModalVisible={this.state.pinConfirmModalVisible}
            offTheModal={this.offTheModal}
            genratedAttendeeAppointmentNoteId={this.props.genratedAttendeeAppointmentNoteId}
            newlyGenratedAttendeeAddendumAppointmentNoteId={
              this.props.newlyGenratedAttendeeAddendumAppointmentNoteId
            }
            getDirtySubmitSucceededStatus={this.props.getDirtySubmitSucceededStatus}
          />
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              // this.props.onCloseDrawer();
              this.props.resetManageAttendeeNote();
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
        {this.state.selectedInterventions && this.state.selectedInterventions.length > 0 && (
          <div
            className="col-md-12 text-left mt-3 px-0"
            style={{
              color: 'rgb(130, 130, 130)',
              fontSize: '16px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            {' '}
            {/* Selected Interventions{' '} */}
          </div>
        )}

        <ol>
          {this.state.selectedInterventions &&
            this.state.selectedInterventions.map(v => (
              <li style={{ color: 'rgb(130, 130, 130)' }}>{v}</li>
            ))}
        </ol>
      </React.Fragment>
    );
  }
}

// let ManageGroupsForm = () => {
//   const { ServiceCode } = medicateCombos;
//   return (
//   );
// };

const mapStateToProps = ({
  medicate_combos,
  practitioner_combos,
  practitioner_combos_office,
  list_group_membership,
  upsert_group,
  get_patient_list,
  get_client_group,
  upsert_client_group,
  get_list_client_group,
  appointment_note_list,
  get_appointment_note,
  upsert_appointment_note,
  upsert_appointment_note_addendum,
  validate_staff_pin,
  combos,
}) => {
  const { data: medicateCombos } = medicate_combos;
  const { data: practitionerData } = practitioner_combos;
  const { data: practitionerOfficeData } = practitioner_combos_office;
  const { data: listgroup, isFetching } = list_group_membership;
  const { data: getPatientListData } = get_patient_list;
  const { data: getClientListData, isFetching: isFetchingGroupClient } = get_client_group;
  const {
    data: getListClientGroup,
    isFetching: isFetchingGetListClienGroup,
  } = get_list_client_group;
  const { data: appointmentNoteList } = appointment_note_list;
  const {
    data: getAppointmentNote,
    isFetching: isFetchingGetAppointmentNote,
  } = get_appointment_note;
  // const { data: upsertAppointmentNote } = upsert_appointment_note;
  var { data: validateDataPin } = validate_staff_pin;
  let Client = [];
  let GroupClientList = [];
  let isSuccess = false;
  let medicateCombosObj = {
    ServiceCode: [],
  };
  let Practitioner = [];
  let PractitionerOffice = [];
  let ListGroup = [];
  let isGroupCreated = false;
  let searchedClient = [];
  let noteList = [];
  let AppointmentNote = [];
  let upsertAppointmentNoteSuccess = false;
  let upsertAppointmentNoteAddendumSuccess = false;
  let validateStaffPinData = {};
  let genratedAttendeeAppointmentNoteId;
  let newlyGenratedAttendeeAddendumAppointmentNoteId;

  if (getClientListData) {
    GroupClientList = getClientListData.ClientGroup;
  }
  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList;
  }
  if (practitionerOfficeData) {
    PractitionerOffice = practitionerOfficeData.PractitionerOfficeList || [];
  }
  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
    };
  }

  if (listgroup && listgroup.Group) {
    ListGroup = listgroup.Group;
  }
  if (upsert_group && upsert_group.data && upsert_group.data.success) {
    isGroupCreated = upsert_group.data.success;
  }
  if (getPatientListData && getPatientListData.data) {
    Client = JSON.parse(getPatientListData.data)[0].Client;
  }
  if (upsert_client_group.data && upsert_client_group.data.success) {
    isSuccess = upsert_client_group.data.success;
  }
  if (getListClientGroup) {
    searchedClient = getListClientGroup.Client || [];
  }
  if (appointmentNoteList) {
    noteList = appointmentNoteList[0].AppointmentNote || [];
  }
  if (getAppointmentNote) {
    AppointmentNote = getAppointmentNote[0].AppointmentNote[0] || [];
  }

  if (upsert_appointment_note && upsert_appointment_note.data) {
    upsertAppointmentNoteSuccess = upsert_appointment_note.data.success;
    genratedAttendeeAppointmentNoteId = upsert_appointment_note.data.result;
  }
  if (upsert_appointment_note_addendum && upsert_appointment_note_addendum.data) {
    upsertAppointmentNoteAddendumSuccess = upsert_appointment_note_addendum.data.success;
    newlyGenratedAttendeeAddendumAppointmentNoteId = upsert_appointment_note_addendum.data.result;
  }
  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }

  return {
    medicateCombosObj: medicateCombosObj,
    Practitioner: Practitioner,
    PractitionerOffice: PractitionerOffice,
    ListGroup,
    isGroupCreated,
    isFetching,
    isFetchingGroupClient,
    isFetchingGetListClienGroup,
    client: Client || [],
    GroupClientList,
    isSuccess,
    searchedClient,
    noteList,
    AppointmentNote,
    upsertAppointmentNoteSuccess,
    isFetchingGetAppointmentNote,
    upsertAppointmentNoteAddendumSuccess,
    validateStaffPinData,
    genratedAttendeeAppointmentNoteId,
    newlyGenratedAttendeeAddendumAppointmentNoteId,
  };
};
export default connect(mapStateToProps)(withRouter(AttendeeNotes));
