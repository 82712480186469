import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button, Drawer, Tooltip } from 'antd';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
} from '../../../../../component/custom-redux-components';
import AssignedTask from './assignNewTask';
import AssignedPreDefinedTask from './assignPreDefinedTask';
import TaskList from './listComponent';
import { taskClientUpsert, taskClientListGet, listCombos } from '../../../../../store/actions';
import CustomModal from '../../../../../core/modal';
class ClinicalTasks extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      // SearchStr: null,
      showContentDrawer: false,
      filterId: null,
      taskType: null,
      isModelOpen: false,
    };
  }

  changeDrawerState = Title => {
    this.setState((prevState, props) => ({
      showContentDrawer: !prevState.showContentDrawer,
      taskType: 'newTask',
      repeatTaskTitle: Title,
    }));
  };

  getPatientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var patientId = param.get('pId') || param.get('pId');
    if (patientId) {
      return (patientId = parseInt(patientId));
    } else {
      patientId = -1;
    }
    return patientId;
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  getLastData = () => {
    let data = {
      Task: [
        {
          ClientId: this.getPatientId(),
        },
      ],
    };
    let Data = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(taskClientListGet(Data));
  };

  onSearchChange = e => {
    let data = {
      Task: [
        {
          ClientId: this.getPatientId(),
          Search: e.target.value,
        },
      ],
    };
    let Data = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(taskClientListGet(Data));
  };

  callListCombo = () => {
    this.props.dispatch(
      listCombos({
        comboTypes: 'clinicaltask,clinicaltaskappointment,CWTaskDropdownOption',
        Json: JSON.stringify({
          User: { ClientId: this.getPatientId() },
        }),
      }),
    );
  };

  componentDidMount() {
    this.getLastData();
    this.callListCombo();
  }

  componentWillReceiveProps({ isTaskReassigned, isTaskUpsertSuccess, isLinkingSuccess, statusId }) {
    if (isTaskReassigned && isTaskReassigned != this.props.isTaskReassigned) {
      this.toggle('Task repeated successfully');
    }
    if (isTaskUpsertSuccess && isTaskUpsertSuccess != this.props.isTaskUpsertSuccess) {
      this.setState({ showContentDrawer: false, taskType: null }, () => {
        this.toggle('Task added successfully');
      });
    }
    if (isLinkingSuccess && isLinkingSuccess != this.props.isLinkingSuccess) {
      if (statusId == 1) {
        this.toggle('Task Marked as Complete');
      } else if (statusId == 2) {
        this.toggle('Task Marked as Failed');
      } else {
        this.toggle('Appointment linked successfully');
      }
    }
  }

  render() {
    const { isReadOnlyUser, prescriptionList, isFetching } = this.props;
    return (
      <React.Fragment>
        <div className="container clinicalTaskSection">
          <div className="row mt-3 justify-content-between align-items-center">
            <div className="col-2">
              <h5>Task List</h5>
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col-3">
                  {/* <h5>Filter</h5> */}
                  <ReduxSelect
                    options={this.props.taskFilterOptions}
                    onChange={value => {
                      this.setState({ filterId: value }, () => {
                        let data = {
                          Task: [
                            {
                              ClientId: this.getPatientId(),
                              FilterBy: value,
                            },
                          ],
                        };
                        let Data = {
                          Json: JSON.stringify(data),
                        };
                        this.props.dispatch(taskClientListGet(Data));
                      });
                      // this.props.dispatch(getSurveyQuestionMultiple(parseInt(value), 20000019));
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    // value={defaultCenter}
                    value={this.state.filterId}
                    placeholder="Select"
                  />
                </div>
                <div className="col-6">
                  <div className="" style={{ textAlign: 'right' }}>
                    <div class="search-box d-inline-flex w-100">
                      <div class="form-group has-search w-100" style={{ alignSelf: 'center' }}>
                        <input
                          type="text"
                          class="form-control 
                   shadow-sm rcm-document-search"
                          placeholder="Search"
                          id="filterbox"
                          onChange={this.onSearchChange}
                          style={{ minHeight: '44px' }}
                        />
                        <button class="btn search-icon " type="button">
                          {' '}
                          <i class="fa fa-search" />{' '}
                        </button>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <input
                    type="button"
                    class="btn black-btn px-3"
                    value="Add New Task"
                    onClick={() => {
                      this.setState({ showContentDrawer: true });
                    }}
                    style={{ alignSelf: 'center' }}
                    // disabled={this.props.isReadOnlyUser}
                  />
                </div>
              </div>
            </div>
          </div>
          <TaskList
            taskListData={this.props.taskListData}
            clinicalTaskAppointmentDropdown={this.props.clinicalTaskAppointmentDropdown}
            changeDrawerState={this.changeDrawerState}
          />

          <div className="">
            <Drawer
              className="custom-drawer"
              title="Add Task"
              placement="right"
              width="600px"
              closable={true}
              onClose={() => {
                this.setState({ showContentDrawer: false, taskType: null, repeatTaskTitle: null });
              }}
              open={this.state.showContentDrawer}
              destroyOnClose={true}
            >
              <div style={{ width: '100%' }}>
                <div class="form-group isMultiHeight custom-radio ">
                  <label className="mr-5">
                    Assign New Task
                    <ReduxInput
                      name="taskType"
                      // component={ReduxInput}
                      type="radio"
                      value="newTask"
                      style={{
                        width: '20px',
                        height: '20px',
                        border: 'solid 1px #bcbcbc',
                        marginRight: '5px',
                      }}
                      onChange={e => {
                        this.setState({ taskType: e.target.value });
                      }}
                      defaultValue={'checked'}
                      checked="checked"
                    />
                    <span className="checkmark"></span>
                  </label>{' '}
                  &nbsp;
                  <label className="mr-5">
                    Assign Predefined Task
                    <ReduxInput
                      name="taskType"
                      // component={ReduxInput}
                      type="radio"
                      value="predefinedTask"
                      style={{
                        width: '20px',
                        height: '20px',
                        border: 'solid 1px #bcbcbc',
                        marginRight: '5px',
                      }}
                      onChange={e => {
                        this.setState({ taskType: e.target.value });
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {this.state.taskType == 'newTask' ? (
                  <AssignedTask repeatTaskTitle={this.state.repeatTaskTitle} />
                ) : this.state.taskType == 'predefinedTask' ? (
                  <AssignedPreDefinedTask
                    clinicalTaskDropdown={this.props.clinicalTaskDropdown}
                  />
                ) : (
                  <>Please select any one to assign.</>
                )}
              </div>
            </Drawer>
            <CustomModal
              isOpen={this.state.isModelOpen}
              header={this.state.header}
              yesAction={() => {
                this.getLastData();
                this.callListCombo();
                this.toggle();
              }}
              yesText="Ok"
              noText="Cancel"
              hideCancel={true}
              zIndex={999999}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// prescription_list_get

const mapStateToProps = ({
  combos,
  task_client_list_get,
  task_client_reassign,
  task_client_upsert,
  task_client_update,
}) => {
  var { data, isFetching, error } = combos;
  var { data: taskListValue } = task_client_list_get;
  var { data: taskReassignData } = task_client_reassign;
  var { data: taskUpsertData } = task_client_upsert;
  var { data: taskLinkData } = task_client_update;

  let clinicalTaskDropdown;
  let clinicalTaskAppointmentDropdown;
  let taskListData;
  let isTaskReassigned;
  let isTaskUpsertSuccess;
  let taskFilterOptions;
  let isLinkingSuccess;
  let statusId;

  if (combos.data && combos.data.clinicaltask) {
    clinicalTaskDropdown = combos.data['clinicaltask'];
  }
  if (combos.data && combos.data.clinicaltaskappointment) {
    clinicalTaskAppointmentDropdown = combos.data['clinicaltaskappointment'];
  }
  if (combos.data && combos.data.CWTaskDropdownOption) {
    taskFilterOptions = combos.data['CWTaskDropdownOption'];
  }
  if (task_client_list_get && task_client_list_get.data) {
    taskListData = taskListValue.TaskClient;
  }
  if (task_client_reassign && task_client_reassign.data && task_client_reassign.data.success) {
    isTaskReassigned = taskReassignData.success;
  }
  if (task_client_upsert && task_client_upsert.data && task_client_upsert.data.success) {
    isTaskUpsertSuccess = taskUpsertData.success;
  }
  if (task_client_update && task_client_update.data && task_client_update.data.success) {
    isLinkingSuccess = taskLinkData.success;
    statusId = task_client_update.data.StatusId;
  }

  return {
    clinicalTaskDropdown,
    clinicalTaskAppointmentDropdown,
    taskListData,
    isTaskReassigned,
    isTaskUpsertSuccess,
    taskFilterOptions,
    isLinkingSuccess,
    statusId,
  };
};

export default withRouter(connect(mapStateToProps)(ClinicalTasks));
