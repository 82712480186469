import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { Button } from 'antd';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';
import { getSurveyScore } from '../../../../../../store/actions';
class ScoreType extends React.Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      value: Response,
      SurveyScore: '',
      ScoreTypeQuestionId: false,
    };
  }
  onChange = (event, click) => {
    const { value } = event.target;
    // const { SurveyQuestionId } = this.props.survey;
    this.setState({
      value: value,
    });
  };
  componentWillReceiveProps({ score }) {
    if (
      score &&
      score.SurveyQuestionId &&
      score.SurveyQuestionId != this.props.score.SurveyQuestionId
    ) {
      this.setState({
        [score.SurveyQuestionId]: score.SurveyScore,
        [`${score.SurveyQuestionId}Text`]: score.ChildSurveyScore,
        ScoreTypeQuestionId: false,
      });
    }
  }
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      score,
      isReadOnly,
      SurveyId,
      isFetching,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    return (
      <>
        <div className="type-border col-12">
          <div>
            <SurveyQuestion survey={survey} ClassName={'mb-3'} />
            <div className="row align-items-center">
              <div className="col-8">
                {/* <div className="answer-options text-left mb-0 d-flex">
                  <div
                    className="border"
                    style={{
                      width: '680px',
                      borderRadius: '10px',
                      backgroundColor: '#F8F8F8',
                      maxHeight: '50px',
                    }}
                  >
                    <div className="">
                      <input
                        type="text"
                        value={this.state[SurveyQuestionId] || Response}
                        tabindex="0"
                        style={{ textAlign: 'center', paddingLeft: '10px', width: '100%' }}
                        className="alphanumeric-type"
                        disabled
                      />
                    </div>
                  </div>
                </div> */}
                <div className="col-12 text-left mt-2 px-0">
                  <div className="score-text">
                    <p>
                      {(this.state[SurveyQuestionId] || Response) &&
                        renderHTML(this.state[SurveyQuestionId] || Response)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-2" style={{ marginTop: '10px' }}>
                {!isReadOnly && (
                  <>
                    <Button
                      loading={this.state.ScoreTypeQuestionId && isFetching}
                      type="primary"
                      style={{
                        fontWeight: 'bold',
                        borderRadius: '26px',
                        height: '42px',
                        border: 0,
                        color: '#fff',
                        padding: '10px 20px',
                        // lineHeight: '24px',
                        background:
                          'linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%)',
                        backgroundSize: '300% 100%',
                        transition: 'all 300ms ease-in-out',
                        marginLeft: '-45px',
                      }}
                      // className="btn btn-outline-primary"
                      onClick={() => {
                        // this.props.dispatch(getSurveyScore({}));
                        this.props.dispatch(
                          getSurveyScore({
                            json: JSON.stringify({
                              Survey: [
                                {
                                  SurveyId: SurveyId,
                                  SurveyQuestionId: SurveyQuestionId,
                                },
                              ],
                            }),
                          }),
                        );
                        this.setState({
                          ScoreTypeQuestionId: SurveyQuestionId,
                        });
                      }}
                    >
                      {Response || this.state[SurveyQuestionId] ? 'Recalculate' : 'Calculate'}
                    </Button>
                  </>
                )}
              </div>
            </div>
            <DoesNotApplyOption surveyProps={this.props} />
          </div>
        </div>
        {/*     
        <div class="survey-box" style={{ width: '100%', display: 'inline-block;' }}>
          <h2>{Question}</h2>
          <div class="survey-form-box">
            <div class="survey-form-left">
              <div class="form-group survey-textinput">
                <div class="input-group">
                  <input class="form-control" type="text" style={{ textAlign: 'center' }} />
                </div>
              </div>
              <span class="small-text" />
            </div>
            <div class="survey-form-right">
              <input
                type="button"
                class="form-control survey-form-btn"
                value="Next"
                tabindex="0"
                onClick={onClick.bind(this)}
              />
            </div>
            <div class="clearfix" />
          </div>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = ({ get_survey_score }) => {
  const { data, isFetching, error } = get_survey_score;
  let score = '';
  if (data && data.Survey) {
    score = data.Survey[0] || '';
  }
  return {
    score: score,
    isFetching: isFetching,
  };
};
export default connect(mapStateToProps)(ScoreType);
