import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
import CustomModal2 from '../../../../../../core/modal';
import { Upload, message, Modal, Popconfirm, Tooltip, Drawer, Button, Input } from 'antd';
import Icon from '@ant-design/icons';
import Autocomplete from 'react-autocomplete';
import { Field, reduxForm, FieldArray, formValueSelector, isDirty } from 'redux-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TreeDataComponent } from './treeDataComponent';
import { TreeDataComponentView } from './treeDataComponentView';
import ValidatePin from '../../../../../../Components/validatePinModal';
import moment from 'moment';
import renderHTML from 'react-render-html';
import TextareaAutosize from 'react-autosize-textarea';
import Mood from '../../../../../../Components/mood';
// import validate, {
//   dateofbirthNormalize,
//   ssnFormatter,
//   ssnNormalize,
//   MobileFormatter,
//   MobileNormalize,
// } from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
} from '../../../../../../component/custom-redux-components';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';
import {
  listCombos,
  medicateCombos,
  practitionerCombosOffice,
  upsertGroup,
  listGroupMembership,
  resetAddCallSession,
  deleteGroup,
  getPatientList,
  getClientGroup,
  upsertClientGroup,
  getListClientGroup,
  appointmentNoteList,
  getAppointmentNote,
  upsertAppointmentNote,
  upsertAppointmentNoteAddendum,
  getTreatmentPlanGraph,
  unsignForm,
} from '../../../../../../store/actions';
import { mapTime, timeConvert } from '../../../../../../util/time';
import AntdModal from '../../../../../../Components/CustomModal';
import { image } from 'faker';
import { DurationInMinCalc } from '../../../../../../util/time';
import { get } from 'lodash';
import DictationComponent from '../../../../../../Components/DictationComponent';

const { TextArea } = Input;

// const { Button } = antd;
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

class NoteAddEditBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Addendum: '',
      sign: false,
      childrenDrawer: false,
      durationValue: 0,
      checkedInterventions: [],
      checkedInterventionTitles: [],
      validPinModalVisible: false,
      noteApiObj: {},
      addendumNoteApiObj: {},
      validationFor: '',
      formType: '',
      formId: '',
    };
  }

  resetValidPinModalVisible = () => {
    this.setState({ validPinModalVisible: !this.state.validPinModalVisible });
  };

  handleDictate = result => {
    if (result) {
      if (this.props.presentNoteValue) {
        this.props.change('note', this.props.presentNoteValue + ' ' + result);
      } else {
        this.props.change('note', result);
      }
    }
  };
  handleDictateForAddedum = result => {
    if (result) {
      let data;
      if (this.state.Addendum) {
        data = this.state.Addendum + ' ' + result;
      } else {
        data = result;
      }
      this.setState({ Addendum: data });
    }
  };

  componentWillReceiveProps({
    editAddendumObj,
    durationInMin,
    AppointmentNote,
    validateStaffPinData,
    unsignFormStatus,
    pinConfirmModalVisible,
    genratedAttendeeAppointmentNoteId,
    submitSucceeded,
    isDirty,
    isPanasTagSubmit,
    isPanasDelete,
    ClientId,
  }) {
    if (ClientId && ClientId && ClientId !== this.props.ClientId) {
      this.setState({ ...this.state, sign: false, validPinModalVisible: false });
    }
    if (isPanasTagSubmit && isPanasTagSubmit != this.props.isPanasTagSubmit) {
      this.props.callAppDataAPI();
    }
    if (isPanasDelete && isPanasDelete != this.props.isPanasDelete) {
      this.props.callAppDataAPI();
    }
    if (isDirty && isDirty != this.props.isDirty) {
      this.props.getDirtySubmitSucceededStatus(isDirty, submitSucceeded);
    }
    if (submitSucceeded && submitSucceeded != this.props.submitSucceeded) {
      this.props.getDirtySubmitSucceededStatus(isDirty, submitSucceeded);
    }

    if (
      genratedAttendeeAppointmentNoteId &&
      genratedAttendeeAppointmentNoteId != this.props.genratedAttendeeAppointmentNoteId
    ) {
      this.setState({
        noteApiObj: {
          ...this.state.noteApiObj,
          AppointmentNoteId: genratedAttendeeAppointmentNoteId,
        },
      });
    }

    if (
      pinConfirmModalVisible != this.props.pinConfirmModalVisible &&
      this.state.sign &&
      ClientId === this.props.ClientId
    ) {
      this.resetValidPinModalVisible();
    }
    if (
      pinConfirmModalVisible &&
      pinConfirmModalVisible != this.props.pinConfirmModalVisible &&
      this.state.appointmentNoteAddendumSign
    ) {
      this.resetValidPinModalVisible();
    }

    if (unsignFormStatus && unsignFormStatus != this.props.unsignFormStatus) {
      this.props.callAppDataAPI();
    }

    if (
      JSON.stringify(editAddendumObj.Addendum) !=
      JSON.stringify(this.props.editAddendumObj.Addendum)
    ) {
      this.setState({ Addendum: editAddendumObj.Addendum });
    }

    if (AppointmentNote.DurationInMin != this.props.AppointmentNote.DurationInMin) {
      this.setState({
        durationValue: AppointmentNote.DurationInMin,
      });
    }
    if (durationInMin && durationInMin != this.props.durationInMin) {
      this.setState({
        durationValue: durationInMin,
      });
    }
    if (
      AppointmentNote &&
      AppointmentNote.TreatmentPlanInterventionId &&
      AppointmentNote.TreatmentPlanInterventionId.length > 0 &&
      AppointmentNote.TreatmentPlanInterventionId !=
        this.props.AppointmentNote.TreatmentPlanInterventionId
    ) {
      let currentlySelectedCheckboxString = AppointmentNote.TreatmentPlanInterventionId;
      let currentlySelectedCheckboxArray = currentlySelectedCheckboxString.split(',').map(item => {
        return parseInt(item);
      });

      this.setState({
        checkedInterventions: currentlySelectedCheckboxArray,
      });
    }

    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      if (this.state.validationFor == 'note') {
        if (validateStaffPinData.IsValid) {
          this.props.dispatch(
            upsertAppointmentNote({
              Json: JSON.stringify({
                AppointmentNote: [
                  {
                    ...this.state.noteApiObj,
                    StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId,
                    SupervisorStaffPinHistoryId:
                      validateStaffPinData.SupervisorStaffPinHistoryId || null,
                  },
                ],
              }),
            }),
          );
        }
      } else {
        if (validateStaffPinData.IsValid) {
          this.props.dispatch(
            upsertAppointmentNoteAddendum({
              Json: JSON.stringify({
                AppointmentNoteAddendum: [
                  {
                    ...this.state.addendumNoteApiObj,
                    IsSign: true,
                    AppointmentNoteAddendumId: this.props
                      .newlyGenratedAttendeeAddendumAppointmentNoteId,
                    StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId,
                  },
                ],
              }),
            }),
          );
        }
      }
    }
  }

  componentDidMount() {
    this.props.dispatch(
      getTreatmentPlanGraph({
        Json: JSON.stringify({ TreatmentPlan: [{ ClientId: this.props.ClientId }] }),
      }),
    );
  }

  insertInCheckedInterventionsArray = (value, mergedTitle) => {
    if (this.state.checkedInterventionTitles.includes(mergedTitle)) {
      let tempArray = this.state.checkedInterventionTitles;
      if (tempArray.indexOf(mergedTitle) >= 0) {
        tempArray.splice(tempArray.indexOf(mergedTitle), 1);
      }
      this.setState({
        checkedInterventionTitles: tempArray,
      });
    } else {
      let existing = this.state.checkedInterventionTitles;
      this.setState({
        checkedInterventionTitles: [...existing, mergedTitle],
      });
    }

    if (this.state.checkedInterventions.includes(value)) {
      let tempArray = this.state.checkedInterventions;
      if (tempArray.indexOf(value) >= 0) {
        tempArray.splice(tempArray.indexOf(value), 1);
      }
      this.setState({
        checkedInterventions: tempArray,
      });
    } else {
      let existing = this.state.checkedInterventions;
      this.setState({
        checkedInterventions: [...existing, value],
      });
    }
  };
  showChildrenDrawer = () => {
    this.setState({
      childrenDrawer: true,
    });
  };

  onChildrenDrawerClose = () => {
    this.props.callAppDataAPI();
    this.setState({
      childrenDrawer: false,
    });
    this.props.sendSelectedInterventions(this.state.checkedInterventionTitles);
  };

  unsignFormFun = (FormType, FormId) => {
    let data = {
      Json: JSON.stringify({
        UnSign: [
          {
            FormType,
            FormId,
          },
        ],
      }),
    };

    this.props.dispatch(unsignForm(data));
  };

  render() {
    const {
      noteAddEditBox,
      AppointmentNote,
      AppointmentId,
      SignedDate,
      IsSign,
      Note,
      newAddendumBtn,
      editAddendumObj,
      AppointmentNoteAddendum,
      AddendumCount,
      resetnote,
      resetnewAddendumBtn,
      resetnoteAddEditBox,
      localNote,
      editAppointmentId,
      editAddendum,
      resetEditAddendumObj,
      handleSubmit,
      ServiceCode,
      SessionType,
      ProgramDescription,
      resetForm,
      reset,
      resetInitValues,
      AppointmentNoteId,
      durationInMin,
      DurationInMin,
      ClientId,
      SignedMessage,
      NoteSignHoursLeft,
      IsEligibleForUnSign,
      genratedAttendeeAppointmentNoteId,
      isDirty,
      submitSucceeded,
      GroupAppointmentId,
      FeatureFlags: { enableDictation },
      isAppointmentAddendumFetching,
      isAppointmentFetching,
    } = this.props;
    return (
      <>
        {/* {noteAddEditBox ? ( */}

        {true ? (
          <>
            <div className="individualNotesEdit">
              <div>
                <form
                  onSubmit={handleSubmit(values => {
                    let noteApiObj;
                    if (this.state.sign) {
                      noteApiObj = {
                        AppointmentNoteId: AppointmentNoteId,
                        AppointmentId: AppointmentId ? AppointmentId : null,
                        Note: values.note,
                        StartTime: mapTime(values.startTime),
                        EndTime: mapTime(values.endTime),
                        DurationInMin: this.state.durationValue,
                        IsSign: true,
                        ClientId: ClientId,
                        GroupAppointmentId: this.props.GroupAppointmentId,
                      };
                      this.props.getIsSignStatus(this.state.sign);
                      this.props.getFormTypeAndFormId('AppointmentNote', AppointmentNoteId);
                      this.setState({
                        // validPinModalVisible: true,
                        noteApiObj: noteApiObj,
                        validationFor: 'note',
                        formType: 'AppointmentNote',
                        formId: AppointmentNoteId,
                      });
                      this.props.dispatch(
                        upsertAppointmentNote({
                          Json: JSON.stringify({
                            AppointmentNote: [
                              {
                                ...noteApiObj,
                                IsSign: false,
                              },
                            ],
                          }),
                        }),
                      );
                    } else {
                      this.props.getIsSignStatus(this.state.sign);
                      this.props.dispatch(
                        upsertAppointmentNote({
                          Json: JSON.stringify({
                            AppointmentNote: [
                              {
                                AppointmentNoteId: AppointmentNoteId,
                                AppointmentId: AppointmentId ? AppointmentId : null,
                                Note: values.note,
                                StartTime: mapTime(values.startTime),
                                EndTime: mapTime(values.endTime),
                                DurationInMin: this.state.durationValue,
                                IsSign: false,
                                ClientId: ClientId,
                                GroupAppointmentId: this.props.GroupAppointmentId,
                              },
                            ],
                          }),
                        }),
                      );
                    }
                  })}
                  style={{ width: '100%' }}
                >
                  <div className="row mt-3 mb-3">
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            class="form-group mb-2 w-75"
                            // style={{ borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)' }}
                          >
                            <Field
                              name="startTime"
                              type="text"
                              component={ReduxTimePicker}
                              fieldName={'Start Time'}
                              validate={Validator.required}
                              label={'Start Time*'}
                              disabled={this.state.isDisable || this.props.IsSign}
                              // minuteStep={5}
                            />
                            {/* <label class="placeholder-label">Start Time</label> */}
                            {/* <i class="far fa-clock form-control-icon" /> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            class="form-group mb-2 w-75"
                            // style={{ borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)' }}
                          >
                            <Field
                              name="endTime"
                              type="text"
                              component={ReduxTimePicker}
                              fieldName={'End Time'}
                              validate={[Validator.required, Validator.endTime]}
                              label={'End Time*'}
                              disabled={this.state.isDisable || this.props.IsSign}
                              // minuteStep={5}
                            />
                            {/* <label class="placeholder-label">Start Time</label> */}
                            {/* <i class="far fa-clock form-control-icon" /> */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label
                            style={{ fontSize: '11px', top: ' -5px' }}
                            class="placeholder-label"
                          >
                            Duration
                          </label>
                          <div
                            class="mb-2"
                            style={{
                              fontWeight: 'normal',
                              fontSize: '14px',
                              background: '#E6E6E6',
                              borderRadius: '8px',
                              padding: '5px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                              width: '90px',
                            }}
                          >
                            <span>
                              <img src="images/timer.svg" />
                            </span>
                            {timeConvert(this.state.durationValue)}
                            {/* {DurationInMin
                          ? moment
                              .utc(moment.duration(DurationInMin * 60, 'seconds').asMilliseconds())
                              .format('HH:mm')
                          : moment
                              .utc(
                                moment
                                  .duration(this.props.durationInMin * 60, 'seconds')
                                  .asMilliseconds(),
                              )
                              .format('HH:mm')} */}
                            <span>
                              {/* {moment
                            .utc(
                              moment
                                .duration(this.state.durationValue * 60, 'seconds')
                                .asMilliseconds(),
                            )
                            .format('HH:mm')}{' '} */}
                            </span>
                            {/* <span>Hours</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <div className="row">
                        <div className="col-md-6">
                          <h4
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '12px',
                              lineHeight: '16px',
                            }}
                          >
                            SERVICE CODE
                          </h4>
                          <p>{ServiceCode}</p>
                        </div>
                        <div className="col-md-6">
                          <h4
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '12px',
                              lineHeight: '16px',
                            }}
                          >
                            SESSION TYPE
                          </h4>
                          <p>{SessionType}</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-7">
                      <h4
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '12px',
                          lineHeight: '16px',
                        }}
                      >
                        PROGRAM
                      </h4>
                      <p>{ProgramDescription}</p>
                    </div>
                  </div>

                  {IsSign ? (
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <h5
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '13px',
                              lineHeight: '16px',
                              color: '#b8b8b8',
                            }}
                          >
                            NOTE
                          </h5>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex justify-content-between align-items-center">
                            {SignedMessage && renderHTML(SignedMessage)}
                            {this.props.AppointmentNote &&
                              !this.props.AppointmentNote.IsOnlySupervisorSigned &&
                              IsSign && (
                                <Button
                                  type="disabled"
                                  shape="round"
                                  size="small"
                                  style={{
                                    background: '#BCBCBC',
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '87px',
                                    justifyContent: 'space-evenly',
                                    marginBottom: '14px',
                                    marginRight: '5px',
                                    marginLeft: '5px',
                                  }}
                                >
                                  <i class="fas fa-check"></i> Signed
                                </Button>
                              )}
                          </div>
                        </div>

                        <TextareaAutosize
                          className={`form-control not-empty mb-2`}
                          rows="1"
                          name="textarea_field"
                          key="gNoteBox"
                          id="NoteBox"
                          value={Note}
                          disabled
                        />

                        <div className="d-flex justify-content-between">
                          <div class="d-flex"></div>
                          {this.props.AppointmentNote &&
                            !this.props.AppointmentNote.IsSupervisorSignatureAvailable && (
                              <AntdModal
                                ModalButton={({ onClick }) => (
                                  <Tooltip placement="top" title="Un-sign">
                                    <span class="delete-course" onClick={onClick}>
                                      {IsEligibleForUnSign && (
                                        <span
                                          className="mt-2 mb-2 mr-2"
                                          style={{
                                            color: 'red',
                                            cursor: 'pointer',
                                            wordBreak: 'keep-all',
                                          }}
                                        >
                                          Un-sign
                                        </span>
                                      )}
                                    </span>
                                  </Tooltip>
                                )}
                                ModalContent={({ onOk, onCancel }) => (
                                  <div class="modal-content border-0">
                                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                      >
                                        &times;
                                      </button>
                                      <h4 class="modal-title col-12">
                                        Are you sure you want to Un-sign?
                                      </h4>
                                    </div>

                                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                      <button
                                        type="button"
                                        class="btn black-btn"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        class="btn "
                                        onClick={() => {
                                          onOk();
                                          this.unsignFormFun('AppointmentNote', AppointmentNoteId);
                                          // this.cancelGroupCall(Data.VMCallSessionId);
                                        }}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            )}
                        </div>

                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            {this.props.AppointmentNote &&
                              this.props.AppointmentNote.IsSupervisorSignatureRequired && (
                                <div className="col-md-12 supervisor-sign">
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      this.setState({ sign: true });
                                      // this.props.saveForNowClicked(true);
                                    }}
                                    type="submit"
                                    onClose={this.onCloseDrawer}

                                    // onClose={this.onCloseDrawer}
                                    // disabled={
                                    //   this.props.presentNoteValue && this.props.presentNoteValue.length > 0
                                    //     ? false
                                    //     : true
                                    // }
                                  >
                                    Supervisor Sign
                                  </button>
                                </div>
                              )}
                            {this.props.AppointmentNote &&
                              this.props.AppointmentNote.SupervisorSignedMessage &&
                              renderHTML(
                                this.props.AppointmentNote &&
                                  this.props.AppointmentNote.SupervisorSignedMessage,
                              )}
                            {this.props.AppointmentNote &&
                              this.props.AppointmentNote.IsSupervisorSignatureAvailable && (
                                <Button
                                  type="disabled"
                                  shape="round"
                                  size="small"
                                  style={{
                                    background: '#BCBCBC',
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '165px',
                                    justifyContent: 'space-evenly',
                                    marginBottom: '14px',
                                    marginRight: '5px',
                                    marginLeft: '5px',
                                  }}
                                >
                                  <i class="fas fa-check"></i> Supervisor Signed
                                </Button>
                              )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex"></div>
                          {this.props.AppointmentNote &&
                            this.props.AppointmentNote.IsSupervisorSignatureAvailable && (
                              <AntdModal
                                ModalButton={({ onClick }) => (
                                  <Tooltip placement="top" title="Un-sign">
                                    <span class="delete-course" onClick={onClick}>
                                      {this.props.AppointmentNote.IsSupervisorEligibleForUnSign && (
                                        <span
                                          className="mt-2 mb-2 mr-2"
                                          style={{ color: 'red', cursor: 'pointer' }}
                                        >
                                          Un-sign
                                        </span>
                                      )}
                                    </span>
                                  </Tooltip>
                                )}
                                ModalContent={({ onOk, onCancel }) => (
                                  <div class="modal-content border-0">
                                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                      >
                                        &times;
                                      </button>
                                      <h4 class="modal-title col-12">
                                        Are you sure you want to Un-sign?
                                      </h4>
                                    </div>

                                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                      <button
                                        type="button"
                                        class="btn black-btn"
                                        data-dismiss="modal"
                                        onClick={onCancel}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        class="btn "
                                        onClick={() => {
                                          onOk();
                                          this.unsignFormFun('AppointmentNote', AppointmentNoteId);
                                          // this.cancelGroupCall(Data.VMCallSessionId);
                                        }}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            )}{' '}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="row mt-3 mb-3">
                        <div className="col-md-6">
                          <h5
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              lineHeight: '19px',
                              color: 'black',
                            }}
                          >
                            {Note ? `EDIT` : `NEW`} NOTE
                          </h5>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div
                              className={
                                this.props.presentNoteValue &&
                                this.props.presentNoteValue.length > 0
                                  ? 'col-md-6 text-right'
                                  : 'col-md-12 text-right'
                              }
                            >
                              <button
                                type="button"
                                // onClick={() => {
                                //   resetnote('');

                                // }}
                                onClick={() => {
                                  this.props.reset();
                                  // this.props.resetInitValues();
                                  // this.props.resetManageAttendeeNote();
                                }}
                                style={{
                                  marginRight: 8,
                                  cursor: 'pointer',
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  background: 'transparent',
                                  border: 'none',
                                  color: '#bfbfbf',
                                }}
                              >
                                Cancel
                              </button>
                            </div>

                            {this.props.presentNoteValue && this.props.presentNoteValue.length && (
                              <div className="col-md-6 text-right">
                                <button
                                  className="h5btn"
                                  type="submit"
                                  style={{
                                    color: '#4AB0FE',
                                    cursor: 'pointer',
                                    background: 'transparent',
                                    border: 'none',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                  }}
                                  onClick={() => {
                                    this.setState({ sign: false });
                                  }}
                                  onClose={this.onCloseDrawer}
                                  disabled={isAppointmentFetching}
                                >
                                  Save for now
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12 enrollment-form">
                          <div class="form-group mb-2">
                            <Field
                              name="note"
                              type="textarea"
                              component={ReduxTextarea}
                              fieldName={'note'}
                              disabled={this.state.isDisable}
                              style={{
                                minHeight: '100px',
                                borderRadius: '12px',
                                border: 'none',
                                padding: '10px',
                              }}
                              placeholder="Write note here..."
                              showPlaceHolder={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        {' '}
                        {enableDictation && (
                          <>
                            <div
                              className="dictation-space"
                              style={{ display: 'inline-flex', paddingRight: '10px' }}
                            >
                              <DictationComponent handleDictate={this.handleDictate} />
                            </div>
                            <span style={{ color: 'rgb(232, 122, 122)', marginRight: '15px' }}>
                              {NoteSignHoursLeft && NoteSignHoursLeft}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mt-0 mb-5 text-right">
                        <Button
                          loading={this.state.sign && isAppointmentFetching}
                          className="btn save-button"
                          onClick={() => {
                            this.setState({ sign: true });
                          }}
                          htmlType="submit"
                          style={{ height: '42px', padding: '6px 20px' }}
                          type="primary"
                          onClose={this.onCloseDrawer}
                          // style={{ display: 'none' }}
                          disabled={
                            this.props.presentNoteValue && this.props.presentNoteValue.length > 0
                              ? false
                              : true
                          }
                        >
                          Sign and Submitt
                        </Button>
                      </div>
                    </div>
                  )}

                  <div className="row mt-3 mb-5">
                    {/* <Mood
                      formType="AppointmentNoteId"
                      formId={this.props.AppointmentNoteId}
                      PANASTag={
                        this.props.AppointmentNote &&
                        this.props.AppointmentNote.PANASTag &&
                        this.props.AppointmentNote.PANASTag[0]
                      }
                      ClientId={this.props.ClientId}
                    /> */}

                    {/* <div
                      className="col-md-6 text-left"
                      style={{ color: '#828282', fontSize: '12px' }}
                    >
                      INTERVENTION
                    </div>
                    <div
                      className="col-md-6 text-right"
                      style={{
                        color: '#4AB0FE',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                    >
                      Treatment Plan{' '}
                    </div> */}

                    <Drawer
                      className="custom-drawer"
                      title="PLEASE SELECT INTERVENTION(S)"
                      width={700}
                      closable={true}
                      onClose={this.onChildrenDrawerClose}
                      visible={this.state.childrenDrawer}
                    >
                      <div class="col-12">
                        <div
                          class="form-group"
                          style={{
                            flexFlow: 'wrap',
                            display: 'flex',
                          }}
                        >
                          {this.props.treeData && this.props.treeData.length > 0 ? (
                            <TreeDataComponent
                              treeData={this.props.treeData}
                              insertInCheckedInterventionsArray={
                                this.insertInCheckedInterventionsArray
                              }
                              AppointmentId={AppointmentId}
                              AppointmentNoteId={AppointmentNoteId}
                              dispatch={this.props.dispatch}
                              checkedInterventions={this.state.checkedInterventions}
                              IsSign={IsSign}
                              GroupAppointmentId={GroupAppointmentId}
                              ClientId={ClientId}
                            />
                          ) : (
                            <p style={{ color: 'rgb(0 0 0 / 25%)', fontSize: '18px' }}>
                              {' '}
                              <i class="fa fa-info-circle" aria-hidden="true"></i> No treatment plan
                              available.
                            </p>
                          )}

                          {/* <Field
                            name="comboTreatmentPlanIntervention"
                            component={CheckboxGroup}
                            col={24}
                            widthClassName={'w-75'}
                            // offset={1}
                            // justify={'start'}
                            options={this.props.comboTreatmentPlanIntervention}
                          /> */}
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </form>
              </div>

              {/* <div>
                <textarea
                  rows="5"
                  cols="85"
                  name="desc"
                  placeholder="
                  Write Note here ...."
                  style={{
                    width: '100%',
                    border: 'none',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    paddingLeft: '10px',
                  }}
                  onChange={e => {
                    resetnote(e.target.value);
                  }}
                >
                  {localNote}
                </textarea>
              </div> */}
            </div>
            <div className="mt-0 mb-5 text-right d-none">
              {/* <span style={{ color: 'red' }}>
                <i class="fas fa-history" />
                12 Hours Left
              </span>{' '} */}
              &nbsp; &nbsp;
              <button
                onClose={this.onCloseDrawer}
                type="primary"
                className="btn"
                onClick={() => {
                  this.props.dispatch(
                    upsertAppointmentNote({
                      Json: JSON.stringify({
                        AppointmentNote: [
                          {
                            AppointmentNoteId: AppointmentNoteId,
                            AppointmentId: AppointmentId ? AppointmentId : null,
                            Note: localNote,
                            IsSign: true,
                          },
                        ],
                      }),
                    }),
                  );

                  //   this.props.dispatch(
                  //     upsertAppointmentNote({
                  //       Json: JSON.stringify({
                  //         AppointmentNote: [
                  //           {
                  //             AppointmentId: AppointmentId,
                  //             Note: AppointmentNote ? AppointmentNote : this.state.note,
                  //             IsSign: true,
                  //           },
                  //         ],
                  //       }),
                  //     }),
                  //   );
                }}
              >
                Sign and Submittt
              </button>
            </div>
          </>
        ) : (
          <React.Fragment>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <h5
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '16px',
                      color: '#b8b8b8',
                    }}
                  >
                    NOTE
                  </h5>
                  {!IsSign && (
                    <div
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '19.1px',
                        color: '#FF7979',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        resetnote(Note);
                        resetnoteAddEditBox();
                        //   this.setState({
                        //     newAddendumBtn: true,
                        //     editAddendumObj: {
                        //       AppointmentNoteAddendumId,
                        //       AppointmentId,
                        //       Addendum: Addendum,
                        //       IsSign: IsSign,
                        //     },
                        //     Addendum: Addendum,
                        //   });
                      }}
                    >
                      <img src="images/pencil.svg" />
                    </div>
                  )}
                </div>
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {SignedMessage && renderHTML(SignedMessage)}
                  {IsSign && (
                    <Button
                      type="disabled"
                      shape="round"
                      size="small"
                      style={{
                        background: '#BCBCBC',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        width: '87px',
                        justifyContent: 'space-evenly',
                        marginBottom: '14px',
                        marginRight: '5px',
                      }}
                    >
                      <i class="fas fa-check"></i> Signed
                    </Button>
                  )}
                </p>
                <TextareaAutosize
                  className={`form-control not-empty mb-2`}
                  rows="1"
                  name="textarea_field"
                  key="gNoteBox"
                  id="NoteBox"
                  value={Note}
                  disabled
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {!newAddendumBtn && (
          <div className="row mt-3" style={{ background: '#F8F8F8' }}>
            <div className="col-md-12">
              <div className="individualNotesEdit">
                <div className="row mt-3 mb-3">
                  <div className="col-md-6">
                    <h5
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '19px',
                        color: 'black',
                      }}
                    >
                      {editAddendumObj.AppointmentNoteAddendumId ? `EDIT` : `NEW`} ADDENDUM
                    </h5>
                  </div>
                  <div className="col-md-6">
                    <div1 className="row">
                      <div className="col-md-6 text-right">
                        <h5
                          // onClose={this.onCloseDrawer}
                          onClick={() => {
                            let a =
                              this.props.editAddendumObj && this.props.editAddendumObj.Addendum;
                            this.setState({
                              Addendum: a ? a : '',
                            });
                            // this.props.reset();
                            // this.props.resetInitValues();
                            // this.props.onCloseDrawer();
                          }}
                          style={{
                            marginRight: 8,
                            cursor: 'pointer',
                          }}
                        >
                          Cancel
                        </h5>
                      </div>
                      <div className="col-md-6 text-right">
                        <h5
                          className="h5btn"
                          style={{
                            color: '#46b4fe',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (editAddendumObj && editAddendumObj.AppointmentNoteAddendumId) {
                              let addendumNoteApiObj = {
                                AppointmentNoteAddendum: [
                                  {
                                    AppointmentNoteAddendumId: editAddendumObj.AppointmentNoteAddendumId
                                      ? editAddendumObj.AppointmentNoteAddendumId
                                      : undefined,
                                    AppointmentId: editAddendumObj.AppointmentId,
                                    Addendum: this.state.Addendum,
                                    AppointmentNoteId: AppointmentNoteId,
                                    IsSign: false,
                                  },
                                ],
                              };

                              this.props.dispatch(
                                upsertAppointmentNoteAddendum({
                                  Json: JSON.stringify(addendumNoteApiObj),
                                }),
                              );

                              // this.props.dispatch(
                              //   upsertAppointmentNoteAddendum({
                              //     Json: JSON.stringify(),
                              //   }),
                              // );
                              // this.setState({
                              //   validPinModalVisible: true,
                              //   addendumNoteApiObj: addendumNoteApiObj,
                              //   validationFor: 'addendum',
                              // });
                            } else {
                              this.props.dispatch(
                                upsertAppointmentNoteAddendum({
                                  Json: JSON.stringify({
                                    AppointmentNoteAddendum: [
                                      {
                                        AppointmentId: AppointmentId,
                                        AppointmentNoteId: AppointmentNoteId,
                                        Addendum: this.state.Addendum,
                                        IsSign: false,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }
                          }}
                          disabled={isAppointmentAddendumFetching}
                        >
                          Save for now
                        </h5>
                      </div>
                    </div1>
                  </div>
                </div>
                <div>
                  <TextareaAutosize
                    name="desc"
                    placeholder="Write addendum here ...."
                    style={{
                      width: '100%',
                      border: 'none',
                      borderRadius: '12px',
                      overflow: 'hidden',
                      paddingLeft: '10px',
                    }}
                    onChange={e => {
                      this.setState({ Addendum: e.target.value });
                      if (e.target.value != this.props.editAddendumObj.Addendum) {
                        this.props.getDirtySubmitSucceededStatus(true);
                      }
                    }}
                    value={this.state.Addendum}
                  />
                </div>
              </div>
              <div className="mt-2 mb-5 text-right">
                {enableDictation && (
                  <div
                    className="dictation-space"
                    style={{ display: 'inline-flex', paddingRight: '10px' }}
                  >
                    <DictationComponent handleDictate={this.handleDictateForAddedum} />
                  </div>
                )}
                {/* <span style={{ color: 'red' }}>
                <i class="fas fa-history" />
                12 Hours Left
              </span>{' '} */}
                &nbsp; &nbsp;
                <Button
                  onClose={this.onCloseDrawer}
                  loading={this.state.appointmentNoteAddendumSign && isAppointmentAddendumFetching}
                  type="primary"
                  className="btn save-button"
                  style={{ heihjt: '42px', padding: '6px 20px' }}
                  onClick={() => {
                    let addendumNoteApiObj = {
                      AppointmentNoteAddendumId: editAddendumObj.AppointmentNoteAddendumId
                        ? editAddendumObj.AppointmentNoteAddendumId
                        : null,
                      AppointmentId: AppointmentId,
                      AppointmentNoteId: AppointmentNoteId,
                      Addendum: this.state.Addendum,
                      IsSign: false,
                    };

                    this.props.getFormTypeAndFormId(
                      'AppointmentNoteAddendum',
                      editAddendumObj.AppointmentNoteAddendumId
                        ? editAddendumObj.AppointmentNoteAddendumId
                        : null,
                    );

                    this.props.getAppointmentNoteAddendumSign(true);

                    this.props.dispatch(
                      upsertAppointmentNoteAddendum({
                        Json: JSON.stringify({
                          AppointmentNoteAddendum: [{ ...addendumNoteApiObj }],
                        }),
                      }),
                    );

                    this.setState({
                      // validPinModalVisible: true,
                      appointmentNoteAddendumSign: true,
                      addendumNoteApiObj: addendumNoteApiObj,
                      validationFor: 'addendum',
                      formType: 'AppointmentNoteAddendum',
                      formId: editAddendumObj.AppointmentNoteAddendumId
                        ? editAddendumObj.AppointmentNoteAddendumId
                        : null,
                    });
                  }}
                >
                  Sign and Submittttt
                </Button>
              </div>
            </div>
          </div>
        )}

        {IsSign && (
          <div className="row mt-3 mb-4">
            <div className="col-md-6">
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: '#828282',
                }}
              >
                ADDENDUMS ({AddendumCount})
              </div>
            </div>
            {AppointmentNoteAddendum &&
              AppointmentNoteAddendum[AppointmentNoteAddendum.length - 1].IsSign == true && (
                <div className="col-md-6 d-flex justify-content-end">
                  {newAddendumBtn && (
                    <div
                      style={{ display: 'flex', cursor: 'pointer' }}
                      onClick={() => {
                        resetnewAddendumBtn();
                      }}
                    >
                      <img src="images/addendum.svg" /> &nbsp;
                      <div style={{ cursor: 'pointer', zIndex: '99', pointerEvents: 'auto' }}>
                        Add New Addendum
                      </div>
                    </div>
                  )}
                </div>
              )}
            {AppointmentNoteAddendum == null && (
              <div className="col-md-6 d-flex justify-content-end">
                {newAddendumBtn && (
                  <div
                    style={{ display: 'flex', cursor: 'pointer' }}
                    onClick={() => {
                      resetnewAddendumBtn();
                    }}
                  >
                    <img src="images/addendum.svg" /> &nbsp;
                    <div style={{ cursor: 'pointer', zIndex: '99', pointerEvents: 'auto' }}>
                      Add New Addendum
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="row mt-3 page-break">
          {AppointmentNoteAddendum &&
            AppointmentNoteAddendum.map((item, index) => {
              const {
                AppointmentNoteAddendumId,
                Addendum,
                SignedDate,
                IsSign,
                SignedMessage,
                IsEligibleForUnSign,
              } = item;
              return (
                <>
                  <div className="col-md-12">
                    <div style={{ borderBottom: '1px solid #D8D8D8', padding: '10px 0px' }}>
                      <h5
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: '#000000',
                          display: 'inline-flex',
                        }}
                      >
                        Addendum {index + 1}
                      </h5>

                      <p
                        style={{
                          display: 'inline',
                          alignItems: 'center',
                          // justifyContent: 'space-between',
                        }}
                      >
                        {!SignedDate && (
                          <span
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: '16px',
                              lineHeight: '22px',
                              color: '#4AB0FE',
                              cursor: 'pointer',
                              display: 'none',
                            }}
                            onClick={() => {
                              let addendumNoteApiObj = {
                                AppointmentNoteAddendumId: AppointmentNoteAddendumId,
                                AppointmentId: AppointmentId,
                                AppointmentNoteId: AppointmentNoteId,
                                Addendum: Addendum,
                                IsSign: false,
                              };

                              // let addendumNoteApiObj = {
                              //   GroupAppointmentNoteAddendumId: editAddendumObj.GroupAppointmentNoteAddendumId
                              //     ? editAddendumObj.GroupAppointmentNoteAddendumId
                              //     : null,
                              //   GroupAppointmentId: GroupAppointmentId,
                              //   Addendum: this.state.Addendum,
                              //   IsSign: false,
                              // };

                              this.props.getFormTypeAndFormId(
                                'AppointmentNoteAddendum',
                                AppointmentNoteAddendumId,
                              );

                              // this.props.getFormTypeAndFormId('GroupAppointmentNoteAddendum', editAddendumObj.GroupAppointmentNoteAddendumId
                              // ? editAddendumObj.GroupAppointmentNoteAddendumId
                              //   : null);

                              this.props.getAppointmentNoteAddendumSign(true);
                              // this.props.getGroupAddendumSign(true);

                              this.props.dispatch(
                                upsertAppointmentNoteAddendum({
                                  Json: JSON.stringify({
                                    AppointmentNoteAddendum: [{ ...addendumNoteApiObj }],
                                  }),
                                }),
                              );

                              // this.props.dispatch(
                              //   upsertGroupAppointmentNoteAddendum({
                              //     Json: JSON.stringify({
                              //       GroupAppointmentNoteAddendum: [
                              //        { ...addendumNoteApiObj}
                              //       ],
                              //     }),
                              //   }),
                              // );

                              // let addendumNoteApiObj = {
                              //   GroupAppointmentNoteAddendum: [
                              //     {
                              //       GroupAppointmentNoteAddendumId: editAddendumObj.GroupAppointmentNoteAddendumId
                              //         ? editAddendumObj.GroupAppointmentNoteAddendumId
                              //         : null,
                              //       GroupAppointmentId: GroupAppointmentId,
                              //       Addendum: this.state.Addendum,
                              //       IsSign: true,
                              //     },
                              //   ],
                              // };

                              this.setState({
                                // validPinModalVisible: true,
                                appointmentNoteAddendumSign: true,
                                addendumNoteApiObj: addendumNoteApiObj,
                                validationFor: 'addendum',
                                formType: 'AppointmentNoteAddendum',
                                formId: AppointmentNoteAddendumId,
                              });

                              // this.setState({
                              // //  validPinModalVisible: true,
                              // groupAddendumSign: true,
                              //   addendumNoteApiObj: addendumNoteApiObj,
                              //   validationFor: 'addendum',
                              //   formType: 'GroupAppointmentNoteAddendum',
                              //   formId: editAddendumObj.GroupAppointmentNoteAddendumId
                              //     ? editAddendumObj.GroupAppointmentNoteAddendumId
                              //     : null,
                              // });
                            }}
                          >
                            Sign and Submitttttt
                          </span>
                        )}
                        {!SignedDate && (
                          <span
                            style={{
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '14px',
                              lineHeight: '19.1px',
                              color: '#FF7979',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              editAddendum();
                              resetEditAddendumObj({
                                AppointmentNoteAddendumId,
                                AppointmentId,
                                Addendum: Addendum,
                                IsSign: IsSign,
                              });
                            }}
                          >
                            &nbsp;&nbsp; <img src="images/pencil.svg" />
                          </span>
                        )}
                      </p>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between align-items-center">
                          {SignedMessage && renderHTML(SignedMessage)}
                          {IsSign && (
                            <Button
                              type="disabled"
                              shape="round"
                              size="small"
                              style={{
                                background: '#BCBCBC',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                width: '87px',
                                justifyContent: 'space-evenly',
                                marginBottom: '14px',
                                marginRight: '5px',
                                marginLeft: '5px',
                              }}
                            >
                              <i class="fas fa-check"></i> Signed
                            </Button>
                          )}
                        </div>
                      </div>

                      <TextareaAutosize
                        className={`form-control not-empty mb-2`}
                        rows="1"
                        name="textarea_field"
                        key="Addendum"
                        id="NoteBox"
                        value={Addendum}
                        disabled
                      />
                      <div className="d-flex justify-content-between">
                        <div class="d-flex"></div>
                        <AntdModal
                          ModalButton={({ onClick }) => (
                            <Tooltip placement="top" title="Un-sign">
                              <span class="delete-course" onClick={onClick}>
                                {IsEligibleForUnSign && (
                                  <span
                                    className="mt-2 mb-2 mr-2"
                                    style={{
                                      color: 'red',
                                      cursor: 'pointer',
                                      wordBreak: 'keep-all',
                                    }}
                                  >
                                    Un-sign
                                  </span>
                                )}
                              </span>
                            </Tooltip>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <h4 class="modal-title col-12">
                                  Are you sure you want to Un-sign?
                                </h4>
                              </div>

                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  class="btn black-btn"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  class="btn "
                                  onClick={() => {
                                    onOk();
                                    this.unsignFormFun(
                                      'AppointmentNoteAddendum',
                                      AppointmentNoteAddendumId,
                                    );
                                    // this.cancelGroupCall(Data.VMCallSessionId);
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        {
          <div
            className="col-md-12 text-Left mt-3 px-0 d-flex justify-content-between"
            style={{
              background: '#D1EBFF',
            }}
          >
            <div
              style={{
                fontStyle: 'normal',
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '5px 10px',
              }}
            >
              SELECTED INTERVENTION(S)
            </div>
            <div
              style={{
                color: '#4AB0FE',
                fontSize: '16px',
                fontWeight: 'bold',
                cursor: 'pointer',
                background: '#D1EBFF',
                padding: '5px 10px',
              }}
              onClick={this.showChildrenDrawer}
            >
              {' '}
              {IsSign ? (
                <span>VIEW </span>
              ) : (
                <button
                  type="submit"
                  onClick={() => {
                    this.setState({ sign: false });
                    this.showChildrenDrawer();
                  }}
                  style={{ background: 'none', border: '0px' }}
                >
                  MANAGE{' '}
                </button>
              )}
              <span style={{ color: 'grey', cursor: 'pointer' }}>
                <i class="fas fa-tasks"></i>
              </span>{' '}
              <br />
            </div>{' '}
          </div>
        }

        <br />

        {/* {
          <div
            className="col-md-12 text-Left mt-3 px-0 d-flex justify-content-between"
            style={{
              background: '#D1EBFF',
            }}
          >
            <div
              style={{
                fontStyle: 'normal',
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '5px 10px',
              }}
            >
              SELECTED INTERVENTION(S)
            </div>
            <div
              style={{
                color: '#4AB0FE',
                fontSize: '16px',
                fontWeight: 'bold',
                cursor: 'pointer',
                background: '#D1EBFF',
                padding: '5px 10px',
              }}
              onClick={this.showChildrenDrawer}
            >
              {' '}
              {IsSign ? <span>VIEW </span> : <span>MANAGE </span>}
              <span style={{ color: 'grey', cursor: 'pointer' }}>
                <i class="fas fa-tasks"></i>
              </span>{' '}
              <br />
            </div>{' '}
          </div>
        }

        <br /> */}
        {this.props.AppointmentNoteIntervention &&
          this.props.AppointmentNoteIntervention.map((item, index) => {
            return (
              <p
                style={{
                  background: 'white',
                  borderRadius: '11px',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)',
                  padding: '5px 10px',
                }}
              >
                {/* <span>&#183;</span> */}
                {index + 1}. &nbsp;
                {item.Description}
              </p>
            );
          })}
        {/* <TreeDataComponentView
          treeData={this.props.treeData}
          AppointmentNote={this.props.AppointmentNote}
          insertInCheckedInterventionsArray={this.insertInCheckedInterventionsArray}
        /> */}
        <ValidatePin
          validPinModalVisible={this.state.validPinModalVisible}
          resetValidPinModalVisible={this.resetValidPinModalVisible}
          offTheModal={this.props.offTheModal}
          FormType={this.state.formType}
          FormId={this.state.formId}
          IsRealSignature={this.props.IsRealSignature}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    combo_treatment_plan_intervention,
    get_appointment_note,
    get_treatment_plan_graph,
    validate_staff_pin,
    unsign_form,
    panas_tag_upsert,
    panas_tag_delete,
    user_feature_flag_get,
    upsert_appointment_note_addendum,
    upsert_appointment_note,
  } = state;
  var { data, isFetching, error } = combo_treatment_plan_intervention;
  var { data: validateDataPin } = validate_staff_pin;
  var { data: panasUpsert, isFetching, error } = panas_tag_upsert;
  var { data: panasDelete } = panas_tag_delete;
  const { data: unsignForm } = unsign_form;
  const { data: fFData } = user_feature_flag_get;
  const {
    data: getAppointmentNote,
    isFetching: isFetchingGetAppointmentNote,
  } = get_appointment_note;
  const { data: getTreatmentPlanGraph } = get_treatment_plan_graph;
  const { isFetching: isAppointmentFetching } = upsert_appointment_note;
  const { isFetching: isAppointmentAddendumFetching } = upsert_appointment_note_addendum;

  let treeData = [];
  // let startTime = formValueSelector('NoteAddEditBox')(state, 'startTime');
  // let endTime = formValueSelector('NoteAddEditBox')(state, 'endTime');
  let isPanasTagSubmit = false;
  let isPanasDelete = false;
  let startTime;
  let endTime;
  var unsignFormStatus = false;
  let FeatureFlags = {};
  if (unsignForm && unsignForm.success) {
    unsignFormStatus = unsignForm.success;
  }
  if (panasUpsert && panasUpsert.success) {
    isPanasTagSubmit = panasUpsert.success;
  }
  if (panasDelete && panasDelete.success) {
    isPanasDelete = panasDelete.success;
  }
  if (
    state &&
    state.form &&
    state.form.NoteAddEditBox &&
    state.form.NoteAddEditBox.values &&
    state.form.NoteAddEditBox.values.startTime &&
    state.form.NoteAddEditBox.values.startTime._isAMomentObject
  ) {
    startTime = moment(state.form.NoteAddEditBox.values.startTime._d).format('HH:mm:ss');
  } else {
    startTime = formValueSelector('NoteAddEditBox')(state, 'startTime');
  }
  if (
    state &&
    state.form &&
    state.form.NoteAddEditBox &&
    state.form.NoteAddEditBox.values &&
    state.form.NoteAddEditBox.values.endTime &&
    state.form.NoteAddEditBox.values.endTime._isAMomentObject
  ) {
    endTime = moment(state.form.NoteAddEditBox.values.endTime._d).format('HH:mm:ss');
  } else {
    endTime = formValueSelector('NoteAddEditBox')(state, 'endTime');
  }

  let comboTreatmentPlanIntervention;
  let durationInMin = 0;
  let durationInSec = 0;
  let AppointmentNote = [];
  let previousInterventionIds = '';
  let validateStaffPinData = {};
  if (data && data.TreatmentPlanIntervention) {
    comboTreatmentPlanIntervention = data.TreatmentPlanIntervention;
    comboTreatmentPlanIntervention = comboTreatmentPlanIntervention.map(item => {
      return { label: item.Text, value: item.Value };
    });
  }
  if (startTime && endTime) {
    durationInMin = DurationInMinCalc(startTime, endTime);

    // durationInMin = moment
    //   .duration(moment(endTime, 'YYYY/MM/DD HH:mm').diff(moment(startTime, 'YYYY/MM/DD HH:mm')))
    //   .asMinutes();

    // .asHours();

    // durationInSec = durationInMin * 60;
    // let s = moment(startTime).format('hh:mm:ss a');
    // let e = moment(endTime).format('hh:mm:ss a');
    // let duration = moment.duration(e.diff(s));
    // var hours = duration.asHours();
  }
  if (getAppointmentNote) {
    AppointmentNote = getAppointmentNote[0].AppointmentNote[0] || [];
    previousInterventionIds = getAppointmentNote[0].AppointmentNote[0].TreatmentPlanInterventionId;
  }
  if (getTreatmentPlanGraph) {
    treeData = getTreatmentPlanGraph.TreatmentPlanDimension;
  }
  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
  }

  return {
    durationInMin,
    comboTreatmentPlanIntervention,
    AppointmentNote,
    treeData,
    validateStaffPinData,
    unsignFormStatus,
    presentNoteValue: formValueSelector('NoteAddEditBox')(state, 'note'),
    isDirty: isDirty('NoteAddEditBox')(state),
    submitSucceeded: get(state, 'form.NoteAddEditBox.submitSucceeded', false),
    isPanasTagSubmit,
    isPanasDelete,
    FeatureFlags,
    isAppointmentAddendumFetching,
    isAppointmentFetching,
  };
};

const ReduxNoteAddEditBox = reduxForm({
  form: 'NoteAddEditBox',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(NoteAddEditBox)));

export { ReduxNoteAddEditBox };
