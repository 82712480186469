import React, { Component } from 'react';
import { Bar, HorizontalBar, Line, Doughnut, Pie } from 'react-chartjs-2';
import {
  // barData,
  barOptions,
  // horizontalBarData,
  horizontalBarOptions,
  // lineData,
  // lineData2,
  lineOptions,
  // pieData,
  pieOptions,
  // doughnutData,
  doughnutOptions,
} from './chartData';
import { loadSmallGroupDashboard } from '../store/actions';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader';
import { Table, Typography, Tooltip, Progress, Slider } from 'antd';
import ReactApexChart from 'react-apexcharts';
import SmallGroup from './smallGroupList';
import { currencyFormatter, numberFormat } from '../util/formatter';
import { CommonLicenceScreens } from './licenceScreens';
import ComingSoon from '../component/comingSoonPage';

class SmallGroupDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barSeries: [
        {
          name: 'Total Revenue',
          type: 'column',
          data: [],
        },
        {
          name: 'Revenue Per Client',
          type: 'line',
          data: [],
        },
      ],
      barOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
            columnWidth: '25%',
            // distributed: true
          },
        },
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 4],
        },
        // title: {
        //   text: 'Legacy . New',
        // },
        dataLabels: {
          enabled: true,
          // enabledOnSeries: [1]
          formatter: function(val, opt) {
            return currencyFormatter.format(val);
          },
        },
        labels: ['Published', 'Private'],
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            title: {
              text: 'Total',
              style: {
                fontSize: '13px',
              },
            },
            labels: {
              formatter: function(value) {
                return currencyFormatter.format(value);
              },
            },
          },
          {
            opposite: true,
            title: {
              text: 'Average Revenue Per Client',
              style: {
                fontSize: '13px',
              },
            },
            labels: {
              formatter: function(value) {
                return currencyFormatter.format(value);
              },
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(loadSmallGroupDashboard());
  }
  componentWillReceiveProps({ smallGroupInfo, smallGroupStatusData }) {
    if (
      smallGroupInfo &&
      JSON.stringify(smallGroupInfo) != JSON.stringify(this.props.smallGroupInfo) &&
      smallGroupInfo.ClientRevenueMap
    ) {
      let labelText = [];
      let SeriesFirst = [];
      let SeriesSecond = [];
      smallGroupInfo.ClientRevenueMap.map(item => {
        SeriesFirst.push(item.PublishedCost, item.PrivateCost);
        SeriesSecond.push(item.AvgPublishedRevenuewPerClient, item.AvgPrivateRevenuewPerClient);
      });
      let barSeriesFirst = { ...this.state.barSeries[0], data: SeriesFirst };
      let barSeriesSecond = { ...this.state.barSeries[1], data: SeriesSecond };
      let newBarSeries = [barSeriesFirst, barSeriesSecond];

      this.setState({
        // barOptions: { ...this.state.barOptions, labels: labelText },
        barSeries: newBarSeries,
      });
    }

      if (
        smallGroupStatusData &&
        JSON.stringify(smallGroupStatusData) != JSON.stringify(this.props.smallGroupStatusData)
      ) {
        this.setState({ smallGroupStatusData });
      }
  }
  render() {
    const { smallGroupInfo, isFetching, DashboardMenu } = this.props;
    const {
      ClientEngagement,
      ClientEngagementCount,
      GroupType,
      GroupTypeCount,
      ClientAvgScore,
      ClientRevenue,
      ClientRevenueMap,
      ClientMisc,
    } = smallGroupInfo;
    const { IsSmallGroupRevenueCard, IsSmallGroupListCard } = DashboardMenu || {};

    var totalConnections = 0;
    var totalConnectionsChart = [10, 15, 10, 15, 20, 10, 15];
    var newStudentEnroll = 0;
    var newStudentEnrollChart = [5, 10, 5, 10, 5, 10, 15];
    var doughnutData = {
      labels: [],
      datasets: [
        {
          data: [0, 0],
          backgroundColor: ['#36A2EB', '#FF6384'],
          hoverBackgroundColor: ['#36A2EB', '#FF6384'],
          borderWidth: 0,
        },
      ],
    };
    var pieData = {
      labels: [],
      datasets: [
        {
          data: [0, 0],
          backgroundColor: ['#FF6384', '#36A2EB'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB'],
          borderWidth: 0,
        },
      ],
    };
    var barData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd'],
        },
      ],
    };
    var horizontalBarData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0, 0],
          backgroundColor: ['#ff5d5e', '#ff5d5e', '#30e191', '#48b4ff', '#48b4ff', '#30e191'],
        },
      ],
    };
    var lineData = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['gradientFill'],
        },
      ],
    };

    var lineData2 = {
      labels: [],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['gradientFill'],
        },
      ],
    };

    const GroupTypeColumns = [
      {
        title: '',
        dataIndex: 'Header',
        key: 'Header',
        align: 'right',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && text}</span>
            </div>
          );
        },
      },
      {
        title: 'Private',
        dataIndex: 'PrivateCount',
        key: 'PrivateCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Published',
        dataIndex: 'PublishedCount',
        key: 'PublishedCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
    ];
    const ClientEngagedColumns = [
      {
        title: '',
        dataIndex: 'Header',
        key: 'Header',
        align: 'right',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && text}</span>
            </div>
          );
        },
      },
      {
        title: 'Private',
        dataIndex: 'PrivateCount',
        key: 'PrivateCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Published',
        dataIndex: 'PublishedCount',
        key: 'PublishedCount',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && numberFormat.format(text)}
              </span>
            </div>
          );
        },
      },
    ];

    const { SPBehavioral, Faith, Addiction } =
      (ClientAvgScore && ClientAvgScore[0] && ClientAvgScore[0]) || {};
    const ClientRevenueColumns = [
      {
        title: 'Group Type',
        dataIndex: 'Header',
        key: 'Header',
        align: 'right',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && text}</span>
            </div>
          );
        },
      },
      {
        title: 'Average Revenue Per Client',
        dataIndex: 'AvgRevenuewPerClient',
        key: 'AvgRevenuewPerClient',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && currencyFormatter.format(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Average Revenue Per Group',
        dataIndex: 'AvgRevenuewPerGroup',
        key: 'AvgRevenuewPerGroup',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && currencyFormatter.format(text)}
              </span>
            </div>
          );
        },
      },
    ];
    return (
      <>
        <Loader loading={isFetching} />
        <CommonLicenceScreens
          dispatch={this.props.dispatch}
          StatusData={this.state.smallGroupStatusData}
          moduleName={'SmallGroup'}
        />
        {this.state.smallGroupStatusData && this.state.smallGroupStatusData.LicenseStatusId == 6 ? (
          <div>
            <ComingSoon />
            <div className="pb-5"></div>
          </div>
        ) : (
          <>
            {this.state.smallGroupStatusData &&
              this.state.smallGroupStatusData.LicenseStatusId != 1 &&
              this.state.smallGroupStatusData.LicenseStatusId != 3 &&
              this.state.smallGroupStatusData.LicenseStatusId != 5 && (
                <>
                  <div>
                    <div class="row dashboard-analytics">
                      <div class="col-lg-6  mb-4">
                        <div class="card border-0 h-100 pt-3 pb-3 ">
                          <div class="row align-items-center card-body px-3">
                            <div class="col-6 py-2">
                              <h5 class="text-uppercase font-weight-bold mb-0">{'Group Type'}</h5>
                            </div>
                            <div class="col-6 py-3">
                              {/* <input
                    type="button"
                    class="btn btn-outline-primary mr-2"
                    value="Purchase Analytics Package"
                  /> */}
                            </div>
                            <div class="col-12">
                              <div className="custom-header">
                                <Table
                                  dataSource={GroupType}
                                  columns={GroupTypeColumns}
                                  bordered
                                  pagination={false}
                                />
                              </div>
                              <div className="client-type-count mt-2">
                                <div className="mr-2">
                                  <span>Total Small Groups </span>
                                  <span className="mr-1 bold" style={{ color: '#4AB0FE' }}>
                                    {GroupTypeCount &&
                                      numberFormat.format(GroupTypeCount[0].TotalCount || 0)}
                                    &nbsp;
                                  </span>
                                  {' | '}
                                </div>
                                <div className="mr-2">
                                  <span>Active </span>
                                  <span className="mr-1 bold" style={{ color: '#2FE192' }}>
                                    {GroupTypeCount &&
                                      numberFormat.format(GroupTypeCount[0].ActiveCount || 0)}
                                    &nbsp;
                                  </span>
                                  {' | '}
                                </div>
                                <div className="mr-2">
                                  <span>Inactive </span>
                                  <span className="mr-1 bold" style={{ color: '#E87A7A' }}>
                                    {GroupTypeCount &&
                                      numberFormat.format(GroupTypeCount[0].InActiveCount || 0)}
                                  </span>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6  mb-4">
                        <div class="card border-0 h-100 pt-3 pb-3 ">
                          <div class="row align-items-center card-body px-3">
                            <div class="col-12 py-2">
                              <h5 class="text-uppercase font-weight-bold mb-0">
                                {'Client Engagement'}
                              </h5>
                            </div>
                            <div class="col-6 py-3">
                              {/* <input
                    type="button"
                    class="btn btn-outline-primary mr-2"
                    value="Purchase Analytics Package"
                  /> */}
                            </div>
                            <div class="col-12">
                              <div className="custom-header">
                                <Table
                                  dataSource={ClientEngagement || []}
                                  columns={ClientEngagedColumns}
                                  bordered
                                  pagination={false}
                                />
                              </div>
                              <div className="client-type-count mt-2">
                                <div className="mr-2">
                                  <span>Total Clients </span>
                                  <span className="mr-1 bold" style={{ color: '#4AB0FE' }}>
                                    {ClientEngagementCount &&
                                      numberFormat.format(
                                        ClientEngagementCount[0].TotalClient || 0,
                                      )}
                                  </span>
                                  {' | '}
                                </div>
                                <div className="mr-2">
                                  <span>Active </span>
                                  <span className="mr-1 bold" style={{ color: '#2FE192' }}>
                                    {ClientEngagementCount &&
                                      numberFormat.format(
                                        ClientEngagementCount[0].ActiveCount || 0,
                                      )}
                                  </span>
                                  {' | '}
                                </div>
                                <div className="mr-2">
                                  <span>Average</span>{' '}
                                  <span className="mr-1 bold" style={{ color: '#F2C724' }}>
                                    {ClientEngagementCount &&
                                      numberFormat.format(
                                        ClientEngagementCount[0].AverageCount || 0,
                                      )}
                                  </span>
                                  {' | '}
                                </div>
                                <div className="mr-2">
                                  <span>Guest Passes</span>{' '}
                                  <span className="mr-1 bold" style={{ color: '#FE73B1' }}>
                                    {ClientEngagementCount &&
                                      numberFormat.format(
                                        ClientEngagementCount[0].GuestPassesCount || 0,
                                      )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4">
                        <div class="card border-0 h-100 inner-content">
                          <div class="row card-body align-items-center">
                            <div class="col-12 pt-3">
                              <h5 class="text-uppercase font-weight-bold mb-3">
                                {'Average Scores'}
                              </h5>
                            </div>
                            <div class="col-sm-4 text-center">
                              <div class="mb-2">
                                <Progress
                                  type="circle"
                                  strokeWidth={8}
                                  strokeColor="#2fe192"
                                  width={80}
                                  percent={SPBehavioral}
                                  format={percent => (
                                    <div class="progress-textbox" style={{ fontSize: '13px' }}>
                                      <strong style={{ fontSize: '16px' }}>{percent}</strong>
                                      score
                                    </div>
                                  )}
                                  trailColor={'#eff7fe'}
                                />
                              </div>
                              <h6 class="font-weight-semibold">SP Rating</h6>
                            </div>
                            <div class="col-sm-4 text-center">
                              <div class="mb-2">
                                <Progress
                                  type="circle"
                                  strokeWidth={8}
                                  strokeColor="#46b4fe"
                                  width={80}
                                  percent={Faith}
                                  format={percent => (
                                    <div class="progress-textbox" style={{ fontSize: '13px' }}>
                                      <strong style={{ fontSize: '16px' }}>{percent}</strong>
                                      score
                                    </div>
                                  )}
                                  trailColor={'#eefdf8'}
                                />
                              </div>
                              <h6 class="font-weight-semibold">Faith</h6>
                            </div>
                            <div class="col-sm-4 text-center">
                              <div class="mb-2">
                                <Progress
                                  type="circle"
                                  strokeWidth={8}
                                  width={80}
                                  strokeColor="#ff5d5d"
                                  percent={Addiction}
                                  format={percent => (
                                    <div class="progress-textbox" style={{ fontSize: '13px' }}>
                                      <strong style={{ fontSize: '16px' }}>{percent}</strong>
                                      score
                                    </div>
                                  )}
                                  trailColor={'#fef2f2'}
                                />
                              </div>
                              <h6 class="font-weight-semibold">Addiction</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <div class="card border-0 h-100 pt-3 pb-3 ">
                          <div class="row align-items-center card-body">
                            <div class="col-lg-8 col-sm-8">
                              <div class="student-info">
                                <h2>
                                  {ClientMisc &&
                                    numberFormat.format(
                                      ClientMisc[0].TotalConnectedPopulation || 0,
                                    )}
                                </h2>
                                <span class="text-uppercase h6">Total Connected Population</span>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 text-md-right">
                              {/* <Sparklines data={totalConnectionsChart}>
                    <SparklinesLine color="#179bef" />
                  </Sparklines> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                        <div class="card border-0 h-100 pt-3 pb-3 ">
                          <div class="row align-items-center card-body">
                            <div class="col-lg-8  col-sm-6">
                              <div class="student-info">
                                <h2>
                                  {ClientMisc &&
                                    numberFormat.format(ClientMisc[0].TotalInteractions || 0)}
                                </h2>
                                <span class="text-uppercase h6">Total Interactions</span>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 text-md-right">
                              {/* <Sparklines data={newStudentEnrollChart}>
                    <SparklinesLine color="#179bef" />
                  </Sparklines> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row dashboard-analytics">
                      {IsSmallGroupRevenueCard && (
                        <div class="col-12 col-xl-6 mb-4">
                          <div class="card border-0">
                            <div class="row  card-body px-3">
                              <div class="col-12">
                                <h3>
                                  $
                                  {ClientRevenueMap &&
                                    new Intl.NumberFormat('en-US', {
                                      maximumSignificantDigits: 7,
                                    }).format(ClientRevenueMap[0].TotalNetMonthlyRevenue || 0)}
                                </h3>
                                {/* <h3>${(ClientRevenueMap &&  new Intl.NumberFormat('en-US', { maximumSignificantDigits: 7 }).format(
                            ClientRevenueMap[0].TotalNetMonthlyRevenue) || 0}</h3> */}
                                <h5>Total Net Monthly Revenue</h5>
                                <div>
                                  <ReactApexChart
                                    options={this.state.barOptions}
                                    series={this.state.barSeries}
                                    type="line"
                                    height={350}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="custom-header">
                                  <Table
                                    dataSource={ClientRevenue || []}
                                    columns={ClientRevenueColumns}
                                    bordered
                                    pagination={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div class="col-lg-6 col-xl-6 mb-4">
                        <div class="card border-0 ">
                          <div class="row card-body d-none">
                            <div class="col-12 mb-lg-3">
                              <h5 class="text-uppercase pt-4">Map</h5>
                              <div>
                                <span>Coming Soon</span>
                              </div>
                            </div>
                            <div class="col-12"></div>
                          </div>
                        </div>
                      </div>
                      {IsSmallGroupListCard && (
                        <div className="col-lg-12">
                          <div class="row mt-5" style={{ backgroundColor: 'white' }}>
                            <div class="col-md-12">
                              {<SmallGroup {...this.props} isDashboard={true} />}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}{' '}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ load_smallGroup_dashboard, user_feature_flag_get }) => {
  const { data, isFetching } = load_smallGroup_dashboard;
    const { data: smallGroupStatus } = user_feature_flag_get;
  let smallGroupInfo = {};
    let smallGroupStatusData = null;
  if (data && data.DashboardSmallGroupMetrics) {
    smallGroupInfo = data.DashboardSmallGroupMetrics[0];
  }
    if (
      smallGroupStatus &&
      smallGroupStatus.FeatureFlag &&
      smallGroupStatus.FeatureFlag.length > 0 &&
      smallGroupStatus.FeatureFlag[0]
    ) {
      smallGroupStatusData =
        smallGroupStatus.FeatureFlag &&
        smallGroupStatus.FeatureFlag[0] &&
        smallGroupStatus.FeatureFlag[0].License &&
        smallGroupStatus.FeatureFlag[0].License.length > 0 &&
        smallGroupStatus.FeatureFlag[0].License.filter(item => item.ModuleName == 'SmallGroup')[0];
    }

  return {
    smallGroupInfo,
    isFetching,
    smallGroupStatusData,
  };
};

export default connect(mapStateToProps)(withRouter(SmallGroupDashboard));
