import React from 'react';

const AssignedProgram = ({ AssignedProgram }) => {
  const { ProgramType, StateOrProvince, City, CenterName, PriorityName } = AssignedProgram || {};

  return (
    <div class="assigned-program">
      <div className="program">
        <div>
          <span className="text">Program Recommended: </span>
          <span className="value-1">{ProgramType}</span>
        </div>
        <div className="program-detail">
          <div className="items">
            <span className="text">Priority</span>
            <span className="value">
              {PriorityName ? <img src={`images/icons/${PriorityName}-risk-program.svg`} /> : null}
            </span>
          </div>
          <div className="items">
            <span className="text">Center Recommended</span>
            <span className="value">{CenterName}</span>
          </div>
          <div className="items">
            <span className="text">State</span>
            <span className="value">{StateOrProvince}</span>
          </div>
          <div className="items">
            <span className="text">City</span>
            <span className="value">{City}</span>
          </div>
        </div>
      </div>
      <div className="assigned-to-queue">
        <span className="assigned-lable">
          <img src="images/icons/tick-blue.svg" /> Assigned to Queue
        </span>
      </div>
    </div>
  );
};
export default AssignedProgram;
