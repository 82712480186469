import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  upsertClientPayment,
  resetUpsertClientPayment,
  getPatientDetail,
} from '../../../../store/actions';
import CustomModal from '../../../../core/modal';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import { stringDate } from '../../../../util/date';

class PaymentStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientPaymentData: {},
    };
  }
  // need to work on it always return zero for me
  getClientId() {
    // var param = new URLSearchParams(this.props.history.location.search);
    // var courseId = param.get('courseId') || param.get('courseid');
    // if (courseId) {
    //   courseId = parseInt(courseId);
    // } else {
    //   courseId = -1;
    // }
    return 20000019;
  }

  componentDidMount() {
    if (this.getClientId() > 0) {
      // this.props.dispatch(adminstrationGetCourse({ CourseId: this.getClientId() }));
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({ isInserted, isRemoved }) {
    if (isInserted) {
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(
        getPatientDetail({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(resetUpsertClientPayment());
      //this.props.history.push('/manage/courses');
    }
    if (isRemoved) {
      this.toggle(Delete_Sucess_Msg);
      this.props.dispatch(resetUpsertClientPayment());
    }
  }
  mapClientPayment = () => {
    let { clientInfo } = this.props;
    let clientData = clientInfo && clientInfo.ClientPayment && clientInfo.ClientPayment[0];
    if (clientData && clientInfo.ClientId > 0) {
      return {
        clientPaymentId: clientData.ClientPaymentId,
        paymentId: clientData.PaymentId,
        patientName: clientData.PatientName,
        paymentDate: clientData.PaymentDate,
        payerType: clientData.PayerType,
        payerName: clientData.PayerName,
        paymentMethod: clientData.PaymentMethod,
        checkNumber: clientData.CheckNumber,
        paymentAmount: clientData.PaymentAmount,
        description: clientData.Description,
        applyToPatientId: clientData.ApplyToPatientId,
      };
    } else {
      return {};
    }
  };
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      paymentId,
      patientName,
      paymentDate,
      payerType,
      payerName,
      paymentMethod,
      checkNumber,
      paymentAmount,
      description,
      applyToPatientId,
      nameonCard,
      Number,
      cvv,
      expirationDate,
    } = values;
    let patientId = null;
    let paramClientId = this.props.clientId;
    if (paramClientId && paramClientId > 0) {
      patientId = paramClientId;
    }
    const data = {
      Client: [
        {
          ClientPayment: [
            {
              ClientId: patientId,
              PaymentId: paymentId,
              PatientName: patientName,
              PaymentDate: stringDate(paymentDate),
              PayerType: payerType,
              PayerName: payerName,
              PaymentMethod: paymentMethod,
              CheckNumber: checkNumber,
              PaymentAmount: paymentAmount,
              Description: description,
              ApplyToPatientId: applyToPatientId,
            },
          ],
        },
      ],
    };
    let PaymentData = {
      // courseId: courseId,
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertClientPayment(PaymentData));
  }

  render() {
    return (
      <React.Fragment className="rcm-tab">
        <ReduxPaymentForm
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.mapClientPayment()}
          onCancel={this.props.onCancel}
          getClientId={this.getClientId()}
          clientData={this.props.clientData}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/courses')
          }}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // this.props.history.push('/manage/courses');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(courseDelete({ CourseId: this.getClientId() }));
            // this.props.history.push('/manage/courses');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            // this.props.history.push('/manage/courses');
          }}
        />
      </React.Fragment>
    );
  }
}

const PaymentForm = props => {
  const {
    handleSubmit,
    onLastSubmit,
    onSaveClick,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    isEmployedValue,
    style,
    goToPreviuos,
    disable,
    clientData,
    reset,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSaveClick)} style={{ padding: '36px 20px 200px 20px' }}>
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`paymentId`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Payment ID</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`patientName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Patient Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              component={ReduxDateInput}
              name="paymentDate"
              label="Payment Date*"
              validate={validate.required}
              fieldName={'Payment Date'}
              disabled={disable}
            />
            <i class="far fa-calendar-alt form-control-icon" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`payerType`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Payer Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Payer Type</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`payerName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Payer Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Payer Name</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="paymentMethod"
              type="text"
              placeholder="Payment Method"
              component={ReduxSelect}
              options={[
                {
                  Text: 'Payment Method_one',
                  Value: 1,
                },
                {
                  Text: 'Payment Method_two',
                  Value: 2,
                },
                {
                  Text: 'Payment Method_three',
                  Value: 3,
                },
              ]}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`checkNumber`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Check Number</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`paymentAmount`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Payment Amount</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`description`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Description</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`applyToPatientId`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Apply to Patient ID</label>
          </div>
        </div>
        <div class="col-12">
          <h6>Credit Card Information</h6>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`nameonCard`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'First Name'}
              disabled={disable}
            />
            <label class="placeholder-label">Name on Card</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`number`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Number'}
              disabled={disable}
            />
            <label class="placeholder-label">Number</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`cvv`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'CVV'}
              disabled={disable}
            />
            <label class="placeholder-label">CVV</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`expirationDate`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Expiration Date'}
              disabled={disable}
            />
            <label class="placeholder-label">Expiration Date</label>
          </div>
        </div>

        <div class="row pagination-button">
          <div class="col-12 text-right">
            <input
              type="button"
              class="btn btn-outline-primary mr-2"
              value="Cancel"
              onClick={reset}
            />
            <input type="submit" class="btn" value="Save" />
          </div>
        </div>
      </div>
    </form>
  );
};
const mapStateToProps = state => {
  const { combos, medicate_combos } = state;
  const { data } = medicate_combos;
  let medicateCombosObj = {
    Allergies: {},
    DiseaseConditions: {},
    Medication: {},
    Surgeries: {},
    Vaccination: {},
    Psychiatric: {},
  };
  if (data) {
    medicateCombosObj = {
      Allergies: data.Allergies,
      DiseaseConditions: data.DiseaseConditions,
      Medication: data.Medication,
      Surgeries: data.Surgeries,
      Vaccination: data.Vaccination,
      Psychiatric: data.Psychiatric,
    };
  }
  return {
    MaritalStatus: combos.data.MaritalStatus,
    EthnicRace: combos.data.EthnicRace,
    Gender: combos.data.Gender,
    HousingType: combos.data.HousingType,
    EyeColor: combos.data.EyeColor,
    Language: combos.data.Language,
    EducationType: combos.data.EducationType,
    WorkSkill: combos.data.WorkSkill,
    isEmployedValue: selector(state, 'isEmployed'),
    medicateCombosObj: medicateCombosObj,
  };
};
const ReduxPaymentForm = reduxForm({
  form: 'patientPaymentForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(PaymentForm)));

const mapStateToPropsParent = ({ upsert_client_payment, get_patient_detail }) => {
  const { data: clientInfo } = get_patient_detail;
  let isInserted = false;
  let clientData = {};

  if (upsert_client_payment && upsert_client_payment.data && upsert_client_payment.data.success) {
    isInserted = true;
  }
  if (clientInfo && clientInfo.ClientPayment) {
    clientData = clientInfo.ClientPayment[0];
    clientData.ClientId = clientInfo.ClientId;
  }

  return {
    isInserted,
    clientData: clientData,
  };
};
export default connect(mapStateToPropsParent)(PaymentStep);
const selector = formValueSelector('patientPaymentForm');
