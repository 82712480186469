import React, { Component } from 'react';
import { Tabs } from 'antd';
import { Collapse, Checkbox, Row, Col } from 'antd';
import { Modal } from 'antd';
import renderHTML from 'react-render-html';
import CustomModal from '../../../Components/CustomModal';
import ChatBot from '../../../Components/ChatBot';
import {
  loadUserEngagement,
  loadSupportGroup,
  getAppSubscribersChat,
  loadPeer,
} from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';
import SupportGroup from './supportGroupTab';
import Engagement from './EngagementTab';
import Album from './Album';
import Posts from './Posts';
import Friends from './Friends';
import Chat from './Chat';
import PeersConnected from './Peer';


const tabName = [
  { tabLabel: 'Engagement', keyIndex: 1, Content: 'Engagement' },
  { tabLabel: 'Support Group', keyIndex: 2, Content: 'SupportGroup' },
  { tabLabel: 'Album', keyIndex: 3, Content: 'Album' },
  { tabLabel: 'Posts', keyIndex: 4, Content: 'Posts' },
  { tabLabel: 'Friends', keyIndex: 5, Content: 'Friends' },
  { tabLabel: 'Chat', keyIndex: 6, Content: 'Chat' },
];

const tabName2 = [
  { tabLabel: 'Engagement', keyIndex: 1, Content: 'Engagement' },
  { tabLabel: 'Peers Connected', keyIndex: 2, Content: 'PeersConnected' },
  { tabLabel: 'Album', keyIndex: 4, Content: 'Album' },
  { tabLabel: 'Posts', keyIndex: 5, Content: 'Posts' },
  { tabLabel: 'Friends', keyIndex: 6, Content: 'Friends' },
  { tabLabel: 'Chat', keyIndex: 7, Content: 'Chat' },
];

const components = {
  Engagement,
  SupportGroup,
  Album,
  Posts,
  Friends,
  Chat,
  PeersConnected,
};

const App = props => (
  <Tabs defaultActiveKey="1" centered>
    {!props.isConcernedPerson &&
      tabName &&
      tabName.map((item, index) => {
        const { tabLabel, keyIndex, Content } = item;
        const DynamicComponentConversion = components[Content];
        return (
          <Tabs.TabPane tab={tabLabel} key={keyIndex}>
            {
              <DynamicComponentConversion
                {...props}
                getSubscriberUserGUID={props.getSubscriberUserGUID}
              />
            }
          </Tabs.TabPane>
        );
      })}
    {props.isConcernedPerson &&
      tabName2 &&
      tabName2.map((item, index) => {
        const { tabLabel, keyIndex, Content } = item;
        const DynamicComponentConversion = components[Content];
        return (
          <Tabs.TabPane tab={tabLabel} key={keyIndex}>
            {
              <DynamicComponentConversion
                {...props}
                getSubscriberUserGUID={props.getSubscriberUserGUID}
                isConcernedPerson={props.isConcernedPerson}
              />
            }
          </Tabs.TabPane>
        );
      })}
  </Tabs>
);

export class SubscriberTabs extends Component {
  componentDidMount() {
    this.props.dispatch(loadUserEngagement({ UserGuid:  this.props.getSubscriberUserGUID() }));
    this.props.dispatch(loadSupportGroup({ UserGuid: this.props.getSubscriberUserGUID() }));
       this.props.dispatch(loadPeer({ UserGuid: this.props.getSubscriberUserGUID() }));
    this.props.dispatch(
          getAppSubscribersChat({ UserGuid:  this.props.getSubscriberUserGUID() })
        );
  }

  render() {
    const { isConcernedPerson } = this.props;
    return (
      <div>
        <div className="subscriber-tab-section">
          <App {...this.props} isConcernedPerson={isConcernedPerson} />
        </div>
      </div>
    );
  }
}

export default SubscriberTabs;
