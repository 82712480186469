import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
// { record, index, indent, expanded }
const InsuranceDetail = (record, index, indent, expanded) => {
  const { ClientInsurance } = record || {};
  const {
    InsuranceName,
    InsurerPlanName,
    PatientRelationshipToPrimaryInsured,
    PrimaryInsuranceId,
    SubscriberMedicaidId,
    SubscriberGroupNumber,
  } = (ClientInsurance && ClientInsurance[0]) || {};
  return (
    <div className="insurance-detail px-3">
      {ClientInsurance ? (
        <div className="insurance-section">
          <div className="row">
            <div className="col-4">
              <div className="items">
                <span className="label-text">Insurance Name</span>
                <span className="label-value">{InsuranceName}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="items">
                <span className="label-text">Plan Name</span>
                <span className="label-value">{InsurerPlanName}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="items">
                <span className="label-text">Patient Relationship to Primary Insured</span>
                <span className="label-value">{PatientRelationshipToPrimaryInsured}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="items">
                <span className="label-text">Insurance ID</span>
                <span className="label-value">{PrimaryInsuranceId}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="items">
                <span className="label-text">Medicaid ID</span>
                <span className="label-value">{SubscriberMedicaidId}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="items">
                <span className="label-text">Group Number</span>
                <span className="label-value">{SubscriberGroupNumber}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center border-1">
          <span style={{ color: '#828282', fontSize: '16px', fontWeight: '400' }}>
            No insurance found.
          </span>
        </div>
      )}
    </div>
  );
};

export default InsuranceDetail;
