import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import ServiceCode from './changeRequest/serviceCode';
import TargetPrice from './changeRequest/targetPrice';
import {
  payerPortalRequestGet,
  payerPortalRequestUpsert,
  resetPayerPortalRequestUpsert,
} from '../../../store/actions';
import ConfirmationPopup from '../../../Components/ConfirmationPopup';
import { Loader2 } from '../../../Components/Loader';

const ContractDetails = ({ clientId, smartContractId, onClose, getLatestData }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (smartContractId) {
      getRequestData();
    }
  }, []);
  const { RequestGet, isReqSuccess, isFetching } = useSelector(
    ({
      payer_portal_request_get: { data: requestGetData, isFetching, error },
      payer_portal_request_upsert: { data: requestUpsert },
    }) => {
      return {
        RequestGet: requestGetData && requestGetData.Request,
        isReqSuccess: requestUpsert && requestUpsert.success,
        isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    if (smartContractId && isReqSuccess) {
      dispatch(resetPayerPortalRequestUpsert());
      getRequestData();
      getLatestData();
      onClose();
    }
  }, [isReqSuccess]);

  const getRequestData = () => {
    dispatch(
      payerPortalRequestGet({
        Json: JSON.stringify({
          Request: [
            {
              SmartContractId: smartContractId,
              Id: null,
              IsContractRequest: true,
              IsTermsRequest: false,
              IsNotes: false,
            },
          ],
        }),
      }),
    );
  };
  const onDeny = record => {
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30311}]} Approve
    // --{"Request":[{"PayerPortalRequestId":int,"StatusId":30312}]} decline
    dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: record.PayerPortalRequestId,
              StatusId: 30312,
            },
          ],
        }),
      }),
    );
  };
  const onGoTo = (record, statusId) => {
    dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: record.PayerPortalRequestId,
              StatusId: statusId,
            },
          ],
        }),
      }),
    );
    // onClose();
  };
  return (
    <div className="contract-details">
      <Loader2 loading={isFetching} />
      {RequestGet &&
        RequestGet.map((item, index) => {
          const { RequestType, StatusId, StatusUpdatedOn } = item;
          return (
            <div className="sections">
              <div className="section-item">
                {RequestType == 30201 && <ServiceCode RequestItem={item} index={index} />}
                {RequestType == 30200 && <TargetPrice RequestItem={item} index={index} />}
              </div>
              <div className="sections-action">
                {StatusId ? (
                  <div>
                    {StatusId == 30312 && (
                      <span className="denied">{StatusUpdatedOn && StatusUpdatedOn}</span>
                    )}
                    {StatusId == 30311 && (
                      <span className="approved">{StatusUpdatedOn && StatusUpdatedOn}</span>
                    )}
                    {StatusId == 30330 && <span className="denied">Pending</span>}
                  </div>
                ) : (
                  <span className="denied">Pending</span>
                )}
              </div>
            </div>
          );
        })}

      {/* <div className="sections">
        <div className="section-item">
          <TargetPrice RequestGet={RequestGet} />
        </div>
        <div className="sections-action">
          <button type="button" className="btn btn-outline-primary px-4 py-2">
            Go to Contract
          </button>
          <button type="button" className="btn-deny px-4 py-2 mr-0">
            Deny
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default ContractDetails;
