import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoCompleteText,
} from '../../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Autocomplete from 'react-autocomplete';
import CustomModal from '../../core/modal';
import AntdModal from '../../Components/CustomModal';
import moment from 'moment';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import { getFormFieldMetaData, upsertFormData } from '../../store/actions';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Deactivate_Sucess_Msg,
} from '../../app-config';

import validate, {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from '../enrollmentForm/validation/validate';

import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../core/generic-form';
import { ProcessCombos } from '../../store/comboConfig';
import jsPDF from 'jspdf';
import PageTitle from '../../Components/PageTitle';

let namedExports = {
  dateofbirthNormalize,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
};

export const FORMATTER_TYPES = {
  dateofbirthNormalize: dateofbirthNormalize,
  ssnFormatter: ssnFormatter,
  ssnNormalize: ssnNormalize,
  MobileFormatter: MobileFormatter,
  MobileNormalize: MobileNormalize,
};

const EmergencyContact = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  Filter_StateProvince,
  Country,
  emergencyContacts,
  change,
  disable,
  dispatch,
}) => {
  const countryChange = (countryISO, currentIndex) => {
    /*if (countryISO) {
      dispatch(
        ProcessFilterCombos({
          combo: 'StateProvince',
          CountryISO: countryISO,
          filterBy: countryISO,
        }),
      );
    }*/
  };

  var formFields = [
    {
      name: 'line1',
      // fieldName: 'Line 1',
      fieldName: 'Permanent Address Line 1',
    },
    {
      name: 'line2',
      // fieldName: 'Line 2',
      fieldName: 'Permanent Address Line 2',
    },
  ];

  return (
    <DynamicSection
      sectionKey={'EmergencyContact'}
      sections={fields}
      title={'Address'}
      deleteTitle={'Delete Address'}
      addTitle={'Address'}
      formFields={formFields}
      onAdd={() => fields.push({ country: 'USA' })}
      disable={disable}
      rows={emergencyContacts}
    />
  );
};
const EmergencyContact1 = connect(state => {
  const emergencyContacts = selector(state, 'EmergencyContact');
  return {
    emergencyContacts,
  };
})(EmergencyContact);

class GerericForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
    };
  }

  sendFormData = values => {
    let valueArray = Object.keys(values);
    const obj = {};

    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else {
        obj[key] = values[key];
      }
    }
    let jsonData = {
      DynamicForm: [
        {
          FormName: 'TestForm',
          FormDataId: null,
          FormData: [
            {
              ...obj,
            },
          ],
        },
      ],
    };
    this.props.dispatch(
      upsertFormData({
        Json: JSON.stringify(jsonData),
      }),
    );
  };
  componentDidMount() {
    // let optionsList = this.state.formFields.map(item => {
    //   item.options;
    // });
    // this.setState({ optionsList });

    var result = ProcessCombos(
      'Gender,StateProvince,EthnicRace,FaithType,EducationType,MaritalStatus,Veteran,ReferenceSource,CenterProgramType,Ethnicity',
    );
    // ,Practitioner
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let jsonData = {
      DynamicForm: [
        {
          FormName: 'Announcement',
        },
      ],
    };
    this.props.dispatch(
      getFormFieldMetaData({
        Json: JSON.stringify(jsonData),
      }),
    );
  }

  componentWillReceiveProps({ genericFormData, comboData }) {
    // if (comboData && JSON.stringify(comboData) != JSON.stringify(this.props.comboData)) {
    //   if (genericFormData) {
    //     debugger;
    //     let genericFormDataModified = genericFormData.map(item => {
    //       return { ...item, options: comboData[item.options] };
    //     });
    //     this.setState({ formFields: genericFormDataModified });
    //   }
    // }
    if (
      genericFormData &&
      JSON.stringify(genericFormData) != JSON.stringify(this.props.genericFormData)
    ) {
      // let validationConversion = genericFormData.map(item => {
      //   debugger;
      //   let v = JSON.parse(item.validate);
      //   v = v.map(item => {
      //     return validate[item];
      //   });
      //   return {
      //     ...item,
      //   };
      // });
      let validationConversion = genericFormData;

      this.setState({ formFields: validationConversion });
    }
  }

  render() {
    const { formFields, optionsList } = this.state;
    const { handleSubmit, comboData } = this.props;
    // let
    // const [...optionsList] = object.keys(comboData);
    return (
      <React.Fragment>
        <PageTitle TitleText="Generic Form<" />
        <div className="container-fluid" style={{ background: 'white' }}>
          <form
            onSubmit={handleSubmit(values => {
              this.sendFormData(values);
            })}
          >
            <div className="enrollment-form pt-2 pb-2">
              <div className="row">
                {' '}
                <DynamicForm
                  formFields={formFields}
                  // disable={disable}
                />
              </div>
              <div className="row">
                <div class="col-12">
                  <FieldArray
                    name="EmergencyContact"
                    component={EmergencyContact1}
                    // RelationShip={RelationShip}
                    // Filter_StateProvince={Filter_StateProvince}
                    // Country={Country}
                    // change={change}
                    // disable={IsDeActivated}
                    dispatch={this.props.dispatch}
                  />
                </div>
              </div>
            </div>
            <input
              type="submit"
              class="btn"
              value="Submit"
              // disabled={IsDeActivated}
            />
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { combos, get_form_field_meta_data } = state;
  const { data: getGenericFormFields } = get_form_field_meta_data;
  let comboData = {};
  let genericFormData = [];
  if (combos && combos.data) {
    comboData = combos.data || {};
  }
  if (getGenericFormFields) {
    genericFormData = getGenericFormFields.FormFieldMetaData;

    // genericFormData = genericFormData.map(item => {
    //   let a;
    //   if (item.validate) {
    //     a = item.validate.split(',');
    //   } else {
    //     a = ['required'];
    //   }

    //   return { ...item, validate: a };
    // });
  }
  return {
    genericFormData,
    comboData,
  };
};

const ReduxGenericForm = reduxForm({
  form: 'genericForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(GerericForm)));

const selector = formValueSelector('genericForm');

export default ReduxGenericForm;
