import React, { Component, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
  CheckboxGroup,
  ReduxRadio,
} from '../../../../../component/custom-redux-components';
import { Button, Radio } from 'antd';
import { labOrderPanelCodeComboGet } from '../../../../../store/actions';
// import ReduxRadioGroup from '../../../../../component/custom-redux-components/radio';

let Validator = {
  required: value => (value ? undefined : 'Required'),
  text: value => (value && value.trim().length == 0 ? 'Required' : 'Dummy Text'),
};

const LabOrderForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    disable,
    PayerId,
    onCancel,
    PayerType,
    InvoiceDisplay,
    practitionerOffice,
    LabOrg,
    LabTestCode,
    Practitioner,
    LabTestPriority,
    DiagnosisList,
    isLabOrderFetching,
    selectedLabTests,
    labTestsLists,
    OrderInfo,
    showDrawer,
    panelIndex,
    isEnterprise,
    ClientId,
  } = props;
  const dispatch = useDispatch();
  const DiagnosisOption =
    (DiagnosisList &&
      DiagnosisList.map(item => {
        return {
          label: item.Text,
          value: item.Value,
          description: item.FullDescription,
        };
      })) ||
    [];
  let keys;
  let isTestSelected;
  if (selectedLabTests) {
    keys = Object.keys(selectedLabTests);
    keys.map(item => {
      let isTest = Object.values(selectedLabTests[parseInt(item)]).some(item => item.length);
      if (isTest) {
        isTestSelected = isTest;
      }
    });
  }

  const onSampleTypeChange = e => {
    let value = e.target.value;
    dispatch(
      labOrderPanelCodeComboGet({
        Json: JSON.stringify({
          LabOrder: [
            {
              ClientId: ClientId,
              IsEnterprise: true,
              SampleType: value,
            },
          ],
        }),
      }),
    );
    props.onSampleTypeChange();
  };
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div className="rcm-panel enrollment-form">
        <div className="row mb-2">
          {!isEnterprise && (
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <Field name={`SpeciminId`} type="text" component={ReduxInput} disabled={disable} />
                <label className="placeholder-label">Specimen Id</label>
              </div>
            </div>
          )}
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <Field
                name={`PractitionerId`}
                type="text"
                component={ReduxSelect}
                fieldName={'Provider'}
                disabled={disable}
                validate={Validator.required}
                options={Practitioner}
                placeholder="Provider*"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <Field
                name={`LabOrgId`}
                type="text"
                component={ReduxSelect}
                fieldName={'Laboratory'}
                disabled={disable}
                validate={Validator.required}
                options={LabOrg}
                placeholder="Laboratory*"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <Field
                name={`Medications`}
                type="textarea"
                component={ReduxTextarea}
                disabled={disable}
              />
              <label className="placeholder-label">Medications</label>
            </div>
          </div>
          <div className="col-8">
            <div className="form-group">
              <Field
                name={`Comments`}
                type="textarea"
                component={ReduxTextarea}
                disabled={disable}
              />
              <label className="placeholder-label">Medication Notes</label>
            </div>
          </div>
          <div className="col-4 checkbox-to-radio lab-sample-types">
            <div className="form-group">
              <p style={{ fontSize: '16px', fontWeight: '600', marginBottom: '6px' }}>
                Sample Type
              </p>
              <Field
                name={`SampleType`}
                component={ReduxRadio}
                options={['Oral Fluids', 'Urine']}
                defaultValue="Urine"
                onChange={onSampleTypeChange}
                disabled={disable}
                //  className="service-code"
              />
            </div>
          </div>
          <div className="col-12">
            <h5 class="">Lab Test</h5>
          </div>
          <div className="col-12 mb-2">
            {disable && (
              <>
                <span className="font-weight-bold">Lab Test Code : </span>
                <span className="font-weight-normal">{OrderInfo.LabOrder[0].LabTestCode}</span>
              </>
            )}
            {!disable && (
              <button
                type="button"
                class="btn btn-outline-primary"
                onClick={() => {
                  showDrawer();
                }}
              >
                {isTestSelected ? 'Edit Lab Test(s)' : 'Select Lab Test(s)'}
              </button>
            )}
            {isTestSelected > 0 && (
              <div className="test-list-text row">
                <div className="col-12 mb-2">
                  {keys.map(testItem => {
                    let testKeys = Object.keys(selectedLabTests[parseInt(testItem)]);
                    let isTest = Object.values(selectedLabTests[parseInt(testItem)]).some(
                      item => item.length,
                    );
                    return (
                      <>
                        {isTest && (
                          <span className="h6">
                            {labTestsLists && labTestsLists[parseInt(testItem)] && labTestsLists[parseInt(testItem)].label}
                          </span>
                        )}
                        <div className="d-flex flex-wrap">
                          {testKeys.map(item => {
                            let testLabel = labTestsLists[parseInt(testItem)] &&
                              labTestsLists[parseInt(testItem)].children[parseInt(item)] &&
                              labTestsLists[parseInt(testItem)].children[parseInt(item)].label;
                            let testValue = selectedLabTests[parseInt(testItem)][parseInt(item)];
                            if (testValue.length) {
                              return (
                                <div className="mb-2 px-2">
                                  <span className="cat-title">{testLabel}</span>
                                  <span className="cat-desc">{testValue.join(', ')}</span>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <Field
                name={`LabTestPriority`}
                type="text"
                component={ReduxSelect}
                fieldName={'Lab Test Priority'}
                disabled={disable}
                options={LabTestPriority}
                placeholder="Lab Test Priority"
                // placeholder={inex == 0 ? 'Lab Test Priority*' : 'Lab Test Priority'}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <Field
                component={ReduxDateInput}
                name={`ObservationDate`}
                label="Observation Date*"
                fieldName={'Observation Date'}
                validate={Validator.required}
                disabled={disable}
              />
              <i className="far fa-calendar-alt form-control-icon" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <Field
                name={`LabInstructions`}
                type="textarea"
                component={ReduxTextarea}
                disabled={disable}
                // validate={Validator.required}
                fieldName={'Lab Instructions'}
              />
              <label className="placeholder-label">Lab Instructions</label>
            </div>
          </div>
          {/* <div class="col-12">
            <FieldArray
              name="LabOrder"
              disable={disable}
              component={AddMoreCode}
              LabTestCode={LabTestCode}
              LabTestPriority={LabTestPriority}
              selectedLabTests={selectedLabTests}
              labTestsLists={labTestsLists}
              showDrawer={props.showDrawer}
              OrderInfo={OrderInfo}
            />
          </div> */}

          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group mb-2 custom-radio">
              <span className="d-block mb-2">Fasting</span>
              <label className="mr-3">
                Yes
                <Field
                  name="IsFasting"
                  component={ReduxInput}
                  type="radio"
                  disabled={disable}
                  value={'true'}
                  style={{
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #bcbcbc',
                    marginRight: '5px',
                  }}
                />
                <span className="checkmark"></span>
              </label>
              <label className="mr-3">
                No
                <Field
                  name="IsFasting"
                  component={ReduxInput}
                  type="radio"
                  value={'false'}
                  disabled={disable}
                  style={{
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #bcbcbc',
                    marginRight: '5px',
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          {/* {DiagnosisList.length > 0 && !isEnterprise && (
            <div class="col-12 my-3">
              <h6>Diagnosis</h6>
              <div
                class="col-12"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '350px',
                }}
              >
                <div
                  class="form-group"
                  style={{
                    flexFlow: 'wrap',
                    display: 'flex',
                  }}
                >
                  <Field
                    name="DiagnosisListId"
                    component={CheckboxGroup}
                    col={24}
                    widthClassName={'w-75'}
                    options={DiagnosisOption}
                    fullDescription={true}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>
          )} */}

          {/* {isEnterprise && ( */}
          <div className="col-12">
            <div className="form-group">
              <Field
                name={`DiagnosisListId`}
                component={ReduxInput}
                disabled={disable}
                validate={Validator.required}
                fieldName={'Diagnosis'}
              />
              <label className="placeholder-label">
                Diagnosis (Comma delimited, ICD 10 codes)*
              </label>
            </div>
          </div>
          {/* )} */}

          {!disable && (
            <div className="col-12 col-md-12 col-lg-12 text-right">
              <button onClick={onCancel} type="button" class="btn btn-outline-primary mx-2">
                Cancel
              </button>
              <Button
                loading={isLabOrderFetching}
                htmlType="submit"
                className="save-button"
                style={{ padding: '0 20px', height: '45px' }}
              >
                Create Order
              </Button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
const mapStateToPropsChild = state => {
  const {
    combos,
    medicate_combos,
    combo_practitioner_center_get,
    combo_diagnosis_list,
    combo_lab_order_practitioner,
  } = state;
  // const { data: practitionerData } = combo_practitioner_center_get;
  const { data: diagnosisData } = combo_diagnosis_list;
  const { data: practitionerData } = combo_lab_order_practitioner;

  let LabOrg = [],
    LabTestCode = [],
    LabTestPriority = [];
  let Practitioner = [];
  let DiagnosisList = [];

  if (combos.data && combos.data['LabOrg']) {
    LabOrg = combos.data['LabOrg'];
    LabTestCode = combos.data['LabTestCode'];
    LabTestPriority = combos.data['LabTestPriority'];
  }
  if (practitionerData && practitionerData[0] && practitionerData[0].PractitionerList) {
    Practitioner = practitionerData[0].PractitionerList || [];
    // FullDescription;
  }
  if (diagnosisData && diagnosisData.data) {
    DiagnosisList = diagnosisData.data || [];
  }
  return {
    LabOrg,
    LabTestCode,
    Practitioner: Practitioner,
    LabTestPriority,
    DiagnosisList,
  };
};
const ReduxLabOrderForm = reduxForm({
  form: 'labOrderForm',
  enableReinitialize: true,
})(connect(mapStateToPropsChild)(withRouter(LabOrderForm)));

const AddMoreCode = ({
  disable,
  fields,
  LabTestCode,
  LabTestPriority,
  showDrawer,
  selectedLabTests,
  labTestsLists,
  OrderInfo,
}) => {
  if (fields.length == 0) {
    fields.push({});
  }
  return (
    <>
      {fields.map((field, index) => {
        let keys;
        if (selectedLabTests && selectedLabTests[index]) {
          keys = Object.keys(selectedLabTests[index]);
        }
        return (
          <div class="row" style={{ paddingTop: '10px' }} key={field}>
            <h5 class="col-6">Lab Test - {index + 1}</h5>
            <div class="col-6">
              {fields.length > 1 && (
                <a
                  class="delete-contact"
                  onClick={
                    disable
                      ? undefined
                      : () => {
                          fields.remove(index);
                          // deleteMoreCode(index);
                        }
                  }
                  disabled={disable}
                >
                  <i class="fas fa-trash" />
                </a>
              )}
            </div>
            <div className="col-12 mb-2">
              {disable && (
                <>
                  <span className="font-weight-bold">Lab Test Code : </span>
                  <span className="font-weight-normal">
                    {OrderInfo.LabOrder[index].LabTestCode}
                  </span>
                </>
              )}
              {!disable && (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    showDrawer(index);
                  }}
                >
                  {keys ? 'Edit Lab Test(s)' : 'Select Lab Test(s)'}
                </button>
              )}
              {keys && (
                <div className="test-list-text row">
                  {keys.map(item => {
                    let label = labTestsLists[parseInt(item)].label;
                    let testValue = selectedLabTests[index][parseInt(item)];
                    if (testValue.length) {
                      return (
                        <div className="col-12 mb-2" key={item}>
                          <span className="cat-title">{label}</span>
                          <span className="cat-desc">{testValue.toString()}</span>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
            {/* <div className="col-4">
                <div className="form-group">
                  <Field
                    name={`${field}.LabTestCodeId`}
                    type="text"
                    component={ReduxSelect}
                    fieldName={'Lab Test Code'}
                    disabled={disable}
                    options={LabTestCode}
                    validate={Validator.required}
                    placeholder={'Lab Test Code*'}
                  />
                </div>
              </div> */}
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <Field
                  name={`${field}.LabTestPriority`}
                  type="text"
                  component={ReduxSelect}
                  fieldName={'Lab Test Priority'}
                  disabled={disable}
                  options={LabTestPriority}
                  placeholder="Lab Test Priority"
                  // placeholder={inex == 0 ? 'Lab Test Priority*' : 'Lab Test Priority'}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group">
                <Field
                  component={ReduxDateInput}
                  name={`${field}.ObservationDate`}
                  label="Observation Date*"
                  fieldName={'Observation Date'}
                  validate={Validator.required}
                  disabled={disable}
                />
                <i className="far fa-calendar-alt form-control-icon" />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <Field
                  name={`${field}.LabInstructions`}
                  type="textarea"
                  component={ReduxTextarea}
                  disabled={disable}
                  validate={Validator.required}
                  fieldName={'Lab Instructions'}
                />
                <label className="placeholder-label">Lab Instructions*</label>
              </div>
            </div>
          </div>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-1 pb-1">
          <a
            class="add-contact"
            onClick={disable ? undefined : () => fields.push({})}
            disabled={disable}
          >
            <label style={{ cursor: 'pointer' }}>
              + Add {fields.length == 0 ? 'Lab test' : 'Another Lab Test'}
            </label>
          </a>
        </div>
      </div>
    </>
  );
};

export default ReduxLabOrderForm;
