import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button, Menu } from 'antd';
import SectionTab from './custom/sectionTab';
import DefaultPricing from './custom/defaultPricing';
import Loader, { Loader2 } from '../../Components/Loader';
import ListRenderer from '../../Components/listRenderer';
import { getAdvisorCost } from '../../store/actions';
import PageTitle from '../../Components/PageTitle';
const activeKey = 0;

class MultiPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabName: 'counselors',
    };
  }
  handleTabClick = e => {
    this.setState({ tabName: e.key });
  };
  componentDidMount() {
    // this.props.dispatch(
    //   getAdvisorCost({
    //     Json: JSON.stringify({
    //       AdvisorCost: [
    //         {
    //           BadgeId: 'C',
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }
  render() {
    const { isFetching } = this.props;
    const { tabName } = this.state;
    const list = [
      {
        Name: 'DEFAULT PRICING',
        ExtraComponent: () => <DefaultPricing />,
      },
      {
        Name: 'COUNSELORS',
        ExtraComponent: () => <SectionTab BadgeId={'L'} TabName="Counselors" />,
      },
      {
        Name: 'COACHES',
        ExtraComponent: () => <SectionTab BadgeId={'C'} TabName="Coaches" />,
      },
      {
        Name: 'GUIDES',
        ExtraComponent: () => <SectionTab BadgeId={'G'} TabName="Guides" />,
      },
    ];
    return (
      <div className="pricing-tab">
        <PageTitle TitleText="Advisor Pricing" />
        <div class="container-fluid">
          <div class="row top-heading-row  top-table-search mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              {/* <Link to="/manage" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link> */}
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.goBack()}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Advisor Pricing</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="vertical-tabs enrollment-form">
                <ListRenderer
                  list={list}
                  activeKey={activeKey}
                  tabPosition="left"
                  destroyInactiveTabPane={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row mx-0" style={{ background: '#E3F4FF' }}>
          <div className="col-12">
            <Menu
              onClick={this.handleTabClick}
              selectedKeys={[tabName]}
              mode="horizontal"
              className="dashboard-menu"
            >
              <Menu.Item key="guides">GUIDES</Menu.Item>
              <Menu.Item key="counselors">COUNSELORS</Menu.Item>
              <Menu.Item key="coaches">COACHES</Menu.Item>
            </Menu>
          </div>
        </div>
        <>
          {tabName === 'guides' && <SectionTab />}
          {tabName === 'counselors' && <SectionTab />}
          {tabName === 'coaches' && <IndividualPricing />}
        </> */}
      </div>
    );
  }
}

export default connect()(MultiPricing);
