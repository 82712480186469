import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ProfilePictureChange extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {}
  render() {
    const { EventJsonData, isFetching } = this.props;
    let { ProfilePictureURL } = EventJsonData.ProfilePicture && EventJsonData.ProfilePicture[0];
    return (
      <div class="border-0">
        <div class="row justify-content-center">
          <img src={ProfilePictureURL} height="300" />
        </div>
      </div>
    );
  }
}

// const mapStateToProps = ({ get_call_session_video_url }) => {
//   const { data, error, isFetching } = get_call_session_video_url;
//   let VideoCallUrl = '';

//   if (get_call_session_video_url && get_call_session_video_url.data) {
//     VideoCallUrl = data.result;
//   }
//   return {
//     VideoCallUrl,
//     isFetching,
//   };
// };
export default ProfilePictureChange;
