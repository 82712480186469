import React from 'react';
import moment from 'moment';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate, {
  dateofbirthNormalize,
  // ssnFormatter,
  ssn9Formatter,
  ssn11Formatter,
  // ssnNormalize,
  ssn9Normalize,
  ssn11Normalize,
  MobileFormatter,
  MobileNormalize,
} from './validation';

import {
  ReduxInput,
  ReduxSsnInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxPhoneInput,
  // TextareaWithDictation,
} from '../../component/custom-redux-components';

export const COMPONENT_TYPES = {
  // Toogle: 'Toogle',
  // DatePicker: 'DatePicker',
  // PhoneInput: 'PhoneInput',
  ReduxSelect: 'ReduxSelect',
  // ReduxTextSelect: 'ReduxTextSelect',
  ReduxInput: 'ReduxInput',
  ReduxSsnInput: 'ReduxSsnInput',
  ReduxPhoneInput: 'ReduxPhoneInput',
  ReduxDateInput: 'ReduxDateInput',
  ReduxToggle: 'ReduxToggle',
  ReduxAutoComplete: 'ReduxAutoComplete',
  // FrequencyReduxSelect: 'FrequencyReduxSelect',
  // CheckboxGroup: 'CheckboxGroup',
  // ReduxCheckbox: 'ReduxCheckbox',
  // ReduxTimePicker: 'ReduxTimePicker',
  ReduxCurrencyInput: 'ReduxCurrencyInput',
  ReduxTextarea: 'ReduxTextarea',
  // TextareaWithDictation: 'TextareaWithDictation',
  ReduxDollarInput: 'ReduxDollarInput',
  DisplayTitle: 'displayTitle',
  DisplayInfo: 'DisplayInfo',
};

export const FORMATTER_TYPES = {
  dateofbirthNormalize: dateofbirthNormalize,
  ssn9Formatter: ssn9Formatter,
  ssn11Formatter: ssn11Formatter,
  ssn9Normalize: ssn9Normalize,
  ssn11Normalize: ssn11Normalize,
  MobileFormatter: MobileFormatter,
  MobileNormalize: MobileNormalize,
};

export const DynamicSection = ({
  sectionKey,
  sections,
  title,
  deleteTitle,
  addTitle,
  formFields,
  onAdd,
  disable,
  rows,
}) => {
  return (
    <>
      {sections.map((item, index) => {
        return (
          <>
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <h6>
                  {title} {index + 1}
                </h6>
              </div>
              <div className="col-12 col-md-6">
                <a
                  className="delete-contact"
                  onClick={() => sections.remove(index)}
                  disabled={disable}
                >
                  <i className="fas fa-trash" /> {deleteTitle}
                </a>
              </div>
            </div>
            <div className="row mb-4">
              <DynamicForm
                formFields={formFields}
                mapper={sectionKey + '[' + index + ']'}
                row={rows[index]}
                disable={disable}
              />
            </div>
          </>
        );
      })}
      <div className="row">
        <div className="col-12 text-right pt-4 pb-4">
          <a className="add-contact" onClick={onAdd} disabled={disable}>
            {sections.length >= 1 && <label> + Add Another {addTitle}</label>}
            {sections.length == 0 && <label> + Add {addTitle}</label>}
          </a>
        </div>
      </div>
    </>
  );
};

// set fieldName: true when we add validations
// fill placeholder for lookups and placeholder != fieldName
// set isRequired: true to see required field
// when isRequired: true, no need to set validate.required
// use condition as a function rather than value
// displayCalenderIcon true to show calender icon for text box. ex: dob
// componentType == COMPONENT_TYPES.ReduxCurrencyInput to get $ icon on left
// displayTitle true to show only title or header. title attribute to fill text

const DynamicForm = ({ formFields, mapper, row, disable, handleEvent, helperComponent }) => {
  return formFields.map(
    ({
      allowDebug,
      componentClass,
      componentType,
      isRequired,
      title,
      displayCalenderIcon,
      disabled,
      component,
      options,
      class: className,
      condition,
      for: forMap,
      displayTitle,
      renderComponent,
      smallInfo,
      isHelperComponent,
      // helperComponent,
      filterBy,
      defaultFilterBy,
      eventType,
      FieldClass,
      ...formField
    }) => {
      if (allowDebug) {
      }
      componentType = componentType || COMPONENT_TYPES.ReduxInput;
      var label = title || formField.placeholder || formField.fieldName;
      var isText = true;
      // formField.validate = formField.validate || [];
      let eventData = {
        [eventType]: handleEvent,
      };

      formField.validate =
        (formField.validate &&
          JSON.parse(formField.validate).map(item => {
            {
              return validate[item];
            }
          })) ||
        [];
      className = className || FieldClass || 'col-12 col-md-6 col-lg-4';
      if (isRequired) {
        label = (formField.placeholder || formField.fieldName) + '*';
        if (formField.placeholder) {
          formField.placeholder = formField.placeholder + '*';
        }
        if (formField.validate.length == 0) {
          formField.validate.push(validate.required);
        }
      }
      component = component || ReduxInput;
      if (componentType == COMPONENT_TYPES.ReduxDateInput) {
        component = ReduxDateInput;
        isText = false;
        formField.onBlurEvent = handleEvent;
      }

      if (options) {
        isText = false;
        component = ReduxSelect;
        formField.placeholder = formField.placeholder || label;
        if (filterBy) {
          var filter = defaultFilterBy;
          if (row && row[filterBy]) {
            filter = row[filterBy];
          }
          options = options[filter];
        }
      }
      if (condition && !condition()) {
        return <></>;
      }

      if (mapper) {
        formField.name = mapper + '.' + formField.name;
      }

      if (componentType == COMPONENT_TYPES.ReduxToggle) {
        return (
          <div className={className || 'col-12 col-md-6 col-lg-4'} key={formField.name}>
            <div className="form-group">
              <label className={`custom-control-label ${formField.ClassName}`}>
                {formField.fieldName} {'   '}
                <Field
                  name={formField.name}
                  type="text"
                  className="custom-control-input not-empty"
                  component={ReduxToggle}
                  disabled={disable || disabled}
                />
              </label>
            </div>
          </div>
        );
      } else if (componentType == COMPONENT_TYPES.ReduxDollarInput) {
        return (
          <div className="col-12 col-md-6 col-lg-4" key={formField.name}>
            <div className="form-group">
              <Field
                type="text"
                disabled={disable || disabled}
                {...formField}
                component={ReduxDollarInput}
                // style={{ width: 'auto' }}
                options={options}
                dollarPlaceHolder={label}
              />
            </div>
          </div>
        );
      } else if (componentType == COMPONENT_TYPES.ReduxSsnInput) {
        // var ssnLength = formField.FiledLength;
        let v;
        if (formField.validate && JSON.parse(formField.validate).length > 0) {
          v = JSON.parse(formField.validate);
          v = v.map(item => {
            {
              return validate[item];
            }
          });
          // v = v.map(item => {
          //   debugger;
          //   if (item == 'ssn') {
          //     if (ssnLength == 9) {
          //       debugger;
          //       return value => {
          //         if (value) {
          //           const onlyNums = value.replace(/[^\d\*]/g, '');
          //           if (onlyNums.length !== 9) {
          //             return 'Please enter 9 digits';
          //           }
          //         } else {
          //           return 'Social Security Number Required';
          //         }
          //       };
          //     } else {
          //       debugger;
          //       return value => {
          //         if (value) {
          //           const onlyNums = value.replace(/[^\d\*]/g, '');
          //           if (onlyNums.length !== ssnLength) {
          //             return `Please enter ${ssnLength} digits`;
          //           }
          //         } else {
          //           return 'Social Security Number Required';
          //         }
          //       };
          //     }
          //   } else {
          //     return validate[item];
          //   }
          // });
        }

        return (
          <div className="col-12 col-md-6 col-lg-4" key={formField.name}>
            <div className="form-group">
              <Field
                type="text"
                disabled={disable || disabled}
                {...formField}
                component={ReduxSsnInput}
                // style={{ width: 'auto' }}
                options={options}
                dollarPlaceHolder={label}
                format={FORMATTER_TYPES[formField.format]}
                normalize={FORMATTER_TYPES[formField.normalize]}
                // validate={v}
              />
              <label className="placeholder-label">National ID (SSN)*</label>
            </div>
          </div>
        );
      } else if (componentType == COMPONENT_TYPES.ReduxPhoneInput) {
        return (
          <div className="col-12 col-md-6 col-lg-4 d-flex" key={formField.name}>
            <div className="form-group w-100">
              <Field
                {...formField}
                component={ReduxPhoneInput}
                validate={validate.isValidMobile}
                // validate={[validate.isValidMobile, validate.required]}
                // country={options} // currently country is in component side
                placeholder={label}
                fieldName={'Phone'}
              />
            </div>
          </div>
        );
      } else if (displayTitle || componentType == COMPONENT_TYPES.DisplayTitle) {
        return (
          <div className="col-12 mt-2">
            <h6>{title || label}</h6>
          </div>
        );
      } else if (componentType == COMPONENT_TYPES.DisplayInfo) {
        return (
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <Field
                type="text"
                name={formField.name}
                component={ReduxInput}
                fieldName={formField.fieldName}
                disabled
              />
              <label className="placeholder-label">{label}</label>
            </div>
          </div>
        );
      } else if (renderComponent) {
        return (
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">{renderComponent}</div>
          </div>
        );
      }

      if (componentType == COMPONENT_TYPES.ReduxAutoComplete) {
        return (
          <div className="col-12 col-md-6 col-lg-4" key={formField.name}>
            <div className="form-group">
              <Field
                name={formField.name}
                type="text"
                component={ReduxAutoComplete}
                options={options}
                label={label}
                placeholder={label}
                validate={isRequired ? validate.required : null}
                fieldName={formField.fieldName}
              />
            </div>
          </div>
        );
      }
      // if (componentType == COMPONENT_TYPES.ReduxTextarea) {
      //   return (
      //     <div className="col-12 col-md-6 col-lg-4">
      //       <div class="form-group">
      //         <Field
      //           name={formField.name}
      //           type="text"
      //           component={TextareaWithDictation}
      //           label={label}
      //           showPlaceHolder={true}
      //         />
      //       </div>
      //     </div>
      //   );
      // }
      componentClass = componentClass || 'form-group';
      return (
        <div className={className} key={formField.name}>
          <div className={componentClass}>
            <Field
              type="text"
              {...formField}
              component={component}
              options={options}
              disabled={disable || disabled}
              format={formField.format ? FORMATTER_TYPES[formField.format] : null}
              normalize={formField.normalize ? FORMATTER_TYPES[formField.normalize] : null}
              {...eventData}
            />
            {isText && <label className="placeholder-label">{label}</label>}
            {smallInfo && <span className="small-info">{smallInfo}</span>}
            {isHelperComponent && <div className="helper-action">{helperComponent}</div>}
            {displayCalenderIcon && <i className="far fa-calendar-alt form-control-icon" />}
          </div>
        </div>
      );
    },
  );
};
export const GetGenericFormData = values => {
  let valueArray = Object.keys(values);
  const obj = {};
  for (const key of valueArray) {
    if (values[key] instanceof Date) {
      obj[key] = moment(values[key]).format('MM/DD/YYYY');
    } else if (values[key] != null && typeof values[key] == 'object') {
      obj[key] =
        values[key] && values[key].length > 0
          ? values[key].map(item => item.Value).toString()
          : null;
    } else {
      obj[key] = values[key];
    }
  }
  return obj;
};

export default DynamicForm;
