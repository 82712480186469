import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../component/custom-redux-components';
import { getSurveyCategoryQuestion, resetGetSurveyCategoryQuestion } from '../../store/actions';

class SurveysQuestionPreview extends Component {
  constructor(props) {
    super(props);
    const { Value } = props.surveyQuestionCategoryId[0];
    this.state = {
      questionCategoryId: Value,
    };
  }
  componentDidMount() {
    this.props.dispatch(
      getSurveyCategoryQuestion({
        JsonInput: JSON.stringify({
          Survey: [
            {
              SurveyQuestionCategoryId: this.state.questionCategoryId,
            },
          ],
        }),
      }),
    );
  }
  componentWillUnmount() {
    this.props.dispatch(resetGetSurveyCategoryQuestion());
  }
  render() {
    const { surveyQuestionCategoryId, categoryQuestion } = this.props;
    const { questionCategoryId } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-10">
            <div class="form-group reduxSelectNew">
              <ReduxSelect
                options={surveyQuestionCategoryId}
                value={questionCategoryId}
                onChange={catId => {
                  this.props.dispatch(
                    getSurveyCategoryQuestion({
                      JsonInput: JSON.stringify({
                        Survey: [
                          {
                            SurveyQuestionCategoryId: catId,
                          },
                        ],
                      }),
                    }),
                  );
                  this.setState({
                    questionCategoryId: catId,
                  });
                }}
                placeholder="Select Survey"
                allowDefault={true}
              />
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-12">
            {categoryQuestion && categoryQuestion.length > 0 && (
              <h6 className="" style={{ color: 'rgb(74, 176, 254)' }}>
                Question Count : {categoryQuestion.length}
              </h6>
            )}
          </div>
          <div className="col-12">
            {categoryQuestion &&
              categoryQuestion.map((Question, index) => {
                const OptionArray = [
                  Question.Option1Text,
                  Question.Option2Text,
                  Question.Option3Text,
                  Question.Option4Text,
                  Question.Option5Text,
                  Question.Option6Text,
                  Question.Option7Text,
                  Question.Option8Text,
                  Question.Option9Text,
                  Question.Option10Text,
                ];
                return (
                  <div className="question-preview">
                    <span className="question">
                      {/* {index + 1}. */}
                      {Question.Question && renderHTML(Question.Question)}
                    </span>
                    <ol type="a">
                      {OptionArray.map(option => {
                        return option && <li className="option">{option}</li>;
                      })}
                    </ol>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ get_survey_category_question }) => {
  return {
    categoryQuestion:
      (get_survey_category_question.data && Object.values(get_survey_category_question.data)) || [],
  };
};
export default connect(mapStateToProps)(SurveysQuestionPreview);
