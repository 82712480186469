import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Modal } from 'antd';

import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import CustomModal from '../../../../core/modal';
import { getGroupAppointment } from '../../../../store/actions';
import GroupAppointmentModal from '../../appointments/groupAppointmentModal';
import PersistTableSort from '../../../../component/persistTableSort';
import GridSearch from '../../../../Components/gridSearch';

class GroupAppointmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRowData: '',
      isModelOpen: false,
      groupModalView: false,
      columns: [
        {
          title: 'Session Date',
          dataIndex: 'SessionDate',
          key: 'SessionDate',
          sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Start Time / End Time',
          dataIndex: 'SessionTime',
          key: 'SessionTime',
          sorter: (a, b) => isComparer(a.SessionTime, b.SessionTime),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Program',
          dataIndex: 'ProgramDescription',
          key: 'ProgramDescription',
          sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
          sorter: (a, b) => isComparer(a.Status, b.Status),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => {
            if (text == 'UnSigned') {
              return <span style={{ color: '#E87A7A' }}>{text}</span>;
            } else {
              return <span>{text}</span>;
            }
          },
        },
        {
          title: 'Action',
          render: (text, record, index) => {
            if (!props.isReadOnlyUserSession) {
              return (
                <div class="tools-grid">
                  <>
                    <span
                      className="start-btn"
                      onClick={() => {
                        // this.showDrawer(record);
                        this.setState({
                          groupModalView: true,
                          appointmentRecord: record,
                        });
                      }}
                    >
                      Manage Appointment{' '}
                    </span>
                  </>
                </div>
              );
            } else {
              return null;
            }
          },
        },
      ],
    };
  }

  getGroupId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      groupId = parseInt(groupId);
    } else {
      groupId = null;
    }
    return groupId;
  };

  componentDidMount() {
    let GroupId = this.getGroupId();
    this.props.dispatch(
      getGroupAppointment({
        json: JSON.stringify({
          GroupAppointment: [
            {
              GroupId: GroupId,
            },
          ],
        }),
      }),
    );

    let param = new URLSearchParams(this.props.history.location.search);
    let groupAppointmentId = param.get('groupAppointmentId');
    if (groupAppointmentId) {
      setTimeout(() => {
        this.setState({
          groupModalView: !this.props.isReadOnlyUserSession,
          appointmentRecord: { GroupAppointmentId: groupAppointmentId },
        });
      }, 1000);
    }
  }
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      getGroupAppointment({
        json: JSON.stringify({
          GroupAppointment: [
            {
              Search: value,
              GroupId: this.getGroupId(),
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { columns, groupModalView, appointmentRecord, currentRowData, isModelOpen } = this.state;

    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page mb-5">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 mb-5">
                <div class="border-0">
                  <div className="mb-3">
                    <div className="row mt-5 d-flex align-items-center">
                      <div class="col-6">
                        {' '}
                        <h4 className="">APPOINTMENT LIST</h4>
                      </div>
                      <div class="col-6 d-flex align-items-center">
                        <GridSearch onSearchChange={this.onSearchChange} />
                      </div>
                    </div>
                  </div>
                  <div class="row pt-3 pb-5 rcm-subtab-table-block">
                    <div class="col-12">
                      <PersistTableSort
                        name="appointmentList"
                        columns={columns}
                        dataSource={this.props.appointmentList}
                        size={'middle'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {groupModalView && (
            <Modal
              zIndex={100}
              visible={groupModalView}
              footer={null}
              closable={false}
              width={700}
              destroyOnClose={true}
            >
              <GroupAppointmentModal
                GroupAppointmentId={appointmentRecord.GroupAppointmentId}
                AppointmentRecord={appointmentRecord}
                onCancel={() => {
                  this.props.dispatch(
                    getGroupAppointment({
                      json: JSON.stringify({
                        GroupAppointment: [
                          {
                            GroupId: this.getGroupId(),
                          },
                        ],
                      }),
                    }),
                  );

                  let param = new URLSearchParams(this.props.history.location.search);
                  let groupAppointmentId = param.get('groupAppointmentId');
                  if (groupAppointmentId) {
                    this.props.history.goBack();
                  }
                  this.setState({
                    groupModalView: false,
                    appointmentRecord: {},
                  });
                }}
              />
            </Modal>
          )}

          {isModelOpen && (
            <CustomModal
              isOpen={this.state.isModelOpen}
              header={this.state.header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ get_group_appointment }) => {
  const { data: GroupAppointmentData } = get_group_appointment;

  let appointmentList = [];

  if (GroupAppointmentData) {
    appointmentList = GroupAppointmentData.data;
  }

  return {
    appointmentList,
  };
};

export default connect(mapStateToProps)(withRouter(GroupAppointmentList));
