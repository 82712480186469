import React, { Component } from 'react';
import { BGContent2 } from '../commonSection';
 const FamilyProgram = props => {
   const { StatusData, isSupportGroup } = props;
   return (
     <>
       <div
         className="d-flex align-items-center justify-content-between"
         style={{ backgroundColor: '#FC2E20', borderRadius: '12px', padding: '2px' }}
       >
         <div className="d-flex align-items-center">
           {' '}
           <img src={`images/license/hour_glass.svg`} width="86" height="79" alt="" />
           <div style={{ fontSize: '26px', fontWeight: '600', color: '#fff' }}>
             {' '}
             Uh-Oh! Your Trial has ended.{' '}
             <span style={{ fontSize: '20px', fontWeight: '600' }}>
               Purchase license to enjoy hassle-free usage.
             </span>
           </div>
         </div>

         <span
           style={{
             fontSize: '16px',
             fontWeight: '600',
             background: '#fff',
             color: '#0070E0',
             borderRadius: '25px',
             padding: '8px',
             marginRight: '50px',
             cursor: 'pointer',
           }}
           onClick={() => {
             props.history.push('/manage/maintain-license');
           }}
         >
           Purchase Family Program License
         </span>
       </div>
       <BGContent2
         moduleName={'Uh-oh! Your free trial has ended.'}
         backGroundImage={isSupportGroup?`images/license/supportGroupBG.png`:`images/license/familyBG.svg`}
         //  moduleLogoImage={`images/license/fanilyProgram.svg`}
         //  labelTitle={' What you will get:'}
         tagLine={
           "View all connected people by purchasing license. To help your clients interact with their families, purchase a license for the full program. With this license, you'll have access to all of the features and benefits that come with being a member of our family program. Thanks for considering us as your go-to source for family support!"
         }
         buyButtonText={'Purchase Family Program License'}
         interaction={[
           {
             image: `images/license/user.svg`,
             tagNumber:
               (StatusData &&
                 StatusData.Interaction &&
                 StatusData.Interaction[0].PeopleConnected) ||
               '0',
           },
           {
             image: `images/license/chat.svg`,
             tagNumber:
               (StatusData && StatusData.Interaction && StatusData.Interaction[0].NewMessages) ||
               '0',
           },
         ]}
         peopleConnected={
           (StatusData && StatusData.Interaction && StatusData.Interaction[0].PeopleConnected) ||
           '0'
         }
         newMessages={
           (StatusData && StatusData.Interaction && StatusData.Interaction[0].NewMessages) || '0'
         }
       />
     </>
   );
 };
export default FamilyProgram;