import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QuestionType from '../../../../RCM/patientEnrollmentForm/formSteps/questionType/questionComponent';
import Loader, { Loader2 } from '../../../../../Components/Loader';

class PreviousCare extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      SurveyQuestion,
      SurveyId,
      onNext,
      setValue,
      scope,
      goToPreviuos,
      handleNext,
    } = this.props;
    // const { SurveyId, SurveyMainTitle, SurveyQuestion } = SurveyQuestionList || {};
    return (
      <div
        className="row survery-question-box client-screening"
        style={{ maxWidth: 'unset', marginTop: '0px' }}
      >
        <div className="col-12 mt-2">
          {SurveyQuestion && (
            <div
              style={{
                padding: '5px',
                margin: '0px 5px',
              }}
            >
              <QuestionType
                SurveyQuestion={SurveyQuestion[0].Questions}
                SurveyId={SurveyId}
                onNext={onNext}
                setValue={setValue}
                scope={scope}
                level={0}
                // isReadOnly={this.state.isReadOn}
                // saveSignature={this.saveSignature}
              />
            </div>
          )}
        </div>
        <div class="col-12 text-right survey-pagination-button">
          <button
            onClick={goToPreviuos}
            class="btn btn-outline-primary btn-eForm-Prev mr-2"
            //   disabled={disable}
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            class="btn btn-outline-primary btn-eForm-Prev mr-2"
            //   disabled={disable}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
export default connect()(PreviousCare);
