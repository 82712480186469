import React, { Component } from 'react';
import { Avatar, Divider, Tooltip } from 'antd';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { BGContent2, BGContentInternal2 } from '../commonSection';
const SmallGroup = props => {
const { StatusData, isSubscriberProfileScreen } = props;
  return (
    <>
      {!isSubscriberProfileScreen && (
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ backgroundColor: '#FC2E20', borderRadius: '12px', padding: '2px' }}
        >
          <div className="d-flex align-items-center">
            {' '}
            <img src={`images/license/hour_glass.svg`} width="86" height="79" alt="" />
            <div style={{ fontSize: '26px', fontWeight: '600', color: '#fff' }}>
              {' '}
              Uh-Oh! Your license has expired.{' '}
              <span style={{ fontSize: '20px', fontWeight: '600' }}>
                Purchase license to enjoy hassle-free usage.
              </span>
            </div>
          </div>

          <span
            style={{
              fontSize: '16px',
              fontWeight: '600',
              background: '#fff',
              color: '#0070E0',
              borderRadius: '25px',
              padding: '8px',
              marginRight: '50px',
              cursor: 'pointer',
            }}
            onClick={() => {
              props.history.push('/manage/maintain-license');
            }}
          >
            Purchase Small Group License
          </span>
        </div>
      )}
      {!isSubscriberProfileScreen && (
        <BGContent2
          moduleName={'Uh-oh! Your license has expired.'}
          backGroundImage={`images/license/smallGroupBG.svg`}
          tagLine={`Small Groups feature license creates small groups meeting sessions for patients.
This is a great way to get patients together to discuss their progress and challenges, and to support each other in their recovery.`}
          buyButtonText={'Purchase Small Group License'}
          isSmallGroupModule={true}
          smallGroupCreated={
            (StatusData && StatusData.Interaction && StatusData.Interaction[0].SmallGroupCreated) ||
            0
          }
        />
      )}

      {isSubscriberProfileScreen && (
        <BGContentInternal2
          moduleName={'Uh-oh! Your license has expired.'}
          backGroundImage={`images/license/smallGroupBGInternal.png`}
          tagLine={`Small Groups feature license creates small groups meeting sessions for patients.
This is a great way to get patients together to discuss their progress and challenges, and to support each other in their recovery.`}
          buyLinkText={'Purchase Small Groups License'}
          trialButtonText={'Start your 14-day trial'}
          isSmallGroupModule={true}
          smallGroupCreated={
            (StatusData && StatusData.Interaction && StatusData.Interaction[0].SmallGroupCreated) ||
            0
          }
        />
      )}
    </>
  );
};
export default SmallGroup;
