import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import _blank from '../../images/blank-profile.png';
import Twilio from '../../../Components/twilio';
import { studentBanner, clientScreeningStatusUpdate } from '../../../store/actions';
import Axios from 'axios';
import {
  NoRisk,
  LowRisk,
  MediumRisk,
  HighRisk,
} from '../../RCM/patientEnrollmentForm/formSteps/questionType/risk';
import FlaggedStatus from '../components/FlaggedStatus';
import AntdModal from '../../../Components/CustomModal';

class ProspectBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callModelVisible: false,
      bannerInfo: {},
    };
  }
  getRisk = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
            IsDefaultPreIntakeSurvey: true,
          },
        ],
      }),
    };
    Axios.post('Survey/ClientRiskGet', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            riskResponse: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  getBannerData = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
          },
        ],
      }),
    };
    this.props.dispatch(studentBanner(data));
  };
  componentDidMount() {
    this.getBannerData();
    this.getRisk();
  }
  componentWillReceiveProps({ bannerInfo, riskResponse, isScreeningStatusUpdated }) {
    if (bannerInfo && JSON.stringify(bannerInfo) != JSON.stringify(this.props.bannerInfo)) {
      this.setState({ bannerInfo });
    }
    if (riskResponse && riskResponse != this.props.riskResponse) {
      this.setState({ riskResponse });
    }
    if (
      isScreeningStatusUpdated &&
      isScreeningStatusUpdated != this.props.isScreeningStatusUpdated
    ) {
      this.getBannerData();
    }
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  handleQualified = () => {
    const { PreItakeSurveyId } = this.state.bannerInfo;
    this.props.dispatch(
      clientScreeningStatusUpdate({
        Json: JSON.stringify({
          Survey: [
            {
              SurveyId: PreItakeSurveyId,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { isRiskChart, isflagged, isScreeningStatusUpdated, isFetching } = this.props;
    const { bannerInfo } = this.state;
    const {
      StudentId,
      PrefferedName,
      DisplayName,
      PrimaryPhone,
      Email,
      StateOrProvince,
      PreIntakeClientStatus,
      City,
      PreItakeSurveyId,
      StudentImageUrl,
      IsVoiceCallAllowed,
      EditActionUrl,
      EnrolledSurveyId,
      ProgramAllignmentSurveyId,
      QualifiedCount,
      FlaggedCount,
      IsQualified,
    } = bannerInfo || {};
    return (
      <div class="card border-0 rounded-0 prospect-banner mb-3">
        <div class="row card-body align-items-center">
          <div class="col-sm-12 col-lg-6">
            <div className="d-flex align-items-center">
              <div className="name">
                <img src="images/icons/user.svg" />
                <span className="text">{PrefferedName}</span>
              </div>
              <div className="phone">
                {IsVoiceCallAllowed === 1 ? (
                  // && !this.props.isReadOnlyUser
                  <span
                    title="Call"
                    className="d-flex align-items.center"
                    style={{ color: '#46B4FE', marginBottom: '0', cursor: 'pointer' }}
                    onClick={event => {
                      this.setState({
                        callModelVisible: true,
                        clickedRecord: {
                          Phone: PrimaryPhone,
                          Name: PrefferedName,
                          ProfileImageUrl: StudentImageUrl,
                        },
                      });
                      event.stopPropagation();
                    }}
                  >
                    <img
                      style={{
                        display: 'block',
                        marginRight: '5px',
                      }}
                      src={`images/icons/phone-outline.svg`}
                      alt="Calling Allowed"
                      width="10px"
                    />
                    {PrimaryPhone}
                  </span>
                ) : (
                  <>
                    <img src={`images/icons/phone-outline_gray.svg`} alt="Calling Not Allowed" />
                    <span>{PrimaryPhone ? PrimaryPhone : 'N/A'}</span>
                  </>
                )}
              </div>
              <div className="mail">
                <img src="images/icons/mail.svg" />
                <span>{Email ? Email : 'N/A'}</span>
              </div>
            </div>
          </div>
          {isflagged && !isFetching && (
            <div class="col-sm-12 col-lg-6 screening-status-block justify-content-end">
              <FlaggedStatus FlaggedStatus={bannerInfo} />
              {/* {!IsQualified && (
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      class="delete-btn"
                      onClick={e => {
                        onClick();
                        e.stopPropagation();
                      }}
                      data-toggle="modal"
                      data-target="#delete-Screen-Tool"
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="change-qualified">Change to Qualified</span>
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5 text-center">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to change the status to Qualified?
                        </h4>
                        <p style={{color: 'red', fontWeight: '600', fontSize: '16px'}}>Please Note: This Change is irreversible.</p>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn btn-outline-primary black-btn"
                          onClick={e => {
                            onCancel();
                            e.stopPropagation();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          class="btn"
                          onClick={e => {
                            e.stopPropagation();
                            onOk();
                            this.handleQualified();
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  )}
                />
              )} */}
            </div>
          )}
          {isRiskChart && (
            <div className="col-sm-12 col-lg-6">
              <div className="d-flex justify-content-end">
                <div className="d-flex risk-chart border rounded py-2 px-3 shadow-sm">
                  <span className="mr-2" style={{ fontSize: '14px' }}>
                    Client Risk Chart:
                  </span>
                  {this.state.riskResponse === '0' && NoRisk}
                  {this.state.riskResponse === '1' && LowRisk}
                  {this.state.riskResponse === '2' && MediumRisk}
                  {this.state.riskResponse === '3' && HighRisk}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { student_banner, client_screening_status_update } = state;
  const { data, isFetching, error } = student_banner;

  let bannerInfo = null;
  let preItakeSurveyId = null;
  let isScreeningStatusUpdated;
  if (data) {
    bannerInfo = data || {};
    preItakeSurveyId = data.PreItakeSurveyId;
  }
  if (client_screening_status_update.data && client_screening_status_update.data.success) {
    isScreeningStatusUpdated = true;
  }
  return {
    bannerInfo,
    preItakeSurveyId,
    isScreeningStatusUpdated,
    isFetching,
  };
};

export default connect(mapStateToProps)(ProspectBanner);
