import React, { Component, useEffect } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import { Modal } from 'antd';
import { ReduxInput } from '../../../../component/custom-redux-components';
import validate from '../../../../core/generic-form/validation';
import { payerPortalRequestUpsert } from '../../../../store/actions';
import SubmitButton from '../../../../Components/submitButton';

const DocumentForm = ({
  fields,
  meta: { touched, error },
  disable,
  dispatch,
  ExistingInsuranceRows,
  clientId,
  Practitioner,
  onSaveClick,
  onCareTeamDelete,
  showSave,
}) => {
  useEffect(() => { fields.push({});}, [])
  return (
    <>
      {fields.map((name, index) => {
        let indexN = index + 1;
        return (
          <>
            <div class="row my-2 align-items-center">
              <div class="col-10">
                <div class="form-group mb-0">
                  <Field
                    name={`${name}.documentName`}
                    type="text"
                    component={ReduxInput}
                    // placeholder="Enter Document Name"
                    validate={validate.required}
                    fieldName={'Document Name'}
                  />
                  <label class="placeholder-label">Enter Document Name*</label>
                </div>
              </div>

              <div className="col-2">
                <a
                  class="col-1 delete-contract"
                  style={{ margin: '0px', fontSize: '16px', fontWeight: '800' }}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      fields.remove(index);
                    }
                  }}
                >
                  <i class="fas fa-trash" /> Delete
                </a>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a
            class="add-contact"
            onClick={() => {
              if (disable) {
              } else {
                fields.push({});
              }
            }}
            disabled={disable}
          >
            {fields.length >= 1 && (
              <label>
                <a>+ REQUEST ANOTHER DOCUMENT</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                <a>+ REQUEST DOCUMENT</a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

class DocumentsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimelineModalOpen: false,
    };
  }

  onSubmit = values => {
    const { Docs } = values;
    let docArray = [];

    Docs &&
      Docs.map((item, index) => {
        docArray.push({
          PayerPortalRequestId: null,
          SmartContractId: this.props.contractId,
          RequestType: '30212 ',
          Id: null,
          Note: item.documentName,
        });
      });
    this.props.dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [...docArray],
        }),
      }),
    );
  };

  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.props.reset('contractDocumentForm');
    }
  }
  render() {
    const { handleSubmit, ExistingCareRows, isFetching } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <div className="enrollment-form p-5">
          <div class="row">
            <h4 className="text-left">Request Document(s)</h4>
          </div>

          <div className="col-12" style={{height: '400px', overflowX: 'hidden', overflowY: 'auto'}}>
            <FieldArray name="Docs" component={DocumentForm} />
          </div>
          <div className="col-12 text-right">
            {/* <button
              disabled={!(ExistingCareRows && ExistingCareRows.length > 0)}
              type="submit"
              className="btn btn-primary px-5"
            >
              Send Request
            </button> */}
            <SubmitButton
              disabled={!(ExistingCareRows && ExistingCareRows.length > 0)}
              loading={isFetching}
              type="submit"
              value={'Send Request'}
              style={{ height: '45px' }}
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const { payer_portal_request_upsert } = state;
  const { data, isFetching } = payer_portal_request_upsert;
  const ExistingCareRows = formValueSelector('contractDocumentForm')(state, 'Docs');

  return {
    ExistingCareRows,
    isFetching,
    isSuccess: data && data.success,
  };
};

const ReduxDocumentsModal = reduxForm({
  form: 'contractDocumentForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(DocumentsModal)));

export default ReduxDocumentsModal;
