import React, { useState, useEffect } from 'react';
import { Collapse, Button, Drawer, Divider, Space, Tag, Tooltip } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { Field, reduxForm, formValueSelector, submit, reset, FieldArray } from 'redux-form';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  ReduxSelect,
  ReduxInput,
  ReduxTextarea,
} from '../../../../../../component/custom-redux-components';
import validate from '../../../../../../core/generic-form/validation';
import ReduxGoals from './goals';
import { smartContractUpdate } from '../../../../../../store/actions';
import getParam from '../../../../../../util/getParam';

const { Panel } = Collapse;

const AddRemarkForm = props => {
  const [remarkSubmit, setRemarkSubmit] = useState(null);
  const [textAreaInputData, setTextAreaInputData] = useState(null);
  const { handleSubmit, drawerData, onClose } = props;
  const { AssignedDate, DueDate, IsCompleted, SmartContractTaskId, StatusId, StatusText, Task } =
    drawerData && drawerData.taskData;
  const dispatch = useDispatch();

  const { remarkFieldData } = useSelector(state => {
    return {
      remarkFieldData: formValueSelector('addRemarkFormTask')(state, 'remarks'),
    };
  }, shallowEqual);

  useEffect(() => {
    if (remarkSubmit != null) {
      dispatch(submit('addRemarkFormTask'));
      setRemarkSubmit(null);
    }
  }, [remarkSubmit]);

  useEffect(() => {
    setTextAreaInputData(remarkFieldData);
  }, [remarkFieldData]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Task
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {Task}
          </span>
        </div>
      </div>
      <div class="col-12 enrollment-form mt-3">
        <div class="form-group">
          <Field
            name="remarks"
            type="text"
            errorMessage="remark Required"
            component={ReduxTextarea}
            validate={validate.required}
            fieldName={'Remarks'}
          />
          <label class="placeholder-label">Add Remarks*</label>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        // data-dismiss="modal"
        onClick={() => {
          setRemarkSubmit('Remarks');
          onClose();
        }}
        disabled={!textAreaInputData}
      >
        Add Remarks
      </button>
    </form>
  );
};

const ReduxAddRemarkForm = reduxForm({
  form: 'addRemarkFormTask',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    const { drawerData } = props;
    const { AssignedDate, DueDate, IsCompleted, SmartContractTaskId, StatusId, StatusText, Task } =
      drawerData && drawerData.taskData;
    let data = {
      SmartContract: [
        {
          SmartContractTaskId: SmartContractTaskId,
          Remarks: values.remarks,
          IsCompleted: drawerData.IsCompleted,
        },
      ],
    };
    dispatch(
      smartContractUpdate({
        json: JSON.stringify(data),
        markAsComplete: true,
      }),
    );
  },
})(connect()(withRouter(AddRemarkForm)));

const Tasks = props => {
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const SMART_CONTRACT_ID = getParam(props.history, 'smartContractId') || null;
  const {
    fields,
    smartContractMeasure,
    mesuareValues,
    initialValues,
    goalLibrary,
    goalTimePeriod,
    taskIndex,
    goalIndex,
    existingRows,
  } = props;

  const onChange = key => {
    console.log(key);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getBaseLineScore = index => {
    let baseLineScore =
      initialValues &&
      initialValues[index] &&
      initialValues[index].SurveyQuestionCategoryId &&
      smartContractMeasure.filter(
        item => item.Value == initialValues[index].SurveyQuestionCategoryId,
      )[0] &&
      smartContractMeasure.filter(
        item => item.Value == initialValues[index].SurveyQuestionCategoryId,
      )[0].BaselineScore;
    return baseLineScore;
  };

  const showDrawer = (taskData, IsCompleted) => {
    setOpen(true);
    setDrawerData({ taskData, IsCompleted });
  };
  useEffect(() => {
    if (props.fields && props.fields.length < 1) {
      props.fields.push();
    }
  }, []);

  return (
    <div className="row buildServiceBundle" style={{ background: '#f8f8f8' }}>
      <div className="col-12 col-md-12 col-lg-12 my-1">
        <div className="">
          <Collapse
            // defaultActiveKey={['0']}
            // expandIcon={({ isActive }) => (
            //   <RightCircleOutlined
            //     rotate={isActive ? -90 : 90}
            //     style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
            //   />
            // )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={onChange}
          >
            {fields.length > 0 ? (
              fields.map((item, index) => {
                const { StatusId, StatusText, IsCompleted, SmartContractTaskId } =
                  (existingRows && existingRows[index]) || {};
                return (
                  <>
                    <Panel
                      showArrow={true}
                      key={index}
                      className="task-panel-border"
                      header={
                        <>
                          <div className="row">
                            <div className="col-md-3 panel-header-text">{`Task ${index + 1}`}</div>
                            <div className="col-md-9 text-right d-flex">
                              {' '}
                              {!props.disabled && (
                                <button
                                  type="button"
                                  class="btn-newCon"
                                  style={{
                                    position: 'unset',
                                    width: 'unset',
                                    background: 'unset',
                                  }}
                                  onClick={event => {
                                    event.stopPropagation();
                                    fields.push();
                                  }}
                                >
                                  + Add {fields.length == 0 ? 'Task' : 'Another Task'}
                                </button>
                              )}
                              {fields.length > 1 && (
                                <div>
                                  {!props.disabled && (
                                    <a
                                      class="col-1 delete-contract"
                                      onClick={() => {
                                        if (fields.length && fields.length > 0) {
                                          fields.remove(index);
                                        }
                                      }}
                                    >
                                      <i class="fas fa-trash" />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    >
                      <div className="mt-3 d-none">
                        {SMART_CONTRACT_ID && SmartContractTaskId && !StatusId ? (
                          <>
                            {' '}
                            <div className="col-12 text-right">
                              <div
                                className="btn-deny"
                                style={{
                                  display: 'inline-block',
                                  cursor: 'pointer',
                                  margin: '0px',
                                }}
                                onClick={() => {
                                  showDrawer(existingRows[index], '2');
                                }}
                              >
                                Mark as Failed
                              </div>
                              <div
                                className="btn-approved"
                                style={{ display: 'inline-block' }}
                                onClick={() => {
                                  showDrawer(existingRows[index], '1');
                                }}
                              >
                                Mark as Completed
                              </div>
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        <>
                          {StatusId && StatusText ? (
                            <>
                              <div className="col-12 text-right d-flex align-items-center justify-content-end ">
                                <span style={{ marginBottom: '29px' }}>
                                  <Tag
                                    icon={
                                      IsCompleted ? (
                                        <CheckCircleOutlined />
                                      ) : (
                                        <CloseCircleOutlined />
                                      )
                                    }
                                    color={IsCompleted ? 'success' : 'error'}
                                  >
                                    <Tooltip title="Service Status">{StatusText}</Tooltip>
                                  </Tag>
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                      <div
                        class="mx-4"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {' '}
                        <div class="row p-2">
                          <div className={SMART_CONTRACT_ID ? 'col-12' : 'col-12'}>
                            <div class="form-group">
                              <Field
                                name={`${item}Task`}
                                type="text"
                                component={ReduxInput}
                                // component={ReduxSelect}
                                // options={smartContractMeasure}
                                validate={validate.required}
                                // placeholder={`Milestone ${index + 1}*`}
                                fieldName={'Task'}
                                class="form-control"
                                disabled={props.disabled}
                                style={{ background: 'unset' }}
                              />
                              <label class="placeholder-label">{`Task ${index + 1}*`}</label>
                            </div>
                          </div>

                          {/* <div className="col-3 d-flex align-items-center">
                                {getBaseLineScore(index) != undefined && (
                                  <span
                                    className="p-2"
                                    style={{
                                      border: '1px solid grey',
                                      background: 'white',
                                      color: 'black',
                                      fontSize: '16px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    Baseline Score:{' '}
                                    <span style={{ fontWeight: 700 }}>
                                      {getBaseLineScore(index)}
                                    </span>
                                  </span>
                                )}
                              </div> */}
                        </div>
                      </div>
                    </Panel>
                  </>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
        </div>
      </div>

      <Drawer
        title="Add Remarks"
        placement="right"
        onClose={onClose}
        open={open}
        width="600"
        destroyOnClose={true}
      >
        <ReduxAddRemarkForm drawerData={drawerData} onClose={onClose} />
      </Drawer>
    </div>
  );
};

const ReduxTasks = withRouter(Tasks);
export default ReduxTasks;
