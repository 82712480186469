import React, { Component } from 'react';
import { Collapse, Checkbox, Row, Col } from 'antd';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
// import {
//   ReduxInput,
//   ReduxSelect,
//   ReduxToggle,
//   ReduxDateInput,
//   ReduxCheckbox,
//   ReduxTimePicker,
//   CheckboxGroup,
// } from '../../../component/custom-redux-components';
const { Panel } = Collapse;
class TreeDataComponentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousInterventionIds: [],
      interventionString: [],
    };
  }

  componentWillReceiveProps({ AppointmentNote }) {
    if (
      AppointmentNote &&
      AppointmentNote.TreatmentPlanInterventionId &&
      AppointmentNote.TreatmentPlanInterventionId.length > 0 &&
      JSON.stringify(AppointmentNote) != JSON.stringify(this.props.AppointmentNote)
    ) {
      let intArray = JSON.parse(AppointmentNote.TreatmentPlanInterventionId)
        ? JSON.parse(AppointmentNote.TreatmentPlanInterventionId).split(',')
        : [];
      this.setState({ previousInterventionIds: intArray });
    }
  }
  render() {
    return (
      <>
        <div>
          <div>
            <div
              className="col-md-12 text-left mt-3 px-0"
              style={{
                color: 'rgb(130, 130, 130)',
                fontSize: '16px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              {' '}
              Selected Interventions{' '}
            </div>
            {this.props.treeData &&
              this.props.treeData.map((item, index) => {
                const dimensionTitle = item.Description;
                return (
                  <>
                    <div
                      header={`DIMENSION${index + 1} : ${item.Description}`}
                      key={index}
                      className="mt-2 mb-2"
                    >
                      {item.TreatmentPlanProblem &&
                        item.TreatmentPlanProblem.map((item, index2) => {
                          const problemTitle = item.ProblemTitle;
                          return (
                            <>
                              <div defaultActiveKey="0">
                                <div
                                  header={`PROBLEM${index2 + 1} : ${item.ProblemTitle}`}
                                  key={index2}
                                >
                                  {item.TreatmentPlanGoal &&
                                    item.TreatmentPlanGoal.map((item, index3) => {
                                      const goalTitle = item.GoalTitle;
                                      return (
                                        <>
                                          <div defaultActiveKey="0">
                                            <div
                                              header={`GOAL${index3 + 1} : ${item.GoalTitle}`}
                                              key={index3}
                                            >
                                              {item.TreatmentPlanObjective &&
                                                item.TreatmentPlanObjective.map((item, index4) => {
                                                  const objectiveTitle = item.ObjectiveTitle;
                                                  return (
                                                    <>
                                                      <div defaultActiveKey="0">
                                                        <div
                                                          header={`OBJECTIVE${index4 + 1} : ${
                                                            item.ObjectiveTitle
                                                          }`}
                                                          key={index4}
                                                        >
                                                          <ol>
                                                            {item.TreatmentPlanIntervention &&
                                                              item.TreatmentPlanIntervention.map(
                                                                (item, index5) => {
                                                                  const interventionTitle =
                                                                    item.InterventionTitle;
                                                                  const mergedTitle = `${dimensionTitle} - ${problemTitle} - ${goalTitle} - ${objectiveTitle} - ${interventionTitle}`;
                                                                  if (
                                                                    this.state
                                                                      .previousInterventionIds &&
                                                                    this.state.previousInterventionIds.includes(
                                                                      JSON.stringify(
                                                                        item.TreatmentPlanInterventionId,
                                                                      ),
                                                                    )
                                                                  ) {
                                                                    // let data = this.state
                                                                    //   .interventionString;
                                                                    // this.state = {
                                                                    //   interventionString: [
                                                                    //     ...data,
                                                                    //     mergedTitle,
                                                                    //   ],
                                                                    // };

                                                                    // return;
                                                                    // this.props.insertInCheckedInterventionsArray(
                                                                    //   parseInt(
                                                                    //     item.TreatmentPlanInterventionId,
                                                                    //   ),
                                                                    //   mergedTitle,
                                                                    // );

                                                                    return (
                                                                      // <>
                                                                      //   <div className="row justify-content-center mt-2 mb-2">
                                                                      //     {
                                                                      //       item.TreatmentPlanInterventionId
                                                                      //     }{' '}
                                                                      //     {mergedTitle}
                                                                      //   </div>
                                                                      // </>

                                                                      <>
                                                                        <li
                                                                          style={{
                                                                            color:
                                                                              'rgb(130, 130, 130)',
                                                                          }}
                                                                        >
                                                                          {mergedTitle}
                                                                        </li>
                                                                      </>
                                                                    );
                                                                  } else {
                                                                    return <>{null}</>;
                                                                  }
                                                                },
                                                              )}
                                                          </ol>
                                                        </div>
                                                      </div>
                                                    </>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        {/* <Collapse onChange={this.callback}>
          <Panel header="This is panel header 1" key="1">
            <Collapse defaultActiveKey="1">
              <Panel header="This is panel nest panel" key="1">
                <p>{this.text}</p>
              </Panel>
            </Collapse>
          </Panel>
          <Panel header="This is panel header 2" key="2">
            <p>{this.text}</p>
          </Panel>
          <Panel header="This is panel header 3" key="3">
            <p>{this.text}</p>
          </Panel>
        </Collapse> */}
        ,
      </>
    );
  }
}
export { TreeDataComponentView };
