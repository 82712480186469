import React, { Component } from 'react';
import ClientAuthorizationsList from './clientAuthorizationsList';
import { currencyFormatter } from '../../../util/formatter';

class ClientAuthorizationInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // this.props.dispatch();
  }
  render() {
    const { authorizationOverView, authorizationsList } = this.props;
    const {
      ClaimAmount,
      TotalMeasuresDefined,
      TotalServicesAssigned,
      AuthorizationsApproved,
    } = authorizationOverView;
    return (
      <div className="d-flex flex-wrap client-authorization">
        <div className="col-12 d-flex client-authorization-container col-md-6">
          <p className="mb-0 client-authorization-label">Claim Amount</p>
          <p className="mb-0 client-authorization-value">
            {ClaimAmount && currencyFormatter.format(ClaimAmount)}
          </p>
        </div>
        <div className="col-12 d-flex client-authorization-container col-md-6">
          <p className="mb-0 client-authorization-label">Number of Authorizations Approved</p>
          <p className="mb-0 client-authorization-value">
            {AuthorizationsApproved && AuthorizationsApproved}
          </p>
        </div>
        <div className="col-12 d-flex client-authorization-container col-md-6">
          <p className="mb-0 client-authorization-label">Total Services Assigned</p>
          <p className="mb-0 client-authorization-value">
            {TotalServicesAssigned && TotalServicesAssigned}
          </p>
        </div>
        <div className="col-12 d-flex client-authorization-container col-md-6">
          <p className="mb-0 client-authorization-label">Total Measures Defined</p>
          <p className="mb-0 client-authorization-value">
            {TotalMeasuresDefined && TotalMeasuresDefined}
          </p>
        </div>
      </div>
    );
  }
}

export default ClientAuthorizationInfo;
