import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Button, Modal } from 'antd';
import moment from 'moment';
import CustomModal from '../../Components/CustomModal';
import {
  getAppUserSubscription,
  getServiceOptions,
  getServiceAdvisorList,
  setServiceAdvisor,
  updateCancelService,
} from '../../store/actions';
import ModifyService from '../PopupModals/ModifyService';
import ChangeCounselor from '../PopupModals/ChangeCounselor';
import ModifyMembership from '../PopupModals/ModifyMembership';
import { currencyFormatter2 } from '../../util/formatter';
import Twilio from '../../Components/twilio';
import Loader, { Loader2 } from '../../Components/Loader';

class ManageCounselorService extends Component {
  constructor() {
    super();
    this.state = {
      isModifyServiceModal: false,
      isModifyMembershipModal: false,
      isChangeCounselorModal: false,
    };
  }
  componentDidMount() {
    let jsonData = {
      UserSubscription: [
        {
          UserGuid: this.props.UserGuid,
        },
      ],
    };
    this.props.dispatch(
      getAppUserSubscription({
        Json: JSON.stringify(jsonData),
      }),
    );
    this.props.dispatch(
      getServiceOptions({
        Json: JSON.stringify(jsonData),
      }),
    );
    // this.props.dispatch(
    //   getServiceAdvisorList({
    //     Json: JSON.stringify(jsonData),
    //   }),
    // );
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  render() {
    const { isModifyServiceModal, isChangeCounselorModal, isModifyMembershipModal } = this.state;
    const { UserGuid, CurrentPlan, CurrentService, User, isFetching } = this.props;

    const { DisplayName, Phone } = User || {};

    return (
      <>
        <div className="border-0 mb-3 manage-counselor-service">
          <div className="mcs-card">
            {!CurrentPlan ? (
              <div className="row mx-0">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="manage-service">No membership subscriptions yet</span>
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-primary px-4 py-2"
                        style={{
                          display: 'inline',
                          marginRight: '5px',
                        }}
                        // disabled={isDischarged}
                        onClick={() => {
                          this.setState({
                            isModifyServiceModal: true,
                          });
                        }}
                      >
                        Add Membership
                      </button>
                      <button
                        className="btn px-4 py-2"
                        style={{
                          display: 'inline',
                        }}
                        // disabled={isDischarged}
                        onClick={() => {
                          this.setState({
                            callModelVisible: true,
                            clickedRecord: {
                              Name: DisplayName,
                              Phone: Phone,
                            },
                          });
                        }}
                      >
                        <figure
                          class="action-button-icon"
                          style={{ display: 'inline-block', marginRight: '2px' }}
                        >
                          <img src="images/icons/phone.svg" alt="" width="20" />
                        </figure>
                        Call {DisplayName}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row mx-0 mb-3">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span className="manage-service">Manage Service</span>
                      </div>
                      <div>
                        <button
                          className="btn px-4 py-2"
                          style={{
                            display: 'inline',
                          }}
                          // disabled={isDischarged}
                          onClick={() => {
                            this.setState({
                              callModelVisible: true,
                              clickedRecord: {
                                Name: DisplayName,
                                Phone: Phone,
                              },
                            });
                          }}
                        >
                          <figure
                            class="action-button-icon"
                            style={{ display: 'inline-block', marginRight: '2px' }}
                          >
                            <img src="images/icons/phone.svg" alt="" width="20" />
                          </figure>
                          Call {DisplayName}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mx-0">
                  {CurrentPlan && CurrentPlan.IsPlanCancelRequest === 0 ? (
                    <>
                      {CurrentPlan && CurrentPlan.PlanStatus && (
                        <div className="col-12 mb-3">
                          <div className="d-flex justify-content-between align-item-center">
                            <div className="d-flex justify-content-between align-item-center">
                              <img src="images/icons/info-circle-warning.svg" className="mr-2" />
                              <span className="warning-text">{CurrentPlan.PlanStatus}</span>
                            </div>{' '}
                            {/* <span className="dismiss">Dismiss</span> */}
                            <CustomModal
                              modalWidth={500}
                              ModalButton={({ onClick }) => (
                                <span className="dismiss" onClick={onClick}>
                                  Dismiss
                                </span>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      &times;
                                    </button>
                                    <h6 class="modal-title col-12">
                                      Are you sure you want to dismiss the request?
                                    </h6>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row">
                                      <div className="col-md-10 offset-md-1 text-center">
                                        <span style={{ color: '#828282', fontSize: '16px' }}>
                                          Client will continue with the current service if you
                                          dismiss the request
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary px-5"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      No
                                    </button>
                                    <button
                                      type="button"
                                      class="btn "
                                      onClick={() => {
                                        onOk();
                                      }}
                                    >
                                      Yes, dismiss request
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {CurrentPlan && CurrentPlan.PlanStatus && (
                        <div className="col-12 mb-3">
                          <div className="d-flex justify-content-between align-item-center">
                            <div className="d-flex justify-content-between align-item-center">
                              <img src="images/icons/info-circle-danger.svg" className="mr-2" />
                              <span className="danger-text">{CurrentPlan.PlanStatus}</span>
                            </div>{' '}
                            {/* <span className="dismiss">Dismiss</span> */}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="col-12">
                    <Loader2 loading={isFetching} />

                    {CurrentPlan ? (
                      <div className="row">
                        <div className="col-6">
                          <div className="premium-membership-block d-flex align-items-center justify-content-between px-5">
                            <div className="d-flex">
                              <img
                                src={CurrentPlan.AdvisorIconUrl || 'images/icons/star_pink.svg'}
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  borderRadius: '8px',
                                  marginRight: '10px',
                                }}
                              />
                              <div>
                                <div className="">
                                  <span
                                    className="name"
                                    style={{ fontSize: '20px', color: '#46B4FE' }}
                                  >
                                    {CurrentPlan.SubscriptionName}
                                  </span>
                                </div>
                                <div>
                                  <span className="text">Renewal Date :</span>
                                  <span className="date">
                                    {moment(CurrentPlan.RenewalDate).format('ll')}
                                  </span>
                                </div>
                                <div className="fee-info">
                                  <span className="fee">
                                    {currencyFormatter2.format(CurrentPlan.Cost || 0)}
                                  </span>
                                  <span className="text">/month</span>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <button
                                className="btn btn-outline-primary px-5 py-1"
                                onClick={() => {
                                  this.setState({
                                    // isModifyMembershipModal: true,
                                    isModifyServiceModal: true,
                                    CurrentPlan: CurrentPlan,
                                  });
                                }}
                              >
                                Modify
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="counselor-assigned-area">
                            {CurrentService ? (
                              <div className="row mx-0">
                                <div className="col-6">
                                  <div className="counselor-assigned">
                                    <div className="assigned-counselor-info">
                                      <img
                                        src={
                                          CurrentService.AdvisorIconUrl ||
                                          'images/blank-profile.png'
                                        }
                                        style={{
                                          width: '50px',
                                          height: '50px',
                                          borderRadius: '8px',
                                          marginRight: '10px',
                                        }}
                                      />
                                      <div className="counselor-info">
                                        <span className="counselor">
                                          {CurrentService.SubscriptionName}
                                        </span>
                                        <div className="renewal-date">
                                          <span className="text">Renewal Date :</span>
                                          <span className="date">
                                            {moment(CurrentService.RenewalDate).format('ll')}
                                          </span>
                                        </div>
                                        <div className="counselor-fee">
                                          <span className="fee">
                                            {currencyFormatter2.format(CurrentService.Cost || 0)}
                                          </span>
                                          <span className="text">{'/month'}</span>
                                        </div>
                                        <div className="">
                                          <button
                                            className="btn btn-outline-primary px-5 py-1"
                                            onClick={() => {
                                              this.setState({
                                                isModifyServiceModal: true,
                                              });
                                            }}
                                          >
                                            Modify
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {CurrentService.Advisor ? (
                                  <div className="col-6 counselor-change">
                                    <div className="d-flex">
                                      <img
                                        src={
                                          CurrentService.ProfileImageUrl ||
                                          'images/blank-profile.png'
                                        }
                                        style={{
                                          width: '50px',
                                          height: '50px',
                                          borderRadius: '50%',
                                          marginRight: '10px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                      <div className="">
                                        {CurrentService.Badge && (
                                          <span className="counselor-label">
                                            {CurrentService.Badge}
                                          </span>
                                        )}
                                        <div className="mt-2">
                                          <span className="counselor-name">
                                            {CurrentService.Advisor}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="counselor-add">
                                            {'Teen challenge center'}
                                          </span>
                                        </div>
                                        <div className="">
                                          <button
                                            className="btn btn-outline-primary px-5 py-1"
                                            onClick={() => {
                                              this.setState({
                                                isChangeCounselorModal: true,
                                                CurrentService: CurrentService,
                                              });
                                            }}
                                          >
                                            Change
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-6 no-counselor-assigned">
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <span className="no-counselor-text">
                                        No counselor assigned
                                      </span>
                                      <div>
                                        <button
                                          className="btn px-4"
                                          onClick={() => {
                                            this.setState({
                                              isChangeCounselorModal: true,
                                              CurrentService: CurrentService,
                                            });
                                          }}
                                        >
                                          Assign a Counselor
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-12 no-counselor-assigned">
                                  <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span className="no-counselor-text">
                                      No service subscriptions yet
                                    </span>
                                    <div>
                                      <button
                                        className="btn px-4"
                                        onClick={() => {
                                          this.setState({
                                            // isChangeCounselorModal: true,
                                            isModifyServiceModal: true,
                                          });
                                        }}
                                      >
                                        Add a service
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null
                    // <div className="row">
                    //   <div className="col-12 mb-2 counselor-service-block">
                    //     <div className="no-subscriptions-area">
                    //       <div className="d-flex flex-column justify-content-center align-items-center">
                    //         <span className="no-subscriptions-text">No subscriptions yet</span>
                    //         <div>
                    //           <button
                    //             className="btn btn-outline-primary px-4 "
                    //             // disabled={isDischarged}
                    //             onClick={() => {
                    //               this.setState({
                    //                 isModifyMembershipModal: true,
                    //               });
                    //             }}
                    //           >
                    //             Prompt User to Subscribe
                    //           </button>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                    }
                  </div>
                </div>
              </>
            )}

            {isModifyServiceModal && (
              <Modal
                visible={isModifyServiceModal}
                footer={null}
                closable={false}
                width={750}
                destroyOnClose={true}
              >
                <ModifyService
                  UserGuid={UserGuid}
                  // SubscriptionTypeId={CurrentService.SubscriptionTypeId}
                  CurrentService={CurrentService}
                  CurrentPlan={CurrentPlan}
                  onCancel={() => {
                    this.setState({
                      isModifyServiceModal: false,
                    });
                  }}
                />
              </Modal>
            )}

            {isModifyMembershipModal && (
              <Modal
                visible={isModifyMembershipModal}
                footer={null}
                closable={false}
                width={700}
                destroyOnClose={true}
              >
                <ModifyMembership
                  UserGuid={UserGuid}
                  CurrentPlan={CurrentPlan}
                  SubscriptionTypeId={CurrentPlan.SubscriptionTypeId}
                  onCancel={() => {
                    this.setState({
                      isModifyMembershipModal: false,
                    });
                  }}
                />
              </Modal>
            )}

            {isChangeCounselorModal && (
              <Modal
                visible={isChangeCounselorModal}
                footer={null}
                closable={false}
                width={700}
                destroyOnClose={true}
              >
                <ChangeCounselor
                  UserGuid={UserGuid}
                  SubscriptionTypeId={CurrentService.SubscriptionTypeId}
                  CurrentService={CurrentService}
                  onCancel={() => {
                    this.setState({
                      isChangeCounselorModal: false,
                    });
                  }}
                />
              </Modal>
            )}
            {this.state.callModelVisible && (
              <Twilio
                isOpen={this.state.callModelVisible}
                userData={this.state.clickedRecord}
                resetIsOpen={this.resetIsOpen}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ get_app_user_subscription }) => {
  const { data, isFetching, error } = get_app_user_subscription;
  let CurrentPlan,
    CurrentService,
    User = null;
  if (data && data.UserSubscription) {
    CurrentPlan = data.UserSubscription[0].CurrentPlan && data.UserSubscription[0].CurrentPlan[0];
    CurrentService =
      data.UserSubscription[0].CurrentService && data.UserSubscription[0].CurrentService[0];
    User = data.UserSubscription[0].User[0];
  }
  return {
    CurrentPlan,
    CurrentService,
    User,
    isFetching,
  };
};
export default connect(mapStateToProps)(ManageCounselorService);
