import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAppSubscribers, listCombos, getAppAdvisor } from '../store/actions';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { ReduxSelect } from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import { Typography } from 'antd';

const CheckboxGroup = Checkbox.Group;

const columns = [
  {
    title: 'Name',
    dataIndex: 'DisplayName',
    key: 'DisplayName',
    width: 150,
    sorter: (a, b) => isComparer(a.DisplayName, b.DisplayName),
    sortDirections: ['descend', 'ascend'],
    render: (text, student) => (
      <>
        {/* <div class="student-list-pic">
          <img src={student.StudentImageUrl} alt="student" />
        </div> */}
        <div class="student-list-name">
          <b>{text} </b>
        </div>
      </>
    ),
  },
  {
    title: 'Center Name',
    dataIndex: 'CenterName',
    key: 'CenterName',
    sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Advisor Role',
    dataIndex: 'AdvisorRole',
    key: 'AdvisorRole',
    sorter: (a, b) => isComparer(a.AdvisorRole, b.AdvisorRole),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    sorter: (a, b) => isComparer(a.Status, b.Status),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Active Clients',
    dataIndex: 'ActiveClients',
    key: 'ActiveClients',
    sorter: (a, b) => isNumericComparer(a.ActiveClients, b.ActiveClients),
    sortDirections: ['descend', 'ascend'],
  },

  {
    title: 'Monthly Revenue',
    dataIndex: 'MonthlyRevenue',
    key: 'MonthlyRevenue',
    render: (text, student) => (
      <>
        {/* <div class="student-list-pic">
          <img src={student.StudentImageUrl} alt="student" />
        </div> */}
        <div class="">${text}</div>
      </>
    ),
    sorter: (a, b) => isNumericComparer(a.MonthlyRevenue, b.MonthlyRevenue),
    sortDirections: ['descend', 'ascend'],
  },

  // {
  //   title: 'Age',
  //   dataIndex: 'Age',
  //   key: 'age',
  //   sorter: (a, b) => isNumericComparer(a.Age, b.Age),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Date Joined',
  //   //     dataIndex: 'appJoiningDate',
  //   //     key: 'appJoiningDate',
  //   width: 150,

  //   dataIndex: 'AppJoiningDate',
  //   key: 'AppJoiningDate',
  //   sorter: (a, b) => isDateComparer(a.AppJoiningDate, b.AppJoiningDate),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Center',
  //   dataIndex: 'CenterName',
  //   key: 'CenterName',
  //   width: 150,
  //   sorter: (a, b) => isNumericComparer(a.CenterName, b.CenterName),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Type Service',
  //   dataIndex: 'ServiceType',
  //   width: 150,
  //   key: 'ServiceType',
  //   sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Advisor Name',
  //   dataIndex: 'StaffName',
  //   width: 150,
  //   key: 'StaffName',
  //   sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Type Subscription',
  //   dataIndex: 'TypeOfSubscription',
  //   key: 'TypeOfSubscription',
  //   width: 150,
  //   sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   // title: 'Subscription Amount',
  //   title: (
  //     <Typography.Text
  //       // ellipsis={true}
  //       style={{ color: '#828282' }}
  //     >
  //       Subscription <br /> Amount
  //     </Typography.Text>
  //   ),
  //   dataIndex: 'SubscriptionAmt',
  //   key: 'SubscriptionAmt',
  //   width: 120,
  //   textWrap: 'word-break',
  //   ellipsis: true,
  //   sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Status',
  //   dataIndex: 'UserStatus',
  //   key: 'UserStatus',
  //   width: 100,
  //   sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Referral Code',
  //   dataIndex: 'ReferralCode',
  //   key: 'ReferralCode',
  //   width: 140,
  //   sorter: (a, b) => isComparer(a.ServiceType, b.ServiceType),
  //   sortDirections: ['descend', 'ascend'],
  // },

  // {
  //   title: 'SPB Rating',
  //   dataIndex: 'PsychometricScore',
  //   key: 'PsychometricScore',
  //   sorter: (a, b) => isNumericComparer(a.LadderScore, b.LadderScore),
  //   sortDirections: ['descend', 'ascend'],
  // },

  // {
  //   title: 'Counselor',
  //   dataIndex: 'CounselorName',
  //   key: 'CounselorName',
  //   sorter: (a, b) => isNumericComparer(a.CounselorName, b.CounselorName),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Faith Score',
  //   dataIndex: 'SWBScore',
  //   key: 'SWBScore',
  //   sorter: (a, b) => isNumericComparer(a.SWBScore, b.SWBScore),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'ASI Score',
  //   dataIndex: 'ASIScore',
  //   key: 'ASIScore',
  //   sorter: (a, b) => isNumericComparer(a.ASIScore, b.ASIScore),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'App Last Used Date',
  //   dataIndex: 'AppLastUsedDate',
  //   key: 'AppLastUsedDate',
  //   width: 150,
  //   sorter: (a, b) => isDateComparer(a.AppLastUsedDate, b.AppLastUsedDate),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Status',
  //   dataIndex: 'UserStatus',
  //   key: 'UserStatus',
  //   sorter: (a, b) => isDateComparer(a.UserStatus, b.UserStatus),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Alumni',
  //   dataIndex: 'UserSubscriptionType',
  //   key: 'UserSubscriptionType',
  //   sorter: (a, b) => isDateComparer(a.UserSubscriptionType, b.UserSubscriptionType),
  //   sortDirections: ['descend', 'ascend'],
  //   width: 75,
  // },
  // {
  //   title: 'Composite Score',
  //   dataIndex: 'CompositeScore',
  //   key: 'CompositeScore',
  //   sorter: (a, b) => isNumericComparer(a.CompositeScore, b.CompositeScore),
  //   sortDirections: ['descend', 'ascend'],
  // },
];

const options = [
  { Value: null, Text: 'Please Select' },
  { Value: 'Enrolled', Text: 'All' },
  { Value: 'Graduated', Text: 'Graduated' },
  { Value: 'Discharged', Text: 'Discharged' },
  { Value: 'Active', Text: 'Active' },
];

const plainOptions = ['Graduated', 'Discharged', 'Active'];

class FilterModal extends Component {
  constructor(props) {
    super(props);
    const { checked, startDate, endDate, filterCenterId } = props;
    this.state = {
      checked: checked || 'Enrolled',
      startDate: startDate || null,
      endDate: endDate || null,
      filterCenterId: filterCenterId || null,
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  handleCenterChange = filterCenterId => {
    this.setState({ filterCenterId });
  };

  resetState = () => this.setState({ checked: '', startDate: '', endDate: '', filterCenterId: '' });

  render() {
    const { onOk, onCancel, Centers, refreshGrid } = this.props;
    const { startDate, endDate, filterCenterId, checked } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
      </>
    );
    const ExampleCustomInput2 = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">End date</label>
      </>
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold d-flex ">Status</span>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mr-2 mb-2">
                  <Radio.Group name="radiogroup" onChange={this.onChange} value={checked}>
                    <Radio value="Enrolled">All</Radio>
                    <Radio value="Graduated">Graduated</Radio>
                    <Radio value="Discharged">Discharged</Radio>
                    <Radio value="Active">Active</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Date</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => this.handleChange('startDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => this.handleChange('endDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput2 />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold "> Centers</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={Centers}
                  onChange={this.handleCenterChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterCenterId}
                  placeholder="Centers"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              // onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
          <button
            type="button"
            class="btn"
            onClick={() => {
              onOk();
              refreshGrid(checked, startDate, endDate, filterCenterId);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

class AppSubscribers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
      filterType: this.getFilterType(),
      subscribers: [],
      startDate: null,
      endDate: null,
      filterCenterId: null,
    };
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getFilterType = () => {
    const { history } = this.props;
    var param = new URLSearchParams(history.location.search);
    return param.get('Filter') || param.get('filter');
  };

  componentDidMount({ searchStr, startDate, endDate, filterCenterId } = this.state) {
    const { dispatch } = this.props;

    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );

    this.state.filterType = this.getFilterType();
    // dispatch(
    //   getAppSubscribers({
    //     Filter: this.state.filterType,
    //     searchStr: searchStr,
    //     startDate,
    //     endDate,
    //     filterCenterId,
    //   }),
    // );
    dispatch(
      getAppAdvisor({
        // Filter: this.state.filterType,
        // searchStr: searchStr,
        // startDate,
        // endDate,
        // filterCenterId,
      }),
    );
  }

  componentWillReceiveProps({
    history: {
      location: { search },
    },
    subscribers,
  }) {
    if (subscribers) {
      this.setState({ subscribers });
    }
  }

  refreshGrid = (filterType, startDate, endDate, filterCenterId) => {
    const { searchStr } = this.state;
    this.setState({ checked: filterType, startDate, endDate, filterCenterId });
    // this.props.dispatch(
    //   getAppSubscribers({
    //     Filter: filterType ? filterType.toString() : null,
    //     searchStr: searchStr,
    //     startDate,
    //     endDate,
    //     filterCenterId,
    //   }),
    // );
    this.props.dispatch(
      getAppAdvisor({
        Filter: filterType ? filterType.toString() : null,
        searchStr: searchStr,
        startDate,
        endDate,
        filterCenterId,
      }),
    );
  };

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  onRowClick(UserGuid) {
    const { history } = this.props;
    history.push('/subscriberProfile/' + UserGuid);
  }

  startDateChange = date => {
    this.state.startDate = date;
    this.refreshGrid();
  };

  endDateChange = date => {
    this.state.endDate = date;
    this.refreshGrid();
  };

  handleFilterChange = filterType => {
    this.state.filterType = filterType;
    this.refreshGrid();
    this.props.history.push(`/students?Filter=${filterType}`);
  };

  handleCenterChange = filterCenterId => {
    this.state.filterCenterId = filterCenterId;
    this.refreshGrid();
  };

  render() {
    const { isFetching, Centers } = this.props;
    const { subscribers, checked, startDate, endDate, filterCenterId } = this.state;
    const FilterModalData = { checked, startDate, endDate, filterCenterId };

    return (
      <div class="container-fluid">
        {/* <Loader loading={isFetching} /> */}
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              {!this.props.isDashboard && (
                <a class="back-link" onClick={() => this.props.history.goBack()}>
                  <img src="images/back-arrow.svg" /> Back
                </a>
              )}

              <h3 class="page-title mb-3 mb-md-0">Advisor Details</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control 
                   shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>

              <CustomModal
                ModalButton={({ onClick }) => (
                  <input
                    type="button"
                    class="btn black-btn d-none"
                    value="Filter"
                    onClick={onClick}
                  />
                )}
                ModalContent={props => (
                  <FilterModal
                    {...props}
                    {...FilterModalData}
                    refreshGrid={this.refreshGrid}
                    Centers={Centers}
                  />
                )}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12 row-hover-enabled">
                    <Loader2 loading={isFetching} />
                    <Table
                      columns={columns}
                      // scroll={{ x: 'max-content' }}
                      dataSource={subscribers}
                      // size={'middle'}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: event => this.onRowClick(record.UserGuid),
                        };
                      }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                        defaultPageSize: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_app_subscribers, combos, get_app_advisor }) => {
  var { data, isFetching, error } = get_app_advisor;
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }

  var Centers = [{ Value: null, Text: 'Please Select' }];

  if (combos.data) {
    if (combos.data.Center)
      for (var center of combos.data.Center) {
        Centers.push(center);
      }
  }

  return {
    subscribers: data || [],
    isFetching,
    Centers: Centers,
  };
};
export default connect(mapStateToProps, null, null, {
  // areStatesEqual: (next, prev) => {
  //   var isEqual = prev.get_app_subscribers === next.get_app_subscribers;
  //   var isCombosEqual = prev.combos === next.combos;
  //   return isEqual && isCombosEqual;
  // },
})(AppSubscribers);
