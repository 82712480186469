import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import GoalCollapse from './goalCollapse';

const { Panel } = Collapse;

export default function BundleCollapse(props) {
  const { bundles, keyIndex, defaultOpenPanels } = props;
  const [defaultActivePanels, setDefaultActivePanels] = useState([]);

  useEffect(() => {
    if (bundles && bundles.length > 0) {
      let defaultOpenPanels =
        bundles &&
        bundles.length > 0 &&
        bundles.map((item, index) => `${item.ServiceCodeBundleName}-${index + 1}`);
      setDefaultActivePanels(defaultOpenPanels);
    }
  }, [bundles]);

  return (
    <div className="milestones-collapsed">
      {defaultActivePanels && defaultActivePanels.length > 0 && (
        <Collapse
          // defaultActiveKey={['BN01-1', 'BN02-2', 'BN03-3', 'BN04-4']}
          defaultActiveKey={defaultActivePanels}
          expandIconPosition={'end'}
        >
          {/* <Panel header="This is panel header 1" key="1">
          <div>{'text'}</div>
        </Panel> */}
          {bundles &&
            bundles.length > 0 &&
            bundles.map((item, index) => {
              const { ServiceCodeBundleName, Goal, CountInfo } = item;
              return (
                <Panel
                  // header={`Bundle ${index + 1}`}
                  className="bundle-panel-border mb-3"
                  header={
                    <>
                      <div className="row">
                        <div className="col-12 panel-header-text">{CountInfo && CountInfo}</div>
                      </div>
                    </>
                  }
                  key={`${ServiceCodeBundleName}-${index + 1}`}
                >
                  <p className="mb-0 my-3" style={{ color: '#182838', fontSize: '16px' }}>
                    {ServiceCodeBundleName}
                  </p>
                  <GoalCollapse goals={Goal} keyIndex={index} />
                </Panel>
              );
            })}
        </Collapse>
      )}
    </div>
  );
}
