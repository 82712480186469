import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPromoCode, deleteCouponCode, resetDeleteCouponCode } from '../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import PropTypes from 'prop-types';
import Loader, { Loader2 } from '../Components/Loader';
import CustomTable from '../component/table';
// import CustomTable from '../component/custom-components/customTable';
import ExportExcel from '../Components/ExportExcel';
import PersistTableSort from '../component/persistTableSort';
import AntdModal from '../Components/CustomModal';
import { Tooltip } from 'antd';
import PageTitle from '../Components/PageTitle';
class PromoCode extends Component {
  constructor() {
    super();
    this.state = {
      isFetching: false,
      isHTMLLoaded: false,
      html: '',
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // const { ReportType, ReportId } = this.props;
    // let data = {
    //   ReportType: ReportType,
    //   Json: JSON.stringify({ Report: [{ ReportId: ReportId }] }),
    // };
    this.props.dispatch(getPromoCode({}));
  }
  onRowClick = (event, record, rowIndex) => {
    const { history } = this.props;
    history.push('/manage/coupon-form?couponId=' + record.CouponId);
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentWillReceiveProps({ isRemoved }) {
    if (isRemoved && isRemoved != this.props.isRemoved) {
      //  this.toggle(Delete_Sucess_Msg);
      this.props.dispatch(resetDeleteCouponCode());
      this.props.dispatch(getPromoCode({}));
    }
  }
  render() {
    const { promoCodeData, isFetching } = this.props;
    // const promoCodetArr = Object.values(promoCodeData);
    // const promoCodeName = Object.keys(promoCodeData);
    const columns = [
      {
        title: 'Center Id',
        dataIndex: 'CenterId',
        key: 'CenterId',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isNumericComparer(a.CenterId, b.CenterId),
      },
      {
        title: 'Center Name',
        dataIndex: 'CenterName',
        key: 'CenterName',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
      },
      {
        title: 'Advisor',
        dataIndex: 'Advisor',
        key: 'Advisor',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isComparer(a.Advisor, b.Advisor),
      },
      {
        title: 'Promo Code',
        dataIndex: 'CouponCode',
        key: 'CouponCode',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isComparer(a.CouponCode, b.CouponCode),
      },
      {
        title: 'Subscription Type',
        dataIndex: 'SubscriptionType',
        key: 'SubscriptionType',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isComparer(a.SubscriptionType, b.SubscriptionType),
      },
      {
        title: 'Start Date',
        dataIndex: 'StartDate',
        key: 'StartDate',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
      },
      {
        title: 'End Date',
        dataIndex: 'EndDate',
        key: 'EndDate',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isDateComparer(a.EndDateForSort, b.EndDateForSort),
      },
      {
        title: 'Alumni / Free',
        dataIndex: 'AlumniFree',
        key: 'AlumniFree',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isComparer(a.AlumniFree, b.AlumniFree),
      },
      {
        title: 'Used Count',
        dataIndex: 'UsedCount',
        key: 'UsedCount',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => isNumericComparer(a.UsedCount, b.UsedCount),
      },
      // {
      //   title: '',
      //   key: 'Manage',
      //   dataIndex: 'Manage',
      //   render: () => <button class="btn">Manage Staff</button>,
      //   onCell: (record, rowIndex) => {
      //     return {
      //       onClick: event => this.onManageClick(record.CenterId),
      //     };
      //   },
      // },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="d-flex align-items-center justify-content-between">
              <Tooltip placement="top" title="Edit">
                <span
                  className="start-btn"
                  onClick={() => {
                    this.props.history.push('/manage/coupon-form?couponId=' + record.CouponId);
                  }}
                >
                  <i class="far fa-edit" style={{ cursor: 'pointer' }}></i>
                </span>
              </Tooltip>
              {/* <img src="images/shape.svg" width="14px" style={{ cursor: 'pointer' }} /> */}
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-coupon"
                    style={{ cursor: 'pointer' }}
                  >
                    <Tooltip placement="top" title="Delete">
                      <p>
                        <i class="far fa-trash-alt"></i>
                      </p>
                    </Tooltip>
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to delete this Promo Code?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            deleteCouponCode({
                              Json: JSON.stringify({
                                Coupon: [
                                  {
                                    CouponId: record.CouponId,
                                  },
                                ],
                              }),
                            }),
                          );
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];

    return (
      <div class="container-fluid">
        <PageTitle TitleText="Promo Codes" />
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a class="back-link" onClick={() => this.props.history.push('/manage')}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0" style={{ textTransform: 'initial' }}>
                Promo Code(s)
              </h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <Link to="/manage/coupon-form" class="btn black-btn" color="blue">
                Add New Promo Code
              </Link>
            </div>
          </div>
          <div className="row">
            <div class="col-12 mb-5">
              <div class="card border-0 promo_codes">
                <div className="row card-body" style={{ minHeight: '300px' }}>
                  <div class="col-12 ">
                    <Loader2 loading={isFetching} />
                    <PersistTableSort
                      name="PromoCodeList"
                      columns={columns}
                      dataSource={promoCodeData}
                      size={'middle'}
                      // onRow={(record, rowIndex) => {
                      //   return {
                      //     onClick: event => this.onRowClick(event, record, rowIndex),
                      //   };
                      // }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                      }}
                    />
                  </div>

                  {/* {promoCodetArr && promoCodetArr[0] != null ? (
                    promoCodetArr.map((arrData, index) => {
                      return (
                        <div className="col-12 px-2">
                          <CustomTable
                            heading={
                              // <h6>{promoCodeName[index]}</h6>
                              <div className="d-flex align-items-center">
                                <h6 className="flex-grow-1">{promoCodeName[index]}</h6>
                                <ExportExcel reportData={promoCodeData} />
                              </div>
                            }
                            rowData={arrData}
                            // pagination={false}
                            // scroll={900}
                            // size={'small'}
                            bordered={true}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div class="dataTables_wrapper no-footer">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a class="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a class="paginate_button next">Next</a>
                                      ) : (
                                        <a class="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              showSizeChanger: true,
                              // hideOnSinglePage: true,
                            }}
                          />

                          <br />
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-12">
                      <h6 className="text-center">PromoCode Record Empty</h6>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToPropsTable = ({ get_promo_code, delete_coupon_code }) => {
  var { data, isFetching, error } = get_promo_code;
  let promoCodeData = [];
  let isRemoved = false;
  if (data) {
    promoCodeData = Object.values(data)[0];
  }
  if (delete_coupon_code && delete_coupon_code.data && delete_coupon_code.data.success) {
    isRemoved = true;
  }
  return {
    isFetching: isFetching,
    promoCodeData,
    isRemoved,
  };
};

export default connect(mapStateToPropsTable)(withRouter(PromoCode));
