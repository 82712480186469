import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxCheckbox,
  ReduxSelect,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  listFeeSchedule,
  getFeeSchedule,
  upsertFeeSchedule,
  resetUpsertFeeSchedule,
  getComboFacility,
} from '../../../../store/actions';
import { ProcessCombos } from '../../../../store/comboConfig';

import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import Loader, { Loader2 } from '../../../../Components/Loader';
import PersistTableSort from '../../../../component/persistTableSort';
import { Tooltip } from 'antd';
import SubmitButton from '../../../../Components/submitButton';

import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import { Table, Modal } from 'antd';

class FeeSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FeeScheduleList: [],
      PayersList: [],
      feeScheduleinfo: {},
      createAndEditFeeModal: false,
      searchStr: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(listFeeSchedule({}));
    this.props.dispatch(getComboFacility({}));
    var result = ProcessCombos('UnitOfMeasurement');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ isInserted, feeScheduleList, feeScheduleinfo, Payers }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditFeeModal: false });
      this.toggle('Fee Schedule Saved Successfully');
      this.props.dispatch(resetUpsertFeeSchedule());
      this.props.dispatch(reset('feeScheduleForm'));
      this.setState({
        feeScheduleinfo: {},
      });
    }
    if (
      feeScheduleList &&
      JSON.stringify(feeScheduleList) != JSON.stringify(this.props.feeScheduleList)
    ) {
      this.setState({
        FeeScheduleList: feeScheduleList,
      });
    }
    if (Payers && JSON.stringify(Payers) != JSON.stringify(this.props.Payers)) {
      this.setState({
        PayersList: Payers,
      });
    }
    if (
      feeScheduleinfo &&
      JSON.stringify(feeScheduleinfo) != JSON.stringify(this.props.feeScheduleinfo)
    ) {
      this.setState({
        feeScheduleinfo: feeScheduleinfo,
      });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const { BillingRate, ReimbursementRate, IsNoReimbursement, UnitOfMeasurementId } = values;
    const data = {
      FeeSchedule: [
        {
          PayerId: this.state.feeScheduleinfo.PayerId || null,
          ServiceCodeId: this.state.feeScheduleinfo.ServiceCodeId,
          BillingRate: BillingRate,
          ReimbursementRate: ReimbursementRate,
          IsNoReimbursement: IsNoReimbursement,
          UnitOfMeasurementId: UnitOfMeasurementId,
        },
      ],
    };
    let PayerData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(upsertFeeSchedule(PayerData));
  }

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };
  refreshGrid = () => {
    const { searchStr } = this.state;
    this.setState({
      searchStr,
    });
    this.props.dispatch(
      listFeeSchedule({
        json: JSON.stringify({
          FeeSchedule: [
            {
              Search: searchStr,
            },
          ],
        }),
      }),
    );
    sessionStorage.setItem(
      'appliedFilter',
      JSON.stringify({
        // Filter: filterType && filterType.toString(),
        searchStr: searchStr,
        // startDate,
        // endDate,
        // filterCenterId,
        // FilterCounserlorId,
      }),
    );
  };
  storeSelectFilter = val => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        selectFilter: val,
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };
  render() {
    const tempColumns = [
      {
        title: 'Payer',
        dataIndex: 'PayerName',
        key: 'PayerName',
        sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Description',
        dataIndex: 'ServiceCodeDescription',
        key: 'ServiceCodeDescription',
        sorter: (a, b) => isComparer(a.ServiceCodeDescription, b.ServiceCodeDescription),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Unit of Measure',
      //   dataIndex: 'FacilityName',
      //   key: 'FacilityName',
      //   sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Billing Rate',
        dataIndex: 'BillingRate',
        key: 'BillingRate',
        sorter: (a, b) => isComparer(a.BillingRate, b.BillingRate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Reimbursement Rate',
        dataIndex: 'ReimbursementRate',
        key: 'ReimbursementRate',
        sorter: (a, b) => isComparer(a.ReimbursementRate, b.ReimbursementRate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Unit of Measure',
        dataIndex: 'UnitOfMeasurementDescription',
        key: 'UnitOfMeasurementDescription',
        sorter: (a, b) =>
          isComparer(a.UnitOfMeasurementDescription, b.UnitOfMeasurementDescription),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <>
                {' '}
                <div class="tools-grid">
                  <div className="mr-4">
                    <span className="start-btn">
                      <Tooltip placement="top" title="Edit">
                        <img
                          src="images/shape.svg"
                          width="14px"
                          style={{ cursor: 'pointer' }}
                          onClick={event => {
                            this.setState({
                              createAndEditFeeModal: true,
                              // feeScheduleData: record,
                            });

                            this.props.dispatch(
                              getFeeSchedule({
                                json: JSON.stringify({
                                  FeeSchedule: [
                                    {
                                      PayerId: record.PayerId,
                                      ServiceCodeId: record.ServiceCodeId,
                                    },
                                  ],
                                }),
                              }),
                            );
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                  {/* <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                  >
                    <i class="far fa-trash-alt"></i>
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to delete this service?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          onOk();
                          this.props.dispatch(
                            deletePayer({
                              json: JSON.stringify({
                                Payer: [
                                  {
                                    PayerId: record.PayerId,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div> */}
                </div>
              </>
            );
          } else {
            return null;
          }
        },
      },
    
    ];
    const { FeeScheduleList, PayersList } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid mt-5">
          <div className="">
            <div className="row px-0 mt-5 d-flex align-items-center">
              <div class="col-4 px-0 align-self-baseline">
                {' '}
                <h4 className="">FEE SCHEDULE</h4>
              </div>
              <div class="col-3">
                <div class="form-group call-schedule">
                  <ReduxSelect
                    options={PayersList}
                    onChange={val => {
                      this.storeSelectFilter(val);
                      this.props.dispatch(
                        listFeeSchedule({
                          Json: JSON.stringify({
                            FeeSchedule: [
                              {
                                FilterBy: val,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        filterBy: val,
                      });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={this.state.filterBy}
                    placeholder="Filter by Payer"
                  />
                </div>
              </div>
              <div class="col-5">
                <div class="search-box d-inline-flex w-100">
                  <div class="form-group has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row card-body px-2 rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name="fee-table"
                    // bordered
                    columns={tempColumns}
                    dataSource={FeeScheduleList}
                    size={'middle'}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(listFeeSchedule({}));
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />

        <Modal
          zIndex={100}
          visible={this.state.createAndEditFeeModal}
          footer={null}
          closable={false}
          width={700}
        >
          <ReduxFeeScheduleForm
            feeScheduleData={this.props.feeScheduleinfo}
            onSaveClick={this.onSubmit.bind(this)}
            initialValues={this.props.feeScheduleinfo}
            isFetchingFeeSchedule={this.props.isFetchingFeeSchedule}
            isFetchingUpsertFee={this.props.isFetchingUpsertFee}
            // onCancel={this.props.onCancel}
            Delete={() => {
              this.Removetoggle();
            }}
            onCancel={() => {
              this.setState({
                createAndEditFeeModal: false,
              });
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const FeeScheduleForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    disable,
    onCancel,
    feeScheduleData,
    isFetchingFeeSchedule,
    isFetchingUpsertFee,
    isNoReimbursementValue,
    UnitOfMeasurement,
  } = props;
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      {/* <div class="modal-header flex-wrap border-bottom-0 "></div> */}
      <div class="modal-body rcm-panel enrollment-form py-0">
        <Loader2 loading={isFetchingFeeSchedule || isFetchingUpsertFee} />
        <div className="row">
          <div className="col-6" style={{ background: '#F8F8F8' }}>
            <div
              className="edit-fee-schedule"
              style={{ paddingTop: '30px', paddingBottom: '30px' }}
            >
              <h4 class="title col-12 text-left">EDIT FEE SCHEDULE</h4>
              <div className="fee-schedule">
                <div className="block">
                  <span className="label">{'Payer'}</span>
                  <p className="value"> {feeScheduleData.PayerName}</p>
                </div>
                <div className="block">
                  <span className="label">{'Code'}</span>
                  <p className="value"> {feeScheduleData.ServiceCode}</p>
                </div>
              </div>
              <div className="block px-3">
                <span className="label">{'Description'}</span>
                <p className="description">{feeScheduleData.ServiceCodeDescription}</p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="" style={{ paddingTop: '40px', paddingBottom: '30px' }}>
              <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                ×
              </button>
              <div class="row my-4">
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`BillingRate`}
                      component={ReduxInput}
                      validate={!isNoReimbursementValue && validate.required}
                      fieldName={'Billing Rate'}
                      disabled={isNoReimbursementValue}
                    />
                    <label class="placeholder-label">Billing Rate</label>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`ReimbursementRate`}
                      type="text"
                      component={ReduxInput}
                      // validate={validate.required}
                      fieldName={'Reimbursment Rate'}
                      disabled={isNoReimbursementValue}
                    />
                    <label class="placeholder-label">Reimbursment Rate</label>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group mb-2">
                    <Field
                      name="UnitOfMeasurementId"
                      type="text"
                      component={ReduxSelect}
                      validate={!isNoReimbursementValue && validate.required}
                      fieldName={'Unit of Measurement'}
                      placeholder={'Unit of Measure'}
                      options={UnitOfMeasurement}
                      disabled={isNoReimbursementValue}
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <Field
                      name={`IsNoReimbursement`}
                      type="text"
                      component={ReduxCheckbox}
                      fieldName={'No reimbursement'}
                      label={'No reimbursement'}
                      // disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 mt-2">
                  {/* <input type="submit" class="btn px-5" value={'Update Changes'} /> */}
                  <SubmitButton className="px-5" style={{height: '45px'}} value={'Update Changes'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
const mapStateToProps = state => {
  const { combos } = state;
  let UnitOfMeasurement = [];
  if (combos.data && combos.data['UnitOfMeasurement']) {
    UnitOfMeasurement = combos.data['UnitOfMeasurement'] || [];
  }
  return {
    UnitOfMeasurement,
    isNoReimbursementValue: selector(state, 'IsNoReimbursement'),
  };
};
const ReduxFeeScheduleForm = reduxForm({
  form: 'feeScheduleForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(FeeScheduleForm)));
const selector = formValueSelector('feeScheduleForm');

const mapStateToPropsParent = ({ upsert_fee_schedule, list_fee_schedule, get_fee_schedule }) => {
  const { data: feeListData, isFetching } = list_fee_schedule;
  const { data: feeScheduleData, isFetching: isFetchingFeeSchedule } = get_fee_schedule;
  const { data: upsertFeeScheduleData, isFetching: isFetchingUpsertFee } = upsert_fee_schedule;
  let isInserted = false;
  let feeScheduleList = [];
  let Payers = [];
  let feeScheduleinfo = {};
  if (upsertFeeScheduleData && upsertFeeScheduleData.success) {
    isInserted = true;
  }

  if (feeListData && feeListData.FeeSchedule) {
    feeScheduleList = feeListData.FeeSchedule || [];
  }
  if (feeListData && feeListData.Payers) {
    Payers = feeListData.Payers || [];
  }
  if (feeScheduleData && feeScheduleData.FeeSchedule) {
    feeScheduleinfo = feeScheduleData.FeeSchedule[0];
  }

  return {
    isInserted,
    feeScheduleList: feeScheduleList,
    Payers: Payers,
    isFetching: isFetching,
    feeScheduleinfo: feeScheduleinfo,
    isFetchingFeeSchedule: isFetchingFeeSchedule,
    isFetchingUpsertFee: isFetchingUpsertFee,
  };
};
export default connect(mapStateToPropsParent)(FeeSchedule);
