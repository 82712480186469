import React, { Component, useState } from 'react';
import { Tabs, Button, Drawer, Tooltip, Checkbox } from 'antd';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import Loader, { Loader2 } from '../../../Components/Loader';
import PersistTableSort from '../../../component/persistTableSort';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
  CheckboxGroup,
  ReduxTextarea,
  ReduxTextareaWithCounter,
  CheckboxGroupTest4,
} from '../../../component/custom-redux-components';
import validate from '../../../Screens/enrollmentForm/validation/validate';
import { listCombos } from '../../../store/actions';

class SupportGroupMemberForm extends Component {
  constructor() {
    super();
    this.state = {
      selectedMembers: [],
      relation: {},
    };
  }
  handleChange(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      let newMember = e.target.value && JSON.parse(e.target.value);
      this.setState({ selectedMembers: [...this.state.selectedMembers, newMember] });
    } else {
      let remainingUsers =
        this.state.selectedMembers &&
        this.state.selectedMembers.filter(
          item => item.UserGuid != JSON.parse(e.target.value).UserGuid,
        );
      this.setState({ selectedMembers: [...remainingUsers] });
    }
  }

  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'RelationShip,SupportGroup',
        json: JSON.stringify({ User: { UserGuid: this.props.UserGuid } }),
      }),
    );
  }

  handleRelationChange = (value, UserGuid) => {
    this.setState({
      relation: {
        ...this.state.relation,
        [UserGuid]: value,
      },
    });
    // console.log({
    //   relation: {
    //     ...this.state.relation,
    //     [UserGuid]: value,
    //   },
    // });
  };

  onSearchChange = control => {
    var value = control.target.value;
    this.state.searchStr = value;
    if (value.length > 1) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'RelationShip,SupportGroup',
          json: JSON.stringify({
            User: { UserGuid: this.props.UserGuid, SearchStr: this.state.searchStr },
          }),
        }),
      );
    } else if (value.length == 0) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'RelationShip,SupportGroup',
          json: JSON.stringify({ User: { UserGuid: this.props.UserGuid, SearchStr: null } }),
        }),
      );
    }
  };

  render() {
    const {
      handleSubmit,
      onhandleSubmit,
      relationOptions,
      supportGroup,
      memberType,
      handleExistingMember,
    } = this.props;
    return (
      <div className="container">
        <form onSubmit={handleSubmit(onhandleSubmit)}>
          {' '}
          <div class="row mb-4 enrollment-form">
            <div
              class="col-12 col-md-12 col-lg-12"
              // className={
              //   this.props.existingPaitent ? 'col-6 col-md-4 col-lg-4' : 'col-6 col-md-12 col-lg-12'
              // }
            >
              <div class="form-group custom-radio ">
                <label className="mr-5">
                  Add New Member
                  <Field
                    name="memberType"
                    component={ReduxInput}
                    type="radio"
                    value="ANM"
                    style={{
                      width: '20px',
                      height: '20px',
                      border: 'solid 1px #bcbcbc',
                      marginRight: '5px',
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label>
                  Invite New Member
                  <Field
                    name="memberType"
                    component={ReduxInput}
                    type="radio"
                    value="INM"
                    style={{
                      width: '20px',
                      height: '20px',
                      border: 'solid 1px #bcbcbc',
                      marginRight: '5px',
                    }}
                  />
                  <span className="checkmark"></span>
                </label>

                {/* <label class="control-label">Add New Member</label>
                <br />
                <Field
                  name="AddNewMember"
                  component={ReduxCheckbox}
                  // onChange={bool => toogleChange(bool, index)}
                /> */}
              </div>
            </div>
            {memberType == 'ANM' && (
              <>
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`FirstName`}
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'First Name'}
                    />
                    <label class="placeholder-label">First Name*</label>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`LastName`}
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Last Name'}
                    />
                    <label class="placeholder-label">Last Name*</label>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`Phone`}
                      component={ReduxPhoneInput}
                      validate={validate.required}
                      fieldName={'Phone'}
                    />
                    <label class="placeholder-label" style={{ top: '-5px', fontSize: '14px' }}>
                      Phone*
                    </label>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`Email`}
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Email'}
                    />
                    <label class="placeholder-label">Email*</label>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`Address`}
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Address'}
                    />
                    <label class="placeholder-label">
                      Permanent Address Line (Last Known Address)*
                    </label>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`ZIP`}
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'ZIP'}
                    />
                    <label class="placeholder-label">ZIP Code*</label>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={'Relation'}
                      type="text"
                      component={ReduxSelect}
                      options={relationOptions}
                      placeholder="Relation to the Client*"
                      validate={validate.required}
                      fieldName={'Relation to the Client'}
                      // disabled={disable}
                    />
                    {/* <label class="placeholder-label">Relation to the Client</label> */}
                  </div>
                </div>

                <div class="col-12 col-md-4 col-lg-4">
                  <input type="submit" class="btn px-5" value={'Submit'} />
                </div>
              </>
            )}

            {memberType != 'ANM' && (
              <>
                <div className="px-4 w-100">
                  <div class="search-box w-100">
                    <div class="form-group has-search w-75">
                      <input
                        type="text"
                        class="form-control shadow-sm"
                        placeholder="Search"
                        onChange={this.onSearchChange}
                      />
                      <button class="btn search-icon " type="button">
                        {' '}
                        <i class="fa fa-search" />{' '}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row top-table-search  mb-4 align-items-center">
                  <div class="col-12  text-right d-flex justify-content-between align-items-center"></div>
                </div>
                {supportGroup && supportGroup.length > 0 ? (
                  <div
                    style={{
                      height: 'auto',
                      overflow: 'auto',
                      width: '100%',
                      paddingBottom: '139px',
                    }}
                  >
                    {supportGroup &&
                      supportGroup.map((item, index) => {
                        const { ProfileImageUrl, DisplayName, UserGuid, Phone } = item;
                        return (
                          <>
                            <div className="d-flex justify-content-around mt-3 mb-3">
                              <div
                                className=" mt-2 mb-2 d-flex align-items-center"
                                style={{ background: '#FFF', width: '250px' }}
                              >
                                {' '}
                                <Checkbox
                                  type="checkbox"
                                  id="user"
                                  name={DisplayName}
                                  value={JSON.stringify({ UserGuid })}
                                  disabled={
                                    Object.keys(this.state.relation).includes(UserGuid)
                                      ? false
                                      : true
                                  }
                                  onChange={e => {
                                    this.handleChange(e);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                                {/* <input
                                type="checkbox"
                                id="user"
                                name={DisplayName}
                                value={JSON.stringify({ UserGuid })}
                                disabled={
                                  Object.keys(this.state.relation).includes(UserGuid) ? false : true
                                }
                                onChange={e => {
                                  this.handleChange(e);
                                }}
                                style={{ cursor: 'pointer' }}
                              />{' '} */}
                                &nbsp;&nbsp;&nbsp;
                                <img
                                  className="autoSelectImage"
                                  src={
                                    ProfileImageUrl ? ProfileImageUrl : 'images/blank-profile.png'
                                  }
                                  style={{ marginLeft: '5px' }}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <div>
                                  <span className="d-flex flex-column"> {DisplayName}</span>
                                  <span> {Phone}</span>
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <br />
                              </div>
                              <div style={{ width: '150px' }}>
                                <ReduxSelect
                                  options={relationOptions}
                                  onChange={value => {
                                    this.handleRelationChange(value, UserGuid);
                                  }}
                                  className="basic-single w-50"
                                  classNamePrefix="select"
                                  value={this.state.relation && this.state.relation[UserGuid]}
                                  placeholder="Relation"
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                ) : (
                  <div className="col-12 col-md-12 col-lg-12 mt-12" style={{ color: 'grey' }}>
                    Members not available for selection.
                  </div>
                )}

                {supportGroup && supportGroup.length > 0 && (
                  <div class="col-12 col-md-12 col-lg-12 mt-3">
                    <input
                      type="button"
                      class="btn px-5"
                      value={'Invite & Add Member'}
                      disabled={this.state.selectedMembers.length > 0 ? false : true}
                      onClick={() => {
                        handleExistingMember(this.state.selectedMembers, this.state.relation);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { app_invite_dashboard } = state;
  const { data: inviteData } = app_invite_dashboard;

  let isSuccess = false;

  if (app_invite_dashboard.data && app_invite_dashboard.data.Success) {
    isSuccess = inviteData.Success;
  }
  return {
    isSuccess,
  };
};

const ReduxSupportGroupMemberForm = reduxForm({
  form: 'SupportGroupMemberForm',
})(connect(mapStateToProps)(SupportGroupMemberForm));

export default ReduxSupportGroupMemberForm;
