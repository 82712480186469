import moment from 'moment';

export const getDate = value => {
  var dt = new Date(value);
  return dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);

  //return new Date(dt);
};

export const getLocalDate = value => {
  var dt = new Date(value);
  return dt;
};

export const stringDate = value => {
  // if (typeof value === 'string') {
  //   return value;
  // } else {
  //   return value && moment(value).format('MM/DD/YYYY');
  // }
  return value === 'Invalid date' ? null : value ? moment(value).format('MM/DD/YYYY') : null;
};
