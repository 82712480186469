import React, { useState } from 'react';
import { Drawer, Tooltip } from 'antd';
import AugmentWithAIForCourse from './AugmentWithAIForCourse';

export default function AugmentWithAI(props) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const onHandleDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <button
          // title="Augment with AI"
          class="ai-btn"
          onClick={() => setOpenDrawer(true)}
          style={{
            display: 'inline',
            padding: '5px 20px',
          }}
        >
          <figure class="action-button-icon" style={{ display: 'inline-block' }}>
            <img src="images/AIStar.svg" alt="" width="23" />
          </figure>
          Augment with AI
        </button>

        <Tooltip
          placement="topRight"
          title={'The summary augmentation will be generated using the most recent 20 records.'}
        >
          <img src="images/TooltipIcon.svg" className="ml-3" />
        </Tooltip>

        <Drawer
          className="custom-drawer journal-augmenting"
          title="Augment with AI"
          placement="right"
          open={openDrawer}
          width={800}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          destroyOnClose={true}
          onClose={() => setOpenDrawer(false)}
        >
          <AugmentWithAIForCourse
            isEnterprise={props.isEnterprise}
            userGuid={props.UserGuid}
            studentId={props.studentId}
            onHandleDrawer={onHandleDrawer}
          />
        </Drawer>
      </div>
    </>
  );
}
