import React, { Component } from 'react';
import { Radio } from 'antd';
const ReduxRadioGroup = ({
  type,
  name,
  input,
  options,
  defaultValue,
  onChange,
  value,
  disabled,
}) => (
  <div>
    <Radio.Group
      name="radiogroup"
      onChange={onChange}
      value={input.value}
      defaultValue={defaultValue}
      disabled={disabled}
    >
      {options.map((option, index) => (
        <Radio name={input.name} value={option} onChange={input.onChange}>
          {option}
        </Radio>
      ))}
    </Radio.Group>
  </div>
);

export default ReduxRadioGroup;

// Reference
// https://codesandbox.io/s/EkggLy6m?file=/MyForm.js:615-661
// defaultValue,defaultChecked right now not working properlly.
// we can use field array.push({key:"radio text"}) to initialize values as default and set.
// Example to use
//  <div className="col-12">
//    <div class="form-group checkbox-to-radio">
//      <Field
//        name={`${item}MaterialDeliveryOption`}
//        component={ReduxRadio}
//        options={['Sell Material', 'Provide Service']}
//        defaultValue="Sell Material"
//        //  className="service-code"
//      />
//    </div>
//  </div>;