import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Modal, Popover, Drawer, Tooltip } from 'antd';
import Axios from 'axios';
import AntdModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import Summary from './dashboard/preintakeProfile';
import ViewAnswerTab from './dashboard/ViewAnswerTab';
import Associations from './dashboard/Associations';
// import IntakeSurveys from './preintakeProfile';
import Banner from '../Banner';
import AssignSurveysModal from '../AssignSurveysModal';
import SetReminderModal from './SetReminderModal';
import ProspectTimeTravel from './dashboard/ProspectTimeTravel';
import ProgramMatch from './dashboard/ProgramMatch';
import DocumentUpload from './dashboard/DocumentUpload';
import Appointments from './dashboard/Appointments';
import Notes from './dashboard/Notes';
import {
  prospectArchive,
  resetProspectArchive,
  studentBanner,
  studentQueueDelete,
  upsertAppointmentReset,
} from '../../../store/actions';
import AddProspectNote from './AddNoteModal';
import StopwatchTimerAction from './StopwatchTimerAction';
// import StopwatchTimer from './StopwatchTimer';
import CallEndedForm from './dashboard/AppointmentsScreens/CallEnded';
import CloseIcon from '../../../Components/CloseIcon';

const { TabPane } = Tabs;

class PreIntakeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabMapping: {
        0: 'summary',
        1: 'screening',
        2: 'associations',
        3: 'time-travel',
        4: 'document-upload',
        5: 'notes',
        6: 'appointment',
        7: 'program-match',
      },
      tabLabels: [
        {
          Name: 'Summary',
          Component: props => <Summary {...props} viewHistory={this.viewHistory} />,
        },
        {
          Name: 'Screening',
          Component: props => <ViewAnswerTab {...props} viewHistory={this.viewHistory} />,
        },
        {
          Name: 'Associations',
          Component: props => <Associations {...props} />,
        },
        {
          Name: 'Time Travel',
          Component: props => <ProspectTimeTravel {...props} />,
        },
        {
          Name: 'Document Upload',
          Component: props => <DocumentUpload {...props} />,
        },
        {
          Name: 'Notes',
          Component: props => <Notes {...props} />,
        },
        {
          Name: 'Appointments',
          Component: props => <Appointments {...props} />,
        },
        {
          Name: 'Program Match',
          Component: props => <ProgramMatch {...props} />,
        },
        // {
        //   Name: 'Surveys',
        //   Component: props => <IntakeSurveys {...props} />,
        // },
      ],
      activeKey: 0,
      activeTabs: [true, true],
      isNoteAddModal: false,
      isModalOpen: false,
      isCallEndedDrawer: false,
    };
  }
  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }
  viewHistory = (key, filterVal, filterText) => {
    // history.push(`/timetravel/?subscriberId=${UserGuid}&TTFilter=25&TTItem=0_Suspicious%Terms`);
    if (this.getIntakeId()) {
      let keyData = parseInt(key);
      let clickedTab = this.state.tabMapping[keyData];
      this.props.history.push(
        `/clients/pre-intake/dashboard?tab=${clickedTab}&id=${this.getIntakeId()}&TTFilter=${filterVal}&TTItem=${filterText}`,
      );
      this.setState({
        activeKey: parseInt(key),
      });
    } else {
      return;
    }
  };
  onTabClick = key => {
    if (this.getIntakeId()) {
      let keyData = parseInt(key);
      let clickedTab = this.state.tabMapping[keyData];
      this.props.history.push(
        `/clients/pre-intake/dashboard?tab=${clickedTab}&id=${this.getIntakeId()}`,
      );
      this.setState({
        activeKey: parseInt(key),
      });
    } else {
      return;
    }
  };
  backButtonImplimentation = nextProps => {
    let upComingTab = new URLSearchParams(nextProps.history.location.search);
    upComingTab = upComingTab.get('tab');
    let upComingTabIndex = Object.values(this.state.tabMapping).indexOf(upComingTab);
    let pastTabs = new URLSearchParams(this.props.location.search);
    pastTabs = pastTabs.get('tab');
    let pastTabsIndex = Object.values(this.state.tabMapping).indexOf(pastTabs);
    if (upComingTabIndex != pastTabsIndex) {
      this.setState({
        activeKey: upComingTabIndex,
      });
    }
  };
  componentDidMount(props) {
    window.scrollTo(0, 0);
    const { tabMapping } = this.state;
    var params = new URLSearchParams(this.props.location.search);
    var tab = params.get('tab');
    if (tabMapping) {
      this.setState({
        activeKey: Object.values(tabMapping).indexOf(tab),
      });
    }
    // if (this.props.hasAdminAccess) {
    //   let tabsWithProgramMatch = [
    //     ...this.state.tabLabels,
    //     {
    //       Name: 'Program Match',
    //       Component: props => <ProgramMatch {...props} />,
    //     },
    //   ];
    //   this.setState({
    //     tabLabels: tabsWithProgramMatch,
    //   });
    // }
    this.props.dispatch(
      studentBanner({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.getIntakeId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({
    isArchiveSuccess,
    archiveSuccessMsg,
    hasAdminAccess,
    isQueueDeleted,
    isAppointmentBooked,
  }) {
    // this.backButtonImplimentation(nextProps);
    if (isArchiveSuccess && isArchiveSuccess != this.props.isArchiveSuccess) {
      this.toggleSuccess(archiveSuccessMsg);
      this.props.dispatch(
        studentBanner({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: this.getIntakeId(),
              },
            ],
          }),
        }),
      );
      this.props.dispatch(resetProspectArchive());
      // this.props.history.push(`/clients/pre-intake`);   // based on requirement keeping on same page
    }
    if (isQueueDeleted && isQueueDeleted != this.props.isQueueDeleted) {
      this.toggleSuccess('Client moved from Queue to Prospect Successfully.');
      this.props.history.push('/clients/queue');
      // this.props.dispatch(
      //   studentBanner({
      //     Json: JSON.stringify({
      //       Student: [
      //         {
      //           StudentId: this.getIntakeId(),
      //         },
      //       ],
      //     }),
      //   }),
      // );
    }
    // if (hasAdminAccess && hasAdminAccess != this.props.hasAdminAccess) {
    //   let hasTab = this.state.tabLabels.filter(tab => tab.Name === 'Program Match');
    //   if (hasTab.length > 0) return;
    //   let tabsWithProgramMatch = [
    //     ...this.state.tabLabels,
    //     {
    //       Name: 'Program Match',
    //       Component: props => <ProgramMatch {...props} />,
    //     },
    //   ];
    //   this.setState({
    //     tabLabels: tabsWithProgramMatch,
    //   });
    // }
    if (isAppointmentBooked && isAppointmentBooked != this.props.isAppointmentBooked) {
      // this.props.dispatch(upsertAppointmentReset());
      this.setState({
        isCallEndedDrawer: false,
        CallSid: null,
        twilioData: null,
      });
    }
  }
  moveToIntake = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            EnrollStatusId: 0,
          },
        ],
      }),
    };
    Axios.post('Student/StudentEnrollStatusUpdate', data)
      .then(response => {
        if (response.data.success) {
          this.props.history.replace(`/enrollment/?studentid=${this.getIntakeId()}`);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  toggle = (header, isSuccess) => {
    this.setState(prevState => ({
      isSetReminderModal: !prevState.isSetReminderModal,
      header,
      isSetReminderSuccess: isSuccess,
    }));
  };
  toggleSuccess = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  toggleDarwer = () => {
    this.setState({
      isCallEndedDrawer: false,
      CallSid: null,
      twilioData: null,
    });
  };
  getCallEndDetails = (isRecored, callDetails, twilioData) => {
    let SID = callDetails.parameters.CallSid;
    this.setState({
      isCallEndedDrawer: isRecored ? true : false,
      CallSid: SID,
      twilioData,
    });
  };
  render() {
    const { activeKey, tabLabels, isNoteAddModal, isSetReminderModal } = this.state;
    const {
      PreItakeSurveyId,
      IsPreIntakeArchived,
      BannerData,
      CurrentClientId,
      IsthisPeerRecoveryClient,
    } = this.props;
    const content = (
      <div
        className="sort-by-list"
        onClick={() => {
          this.setState({
            isVisible: false,
          });
        }}
      >
        <p
          onClick={() => {
            this.props.history.push(
              `/pre-intake-form?step=demographics&studentid=${this.getIntakeId()}`,
            );
          }}
        >
          Edit Details
        </p>
        {/* <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#move-intake-model">
              Move to Intake
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">
                  Are you sure you want to move to Intake?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    onOk();
                    this.moveToIntake();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        /> */}
        <p
          onClick={() => {
            this.setState({
              isNoteAddModal: true,
            });
          }}
        >
          Add Note
        </p>
        <p
          onClick={() => {
            this.setState({
              isSetReminderModal: true,
            });
          }}
        >
          Set Reminder
        </p>

        <AntdModal
          ModalButton={({ onClick }) => (
            <p class="" onClick={onClick}>
              {IsPreIntakeArchived ? 'Restore' : 'Archive'}
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                  &times;
                </button>
                <h4 className="modal-title col-12">
                  Are you sure you want to {IsPreIntakeArchived ? 'restore' : 'archive'} this
                  prospect?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  type="button"
                  className="btn btn-outline-primary py-1"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn py-1"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      prospectArchive({
                        Json: JSON.stringify({
                          Student: [
                            {
                              StudentId: this.getIntakeId(),
                              Archive: !IsPreIntakeArchived,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
        {this.props.EnrollStatusId === 4 && (
          <AntdModal
            ModalButton={({ onClick }) => (
              <p style={{ color: '#FF3B3B' }} onClick={onClick} data-target="#move-intake-model">
                Remove from Queue
              </p>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div className="modal-content border-0">
                <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                  <h4 className="modal-title col-12 mb-3">
                    Are you sure you want to remove this client from queue?
                  </h4>
                </div>
                <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No, keep
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      onOk();
                      this.props.dispatch(
                        studentQueueDelete({
                          Json: JSON.stringify({
                            Queue: {
                              StudentId: this.getIntakeId(),
                            },
                          }),
                        }),
                      );
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            )}
          />
        )}
        {/* <p>Share Pre-intake Details</p> */}
        {/* <p>Assign Forms</p> */}
        {/* <p>Schedule Appointment</p> */}
      </div>
    );
    return (
      <div class="main enrollment-page mb-5">
        <div class="container-fluid">
          <div class="row top-table-search align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h5>Prospect Details</h5>
            </div>
            <div className="col-sm-12 col-lg-8">
              <div className="d-flex justify-content-end  align-items-center">
                {IsthisPeerRecoveryClient && (
                  <StopwatchTimerAction
                    ID={this.getIntakeId()}
                    CurrentClientId={CurrentClientId}
                    Record={BannerData}
                    // StartTime={localStorage.getItem(`IsPeer${this.getIntakeId()}`)}
                  />
                )}
                <Popover
                  overlayClassName="alert-box-style"
                  content={content}
                  title={false}
                  trigger="click"
                  placement="bottom"
                  class="alert-sort"
                  open={this.state.isVisible}
                  onOpenChange={visible => {
                    this.setState({
                      isVisible: visible,
                    });
                  }}
                >
                  <button
                    className="btn btn-outline-primary mr-2"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    More <img src="images/icons/3dot_blue.svg" className="mx-2" />
                  </button>
                </Popover>

                {/* <AntdModal
                  modalWidth={800}
                  // style={{ top: 20 }}
                  ModalButton={({ onClick }) => (
                    <button
                      data-target="#set-reminder-model"
                      className="btn btn-outline-primary mr-2"
                      onClick={() => {
                        onClick();
                      }}
                    >
                      Assign Forms
                    </button>
                  )}
                  ModalContent={props => (
                    // <SetReminderModal
                    //   {...props}
                    //   ClientId={this.getIntakeId()}
                    //   toggle={this.toggle}
                    //   SurveyId={PreItakeSurveyId}
                    // />
                    <h4>Assign Forms</h4>
                  )}
                /> */}
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <button className="btn mr-2" data-target="#move-intake-model" onClick={onClick}>
                      Move to Intake
                    </button>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div className="modal-content border-0">
                      <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 className="modal-title col-12 mb-3">
                          Are you sure you want to move to Intake?
                        </h4>
                      </div>
                      <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          className="btn"
                          onClick={() => {
                            onOk();
                            this.moveToIntake();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 pt-2">
              <Banner
                getCallEndDetails={this.getCallEndDetails}
                ClientId={this.getIntakeId()}
                EditActionUrl={`/pre-intake-form/?step=demographics&studentid=${this.getIntakeId()}`}
              />
            </div>
          </div>
          <div class="content-area">
            <div class="col-12 pb-5 vertical-tabs px-0">
              <Tabs
                activeKey={String(activeKey)}
                tabPosition="left"
                defaultActiveKey="0"
                onTabClick={this.onTabClick}
              >
                {tabLabels.map((item, index) => {
                  const { Component, Name } = item;
                  return (
                    <TabPane tab={Name} key={`${index}`} className="tab-content enrollment-form">
                      {activeKey == index && (
                        <Component
                          style={{ padding: '36px 0px' }}
                          ClientId={this.getIntakeId()}
                          PreItakeSurveyId={PreItakeSurveyId}
                          BannerData={BannerData}
                        />
                      )}
                    </TabPane>
                  );
                })}
              </Tabs>
            </div>
          </div>
          {isNoteAddModal && (
            <Modal open={isNoteAddModal} footer={null} closable={false} destroyOnClose={true}>
              <AddProspectNote
                StudentId={this.getIntakeId()}
                onCancel={() => {
                  this.setState({
                    isNoteAddModal: false,
                  });
                }}
                NoteTypeIds={181} //181 is for prospect Note
                IsProspectNote={true}
              />
            </Modal>
          )}
          {isSetReminderModal && (
            <Modal
              open={isSetReminderModal}
              footer={null}
              closable={false}
              destroyOnClose={true}
              width={800}
              style={{ top: 20 }}
            >
              <SetReminderModal
                ClientId={this.getIntakeId()}
                toggle={this.toggle}
                SurveyId={PreItakeSurveyId}
                onCancel={() => {
                  this.setState({
                    isSetReminderModal: false,
                  });
                }}
              />
            </Modal>
          )}
          <CustomModal
            isOpen={this.state.isSetReminderSuccess}
            header={this.state.header}
            yesAction={() => {
              this.setState({
                isSetReminderSuccess: false,
                header: '',
              });
            }}
            yesText="OK"
            hideCancel={true}
          />
          <CustomModal
            isOpen={this.state.isModalOpen}
            header={this.state.header}
            yesAction={() => {
              this.setState({
                isModalOpen: false,
                header: '',
              });
            }}
            yesText="OK"
            hideCancel={true}
          />
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">Add Appointment</span>
              </div>
            }
            placement="right"
            open={this.state.isCallEndedDrawer}
            width={700}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={this.toggleDarwer}
          >
            <CallEndedForm
              CallSid={this.state.CallSid}
              twilioData={this.state.twilioData}
              isTwilioAppointment={true}
              onClose={this.toggleDarwer}
              // handleAppointment={this.handleAppointment}
              // initialValues={this.state.initialData}
              // AppointmentId={this.state.initialData.AppointmentId}
              // ClientId={ClientId}
              // OptionData={optionData}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    student_banner,
    prospect_archive,
    default_values,
    student_queue_delete,
    upsert_appointment,
  } = state;
  const { data, isFetching, error } = student_banner;
  const { data: archiveData } = prospect_archive;
  const { data: deleteData } = student_queue_delete;
  var hasAdminAccess = false;
  let isAppointmentBooked = false;

  let BannerData = null;
  let PreItakeSurveyId,
    CurrentClientId,
    IsthisPeerRecoveryClient = null;
  let EnrollStatusId = null;
  let IsPreIntakeArchived = false;
  let isArchiveSuccess = false;
  let isQueueDeleted = false;
  let archiveSuccessMsg = null;
  if (default_values && default_values.data) {
    hasAdminAccess = default_values.data && default_values.data.IsPurchaseEligible;
  }
  if (data) {
    PreItakeSurveyId = data.PreItakeSurveyId;
    CurrentClientId = data.CurrentClientId;
    IsthisPeerRecoveryClient = data.IsthisPeerRecoveryClient;
    EnrollStatusId = data.EnrollStatusId;
    IsPreIntakeArchived = data.IsPreIntakeArchived;
    BannerData = data;
  }

  if (archiveData && archiveData.success) {
    isArchiveSuccess = true;
    archiveSuccessMsg = archiveData.data && JSON.parse(archiveData.data)[0].Msg;
  }
  if (deleteData && deleteData.success) {
    isQueueDeleted = true;
    // archiveSuccessMsg = deleteData.data && JSON.parse(deleteData.data)[0].Msg;
  }
  if (upsert_appointment.data && upsert_appointment.data.success) {
    isAppointmentBooked = upsert_appointment.data.success;
  }
  return {
    PreItakeSurveyId,
    IsPreIntakeArchived,
    isArchiveSuccess,
    archiveSuccessMsg,
    hasAdminAccess,
    EnrollStatusId,
    isQueueDeleted,
    BannerData,
    isAppointmentBooked,
    CurrentClientId,
    IsthisPeerRecoveryClient,
  };
};
export default connect(mapStateToProps)(withRouter(PreIntakeDashboard));
