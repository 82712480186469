import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  CheckboxGroup,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
  ReduxAutoComplete,
} from '../../../../component/custom-redux-components';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import CustomModal from '../../../../core/modal';
import { connect } from 'react-redux';
import {
  medicateCombos,
  upsertClientMedical,
  resetUpsertClientMedical,
  getPatientDetail,
  allergenSearch,
  resetAllergenSearch,
  drugSearch,
  resetDrugSearch,
} from '../../../../store/actions';
import { ProcessCombos, ProcessFilterCombos } from '../../../../store/comboConfig';
import ManageAllergen from './manageAllergen';
import ManageMedication from './manageMedication';
import SubmitButton from '../../../../Components/submitButton';
var timer;

const diagnosed = [
  { label: 'Bipolar disorder', value: 'Bipolardisorder' },
  { label: 'Depression', value: 'Depression' },
  { label: 'Anxiety', value: 'Anxiety' },
  { label: 'Anger', value: 'Anger' },
  { label: 'Suicide', value: 'Suicide' },
  { label: 'Schizophrenia', value: 'Schizophrenia' },
  { label: 'Post-traumatic stress', value: 'Post-traumatic stress' },
  { label: 'Substance Use Disorder/Chemical Dependency', value: 'substanceUseDisorder' },
  // { label: 'Chemical Dependency', value: 'chemicalDependency' },
];
class MedicalHistoryStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courseData: {},
    };
  }

  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes:
        'Allergies,DiseaseConditions,Medication,Surgeries,Vaccination,Psychiatric,Veteran,Ethnicity,AllergyReaction,AllergySeverity',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    var result = ProcessCombos('Filter_StateProvince');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ isInserted }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.toggle(Upsert_Sucess_Msg);
    }
  }
  mapClientMedical = () => {
    // let { clientData } = this.props;
    let { clientInfo } = this.props;
    let clientData = clientInfo && clientInfo.ClientMedical && clientInfo.ClientMedical[0];
    if (clientData && clientInfo.ClientId > 0) {
      return {
        pcpName: clientData.PrimaryCareProvider,
        pcpPhoneNumber: clientData.PhoneNumber,
        pcpAddress: clientData.Address,
        pcpCity: clientData.City,
        pcpStateOrProvince: clientData.State,
        pcpZip: clientData.Zipcode,
        pcpPreferredHospital: clientData.PrefferedHospital,
        pcpHospitalPhoneNumber: clientData.HospitalPhoneNumber,
        allergies: clientData.AllergiesId,
        allergiesNotes: clientData.AllergiesNote,
        medication: clientData.MedicationId,
        medicationNotes: clientData.MedicationNote,
        vaccination: clientData.VaccinationId,
        vaccinationNotes: clientData.VaccinationNote,
        diseaseConditions: clientData.DiseaseId,
        diseaseConditionsNotes: clientData.DiseaseNote,
        listSurgeries: clientData.SurgeriesId,
        listSurgeriesNotes: clientData.SurgeriesNote,
        psychiatric: clientData.PsychiatricId,
        psychiatricNotes: clientData.PsychiatricNote,
        familyMedicalHistory:
          clientData.FamilyMedicalHistoryId && clientData.FamilyMedicalHistoryId.split(','),
        otherIssues: clientData.OtherIssue,
      };
    } else {
      return {};
    }
  };
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      pcpName,
      pcpPhoneNumber,
      pcpAddress,
      pcpCity,
      pcpStateOrProvince,
      pcpZip,
      pcpPreferredHospital,
      pcpHospitalPhoneNumber,
      allergies,
      allergiesNotes,
      medication,
      medicationNotes,
      diseaseConditions,
      diseaseConditionsNotes,
      vaccination,
      vaccinationNotes,
      listSurgeries,
      listSurgeriesNotes,
      psychiatric,
      psychiatricNotes,
      familyMedicalHistory,
      otherIssues,
    } = values;
    // var patientId = 20000019;
    let patientId = null;
    let paramClientId = this.props.clientId;
    if (paramClientId && paramClientId > 0) {
      patientId = paramClientId;
    }
    const data = {
      Client: [
        {
          ClientMedical: [
            {
              ClientId: patientId,
              PrimaryCareProvider: pcpName,
              PhoneNumber: pcpPhoneNumber,
              Address: pcpAddress,
              City: pcpCity,
              State: pcpStateOrProvince,
              Zipcode: pcpZip,
              PrefferedHospital: pcpPreferredHospital,
              HospitalPhoneNumber: pcpHospitalPhoneNumber,
              AllergiesId: allergies && 
                typeof allergies == 'object'
                  ? allergies.map(item => item.Value).toString()
                  : allergies || '',
              AllergiesNote: allergiesNotes,
              MedicationId: medication &&
                typeof medication == 'object'
                  ? medication.map(item => item.Value).toString()
                  : medication || '',
              MedicationNote: medicationNotes,
              VaccinationId: vaccination &&
                typeof vaccination == 'object'
                  ? vaccination.map(item => item.Value).toString()
                  : vaccination || '',
              VaccinationNote: vaccinationNotes,
              DiseaseId: diseaseConditions &&
                typeof diseaseConditions == 'object'
                  ? diseaseConditions.map(item => item.Value).toString()
                  : diseaseConditions || '',
              DiseaseNote: diseaseConditionsNotes,
              SurgeriesId: listSurgeries &&
                typeof listSurgeries == 'object'
                  ? listSurgeries.map(item => item.Value).toString()
                  : listSurgeries || '',
              SurgeriesNote: listSurgeriesNotes,
              PsychiatricId: psychiatric &&
                typeof psychiatric == 'object'
                  ? psychiatric.map(item => item.Value).toString()
                  : psychiatric || '',
              PsychiatricNote: psychiatricNotes,
              FamilyMedicalHistoryId: null,
              FamilyMedicalHistoryId: familyMedicalHistory && familyMedicalHistory.join(),
              // FamilyMedicalHistoryId:
              //   typeof familyMedicalHistory == 'object'
              //     ? familyMedicalHistory.map(item => item.Value).toString()
              //     : familyMedicalHistory || '',
              OtherIssue: otherIssues,
            },
          ],
        },
      ],
    };
    let medicalData = {
      // courseId: courseId,
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertClientMedical(medicalData));
  }

  render() {
    return (
      <React.Fragment>
        <ReduxMedicalHistoryForm
          {...this.props}
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.mapClientMedical()}
          onCancel={this.props.onCancel}
          clientData={this.props.clientData}
          disable={this.props.isDeActivated || this.props.isReadOnlyUser}
          dispatch={this.props.dispatch}
          clientId={this.props.clientId}
          isEPrescribe={this.props.isEPrescribe}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // this.props.onCancel();
            this.props.dispatch(
              getPatientDetail({
                json: JSON.stringify({
                  Client: [
                    {
                      ClientId: this.props.clientId,
                    },
                  ],
                }),
              }),
            );
            this.props.dispatch(resetUpsertClientMedical());
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const MedicalHistoryForm = props => {
  const {
    handleSubmit,
    onSaveClick,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    isEmployedValue,
    style,
    medicateCombosObj,
    goToPreviuos,
    StateProvince,
    disable,
    reset,
    dispatch,
    isEPrescribe,
    isFetching,
  } = props;
  const {
    Allergies,
    DiseaseConditions,
    Medication,
    Surgeries,
    Vaccination,
    Psychiatric,
  } = props.medicateCombosObj;
  let doseList = [];
  return (
    <form onSubmit={handleSubmit(onSaveClick)} style={{ padding: '36px 20px 20px 20px' }}>
      <div class="row  rcm-tab mb-4">
        <div class="col-12">
          <h6>PRIMARY CARE PROVIDER</h6>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpName`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Primary Care Provider'}
              disabled={disable}
            />
            <label class="placeholder-label">Primary Care Provider</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpPhoneNumber`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Phone Number'}
              disabled={disable}
            />
            <label class="placeholder-label">Phone Number</label>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpAddress`}
              type="textarea"
              component={ReduxTextarea}
              // validate={validate.required}
              fieldName={'Address'}
              disabled={disable}
            />
            <label class="placeholder-label">Address</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpCity`}
              type="text"
              component={ReduxInput}
              fieldName="City"
              validate={validate.required}
              disabled={disable}
            />
            <label class="placeholder-label">City*</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpStateOrProvince`}
              type="text"
              component={ReduxSelect}
              options={StateProvince}
              placeholder="State*"
              validate={validate.required}
              fieldName="State"
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpZip`}
              type="text"
              component={ReduxInput}
              disabled={disable}
              validate={[validate.usaZip, validate.required]}
              fieldName={'Zip'}
            />
            <label class="placeholder-label">Zipcode*</label>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpPreferredHospital`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Preferred Hospital'}
              disabled={disable}
            />
            <label class="placeholder-label">Preferred Hospital</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`pcpHospitalPhoneNumber`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Phone Number'}
              disabled={disable}
            />
            <label class="placeholder-label">Phone Number</label>
          </div>
        </div>
        {/* <div class="col-12 mt-2">
          <h6 style={{ display: 'inline-block', position: 'relative', top: '30px ' }}>Allergies</h6>
        </div> */}
        <div className="col-12">
          <h6 style={{ display: 'inline-block', position: 'relative', top: '30px ' }}>Allergies</h6>
          <ManageAllergen disable={disable} clientId={props.clientId} isEPrescribe={isEPrescribe} />
        </div>

        {/* <div class="col-12 mt-2">
          <h6 style={{ display: 'inline-block', position: 'relative', top: '30px ' }}>
            Medication
          </h6>
        </div> */}
        <div className="col-12">
          <h6 style={{ display: 'inline-block', position: 'relative', top: '30px ' }}>
            Medication
          </h6>
          <ManageMedication
            disable={disable}
            clientId={props.clientId}
            isEPrescribe={isEPrescribe}
          />
        </div>
        <div class="col-12 mt-2">
          <h6>PATIENT MEDICAL HISTORY</h6>
        </div>

        {/* <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name="allergies"
              type="text"
              placeholder="Allergies"
              component={ReduxSelect}
              options={Allergies}
              // validate={validate.required}
              fieldName={'Allergies'}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`allergiesNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name="medication"
              type="text"
              placeholder="Medication"
              component={ReduxSelect}
              options={Medication}
              // validate={validate.required}
              fieldName={'Medication'}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`medicationNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div> */}
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name="vaccination"
              type="text"
              placeholder="Vaccination"
              component={ReduxSelect}
              options={Vaccination}
              // validate={validate.required}
              fieldName={'Vaccination'}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`vaccinationNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name="diseaseConditions"
              type="text"
              placeholder="Disease/Conditions"
              component={ReduxSelect}
              options={DiseaseConditions}
              // validate={validate.required}
              fieldName={'Disease/Conditions'}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`diseaseConditionsNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name="listSurgeries"
              type="text"
              placeholder="List Surgeries"
              component={ReduxSelect}
              options={Surgeries}
              // validate={validate.required}
              fieldName={'List Surgeries'}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`listSurgeriesNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name="psychiatric"
              type="text"
              placeholder="Psychiatric"
              component={ReduxSelect}
              options={Psychiatric}
              // validate={validate.required}
              fieldName={'Psychiatric'}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <Field
              name={`psychiatricNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>

        {/* <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`medication`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Medication'}
              disabled={disable}
            />
            <label class="placeholder-label">Medication</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`vaccination`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Vaccination'}
              disabled={disable}
            />
            <label class="placeholder-label">Vaccination</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="Disease/Conditions"
              type="text"
              placeholder="Disease/Conditions"
              component={ReduxSelect}
              options={[
                {
                  Text: 'optionsPending',
                  Value: 'optionsPending',
                },
              ]}
              // validate={validate.required}
              fieldName={'Disease/Conditions'}
              disabled={disable}
              isMulti
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`listSurgeries`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'List Surgeries'}
              disabled={disable}
            />
            <label class="placeholder-label">List Surgeries</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`psychiatric`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Psychiatric'}
              disabled={disable}
            />
            <label class="placeholder-label">Psychiatric</label>
          </div>
        </div>
         */}
        <div class="col-12 col-md-6 col-lg-4">
          <br />
        </div>
        <div class="col-12">
          <h6>FAMILY MEDICAL HISTORY</h6>
        </div>
        <div className="col-12 my-3">
          <p className="">Has anyone in your family been diagnosed with or treated for :</p>
        </div>
        <div class="col-12">
          <div
            class="form-group"
            style={{
              flexFlow: 'wrap',
              display: 'flex',
            }}
          >
            <Field
              name="familyMedicalHistory"
              component={CheckboxGroup}
              col={8}
              widthClassName={'w-75'}
              // offset={1}
              // justify={'start'}
              options={diagnosed}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12">
          <br />
        </div>

        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <Field
              name={`otherIssues`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Other Issues</label>
          </div>
        </div>

        <div class="row pagination-button">
          {!disable && (
            <div class="col-12 text-right">
              <input
                type="button"
                class="btn btn-outline-primary mr-2"
                value="Cancel"
                // onClick={props.onCancel}
                onClick={reset}
              />
              <SubmitButton loading={isFetching} value="Save" />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
const mapStateToProps = state => {
  const { combos, medicate_combos, drug_search, upsert_client_medical } = state;
  const { data } = medicate_combos;
  const { isFetching } = upsert_client_medical;
  let medicateCombosObj = {
    Allergies: [],
    DiseaseConditions: [],
    Medication: [],
    Surgeries: [],
    Vaccination: [],
    Psychiatric: [],
  };

  if (data) {
    medicateCombosObj = {
      Allergies: data.Allergies || [],
      DiseaseConditions: data.DiseaseConditions || [],
      Medication: data.Medication || [],
      Surgeries: data.Surgeries || [],
      Vaccination: data.Vaccination || [],
      Psychiatric: data.Psychiatric || [],
    };
  }
  let Filter_StateProvince = [];
  if (combos.data && combos.data['Filter_StateProvince']) {
    Filter_StateProvince = combos.data['Filter_StateProvince'].USA;
  }
  return {
    StateProvince: Filter_StateProvince,
    isEmployedValue: selector(state, 'isEmployed'),
    medicateCombosObj: medicateCombosObj,
    isFetching,
  };
};

const ReduxMedicalHistoryForm = reduxForm({
  form: 'patientMedicalHistoryForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(MedicalHistoryForm)));

const mapStateToPropsParent = ({
  upsert_client_medical,
  get_patient_detail,
  client_banner_get,
}) => {
  const { data: clientInfo } = get_patient_detail;
  const { data: clinetBanner } = client_banner_get;

  let isInserted = false;
  let clientData = {};
  let isDeActivated = false;
  let isEPrescribe;

  if (upsert_client_medical && upsert_client_medical.data && upsert_client_medical.data.success) {
    isInserted = true;
  }
  if (clinetBanner && clinetBanner.Banner) {
    isEPrescribe = clinetBanner.Banner[0] && clinetBanner.Banner[0].IsEPrescribeClient;
  }

  if (clientInfo && clientInfo.ClientMedical) {
    clientData = clientInfo.ClientMedical[0];
    clientData.ClientId = clientInfo.ClientId;
  }
  if (clientInfo && clientInfo.ClientId) {
    isDeActivated = clientInfo.IsDeActivated;
  }
  return {
    isInserted,
    clientData: clientData,
    isDeActivated,
    isEPrescribe,
  };
};
export default connect(mapStateToPropsParent)(MedicalHistoryStep);
const selector = formValueSelector('patientMedicalHistoryForm');
