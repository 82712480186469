import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Checkbox, Radio, Pagination } from 'antd';
import Loader, { Loader2 } from '../../Components/Loader';
import AntdModal from '../../Components/CustomModal';
import { Link, withRouter } from 'react-router-dom';
import { Tag, Modal, Tooltip, Avatar } from 'antd';
import _blank from '../../images/blank-profile.png';
import { isComparer, isNumericComparer, isDateComparer } from '../../util/handler';
import ExportExcel from '../../Components/ExportExcel';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { breathalyzerTestUpcomingToggle, getBreathalyzerTestUpcoming } from '../../store/actions';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

class UpcomingTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BreathalyzerData: {},
      columns: [
        {
          title: 'Test Date',
          dataIndex: 'TestDate',
          key: 'TestDate',
          isPrint: true,
          sorter: (a, b) => isDateComparer(a.TestDateForSort, b.TestDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Day of the week',
          dataIndex: 'TestDayOfWeek',
          key: 'TestDayOfWeek',
          isPrint: true,
          sorter: (a, b) => isComparer(a.TestDayOfWeek, b.TestDayOfWeek),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Time',
          dataIndex: 'TestTime',
          key: 'TestTime',
          isPrint: true,
          sorter: (a, b) => isComparer(a.TestTime, b.TestTime),
          sortDirections: ['descend', 'ascend'],
        },

        {
          title: 'Action',
          key: 'x',
          isPrint: false,
          render: (text, record, index) => {
            return (
              <div class="tools-grid d-flex align-items-center">
                {!record.IsCanceled ? (
                  <div className="">
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <img
                          src="images/icons/cancel.svg"
                          alt="Cancel Schedule"
                          width="30"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#cancel-schedule"
                        />
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to cancel this scheduled test?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  breathalyzerTestUpcomingToggle({
                                    Json: JSON.stringify({
                                      Breathalyzer: [
                                        {
                                          UserGuid: props.UserGuid,
                                          BreathalyzerTestInstanceGuid:
                                            record.BreathalyzerTestInstanceGuid,
                                          IsCancel: true,
                                          IsCancelUndo: false,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <span
                      style={{
                        fontStyle: 'italic',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '19px',
                        color: '#B8B8B8',
                        marginRight: '10px',
                      }}
                    >
                      Canceled
                    </span>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '19px',
                        color: '#4AB0FE',
                      }}
                      onClick={() => {
                        this.props.dispatch(
                          breathalyzerTestUpcomingToggle({
                            Json: JSON.stringify({
                              Breathalyzer: [
                                {
                                  UserGuid: props.UserGuid,
                                  BreathalyzerTestInstanceGuid: record.BreathalyzerTestInstanceGuid,
                                  IsCancel: false,
                                  IsCancelUndo: true,
                                },
                              ],
                            }),
                          }),
                        );
                      }}
                    >
                      Undo
                    </span>
                  </div>
                )}
              </div>
            );
          },
        },
      ],
    };
  }
  componentDidMount() {
    // this.props.dispatch(
    //   getMediaVideoUrl({
    //     Json: JSON.stringify({
    //       Media: [
    //         {
    //           MediaGuid: this.props.MediaGuid,
    //         },
    //       ],
    //     }),
    //   }),
    // );
    this.props.dispatch(
      getBreathalyzerTestUpcoming({
        Json: JSON.stringify({
          Breathalyzer: [
            {
              UserGuid: this.props.UserGuid,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ IsCanceledToggle }) {
    if (
      IsCanceledToggle &&
      JSON.stringify(IsCanceledToggle) != JSON.stringify(this.props.IsCanceledToggle)
    ) {
      this.props.dispatch(
        getBreathalyzerTestUpcoming({
          Json: JSON.stringify({
            Breathalyzer: [
              {
                UserGuid: this.props.UserGuid,
              },
            ],
          }),
        }),
      );
    }
  }

  render() {
    const { MediaGuid } = this.state;
    const { isFetching, UserGuid, BreathalyzerData, BreathalyzerUpcoming } = this.props;
    // const {
    //   UserGuid,
    //   BreathalyzerScheduleGuid,
    //   BreathalyzerTestResult,
    //   DisplayName,
    //   EndDate,
    //   StartDate,
    //   NextTest,
    //   ProfileImageUrl,
    //   LastTestResult,
    // } = BreathalyzerData || {};
    return (
      <div>
        <Loader2 loading={isFetching} />
        {BreathalyzerUpcoming && BreathalyzerUpcoming.length > 0 ? (
          <div className="row">
            <div class="col-12 pb-5">
              <div style={{ height: '276px', overflowY: 'scroll' }} className="time-line-table">
                <Table
                  columns={this.state.columns}
                  dataSource={BreathalyzerUpcoming}
                  size={'middle'}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex align-items-center justify-content-center"
              style={{ height: '276px' }}
            >
              <h5 className="text-center">No data</h5>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToPropsHistory = ({
  get_breathalyzer_test_upcoming,
  breathalyzer_test_upcoming_toggle,
}) => {
  const { data: BreathalyzerUpcomingData, error, isFetching } = get_breathalyzer_test_upcoming;
  let BreathalyzerUpcoming = null;
  let IsCanceledToggle = false;

  if (BreathalyzerUpcomingData && BreathalyzerUpcomingData.BreathalyzerUpcomingTests) {
    BreathalyzerUpcoming = BreathalyzerUpcomingData.BreathalyzerUpcomingTests || [];
  }
  if (breathalyzer_test_upcoming_toggle.data && breathalyzer_test_upcoming_toggle.data.success) {
    IsCanceledToggle = true;
  }

  return {
    BreathalyzerUpcoming: BreathalyzerUpcoming,
    isFetching,
    IsCanceledToggle,
  };
};

export default connect(mapStateToPropsHistory)(UpcomingTests);
