import React from 'react';
import { Collapse } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';
import TextareaAutosize from 'react-autosize-textarea';
const { Panel } = Collapse;

class AttendeesModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandAll: false,
      activeKey: this.props.Attendees && this.props.Attendees.map((v, idx) => idx),
    };
  }

  expandAll = () => {
    this.setState(prevState => {
      let activeKeys = [];
      if (prevState.expandAll) {
        activeKeys = this.props.Attendees && this.props.Attendees.map((v, idx) => idx);
      }
      return {
        expandAll: !prevState.expandAll,
        activeKey: activeKeys,
      };
    });
  };

  render() {
    const { Attendees, AttendeesCount, GroupAppointmentId } = this.props;
    return (
      <>
        <div className="attendees-section">
          <div className="mt-2 mb-2 d-flex" style={{ justifyContent: 'space-between' }}>
            <span
              style={{
                fontStyle: 'normal',
                fontWight: 'normal',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#828282',
              }}
            >
              {' '}
              ATTENDEES &nbsp; ({AttendeesCount}){' '}
            </span>
            <span
              onClick={this.expandAll}
              className="mt-2 mb-2"
              style={{
                width: '150px',
                fontSize: '18px',
                color: '#46b4fe',
                fontWeight: '600',
                cursor: 'pointer',
              }}
            >
              {this.state.expandAll ? `+ Expand All` : `- Collapse All`}
            </span>
          </div>

          {Attendees &&
            Attendees.map((item, index) => {
              const {
                ClientId,
                ClientName,
                Note,
                NoteStatus,
                AddendumCount,
                AddendumStatus,
                TreatmentPlanIntervention,
                StartTime,
                EndTime,
                SessionTime,
                ClientMeetingStatus,
              } = item;
              let accordianHeader = (
                <>
                  {' '}
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <span
                        style={{
                          fontStyle: 'normal',
                          fontWight: 'bold',
                          fontSize: '18px',
                          lineHeight: '25px',
                          color: '#000',
                        }}
                      >
                        {`${ClientName}`}
                      </span>
                      <span
                        style={{
                          fontStyle: 'normal',
                          // fontWight: 'bold',
                          fontSize: '12px',
                          lineHeight: '25px',
                          marginLeft: '5px',
                          color: '#828282',
                        }}
                      >
                        ({ClientMeetingStatus})
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <span
                        style={{
                          fontStyle: 'normal',
                          fontWight: 'normal',
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: '#FF7979',
                          display: 'inline-flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {NoteStatus && NoteStatus.length > 0 && (
                          <i class="fas fa-circle" style={{ fontSize: '5px' }}></i>
                        )}
                        &nbsp;
                        {/* <span> {`${NoteStatus}`}</span> */}
                      </span>
                      &nbsp;<span className="d-flex align-items-center">{SessionTime}</span>
                    </div>
                    <span
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#4AB0FE',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        this.props.resetManageAttendeeNote(ClientId, GroupAppointmentId);
                      }}
                    >
                      MANAGE <i class="fas fa-tasks"></i>
                    </span>
                  </div>
                </>
              );
              return (
                <Collapse
                  activeKey={this.state.activeKey}
                  // defaultActiveKey=[]
                  defaultActiveKey={`${index}`}
                  // activeKey={(function(Attendees) {
                  //   var a =
                  //     Attendees &&
                  //     Attendees.map((item, index) => {
                  //       return JSON.stringify(index);
                  //     });
                  //   return a;
                  // })()}
                  expandIconPosition={'right'}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: '#000' }} />
                  )}
                >
                  <Panel header={accordianHeader} key={index}>
                    {TreatmentPlanIntervention && TreatmentPlanIntervention.length > 0 ? (
                      <div className="mt-2 mb-2">
                        <div className="content-title">INTERVENTION</div>
                        {TreatmentPlanIntervention &&
                          TreatmentPlanIntervention.map(item => {
                            return <p className="content-content">{item.Description}</p>;
                          })}
                      </div>
                    ) : (
                      <p
                        className="content-content"
                        style={{
                          fontStyle: 'normal',
                          fontWight: 'normal',
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: 'rgb(255, 121, 121)',
                        }}
                      >
                        Intervention not added
                      </p>
                    )}
                    <div className="mt-2 mb-2">
                      {Note && (
                        <div className="content-title">
                          NOTE &nbsp;&nbsp;
                          <span
                            style={{
                              fontStyle: 'normal',
                              fontWight: 'normal',
                              fontSize: '14px',
                              lineHeight: '19px',
                              color: '#FF7979',
                              display: 'inline-flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {NoteStatus.length > 0 && (
                              <i class="fas fa-circle" style={{ fontSize: '5px' }}></i>
                            )}
                            &nbsp;&nbsp;
                            <span> {`${NoteStatus}`}</span>
                          </span>
                        </div>
                      )}

                      {Note && Note ? (
                        <TextareaAutosize
                          className={`form-control not-empty mb-2`}
                          rows="1"
                          name="textarea_field"
                          key="gNoteBox"
                          id="NoteBox"
                          value={Note}
                          disabled
                        />
                      ) : (
                        <p
                          className="content-content"
                          style={{
                            fontStyle: 'normal',
                            fontWight: 'normal',
                            fontSize: '16px',
                            lineHeight: '19px',
                            color: '#B8B8B8',
                          }}
                        >
                          No note added
                        </p>
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <span
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '14px',
                          lineHeight: '19px',
                          color: '#000',

                          display: 'flex',
                          alignItems: 'center',
                          //   cursor: 'pointer',
                        }}
                      >
                        <img src="images/addendum.svg" /> &nbsp;
                        {AddendumCount && AddendumCount}&nbsp;Addendum &nbsp;{' '}
                        <span
                          style={{
                            fontStyle: 'normal',
                            fontWight: 'normal',
                            fontSize: '14px',
                            lineHeight: '19px',
                            color: '#FF7979',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {AddendumStatus && AddendumStatus.length > 0 && (
                            <i class="fas fa-circle" style={{ fontSize: '5px' }}></i>
                          )}
                          &nbsp;&nbsp;
                          <span> {`${AddendumStatus}`}</span>
                        </span>
                      </span>
                      {/* <span
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: '#4AB0FE',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.props.resetManageAttendeeNote(ClientId, GroupAppointmentId);
                        }}
                      >
                        Manage
                      </span> */}
                    </div>
                  </Panel>
                </Collapse>
              );
            })}
        </div>
      </>
    );
  }
}
export default withRouter(connect()(AttendeesModule));
