import React, { Component } from 'react';

const RefundPolicy=()=> {
 return (
   <React.Fragment>
     <div class="container mt-5">
       <h5 className="text-center" style={{ fontSize: '38px', color: '#46b4fe' }}>
         Refund Policy
       </h5>
       <p>
         Since our Website offers non-tangible, irrevocable goods we do not provide refunds after
         the product is purchased, which you acknowledge prior to purchasing any product on the
         Website. Please make sure that you’ve carefully read service description before making a
         purchase.
       </p>
       <h5>Contacting Us</h5>
       <p>
         If you would like to contact us concerning any matter relating to this Refund Policy, you
         may send an email to{' '}
         <a href="mailto:support@soberpeer.com">
           <strong>support@soberpeer.com</strong>
         </a>
         .
       </p>
       <h5>Acceptance of this Policy</h5>
       <p>
         You acknowledge that you have read this Policy and agree to all its terms and conditions.
         By accessing the Website, you agree to be bound by this Policy. If you do not agree to
         abide by the terms of this Policy, you are not authorized to use or access the Website.
       </p>
       <p>
         This document is incorporated as a part of the Company’s overall policies, terms and
         conditions, and acceptable use standards for any person using the Website and/or Services.
         We reserve our right to be the sole arbiter in interpreting and applying the policy,
         including determining the seriousness of any violation and any corrective actions.
       </p>
       <p className="pb-5">This document was last updated on July 9, 2023.</p>
     </div>
   </React.Fragment>
 );
}



export default RefundPolicy;
