import React, { Component } from 'react';
import { Popover, Cascader, Icon, Checkbox, Table } from 'antd';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ TriggerTypeValue, TriggerTypeImgUrl }) => (
  <div className="custom-marker">
    <div className="d-flex flex-column">{<img src={TriggerTypeImgUrl} width={'45px'} />}</div>
    {/* {TriggerTypeValue} */}
  </div>
);

class TriggerAdded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 16,
    };
  }

  componentDidMount() {}

  render() {
    const { EventJsonData, isFetching } = this.props;
    let { Latitude, Longitude, FullAddress, TriggerTypeValue, TriggerTypeImgUrl } =
      EventJsonData.Trigger && EventJsonData.Trigger[0];
    return (
      <div class="border-0">
        <div>
          <h5>
            Full Address : <small>{FullAddress}</small>
          </h5>
          <a
            className="ml-2 mb-2 d-flex align-items-center"
            href={`http://www.google.com/maps/place/${Latitude},${Longitude}`}
            target={'_blank'}
          >
            See Event on the Map &nbsp;
            <img src={`images/timeline/location.svg`} width={'20px'} />
          </a>
        </div>
        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo' }}
            defaultCenter={{
              lat: Latitude,
              lng: Longitude,
            }}
            defaultZoom={this.state.zoom}
          >
            <AnyReactComponent
              lat={Latitude}
              lng={Longitude}
              // VisitCount={history.VisitCount}
              TriggerTypeImgUrl={TriggerTypeImgUrl}
              // OtherUsersTaggedThisPlaceCount={history.OtherUsersTaggedThisPlaceCount}
              // FullAddress={history.FullAddress}
              TriggerTypeValue={TriggerTypeValue}
              // text={TriggerTypeValue}
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default TriggerAdded;
