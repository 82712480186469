import React, { Component } from 'react';

const Disclaimer=()=> {
 return (
   <React.Fragment>
     <div class="container mt-5">
       <h5 className="text-center" style={{ fontSize: '38px', color: '#46b4fe' }}>
         Disclaimer
       </h5>
       <p>
         This disclaimer (“Disclaimer”, “Agreement”) is an agreement between Sober Peer, LLC (“Sober
         Peer, LLC”, “us”, “we” or “our”) and you (“User”, “you” or “your”). This Disclaimer sets
         forth the general guidelines, terms, and conditions of your use of the{' '}
         <a target="_blank" rel="nofollow" href="https://provider.soberpeer.com/">
           provider.soberpeer.com
         </a>{' '}
         website and any of its products or services (collectively, “Website” or “Services”).
       </p>

       <h5>Representation</h5>
       <p>
         Any views or opinions represented in this Website reflect the views and opinions of Sober
         Peer, LLC, its affiliates, Content creators or employees. Any views or opinions are not
         intended to malign any religion, ethnic group, club, organization, company, or individual.
       </p>

       <h5>Content and Postings</h5>
       <p>
         You may not modify, print, or copy any part of the Website. Inclusion of any part of this
         Website in another work, whether in printed or electronic or another form or inclusion of
         any part of the Website in another website by embedding, framing or otherwise without the
         express permission of Sober Peer, LLC is prohibited.
       </p>

       <p>
         You may submit comments for the Content available on the Website. By uploading or otherwise
         making available any information to Sober Peer, LLC, you grant Sober Peer, LLC the
         unlimited, perpetual right to distribute, display, publish, reproduce, reuse, and copy the
         information contained therein. You may not impersonate any other person through the
         Website. You may not post content that is defamatory, fraudulent, obscene, threatening,
         invasive of another person’s privacy rights or that is otherwise unlawful. You may not post
         content that infringes on the intellectual property rights of any other person or entity.
         You may not post any content that includes any computer virus or other code designed to
         disrupt, damage, or limit the functioning of any computer software or hardware. By
         submitting or posting Content on the Website, you grant Sober Peer, LLC the right to edit
         and, if necessary, remove any Content at any time and for any reason.
       </p>

       <h5>Indemnification and Warranties</h5>
       <p>
         While we have made every attempt to ensure that the information contained on the Website is
         correct, Sober Peer, LLC is not responsible for any errors or omissions, or for the results
         obtained from the use of this information. All information on the Website is provided “as
         is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained
         from the use of this information, and without warranty of any kind, express or implied. In
         no event will Sober Peer, LLC, or its partners, employees, or agents, be liable to you or
         anyone else for any decision made or action taken in reliance on the information on the
         Website or for any consequential, special, or similar damages, even if advised of the
         possibility of such damages. Furthermore, information contained on the Website and any
         pages linked to and from it are subject to change at any time and without warning.
       </p>

       <p>
         We reserve the right to modify this Disclaimer relating to the Website or Services at any
         time, effective upon posting of an updated version of this Disclaimer on the Website. When
         we do, we will revise the updated date at the bottom of this page. Continued use of the
         Website after any such changes shall constitute your consent to such changes.
       </p>

       <h5>Contacting Us</h5>
       <p>
         If you would like to contact us to understand more about this Disclaimer or wish to contact
         us concerning any matter relating to it, you may send an email to{' '}
         <a href="mailto:support@soberpeer.com">
           <strong>support@soberpeer.com</strong>
         </a>
         .
       </p>

       <h5>Acceptance of this Disclaimer</h5>
       <p>
         You acknowledge that you have read this Disclaimer and agree to all its terms and
         conditions. By accessing the Website, you agree to be bound by this Disclaimer. If you do
         not agree to abide by the terms of this Disclaimer, you are not authorized to use or access
         the Website.
       </p>

       <p>
         This document is incorporated as a part of the Company’s overall policies, terms and
         conditions, and acceptable use standards for any person using the Website and/or Services.
         We reserve our right to be the sole arbiter in interpreting and applying the policy,
         including determining the seriousness of any violation and any corrective actions.
       </p>

       <p className="pb-5">This document was last updated on July 9, 2023.</p>
     </div>
   </React.Fragment>
 );
}



export default Disclaimer;
