import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import SubmitButton from '../../../../../Components/submitButton';
import ValidatePin from '../../../../../Components/validatePinModal';
import ValidateSignatureModal from '../../../../../Components/validateSignatureModal';
import renderHTML from 'react-render-html';
import { ReduxDateInput, ReduxCheckbox } from '../../../../../component/custom-redux-components';
import CustomModal from '../../../../../core/modal';
import { Field, reduxForm } from 'redux-form';
import {
  upsertTreatmentPlan,
  getTreatmentPlan,
  resetClientSignatureSave,
} from '../../../../../store/actions';
import Axios from 'axios';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (beginningTime.isBefore(endTime)) {
    } else {
      return 'should be greater than Start time';
    }
  },
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return 'has to be greater than Start Date';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class TreatmentPlanSubmissionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TreatmentPlanId: props.TreatmentPlanId,
      validPinModalVisible: false,
      treatmentPlanApiObj: {},
      isModalOpen: true,
      isSignatureModalOpen: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  resetValidPinModalVisible = () => {
    this.setState({ validPinModalVisible: !this.state.validPinModalVisible });
  };
  toggleSignatureModal = header => {
    this.setState({ isSignatureModalOpen: !this.state.isSignatureModalOpen });
  };

  componentWillReceiveProps({
    validateStaffPinData,
    utDataSuccess,
    signatureValidatedMsg,
    isSignatureValidated,
  }) {
    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      const { treatmentPlanApiObj } = this.state;
      if (validateStaffPinData.IsValid) {
        // this.setState({
        //   StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId,
        // });
        if (this.state.IsSign) {
          this.props.dispatch(
            upsertTreatmentPlan({
              Json: JSON.stringify({
                TreatmentPlan: [
                  {
                    ...treatmentPlanApiObj,
                    StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId
                      ? validateStaffPinData.StaffPinHistoryId
                      : null,
                    SupervisorStaffPinHistoryId: validateStaffPinData.SupervisorStaffPinHistoryId
                      ? validateStaffPinData.SupervisorStaffPinHistoryId
                      : null,
                    IsSign: true,
                    IsIntitialSignatureCompleted: this.props.isTreatmentPlanSigned
                      .IsIntitialSignatureCompleted
                      ? this.props.isTreatmentPlanSigned.IsIntitialSignatureCompleted
                      : false,
                  },
                ],
              }),
            }),
          );
        } else {
          this.props.dispatch(
            upsertTreatmentPlan({
              Json: JSON.stringify({
                TreatmentPlan: [
                  {
                    ...treatmentPlanApiObj,
                  },
                ],
              }),
            }),
          );
        }
      }
    }
    if (isSignatureValidated && isSignatureValidated != this.props.isSignatureValidated) {
      this.props.dispatch(resetClientSignatureSave());
      this.toggleSignatureModal();
      this.toggle('Patient Signed Successfully');
      this.props.dispatch(
        getTreatmentPlan({
          Json: JSON.stringify({
            TreatmentPlan: [
              {
                TreatmentPlanId: this.props.TreatmentPlanId,
              },
            ],
          }),
        }),
      );
    }
    if (
      utDataSuccess &&
      JSON.stringify(utDataSuccess) != JSON.stringify(this.props.utDataSuccess)
    ) {
      let data = {
        Json: JSON.stringify({
          TreatmentPlan: [
            {
              TreatmentPlanId: this.props.TreatmentPlanId,
            },
          ],
        }),
      };
      this.props.dispatch(getTreatmentPlan(data));
      if (this.state.IsSign) {
        this.toggle('Treatment Plan Submitted Successfully');
      } else {
        this.toggle('Treatment Plan Saved Successfully');
      }
    }
  }
  onhandleSubmit = val => {
    // let treatmentPlanApiObj = {
    //   TreatmentPlan: [
    //     {
    //       TreatmentPlanId: this.state.TreatmentPlanId,
    //       StartDate: moment(val.startDate).format('YYYY-MM-DD'),
    //       EndDate: moment(val.endDate).format('YYYY-MM-DD'),
    //       StaffPinHistoryId: 1,
    //     },
    //   ],
    // };
    let treatmentPlanApiObj = {
      TreatmentPlanId: this.state.TreatmentPlanId,
      StartDate: moment(val.startDate).format('YYYY-MM-DD'),
      EndDate: moment(val.endDate).format('YYYY-MM-DD'),
      IsIndividualCopyRecieved: val.isIndividualCopyRecieved,
    };
    if (this.state.IsSign) {
      let data = {
        Json: JSON.stringify({
          Staff: [
            {
              FormType: 'TreatmentPlan',
              FormId: this.state.TreatmentPlanId,
            },
          ],
        }),
      };
      let me = this;
      Axios.post('Medicaid/PreValidatePin', data).then(response => {
        let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
        let failMsg = JSON.parse(response.data.data)[0].Staff[0].FailMessage;
        let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0].IsRealSignature;
        if (isValid) {
          this.setState({
            treatmentPlanApiObj: treatmentPlanApiObj,
            validPinModalVisible: true,
            IsRealSignature: IsRealSignature,
          });
        } else {
          me.props.toggle(failMsg);
          me.setState({
            isSignStatus: false,
          });
        }
      });
    } else {
      this.props.dispatch(
        upsertTreatmentPlan({
          Json: JSON.stringify({
            TreatmentPlan: [
              {
                ...treatmentPlanApiObj,
              },
            ],
          }),
        }),
      );
    }
  };
  onChange = value => {};
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  TPForm = (
    <form onSubmit={this.props.handleSubmit(this.onhandleSubmit)}>
      <div class="row">
        {this.props.isTreatmentPlanSigned && this.props.isTreatmentPlanSigned.IsUserSigned && (
          <div class="col-12">
            <div class="form-group">
              <Field
                className="mr-2"
                name="isIndividualCopyRecieved"
                component={ReduxCheckbox}
                fieldName={'Is Individual Copy Recieved'}
              />
              <label class="placeholder-label" style={{ top: '3px' }}>
                An individual was provided a copy
              </label>
            </div>
          </div>
        )}

        <div class="form-group mb-2 col-md-6 col-lg-4 col-xl-3">
          <Field
            name="startDate"
            type="text"
            component={ReduxDateInput}
            fieldName={'Start Date'}
            validate={Validator.required}
            label="Start Date*"
            minDate={new Date()}
          />
          <i class="far fa-calendar-alt form-control-icon" style={{ paddingRight: '11px' }} />
        </div>
        <div class="form-group mb-2 col-md-6 col-lg-4 col-xl-3">
          <Field
            name="endDate"
            type="text"
            component={ReduxDateInput}
            fieldName={'End Date'}
            validate={[Validator.required, Validator.endDate]}
            label="End Date*"
            minDate={new Date()}
          />
          {/* <i class="far fa-calendar-alt form-control-icon" /> */}
          <i class="far fa-calendar-alt form-control-icon" style={{ paddingRight: '11px' }} />
        </div>
        {!this.props.isDeActivated && (
          <div className="form-group mb-2 col-md-4 col-lg-4 col-xl-6 d-flex align-items-center">
            <div>
              <button
                className="btn btn-outline-primary  font-weight-bold mr-0"
                type="submit"
                // style={{
                //   color: '#4AB0FE',
                //   cursor: 'pointer',
                //   background: 'transparent',
                //   border: 'none',
                //   fontSize: '16px',
                //   fontWeight: 'bold',
                // }}
                onClick={() => {
                  this.setState({ IsSign: false });
                }}
              >
                Save
              </button>

              {/* <input
                      type="submit"
                      class="btn black-btn font-weight-bold mr-0"
                      value="Sign and Submit"
                    /> */}
            </div>
            <div className="ml-2">
              <SubmitButton
                loading={this.props.isFetching}
                style={{ padding: '0 24px', height: '44px' }}
                value="Sign and Submit"
                onClick={() => {
                  this.setState({ IsSign: true });
                }}
              />
            </div>
          </div>
        )}
      </div>
    </form>
  );

  render() {
    var role = localStorage.getItem('userRole');
    const { isTreatmentPlanSigned, isReadOnlyUserSession } = this.props;
    const {
      StartDate,
      IsSigned,
      IsSupervisorSigned,
      EndDate,
      SignedBy,
      SignedBySupervisor,
      SignatureContentHtml,
      IsEligibleForSign,
      IsClientEligibleForSign,
      IsUserSigned,
      IsUserSignRequired,
      IsIndividualCopyRecieved,
      ClientSignatureUrl,
    } = (isTreatmentPlanSigned && isTreatmentPlanSigned) || {};
    return (
      <>
        <h3 style={{ color: '#979999', textTransform: 'uppercase' }} className="mb-2">
          Treatment Plan Details
        </h3>
        <div className="new-div">
          <div className="row">
            <div className="col-md-12">
              <div className="row mt-2">
                {StartDate && (
                  <>
                    {IsSigned || IsSupervisorSigned ? (
                      <div className="col-12">
                        {' '}
                        {role == 'Clinical Supervisor' ? (
                          IsSupervisorSigned && (
                            <>
                              <span
                                className="text d-inline-block"
                                style={{ color: '#959595', width: '75px' }}
                              >
                                Start Date :
                              </span>
                              <span className="value font-weight-bold">{StartDate}</span>
                            </>
                          )
                        ) : (
                          <>
                            <span
                              className="text d-inline-block"
                              style={{ color: '#959595', width: '75px' }}
                            >
                              Start Date :
                            </span>
                            <span className="value font-weight-bold">{StartDate}</span>
                          </>
                        )}
                      </div>
                    ) : null}
                  </>
                )}
                {EndDate && (
                  <>
                    {IsSigned || IsSupervisorSigned ? (
                      <div className="col-12">
                        {role == 'Clinical Supervisor' ? (
                          IsSupervisorSigned && (
                            <>
                              <span
                                className="text d-inline-block"
                                style={{ color: '#959595', width: '75px' }}
                              >
                                End Date :
                              </span>
                              <span className="value font-weight-bold">{EndDate}</span>
                            </>
                          )
                        ) : (
                          <>
                            <span
                              className="text d-inline-block"
                              style={{ color: '#959595', width: '75px' }}
                            >
                              End Date :
                            </span>
                            <span className="value font-weight-bold">{EndDate}</span>
                          </>
                        )}
                      </div>
                    ) : null}
                  </>
                )}
                {this.props.isTreatmentPlanSigned && (
                  <>
                    {SignedBy || SignedBySupervisor ? (
                      <div className="col-12 mt-3">
                        {' '}
                        <h6>Signatures - </h6>
                        {/* {this.props.isTreatmentPlanSigned.SignedBy && (
                          <p>
                            Counselor : {this.props.isTreatmentPlanSigned.SignedBy} ({' '}
                            {this.props.isTreatmentPlanSigned.SignedDate}){' '}
                            {this.props.isTreatmentPlanSigned.IsIntitialSignatureCompleted && (
                              <span className="p-1" style={{ background: '#EBEFF2' }}>
                                INITIAL
                              </span>
                            )}
                          </p>
                          )}
                          
                        {this.props.isTreatmentPlanSigned.IsUserSigned &&
                          this.props.isTreatmentPlanSigned
                            .IsCompletedSignatureRequiredForCousoler && (
                            <p>Patient : {this.props.isTreatmentPlanSigned.UserSignedMessage} </p>
                            )}
                          
                        {this.props.isTreatmentPlanSigned.IsCounsolerDoneByCompletedSignature && (
                          <p>
                            Counselor :
                            {renderHTML(this.props.isTreatmentPlanSigned.CompletedSignedMessage)}{' '}
                            {this.props.isTreatmentPlanSigned
                              .IsCounsolerDoneByCompletedSignature && (
                              <span className="p-1" style={{ background: '#EBEFF2' }}>
                                COMPLETED
                              </span>
                            )}
                          </p>
                          )}
                          
                        {this.props.isTreatmentPlanSigned.IsSupervisorSigned && (
                          <p>
                            Supervisor :{' '}
                            {renderHTML(this.props.isTreatmentPlanSigned.SupervisorSignedMessage)}{' '}
                            {this.props.isTreatmentPlanSigned.IsSupervisorSigned && (
                              <span className="p-1" style={{ background: '#EBEFF2' }}>
                                INITIAL
                              </span>
                            )}
                          </p>
                          )}
                          
                        {this.props.isTreatmentPlanSigned.IsUserSigned &&
                          !this.props.isTreatmentPlanSigned
                            .IsCompletedSignatureRequiredForCousoler && (
                            <p>Patient : {this.props.isTreatmentPlanSigned.UserSignedMessage} </p>
                            )}
                          
                        {this.props.isTreatmentPlanSigned.IsSupervisorDoneByCompletedSignature && (
                          <p>
                            Supervisor :{' '}
                            {renderHTML(
                              this.props.isTreatmentPlanSigned.CompletedSupervisorSignedMessage,
                            )}{' '}
                            {this.props.isTreatmentPlanSigned.CompletedSupervisorSignedMessage && (
                              <span className="p-1" style={{ background: '#EBEFF2' }}>
                                COMPLETED
                              </span>
                            )}
                          </p>
                          )} */}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            {SignatureContentHtml && (
              <div className="col-md-12 treatment-plan-detail">
                {renderHTML(SignatureContentHtml)}
              </div>
            )}
            {IsClientEligibleForSign && !isReadOnlyUserSession && !this.props.isDeActivated && (
              <div className="col-md-12">
                <button className="btn py-2" onClick={this.toggleSignatureModal}>
                  Patient Signature
                </button>
              </div>
            )}
          </div>
        </div>

        {IsClientEligibleForSign && (
          <ValidateSignatureModal
            isSignatureModalOpen={this.state.isSignatureModalOpen}
            onCancel={this.toggleSignatureModal}
            title="Patient Signature"
            TreatmentPlanId={this.state.TreatmentPlanId}
            ClientId={this.props.clientId}
          />
        )}
        {IsEligibleForSign && (
          <>
            <div className="px-0 enrollment-form">{this.TPForm}</div>
            <ValidatePin
              validPinModalVisible={this.state.validPinModalVisible}
              resetValidPinModalVisible={this.resetValidPinModalVisible}
              FormType={'TreatmentPlan'}
              FormId={this.state.TreatmentPlanId}
              resetPinConfirmModalVisible={this.props.resetPinConfirmModalVisible}
              IsRealSignature={this.state.IsRealSignature}
            />
          </>
        )}

        {this.props.isTreatmentPlanSigned && !this.props.isTreatmentPlanSigned.IsSigned ? (
          <div>
            {role == 'Clinical Supervisor' ? (
              <>
                {this.props.isTreatmentPlanSigned && this.props.isTreatmentPlanSigned.IsSigned && (
                  <>
                    <div className="px-0 enrollment-form mt-3">3{this.TPForm}</div>
                    <ValidatePin
                      validPinModalVisible={this.state.validPinModalVisible}
                      resetValidPinModalVisible={this.resetValidPinModalVisible}
                      FormType={'TreatmentPlan'}
                      FormId={this.state.TreatmentPlanId}
                      resetPinConfirmModalVisible={this.props.resetPinConfirmModalVisible}
                      IsRealSignature={this.state.IsRealSignature}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {/* {this.props.isTreatmentPlanSigned &&
                  !this.props.isTreatmentPlanSigned.IsSupervisorSigned && (
                    <div className="px-0 enrollment-form mt-3">4{this.TPForm}</div>
                  )}

                <ValidatePin
                  validPinModalVisible={this.state.validPinModalVisible}
                  resetValidPinModalVisible={this.resetValidPinModalVisible}
                  FormType={'TreatmentPlan'}
                  FormId={this.state.TreatmentPlanId}
                  resetPinConfirmModalVisible={this.props.resetPinConfirmModalVisible}
                  IsRealSignature={this.state.IsRealSignature}
                /> */}
              </>
            )}
          </div>
        ) : null}

        {role == 'Clinical Supervisor' ? (
          <>
            {((this.props.isTreatmentPlanSigned && this.props.isTreatmentPlanSigned.IsSigned) ||
              true) && (
              <div className="super-sign">
                <div>
                  <div className="px-0 enrollment-form mt-3">
                    {this.props.isTreatmentPlanSigned &&
                    this.props.isTreatmentPlanSigned.IsSupervisorSigned ? (
                      <>
                        <div></div>

                        <p className="d-none">
                          {this.props.isTreatmentPlanSigned.SupervisorSignedMessage &&
                            renderHTML(this.props.isTreatmentPlanSigned.SupervisorSignedMessage)}
                        </p>
                      </>
                    ) : (
                      <>{/* 5{this.TPForm} */}</>
                    )}
                  </div>
                  <ValidatePin
                    validPinModalVisible={this.state.validPinModalVisible}
                    resetValidPinModalVisible={this.resetValidPinModalVisible}
                    FormType={'TreatmentPlan'}
                    FormId={this.state.TreatmentPlanId}
                    resetPinConfirmModalVisible={this.props.resetPinConfirmModalVisible}
                    IsRealSignature={this.state.IsRealSignature}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {' '}
            {this.props.isTreatmentPlanSigned &&
              this.props.isTreatmentPlanSigned.IsSupervisorSigned && (
                <div className="super-sign">
                  <div className="d-none">
                    <div className="px-0 enrollment-form mt-5">
                      {this.props.isTreatmentPlanSigned.SupervisorSignedMessage &&
                        renderHTML(this.props.isTreatmentPlanSigned.SupervisorSignedMessage)}
                    </div>
                  </div>
                </div>
              )}
          </>
        )}

        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.backButton();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </>
    );
  }
}
const mapStateToProps = ({
  validate_staff_pin,
  upsert_treatment_plan,
  get_treatment_plan,
  patient_signature_save,
}) => {
  var { data: validateDataPin } = validate_staff_pin;
  var { data: upsertTreatmentPlanSucessData, isFetching } = upsert_treatment_plan;
  var { data: signatureData } = patient_signature_save;

  const { data, error } = get_treatment_plan;
  let validateStaffPinData = {};
  let utDataSuccess = false;
  let isSignatureValidated = false;
  let signatureValidatedMsg = null;
  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (upsertTreatmentPlanSucessData && upsertTreatmentPlanSucessData.success) {
    utDataSuccess = upsertTreatmentPlanSucessData.success;
  }
  if (signatureData && signatureData.success) {
    // signatureValidatedMsg = data.result && data.result.Msg;
    isSignatureValidated = signatureData.success;
  }
  return {
    validateStaffPinData,
    utDataSuccess,
    isFetching,
    signatureValidatedMsg,
    isSignatureValidated,
  };
};

const ReduxTreatmentPlanSubmissionForm = reduxForm({
  form: 'TreatmentPlanSubmissionForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(TreatmentPlanSubmissionForm));

export { ReduxTreatmentPlanSubmissionForm };
