import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TimeTravel from '../../../timelineComponent';

class ProspectTimeTravel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { ClientId, PreItakeSurveyId } = this.props;
    return (
      <div className="prospect-time-travel card-box-shadow">
        <TimeTravel hideHeader={true} ProspectTimeline={true} StudentId={ClientId} />
      </div>
    );
  }
}
export default connect()(withRouter(ProspectTimeTravel));
