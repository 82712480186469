import React, { useState } from 'react';
import { Button, Popover, Space } from 'antd';

export const PopoverMenu = ({ OptionData, title, placement, trigger }) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibleChange = visible => {
    setIsVisible(visible);
  };
  let content = (
    <div className="sort-by-list" onClick={() => setIsVisible(false)}>
      {OptionData}
    </div>
  );
  return (
    <div>
      <Popover
        overlayClassName="alert-box-style"
        content={content}
        title={title ? title : false}
        trigger={trigger ? trigger : 'click'}
        placement={placement}
        // arrowPointAtCenter
        class="alert-sort"
        open={isVisible}
        onOpenChange={handleVisibleChange}
      >
        <img src="images/icons/3dot.svg" style={{ cursor: 'pointer' }} />
      </Popover>
    </div>
  );
};
