import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoComplete,
  ReduxCheckbox,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import AntdModal from '../Components/CustomModal';
import Autocomplete from 'react-autocomplete';
import Loader, { Loader2 } from '../Components/Loader';
import moment from 'moment';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  submit,
} from 'redux-form';
import PageTitle from '../Components/PageTitle';

import {
  appAnnouncementUpsert,
  appAnnouncementListGetAll,
  appAnnouncementGet,
  listCombos,
} from '../store/actions';

import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditorComponent from 'react-ckeditor-component';

class ActivityBase extends React.Component {}

const Validator = {
  // required: value =>
  //   value ?  undefined : 'Required',
  characterLimit: value =>
    value ? (value.length > 100 ? 'cannot exceed 100 characters' : undefined) : 'Required',
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

class AppAnnouncementform extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      announcementData: {},
      isModalOpen: false,
      header: '',
      isRemoveModelOpen: false,
      getAppAnnouncementHistoryState: null,
    };
  }

  getAnnouncementId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var announcementId = param.get('appannouncementId') || param.get('appannouncementId');
    if (announcementId) {
      return (announcementId = parseInt(announcementId));
    } else {
      announcementId = -1;
    }
    return announcementId;
  }

  getIndexId = () => { 
      var param = new URLSearchParams(this.props.history.location.search);
    var indexId = param.get('indexId');
      if (indexId) {
        return (indexId = parseInt(indexId));
      } else {
        indexId = -1;
      }
      return indexId;

  }

  

  componentWillReceiveProps({
    GetAnnouncementData,
    existingAnnouncement,
    getAppAnnouncementHistory,
  }) {
    const { AppAnnouncementId, CenterId, HtmlContent, IsPublished, Title } = GetAnnouncementData;
    if (GetAnnouncementData && this.getAnnouncementId() > 0) {
      var announcement = {
        title: Title,
        htmlContent: HtmlContent,
        isPublished: IsPublished,
      };
      this.setState({ announcementData: announcement });
    }
    if (
      existingAnnouncement &&
      JSON.stringify(existingAnnouncement) != JSON.stringify(this.props.existingAnnouncement)
    ) {
      const {
        AppAnnouncementId: appId,
        Title: appTitle,
        HtmlContent: appContent,
        PublishedUser,
      } = existingAnnouncement;

      var data = {
        AppAnnouncement: [
          {
            AppAnnouncementId: existingAnnouncement.AppAnnouncementId,
          },
        ],
      };
      var announcementData = {
        jsonInput: JSON.stringify(data),
      };

      this.setState({ appAnnouncementId: appId, publishedUser: PublishedUser }, () => {
        this.props.dispatch(appAnnouncementGet(announcementData));
      });
    }
    if (
      getAppAnnouncementHistory &&
      JSON.stringify(getAppAnnouncementHistory) !=
        JSON.stringify(this.props.getAppAnnouncementHistory)
    ) {
      let indexId = this.getIndexId();
      if (indexId>=0) {
        let filteredAnnouncement = getAppAnnouncementHistory.filter(
          (item, index) => index == indexId,
        );
        const { AppAnnouncementId, CenterId, HtmlContent, IsPublished, Title } =
          filteredAnnouncement && filteredAnnouncement[0];
        var announcement = {
          title: Title,
          htmlContent: HtmlContent,
          isPublished: IsPublished,
        };
        this.setState({
          getAppAnnouncementHistoryState: getAppAnnouncementHistory,
          announcementData: announcement,
        });
      } else { 
          this.setState({
            getAppAnnouncementHistoryState: getAppAnnouncementHistory,
          });
      }
      

    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  componentDidMount() {
    this.props.dispatch(
      appAnnouncementListGetAll({ filter: null, searchStr: this.state.searchStr }),
    );

    if (this.getAnnouncementId() > 0) {
      var data = {
        AppAnnouncement: [
          {
            AppAnnouncementId: this.getAnnouncementId(),
          },
        ],
      };
      var announcementData = {
        jsonInput: JSON.stringify(data),
      };
      this.props.dispatch(appAnnouncementGet(announcementData));
    } else {
      // this.props.dispatch(announcementGetJsonReset());
    }
  }

  // toggle = header => {
  //   this.setState(prevState => ({
  //     isModelOpen: !prevState.isModelOpen,
  //     header,
  //   }));
  // };
  // Removetoggle = () => {
  //   this.setState(prevState => ({
  //     isRemoveModelOpen: !prevState.isRemoveModelOpen,
  //   }));
  // };

  onSubmit(values) {
    //
    //alert(JSON.stringify(values));
    const {
      abstract,
      centerId,
      endDate,
      imageUrl,
      isMain,
      startDate,
      tags,
      title,
      updatedBy,
      link,
    } = values;

    // var announcementId = null;

    // var paramAnnouncementId = this.getAnnouncementId();
    // if (paramAnnouncementId && paramAnnouncementId > 0) {
    //   announcementId = paramAnnouncementId;
    // }

    var data = {
      Announcement: [
        {
          Abstract: abstract,
          // AnnouncementId: announcementId,
          CenterId: centerId,
          EndDate: endDate,
          ImageUrl: imageUrl,
          IsMain: isMain,
          StartDate: startDate,
          Tags: tags,
          Title: title,
          UpdatedBy: updatedBy,
          Link: link,
        },
      ],
    };
    var announcementData = {
      // AnnouncementId: announcementId,
      jsonInput: JSON.stringify(data),
    };
    // this.props.dispatch(announcementUpsert(announcementData));
  }

  render() {
    // const { GetNewsData } = this.props;

    return (
      <React.Fragment>
        <PageTitle TitleText="App Announcement" />
        <ReduxAnnouncementForm
          isFetching2={this.props.isFetching2}
          onSubmit={this.onSubmit.bind(this)}
          initialValues={this.state.announcementData}
          // getAnnouncementId={this.getAnnouncementId()}
          onCancel={() => this.props.history.push('/manage/announcements')}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/announcements')
          }}
          history={this.props.history}
          htmlContent={this.state.announcementData.htmlContent}
          isFetching={this.props.isFetching}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/announcements');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(announcementDelete({ AnnouncementId: this.getAnnouncementId() }));
            // this.props.history.push('/manage/announcements');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            this.props.history.push('/manage/announcements');
          }}
        />
      </React.Fragment>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: 'Type Here',
      showLoader: false,
      isModelOpen123: false,
    };
  }
  getAnnouncementId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var announcementId = param.get('appannouncementId') || param.get('appannouncementId');
    if (announcementId) {
      return (announcementId = parseInt(announcementId));
    } else {
      announcementId = -1;
    }
    return announcementId;
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen123: !prevState.isModelOpen123,
      header,
    }));
  };

  componentWillReceiveProps({ isSuccess, htmlContent }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.toggle(Upsert_Sucess_Msg);
    }
    if (htmlContent != this.props.htmlContent) {
      this.setState({ content: htmlContent });
    }
  }
  onAnnouncementSubmit = values => {
                                     const { title, isPublished } = values;
                                     var announcementId = null;

                                     var paramAnnouncementId = this.getAnnouncementId();

                                     if (paramAnnouncementId && paramAnnouncementId > 0) {
                                       announcementId = paramAnnouncementId;
                                     }
                                     var data = {
                                       AppAnnouncement: [
                                         {
                                           AppAnnouncementId: announcementId,
                                           Title: title,
                                           HtmlContent: this.state.content,
                                           IsPublished: isPublished,
                                         },
                                       ],
                                     };
                                     // if (this.getAnnouncementId() > 0) {
                                     //   data.News[0].NewsId = this.getAnnouncementId();
                                     // }
                                     var announcementData = {
                                       AppAnnouncementId: announcementId,
                                       jsonInput: JSON.stringify(data),
                                     };
                                     //In new implimentation i am not sending AppAnnouncementId if something goes wrong i need to send AppAnnouncementId inside json.
                                     this.props.dispatch(appAnnouncementUpsert(announcementData));
                                     // this.setState({ showLoader: true });
                                   };
  updateContent = newContent => {
    this.setState({
      content: newContent,
    });
  };

  onChange = evt => {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  onBlur(evt) {
    // console.log('onBlur event called with event info: ', evt);
  }

  afterPaste(evt) {
    // console.log('afterPaste event called with event info: ', evt);
  }

  getIndexId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var indexId = param.get('indexId');
    if (indexId) {
      return (indexId = parseInt(indexId));
    } else {
      indexId = -1;
    }
    return indexId;
  };

  render() {
    const { isFetching, isFetching2 } = this.props;
    return (
      <div class="main enrollment-page  mb-5">
        <div class="container-fluid">
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-8 col-lg-9 text-right"></div>
          </div>
          <form onSubmit={this.props.handleSubmit(this.onAnnouncementSubmit)}>
            <Loader2 loading={isFetching || isFetching2} />
            <Link to="/manage/Announcements" class="back-link col-12">
              <img src="images/back-arrow.svg" style={{ marginTop: '10px' }} /> Back
            </Link>
            <div class="mb-4 mt-3 col-12 col-md-12 col-lg-12 d-flex justify-content-between">
              <h3 class="form-main-head mb-3 mb-md-0">
                {this.getIndexId() >= 0 ? 'Edit App Announcement' : 'Add App Announcement'}
              </h3>
              <div className="d-flex justify-content-between">
                <button
                  class="btn btn-outline-primary"
                  onClick={() => {
                    this.props.history.push('/manage/Announcements');
                  }}
                >
                  Cancel
                </button>
                {/* )} */}
                &nbsp;
                <button type="submit" value="submit" class="btn">
                  Submit
                </button>
              </div>
            </div>

            <div class="row  mb-2">
              <div class="col-12 mb-4">
                <div class="card border-0 rounded-0 ">
                  <div class="card-body enrollment-form">
                    <div class="row pb-5 pl-3 pr-3 pt-3">
                      <div class="col-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <Field
                            name="title"
                            type="text"
                            errorMessage="Title Required"
                            component={ReduxInput}
                            validate={[Validator.characterLimit]}
                            fieldName={'Title'}
                          />
                          <label class="placeholder-label">Title*</label>
                        </div>
                      </div>

                      <div class="col-12 col-md-12 col-lg-12">
                        <CKEditorComponent
                          activeClass="p10"
                          content={this.state.content}
                          events={{
                            blur: this.onBlur,
                            afterPaste: this.afterPaste,
                            change: this.onChange,
                          }}
                          validate={[Validator.required]}
                        />
                      </div>
                      <div class="col-12 col-md-12 col-lg-12 mt-3">
                        <div class="form-group">
                          <Field
                            className="mr-2"
                            name="isPublished"
                            component={ReduxCheckbox}
                            fieldName={'isPublished'}
                          />
                          <label class="placeholder-label" style={{ top: '5px' }}>
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <CustomModal
            isOpen={this.state.isModelOpen123}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              this.props.history.push('/manage/Announcements');
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ app_announcement_upsert }) => {
  var { data, isFetching, error } = app_announcement_upsert;
  let isSuccess = false;
  if (data && data.success) {
    isSuccess = data.success;
  }
  return { isSuccess: isSuccess };
};

const ReduxAnnouncementForm = reduxForm({
  form: 'AppAnnouncementform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(Example)));

const selector = formValueSelector('AppAnnouncementform');

const mapStateToPropsParent = ({ app_announcement_get, app_announcement_list_get_all }) => {
  var { data: getAnnouncementData, isFetching, error } = app_announcement_get;
  var { data: appData ,isFetching:isFetching2} = app_announcement_list_get_all;

  let existingAnnouncement;
  let getAppAnnouncementHistory;

  if (app_announcement_get && app_announcement_get.data) {
    getAppAnnouncementHistory = app_announcement_get.data.AppAnnouncement_History;
  }
  if (appData && appData.AppAnnouncement) {
    existingAnnouncement = appData.AppAnnouncement[0];
  }

  return {
    GetAnnouncementData:
      app_announcement_get && app_announcement_get.data && app_announcement_get.data.AppAnnouncement
        ? app_announcement_get.data.AppAnnouncement[0]
        : {},
    isFetching: isFetching ? isFetching : false,
    existingAnnouncement,
    getAppAnnouncementHistory,
    isFetching2:isFetching2 ? isFetching2 : false,
  };
};

export default withRouter(connect(mapStateToPropsParent)(AppAnnouncementform));
