import React, { Component } from 'react';
import hostGrabber from '../util/hostGrabber';

const PageBackAction = props => {
  const { history, pageBackText, onClick } = props;
  return (
    <div className="">
      <a class="back-link" onClick={onClick ? onClick : history.gogoBack()}>
        <img src="images/back-arrow.svg" /> {pageBackText}
      </a>
    </div>
  );
};

export default PageBackAction;
