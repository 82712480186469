import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import AntdModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import {
  studentBanner,
  riskChartGet,
  augmentedSummaryGet,
  preIntakeSurveyAnswersGet,
} from '../../../store/actions';
import AugmentedSummary from '../AugmentedSummary';
import RiskChartLegacy from '../RiskChartLegacy';
import ClientScreeningCard from '../ClientScreeningCard';
import UpdateHistory from '../UpdateHistory';
import Banner from '../BannerLegacy';
import SetReminderModal from './SetReminderModal';
import { CommonLicenceScreens } from '../../licenceScreens';
import TimeTravel from '../../timelineComponent';
import PageTitle from '../../../Components/PageTitle';

class PreIntakeSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAugmentedAIDrawerOpen: false,
      riskResponse: null,
      profileData: {},
    };
  }

  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }
  getSurveyId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let surveyid = param.get('surveyid');
    if (surveyid) {
      surveyid = parseInt(surveyid);
    } else {
      surveyid = null;
    }
    return surveyid;
  }
  getRisk = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            IsDefaultPreIntakeSurvey: true,
          },
        ],
      }),
    };
    Axios.post('Survey/ClientRiskGet', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            riskResponse: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  componentDidMount() {
    this.getRisk();
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
          },
        ],
      }),
    };
    this.props.dispatch(
      preIntakeSurveyAnswersGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.getIntakeId(),
            },
          ],
        }),
      }),
    );
    this.props.dispatch(studentBanner(data));
    // this.props.dispatch(augmentedSummaryGet(data));
    // this.props.dispatch(riskChartGet(data));

    // if (this.getIntakeId() > 0) {
    //   this.props.dispatch(studentBanner(this.getIntakeId()));
    // }
    // var result = ProcessCombos('ReferenceSource,Country,Filter_StateProvince');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
  }

  toggleDrawer = () => {
    this.setState({ isAugmentedAIDrawerOpen: !this.state.isAugmentedAIDrawerOpen });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const {
      StudentId,
      PrefferedName,
      PrimaryPhone,
      Email,
      StateOrProvince,
      PreIntakeClientStatus,
      City,
      PreItakeSurveyId,
    } = this.props.studentInfo;
    return (
      <React.Fragment>
        <PageTitle TitleText="Prospect" />
        <div class="main students-page mb-5">
          <div class="container-fluid">
            {/* <div class="row top-table-search align-items-center">
              <div class="col-12 col-md-4 col-lg-4">
                <a
                  class="back-link"
                  onClick={
                    () => this.props.history.goBack()
                    // this.props.history.push({
                    //   pathname: '/students',
                    // })
                  }
                >
                  <img src="images/back-arrow.svg" /> Back
                </a>
              </div>
            </div> */}
            <div className="row">
              <div className="col-4">
                <span className="title">Summary</span>
              </div>
              <div className="col-8">
                <div className="d-flex justify-content-end">
                  <AntdModal
                    modalWidth={800}
                    // style={{ top: 20 }}
                    ModalButton={({ onClick }) => (
                      <button
                        data-target="#set-reminder-model"
                        className="btn btn-outline-primary mr-2"
                        onClick={() => {
                          onClick();
                        }}
                      >
                        Set Reminder
                      </button>
                    )}
                    ModalContent={props => (
                      <SetReminderModal
                        {...props}
                        ClientId={this.getIntakeId()}
                        toggle={this.toggle}
                        SurveyId={PreItakeSurveyId}
                      />
                    )}
                  />

                  <input
                    type="button"
                    className="btn"
                    value="Done"
                    onClick={() => {
                      // this.props.history.push(
                      //   `/clients/pre-intake/dashboard?id=${this.getIntakeId()}`,
                      // );
                      this.props.history.push('/clients/pre-intake-legacy');
                      // this.props.history.replace('/clients/pre-intake');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-4 pt-2">
                <Banner
                  ClientId={this.getIntakeId()}
                  EditActionUrl={`/pre-intake-form/?step=demographics&studentid=${this.getIntakeId()}`}
                />
              </div>
            </div>
            <div class="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                {this.props.AugmentedAIStatusData && (
                  <CommonLicenceScreens
                    StatusData={this.props.AugmentedAIStatusData}
                    moduleName={'AugmentedAI'}
                  />
                )}
                <>
                  {this.props.AugmentedAIStatusData &&
                    this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
                    this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
                    this.props.AugmentedAIStatusData.LicenseStatusId != 5 && (
                      <>
                        <AugmentedSummary
                          ClientId={this.getIntakeId()}
                          SurveyId={PreItakeSurveyId}
                        />
                      </>
                    )}
                </>
              </div>
              <RiskChartLegacy ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />
              <div className="col-sm-12">
                <ClientScreeningCard ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />
              </div>
              {/* <div className="col-sm-12">
                <UpdateHistory ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />
              </div> */}
              <div className="prospect-time-travel">
                <TimeTravel
                  hideHeader={true}
                  ProspectTimeline={true}
                  StudentId={this.getIntakeId()}
                  SurveyId={PreItakeSurveyId}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { student_banner, user_feature_flag_get } = state;
  const { data, isFetching, error } = student_banner;
  const { data: fFData } = user_feature_flag_get;

  let studentInfo = {};
  let preItakeSurveyId = null;
  let AugmentedAIStatusData = null;

  if (data) {
    studentInfo = data;
    preItakeSurveyId = data.PreItakeSurveyId;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }

  return {
    studentInfo,
    preItakeSurveyId,
    AugmentedAIStatusData,
  };
};

export default connect(mapStateToProps)(PreIntakeSummary);
