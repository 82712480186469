import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, Table } from 'antd';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, reset } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../../../../../component/custom-redux-components';
import Validator from '../../../../../../Validation';

const { Panel } = Collapse;

const CaseForm = ({ fields, name, meta: { touched, error }, change, disable, existingRows }) => {
  if (fields.length == 0) {
    fields.push({});
  }
  const allowEdit = !disable;
  return (
    <div className="row">
      <div className="col-6">
        <h5 class="d-inline-flex mb-0 mr-3">Case</h5>
      </div>
      <div class="col-6 text-right pt-1 pb-1">
        {allowEdit && (
          <a class="add-contact" onClick={() => fields.push({})}>
            {fields.length >= 1 && <label>+ Add Another Case</label>}
            {fields.length == 0 && <label>+ Add Case</label>}
          </a>
        )}
      </div>
      <div className="col-12">
        {fields.map((options, index) => {
          let sNo = index + 1;
          return (
            <div className="case-row" key={options}>
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <h6>Case {sNo}</h6>
                  </div>
                  <div className="col-6 text-right">
                    {allowEdit && fields.length > 1 && (
                      <a class="delete-contact" onClick={() => fields.remove(index)}>
                        <i class="fas fa-trash" />
                      </a>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group mb-1">
                          <Field
                            name={`${options}.CaseIf`}
                            type="text"
                            component={ReduxInput}
                            fieldName={'If'}
                            validate={Validator.required}
                            style={{ background: '#F8F8F8' }}
                            disabled={disable}
                          />
                          <label class="placeholder-label">if {sNo}*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group mb-1">
                          <Field
                            name={`${options}.CaseThen`}
                            type="text"
                            component={ReduxInput}
                            fieldName={'Then'}
                            validate={Validator.required}
                            style={{ background: '#F8F8F8' }}
                            disabled={disable}
                          />
                          <label class="placeholder-label">Then {sNo}*</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const OptionForm = ({
  fields,
  name,
  meta: { touched, error },
  change,
  disable,
  existingRows,
  isButtonVisible,
}) => {
  if (fields.length == 0) {
    fields.push({});
  }
  const allowEdit = !disable;
  return (
    <>
      <div className="row buildServiceBundle">
        <div className="col-12 col-md-12 col-lg-12 my-1">
          <div className="conditions-form">
            <Collapse
              // expandIcon={({ isActive }) => (
              //   <RightCircleOutlined
              //     rotate={isActive ? -90 : 90}
              //     style={{ fontSize: '20px', marginTop: '4px', color: 'rgba(0,0,0,0.7)' }}
              //   />
              // )}
              defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
              // onChange={onChange}
            >
              {fields.length > 0 ? (
                fields.map((options, index) => {
                  let sNo = index + 1;
                  return (
                    <Panel
                      key={index}
                      className="bundle-panel-border"
                      header={
                        <>
                          <div className="row">
                            <div className="col-md-3 panel-header-text">{`Condition ${index +
                              1}`}</div>
                            <div className="col-md-9 text-right d-flex">
                              {/* {allowEdit && (
                                    <div class="col-6 text-right pt-1 pb-1">
                                      <a class="add-contact" onClick={() => fields.push({})}>
                                        {fields.length >= 1 && (
                                          <label>+ Add Another Term Category</label>
                                        )}
                                        {fields.length == 0 && <label> + Add Term Category</label>}
                                      </a>
                                    </div>
                                  )} */}

                              {isButtonVisible && (
                                <>
                                  {!disable && (
                                    <button
                                      type="button"
                                      class="btn-newCon"
                                      style={{
                                        position: 'unset',
                                        width: 'unset',
                                      }}
                                      onClick={event => {
                                        event.stopPropagation();
                                        fields.push();
                                      }}
                                    >
                                      + Add{' '}
                                      {fields.length == 0
                                        ? 'Term Condition'
                                        : 'Another Term Condition'}
                                    </button>
                                  )}
                                </>
                              )}
                              {fields.length > 1 && (
                                <div className="text-right">
                                  {isButtonVisible && (
                                    <>
                                      {!disable && (
                                        <a
                                          class="col-1 delete-contract"
                                          onClick={() => {
                                            if (fields.length && fields.length > 0) {
                                              fields.remove(index);
                                            }
                                          }}
                                        >
                                          <i class="fas fa-trash" />
                                        </a>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    >
                      <>
                        <div className="row mb-2" key={options}>
                          <div className="col-12">
                            <div className="card border-0">
                              <div className="row card-body">
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="form-group mb-1">
                                    <Field
                                      name={`${options}.Condition`}
                                      type="text"
                                      component={ReduxInput}
                                      fieldName={'Condition'}
                                      validate={Validator.required}
                                      disabled={disable}
                                      isButtonVisible={isButtonVisible}
                                    />
                                    <label class="placeholder-label">Condition {sNo}*</label>
                                  </div>
                                </div>
                                {allowEdit && fields.length > 1 && (
                                  <div className="col-12 col-md-6 col-lg-8 text-right">
                                    <a class="delete-contact" onClick={() => fields.remove(index)}>
                                      <i class="fas fa-trash" />
                                    </a>
                                  </div>
                                )}
                                <div className="col-12">
                                  <FieldArray
                                    name={`${options}.ConditionCase`}
                                    component={CaseForm}
                                    disable={disable}
                                    isButtonVisible={isButtonVisible}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Panel>
                  );
                })
              ) : (
                <div class="row"></div>
              )}
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};
// class Conditions extends Component {
//   render() {
//     const { name, disable, isButtonVisible } = this.props;
//     return (
//       <div className="row">
//         <div className="col-12">
//           <FieldArray
//             name={'Conditions'}
//             component={OptionForm}
//             disable={disable}
//             isButtonVisible={isButtonVisible}
//           />
//         </div>
//       </div>
//     );
//   }
// }

export default OptionForm;
