import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Tooltip } from 'antd';
import ReduxDocumentsModal from './documentsModal';
import { payerPortalRequestGet } from '../../../../store/actions';
import { Loader2 } from '../../../../Components/Loader';
import DownloadDocument from '../../../customScreens/downloadDocument';
import DocumentsUploaded from './documentsUploaded';
import DocumentsRequested from './documentsRequested';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimelineModalOpen: false,
      docUploaded: [],
      docRequested: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(
      payerPortalRequestGet({
        Json: JSON.stringify({
          Request: [
            {
              SmartContractId: this.props.contractId,
              Id: null,
              RequestType: '30212 ',
            },
          ],
        }),
      }),
    );
  }

  componentWillReceiveProps({ downloadUrl, isSuccess, documentReq }) {
    if (this.state.isDownloadClicked && downloadUrl && downloadUrl != this.props.downloadUrl) {
      window.open(downloadUrl);
    }
    if (documentReq && documentReq != this.props.documentReq) {
      let docReq = [];
      let docUpload = [];
      documentReq &&
        documentReq.map((item, index) => {
          if (item.Id) {
            docUpload.push(item);
          } else {
            docReq.push(item);
          }
        });
      

      this.setState({
        docUploaded: [...docUpload],
        docRequested: [...docReq],
      });
    }
  }
  render() {
    const {
      documentReq,
      isFetching,
      downloadUrl,
      isSuccess,
      clientId,
      tabsCount,
      goToPreviuos,
      currentTab,
      disable,
      goToNext,
    } = this.props;
    const { docUploaded, docRequested } = this.state;
    return (
      <div>
        {docUploaded && (
          <DocumentsUploaded
            documentUpload={docUploaded}
            downloadUrl={downloadUrl}
            isSuccess={isSuccess}
            dispatch={this.props.dispatch}
            contractId={this.props.contractId}
            clientId={clientId}
          />
        )}
        {docRequested && (
          <DocumentsRequested
            docRequested={docRequested}
            contractId={this.props.contractId}
            clientId={clientId}
          />
        )}
        <div class="col-12 text-right mt-3">
          {(currentTab > 0 || currentTab + 1 == tabsCount) && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="button"
              onClick={goToNext}
              class="btn btn-eForm-Next"
              disabled={disable}
              style={{ padding: '10px 39px' }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  payer_portal_request_get,
  download_client_document,
  payer_portal_request_upsert,
}) => {
  const { data, isFetching } = payer_portal_request_get;
  const { data: upsertData } = payer_portal_request_upsert;
  const {
    data: DownloadData,
    isFetching: isFetchingDownload,
    error: errorDownload,
  } = download_client_document;
  let documentReq = [];
  let documentError;
  let downloadUrl;

  if (data && data.Request) {
    documentReq = data.Request;
  }

  if (DownloadData && DownloadData.success) {
    downloadUrl = DownloadData.result;
  } else if (errorDownload) {
    documentError = errorDownload;
  }

  return {
    documentReq,
    isFetching,
    downloadUrl,
    documentError,
    isSuccess: upsertData && upsertData.success,
  };
};

export default connect(mapStateToProps)(withRouter(Documents));
