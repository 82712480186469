import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Checkbox, Drawer } from 'antd';
import renderHTML from 'react-render-html';
import {
  augmentedSummaryGet,
  GPT3EnrollSurveys,
  augmentedClientInsightsGet,
  getMultipleSurveyAugmentedSummary,
  resetAugmentedClientInsightsGet,
  resetGetMultipleSurveyAugmentedSummary,
  augmentWithAI,
  resetAugmentWithAI,
  augmentWithAIGet,
  resetAugmentWithAIGet,
} from '../../store/actions';
import AIAugmentedSummary from '../customScreens/surveyResponse/AIAugmentedSummary';
import Loader, { Loader2 } from '../../Components/Loader';
import AntdModal from '../../Components/CustomModal';

class EnrolledDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAIAugmentedSummaryOpen: false,
    };
  }
  componentDidMount() {
    // let data = {
    //   Json: JSON.stringify({
    //     Student: [
    //       {
    //         StudentId: this.props.ClientId,
    //       },
    //     ],
    //   }),
    // };
    // this.props.dispatch(augmentedSummaryGet(data));
    // this.props.dispatch(
    //   GPT3EnrollSurveys({
    //     Json: JSON.stringify({
    //       StudentId: this.props.ClientId,
    //     }),
    //   }),
    // );
    // this.props.dispatch(resetAugmentedClientInsightsGet());
  }
  // componentWillReceiveProps({ LatestSurveyIds, isAugmentedWithAI }) {
  //   if (LatestSurveyIds && LatestSurveyIds != this.props.LatestSurveyIds) {
  //     this.setState({
  //       ActiveSurveyIds: LatestSurveyIds,
  //       isAIAugmentedSummaryOpen: true,
  //     });
  //   }
  //   if (isAugmentedWithAI && isAugmentedWithAI != this.props.isAugmentedWithAI) {
  //     this.props.dispatch(
  //       augmentWithAIGet({
  //         Json: JSON.stringify({
  //           Survey: [
  //             {
  //               StudentId: this.props.ClientId,
  //               SurveyIds: null,
  //               GPT3QuestionAnswerIds: null,
  //               AllResponses: true,
  //             },
  //           ],
  //         }),
  //       }),
  //     );
  //   }
  // }

  // handelAISurvey = item => {
  //   this.setState({
  //     // SurveyIds: item.SurveyIds,
  //     ActiveSurveyIds: item.SurveyIds,
  //     isAIAugmentedSummaryOpen: true,
  //   });
  //   this.props.dispatch(
  //     augmentedClientInsightsGet({
  //       StudentId: this.props.ClientId,
  //       UserGuid: this.props.UserGuid,
  //       SurveyId: this.props.SurveyId,
  //       SurveyIds: item.SurveyIds,
  //       GPT3QuestionId: item.GPT3QuestionId,
  //     }),
  //   );
  // };
  // getSelectedSurveys = SurveyIds => {
  //   let data = {
  //     StudentId: this.props.ClientId,
  //     UserGuid: null,
  //     Surveys: SurveyIds.map(survey => {
  //       return {
  //         SurveyIds: survey.SurveyIds,
  //         GPT3QuestionId: survey.GPT3QuestionId,
  //       };
  //     }),
  //   };
  //   this.props.dispatch(augmentWithAI(data));
  //   // if (SurveyIds.length == 1) {
  //   //   this.props.dispatch(
  //   //     augmentedClientInsightsGet({
  //   //       StudentId: this.props.ClientId,
  //   //       SurveyIds: SurveyIds[0].SurveyIds,
  //   //     }),
  //   //   );
  //   //   this.props.dispatch(resetGetMultipleSurveyAugmentedSummary());
  //   // } else {
  //   //   this.props.dispatch(getMultipleSurveyAugmentedSummary(data));
  //   //   this.props.dispatch(resetAugmentedClientInsightsGet());
  //   // }
  // };
  render() {
    const {
      augmentedSummary,
      isFetching,
      isEnrolled,
      EnrollSurveysList,
      LatestSurveyIds,
      isFetchingGPTSurveys,
      AugmentedWithAI,
    } = this.props;
    const me = this;
    const { ActiveSurveyIds } = this.state;
    return (
      <div class="card border-0 mb-3">
        <div class="card-body p-0">
          <Loader2 loading={isFetchingGPTSurveys} />

          <div className="drawer-summary">
            <AIAugmentedSummary
              ClientId={this.props.ClientId}
              SurveyIds={this.state.ActiveSurveyIds}
              // toggleDrawer={this.toggleDrawer}
              IsUseSummary={true}
              isActionTrigered={() => {
                this.props.dispatch(
                  augmentedSummaryGet({
                    Json: JSON.stringify({
                      Student: [
                        {
                          StudentId: this.props.ClientId,
                        },
                      ],
                    }),
                  }),
                );
                this.props.dispatch(
                  GPT3EnrollSurveys({
                    Json: JSON.stringify({
                      StudentId: this.props.ClientId,
                    }),
                  }),
                );
                // this.toggleDrawer();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    augmented_summary_get,
    gpt3_enroll_surveys,
    augment_with_ai,
    augment_with_ai_get,
  } = state;
  const { data: AugmentedSummaryData, isFetching } = augmented_summary_get;
  const { data: EnrollSurveysData, isFetching: isFetchingGPTSurveys } = gpt3_enroll_surveys;
  const { data: AugmentWithAIData, isFetching: isFetchingAugmentWithAI } = augment_with_ai;
  const {
    data: AugmentWithAIGetData,
    isFetching: isFetchingAugmentWithAIGet,
  } = augment_with_ai_get;

  let augmentedSummary = null;
  let isHistory = false;
  let EnrollSurveysList = null;
  let LatestSurveyIds = null;
  let isAugmentedWithAI = false;
  let AugmentedWithAI;

  if (AugmentedSummaryData && AugmentedSummaryData.Summary) {
    augmentedSummary = AugmentedSummaryData.Summary[0];
    isHistory = AugmentedSummaryData.History && AugmentedSummaryData.History.length > 0;
  }
  if (EnrollSurveysData && EnrollSurveysData.StudentId) {
    EnrollSurveysList = EnrollSurveysData.Surveys;
    LatestSurveyIds = EnrollSurveysData.SurveyIds;
  }
  if (AugmentWithAIData && AugmentWithAIData.success) {
    isAugmentedWithAI = true;
    // if (result == 'Successful') {
    //   isAugmentedWithAI = true;
    // } else {
    //   isAugmentedWithAI = false;
    // }
  }
  if (AugmentWithAIGetData && AugmentWithAIGetData.data) {
    AugmentedWithAI = AugmentWithAIGetData.data;
  }
  return {
    augmentedSummary,
    isHistory,
    isFetching,
    EnrollSurveysList,
    LatestSurveyIds,
    isFetchingGPTSurveys,
    isAugmentedWithAI,
    AugmentedWithAI,
  };
};

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedSurveys: [],
      checkedList: {},
    };
  }
  handelCheckbox = (event, item) => {
    let data = this.state.SelectedSurveys;
    data.push(item);
    this.setState({
      SelectedSurveys: data,
    });
  };
  render() {
    const { onOk, onCancel, EnrollSurveysList, getSelectedSurveys } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header d-flex align-items-center flex-row-reverse border-bottom-1">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h5 className="modal-title col-12 text-left">What would you like to Augment today?</h5>
        </div>
        <div
          className="modal-body pt-2"
          style={{
            height: '500px',
            overflowY: 'scroll',
          }}
        >
          <span
            style={{
              color: '#7F8188',
              fontWeight: '400',
              fontSize: '16px',
            }}
          >
            You can select upto 5 surveys :
          </span>
          <div className="row">
            {EnrollSurveysList &&
              EnrollSurveysList.map(survey => (
                <div className="col-12">
                  <div className="survey-list-item">
                    <Checkbox
                      onChange={event => {
                        this.handelCheckbox(event, survey);
                      }}
                      // disabled={true}
                      className="dis-Ref mr-2 d-flex align-items-end"
                    >
                      {survey.Name}
                    </Checkbox>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="modal-footer border-top-0 pb-5 pt-2">
          <button
            title="Augment with AI"
            className="ai-btn"
            onClick={() => {
              getSelectedSurveys(this.state.SelectedSurveys);
              onOk();
            }}
            style={{
              display: 'inline',
              padding: '5px 20px',
            }}
            disabled={
              !this.state.SelectedSurveys.length > 0 || this.state.SelectedSurveys.length == 5
            }
          >
            <figure class="action-button-icon" style={{ display: 'inline-block' }}>
              <img src="images/AIStar.svg" alt="" width="23" />
            </figure>
            Augment with AI
          </button>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(EnrolledDrawer));
