import React, { Component } from 'react';

 const FamilyProgram = props => {
   const { StatusData } = props;
   return (
     <>
       <div
         className="d-flex align-items-center justify-content-between"
         style={{ backgroundColor: '#FFA800', borderRadius: '12px', padding: '2px' }}
       >
         <div className="d-flex align-items-center">
           {' '}
           <img src={`images/license/dustWatch.svg`} width="86" height="79" alt="" />
           <div style={{ fontSize: '26px', fontWeight: '600' }}>
             {' '}
             Your free trial expires in{' '}
             <b>
               {' '}
               {(StatusData && StatusData.Interaction && StatusData.Interaction[0].DaysLeft) || 0}
             </b>{' '}
             days.{' '}
             <span style={{ fontSize: '20px', fontWeight: '600' }}>
               Purchase license to enjoy hassle-free usage.
             </span>
           </div>
         </div>

         <span
           style={{
             fontSize: '16px',
             fontWeight: '600',
             background: '#fff',
             color: '#0070E0',
             borderRadius: '25px',
             padding: '8px',
             marginRight: '50px',
             cursor: 'pointer',
           }}
           onClick={() => {
             props.history.push('/manage/maintain-license');
           }}
         >
           Purchase Family Program License
         </span>
       </div>
     </>
   );
 };
export default FamilyProgram;