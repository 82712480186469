import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { CSVLink, CSVDownload } from 'react-csv';

class ExportTableCSV extends React.Component {
  render() {
    const { reportData, labelText, filename, isHideLable } = this.props;
    let keys = Object.keys(reportData[0]);
    const getCsvHeader = keys => {
      const csvData = [];
      let i;
      for (i = 0; i < keys.length; i += 1) {
        csvData.push({ label: `${keys[i]}`, key: `${keys[i]}` });
      }
      return csvData;
    };
    return (
      <div className="excel-download d-flex align-items-center">
        {reportData && reportData.length > 0 && (
          <CSVLink filename={filename} data={reportData} headers={getCsvHeader(keys)}>
            <span className="mr-2">{labelText ? labelText : 'CSV :'}</span>
            <img className="mr-2" src="images/csv.svg" width="20" alt="CSV" />{' '}
          </CSVLink>
        )}
      </div>
    );
  }
}
export default ExportTableCSV;
