import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getReportData,
  getReports,
  listCombos,
  getCounselor,
  comboPractitionerAll,
} from '../store/actions';
import PropTypes from 'prop-types';
import axios, { FileSaver } from 'axios';
import { saveAs } from 'file-saver';
import Loader, { Loader2 } from '../Components/Loader';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import renderHTML from 'react-render-html';
import CustomTable from '../component/table';
import { ReduxSelect } from '../component/custom-redux-components/index.js';
import { List, Typography, Divider, DatePicker as AntdDatePicker, Table } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ExportExcel from '../Components/ExportExcel';
// import ExportCSV from '../Components/ExportCSV';
import { FormatDate } from '../Components/FormatDate';
import PageTitle from '../Components/PageTitle';

const { RangePicker } = AntdDatePicker;
const dateFormat = 'YYYY-MM-DD';

// import CustomTable from '../component/custom-components/customTable';

const ReportList = ({ reportArr, toggleView, getReportType, isFetching }) => {
  return (
    <div class="row">
      {/* <Loader2 loading={isFetching} /> */}
      <div class="col-12 mb-5 mt-2">
        <div class="card-columns">
          {reportArr &&
            reportArr.map(report => {
              return report.Reports ? (
                <>
                  <div
                    class="card border-0"
                    style={{ position: 'inherit', backgroundColor: '#F7F5F5' }}
                  >
                    <div class="card-body p-3">
                      <h6 className="report-title">{report.ReportCategory}</h6>
                      <div className="row">
                        {report.Reports &&
                          report.Reports.map((item, index) => (
                            <div className="col-sm-12 col-lg-12">
                              <div className="invoice-list">
                                <div className="d-flex">
                                  <img src="images/icons/report.svg" />
                                  <span
                                    // style={{ fontWeight: 'normal' }}
                                    className="download"
                                    onClick={() => {
                                      getReportType(item);
                                      toggleView();
                                    }}
                                  >
                                    {item.ReportName}
                                  </span>
                                </div>
                                {/* <div>
                            <span
                              className="download"
                              onClick={() => {
                                getReportType(item);
                                toggleView();
                              }}
                              key="list-loadmore-edit"
                            >
                              View Report
                            </span>
                          </div> */}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : null;
            })}
        </div>
      </div>
    </div>
  );
};

class ReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isHTMLLoaded: false,
      html: '',
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
      counsellor: null,
    };
  }
  // moment(moment().subtract(30, 'days') ).format("YYYY-MM-DD")
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );
    const { reportItem } = this.props;
    const { ReportId, ReportType } = reportItem;
    const { startDate, endDate, counsellor, practitionerId } = this.state;
    let sDate = '';
    let eDate = '';
    if (typeof startDate != 'string') {
      sDate = FormatDate(startDate);
      eDate = FormatDate(endDate);
    } else {
      sDate = startDate;
      eDate = endDate;
    }
    let data = {
      ReportType: ReportType,
      Json: JSON.stringify({ Report: [{ ReportId: ReportId, FromDate: sDate, ToDate: eDate }] }),
    };
    this.props.dispatch(getReports(data));
    this.props.dispatch(getCounselor({}));
    this.props.dispatch(
      comboPractitionerAll({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: true,
            },
          ],
        }),
      }),
    );
  }
  getFilterReport = () => {
    const { ReportId, ReportType } = this.props.reportItem;
    const { startDate, endDate, counsellor, practitionerId, statusId, filterCenterId } = this.state;
    let sDate = '';
    let eDate = '';
    if (typeof startDate != 'string') {
      sDate = FormatDate(startDate);
      eDate = FormatDate(endDate);
    } else {
      sDate = startDate;
      eDate = endDate;
    }
    let data = {
      ReportType: ReportType,
      Json: JSON.stringify({
        Report: [
          {
            ReportId: ReportId,
            FromDate: sDate,
            ToDate: eDate,
            StaffId: counsellor,
            PractitionerId: practitionerId,
            Status: statusId,
            FilterCenterId: filterCenterId,
          },
        ],
      }),
    };
    this.props.dispatch(getReports(data));
  };
  getreportDetail = e => {
    const { ReportId, ReportType } = this.props.reportItem;
    const { startDate, endDate, counsellor } = this.state;
    let sDate = '';
    let eDate = '';
    let repSubType = e.target.innerText;
    if (typeof startDate != 'string') {
      sDate = FormatDate(startDate);
      eDate = FormatDate(endDate);
    } else {
      sDate = startDate;
      eDate = endDate;
    }
    let data = {
      ReportType: ReportType,
      Json: JSON.stringify({
        Report: [
          {
            ReportId: ReportId,
            FromDate: sDate,
            ToDate: eDate,
            StaffId: counsellor,
            ReportSubType: repSubType,
          },
        ],
      }),
    };
    this.props.dispatch(getReports(data));
  };

  handleChange = (key, date) => {
    this.setState({
      startDate: date[0],
      endDate: date[1],
    });
  };
  handleCounsellorChange = counsellor => {
    this.setState({ counsellor });
  };
  handlePractitionerChange = practitionerId => {
    this.setState({ practitionerId });
  };
  handleStatusChange = statusId => {
    this.setState({ statusId });
  };
  handleCenterChange = filterCenterId => {
    this.setState({ filterCenterId });
  };
  componentWillReceiveProps({ reportData }) {
    if (reportData && JSON.stringify(reportData) != JSON.stringify(this.props.reportData)) {
      let reportArr = Object.values(reportData);
      let reportName = Object.keys(reportData);
      let newData;
      reportName.map((reportname, index) => {
        let data = reportData[reportname].map(item => {
          delete item.ReactSortType;
          return item;
        });
        newData = {
          [reportname]: data,
        };
      });
      this.setState({
        reportArr: reportArr,
        reportName: reportName,
        newData,
      });
    }
  }
  render() {
    const {
      reportData,
      isFetching,
      reportItem,
      Center,
      Counsellor,
      Practitioner,
      Status,
    } = this.props;
    const {
      startDate,
      endDate,
      counsellor,
      practitionerId,
      statusId,
      filterCenterId,
      reportArr,
      reportName,
      newData,
    } = this.state;
    const {
      ReportName,
      ReportId,
      CalenderRequired,
      StaffRequired,
      PractitionerRequired,
      StatusRequired,
      CenterRequired,
    } = reportItem;
    let isFilterView =
      StaffRequired || PractitionerRequired || StatusRequired || CalenderRequired || CenterRequired;
    // const reportArr = Object.values(reportData);
    // const reportName = Object.keys(reportData);

    // let newData;
    // if (reportData && reportData['Referrals Sent Report']) {
    //   let data =
    //     reportData &&
    //     reportData['Referrals Sent Report'] &&
    //     reportData['Referrals Sent Report'].map(item => {
    //       delete item.ReactSortType;
    //       return item;
    //     });
    //   newData = {
    //     'Referrals Sent Report': data,
    //   };
    // } else {
    //   newData = reportData;
    // }
    return (
      <div className="row">
        {/* <Loader loading={isFetching} /> */}
        <div class="col-12 mb-5">
          <div className="card border-0 mb-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <h5 className="flex-grow-1">{ReportName}</h5>
                <PageTitle TitleText="Reports" SecondText={ReportName} />
                {/* <span className="mr-2">Excel Export :</span> */}
                {!this.props.isReadOnlyUser && (
                  <>
                    {reportArr && reportArr.length > 0 && newData && (
                      <ExportExcel reportData={newData} labelText={'Excel'} filename="Report" />
                    )}
                  </>
                )}
                {/* <ExportCSV reportData={newData} labelText={'CSV'} filename="Report" /> */}
              </div>
              <div
                className={`d-flex align-items-center ${
                  StaffRequired || PractitionerRequired === 1
                    ? 'justify-content-between'
                    : 'justify-content-end'
                } mt-2`}
              >
                {StaffRequired === 1 && (
                  <div className="w-50">
                    <div className="col-12">
                      <div class="form-group">
                        <ReduxSelect
                          options={Counsellor}
                          onChange={this.handleCounsellorChange}
                          className="basic-single"
                          // class={`basic-single form-control ${counsellor && 'not-empty'}`}
                          classNamePrefix="select"
                          value={counsellor}
                          placeholder="Counselor"
                        />
                        {/* <label class="placeholder-label">Counselor*</label> */}
                      </div>
                    </div>
                    {/* <div className="col-3">
                      <div class="form-group call-schedule">
                        <ReduxSelect
                          options={Counsellor}
                          // onChange={this.handleTimeChange}
                          className="basic-single"
                          classNamePrefix="select"
                          // value={timeSlot}
                          placeholder="Start Time"
                        />
                      </div>
                    </div> */}
                  </div>
                )}
                <div className="w-100">
                  <div className="row">
                    {PractitionerRequired === 1 && (
                      <div className="col-6">
                        <div class="form-group">
                          <ReduxSelect
                            options={Practitioner}
                            onChange={this.handlePractitionerChange}
                            className="basic-single"
                            // class={`basic-single form-control ${counsellor && 'not-empty'}`}
                            classNamePrefix="select"
                            value={practitionerId}
                            placeholder="Provider"
                          />
                          {/* <label class="placeholder-label">Counselor*</label> */}
                        </div>
                      </div>
                    )}
                    {StatusRequired === 1 && (
                      <div className="col-6">
                        <div class="form-group">
                          <ReduxSelect
                            options={Status}
                            onChange={this.handleStatusChange}
                            className="basic-single"
                            // class={`basic-single form-control ${counsellor && 'not-empty'}`}
                            classNamePrefix="select"
                            value={statusId}
                            placeholder="Status"
                          />
                          {/* <label class="placeholder-label">Counselor*</label> */}
                        </div>
                      </div>
                    )}
                    {CenterRequired && (
                      <div className="col-6">
                        <div class="form-group">
                          <ReduxSelect
                            options={Center}
                            onChange={this.handleCenterChange}
                            className="basic-single"
                            classNamePrefix="select"
                            value={filterCenterId}
                            placeholder="Center"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {CalenderRequired === 1 && (
                  <div className="col-6 px-0">
                    <div className="d-flex align-items-center justify-content-end">
                      {/* <div className="d-flex align-items-center justify-content-between mt-2"> */}
                      <div className="text-right" style={{ width: '427px' }}>
                        <div class="font-weight-bold ant-picker-range">Date Range : &nbsp;</div>
                        <RangePicker
                          // style={{}}
                          defaultValue={[
                            moment(startDate, dateFormat),
                            moment(endDate, dateFormat),
                          ]}
                          onChange={this.handleChange}
                          className="range-picker w-75 text-left"
                          format={['YYYY-MM-DD', 'YYYY/MM/DD']}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isFilterView ? (
                  <div className="text-right">
                    <input
                      type="button"
                      class="btn px-5 py-2 ml-2"
                      value="View"
                      onClick={this.getFilterReport}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div class="card border-0 ">
            <div className="row card-body">
              {reportArr && reportArr.length > 0 ? (
                reportArr.map((arrData, index) => {
                  return (
                    <>
                      {arrData ? (
                        <>
                          <div
                            className={
                              'col-12 px-2 ' + (reportArr.length == 1 ? 'col-lg-12' : 'col-lg-6')
                            }
                          >
                            <CustomTable
                              heading={
                                ReportId === 'RP6' ? (
                                  // <span className="download" onClick={this.getreportDetail}>
                                  //   {reportName[index]}
                                  // </span>
                                  <Link
                                    to="/report-view"
                                    class="download"
                                    onClick={this.getreportDetail}
                                  >
                                    {reportName[index]}
                                  </Link>
                                ) : (
                                  <div className="d-flex">
                                    <h6 className="flex-grow-1">{reportName[index]}</h6>
                                  </div>
                                )
                              }
                              reportName={reportName[index]}
                              rowData={arrData}
                              pagination={false}
                              // scroll={100}
                              bordered={true}
                              selectSorter={['isComparer', 'isNumericComparer', 'isDateComparer']}
                              // selectSorter={{ 0: isComparer, 1: isNumericComparer, 2: isDateComparer }}
                              isReadOnlyUser={this.props.isReadOnlyUser}
                            />
                            <br />
                          </div>
                        </>
                      ) : (
                        <div className="col-12">
                          <h6 className="text-center">No Records</h6>
                        </div>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="col-12">
                  <h6 className="text-center">No Records</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToPropsTable = ({
  get_reports,
  combos,
  get_counselor,
  combo_practitioner_all,
  medicate_combos,
}) => {
  var { data, isFetching, error } = get_reports;
  var { data: counselorData, isFetching, error } = get_counselor;
  const { data: practitionerDataAll } = combo_practitioner_all;
  const { data: medicateCombos, isFetching: isMedicalComboFetching } = medicate_combos;

  let reportData = null;
  let Center = [];
  let Counsellor = [];
  let CounsellorData = [];
  let Practitioner = [];
  let Status = [];

  if (combos.data) {
    Center = combos.data.Center;
    // Counsellor = combos.data.Counsellor;
  }
  if (medicateCombos && medicateCombos.Status) {
    Status = medicateCombos.Status;
  }
  if (data && data.data) {
    reportData = JSON.parse(data.data)[0];
  }
  if (counselorData) {
    CounsellorData = Object.values(get_counselor.data);
    Counsellor = CounsellorData.map(item => {
      return {
        Value: item.StaffId,
        Text: item.StaffName,
      };
    });
  }
  if (practitionerDataAll && practitionerDataAll.PractitionerList) {
    Practitioner = practitionerDataAll.PractitionerList || [];
  }
  return {
    isFetching: isFetching,
    reportData,
    Center,
    Counsellor,
    Practitioner: Practitioner,
    Status,
  };
};

const ReduxReportTable = connect(mapStateToPropsTable)(ReportTable);

class Report extends Component {
  state = {
    isFetching: false,
    isHTMLLoaded: false,
    html: '',
    viewTable: false,
  };
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getReportType = item => {
    this.setState({
      ReportType: item.ReportType,
      ReportId: item.ReportId,
      reportItem: item,
    });
  };
  // getAdmissionReportData = () => {
  //   this.setState({ isFetching: true });
  //   const me = this;
  //   var fileName = '';
  //   const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
  //   var hearders = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     Authorization: accessToken ? `Bearer ${accessToken}` : '',
  //     Accept: 'application/vnd.ms-excel',
  //   };

  //   fetch(`/api/v1/report/GetReportData`, {
  //     method: 'POST',
  //     headers: hearders,
  //     responseType: 'arraybuffer',
  //   })
  //     .then(response => {
  //       if (response.ok) {
  //         fileName = response.headers.get('FileName');
  //         return response.blob();
  //       }
  //     })
  //     .then(blob => {
  //       me.setState({ isFetching: false });
  //       saveAs(blob, fileName);
  //     });
  // };
  componentDidMount() {
    if (this.props.isCWReport) {
      this.props.dispatch(getReports(this.props.CWReportData));
    } else this.props.dispatch(getReports({}));
  }
  // getAdmissionReportDataHTML = () => {
  //   this.setState({ isFetching: true });
  //   const me = this;
  //   const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
  //   var hearders = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     Authorization: accessToken ? `Bearer ${accessToken}` : '',
  //     Accept: 'application/vnd.ms-excel',
  //   };

  //   fetch(`/api/v1/report/GetReportDataHTML`, {
  //     method: 'POST',
  //     headers: hearders,
  //   })
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //     })
  //     .then(response => {
  //       me.setState({ isFetching: false, html: response.data, isHTMLLoaded: true });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       alert(JSON.stringify(error));
  //     });
  // };

  toggleView = () => {
    if (this.state.viewTable) {
      if (this.props.isCWReport) {
        this.props.dispatch(getReports(this.props.CWReportData));
      } else this.props.dispatch(getReports({}));
    }
    this.setState({ viewTable: !this.state.viewTable });
  };
  componentWillReceiveProps({ reportData }) {
    if (reportData && JSON.stringify(reportData) != JSON.stringify(this.props.reportData)) {
      this.setState({
        reportList: reportData,
        filteredReportList: reportData,
      });
    }
  }
  onSearchBoxChange(event) {
    var str = event.target.value.toLowerCase().trimStart();
    var filteredReportList = [];

    for (const report of this.state.reportList) {
      if (report.ReportCategory.toLowerCase().includes(str)) {
        filteredReportList.push(report);
      } else {
        let list =
          report.Reports &&
          report.Reports.filter(item => {
            return item.ReportName.toLowerCase().includes(str);
          });
        if (list && list.length > 0) {
          let reportObj = { ReportCategory: report.ReportCategory, Reports: list };
          filteredReportList.push(reportObj);
        }
      }
    }
    this.setState({
      filteredReportList: filteredReportList,
      searchBoxValue: str,
    });
  }
  render() {
    const { isFetching } = this.props;
    const { reportItem, reportList, filteredReportList, searchBoxValue } = this.state;
    return (
      <div class="container-fluid mt-1">
        <PageTitle TitleText="Reports" />

        <div class="content-area bg-white p-3" style={{ position: 'relative' }}>
          <Loader2 loading={isFetching} />
          <div class="row top-table-search mt-1 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h3 class="page-title mb-3 mb-md-0 text-uppercase">REPORTS</h3>
            </div>
            <div class="col-md-4 mar-top-30" />
          </div>
          {/* <div class="row">
            <div class="col-12 mar-top-70">
              <div class="report-hold">
                <p>
                  <a>
                    <h4 onClick={this.getAdmissionReportDataHTML}>
                      Students and Budget Report &nbsp;{' '}
                      <img
                        onClick={this.getAdmissionReportData}
                        src="images/excel_icon.png"
                        width="50"
                        alt=""
                      />
                    </h4>
                  </a>
                </p>
                <p>This report gives chart and report of Students and Budget for your Centers</p>
                {this.state.isHTMLLoaded && renderHTML(this.state.html)}
              </div>
            </div>
          </div> */}

          <div className="row">
            {!this.state.viewTable ? (
              <div className="col-12">
                <div class="row top-table-search justify-content-end mb-2">
                  <div class="col-md-6 d-flex align-items-center justify-content-end">
                    <div class="search-box d-inline-flex w-100">
                      <div class="form-group has-search w-100">
                        <input
                          type="text"
                          class="form-control shadow-sm"
                          placeholder="Search"
                          onChange={this.onSearchBoxChange.bind(this)}
                          value={searchBoxValue}
                        />
                        <button class="btn search-icon " type="button">
                          <i class="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {filteredReportList && filteredReportList.length > 0 ? (
                  <ReportList
                    reportArr={filteredReportList || []}
                    toggleView={this.toggleView}
                    getReportType={this.getReportType}
                    isFetching={isFetching}
                  />
                ) : (
                  <div className="card border-0">
                    <div className="card-body p-3">
                      <span>
                        "<b>{searchBoxValue}</b>" did not match any reports.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div class="col-12 col-md-4 col-lg-4 mb-2 top-table-search">
                  <a class="back-link" onClick={this.toggleView}>
                    <img src="images/back-arrow.svg" /> Back
                  </a>
                </div>
                <div className="col-12">
                  <ReduxReportTable
                    reportItem={reportItem}
                    isReadOnlyUser={this.props.isReadOnlyUser}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_reports, default_values }) => {
  var { data, isFetching, error } = get_reports;
  let reportData = [];
  let isReadOnlyUser = false;
  if (data && data.data) {
    reportData = JSON.parse(data.data);
  }
  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  return {
    isFetching: isFetching,
    reportData,
    isReadOnlyUser,
  };
};

export default connect(mapStateToProps)(Report);
