import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';
// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
import { PermissionsErrorMsg } from '../../app-config/index';

export function* handleError(action) {
  const { error, successAction, failedAction } = action;
  if (!error.response) {
    yield put({
      type: ActionTypes.DISPLAY_ERROR_SUCCESS,
      payload: { error: error.message },
    });

    yield put({
      type: ActionTypes.LOG_ERROR_REQUEST,
      payload: error,
    });
    // if (error && error.message) {
    //   alert(error.message);
    // } else {
    //   alert('Something went wrong');
    // }
  }
  var status = 0;
  if (typeof error.status != 'undefined') {
    status = error.status;
  }
  if (status == 0 && error.response) {
    status = error.response.status;
  }
  if (status === 403) {
    localStorage.clear();
    window.location.href = '/';
    window.location.reload(true);
  } else if (status == 405) {
    alert('Action Not Allowed');
  } else if (status == 401) {
    var istokenRefreshing = localStorage.getItem('istokenRefreshing');
    var token = JSON.parse(localStorage.getItem('dr:token'));
    if ((token && token.date_exp && token.date_exp < Date.now()) || istokenRefreshing == 'true') {
      return;
    }
    var refresh_token = '';
    var tokenObject = localStorage.getItem('dr:token');
    if (tokenObject) {
      refresh_token = JSON.parse(localStorage.getItem('dr:token')).refresh_token;
    } else {
      var path = window.location.hash.split('#/')[1];
      if (path) {
        const history = createBrowserHistory();
        history.push('/#login?redirectPath=' + path);
        window.location.reload(true);
      }
    }

    if (Boolean(refresh_token)) {
      try {
        const originalRequest = error.config;
        const refresh_response = yield API.refreshToken({
          grant_type: 'refresh_token',
          refresh_token: refresh_token,
          originalRequest: originalRequest,
        });
        console.debug(refresh_response);
        if (refresh_response.data.access_token) {
          var token = JSON.parse(localStorage.getItem('dr:token'));
          token.access_token = refresh_response.data.access_token;
          token.date_exp = Date.now() + refresh_response.data.expires_in * 1000;
          localStorage.setItem('dr:token', JSON.stringify(token));
          yield put(successAction);
        } else {
          var tokenObject = localStorage.getItem('dr:token');
          if (tokenObject) {
            var token = JSON.parse(localStorage.getItem('dr:token'));
            token.access_token = '';
            token.refresh_token = '';
            localStorage.setItem('dr:token', JSON.stringify(token));
          }
        }
      } catch (error) {
        localStorage.clear();
        var path = window.location.hash.split('#/')[1];
        const history = createBrowserHistory();
        history.push('/#login?redirectPath=' + path);
        window.location.reload(true);
      }
    }
  }
  // else if (status == 500) {
  //   alert(error.response.data.Message || error.message);
  // }
  else {
    if (error && error.response && error.response.data) {
      if (PermissionsErrorMsg == error.response.data.Message) {
        const history = createBrowserHistory();
        alert(error.response.data.Message);
        history.goBack();
        history.go(-1);
        return false;
      } else {
        alert(error.response.data.Message || error.message);
      }
      // console.error(error.response.data.Message);
      /*yield put({
        type: ActionTypes.DISPLAY_ERROR_SUCCESS,
        payload: { error: error.response.data.Message },
      });*/
    } else if (error.message) {
      alert(error.message);
    }

    yield put({
      type: failedAction,
      error: error,
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.HANDLE_ERRORS_REQUEST, handleError)]);
}
