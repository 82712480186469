import React from 'react';
import { Tabs, Modal, Tooltip, Drawer, Popover } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import Axios from 'axios';
import {
  upsertPatientEnrollment,
  resetUpsertPatientEnrollment,
  resetGetPatientDetail,
  addNewApplicant,
  getClientCounselor,
  getDynamicComos,
  medicateCombos,
  getPatientDetail,
  clientChartListGet,
  getStudentProfileDashboardData,
  practitionerCombos,
  comboPractitionerCenterGet,
  clientStudentSynchronize,
  staffAccessedTabGet,
  resetClientReactivate,
  clientBannerGet,
  clientChartReport,
  clinicalToIntakeMovePatient,
} from '../../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import Demographic from './formSteps/demographic';
// import ContactInformation from './formSteps/contactInformation';
import HealthInsurance from './formSteps/healthInsurance';
import Payment from './formSteps/payment';
import MedicalHistory from './formSteps/medicalHistory';
import ScreeningTool from './formSteps/screeningTool';
import MedicationLogs from './formSteps/medicationLogs';
import VitalList from './formSteps/vitalList';
import AssessmentTool from './formSteps/assessmentTool';
import ConsentAuthorizationForm from './formSteps/consentAuthorizationForm';
import ConsentForm from './formSteps/consentForm';
import MedicalAnalysis from './formSteps/medicalAnalysis';
import Forms from './formSteps/forms';
// import CareTeam from './formSteps/careTeam';
import CareTeam from './formSteps/manageCareTeam';
import TreatmentPlan from './formSteps/treatmentPlan';
import GroupMemebership from './formSteps/groupMembership';
import DocumentForm from './formSteps/documentForm';
import IndividualNotes from './formSteps/individualNotes';
import Notes from './formSteps/notes';
import Memo from './formSteps/memo';
import IndividualAppointment from './formSteps/individualAppointment';
import ServiceLine from './formSteps/serviceLine';
import Discharge from './formSteps/discharge';
import ListLabOrder from './formSteps/listLabOrder';
import EPrescription from './formSteps/ePrescription';
import Authorizations from './formSteps/authorizations';
import VirtaulAssistant from './formSteps/virtualAssistant';
import SmartContract from './formSteps/smartContract';
import AugmentedSummary from './formSteps/augmentedSummary';
import ClinicalTasks from './formSteps/clinicalTask';
import CustomModal from '../../../core/modal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { submit, reset, destroy, Field, reduxForm } from 'redux-form';
import { getDate, stringDate } from '../../../util/date';
import AntdModal from '../../../Components/CustomModal';
import { ProcessCombos, ProcessFilterCombos } from '../../../store/comboConfig';
import ClientProfileHeader from '../../customScreens/clientProfileHeader';
import { ReduxSelect } from '../../../component/custom-redux-components';
import PrintClientChart from './printClientChart';
import renderHTML from 'react-render-html';
import ActionableIcon from '../../../Components/ActionableIcon';
import PageTitle from '../../../Components/PageTitle';

const Components = {
  VirtaulAssistant,
  Demographic,
  HealthInsurance,
  MedicalHistory,
  CareTeam,
  Forms,
  Authorizations,
  ScreeningTool,
  MedicationLogs,
  VitalList,
  // IndividualNotes,
  Notes,
  Memo,
  AssessmentTool,
  TreatmentPlan,
  MedicalAnalysis,
  GroupMemebership,
  IndividualAppointment,
  ServiceLine,
  // Payment,
  EPrescription,
  ListLabOrder,
  DocumentForm,
  Discharge,
  SmartContract,
  AugmentedSummary,
  ClinicalTasks,
};
const { TabPane } = Tabs;
class SlidingTabs extends React.Component {
  constructor(props) {
    super(props);
    props.reset();
    this.state = {
      message: null,
      isClientModalOpen: false,
      isIntakeModal: false,
      clickedRecord: {},
      callModelVisible: false,
      printDetails: {},
      isProcessed: false,
      tabMapping: {},
      tabLabels: {},

      activeKey: 0,
      activeTabs: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        // false,
      ],
      clientData: {},
      isModelOpen: false,
      isModelOpen2: false,
      isSyncModalOpen: false,
      IsStudentSync: true,
      isNewSaveModelOpen: false,
      isDeleteModelOpen: false,
      deleteModelMessage: null,
      yesCallBack: null,
      showError: false,
      modalVisible: false,
      ClientId: null,
    };
  }

  onAddNewApplicant = values => {
    this.props.addNewApplicant({});
  };

  onSubmit(values) {
    // if (this.state.isProcessed) {
    //   return;
    // }
    //if (this.getDischarged()) return;
    this.state.isProcessed = true;
    const { activeTabs } = this.state;
    const {
      // start - student information
      patientId,
      firstName,
      lastName,
      middleName,
      dateofBirth,
      age,
      gender,
      ssn,
      line1,
      line2,
      city,
      stateOrProvince,
      zip,
      primaryCN,
      email,
      primaryLanguage,
      race,
      ethnicity,
      religion,
      education,
      maritalStatus,
      practitioner,
      isPregnant,
      ethnicityStatus,
      veteranStatus,
      referralSource,
      centerProgramType,
      houseHoldincome,
      programLevel,
      admissionDate,
      EmergencyContact,
      height,
      weight,
      BMI,
      bloodPressure,
      temperature,
      centerServiceType,
      dischargeDate,
      centerId,
      clientStatus,
      demographicNotes,
      projectedCompletionDate,
    } = values;

    var dateItem = dateofBirth.split('/');
    var dob = dateItem[2] + '-' + dateItem[0] + '-' + dateItem[1];
    // let studentDemographic = {};
    // if (
    //   eyeColorId ||
    //   maritalStatus ||
    //   ethnicRaceId ||
    //   educationTypeId ||
    //   workSkillId ||
    //   (languageType && !activeTabs[2])
    // ) {
    //   studentDemographic = {
    //     MaritalStatus: maritalStatus,
    //     EthnicRaceId: ethnicRaceId,
    //     EyeColorId: eyeColorId,
    //     EducationTypeId: educationTypeId,
    //     WorkSkillId: workSkillId,
    //     LanguageISO: languageType,
    //     DemographicNotes: demographicNotes,
    //   };
    // }
    let data = {
      Client: [
        {
          ClientId: this.getClientId() || null,
          FirstName: firstName,
          LastName: lastName,
          MiddleName: middleName,
          DateOfBirth: dateofBirth,
          // age,
          Gender: gender,
          SSN: ssn ? ssn.replace('-', '').replace('-', '') : null,
          AddressLine1: line1,
          AddressLine2: line2,
          City: city,
          State: stateOrProvince,
          ZipCode: zip,
          PrimaryPhone: primaryCN && primaryCN.replace(/\D/g, ''),
          Email: email,
          // primaryLanguage,
          EthnicRaceId: race,
          // ethnicity,
          FaithTypeId: religion,
          EducationTypeId: education,
          MaritalStatus: maritalStatus,
          PractitionerId: practitioner,
          IsPregnant: isPregnant,
          EthnicityId: ethnicityStatus,
          VeteranId: veteranStatus,
          ReferralSourceId: referralSource,
          ProgramId: centerProgramType,
          CenterServiceTypeId: centerServiceType,
          DischargeDate: dischargeDate,
          HouseHoldIncome: houseHoldincome,
          ProgramLevelId: programLevel,
          CenterId: centerId,
          AdmissionDate: admissionDate ? stringDate(admissionDate) : null,
          ClientStatusId: clientStatus,
          Notes: demographicNotes,
          ProjectedCompletionDate: projectedCompletionDate
            ? stringDate(projectedCompletionDate)
            : null,
          ClientVital: [
            {
              Height: height,
              Weight: weight,
              BMI: BMI,
              BloodPressure: bloodPressure,
              Temperature: temperature,
            },
          ],
          ClientContact:
            EmergencyContact &&
            EmergencyContact.map(item => {
              return {
                ContactId: item.contactId || null,
                Contact: [
                  {
                    ContactId: item.contactId,
                    FirstName: item.firstName,
                    MiddleName: item.middleName,
                    LastName: item.lastName,
                    AddressLine1: item.line1,
                    AddressLine2: item.line2,
                    AddressLine3: item.line3,
                    City: item.city,
                    Notes: item.notes,
                    StateOrProvince: item.stateOrProvince,
                    ZipCode: item.zip,
                    CountryISO: item.country || 'USA',
                    EmergencyPhone: item.emergencyCN,
                    PrimaryPhone: item.primaryCN && item.primaryCN.replace(/\D/g, ''),
                    SecondaryPhone: item.secondaryCN,
                    Email: item.email,
                    RelationShipId: item.relationshipId,
                    // IsEmergencyContact: item.isEmergencyContact,
                  },
                ],
              };
            }),
        },
      ],
    };
    let patientInfo = {
      json: JSON.stringify(data),
    };

    this.props.upsertPatientEnrollment(patientInfo);
  }

  constructStudentAddictionType = substanceUseProfiles => {
    var returnItem = [];
    for (var item of substanceUseProfiles) {
      if (item.addictionTypeId) {
        if (item.addictionTypeId == 1) {
          if (!item.startDate) {
            continue;
          }
        }
        returnItem.push({
          StudentAddictionTypeId: item.studentAddictionTypeId || null,
          AddictionProfileTypeId: item.addictionProfileTypeId,
          AddictionProfileTypeOther:
            item.addictionProfileTypeId == 5 ? item.addictionProfileTypeOther : null,
          AddictionTypeId: item.addictionTypeId,
          DrugRouteOfAdminId:
            typeof item.drugRouteOfAdminId == 'object'
              ? item.drugRouteOfAdminId.map(item => item.Value).toString()
              : item.drugRouteOfAdminId || '',
          ReportedDate: null, // To Do: Need to Add
          StartDate: item.startDate,
          EndDate: item.endDate,
          Notes: item.notes,
        });
      } else {
        returnItem.push({
          StudentAddictionTypeId: item.studentAddictionTypeId || null,
          AddictionProfileTypeId: item.addictionProfileTypeId,
          AddictionProfileTypeOther:
            item.addictionProfileTypeId == 5 ? item.addictionProfileTypeOther : null,
          StartDate: item.startDate,
          EndDate: item.endDate,
        });
      }
    }
    return returnItem;
  };

  handleNext = index => {
    const { activeTabs } = this.state;
    activeTabs[index] = true;
    activeTabs[index + 1] = true;
    this.setState({
      activeKey: `${index + 1}`,
      activeTabs,
    });
  };
  changeActiveTab = index => {
    const { activeTabs } = this.state;
    activeTabs[index] = true;
    this.setState({
      activeKey: `${index}`,
      activeTabs,
    });
  };
  goToPreviuos = index => {
    /*window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });*/
    this.setState({ activeKey: index - 1 });
  };

  onTabClick = key => {
    if (this.getClientId()) {
      let keyData = parseInt(key);
      let clickedTab = this.state.tabMapping[keyData];
      this.props.history.push(
        `/cw/patient-management/patient-profile?tab=${clickedTab}&pId=${this.getClientId()}`,
      );
    } else {
      return;
    }
  };

  getDischarged = () => {
    if (this.props.isView || this.props.isReadOnlyUser) {
      return true;
    }
  };

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  hideButton = () => {
    //for (let item of document.getElementsByClassName('btn')) item.disabled = true;
  };

  componentDidMount() {
    this.props.dispatch(
      comboPractitionerCenterGet({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: true,
            },
          ],
        }),
      }),
    );

    let ClientId = this.getClientId();
    if (ClientId) {
      this.props.dispatch(
        getPatientDetail({
          json: JSON.stringify({
            Client: [
              {
                ClientId: ClientId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(clientChartListGet({}));
    }

    if (ClientId) {
      this.setState({
        ClientId,
        // activeKey: 3,
        activeTabs: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          // true,
        ],
      });
    } else {
      this.setState({
        ClientId: null,
        clientData: {
          SubscriberInformation: [{}],
          EmergencyContact: [{}],
          ClientMedical: [{}],
          ClientPayment: [{}],
          country: 'USA',
        },
        // demographicData: {},
        demographicData: {
          admissionDate: undefined,
          age: undefined,
          bloodPressure: undefined,
          centerId: undefined,
          // centerId: this.props.defaultCenter,
          centerServiceType: undefined,
          city: undefined,
          clientStatus: undefined,
          dateofBirth: undefined,
          demographicNotes: undefined,
          projectedCompletionDate: undefined,
          displayClientId: undefined,
          education: undefined,
          email: undefined,
          ethnicityStatus: undefined,
          firstName: undefined,
          gender: undefined,
          height: undefined,
          houseHoldincome: undefined,
          isPregnant: null,
          line1: undefined,
          line2: undefined,
          maritalStatus: undefined,
          middleName: undefined,
          practitioner: undefined,
          primaryCN: undefined,
          programLevel: undefined,
          race: undefined,
          referralSource: undefined,
          religion: undefined,
          ssn: undefined,
          stateOrProvince: undefined,
          temperature: undefined,
          veteranStatus: undefined,
          weight: undefined,
          zip: undefined,
        },
        activeKey: 0,
        activeTabs: [
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
          // false,
        ],
      });
    }
    // let medicateComboTypes = {
    //   ComboTypes:
    //     'Allergies,DiseaseConditions,Medication,Surgeries,Vaccination,Psychiatric,Veteran,Ethnicity,Category,DiagnosisType,Ranking,StatusMedAnalysis,PatientRelationshipToInsured,InsuredAuthorization',
    // };
    this.props.medicateCombos({
      ComboTypes: 'Veteran,Ethnicity',
    });

    var result = ProcessCombos(
      'CenterServiceType,Extension,DocumentType,MaritalStatus,Gender,Filter_StateProvince,Center,Counsellor,ReferenceSource,NameSuffix,NamePrefix,Country,HomeAddressType,ChurchDenomination,EthnicRace,EducationType,Language,RelationShip,FaithType,CenterProgramType,RCM_Program,ProgramLevel,ClientStatus',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    if (this.props.patientTabsMenu) {
      this.stafftabMenu(this.props.patientTabsMenu);
    }
    /*
    this.props.dispatch(
      ProcessFilterCombos({
        combo: 'StateProvince',
        CountryISO: 'USA',
        filterBy: 'USA',
      }),
    );*/
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  toggle2 = failMsg => {
    this.setState(prevState => ({
      isModelOpen2: !prevState.isModelOpen2,
      failMsg,
    }));
  };
  SynchronizedModalToggle = header => {
    this.setState(prevState => ({
      isSyncModalOpen: !prevState.isSyncModalOpen,
      header,
    }));
  };

  error = msg => {
    Modal.error({
      content: msg,
    });
  };
  deleteModelToggle = () => {
    this.state.yesCallBack();
    this.setState({ isDeleteModelOpen: !this.state.isDeleteModelOpen });
  };

  updateDeleteModel = (isDeleteModelOpen, deleteModelMessage, yesCallBack) => {
    this.setState({
      isDeleteModelOpen: isDeleteModelOpen,
      deleteModelMessage: deleteModelMessage,
      yesCallBack: yesCallBack,
    });
  };

  launchSurvey = () => {
    this.props.history.push('/surveyer');
  };

  componentWillUnmount() {
    // this.props.resetGetStudent();
    // this.props.resetGetPatientDetail();
    this.props.resetUpsertPatientEnrollment();
    this.props.reset();
  }

  backButtonImplimentation = nextProps => {
    let upComingTab = new URLSearchParams(nextProps.history.location.search);
    upComingTab = upComingTab.get('tab');
    let upComingTabIndex = Object.values(this.state.tabMapping).indexOf(upComingTab);
    let pastTabs = new URLSearchParams(this.props.location.search);
    pastTabs = pastTabs.get('tab');
    let pastTabsIndex = Object.values(this.state.tabMapping).indexOf(pastTabs);
    if (upComingTabIndex != pastTabsIndex) {
      this.setState({
        activeKey: upComingTabIndex,
      });
    }
  };
  stafftabMenu = patientTabsMenu => {
    let tempUrls = {};
    let temptabLabels = [];
    patientTabsMenu.map((item, index) => {
      const { url } = item || {};
      tempUrls[index] = url;
    });
    temptabLabels = patientTabsMenu.map((item, index) => {
      const { text, component } = item || {};
      const SelectedComponent = Components[component];
      if (component == 'TreatmentPlan') {
        return {
          Name: text,
          Component: props => (
            <SelectedComponent {...props} printDetails={this.state.printDetails} />
          ),
        };
      } else if (component == 'AssessmentTool') {
        return {
          Name: text,
          Component: props => <SelectedComponent {...props} toggle={this.toggle2} />,
        };
      } else if (component == 'MedicalAnalysis') {
        return {
          Name: text,
          Component: props => <SelectedComponent {...props} name="medicalAnalysis" />,
        };
      } else if (component == 'VirtaulAssistant' || component == 'AugmentedSummary') {
        return {
          Name: (
            <>
              <img className="chat-submit mr-1" src="images/AIStarBG.svg" />
              {text}
            </>
          ),
          TabName: text,
          Component: props => <SelectedComponent {...props} />,
        };
      } else {
        return {
          Name: text,
          Component: props => <SelectedComponent {...props} />,
        };
      }
    });

    //  let val = Object.values(tempUrls);
    //  let urlKey = val.indexOf(this.props.location.pathname.split('/')[2]);
    //  let tempCurrent = urlKey >= 0 ? urlKey.toString() : '0';

    var params = new URLSearchParams(this.props.location.search);
    var tab = params.get('tab');
    // JSON.stringify(Object.values(tabMapping).indexOf(tab))
    // if (temptabLabels[0].TabName == 'Virtual Assistant' && !this.getClientId()) {
    //   temptabLabels.shift();
    // }
    if (!this.getClientId()) {
      let array = [];
      temptabLabels &&
        temptabLabels.map(item => {
          // if (!this.getClientId()) {
          if (item.TabName != 'Augmented Summary' && item.TabName != 'Virtual Assistant') {
            array.push(item);
          }

          // }
        });
      temptabLabels = [...array];
    }

    this.setState({
      tabMapping: tempUrls,
      tabLabels: temptabLabels,
      activeKey: tempUrls && tab ? Object.values(tempUrls).indexOf(tab) : 0,
    });
  };
  componentWillReceiveProps(nextProps, state) {
    const {
      isInserted,
      recordInfo,
      errorMessage,
      clientInfo,
      newClientId,
      isSynchronized,
      patientTabsMenu,
      isReactivated,
      bannerDetails,
      clientMatchedWithStudent,
      inviteMsg,
      isInvitationSent,
      isMovedToIntake,
    } = nextProps;
    this.backButtonImplimentation(nextProps);
    if (
      clientMatchedWithStudent &&
      clientMatchedWithStudent != this.props.clientMatchedWithStudent
    ) {
      let array = [];
      if (clientMatchedWithStudent) {
        for (let key in clientMatchedWithStudent) {
          if (
            clientMatchedWithStudent[key] != null &&
            key != 'Studentid' &&
            key != 'FirstName' &&
            key != 'LastName'
          ) {
            if (key == 'PrimaryPhone') {
              array.push('Phone Number');
            } else {
              array.push(key);
            }
          }
        }
        if (array.length == 3) {
          this.setState({
            message: `${array[0]}, ${array[1]} and ${array[2]}`,
          });
        } else if (array.length == 2) {
          this.setState({
            message: `${array[0]} and ${array[1]}`,
          });
        } else {
          this.setState({
            message: `${array[0]}`,
          });
        }
      }
    }

    if (
      patientTabsMenu &&
      JSON.stringify(patientTabsMenu) != JSON.stringify(this.props.patientTabsMenu)
    ) {
      this.stafftabMenu(patientTabsMenu);
    }

    if (isMovedToIntake && isMovedToIntake != this.props.isMovedToIntake) {
      this.setState({ isIntakeModal: true });
    }

    if (isSynchronized && isSynchronized != this.props.isSynchronized) {
      this.setState(
        {
          newClientId: newClientId,
        },
        () => {
          this.SynchronizedModalToggle('Client Synchronized Successfully');

          this.props.dispatch(
            getPatientDetail({
              json: JSON.stringify({
                Client: [
                  {
                    ClientId: this.getClientId(),
                  },
                ],
              }),
            }),
          );
        },
      );
    }
    if (isReactivated && isReactivated != this.props.isReactivated) {
      this.setState(
        {
          newClientId: newClientId,
        },
        () => {
          this.toggle('The patient has been reactivated successfully');
        },
      );
      if (newClientId) {
        this.props.dispatch(
          getPatientDetail({
            json: JSON.stringify({
              Client: [
                {
                  ClientId: newClientId,
                },
              ],
            }),
          }),
        );
      }
      this.props.dispatch(
        clientBannerGet({
          json: JSON.stringify({
            Client: [
              {
                ClientId: newClientId,
              },
            ],
          }),
        }),
      );
      this.props.resetClientReactivate();
    }
    if (isInserted && isInserted != this.props.isInserted) {
      this.props.resetUpsertPatientEnrollment();
      this.setState(
        {
          newClientId: newClientId,
          recordInfo: recordInfo,
        },
        () => {
          this.toggle(recordInfo.Msg);
        },
      );

      let ClientId = this.getClientId();
      if (ClientId) {
        this.props.dispatch(
          getPatientDetail({
            json: JSON.stringify({
              Client: [
                {
                  ClientId: newClientId || ClientId,
                },
              ],
            }),
          }),
        );
        this.props.dispatch(
          clientBannerGet({
            json: JSON.stringify({
              Client: [
                {
                  ClientId: newClientId || ClientId,
                },
              ],
            }),
          }),
        );
      }

      // if (
      //   this.getStudentId() > 0 &&
      //   !(recordInfo.oldEnrollStatusId == 0 && recordInfo.enrollStatusId == 1)
      // ) {
      //   this.toggle();
      // } else {
      //   const me = this;
      //   this.setState(
      //     { modalMessage: 'Enrollment was successfully saved.', isNewSaveModelOpen: true },
      //     () => {
      //       setTimeout(() => {
      //         me.setState({ modalMessage: '', isNewSaveModelOpen: false }, () => {
      //           var searchStr =
      //             'surveyId=' + recordInfo.surveyId + '&studentId=' + recordInfo.studentId;
      //           const isWebCam = localStorage.getItem('isWebCam') === '0' ? false : true;
      //           if (recordInfo.enrollStatusId == 1) {
      //             if (isWebCam) {
      //               me.props.history.push({
      //                 pathname: '/camera',
      //                 search: searchStr,
      //               });
      //             } else {
      //               me.props.history.push({
      //                 pathname: '/pin',
      //                 search: searchStr,
      //               });
      //             }
      //           } else this.props.history.push('/students');
      //         });
      //       }, 1000);
      //     },
      //   );
      // }
    }

    if (errorMessage) {
      var me = this;
      this.setState({ isModelOpen: true, errorMessage: errorMessage }, () => {
        me.state.isModelOpen = false;
        me.state.errorMessage = '';
      });
    }
    if (clientInfo)
      if (clientInfo && this.getClientId()) {
        let episode;
        this.setState({
          printDetails: bannerDetails && bannerDetails.Banner && bannerDetails.Banner[0],
        });
        if (clientInfo.Episode) {
          episode = clientInfo.Episode.map(item => {
            return {
              ...item,
              Value: item.ClientId,
              Text: item.EpisodeId,
            };
          });
        }
        const clientData = {
          firstName: clientInfo.FirstName,
          lastName: clientInfo.LastName,
          middleName: clientInfo.MiddleName,
          dateofBirth:
            clientInfo.DateOfBirth && moment(clientInfo.DateOfBirth).format('MM/DD/YYYY'),
          age: clientInfo.Age,
          gender: clientInfo.Gender,
          ssn: clientInfo.SSN,
          line1: clientInfo.AddressLine1,
          line2: clientInfo.AddressLine2,
          city: clientInfo.City,
          stateOrProvince: clientInfo.State,
          zip: clientInfo.ZipCode,
          primaryCN: clientInfo.PrimaryPhone,
          email: clientInfo.Email,
          race: clientInfo.EthnicRaceId,
          religion: clientInfo.FaithTypeId,
          education: clientInfo.EducationTypeId,
          maritalStatus: clientInfo.MaritalStatus,
          practitioner: clientInfo.PractitionerId,
          isPregnant: clientInfo.IsPregnant,
          ethnicityStatus: clientInfo.EthnicityId,
          veteranStatus: clientInfo.VeteranId,
          referralSource: clientInfo.ReferralSourceId,
          centerProgramType: clientInfo.ProgramId,
          centerServiceType: clientInfo.CenterServiceTypeId,
          centerId: clientInfo.CenterId,
          dischargeDate: clientInfo.DischargeDate,
          houseHoldincome: clientInfo.HouseHoldIncome,
          programLevel: clientInfo.ProgramLevelId,
          admissionDate: clientInfo.AdmissionDate,
          clientStatus: clientInfo.ClientStatusId,
          demographicNotes: clientInfo.Notes,
          projectedCompletionDate: clientInfo.ProjectedCompletionDate,
          height: clientInfo.ClientVital && clientInfo.ClientVital[0].Height,
          weight: clientInfo.ClientVital && clientInfo.ClientVital[0].Weight,
          BMI: clientInfo.ClientVital && clientInfo.ClientVital[0].BMI,
          bloodPressure: clientInfo.ClientVital && clientInfo.ClientVital[0].BloodPressure,
          temperature: clientInfo.ClientVital && clientInfo.ClientVital[0].Temperature,
          isStudentSync: clientInfo.IsStudentSync,
          isMobileUser: clientInfo.IsMobileUser,
          isStudent: clientInfo.IsStudent,
          userGuid: clientInfo.UserGuid,
          enterpriseStudentId: clientInfo.StudentId,
          isIntakeUser: clientInfo.IsIntakeUser,
        };
        const demographicData = {
          firstName: clientInfo.FirstName,
          lastName: clientInfo.LastName,
          middleName: clientInfo.MiddleName,
          dateofBirth:
            clientInfo.DateOfBirth && moment(clientInfo.DateOfBirth).format('MM/DD/YYYY'),
          age: clientInfo.Age,
          gender: clientInfo.Gender,
          ssn: clientInfo.SSN,
          line1: clientInfo.AddressLine1,
          line2: clientInfo.AddressLine2,
          city: clientInfo.City,
          stateOrProvince: clientInfo.State,
          zip: clientInfo.ZipCode,
          primaryCN: clientInfo.PrimaryPhone,
          email: clientInfo.Email,
          race: clientInfo.EthnicRaceId,
          religion: clientInfo.FaithTypeId,
          education: clientInfo.EducationTypeId,
          maritalStatus: clientInfo.MaritalStatus,
          practitioner: clientInfo.PractitionerId,
          isPregnant: clientInfo.IsPregnant,
          ethnicityStatus: clientInfo.EthnicityId,
          veteranStatus: clientInfo.VeteranId,
          referralSource: clientInfo.ReferralSourceId,
          houseHoldincome: clientInfo.HouseHoldIncome,
          programLevel: clientInfo.ProgramLevelId,
          centerProgramType: clientInfo.ProgramId,
          centerServiceType: clientInfo.CenterServiceTypeId,
          admissionDate: clientInfo.AdmissionDate,
          clientStatus: clientInfo.ClientStatusId,
          demographicNotes: clientInfo.Notes,
          projectedCompletionDate: clientInfo.ProjectedCompletionDate,
          height: clientInfo.ClientVital && clientInfo.ClientVital[0].Height,
          weight: clientInfo.ClientVital && clientInfo.ClientVital[0].Weight,
          bloodPressure: clientInfo.ClientVital && clientInfo.ClientVital[0].BloodPressure,
          temperature: clientInfo.ClientVital && clientInfo.ClientVital[0].Temperature,
          centerId: clientInfo.CenterId,
          displayClientId: clientInfo.DisplayClientId,
        };
        if (clientInfo.ClientContact && clientInfo.ClientContact.length > 0) {
          clientData.EmergencyContact = this.mapClientContactToState(clientInfo.ClientContact);
          demographicData.EmergencyContact = this.mapClientContactToState(clientInfo.ClientContact);
        } else {
          clientData.EmergencyContact = [{}];
          demographicData.EmergencyContact = [{}];
        }

        // if (clientInfo.ClientInsurance && clientInfo.ClientInsurance[0]) {
        //   this.mapClientInsurance(clientData, clientInfo.ClientInsurance[0]);
        // }

        if (clientInfo.ClientPayment && clientInfo.ClientPayment[0]) {
          this.mapClientPayment(clientData, clientInfo.ClientPayment[0]);
        }
        if (clientInfo.ClientMedical && clientInfo.ClientMedical[0]) {
          this.mapClientMedical(clientData, clientInfo.ClientMedical[0]);
        }

        this.setState({
          clientData: clientData,
          printDetails: bannerDetails && bannerDetails.Banner && bannerDetails.Banner[0],
          isDeActivated: clientInfo.IsDeActivated,
          IsArchived: clientInfo.IsArchived,
          displayClientId: clientInfo.DisplayClientId,
          patientId: clientInfo.ClientId,
          episode: episode,
          enterpriseLink: clientInfo.EnterpriseLink,
          demographicData: demographicData,
        });
      } else {
        this.setState({
          activeKey: 0,
          activeTabs: [true],
          // activeKey: this.state.ClientId > -1 ? null : this.state.activeKey || 0,
          clientData: {
            SubscriberInformation: [{}],
            EmergencyContact: [{}],
            ClientMedical: [{}],
            ClientPayment: [{}],
            country: 'USA',
          },
        });
      }
    if (isInvitationSent && isInvitationSent != this.props.isInvitationSent) {
      this.SynchronizedModalToggle(inviteMsg);
    }
  }
  mapClientContactToState(data) {
    let EmergencyContacts = [{}];
    if (data) {
      EmergencyContacts = [];
      for (let item of data) {
        let emergencyContact = {};
        emergencyContact.contactId = item.ContactId;
        emergencyContact.firstName = item.FirstName;
        emergencyContact.middleName = item.MiddleName;
        emergencyContact.lastName = item.LastName;
        emergencyContact.line1 = item.AddressLine1;
        emergencyContact.line2 = item.AddressLine2;
        emergencyContact.line3 = item.AddressLine3;
        emergencyContact.city = item.City;
        emergencyContact.notes = item.Notes;
        emergencyContact.stateOrProvince = item.StateOrProvince;
        emergencyContact.zip = item.ZipCode;
        emergencyContact.country = item.CountryISO;
        emergencyContact.emergencyCN = item.EmergencyPhone;
        emergencyContact.primaryCN = item.PrimaryPhone;
        emergencyContact.secondaryCN = item.SecondaryPhone;
        emergencyContact.email = item.Email;
        emergencyContact.relationshipId = item.RelationShipId;

        EmergencyContacts.push(emergencyContact);
      }
    }
    return EmergencyContacts;
  }

  // mapClientInsurance(clientData, item) {
  //   clientData.clientInsuranceId = item.ClientInsuranceId;
  //   clientData.primaryInsurance = item.PrimaryInsuranceId;
  //   clientData.insuranceName = item.InsuranceName;
  //   clientData.insurerFirstName = item.InsurerFirstName;
  //   clientData.insurerLastName = item.InsurerLastName;
  //   clientData.insurerMiddleName = item.InsurerMiddleName;
  //   clientData.patientRelationshipToPrimaryInsured = item.PatientRelationshipToPrimaryInsured;
  //   clientData.groupNumber = item.GroupNumber;
  //   clientData.planName = item.PlanName;
  //   clientData.insuredAuthorization = item.InsuredAuthorization;
  //   clientData.deductible = item.Deductible;
  //   clientData.patientCoPayment = item.PatientCoPayment;
  //   clientData.signatureOnFile = item.ReleaseOfInformation;
  //   clientData.signatureDate = item.SignatureDate;
  // }
  mapClientPayment(clientData, item) {
    clientData.clientPaymentId = item.ClientPaymentId;
    clientData.paymentId = item.PaymentId;
    clientData.patientName = item.PatientName;
    clientData.paymentDate = item.PaymentDate;
    clientData.payerType = item.PayerType;
    clientData.payerName = item.PayerName;
    clientData.paymentMethod = item.PaymentMethod;
    clientData.checkNumber = item.CheckNumber;
    clientData.paymentAmount = item.PaymentAmount;
    clientData.description = item.Description;
    clientData.applyToPatientId = item.ApplyToPatientId;
  }
  mapClientMedical(clientData, item) {
    clientData.pcpName = item.PrimaryCareProvider;
    clientData.pcpPhoneNumber = item.PhoneNumber;
    clientData.pcpAddress = item.Address;
    clientData.pcpPreferredHospital = item.PrefferedHospital;
    clientData.pcpHospitalPhoneNumber = item.HospitalPhoneNumber;
    clientData.allergies = item.AllergiesId;
    clientData.allergiesNotes = item.AllergiesNote;
    clientData.medication = item.MedicationId;
    clientData.medicationNotes = item.MedicationNote;
    clientData.vaccination = item.VaccinationId;
    clientData.vaccinationNotes = item.VaccinationNote;
    clientData.diseaseConditions = item.DiseaseId;
    clientData.diseaseConditionsNotes = item.DiseaseNote;
    clientData.listSurgeries = item.SurgeriesId;
    clientData.listSurgeriesNotes = item.SurgeriesNote;
    clientData.psychiatric = item.PsychiatricId;
    clientData.psychiatricNotes = item.PsychiatricNote;
    clientData.familyMedicalHistory = item.FamilyMedicalHistoryId;
    clientData.otherIssues = item.OtherIssue;
  }
  mapStudentMore(data, item) {
    data.referenceSourceId = item.ReferenceSourceId;
    data.isEmployed = item.IsEmployed;
    data.lastEmployedDate = item.LastEmployedDate;
    data.employmentStartDate = item.EmploymentStartDate;
    data.faithTypeId = item.FaithTypeId;
    data.isChurchGoing = item.IsChurchGoing;
    data.churchName = item.ChurchName;
    data.faithNotes = item.FaithNotes;
    data.churchCity = item.ChurchCity;
    data.churchState = item.ChurchState;
    data.churchCountry = item.ChurchCountry;
    data.churchDenominationId = item.ChurchDenominationId;
    data.isBornAgain = item.IsBornAgain;
    data.isSpiritFilled = item.IsSpiritFilled;
    data.exitReasonNotes = item.ExitReasonNotes;
    data.housingTypeId = item.HousingTypeId;
    data.demographicNotes = item.Notes;
  }

  setRef = webcam => {
    this.webcam = webcam;
  };
  capture = imageSrc => {
    // const imageSrc = this.webcam.getScreenshot();
    const me = this;
    Axios.post('student/UploadImage', {
      image: imageSrc,
      studentId: this.state.ClientId,
      IsProfilePic: true,
    })
      .then(response => {
        me.props.dispatch(
          getPatientDetail({
            json: JSON.stringify({
              Client: [
                {
                  ClientId: this.state.ClientId,
                },
              ],
            }),
          }),
        );
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };

  resetIsOpen = () => {
    this.setState({
      // twilioToken: '',
      callModelVisible: false,
      clickedRecord: {},
      // device: '',
    });
  };

  isAuditorLogin = () => {
    let isReadOnlyUserSession = false;
    if (localStorage.getItem('giveRead') == 'true') {
      isReadOnlyUserSession = true;
    }
    return isReadOnlyUserSession;
  };
  render() {
    const {
      handleSubmit,
      Practitioner,
      clientInfo,
      isFetching,
      isUpserting,
      defaultAddictionProfileTypeId,
      defaultCounsellor,
      defaultHomeAddressType,
      defaultLanguage,
      defaultFaithType,
      errorMessage,
      defaultCenter,
      defaultEnrollDate,
      printClientChart,
      clientMatchedWithStudent,
      isExternalStaff,
    } = this.props;
    let PractitionerList = this.getClientId()
      ? Practitioner
      : Practitioner.filter(provider => provider.IsArchived === false) || [];
    const { onSubmit } = this;
    const { modalMessage, showError, modalVisible } = this.state;
    const {
      StateProvince,
      Center,
      Counsellor,
      ReferenceSource,
      NameSuffix,
      NamePrefix,
      Country,
      HomeAddressType,
      ChurchDenomination,
    } = this.props.combos;

    const { ClientId, activeKey, tabLabels, activeTabs, clientData, recordInfo } = this.state;

    let studentSync = clientData && clientData.isStudentSync && !this.props.isReadOnlyUser;
    let intakeUser = clientData && clientData.isIntakeUser && !this.props.isReadOnlyUser;
    let mobileUser = clientData && clientData.isMobileUser && !this.props.isReadOnlyUser;
    let enterpriseStudent = clientData && clientData.isStudent && !this.props.isReadOnlyUser;

    return (
      <div>
        <PageTitle TitleText="Patient Management" />
        <div class="w-100 rcm-panel">
          <div class="main enrollment-page mb-5">
            <div class="container-fluid px-5">
              <div class="content-area">
                <div>
                  {/* <div class="row top-heading-row mt-2 mb-3 align-items-center"></div> */}
                  {showError && (
                    <div class="alert alert-danger alert-panel" role="alert">
                      Please fill the entire form
                    </div>
                  )}

                  <div
                    class="top-table-search d-flex align-items-center pt-2"
                    style={{ justifyContent: 'space-between' }}
                  >
                    {/* <div
                        className="mt-3 mb-3 cursor-pointer"
                        style={{ display: 'inline-block', marginRight: '20px' }}
                      >
                        <a class="back-link" onClick={() => this.props.history.goBack()}>
                          <img src="images/back-arrow.svg" /> Back
                        </a>
                      </div> */}
                    <div className="mt-3 cursor-pointer" style={{ display: 'inline-block' }}>
                      <a
                        class="back-link"
                        onClick={() =>
                          this.props.history.push(`/cw/patient-management/patient-list`)
                        }
                      >
                        <img src="images/back-arrow.svg" /> Back to patient list
                      </a>
                    </div>
                    <div>
                      {!isExternalStaff && (
                        <div className="d-flex align-items-center mt-2">
                          {mobileUser && (
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={() => {
                                this.props.history.push(
                                  '/subscriberProfile/' + clientData.userGuid,
                                );
                              }}
                            >
                              Switch to Subscriber
                            </button>
                          )}
                          {enterpriseStudent && (
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={() => {
                                this.props.history.push(this.state.enterpriseLink);
                              }}
                            >
                              Switch to Enterprise
                            </button>
                          )}
                          {studentSync && (
                            <AntdModal
                              modalWidth={800}
                              ModalButton={({ onClick }) => (
                                <ActionableIcon
                                  title="Copy to Enterprise"
                                  iconSrc="images/copy-to.svg"
                                  onClick={() => {
                                    onClick();
                                  }}
                                />
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                  <div class="modal-body pb-0 pr-2 pl-2  pr-xl-5 pl-xl-5">
                                    <span
                                      class="modal-title user-merging d-flex align-items-center col-12 mb-3"
                                      style={{ fontSize: '22px' }}
                                    >
                                      {clientMatchedWithStudent
                                        ? renderHTML(clientMatchedWithStudent.Message)
                                        : `Are you sure you want to copy this patient to enterprise system`}
                                      <br />
                                    </span>
                                  </div>
                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      class="btn btn-outline-primary black-btn"
                                      onClick={() => {
                                        onCancel();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="btn"
                                      onClick={() => {
                                        onOk();
                                        this.props.dispatch(
                                          clientStudentSynchronize({
                                            ClientId: this.state.ClientId,
                                            StudentId: clientMatchedWithStudent
                                              ? clientMatchedWithStudent.PatientId
                                              : null,
                                          }),
                                        );
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          )}
                          {intakeUser && (
                            <AntdModal
                              modalWidth={800}
                              ModalButton={({ onClick }) => (
                                <ActionableIcon
                                  title="Move back to Intake"
                                  iconSrc="images/move-to-prospect.svg"
                                  onClick={() => {
                                    onClick();
                                  }}
                                />
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                  <div class="modal-body pb-0 pr-2 pl-2  pr-xl-5 pl-xl-5">
                                    <span
                                      class="modal-title user-merging d-flex align-items-center col-12 mb-3"
                                      style={{ fontSize: '22px' }}
                                    >
                                      {`Are you sure you want to move this patient back to Intake?`}
                                      <br />
                                    </span>
                                  </div>
                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      class="btn btn-outline-primary black-btn"
                                      onClick={() => {
                                        onCancel();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="btn"
                                      onClick={() => {
                                        onOk();
                                        this.props.dispatch(
                                          clinicalToIntakeMovePatient({
                                            JSON: JSON.stringify({
                                              Client: {
                                                ClientId: this.getClientId(),
                                              },
                                            }),
                                          }),
                                        );
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          )}
                          {!this.props.isFetching && this.getClientId() && (
                            <ActionableIcon
                              title="Print"
                              iconSrc="images/print_action.svg"
                              onClick={() => {
                                this.setState({
                                  isClientModalOpen: true,
                                });
                              }}
                            />
                          )}
                          {this.state.episode && (
                            <div className="mx-2">
                              <div className="text-left filter-dropdown d-inline-block">
                                <div
                                  className="form-group call-schedule episodes-dropdown grid-filter mb-0 mr-3"
                                  style={{ width: '120px' }}
                                >
                                  <ReduxSelect
                                    options={this.state.episode}
                                    onChange={val => {
                                      this.props.dispatch(
                                        getPatientDetail({
                                          json: JSON.stringify({
                                            Client: [
                                              {
                                                ClientId: val,
                                              },
                                            ],
                                          }),
                                        }),
                                      );
                                      this.props.dispatch(
                                        clientBannerGet({
                                          json: JSON.stringify({
                                            Client: [
                                              {
                                                ClientId: val,
                                              },
                                            ],
                                          }),
                                        }),
                                      );
                                      this.props.history.push(
                                        `/cw/patient-management/patient-profile?tab=demographic&pId=${val}`,
                                      );

                                      this.setState({ epsodeValue: val });
                                      // window.location.reload();
                                    }}
                                    // }}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={this.state.patientId}
                                    // placeholder={item.AdditionalFieldName}
                                    // disabled={this.state.IsArchived}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.patientId > 0 && (
                    <ClientProfileHeader
                      isReadOnlyUser={this.props.isReadOnlyUser || isExternalStaff}
                      IsArchived={this.state.IsArchived}
                    />
                  )}
                  {!this.getClientId() && <h5>Add New Patient</h5>}
                  <div class="row">
                    <div class="col-12 mb-5 vertical-tabs pat-tab rcm-group-tab">
                      {this.props.isFetching && (
                        <div style={{ height: '100vh', position: 'relative' }}>
                          <Loader2 loading={this.props.isFetching} />
                        </div>
                      )}
                      {!this.props.isFetching && (
                        <Tabs
                          activeKey={String(activeKey)}
                          tabPosition="left"
                          defaultActiveKey="0"
                          onTabClick={this.onTabClick}
                          // style={{ height: '100vh' }}
                        >
                          {tabLabels &&
                            tabLabels.length > 0 &&
                            tabLabels.map((item, index) => {
                              const { Component, Name } = item;
                              return (
                                <TabPane
                                  // tab={Name}
                                  tab={
                                    <div
                                      className="text-left"
                                      // style={{ whiteSpace: 'break-spaces' }}
                                    >
                                      {Name}
                                    </div>
                                  }
                                  key={`${index}`}
                                  className="tab-content enrollment-form"
                                  style={{ overflow: 'visible' }}
                                >
                                  {activeKey == index && (
                                    <Component
                                      style={{ padding: '36px 20px 150px 20px' }}
                                      handleNext={() => this.handleNext(index)}
                                      // initialValues={clientData}
                                      initialValues={this.state.demographicData}
                                      clientInfo={clientInfo}
                                      defaultCounsellor={defaultCounsellor}
                                      defaultCenter={defaultCenter}
                                      defaultEnrollDate={defaultEnrollDate}
                                      defaultLanguage={defaultLanguage}
                                      defaultFaithType={defaultFaithType}
                                      defaultHomeAddressType={defaultHomeAddressType}
                                      defaultAddictionProfileTypeId={defaultAddictionProfileTypeId}
                                      StateProvince={StateProvince}
                                      clientId={this.state.patientId}
                                      Center={Center}
                                      Counsellor={Counsellor}
                                      Practitioner={PractitionerList}
                                      ReferenceSource={ReferenceSource}
                                      NameSuffix={NameSuffix}
                                      NamePrefix={NamePrefix}
                                      Country={Country}
                                      HomeAddressType={HomeAddressType}
                                      ChurchDenomination={ChurchDenomination}
                                      dispatch={this.props.dispatch}
                                      // onCancel={() => this.props.history.goBack()}
                                      onCancel={this.props.toggleView}
                                      isFetching={isFetching}
                                      isUpserting={isUpserting}
                                      updateDeleteModel={this.updateDeleteModel}
                                      onLastSubmit={onSubmit.bind(this)}
                                      goToPreviuos={() => this.goToPreviuos(index)}
                                      onSubmit={onSubmit.bind(this)}
                                      enableReinitialize={true}
                                      keepDirtyOnReinitialize={true}
                                      disable={this.getDischarged()}
                                      isDeActivated={this.state.isDeActivated}
                                      changeActiveTab={this.changeActiveTab}
                                      history={this.props.history}
                                      isReadOnlyUser={this.props.isReadOnlyUser || isExternalStaff}
                                      isReadOnlyUserSession={this.isAuditorLogin()}
                                    />
                                  )}
                                </TabPane>
                              );
                            })}
                        </Tabs>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.isModelOpen && (
                  <CustomModal
                    isCustomClass={true}
                    isOpen={this.state.isModelOpen}
                    header={
                      errorMessage || this.state.header
                      // errorMessage || this.state.header || 'Your changes have been saved successfully'
                    }
                    // header={'Your Changes have been saved successfully'}
                    yesAction={() => {
                      this.toggle();
                      this.props.history.push(
                        `/cw/patient-management/patient-profile?tab=demographic&pId=${this.state
                          .newClientId || this.state.patientId}`,
                      );
                      // this.props.toggleView();
                      // this.props.history.goBack();
                    }}
                    yesText={'OK'}
                    hideCancel={true}
                    changeMaxWidth="100%"
                  />
                )}
                {this.state.isModelOpen2 && (
                  <CustomModal
                    isOpen={this.state.isModelOpen2}
                    header={this.state.failMsg}
                    yesAction={() => {
                      this.toggle2();
                      this.props.history.push(
                        `/cw/patient-management/patient-profile?tab=assessment-tool&pId=${this.state.patientId}`,
                      );
                    }}
                    yesText="OK"
                    hideCancel={true}
                    changeMaxWidth="100%"
                  />
                )}
                {this.state.isIntakeModal && (
                  <CustomModal
                    isOpen={this.state.isIntakeModal}
                    header={'Patient moved back to Intake successfully'}
                    yesAction={() => {
                      this.setState({
                        isIntakeModal: false,
                      });
                      this.props.history.push(`/cw/patient-management/patient-list`);
                    }}
                    yesText="OK"
                    hideCancel={true}
                    changeMaxWidth="100%"
                  />
                )}
                {this.state.isNewSaveModelOpen && (
                  <CustomModal
                    isOpen={this.state.isNewSaveModelOpen}
                    header={modalMessage}
                    hideCancel={true}
                    hideOk={true}
                  />
                )}
                {this.state.isDeleteModelOpen && (
                  <CustomModal
                    isOpen={this.state.isDeleteModelOpen}
                    header={this.state.deleteModelMessage}
                    yesAction={() => {
                      this.deleteModelToggle();
                    }}
                    yesText="OK"
                    noText="Cancel"
                    noAction={() => this.setState({ isDeleteModelOpen: false })}
                  />
                )}
                {this.state.isDeleteModelOpen && (
                  <CustomModal
                    isOpen={this.state.isDeleteModelOpen}
                    header={this.state.deleteModelMessage}
                    yesAction={() => {
                      this.deleteModelToggle();
                    }}
                    yesText="OK"
                    noText="Cancel"
                    noAction={() => this.setState({ isDeleteModelOpen: false })}
                  />
                )}
                {this.state.isSyncModalOpen && (
                  <CustomModal
                    isOpen={this.state.isSyncModalOpen}
                    header={this.state.header}
                    yesAction={() => {
                      // this.SynchronizedModalToggle();
                    }}
                    hideCancel={true}
                    yesText="Close"
                  />
                )}
                {this.state.isSyncModalOpen && (
                  <CustomModal
                    isOpen={this.state.isSyncModalOpen}
                    header={this.state.header}
                    yesAction={() => {
                      this.SynchronizedModalToggle();
                    }}
                    hideCancel={true}
                    yesText="Close"
                  />
                )}
                {this.state.isClientModalOpen && (
                  <Drawer
                    className="custom-drawer print-drawer"
                    title="Print Client Chart"
                    open={this.state.isClientModalOpen}
                    onClose={() => {
                      this.setState({
                        isClientModalOpen: !this.state.isClientModalOpen,
                      });
                    }}
                    width={700}
                    bodyStyle={{ paddingBottom: 80 }}
                    destroyOnClose={true}
                    placement="right"
                    // className="print-drawer"
                  >
                    <PrintClientChart
                      onCancel={() => {
                        this.setState({ isClientModalOpen: false });
                      }}
                      printClientChart={printClientChart}
                      getClientId={this.getClientId()}
                    />
                  </Drawer>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    upsert_patient_enrollment,
    get_patient_detail,
    practitioner_combos,
    student_get,
    combos,
    default_values,
    add_new_applicant,
    client_student_synchronize,
    staff_accessed_tab_get,
    client_reactivate,
    client_banner_get,
    client_chart_list_get,
    combo_practitioner_center_get,
    client_send_mobile_app_invite,
    clinical_to_intake_move_patient,
    center_config_get,
  } = state;
  const { data: practitionerData } = combo_practitioner_center_get;
  let { data: bannerDetails } = client_banner_get;
  let { data: clientChart } = client_chart_list_get;
  let { data: appInviteData } = client_send_mobile_app_invite;
  const { data: centerConfig } = center_config_get;

  let Practitioner = [];

  var defaultCounsellor = null;
  var defaultLanguage = null;
  var defaultFaithType = null;
  var defaultHomeAddressType = null;
  var defaultAddictionProfileTypeId = null;
  var defaultCenter = null;
  var defaultEnrollDate = null;
  let patientTabsMenu = null;
  let printClientChart = [];
  let isReadOnlyUser = false;
  let clientMatchedWithStudent = null;
  let isMovedToIntake = false;
  let isExternalStaff = null;

  if (
    clinical_to_intake_move_patient &&
    clinical_to_intake_move_patient.data &&
    clinical_to_intake_move_patient.data.success
  ) {
    isMovedToIntake = true;
  }

  if (bannerDetails && bannerDetails.Banner && bannerDetails.Banner[0].ClientMatchedWithStudent) {
    clientMatchedWithStudent =
      bannerDetails.Banner[0].ClientMatchedWithStudent.Student &&
      bannerDetails.Banner[0].ClientMatchedWithStudent.Student[0];
  }

  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner = practitionerData.PractitionerList || [];
  }
  if (clientChart && clientChart.ClientChart) {
    printClientChart = clientChart.ClientChart;
  }
  if (default_values && default_values.data) {
    defaultCounsellor = default_values.data.userId;
    defaultLanguage = default_values.data.language;
    defaultFaithType = default_values.data.faithTypeId;
    defaultHomeAddressType = default_values.data.homeAddressType;
    defaultAddictionProfileTypeId = default_values.data.addictionProfileTypeId;
    defaultCenter = default_values.data.centerId;
    defaultEnrollDate = default_values.data.enrollDate;
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  // var submitErrors = getFormSyncErrors('EnrollmentForm')(state);
  var { data, error, isFetching: isUpserting } = upsert_patient_enrollment;
  const { data: clientInfo, isFetching: isFetchingClientInfo } = get_patient_detail;
  const { data: studentInfo, isFetching } = student_get;
  var isInserted = false;
  let isReactivated = false;
  let isSynchronized = false;
  var recordInfo = null;
  var inviteMsg = null;
  let isInvitationSent = false;

  var newClientId;
  var newStudentId;
  var errorMessage = '';
  if (upsert_patient_enrollment && upsert_patient_enrollment.data) {
    if (data && data.success) {
      isInserted = true;
      newClientId = data.result.ClientId;
      recordInfo = data.result;
    }
  }
  if (client_reactivate && client_reactivate.data && client_reactivate.data.success) {
    isReactivated = true;
    newClientId = client_reactivate.data.result.ClientId;
  }
  if (
    client_student_synchronize &&
    client_student_synchronize.data &&
    client_student_synchronize.data.success
  ) {
    isSynchronized = true;
    newStudentId = client_student_synchronize.data.data[0].ClientId;
  }
  if (error) {
    //errorMessage = error.Message;
  }
  if (staff_accessed_tab_get && staff_accessed_tab_get.data) {
    patientTabsMenu =
      staff_accessed_tab_get &&
      staff_accessed_tab_get.data &&
      staff_accessed_tab_get.data.Tabs &&
      staff_accessed_tab_get.data.Tabs[0].PatientTabs;
  }
  if (clientInfo && clientInfo.Banner) {
    delete clientInfo.Banner;
  }

  if (appInviteData && appInviteData.success) {
    inviteMsg = appInviteData.data;
    isInvitationSent = true;
  }
  if (centerConfig && centerConfig.CenterConfig) {
    isExternalStaff = centerConfig.CenterConfig[0] && centerConfig.CenterConfig[0].IsExternalStaff;
    // isReadOnlyUser = centerConfig.CenterConfig[0] && centerConfig.CenterConfig[0].IsExternalStaff;
  }

  return {
    isInserted: isInserted,
    studentInfo: studentInfo,
    clientInfo: clientInfo,
    combos: combos.data,
    Practitioner: Practitioner,
    recordInfo: recordInfo,
    errorMessage: errorMessage,
    // submitErrors: submitErrors,
    isFetching: isFetching || isFetchingClientInfo,
    isUpserting,
    defaultCounsellor,
    defaultLanguage,
    defaultFaithType,
    defaultHomeAddressType,
    defaultAddictionProfileTypeId,
    defaultCenter,
    defaultEnrollDate,
    newClientId,
    isSynchronized,
    newStudentId,
    patientTabsMenu,
    isReadOnlyUser,
    isReactivated,
    bannerDetails,
    printClientChart,
    clientMatchedWithStudent,
    inviteMsg,
    isInvitationSent,
    isMovedToIntake,
    isExternalStaff,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    upsertPatientEnrollment: Client => dispatch(upsertPatientEnrollment(Client)),
    getClientCounselor: CenterId => dispatch(getClientCounselor({ CenterId })),
    getDynamicComos: ComboTypes => dispatch(getDynamicComos(ComboTypes)),
    medicateCombos: ComboTypes => dispatch(medicateCombos(ComboTypes)),
    resetUpsertPatientEnrollment: () => dispatch(resetUpsertPatientEnrollment()),
    resetClientReactivate: () => dispatch(resetClientReactivate()),
    remoteSubmit: () => dispatch(submit('patientEnrollmentForm')),
    reset: () => dispatch(destroy('patientEnrollmentForm')),
    // clearSubmit: () => dispatch(clearSubmit('patientEnrollmentForm')),
    addNewApplicant: payload => dispatch(addNewApplicant(payload)),
    dispatch: dispatch,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlidingTabs));
