import { combineReducers } from 'redux';
import { ReduxGenerator } from '../../core';
import { reducer as form } from 'redux-form';
import actionsSave from './actionsSave';

import Combo from './combo';
import lastUpdatedDimensionId from './lastUpdatedTreatmentPlan';

import * as Counter from './Counter';
import * as WeatherForecasts from './WeatherForecasts';
import * as featchData from './FeatchUserData';

const rootReducer = combineReducers({
  combos: Combo,
  lastUpdatedDimensionId: lastUpdatedDimensionId,
  get_form_field_meta_data: ReduxGenerator.getReducer('GET_FORM_FIELD_META_DATA'),
  display_error: ReduxGenerator.getReducer('DISPLAY_ERROR'),
  dashboard_get: ReduxGenerator.getReducer('DASHBOARD_GET'),
  students: ReduxGenerator.getReducer('STUDENTS'),
  student_courses: ReduxGenerator.getReducer('STUDENT_GET_ALL_COURSES'),
  student_add: ReduxGenerator.getReducer('STUDENT_ADD'),
  student_note_add: ReduxGenerator.getReducer('STUDENT_NOTE_ADD'),
  student_couse_assign: ReduxGenerator.getReducer('STUDENT_COURSE_ASSIGN'),
  student_pin_upsert: ReduxGenerator.getReducer('STUDENT_PIN_UPSERT'),
  student_validate_pin: ReduxGenerator.getReducer('STUDENT_VALIDATE_PIN'),
  student_mark_complete: ReduxGenerator.getReducer('STUDENT_MARK_COMPLETE'),
  student_unassign: ReduxGenerator.getReducer('STUDENT_UNASSIGN'),
  student_get: ReduxGenerator.getReducer('STUDENT_GET'),
  student_add_existing_task: ReduxGenerator.getReducer('STUDENT_ADD_EXISTING_TASK'),
  student_discharge: ReduxGenerator.getReducer('STUDENT_DISCHARGE'),
  student_reinstate: ReduxGenerator.getReducer('STUDENT_REINSTATE'),
  student_task_upsert: ReduxGenerator.getReducer('STUDENT_TASK_UPSERT'),
  surveys: ReduxGenerator.getReducer('SURVEY_STUDENTS_GET_ALL_PENDING'),
  survey_assign: ReduxGenerator.getReducer('SURVEY_ASSIGN'),
  survey_get_to_assign: ReduxGenerator.getReducer('SURVEY_GET_TO_ASSIGN'),
  survery_question_get_multiple: ReduxGenerator.getReducer('SURVEY_QUESTION_GET_MULTIPLE'),
  survey_answer_upsert: ReduxGenerator.getReducer('SURVEY_ANSWER_UPSERT'),
  login: ReduxGenerator.getReducer('LOGIN'),
  forget_password: ReduxGenerator.getReducer('FORGET_PASSWORD'),
  reset_password: ReduxGenerator.getReducer('RESET_PASSWORD'),
  counter: Counter.reducer,
  weatherForecasts: WeatherForecasts.reducer,
  featchuser: featchData.featchReducer,
  todos: ReduxGenerator.getReducer('TODO_GET_ALL'),
  todos_by_student: ReduxGenerator.getReducer('TODO_GET_ALL_BY_STUDENT'),
  activities: ReduxGenerator.getReducer('ACTIVITY_GET_ALL'),
  activities_by_student: ReduxGenerator.getReducer('ACTIVITY_GET_ALL_BY_STUDENT'),
  todo_add: ReduxGenerator.getReducer('TODO_ADD'),
  student_task_unassign: ReduxGenerator.getReducer('STUDENT_TASK_UNASSIGN'),
  student_task_mark_complete: ReduxGenerator.getReducer('STUDENT_TASK_MARK_COMPLETE'),
  survey_task_mark_in_complete: ReduxGenerator.getReducer('SURVEY_TASK_MARK_IN_COMPLETE'),
  get_report_data: ReduxGenerator.getReducer('GET_REPORT_DATA'),
  course_get_all: ReduxGenerator.getReducer('COURSE_GET_ALL'),
  staff_get_all: ReduxGenerator.getReducer('STAFF_GET_ALL'),
  center_get_all: ReduxGenerator.getReducer('CENTER_GET_ALL'),
  course_get: ReduxGenerator.getReducer('COURSE_GET'),
  course_upsert: ReduxGenerator.getReducer('COURSE_UPSERT'),
  course_delete: ReduxGenerator.getReducer('COURSE_DELETE'),
  staff_get: ReduxGenerator.getReducer('STAFF_GET'),
  staff_upsert: ReduxGenerator.getReducer('STAFF_UPSERT'),
  staff_delete: ReduxGenerator.getReducer('STAFF_DELETE'),
  center_get: ReduxGenerator.getReducer('CENTER_GET'),
  get_center_field_by_center: ReduxGenerator.getReducer('GET_CENTER_FIELD_BY_CENTER'),
  center_upsert: ReduxGenerator.getReducer('CENTER_UPSERT'),
  center_delete: ReduxGenerator.getReducer('CENTER_DELETE'),
  get_student_survery_dashboard_data: ReduxGenerator.getReducer(
    'STUDENT_SURVEY_DASHBOARD_GET_JSON',
  ),
  log_device: ReduxGenerator.getReducer('LOG_DEVICE'),
  log_error: ReduxGenerator.getReducer('LOG_ERROR'),
  form,
  announcement_get_all: ReduxGenerator.getReducer('ANNOUNCEMENT_GET_ALL'),
  announcement_get_json: ReduxGenerator.getReducer('ANNOUNCEMENT_GET_JSON'),
  announcement_upsert: ReduxGenerator.getReducer('ANNOUNCEMENT_UPSERT'),
  announcement_delete: ReduxGenerator.getReducer('ANNOUNCEMENT_DELETE'),
  update_staff_email: ReduxGenerator.getReducer('UPDATE_STAFF_EMAIL'),
  update_staff_password: ReduxGenerator.getReducer('UPDATE_STAFF_PASSWORD'),
  update_staff_center: ReduxGenerator.getReducer('UPDATE_STAFF_CENTER'),
  default_values: ReduxGenerator.getReducer('LOAD_DEFAULT_VALUES'),
  menu: ReduxGenerator.getReducer('LOAD_MENU'),
  logout: ReduxGenerator.getReducer('LOGOUT'),
  survey_answers_get: ReduxGenerator.getReducer('SURVEY_ANSWERS_GET'),
  student_get_profile_dashboard_data: ReduxGenerator.getReducer(
    'STUDENT_GET_PROFILE_DASHBOARD_DATA',
  ),
  student_search: ReduxGenerator.getReducer('STUDENTS_SEARCH'),
  user_role_list: ReduxGenerator.getReducer('USER_ROLE_LIST'),
  list_job_title: ReduxGenerator.getReducer('LIST_JOB_TITLE'),
  update_job_title: ReduxGenerator.getReducer('UPDATE_JOB_TITLE'),
  update_role: ReduxGenerator.getReducer('UPDATE_ROLE'),
  delete_role: ReduxGenerator.getReducer('DELETE_ROLE'),
  delete_job_title: ReduxGenerator.getReducer('DELETE_JOB_TITLE'),
  update_role_permissions: ReduxGenerator.getReducer('UPDATE_ROLE_PERMISSIONS'),
  update_job_title_sync: ReduxGenerator.getReducer('UPDATE_JOB_TITLE_SYNC'),
  get_contract: ReduxGenerator.getReducer('GET_CONTRACT'),
  upsert_contract: ReduxGenerator.getReducer('UPSERT_CONTRACT'),
  delete_contract: ReduxGenerator.getReducer('DELETE_CONTRACT'),
  get_contract_template: ReduxGenerator.getReducer('GET_CONTRACT_TEMPLATE'),
  upsert_contract_template: ReduxGenerator.getReducer('UPSERT_CONTRACT_TEMPLATE'),
  get_student_contract_template: ReduxGenerator.getReducer('GET_STUDENT_CONTRACT_TEMPLATE'),
  create_contract_from_template: ReduxGenerator.getReducer('CREATE_CONTRACT_FROM_TEMPLATE'),
  add_favorite_template: ReduxGenerator.getReducer('ADD_FAVORITE_TEMPLATE'),
  remove_favorite_template: ReduxGenerator.getReducer('REMOVE_FAVORITE_TEMPLATE'),
  upload_staff_image: ReduxGenerator.getReducer('UPLOAD_STAFF_IMAGE'),
  close_contract: ReduxGenerator.getReducer('CLOSE_CONTRACT'),
  user_feedback: ReduxGenerator.getReducer('USER_FEEDBACK'),
  is_license_available: ReduxGenerator.getReducer('IS_LICENSE_AVAILABLE'),
  get_dashboard_load_app_users: ReduxGenerator.getReducer('GET_DASHBOARD_LOAD_APP_USERS'),
  get_load_user_info: ReduxGenerator.getReducer('GET_LOAD_USER_INFO'),
  get_validate_image: ReduxGenerator.getReducer('GET_VALIDATE_IMAGE'),
  get_license_activites: ReduxGenerator.getReducer('GET_LICENSE_ACTIVITES'),
  get_current_licenses: ReduxGenerator.getReducer('GET_CURRENT_LICENSES'),
  get_licenses_invoice: ReduxGenerator.getReducer('GET_LICENSES_INVOICE'),
  get_licenses_receipt: ReduxGenerator.getReducer('GET_LICENSES_RECEIPT'),
  add_licenses: ReduxGenerator.getReducer('ADD_LICENSES'),
  start_free_license: ReduxGenerator.getReducer('START_FREE_LICENSE'),
  get_payment_info: ReduxGenerator.getReducer('GET_PAYMENT_INFO'),
  is_ach_validation: ReduxGenerator.getReducer('IS_ACH_VALIDATION'),
  get_billing_info: ReduxGenerator.getReducer('GET_BILLING_INFO'),
  update_payment_methode: ReduxGenerator.getReducer('UPDATE_PAYMENT_METHODE'),
  ach_validation: ReduxGenerator.getReducer('ACH_VALIDATE'),
  add_new_applicant: ReduxGenerator.getReducer('ADD_NEW_APPLICANT'),
  get_app_subscribers: ReduxGenerator.getReducer('GET_APP_SUBSCRIBERS'),
  get_app_subscribers_dashboard: ReduxGenerator.getReducer('GET_APP_SUBSCRIBERS_DASHBOARD'),
  get_app_subscribers_profile: ReduxGenerator.getReducer('GET_APP_SUBSCRIBERS_PROFILE'),
  get_app_subscribers_chat: ReduxGenerator.getReducer('GET_APP_SUBSCRIBERS_CHAT'),
  get_app_subscribers_chat_coach: ReduxGenerator.getReducer('GET_APP_SUBSCRIBERS_CHAT_COACH'),
  get_app_subscribers_activities: ReduxGenerator.getReducer('GET_APP_SUBSCRIBERS_ACTIVITIES'),
  add_app_subscribers_survey_assign: ReduxGenerator.getReducer('APP_SUBSCRIBERS_SURVEY_ASSIGN'),
  add_app_subscribers_note: ReduxGenerator.getReducer('APP_SUBSCRIBERS_INSERT_NOTE'),
  add_app_subscribers_task: ReduxGenerator.getReducer('APP_SUBSCRIBERS_ADD_TASK'),
  delete_app_subscribers_task: ReduxGenerator.getReducer('APP_SUBSCRIBERS_DELETE_TASK'),
  delete_app_subscribers_survey: ReduxGenerator.getReducer('APP_SUBSCRIBERS_SURVEY_UNASSIGN'),
  app_subscribers_user_search: ReduxGenerator.getReducer('APP_SUBSCRIBERS_USER_SEARCH'),
  app_subscribers_complete_task: ReduxGenerator.getReducer('APP_SUBSCRIBERS_COMPLETE_TASK'),
  add_app_subscribers_course: ReduxGenerator.getReducer('APP_SUBSCRIBERS_ADD_COURSE'),
  update_app_subscribers_case: ReduxGenerator.getReducer('APP_SUBSCRIBERS_UPDATE_CASE'),
  delete_app_subscribers_course: ReduxGenerator.getReducer('APP_SUBSCRIBERS_DELETE_COURSE'),
  app_subscribers_complete_course: ReduxGenerator.getReducer('APP_SUBSCRIBERS_COMPLETE_COURSE'),
  list_mobile_appRole_get: ReduxGenerator.getReducer('GET_LIST_MOBILE_APP_ROLE'),
  sp_list_combos: ReduxGenerator.getReducer('SP_LIST_COMBOS'),
  app_subscribers_map_history: ReduxGenerator.getReducer('APP_SUBSCRIBERS_MAP_HISTORY'),
  app_invite: ReduxGenerator.getReducer('APP_INVITE'),
  get_mobile_app_download_status: ReduxGenerator.getReducer('GET_MOBILE_APP_DOWNLOAD_STATUS'),
  update_mobile_app_role: ReduxGenerator.getReducer('UPDATE_MOBILE_APP_ROLE'),
  add_note_type: ReduxGenerator.getReducer('ADD_NOTE_TYPE'),
  change_app_role: ReduxGenerator.getReducer('CHANGE_APP_ROLE'),
  two_step_auth: ReduxGenerator.getReducer('TWO_STEP_AUTH'),
  validate_passcode: ReduxGenerator.getReducer('VALIDATE_PASS_CODE'),
  app_invite_dashboard: ReduxGenerator.getReducer('APP_INVITE_DASHBOARD'),
  get_staff_shift_log: ReduxGenerator.getReducer('GET_STAFF_SHIFT_LOG'),
  add_staff_shift_log: ReduxGenerator.getReducer('ADD_STAFF_SHIFT_LOG'),
  get_all_call_session: ReduxGenerator.getReducer('GET_ALL_CALL_SESSION'),
  add_call_session: ReduxGenerator.getReducer('ADD_CALL_SESSION'),
  get_all_vacation: ReduxGenerator.getReducer('GET_ALL_VACATION'),
  add_vacation_days: ReduxGenerator.getReducer('ADD_VACATION_DAYS'),
  get_one_call_timeSlots: ReduxGenerator.getReducer('GET_ONE_CALL_TIMESLOTS'),
  get_one_call_session: ReduxGenerator.getReducer('GET_ONE_CALL_SESSION'),
  invite_one_call_session: ReduxGenerator.getReducer('INVITE_ONE_CALL_SESSION'),
  cancel_one_call_session: ReduxGenerator.getReducer('CANCEL_ONE_CALL_SESSION'),
  get_stripe_api_key: ReduxGenerator.getReducer('GET_STRIPE_API_KEY'),
  edit_group_call_session: ReduxGenerator.getReducer('EDIT_GROUP_CALL_SESSION'),
  get_call_session_video: ReduxGenerator.getReducer('GET_CALL_SESSION_VIDEO'),
  get_call_session_video_url: ReduxGenerator.getReducer('GET_CALL_SESSION_VIDEO_URL'),
  staff_reactivate: ReduxGenerator.getReducer('STAFF_REACTIVATE'),
  get_reports: ReduxGenerator.getReducer('GET_REPORTS'),
  get_promo_code: ReduxGenerator.getReducer('GET_PROMO_CODE'),
  get_smallgroup: ReduxGenerator.getReducer('GET_SMALLGROUP'),
  add_smallgroup: ReduxGenerator.getReducer('ADD_SMALLGROUP'),
  get_counselor: ReduxGenerator.getReducer('GET_COUNSELOR'),
  get_client_counselor: ReduxGenerator.getReducer('GET_CLIENT_COUNSELOR'),
  get_dynamic_combos: ReduxGenerator.getReducer('COMBOS_DYNAMIC'),
  send_voucher_code: ReduxGenerator.getReducer('SEND_VOUCHER_CODE'),
  get_small_group_list: ReduxGenerator.getReducer('GET_SMALL_GROUP_LIST'),
  get_small_group_users: ReduxGenerator.getReducer('GET_SMALL_GROUP_USERS'),
  upload_leader_photo: ReduxGenerator.getReducer('UPLOAD_LEADER_PHOTO'),
  mark_system_observation: ReduxGenerator.getReducer('MARK_SYSTEM_OBSERVATION'),
  app_mark_system_observation: ReduxGenerator.getReducer('APP_MARK_SYSTEM_OBSERVATION'),
  get_Analytics: ReduxGenerator.getReducer('GET_ANALYTICS'),
  get_active_client: ReduxGenerator.getReducer('GET_ACTIVE_CLIENT'),
  is_manage_license_required: ReduxGenerator.getReducer('IS_MANAGE_LICENSE_REQUIRED'),
  is_staff_pro_bio: ReduxGenerator.getReducer('CHECK_STAFF_PRO_BIO'),
  update_staff_pro_bio: ReduxGenerator.getReducer('UPDATE_STAFF_PRO_BIO'),
  get_notes: ReduxGenerator.getReducer('GET_NOTES'),
  get_category_client: ReduxGenerator.getReducer('GET_CATEGORY_CLIENT'),
  get_client_demographic: ReduxGenerator.getReducer('GET_CLIENT_DEMOGRAPHIC'),
  get_cost_of_care: ReduxGenerator.getReducer('GET_COST_OF_CARE'),
  get_behavioral_rating: ReduxGenerator.getReducer('GET_BEHAVIORAL_RATING'),
  get_score_improvement: ReduxGenerator.getReducer('GET_SCORE_IMPROVEMENT'),
  get_provider_performance: ReduxGenerator.getReducer('GET_PROVIDER_PERFORMANCE'),
  get_Trigger_Location_Activity: ReduxGenerator.getReducer('GET_TRIGGER_LOCATION'),
  get_Service_Delivered: ReduxGenerator.getReducer('GET_SERVICE_DELIVERED'),
  get_referral_source: ReduxGenerator.getReducer('GET_REFERRAL_SOURCE'),
  get_student_document_list: ReduxGenerator.getReducer('GET_DOCUMENT_LIST'),
  archive_student_document: ReduxGenerator.getReducer('ARCHIVE_STUDENT_DOCUMENT'),
  upload_document: ReduxGenerator.getReducer('UPLOAD_DOCUMENT'),
  download_student_document: ReduxGenerator.getReducer('DOWNLOAD_DOCUMENT'),
  menu_contract_template: ReduxGenerator.getReducer('MENU_CONTRACT_TEMPLATE'),
  archive_contract_template: ReduxGenerator.getReducer('ARCHIVE_CONTRACT_TEMPLATE'),
  actions_save: actionsSave,
  news_upsert: ReduxGenerator.getReducer('NEWS_UPSERT'),
  get_list_news: ReduxGenerator.getReducer('GET_LIST_NEWS'),
  get_news: ReduxGenerator.getReducer('GET_NEWS'),
  delete_news: ReduxGenerator.getReducer('DELETE_NEWS'),
  get_embed_info: ReduxGenerator.getReducer('GET_EMBED_INFO'),
  get_gender_pie_chart_embed_info: ReduxGenerator.getReducer('GET_GENDER_PIE_CHART_EMBED_INFO'),
  get_refernce_source_time_in_treatment_report: ReduxGenerator.getReducer(
    'GET_REFERNCE_SOURCE_TIME_IN_TREATMENT_REPORT',
  ),
  get_patient_list: ReduxGenerator.getReducer('GET_CLIENT_LIST'),
  get_patient_detail: ReduxGenerator.getReducer('GET_CLIENT_DETAIL'),
  client_chart_list_get: ReduxGenerator.getReducer('CLIENT_CHART_LIST_GET'),
  client_chart_report: ReduxGenerator.getReducer('CLIENT_CHART_REPORT'),
  upsert_patient_enrollment: ReduxGenerator.getReducer('UPSERT_PATIENT_ENROLLMENT'),
  upsert_client_insurance: ReduxGenerator.getReducer('UPSERT_CLIENT_INSURANCE'),
  upsert_client_medical: ReduxGenerator.getReducer('UPSERT_CLIENT_MEDICAL'),
  upsert_client_payment: ReduxGenerator.getReducer('UPSERT_CLIENT_PAYMENT'),
  upsert_provider_enrollment: ReduxGenerator.getReducer('UPSERT_PROVIDER_ENROLLMENT'),
  upsert_appointment: ReduxGenerator.getReducer('UPSERT_APPOINTMENT'),
  list_appointment: ReduxGenerator.getReducer('LIST_APPOINTMENT'),
  get_appointment: ReduxGenerator.getReducer('GET_APPOINTMENT'),
  delete_appointment: ReduxGenerator.getReducer('DELETE_APPOINTMENT'),
  medicate_combos: ReduxGenerator.getReducer('COMBOS_MEDICATE'),
  list_survey_question_category: ReduxGenerator.getReducer('LIST_SURVEY_QUESTION_CATEGORY'),
  list_survey_question_category_assessment: ReduxGenerator.getReducer(
    'LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT',
  ),
  list_survey_question_assessment: ReduxGenerator.getReducer('LIST_SURVEY_QUESTION_ASSESSMENT'),
  practitioner_combos: ReduxGenerator.getReducer('COMBOS_PRACTITIONER'),
  practitioner_combos_office: ReduxGenerator.getReducer('COMBOS_PRACTITIONER_OFFICE'),
  upsert_group: ReduxGenerator.getReducer('UPSERT_GROUP'),
  list_group: ReduxGenerator.getReducer('LIST_GROUP'),
  get_group: ReduxGenerator.getReducer('GET_GROUP'),
  delete_group: ReduxGenerator.getReducer('DELETE_GROUP'),
  upsert_client_group: ReduxGenerator.getReducer('UPSERT_CLIENT_GROUP'),
  get_client_group: ReduxGenerator.getReducer('GET_CLIENT_GROUP'),
  upsert_group_appointment: ReduxGenerator.getReducer('UPSERT_GROUP_APPOINTMENT'),
  upsert_group_appointment_group_all_info_json: ReduxGenerator.getReducer(
    'UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON',
  ),
  get_list_client_group: ReduxGenerator.getReducer('GET_LIST_CLIENT_GROUP'),
  patient_combo_group: ReduxGenerator.getReducer('PATIENT_COMBO_GROUP'),
  deactivate_group_appointment: ReduxGenerator.getReducer('DEACTIVATE_GROUP_APPOINTMENT'),
  combo_center_consent: ReduxGenerator.getReducer('COMBO_CENTER_CONSENT'),
  upsert_client_consent: ReduxGenerator.getReducer('UPSERT_CLIENT_CONSENT'),
  list_client_consent: ReduxGenerator.getReducer('LIST_CLIENT_CONSENT'),
  deactivate_client_consent: ReduxGenerator.getReducer('DEACTIVATE_CLIENT_CONSENT'),
  deactivate_client_screening: ReduxGenerator.getReducer('DEACTIVATE_CLIENT_SCREENING'),
  load_judicial_dashboard: ReduxGenerator.getReducer('LOAD_JUDICIAL_DASHBOARD'),
  get_judicial_user_list: ReduxGenerator.getReducer('GET_JUDICIAL_USER_LIST'),
  upload_document_using_multiPart: ReduxGenerator.getReducer('UPLOAD_DOCUMENT_USING_MULTIPART'),
  upsert_client_document: ReduxGenerator.getReducer('UPSERT_CLIENT_DOCUMENT'),
  list_client_document: ReduxGenerator.getReducer('LIST_CLIENT_DOCUMENT'),
  download_client_document: ReduxGenerator.getReducer('DOWNLOAD_CLIENT_DOCUMENT'),
  archive_client_document: ReduxGenerator.getReducer('ARCHIVE_DOCUMENT'),
  list_group_membership: ReduxGenerator.getReducer('LIST_GROUP_MEMBERSHIP'),
  get_survey_score: ReduxGenerator.getReducer('GET_SURVEY_SCORE'),
  get_consent_form_template: ReduxGenerator.getReducer('GET_CONSENT_FORM_TEMPLATE'),
  get_combo_facility: ReduxGenerator.getReducer('GET_COMBO_FACEILITY'),
  load_app_sub_survey_dashboard: ReduxGenerator.getReducer('LOAD_APP_SUB_SURVEY_DASHBOARD'),
  app_sub_survey_ans_get: ReduxGenerator.getReducer('APP_SUB_SURVEY_ANS_GET'),
  center_program_type_get: ReduxGenerator.getReducer('CENTER_PROGRAM_TYPE_GET'),
  download_client_consent: ReduxGenerator.getReducer('DOWNLOAD_CLIENT_CONSENT'),
  combo_practitioner_all: ReduxGenerator.getReducer('COMBO_PRACTITIONER_ALL'),
  combo_facility_all: ReduxGenerator.getReducer('COMBO_FACILITY_ALL'),
  save_client_consent: ReduxGenerator.getReducer('SAVE_CLIENT_CONSENT'),
  get_treatment_plan: ReduxGenerator.getReducer('GET_TREATMENT_PLAN'),
  get_diagnosis: ReduxGenerator.getReducer('GET_DIAGNOSIS'),
  upsert_treatment_plan_problem: ReduxGenerator.getReducer('UPSERT_TREATMENT_PLAN_PROBLEM'),
  upsert_medical_analysis: ReduxGenerator.getReducer('UPSERT_MEDICAL_ANALYSIS'),
  upsert_treatment_plan_goal: ReduxGenerator.getReducer('UPSERT_TREATMENT_PLAN_GOAL'),
  upsert_treatment_plan_objective: ReduxGenerator.getReducer('UPSERT_TREATMENT_PLAN_OBJECTIVE'),
  upsert_treatment_plan_intervention: ReduxGenerator.getReducer(
    'UPSERT_TREATMENT_PLAN_INTERVENTION',
  ),
  delete_treatment_plan: ReduxGenerator.getReducer('DELETE_TREATMENT_PLAN'),
  get_diagnosis_description: ReduxGenerator.getReducer('GET_DIAGNOSIS_DESCRIPTION'),
  get_diagnosis_code: ReduxGenerator.getReducer('GET_DIAGNOSIS_CODE'),
  combo_problem_template: ReduxGenerator.getReducer('COMBO_PROBLEM_TEMPLATE'),
  combo_goal_template: ReduxGenerator.getReducer('COMBO_GOAL_TEMPLATE'),
  list_treatment_plan: ReduxGenerator.getReducer('LIST_TREATMENT_PLAN'),
  recurring_count: ReduxGenerator.getReducer('RECURRING_COUNT'),
  get_staff_permission: ReduxGenerator.getReducer('GET_STAFF_PERMISSION'),
  update_staff_permission: ReduxGenerator.getReducer('UPDATE_STAFF_PERMISSION'),
  load_case_profile: ReduxGenerator.getReducer('LOAD_CASE_PROFILE'),
  load_location_auth_history: ReduxGenerator.getReducer('LOAD_LOCATION_AUTH_HISTORY'),
  screen_authorization: ReduxGenerator.getReducer('SCREEN_AUTHORIZATION'),
  list_consent_form: ReduxGenerator.getReducer('LIST_CONSENT_FORM'),
  upsert_treatment_plan_dimension: ReduxGenerator.getReducer('UPSERT_TREATMENT_PLAN_DIMENSION'),
  save_consent_sign: ReduxGenerator.getReducer('SAVE_CONSENT_SIGN'),
  consent_form_download: ReduxGenerator.getReducer('CONSENT_FORM_DOWNLOAD'),
  list_meeting: ReduxGenerator.getReducer('LIST_MEETING'),
  upsert_progress_group_note: ReduxGenerator.getReducer('UPSERT_PROGRESS_GROUP_NOTE'),
  get_progress_note_group: ReduxGenerator.getReducer('GET_PROGRESS_NOTE_GROUP'),
  get_user_note_patient: ReduxGenerator.getReducer('GET_USER_NOTE_PATIENT'),
  get_user_note_comment: ReduxGenerator.getReducer('GET_USER_NOTE_COMMENT'),
  add_user_note_comment: ReduxGenerator.getReducer('ADD_USER_NOTE_COMMENT'),
  get_user_note_emoji: ReduxGenerator.getReducer('GET_USER_NOTE_EMOJI'),
  add_user_note_emoji: ReduxGenerator.getReducer('ADD_USER_NOTE_EMOJI'),
  combo_intervention_template: ReduxGenerator.getReducer('COMBO_INTERVENTION_TEMPLATE'),
  get_progress_note_for_intervention: ReduxGenerator.getReducer(
    'GET_PROGRESS_NOTE_FOR_INTERVENTION',
  ),
  upsert_payer: ReduxGenerator.getReducer('UPSERT_PAYER'),
  list_payer: ReduxGenerator.getReducer('LIST_PAYER'),
  delete_payer: ReduxGenerator.getReducer('DELETE_PAYER'),
  get_payer: ReduxGenerator.getReducer('GET_PAYER'),
  combo_payer: ReduxGenerator.getReducer('COMBO_PAYER'),
  upsert_service_code: ReduxGenerator.getReducer('UPSERT_SERVICE_CODE'),
  list_service_code: ReduxGenerator.getReducer('LIST_SERVICE_CODE'),
  delete_service_code: ReduxGenerator.getReducer('DELETE_SERVICE_CODE'),
  get_service_code: ReduxGenerator.getReducer('GET_SERVICE_CODE'),
  combo_code: ReduxGenerator.getReducer('COMBO_CODE'),
  get_code_description: ReduxGenerator.getReducer('GET_CODE_DESCRIPTION'),
  appointment_note_list: ReduxGenerator.getReducer('APPOINTMENT_NOTE_LIST'),
  upsert_appointment_note: ReduxGenerator.getReducer('UPSERT_APPOINTMENT_NOTE'),
  get_appointment_note: ReduxGenerator.getReducer('GET_APPOINTMENT_NOTE'),
  combo_place_of_service: ReduxGenerator.getReducer('COMBO_PLACE_OF_SERVICE'),
  list_service_line: ReduxGenerator.getReducer('LIST_SERVICE_LINE'),
  get_service_line: ReduxGenerator.getReducer('GET_SERVICE_LINE'),
  upsert_service_line: ReduxGenerator.getReducer('UPSERT_SERVICE_LINE'),
  upsert_appointment_note_addendum: ReduxGenerator.getReducer('UPSERT_APPOINTMENT_NOTE_ADDENDUM'),
  list_group_appointment_note: ReduxGenerator.getReducer('LIST_GROUP_APPOINTMENT_NOTE'),
  get_group_appointment_note: ReduxGenerator.getReducer('GET_GROUP_APPOINTMENT_NOTE'),
  combo_treatment_plan_intervention: ReduxGenerator.getReducer('COMBO_TREATMENT_PLAN_INTERVENTION'),
  list_fee_schedule: ReduxGenerator.getReducer('LIST_FEE_SCHEDULE'),
  get_fee_schedule: ReduxGenerator.getReducer('GET_FEE_SCHEDULE'),
  upsert_fee_schedule: ReduxGenerator.getReducer('UPSERT_FEE_SCHEDULE'),
  get_treatment_plan_graph: ReduxGenerator.getReducer('GET_TREATMENT_PLAN_GRAPH'),
  list_group_service_line: ReduxGenerator.getReducer('LIST_GROUP_SERVICE_LINE'),
  get_group_service_line: ReduxGenerator.getReducer('GET_GROUP_SERVICE_LINE'),
  get_group_banner: ReduxGenerator.getReducer('GET_GROUP_BANNER'),
  upsert_group_service_line: ReduxGenerator.getReducer('UPSERT_GROUP_SERVICE_LINE'),
  upsert_group_appointment_note: ReduxGenerator.getReducer('UPSERT_GROUP_APPOINTMENT_NOTE'),
  upsert_group_appointment_note_addendum: ReduxGenerator.getReducer(
    'UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM',
  ),
  list_survey_question_discharge: ReduxGenerator.getReducer('LIST_SURVEY_QUESTION_DISCHARGE'),
  upsert_appointment_note_intervention: ReduxGenerator.getReducer(
    'UPSERT_APPOINTMENT_NOTE_INTERVENTION',
  ),
  mark_as_complete: ReduxGenerator.getReducer('MARK_AS_COMPLETE'),
  get_service_line_cost: ReduxGenerator.getReducer('GET_SERVICE_LINE_COST'),
  upsert_pin: ReduxGenerator.getReducer('UPSERT_PIN'),
  validate_staff_pin: ReduxGenerator.getReducer('VALIDATE_STAFF_PIN'),
  load_smallGroup_dashboard: ReduxGenerator.getReducer('LOAD_SMALLGROUP_DASHBOARD'),
  upsert_treatment_plan: ReduxGenerator.getReducer('UPSERT_TREATMENT_PLAN'),
  list_client_diagnosis: ReduxGenerator.getReducer('LIST_CLIENT_DIAGNOSIS'),
  get_client_diagnosis: ReduxGenerator.getReducer('GET_CLIENT_DIAGNOSIS'),
  delete_client_diagnosis: ReduxGenerator.getReducer('DELETE_CLIENT_DIAGNOSIS'),
  validate_pin_exists: ReduxGenerator.getReducer('VALIDATE_PIN_EXISTS'),
  clone_diagnosis: ReduxGenerator.getReducer('CLONE_DIAGNOSIS'),
  clone_treatment_plan: ReduxGenerator.getReducer('CLONE_TREATMENT_PLAN_DIAGNOSIS'),
  get_breathalyzer_schedule: ReduxGenerator.getReducer('GET_BREATHALYZER_SCHEDULE'),
  list_breathalyzer_schedule: ReduxGenerator.getReducer('LIST_BREATHALYZER_SCHEDULE'),
  upsert_breathalyzer_schedule: ReduxGenerator.getReducer('UPSERT_BREATHALYZER_SCHEDULE'),
  rcm_client_merge: ReduxGenerator.getReducer('RCM_CLIENT_MERGE'),
  pre_validate_pin: ReduxGenerator.getReducer('PRE_VALIDATE_PIN'),
  validate_client: ReduxGenerator.getReducer('VALIDATE_CLIENT'),
  get_app_advisor: ReduxGenerator.getReducer('GET_APP_ADVISOR'),
  get_media_video_url: ReduxGenerator.getReducer('GET_MEDIA_VIDEO_URL'),
  get_dashboard_menu: ReduxGenerator.getReducer('GET_DASHBOARD_MENU'),
  breathalyzer_push_test: ReduxGenerator.getReducer('BREATHALYZER_PUSH_A_TEST'),
  list_service_code_bundle: ReduxGenerator.getReducer('LIST_SERVICE_CODE_BUNDLE'),
  get_service_code_bundle: ReduxGenerator.getReducer('GET_SERVICE_CODE_BUNDLE'),
  get_student_note: ReduxGenerator.getReducer('GET_STUDENT_NOTE'),
  student_note_list_get: ReduxGenerator.getReducer('STUDENT_NOTE_LIST_GET'),
  get_student_noteComment: ReduxGenerator.getReducer('GET_STUDENT_NOTE_COMMENT'),
  add_student_noteComment: ReduxGenerator.getReducer('ADD_STUDENT_NOTE_COMMENT'),
  add_student_noteEmoji: ReduxGenerator.getReducer('ADD_STUDENT_NOTE_EMOJI'),
  get_student_noteEmoji: ReduxGenerator.getReducer('GET_STUDENT_NOTE_EMOJI'),
  get_treatment_plan_accordin: ReduxGenerator.getReducer('GET_TREATMENT_PLAN_ACCORDIN'),
  breathalyzer_enable: ReduxGenerator.getReducer('BREATHALYZER_ENABLE'),
  get_breathalyzer_test_result: ReduxGenerator.getReducer('GET_BREATHALYZER_TEST_RESULT'),
  get_breathalyzer_test_upcoming: ReduxGenerator.getReducer('GET_BREATHALYZER_TEST_UPCOMING'),
  breathalyzer_test_upcoming_toggle: ReduxGenerator.getReducer('BREATHALYZER_TEST_UPCOMING_TOGGLE'),
  get_advisor_cost: ReduxGenerator.getReducer('GET_ADVISOR_COST'),
  upsert_advisor_cost: ReduxGenerator.getReducer('UPSERT_ADVISOR_COST'),
  claims_pdf_download: ReduxGenerator.getReducer('CLAIMS_PDF_DOWNLOAD'),
  get_client_service: ReduxGenerator.getReducer('GET_CLIENT_SERVICE'),
  get_list_timeline: ReduxGenerator.getReducer('GET_LIST_TIMELINE'),
  get_dashboard_daily_schedule: ReduxGenerator.getReducer('GET_DASHBOARD_DAILY_SCHEDULES'),
  get_dashboard_daily_case_load: ReduxGenerator.getReducer('GET_DASHBOARD_DAILY_CASE_LOAD'),
  get_dashboard_alert: ReduxGenerator.getReducer('GET_DASHBOARD_ALERT'),
  get_student_breathalyzer_test_result: ReduxGenerator.getReducer(
    'GET_STUDENT_BREATHALYZER_TEST_RESULT',
  ),
  get_center_advisor_default_costs: ReduxGenerator.getReducer('GET_CENTER_ADVISOR_DEFAULT_COSTS'),
  update_center_advisor_default_cost: ReduxGenerator.getReducer(
    'UPDATE_CENTER_ADVISOR_DEFAULT_COST',
  ),
  add_client_insurance_eligibility: ReduxGenerator.getReducer('ADD_CLIENT_INSURANCE_ELIGIBILITY'),
  edit_client_insurance_eligibility: ReduxGenerator.getReducer('EDIT_CLIENT_INSURANCE_ELIGIBILITY'),
  delete_client_insurance_eligibility: ReduxGenerator.getReducer(
    'DELETE_CLIENT_INSURANCE_ELIGIBILITY',
  ),
  get_staff_practitioner: ReduxGenerator.getReducer('GET_STAFF_PRACTITIONER'),
  discharge_summary_save: ReduxGenerator.getReducer('DISCHARGE_SUMMERY_SAVE'),
  get_client_appointment: ReduxGenerator.getReducer('GET_CLIENT_APPOINTMENT'),
  get_group_appointment: ReduxGenerator.getReducer('GET_GROUP_APPOINTMENT'),
  unsign_form: ReduxGenerator.getReducer('UNSIGN_FORM'),
  get_filters: ReduxGenerator.getReducer('GET_FILTERS'),
  list_lab_order: ReduxGenerator.getReducer('LIST_LAB_ORDER'),
  get_lab_order: ReduxGenerator.getReducer('GET_LAB_ORDER'),
  upsert_lab_order: ReduxGenerator.getReducer('UPSERT_LAB_ORDER'),
  lab_order_label_get: ReduxGenerator.getReducer('lab_order_label_get'),
  combo_diagnosis_list: ReduxGenerator.getReducer('COMBO_DIAGNOSIS_LIST'),
  get_medication_note: ReduxGenerator.getReducer('GET_MEDICATION_NOTE'),
  upsert_client_group_appointment: ReduxGenerator.getReducer('UPSERT_CLIENT_GROUP_APPOINTMENT'),
  sign_group_appointment_note: ReduxGenerator.getReducer('SIGN_GROUP_APPOINTMENT_NOTE'),
  list_service_line_payment: ReduxGenerator.getReducer('LIST_SERVICE_LINE_PAYMENT'),
  get_service_line_payment: ReduxGenerator.getReducer('GET_SERVICE_LINE_PAYMENT'),
  upsert_service_line_payment: ReduxGenerator.getReducer('UPSERT_SERVICE_LINE_PAYMENT'),
  delete_service_line_payment: ReduxGenerator.getReducer('DELETE_SERVICE_LINE_PAYMENT'),
  combo_supervisor: ReduxGenerator.getReducer('COMBO_SUPERVISOR'),
  location_day_history: ReduxGenerator.getReducer('LOCATION_DAY_HISTORY'),
  upsert_payment_client: ReduxGenerator.getReducer('UPSERT_PAYMENT_CLIENT'),
  get_insurance_priority_order: ReduxGenerator.getReducer('GET_INSURANCE_PRIORITY_ORDER'),
  upsert_insurance_priority_order: ReduxGenerator.getReducer('UPSERT_INSURANCE_PRIORITY_ORDER'),
  list_CWPayment: ReduxGenerator.getReducer('LIST_CW_PAYMENT'),
  get_CWPayment: ReduxGenerator.getReducer('GET_CW_PAYMENT'),
  delete_CWPayment: ReduxGenerator.getReducer('DELETE_CW_PAYMENT'),
  past_meeting_info: ReduxGenerator.getReducer('PAST_MEETING_INFO'),
  list_past_meeting_info: ReduxGenerator.getReducer('LIST_PAST_MEETING_INFO'),
  combo_appointment_treatment_plan: ReduxGenerator.getReducer('COMBO_APPOINTMENT_TREATMENT_PLAN'),
  link_appointment: ReduxGenerator.getReducer('LINK_APPOINTMENT'),
  appointment_link_get: ReduxGenerator.getReducer('APPOINTMENT_LINK_GET'),
  get_basic_profile: ReduxGenerator.getReducer('GET_BASIC_PROFILE'),
  get_invited_list: ReduxGenerator.getReducer('GET_INVITED_LIST'),
  invite_resend: ReduxGenerator.getReducer('INVITE_RESEND'),
  suspicious_term_ack: ReduxGenerator.getReducer('SUSPICIOUS_TERM_ACK'),
  get_broadcast_filter: ReduxGenerator.getReducer('GET_BROADCAST_FILTER'),
  send_broadcast: ReduxGenerator.getReducer('SEND_BROADCAST'),
  get_service_line_diagnosis: ReduxGenerator.getReducer('GET_SERVICE_LINE_DIAGNOSIS'),
  upsert_service_line_diagnosis: ReduxGenerator.getReducer('UPSERT_SERVICE_LINE_DIAGNOSIS'),
  combo_broadcast_template: ReduxGenerator.getReducer('COMBO_BROADCAST_TEMPLATE'),
  upsert_broadcast_template: ReduxGenerator.getReducer('UPSERT_BROADCAST_TEMPLATE'),
  delete_note_type: ReduxGenerator.getReducer('DELETE_NOTE_TYPE'),
  list_client_authorization: ReduxGenerator.getReducer('LIST_CLIENT_AUTHORIZATION'),
  get_client_authorization: ReduxGenerator.getReducer('GET_CLIENT_AUTHORIZATION'),
  delete_client_authorization: ReduxGenerator.getReducer('DELETE_CLIENT_AUTHORIZATION'),
  upsert_client_authorization: ReduxGenerator.getReducer('UPSERT_CLIENT_AUTHORIZATION'),
  get_note_type_archive: ReduxGenerator.getReducer('GET_NOTE_TYPE_ARCHIVE'),
  get_note_type_list: ReduxGenerator.getReducer('GET_NOTE_TYPE_LIST'),
  upsert_coupon_code: ReduxGenerator.getReducer('UPSERT_COUPON_CODE'),
  delete_coupon_code: ReduxGenerator.getReducer('DELETE_COUPON_CODE'),
  get_coupon_code: ReduxGenerator.getReducer('GET_COUPON_CODE'),
  center_reactivate: ReduxGenerator.getReducer('CENTER_REACTIVATE'),
  generate_call_token: ReduxGenerator.getReducer('GENERATE_CALL_TOKEN'),
  badge_combo_get: ReduxGenerator.getReducer('BADGE_COMBO_GET'),
  badge_update: ReduxGenerator.getReducer('BADGE_UPDATE'),
  panas_scale_moods_get: ReduxGenerator.getReducer('PANAS_SCALE_MOODS_GET'),
  panas_tag_upsert: ReduxGenerator.getReducer('PANAS_TAG_UPSERT'),
  bed_assignment_get_all: ReduxGenerator.getReducer('BED_ASSIGNMENT_GET_ALL'),
  bed_assignment_upsert: ReduxGenerator.getReducer('BED_ASSIGNMENT_UPSERT'),
  upcoming_discharges_get_all: ReduxGenerator.getReducer('UPCOMING_DISCHARGES_GET_ALL'),
  upcoming_admissions: ReduxGenerator.getReducer('UPCOMING_ADMISSIONS'),
  admission_cancel: ReduxGenerator.getReducer('ADMISSION_CANCEL'),
  panas_tag_delete: ReduxGenerator.getReducer('PANAS_TAG_DELETE'),
  dynamic_grid_list: ReduxGenerator.getReducer('DYNAMIC_GRID_LIST'),
  get_grid: ReduxGenerator.getReducer('GET_GRID'),
  get_grid_data: ReduxGenerator.getReducer('GET_GRID_DATA'),
  grid_on_row_click_get: ReduxGenerator.getReducer('GRID_ON_ROW_CLICK_GET'),
  upsert_form_data: ReduxGenerator.getReducer('UPSERT_FORM_DATA'),
  form_data_get: ReduxGenerator.getReducer('FORM_DATA_GET'),
  get_predefined_task: ReduxGenerator.getReducer('GET_PREDEFINED_TASK'),
  get_predefined_task_archive: ReduxGenerator.getReducer('GET_PREDEFINED_TASK_ARCHIVE'),
  delete_predefined_task: ReduxGenerator.getReducer('DELETE_PREDEFINED_TASK'),
  upsert_predefined_task: ReduxGenerator.getReducer('UPSERT_PREDEFINED_TASK'),
  list_survey_question_category_get_all: ReduxGenerator.getReducer(
    'LIST_SURVEY_QUESTION_CATEGORY_GET_ALL',
  ),
  get_advisor_member_trends: ReduxGenerator.getReducer('GET_ADVISOR_MEMBER_TRENDS'),
  update_small_group_attendence: ReduxGenerator.getReducer('UPDATE_SMALL_GROUP_ATTENDENCE'),
  update_user_profile: ReduxGenerator.getReducer('UPDATE_USER_PROFILE'),
  get_advisor_member_trends_history: ReduxGenerator.getReducer('GET_ADVISOR_MEMBER_TRENDS_HISTORY'),
  mobile_invite_options_load: ReduxGenerator.getReducer('MOBILE_INVITE_OPTIONS_LOAD'),
  get_app_user_subscription: ReduxGenerator.getReducer('GET_APP_USER_SUBSCRIPTION'),
  get_service_option: ReduxGenerator.getReducer('GET_SERVICE_OPTION'),
  get_service_advisor_list: ReduxGenerator.getReducer('GET_SERVICE_ADVISOR_LIST'),
  set_service_advisor: ReduxGenerator.getReducer('SET_SERVICE_ADVISOR'),
  update_cancel_service: ReduxGenerator.getReducer('UPDATE_CANCEL_SERVICE'),
  set_membership: ReduxGenerator.getReducer('SET_MEMBERSHIP'),
  cancel_membership: ReduxGenerator.getReducer('CANCEL_MEMBERSHIP'),
  set_service_subscription: ReduxGenerator.getReducer('SET_SERVICE_SUBSCRIPTION'),
  upsert_client_pin: ReduxGenerator.getReducer('UPSERT_CLIENT_PIN'),
  get_student_timeLine: ReduxGenerator.getReducer('GET_STUDENT_TIMELINE'),
  get_client_timeLine: ReduxGenerator.getReducer('GET_CLIENT_TIMELINE'),
  client_student_synchronize: ReduxGenerator.getReducer('CLIENT_STUDENT_SYNCHRONIZE'),
  update_user_membership: ReduxGenerator.getReducer('UPDATE_USER_MEMBERSHIP'),
  combo_app_advisor: ReduxGenerator.getReducer('COMBO_APP_ADVISOR'),
  get_survey_category_question: ReduxGenerator.getReducer('GET_SURVEY_CATEGORY_QUESTIONS'),
  upsert_block_appointment: ReduxGenerator.getReducer('UPSERT_BLOCK_APPOINTMENT'),
  upsert_client_memo: ReduxGenerator.getReducer('UPSERT_CLIENT_MEMO'),
  get_client_memo: ReduxGenerator.getReducer('GET_CLIENT_MEMO'),
  delete_client_memo: ReduxGenerator.getReducer('DELETE_CLIENT_MEMO'),
  validate_SSN: ReduxGenerator.getReducer('VALIDATE_SSN'),
  user_app_link_validate: ReduxGenerator.getReducer('USER_APP_LINK_VALIDATE'),
  client_center_move: ReduxGenerator.getReducer('CLIENT_CENTER_MOVE'),
  form_tab_list_get: ReduxGenerator.getReducer('FORM_TAB_LIST_GET'),
  upsert_client_care_team: ReduxGenerator.getReducer('UPSERT_CLIENT_CARE_TEAM'),
  client_care_team_get: ReduxGenerator.getReducer('CLIENT_CARE_TEAM_GET'),
  get_client_care_team_list: ReduxGenerator.getReducer('GET_CLIENT_CARE_TEAM_LIST'),
  client_care_team_delete: ReduxGenerator.getReducer('CLIENT_CARE_TEAM_DELETE'),
  care_team_member_combo: ReduxGenerator.getReducer('CARE_TEAM_MEMBER_COMBO'),
  external_provider_list_get: ReduxGenerator.getReducer('EXTERNAL_PROVIDER_LIST_GET'),
  external_provider_get: ReduxGenerator.getReducer('EXTERNAL_PROVIDER_GET'),
  external_provider_filter_get: ReduxGenerator.getReducer('EXTERNAL_PROVIDER_FILTER_GET'),
  external_provider_upsert: ReduxGenerator.getReducer('EXTERNAL_PROVIDER_UPSERT'),
  external_provider_authentication: ReduxGenerator.getReducer('EXTERNAL_PROVIDER_AUTHENTICATION'),
  external_staff_archive: ReduxGenerator.getReducer('EXTERNAL_STAFF_ARCHIVE'),
  organization_center_family_combo: ReduxGenerator.getReducer('ORGANIZATION_CENTER_FAMILY_COMBO'),
  upsert_client_vital: ReduxGenerator.getReducer('UPSERT_CLIENT_VITAL'),
  upsert_medication_log: ReduxGenerator.getReducer('UPSERT_MEDICATION_LOG'),
  get_medication_log: ReduxGenerator.getReducer('GET_MEDICATION_LOG'),
  get_client_vital: ReduxGenerator.getReducer('GET_CLIENT_VITAL'),
  get_medication_log_list: ReduxGenerator.getReducer('GET_MEDICATION_LOG_LIST'),
  get_client_vital_list: ReduxGenerator.getReducer('GET_CLIENT_VITAL_LIST'),
  get_CH_patient_list: ReduxGenerator.getReducer('GET_CH_PATIENT_LIST'),
  get_CH_patient_service_send: ReduxGenerator.getReducer('GET_CH_PATIENT_SERVICE_SEND'),
  push_claims_to_CH: ReduxGenerator.getReducer('PUSH_CLAIMS_TO_CH'),
  push_single_claim_to_CH: ReduxGenerator.getReducer('PUSH_SINGLE_CLAIM_TO_CH'),
  get_billable_to_claims: ReduxGenerator.getReducer('GET_BILLABLE_CLAIMS'),
  clone_form: ReduxGenerator.getReducer('CLONE_FORM'),
  clear_signature: ReduxGenerator.getReducer('CLEAR_SIGNATURE'),
  upsert_staff_viewed_activity: ReduxGenerator.getReducer('UPSERT_STAFF_VIEWED_ACTIVITY'),
  delete_student_note: ReduxGenerator.getReducer('DELETE_STUDENT_NOTE'),
  staff_accessed_tab_get: ReduxGenerator.getReducer('STAFF_ACCESSED_TAB_GET'),
  student_staff_accessed_tab_get: ReduxGenerator.getReducer('STUDENT_STAFF_ACCESSED_TAB_GET'),
  app_announcement_upsert: ReduxGenerator.getReducer('APP_ANNOUNCEMENT_UPSERT'),
  app_announcement_list_get_all: ReduxGenerator.getReducer('APP_ANNOUNCEMENT_LIST_GET_ALL'),
  app_announcement_get: ReduxGenerator.getReducer('APP_ANNOUNCEMENT_GET'),
  token_management_list_get_all: ReduxGenerator.getReducer('TOKEN_MANAGEMENT_LIST_GET_ALL'),
  token_management_stats_get: ReduxGenerator.getReducer('TOKEN_MANAGEMENT_STATS_GET'),
  resource_upsert: ReduxGenerator.getReducer('RESOURCE_UPSERT'),
  resource_list_get_all: ReduxGenerator.getReducer('RESOURCE_LIST_GET_ALL'),
  resource_get: ReduxGenerator.getReducer('RESOURCE_GET'),
  allergen_search: ReduxGenerator.getReducer('ALLERGEN_SEARCH'),
  drug_search: ReduxGenerator.getReducer('DRUG_SEARCH'),
  client_allergy_get: ReduxGenerator.getReducer('CLIENT_ALLERGY_GET'),
  allergy_options_get: ReduxGenerator.getReducer('ALLERGY_OPTIONS_GET'),
  client_allergy_upsert: ReduxGenerator.getReducer('CLIENT_ALLERGY_UPSERT'),
  client_allergy_get_all: ReduxGenerator.getReducer('CLIENT_ALLERGY_GET_ALL'),
  client_allergy_delete: ReduxGenerator.getReducer('CLIENT_ALLERGY_DELETE'),
  client_medication_upsert: ReduxGenerator.getReducer('CLIENT_MEDICATION_UPSERT'),
  client_medication_get_all: ReduxGenerator.getReducer('CLIENT_MEDICATION_GET_ALL'),
  client_medication_delete: ReduxGenerator.getReducer('CLIENT_MEDICATION_DELETE'),
  drug_package_get_all: ReduxGenerator.getReducer('DRUG_PACKAGE_GET_ALL'),
  prescribe_notification_get: ReduxGenerator.getReducer('PRESCRIBE_NOTIFICATION_GET'),
  prescribe_update: ReduxGenerator.getReducer('PRESCRIBE_UPDATE'),
  patient_signature_save: ReduxGenerator.getReducer('PATIENT_SIGNATURE_SAVE'),
  prescription_list_get: ReduxGenerator.getReducer('PRESCRIPTION_LIST_GET'),
  user_feature_flag_get: ReduxGenerator.getReducer('USER_FEATURE_FLAG_GET'),
  augmented_client_insights_get: ReduxGenerator.getReducer('AUGMENTED_CLIENT_INSIGHTS_GET'),
  risk_chart_get: ReduxGenerator.getReducer('RISK_CHART_GET'),
  augmented_summary_get: ReduxGenerator.getReducer('AUGMENTED_SUMMARY_GET'),
  eprescribe_single_patient_mode_sso: ReduxGenerator.getReducer(
    'EPRESCRIBE_SINGLE_PATIENT_MODE_SSO',
  ),
  client_reactivate: ReduxGenerator.getReducer('CLIENT_REACTIVATE'),
  client_banner_get: ReduxGenerator.getReducer('CLIENT_BANNER_GET'),
  lab_order_cancel: ReduxGenerator.getReducer('LAB_ORDER_CANCEL'),
  drug_detail_search: ReduxGenerator.getReducer('DRUG_DETAIL_SEARCH'),
  lab_result_get: ReduxGenerator.getReducer('LAB_RESULT_GET'),
  lab_result_document_get: ReduxGenerator.getReducer('LAB_RESULT_DOCUMENT_GET'),
  lab_order_delete: ReduxGenerator.getReducer('LAB_ORDER_DELETE'),
  add_support_group: ReduxGenerator.getReducer('ADD_SUPPORT_GROUP'),
  load_support_group: ReduxGenerator.getReducer('LOAD_SUPPORT_GROUP'),
  load_peer: ReduxGenerator.getReducer('LOAD_PEER'),
  load_user_engagement: ReduxGenerator.getReducer('LOAD_USER_ENGAGEMENT'),
  student_banner: ReduxGenerator.getReducer('STUDENT_BANNER'),
  pre_intake_survey_answers_get: ReduxGenerator.getReducer('PRE_INTAKE_SURVEY_ANSWERS_GET'),
  pre_intake_history_get: ReduxGenerator.getReducer('PRE_INTAKE_HISTORY_GET'),
  survey_latest_answer_date_update: ReduxGenerator.getReducer('SURVEY_LATEST_ANSWER_DATE_UPDATE'),
  app_user_demographic_upsert: ReduxGenerator.getReducer('APP_USER_DEMOGRAPHIC_UPSERT'),
  app_user_demographic_get: ReduxGenerator.getReducer('APP_USER_DEMOGRAPHIC_GET'),
  remove_support_group: ReduxGenerator.getReducer('REMOVE_SUPPORT_GROUP'),
  concerned_person_list: ReduxGenerator.getReducer('CONCERNED_PERSON_LIST'),
  concerned_person_dasboard_graph_get: ReduxGenerator.getReducer(
    'CONCERNED_PERSON_DASBOARD_GRAPH_GET',
  ),
  advisor_search: ReduxGenerator.getReducer('ADVISOR_SEARCH'),
  list_client_get_all_with_pagination: ReduxGenerator.getReducer(
    'LIST_CLIENT_GET_ALL_WITH_PAGINATION',
  ),
  app_user_live_location_get: ReduxGenerator.getReducer('APP_USER_LIVE_LOCATION_GET'),
  student_survey_list_get: ReduxGenerator.getReducer('STUDENT_SURVEY_LIST_GET'),
  gpt3_enroll_surveys: ReduxGenerator.getReducer('GPT3_ENROLL_SURVEYS'),
  get_multiple_survey_augmented_summary: ReduxGenerator.getReducer(
    'GET_MULTIPLE_SURVEY_AUGMENTED_SUMMARY',
  ),
  gpt3_answer_status_upsert: ReduxGenerator.getReducer('GPT3_ANSWER_STATUS_UPSERT'),
  augment_with_ai: ReduxGenerator.getReducer('AUGMENT_WITH_AI'),
  augment_with_ai_get: ReduxGenerator.getReducer('AUGMENT_WITH_AI_GET'),
  grid_filter_get: ReduxGenerator.getReducer('GRID_FILTER_GET'),
  get_group_dropdown: ReduxGenerator.getReducer('GET_GROUP_DROPDOWN'),
  get_prospect_dashboard_graph: ReduxGenerator.getReducer('GET_PROSPECT_DASHBOARD_GRAPH'),
  medical_program_upsert: ReduxGenerator.getReducer('MEDICAL_PROGRAM_UPSERT'),
  medical_program_get: ReduxGenerator.getReducer('MEDICAL_PROGRAM_GET'),
  medical_program_delete: ReduxGenerator.getReducer('MEDICAL_PROGRAM_DELETE'),
  region_insert: ReduxGenerator.getReducer('REGION_INSERT'),
  region_get: ReduxGenerator.getReducer('REGION_GET'),
  region_delete: ReduxGenerator.getReducer('REGION_DELETE'),
  staff_accessed_tab_ent_get: ReduxGenerator.getReducer('STAFF_ACCESSED_TAB_ENT_GET'),
  survey_question_library_get: ReduxGenerator.getReducer('SURVEY_QUESTION_LIBRARY_GET'),
  survey_question_config_get: ReduxGenerator.getReducer('SURVEY_QUESTION_CONFIG_GET'),
  survey_question_section_upsert: ReduxGenerator.getReducer('SURVEY_QUESTION_SECTION_UPSERT'),
  survey_question_config_banner_get: ReduxGenerator.getReducer('SURVEY_QUESTION_CONFIG_BANNER_GET'),
  survey_question_section_combo_get: ReduxGenerator.getReducer('SURVEY_QUESTION_SECTION_COMBO_GET'),
  survey_question_config_upsert: ReduxGenerator.getReducer('SURVEY_QUESTION_CONFIG_UPSERT'),
  survey_question_category_config_upsert: ReduxGenerator.getReducer(
    'SURVEY_QUESTION_CATEGORY_CONFIG_UPSERT',
  ),
  student_queue_list_get: ReduxGenerator.getReducer('STUDENT_QUEUE_LIST_GET'),
  student_queue_delete: ReduxGenerator.getReducer('STUDENT_QUEUE_DELETE'),
  student_available_spaces_list_get: ReduxGenerator.getReducer('STUDENT_AVAILABLE_SPACES_LIST_GET'),
  student_queue_insert: ReduxGenerator.getReducer('STUDENT_QUEUE_INSERT'),
  client_screening_status_update: ReduxGenerator.getReducer('CLIENT_SCREENING_STATUS_UPDATE'),
  student_associate_insert: ReduxGenerator.getReducer('STUDENT_ASSOCIATE_INSERT'),
  student_associate_list_get: ReduxGenerator.getReducer('STUDENT_ASSOCIATE_LIST_GET'),
  student_associate_delete: ReduxGenerator.getReducer('STUDENT_ASSOCIATE_DELETE'),
  center_option_get: ReduxGenerator.getReducer('CENTER_OPTION_GET'),
  combo_practitioner_center_get: ReduxGenerator.getReducer('COMBO_PRACTITIONER_CENTER_GET'),
  prospect_archive: ReduxGenerator.getReducer('PROSPECT_ARCHIVE'),
  tokens_student_transaction_history: ReduxGenerator.getReducer(
    'TOKENS_STUDENT_TRANSACTION_HISTORY',
  ),
  tokens_counselor_transaction_history: ReduxGenerator.getReducer(
    'TOKENS_COUNSELOR_TRANSACTION_HISTORY',
  ),
  tokens_student_transaction_history_get_all: ReduxGenerator.getReducer(
    'TOKENS_STUDENT_TRANSACTION_HISTORY_GET_ALL',
  ),
  token_management_counselor_transaction_history_get_all: ReduxGenerator.getReducer(
    'TOKEN_MANAGEMENT_COUNSELOR_TRANSACTION_HISTORY_GET_ALL',
  ),
  center_over_head_cost_get: ReduxGenerator.getReducer('CENTER_OVER_HEAD_COST_GET'),
  center_over_head_cost_upsert: ReduxGenerator.getReducer('CENTER_OVER_HEAD_COST_UPSERT'),
  center_laber_cost_get: ReduxGenerator.getReducer('CENTER_LABER_COST_GET'),
  center_laber_cost_get_upsert: ReduxGenerator.getReducer('CENTER_LABER_COST_GET_UPSERT'),
  center_consumable_get_all: ReduxGenerator.getReducer('CENTER_CONSUMABLE_GET_ALL'),
  center_consumable_get: ReduxGenerator.getReducer('CENTER_CONSUMABLE_GET'),
  center_consumable_get_upsert: ReduxGenerator.getReducer('CENTER_CONSUMABLE_GET_UPSERT'),
  center_consumable_delete: ReduxGenerator.getReducer('CENTER_CONSUMABLE_DELETE'),
  dashboard_pending_survey_list_get: ReduxGenerator.getReducer('DASHBOARD_PENDING_SURVEY_LIST_GET'),
  client_embedding_prepare: ReduxGenerator.getReducer('CLIENT_EMBEDDING_PREPARE'),
  ask_question: ReduxGenerator.getReducer('ASK_QUESTION'),
  chat_get: ReduxGenerator.getReducer('CHAT_GET'),
  youuniverse_conversation_get: ReduxGenerator.getReducer('YOUUNIVERSE_CONVERSATION_GET'),
  youuniverse_chat_get: ReduxGenerator.getReducer('YOUUNIVERSE_CHAT_GET'),
  youuniverse_chat_post: ReduxGenerator.getReducer('YOUUNIVERSE_CHAT_POST'),
  lab_order_panel_code_combo_get: ReduxGenerator.getReducer('LAB_ORDER_PANEL_CODE_COMBO_GET'),
  default_practotioner_facility_get: ReduxGenerator.getReducer('DEFAULT_PRACTOTIONER_FACILITY_GET'),
  license_notify_center_admin: ReduxGenerator.getReducer('LICENSE_NOTIFY_CENTER_ADMIN'),
  goal_library_upsert: ReduxGenerator.getReducer('GOAL_LIBRARY_UPSERT'),
  goal_library_archive: ReduxGenerator.getReducer('GOAL_LIBRARY_ARCHIVE'),
  goal_library_get_all: ReduxGenerator.getReducer('GOAL_LIBRARY_GET_ALL'),
  goal_library_get: ReduxGenerator.getReducer('GOAL_LIBRARY_GET'),
  combo_service_code: ReduxGenerator.getReducer('COMBO_SERVICE_CODE'),
  center_token_assign_update: ReduxGenerator.getReducer('CENTER_TOKEN_ASSIGN_UPDATE'),
  service_code_bundle_upsert: ReduxGenerator.getReducer('SERVICE_CODE_BUNDLE_UPSERT'),
  service_code_bundle_delete: ReduxGenerator.getReducer('SERVICE_CODE_BUNDLE_DELETE'),
  service_code_bundle_get_all: ReduxGenerator.getReducer('SERVICE_CODE_BUNDLE_GET_ALL'),
  service_code_bundle_get: ReduxGenerator.getReducer('SERVICE_CODE_BUNDLE_GET'),
  smart_contract_upsert: ReduxGenerator.getReducer('SMART_CONTRACT_UPSERT'),
  vbc_ledger_remark_upsert: ReduxGenerator.getReducer('VBC_LEDGER_REMARK_UPSERT'),
  distribution_payment_upsert: ReduxGenerator.getReducer('DISTRIBUTION_PAYMENT_UPSERT'),
  smart_contract_get: ReduxGenerator.getReducer('SMART_CONTRACT_GET'),
  smart_contract_request_get: ReduxGenerator.getReducer('SMART_CONTRACT_REQUEST_GET'),
  smart_contract_request_update: ReduxGenerator.getReducer('SMART_CONTRACT_REQUEST_UPDATE'),
  client_insurance_get: ReduxGenerator.getReducer('CLIENT_INSURANCE_GET'),
  payer_portal_dashboard: ReduxGenerator.getReducer('PAYER_PORTAL_DASHBOARD'),
  payer_portal_authorization_get: ReduxGenerator.getReducer('PAYER_PORTAL_AUTHORIZATION_GET'),
  payer_portal_authorization_status_upsert: ReduxGenerator.getReducer(
    'PAYER_PORTAL_AUTHORIZATION_STATUS_UPSERT',
  ),
  payer_portal_request_insert: ReduxGenerator.getReducer('PAYER_PORTAL_REQUEST_INSERT'),
  student_recommendations_list_get: ReduxGenerator.getReducer('STUDENT_RECOMMENDATIONS_LIST_GET'),
  youuniverse_bookmark_question_upsert: ReduxGenerator.getReducer(
    'YOUUNIVERSE_BOOKMARK_QUESTION_UPSERT',
  ),
  youuniverse_bookmark_question_list_get: ReduxGenerator.getReducer(
    'YOUUNIVERSE_BOOKMARK_QUESTION_LIST_GET',
  ),
  youuniverse_chat_attribute_update: ReduxGenerator.getReducer('YOUUNIVERSE_CHAT_ATTRIBUTE_UPDATE'),
  youuniverse_conversation_delete: ReduxGenerator.getReducer('YOUUNIVERSE_CONVERSATION_DELETE'),
  youu_chat_edited_answer_upsert: ReduxGenerator.getReducer('YOUU_CHAT_EDITED_ANSWER_UPSERT'),
  smart_contract_ledger_get: ReduxGenerator.getReducer('SMART_CONTRACT_LEDGER_GET'),
  client_status_report: ReduxGenerator.getReducer('CLIENT_STATUS_REPORT'),
  user_trigger_report: ReduxGenerator.getReducer('USER_TRIGGER_REPORT'),
  survey_question_library_question_list_get: ReduxGenerator.getReducer(
    'SURVEY_QUESTION_LIBRARY_QUESTION_LIST_GET',
  ),
  survey_question_library_question_delete: ReduxGenerator.getReducer(
    'SURVEY_QUESTION_LIBRARY_QUESTION_DELETE',
  ),
  survey_question_library_section_update: ReduxGenerator.getReducer(
    'SURVEY_QUESTION_LIBRARY_SECTION_UPDATE',
  ),
  survey_question_library_question_upsert: ReduxGenerator.getReducer(
    'SURVEY_QUESTION_LIBRARY_QUESTION_UPSERT',
  ),
  smart_contract_update: ReduxGenerator.getReducer('SMART_CONTRACT_UPDATE'),
  student_note_get_all: ReduxGenerator.getReducer('STUDENT_NOTE_GET_ALL'),
  ask_follow_up_initiate: ReduxGenerator.getReducer('ASK_FOLLOW_UP_INITIATE'),
  survey_question_library_question_get: ReduxGenerator.getReducer(
    'SURVEY_QUESTION_LIBRARY_QUESTION_GET',
  ),
  signed_note_get: ReduxGenerator.getReducer('SIGNED_NOTE_GET'),
  dashboard_task_list_get: ReduxGenerator.getReducer('DASHBOARD_TASK_LIST_GET'),
  dashboard_course_list_get: ReduxGenerator.getReducer('DASHBOARD_COURSE_LIST_GET'),
  dashboard_contract_list_get: ReduxGenerator.getReducer('DASHBOARD_CONTRACT_LIST_GET'),
  dashboard_alert_list_get: ReduxGenerator.getReducer('DASHBOARD_ALERT_LIST_GET'),
  center_labor_burden_get: ReduxGenerator.getReducer('CENTER_LABOR_BURDEN_GET'),
  center_labor_burden_upsert: ReduxGenerator.getReducer('CENTER_LABOR_BURDEN_UPSERT'),
  student_timer_upsert: ReduxGenerator.getReducer('STUDENT_TIMER_UPSERT'),
  get_appointment_client: ReduxGenerator.getReducer('GET_APPOINTMENT_CLIENT'),
  appointment_note_list_get: ReduxGenerator.getReducer('APPOINTMENT_NOTE_LIST_GET'),
  combo_service_code_get: ReduxGenerator.getReducer('COMBO_SERVICE_CODE_GET'),
  note_type_list_get: ReduxGenerator.getReducer('NOTE_TYPE_LIST_GET'),
  note_type_upsert: ReduxGenerator.getReducer('NOTE_TYPE_UPSERT'),
  student_merge_combo_list_get: ReduxGenerator.getReducer('STUDENT_MERGE_COMBO_LIST_GET'),
  student_merge_details_get: ReduxGenerator.getReducer('STUDENT_MERGE_DETAILS_GET'),
  student_merge_secondary_details_get: ReduxGenerator.getReducer(
    'STUDENT_MERGE_SECONDARY_DETAILS_GET',
  ),
  student_merge: ReduxGenerator.getReducer('STUDENT_MERGE'),
  student_twilio_transcript_get: ReduxGenerator.getReducer('STUDENT_TWILIO_TRANSCRIPT_GET'),
  power_bi_report_list_get: ReduxGenerator.getReducer('POWER_BI_REPORT_LIST_GET'),
  appointment_pre_selected_get: ReduxGenerator.getReducer('APPOINTMENT_PRE_SELECTED_GET'),
  call_note_augmentation: ReduxGenerator.getReducer('CALL_NOTE_AUGMENTATION'),
  call_summary_augment: ReduxGenerator.getReducer('CALL_SUMMARY_AUGMENT'),
  gpt_augmentation_update: ReduxGenerator.getReducer('GPT_AUGMENTATION_UPDATE'),
  center_field_get: ReduxGenerator.getReducer('CENTER_FIELD_GET'),
  center_field_upsert: ReduxGenerator.getReducer('CENTER_FIELD_UPSERT'),
  client_send_mobile_app_invite: ReduxGenerator.getReducer('CLIENT_SEND_MOBILE_APP_INVITE'),
  smart_contract_patient_list_get: ReduxGenerator.getReducer('SMART_CONTRACT_PATIENT_LIST_GET'),
  smart_contract_list_get: ReduxGenerator.getReducer('SMART_CONTRACT_LIST_GET'),
  terms_condition_list_get: ReduxGenerator.getReducer('TERMS_CONDITION_LIST_GET'),
  terms_condition_upsert: ReduxGenerator.getReducer('TERMS_CONDITION_UPSERT'),
  terms_condition_get_all_info_json: ReduxGenerator.getReducer('TERMS_CONDITION_GET_ALL_INFO_JSON'),
  center_config_get: ReduxGenerator.getReducer('CENTER_CONFIG_GET'),
  center_config_get_by_id: ReduxGenerator.getReducer('CENTER_CONFIG_GET_BY_ID'),
  payer_price_details_get: ReduxGenerator.getReducer('PAYER_PRICE_DETAILS_GET'),
  payer_portal_request_upsert: ReduxGenerator.getReducer('PAYER_PORTAL_REQUEST_UPSERT'),
  client_insurance_combo_get: ReduxGenerator.getReducer('CLIENT_INSURANCE_COMBO_GET'),
  smart_contract_terms_condition_getallinfo_json: ReduxGenerator.getReducer(
    'SMART_CONTRACT_TERMS_CONDITION_GETALLINFO_JSON',
  ),
  terms_condition_delete: ReduxGenerator.getReducer('TERMS_CONDITION_DELETE'),
  terms_condition_combo_get: ReduxGenerator.getReducer('TERMS_CONDITION_COMBO_GET'),
  combo_lab_order_practitioner: ReduxGenerator.getReducer('COMBO_LAB_ORDER_PRACTITIONER'),
  smart_contract_payers_list_get: ReduxGenerator.getReducer('SMART_CONTRACT_PAYERS_LIST_GET'),
  payer_portal_request_get: ReduxGenerator.getReducer('PAYER_PORTAL_REQUEST_GET'),
  group_service_line_approve_all_billing_or_batching: ReduxGenerator.getReducer(
    'GROUP_SERVICE_LINE_APPROVE_ALL_BILLING_OR_BATCHING',
  ),
  payer_terms_conditions_status_upsert: ReduxGenerator.getReducer(
    'PAYER_TERMS_CONDITIONS_STATUS_UPSERT',
  ),
  client_care_team_list_get: ReduxGenerator.getReducer('CLIENT_CARE_TEAM_LIST_GET'),
  dashboard_mobile_user_get_all: ReduxGenerator.getReducer('DASHBOARD_MOBILE_USER_GET_ALL'),
  ent_mobile_user_dashboard_get: ReduxGenerator.getReducer('ENT_MOBILE_USER_DASHBOARD_GET'),
  set_stickey_note: ReduxGenerator.getReducer('STICKEY_NOTE'),
  recovery_capital_quadrant_graph_get: ReduxGenerator.getReducer(
    'RECOVERY_CAPITAL_QUADRANT_GRAPH_GET',
  ),
  client_merge_get: ReduxGenerator.getReducer('CLIENT_MERGE_GET'),
  client_merge_secondary_get: ReduxGenerator.getReducer('CLIENT_MERGE_SECONDARY_GET'),
  client_combo_get_all: ReduxGenerator.getReducer('CLIENT_COMBO_GET_ALL'),
  client_merge: ReduxGenerator.getReducer('CLIENT_MERGE'),
  clinical_to_intake_move_patient: ReduxGenerator.getReducer('CLINICAL_TO_INTAKE_MOVE_PATIENT'),
  staff_license_agreement_update: ReduxGenerator.getReducer('STAFF_LICENSE_AGREEMENT_UPDATE'),
  smart_contract_terms_condition_upsert: ReduxGenerator.getReducer(
    'SMART_CONTRACT_TERMS_CONDITION_UPSERT',
  ),
  user_tag_type_insert: ReduxGenerator.getReducer('USER_TAG_TYPE_INSERT'),
  user_tag_type_list_get: ReduxGenerator.getReducer('USER_TAG_TYPE_LIST_GET'),
  user_tag_type_combo_list_get: ReduxGenerator.getReducer('USER_TAG_TYPE_COMBO_LIST_GET'),
  user_tag_type_update: ReduxGenerator.getReducer('USER_TAG_TYPE_UPDATE'),
  list_prospect_appointment: ReduxGenerator.getReducer('LIST_PROSPECT_APPOINTMENT'),
  recovery_capital_trend_graph_get: ReduxGenerator.getReducer('RECOVERY_CAPITAL_TREND_GRAPH_GET'),
  care_team_assign: ReduxGenerator.getReducer('CARE_TEAM_ASSIGN'),
  service_code_care_team_get: ReduxGenerator.getReducer('SERVICE_CODE_CARE_TEAM_GET'),
  smart_contract_tab_get: ReduxGenerator.getReducer('SMART_CONTRACT_TAB_GET'),
  smart_contract_distribution_get: ReduxGenerator.getReducer('SMART_CONTRACT_DISTRIBUTION_GET'),
  smart_contract_bundle_submit_to_payer: ReduxGenerator.getReducer(
    'SMART_CONTRACT_BUNDLE_SUBMIT_TO_PAYER',
  ),
  smart_contract_payer_bundle_get_to_submit: ReduxGenerator.getReducer(
    'SMART_CONTRACT_PAYER_BUNDLE_GET_TO_SUBMIT',
  ),
  service_code_suggestions_generate: ReduxGenerator.getReducer('SERVICE_CODE_SUGGESTIONS_GENERATE'),
  service_code_suggestion_list_get: ReduxGenerator.getReducer('SERVICE_CODE_SUGGESTION_LIST_GET'),
  peer_recovery_service_code_update: ReduxGenerator.getReducer('PEER_RECOVERY_SERVICE_CODE_UPDATE'),
  broadcast_user_list_get: ReduxGenerator.getReducer('BROADCAST_USER_LIST_GET'),
  client_report_get: ReduxGenerator.getReducer('CLIENT_REPORT_GET'),
  client_risk_level_graph_get: ReduxGenerator.getReducer('CLIENT_RISK_LEVEL_GRAPH_GET'),
  contract_period_graph_get: ReduxGenerator.getReducer('CONTRACT_PERIOD_GRAPH_GET'),
  dashboard_claims_get: ReduxGenerator.getReducer('DASHBOARD_CLAIMS_GET'),
  dashboard_cards_get: ReduxGenerator.getReducer('DASHBOARD_CARDS_GET'),
  risk_profile_data_graph_get: ReduxGenerator.getReducer('RISK_PROFILE_DATA_GRAPH_GET'),
  app_user_journal_augment_get: ReduxGenerator.getReducer('APP_USER_JOURNAL_AUGMENT_GET'),
  app_user_journal_augmentation: ReduxGenerator.getReducer('APP_USER_JOURNAL_AUGMENTATION'),
  student_journal_augment_get: ReduxGenerator.getReducer('STUDENT_JOURNAL_AUGMENT_GET'),
  student_journal_augmentation: ReduxGenerator.getReducer('STUDENT_JOURNAL_AUGMENTATION'),
  student_course_augmentation_get: ReduxGenerator.getReducer('STUDENT_COURSE_AUGMENTATION_GET'),
  student_course_augmentation: ReduxGenerator.getReducer('STUDENT_COURSE_AUGMENTATION'),
  dashboard_radar_graph_get: ReduxGenerator.getReducer('DASHBOARD_RADAR_GRAPH_GET'),
  smart_contract_document_request_get: ReduxGenerator.getReducer(
    'SMART_CONTRACT_DOCUMENT_REQUEST_GET',
  ),
  gpt_augmentation_get: ReduxGenerator.getReducer('GPT_AUGMENTATION_GET'),
  task_combo_get: ReduxGenerator.getReducer('TASK_COMBO_GET'),
  recovery_category_combo_get: ReduxGenerator.getReducer('RECOVERY_CATEGORY_COMBO_GET'),
  gpt_augmentation_with_ai: ReduxGenerator.getReducer('GPT_AUGMENTATION_WITH_AI'),
  smart_contract_clone: ReduxGenerator.getReducer('SMART_CONTRACT_CLONE'),
  external_staff_client_insurance_get: ReduxGenerator.getReducer(
    'EXTERNAL_STAFF_CLIENT_INSURANCE_GET',
  ),
  external_staff_referral_upsert: ReduxGenerator.getReducer('EXTERNAL_STAFF_REFERRAL_UPSERT'),
  client_referral_to_center: ReduxGenerator.getReducer('CLIENT_REFERRAL_TO_CENTER'),
  external_staff_referral_form_get: ReduxGenerator.getReducer('EXTERNAL_STAFF_REFERRAL_FORM_GET'),
  task_client_upsert: ReduxGenerator.getReducer('TASK_CLIENT_UPSERT'),
  task_client_list_get: ReduxGenerator.getReducer('TASK_CLIENT_LIST_GET'),
  task_client_update: ReduxGenerator.getReducer('TASK_CLIENT_UPDATE'),
  task_client_reassign: ReduxGenerator.getReducer('TASK_CLIENT_REASSIGN'),
  student_note_history_get: ReduxGenerator.getReducer('STUDENT_NOTE_HISTORY_GET'),
  app_user_journal_history_get: ReduxGenerator.getReducer('APP_USER_JOURNAL_HISTORY_GET'),
  appointment_get_client_upcoming_schedule: ReduxGenerator.getReducer(
    'APPOINTMENT_GET_CLIENT_UPCOMING_SCHEDULE',
  ),
  list_client_outstanding_balance_get: ReduxGenerator.getReducer(
    'LIST_CLIENT_OUTSTANDING_BALANCE_GET',
  ),
  pre_intake_get_all: ReduxGenerator.getReducer('PRE_INTAKE_GET_ALL'),
  client_shared_memo_upsert: ReduxGenerator.getReducer('CLIENT_SHARED_MEMO_UPSERT'),
  client_shared_memo_get_all: ReduxGenerator.getReducer('CLIENT_SHARED_MEMO_GET_ALL'),
  client_shared_memo_update: ReduxGenerator.getReducer('CLIENT_SHARED_MEMO_UPDATE'),
  feedback_form_analytics_get: ReduxGenerator.getReducer('FEEDBACK_FORM_ANALYTICS_GET'),
  feedback_form_get: ReduxGenerator.getReducer('FEEDBACK_FORM_GET'),
  feedback_form_delete: ReduxGenerator.getReducer('FEEDBACK_FORM_DELETE'),
  feedback_form_list_get: ReduxGenerator.getReducer('FEEDBACK_FORM_LIST_GET'),
  appointment_auto_select_get: ReduxGenerator.getReducer('APPOINTMENT_AUTO_SELECT_GET'),
});
export default rootReducer;
