import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from '.././enrollmentForm/validation/validate';
import { ReduxInput, ReduxAutoComplete } from '../../component/custom-redux-components';

const NewApplicantForm = props => {
  const { handleSubmit, style } = props;
  return (
    <div class="container">
      <form style={style} onSubmit={handleSubmit(props.onSubmit)}>
        <div class="enrollment-form">
          <div class="col-12">
            <div class="row pb-5 pl-3 pr-3 pt-3">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <Field
                    name="first name"
                    class="form-control"
                    type="text"
                    validate={validate.required}
                    component={ReduxInput}
                    fieldName={'First Name'}
                  />
                  <label class="placeholder-label">First Name*</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="form-group w-100">
                    <Field
                      name="lastName"
                      type="text"
                      errorMessage="Last Name Required"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Last Name'}
                    />
                    <label class="placeholder-label">Last Name*</label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="form-group w-100">
                    <Field
                      name="phone"
                      component={ReduxInput}
                      validate={validate.required}
                      fieldName={'Phone'}
                    />
                    <label class="placeholder-label">Phone*</label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <Field
                    name="email"
                    class="form-control"
                    type="text"
                    validate={validate.required}
                    component={ReduxInput}
                    fieldName={'Email'}
                  />
                  <label class="placeholder-label">Email*</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <Field
                    name="line1"
                    type="text"
                    errorMessage="Permanent Address Line 1 Required"
                    component={ReduxInput}
                    fieldName={'Permanent Address'}
                  />
                  <label class="placeholder-label">
                    Permanent Address Line 1<span class="small-text">(Last Known Address)</span>
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <Field name="line2" type="text" component={ReduxInput} />
                  <label class="placeholder-label">Permanent Address Line 2</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <Field
                    name="city"
                    type="text"
                    errorMessage="City Required"
                    component={ReduxInput}
                    fieldName={'City'}
                  />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <Field
                    name="stateOrProvince"
                    type="text"
                    errorMessage="State Required"
                    component={ReduxAutoComplete}
                    label={'State/Province/Territories'}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <Field
                    name="zip"
                    type="text"
                    errorMessage="Zip Code Required"
                    component={ReduxInput}
                    validate={validate.required}
                    fieldName={'Zip Code'}
                  />
                  <label class="placeholder-label">Zip Code</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <button class="btn btn-block" color="blue">
                Send a Link
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
const ReduxNewApplicantForm = reduxForm({
  form: 'newApplicant',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(NewApplicantForm);

export default ReduxNewApplicantForm;
