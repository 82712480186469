import React from 'react';
import { ReduxSelect } from '../../../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import CustomModal from '../../../core/modal';
import WarningComponent from '../../../core/warning-component';
import { Field, reduxForm, formValueSelector, isDirty } from 'redux-form';
import {
  getStudent,
  addStudent,
  resetAddRequest,
  appInviteDashboard,
  userAppLinkValidate,
  resetUserAppLinkValidate,
  resetGetStudent,
  listSurveyQuestionAssessment,
  surveyAnswerUpsert,
  preIntakeHistoryGet,
  resetPreIntakeHistoryGet,
  getFormFieldMetaData,
  formDataGet,
  studentRecommendationsListGet,
  resetStudentRecommendationsListGet,
  studentBanner,
} from '../../../store/actions';
import { ProcessCombos } from '../../../store/comboConfig';
import { get } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import {
  getPhoneNumber,
  getCountryCalling,
  isValidMobileNumber,
} from '../../../util/phoneFormatter';
import validate from '../../enrollmentForm/validation/validate';
import Loader, { Loader2 } from '../../../Components/Loader';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../core/generic-form';
import moment from 'moment';
import RecommendationsModal from '../components/RecommendationsModal';
import { stringDate } from '../../../util/date';
import StopwatchTimerAction from './StopwatchTimerAction';
import CreateAppointment from './dashboard/AppointmentsScreens/createAppointment';

class ActivityBase extends React.Component {}

class PreIntakeForm extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
      studentData: {
        CountryISO: 'USA',
        PreIntakeRelationshipId: 2,
        StudentMore: [
          {
            PreIntakeRelationshipId: 2,
          },
        ],
      },
      whoIsCallingMap: {
        2: 'ProspectDemographics',
        1: 'ProspectConcernedPersonDemographics',
        3: 'ProspectPartnerOrganizatonDemographics',
      },
      isRecommendationModelOpen: false,
    };
  }
  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = null;
    }
    return studentId;
  }
  getProspectData = StudentId => {
    this.props.dispatch(getStudent(StudentId));
    this.props.dispatch(
      preIntakeHistoryGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: StudentId,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    if (this.props.ID > 0 || this.getIntakeId()) {
      this.getProspectData(this.props.ID || this.getIntakeId());
    }

    var result = ProcessCombos(
      'ReferenceSource,Country,Filter_StateProvince,PreIntakeRelationship,RelationShip,ClientTherapy',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    // this.props.dispatch(resetFormDataGet({}));
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentWillUnmount() {
    this.props.dispatch(resetGetStudent());
    this.props.dispatch(resetPreIntakeHistoryGet());
  }
  componentWillReceiveProps({
    studentInfo,
    isInserted,
    enrollStatus,
    isInvitationSent,
    formVal,
    isValidPhone,
    primaryCNValue,
    recommendations,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      if (enrollStatus.enrollStatusId === 3 && enrollStatus.PreSurveyId) {
        let isSaveClicked = this.props.getSaveStatus();
        if (isSaveClicked) {
          // studentInfo.IsClientScreeningCompleted
          //   ? this.props.history.push(
          //       `/clients/pre-intake/dashboard?tab=summary&id=${enrollStatus.studentId}`,
          //     )
          //   : this.getIntakeId()
          //   ? this.props.history.push(`/clients/pre-intake`)
          //   : this.props.history.push(
          //       `/pre-intake-form/?studentid=${enrollStatus.studentId}&surveyid=${enrollStatus.PreSurveyId}`,
          //     );
          this.props.history.push(
            `/pre-intake-form/?studentid=${enrollStatus.studentId}&surveyid=${enrollStatus.PreSurveyId}`,
          );
          this.toggle('Prospect saved successfully.');
        } else {
          this.props.history.push(
            `/pre-intake-form?step=client-screening&tab=legal&studentid=${enrollStatus.studentId}&surveyid=${enrollStatus.PreSurveyId}`,
          );
          this.props.updateStep(enrollStatus.studentId);
        }
      } else if (enrollStatus.enrollStatusId === 0) {
        this.props.history.push('/enrollment/?studentid=' + enrollStatus.studentId);
      } else {
        this.props.history.push(
          `/clients/pre-intake/dashboard?tab=summary&id=${enrollStatus.studentId}`,
        );
        // this.props.history.push('/clients/pre-intake');
      }
      this.props.dispatch(resetAddRequest());
      this.getProspectData(enrollStatus.studentId);
      this.props.dispatch(
        studentBanner({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: enrollStatus.studentId,
              },
            ],
          }),
        }),
      );
    }

    if (studentInfo && studentInfo.StudentId > 0 && (this.getIntakeId() || this.props.ID > 0)) {
      let data = {
        ...studentInfo,
        studentId: studentInfo.StudentId,
        StudentId: studentInfo.StudentId,
        // primaryCN: studentInfo.PrimaryPhone
        //   ? `+${studentInfo.PrimaryPhoneCountryCode}${studentInfo.PrimaryPhone}`
        //   : null,
        CountryISO: studentInfo.CountryISO || 'USA',
      };
      if (studentInfo.StudentMore && studentInfo.StudentMore[0]) {
        data = {
          ...data,
          PreIntakeRelationshipId: studentInfo.StudentMore[0].PreIntakeRelationshipId,
          ReferenceSourceId: studentInfo.StudentMore[0].ReferenceSourceId,
          TherapyId: studentInfo.StudentMore[0].TherapyId,
          SourceMobile: studentInfo.StudentMore[0].SourceMobile,
          SourceEmail: studentInfo.StudentMore[0].SourceEmail,
          SourceAddress: studentInfo.StudentMore[0].SourceAddress,
          IsthisPeerRecoveryClient: studentInfo.StudentMore[0].IsthisPeerRecoveryClient,
          PeerFirstName: studentInfo.StudentMore[0].PeerFirstName,
          PeerLastName: studentInfo.StudentMore[0].PeerLastName,
          PeerDateofBirth: studentInfo.StudentMore[0].PeerDateofBirth,
          NumberofChildren: studentInfo.StudentMore[0].NumberofChildren,
          Age: studentInfo.StudentMore[0].Age,
          IsVeteran: studentInfo.StudentMore[0].IsVeteran,
          IsSexOffender: studentInfo.StudentMore[0].IsSexOffender,
          PendingCharges: studentInfo.StudentMore[0].PendingCharges,
          VisitedBefore: studentInfo.StudentMore[0].VisitedBefore,
          ReferralSource: studentInfo.StudentMore[0].ReferralSource,
        };
      }
      if (studentInfo.ProspectContact && studentInfo.ProspectContact[0]) {
        data = {
          ...data,
          ProspectContactId: studentInfo.ProspectContact[0].ContactId,
          RelationShipId: studentInfo.ProspectContact[0].Contact[0].RelationShipId,
          ContactFirstName: studentInfo.ProspectContact[0].Contact[0].ContactFirstName,
          ContactMiddleName: studentInfo.ProspectContact[0].Contact[0].ContactMiddleName,
          ContactLastName: studentInfo.ProspectContact[0].Contact[0].ContactLastName,
          ContactAddressLine1: studentInfo.ProspectContact[0].Contact[0].ContactAddressLine1,
          ContactPrimaryPhone: studentInfo.ProspectContact[0].Contact[0].ContactPrimaryPhone,
          ContactEmail: studentInfo.ProspectContact[0].Contact[0].ContactEmail,
          OrganizationType: studentInfo.ProspectContact[0].Contact[0].OrganizationType,
          OrganizationName: studentInfo.ProspectContact[0].Contact[0].OrganizationName,
          CallerName: studentInfo.ProspectContact[0].Contact[0].CallerName,
        };
      }
      if (studentInfo.StudentInsurance && studentInfo.StudentInsurance[0]) {
        data = {
          ...data,
          InsuranceCompany: studentInfo.StudentInsurance[0].InsuranceCompany,
          PolicyNumber: studentInfo.StudentInsurance[0].PolicyNumber,
          PolicyHolderName: studentInfo.StudentInsurance[0].PolicyHolderName,
          PolicyHolderDOB: studentInfo.StudentInsurance[0].PolicyHolderDOB,
        };
      }
      if (!this.state.isValidated) {
        const Phone =
          data.PrimaryPhone && data.PrimaryPhone.includes('-')
            ? getPhoneNumber(data.PrimaryPhone)
            : data.PrimaryPhone;

        this.props.dispatch(
          userAppLinkValidate({
            Json: JSON.stringify({
              Client: [
                {
                  PhoneNumber: Phone,
                  Email: data.Email,
                  FirstName: data.FirstName,
                  LastName: data.LastName,
                  CountryISOCode: data.CountryISO,
                },
              ],
            }),
          }),
        );
      }
      this.setState({
        studentData: data,
        isValidated: true,
      });
    }
    if (isInvitationSent && isInvitationSent != this.props.isInvitationSent) {
      this.checkAppStatus();
      toast.success('Invitation Sent Successfully', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (isValidPhone && isValidPhone != this.props.isValidPhone) {
      const { CountryISO, FirstName, LastName, PrimaryPhone, Email } = formVal || {};
      if (PrimaryPhone) {
        const Phone =
          PrimaryPhone && PrimaryPhone.includes('-') ? getPhoneNumber(PrimaryPhone) : PrimaryPhone;
        this.props.dispatch(
          userAppLinkValidate({
            Json: JSON.stringify({
              Client: [
                {
                  PhoneNumber: Phone,
                  Email: Email,
                  FirstName: FirstName,
                  LastName: LastName,
                  CountryISOCode: CountryISO,
                },
              ],
            }),
          }),
        );
      }
    }
    if (
      recommendations &&
      JSON.stringify(recommendations) != JSON.stringify(this.props.recommendations)
    ) {
      this.setState({ isRecommendationModelOpen: true, recommendations });
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  checkAppStatus = formVal => {
    const { FirstName, LastName, PrimaryPhone, Email, CountryISO } = this.props.formVal || {};
    const Phone =
      PrimaryPhone && PrimaryPhone.includes('-') ? getPhoneNumber(PrimaryPhone) : PrimaryPhone;
    this.props.dispatch(
      userAppLinkValidate({
        Json: JSON.stringify({
          Client: [
            {
              PhoneNumber: Phone,
              Email: Email,
              FirstName: FirstName,
              LastName: LastName,
              CountryISOCode: CountryISO,
            },
          ],
        }),
      }),
    );
  };
  sendAppInvit = () => {
    const { firstName, lastName, primaryCN, email, country } = this.props.formVal || {};
    this.props.dispatch(
      appInviteDashboard({
        MobileNumber: getPhoneNumber(primaryCN),
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        CountryCode: country,
      }),
    );
    // this.props.dispatch(
    //   userAppLinkValidate({
    //     Json: JSON.stringify({
    //       Client: [
    //         {
    //           PhoneNumber: primaryCN && primaryCN.replace(/\D/g, ''),
    //           Email: email,
    //           FirstName: firstName,
    //           LastName: lastName,
    //           CountryISOCode: country,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  };
  render() {
    let me = this;
    return (
      <React.Fragment>
        <ReduxPreIntakeForm
          // onSaveClick={this.onSubmit.bind(this)}
          // onLastSubmit={this.props.onLastSubmit}
          initialValues={this.state.studentData}
          whoIsCallingMap={this.state.whoIsCallingMap}
          studentData={this.state.studentData}
          PreIntakeRelationshipId={
            this.state.studentData.StudentMore &&
            this.state.studentData.StudentMore[0].PreIntakeRelationshipId
          }
          onCancel={() => this.props.history.goBack()}
          IntakeId={this.props.ID || this.getIntakeId()}
          CurrentClientId={this.state.studentData.ClientId}
          isValidPhone={this.props.isValidPhone}
          sendAppInvite={() => {
            me.sendAppInvit();
          }}
          checkAppStatus={() => {
            me.checkAppStatus();
          }}
        />
        <Modal
          width={920}
          open={this.state.isRecommendationModelOpen}
          footer={null}
          closable={false}
          zIndex={100}
        >
          <RecommendationsModal
            recommendations={this.state.recommendations}
            onCancel={() => {
              this.setState({
                isRecommendationModelOpen: false,
                recommendations: null,
              });
              this.props.dispatch(resetStudentRecommendationsListGet());
            }}
            history={this.props.history}
          />
        </Modal>
        {/* <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            if (
              this.state.enrollStatus.enrollStatusId === 3 &&
              this.state.enrollStatus.PreSurveyId
            ) {
              this.props.history.push(
                `/client-screening/?studentid=${this.state.enrollStatus.studentId}&surveyid=${this.state.enrollStatus.PreSurveyId}`,
              );
              this.toggle();
            } else {
              this.props.history.push(
                '/enrollment/?studentid=' + this.state.enrollStatus.studentId,
              );
            }
          }}
          yesText="OK"
          hideCancel={true}
        /> */}

        <WarningComponent
          dirtyFlag={this.props.isDirty}
          submitSucceeded={this.props.submitSucceeded}
        />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
            }}
            yesText="Ok"
            hideCancel={true}
          />
        )}
      </React.Fragment>
    );
  }
}
const AppInviteStatus = ({ isValidPhone, inviteStatus, sendAppInvite }) => {
  return (
    <>
      {isValidPhone && inviteStatus && (
        <span class="send-app-invite" onClick={sendAppInvite}>
          {inviteStatus.Msg || 'SEND APP INVITE '}
        </span>
      )}
      {/* <span className="small-info">test</span> */}
    </>
  );
};
class PreIntakeEnrollForm extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(
      getFormFieldMetaData({
        Json: JSON.stringify({
          DynamicForm: [
            {
              // FormName: 'ProspectDemographics',
              FormName:
                this.props.whoIsCallingMap[this.props.PreIntakeRelationshipId] ||
                'ProspectDemographics',
              Id: this.props.IntakeId || null,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({
    countryISO,
    genericFormData,
    whoIsCallingValue,
    IsPeerRecoveryClient,
  }) {
    let peerClient = document.getElementsByClassName('text-bold')[0];
    if (IsPeerRecoveryClient && peerClient) {
      peerClient.style.fontWeight = '800';
    } else if (!IsPeerRecoveryClient && peerClient) {
      peerClient.style.fontWeight = '400';
    }

    if (countryISO && countryISO != this.props.countryISO) {
      let formFields = this.state.formFields.map((item, index) => {
        if (item.name == 'StateOrProvince') {
          let currentOptions = item.DependentOptions.filter(item => {
            return item.Parent == countryISO;
          })[0].Child;
          return {
            ...item,
            options: currentOptions || [],
          };
        } else {
          return item;
        }
      });

      this.setState({ formFields });
    }
    if (IsPeerRecoveryClient != this.props.IsPeerRecoveryClient) {
      let formFieldsConversion = this.state.formFields;
      formFieldsConversion = formFieldsConversion.map((item, index) => {
        if (item.DependentOn == 'IsthisPeerRecoveryClient') {
          return {
            ...item,
            condition: () => IsPeerRecoveryClient === true,
          };
        } else {
          return item;
        }
      });
      this.setState({ formFields: formFieldsConversion });
    }
    if (
      genericFormData &&
      JSON.stringify(genericFormData) != JSON.stringify(this.props.genericFormData)
    ) {
      let validationConversion = genericFormData;
      validationConversion = validationConversion.map((item, index) => {
        if (item.name == 'StateOrProvince') {
          let currentOptions = item.DependentOptions.filter(fitem => {
            return fitem.Parent == countryISO;
          })[0].Child;
          return {
            ...item,
            options: currentOptions || [],
          };
        } else if (item.name == 'IsthisPeerRecoveryClient') {
          return {
            ...item,
            class: 'col-12',
          };
        } else if (item.DependentOn == 'IsthisPeerRecoveryClient') {
          return {
            ...item,
            condition: () => IsPeerRecoveryClient === true,
          };
        } else if (item.name == 'PrimaryPhone') {
          return {
            ...item,
            isHelperComponent: true,
            // helperComponent: 'InviteStatus',
          };
        } else {
          return item;
        }
      });

      this.setState({ formFields: validationConversion });

      // if (this.props.getStaffId) {
      //   let jsonData2 = {
      //     DynamicForm: [
      //       {
      //         FormName: Staff_Form,
      //         FormDataId: this.props.getStaffId,
      //       },
      //     ],
      //   };
      //   this.props.dispatch(
      //     formDataGet({
      //       Json: JSON.stringify(jsonData2),
      //     }),
      //   );
      // } else {
      //   this.props.dispatch(reset('staffForm'));
      // }
    }
    if (whoIsCallingValue && whoIsCallingValue != this.props.whoIsCallingValue) {
      this.props.dispatch(
        getFormFieldMetaData({
          Json: JSON.stringify({
            DynamicForm: [
              {
                FormName: this.props.whoIsCallingMap[whoIsCallingValue] || 'ProspectDemographics',
                Id: this.props.IntakeId || null,
              },
            ],
          }),
        }),
      );
    }
  }
  handleSuggetion = (event, value) => {
    const {
      CountryISO,
      FirstName,
      LastName,
      PrimaryPhone,
      Email,
      DateofBirth,
    } = this.props.formData;
    let eventValue = event.target.value;
    if (!this.props.IntakeId && eventValue.length)
      this.props.dispatch(
        studentRecommendationsListGet({
          Json: JSON.stringify({
            Student: [
              {
                FirstName: FirstName,
                LastName: LastName,
                PrimaryPhone: PrimaryPhone,
                Email: Email,
                DateofBirth: DateofBirth,
                DateofBirth: DateofBirth ? stringDate(DateofBirth) : null,
              },
            ],
          }),
        }),
      );
    if (event.target.name == 'PrimaryPhone' && PrimaryPhone && PrimaryPhone.length > 5) {
      // let isValidMobile = isValidMobileNumber(PrimaryPhone);
      // if (isValidMobile === true) {
      //   this.props.checkAppStatus();
      // }
      this.props.checkAppStatus();
    }
  };
  scheduleAppointments = () => {
    this.setState({ isAppointmentDrawer: !this.state.isAppointmentDrawer });
  };
  HELPER_COMPONENT = {};
  render() {
    const {
      onSaveClick,
      onSubmit,
      handleSubmit,
      initialize,
      getIntakeId,
      initialValues,
      onCancel,
      Delete,
      history,
      dispatch,
      change,
      ReferenceSource,
      TherapyTypes,
      Country,
      Filter_State,
      sendAppInvite,
      checkAppStatus,
      isValidPhone,
      inviteStatus,
      PreIntakeRelationship,
      whoIsCallingValue,
      isFetching,
      NoteHistory,
      // FeatureFlags: { enableDictation },
      enableDictation,
      presetNotes,
      onLastSubmit,
      IntakeId,
      IsPeerRecoveryClient,
      CurrentClientId,
      formData,
    } = this.props;
    const { formFields } = this.state;

    const handleDictate = result => {
      let data;
      if (result) {
        if (presetNotes) {
          data = `${presetNotes} ${result}`;
          change('preIntakeNotes', data);
        } else {
          data = result;
          change('preIntakeNotes', data);
        }
      }
    };

    const handleClear = () => {
      if (presetNotes) {
        change('preIntakeNotes', null);
      }
    };
    return (
      <div class="main enrollment-page mb-5">
        <Loader2 loading={isFetching} />
        <form onSubmit={handleSubmit}>
          <div class="row">
            <div class="col-12 mb-2">
              <div class="card border-0 rounded-0">
                <div class="card-body enrollment-form pb-2">
                  <div class="row px-3">
                    <div class="col-12 mb-3">
                      <h6>Who is Calling?</h6>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`PreIntakeRelationshipId`}
                          type="text"
                          component={ReduxSelect}
                          placeholder="Who is calling?*"
                          fieldName="Who is calling"
                          options={PreIntakeRelationship}
                          validate={validate.required}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-2">
              <div class="card border-0 rounded-0 demographics-form">
                <div class="card-body enrollment-form">
                  <div class="row">
                    <DynamicForm
                      formFields={formFields}
                      disable={false}
                      handleEvent={
                        this.handleSuggetion
                        // IntakeId ? null : this.handleSuggetion
                      }
                      helperComponent={
                        isValidPhone ? (
                          <AppInviteStatus
                            isValidPhone={true}
                            inviteStatus={inviteStatus}
                            sendAppInvite={sendAppInvite}
                          />
                        ) : null
                      }
                    />
                    {NoteHistory && (
                      <>
                        <div class="col-12 mt-3">
                          <h6>Previous Notes</h6>
                        </div>
                        <div class="col-12">
                          <div class="note-history mb-2">
                            {NoteHistory.map(notes => (
                              <div class="col-12 note-section mb-2">
                                <div className="updated-by">
                                  <span className="date-time">{notes.UpdatedOn}</span>
                                  <div className="added-by">
                                    <span className="added-text mr-2">Updated By</span>
                                    <img src={notes.ProfileImageUrl} />
                                    <div className="name-box">
                                      <span className="name">{notes.StaffName}</span>
                                      <span className="role">{notes.Role}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="note">
                                  <span>{notes.PreIntakeNotes}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    {IsPeerRecoveryClient && !CurrentClientId && !IntakeId && (
                      <div class="col-12 mb-2">
                        <span>
                          <strong>Note:</strong> Please save the client information to start the
                          session or create appointment.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="row mb-2">
            <div class="col-12 mb-2">
              <div class="card border-0 rounded-0">
                <div class="card-body enrollment-form">
                  <div class="row py-2 px-3">
                    <div class="col-12 mb-3">
                      <h6>Status of Client</h6>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name={`PreIntakeClientStatus`}
                          type="text"
                          component={ReduxSelect}
                          placeholder="Select Status of Client"
                          options={PreIntakeClientStatus}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </form>
        {IntakeId && IsPeerRecoveryClient && (
          <div class="row mb-2">
            <div class="col-12 mb-2">
              <div class="card border-0 rounded-0">
                <div class="card-body enrollment-form">
                  <div class="row py-2 px-3">
                    <div class="col-12 mb-2">
                      <div className="peer-recovery-section">
                        <div className="action d-flex align-items-center">
                          <div className="stop-timer mr-2">
                            <StopwatchTimerAction
                              ID={this.props.IntakeId}
                              CurrentClientId={CurrentClientId}
                              Record={this.props.formData}
                            />
                          </div>
                          <CreateAppointment ID={this.props.IntakeId} ClientId={CurrentClientId} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    combos,
    user_app_link_validate,
    user_feature_flag_get,
    student_add,
    pre_intake_history_get,
    get_form_field_meta_data,
  } = state;
  const { isFetching } = student_add;
  const { data: HistoryData, isFetching: isHistoryFetching } = pre_intake_history_get;
  const { data: fFData } = user_feature_flag_get;
  const { data: getGenericFormFields, isFetching: isFormFieldFetching } = get_form_field_meta_data;

  let countryISO = selector(state, 'CountryISO');
  let whoIsCallingValue = selector(state, 'PreIntakeRelationshipId');
  let presetNotes = selector(state, 'preIntakeNotes');
  let IsPeerRecoveryClient = selector(state, 'IsthisPeerRecoveryClient');
  let formData = selector(
    state,
    'CountryISO',
    'FirstName',
    'LastName',
    'PrimaryPhone',
    'Email',
    'DateofBirth',
  );
  let primaryCNValue = selector(state, 'PrimaryPhone');
  let isValidPhone = false;
  let NoteHistory = null;
  let inviteStatus = null;
  let FeatureFlags = {};
  let ReferenceSource = [],
    Country = [],
    Filter_State = [],
    PreIntakeRelationship = [],
    TherapyTypes = [];
  let genericFormData = [];

  if (getGenericFormFields) {
    genericFormData = getGenericFormFields.FormFieldMetaData;
  }

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
  }
  if (combos.data && combos.data.ReferenceSource) {
    ReferenceSource = combos.data.ReferenceSource;
    // Country = combos.data.Country;
    // Filter_State = combos.data.Filter_StateProvince[countryISO];
    PreIntakeRelationship = combos.data.PreIntakeRelationship;
    TherapyTypes = combos.data.ClientTherapy;
  }
  if (user_app_link_validate.data && user_app_link_validate.data) {
    inviteStatus = user_app_link_validate.data;
  }
  if (HistoryData && HistoryData.PreIntakeNotes) {
    NoteHistory = HistoryData.PreIntakeNotes;
  }
  if (primaryCNValue && primaryCNValue.length > 10) {
    // let isValidMobile = isValidMobileNumber(primaryCNValue);
    // if (isValidMobile === true) {
    //   isValidPhone = true;
    // } else {
    //   isValidPhone = false;
    // }
    isValidPhone = true;
  }
  return {
    ReferenceSource: ReferenceSource,
    Country: Country,
    // Filter_State: Filter_State,
    inviteStatus,
    PreIntakeRelationship,
    TherapyTypes,
    whoIsCallingValue,
    isFetching: isFetching || isFormFieldFetching,
    NoteHistory,
    isHistoryFetching,
    FeatureFlags,
    presetNotes,
    genericFormData,
    countryISO,
    formData,
    IsPeerRecoveryClient,
    isValidPhone,
  };
};

const ReduxPreIntakeForm = reduxForm({
  form: 'prospectForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    let valueArray = Object.keys(values);
    // this.props.whoIsCallingMap;
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object') {
        obj[key] =
          values[key] && values[key].length > 0
            ? values[key].map(item => item.Value).toString()
            : null;
      } else {
        obj[key] = values[key];
      }
    }
    let ssn = obj.NationalId ? obj.NationalId.replace('-', '').replace('-', '') : null;
    const data = {
      Student: [
        {
          StudentId: props.IntakeId,
          ...obj,
          NationalId: ssn,
          EnrollStatusId: values.isIntake ? 0 : 3,
          StudentMore: [
            {
              PreIntakeRelationshipId: values.PreIntakeRelationshipId,
              ReferenceSourceId: values.ReferenceSourceId,
              TherapyId: values.TherapyId,
              SourceMobile: values.SourceMobile,
              SourceEmail: values.SourceEmail,
              SourceAddress: values.SourceAddress,
              IsthisPeerRecoveryClient: values.IsthisPeerRecoveryClient,
              PeerFirstName: values.PeerFirstName,
              PeerLastName: values.PeerLastName,
              PeerDateofBirth: values.PeerDateofBirth,
              NumberofChildren: values.NumberofChildren,
              IsVeteran: values.IsVeteran,
              IsSexOffender: values.IsSexOffender,
              PendingCharges: values.PendingCharges,
              VisitedBefore: values.VisitedBefore,
              ReferralSource: values.ReferralSource,
            },
          ],
          StudentInsurance: [
            {
              InsuranceCompany: values.InsuranceCompany,
              PolicyNumber: values.PolicyNumber,
              PolicyHolderName: values.PolicyHolderName,
              PolicyHolderDOB: values.PolicyHolderDOB,
            },
          ],
          ProspectContact: [
            {
              ContactId: values.ProspectContactId || null,
              Contact: [
                {
                  RelationShipId: values.RelationShipId,
                  ContactFirstName: values.ContactFirstName,
                  ContactMiddleName: values.ContactMiddleName,
                  ContactLastName: values.ContactLastName,
                  ContactAddressLine1: values.ContactAddressLine1,
                  ContactPrimaryPhone: values.ContactPrimaryPhone,
                  ContactEmail: values.ContactEmail,
                  OrganizationType: values.OrganizationType,
                  OrganizationName: values.OrganizationName,
                  CallerName: values.CallerName,
                },
              ],
            },
          ],
        },
      ],
    };
    let studentInfo = {
      userId: 1,
      centerId: props.defaultCenter,
      json: JSON.stringify(data),
    };
    if (props.IntakeId > 0) {
      studentInfo.studentId = props.IntakeId;
    }
    props.dispatch(addStudent(studentInfo));
  },
})(connect(mapStateToProps)(withRouter(PreIntakeEnrollForm)));
const selector = formValueSelector('prospectForm');

const mapStateToPropsParent = state => {
  const {
    student_get,
    student_add,
    default_values,
    app_invite_dashboard,
    student_recommendations_list_get,
  } = state;
  const { data, isFetching, error } = student_get;
  const { data: recommendationsData } = student_recommendations_list_get;

  let formVal = selector(state, 'CountryISO', 'FirstName', 'LastName', 'PrimaryPhone', 'Email');
  let primaryCNValue = selector(state, 'PrimaryPhone');
  let isValidPhone = false;
  let studentInfo = [];
  let recommendations = null;
  var isInserted = false;
  var isInvitationSent = false;
  let defaultCenter = null;
  let enrollStatus = null;
  if (default_values && default_values.data) {
    defaultCenter = default_values.data.centerId;
  }
  if (data) {
    studentInfo = data;
  }
  if (student_add && student_add.data && student_add.data.success) {
    enrollStatus = student_add.data;
    isInserted = true;
  }
  if (app_invite_dashboard.data && app_invite_dashboard.data.Success) {
    isInvitationSent = app_invite_dashboard.data.Success;
  }
  if (primaryCNValue && primaryCNValue.length > 2) {
    let isValidMobile = isValidMobileNumber(primaryCNValue);
    if (isValidMobile === true) {
      isValidPhone = true;
    } else {
      isValidPhone = false;
    }
  }
  if (recommendationsData && recommendationsData.Student) {
    recommendations = recommendationsData.Student;
  }
  return {
    defaultCenter,
    studentInfo,
    isInserted,
    enrollStatus,
    isInvitationSent,
    formVal: formVal,
    isDirty: isDirty('prospectForm')(state),
    submitSucceeded: get(state, 'form.prospectForm.submitSucceeded', false),
    isValidPhone,
    primaryCNValue,
    recommendations,
  };
};

export default connect(mapStateToPropsParent)(withRouter(PreIntakeForm));
