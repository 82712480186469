import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusInputWithRange,
} from '../../../../component/custom-redux-components';
import validate from '../../../../core/generic-form/validation';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
export const CalculateLaborCost = props => {
  const [total, setTotal] = useState(0);
  const {
    fields,
    // LaberCostType,
    CenterLaborCost,
    LaborCostFormData,
    setTotalLaborCost,
    unitOfMeasurement,
    PayFrequency,
  } = props;
  const getUnit = (LaborCostFormData, index) => {
    if (LaborCostFormData.some(item => item && item.CenterLaborCost != undefined)) {
      if (
        CenterLaborCost &&
        CenterLaborCost.filter(item => {
          if (LaborCostFormData[index] && item.Value == LaborCostFormData[index].CenterLaborCost) {
            return item.Value == LaborCostFormData[index].CenterLaborCost;
          }
        }).length > 0
      ) {
        return CenterLaborCost.filter(item => {
          if (LaborCostFormData[index] && item.Value == LaborCostFormData[index].CenterLaborCost) {
            return item.Value == LaborCostFormData[index].CenterLaborCost;
          }
        })[0].PayFrequencyText;
      }
    } else {
    }
  };
  const calVarLabor = item => {
    let filterV;
    let centerLaborCostPriceValue;
    filterV = CenterLaborCost.filter(role => {
      return role.Value == item.CenterLaborCost;
    });
    centerLaborCostPriceValue = filterV.length > 0 && filterV[0].Price * item.LaborTime;
    return parseFloat(centerLaborCostPriceValue);
  };

  const calFixedLabor = item => {
    // centerLaborCostPriceValue =
    //   LaborCostFormData.map(item => {
    //     if (item != undefined) {
    //       return item.LaborCost;
    //     } else {
    //       return item;
    //     }
    //   }) || [];

    return parseFloat(item.LaborCost);
  };

  const handleTotalChange = () => {
    if (LaborCostFormData.some(item => item != undefined && item.CenterLaborCost != undefined)) {
      let centerLaborCostPriceValue;
      let result = LaborCostFormData.map((item, index) => {
        if (item != undefined && item.CenterLaborCost != undefined) {
          if (item.CenterLaborCost != 74) {
            return calVarLabor(item);
          } else {
            return calFixedLabor(item);
            //replacing CalFixedLabor with CalVarLabor because now both labor cost calcualtions will be same as per pramod.
            // return calVarLabor(item);
            // replacing again with the new requirements.
          }
        }
        return centerLaborCostPriceValue;
      });
      let newTotal =
        result.filter(item => item).length > 0 &&
        result
          .filter(item => item)
          .reduce((p, c) => {
            return p + c;
          });
      if (!isNaN(newTotal)) {
        setTotal(Number(newTotal));
        setTotalLaborCost(Number(newTotal));
      }
    } else {
      setTotal(0);
      setTotalLaborCost(0);
    }
  };

  const getLaborCost = (LaborCostFormData, index) => {
    if (LaborCostFormData.some(item => item && item.CenterLaborCost != undefined)) {
      if (
        CenterLaborCost &&
        CenterLaborCost.filter(item => {
          if (LaborCostFormData[index] && item.Value == LaborCostFormData[index].CenterLaborCost) {
            return item.Value == LaborCostFormData[index].CenterLaborCost;
          }
        }).length > 0
      ) {
        return CenterLaborCost.filter(item => {
          if (LaborCostFormData[index] && item.Value == LaborCostFormData[index].CenterLaborCost) {
            return item.Value == LaborCostFormData[index].CenterLaborCost;
          }
        })[0].Price;
      }
    } else {
    }
  };

  useEffect(() => {
    handleTotalChange();
  }, [LaborCostFormData]);

  // useEffect(() => { fields.push();},[])
  return (
    <>
      {' '}
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 my-3">
          <div style={{ background: '#F8F8F8' }} className="p-2">
            <div className="d-flex justify-content-between p-2 mx-3">
              <h6
                className="d-flex align-items-center"
                style={{ fontWeight: '600', margin: '0px' }}
              >
                Labour Cost/Burden Details
              </h6>
              <div>
                <button
                  type="button"
                  class="btn-newCon"
                  style={{ position: 'unset', width: 'unset' }}
                  onClick={() => fields.push()}
                >
                  + Add {fields.length == 0 ? 'Labour' : 'Another Labour'}
                </button>
              </div>
            </div>

            {fields.length > 0 ? (
              fields.map((item, index) => (
                <div class="mx-4">
                  {' '}
                  <div class="row p-2">
                    <div key={index} className="col-12 field-array-item">
                      <div
                        className="row p-3"
                        style={{
                          background: '#fff',
                          border: '1px solid #EAEAEA',
                          borderRadius: '4px',
                        }}
                      >
                        {/* <div className="col-12 text-right">
                          <div className="row">
                            <div className="col-12 mt-3 mb-2">
                              <a
                                class="col-1 delete-contract"
                                onClick={() => {
                                  if (fields.length && fields.length > 0) {
                                    fields.remove(index);
                                  }
                                }}
                              >
                                <i class="fas fa-trash" /> Delete
                              </a>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-6">
                          <div class="form-group">
                            <Field
                              name={`${item}CenterLaborCost`}
                              type="text"
                              errorMessage="State Required"
                              component={ReduxSelect}
                              options={CenterLaborCost}
                              validate={validate.required}
                              placeholder={'Labour Role*'}
                              fieldName={'Labour Role'}
                              class="form-control"
                            />
                          </div>
                        </div>
                        {LaborCostFormData &&
                          LaborCostFormData[index] &&
                          LaborCostFormData[index].CenterLaborCost == 74 && (
                            <div className="col-6">
                              <div class="form-group">
                                <Field
                                  name={`${item}ContractTypeRoleId`}
                                  type="text"
                                  errorMessage="State Required"
                                  component={ReduxSelect}
                                  options={
                                    CenterLaborCost &&
                                    CenterLaborCost.filter(item => item.Text != 'Contractor')
                                  }
                                  validate={validate.required}
                                  placeholder={'Labour Type*'}
                                  fieldName={'Labour Type'}
                                  class="form-control"
                                />
                              </div>
                            </div>
                          )}

                        {/* <div className="col-6">
                          <div class="form-group">
                            <Field
                              name={`${item}LaberCostType`}
                              type="text"
                              errorMessage="State Required"
                              component={ReduxSelect}
                              options={LaberCostType}
                              validate={validate.required}
                              placeholder={'Select Labor Cost Type*'}
                              fieldName={'Laber Cost Type'}
                              class="form-control"
                            />
                          </div>
                        </div> */}

                        {/* {true &&
                          LaborCostFormData &&
                          LaborCostFormData[index] &&
                          LaborCostFormData[index].LaberCostType == 1 && (
                           
                          )} */}

                        {LaborCostFormData &&
                          LaborCostFormData[index] &&
                          LaborCostFormData[index].CenterLaborCost == 74 &&
                          LaborCostFormData[index].ContractTypeRoleId && (
                            <div className="col-6 d-flex align-items-center justify-content-around">
                              <div
                                class="license-quantity d-flex  justify-content-left w-100"
                                style={{ fontWeight: '600' }}
                              >
                                <label
                                  class="placeholder-label flex-grow-1"
                                  style={{ fontSize: '16px' }}
                                >
                                  Set Fixed Labour Cost $*
                                </label>
                                <div class="">
                                  <Field
                                    name={`${item}LaborCost`}
                                    type="number"
                                    errorMessage="State Required"
                                    component={ReduxPlusMinusInput}
                                    validate={validate.required}
                                    fieldName={'Fixed Labour Cost'}
                                    className="number-icon"
                                    style={{
                                      textAlign: 'center',
                                      margin: '0px 5px',
                                      height: '32px',
                                      width: '56px',
                                      fontSize: '16px',
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {LaborCostFormData &&
                          LaborCostFormData[index] &&
                          LaborCostFormData[index].CenterLaborCost == 74 &&
                          LaborCostFormData[index].ContractTypeRoleId && (
                            <div class="col-12 col-md-6 col-lg-6">
                              <div class="form-group">
                                <Field
                                  name={`${item}unitOfMeasurementId`}
                                  type="text"
                                  component={ReduxSelect}
                                  validate={validate.required}
                                  fieldName={'Unit of Measurement'}
                                  placeholder={'Unit of Measure*'}
                                  // options={unitOfMeasurement}
                                  options={PayFrequency}
                                />
                              </div>
                            </div>
                          )}

                        {LaborCostFormData &&
                          LaborCostFormData[index] &&
                          LaborCostFormData[index].CenterLaborCost != 74 && (
                            <div className="col-12">
                              <div className="row">
                                <div className="col-6">
                                  <div
                                    class="license-quantity d-flex  justify-content-left"
                                    style={{ fontWeight: '600', display: 'contents' }}
                                  >
                                    <label
                                      class="placeholder-label flex-grow-1"
                                      style={{ fontSize: '15px' }}
                                    >
                                      Labour Duration required ({getUnit(LaborCostFormData, index)}
                                      )*
                                    </label>
                                    <div class="">
                                      <Field
                                        name={`${item}LaborTime`}
                                        type="text"
                                        errorMessage="State Required"
                                        component={ReduxPlusMinusInputWithRange}
                                        validate={validate.required}
                                        fieldName={'Labour Duration'}
                                        className="number-icon"
                                        min={0}
                                        max={100}
                                        style={{
                                          textAlign: 'center',
                                          margin: '0px 5px',
                                          height: '32px',
                                          width: '56px',
                                          fontSize: '16px',
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6  text-right">
                                  {' '}
                                  <span
                                    class="p-2"
                                    style={{
                                      background: '#E8E8E8',
                                      color: '#7F8188',
                                      borderRadius: '2px',
                                    }}
                                  >
                                    Labour Cost/Burden:{' '}
                                    <span style={{ color: '#000', fontWeight: '700' }}>
                                      {currencyFormatter.format(
                                        getLaborCost(LaborCostFormData, index) || 0,
                                      )}{' '}
                                      {`/${getUnit(LaborCostFormData, index) &&
                                        getUnit(LaborCostFormData, index).replace('Per', '')}`}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div class="row">
                {/* <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Consumable Cost Details</h6>
            </div>
          </div> */}
              </div>
            )}
            {total ? (
              <div
                className="d-flex justify-content-between mx-3 mt-2 mb-2"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  background: '#E8E8E8',
                  borderRadius: '4px',
                }}
              >
                <div
                  class="mx-3 px-3 p-2 my-2"
                  style={{
                    fontWeight: '600',
                  }}
                >
                  Total Labour Cost/Burden
                </div>
                <div
                  class="mx-3 px-3 p-2 my-2"
                  style={{
                    fontSize: '18px',
                    fontWeight: '700',
                  }}
                >
                  {currencyFormatter.format(total || 0)}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
