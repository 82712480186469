import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

class LaderProgress extends Component {
  state = {};
  render() {
    const data = {
      labels: ['', 'Nov 5', 'Nov 12', 'Nov 19', 'Nov 26', 'Nov 26'],
      datasets: [
        {
          data: [300, 600, 400, 700, 500, 800],
          borderColor: '#fff',
          color: '#fff',
          fill: false,
        },
      ],
    };
    const options = {
      legend: { display: false },
      title: {
        display: false,

        text: 'World population per region (in millions)',
      },
    };
    return (
      <div>
        <div class="container-fluid">
          <div class="content-area">
            <div class="row">
              <div class="col-md-8 mar-top-30">
                <h1 class="main-head text-uppercase">LIVING HEALTHY</h1>
              </div>
              <div class="col-md-4 mar-top-30" />
            </div>
            <div class="row">
              <div class="col-md-8 mar-top-15">
                <div class="chart gradient-map-bg">
                  <Line data={data} options={options} />
                </div>

                <div class="mar-top-50">
                  <h3 class="side-head text-center">COURSE MATERIAL</h3>
                  <div class="center-line mar-top-15" />

                  <h2 class="sub-head text-center mar-top-50">Course Category 1</h2>

                  <ul class="cource-category-list">
                    <li>
                      <div class="cource-category-list-left">
                        <div class="cource-category-icon">
                          <i class="fa fa-check-circle" aria-hidden="true" />{' '}
                        </div>
                      </div>
                      <div class="cource-category-list-center">
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing.</h4>

                        <div class="cource-category-date-box">
                          <span class="light">Enroll Date:</span>
                          <span class="dark">Jun 1st, 2018</span>
                        </div>

                        <div class="cource-category-date-box">
                          <span class="light">Completion Date:</span>
                          <span class="dark">Jun 10th, 2018</span>
                        </div>
                      </div>
                      <div class="cource-category-list-right">
                        <Link to="#" class="re-assign-btn">
                          Re-Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left">
                        <div class="cource-category-icon">
                          <i class="fa fa-check-circle" aria-hidden="true" />{' '}
                        </div>
                      </div>
                      <div class="cource-category-list-center">
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing.</h4>

                        <div class="cource-category-date-box">
                          <span class="light">Enroll Date:</span>
                          <span class="dark">Jun 1st, 2018</span>
                        </div>

                        <div class="cource-category-date-box">
                          <span class="light">Completion Date:</span>
                          <span class="dark">Jun 10th, 2018</span>
                        </div>
                      </div>
                      <div class="cource-category-list-right">
                        <Link to="#" class="re-assign-btn">
                          Re-Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left">
                        <div class="cource-category-icon">
                          <i class="fa fa-check-circle" aria-hidden="true" />{' '}
                        </div>
                      </div>
                      <div class="cource-category-list-center">
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing.</h4>

                        <div class="cource-category-date-box">
                          <span class="light">Enroll Date:</span>
                          <span class="dark">Jun 1st, 2018</span>
                        </div>

                        <div class="cource-category-date-box">
                          <span class="light">Completion Date:</span>
                          <span class="dark">Jun 10th, 2018</span>
                        </div>
                      </div>
                      <div class="cource-category-list-right">
                        <Link to="#" class="re-assign-btn">
                          Re-Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left">
                        <div class="cource-category-icon yellow">
                          <i class="fa fa-refresh" aria-hidden="true" />
                        </div>
                      </div>
                      <div class="cource-category-list-center">
                        <h4>
                          Donec ac turpis sed lorem mollis cursus Link vel tellus. Cras in
                          consectetur nulla.
                        </h4>

                        <div class="cource-category-date-box">
                          <span class="light">Enroll Date:</span>
                          <span class="dark">Nov 11th, 2018</span>
                        </div>

                        <div class="cource-category-date-box">
                          <span class="light">Expected Date of Completion:</span>
                          <span class="dark red-text">Nov 30th, 2018</span>
                        </div>
                      </div>
                      <div class="cource-category-list-right">
                        <Link
                          to="#"
                          class="re-assign-btn black-btn"
                          data-toggle="modal"
                          color="blue"
                          data-target="#mark-complete"
                        >
                          Mark as Complete
                        </Link>
                        <div class="mar-top-10">
                          <Link to="#" class="re-assign-btn">
                            Un-Assign
                          </Link>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left" />
                      <div class="cource-category-list-center">
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing.</h4>
                      </div>
                      <div class="cource-category-list-right">
                        <Link to="#" class="re-assign-btn black-btn" color="blue">
                          Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left" />
                      <div class="cource-category-list-center">
                        <h4>
                          Pellentesque habitant morbi tristique senectus et netus et malesuada
                          egestas.
                        </h4>
                      </div>
                      <div class="cource-category-list-right">
                        <Link to="#" class="re-assign-btn black-btn" color="blue">
                          Assign
                        </Link>
                      </div>
                    </li>
                  </ul>

                  <div class="clearfix" />

                  <h2 class="sub-head text-center mar-top-50">Course Category 2</h2>

                  <ul class="cource-category-list">
                    <li>
                      <div class="cource-category-list-left" />
                      <div class="cource-category-list-center">
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing.</h4>
                      </div>
                      <div class="cource-category-list-right">
                        <Link to="#" class="re-assign-btn black-btn" color="blue">
                          Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left" />
                      <div class="cource-category-list-center">
                        <h4>
                          Praesent auctor nisl sit amet blandit varius. Nullam scelerisque eget
                          amet.
                        </h4>
                      </div>
                      <div class="cource-category-list-right">
                        <Link
                          to="#"
                          class="re-assign-btn black-btn de-active disabled"
                          color="blue"
                        >
                          Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left" />
                      <div class="cource-category-list-center">
                        <h4>
                          Praesent auctor nisl sit amet blandit varius. Nullam scelerisque eget
                          amet.
                        </h4>
                      </div>
                      <div class="cource-category-list-right">
                        <Link
                          to="#"
                          class="re-assign-btn black-btn de-active disabled"
                          color="blue"
                        >
                          Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left" />
                      <div class="cource-category-list-center">
                        <h4>
                          Praesent auctor nisl sit amet blandit varius. Nullam scelerisque eget
                          amet.
                        </h4>
                      </div>
                      <div class="cource-category-list-right">
                        <Link
                          to="#"
                          class="re-assign-btn black-btn de-active disabled"
                          color="blue"
                        >
                          Assign
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div class="cource-category-list-left" />
                      <div class="cource-category-list-center">
                        <h4>
                          Praesent auctor nisl sit amet blandit varius. Nullam scelerisque eget
                          amet.
                        </h4>
                      </div>
                      <div class="cource-category-list-right">
                        <Link
                          to="#"
                          class="re-assign-btn black-btn de-active disabled"
                          color="blue"
                        >
                          Assign
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 mar-top-15">
                <div class="activity-log-right">
                  <h3 class="side-head text-center">ACTIVITY LOG</h3>

                  <ul class="activity-log-list">
                    <li>
                      <h4>Completed Mental Health Survey</h4>
                      <p> Yesterday, Nov 29th, 2018 @ 12:15 PM</p>
                    </li>

                    <li>
                      <h4>Completed 40 hours of work training</h4>
                      <p> Nov 25th, 2018 @ 12:15 PM</p>
                    </li>

                    <li>
                      <h4>Drug test result received</h4>
                      <p> Nov 1st, 2018 @ 9:24 AM</p>
                    </li>

                    <li>
                      <h4>Blood sample taken</h4>
                      <p> Sep 22nd, 2018 @ 7:09 AM</p>
                    </li>

                    <li>
                      <h4>Drug test result received</h4>
                      <p> Sep 1st, 2018 @ 9:24 AM</p>
                    </li>

                    <li>
                      <h4>Completed Mental Health Survey</h4>
                      <p> Yesterday, Nov 29th, 2018 @ 12:15 PM</p>
                    </li>

                    <li>
                      <h4>Completed 40 hours of worktraining</h4>
                      <p> Nov 25th, 2018 @ 12:15 PM </p>
                    </li>

                    <li>
                      <h4>Drug test result received</h4>
                      <p> Nov 1st, 2018 @ 9:24 AM </p>
                    </li>

                    <li>
                      <h4>Completed Mental Health Survey</h4>
                      <p> Yesterday, Nov 29th, 2018 @ 12:15 PM</p>
                    </li>

                    <li>
                      <h4>Completed 40 hours of work training</h4>
                      <p> Nov 25th, 2018 @ 12:15 PM</p>
                    </li>

                    <li>
                      <h4>Drug test result received</h4>
                      <p> Nov 1st, 2018 @ 9:24 AM</p>
                    </li>

                    <li>
                      <h4>Blood sample taken</h4>
                      <p> Sep 22nd, 2018 @ 7:09 AM</p>
                    </li>

                    <li>
                      <h4>Drug test result received</h4>
                      <p> Sep 1st, 2018 @ 9:24 AM</p>
                    </li>

                    <li>
                      <h4>Completed Mental Health Survey</h4>
                      <p> Yesterday, Nov 29th, 2018 @ 12:15 PM</p>
                    </li>

                    <li>
                      <h4>Completed 40 hours of worktraining</h4>
                      <p> Nov 25th, 2018 @ 12:15 PM </p>
                    </li>

                    <li>
                      <h4>Drug test result received</h4>
                      <p> Nov 1st, 2018 @ 9:24 AM </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal mark-complete" id="mark-complete">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">EXPECTED COMPLETION TIME</h4>
              </div>

              <div class="modal-body">
                <div class="mark-complete-content inner-form">
                  <div class="row">
                    <div class="col-6 mar-top-15">
                      <input name="textinput" type="text" class="form-control" value="1" />
                    </div>
                    <div class="col-6 mar-top-15">
                      <select name="selectbasic" class="form-control">
                        <option value="1">Week(s)</option>
                        <option value="2">Option two</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mar-top-30">
                    <div class="col-sm-6 col-12 text-center mar-top-15">
                      <input type="button" class="form-control btn " value="Cancel" />
                    </div>
                    <div class="col-sm-6 col-12 text-center mar-top-15">
                      <input type="button" class="form-control btn black-btn" value="Submit" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LaderProgress;
