import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate, { MobileFormatter, MobileNormalize } from '../validation/validate';
import renderField from '../reduxFormComponent/renderField';
import {
  ReduxSelect,
  ReduxInput,
  ReduxToggle,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { listCombos } from '../../../store/actions';

const HealthInsurance = ({
  fields,
  name,
  meta: { touched, error },
  HealthInsuranceType,
  StateProvince,
  Country,
  disable,
  dispatch,
  Filter_State,
  existingRows,
}) => {
  // const countryChange = (countryISO, currentIndex) => {
  //   if (countryISO) {
  //     dispatch(
  //       listCombos({
  //         comboTypes: 'StateProvince',
  //         CountryISO: countryISO,
  //       }),
  //     );
  //   }
  // };
  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Health Insurance {index + 1}</h6>
              </div>
              {fields.length > 1 && (
                <div class={`col-12 col-md-4`}>
                  <a class="delete-contact" disabled={disable} onClick={() => fields.remove(index)}>
                    <i class="fas fa-trash" /> Delete Health Insurance Source
                  </a>
                </div>
              )}
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.insuranceCompany`}
                    component={ReduxInput}
                    validate={validate.required}
                    fieldName={'Provider'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Provider*</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.policyNumber`}
                    component={ReduxInput}
                    validate={validate.required}
                    fieldName={'ID'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">ID*</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.groupNumber`}
                    component={ReduxInput}
                    // validate={validate.required}
                    fieldName={'Group'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Group</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.healthInsuranceTypeId`}
                    placeholder="Health Insurance Type*"
                    component={ReduxSelect}
                    options={HealthInsuranceType}
                    validate={validate.required}
                    fieldName={'Health Insurance Type'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field name={`${item}.line1`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">Address</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.country`}
                    type="text"
                    errorMessage="Country Required"
                    component={ReduxSelect}
                    options={Country}
                    // validate={Validator.required}
                    // onChange={countryISO => countryChange(countryISO, index)}
                    placeholder="Country"
                    fieldName={'Country'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field name={`${item}.city`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.stateOrProvince`}
                    type="text"
                    component={ReduxSelect}
                    options={Filter_State && Filter_State[existingRows[index].country]}
                    placeholder="State/Province/Territories"
                    fieldName="State/Province/Territories"
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.zip`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Postal/Zip Code</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.phone`}
                    component={ReduxInput}
                    validate={validate.NumericMobile}
                    format={MobileFormatter}
                    normalize={MobileNormalize}
                    fieldName={'Phone'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field name={`${item}.fax`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">Fax</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field name={`${item}.email`} component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">E-mail</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${item}.notes`}
                    class="form-control textarea-box"
                    rows="4"
                    type="textarea"
                    component={ReduxTextarea}
                    style={{
                      minHeight: '100px',
                    }}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" disabled={disable} onClick={() => fields.push({ country: 'USA' })}>
            {fields.length >= 1 && (
              <label>
                {' '}
                <a>+ Add Another Insurance Source</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                <a> + Add Insurance Source</a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

const HealthInsuranceUI = props => {
  const {
    handleSubmit,
    name,
    pristine,
    submitting,
    onLastSubmit,
    handleNext,
    HealthInsuranceType,
    StateProvince,
    CountryISO,
    style,
    isHealthInsurance,
    goToPreviuos,
    disable,
    Filter_State,
    existingRows,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Do you have Health Insurance?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isHealthInsurance" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      {isHealthInsurance && (
        <FieldArray
          name="healthInsurances"
          component={HealthInsurance}
          HealthInsuranceType={HealthInsuranceType}
          Filter_State={Filter_State}
          existingRows={existingRows}
          Country={CountryISO}
          disable={disable}
          dispatch={props.dispatch}
        />
      )}
      <div className="row">
        <div class="col-12 col-md-6 col-lg-8">
          <div class="form-group">
            <Field
              name={`healthInsurancesNotes`}
              class="form-control textarea-box"
              rows="4"
              type="textarea"
              component={ReduxTextarea}
              validate={!isHealthInsurance && validate.required}
              fieldName={'Notes'}
              style={{
                minHeight: '100px',
              }}
              disabled={disable}
            />
            <label class="placeholder-label">{isHealthInsurance ? 'Notes' : 'Notes*'}</label>
          </div>
        </div>
      </div>
      <div class="row pagination-button">
        <div class="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              class="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  const isHealthInsurance = selector(state, 'isHealthInsurance');
  const existingRows = selector(state, `healthInsurances`);
  return {
    Filter_State: combos.data.Filter_StateProvince,
    HealthInsuranceType: combos.data.HealthInsuranceType,
    CountryISO: combos.data.Country,
    isHealthInsurance,
    existingRows,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(HealthInsuranceUI),
);

const selector = formValueSelector('wizard');
