import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import {
  getFormFieldMetaData,
  upsertFormData,
  formDataGet,
  appInvite,
  adminstrationStaffUpsert,
} from '../store/actions';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../core/generic-form';
import { StaffMobileAppDetails } from '../app-config/screenName';
import { ReduxToggle } from '../component/custom-redux-components';
// import Loader, { Loader2 } from '../../src/Components/Loader';
import CustomModal from '../core/modal';

class MobileAppDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      showMobileAppUserForm: props.IsProvider,
      inviteData: {},
    };
  }

  sendFormData = values => {
    let valueArray = Object.keys(values);
    const obj = {};
    for (const key of valueArray) {
      if (values[key] instanceof Date) {
        obj[key] = moment(values[key]).format('MM/DD/YYYY');
      } else if (values[key] != null && typeof values[key] == 'object') {
        obj[key] = values[key].map(item => item.Value).toString();
      } else {
        obj[key] = values[key];
      }
    }
    let jsonData = {
      Staff: [
        {
          StaffId: this.props.getStaffId,
          IsProvider: true,
          IsFinalSubmit: true,
          Misc: [
            {
              ...obj,
            },
          ],
        },
      ],
    };
    let staffData = {
      staffId: this.props.getStaffId,
      email: this.props.EmailWork,
      jsonInput: JSON.stringify(jsonData),
    };
    this.props.dispatch(adminstrationStaffUpsert(staffData));
  };
  componentDidMount() {
    let jsonData = {
      DynamicForm: [
        {
          FormName: StaffMobileAppDetails,
        },
      ],
    };
    this.props.dispatch(
      getFormFieldMetaData({
        Json: JSON.stringify(jsonData),
      }),
    );
  }
  componentWillReceiveProps({
    genericFormData,
    comboData,
    IsProviderValue,
    isUpsertSuccess,
    IsProvider,
    upsertSucessMsg,
  }) {
    if (isUpsertSuccess && isUpsertSuccess != this.props.isUpsertSuccess) {
      this.toggle(upsertSucessMsg);
    }
    if (IsProviderValue != this.props.IsProviderValue) {
      this.setState({ showMobileAppUserForm: IsProviderValue });
    }
    if (IsProvider != this.props.IsProvider) {
      this.setState({ showMobileAppUserForm: IsProvider });
    }
    if (
      genericFormData &&
      JSON.stringify(genericFormData) != JSON.stringify(this.props.genericFormData)
    ) {
      let validationConversion = genericFormData;

      this.setState({ formFields: validationConversion });
      if (this.props.getStaffId) {
        let jsonData2 = {
          DynamicForm: [
            {
              FormName: 'Staff',
              FormDataId: this.props.getStaffId,
            },
          ],
        };
        this.props.dispatch(
          formDataGet({
            Json: JSON.stringify(jsonData2),
          }),
        );
      }
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const { formFields, optionsList, inviteData } = this.state;
    const { handleSubmit, comboData, onCancel, inviteDetails, isDeactivated } = this.props;
    const { InviteMsg, IsInviteSent, MobileAppInviteMsg, SucessMsg } = inviteData;

    return (
      <div className="main enrollment-page  mb-5">
        <div className="container-fluid">
          <div className="row">
            <form
              onSubmit={handleSubmit(values => {
                this.sendFormData(values);
              })}
            >
              <div class="row top-heading-row mt-2 mb-3 align-items-center">
                {/* <div class="col-12 col-md-4 col-lg-3">
                  <h3 class="form-main-head mb-3 mb-md-0">
                    {this.props.getStaffId > 0 ? 'Edit Staff' : 'Add a Staff'}
                  </h3>
                </div> */}
              </div>

              <div className="">
                <div className="enrollment-form pt-2 pb-2">
                  <div className="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="custom-control-label">
                          <Field
                            name={`IsProvider`}
                            type="text"
                            class="custom-control-input not-empty"
                            component={ReduxToggle}
                            disabled={true}
                          />
                          {'   '}
                          Mobile platform user?
                        </label>
                      </div>
                    </div>
                  </div>

                  {this.state.showMobileAppUserForm && (
                    <div className="row pb-5 pl-3 pr-3 pt-3">
                      <div className="col-md-12 staff-form">
                        <div className="row">
                          {/* <Loader2 loading={this.props.isFetchingStaff} /> */}

                          <DynamicForm formFields={formFields} disable={isDeactivated} />
                        </div>
                      </div>
                      <div class="col-12 text-right top-heading-row align-items-center">
                        <input
                          type="submit"
                          class="btn"
                          value="Submit"
                          // disabled={IsDeActivated}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { combos, get_form_field_meta_data, staff_upsert, app_invite, form_data_get } = state;
  const { data: getGenericFormFields } = get_form_field_meta_data;
  const { data: upsertData, isFetching: isFetchingStaff } = staff_upsert;
  const { data: InviteData, error, isFetching } = app_invite;
  const { data } = form_data_get;

  const IsProviderValue = formValueSelector('MobileAppDetailsForm')(state, 'IsProvider');
  const mobileAppRoleValue = formValueSelector('MobileAppDetailsForm')(state, 'MobileAppRole');
  const LevelOfCaresSelector = formValueSelector('financial');
  let comboData = {};
  let genericFormData = [];
  let isUpsertSuccess;
  let isAppInviteSent;
  let inviteDetails;
  let preInviteDetails = {};
  let upsertSucessMsg;
  if (combos && combos.data) {
    comboData = combos.data || {};
  }
  if (getGenericFormFields) {
    genericFormData = getGenericFormFields.FormFieldMetaData;
  }
  if (upsertData && upsertData.success) {
    upsertSucessMsg = JSON.parse(upsertData.data).Staff[0].InviteDetails[0].SucessMsg;
    isUpsertSuccess = upsertData.success;
  }
  if (InviteData && InviteData.success) {
    isAppInviteSent = InviteData.success;
    inviteDetails = JSON.parse(InviteData.data).Staff[0].InviteDetails[0];
  }
  if (data && data.Staff[0]) {
    preInviteDetails = data.Staff[0].InviteDetails[0];
  }
  return {
    genericFormData,
    comboData,
    IsProviderValue,
    isUpsertSuccess,
    mobileAppRoleValue,
    isAppInviteSent,
    inviteDetails,
    isFetching,
    preInviteDetails,
    upsertSucessMsg,
    isFetchingStaff,
  };
};
const ReduxMobileAppDetailsForm = reduxForm({
  form: 'MobileAppDetailsForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(MobileAppDetailsForm)));

class MobileAppDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      initialValues: {
        IsProvider: true,
        MobileAppRole: 'P',
      },
    };
  }
  componentDidMount() {
    let jsonData2 = {
      DynamicForm: [
        {
          FormName: 'Staff',
          FormDataId: this.props.UserId,
        },
      ],
    };
    this.props.dispatch(
      formDataGet({
        Json: JSON.stringify(jsonData2),
      }),
    );
  }
  componentWillReceiveProps({ staffForm, IsFinalSubmit }) {
    if (staffForm && JSON.stringify(staffForm) != JSON.stringify(this.props.staffForm)) {
      if (staffForm.IsProvider) {
        this.setState({
          staffForm: staffForm,
          initialValues: {
            IsProvider: staffForm.IsProvider,
            ...staffForm.Misc[0],
          },
        });
      } else {
        this.setState({
          staffForm: staffForm,
          initialValues: {
            IsProvider: staffForm.IsProvider,
          },
        });
      }
    }
  }
  render() {
    return (
      <>
        <ReduxMobileAppDetailsForm
          initialValues={this.state.initialValues}
          getStaffId={this.props.UserId}
          //   onCancel={() => this.props.history.push('/manage/staff/staff-list')}
          IsProvider={this.props.IsProvider}
          EmailWork={this.props.EmailWork}
        />
      </>
    );
  }
}
const mapStateToParent = state => {
  const { form_data_get, default_values } = state;
  const { data, isFetching, error } = form_data_get;
  let inviteDetails = {};
  let EmailWork;
  let isDeactivated;
  let IsFinalSubmit = false;
  let staffForm;
  let IsProvider = false;
  if (data && data.Staff[0]) {
    staffForm = data.Staff[0];
    IsProvider = data.Staff[0].IsProvider;
    EmailWork = data.Staff[0].EmailWork;
    isDeactivated = data.Staff[0].IsDeactivated;
    inviteDetails = data.Staff[0].InviteDetails[0];
    IsFinalSubmit = data.Staff[0].IsFinalSubmit;
  }

  return {
    isFetching,
    staffForm,
    IsProvider,
    inviteDetails,
    EmailWork,
    isDeactivated,
    IsFinalSubmit,
  };
};
export default connect(mapStateToParent)(withRouter(MobileAppDetails));
