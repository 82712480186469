import React, { Component, useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { connect } from 'react-redux';
import {
  startFreeLicense,
  userFeatureFlagGet,
  licenseNotifyCenterAdmin,
  resetLicenseNotifyCenterAdmin,
} from '../../../../store/actions';
import { HTMLContent } from '../commonSection';
import CustomModal from '../../../../core/modal';
import AugmentAIDrawerContent from './augmentAIDrawerContent';

const AugmentedAI = props => {
  const { StatusData, dispatch, isLicenseNotify, showIconOnly } = props;
  // console.log(showIconOnly, 'showIconOnly');
  const [open, setOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  useEffect(() => {
    if (isLicenseNotify) {
      setIsModelOpen(true);
    }
  }, [isLicenseNotify]);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ backgroundColor: '#FC4D4D', borderRadius: '12px', padding: '2px' }}
      >
        <div className="d-flex align-items-center">
          <img src={`images/license/hour_glass.svg`} width="86" height="79" alt="" />
          <div style={{ fontSize: '19px', fontWeight: '600', color: '#fff' }}>
            {' '}
            Uh-Oh! Your Trial has ended.
            <div style={{ fontSize: '16px', fontWeight: '600', color: '#fff' }}>
              Purchase license to enjoy hassle-free usage.
            </div>
            {!props.canRoleByLicense && (
              <div style={{ fontSize: '16px', fontWeight: '600', color: '#fff' }}>
                Please contact your admin to purchase license.
              </div>
            )}
          </div>
        </div>

        {props.canRoleByLicense && (
          <>
            <button
              title="Augment with AI"
              class="btn mx-2"
              onClick={showDrawer}
              style={{
                display: 'inline',
                padding: '5px 20px',
                color: '#0070E0',
                background: '#fff',
                boxShadow: '0px 9px 12px rgba(0, 0, 0, 0.0467384)',
              }}
            >
              {/* <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                <img src="images/AIStar.svg" alt="" width="23" />
              </figure> */}
              {/* Purchase AI License */}
              Contact a SoberPeer Representative
            </button>
          </>
        )}
      </div>

      <div className="ai-licence-drawer">
        <AugmentAIDrawerContent open={open} HTMLContent={HTMLContent} onClose={onClose} />
      </div>
      <CustomModal
        isOpen={isModelOpen}
        headerTop={
          '<div class="col-12 mb-3 text-center"><div style="color:#2FE192;font-size:36px"><i class="fas fa-check-circle"></i></div>&nbsp;<div><p style="font-size:26px;font-weight:700">Successfullyy Notified!</p><p style="color:#828282">A SoberPeer representative has been successfully notified of your interest in purchasing license for this feature. We will reach out to you very soon.</p></div></div>'
        }
        yesAction={() => {
          dispatch(resetLicenseNotifyCenterAdmin({}));
          toggle();
          onClose();
        }}
        yesText="OK"
        hideCancel={true}
      />
    </>
  );
};

const mapStateToProps = ({ default_values, license_notify_center_admin }) => {
  var canRoleByLicense = false;
  var isLicenseNotify = false;
  if (default_values && default_values.data) {
    canRoleByLicense = default_values.data && default_values.data.IsPurchaseEligible;
  }
  if (
    license_notify_center_admin &&
    license_notify_center_admin.data &&
    license_notify_center_admin.data.success
  ) {
    isLicenseNotify = license_notify_center_admin.data.success;
  }
  return {
    canRoleByLicense,
    isLicenseNotify,
  };
};

export default connect(mapStateToProps)(AugmentedAI);
