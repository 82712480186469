import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../../validation/validate';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { mapTime } from '../../../../../util/time';
import SearchPatient from '../../../../../Components/searchPatient';
import { withRouter } from 'react-router-dom';
import {
  ReduxInput,
  ReduxCheckbox,
  ReduxSelect,
  ReduxDateInput,
  ReduxTimePicker,
  ReduxToggle,
  ReduxTextarea,
} from '../../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import {
  practitionerCombos,
  upsertClientVital,
  getClientVital,
} from '../../../../../store/actions';

class VitalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
    };
  }
  componentDidMount() {
    this.props.dispatch(practitionerCombos({}));
    const data = {
      ClientVital: [
        {
          ClientVitalId: this.props.ClientVitalId,
        },
      ],
    };

    this.props.dispatch(
      getClientVital({
        Json: JSON.stringify(data),
      }),
    );
  }
  setSearchClientValues = data => {
    this.props.updateInitValuesForExistingPaitents(data);
  };

  getVitalFormValues = values => {
    const {
      clientId,
      counsultingCounselor,
      bloodPressure,
      bloodPressureReading,
      temperature,
      temperatureReading,
      pulse,
      pulseReading,
      respiration,
      respirationReading,
      glucose,
      glucoseReading,
      glucoseDropdownReading,
      weight,
      weightReading,
      oxygenSaturation,
      oxygenSaturationReading,
      bmi,
      bmiReading,
      testDate,
      testTime,
      notes,
      intervention,
      glucoseReadingType,
    } = values;
    const data = {
      ClientVital: [
        {
          ClientVitalId: this.props.ClientVitalId,
          ClientId: this.props.ClientId,
          CounselorId: counsultingCounselor,
          BloodPressure: bloodPressureReading,
          Temperature: temperatureReading,
          Pulse: pulseReading,
          Respiration: respirationReading,
          Glucose: glucoseReading,
          GlucoseReadingType: glucoseReadingType,
          OxygenSaturation: oxygenSaturationReading,
          BMI: bmiReading,
          TestDate: moment(testDate).format('MM/DD/YYYY'),
          TestTime: testTime,
          Intervention: intervention,
          Notes: notes,
          Weight: weightReading,
        },
      ],
    };

    var jsonData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(upsertClientVital(jsonData));
    this.props.dispatch(reset('vitalForm'));
  };

  // componentWillReceiveProps({ isUpsert }) {}

  render() {
    const {
      handleSubmit,
      onCancel,
      ClientVitalId,
      Practitioner,
      TimeSlot,
      GlucoseReadingType,
    } = this.props;
    return (
      <React.Fragment>
        <div className="container mt-5 enrollment-form">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
              ×
            </button>
            <h4 class="modal-title col-12 text-left">
              {ClientVitalId ? 'Update Vital Details' : 'Add Vital Details'}
            </h4>
          </div>
          <div class="modal-body rcm-panel enrollment-form">
            <form onSubmit={handleSubmit(this.getVitalFormValues)}>
              <div class="row mb-4">
                {/* <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`clientName`}
                      component={ReduxInput}
                      // validate={validate.required}
                      fieldName={' Client Name'}
                      disabled
                    />
                    <label class="placeholder-label">Client Name</label>
                  </div>
                </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="counsultingCounselor"
                      type="text"
                      component={ReduxSelect}
                      options={Practitioner}
                      placeholder="Select Counselor"
                      fieldName={'Counsulting Counselor'}
                    />
                  </div>
                </div>
                {/* <div class="d-flex col-6 col-md-6 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="bloodPressure"
                        component={ReduxCheckbox}
                        fieldName={'Blood Pressure'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    Blood Pressure
                    </label>
                  </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="bloodPressureReading"
                      type="text"
                      component={ReduxInput}
                      // placeholder = "Enter Reading"
                      fieldName={'Blood Pressure Reading'}
                    />
                    <label class="placeholder-label">Blood Pressure</label>
                  </div>
                </div>
                {/* </div> */}
                {/* <div class="d-flex col-12 col-md-12 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="temperature"
                        component={ReduxCheckbox}
                        fieldName={'Temperature'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    Temperature
                    </label>
                        </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="temperatureReading"
                      type="number"
                      component={ReduxInput}
                      // placeholder = "Enter Reading"
                      fieldName={'Temperature Reading'}
                      min={1}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">Temperature (degrees)</label>
                  </div>
                </div>
                {/* </div> */}
                {/* <div class="d-flex col-12 col-md-12 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="pulse"
                        component={ReduxCheckbox}
                        fieldName={'Pulse'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    Pulse
                    </label>
                        </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="pulseReading"
                      type="number"
                      component={ReduxInput}
                      // placeholder = "Enter Reading"
                      fieldName={'Pulse Reading'}
                      min={1}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">Pulse</label>
                  </div>
                </div>
                {/* </div> */}
                {/* <div class="d-flex col-12 col-md-12 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="respiration"
                        component={ReduxCheckbox}
                        fieldName={'Respiration'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    Respiration
                    </label>
                        </div> */}

                {/* </div>
                <div class="d-flex col-12 col-md-12 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="glucose"
                        component={ReduxCheckbox}
                        fieldName={'Glucose'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    Glucose
                    </label>
                        </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="glucoseReading"
                      type="number"
                      component={ReduxInput}
                      // placeholder = "Enter Reading"
                      fieldName={'Glucose Reading'}
                      min={1}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">Glucose</label>
                  </div>
                </div>
                {/* </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="glucoseReadingType"
                      type="text"
                      component={ReduxSelect}
                      options={GlucoseReadingType}
                      placeholder="Select Glucose Reading"
                      fieldName={'Glucose Reading'}
                    />
                  </div>
                </div>
                {/* <div class="d-flex col-12 col-md-12 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="weight"
                        component={ReduxCheckbox}
                        fieldName={'Weight'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    Weight
                    </label>
                        </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="respirationReading"
                      type="number"
                      component={ReduxInput}
                      // placeholder = "Enter Reading"
                      fieldName={'Respiration Reading'}
                      min={1}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">Respiration</label>
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="weightReading"
                      type="number"
                      component={ReduxInput}
                      // placeholder = "Enter Reading"
                      fieldName={'Weight Reading'}
                      min={1}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">Weight (lb(s))</label>
                  </div>
                </div>
                {/* </div>
                <div class="d-flex col-12 col-md-12 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="oxygenSaturation"
                        component={ReduxCheckbox}
                        fieldName={'Oxygen Saturation'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    Oxygen Saturation
                    </label>
                        </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="oxygenSaturationReading"
                      type="number"
                      component={ReduxInput}
                      // placeholder = "Enter Reading"
                      fieldName={'Oxygen Saturation Reading'}
                      min={1}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">Oxygen Saturation</label>
                  </div>
                </div>
                {/* </div>
                <div class="d-flex col-12 col-md-12 col-lg-6"> */}
                {/* <div class="col-4 custom-redux-checkbox">
                    <Field
                        className="mr-2"
                        name="bmi"
                        component={ReduxCheckbox}
                        fieldName={'BMI'}
                    />
                    <label class="placeholder-label" style={{ top: '0px' }}>
                    BMI
                    </label>
                        </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="bmiReading"
                      type="number"
                      component={ReduxInput}
                      fieldName={'BMI Reading'}
                      min={1}
                      negativeNumberBlocked={true}
                    />
                    <label class="placeholder-label">BMI</label>
                  </div>
                </div>
                {/* </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="testDate"
                      type="text"
                      component={ReduxDateInput}
                      errorMessage="Test Date Required"
                      label={'Test Date'}
                    />
                    <i class="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`testTime`}
                      type="text"
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'Time Slot'}
                      options={TimeSlot}
                      placeholder="Please Select Time Slot"
                      // isMulti={true}
                    />
                  </div>
                </div>

                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="intervention"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Intervention'}
                    />
                    <label class="placeholder-label">Add Intervention</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <Field
                      name="notes"
                      type="textarea"
                      component={ReduxTextarea}
                      fieldName={'Note'}
                      showPlaceHolder={true}
                    />
                    <label class="placeholder-label">Add Note</label>
                  </div>
                </div>
                <div class="col-12 text-right">
                  <input type="submit" class="btn px-5" value={'Submit'} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { combos, practitioner_combos, get_client_vital } = state;
  const { data } = get_client_vital;
  const { data: practitionerData } = practitioner_combos;

  let GlucoseReadingType = [];
  let Practitioner = [];
  let TimeSlot = [];
  if (combos.data && combos.data['GlucoseReadingType']) {
    GlucoseReadingType = combos.data.GlucoseReadingType;
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner = practitionerData.PractitionerList || [];
  }
  if (data && data.TimeSlotCombo) {
    TimeSlot = data.TimeSlotCombo;
  }

  return {
    Practitioner: Practitioner,
    GlucoseReadingType,
    TimeSlot,
  };
};

const ReduxVitalForm = reduxForm({
  form: 'vitalForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(VitalForm)));

export default ReduxVitalForm;
