import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import {
  downloadStudentDocument,
  downloadClientDocument,
  resetDownloadClientDocument,
} from '../../../store/actions';

const DocViewerComponent = ({ downloadUrl, documentExtension }) => {
  const docs = [
    {
      uri: downloadUrl,
      fileType: documentExtension,
    },
  ];
  return (
    <>
      {downloadUrl && (
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          className=""
          style={{ height: '100vh' }}
          theme={{
            primary: '#5296d8',
            secondary: '#ffffff',
            tertiary: '#5296d899',
            text_primary: '#ffffff',
            text_secondary: '#5296d8',
            text_tertiary: '#00000099',
            disableThemeScrollbar: false,
          }}
          config={{
            header: {
              disableHeader: false,
              // disableFileName: false,
              retainURLParams: false,
            },
          }}
        />
      )}
    </>
  );
};
class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps({ downloadUrl, isDownloadClicked }) {
    if (isDownloadClicked && downloadUrl && downloadUrl != this.props.downloadUrl) {
      window.open(downloadUrl);
      this.props.dispatch(resetDownloadClientDocument());
    }
  }

  DocumentDownload = () => {
    let {
      EventJsonData: { Document },
    } = this.props;
    let {
      ClientId,
      StudentId,
      DocumentGuid,
      ClientDocumentGuid,
      StudentDocumentGuid,
      DocumentExtension,
      Extension,
      IsClinical,
    } = Document && Document[0];

    if (IsClinical) {
      this.props.dispatch(
        downloadClientDocument({
          json: JSON.stringify({
            ClientDocument: [
              {
                ClientId: ClientId,
                DocumentGuid: DocumentGuid,
              },
            ],
          }),
        }),
      );
    }
    // else {
    //   this.props.dispatch(
    //     downloadStudentDocument({
    //       json: JSON.stringify({
    //         StudentDocument: [
    //           {
    //             StudentId: StudentId,
    //             StudentDocumentGuid: StudentDocumentGuid,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  };
  componentDidMount() {
    this.DocumentDownload();
  }
  render() {
    let {
      EventJsonData: { Document },
      downloadUrl,
    } = this.props;
    let {
      ClientId,
      StudentId,
      DocumentGuid,
      ClientDocumentGuid,
      StudentDocumentGuid,
      DocumentExtension,
      Extension,
      IsClinical,
    } = Document && Document[0];
    return (
      <div class="border-0">
        <span
          target="_blank"
          className="text-right"
          onClick={() => {
            if (IsClinical) {
              this.props.dispatch(
                downloadClientDocument({
                  json: JSON.stringify({
                    ClientDocument: [
                      {
                        ClientId: ClientId,
                        DocumentGuid: DocumentGuid,
                      },
                    ],
                  }),
                  IsDownloadClicked: true,
                }),
              );
            } else {
              this.props.dispatch(
                downloadStudentDocument({
                  json: JSON.stringify({
                    StudentDocument: [
                      {
                        StudentId: StudentId,
                        StudentDocumentGuid: StudentDocumentGuid,
                      },
                    ],
                  }),
                  IsDownloadClicked: true,
                }),
              );
            }
          }}
          style={{ color: '#46B4FE', fontSize: '14px', cursor: 'pointer' }}
          download
        >
          <i class="fas fa-file-download"></i> Download
        </span>
        <DocViewerComponent
          downloadUrl={downloadUrl}
          documentExtension={Extension || DocumentExtension}
        />

        {/* <div className="m-0 view-document-modal">
          {downloadUrl && (
            // <div className="">
            //   <DocViewerComponent file={downloadUrl} type={DocumentExtension} />
            // </div>
            <DocViewer
              documents={docs}
              pluginRenderers={DocViewerRenderers}
              className=""
              style={{ height: '100vh' }}
              theme={{
                primary: '#5296d8',
                secondary: '#ffffff',
                tertiary: '#5296d899',
                text_primary: '#ffffff',
                text_secondary: '#5296d8',
                text_tertiary: '#00000099',
                disableThemeScrollbar: false,
              }}
              config={{
                header: {
                  disableHeader: false,
                  // disableFileName: false,
                  retainURLParams: false,
                },
              }}
            />
          )}
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = ({ download_student_document, download_client_document }) => {
  const {
    data: DownloadData,
    isFetching: isFetchingDownload,
    error: errorDownload,
  } = download_student_document;
  const {
    data: ClientData,
    isFetching: isFetchingClient,
    error: errorClient,
  } = download_client_document;
  let downloadUrl;
  let documentError;
  let isDownloadClicked;

  if (DownloadData && DownloadData.success) {
    downloadUrl = DownloadData.result;
    isDownloadClicked = DownloadData.IsDownloadClicked;
  }
  if (ClientData && ClientData.success) {
    downloadUrl = ClientData.result;
    isDownloadClicked = ClientData.IsDownloadClicked;
  } else if (errorClient) {
    documentError = errorClient;
  }
  return {
    downloadUrl,
    documentError,
    isDownloadClicked,
  };
};

export default connect(mapStateToProps)(Document);
