import React, { Component } from 'react';
import { ReduxSelect } from '../../component/custom-redux-components';

const DropdownFilter = props => {
  const { item, onFilterChange, onFilter } = props;
  return (
    <div className="text-left filter-dropdown d-inline-block">
      <div className="form-group call-schedule  grid-filter mr-3 mb-0" style={{ width: '230px' }}>
        <ReduxSelect
          options={item.FilterByOptions || []}
          onChange={val => {
            onFilterChange({
              [item.KeyName]: val,
            });
          }}
          className="basic-single"
          classNamePrefix="select"
          value={onFilter[item.KeyName]}
          //   value={this.state.onFilter[item.KeyName]}
          placeholder={item.AdditionalFieldName}
        />
      </div>
    </div>
  );
};

export { DropdownFilter };
