import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  resetChatGet,
  youuniverseConversationGet,
  youuniverseChatGet,
  resetYouuniverseChatGet,
  youuniverseChatPost,
  resetYouuniverseChatPost,
  youuniverseBookMarkQuestionUpsert,
  resetYouuniverseBookMarkQuestionUpsert,
  youuniverseBookMarkQuestionListGet,
  youuniverseChatAttributeUpdate,
  resetYouuniverseChatAttributeUpdate,
  resetAskFollowUpInitiate,
  youuniverseChatSet,
} from '../../store/actions';
import SpeechToText from '../../Components/YouuSpeechToText';

const YOUUniverseHomePage = props => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [isFinal, setIsFinal] = useState(false);
  // const [showChatPage, setShowChatPage] = useState(false);

  const { bookmarkedDataList, askQuestionResponse, isFetching } = useSelector(
    ({
      youuniverse_bookmark_question_list_get: { data: bookmarkedData },
      youuniverse_chat_post: { data: askQuestionData, isFetching, error },
    }) => {
      return {
        bookmarkedDataList: bookmarkedData && bookmarkedData.data,
        askQuestionResponse: askQuestionData,
        isFetching: isFetching,
      };
    },
    shallowEqual,
  );

  const handleDictate = (result, isFinal) => {
    if (result) {
      let data;
      if (message) {
        data = `${message} ${result}`;
      } else {
        data = result;
      }
      setMessage(data);
    }
    if (isFinal == 'true') {
      setIsFinal(true);
    }
  };

  useEffect(() => {
    if (isFinal) {
      let e = {
        type: 'click',
      };
      assignMessage(e);
      setIsFinal(false);
    }
  }, [isFinal]);

  const assignMessage = e => {
    if (
      (e.keyCode === 13 && message.trim().length) ||
      (e.type === 'click' && message.trim().length)
    ) {
      props.ShowHomepage(true);
      sessionStorage.setItem('homePageMsg', message);
    }
  };

  useEffect(() => {
    dispatch(youuniverseBookMarkQuestionListGet({}));
  }, []);

  return (
    <div className="youu-homepage" style={{ height: '40%' }}>
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <img style={{ height: '100px' }} src="images/chatSP.svg" />
      </div>
      <div className="ask-me-anything chatbot">
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="d-flex align-items-center"
            style={{
              backgroundColor: '#F2F2F2',
              position: 'relative',
              borderRadius: '30px',
              maxWidth: '990px',
              minWidth: '85%',
            }}
          >
            {/* <input className="chat-input col-11" placeholder="Type Here..." type="text" /> */}
            <input
              className="chat-input col-11"
              placeholder="Type Here..."
              type="text"
              value={message}
              onChange={e => {
                setMessage(e.target.value);
              }}
              onKeyDown={assignMessage}
              // disabled={loader}
            />
            <span className="d-flex align-items-center justify-content-end">
              <SpeechToText handleDictate={handleDictate} />
            </span>
          </div>
          <span className="youu-chat-submit btn" onClick={e => assignMessage(e)}>
            <img src="images/icons/send-bookmark.svg" />
          </span>
        </div>
      </div>
      <div className="bookmarked-questions">
        <span className="bookmark-title">Bookmarked Questions</span>
        <ul className="bookmark-list">
          {bookmarkedDataList &&
            bookmarkedDataList.map((bookmark, index) => {
              if (bookmark.IsQueryBookmarked) {
                return (
                  <li>
                    <div
                      className="bookmark-list-item"
                      onClick={e => {
                        props.ShowHomepage(true);
                        sessionStorage.setItem('homePageMsg', bookmark.UserQuery);
                      }}
                    >
                      <div className="query">
                        {/* <img src="images/icons/chat-bookmark.svg" /> */}
                        <img src="images/icons/chat-bookmarked.svg" />
                        <span className="text"> {bookmark.UserQuery}</span>
                      </div>
                      <img src="images/icons/bookmark-arrow.svg" />
                    </div>
                  </li>
                );
              } else {
                return null;
              }
            })}
        </ul>
      </div>
    </div>
  );
};
export default YOUUniverseHomePage;
