import React from 'react';
import { reduxForm } from 'redux-form';
// import bedData from '../../../../data/bedData';
import ReactRangePicker from '../../../../component/Range-Picker';
import { bedAssignmentGetAll, upcomingDischargesGetAll } from '../../../../store/actions';
import { DatePicker, Drawer, Avatar, Image } from 'antd';
import {
  ReduxInput,
  ReduxCheckbox,
  ReduxSelect,
  ReduxDateInput,
  ReduxTimePicker,
  ReduxToggle,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import RoomComponent from './custom-screen/roomComponent';
import AdmitForm from './custom-screen/admitForm';
import moment from 'moment';
import Loader, { Loader2 } from '../../../../Components/Loader';
import blank_user from '../../../../images/blank-profile.png';
import CustomModal2 from '../../../../core/modal';

const date = new Date();

class BedManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      admissionFormVisible: false,
      dateValue: moment(date).format('YYYY-MM-DD'),
      filterBy: null,
    };
  }

  updateInitValuesForExistingPaitents = data => {
    let ExistingPaitentValue;
    if (data.ClientId) {
      ExistingPaitentValue = true;
    } else {
      ExistingPaitentValue = false;
    }
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        FirstName: data.FirstName,
        LastName: data.LastName,
        Phone: data.PrimaryPhone,
        ExistingPaitent: ExistingPaitentValue,
        ClientId: data.ClientId,
      },
    });
  };
  onChange = dateValue => {
    let jsonData = {
      Json: JSON.stringify({
        BedMangement: [
          {
            Date: dateValue ? moment(dateValue).format('YYYY-MM-DD') : null,
            FilterBy: this.state.filterBy,
          },
        ],
      }),
    };
    this.props.dispatch(bedAssignmentGetAll(jsonData));
    this.setState({
      dateValue: moment(dateValue).format('YYYY-MM-DD'),
    });

    // console.log(date, dateString);
  };
  onFilterChange = val => {
    let jsonData = {
      Json: JSON.stringify({
        BedMangement: [
          {
            Date: this.state.dateValue ? this.state.dateValue : null,
            FilterBy: val,
          },
        ],
      }),
    };
    this.props.dispatch(bedAssignmentGetAll(jsonData));

    this.setState({
      filterBy: val,
    });

    // console.log(date, dateString);
  };
  setAdmissionFormVisiblity = (CenterRehabRoomBedId, roomNumber, bedNumber) => {
    this.setState({
      admissionFormVisible: !this.state.admissionFormVisible,
      initialValues: {
        CenterRehabRoomId: roomNumber,
        CenterRehabRoomBedId,
        bedNumber: bedNumber,
        AdmitDate: this.state.dateValue ? this.state.dateValue : new Date(),
      },
    });
  };

  handleUpComingDischarge = () => {
    // let jsonData = {
    //   Json: JSON.stringify({
    //     BedMangement: [
    //       {
    //         Date: '2021-12-06',
    //       },
    //     ],
    //   }),
    // };
    this.props.dispatch(upcomingDischargesGetAll({}));
    this.setState({
      upcomingDischargeVisible: !this.state.upcomingDischargeVisible,
    });
  };
  getLatest = () => {
    let jsonData = {
      Json: JSON.stringify({
        BedMangement: [
          {
            Date: moment(date).format('YYYY-MM-DD'),
            SearchStr: this.state.SearchStr,
          },
        ],
      }),
    };
    this.props.dispatch(bedAssignmentGetAll(jsonData));
  };
  componentDidMount() {
    this.getLatest();
  }
  onSearchChange = val => {
    let value = val.target.value;
    this.state.SearchStr = value;
    this.getLatest();
  };
  componentWillReceiveProps({ bedAssignSuccess }) {
    if (bedAssignSuccess && this.props.bedAssignSuccess != bedAssignSuccess) {
      this.setState({
        inviteMsg: 'Form submitted successfully',
        isModelOpen: true,
        admissionFormVisible: false,
        initialValues: {},
      });
    }
  }

  render() {
    const { admissionFormVisible, upcomingDischargeVisible } = this.state;
    const { bedData, upcomingData, isFetching } = this.props;
    return (
      <React.Fragment>
        <div className="container bed-management">
          <div className="row mt-5">
            <div className="col-md-5">
              {' '}
              <p
                className="d-flex align-items-center mt-3"
                style={{ fontSize: '20px', fontWeight: '800' }}
              >
                Bed Management{' '}
                <span
                  className="d-flex align-items-center"
                  style={{
                    color: '#009EF7',
                    fontSize: '14px',
                    // textTransform: 'uppercase',
                    fontWeight: 'bold',
                    marginLeft: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.handleUpComingDischarge();
                  }}
                >
                  Upcoming Discharges &nbsp;
                  <img src="images/newTabLink.svg" />
                </span>
              </p>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4 d-flex align-items-center">
                  <div class="search-box d-inline-flex w-100">
                    <div
                      class="form-group has-search w-100 d-flex align-items-center"
                      style={{ marginBottom: '0px' }}
                    >
                      <input
                        type="text"
                        class="form-control 
                   shadow-sm rcm-document-search"
                        placeholder="Search"
                        id="filterbox"
                        onChange={this.onSearchChange}
                        style={{ minHeight: '46px' }}
                      />
                      <button class="btn search-icon " type="button">
                        {' '}
                        <i class="fa fa-search" />{' '}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  {' '}
                  {/* <ReactRangePicker handleChange={this.handleChange} /> */}
                  <DatePicker
                    defaultValue={moment(this.state.dateValue, 'YYYY/MM/DD')}
                    selected={this.state.dateValue}
                    onChange={this.onChange}
                    placeholder="Select Date"
                    dateFormat="YYYY/MM/DD"
                    minDate={new Date()}
                  />
                </div>

                <div className="col-md-4">
                  {' '}
                  <div class="form-group call-schedule">
                    <ReduxSelect
                      options={[
                        { Text: 'All', Value: 0 },
                        { Text: 'Available', Value: 1 },
                        { Text: 'Occupied', Value: 2 },
                        { Text: 'Reserved', Value: 3 },
                        { Text: 'Not-available', Value: 4 },
                      ]}
                      onChange={val => {
                        this.onFilterChange(val);
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      value={this.state.filterBy}
                      placeholder="Filter By"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {bedData &&
            bedData.map((roomData, index) => {
              return (
                <>
                  {' '}
                  <RoomComponent
                    roomData={roomData}
                    setAdmissionFormVisiblity={this.setAdmissionFormVisiblity}
                  />
                </>
              );
            })}
          <Drawer
            className="custom-drawer"
            title="Add Reserve Details"
            placement="right"
            width="600px"
            closable={true}
            onClose={() => {
              this.setAdmissionFormVisiblity();
            }}
            visible={admissionFormVisible}
            className="bed-drawer"
          >
            <AdmitForm
              initialValues={this.state.initialValues}
              updateInitValuesForExistingPaitents={this.updateInitValuesForExistingPaitents}
            />
          </Drawer>
          <Drawer
            className="custom-drawer"
            title="Upcoming Discharges"
            placement="right"
            width="600px"
            closable={true}
            onClose={() => {
              this.handleUpComingDischarge();
            }}
            visible={upcomingDischargeVisible}
          >
            <div className="row">
              <div className="col-md-12">
                <Loader2 loading={isFetching} />
                {upcomingData &&
                  upcomingData.map(item => {
                    const { DayName, DischargeDate, DischargeDateForSort, PatientsData } = item;
                    return (
                      <div>
                        <div1 className="row mt-3">
                          <div className="col-md-2 d-flex flex-column  text-center">
                            {/* <span style={{ color: '#182838', fontSize: '16px', fontWeight: '800' }}>
                              05
                            </span> */}
                            <span
                              style={{
                                backgroundColor: 'rgb(235, 239, 242)',
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                              }}
                            >
                              <span
                                style={{
                                  color: '#182838',
                                  fontWeight: '800',
                                  fontSize: '14px',
                                  paddingTop: '5px',
                                  display: 'inline-block',
                                }}
                              >
                                {moment(DischargeDateForSort).format('Do')}
                              </span>
                              <br />
                              <span style={{ fontSize: '13px' }}>
                                {moment(DischargeDateForSort).format('MMM')}
                              </span>
                            </span>
                          </div>
                          <div className="col-md-10 d-flex flex-column">
                            {' '}
                            <span style={{ color: '#7F8188', fontSize: '14px' }}>
                              {/* Tomorrow, Sunday */}
                              {/* {moment(DischargeDate).format('DD-MMM-YYYY')} */}
                            </span>
                            <span style={{ color: '#182838', fontSize: '16px', fontWeight: '800' }}>
                              <div>
                                <div
                                  className="d-flex align-items-top"
                                  style={{ color: '#7F8188', fontSize: '14px', fontWeight: '400' }}
                                >
                                  {DischargeDate === 'Today' || DischargeDate === 'Tomorrow'
                                    ? `${DischargeDate},`
                                    : null}
                                  &nbsp;
                                  {DayName}
                                </div>
                                <div className="d-flex align-items-top">
                                  {PatientsData.map(item => {
                                    const {
                                      PatientName,
                                      BedNumber,
                                      RoomNumber,
                                      DischargeDate,
                                      ProgramType,
                                      ProfileUrl,
                                    } = item;
                                    return (
                                      <>
                                        <div
                                          className=" d-flex flex-column"
                                          style={{
                                            width: '215px',
                                            // border: '0.5px dashed #d9d9d9',
                                            padding: '5px',
                                            margin: '5px',
                                          }}
                                        >
                                          <div className="row">
                                            <div className="col-md-3  text-right">
                                              <Avatar
                                                src={
                                                  <Image
                                                    src={ProfileUrl || blank_user}
                                                    style={{
                                                      width: '40px',
                                                      height: '40px',
                                                      objectFit: 'cover',
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                            <div className="col-md-9 px-0">
                                              <div>
                                                <span
                                                  style={{ color: '#182838', fontWeight: '600' }}
                                                >
                                                  {PatientName}
                                                </span>
                                                &nbsp;
                                                <span
                                                  style={{
                                                    backgroundColor: '#EBEFF2',
                                                    fontSize: '12px',
                                                    color: '#7F8188',
                                                    padding: '2px 4px',
                                                  }}
                                                >
                                                  {ProgramType}
                                                </span>
                                              </div>
                                              <div>
                                                {' '}
                                                <span
                                                  style={{
                                                    color: '#182838',
                                                    fontWeight: '400',
                                                    fontSize: '12px',
                                                  }}
                                                >{`Room No.${RoomNumber}, Bed No.${BedNumber}`}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>{' '}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </span>
                          </div>
                        </div1>
                      </div>
                    );
                  })}
                {/* <div className="row row mt-3">
                  <div className="col-md-2 d-flex flex-column text-center">
                    <span style={{ color: '#182838', fontSize: '16px', fontWeight: '800' }}>
                      04
                    </span>
                    <span>Dec</span>
                  </div>
                  <div className="col-md-10 d-flex flex-column">
                    {' '}
                    <span style={{ color: '#7F8188', fontSize: '14px' }}>Today, Saturday</span>
                    <span style={{ color: '#182838', fontSize: '16px', fontWeight: '800' }}>
                      No Discharge
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2 d-flex flex-column  text-center">
                    <span style={{ color: '#182838', fontSize: '16px', fontWeight: '800' }}>
                      05
                    </span>
                    <span>Dec</span>
                  </div>
                  <div className="col-md-10 d-flex flex-column">
                    {' '}
                    <span style={{ color: '#7F8188', fontSize: '14px' }}>Tomorrow, Sunday</span>
                    <span style={{ color: '#182838', fontSize: '16px', fontWeight: '800' }}>
                      <div>
                        <div className="d-flex align-items-top">
                          <span>
                            <Avatar
                              src={
                                <Image
                                  src={
                                    'https://drattach.blob.core.windows.net/dr-blob-container-v1/7b0129b7-5b6b-4d91-b294-816ebdea5140.jpeg'
                                  }
                                  style={{
                                    width: '32px',
                                    height: '32px',
                                    objectFit: 'cover',
                                  }}
                                />
                              }
                            />
                            &nbsp;
                          </span>
                          <div className=" d-flex flex-column">
                            <span style={{ color: '#182838', fontWeight: '600' }}>
                              Vishnu Goyal
                            </span>
                            <span>
                              <span
                                style={{
                                  backgroundColor: '#EBEFF2',
                                  fontSize: '12px',
                                  color: '#7F8188',
                                  padding: '2px 4px',
                                }}
                              >
                                DETOX
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div> */}
              </div>
              <div className="col-md-12"></div>
            </div>
          </Drawer>
          <CustomModal2
            isOpen={this.state.isModelOpen}
            header={this.state.inviteMsg}
            yesAction={() => {
              this.setState({
                isModelOpen: false,
                inviteMsg: '',
              });
              this.getLatest();
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  bed_assignment_get_all,
  bed_assignment_upsert,
  upcoming_discharges_get_all,
}) => {
  const { data } = bed_assignment_get_all;
  const { data: successData } = bed_assignment_upsert;
  const { data: upcomingDischargesData, isFetching } = upcoming_discharges_get_all;
  let bedData = [];
  let upcomingData = [];
  let bedAssignSuccess;
  if (data) {
    bedData = data.BedManagement;
  }
  if (upcomingDischargesData) {
    upcomingData = upcomingDischargesData.BedMangement;
  }
  if (successData && successData.success) {
    bedAssignSuccess = successData.success;
  }
  return {
    bedData,
    bedAssignSuccess,
    upcomingData,
    isFetching,
  };
};

const ReduxBedManagementForm = reduxForm({
  form: 'bedManagementForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(BedManagement));

export default ReduxBedManagementForm;
