import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Checkbox } from 'antd';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
  CheckboxGroup,
  ReduxTextarea,
  ReduxTextareaWithCounter,
  CheckboxGroupTest4,
} from '../../../component/custom-redux-components';
const validator = {
  required: value => (value ? undefined : 'Required'),
  usaZip: value => (value && !/^[0-9]{5}$/.test(value) ? 'code is invalid' : undefined),
  zip: value => (value && !/^[a-zA-Z0-9_ ]{6,7}$/.test(value) ? 'code is invalid' : undefined),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
};
class AddNewAssociation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMembers: [],
      selectedMembersList: {},
      relation: {},
      isAllvalidRecord: true,
      isValidOneRecord: false,
    };
  }
  handleChange(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      let newMember = e.target.value && JSON.parse(e.target.value);
      this.setState({ selectedMembers: [...this.state.selectedMembers, newMember] });
    } else {
      let remainingUsers =
        this.state.selectedMembers &&
        this.state.selectedMembers.filter(item => item.UserId != JSON.parse(e.target.value).UserId);
      this.setState({ selectedMembers: [...remainingUsers] });
    }
  }
  handleRelationChange = (value, UserId) => {
    this.setState({
      relation: {
        ...this.state.relation,
        [UserId]: value,
      },
    });
  };
  onSearchChange = control => {
    var value = control.target.value;
    this.state.searchStr = value;
    if (value.length > 0) {
      this.props.onSearch(value);
    } else if (value.length == 0) {
      this.props.onSearch(null);
    }
  };
  scrollToError = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };
  handleRecordChange = props => {
    const { index, isChecked, UserId, RelationShipId, type } = props;
    let selectedMembersList = this.state.selectedMembersList;
    let selectedRecord = {};
    if (selectedMembersList) selectedRecord = selectedMembersList[index];

    if (type === 'checkbox') {
      if (isChecked) {
        selectedMembersList = {
          ...selectedMembersList,
          [index]: { ...selectedRecord, isChecked, UserId },
        };
      } else {
        delete selectedMembersList[index];
      }
    } else {
      selectedMembersList = {
        ...selectedMembersList,
        [index]: { ...selectedRecord, UserId, RelationShipId },
      };
    }
    let isValidOneRecord = Object.values(selectedMembersList).some(
      record => record.RelationShipId && record.isChecked,
    );

    this.setState({
      selectedMembersList,
      isValidOneRecord,
      isAllvalidRecord: true,
    });
  };
  render() {
    const {
      onClose,
      handleSubmit,
      onhandleSubmit,
      relationOptions,
      handleExistingMember,
      memberType,
      supportGroup,
    } = this.props;
    const { relation, selectedMembers, selectedMembersList } = this.state;
    let recordInList = Object.values(selectedMembersList);
    return (
      <div class="container">
        <form
          onSubmit={handleSubmit(values => {
            onhandleSubmit(values);
          })}
        >
          <div class="row mb-4 association-model">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group custom-radio ">
                <label className="mr-5">
                  Invite New Member
                  <Field
                    name="memberType"
                    component={ReduxInput}
                    type="radio"
                    value="new"
                    style={{
                      width: '20px',
                      height: '20px',
                      border: 'solid 1px #bcbcbc',
                      marginRight: '5px',
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label>
                  Explore Existing Users
                  <Field
                    name="memberType"
                    component={ReduxInput}
                    type="radio"
                    value="existing"
                    style={{
                      width: '20px',
                      height: '20px',
                      border: 'solid 1px #bcbcbc',
                      marginRight: '5px',
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            {memberType == 'new' && (
              <div className="col-12">
                <div className="row enrollment-form">
                  <div class="col-6">
                    <div class="form-group">
                      <Field
                        name={'Relation'}
                        type="text"
                        component={ReduxSelect}
                        options={relationOptions}
                        placeholder="Relation to the Client*"
                        validate={validator.required}
                        fieldName={'Relation to the Client'}
                        // disabled={disable}
                      />
                    </div>
                  </div>
                  <div class="col-6"></div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <Field
                        name={`FirstName`}
                        component={ReduxInput}
                        validate={validator.required}
                        fieldName={'First Name'}
                      />
                      <label class="placeholder-label">First Name*</label>
                    </div>
                  </div>
                  <div class="col-12  col-lg-6">
                    <div class="form-group">
                      <Field
                        name={`LastName`}
                        component={ReduxInput}
                        validate={validator.required}
                        fieldName={'Last Name'}
                      />
                      <label class="placeholder-label">Last Name*</label>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <Field
                        name={`Phone`}
                        component={ReduxPhoneInput}
                        validate={validator.required}
                        // validate={[Validator.isValidMobile, Validator.required]}
                        fieldName={'Phone'}
                      />
                      <label class="placeholder-label" style={{ top: '-5px', fontSize: '14px' }}>
                        Phone*
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <Field
                        name={`Email`}
                        component={ReduxInput}
                        validate={[validator.required, validator.email]}
                        fieldName={'Email'}
                      />
                      <label class="placeholder-label">Email*</label>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <Field
                        name={`Address`}
                        component={ReduxInput}
                        validate={validator.required}
                        fieldName={'Address'}
                      />
                      <label class="placeholder-label">
                        Permanent Address Line (Last Known Address)*
                      </label>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <Field
                        name={`ZipCode`}
                        component={ReduxInput}
                        validate={[validator.required, validator.usaZip]}
                        // validate={
                        //   countryValue === 'USA'
                        //     ? [validator.required, validator.usaZip]
                        //     : [validator.required, validator.zip]
                        // }
                        fieldName={'Zip Code'}
                      />
                      <label class="placeholder-label">ZIP Code*</label>
                    </div>
                  </div>
                </div>
                <div className="app-status">
                  <img src="images/icons/info.svg" />
                  <span className="text">An app download link will be sent.</span>
                </div>
              </div>
            )}
            {memberType == 'existing' && (
              <>
                <div className="px-4 w-100">
                  <div class="search-box w-100">
                    <div class="form-group has-search">
                      <input
                        type="text"
                        class="form-control shadow-sm"
                        placeholder="Search"
                        onChange={this.onSearchChange}
                      />
                      <button class="btn search-icon " type="button">
                        <i class="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row top-table-search  mb-4 align-items-center">
                  <div class="col-12  text-right d-flex justify-content-between align-items-center">
                    <div
                      style={{ float: 'left', clear: 'both' }}
                      ref={el => {
                        this.messagesEnd = el;
                      }}
                    ></div>
                    {!this.state.isAllvalidRecord && (
                      <span className="error-text">
                        Please select the relation with client to add association.
                      </span>
                    )}
                  </div>
                </div>
                {supportGroup && supportGroup.length > 0 ? (
                  <div
                    style={{
                      // height: 'auto',
                      // overflow: 'auto',
                      width: '100%',
                      paddingBottom: '50px',
                    }}
                  >
                    {supportGroup &&
                      supportGroup.map((item, index) => {
                        const { ProfileImageUrl, DisplayName, UserGuid, Phone, UserId } = item;
                        let srcImg = ProfileImageUrl ? ProfileImageUrl : 'images/blank-profile.png';
                        return (
                          <>
                            <div className="d-flex justify-content-between my-3 px-5">
                              <div className="new-association">
                                <Checkbox
                                  type="checkbox"
                                  id="user"
                                  name={DisplayName}
                                  value={UserId}
                                  onChange={e => {
                                    let isChecked = e.target.checked;
                                    let value = e.target.value;
                                    this.handleRecordChange({
                                      index,
                                      UserId: value,
                                      isChecked,
                                      type: 'checkbox',
                                    });
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                                <img
                                  className="autoSelectImage"
                                  style={{ margin: '0px 15px', width: '60px', height: '60px' }}
                                  src={srcImg}
                                />
                                <div className="user">
                                  <span
                                    className={
                                      recordInList.some(
                                        record => record.UserId === UserId && record.isChecked,
                                      )
                                        ? 'selected-display-name'
                                        : 'display-name'
                                    }
                                  >
                                    {DisplayName}
                                  </span>
                                  <span>{Phone}</span>
                                </div>
                                <br />
                              </div>
                              <div style={{ width: '180px', margin: '10px 0px' }}>
                                <ReduxSelect
                                  options={relationOptions}
                                  onChange={value => {
                                    this.handleRecordChange({
                                      index,
                                      RelationShipId: value,
                                      UserId: UserId,
                                    });
                                  }}
                                  className="basic-single w-50"
                                  classNamePrefix="select"
                                  value={
                                    selectedMembersList[index] &&
                                    selectedMembersList[index].RelationShipId
                                  }
                                  placeholder="Relation*"
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                ) : (
                  <div className="col-12 col-md-12 col-lg-12 mt-12" style={{ color: 'grey' }}>
                    Members not available for selection.
                  </div>
                )}
              </>
            )}
          </div>

          <div class="pb-4 pt-2">
            {memberType == 'new' && (
              <div class="text-right">
                <input type="submit" class="btn px-5" value={'Invite'} />
              </div>
            )}
            {memberType == 'existing' && (
              <div class="text-right">
                <input
                  type="button"
                  class="btn px-5"
                  value={'Invite & Add Member'}
                  // disabled={!this.state.isValidOneRecord}
                  disabled={!recordInList.length}
                  onClick={() => {
                    let isAllvalidRecord = recordInList.every((record, index) => {
                      if (record.isChecked) {
                        return record.RelationShipId ? true : false;
                      } else return true;
                    });
                    if (!isAllvalidRecord) {
                      this.setState({ isAllvalidRecord });
                      this.scrollToError();
                    } else {
                      handleExistingMember(selectedMembersList);
                      onClose();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const ReduxAddNewAssociationForm = reduxForm({
  form: 'AddNewAssociationForm',
})(AddNewAssociation);

export default ReduxAddNewAssociationForm;
