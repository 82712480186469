import React, { useEffect } from 'react';
import hostGrabber from '../util/hostGrabber';

const loaders = ['Guardian_Loader','Mindfuli_Loader', 'loader'];

const loaderFunction = () => {
  let loader = sessionStorage.getItem('loaderName');
  
  let defaultLoader;
  if (loader == 'undefined' || loader == null) {
    // defaultLoader = 'default_loader';
    defaultLoader = hostGrabber() ? 'youu_loader' : 'default_loader';
  } else {
    let isLoader = loaders.some((item, index) => item == loader);
    // defaultLoader = isLoader ? loader : 'default_loader';
    // defaultLoader = isLoader ? hostGrabber() ? 'youu_loader' : loader : hostGrabber() ? 'youu_loader' : 'default_loader';
    defaultLoader = isLoader
      ? loader
      : hostGrabber()
      ? 'youu_loader'
      : 'default_loader';
  }
  return defaultLoader;
};

const Loader = ({ loading }) => {
  return (
    loading && (
      <div className="preloader-box">
        <div
          className={`preloader loading ${loaderFunction()}`}

          // style={loaderFunction() !== 'loader' ? { width: '135px' } : { width: '' }}

          // style={hostGrabber() ? { width: '8em' } : { width: '3.75em' }}

          style={
            loaderFunction() !== 'loader'
              ? hostGrabber()
                ? { width: '8em' }
                : { width: '3.75em' }
              : { width: '3.75em' }
          }
        >
          <figure>
            <img src={`images/${loaderFunction()}.gif`} alt="" />
          </figure>
        </div>
      </div>
    )
  );
};

export default Loader;

export const Loader2 = ({ loading }) => {
  return (
    loading && (
      <div className="preloader-box2">
        <div
          // className="preloader2 loading"
          className={`preloader2 loading ${loaderFunction()}`}
          // style={
          //   loaderFunction() !== 'loader'
          //     ? { width: '135px', transform: 'translate(-50%, -50%)' }
          //     : { width: '' }
          // }

          // style={hostGrabber() ? { width: '8em' } : { width: '3.75em' }}

          style={
            loaderFunction() !== 'loader'
              ? hostGrabber()
                ? { width: '8em' }
                : { width: '3.75em' }
              : { width: '3.75em' }
          }
        >
          <figure>
            <img src={`images/${loaderFunction()}.gif`} alt="" />
          </figure>
        </div>
      </div>
    )
  );
};
export const AILoader = ({ loading }) => {
  useEffect(() => {
    if (document.getElementById('text1')) {
      const elts = {
        text1: document.getElementById('text1'),
        text2: document.getElementById('text2'),
      };

      const texts = ['Searching 1 billion records', "Summarizing patient's data", 'One moment...'];

      const morphTime = 1;
      const cooldownTime = 0.95;

      let textIndex = texts.length - 1;
      let time = new Date();
      let morph = 0;
      let cooldown = cooldownTime;

      elts.text1.textContent = texts[textIndex % texts.length];
      elts.text2.textContent = texts[(textIndex + 1) % texts.length];

      function doMorph() {
        morph -= cooldown;
        cooldown = 0;

        let fraction = morph / morphTime;

        if (fraction > 1) {
          cooldown = cooldownTime;
          fraction = 1;
        }

        setMorph(fraction);
      }

      function setMorph(fraction) {
        elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
        elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

        fraction = 1 - fraction;
        elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
        elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

        elts.text1.textContent = texts[textIndex % texts.length];
        elts.text2.textContent = texts[(textIndex + 1) % texts.length];
      }

      function doCooldown() {
        morph = 0;

        elts.text2.style.filter = '';
        elts.text2.style.opacity = '100%';

        elts.text1.style.filter = '';
        elts.text1.style.opacity = '0%';
      }

      function animate() {
        requestAnimationFrame(animate);

        let newTime = new Date();
        let shouldIncrementIndex = cooldown > 0;
        let dt = (newTime - time) / 1000;
        time = newTime;

        cooldown -= dt;

        if (cooldown <= 0) {
          if (shouldIncrementIndex) {
            textIndex++;
          }

          doMorph();
        } else {
          doCooldown();
        }
      }

      animate();
    }
  }, [document.getElementById('text1')]);

  return (
    loading && (
      <>
        <div className="preloader-box2 d-flex justify-content-center align-items-end">
          <div className="AIloader loading">
            <div id="container">
              <span id="text1"></span>
              <span id="text2"></span>
            </div>
            <svg id="filters">
              <defs>
                <filter id="threshold">
                  <feColorMatrix
                    in="SourceGraphic"
                    type="matrix"
                    values="1 0 0 0 0
									0 1 0 0 0
									0 0 1 0 0
									0 0 0 255 -140"
                  />
                </filter>
              </defs>
            </svg>
            <figure>
              <img src="images/AI_Loader_whitebg.gif" alt="" style={{ height: '300px' }} />
            </figure>
          </div>
        </div>
      </>
    )
  );
};

export const AILoader2 = ({ loading }) => {
  return (
    loading && (
      <div className="preloader-box2">
        <div
          className="AIloader loading"
          style={{
            width: '30%',
            left: '60%',
            top: '35%',
          }}
        >
          <img src="images/AI_Loader_text.gif" />
        </div>
      </div>
    )
  );
};
