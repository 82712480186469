import React from 'react';
import { Modal, Checkbox, Row, Col, Radio, Slider, InputNumber } from 'antd';

class CheckboxGroup extends React.Component {
  checkboxGroup() {
    let {
      label,
      required,
      options,
      input,
      meta,
      col,
      offset,
      justify,
      blockDays,
      fullDescription,
      title,
      disabled,
    } = this.props;

    return options.map((option, index) => {
      return (
        <Col
          // span={col}
          lg={{ span: col }}
          offset={offset || 0}
          style={{ marginBottom: '10px', marginTop: '10px', marginRight: '5px' }}
          key={index}
        >
          {/* <label>
            <input
              type="checkbox"
              name={`${input.name}[${index}]`}
              value={option.value}
              checked={input.value.indexOf(option.value) !== -1}
              onChange={event => {
                const newValue = [...input.value];
                if (event.target.checked) {
                  newValue.push(option.value);
                } else {
                  newValue.splice(newValue.indexOf(option.value), 1);
                }

                return input.onChange(newValue);
              }}
            />
            {option.label}
          </label> */}
          <Checkbox
            className={'d-flex align-items-center'}
            name={`${input.name}[${index}]`}
            value={option.value}
            disabled={option.value === blockDays || disabled}
            checked={
              (input.value && input.value.indexOf(option.value) !== -1) ||
              option.value === blockDays
            }
            onChange={event => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(option.value);
              } else {
                newValue.splice(newValue.indexOf(option.value), 1);
              }

              return input.onChange(newValue);
            }}
          >
            {title ? <span title={option.text}>{option.label}</span> : option.label}
          </Checkbox>
          {fullDescription && <span style={{ marginLeft: '30px' }}>{option.description}</span>}
        </Col>
      );
    });
  }

  render() {
    return (
      // <Row justify={this.props.justify} className={this.props.widthClassName}>
      // <div className="check-box-group">{this.checkboxGroup()}</div>
      <>{this.checkboxGroup()}</>
      // </Row>
    );
  }
}

export default CheckboxGroup;
