import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class DateType extends React.Component {
  constructor(props) {
    super(props);
    let { Response } = props.survey;
    this.state = {
      dateValue:
        Response && Response === 'Invalid date' ? '' : Response ? moment(Response).toDate() : '',
    };
  }

  handleChange = dateValue => {
    this.setState({
      dateValue: dateValue,
    });
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    const ExampleCustomInput = ({ value, onClick }) => {
      return (
        <input
          type="text"
          class="form-control datepicker"
          placeholder="Select Date"
          defaultValue={value}
          onClick={onClick}
        />
      );
    };
    return (
      <div className="type-border col-sm-12 col-lg-6">
        <div>
          <SurveyQuestion survey={survey} ClassName={'mb-0'} />

          <div class="form-group d-flex align-items-center" style={{ maxWidth: '371px' }}>
            <DatePicker
              showYearDropdown
              disabled={isReadOnly}
              selected={this.state.dateValue}
              placeholder="Select Date"
              dateFormat="dd-MMM-yyyy"
              isClearable
              // dateFormat="MMMM d, yyyy"
              // placeholderText="Select Date"
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              onChange={dateValue => {
                this.handleChange(dateValue);
                setValue.call(scope, {
                  response: dateValue ? moment(dateValue).format('YYYY-MM-DD') : null,
                  surveyQuestionId: SurveyQuestionId,
                  level: level,
                  subLevel: subLevel,
                  subCatLevel: subCatLevel,
                  index: index,
                });
              }}
              // minDate={new Date()}
            />
            {/* <i class="far fa-calendar-alt form-control-icon" /> */}
          </div>
          {/* <div class="answer-options text-left">
            <div class="quantity-btn">
              <div class="numbers-row">
                <input
                  disabled={isReadOnly}
                  type="text"
                  value={this.state.value}
                  tabindex="0"
                  style={{ textAlign: 'center', paddingLeft: '10px' }}
                  className="alphanumeric-type"
                  onChange={this.onChange}
                  onBlur={() => {
                    setValue.call(scope, {
                      response: this.state.value,
                      surveyQuestionId: SurveyQuestionId,
                      level: level,
                      subLevel: subLevel,
                      subCatLevel: subCatLevel,
                      index: index,
                    });
                  }}
                />
              </div>
            </div>
          </div> */}
          {/* <div class="row">
              <div class="col-12 text-right">
                <button class="btn" onClick={onClick.bind(this)}>
                  Next
                </button>
              </div>
            </div> */}
          <DoesNotApplyOption surveyProps={this.props} />
        </div>
      </div>
    );
  }
}
export default DateType;
