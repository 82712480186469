import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QuestionType from '../../RCM/patientEnrollmentForm/formSteps/questionType/questionComponent';
import Loader, { Loader2 } from '../../../Components/Loader';
// import { MandatoryWarning } from '../mandatoryWarning';
class ConfigutationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      SurveyQuestion,
      SurveyId,
      onNext,
      setValue,
      scope,
      goToPreviuos,
      handleNext,
      isMandatory,
      tabsCount,
      index,
      isAnswerUpsert,
      isAnserFetching,
    } = this.props;
    // const { SurveyId, SurveyMainTitle, SurveyQuestion } = SurveyQuestionList || {};
    return (
      <div
        className="row survery-question-box client-screening"
        style={{ maxWidth: 'unset', marginTop: '0px' }}
      >
        {/* {isMandatory && <MandatoryWarning />} */}

        <div className="col-12 mt-2">
          {SurveyQuestion && SurveyQuestion.length > 0 && (
            <div
              style={{
                padding: '5px',
                margin: '0px 5px',
              }}
            >
              <QuestionType
                SurveyQuestion={SurveyQuestion}
                SurveyId={SurveyId}
                onNext={onNext}
                setValue={setValue}
                scope={scope}
                level={0}
                // isReadOnly={this.state.isReadOn}
                // saveSignature={this.saveSignature}
                isAnswerUpsert={isAnswerUpsert}
                isAnserFetching={isAnserFetching}
              />
            </div>
          )}
        </div>

        <div class="col-12 mb-4 text-right survey-pagination-button">
          {index > 0 && (
            <button
              onClick={goToPreviuos}
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              //   disabled={disable}
            >
              Previous
            </button>
          )}
          {index + 1 != tabsCount && (
            <button
              onClick={handleNext}
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              //   disabled={disable}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(ConfigutationTab);
