import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import CustomModal from '../../../../core/modal';
import AugmentedSummary from '../../AugmentedSummary';
import RiskChart from '../../RiskChart';
import { CommonLicenceScreens } from '../../../licenceScreens';
import { userFeatureFlagGet } from '../../../../store/actions';
import PageTitle from '../../../../Components/PageTitle';
// import ClientScreeningCard from '../../ClientScreeningCard';
// import UpdateHistory from '../../UpdateHistory';

class PreintakeProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAugmentedAIDrawerOpen: false,
    };
  }
  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }

  moveToIntake = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            EnrollStatusId: 0,
          },
        ],
      }),
    };
    Axios.post('Student/StudentEnrollStatusUpdate', data)
      .then(response => {
        if (response.data.success) {
          this.props.history.replace(`/enrollment/?studentid=${this.getIntakeId()}`);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  toggleDrawer = () => {
    this.setState({ isAugmentedAIDrawerOpen: !this.state.isAugmentedAIDrawerOpen });
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  viewHistory = () => {
    this.props.viewHistory('3', 43, '0_Program%Allignment');
  };
 
  componentWillReceiveProps({ AugmentedAIStatusData }) {
    // JSON.stringify(AugmentedAIStatusData) != JSON.stringify(this.props.AugmentedAIStatusData);
     if (
       this.props.AugmentedAIStatusData &&
       Object.keys(this.props.AugmentedAIStatusData).length > 0 &&
       JSON.stringify(AugmentedAIStatusData) != JSON.stringify(this.props.AugmentedAIStatusData)
     ) {
       if (AugmentedAIStatusData && AugmentedAIStatusData.StudentId == null) {
         this.props.dispatch(
           userFeatureFlagGet({
             JsonInput: JSON.stringify({ Center: { StudentId: this.getIntakeId() } }),
           }),
         );
       }
     }
  }
  render() {
    const { PreItakeSurveyId } = this.props;
    return (
      <React.Fragment>
        <PageTitle TitleText="Prospect" />
        <div className="main students-page mb-5">
          <div className="row">
            <div className="col-sm-12">
              {this.props.AugmentedAIStatusData && (
                <CommonLicenceScreens
                  StatusData={this.props.AugmentedAIStatusData}
                  moduleName={'AugmentedAI'}
                />
              )}
              <>
                {this.props.AugmentedAIStatusData &&
                  this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
                  this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
                  this.props.AugmentedAIStatusData.LicenseStatusId != 5 && (
                    <>
                      <AugmentedSummary ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />
                    </>
                  )}
              </>
            </div>
            <div className="col-sm-12">
              <RiskChart
                ClientId={this.getIntakeId()}
                SurveyId={PreItakeSurveyId}
                viewHistory={this.viewHistory}
              />
            </div>

            {/* <div className="col-sm-12">
                <ClientScreeningCard
                  ClientId={this.getIntakeId()}
                  SurveyId={PreItakeSurveyId}
                  history={this.props.history}
                />
              </div> */}
            {/* <div className="col-sm-12">
                <UpdateHistory ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />
              </div> */}
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { student_banner, user_feature_flag_get } = state;
  const { data, isFetching, error } = student_banner;
  const { data: fFData } = user_feature_flag_get;

  let PreItakeSurveyId = null;
  let AugmentedAIStatusData = null;

  if (data) {
    PreItakeSurveyId = data.PreItakeSurveyId;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }

  return {
    PreItakeSurveyId,
    AugmentedAIStatusData,
  };
};

export default connect(mapStateToProps)(withRouter(PreintakeProfile));
