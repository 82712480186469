import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxToggle,
} from '../../component/custom-redux-components/index.js';
import { Link, withRouter } from 'react-router-dom';
import _blank from '../../images/blank-profile.png';
import TestHistory from './testHistory';
import UpcomingTests from './upcomingTests';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import ListRenderer from '../../Components/listRenderer';
import {
  upsertBreathalyzerSchedule,
  breathalyzerPushATest,
  getBreathalyzerTestResult,
  getBreathalyzerTestUpcoming,
  getOneCallTimeSlots,
} from '../../store/actions';
import { stringDate } from '../../util/date';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const Validator = {
  required: value => (value ? undefined : 'Required'),
  accountName: value =>
    value && !/^.{1,50}$/.test(value) ? ' cannot exceed 50 characters' : undefined,
  routingNumber: value =>
    value && !/^[0-9]{9}$/.test(value) ? ' is a 9 digit numeric Value' : undefined,
  accountNumber: value =>
    value && !/^[0-9]{4,20}$/.test(value)
      ? ' has a minimum of 4 and a maximum of 20 digits'
      : undefined,

  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? ' numeric values only' : undefined,
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return 'has to be greater than Start Date';
    }
  },
};

class BreathalyzerTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      capacityCount: 4,
      activeKey: props.activeTab.toString() || 0,
    };
    props.dispatch(getOneCallTimeSlots({}));
  }
  onGroupCapacity = capacityCount => {
    this.state.capacityCount = capacityCount;
  };
  getCapacityCount = e => {
    let inputNum = e.target.value;
    let pat = /^[0-9]{0,1}$/;
    if (inputNum.match(pat) && inputNum > 1) {
      inputNum = parseInt(inputNum);
      this.setState({
        capacityCount: inputNum || 0,
      });
    }
  };
  componentDidMount() {
    // this.props.dispatch(
    //   getBreathalyzerTestResult({
    //     Json: JSON.stringify({
    //       Breathalyzer: [
    //         {
    //           UserGuid: this.props.UserGuid,
    //         },
    //       ],
    //     }),
    //   }),
    // );
    // this.props.dispatch(
    //   getBreathalyzerTestUpcoming({
    //     Json: JSON.stringify({
    //       Breathalyzer: [
    //         {
    //           UserGuid: this.props.UserGuid,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }
  componentWillReceiveProps({ BreathalyzerData, isInserted }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.props.dispatch(reset('BreathalyzerFormModal'));
      this.props.onCancel();
    }
    if (
      BreathalyzerData &&
      JSON.stringify(BreathalyzerData) != JSON.stringify(this.props.BreathalyzerData)
    ) {
      this.setState({
        capacityCount: BreathalyzerData.TestsPerDay,
      });
    }
  }

  render() {
    const { activeKey, capacityCount, ScheduleList } = this.state;
    const {
      BreathalyzerData,
      Breathalyzer,
      onCancel,
      isFetchingBreathalyzer,
      handleSubmit,
      repeatTypeValue,
      isRandomValue,
      timeSlots,
    } = this.props;

    const getTimeSlot = () => {
      let content = [];
      for (let i = 0; i < capacityCount; i++) {
        content.push(
          <div className="col-4" key={i}>
            <div class="form-group mb-2">
              <Field
                name={`option${i}`}
                type="text"
                component={ReduxSelect}
                // validate={Validator.required}
                options={timeSlots}
                placeholder="Test Time"
                fieldName={'Test Time'}
              />
            </div>
          </div>,
        );
      }
      return content;
    };
    const weekDays = [
      { label: 'Mo', value: 2, text: 'Monday' },
      { label: 'Tu', value: 3, text: 'Tuesday' },
      { label: 'We', value: 4, text: 'Wednesday' },
      { label: 'Th', value: 5, text: 'Thursday' },
      { label: 'Fr', value: 6, text: 'Friday' },
      { label: 'Sa', value: 7, text: 'Saturday' },
      { label: 'Su', value: 1, text: 'Sunday' },
    ];
    const {
      UserGuid,
      BreathalyzerScheduleGuid,
      DisplayName,
      EndDate,
      StartDate,
      NextTest,
      ProfileImageUrl,
      LastTestResult,
    } = BreathalyzerData || {};
    return (
      <div class="modal-content border-0" style={{ background: '#F8F8F8' }}>
        <div class="modal-header justify-content-center flex-wrap pb-0 border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          {/* <h4 class="modal-title col-12">Welcome {localStorage.getItem('userName')}</h4> */}
          {/* <div className="w-100 text-center mb-3">
            <img src="images/SoberPeer_Logo.png" alt="asas" style={{ height: '70px' }} />
          </div> */}
        </div>
        <div class="modal-body col-md-12 enrollment-form pb-2">
          <div className="container">
            <div class="card border-0 ">
              <div class="row card-body align-items-center">
                <div class="col-12 col-md-6 col-lg-6">
                  <h5 class="font-weight-semibold mb-3 mb-md-0 students-profile">
                    <div className="d-flex align-items-center">
                      <img
                        src={ProfileImageUrl || _blank}
                        style={{
                          marginRight: '5px',
                          objectFit: 'cover',
                          background: '#D8D8D8',
                          borderRadius: '10px',
                        }}
                        width="74"
                        height="74"
                      />
                      <div>
                        <span className="d-block">{DisplayName}</span>
                        {/* <span className="d-block">20005678</span> */}
                      </div>
                    </div>
                  </h5>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="row students-detail  text-md-left  justify-content-between">
                    <>
                      <div class="mb-2">
                        <span class="info-label">Start Date</span>
                        <h6 class="info-detail m-md-0">{StartDate}</h6>
                      </div>
                      <div class="mb-2 mr-2">
                        <span class="info-label">End Date</span>
                        <h6 class="info-detail m-md-0">{EndDate}</h6>
                      </div>
                    </>
                    <div class="mb-2 mr-2">
                      {/* <a>
                        <img src="images/icons/download.svg" alt="download" width="35" />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row center-tabs breathalyzer-tab">
              <div className="col-12">
                <div className="Activities">
                  {/* <ListRenderer list={breathalyzerList} activeKey={activeKey} /> */}
                  <Tabs defaultActiveKey={activeKey}>
                    <TabPane tab="Test History" key="0">
                      <TestHistory
                        DisplayName={BreathalyzerData.DisplayName}
                        UserGuid={UserGuid || this.props.UserGuid}
                      />
                    </TabPane>
                    <TabPane tab="Upcoming Tests" key="1">
                      <UpcomingTests UserGuid={UserGuid || this.props.UserGuid} />
                    </TabPane>
                    <TabPane tab="Manage Schedule" key="2">
                      <div className="row">
                        <div class="col-12">
                          <form
                            onSubmit={handleSubmit(val => {
                              let timeSlots = [];
                              if (!val.isRandom)
                                for (let i = 0; i < this.state.capacityCount; i++) {
                                  timeSlots.push(val[`option${i}`]);
                                }
                              let data = {
                                BreathalyzerSchedule: [
                                  {
                                    BreathalyzerScheduleGuid: BreathalyzerScheduleGuid || null,
                                    UserGuid: UserGuid || this.props.UserGuid,
                                    StartDate: stringDate(val.startDate),
                                    EndDate: stringDate(val.endDate),
                                    RepeatFrequency: val.repeatType,
                                    DaysOfWeek:
                                      val.repeatType === 'Weekly'
                                        ? val.dayOfWeek && val.dayOfWeek.join()
                                        : null,
                                    IsRandom: val.isRandom,
                                    TestsPerDay: this.state.capacityCount,
                                    TimeSlots: !val.isRandom ? timeSlots.join() : null,
                                    StartTimeSlotId: val.startTime || null,
                                    EndTimeSlotId: val.endTime || null,
                                  },
                                ],
                              };
                              let BreathalyzerData = {
                                Json: JSON.stringify(data),
                              };
                              this.props.dispatch(upsertBreathalyzerSchedule(BreathalyzerData));
                              // onCancel();
                            })}
                          >
                            <div class="card border-0 ">
                              <div class="row card-body ">
                                <div className="col-12 enrollment-form">
                                  <div className="row">
                                    <div className="col-12">
                                      <h6 className="text-uppercase">Duration</h6>
                                    </div>
                                    <div className="col-6">
                                      <div class="form-group mb-2">
                                        <Field
                                          name="startDate"
                                          type="text"
                                          component={ReduxDateInput}
                                          errorMessage="Start Date"
                                          label={'Start Date'}
                                          //   validate={Validator.required}
                                          minDate={new Date()}
                                        />
                                        <i
                                          class="far fa-calendar-alt form-control-icon"
                                          style={{ paddingRight: '11px' }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div class="form-group mb-2">
                                        <Field
                                          name="endDate"
                                          type="text"
                                          component={ReduxDateInput}
                                          label={'End Date'}
                                          fieldName={'End Date'}
                                          // validate={[Validator.required, Validator.endDate]}
                                          minDate={new Date()}
                                        />
                                        <i
                                          class="far fa-calendar-alt form-control-icon"
                                          style={{ paddingRight: '11px' }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <h6 className="text-uppercase">Repeat</h6>
                                    </div>
                                    <div className="col-12">
                                      <div class="form-group mb-2 custom-radio">
                                        <label className="mr-4">
                                          Weekly
                                          <Field
                                            name="repeatType"
                                            component={ReduxInput}
                                            type="radio"
                                            value="Weekly"
                                            //   disabled={this.state.isDisable}
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                              border: 'solid 1px #bcbcbc',
                                              marginRight: '5px',
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                        <label className="mr-4">
                                          Daily
                                          <Field
                                            name="repeatType"
                                            component={ReduxInput}
                                            type="radio"
                                            value="Daily"
                                            //   disabled={this.state.isDisable}
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                              border: 'solid 1px #bcbcbc',
                                              marginRight: '5px',
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    {repeatTypeValue === 'Weekly' && (
                                      <>
                                        <div className="weekly-block">
                                          <div className="week-days">
                                            <div class="col-12">
                                              <div
                                                style={{
                                                  flexFlow: 'wrap',
                                                  display: 'flex',
                                                }}
                                                className="repeat-weekly"
                                              >
                                                <Field
                                                  name="dayOfWeek"
                                                  component={CheckboxGroup}
                                                  col={3}
                                                  widthClassName={'w-75'}
                                                  options={weekDays}
                                                  title={true}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    <div class="col-8 mt-3">
                                      <div class="license-quantity d-flex  justify-content-center">
                                        <label class="placeholder-label flex-grow-1">
                                          Tests per day
                                        </label>
                                        <div class="quantity-btn flex-grow-1">
                                          <div class="numbers-row">
                                            <input
                                              name={'groupCapacity'}
                                              class="number-icon"
                                              type="text"
                                              min="0"
                                              max="3"
                                              value={capacityCount}
                                              onChange={this.getCapacityCount}
                                              //   validate={Validator.minValue}
                                              tabindex="0"
                                              style={{
                                                textAlign: 'center',
                                                margin: '0px 5px',
                                                height: '32px',
                                                width: '56px',
                                                fontSize: '16px',
                                              }}
                                              //   disabled={isDeactivated}
                                            />
                                            <div
                                              style={{
                                                borderRadius: '0px',
                                                height: '32px',
                                                width: '32px',
                                              }}
                                              class="add quantity-button"
                                              onClick={() => {
                                                // if (!isDeactivated)
                                                if (capacityCount <= 9) {
                                                  return this.setState(
                                                    { capacityCount: capacityCount + 1 },
                                                    this.onGroupCapacity(capacityCount + 1),
                                                  );
                                                } else {
                                                  return;
                                                }
                                              }}
                                            >
                                              +
                                            </div>
                                            <div
                                              style={{
                                                borderRadius: '0px',
                                                height: '32px',
                                                width: '32px',
                                              }}
                                              class="remove quantity-button"
                                              onClick={() => {
                                                const val = capacityCount - 1;
                                                if (val < 1) return;
                                                // if (!isDeactivated)
                                                this.setState(
                                                  {
                                                    capacityCount: val,
                                                  },
                                                  this.onGroupCapacity(val),
                                                );
                                              }}
                                            >
                                              -
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-4 mt-3 px-0">
                                      <div class="form-group mt-1">
                                        <label
                                          class="custom-control-label"
                                          for="Recurring-Appointment"
                                          style={{ fontSize: '16px' }}
                                        >
                                          Random?{' '}
                                          <Field
                                            name={`isRandom`}
                                            type="text"
                                            class="custom-control-input not-empty"
                                            component={ReduxToggle}
                                            // onChange={bool => toogleChange(bool)}
                                            // disabled={this.state.isDisable}
                                          />
                                        </label>
                                      </div>
                                    </div>
                                    {isRandomValue ? (
                                      <>
                                        <div className="col-12">
                                          <div className="row">
                                            <div className="col-6">
                                              <div class="form-group mb-2">
                                                <Field
                                                  name="startTime"
                                                  type="text"
                                                  component={ReduxSelect}
                                                  options={timeSlots}
                                                  placeholder="Start Time"
                                                  // validate={Validator.required}
                                                  fieldName={'Start Time'}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-6">
                                              <div class="form-group mb-2">
                                                <Field
                                                  name="endTime"
                                                  type="text"
                                                  component={ReduxSelect}
                                                  options={timeSlots}
                                                  placeholder="End Time"
                                                  // validate={Validator.required}
                                                  fieldName={'End Time'}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="col-12">
                                        <div className="row">{getTimeSlot()}</div>
                                      </div>
                                    )}
                                    <div className="col-12">
                                      <div class="modal-footer  border-top-0 pt-2">
                                        <input
                                          type="submit"
                                          class="btn black-btn"
                                          value={'Schedule Now'}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const timelinemapStateToProps = state => {
  const { upsert_breathalyzer_schedule, get_one_call_timeSlots } = state;
  const { data, isFetching, error } = upsert_breathalyzer_schedule;

  let isInserted = false;
  let timeSlots = [];
  if (data && data.success) {
    isInserted = data.success;
  }
  if (get_one_call_timeSlots.data && get_one_call_timeSlots.data.Advisor) {
    timeSlots = get_one_call_timeSlots.data.Advisor[0].TimeSlots || [];
  }

  const repeatTypeValue = formValueSelector('BreathalyzerFormModal')(state, 'repeatType');
  const isRandomValue = formValueSelector('BreathalyzerFormModal')(state, 'isRandom');
  return {
    repeatTypeValue,
    isRandomValue,
    timeSlots,
    isInserted,
    isFetching,
  };
};
const ReduxBreathalyzerTimeline = reduxForm({
  form: 'BreathalyzerFormModal',
  enableReinitialize: true,
})(connect(timelinemapStateToProps)(BreathalyzerTimeline));

export default ReduxBreathalyzerTimeline;
