import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { clientSignatureSave } from '../store/actions';
import { Link } from 'react-router-dom';
import Loader, { Loader2 } from './Loader';
import SignaturePad from 'react-signature-canvas';
class ValidateSignatureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enterPinModal: false,
      signImageByteData: null,
    };

    this.pinInput = React.createRef();
  }

  validateSignature = () => {
    this.props.dispatch(
      clientSignatureSave({
        ClientId: this.props.ClientId,
        TreatmentPlanId: this.props.TreatmentPlanId,
        Extension: 'png',
        SignatureImage: this.state.signImageByteData.replace('data:image/png;base64,', ''),
        // SignatureImage: JSON.stringify({ Image: [{ Data: this.state.signImageByteData }] }),
      }),
    );
  };

  saveSignature = data => {
    this.setState({ signImageByteData: data });
  };

  render() {
    const sigPadObj = {};
    const clear = index => {
      sigPadObj[index].clear();
    };
    const { onCancel, isSignatureModalOpen, title } = this.props;
    return (
      <>
        <Modal
          width={600}
          zIndex={99999}
          open={isSignatureModalOpen}
          footer={null}
          onCancel={onCancel}
          closable={false}
          destroyOnClose={true}
        >
          <div class="modal-content border-0">
            <div class="modal-header flex-wrap border-bottom-0 ">
              <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                ×
              </button>
              <h4 class="modal-title col-12 text-center">{title}</h4>
            </div>

            <div class="modal-body">
              <Loader2 loading={this.props.isFetching} />
              <div className="d-flex align-items-center justify-content-center">
                <div className="" style={{ width: '400px' }}>
                  <div className="sigContainer">
                    <SignaturePad
                      canvasProps={{ className: 'sigPad', height: 140, width: 400 }}
                      ref={ref => {
                        Object.assign(sigPadObj, { [1]: ref });
                      }}
                      onEnd={() => {
                        this.saveSignature(sigPadObj[1].toDataURL());
                      }}
                    />
                  </div>
                  <div className="text-right mt-2">
                    <button
                      className="can-buttons d-inline"
                      onClick={() => {
                        clear(1);
                        this.setState({ signImageByteData: null });
                      }}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="text-danger text-center">{this.state.errorForSign}</div>
                  <div className="d-flex justify-content-center">
                    <input
                      className="btn"
                      type="button"
                      value="Submit Signature"
                      onClick={() => {
                        if (this.state.signImageByteData) {
                          this.validateSignature();
                        } else {
                          this.setState(
                            {
                              errorForSign: 'Please sign before clicking submit',
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({
                                  errorForSign: '',
                                });
                              }, 3000);
                            },
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { patient_signature_save } = state;
  var { data, isFetching } = patient_signature_save;

  let isSignatureValidated = false;
  let msg = null;

  if (data && data.success) {
    msg = data.result && data.result.Msg;
    isSignatureValidated = data.success;
  }

  return {
    isSignatureValidated,
    isFetching,
    msg,
  };
};

const ReduxValidateSignature = connect(mapStateToProps)(ValidateSignatureModal);

export default ReduxValidateSignature;
