import React, { Component, useState } from 'react';
import { Tabs, Button, Drawer, Tooltip } from 'antd';
import GoogleMapReact from 'google-map-react';
import Loader, { Loader2 } from '../../../Components/Loader';
import PersistTableSort from '../../../component/persistTableSort';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import {
  addSupportGroup,
  removeSupportGroup,
  loadSupportGroup,
  loadPeer,
} from '../../../store/actions';
import { connect } from 'react-redux';
import CustomModal from '../../../core/modal';
import AntdModal from '../../../Components/CustomModal';
import Twilio from '../../../Components/twilio';
import ComingSoon from '../../../component/comingSoonPage';
import { CommonLicenceScreens } from '../../licenceScreens';
class Peer extends Component {
  constructor() {
    super();
    this.state = {
      columns: [
        {
          title: 'Name',
          dataIndex: 'DisplayName',
          key: 'DisplayName',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.DisplayName, b.DisplayName),
          render: (text, record, index) => {
            const { DisplayName, ProfileImageUrl, UserGuid } = record;
            return (
              <>
                <div
                  className="d-flex align-items-center"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <div className="mr-4">
                    {' '}
                    <figure>
                      <img
                        style={{
                          boxShadow: '0px 11px 6px #f2f2f2',
                          cursor: 'pointer',
                          maxWidth: '40px',
                        }}
                        src={ProfileImageUrl || 'images/blank-profile.png'}
                        class="rounded-circle "
                        width="40"
                        height="40"
                        alt=""
                        onClick={() => {
                          this.props.history.push(`/subscriberProfile/${UserGuid}`);
                        }}
                      />
                    </figure>
                  </div>
                  <div className="">{DisplayName}</div>
                </div>
              </>
            );
          },
        },
        {
          title: 'Phone Number',
          dataIndex: 'Phone',
          key: 'Phone',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isNumericComparer(a.Phone, b.Phone),
          render: (text, record) => {
            return (
              <div className="d-flex">
                <div
                  class="tools-grid"
                  onClick={event => {
                    if (record.IsVoiceCallAllowed) {
                      this.setState({
                        callModelVisible: true,
                        clickedRecord: record,
                      });
                      event.stopPropagation();
                    } else {
                      alert('VOIP call not allowed');
                      event.stopPropagation();
                    }
                  }}
                  style={{
                    cursor: 'pointer',
                    width: '125px',
                    marginRight: '10px',
                  }}
                >
                  <p
                    className="d-flex justify-content-around"
                    style={
                      record.IsVoiceCallAllowed
                        ? {
                            color: '#46B4FE',
                            marginBottom: '0',
                          }
                        : {
                            color: 'black',
                            marginBottom: '0',
                          }
                    }
                  >
                    {' '}
                    {record.IsVoiceCallAllowed ? (
                      <img
                        style={{
                          cursor: 'pointer',
                          display: 'block',
                        }}
                        src={`images/twilioPhoneAllowed.svg`}
                        alt="Calling Allowed"
                        width="10px"
                      />
                    ) : null}
                    {record.Phone}
                  </p>
                </div>
              </div>
            );
          },
        },
        {
          title: 'Age',
          dataIndex: 'Age',
          key: 'Age',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isNumericComparer(a.Age, b.Age),
        },
        {
          title: 'Gender',
          dataIndex: 'Gender',
          key: 'Gender',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.Gender, b.Gender),
        },
        {
          title: 'Program Type',
          dataIndex: 'ProgramType',
          key: 'ProgramType',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.ProgramType, b.ProgramType),
        },
        {
          title: 'Relation to Peer',
          dataIndex: 'Relation',
          key: 'Relation',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.Relation, b.Relation),
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.Status, b.Status),
        },

        {
          title: 'Action',
          key: 'x',
          render: (text, record, index) => (
            <div class="tools-grid">
              <div className="d-flex align-items-center justify-content-between">
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <Tooltip placement="top" title="delete">
                      <span className="start-btn" onClick={onClick}>
                        <i class="fa fa-trash" style={{ cursor: 'pointer' }}></i>
                      </span>
                    </Tooltip>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          Are you sure you want to delete this peer? <br />
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            onOk();
                            this.handleRemoveSupportGroup(record.UserSupportGroupId);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          ),
        },
      ],
    };
  }
  componentWillReceiveProps({ isAdded, isDeleteSupportSuccess, familyProgramStatusData }) {
    if (isAdded && isAdded != this.props.isAdded) {
      this.toggle('Added Successfully');
    }
    if (isDeleteSupportSuccess && isDeleteSupportSuccess != this.props.isDeleteSupportSuccess) {
      this.toggle('Removed Successfully');
    }
    if (
      familyProgramStatusData &&
      JSON.stringify(familyProgramStatusData) != JSON.stringify(this.props.familyProgramStatusData)
    ) {
      this.setState({ familyProgramStatusData });
    }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  onhandleSubmit = values => {
    const { FirstName, LastName, Phone, Email, Relation } = values;

    let data = {
      Json: JSON.stringify({
        User: [
          {
            UserGuid: this.props.getSubscriberUserGUID(),
            ConcernedUserGuid: null,
            RelationShipId: Relation,
            NewUser: [
              {
                MobileNumber: Phone,
                FirstName: FirstName,
                LastName: LastName,
                Email: Email,
              },
            ],
          },
        ],
      }),
    };

    this.props.dispatch(addSupportGroup(data));
  };

  handleRemoveSupportGroup = id => {
    let data = {
      Json: JSON.stringify({ SupportGroup: [{ UserSupportGroupId: id }] }),
    };
    this.props.dispatch(removeSupportGroup(data));
  };

  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };

  render() {
    const { SupportGroup } = (this.props && this.props.loadPeerData) || [];
    const { familyProgramStatusData } = this.props;
    return (
      <>
        <CommonLicenceScreens
          StatusData={this.props.familyProgramStatusData}
          moduleName={'FamilyProgram'}
          isSupportGroup={true}
        />

        <div className="container-fluid p-3 px-0 bg-white pb-5">
          {this.props.familyProgramStatusData &&
          this.props.familyProgramStatusData.LicenseStatusId == 6 ? (
            <>
              <ComingSoon />
            </>
          ) : (
            <>
              {this.props.familyProgramStatusData &&
                this.props.familyProgramStatusData.LicenseStatusId != 1 &&
                this.props.familyProgramStatusData.LicenseStatusId != 3 &&
                this.props.familyProgramStatusData.LicenseStatusId != 5 && (
                  <div
                    className="row card-body"
                    style={{
                      minHeight: '300px',
                      background: 'rgb(255, 255, 255)',
                      borderRadius: '12px',
                      paddingRop: '10px',
                    }}
                  >
                    <div class="col-12 ">
                      {/* <Loader2 loading={isFetching} /> */}
                      <PersistTableSort
                        name="SupportGroupListView"
                        columns={this.state.columns}
                        dataSource={SupportGroup}
                        size={'middle'}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: event => this.onRowClick(event, record, rowIndex),
                        //   };
                        // }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                        }}
                      />
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // window.location.reload(true);
            this.props.dispatch(loadPeer({ UserGuid: this.props.getSubscriberUserGUID() }));
          }}
          yesText="OK"
          hideCancel={true}
        />
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { add_support_group, remove_support_group, user_feature_flag_get } = state;
  var { isFetching, data } = add_support_group;
  var { data: deleteSupportData } = remove_support_group;
  const { data: familyProgramStatus } = user_feature_flag_get;

  let isAdded = false;
  let isDeleteSupportSuccess = false;
  let familyProgramStatusData = null;
  if (data && data.success) {
    isAdded = data.success;
  }
  if (deleteSupportData && deleteSupportData.success) {
    isDeleteSupportSuccess = deleteSupportData.success;
  }
  if (
    familyProgramStatus &&
    familyProgramStatus.FeatureFlag &&
    familyProgramStatus.FeatureFlag.length > 0 &&
    familyProgramStatus.FeatureFlag[0]
  ) {
    familyProgramStatusData =
      familyProgramStatus.FeatureFlag &&
      familyProgramStatus.FeatureFlag[0] &&
      familyProgramStatus.FeatureFlag[0].License &&
      familyProgramStatus.FeatureFlag[0].License[1];
  }
  return {
    isAdded,
    isDeleteSupportSuccess,
    familyProgramStatusData,
  };
};

export default connect(mapStateToProps)(Peer);
