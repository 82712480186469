//Mention all the url where you want to block the center change during editing.
export let blockRoutesDuringCenterChange = [
         '/enrollment',
         '/enrollment/',
         '/manage/course-form',
         '/manage/staff-form',
         '/manage/staff-management/staff-form',
         '/manage/center-form',
        //  '/manage/token',
         '/manage/maintain-license',
         '/manage/miscellaneous',
         '/manage/coupon-form',
         '/manage/default-payment',
         '/manage/announcement-form',
         '/manage/news-form',
         '/manage/app-resource-form',
         '/manage/question-library/config-screening-questions',
         '/completepurchase',
         '/cw/patient-management/new-registration',
         '/cw/patient-management/patient-profile',
         '/cw/group-management/create-group',
         '/cw/group-management/group-details',
        //  '/cw/claims',
         '/pre-intake-form',
       ];
