import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Checkbox, Divider, DatePicker as AntdDatePicker } from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SubmitButton from '../../Components/submitButton';
import {
  surveyQuestionLibrarySectionUpdate,
  surveyQuestionConfigGet,
  surveyQuestionConfigBannerGet,
} from '../../store/actions';
// import { clientChartReport, resetClientChartReport } from '../store/actions';
// import { stringDate } from '../util/date';
// import { Loader2 } from './Loader';

const CheckboxGroup = Checkbox.Group;

// const DragHandle = sortableHandle(() => (
//   <MenuOutlined style={{ cursor: 'grab', color: '#999', marginRight: '10px' }} />
// ));
// const CheckboxGroup = Checkbox.Group;
// const { Search } = Input;

const { RangePicker } = AntdDatePicker;
const dateFormat = 'YYYY-MM-DD';

const SortableItem = SortableElement(({ value, name, required, onCheckChanged }) => (
  <li
    style={{
      padding: '10px',
      margin: '10px',
      background: '#F7F8FA',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      cursor: 'grab',
      border: '1px solid #d9d9d9',
      position: 'relative',
      zIndex: '1000',
    }}
  >
    <img src="images/dragDrop.svg" className="mr-2" style={{ position: 'relative', top: '2px' }} />
    <Checkbox
      className="d-flex align-items-end mb-0"
      value={value}
      //   disabled={required}
      //   style={{ zIndex: 99999 }}
    >
      {name}
    </Checkbox>
  </li>
));

const SortableList = SortableContainer(({ items, onCheckChanged }) => {
  return (
    <ul style={{ display: 'flex', flexDirection: 'column', padding: '0', maxHeight: '550px', overflowY: 'auto' }}>
      {items.map((item, index) => {
        return (
          <SortableItem
            key={item.value}
            index={index}
            value={item.value}
            name={item.label}
            // required={item.required}
            disabled={!!item.fixed}
            onCheckChanged={onCheckChanged}
          />
        );
      })}
    </ul>
  );
});

class ManageCategoryDrawer extends React.Component {
  static defaultProps = {
    filter: true,
    filterList: [],
    onFilterSave: () => {},
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      array: [],
      filterList: [],
      showList: [],
      checkAll: false,
      indeterminate: false,
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
      isFetching: false,
    };
  }

  componentDidMount() {
    this.setState({
      filterList: this.props.filterList.children,
      showList: this.props.filterList.children,
      checkAll:
        this.props.filterList.children.filter(item => item.selected).length ===
        this.props.filterList.children.length,
      indeterminate: this.isIndeterminate(this.props.filterList.children),
    });
  }

  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.props.onCancel();
    }
  }
  componentWillUnmount() {
    this.setState({ isFetching: false });
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  isIndeterminate(list) {
    return (
      list.filter(item => item.selected).length &&
      list.filter(item => item.selected).length < list.length
    );
  }

  onCheckChange(checkedList) {
    this.setState(({ filterList }) => {
      return {
        filterList: filterList.map(item => {
          return {
            ...item,
            selected: !!checkedList.includes(item.value),
          };
        }),
        checkAll: checkedList.length === filterList.length,
        indeterminate: checkedList.length && checkedList.length < filterList.length,
      };
    });
  }

  onCheckAllChange(e) {
    const isCheckedAll = e.target.checked;
    this.setState(
      {
        filterList: isCheckedAll
          ? this.state.filterList.map(item => ({ ...item, selected: true }))
          : this.state.filterList.map(item => ({ ...item, selected: false })),
        checkAll: isCheckedAll,
      },
      () => {
        this.setState({
          indeterminate:
            this.state.filterList.filter(item => item.selected).length &&
            this.state.filterList.filter(item => item.selected).length <
              this.state.filterList.length,
        });
      },
    );
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ filterList }) => {
      const newList = arrayMove(filterList, oldIndex, newIndex);
      return {
        filterList: newList,
        showList: newList,
      };
    });
  }
  handleDateChange = (key, date) => {
    this.setState({
      startDate: date[0],
      endDate: date[1],
    });
  };

  reArrangeCategory = () => {
    const { filterList } = this.state;
    let newList = [];
    filterList &&
      filterList.map((item, index) => {
        const { SurveyQuestionCategoryConfigId, SurveyQuestionSectionId, label, selected } = item;
        newList.push({
          SurveyQuestionSectionId,
          SurveyQuestionCategoryConfigId,
          Sequence: index + 1,
          IsArchived: !selected,
        });
      });
    this.props.dispatch(
      surveyQuestionLibrarySectionUpdate({
        JSON: JSON.stringify({
          SurveyQuestionLibrary: [...newList],
        }),
      }),
    );
  };

  render() {
    const { filterList, showList, checkAll, indeterminate, startDate, endDate } = this.state;
    const { isFetching } = this.props;
    return (
      <div className="print-client-chart">
        {/* <Loader2 loading={isFetching} /> */}
        <div>
          <div className="select-all">
            <Checkbox
              onChange={this.onCheckAllChange.bind(this)}
              checked={checkAll}
              indeterminate={indeterminate}
            />
            <span className="ml-2" style={{ fontSize: '17px', fontWeight: '600' }}>
              Select All
            </span>
          </div>

          <CheckboxGroup
            value={filterList.filter(item => item.selected).map(item => item.value)}
            onChange={this.onCheckChange.bind(this)}
          >
            <SortableList
              lockAxis="y"
              lockToContainerEdges={true}
              items={showList}
              onSortEnd={this.onSortEnd.bind(this)}
              onCheckChanged={this.onCheckChanged}
            />
          </CheckboxGroup>
        </div>
        <div className="d-flex justify-content-end" style={{ marginBottom: '15px' }}>
          {/* <button type="button" className="btn btn-outline-primary" onClick={this.props.onCancel}>
            Cancel
          </button> */}
          {/* <button type="button" className="btn ml-2" onClick={this.reArrangeCategory.bind(this)}>
            Save
          </button> */}
          <SubmitButton
            loading={isFetching}
            style={{ padding: '10px 40px', height: '45px' }}
            onClick={this.reArrangeCategory.bind(this)}
            value={'Save'}
          />
        </div>
      </div>
    );
  }
}
// export default MultipleCategoryCheckboxAll;
const mapStateToProps = state => {
  const { survey_question_library_section_update } = state;
  const { data: sectionUpdate, isFetching, error } = survey_question_library_section_update;

  return {
    isFetching,
    isSuccess: sectionUpdate && sectionUpdate.success,
  };
};
export default connect(mapStateToProps)(withRouter(ManageCategoryDrawer));
