import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tag, Modal, Tooltip, Avatar } from 'antd';
import Loader, { Loader2 } from '../../../Components/Loader';
import { getMediaVideoUrl } from '../../../store/actions';
import BreathlyzerVideoModal from '../../PopupModals/BreathlyzerVideoModal';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ TriggerTypeImgUrl }) => (
  <div>{<img src={TriggerTypeImgUrl} width={'25px'} />}</div>
);

class BreathalyzerTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewVideoModal: false,
      defaultProps: {
        center: {
          lat: '',
          lng: '',
        },
        zoom: 3,
      },
    };
  }
  componentDidMount() {
    const { EventJsonData } = this.props;
    let { Result, ResultClassBlock, Latitude, Longitude, MediaUrl, MediaGuid } =
      EventJsonData.BreathalyzerTestResult && EventJsonData.BreathalyzerTestResult[0];
    this.props.dispatch(
      getMediaVideoUrl({
        Json: JSON.stringify({
          Media: [
            {
              MediaGuid: MediaGuid,
            },
          ],
        }),
      }),
    );
    this.setState({
      defaultProps: {
        center: {
          lat: Latitude,
          lng: Longitude,
        },
        zoom: 8,
      },
    });
  }
  render() {
    const { EventJsonData, VideoCallUrl, isFetching } = this.props;
    let { Result, ResultClassBlock, Latitude, Longitude, MediaUrl, MediaGuid } =
      EventJsonData.BreathalyzerTestResult && EventJsonData.BreathalyzerTestResult[0];
    return (
      <div class="border-0">
        <div className="row no-gutters w-100 justify-content-between align-items-center">
          <div className="col-12">
            <Loader2 loading={isFetching} />
            <div className="d-flex flex-column">
              {Result && (
                <div className="my-2 time-line-table">
                  <span className="due-date d-flex align-items-center">
                    <em>Result : </em> <span className={`${ResultClassBlock} ml-2`}>{Result}</span>
                  </span>
                </div>
              )}
              <div className="row">
                <div className="col-6">
                  <div className="my-2">
                    <span className="due-date d-flex align-items-center">
                      <em>See Event on the Map : </em>{' '}
                      <a
                        href={`http://www.google.com/maps/place/${Latitude},${Longitude}`}
                        target={'_blank'}
                      >
                        <img src={`images/timeline/location.svg`} className="ml-2" />
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="my-2">
                    <span className="due-date d-flex align-items-center">
                      <em>Recorded Video </em>{' '}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAYFWM8jJzcn1Bc-blNBXJBqeSPOoA5Ugo' }}
                    defaultCenter={this.state.defaultProps.center}
                    defaultZoom={this.state.defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={Latitude}
                      lng={Longitude}
                      // text={`images/timeline/location.svg`}
                      TriggerTypeImgUrl={`images/timeline/location.svg`}
                    />
                  </GoogleMapReact>
                </div>
                <div className="col-6">
                  {VideoCallUrl.includes('https') ? (
                    <video
                      style={{ border: '1px solid #EEE' }}
                      width="100%"
                      height="auto"
                      src={VideoCallUrl}
                      autoplay="true"
                      controls
                      disablePictureInPicture="true"
                      controlsList="nodownload"
                    />
                  ) : (
                    <div className="text-center">
                      <h5>{VideoCallUrl}</h5>
                    </div>
                  )}
                </div>
              </div>

              <div className="my-2">
                {/* <span className="due-date d-flex align-items-center">
                        <em>Video : </em>{' '}
                        <img
                          style={{ cursor: 'pointer' }}
                          className="ml-2"
                          src="images/icons/play_grey.svg"
                          alt="download"
                          width="30"
                          onClick={() => {
                            this.setState({
                              viewVideoModal: true,
                            });
                            this.props.dispatch(
                              getMediaVideoUrl({
                                Json: JSON.stringify({
                                  Media: [
                                    {
                                      MediaGuid: MediaGuid,
                                    },
                                  ],
                                }),
                              }),
                            );
                          }}
                        />
                      </span> */}
              </div>
            </div>
          </div>
        </div>

        {/* {this.state.viewVideoModal && (
            <Modal
              // zIndex={200}
              visible={this.state.viewVideoModal}
              footer={null}
              closable={false}
              destroyOnClose={true}
            >
              <BreathlyzerVideoModal
                onCancel={() => {
                  this.setState({
                    viewVideoModal: false,
                  });
                }}
              />
            </Modal>
          )} */}
      </div>
    );
  }
}
const mapStateToProps = ({ get_media_video_url }) => {
  const { data, error, isFetching } = get_media_video_url;
  let VideoCallUrl = '';

  if (get_media_video_url && get_media_video_url.data) {
    VideoCallUrl = data.result;
  }
  return {
    VideoCallUrl,
    isFetching,
  };
};

export default connect(mapStateToProps)(BreathalyzerTest);
