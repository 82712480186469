import React, { Component } from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
  CheckboxGroup,
  ReduxTextarea,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import CustomModal from '../core/modal';
import CustomModal1 from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import { Popover, Button } from 'antd';
import ByteToSize from '../util/ByteToSize';
import AntdModal from '../Components/CustomModal';
import ReduxBroadcaseInvitationModal from './customScreens/broadcastInvitation';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  resetAddSmallGroup,
  listCombos,
  addSmallGroup,
  getOneCallTimeSlots,
  spListCombos,
  getClientCounselor,
  getSmallGroup,
  uploadLeaderPhoto,
  sendVoucherCode,
  getSmallGroupUsers,
  isStaffProBio,
  updateStaffProBio,
  resetStaffProBio,
} from '../store/actions';
import { ProcessCombos } from '../store/comboConfig';
import { FormatDate } from '../Components/FormatDate';
import { Tooltip, Avatar, Checkbox, Radio } from 'antd';
import { Upload, message, Modal, Table, Popconfirm } from 'antd';
import Icon from '@ant-design/icons';
import { isComparer, isNumericComparer } from '../util/handler';

import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';
import { ReduxSendInvitationModal } from './SendInvitation';
import Dropzone from 'react-dropzone';
import Twilio from '../Components/twilio';
import { formatDistance, subDays } from 'date-fns';
import addDays from 'date-fns/addDays';

class ActivityBase extends React.Component {}

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  maxCount: value => {
    let count;
    if (typeof value === 'string') {
      count = value.split(',').length;
    } else {
      count = value.length;
    }
    if (count && count > 4) {
      return 'Max 4 allowed';
    }
    return undefined;
  },
  minValue: value => {
    if (value && value < 2) {
      return 'At least 2 member is required';
    }
    return undefined;
  },
  leaderRequired: value => (value && value.length > 0 ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  required: value => (value ? undefined : 'Required'),

  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 >= date1) {
    } else {
      return 'has to be greater than or Equal to Start Date';
    }
  },
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
  cost: value =>
    value && !/^[0-9]{0,3}$/.test(value) ? 'value between 0 to $999 only' : undefined,
  usaZip: value => (value && !/^[0-9]{5}$/.test(value) ? 'code is invalid for USA' : undefined),
  zip: value => (value && !/^[a-zA-Z0-9_ ]{6,7}$/.test(value) ? 'code is invalid' : undefined),
};
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
const weekDays = [
  { label: 'Mon', value: '2' },
  { label: 'Tue', value: '3' },
  { label: 'Wed', value: '4' },
  { label: 'Thu', value: '5' },
  { label: 'Fri', value: '6' },
  { label: 'Sat', value: '7' },
  { label: 'Sun', value: '1' },
];
const MonthOccurance = [
  {
    Value: 'Week1',
    Text: 'First Week',
  },
  {
    Value: 'Week2',
    Text: 'Second Week',
  },
  {
    Value: 'Week3',
    Text: 'Third Week',
  },
  {
    Value: 'Week4',
    Text: 'Fourth Week',
  },
  {
    Value: 'WeekLast',
    Text: 'Last Week',
  },
];
class SelectGroupPictureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onOk, onCancel, handleSubmit, groupPicture, handleSelect } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">Select Group Picture</h4>
        </div>

        <div class="modal-body col-md-12 enrollment-form">
          <span style={{ fontSize: '16px', color: '#000000' }}>
            Choose an image for the Small Group
          </span>
          <div
            className="d-flex flex-wrap justify-content-center"
            style={{ overflowY: 'auto', height: '400px' }}
          >
            {groupPicture &&
              groupPicture.map((img, index) => {
                return (
                  <img
                    onClick={() => {
                      handleSelect(img.MediaGuid);
                      onCancel();
                    }}
                    name={img.MediaGuid}
                    src={img.MediaUrl}
                    style={{
                      // width: '121px',
                      height: '121px',
                      border: ' solid 1px #000000',
                      marginBottom: '5px',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
class ProfessionalBioModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      onOk,
      onCancel,
      handleSubmit,
      SmallGroupValues,
      professionalBioValues,
      handleSelect,
    } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">Update Staff Professional Bio </h4>
        </div>

        <div class="modal-body col-md-12 enrollment-form">
          <div
            // className="d-flex flex-wrap justify-content-center"
            style={{ overflowY: 'auto', height: '300px' }}
          >
            <h6>{professionalBioValues && professionalBioValues[0].DisplayMessage}</h6>
            <br />
            <form className="enrollment-form mt-4">
              <div className="form-group">
                <textarea
                  style={{ minHeight: '100px' }}
                  className={`form-control ${this.state.proBio && 'not-empty'}`}
                  value={this.state.proBio}
                  onChange={event => {
                    this.setState({ proBio: event.target.value });
                  }}
                  rows="4"
                  key="professionalBio"
                />
                <label class="placeholder-label">Add a Professional Bio..</label>
              </div>
              <div class="modal-footer  border-top-0 pt-2">
                <input onClick={onCancel} type="button" class="btn mx-2" value="Cancel" />
                <input
                  type="button"
                  // disabled={this.state.selectedStudent.length <= 0}
                  class="btn black-btn font-weight-bold mr-2"
                  value=" Save  "
                  onClick={() => {
                    this.props.dispatch(
                      updateStaffProBio({
                        jsonInput: JSON.stringify({
                          Staff: [
                            {
                              Misc: [
                                {
                                  FromPage: 'SmallGroup',
                                  StaffId: SmallGroupValues[0].StaffId,
                                  ProfessionalBio: this.state.proBio,
                                },
                              ],
                            },
                          ],
                        }),
                      }),
                    );
                    onCancel();
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

class SmallGroupForm extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      passCount: 1,
      capacityCount: 2,

      smallGroupData: {
        country: 'USA',
        meetingFrequency: 'weekly',
        acceptNewMember: true,
        grpLeader: '',
        referralCode: '(System Generated)',
        specialGroupType: 'NA',
        // grpLeader: props.hostUser.UserGuid,
      },
    };
  }
  onPassCount = passCount => {
    this.state.passCount = passCount;
  };
  onGroupCapacity = capacityCount => {
    this.state.capacityCount = capacityCount;
  };
  // need to work on it always return zero for me
  getSmallGroupGuid() {
    var param = new URLSearchParams(this.props.history.location.search);
    var smallGroupGuid = param.get('smallGroupGuid') || param.get('smallGroupGuid');
    if (smallGroupGuid) {
      smallGroupGuid = smallGroupGuid;
    } else {
      smallGroupGuid = null;
    }
    return smallGroupGuid;
  }

  componentDidMount() {
    var result = ProcessCombos('Country,StateProvince,Center');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }

    window.scrollTo(0, 0);
    if (this.getSmallGroupGuid() != null) {
      const smallGrp = {
        SmallGroup: [
          {
            SmallGroupGuid: this.getSmallGroupGuid(),
          },
        ],
      };
      let smallGroupGuid = {
        Json: JSON.stringify(smallGrp),
      };
      this.props.dispatch(getSmallGroup(smallGroupGuid));
      this.props.dispatch(
        getSmallGroupUsers({
          Json: JSON.stringify({
            SmallGroup: [{ SmallGroupGuid: this.getSmallGroupGuid(), FilterBy: null }],
          }),
        }),
      );
    } else {
      const smallGrp = {
        SmallGroup: [
          {
            SmallGroupGuid: null,
          },
        ],
      };
      let smallGroupGuid = {
        Json: JSON.stringify(smallGrp),
      };
      this.props.dispatch(getSmallGroup(smallGroupGuid));
    }
    this.props.dispatch(getOneCallTimeSlots({}));
    // this.props.dispatch(getClientCounselor({}));
    this.props.dispatch(
      spListCombos({
        comboTypes:
          'SGMaterialsFormat,SGBehavioralCategory,SGSamshaClassification,SGAudienceType,SGGender,SGGroupLeaderType,SGGroupTag,SGInstructionModel,SGMeetingFormat,SGMeetingFrequency,SGMeetingLocation,SGStageOfTreatment,WeekDay,PANASScaleMood,SpecialGroupType,SmallGroupMeetingFrequency',
        // UserGuid: this.getUserGuid(),
      }),
    );
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  toggleSuccess = successHeader => {
    this.setState(prevState => ({
      isSuccessModelOpen: !prevState.isSuccessModelOpen,
      successHeader,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({
    smallGroup,
    isInserted,
    mediaguid,
    hostUser,
    isBioInserted,
    constantData,
    isSuccessMsg,
    createdSMGuid,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      let msg = this.state.isWarningMessageRequired
        ? `${isInserted} <br/> <small>${this.state.WarningMessageValues[0].DisplayMessage}</small>`
        : `${isInserted}`;
      this.toggle(msg);

      this.props.dispatch(resetAddSmallGroup());
      // this.props.history.push('/manage/smallgroups');
      this.setState({
        isSuccessMsg,
        createdSMGuid,
      });
    }
    if (isBioInserted && isBioInserted != this.props.isBioInserted) {
      this.toggleSuccess(Upsert_Sucess_Msg);
      this.props.dispatch(resetStaffProBio());
    }
    if (mediaguid && mediaguid != this.props.mediaguid) {
      this.setState({ mediaguid });
    }
    if (smallGroup && smallGroup.SmallGroupGuid != null && this.getSmallGroupGuid()) {
      const data = {
        referralCode: smallGroup.GroupReferralCode,
        samshaClassification: smallGroup.SamshaClassification,
        spBehavioral: smallGroup.SPBehavioralCategory,
        groupTags: smallGroup.GroupTags,
        audience: smallGroup.AudienceType,
        instructionModel: smallGroup.InstructionModel,
        grpLeaderType: smallGroup.GroupLeaderType,
        stageOfTreatment: smallGroup.StageOfTreatment,
        grpLeader: smallGroup.GroupLeader,
        centerId: smallGroup.CenterId,
        gender: smallGroup.Gender,
        groupName: smallGroup.GroupName,
        groupCost: smallGroup.Cost,
        description: smallGroup.Description,
        whatYouWillLearn: smallGroup.WhatYouWillLearn,
        weekDay: smallGroup.DayOfTheWeek,
        hour: smallGroup.TimeOfDay,
        meetingFrequency: smallGroup.MeetingFrequency,
        monthlyRecurring: smallGroup.MonthlyRecurring,
        duration: smallGroup.MeetingDuration,
        meetingFormat: smallGroup.MeetingFormat,
        location: smallGroup.MeetingLocation,
        hostedAddress: smallGroup.MeetingAddress,
        hostedCity: smallGroup.MeetingCity,
        country: smallGroup.MeetingCountry,
        stateOrProvince: smallGroup.MeetingStateOrProvince,
        pANASScaleMoodId: smallGroup.PANASScaleMoodId,
        hostedZip: smallGroup.MeetingZip,
        startDate: smallGroup.StartDate,
        endDate: smallGroup.EndDate,
        acceptNewMember: smallGroup.AcceptNewMembers,
        allowDropIn: smallGroup.AllowDropIns,
        allowGuestPass: smallGroup.AllowGuestPass,
        curriculum: smallGroup.Curriculm,
        curriculumName: smallGroup.NameNecessaryEndings,
        curriculumAuthor: smallGroup.Author,
        getMaterials: smallGroup.GetMaterials,
        materialsFormat: smallGroup.MaterialsFormat,
        materialsCost: smallGroup.MaterialsCost,
        nameGetMaterial: smallGroup.MaterialsName,
        materialsLink: smallGroup.MaterialsLink,
        GroupPictureMediaGuid: smallGroup.GroupPictureMediaGuid,
        isPrivate: smallGroup.IsPrivate,
        isPublished: smallGroup.IsPublished,
        specialGroupType: smallGroup.SpecialGroupType ? smallGroup.SpecialGroupType : 'NA',
        WebPortalURL: smallGroup.WebPortalURL,
        JoinUrl: smallGroup.JoinUrl,
      };
      this.setState({
        smallGroupData: data,
        capacityCount: smallGroup.GroupCapacity,
        passCount: smallGroup.PassesPerGuest || 1,
        GroupPictureMediaGuid: smallGroup.GroupPictureMediaGuid,
        // isLeaderPictureUpload: smallGroup.GroupLeaderProfilePicMediaGuid ? true : false,
        GroupLeaderProfilePicMediaGuid: smallGroup.GroupLeaderProfilePicMediaGuid,
        // mediaguid: smallGroup.GroupLeaderProfilePicMediaGuid,
        profileImgUrl: smallGroup.GroupLeaderProfilePicUrl,
        uploadedProfileImgUrl: smallGroup.GroupUploadedProfilePicUrl,
        IsGroupUploadedProfilePicUrl: smallGroup.IsGroupUploadedProfilePicUrl,
        GroupPictureUrl: smallGroup.GroupPictureUrl,
        WebPortalURL: smallGroup.WebPortalURL,
        JoinUrl: smallGroup.JoinUrl,
        HasPublishAccess: smallGroup.HasPublishAccess,
        ShowPublish: smallGroup.ShowPublish,
        // TextPrivate: constantData.TextPrivate,
        TextPublish: smallGroup.TextPublish,
      });
    }
    this.setState({ TextPrivate: constantData.TextPrivate });
    if (hostUser && !this.getSmallGroupGuid()) {
      this.setState({
        smallGroupData: {
          ...this.state.smallGroupData,
          grpLeader: hostUser.UserGuid,
        },
      });
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  getString = weekDay => {
    if (typeof weekDay == 'string') {
      return weekDay;
    } else {
      return weekDay.toString();
    }
  };
  getArrayType = (data, key) => {
    let array = [];
    if (typeof data === 'string') {
      if (data.indexOf(',') > 0) {
        for (var value of data.split(',')) {
          array.push({ [key]: value });
        }
      } else {
        array = [{ [key]: data }];
      }
    } else if (data.length > 0) {
      for (let item of data) array.push({ [item.ValueColumnName]: item.Value });
    }
    return array;
  };
  getGroupImage = values => {
    this.setState({
      GroupPictureMediaGuid: values.GroupPictureMediaGuid,
    });
  };
  groupLeaderPhoto = type => {
    this.setState({
      isLeaderPictureUpload: type.upload,
      mediaguid: type.mediaguid,
    });
  };
  onSubmit(values) {
    const {
      groupName,
      groupTags,
      description,
      groupCost,
      weekDay,
      monthlyRecurring,
      hour,
      duration,
      referralCode,
      location,
      hostedAddress,
      hostedCity,
      country,
      stateOrProvince,
      hostedZip,
      grpLeaderType,
      grpLeader,
      whatYouWillLearn,
      meetingFormat,
      startDate,
      endDate,
      acceptNewMember,
      allowDropIn,
      allowGuestPass,
      curriculum,
      curriculumName,
      curriculumAuthor,
      gender,
      samshaClassification,
      spBehavioral,
      audience,
      instructionModel,
      stageOfTreatment,
      getMaterials,
      materialsCost,
      nameGetMaterial,
      materialsFormat,
      materialsLink,
      meetingFrequency,
      isPrivate,
      isPublished,
      appProfile,
      uploadedProfile,
      pANASScaleMoodId,
      specialGroupType,
      centerId,
    } = values;
    const {
      passCount,
      capacityCount,
      GroupPictureMediaGuid,
      isLeaderPictureUpload,
      mediaguid,
    } = this.state;
    var smallGroupGuid = null;
    var paramSmallGroupGuid = this.getSmallGroupGuid();
    if (paramSmallGroupGuid && paramSmallGroupGuid != null) {
      smallGroupGuid = paramSmallGroupGuid;
    }
    if (isLeaderPictureUpload && !mediaguid) {
      return;
    }
    // SmallGroupGuid: courseId,
    const data = {
      SmallGroup: [
        {
          SmallGroupGuid: smallGroupGuid,
          GroupReferralCode: referralCode,
          SamshaClassification: samshaClassification,
          // SPBehavioralCategory: spBehavioral,
          SPBehavioralCategory: this.getStringType(spBehavioral),
          GroupTags: this.getStringType(groupTags),
          AudienceType: audience,
          InstructionModel: instructionModel,
          GroupLeaderType: grpLeaderType,
          StageOfTreatment: stageOfTreatment,
          CenterId: centerId,
          GroupLeader: this.getStringType(grpLeader),
          Gender: gender,
          GroupName: groupName.trim(),
          Cost: groupCost,
          Description: description.trim(),
          WhatYouWillLearn: whatYouWillLearn && whatYouWillLearn.trim(),
          DayOfTheWeek: weekDay && this.getString(weekDay),
          // DayOfTheWeek: this.getStringType(weekDay),
          TimeOfDay: hour,
          MonthlyRecurring: monthlyRecurring,
          MeetingFrequency: meetingFrequency,
          MeetingDuration: duration,
          MeetingFormat: meetingFormat,
          MeetingLocation: location,
          MeetingAddress: hostedAddress && hostedAddress.trim(),
          MeetingCity: hostedCity && hostedCity.trim(),
          MeetingCountry: country,
          MeetingStateOrProvince: stateOrProvince,
          PANASScaleMoodId: this.getStringType(pANASScaleMoodId),
          MeetingZip: hostedZip,
          StartDate: FormatDate(startDate),
          EndDate: FormatDate(endDate),
          GroupCapacity: capacityCount,
          AcceptNewMembers: acceptNewMember,
          PassesPerGuest: passCount,
          AllowDropIns: allowDropIn,
          AllowGuestPass: allowGuestPass,
          Curriculm: curriculum,
          NameNecessaryEndings: curriculumName && curriculumName.trim(),
          Author: curriculumAuthor && curriculumAuthor.trim(),
          GetMaterials: getMaterials && getMaterials.trim(),
          MaterialsFormat: materialsFormat,
          MaterialsCost: materialsCost,
          MaterialsName: nameGetMaterial,
          MaterialsLink: materialsLink && materialsLink.trim(),
          GroupPictureMediaGuid: GroupPictureMediaGuid,
          GroupLeaderProfilePicMediaGuid: isLeaderPictureUpload ? mediaguid : null,
          IsPrivate: isPrivate,
          IsPublished: isPublished,
          SpecialGroupType: specialGroupType,
        },
      ],
    };
    let smallGroupData = {
      Json: JSON.stringify(data),
    };
    // var smallGroupData = {
    //   courseId: courseId,
    //   jsonInput: JSON.stringify(data),
    // };
    const me = this;
    const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
    var hearders = {
      'content-type': 'application/json; charset=utf-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      // Accept: 'application/json, text/plain, */*',
      Accept: 'application/json',
    };
    fetch(`/api/v1/SmallGroups/CheckStaffProfessionalBio`, {
      method: 'POST',
      headers: hearders,
      // responseType: 'application/json',
      body: JSON.stringify({
        Json: JSON.stringify({
          SmallGroup: [
            {
              GroupLeader: this.getStringType(grpLeader),
            },
          ],
        }),
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(response => {
        let responseData = JSON.parse(response.data)[0];
        let isUpdateRequired = responseData.ProfessionalBioValues[0].IsUpdateRequired;
        me.setState({
          isFetching: false,
          responseData: responseData,
          isProfessionalBioReq: isUpdateRequired,
          ProfessionalBioValues: responseData.ProfessionalBioValues,
          SmallGroupValues: responseData.SmallGroup,
          WarningMessageValues: responseData.WarningMessage,
          isWarningMessageRequired: responseData.WarningMessage[0].IsWarningMessageRequired,
        });
        if (!isUpdateRequired) {
          // if (GroupPictureMediaGuid && isLeaderPictureUpload && mediaguid)
          if (GroupPictureMediaGuid) this.props.dispatch(addSmallGroup(smallGroupData));
        }
      })
      .catch(error => {
        console.log(error);
        alert(JSON.stringify(error));
      });

    // if (GroupPictureMediaGuid) this.props.dispatch(addSmallGroup(smallGroupData));
  }

  render() {
    const { timeSlots, durations, isFetching, isFetchingSM } = this.props;
    const {
      capacityCount,
      passCount,
      GroupPictureMediaGuid,
      mediaguid,
      GroupLeaderProfilePicMediaGuid,
      profileImgUrl,
      uploadedProfileImgUrl,
      IsGroupUploadedProfilePicUrl,
      GroupPictureUrl,
      WebPortalURL,
      HasPublishAccess,
      ShowPublish,
      TextPrivate,
      TextPublish,
      JoinUrl,
    } = this.state;
    const me = this;
    return (
      <React.Fragment>
        <Loader loading={isFetching} />
        <ReduxCreateGroupForm
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.state.smallGroupData}
          onCancel={() => this.props.history.push('/manage/smallgroups')}
          smallGroupGuid={this.getSmallGroupGuid()}
          onPassCount={this.onPassCount}
          onGroupCapacity={this.onGroupCapacity}
          getGroupImage={this.getGroupImage}
          groupLeaderPhoto={this.groupLeaderPhoto}
          smallGroupProps={{
            capacityCount,
            passCount,
            GroupPictureMediaGuid: GroupPictureMediaGuid,
            mediaguid: mediaguid,
            GroupLeaderProfilePicMediaGuid: GroupLeaderProfilePicMediaGuid,
            profileImgUrl: profileImgUrl,
            uploadedProfileImgUrl: uploadedProfileImgUrl,
            IsGroupUploadedProfilePicUrl: IsGroupUploadedProfilePicUrl,
            GroupPictureUrl: GroupPictureUrl,
            WebPortalURL: WebPortalURL,
            HasPublishAccess: HasPublishAccess,
            ShowPublish: ShowPublish,
            TextPrivate: TextPrivate,
            TextPublish: TextPublish,
            JoinUrl: JoinUrl,
          }}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/smallgroups')
          }}
          isDeactivated={this.props.smallGroup.IsDeActivated}
        />
        {this.state.isProfessionalBioReq && (
          <Modal
            zIndex={100}
            visible={this.state.isProfessionalBioReq}
            footer={null}
            closable={false}
          >
            <ProfessionalBioModal
              {...this.props}
              SmallGroupValues={this.state.SmallGroupValues}
              professionalBioValues={this.state.ProfessionalBioValues}
              onCancel={() => {
                this.setState({
                  isProfessionalBioReq: false,
                });
              }}
            />
          </Modal>
        )}

        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            changeMaxWidth={this.state.isWarningMessageRequired && '550px'}
            yesAction={() => {
              if (!me.props.smallGroup.IsDeActivated && me.state.isSuccessMsg) {
                me.props.history.push('/manage/smallgroups');
              } else {
                if (me.getSmallGroupGuid() || me.state.createdSMGuid) {
                  me.props.dispatch(
                    getSmallGroup({
                      Json: JSON.stringify({
                        SmallGroup: [
                          {
                            SmallGroupGuid: me.getSmallGroupGuid() || me.state.createdSMGuid,
                          },
                        ],
                      }),
                    }),
                  );
                  window.scrollTo(0, 0);
                }
              }
              me.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}

        {this.state.isSuccessModelOpe && (
          <CustomModal
            isOpen={this.state.isSuccessModelOpen}
            header={this.state.successHeader}
            yesAction={() => {
              this.toggleSuccess();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}

        {this.state.isRemoveModelOpen && (
          <CustomModal
            isOpen={this.state.isRemoveModelOpen}
            header={Delete_Confirmation_Msg}
            yesAction={() => {
              this.Removetoggle();
            }}
            yesText="submit"
            noText="cancel"
            noAction={() => {
              this.Removetoggle();
              this.props.history.push('/manage/smallgroups');
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

class CreateGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popVisible: false,
      toggleVisible: false,
      capacityCount: 2,
      passCount: 1,
      sliderValue: 0,
      loading: false,
      profileImgUrl: '',
      existing: true,
      upload: false,
      // chars_left: max_chars,
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      smUsers: 'All',
      grp_chars_left: 35,
      Desc_chars_left: 200,
      learn_chars_left: 200,
      isProfessionalBioReq: false,
      files: [],
      twilioToken: 'Fetching Token ....',
      callModelVisible: false,
      clickedRecord: {},
    };
  }

  componentWillReceiveProps({
    smallGroupProps,
    isSuccess,
    errorMessage,
    successMessage,
    smNameValue,
    descriptionValue,
    learnValue,
    initialValues,
  }) {
    if (JSON.stringify(smallGroupProps) != JSON.stringify(this.props.smallGroupProps)) {
      if (
        smallGroupProps.uploadedProfileImgUrl &&
        smallGroupProps.uploadedProfileImgUrl != this.props.smallGroupProps.uploadedProfileImgUrl
      ) {
        this.setState({
          ...smallGroupProps,
          existing: !smallGroupProps.IsGroupUploadedProfilePicUrl,
          upload: smallGroupProps.IsGroupUploadedProfilePicUrl,
          files: [
            {
              name: 'GroupLeaderUploadedProfile.png',
              size: 20000,
              preview: smallGroupProps.uploadedProfileImgUrl || null,
            },
          ],
        });
      } else {
        this.setState({
          ...smallGroupProps,
        });
      }
    }
    if (
      initialValues &&
      JSON.stringify(initialValues) != JSON.stringify(this.props.initialValues)
    ) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: initialValues.country,
        }),
      );
    }

    if (isSuccess && isSuccess != this.props.isSuccess) {
      if (this.state.isReSendInvitaion) {
        this.setState({
          isResendModelOpen: true,
          successMessage: successMessage,
        });
      }
    }

    if (smNameValue) {
      this.setState({
        grp_chars_left: 35 - smNameValue.length,
      });
    } else {
      this.setState({
        grp_chars_left: 35 - 0,
      });
    }
    if (descriptionValue) {
      this.setState({
        Desc_chars_left: 200 - descriptionValue.length,
      });
    } else {
      this.setState({
        Desc_chars_left: 200 - 0,
      });
    }

    if (learnValue) {
      this.setState({
        learn_chars_left: 200 - learnValue.length,
      });
    } else {
      this.setState({
        learn_chars_left: 200 - 0,
      });
    }
  }
  getPassCount = e => {
    let inputNum = e.target.value;
    let pat = /^[0-9]{0,3}$/;
    if (inputNum.match(pat)) {
      inputNum = parseInt(inputNum);
      this.setState(
        {
          passCount: inputNum || 0,
        },
        this.props.onPassCount(inputNum),
      );
    }
  };
  getCapacityCount = e => {
    let inputNum = e.target.value;
    let pat = /^[0-9]{0,3}$/;
    if (inputNum.match(pat) && inputNum > 2) {
      inputNum = parseInt(inputNum);
      this.setState(
        {
          capacityCount: inputNum || 0,
        },
        this.props.onGroupCapacity(inputNum),
      );
    }
  };
  countryChange = (countryISO, currentIndex) => {
    if (countryISO) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
  };
  handleSelect = img => {
    this.setState({
      GroupPictureMediaGuid: img,
      groupPictureErrorMsg: '',
    });
    this.props.getGroupImage({
      GroupPictureMediaGuid: img,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  reSendInvitaion = record => {
    let data = {
      SmallGroup: [
        {
          SmallGroupUserGuid: record.SmallGroupUserGuid,
          ReferralCode: record.ReferralCode,
          Action: record.ButtonText,
        },
      ],
    };
    let voucherData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(sendVoucherCode(voucherData));
    this.state.isReSendInvitaion = true;
  };

  onFilterChange = value => {
    this.setState({
      smUsers: value,
    });
    const { dispatch, smallGroupGuid } = this.props;
    let filterData = {
      Json: JSON.stringify({
        SmallGroup: [{ SmallGroupGuid: smallGroupGuid, FilterBy: value !== 'All' ? value : null }],
      }),
    };
    dispatch(getSmallGroupUsers(filterData));
  };
  hide = () => {
    this.setState({
      popVisible: false,
    });
  };

  handleVisibleChange = popVisible => {
    this.setState({ popVisible });
  };
  toggleCheckbox = checkbox => {
    if (checkbox.target.name === 'existing') {
      this.setState({
        existing: true,
        upload: false,
      });
      this.props.groupLeaderPhoto({
        existing: true,
        upload: false,
      });
    } else {
      this.setState({
        existing: false,
        upload: true,
      });
      this.props.groupLeaderPhoto({
        existing: false,
        upload: true,
      });
    }
  };
  copyTextToClipboard = () => {
    const context = this.textArea;
    context.select();
    document.execCommand('copy');
    // Change state to false
    this.setState({ copied: !this.state.copied });
    // Change state after 3 seconds
    setTimeout(() => {
      return this.setState({ copied: !this.state.copied });
    }, 3000);
  };

  copyTextToClipboard2 = () => {
    const context = this.textArea2;
    context.select();
    document.execCommand('copy');
    // Change state to false
    this.setState({ copied2: !this.state.copied2 });
    // Change state after 3 seconds
    setTimeout(() => {
      return this.setState({ copied2: !this.state.copied2 });
    }, 3000);
  };

  onDrop = files => {
    let me = this;
    let dataList = [];
    files.map((obj, index) => {
      Object.assign(obj, {
        preview: URL.createObjectURL(obj),
      });
      getBase64(obj, uploadedData => {
        // URL.revokeObjectURL(obj.preview);
        dataList.push({
          name: obj.name,
          size: obj.size,
          // uploadedData: uploadedData,
          preview: obj.preview,
        });
        this.props.dispatch(
          uploadLeaderPhoto({
            image: uploadedData,
          }),
        );
        this.props.groupLeaderPhoto({ upload: true, existing: false });
        // let newData = [...me.state.uploadedDataList, ...dataList];
        me.setState({ files: dataList, existing: false, upload: true, groupLeaderErrorMsg: '' });
      });
      // URL.revokeObjectURL(obj.preview);
    });
  };

  resetIsOpen = () => {
    this.setState({
      // twilioToken: '',
      callModelVisible: false,
      clickedRecord: {},
      // device: '',
    });
  };

  render() {
    const {
      onSaveClick,
      onSubmit,
      handleSubmit,
      course,
      initialize,
      Staff,
      Language,
      smallGroupGuid,
      initialValues,
      onCancel,
      Delete,
      history,
      dispatch,
      StateProvince,
      Country,
      Center,
      ladder,
      timeSlots,
      counsellors,
      durations,
      isGetMaterials,
      isAllowGuestPass,
      spListCombo,
      isCurriculum,
      onPassCount,
      onGroupCapacity,
      startDateValue,
      meetingFormatValue,
      isDeactivated,
      groupPicture,
      meetingFrequencyValue,
      countryValue,
      cost,
      smallGroupUser,
      hostUser,
      isFetchingUsers,
      isFetchingSM,
      clearFields,
    } = this.props;
    const { capacityCount, passCount, existing } = this.state;
    const {
      SGAudienceType,
      SGGender,
      SGGroupLeaderType,
      SGGroupTag,
      SGInstructionModel,
      SGMeetingFormat,
      SGMeetingFrequency,
      SGMeetingLocation,
      SGStageOfTreatment,
      SGSamshaClassification,
      SGBehavioralCategory,
      SGMaterialsFormat,
      WeekDay,
      PANASScaleMood,
      SpecialGroupType,
      SmallGroupMeetingFrequency,
    } = spListCombo;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        sorter: (a, b) => isComparer(a.Name, b.Name),
        sortDirections: ['descend', 'ascend'],
        width: '200px',
      },
      {
        title: 'Phone/Email',
        dataIndex: 'Phone',
        key: 'Phone',
        width: 150,
        sorter: (a, b) => isNumericComparer(a.Phone, b.Phone),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="d-flex">
              <div
                class="tools-grid"
                onClick={event => {
                  if (record.IsVoiceCallAllowed) {
                    this.setState({ callModelVisible: true, clickedRecord: record });
                    // Axios.post('CallToken/Generate', {}).then(response => {
                    //   if (response && response.data.token) {
                    //     this.setState({
                    //       twilioToken: response.data.token,
                    //       callModelVisible: true,
                    //       clickedRecord: record,
                    //     });
                    //   }
                    // });
                    // this.props.dispatch(generateCallToken({}));
                    event.stopPropagation();
                  } else {
                    alert('VOIP call not allowed');
                    event.stopPropagation();
                  }
                }}
                style={{ cursor: 'pointer', width: '125px', marginRight: '10px' }}
              >
                <p
                  className="d-flex justify-content-around"
                  style={record.IsVoiceCallAllowed ? { color: '#46B4FE' } : { color: 'black' }}
                >
                  {' '}
                  {record.IsVoiceCallAllowed ? (
                    <img
                      style={{ cursor: 'pointer', display: 'block' }}
                      src={`images/twilioPhoneAllowed.svg`}
                      alt="Calling Allowed"
                      width="10px"
                    />
                  ) : null}
                  {record.Phone}
                </p>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Invite Date',
        dataIndex: 'InviteDate',
        key: 'InviteDate',
        sorter: (a, b) => isComparer(a.InviteDate, b.InviteDate),
        sortDirections: ['descend', 'ascend'],
        width: '150px',
      },
      {
        title: 'Join Date',
        dataIndex: 'JoinDate',
        key: 'JoinDate',
        sorter: (a, b) => isComparer(a.JoinDate, b.JoinDate),
        sortDirections: ['descend', 'ascend'],
        width: '150px',
      },
      {
        title: 'Is Guest',
        dataIndex: 'IsGuest',
        key: 'IsGuest',
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{text.toString()}</span>
            </div>
          );
        },
        sorter: (a, b) => isComparer(a.IsGuest, b.IsGuest),
        sortDirections: ['descend', 'ascend'],
        width: 100,
      },
      {
        title: 'Cancel Date',
        dataIndex: 'CancelDate',
        key: 'CancelDate',
        sorter: (a, b) => isComparer(a.CancelDate, b.CancelDate),
        sortDirections: ['descend', 'ascend'],
        width: 100,
      },
      {
        title: 'Referral Type',
        dataIndex: 'ReferralType',
        key: 'ReferralType',
        sorter: (a, b) => isComparer(a.ReferralType, b.ReferralType),
        sortDirections: ['descend', 'ascend'],
        width: '150px',
      },
      {
        title: 'Referral Code',
        dataIndex: 'ReferralCode',
        key: 'ReferralCode',
        sorter: (a, b) => isComparer(a.ReferralCode, b.ReferralCode),
        sortDirections: ['descend', 'ascend'],
        width: 100,
      },
      {
        title: 'Is Free',
        dataIndex: 'IsFree',
        key: 'IsFree',
        sorter: (a, b) => isComparer(a.IsFree, b.IsFree),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="license-invoice">
              <span className="">{text.toString()}</span>
            </div>
          );
        },
        width: 100,
        // sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
        // sortDirections: ['descend', 'ascend'],
      },
      {
        title: '',
        key: 'ButtonText',
        dataIndex: 'ButtonText',
        width: '260px',
        render: (text, record) => {
          if (record.IsButton) {
            return (
              <AntdModal
                ModalButton={({ onClick }) => (
                  <button
                    class="btn py-1"
                    data-toggle="modal"
                    data-target="#deactivate-staff-model"
                    onClick={onClick}
                  >
                    {text}
                  </button>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to <br />
                        <strong>{text}</strong> ?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn  black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          // dispatch(staffDelete({ staffId: getStaffId }));
                          this.reSendInvitaion(record);
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            );
          } else return <span className="">{text}</span>;
        },
        // render: (text, record) => {
        //   if (record.IsButton) {
        //     return (
        //       <button
        //         onClick={event => {
        //           this.reSendInvitaion(record);
        //         }}
        //         class="btn py-1"
        //       >
        //         {text}
        //       </button>
        //     );
        //   } else return <span className="">{text}</span>;
        // },
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onManageClick(record.CenterId),
        //   };
        // },
      },
    ];
    let groupPictureObj = groupPicture.find(o => o.MediaGuid === this.state.GroupPictureMediaGuid);
    const remove = index => {
      const newFiles = [...this.state.files]; // make a var for the new array
      newFiles.splice(index, 1); // remove the file from the array
      this.setState({
        // files: newFiles,
        files: [],
        mediaguid: null,
        existing: true,
        upload: false,
      });
      this.props.groupLeaderPhoto({
        existing: true,
        upload: false,
        mediaguid: null,
      });
    };
    const thumbs = this.state.files.map((file, index) => {
      return (
        <div className="d-flex">
          <div className="thumb" key={file.name}>
            <div className="thumbInner">
              <img src={file.preview} className="img" />
            </div>
          </div>
          <div className="leader-img-name-block">
            <span classname="leader-img-name d-block"> {file.name ? file.name : ''}</span>
            <br />
            <span className="leader-img-size">{ByteToSize(file.size ? file.size : '')}</span>
          </div>
          <Tooltip placement="top" title="Remove">
            <span class="delete-course " onClick={() => remove(index)}>
              <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                <img src="images/circle-cross.svg" alt="" width="35" />
              </figure>
            </span>
          </Tooltip>
        </div>
      );
    });
    const Clearweekdays = e => {
      // clearFields(false, ...['weekDay']);
      this.props.change(`weekDay`, null);
    };
    return (
      <div class="main enrollment-page mb-5">
        <div className="container-fluid">
          <Loader loading={isFetchingSM} />
          <form onSubmit={handleSubmit(onSaveClick)}>
            <div class="row top-table-search mt-5 mb-3 align-items-center">
              <div class="col-12 col-md-4 col-lg-10">
                <a
                  class="back-link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.history.goBack()}
                >
                  <img src="images/back-arrow.svg" /> Back
                </a>
                <h4 class="form-main-head">
                  {smallGroupGuid != null ? 'Edit a Small Group' : 'Create a Small Group'}
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-4">
                <div class="card border-0 rounded-0 ">
                  <div class="card-body enrollment-form small-Group-form ">
                    <div className="row pb-5 px-3 pt-3">
                      <div class="row">
                        <div class="d-flex mx-3 mb-2 w-100 justify-content-between">
                          <h5>Group Information </h5>{' '}
                        </div>
                        <div class="col-md-10 col-xl-6 mb-3">
                          <div className="group-image-bg">
                            <h6>Select Group Picture</h6>
                            <div className="group-picture">
                              {groupPictureObj ? (
                                <div className="d-block">
                                  <img
                                    src={groupPictureObj.MediaUrl}
                                    style={{
                                      // width: '91px',
                                      height: '91px',
                                      border: ' solid 1px #000000',
                                    }}
                                  />
                                  {!isDeactivated && (
                                    <Tooltip placement="top" title="Remove">
                                      <span
                                        class="delete-course "
                                        onClick={() => {
                                          this.setState({
                                            GroupPictureMediaGuid: null,
                                          });
                                          this.props.getGroupImage({
                                            GroupPictureMediaGuid: null,
                                            // groupPictureErrorMsg: this.state.groupPictureErrorMsg,
                                          });
                                        }}
                                      >
                                        {/* <i class="fas fa-times" /> */}
                                        <figure
                                          class="action-button-icon"
                                          style={{ display: 'inline-block' }}
                                        >
                                          <img src="images/circle-cross.svg" alt="" width="35" />
                                        </figure>
                                      </span>
                                    </Tooltip>
                                  )}
                                </div>
                              ) : (
                                <CustomModal1
                                  modalWidth={800}
                                  ModalButton={({ onClick }) =>
                                    !isDeactivated ? (
                                      <p onClick={onClick}>
                                        Drag and Drop here or <span>Browse Files</span>{' '}
                                      </p>
                                    ) : (
                                      <span>Select Group Picture (Disabled)</span>
                                    )
                                  }
                                  ModalContent={props => (
                                    <SelectGroupPictureModal
                                      {...props}
                                      groupPicture={groupPicture}
                                      handleSelect={this.handleSelect}
                                    />
                                  )}
                                />
                              )}
                            </div>
                            <span className="validation-error">
                              {this.state.groupPictureErrorMsg}
                            </span>
                          </div>
                        </div>
                        <div class="col-md-10 col-xl-6 mb-3">
                          <div className="profile-image-bg">
                            <h6>Group Leader Profile Picture</h6>

                            <div className="existing">
                              {!isDeactivated && (
                                <Radio
                                  name="existing"
                                  onClick={this.toggleCheckbox}
                                  checked={this.state.existing}
                                  // checked={this.state.profileImgUrl && this.state.existing}
                                  // disabled={!this.state.profileImgUrl}
                                />
                              )}

                              <div className="existing-picture">
                                <img
                                  src={this.state.profileImgUrl || 'images/blank-profile.png'}
                                  class="rounded-circle face-fit-cover mr-2"
                                  width="47"
                                  height="47"
                                  alt=""
                                />
                                <span className="existing-text">Use default profile picture</span>
                              </div>
                            </div>
                            <div className="upload">
                              {!isDeactivated && (
                                <Radio
                                  name="upload"
                                  onClick={this.toggleCheckbox}
                                  checked={this.state.upload}
                                />
                              )}
                              <div className="clearfix">
                                <Dropzone
                                  onDrop={this.onDrop}
                                  noDrag={true}
                                  accept=".jpg,.jpeg,.png"
                                  maxSize={2097152}
                                  maxFiles={1}
                                  multiple={false}
                                  disabled={isDeactivated}
                                >
                                  {({
                                    getRootProps,
                                    getInputProps,
                                    acceptedFiles,
                                    fileRejections,
                                  }) => (
                                    <section className="container">
                                      {this.state.files.length < 1 ? (
                                        <div
                                          {...getRootProps({
                                            className: 'dropzone upload-leader-profile',
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <div
                                            className="d-flex align-items-baseline justify-content-center"
                                            style={{ marginTop: '15px' }}
                                          >
                                            <p
                                              className="dropzone-upload-drag-icon mr-2"
                                              style={{ marginBottom: '0' }}
                                            >
                                              <Icon type="upload" />
                                            </p>
                                            <p
                                              className="dropzone-upload-text"
                                              style={{ marginBottom: '0' }}
                                            >
                                              {' '}
                                              Upload New
                                            </p>
                                          </div>
                                          <p>
                                            Drag and Drop here or
                                            <p
                                              class="d-inline ml-2"
                                              style={{
                                                color: '#4ab0fe',
                                                cursor: 'pointer',
                                                marginBottom: '0',
                                              }}
                                            >
                                              Browse Files
                                            </p>
                                          </p>
                                        </div>
                                      ) : (
                                        <aside className="thumbsContainer">
                                          <div className="group-leader-container">{thumbs}</div>
                                        </aside>
                                      )}
                                      {fileRejections && fileRejections.length > 0 && (
                                        <div className="">
                                          {fileRejections.map(({ file, errors }) => {
                                            return (
                                              <div>
                                                {errors.map(e => (
                                                  <span key={e.code} className="validation-error">
                                                    {e.message}
                                                  </span>
                                                ))}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                      <span className="validation-error">
                                        {this.state.groupLeaderErrorMsg}
                                      </span>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </div>

                            {/* <span className="validation-error">
                              {this.state.groupLeaderErrorMsg}
                            </span> */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="groupName"
                              type="text"
                              component={ReduxInput}
                              maxLength={35}
                              errorMessage="Name of Small Group Required"
                              validate={Validator.requiredInput}
                              fieldName={'Group Name'}
                              disabled={isDeactivated}
                            />
                            <div className="remaining-char">
                              <span>{this.state.grp_chars_left} / 35</span>
                            </div>
                            <label class="placeholder-label">Group Name*</label>
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name={`grpLeader`}
                              type="text"
                              placeholder="Group Leader(s) Name*"
                              component={ReduxSelect}
                              validate={[Validator.leaderRequired, Validator.maxCount]}
                              options={counsellors}
                              fieldName={'Group Leader(s)'}
                              isMulti
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="centerId"
                              type="text"
                              component={ReduxSelect}
                              options={Center}
                              placeholder="Center"
                              // validate={Validator.required}
                              fieldName={'Center'}
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="referralCode"
                              type="text"
                              component={ReduxInput}
                              // component={ReduxSelect}
                              // options={durations}
                              // placeholder="Group Promo Code*"
                              // validate={Validator.required}
                              fieldName={'Referral Code'}
                              disabled={true}
                            />
                            <label class="placeholder-label">Group Referral Code</label>
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="startDate"
                              type="text"
                              component={ReduxDateInput}
                              errorMessage="Start Date Required"
                              validate={Validator.required}
                              label={'Start Date*'}
                              minDate={new Date()}
                              disabled={isDeactivated}
                            />
                            <i class="far fa-calendar-alt form-control-icon" />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          {meetingFrequencyValue != 'one-time' && (
                            <div class="form-group">
                              <Field
                                name="endDate"
                                type="text"
                                // disabled={!startDateValue}
                                component={ReduxDateInput}
                                // errorMessage="End Date Required"
                                validate={[Validator.required, Validator.endDate]}
                                label={'End Date*'}
                                fieldName={'End Date'}
                                // minDate={new Date(2016, 0, 1)}
                                disabled={isDeactivated}
                              />
                              <i class="far fa-calendar-alt form-control-icon" />
                            </div>
                          )}
                        </div>

                        <div class="col-12">
                          <div className="row">
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="meetingFrequency"
                                  type="text"
                                  component={ReduxSelect}
                                  options={SmallGroupMeetingFrequency}
                                  placeholder="Meeting Frequency*"
                                  validate={Validator.required}
                                  fieldName={'Meeting Frequency'}
                                  disabled={isDeactivated}
                                  onChange={val => val === 'one-time' && Clearweekdays()}
                                />
                              </div>
                            </div>

                            {(meetingFrequencyValue === 'weekly' ||
                              meetingFrequencyValue === 'one-time') && (
                              <div className="recurring-block">
                                <div className="week-days">
                                  <div class="col-12">
                                    <div
                                      style={{
                                        flexFlow: 'wrap',
                                        display: 'flex',
                                      }}
                                    >
                                      <Field
                                        name="weekDay"
                                        component={CheckboxGroup}
                                        col={3}
                                        widthClassName={'w-75'}
                                        blockDays={
                                          startDateValue
                                            ? JSON.stringify(
                                                parseInt(moment(startDateValue).format('d')) + 1,
                                              )
                                            : null
                                        }
                                        options={weekDays}
                                        disabled={meetingFrequencyValue === 'one-time'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* {meetingFrequencyValue === 'weekly' && (
                              <div class="col-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                  <Field
                                    name="weekDay"
                                    validate={Validator.required}
                                    type="text"
                                    component={ReduxSelect}
                                    placeholder="Day(s) of the week*"
                                    options={WeekDay}
                                    fieldName={'Week Day'}
                                    disabled={isDeactivated}
                                    isMulti
                                  />
                                </div>
                              </div>
                            )}
                            {meetingFrequencyValue === 'one-time' && (
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                  <Field
                                    name="weekDay"
                                    validate={Validator.required}
                                    type="text"
                                    component={ReduxSelect}
                                    placeholder="Day(s) of the week*"
                                    options={WeekDay}
                                    fieldName={'Week Day'}
                                    disabled={isDeactivated}
                                    // isMulti={meetingFrequencyValue === 'one-time' ? false : true}
                                  />
                                </div>
                              </div>
                            )} */}

                            {meetingFrequencyValue === 'biweekly' && (
                              <div class="col-12 col-md-8">
                                <div className="d-flex sg-frequenncy">
                                  <div className="recurring-block">
                                    <span className="recurring-block-label">Day of the week</span>
                                    <p className="recurring-block-value">
                                      {moment(startDateValue).format('dddd')}
                                    </p>
                                  </div>
                                  <div className="recurring-block">
                                    <div className="bi-week-note">
                                      <span className="note-text">
                                        <strong>Note</strong>: Note: Bi-weekly meetings are
                                        automatically scheduled to reoccur every alternate week.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {meetingFrequencyValue === 'MonthlyOnce' && (
                              <>
                                <div class="col-12 col-md-4 sg-frequenncy">
                                  <div className="recurring-block">
                                    <span className="recurring-block-label">Day of the week</span>
                                    <p className="recurring-block-value">
                                      {moment(startDateValue).format('dddd')}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-12 col-md-4">
                                  <div class="form-group mb-2">
                                    <Field
                                      name="monthlyRecurring"
                                      type="text"
                                      component={ReduxSelect}
                                      fieldName={'Occurrence in the month'}
                                      placeholder={'Occurrence in the month'}
                                      options={MonthOccurance}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="duration"
                              type="text"
                              component={ReduxSelect}
                              options={durations}
                              placeholder="Duration*"
                              validate={Validator.required}
                              fieldName={'Duration'}
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            {/* <label class="placeholder-label">Hour*</label> */}
                            <Field
                              name="hour"
                              type="text"
                              component={ReduxSelect}
                              options={timeSlots}
                              placeholder="Time of Day*"
                              validate={Validator.required}
                              fieldName={'Time of Day'}
                              disabled={isDeactivated}
                            />
                            {/* <label class="placeholder-label">Hour*</label> */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="groupTags"
                              class="custom-select enrollment-select"
                              component={ReduxSelect}
                              placeholder="Tags to identify the Group*"
                              options={SGGroupTag}
                              validate={Validator.required}
                              fieldName={'Tags'}
                              isMulti
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="meetingFormat"
                              type="text"
                              component={ReduxSelect}
                              options={SGMeetingFormat}
                              placeholder="Meeting Format*"
                              validate={Validator.required}
                              fieldName={'Meeting Format'}
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>
                        {meetingFormatValue != 12601 && (
                          <div class="col-12 col-md-6 col-lg-4">
                            <div class="form-group">
                              <Field
                                name="hostedAddress"
                                type="text"
                                component={ReduxInput}
                                errorMessage="Meeting Address Required"
                                validate={Validator.requiredInput}
                                fieldName={'Meeting Address'}
                                disabled={isDeactivated}
                              />
                              <label class="placeholder-label">Meeting Address*</label>
                            </div>
                          </div>
                        )}

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name={`country`}
                              type="text"
                              errorMessage="Country Required"
                              component={ReduxSelect}
                              options={Country}
                              validate={Validator.required}
                              onChange={countryISO => {
                                this.countryChange(countryISO);
                                this.props.change(`stateOrProvince`, null);
                              }}
                              placeholder="Country"
                              fieldName={'Country'}
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="hostedCity"
                              type="text"
                              component={ReduxInput}
                              errorMessage="City Name Required"
                              validate={Validator.requiredInput}
                              fieldName={'City Name'}
                              disabled={isDeactivated}
                            />
                            <label class="placeholder-label">City*</label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name={`stateOrProvince`}
                              type="text"
                              component={ReduxSelect}
                              options={StateProvince}
                              placeholder="State/Province/Territories*"
                              fieldName="State/Province/Territories"
                              validate={Validator.required}
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="hostedZip"
                              type="text"
                              component={ReduxInput}
                              errorMessage="Zip Required"
                              validate={
                                countryValue === 'USA'
                                  ? [Validator.required, Validator.usaZip]
                                  : [Validator.required, Validator.zip]
                              }
                              fieldName={'Zip'}
                              disabled={isDeactivated}
                            />
                            <label class="placeholder-label">Zip*</label>
                          </div>
                        </div>

                        <div class="col-12 mt-3 pt-2">
                          <div class="form-group">
                            <Field
                              name="description"
                              type="textarea"
                              component={ReduxInput}
                              style={{ minHeight: '80px' }}
                              validate={Validator.requiredInput}
                              rows="3"
                              maxLength={200}
                              fieldName={'Description'}
                              disabled={isDeactivated}
                            />
                            <div className="remaining-char">
                              <span>{this.state.Desc_chars_left} / 200</span>
                            </div>
                            <label class="placeholder-label">Description*</label>
                          </div>
                        </div>
                        <div class="col-12 mt-3 pt-2">
                          <div class="form-group">
                            <Field
                              name="whatYouWillLearn"
                              type="textarea"
                              component={ReduxInput}
                              style={{ minHeight: '80px' }}
                              maxLength={200}
                              validate={Validator.requiredInput}
                              rows="3"
                              fieldName={'What You Will Learn'}
                              disabled={isDeactivated}
                            />
                            <div className="remaining-char">
                              <span>{this.state.learn_chars_left} / 200</span>
                            </div>
                            <label class="placeholder-label">What You Will Learn*</label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            {/* <div class="input-group mb-3 input-cost">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  style={{ backgroundColor: 'white', border: 'unset' }}
                                >
                                  $
                                </span>
                              </div>
                              <Field
                                name={`groupCost`}
                                component={ReduxInput}
                                validate={[Validator.required, Validator.cost]}
                                fieldName={'Group Cost'}
                              />
                              <label class="placeholder-label" style={{ zIndex: 3, left: 40 }}>
                                Cost{' '}
                                {meetingFrequencyValue != 'one-time' ? '(Monthly)' : '(one-time)'}*
                              </label>
                            </div> */}
                            <Field
                              name={`groupCost`}
                              type="text"
                              component={ReduxSelect}
                              options={cost}
                              placeholder={`Cost ${
                                meetingFrequencyValue != 'one-time' ? '(Monthly)' : '(one-time)'
                              }`}
                              fieldName="Group Cost"
                              // validate={Validator.required}
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="license-quantity d-flex align-items-center justify-content-center">
                            <label class="placeholder-label flex-grow-1">Group Capacity</label>
                            <div class="quantity-btn flex-grow-1">
                              <div class="numbers-row">
                                <input
                                  name={'groupCapacity'}
                                  class="number-icon"
                                  type="text"
                                  min="0"
                                  max="3"
                                  value={capacityCount}
                                  onChange={this.getCapacityCount}
                                  validate={Validator.minValue}
                                  tabindex="0"
                                  style={{
                                    textAlign: 'center',
                                    margin: '0px 5px',
                                    height: '32px',
                                    width: '96px',
                                    fontSize: '16px',
                                  }}
                                  disabled={isDeactivated}
                                />
                                <div
                                  style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                                  class="add quantity-button"
                                  onClick={() => {
                                    if (!isDeactivated)
                                      if (capacityCount <= 998) {
                                        return this.setState(
                                          { capacityCount: capacityCount + 1 },
                                          onGroupCapacity(capacityCount + 1),
                                        );
                                      } else {
                                        return;
                                      }
                                  }}
                                >
                                  +
                                </div>
                                <div
                                  style={{ borderRadius: '0px', height: '32px', width: '32px' }}
                                  class="remove quantity-button"
                                  onClick={() => {
                                    const val = capacityCount - 1;
                                    if (val < 2) return;
                                    if (!isDeactivated)
                                      this.setState(
                                        {
                                          capacityCount: val,
                                        },
                                        onGroupCapacity(val),
                                      );
                                  }}
                                >
                                  -
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="custom-control-label">
                              Accept New Members?{' '}
                              <Field
                                name="acceptNewMember"
                                type="text"
                                class="custom-control-input not-empty"
                                component={ReduxToggle}
                                disabled={isDeactivated}
                              />
                              {'   '}
                            </label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="gender"
                              type="text"
                              component={ReduxSelect}
                              options={SGGender}
                              placeholder="Gender*"
                              validate={Validator.required}
                              fieldName={'Gender'}
                              disabled={isDeactivated}
                            />
                          </div>
                        </div>

                        {/* <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="location"
                              type="text"
                              component={ReduxSelect}
                              options={SGMeetingLocation}
                              placeholder="Location Type"
                              // validate={Validator.required}
                              fieldName={'Location'}
                            />
                          </div>
                        </div> */}

                        {/* <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <label class="custom-control-label">
                              Allow Drop In?{' '}
                              <Field
                                name="allowDropIn"
                                type="text"
                                class="custom-control-input not-empty"
                                component={ReduxToggle}
                                disabled={isDeactivated}
                              />
                              {'   '}
                            </label>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <div className="row mb-5">
                            <div class="col-12">
                              <div class="form-group">
                                <label class="custom-control-label">
                                  Allow Guest Pass?{' '}
                                  <Field
                                    name="allowGuestPass"
                                    type="text"
                                    class="custom-control-input not-empty"
                                    component={ReduxToggle}
                                    disabled={isDeactivated}
                                  />
                                  {'   '}
                                </label>
                              </div>
                            </div>
                            {isAllowGuestPass && (
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="license-quantity d-flex align-items-center justify-content-center">
                                  <label class="placeholder-label flex-grow-1">
                                    Passes per Guest
                                  </label>
                                  <div class="quantity-btn flex-grow-1 mb-0">
                                    <div class="numbers-row">
                                      <input
                                        name={'passPerGuest'}
                                        class="number-icon"
                                        type="text"
                                        min="0"
                                        max="3"
                                        value={passCount}
                                        // onChange={this.getPassCount}
                                        // validate={[Validator.allowNumericMobile]}
                                        disabled={isDeactivated}
                                        tabindex="0"
                                        style={{
                                          textAlign: 'center',
                                          margin: '0px 5px',
                                          height: '32px',
                                          width: '96px',
                                          fontSize: '16px',
                                        }}
                                      />
                                      <div
                                        style={{
                                          borderRadius: '0px',
                                          height: '32px',
                                          width: '32px',
                                        }}
                                        class="add quantity-button"
                                        onClick={() => {
                                          if (!isDeactivated)
                                            if (passCount < 5) {
                                              return this.setState(
                                                { passCount: passCount + 1 },
                                                onPassCount(passCount + 1),
                                              );
                                            } else {
                                              return;
                                            }
                                        }}
                                      >
                                        +
                                      </div>
                                      <div
                                        style={{
                                          borderRadius: '0px',
                                          height: '32px',
                                          width: '32px',
                                        }}
                                        class="remove quantity-button"
                                        onClick={() => {
                                          const val = passCount - 1;
                                          if (val < 1) return;
                                          if (!isDeactivated)
                                            this.setState(
                                              {
                                                passCount: val,
                                              },
                                              onPassCount(val),
                                            );
                                        }}
                                      >
                                        -
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div class="col-12">
                              <div class="form-group">
                                <label class="custom-control-label">
                                  Curriculum?{' '}
                                  <Field
                                    name="curriculum"
                                    type="text"
                                    class="custom-control-input not-empty"
                                    component={ReduxToggle}
                                    disabled={isDeactivated}
                                  />
                                  {'   '}
                                </label>
                              </div>
                            </div>
                            {isCurriculum && (
                              <>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="form-group">
                                    <Field
                                      name="curriculumName"
                                      type="text"
                                      component={ReduxInput}
                                      // validate={Validator.required}
                                      fieldName={'Curriculum Name'}
                                      disabled={isDeactivated}
                                    />
                                    <label class="placeholder-label">Curriculum Name</label>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="form-group">
                                    <Field
                                      name="curriculumAuthor"
                                      type="text"
                                      component={ReduxInput}
                                      // validate={Validator.required}
                                      fieldName={'Curriculum Author'}
                                      disabled={isDeactivated}
                                    />
                                    <label class="placeholder-label">Curriculum Author</label>
                                  </div>
                                </div>
                                {/* <div class="col-12 col-md-6 col-lg-4">
                                  <div class="form-group">
                                    <Field
                                      name="nameGetMaterial"
                                      type="text"
                                      component={ReduxInput}
                                      validate={Validator.required}
                                      fieldName={'Materials'}
                                    />
                                    <label class="placeholder-label">Name Get Materials*</label>
                                  </div>
                                </div> */}
                                {/* <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <div class="input-group mb-3 input-cost">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text"
                                      style={{ backgroundColor: 'white', border: 'unset' }}
                                    >
                                      $
                                    </span>
                                  </div>
                                  <Field
                                    name={`materialsCost`}
                                    component={ReduxInput}
                                    validate={[Validator.required, Validator.cost]}
                                    fieldName={'Materials Cost'}
                                  />
                                  <label class="placeholder-label" style={{ zIndex: 3, left: 40 }}>
                                    Materials Cost*
                                  </label>
                                </div>
                              </div>
                            </div> */}
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="form-group">
                                    <Field
                                      name="materialsFormat"
                                      type="text"
                                      component={ReduxSelect}
                                      // options={durations}
                                      options={SGMaterialsFormat}
                                      placeholder="Materials Format*"
                                      validate={Validator.required}
                                      fieldName={'Materials Format'}
                                      disabled={isDeactivated}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="form-group">
                                    <Field
                                      name="materialsLink"
                                      type="textarea"
                                      component={ReduxTextarea}
                                      validate={Validator.required}
                                      fieldName={'Materials Link'}
                                      disabled={isDeactivated}
                                    />
                                    <label class="placeholder-label">Link to Get Materials *</label>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {/* <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="curriculum"
                              type="text"
                              component={ReduxSelect}
                              // options={durations}
                              options={[
                                { Value: '1', Text: 'Yes' },
                                { Value: '2', Text: 'No' },
                              ]}
                              placeholder="Curriculum"
                              // validate={Validator.required}
                              fieldName={'Curriculum'}
                            />
                          </div>
                        </div> */}

                        {/* <div class="col-12 col-md-6 col-lg-4">
                          <div class="form-group">
                            <Field
                              name="ladderAssociation"
                              class="custom-select enrollment-select"
                              // component={ReduxInput}
                              component={ReduxSelect}
                              placeholder="Ladder Association"
                              options={ladder}
                              validate={Validator.required}
                              fieldName={'Ladder Association'}
                              // isMulti
                            />
                           
                          </div>
                        </div> */}

                        <div className="col-12 mt-5">
                          <div class="row">
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="audience"
                                  type="text"
                                  component={ReduxSelect}
                                  // options={durations}
                                  options={SGAudienceType}
                                  placeholder="Audience Type*"
                                  validate={Validator.required}
                                  fieldName={'Audience Type'}
                                  disabled={isDeactivated}
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="grpLeaderType"
                                  type="text"
                                  component={ReduxSelect}
                                  options={SGGroupLeaderType}
                                  placeholder="Group Leader Type*"
                                  validate={Validator.required}
                                  fieldName={'Group Leader Type'}
                                  disabled={isDeactivated}
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="instructionModel"
                                  type="text"
                                  component={ReduxSelect}
                                  // options={durations}
                                  options={SGInstructionModel}
                                  placeholder="Instruction Model*"
                                  validate={Validator.required}
                                  fieldName={'Instruction Model'}
                                  disabled={isDeactivated}
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="samshaClassification"
                                  type="text"
                                  component={ReduxSelect}
                                  // options={durations}
                                  options={SGSamshaClassification}
                                  placeholder="SAMHSA Classification*"
                                  validate={Validator.required}
                                  fieldName={'SAMHSA Classification'}
                                  disabled={isDeactivated}
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="spBehavioral"
                                  type="text"
                                  component={ReduxSelect}
                                  // options={durations}
                                  options={SGBehavioralCategory}
                                  placeholder="SP Behavioral Category*"
                                  validate={Validator.required}
                                  fieldName={'SP Behavioral'}
                                  disabled={isDeactivated}
                                  isMulti
                                />
                              </div>
                            </div>
                            {/* <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="pANASScaleMoodId"
                                  type="text"
                                  component={ReduxSelect}
                                  options={PANASScaleMood}
                                  placeholder="PANAS Scale Mood"
                                  // validate={Validator.required}
                                  fieldName={'PANAS Scale Mood'}
                                  disabled={isDeactivated}
                                  isMulti
                                />
                              </div>
                            </div> */}
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name="stageOfTreatment"
                                  type="text"
                                  component={ReduxSelect}
                                  options={SGStageOfTreatment}
                                  placeholder="Stage Of Treatment*"
                                  validate={Validator.required}
                                  fieldName={'Stage of Treatment'}
                                  disabled={isDeactivated}
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                              <div class="form-group">
                                <Field
                                  name={`specialGroupType`}
                                  type="text"
                                  component={ReduxSelect}
                                  options={SpecialGroupType}
                                  placeholder="Meeting Type if Single Meeting Per Member"
                                  fieldName="Special Group Type"
                                  disabled={isDeactivated}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div class="">
                      <div class="form-group">
                        <label class="custom-control-label">
                          Get Materials?{' '}
                          <Field
                            name="getMaterials"
                            type="text"
                            class="custom-control-input not-empty"
                            component={ReduxToggle}
                            // disabled={isDeactivated}
                          />
                        </label>
                      </div>
                    </div> */}
                    <div className="d-flex align-items-center justify-content-between">
                      <div style={{ width: '-webkit-fill-available' }}>
                        <div class="form-group">
                          <Field
                            className="mr-2"
                            name="isPrivate"
                            component={ReduxCheckbox}
                            fieldName={'isPrivate'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label" style={{ top: '5px' }}>
                            {' '}
                            {this.state.TextPrivate}
                          </label>
                        </div>
                        {this.state.ShowPublish && (
                          <div class="form-group">
                            <Field
                              className="mr-2"
                              name="isPublished"
                              component={ReduxCheckbox}
                              fieldName={'isPublished'}
                              disabled={isDeactivated || !this.state.HasPublishAccess}
                            />
                            <label class="placeholder-label" style={{ top: '0px' }}>
                              {this.state.TextPublish}
                            </label>
                          </div>
                        )}
                        {this.state.WebPortalURL && (
                          <div>
                            <h6>
                              Please use the following small group link to share with others to
                              join.
                            </h6>
                            <div className="copy-clipboard">
                              <textarea
                                onClick={this.copyTextToClipboard}
                                readOnly
                                ref={textarea => (this.textArea = textarea)}
                                className="copy-textarea"
                                style={{ resize: 'none' }}
                                value={this.state.WebPortalURL}
                              />
                              <span className="clipboard-success">
                                {this.state.copied && 'Copied'}
                              </span>
                            </div>
                          </div>
                        )}

                        {this.state.JoinUrl && (
                          <div className="mt-5">
                            <h6>
                              Please use the following Zoom Participants link to share with others
                              to join.
                            </h6>
                            <div className="copy-clipboard">
                              <textarea
                                onClick={this.copyTextToClipboard2}
                                readOnly
                                ref={textarea2 => (this.textArea2 = textarea2)}
                                className="copy-textarea"
                                style={{ resize: 'none' }}
                                value={this.state.JoinUrl}
                              />
                              <span className="clipboard-success">
                                {this.state.copied2 && 'Copied'}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class="text-right disable-li d-flex flex-row-reverse align-items-center">
                        <input
                          type="submit"
                          class="btn px-5"
                          value="Save"
                          disabled={isFetchingSM}
                          onClick={() => {
                            if (!this.state.GroupPictureMediaGuid) {
                              this.setState({
                                groupPictureErrorMsg: 'Please Select Group Picture',
                              });
                            } else if (this.state.upload && !this.state.mediaguid) {
                              this.setState({
                                groupLeaderErrorMsg: 'Please upload group leader profile',
                              });
                            }
                          }}
                          // disabled={isDeactivated}
                        />{' '}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          type="button"
                          class="px-5 btn btn-outline-primary"
                          value="Cancel"
                          onClick={onCancel}
                        />
                        {smallGroupGuid != null && (
                          <Popover
                            trigger="click"
                            visible={this.state.popVisible}
                            onVisibleChange={this.handleVisibleChange}
                            content={
                              <div onClick={this.hide}>
                                {' '}
                                {isDeactivated ? (
                                  <>
                                    <AntdModal
                                      ModalButton={({ onClick }) => (
                                        <a
                                          // class="reactivate-link"
                                          data-toggle="modal"
                                          data-target="#reactivate-staff-model"
                                          onClick={onClick}
                                        >
                                          {/* <i class="fa fa-ban" aria-hidden="true" /> */}
                                          Reactivate
                                        </a>
                                      )}
                                      ModalContent={({ onOk, onCancel }) => (
                                        <div class="modal-content border-0">
                                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                            <h4 class="modal-title col-12 mb-3">
                                              Are you sure you want to reactivate this small group?{' '}
                                              <br />
                                            </h4>
                                          </div>
                                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                            <button
                                              class="btn btn-outline-primary black-btn"
                                              onClick={() => {
                                                onCancel();
                                                // history.push('/manage/smallgroups');
                                              }}
                                            >
                                              No
                                            </button>
                                            <button
                                              class="btn"
                                              onClick={() => {
                                                let deactivateSmallGroup = {
                                                  Json: JSON.stringify({
                                                    SmallGroup: [
                                                      {
                                                        SmallGroupGuid: smallGroupGuid,
                                                        IsActive: 1,
                                                      },
                                                    ],
                                                  }),
                                                };
                                                this.props.dispatch(
                                                  addSmallGroup(deactivateSmallGroup),
                                                );
                                                onOk();
                                              }}
                                            >
                                              Yes
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <AntdModal
                                      ModalButton={({ onClick }) => (
                                        <a
                                          // class="deactivate-link"
                                          data-toggle="modal"
                                          data-target="#deactivate-staff-model"
                                          onClick={onClick}
                                        >
                                          <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                                        </a>
                                      )}
                                      ModalContent={({ onOk, onCancel }) => (
                                        <div class="modal-content border-0">
                                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5  text-center">
                                            <h4 class="modal-title col-12 mb-3">
                                              Are you sure you want to deactivate <br />
                                              <span className="deactivate-popup">
                                                {this.props.smNameValue}
                                              </span>{' '}
                                              small group? <br />
                                            </h4>
                                            <span className="text-danger">
                                              Deactivating a small group will automatically remove
                                              all the subscriptions.
                                            </span>
                                          </div>
                                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                            <button
                                              class="btn black-btn"
                                              onClick={() => {
                                                onCancel();
                                                // history.push('/manage/smallgroups');
                                              }}
                                            >
                                              No
                                            </button>
                                            <button
                                              class="btn"
                                              onClick={() => {
                                                let deactivateSmallGroup = {
                                                  Json: JSON.stringify({
                                                    SmallGroup: [
                                                      {
                                                        SmallGroupGuid: smallGroupGuid,
                                                        IsActive: 0,
                                                      },
                                                    ],
                                                  }),
                                                };
                                                onOk();
                                                this.props.dispatch(
                                                  addSmallGroup(deactivateSmallGroup),
                                                );
                                              }}
                                            >
                                              Yes
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </>
                                )}
                              </div>
                            }
                          >
                            <div
                              className="d-flex align-items-center mr-4"
                              style={{ cursor: 'pointer' }}
                            >
                              <span>
                                <i class="fas fa-cog fa-2x" style={{ color: '#959595' }} />
                              </span>
                              &nbsp;
                              <span>
                                <i class="fa fa-caret-down" style={{ color: '#959595' }} />
                              </span>
                            </div>
                          </Popover>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {smallGroupGuid != null && (
            <>
              <div className="col-12 mt-5">
                <div class="card border-0 rounded-0 small_group_users">
                  <div class="row p-3">
                    <div class="col-7 mb-3 d-flex align-items-center">
                      <h5>{this.props.smNameValue} Users</h5> &nbsp; &nbsp;
                      {!isDeactivated && smallGroupGuid != null && (
                        <h5
                          className="btn py-2"
                          data-toggle="modal"
                          data-target="#deactivate-staff-model"
                          onClick={() => {
                            this.setState({
                              inviteSubscriberModal: true,
                              smallGroupGuid: smallGroupGuid,
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          Send Invitation
                        </h5>
                      )}
                    </div>
                    <div class="col-2 mb-3 d-flex align-items-center">
                      {' '}
                      <a
                        onClick={() => {
                          this.setState({
                            reduxBroadcaseInvitationModal: true,
                          });
                        }}
                      >
                        <span style={{ color: '#46b4fe', marginRight: '15px', fontWeight: '600' }}>
                          Broadcast Message
                        </span>
                      </a>
                    </div>
                    <div class="col-3 enrollment-form">
                      <div class="form-group">
                        <ReduxSelect
                          options={[
                            { Text: 'All', Value: 'All' },
                            { Text: 'Guest', Value: 'Guest' },
                            { Text: 'Active', Value: 'Active' },
                            { Text: 'Canceled', Value: 'Canceled' },
                            { Text: 'ReferralCode', Value: 'ReferralCode' },
                            { Text: 'VoucherCode', Value: 'VoucherCode' },
                          ]}
                          onChange={this.onFilterChange}
                          className="basic-single"
                          classNamePrefix="select"
                          value={this.state.smUsers}
                          placeholder="Filter By"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <Loader2 loading={isFetchingUsers} />
                      <div>
                        <Table
                          selections={false}
                          columns={columns}
                          dataSource={smallGroupUser}
                          // rowClassName={(record, index) => {
                          //   return !record.IsButton && 'table-bg-danger';
                          // }}
                          rowClassName={(record, index) => {
                            if (!record.IsButton) return 'table-bg-danger';
                          }}
                          // rowClassName={(record, index) => {
                          //   // if (record.ButtonText === 'Resend') return 'table-bg-primary';
                          //   // if (record.ButtonText === 'Cancel Subscription') return null;
                          //   if (record.ButtonText === 'Canceled') return 'table-bg-danger';
                          // }}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div class="dataTables_wrapper no-footer">
                                  <div class="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a class="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a class="paginate_button next">Next</a>
                                    ) : (
                                      <a class="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                            // hideOnSinglePage: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <Modal
            zIndex={100}
            visible={this.state.inviteSubscriberModal}
            footer={null}
            closable={false}
          >
            <ReduxSendInvitationModal
              // {...this.props}
              // GetStudentGui={this.getStudentGui}
              SmallGroupGuid={this.state.smallGroupGuid}
              initialValues={{
                referralType: 'R',
                CountryCode: '1',
              }}
              ClientType="subscriber"
              onCancel={() => {
                this.setState({
                  inviteSubscriberModal: false,
                });
              }}
            />
          </Modal>
          <Modal
            visible={this.state.reduxBroadcaseInvitationModal}
            footer={null}
            closable={false}
            width={702}
            destroyOnClose={true}
          >
            <ReduxBroadcaseInvitationModal
              onCancel={() => {
                this.setState({
                  reduxBroadcaseInvitationModal: false,
                });
              }}
              SmallGroupGuid={this.props.smallGroupGuid}
            />
          </Modal>
          <CustomModal
            isOpen={this.state.isResendModelOpen}
            header={this.state.successMessage}
            yesAction={() => {
              this.setState({
                isReSendInvitaion: false,
                isResendModelOpen: false,
              });
              this.props.dispatch(
                getSmallGroupUsers({
                  Json: JSON.stringify({
                    SmallGroup: [
                      {
                        SmallGroupGuid: this.props.smallGroupGuid,
                        FilterBy: this.state.smUsers !== 'All' ? this.state.smUsers : null,
                      },
                    ],
                  }),
                }),
              );
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            // twilioToken={this.state.twilioToken}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    combos,
    get_one_call_timeSlots,
    sp_list_combos,
    get_smallgroup,
    send_voucher_code,
    get_small_group_users,
    add_smallgroup,
  } = state;
  const { data: timeSlotData } = get_one_call_timeSlots;
  const { data: spListData } = sp_list_combos;
  const { data: smallgroupData } = get_smallgroup;
  const { data: smallgroupUsers, isFetching: isFetchingUsers } = get_small_group_users;
  const { isFetching: isFetchingSM, error: errorSM } = add_smallgroup;

  let isSuccess = false;
  let errorMessage;
  let successMessage;

  let timeSlots = [];
  let durations = [];
  let counsellors = [];
  let spListCombo = {};
  let groupPicture = [];
  let cost = [];
  let smallGroupUser = [];

  if (!combos.data) {
    combos.data = {
      StateProvince: [],
      Country: [],
      Center: [],
    };
  }

  if (smallgroupData && smallgroupData.GroupPicture) {
    groupPicture = smallgroupData.GroupPicture;
  }
  if (smallgroupData && smallgroupData.Cost) {
    cost = smallgroupData.Cost;
  }
  if (smallgroupData && smallgroupData.LeaderCombo) {
    counsellors = smallgroupData.LeaderCombo;
  }
  if (smallgroupData && smallgroupData.TimeSlotCombo) {
    timeSlots = smallgroupData.TimeSlotCombo;
  }
  if (smallgroupUsers && smallgroupUsers.SmallGroupUser) {
    smallGroupUser = smallgroupUsers.SmallGroupUser;
  }
  if (spListData) {
    spListCombo = spListData;
  }
  if (timeSlotData && timeSlotData.Advisor) {
    durations = timeSlotData.Advisor[0].Duration || [];
  }
  if (send_voucher_code.data && send_voucher_code.data.success) {
    isSuccess = send_voucher_code.data.success;
    successMessage = send_voucher_code.data.result;
  } else if (send_voucher_code && send_voucher_code.error) {
    errorMessage = send_voucher_code.error.Message;
  }
  const isGetMaterials = formValueSelector('smallGroupForm')(state, 'getMaterials');
  const isAllowGuestPass = formValueSelector('smallGroupForm')(state, 'allowGuestPass');
  const isCurriculum = formValueSelector('smallGroupForm')(state, 'curriculum');
  const startDateValue = formValueSelector('smallGroupForm')(state, 'startDate');
  const meetingFormatValue = formValueSelector('smallGroupForm')(state, 'meetingFormat');
  const meetingFrequencyValue = formValueSelector('smallGroupForm')(state, 'meetingFrequency');
  const countryValue = formValueSelector('smallGroupForm')(state, 'country');
  const smNameValue = formValueSelector('smallGroupForm')(state, 'groupName');
  const descriptionValue = formValueSelector('smallGroupForm')(state, 'description');
  const learnValue = formValueSelector('smallGroupForm')(state, 'whatYouWillLearn');

  return {
    ladder: combos.data.Ladder,
    StateProvince: combos.data.StateProvince,
    Country: combos.data.Country,
    Center: combos.data.Center,
    spListCombo,
    timeSlots,
    durations,
    counsellors,
    isGetMaterials,
    isAllowGuestPass,
    isCurriculum,
    startDateValue,
    meetingFormatValue,
    meetingFrequencyValue,
    countryValue,
    groupPicture,
    cost,
    smallGroupUser,
    isSuccess,
    errorMessage,
    successMessage,
    smNameValue,
    descriptionValue,
    learnValue,
    isFetchingUsers,
    isFetchingSM,
  };
};

const ReduxCreateGroupForm = reduxForm({
  form: 'smallGroupForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(CreateGroupForm)));

const mapStateToPropsParent = ({
  get_smallgroup,
  add_smallgroup,
  upload_leader_photo,
  update_staff_pro_bio,
}) => {
  const { data: addSMGData, isFetching: isFetchingSM, error: errorSM } = add_smallgroup;
  const { data, isFetching, error } = get_smallgroup;
  const { data: uploadLeaderData } = upload_leader_photo;
  const { data: staffBioData, isFetching: isFetchingBio } = update_staff_pro_bio;

  let smallGroup = {};
  var isBioInserted = false;
  var isInserted = false;
  var isSuccessMsg = false;
  var recordInfo = null;
  let mediaguid;
  let createdSMGuid;
  let hostUser = [];
  let constantData = [];

  if (uploadLeaderData) {
    mediaguid = uploadLeaderData.result;
    console.log(mediaguid);
  }

  if (data && data.SmallGroup) {
    smallGroup = data.SmallGroup && data.SmallGroup[0];
  }

  if (data && data.ConstantData) {
    constantData = data.ConstantData && data.ConstantData[0];
  }

  if (addSMGData && addSMGData.success && addSMGData.data) {
    isInserted =
      JSON.parse(addSMGData.data).SmallGroup[0].SuccessMsg ||
      JSON.parse(addSMGData.data).SmallGroup[0].ErrorMsg;
    isSuccessMsg = JSON.parse(addSMGData.data).SmallGroup[0].SuccessMsg;
    createdSMGuid = JSON.parse(addSMGData.data).SmallGroup[0].SmallGroupGuid;
  }

  if (staffBioData && staffBioData) {
    isBioInserted = staffBioData.success;
  }

  if (data && data.HostUser) {
    hostUser = data.HostUser[0];
  }

  return {
    isFetching: isFetching,
    smallGroup,
    isInserted,
    isSuccessMsg,
    mediaguid,
    hostUser,
    isBioInserted,
    constantData,
    createdSMGuid,
  };
};

export default connect(mapStateToPropsParent)(SmallGroupForm);
