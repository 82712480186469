import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicGrid from '../../../Components/DynamicGrid';
import PageTitle from '../../../Components/PageTitle';

class Audit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Audits" />
        <div class="content-area">
          <div class="row top-table-search mt-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h3 class="page-title mb-3 mb-md-0 page-title-absolute">Audits</h3>
            </div>
          </div>
          <div />
          <div class="row payer-grid">
            <div class="col-12 mb-5">
              <div class="card border-0 " style={{ backgroundColor: 'unset', boxShadow: 'none' }}>
                <div className="dynamic-grid">
                  <DynamicGrid ScreenName={'PayerPortalAudit'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Audit);
