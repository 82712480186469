import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import Demographic from './tabs/demographic';
import PreviousCare from './tabs/previous-care';
import SubstanceUse from './tabs/substance-use';
import MentalHealth from './tabs/mental-health';
import Environment from './tabs/environment';
import ProspectBanner from '../ProspectBanner';
// import ConfigutationTab from './tabs/ConfigutationTab';
import ConfigutationTab from '../ConfigutationTab';
import renderHTML from 'react-render-html';
import { Loader2 } from '../../../../Components/Loader';
import {
  listSurveyQuestionAssessment,
  surveyAnswerUpsert,
  augmentedClientInsightsGet,
  surveyLatestAnswerDateUpdate,
} from '../../../../store/actions';
const { TabPane } = Tabs;

class ProgramAlignment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskResponse: null,
      isMandatory: false,
      tabMapping: {
        0: 'demographic',
        1: 'previous-care',
        2: 'substance-use',
        3: 'mental-health',
        4: 'environment',
      },
      tabLabels: [
        {
          Name: 'Demographic',
          Component: props => <Demographic {...props} name={'Demographic'} />,
        },
        {
          Name: 'Previous Care',
          Component: props => <PreviousCare {...props} name={'PreviousCare'} />,
        },
        {
          Name: 'Substance Use',
          Component: props => <SubstanceUse {...props} name={'SubstanceUse'} />,
        },
        {
          Name: 'Mental Health',
          Component: props => <MentalHealth {...props} name={'MentalHealth'} />,
        },
        {
          Name: 'Environment',
          Component: props => <Environment {...props} name={'Environment'} />,
        },
      ],
      activeKey: 0,
    };
  }
  getIntakeId = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentId = param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = null;
    }
    return studentId;
  };
  getSurveyId = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let surveyid = param.get('surveyid');
    if (surveyid) {
      surveyid = parseInt(surveyid);
    } else {
      surveyid = null;
    }
    return surveyid;
  };
  onTabClick = key => {
    let keyData = parseInt(key);
    let clickedTab = this.state.tabMapping[keyData];

    this.setState({
      activeKey: parseInt(key),
    });
    window.scrollTo(0, 0);
    this.props.history.push(
      `/pre-intake-form?step=program-alignment&tab=${clickedTab}&studentid=${this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    );
  };
  getRisk = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            IsDefaultPreIntakeSurvey: true,
          },
        ],
      }),
    };
    Axios.post('Survey/ClientRiskGet', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            riskResponse: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  handleNext = index => {
    let keyData = index + 1;
    let clickedTab = this.state.tabMapping[keyData];

    this.setState({
      activeKey: `${index + 1}`,
    });
    this.props.history.push(
      `/pre-intake-form?step=program-alignment&tab=${clickedTab}&studentid=${this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    );
  };

  goToPreviuos = index => {
    let keyData = index - 1;
    let clickedTab = this.state.tabMapping[keyData];
    this.setState({ activeKey: index - 1 });
    this.props.history.push(
      `/pre-intake-form?step=program-alignment&tab=${clickedTab}&studentid=${this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
    );
  };
  componentDidMount(props) {
    // const { tabMapping } = this.state;
    // var params = new URLSearchParams(this.props.location.search);
    // var tab = params.get('tab') || tabMapping[0];
    // if (tabMapping) {
    //   this.setState({
    //     activeKey: Object.values(tabMapping).indexOf(tab),
    //   });
    // }
    if (this.props.ProgramAllignmentSurveyId) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: this.getIntakeId(),
                SurveyId: this.props.ProgramAllignmentSurveyId,
                SurveyType: 'ProgramAlignment',
              },
            ],
          }),
        }),
      );
    }

    this.getRisk();
  }
  componentWillReceiveProps({ surveyQuestion, ProgramAllignmentSurveyId, isAnswerUpsert }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      // this.setState({ SurveyQuestionList: surveyQuestion });
      this.setState({ SurveyQuestionList: surveyQuestion }, () => {
        this.state.SurveyQuestionList &&
          this.state.SurveyQuestionList.SurveyQuestion &&
          this.state.SurveyQuestionList.SurveyQuestion.map((item, index) => {
            const { Tab } = item;
            this.state.tabMapping[index] = Tab && Tab.toLowerCase().replace(' ', '-');
          });
        const { tabMapping } = this.state;
        var params = new URLSearchParams(this.props.location.search);
        var tab = params.get('tab') || tabMapping[0];
        if (tabMapping) {
          let tabIndex = Object.values(tabMapping).indexOf(tab)
          this.setState({
            activeKey: tabIndex >= 0 ? tabIndex : 0,
          });
        }
      });
      this.props.getQuestionList(surveyQuestion);
    }
    if (
      ProgramAllignmentSurveyId &&
      ProgramAllignmentSurveyId != this.props.ProgramAllignmentSurveyId
    ) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: this.getIntakeId(),
                SurveyId: ProgramAllignmentSurveyId,
                SurveyType: 'ProgramAlignment',
              },
            ],
          }),
        }),
      );
    }
    if (isAnswerUpsert && isAnswerUpsert != this.props.isAnswerUpsert) {
      this.getRisk();
    }
  }

  setValue = ({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
    note,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    // let tabName = tabLabels[activeKey].Name;
    var json = {
      StudentId: this.getIntakeId(),
      SurveyId: this.props.ProgramAllignmentSurveyId,
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      Note: note,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    let isRequired = response ? false : true;
    if (level === 0) {
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Response = response;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Note = note;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].IsRequired = isRequired;
      this.setState({ SurveyQuestionList, isMandatory: false });
      this.props.getQuestionList(SurveyQuestionList);
    }
    // const surveys = [...this.state.surveys];
    // for (var survey of surveys) {
    //   if (survey.SurveyQuestionId == surveyQuestionId) {
    //     survey.Response = response;
    //   }
    // }
    // if (IsSignature) {
    //   this.setState({
    //     surveys: surveys,
    //     Signature: IsSignature && Signature,
    //     SignatureCanvas: IsSignature && {
    //       ...this.state.SignatureCanvas,
    //       signatureDataList: {
    //         ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
    //         [surveyQuestionId]: {
    //           SurveyQuestionId: surveyQuestionId,
    //           isSignatureSaved: isSignatureSaved,
    //         },
    //       },
    //     },
    //   });
    // } else {
    //   this.setState({
    //     surveys: surveys,
    //   });
    // }

    this.props.dispatch(surveyAnswerUpsert(json));
  };
  onNext = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    isResetAnswer,
    note,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    // let tabName = tabLabels[activeKey].Name;
    let json = {
      StudentId: this.getIntakeId(),
      SurveyId: this.props.ProgramAllignmentSurveyId,
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      Note: note,
    };
    let isRequired = response ? false : true;
    if (level === 0) {
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Response = response;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].Note = note;
      SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].IsRequired = isRequired;

      this.setState({ SurveyQuestionList, isMandatory: false });
      this.props.getQuestionList(SurveyQuestionList);
    }
    // const surveys = [...this.state.surveys];
    // for (let survey of surveys) {
    //   if (survey.SurveyQuestionId == surveyQuestionId) {
    //     survey.Response = response;
    //   }
    // }

    // $('.main-banner').slick('slickNext');
    // this.setState({
    //   surveys: surveys,
    // });
    // if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
  };
  handelSaveAnalyze = () => {
    let QuestionList = this.state.SurveyQuestionList.SurveyQuestion;
    let isAnalyze = QuestionList.some(item => {
      return !item.Response && item.IsRequired === true;
    });
    if (false && isAnalyze) {
      this.setState({
        isMandatory: true,
      });
    } else {
      this.props.dispatch(surveyLatestAnswerDateUpdate({ Surveyid: this.getSurveyId() }));
      this.props.history.push(
        `/clients/pre-intake/summary?studentid=${this.getIntakeId()}&surveyid=${this.getSurveyId()}`,
      );
    }
  };

  render() {
    const { activeKey, studentData, tabLabels, activeTabs, SurveyQuestionList } = this.state;
    return (
      <div class="main enrollment-form">
        <div class="col-12">
          <ProspectBanner
            ClientId={this.props.ID || this.getIntakeId()}
            isRiskChart={true}
            riskResponse={this.state.riskResponse}
          />
        </div>
        <div class="col-12 mb-5 vertical-tabs">
          <Loader2 loading={this.props.isFetchingSurveyQuestion} />

          {SurveyQuestionList && SurveyQuestionList.SurveyQuestion ? (
            <Tabs
              activeKey={String(activeKey)}
              tabPosition="left"
              defaultActiveKey="0"
              onTabClick={this.onTabClick}
            >
              {/* {tabLabels.map((item, index) => {
              const { Component, Name } = item;
              const { SurveyQuestion, SurveyId } = SurveyQuestionList || {};
              return (
                <TabPane tab={Name} key={`${index}`} className="tab-content">
                  {activeKey == index && (
                    <Component
                      style={{ padding: '36px 20px 150px 20px' }}
                      dispatch={this.props.dispatch}
                      SurveyId={SurveyId}
                      SurveyQuestion={
                        SurveyQuestion &&
                        SurveyQuestion.filter(surveryItem => surveryItem.Tab === Name)
                      }
                      onNext={this.onNext}
                      setValue={this.setValue}
                      scope={this}
                      goToPreviuos={() => this.goToPreviuos(index)}
                      handleNext={() => this.handleNext(index)}
                      //   isReadOnlyUser={isReadOnlyUser}
                    />
                  )}
                </TabPane>
              );
            })} */}

              {SurveyQuestionList &&
                SurveyQuestionList.SurveyQuestion &&
                SurveyQuestionList.SurveyQuestion.map((item, index) => {
                  const { Tab, Sequence, Questions } = item;
                  const { SurveyQuestion, SurveyId } = SurveyQuestionList || {};
                  const tabsCount = SurveyQuestion.length;
                  let Component = () => (
                    <ConfigutationTab
                      style={{ padding: '36px 20px 150px 20px' }}
                      dispatch={this.props.dispatch}
                      SurveyId={SurveyId}
                      SurveyQuestion={Questions}
                      onNext={this.onNext}
                      setValue={this.setValue}
                      isMandatory={this.state.isMandatory}
                      scope={this}
                      tabsCount={tabsCount}
                      index={index}
                      goToPreviuos={() => this.goToPreviuos(index)}
                      handleNext={() => this.handleNext(index)}
                      isAnserFetching={this.props.isAnserFetching}
                      // onLastSubmit={onSubmit.bind(this)}
                      //   isReadOnlyUser={isReadOnlyUser}
                    />
                  );
                  let isTab = Tab && Tab.length;
                  return (
                    <TabPane
                      tab={isTab && renderHTML(Tab)}
                      key={`${index}`}
                      className="tab-content"
                    >
                      {activeKey == index && (
                        <Component style={{ padding: '36px 20px 150px 20px' }} />
                      )}
                    </TabPane>
                  );
                })}
            </Tabs>
          ) : (
            <div
              className="d-flex justify-content-center text-center flex-column align-items-center"
              style={{
                height: '500px',
                backgroundColor: 'white',
              }}
            >
              {/* <img src="images/category.svg" /> */}
              <h4 className="mt-4">
                Looks like there are no pre-configured questions available for the chosen service
                type or center in our Question Library. For additional support, kindly contact the
                center administrator and have them add questions under Admin &gt; Screening Questions Library. Please reach out to our chat agent if you have any
                questions!
              </h4>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { list_survey_question_assessment, survey_answer_upsert, student_banner } = state;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = list_survey_question_assessment;
  const { data: upsertData, isFetching: isAnserFetching } = survey_answer_upsert;

  const { data: bannerData , isFetching: isBannerFetching} = student_banner;
  let surveyQuestion;
  let augmentedAnswer;
  let isAnswerUpsert = false;
  let ProgramAllignmentSurveyId = null;
  let preItakeSurveyId = null;

  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }

  if (upsertData && upsertData.data && upsertData.data.success) {
    isAnswerUpsert = true;
  }
  if (bannerData) {
    preItakeSurveyId = bannerData.PreItakeSurveyId;
    ProgramAllignmentSurveyId = bannerData.ProgramAllignmentSurveyId;
  }
  return {
    surveyQuestion,
    isFetchingSurveyQuestion: isFetchingSurveyQuestion || isBannerFetching,
    augmentedAnswer,
    isAnswerUpsert,
    ProgramAllignmentSurveyId,
    preItakeSurveyId,
    isAnserFetching,
  };
};

export default connect(mapStateToProps)(withRouter(ProgramAlignment));
