import React, { Component } from 'react';
import { ReduxSelect } from '../../../component/custom-redux-components';
import { payerPortalDashboard } from '../../../store/actions';

export default class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterId: 'Last 3 Months',
    };
  }

  handleFilter = filterId => {
    const { datesDropdown } = this.props;
    this.setState({ filterId });
    let dates =
      datesDropdown &&
      datesDropdown.filter((item, index) => {
        return item.Value == filterId;
      });
    this.handleContractPeriod(
      dates[0] ? dates[0].StartDate : null,
      dates[0] ? dates[0].EndDate : null,
    );
  };

  handleContractPeriod = (startDate, endDate) => {
    this.props.dispatch(
      payerPortalDashboard({
        JSON: JSON.stringify({
          Dashboard: [
            {
              StartDate: startDate,
              EndDate: endDate,
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { alertsMessages, datesDropdown } = this.props;
    return (
      <div className="col-12 payer-contracts-period">
        <div className="card border-0 alerts mb-5">
          <div className="card-body">
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              // style={{ borderBottom: '1px solid #CCC', padding: '16px' }}
            >
              <p className="heading">ALERTS</p>
              <div className="d-flex ">
                {/* <div className="d-flex align-items-center payer-portal-cente mr-2">
                  <div className="form-group mb-0" style={{ width: '180px' }}>
                    <ReduxSelect
                      name={`staffId`}
                      // component={ReduxSelect}
                      options={[]}
                      // onChange={value => this.handleFilter(value, this.state.filterId)}
                      // value={this.state.staffId}
                    />
                  </div>
                </div> */}
                <div className="d-flex align-items-center payer-portal-center">
                  <div className="form-group mb-0" style={{ width: '160px' }}>
                    <ReduxSelect
                      name={`filterId`}
                      options={datesDropdown}
                      onChange={this.handleFilter}
                      value={this.state.filterId}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
              {alertsMessages &&
                alertsMessages.map(item => {
                  return (
                    <ul
                      className="d-flex align-items-center p-3 payer-item mb-0"
                      style={{
                        // borderBottom: '1px solid #D8D8D8',
                        padding: '0',
                        listStyleType: 'circle',
                        cursor: 'pointer',

                        // margin: '0'
                      }}
                      onClick={() => {
                        this.props.history.push(item.OnRowClick);
                      }}
                    >
                      <li className="payer-alerts">{item.Message}</li>
                      {item.OnRowClick && (
                        <span className="ml-2 d-flex" style={{ color: '#46B4FE' }}>
                          View Details <img className="ml-2" src={'images/openIcon.svg'} />
                        </span>
                      )}
                    </ul>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
