import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, Tooltip } from 'antd';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import {
  getClientMemo,
  upsertClientMemo,
  resetUpsertClientMemo,
  deleteClientMemo,
  resetDeleteClientMemo,
} from '../../../../store/actions';
import NotesDrawer from './notesDrawer/notesDrawer';
import PersistTableSort from '../../../../component/persistTableSort';
import AntdModal from '../../../../Components/CustomModal';
import DictationComponent from '../../../../Components/DictationComponent';
import SubmitButton from '../../../../Components/submitButton';

const { TextArea } = Input;

class Memo extends Component {
  constructor() {
    super();
    this.state = {
      memo: '',
      memoId: null,
      columns: [
        {
          title: 'Memo',
          dataIndex: 'Memo',
          key: 'Memo',
          sorter: (a, b) => isComparer(a.Memo, b.Memo),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Created Date',
          dataIndex: 'CreatedDate',
          key: 'CreatedDate',
          sorter: (a, b) => isDateComparer(a.CreateDateForSort, b.CreateDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Staff Name',
          dataIndex: 'StaffName',
          key: 'StaffName',
          sorter: (a, b) => isComparer(a.StaffName, b.StaffName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Action',
          key: 'x',
          width: '50px',
          render: (text, record, index) => (
            <div class="tools-grid">
              {!this.props.isReadOnlyUser && !this.props.isDeActivated && (
                <>
                  {' '}
                  <div className="mr-5" style={{ cursor: 'pointer' }}>
                    <span
                      className="start-btn"
                      onClick={event => {
                        this.setState({
                          clickedMemo: record,
                          memo: record.Memo,
                          memoId: record.MemoId,
                        });
                      }}
                    >
                      {'Edit'}
                    </span>
                  </div>
                  <div className="mr-2">
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Delete">
                            <p>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to delete this memo?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                this.props.dispatch(
                                  deleteClientMemo({
                                    json: JSON.stringify({
                                      Memo: [
                                        {
                                          MemoId: record.MemoId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                onOk();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          ),
        },
      ],
    };
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentDidMount() {
    this.props.dispatch(
      getClientMemo({
        Json: JSON.stringify({
          Memo: [
            {
              ClientId: this.getClientId(),
              StartDate: null,
              EndDate: null,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ isInserted, isRemoved }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.props.dispatch(resetUpsertClientMemo());
      this.props.dispatch(
        getClientMemo({
          Json: JSON.stringify({
            Memo: [
              {
                ClientId: this.getClientId(),
                StartDate: null,
                EndDate: null,
                Search: this.state.searchStr,
              },
            ],
          }),
        }),
      );
      this.setState({ memo: '', memoId: null });
    }
    if (isRemoved && isRemoved != this.props.isRemoved) {
      this.props.dispatch(resetDeleteClientMemo());
      this.props.dispatch(
        getClientMemo({
          Json: JSON.stringify({
            Memo: [
              {
                ClientId: this.getClientId(),
                StartDate: null,
                EndDate: null,
                Search: this.state.searchStr,
              },
            ],
          }),
        }),
      );
    }
  }
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      getClientMemo({
        json: JSON.stringify({
          Memo: [
            {
              ClientId: this.getClientId(),
              Search: value,
              StartDate: null,
              EndDate: null,
            },
          ],
        }),
      }),
    );
  };
  addMemo = () => {
    this.props.dispatch(
      upsertClientMemo({
        Json: JSON.stringify({
          Memo: [
            {
              ClientId: this.getClientId(),
              Memo: this.state.memo,
              MemoId: this.state.memoId,
            },
          ],
        }),
      }),
    );
  };
  handleDictate = result => {
    if (result) {
      let string = this.state.memo + ' ' + result;
      this.setState({
        memo: string,
      });
    }
  };
  render() {
    const {
      columns,
      notesDrawerVisible,
      appointmentId,
      isGroup,
      groupAppointmentId,
      isModelOpen,
      header,
    } = this.state;
    const { clientId, MemoList, isFetching, isMemoFetcing } = this.props;
    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page mb-5">
          <div class="container-fluid rcm-tab">
            {!this.props.isReadOnlyUser && !this.props.isDeActivated && (
              <div class="row">
                <div class="col-md-4 col-xl-6 px-0 mb-2">
                  <h4 className="mt-5">MEMO</h4>
                </div>
                <div class="col-12 col-md-12 enrollment-form px-0">
                  <div class="form-group">
                    <TextArea
                      showCount
                      maxLength={2000}
                      style={{ minHeight: '75px' }}
                      class="form-control"
                      placeholder="Add Memo"
                      rows="3"
                      value={this.state.memo}
                      onChange={event => this.setState({ memo: event.target.value })}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-12" style={{ textAlign: 'right' }}>
                  <div
                    className="dictation-space"
                    style={{ display: 'inline-flex', paddingRight: '10px' }}
                  >
                    <DictationComponent handleDictate={this.handleDictate} />
                  </div>
                  <button
                    type="button"
                    class="btn btn-outline-primary px-5 mr-2"
                    onClick={() => {
                      this.setState({
                        memo: '',
                        memoId: null,
                      });
                    }}
                  >
                    Clear
                  </button>

                  {/* <button
                    type="button"
                    class="btn black-btn px-5"
                    onClick={this.addMemo}
                    disabled={!this.state.memo.length > 0}
                  >
                    {this.state.memoId ? 'Update' : 'Add'}
                  </button> */}
                  <SubmitButton
                    type="button"
                    className="px-5"
                    onClick={this.addMemo}
                    style={{ height: '45px' }}
                    disabled={!this.state.memo.length > 0}
                    value={this.state.memoId ? 'Update' : 'Add'}
                    loading={isMemoFetcing}
                  />
                </div>
              </div>
            )}
            <div class="row">
              <div class="col-12 mb-5 px-0">
                <div class="border-0">
                  <div className="">
                    <div className="row px-0 mt-2 d-flex align-items-center">
                      <div class="col-md-4 col-xl-6 px-0">
                        <h6 className="">Memo List</h6>
                      </div>
                      <div class="col-md-7 col-xl-6 px-0 text-right">
                        <div class="search-box d-inline-flex w-100">
                          <div class="form-group has-search w-100 d-flex align-items-center">
                            <input
                              type="text"
                              class="form-control 
                   shadow-sm rcm-document-search mt-3"
                              placeholder="Search"
                              id="filterbox"
                              onChange={this.onSearchChange}
                            />
                            <button class="btn search-icon " type="button">
                              {' '}
                              <i class="fa fa-search" />{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-2 pb-5 rcm-subtab-table-block">
                    <div class="col-12" style={{ position: 'relative' }}>
                      <Loader2 loading={isFetching} />
                      <PersistTableSort
                        name="indi-noteList"
                        columns={columns}
                        dataSource={MemoList}
                        size={'middle'}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: event => this.onRowClick(record.ClientId),
                        //   };
                        // }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isModelOpen && (
            <CustomModal
              isOpen={isModelOpen}
              zIndex={9999999}
              header={header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  upsert_client_memo,
  get_client_memo,
  delete_client_memo,
  get_patient_detail,
}) => {
  const { data, isFetching } = get_client_memo;
  const { data: clientInfo } = get_patient_detail;
  const { isFetching: isMemoFetcing } = upsert_client_memo;

  let MemoList = [];
  let isInserted = false;
  let isRemoved = false;
  let isDeActivated = false;

  if (data && data.Memo) {
    MemoList = data.Memo || [];
  }
  if (upsert_client_memo.data && upsert_client_memo.data.success) {
    isInserted = upsert_client_memo.data.success;
  }
  if (upsert_client_memo.data && upsert_client_memo.data.success) {
    isInserted = upsert_client_memo.data.success;
  }
  if (delete_client_memo.data && delete_client_memo.data.success) {
    isRemoved = delete_client_memo.data.success;
  }
  if (clientInfo && clientInfo.ClientId) {
    isDeActivated = clientInfo.IsDeActivated;
  }
  return {
    MemoList,
    isFetching,
    isInserted,
    isRemoved,
    isDeActivated,
    isMemoFetcing,
  };
};

export default connect(mapStateToProps)(withRouter(Memo));
