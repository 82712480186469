import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Avatar, Divider, Tooltip } from 'antd';

const BGContent2 = props => {
  const {
    labels,
    backGroundImage,
    moduleLogoImage,
    moduleName,
    tagLine,
    buyButtonText,
    labelTitle,
    interaction,
    peopleConnected,
    newMessages,
    subHeader,
    avatar,
    clientsWaiting,
    isSmallGroupModule,
    smallGroupCreated,
  } = props;
  return (
    <>
      <div className="row bg-white mt-4  m-1 trialEnded">
        <div
          //   className="col-12 mt-2 mb-2 px-0"
          className="col-12 px-0"
        >
          <div style={{ position: 'relative' }}>
            <img src={backGroundImage} alt="" style={{ width: '100%' }} />
            <div className="section">
              <div className="d-flex justify-content-center">
                <span style={{ position: 'relative', marginRight: '20px' }}>
                  <img src={moduleLogoImage} alt="" style={{ width: '100%', height: '64px' }} />
                </span>
              </div>
              <div className="d-flex justify-content-center">
                {interaction &&
                  interaction.map(item => {
                    return (
                      <span style={{ position: 'relative', marginRight: '20px' }}>
                        <span className="iconTag">{item.tagNumber}</span>
                        <img src={item.image} alt="" style={{ width: '100%', height: '55px' }} />
                      </span>
                    );
                  })}
              </div>
              {avatar && (
                <div className="d-flex justify-content-center">
                  <Avatar.Group
                    // maxCount={2}
                    // maxPopoverTrigger="click"
                    size="large"
                    maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                  >
                    <Avatar src="images/license/sgDemo1.png" />
                    <Avatar src="images/license/sgDemo2.png" />
                    <Avatar src="images/license/sgDemo3.png" />
                  </Avatar.Group>
                </div>
              )}
              {isSmallGroupModule && (
                <div className="d-flex justify-content-center">
                  <Avatar.Group
                    // maxCount={2}
                    // maxPopoverTrigger="click"
                    size="large"
                    maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
                  >
                    <Avatar src="images/license/sgDemo1.png" />
                    <Avatar src="images/license/sgDemo2.png" />
                    <Avatar src="images/license/sgDemo3.png" />
                  </Avatar.Group>
                </div>
              )}
              <p className="heading">{moduleName}</p>
              {peopleConnected && newMessages && (
                <p style={{ fontWeight: '700', fontSize: '24px' }}>
                  {`${peopleConnected} People connected. You have ${newMessages} new messages.`}
                </p>
              )}
              {clientsWaiting && (
                <p style={{ fontWeight: '700', fontSize: '24px' }}>
                  {`${clientsWaiting} clients in prospect waiting to be reviewed.`}
                </p>
              )}

              {isSmallGroupModule && (
                <p
                  style={{ fontWeight: '700', fontSize: '24px' }}
                >{`${smallGroupCreated} Small groups created.`}</p>
              )}

              {subHeader && (
                <p style={{ fontWeight: '700', fontSize: '24px', margin: '0px' }}>{subHeader}</p>
              )}
              <p className="tagLine">{tagLine}</p>
              {labelTitle && (
                <p style={{ fontWeight: '700', fontSize: '24px', margin: '0px' }}>{labelTitle}</p>
              )}
              {labels &&
                labels.map(item => {
                  return <span className="labels">{item}</span>;
                })}
              <div>
                <button
                  className="btn text-center mt-3 mb-3"
                  onClick={() => {
                    props.history.push('/manage/maintain-license');
                  }}
                >
                  {buyButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(BGContent2);
