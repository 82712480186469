import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import renderHTML from 'react-render-html';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Upload, message, Table, Modal, Popconfirm, Icon, Tooltip, Tag } from 'antd';
import { ReduxAddDimensionModal } from './treatmentPlanModals/addDimensionModal';
import { ReduxAddProblemModal } from './treatmentPlanModals/addProblemModal';
import { ReduxAddGoalModal } from './treatmentPlanModals/addGoalModal';
import { ReduxAddObjectiveModal } from './treatmentPlanModals/addObjectiveModal';
import { ReduxAddInterventionModal } from './treatmentPlanModals/addInterventionModal';
import { ReduxTreatmentPlanSubmissionForm } from './treatmentPlanModals/treatmentPlanSubmissionForm';
import CustomModal2 from '../../../../core/modal';
import AntdModal from '../../../../Components/CustomModal';
import { Collapse, Checkbox, Row, Col } from 'antd';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Drawer, Button } from 'antd';
import moment from 'moment';
import PersistTableSort from '../../../../component/persistTableSort';
import LinkAppointment from '../../rcmComponents/linkingAppointment';
// import { ProcessCombos } from '../../../../../store/comboConfig';
// import validate, {
//   dateofbirthNormalize,
//   ssnFormatter,
//   ssnNormalize,
//   MobileFormatter,
//   MobileNormalize,
// } from '../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
} from '../../../../component/custom-redux-components';
import {
  listCombos,
  getTreatmentPlan,
  listTreatmentPlan,
  getDiagnosis,
  deleteTreatmentPlan,
  resetListTreatmentPlan,
  cloneTreatmentPlan,
  upsertTreatmentPlan,
  getTreatmentPlanAccordin,
  unsignForm,
  upsertTreatmentPlanDimension,
} from '../../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
const { Panel } = Collapse;
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

const classRemove = e => {
  var elements = document.querySelectorAll('.mystyle');
  elements.forEach(function(item) {
    item.classList.remove('mystyle');
  });
  e.currentTarget.parentNode.classList.add('mystyle');
};

class EditDeleteComponent extends Component {
  handleDelete = (key, value) => {
    let data = {
      Json: JSON.stringify({
        TreatmentPlan: [
          {
            [key]: value,
          },
        ],
      }),
    };
    this.props.dispatch(deleteTreatmentPlan(data));
  };
  render() {
    const { isReadOnlyUserSession } = this.props;
    return (
      <>
        <>
          <div className="editBtn1">
            {false ? (
              ''
            ) : (
              <>
                {this.props.isTreatmentPlanSigned &&
                  !this.props.isTreatmentPlanSigned.IsSigned &&
                  !this.props.isReadOnlyUserSession && (
                    <span className="d-flex">
                      <div
                        style={{ cursor: 'pointer' }}
                        className="d-flex"
                        onClick={e => {
                          e.stopPropagation();
                          this.props.editCallBack();
                        }}
                      >
                        <img src="images/edittp.svg" />
                        &nbsp;Edit
                        {/* <img
                        src="images/edittp.svg"
                        style={{
                          height: '27',
                          padding: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          this.props.editCallBack();
                        }}
                      /> */}
                      </div>

                      {this.props.apiKey != 'TreatmentPlanDimensionId' && (
                        <div className="ml-3" style={{ cursor: 'pointer' }}>
                          <AntdModal
                            ModalButton={({ onClick }) => (
                              // <img
                              //   src="images/deletetp.svg"
                              //   data-toggle="modal"
                              //   data-target="#deactivate-staff-model"
                              //   style={{
                              //     height: '27',
                              //     padding: '10px',
                              //     cursor: 'pointer',
                              //   }}
                              //   onClick={onClick}

                              // />
                              <span onClick={onClick} className="d-flex">
                                {' '}
                                <img src="images/deletetp.svg" />
                                &nbsp;Delete
                              </span>
                              //  <img
                              //     src="images/deleteEvent.svg"
                              //     style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                              //     data-toggle="modal"
                              //     data-target="#deactivate-staff-model"
                              //     onClick={onClick}
                              //   />
                            )}
                            ModalContent={({ onOk, onCancel }) => (
                              <div class="modal-content border-0">
                                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                  <h4 class="modal-title col-12 mb-3">
                                    Are you sure you want to delete?
                                  </h4>
                                </div>
                                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                  <button
                                    class="btn black-btn"
                                    onClick={() => {
                                      onCancel();
                                    }}
                                  >
                                    No
                                  </button>
                                  <button
                                    class="btn"
                                    onClick={() => {
                                      onOk();
                                      this.handleDelete(this.props.apiKey, this.props.apiValue);
                                      // this.props.onCancel();
                                    }}
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}
                    </span>
                  )}
              </>
            )}
            {/* {this.props.apiKey == 'TreatmentPlanDimensionId' && (
              <div
                style={{ cursor: 'pointer' }}
                className="d-flex justify-content-center align-items-center"
                onClick={() => {
                  // var elems = document.querySelectorAll(this.props.resetCSS);
                  // var index = 0,
                  //   length = elems.length;
                  // for (; index < length; index++) {
                  //   // elems[index].style.transition = 'opacity 0.5s linear 0s';
                  //   window.getComputedStyle(elems[index], '::before').display = 'block';
                  // }
                  let data = {
                    Json: JSON.stringify({
                      TreatmentPlan: [
                        {
                          [this.props.apiKey]: this.props.apiValue,
                        },
                      ],
                    }),
                  };
                  this.props.dispatch(getTreatmentPlan(data));
                }}
              >
                <i class="fas fa-angle-down" style={{ color: '#46b4fe' }}></i>
              </div>
            )} */}
          </div>
        </>
      </>
    );
  }
}
const ReduxEditDeleteComponent = connect()(EditDeleteComponent);

class StyledTreeExample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AssociatedNoteData: null,
      isDimensionModalVisible: false,
      isProblemModalVisible: false,
      isGoalModalVisible: false,
      isObjetiveModalVisible: false,
      isInterventionModalVisible: false,
      isModelOpen: false,
      messageText: '',
      treatmentPlanDimensionId: '',
      treatmentPlanProblemId: '',
      treatmentPlanObjectiveId: '',
      initialValues: {
        problemText: '',
      },
      dataAPICallObj: {},
      expandAll: false,
      activeKey: this.props.treatmentData.map((v, idx) => idx),
      visible: false,
    };
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentWillReceiveProps({
    deleteTp,
    deleteReturnData,
    treatmentData,
    unsignFormStatus,
    lastUpdatedDimensionId,
    upsertTreatmentPlanDimensionDataSuccess,
  }) {
    if (
      upsertTreatmentPlanDimensionDataSuccess &&
      upsertTreatmentPlanDimensionDataSuccess != this.props.upsertTreatmentPlanDimensionDataSuccess
    ) {
      this.props.dispatch(
        getTreatmentPlanAccordin({
          Json: JSON.stringify({
            TreatmentPlan: [
              {
                TreatmentPlanId: this.props.clikedTreatmentPlanId,
              },
            ],
          }),
        }),
      );
    }
    if (unsignFormStatus && unsignFormStatus != this.props.unsignFormStatus) {
      let data = {
        Json: JSON.stringify({
          TreatmentPlan: [
            {
              TreatmentPlanId: this.props.clikedTreatmentPlanId,
            },
          ],
        }),
      };

      this.props.dispatch(getTreatmentPlanAccordin(data));
    }
    if (deleteTp && deleteTp != this.props.deleteTp) {
      // let data = {
      //   Json: JSON.stringify(deleteReturnData),
      // };
      // let data = {
      //   Json: JSON.stringify({
      //     TreatmentPlan: [
      //       {
      //         // TreatmentPlanId: this.props.clikedTreatmentPlanId,
      //         TreatmentPlanDimensionId: this.props.treatmentPlanDimensionId,
      //       },
      //     ],
      //   }),
      // };

      let data = {
        Json: JSON.stringify({
          TreatmentPlan: [
            {
              TreatmentPlanId: this.props.clikedTreatmentPlanId,
            },
          ],
        }),
      };

      this.props.dispatch(getTreatmentPlanAccordin(data));
      if (
        deleteReturnData &&
        JSON.stringify(deleteReturnData) != JSON.stringify(this.props.deleteReturnData)
      ) {
        // var level;
        if (Object.keys(deleteReturnData.TreatmentPlan[0])[0] == 'TreatmentPlanDimensionId') {
          this.resetMessageText(` Problem Deleted Successfully`);
        }
        if (Object.keys(deleteReturnData.TreatmentPlan[0])[0] == 'TreatmentPlanProblemId') {
          this.resetMessageText(` Goal Deleted Successfully`);
        }
        if (Object.keys(deleteReturnData.TreatmentPlan[0])[0] == 'TreatmentPlanGoalId') {
          this.resetMessageText(` Objective Deleted Successfully`);
        }
        if (Object.keys(deleteReturnData.TreatmentPlan[0])[0] == 'TreatmentPlanObjectiveId') {
          this.resetMessageText(` Intervention Deleted Successfully`);
        }
      }
    }
    if (
      !isEqual(treatmentData, this.props.treatmentData) ||
      !isEqual(lastUpdatedDimensionId, this.props.lastUpdatedDimensionId)
    ) {
      let keysList;
      if (lastUpdatedDimensionId) {
        treatmentData &&
          treatmentData.forEach((v, idx) => {
            if (v.TreatmentPlanDimensionId == lastUpdatedDimensionId) {
              keysList = [`${idx}`];
            }
          });
      } else {
        keysList = treatmentData.map((v, idx) => idx);
      }
      this.setState({ activeKey: keysList });
    }
  }

  resetMessageText = (msg, dataAPICallObj) => {
    this.setState({
      isModelOpen: true,
      messageText: msg,
      dataAPICallObj: dataAPICallObj,
    });
  };

  // handleDelete = (key, value) => {
  //   let data = {
  //     Json: JSON.stringify({
  //       TreatmentPlan: [
  //         {
  //           [key]: value,
  //         },
  //       ],
  //     }),
  //   };
  //   this.props.dispatch(deleteTreatmentPlan(data));
  // };

  toggleDimensionModal = (TreatmentPlanDimensionId, InitDataObj) => {
    if (TreatmentPlanDimensionId) {
      this.setState({
        isDimensionModalVisible: !this.state.isDimensionModalVisible,
        treatmentPlanDimensionId: TreatmentPlanDimensionId,
        initialValues: InitDataObj,
      });
    } else {
      this.setState({
        isDimensionModalVisible: !this.state.isDimensionModalVisible,
        initialValues: {},
      });
    }
  };

  toggleProblemModal = (TreatmentPlanDimensionId, TreatmentPlanProblemId, InitDataObj) => {
    if (TreatmentPlanDimensionId) {
      this.setState({
        isProblemModalVisible: !this.state.isProblemModalVisible,
        treatmentPlanDimensionId: TreatmentPlanDimensionId,
        treatmentPlanProblemId: TreatmentPlanProblemId,
        initialValues: InitDataObj,
      });
    } else {
      this.setState({
        isProblemModalVisible: !this.state.isProblemModalVisible,
        initialValues: {},
      });
    }
  };

  toggleGoalModal = (
    TreatmentPlanDimensionId,
    TreatmentPlanProblemId,
    TreatmentPlanGoalId,
    InitDataObj,
  ) => {
    if (TreatmentPlanProblemId) {
      this.setState({
        treatmentPlanDimensionId: TreatmentPlanDimensionId,
        isGoalModalVisible: !this.state.isGoalModalVisible,
        treatmentPlanProblemId: TreatmentPlanProblemId,
        treatmentPlanGoalId: TreatmentPlanGoalId,
        initialValues: InitDataObj,
      });
    } else {
      this.setState({
        isGoalModalVisible: !this.state.isGoalModalVisible,
        initialValues: {},
      });
    }
  };

  toggleObjectiveModal = (
    TreatmentPlanDimensionId,
    TreatmentPlanGoalId,
    TreatmentPlanObjectiveId,
    InitDataObj,
  ) => {
    if (TreatmentPlanGoalId) {
      this.setState({
        treatmentPlanDimensionId: TreatmentPlanDimensionId,
        isObjetiveModalVisible: !this.state.isObjetiveModalVisible,
        treatmentPlanGoalId: TreatmentPlanGoalId,
        treatmentPlanObjectiveId: TreatmentPlanObjectiveId,
        initialValues: InitDataObj,
      });
    } else {
      this.setState({
        isObjetiveModalVisible: !this.state.isObjetiveModalVisible,
        initialValues: {},
      });
    }
  };

  toggleInterventionModal = (
    TreatmentPlanDimensionId,
    TreatmentPlanObjectiveId,
    TreatmentPlanInterventionId,
    InitDataObj,
  ) => {
    if (TreatmentPlanObjectiveId) {
      this.setState({
        treatmentPlanDimensionId: TreatmentPlanDimensionId,
        isInterventionModalVisible: !this.state.isInterventionModalVisible,
        treatmentPlanObjectiveId: TreatmentPlanObjectiveId,
        treatmentPlanInterventionId: TreatmentPlanInterventionId,
        initialValues: InitDataObj,
      });
    } else {
      this.setState({
        isInterventionModalVisible: !this.state.isInterventionModalVisible,
        initialValues: {},
      });
    }
  };

  resetInitialValues = () => {
    this.setState({
      initialValues: {
        problemText: '',
      },
    });
  };

  setTemplateValue = resetObj => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        ...resetObj,
      },
    });
  };

  expandAll = () => {
    this.setState(prevState => {
      let activeKeys = [];
      if (prevState.expandAll) {
        activeKeys = this.props.treatmentData && this.props.treatmentData.map((v, idx) => idx);
      }
      return {
        expandAll: !prevState.expandAll,
        activeKey: activeKeys,
      };
    });
  };

  callback = key => {
    this.setState({ activeKey: key });
  };

  captureExpandedkeys = key => {
    this.setState({ activeKey: key });
  };
  showDrawer = AssociatedNote => {
    this.setState({
      visible: true,
      AssociatedNoteData: AssociatedNote,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      visible: false,
      AssociatedNoteData: null,
    });
  };
  unsignFormFun = () => {
    let data = {
      Json: JSON.stringify({
        UnSign: [
          {
            FormType: 'TreatmentPlan',
            FormId: this.props.clikedTreatmentPlanId,
            // TreatmentPlanId: this.props.clikedTreatmentPlanId,
          },
        ],
      }),
    };

    this.props.dispatch(unsignForm(data));
  };

  render() {
    const {
      treatmentData,
      isTreatmentPlanSigned,
      printDetails,
      isReadOnlyUserSession,
    } = this.props;
    const { expandAll, noteList } = this.state;
    var role = localStorage.getItem('userRole');
    return (
      <>
        <Drawer
          className="custom-drawer"
          width={640}
          title="Associated Notes"
          placement="right"
          closable={true}
          onClose={this.onCloseDrawer}
          visible={this.state.visible}
          open={this.state.visible}
        >
          {this.state.AssociatedNoteData &&
            this.state.AssociatedNoteData.map(item => {
              return (
                <div
                  style={{
                    background: 'white',
                    borderRadius: '11px',
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 16px',
                    padding: '5px 10px',
                    margin: '5px 0px',
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {item.AppointmentDate && (
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Appointment Date: </span>
                        {item.AppointmentDate}
                      </p>
                    )}

                    {item.SignedDate && (
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Signed Date: </span>
                        {item.SignedDate}
                      </p>
                    )}
                  </div>

                  {item.Note && (
                    <>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Note:</span> {item.Note}
                      </p>
                    </>
                  )}
                </div>
              );
            })}
          {this.state.AssociatedNoteData == null ? 'No Associated Note Found !' : null}
        </Drawer>

        <div className="d-flex justify-content-end align-items-center mb-1">
          {role != 'Clinical Supervisor' && (
            <AntdModal
              ModalButton={({ onClick }) => (
                <Tooltip placement="top" title="Un-sign">
                  <span class="delete-course" onClick={onClick}>
                    {isTreatmentPlanSigned && isTreatmentPlanSigned.IsEligibleForUnSign && (
                      <span className="mt-2 mb-2 mr-2 danger-custom-btn">Un-sign</span>
                    )}
                  </span>
                </Tooltip>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                    <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                      &times;
                    </button>
                    <h4 class="modal-title col-12">Are you sure you want to Un-sign?</h4>
                  </div>

                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      type="button"
                      class="btn black-btn"
                      data-dismiss="modal"
                      onClick={onCancel}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn "
                      onClick={() => {
                        onOk();
                        this.unsignFormFun();
                        // this.cancelGroupCall(Data.VMCallSessionId);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          )}

          {role == 'Clinical Supervisor' && (
            <AntdModal
              ModalButton={({ onClick }) => (
                <Tooltip placement="top" title="Un-sign">
                  <span class="delete-course" onClick={onClick}>
                    {isTreatmentPlanSigned &&
                      isTreatmentPlanSigned.IsSupervisorSigned &&
                      isTreatmentPlanSigned.IsSupervisorEligibleForUnSign && (
                        <span className="mt-2 mb-2 mr-2 danger-custom-btn">Un-sign</span>
                      )}
                  </span>
                </Tooltip>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                    <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                      &times;
                    </button>
                    <h4 class="modal-title col-12">Are you sure you want to Un-sign?</h4>
                  </div>

                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      type="button"
                      class="btn black-btn"
                      data-dismiss="modal"
                      onClick={onCancel}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn "
                      onClick={() => {
                        onOk();
                        this.unsignFormFun();
                        // this.cancelGroupCall(Data.VMCallSessionId);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          )}

          <span
            onClick={this.expandAll}
            className="mt-2 mb-2"
            style={{
              width: '150px',
              fontSize: '18px',
              color: '#46b4fe',
              fontWeight: '600',
              cursor: 'pointer',
            }}
          >
            {this.state.expandAll ? `+ Expand All` : `- Collapse All`}
          </span>
          {!this.props.isReadOnlyUserSession && (
            <ReactToPrint
              trigger={() => (
                <div className="text-right ml-2">
                  {' '}
                  <button style={{ border: 'none', background: '#fff' }}>
                    {/* <i class="fas fa-print"></i> */}
                    <img src="images/printSVG.svg" />
                  </button>
                </div>
              )}
              content={() => this.componentRef}
              documentTitle="Treatment Plan"
            />
          )}
        </div>

        <div ref={el => (this.componentRef = el)} className="rcm-tab pageStyle accordian-tp">
          {this.props.isTreatmentPlanSigned &&
            this.props.isTreatmentPlanSigned.IsIntitialSignatureCompleted && (
              <div className="print-header">
                <h5 className="mb-3">TREATMENT PLAN DETAILS</h5>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-3">
                        <span className="text" style={{ color: '#959595' }}>
                          Patient Id
                        </span>
                        <br />
                        <span className="value font-weight-bold">
                          {printDetails && printDetails.ClientId}
                        </span>
                      </div>
                      <div className="col-3">
                        <span className="text" style={{ color: '#959595' }}>
                          Patient Name
                        </span>
                        <br />
                        <span className="value font-weight-bold">
                          {printDetails.ClientFullName}
                        </span>
                      </div>

                      <div className="col-6">
                        <span className="text" style={{ color: '#959595' }}>
                          Center Name
                        </span>
                        <br />
                        <span className="value font-weight-bold">{printDetails.CenterName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    {this.props.isTreatmentPlanSigned && (
                      <div className="row">
                        <div className="col-3">
                          <span className="text" style={{ color: '#959595' }}>
                            Start Date
                          </span>
                          <br />
                          <span className="value font-weight-bold">
                            {this.props.isTreatmentPlanSigned.StartDate}
                          </span>
                        </div>

                        <div className="col-3">
                          <span className="text" style={{ color: '#959595' }}>
                            End Date
                          </span>
                          <br />
                          <span className="value font-weight-bold">
                            {this.props.isTreatmentPlanSigned.EndDate}
                          </span>
                        </div>

                        <div className="col-3">
                          <span className="text" style={{ color: '#959595' }}>
                            Signed Date
                          </span>
                          <br />
                          <span className="value font-weight-bold">
                            {this.props.isTreatmentPlanSigned.SignedDate}
                          </span>
                        </div>

                        {/* <div className="col-3">
                        <span className="text" style={{ color: '#959595' }}>
                          Signed By
                        </span>
                        <br />
                        <span className="value font-weight-bold">
                          {this.props.isTreatmentPlanSigned.SignedBy}
                        </span>
                      </div> */}
                        {this.props.isTreatmentPlanSigned.SignatureContentHtml && (
                          <div className="col-md-12 treatment-plan-detail py-2">
                            {renderHTML(this.props.isTreatmentPlanSigned.SignatureContentHtml)}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          {this.props.treatmentData &&
            this.props.treatmentData.map((dimentionItem, index) => {
              const {
                DimensionTitle,
                Description,
                TreatmentPlanDimensionId,
                TreatmentPlanProblem,
                IsAddProblem,
                InitialRiskRating,
                CurrentRiskRating,
                CurrentRiskRatingText,
                AccordinTitle,
                DimensionName,
              } = dimentionItem;
              const dimensionTitle = Description;
              return (
                <div className={index != 0 ? 'page-break' : ''}>
                  <Collapse
                    defaultActiveKey={`${index}`}
                    key={`${index}`}
                    activeKey={this.state.activeKey}
                    onChange={index => this.captureExpandedkeys(index)}
                  >
                    <Panel
                      header={
                        <span
                          style={{
                            fontWeight: '800',
                          }}
                        >
                          {AccordinTitle}
                        </span>
                      }
                      // header={`DIMENSION${index + 1} : ${Description}`}
                      key={`${index}`}
                      // className="mt-2 mb-2"
                      className=" dimension-panel-border"
                      extra={
                        <div className="d-flex justify-content-end">
                          <ReduxEditDeleteComponent
                            isReadOnlyUserSession={isReadOnlyUserSession}
                            apiKey={'TreatmentPlanDimensionId'}
                            apiValue={TreatmentPlanDimensionId}
                            resetCSS=".dimension + ul.css-1jae07r"
                            editCallBack={() => {
                              this.toggleDimensionModal(TreatmentPlanDimensionId, {
                                initialRiskRating: InitialRiskRating,
                                currentRiskRating: CurrentRiskRating,
                                dimensionName: DimensionName,
                                dimensionTitle: DimensionTitle,
                              });
                            }}
                            isTreatmentPlanSigned={isTreatmentPlanSigned}
                          />
                          {isTreatmentPlanSigned &&
                            !isTreatmentPlanSigned.IsSigned &&
                            !isReadOnlyUserSession && (
                              <div
                                className="ml-3"
                                onClick={e => {
                                  e.stopPropagation();
                                  this.toggleProblemModal(TreatmentPlanDimensionId);
                                }}
                                style={{
                                  color: 'rgba(128,128,128)',
                                  cursor: 'pointer',
                                }}
                              >
                                <i class="fas fa-plus"></i> Add Problem
                              </div>
                            )}
                        </div>
                      }
                    >
                      {/* <h3>{DimensionTitle}</h3> */}
                      <p>{Description}</p>
                      <div className="row mx-0 mb-3">
                        <div
                          className="col-6 py-3"
                          style={{
                            fontSize: '14px',
                            fontWeight: 700,
                            // borderLeft: '0.5px solid #25bde2',
                            border: '0.5px solid #bfbfbf',
                          }}
                        >
                          Initial Risk Rating:
                          <span
                            style={{
                              fontWeight: 300,
                              marginLeft: '5px',
                            }}
                          >
                            {InitialRiskRating ? InitialRiskRating : 0}
                          </span>
                        </div>
                        <div
                          className="col-6 py-3"
                          style={{
                            fontSize: '14px',
                            fontWeight: 700,
                            // border: '0.5px solid #25bde2',
                            border: '0.5px solid #bfbfbf',
                            borderLeft: 'unset',
                          }}
                        >
                          Current Risk Rating:
                          <span
                            style={{
                              fontWeight: 300,
                              marginLeft: '5px',
                            }}
                          >
                            {CurrentRiskRatingText && CurrentRiskRatingText}
                          </span>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-6">
                          Initial Risk Rating <br />
                          <span className="dimesion-rating-accoridan">
                            {InitialRiskRating ? InitialRiskRating : 0}
                          </span>
                        </div>
                        <div className="col-md-6">
                          Current Risk Rating <br />
                          <span className="dimesion-rating-accoridan">
                            {CurrentRiskRatingText && CurrentRiskRatingText}
                          </span>
                        </div>
                      </div> */}

                      {TreatmentPlanProblem &&
                        TreatmentPlanProblem.map((problemItems, index2) => {
                          const {
                            ProblemTitle,
                            Description,
                            TreatmentPlanGoal,
                            TreatmentPlanProblemId,
                            TreatmentPlanDimensionId,
                            IsAddGoal,
                            AccordinTitle,
                          } = problemItems;
                          const problemTitle = ProblemTitle;

                          return (
                            <>
                              <Collapse
                                defaultActiveKey={`${index2}`}
                                // defaultActiveKey={expandAll ? true : `${index2}`}
                                // activeKey={

                                //   this.state.activeKey
                                //     ? this.state.activeKey
                                //     : expandAll
                                //     ? true
                                //     : `${index2}`
                                // }

                                // activeKey={expandAll ? true : this.state.activeKey}

                                // onChange={this.callback}
                              >
                                <Panel
                                  header={
                                    <span
                                      style={{
                                        fontWeight: '800',
                                      }}
                                    >
                                      {AccordinTitle}
                                    </span>
                                  }
                                  className="problem-panel-border"
                                  // header={`PROBLEM${index2 + 1} : ${Description}`}
                                  key={`${index2}`}
                                  extra={
                                    <div className="d-flex justify-content-end">
                                      <ReduxEditDeleteComponent
                                        isReadOnlyUserSession={isReadOnlyUserSession}
                                        apiKey={'TreatmentPlanProblemId'}
                                        apiValue={TreatmentPlanProblemId}
                                        editCallBack={() => {
                                          this.toggleProblemModal(
                                            TreatmentPlanDimensionId,
                                            TreatmentPlanProblemId,
                                            {
                                              problemText: Description,
                                            },
                                          );
                                        }}
                                        isTreatmentPlanSigned={isTreatmentPlanSigned}
                                      />
                                      {isTreatmentPlanSigned &&
                                        !isTreatmentPlanSigned.IsSigned &&
                                        !isReadOnlyUserSession && (
                                          <div
                                            className="ml-3"
                                            onClick={e => {
                                              e.stopPropagation();
                                              this.toggleGoalModal(
                                                TreatmentPlanDimensionId,
                                                TreatmentPlanProblemId,
                                              );
                                            }}
                                            style={{
                                              color: 'rgba(128,128,128)',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            <i class="fas fa-plus"></i> Add Goal
                                          </div>
                                        )}
                                    </div>
                                  }
                                >
                                  <p>{Description}</p>

                                  {problemItems.TreatmentPlanGoal &&
                                    problemItems.TreatmentPlanGoal.map((goalItems, index3) => {
                                      const {
                                        GoalTitle,
                                        Description,
                                        SubText,
                                        SubValue,
                                        TreatmentPlanObjective,
                                        TreatmentPlanGoalId,
                                        TreatmentPlanProblemId,
                                        IsAddObjective,
                                        AccordinTitle,
                                      } = goalItems;
                                      const goalTitle = GoalTitle;

                                      return (
                                        <>
                                          <Collapse
                                            defaultActiveKey={`${index3}`}
                                            // defaultActiveKey={expandAll ? true : `${index3}`}
                                            // activeKey={expandAll ? true : `${index3}`}

                                            // activeKey={
                                            //   this.state.activeKey
                                            //     ? this.state.activeKey
                                            //     : expandAll
                                            //     ? true
                                            //     : `${index3}`
                                            // }

                                            // onChange={this.callback}
                                          >
                                            <Panel
                                              className="goal-panel-border"
                                              header={
                                                <span
                                                  style={{
                                                    fontWeight: '800',
                                                  }}
                                                >
                                                  {AccordinTitle}
                                                </span>
                                              }
                                              // header={`GOAL${index3 + 1} : ${Description}`}
                                              // key={index3}
                                              key={`${index3}`}
                                              extra={
                                                <div className="d-flex justify-content-end">
                                                  <ReduxEditDeleteComponent
                                                    isReadOnlyUserSession={isReadOnlyUserSession}
                                                    apiKey={'TreatmentPlanGoalId'}
                                                    apiValue={TreatmentPlanGoalId}
                                                    editCallBack={() => {
                                                      this.toggleGoalModal(
                                                        TreatmentPlanDimensionId,
                                                        TreatmentPlanProblemId,
                                                        TreatmentPlanGoalId,
                                                        {
                                                          goalDescription: Description,
                                                          subText: SubText,
                                                          subValue: SubValue,
                                                        },
                                                      );
                                                    }}
                                                    isTreatmentPlanSigned={isTreatmentPlanSigned}
                                                  />
                                                  {isTreatmentPlanSigned &&
                                                    !isTreatmentPlanSigned.IsSigned &&
                                                    !isReadOnlyUserSession && (
                                                      <div
                                                        className="ml-3"
                                                        onClick={e => {
                                                          e.stopPropagation();
                                                          this.toggleObjectiveModal(
                                                            TreatmentPlanDimensionId,
                                                            TreatmentPlanGoalId,
                                                          );
                                                        }}
                                                        style={{
                                                          color: 'rgba(128,128,128)',
                                                          cursor: 'pointer',
                                                        }}
                                                      >
                                                        <i class="fas fa-plus"></i> Add Objective
                                                      </div>
                                                    )}
                                                </div>
                                              }
                                            >
                                              <p>{Description}</p>
                                              {TreatmentPlanObjective &&
                                                TreatmentPlanObjective.map(
                                                  (objectiveItem, index4) => {
                                                    const {
                                                      ObjectiveTitle,
                                                      Description,
                                                      TreatmentPlanObjectiveId,
                                                      TreatmentPlanGoalId,
                                                      TreatmentPlanIntervention,
                                                      IsAddIntervention,
                                                      AccordinTitle,
                                                    } = objectiveItem;

                                                    const objectiveTitle = ObjectiveTitle;
                                                    return (
                                                      <>
                                                        <Collapse
                                                          defaultActiveKey={`${index4}`}
                                                          // defaultActiveKey={
                                                          //   expandAll ? true : `${index4}`
                                                          // }
                                                          // activeKey={expandAll ? true : `${index4}`}

                                                          // activeKey={
                                                          //   this.state.activeKey
                                                          //     ? this.state.activeKey
                                                          //     : expandAll
                                                          //     ? true
                                                          //     : `${index4}`
                                                          // }

                                                          // onChange={this.callback}
                                                        >
                                                          <Panel
                                                            className="objective-panel-border"
                                                            // header={`OBJECTIVE${index4 +
                                                            //   1} : ${Description}`}
                                                            header={
                                                              <span
                                                                style={{
                                                                  fontWeight: '800',
                                                                }}
                                                              >
                                                                {AccordinTitle}
                                                              </span>
                                                            }
                                                            // key={index4}
                                                            key={`${index4}`}
                                                            extra={
                                                              <div className="d-flex justify-content-end">
                                                                {/* <ReduxEditDeleteComponent
                                                                apiKey={'TreatmentPlanGoalId'}
                                                                apiValue={TreatmentPlanGoalId}
                                                                editCallBack={() => {
                                                                  this.toggleGoalModal(
                                                                    TreatmentPlanProblemId,
                                                                    TreatmentPlanGoalId,
                                                                    {
                                                                      goalDescription: Description,
                                                                      subText: SubText,
                                                                      subValue: SubValue,
                                                                    },
                                                                  );
                                                                }}
                                                                isTreatmentPlanSigned={
                                                                  isTreatmentPlanSigned
                                                                }
                                                              /> */}
                                                                <ReduxEditDeleteComponent
                                                                  isReadOnlyUserSession={
                                                                    isReadOnlyUserSession
                                                                  }
                                                                  apiKey={
                                                                    'TreatmentPlanObjectiveId'
                                                                  }
                                                                  apiValue={
                                                                    TreatmentPlanObjectiveId
                                                                  }
                                                                  editCallBack={() => {
                                                                    this.toggleObjectiveModal(
                                                                      TreatmentPlanDimensionId,
                                                                      TreatmentPlanGoalId,
                                                                      TreatmentPlanObjectiveId,
                                                                      {
                                                                        objectiveDescription: Description,
                                                                      },
                                                                    );
                                                                  }}
                                                                  isTreatmentPlanSigned={
                                                                    isTreatmentPlanSigned
                                                                  }
                                                                />
                                                                {isTreatmentPlanSigned &&
                                                                  !isTreatmentPlanSigned.IsSigned &&
                                                                  !isReadOnlyUserSession && (
                                                                    <div
                                                                      className="ml-3"
                                                                      onClick={e => {
                                                                        e.stopPropagation();
                                                                        this.toggleInterventionModal(
                                                                          TreatmentPlanDimensionId,
                                                                          TreatmentPlanObjectiveId,
                                                                        );
                                                                      }}
                                                                      style={{
                                                                        color: 'rgba(128,128,128)',
                                                                        cursor: 'pointer',
                                                                      }}
                                                                    >
                                                                      <i class="fas fa-plus"></i>{' '}
                                                                      Add Intervention
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            }
                                                          >
                                                            <p>{Description}</p>
                                                            {TreatmentPlanIntervention &&
                                                              TreatmentPlanIntervention.map(
                                                                (intervationItem, index5) => {
                                                                  const {
                                                                    InterventionTitle,
                                                                    Description,
                                                                    Status,
                                                                    Amount,
                                                                    Frequency,
                                                                    FrequencyId,
                                                                    TargetDate,
                                                                    CompletedDate,
                                                                    TreatmentPlanObjectiveId,
                                                                    TreatmentPlanInterventionId,
                                                                    InterventionNote,
                                                                    AppointmentNote,
                                                                    AssociatedNote,
                                                                    AccordinTitle,
                                                                  } = intervationItem;

                                                                  const interventionTitle = InterventionTitle;

                                                                  return (
                                                                    <>
                                                                      <Collapse
                                                                        defaultActiveKey={`${index5}`}

                                                                        // defaultActiveKey={
                                                                        //   expandAll
                                                                        //     ? true
                                                                        //     : `${index5}`
                                                                        // }
                                                                        // activeKey={
                                                                        //   expandAll
                                                                        //     ? true
                                                                        //     : `${index5}`
                                                                        // }

                                                                        //   activeKey={
                                                                        //     this.state.activeKey
                                                                        //       ? this.state.activeKey
                                                                        //       : expandAll
                                                                        //       ? true
                                                                        //       : `${index5}`
                                                                        //   }
                                                                        //   onChange={this.callback}
                                                                      >
                                                                        <Panel
                                                                          // header={`INTERVENTION${index5 +
                                                                          //   1} : ${Description}`}
                                                                          className="intervention-panel-border"
                                                                          header={
                                                                            <span
                                                                              style={{
                                                                                fontWeight: '800',
                                                                              }}
                                                                            >
                                                                              {AccordinTitle}
                                                                            </span>
                                                                          }
                                                                          // key={index5}
                                                                          key={`${index5}`}
                                                                          extra={
                                                                            <div className="d-flex justify-content-end">
                                                                              <ReduxEditDeleteComponent
                                                                                isReadOnlyUserSession={
                                                                                  isReadOnlyUserSession
                                                                                }
                                                                                apiKey={
                                                                                  'TreatmentPlanInterventionId'
                                                                                }
                                                                                apiValue={
                                                                                  TreatmentPlanInterventionId
                                                                                }
                                                                                editCallBack={() => {
                                                                                  this.toggleInterventionModal(
                                                                                    TreatmentPlanDimensionId,
                                                                                    TreatmentPlanObjectiveId,
                                                                                    TreatmentPlanInterventionId,
                                                                                    {
                                                                                      interventionDescription: Description,
                                                                                      interventionAmount: Amount,
                                                                                      interventionFrequency: FrequencyId,
                                                                                      interventionTargetDate: TargetDate,
                                                                                      interventionCompletedDate: CompletedDate,
                                                                                      interventionNote: InterventionNote,
                                                                                      AppointmentNote: AssociatedNote,
                                                                                    },
                                                                                  );
                                                                                }}
                                                                                isTreatmentPlanSigned={
                                                                                  isTreatmentPlanSigned
                                                                                }
                                                                              />
                                                                              {isTreatmentPlanSigned &&
                                                                                isTreatmentPlanSigned.IsSigned && (
                                                                                  <span
                                                                                    type="link"
                                                                                    size={'large'}
                                                                                    onClick={e => {
                                                                                      e.stopPropagation();
                                                                                      this.showDrawer(
                                                                                        AssociatedNote,
                                                                                      );
                                                                                    }}
                                                                                    className="cursor-pointer ml-3"
                                                                                  >
                                                                                    Associated Notes
                                                                                    {AssociatedNote
                                                                                      ? `(${AssociatedNote.length})`
                                                                                      : '(0)'}{' '}
                                                                                    &nbsp;
                                                                                    <img src="images/viewnotes.svg" />
                                                                                  </span>
                                                                                )}
                                                                            </div>
                                                                          }
                                                                        >
                                                                          {/* <div className="d-flex justify-content-end align-item-center">
                                                                          <Button
                                                                            type="link"
                                                                            size={'large'}
                                                                            onClick={() => {
                                                                              this.showDrawer(
                                                                                AssociatedNote,
                                                                              );
                                                                            }}
                                                                          >
                                                                            Show Associated Notes
                                                                          </Button>
                                                                        </div> */}
                                                                          <p>{Description}</p>
                                                                          <div className="row mx-0">
                                                                            <div
                                                                              className="col-6 py-3"
                                                                              style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 700,
                                                                                border:
                                                                                  '0.5px solid #bfbfbf',
                                                                                // border:
                                                                                //   '0.5px solid #25bde2',
                                                                              }}
                                                                            >
                                                                              Quantity:
                                                                              <span
                                                                                style={{
                                                                                  fontWeight: 300,
                                                                                  marginLeft: '5px',
                                                                                }}
                                                                              >
                                                                                {Amount}
                                                                              </span>
                                                                            </div>
                                                                            <div
                                                                              className="col-6 py-3"
                                                                              style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 700,
                                                                                // border:
                                                                                //   '0.5px solid #25bde2',
                                                                                border:
                                                                                  '0.5px solid #bfbfbf',
                                                                                borderLeft: 'unset',
                                                                              }}
                                                                            >
                                                                              Frequency:
                                                                              <span
                                                                                style={{
                                                                                  fontWeight: 300,
                                                                                  marginLeft: '5px',
                                                                                }}
                                                                              >
                                                                                {Frequency}
                                                                              </span>
                                                                            </div>
                                                                            <div
                                                                              className="col-6 py-3"
                                                                              style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 700,
                                                                                // borderRight:
                                                                                //   '0.5px solid #25bde2',
                                                                                border:
                                                                                  '0.5px solid #bfbfbf',
                                                                                // border:
                                                                                //   '0.5px solid #25bde2',
                                                                                borderTop: 'unset',
                                                                                textAlign: 'left',
                                                                              }}
                                                                            >
                                                                              Start Date:
                                                                              <span
                                                                                style={{
                                                                                  fontWeight: 300,
                                                                                  marginLeft: '5px',
                                                                                }}
                                                                              >
                                                                                {TargetDate}
                                                                              </span>
                                                                            </div>
                                                                            <div
                                                                              className="col-6 py-3"
                                                                              style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 700,
                                                                                border:
                                                                                  '0.5px solid #bfbfbf',
                                                                                // border:
                                                                                //   '0.5px solid #25bde2',
                                                                                borderTop: 'unset',
                                                                                textAlign: 'left',
                                                                                borderLeft: 'unset',
                                                                              }}
                                                                            >
                                                                              Projected Completion
                                                                              Date:
                                                                              <span
                                                                                style={{
                                                                                  fontWeight: 300,
                                                                                  marginLeft: '5px',
                                                                                }}
                                                                              >
                                                                                {CompletedDate}
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                          <div className="d-flex justify-content-Start">
                                                                            <span
                                                                              style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 700,
                                                                                // border:
                                                                                //   '0.5px solid #25bde2',
                                                                                textAlign: 'left',
                                                                                borderLeft: 'unset',
                                                                              }}
                                                                            >
                                                                              Intervention Note :
                                                                            </span>{' '}
                                                                            <span
                                                                              style={{
                                                                                fontWeight: 300,
                                                                                marginLeft: '5px',
                                                                              }}
                                                                            >
                                                                              {' '}
                                                                              {InterventionNote}
                                                                            </span>
                                                                          </div>
                                                                          <div className="d-flex justify-content-end">
                                                                            <div
                                                                              className="my-0 mt-3"
                                                                              style={{
                                                                                background:
                                                                                  '#4aaffd',
                                                                                color: '#fff',
                                                                                height: 'auto',
                                                                                borderRadius: '4px',
                                                                                display:
                                                                                  '-webkit-inline-box',
                                                                              }}
                                                                            >
                                                                              {Status ==
                                                                              'In Progress' ? (
                                                                                <img
                                                                                  src="images/InterventionInProgress.svg"
                                                                                  style={{
                                                                                    height: '40px',
                                                                                    padding: '10px',
                                                                                    cursor:
                                                                                      'pointer',
                                                                                  }}
                                                                                />
                                                                              ) : (
                                                                                <img
                                                                                  src="images/InterventionComplete.svg"
                                                                                  style={{
                                                                                    height: '40px',
                                                                                    padding: '10px',
                                                                                  }}
                                                                                />
                                                                              )}
                                                                              <span
                                                                                style={{
                                                                                  marginTop: '11px',
                                                                                  display: 'block',
                                                                                  marginRight:
                                                                                    '11px',
                                                                                }}
                                                                              >
                                                                                {Status}
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        </Panel>
                                                                      </Collapse>

                                                                      {/* <div className="row justify-content-center mt-2 mb-2">
                                                                    
                                                                        {Description}
                                                                      </div> */}
                                                                    </>
                                                                  );
                                                                },
                                                              )}
                                                            {!isTreatmentPlanSigned &&
                                                              !isReadOnlyUserSession && (
                                                                <div
                                                                  className="mt-4 mb-3 d-flex justify-content-end"
                                                                  onClick={e => {
                                                                    this.toggleInterventionModal(
                                                                      TreatmentPlanDimensionId,
                                                                      TreatmentPlanObjectiveId,
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    color: '#1890ff',
                                                                    cursor: 'pointer',
                                                                  }}
                                                                >
                                                                  <i class="fas fa-plus"></i> Add
                                                                  Intervention
                                                                </div>
                                                              )}
                                                          </Panel>
                                                        </Collapse>
                                                      </>
                                                    );
                                                  },
                                                )}
                                              {!isTreatmentPlanSigned && (
                                                <div
                                                  className="mt-4 mb-3 d-flex justify-content-end"
                                                  onClick={e => {
                                                    this.toggleObjectiveModal(
                                                      TreatmentPlanDimensionId,
                                                      TreatmentPlanGoalId,
                                                    );
                                                  }}
                                                  style={{ color: '#1890ff', cursor: 'pointer' }}
                                                >
                                                  Add Objective
                                                </div>
                                              )}
                                            </Panel>
                                          </Collapse>
                                        </>
                                      );
                                    })}
                                  {!isTreatmentPlanSigned && (
                                    <div
                                      className="mt-4 mb-3 d-flex justify-content-end"
                                      onClick={e => {
                                        this.toggleGoalModal(
                                          TreatmentPlanDimensionId,
                                          TreatmentPlanProblemId,
                                        );
                                      }}
                                      style={{ color: '#1890ff', cursor: 'pointer' }}
                                    >
                                      Add Goal
                                    </div>
                                  )}
                                </Panel>
                              </Collapse>
                            </>
                          );
                        })}
                      {!isTreatmentPlanSigned && (
                        <div
                          className="mt-4 mb-3 d-flex justify-content-end"
                          onClick={e => {
                            this.toggleProblemModal(TreatmentPlanDimensionId);
                          }}
                          style={{ color: '#1890ff', cursor: 'pointer' }}
                        >
                          Add Problem
                        </div>
                      )}
                    </Panel>
                  </Collapse>
                </div>
              );
            })}
          {this.props.IsWithoutASAM &&
            isTreatmentPlanSigned &&
            !isTreatmentPlanSigned.IsSigned &&
            !isReadOnlyUserSession && (
              <div className="mt-2 text-right" style={{ cursor: 'pointer' }}>
                <span
                  onClick={() => {
                    this.props.dispatch(
                      upsertTreatmentPlanDimension({
                        Json: JSON.stringify({
                          TreatmentPlanDimension: [
                            {
                              TreatmentPlanId: this.props.treatmentPlanId,
                              TreatmentPlanDimensionId: null,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                  className="mt-2 mb-2 add-contact"
                >
                  + Add New Dimension
                </span>
              </div>
            )}
          <div className="mt-4">
            {this.props.treatmentPlanId && (
              <LinkAppointment
                clientId={this.props.clientId}
                formType="TreatmentPlan"
                componentDataUniqueId={this.props.treatmentPlanId}
                linkData={this.props.isTreatmentPlanSigned}
                isReadOnlyUserSession={isReadOnlyUserSession}
                // isFetching={this.props.isFetching}
              />
            )}{' '}
          </div>
          {this.props.isTreatmentPlanSigned && this.props.isTreatmentPlanSigned.ClientSignatureUrl && (
            <div>
              <h5>Patient Signature:</h5>
              <img
                style={{ border: '1px solid black', width: '400px', height: 'auto' }}
                src={
                  this.props.isTreatmentPlanSigned &&
                  this.props.isTreatmentPlanSigned.ClientSignatureUrl
                }
              />
            </div>
          )}
        </div>

        <Modal
          zIndex={100}
          visible={this.state.isDimensionModalVisible}
          open={this.state.isDimensionModalVisible}
          footer={null}
          closable={false}
          width={400}
          destroyOnClose={true}
        >
          <ReduxAddDimensionModal
            treatmentPlanDimensionId={this.state.treatmentPlanDimensionId}
            IsWithoutASAM={this.props.IsWithoutASAM}
            // onCancel={() => {
            //   this.setState({
            //     isDimensionModalVisible: false,
            //   });
            // }}
            toggleDimensionModal={this.toggleDimensionModal}
            initialValues={this.state.initialValues}
            resetInitialValues={this.resetInitialValues}
            resetMessageText={this.resetMessageText}
            setTemplateValue={this.setTemplateValue}
            CurrentRiskRatingDropdown={this.props.CurrentRiskRatingDropdown}
          />
        </Modal>

        <Modal
          zIndex={100}
          visible={this.state.isProblemModalVisible}
          open={this.state.isProblemModalVisible}
          footer={null}
          closable={false}
          width={600}
          destroyOnClose={true}
        >
          <ReduxAddProblemModal
            treatmentPlanDimensionId={this.state.treatmentPlanDimensionId}
            treatmentPlanProblemId={this.state.treatmentPlanProblemId}
            onCancel={() => {
              this.setState({
                isProblemModalVisible: false,
              });
            }}
            toggleProblemModal={this.toggleProblemModal}
            initialValues={this.state.initialValues}
            resetInitialValues={this.resetInitialValues}
            resetMessageText={this.resetMessageText}
            setTemplateValue={this.setTemplateValue}
            clikedTreatmentPlanId={this.props.clikedTreatmentPlanId}
          />
        </Modal>
        <Modal
          zIndex={100}
          visible={this.state.isGoalModalVisible}
          open={this.state.isGoalModalVisible}
          footer={null}
          closable={false}
          width={600}
          destroyOnClose={true}
        >
          <ReduxAddGoalModal
            treatmentPlanDimensionId={this.state.treatmentPlanDimensionId}
            treatmentPlanProblemId={this.state.treatmentPlanProblemId}
            treatmentPlanGoalId={this.state.treatmentPlanGoalId}
            onCancel={() => {
              this.setState({
                isGoalModalVisible: false,
              });
            }}
            toggleGoalModal={this.toggleGoalModal}
            initialValues={this.state.initialValues}
            resetInitialValues={this.resetInitialValues}
            resetMessageText={this.resetMessageText}
            setTemplateValue={this.setTemplateValue}
            clikedTreatmentPlanId={this.props.clikedTreatmentPlanId}
          />
        </Modal>
        <Modal
          zIndex={100}
          visible={this.state.isObjetiveModalVisible}
          open={this.state.isObjetiveModalVisible}
          footer={null}
          closable={false}
          width={600}
          destroyOnClose={true}
        >
          <ReduxAddObjectiveModal
            treatmentPlanDimensionId={this.state.treatmentPlanDimensionId}
            treatmentPlanGoalId={this.state.treatmentPlanGoalId}
            treatmentPlanObjectiveId={this.state.treatmentPlanObjectiveId}
            onCancel={() => {
              this.setState({
                isObjetiveModalVisible: false,
              });
            }}
            toggleObjectiveModal={this.toggleObjectiveModal}
            initialValues={this.state.initialValues}
            resetInitialValues={this.resetInitialValues}
            resetMessageText={this.resetMessageText}
          />
        </Modal>
        <Modal
          zIndex={100}
          visible={this.state.isInterventionModalVisible}
          open={this.state.isInterventionModalVisible}
          footer={null}
          closable={false}
          width={650}
          destroyOnClose={true}
        >
          <ReduxAddInterventionModal
            treatmentPlanDimensionId={this.state.treatmentPlanDimensionId}
            treatmentPlanObjectiveId={this.state.treatmentPlanObjectiveId}
            treatmentPlanInterventionId={this.state.treatmentPlanInterventionId}
            onCancel={() => {
              this.setState({
                isInterventionModalVisible: false,
              });
            }}
            toggleInterventionModal={this.toggleInterventionModal}
            initialValues={this.state.initialValues}
            resetInitialValues={this.resetInitialValues}
            resetMessageText={this.resetMessageText}
            setTemplateValue={this.setTemplateValue}
            clientId={this.props.clientId}
          />
        </Modal>
        <CustomModal2
          zIndex={9999999}
          isOpen={this.state.isModelOpen}
          // header={errorMessage || 'Your changes have been saved successfully'}
          header={this.state.messageText}
          yesAction={() => {
            // this.toggle();
            // this.toggleView();
            // this.props.onCancel();
            // this.props.history.goBack();
            if (this.state.dataAPICallObj && Object.keys(this.state.dataAPICallObj).length > 0) {
              // let data = {
              //   Json: JSON.stringify(this.state.dataAPICallObj),
              // };
              // this.props.dispatch(getTreatmentPlan(data));
              let data = {
                Json: JSON.stringify({
                  TreatmentPlan: [
                    {
                      TreatmentPlanId: this.props.treatmentPlanId,
                    },
                  ],
                }),
              };
              // this.props.dispatch(getTreatmentPlan(data));
              this.props.dispatch(getTreatmentPlanAccordin(data));
            }

            this.setState({
              isModelOpen: false,
              messageText: '',
            });
          }}
          yesText="OK"
          hideCancel={true}
          changeMaxWidth="100%"
        />
      </>
    );
  }
}

const mapStateToPropsStyled = ({
  delete_treatment_plan,
  unsign_form,
  upsert_treatment_plan_dimension,
}) => {
  const { data: deleteTreatmentPlan } = delete_treatment_plan;
  const { data: unsignForm } = unsign_form;
  const { data: upsertTreatmentPlanDimensionData } = upsert_treatment_plan_dimension;

  var deleteTp = false;
  var deleteReturnData;
  var unsignFormStatus = false;
  let upsertTreatmentPlanDimensionDataSuccess;

  if (deleteTreatmentPlan && deleteTreatmentPlan.success) {
    deleteTp = deleteTreatmentPlan.success;
    deleteReturnData = JSON.parse(deleteTreatmentPlan.data)[0];
  }
  if (unsignForm && unsignForm.success) {
    unsignFormStatus = unsignForm.success;
  }
  if (upsertTreatmentPlanDimensionData && upsertTreatmentPlanDimensionData.success) {
    upsertTreatmentPlanDimensionDataSuccess = upsertTreatmentPlanDimensionData.success;
  }
  return {
    deleteTp,
    deleteReturnData,
    unsignFormStatus,
    upsertTreatmentPlanDimensionDataSuccess,
  };
};

const ReduxStyledTreeExample = connect(mapStateToPropsStyled)(withRouter(StyledTreeExample));

class TreatmentPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treatmentListView: true,
      clikedTreatmentPlanId: '',
      tempColumns: [
        // {
        //   title: 'Treatment Plan Id',
        //   dataIndex: 'TreatmentPlanId',
        //   key: 'TreatmentPlanId',
        //   sorter: (a, b) => isComparer(a.TreatmentPlanId, b.TreatmentPlanId),
        //   sortDirections: ['descend', 'ascend'],
        // },
        {
          title: 'Created Date',
          dataIndex: 'CreatedDate',
          key: 'CreatedDate',
          sorter: (a, b) => isDateComparer(a.CreatedDateForSort, b.CreatedDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Start Date',
          dataIndex: 'StartDate',
          key: 'StartDate',
          sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'End Date',
          dataIndex: 'EndDate',
          key: 'EndDate',
          sorter: (a, b) => isDateComparer(a.EndDateForSort, b.EndDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Last Signed Date',
          dataIndex: 'SignedDate',
          key: 'SignedDate',
          sorter: (a, b) => isDateComparer(a.SignedDateForSort, b.SignedDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Last Signed By',
          dataIndex: 'SignedBy',
          key: 'SignedBy',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.SignedBy, b.SignedBy),
        },
        {
          title: 'Last Signed By Role',
          dataIndex: 'SignedStaffRole',
          key: 'SignedStaffRole',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.SignedStaffRole, b.SignedStaffRole),
          render: (text, record, index) => {
            let selectedColor = 'volcano';
            switch (text) {
              case 'Not Signed':
                selectedColor = 'volcano';
                break;
              case 'Supervisor':
                selectedColor = 'green';
                break;
              case 'Counselor':
                selectedColor = 'blue';
                break;
              case 'Patient':
                selectedColor = 'purple';
                break;
            }
            // const colorDecider = {
            //   'Not signed': 'volcano',
            //   Supervisor: 'green',
            //   Counselor: 'geekblue',
            //   Client: 'purple',
            // };
            // let color = record.IsSigned ? 'geekblue' : 'volcano';
            // if (record.IsSupervisorSigned) {
            //   color = 'green';
            // }
            return (
              <>
                <Tag color={selectedColor} key={text}>
                  {text}
                </Tag>
              </>
            );
          },
        },
        // {
        //   title: 'Treatment Plan Description',
        //   dataIndex: 'TreatmentPlanDescription',
        //   key: 'TreatmentPlanDescription',
        //   sorter: (a, b) => isComparer(a.TreatmentPlanDescription, b.TreatmentPlanDescription),
        //   sortDirections: ['descend', 'ascend'],
        // },
        {
          title: 'Action',
          render: (text, record, index) => {
            var role = localStorage.getItem('userRole');
            return (
              <>
                {' '}
                <div class="tools-grid">
                  <>
                    <div className="mr-lg-2 mr-xl-5" style={{ cursor: 'pointer' }}>
                      <span
                        className="start-btn"
                        onClick={event => {
                          this.setState({
                            clikedTreatmentPlanId: record.TreatmentPlanId,
                          });
                          let data = {
                            Json: JSON.stringify({
                              TreatmentPlan: [
                                {
                                  TreatmentPlanId: record.TreatmentPlanId,
                                },
                              ],
                            }),
                          };
                          // this.props.dispatch(getTreatmentPlan(data));
                          this.props.dispatch(getTreatmentPlanAccordin(data));
                        }}
                      >
                        {/* {this.props.isReadOnlyUser
                          ? record.ActionText == 'View' && record.ActionText
                          : record.ActionText} */}
                        {record.IsSigned && record.ActionText}
                        {!record.IsSigned && !props.isDeActivated && record.ActionText}
                      </span>
                    </div>
                    {!record.IsSigned && !props.isDeActivated && (
                      <div className="">
                        <AntdModal
                          ModalButton={({ onClick }) => (
                            <span
                              class="delete-btn"
                              onClick={onClick}
                              data-toggle="modal"
                              data-target="#delete-Screen-Tool"
                            >
                              {this.props.isReadOnlyUser ? null : (
                                <Tooltip placement="top" title="Archive">
                                  <p>
                                    <img src="images/archive.svg" />
                                  </p>
                                </Tooltip>
                              )}
                            </span>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                              <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                <h4 class="modal-title col-12 mb-3">
                                  Are you sure you want to archive this Treatment Plan?
                                </h4>
                              </div>
                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  class="btn black-btn"
                                  onClick={() => {
                                    onCancel();
                                  }}
                                >
                                  No
                                </button>
                                <button
                                  class="btn"
                                  onClick={() => {
                                    onOk();
                                    this.props.dispatch(
                                      deleteTreatmentPlan({
                                        json: JSON.stringify({
                                          TreatmentPlan: [
                                            {
                                              TreatmentPlanId: record.TreatmentPlanId,
                                            },
                                          ],
                                        }),
                                      }),
                                    );
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    )}
                  </>
                </div>
              </>
            );
          },
        },
      ],
    };
  }
  refreshGrid = () => {
    let data = {
      Json: JSON.stringify({
        TreatmentPlan: [
          {
            ClientId: this.props.clientId,
          },
        ],
      }),
    };
    this.props.dispatch(listTreatmentPlan(data));
  };
  backButton = () => {
    this.setState({ treatmentListView: !this.state.treatmentListView });
    this.refreshGrid();
  };
  componentDidMount() {
    // this.props.dispatch(getTreatmentPlan({}));

    this.props.dispatch(resetListTreatmentPlan());
    this.refreshGrid();
    // let ComboTypes = {
    //   ComboTypes: 'Diagnosis',
    // };
    // this.props.dispatch(getDiagnosis(ComboTypes));
  }
  componentWillReceiveProps({
    treatmentData,
    listTP,
    clientId,
    cloneTPDataValue,
    deleteTp,
    deleteReturnData,
    isTreatmentPlanCreated,
  }) {
    if (JSON.stringify(treatmentData) != JSON.stringify(this.props.treatmentData)) {
      this.setState({ treatmentListView: false });
    }
    if (JSON.stringify(listTP) != JSON.stringify(this.props.listTP)) {
      this.setState({
        listTreatmentPlan: listTP,
      });
    }
    if (clientId != this.props.clientId) {
      this.refreshGrid();
    }
    if (cloneTPDataValue && cloneTPDataValue != this.props.cloneTPDataValue) {
      this.refreshGrid();
    }
    if (deleteTp && deleteTp != this.props.deleteTp) {
      this.refreshGrid();
    }
    if (isTreatmentPlanCreated && isTreatmentPlanCreated != this.props.isTreatmentPlanCreated) {
      if (this.state.treatmentListView) {
        this.refreshGrid();
        this.toggle3('Start creating treatment plan.');
      } else {
        this.refreshGrid();
      }
    }
  }

  toggle3 = header => {
    this.setState(prevState => ({
      isModelOpen3: !prevState.isModelOpen3,
      header,
    }));
  };
  render() {
    let locale = {
      emptyText:
        'Treatment plan will automatically be created when ASAM assessment  has assessed level of care.',
    };
    const {
      treatmentData,
      cloneBtn,
      printDetails,
      isReadOnlyUserSession,
      isDeActivated,
    } = this.props;
    const { treatmentListView, listTreatmentPlan } = this.state;
    return (
      <React.Fragment>
        <div
          style={{
            padding: '50px 25px 200px 0px',
            // overflowX: 'scroll',
            // height: '150vh'
            position: 'relative',
            overflow: 'visible',
          }}
          className="treatmentplan"
        >
          {treatmentListView ? (
            <div className="rcm-subtab-table-block ">
              <Loader2 loading={this.props.listTreatmentPlanDataFetching} />
              <p className="px-5 pt-3 text-right">
                <span
                  style={{ color: '#4ab0fe', cursor: 'pointer' }}
                  onClick={() => {
                    this.props.dispatch(
                      upsertTreatmentPlan({
                        Json: JSON.stringify({
                          TreatmentPlan: [
                            {
                              ClientId: this.props.clientId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Create treatment plan (without ASAM)
                </span>
              </p>
              {!this.props.isReadOnlyUser && !isDeActivated && cloneBtn && (
                <p className="px-5 pt-3 text-right">
                  <span
                    style={{ color: '#4ab0fe', cursor: 'pointer' }}
                    onClick={() => {
                      this.props.dispatch(
                        cloneTreatmentPlan({
                          Json: JSON.stringify({
                            TreatmentPlan: [{ ClientId: this.props.clientId }],
                          }),
                        }),
                      );
                    }}
                  >
                    Clone and create most recently signed treatment plan
                  </span>
                </p>
              )}
              <PersistTableSort
                name="pat-listTreatmentPlan"
                columns={this.state.tempColumns}
                dataSource={listTreatmentPlan}
                size={'middle'}
                pagination={{
                  itemRender: (page, type, originalElement) => {
                    return (
                      <div class="dataTables_wrapper no-footer">
                        <div class="dataTables_paginate paging_simple_numbers">
                          {type == 'prev' ? (
                            <a class="paginate_button previous">Previous</a>
                          ) : type == 'next' ? (
                            <a class="paginate_button next">Next</a>
                          ) : (
                            <a class="paginate_button ">{page}</a>
                          )}
                        </div>
                      </div>
                    );
                  },
                  showSizeChanger: true,
                  // hideOnSinglePage: true,
                }}
                style={{ padding: '20px' }}
                locale={locale}
              />
              <CustomModal2
                isOpen={this.state.isModelOpen3}
                header={this.state.header}
                yesAction={() => {
                  this.toggle3();
                }}
                yesText="OK"
                hideCancel={true}
              />
            </div>
          ) : (
            <div>
              <div className="assessment-main-title d-flex align-items-center justify-content-between">
                <div
                  onClick={() => {
                    this.backButton(true);
                  }}
                  style={{ cursor: 'pointer' }}
                  className="text-left d-flex"
                >
                  <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                </div>
                <div></div>
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  // paddingLeft: '30px',
                  paddingBottom: '100px',
                  margin: '50px 10px',
                  // overflow: 'auto',
                  overflow: 'visible',
                }}
              >
                <CustomModal2
                  isOpen={this.state.isModelOpen3}
                  header={this.state.header}
                  yesAction={() => {
                    this.toggle3();
                    // this.props.onCancel();
                    this.backButton(true);
                  }}
                  yesText="OK"
                  hideCancel={true}
                />
                <div className="mb-xl-4">
                  {treatmentData && treatmentData.length > 0 && (
                    <ReduxTreatmentPlanSubmissionForm
                      isReadOnlyUserSession={isReadOnlyUserSession}
                      isFetching={this.props.isFetching}
                      clientId={this.props.clientId}
                      TreatmentPlanId={this.state.clikedTreatmentPlanId}
                      isTreatmentPlanSigned={this.props.isTreatmentPlanSigned}
                      // initialValues={this.props.isTreatmentPlanSigned}
                      toggle={this.toggle3}
                      backButton={this.backButton}
                      isDeActivated={this.props.isDeActivated}
                      initialValues={{
                        startDate: (() => {
                          if (
                            this.props.isTreatmentPlanSigned &&
                            this.props.isTreatmentPlanSigned.StartDate
                          ) {
                            return moment(this.props.isTreatmentPlanSigned.StartDate).format(
                              'YYYY-MM-DD',
                            );
                          } else return;
                        })(),
                        endDate: (() => {
                          if (
                            this.props.isTreatmentPlanSigned &&
                            this.props.isTreatmentPlanSigned.EndDate
                          ) {
                            return moment(this.props.isTreatmentPlanSigned.EndDate).format(
                              'YYYY-MM-DD',
                            );
                          } else return;
                        })(),
                        isIndividualCopyRecieved: this.props.isTreatmentPlanSigned
                          .IsIndividualCopyRecieved,
                      }}
                    />
                  )}
                </div>
                <ReduxStyledTreeExample
                  lineColor="#000"
                  treatmentData={treatmentData}
                  clikedTreatmentPlanId={this.state.clikedTreatmentPlanId}
                  backButton={this.backButton}
                  clientId={this.props.clientId}
                  isTreatmentPlanSigned={this.props.isTreatmentPlanSigned}
                  treatmentPlanId={this.state.clikedTreatmentPlanId}
                  printDetails={printDetails}
                  lastUpdatedDimensionId={this.props.lastUpdatedDimensionId}
                  IsWithoutASAM={this.props.IsWithoutASAM}
                  isReadOnlyUserSession={isReadOnlyUserSession}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  // get_treatment_plan,
  get_treatment_plan_accordin,
  list_treatment_plan,
  clone_treatment_plan,
  delete_treatment_plan,
  lastUpdatedDimensionId,
  upsert_treatment_plan,
}) => {
  const { data: upsertTreatmentPlan } = upsert_treatment_plan;

  // const { data, isFetching, error } = get_treatment_plan;

  const { data, isFetching, error } = get_treatment_plan_accordin;

  const { data: cloneTPData } = clone_treatment_plan;
  const {
    data: listTreatmentPlanData,
    isFetching: listTreatmentPlanDataFetching,
  } = list_treatment_plan;
  var tData;
  var isTreatmentPlanSigned;
  var IsWithoutASAM;
  var listTP;
  var cloneTPDataValue = false;
  var cloneBtn = false;
  var deleteTp = false;
  let isTreatmentPlanCreated = false;
  var deleteReturnData;

  if (delete_treatment_plan && delete_treatment_plan.data) {
    deleteTp = delete_treatment_plan.data.success;
    deleteReturnData = JSON.parse(delete_treatment_plan.data.data)[0];
  }

  if (data) {
    // tData = JSON.parse(data.data)[0].TreatmentPlanDimension;

    // isTreatmentPlanSigned = JSON.parse(data.data)[0].TreatmentPlan[0];
    tData = data.TreatmentPlanDimension;
    isTreatmentPlanSigned = data.TreatmentPlan[0];
    IsWithoutASAM = data.TreatmentPlan[0].IsWithoutASAM;
  }

  if (listTreatmentPlanData && listTreatmentPlanData.TreatmentPlan) {
    listTP = listTreatmentPlanData.TreatmentPlan;
    cloneBtn = listTreatmentPlanData.TreatmentPlan.some(item => {
      return item.IsSigned;
    });
  }
  if (cloneTPData && cloneTPData.success) {
    cloneTPDataValue = cloneTPData.success;
  }
  if (upsertTreatmentPlan && upsertTreatmentPlan.success) {
    isTreatmentPlanCreated = true;
  }
  return {
    treatmentData: tData || [],
    listTP: listTP || [],
    listTreatmentPlanDataFetching,
    isTreatmentPlanSigned,
    cloneTPDataValue,
    cloneBtn,
    deleteTp,
    deleteReturnData,
    lastUpdatedDimensionId: lastUpdatedDimensionId.lastUpdatedDimensionId,
    isFetching,
    isTreatmentPlanCreated,
    IsWithoutASAM,
  };
};

export default connect(mapStateToProps)(withRouter(TreatmentPlan));
