import React, { Component } from 'react';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportExcel extends React.Component {
  render() {
    const { reportData, labelText, filename } = this.props;
    const reportArr = Object.values(reportData);
    const sheetName = Object.keys(reportData);
    return (
      <ExcelFile
        filename={filename}
        element={
          // <button className="btn px-5 py-2">Export</button>
          <div
            className="excel-download d-flex align-items-center"
            style={{ position: 'relative' }}
          >
            <span class="tooltiptext">Download Excel</span>
            <img className="mr-2" src="images/excel_icon.png" width="20" alt="Export Excel" />{' '}
            <span className="mr-2 report-text">{labelText ? labelText : 'XLSX'}</span>
          </div>
        }
      >
        {reportArr &&
          reportArr[0] != undefined &&
          reportArr.map((arrData, index) => {
            let keys = Object.keys(arrData[0]);
            return (
              <ExcelSheet data={arrData} name={sheetName[index]}>
                {keys &&
                  keys.map(key => (
                    <ExcelColumn
                      label={key}
                      value={key}
                      // value={col => (col.is_married ? 'Married' : 'Single')}
                    />
                  ))}
              </ExcelSheet>
            );
          })}
      </ExcelFile>
    );
  }
}
export default ExportExcel;
