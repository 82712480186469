import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { Cascader } from 'antd';
import SubmitButton from '../../../../../Components/submitButton';
import { ReduxTextarea } from '../../../../../component/custom-redux-components';
import { Field, reduxForm } from 'redux-form';
import {
  upsertTreatmentPlanProblem,
  getTreatmentPlan,
  comboProblemTemplate,
} from '../../../../../store/actions';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (beginningTime.isBefore(endTime)) {
    } else {
      return 'should be greater than Start time';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class AddProblemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      comboProblemDataState: [],
      isButtonDisabled: false,
    };
  }

  onLaunchClicked = event => {
    // event.preventDefault();
    this.setState({
      isButtonDisabled: true,
    });

    // **** here's the timeout ****
    setTimeout(() => this.setState({ isButtonDisabled: false }), 5000);

    // return this.props.onLaunchClicked();
  };

  onChange = (value, selectedOptions) => {
    // this.setState({
    //   text: selectedOptions.map(o => o.label).join(', '),
    // });
    this.props.setTemplateValue({
      problemText: selectedOptions.map(o => o.label).join(', '),
    });
    this.props.change('problemText', selectedOptions.map(o => o.label).join(', '));
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  componentDidMount() {
    this.props.dispatch(comboProblemTemplate({}));
  }
  componentWillReceiveProps({ upsertTreatmentPlanProblemDataSuccess, comboProblemData }) {
    if (
      upsertTreatmentPlanProblemDataSuccess &&
      upsertTreatmentPlanProblemDataSuccess != this.props.upsertTreatmentPlanProblemDataSuccess
    ) {
      this.props.toggleProblemModal();
      // let data = {
      //   Json: JSON.stringify({
      //     TreatmentPlan: [
      //       {
      //         // TreatmentPlanId: this.props.clikedTreatmentPlanId,
      //         TreatmentPlanDimensionId: this.props.treatmentPlanDimensionId,
      //       },
      //     ],
      //   }),
      // };
      // this.props.dispatch(getTreatmentPlan(data));
      this.props.resetMessageText(
        `Problem ${this.props.treatmentPlanProblemId ? 'Updated' : 'Added'} Successfully`,
        {
          Json: JSON.stringify({
            TreatmentPlan: [
              {
                // TreatmentPlanId: this.props.clikedTreatmentPlanId,
                TreatmentPlanDimensionId: this.props.treatmentPlanDimensionId,
              },
            ],
          }),
        },
      );
    }
    if (comboProblemData != this.props.comboProblemData) {
      let temp = comboProblemData.map(item => {
        return {
          label: item.Text,
          value: item.Value,
        };
      });
      this.setState({
        comboProblemDataState: temp,
      });
    }
  }

  onhandleSubmit = val => {
    this.onLaunchClicked();
    const { treatmentPlanDimensionId, treatmentPlanProblemId } = this.props;
    let data;
    if (treatmentPlanDimensionId && treatmentPlanProblemId) {
      data = {
        Json: JSON.stringify({
          TreatmentPlanProblem: [
            {
              TreatmentPlanDimensionId: treatmentPlanDimensionId,
              TreatmentPlanProblemId: treatmentPlanProblemId,
              Description: val.problemText,
              ProblemTemplateId: 1,
            },
          ],
        }),
      };
    } else {
      data = {
        Json: JSON.stringify({
          TreatmentPlanProblem: [
            {
              TreatmentPlanProblemId: null,
              TreatmentPlanDimensionId: treatmentPlanDimensionId,
              Description: val.problemText,
              ProblemTemplateId: 1,
            },
          ],
        }),
      };
    }

    this.props.dispatch(upsertTreatmentPlanProblem(data, treatmentPlanDimensionId));
    // this.props.toggleProblemModal();
  };

  next = () => {
    this.carousel.next();
  };
  previous = () => {
    this.carousel.prev();
  };
  render() {
    const { onCancel, isFetching } = this.props;
    const { comboProblemDataState } = this.state;
    return (
      <>
        <div class="modal-content border-0">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={() => {
                onCancel();

                this.props.resetInitialValues();
              }}
            >
              ×
            </button>
            <h4 class="modal-title col-12 text-left">Problem</h4>
            {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
          </div>
          <div class="modal-body rcm-panel">
            <div className="px-4 enrollment-form">
              <div style={{ background: 'white' }}>
                {/* <Slider {...settings}>
                  {this.props.comboProblemData &&
                    this.props.comboProblemData.map(item => {
                      return (
                        <div>
                          <p
                            style={{ border: '1px solid #bfbfbf', height: '50px', padding: '5px' }}
                          >
                            {item.Text}
                          </p>
                        </div>
                      );
                    })}
                </Slider> */}

                <br />
              </div>
              {/* <br />
              <br />
              <br />
              <br />
              <br /> */}

              <form onSubmit={this.props.handleSubmit(this.onhandleSubmit)}>
                <div className="d-flex">
                  <div class="form-group mb-2" style={{ flexGrow: '8' }}>
                    <Field
                      name="problemText"
                      type="textarea"
                      component={ReduxTextarea}
                      fieldName={'Problem Description'}
                      validate={Validator.required}
                      rows={'4'}
                      cols={'50'}
                      style={{ minHeight: '92px' }}
                    />
                    <label class="placeholder-label" style={{ background: '#fefefe' }}>
                      Problem Description*
                    </label>
                  </div>
                  <div style={{ flexGrow: '1', marginLeft: '5px' }}>
                    {/* <Popover
                      content={<a onClick={this.hide}>Close</a>}
                      title="Title"
                      trigger="click"
                      visible={this.state.visible}
                      onVisibleChange={this.handleVisibleChange}
                    > */}
                    <Cascader options={comboProblemDataState} onChange={this.onChange}>
                      <a href="#" style={{ color: 'grey' }}>
                        {/* <Icon type="down-circle" /> */}
                        <i class="fas fa-chevron-circle-down"></i>
                      </a>
                    </Cascader>

                    {/* </Popover> */}
                  </div>
                </div>
                <div class="modal-footer  border-top-0 pt-2 px-0 mr-0">
                  <SubmitButton
                    loading={isFetching}
                    style={{ padding: '0 24px', height: '44px' }}
                    value={this.props.treatmentPlanProblemId ? ' Update Problem' : '  Add Problem'}
                    disabled={this.state.isButtonDisabled}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ upsert_treatment_plan_problem, combo_problem_template }) => {
  const { data, isFetching } = upsert_treatment_plan_problem;
  const { data: comboProblemTemplateData } = combo_problem_template;
  let upsertTreatmentPlanProblemDataSuccess;
  let comboProblemData;
  if (upsert_treatment_plan_problem && upsert_treatment_plan_problem.data) {
    upsertTreatmentPlanProblemDataSuccess = upsert_treatment_plan_problem.data.success;
  }
  if (comboProblemTemplateData) {
    comboProblemData = comboProblemTemplateData.ProblemTemplate;
  }
  return {
    comboProblemData: comboProblemData || [],
    upsertTreatmentPlanProblemDataSuccess: upsertTreatmentPlanProblemDataSuccess || false,
    isFetching,
  };
};

const ReduxAddProblemModal = reduxForm({
  form: 'AddProblemModal',
  enableReinitialize: true,
})(connect(mapStateToProps)(AddProblemModal));

export { ReduxAddProblemModal };
