import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { Checkbox, Radio, DatePicker, Modal, Drawer, Button, Pagination } from 'antd';
import ClientRiskChart from '../../EnterpriseClient/components/ClientRiskChart';
import ReactApexRiskChart from '../../EnterpriseClient/components/ReactApexRiskChart';
import ViewAnswerDrawer from '../../EnterpriseClient/pre-intake/dashboard/ViewAnswerTab';
import { ProfilePicComponent } from '../../../Components/profilePicComponent';

class ProgramAllignment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { EventJsonData, StudentId, showAnswerDrawerDrawer } = this.props;
    const { visibleAnswerDrawer } = this.state;
    let {
      FlaggedCount,
      Role,
      ImageUrl,
      IsQualified,
      QualifiedCount,
      Status,
      UpdatedBy,
      UpdatedOn,
      FinalRisk,
      SurveyId,
    } = EventJsonData[0] || {};
    return (
      <div class="border-0 update-history-card">
        <div className="row survey-event-details">
          <div className="screening-questions-answered-event w-100">
            <div className="note-event">
              <div className="added-by">
                <span className="added-text two">Added by</span>
                {/* <img src={ImageUrl} /> */}
                <ProfilePicComponent src={ImageUrl} altText={'Image'} />
                <div className="name-box">
                  <span className="name">{UpdatedBy}</span>
                  <span className="role">{Role}</span>
                </div>
              </div>
            </div>

            <div className="status d-flex">
              <ClientRiskChart ClientRisk={(FinalRisk && FinalRisk.toString()) || '0'} />
              <button
                title="View Answers"
                class="survey-btn btn btn-outline-primary black-btn py-1"
                onClick={() => {
                  showAnswerDrawerDrawer(SurveyId, EventJsonData[0] || {}, 'Program Alignment');
                }}
                style={{
                  display: 'inline',
                }}
              >
                View Answers{' '}
                <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                  <img src="images/view-answer.svg" alt="" width="21" />
                </figure>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div className="col-12">
            <ReactApexRiskChart riskChartInfo={EventJsonData} isBackground={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default ProgramAllignment;
