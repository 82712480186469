import React, { useState, useEffect, Component } from 'react';
import { Table } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router-dom';
import PayerDetails from './payerDetails';
import CreateContract from './createContract';
import Authorizations from '../authorizations';
import Ledger from './ledger';
import Requests from './requests';
import {
  resetSmartContractGet,
  smartContractGet,
  resetSmartContractUpsert,
  clientInsuranceGet,
  smartContractPatientListGet,
  resetServiceCodeBundleGet,
  smartContractClone,
} from '../../../../../store/actions';
import CustomModal from '../../../../../core/modal';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import GridSearch from '../../../../../Components/gridSearch';
import ExpandIcon from '../../../../../util/expandIcon';
import BundleRowRender from '../../expandedRow/bundleRowRender';
import SmartContractComponent from './smartContractForm';
import getParam from '../../../../../util/getParam';
class SmartContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smartContractListView: getParam(props.history, 'smartContractId')
        ? false
        : getParam(props.history, 'new-contract') == 'true'
        ? false
        : true,
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleURLChange);
    this.props.dispatch(
      smartContractPatientListGet({
        json: JSON.stringify({
          SmartContract: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  }

  handleURLChange = () => {
    let newURL = window.location.href;
    if (
      getParam(this.props.history, 'smartContractId') &&
      this.state.smartContractListView == true
    ) {
      this.props.history.push(
        `/cw/patient-management/patient-profile?tab=smart-contract&smartContractId=${getParam(
          this.props.history,
          'smartContractId',
        )}&pId=${this.getClientId()}`,
      );
      this.setState({
        smartContractListView: false,
      });
    }
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen123: !prevState.isModelOpen123,
      header,
    }));
  };

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleURLChange);
  }

  onSearchChange = value => {
    const { dispatch } = this.props;
    this.state.searchStr = value;
    // let filterVal = this.state.filterBy === 'All' ? null : this.state.filterBy;
    // dispatch(
    //   smartContractListGet({
    //     json: JSON.stringify({
    //       SmartContract: [
    //         {
    //           Filter: filterVal,
    //           Search: this.state.searchStr,
    //         },
    //       ],
    //     }),
    //   }),
    // );
    dispatch(
      smartContractPatientListGet({
        json: JSON.stringify({
          SmartContract: [
            {
              ClientId: this.getClientId(),
              Search: this.state.searchStr,
            },
          ],
        }),
      }),
    );
  };

  showListView = () => {
    this.setState({ smartContractListView: true });
  };

  backButton = bool => {
    this.props.dispatch(resetSmartContractGet());
    this.props.dispatch(reset('contractForm'));
    this.props.history.push(
      `/cw/patient-management/patient-profile?tab=smart-contract&pId=${this.getClientId()}`,
    );
    this.setState({ smartContractListView: !this.state.smartContractListView });
  };

  componentWillReceiveProps({ submitToPayerSuccess, clonedMessage }) {
    if (submitToPayerSuccess && submitToPayerSuccess != this.props.submitToPayerSuccess) {
      this.props.dispatch(
        smartContractPatientListGet({
          json: JSON.stringify({
            SmartContract: [
              {
                ClientId: this.getClientId(),
              },
            ],
          }),
        }),
      );
    }
    if (clonedMessage && clonedMessage != this.props.clonedMessage) {
      this.toggle(clonedMessage);
    }
  }

  render() {
    const { isFetching, smartContractList } = this.props;

    const columns = [
      {
        title: 'Contract Id',
        dataIndex: 'SmartContractId',
        key: 'SmartContractId',
        sorter: (a, b) => isNumericComparer(a.SmartContractId, b.SmartContractId),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div className="text-right">{text}</div>,
      },
      {
        title: 'Contract Name',
        dataIndex: 'ContractName',
        key: 'ContractName',
        sorter: (a, b) => isComparer(a.ContractName, b.ContractName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Date',
        dataIndex: 'StartDate',
        key: 'StartDate',
        sorter: (a, b) => isDateComparer(a.StartDateSort, b.StartDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date',
        dataIndex: 'EndDate',
        key: 'EndDate',
        sorter: (a, b) => isDateComparer(a.EndDateSort, b.EndDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Total Bundles',
        dataIndex: 'TotalBundles',
        key: 'TotalBundles',
        sorter: (a, b) => isNumericComparer(a.TotalBundles, b.TotalBundles),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div className="text-right">{text}</div>,
      },
      {
        title: 'Total Services',
        dataIndex: 'TotalServices',
        key: 'TotalServices',
        sorter: (a, b) => isNumericComparer(a.TotalServices, b.TotalServices),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div className="text-right">{text}</div>,
      },
      {
        title: 'Payer',
        dataIndex: 'PayerCount',
        key: 'PayerCount',
        sorter: (a, b) => isNumericComparer(a.PayerCount, b.PayerCount),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <div className="text-right">{text}</div>,
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        sorter: (a, b) => isComparer(a.Status, b.Status),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          let status = {
            1: 'incomplete',
            2: 'pending',
            3: 'active',
            4: 'terminated',
            5: 'expired',
            6: 'delivered',
            7: 'declined',
          };
          return (
            <div className="contract-status">
              {text && <span className={status[record.StatusId]}>{text}</span>}
            </div>
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (text, record) => {
          if (record.StatusId) {
            return (
              <>
                <span
                  style={{
                    color: '#4FACFE',
                    fontSize: '14px',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.props.history.push(
                      `/cw/patient-management/patient-profile?tab=smart-contract&smartContractId=${
                        record.SmartContractId
                      }&pId=${this.getClientId()}`,
                    );
                    this.setState({
                      smartContractListView: false,
                    });
                  }}
                >
                  <i class="far fa-eye form-control-icon" />
                  &nbsp;View Contract
                </span>{' '}
                &nbsp; &nbsp;
                <span
                  style={{
                    color: '#4FACFE',
                    fontSize: '14px',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.props.dispatch(
                      smartContractClone({
                        Json: JSON.stringify({
                          SmartContract: [
                            {
                              SmartContractId: record.SmartContractId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  <i class="fa fa-clone" aria-hidden="true"></i> Clone
                </span>
              </>
            );
          } else {
            return;
          }
        },
      },
    ];
    return (
      <div class="w-100 rcm-panel rcm-patient-table">
        <div class="main enrollment-page mb-5">
          <div class="container-fluid  px-2 py-4">
            {this.state.smartContractListView ? (
              <>
                {' '}
                <div class="content-area">
                  <div class="row top-table-search mt-1 mb-2 align-items-center">
                    <div class="col-12 col-md-12 text-right d-flex align-items-center justify-content-end">
                      <div className="text-left app-subscriber-page d-inline-block">
                        <div class="form-group mr-3 mb-0" style={{ width: '230px' }}></div>
                      </div>

                      <GridSearch onSearchChange={this.onSearchChange} />
                      <button
                        class="btn black-btn"
                        type="button"
                        onClick={event => {
                          this.props.dispatch(resetSmartContractGet({}));
                          this.props.dispatch(resetServiceCodeBundleGet({}));
                          this.props.dispatch(reset('contractForm'));
                          this.props.history.push(
                            `/cw/patient-management/patient-profile?tab=smart-contract&new-contract=true&pId=${this.getClientId()}`,
                          );
                          this.setState({
                            smartContractListView: false,
                          });
                        }}
                      >
                        Add New Contract
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 mb-5">
                      <div class="card border-0 ">
                        <div class="row card-body">
                          <div class="col-12">
                            <Loader2 loading={isFetching} />
                            <Table
                              columns={columns}
                              dataSource={smartContractList}
                              size={'middle'}
                              // pagination={{
                              //   itemRender: (page, type, originalElement) => {
                              //     return (
                              //       <div class="dataTables_wrapper no-footer">
                              //         <div class="dataTables_paginate paging_simple_numbers">
                              //           {type == 'prev' ? (
                              //             <a class="paginate_button previous">Previous</a>
                              //           ) : type == 'next' ? (
                              //             <a class="paginate_button next">Next</a>
                              //           ) : (
                              //             <a class="paginate_button ">{page}</a>
                              //           )}
                              //         </div>
                              //       </div>
                              //     );
                              //   },
                              //   showSizeChanger: true,
                              // }}
                              pagination={false}
                              expandable={{
                                expandedRowRender: BundleRowRender,
                                expandIcon: ExpandIcon,
                              }}
                              className="nested-tables"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {' '}
                <div
                  onClick={() => {
                    this.backButton(true);
                  }}
                  style={{ cursor: 'pointer' }}
                  className="text-left d-flex"
                >
                  <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                </div>
                <SmartContractComponent
                  showListView={this.showListView}
                  // {...this.props}
                />
              </>
            )}
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen123}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
              this.props.dispatch(
                smartContractPatientListGet({
                  json: JSON.stringify({
                    SmartContract: [
                      {
                        ClientId: this.getClientId(),
                      },
                    ],
                  }),
                }),
              );
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    smart_contract_patient_list_get,
    smart_contract_bundle_submit_to_payer,
    smart_contract_clone,
  } = state;
  const { data, isFetching, error } = smart_contract_patient_list_get;
  const { data: data2 } = smart_contract_bundle_submit_to_payer;
  const { data: smartContractCloneAction } = smart_contract_clone;

  let smartContractList = null;
  let submitToPayerSuccess = false;
  let clonedMessage = null;

  if (data && data.data) {
    smartContractList = JSON.parse(data.data).Contract;
  }
  if (data2 && data2.success) {
    submitToPayerSuccess = data2.success;
  }
  if (smartContractCloneAction && smartContractCloneAction.data) {
    clonedMessage =
      JSON.parse(smartContractCloneAction.data) &&
      JSON.parse(smartContractCloneAction.data).SmartContract &&
      JSON.parse(smartContractCloneAction.data).SmartContract[0] && 
    JSON.parse(
        smartContractCloneAction.data,
      ).SmartContract[0].Message;
  }
  return {
    smartContractList,
    isFetching,
    submitToPayerSuccess,
    clonedMessage
  };
};
export default connect(mapStateToProps)(withRouter(SmartContract));
