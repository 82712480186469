import React, { Component } from 'react';
import { Collapse } from 'antd';
import { RightCircleOutlined, RightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export default class TargetPriceBundleService extends Component {
  render() {
    const { serviceCodes, activeKey } = this.props || [];
    return (
      <div className="payer-milestone my-2 target-service ml-0 row" style={{ width: '100%' }}>
        <Collapse
          expandIcon={({ isActive }) => (
            <div className="d-flex flex-column align-items-end m-0">
              <p className="mb-0" style={{ fontSize: '18px', fontWeight: '700', color: '#182838' }}>
                {serviceCodes.BillingRate}
              </p>
              <RightCircleOutlined
                rotate={isActive ? -90 : 90}
                style={{
                  fontSize: '20px',
                  marginTop: '4px',
                  color: '#7F8188',
                }}
              />
              {/* <RightOutlined /> */}
            </div>
          )}
          key={activeKey}
          defaultActiveKey={activeKey}
          // className="col-10"
          style={{ flexGrow: '1', width: '85%' }}
          //   onChange={onChange}
        >
          <Panel
            header={
              <div className="d-flex flex-column">
                <span
                  style={{
                    color: '#959595',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >{`Service Code ${activeKey}`}</span>
                <span
                  style={{
                    color: 'black',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}
                >
                  {serviceCodes.CodeId}
                </span>
              </div>
            }
            key={activeKey}
          >
            <div className="row">
              <div className="col-12 mb-2 p-0">
                <div className="border-0 students-profile">
                  <p
                    className="mb-0"
                    style={{
                      color: '#959595',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                  >
                    Code Description
                  </p>
                  <p
                    className="mb-0"
                    style={{
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: '400',
                    }}
                  >
                    {serviceCodes.ServiceCodeDescription}
                  </p>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
