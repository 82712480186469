import React, { Component } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../../component/custom-redux-components';
import Validator from '../../../Validation';

const NumericOptionForm = ({
  fields,
  name,
  meta: { touched, error },
  change,
  disable,
  existingRows,
  dispatch,
  SurveyNumericInputCondition,
}) => {
  return (
    <>
      <div class="row">
        <div class="col-12 text-left pt-1 pb-1">
          {fields.length < 10 && (
            <a class="add-contact" onClick={() => fields.push({})} style={{ marginLeft: 'unset' }}>
              {fields.length >= 1 && (
                <label>
                  <a> + Add Another Option</a>
                </label>
              )}
              {fields.length == 0 && (
                <label>
                  {' '}
                  <a> + Add Option</a>
                </label>
              )}
            </a>
          )}
        </div>
      </div>

      <div class="answer-options-section" style={{ maxHeight: '350px' }}>
        {fields.map((options, index) => {
          return (
            <div class="row mb-3 options-row">
              <div className="col-5">
                <div className="d-flex">
                  <div class="form-group w-100">
                    <Field
                      name={`${options}.condition`}
                      type="text"
                      component={ReduxSelect}
                      options={SurveyNumericInputCondition}
                      placeholder="Condition"
                      fieldName={'Condition'}
                      validate={Validator.required}
                      // disabled={disable}
                    />
                    {/* <label class="placeholder-label">Condition</label> */}
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="d-flex">
                  <div class="form-group w-100">
                    <Field
                      name={`${options}.range`}
                      type="text"
                      component={ReduxInput}
                      fieldName={'Range'}
                      validate={Validator.required}
                      // disabled={disable}
                    />
                    <label class="placeholder-label">Range</label>
                  </div>
                </div>
              </div>
              <div className="col-4 mt-3">
                <div class="form-group">
                  <Field
                    className="mr-2"
                    name={`${options}.IsFlagged`}
                    component={ReduxCheckbox}
                    fieldName={'isFlagged'}
                  />
                  <label class="placeholder-label" style={{ top: '5px' }}>
                    Mark as Flagged
                  </label>
                </div>
              </div>
              {fields.length > 1 && (
                <div className="col-1">
                  <a
                    class="delete-contact"
                    onClick={() => fields.remove(index)}
                    style={{ marginLeft: 'unset' }}
                  >
                    <i class="fas fa-trash" />
                  </a>
                </div>
              )}
              {/* <div className="col-12">
                <div class="form-group">
                  <label class="custom-control-label">
                    <Field
                      name={`${options}.IsFollowUpQuestion`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                    />
                    {'   '}
                    Add Follow Up Question
                  </label>
                </div>
              </div> */}
            </div>
          );
        })}
      </div>
    </>
  );
};
class Numeric extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { QuestionFormatId, name, answerOptions, questionFormValue } = this.props;
    const { SurveyNumericInputCondition } = answerOptions || {};

    return (
      // <FieldArray
      //   name={name}
      //   component={NumericOptionForm}
      //   SurveyNumericInputCondition={SurveyNumericInputCondition}
      // />
      <div class="row mb-3 options-row">
        <div className="col-4">
          <div className="d-flex">
            <div class="form-group w-100">
              <Field
                name={`ThresholdSign`}
                type="text"
                component={ReduxSelect}
                options={SurveyNumericInputCondition}
                placeholder="Threshold*"
                fieldName={'Threshold'}
                validate={Validator.required}
                // disabled={disable}
              />
              {/* <label class="placeholder-label">Condition</label> */}
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="d-flex">
            <div class="form-group w-100">
              <Field
                name={`ResponseThreshold`}
                type="text"
                component={ReduxInput}
                fieldName={'Threshold'}
                validate={[Validator.required, Validator.allowNumeric]}
                // disabled={disable}
              />
              <label class="placeholder-label">Range*</label>
            </div>
          </div>
        </div>
        {questionFormValue.ThresholdSign === 'Between' && (
          <div className="col-2">
            <div className="d-flex">
              <div class="form-group w-100">
                <Field
                  name={`MaxResponseThreshold`}
                  type="text"
                  component={ReduxInput}
                  fieldName={'Threshold'}
                  validate={[Validator.required, Validator.allowNumeric]}
                  // disabled={disable}
                />
                <label class="placeholder-label">Range*</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-3 mt-3">
          <div class="form-group">
            <Field
              className="mr-2"
              name={`Option1QF`}
              component={ReduxCheckbox}
              fieldName={'isFlagged'}
            />
            <label class="placeholder-label" style={{ top: '5px' }}>
              Mark as Flagged
            </label>
          </div>
        </div>

        {/* <div className="col-12">
                <div class="form-group">
                  <label class="custom-control-label">
                    <Field
                      name={`${options}.IsFollowUpQuestion`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                    />
                    {'   '}
                    Add Follow Up Question
                  </label>
                </div>
              </div> */}
      </div>
    );
  }
}
export default Numeric;
