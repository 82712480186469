import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { preIntakeSurveyAnswersGet } from '../../store/actions';
import {
  NoRisk,
  LowRisk,
  MediumRisk,
  HighRisk,
} from '../RCM/patientEnrollmentForm/formSteps/questionType/risk';
import AntdModal from '../../Components/CustomModal';
import SurveyAnswersModal from './SurveyAnswersModal';
import ClientRiskChart from './ClientRiskChart';

class ClientScreeningCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskResponse: null,
    };
  }
  getRisk = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
            IsDefaultPreIntakeSurvey: true,
          },
        ],
      }),
    };
    Axios.post('Survey/ClientRiskGet', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            riskResponse: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  getSurveyAnswers = () => {
    this.props.dispatch(
      preIntakeSurveyAnswersGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    this.getRisk();
  }

  render() {
    return (
      <div class="card border-0 client-screening-card mb-3">
        <div class="card-body block">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="title">Client Screening</h6>
            <div className="d-flex align-items-center">
              <ClientRiskChart
                ClientId={this.props.ClientId}
                ClientRisk={this.state.riskResponse}
              />
              <div class="px-4">
                <img
                  src="/images/circle-arrow-left_Grey.svg"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push(
                      `/client-screening/?studentid=${this.props.ClientId}&surveyid=${this.props.SurveyId}`,
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(ClientScreeningCard));
