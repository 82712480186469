import React, { PureComponent } from 'react';
import Menu from './menu';
import { connect } from 'react-redux';

class Master extends PureComponent {
  state = {};

  render() {
    return (
      <div id="menu" className="pushmenu-push">
        <div class="wrapper">
          <Menu defaultDashboard={this.props.defaultDashboard} />
          <div class="container-fluid">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ default_values }) => {
  const { data } = default_values;
  let defaultDashboard;
  if (data) {
    defaultDashboard = data.defaultDashboard;
  }
  return {
    defaultDashboard,
  };
};
export default connect(mapStateToProps)(Master);
