import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import moment from 'moment';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { listGroupServiceLine, getGroupServiceLine } from '../../../../store/actions';
import ServiceLineDetail from './customScreens/groupServiceLineDetail';
import { ProcessCombos } from '../../../../store/comboConfig';
import PersistTableSort from '../../../../component/persistTableSort';
import ReactRangePicker from '../../../../component/Range-Picker';
import GridSearch from '../../../../Components/gridSearch';

class ManageGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineData: {},
      tabView: true,
      columns: [
        {
          title: 'Group Event Name',
          dataIndex: 'Title',
          key: 'Title',
          sorter: (a, b) => isComparer(a.Title, b.Title),
          sortDirections: ['descend', 'ascend'],
          // fixed: 'left',
          // width: 150,
          width: '150px',
        },
        {
          title: 'Session Date',
          dataIndex: 'SessionDate',
          key: 'SessionDate',
          sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
          sortDirections: ['descend', 'ascend'],
          // fixed: 'left',
          // width: 150,
          width: '120px',
        },
        {
          title: 'Service Code',
          dataIndex: 'ServiceCode',
          key: 'ServiceCode',
          sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Start / End Time',
          dataIndex: 'SessionTime',
          key: 'SessionTime',
          sorter: (a, b) => isNumericComparer(a.SessionTime, b.SessionTime),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Program',
          dataIndex: 'ProgramDescription',
          key: 'ProgramDescription',
          sorter: (a, b) => isNumericComparer(a.ProgramDescription, b.ProgramDescription),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: (
            <span>
              Attendees <br />
              Count
            </span>
          ),
          // title: 'Attendees Count',
          dataIndex: 'AttendeesCount',
          key: 'AttendeesCount',
          sorter: (a, b) => isNumericComparer(a.AttendeesCount, b.AttendeesCount),
          sortDirections: ['descend', 'ascend'],
          width: 75,
        },
        {
          title: 'Action',
          key: 'x',
          fixed: 'right',
          width: '100px',
          render: (text, record, index) => {
            if (!this.props.isReadOnlyUser) {
              return (
                <>
                  <div class="tools-grid">
                    <>
                      <span
                        className="start-btn"
                        onClick={() => {
                          props.dispatch(
                            getGroupServiceLine({
                              json: JSON.stringify({
                                GroupServiceLine: [
                                  {
                                    GroupAppointmentId: record.GroupAppointmentId,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.setState({
                            // tabView: false,
                            GroupAppointmentId: record.GroupAppointmentId,
                          });
                        }}
                      >
                        Manage
                      </span>
                    </>
                  </div>
                </>
              );
            } else {
              return null;
            }
          },
        },
      ],
      StartDate: moment(new Date())
        .subtract(30, 'd')
        .format('MM-DD-YYYY'),
      EndDate: moment(new Date()).format('MM-DD-YYYY'),
    };
  }
  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = -1;
    }
    return groupId;
  }

  toggleView = getLatest => {
    // this.setFilter();
    this.setState(prevState => ({
      tabView: !prevState.tabView,
    }));
    // this.props.dispatch(
    //   listGroupServiceLine({
    //     json: JSON.stringify({
    //       GroupServiceLine: [
    //         {
    //           // GroupId: this.getGroupId(),
    //         },
    //       ],
    //     }),
    //   }),
    // );
    // if (getLatest) {
    //   this.props.dispatch(
    //     listSurveyQuestionCategory({
    //       json: JSON.stringify({
    //         Survey: [
    //           {
    //             ClientId: this.props.clientId,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  };
  setStoredFilter = () => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);

    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      if (JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter) {
        let sDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.startDateRange;
        let eDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.endDateRange;
        this.setState({
          StartDate: sDate,
          EndDate: eDate,
        });
        if (!JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter) {
          this.props.dispatch(
            listGroupServiceLine({
              json: JSON.stringify({
                GroupServiceLine: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                  },
                ],
              }),
            }),
          );
          return;
        } else {
          let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
          this.setState({
            filterBy,
          });
          this.props.dispatch(
            listGroupServiceLine({
              json: JSON.stringify({
                GroupServiceLine: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                    FilterBy: filterBy,
                  },
                ],
              }),
            }),
          );
        }
      } else if (
        JSON.parse(pageFilter)[`table_${this.props.name}`] &&
        JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter
      ) {
        let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
        this.setState({
          filterBy,
        });
        this.props.dispatch(
          listGroupServiceLine({
            json: JSON.stringify({
              GroupServiceLine: [
                {
                  StartDate: this.state.StartDate,
                  EndDate: this.state.EndDate,
                  FilterBy: filterBy,
                },
              ],
            }),
          }),
        );
      }
    } else {
      this.props.dispatch(
        listGroupServiceLine({
          json: JSON.stringify({
            GroupServiceLine: [
              {
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate,
              },
            ],
          }),
        }),
      );
    }
  };
  componentDidMount() {
    this.setStoredFilter();
    // this.props.dispatch(
    //   listGroupServiceLine({
    //     json: JSON.stringify({
    //       GroupServiceLine: [
    //         {
    //           // GroupId: this.getGroupId(),
    //           StartDate: this.state.StartDate,
    //           EndDate: this.state.EndDate,
    //         },
    //       ],
    //     }),
    //   }),
    // );

    var result = ProcessCombos('RCM_ServiceCode,Supervisor,UnitOfMeasurement');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  componentWillReceiveProps({ ServiceLineData, isCreated }) {
    if (
      ServiceLineData &&
      JSON.stringify(ServiceLineData[0]) != JSON.stringify(this.props.ServiceLineData[0])
    ) {
      let data = {
        ...ServiceLineData[0],
        ServiceCodeId: ServiceLineData[0] && parseInt(ServiceLineData[0].ServiceCodeId),
      };
      this.setState({
        ServiceLineData: data,
        tabView: false,
      });
    }
    if (isCreated && isCreated != this.props.isCreated) {
      this.props.dispatch(
        getGroupServiceLine({
          json: JSON.stringify({
            GroupServiceLine: [
              {
                GroupAppointmentId: this.state.GroupAppointmentId,
              },
            ],
          }),
        }),
      );
    }
  }
  storeRangeFilter = date => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && !JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        rangeFilter: { startDateRange: `${date[0]}`, endDateRange: `${date[1]}` },
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };
  handleChange = (key, date) => {
    this.storeRangeFilter(date);
    this.props.dispatch(
      listGroupServiceLine({
        Json: JSON.stringify({
          GroupServiceLine: [
            {
              Search: this.state.searchStr,
              StartDate: date[0],
              EndDate: date[1],
            },
          ],
        }),
      }),
    );
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });
  };
  onSearchChange = value => {
    // let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listGroupServiceLine({
        json: JSON.stringify({
          GroupServiceLine: [
            {
              Search: value,
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { columns, tabView, ServiceLineData } = this.state;
    const { isFetching, ListServiceLine } = this.props;

    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page">
          {tabView ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="border-0">
                    <div className="mb-3">
                      <div className="row mt-5 d-flex align-items-center">
                        <div className="col-12 col-xl-4">
                          {' '}
                          <h4 className="">SERVICES LIST</h4>
                        </div>
                        <div class="col-md-5 col-xl-3">
                          <ReactRangePicker
                            value={[
                              moment(`${this.state.StartDate}`, 'MM-DD-YYYY'),
                              moment(`${this.state.EndDate}`, 'MM-DD-YYYY'),
                            ]}
                            format={'MM-DD-YYYY'}
                            // value={[
                            //   moment(this.state.StartDate, 'MM-DD-YYYY'),
                            //   moment(this.state.EndDate, 'MM-DD-YYYY'),
                            // ]}

                            // defaultValue={[moment(Startdate, dateFormat), moment(Enddate, dateFormat)]}
                            handleChange={this.handleChange}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-xl-5 d-flex align-items-center justify-content-end">
                          <GridSearch onSearchChange={this.onSearchChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row  pb-5 rcm-subtab-table-block">
                      <div className="col-12">
                        <Loader2 loading={isFetching} />
                        <PersistTableSort
                          name={this.props.name}
                          columns={columns}
                          dataSource={ListServiceLine}
                          size={'middle'}
                          scroll={{ x: 1100 }}
                          // onRow={(record, rowIndex) => {
                          //   return {
                          //     onClick: event => this.onRowClick(record.ClientId),
                          //   };
                          // }}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div className="dataTables_wrapper no-footer">
                                  <div className="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a className="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a className="paginate_button next">Next</a>
                                    ) : (
                                      <a className="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                            // hideOnSinglePage: true,
                          }}
                          // scroll={{ x: 1500, y: 300 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid px-0">
              <ServiceLineDetail
                initialValues={ServiceLineData}
                ServiceLineData={ServiceLineData}
                isCreated={this.props.isCreated}
                toggleView={this.toggleView}
                GroupAppointmentId={this.state.GroupAppointmentId}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  list_group_service_line,
  get_group_service_line,
  upsert_service_line,
}) => {
  const { data: listServiceLineData, isFetching } = list_group_service_line;
  const { data: getServiceLineData } = get_group_service_line;

  let ListServiceLine = [];
  let ServiceLineData = [];
  let isCreated = false;

  if (listServiceLineData && listServiceLineData.GroupServiceLine) {
    ListServiceLine = listServiceLineData.GroupServiceLine || [];
  }
  if (getServiceLineData && getServiceLineData.GroupServiceLine) {
    ServiceLineData = getServiceLineData.GroupServiceLine || [];
  }
  if (upsert_service_line && upsert_service_line.data && upsert_service_line.data.success) {
    isCreated = upsert_service_line.data.success;
  }
  return {
    isCreated,
    isFetching,
    ListServiceLine: ListServiceLine,
    ServiceLineData: ServiceLineData,
  };
};

export default connect(mapStateToProps)(withRouter(ManageGroup));
