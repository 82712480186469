import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { img } from '../config';
import { Container, Row, Col } from 'reactstrap';
import style from './people.module.css';
import Switch from '@material-ui/core/Switch';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
  container: {
    width: window.innerWidth,
    marginTop: -50,
  },
  card: {
    maxWidth: 220,
    marginTop: 20,
  },
  media: {
    height: 140,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'black',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '85%',
      '&:focus': {
        width: '85%',
      },
    },
  },
});

function MediaCard(props) {
  const images = img;
  const { classes } = props;
  return (
    <div>
      <Container className={classes.container}>
        <Row>
          <Col sm={2}>
            <h1>SURVEYS</h1>
          </Col>
          <Col sm={3}>
            <FormControlLabel
              label="Show Over Due"
              control={<Switch checked={true} color="primary" />}
            />
          </Col>
          <Col sm={3}>
            <FormControlLabel
              label="Show Upcoming Due"
              control={<Switch checked={true} color="primary" />}
            />
          </Col>
          <Col sm={4}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <Input
                placeholder="SURVES SEARCH"
                disableUnderline
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          {images.map(id => (
            <Col sm={2} key={id}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={id.image}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography className={style.position}>{id.name}</Typography>
                    <Typography className={style.position}>Due:{id.date}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCard);
