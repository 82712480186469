import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { StudentImage } from '../../../../component/student';
import Loader, { Loader2 } from '../../../../Components/Loader';
import '../../../../component/jquery/slick.js';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Table, Button, Tooltip } from 'antd';
import Axios from 'axios';
import AntdModal from '../../../../Components/CustomModal';
import CustomModal from '../../../../core/modal';

import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  listConsentForm,
  surveyAssign,
  listSurveyQuestionAssessment,
  deactivateClientScreening,
  saveConsentSign,
  consentFormDownload,
  resetSaveConsentSign,
  listSurveyQuestionCategoryGetAll,
} from '../../../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';
import QuestionType from './questionType/questionComponent';
import moment from 'moment';
import CustomModalPopup from '../../../../core/modal';
import PersistTableSort from '../../../../component/persistTableSort';
import {
  HasMandatoryQuestion,
  HasMandatoryQuestionWarning,
  UpdateResponse,
} from './questionType/mandatoryQuestionValidation';
class ConsentForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      SurveyQuestionCategoryListData: [],
      assesmentId: '',
      screeningView: true,
      surveyListData: [],
      surveyQuestionAssessment: {},
      currentPage: 1,
      isAssignAlreadyModal: false,
      isAssignNew: false,
    };
  }

  onAssignSurvey = () => {
    var item = {
      studentIds: this.props.clientId,
      surveyQuestionCategoryIds: this.state.assesmentId,
    };
    // if (this.props.SurveyListData.length == 0 || this.props.TabName == 'ROIs') {
    //   this.setState({
    //     isAssignNew: true,
    //   });
    // } else {
    //   this.props.SurveyListData.map(item =>
    //     item.SurveyQuestionCategoryId == this.state.assesmentId &&
    //     item.DocumentGuid == null &&
    //     item.SurveyStatus == 0
    //       ? this.setState({
    //           isAssignAlreadyModal: true,
    //         })
    //       : this.setState({
    //           isAssignNew: true,
    //         }),
    //   );
    // }
    this.props.dispatch(surveyAssign(item));
  };

  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    // this.props.dispatch(getSurveyQuestionMultiple(this.getSurveyId(), 1));
    var getSurveyData = {
      json: JSON.stringify({
        Survey: [
          {
            ClientId: this.props.clientId || this.getClientId(),
            SurveyTypeDesc: this.props.TabName || 'Intake Forms',
          },
        ],
      }),
    };
    // this.props.dispatch(listConsentForm(getSurveyData));
    this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
  }
  toggleView = getLatest => {
    this.setState(prevState => ({
      screeningView: !prevState.screeningView,
      SignatureCanvas: null,
    }));
    this.props.DocumentFormRequired(!this.state.screeningView);
    if (getLatest) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: this.props.TabName || 'Intake Forms',
              },
            ],
          }),
        }),
      );
    }
  };
  componentWillReceiveProps({
    surveys,
    isSurveyAssign,
    SurveyQuestionCategoryList,
    SurveyListData,
    surveyQuestionAssessment,
    isSurveyDeactivated,
    downloadUrl,
    documentError,
    isSignedConsent,
    formStatus,
    signedConsentMsg,
  }) {
    if (surveys.length > 0 && surveys.length != this.props.surveys.length) {
      this.toggleView();
      for (var surveyIndex in surveys) {
        if (!surveys[surveyIndex].Response) {
          this.setState(
            {
              surveys: surveys,
              currentSlideIndex: parseInt(surveyIndex),
            },
            this.applySlick,
          );
          return;
        }
      }
    }
    if (
      surveyQuestionAssessment &&
      JSON.stringify(surveyQuestionAssessment) !=
        JSON.stringify(this.props.surveyQuestionAssessment)
    ) {
      this.toggleView();
      // let isSignedForm = surveyQuestionAssessment.SurveyQuestion.some(qitem => {
      //   return qitem.SurveyQuestionTypeId === 14;
      // });
      const hasMandatory = HasMandatoryQuestion(
        surveyQuestionAssessment,
        this.state.SignatureCanvas,
      );
      this.setState({
        surveyQuestionAssessment: surveyQuestionAssessment,
        // isSignedForm: isSignedForm,
        hasMandatory: hasMandatory,
      });
    }
    // if (surveyQuestionAssessment) {
    //
    //   this.toggleView();
    //   this.setState({ surveyQuestionAssessment: surveyQuestionAssessment });
    // }
    if (
      SurveyListData.length > 0 &&
      JSON.stringify(SurveyListData) != JSON.stringify(this.props.SurveyListData)
    ) {
      this.setState({ surveyListData: SurveyListData });
    }
    if (isSurveyAssign && isSurveyAssign != this.props.isSurveyAssign) {
      // let getSurveyData = {
      //   json: JSON.stringify({
      //     Survey: [
      //       {
      //         ClientId: this.props.clientId,
      //         SurveyTypeDesc: this.props.TabName || 'Intake Forms',
      //       },
      //     ],
      //   }),
      // };
      this.setState({
        assesmentId: '',
        currentPage: 1,
        formStatus: formStatus,
        isSurveyAssign: isSurveyAssign,
      });
      // this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
    }
    if (isSurveyDeactivated && isSurveyDeactivated != this.props.isSurveyDeactivated) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: this.props.TabName || 'Intake Forms',
              },
            ],
          }),
        }),
      );
    }
    if (isSignedConsent && isSignedConsent != this.props.isSignedConsent) {
      this.props.dispatch(resetSaveConsentSign());
      this.toggle(signedConsentMsg);
    }
    if (downloadUrl && downloadUrl != this.props.downloadUrl) {
      window.open(downloadUrl);
    }
    // if (
    //   SurveyQuestionCategoryList.length > 0 &&
    //   SurveyQuestionCategoryList.length != this.props.SurveyQuestionCategoryList.length
    // ) {
    //
    //   this.setState({ SurveyQuestionCategoryList: SurveyQuestionCategoryList });
    // }
  }

  setValue({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
  }) {
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    debugger;
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    debugger;
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    if (IsSignature) {
      this.setState({
        surveys: surveys,
        Signature: IsSignature && Signature,
        SignatureCanvas: IsSignature && {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [surveyQuestionId]: {
              SurveyQuestionId: surveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveys: surveys,
      });
    }

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index, isResetAnswer }) {
    let json = {
      StudentId: this.props.clientId,
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
    };
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
    const surveys = [...this.state.surveys];
    for (let survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    // $('.main-banner').slick('slickNext');
    this.setState({
      surveys: surveys,
    });
    if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }
  saveResponse = ({ response, surveyQuestionId, index, level, subLevel, subCatLevel }) => {
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
  };
  saveSignature = (
    signOne,
    data,
    SurveyQuestionId,
    index,
    Response,
    isSignatureSaved,
    isClear,
    isResponceData,
    level,
    subLevel,
    subCatLevel,
  ) => {
    let me = this;
    if (isClear) {
      let signaturData = me.state.SignatureCanvas;
      let signaturDataList = me.state.SignatureCanvas && me.state.SignatureCanvas.signatureDataList;
      delete signaturData[signOne];
      delete signaturDataList[signOne];
      signaturData.signatureDataList = signaturDataList;
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
      return;
    }

    if (isResponceData) {
      let signaturData = {
        ...this.state.SignatureCanvas,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    } else {
      let signaturData = {
        ...this.state.SignatureCanvas,
        [signOne]: data,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
            Response: Response,
            IsUpdateSignature: Response ? true : false,
            MediaGuid: Response ? Response : null,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        surveyQuestionId: SurveyQuestionId,
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    }
  };

  getSurveyId() {
    const { SurveyId } = this.state;

    return SurveyId;
  }

  getStudentId = () => {
    // var param = new URLSearchParams(this.props.history.location.search);
    // return param.get('studentId');
    return this.props.clientId;
  };

  onClose() {
    this.props.history.push('/studentProfile/' + this.getStudentId());
  }
  saveConsent = SurveyId => {
    this.props.dispatch(
      saveConsentSign({
        Json: JSON.stringify({
          Survey: [
            {
              ClientId: this.props.clientId,
              SurveyId: SurveyId,
            },
          ],
        }),
        // ImageSign: JSON.stringify({
        //   Image: imgByteArr.map((item, index) => {
        //     return {
        //       SurveyQuestionId: imgKeyArr[index],
        //       Data: item,
        //     };
        //   }),
        // }),
      }),
    );
  };
  submitConsentForm = () => {
    const { SurveyId, SignatureCanvas, surveyQuestionId } = this.state;
    let listData =
      SignatureCanvas &&
      SignatureCanvas.signatureDataList &&
      Object.values(SignatureCanvas.signatureDataList);
    let signatureSaveList = listData && listData.filter(item => !item.isSignatureSaved);
    let saveList = listData && listData.filter(item => item.isSignatureSaved);
    signatureSaveList &&
      signatureSaveList.map((item, index) => {
        let jsonData = {
          StudentId: this.getStudentId(),
          SurveyId: this.getSurveyId(),
          SurveyQuestionId: item.SurveyQuestionId,
          Answer: item.Response,
          IsSignature: true,
          Signature: {
            Base64String: SignatureCanvas[item.SurveyQuestionId].replace(
              'data:image/png;base64,',
              '',
            ),
            IsUpdateSignature: item.Response ? true : false,
            MediaGuid: item.MediaGuid,
          },
        };
        Axios.post('Survey/SurveyAnswerUpsert', jsonData)
          .then(response => {
            saveList.push({
              [surveyQuestionId]: {
                SurveyQuestionId: item.SurveyQuestionId,
                isSignatureSaved: true,
              },
            });
          })
          .then(response => {
            if ((listData && listData.length) == (saveList && saveList.length)) {
              this.saveConsent(SurveyId);
            }
          });
      });
    if ((listData && listData.length) == (saveList && saveList.length)) {
      this.saveConsent(SurveyId);
    }
  };
  render() {
    const {
      surveys,
      currentSlideIndex,
      surveyListData,
      screeningView,
      surveyQuestionAssessment,
      // isSignedForm,
      SignatureCanvas,
      hasMandatory,
    } = this.state;
    // let isSignatureDataList =
    //   (SignatureCanvas && Object.values(SignatureCanvas.signatureDataList).length > 0) || false;
    const {
      lastSlideIndex,
      SurveyQuestionCategoryList,
      isFetchingSurveyQuestionAssessment,
      isReadOnlyUser,
      isDeActivated,
    } = this.props;
    const tempColumns = [
      {
        title: 'Name',
        dataIndex: 'SurveyName',
        key: 'SurveyName',
        sorter: (a, b) => isComparer(a.SurveyName, b.SurveyName),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Assigned Date',
        dataIndex: 'AssignedDate',
        key: 'AssignedDate',
        sorter: (a, b) => isDateComparer(a.AssignedDateForSort, b.AssignedDateForSort),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Completed Date',
        dataIndex: 'CompletedDate',
        key: 'CompletedDate',
        sorter: (a, b) => isDateComparer(a.CompletedDateForSort, b.CompletedDateForSort),

        // sorter: (a, b) => isComparer(a.CenterId, b.CenterId),
        // sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Discarded Date',
        dataIndex: 'DiscardedDate',
        key: 'DiscardedDate',
        sorter: (a, b) => isDateComparer(a.DiscardedDateForSort, b.DiscardedDateForSort),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid d-flex align-items-center" style={{ border: '0px solid red' }}>
            {record.SurveyStatus === 0 && (
              <>
                <div className="mr-3" style={{ border: '0px solid red', textAlign: 'left' }}>
                  <Button
                    type="link"
                    style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                    loading={this.state.index === index && isFetchingSurveyQuestionAssessment}
                    onClick={event => {
                      this.props.dispatch(
                        listSurveyQuestionAssessment({
                          json: JSON.stringify({
                            Survey: [
                              {
                                SurveyId: record.SurveyId,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        SurveyId: record.SurveyId,
                        isReadOnly: false,
                        index: index,
                      });
                    }}
                  >
                    {this.state.index === index && isFetchingSurveyQuestionAssessment ? (
                      'Loading'
                    ) : record.IsStart ? (
                      <>{!isReadOnlyUser && 'Resume'}</>
                    ) : (
                      <>{!isReadOnlyUser && 'Start'}</>
                    )}
                  </Button>
                </div>
                <div className="">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        class="delete-btn"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-Screen-Tool"
                      >
                        {!isReadOnlyUser && (
                          <Tooltip placement="top" title="Delete">
                            <p>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        )}
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to delete this form?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                deactivateClientScreening({
                                  json: JSON.stringify({
                                    Survey: [
                                      {
                                        SurveyId: record.SurveyId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                              // this.deleteGroup(record.GroupId);
                              // this.props.onCancel();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </>
            )}
            {record.IsUpload && (
              <>
                <div className="">
                  <span
                    // class="start-btn"
                    onClick={event => {
                      this.props.dispatch(
                        consentFormDownload({
                          json: JSON.stringify({
                            Survey: [
                              {
                                ClientId: record.StudentId || this.props.clientId,
                                DocumentGuid: record.DocumentGuid,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                    className="mr-5"
                    style={{
                      border: '0px solid red',
                      color: '#1890ff',
                      fontSize: '14px',
                      cursor: 'pointer',
                      textAlign: 'left',
                      // paddingLeft: '15px',
                    }}
                    download
                  >
                    {!isReadOnlyUser && 'Download'}
                  </span>
                </div>
              </>
            )}
            {!record.IsUpload && record.SurveyStatus === 1 && (
              <>
                <div className="">
                  <span
                    // class="start-btn"

                    className="mr-5"
                    style={{
                      border: '0px solid red',
                      color: '#1890ff',
                      fontSize: '14px',
                      cursor: 'pointer',
                      textAlign: 'left',
                      // paddingLeft: '15px',
                    }}
                  >
                    {!isReadOnlyUser && 'Generating.Check back later.'}
                  </span>
                </div>
              </>
            )}
            {record.SurveyStatus === -1 && (
              <div className="">
                <span
                  className="mr-5"
                  style={{
                    border: '0px solid red',
                    color: '#e87a7a',
                    fontSize: '14px',
                    textAlign: 'left',
                    // paddingLeft: '15px',
                  }}
                  download
                >
                  Deleted
                </span>
              </div>
            )}
          </div>
        ),
      },
    ];
    return (
      <>
        <div class="main survery-question-page  rcm-tab mb-5">
          {/* <Loader2 loading={this.props.isFetching} /> */}
          {screeningView ? (
            <div class="container-fluid">
              <div className="row">
                {/* <h3 class="page-title pt-5 mb-3 mb-md-5 ">Screening Tool</h3> */}
                <div className="col-12 enrollment-form mt-2">
                  {!isDeActivated && (
                    <div className="row px-0">
                      <div class="col-5 px-0">
                        <div class="form-group">
                          <ReduxSelect
                            options={SurveyQuestionCategoryList}
                            onChange={value => {
                              this.setState({ assesmentId: value });
                              // this.props.dispatch(getSurveyQuestionMultiple(parseInt(value), 20000019));
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            // value={defaultCenter}
                            value={this.state.assesmentId}
                            placeholder="Select"
                            disabled={this.props.isReadOnlyUser}
                          />
                        </div>
                      </div>
                      <div class="col-3 d-flex align-items-center">
                        <button
                          class={
                            this.state.assesmentId
                              ? 'rcm-sub-tab-btn'
                              : ' rcm-sub-tab-btn disable-cursor'
                          }
                          onClick={this.onAssignSurvey}
                          disabled={!this.state.assesmentId || this.props.isReadOnlyUser}
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  )}
                  <CustomModal
                    isOpen={this.state.isSurveyAssign}
                    // isOpen={this.state.isAssignAlreadyModal || this.state.isAssignNew}
                    // header={this.state.header}
                    // header={
                    //   this.state.isAssignAlreadyModal
                    //     ? 'This form is already assigned'
                    //     : 'The form has been assigned successfully'
                    // }
                    header={this.state.formStatus}
                    yesAction={() => {
                      this.setState({
                        isSurveyAssign: false,
                        formStatus: null,
                      });
                      let getSurveyData = {
                        json: JSON.stringify({
                          Survey: [
                            {
                              ClientId: this.props.clientId,
                              SurveyTypeDesc: this.props.TabName || 'Intake Forms',
                            },
                          ],
                        }),
                      };
                      this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
                    }}
                    yesText="OK"
                    hideCancel={true}
                    changeMaxWidth="100%"
                  />
                </div>
                <div className="col-12 enrollment-form mt-1">
                  <div class="row card-body px-2 rcm-subtab-table-block">
                    <Loader2 loading={this.props.isFetching} />
                    <div class="col-12 rcm-tools-table">
                      <PersistTableSort
                        name="surveyListData"
                        columns={tempColumns}
                        dataSource={surveyListData}
                        loader={this.props.isFetchingSurveyQuestionAssessment}
                        size={'middle'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="container-fluid px-0">
              <div className="row">
                <div class="col-12 text-center">
                  <Loader2 loading={this.props.isFetching} />
                  <div
                    class="survery-question-box "
                    style={{ maxWidth: 'unset', marginTop: '0px' }}
                  >
                    {/* <h3 class="mb-3 mb-md-5 ">{surveys.length > 0 && surveys[0].SurveyCategory}</h3> */}
                    <div className="screening-tool consent-form-main">
                      <div className="assessment-main-title d-flex align-items-center justify-content-between">
                        <div
                          onClick={() => {
                            this.toggleView(true);
                          }}
                          class="text-left d-flex"
                          style={{ cursor: 'pointer' }}
                        >
                          <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                        </div>
                        <span className="main-title-text">
                          {surveyQuestionAssessment.SurveyMainTitle}
                        </span>
                        <div></div>
                      </div>
                      {surveyQuestionAssessment && surveyQuestionAssessment.SurveyQuestion && (
                        <div
                          style={{
                            padding: '25px',
                            margin: '0px 25px',
                          }}
                        >
                          <QuestionType
                            SurveyQuestion={surveyQuestionAssessment.SurveyQuestion}
                            SurveyId={surveyQuestionAssessment.SurveyId}
                            onNext={this.onNext}
                            setValue={this.setValue}
                            saveResponse={this.saveResponse}
                            scope={this}
                            level={0}
                            isReadOnly={this.state.isReadOnly}
                            saveSignature={this.saveSignature}
                          />
                        </div>
                      )}
                      {surveyQuestionAssessment &&
                        surveyQuestionAssessment.SurveyCategory &&
                        surveyQuestionAssessment.SurveyCategory.map((surveyCategory, index) => {
                          return (
                            <div index={index}>
                              <div
                                className="assessment-sub-title"
                                style={{
                                  margin: '0px 25px',
                                }}
                              >
                                <span className="sub-title-text">
                                  {surveyCategory.SurveyCategory}
                                </span>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#F8F8F8',
                                  padding: '25px',
                                  margin: '0px 25px',
                                }}
                              >
                                <QuestionType
                                  SurveyQuestion={surveyCategory.SurveyQuestion}
                                  SurveyId={surveyQuestionAssessment.SurveyId}
                                  onNext={this.onNext}
                                  setValue={this.setValue}
                                  saveResponse={this.saveResponse}
                                  scope={this}
                                  level={1}
                                  subLevel={index}
                                  isReadOnly={this.state.isReadOnly}
                                  saveSignature={this.saveSignature}
                                />
                              </div>
                            </div>
                          );
                        })}
                      <div class="col-12 col-md-12 col-lg-12 mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <HasMandatoryQuestionWarning hasMandatory={hasMandatory} />
                          </div>
                          <div>
                            <input
                              type="button"
                              class=" btn btn-outline-primary mr-2"
                              value="Back to list"
                              onClick={() => {
                                this.toggleView(true);
                              }}
                            />
                            <button
                              type="button"
                              className={'btn'}
                              // class={isSignatureDataList ? 'btn btn-outline-primary' : 'btn'}
                              disabled={hasMandatory}
                              // disabled={isSignedForm ? !isSignatureDataList : false}
                              // disabled={isSignedForm ? !this.state.Signature : false}
                              onClick={() => {
                                // this.toggleView(true);
                                this.submitConsentForm();
                                window.scrollTo(0, 0);
                              }}
                            >
                              Submit and Back to list
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <CustomModalPopup
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggleView(true);
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  survery_question_get_multiple,
  survey_assign,
  list_survey_question_assessment,
  deactivate_client_screening,
  list_consent_form,
  save_consent_sign,
  consent_form_download,
  list_survey_question_category_get_all,
}) => {
  const { data, isFetching, error } = survery_question_get_multiple;
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: surveyAssignData } = survey_assign;
  const {
    data: listConsentFormData,
    isFetching: isFetchingListConsent,
  } = list_survey_question_category_get_all;
  const { data: saveConsentFormData, isFetching: isFetchingSaveConsent } = save_consent_sign;
  const {
    data: downloadConsentFormData,
    isFetching: isFetchingDownloadConsent,
    error: errorDownload,
  } = consent_form_download;
  var lastSlideIndex = 1;
  let ConsentQuestionCategoryList;
  let ConsentListData = [];
  let surveyData = [];
  let surveyQuestionAssessment;
  let isSurveyAssign = false;
  let isSurveyDeactivated = false;
  let downloadUrl;
  let downloadError;
  let formStatus;
  let isSignedConsent = false;
  let signedConsentMsg="";

  if (data) {
    let array = [];
    for (let index in data) {
      array.push(data[index]);
    }
    //array = array.slice(0, 10);

    array.push({});
    surveyData = [...array];
    lastSlideIndex = data.length - 2;
  }
  if (surveyQuestionAssessmentData) {
    surveyQuestionAssessment = surveyQuestionAssessmentData;
  }
  if (listConsentFormData && listConsentFormData.SurveyQuestionCategoryList) {
    ConsentQuestionCategoryList = listConsentFormData.SurveyQuestionCategoryList;
  }
  if (listConsentFormData && listConsentFormData.SurveyList) {
    ConsentListData = listConsentFormData.SurveyList;
  }
  if (surveyAssignData && surveyAssignData.success) {
    isSurveyAssign = surveyAssignData.success;
    formStatus = JSON.parse(surveyAssignData.data).Survey[0].FormStatus;
  }
  if (deactivate_client_screening.data && deactivate_client_screening.data.success) {
    isSurveyDeactivated = deactivate_client_screening.data.success;
  }

  if (downloadConsentFormData && downloadConsentFormData.success) {
    downloadUrl = downloadConsentFormData.result;
  } else if (errorDownload) {
    downloadError = errorDownload;
  }
  if (saveConsentFormData && saveConsentFormData.success) {
    isSignedConsent = saveConsentFormData.success;
    signedConsentMsg = saveConsentFormData.data
      ? JSON.parse(saveConsentFormData.data) &&
        JSON.parse(saveConsentFormData.data)[0] &&
        JSON.parse(saveConsentFormData.data)[0].Survey &&
        JSON.parse(saveConsentFormData.data)[0].Survey[0] &&
        JSON.parse(saveConsentFormData.data)[0].Survey[0].Msg
      : 'Document submitted successfully';
  }
  return {
    surveys: surveyData,
    surveyQuestionAssessment,
    isSurveyAssign,
    isSurveyDeactivated: isSurveyDeactivated,
    lastSlideIndex: lastSlideIndex,
    SurveyQuestionCategoryList: ConsentQuestionCategoryList || [],
    SurveyListData: ConsentListData,
    // isFetchingListSurvey: isFetchingListSurvey || isFetching || false,
    isFetching: isFetching || isFetchingListConsent || isFetchingSaveConsent,
    isFetchingSurveyQuestionAssessment: isFetchingSurveyQuestionAssessment,
    downloadUrl,
    downloadError,
    isSignedConsent,
    formStatus,
    signedConsentMsg,
  };
};

export default connect(mapStateToProps)(withRouter(ConsentForm));
