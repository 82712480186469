import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import Dropzone from 'react-dropzone';
import ByteToSize from '../../../../../../util/ByteToSize';
import { Tooltip } from 'antd';
import { Line } from 'rc-progress';
import {
  upsertClientDocument,
  UploadDocumentUsingMultiPart,
  resetUploadDocumentUsingMultiPart,
  upsertClientDocumentReset,
  payerPortalRequestUpsert,
} from '../../../../../../store/actions';
import SubmitButton from '../../../../../../Components/submitButton';
import CustomModal from '../../../../../../core/modal';

const MAX_FILE_SIZE = 5242880;
const SelfDocumentsUpload = props => {
  const dispatch = useDispatch();
  const [uploadedFiles, setFiles] = useState();
  const [uploadedDocName, setUploadedDocName] = useState();
  const [docRequested, setDocRequested] = useState([]);
  const [payerPortalRequestId, setPayerPortalRequestId] = useState([]);
  const [isSelfSection, setSelfSection] = useState(false);
  const [header, setHeader] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const { smartContractId, clientId, RequestedFiles } = props;
  const {
    isUploaded,
    uploadedDataGuid,
    uploadError,
    isUpsertDocument,
    documentError,
    upsertDocumentData,
    isFetching,
  } = useSelector(({ // payer_portal_request_get: { data: requestGetData, isFetching, error },
    upsert_client_document: { data: documentUpsert, error: errorUpload }, upload_document_using_multiPart: { data: uploadData, isFetching, error: documentError } }) => {
    return {
      isUploaded: uploadData && uploadData.success,
      uploadedDataGuid: uploadData && uploadData.data,
      uploadError: errorUpload,
      isUpsertDocument: documentUpsert && documentUpsert.success,
      upsertDocumentData: documentUpsert && documentUpsert.data,
      documentError: documentError && documentError.Message,
      isFetching: isFetching,
    };
  }, shallowEqual);

  const toggle = header => {
    setIsModelOpen(!isModelOpen);
    setHeader(header);
  };

  useEffect(() => {
    if (RequestedFiles) {
      setDocRequested(RequestedFiles);
      setFiles();
    }
  }, [RequestedFiles]);

  useEffect(() => {
    if (isUploaded && isSelfSection) {
      let data = JSON.parse(uploadedDataGuid)[0].Document.map(item => {
        return { ...item, ClientId: clientId };
      });
      dispatch(
        upsertClientDocument({
          json: JSON.stringify({
            ClientDocument: data,
          }),
        }),
      );
      dispatch(resetUploadDocumentUsingMultiPart());
      setFiles();
      setUploadedDocName();
    }
  }, [isUploaded]);

  useEffect(() => {
    if (isUpsertDocument) {
      toggle('Document uploaded successfully.');
    }
    if (isUpsertDocument && isSelfSection) {
      let clientDocumentId = JSON.parse(upsertDocumentData)[0].ClientDocument[0].ClientDocumentId;
      dispatch(
        payerPortalRequestUpsert({
          Json: JSON.stringify({
            Request: [
              {
                PayerPortalRequestId: null,
                RequestType: '30212',
                SmartContractId: smartContractId,
                Id: clientDocumentId,
              },
            ],
          }),
        }),
      );
      dispatch(upsertClientDocumentReset());
      setSelfSection(false);
    }
  }, [isUpsertDocument]);

  const onDrop = ({ files }) => {
    let dataList = [];
    files.map((obj, index) => {
      let fileName = obj.name.substr(0, obj.name.lastIndexOf('.'));
      let fileExtention = obj.name.substr(obj.name.lastIndexOf('.') + 1);
      let fileInfo = {
        name: obj.name,
        size: obj.size,
        docName: fileName,
        fileExtention: fileExtention,
      };
      dataList.push(fileInfo);
      setUploadedDocName(fileName);
    });

    setFiles(files);
    setDocRequested(dataList);
  };
  const remove = index => {
    setFiles([]);
    setDocRequested([]);
  };
  const UploadDocument = data => {
    let fileExtention = data.name.substr(data.name.lastIndexOf('.') + 1);
    let dataArray = [
      {
        ClientId: clientId,
        DocumentName: uploadedDocName,
        DocumentTypeId: '16023',
        UploadedFileFullName: data.name,
        FileSizeInByte: data.size,
        Extension: fileExtention,
        IsConfidential: false,
      },
    ];
    let jsonData = {
      json: JSON.stringify({
        Document: dataArray,
      }),
    };
    let param = jsonData;
    const formData = new FormData();
    for (var property in param) {
      formData.append(property, param[property]);
    }
    formData.append('file', uploadedFiles[0]);
    dispatch(UploadDocumentUsingMultiPart(formData));
    setSelfSection(true);
    // setPayerPortalRequestId(docItem.PayerPortalRequestId);
  };
  return (
    <>
      <div className="requested-documents-list p-3">
        <span className="title mb-3">Document Upload</span>
        {/* {docRequested &&
        docRequested.map((docItem, index) => {
          const { Note, DocumentName, RequestedBy, RequestedOn, dataList } = docItem;
          const files = uploadedFiles[index] || {};
          return (
           
          );
        })} */}
        <div className="list-item">
          <div className="doc-action">
            {uploadedFiles && uploadedFiles[0] ? (
              <aside className="profile-thumbs-Container" key={'file.name'}>
                <div className="profile-container">
                  <div className="d-flex align-items-center">
                    <div
                      className=""
                      style={{
                        width: '500px',
                        marginRight: '10px',
                      }}
                    >
                      <div class="col-12 enrollment-form px-0 mb-3">
                        <div class="form-group mb-0">
                          <input
                            name={`docName`}
                            type="text"
                            class={`form-control ${uploadedDocName && 'not-empty'}`}
                            value={uploadedDocName}
                            onChange={event => {
                              setUploadedDocName(event.target.value);
                            }}
                            style={{ background: 'unset' }}
                          />
                          <label className="placeholder-label">Document Name</label>
                          {/* {this.state[`${index}.docName`] === '' ? (
                        <label className="text-danger">Name Required</label>
                      ) : null} */}
                        </div>
                      </div>

                      <p className="d-flex justify-content-between mb-0">
                        <span classname="leader-img-name">{uploadedFiles[0].name}</span>
                        <span className="leader-img-size">
                          {ByteToSize(uploadedFiles[0].size || 0)} /{' '}
                          {ByteToSize(MAX_FILE_SIZE || 0)}
                        </span>
                      </p>
                      <Line
                        percent={(uploadedFiles[0].size / MAX_FILE_SIZE) * 100}
                        strokeWidth="1"
                        strokeColor="#2FE192"
                        strokeLinecap="round"
                      />
                    </div>
                    <div className="pl-2 d-flex align-items-center">
                      {/* <button
                      type="button"
                      class="btn mr-2 px-4 py-1"
                      onClick={() => UploadDocument(uploadedFiles[0])}
                    >
                      Upload
                    </button> */}
                      <SubmitButton
                        type="button"
                        style={{ height: '45px' }}
                        loading={isFetching}
                        value="Upload"
                        onClick={() => UploadDocument(uploadedFiles[0])}
                      />
                      <Tooltip placement="top" title="Remove">
                        <span class="delete-course " onClick={() => remove()}>
                          <figure class="action-button-icon" style={{ display: 'inline-block' }}>
                            <img src="images/circle-cross.svg" alt="" width="35" />
                          </figure>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </aside>
            ) : (
              <div className="clearfix w-100">
                <Dropzone
                  onDrop={data => {
                    onDrop({ files: data });
                  }}
                  // noDrag={false}
                  accept={'.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf'}
                  maxSize={MAX_FILE_SIZE}
                  maxFiles={1}
                  multiple={false}
                  // disabled={isDeactivated}
                >
                  {({ getRootProps, getInputProps, acceptedFiles, fileRejections }) => (
                    <section className="container upload-document">
                      <div
                        {...getRootProps({
                          className:
                            'dropzone d-flex justify-content-center align-items-center flex-column',
                        })}
                        style={{
                          // width: '375px',
                          cursor: 'pointer',
                        }}
                      >
                        <input {...getInputProps()} />
                        <div className="d-flex align-items-baseline justify-content-center">
                          <p className="dropzone-upload-drag-icon mr-2">
                            <img src="images/icons/upload-doc.svg" />
                          </p>
                          <p className="dropzone-upload-text mb-0">Upload</p>
                        </div>
                        <p className="mb-0">Drag and Drop Here or Browse Files</p>
                      </div>
                      {fileRejections && fileRejections.length > 0 && (
                        <div className="">
                          {fileRejections.map(({ file, errors }) => {
                            return (
                              <div>
                                {errors.map(e => (
                                  <span key={e.code} className="validation-error">
                                    {/* {e.message} */}
                                    File is larger than {ByteToSize(MAX_FILE_SIZE || 0)}
                                  </span>
                                ))}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {/* <span className="validation-error">{this.state.groupLeaderErrorMsg}</span> */}
                    </section>
                  )}
                </Dropzone>
              </div>
            )}
          </div>
        </div>
      </div>
      {isModelOpen && (
        <CustomModal
          isOpen={isModelOpen}
          header={header}
          yesAction={() => {
            toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      )}
    </>
  );
};

export default SelfDocumentsUpload;
