import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { resetLogin, logOut } from '../../store/actions';
import { userFeedback, removeUserFeedback } from '../../store/actions';
import hostGrabber from '../../util/hostGrabber';

class Menu extends Component {
  state = { showSubMenu: false, showMenu: false };

  constructor(props) {
    super(props);
    this.state = {
      menu: true,
      menus: [],
      centerName: '',
      userName: '',
      userRole: '',
      profileImgUrl: null,
      email: '',
    };
    var menu = localStorage.getItem('dr:menu');
    if (menu !== undefined) {
      this.state.menus = JSON.parse(menu);
    }

    localStorage.setItem('isWebCam', 1);
  }

  getActiveUrl = path => {
    const { pathname } = this.props.location;
    var exists = pathname.includes(path);
    if (exists > 0) return true;
    else return false;
  };

  onLogOut() {
    this.props.dispatch(resetLogin);
    this.props.dispatch(logOut({}));
    this.props.logOutBroadcaster();
    // localStorage.clear();
    // sessionStorage.clear();
    //this.props.history.push('/login');
    // window.location.href = '/';
    console.log('master5 menu=>logout executed');
  }

  componentDidMount() {
    const me = this;
    var menu = JSON.parse(localStorage.getItem('dr:menu'));
    let inHouseMenu = [];
    // if (menu) {
    //   for (let menuItem of menu) {
    //     if (menuItem.IsInHouse) {
    //       inHouseMenu.push(menuItem);
    //     }
    //   }
    // }
    if (menu) {
      me.setState({
        menus: menu,
        userName: localStorage.getItem('userName'),
        userRole: localStorage.getItem('userRole'),
        centerName: localStorage.getItem('centerName'),
        profileImgUrl: localStorage.getItem('profileImgUrl'),
        hasClinicalAccess: JSON.parse(localStorage.getItem('hasClinicalAccess')),
        email: localStorage.getItem('email'),
        adminUserInfo: localStorage.getItem('adminUserInfo'),
      });
    } else {
      /*Axios.post('user/ListMenu', {}).then(response => {
        localStorage.setItem('userName', response.data.UserName);
        localStorage.setItem('userRole', response.data.UserRole);
        localStorage.setItem('centerName', response.data.CenterName);
        localStorage.setItem('profileImgUrl', response.data.ProfileImgUrl);
        localStorage.setItem('email', response.data.Email);
        localStorage.setItem('adminUserInfo', JSON.stringify(response.data.AdminUserInfo));
        if (response.data.data) {
          for (let menuItem of response.data.data) {
            if (menuItem.IsInHouse) {
              inHouseMenu.push(menuItem);
            }
          }
        }
        me.setState({
          menus: inHouseMenu,
          userName: response.data.UserName,
          userRole: response.data.UserRole,
          centerName: response.data.CenterName,
          profileImgUrl: response.data.ProfileImgUrl,
          email: response.data.Email,
        });
      });*/
    }
  }

  getUserFeedBack = Text => {
    this.props.dispatch(
      userFeedback({
        Notes: Text,
      }),
    );
  };

  render() {
    const {
      showSubMenu,
      showMenu,
      userName,
      menus,
      userRole,
      profileImgUrl,
      hasClinicalAccess,
    } = this.state;
    const { CustomLogo, isFetching, isGlobalChatEnabled } = this.props;
    return (
      <header className="header mb-3 bg-white" style={{ minHeight: '100px' }}>
        <nav className="navbar navbar-light navbar-expand-md static-top pl-0 pr-0">
          <div className="container-fluid new-header-css">
            {/* <a className="navbar-brand" title="Sober peer">
              <img src="images/logo.svg" />
            </a> */}
            <Link className="navbar-brand" to={`${this.props.defaultDashboard}`}>
              <img
                src={
                  !isFetching &&
                  (CustomLogo
                    ? `images/${CustomLogo}`
                    : hostGrabber()
                    ? 'images/youulogo.png'
                    : 'images/logo.png')
                }
                width={hostGrabber() ? !CustomLogo && '150px' : !CustomLogo && '48px'}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() =>
                this.setState(prevState => {
                  return { showMenu: !prevState.showMenu };
                })
              }
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse header-nav ${showMenu &&
                'show'} align-item-center`}
              id="navbarResponsive"
            >
              <ul className="navbar-nav mr-auto  align-items-md-center">
                {/* {menus.map(menuItem => {
                  if (menuItem.Text && !menuItem.hideMenu) {
                    return (
                      <li
                        className={`nav-item ${this.getActiveUrl(menuItem.Path) ? 'active' : ''}`}
                      >
                        <Link className="nav-link" to={menuItem.Path}>
                          {menuItem.Text} <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                    );
                  }
                })} */}
              </ul>
              <div className="d-flex navbar-nav  align-items-center">
                <div
                  className="nav-item dropdown user-info"
                  onMouseEnter={() => this.setState({ showSubMenu: true })}
                  onMouseLeave={() => this.setState({ showSubMenu: false })}
                >
                  <a className="nav-link dropdown-toggle d-flex align-items-center">
                    <img
                      src={'images/blank-profile.png'}
                      className="rounded-circle face-fit-cover"
                      width="47"
                      height="47"
                      alt=""
                    />
                    <span className="ml-2">
                      {userName}
                      <span className="user-name">{userRole}</span>
                    </span>
                  </a>
                  <div
                    className={`dropdown-menu ${showSubMenu && 'show'}`}
                    style={{ marginTop: '-5px' }}
                  >
                    <a className="dropdown-item" onClick={this.onLogOut.bind(this)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <g fill="#9A9A9A" fill-rule="nonzero">
                          <path d="M15.521 6.388l-1.509 2.14a.586.586 0 0 0 .48.924h.922v10.275a.586.586 0 0 0 1.172 0V9.452h.923c.477 0 .75-.539.479-.924l-1.51-2.14a.587.587 0 0 0-.957 0z" />
                          <path d="M19.762 9.325a.586.586 0 0 0 .214.801 7.952 7.952 0 0 1 3.977 6.89c0 4.385-3.568 7.953-7.953 7.953S8.047 21.4 8.047 17.016a7.952 7.952 0 0 1 3.977-6.89.586.586 0 0 0-.587-1.014 9.124 9.124 0 0 0-4.562 7.904A9.12 9.12 0 0 0 16 26.14a9.12 9.12 0 0 0 9.125-9.125 9.124 9.124 0 0 0-4.562-7.904.586.586 0 0 0-.8.213z" />
                        </g>
                      </svg>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = ({ user_feedback, default_values }) => {
  const { data, isFetching } = default_values;
  var modalOpen = false;
  let defaultDashboard;
  let isGlobalChatEnabled;
  let dashboardClinical;
  let CustomLogo;
  if (data) {
    defaultDashboard = data.defaultDashboard;
    dashboardClinical = data.dashboardClinical;
    isGlobalChatEnabled = data.IsGlobalChatEnabled;
    CustomLogo = data.CustomLogo;
  }

  if (user_feedback && user_feedback.data) {
    modalOpen = (user_feedback && user_feedback.data && user_feedback.data.success) || false;
  }

  return {
    modalOpen,
    defaultDashboard,
    dashboardClinical,
    CustomLogo,
    isFetching,
    isGlobalChatEnabled,
  };
};

export default withRouter(connect(mapStateToProps)(Menu));
