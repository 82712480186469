import React from 'react';
import _blank from '../../../images/blank-profile.png';
import { Tooltip, Drawer } from 'antd';

export const CareTeamMemberOption = ({ OptionData }) => {
  const { Text, Value, ProfileImageUrl, CareTeamType, RoleDescription, ShortRoleName } = OptionData;
  // let roles = RoleDescription.split(' ');
  return (
    <div className="care-team-member-option">
      <div className="profile mr-2">
        <img src={ProfileImageUrl || _blank} className="pic" height="31" width="31" />
        <div className="info">
          <span className="name" style={{ textAlign: 'left', fontWeight: '700' }}>
            {Text}
          </span>
          <span className="care-team-type" style={{ textAlign: 'left', fontSize: '14px' }}>
            Provider ID: {CareTeamType}
          </span>
        </div>
      </div>
    </div>
  );
};
