import { ActionTypes } from '../constants';
import API from '../requests';

const getFormFieldMetaData = payload => {
  return {
    type: ActionTypes.GET_FORM_FIELD_META_DATA_REQUEST,
    payload: payload,
  };
};

const listStudents = payload => {
  return {
    type: ActionTypes.STUDENTS_REQUEST,
    payload: payload,
  };
};

const resetAddRequest = () => {
  return {
    type: ActionTypes.STUDENT_ADD_RESET,
  };
};

const forgetPassword = payload => {
  return {
    type: ActionTypes.FORGET_PASSWORD_REQUEST,
    payload: payload,
  };
};

const resetPassword = payload => {
  return {
    type: ActionTypes.RESET_PASSWORD_REQUEST,
    payload: payload,
  };
};

const addStudent = payload => {
  return {
    type: ActionTypes.STUDENT_ADD_REQUEST,
    payload: payload,
  };
};

const resetAddStudentCourse = () => {
  return {
    type: ActionTypes.STUDENT_COURSE_ASSIGN_RESET,
  };
};

const addStudentCourse = payload => {
  return {
    type: ActionTypes.STUDENT_COURSE_ASSIGN_REQUEST,
    payload: payload,
  };
};

const addStudentNote = payload => {
  return {
    type: ActionTypes.STUDENT_NOTE_ADD_REQUEST,
    payload: payload,
    method: API['addStudentNote'],
    successType: ActionTypes.STUDENT_NOTE_ADD_SUCCESS,
    failureType: ActionTypes.STUDENT_NOTE_ADD_FAILURE,
  };
};

const resetStudentNote = payload => {
  return {
    type: ActionTypes.STUDENT_NOTE_ADD_RESET,
    payload: payload,
  };
};

const upsertStudentPin = payload => {
  return {
    type: ActionTypes.STUDENT_PIN_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetUpsertPinRequest = () => {
  return {
    type: ActionTypes.STUDENT_PIN_UPSERT_RESET,
  };
};

const validateStudentPin = payload => {
  return {
    type: ActionTypes.STUDENT_VALIDATE_PIN_REQUEST,
    payload: payload,
  };
};

const resetValidatePin = payload => {
  return {
    type: ActionTypes.STUDENT_VALIDATE_PIN_RESET,
    payload: payload,
  };
};

const resetGetStudent = () => {
  return {
    type: ActionTypes.STUDENT_GET_RESET,
  };
};

const getStudent = id => {
  return {
    type: ActionTypes.STUDENT_GET_REQUEST,
    payload: { studentId: id },
  };
};

const getDashboard = () => {
  return {
    type: ActionTypes.DASHBOARD_GET_REQUEST,
    payload: {},
  };
};

const getSurveys = payload => {
  return {
    type: ActionTypes.SURVEY_STUDENTS_GET_ALL_PENDING_REQUEST,
    payload: payload,
  };
};

const getSurveyQuestionMultiple = (surveyId, includeAnswered) => {
  return {
    type: ActionTypes.SURVEY_QUESTION_GET_MULTIPLE_REQUEST,
    payload: { surveyId: surveyId, includeAnswered: includeAnswered },
  };
};

const surveyAnswerUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_ANSWER_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetSurveyAnswerUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_ANSWER_UPSERT_RESET,
    payload: payload,
  };
};

const login = payload => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
    payload: payload,
  };
};

const resetLogin = payload => {
  return {
    type: ActionTypes.LOGIN_RESET,
    payload: payload,
  };
};

const listCombos = payload => {
  return {
    type: ActionTypes.COMBOS_REQUEST,
    payload: payload,
  };
};

const listFilterCombo = payload => {
  return {
    type: ActionTypes.FILTER_COMBO_REQUEST,
    payload: payload,
  };
};
const listSmartCombos = payload => {
  return {
    type: ActionTypes.SMARTCOMBOS_REQUEST,
    payload: payload,
  };
};

const listFullCombos = payload => {
  return {
    type: 'COMBOS_FULL_REQUEST',
    payload: payload,
  };
};

const listTodos = () => {
  return {
    type: ActionTypes.TODO_GET_ALL_REQUEST,
  };
};

const listTodosByStudent = payload => {
  return {
    type: ActionTypes.TODO_GET_ALL_BY_STUDENT_REQUEST,
    payload: payload,
  };
};

const addTodo = payload => {
  return {
    type: ActionTypes.STUDENT_ADD_REQUEST,
    payload: payload,
  };
};

const studentAddExistingTask = payload => {
  return {
    type: ActionTypes.STUDENT_ADD_EXISTING_TASK_REQUEST,
    payload: payload,
  };
};

const resetStudentAddExistingTask = payload => {
  return {
    type: ActionTypes.STUDENT_ADD_EXISTING_TASK_RESET,
    payload: payload,
  };
};

const studentDischarge = payload => {
  return {
    type: ActionTypes.STUDENT_DISCHARGE_REQUEST,
    payload: payload,
  };
};
const resetStudentDischarge = payload => {
  return {
    type: ActionTypes.STUDENT_DISCHARGE_RESET,
    payload: payload,
  };
};
const studentReinstate = payload => {
  return {
    type: ActionTypes.STUDENT_REINSTATE_REQUEST,
    payload: payload,
  };
};
const resetStudentReinstate = payload => {
  return {
    type: ActionTypes.STUDENT_REINSTATE_RESET,
    payload: payload,
  };
};
const listActivities = () => {
  return {
    type: ActionTypes.ACTIVITY_GET_ALL_REQUEST,
  };
};

const listActivitiesByStudent = payload => {
  return {
    type: ActionTypes.ACTIVITY_GET_ALL_BY_STUDENT_REQUEST,
    payload: payload,
  };
};

const listCoursesByStudent = payload => {
  return {
    type: ActionTypes.STUDENT_GET_ALL_COURSES_REQUEST,
    payload: payload,
  };
};

const studentUnAssign = payload => {
  return {
    type: ActionTypes.STUDENT_UNASSIGN_REQUEST,
    payload: payload,
  };
};

const resetStudentUnAssign = payload => {
  return {
    type: ActionTypes.STUDENT_UNASSIGN_RESET,
    payload: payload,
  };
};

const studentMarkComplete = payload => {
  return {
    type: ActionTypes.STUDENT_MARK_COMPLETE_REQUEST,
    payload: payload,
  };
};

const resetStudentMarkComplete = payload => {
  return {
    type: ActionTypes.STUDENT_MARK_COMPLETE_RESET,
    payload: payload,
  };
};

const surveyGetToAssign = payload => {
  return {
    type: ActionTypes.SURVEY_GET_TO_ASSIGN_REQUEST,
    payload: payload,
  };
};

const surveyAssign = payload => {
  return {
    type: ActionTypes.SURVEY_ASSIGN_REQUEST,
    payload: payload,
  };
};

const surveyQuestionLibraryGet = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_GET_REQUEST,
    payload: payload,
  };
};

const surveyQuestionConfigGet = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CONFIG_GET_REQUEST,
    payload: payload,
  };
};

const surveyQuestionSectionUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_SECTION_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetSurveyQuestionSectionUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_SECTION_UPSERT_RESET,
    payload: payload,
  };
};

const surveyQuestionSectionComboGet = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_SECTION_COMBO_GET_REQUEST,
    payload: payload,
  };
};

const surveyQuestionConfigUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CONFIG_UPSERT_REQUEST,
    payload: payload,
  };
};

const surveyQuestionCategoryConfigUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CATEGORY_CONFIG_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetSurveyQuestionCategoryConfigUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CATEGORY_CONFIG_UPSERT_RESET,
    payload: payload,
  };
};

const surveyQuestionConfigBannerGet = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CONFIG_BANNER_GET_REQUEST,
    payload: payload,
  };
};

const resetSurveyQuestionConfigUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_CONFIG_UPSERT_RESET,
    payload: payload,
  };
};

const resetSurveyAssign = payload => {
  return {
    type: ActionTypes.SURVEY_ASSIGN_RESET,
    payload: payload,
  };
};

const studentTaskUpsert = payload => {
  return {
    type: ActionTypes.STUDENT_TASK_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetStudentTaskUpsert = payload => {
  return {
    type: ActionTypes.STUDENT_TASK_UPSERT_RESET,
    payload: payload,
  };
};

const studentTaskUnAssign = payload => {
  return {
    type: ActionTypes.STUDENT_TASK_UNASSIGN_REQUEST,
    payload: payload,
  };
};

const resetStudentTaskUnAssign = payload => {
  return {
    type: ActionTypes.STUDENT_TASK_UNASSIGN_RESET,
    payload: payload,
  };
};

const studentTaskMarkComplete = payload => {
  return {
    type: ActionTypes.STUDENT_TASK_MARK_COMPLETE_REQUEST,
    payload: payload,
  };
};

const resetStudentTaskMarkComplete = payload => {
  return {
    type: ActionTypes.STUDENT_TASK_MARK_COMPLETE_RESET,
    payload: payload,
  };
};

const surveyTaskMarkInComplete = payload => {
  return {
    type: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_REQUEST,
    payload: payload,
  };
};

const resetSurveyTaskMarkInComplete = payload => {
  return {
    type: ActionTypes.SURVEY_TASK_MARK_IN_COMPLETE_RESET,
    payload: payload,
  };
};

const getReportData = () => {
  return {
    type: ActionTypes.GET_REPORT_DATA_REQUEST,
  };
};

const adminstrationListCourse = payload => {
  return {
    type: ActionTypes.COURSE_GET_ALL_REQUEST,
    payload: payload,
  };
};

const adminstrationListCenter = payload => {
  return {
    type: ActionTypes.CENTER_GET_ALL_REQUEST,
    payload: payload,
  };
};

const getCenterFieldByCenter = payload => {
  return {
    type: ActionTypes.GET_CENTER_FIELD_BY_CENTER_REQUEST,
    payload: payload,
  };
};

const resetGetCenterFieldByCenter = payload => {
  return {
    type: ActionTypes.GET_CENTER_FIELD_BY_CENTER_RESET,
    payload: payload,
  };
};

const adminstrationListStaff = payload => {
  return {
    type: ActionTypes.STAFF_GET_ALL_REQUEST,
    payload: payload,
  };
};

const adminstrationGetCourse = payload => {
  return {
    type: ActionTypes.COURSE_GET_REQUEST,
    payload: payload,
  };
};

const adminstrationCourseUpsert = payload => {
  return {
    type: ActionTypes.COURSE_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationCourseUpsert = payload => {
  return {
    type: ActionTypes.COURSE_UPSERT_RESET,
    payload: payload,
  };
};

const adminstrationGetStaff = payload => {
  return {
    type: ActionTypes.STAFF_GET_REQUEST,
    payload: payload,
  };
};

const adminstrationStaffUpsert = payload => {
  return {
    type: ActionTypes.STAFF_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationStaffUpsert = payload => {
  return {
    type: ActionTypes.STAFF_UPSERT_RESET,
    payload: payload,
  };
};

const adminstrationStaffDelete = payload => {
  return {
    type: ActionTypes.STAFF_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationStaffDelete = payload => {
  return {
    type: ActionTypes.STAFF_DELETE_RESET,
    payload: payload,
  };
};

const adminstrationGetCenter = payload => {
  return {
    type: ActionTypes.CENTER_GET_REQUEST,
    payload: payload,
  };
};

const adminstrationCenterUpsert = payload => {
  return {
    type: ActionTypes.CENTER_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationCenterUpsert = payload => {
  return {
    type: ActionTypes.CENTER_UPSERT_RESET,
    payload: payload,
  };
};

const adminstrationCenterDelete = payload => {
  return {
    type: ActionTypes.CENTER_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationCenterDelete = () => {
  return {
    type: ActionTypes.CENTER_DELETE_RESET,
  };
};

const adminstrationCourseDelete = payload => {
  return {
    type: ActionTypes.COURSE_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAdminstrationCourseDelete = () => {
  return {
    type: ActionTypes.COURSE_DELETE_RESET,
  };
};

const adminstrationResetCenter = () => {
  return {
    type: ActionTypes.CENTER_GET_RESET,
  };
};

const adminstrationResetCourse = () => {
  return {
    type: ActionTypes.COURSE_GET_RESET,
  };
};

const adminstrationResetStaff = () => {
  return {
    type: ActionTypes.STAFF_GET_RESET,
  };
};

const getStudentSurveyDashboardData = payload => {
  return {
    type: ActionTypes.STUDENT_SURVEY_DASHBOARD_GET_JSON_REQUEST,
    payload: { studentId: payload },
  };
};

const resetSurveyAnswersGet = payload => {
  return {
    type: ActionTypes.SURVEY_ANSWERS_GET_RESET,
    payload: payload,
  };
};

const logDevice = payload => {
  return {
    type: ActionTypes.LOG_DEVICE_REQUEST,
    payload: payload,
  };
};

const logError = payload => {
  return {
    type: ActionTypes.LOG_ERROR_REQUEST,
    payload: payload,
  };
};

const announcementGetAll = payload => {
  return {
    type: ActionTypes.ANNOUNCEMENT_GET_ALL_REQUEST,
    payload: payload,
  };
};

const announcementGetJson = payload => {
  return {
    type: ActionTypes.ANNOUNCEMENT_GET_JSON_REQUEST,
    payload: payload,
  };
};

const announcementGetJsonReset = payload => {
  return {
    type: ActionTypes.ANNOUNCEMENT_GET_JSON_RESET,
    payload: payload,
  };
};

const announcementUpsert = payload => {
  return {
    type: ActionTypes.ANNOUNCEMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetAnnouncementUpsert = payload => {
  return {
    type: ActionTypes.ANNOUNCEMENT_UPSERT_RESET,
    payload: payload,
  };
};

const announcementDelete = payload => {
  return {
    type: ActionTypes.ANNOUNCEMENT_DELETE_REQUEST,
    payload: payload,
  };
};

const resetAnnouncementDelete = () => {
  return {
    type: ActionTypes.ANNOUNCEMENT_DELETE_RESET,
  };
};
const centerDelete = payload => {
  return {
    type: ActionTypes.CENTER_DELETE_REQUEST,
    payload: payload,
  };
};
const resetCenterDelete = () => {
  return {
    type: ActionTypes.CENTER_DELETE_RESET,
  };
};

const staffDelete = payload => {
  return {
    type: ActionTypes.STAFF_DELETE_REQUEST,
    payload: payload,
  };
};
const resetStaffDelete = () => {
  return {
    type: ActionTypes.STAFF_DELETE_RESET,
  };
};
const courseDelete = payload => {
  return {
    type: ActionTypes.COURSE_DELETE_REQUEST,
    payload: payload,
  };
};
const resetCourseDelete = () => {
  return {
    type: ActionTypes.COURSE_DELETE_RESET,
  };
};

const updateStaffEmail = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_EMAIL_REQUEST,
    payload: payload,
  };
};

const updateStaffPassword = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_PASSWORD_REQUEST,
    payload: payload,
  };
};

const updateStaffCenter = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_CENTER_REQUEST,
    payload: payload,
  };
};

const resetStaffEmail = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_EMAIL_RESET,
    payload: payload,
  };
};

const resetStaffPassword = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_PASSWORD_RESET,
    payload: payload,
  };
};

const resetStaffCenter = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_CENTER_RESET,
    payload: payload,
  };
};

const loadDefaultValues = payload => {
  return {
    type: ActionTypes.LOAD_DEFAULT_VALUES_REQUEST,
    payload: payload,
  };
};

const loadMenu = payload => {
  return {
    type: ActionTypes.LOAD_MENU_REQUEST,
    payload: payload,
  };
};

const logOut = payload => {
  return {
    type: ActionTypes.LOGOUT_REQUEST,
    payload: payload,
  };
};
const surveyAnswersGet = payload => {
  return {
    type: ActionTypes.SURVEY_ANSWERS_GET_REQUEST,
    payload: payload,
  };
};

const getStudentProfileDashboardData = payload => {
  return {
    type: ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_REQUEST,
    payload: payload,
  };
};

const getStudentsSearch = payload => {
  return {
    type: ActionTypes.STUDENTS_SEARCH_REQUEST,
    payload: payload,
  };
};

const resetStudentsSearch = payload => {
  return {
    type: ActionTypes.STUDENTS_SEARCH_RESET,
    payload: payload,
  };
};

const getUserRole = payload => {
  return {
    type: ActionTypes.USER_ROLE_LIST_REQUEST,
    payload: payload,
  };
};

const getListJobTitle = payload => {
  return {
    type: ActionTypes.LIST_JOB_TITLE_REQUEST,
    payload: payload,
  };
};

const updateJobTitle = payload => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_REQUEST,
    payload: payload,
  };
};

const updateRole = payload => {
  return {
    type: ActionTypes.UPDATE_ROLE_REQUEST,
    payload: payload,
  };
};

const resetUpdateJobTitle = payload => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_RESET,
    payload: payload,
  };
};

const resetUpdateRole = payload => {
  return {
    type: ActionTypes.UPDATE_ROLE_RESET,
    payload: payload,
  };
};

const deleteRole = payload => {
  return {
    type: ActionTypes.DELETE_ROLE_REQUEST,
    payload: payload,
  };
};

const deleteJobTitle = payload => {
  return {
    type: ActionTypes.DELETE_JOB_TITLE_REQUEST,
    payload: payload,
  };
};

const resetDeleteRole = payload => {
  return {
    type: ActionTypes.DELETE_ROLE_RESET,
    payload: payload,
  };
};

const resetDeleteJobTitle = payload => {
  return {
    type: ActionTypes.DELETE_JOB_TITLE_RESET,
    payload: payload,
  };
};

const updateRolePermissions = payload => {
  return {
    type: ActionTypes.UPDATE_ROLE_PERMISSIONS_REQUEST,
    payload: payload,
  };
};

const updateJobTitleSync = payload => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_SYNC_REQUEST,
    payload: payload,
  };
};

const resetUpdateRolePermissions = payload => {
  return {
    type: ActionTypes.UPDATE_ROLE_PERMISSIONS_RESET,
    payload: payload,
  };
};

const resetUpdateJobTitleSync = payload => {
  return {
    type: ActionTypes.UPDATE_JOB_TITLE_SYNC_RESET,
    payload: payload,
  };
};

const getContract = payload => {
  return {
    type: ActionTypes.GET_CONTRACT_REQUEST,
    payload: payload,
  };
};

const resetGetContract = payload => {
  return {
    type: ActionTypes.GET_CONTRACT_RESET,
    payload: payload,
  };
};

const upsertContract = payload => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_REQUEST,
    payload: payload,
  };
};

const resetUpsertContract = payload => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_RESET,
    payload: payload,
  };
};

const deleteContract = payload => {
  return {
    type: ActionTypes.DELETE_CONTRACT_REQUEST,
    payload: payload,
  };
};

const resetDeleteContract = payload => {
  return {
    type: ActionTypes.DELETE_CONTRACT_RESET,
    payload: payload,
  };
};

const getContractTemplate = payload => {
  return {
    type: ActionTypes.GET_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const upsertContractTemplate = payload => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const getStudentContractTemplate = payload => {
  return {
    type: ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetGetContractTemplate = payload => {
  return {
    type: ActionTypes.GET_CONTRACT_TEMPLATE_RESET,
    payload: payload,
  };
};

const resetUpsertContractTemplate = payload => {
  return {
    type: ActionTypes.UPSERT_CONTRACT_TEMPLATE_RESET,
    payload: payload,
  };
};

const uploadStaffImage = payload => {
  return {
    type: ActionTypes.UPLOAD_STAFF_IMAGE_REQUEST,
    payload: payload,
  };
};

const createContractFromTemplate = payload => {
  return {
    type: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetCreateContractFromTemplate = payload => {
  return {
    type: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_RESET,
    payload: payload,
  };
};

const addFavoriteTemplate = payload => {
  return {
    type: ActionTypes.ADD_FAVORITE_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetAddFavoriteTemplate = payload => {
  return {
    type: ActionTypes.ADD_FAVORITE_TEMPLATE_RESET,
    payload: payload,
  };
};

const removeFavoriteTemplate = payload => {
  return {
    type: ActionTypes.REMOVE_FAVORITE_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const resetRemoveFavoriteTemplate = payload => {
  return {
    type: ActionTypes.REMOVE_FAVORITE_TEMPLATE_RESET,
    payload: payload,
  };
};

const closeContract = payload => {
  return {
    type: ActionTypes.CLOSE_CONTRACT_REQUEST,
    payload: payload,
  };
};

const userFeedback = payload => {
  return {
    type: ActionTypes.USER_FEEDBACK_REQUEST,
    payload: payload,
  };
};

const removeUserFeedback = payload => {
  return {
    type: ActionTypes.USER_FEEDBACK_RESET,
    payload: payload,
  };
};

const isLicenseAvailable = payload => {
  return {
    type: ActionTypes.IS_LICENSE_AVAILABLE_REQUEST,
    payload: payload,
  };
};

const getDashboardLoadAppUsers = payload => {
  return {
    type: ActionTypes.GET_DASHBOARD_LOAD_APP_USERS_REQUEST,
    payload: payload,
  };
};

const getLoadUserInfo = payload => {
  return {
    type: ActionTypes.GET_LOAD_USER_INFO_REQUEST,
    payload: payload,
  };
};

const resetLoadUserInfo = payload => {
  return {
    type: ActionTypes.GET_LOAD_USER_INFO_RESET,
    payload: payload,
  };
};
const getValidateImage = payload => {
  return {
    type: ActionTypes.GET_VALIDATE_IMAGE_REQUEST,
    payload: payload,
  };
};

const resetGetValidateImage = payload => {
  return {
    type: ActionTypes.GET_VALIDATE_IMAGE_RESET,
    payload: payload,
  };
};

const getLicenseActivites = payload => {
  return {
    type: ActionTypes.GET_LICENSE_ACTIVITES_REQUEST,
    payload: payload,
  };
};

const getCurrentLicenses = payload => {
  return {
    type: ActionTypes.GET_CURRENT_LICENSES_REQUEST,
    payload: payload,
  };
};
const getLicensesInvoice = payload => {
  return {
    type: ActionTypes.GET_LICENSES_INVOICE_REQUEST,
    payload: payload,
  };
};
const getLicensesReceipt = payload => {
  return {
    type: ActionTypes.GET_LICENSES_RECEIPT_REQUEST,
    payload: payload,
  };
};
const setCurrentLicenses = payload => {
  return {
    type: ActionTypes.GET_CURRENT_LICENSES_SET,
    payload,
  };
};

const addLicenses = payload => {
  return {
    type: ActionTypes.ADD_LICENSES_REQUEST,
    payload,
  };
};
const resetSetLicenses = payload => {
  return {
    type: ActionTypes.ADD_LICENSES_RESET,
    payload,
  };
};
const startFreeLicense = payload => {
  return {
    type: ActionTypes.START_FREE_LICENSE_REQUEST,
    payload,
  };
};
const resetAddLicenses = payload => {
  return {
    type: ActionTypes.GET_PAYMENT_INFO_RESET,
    payload: payload,
  };
};
const getPaymentInfo = payload => {
  return {
    type: ActionTypes.GET_PAYMENT_INFO_REQUEST,
    payload,
  };
};
const isAchValidation = payload => {
  return {
    type: ActionTypes.IS_ACH_VALIDATION_REQUEST,
    payload,
  };
};
const achValidate = payload => {
  return {
    type: ActionTypes.ACH_VALIDATE_REQUEST,
    payload,
  };
};
const getBillingInfo = payload => {
  return {
    type: ActionTypes.GET_BILLING_INFO_REQUEST,
    payload,
  };
};
const updatePaymentMethode = payload => {
  return {
    type: ActionTypes.UPDATE_PAYMENT_METHODE_REQUEST,
    payload,
  };
};
const resetPaymentMethode = payload => {
  return {
    type: ActionTypes.UPDATE_PAYMENT_METHODE_RESET,
    payload,
  };
};
const addNewApplicant = payload => {
  return {
    type: ActionTypes.ADD_NEW_APPLICANT_REQUEST,
    payload,
  };
};

const getAppSubscribers = payload => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_REQUEST,
    payload,
  };
};
const getAppSubscribersDashboard = payload => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_DASHBOARD_REQUEST,
    payload,
  };
};
const getAppSubscribersProfile = payload => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_PROFILE_REQUEST,
    payload,
  };
};
const getAppSubscribersChat = payload => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_REQUEST,
    payload,
  };
};
const getAppSubscribersChatCoach = payload => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_CHAT_COACH_REQUEST,
    payload,
  };
};
const getAppSubscribersActivities = payload => {
  return {
    type: ActionTypes.GET_APP_SUBSCRIBERS_ACTIVITIES_REQUEST,
    payload,
  };
};
const addAppSubscribersSurvey = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_REQUEST,
    payload,
  };
};
const deleteAppSubscribersSurvey = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_REQUEST,
    payload,
  };
};
const resetAppSubscribersUnassignSurvey = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_UNASSIGN_RESET,
    payload,
  };
};
const resetAppSubscribersSurvey = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_SURVEY_ASSIGN_RESET,
    payload,
  };
};
const addAppSubscribersNote = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_REQUEST,
    payload,
  };
};
const resetAddAppSubscribersNote = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_INSERT_NOTE_RESET,
    payload,
  };
};
const addAppSubscribersTask = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_REQUEST,
    payload,
  };
};
const deleteAppSubscribersTask = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_REQUEST,
    payload,
  };
};
const resetDeleteAppSubscribersTask = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_TASK_RESET,
    payload,
  };
};
const resetAppSubscribersTask = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_TASK_RESET,
    payload,
  };
};
const appSubscribersUserSearch = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_USER_SEARCH_REQUEST,
    payload,
  };
};
const AppSubscribersCompleteTask = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_REQUEST,
    payload,
  };
};
const resetAppSubscribersCompleteTask = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_TASK_RESET,
    payload,
  };
};
const addAppSubscribersCourse = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_REQUEST,
    payload,
  };
};
const deleteAppSubscribersCourse = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_REQUEST,
    payload,
  };
};
const appSubscribersCompleteCourse = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_REQUEST,
    payload,
  };
};
const resetAddAppSubscribersCourse = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_ADD_COURSE_RESET,
    payload,
  };
};
const appSubscribersupdateCase = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_REQUEST,
    payload,
  };
};
const resetAppSubscribersupdateCase = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_UPDATE_CASE_RESET,
    payload,
  };
};
const resetDeleteAppSubscribersCourse = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_DELETE_COURSE_RESET,
    payload,
  };
};
const resetAppSubscribersCompleteCourse = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_COMPLETE_COURSE_RESET,
    payload,
  };
};
const listMobileAppRoleGet = payload => {
  return {
    type: ActionTypes.GET_LIST_MOBILE_APP_ROLE_REQUEST,
    payload: payload,
  };
};
const spListCombos = payload => {
  return {
    type: ActionTypes.SP_LIST_COMBOS_REQUEST,
    payload: payload,
  };
};
const getDynamicComos = payload => {
  return {
    type: ActionTypes.COMBOS_DYNAMIC_REQUEST,
    payload: payload,
  };
};
const appSubscribersMapHistory = payload => {
  return {
    type: ActionTypes.APP_SUBSCRIBERS_MAP_HISTORY_REQUEST,
    payload: payload,
  };
};
const appInvite = payload => {
  return {
    type: ActionTypes.APP_INVITE_REQUEST,
    payload: payload,
  };
};

const getMobileAppDownloadStatus = payload => {
  return {
    type: ActionTypes.GET_MOBILE_APP_DOWNLOAD_STATUS_REQUEST,
    payload: payload,
  };
};
const resetGetMobileAppDownloadStatus = payload => {
  return {
    type: ActionTypes.GET_MOBILE_APP_DOWNLOAD_STATUS_RESET,
    payload: payload,
  };
};

const updateMobileAppRole = payload => {
  return {
    type: ActionTypes.UPDATE_MOBILE_APP_ROLE_REQUEST,
    payload: payload,
  };
};
const resetupdateMobileAppRole = payload => {
  return {
    type: ActionTypes.UPDATE_MOBILE_APP_ROLE_RESET,
    payload: payload,
  };
};
const addNoteType = payload => {
  return {
    type: ActionTypes.ADD_NOTE_TYPE_REQUEST,
    payload: payload,
  };
};
const resetAddNoteType = payload => {
  return {
    type: ActionTypes.ADD_NOTE_TYPE_RESET,
    payload: payload,
  };
};
const changeAppRole = payload => {
  return {
    type: ActionTypes.CHANGE_APP_ROLE_REQUEST,
    payload: payload,
  };
};
const resetChangeAppRole = payload => {
  return {
    type: ActionTypes.CHANGE_APP_ROLE_RESET,
    payload: payload,
  };
};
const twoStepAuth = payload => {
  return {
    type: ActionTypes.TWO_STEP_AUTH_REQUEST,
    payload: payload,
  };
};
const validatePasscode = payload => {
  return {
    type: ActionTypes.VALIDATE_PASS_CODE_REQUEST,
    payload: payload,
  };
};
const appInviteDashboard = payload => {
  return {
    type: ActionTypes.APP_INVITE_DASHBOARD_REQUEST,
    payload: payload,
  };
};
const getStaffShiftLog = payload => {
  return {
    type: ActionTypes.GET_STAFF_SHIFT_LOG_REQUEST,
    payload: payload,
  };
};
const addStaffShiftLog = payload => {
  return {
    type: ActionTypes.ADD_STAFF_SHIFT_LOG_REQUEST,
    payload: payload,
  };
};
const resetStaffShiftLog = payload => {
  return {
    type: ActionTypes.ADD_STAFF_SHIFT_LOG_RESET,
    payload: payload,
  };
};
const getAllCallSession = payload => {
  return {
    type: ActionTypes.GET_ALL_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const addCallSession = payload => {
  return {
    type: ActionTypes.ADD_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const resetAddCallSession = payload => {
  return {
    type: ActionTypes.ADD_CALL_SESSION_RESET,
    payload: payload,
  };
};
const getAllVacation = payload => {
  return {
    type: ActionTypes.GET_ALL_VACATION_REQUEST,
    payload: payload,
  };
};
const addVacationDays = payload => {
  return {
    type: ActionTypes.ADD_VACATION_DAYS_REQUEST,
    payload: payload,
  };
};
const resetAddVacationDays = payload => {
  return {
    type: ActionTypes.ADD_VACATION_DAYS_RESET,
    payload: payload,
  };
};
const getOneCallTimeSlots = payload => {
  return {
    type: ActionTypes.GET_ONE_CALL_TIMESLOTS_REQUEST,
    payload: payload,
  };
};
const getOneCallSession = payload => {
  return {
    type: ActionTypes.GET_ONE_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const inviteOneCallSession = payload => {
  return {
    type: ActionTypes.INVITE_ONE_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const resetinviteOneCallSession = payload => {
  return {
    type: ActionTypes.INVITE_ONE_CALL_SESSION_RESET,
    payload: payload,
  };
};
const cancelOneCallSession = payload => {
  return {
    type: ActionTypes.CANCEL_ONE_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const resetCancelOneCallSession = payload => {
  return {
    type: ActionTypes.CANCEL_ONE_CALL_SESSION_RESET,
    payload: payload,
  };
};
const getStripeApiKey = payload => {
  return {
    type: ActionTypes.GET_STRIPE_API_KEY_REQUEST,
    payload: payload,
  };
};
const editGroupCallSession = payload => {
  return {
    type: ActionTypes.EDIT_GROUP_CALL_SESSION_REQUEST,
    payload: payload,
  };
};
const getCallSessionVideo = payload => {
  return {
    type: ActionTypes.GET_CALL_SESSION_VIDEO_REQUEST,
    payload: payload,
  };
};
const getCallSessionVideoUrl = payload => {
  return {
    type: ActionTypes.GET_CALL_SESSION_VIDEO_URL_REQUEST,
    payload: payload,
  };
};
const staffReactivate = payload => {
  return {
    type: ActionTypes.STAFF_REACTIVATE_REQUEST,
    payload: payload,
  };
};
const resetStaffReactivate = () => {
  return {
    type: ActionTypes.STAFF_REACTIVATE_RESET,
  };
};
const getReports = payload => {
  return {
    type: ActionTypes.GET_REPORTS_REQUEST,
    payload: payload,
  };
};
const getPromoCode = payload => {
  return {
    type: ActionTypes.GET_PROMO_CODE_REQUEST,
    payload: payload,
  };
};
const getSmallGroup = payload => {
  return {
    type: ActionTypes.GET_SMALLGROUP_REQUEST,
    payload: payload,
  };
};
const addSmallGroup = payload => {
  return {
    type: ActionTypes.ADD_SMALLGROUP_REQUEST,
    payload: payload,
  };
};
const resetAddSmallGroup = () => {
  return {
    type: ActionTypes.ADD_SMALLGROUP_RESET,
  };
};
const getCounselor = payload => {
  return {
    type: ActionTypes.GET_COUNSELOR_REQUEST,
    payload: payload,
  };
};
const getClientCounselor = payload => {
  return {
    type: ActionTypes.GET_CLIENT_COUNSELOR_REQUEST,
    payload: payload,
  };
};
const sendVoucherCode = payload => {
  return {
    type: ActionTypes.SEND_VOUCHER_CODE_REQUEST,
    payload: payload,
  };
};
const getSmallGroupList = payload => {
  return {
    type: ActionTypes.GET_SMALL_GROUP_LIST_REQUEST,
    payload: payload,
  };
};
const getSmallGroupUsers = payload => {
  return {
    type: ActionTypes.GET_SMALL_GROUP_USERS_REQUEST,
    payload: payload,
  };
};
const uploadLeaderPhoto = payload => {
  return {
    type: ActionTypes.UPLOAD_LEADER_PHOTO_REQUEST,
    payload: payload,
  };
};
const markSystemObservation = payload => {
  return {
    type: ActionTypes.MARK_SYSTEM_OBSERVATION_REQUEST,
    payload: payload,
  };
};
const appMarkSystemObservation = payload => {
  return {
    type: ActionTypes.APP_MARK_SYSTEM_OBSERVATION_REQUEST,
    payload: payload,
  };
};
const getAnalytics = payload => {
  return {
    type: ActionTypes.GET_ANALYTICS_REQUEST,
    payload: payload,
  };
};
const getActiveClient = payload => {
  return {
    type: ActionTypes.GET_ACTIVE_CLIENT_REQUEST,
    payload: payload,
  };
};
const isManageLicenseRequired = payload => {
  return {
    type: ActionTypes.IS_MANAGE_LICENSE_REQUIRED_REQUEST,
    payload: payload,
  };
};
const isStaffProBio = payload => {
  return {
    type: ActionTypes.CHECK_STAFF_PRO_BIO_REQUEST,
    payload: payload,
  };
};
const updateStaffProBio = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_PRO_BIO_REQUEST,
    payload: payload,
  };
};
const resetStaffProBio = () => {
  return {
    type: ActionTypes.UPDATE_STAFF_PRO_BIO_RESET,
  };
};
const getNotes = payload => {
  return {
    type: ActionTypes.GET_NOTES_REQUEST,
    payload: payload,
  };
};
const getCategoryClient = payload => {
  return {
    type: ActionTypes.GET_CATEGORY_CLIENT_REQUEST,
    payload: payload,
  };
};
const getClientDemographic = payload => {
  return {
    type: ActionTypes.GET_CLIENT_DEMOGRAPHIC_REQUEST,
    payload: payload,
  };
};
const getCostOfCare = payload => {
  return {
    type: ActionTypes.GET_COST_OF_CARE_REQUEST,
    payload: payload,
  };
};
const getBehavioralRating = payload => {
  return {
    type: ActionTypes.GET_BEHAVIORAL_RATING_REQUEST,
    payload: payload,
  };
};
const getScoreImprovement = payload => {
  return {
    type: ActionTypes.GET_SCORE_IMPROVEMENT_REQUEST,
    payload: payload,
  };
};
const getProviderPerformance = payload => {
  return {
    type: ActionTypes.GET_PROVIDER_PERFORMANCE_REQUEST,
    payload: payload,
  };
};
const getTriggerLocationActivity = payload => {
  return {
    type: ActionTypes.GET_TRIGGER_LOCATION_REQUEST,
    payload: payload,
  };
};
const getServiceDelivered = payload => {
  return {
    type: ActionTypes.GET_SERVICE_DELIVERED_REQUEST,
    payload: payload,
  };
};
const getReferralSource = payload => {
  return {
    type: ActionTypes.GET_REFERRAL_SOURCE_REQUEST,
    payload: payload,
  };
};
const getStudentDocumentList = payload => {
  return {
    type: ActionTypes.GET_DOCUMENT_LIST_REQUEST,
    payload: payload,
  };
};
const archiveStudentDocument = payload => {
  return {
    type: ActionTypes.ARCHIVE_STUDENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const archiveDocumentReset = payload => {
  return {
    type: ActionTypes.ARCHIVE_STUDENT_DOCUMENT_RESET,
    payload: payload,
  };
};
const uploadDocument = payload => {
  return {
    type: ActionTypes.UPLOAD_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const uploadDocumentReset = payload => {
  return {
    type: ActionTypes.UPLOAD_DOCUMENT_RESET,
    payload: payload,
  };
};
const downloadStudentDocument = payload => {
  return {
    type: ActionTypes.DOWNLOAD_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const resetDownloadStudentDocument = payload => {
  return {
    type: ActionTypes.DOWNLOAD_DOCUMENT_RESET,
    payload: payload,
  };
};
const menuContractTemplate = payload => {
  return {
    type: ActionTypes.MENU_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const archiveContractTemplate = payload => {
  return {
    type: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const resetArchiveContractTemplate = payload => {
  return {
    type: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_RESET,
    payload: payload,
  };
};
const resetNewsUpsert = payload => {
  return {
    type: ActionTypes.NEWS_UPSERT_RESET,
    payload: payload,
  };
};
const newsUpsert = payload => {
  return {
    type: ActionTypes.NEWS_UPSERT_REQUEST,
    payload: payload,
  };
};
const getListNews = payload => {
  return {
    type: ActionTypes.GET_LIST_NEWS_REQUEST,
    payload: payload,
  };
};
const getNews = payload => {
  return {
    type: ActionTypes.GET_NEWS_REQUEST,
    payload: payload,
  };
};
const deleteNews = payload => {
  return {
    type: ActionTypes.DELETE_NEWS_REQUEST,
    payload: payload,
  };
};
const getEmbedInfo = payload => {
  return {
    type: ActionTypes.GET_EMBED_INFO_REQUEST,
    payload: payload,
  };
};
const getGenderPieChartEmbedInfo = payload => {
  return {
    type: ActionTypes.GET_GENDER_PIE_CHART_EMBED_INFO_REQUEST,
    payload: payload,
  };
};
const resetGetGenderPieChartEmbedInfo = payload => {
  return {
    type: ActionTypes.GET_GENDER_PIE_CHART_EMBED_INFO_RESET,
    payload: payload,
  };
};
const getRefernceSourceTimeInTreatmentReport = payload => {
  return {
    type: ActionTypes.GET_REFERNCE_SOURCE_TIME_IN_TREATMENT_REPORT_REQUEST,
    payload: payload,
  };
};
const resetGetRefernceSourceTimeInTreatmentReport = payload => {
  return {
    type: ActionTypes.GET_REFERNCE_SOURCE_TIME_IN_TREATMENT_REPORT_RESET,
    payload: payload,
  };
};
const getPatientList = payload => {
  return {
    type: ActionTypes.GET_CLIENT_LIST_REQUEST,
    payload: payload,
  };
};
const getPatientDetail = payload => {
  return {
    type: ActionTypes.GET_CLIENT_DETAIL_REQUEST,
    payload: payload,
  };
};
const clientChartListGet = payload => {
  return {
    type: ActionTypes.CLIENT_CHART_LIST_GET_REQUEST,
    payload: payload,
  };
};
const clientChartReport = payload => {
  return {
    type: ActionTypes.CLIENT_CHART_REPORT_REQUEST,
    payload: payload,
  };
};
const resetClientChartReport = payload => {
  return {
    type: ActionTypes.CLIENT_CHART_REPORT_RESET,
    payload: payload,
  };
};

const resetGetPatientDetail = payload => {
  return {
    type: ActionTypes.GET_CLIENT_DETAIL_RESET,
    payload: payload,
  };
};
const upsertPatientEnrollment = payload => {
  return {
    type: ActionTypes.UPSERT_PATIENT_ENROLLMENT_REQUEST,
    payload: payload,
  };
};
const resetUpsertPatientEnrollment = payload => {
  return {
    type: ActionTypes.UPSERT_PATIENT_ENROLLMENT_RESET,
    payload: payload,
  };
};
const upsertClientInsurance = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_INSURANCE_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientInsurance = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_INSURANCE_RESET,
    payload: payload,
  };
};
const upsertClientMedical = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_MEDICAL_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientMedical = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_MEDICAL_RESET,
    payload: payload,
  };
};
const upsertClientPayment = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_PAYMENT_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientPayment = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_PAYMENT_RESET,
    payload: payload,
  };
};

const upsertProviderEnrollment = payload => {
  return {
    type: ActionTypes.UPSERT_PROVIDER_ENROLLMENT_REQUEST,
    payload: payload,
  };
};

const resetUpsertProviderEnrollment = payload => {
  return {
    type: ActionTypes.UPSERT_PROVIDER_ENROLLMENT_RESET,
    payload: payload,
  };
};

const upsertAppointment = payload => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_REQUEST,
    payload: payload,
  };
};

const upsertAppointmentReset = payload => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_RESET,
    payload: payload,
  };
};
const listAppointment = payload => {
  return {
    type: ActionTypes.LIST_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const getAppointment = payload => {
  return {
    type: ActionTypes.GET_APPOINTMENT_REQUEST,
    payload: payload,
  };
};

const resetGetAppointment = payload => {
  return {
    type: ActionTypes.GET_APPOINTMENT_RESET,
    payload: payload,
  };
};
const deleteAppointment = payload => {
  return {
    type: ActionTypes.DELETE_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const medicateCombos = payload => {
  return {
    type: ActionTypes.COMBOS_MEDICATE_REQUEST,
    payload: payload,
  };
};
const listSurveyQuestionCategory = payload => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_REQUEST,
    payload: payload,
  };
};
const listSurveyQuestionCategoryAssessment = payload => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_ASSESSMENT_REQUEST,
    payload: payload,
  };
};
const listSurveyQuestionAssessment = payload => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_ASSESSMENT_REQUEST,
    payload: payload,
  };
};
const resetListSurveyQuestionAssessment = payload => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_ASSESSMENT_RESET,
    payload: payload,
  };
};
const practitionerCombos = payload => {
  return {
    type: ActionTypes.COMBOS_PRACTITIONER_REQUEST,
    payload: payload,
  };
};
const practitionerCombosOffice = payload => {
  return {
    type: ActionTypes.COMBOS_PRACTITIONER_OFFICE_REQUEST,
    payload: payload,
  };
};

const upsertGroup = payload => {
  return {
    type: ActionTypes.UPSERT_GROUP_REQUEST,
    payload: payload,
  };
};
const listGroup = payload => {
  return {
    type: ActionTypes.LIST_GROUP_REQUEST,
    payload: payload,
  };
};

const getGroup = payload => {
  return {
    type: ActionTypes.GET_GROUP_REQUEST,
    payload: payload,
  };
};

const upsertClientGroup = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_GROUP_REQUEST,
    payload: payload,
  };
};
const getClientGroup = payload => {
  return {
    type: ActionTypes.GET_CLIENT_GROUP_REQUEST,
    payload: payload,
  };
};
const resetGetClientGroup = payload => {
  return {
    type: ActionTypes.GET_CLIENT_GROUP_RESET,
    payload: payload,
  };
};

const upsertGroupAppointment = payload => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};

const upsertGroupAppointmentGroupAllInfoJson = payload => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_GROUP_ALL_INFO_JSON_REQUEST,
    payload: payload,
  };
};
const deleteGroup = payload => {
  return {
    type: ActionTypes.DELETE_GROUP_REQUEST,
    payload: payload,
  };
};

const getListClientGroup = payload => {
  return {
    type: ActionTypes.GET_LIST_CLIENT_GROUP_REQUEST,
    payload: payload,
  };
};

const patientComboGroup = payload => {
  return {
    type: ActionTypes.PATIENT_COMBO_GROUP_REQUEST,
    payload: payload,
  };
};

const deactivateGroupAppointment = payload => {
  return {
    type: ActionTypes.DEACTIVATE_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const comboCenterConsent = payload => {
  return {
    type: ActionTypes.COMBO_CENTER_CONSENT_REQUEST,
    payload: payload,
  };
};
const upsertClientConsent = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const listClientConsent = payload => {
  return {
    type: ActionTypes.LIST_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const deactivateClientConsent = payload => {
  return {
    type: ActionTypes.DEACTIVATE_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const deactivateClientScreening = payload => {
  return {
    type: ActionTypes.DEACTIVATE_CLIENT_SCREENING_REQUEST,
    payload: payload,
  };
};
const loadJudicialDashboard = payload => {
  return {
    type: ActionTypes.LOAD_JUDICIAL_DASHBOARD_REQUEST,
    payload: payload,
  };
};
const getJudicialUserList = payload => {
  return {
    type: ActionTypes.GET_JUDICIAL_USER_LIST_REQUEST,
    payload: payload,
  };
};
const UploadDocumentUsingMultiPart = payload => {
  return {
    type: ActionTypes.UPLOAD_DOCUMENT_USING_MULTIPART_REQUEST,
    payload: payload,
  };
};
const resetUploadDocumentUsingMultiPart = payload => {
  return {
    type: ActionTypes.UPLOAD_DOCUMENT_USING_MULTIPART_RESET,
    payload: payload,
  };
};
const upsertClientDocument = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const upsertClientDocumentReset = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_DOCUMENT_RESET,
    payload: payload,
  };
};
const listClientDocument = payload => {
  return {
    type: ActionTypes.LIST_CLIENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const downloadClientDocument = payload => {
  return {
    type: ActionTypes.DOWNLOAD_CLIENT_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const resetDownloadClientDocument = payload => {
  return {
    type: ActionTypes.DOWNLOAD_CLIENT_DOCUMENT_RESET,
    payload: payload,
  };
};
const archiveClientDocument = payload => {
  return {
    type: ActionTypes.ARCHIVE_DOCUMENT_REQUEST,
    payload: payload,
  };
};
const listGroupMembership = payload => {
  return {
    type: ActionTypes.LIST_GROUP_MEMBERSHIP_REQUEST,
    payload: payload,
  };
};
const getSurveyScore = payload => {
  return {
    type: ActionTypes.GET_SURVEY_SCORE_REQUEST,
    payload: payload,
  };
};
const getConsentFormTemplate = payload => {
  return {
    type: ActionTypes.GET_CONSENT_FORM_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const getComboFacility = payload => {
  return {
    type: ActionTypes.GET_COMBO_FACEILITY_REQUEST,
    payload: payload,
  };
};

const resetGetComboFacility = payload => {
  return {
    type: ActionTypes.GET_COMBO_FACEILITY_RESET,
    payload: payload,
  };
};

const loadAppSubSurveyDashboard = payload => {
  return {
    type: ActionTypes.LOAD_APP_SUB_SURVEY_DASHBOARD_REQUEST,
    payload: payload,
  };
};
const appSubSurveyAnsGet = payload => {
  return {
    type: ActionTypes.APP_SUB_SURVEY_ANS_GET_REQUEST,
    payload: payload,
  };
};

const resetAppSubSurveyAnsGet = payload => {
  return {
    type: ActionTypes.APP_SUB_SURVEY_ANS_GET_RESET,
    payload: payload,
  };
};

const centerProgramTypeGet = payload => {
  return {
    type: ActionTypes.CENTER_PROGRAM_TYPE_GET_REQUEST,
    payload: payload,
  };
};
const downloadClientConsent = payload => {
  return {
    type: ActionTypes.DOWNLOAD_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const comboPractitionerAll = payload => {
  return {
    type: ActionTypes.COMBO_PRACTITIONER_ALL_REQUEST,
    payload: payload,
  };
};
const comboFacilityAll = payload => {
  return {
    type: ActionTypes.COMBO_FACILITY_ALL_REQUEST,
    payload: payload,
  };
};
const saveClientConsent = payload => {
  return {
    type: ActionTypes.SAVE_CLIENT_CONSENT_REQUEST,
    payload: payload,
  };
};
const getTreatmentPlan = payload => {
  return {
    type: ActionTypes.GET_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const getDiagnosis = payload => {
  return {
    type: ActionTypes.GET_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const upsertTreatmentPlanProblem = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_PROBLEM_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const upsertMedicalAnalysis = payload => {
  return {
    type: ActionTypes.UPSERT_MEDICAL_ANALYSIS_REQUEST,
    payload: payload,
  };
};
const resetUpsertMedicalAnalysis = payload => {
  return {
    type: ActionTypes.UPSERT_MEDICAL_ANALYSIS_RESET,
    payload: payload,
  };
};
const upsertTreatmentPlanGoal = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_GOAL_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const upsertTreatmentPlanObjective = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_OBJECTIVE_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const upsertTreatmentPlanIntervention = (payload, treatmentPlanDimensionId) => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_INTERVENTION_REQUEST,
    payload: payload,
    treatmentPlanDimensionId,
  };
};
const deleteTreatmentPlan = payload => {
  return {
    type: ActionTypes.DELETE_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const getDiagnosisDescription = payload => {
  return {
    type: ActionTypes.GET_DIAGNOSIS_DESCRIPTION_REQUEST,
    payload: payload,
  };
};
const getDiagnosisCode = payload => {
  return {
    type: ActionTypes.GET_DIAGNOSIS_CODE_REQUEST,
    payload: payload,
  };
};
const listTreatmentPlan = payload => {
  return {
    type: ActionTypes.LIST_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const resetListTreatmentPlan = payload => {
  return {
    type: ActionTypes.LIST_TREATMENT_PLAN_RESET,
    payload: payload,
  };
};
const comboProblemTemplate = payload => {
  return {
    type: ActionTypes.COMBO_PROBLEM_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const comboGoalTemplate = payload => {
  return {
    type: ActionTypes.COMBO_GOAL_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const recurringCount = payload => {
  return {
    type: ActionTypes.RECURRING_COUNT_REQUEST,
    payload: payload,
  };
};
const staffPermission = payload => {
  return {
    type: ActionTypes.GET_STAFF_PERMISSION_REQUEST,
    payload: payload,
  };
};
const updateStaffPermission = payload => {
  return {
    type: ActionTypes.UPDATE_STAFF_PERMISSION_REQUEST,
    payload: payload,
  };
};
const loadCaseProfile = payload => {
  return {
    type: ActionTypes.LOAD_CASE_PROFILE_REQUEST,
    payload: payload,
  };
};
const loadLocationAuthHistory = payload => {
  return {
    type: ActionTypes.LOAD_LOCATION_AUTH_HISTORY_REQUEST,
    payload: payload,
  };
};
const screenAuthorization = payload => {
  return {
    type: ActionTypes.SCREEN_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const listConsentForm = payload => {
  return {
    type: ActionTypes.LIST_CONSENT_FORM_REQUEST,
    payload: payload,
  };
};
const upsertTreatmentPlanDimension = payload => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_DIMENSION_REQUEST,
    payload: payload,
  };
};
const saveConsentSign = payload => {
  return {
    type: ActionTypes.SAVE_CONSENT_SIGN_REQUEST,
    payload: payload,
  };
};
const resetSaveConsentSign = payload => {
  return {
    type: ActionTypes.SAVE_CONSENT_SIGN_RESET,
    payload: payload,
  };
};

const consentFormDownload = payload => {
  return {
    type: ActionTypes.CONSENT_FORM_DOWNLOAD_REQUEST,
    payload: payload,
  };
};

const listMeeting = payload => {
  return {
    type: ActionTypes.LIST_MEETING_REQUEST,
    payload: payload,
  };
};
const upsertProgressGroupNote = payload => {
  return {
    type: ActionTypes.UPSERT_PROGRESS_GROUP_NOTE_REQUEST,
    payload: payload,
  };
};
const resetUpsertProgressGroupNote = payload => {
  return {
    type: ActionTypes.UPSERT_PROGRESS_GROUP_NOTE_RESET,
    payload: payload,
  };
};

const getProgressNoteGroup = payload => {
  return {
    type: ActionTypes.GET_PROGRESS_NOTE_GROUP_REQUEST,
    payload: payload,
  };
};
const getUserNotePatient = payload => {
  return {
    type: ActionTypes.GET_USER_NOTE_PATIENT_REQUEST,
    payload: payload,
  };
};
const getUserNoteComment = payload => {
  return {
    type: ActionTypes.GET_USER_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const addUserNoteComment = payload => {
  return {
    type: ActionTypes.ADD_USER_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const getUserNoteEmoji = payload => {
  return {
    type: ActionTypes.GET_USER_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const addUserNoteEmoji = payload => {
  return {
    type: ActionTypes.ADD_USER_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const comboInterventionTemplate = payload => {
  return {
    type: ActionTypes.COMBO_INTERVENTION_TEMPLATE_REQUEST,
    payload: payload,
  };
};

const getProgressNoteForIntervention = payload => {
  return {
    type: ActionTypes.GET_PROGRESS_NOTE_FOR_INTERVENTION_REQUEST,
    payload: payload,
  };
};
const upsertPayer = payload => {
  return {
    type: ActionTypes.UPSERT_PAYER_REQUEST,
    payload: payload,
  };
};
const resetUpsertPayer = payload => {
  return {
    type: ActionTypes.UPSERT_PAYER_RESET,
    payload: payload,
  };
};
const listPayer = payload => {
  return {
    type: ActionTypes.LIST_PAYER_REQUEST,
    payload: payload,
  };
};
const deletePayer = payload => {
  return {
    type: ActionTypes.DELETE_PAYER_REQUEST,
    payload: payload,
  };
};
const getPayer = payload => {
  return {
    type: ActionTypes.GET_PAYER_REQUEST,
    payload: payload,
  };
};
const comboPayer = payload => {
  return {
    type: ActionTypes.COMBO_PAYER_REQUEST,
    payload: payload,
  };
};
const upsertServiceCode = payload => {
  return {
    type: ActionTypes.UPSERT_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};
const resetUpsertServiceCode = payload => {
  return {
    type: ActionTypes.UPSERT_SERVICE_CODE_RESET,
    payload: payload,
  };
};
const listServiceCode = payload => {
  return {
    type: ActionTypes.LIST_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};
const deleteServiceCode = payload => {
  return {
    type: ActionTypes.DELETE_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};
const getServiceCode = payload => {
  return {
    type: ActionTypes.GET_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};
const resetGetServiceCode = payload => {
  return {
    type: ActionTypes.GET_SERVICE_CODE_RESET,
    payload: payload,
  };
};

const comboCode = payload => {
  return {
    type: ActionTypes.COMBO_CODE_REQUEST,
    payload: payload,
  };
};
const getCodeDescription = payload => {
  return {
    type: ActionTypes.GET_CODE_DESCRIPTION_REQUEST,
    payload: payload,
  };
};

const resetGetCodeDescription = payload => {
  return {
    type: ActionTypes.GET_CODE_DESCRIPTION_RESET,
    payload: payload,
  };
};

const appointmentNoteList = payload => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_LIST_REQUEST,
    payload: payload,
  };
};
const resetAppointmentNoteList = payload => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_LIST_RESET,
    payload: payload,
  };
};
const upsertAppointmentNote = payload => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const resetUpsertAppointmentNote = payload => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_RESET,
    payload: payload,
  };
};

const getAppointmentNote = payload => {
  return {
    type: ActionTypes.GET_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};
const comboPlaceOfService = payload => {
  return {
    type: ActionTypes.COMBO_PLACE_OF_SERVICE_REQUEST,
    payload: payload,
  };
};
const listServiceLine = payload => {
  return {
    type: ActionTypes.LIST_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const getServiceLine = payload => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const upsertServiceLine = payload => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const resetUpsertServiceLine = payload => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_RESET,
    payload: payload,
  };
};
const upsertAppointmentNoteAddendum = payload => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_ADDENDUM_REQUEST,
    payload: payload,
  };
};

const listGroupAppointmentNote = payload => {
  return {
    type: ActionTypes.LIST_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const getGroupAppointmentNote = payload => {
  return {
    type: ActionTypes.GET_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const comboTreatmentPlanIntervention = payload => {
  return {
    type: ActionTypes.COMBO_TREATMENT_PLAN_INTERVENTION_REQUEST,
    payload: payload,
  };
};

const listFeeSchedule = payload => {
  return {
    type: ActionTypes.LIST_FEE_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const getFeeSchedule = payload => {
  return {
    type: ActionTypes.GET_FEE_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const upsertFeeSchedule = payload => {
  return {
    type: ActionTypes.UPSERT_FEE_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const resetUpsertFeeSchedule = payload => {
  return {
    type: ActionTypes.UPSERT_FEE_SCHEDULE_RESET,
    payload: payload,
  };
};
const getTreatmentPlanGraph = payload => {
  return {
    type: ActionTypes.GET_TREATMENT_PLAN_GRAPH_REQUEST,
    payload: payload,
  };
};
const listGroupServiceLine = payload => {
  return {
    type: ActionTypes.LIST_GROUP_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const getGroupServiceLine = payload => {
  return {
    type: ActionTypes.GET_GROUP_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const getGroupBanner = payload => {
  return {
    type: ActionTypes.GET_GROUP_BANNER_REQUEST,
    payload: payload,
  };
};
const upsertGroupServiceLine = payload => {
  return {
    type: ActionTypes.UPSERT_GROUP_SERVICE_LINE_REQUEST,
    payload: payload,
  };
};
const resetUpsertGroupServiceLine = payload => {
  return {
    type: ActionTypes.UPSERT_GROUP_SERVICE_LINE_RESET,
    payload: payload,
  };
};
const upsertGroupAppointmentNote = payload => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const upsertGroupAppointmentNoteAddendum = payload => {
  return {
    type: ActionTypes.UPSERT_GROUP_APPOINTMENT_NOTE_ADDENDUM_REQUEST,
    payload: payload,
  };
};
const listSurveyQuestionDischarge = payload => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_DISCHARGE_REQUEST,
    payload: payload,
  };
};
const upsertAppointmentNoteIntervention = payload => {
  return {
    type: ActionTypes.UPSERT_APPOINTMENT_NOTE_INTERVENTION_REQUEST,
    payload: payload,
  };
};
const markAsComplete = payload => {
  return {
    type: ActionTypes.MARK_AS_COMPLETE_REQUEST,
    payload: payload,
  };
};
const getServiceLineCost = payload => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_COST_REQUEST,
    payload: payload,
  };
};

const upsertPin = payload => {
  return {
    type: ActionTypes.UPSERT_PIN_REQUEST,
    payload: payload,
  };
};
const validateStaffPin = payload => {
  return {
    type: ActionTypes.VALIDATE_STAFF_PIN_REQUEST,
    payload: payload,
  };
};

const resetValidateStaffPin = payload => {
  return {
    type: ActionTypes.VALIDATE_STAFF_PIN_RESET,
    payload: payload,
  };
};
const loadSmallGroupDashboard = payload => {
  return {
    type: ActionTypes.LOAD_SMALLGROUP_DASHBOARD_REQUEST,
    payload: payload,
  };
};
const upsertTreatmentPlan = payload => {
  return {
    type: ActionTypes.UPSERT_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const listClientDiagnosis = payload => {
  return {
    type: ActionTypes.LIST_CLIENT_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const getClientDiagnosis = payload => {
  return {
    type: ActionTypes.GET_CLIENT_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const deleteClientDiagnosis = payload => {
  return {
    type: ActionTypes.DELETE_CLIENT_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const validatePinExists = payload => {
  return {
    type: ActionTypes.VALIDATE_PIN_EXISTS_REQUEST,
    payload: payload,
  };
};
const cloneDiagnosis = payload => {
  return {
    type: ActionTypes.CLONE_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const cloneTreatmentPlan = payload => {
  return {
    type: ActionTypes.CLONE_TREATMENT_PLAN_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const getBreathalyzerSchedule = payload => {
  return {
    type: ActionTypes.GET_BREATHALYZER_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const listBreathalyzerSchedule = payload => {
  return {
    type: ActionTypes.LIST_BREATHALYZER_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const upsertBreathalyzerSchedule = payload => {
  return {
    type: ActionTypes.UPSERT_BREATHALYZER_SCHEDULE_REQUEST,
    payload: payload,
  };
};
const rcmClientMerge = payload => {
  return {
    type: ActionTypes.RCM_CLIENT_MERGE_REQUEST,
    payload: payload,
  };
};

const preValidatePin = payload => {
  return {
    type: ActionTypes.PRE_VALIDATE_PIN_REQUEST,
    payload: payload,
  };
};
const validateClient = payload => {
  return {
    type: ActionTypes.VALIDATE_CLIENT_REQUEST,
    payload: payload,
  };
};
const getAppAdvisor = payload => {
  return {
    type: ActionTypes.GET_APP_ADVISOR_REQUEST,
    payload: payload,
  };
};
const getMediaVideoUrl = payload => {
  return {
    type: ActionTypes.GET_MEDIA_VIDEO_URL_REQUEST,
    payload: payload,
  };
};
const getDashboardMenu = payload => {
  return {
    type: ActionTypes.GET_DASHBOARD_MENU_REQUEST,
    payload: payload,
  };
};
const breathalyzerPushATest = payload => {
  return {
    type: ActionTypes.BREATHALYZER_PUSH_A_TEST_REQUEST,
    payload: payload,
  };
};
const listServiceCodeBundle = payload => {
  return {
    type: ActionTypes.LIST_SERVICE_CODE_BUNDLE_REQUEST,
    payload: payload,
  };
};
const getServiceCodeBundle = payload => {
  return {
    type: ActionTypes.GET_SERVICE_CODE_BUNDLE_REQUEST,
    payload: payload,
  };
};
const getStudentNote = payload => {
  return {
    type: ActionTypes.GET_STUDENT_NOTE_REQUEST,
    payload: payload,
  };
};
const getStudentNoteComment = payload => {
  return {
    type: ActionTypes.GET_STUDENT_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const addStudentNoteComment = payload => {
  return {
    type: ActionTypes.ADD_STUDENT_NOTE_COMMENT_REQUEST,
    payload: payload,
  };
};
const studentNoteListGet = payload => {
  return {
    type: ActionTypes.STUDENT_NOTE_LIST_GET_REQUEST,
    payload: payload,
  };
};
const addStudentNoteEmoji = payload => {
  return {
    type: ActionTypes.ADD_STUDENT_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const getStudentNoteEmoji = payload => {
  return {
    type: ActionTypes.GET_STUDENT_NOTE_EMOJI_REQUEST,
    payload: payload,
  };
};
const getTreatmentPlanAccordin = payload => {
  return {
    type: ActionTypes.GET_TREATMENT_PLAN_ACCORDIN_REQUEST,
    payload: payload,
  };
};
const breathalyzerEnable = payload => {
  return {
    type: ActionTypes.BREATHALYZER_ENABLE_REQUEST,
    payload: payload,
  };
};
const getBreathalyzerTestResult = payload => {
  return {
    type: ActionTypes.GET_BREATHALYZER_TEST_RESULT_REQUEST,
    payload: payload,
  };
};
const getBreathalyzerTestUpcoming = payload => {
  return {
    type: ActionTypes.GET_BREATHALYZER_TEST_UPCOMING_REQUEST,
    payload: payload,
  };
};
const breathalyzerTestUpcomingToggle = payload => {
  return {
    type: ActionTypes.BREATHALYZER_TEST_UPCOMING_TOGGLE_REQUEST,
    payload: payload,
  };
};
const getAdvisorCost = payload => {
  return {
    type: ActionTypes.GET_ADVISOR_COST_REQUEST,
    payload: payload,
  };
};
const upsertAdvisorCost = payload => {
  return {
    type: ActionTypes.UPSERT_ADVISOR_COST_REQUEST,
    payload: payload,
  };
};
const resetUpsertAdvisorCost = payload => {
  return {
    type: ActionTypes.UPSERT_ADVISOR_COST_RESET,
    payload: payload,
  };
};

const claimsPdfDownload = payload => {
  return {
    type: ActionTypes.CLAIMS_PDF_DOWNLOAD_REQUEST,
    payload: payload,
  };
};
const getClientService = payload => {
  return {
    type: ActionTypes.GET_CLIENT_SERVICE_REQUEST,
    payload: payload,
  };
};
const getListTimeLine = payload => {
  return {
    type: ActionTypes.GET_LIST_TIMELINE_REQUEST,
    payload: payload,
  };
};
const resetListTimeLine = payload => {
  return {
    type: ActionTypes.GET_LIST_TIMELINE_RESET,
    payload: payload,
  };
};
const getStudentBreathalyzerTestResult = payload => {
  return {
    type: ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_REQUEST,
    payload: payload,
  };
};
const getDashboardDailySchedule = payload => {
  return {
    type: ActionTypes.GET_DASHBOARD_DAILY_SCHEDULES_REQUEST,
    payload: payload,
  };
};
const getDashboardDailyCaseLoad = payload => {
  return {
    type: ActionTypes.GET_DASHBOARD_DAILY_CASE_LOAD_REQUEST,
    payload: payload,
  };
};
const getDashboardAlert = payload => {
  return {
    type: ActionTypes.GET_DASHBOARD_ALERT_REQUEST,
    payload: payload,
  };
};
const getCenterAdvisorDefaultCosts = payload => {
  return {
    type: ActionTypes.GET_CENTER_ADVISOR_DEFAULT_COSTS_REQUEST,
    payload: payload,
  };
};
const updateCenterAdvisorDefaultCost = payload => {
  return {
    type: ActionTypes.UPDATE_CENTER_ADVISOR_DEFAULT_COST_REQUEST,
    payload: payload,
  };
};
const resetUpdateCenterAdvisorDefaultCost = payload => {
  return {
    type: ActionTypes.UPDATE_CENTER_ADVISOR_DEFAULT_COST_RESET,
    payload: payload,
  };
};
const addClientInsuranceEligibility = payload => {
  return {
    type: ActionTypes.ADD_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
    payload: payload,
  };
};
const editClientInsuranceEligibility = payload => {
  return {
    type: ActionTypes.EDIT_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
    payload: payload,
  };
};
const deleteClientInsuranceEligibility = payload => {
  return {
    type: ActionTypes.DELETE_CLIENT_INSURANCE_ELIGIBILITY_REQUEST,
    payload: payload,
  };
};
const getStaffPractitioner = payload => {
  return {
    type: ActionTypes.GET_STAFF_PRACTITIONER_REQUEST,
    payload: payload,
  };
};
const dischargeSummarySave = payload => {
  return {
    type: ActionTypes.DISCHARGE_SUMMERY_SAVE_REQUEST,
    payload: payload,
  };
};
const getClientAppointment = payload => {
  return {
    type: ActionTypes.GET_CLIENT_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const resetGetClientAppointment = payload => {
  return {
    type: ActionTypes.GET_CLIENT_APPOINTMENT_RESET,
    payload: payload,
  };
};
const getGroupAppointment = payload => {
  return {
    type: ActionTypes.GET_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const unsignForm = payload => {
  return {
    type: ActionTypes.UNSIGN_FORM_REQUEST,
    payload: payload,
  };
};
const getFilters = payload => {
  return {
    type: ActionTypes.GET_FILTERS_REQUEST,
    payload: payload,
  };
};
const listLabOrder = payload => {
  return {
    type: ActionTypes.LIST_LAB_ORDER_REQUEST,
    payload: payload,
  };
};
const getLabOrder = payload => {
  return {
    type: ActionTypes.GET_LAB_ORDER_REQUEST,
    payload: payload,
  };
};
const resetGetLabOrder = payload => {
  return {
    type: ActionTypes.GET_LAB_ORDER_RESET,
    payload: payload,
  };
};
const upsertLabOrder = payload => {
  return {
    type: ActionTypes.UPSERT_LAB_ORDER_REQUEST,
    payload: payload,
  };
};
const labOrderLabelGet = payload => {
  return {
    type: ActionTypes.LAB_ORDER_LABEL_GET_REQUEST,
    payload: payload,
  };
};
const comboDiagnosisList = payload => {
  return {
    type: ActionTypes.COMBO_DIAGNOSIS_LIST_REQUEST,
    payload: payload,
  };
};
const getMedicationNote = payload => {
  return {
    type: ActionTypes.GET_MEDICATION_NOTE_REQUEST,
    payload: payload,
  };
};
const upsertClientGroupAppointment = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_GROUP_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const signGroupAppointmentNote = payload => {
  return {
    type: ActionTypes.SIGN_GROUP_APPOINTMENT_NOTE_REQUEST,
    payload: payload,
  };
};

const listServiceLinePayment = payload => {
  return {
    type: ActionTypes.LIST_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const getServiceLinePayment = payload => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const upsertServiceLinePayment = payload => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const deleteServiceLinePayment = payload => {
  return {
    type: ActionTypes.DELETE_SERVICE_LINE_PAYMENT_REQUEST,
    payload: payload,
  };
};
const comboSupervisor = payload => {
  return {
    type: ActionTypes.COMBO_SUPERVISOR_REQUEST,
    payload: payload,
  };
};
const locationDayHistory = payload => {
  return {
    type: ActionTypes.LOCATION_DAY_HISTORY_REQUEST,
    payload: payload,
  };
};
const upsertPaymentClient = payload => {
  return {
    type: ActionTypes.UPSERT_PAYMENT_CLIENT_REQUEST,
    payload: payload,
  };
};
const resetUpsertPaymentClient = payload => {
  return {
    type: ActionTypes.UPSERT_PAYMENT_CLIENT_RESET,
    payload: payload,
  };
};
const getInsurancePriorityOrder = payload => {
  return {
    type: ActionTypes.GET_INSURANCE_PRIORITY_ORDER_REQUEST,
    payload: payload,
  };
};
const upsertInsurancePriorityOrder = payload => {
  return {
    type: ActionTypes.UPSERT_INSURANCE_PRIORITY_ORDER_REQUEST,
    payload: payload,
  };
};
const listCWPayment = payload => {
  return {
    type: ActionTypes.LIST_CW_PAYMENT_REQUEST,
    payload: payload,
  };
};
const getCWPayment = payload => {
  return {
    type: ActionTypes.GET_CW_PAYMENT_REQUEST,
    payload: payload,
  };
};
const deleteCWPayment = payload => {
  return {
    type: ActionTypes.DELETE_CW_PAYMENT_REQUEST,
    payload: payload,
  };
};
const pastMeetingInfo = payload => {
  return {
    type: ActionTypes.PAST_MEETING_INFO_REQUEST,
    payload: payload,
  };
};
const listPastMeetingInfo = payload => {
  return {
    type: ActionTypes.LIST_PAST_MEETING_INFO_REQUEST,
    payload: payload,
  };
};

const comboAppointmentTreatmentPlan = payload => {
  return {
    type: ActionTypes.COMBO_APPOINTMENT_TREATMENT_PLAN_REQUEST,
    payload: payload,
  };
};
const linkAppointment = payload => {
  return {
    type: ActionTypes.LINK_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const appointmentLinkGet = payload => {
  return {
    type: ActionTypes.APPOINTMENT_LINK_GET_REQUEST,
    payload: payload,
  };
};
const getBasicProfile = payload => {
  return {
    type: ActionTypes.GET_BASIC_PROFILE_REQUEST,
    payload: payload,
  };
};
const getInvitedList = payload => {
  return {
    type: ActionTypes.GET_INVITED_LIST_REQUEST,
    payload: payload,
  };
};
const inviteResend = payload => {
  return {
    type: ActionTypes.INVITE_RESEND_REQUEST,
    payload: payload,
  };
};
const suspiciousTermAck = payload => {
  return {
    type: ActionTypes.SUSPICIOUS_TERM_ACK_REQUEST,
    payload: payload,
  };
};
const getBroadcastFilter = payload => {
  return {
    type: ActionTypes.GET_BROADCAST_FILTER_REQUEST,
    payload: payload,
  };
};
const sendBroadcast = payload => {
  return {
    type: ActionTypes.SEND_BROADCAST_REQUEST,
    payload: payload,
  };
};
const getServiceLineDiagnosis = payload => {
  return {
    type: ActionTypes.GET_SERVICE_LINE_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const upsertServiceLineDiagnosis = payload => {
  return {
    type: ActionTypes.UPSERT_SERVICE_LINE_DIAGNOSIS_REQUEST,
    payload: payload,
  };
};
const comboBroadcastTemplate = payload => {
  return {
    type: ActionTypes.COMBO_BROADCAST_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const upsertBroadcastTemplate = payload => {
  return {
    type: ActionTypes.UPSERT_BROADCAST_TEMPLATE_REQUEST,
    payload: payload,
  };
};
const deleteNoteType = payload => {
  return {
    type: ActionTypes.DELETE_NOTE_TYPE_REQUEST,
    payload: payload,
  };
};
const listClientAuthorization = payload => {
  return {
    type: ActionTypes.LIST_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const getClientAuthorization = payload => {
  return {
    type: ActionTypes.GET_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const resertGetClientAuthorization = payload => {
  return {
    type: ActionTypes.GET_CLIENT_AUTHORIZATION_RESET,
    payload: payload,
  };
};
const deleteClientAuthorization = payload => {
  return {
    type: ActionTypes.DELETE_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const upsertClientAuthorization = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_AUTHORIZATION_REQUEST,
    payload: payload,
  };
};
const getNoteTypeArchive = payload => {
  return {
    type: ActionTypes.GET_NOTE_TYPE_ARCHIVE_REQUEST,
    payload: payload,
  };
};
const getNoteTypeList = payload => {
  return {
    type: ActionTypes.GET_NOTE_TYPE_LIST_REQUEST,
    payload: payload,
  };
};
const upsertCouponCode = payload => {
  return {
    type: ActionTypes.UPSERT_COUPON_CODE_REQUEST,
    payload: payload,
  };
};
const resetUpsertCouponCode = payload => {
  return {
    type: ActionTypes.UPSERT_COUPON_CODE_RESET,
    payload: payload,
  };
};
const deleteCouponCode = payload => {
  return {
    type: ActionTypes.DELETE_COUPON_CODE_REQUEST,
    payload: payload,
  };
};
const resetDeleteCouponCode = payload => {
  return {
    type: ActionTypes.DELETE_COUPON_CODE_RESET,
    payload: payload,
  };
};
const getCouponCode = payload => {
  return {
    type: ActionTypes.GET_COUPON_CODE_REQUEST,
    payload: payload,
  };
};

const centerReactivate = payload => {
  return {
    type: ActionTypes.CENTER_REACTIVATE_REQUEST,
    payload: payload,
  };
};
const resetCenterReactivate = payload => {
  return {
    type: ActionTypes.CENTER_REACTIVATE_RESET,
    payload: payload,
  };
};
const generateCallToken = payload => {
  return {
    type: ActionTypes.GENERATE_CALL_TOKEN_REQUEST,
    payload: payload,
  };
};

const badgeComboGet = payload => {
  return {
    type: ActionTypes.BADGE_COMBO_GET_REQUEST,
    payload: payload,
  };
};
const badgeUpdate = payload => {
  return {
    type: ActionTypes.BADGE_UPDATE_REQUEST,
    payload: payload,
  };
};
const panasScaleMoodsGet = payload => {
  return {
    type: ActionTypes.PANAS_SCALE_MOODS_GET_REQUEST,
    payload: payload,
  };
};
const panasTagUpsert = payload => {
  return {
    type: ActionTypes.PANAS_TAG_UPSERT_REQUEST,
    payload: payload,
  };
};
const bedAssignmentGetAll = payload => {
  return {
    type: ActionTypes.BED_ASSIGNMENT_GET_ALL_REQUEST,
    payload: payload,
  };
};
const bedAssignmentUpsert = payload => {
  return {
    type: ActionTypes.BED_ASSIGNMENT_UPSERT_REQUEST,
    payload: payload,
  };
};
const upcomingDischargesGetAll = payload => {
  return {
    type: ActionTypes.UPCOMING_DISCHARGES_GET_ALL_REQUEST,
    payload: payload,
  };
};
const upcomingAdmissions = payload => {
  return {
    type: ActionTypes.UPCOMING_ADMISSIONS_REQUEST,
    payload: payload,
  };
};
const admissionCancel = payload => {
  return {
    type: ActionTypes.ADMISSION_CANCEL_REQUEST,
    payload: payload,
  };
};
const panasTagDelete = payload => {
  return {
    type: ActionTypes.PANAS_TAG_DELETE_REQUEST,
    payload: payload,
  };
};
const dynamicGridList = payload => {
  return {
    type: ActionTypes.DYNAMIC_GRID_LIST_REQUEST,
    payload: payload,
  };
};
const getGrid = payload => {
  return {
    type: ActionTypes.GET_GRID_REQUEST,
    payload: payload,
  };
};
const getGridData = payload => {
  return {
    type: ActionTypes.GET_GRID_DATA_REQUEST,
    payload: payload,
  };
};
const gridOnRowClickGet = payload => {
  return {
    type: ActionTypes.GRID_ON_ROW_CLICK_GET_REQUEST,
    payload: payload,
  };
};
const resetGridOnRowClickGet = payload => {
  return {
    type: ActionTypes.GRID_ON_ROW_CLICK_GET_RESET,
    payload: payload,
  };
};
const upsertFormData = payload => {
  return {
    type: ActionTypes.UPSERT_FORM_DATA_REQUEST,
    payload: payload,
  };
};
const formDataGet = payload => {
  return {
    type: ActionTypes.FORM_DATA_GET_REQUEST,
    payload: payload,
  };
};
const resetFormDataGet = payload => {
  return {
    type: ActionTypes.FORM_DATA_GET_RESET,
    payload: payload,
  };
};
const getPredefinedTask = payload => {
  return {
    type: ActionTypes.GET_PREDEFINED_TASK_REQUEST,
    payload: payload,
  };
};
const getPredefinedTaskArchive = payload => {
  return {
    type: ActionTypes.GET_PREDEFINED_TASK_ARCHIVE_REQUEST,
    payload: payload,
  };
};
const deletePredefinedTask = payload => {
  return {
    type: ActionTypes.DELETE_PREDEFINED_TASK_REQUEST,
    payload: payload,
  };
};
const upsertPredefinedTask = payload => {
  return {
    type: ActionTypes.UPSERT_PREDEFINED_TASK_REQUEST,
    payload: payload,
  };
};
const resetPredefinedTask = payload => {
  return {
    type: ActionTypes.UPSERT_PREDEFINED_TASK_RESET,
    payload: payload,
  };
};
const listSurveyQuestionCategoryGetAll = payload => {
  return {
    type: ActionTypes.LIST_SURVEY_QUESTION_CATEGORY_GET_ALL_REQUEST,
    payload: payload,
  };
};
const getAdvisorMemberTrends = payload => {
  return {
    type: ActionTypes.GET_ADVISOR_MEMBER_TRENDS_REQUEST,
    payload: payload,
  };
};
const updateSmallGroupAttendence = payload => {
  return {
    type: ActionTypes.UPDATE_SMALL_GROUP_ATTENDENCE_REQUEST,
    payload: payload,
  };
};
const updateUserProfile = payload => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE_SET,
    payload: payload,
  };
};
const getAdvisorMemberTrendsHistory = payload => {
  return {
    type: ActionTypes.GET_ADVISOR_MEMBER_TRENDS_HISTORY_REQUEST,
    payload: payload,
  };
};
const mobileInviteOptionsLoad = payload => {
  return {
    type: ActionTypes.MOBILE_INVITE_OPTIONS_LOAD_REQUEST,
    payload: payload,
  };
};
const getAppUserSubscription = payload => {
  return {
    type: ActionTypes.GET_APP_USER_SUBSCRIPTION_REQUEST,
    payload: payload,
  };
};
const getServiceOptions = payload => {
  return {
    type: ActionTypes.GET_SERVICE_OPTION_REQUEST,
    payload: payload,
  };
};
const getServiceAdvisorList = payload => {
  return {
    type: ActionTypes.GET_SERVICE_ADVISOR_LIST_REQUEST,
    payload: payload,
  };
};
const setServiceAdvisor = payload => {
  return {
    type: ActionTypes.SET_SERVICE_ADVISOR_REQUEST,
    payload: payload,
  };
};
const updateCancelService = payload => {
  return {
    type: ActionTypes.UPDATE_CANCEL_SERVICE_REQUEST,
    payload: payload,
  };
};
const setMembership = payload => {
  return {
    type: ActionTypes.SET_MEMBERSHIP_REQUEST,
    payload: payload,
  };
};
const cancelMembership = payload => {
  return {
    type: ActionTypes.CANCEL_MEMBERSHIP_REQUEST,
    payload: payload,
  };
};
const setServiceSubscription = payload => {
  return {
    type: ActionTypes.SET_SERVICE_SUBSCRIPTION_REQUEST,
    payload: payload,
  };
};
const upsertClientPin = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_PIN_REQUEST,
    payload: payload,
  };
};

const resetClientPin = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_PIN_RESET,
    payload: payload,
  };
};
const getStudentTimeLine = payload => {
  return {
    type: ActionTypes.GET_STUDENT_TIMELINE_REQUEST,
    payload: payload,
  };
};
const resetStudentTimeLine = payload => {
  return {
    type: ActionTypes.GET_STUDENT_TIMELINE_RESET,
    payload: payload,
  };
};
const getClientTimeLine = payload => {
  return {
    type: ActionTypes.GET_CLIENT_TIMELINE_REQUEST,
    payload: payload,
  };
};
const resetClientTimeLine = payload => {
  return {
    type: ActionTypes.GET_CLIENT_TIMELINE_RESET,
    payload: payload,
  };
};
const clientStudentSynchronize = payload => {
  return {
    type: ActionTypes.CLIENT_STUDENT_SYNCHRONIZE_REQUEST,
    payload: payload,
  };
};
const updateUserMembership = payload => {
  return {
    type: ActionTypes.UPDATE_USER_MEMBERSHIP_REQUEST,
    payload: payload,
  };
};
const comboAppAdvisor = payload => {
  return {
    type: ActionTypes.COMBO_APP_ADVISOR_REQUEST,
    payload: payload,
  };
};
const getSurveyCategoryQuestion = payload => {
  return {
    type: ActionTypes.GET_SURVEY_CATEGORY_QUESTIONS_REQUEST,
    payload: payload,
  };
};
const resetGetSurveyCategoryQuestion = payload => {
  return {
    type: ActionTypes.GET_SURVEY_CATEGORY_QUESTIONS_RESET,
    payload: payload,
  };
};
const upsertBlockAppointment = payload => {
  return {
    type: ActionTypes.UPSERT_BLOCK_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const upsertClientMemo = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_MEMO_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientMemo = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_MEMO_RESET,
    payload: payload,
  };
};
const deleteClientMemo = payload => {
  return {
    type: ActionTypes.DELETE_CLIENT_MEMO_REQUEST,
    payload: payload,
  };
};
const resetDeleteClientMemo = payload => {
  return {
    type: ActionTypes.DELETE_CLIENT_MEMO_RESET,
    payload: payload,
  };
};
const getClientMemo = payload => {
  return {
    type: ActionTypes.GET_CLIENT_MEMO_REQUEST,
    payload: payload,
  };
};
const resetUpsertBlockAppointment = payload => {
  return {
    type: ActionTypes.UPSERT_BLOCK_APPOINTMENT_RESET,
    payload: payload,
  };
};
const validateSSN = payload => {
  return {
    type: ActionTypes.VALIDATE_SSN_REQUEST,
    payload: payload,
  };
};
const userAppLinkValidate = payload => {
  return {
    type: ActionTypes.USER_APP_LINK_VALIDATE_REQUEST,
    payload: payload,
  };
};
const resetUserAppLinkValidate = payload => {
  return {
    type: ActionTypes.USER_APP_LINK_VALIDATE_RESET,
    payload: payload,
  };
};
const clientCenterMove = payload => {
  return {
    type: ActionTypes.CLIENT_CENTER_MOVE_REQUEST,
    payload: payload,
  };
};
const formTabListGet = payload => {
  return {
    type: ActionTypes.FORM_TAB_LIST_GET_REQUEST,
    payload: payload,
  };
};
const resetFormTabListGet = payload => {
  return {
    type: ActionTypes.FORM_TAB_LIST_GET_RESET,
    payload: payload,
  };
};
const upsertClientCareTeam = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_CARE_TEAM_REQUEST,
    payload: payload,
  };
};
const resetUpsertClientCareTeam = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_CARE_TEAM_RESET,
    payload: payload,
  };
};
const clientCareTeamGet = payload => {
  return {
    type: ActionTypes.CLIENT_CARE_TEAM_GET_REQUEST,
    payload: payload,
  };
};
const resetClientCareTeamGet = payload => {
  return {
    type: ActionTypes.CLIENT_CARE_TEAM_GET_RESET,
    payload: payload,
  };
};

const getClientCareTeamList = payload => {
  return {
    type: ActionTypes.GET_CLIENT_CARE_TEAM_LIST_REQUEST,
    payload: payload,
  };
};

const clientCareTeamDelete = payload => {
  return {
    type: ActionTypes.CLIENT_CARE_TEAM_DELETE_REQUEST,
    payload: payload,
  };
};

const resetClientCareTeamDelete = payload => {
  return {
    type: ActionTypes.CLIENT_CARE_TEAM_DELETE_RESET,
    payload: payload,
  };
};

const careTeamMemberCombo = payload => {
  return {
    type: ActionTypes.CARE_TEAM_MEMBER_COMBO_REQUEST,
    payload: payload,
  };
};

const resetCareTeamMemberCombo = payload => {
  return {
    type: ActionTypes.CARE_TEAM_MEMBER_COMBO_RESET,
    payload: payload,
  };
};

const externalProviderListGet = payload => {
  return {
    type: ActionTypes.EXTERNAL_PROVIDER_LIST_GET_REQUEST,
    payload: payload,
  };
};

const externalProviderGet = payload => {
  return {
    type: ActionTypes.EXTERNAL_PROVIDER_GET_REQUEST,
    payload: payload,
  };
};

const resetExternalProviderGet = payload => {
  return {
    type: ActionTypes.EXTERNAL_PROVIDER_GET_RESET,
    payload: payload,
  };
};

const externalProviderFilterGet = payload => {
  return {
    type: ActionTypes.EXTERNAL_PROVIDER_FILTER_GET_REQUEST,
    payload: payload,
  };
};

const externalProviderAuthentication = payload => {
  return {
    type: ActionTypes.EXTERNAL_PROVIDER_AUTHENTICATION_REQUEST,
    payload: payload,
  };
};

const externalStaffArchive = payload => {
  return {
    type: ActionTypes.EXTERNAL_STAFF_ARCHIVE_REQUEST,
    payload: payload,
  };
};

const resetExternalStaffArchive = payload => {
  return {
    type: ActionTypes.EXTERNAL_STAFF_ARCHIVE_RESET,
    payload: payload,
  };
};
const externalProviderUpsert = payload => {
  return {
    type: ActionTypes.EXTERNAL_PROVIDER_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetExternalProviderUpsert = payload => {
  return {
    type: ActionTypes.EXTERNAL_PROVIDER_UPSERT_RESET,
    payload: payload,
  };
};

const organizationCenterFamilyCombo = payload => {
  return {
    type: ActionTypes.ORGANIZATION_CENTER_FAMILY_COMBO_REQUEST,
    payload: payload,
  };
};

const upsertClientVital = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_VITAL_REQUEST,
    payload: payload,
  };
};
const restUpsertClientVital = payload => {
  return {
    type: ActionTypes.UPSERT_CLIENT_VITAL_RESET,
    payload: payload,
  };
};
const upsertMedicationLog = payload => {
  return {
    type: ActionTypes.UPSERT_MEDICATION_LOG_REQUEST,
    payload: payload,
  };
};
const resetUpsertMedicationLog = payload => {
  return {
    type: ActionTypes.UPSERT_MEDICATION_LOG_RESET,
    payload: payload,
  };
};
const getMedicationLog = payload => {
  return {
    type: ActionTypes.GET_MEDICATION_LOG_REQUEST,
    payload: payload,
  };
};
const getClientVital = payload => {
  return {
    type: ActionTypes.GET_CLIENT_VITAL_REQUEST,
    payload: payload,
  };
};
const getMedicationLogList = payload => {
  return {
    type: ActionTypes.GET_MEDICATION_LOG_LIST_REQUEST,
    payload: payload,
  };
};
const getClientVitalList = payload => {
  return {
    type: ActionTypes.GET_CLIENT_VITAL_LIST_REQUEST,
    payload: payload,
  };
};
const getCHPatientList = payload => {
  return {
    type: ActionTypes.GET_CH_PATIENT_LIST_REQUEST,
    payload: payload,
  };
};
const CHPatientServiceSend = payload => {
  return {
    type: ActionTypes.GET_CH_PATIENT_SERVICE_SEND_REQUEST,
    payload: payload,
  };
};
const pushClaimsToCH = payload => {
  return {
    type: ActionTypes.PUSH_CLAIMS_TO_CH_REQUEST,
    payload: payload,
  };
};
const pushSingleClaimToCH = payload => {
  return {
    type: ActionTypes.PUSH_SINGLE_CLAIM_TO_CH_REQUEST,
    payload: payload,
  };
};
const resetPushSingleClaimToCH = payload => {
  return {
    type: ActionTypes.PUSH_SINGLE_CLAIM_TO_CH_RESET,
    payload: payload,
  };
};
const getBillableClaims = payload => {
  return {
    type: ActionTypes.GET_BILLABLE_CLAIMS_REQUEST,
    payload: payload,
  };
};
const cloneForm = payload => {
  return {
    type: ActionTypes.CLONE_FORM_REQUEST,
    payload: payload,
  };
};
const clearSignature = payload => {
  return {
    type: ActionTypes.CLEAR_SIGNATURE_REQUEST,
    payload: payload,
  };
};
const resetClearSignature = payload => {
  return {
    type: ActionTypes.CLEAR_SIGNATURE_RESET,
    payload: payload,
  };
};
const upsertStaffViewedActivity = payload => {
  return {
    type: ActionTypes.UPSERT_STAFF_VIEWED_ACTIVITY_REQUEST,
    payload: payload,
  };
};

const deleteStudentNote = payload => {
  return {
    type: ActionTypes.DELETE_STUDENT_NOTE_REQUEST,
    payload: payload,
  };
};

const staffAccessedTabGet = payload => {
  return {
    type: ActionTypes.STAFF_ACCESSED_TAB_GET_REQUEST,
    payload: payload,
  };
};

const studentStaffAccessedTabGet = payload => {
  return {
    type: ActionTypes.STUDENT_STAFF_ACCESSED_TAB_GET_REQUEST,
    payload: payload,
  };
};

const appAnnouncementUpsert = payload => {
  return {
    type: ActionTypes.APP_ANNOUNCEMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const appAnnouncementListGetAll = payload => {
  return {
    type: ActionTypes.APP_ANNOUNCEMENT_LIST_GET_ALL_REQUEST,
    payload: payload,
  };
};

const appAnnouncementGet = payload => {
  return {
    type: ActionTypes.APP_ANNOUNCEMENT_GET_REQUEST,
    payload: payload,
  };
};

const tokenManagementListGetAll = payload => {
  return {
    type: ActionTypes.TOKEN_MANAGEMENT_LIST_GET_ALL_REQUEST,
    payload: payload,
  };
};
const tokenManagementStatsGet = payload => {
  return {
    type: ActionTypes.TOKEN_MANAGEMENT_STATS_GET_REQUEST,
    payload: payload,
  };
};

const resourceUpsert = payload => {
  return {
    type: ActionTypes.RESOURCE_UPSERT_REQUEST,
    payload: payload,
  };
};

const resourceListGetAll = payload => {
  return {
    type: ActionTypes.RESOURCE_LIST_GET_ALL_REQUEST,
    payload: payload,
  };
};

const resourceGet = payload => {
  return {
    type: ActionTypes.RESOURCE_GET_REQUEST,
    payload: payload,
  };
};

const allergenSearch = payload => {
  return {
    type: ActionTypes.ALLERGEN_SEARCH_REQUEST,
    payload: payload,
  };
};
const resetAllergenSearch = payload => {
  return {
    type: ActionTypes.ALLERGEN_SEARCH_RESET,
    payload: payload,
  };
};
const drugSearch = payload => {
  return {
    type: ActionTypes.DRUG_SEARCH_REQUEST,
    payload: payload,
  };
};
const resetDrugSearch = payload => {
  return {
    type: ActionTypes.DRUG_SEARCH_RESET,
    payload: payload,
  };
};
const clientAllergyGet = payload => {
  return {
    type: ActionTypes.CLIENT_ALLERGY_GET_REQUEST,
    payload: payload,
  };
};
const allergyOptionsGet = payload => {
  return {
    type: ActionTypes.ALLERGY_OPTIONS_GET_REQUEST,
    payload: payload,
  };
};
const clientAllergyUpsert = payload => {
  return {
    type: ActionTypes.CLIENT_ALLERGY_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetClientAllergyUpsert = payload => {
  return {
    type: ActionTypes.CLIENT_ALLERGY_UPSERT_RESET,
    payload: payload,
  };
};
const clientAllergyGetAll = payload => {
  return {
    type: ActionTypes.CLIENT_ALLERGY_GET_ALL_REQUEST,
    payload: payload,
  };
};
const clientAllergyDelete = payload => {
  return {
    type: ActionTypes.CLIENT_ALLERGY_DELETE_REQUEST,
    payload: payload,
  };
};
const resetClientAllergyDelete = payload => {
  return {
    type: ActionTypes.CLIENT_ALLERGY_DELETE_RESET,
    payload: payload,
  };
};
const clientMedicationUpsert = payload => {
  return {
    type: ActionTypes.CLIENT_MEDICATION_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetClientMedicationUpsert = payload => {
  return {
    type: ActionTypes.CLIENT_MEDICATION_UPSERT_RESET,
    payload: payload,
  };
};
const clientMedicationGetAll = payload => {
  return {
    type: ActionTypes.CLIENT_MEDICATION_GET_ALL_REQUEST,
    payload: payload,
  };
};
const clientMedicationDelete = payload => {
  return {
    type: ActionTypes.CLIENT_MEDICATION_DELETE_REQUEST,
    payload: payload,
  };
};
const resetClientMedicationDelete = payload => {
  return {
    type: ActionTypes.CLIENT_MEDICATION_DELETE_RESET,
    payload: payload,
  };
};
const drugPackageGetAll = payload => {
  return {
    type: ActionTypes.DRUG_PACKAGE_GET_ALL_REQUEST,
    payload: payload,
  };
};
const resetDrugPackageGetAll = payload => {
  return {
    type: ActionTypes.DRUG_PACKAGE_GET_ALL_RESET,
    payload: payload,
  };
};
const prescribeNotificationGet = payload => {
  return {
    type: ActionTypes.PRESCRIBE_NOTIFICATION_GET_REQUEST,
    payload: payload,
  };
};
const prescribeUpdate = payload => {
  return {
    type: ActionTypes.PRESCRIBE_UPDATE_REQUEST,
    payload: payload,
  };
};
const clientSignatureSave = payload => {
  return {
    type: ActionTypes.PATIENT_SIGNATURE_SAVE_REQUEST,
    payload: payload,
  };
};
const resetClientSignatureSave = payload => {
  return {
    type: ActionTypes.PATIENT_SIGNATURE_SAVE_RESET,
    payload: payload,
  };
};
const prescriptionListGet = payload => {
  return {
    type: ActionTypes.PRESCRIPTION_LIST_GET_REQUEST,
    payload: payload,
  };
};
const userFeatureFlagGet = payload => {
  return {
    type: ActionTypes.USER_FEATURE_FLAG_GET_REQUEST,
    payload: payload,
  };
};

const augmentedClientInsightsGet = payload => {
  return {
    type: ActionTypes.AUGMENTED_CLIENT_INSIGHTS_GET_REQUEST,
    payload: payload,
  };
};
const resetAugmentedClientInsightsGet = payload => {
  return {
    type: ActionTypes.AUGMENTED_CLIENT_INSIGHTS_GET_RESET,
    payload: payload,
  };
};
const riskChartGet = payload => {
  return {
    type: ActionTypes.RISK_CHART_GET_REQUEST,
    payload: payload,
  };
};

const augmentedSummaryGet = payload => {
  return {
    type: ActionTypes.AUGMENTED_SUMMARY_GET_REQUEST,
    payload: payload,
  };
};

const ePrescribeSinglePatientModeSSO = payload => {
  return {
    type: ActionTypes.EPRESCRIBE_SINGLE_PATIENT_MODE_SSO_REQUEST,
    payload: payload,
  };
};

const resetEPrescribeSinglePatientModeSSO = payload => {
  return {
    type: ActionTypes.EPRESCRIBE_SINGLE_PATIENT_MODE_SSO_RESET,
    payload: payload,
  };
};

const clientReactivate = payload => {
  return {
    type: ActionTypes.CLIENT_REACTIVATE_REQUEST,
    payload: payload,
  };
};

const resetClientReactivate = payload => {
  return {
    type: ActionTypes.CLIENT_REACTIVATE_RESET,
    payload: payload,
  };
};

const clientBannerGet = payload => {
  return {
    type: ActionTypes.CLIENT_BANNER_GET_REQUEST,
    payload: payload,
  };
};

const labOrderCancel = payload => {
  return {
    type: ActionTypes.LAB_ORDER_CANCEL_REQUEST,
    payload: payload,
  };
};

const drugDetailSearch = payload => {
  return {
    type: ActionTypes.DRUG_DETAIL_SEARCH_REQUEST,
    payload: payload,
  };
};

const resetDrugDetailSearch = payload => {
  return {
    type: ActionTypes.DRUG_DETAIL_SEARCH_RESET,
    payload: payload,
  };
};

const labResultGet = payload => {
  return {
    type: ActionTypes.LAB_RESULT_GET_REQUEST,
    payload: payload,
  };
};

const labResultDocumentGet = payload => {
  return {
    type: ActionTypes.LAB_RESULT_DOCUMENT_GET_REQUEST,
    payload: payload,
  };
};

const labOrderDelete = payload => {
  return {
    type: ActionTypes.LAB_ORDER_DELETE_REQUEST,
    payload: payload,
  };
};

const addSupportGroup = payload => {
  return {
    type: ActionTypes.ADD_SUPPORT_GROUP_REQUEST,
    payload: payload,
  };
};

const loadSupportGroup = payload => {
  return {
    type: ActionTypes.LOAD_SUPPORT_GROUP_REQUEST,
    payload: payload,
  };
};

const loadPeer = payload => {
  return {
    type: ActionTypes.LOAD_PEER_REQUEST,
    payload: payload,
  };
};

const loadUserEngagement = payload => {
  return {
    type: ActionTypes.LOAD_USER_ENGAGEMENT_REQUEST,
    payload: payload,
  };
};

const studentBanner = payload => {
  return {
    type: ActionTypes.STUDENT_BANNER_REQUEST,
    payload: payload,
  };
};

const preIntakeSurveyAnswersGet = payload => {
  return {
    type: ActionTypes.PRE_INTAKE_SURVEY_ANSWERS_GET_REQUEST,
    payload: payload,
  };
};

const preIntakeHistoryGet = payload => {
  return {
    type: ActionTypes.PRE_INTAKE_HISTORY_GET_REQUEST,
    payload: payload,
  };
};

const surveyLatestAnswerDateUpdate = payload => {
  return {
    type: ActionTypes.SURVEY_LATEST_ANSWER_DATE_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetPreIntakeHistoryGet = payload => {
  return {
    type: ActionTypes.PRE_INTAKE_HISTORY_GET_RESET,
    payload: payload,
  };
};
const appUserDemographicUpsert = payload => {
  return {
    type: ActionTypes.APP_USER_DEMOGRAPHIC_UPSERT_REQUEST,
    payload: payload,
  };
};

const appUserDemographicGet = payload => {
  return {
    type: ActionTypes.APP_USER_DEMOGRAPHIC_GET_REQUEST,
    payload: payload,
  };
};

const removeSupportGroup = payload => {
  return {
    type: ActionTypes.REMOVE_SUPPORT_GROUP_REQUEST,
    payload: payload,
  };
};

const concernedPersonList = payload => {
  return {
    type: ActionTypes.CONCERNED_PERSON_LIST_REQUEST,
    payload: payload,
  };
};

const concernedPersonDasboardGraphGet = payload => {
  return {
    type: ActionTypes.CONCERNED_PERSON_DASBOARD_GRAPH_GET_REQUEST,
    payload: payload,
  };
};

const advisorSearch = payload => {
  return {
    type: ActionTypes.ADVISOR_SEARCH_REQUEST,
    payload: payload,
  };
};

const listClientGetAllWithPagination = payload => {
  return {
    type: ActionTypes.LIST_CLIENT_GET_ALL_WITH_PAGINATION_REQUEST,
    payload: payload,
  };
};

const appUserLiveLocationGet = payload => {
  return {
    type: ActionTypes.APP_USER_LIVE_LOCATION_GET_REQUEST,
    payload: payload,
  };
};

const studentSurveyListGet = payload => {
  return {
    type: ActionTypes.STUDENT_SURVEY_LIST_GET_REQUEST,
    payload: payload,
  };
};

const GPT3EnrollSurveys = payload => {
  return {
    type: ActionTypes.GPT3_ENROLL_SURVEYS_REQUEST,
    payload: payload,
  };
};

const getMultipleSurveyAugmentedSummary = payload => {
  return {
    type: ActionTypes.GET_MULTIPLE_SURVEY_AUGMENTED_SUMMARY_REQUEST,
    payload: payload,
  };
};

const resetGetMultipleSurveyAugmentedSummary = payload => {
  return {
    type: ActionTypes.GET_MULTIPLE_SURVEY_AUGMENTED_SUMMARY_RESET,
    payload: payload,
  };
};

const GPT3AnswerStatusUpsert = payload => {
  return {
    type: ActionTypes.GPT3_ANSWER_STATUS_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetGPT3AnswerStatusUpsert = payload => {
  return {
    type: ActionTypes.GPT3_ANSWER_STATUS_UPSERT_RESET,
    payload: payload,
  };
};

const augmentWithAI = payload => {
  return {
    type: ActionTypes.AUGMENT_WITH_AI_REQUEST,
    payload: payload,
  };
};

const resetAugmentWithAI = payload => {
  return {
    type: ActionTypes.AUGMENT_WITH_AI_RESET,
    payload: payload,
  };
};

const augmentWithAIGet = payload => {
  return {
    type: ActionTypes.AUGMENT_WITH_AI_GET_REQUEST,
    payload: payload,
  };
};

const resetAugmentWithAIGet = payload => {
  return {
    type: ActionTypes.AUGMENT_WITH_AI_GET_RESET,
    payload: payload,
  };
};

const gridFilterGet = payload => {
  return {
    type: ActionTypes.GRID_FILTER_GET_REQUEST,
    payload: payload,
  };
};

const resetGridFilterGet = payload => {
  return {
    type: ActionTypes.GRID_FILTER_GET_RESET,
    payload: payload,
  };
};

const getGroupDropdown = payload => {
  return {
    type: ActionTypes.GET_GROUP_DROPDOWN_REQUEST,
    payload: payload,
  };
};

const getProspectDashboardGraph = payload => {
  return {
    type: ActionTypes.GET_PROSPECT_DASHBOARD_GRAPH_REQUEST,
    payload: payload,
  };
};
const medicalProgramUpsert = payload => {
  return {
    type: ActionTypes.MEDICAL_PROGRAM_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetMedicalProgramUpsert = payload => {
  return {
    type: ActionTypes.MEDICAL_PROGRAM_UPSERT_RESET,
    payload: payload,
  };
};

const medicalProgramGet = payload => {
  return {
    type: ActionTypes.MEDICAL_PROGRAM_GET_REQUEST,
    payload: payload,
  };
};

const medicalProgramDelete = payload => {
  return {
    type: ActionTypes.MEDICAL_PROGRAM_DELETE_REQUEST,
    payload: payload,
  };
};
const resetMedicalProgramDelete = payload => {
  return {
    type: ActionTypes.MEDICAL_PROGRAM_DELETE_RESET,
    payload: payload,
  };
};
const regionUpsert = payload => {
  return {
    type: ActionTypes.REGION_INSERT_REQUEST,
    payload: payload,
  };
};
const resetRegionUpsert = payload => {
  return {
    type: ActionTypes.REGION_INSERT_RESET,
    payload: payload,
  };
};

const regionGet = payload => {
  return {
    type: ActionTypes.REGION_GET_REQUEST,
    payload: payload,
  };
};

const regionDelete = payload => {
  return {
    type: ActionTypes.REGION_DELETE_REQUEST,
    payload: payload,
  };
};
const resetRegionDelete = payload => {
  return {
    type: ActionTypes.REGION_DELETE_RESET,
    payload: payload,
  };
};
const staffAccessedTabEntGet = payload => {
  return {
    type: ActionTypes.STAFF_ACCESSED_TAB_ENT_GET_REQUEST,
    payload: payload,
  };
};
const studentQueueListGetEnt = payload => {
  return {
    type: ActionTypes.STUDENT_QUEUE_LIST_GET_REQUEST,
    payload: payload,
  };
};
const studentQueueDelete = payload => {
  return {
    type: ActionTypes.STUDENT_QUEUE_DELETE_REQUEST,
    payload: payload,
  };
};
const studentAvailableSpacesListGet = payload => {
  return {
    type: ActionTypes.STUDENT_AVAILABLE_SPACES_LIST_GET_REQUEST,
    payload: payload,
  };
};
const studentQueueInsert = payload => {
  return {
    type: ActionTypes.STUDENT_QUEUE_INSERT_REQUEST,
    payload: payload,
  };
};
const clientScreeningStatusUpdate = payload => {
  return {
    type: ActionTypes.CLIENT_SCREENING_STATUS_UPDATE_REQUEST,
    payload: payload,
  };
};
const studentAssociateInsert = payload => {
  return {
    type: ActionTypes.STUDENT_ASSOCIATE_INSERT_REQUEST,
    payload: payload,
  };
};
const studentAssociateListGet = payload => {
  return {
    type: ActionTypes.STUDENT_ASSOCIATE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const studentAssociateDelete = payload => {
  return {
    type: ActionTypes.STUDENT_ASSOCIATE_DELETE_REQUEST,
    payload: payload,
  };
};

const centerOptionGet = payload => {
  return {
    type: ActionTypes.CENTER_OPTION_GET_REQUEST,
    payload: payload,
  };
};

const comboPractitionerCenterGet = payload => {
  return {
    type: ActionTypes.COMBO_PRACTITIONER_CENTER_GET_REQUEST,
    payload: payload,
  };
};

const prospectArchive = payload => {
  return {
    type: ActionTypes.PROSPECT_ARCHIVE_REQUEST,
    payload: payload,
  };
};

const resetProspectArchive = payload => {
  return {
    type: ActionTypes.PROSPECT_ARCHIVE_RESET,
    payload: payload,
  };
};

const tokensStudentTransactionHistory = payload => {
  return {
    type: ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_REQUEST,
    payload: payload,
  };
};

const tokensCounselorTransactionHistory = payload => {
  return {
    type: ActionTypes.TOKENS_COUNSELOR_TRANSACTION_HISTORY_REQUEST,
    payload: payload,
  };
};

const tokensStudentTransactionHistoryGetAll = payload => {
  return {
    type: ActionTypes.TOKENS_STUDENT_TRANSACTION_HISTORY_GET_ALL_REQUEST,
    payload: payload,
  };
};

const tokensCounselorTransactionHistoryGetAll = payload => {
  return {
    type: ActionTypes.TOKEN_MANAGEMENT_COUNSELOR_TRANSACTION_HISTORY_GET_ALL_REQUEST,
    payload: payload,
  };
};

const centerOverHeadCostGet = payload => {
  return {
    type: ActionTypes.CENTER_OVER_HEAD_COST_GET_REQUEST,
    payload: payload,
  };
};

const centerOverHeadCostUpsert = payload => {
  return {
    type: ActionTypes.CENTER_OVER_HEAD_COST_UPSERT_REQUEST,
    payload: payload,
  };
};

const centerLaberCostGet = payload => {
  return {
    type: ActionTypes.CENTER_LABER_COST_GET_REQUEST,
    payload: payload,
  };
};

const centerLaberCostGetUpsert = payload => {
  return {
    type: ActionTypes.CENTER_LABER_COST_GET_UPSERT_REQUEST,
    payload: payload,
  };
};

const centerConsumableGetAll = payload => {
  return {
    type: ActionTypes.CENTER_CONSUMABLE_GET_ALL_REQUEST,
    payload: payload,
  };
};

const centerConsumableGet = payload => {
  return {
    type: ActionTypes.CENTER_CONSUMABLE_GET_REQUEST,
    payload: payload,
  };
};

const resetCenterConsumableGet = payload => {
  return {
    type: ActionTypes.CENTER_CONSUMABLE_GET_RESET,
    payload: payload,
  };
};

const centerConsumableGetUpsert = payload => {
  return {
    type: ActionTypes.CENTER_CONSUMABLE_GET_UPSERT_REQUEST,
    payload: payload,
  };
};

const centerConsumableDelete = payload => {
  return {
    type: ActionTypes.CENTER_CONSUMABLE_DELETE_REQUEST,
    payload: payload,
  };
};

const dashboardPendingSurveyListGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_PENDING_SURVEY_LIST_GET_REQUEST,
    payload: payload,
  };
};

const clientembeddingprepare = payload => {
  return {
    type: ActionTypes.CLIENT_EMBEDDING_PREPARE_REQUEST,
    payload: payload,
  };
};

const askQuestion = payload => {
  return {
    type: ActionTypes.ASK_QUESTION_REQUEST,
    payload: payload,
  };
};

const resetAskQuestion = payload => {
  return {
    type: ActionTypes.ASK_QUESTION_RESET,
    payload: payload,
  };
};

const chatGet = payload => {
  return {
    type: ActionTypes.CHAT_GET_REQUEST,
    payload: payload,
  };
};

const resetChatGet = payload => {
  return {
    type: ActionTypes.CHAT_GET_RESET,
    payload: payload,
  };
};

const youuniverseConversationGet = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CONVERSATION_GET_REQUEST,
    payload: payload,
  };
};

const youuniverseChatGet = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CHAT_GET_REQUEST,
    payload: payload,
  };
};

const resetYouuniverseChatGet = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CHAT_GET_RESET,
    payload: payload,
  };
};
const youuniverseChatSet = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CHAT_GET_SET,
    payload: payload,
  };
};

const youuniverseChatPost = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CHAT_POST_REQUEST,
    payload: payload,
  };
};

const resetYouuniverseChatPost = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CHAT_POST_RESET,
    payload: payload,
  };
};

const labOrderPanelCodeComboGet = payload => {
  return {
    type: ActionTypes.LAB_ORDER_PANEL_CODE_COMBO_GET_REQUEST,
    payload: payload,
  };
};

const defaultPractotionerFacilityGet = payload => {
  return {
    type: ActionTypes.DEFAULT_PRACTOTIONER_FACILITY_GET_REQUEST,
    payload: payload,
  };
};

const licenseNotifyCenterAdmin = payload => {
  return {
    type: ActionTypes.LICENSE_NOTIFY_CENTER_ADMIN_REQUEST,
    payload: payload,
  };
};

const resetLicenseNotifyCenterAdmin = payload => {
  return {
    type: ActionTypes.LICENSE_NOTIFY_CENTER_ADMIN_RESET,
    payload: payload,
  };
};

const goalLibraryUpsert = payload => {
  return {
    type: ActionTypes.GOAL_LIBRARY_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetGoalLibraryUpsert = payload => {
  return {
    type: ActionTypes.GOAL_LIBRARY_UPSERT_RESET,
    payload: payload,
  };
};

const goalLibraryArchive = payload => {
  return {
    type: ActionTypes.GOAL_LIBRARY_ARCHIVE_REQUEST,
    payload: payload,
  };
};

const goalLibraryGetAll = payload => {
  return {
    type: ActionTypes.GOAL_LIBRARY_GET_ALL_REQUEST,
    payload: payload,
  };
};

const goalLibraryGet = payload => {
  return {
    type: ActionTypes.GOAL_LIBRARY_GET_REQUEST,
    payload: payload,
  };
};

const comboServiceCode = payload => {
  return {
    type: ActionTypes.COMBO_SERVICE_CODE_REQUEST,
    payload: payload,
  };
};

const centerTokenAssignUpdate = payload => {
  return {
    type: ActionTypes.CENTER_TOKEN_ASSIGN_UPDATE_REQUEST,
    payload: payload,
  };
};

const serviceCodeBundleUpsert = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_BUNDLE_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetServiceCodeBundleUpsert = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_BUNDLE_UPSERT_RESET,
    payload: payload,
  };
};

const serviceCodeBundleGetAll = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_BUNDLE_GET_ALL_REQUEST,
    payload: payload,
  };
};

const serviceCodeBundleGet = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_BUNDLE_GET_REQUEST,
    payload: payload,
  };
};

const resetServiceCodeBundleGet = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_BUNDLE_GET_RESET,
    payload: payload,
  };
};

const smartContractUpsert = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetSmartContractUpsert = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_UPSERT_RESET,
    payload: payload,
  };
};

const serviceCodeBundleDelete = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_BUNDLE_DELETE_REQUEST,
    payload: payload,
  };
};

const smartContractGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_GET_REQUEST,
    payload: payload,
  };
};

const smartContractRequestGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_REQUEST_GET_REQUEST,
    payload: payload,
  };
};

const smartContractRequestUpdate = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_REQUEST_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetSmartContractRequestUpdate = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_REQUEST_UPDATE_RESET,
    payload: payload,
  };
};

const resetSmartContractGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_GET_RESET,
    payload: payload,
  };
};

const clientInsuranceGet = payload => {
  return {
    type: ActionTypes.CLIENT_INSURANCE_GET_REQUEST,
    payload: payload,
  };
};

const payerPortalDashboard = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_DASHBOARD_REQUEST,
    payload: payload,
  };
};

const payerPortalAuthorizationGet = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_AUTHORIZATION_GET_REQUEST,
    payload: payload,
  };
};

const payerPortalAuthorizationStatusUpsert = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_AUTHORIZATION_STATUS_UPSERT_REQUEST,
    payload: payload,
  };
};

const payerPortalRequestInsert = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_REQUEST_INSERT_REQUEST,
    payload: payload,
  };
};

const resetPayerPortalRequestInsert = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_REQUEST_INSERT_RESET,
    payload: payload,
  };
};

const studentRecommendationsListGet = payload => {
  return {
    type: ActionTypes.STUDENT_RECOMMENDATIONS_LIST_GET_REQUEST,
    payload: payload,
  };
};

const resetStudentRecommendationsListGet = payload => {
  return {
    type: ActionTypes.STUDENT_RECOMMENDATIONS_LIST_GET_RESET,
    payload: payload,
  };
};

const youuniverseBookMarkQuestionUpsert = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetYouuniverseBookMarkQuestionUpsert = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_UPSERT_RESET,
    payload: payload,
  };
};

const youuniverseBookMarkQuestionListGet = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_BOOKMARK_QUESTION_LIST_GET_REQUEST,
    payload: payload,
  };
};

const youuniverseChatAttributeUpdate = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CHAT_ATTRIBUTE_UPDATE_REQUEST,
    payload: payload,
  };
};
const resetYouuniverseChatAttributeUpdate = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CHAT_ATTRIBUTE_UPDATE_RESET,
    payload: payload,
  };
};

const youuniverseConversationDelete = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CONVERSATION_DELETE_REQUEST,
    payload: payload,
  };
};
const resetYouuniverseConversationDelete = payload => {
  return {
    type: ActionTypes.YOUUNIVERSE_CONVERSATION_DELETE_RESET,
    payload: payload,
  };
};

const youuChatEditedAnswerUpsert = payload => {
  return {
    type: ActionTypes.YOUU_CHAT_EDITED_ANSWER_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetYouuChatEditedAnswerUpsert = payload => {
  return {
    type: ActionTypes.YOUU_CHAT_EDITED_ANSWER_UPSERT_RESET,
    payload: payload,
  };
};
const smartContractLedgerGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_LEDGER_GET_REQUEST,
    payload: payload,
  };
};
const smartContractUpdate = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_UPDATE_REQUEST,
    payload: payload,
  };
};

const vbcLedgerRemarkUpsert = payload => {
  return {
    type: ActionTypes.VBC_LEDGER_REMARK_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetVbcLedgerRemarkUpsert = payload => {
  return {
    type: ActionTypes.VBC_LEDGER_REMARK_UPSERT_RESET,
    payload: payload,
  };
};

const distributionPaymentUpsert = payload => {
  return {
    type: ActionTypes.DISTRIBUTION_PAYMENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetDistributionPaymentUpsert = payload => {
  return {
    type: ActionTypes.DISTRIBUTION_PAYMENT_UPSERT_RESET,
    payload: payload,
  };
};

const clientStatusReport = payload => {
  return {
    type: ActionTypes.CLIENT_STATUS_REPORT_REQUEST,
    payload: payload,
  };
};
const userTriggerReport = payload => {
  return {
    type: ActionTypes.USER_TRIGGER_REPORT_REQUEST,
    payload: payload,
  };
};
const surveyQuestionLibraryQuestionListGet = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_LIST_GET_REQUEST,
    payload: payload,
  };
};
const surveyQuestionLibraryQuestionDelete = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_DELETE_REQUEST,
    payload: payload,
  };
};
const surveyQuestionLibrarySectionUpdate = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_SECTION_UPDATE_REQUEST,
    payload: payload,
  };
};
const surveyQuestionLibraryQuestionUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_UPSERT_REQUEST,
    payload: payload,
  };
};
const studentNoteGetAll = payload => {
  return {
    type: ActionTypes.STUDENT_NOTE_GET_ALL_REQUEST,
    payload: payload,
  };
};

const askFollowUpInitiate = payload => {
  return {
    type: ActionTypes.ASK_FOLLOW_UP_INITIATE_REQUEST,
    payload: payload,
  };
};

const resetAskFollowUpInitiate = payload => {
  return {
    type: ActionTypes.ASK_FOLLOW_UP_INITIATE_RESET,
    payload: payload,
  };
};
const resetSurveyQuestionLibraryQuestionUpsert = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_UPSERT_RESET,
    payload: payload,
  };
};

const surveyQuestionLibraryQuestionGet = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_GET_REQUEST,
    payload: payload,
  };
};

const resetSurveyQuestionLibraryQuestionGet = payload => {
  return {
    type: ActionTypes.SURVEY_QUESTION_LIBRARY_QUESTION_GET_RESET,
    payload: payload,
  };
};

const signedNoteGet = payload => {
  return {
    type: ActionTypes.SIGNED_NOTE_GET_REQUEST,
    payload: payload,
  };
};

const resetSignedNoteGet = payload => {
  return {
    type: ActionTypes.SIGNED_NOTE_GET_RESET,
    payload: payload,
  };
};

const dashboardTaskListGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_TASK_LIST_GET_REQUEST,
    payload: payload,
  };
};

const dashboardCourseListGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_COURSE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const dashboardContractListGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_CONTRACT_LIST_GET_REQUEST,
    payload: payload,
  };
};

const dashboardAlertListGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_ALERT_LIST_GET_REQUEST,
    payload: payload,
  };
};

const centerLaborBurdenGet = payload => {
  return {
    type: ActionTypes.CENTER_LABOR_BURDEN_GET_REQUEST,
    payload: payload,
  };
};

const resetCenterLaborBurdenGet = payload => {
  return {
    type: ActionTypes.CENTER_LABOR_BURDEN_GET_RESET,
    payload: payload,
  };
};

const centerLaborBurdenUpsert = payload => {
  return {
    type: ActionTypes.CENTER_LABOR_BURDEN_UPSERT_REQUEST,
    payload: payload,
  };
};

const studentTimerUpsert = payload => {
  return {
    type: ActionTypes.STUDENT_TIMER_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetStudentTimerUpsert = payload => {
  return {
    type: ActionTypes.STUDENT_TIMER_UPSERT_RESET,
    payload: payload,
  };
};
const getAppointmentClient = payload => {
  return {
    type: ActionTypes.GET_APPOINTMENT_CLIENT_REQUEST,
    payload: payload,
  };
};

const appointmentNoteListGet = payload => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const resetAppointmentNoteListGet = payload => {
  return {
    type: ActionTypes.APPOINTMENT_NOTE_LIST_GET_RESET,
    payload: payload,
  };
};

const comboServiceCodeGet = payload => {
  return {
    type: ActionTypes.COMBO_SERVICE_CODE_GET_REQUEST,
    payload: payload,
  };
};

const noteTypeListGet = payload => {
  return {
    type: ActionTypes.NOTE_TYPE_LIST_GET_REQUEST,
    payload: payload,
  };
};

const noteTypeUpsert = payload => {
  return {
    type: ActionTypes.NOTE_TYPE_UPSERT_REQUEST,
    payload: payload,
  };
};

const studentMergeComboListGet = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_COMBO_LIST_GET_REQUEST,
    payload: payload,
  };
};

const studentMergeDetailsGet = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_DETAILS_GET_REQUEST,
    payload: payload,
  };
};

const studentMergeSecondaryDetailsGet = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_SECONDARY_DETAILS_GET_REQUEST,
    payload: payload,
  };
};

const resetStudentMergeComboListGet = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_COMBO_LIST_GET_RESET,
    payload: payload,
  };
};

const resetStudentMergeDetailsGet = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_DETAILS_GET_RESET,
    payload: payload,
  };
};

const resetStudentMergeSecondaryDetailsGet = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_SECONDARY_DETAILS_GET_RESET,
    payload: payload,
  };
};

const studentMerge = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_REQUEST,
    payload: payload,
  };
};

const resetStudentMerge = payload => {
  return {
    type: ActionTypes.STUDENT_MERGE_RESET,
    payload: payload,
  };
};

const studentTwilioTranscriptGet = payload => {
  return {
    type: ActionTypes.STUDENT_TWILIO_TRANSCRIPT_GET_REQUEST,
    payload: payload,
  };
};

const resetStudentTwilioTranscriptGet = payload => {
  return {
    type: ActionTypes.STUDENT_TWILIO_TRANSCRIPT_GET_RESET,
    payload: payload,
  };
};

const powerBiReportListGet = payload => {
  return {
    type: ActionTypes.POWER_BI_REPORT_LIST_GET_REQUEST,
    payload: payload,
  };
};

const appointmentPreSelectedGet = payload => {
  return {
    type: ActionTypes.APPOINTMENT_PRE_SELECTED_GET_REQUEST,
    payload: payload,
  };
};

const callNotesAugment = payload => {
  return {
    type: ActionTypes.CALL_NOTE_AUGMENTATION_REQUEST,
    payload: payload,
  };
};

const resetCallNotesAugment = payload => {
  return {
    type: ActionTypes.CALL_NOTE_AUGMENTATION_RESET,
    payload: payload,
  };
};

const callSummaryAugment = payload => {
  return {
    type: ActionTypes.CALL_SUMMARY_AUGMENT_REQUEST,
    payload: payload,
  };
};

const gptAugmentationUpdate = payload => {
  return {
    type: ActionTypes.GPT_AUGMENTATION_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetGptAugmentationUpdate = payload => {
  return {
    type: ActionTypes.GPT_AUGMENTATION_UPDATE_RESET,
    payload: payload,
  };
};

const centerFieldGet = payload => {
  return {
    type: ActionTypes.CENTER_FIELD_GET_REQUEST,
    payload: payload,
  };
};

const centerFieldUpsert = payload => {
  return {
    type: ActionTypes.CENTER_FIELD_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetCenterFieldUpsert = payload => {
  return {
    type: ActionTypes.CENTER_FIELD_UPSERT_RESET,
    payload: payload,
  };
};

const clientSendMobileAppInvite = payload => {
  return {
    type: ActionTypes.CLIENT_SEND_MOBILE_APP_INVITE_REQUEST,
    payload: payload,
  };
};

const smartContractPatientListGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_PATIENT_LIST_GET_REQUEST,
    payload: payload,
  };
};

const resetSmartContractPatientListGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_PATIENT_LIST_GET_RESET,
    payload: payload,
  };
};
const smartContractListGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_LIST_GET_REQUEST,
    payload: payload,
  };
};
const termsConditionListGet = payload => {
  return {
    type: ActionTypes.TERMS_CONDITION_LIST_GET_REQUEST,
    payload: payload,
  };
};
const termsConditionUpsert = payload => {
  return {
    type: ActionTypes.TERMS_CONDITION_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetTermsConditionUpsert = payload => {
  return {
    type: ActionTypes.TERMS_CONDITION_UPSERT_RESET,
    payload: payload,
  };
};

const termsConditionGetAllInfoJson = payload => {
  return {
    type: ActionTypes.TERMS_CONDITION_GET_ALL_INFO_JSON_REQUEST,
    payload: payload,
  };
};

const resetTermsConditionGetAllInfoJson = payload => {
  return {
    type: ActionTypes.TERMS_CONDITION_GET_ALL_INFO_JSON_RESET,
    payload: payload,
  };
};
const centerConfigGet = payload => {
  return {
    type: ActionTypes.CENTER_CONFIG_GET_REQUEST,
    payload: payload,
  };
};
const centerConfigGetById = payload => {
  return {
    type: ActionTypes.CENTER_CONFIG_GET_BY_ID_REQUEST,
    payload: payload,
  };
};
const payerPriceDetailsGet = payload => {
  return {
    type: ActionTypes.PAYER_PRICE_DETAILS_GET_REQUEST,
    payload: payload,
  };
};
const payerPortalRequestUpsert = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_REQUEST_UPSERT_REQUEST,
    payload: payload,
  };
};
const resetPayerPortalRequestUpsert = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_REQUEST_UPSERT_RESET,
    payload: payload,
  };
};
const clientInsuranceComboGet = payload => {
  return {
    type: ActionTypes.CLIENT_INSURANCE_COMBO_GET_REQUEST,
    payload: payload,
  };
};
const resetClientInsuranceComboGet = payload => {
  return {
    type: ActionTypes.CLIENT_INSURANCE_COMBO_GET_RESET,
    payload: payload,
  };
};
const smartContractTermsConditionGetAllInfoJson = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_TERMS_CONDITION_GETALLINFO_JSON_REQUEST,
    payload: payload,
  };
};

const termsConditionDelete = payload => {
  return {
    type: ActionTypes.TERMS_CONDITION_DELETE_REQUEST,
    payload: payload,
  };
};

const termsConditionComboGet = payload => {
  return {
    type: ActionTypes.TERMS_CONDITION_COMBO_GET_REQUEST,
    payload: payload,
  };
};

const comboLabOrderPractitioner = payload => {
  return {
    type: ActionTypes.COMBO_LAB_ORDER_PRACTITIONER_REQUEST,
    payload: payload,
  };
};

const resetSmartContractUpdate = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_UPDATE_RESET,
    payload: payload,
  };
};

const smartContractPayersListGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_PAYERS_LIST_GET_REQUEST,
    payload: payload,
  };
};

const resetSmartContractPayersListGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_PAYERS_LIST_GET_RESET,
    payload: payload,
  };
};

const payerPortalRequestGet = payload => {
  return {
    type: ActionTypes.PAYER_PORTAL_REQUEST_GET_REQUEST,
    payload: payload,
  };
};

const groupServiceLineApproveAllBillingOrBatching = payload => {
  return {
    type: ActionTypes.GROUP_SERVICE_LINE_APPROVE_ALL_BILLING_OR_BATCHING_REQUEST,
    payload: payload,
  };
};

const payerTermsConditionsStatusUpsert = payload => {
  return {
    type: ActionTypes.PAYER_TERMS_CONDITIONS_STATUS_UPSERT_REQUEST,
    payload: payload,
  };
};

const clientCareTeamListGet = payload => {
  return {
    type: ActionTypes.CLIENT_CARE_TEAM_LIST_GET_REQUEST,
    payload: payload,
  };
};

const dashboardMobileUserGetAll = payload => {
  return {
    type: ActionTypes.DASHBOARD_MOBILE_USER_GET_ALL_REQUEST,
    payload: payload,
  };
};

const entMobileUserDashboardGet = payload => {
  return {
    type: ActionTypes.ENT_MOBILE_USER_DASHBOARD_GET_REQUEST,
    payload: payload,
  };
};
const setStickeyNote = payload => {
  return {
    type: ActionTypes.STICKEY_NOTE_SET,
    payload: payload,
  };
};

const resetSetStickeyNote = payload => {
  return {
    type: ActionTypes.STICKEY_NOTE_RESET,
    payload: payload,
  };
};

const recoveryCapitalQuadrantGraphGet = payload => {
  return {
    type: ActionTypes.RECOVERY_CAPITAL_QUADRANT_GRAPH_GET_REQUEST,
    payload: payload,
  };
};

const resetRecoveryCapitalQuadrantGraphGet = payload => {
  return {
    type: ActionTypes.RECOVERY_CAPITAL_QUADRANT_GRAPH_GET_RESET,
    payload: payload,
  };
};

const clientMergeGet = payload => {
  return {
    type: ActionTypes.CLIENT_MERGE_GET_REQUEST,
    payload: payload,
  };
};

const clientMergeSecondaryGet = payload => {
  return {
    type: ActionTypes.CLIENT_MERGE_SECONDARY_GET_REQUEST,
    payload: payload,
  };
};

const clientComboGetAll = payload => {
  return {
    type: ActionTypes.CLIENT_COMBO_GET_ALL_REQUEST,
    payload: payload,
  };
};

const clientMerge = payload => {
  return {
    type: ActionTypes.CLIENT_MERGE_REQUEST,
    payload: payload,
  };
};

const resetClientMergeGet = payload => {
  return {
    type: ActionTypes.CLIENT_MERGE_GET_RESET,
    payload: payload,
  };
};

const resetClientMergeSecondaryGet = payload => {
  return {
    type: ActionTypes.CLIENT_MERGE_SECONDARY_GET_RESET,
    payload: payload,
  };
};

const resetClientMerge = payload => {
  return {
    type: ActionTypes.CLIENT_MERGE_RESET,
    payload: payload,
  };
};

const clinicalToIntakeMovePatient = payload => {
  return {
    type: ActionTypes.CLINICAL_TO_INTAKE_MOVE_PATIENT_REQUEST,
    payload: payload,
  };
};

const staffLicenseAgreementUpdate = payload => {
  return {
    type: ActionTypes.STAFF_LICENSE_AGREEMENT_UPDATE_REQUEST,
    payload: payload,
  };
};

const smartContractTermsConditionUpsert = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_TERMS_CONDITION_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetSmartContractTermsConditionUpsert = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_TERMS_CONDITION_UPSERT_RESET,
    payload: payload,
  };
};

const userTagTypeInsert = payload => {
  return {
    type: ActionTypes.USER_TAG_TYPE_INSERT_REQUEST,
    payload: payload,
  };
};
const resetUserTagTypeInsert = payload => {
  return {
    type: ActionTypes.USER_TAG_TYPE_INSERT_RESET,
    payload: payload,
  };
};
const userTagTypeListGet = payload => {
  return {
    type: ActionTypes.USER_TAG_TYPE_LIST_GET_REQUEST,
    payload: payload,
  };
};
const userTagTypeComboListGet = payload => {
  return {
    type: ActionTypes.USER_TAG_TYPE_COMBO_LIST_GET_REQUEST,
    payload: payload,
  };
};

const userTagTypeUpdate = payload => {
  return {
    type: ActionTypes.USER_TAG_TYPE_UPDATE_REQUEST,
    payload: payload,
  };
};
const resetUserTagTypeUpdate = payload => {
  return {
    type: ActionTypes.USER_TAG_TYPE_UPDATE_RESET,
    payload: payload,
  };
};
const listProspectAppointment = payload => {
  return {
    type: ActionTypes.LIST_PROSPECT_APPOINTMENT_REQUEST,
    payload: payload,
  };
};
const recoveryCapitalTrendGraphGet = payload => {
  return {
    type: ActionTypes.RECOVERY_CAPITAL_TREND_GRAPH_GET_REQUEST,
    payload: payload,
  };
};

const careTeamAssign = payload => {
  return {
    type: ActionTypes.CARE_TEAM_ASSIGN_REQUEST,
    payload: payload,
  };
};

const resetCareTeamAssign = payload => {
  return {
    type: ActionTypes.CARE_TEAM_ASSIGN_RESET,
    payload: payload,
  };
};

const serviceCodeCareTeamGet = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_CARE_TEAM_GET_REQUEST,
    payload: payload,
  };
};

const smartContractTabGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_TAB_GET_REQUEST,
    payload: payload,
  };
};

const smartContractDistributionGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_DISTRIBUTION_GET_REQUEST,
    payload: payload,
  };
};

const smartContractBundleSubmitToPayer = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_BUNDLE_SUBMIT_TO_PAYER_REQUEST,
    payload: payload,
  };
};

const smartContractPayerBundleGetToSubmit = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_PAYER_BUNDLE_GET_TO_SUBMIT_REQUEST,
    payload: payload,
  };
};

const serviceCodeSuggestionsGenerate = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_SUGGESTIONS_GENERATE_REQUEST,
    payload: payload,
  };
};

const resetServiceCodeSuggestionsGenerate = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_SUGGESTIONS_GENERATE_RESET,
    payload: payload,
  };
};

const serviceCodeSuggestionListGet = payload => {
  return {
    type: ActionTypes.SERVICE_CODE_SUGGESTION_LIST_GET_REQUEST,
    payload: payload,
  };
};

const peerRecoveryServiceCodeUpdate = payload => {
  return {
    type: ActionTypes.PEER_RECOVERY_SERVICE_CODE_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetPeerRecoveryServiceCodeUpdate = payload => {
  return {
    type: ActionTypes.PEER_RECOVERY_SERVICE_CODE_UPDATE_RESET,
    payload: payload,
  };
};

const broadCastUserListGet = payload => {
  return {
    type: ActionTypes.BROADCAST_USER_LIST_GET_REQUEST,
    payload: payload,
  };
};

const clientReportGet = payload => {
  return {
    type: ActionTypes.CLIENT_REPORT_GET_REQUEST,
    payload: payload,
  };
};

const clientRiskLevelGraphGet = payload => {
  return {
    type: ActionTypes.CLIENT_RISK_LEVEL_GRAPH_GET_REQUEST,
    payload: payload,
  };
};

const contractPeriodGraphGet = payload => {
  return {
    type: ActionTypes.CONTRACT_PERIOD_GRAPH_GET_REQUEST,
    payload: payload,
  };
};

const dashboardClaimsGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_CLAIMS_GET_REQUEST,
    payload: payload,
  };
};

const dashboardCardsGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_CARDS_GET_REQUEST,
    payload: payload,
  };
};

const riskProfileDataGraphGet = payload => {
  return {
    type: ActionTypes.RISK_PROFILE_DATA_GRAPH_GET_REQUEST,
    payload: payload,
  };
};

const appUserJournalAugmentGet = payload => {
  return {
    type: ActionTypes.APP_USER_JOURNAL_AUGMENT_GET_REQUEST,
    payload: payload,
  };
};

const appUserJournalAugmentation = payload => {
  return {
    type: ActionTypes.APP_USER_JOURNAL_AUGMENTATION_REQUEST,
    payload: payload,
  };
};

const resetAppUserJournalAugmentation = payload => {
  return {
    type: ActionTypes.APP_USER_JOURNAL_AUGMENTATION_RESET,
    payload: payload,
  };
};

const studentJournalAugmentGet = payload => {
  return {
    type: ActionTypes.STUDENT_JOURNAL_AUGMENT_GET_REQUEST,
    payload: payload,
  };
};

const studentJournalAugmentation = payload => {
  return {
    type: ActionTypes.STUDENT_JOURNAL_AUGMENTATION_REQUEST,
    payload: payload,
  };
};

const resetStudentJournalAugmentation = payload => {
  return {
    type: ActionTypes.STUDENT_JOURNAL_AUGMENTATION_RESET,
    payload: payload,
  };
};

const studentCourseAugmentationGet = payload => {
  return {
    type: ActionTypes.STUDENT_COURSE_AUGMENTATION_GET_REQUEST,
    payload: payload,
  };
};

const studentCourseAugmentation = payload => {
  return {
    type: ActionTypes.STUDENT_COURSE_AUGMENTATION_REQUEST,
    payload: payload,
  };
};

const resetStudentCourseAugmentationGet = payload => {
  return {
    type: ActionTypes.STUDENT_COURSE_AUGMENTATION_GET_RESET,
    payload: payload,
  };
};

const resetStudentCourseAugmentation = payload => {
  return {
    type: ActionTypes.STUDENT_COURSE_AUGMENTATION_RESET,
    payload: payload,
  };
};

const dashboardRadarGraphGet = payload => {
  return {
    type: ActionTypes.DASHBOARD_RADAR_GRAPH_GET_REQUEST,
    payload: payload,
  };
};

const smartContractDocumentRequestGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_DOCUMENT_REQUEST_GET_REQUEST,
    payload: payload,
  };
};

const resetSmartContractDocumentRequestGet = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_DOCUMENT_REQUEST_GET_RESET,
    payload: payload,
  };
};

const gptAugmentationGet = payload => {
  return {
    type: ActionTypes.GPT_AUGMENTATION_GET_REQUEST,
    payload: payload,
  };
};

const resetGptAugmentationGet = payload => {
  return {
    type: ActionTypes.GPT_AUGMENTATION_GET_RESET,
    payload: payload,
  };
};

const taskComboGet = payload => {
  return {
    type: ActionTypes.TASK_COMBO_GET_REQUEST,
    payload: payload,
  };
};

const recoveryCategoryComboGet = payload => {
  return {
    type: ActionTypes.RECOVERY_CATEGORY_COMBO_GET_REQUEST,
    payload: payload,
  };
};

const gptAugmentationWithAI = payload => {
  return {
    type: ActionTypes.GPT_AUGMENTATION_WITH_AI_REQUEST,
    payload: payload,
  };
};

const smartContractClone = payload => {
  return {
    type: ActionTypes.SMART_CONTRACT_CLONE_REQUEST,
    payload: payload,
  };
};

const externalStaffClientInsuranceGet = payload => {
  return {
    type: ActionTypes.EXTERNAL_STAFF_CLIENT_INSURANCE_GET_REQUEST,
    payload: payload,
  };
};

const externalStaffReferralUpsert = payload => {
  return {
    type: ActionTypes.EXTERNAL_STAFF_REFERRAL_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetExternalStaffReferralUpsert = payload => {
  return {
    type: ActionTypes.EXTERNAL_STAFF_REFERRAL_UPSERT_RESET,
    payload: payload,
  };
};
const clientReferralToCenter = payload => {
  return {
    type: ActionTypes.CLIENT_REFERRAL_TO_CENTER_REQUEST,
    payload: payload,
  };
};

const externalStaffReferralFormGet = payload => {
  return {
    type: ActionTypes.EXTERNAL_STAFF_REFERRAL_FORM_GET_REQUEST,
    payload: payload,
  };
};

const taskClientUpsert = payload => {
  return {
    type: ActionTypes.TASK_CLIENT_UPSERT_REQUEST,
    payload: payload,
  };
};

const taskClientListGet = payload => {
  return {
    type: ActionTypes.TASK_CLIENT_LIST_GET_REQUEST,
    payload: payload,
  };
};

const taskClientUpdate = payload => {
  return {
    type: ActionTypes.TASK_CLIENT_UPDATE_REQUEST,
    payload: payload,
  };
};

const taskClientReassign = payload => {
  return {
    type: ActionTypes.TASK_CLIENT_REASSIGN_REQUEST,
    payload: payload,
  };
};

const studentNoteHistoryGet = payload => {
  return {
    type: ActionTypes.STUDENT_NOTE_HISTORY_GET_REQUEST,
    payload: payload,
  };
};
const resetStudentNoteHistoryGet = payload => {
  return {
    type: ActionTypes.STUDENT_NOTE_HISTORY_GET_RESET,
    payload: payload,
  };
};

const appUserJournalHistoryGet = payload => {
  return {
    type: ActionTypes.APP_USER_JOURNAL_HISTORY_GET_REQUEST,
    payload: payload,
  };
};

const resetAppUserJournalHistoryGet = payload => {
  return {
    type: ActionTypes.APP_USER_JOURNAL_HISTORY_GET_RESET,
    payload: payload,
  };
};

const appointmentGetClientUpcomingSchedule = payload => {
  return {
    type: ActionTypes.APPOINTMENT_GET_CLIENT_UPCOMING_SCHEDULE_REQUEST,
    payload: payload,
  };
};

const resetAppointmentGetClientUpcomingSchedule = payload => {
  return {
    type: ActionTypes.APPOINTMENT_GET_CLIENT_UPCOMING_SCHEDULE_RESET,
    payload: payload,
  };
};

const listClientOutstandingBalanceGet = payload => {
  return {
    type: ActionTypes.LIST_CLIENT_OUTSTANDING_BALANCE_GET_REQUEST,
    payload: payload,
  };
};

const preIntakeGetAll = payload => {
  return {
    type: ActionTypes.PRE_INTAKE_GET_ALL_REQUEST,
    payload: payload,
  };
};

const clientSharedMemoUpsert = payload => {
  return {
    type: ActionTypes.CLIENT_SHARED_MEMO_UPSERT_REQUEST,
    payload: payload,
  };
};

const resetClientSharedMemoUpsert = payload => {
  return {
    type: ActionTypes.CLIENT_SHARED_MEMO_UPSERT_RESET,
    payload: payload,
  };
};

const clientSharedMemoGetAll = payload => {
  return {
    type: ActionTypes.CLIENT_SHARED_MEMO_GET_ALL_REQUEST,
    payload: payload,
  };
};

const resetClientSharedMemoGetAll = payload => {
  return {
    type: ActionTypes.CLIENT_SHARED_MEMO_GET_ALL_RESET,
    payload: payload,
  };
};

const clientSharedMemoUpdate = payload => {
  return {
    type: ActionTypes.CLIENT_SHARED_MEMO_UPDATE_REQUEST,
    payload: payload,
  };
};

const resetClientSharedMemoUpdate = payload => {
  return {
    type: ActionTypes.CLIENT_SHARED_MEMO_UPDATE_RESET,
    payload: payload,
  };
};
const feedbackFormAnalyticsGet = payload => {
  return {
    type: ActionTypes.FEEDBACK_FORM_ANALYTICS_GET_REQUEST,
    payload: payload,
  };
};
const feedbackFormGet = payload => {
  return {
    type: ActionTypes.FEEDBACK_FORM_GET_REQUEST,
    payload: payload,
  };
};
const feedbackFormDelete = payload => {
  return {
    type: ActionTypes.FEEDBACK_FORM_DELETE_REQUEST,
    payload: payload,
  };
};
const resetFeedbackFormDelete = payload => {
  return {
    type: ActionTypes.FEEDBACK_FORM_DELETE_RESET,
    payload: payload,
  };
};
const feedbackFormListGet = payload => {
  return {
    type: ActionTypes.FEEDBACK_FORM_LIST_GET_REQUEST,
    payload: payload,
  };
};

const appointmentAutoSelectGet = payload => {
  return {
    type: ActionTypes.APPOINTMENT_AUTO_SELECT_GET_REQUEST,
    payload: payload,
  };
};

export {
  getFormFieldMetaData,
  login,
  resetLogin,
  forgetPassword,
  resetPassword,
  resetAddStudentCourse,
  addStudentCourse,
  getDashboard,
  listStudents,
  resetAddRequest,
  addStudent,
  addStudentNote,
  resetStudentNote,
  getStudent,
  upsertStudentPin,
  resetValidatePin,
  resetUpsertPinRequest,
  validateStudentPin,
  resetGetStudent,
  getSurveys,
  getSurveyQuestionMultiple,
  surveyAnswerUpsert,
  resetSurveyAnswerUpsert,
  listCombos,
  listFilterCombo,
  listSmartCombos,
  listFullCombos,
  listTodos,
  listTodosByStudent,
  addTodo,
  listActivities,
  listActivitiesByStudent,
  listCoursesByStudent,
  studentAddExistingTask,
  studentDischarge,
  studentReinstate,
  resetStudentReinstate,
  studentUnAssign, // student profile
  studentMarkComplete, // student profile
  surveyGetToAssign, // for list of surveys
  surveyAssign, // assign survey
  studentTaskUpsert, // existing or new task
  studentTaskUnAssign,
  studentTaskMarkComplete,
  surveyTaskMarkInComplete,
  getReportData,
  adminstrationListCourse,
  adminstrationListStaff,
  adminstrationListCenter,
  getCenterFieldByCenter,
  resetGetCenterFieldByCenter,
  adminstrationGetCourse,
  adminstrationCourseUpsert,
  adminstrationGetStaff,
  adminstrationStaffUpsert,
  adminstrationStaffDelete,
  adminstrationGetCenter,
  adminstrationCenterUpsert,
  adminstrationCenterDelete,
  adminstrationCourseDelete,
  adminstrationResetCenter,
  adminstrationResetCourse,
  adminstrationResetStaff,
  resetAdminstrationCourseUpsert,
  resetAdminstrationStaffUpsert,
  resetAdminstrationCenterUpsert,
  resetAdminstrationCourseDelete,
  resetAdminstrationStaffDelete,
  resetAdminstrationCenterDelete,
  getStudentSurveyDashboardData,
  resetSurveyAnswersGet,
  logDevice,
  logError,
  announcementGetAll,
  announcementGetJson,
  announcementUpsert,
  announcementDelete,
  announcementGetJsonReset,
  resetAnnouncementUpsert,
  resetAnnouncementDelete,
  centerDelete,
  resetCenterDelete,
  staffDelete,
  resetStaffDelete,
  courseDelete,
  resetCourseDelete,
  updateStaffEmail,
  updateStaffPassword,
  updateStaffCenter,
  loadDefaultValues,
  loadMenu,
  logOut,
  surveyAnswersGet,
  getStudentProfileDashboardData,
  resetStudentAddExistingTask,
  resetStudentTaskUnAssign,
  resetStudentTaskMarkComplete,
  resetStudentMarkComplete,
  resetStudentUnAssign,
  resetSurveyAssign,
  resetStudentTaskUpsert,
  resetSurveyTaskMarkInComplete,
  resetStudentDischarge,
  getStudentsSearch,
  resetStaffEmail,
  resetStaffPassword,
  resetStaffCenter,
  getUserRole,
  resetStudentsSearch,
  getListJobTitle,
  updateJobTitle,
  updateRole,
  deleteRole,
  deleteJobTitle,
  updateRolePermissions,
  updateJobTitleSync,
  resetUpdateJobTitle,
  resetUpdateRole,
  resetDeleteRole,
  resetDeleteJobTitle,
  resetUpdateRolePermissions,
  resetUpdateJobTitleSync,
  getContract,
  upsertContract,
  resetUpsertContract,
  resetGetContract,
  deleteContract,
  resetDeleteContract,
  getContractTemplate,
  upsertContractTemplate,
  getStudentContractTemplate,
  resetGetContractTemplate,
  resetUpsertContractTemplate,
  uploadStaffImage,
  createContractFromTemplate,
  resetCreateContractFromTemplate,
  addFavoriteTemplate,
  resetAddFavoriteTemplate,
  removeFavoriteTemplate,
  resetRemoveFavoriteTemplate,
  closeContract,
  userFeedback,
  removeUserFeedback,
  isLicenseAvailable,
  getDashboardLoadAppUsers,
  getLoadUserInfo,
  getValidateImage,
  resetGetValidateImage,
  resetLoadUserInfo,
  getLicenseActivites,
  getCurrentLicenses,
  getLicensesInvoice,
  getLicensesReceipt,
  setCurrentLicenses,
  addLicenses,
  startFreeLicense,
  getPaymentInfo,
  updatePaymentMethode,
  resetPaymentMethode,
  resetAddLicenses,
  resetSetLicenses,
  isAchValidation,
  achValidate,
  getBillingInfo,
  addNewApplicant,
  getAppSubscribers,
  getAppSubscribersDashboard,
  getAppSubscribersProfile,
  getAppSubscribersChat,
  getAppSubscribersChatCoach,
  getAppSubscribersActivities,
  addAppSubscribersSurvey,
  addAppSubscribersNote,
  addAppSubscribersTask,
  resetAppSubscribersSurvey,
  deleteAppSubscribersTask,
  deleteAppSubscribersSurvey,
  appSubscribersUserSearch,
  resetAppSubscribersTask,
  resetAppSubscribersUnassignSurvey,
  AppSubscribersCompleteTask,
  resetDeleteAppSubscribersTask,
  resetAppSubscribersCompleteTask,
  resetAddAppSubscribersNote,
  addAppSubscribersCourse,
  deleteAppSubscribersCourse,
  appSubscribersCompleteCourse,
  resetAddAppSubscribersCourse,
  appSubscribersupdateCase,
  resetAppSubscribersupdateCase,
  resetDeleteAppSubscribersCourse,
  resetAppSubscribersCompleteCourse,
  listMobileAppRoleGet,
  spListCombos,
  appSubscribersMapHistory,
  appInvite,
  getMobileAppDownloadStatus,
  updateMobileAppRole,
  resetupdateMobileAppRole,
  addNoteType,
  resetAddNoteType,
  changeAppRole,
  resetChangeAppRole,
  twoStepAuth,
  validatePasscode,
  appInviteDashboard,
  getStaffShiftLog,
  addStaffShiftLog,
  resetStaffShiftLog,
  getAllCallSession,
  addCallSession,
  resetAddCallSession,
  getAllVacation,
  addVacationDays,
  resetAddVacationDays,
  getOneCallTimeSlots,
  getOneCallSession,
  inviteOneCallSession,
  resetinviteOneCallSession,
  cancelOneCallSession,
  resetCancelOneCallSession,
  getStripeApiKey,
  editGroupCallSession,
  getCallSessionVideo,
  getCallSessionVideoUrl,
  staffReactivate,
  resetStaffReactivate,
  getReports,
  getPromoCode,
  getSmallGroup,
  addSmallGroup,
  resetAddSmallGroup,
  getCounselor,
  getClientCounselor,
  getDynamicComos,
  sendVoucherCode,
  getSmallGroupList,
  uploadLeaderPhoto,
  markSystemObservation,
  appMarkSystemObservation,
  getAnalytics,
  getActiveClient,
  isManageLicenseRequired,
  getSmallGroupUsers,
  isStaffProBio,
  updateStaffProBio,
  resetStaffProBio,
  getNotes,
  getCategoryClient,
  getClientDemographic,
  getCostOfCare,
  getBehavioralRating,
  getScoreImprovement,
  getProviderPerformance,
  getTriggerLocationActivity,
  getServiceDelivered,
  getReferralSource,
  getStudentDocumentList,
  archiveStudentDocument,
  uploadDocument,
  downloadStudentDocument,
  resetDownloadStudentDocument,
  uploadDocumentReset,
  archiveDocumentReset,
  menuContractTemplate,
  archiveContractTemplate,
  resetArchiveContractTemplate,
  newsUpsert,
  getListNews,
  getNews,
  deleteNews,
  resetNewsUpsert,
  getEmbedInfo,
  getGenderPieChartEmbedInfo,
  resetGetGenderPieChartEmbedInfo,
  getRefernceSourceTimeInTreatmentReport,
  resetGetRefernceSourceTimeInTreatmentReport,
  getPatientList,
  getPatientDetail,
  clientChartListGet,
  clientChartReport,
  resetClientChartReport,
  upsertPatientEnrollment,
  resetUpsertPatientEnrollment,
  upsertClientInsurance,
  resetUpsertClientInsurance,
  upsertClientMedical,
  resetUpsertClientMedical,
  upsertClientPayment,
  resetUpsertClientPayment,
  upsertProviderEnrollment,
  resetUpsertProviderEnrollment,
  upsertAppointment,
  upsertAppointmentReset,
  listAppointment,
  getAppointment,
  resetGetAppointment,
  deleteAppointment,
  medicateCombos,
  listSurveyQuestionCategory,
  listSurveyQuestionCategoryAssessment,
  listSurveyQuestionAssessment,
  practitionerCombos,
  practitionerCombosOffice,
  upsertGroup,
  listGroup,
  getGroup,
  upsertClientGroup,
  getClientGroup,
  upsertGroupAppointment,
  upsertGroupAppointmentGroupAllInfoJson,
  deleteGroup,
  getListClientGroup,
  patientComboGroup,
  deactivateGroupAppointment,
  comboCenterConsent,
  upsertClientConsent,
  listClientConsent,
  deactivateClientConsent,
  deactivateClientScreening,
  loadJudicialDashboard,
  getJudicialUserList,
  UploadDocumentUsingMultiPart,
  resetUploadDocumentUsingMultiPart,
  upsertClientDocument,
  upsertClientDocumentReset,
  listClientDocument,
  downloadClientDocument,
  resetDownloadClientDocument,
  archiveClientDocument,
  listGroupMembership,
  getSurveyScore,
  getConsentFormTemplate,
  resetGetClientGroup,
  getComboFacility,
  resetGetComboFacility,
  loadAppSubSurveyDashboard,
  appSubSurveyAnsGet,
  resetAppSubSurveyAnsGet,
  centerProgramTypeGet,
  downloadClientConsent,
  comboPractitionerAll,
  comboFacilityAll,
  saveClientConsent,
  getTreatmentPlan,
  getDiagnosis,
  upsertTreatmentPlanProblem,
  resetGetMobileAppDownloadStatus,
  upsertMedicalAnalysis,
  resetUpsertMedicalAnalysis,
  upsertTreatmentPlanGoal,
  upsertTreatmentPlanObjective,
  upsertTreatmentPlanIntervention,
  deleteTreatmentPlan,
  getDiagnosisDescription,
  getDiagnosisCode,
  listTreatmentPlan,
  comboProblemTemplate,
  comboGoalTemplate,
  resetListTreatmentPlan,
  recurringCount,
  staffPermission,
  updateStaffPermission,
  loadCaseProfile,
  loadLocationAuthHistory,
  screenAuthorization,
  listConsentForm,
  upsertTreatmentPlanDimension,
  saveConsentSign,
  resetSaveConsentSign,
  consentFormDownload,
  listMeeting,
  upsertProgressGroupNote,
  resetUpsertProgressGroupNote,
  getProgressNoteGroup,
  getUserNotePatient,
  getUserNoteComment,
  addUserNoteComment,
  getUserNoteEmoji,
  addUserNoteEmoji,
  comboInterventionTemplate,
  getProgressNoteForIntervention,
  upsertPayer,
  resetUpsertPayer,
  listPayer,
  deletePayer,
  getPayer,
  comboPayer,
  upsertServiceCode,
  resetUpsertServiceCode,
  listServiceCode,
  deleteServiceCode,
  getServiceCode,
  resetGetServiceCode,
  comboCode,
  getCodeDescription,
  appointmentNoteList,
  resetAppointmentNoteList,
  upsertAppointmentNote,
  getAppointmentNote,
  comboPlaceOfService,
  listServiceLine,
  getServiceLine,
  upsertServiceLine,
  resetUpsertServiceLine,
  upsertAppointmentNoteAddendum,
  resetUpsertAppointmentNote,
  listGroupAppointmentNote,
  getGroupAppointmentNote,
  comboTreatmentPlanIntervention,
  listFeeSchedule,
  getFeeSchedule,
  upsertFeeSchedule,
  resetUpsertFeeSchedule,
  getTreatmentPlanGraph,
  listGroupServiceLine,
  getGroupServiceLine,
  getGroupBanner,
  upsertGroupServiceLine,
  resetUpsertGroupServiceLine,
  upsertGroupAppointmentNote,
  upsertGroupAppointmentNoteAddendum,
  listSurveyQuestionDischarge,
  upsertAppointmentNoteIntervention,
  markAsComplete,
  getServiceLineCost,
  upsertPin,
  validateStaffPin,
  resetValidateStaffPin,
  loadSmallGroupDashboard,
  upsertTreatmentPlan,
  listClientDiagnosis,
  getClientDiagnosis,
  deleteClientDiagnosis,
  validatePinExists,
  cloneDiagnosis,
  cloneTreatmentPlan,
  upsertBreathalyzerSchedule,
  listBreathalyzerSchedule,
  getBreathalyzerSchedule,
  rcmClientMerge,
  preValidatePin,
  validateClient,
  getAppAdvisor,
  getMediaVideoUrl,
  getDashboardMenu,
  breathalyzerPushATest,
  listServiceCodeBundle,
  getServiceCodeBundle,
  getStudentNote,
  getStudentNoteComment,
  addStudentNoteComment,
  studentNoteListGet,
  addStudentNoteEmoji,
  getStudentNoteEmoji,
  getTreatmentPlanAccordin,
  breathalyzerEnable,
  getBreathalyzerTestResult,
  getBreathalyzerTestUpcoming,
  breathalyzerTestUpcomingToggle,
  getAdvisorCost,
  upsertAdvisorCost,
  resetUpsertAdvisorCost,
  claimsPdfDownload,
  getClientService,
  getListTimeLine,
  getStudentBreathalyzerTestResult,
  getDashboardDailySchedule,
  getDashboardDailyCaseLoad,
  getDashboardAlert,
  getCenterAdvisorDefaultCosts,
  updateCenterAdvisorDefaultCost,
  resetUpdateCenterAdvisorDefaultCost,
  addClientInsuranceEligibility,
  editClientInsuranceEligibility,
  deleteClientInsuranceEligibility,
  getStaffPractitioner,
  dischargeSummarySave,
  getClientAppointment,
  resetGetClientAppointment,
  getGroupAppointment,
  unsignForm,
  getFilters,
  listLabOrder,
  getLabOrder,
  resetGetLabOrder,
  upsertLabOrder,
  labOrderLabelGet,
  comboDiagnosisList,
  getMedicationNote,
  upsertClientGroupAppointment,
  signGroupAppointmentNote,
  listServiceLinePayment,
  getServiceLinePayment,
  upsertServiceLinePayment,
  deleteServiceLinePayment,
  comboSupervisor,
  locationDayHistory,
  upsertPaymentClient,
  resetUpsertPaymentClient,
  getInsurancePriorityOrder,
  upsertInsurancePriorityOrder,
  listCWPayment,
  getCWPayment,
  deleteCWPayment,
  pastMeetingInfo,
  listPastMeetingInfo,
  comboAppointmentTreatmentPlan,
  linkAppointment,
  appointmentLinkGet,
  getBasicProfile,
  getInvitedList,
  inviteResend,
  suspiciousTermAck,
  getBroadcastFilter,
  sendBroadcast,
  getServiceLineDiagnosis,
  upsertServiceLineDiagnosis,
  comboBroadcastTemplate,
  upsertBroadcastTemplate,
  deleteNoteType,
  listClientAuthorization,
  getClientAuthorization,
  deleteClientAuthorization,
  upsertClientAuthorization,
  resertGetClientAuthorization,
  getNoteTypeArchive,
  getNoteTypeList,
  upsertCouponCode,
  resetUpsertCouponCode,
  deleteCouponCode,
  resetDeleteCouponCode,
  getCouponCode,
  centerReactivate,
  resetCenterReactivate,
  generateCallToken,
  badgeComboGet,
  badgeUpdate,
  panasScaleMoodsGet,
  panasTagUpsert,
  bedAssignmentGetAll,
  bedAssignmentUpsert,
  upcomingDischargesGetAll,
  upcomingAdmissions,
  admissionCancel,
  panasTagDelete,
  dynamicGridList,
  getGrid,
  getGridData,
  gridOnRowClickGet,
  resetGridOnRowClickGet,
  upsertFormData,
  formDataGet,
  resetFormDataGet,
  getPredefinedTask,
  getPredefinedTaskArchive,
  deletePredefinedTask,
  upsertPredefinedTask,
  resetPredefinedTask,
  listSurveyQuestionCategoryGetAll,
  getAdvisorMemberTrends,
  updateSmallGroupAttendence,
  updateUserProfile,
  getAdvisorMemberTrendsHistory,
  mobileInviteOptionsLoad,
  getAppUserSubscription,
  getServiceOptions,
  getServiceAdvisorList,
  setServiceAdvisor,
  updateCancelService,
  setMembership,
  cancelMembership,
  setServiceSubscription,
  upsertClientPin,
  resetClientPin,
  getStudentTimeLine,
  clientStudentSynchronize,
  updateUserMembership,
  getClientTimeLine,
  resetStudentTimeLine,
  resetClientTimeLine,
  resetListTimeLine,
  comboAppAdvisor,
  getSurveyCategoryQuestion,
  resetGetSurveyCategoryQuestion,
  upsertBlockAppointment,
  upsertClientMemo,
  resetUpsertClientMemo,
  getClientMemo,
  resetUpsertBlockAppointment,
  deleteClientMemo,
  resetDeleteClientMemo,
  validateSSN,
  userAppLinkValidate,
  resetUserAppLinkValidate,
  clientCenterMove,
  formTabListGet,
  resetFormTabListGet,
  upsertClientCareTeam,
  resetUpsertClientCareTeam,
  clientCareTeamGet,
  resetClientCareTeamGet,
  getClientCareTeamList,
  clientCareTeamDelete,
  resetClientCareTeamDelete,
  careTeamMemberCombo,
  resetCareTeamMemberCombo,
  externalProviderListGet,
  externalProviderGet,
  resetExternalProviderGet,
  externalProviderFilterGet,
  externalProviderAuthentication,
  externalStaffArchive,
  resetExternalStaffArchive,
  externalProviderUpsert,
  resetExternalProviderUpsert,
  organizationCenterFamilyCombo,
  resetGetPatientDetail,
  upsertClientVital,
  upsertMedicationLog,
  getMedicationLog,
  getClientVital,
  getMedicationLogList,
  getClientVitalList,
  restUpsertClientVital,
  resetUpsertMedicationLog,
  getCHPatientList,
  CHPatientServiceSend,
  pushClaimsToCH,
  pushSingleClaimToCH,
  resetPushSingleClaimToCH,
  getBillableClaims,
  cloneForm,
  clearSignature,
  resetClearSignature,
  upsertStaffViewedActivity,
  deleteStudentNote,
  staffAccessedTabGet,
  studentStaffAccessedTabGet,
  appAnnouncementUpsert,
  appAnnouncementListGetAll,
  appAnnouncementGet,
  tokenManagementListGetAll,
  tokenManagementStatsGet,
  resourceUpsert,
  resourceListGetAll,
  resourceGet,
  allergenSearch,
  resetAllergenSearch,
  drugSearch,
  resetDrugSearch,
  clientAllergyGet,
  allergyOptionsGet,
  clientAllergyUpsert,
  clientAllergyGetAll,
  clientAllergyDelete,
  clientMedicationUpsert,
  clientMedicationGetAll,
  clientMedicationDelete,
  drugPackageGetAll,
  resetDrugPackageGetAll,
  resetClientMedicationUpsert,
  resetClientMedicationDelete,
  resetClientAllergyUpsert,
  resetClientAllergyDelete,
  prescribeNotificationGet,
  prescribeUpdate,
  clientSignatureSave,
  resetClientSignatureSave,
  prescriptionListGet,
  userFeatureFlagGet,
  augmentedClientInsightsGet,
  riskChartGet,
  augmentedSummaryGet,
  ePrescribeSinglePatientModeSSO,
  resetEPrescribeSinglePatientModeSSO,
  clientReactivate,
  resetClientReactivate,
  clientBannerGet,
  labOrderCancel,
  drugDetailSearch,
  resetDrugDetailSearch,
  labResultGet,
  labResultDocumentGet,
  labOrderDelete,
  addSupportGroup,
  loadSupportGroup,
  loadPeer,
  loadUserEngagement,
  studentBanner,
  preIntakeSurveyAnswersGet,
  preIntakeHistoryGet,
  surveyLatestAnswerDateUpdate,
  appUserDemographicUpsert,
  appUserDemographicGet,
  removeSupportGroup,
  concernedPersonList,
  concernedPersonDasboardGraphGet,
  advisorSearch,
  resetPreIntakeHistoryGet,
  listClientGetAllWithPagination,
  appUserLiveLocationGet,
  studentSurveyListGet,
  GPT3EnrollSurveys,
  getMultipleSurveyAugmentedSummary,
  resetGetMultipleSurveyAugmentedSummary,
  resetAugmentedClientInsightsGet,
  GPT3AnswerStatusUpsert,
  resetGPT3AnswerStatusUpsert,
  augmentWithAI,
  resetAugmentWithAI,
  augmentWithAIGet,
  resetAugmentWithAIGet,
  gridFilterGet,
  resetGridFilterGet,
  getGroupDropdown,
  getProspectDashboardGraph,
  medicalProgramUpsert,
  resetMedicalProgramUpsert,
  medicalProgramGet,
  medicalProgramDelete,
  resetMedicalProgramDelete,
  regionUpsert,
  resetRegionUpsert,
  regionGet,
  regionDelete,
  resetRegionDelete,
  staffAccessedTabEntGet,
  surveyQuestionLibraryGet,
  surveyQuestionConfigGet,
  surveyQuestionSectionUpsert,
  resetSurveyQuestionSectionUpsert,
  surveyQuestionSectionComboGet,
  surveyQuestionConfigUpsert,
  surveyQuestionCategoryConfigUpsert,
  resetSurveyQuestionCategoryConfigUpsert,
  surveyQuestionConfigBannerGet,
  resetSurveyQuestionConfigUpsert,
  studentQueueListGetEnt,
  studentQueueDelete,
  studentAvailableSpacesListGet,
  studentQueueInsert,
  clientScreeningStatusUpdate,
  studentAssociateInsert,
  studentAssociateListGet,
  studentAssociateDelete,
  centerOptionGet,
  comboPractitionerCenterGet,
  prospectArchive,
  resetProspectArchive,
  tokensStudentTransactionHistory,
  tokensCounselorTransactionHistory,
  tokensStudentTransactionHistoryGetAll,
  tokensCounselorTransactionHistoryGetAll,
  centerOverHeadCostGet,
  centerOverHeadCostUpsert,
  centerLaberCostGet,
  centerLaberCostGetUpsert,
  centerConsumableGetAll,
  centerConsumableGet,
  resetCenterConsumableGet,
  centerConsumableGetUpsert,
  centerConsumableDelete,
  dashboardPendingSurveyListGet,
  clientembeddingprepare,
  askQuestion,
  resetAskQuestion,
  chatGet,
  resetChatGet,
  youuniverseConversationGet,
  youuniverseChatGet,
  resetYouuniverseChatGet,
  youuniverseChatSet,
  youuniverseChatPost,
  resetYouuniverseChatPost,
  labOrderPanelCodeComboGet,
  defaultPractotionerFacilityGet,
  licenseNotifyCenterAdmin,
  resetLicenseNotifyCenterAdmin,
  goalLibraryUpsert,
  resetGoalLibraryUpsert,
  goalLibraryArchive,
  goalLibraryGetAll,
  goalLibraryGet,
  comboServiceCode,
  centerTokenAssignUpdate,
  serviceCodeBundleUpsert,
  serviceCodeBundleDelete,
  resetServiceCodeBundleUpsert,
  serviceCodeBundleGetAll,
  serviceCodeBundleGet,
  resetServiceCodeBundleGet,
  smartContractUpsert,
  smartContractGet,
  smartContractRequestGet,
  smartContractRequestUpdate,
  resetSmartContractRequestUpdate,
  resetSmartContractGet,
  clientInsuranceGet,
  payerPortalDashboard,
  payerPortalAuthorizationGet,
  payerPortalAuthorizationStatusUpsert,
  payerPortalRequestInsert,
  resetPayerPortalRequestInsert,
  studentRecommendationsListGet,
  resetStudentRecommendationsListGet,
  youuniverseBookMarkQuestionUpsert,
  resetYouuniverseBookMarkQuestionUpsert,
  youuniverseBookMarkQuestionListGet,
  youuniverseChatAttributeUpdate,
  resetYouuniverseChatAttributeUpdate,
  youuniverseConversationDelete,
  resetYouuniverseConversationDelete,
  youuChatEditedAnswerUpsert,
  resetYouuChatEditedAnswerUpsert,
  smartContractLedgerGet,
  smartContractUpdate,
  vbcLedgerRemarkUpsert,
  distributionPaymentUpsert,
  resetDistributionPaymentUpsert,
  clientStatusReport,
  userTriggerReport,
  resetSmartContractUpsert,
  surveyQuestionLibraryQuestionListGet,
  surveyQuestionLibraryQuestionDelete,
  surveyQuestionLibrarySectionUpdate,
  surveyQuestionLibraryQuestionUpsert,
  studentNoteGetAll,
  askFollowUpInitiate,
  resetAskFollowUpInitiate,
  resetGetCodeDescription,
  resetSurveyQuestionLibraryQuestionUpsert,
  surveyQuestionLibraryQuestionGet,
  resetSurveyQuestionLibraryQuestionGet,
  signedNoteGet,
  resetSignedNoteGet,
  dashboardTaskListGet,
  dashboardCourseListGet,
  dashboardContractListGet,
  dashboardAlertListGet,
  centerLaborBurdenGet,
  resetCenterLaborBurdenGet,
  centerLaborBurdenUpsert,
  studentTimerUpsert,
  resetStudentTimerUpsert,
  getAppointmentClient,
  appointmentNoteListGet,
  resetAppointmentNoteListGet,
  comboServiceCodeGet,
  noteTypeListGet,
  noteTypeUpsert,
  studentMergeComboListGet,
  studentMergeDetailsGet,
  studentMergeSecondaryDetailsGet,
  studentMerge,
  resetStudentMergeComboListGet,
  resetStudentMergeDetailsGet,
  resetStudentMergeSecondaryDetailsGet,
  resetStudentMerge,
  studentTwilioTranscriptGet,
  resetStudentTwilioTranscriptGet,
  powerBiReportListGet,
  appointmentPreSelectedGet,
  callNotesAugment,
  resetCallNotesAugment,
  callSummaryAugment,
  gptAugmentationUpdate,
  resetGptAugmentationUpdate,
  centerFieldGet,
  centerFieldUpsert,
  resetCenterFieldUpsert,
  clientSendMobileAppInvite,
  smartContractPatientListGet,
  resetSmartContractPatientListGet,
  smartContractListGet,
  termsConditionListGet,
  termsConditionUpsert,
  resetTermsConditionUpsert,
  termsConditionGetAllInfoJson,
  resetTermsConditionGetAllInfoJson,
  centerConfigGet,
  centerConfigGetById,
  payerPriceDetailsGet,
  payerPortalRequestUpsert,
  resetPayerPortalRequestUpsert,
  clientInsuranceComboGet,
  resetClientInsuranceComboGet,
  smartContractTermsConditionGetAllInfoJson,
  termsConditionDelete,
  termsConditionComboGet,
  comboLabOrderPractitioner,
  resetSmartContractUpdate,
  smartContractPayersListGet,
  resetSmartContractPayersListGet,
  payerPortalRequestGet,
  groupServiceLineApproveAllBillingOrBatching,
  payerTermsConditionsStatusUpsert,
  clientCareTeamListGet,
  dashboardMobileUserGetAll,
  entMobileUserDashboardGet,
  setStickeyNote,
  resetSetStickeyNote,
  recoveryCapitalQuadrantGraphGet,
  resetRecoveryCapitalQuadrantGraphGet,
  clientMergeGet,
  clientMergeSecondaryGet,
  clientComboGetAll,
  clientMerge,
  resetClientMergeGet,
  resetClientMergeSecondaryGet,
  resetClientMerge,
  clinicalToIntakeMovePatient,
  staffLicenseAgreementUpdate,
  smartContractTermsConditionUpsert,
  resetSmartContractTermsConditionUpsert,
  userTagTypeInsert,
  userTagTypeListGet,
  userTagTypeComboListGet,
  userTagTypeUpdate,
  listProspectAppointment,
  recoveryCapitalTrendGraphGet,
  careTeamAssign,
  resetCareTeamAssign,
  serviceCodeCareTeamGet,
  smartContractTabGet,
  smartContractDistributionGet,
  smartContractBundleSubmitToPayer,
  smartContractPayerBundleGetToSubmit,
  serviceCodeSuggestionsGenerate,
  resetServiceCodeSuggestionsGenerate,
  serviceCodeSuggestionListGet,
  peerRecoveryServiceCodeUpdate,
  resetPeerRecoveryServiceCodeUpdate,
  broadCastUserListGet,
  resetUserTagTypeInsert,
  resetUserTagTypeUpdate,
  clientReportGet,
  clientRiskLevelGraphGet,
  contractPeriodGraphGet,
  dashboardClaimsGet,
  dashboardCardsGet,
  riskProfileDataGraphGet,
  appUserJournalAugmentGet,
  appUserJournalAugmentation,
  studentJournalAugmentGet,
  studentJournalAugmentation,
  resetStudentJournalAugmentation,
  resetAppUserJournalAugmentation,
  studentCourseAugmentationGet,
  studentCourseAugmentation,
  resetStudentCourseAugmentation,
  resetStudentCourseAugmentationGet,
  dashboardRadarGraphGet,
  smartContractDocumentRequestGet,
  resetSmartContractDocumentRequestGet,
  resetVbcLedgerRemarkUpsert,
  gptAugmentationGet,
  resetGptAugmentationGet,
  taskComboGet,
  recoveryCategoryComboGet,
  gptAugmentationWithAI,
  smartContractClone,
  externalStaffClientInsuranceGet,
  externalStaffReferralUpsert,
  resetExternalStaffReferralUpsert,
  clientReferralToCenter,
  externalStaffReferralFormGet,
  taskClientUpsert,
  taskClientListGet,
  taskClientUpdate,
  taskClientReassign,
  studentNoteHistoryGet,
  resetStudentNoteHistoryGet,
  appUserJournalHistoryGet,
  resetAppUserJournalHistoryGet,
  appointmentGetClientUpcomingSchedule,
  resetAppointmentGetClientUpcomingSchedule,
  listClientOutstandingBalanceGet,
  preIntakeGetAll,
  clientSharedMemoUpsert,
  clientSharedMemoGetAll,
  resetClientSharedMemoGetAll,
  clientSharedMemoUpdate,
  resetClientSharedMemoUpdate,
  resetClientSharedMemoUpsert,
  feedbackFormAnalyticsGet,
  feedbackFormGet,
  feedbackFormDelete,
  resetFeedbackFormDelete,
  feedbackFormListGet,
  appointmentAutoSelectGet,
  resetListSurveyQuestionAssessment,
};
