import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import MilestoneCollapse from './milestoneCollapse';

const { Panel } = Collapse;

export default function GoalCollapse(props) {
  const { goals, keyIndex } = props;
  const [defaultActivePanels, setDefaultActivePanels] = useState([]);

  useEffect(() => {
    if (goals && goals.length > 0) {
      let defaultOpenPanels =
        goals && goals.length > 0 && goals.map((item, index) => `${item.Description}-${index + 1}`);
      setDefaultActivePanels(defaultOpenPanels);
    }
  }, [goals]);
  return (
    <div>
      {defaultActivePanels && defaultActivePanels.length > 0 && (
        <Collapse defaultActiveKey={defaultActivePanels} expandIconPosition={'end'}>
          {/* <Panel header="This is panel header 1" key="1">
          <div>{'text'}</div>
        </Panel> */}
          {goals &&
            goals.length > 0 &&
            goals.map((item, index) => {
              const { Description, Milestone, CountInfo } = item;
              return (
                <Panel
                  // header={`Goal ${index + 1}`}
                  header={
                    <>
                      <div className="row">
                        <div className="col-12 panel-header-text">{CountInfo && CountInfo}</div>
                      </div>
                    </>
                  }
                  key={`${Description}-${index + 1}`}
                  className="goalSection-panel-border mb-3"
                >
                  <p className="mb-0 my-3" style={{ color: '#182838', fontSize: '16px' }}>
                    {Description}
                  </p>
                  <MilestoneCollapse milestone={Milestone} keyIndex={index} />
                </Panel>
              );
            })}
        </Collapse>
      )}
    </div>
  );
}
