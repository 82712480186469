import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { Table, Checkbox, Radio, Modal, Popover } from 'antd';
import Inquiries from '../components/Inquiries';
import BedsAvailableModal from '../components/BedsAvailableModal';
import DynamicGrid from '../../../Components/DynamicGrid';
import {
  getProspectDashboardGraph,
  studentQueueListGetEnt,
  studentQueueDelete,
} from '../../../store/actions';
import { isComparer, isDateComparer, isNumericComparer } from '../../../util/handler';
import { PopoverMenu } from '../../../Components/PopoverMenu';
import Loader, { Loader2 } from '../../../Components/Loader';
import AntdModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import PageTitle from '../../../Components/PageTitle';

class QueueList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      bedsAvailableModal: false,
      fetchLatestData: null,
    };
  }
  getLatestData = () => {
    this.props.dispatch(
      getProspectDashboardGraph({
        Json: JSON.stringify({
          Student: {
            IsQueue: true,
          },
        }),
      }),
    );
  };

  componentDidMount() {
    this.getLatestData();
  }

  componentWillReceiveProps({ isQueueRemoved }) {
    if (isQueueRemoved && isQueueRemoved != this.props.isQueueRemoved) {
      this.toggleSuccess('Client moved from Queue to Prospect Successfully.');
      if (this.state.fetchLatestData) {
        this.state.fetchLatestData();
      }
    }
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  toggleSuccess = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };

  moveToIntake = record => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: record.StudentId,
            EnrollStatusId: 0,
          },
        ],
      }),
    };
    Axios.post('Student/StudentEnrollStatusUpdate', data)
      .then(response => {
        if (response.data.success) {
          this.props.history.replace(`/enrollment/?studentid=${record.StudentId}`);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getPopOverData = record => {
    return (
      <>
        <p
          onClick={() => {
            this.props.history.push(
              `/clients/pre-intake/dashboard?tab=summary&id=${record.StudentId}`,
            );
          }}
        >
          View Details
        </p>
        {/* <p onClick={() => {}}>Move to Intake</p> */}
        <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#move-intake-model">
              Move to Intake
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">
                  Are you sure you want to move to Intake?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    onOk();
                    this.moveToIntake(record);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
        <AntdModal
          ModalButton={({ onClick }) => (
            <p style={{ color: '#FF3B3B' }} onClick={onClick} data-target="#move-intake-model">
              Remove from Queue
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">
                  Are you sure you want to remove this client from queue?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No, keep
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      studentQueueDelete({
                        Json: JSON.stringify({
                          Queue: {
                            StudentId: record.StudentId,
                          },
                        }),
                      }),
                    );
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          )}
        />
      </>
    );
  };
  getGridLatestData = getData => {
    this.setState({ fetchLatestData: getData });
  };
  render() {
    const { ClientsQueue, ClientsStatus, isFetching } = this.props;

    // const columns = [
    //   {
    //     title: 'Client Name',
    //     dataIndex: 'StudentName',
    //     key: 'StudentName',
    //     sorter: (a, b) => isComparer(a.StudentName, b.StudentName),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Phone number',
    //     dataIndex: 'Phone',
    //     key: 'Phone',
    //     render: (text, record) => <div>{text}</div>,
    //     sorter: (a, b) => isComparer(a.Phone, b.Phone),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Center Name',
    //     dataIndex: 'CenterName',
    //     key: 'CenterName',
    //     sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Program Referred',
    //     dataIndex: 'ProgramType',
    //     key: 'ProgramType',
    //     render: (text, record) => <div>{text}</div>,
    //     sorter: (a, b) => isComparer(a.ProgramType, b.ProgramType),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Priority',
    //     dataIndex: 'Priority',
    //     key: 'Priority',
    //     render: (text, record) => {
    //       return <div>{text ? <img src={`images/icons/${text}-risk-program.svg`} /> : null}</div>;
    //     },
    //     sorter: (a, b) => isComparer(a.Priority, b.Priority),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Referred on',
    //     dataIndex: 'RefferedOn',
    //     key: 'RefferedOn',
    //     render: (text, record) => <div>{text}</div>,
    //     sorter: (a, b) => isComparer(a.RefferedOn, b.RefferedOn),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Referred By',
    //     dataIndex: 'RefferedBy',
    //     key: 'RefferedBy',
    //     render: (text, record) => <div>{text}</div>,
    //     sorter: (a, b) => isComparer(a.RefferedBy, b.RefferedBy),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Status of Client',
    //     dataIndex: 'StatusOfClient',
    //     key: 'StatusOfClient',
    //     render: (text, record) => <div>{text}</div>,
    //     sorter: (a, b) => isComparer(a.StatusOfClient, b.StatusOfClient),
    //     sortDirections: ['descend', 'ascend'],
    //   },
    //   {
    //     title: 'Action',
    //     dataIndex: '',
    //     key: 'x',
    //     // render: (text, record) => (
    //     //   <Popover
    //     //     overlayClassName="alert-box-style"
    //     //     title={false}
    //     //     content={<div className="sort-by-list">{getPopOverData(record)}</div>}
    //     //     trigger="click"
    //     //     visible={this.state.visible}
    //     //     onVisibleChange={this.handleVisibleChange}
    //     //     class="alert-sort"
    //     //   >
    //     //     <img src="images/icons/3dot.svg" style={{ cursor: 'pointer' }} />
    //     //   </Popover>
    //     // ),
    //     render: (text, record) => {
    //       return <PopoverMenu OptionData={getPopOverData(record)} placement="left" />;
    //     },
    //   },
    // ];
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Queue" />
        <div class="content-area mt-3">
          <div class="row py-3">
            <div class="col-12">
              <Inquiries
                Inquiries={ClientsStatus}
                // to show bess available in Inquiries card.
                // ModalAction={() => {
                //   this.setState({
                //     bedsAvailableModal: true,
                //   });
                // }}
              />
            </div>
          </div>
          <div class="row top-table-search my-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h4 class="mb-3 mb-md-0">Queue Roster</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 " style={{ backgroundColor: 'unset', boxShadow: 'none' }}>
                <div className="dynamic-grid">
                  <DynamicGrid
                    ScreenName={'Queue'}
                    getPopOverData={this.getPopOverData}
                    getGridLatestData={this.getGridLatestData}
                  />
                </div>
                {/* <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="row-hover-enabled">
                      <Table
                        columns={columns}
                        dataSource={ClientsQueue}
                        size={'middle'}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div class="row">
            {this.state.bedsAvailableModal && (
              <Modal
                visible={this.state.bedsAvailableModal}
                footer={null}
                closable={false}
                width={702}
                destroyOnClose={true}
                style={{ height: '100vh' }}
              >
                <BedsAvailableModal
                  onCancel={() => {
                    this.setState({
                      bedsAvailableModal: false,
                    });
                  }}
                />
              </Modal>
            )}
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.setState({
              isModalOpen: false,
              header: '',
            });
            this.props.history.push(`/clients/queue`);
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToProps = ({
  user_feature_flag_get,
  get_prospect_dashboard_graph,
  student_queue_delete,
}) => {
  const { data: preIntakeStatus } = user_feature_flag_get;
  const { data: prospectDashboardGraph, isFetching } = get_prospect_dashboard_graph;
  let preIntakeStatusData = null;
  let ClientsStatus = null;
  let ClientsQueue = null;
  let isQueueRemoved = false;

  if (
    preIntakeStatus &&
    preIntakeStatus.FeatureFlag &&
    preIntakeStatus.FeatureFlag.length > 0 &&
    preIntakeStatus.FeatureFlag[0]
  ) {
    preIntakeStatusData =
      preIntakeStatus.FeatureFlag &&
      preIntakeStatus.FeatureFlag[0] &&
      preIntakeStatus.FeatureFlag[0].License &&
      preIntakeStatus.FeatureFlag[0].License.length > 0 &&
      preIntakeStatus.FeatureFlag[0].License.filter(item => item.ModuleName == 'PreIntake')[0];
  }
  if (prospectDashboardGraph && prospectDashboardGraph.Queue) {
    ClientsStatus = prospectDashboardGraph.Queue;
  }
  // if (studentQueueList && studentQueueList.ClientsStatus) {
  //   ClientsStatus = studentQueueList.ClientsStatus;
  // }
  // if (studentQueueList && studentQueueList.Queue) {
  //   ClientsQueue = studentQueueList.Queue;
  // }
  if (student_queue_delete.data && student_queue_delete.data.success) {
    isQueueRemoved = true;
  }
  return {
    isFetching,
    ClientsStatus,
    isQueueRemoved,
  };
};
export default connect(mapStateToProps)(withRouter(QueueList));
