import React, { Component, useEffect } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../../component/custom-redux-components';
import Validator from '../../../Validation';

const BinaryOptionForm = ({
  fields,
  name,
  meta: { touched, error },
  change,
  disable,
  existingRows,
  dispatch,
  answerOptions,
  binaryOption,
}) => {
  useEffect(() => {
    if (fields && fields.length <= 1) {
      return fields.push({
        [`Option${fields.length + 1}Text`]: fields.length == 0 ?  'Yes' : 'No'
      });
    }
  }, [fields]);
  return (
    <>
      <div class="answer-options-section">
        {fields.map((options, index) => {
          return (
            <div class="row mb-3 options-row d-flex align-items-center">
              <div className="col-2">
                <div className="d-flex">
                  <img src="images/icons/radio-option.svg" className="pr-2" />
                  <span className="label-text">{binaryOption[index]}</span>
                </div>
              </div>
              <div className="col-4">
                <div class="form-group mb-0">
                  <Field
                    className="mr-2"
                    name={`${options}.IsFlagged`}
                    component={ReduxCheckbox}
                    fieldName={'isFlagged'}
                  />
                  <label class="placeholder-label" style={{ top: '5px' }}>
                    Mark as Flagged
                  </label>
                </div>
              </div>

              {/* <div className="col-12">
                <div class="form-group">
                  <label class="custom-control-label">
                    <Field
                      name={`${options}.IsFollowUpQuestion`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                    />
                    {'   '}
                    Add Follow Up Question
                  </label>
                </div>
              </div> */}
            </div>
          );
        })}
      </div>
    </>
  );
};
class Binary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      binaryOption: {
        0: 'Yes',
        1: 'No',
      },
    };
  }
  render() {
    const { name } = this.props;

    return (
      <FieldArray name={name} component={BinaryOptionForm} binaryOption={this.state.binaryOption} />
    );
  }
}
export default Binary;
