import React from 'react';
import { Input } from 'reactstrap';

class CustomInput extends Input {
  constructor(props) {
    super(props);
  }
}

export default CustomInput;

const ReduxDollarInput = ({
  input,
  type,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  maxLength,
  mobileFormat,
  tabIndex,
  style,
  fieldName,
  min,
  max,
  controlledMaxAge,
  negativeNumberBlocked,
  showPlaceHolder,
  dollarPlaceHolder,
}) => {
  var { touched, error, warning, active } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value.toString();
    onChange = input.onChange;
    onBlur = input.onBlur;
  }

  /*if(mobileFormat){
        value = Util.formatPhoneNumber(value);
    }*/

  return (
    <>
      {!controlledMaxAge ? (
        !negativeNumberBlocked ? (
          <div class="input-group d-block">
            <div class="input-group-prepend">
              <span class="input-group-text" style={{ backgroundColor: 'white', border: 'unset' }}>
                $
              </span>
              <CustomInput
                {...input}
                style={style}
                tabIndex={tabIndex}
                type={type}
                name={name}
                id={id}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                // placeholder={active && placeholder}
                placeholder={showPlaceHolder ? placeholder : active && placeholder}
                className={`${className} ${value && 'not-empty'} ${type == 'textarea' &&
                  'textarea-height'}`}
                disabled={!!disabled}
                maxLength={maxLength}
                autocomplete="off"
                onWheel={event => {
                  window.document.activeElement.blur();
                }}
              />
              <label class="placeholder-label" style={{ left: '40px', zIndex: 1 }}>
                {dollarPlaceHolder}
              </label>
            </div>
          </div>
        ) : (
          <div class="input-group d-block">
            <div class="input-group-prepend">
              <span class="input-group-text" style={{ backgroundColor: 'white', border: 'unset' }}>
                $
              </span>
              <CustomInput
                {...input}
                style={style}
                tabIndex={tabIndex}
                type={type}
                name={name}
                id={id}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={active && placeholder}
                className={`${className} ${value && 'not-empty'}`}
                disabled={!!disabled}
                maxLength={maxLength}
                autocomplete="off"
                min={min}
                max={max}
                onWheel={event => {
                  window.document.activeElement.blur();
                }}
              />
            </div>
          </div>
        )
      ) : (
        <div class="input-group d-block">
          <div class="input-group-prepend">
            <span class="input-group-text" style={{ backgroundColor: 'white', border: 'unset' }}>
              $
            </span>
            <CustomInput
              {...input}
              style={style}
              tabIndex={tabIndex}
              type={type}
              name={name}
              id={id}
              defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={active && placeholder}
              className={`${className} ${value && 'not-empty'}`}
              disabled={!!disabled}
              maxLength={maxLength}
              autocomplete="off"
              min={min}
              max={max}
              onWheel={event => {
                window.document.activeElement.blur();
              }}
              //   onKeyPress={function(evt) {
              //     evt.preventDefault();
              //   }
              // }
            />
          </div>
        </div>
      )}

      {touched &&
        ((error && fieldName && (
          <span className="error-text">
            {fieldName} {error}
          </span>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </>
  );
};

export { ReduxDollarInput };
