import React, { useState } from 'react';
import { Drawer, Tooltip } from 'antd';
import CloseIcon from '../../../Components/CloseIcon';
import AugmentJournals from './AugmentWithAIForJournals';

export default function AugmentWithAIForJournals(props) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const onHandleDrawer = () => {
    setOpenDrawer(false);
  };
  return (
    <div className="d-flex justify-content-center">
      <button
        title="Augment with AI"
        class="ai-btn"
        onClick={() => setOpenDrawer(true)}
        style={{
          display: 'inline',
          padding: '5px 20px',
        }}
      >
        <figure class="action-button-icon" style={{ display: 'inline-block' }}>
          <img src="images/AIStar.svg" alt="" width="23" />
        </figure>
        Augment with AI
      </button>

      <Tooltip
        placement="top"
        title={'The summary augmentation will be generated using the most recent 20 records.'}
      >
        <img src="images/TooltipIcon.svg" className="ml-3" />
      </Tooltip>

      <Drawer
        className="custom-drawer journal-augmenting"
        title="Augment with AI"
        placement="right"
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        closeIcon={<CloseIcon />}
        width={750}
        destroyOnClose={true}
      >
        <AugmentJournals
          isEnterprise={props.isEnterprise}
          userGuid={props.UserGuid}
          studentId={props.StudentId}
          onHandleDrawer={onHandleDrawer}
        />
      </Drawer>
    </div>
  );
}
