import React, { Component } from 'react';
import { connect } from 'react-redux';

class VOIPCallModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { onCancel, BannerData } = this.props;
    debugger;
    return (
      <div class="modal-content">
        <div class="modal-header justify-content-center border-0 flex-wrap mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
        </div>
        <div class="modal-body px-4 record-call-modal mb-1">
          <div className="row">
            <div className="col-12 text-center">
              <span class="title-text">Are you sure you want to call?</span>
              <div className="py-5">
                <img
                  src={BannerData.StudentImageUrl || '/images/blank-profile.png'}
                  className="pro-picture"
                />
                <span className="name">{BannerData.PrefferedName}</span>
                <span className="phone">{BannerData.PrimaryPhone}</span>
              </div>
              <div className="call-action mt-2">
                <button type="button" className="btn">
                  Start Peer Recovery Session
                </button>
                <button type="button" className="btn">
                  Start Regular Call
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="modal-footer justify-content-end border-top-0 pb-2 pt-2">sdads</div> */}
      </div>
    );
  }
}

export default connect()(VOIPCallModal);
