import React from 'react';
import { numberFormat } from '../../../util/formatter';

const Inquiries = ({ Inquiries, ModalAction }) => {
  // status1  for Blue
  // status2  for green
  // status3  for warning
  // status4  for red
  // status5  for purple
  return (
    <div className="prospect-status">
      {Inquiries &&
        Inquiries.map((status, index) => {
          const { Status, Text, Value } = status;
          return (
            <div className={Status || 'status-1'} key={Text + index}>
              <div className="status">
                <span className="text">{Text}</span>
                <span className="value">{numberFormat.format(parseInt(Value || 0))}</span>
                {/* {ModalAction && (
                  <img
                    src="images/icons/open_external.svg"
                    className="mx-2"
                    onClick={ModalAction}
                  />
                )} */}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default Inquiries;
