import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse,Table, Checkbox, Radio, Modal, Button } from 'antd';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import TextareaAutosize from 'react-autosize-textarea';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusPercentageInput,
} from '../../component/custom-redux-components';
import validate from '../../core/generic-form/validation';
import { recoveryCategoryComboGet, taskComboGet } from '../../store/actions';
import Accordians from "./accordians";
import Goal from './accordians/goal';
const { Panel } = Collapse;
class AddGoalModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(recoveryCategoryComboGet({}));
     this.props.dispatch(taskComboGet({}));
  }

  render() {
    const {
      onCancel,
      addGoal,
      handleSubmit,
      onSaveClick,
      disable,
      goalTypeList,
      goalsData,
      isGoalFetching,
      recoveryCombo,
      taskCombo,
      goalRecoveryCapitalDropdown,
      fields,
      existingRows,
      initialValues,
    } = this.props;
    
    const { GoalLibraryId } = goalsData;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-start flex-wrap  border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 className="modal-title col-12">{GoalLibraryId ? 'Edit Goal' : 'Add Goal'}</h4>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSaveClick)}>
            {/* <Loader2 loading={isFetchingGetServiceCode} /> */}

            <div class="enrollment-form">
              <div class="row mb-4">
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="RecoveryCategoryId"
                      type="text"
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'Goal type'}
                      placeholder={'Recovery Categories*'}
                      options={recoveryCombo}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="RecoveryCapitalId"
                      type="text"
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'Recovery Capital'}
                      placeholder={'Recovery Capital*'}
                      options={goalRecoveryCapitalDropdown}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="GoalTypeId"
                      type="text"
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'Goal type'}
                      placeholder={'Goal Type*'}
                      options={goalTypeList}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name={`Description`}
                      type="textarea"
                      component={ReduxTextarea}
                      validate={validate.required}
                      fieldName={'Goal'}
                      disabled={disable}
                      style={{
                        background: '#f8f8f8',
                        paddingLeft: '5px',
                        height: '100px',
                        minHeight: '100px',
                      }}
                    />
                    <label class="placeholder-label">Goal*</label>
                  </div>
                </div>
                {/* <div class="col-12 col-md-6 col-lg-12">
                  <div class="form-group">
                    <Field
                      name="task"
                      type="text"
                      component={ReduxSelect}
                      validate={validate.required}
                      fieldName={'Task'}
                      placeholder={'Task*'}
                      options={taskCombo}
                      disabled={disable}
                    />
                  </div>
                </div> */}
                {this.props.currentGoalType == 1 ? (
                  <div class="col-12 col-md-12 col-lg-12">
                    <div className="">
                      <div
                        class="license-quantity d-flex  justify-content-left"
                        style={{ fontWeight: '600' }}
                      >
                        <label class="placeholder-label flex-grow-1" style={{ fontSize: '16px' }}>
                          % of Score to be Improved
                        </label>
                        <div class="">
                          <Field
                            name={`ThresholdValue`}
                            type="text"
                            errorMessage="State Required"
                            min={0}
                            max={1000}
                            component={ReduxPlusMinusPercentageInput}
                            disableOnPaste={true}
                            validate={validate.requiredZeroAtleast}
                            fieldName={'percentage score'}
                            className="number-icon"
                            style={{
                              textAlign: 'center',
                              margin: '0px 5px',
                              height: '32px',
                              width: '56px',
                              fontSize: '16px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div class="col-12 col-md-6 col-lg-12">
                  <FieldArray
                    name={`Goal`}
                    component={Goal}
                    existingRows={existingRows}
                    taskCombo={taskCombo}
                    // fields={fields}
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-12 col-md-12 col-lg-12 text-center">
                  <Button
                    loading={isGoalFetching}
                    htmlType="submit"
                    type="primary"
                    className="btn save-button px-5"
                    disabled={true ? false : true}
                  >
                    {GoalLibraryId ? ` UPDATE` : `ADD`}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer justify-content-end border-top-0"></div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  const { recovery_category_combo_get, task_combo_get } = state;
  const currentGoalType = formValueSelector('AddGoalForm')(state, 'GoalTypeId');
  const { data: recoveryCombo } = recovery_category_combo_get;
  const { data: taskCombo } = task_combo_get;

  return {
    currentGoalType,
    recoveryCombo: recoveryCombo ? recoveryCombo.RecoveryCategory : [],
    taskCombo: taskCombo ? taskCombo.Task : [],
    existingRows: formValueSelector('AddGoalForm')(state, 'Milestone'),
  };
};

const ReduxAddGoalModal = reduxForm({
  form: 'AddGoalForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(AddGoalModal)));

export default ReduxAddGoalModal;

