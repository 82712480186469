import React, { Component, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets';

const AssociationChart = props => {
  am4core.useTheme(am4themes_animated);
  useLayoutEffect(() => {
    // Create chart
    var chart = am4core.create('chartdiv', am4plugins_forceDirected.ForceDirectedTree);

    // Create series
    var series = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
    series.nodes.template.togglable = false;
    // chart.modal.container = document.body;
    // Set data
    series.data = props.seriesData;
    // series.data = [
    //   {
    //     ProfileImg:
    //       'https://drattach.blob.core.windows.net/dr-blob-container-v1/08236926-100e-4546-af32-68612c375002.jpeg',
    //     PrimaryPhone: '2055552821',
    //     Email: 'AdrianDTurner@dayrep.com',
    //     Address: null,
    //     FullName: 'kukuasd  kuku',
    //     name: 'kukuasd  kuku',
    //     image:
    //       'https://drattach.blob.core.windows.net/dr-blob-container-v1/08236926-100e-4546-af32-68612c375002.jpeg',
    //     children: [
    //       {
    //         ProfileImg: 'images/blank-profile.png',
    //         RelationShip: 'Aunt',
    //         PrimaryPhone: null,
    //         Email: null,
    //         Address: null,
    //         ContactId: 2007,
    //         FullName: 'sd sds sdsd',
    //         name: 'sd sds sdsd',
    //         value: 2007,
    //         image: 'images/blank-profile.png',
    //       },
    //       {
    //         ProfileImg:
    //           'https://spdevappmedia.blob.core.windows.net/dr-blob-container-v1/7eb00fe5-5ba1-4695-bb23-2a081b378620.jpg',
    //         RelationShip: 'Parent',
    //         PrimaryPhone: '2055552323',
    //         Email: 'gyg@gmail.com',
    //         Address: 'dghdgh',
    //         ContactId: 2029,
    //         FullName: 'Penny red',
    //         name: 'Penny red',
    //         value: 2029,

    //         type: 'Image',
    //         image:
    //           'https://spdevappmedia.blob.core.windows.net/dr-blob-container-v1/7eb00fe5-5ba1-4695-bb23-2a081b378620.jpg',
    //       },
    //     ],
    //   },
    // ];

    // Set up data fields
    series.dataFields.value = 'value';
    series.dataFields.name = 'name';
    series.dataFields.id = 'id';
    series.dataFields.children = 'children';
    series.dataFields.linkWith = 'link';
    series.colors.list = [
      am4core.color('#000'),
      am4core.color('#000'),
      am4core.color('#000'),
      am4core.color('#000'),
    ];

    // Add labels
    series.nodes.template.label.text =
      '[bold]{name}[/] \n[ #828282 font-size: 12px]{RelationShip}[/]';
    series.nodes.template.label.valign = 'bottom';
    series.nodes.template.label.fill = am4core.color('#000');
    series.nodes.template.label.dy = 25;
    series.nodes.template.tooltipText = '{name}';
    // series.nodes.template.tooltipText = '{name}: [bold]{value}[/]';
    series.nodes.template.label.hidden = false;
    series.fontSize = 15;
    series.minRadius = 30;
    series.maxRadius = 30;

    // Configure circles
    series.nodes.template.circle.disabled = true;
    series.nodes.template.outerCircle.disabled = true;

    // Configure icons
    var icon = series.nodes.template.createChild(am4plugins_bullets.PinBullet);
    icon.image = new am4core.Image();
    icon.image.propertyFields.href = 'image';

    icon.circle.radius = am4core.percent(100);
    icon.circle.strokeWidth = 0;
    icon.background.pointerLength = 0;
    icon.background.disabled = true;
    icon.image.width = 80;
    icon.image.height = 80;

    series.centerStrength = 0.2;

    series.nodes.template.events.on('up', function(event) {
      var node = event.target;
      // node.label.text = '{name}';
      // node.label.valign = 'bottom';
      // node.outerCircle.disabled = !node.outerCircle.disabled;
      // chart.openPopup('Hello there!', 'map title');
      chart.openModal(
        `<div class="association">
          <div class='detail'>
            <span class='mb-1'><img src='images/icons/phone_white.svg' class='mr-1'/> ${
              event.target.dataItem.dataContext.PrimaryPhone
                ? event.target.dataItem.dataContext.PrimaryPhone
                : ''
            }</span>
            <span class='mb-1'><img src='images/icons/mail_white.svg' class='mr-1'/> ${
              event.target.dataItem.dataContext.Email ? event.target.dataItem.dataContext.Email : ''
            }</span>
            <span class='pb-1'><img src='images/icons/location_white.svg' class='mr-1'/> ${
              event.target.dataItem.dataContext.Address
                ? event.target.dataItem.dataContext.Address
                : ''
            }</span>
          </div> 
        </div>`,
        `<div class="association">
          <div class='user-info'>
            <img src=${event.target.dataItem.dataContext.image} class='association-avatar'/>
            <div class='text-left'>
              <span class='name'>${
                event.target.dataItem.dataContext.name ? event.target.dataItem.dataContext.name : ''
              }</span>
              <span class='relation'>${
                event.target.dataItem.dataContext.RelationShip
                  ? event.target.dataItem.dataContext.RelationShip
                  : ''
              }</span>
            </div>
          </div>
        </div>`,
      );
      // popup.left = event.svgPoint.x + 15;
      // popup.top = event.svgPoint.y + 15;
    });

    series.links.template.interactionsEnabled = true;
    series.links.template.clickable = true;
    series.links.template.strokeWidth = 3;
    series.links.template.strokeOpacity = 0.3;
    series.links.template.distance = 2;
    series.links.template.stroke.fill = am4core.color('#000');
    series.links.template.events.on('hit', function(event) {
      var link = event.target;
    });

    return () => {
      chart.dispose();
    };
  }, [props.seriesData]);

  return (
    <div>
      <div id="chartdiv" style={{ width: '100%', height: '550px' }}></div>
    </div>
  );
};

export default connect()(withRouter(AssociationChart));
