import React from 'react';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import validate from '../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  CheckboxGroup,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  upsertServiceLine,
  getServiceCode,
  getServiceLine,
  medicateCombos,
  getServiceLineCost,
  claimsPdfDownload,
  listServiceLine,
  comboSupervisor,
} from '../../../store/actions';
import { ProcessCombos } from '../../../store/comboConfig';
import CustomModal from '../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import moment from 'moment';
import AntdModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { timeConvert } from '../../../util/time';
import { stringDate } from '../../../util/date';
import NotesDrawer from '../patientEnrollmentForm/formSteps/notesDrawer/notesDrawer';

import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../../../app-config';
import { Table, Modal, Button, Checkbox } from 'antd';
import { saveAs } from 'file-saver';
import { EditableRow, EditableCell } from '../accounting/formSteps/customScreens/EditableTable';
import PersistTableSort from '../../../component/persistTableSort';
import SubmitButton from '../../../Components/submitButton';
import { currencyFormatter, numberFormat } from '../../../util/formatter';

class ServiceLineModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineInfo: {},
      createAndEditGroupModal: false,
      ServiceDetails: [],
      DignosisOrder: [],
      isModelOpen: '',
      header: '',
      notesDrawerVisible: false,
    };
  }
  componentDidMount() {
    var result = ProcessCombos(
      'RCM_ServiceCode,Supervisor,UnitOfMeasurement,ServiceLineStatus,RCM_PlaceOfService',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(
      getServiceLine({
        json: JSON.stringify({
          ServiceLine: [
            {
              AppointmentId: this.props.AppointmentId,
              ServiceLineId: this.props.ServiceLineId,
              ClientId: this.props.ClientId,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(comboSupervisor({}));
  }
  showDrawer = () =>
    // appointmentId,
    // isGroup,
    // groupAppointmentId
    {
      this.setState({
        notesDrawerVisible: true,
        // appointmentId: appointmentId,
        // isGroup: isGroup,
        // groupAppointmentId: groupAppointmentId,
      });
    };

  onCloseDrawer = () => {
    this.setState({
      notesDrawerVisible: false,
    });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({
    isInserted,
    isRemoved,
    ServiceInfo,
    serviceLineCodeInfo,
    SupervisorIdValue,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditGroupModal: false });
      this.toggle('Service Line Saved Successfully');
      this.props.dispatch(reset('ClientserviceLineForm'));
      if (this.props.isFilterBy) {
        this.props.dispatch(
          listServiceLine({
            json: JSON.stringify({
              ServiceLine: [
                {
                  FilterBy: this.props.FilterBy,
                  IsPeerRecovery: this.props.IsPeerRecovery,
                },
              ],
            }),
          }),
        );
      } else if (this.props.filterData) {
        this.props.dispatch(
          listServiceLine({
            json: JSON.stringify({
              ServiceLine: [this.props.filterData],
            }),
          }),
        );
      }
      this.setState({
        ServiceLineInfo: {},
        ServiceInfoInit: {},
      });
    }

    if (SupervisorIdValue && SupervisorIdValue != this.props.SupervisorIdValue) {
      this.state.ServiceLineInfo.SupervisorId = SupervisorIdValue;
    }
    if (ServiceInfo && JSON.stringify(ServiceInfo) != JSON.stringify(this.props.ServiceInfo)) {
      this.setState({
        ServiceLineInfo: ServiceInfo,
        ServiceDetails: JSON.parse(ServiceInfo.ServiceDetails),
      });
    }
    if (
      serviceLineCodeInfo &&
      JSON.stringify(serviceLineCodeInfo) != JSON.stringify(this.props.serviceLineCodeInfo)
    ) {
      const {
        ServiceCodeId,
        ServiceCodeDescription,
        UnitsId,
        UnitOfMeasurementId,
        CostPerUnit,
        TotalCost,
        IsExcludeBundle,
        ReactIndex,
      } = serviceLineCodeInfo;

      let serviceLineCode = this.props.existingServiceCodesRows;
      if (serviceLineCode && serviceLineCode.length > ReactIndex) {
        serviceLineCode[ReactIndex].ServiceCodeId = ServiceCodeId;
        serviceLineCode[ReactIndex].ServiceCodeDescription = ServiceCodeDescription;
        serviceLineCode[ReactIndex].UnitOfMeasurementId = UnitOfMeasurementId;
        serviceLineCode[ReactIndex].CostPerUnit = CostPerUnit;
        serviceLineCode[ReactIndex].IsExcludeBundle = IsExcludeBundle;
        serviceLineCode[ReactIndex].UnitsId = UnitsId;
        serviceLineCode[ReactIndex].TotalCost = TotalCost;
      }
      this.setState({
        ServiceDetails: serviceLineCode,
      });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit = values => {
    const {
      DiagnosisCode,
      ServiceCodes,
      SupervisorId,
      IsApprovedForBilling,
      IsApprovedForBatching,
      BatchSentDate,
      StatusId,
      PlaceOfServiceId,
      Notes,
    } = values;

    // let patientId = null;
    // let paramClientId = this.props.clientId;
    // if (paramClientId && paramClientId > 0) {
    //   patientId = paramClientId;
    // }
    let ServiceCodeId = [];
    let unitsId = [];
    let CostPerUnit = [];
    let UnitOfMeasurementId = [];
    let TotalCost = [];
    let IsExcludeBundle = [];
    ServiceCodes &&
      ServiceCodes.map(item => {
        ServiceCodeId.push(item.ServiceCodeId);
        unitsId.push(item.UnitsId);
        CostPerUnit.push(item.CostPerUnit);
        UnitOfMeasurementId.push(item.UnitOfMeasurementId);
        TotalCost.push(item.TotalCost);
        IsExcludeBundle.push(item.IsExcludeBundle);
      });
    // DiagnosisCode.map(item => {
    //   DiagnosisListId.push(item.DiagnosisListId);
    // });
    let ServiceLineData = {
      json: JSON.stringify({
        ServiceLine: [
          {
            AppointmentId: this.state.ServiceLineInfo.AppointmentId,
            ServiceLineId: this.state.ServiceLineInfo.ServiceLineId,
            ClientId: this.state.ServiceLineInfo.ClientId,
            GroupAppointmentId: this.state.ServiceLineInfo.GroupAppointmentId,
            ServiceCodeId: ServiceCodeId.join(),
            UnitsId: unitsId.join(),
            TotalCost: TotalCost.join(),
            IsExcludeBundle: IsExcludeBundle.join(),
            CostPerUnit: CostPerUnit.join(),
            UnitOfMeasurementId: UnitOfMeasurementId.join(),
            DiagnosisListId: DiagnosisCode && DiagnosisCode.join(),
            SupervisorId: SupervisorId,
            IsApprovedForBilling: IsApprovedForBilling,
            IsApprovedForBatching: IsApprovedForBatching,
            BatchSentDate: BatchSentDate ? stringDate(BatchSentDate) : null,
            StatusId: StatusId ? StatusId : null,
            PlaceOfServiceId: PlaceOfServiceId ? PlaceOfServiceId : null,
            Notes: Notes ? Notes : null,
            ServiceLineDiagnosis: this.state.DignosisOrder,
          },
        ],
      }),
    };
    this.props.dispatch(upsertServiceLine(ServiceLineData));
  };
  getDignosisOrder = data => {
    this.state.DignosisOrder = data;
  };

  getTotalCost = (event, index) => {
    let rows = this.props.existingServiceCodesRows;
    let unitVal = parseInt(event.target.value);
    let costPerUnit = parseFloat(rows[index].CostPerUnit);
    debugger;
    rows[index].UnitsId = unitVal;
    rows[index].TotalCost = (unitVal * costPerUnit || 0).toFixed(2) || 0;

    this.setState({
      ServiceDetails: rows,
    });
  };
  deleteMoreCode = index => {
    let ServiceDetails = this.state.ServiceDetails;
    ServiceDetails.splice(index, 1);
    this.setState({
      ServiceDetails,
    });
  };
  downloadAsPdf = () => {
    const { dispatch } = this.props;
    const { ServiceLineInfo } = this.state;
    this.setState({ isFileDownloading: true });
    const me = this;
    var fileName;
    const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
    var hearders = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Accept: 'application/json, text/plain, */*',
    };

    fetch(`/api/v1/Claims/PDFDownload`, {
      method: 'POST',
      headers: hearders,
      body: JSON.stringify({
        Json: JSON.stringify({
          PDFForm: [
            {
              FormName: 'Claims1500',
              ClientId: ServiceLineInfo.ClientId,
              ServiceLineId: ServiceLineInfo.ServiceLineId,
            },
          ],
        }),
      }),
      // responseType: 'arraybuffer',
    })
      .then(response => {
        if (response.ok) {
          fileName = response.headers.get('FileName');
          return response.blob();
        }
      })
      .then(blob => {
        me.setState({ isFileDownloading: false });
        saveAs(blob, fileName || 'Claim.pdf');
      })
      .catch(error => {
        console.log(error);
        alert(JSON.stringify(error));
      });
  };

  render() {
    const { ServiceLineInfo, header, notesDrawerVisible, isModelOpen } = this.state;
    const {
      handleSubmit,
      onSaveClick,
      disable,
      onCancel,
      ServiceCodeList,
      dispatch,
      isFetchingServiceLine,
      Supervisor,
      deleteMoreCode,
      downloadAsPdf,
      isFileDownloading,
      // AppointmentId,
      ServiceLineStatus,
      ServiceLineRecord,
      hideInterventions,
    } = this.props;
    const {
      AppointmentId,
      GroupAppointmentId,
      DiagnosisInfo,
      EndTime,
      PlaceOfServiceId,
      ProgramDescription,
      ServiceCodeDescription,
      ServiceCodeId,
      SessionDate,
      SessionTime,
      SessionType,
      StartTime,
      SupervisorId,
      SupervisorName,
      PractitionerName,
      ClientName,
      ClientId,
      DisplayClientId,
      PlaceOfServiceName,
      FacilityName,
      StatusDescription,
      Diagnosis,
      DurationInMin,
      ServiceDetails,
      ServiceLineId,
      Note,
      AppointmentNoteId,
    } = ServiceLineInfo;
    const DiagnosisOption =
      (Diagnosis &&
        Diagnosis.map(item => {
          return {
            label: item.DiagnosisDictDesc,
            value: item.DiagnosisListId,
            description: item.FullDescription,
          };
        })) ||
      [];
    return (
      <React.Fragment>
        <div class="modal-body rcm-panel enrollment-form py-0">
          <Loader2 loading={isFetchingServiceLine} />
          <div className="row">
            <div className="col-4" style={{ background: '#F8F8F8' }}>
              <div className="service-line" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                <h4 class="title col-12 text-left">View/edit service</h4>
                <div className="block">
                  <span className="name">{ClientName}</span>
                  <p className="enrollment"> {DisplayClientId}</p>
                </div>
                <div className="service-info-block px-3">
                  <div className="d-flex align-items-center">
                    <img src="images/icons/grey_calendar.svg" width="24px" className="mr-2" />
                    {SessionDate}
                  </div>
                  <div className="d-flex align-items-center">
                    <img src="images/icons/grey_clock.svg" width="24px" className="mr-2" />
                    <span className="mr-2">{SessionTime} </span>{' '}
                    <span
                      style={{
                        border: ' 1px solid #D8D8D8',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        color: '#828282',
                        padding: '0px 5px',
                      }}
                    >
                      {DurationInMin && timeConvert(DurationInMin)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img src="images/icons/location_black.svg" width="24px" className="mr-2" />
                    {FacilityName}
                  </div>
                  <div className="d-flex align-items-center">
                    <img src="images/icons/user.svg" width="24px" className="mr-2" />
                    {SessionType}
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src="images/icons/grommet-icons_status-good.svg"
                      width="22px"
                      className="mr-2"
                    />
                    {StatusDescription}
                  </div>
                </div>

                <div className="info-block">
                  {/* <div className="block">
                      <span className="label">{'Notes'}</span>
                      <p className="value"> {Note}</p>
                    </div> */}
                  {/* <div className="block">
                    <span className="label">{'Place of Service'}</span>
                    <p className="value"> {PlaceOfServiceName}</p>
                  </div> */}
                  <div className="block">
                    <span className="label">{'Program'}</span>
                    <p className="value"> {ProgramDescription}</p>
                  </div>
                  <div className="block">
                    <span className="label">{'Staff'}</span>
                    <p className="value"> {PractitionerName}</p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    this.showDrawer();
                  }}
                  style={{
                    color: ' rgb(74, 176, 254)',
                    cursor: 'pointer',
                    background: 'transparent',
                    border: 'none',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                    padding: '5px',
                  }}
                >
                  Clinical Notes
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="" style={{ paddingTop: '25px', paddingBottom: '30px' }}>
                <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                  ×
                </button>
                <div class="row my-4">
                  <div class="col-12 ">
                    <div className="row">
                      <div class="col-6 d-flex align-items-center">
                        {' '}
                        <h4>Service Details</h4>
                      </div>
                      <div>
                        <Button
                          loading={isFileDownloading}
                          type="link"
                          onClick={() => {
                            this.downloadAsPdf();
                          }}
                        >
                          Download Claims 1500 Form
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <ReduxServiceLineForm
                      onSaveClick={this.onSubmit}
                      getTotalCost={this.getTotalCost}
                      getDignosisOrder={this.getDignosisOrder}
                      deleteMoreCode={this.deleteMoreCode}
                      ServiceLineInfo={ServiceLineInfo}
                      ServiceLineDiagnosis={ServiceLineInfo.ServiceLineDiagnosis}
                      ClientId={ClientId}
                      AppointmentNoteId={AppointmentNoteId}
                      DiagnosisOption={DiagnosisOption}
                      ServiceCodeList={this.props.ServiceCodeList}
                      Supervisor={this.props.Supervisor}
                      UnitOfMeasurement={this.props.UnitOfMeasurement}
                      ServiceLineStatus={this.props.ServiceLineStatus}
                      PlaceOfService={this.props.PlaceOfService}
                      ServiceDetails={this.state.ServiceDetails}
                      initialValues={{
                        ServiceCodes: this.state.ServiceDetails,
                        DiagnosisCode:
                          ServiceLineInfo.DiagnosisListId &&
                          ServiceLineInfo.DiagnosisListId.split(',') &&
                          ServiceLineInfo.DiagnosisListId.split(',').map(Number),
                        SupervisorId: ServiceLineInfo.SupervisorId,
                        IsApprovedForBilling: ServiceLineInfo.IsApprovedForBilling,
                        IsApprovedForBatching: ServiceLineInfo.IsApprovedForBatching,
                        IsPartofBundledService: ServiceLineInfo.IsPartofBundledService,
                        BatchSentDate:
                          ServiceLineInfo.BatchSentDate &&
                          moment(ServiceLineInfo.BatchSentDate).format('YYYY-MM-DD'),
                        StatusId: ServiceLineInfo.StatusId,
                        PlaceOfServiceId: ServiceLineInfo.PlaceOfServiceId,
                        Notes: ServiceLineInfo.Notes,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {notesDrawerVisible && (
          <NotesDrawer
            OnCloseDrawer={this.onCloseDrawer}
            NotesDrawerVisible={notesDrawerVisible}
            // AppointmentId={this.props.AppointmentId}
            AppointmentId={this.props.AppointmentId || AppointmentId}
            IsGroup={GroupAppointmentId ? true : false}
            ClientId={ClientId}
            GroupAppointmentId={GroupAppointmentId}
            toggle={this.toggle}
            hideInterventions={hideInterventions}
          />
        )}
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToPropsParent = state => {
  const {
    upsert_service_line,
    get_service_line,
    combos,
    get_service_line_cost,
    combo_supervisor,
  } = state;
  const { data: serviceLineCodeInfoData } = get_service_line_cost;
  const { data: comboSupervisorData } = combo_supervisor;
  const { data: serviceInfoData, isFetching: isFetchingServiceLine } = get_service_line;

  let ServiceCodeList = [];
  let Supervisor = [];
  let UnitOfMeasurement = [];
  let ServiceLineStatus = [];
  let PlaceOfService = [];

  if (combos.data && combos.data['RCM_ServiceCode']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'];
    // Supervisor = combos.data['Supervisor'];
    UnitOfMeasurement = combos.data['UnitOfMeasurement'] || [];
    ServiceLineStatus = combos.data['ServiceLineStatus'] || [];
    PlaceOfService = combos.data['RCM_PlaceOfService'] || [];
  }

  let ServiceInfo = null;
  let serviceLineCodeInfo = null;
  let isInserted = false;
  let isRemoved = false;

  if (serviceInfoData) {
    ServiceInfo = serviceInfoData || {};
  }

  if (comboSupervisorData && comboSupervisorData.data) {
    Supervisor = comboSupervisorData.data || [];
  }
  if (serviceLineCodeInfoData && serviceLineCodeInfoData.ServiceLine) {
    serviceLineCodeInfo = serviceLineCodeInfoData.ServiceLine[0] || [];
  }
  if (upsert_service_line.data && upsert_service_line.data.success) {
    isInserted = true;
  }
  const existingServiceCodesRows = selector(state, 'ServiceCodes');
  const SupervisorIdValue = selector(state, 'SupervisorId');

  return {
    ServiceCodeList: ServiceCodeList,
    serviceLineCodeInfo: serviceLineCodeInfo,

    ServiceInfo: ServiceInfo,
    isInserted,
    // isFetching,
    isFetchingServiceLine,
    Supervisor,
    existingServiceCodesRows,
    UnitOfMeasurement,
    SupervisorIdValue,
    ServiceLineStatus,
    PlaceOfService,
  };
};
export default connect(mapStateToPropsParent)(ServiceLineModal);
const selector = formValueSelector('ClientserviceLineForm');

class ServiceLineForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      columns: [
        {
          dataIndex: 'IsChecked',
          key: 'IsChecked',
          render: (text, record, index) => {
            return (
              <div class="actions-button d-flex">
                <div className="actions-button">
                  <Checkbox
                    onChange={e => {
                      this.onChange(record, index, e);
                    }}
                    name={record.DiagnosisListId}
                    checked={text}
                  />
                </div>
              </div>
            );
          },
        },
        {
          title: <span>Bill Order</span>,
          dataIndex: 'BillOrder',
          key: '4',
          // align: 'right',
          editable: true,
          onCell: (record, index) => ({
            record,
            editable: true,
            isString: true,
            required: false,
            dataIndex: 'BillOrder',
            title: '',
            style: { width: '50px' },
            minWidth: '25px',
            // type: 'number',
            handleSave: this.handleSave,
          }),
        },
        {
          title: <span>Ranking</span>,
          dataIndex: 'Ranking',
          key: 'Ranking',
          // align: 'right',
          sorter: (a, b) => isComparer(a.Ranking, b.Ranking),
        },
        {
          title: 'Diagnosis',
          dataIndex: 'ICD10Code',
          key: '1',
          sorter: (a, b) => isComparer(a.ICD10Code, b.ICD10Code),
          // render: (text: string) => <a>{text}</a>,
        },
        {
          title: <span>Category</span>,
          dataIndex: 'DiagnosisCategoryDescription',
          key: '2',
          // align: 'right',
          // sorter: (a, b) =>
          //   isComparer(a.DiagnosisCategoryDescription, b.DiagnosisCategoryDescription),
          render: (text, record) => {
            return <span className={record.IsBold ? 'font-weight-bold' : ''}>{text}</span>;
          },
        },
        {
          title: <span>Full Description</span>,
          dataIndex: 'DiagnosisDescription',
          key: '3',
          // align: 'right',
          sorter: (a, b) => isComparer(a.DiagnosisDescription, b.DiagnosisDescription),
        },
      ],
      dataSource: [],
    };
  }
  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.DiagnosisListId === item.DiagnosisListId);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    let editedSource = this.state.editedSource;
    let newSource = {
      ...editedSource,
      [row.DiagnosisListId]: row,
    };
    this.setState({
      dataSource: newData,
      editedSource: newSource,
    });
    this.props.getDignosisOrder(newData);
  };
  onChange = (record, index, e) => {
    let result = this.state.dataSource;
    result[index].IsChecked = !record.IsChecked;
    this.setState({
      dataSource: result,
    });
    this.props.getDignosisOrder(result);
  };
  componentWillReceiveProps({ ServiceLineDiagnosis }) {
    if (
      ServiceLineDiagnosis &&
      JSON.stringify(ServiceLineDiagnosis) != JSON.stringify(this.props.ServiceLineDiagnosis)
    ) {
      this.setState({
        dataSource: ServiceLineDiagnosis,
      });
    }
  }

  render() {
    const {
      handleSubmit,
      onSaveClick,
      disable,
      ServiceCodeList,
      ServiceLineInfo,
      Supervisor,
      deleteMoreCode,
      ServiceLineStatus,
      PlaceOfService,
      DiagnosisOption,
      ClientId,
      AppointmentNoteId,
      getDignosisOrder,
    } = this.props;
    const {
      columns,
      dataSource,
      costPerValue,
      costMonthlyValue,
      costPerColorCls,
      costPerNotificationText,
      monthlyColorCls,
      monthlyNotificationText,
      selectedRowKeys,
      isDataReadOnly,
    } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <div class="row my-4">
            <div
              class="col-12"
              style={{
                height: '450px',
                overflowY: 'scroll',
              }}
            >
              <FieldArray
                name="ServiceCodes"
                disable={disable}
                component={AddMoreCode}
                deleteMoreCode={deleteMoreCode}
                ServiceCodeList={ServiceCodeList}
                ClientId={ClientId}
                AppointmentNoteId={AppointmentNoteId}
                ServiceCodes={this.props.ServiceDetails}
                getTotalCost={this.props.getTotalCost}
                UnitOfMeasurement={this.props.UnitOfMeasurement}
                // existingRows={existingServiceCodesRows}
                {...this.props}
              />
            </div>
            <div class="col-9">
              <div class="form-group">
                <Field
                  name="PlaceOfServiceId"
                  type="text"
                  placeholder="Place of Service"
                  component={ReduxSelect}
                  options={PlaceOfService}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <Field
                  name="Notes"
                  class="form-control textarea-box"
                  rows="2"
                  type="textarea"
                  // placeholder="POS Notes"
                  component={ReduxTextarea}
                  style={{
                    minHeight: '80px',
                  }}
                />
                <label class="placeholder-label">Notes</label>
              </div>
            </div>
            <div class="col-12 my-3">
              <h6>Diagnosis</h6>
              <div
                class="col-12"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '350px',
                }}
              >
                {/* <div
                  class="form-group"
                  style={{
                    flexFlow: 'wrap',
                    display: 'flex',
                  }}
                >
                  <Field
                    name="DiagnosisCode"
                    component={CheckboxGroup}
                    col={24}
                    widthClassName={'w-75'}
                    options={DiagnosisOption}
                    fullDescription={true}
                  />
                </div> */}
                <div>
                  <PersistTableSort
                    name="service-line-modal"
                    components={components}
                    rowClassName={() => 'editable-row'}
                    columns={columns}
                    // dataSource={ServiceLineInfo.Diagnosis || []}
                    dataSource={dataSource || []}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <Field
                  name="SupervisorId"
                  type="text"
                  placeholder="Supervisor"
                  component={ReduxSelect}
                  options={Supervisor}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <Field
                  name="StatusId"
                  type="text"
                  placeholder="Service Line Status"
                  component={ReduxSelect}
                  options={ServiceLineStatus}
                  disabled={disable}
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <Field
                  name={`IsApprovedForBilling`}
                  type="text"
                  component={ReduxCheckbox}
                  label={'Approved For Billing '}
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <Field
                  name={`IsApprovedForBatching`}
                  type="text"
                  component={ReduxCheckbox}
                  label={'Approved For Batching '}
                />
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <Field
                  name={`BatchSentDate`}
                  type="text"
                  component={ReduxDateInput}
                  label={'Batch Sent Date '}
                />
                <i class="far fa-calendar-alt form-control-icon" />
              </div>
            </div>
            <div class="col-8">
              <div class="form-group">
                <Field
                  name={`IsPartofBundledService`}
                  type="text"
                  component={ReduxCheckbox}
                  disabled={true}
                  label={'This service is part of bundled service'}
                />
              </div>
            </div>
            <div class="col-4 mt-2 text-right">
              {/* <input
                type="submit"
                class="btn px-5"
                value={'Update'}
                // onClick={() => {
                //   this.props.getDignosisOrder(this.state.dataSource);
                // }}
              /> */}
              <SubmitButton
                value={'Update'}
                style={{ height: '43px', padding: '10px 45px' }}
                loading={this.props.isFetching}
              />
            </div>
          </div>
        </form>
      </>
    );
  }
}

const ReduxServiceLineForm = reduxForm({
  form: 'ClientserviceLineForm',
  enableReinitialize: true,
})(
  connect(state => {
    const { upsert_service_line } = state;
    // const existingRows = selector(state, 'fundSources');

    const { isFetching } = upsert_service_line;
    return {
      isFetching,
    };
  })(withRouter(ServiceLineForm)),
);

const AddMoreCode = ({
  fields,
  meta: { touched, error },
  disable,
  existingRows,
  ServiceCodeList,
  ServiceCodes,
  UnitOfMeasurement,
  getTotalCost,
  ClientId,
  AppointmentNoteId,
  deleteMoreCode,
  dispatch,
}) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <>
            <div
              class="row"
              style={{
                background: '#F8F8F8',
                margin: '5px',
                padding: '10px 0px',
                alignItems: 'center',
              }}
            >
              <div class="col-5">
                <div class="form-group service_line_code">
                  <Field
                    name={`${field}.ServiceCodeId`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Code`}
                    options={ServiceCodeList}
                    fieldName={'Code'}
                    disabled={disable}
                    onChange={value => {
                      dispatch(
                        getServiceLineCost({
                          json: JSON.stringify({
                            ServiceLine: [
                              {
                                AppointmentNoteId: AppointmentNoteId,
                                ClientId: ClientId,
                                ServiceCodeId: value,
                                ReactIndex: index,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div class="col-7">
                <div className="service-code p-2">
                  <p className="code-description-lable">Description</p>
                  <p className="code-description-val">
                    {ServiceCodes &&
                      ServiceCodes[index] &&
                      ServiceCodes[index].ServiceCodeDescription}
                  </p>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <Field
                    name={`${field}.UnitsId`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Unit'}
                    min={1}
                    max={999}
                    // negativeNumberBlocked={true}
                    controlledMaxAge={true}
                    style={{ background: '#F8F8F8' }}
                    onBlur={event => {
                      getTotalCost(event, index);
                    }}
                  />
                  <label class="placeholder-label">Units</label>
                </div>
              </div>
              <div class="col-3 pr-0">
                <div class="form-group service_line_code">
                  <Field
                    name={`${field}.UnitOfMeasurementId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Unit of Measure`}
                    options={UnitOfMeasurement}
                    fieldName={'Unit of Measure'}
                    disabled={true}
                  />
                </div>
              </div>
              <div class="col-2 px-0">
                <div class="form-group">
                  <Field
                    name={`${field}.CostPerUnit`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Cost per Unit'}
                    min={0}
                    max={999}
                    negativeNumberBlocked={true}
                    controlledMaxAge={true}
                    style={{ background: '#F8F8F8' }}
                    disabled={true}
                  />
                  <label class="placeholder-label">Cost per Unit</label>
                </div>
              </div>

              <div class="col-2 px-0">
                <div class="form-group">
                  <Field
                    name={`${field}.TotalCost`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Cost'}
                    style={{ background: '#F8F8F8' }}
                    disabled={true}
                  />
                  <label class="placeholder-label">Total Cost </label>
                </div>
              </div>
              <div class="col-9">
                <div class="form-group">
                  <Field
                    name={`${field}.IsExcludeBundle`}
                    type="text"
                    component={ReduxCheckbox}
                    label={'Exclude this Service Line from Bundled Service'}
                  />
                </div>
              </div>
              <div class="col-3">
                {fields.length > 1 && (
                  <a
                    class="delete-contact"
                    onClick={() => {
                      fields.remove(index);
                      deleteMoreCode(index);
                    }}
                    disabled={disable}
                  >
                    <i class="fas fa-trash" />
                  </a>
                )}
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-1 pb-1">
          {/* <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}> */}
          <a
            class="add-contact"
            onClick={() => fields.push({})}
            disabled={disable}
            style={{ marginLeft: 'unset' }}
          >
            <label style={{ cursor: 'pointer' }}>
              + Add {fields.length == 0 ? 'Code' : 'Another Code'}
            </label>
          </a>
        </div>
      </div>
    </>
  );
};
