import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Tooltip , Popover} from 'antd';
import renderHTML from 'react-render-html';
import {
  getPatientDetail,
  ePrescribeSinglePatientModeSSO,
  resetEPrescribeSinglePatientModeSSO,
  clientBannerGet,
  clientSendMobileAppInvite,
} from '../../store/actions';
import Axios from 'axios';
import { ToolTip } from '../../util/toolTip';
import UploadProfile from '../../Components/uploadProfilePicture';
import Twilio from '../../Components/twilio';
import AntdModal from '../../Components/CustomModal';

const AlertToolTip = ({ ClientId, TooltipMessage, Message, IsSendInvite, props }) => {
  return (
    <AntdModal
      ModalButton={({ onClick }) => (
        <Tooltip placement="top" title={TooltipMessage}>
          <img
            src="images/icons/profile_alert.svg"
            style={{ cursor: 'pointer' }}
            onClick={onClick}
          />
        </Tooltip>
      )}
      ModalContent={({ onOk, onCancel }) => (
        <div class="modal-content border-0">
          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
          <div class="modal-body pb-0 pr-2 pl-2  pr-xl-5 pl-xl-5">
            <span
              class="modal-title user-merging d-flex align-items-center col-12 mb-3"
              style={{ fontSize: '22px' }}
            >
              {renderHTML(Message)}
              <br />
            </span>
          </div>
          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
            {IsSendInvite && (
              <button
                class="btn btn-outline-primary black-btn"
                onClick={() => {
                  props.dispatch(
                    clientSendMobileAppInvite({
                      json: JSON.stringify({
                        Client: [
                          {
                            ClientId: ClientId,
                          },
                        ],
                      }),
                    }),
                  );
                  onCancel();
                }}
              >
                Send Invite
              </button>
            )}
            <button
              class="btn"
              onClick={() => {
                onOk();
              }}
            >
              {IsSendInvite ? 'Cancel' : 'OK'}
            </button>
          </div>
        </div>
      )}
    />
  );
};
class ClientProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertModelOpen: false,
    };
  }

  getClientId() {
    const param = new URLSearchParams(this.props.history.location.search);
    let clientId = param.get('pId') || param.get('patientid');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentDidMount() {
    this.props.dispatch(
      clientBannerGet({
        json: JSON.stringify({
          Client: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ EPrescribeURL }) {
    if (EPrescribeURL) {
      window.open(EPrescribeURL, '_blank');
      this.props.dispatch(resetEPrescribeSinglePatientModeSSO());
    }
  }
  setRef = webcam => {
    this.webcam = webcam;
  };
  capture = imageSrc => {
    // const imageSrc = this.webcam.getScreenshot();
    const me = this;
    Axios.post('student/UploadImage', {
      image: imageSrc,
      studentId: this.getClientId(),
      IsProfilePic: true,
    })
      .then(response => {
        me.props.dispatch(
          clientBannerGet({
            json: JSON.stringify({
              Client: [
                {
                  ClientId: this.getClientId(),
                },
              ],
            }),
          }),
        );
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };

  resetIsOpen = () => {
    this.setState({
      // twilioToken: '',
      callModelVisible: false,
      clickedRecord: {},
      // device: '',
    });
  };
  render() {
    const { isTimeline, isFetching, BannerData } = this.props;

    const {
      ClientId,
      DisplayClientId,
      // FullName,
      ClientFullName,
      FirstName,
      MiddleName,
      LastName,
      Age,
      ServiceType,
      AdmissionDate,
      PractitionerName,
      ProfileImgAttachmentUrl,
      PrimaryPhone,
      IsVoiceCallAllowed,
      // ProfileImgAttachmentUrl,
      //   Phone,
      Banner,
      StudentId,
      DateOfBirth,
      Gender,
      AllergiesText,
      ProgramLevel,
      PrimaryInsurance,
      DischargeDate,
      IsEPrescribeClient,
      ShortClientId,
      ClientDiagnosisDesc,
      Message,
      TooltipMessage,
      IsSendInvite,
      EmergencyContact
    } = BannerData || {};
    return (
      <div class="border-0 enrollment-page">
        <div
          className="row top-heading-row my-3 align-items-center mr-0 p-2"
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: 'rgba(0, 0, 0, 0.03)',
          }}
        >
          <div class="col-12 col-md-4 col-lg-4 pr-0">
            <h5 class="font-weight-semibold mb-3 mb-md-0 students-profile">
              <div className="d-flex align-items-center">
                <UploadProfile
                  imgSrc={ProfileImgAttachmentUrl}
                  captureProfile={this.capture}
                  isReadOnlyUser={this.props.isReadOnlyUser}
                />
                <div className="patient-info">
                  <span className="d-block name">
                    {FirstName} {LastName}&nbsp;
                    {TooltipMessage && (
                      <AlertToolTip
                        ClientId={ClientId}
                        TooltipMessage={TooltipMessage}
                        Message={Message}
                        IsSendInvite={IsSendInvite}
                        props={this.props}
                      />
                    )}
                  </span>
                  <span className="d-block id">{DisplayClientId}</span>
                  <div class="tools-grid mt-2 client-header">
                    {!isTimeline && (
                      <div className="d-flex flex-wrap">
                        {!this.props.isReadOnlyUser && (
                          <button
                            class="btn btn-outline-primary mt-md-2"
                            style={{
                              display: 'inline-block',
                            }}
                            onClick={() => {
                              const isWebCam =
                                localStorage.getItem('isWebCam') === '0' ? false : true;
                              if (isWebCam) {
                                this.props.history.push({
                                  pathname: '/camera',
                                  search: 'clientId=' + ClientId,
                                  param: {
                                    message:
                                      'Changing PIN requires new profile picture to be taken.',
                                  },
                                });
                              } else {
                                this.props.history.push({
                                  pathname: '/clientPin',
                                  search: 'clientId=' + ClientId,
                                });
                              }
                            }}
                          >
                            <figure
                              class="action-button-icon"
                              style={{ display: 'inline-block', marginRight: '2px' }}
                            >
                              <img
                                src="images/change-pin@3x.png"
                                alt=""
                                width="23"
                                // style={{ verticalAlign: 'baseline' }}
                              />
                              Pin
                            </figure>
                          </button>
                        )}

                        {IsVoiceCallAllowed > 0 && !this.props.isReadOnlyUser && (
                          <input
                            type="button"
                            class="btn mr-2 mt-md-2"
                            value="Call"
                            onClick={event => {
                              this.setState({
                                callModelVisible: true,
                                clickedRecord: {
                                  Phone: PrimaryPhone,
                                  Name: FirstName,
                                  ProfileImageUrl: ProfileImgAttachmentUrl,
                                },
                              });
                              event.stopPropagation();
                            }}
                          />
                        )}

                        {!this.props.isReadOnlyUser && (
                          <button
                            onClick={() => {
                              this.props.history.push(
                                `/cw/patient-management/patient-timetravel?patientid=${this.getClientId()}`,
                              );
                            }}
                            class="btn btn-outline-primary mt-md-2"
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            <figure
                              class="action-button-icon"
                              style={{
                                display: 'inline-block',
                                marginRight: '2px',
                                padding: '0px 10px',
                              }}
                            >
                              <img src="images/timetravel.svg" alt="" width="23" className="mr-1" />
                              Time Travel
                            </figure>
                          </button>
                        )}
                        {EmergencyContact && (
                          <Popover
                            content={<div>{EmergencyContact}</div>}
                            title="Emergency Contact"
                            style={{ width: '100px' }}
                          >
                            <span
                              style={{
                                color: 'red',
                                fontSize: '12px',
                                marginTop: '4%',
                                cursor: 'pointer',
                              }}
                            >
                              Emergency Contact
                            </span>
                          </Popover>
                        )}
                      </div>
                    )}
                  </div>
                  {this.state.callModelVisible && (
                    <Twilio
                      isOpen={this.state.callModelVisible}
                      userData={this.state.clickedRecord}
                      resetIsOpen={this.resetIsOpen}
                      isCallRecord={true}
                    />
                  )}
                </div>
              </div>
            </h5>
          </div>
          <div class={IsEPrescribeClient ? 'col-12 col-md-8 col-lg-7' : 'col-12 col-md-8 col-lg-8'}>
            <div class="row students-detail  text-md-left  justify-content-start">
              <div class="mb-2 mr-4">
                <span class="info-label">Provider Name</span>
                {PractitionerName && (
                  <h6
                    title={PractitionerName}
                    class="info-detail m-md-0"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '180px',
                    }}
                  >
                    {PractitionerName}
                  </h6>
                )}
              </div>
              <div class="mb-2 mr-4">
                <span class="info-label">Admission Date</span>
                {AdmissionDate && (
                  <h6
                    title={AdmissionDate}
                    class="info-detail m-md-0"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      // width: '150px',
                    }}
                  >
                    {AdmissionDate}
                  </h6>
                )}
              </div>
              <div class="mb-2 mr-4">
                <span class="info-label">Discharge Date</span>
                {DischargeDate && (
                  <h6
                    title={DischargeDate}
                    class="info-detail m-md-0"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      // width: '150px',
                    }}
                  >
                    {DischargeDate}
                  </h6>
                )}
              </div>
              <div class="mb-2 mr-4">
                <span class="info-label">Service Type</span>
                {ServiceType && <h6 class="info-detail m-md-0">{ToolTip(ServiceType)}</h6>}
              </div>
              <div class="mb-2 mr-4">
                <span class="info-label">Diagnosis</span>
                {ClientDiagnosisDesc && (
                  <h6 class="info-detail m-md-0">{ToolTip(ClientDiagnosisDesc)}</h6>
                )}
              </div>
              {/* <div class="mb-2 mr-2">
                <span class="info-label">Age</span>
                <h6 class="info-detail m-md-0">
                  {Age}
                  {Age && <span> Years</span>}{' '}
                </h6>
              </div> */}
              <div class="mb-2 mr-4">
                <span class="info-label">DOB</span>
                {DateOfBirth && Age && (
                  <h6 class="info-detail m-md-0">
                    {DateOfBirth}
                    {Age && `(${Age} Yrs)`}
                  </h6>
                )}
              </div>
              <div class="mb-2 mr-4">
                <span class="info-label">Gender</span>
                <h6 class="info-detail m-md-0">{Gender}</h6>
              </div>

              {ProgramLevel && (
                <div class="mb-2 mr-4">
                  <span class="info-label">Program Level</span>
                  <h6 class="info-detail m-md-0">{ProgramLevel}</h6>
                </div>
              )}
              <div class="mb-2 mr-4">
                <span class="info-label">Primary Insurance</span>
                {PrimaryInsurance && (
                  <h6
                    title={PrimaryInsurance}
                    class="info-detail m-md-0"
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '180px',
                    }}
                  >
                    {PrimaryInsurance}
                  </h6>
                )}
              </div>
              <div class="mb-2 mr-4">
                <span class="info-label">Allergies</span>
                {AllergiesText && <h6 class="info-detail m-md-0">{ToolTip(AllergiesText)}</h6>}
              </div>
            </div>
          </div>
          {IsEPrescribeClient && (
            <div class="col-12 col-md-8 col-lg-1 px-0">
              <Button
                loading={isFetching}
                style={{
                  padding: '5px 10px',
                  border: 'none',
                  borderRadius: '20px',
                  backgroundColor: 'rgba(240,60,20,0.8)',
                  color: 'white',
                }}
                onClick={() => {
                  let data = {
                    ClientId: ShortClientId,
                    Json: JSON.stringify({ RedirectUrl: window.location.href }),
                  };
                  this.props.dispatch(ePrescribeSinglePatientModeSSO(data));
                }}
              >
                <img src="images/Layer_2.svg" style={{ marginRight: '5px' }} />
                Prescribe
                <img src="images/clarity_pop-out-line.svg" style={{ marginLeft: '5px' }} />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ eprescribe_single_patient_mode_sso, client_banner_get }) => {
  const { data: ePrescribeData, error, isFetching } = eprescribe_single_patient_mode_sso;
  let { data: bannerDetails } = client_banner_get;
  let BannerData = {};

  let EPrescribeURL = null;

  if (bannerDetails && bannerDetails.Banner) {
    let profileBanner = bannerDetails.Banner[0];
    BannerData = {
      ClientId: profileBanner.ClientId,
      DisplayClientId: profileBanner.DisplayClientId,
      FullName: profileBanner.FullName,
      FirstName: profileBanner.FirstName,
      MiddleName: profileBanner.MiddleName,
      LastName: profileBanner.LastName,
      IsVoiceCallAllowed: profileBanner.IsVoiceCallAllowed,
      IsEPrescribeClient: profileBanner.IsEPrescribeClient,
      ...profileBanner,
    };
  }
  if (ePrescribeData && ePrescribeData.result && ePrescribeData.result.url) {
    EPrescribeURL = ePrescribeData.result.url;
  }

  return { BannerData, EPrescribeURL, isFetching, error };
};

export default connect(mapStateToProps)(withRouter(ClientProfileHeader));
