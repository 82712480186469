import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { StudentImage } from '../../../../component/student';
import { Upload, message, Modal, Popconfirm, Icon, Tooltip, Table } from 'antd';
import moment from 'moment';
import Loader, { Loader2 } from '../../../../Components/Loader';
import '../../../../component/jquery/slick.js';
import { isComparer, isDateComparer, isNumericComparer } from '../../../../util/handler';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NumericType from './questionType/numericType';
import CurrencyType from './questionType/currencyType';
import AntdModal from '../../../../Components/CustomModal';
import PersistTableSort from '../../../../component/persistTableSort';

import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  listSurveyQuestionCategory,
  surveyAssign,
  comboCenterConsent,
  listClientConsent,
  upsertClientConsent,
  deactivateClientConsent,
  getConsentFormTemplate,
  downloadClientConsent,
} from '../../../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';

class ConsentForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      SurveyQuestionCategoryListData: [],
      assesmentId: '',
    };
  }

  onAssignSurvey = () => {
    var item = {
      studentIds: '20000003',
      surveyQuestionCategoryIds: this.state.assesmentId,
    };

    this.props.dispatch(surveyAssign(item));
  };

  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }

  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    this.props.dispatch(comboCenterConsent({}));
    let listData = {
      json: JSON.stringify({
        ClientConsent: [
          {
            ClientId: this.props.clientId || this.getClientId(),
          },
        ],
      }),
    };
    this.props.dispatch(listClientConsent(listData));
  }

  componentWillReceiveProps({ isUpsertConsent, isDeactivateConsent, consentDownloadURL, isSaved }) {
    if (isUpsertConsent && isUpsertConsent != this.props.isUpsertConsent) {
      this.props.dispatch(
        listClientConsent({
          json: JSON.stringify({
            ClientConsent: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
    }
    if (isDeactivateConsent && isDeactivateConsent != this.props.isDeactivateConsent) {
      this.props.dispatch(
        listClientConsent({
          json: JSON.stringify({
            ClientConsent: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
    }
    if (consentDownloadURL && consentDownloadURL != this.props.consentDownloadURL) {
      window.open(consentDownloadURL);
    }
    if (isSaved && isSaved != this.props.isSaved) {
      this.props.dispatch(
        listClientConsent({
          json: JSON.stringify({
            ClientConsent: [
              {
                ClientId: this.getClientId(),
              },
            ],
          }),
        }),
      );
    }
    // if (
    //   SurveyQuestionCategoryList.length > 0 &&
    //   SurveyQuestionCategoryList.length != this.props.SurveyQuestionCategoryList.length
    // ) {
    //   debugger;
    //   this.setState({ SurveyQuestionCategoryList: SurveyQuestionCategoryList });
    // }
  }

  setValue({ response, surveyQuestionId }) {
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };

    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    this.setState({
      surveys: surveys,
    });

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response }) {
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };

    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    $('.main-banner').slick('slickNext');

    this.setState({
      surveys: surveys,
    });

    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }

  getSurveyId() {
    const { SurveyId } = this.state;
    return SurveyId;
  }

  getStudentId = () => {
    return this.props.clientId;
  };

  onClose() {
    this.props.history.push('/studentProfile/' + this.getStudentId());
  }

  onSubmit() {
    this.props.history.push({
      pathname: '/surveyer',
      search: 'studentId=' + this.getStudentId() + '&ignoreUpdatingPin=true',
    });
  }

  render() {
    // const {} = this.state;
    const { CenterConsentForm, consentList, isFetching, isUpsertConsent } = this.props;
    const tempColumns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        sorter: (a, b) => isComparer(a.Name, b.Name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Assigned Date',
        dataIndex: 'AssignedDate',
        key: 'AssignedDate',
        sorter: (a, b) => isDateComparer(a.AssignedDateForSort, b.AssignedDateForSort),
      },
      {
        title: 'Signed Date',
        dataIndex: 'SignedDate',
        key: 'SignedDate',
        sorter: (a, b) => isDateComparer(moment(a.SignedDateForSort), moment(b.SignedDateForSort)),
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
      },
      {
        title: 'Status',
        dataIndex: 'IsActive',
        key: 'IsActive',
        sorter: (a, b) => isNumericComparer(a.IsActive, b.IsActive),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        render: (text, record, index) => {
          return (
            <>
              {record.SignedDate ? (
                <div className="tools-grid">
                  <div className="">
                    <span
                      className="start-btn"
                      onClick={() => {
                        this.props.dispatch(
                          downloadClientConsent({
                            json: JSON.stringify({
                              ClientConsent: [
                                {
                                  ClientId: this.props.clientId,
                                  DocumentGuid: record.SignedDocumentGuid,
                                },
                              ],
                            }),
                          }),
                        );
                      }}
                    >
                      View Pdf
                    </span>
                  </div>
                </div>
              ) : (
                <div className="tools-grid">
                  <div className="mr-5">
                    <Link
                      className="start-btn"
                      to={{
                        pathname: '/sign-consent-form',
                        search: `consentId=${record.CenterConsentFormId}&Cid=${this.props.clientId}&ccid=${record.ClientConsentId}`,
                      }}
                      target="_blank"
                    >
                      Sign
                    </Link>
                  </div>
                  <div className="">
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Archive">
                            <p>
                              <img src="images/archive.svg" />
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div className="modal-content border-0">
                          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 className="modal-title col-12 mb-3">
                              Are you sure you want to archive this Consent?
                            </h4>
                          </div>
                          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              className="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  deactivateClientConsent({
                                    json: JSON.stringify({
                                      ClientConsent: [
                                        {
                                          ClientConsentId: record.ClientConsentId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                // this.deleteGroup(record.GroupId);
                                // this.props.onCancel();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              )}
            </>
          );
        },
      },
    ];
    return (
      <>
        {/* <Loader loading={this.props.isFetchingListSurvey} /> */}
        <div className="main mb-5 mt-5">
          <div className="container-fluid">
            <div className="row px-4">
              <div className="col-6">
                <div className="form-group">
                  <ReduxSelect
                    options={CenterConsentForm}
                    onChange={value => {
                      this.setState({ consentFormId: value });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={this.state.consentFormId}
                    placeholder="Select Consent Form"
                    // disabled
                  />
                </div>
              </div>
              <div className="col-3 d-flex align-items-center">
                <button
                  className="btn"
                  onClick={() => {
                    this.props.dispatch(
                      upsertClientConsent({
                        json: JSON.stringify({
                          ClientConsent: [
                            {
                              ClientId: this.props.clientId,
                              CenterConsentFormId: this.state.consentFormId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Assign
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 enrollment-form mt-1">
                <div className="row card-body">
                  <Loader2 loading={this.props.isFetching} />
                  <div className="col-12 rcm-tools-table">
                    <Table
                      name="consentList"
                      columns={tempColumns}
                      dataSource={consentList}
                      size={'middle'}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div className="dataTables_wrapper no-footer">
                              <div className="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a className="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a className="paginate_button next">Next</a>
                                ) : (
                                  <a className="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  combo_center_consent,
  list_client_consent,
  upsert_client_consent,
  deactivate_client_consent,
  download_client_consent,
  save_client_consent,
}) => {
  const { data } = combo_center_consent;
  const { data: listData, isFetching: isFetchingList } = list_client_consent;
  const { data: clientConsent } = upsert_client_consent;
  const {
    data: consentDownload,
    isFetching: isFetchingURL,
    error: errorDownload,
  } = download_client_consent;
  let isSaved = false;
  let CenterConsentForm = [];
  let consentList = [];
  let isUpsertConsent = false;
  let isDeactivateConsent = false;
  let consentDownloadURL = '';
  let DownloadError = '';
  if (data && data.CenterConsentForm) {
    CenterConsentForm = data.CenterConsentForm;
  }
  if (listData && listData.ClientConsent) {
    consentList = listData.ClientConsent;
  }
  if (clientConsent && clientConsent.success) {
    isUpsertConsent = clientConsent.success;
  }
  if (deactivate_client_consent.data && deactivate_client_consent.data.success) {
    isDeactivateConsent = deactivate_client_consent.data.success;
  }
  if (save_client_consent.data && save_client_consent.data) {
    isSaved = true;
  }

  if (consentDownload && consentDownload.success) {
    consentDownloadURL = consentDownload.result;
  } else if (errorDownload) {
    DownloadError = errorDownload;
  }
  return {
    CenterConsentForm,
    consentList,
    isFetching: isFetchingList || isFetchingURL,
    isUpsertConsent,
    isDeactivateConsent,
    consentDownloadURL,
    isSaved,
  };
};

export default connect(mapStateToProps)(withRouter(ConsentForm));
