import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Routes from './routes';
import { get, isArray } from 'lodash';
import CustomModal from './core/modal';
import { ToastContainer, toast } from 'react-toastify';
import { logOut } from './store/actions';
import { BroadcastChannel } from 'broadcast-channel';
import { logError } from './store/actions';
import CustomError from './component/CustomError';
import { connect } from 'react-redux';
import StickyNotes from './Screens/EnterpriseClient/stickyNotes';

const logoutChannel = new BroadcastChannel('logout');
const mapStateToProps = (state, props) => { return {} };
var time;
var idleTimeoutValueinMs = 1200000;
var path;
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      displayJSError: false,
      jsError: '',
    };
    this.inactivityTime = this.inactivityTime.bind(this);
    this.checkForInactivity = this.checkForInactivity.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    // Need to remove this code once white screen is not reported often.
    // let navigationArray =window.performance.getEntriesByType('navigation').map(nav => {
    //     return nav.type;
    //   });
    // let isPageReloaded = navigationArray.includes('reload');
    // let isPageNavigated = navigationArray.includes('navigate');
    // if (isPageReloaded) {
    //   return;
    // } else if (isPageNavigated) {
    //    return;
    // } else {
    //   localStorage.clear();
    //   return;
    // }
  }

  componentDidCatch(error) {
    console.log(error);
    this.props.dispatch(
      logError({
        message: error.message,
        stack: error.stack,
      }),
    );
    this.setState({
      displayJSError: true,
      jsError: 'An error has occurred. Please try again later', //error,
    });
  }

  resetError() {
    this.setState({
      displayJSError: false,
      jsError: '',
    });
  }

  callLogout() {
    // dispatch(logOut({}));
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.location.href = `${window.location.origin}`;
    console.log('app.js=>logout executed');
  }

  logOutBroadcaster = () => {
    logoutChannel.postMessage({ logoutMessage: 'User is logged out' });
    this.callLogout();
  };

  checkForInactivity() {
    try {
      //console.log('checking if time exceeds idle timeout value');
      let dateObj = new Date();
      let presentTimeStamp = dateObj.getTime();
      let lastActivityAt = window.localStorage.getItem('lastActivityAt');
      let timeDiff = presentTimeStamp - lastActivityAt;
      //console.log('time diff', timeDiff);
      if (
        timeDiff > idleTimeoutValueinMs - 120000 &&
        window &&
        window.location &&
        window.localStorage.getItem('userName') &&
        window.sessionStorage.getItem('defaultCenter')
      ) {
        this.setState({ showModal: true });
      }
      if (timeDiff > idleTimeoutValueinMs) {
        if (
          window &&
          window.location &&
          window.localStorage.getItem('userName') &&
          window.sessionStorage.getItem('defaultCenter')
        ) {
          console.log('User has to be logged out');
          var hashlocation = get(window, 'location.hash', '');
          // var path = isArray(hashlocation) ? hashlocation.split('#/')[1] : `dashboard/inHouse`;
          let defaultDashboard = sessionStorage.getItem('defaultCenter')
            ? JSON.parse(sessionStorage.getItem('defaultCenter')).substring(1)
            : '';

          path = isArray(hashlocation) ? hashlocation.split('#/')[1] : defaultDashboard;
          //postmessage here
          this.callLogout();
          logoutChannel.postMessage({ logoutMessage: 'User is logged out' });
          window.localStorage.clear();
        }
        clearTimeout(time);
      }
    } catch (e) {
      console.log('error occured ', e);
    }
  }

  resetTimer() {
    try {
      // this.setState({ showModal: false });
      if (this.state.showModal) {
        this.setState({ showModal: false });
      }
      clearTimeout(time);
      let dateObj = new Date();
      const currentTime = dateObj.getTime();
      window.localStorage.setItem('lastActivityAt', currentTime);
      console.log('Timer got reset');
      time = setInterval(this.checkForInactivity, 2000);
    } catch (e) {
      console.log('e ', e);
    }
  }
  inactivityTime() {
    try {
      window.onload = this.resetTimer;
      // DOM Events
      document.onmousemove = this.resetTimer;
      document.onkeyup = this.resetTimer;
      document.onscroll = this.resetTimer;
    } catch (e) {
      console.log('err ', e);
    }
  }

  logoutAllTabsListener() {
    try {
      logoutChannel.onmessage = event => {
        this.callLogout();
        logoutChannel.close();
        console.log('event message ', event.logoutMessage);
      };
    } catch (e) {
      console.log('errrr ', e);
    }
  }

  componentDidMount() {
    // window.onbeforeunload = function() {
    //   localStorage.clear();
    // };
    this.inactivityTime();
    this.logoutAllTabsListener();
    let toastifyElements = document.getElementsByClassName('Toastify');
    if (toastifyElements.length === 0) {
      this.logOutBroadcaster();
    }
  }
  render() {
    const { displayJSError, jsError } = this.state;
    return (
      <>
        {!displayJSError ? (
          <>
            {this.state.showModal && (
              <CustomModal
                isOpen={this.state.showModal}
                header={`Due to inactivity, you are about to be logged out. If you would like to stay logged in, move your mouse or click any button on your keyboard.`}
                yesText="OK"
                noText="Cancel"
                hideCancel={true}
                zIndex={999999999}
              />
            )}
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Routes logOutBroadcaster={this.logOutBroadcaster} />
            <StickyNotes />
          </>
        ) : (
          <CustomError
            displayJSError={displayJSError}
            jsError={jsError}
            resetError={this.resetError.bind(this)}
          />
        )}
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps)(App));
