import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import renderHTML from 'react-render-html';
import InfiniteScroll from 'react-infinite-scroll-component';
import SubscriberReelInfo from './subscriberReelInfo';
import { Loader2 } from '../../../Components/Loader';
import {
  dashboardMobileUserGetAll,
  entMobileUserDashboardGet,
  augmentedSummaryGet,
} from '../../../store/actions';

let { TabPane } = Tabs;

class ClientReels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      subscribersListArray: [],
      hasMoreSubscribersListArray: true,
      userGuid: null,
      // initial
    };
    // this.selectorRef = React.createRef();
  }
  componentDidMount() {
    this.props.dispatch(dashboardMobileUserGetAll({}));
  }

  componentWillReceiveProps({ subscribersList, isSuccess }) {
    if (
      subscribersList &&
      JSON.stringify(subscribersList) != JSON.stringify(this.props.subscribersList)
    ) {
      let newData = [...this.state.subscribersListArray, ...subscribersList];
      this.setState({ subscribersListArray: newData, hasMoreSubscribersListArray: true }, () => {
        if (this.state.activeKey == 0) {
          this.handleOnClick(
            this.state.subscribersListArray &&
              this.state.subscribersListArray[0] &&
              this.state.subscribersListArray[0].UserGuid, 0
          );
         }
      });
    } else if (subscribersList && subscribersList.length === 0) {
      this.setState({ hasMoreSubscribersListArray: false });
    }

    // if (isSuccess && isSuccess != this.props.isSuccess) {
    //   this.setState({
    //     userGuid: null,
    //   });
    // }
  }

  getPendingSubscribersList = filterData => {
    this.props.dispatch(
      dashboardMobileUserGetAll({
        Json: JSON.stringify({
          ClientReels: {
            ...filterData,
          },
        }),
      }),
    );
  };

  onTabClick = (key, abc) => {
    const { subscribersList } = this.props;
    let keyData = parseInt(key);
    // let clickedTab = this.state.tabMapping[keyData];
    this.setState({
      activeKey: parseInt(key),
    });
  };

  goToNext = () => {
    const { activeKey, subscribersListArray } = this.state;
    if (activeKey != subscribersListArray.length) {
      this.handleOnClick(subscribersListArray[activeKey + 1].UserGuid, activeKey + 1);
    }
  };

  goToPrev = () => {
    const { activeKey, subscribersListArray } = this.state;
    if (activeKey > 0) {
      this.handleOnClick(subscribersListArray[activeKey - 1].UserGuid, activeKey - 1);
    }
  };

  handleOnClick = (UserGuid, activeKey) => {
    const { subscribersListArray } = this.state;
    this.setState({
      userGuid: UserGuid,
      activeKey: activeKey,
    });

    this.props.dispatch(
      entMobileUserDashboardGet({
        Json: JSON.stringify({
          Member: [{ UserGuid: UserGuid }],
        }),
      }),
    );
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.props.ClientId,
            UserGuid: UserGuid,
          },
        ],
      }),
    };
    this.props.dispatch(augmentedSummaryGet(data));

    let infiniteScrollerContainer = document.getElementsByClassName('infinite-scroll-component');
    if (infiniteScrollerContainer && infiniteScrollerContainer.length > 0) {
      let infiniteElements = infiniteScrollerContainer[0].getElementsByClassName(
        'clients-reel-subscriber active',
      );
      if (infiniteElements && infiniteElements.length > 0) {
        infiniteElements[0].classList.remove('active');
      }
    }

    let subscriber = document.getElementsByClassName('clients-reel-subscriber');
    if (subscriber) {
      if (subscriber[activeKey]) {
        subscriber[activeKey].classList.add('active');
      }
    }
  };
  render() {
    const { subscribersList, isFetching } = this.props;
    const { activeKey, subscribersListArray, hasMoreSubscribersListArray } = this.state;
    return (
      <div className="d-flex client-reels">
        <div id="scrollableDiv" style={{ height: 600, overflow: 'auto', width: '315px' }}>
          <InfiniteScroll
            dataLength={subscribersListArray.length}
            next={() => {
              let { LastTrendUpdateDateForSort } = subscribersListArray[
                subscribersListArray.length - 1
              ];
              this.getPendingSubscribersList({
                PageSize: 10,
                LastTrendUpdateDate: LastTrendUpdateDateForSort,
              });
            }}
            hasMore={hasMoreSubscribersListArray}
            loader={<h4>Loading...</h4>}
            scrollableTarget={'scrollableDiv'}
            endMessage={
              <p style={{ textAlign: 'center', marginTop: '15px' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {subscribersListArray &&
              subscribersListArray.length > 0 &&
              subscribersListArray.map((item, index) => {
                const { DisplayName, UserGuid, ProfileImageUrl, Service, TrendLevel } = item;
                let profileImage = ProfileImageUrl || 'images/blank-profile.png';
                let trendLevel =
                  TrendLevel == 0
                    ? 'pic-circle-neutral'
                    : TrendLevel == 1
                    ? 'pic-circle-arrow-up'
                    : 'pic-circle-arrow-down';
                // let tabLabel = `<div className='d-flex justify-content-center align-items-center'><img src=${profileImage} style='height: 50px; width: 50px; border-radius: 50%' /><div className='ml-3'><p className='mb-0'>${DisplayName}</p><p className='mb-0'>${Service}</p></div></div>`;

                return (
                  <div
                    className="d-flex justify-content-center align-items-center mb-3 py-2 clients-reel-subscriber"
                    onClick={() => this.handleOnClick(UserGuid, index)}
                    style={{ cursor: 'pointer', backgroundColor: '#F8F8F8' }}
                    // ref={el => this.selectorRef[index] = el}
                    key={UserGuid}
                  >
                    <div className={`${trendLevel}`}>
                      <div class="student-list-pic">
                        <img src={`${profileImage}`} class="img-circle" />
                      </div>
                    </div>
                    <div style={{ width: '190px' }} className="ml-3">
                      <p
                        className="mb-0 font-weight-bolder"
                        style={{ fontSize: '16px', whiteSpace: 'break-spaces' }}
                      >{`${DisplayName}`}</p>
                      <p className="mb-0" style={{ whiteSpace: 'break-spaces' }}>{`${Service}`}</p>
                    </div>
                  </div>
                );
              })}
          </InfiniteScroll>
        </div>

        <div style={{ width: '80%' }}>
          {this.state.userGuid && (
            <SubscriberReelInfo
              UserGuid={this.state.userGuid}
              totalTabCount={subscribersList.length}
              goToNext={this.goToNext}
              goToPrev={this.goToPrev}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard_mobile_user_get_all, ent_mobile_user_dashboard_get }) => {
  const { data, isFetching } = dashboard_mobile_user_get_all;

  let subscribersList = [];

  if (data && data.Data) {
    subscribersList = data.Data;
  }

  return {
    subscribersList,
    isFetching,
    isSuccess:
      ent_mobile_user_dashboard_get &&
      ent_mobile_user_dashboard_get.data &&
      ent_mobile_user_dashboard_get.data.success,
  };
};

export default connect(mapStateToProps)(withRouter(ClientReels));
