import React, { Component } from 'react';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';

let resultColumns = [
  {
    title: 'Observation Test',
    dataIndex: 'ObservationTest',
    key: 'title',
    sorter: (a, b) => isComparer(a.ObervationTest, b.ObervationTest),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return text && <span style={{ fontWeight: '600' }}>{text}</span>;
    },
  },
  {
    title: 'Observation Value',
    dataIndex: 'ObservationValue',
    key: 'title',
    sorter: (a, b) => isComparer(a.ObervationTest, b.ObervationTest),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return text && <span style={{ fontWeight: '600' }}>{text}</span>;
    },
  },
  {
    title: 'Unit',
    dataIndex: 'Unit',
    key: 'unit',
    sorter: (a, b) => isComparer(a.Unit, b.Unit),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return text && <span style={{ fontWeight: '600' }}>{text}</span>;
    },
  },
  {
    title: 'Notes',
    dataIndex: 'Notes',
    key: 'notes',
    sorter: (a, b) => isComparer(a.Notes, b.Notes),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return text && <span style={{ fontWeight: '600' }}>{text}</span>;
    },
  },
];

const LabOrderResult = props => {
  const { onCancel, labResultArray, isResultFetching } = props;
  return (
    <div>
      <div className="modal-header flex-wrap border-bottom-0 px-4">
        <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        <h4
          className="lab-modal-title col-12 text-left"
          // style={{ backgroundColor: '#4AB0FE', color: 'white' }}
        >
          LAB ORDER RESULTS
        </h4>
        {/* <h4 className="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
      </div>
      <div>
        <Loader2 loading={isResultFetching} />
      </div>
      {labResultArray.map((item, index) => (
        <div className="modal-body rcm-panel enrollment-form px-4">
          <div className="row mb-2">
            <div className="d-flex col-12 col-md-6 col-lg-5">
              <p className="lab-info">Lab Test {index + 1}: </p>
              <p className="lab-info-value">{item.TestCode}</p>
            </div>
            <div className="d-flex col-12 col-md-6 col-lg-5">
              <p className="lab-info">Result Status : </p>
              <p className="lab-info-value">{item.ResultStatus}</p>
            </div>
            <div className="col-12">
              <div className="row-hover-enabled w-100">
                <Table
                  columns={resultColumns}
                  dataSource={JSON.parse(item.LabOrderTest)}
                  pagination={{
                    itemRender: (page, type, originalElement) => {
                      return (
                        <div class="dataTables_wrapper no-footer">
                          <div class="dataTables_paginate paging_simple_numbers">
                            {type == 'prev' ? (
                              <a class="paginate_button previous">Previous</a>
                            ) : type == 'next' ? (
                              <a class="paginate_button next">Next</a>
                            ) : (
                              <a class="paginate_button ">{page}</a>
                            )}
                          </div>
                        </div>
                      );
                    },
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                  }}
                />
              </div>
            </div>
            {/*this notes and comments section only show when there is notes if not don't even show the heading*/}
            {item.Comments && (
              <div className="col-12">
                <u style={{ color: '#727272' }}>Patient Notes and Comments</u>
              </div>
            )}
            {item.Comments &&
              JSON.parse(item.Comments).map(item => (
                <div className="col-12 mt-1">
                  <p className="mb-0">{item.Comment}</p>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LabOrderResult;
