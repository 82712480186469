import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { ProcessCombos } from '../../../../store/comboConfig';
import PersistTableSort from '../../../../component/persistTableSort';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { getPatientList } from '../../../../store/actions';
import AntdModal from '../../../../Components/CustomModal';
import CustomModal from '../../../../core/modal';
import { Table, Modal } from 'antd';
import { timeConvert } from '../../../../util/time';
import moment from 'moment';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';

class PaymentServiceLineDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createAndEditPaymentModal: false,
      ServiceLineData: [],
      editedSource: {},
    };
  }
  componentDidMount() {
    var result = ProcessCombos('PaymentSource,CWPaymentMethod,');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(
      getPatientList({
        Json: JSON.stringify({
          Client: [
            {
              FilterBy: 'Active',
            },
          ],
        }),
      }),
    );
  }

  render() {
    const { PaymentInfo, PaymentServiceList, toggleView } = this.props;

    const columns = [
      {
        title: 'Patient ID',
        dataIndex: 'ClientId',
        key: '1',
        sorter: (a, b) => isComparer(a.ClientId, b.ClientId),
      },
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: '2',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
      },
      {
        title: 'Date of Service',
        dataIndex: 'DateofService',
        key: '3',
        sorter: (a, b) => isDateComparer(a.DateofServiceForSort, b.DateofServiceForSort),
      },
      {
        title: 'Service Line',
        dataIndex: 'ServiceCode',
        key: '4',
        sorter: (a, b) => isComparer(a.ServiceLineId, b.ServiceLineId),
      },
      {
        title: 'Adjustment Amount',
        dataIndex: 'AdjustmentAmount',
        key: '5',
        sorter: (a, b) => isNumericComparer(a.AdjustmentAmount, b.AdjustmentAmount),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Amount Paid',
        dataIndex: 'Amount',
        key: '6',
        sorter: (a, b) => isNumericComparer(a.Amount, b.Amount),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Explanation Code',
        dataIndex: 'ExplanationCode',
        key: '7',
        sorter: (a, b) => isComparer(a.ExplanationCode, b.ExplanationCode),
      },
      // {
      //   title: 'Allowed',
      //   dataIndex: 'Allowed',
      //   key: '8',
      //   sorter: (a, b) => isNumericComparer(a.Allowed, b.Allowed),
      //   render: (text, record) => {
      //     return <div className="">{currencyFormatter.format(text || 0)}</div>;
      //   },
      // },
      {
        title: 'Deduct',
        dataIndex: 'Deduct',
        key: '9',
        sorter: (a, b) => isNumericComparer(a.Deduct, b.Deduct),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'CoPay',
        dataIndex: 'CoPay',
        key: '10',
        sorter: (a, b) => isNumericComparer(a.CoPay, b.CoPay),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'CoInsurance',
        dataIndex: 'CoInsurance',
        key: '11',
        sorter: (a, b) => isNumericComparer(a.CoInsurance, b.CoInsurance),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Discount',
        dataIndex: 'Discount',
        key: '12',
        sorter: (a, b) => isNumericComparer(a.Discount, b.Discount),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Interest',
        dataIndex: 'Interest',
        key: '13',
        sorter: (a, b) => isNumericComparer(a.Interest, b.Interest),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Medicare Allowed',
        dataIndex: 'MedicareAllowed',
        key: '14',
        sorter: (a, b) => isNumericComparer(a.MedicareAllowed, b.MedicareAllowed),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Medicare Paid',
        dataIndex: 'MedicarePaid',
        key: '15',
        sorter: (a, b) => isNumericComparer(a.MedicarePaid, b.MedicarePaid),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Third Party',
        dataIndex: 'ThirdParty',
        key: '16',
        sorter: (a, b) => isNumericComparer(a.ThirdParty, b.ThirdParty),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
      {
        title: 'Withheld',
        dataIndex: 'Withheld',
        key: '17',
        sorter: (a, b) => isNumericComparer(a.Withheld, b.Withheld),
        render: (text, record) => {
          return <div className="">{currencyFormatter.format(text || 0)}</div>;
        },
      },
    ];
    return (
      <div className="row">
        <div className="col-12">
          <div className="group-service-detail">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center my-2">
                  <div
                    onClick={() => {
                      toggleView();
                    }}
                    className="text-left d-flex"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="images/back-arrow.svg" /> Back to Payment List
                  </div>
                </div>
              </div>
              <div className="col-12 p-3">
                <div className="service-line">
                  <div className="info-block">
                    <div className="block" style={{ borderBottom: '0px' }}>
                      <span className="label">{'Payment Source'}</span>
                      <p className="value"> {PaymentInfo.PaymentSourceText}</p>
                    </div>
                    <div className="block" style={{ borderBottom: '0px' }}>
                      <span className="label">{'Insurance Payer Name'}</span>
                      <p className="value"> {PaymentInfo.PayerName}</p>
                    </div>
                    <div className="block" style={{ borderBottom: '0px' }}>
                      <span className="label">{'Payment Method'}</span>
                      <p className="value"> {PaymentInfo.PaymentMethodText}</p>
                    </div>
                    <div className="block" style={{ borderBottom: '0px' }}>
                      <span className="label">{'Cheque Number'}</span>
                      <p className="value"> {PaymentInfo.ChequeNumber}</p>
                    </div>
                    <div className="block" style={{ borderBottom: '0px' }}>
                      <span className="label">{'Amount'}</span>
                      <p className="value"> {currencyFormatter.format(PaymentInfo.Amount || 0)}</p>
                    </div>
                  </div>
                  <div className="info-block mt-0">
                    <div className="block" style={{ borderBottom: '0px' }}>
                      <span className="label">{'Note'}</span>
                      <p className="value"> {PaymentInfo.Note}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h6>Payment Service List</h6>
                <Table columns={columns} dataSource={PaymentServiceList} pagination={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { combos, get_CWPayment } = state;
  const { data, isFetching } = get_CWPayment;

  let PaymentServiceList = [];
  let PaymentInfo = {};

  if (data && data.data && data.data[0]) {
    PaymentInfo = data.data[0];
    PaymentServiceList = JSON.parse(data.data[0].CWPaymentServiceLine);
  }
  return {
    PaymentServiceList,
    PaymentInfo,
    isFetching,
  };
};

export default connect(mapStateToProps)(PaymentServiceLineDetail);
