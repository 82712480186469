import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Tooltip } from 'antd';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import Loader, { Loader2 } from '../../../Components/Loader';
import CustomModal2 from '../../../core/modal';
import AntdModal from '../../../Components/CustomModal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import GroupEventModal from './formSteps/GroupEventNote';
import validate from '../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import {
  listCombos,
  medicateCombos,
  practitionerCombosOffice,
  upsertGroup,
  listGroup,
  resetAddCallSession,
  deleteGroup,
  getPatientList,
  getClientGroup,
  upsertClientGroup,
  getListClientGroup,
  getComboFacility,
  listGroupAppointmentNote,
} from '../../../store/actions';
import PersistTableSort from '../../../component/persistTableSort';
import GridSearch from '../../../Components/gridSearch';
import PageTitle from '../../../Components/PageTitle';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

class PatientGroups extends Component {
  constructor() {
    super();
    this.state = {
      editGroupData: {},
      createAndEditGroupModal: false,
      manageGroupsModal: false,
      groupEventModal: false,
    };
  }

  onSearchChange = value => {
    this.state.searchStr = value;
    this.refreshGrid();
  };

  refreshGrid = () => {
    const { searchStr } = this.state;
    this.setState({
      searchStr,
    });
    this.props.dispatch(
      listGroup({
        json: JSON.stringify({
          Group: [
            {
              Search: searchStr,
            },
          ],
        }),
      }),
    );
    sessionStorage.setItem(
      'appliedFilter',
      JSON.stringify({
        // Filter: filterType && filterType.toString(),
        searchStr: searchStr,
        // startDate,
        // endDate,
        // filterCenterId,
        // FilterCounserlorId,
      }),
    );
  };

  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes: 'ServiceCode',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    this.props.dispatch(listGroup({}));
    this.props.dispatch(getPatientList({}));
    this.props.dispatch(getComboFacility({}));
  }

  componentWillReceiveProps({ isGroupCreated, deleteGroupStatusValue }) {
    if (isGroupCreated && isGroupCreated != this.props.isGroupCreated) {
      this.props.dispatch(listGroup({}));
      this.setState({
        inviteMsg: 'Group created successfully.',
        isModelOpen: true,
      });
    }

    if (deleteGroupStatusValue && deleteGroupStatusValue != this.props.deleteGroupStatusValue) {
      this.props.dispatch(listGroup({}));
    }
  }
  resetEditGroup = () => {
    this.setState({ editGroupData: {} });
  };

  onSubmit = values => {
    const { groupId } = this.state.editGroupData;
    var data = {
      Group: [
        {
          GroupId: groupId || null,
          GroupName: values.groupName,
          ServiceCodeId: values.serviceCode,
          CounsellorId: values.counselor,
          PractitionerOfficeId: values.officelocation,
        },
      ],
    };
    var GroupData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertGroup(GroupData));
    this.setState({ createAndEditGroupModal: false, resetEditGroup: {} });
  };

  // deleteGroup = groupId => {
  //   this.props.dispatch(
  //     deleteGroup({
  //       json: JSON.stringify({
  //         Group: [
  //           {
  //             GroupId: groupId,
  //           },
  //         ],
  //       }),
  //     }),
  //   );
  //   this.props.dispatch(listGroup({}));
  //   // let editRecord = {
  //   //   groupId: record.GroupId,
  //   //   groupName: record.GroupName,
  //   //   serviceCode: parseInt(record.ServiceCodeId),
  //   //   counselor: record.CounsellorId,
  //   //   officelocation: JSON.stringify(record.PractitionerOfficeId),
  //   // };
  //   // this.setState({ editGroupData: editRecord });
  // };

  editGroup = record => {
    this.props.dispatch(
      practitionerCombosOffice({
        json: JSON.stringify({
          PractitionerOffice: [
            {
              PractitionerId: record.CounsellorId,
            },
          ],
        }),
      }),
    );

    let editRecord = {
      groupId: record.GroupId,
      groupName: record.GroupName,
      serviceCode: parseInt(record.ServiceCodeId),
      counselor: record.CounsellorId,
      officelocation: JSON.stringify(record.PractitionerOfficeId),
      groupStatus: record.GroupStatus,
    };
    this.setState({ editGroupData: editRecord });
  };

  onGroupAssign = values => {
    const { groupId } = this.state.editGroupData;
    var data = {
      Group: [
        {
          GroupId: groupId || null,
          GroupName: values.groupName,
          ServiceCodeId: values.serviceCode,
          CounsellorId: values.counselor,
          PractitionerOfficeId: values.officelocation,
        },
      ],
    };
    var GroupData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertGroup(GroupData));
    this.setState({ createAndEditGroupModal: false, resetEditGroup: {} });
  };

  setCreateAndEditGroupModalToFalse = () => {
    this.setState({ createAndEditGroupModal: false });
  };

  onRowClick(groupId) {
    const { dispatch, history } = this.props;

    // this.props.history.push({
    //   pathname: '/rcm/PatientManagement',
    //   search: `?patientId=${ClientId}`,
    // });
    this.props.history.push(
      `/cw/group-management/group-details?tab=group-information&gId=${groupId}`,
    );
  }

  render() {
    const { groupEventModal } = this.state;
    const { isFetching, isReadOnlyUser } = this.props;
    const columns = [
      // {
      //   title: 'Group Id',
      //   dataIndex: 'GroupId',
      //   key: 'GroupId',
      //   sorter: (a, b) => isNumericComparer(a.GroupId, b.GroupId),
      // },
      {
        title: 'Group Name',
        dataIndex: 'GroupName',
        key: 'GroupName',
        sorter: (a, b) => isComparer(a.GroupName, b.GroupName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Patient(s) Count',
        dataIndex: 'ClientCount',
        key: 'ClientCount',
        sorter: (a, b) => isNumericComparer(a.ClientCount, b.ClientCount),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Counselor',
        dataIndex: 'PractitionerName',
        key: 'PractitionerName',
        sorter: (a, b) => isComparer(a.PractitionerName, b.PractitionerName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Service(s)',
        dataIndex: 'ServiceCodeText',
        key: 'ServiceCodeText',
        sorter: (a, b) => isNumericComparer(a.ServiceCodeText, b.ServiceCodeText),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Office Location',
        dataIndex: 'FacilityName',
        key: 'FacilityName',
        sorter: (a, b) => isComparer(a.FacilityName, b.FacilityName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Group Status',
        dataIndex: 'GroupStatus',
        key: 'GroupStatus',
        sorter: (a, b) => isComparer(a.GroupStatus, b.GroupStatus),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Action',
      //   render: (text, record, index) => (
      //     <>

      //       <div class="actions-button d-flex justify-content-around">
      //         <div className="">
      //           <Tooltip placement="top" title="Edit Group">
      //             <span
      //               class="select-course"
      //               onClick={event => {
      //                 this.setState({
      //                   createAndEditGroupModal: true,
      //                 });
      //                 this.editGroup(record);
      //               }}
      //             >
      //               {/* <i class="select-icon" /> */}
      //               <i class="fas fa-pencil-alt" />
      //             </span>
      //           </Tooltip>
      //         </div>
      //         <div className="actions-button">
      //           {/* <AntdModal
      //               ModalButton={({ onClick }) => (
      //                 <Tooltip placement="top" title="Archive Group">
      //                   <span class="delete-course" onClick={onClick}>
      //                     <i class="fas fa-times" />
      //                   </span>
      //                 </Tooltip>
      //               )}
      //               ModalContent={({ onOk, onCancel }) => (
      //                 <div class="modal-content border-0">
      //                   <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
      //                   <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
      //                     <h4 class="modal-title col-12 mb-3">
      //                       Are you sure you want to archive this Group?
      //                     </h4>
      //                   </div>
      //                   <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
      //                     <button
      //                       class="btn black-btn"
      //                       onClick={() => {
      //                         onCancel();
      //                       }}
      //                     >
      //                       No
      //                     </button>
      //                     <button
      //                       class="btn"
      //                       onClick={() => {
      //                         onOk();
      //                         this.deleteGroup(record.GroupId);
      //                         // this.props.onCancel();
      //                       }}
      //                     >
      //                       Yes
      //                     </button>
      //                   </div>
      //                 </div>
      //               )}
      //             /> */}
      //         </div>
      //         <div className="actions-button">
      //           <a
      //             style={{ color: '#4AB0FE', textDecoration: 'underline' }}
      //             onClick={event => {
      //               event.stopPropagation();

      //               this.props.dispatch(
      //                 getClientGroup({
      //                   json: JSON.stringify({
      //                     ClientGroup: [
      //                       {
      //                         GroupId: record.GroupId,
      //                       },
      //                     ],
      //                   }),
      //                 }),
      //               );
      //               this.props.dispatch(
      //                 getListClientGroup({
      //                   json: JSON.stringify({
      //                     Client: [
      //                       {
      //                         Search: '',
      //                         GroupId: record.GroupId,
      //                       },
      //                     ],
      //                   }),
      //                 }),
      //               );
      //               this.setState({
      //                 manageGroupsModal: true,
      //                 groupData: record,
      //               });
      //               this.editGroup(record);
      //             }}
      //           >
      //             Manage Patients
      //           </a>
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
      // {
      //   title: 'Meetings',
      //   dataIndex: 'Meetings',
      //   key: 'Meetings',
      //   sortDirections: ['descend', 'ascend'],
      //   render: (text, record) => {
      //     return (
      //       <span
      //         style={{ color: '#4AB0FE', fontSize: '14px', cursor: 'pointer' }}
      //         className="mr-2"
      //         data-toggle="modal"
      //         data-target="#archive-model"
      //         onClick={e => {
      //           e.stopPropagation();
      //           // this.setState({
      //           //   groupEventModal: true,
      //           //   // Name: record.Name,
      //           //   // UserGuid: record.UserGuid,
      //           // });
      //           // this.props.history.push(
      //           //   '/rcm?tab=GroupManagement/groupDetails&groupId=' + record.GroupId,
      //           // );
      //           this.props.history.push(
      //             `/rcm?tab=GroupManagement/groupDetails&groupId=${record.GroupId}&meeting=list`,
      //           );
      //         }}
      //       >
      //         View
      //       </span>
      //     );
      //   },
      // },
    ];
    return (
      <React.Fragment>
        <div class="w-100 rcm-panel">
          <PageTitle TitleText="Group Management" />
          <div class="main enrollment-page mb-5">
            <div class="container-fluid px-5">
              <div class="content-area">
                <div class="col-md-12">
                  <div class="row top-table-search mt-3 mb-3 align-items-center">
                    <div class="col-12 col-md-4 col-lg-3">
                      <h3 class="page-title mb-3 mb-md-0 text-uppercase">Group List</h3>
                    </div>
                    <div class="col-12 col-md-8 col-lg-9 text-md-right d-flex justify-content-end">
                      <GridSearch onSearchChange={this.onSearchChange} />
                      {!isReadOnlyUser && (
                        <button
                          class="btn"
                          // onClick={event => {
                          //   this.setState({
                          //     createAndEditGroupModal: true,
                          //   });
                          // }}
                          onClick={() => {
                            this.props.history.push('/cw/group-management/create-group');
                          }}
                        >
                          Create Group
                        </button>
                      )}
                    </div>

                    {/* <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input type="text" class="form-control shadow-sm" placeholder="Search" id="filterbox">
                    <button class="btn search-icon " type="button">
                      <i class="fa fa-search"></i>
                    </button>
                    </div>
                </div>
                <input type="button" class="btn black-btn" value="Download Excel">
                  <input type="button" class="btn btn-outline-primary" value="Filter">
                    <span class="delete-course">
                      <button class="btn btn-outline-primary" color="blue">Add a Staff</button></span>
                    */}
                  </div>

                  <div class="row">
                    <div class="col-12 mb-5 px-0">
                      <div class="card border-0">
                        <Loader2 loading={isFetching} />
                        <div class="row card-body">
                          <div class="col-12 row-hover-enabled">
                            <PersistTableSort
                              name="grp-list"
                              columns={columns}
                              dataSource={this.props.ListGroup}
                              size={'middle'}
                              onRow={(record, rowIndex) => {
                                return {
                                  onClick: event => this.onRowClick(record.GroupId),
                                };
                              }}
                              pagination={{
                                itemRender: (page, type, originalElement) => {
                                  return (
                                    <div class="dataTables_wrapper no-footer">
                                      <div class="dataTables_paginate paging_simple_numbers">
                                        {type == 'prev' ? (
                                          <a class="paginate_button previous">Previous</a>
                                        ) : type == 'next' ? (
                                          <a class="paginate_button next">Next</a>
                                        ) : (
                                          <a class="paginate_button ">{page}</a>
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                                showSizeChanger: true,
                                // hideOnSinglePage: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CustomModal2
                  isOpen={this.state.isModelOpen}
                  // header={errorMessage || 'Your changes have been saved successfully'}
                  header={this.state.inviteMsg}
                  yesAction={() => {
                    this.props.dispatch(reset('patientGroupsForm'));
                    this.setState({
                      isModelOpen: false,
                      inviteMsg: '',
                      editGroupData: {},
                    });
                  }}
                  yesText="OK"
                  hideCancel={true}
                  changeMaxWidth="100%"
                />
                <Modal
                  zIndex={100}
                  visible={this.state.createAndEditGroupModal}
                  footer={null}
                  closable={false}
                  width={700}
                >
                  <ReduxPatientGroups
                    {...this.props}
                    onSubmit={this.onSubmit}
                    dispatch={this.props.dispatch}
                    history={this.props.history}
                    medicateCombos={this.props.medicateCombosObj}
                    initialValues={this.state.editGroupData}
                    groupId={this.state.editGroupData.groupId}
                    resetEditGroup={this.resetEditGroup}
                    setCreateAndEditGroupModalToFalse={this.setCreateAndEditGroupModalToFalse}
                    onCancel={() => {
                      // this.resetSearchClientValues();
                      this.setState({
                        createAndEditGroupModal: false,
                        editGroupData: {},
                      });
                    }}
                  />
                </Modal>
                <Modal
                  zIndex={100}
                  visible={this.state.manageGroupsModal}
                  footer={null}
                  closable={false}
                  width={942}
                >
                  <ReduxManageGroups
                    {...this.props}
                    onSubmit={this.onGroupAssign}
                    dispatch={this.props.dispatch}
                    history={this.props.history}
                    medicateCombos={this.props.medicateCombosObj}
                    initialValues={this.state.editGroupData}
                    groupData={this.state.groupData}
                    groupId={this.state.editGroupData.groupId}
                    resetEditGroup={this.resetEditGroup}
                    onCancel={() => {
                      // this.resetSearchClientValues();
                      this.setState({
                        manageGroupsModal: false,
                        editGroupData: {},
                      });
                    }}
                  />
                </Modal>
                <Modal
                  visible={groupEventModal}
                  footer={null}
                  closable={false}
                  width={900}
                  destroyOnClose={true}
                >
                  <GroupEventModal
                    // UserGuid={this.state.UserGuid}
                    // Name={this.state.Name}
                    onCancel={() => {
                      this.setState({
                        groupEventModal: false,
                      });
                    }}
                  />
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let PatientGroupsForm = ({
  onSubmit,
  handleSubmit,
  dispatch,
  history,
  name,
  disable,
  medicateCombos,
  Practitioner,
  PractitionerOffice,
  initialValues,
  groupId,
  resetForm,
  resetEditGroup,
  isFetching,
  onCancel,
  setCreateAndEditGroupModalToFalse,
}) => {
  // const deleteGroup = groupId => {
  //   this.props.dispatch(
  //     deleteGroup({
  //       json: JSON.stringify({
  //         Group: [
  //           {
  //             GroupId: groupId,
  //           },
  //         ],
  //       }),
  //     }),
  //   );
  //   this.props.dispatch(listGroup({}));
  // };

  const { ServiceCode } = medicateCombos;
  return (
    <div class="modal-content border-0">
      <div class="modal-header flex-wrap border-bottom-0 ">
        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        {/* <h4 class="modal-title col-12 text-left">Appointment</h4> */}
        {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
      </div>
      <div class="modal-body rcm-panel">
        <div style={{ textAlign: 'center' }}>
          {/* <h6 style={{ fontSize: '16px', color: 'rgb(18, 196, 117)' }}>{this.state.inviteMsg}</h6>
        <h6 style={{ fontSize: '16px', color: 'rgb(255, 0, 0)' }}>{this.state.errorMessage}</h6> */}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div class="content-area "> */}
          <div className="row">
            <div class="col-12 px-1">
              {/* <Loader2 loading={isFetching} /> */}
              <div class="border-0 rounded-0">
                <div class="enrollment-form patientGroups-form px-3">
                  <div className="row">
                    <div class="col-12">
                      {groupId > 0 ? <h6>Edit Group</h6> : <h6>Create Group</h6>}
                    </div>
                  </div>
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    <div class="col-12 col-md-6 col-lg-6">
                      <div class="form-group">
                        <Field
                          name="groupName"
                          component={ReduxInput}
                          validate={validate.required}
                          fieldName={'Group Name'}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">Group Name*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6">
                      <div class="form-group">
                        <Field
                          name={`serviceCode`}
                          validate={validate.required}
                          type="text"
                          component={ReduxSelect}
                          placeholder={`Service Code*`}
                          options={ServiceCode}
                          fieldName={'Diagnosis'}
                          // disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6">
                      <div class="form-group">
                        <Field
                          name={`counselor`}
                          validate={validate.required}
                          type="text"
                          component={ReduxSelect}
                          placeholder={`Counselor*`}
                          options={Practitioner}
                          fieldName={'Counselor'}
                          // onChange={val => {
                          //   dispatch(
                          //     practitionerCombosOffice({
                          //       json: JSON.stringify({
                          //         PractitionerOffice: [
                          //           {
                          //             PractitionerId: val,
                          //           },
                          //         ],
                          //       }),
                          //     }),
                          //   );
                          // }}
                          // disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6">
                      <div class="form-group">
                        <Field
                          name={`officelocation`}
                          validate={validate.required}
                          type="text"
                          component={ReduxSelect}
                          placeholder={`Office Location*`}
                          options={PractitionerOffice}
                          fieldName={'Office Location'}
                          // disabled={disable}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-between">
                      {groupId > 0 && (
                        <div>
                          {initialValues && initialValues.groupStatus == 'Active' ? (
                            <AntdModal
                              ModalButton={({ onClick }) => (
                                <Tooltip placement="top" title="Archive Group">
                                  {/* <span class="delete-course" onClick={onClick}>
                          <i class="fas fa-times" />
                        </span> */}
                                  <button
                                    type="button"
                                    className="btn-small deactivate-link"
                                    onClick={onClick}
                                  >
                                    <i class="fa fa-ban" aria-hidden="true"></i> Deactivate Group
                                  </button>
                                </Tooltip>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                    <h4 class="modal-title col-12 mb-3">
                                      Are you sure you want to archive this Group?
                                    </h4>
                                  </div>
                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      class="btn black-btn"
                                      onClick={() => {
                                        onCancel();
                                      }}
                                    >
                                      No
                                    </button>
                                    <button
                                      class="btn"
                                      onClick={() => {
                                        onOk();
                                        // this.deleteGroup(groupId);
                                        dispatch(
                                          deleteGroup({
                                            json: JSON.stringify({
                                              Group: [
                                                {
                                                  GroupId: groupId,
                                                  IsActivate: false,
                                                },
                                              ],
                                            }),
                                          }),
                                        );
                                        dispatch(listGroup({}));
                                        resetEditGroup();
                                        onCancel();
                                        setCreateAndEditGroupModalToFalse();

                                        // this.props.onCancel();
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          ) : (
                            <AntdModal
                              ModalButton={({ onClick }) => (
                                <Tooltip placement="top" title="Reactivate Group">
                                  {/* <span class="delete-course" onClick={onClick}>
                        <i class="fas fa-times" />
                      </span> */}
                                  <button
                                    type="button"
                                    className="btn-small reactivate-link"
                                    onClick={onClick}
                                  >
                                    Reactivate Group
                                  </button>
                                </Tooltip>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                    <h4 class="modal-title col-12 mb-3">
                                      Are you sure you want to reactivate this Group?
                                    </h4>
                                  </div>
                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      class="btn btn-outline-primary black-btn"
                                      onClick={() => {
                                        onCancel();
                                      }}
                                    >
                                      No
                                    </button>
                                    <button
                                      class="btn"
                                      onClick={() => {
                                        onOk();
                                        // this.deleteGroup(groupId);
                                        dispatch(
                                          deleteGroup({
                                            json: JSON.stringify({
                                              Group: [
                                                {
                                                  GroupId: groupId,
                                                  IsActivate: true,
                                                },
                                              ],
                                            }),
                                          }),
                                        );
                                        dispatch(listGroup({}));
                                        // onCancel();
                                        // this.props.onCancel();
                                        // resetEditGroup();
                                        setCreateAndEditGroupModalToFalse();
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          )}
                        </div>
                      )}

                      {groupId > 0 ? (
                        <div>
                          <button type="submit" class="btn">
                            Update
                          </button>
                          &nbsp;
                          <input
                            type="button"
                            class="btn"
                            value="Cancel"
                            onClick={() => {
                              // resetEditGroup();
                              onCancel();
                            }}
                          />
                        </div>
                      ) : (
                        <button type="submit" class="btn">
                          Create Group
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col-6 mb-5 px-1">
              <div class="card border-0 rounded-0">
                <div class="card-body enrollment-form patientGroups-form">
                  <div className="row">
                  <div class="col-12">
                     <h6>Assign Group</h6>
                    </div>
                  </div>
                  <div className="row pb-5 pl-3 pr-3 pt-3">
                    <div class="col-12 col-md-12 col-lg-12">
                      <div class="form-group">
                        <Field
                          name={`groupsName`}
                          type="text"
                          component={ReduxSelect}
                          placeholder={`Group Name*`}
                          options={ServiceCode}
                          fieldName={'group Id'}
                          // disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-12">
                      <div class="form-group">
                        <Field
                          name="searchClient"
                          component={ReduxInput}
                          fieldName={'Search Client'}
                          // disabled={disable}
                        />
                        <label class="placeholder-label">Search Client*</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 text-right">
                      <button type="submit" class="btn" disabled>
                        Assign Group
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          {/* </div> */}
        </form>
      </div>
    </div>
  );
};

class ManageGroupsForm extends Component {
  constructor() {
    super();
    this.state = {
      searchStr: '',
      isActionTriggered: false,
      columns2: [
        {
          title: 'Image',
          dataIndex: 'ProfileImgUrl',
          key: 'ProfileImgUrl',
          render: (text, record) => (
            <div class="student-list-pic">
              <img
                src={text ? text : 'images/announcements-img2.jpg'}
                class="img-circle"
                height="40"
                width="40"
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
            </div>
          ),
        },
        {
          title: 'Patient ID',
          dataIndex: 'ClientId',
          key: 'ClientId',
          sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Patient Name',
          dataIndex: 'DisplayName',
          key: 'DisplayName',
          sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Include',
          dataIndex: 'IsAppointment',
          key: 'IsAppointment',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  title="Click to Include"
                  style={{ color: '#09B66D', cursor: 'pointer' }}
                  onClick={() => {
                    this.props.dispatch(
                      upsertClientGroup({
                        json: JSON.stringify({
                          ClientGroup: [
                            {
                              ClientGroupId: null,
                              GroupId: this.props.groupId,
                              ClientId: record.ClientId,
                              IsAdd: true,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  <img
                    src={'images/circle-plus.svg'}
                    class="img-circle"
                    height="40"
                    width="40"
                    style={{ borderRadius: '50%' }}
                  />
                </span>
              </div>
            );
          },
        },
      ],
      columns3: [
        {
          title: 'Image',
          dataIndex: 'ProfileImgUrl',
          key: 'ProfileImgUrl',
          render: (text, record) => (
            <div class="student-list-pic">
              <img
                src={text ? text : 'images/announcements-img2.jpg'}
                class="img-circle"
                height="40"
                width="40"
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
            </div>
          ),
        },
        {
          title: 'Patient ID',
          dataIndex: 'ClientId',
          key: 'ClientId',
          sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Patient Name',
          dataIndex: 'DisplayName',
          key: 'DisplayName',
          sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Exclude',
          dataIndex: 'IsAppointment',
          key: 'IsAppointment',
          render: (text, record, index) => {
            return (
              <div>
                <AntdModal
                  ModalButton={({ onClick }) => (
                    <span
                      title="Click to Exclude"
                      style={{ color: '#FF3D57', cursor: 'pointer' }}
                      onClick={onClick}
                    >
                      <img
                        src={'images/circle-minus.svg'}
                        class="img-circle"
                        height="40"
                        width="40"
                        style={{ borderRadius: '50%' }}
                      />
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                      <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                        <h4 class="modal-title col-12 mb-3">
                          {`Are you sure you want to remove this patient from ${this.props.groupData.GroupName} Group?`}
                        </h4>
                      </div>
                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          class="btn black-btn"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          No
                        </button>
                        <button
                          class="btn"
                          onClick={() => {
                            this.props.dispatch(
                              upsertClientGroup({
                                json: JSON.stringify({
                                  ClientGroup: [
                                    {
                                      ClientGroupId: record.ClientGroupId,
                                      GroupId: this.props.groupId,
                                      ClientId: record.ClientId,
                                      IsAdd: false,
                                    },
                                  ],
                                }),
                              }),
                            );
                            onOk();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            );
          },
        },
      ],
    };
  }
  resetSearch = () => {
    this.setState({
      searchStr: '',
    });
  };
  patientSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    var data = {
      Client: [
        {
          Search: value,
          GroupId: this.props.groupId,
        },
      ],
    };
    var groupPatientListData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(getListClientGroup(groupPatientListData));

    // Json - {"Client":[{"Search":"SHARMA"}]}
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };

  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.props.dispatch(
        getClientGroup({
          json: JSON.stringify({
            ClientGroup: [
              {
                GroupId: this.props.groupId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(
        getListClientGroup({
          json: JSON.stringify({
            Client: [
              {
                Search: this.state.searchStr,
                GroupId: this.props.groupId,
              },
            ],
          }),
        }),
      );

      this.setState({
        isActionTriggered: true,
      });
    }
  }
  closeModal = () => {
    const { isActionTriggered } = this.state;

    if (isActionTriggered) {
      this.props.dispatch(listGroup({}));
      this.setState({ isActionTriggered: false });
      this.resetSearch();
      this.props.onCancel();
    } else {
      this.resetSearch();
      this.props.onCancel();
    }
  };
  render() {
    const {
      onSubmit,
      handleSubmit,
      dispatch,
      history,
      name,
      disable,
      medicateCombos,
      Practitioner,
      PractitionerOffice,
      initialValues,
      groupId,
      resetForm,
      resetEditGroup,
      isFetching,
      onCancel,
      groupData,
      GroupClientList,
      searchedClient,
    } = this.props;
    const { GroupName, FacilityName, PractitionerName, ServiceCodeText, ClientCount } = groupData;
    const { columns2, columns3 } = this.state;
    return (
      <>
        <div class="modal-content border-0">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={() => {
                this.closeModal();
              }}
            >
              ×
            </button>
            {/* <h4 class="modal-title col-12 text-left">Appointment</h4> */}
            {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
          </div>
          <div class="modal-body rcm-panel">
            <div style={{ textAlign: 'center' }}>
              {/* <h6 style={{ fontSize: '16px', color: 'rgb(18, 196, 117)' }}>{this.state.inviteMsg}</h6>
        <h6 style={{ fontSize: '16px', color: 'rgb(255, 0, 0)' }}>{this.state.errorMessage}</h6> */}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div class="content-area "> */}
              <div className="row">
                <div class="col-12 px-1">
                  {/* <Loader2 loading={this.props.isFetchingGroupClient} /> */}
                  <div class="border-0 rounded-0">
                    <div class="enrollment-form patientGroups-form px-3">
                      <div className="row">
                        <div class="col-12 text-center h4">MANAGE PATIENTS</div>
                        <div class="col-12">
                          <h6>GROUP INFORMATION</h6>
                        </div>
                      </div>
                      <div className="row pb-5 pl-3 pr-3 pt-3 pb-3">
                        <div class="col-6 col-lg-3 text-left">
                          <p style={{ color: '#959595' }}>Group Name</p>
                          <span style={{ color: 'black', fontWeight: '800', fontSize: '18px' }}>
                            {GroupName}
                          </span>
                        </div>
                        <div class="col-6 col-lg-3 text-left">
                          <p style={{ color: '#959595' }}>Office Location</p>
                          <span style={{ color: 'black', fontWeight: '800', fontSize: '18px' }}>
                            {FacilityName}
                          </span>
                        </div>
                        <div class="col-6 col-lg-3 text-left">
                          <p style={{ color: '#959595' }}>Counselor</p>
                          <span style={{ color: 'black', fontWeight: '800', fontSize: '18px' }}>
                            {PractitionerName}
                          </span>
                        </div>
                        <div class="col-6 col-lg-3 text-left">
                          <p style={{ color: '#959595' }}>Service</p>
                          <span style={{ color: 'black', fontWeight: '800', fontSize: '18px' }}>
                            {ServiceCodeText}
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-12">
                        <div class="form-group group-client-search">
                          <input
                            type="text"
                            value={this.state.searchStr}
                            class="form-control shadow-sm"
                            placeholder="Search"
                            onChange={this.patientSearch}
                            ref={ref => (this.input = ref)}
                            style={{
                              paddingLeft: '15px',
                              background: '#FFFFFF',
                              boxShadow: '0px 7px 28px rgba(0, 0, 0, 0.0412478)',
                              borderRadius: '26px',
                            }}
                          />
                          <i
                            class="fas fa-search form-control-icon"
                            style={{ paddingRight: '15px' }}
                          ></i>
                          {/* <i class="far fa-calendar-alt form-control-icon" /> */}
                          {/* <Autocomplete
                            getItemValue={item => {
                              return JSON.stringify(item.ClientId);
                            }}
                            items={this.props.client.map(item => {
                              return {
                                label: ` ${item.FirstName} ( ${item.ClientId} )`,
                                ...item,
                              };
                            })}
                            renderItem={(item, isHighlighted) => (
                              <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                {item.label}
                              </div>
                            )}
                            onChange={this.studentSearch}
                            ref={ref => (this.input = ref)}
                            onSelect={value => {
                              if (this.props.client.length > 0) {
                                let searchResult = this.props.client.filter(item => {
                                  if (item.ClientId == parseInt(value)) {
                                    return item;
                                  } else {
                                    return null;
                                  }
                                });
                                // this.props.setSearchClientValues(searchResult[0]);
                                this.setState({ searchedClient: searchResult[0] });
                              } else {
                                return [];
                              }

                              // this.props.history.push(SearchOption[TabName].pushKey + value);
                            }}
                            value={this.state.searchStr}
                            // wrapperStyle={{ display: 'unset' }}
                            renderInput={props => (
                              <input
                                type="text"
                                {...props}
                                class="form-control shadow-sm search-placeholder"
                                placeholder="Search Patient"
                              />
                            )}
                            menuStyle={{
                              borderRadius: '3px',
                              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                              background: 'rgba(255, 255, 255, 0.9)',
                              // padding: searchStr ? '4px 4px' : '0px 4px',
                              fontSize: '90%',
                              position: 'fixed',
                              overflow: 'auto',
                              maxHeight: '50%',
                              zIndex: 1,
                            }}
                          /> */}
                        </div>
                      </div>

                      <div className="row pb-5 pl-3 pr-3 pt-3 pb-3">
                        {/* <Loader2 loading={this.props.isFetchingGroupClient} /> */}
                        <div class="col-12 col-md-6">
                          <h6>{`Add to ${GroupName} Group`}</h6>
                          <div
                            style={{
                              // border: '1px solid grey',
                              // height: '250px',
                              // overflowY: 'scroll',
                              position: 'relative',
                              // border: '5px solid #f2f8f9',
                              // borderRadius: '4px',
                              // padding: '10px',
                            }}
                          >
                            <Loader2 loading={this.props.isFetchingGroupClient} />
                            <Loader2 loading={this.props.isFetchingGetListClienGroup} />
                            <div style={{ height: '241px', overflowY: 'scroll' }}>
                              <PersistTableSort
                                nmae="searched-client"
                                columns={columns2}
                                dataSource={searchedClient}
                                size={'middle'}
                                pagination={false}
                                // onRow={(record, rowIndex) => {
                                //   return {
                                //     onClick: event => this.onRowClick(record.ClientId),
                                //   };
                                // }}
                                // pagination={{
                                //   itemRender: (page, type, originalElement) => {
                                //     return (
                                //       <div class="dataTables_wrapper no-footer">
                                //         <div class="dataTables_paginate paging_simple_numbers">
                                //           {type == 'prev' ? (
                                //             <a class="paginate_button previous">Previous</a>
                                //           ) : type == 'next' ? (
                                //             <a class="paginate_button next">Next</a>
                                //           ) : (
                                //             <a class="paginate_button ">{page}</a>
                                //           )}
                                //         </div>
                                //       </div>
                                //     );
                                //   },
                                //   pageSize: 3,
                                // }}
                              />
                            </div>

                            {searchedClient &&
                              searchedClient.map(item => {
                                return (
                                  <div
                                    class="align-items-center d-none"
                                    style={{
                                      // borderTop: '1px solid grey',
                                      borderBottom: '1px solid grey',
                                      padding: '10px 5px',
                                      margin: '5px',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      boxShadow:
                                        '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                    }}
                                  >
                                    <div className="d-flex" style={{ flexDirection: 'column' }}>
                                      <div class="student-list-pic d-flex align-items-center pb-2">
                                        <img
                                          src={
                                            item.ProfileImgUrl
                                              ? item.ProfileImgUrl
                                              : 'images/announcements-img2.jpg'
                                          }
                                          class="img-circle"
                                          height="40"
                                          width="40"
                                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                                          alt={item.DisplayName}
                                        />
                                        &nbsp;
                                        <span
                                          style={{
                                            border: '1px solid #faad14',
                                            borderRadius: '20px',
                                            padding: '0 12px',
                                          }}
                                        >
                                          {item.ClientId}
                                        </span>
                                        &nbsp;
                                      </div>
                                      <div>
                                        {' '}
                                        <span>{item.DisplayName}</span>
                                      </div>
                                    </div>
                                    <span
                                      title="Click to Include"
                                      style={{ color: '#09B66D', cursor: 'pointer' }}
                                      onClick={() => {
                                        this.props.dispatch(
                                          upsertClientGroup({
                                            json: JSON.stringify({
                                              ClientGroup: [
                                                {
                                                  ClientGroupId: null,
                                                  GroupId: groupId,
                                                  ClientId: item.ClientId,
                                                  IsAdd: true,
                                                },
                                              ],
                                            }),
                                          }),
                                        );
                                      }}
                                    >
                                      <i class="fas fa-user-plus fa-lg"></i>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <h6>Patients Enrolled</h6>
                          <div
                            style={{
                              // border: '1px solid grey',
                              // height: '250px',
                              // overflowY: 'scroll',
                              position: 'relative',
                              // border: '5px solid #f2f8f9',
                              // borderRadius: '4px',
                              // padding: '10px',
                            }}
                          >
                            <Loader2 loading={this.props.isFetchingGroupClient} />
                            <div style={{ height: '241px', overflowY: 'scroll' }}>
                              <PersistTableSort
                                name="grp-client-list"
                                columns={columns3}
                                dataSource={GroupClientList}
                                size={'middle'}
                                pagination={false}
                                // onRow={(record, rowIndex) => {
                                //   return {
                                //     onClick: event => this.onRowClick(record.ClientId),
                                //   };
                                // }}
                                // pagination={{
                                //   itemRender: (page, type, originalElement) => {
                                //     return (
                                //       <div class="dataTables_wrapper no-footer">
                                //         <div class="dataTables_paginate paging_simple_numbers">
                                //           {type == 'prev' ? (
                                //             <a class="paginate_button previous">Previous</a>
                                //           ) : type == 'next' ? (
                                //             <a class="paginate_button next">Next</a>
                                //           ) : (
                                //             <a class="paginate_button ">{page}</a>
                                //           )}
                                //         </div>
                                //       </div>
                                //     );
                                //   },
                                //   pageSize: 3,
                                // }}
                              />
                            </div>

                            {GroupClientList &&
                              GroupClientList.map(item => {
                                return (
                                  <div
                                    class="align-items-center d-none"
                                    style={{
                                      // borderTop: '1px solid grey',
                                      borderBottom: '1px solid grey',
                                      padding: '10px 5px',
                                      display: 'flex',
                                      margin: '5px',
                                      justifyContent: 'space-between',
                                      boxShadow:
                                        '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                    }}
                                  >
                                    <div className="d-flex" style={{ flexDirection: 'column' }}>
                                      <div class="student-list-pic d-flex align-items-center pb-2">
                                        <img
                                          src={
                                            item.ProfileImgUrl
                                              ? item.ProfileImgUrl
                                              : 'images/announcements-img2.jpg'
                                          }
                                          class="img-circle"
                                          height="40"
                                          width="40"
                                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                                          alt={item.DisplayName}
                                        />
                                        &nbsp;
                                        <span
                                          style={{
                                            border: '1px solid #faad14',
                                            borderRadius: '20px',
                                            padding: '0 12px',
                                          }}
                                        >
                                          {item.ClientId}
                                        </span>
                                        &nbsp;
                                      </div>
                                      <div>
                                        {' '}
                                        <span>{item.DisplayName}</span>
                                      </div>
                                    </div>
                                    <AntdModal
                                      ModalButton={({ onClick }) => (
                                        <span
                                          title="Click to Exclude"
                                          style={{ color: '#FF3D57', cursor: 'pointer' }}
                                          onClick={onClick}
                                        >
                                          <i class="fas fa-user-minus fa-lg"></i>
                                        </span>
                                      )}
                                      ModalContent={({ onOk, onCancel }) => (
                                        <div class="modal-content border-0">
                                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                            <h4 class="modal-title col-12 mb-3">
                                              {`Are you sure you want to remove from ${GroupName} Group?`}
                                            </h4>
                                          </div>
                                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                            <button
                                              class="btn black-btn"
                                              onClick={() => {
                                                onCancel();
                                              }}
                                            >
                                              No
                                            </button>
                                            <button
                                              class="btn"
                                              onClick={() => {
                                                this.props.dispatch(
                                                  upsertClientGroup({
                                                    json: JSON.stringify({
                                                      ClientGroup: [
                                                        {
                                                          ClientGroupId: item.ClientGroupId,
                                                          GroupId: groupId,
                                                          ClientId: item.ClientId,
                                                          IsAdd: false,
                                                        },
                                                      ],
                                                    }),
                                                  }),
                                                );
                                                onOk();
                                              }}
                                            >
                                              Yes
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="row pb-5 pl-3 pr-3 pt-3 pb-3 d-none">
                        {/* <Loader2 loading={this.props.isFetchingGroupClient} /> */}
                        <div class="col-12 col-md-6">
                          <h6>{`Add to ${GroupName} Group`}</h6>
                          <div
                            style={{
                              // border: '1px solid grey',
                              height: '250px',
                              overflowY: 'scroll',
                              position: 'relative',
                              border: '5px solid #f2f8f9',
                              borderRadius: '4px',
                              padding: '10px',
                            }}
                          >
                            <Loader2 loading={this.props.isFetchingGroupClient} />
                            <Loader2 loading={this.props.isFetchingGetListClienGroup} />
                            {searchedClient &&
                              searchedClient.map(item => {
                                return (
                                  <div
                                    class="align-items-center"
                                    style={{
                                      // borderTop: '1px solid grey',
                                      borderBottom: '1px solid grey',
                                      padding: '10px 5px',
                                      margin: '5px',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      boxShadow:
                                        '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                    }}
                                  >
                                    <div className="d-flex" style={{ flexDirection: 'column' }}>
                                      <div class="student-list-pic d-flex align-items-center pb-2">
                                        <img
                                          src={
                                            item.ProfileImgUrl
                                              ? item.ProfileImgUrl
                                              : 'images/announcements-img2.jpg'
                                          }
                                          class="img-circle"
                                          height="40"
                                          width="40"
                                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                                          alt={item.DisplayName}
                                        />
                                        &nbsp;
                                        <span
                                          style={{
                                            border: '1px solid #faad14',
                                            borderRadius: '20px',
                                            padding: '0 12px',
                                          }}
                                        >
                                          {item.ClientId}
                                        </span>
                                        &nbsp;
                                      </div>
                                      <div>
                                        {' '}
                                        <span>{item.DisplayName}</span>
                                      </div>
                                    </div>
                                    <span
                                      title="Click to Include"
                                      style={{ color: '#09B66D', cursor: 'pointer' }}
                                      onClick={() => {
                                        this.props.dispatch(
                                          upsertClientGroup({
                                            json: JSON.stringify({
                                              ClientGroup: [
                                                {
                                                  ClientGroupId: null,
                                                  GroupId: groupId,
                                                  ClientId: item.ClientId,
                                                  IsAdd: true,
                                                },
                                              ],
                                            }),
                                          }),
                                        );
                                      }}
                                    >
                                      <i class="fas fa-user-plus fa-lg"></i>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <h6>Patients Enrolled</h6>
                          <div
                            style={{
                              // border: '1px solid grey',
                              height: '250px',
                              overflowY: 'scroll',
                              position: 'relative',
                              border: '5px solid #f2f8f9',
                              borderRadius: '4px',
                              padding: '10px',
                            }}
                          >
                            <Loader2 loading={this.props.isFetchingGroupClient} />

                            {GroupClientList &&
                              GroupClientList.map(item => {
                                return (
                                  <div
                                    class="align-items-center"
                                    style={{
                                      // borderTop: '1px solid grey',
                                      borderBottom: '1px solid grey',
                                      padding: '10px 5px',
                                      display: 'flex',
                                      margin: '5px',
                                      justifyContent: 'space-between',
                                      boxShadow:
                                        '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                      transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                    }}
                                  >
                                    <div className="d-flex" style={{ flexDirection: 'column' }}>
                                      <div class="student-list-pic d-flex align-items-center pb-2">
                                        <img
                                          src={
                                            item.ProfileImgUrl
                                              ? item.ProfileImgUrl
                                              : 'images/announcements-img2.jpg'
                                          }
                                          class="img-circle"
                                          height="40"
                                          width="40"
                                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                                          alt={item.DisplayName}
                                        />
                                        &nbsp;
                                        <span
                                          style={{
                                            border: '1px solid #faad14',
                                            borderRadius: '20px',
                                            padding: '0 12px',
                                          }}
                                        >
                                          {item.ClientId}
                                        </span>
                                        &nbsp;
                                      </div>
                                      <div>
                                        {' '}
                                        <span>{item.DisplayName}</span>
                                      </div>
                                    </div>
                                    <AntdModal
                                      ModalButton={({ onClick }) => (
                                        <span
                                          title="Click to Exclude"
                                          style={{ color: '#FF3D57', cursor: 'pointer' }}
                                          onClick={onClick}
                                        >
                                          <i class="fas fa-user-minus fa-lg"></i>
                                        </span>
                                      )}
                                      ModalContent={({ onOk, onCancel }) => (
                                        <div class="modal-content border-0">
                                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                            <h4 class="modal-title col-12 mb-3">
                                              {`Are you sure you want to remove from ${GroupName} Group?`}
                                            </h4>
                                          </div>
                                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                            <button
                                              class="btn black-btn"
                                              onClick={() => {
                                                onCancel();
                                              }}
                                            >
                                              No
                                            </button>
                                            <button
                                              class="btn"
                                              onClick={() => {
                                                this.props.dispatch(
                                                  upsertClientGroup({
                                                    json: JSON.stringify({
                                                      ClientGroup: [
                                                        {
                                                          ClientGroupId: item.ClientGroupId,
                                                          GroupId: groupId,
                                                          ClientId: item.ClientId,
                                                          IsAdd: false,
                                                        },
                                                      ],
                                                    }),
                                                  }),
                                                );
                                                onOk();
                                              }}
                                            >
                                              Yes
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 text-right">
                          {/* <button type="submit" class="btn">
                            Update
                          </button>
                          &nbsp; */}
                          <input
                            type="button"
                            class="btn"
                            value="Close"
                            onClick={() => {
                              resetEditGroup();
                              this.closeModal();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
// let ManageGroupsForm = () => {
//   const { ServiceCode } = medicateCombos;
//   return (
//   );
// };

const mapStateToProps = ({
  medicate_combos,
  practitioner_combos,
  practitioner_combos_office,
  list_group,
  upsert_group,
  get_patient_list,
  get_client_group,
  upsert_client_group,
  get_list_client_group,
  delete_group,
  get_combo_facility,
  default_values,
}) => {
  const { data: medicateCombos } = medicate_combos;
  const { data: practitionerData } = practitioner_combos;
  const { data: practitionerOfficeData } = practitioner_combos_office;
  const { data: listgroup, isFetching } = list_group;
  const { data: getPatientListData } = get_patient_list;
  const { data: getClientListData, isFetching: isFetchingGroupClient } = get_client_group;
  const { data: deleteGroupStatus } = delete_group;
  const {
    data: getListClientGroup,
    isFetching: isFetchingGetListClienGroup,
  } = get_list_client_group;
  const { data: ComboFacilityData } = get_combo_facility;

  let Client = [];
  let GroupClientList = [];
  let isSuccess = false;
  let medicateCombosObj = {
    ServiceCode: [],
  };
  let Practitioner = [];
  let PractitionerOffice = [];
  let ListGroup = [];
  let isGroupCreated = false;
  let searchedClient = [];
  let deleteGroupStatusValue = false;
  let isReadOnlyUser = false;
  if (getClientListData) {
    GroupClientList = getClientListData.ClientGroup;
  }
  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList;
  }
  // if (practitionerOfficeData) {
  //   PractitionerOffice = practitionerOfficeData.PractitionerOfficeList || [];
  // }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
    };
  }

  if (listgroup) {
    ListGroup = listgroup.Group;
  }
  if (upsert_group && upsert_group.data && upsert_group.data.success) {
    isGroupCreated = upsert_group.data.success;
  }
  if (getPatientListData && getPatientListData.data) {
    Client = JSON.parse(getPatientListData.data)[0].Client;
  }
  if (upsert_client_group.data && upsert_client_group.data.success) {
    isSuccess = upsert_client_group.data.success;
  }
  if (getListClientGroup) {
    searchedClient = getListClientGroup.Client || [];
  }
  if (deleteGroupStatus && deleteGroupStatus.success) {
    deleteGroupStatusValue = deleteGroupStatus.success;
  }
  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  return {
    medicateCombosObj: medicateCombosObj,
    Practitioner: Practitioner,
    PractitionerOffice: PractitionerOffice,
    ListGroup,
    isGroupCreated,
    isFetching,
    isFetchingGroupClient,
    isFetchingGetListClienGroup,
    client: Client || [],
    GroupClientList,
    isSuccess,
    searchedClient,
    deleteGroupStatusValue,
    isReadOnlyUser,
  };
};
const ReduxPatientGroups = reduxForm({
  form: 'patientGroupsForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(PatientGroupsForm)));

const ReduxManageGroups = reduxForm({
  form: 'manageGroupsForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(ManageGroupsForm)));

export default connect(mapStateToProps)(withRouter(PatientGroups));
