import React, { Component } from 'react';
// import RiskChart from '../../../EnterpriseClient/RiskChart';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import RiskChart from './RiskChart';

class ClientReports extends Component {
  constructor(props) {
    super(props);
  }
    render() {
        const { Milestone } = this.props;
    return (
      <div>
        <RiskChart ClientId={this.props.clientId} SurveyId={253} viewHistory={this.viewHistory} />
        {/* <h5 className="mt-5"> Milestones - {OverAllProgress} in Progress</h5> */}
        {/* <div className="card border-0 p-4">
          {Milestone &&
            Milestone.map((item, index) => {
              return (
                <div
                  style={{
                    width: '100%',
                    backgroundColor: '#F8F8F8',
                    padding: '20px',
                    border: ' 1px solid #EAEAEA',
                    marginBottom: '15px',
                  }}
                >
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="client-detail-box p-0 m-0">
                      <p className="client-label mb-0">Milestone {index + 1}*</p>
                      <p className="client-text mb-0">{item.Milestone && item.Milestone}</p>
                    </div>

                    <p className={`mb-0 client-info-text ${item.ProgressClassName}`}>
                      {item.StatusText}
                    </p>
                  </div>
                  <div className="d-flex row">
                    <div className="d-flex col-10 mt-3">
                      <div className="client-detail-box col-3 p-0 m-0">
                        <p className="client-label mb-0">Duration*</p>
                        <p className="client-text mb-0">{item.Duration && item.Duration}</p>
                      </div>
                      <div className="client-detail-box col-3 p-0 m-0">
                        <p className="client-label mb-0">Progress*</p>
                        <p className="client-text mb-0">{item.Progress && item.Progress}</p>
                      </div>
                    </div>
                    
                  </div>
                </div>
              );
            })}
        </div> */}
      </div>
    );
  }
}

// const mapStateToProps = ({
//   smart_contract_get,
//   payer_price_details_get,
//   payer_portal_request_upsert,
//   combos,
//   risk_chart_get,
// }) => {
//   const { data: smartContractDetails, isFetching } = smart_contract_get;

  
//   let Milestone = [];
//   const { data: RiskChartData } = risk_chart_get;

  

  

//   if (smartContractDetails && smartContractDetails.SmartContract) {
    

//     Milestone =
//       smartContractDetails.SmartContract[0] && smartContractDetails.SmartContract[0].Goal 
//         ? smartContractDetails.SmartContract[0].Goal
//         : [];
//   }

//   return {
//     Milestone,
//   };
// };


export default ClientReports;

