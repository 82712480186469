import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _blank from '../../images/blank-profile.png';
import { clientBannerGet } from '../../store/actions';

class ClientProfileHeaderInPayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentDidMount() {
   
    this.props.dispatch(
      clientBannerGet({
        json: JSON.stringify({
          Client: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );

  }
  render() {
    const { profileBanner } = this.props;
    const {
      ClientFullName,
      AttachmentUrl,
      State,
      City,
      Status,
      CenterName,
      ContractStatus,
      ProfileImgAttachmentUrl,
    } = profileBanner;
    return (
      <div className="row card-body align-items-center" style={{margin: '18px', padding: '0'}}>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: '100%', backgroundColor: '#F8F8F8', padding: '20px' }}
        >
          <div className="d-flex align-items-center">
            <img
              src={ProfileImgAttachmentUrl || 'images/blank-profile.png'}
              style={{ width: '60px', height: '60px', borderRadius: '8px' }}
            />
            <div style={{ padding: '10px' }}>
              <p className="mb-0 payer-client-name">{ClientFullName && ClientFullName}</p>
              {/* <p className="mb-0 payer-client-center">{CenterName && CenterName}</p> */}
            </div>
          </div>

          <div className="client-details d-flex">
            {/* {clientInfo &&
              clientInfo.map(item => {
                return ( */}
            <div className="client-detail-box">
              <p className="client-label mb-0">Center</p>
              <p className="client-text mb-0">{CenterName && CenterName}</p>
            </div>
            <div className="client-detail-box">
              <p className="client-label mb-0">State</p>
              <p className="client-text mb-0">{State && State}</p>
            </div>
            <div className="client-detail-box">
              <p className="client-label mb-0">City</p>
              <p className="client-text mb-0">{City && City}</p>
            </div>
            <div className="client-detail-box">
              <p className="client-label mb-0">Status</p>
              <p className="client-text mb-0">{ContractStatus && ContractStatus}</p>
            </div>
            {/* );
              })} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ client_banner_get }) => {
  let { data: bannerDetails } = client_banner_get;

  let profileBanner = [];

  if (bannerDetails && bannerDetails.Banner) {
    profileBanner = bannerDetails.Banner[0];
  }
  return { profileBanner };
};

export default connect(mapStateToProps)(withRouter(ClientProfileHeaderInPayer));
