import React, { Component } from 'react';
import { isEmpty } from 'lodash';

import { Table } from 'antd';

class PersistTableSort extends Component {
  constructor() {
    super();
    this.state = {
      finalColumns: [],
      // defaultCurrent: 1,
      // defaultPageSize: 50,
    };
  }

  setPageNoAndPageSize = () => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);

    if (!pageFilter) return;

    if (!JSON.parse(pageFilter)[`table_${this.props.name}`]) return;
    if (
      JSON.parse(pageFilter)[`table_${this.props.name}`] &&
      JSON.parse(pageFilter)[`table_${this.props.name}`].lastPageNoAndPageSize
    ) {
      let defaultCurrent = JSON.parse(pageFilter)[`table_${this.props.name}`].lastPageNoAndPageSize
        .pageNumber;
      let defaultPageSize = JSON.parse(pageFilter)[`table_${this.props.name}`].lastPageNoAndPageSize
        .pageSize;

      this.setState({
        defaultCurrent,
        defaultPageSize,
      });
    }
  };
  getFinalColumns = () => {
    let sortInfo;
    let newColumns;
    let sortInfofromSession = sessionStorage.getItem(`${this.props.name}_sortInfo`);
    if (!isEmpty(sortInfofromSession)) {
      sortInfo = JSON.parse(sortInfofromSession);

      newColumns =
        !isEmpty(this.props.columns) &&
        this.props.columns.map(v => {
          if (v.key === sortInfo.sortKey) {
            return {
              ...v,
              sortOrder: sortInfo.sortOrder,
            };
          } else return v;
        });
    } else {
      newColumns = [...this.props.columns];
    }
    this.setState({ finalColumns: newColumns });
    // this.forceUpdate()
  };

  componentDidMount() {
    this.getFinalColumns();
    this.setPageNoAndPageSize();
  }

  handleTableChange = (pagination, filters, sorter) => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }
    // console.log('Various parameters', pagination, filters, sorter);
    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        lastPageNoAndPageSize: {
          pageNumber: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
    };
    this.setState({ defaultCurrent: pagination.current, defaultPageSize: pagination.pageSize });
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));

    sessionStorage.setItem(
      `${this.props.name}_sortInfo`,
      `{
        "sortKey": "${sorter.columnKey}",
        "sortOrder": "${sorter.order}"
    }`,
    );
    this.getFinalColumns();
  };
  showTotal = total => {
    return `Total ${total} items`;
  };

  render() {
    return (
      <div className="persist-sort-table">
        <Table
          {...this.props}
          columns={this.state.finalColumns}
          onChange={this.handleTableChange}
          locale={this.props.locale}
          loading={this.props.loader}
          pagination={{
            itemRender: (page, type, originalElement) => {
              return (
                <div class="dataTables_wrapper no-footer">
                  <div class="dataTables_paginate paging_simple_numbers">
                    {type == 'prev' ? (
                      <a class="paginate_button previous">Previous</a>
                    ) : type == 'next' ? (
                      <a class="paginate_button next">Next</a>
                    ) : (
                      <a class="paginate_button ">{page}</a>
                    )}
                  </div>
                </div>
              );
            },
            showSizeChanger: true,

            current: this.state.defaultCurrent,
            pageSize: this.state.defaultPageSize,
            defaultPageSize: 50,
            // showTotal: this.showTotal,
            // hideOnSinglePage: true,
          }}
        />
      </div>
    );
  }
}

export default PersistTableSort;
