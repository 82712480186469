import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../../validation/validate';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { mapTime } from '../../../../../util/time';
import SearchPatient from '../../../../../Components/searchPatient';
import { withRouter } from 'react-router-dom';
import {
  ReduxInput,
  ReduxCheckbox,
  ReduxSelect,
  ReduxDateInput,
  ReduxTimePicker,
  ReduxToggle,
  ReduxTextarea,
  ReduxAutoComplete,
  ReduxAutoCompleteText,
} from '../../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import {
  practitionerCombos,
  upsertMedicationLog,
  getMedicationLog,
} from '../../../../../store/actions';
// import { ProcessCombos } from '../../../../../store/comboConfig';
// import Loader, { Loader2 } from '../../../../../Components/Loader';

class MedicationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      medicationOptions: this.props.Medication
    };
  }
  componentDidMount() {
    this.props.dispatch(practitionerCombos({}));
    const data = {
      MedicationLog: [
        {
          MedicationLogId: this.props.MedicationLogId,
        },
      ],
    };
    this.props.dispatch(
      getMedicationLog({
        Json: JSON.stringify(data),
      }),
    );
  }
  setSearchClientValues = data => {
    this.props.updateInitValuesForExistingPaitents(data);
  };

  getMedicationFormValues = values => {
    const {
      clientName,
      prescriber,
      medication,
      dosage,
      frequency,
      route,
      instruction,
      drugInteractionResponse,
      daysSupply,
      startDate,
      endDate,
      intakeTime,
      notes,
    } = values;
    const data = {
      MedicationLog: [
        {
          MedicationLogId: this.props.MedicationLogId,
          ClientId: this.props.ClientId,
          MedicationId: this.state.medicationOptions.filter(item => item.Text == medication ? item.Value : undefined)[0].Value,
          // medication,
          PrescriberId: prescriber,
          DosageId: dosage,
          Frequency: frequency,
          RouteId: route,
          Instruction: instruction,
          DrugInteractionResponseId: drugInteractionResponse,
          StartDate: moment(startDate).format('MM/DD/YYYY'),
          StopDate: moment(endDate).format('MM/DD/YYYY'),
          IntakeTime: intakeTime,
          // IntakeTime:
          //   typeof intakeTime == 'object'
          //     ? intakeTime.map(item => item.Value).toString()
          //     : intakeTime || '',
          Notes: notes,
          DaysSupply: daysSupply,
          // IsArchived: '',
        },
      ],
    };

    var jsonData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(upsertMedicationLog(jsonData));
    this.props.dispatch(reset('medicationForm'));
  };

  // componentWillReceiveProps({ isUpsert }) {}

  render() {
    const {
      handleSubmit,
      onCancel,
      MedicationLogId,
      Practitioner,
      Medication,
      FrequencyOfUse,
      Dosage,
      DrugInteractionResponse,
      Route,
      TimeSlot,
    } = this.props;
    // debugger;
    return (
      <React.Fragment>
        <div className="container mt-5 enrollment-form">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
              ×
            </button>
            <h4 class="modal-title col-12 text-left">
              {MedicationLogId ? 'Update Medication' : 'Add Medication'}
            </h4>
          </div>
          <div class="modal-body rcm-panel enrollment-form">
            <form onSubmit={handleSubmit(this.getMedicationFormValues)}>
              <div class="row mb-4">
                {/* <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`clientName`}
                      component={ReduxInput}
                      // validate={validate.required}
                      fieldName={' Client Name'}
                      disabled
                    />
                    <label class="placeholder-label">Client Name</label>
                  </div>
                </div> */}
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`prescriber`}
                      type="text"
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'prescriber'}
                      options={Practitioner}
                      placeholder="Prescriber"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`medication`}
                      type="text"
                      component={ReduxAutoCompleteText}
                      // validate={validate.required}
                      fieldName={'medication'}
                      options={Medication}
                      // placeholder="Medication"
                      label={'Medication'}

                    />
                  </div>
                </div>

                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`dosage`}
                      type="text"
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'dosage'}
                      options={Dosage}
                      placeholder="Dosage"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`frequency`}
                      type="text"
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'frequency'}
                      options={FrequencyOfUse}
                      placeholder="Frequency"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`route`}
                      type="text"
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'route'}
                      options={Route}
                      placeholder="Route"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`drugInteractionResponse`}
                      type="text"
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'drugInteractionResponse'}
                      options={DrugInteractionResponse}
                      placeholder="Drug Interaction Response"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <Field
                      name={`instruction`}
                      type="textarea"
                      component={ReduxInput}
                      // validate={validate.required}
                      fieldName={' Instruction'}
                      placeholder="Instruction..."
                      showPlaceHolder={true}
                    />
                    <label class="placeholder-label">Instruction</label>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="startDate"
                      type="text"
                      component={ReduxDateInput}
                      errorMessage="Start Date Required"
                      // validate={Validator.required}
                      label={'Start Date'}
                      // minDate={new Date(2016, 0, 1)}
                    />
                    <i class="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name="endDate"
                      type="text"
                      component={ReduxDateInput}
                      errorMessage="End Date Required"
                      // validate={Validator.required}
                      label={'End Date'}
                      // minDate={new Date(2016, 0, 1)}
                    />
                    <i class="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>

                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`daysSupply`}
                      type="text"
                      component={ReduxInput}
                      // validate={validate.required}
                      fieldName={'daysSupply'}
                      placeholder="Days Supply"
                    />
                    <label class="placeholder-label">Days Supply</label>
                  </div>
                </div>
                <div class="col-6 col-md-6 col-lg-6">
                  <div class="form-group">
                    <Field
                      name={`intakeTime`}
                      type="text"
                      component={ReduxSelect}
                      // validate={validate.required}
                      fieldName={'Time Slot'}
                      options={TimeSlot}
                      placeholder="Please Select TimeSlot"
                      // isMulti={true}
                    />
                  </div>
                </div>
                <div class="col-4 col-md-12 col-lg-12">
                  <div class="form-group">
                    <Field
                      name="notes"
                      type="textarea"
                      component={ReduxTextarea}
                      fieldName={'notes'}
                      style={{
                        minHeight: '100px',
                        borderRadius: '12px',
                        border: 'none',
                        padding: '10px',
                      }}
                      showPlaceHolder={true}
                    />
                    <label class="placeholder-label">Notes</label>
                  </div>
                </div>
                <div class="col-12 text-right">
                  <input type="submit" class="btn px-5" value={'Submit'} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { combos, practitioner_combos, get_medication_log, upsert_medication_log } = state;
  const { data } = get_medication_log;
  const { data: practitionerData } = practitioner_combos;

  let Medication = [],
    FrequencyOfUse = [],
    Dosage = [],
    DrugInteractionResponse = [],
    TimeSlot = [],
    Route = [];
  let Practitioner = [];
  if (combos.data && combos.data['Medication']) {
    Medication = combos.data.Medication;
    FrequencyOfUse = combos.data.FrequencyOfUse;
    Dosage = combos.data.Dosage;
    DrugInteractionResponse = combos.data.DrugInteractionResponse;
    Route = combos.data.Route;
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner = practitionerData.PractitionerList || [];
  }
  if (data && data.TimeSlotCombo) {
    TimeSlot = data.TimeSlotCombo;
  }

  return {
    Practitioner: Practitioner,
    Medication,
    FrequencyOfUse,
    Dosage,
    DrugInteractionResponse,
    Route,
    TimeSlot,
  };
};

const ReduxMedicationForm = reduxForm({
  form: 'medicationForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(MedicationForm)));

export default ReduxMedicationForm;
