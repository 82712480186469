import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import AntdModal from '../Components/CustomModal';
import Autocomplete from 'react-autocomplete';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  submit,
} from 'redux-form';
import {
  announcementGetJson,
  announcementUpsert,
  resetAnnouncementUpsert,
  announcementGetJsonReset,
  announcementDelete,
  resetAnnouncementDelete,
  listCombos,
} from '../store/actions';
import PageTitle from '../Components/PageTitle';
import moment from 'moment';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';
import SubmitButton from '../Components/submitButton';
//import { listCombos } from '../store/sagas/combo';

class ActivityBase extends React.Component {}

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var startDate = values.startDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 > date1) {
    } else {
      return ' has to be greater than Start Date';
    }
  },
};

class Announcementform extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      announcementData: {},
      isModalOpen: false,
      header: '',
      isRemoveModelOpen: false,
    };
  }

  getAnnouncementId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var announcementId = param.get('announcementId') || param.get('announcementId');
    if (announcementId) {
      return (announcementId = parseInt(announcementId));
    } else {
      announcementId = -1;
    }
    return announcementId;
  }

  getStringType = data => {
    var array = [];
    if (data) for (let item of data) array.push(Object.values(item)[0]);
    return array.toString();
  };

  componentWillReceiveProps({ announcementData, isInserted, isRemoved }) {
    if (isInserted) {
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(resetAnnouncementUpsert());
    }
    if (isRemoved) {
      this.toggle(Delete_Sucess_Msg);
      this.props.dispatch(resetAnnouncementDelete());
    }
    if (announcementData && announcementData.AnnouncementId && this.getAnnouncementId() > 0) {
      var announcement = {
        abstract: announcementData.Abstract,
        announcementId: announcementData.AnnouncementId,
        centerId: announcementData.CenterId,
        endDate: announcementData.EndDate,
        imageUrl: announcementData.ImageUrl,
        isMain: announcementData.IsMain,
        startDate: announcementData.StartDate,
        tags: announcementData.Tags,
        title: announcementData.Title,
        updatedBy: announcementData.UpdatedBy,
        link: announcementData.Link,
      };
      this.setState({ announcementData: announcement });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );

    if (this.getAnnouncementId() > 0) {
      this.props.dispatch(announcementGetJson({ announcementId: this.getAnnouncementId() }));
    } else {
      this.props.dispatch(announcementGetJsonReset());
    }
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  onSubmit(values) {
    //alert(JSON.stringify(values));
    const {
      abstract,
      centerId,
      endDate,
      imageUrl,
      isMain,
      startDate,
      tags,
      title,
      updatedBy,
      link,
    } = values;

    var announcementId = null;

    var paramAnnouncementId = this.getAnnouncementId();
    if (paramAnnouncementId && paramAnnouncementId > 0) {
      announcementId = paramAnnouncementId;
    }

    var data = {
      Announcement: [
        {
          Abstract: abstract,
          AnnouncementId: announcementId,
          CenterId: centerId,
          EndDate: moment(endDate).format('YYYY-MM-DD'),
          ImageUrl: imageUrl,
          IsMain: isMain,
          StartDate: moment(startDate).format('YYYY-MM-DD'),

          Tags: tags,
          Title: title,
          UpdatedBy: updatedBy,
          Link: link,
        },
      ],
    };
    var announcementData = {
      AnnouncementId: announcementId,
      jsonInput: JSON.stringify(data),
    };
    this.props.dispatch(announcementUpsert(announcementData));
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle TitleText="Announcement" />
        <ReduxAnnouncementForm
          onSubmit={this.onSubmit.bind(this)}
          initialValues={this.state.announcementData}
          getAnnouncementId={this.getAnnouncementId()}
          onCancel={() => this.props.history.push('/manage/announcements')}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/announcements')
          }}
          history={this.props.history}
          isFetching={this.props.isFetching}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/announcements');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            this.props.dispatch(announcementDelete({ AnnouncementId: this.getAnnouncementId() }));
            // this.props.history.push('/manage/announcements');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            this.props.history.push('/manage/announcements');
          }}
        />
      </React.Fragment>
    );
  }
}

let EnrollmentForm = ({
  onSubmit,
  handleSubmit,
  center,
  getAnnouncementId,
  onCancel,
  Delete,
  dispatch,
  title,
  history,
  isFetching,
}) => {
  return (
    <div>
      <div class="main enrollment-page  mb-5">
        <div class="container-fluid">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="row  mb-5">
              <div class="col-12 mb-4">
                <div class="row top-heading-row mt-5 mb-3 align-items-center">
                  <div class="col-12 col-md-4 col-lg-3">
                    <h3 class="form-main-head mb-3 mb-md-0">
                      {getAnnouncementId > 0 ? 'Edit Announcement' : 'Add a Announcement'}
                    </h3>
                  </div>
                  <div class="col-12 col-md-8 col-lg-9 text-right">
                    <button onClick={onCancel} class="btn  btn-outline-primary mr-2">
                      Cancel
                    </button>
                    <SubmitButton loading={isFetching} value={'Save'}  />
                  </div>
                </div>
                <div class="card border-0 rounded-0 ">
                  <div class="card-body enrollment-form">
                    <div class="row pb-5 pl-3 pr-3 pt-3">
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field name="abstract" type="textarea" component={ReduxInput} />
                          <label class="placeholder-label">Abstract</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="centerId"
                            type="text"
                            component={ReduxSelect}
                            options={center}
                            placeholder="Center*"
                            validate={Validator.required}
                            fieldName={'Center'}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="imageUrl"
                            type="text"
                            errorMessage="Permanent Address Line 1 Required"
                            component={ReduxInput}
                            // validate={Validator.required}
                            fieldName={'Image URL'}
                          />
                          <label class="placeholder-label">Image URL</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="custom-control-label" for="is-emergency-contact">
                            <Field name="isMain" type="text" component={ReduxToggle} />
                            {'   '}
                            Lead Story
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="startDate"
                            type="text"
                            label="Start Date"
                            errorMessage="Start Date Line 1 Required"
                            component={ReduxDateInput}
                            // validate={Validator.required}
                          />
                          <i class="far fa-calendar-alt form-control-icon" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="endDate"
                            label="End Date*"
                            type="text"
                            component={ReduxDateInput}
                            validate={Validator.endDate}
                            fieldName={'End Date'}
                          />
                          <i class="far fa-calendar-alt form-control-icon" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="tags"
                            type="text"
                            errorMessage="Tags Required"
                            component={ReduxInput}
                          />
                          <label class="placeholder-label">Tags</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="title"
                            type="text"
                            errorMessage="Title Required"
                            component={ReduxInput}
                          />
                          <label class="placeholder-label">Title</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field name="link" type="text" component={ReduxInput} />
                          <label class="placeholder-label">Link</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-right">
                {getAnnouncementId > 0 && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <a
                        class="deactivate-link"
                        data-toggle="modal"
                        data-target="#deactivate-staff-model"
                        onClick={onClick}
                      >
                        <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                      </a>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to Deactivate <br />
                            <strong>{title}</strong> announcement?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn black-btn"
                            onClick={() => {
                              onCancel();
                              history.push('/manage/announcements');
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              dispatch(announcementDelete({ AnnouncementId: getAnnouncementId }));
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { combos } = state;
  let center = [];

  if (combos.data) {
    const { Center } = combos.data;
    if (Center) {
      center = Center;
    }
  }

  const title = selector(state, 'title');

  return {
    center,
    title,
  };
};

const ReduxAnnouncementForm = reduxForm({
  form: 'Announcementform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(EnrollmentForm)));

const selector = formValueSelector('Announcementform');

const mapStateToPropsParent = ({
  announcement_get_json,
  announcement_upsert,
  announcement_delete,
}) => {
  let announcementData = {};
  var isInserted = false;
  var isRemoved = false;
  var recordInfo = null;
  const { isFetching } = announcement_upsert;
  if (announcement_get_json && announcement_get_json.data) {
    announcementData = announcement_get_json.data;
  }
  if (announcement_upsert && announcement_upsert.data && announcement_upsert.data.success) {
    isInserted = true;
    recordInfo = announcement_upsert.data;
  }
  if (announcement_delete && announcement_delete.data && announcement_delete.data.success) {
    isRemoved = true;
    recordInfo = announcement_delete.data;
  }
  return { announcementData, isInserted, recordInfo, isRemoved, isFetching };
};

export default connect(mapStateToPropsParent)(Announcementform);
