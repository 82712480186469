import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { Tabs, Modal, Popover, Drawer, Tooltip } from 'antd';
import DynamicGrid from '../../../Components/DynamicGrid';
import {
  getClientCounselor,
  listCombos,
  getDynamicComos,
  prospectArchive,
  resetProspectArchive,
} from '../../../store/actions';
import { ProcessCombos } from '../../../store/comboConfig';
import { CommonLicenceScreens } from '../../licenceScreens';
import ComingSoon from '../../../component/comingSoonPage';
import AntdModal from '../../../Components/CustomModal';
import AddProspectNote from './AddNoteModal';
import CustomModal from '../../../core/modal';
import PageTitle from '../../../Components/PageTitle';

class PreIntakeList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );
    var result = ProcessCombos('ClientType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let comboTypes = {
      ComboTypes: 'CenterServiceTypeCenter',
      Json: JSON.stringify({
        Combo: [
          {
            StudentId: null,
            StudentCenterId: null,
          },
        ],
      }),
    };
    this.props.dispatch(getDynamicComos(comboTypes));
    this.props.dispatch(
      getClientCounselor({
        CenterId: this.state.filterCenterId,
      }),
    );
  }
  componentWillReceiveProps({ isArchivedSuccess, successMsg }) {
    if (isArchivedSuccess && isArchivedSuccess != this.props.isArchivedSuccess) {
      this.toggle(successMsg);
      this.props.dispatch(resetProspectArchive({}));
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  getPopOverData = record => {
    return (
      <div className="sort-by-list">
        <p
          onClick={() => {
            this.props.history.push(
              `/pre-intake-form-legacy/?studentid=${record.StudentId}&surveyid=${record.SurveyId}`,
            );
          }}
        >
          Edit Details
        </p>
        <p
          onClick={() => {
            this.props.history.push(
              `/clients/pre-intake/dashboard-legacy?studentid=${record.StudentId}`,
            );
          }}
        >
          View Details
        </p>
        <p
          onClick={() => {
            this.setState({
              isNoteAddModal: true,
              StudentId: record.StudentId,
            });
          }}
        >
          Add Note
        </p>
        <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#move-intake-model">
              Move to Intake
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">
                  Are you sure you want to move to Intake?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    let data = {
                      Json: JSON.stringify({
                        Student: [
                          {
                            StudentId: record.StudentId,
                            EnrollStatusId: 0,
                          },
                        ],
                      }),
                    };
                    Axios.post('Student/StudentEnrollStatusUpdate', data)
                      .then(response => {
                        if (response.data.success) {
                          this.props.history.replace(`/enrollment/?studentid=${record.StudentId}`);
                        }
                      })
                      .catch(function(error) {
                        console.log(error);
                      });
                    onOk();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
        <AntdModal
          ModalButton={({ onClick }) => (
            <p class="" onClick={onClick}>
              {record.IsPreIntakeArchived ? 'Restore' : 'Archive'}
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                  &times;
                </button>
                <h4 className="modal-title col-12">
                  Are you sure you want to {record.IsPreIntakeArchived ? 'restore' : 'archive'} this
                  prospect?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  type="button"
                  className="btn btn-outline-primary py-1"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn py-1"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      prospectArchive({
                        Json: JSON.stringify({
                          Student: [
                            {
                              StudentId: record.StudentId,
                              Archive: !record.IsPreIntakeArchived,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
      </div>
    );
  };
  render() {
    const { preIntakeStatusData } = this.props;
    const { isNoteAddModal } = this.state;

    return (
      <div class="container-fluid">
        <PageTitle TitleText="Prospect" />
        <div class="content-area">
          {' '}
          <CommonLicenceScreens StatusData={preIntakeStatusData} moduleName={'PreIntake'} />
        </div>

        {preIntakeStatusData && preIntakeStatusData.LicenseStatusId == 6 ? (
          <div>
            <div class="content-area">
              {' '}
              <ComingSoon />
            </div>

            <div className="pb-5"></div>
          </div>
        ) : (
          <>
            {preIntakeStatusData &&
              preIntakeStatusData.LicenseStatusId != 1 &&
              preIntakeStatusData.LicenseStatusId != 3 &&
              preIntakeStatusData.LicenseStatusId != 5 && (
                <>
                  <div class="content-area">
                    <div class="row top-table-search my-3 align-items-center">
                      <div class="col-12 col-md-4 col-lg-3">
                        <h3 class="page-title mb-3 mb-md-0">Prospect List</h3>
                      </div>
                    </div>
                    <div />
                    <div class="row">
                      <div class="col-12 mb-5">
                        <div
                          class="card border-0 "
                          style={{ backgroundColor: 'unset', boxShadow: 'none' }}
                        >
                          <div className="dynamic-grid">
                            <DynamicGrid
                              ScreenName={'PreIntake'}
                              FilterBy={{ IsPreIntakeList: true }}
                              getPopOverData={this.getPopOverData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}{' '}
          </>
        )}
        {isNoteAddModal && (
          <Modal visible={isNoteAddModal} footer={null} closable={false} destroyOnClose={true}>
            <AddProspectNote
              StudentId={this.state.StudentId}
              onCancel={() => {
                this.setState({
                  isNoteAddModal: false,
                });
              }}
              IsProspectNote={true}
            />
          </Modal>
        )}
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            // this.props.onCancel();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user_feature_flag_get }) => {
  const { data: preIntakeStatus } = user_feature_flag_get;

  let preIntakeStatusData = {};

  if (
    preIntakeStatus &&
    preIntakeStatus.FeatureFlag &&
    preIntakeStatus.FeatureFlag.length > 0 &&
    preIntakeStatus.FeatureFlag[0]
  ) {
    preIntakeStatusData =
      (preIntakeStatus.FeatureFlag &&
        preIntakeStatus.FeatureFlag[0] &&
        preIntakeStatus.FeatureFlag[0].License &&
        preIntakeStatus.FeatureFlag[0].License.length > 0 &&
        preIntakeStatus.FeatureFlag[0].License.filter(item => item.ModuleName == 'PreIntake')[0]) ||
      {};
  }
  return {
    preIntakeStatusData,
  };
};

export default connect(mapStateToProps)(withRouter(PreIntakeList));
