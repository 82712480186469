import React from 'react';
import Autocomplete from 'react-autocomplete';

const ReduxAutoCompleteText = ({
  input,
  name,
  id,
  value,
  onChange,
  onSelect,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  errorMessage,
  className,
  disabled,
  maxLength,
  tabIndex,
  options,
  label,
}) => {
  var { touched, error, warning, active } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onSelect = input.onSelect;
    onBlur = input.onBlur;
  }

  var items = [];

  if (options) {
    for (var option of options) {
      items.push({
        Value: (option.Value && option.Value.toString()) || option.Value,
        Text: option.Text,
      });
    }
  }

  return (
    <React.Fragment>
      {label && (
        <label
          class="placeholder-label datepicker-label"
          style={{
            zIndex: 1,
            fontSize: !!disabled || value || active ? '11px' : '16px',
            top: !!disabled || value || active ? '-5px' : '10px',
          }}
        >
          {label}
        </label>
      )}
      <Autocomplete
        {...input}
        wrapperStyle={{ display: 'block' }}
        menuStyle={{
          borderRadius: '3px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '2px 0',
          fontSize: '90%',
          position: 'fixed',
          overflow: 'auto',
          maxHeight: '300px',
          zIndex: 10,
          position: 'absolute',
          left: 'unset',
          top: 'unset',
          // width:'fit-content'
        }}
        autocomplete="none"
        tabIndex={tabIndex}
        name={name}
        id={id}
        defaultValue={defaultValue}
        value={value.toString()}
        onChange={onChange}
        onSelect={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        disabled={!!disabled}
        maxLength={maxLength}
        shouldItemRender={(item, value) => {
          return item.Text.toLowerCase().indexOf(value.toString().toLowerCase()) > -1;
        }}
        renderInput={props => <input {...props} disabled={!!disabled} className={'form-control'} />}
        getItemValue={item => {
          // if (item && item.Value) {
          //   sessionStorage.setItem('medicalCode', item.Value);
          // }
          return item.Text;
        }}
        items={items}
        renderItem={(item, isHighlighted) => (
          <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>{item.Text}</div>
        )}
      />
      {touched &&
        ((error && <span className="error-text">{errorMessage}</span>) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </React.Fragment>
  );
};

export { ReduxAutoCompleteText };
