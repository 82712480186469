import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { StudentImage } from '../../../../component/student';
import Loader, { Loader2 } from '../../../../Components/Loader';
import '../../../../component/jquery/slick.js';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Table, Button } from 'antd';
import AntdModal from '../../../../Components/CustomModal';
import QuestionType from './questionType/questionComponent';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';
import ValidatePin from '../../../../Components/validatePinModal';
import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  listSurveyQuestionCategoryAssessment,
  surveyAssign,
  listSurveyQuestionAssessment,
  deactivateClientScreening,
  markAsComplete,
} from '../../../../store/actions';
import { Link } from 'react-router-dom';
import moment from 'moment';

const SURVEYTYPES = {
  Binary: 1,
  Options: 2,
  Numeric: 3,
  AlphaNumeric: 4,
  SmileyType: 5,
  Currency: 6,
};

class Survey extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      SurveyQuestionCategoryListData: [],
      assesmentId: '',
      screeningView: true,
      surveyListData: [],
      surveyQuestionAssessment: {},
      currentPage: 1,
      validPinModalVisible: false,
    };
  }
  getURLSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var SurveyId = param.get('SurveyId');
    if (SurveyId) {
      SurveyId = parseInt(SurveyId);
    } else {
      SurveyId = -1;
    }
    return SurveyId;
  }
  onAssignSurvey = () => {
    var item = {
      studentIds: this.props.clientId,
      surveyQuestionCategoryIds: this.state.assesmentId,
    };

    this.props.dispatch(surveyAssign(item));
  };

  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }

  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }
  componentDidMount() {
    this.props.dispatch(
      listSurveyQuestionAssessment({
        json: JSON.stringify({
          Survey: [
            {
              SurveyId: this.getURLSurveyId(),
            },
          ],
        }),
      }),
    );
  }

  componentWillReceiveProps({
    surveys,
    isSurveyAssign,
    SurveyQuestionCategoryList,
    SurveyListData,
    surveyQuestionAssessment,
    isSurveyDeactivated,
    diagnosisData,
    isSurveyCompleted,
    validateStaffPinData,
  }) {
    if (surveys.length > 0 && surveys.length != this.props.surveys.length) {
      for (var surveyIndex in surveys) {
        if (!surveys[surveyIndex].Response) {
          this.setState(
            {
              surveys: surveys,
              currentSlideIndex: parseInt(surveyIndex),
            },
            this.applySlick,
          );
          return;
        }
      }
    }
    if (
      surveyQuestionAssessment &&
      JSON.stringify(surveyQuestionAssessment) !=
        JSON.stringify(this.props.surveyQuestionAssessment)
    ) {
      this.setState({ surveyQuestionAssessment: surveyQuestionAssessment });
    }
    // if (
    //   SurveyQuestionCategoryList &&
    //   SurveyQuestionCategoryList != this.props.SurveyQuestionCategoryList
    // ) {
    //   debugger;
    //   this.setState({ SurveyQuestionCategoryListData: SurveyQuestionCategoryList });
    // }
    if (SurveyListData.length > 0 && SurveyListData.length != this.props.SurveyListData.length) {
      this.setState({ surveyListData: SurveyListData });
    }

    if (isSurveyAssign && isSurveyAssign != this.props.isSurveyAssign) {
      let getSurveyData = {
        json: JSON.stringify({
          Survey: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      };
      this.setState({
        assesmentId: '',
        currentPage: 1,
      });
      this.props.dispatch(listSurveyQuestionCategoryAssessment(getSurveyData));
    }
    if (isSurveyDeactivated && isSurveyDeactivated != this.props.isSurveyDeactivated) {
      this.props.dispatch(
        listSurveyQuestionCategoryAssessment({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
    }

    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      if (validateStaffPinData.IsValid) {
        this.props.dispatch(
          markAsComplete({
            SurveyId: this.state.surveyQuestionAssessment.SurveyId,
            StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId,
          }),
        );
      }
    }
  }

  setValue({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
  }) {
    let surveyQuestionAssessment = this.state.surveyQuestionAssessment;
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    if (level === 0) {
      surveyQuestionAssessment.SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 1) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 2) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveySubCategory[
        subCatLevel
      ].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    }
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    if (IsSignature) {
      this.setState({
        surveys: surveys,
        Signature: IsSignature && Signature,
        SignatureCanvas: IsSignature && {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [surveyQuestionId]: {
              SurveyQuestionId: surveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveys: surveys,
      });
    }

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index, isResetAnswer }) {
    let surveyQuestionAssessment = this.state.surveyQuestionAssessment;
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };
    if (level === 0) {
      surveyQuestionAssessment.SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 1) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 2) {
      // let newState = Object.assign({}, this.state);
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveySubCategory[
        subCatLevel
      ].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    }
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    $('.main-banner').slick('slickNext');

    this.setState({
      surveys: surveys,
    });
    if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }
  saveSignature = (
    signOne,
    data,
    SurveyQuestionId,
    index,
    Response,
    isSignatureSaved,
    isClear,
    isResponceData,
  ) => {
    let me = this;
    if (isClear) {
      let signaturData = me.state.SignatureCanvas;
      let signaturDataList = me.state.SignatureCanvas && me.state.SignatureCanvas.signatureDataList;
      delete signaturData[signOne];
      delete signaturDataList[signOne];
      signaturData.signatureDataList = signaturDataList;
      this.setState({
        SignatureCanvas: signaturData,
      });
      return;
    }
    if (isResponceData) {
      this.setState({
        SignatureCanvas: {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [SurveyQuestionId]: {
              SurveyQuestionId: SurveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveyQuestionId: SurveyQuestionId,
        SignatureCanvas: {
          ...this.state.SignatureCanvas,
          [signOne]: data,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [SurveyQuestionId]: {
              SurveyQuestionId: SurveyQuestionId,
              isSignatureSaved: isSignatureSaved,
              Response: Response,
              IsUpdateSignature: Response ? true : false,
              MediaGuid: Response ? Response : null,
            },
          },
        },
      });
    }
  };

  getSurveyId() {
    const { SurveyId } = this.state;

    return SurveyId;
  }

  getStudentId = () => {
    // var param = new URLSearchParams(this.props.history.location.search);
    // return param.get('studentId');
    return this.props.clientId;
  };

  onClose() {
    this.props.history.push('/studentProfile/' + this.getStudentId());
  }

  onSubmit() {
    this.props.history.push({
      pathname: '/surveyer',
      search: 'studentId=' + this.getStudentId() + '&ignoreUpdatingPin=true',
    });
  }

  render() {
    const {
      surveys,
      currentSlideIndex,
      surveyListData,
      screeningView,
      surveyQuestionAssessment,
    } = this.state;
    const {
      lastSlideIndex,
      SurveyQuestionCategoryList,
      diagnosisData,
      changeActiveTab,
      isFetchingSurveyQuestionAssessment,
      isSigned,
      signInfo,
    } = this.props;
    return (
      <div class="vertical-tabs pat-tab print-survery-question-page" id="assessmentPagewidth">
        <div className="main survery-question-page  mb-5">
          {/* <Loader2 loading={this.props.isFetching} /> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <Loader2 loading={this.props.isFetching} />
                <div
                  className="survery-question-box"
                  style={{ maxWidth: 'unset', marginTop: '50px' }}
                >
                  {/* <h3 className="mb-3 mb-md-5 ">{surveys.length > 0 && surveys[0].SurveyCategory}</h3> */}
                  <div className="screening-tool" id="assessmentSitecontent">
                    <div className="assessment-main-title d-flex align-items-center justify-content-between">
                      <div></div>
                      <h3 className="main-title-text">
                        {surveyQuestionAssessment.SurveyMainTitle}
                      </h3>
                      <div>
                        <div>
                          <button
                            onClick={() => window.print()}
                            className="btn black-btn px-4 print-btn"
                          >
                            <i class="fas fa-print mr-2"></i>
                            Print Assessment
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        padding: '25px',
                        margin: '0px 25px',
                      }}
                    >
                      {surveyQuestionAssessment && surveyQuestionAssessment.SurveyQuestion && (
                        <div>
                          <QuestionType
                            SurveyQuestion={surveyQuestionAssessment.SurveyQuestion}
                            SurveyId={surveyQuestionAssessment.SurveyId}
                            onNext={this.onNext}
                            setValue={this.setValue}
                            scope={this}
                            level={0}
                            isReadOnly={true}
                            saveSignature={this.saveSignature}
                          />
                        </div>
                      )}
                      {surveyQuestionAssessment &&
                        surveyQuestionAssessment.SurveyCategory &&
                        surveyQuestionAssessment.SurveyCategory.map((surveyCategory, index) => {
                          return (
                            <div>
                              <div
                                className="assessment-sub-title"
                                style={{
                                  margin: '0px 25px',
                                }}
                              >
                                <h5 className="sub-title-text">{surveyCategory.SurveyCategory}</h5>
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#F8F8F8',
                                  padding: '25px',
                                  margin: '0px 25px',
                                }}
                              >
                                <QuestionType
                                  SurveyQuestion={surveyCategory.SurveyQuestion}
                                  SurveyId={surveyQuestionAssessment.SurveyId}
                                  onNext={this.onNext}
                                  setValue={this.setValue}
                                  scope={this}
                                  level={1}
                                  subLevel={index}
                                  isReadOnly={true}
                                  saveSignature={this.saveSignature}
                                />

                                {surveyCategory &&
                                  surveyCategory.SurveySubCategory &&
                                  surveyCategory.SurveySubCategory.map((subCategory, subIndex) => {
                                    return (
                                      <div style={{ backgroundColor: '#FFF', padding: '15px' }}>
                                        <div className="assessment-sub-title">
                                          <h5 className="sub-title-text">
                                            {subCategory.SurveySubCategory}
                                          </h5>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: '#FFF',
                                            padding: '15px',
                                            // margin: '0px 25px',
                                          }}
                                        >
                                          <QuestionType
                                            SurveyQuestion={subCategory.SurveyQuestion}
                                            SurveyId={surveyQuestionAssessment.SurveyId}
                                            onNext={this.onNext}
                                            setValue={this.setValue}
                                            scope={this}
                                            level={2}
                                            subLevel={index}
                                            subCatLevel={subIndex}
                                            isReadOnly={true}
                                            saveSignature={this.saveSignature}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                      <div>
                        {isSigned && (
                          <div className="col-12">
                            {signInfo.SignedBy && (
                              <h5
                                style={{
                                  textAlign: 'left',
                                  fontSize: '17px',
                                  fontWeight: '700',
                                }}
                              >
                                {' '}
                                Signed By:-{signInfo.SignedBy}
                              </h5>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {diagnosisData && (
                      <div class="col-12 mt-5">
                        <h4>DIAGNOSIS</h4>
                        <div className="p-2">
                          <div class="row students-detail text-md-left justify-content-between">
                            <div className="col-12">
                              <div
                                className="row justify-content-between py-3 my-2"
                                style={{ backgroundColor: '#f2f2f2', borderRadius: '5px' }}
                              >
                                <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                  <span class="info-label">Date of Diagnosis</span>
                                  <h6
                                    // title={diagnosisData.DiagnosisDate}
                                    class="info-detail m-md-0"
                                  >
                                    {diagnosisData.DiagnosisDateTime}
                                  </h6>
                                </div>
                                {/* <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                    <span class="info-label">Time of Diagnosis</span>
                                    <h6
                                      title={diagnosisData.DiagnosisTime}
                                      class="info-detail m-md-0"
                                    >
                                      {diagnosisData.DiagnosisTime}
                                    </h6>
                                  </div> */}
                                <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                  <span class="info-label">Program</span>
                                  <h6
                                    title={diagnosisData.ProgramDescription}
                                    class="info-detail m-md-0"
                                  >
                                    {diagnosisData.ProgramDescription}
                                  </h6>
                                </div>
                                <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                  <span class="info-label">Diagnosis Practitioner</span>
                                  <h6
                                    title={diagnosisData.PractitionerName}
                                    class="info-detail m-md-0"
                                  >
                                    {diagnosisData.PractitionerName}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            {diagnosisData.DiagnosisList && (
                              <div className="col-12">
                                <h5 className="text-left">Diagnosis List</h5>
                                {diagnosisData.DiagnosisList.map((diagnosis, index) => {
                                  return (
                                    <div className="row border-bottom mb-2" key={index}>
                                      <div class="col-2 mb-2">
                                        <span class="info-label">Diagnosis Code</span>
                                        <h6
                                          title={diagnosis.DiagnosisDictDesc}
                                          class="info-detail m-md-0"
                                        >
                                          {diagnosis.DiagnosisDictDesc}
                                        </h6>
                                      </div>
                                      <div class="col-8 mb-2">
                                        <span class="info-label">Diagnosis Description</span>
                                        <h6
                                          title={diagnosis.DiagnosisDescription}
                                          class="info-detail m-md-0"
                                          // style={{
                                          //   textOverflow: 'ellipsis',
                                          //   whiteSpace: 'nowrap',
                                          //   overflow: 'hidden',
                                          //   width: '280px',
                                          // }}
                                        >
                                          {diagnosis.DiagnosisDescription}
                                        </h6>
                                      </div>
                                      <div class="col-2 mb-2">
                                        <span class="info-label">Status</span>
                                        <h6 class="info-detail m-md-0">{diagnosis.StatusDesc}</h6>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            {/* <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Diagnosis Category</span>
                                <h6 class="info-detail m-md-0">Category One</h6>
                              </div>
                              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Status</span>
                                <h6 class="info-detail m-md-0">Diagnosis Status</h6>
                              </div>
                              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Diagnosis Ranking</span>
                                <h6 class="info-detail m-md-0">Diagnosis Ranking</h6>
                              </div>
                              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Bill Order</span>
                                <h6 class="info-detail m-md-0">Diagnosis Bill Order</h6>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  survery_question_get_multiple,
  list_survey_question_category_assessment,
  survey_assign,
  list_survey_question_assessment,
  deactivate_client_screening,
  get_patient_detail,
  mark_as_complete,
  validate_staff_pin,
}) => {
  const { data, isFetching, error } = survery_question_get_multiple;
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: surveyAssignData } = survey_assign;
  const {
    data: listSurveyQuestionCategoryAssessmentData,
    isFetching: isFetchingListSurvey,
  } = list_survey_question_category_assessment;
  const { data: clientInfo } = get_patient_detail;
  var { data: validateDataPin } = validate_staff_pin;
  let validateStaffPinData = {};
  var lastSlideIndex = 1;
  let SurveyQuestionCategoryAssessmentList;
  let SurveyListData = [];
  let surveyData = [];
  let surveyQuestionAssessment;
  let isSurveyAssign = false;
  let isSurveyDeactivated = false;
  let diagnosisData = null;
  let isSurveyCompleted = false;
  let isSigned = false;
  let signInfo = {};

  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }

  if (data) {
    let array = [];
    for (let index in data) {
      array.push(data[index]);
    }
    //array = array.slice(0, 10);

    array.push({});
    surveyData = [...array];
    lastSlideIndex = data.length - 2;
  }
  if (surveyQuestionAssessmentData) {
    isSigned = surveyQuestionAssessmentData.IsSigned;
    signInfo = surveyQuestionAssessmentData.SignInfo && surveyQuestionAssessmentData.SignInfo[0];
    surveyQuestionAssessment = surveyQuestionAssessmentData;
  }
  if (
    listSurveyQuestionCategoryAssessmentData &&
    listSurveyQuestionCategoryAssessmentData.SurveyQuestionCategoryList
  ) {
    SurveyQuestionCategoryAssessmentList =
      listSurveyQuestionCategoryAssessmentData.SurveyQuestionCategoryList;
  }
  if (
    listSurveyQuestionCategoryAssessmentData &&
    listSurveyQuestionCategoryAssessmentData.SurveyList
  ) {
    SurveyListData = listSurveyQuestionCategoryAssessmentData.SurveyList;
  }
  if (surveyAssignData && surveyAssignData.success) {
    isSurveyAssign = surveyAssignData.success;
  }
  if (deactivate_client_screening.data && deactivate_client_screening.data.success) {
    isSurveyDeactivated = deactivate_client_screening.data.success;
  }
  if (mark_as_complete.data && mark_as_complete.data.success) {
    isSurveyCompleted = mark_as_complete.data.success;
  }
  if (clientInfo && clientInfo.Diagnosis) {
    diagnosisData = clientInfo.Diagnosis[0];
  }
  return {
    surveys: surveyData,
    surveyQuestionAssessment,
    isSurveyAssign,
    isSurveyDeactivated: isSurveyDeactivated,
    lastSlideIndex: lastSlideIndex,
    SurveyQuestionCategoryList: SurveyQuestionCategoryAssessmentList || [],
    SurveyListData: SurveyListData,
    // isFetchingListSurvey: isFetchingListSurvey || isFetching || false,
    isFetching: isFetching || isFetchingListSurvey,
    isFetchingSurveyQuestionAssessment: isFetchingSurveyQuestionAssessment,
    diagnosisData: diagnosisData,
    isSurveyCompleted,
    validateStaffPinData,
    isSigned,
    signInfo,
  };
};

export default connect(mapStateToProps)(Survey);
