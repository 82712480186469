import React, { Component } from 'react';
import { Tooltip, Checkbox, Collapse, Modal, Radio, Tag, Drawer, Button, Switch } from 'antd';
import moment from 'moment';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import Axios from 'axios';
import DatePicker from 'react-datepicker';
import renderHTML from 'react-render-html';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListRenderer from '../Components/listRenderer';
import CustomModal from '../Components/CustomModal';
import CloseIcon from '../Components/CloseIcon';
import CustomModal2 from '../core/modal';
import { ReduxSelect, ReduxPhoneInput } from '../component/custom-redux-components';
import NotesFromClient from './customScreens/notesFromClient';
import ReduxBreathalyzerTestResult from './PopupModals/BreathalyzerTestResultModal';
import DictationComponent from '../Components/DictationComponent';
import UploadProfile from '../Components/uploadProfilePicture';
import AntdModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import Select from '../component/select/index';
import Paragraph from 'antd/lib/typography/Paragraph';
import { getDate, stringDate } from '../util/date';
import SurveysQuestionPreview from './customScreens/surveyQuestionPreview';
import { getPhoneNumber, getCountryCalling } from '../util/phoneFormatter';
import AugmentedSummary from './EnterpriseClient/AugmentedSummary';
import RiskChart from './EnterpriseClient/RiskChart';
import SurveyResponseDrawer from './customScreens/surveyResponse';
import { CommonLicenceScreens } from './licenceScreens';
import {
  getStudent,
  getStudentProfileDashboardData,
  addStudentNote,
  listCombos,
  resetStudentNote,
  resetAddStudentCourse,
  addStudentCourse,
  surveyTaskMarkInComplete,
  studentTaskMarkComplete,
  studentTaskUnAssign,
  studentUnAssign,
  studentMarkComplete,
  surveyAssign,
  studentAddExistingTask,
  resetStudentAddExistingTask,
  resetStudentTaskUnAssign,
  resetStudentTaskMarkComplete,
  resetStudentMarkComplete,
  resetStudentUnAssign,
  resetSurveyAssign,
  resetStudentTaskUpsert,
  resetSurveyTaskMarkInComplete,
  studentDischarge,
  studentTaskUpsert,
  resetStudentDischarge,
  surveyAnswersGet,
  resetSurveyAnswersGet,
  getStudentsSearch,
  studentReinstate,
  resetStudentReinstate,
  appInvite,
  getMobileAppDownloadStatus,
  appInviteDashboard,
  markSystemObservation,
  upsertContract,
  resetUpsertContract,
  getStudentNote,
  getSurveyCategoryQuestion,
  deleteStudentNote,
  studentNoteListGet,
  userFeatureFlagGet,
  studentCourseAugmentationGet,
} from '../store/actions';
import AugmentWithAI from './customScreens/AugmentWithAIForCourses';
import Mood from './clientAndUserDashboardCommonComponents/mood';
import RecoveryMeasureGraph from './clientAndUserDashboardCommonComponents/recoveryMeasureGraph';
import PerformanceCharts from './clientAndUserDashboardCommonComponents/performanceCharts';
import PageTitle from '../Components/PageTitle';
import PageBackAction from '../Components/PageBackAction';
import RecoveryJourney from './clientAndUserDashboardCommonComponents/recoveryJourney';
const Validator = {
  required: value => (value ? undefined : 'Required'),
};

var activePanel = 1;
var activePanel1 = 1;
const { Panel } = Collapse;
const Close = props => (
  <span {...props}>
    <svg
      height="14"
      width="14"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      class="css-19bqh2r"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
  </span>
);
class NotesExtraComponent extends Component {
  state = {
    noteType: '',
    note: '',
    toggleActive: false,
    msg: false,
    // loading: false,
  };

  componentDidMount() {
    // var result = ProcessCombos('SurveyOptions,TaskOptions,ExitReason,Courses,NoteType');
    // if (result.isProcess) {
    //   this.props.dispatch(result.processItems);
    // }
    this.props.dispatch(
      listCombos({
        comboTypes: 'SurveyOptions,TaskOptions,ExitReason,Courses,NoteType',
        StudentId: this.getStudentId(),
      }),
    );
  }
  onButtonClick(studentId) {
    const { history } = this.props;
    history.push('/printNotes/' + studentId);
  }
  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }
  handleDictate = result => {
    // if (result.result && result.result.transcript) {
    //   // this.props.change('note', this.props.presentNoteValue + ' ' + result.result.transcript);
    //   let data;
    //   if (this.state.note) {
    //     data = `${this.state.note} ${result.result.transcript}`;
    //   } else {
    //     data = result.result.transcript;
    //   }
    //   this.setState({ note: data });
    // }
    let data;
    if (result) {
      if (this.state.note) {
        data = `${this.state.note} ${result}`;
      } else {
        data = result;
      }
      this.setState({ note: data });
    }
  };

  render() {
    const { noteType, note, toggleActive, msg } = this.state;
    const {
      addNote,
      noteOptions,
      isDischarged,
      isNoteFetching,
      FeatureFlags: { enableDictation },
    } = this.props;
    return (
      <div>
        <form className="add-note-form enrollment-form" style={{ marginTop: '5px' }}>
          <div className="form-group">
            <ReduxSelect
              options={noteOptions}
              isMulti
              value={noteType}
              onChange={value => this.setState({ noteType: value, msg: false })}
              placeholder="Note Type*"
              classNamePrefix="note-type-select"
            />
            {msg && <span className="error-text">Note Type required</span>}
          </div>
          <div className="form-group">
            <textarea
              style={{ minHeight: '100px' }}
              className={`form-control ${note && 'not-empty'}`}
              value={note}
              onChange={event => {
                this.setState({ note: event.target.value });
              }}
              rows="4"
              key="addANote"
            />
            <label class="placeholder-label">Add a note..</label>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-auto mb-3 mb-sm-2">
              <div className="custom-control" style={{ padding: 'unset' }}>
                <label className="custom-control-label">
                  <Switch
                    checked={toggleActive}
                    onClick={() => this.setState({ toggleActive: !toggleActive })}
                    size="small"
                  />
                  &nbsp; Is Confidential
                </label>
              </div>
            </div>

            <div className="col-auto">
              {enableDictation && (
                <div
                  className="dictation-space"
                  style={{ display: 'inline-flex', paddingRight: '10px' }}
                >
                  <DictationComponent handleDictate={this.handleDictate} />
                </div>
              )}
              <Link
                class="btn btn-outline-primary black-btn mr-3"
                to={{
                  pathname: '/printNotes',
                  search: 'studentId=' + this.getStudentId(),
                }}
                target="_blank"
              >
                View Notes
              </Link>
              <Button
                loading={isNoteFetching}
                // htmlType="submit"
                type="primary"
                className="btn save-button"
                style={{ padding: '0 29px' }}
                onClick={() => {
                  if (noteType.length > 0) {
                    var noteTypeIds = [];
                    noteTypeIds = noteType.map(item => item.Value);
                    noteTypeIds = noteTypeIds.toString();
                    addNote(noteTypeIds, note, toggleActive);
                  } else {
                    this.setState({ msg: true });
                  }
                  this.setState({
                    noteType: '',
                    note: '',
                  });
                }}
              >
                Add Note
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

NotesExtraComponent = connect(({ combos, student_note_add }) => ({
  noteOptions: (combos.data && combos.data.NoteType) || [],
  isNoteFetching: student_note_add && student_note_add.isFetching,
}))(withRouter(NotesExtraComponent));

class SurveysModalContent extends Component {
  constructor(props) {
    var DisplayName = sessionStorage.getItem('displayName');
    super(props);
    this.input = React.createRef();
    this.state = {
      dueDate: '',
      surveyQuestionCategoryId: '',
      studentData: [{ StudentId: this.getStudentId(), DisplayName }],
      isDrawerOpen: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
    this.props.dispatch(
      listCombos({
        comboTypes: 'SurveyOptions,TaskOptions,ExitReason,Courses,NoteType',
        StudentId: this.getStudentId(),
      }),
    );
  }
  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }
  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  handelAssignSurvey = () => {
    let { dueDate, surveyQuestionCategoryId, searchStr, studentData } = this.state;
    if (!dueDate || !surveyQuestionCategoryId.length > 0 || !studentData.length > 0) {
      if (surveyQuestionCategoryId.length == 0 || !surveyQuestionCategoryId) {
        this.setState({
          isCategoryReq: true,
        });
      } else if (!dueDate) {
        this.setState({
          isDueDateReq: true,
        });
      } else if (!studentData.length > 0) {
        this.setState({
          isStudentDataReq: true,
        });
      }
    } else {
      var surveyQuestionCategoryIds = [];
      for (var item of surveyQuestionCategoryId) {
        surveyQuestionCategoryIds.push(item.Value);
      }
      this.props.onOk();
      this.props.onAssignSurvey(
        surveyQuestionCategoryIds.toString(),
        dueDate,
        studentData.map(item => item.StudentId).toString(),
      );
    }
  };
  showDrawer = () => {
    this.setState({
      isDrawerOpen: true,
    });
  };
  onClose = () => {
    this.setState({
      isDrawerOpen: false,
    });
  };

  render() {
    const { dueDate, surveyQuestionCategoryId, searchStr, studentData, isDrawerOpen } = this.state;
    const { onOk, onCancel, surveyOptions, onAssignSurvey, searchOption } = this.props;
    const me = this;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Survey due date*"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <form>
        <div class="modal-content border-0">
          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
            <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
              &times;
            </button>
            <h4 class="modal-title col-12">Assign Survey</h4>
          </div>
          <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
            <div class="form-group isMultiHeight">
              <ReduxSelect
                options={surveyOptions}
                isMulti
                value={surveyQuestionCategoryId}
                onChange={value =>
                  this.setState({
                    surveyQuestionCategoryId: value,
                    msg: false,
                    isCategoryReq: false,
                  })
                }
                placeholder="Select"
                allowDefault={true}
              />
              {this.state.isCategoryReq && <span className="error-text">Survey Required</span>}
            </div>
            <div class="form-group">
              <DatePicker
                selected={dueDate}
                onChange={this.handleChange}
                className="form-control datepicker"
                minDate={new Date()}
                customInput={<ExampleCustomInput />}
              />
              <i class="far fa-calendar-alt form-control-icon" />
              {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
            </div>
            <div className="form-group">
              <Autocomplete
                getItemValue={item => `${item.StudentId}`}
                items={searchOption.map(item => {
                  return {
                    label: item.DisplayName,
                    ...item,
                  };
                })}
                renderItem={(item, isHighlighted) => {
                  return (
                    <div
                      key={item.StudentId}
                      style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                    >
                      {item.label}
                    </div>
                  );
                }}
                onChange={e => {
                  this.setState({ searchStr: e.target.value });
                  this.props.dispatch(getStudentsSearch({ SearchStr: e.target.value }));
                }}
                onSelect={value => {
                  const st = [...this.state.studentData];
                  for (let item of studentData) if (item.StudentId == value) return;
                  for (let item of searchOption) if (item.StudentId == value) st.push(item);

                  this.setState({ studentData: st, searchStr: '', isStudentDataReq: false });
                  this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
                  // this.props.history.push(`/studentProfile/${value}`);
                }}
                // value={searchStr}
                wrapperStyle={{ display: 'unset' }}
                renderInput={props => {
                  return (
                    <div
                      {...props}
                      style={{
                        borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                        paddingBottom: '2px',
                      }}
                    >
                      <ul
                        style={{ height: 40, overflowY: 'auto', padding: 'unset', margin: 'unset' }}
                        onClick={() => me.input.current.focus()}
                      >
                        {studentData.map((item, index) => (
                          <li className="multi-auto">
                            {item.DisplayName}
                            <Close
                              onClick={event => {
                                event.preventDefault();
                                studentData.splice(index, 1);
                                me.setState({ studentData });
                              }}
                            />
                          </li>
                        ))}
                        <li className="multi-auto">
                          <input
                            type="text"
                            onClick={event => {
                              event.preventDefault();
                            }}
                            ref={me.input}
                            value={searchStr}
                            style={{
                              border: 'none',
                              width: (searchStr && searchStr.length * 13) || '0.75em',
                              maxWidth: '100%',
                              backgroundColor: 'inherit',
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                  );
                }}
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 4px',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'auto',
                  maxHeight: '50%',
                  zIndex: 1,
                  width: 'inherit',
                }}
              />
              {this.state.isStudentDataReq && (
                <span className="error-text">Please select atleast one student</span>
              )}
            </div>
            <div class="form-group text-right">
              {surveyQuestionCategoryId && surveyQuestionCategoryId.length > 0 && (
                <span
                  style={{ color: 'rgb(74, 176, 254)', cursor: 'pointer' }}
                  onClick={this.showDrawer}
                >
                  View selected Survey Questions
                </span>
              )}
            </div>
          </div>

          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
              onClick={onCancel}
            >
              Close
            </button>
            <button
              type="button"
              class="btn "
              onClick={this.handelAssignSurvey}
              // onClick={() => {
              //   if (surveyQuestionCategoryId.length > 0) {
              //     var surveyQuestionCategoryIds = [];
              //     for (var item of surveyQuestionCategoryId) {
              //       surveyQuestionCategoryIds.push(item.Value);
              //     }
              //     onOk();
              //     onAssignSurvey(
              //       surveyQuestionCategoryIds.toString(),
              //       dueDate,
              //       studentData.map(item => item.StudentId).toString(),
              //     );
              //   } else {
              //     this.setState({ msg: true });
              //   }
              // }}
            >
              Assign
            </button>
          </div>
        </div>
        {isDrawerOpen && (
          <Drawer
            className="custom-drawer"
            title="View selected Survey Questions"
            placement="right"
            onClose={this.onClose}
            open={isDrawerOpen}
            closeIcon={<CloseIcon />}
            width="600"
          >
            <SurveysQuestionPreview surveyQuestionCategoryId={surveyQuestionCategoryId} />
          </Drawer>
        )}
      </form>
    );
  }
}

SurveysModalContent = connect(({ student_search, combos }) => ({
  surveyOptions: (combos.data && combos.data.SurveyOptions) || [],
  searchOption: (student_search.data && Object.values(student_search.data)) || [],
}))(withRouter(SurveysModalContent));

class NewTasksModalContent extends Component {
  constructor(props) {
    var DisplayName = sessionStorage.getItem('displayName');
    super(props);
    const { taskName, dueDate, StudentIds, togglePreDefinedTask, isEdit, taskId } = props;
    this.input = React.createRef();
    this.state = {
      title: '',
      dueDate: (isEdit && new Date(dueDate)) || '',
      studentData: [{ StudentId: this.getStudentId(), DisplayName }],
      togglePreDefinedTask: 'false',
    };
    this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
  }
  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }

  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  handelNewTask = () => {
    const { title, dueDate, studentData, togglePreDefinedTask } = this.state;
    // const { taskName, dueDate, StudentIds, togglePreDefinedTask, isEdit, taskId } = this.props;
    if (this.props.isEdit) {
      this.props.onOk();
      this.props.onAssignNewTask(
        this.props.taskName,
        dueDate,
        this.props.studentIds,
        this.props.togglePreDefinedTask,
        this.props.taskId,
        this.props.TaskStudentId,
        this.props.RepeatFrequencyCode,
        this.props.RepeatDayOfWeek,
      );
    } else {
      if (!title || !dueDate || !studentData.length > 0) {
        if (!title) {
          this.setState({
            isTitleReq: true,
          });
        } else if (!dueDate) {
          this.setState({
            isDueDateReq: true,
          });
        } else if (!studentData.length > 0) {
          this.setState({
            isStudentDataReq: true,
          });
        }
      } else {
        this.props.onOk();
        this.props.onAssignNewTask(
          title,
          dueDate,
          studentData.map(item => item.StudentId).toString(),
          togglePreDefinedTask,
          // this.props.taskId,
        );
      }
    }

    // if ((title, dueDate, studentData)) {
    //   this.props.onOk();
    //   this.props.onAssignNewTask(
    //     title,
    //     dueDate,
    //     studentData.map(item => item.StudentId).toString(),
    //   );
    // }
  };
  render() {
    const { onOk, onCancel, onAssignNewTask, searchOption, isEdit } = this.props;
    const { title, dueDate, searchStr, studentData } = this.state;
    const me = this;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Task due date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" class="close" data-dismis="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">{isEdit ? 'Edit task due date' : 'Assign New Task'} </h4>
        </div>
        <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5 enrollment-form">
          {!isEdit && (
            <div class="form-group">
              <textarea
                // type='textarea'
                className={`form-control ${title && 'not-empty'}`}
                value={title}
                onChange={e => {
                  if (e.target.value.length <= 100) {
                    this.setState({ title: e.target.value, isTitleReq: false });
                  }
                }}
              />
              <label className="placeholder-label">Enter Task Name*</label>
              <p className="mb-0 text-right">{title ? title.length : 0} / 100</p>
              {this.state.isTitleReq && <span className="error-text">Task Name Required</span>}
            </div>
          )}

          <div class="form-group">
            <DatePicker
              selected={dueDate}
              onChange={this.handleChange}
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              minDate={new Date()}
            />
            <i class="far fa-calendar-alt form-control-icon" />
            {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
          </div>
          {!isEdit && (
            <div className="form-group">
              <Autocomplete
                getItemValue={item => `${item.StudentId}`}
                items={searchOption.map(item => {
                  return {
                    label: item.DisplayName,
                    ...item,
                  };
                })}
                renderItem={(item, isHighlighted) => {
                  return (
                    <div
                      key={item.StudentId}
                      style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                    >
                      {item.label}
                    </div>
                  );
                }}
                onChange={e => {
                  this.setState({ searchStr: e.target.value, isStudentData: false });
                  this.props.dispatch(getStudentsSearch({ SearchStr: e.target.value }));
                }}
                onSelect={value => {
                  const st = [...this.state.studentData];
                  for (let item of studentData) if (item.StudentId == value) return;
                  for (let item of searchOption) if (item.StudentId == value) st.push(item);

                  this.setState({ studentData: st, searchStr: '' });
                  this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
                  // this.props.history.push(`/studentProfile/${value}`);
                }}
                // value={searchStr}
                wrapperStyle={{ display: 'unset' }}
                renderInput={props => {
                  return (
                    <div
                      {...props}
                      style={{
                        borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                        paddingBottom: '2px',
                      }}
                    >
                      <ul
                        style={{ height: 40, overflowY: 'auto', padding: 'unset', margin: 'unset' }}
                        onClick={() => me.input.current.focus()}
                      >
                        {studentData.map((item, index) => (
                          <li className="multi-auto">
                            {item.DisplayName}
                            <Close
                              onClick={event => {
                                event.preventDefault();
                                studentData.splice(index, 1);
                                me.setState({ studentData });
                              }}
                            />
                          </li>
                        ))}
                        <li className="multi-auto">
                          <input
                            type="text"
                            onClick={event => {
                              event.preventDefault();
                            }}
                            ref={me.input}
                            value={searchStr}
                            style={{
                              border: 'none',
                              width: (searchStr && searchStr.length * 13) || '0.75em',
                              maxWidth: '100%',
                              backgroundColor: 'inherit',
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                  );
                }}
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 4px',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'auto',
                  maxHeight: '50%',
                  zIndex: 1,
                  width: 'inherit',
                }}
              />
              {this.state.isStudentDataReq && (
                <span className="error-text">Please select atleast one student</span>
              )}
            </div>
          )}

          <div classname="form-group payment-dashboard-detail">
            <div className="col-auto mb-3 mb-sm-2">
              {/* <div className="custom-control" style={{ padding: 'unset' }}>
                <label className="custom-control-label">
                  <Switch
                    checked={this.state.togglePreDefinedTask}
                    onClick={() =>
                      this.setState({ togglePreDefinedTask: !this.state.togglePreDefinedTask })
                    }
                    size="small"
                  />
                  &nbsp; Is PreDefined Task ?
                </label>
              </div> */}
              {!isEdit && (
                <Radio.Group
                  name="togglePreDefinedTask"
                  onChange={e => {
                    this.setState({ togglePreDefinedTask: e.target.value });
                  }}
                  value={this.state.togglePreDefinedTask}
                >
                  <Radio value="false">One Time Task</Radio>
                  <Radio value="true">Predefined Task</Radio>
                </Radio.Group>
              )}
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </button>
          <button type="button" class="btn " onClick={this.handelNewTask}>
            Assign
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsNewTasks = ({ student_search }) => {
  const { data, error, isFetching } = student_search;
  return {
    searchOption: (data && Object.values(data)) || [],
  };
};

NewTasksModalContent = connect(mapStateToPropsNewTasks)(withRouter(NewTasksModalContent));

class TasksModalContent extends Component {
  constructor(props) {
    var DisplayName = sessionStorage.getItem('displayName');
    super(props);
    this.input = React.createRef();
    this.state = {
      taskId: '',
      repeatFrequencyCode: '',
      dueDate: '',
      studentData: [{ StudentId: this.getStudentId(), DisplayName }],
    };
    this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
  }
  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }

  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };

  handelAssignExistingTask = () => {
    let { taskId, repeatFrequencyCode, dueDate, searchStr, studentData } = this.state;
    if (!taskId || !repeatFrequencyCode || !dueDate || !studentData.length > 0) {
      if (!taskId) {
        this.setState({
          istaskReq: true,
        });
      } else if (!repeatFrequencyCode) {
        this.setState({
          isRepeatFrequencyReq: true,
        });
      } else if (!dueDate) {
        this.setState({
          isDueDateReq: true,
        });
      } else if (!studentData.length > 0) {
        this.setState({
          isStudentDataReq: true,
        });
      }
    } else {
      this.props.onOk();
      this.props.onAssignExistingTask(
        taskId,
        repeatFrequencyCode,
        dueDate,
        studentData.map(item => item.StudentId).toString(),
      );
    }
  };

  render() {
    const { onOk, onCancel, taskOptions, onAssignExistingTask, searchOption } = this.props;
    const { taskId, repeatFrequencyCode, dueDate, searchStr, studentData } = this.state;
    const me = this;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Task due date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">Assign Pre-defined Task</h4>
        </div>
        <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div class="form-group">
            <Select
              data={taskOptions}
              allowDefault={true}
              value={taskId}
              onChange={event => this.setState({ taskId: event.target.value, istaskReq: false })}
            />
            <i class="fas fa-angle-down form-control-icon" />
            {this.state.istaskReq && <span className="error-text">Task is Required</span>}
          </div>
          <div class="form-group">
            <Select
              data={[
                { Value: 'W', Text: 'Weekly' },
                { Value: 'O', Text: 'One Time' },
                { Value: 'D', Text: 'Daily' },
              ]}
              allowDefault={true}
              value={repeatFrequencyCode}
              onChange={event => {
                this.setState({
                  repeatFrequencyCode: event.target.value,
                  isRepeatFrequencyReq: false,
                });
              }}
            />
            <i class="fas fa-angle-down form-control-icon" />
            {this.state.isRepeatFrequencyReq && (
              <span className="error-text">Frequency is Required</span>
            )}
          </div>

          <div class="form-group">
            <DatePicker
              selected={dueDate}
              onChange={this.handleChange}
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              minDate={new Date()}
            />
            <i class="far fa-calendar-alt form-control-icon" />
            {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
          </div>

          <div className="form-group">
            <Autocomplete
              getItemValue={item => `${item.StudentId}`}
              items={searchOption.map(item => {
                return {
                  label: item.DisplayName,
                  ...item,
                };
              })}
              renderItem={(item, isHighlighted) => {
                return (
                  <div
                    key={item.StudentId}
                    style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                  >
                    {item.label}
                  </div>
                );
              }}
              onChange={e => {
                this.setState({ searchStr: e.target.value });
                this.props.dispatch(getStudentsSearch({ SearchStr: e.target.value }));
              }}
              onSelect={value => {
                const st = [...this.state.studentData];
                for (let item of studentData) if (item.StudentId == value) return;
                for (let item of searchOption) if (item.StudentId == value) st.push(item);

                this.setState({ studentData: st, searchStr: '', isStudentDataReq: false });
                this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
                // this.props.history.push(`/studentProfile/${value}`);
              }}
              // value={searchStr}
              wrapperStyle={{ display: 'unset' }}
              renderInput={props => {
                return (
                  <div
                    {...props}
                    style={{
                      borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                      paddingBottom: '2px',
                    }}
                  >
                    <ul
                      style={{ height: 40, overflowY: 'auto', padding: 'unset', margin: 'unset' }}
                      onClick={() => me.input.current.focus()}
                    >
                      {studentData.map((item, index) => (
                        <li className="multi-auto">
                          {item.DisplayName}
                          <Close
                            onClick={event => {
                              event.preventDefault();
                              studentData.splice(index, 1);
                              me.setState({ studentData });
                            }}
                          />
                        </li>
                      ))}
                      <li className="multi-auto">
                        <input
                          type="text"
                          onClick={event => {
                            event.preventDefault();
                          }}
                          ref={me.input}
                          value={searchStr}
                          style={{
                            border: 'none',
                            width: (searchStr && searchStr.length * 13) || '0.75em',
                            maxWidth: '100%',
                            backgroundColor: 'inherit',
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                );
              }}
              menuStyle={{
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '2px 4px',
                fontSize: '90%',
                position: 'fixed',
                overflow: 'auto',
                maxHeight: '50%',
                zIndex: 1,
                width: 'inherit',
              }}
            />
            {this.state.isStudentDataReq && (
              <span className="error-text">Please select atleast one student</span>
            )}
          </div>
        </div>

        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </button>
          <button
            type="button"
            class="btn "
            onClick={this.handelAssignExistingTask}
            // onClick={() => {
            //   if (repeatFrequencyCode.length > 0) {
            //     onOk();
            //     onAssignExistingTask(
            //       taskId,
            //       repeatFrequencyCode,
            //       dueDate,
            //       studentData.map(item => item.StudentId).toString(),
            //     );
            //     this.setState({
            //       requiredError: '',
            //     });
            //   } else {
            //     this.setState({
            //       requiredError: 'Frequency is Required ',
            //     });
            //   }
            // }}
          >
            Assign
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToPropsTasks = ({ student_search, combos }) => {
  const { data, error, isFetching } = student_search;
  let taskOptions = [];

  if (combos.data.TaskOptions) {
    taskOptions = combos.data.TaskOptions;
  }
  return {
    searchOption: (data && Object.values(data)) || [],
    taskOptions,
  };
};

TasksModalContent = connect(mapStateToPropsTasks)(withRouter(TasksModalContent));

class CoursesModalContent extends Component {
  constructor(props) {
    var DisplayName = sessionStorage.getItem('displayName');
    super(props);
    const { CourseId, DueDate } = props;
    this.input = React.createRef();
    let course = [];
    course.push({ Value: CourseId });
    this.state = {
      selectedCourse: (CourseId && course) || '',
      dueDate: (DueDate && new Date(DueDate)) || '',
      studentData: [{ StudentId: this.getStudentId(), DisplayName }],
    };
    this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
  }
  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }
  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  handelNewCourses = () => {
    let { selectedCourse, dueDate, searchStr, studentData } = this.state;
    if (!dueDate || !selectedCourse || !studentData.length > 0) {
      if (!selectedCourse) {
        this.setState({
          isCourseReq: true,
        });
      } else if (!dueDate) {
        this.setState({
          isDueDateReq: true,
        });
      } else if (!studentData.length > 0) {
        this.setState({
          isStudentDataReq: true,
        });
      }
    } else {
      this.props.onOk();
      var selectedCourses = [];
      for (var item of selectedCourse) {
        selectedCourses.push(item.Value);
      }
      this.props.assignCourse(
        selectedCourses.toString(),
        dueDate,
        studentData.map(item => item.StudentId).toString(),
      );
    }
  };
  render() {
    const { onOk, onCancel, courseOptions, assignCourse, searchOption, isEdit } = this.props;
    const { selectedCourse, dueDate, searchStr, studentData } = this.state;
    const me = this;

    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Courses due date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">{isEdit ? 'Edit course end date' : 'List of Courses'} </h4>
        </div>
        <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div class="form-group">
            {/* <Select
              data={courseOptions}
              allowDefault={true}
              value={selectedCourse}
              onChange={event => this.setState({ selectedCourse: parseInt(event.target.value) })}
            /> */}
            {!isEdit && (
              <>
                <ReduxSelect
                  options={courseOptions}
                  isMulti
                  value={selectedCourse}
                  onChange={value =>
                    this.setState({ selectedCourse: value, msg: false, isCourseReq: false })
                  }
                  placeholder="Select"
                  allowDefault={true}
                />
                {this.state.isCourseReq && <span className="error-text">Course Required</span>}
              </>
            )}
          </div>
          <div class="form-group">
            <DatePicker
              selected={dueDate}
              onChange={this.handleChange}
              className="form-control datepicker"
              customInput={<ExampleCustomInput />}
              minDate={new Date()}
            />
            <i class="far fa-calendar-alt form-control-icon" />
            {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
          </div>
          {!isEdit && (
            <div className="form-group">
              <Autocomplete
                getItemValue={item => `${item.StudentId}`}
                items={searchOption.map(item => {
                  return {
                    label: item.DisplayName,
                    ...item,
                  };
                })}
                renderItem={(item, isHighlighted) => {
                  return (
                    <div
                      key={item.StudentId}
                      style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                    >
                      {item.label}
                    </div>
                  );
                }}
                onChange={e => {
                  this.setState({ searchStr: e.target.value });
                  this.props.dispatch(getStudentsSearch({ SearchStr: e.target.value }));
                }}
                onSelect={value => {
                  const st = [...this.state.studentData];
                  for (let item of studentData) if (item.StudentId == value) return;
                  for (let item of searchOption) if (item.StudentId == value) st.push(item);

                  this.setState({ studentData: st, searchStr: '', isStudentDataReq: false });
                  this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
                  // this.props.history.push(`/studentProfile/${value}`);
                }}
                // value={searchStr}
                wrapperStyle={{ display: 'unset' }}
                renderInput={props => {
                  return (
                    <div
                      {...props}
                      style={{
                        borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                        paddingBottom: '2px',
                      }}
                    >
                      <ul
                        style={{ height: 40, overflowY: 'auto', padding: 'unset', margin: 'unset' }}
                        onClick={() => me.input.current.focus()}
                      >
                        {studentData.map((item, index) => (
                          <li className="multi-auto">
                            {item.DisplayName}
                            <Close
                              onClick={event => {
                                event.preventDefault();
                                studentData.splice(index, 1);
                                me.setState({ studentData });
                              }}
                            />
                          </li>
                        ))}
                        <li className="multi-auto">
                          <input
                            type="text"
                            onClick={event => {
                              event.preventDefault();
                            }}
                            ref={me.input}
                            value={searchStr}
                            style={{
                              border: 'none',
                              width: (searchStr && searchStr.length * 13) || '0.75em',
                              maxWidth: '100%',
                              backgroundColor: 'inherit',
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                  );
                }}
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 4px',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'auto',
                  maxHeight: '50%',
                  zIndex: 1,
                  width: 'inherit',
                }}
              />
              {this.state.isStudentDataReq && (
                <span className="error-text">Please select atleast one student</span>
              )}
            </div>
          )}
        </div>

        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </button>
          <button
            type="button"
            class="btn "
            onClick={this.handelNewCourses}
            // onClick={() => {
            //   if (selectedCourse.length > 0) {
            //     var selectedCourses = [];
            //     for (var item of selectedCourse) {
            //       selectedCourses.push(item.Value);
            //     }
            //     onOk();
            //     assignCourse(
            //       selectedCourses.toString(),
            //       dueDate,
            //       studentData.map(item => item.StudentId).toString(),
            //     );
            //   } else {
            //     this.setState({ msg: true });
            //   }
            // }}
          >
            {isEdit ? 'Update' : 'Assign'}
          </button>
        </div>
      </div>
    );
  }
}

CoursesModalContent = connect(({ combos, student_search }) => ({
  courseOptions: (combos.data && combos.data.Courses) || [],
  searchOption: (student_search.data && Object.values(student_search.data)) || [],
}))(withRouter(CoursesModalContent));

class ReinstateModal extends Component {
  state = {
    dueDate: '',
    reinstateNote: '',
    EnrollStatusId: false,
    isDisabled: false,
    prospectMsg: null,
    studentReinstateCenter: null,
  };
  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }
  handleChange = date => {
    this.setState({
      dueDate: date,
      isReinstateDateReq: false,
    });
  };
  componentDidMount() {
    if (this.props.preIntakeStatusData.LicenseStatusId == 6) {
      this.setState({
        isDisabled: true,
        prospectMsg:
          "Your center doesn't have Prospect module access, Please contact administration.",
      });
    } else if (this.props.preIntakeStatusData.LicenseStatusId == 1) {
      this.setState({
        isDisabled: true,
        prospectMsg:
          'Please initiate a free trial of the prospect module  to facilitate the transition of this client.',
      });
    } else if (this.props.preIntakeStatusData.LicenseStatusId == 3) {
      this.setState({
        isDisabled: true,
        prospectMsg: 'Your Prospect Licence is expired, Please contact administration.',
      });
    } else {
      this.setState({
        isDisabled: false,
        prospectMsg: null,
      });
    }
    this.setState({
      studentReinstateCenter:
        localStorage.getItem('centerId') != 'null'
          ? parseInt(localStorage.getItem('centerId'))
          : null,
    });
  }
  render() {
    const {
      onOk,
      onCancel,
      reinstate,
      history,
      fnAppRefModalClose,
      DischargeDate,
      preIntakeStatusData,
      staffPermittedCenters,
    } = this.props;
    const {
      dueDate,
      reinstateNote,
      enrollStatusId,
      isDisabled,
      prospectMsg,
      studentReinstateCenter,
    } = this.state;
    const staffCenterId = localStorage.getItem('centerId');

    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Reinstate Date*"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <form>
        <div class="modal-content border-0">
          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
            <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
              &times;
            </button>
            <h4 class="modal-title col-12">Student Reinstate</h4>
          </div>
          <div class="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
            <div className="enrollment-form">
              <div class="form-group">
                <DatePicker
                  selected={dueDate}
                  onChange={this.handleChange}
                  className="form-control datepicker"
                  // minDate={new Date()}
                  customInput={<ExampleCustomInput />}
                  minDate={new Date(DischargeDate)}
                />
                <i class="far fa-calendar-alt form-control-icon" />
                {this.state.isReinstateDateReq && (
                  <span className="error-text">Please select reinstate date</span>
                )}
              </div>

              <div class="form-group">
                <textarea
                  className={`form-control`}
                  value={reinstateNote}
                  onChange={event => {
                    this.setState({ reinstateNote: event.target.value });
                  }}
                  rows="4"
                  key="reinstateNote"
                  placeholder={'Reinstate Note..'}
                />
                {/* <label class="placeholder-label">Reinstate Note..</label> */}
              </div>
              <ReduxSelect
                options={staffPermittedCenters}
                value={this.state.studentReinstateCenter || parseInt(staffCenterId)}
                className="basic-single"
                placeholder="Center"
                onChange={value => {
                  return this.setState({
                    studentReinstateCenter: value,
                  });
                }}
              />
              <div className="row">
                <div class="col-12 col-md-12 col-lg-12 mt-3">
                  <div class="form-group">
                    <Checkbox
                      onChange={e => {
                        this.setState({
                          enrollStatusId: e.target.checked,
                        });
                      }}
                      disabled={isDisabled}
                    >
                      Move to Prospect
                    </Checkbox>
                    <p className="mb-0 mt-4" style={{ color: 'red' }}>
                      {prospectMsg}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
              onClick={onCancel}
            >
              Close
            </button>
            <button
              type="button"
              class="btn "
              onClick={() => {
                if (!dueDate) {
                  this.setState({
                    isReinstateDateReq: true,
                  });
                } else {
                  onOk();
                  reinstate(dueDate, reinstateNote, enrollStatusId, studentReinstateCenter);
                  fnAppRefModalClose();
                  // history.goBack();
                }
              }}
            >
              Reinstate
            </button>
          </div>
        </div>
      </form>
    );
  }
}
// ReinstateModal = connect()(withRouter(ReinstateModal));

ReinstateModal = reduxForm({
  form: 'studentDischargeForm',
  // Validator,
  enableReinitialize: true,
})(withRouter(ReinstateModal));

ReinstateModal = connect()(ReinstateModal);

class DischargeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dueDate: '',
      dischargeReason: '',
      exitReasonNotes: '',
      primaryPhone: props.primaryPhone,
      primaryEmail: props.email,
      inviteCheckBox: props.primaryPhone || false,
      errMsg: false,
    };
  }

  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'SurveyOptions,TaskOptions,ExitReason,Courses,NoteType,StaffPermittedCenters',
        StudentId: this.getStudentId(),
      }),
    );
  }
  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }
  onChangeInvite = e => {
    this.setState({
      inviteCheckBox: e.target.checked,
    });
  };
  formatMobile = num => {
    let mobVal = num.replace(/[&\/\\#/\-, +()$~%.'":*?<>{}]/g, '');
    return (mobVal = mobVal.slice(mobVal.length - 10));
  };
  mobileNumberValueChange = e => {
    // let mobVal = e.target.value.replace(/[&\/\\#/\-, +()$~%.'":*?<>{}]/g, '');
    // mobVal = mobVal.slice(mobVal.length - 10);
    this.setState({ primaryPhone: this.formatMobile(e.target.value) });
  };
  fnStudentDischarge = () => {
    const {
      dischargeReason,
      dueDate,
      exitReasonNotes,
      inviteCheckBox,
      primaryPhone,
      primaryEmail,
    } = this.state;
    if (!dueDate || !dischargeReason) {
      if (!dueDate) {
        this.setState({
          isDueDateReq: true,
        });
      } else if (!dischargeReason) {
        this.setState({
          isDischargeReasonReq: true,
        });
      }
    } else {
      this.props.onOk();
      this.props.onDischarge(
        dischargeReason,
        dueDate,
        exitReasonNotes,
        inviteCheckBox,
        primaryPhone,
        primaryEmail,
      );
    }
  };
  render() {
    const {
      onOk,
      onCancel,
      reasonOptions,
      onDischarge,
      history,
      EnrollExitNote,
      AdmissionDate,
      DischargeDate,
      reinstate,
      dispatch,
      appDownloadStatus,
      email,
      preIntakeStatusData,
      staffPermittedCenters,
    } = this.props;
    const {
      dueDate,
      dischargeReason,
      exitReasonNotes,
      primaryPhone,
      inviteCheckBox,
      primaryEmail,
      errMsg,
    } = this.state;

    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          // class="form-control datepicker"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Discharge Date*</label>
      </>
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
        </div>
        <div class="modal-body enrollment-form">
          {EnrollExitNote ? (
            <>
              <div className="col-12 d-flex justify-content-center">
                <div style={{ width: '30%', display: 'inline-block' }}>
                  <h4 class="modal-title" style={{ textAlign: 'left', fontWeight: '600' }}>
                    Admission Date
                  </h4>
                  <h5>
                    <div
                      style={{
                        width: '50%',
                        display: 'inline-block',
                        color: '#abb6bf',
                        fontSize: '16px',
                      }}
                    >
                      {AdmissionDate}
                    </div>
                  </h5>
                </div>

                <div style={{ width: '30%', display: 'inline-block' }}>
                  <h4 class="modal-title" style={{ textAlign: 'left', fontWeight: '600' }}>
                    Discharge
                  </h4>
                  <h5>
                    <div
                      style={{
                        width: '50%',
                        display: 'inline-block',
                        color: '#abb6bf',
                        fontSize: '16px',
                      }}
                    >
                      {DischargeDate}
                    </div>
                  </h5>
                </div>

                <div style={{ width: '30%', display: 'inline-block' }}>
                  <h4 class="modal-title" style={{ textAlign: 'left', fontWeight: '600' }}>
                    Discharge Reason
                  </h4>
                  <h5>
                    <div
                      style={{
                        // width: '50%',
                        display: 'inline-block',
                        color: '#abb6bf',
                        fontSize: '16px',
                      }}
                    >
                      {EnrollExitNote}
                    </div>
                  </h5>
                </div>
              </div>

              <br />
              <div className="col-12 mt-3 " style={{ paddingLeft: '52px' }}>
                <div
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    marginBottom: '0px',
                    display: 'inline-block',
                  }}
                  className="modal-title mb-3"
                >
                  Mobile App Referral
                </div>

                {/* <ReduxPhoneInput
                  className="form-control"
                  value={primaryPhone}
                  onChange={e => {
                    this.setState({ primaryPhone: e });
                  }}
                  placeholder="Please Enter your mobile number213"
                /> */}
                <div class="form-group d-flex" style={{ marginBottom: '10px' }}>
                  <div className="checkBoxNumber" style={{ width: '68%' }}>
                    <Field
                      name="primaryPhone"
                      component={ReduxPhoneInput}
                      className={`form-control ${primaryPhone && 'not-empty'}`}
                      validate={Validator.required}
                      placeholder="Mobile Number"
                      fieldName={'Reinstate Mobile'}
                      defaultValue={this.props.primaryPhone}
                      onChange={e => {
                        if (e !== null) this.setState({ errMsg: false });
                      }}
                      onBlur={e => {
                        this.setState({ primaryPhone: e.target.value });
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      const sid = this.getStudentId();
                      // onOk();
                      this.setState({ errMsg: true });
                      if (primaryPhone && getCountryCalling(primaryPhone)) {
                        this.setState({ errMsg: false });

                        dispatch(
                          appInviteDashboard({
                            MobileNumber: (primaryPhone && this.formatMobile(primaryPhone)) || '',
                            MobileNumberCountryCode:
                              (primaryPhone && getCountryCalling(primaryPhone)) || '',
                            Email: null,
                            FirstName: this.props.firstName,
                            LastName: this.props.lastName,
                            IsDischarge: true,
                            CenterId: this.props.centerId,
                          }),
                        );
                      }
                    }}
                    class="btn mt-3"
                  >
                    Send
                  </button>
                </div>
                {errMsg && (
                  <span style={{ color: 'red' }}>
                    Mobile Number is required in order to send App Referral
                  </span>
                )}

                <p style={{ textAlign: 'left' }}>
                  Status:
                  <span class="status" style={{ color: '#000000' }}>
                    {appDownloadStatus ? appDownloadStatus.AppStatus.substring(1) : 'Loading ...'}
                  </span>
                </p>
              </div>
            </>
          ) : (
            <div>
              <h4 class="modal-title col-12">
                <div style={{ width: '40%', display: 'inline-block' }}>Discharge Details</div>
              </h4>
              <div class="form-group">
                <div className="row">
                  <div className="col-6">
                    <DatePicker
                      selected={dueDate}
                      onChange={this.handleChange}
                      className="form-control datepicker"
                      customInput={<ExampleCustomInput />}
                      // minDate={new Date()}
                      maxDate={new Date()}
                    />
                    <i class="far fa-calendar-alt form-control-icon" />
                    {this.state.isDueDateReq && (
                      <span className="error-text">Please select discharge date</span>
                    )}
                  </div>
                  <div className="col-6">
                    <ReduxSelect
                      options={reasonOptions}
                      value={dischargeReason}
                      className="basic-single"
                      placeholder="Discharge Reason*"
                      onChange={value => {
                        return this.setState({
                          isDischargeReasonReq: false,
                          dischargeReason: value,
                        });
                      }}
                    />
                    {/* <i class="fas fa-angle-down form-control-icon" /> */}
                    {this.state.isDischargeReasonReq && (
                      <span className="error-text">Please select discharge reason</span>
                    )}
                  </div>
                </div>
              </div>
              {/* <div class="form-group">
                <ReduxSelect
                  options={reasonOptions}
                  value={dischargeReason}
                  className="basic-single"
                  placeholder="Discharge Reason"
                  onChange={value => {
                    return this.setState({
                      isDischargeReasonReq: false,
                      dischargeReason: value,
                    });
                  }}
                />
                {/* <i class="fas fa-angle-down form-control-icon" /> 
                {this.state.isDischargeReasonReq && (
                  <span className="error-text">Please select discharge reason</span>
                )}
              </div> */}
              <div class="form-group">
                <textarea
                  type="text"
                  class={`form-control  ${exitReasonNotes && 'not-empty'}`}
                  value={exitReasonNotes}
                  onChange={e => this.setState({ exitReasonNotes: e.target.value })}
                />
                <label class="placeholder-label">Notes</label>
              </div>
              <div class="form-group mb-3 d-flex" style={{ fontSize: 'small', fontWeight: 400 }}>
                <Checkbox
                  onChange={this.onChangeInvite}
                  className="dis-Ref mr-2"
                  checked={inviteCheckBox}
                  style={{ marginBottom: '0px' }}
                />
                <span className="align-self-center">
                  Move to digital care. Send Mobile app link
                </span>
              </div>
              <div class="form-group">
                <div className="row">
                  <div className="col-6">
                    <div className="checkBoxNumber">
                      <input
                        className={`form-control ${primaryEmail && 'not-empty'}`}
                        defaultValue={this.props.email}
                        onChange={e => {
                          this.setState({ primaryEmail: e.target.value });
                        }}
                      />
                      <label className="placeholder-label">Email Address</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="checkBoxNumber">
                      {/* <Checkbox
                    onChange={this.onChangeInvite}
                    className="d-inline-block dis-Ref mr-4"
                  /> */}
                      {/* <ReduxPhoneInput
                    className="form-control"
                    value={primaryPhone}
                    onChange={e => {
                      this.setState({ primaryPhone: e });
                    }}
                    placeholder="Please Enter your mobile number"
                  /> */}
                      <input
                        className={`form-control ${primaryPhone && 'not-empty'}`}
                        defaultValue={this.props.primaryPhone}
                        onChange={e => {
                          this.mobileNumberValueChange(e);
                        }}
                      />
                      <label className="placeholder-label">Mobile Number</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <p className="mt-2" style={{ textAlign: 'center' }}>
                  Status:
                  <span class="status">
                    {appDownloadStatus ? appDownloadStatus.AppStatus.substring(1) : 'Loading ...'}
                  </span>
                </p>
              </div>
              {/* <div class="form-group">
                <ReduxSelect
                  options={[
                    { Text: 'Adviser One', Value: 1 },
                    { Text: 'Adviser Two', Value: 2 },
                    { Text: 'Adviser Three', Value: 3 },
                  ]}
                  value={this.state.adviser}
                  className="basic-single"
                  placeholder="Adviser"
                  onChange={value =>
                    this.setState({
                      // isDischargeReasonReq: false,
                      adviser: value,
                    })
                  }
                />
              </div> */}
            </div>
          )}
        </div>

        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          {EnrollExitNote ? (
            <div class="d-flex">
              <button
                type="button"
                class="btn d-inline-block mr-1 btn-outline-primary mr-3"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Close
              </button>
              <AntdModal
                ModalButton={({ onClick }) => (
                  <button
                    class="btn black-btn"
                    onClick={() => {
                      onClick();
                      this.setState({ errMsg: false });
                    }}
                    // onClick={this.setState({ errMsg: false })}
                    style={{
                      display: 'inline',
                      marginBottom: '5px',
                    }}
                  >
                    <span>Reinstate </span>
                  </button>

                  // <p onClick={onClick}>
                  //   Click Here to Reinstate {sessionStorage.getItem('displayName')}
                  // </p>
                )}
                ModalContent={props => (
                  <ReinstateModal
                    reinstate={reinstate}
                    history={history}
                    errMsg={errMsg}
                    {...props}
                    fnAppRefModalClose={onCancel}
                    DischargeDate={DischargeDate}
                    preIntakeStatusData={preIntakeStatusData}
                    staffPermittedCenters={staffPermittedCenters}
                  />
                )}
              />
              {/* <p
                onClick={() => {
                  onOk();
                  // reinstate();
                }}
              >
                Click Here to Reinstate username
              </p> */}
            </div>
          ) : (
            <div>
              <button
                type="button"
                class="btn black-btn mr-1 btn-outline-primary"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Close
              </button>
              <button
                type="button"
                class="btn"
                onClick={() => {
                  this.fnStudentDischarge();
                }}
              >
                {' '}
                Discharge
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToPropsDischarge = ({
  combos,
  get_mobile_app_download_status,
  student_get_profile_dashboard_data,
}) => {
  let appDownloadStatus = '';
  let email = null,
    centerId = '',
    firstName = '',
    lastName = '';
  let reasonOptions = [];
  let staffPermittedCenters = [];
  if (get_mobile_app_download_status.data) {
    appDownloadStatus = get_mobile_app_download_status.data.result;
  }
  if (student_get_profile_dashboard_data.data) {
    email = student_get_profile_dashboard_data.data.Email || null;
    centerId = student_get_profile_dashboard_data.data.CenterId || null;
    firstName = student_get_profile_dashboard_data.data.FirstName || null;
    lastName = student_get_profile_dashboard_data.data.LastName || null;
  }
  if (combos.data && combos.data) {
    reasonOptions = combos.data.ExitReason ? combos.data.ExitReason : [];
    staffPermittedCenters = combos.data.StaffPermittedCenters
      ? combos.data.StaffPermittedCenters
      : [];
  }

  return {
    reasonOptions: reasonOptions,
    appDownloadStatus,
    email,
    centerId,
    firstName,
    lastName,
    staffPermittedCenters,
  };
};

// DischargeModal = connect(mapStateToPropsDischarge)(withRouter(DischargeModal));

DischargeModal = reduxForm({
  form: 'studentProfileForm',
  // Validator,
  enableReinitialize: true,
})(withRouter(DischargeModal));

const selector = formValueSelector('studentProfileForm');

DischargeModal = connect(mapStateToPropsDischarge)(DischargeModal);

class PanelHeader extends Component {
  render() {
    const { headerData } = this.props;
    let color = headerData.Color === 'blue' ? 'blue' : 'default';
    const statusText = {
      default: {
        text: 'Up Next',
        class: 'fa fa-lock mr-1',
      },
      blue: {
        text: 'Current Level',
        class: '',
      },
      green: {
        text: 'Level Achieved',
        class: 'fa fa-check',
      },
    };
    return (
      <div className="panel-header row">
        <div className="col-3 px-0">
          <div className="panel-header-left">
            <div className="outcome-icon">
              <img src={`images/Outcomes@2x_${color}.png`} alt="Outcomes" />
              <span>{headerData.Level}</span>
            </div>
            <span className="day-count">{headerData.Range}</span>
          </div>
        </div>
        <div className="col-9">
          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap">
              <span className="panel-title mr-2">{headerData.Heading}</span>
              <div className="mr-2 panel-title-status">
                <i class={statusText[headerData.Color].class} /> {statusText[headerData.Color].text}
              </div>
              {headerData.CompletedDate && (
                <span className="panel-date">{headerData.CompletedDate}</span>
              )}
            </div>
            <span className="panel-desc">{headerData.LevelDefinition}</span>
          </div>
        </div>
      </div>
    );
  }
}

class FullDetailModal extends Component {
  render() {
    const { onOk, onCancel, StudentOutcome } = this.props;

    return (
      <div class="modal-content border-0">
        <div class="modal-header  border-bottom-0 mb-2">
          {/* <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button> */}
          <h4 class="modal-title">Ladders & Measures</h4>
        </div>
        <div class="modal-body pt-3 mx-2 pb-0">
          <div class="row">
            <div class="col-12">
              <Collapse
                accordion
                bordered={false}
                defaultActiveKey={activePanel + 'blue'}
                expandIconPosition="right"
                // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                {StudentOutcome &&
                  StudentOutcome.map((item, index) => {
                    activePanel = index;
                    return (
                      <Panel
                        header={<PanelHeader headerData={item} />}
                        key={activePanel + item.Color}
                        className={`site-collapse-custom-panel-${item.Color}`}
                        showArrow={false}
                        style={{ height: '100%' }}
                      >
                        <div className="panel-text">
                          <ul>
                            {item.Question && item.Question.map(ques => <li>{ques.Question}</li>)}
                          </ul>
                        </div>
                      </Panel>
                    );
                  })}
              </Collapse>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center border-top-0 pb-3">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </button>
          {/* <button type="button" class="btn " onClick={onOk}>
            OK
          </button> */}
        </div>
      </div>
    );
  }
}
function getDefaultTab(props) {
  const query = new URLSearchParams(props.location.search);
  let tabName = query.get('tab_name');
  if (tabName === 'notes') {
    return 0;
  } else if (tabName === 'surveys') {
    return 1;
  } else if (tabName === 'tasks') {
    return 2;
  } else if (tabName === 'courses') {
    return 3;
  } else if (tabName === 'growthplan') {
    return 4;
  }
}
class StudentsProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPersonDtlCollapsed: true,
      isContactDtlCollapsed: true,
      currentModel: '',
      currentCourseCenterStudentId: -1,
      note: '',
      noteType: '',
      taskStudentId: '',
      surveyId: '',
      modal: false,
      selectedCourse: -1,
      assignCourseModal: false,
      courseDueDate: null,
      toggleActive: false,
      requestData: false,
      IsStudentDischarged: false,
      IsStudentReinstated: false,
      HederMsg: 'Client discharged successfully',
      reInstateMsg: 'Client reinstated successfully',
      IsInvitationSent: false,
      InvitationMsg: 'Mobile app referral successfully sent',
      viewTimelineModal: false,
      activeKey: getDefaultTab(props),
      reasonForNote: '',
      NotesStudent: [],
      isCourseNoteEmpty: false,
      isCourseAugmentDrawerOpen: false,
      isAugmentDrawerOpen: false,
    };
  }
  toggle = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };

  // success = () => {
  //   Modal.success({
  //     content: 'Mobile app referral successfully sent',
  //   });
  // };

  getStudentId() {
    return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }

  addNote = (noteType, note, toggleActive) => {
    this.state.requestData = true;
    if (note) {
        let validNote = note.replace(/\//g, '-');
        validNote = note.replace(/[</>]/g, '');
      let data = {
        Json: JSON.stringify({
          StudentNote: [
            {
              NoteTypeIds: noteType,
              StudentId: this.getStudentId(),
              IsConfidential: toggleActive == true ? 1 : 0,
              Note: validNote,
            },
          ],
        }),
      };
      this.props.dispatch(addStudentNote(data));
      this.setState({ note: '', noteType: '' });
    }
  };

  onTaskInComplete = (studentId, surveyId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      surveyId,
    };
    this.props.dispatch(surveyTaskMarkInComplete(data));
  };

  onTaskComplete = (taskStudentId, studentId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      taskStudentId,
    };
    this.props.dispatch(studentTaskMarkComplete(data));
  };

  onTaskUnAssign = (studentId, taskStudentId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      taskStudentId,
    };
    this.props.dispatch(studentTaskUnAssign(data));
  };

  onUnAssign(studentId, courseCenterStudentId) {
    this.state.requestData = true;
    var data = {
      studentId,
      courseCenterStudentId,
    };

    this.props.dispatch(studentUnAssign(data));
  }

  onMarkComplete(studentId, courseCenterStudentId, score, courseNotes) {
    this.state.requestData = true;
    var data = {
      studentId,
      courseCenterStudentId,
      Score: score,
      Note: courseNotes,
    };
    this.state.score = 0;
    this.state.courseNotes = null;
    // this.state.isCourseNoteEmpty = false;
    this.setState({
      isCourseNoteEmpty: false,
    });
    this.props.dispatch(studentMarkComplete(data));
  }

  onNoteDelete(studentnoteId, reason) {
    this.state.requestData = true;
    var data = {
      studentnoteId,
      reason,
    };
    // this.state.reasonForNote = ' ';
    this.props.dispatch(
      deleteStudentNote({
        json: JSON.stringify({
          Note: [
            {
              StudentNoteId: studentnoteId,
              Reason: reason,
            },
          ],
        }),
      }),
    );
  }

  onAssignSurvey = (surveyQuestionCategoryIds, dueDate, studentData) => {
    this.state.requestData = true;
    var item = {
      studentId: this.getStudentId(),
      surveyQuestionCategoryIds,
      studentIds: studentData,
      dueDate: stringDate(dueDate),
    };
    this.props.dispatch(surveyAssign(item));
  };

  onAssignExistingTask = (taskId, repeatFrequencyCode, dueDate, studentIds) => {
    this.state.requestData = true;
    var data = {
      studentIds,
      taskId,
      repeatFrequencyCode,
      dueDate: moment(dueDate).format('YYYY-MM-DD'),
    };
    this.props.dispatch(studentAddExistingTask(data));
  };

  assignCourse = (selectedCourse, courseDueDate, studentData) => {
    this.state.requestData = true;
    this.props.dispatch(
      addStudentCourse({
        courseIds: selectedCourse,
        studentIds: studentData,
        dueDate: stringDate(courseDueDate),
      }),
    );
  };
  formatMobile = num => {
    let mobVal = num.replace(/[&\/\\#/\-, +()$~%.'":*?<>{}]/g, '');
    return (mobVal = mobVal.slice(mobVal.length - 10));
  };
  onDischarge = (
    exitReasonId,
    exitDate,
    exitReasonNotes,
    inviteCheckBox,
    primaryPhone,
    primaryEmail,
  ) => {
    this.state.requestData = true;
    const { dispatch, StudentData } = this.props;
    const sid = this.getStudentId();
    if (inviteCheckBox) {
      const { FirstName, LastName } = StudentData;
      this.setState({
        inviteCheckBox: true,
        HederMsg:
          'Client discharged successfully <br/> <small> Mobile App Referral Link sent to Email Address/ Phone Number</small>',
      });
      // dispatch(
      //   appInvite({
      //     studentid: sid,
      //     PhoneNumber: this.formatMobile(primaryPhone),
      //   }),
      // );
      dispatch(
        appInviteDashboard({
          MobileNumber: (primaryPhone && this.formatMobile(primaryPhone)) || '',
          Email: primaryEmail,
          FirstName,
          LastName,
          IsDischarge: true,
          CenterId: StudentData.CenterId,
        }),
      );
    }
    var data = {
      studentId: sid,
      exitReasonId,
      exitDate: stringDate(exitDate),
      exitReasonNotes,
    };

    dispatch(studentDischarge(data));
  };

  onAssignNewTask = (
    taskName,
    dueDate,
    StudentIds,
    togglePreDefinedTask,
    taskId,
    TaskStudentId,
    RepeatFrequencyCode,
    RepeatDayOfWeek,
  ) => {
    this.state.requestData = true;
    var json = {
      Task: [
        {
          TaskId: taskId || null,
          Title: taskName,
          //"Detail": "Walk the dog around the park",
          TaskStudent: [
            {
              TaskStudentId: TaskStudentId || null,
              RepeatFrequencyCode: RepeatFrequencyCode || null,
              RepeatDayOfWeek: RepeatDayOfWeek || null,
              DueDate: moment(dueDate).format('YYYY-MM-DD'),
              //"FinishedDate": "2019-01-23T00:00:00"
            },
          ],
          IsPreDefinedTask: String(togglePreDefinedTask) == 'true',
        },
      ],
    };
    var data = {
      studentIds: StudentIds,
      json: JSON.stringify(json),
    };
    this.props.dispatch(studentTaskUpsert(data));
  };

  reinstate = (dueDate, reinstateNote, enrollStatusId, studentReinstateCenter) => {
    this.state.requestData = true;
    this.props.dispatch(
      studentReinstate({
        StudentId: this.getStudentId(),
        ReinstateDate: stringDate(dueDate),
        Note: reinstateNote,
        EnrollStatusId: enrollStatusId ? 3 : null,
        CenterId: studentReinstateCenter,
      }),
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.props.dispatch(getStudent(this.getStudentId()));
    this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
    this.props.dispatch(studentNoteListGet({ StudentId: this.getStudentId() }));
    this.props.dispatch(
      studentCourseAugmentationGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.getStudentId(),
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      listCombos({
        comboTypes: 'SurveyOptions,TaskOptions,ExitReason,Courses,NoteType',
        StudentId: this.getStudentId(),
      }),
    );
    this.props.dispatch(getStudentNote({ StudentId: this.getStudentId() }));
  }

  componentWillReceiveProps({
    isNoteAdded,
    isCourseAdded,
    currentModel,
    isMarkComplete,
    isUnAssigned,
    isTaskMarkComplete,
    isTaskUnAssigned,
    isTaskMarkInComplete,
    isAssigned,
    isNewTaskCreated,
    isExistingTaskMapped,
    isStudentDischarged,
    isReinstate,
    StudentData,
    appInviteSMS,
    EnrollStatus,
    isMarkObservation,
    StudentMisc,
    isGrowthPlanReset,
    surveyAssignResponse,
    isNoteDeleted,
    NotesStudent,
    surveyanswersdata,
    AugmentedAIStatusData,
  }) {
    if (JSON.stringify(AugmentedAIStatusData) != JSON.stringify(this.props.AugmentedAIStatusData)) {
      if (AugmentedAIStatusData && AugmentedAIStatusData.StudentId == null) {
        this.props.dispatch(
          userFeatureFlagGet({
            JsonInput: JSON.stringify({ Center: { StudentId: this.getStudentId() } }),
          }),
        );
      }
    }
    if (
      surveyanswersdata &&
      surveyanswersdata.length > 0 &&
      JSON.stringify(surveyanswersdata) != JSON.stringify(this.props.surveyanswersdata)
    ) {
      this.setState({ surveyanswersdata, isResultDrawerOpen: true });
    }
    if (NotesStudent && NotesStudent != this.props.NotesStudent) {
      this.setState({
        NotesStudent: NotesStudent,
      });
    }
    if (StudentMisc && StudentMisc.SystemObservation) {
      this.setState({
        SystemObservation: StudentMisc.SystemObservation,
      });
    }
    if (
      EnrollStatus == 'Discharged' &&
      this.props.EnrollStatus == 'Discharged' &&
      isStudentDischarged == false &&
      this.props.isStudentDischarged == false &&
      !this.state.inviteCheckBox
    ) {
      // isDischarged = isDischarged === 'Discharged' Enrolled;
      if (appInviteSMS && appInviteSMS != this.props.appInviteSMS) {
        // this.success();
        this.setState({
          IsInvitationSent: true,
        });
      }
    }

    if (StudentData.DisplayName != this.props.StudentData.DisplayName && StudentData.DisplayName) {
      sessionStorage.setItem('displayName', StudentData.DisplayName);
    }
    if (isMarkObservation && isMarkObservation != this.props.isMarkObservation) {
      // this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
    }
    if (isGrowthPlanReset && isGrowthPlanReset != this.props.isGrowthPlanReset) {
      this.props.dispatch(resetUpsertContract());
      this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
    }
    if (isNoteAdded && isNoteAdded != this.props.isNoteAdded) {
      this.props.dispatch(resetStudentNote());
    } else if (isCourseAdded) {
      this.setState({ selectedCourse: -1, assignCourseModal: false });
      this.props.dispatch(resetAddStudentCourse());
      this.props.dispatch(
        listCombos({
          comboTypes: 'SurveyOptions,TaskOptions,ExitReason,Courses,NoteType',
          StudentId: this.getStudentId(),
        }),
      );
    } /* else if(currentModel && currentModel != ''){
          this.setState({
            currentCourseCenterStudentId: -1,
            note: '',
            noteType: '',
            taskStudentId: '',
            surveyId: '',
            selectedCourse: -1,
            courseDueDate: null,
          });
        }*/
    if (isExistingTaskMapped) this.props.dispatch(resetStudentAddExistingTask());
    if (isTaskUnAssigned) this.props.dispatch(resetStudentTaskUnAssign());
    if (isTaskMarkComplete) this.props.dispatch(resetStudentTaskMarkComplete());
    if (isMarkComplete) this.props.dispatch(resetStudentMarkComplete());
    if (isUnAssigned) this.props.dispatch(resetStudentUnAssign());
    if (isAssigned) this.props.dispatch(resetSurveyAssign());
    // if (isReinstate) this.props.dispatch(resetStudentReinstate());
    if (isNewTaskCreated) this.props.dispatch(resetStudentTaskUpsert());
    if (isTaskMarkInComplete) this.props.dispatch(resetSurveyTaskMarkInComplete());
    if (isGrowthPlanReset) this.props.dispatch(resetUpsertContract());
    // if (isStudentDischarged) this.props.dispatch(resetStudentDischarge());
    if (isStudentDischarged && isStudentDischarged != this.props.isStudentDischarged) {
      this.setState({ IsStudentDischarged: true }, () => {
        // this.state.inviteCheckBox = false;
      });
      this.props.dispatch(resetStudentDischarge());
    }

    if (isReinstate && isReinstate != this.props.isReinstate) {
      this.setState({ IsStudentReinstated: true }, () => {
        // this.state.inviteCheckBox = false;
      });
      this.props.dispatch(resetStudentReinstate());
    }

    if (isAssigned && this.state.requestData) {
      this.state.requestData = false;

      this.setState({
        IsSurveyAssign: true,
        surveySuccessMsg: surveyAssignResponse,
      });
    }
    if (isMarkComplete) {
      this.props.dispatch(
        studentCourseAugmentationGet({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: this.getStudentId(),
              },
            ],
          }),
        }),
      );
    }
    if (
      // isNoteDeleted ||
      (isMarkComplete ||
        isUnAssigned ||
        isTaskMarkComplete ||
        isTaskUnAssigned ||
        isTaskMarkInComplete ||
        isNewTaskCreated ||
        // isNoteAdded ||
        isExistingTaskMapped ||
        isCourseAdded ||
        isStudentDischarged ||
        isReinstate) &&
      this.state.requestData
    ) {
      this.state.requestData = false;
      this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
    }
    if (isNoteAdded && isNoteAdded != this.props.isNoteAdded) {
      this.props.dispatch(studentNoteListGet({ StudentId: this.getStudentId() }));
      // this.state.requestData = false;
      // this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
    }
    if (isNoteDeleted && isNoteDeleted != this.props.isNoteDeleted) {
      this.props.dispatch(studentNoteListGet({ StudentId: this.getStudentId() }));
      // this.state.requestData = false;
      // this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(nextProps.StudentData) != JSON.stringify(this.props.StudentData) ||
      JSON.stringify(nextProps.AugmentedAIStatusData) !=
        JSON.stringify(this.props.AugmentedAIStatusData) ||
      nextState.IsStudentDischarged != this.state.IsStudentDischarged ||
      nextState.IsStudentReinstated != this.state.IsStudentReinstated ||
      nextState.IsInvitationSent != this.state.IsInvitationSent ||
      this.state.viewTimelineModal != nextState.viewTimelineModal ||
      JSON.stringify(nextProps.isMarkObservation) != JSON.stringify(this.props.isMarkObservation) ||
      JSON.stringify(nextProps.surveyanswersdata) != JSON.stringify(this.props.surveyanswersdata) ||
      nextProps.isAssigned != this.props.isAssigned ||
      nextState.IsSurveyAssign != this.state.IsSurveyAssign ||
      nextState.isResultDrawerOpen != this.state.isResultDrawerOpen ||
      nextState.NotesStudent != this.state.NotesStudent ||
      JSON.stringify(nextProps.isAugmentAgreed) != JSON.stringify(this.props.isAugmentAgreed)
      // nextProps.isNoteDeleted != this.props.isNoteDeleted
    ) {
      return true;
    } else {
      return false;
    }
  }
  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = imageSrc => {
    // const imageSrc = this.webcam.getScreenshot();
    const me = this;
    Axios.post('student/UploadImage', {
      image: imageSrc,
      studentId: this.getStudentId(),
      IsProfilePic: true,
    })
      .then(response => {
        me.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };

  toggle = name => {
    this.setState({
      [name]: !this.state[name],
      currentCourseCenterStudentId: -1,
      note: '',
      noteType: '',
      taskStudentId: '',
      surveyId: '',
      selectedCourse: -1,
      courseDueDate: null,
    });
  };

  onDateFieldChange(date) {
    if (date) {
      this.setState({ courseDueDate: date.format('YYYY-MM-DD') });
    }
  }

  onSelectSurvey = (surveyId, studentId, IsForm) => {
    //this.props.dispatch(loadStudentDefaultValues({ studentId: studentId }));
    const me = this;
    const isWebCam = localStorage.getItem('isWebCam') === '0' ? false : true;
    if (isWebCam) {
      Axios.post('Student/LoadStudentDefaultValues', { studentId: studentId }).then(response => {
        if (response.data.data.isNewImageRequired == 1 && isWebCam) {
          me.props.history.push({
            pathname: '/camera',
            search:
              'surveyId=' +
              surveyId +
              '&form=' +
              IsForm +
              '&studentId=' +
              studentId +
              '&ignoreUpdatingPin=' +
              !response.data.data.IsPinValidate,
          });
        } else if (response.data.data.isFacialAuthRequired) {
          me.props.history.push({
            pathname: '/authentication',
            search:
              'surveyId=' +
              surveyId +
              '&form=' +
              IsForm +
              '&studentId=' +
              studentId +
              '&ignoreUpdatingPin=' +
              !response.data.data.IsPinValidate,
          });
        } else {
          me.props.history.push({
            pathname: '/validate',
            search:
              'surveyId=' +
              surveyId +
              '&form=' +
              IsForm +
              '&studentId=' +
              studentId +
              '&ignoreUpdatingPin=' +
              !response.data.data.IsPinValidate,
          });
        }
      });
    } else {
      me.props.history.push({
        pathname: '/validate',
        search: 'surveyId=' + surveyId + '&form=' + IsForm + '&studentId=' + studentId,
      });
    }
  };

  exitReason = () => {
    AntdModal.info({
      title: 'Exit Reason',
      content: <div>{this.props.EnrollExitNote}</div>,
      onOk() {},
    });
  };
  formatMobile = num => {
    let mobVal = num.replace(/[&\/\\#/\-, +()$~%.'":*?<>{}]/g, '');
    return (mobVal = mobVal.slice(mobVal.length - 10));
  };
  handelObservation = data => {
    let { SystemObservation } = this.state;
    let { isAccepted, observationId } = data;
    let json = {
      Student: [
        { StudentId: this.getStudentId(), ObservationId: observationId, IsAccepted: isAccepted },
      ],
    };
    var data = {
      json: JSON.stringify(json),
    };
    this.props.dispatch(markSystemObservation(data));
    // let systemObservationObj = SystemObservation.find(obj => obj.ObservationId === observationId);
    let objIndex = SystemObservation.findIndex(obj => obj.ObservationId === observationId);

    SystemObservation[objIndex].IsAccepted = isAccepted;

    this.setState({
      SystemObservation,
    });
  };
  toggleDrawer = () => {
    this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
  };
  render() {
    const {
      StudentData: {
        AdmissionDate,
        DischargeDate,
        FirstName,
        LastName,
        MiddleName,
        NoteStudent,
        SurveyStudent,
        TaskStudent,
        CourseStudent,
        StudentContract,
        StudentMilestones,
        StudentOutcome,
      },
      StudentMisc,
      isFetching,
      EnrollStatus,
      EnrollExitNote,
      isDischarged,
      ContractId,
      primaryPhone,
      ShowBreathalyzer,
      Breathalyzer,
      FeatureFlags,
      NotesStudent,
      preIntakeStatusData,
      isHideNotesTab,
      courseAugNotes,
    } = this.props;
    this.setState({ NotesStudent: NotesStudent });
    const StudentOutcomeData = StudentOutcome.slice(0, 3);
    const { toggleActive, note, noteType, SystemObservation } = this.state;
    var a = StudentMisc.BehaviorData && StudentMisc.BehaviorData.split(',');
    const { BreathalyzerTestResult, LastTestResult } = Breathalyzer;
    const { TestDate, ResultClassBlock, ResultClassText, ResultText } =
      (LastTestResult && LastTestResult[0]) || {};
    const barData = {
      labels: StudentMisc.LadderChartLabels && StudentMisc.LadderChartLabels.split(','),
      datasets: [
        {
          data: StudentMisc.LadderChartData && StudentMisc.LadderChartData.split(','),
          backgroundColor: ['#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd', '#45b5fd'],
        },
      ],
    };
    const behaviourData = {
      labels: StudentMisc.BehaviorLabels && StudentMisc.BehaviorLabels.split(','),
      datasets: [
        {
          data: StudentMisc.BehaviorData && StudentMisc.BehaviorData.split(','),
        },
      ],
    };

    const barOptions = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            maxBarThickness: 66,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    const notesList = [
      {
        Name: 'My Notes',
        ExtraComponent: () => (
          <NotesExtraComponent
            isDischarged={isDischarged}
            addNote={this.addNote}
            FeatureFlags={this.props.FeatureFlags}
          />
        ),
        Component: props => {
          const {
            StudentNoteId,
            Note,
            NoteTypes,
            IsConfidential,
            DateEntered,
            EnteredBy,
            IsArchived,
            IsDischargeNote,
            IsDeleteAllowed,
            FeatureFlags,
          } = props;
          return (
            <div class="note-box">
              <div class="row">
                <Loader2 loading={isFetching} />
                <div class="col-12 col-md-7">
                  <h6 class="note-type">
                    {NoteTypes}
                    {IsConfidential && (
                      <span class="ml-2 alert alert-danger confidential-alert border-0 text-uppercase">
                        <i class="fas fa-lock" /> Confidential
                      </span>
                    )}
                  </h6>
                  <span class="note-author">by {EnteredBy}</span>
                </div>
                <div class="col-12 col-md-5">
                  <span class="note-date">{DateEntered}</span>
                  {!IsArchived && !IsDeleteAllowed && (
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Archive">
                          <span
                            // class="note-date"
                            onClick={onClick}
                            style={{
                              color: '#e87a7a',
                              cursor: 'pointer',
                              position: 'absolute',
                              right: '17px',
                            }}
                          >
                            <img src="images/archive.svg" />
                          </span>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              &times;
                            </button>
                            <h4 class="modal-title col-12">
                              Are you sure you want to archive this note?
                            </h4>
                          </div>
                          <div className="modal-body">
                            <div className="mb-3 row">
                              <label
                                for="noteReason"
                                className="col-sm-3 col-form-label text-right"
                              >
                                Reason*
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="test"
                                  className="form-control"
                                  id="noteReason"
                                  onChange={e => {
                                    this.setState({ reasonForNote: e.target.value });
                                  }}
                                />
                                <span className="text-red">Please enter the reason</span>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn "
                              onClick={() => {
                                if (this.state.reasonForNote) {
                                  onOk();
                                  this.onNoteDelete(StudentNoteId, this.state.reasonForNote);
                                }
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div>
                <div class="col-12 pt-2">
                  {IsDeleteAllowed ? (
                    <p class="note-text mb-0">{Note && renderHTML(Note)}</p>
                  ) : (
                    <p class="note-text mb-0">{Note}</p>
                  )}
                </div>
              </div>
            </div>
          );
        },
        Content: this.state.NotesStudent || [],
      },
      {
        Name: 'Journal Entries',
        ExtraComponent: props => (
          <NotesFromClient StudentId={this.getStudentId()} {...this.props} />
        ),
        noContent: true,
        // shouldRender: false,
      },
    ];

    let list = [
      // {
      //   Name: 'Notes',
      //   ExtraComponent: () => {
      //     return (
      //       <div className="Activities student-progress">
      //         <ListRenderer
      //           list={notesList}
      //           activeKey={this.state.subTabKey1}
      //           getActiveKey={subTabKey1 => this.setState({ subTabKey1 })}
      //         />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Name: 'Notes',
      //   ExtraComponent: () => (
      //     <NotesExtraComponent isDischarged={isDischarged} addNote={this.addNote} />
      //   ),
      //   Component: props => {
      //     const { StudentNoteId, Note, NoteTypes, IsConfidential, DateEntered, EnteredBy } = props;
      //     return (
      //       <div class="note-box">
      //         <div class="row">
      //           <div class="col-12 col-md-7">
      //             <h6 class="note-type">
      //               {NoteTypes}
      //               {IsConfidential && (
      //                 <span class="ml-2 alert alert-danger confidential-alert border-0 text-uppercase">
      //                   <i class="fas fa-lock" /> Confidential
      //                 </span>
      //               )}
      //             </h6>
      //             <span class="note-author">by {EnteredBy}</span>
      //           </div>
      //           <div class="col-12 col-md-5">
      //             <span class="note-date">{DateEntered}</span>
      //           </div>
      //           <div class="col-12 pt-2">
      //             <p class="note-text mb-0">{Note}</p>
      //           </div>
      //         </div>
      //       </div>
      //     );
      //   },
      //   Content: NoteStudent || [],
      // },
      {
        Name: 'Surveys',
        ExtraComponent: () => {
          return (
            <div>
              <CustomModal
                ModalButton={({ onClick }) => (
                  <div class="modal-section text-center mb-4 mt-2">
                    <button
                      class="btn btn-outline-primary px-5"
                      onClick={onClick}
                      disabled={isDischarged}
                    >
                      + Assign Survey
                    </button>
                  </div>
                )}
                ModalContent={props => (
                  <SurveysModalContent onAssignSurvey={this.onAssignSurvey} {...props} />
                )}
              />
            </div>
          );
        },
        Component: props => {
          const {
            SurveyQuestionCategoryIds,
            SurveyName,
            SurveyId,
            StudentId,
            AssignedByStaffId,
            AssignedDate,
            DueDate,
            StartedDate,
            DueStatus,
            FirstName,
            LastName,
            ProfileImgAttachmentId,
            StudentImageUrl,
            DueDateFontColor,
            SurveyStatus,
            CompletedDate,
            DiscardedDate,
            IsForm,
          } = props;
          const surveyStatus = {
            '-1': { color: '#fef2f2', hide: true, date: DiscardedDate, name: 'Discarded' },
            '0': {
              color: '#f8f8f8',
              hide: false,
              date: DueDate,
              name: 'Due',
              dateFontColor: DueDateFontColor,
            },
            '1': {
              color: 'rgb(239, 247, 254)',
              hide: true,
              date: CompletedDate,
              name: 'Completed',
              sendRequest: true,
            },
          };

          return (
            <div
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <div
                class="task-box"
                style={{ cursor: 'pointer', backgroundColor: surveyStatus[SurveyStatus]['color'] }}
              >
                <div class="w-100 d-flex justify-content-between align-items-center">
                  <div
                    className="w-100"
                    onClick={() => {
                      !surveyStatus[SurveyStatus]['hide'] &&
                        // this.props.history.push(
                        //   '/validate?surveyId=' + SurveyId + '&studentId=' + StudentId,
                        // );
                        this.onSelectSurvey(SurveyId, StudentId, IsForm);

                      surveyStatus[SurveyStatus].sendRequest &&
                        this.props.dispatch(
                          surveyAnswersGet({ studentId: this.getStudentId(), surveyId: SurveyId }),
                        );
                      this.state.answerRecord = {
                        studentId: this.getStudentId(),
                        surveyId: SurveyId,
                        SurveyCategory: SurveyName,
                        CompletedDt: CompletedDate,
                      };
                    }}
                  >
                    <div class="course-name" style={{ width: 'unset' }}>
                      <Paragraph
                        ellipsis={{ rows: 1, expandable: false }}
                        textAlign="justify"
                        style={{ color: '#000000', fontSize: '17px', marginBottom: '0px' }}
                      >
                        {SurveyName}
                      </Paragraph>
                      <div class="d-flex">
                        <div class="flex-fill">
                          <span class="due-date" style={{ minWidth: 'unset' }}>
                            <em>Assigned:</em> {AssignedDate}
                          </span>
                        </div>
                        <div class="flex-fill">
                          <span
                            class="due-date"
                            style={{
                              color: surveyStatus[SurveyStatus].dateFontColor,
                              minWidth: 'unset',
                            }}
                          >
                            <em>{surveyStatus[SurveyStatus].name} : </em>{' '}
                            {surveyStatus[SurveyStatus].date}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="actions-button justify-content-xl-end">
                      {!surveyStatus[SurveyStatus]['hide'] && (
                        <CustomModal
                          ModalButton={({ onClick }) => (
                            <Tooltip placement="top" title={'Incomplete'}>
                              <span class="delete-course" onClick={onClick}>
                                <i class="fas fa-times" />
                              </span>
                            </Tooltip>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <h4 class="modal-title col-12">
                                  Do you want to mark this survey as incomplete?
                                </h4>
                              </div>

                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  class="btn btn-outline-primary"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  class="btn "
                                  onClick={() => {
                                    onOk();
                                    this.onTaskInComplete(StudentId, SurveyId);
                                  }}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: SurveyStudent || [],
      },
      {
        Name: 'Tasks',
        ExtraComponent: props => {
          return (
            <div class="d-flex justify-content-around">
              <div>
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <div class="modal-section text-center mb-4" style={{ height: '74px' }}>
                      <button
                        class="btn btn-outline-primary"
                        onClick={onClick}
                        disabled={isDischarged}
                      >
                        Assign Pre-defined Task
                      </button>
                    </div>
                  )}
                  ModalContent={props => (
                    <TasksModalContent
                      onAssignExistingTask={this.onAssignExistingTask}
                      {...props}
                    />
                  )}
                />
              </div>
              <div>
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <div class="modal-section text-center mb-4" style={{ height: '74px' }}>
                      <button
                        class="btn btn-outline-primary"
                        onClick={onClick}
                        disabled={this.props.isDischarged}
                      >
                        Assign Task
                      </button>
                    </div>
                  )}
                  ModalContent={props => (
                    <NewTasksModalContent onAssignNewTask={this.onAssignNewTask} {...props} />
                  )}
                />
              </div>
            </div>
          );
        },
        Component: props => {
          const {
            TodoType,
            TodoTitle,
            TaskId,
            TaskStudentId,
            StudentId,
            FirstName,
            LastName,
            ProfileImgAttachmentId,
            StudentImageUrl,
            AssignedDate,
            AssignedDateForSort,
            DueDate,
            DueDateString,
            DueDateForSort,
            DueDateFontColor,
            TaskStatus,
            UnassignDate,
            FinishedDate,
            RepeatFrequencyCode,
            RepeatDayOfWeek,
          } = props;
          const taskStatus = {
            '-1': { color: '#fef2f2', hideButtons: true, date: UnassignDate, name: 'Unassigned' },
            '0': {
              color: '#f8f8f8',
              hideButtons: false,
              date: DueDateString,
              dateFontColor: DueDateFontColor,
              name: 'Due',
            },
            '1': {
              color: 'rgb(239, 247, 254)',
              hideButtons: true,
              date: FinishedDate,
              name: 'Completed',
            },
          };
          return (
            <div
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <div class="task-box" style={{ backgroundColor: taskStatus[TaskStatus].color }}>
                <div class="w-100 d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <div class="course-name" style={{ width: 'unset' }}>
                      <h6>{TodoTitle}</h6>
                      <div class="d-flex">
                        <div class="flex-fill">
                          <span class="due-date" style={{ minWidth: 'unset' }}>
                            <em>Assigned:</em> {AssignedDate}
                          </span>
                        </div>
                        <div class="flex-fill">
                          <span
                            class="due-date"
                            style={{
                              color: taskStatus[TaskStatus].dateFontColor,
                              minWidth: 'unset',
                            }}
                          >
                            <em>{taskStatus[TaskStatus].name} : </em> {taskStatus[TaskStatus].date}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="actions-button justify-content-xl-end">
                      {!taskStatus[TaskStatus].hideButtons && (
                        <CustomModal
                          ModalButton={({ onClick }) => (
                            <Tooltip placement="top" title="Un-assign">
                              <span class="delete-course" onClick={onClick}>
                                <i class="fas fa-times" />
                              </span>
                            </Tooltip>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <h4 class="modal-title col-12">
                                  Do you want to un-assign this task?
                                </h4>
                              </div>

                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  class="btn btn-outline-primary"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  class="btn "
                                  onClick={() => {
                                    onOk();
                                    this.onTaskUnAssign(StudentId, TaskStudentId);
                                  }}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      )}
                      {!taskStatus[TaskStatus].hideButtons && (
                        <CustomModal
                          ModalButton={({ onClick }) => (
                            <Tooltip placement="top" title="Complete">
                              <span class="select-course" onClick={onClick}>
                                <i class="select-icon" />
                              </span>
                            </Tooltip>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <h4 class="modal-title col-12">
                                  Do you want to mark this task as complete?
                                </h4>
                              </div>

                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  class="btn btn-outline-primary"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  class="btn "
                                  onClick={() => {
                                    onOk();
                                    this.onTaskComplete(TaskStudentId, StudentId);
                                  }}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      )}
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Edit">
                            <span class="select-course" onClick={onClick}>
                              <img src="images/edit-profile-icon.svg" alt="" width="23" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={props => {
                          return (
                            <NewTasksModalContent
                              isEdit={true}
                              onAssignNewTask={this.onAssignNewTask}
                              taskId={TaskId}
                              TaskStudentId={TaskStudentId}
                              taskName={TodoTitle}
                              dueDate={DueDate}
                              studentIds={StudentId}
                              togglePreDefinedTask={TaskStatus}
                              RepeatFrequencyCode={RepeatFrequencyCode}
                              RepeatDayOfWeek={RepeatDayOfWeek}
                              {...props}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: TaskStudent || [],
      },
      {
        Name: 'Courses',
        ExtraComponent: props => {
          const { courseAugNotes, AugmentedAIStatusData } = this.props;
          const { Answer } = courseAugNotes || {};
          return (
            <div>
              {/* {AugmentedAIStatusData && (
                <>
                  <CustomModal
                    ModalButton={({ onClick }) => (
                      <div class="modal-section text-center mb-4">
                        <button
                          class="btn btn-outline-primary px-5"
                          onClick={onClick}
                          disabled={isDischarged}
                        >
                          + Assign a Course
                        </button>
                      </div>
                    )}
                    ModalContent={props => (
                      <CoursesModalContent assignCourse={this.assignCourse} {...props} />
                    )}
                  />
                  <CommonLicenceScreens
                    StatusData={this.props.AugmentedAIStatusData}
                    moduleName={'AugmentedAI'}
                  />
                </>
              )} */}
              <>
                <div class="row mb-2">
                  <div class="col-12">
                    <>
                      <div
                        className={
                          AugmentedAIStatusData &&
                          AugmentedAIStatusData.LicenseStatusId != 1 &&
                          AugmentedAIStatusData.LicenseStatusId != 3 &&
                          AugmentedAIStatusData.LicenseStatusId != 5 &&
                          'd-flex align-items-center justify-content-around row'
                        }
                      >
                        <CustomModal
                          ModalButton={({ onClick }) => (
                            <div class="modal-section text-center mb-4 col-6">
                              <button
                                class="btn btn-outline-primary px-5"
                                onClick={onClick}
                                disabled={isDischarged}
                              >
                                + Assign a Course
                              </button>
                            </div>
                          )}
                          ModalContent={props => (
                            <CoursesModalContent assignCourse={this.assignCourse} {...props} />
                          )}
                        />
                        {AugmentedAIStatusData &&
                          AugmentedAIStatusData.LicenseStatusId != 1 &&
                          AugmentedAIStatusData.LicenseStatusId != 3 &&
                          AugmentedAIStatusData.LicenseStatusId != 5 && (
                            <div className="col-6">
                              <AugmentWithAI isEnterprise={true} studentId={this.getStudentId()} />
                            </div>
                          )}

                        {AugmentedAIStatusData && (
                          <div className="col-12">
                            <CommonLicenceScreens
                              StatusData={this.props.AugmentedAIStatusData}
                              moduleName={'AugmentedAI'}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </>
              {/* <div className="d-flex align-items-center justify-content-around">
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <div class="modal-section text-center mb-4">
                      <button
                        class="btn btn-outline-primary px-5"
                        onClick={onClick}
                        disabled={isDischarged}
                      >
                        + Assign a Course
                      </button>
                    </div>
                  )}
                  ModalContent={props => (
                    <CoursesModalContent assignCourse={this.assignCourse} {...props} />
                  )}
                />

                <AugmentWithAI isEnterprise={true} studentId={this.getStudentId()} />
              </div> */}
              {Answer && (
                <p
                  style={{
                    color: '#BF6094',
                    fontWeight: '600',
                  }}
                >
                  Augmented Summary
                </p>
              )}
              <p>{Answer && renderHTML(Answer)}</p>
            </div>
          );
        },
        Component: CourseStudent => {
          // const [courseScoreErr, changeCourseScoreErr] = useState();
          const {
            CourseCenterStudentId,
            CourseId,
            CenterId,
            StudentId,
            EnrollDate,
            DueDate,
            DueDateString,
            DueDateForSort,
            DueDateFontColor,
            CourseName,
            CourseDescription,
            CourseStatus,
            UnassignDate,
            CompletedDate,
            Score,
          } = CourseStudent;
          const courseStatus = {
            '-1': {
              color: '#fef2f2',
              hideButtons: true,
              date: UnassignDate,
              name: 'Unassigned On',
            },
            '0': {
              color: '#f8f8f8',
              hideButtons: false,
              date: DueDateString,
              name: 'To be Finished',
              dateFontColor: DueDateFontColor,
            },
            '1': {
              color: 'rgb(239, 247, 254)',
              hideButtons: true,
              date: CompletedDate,
              name: 'Completed On',
            },
          };
          return (
            <div
              className="course-box"
              style={{ backgroundColor: courseStatus[CourseStatus].color }}
            >
              <div className="course-box-container">
                <div className="course-info">
                  <span className="course-name" style={{ width: 'unset' }}>
                    {CourseName}
                  </span>
                  <div className="course-description">
                    <span>{CourseDescription}</span>
                  </div>
                  <div className="row no-gutters w-100">
                    <div className="enroll-date course-date mb-1 col-12 col-md-6">
                      Enrolled on <em className="date">{EnrollDate}</em>
                    </div>
                    <div className="Finish-date course-date mb-1 col-12 col-md-6">
                      {courseStatus[CourseStatus].name}
                      <em
                        className="date"
                        style={{ color: courseStatus[CourseStatus].dateFontColor }}
                      >
                        {courseStatus[CourseStatus].date}
                      </em>
                    </div>
                    {CourseStatus === 1 && (
                      <div className="enroll-date course-date mb-1 col-12 col-md-6">
                        Score <em className="date">{Score}</em>
                      </div>
                    )}
                  </div>
                </div>
                <div className="actions-button">
                  {CourseStatus === 1 && (
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Un-assign">
                          <span className="delete-course" onClick={onClick}>
                            <i className="fas fa-times" />
                          </span>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div className="modal-content border-0">
                          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              &times;
                            </button>
                            <h4 className="modal-title col-12">
                              Are you sure you want to unassign?
                            </h4>
                          </div>

                          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn "
                              onClick={() => {
                                onOk();
                                this.onUnAssign(StudentId, CourseCenterStudentId);
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                  {!courseStatus[CourseStatus].hideButtons && (
                    <>
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Un-assign">
                            <span className="delete-course" onClick={onClick}>
                              <i className="fas fa-times" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div className="modal-content border-0">
                            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 className="modal-title col-12">
                                Are you sure you want to unassign?
                              </h4>
                            </div>

                            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn "
                                onClick={() => {
                                  onOk();
                                  this.onUnAssign(StudentId, CourseCenterStudentId);
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                      />
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Complete">
                            <span class="select-course" onClick={onClick}>
                              <i class="select-icon" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 class="modal-title col-12">
                                Are you sure you want to mark this course as complete?
                              </h4>
                            </div>
                            <div className="modal-body">
                              <div className="mb-3 row">
                                <label
                                  for="CourseScore"
                                  className="col-sm-3 col-form-label text-right"
                                >
                                  Course Score*
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="CourseScore"
                                    // value={this.state.score}
                                    onChange={e => {
                                      this.setState({ score: Number(e.target.value) });

                                      // let scoreval = e.target.value;
                                      // let pat = /^[0-9]{0,2}$/;
                                      // if (scoreval.match(pat) && scoreval > 0) {
                                      //   debugger;
                                      //   this.setState({ score: Number(scoreval) });
                                      //   // this.setCourseScore(Number(scoreval) || 0);
                                      // } else {
                                      //   return;
                                      // }
                                    }}
                                  />
                                  <span className="text-red">
                                    Please enter a score to mark this course complete
                                  </span>
                                  {/* {courseScoreErr && (
                                    <span className="text-red" style={{ color: 'red' }}>
                                      {courseScoreErr}
                                    </span>
                                  )}{' '} */}
                                </div>
                              </div>
                              <div>
                                <textarea
                                  className={`form-control`}
                                  value={this.state.courseNotes}
                                  onChange={event => {
                                    this.setState({ courseNotes: event.target.value });
                                    if (event.target.value.length <= 0) {
                                      this.setState({
                                        isCourseNoteEmpty: true,
                                      });
                                      // this.state.isCourseNoteEmpty = true;
                                    } else {
                                      this.setState({
                                        isCourseNoteEmpty: false,
                                      });
                                      // this.state.isCourseNoteEmpty = false;
                                    }
                                  }}
                                  rows="4"
                                  key="courseNotes"
                                  placeholder={'Notes*'}
                                  style={{ height: '70px' }}
                                />
                                {this.state.isCourseNoteEmpty && (
                                  <p className="error-text text-right">Notes required</p>
                                )}
                              </div>
                            </div>
                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                class="btn btn-outline-primary"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                class="btn "
                                onClick={() => {
                                  if (this.state.score && this.state.courseNotes) {
                                    onOk();
                                    this.onMarkComplete(
                                      StudentId,
                                      CourseCenterStudentId,
                                      this.state.score,
                                      this.state.courseNotes,
                                    );
                                  } else {
                                    this.setState({
                                      isCourseNoteEmpty: true,
                                    });
                                  }
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                      />
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Edit">
                            <span class="select-course" onClick={onClick}>
                              <img src="images/edit-profile-icon.svg" alt="" width="23" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={props => {
                          return (
                            <CoursesModalContent
                              isEdit={true}
                              assignCourse={this.assignCourse}
                              CourseId={CourseId}
                              DueDate={DueDate}
                              {...props}
                            />
                          );
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        },
        Content: CourseStudent || [],
      },
      {
        Name: 'Growth Plan',
        ExtraComponent: props => {
          return (
            <div class="row">
              <div class="col-12">
                <div class="modal-section text-center mb-4">
                  <button
                    class="btn mr-2"
                    onClick={() =>
                      this.props.history.push(
                        `/newcontract?studentId=${this.getStudentId()}&contractId=null`,
                      )
                    }
                    style={{ height: 'inherit' }}
                    disabled={isDischarged}
                  >
                    Assign New Growth Plan
                  </button>
                </div>
              </div>
            </div>
          );
        },
        Component: StudentContract => {
          const {
            StudentContractId,
            ContractName,
            StudentId,
            StartDate,
            DueDate,
            Grade,
            Notes,
            DueDateForSort,
            DueDateFontColor,
            DiscardedDate,
            CompletedDate,
            ContractStatus,
            ShowReActivateButton,
          } = StudentContract;
          const contractStatus = {
            '-1': {
              color: '#fef2f2',
              date: DiscardedDate,
              name: 'Discarded On',
            },
            '0': {
              color: '#f8f8f8',
              date: DueDate,
              name: 'Due',
              dateFontColor: DueDateFontColor,
              gotoContractsPage: true,
            },
            '1': {
              color: 'rgb(239, 247, 254)',
              date: CompletedDate,
              name: 'Completed On',
            },
          };
          return (
            <div
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <div
                class="task-box"
                style={{ backgroundColor: contractStatus[ContractStatus].color }}
              >
                <div class="row no-gutters w-100 justify-content-between align-items-center">
                  <div class="col-12 col-md-9 mb-1">
                    <div
                      class="course-name"
                      style={{
                        width: '100%',
                        cursor: contractStatus[ContractStatus].gotoContractsPage ? 'pointer' : '',
                      }}
                      onClick={() =>
                        contractStatus[ContractStatus].gotoContractsPage &&
                        this.props.history.push(
                          `/newcontract?studentId=${StudentId}&contractId=${StudentContractId}`,
                        )
                      }
                    >
                      <h6>{ContractName}</h6>
                      <div class="row">
                        <div class="col-12 col-md-auto mr-2">
                          <span class="due-date">
                            <em>Assigned: </em> {StartDate}
                          </span>
                        </div>
                        <div class="col-12 col-md-auto mr-2">
                          <span class="due-date">
                            <em>Assigned Due: </em> {DueDate}
                          </span>
                        </div>
                        {ContractStatus === 1 && (
                          <div class="col-12 col-md-auto mr-2">
                            <span class="due-date">
                              <em>Grade: </em> {Grade}
                            </span>
                          </div>
                        )}

                        <div class="col-12 col-md-auto">
                          <span
                            class="due-date"
                            style={{ color: contractStatus[ContractStatus].dateFontColor }}
                          >
                            <em>{contractStatus[ContractStatus].name} : </em>{' '}
                            {contractStatus[ContractStatus].date}
                          </span>
                        </div>
                        {ContractStatus === 1 && (
                          <div class="col-12 col-md-auto">
                            <span class="due-date">
                              <em>Note : </em> {Notes}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div class="actions-button justify-content-xl-end">
                      {ShowReActivateButton && (
                        <>
                          <CustomModal
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title="Reactivate Growth Plan">
                                <span class="select-course" onClick={onClick}>
                                  <i class="select-icon" />
                                </span>
                              </Tooltip>
                            )}
                            ModalContent={({ onOk, onCancel }) => (
                              <div class="modal-content border-0">
                                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    &times;
                                  </button>
                                  <h4 class="modal-title col-12">
                                    Are you sure you want to reactivate Growth Plan?
                                  </h4>
                                </div>

                                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn "
                                    onClick={() => {
                                      let data = { StudentContract: [{ FromPage: 'ReActivate' }] };
                                      data = {
                                        StudentId: this.getStudentId(),
                                        StudentContractId: StudentContractId,
                                        Json: JSON.stringify(data),
                                      };
                                      this.props.dispatch(upsertContract(data));
                                      onOk();

                                      // this.onTaskUnAssign(StudentId, StudentContractId);
                                    }}
                                  >
                                    Ok
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                          <Tooltip placement="top" title="View Growth Plan">
                            {/* <span
                              class="select-course"
                              onClick={() => {
                                let data = { StudentContract: [{ FromPage: 'ReActivate' }] };
                                data = {
                                  StudentId: this.getStudentId(),
                                  StudentContractId: StudentContractId,
                                  Json: JSON.stringify(data),
                                };
                                this.props.dispatch(upsertContract(data));

                                // this.onTaskUnAssign(StudentId, StudentContractId);
                              }}
                            >
                              <i class="select-icon" />
                            </span> */}
                            <span class="select-course">
                              <Link
                                // class="select-course"
                                style={{ height: '15px' }}
                                to={{
                                  pathname: '/viewContract',
                                  search: `studentId=${this.getStudentId()}&contractId=${StudentContractId}`,
                                }}
                                onClick={() => {
                                  localStorage.setItem('displayName', 'studentName');
                                }}
                                target="_blank"
                              >
                                <img src="images/view.svg" />
                              </Link>
                            </span>
                          </Tooltip>
                        </>
                      )}

                      {/* <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Complete">
                            <span class="select-course" onClick={onClick}>
                              <i class="select-icon" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 class="modal-title col-12">
                                Complete Contract
                              </h4>
                            </div>

                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                class="btn black-btn"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="btn "
                                onClick={() => {
                                  onOk();
                                  // this.onTaskComplete(StudentContractId, StudentId);
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )} 
                      />*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: StudentContract || [],
      },
    ];
    let noteTabComponent = {
      Name: 'Notes',
      ExtraComponent: () => {
        return (
          <div className="Activities student-progress">
            <ListRenderer
              list={notesList}
              activeKey={this.state.subTabKey1}
              getActiveKey={subTabKey1 => this.setState({ subTabKey1 })}
            />
          </div>
        );
      },
    };
    const surveyanswersdatacol = [
      { title: 'Question Number', dataIndex: 'QuestionNumber', key: 'QuestionNumber' },
      {
        title: 'Question',
        dataIndex: 'Question',
        key: 'Question',
        render: (text, record) => {
          return <div className="survey-answer">{text && renderHTML(text)}</div>;
        },
      },
      { title: 'Answer', dataIndex: 'Answer', key: 'Answer' },
      { title: 'Responded Date Time', dataIndex: 'RespondedDateTime', key: 'RespondedDateTime' },
    ];
    if (isHideNotesTab) {
      list.unshift(noteTabComponent);
    }
    return (
      <div>
        <PageTitle TitleText="Client profile" />
        <Loader loading={isFetching} />
        <div class="main students-page mb-5">
          <div class="container-fluid">
            <div class="row top-table-search align-items-center">
              <div class="col-12 col-md-4 col-lg-4">
                <PageBackAction
                  pageBackText="Back to list"
                  onClick={() =>
                    // this.props.history.goBack()
                    this.props.history.push({
                      pathname: '/clients/list',
                    })
                  }
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-4 pt-2">
                <div class="card border-0 students-profile ">
                  <div class="row card-body align-items-center">
                    <div class="col-12 col-xl-5 mb-3 mb-xl-0">
                      <div class="row align-items-center">
                        <UploadProfile
                          imgSrc={StudentMisc.StudentImageUrl}
                          captureProfile={this.capture}
                        />

                        <div class="col-12 col-sm-auto">
                          <h4 class="students-name text-xs-center text-md-left">
                            {FirstName} {MiddleName} {LastName}{' '}
                            <small>
                              <span
                                onClick={() => {
                                  if (EnrollStatus && EnrollStatus == 'Discharged') {
                                    this.props.history.push(
                                      `/viewenrollment/?studentid=${this.getStudentId()}`,
                                    );
                                  } else {
                                    this.props.history.push(
                                      `/enrollment/?studentid=${this.getStudentId()}`,
                                    );
                                  }
                                }}
                                title={
                                  EnrollStatus && EnrollStatus == 'Discharged'
                                    ? 'View Profile'
                                    : 'Edit Profile'
                                }
                                style={{ color: '#4ab0fe', cursor: 'pointer' }}
                              >
                                <figure
                                  class="action-button-icon"
                                  style={{ display: 'inline-block' }}
                                >
                                  <img src="images/edit-profile-icon.svg" alt="" width="23" />
                                </figure>
                                {EnrollStatus && EnrollStatus == 'Discharged'
                                  ? 'View Profile'
                                  : 'Edit Profile'}
                              </span>
                            </small>
                          </h4>

                          <div
                            class="students-action-button ml-2 text-xs-center"
                            style={{ maxWidth: 'unset' }}
                          >
                            <div className="row">
                              <div className="col-xs-4">
                                <AntdModal
                                  modalWidth={750}
                                  ModalButton={({ onClick }) => (
                                    <>
                                      <button
                                        class="btn btn-outline-primary m-1"
                                        onClick={() => {
                                          onClick();
                                          this.props.dispatch(
                                            getMobileAppDownloadStatus({
                                              PhoneNumber:
                                                (primaryPhone &&
                                                  this.formatMobile(this.props.primaryPhone)) ||
                                                0,
                                            }),
                                          );
                                        }}
                                        style={{
                                          display: 'inline',
                                        }}
                                      >
                                        <figure
                                          class="action-button-icon"
                                          style={{ display: 'inline-block' }}
                                        >
                                          <img src="images/discharge-icon.svg" alt="" width="23" />
                                        </figure>
                                        {EnrollStatus && EnrollStatus == 'Discharged' ? (
                                          <span>Reinstate</span>
                                        ) : (
                                          'Discharge'
                                        )}
                                      </button>
                                      {/* {EnrollStatus && EnrollStatus == 'Discharged' ? (
                                        <AntdModal
                                          ModalButton={({ onClick }) => (
                                            <input
                                              type="button"
                                              class="btn"
                                              value="Mobile App Referral"
                                              onClick={onClick}
                                              // style={{ display: 'inline', marginBottom: '5px' }}
                                            />
                                          )}
                                          ModalContent={props => (
                                            <ReduxSMSModal
                                              {...props}
                                              phoneMobileValue={primaryPhone}
                                            />
                                          )}
                                        />
                                      ) : null} */}
                                    </>
                                  )}
                                  ModalContent={props => (
                                    <DischargeModal
                                      reinstate={this.reinstate}
                                      onDischarge={this.onDischarge}
                                      history={this.props.history}
                                      EnrollExitNote={EnrollExitNote}
                                      AdmissionDate={AdmissionDate}
                                      primaryPhone={primaryPhone}
                                      DischargeDate={DischargeDate}
                                      preIntakeStatusData={preIntakeStatusData}
                                      {...props}
                                    />
                                  )}
                                />
                              </div>

                              {/* <div className="col-xs-6">
                                {this.getStudentId() > 0 && (
                                  <div>
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary m-1"
                                      value=" Pin "
                                      disabled={isDischarged}
                                      onClick={() => {
                                        const isWebCam =
                                          localStorage.getItem('isWebCam') === '0' ? false : true;
                                        if (isWebCam) {
                                          this.props.history.push({
                                            pathname: '/camera',
                                            search: 'studentId=' + this.getStudentId(),
                                            param: {
                                              message:
                                                'Changing PIN requires new profile picture to be taken.',
                                            },
                                          });
                                        } else {
                                          this.props.history.push({
                                            pathname: '/pin',
                                            search: 'studentId=' + this.getStudentId(),
                                          });
                                        }
                                      }}
                                    >
                                      <figure
                                        class="action-button-icon"
                                        style={{ display: 'inline-block' }}
                                      >
                                        <img src="images/change-pin@3x.png" alt="" width="23" />
                                        Pin
                                      </figure>
                                    </button>
                                  </div>
                                )}
                              </div> */}
                              <div className="col-xs-4">
                                <button
                                  onClick={() => {
                                    this.props.history.push(
                                      `/document-upload/?studentid=${this.getStudentId()}`,
                                    );
                                  }}
                                  title="Upload Document"
                                  class="btn btn-outline-primary m-1"
                                >
                                  <figure
                                    class="action-button-icon mr-1"
                                    style={{ display: 'inline-flex' }}
                                  >
                                    <img src="images/Documents-icon.svg" alt="" width="15" />
                                  </figure>
                                  {EnrollStatus && EnrollStatus == 'Discharged' ? (
                                    <span>Documents</span>
                                  ) : (
                                    'Documents'
                                  )}
                                </button>
                              </div>
                              <div className="col-xs-4">
                                <button
                                  onClick={() => {
                                    this.props.history.push(
                                      `/client-timetravel/?studentid=${this.getStudentId()}`,
                                    );
                                  }}
                                  title="Student Time Travel"
                                  class="btn btn-outline-primary m-1"
                                >
                                  <figure
                                    class="action-button-icon mr-1"
                                    style={{ display: 'inline-flex' }}
                                  >
                                    <img src="images/timetravel.svg" alt="" width="15" />
                                  </figure>
                                  <span>Time Travel</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-xl-7">
                      <div class="row students-detail   text-md-left  justify-content-md-between">
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Phase</span>
                          <h5
                            title={StudentMisc.ServiceType}
                            class="info-detail m-md-0"
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '120px',
                            }}
                          >
                            {StudentMisc.ServiceType}
                          </h5>
                        </div>
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Level in the program</span>
                          <h5 class="info-detail m-md-0">{StudentMisc.Phase}</h5>
                          {/* <span class="info-label">Age</span>
                          <h5 class="info-detail m-md-0">{StudentMisc.Age} Years</h5> */}
                        </div>
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Admission Date</span>
                          <h5 class="info-detail m-md-0">{AdmissionDate}</h5>
                        </div>
                        <div class="col-6 col-sm-auto mb-2 md-sm-0">
                          <span class="info-label">Cost of Care To-Date</span>
                          <h5 class="info-detail m-md-0">${StudentMisc.CostofCareToDate}</h5>
                        </div>
                        {StudentMisc && StudentMisc.ExitReason && (
                          <div class="col-6 col-sm-auto mb-2 md-sm-0">
                            <span class="info-label">Discharge Reason</span>
                            <h5
                              title={StudentMisc.ExitReason}
                              class="info-detail m-md-0"
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '120px',
                              }}
                            >
                              {StudentMisc.ExitReason}
                            </h5>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-7 mb-4 students-performance-col">
                {this.props.AugmentedAIStatusData && (
                  <CommonLicenceScreens
                    StatusData={this.props.AugmentedAIStatusData}
                    moduleName={'AugmentedAI'}
                  />
                )}
                <>
                  <div class="row mb-2">
                    <div class="col-12">
                      <>
                        {this.props.AugmentedAIStatusData &&
                          this.props.AugmentedAIStatusData.LicenseStatusId != 1 &&
                          this.props.AugmentedAIStatusData.LicenseStatusId != 3 &&
                          this.props.AugmentedAIStatusData.LicenseStatusId != 5 && (
                            <>
                              <AugmentedSummary ClientId={this.getStudentId()} isEnrolled={true} />
                            </>
                          )}
                      </>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-12">
                      <RiskChart ClientId={this.getStudentId()} />
                    </div>
                  </div>
                </>

                <div className="row">
                  <div className="col-6 mb-3">
                    <div className="card border-0 p-3" style={{ height: '100%' }}>
                      <PerformanceCharts
                        FirstName={FirstName}
                        history={this.props.history}
                        performaceScores={StudentMisc}
                        studentId={this.getStudentId()}
                        AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                        dispatch={this.props.dispatch}
                      />
                    </div>
                  </div>
                  <div className="col-6 mb-3">
                    <div className="card border-0">
                      <div class="row card-body">
                        <RecoveryMeasureGraph
                          AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                          dispatch={this.props.dispatch}
                          studentId={this.getStudentId()}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="card border-0 ">
                      <div class="row card-body">
                        {/* <div class="col-12 mb-5">
                          <div class="journey-title">
                            <h5>Recovery Journey</h5>
                          </div>
                          {StudentOutcome.length > 0 ? (
                            <>
                              <div class="row">
                                <div class="col-12">
                                  <Collapse
                                    accordion
                                    bordered={false}
                                    defaultActiveKey={activePanel1 + 'blue'}
                                    // expandIconPosition="right"
                                    // expandIcon={({ isActive }) => (
                                    //   <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                    // )}
                                    className="site-collapse-custom-collapse"
                                  >
                                    {StudentOutcomeData &&
                                      StudentOutcomeData.map((item, index) => {
                                        activePanel1 = index;
                                        return (
                                          <Panel
                                            header={<PanelHeader headerData={item} />}
                                            key={activePanel1 + item.Color}
                                            className={`site-collapse-custom-panel-${item.Color}`}
                                            showArrow={false}
                                          >
                                            <div className="panel-text">
                                              <ul>
                                                {item.Question &&
                                                  item.Question.map(ques => (
                                                    <li>{ques.Question}</li>
                                                  ))}
                                              </ul>
                                              {this.props.AugmentedAIStatusData &&
                                                this.props.AugmentedAIStatusData.LicenseStatusId !=
                                                  1 &&
                                                this.props.AugmentedAIStatusData.LicenseStatusId !=
                                                  3 &&
                                                this.props.AugmentedAIStatusData.LicenseStatusId !=
                                                  5 &&
                                                item.Question &&
                                                item.Question.length > 0 && (
                                                  <div className="d-flex justify-content-end">
                                                    <button
                                                      // title="Augment with AI"
                                                      class="ai-btn"
                                                      onClick={() =>
                                                        // this.setState({ isAugmentDrawerOpen: true })
                                                        this.toggleAugmentDrawer()
                                                      }
                                                      style={{
                                                        display: 'inline',
                                                        padding: '5px 20px',
                                                      }}
                                                    >
                                                      <figure
                                                        class="action-button-icon"
                                                        style={{ display: 'inline-block' }}
                                                      >
                                                        <img
                                                          src="images/AIStar.svg"
                                                          alt=""
                                                          width="23"
                                                        />
                                                      </figure>
                                                      Upgrade Level
                                                    </button>
                                                  </div>
                                                )}
                                            </div>
                                          </Panel>
                                        );
                                      })}
                                  </Collapse>
                                </div>
                                <div class="col-12 text-right">
                                  {StudentOutcome.length > 2 && (
                                    <AntdModal
                                      modalWidth={700}
                                      ModalButton={({ onClick }) => (
                                        <button class="btn m-1" onClick={onClick}>
                                          See Full Detail
                                        </button>
                                      )}
                                      ModalContent={props => (
                                        <FullDetailModal
                                          // history={this.props.history}
                                          StudentOutcome={StudentOutcome}
                                          {...props}
                                        />
                                      )}
                                    />
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="text-center">
                                <h5>No Data</h5>
                              </div>
                            </>
                          )}
                        </div> */}
                        <RecoveryJourney
                          AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                          StudentOutcome={StudentOutcome}
                          StudentOutcomeData={StudentOutcomeData}
                          studentId={this.getStudentId()}
                          psychosocialSurveys={this.props.psychosocialSurveys}
                        />
                        {true && (
                          <>
                            <div class="col-12 mb-2 mb-lg-1">
                              <h5 class="pt-4 font-weight-normal">System Observations</h5>
                            </div>
                            <div class="col-12">
                              {SystemObservation ? (
                                SystemObservation.map((item, index) => {
                                  const { Content, Heading, IsAccepted, ObservationId } = item;
                                  const style = { width: 140, height: 35, paddingTop: 5 };
                                  const status = {
                                    null: (
                                      <>
                                        <button
                                          type="button"
                                          class="btn"
                                          style={style}
                                          onClick={() => {
                                            this.handelObservation({
                                              isAccepted: true,
                                              observationId: ObservationId,
                                            });
                                          }}
                                        >
                                          Agree
                                        </button>
                                        &nbsp;
                                        <button
                                          type="button"
                                          class="btn btn-outline-primary"
                                          data-dismiss="modal"
                                          style={style}
                                          onClick={() => {
                                            this.handelObservation({
                                              isAccepted: false,
                                              observationId: ObservationId,
                                            });
                                          }}
                                        >
                                          Disagree
                                        </button>{' '}
                                      </>
                                    ),
                                    true: (
                                      <a style={{ color: '#12c475', fontWeight: 700 }}>
                                        <i
                                          class="fa fa-check"
                                          aria-hidden="true"
                                          style={{ paddingRight: 5 }}
                                        />
                                        Agreed
                                      </a>
                                    ),
                                    false: (
                                      <a
                                        style={{
                                          fontWeight: '700',
                                          letterSpacing: '0.1px',
                                          color: '#ff3b3b',
                                        }}
                                      >
                                        <i
                                          class="fas fa-thumbs-down"
                                          style={{ paddingRight: 5 }}
                                        ></i>
                                        Disagreed
                                      </a>
                                    ),
                                  };
                                  const color = {
                                    null: '#f9f7fa',
                                    true: 'rgb(239, 247, 254)',
                                    false: 'rgb(254, 242, 242)',
                                  };
                                  return (
                                    <div
                                      style={{
                                        backgroundColor: color[IsAccepted],
                                        padding: 20,
                                        borderRadius: 15,
                                        marginBottom: 25,
                                      }}
                                    >
                                      <div style={{ fontWeight: 600 }}>{renderHTML(Heading)}</div>
                                      <div style={{ textAlign: 'justify', paddingTop: 10 }}>
                                        {renderHTML(Content)}
                                      </div>
                                      <br />
                                      {status[IsAccepted]}
                                    </div>
                                  );
                                })
                              ) : (
                                <p style={{ backgroundColor: '#f0f9ff', padding: '15px' }}>
                                  A System Observation cannot be generated for the client at this
                                  time. Please have the client complete additional surveys to
                                  generate an observation.
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-5 mb-4 students-tab-col">
                <div className="mt-4">
                  <Mood
                    Mood={this.props.studentMood}
                    studentId={this.getStudentId()}
                    history={this.props.history}
                    AugmentedAIStatusData={this.props.AugmentedAIStatusData}
                  />
                </div>

                <CommonLicenceScreens
                  StatusData={this.props.BreathalyzerStatusData}
                  moduleName={'Breathalyzer'}
                />

                {this.props.BreathalyzerStatusData &&
                  this.props.BreathalyzerStatusData.LicenseStatusId != 1 &&
                  this.props.BreathalyzerStatusData.LicenseStatusId != 3 &&
                  this.props.BreathalyzerStatusData.LicenseStatusId != 5 &&
                  this.props.BreathalyzerStatusData.LicenseStatusId != 2 && (
                    <div className="border-0 mb-3 breathalyzer-block">
                      <div className={'breathalyzer-test-list'}>
                        <div className="test-card row mx-0 mb-3">
                          <div className="col-6 mb-2">
                            <div className="d-flex justify-content-between">
                              <div className="patient">
                                <div className="patient-name">
                                  <span>{'Breathalyzer'}</span>
                                  <div style={{ margin: '-5px 0px' }}>
                                    {BreathalyzerTestResult &&
                                      BreathalyzerTestResult.map(item => {
                                        return <span className={item.ResultClassBlock}></span>;
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="last-test">
                                <div className="d-flex">
                                  <span className="test-name">Last Test</span>
                                  <span
                                    className="view-schedule"
                                    onClick={() => {
                                      this.setState({
                                        viewTimelineModal: true,
                                      });
                                    }}
                                  >
                                    View Timeline
                                  </span>
                                </div>
                                <div
                                  className="patient-name d-flex align-items-center"
                                  style={{ marginLeft: '-3px' }}
                                >
                                  <img src="images/icons/grey_calendar.svg" width="18" />
                                  <span className="test-date">{TestDate}</span>
                                  <div style={{ display: 'inline' }}>
                                    <span className={ResultClassBlock}></span>
                                    <span className={ResultClassText}>{ResultText}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <div class="card border-0">
                  <div class="card-body center-tabs">
                    <ListRenderer
                      list={list}
                      activeKey={this.state.activeKey}
                      getActiveKey={activeKey => this.setState({ activeKey })}
                    />
                    <Drawer
                      className="custom-drawer"
                      title="Survey Response"
                      placement="right"
                      visible={this.state.isResultDrawerOpen}
                      width={800}
                      bodyStyle={{ paddingBottom: 80 }}
                      closable={true}
                      destroyOnClose={true}
                      onClose={() => {
                        this.props.dispatch(resetSurveyAnswersGet());
                        this.toggleDrawer();
                      }}
                    >
                      <SurveyResponseDrawer
                        ClientId={this.getStudentId()}
                        toggleDrawer={this.toggleDrawer}
                        answerRecord={this.state.answerRecord}
                        ColData={surveyanswersdatacol}
                        RowData={this.props.surveyanswersdata}
                      />
                    </Drawer>

                    {/* <CustomModal2
                      isOpen={this.props.surveyanswersdata.length > 0}
                      yesAction={() => {
                        this.props.dispatch(resetSurveyAnswersGet());
                      }}
                      yesText="OK"
                      hideCancel={true}
                      changeMaxWidth="600px"
                      className="px-0"
                    >
                      <div
                        class="col-lg-12 mb-3 student-profile-smiley-chart"
                        style={{ width: '100%' }}
                      >
                        <h4 class="text-center">Survey Answers</h4>
                        <div class="mar-top-30">
                          <Table
                            dataSource={this.props.surveyanswersdata}
                            columns={surveyanswersdatacol}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div class="dataTables_wrapper no-footer">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a class="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a class="paginate_button next">Next</a>
                                      ) : (
                                        <a class="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              showSizeChanger: true,
                              // hideOnSinglePage: true,
                            }}
                          />
                        </div>
                      </div>
                    </CustomModal2> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          zIndex={100}
          // visible={true}
          visible={this.state.viewTimelineModal}
          footer={null}
          closable={false}
          width={700}
          destroyOnClose={true}
        >
          <ReduxBreathalyzerTestResult
            onCancel={() => {
              this.setState({
                viewTimelineModal: false,
              });
            }}
            StudentId={this.getStudentId()}
            DisplayName={sessionStorage.getItem('displayName')}
          />
        </Modal>
        <CustomModal2
          isOpen={this.state.IsStudentDischarged}
          header={this.state.HederMsg}
          // children={this.state.ReferralMSg}
          yesAction={() => {
            this.toggle('IsStudentDischarged');
            this.state.inviteCheckBox = false;
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal2
          isOpen={this.state.IsInvitationSent}
          header={this.state.InvitationMsg}
          yesAction={() => {
            this.toggle('IsInvitationSent');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal2
          isOpen={this.state.IsStudentReinstated}
          header={this.state.reInstateMsg}
          yesAction={() => {
            this.toggle('IsStudentReinstated');
            this.props.history.push('/clients/list');
          }}
          yesText="OK"
          hideCancel={true}
        />
        {/* <CustomModal2
          isOpen={this.state.IsSurveyAssign}
          // msgContentArray={this.state.surveySuccessMsg}
          header={this.state.surveySuccessMsg}
          yesAction={() => {
            this.props.dispatch(getStudentProfileDashboardData({ StudentId: this.getStudentId() }));
            this.toggle('IsSurveyAssign');
          }}
          yesText="OK"
          hideCancel={true}
        /> */}
        <Modal
          width={600}
          visible={this.state.IsSurveyAssign}
          footer={null}
          closeIcon={null}
          closable={false}
          onClose={e => {
            e.stopPropagation();
            this.setState({
              IsSurveyAssign: false,
            });
          }}
          destroyOnClose={true}
        >
          <div className="modal-content border-0">
            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    IsSurveyAssign: false,
                  });
                }}
              >
                &times;
              </button>
              <h4 className="modal-title col-12">Survey status of clients mentioned below.</h4>
            </div>
            <div
              className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5"
              style={{ height: '300px', overflow: 'auto' }}
            >
              {this.state.surveySuccessMsg &&
                this.state.surveySuccessMsg.map((item, index) => {
                  const { Survey, Clients } = item;
                  return (
                    <div>
                      <div>
                        <h6>{Survey}</h6>
                      </div>
                      <div>
                        <ol>
                          {Clients &&
                            Clients.map((item, index) => {
                              const { ClientName, Status, IsAssigned } = item;

                              return (
                                <li style={{ marginBottom: '5px' }}>
                                  {ClientName} &nbsp;&nbsp;
                                  <Tag color={IsAssigned ? 'success' : 'error'}>{Status}</Tag>
                                </li>
                              );
                            })}
                        </ol>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
              <button
                className="btn"
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    IsSurveyAssign: false,
                  });
                  this.props.dispatch(
                    getStudentProfileDashboardData({ StudentId: this.getStudentId() }),
                  );
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({
  student_get,
  combos,
  student_note_add,
  student_couse_assign,
  student_get_profile_dashboard_data,
  survey_task_mark_in_complete,
  student_task_mark_complete,
  student_task_unassign,
  student_unassign,
  student_mark_complete,
  survey_assign,
  student_task_upsert,
  student_add_existing_task,
  student_discharge,
  survey_answers_get,
  student_reinstate,
  // app_invite,
  app_invite_dashboard,
  mark_system_observation,
  upsert_contract,
  delete_student_note,
  user_feature_flag_get,
  student_note_list_get,
  student_course_augmentation_get,
  gpt_augmentation_update,
}) => {
  var { data, isFetching, error } = student_get;
  const { data: fFData } = user_feature_flag_get;
  // var { data: inviteData, error, isFetching } = app_invite;
  const { data: studentNote } = student_note_list_get;
  const {
    data: studentCourseAugment,
    isFetching: isStudentJournalFetching,
  } = student_course_augmentation_get;
  const { data: GPTAugmentation } = gpt_augmentation_update;
  let FeatureFlags = {};
  let isNoteDeleted = false;
  let appInviteSMS = false;
  let primaryPhone;
  var isNoteAdded = false;
  let isMarkObservation = false;
  var isCourseAdded = false;
  var loadedCourses = false;
  let NotesStudent;
  var EnrollStatus = null,
    EnrollExitNote = null,
    StudentData = {
      NoteStudent: [],
      SurveyStudent: [],
      TaskStudent: [],
      CourseStudent: [],
      StudentContract: [],
      StudentOutcome: [],
      courseAugNotes: {},
    },
    StudentMisc = {},
    isMarkComplete = false,
    isUnAssigned = false,
    isTaskMarkComplete = false,
    isTaskUnAssigned = false,
    isTaskMarkInComplete = false,
    isAssigned = false,
    isNewTaskCreated = false,
    isExistingTaskMapped = false,
    isStudentDischarged = false,
    isDischarged = false,
    isReinstate = false,
    isGrowthPlanReset = false,
    isHideNotesTab = false,
    ContractId = null;
  let surveyAssignResponse = '';
  let Breathalyzer = {};
  let ShowBreathalyzer = false;
  let BreathalyzerStatusData = {};
  let preIntakeStatusData = {};
  let AugmentedAIStatusData = null;
  let courseAugNotes = {};
  let studentMood = [];
  let isAugmentAgreed = false;
  let psychosocialSurveys;

  if (GPTAugmentation && GPTAugmentation.success) {
    isAugmentAgreed = GPTAugmentation.success;
  }

  if (data) {
    const { StudentMore } = data;
    if (StudentMore && StudentMore[0]) {
      EnrollExitNote = StudentMore[0].ExitReasonNotes;
    }
  }
  // DischargeReason

  if (student_note_add && student_note_add.data && student_note_add.data.success) {
    isNoteAdded = true;
  }

  if (studentNote && studentNote.NoteStudent) {
    NotesStudent = studentNote.NoteStudent || [];
  }

  if (studentCourseAugment && studentCourseAugment.Courses && studentCourseAugment.Courses[0]) {
    courseAugNotes =
      (studentCourseAugment && studentCourseAugment.Courses && studentCourseAugment.Courses[0]) ||
      {};
  }

  if (
    mark_system_observation &&
    mark_system_observation.data &&
    mark_system_observation.data.success
  ) {
    isMarkObservation = true;
  }

  if (student_couse_assign && student_couse_assign.data && student_couse_assign.data.success) {
    isCourseAdded = true;
  }

  if (student_get_profile_dashboard_data && student_get_profile_dashboard_data.data) {
    StudentData = student_get_profile_dashboard_data.data;
    StudentData.NoteStudent = StudentData.NoteStudent || [];
    StudentData.SurveyStudent = StudentData.SurveyStudent || [];
    StudentData.TaskStudent = StudentData.TaskStudent || [];
    StudentData.CourseStudent = StudentData.CourseStudent || [];
    StudentData.StudentContract = StudentData.StudentContract || [];
    StudentData.StudentMilestones = StudentData.StudentMilestones || [];
    StudentData.StudentOutcome = StudentData.StudentOutcome || [];
    StudentMisc = StudentData.StudentMisc[0];
    EnrollStatus = StudentMisc.EnrollStatus;
    EnrollExitNote = StudentMisc.DischargeReason;
    isDischarged = EnrollStatus === 'Discharged';
    ContractId = StudentMisc.StudentContractId;
    primaryPhone = student_get_profile_dashboard_data.data.PrimaryPhone;
    ShowBreathalyzer = StudentData.ShowBreathalyzer;
    studentMood = student_get_profile_dashboard_data.data.Mood;
    psychosocialSurveys = student_get_profile_dashboard_data.data.psychosocialSurveys;
  }
  if (
    student_get_profile_dashboard_data.data &&
    student_get_profile_dashboard_data.data.Breathalyzer
  ) {
    Breathalyzer = student_get_profile_dashboard_data.data.Breathalyzer[0] || {};
  }
  if (student_discharge && student_discharge.data) {
    isStudentDischarged = true;
  }

  if (student_add_existing_task && student_add_existing_task.data) {
    isExistingTaskMapped = true;
  }

  if (survey_assign && survey_assign.data && survey_assign.data.data) {
    isAssigned = true;
    surveyAssignResponse = JSON.parse(survey_assign.data.data).Survey;
  }

  if (student_task_upsert && student_task_upsert.data) {
    isNewTaskCreated = true;
  }

  if (student_mark_complete && student_mark_complete.data) {
    isMarkComplete = true;
  }

  if (student_unassign && student_unassign.data) {
    isUnAssigned = true;
  }

  if (student_task_unassign && student_task_unassign.data) {
    isTaskUnAssigned = true;
  }

  if (student_task_mark_complete && student_task_mark_complete.data) {
    isTaskMarkComplete = true;
  }

  if (survey_task_mark_in_complete && survey_task_mark_in_complete.data) {
    isTaskMarkInComplete = true;
  }
  if (student_reinstate && student_reinstate.data) {
    isReinstate = true;
  }
  if (upsert_contract && upsert_contract.data) {
    isGrowthPlanReset = upsert_contract.data.success;
  }
  // if (inviteData && inviteData.Success == true) {
  //   appInviteSMS = inviteData.Success;
  // }
  if (app_invite_dashboard.data && app_invite_dashboard.data.Success) {
    appInviteSMS = app_invite_dashboard.data.Success;
  }
  if (delete_student_note && delete_student_note.data && delete_student_note.data.success) {
    isNoteDeleted = true;
  }

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
    isHideNotesTab = !fFData.FeatureFlag[0].HideNotes;
    BreathalyzerStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'Breathalyzer')[0]) ||
      {};
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
    preIntakeStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'PreIntake')[0]) ||
      {};
  }
  return {
    student: data || {},
    isNoteAdded: isNoteAdded,
    loadedCourses: loadedCourses,
    isCourseAdded: isCourseAdded,
    isFetching: student_get_profile_dashboard_data.isFetching,
    EnrollExitNote,
    EnrollStatus,
    StudentData,
    StudentMisc,
    isMarkComplete,
    isUnAssigned,
    isTaskMarkComplete,
    isTaskUnAssigned,
    isTaskMarkInComplete,
    isAssigned,
    isNewTaskCreated,
    isExistingTaskMapped,
    isStudentDischarged,
    isDischarged,
    ContractId,
    isReinstate,
    surveyanswersdata: (survey_answers_get.data && Object.values(survey_answers_get.data)) || [],
    appInviteSMS,
    primaryPhone,
    isMarkObservation,
    isGrowthPlanReset,
    ShowBreathalyzer,
    Breathalyzer,
    surveyAssignResponse,
    isNoteDeleted,
    FeatureFlags,
    NotesStudent,
    BreathalyzerStatusData,
    AugmentedAIStatusData,
    preIntakeStatusData,
    isHideNotesTab,
    courseAugNotes,
    studentMood,
    isAugmentAgreed,
    psychosocialSurveys,
  };
};
export default connect(mapStateToProps)(StudentsProfile);
