import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { externalProviderAuthentication } from '../store/actions';
import CustomModal from '../core/modal';
import Loader from '../Components/Loader';
import hostGrabber from '../util/hostGrabber';

class AuthenticateByNPI extends Component {
  state = {
    npi: '',
    modal: false,
    content: '',
    error: '',
  };

  onClick() {
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let reg = /^[0-9--]{10}$/;
    if (reg.test(this.state.npi)) {
      var data = {
        JSON: JSON.stringify({
          ExternalProvider: [
            {
              NPI: this.state.npi,
            },
          ],
        }),
      };
      this.props.dispatch(externalProviderAuthentication(data));
    } else {
      this.setState({ error: 'You have entered an invalid NPI!' });
      return false;
    }
  }

  componentWillReceiveProps({ IsAuthenticated, errorMessage, isSuccessMsg }) {
    if (IsAuthenticated) {
      this.setState({
        modal: true,
        content: isSuccessMsg,
      });
    }
    if (errorMessage) {
      this.setState({ error: errorMessage });
    }
  }
  enterButton = event => {
    if (event.key == 'Enter') {
      this.onClick();
    }
  };
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const { modal, content, error } = this.state;
    const { isFetching } = this.props;
    return (
      <React.Fragment>
        <div class="login-page">
          <Loader loading={isFetching} />
          <div class="container-fluid">
            <div class="col-12 col-md-5 col-lg-4 offset-md-1">
              <div class="login-box-main">
                <figure class="login-logo">
                  <img
                    src={hostGrabber() ? 'images/youu_Logo.svg' : 'images/logo.svg'}
                    width={hostGrabber() ? '250' : '120'}
                    alt=""
                  />
                </figure>
                <h5 class="font-weight-semibold mb-3 mb-md-5">Authenticate by NPI Number</h5>
                <form class="login-form">
                  <div class="form-group mb-4">
                    <input
                      type="NPINumber"
                      class="form-control"
                      placeholder="National Provider Identifier Number*"
                      onChange={event => (this.state.npi = event.target.value)}
                      onkeydown={this.enterButton}
                    />
                    {error && <span className="text-danger">{error}</span>}
                  </div>
                  <input
                    type="button"
                    class="btn btn-login"
                    value="SUBMIT"
                    onClick={this.onClick.bind(this)}
                  />
                </form>
              </div>
              <span
                style={{
                  color: '#979797',
                  fontSize: '14px',
                  fontStyle: 'italic',
                  fontWeight: '400',
                }}
              >
                *Authenticate your ID swiftly using your NPI number for added security.
              </span>
            </div>
          </div>
          {this.state.modal && (
            <CustomModal
              isOpen={this.state.modal}
              header={content}
              yesAction={() => {
                this.toggle();
                this.props.history.push('/');
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ external_provider_authentication }) => {
  const { data, isFetching, error } = external_provider_authentication;
  var IsAuthenticated = false;
  var isSuccessMsg = null;
  var errorMessage = '';

  if (data) {
    debugger;
    if (data && data.IsAuthenticated) {
      IsAuthenticated = data.IsAuthenticated;
      isSuccessMsg = data.Msg;
    } else {
      IsAuthenticated = data.IsAuthenticated;
      errorMessage = data.Msg;
    }
    // if (data.error) {
    //   errorMessage = data.error;
    // }
  } else if (error) {
    errorMessage = error.Message;
  }

  return {
    IsAuthenticated: IsAuthenticated,
    errorMessage: errorMessage,
    isFetching,
    isSuccessMsg,
  };
};

export default connect(mapStateToProps)(AuthenticateByNPI);
