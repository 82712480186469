import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';

class NumericType3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };

    const {
      survey: { Response },
    } = this.props;
    if (Response) {
      this.state.value = parseInt(Response);
    }
  }

  onIncrement = (event, Question, SurveyId, SurveyQuestionId, setValue, scope, MaxValue) => {
    let value = this.state.value;
    if (++value <= MaxValue) {
      this.setState({ value: value });
      setValue.call(scope, { response: value, surveyQuestionId: SurveyQuestionId });
    }
  };

  onDecrement = (event, Question, SurveyId, SurveyQuestionId, setValue, scope, MinValue) => {
    let value = this.state.value;
    if (--value >= MinValue) {
      this.setState({ value: value });
      setValue.call(scope, { response: value, surveyQuestionId: SurveyQuestionId });
    }
  };

  onClick() {
    if (this.state.value == 1) {
      const {
        survey: { SurveyQuestionId },
        setValue,
        scope,
      } = this.props;
      setValue.call(scope, { response: 1, surveyQuestionId: SurveyQuestionId });
    }
    $('.main-banner').slick('slickNext');
  }

  onChange = (event, click) => {
    const { value } = event.target;
    const {
      survey: { SurveyQuestionId, MaxValue, MinValue },
      setValue,
      scope,
    } = this.props;
    if (value) {
      if (value >= MinValue && value <= MaxValue) {
        this.setState({ value: value });
        setValue.call(scope, { response: value, surveyQuestionId: SurveyQuestionId });
      }
    } else {
      if (value >= MinValue && value <= MaxValue) this.setState({ value: value });
    }
  };

  componentWillReceiveProps(props) {
    const {
      survey: { Response },
    } = this.props;
    if (Response) {
      this.setState({ value: parseInt(Response) });
    }
  }

  render() {
    const {
      survey: {
        Question,
        DoesNotApplyText,
        DoesNotApplyValue,
        SurveyId,
        SurveyQuestionId,
        MaxValue,
        MinValue,
      },
      setValue,
      scope,
      index,
      total,
    } = this.props;
    return (
      <div className=" card border-0">
        <div class="card-body">
          <span class="d-block question-no">
            <em>{index + 1}</em> of {total}
          </span>
          <div className="d-flex flex-column">
            <h5 className="survey-question">
              {Question && renderHTML(Question)}
              {/* <span class="d-md-block">these psychological or emotional problems?</span> */}
            </h5>
            <div
              class="answer-options mb-4 "
              style={{
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
                textAlign: 'center',
              }}
            >
              <div class="quantity-btn">
                <div class="numbers-row">
                  <input
                    class="number-icon"
                    type="text"
                    min="0"
                    max="30"
                    value={this.state.value}
                    tabindex="0"
                    style={{ textAlign: 'center' }}
                    onChange={this.onChange}
                  />
                  <div
                    class="add quantity-button"
                    onClick={event =>
                      this.onIncrement(
                        event,
                        Question,
                        SurveyId,
                        SurveyQuestionId,
                        setValue,
                        scope,
                        MaxValue,
                      )
                    }
                  >
                    +
                  </div>
                  <div
                    class="remove quantity-button"
                    onClick={event =>
                      this.onDecrement(
                        event,
                        Question,
                        SurveyId,
                        SurveyQuestionId,
                        setValue,
                        scope,
                        MinValue,
                      )
                    }
                  >
                    -
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                maxWidth: '600px',
                width: '100%',
                margin: '0 auto',
                position: 'relative',
                textAlign: 'center',
              }}
            >
              <button class="btn px-5" onClick={this.onClick.bind(this)} tabindex="0">
                Next
              </button>
            </div>
            {DoesNotApplyText && (
              <div className="not-apply-to-me">
                <a
                  name="current-situation"
                  value={DoesNotApplyText}
                  onClick={this.props.onClick.bind(scope, {
                    surveyQuestionId: SurveyQuestionId,
                    response: DoesNotApplyValue,
                  })}
                >
                  {DoesNotApplyText}
                </a>
              </div>
            )}
          </div>
          {/* <div class="row pagination-button">
            <div class="col-12 text-right">
              <button class="btn" onClick={this.onClick.bind(this)}>
                Next
              </button>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default NumericType3;
