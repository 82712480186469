import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, Drawer, Table, Modal, Tooltip } from 'antd';
import { smartContractPayersListGet } from '../../../../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';
import ExpandIcon from '../../../../../../util/expandIcon';
import InsuranceDetail from './insuranceDetail';
import BundleRowRender from '../../../expandedRow/bundleRowRender';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
const PayerDetails = props => {
  const { smartContractId, clientId } = props;
  const dispatch = useDispatch();

  const { payersList , dataFetching } = useSelector(
    ({ smart_contract_payers_list_get: { data: payersListData, isFetching, error } }) => {
      return {
        payersList: payersListData && payersListData.SmartContract,
        dataFetching:isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    let Data = {
      JSON: JSON.stringify({
        SmartContract: [{ ClientId: clientId, SmartContractId: smartContractId }],
      }),
    };

    dispatch(smartContractPayersListGet(Data));
  }, []);
  const Columns = [
    {
      title: 'Payer',
      dataIndex: 'PayerName',
      key: 'PayerName',
      sorter: (a, b) => isComparer(a.PayerName, b.PayerName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Payable Amount',
      dataIndex: 'ClaimAmount',
      key: 'ClaimAmount',
      sorter: (a, b) => isComparer(a.ClaimAmount, b.ClaimAmount),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="text-right">
            <span className="">{text}</span>
          </div>
        );
      },
    },
    {
      title: 'Insured Authorization',
      dataIndex: 'InsuredAuthorization',
      key: 'InsuredAuthorization',
      sorter: (a, b) => isComparer(a.InsuredAuthorization, b.InsuredAuthorization),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="text-right">
            <span className="">
              {record.ClientInsurance && record.ClientInsurance[0] && record.ClientInsurance[0].InsuredAuthorization?"Yes":"No"}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Percentage Distribution',
      dataIndex: 'PercentageDistribution',
      key: 'PercentageDistribution',
      sorter: (a, b) => isComparer(a.PercentageDistribution, b.PercentageDistribution),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div className="text-right">
            <span className="">{text}</span>
          </div>
        );
      },
    },
  ];

  // const labelValue = [
  //   { label: 'Insurance Provider Name', value: InsuranceName },
  //   { label: 'Insurance Name', value: InsuranceName },
  //   { label: 'Plan Name', value: InsurerPlanName },
  //   {
  //     label: 'Patient Relationship to Primary Insured',
  //     value: PatientRelationshipToPrimaryInsuredText,
  //   },
  //   { label: 'Insured Authorization', value: InsuredAuthorizationText },
  //   { label: 'Insurance ID', value: PrimaryInsuranceId },
  //   { label: 'Medicaid ID', value: SubscriberMedicaidId },
  //   { label: 'Group Number', value: SubscriberGroupNumber },
  // ];

  // React.useEffect(() => {
  //   dispatch(
  //     clientInsuranceGet({ Json: JSON.stringify({ Client: [{ ClientId: props.clientId }] }) }),
  //   );
  // }, []);

  return (
    <>
      <div style={dataFetching ? { height: '200px', position: 'relative' } : { height: '0px' }}>
        <Loader2 loading={dataFetching} />
      </div>
      {!dataFetching && (
        <div className="payer-details p-3">
          <span className="label mb-2">Payer Details</span>
          <div className="row">
            <div className="col-12">
              <Table
                columns={Columns}
                dataSource={payersList}
                pagination={false}
                expandable={{
                  rowExpandable: record => record.IsExpandable,
                  expandedRowRender: InsuranceDetail,
                  expandIcon: ExpandIcon,
                }}
                className="nested-tables"
              />
            </div>
            <div className="col-12 text-right mt-4">
              <div className="">
                <Button
                  type="primary"
                  onClick={() => {
                    props.goToPreviuos();
                  }}
                  className={`btn btn-outline-primary`}
                  style={{ height: '42px' }}
                >
                  Previous
                </Button>{' '}
                &nbsp;
                <Button
                  type="primary"
                  onClick={() => {
                    props.handleNext();
                  }}
                  className={`btn save-button`}
                  style={{ height: '42px' }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PayerDetails;
