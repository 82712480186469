import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Drawer, Checkbox, Input, Modal, Button } from 'antd';
import TermRequest from './requestDrawerForAll/termRequest';
import ConditionRequest from './requestDrawerForAll/conditionRequest';
import CustomModal from '../../../../core/modal';
import CloseIcon from '../../../../Components/CloseIcon';
import {
  smartContractTermsConditionGetAllInfoJson,
  resetClientInsuranceComboGet,
  payerTermsConditionsStatusUpsert,
} from '../../../../store/actions';
import { Loader2 } from '../../../../Components/Loader';

const { TextArea } = Input;

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRequestDrawer: false,
      termCategory: '',
      termsCategoryId: null,
      RequestedDrawer: null,
      showModal: false,
      isTermsAndConditionsApproved: false,
      isDeclineModalOpen: false,
      value: null,
      isDisbled: false,
      conditionCase: [],
    };
  }
  getContractId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var cId = param.get('cId');
    if (cId) {
      cId = parseInt(cId);
    } else {
      cId = null;
    }
    return cId;
  }

  openAndCloseModal = () => {
    this.setState({ showRequestDrawer: !this.state.showRequestDrawer });
  };

  componentDidMount() {
    this.props.dispatch(
      smartContractTermsConditionGetAllInfoJson({
        Json: JSON.stringify({
          TermsCondition: [
            {
              SmartContractId: this.getContractId(),
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ isReqSuccess, isTermsAndConditionReqSuccess, smartContractTAndC }) {
    if (smartContractTAndC && smartContractTAndC != this.props.smartContractTAndC) {
      if (smartContractTAndC.PayerTermsConditionsStatus) {
        // if (smartContractTAndC.PayerTermsConditionsStatus == 2) {
        //   this.setState({
        //     isTermsAndConditionsApproved: false,
        //     isDisbled: true,
        //   });
        // }
        // if (smartContractTAndC.PayerTermsConditionsStatus == 1) {
        //   this.setState({
        //     isTermsAndConditionsApproved: true,
        //     isDisbled: true,
        //   });
        // }

        this.setState({
          isTermsAndConditionsApproved:
            smartContractTAndC.PayerTermsConditionsStatus == 2
              ? false
              : smartContractTAndC.PayerTermsConditionsStatus == 1
              ? true
              : null,
          isDisbled: true,
        });
      } else {
        this.setState({
          isTermsAndConditionsApproved: undefined,
          isDisbled: false,
        });
      }
    }
    if (isReqSuccess && isReqSuccess != this.props.isReqSuccess) {
      this.props.dispatch(
        smartContractTermsConditionGetAllInfoJson({
          Json: JSON.stringify({
            TermsCondition: [
              {
                SmartContractId: this.getContractId(),
              },
            ],
          }),
        }),
      );
      this.openAndCloseModal();
    }

    if (
      isTermsAndConditionReqSuccess &&
      isTermsAndConditionReqSuccess != this.props.isTermsAndConditionReqSuccess
    ) {
      this.setState({ isDeclineModalOpen: false });
      this.props.dispatch(
        smartContractTermsConditionGetAllInfoJson({
          Json: JSON.stringify({
            TermsCondition: [
              {
                SmartContractId: this.getContractId(),
              },
            ],
          }),
        }),
      );
    }
  }

  termsAndConditionsAction = (statusId, reason) => {
    this.props.dispatch(
      payerTermsConditionsStatusUpsert({
        JSON: JSON.stringify({
          TermsConditions: [
            {
              PayerTermsConditionsStatusId: null,
              SmartContractTermsConditionId: this.props.smartContractTermsConditionId,
              StatusId: statusId,
              Reason: reason,
            },
          ],
        }),
      }),
    );
  };

  addReasonChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    const {
      terms,
      conditions,
      isActionAllowed,
      isTermsAndConditionsFetching,
      smartContractTAndC,
      tabsCount,
      goToPreviuos,
      currentTab,
      disable,
      goToNext,
      termId,
      termTitle,
    } = this.props;
    const { PayerTermsConditionsStatusText, PayerTermsConditionsStatus } = smartContractTAndC || {};
    const { RequestedDrawer, isTermsAndConditionsApproved, isDisbled, statusId } = this.state;
    return (
      <div className="payer-authorizations">
        <Loader2 loading={this.props.isFetching} />
        <h5>Terms</h5>
        <div className="client-authorization row justify-content-center">
          <div className="client-authorization-container d-flex col-6">
            <p className="mb-0 client-authorization-label">Terms ID</p>
            <p className="mb-0 client-authorization-value" style={{ backgroundColor: '#F5F8FA' }}>
              {termId && termId}
            </p>
          </div>
          <div className="client-authorization-container d-flex col-6">
            <p className="mb-0 client-authorization-label">Title</p>
            <p className="mb-0 client-authorization-value" style={{ backgroundColor: '#F5F8FA' }}>
              {termTitle && termTitle}
            </p>
          </div>
        </div>

        <div className="p-3" style={{ backgroundColor: '#F8F8F8' }}>
          {terms &&
            terms.map((item, index) => {
              const { Category, Terms, TermsCategoryId, IsRequestPending } = item;
              return (
                <div
                  className="p-3 row m-0 align-items-start my-4"
                  style={{ backgroundColor: 'white' }}
                >
                  <div className="col-10">
                    <p style={{ fontWeight: '700', fontSize: '16px' }} className="mb-0">{`${index +
                      1} ${Category}`}</p>
                    <ul>
                      {Terms &&
                        Terms.map((titem, tindex) => {
                          const { Description } = titem;
                          return (
                            <li style={{ fontWeight: '400', color: '#000', fontSize: '16px' }}>
                              {Description}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  {/* {this.props.showRequest && !IsRequestPending && !PayerTermsConditionsStatus && (
                    <button
                      type="button"
                      className="btn btn-outline-primary col-2"
                      onClick={() =>
                        this.setState({
                          showRequestDrawer: true,
                          termCategory: Category,
                          termsCategoryId: TermsCategoryId,
                          RequestedDrawer: TermRequest,
                        })
                      }
                    >
                      Request
                    </button>
                  )}
                  {this.props.showRequest && IsRequestPending && (
                    <p className="mb-0" style={{ color: '#009EF7', fontWeight: '600' }}>
                      Changes requested
                    </p>
                  )} */}
                  {!IsRequestPending && !PayerTermsConditionsStatus && !this.props.isReadOnly && (
                    <p
                      className="mb-0"
                      style={{
                        color: '#009EF7',
                        fontWeight: '600',
                        fontSize: '15px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        this.setState({
                          showRequestDrawer: true,
                          termCategory: Category,
                          termsCategoryId: TermsCategoryId,
                          RequestedDrawer: TermRequest,
                        })
                      }
                    >
                      Request Changes
                    </p>
                  )}

                  {IsRequestPending && !this.props.isReadOnly && (
                    <p
                      className="mb-0"
                      style={{
                        color: '#00DC31',
                        fontSize: '15px',
                        fontWeight: '600',
                      }}
                    >
                      Changes Requested
                    </p>
                  )}
                </div>
              );
            })}
        </div>

        <h5 className="mt-4">Conditions</h5>
        <div className="p-3" style={{ backgroundColor: '#F8F8F8' }}>
          {conditions &&
            conditions.map((item, index) => {
              const { Condition, ConditionCase, ConditionId, IsRequestPending } = item;
              return (
                <div className="p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{ fontWeight: '700', fontSize: '16px' }}
                      className="mb-0"
                    >{`Condition ${index + 1}: ${Condition}`}</p>
                    {/* {this.props.showRequest && !IsRequestPending && !PayerTermsConditionsStatus && (
                      <button
                        type="button"
                        className="btn btn-outline-primary col-2"
                        onClick={() =>
                          this.setState({
                            showRequestDrawer: true,
                            termCategory: Condition,
                            termsCategoryId: ConditionId,
                            RequestedDrawer: ConditionRequest,
                          })
                        }
                      >
                        Request
                      </button>
                    )}
                    {this.props.showRequest && IsRequestPending && (
                      <p className="mb-0" style={{ color: '#009EF7', fontWeight: '600' }}>
                        Changes requested
                      </p>
                    )} */}

                    {!IsRequestPending && !PayerTermsConditionsStatus && !this.props.isReadOnly && (
                      <p
                        className="mb-0"
                        style={{
                          color: '#009EF7',
                          fontWeight: '600',
                          fontSize: '15px',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.setState({
                            showRequestDrawer: true,
                            termCategory: Condition,
                            termsCategoryId: ConditionId,
                            RequestedDrawer: ConditionRequest,
                            conditionCase: ConditionCase,
                          })
                        }
                      >
                        Request Changes
                      </p>
                    )}

                    {IsRequestPending && !this.props.isReadOnly && (
                      <p
                        className="mb-0"
                        style={{
                          color: '#00DC31',
                          fontSize: '15px',
                          fontWeight: '600',
                        }}
                      >
                        Changes Requested
                      </p>
                    )}
                  </div>

                  <div className="client-authorization row">
                    {ConditionCase &&
                      ConditionCase.map((citem, index) => {
                        const { CaseIf, CaseThen } = citem;
                        return (
                          <div className="client-authorization-container d-flex col-6">
                            <p className="mb-0 client-authorization-label">{CaseIf && CaseIf}</p>
                            <p
                              className="mb-0 client-authorization-value"
                              style={{ backgroundColor: '#F5F8FA' }}
                            >
                              {CaseThen && CaseThen}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>

        {isActionAllowed && !PayerTermsConditionsStatus && (
          <p className="warning-info-text my-3">
            Cannot Accept Terms. Please wait for the Provider to resolve all requested changes.
          </p>
        )}

        <div className="tAndC mt-3">
          <Checkbox
            onChange={e => {
              this.setState({ isTermsAndConditionsApproved: e.target.checked });
            }}
            disabled={
              (terms && terms.length <= 0 && conditions && conditions.length <= 0) ||
              isActionAllowed ||
              isDisbled ||
              this.props.isReadOnly
            }
            checked={isTermsAndConditionsApproved}
          >
            I acknowledge that I have read, understood, and agree to the terms and conditions
            outlined above. I understand my rights and responsibilities as a patient receiving
            medical services, and I agree to comply with the guidelines set forth by the medical
            professional. By checking this box, I indicate my acceptance and agreement to these
            terms and conditions.
          </Checkbox>

          {!PayerTermsConditionsStatus && (
            <div className="d-flex">
              <Button
                type="button"
                className="btn save-button"
                style={{ height: '45px' }}
                disabled={
                  !this.state.isTermsAndConditionsApproved ||
                  (terms && terms.length <= 0 && conditions && conditions.length <= 0) ||
                  isActionAllowed ||
                  isDisbled ||
                  this.props.isReadOnly
                }
                onClick={() => {
                  this.termsAndConditionsAction(1, null);
                }}
                loading={isTermsAndConditionsFetching}
              >
                Accept Terms and Conditions
              </Button>
              <button
                type="button"
                className="btn btn-outline-danger ml-3"
                // disabled={!this.state.isTermsAndConditionsApproved}
                onClick={() => {
                  this.setState({ isDeclineModalOpen: true });
                }}
                disabled={
                  isActionAllowed ||
                  (terms && terms.length <= 0 && conditions && conditions.length <= 0) ||
                  isDisbled ||
                  this.props.isReadOnly
                }
              >
                Decline Terms & Conditions
              </button>
            </div>
          )}

          {PayerTermsConditionsStatus == 1 && (
            <div
              className="d-flex justify-content-start align-items-center mt-2"
              style={{ textAlign: 'end' }}
            >
              <a
                type="button"
                className="btn-approved d-flex align-items-center"
                style={{
                  width: 'fit-content',
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'inherit',
                }}
              >
                <img src="images/approve.svg" />
                <p className="mb-0 ml-2" style={{ fontSize: '18px' }}>
                  {PayerTermsConditionsStatusText}
                </p>
              </a>
            </div>
          )}

          {PayerTermsConditionsStatus == 2 && (
            <div
              className="d-flex justify-content-start align-items-center mt-2"
              style={{ textAlign: 'end' }}
            >
              <a
                type="button"
                className="btn-deny d-flex align-items-center"
                style={{
                  width: 'fit-content',
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'inherit',
                }}
              >
                <img src="images/deny.svg" />
                <p className="mb-0 ml-2" style={{ fontSize: '18px' }}>
                  {PayerTermsConditionsStatusText}
                </p>
              </a>
            </div>
          )}
        </div>

        <div class="col-12 text-right mt-3">
          {(currentTab > 0 || currentTab + 1 == tabsCount) && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="button"
              onClick={goToNext}
              class="btn btn-eForm-Next"
              disabled={disable}
              style={{ padding: '10px 39px' }}
            >
              Next
            </button>
          )}
        </div>

        <Modal
          open={this.state.isDeclineModalOpen}
          footer={null}
          closable={true}
          width={700}
          destroyOnClose={true}
          onCancel={() => {
            this.setState({ isDeclineModalOpen: false });
          }}
          style={{ padding: '80px' }}
          className="decline-modal"
          closeIcon={<CloseIcon />}
        >
          <h5>Reason for Decline</h5>
          <TextArea rows={5} value={this.state.value} onChange={this.addReasonChange} />
          <div className="mt-3">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                this.setState({ isDeclineModalOpen: false });
              }}
            >
              Cancel
            </button>
            <Button
              type="button"
              className="btn save-button ml-3"
              onClick={() => {
                this.termsAndConditionsAction(2, this.state.value);
              }}
              loading={isTermsAndConditionsFetching}
              style={{ height: '45px' }}
            >
              Submit
            </Button>
          </div>
        </Modal>

        {RequestedDrawer && (
          <Drawer
            className="custom-drawer"
            title={'Terms Change Request'}
            placement="right"
            open={this.state.showRequestDrawer}
            width={800}
            bodyStyle={{ paddingBottom: 80, backgroundColor: 'transparent' }}
            closable={true}
            destroyOnClose={true}
            onClose={() => {
              this.setState({
                showRequestDrawer: false,
              });
            }}
          >
            <RequestedDrawer
              termCategory={this.state.termCategory}
              termsCategoryId={this.state.termsCategoryId}
              dispatch={this.props.dispatch}
              isReqFetching={this.props.isReqFetching}
              contractId={this.getContractId()}
              openAndCloseModal={this.openAndCloseModal}
              conditionCase={this.state.conditionCase}
            />
          </Drawer>
        )}
        {/* <CustomModal
          isOpen={this.state.showModal}
          header={'Request sent successfully'}
          yesAction={() => {
            this.setState({ showModal: false, showRequestDrawer: false });
            this.props.dispatch(resetClientInsuranceComboGet({}));
          }}
          yesText="OK"
          hideCancel={true}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = ({
  smart_contract_terms_condition_getallinfo_json,
  payer_portal_request_upsert,
  payer_terms_conditions_status_upsert,
}) => {
  const { data: smartContractTAndC, isFetching } = smart_contract_terms_condition_getallinfo_json;
  const { data: requestUpsert, isFetching: isReqFetching } = payer_portal_request_upsert;
  const { data, isFetching: isTermsAndConditionsFetching } = payer_terms_conditions_status_upsert;

  let terms = [];
  let conditions = [];
  let isReqSuccess = false;
  let isActionAllowed = false;
  let smartContractTermsConditionId = null;
  let isTermsAndConditionReqSuccess = false;
  let termId = null;
  let termTitle = ''

  isActionAllowed = smartContractTAndC && smartContractTAndC.IsChangeRequest;
  // isActionAllowed = false;

  if (smartContractTAndC && smartContractTAndC.TermsCondition) {
    smartContractTermsConditionId =
      smartContractTAndC.TermsCondition[0] &&
      smartContractTAndC.TermsCondition[0].SmartContractTermsConditionId;
    termId = smartContractTAndC.TermsCondition[0] && smartContractTAndC.TermsCondition[0].TermId;
    termTitle = smartContractTAndC.TermsCondition[0] && smartContractTAndC.TermsCondition[0].Title;
    terms =
      smartContractTAndC.TermsCondition[0] && smartContractTAndC.TermsCondition[0].TermsCategory;
    conditions =
      smartContractTAndC.TermsCondition[0] && smartContractTAndC.TermsCondition[0].Condition;
  }
  if (requestUpsert && requestUpsert.success) {
    isReqSuccess = true;
  }
  if (data && data.success) {
    isTermsAndConditionReqSuccess = true;
  }
  return {
    terms,
    conditions,
    isReqSuccess,
    isReqFetching,
    isFetching,
    isActionAllowed,
    smartContractTermsConditionId,
    isTermsAndConditionReqSuccess,
    isTermsAndConditionsFetching,
    smartContractTAndC,
    termId,
    termTitle,
  };
};

export default connect(mapStateToProps)(withRouter(TermsAndConditions));
