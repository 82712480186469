import React, { Component } from 'react';

import Loader, { Loader2 } from '../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Avatar } from 'antd';
import { Checkbox, Radio, Tooltip, Modal } from 'antd';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import {
  getSmallGroupList,
  getSmallGroup,
  sendVoucherCode,
  appSubscribersUserSearch,
} from '../store/actions';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};
const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Mobile app referral sent successfully',
  });
};
class SendInvitationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      searchStr: '',
      selectedStudent: '',
      isClientView: true,
      searchedStudent: [],
    };
  }

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  handelGui = () => {
    this.props.GetStudentGui(this.state.selectedStudent);
  };
  componentDidMount() {
    // this.props.dispatch(getAllVacation({}));

    let data = {
      Broadcast: [
        {
          SmallGroupGuid: this.props.SmallGroupGuid,
          FilterBy: null,
        },
      ],
    };
    let searchData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(appSubscribersUserSearch(searchData));
    this.input.focus();
  }
  componentWillReceiveProps({ searchedStudent, isSuccess, errorMessage }) {
    if (searchedStudent && searchedStudent != this.props.searchedStudent) {
      this.setState({ searchedStudent });
    }

    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState(
        {
          inviteMsg: 'Mobile app invitation sent successfully.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              inviteMsg: '',
            });
          }, 5000);
        },
      );

      this.props.dispatch(reset('inviteForm'));
    }
    if (errorMessage && errorMessage != this.props.errorMessage) {
      this.setState(
        {
          errorMessage: errorMessage,
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorMessage: '',
            });
          }, 5000);
        },
      );
      this.props.dispatch(reset('inviteForm'));
    }
  }

  getSerachedStudent = () => {
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };
  onhandleSubmit = val => {
    const { MobileNumber, Email, FirstName, referralType, CountryCode } = val;
    const { SmallGroupGuid } = this.props;
    const { selectedStudent, isClientView } = this.state;
    let voucherData;
    let subscriberData = {
      SmallGroup: [
        {
          SmallGroupGuid: SmallGroupGuid,
          Name: null,
          Phone: null,
          Email: null,
          UserGuid: selectedStudent.UserGuid,
          ReferralType: referralType,
        },
      ],
    };
    let clientdata = {
      SmallGroup: [
        {
          SmallGroupGuid: SmallGroupGuid,
          Name: FirstName,
          CountryCode: CountryCode,
          Phone: MobileNumber,
          Email: Email,
          UserGuid: null,
          ReferralType: referralType,
        },
      ],
    };
    if (isClientView) {
      voucherData = {
        Json: JSON.stringify(subscriberData),
      };
    } else {
      voucherData = {
        Json: JSON.stringify(clientdata),
      };
    }

    this.props.dispatch(sendVoucherCode(voucherData));
    this.setState({
      selected: '',
      selectedStudent: [],
      isClientView: true,
    });
  };
  toggleView = () => {
    this.setState(prevState => ({
      isClientView: !prevState.isClientView,
      inviteMsg: '',
      errorMessage: '',
    }));
  };

  render() {
    const {
      onOk,
      onCancel,
      isFetching,
      isFetchingVoucher,
      handleSubmit,
      MobileNumberValue,
      EmailValue,
    } = this.props;
    const { searchStr, searchedStudent, isClientView } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Send App Invitation</h4>
        </div>
        <div class="modal-body">
          <div style={{ textAlign: 'center' }}>
            <h6 style={{ fontSize: '16px', color: 'rgb(18, 196, 117)' }}>{this.state.inviteMsg}</h6>
            <h6 style={{ fontSize: '16px', color: 'rgb(255, 0, 0)' }}>{this.state.errorMessage}</h6>
          </div>
          <form onSubmit={handleSubmit(this.onhandleSubmit)}>
            {/* <Loader2 loading={isFetchingVoucher} /> */}
            <div class="form-group mb-2 custom-radio px-3">
              <h6 className="mr-4 d-inline">Referral Type :</h6>
              <label className="mr-5">
                Referral
                <Field
                  name="referralType"
                  component={ReduxInput}
                  type="radio"
                  value="R"
                  style={{
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #bcbcbc',
                    marginRight: '5px',
                  }}
                />
                <span className="checkmark"></span>
              </label>
              <label>
                Voucher (Free)
                <Field
                  name="referralType"
                  component={ReduxInput}
                  type="radio"
                  value="V"
                  style={{
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #bcbcbc',
                    marginRight: '5px',
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {isClientView ? (
              <div className="subscriberview">
                <div class="search-box w-100 px-3">
                  <div class="form-group has-search">
                    <input
                      type="text"
                      value={searchStr}
                      class="form-control shadow-sm"
                      placeholder="Search"
                      onChange={this.studentSearch}
                      ref={ref => (this.input = ref)}
                    />
                  </div>
                </div>

                <div class="row">
                  {
                    <div
                      class="col-12 col-md-12 enrollment-form"
                      style={{ height: '200px', overflowY: 'auto' }}
                    >
                      <Loader2 loading={isFetching} />
                      {searchedStudent.length > 0 ? (
                        searchedStudent.map(item => {
                          return (
                            <div className="col-6 d-inline-block">
                              <div
                                className="d-flex justify-content-between m-1 align-items-center"
                                style={{
                                  boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.06)',
                                  paddingBottom: '10px',
                                  marginBottom: '5px',
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    size="large"
                                    className="mr-2"
                                    // icon={<i class="fas fa-users"></i>}
                                    // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                    src={item.ProfileImageUrl}
                                  />
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                                  >
                                    <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                      {item.DisplayName}
                                    </span>
                                    {/* <span style={{ fontSize: '12px', color: 'gray' }}>March 25, 2020</span> */}
                                  </div>
                                </div>
                                <div>
                                  <Checkbox
                                    checked={this.state.selected === item.UserGuid}
                                    onChange={() => {
                                      this.setState({
                                        selected: item.UserGuid,
                                        selectedStudent: item,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <div className="col-12">
                            <h5 className="text-center">No Record </h5>
                          </div>
                        </>
                      )}
                    </div>
                  }
                </div>
              </div>
            ) : (
              <div className="newclientview col-md-12 enrollment-form">
                <div class="form-group mb-2">
                  <Field
                    name="FirstName"
                    type="text"
                    component={ReduxInput}
                    validate={Validator.requiredInput}
                    fieldName={'First Name'}
                  />
                  <label class="placeholder-label">Name*</label>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div class="form-group mb-2">
                      <Field
                        name="CountryCode"
                        type="text"
                        component={ReduxSelect}
                        options={[
                          { Text: '+1', Value: '1' },
                          { Text: '+91', Value: '91' },
                        ]}
                        placeholder="Country*"
                        validate={Validator.required}
                        fieldName={'Country'}
                      />
                    </div>
                  </div>
                  <div className="col-9">
                    <div class="form-group mb-2">
                      <Field
                        name="MobileNumber"
                        type="text"
                        component={ReduxInput}
                        validate={[
                          Validator.allowNumericMobile,
                          Validator.requiredAny,
                          // () => {
                          //   if (!this.props.MobileNumberValue && !this.props.EmailValue) {
                          //     return 'or Email Required';
                          //   }
                          // },
                        ]}
                        fieldName={'Mobile Number'}
                      />
                      <label class="placeholder-label">Mobile Number</label>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-5">
                  <Field
                    name="Email"
                    type="text"
                    component={ReduxInput}
                    validate={[
                      Validator.email,
                      Validator.requiredAny,
                      // () => {
                      //   debugger;
                      //   if (!MobileNumberValue && !EmailValue) {
                      //     return 'or Phone Required';
                      //   }
                      // },
                    ]}
                    fieldName={'Email'}
                  />
                  <label class="placeholder-label">Email</label>
                </div>
              </div>
            )}
            <div className="text-right" style={{ borderBottom: '2px solid darkgray' }}>
              <h6 style={{ color: '#46b4fe', cursor: 'pointer' }} onClick={this.toggleView}>
                {isClientView ? 'Add a new client' : 'Select an existing client'}
              </h6>
            </div>

            <div class="modal-footer  border-top-0 pt-2">
              <input onClick={onCancel} type="button" class="btn mx-2" value="Cancel" />
              <input
                type="submit"
                disabled={isClientView && this.state.selectedStudent.length <= 0}
                class="btn black-btn font-weight-bold mr-2"
                value=" Send  "
              />
            </div>
          </form>
        </div>
        {/* <Modal
          width={600}
          zIndex={100}
          visible={this.state.isSendAnotherInvitation}
          footer={null}
          onCancel={() => {
            this.setState({
              isSendAnotherInvitation: false,
            });
          }}
          closable={false}
        >
          <div class="modal-content border-0">
            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
            <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
              <h4 class="modal-title col-12 mb-3">
                {this.state.inviteMsg}
                <br />
              </h4>
            </div>
          </div>
        </Modal> */}
      </div>
    );
  }
}
const mapStateToPropsOneStudent = state => {
  const { app_subscribers_user_search, send_voucher_code } = state;
  let searchedStudent = [];
  let searchStr;
  let isSuccess = false;
  let errorMessage;

  const { isFetching: isFetchingVoucher } = send_voucher_code;
  const { data, isFetching } = app_subscribers_user_search;
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    if (app_subscribers_user_search.data && app_subscribers_user_search.data.data) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else {
      searchedStudent = Object.values(data) || [];
    }
  }
  if (send_voucher_code.data && send_voucher_code.data.success) {
    isSuccess = send_voucher_code.data.success;
  } else if (send_voucher_code && send_voucher_code.error) {
    errorMessage = send_voucher_code.error.Message;
  }
  const MobileNumberValue = formValueSelector('inviteForm')(state, 'MobileNumber');
  const EmailValue = formValueSelector('inviteForm')(state, 'Email');
  return {
    searchedStudent,
    isFetching: isFetching || isFetchingVoucher,
    // isFetchingVoucher,
    searchStr,
    isSuccess,
    errorMessage,
    MobileNumberValue,
    EmailValue,
  };
};
// const ReduxSelectOneStudentModal = connect(mapStateToPropsOneStudent)(SelectOneStudentModal);
const ReduxSendInvitationModal = reduxForm({
  form: 'inviteForm',
  enableReinitialize: true,
})(connect(mapStateToPropsOneStudent)(withRouter(SendInvitationModal)));

export { ReduxSendInvitationModal };
