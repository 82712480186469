import React from 'react';
import { Tabs, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PatientVisitInformation from './formSteps/patientVisitInformation';
import DiagnosisCptCode from './formSteps/diagnosisCptCode';
import RenderingProvider from './formSteps/renderingProvider';
import ServiceLine from './formSteps/serviceLine';
import PeerRecoveryServiceLine from './formSteps/peerRecoveryServiceLine';
import BundleService from './formSteps/bundleService';
import GroupServiceLine from './formSteps/GroupServiceLine';
import PaperClaims from './formSteps/paperClaims';
import ServicesByDate from './formSteps/servicesByDate';
import CHBillablePatients from './formSteps/CHBillablePatients';
import CHClients from './formSteps/CHClients';
import renderHTML from 'react-render-html';
import ClaimForm from './formSteps/claimForm';
import BillingProvider from './formSteps/billingProvider';
import CustomModal from '../../../core/modal';
import Loader from '../../../Components/Loader';
import { submit, reset, destroy, Field, reduxForm } from 'redux-form';
import { getDate } from '../../../util/date';
import AntdModal from '../../../Components/CustomModal';
import { ProcessCombos } from '../../../store/comboConfig';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../../component/custom-redux-components';
import PageTitle from '../../../Components/PageTitle';

// import ReduxNewApplicantForm from '../enrollmentForm/newApplicant';

const { TabPane } = Tabs;

class SlidingTabs extends React.Component {
  constructor(props) {
    super(props);
    // props.reset();
    this.state = {
      isProcessed: false,
      tabMapping: {
        0: 'service-line',
        1: 'group-service-line',
        2: 'bundle-service',
        3: 'service-line-byDate',
        4: 'billable-claims-to-CH',
        5: 'client-to-CH',
        6: 'peer-recovery-service-line',
        // 4: 'paper-claims',
      },
      tabLabels: [
        // {
        //   Name: 'Patient Visit Information',
        //   Component: props => <PatientVisitInformation {...props} />,
        // },
        // {
        //   Name: 'Diagnosis/CPT Code',
        //   Component: props => <DiagnosisCptCode {...props} />,
        // },
        // {
        //   Name: 'Rendering Provider',
        //   Component: props => <RenderingProvider {...props} />,
        // },
        // {
        //   Name: 'Billing Provider',
        //   Component: props => <BillingProvider {...props} />,
        // },
        // {
        //   Name: 'Claim Form',
        //   Component: props => <ClaimForm {...props} />,
        // },
        {
          Name: 'Service Line',
          Component: props => <ServiceLine {...props} name={'service-line'} />,
        },
        {
          Name: 'Group Service Line',
          Component: props => <GroupServiceLine {...props} name={'group-service-line'} />,
        },
        {
          Name: 'Bundled Service',
          Component: props => <BundleService {...props} name={'bundle-service'} />,
        },
        {
          Name: 'Services By Date',
          Component: props => <ServicesByDate {...props} name={'service-line-byDate'} />,
        },
        {
          Name: 'Billable Claims to CH',
          Component: props => <CHBillablePatients {...props} name={'billable-claims-to-CH'} />,
        },
        {
          Name: 'Patients to CH',
          Component: props => <CHClients {...props} name={'client-to-CH'} />,
        },
        {
          Name:
            '<span className="text-left text-wrap w-100 d-block">Peer Recovery Service Line </span>',
          Component: props => (
            <PeerRecoveryServiceLine {...props} name={'peer-recovery-service-line'} />
          ),
        },
        // {
        //   Name: 'Paper Claims',
        //   Component: props => <PaperClaims {...props} />,
        // },
      ],

      // activeKey: this.getStudentId() === -1 ? 0 : null,
      activeKey: 0,
      // activeTabs: this.getStudentId() > 0 ? [true, true, true, true] : [false, false, false, false],
      activeTabs: [true, true, true],
      isModelOpen: false,
      isNewSaveModelOpen: false,
      isDeleteModelOpen: false,
      deleteModelMessage: null,
      yesCallBack: null,
      showError: false,
      modalVisible: false,
    };
  }
  onTabClick = key => {
    let keyData = parseInt(key);
    let clickedTab = this.state.tabMapping[keyData];
    this.props.history.push(`/cw/claims?tab=${clickedTab}`);
    this.setState({
      activeKey: parseInt(key),
    });

    // const { activeTabs } = this.state;
    // if (activeTabs[key]) {
    //   this.setState({ activeKey: key, activeTabs });
    // }
  };

  backButtonImplimentation = nextProps => {
    let upComingTab = new URLSearchParams(nextProps.history.location.search);
    upComingTab = upComingTab.get('tab');
    let upComingTabIndex = Object.values(this.state.tabMapping).indexOf(upComingTab);
    let pastTabs = new URLSearchParams(this.props.location.search);
    pastTabs = pastTabs.get('tab');
    let pastTabsIndex = Object.values(this.state.tabMapping).indexOf(pastTabs);
    if (upComingTabIndex != pastTabsIndex) {
      this.setState({
        activeKey: upComingTabIndex,
      });
    }
  };

  componentWillReceiveProps(nextProps, state) {
    this.backButtonImplimentation(nextProps);
  }

  componentDidMount(props) {
    const { tabMapping } = this.state;

    var params = new URLSearchParams(this.props.location.search);
    var tab = params.get('tab');
    if (tabMapping) {
      this.setState({
        activeKey: Object.values(tabMapping).indexOf(tab),
      });
    }
  }
  render() {
    const { activeKey, studentData, tabLabels, activeTabs } = this.state;
    const { isReadOnlyUser } = this.props;
    return (
      <div class="w-100 rcm-panel mt-3">
        <PageTitle TitleText="Claims" />
        <div class="main enrollment-page  mb-5">
          <div class="container-fluid px-5">
            <div class="row top-table-search">
              <div className="mt-3 mb-3 cursor-pointer">
                <a class="back-link" onClick={() => this.props.history.goBack()}>
                  <img src="images/back-arrow.svg" /> Back
                </a>
              </div>

              <div class="col-12 mb-5 vertical-tabs pat-tab accounting-tab claims-tab">
                <Tabs
                  activeKey={String(activeKey)}
                  tabPosition="left"
                  defaultActiveKey="0"
                  onTabClick={this.onTabClick}
                >
                  {tabLabels.map((item, index) => {
                    const { Component, Name } = item;
                    return (
                      <TabPane
                        tab={Name && renderHTML(Name)}
                        key={`${index}`}
                        className="tab-content"
                      >
                        {activeKey == index && (
                          <Component
                            style={{ padding: '36px 20px 150px 20px' }}
                            //   initialValues={studentData}
                            //   studentId={this.getStudentId()}
                            dispatch={this.props.dispatch}
                            // onCancel={() => this.props.history.goBack()}
                            //   isFetching={isFetching}
                            //   isUpserting={isUpserting}
                            //   updateDeleteModel={this.updateDeleteModel}
                            //   onLastSubmit={onSubmit.bind(this)}
                            //   goToPreviuos={() => this.goToPreviuos(index)}
                            //   onSubmit={onSubmit.bind(this)}
                            enableReinitialize={true}
                            //   keepDirtyOnReinitialize={true}
                            //   disable={this.getDischarged()}
                            isReadOnlyUser={isReadOnlyUser}
                          />
                        )}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { default_values } = state;

  let isReadOnlyUser = false;

  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }

  return {
    isReadOnlyUser,
  };
};

export default withRouter(connect(mapStateToProps)(SlidingTabs));
