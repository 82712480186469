import React from 'react';
import { Switch } from 'antd';

const Toggle = ({ value, onChange, checkedChildren, unCheckedChildren, disabled }) => (
  <Switch
    checked={value}
    onChange={onChange}
    size="small"
    checkedChildren={checkedChildren || 'Yes'}
    unCheckedChildren={unCheckedChildren || 'No'}
    disabled={disabled}
  />
);

export default Toggle;

const ReduxToggle = ({
  input,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  className,
  disabled,
  tabIndex,
  checkedChildren,
  unCheckedChildren,
}) => {
  var { touched, error, warning } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onBlur = input.onBlur;
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <Toggle
        {...input}
        tabIndex={tabIndex}
        name={name}
        id={id}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        disabled={!!disabled}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
      />
      {touched &&
        ((error && <span className="required-color-red">{error}</span>) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

export { ReduxToggle };
