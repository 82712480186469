import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Tabs, Button, Drawer } from 'antd';
import renderHTML from 'react-render-html';
import CustomModal from '../../core/modal';
import AntdModal from '../../Components/CustomModal';
import CloseIcon from '../../Components/CloseIcon';
import {
  surveyQuestionConfigGet,
  surveyQuestionConfigUpsert,
  surveyQuestionConfigBannerGet,
  resetSurveyQuestionConfigUpsert,
  surveyQuestionLibraryQuestionListGet,
  surveyQuestionLibraryQuestionDelete,
  surveyQuestionLibrarySectionUpdate,
  surveyQuestionLibraryQuestionUpsert,
  resetSurveyQuestionLibraryQuestionUpsert,
  surveyQuestionLibraryQuestionGet,
  resetSurveyQuestionLibraryQuestionGet,
  resetSurveyQuestionSectionUpsert,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import ConfigutationTab from './tabs/ConfigutationTab';
import ManageNewQuestion from './ManageNewQuestion';
import AddCategory from './AddCategory';
import PageTitle from '../../Components/PageTitle';
const { TabPane } = Tabs;

class ManageAdminQuestionLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      isModelOpen: false,
      isQuestionModelOpen: false,
      questionsAdded: 0,
      isQuestionDrawerOpen: false,
      isCategoryDrawerOpen: false,
      successMsg: null,
      isSectionModalOpen: false,
      questionUpsertMsg: null,
    };
  }
  getQId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var question = param.get('qId');
    if (question) {
      question = parseInt(question);
    } else {
      question = null;
    }
    return question;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(surveyQuestionLibraryQuestionListGet({}));
  }
  componentWillReceiveProps({
    surveyQuestion,
    isAnswerUpsert,
    isConfigUpsert,
    isQuestionUpsert,
    questionData,
    isSectionUpsert,
    upsertSuccessMsg,
    isQuestionUpsertMsg,
  }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      const {
        SurveyNumericInputCondition,
        SurveyQuestionSection,
        SurveyQuestionType,
        TotalQuestionsAdded,
      } = surveyQuestion;
      let array = surveyQuestion;

      array.Questions &&
        array.Questions.map((item, index) => {
          const { Tab, Sequence, Questions } = item;
          let arrayOne = [];
          let arrayTwo = [];
          Questions &&
            Questions.map(item => {
              if (item.IsArchived) {
                arrayOne.push(item);
              } else {
                arrayTwo.push(item);
              }
            });
          array.Questions[index].Draggable = [...arrayTwo];
          array.Questions[index].UnDraggable = [...arrayOne];
        });

      this.setState({
        SurveyQuestionList: array,
        questionsAdded: TotalQuestionsAdded,
        questionsListOptions: {
          SurveyNumericInputCondition,
          SurveyQuestionSection,
          SurveyQuestionType,
          TotalQuestionsAdded,
        },
      });
    }

    if (isConfigUpsert && isConfigUpsert != this.props.isConfigUpsert) {
      this.setState({ isModelOpen: isConfigUpsert });
    }
    if (isQuestionUpsert && isQuestionUpsert != this.props.isQuestionUpsert) {
      this.toggleDrawer();
      this.setState({ isQuestionModelOpen: true, questionUpsertMsg: isQuestionUpsertMsg });
    }
    if (isSectionUpsert && isSectionUpsert != this.props.isSectionUpsert) {
      this.toggleCategoryDrawer();
      this.props.dispatch(surveyQuestionLibraryQuestionListGet({}));
      this.setState({
        successMsg: upsertSuccessMsg,
        isSectionModalOpen: true,
      });
    }
    if (questionData && JSON.stringify(questionData) != JSON.stringify(this.props.questionData)) {
      this.setState({
        questionData: questionData.SurveyQuestion[0],
        SurveyQuestionCategoryConfigId: questionData.SurveyQuestionCategoryConfigId,
        isQuestionDrawerOpen: true,
      });
    }
  }
  onTabClick = (key, abc) => {
    this.setState({
      activeKey: parseInt(key),
    });
    window.scrollTo(0, 0);
  };
  setValue = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    TabName,
    Sequence,
    OIndex,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { activeKey, tabLabels } = this.state;
    // let currentTab = SurveyQuestionList.SurveyQuestion.filter(tabItem => tabItem.Tab === TabName);
    let OptKeyName = `Option${OIndex}QF`;
    SurveyQuestionList.Questions[activeKey].Questions[index].IsArchived = response;

    this.setState({ SurveyQuestionList });
  };

  onSort = questionList => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { activeKey, tabLabels } = this.state;
    SurveyQuestionList.Questions[activeKey].Draggable = questionList[0];
    this.setState({ SurveyQuestionList });
  };

  onMoveTo = (tabValue, surveyQuestions) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    let questionIndex;
    const { activeKey, tabLabels } = this.state;
    let newArray = [];
    SurveyQuestionList.Questions[activeKey].Draggable.map((item, index) => {
      if (item.SurveyQuestionId == surveyQuestions.SurveyQuestionId) {
        questionIndex = index;
        return SurveyQuestionList.Questions[activeKey].Draggable.splice(index, item);
      } else {
        return newArray.push(item);
      }
    });

    SurveyQuestionList.Questions.map((item, index) => {
      if (item.Tab == tabValue) {
        if (SurveyQuestionList.Questions[index].Draggable == null) {
          SurveyQuestionList.Questions[index].Draggable = [];
          SurveyQuestionList.Questions[index].Draggable.push(
            SurveyQuestionList.Questions[activeKey].Draggable[questionIndex],
          );
        } else {
          SurveyQuestionList.Questions[index].Draggable.push(
            SurveyQuestionList.Questions[activeKey].Draggable[questionIndex],
          );
        }
      }
    });
    SurveyQuestionList.Questions[activeKey].Draggable = newArray;
    this.setState({ SurveyQuestionList });
  };
  onEditAction = ({ survey, index }) => {
    let jsonData = {
      SurveyQuestionLibrary: [
        {
          SurveyQuestionCategoryConfigId: survey.SurveyQuestionCategoryConfigId,
          SurveyQuestionId: survey.SurveyQuestionId,
        },
      ],
    };
    this.props.dispatch(
      surveyQuestionLibraryQuestionGet({
        json: JSON.stringify(jsonData),
      }),
    );
  };
  onNext = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    OIndex,
    TabName,
    Sequence,
    OptKeyName,
    SurveyQuestionListItems,
    survey,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { activeKey, tabLabels } = this.state;
    SurveyQuestionList.Questions[activeKey].Draggable = SurveyQuestionListItems[0];
    SurveyQuestionList.Questions[activeKey].Draggable[index][OptKeyName] = response;
    this.setState({ SurveyQuestionList });
  };
  onQuestionStateChange = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    TabName,
    Sequence,
    OptKeyName,
    SurveyQuestionListItems,
    survey,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { activeKey, tabLabels } = this.state;
    if (response) {
      let length = SurveyQuestionList.Questions[activeKey].UnDraggable.length;
      this.setState(prevstate => ({ questionsAdded: prevstate.questionsAdded - 1 }));
      survey[OptKeyName] = response;
      SurveyQuestionList.Questions[activeKey].UnDraggable.push(survey);
      SurveyQuestionList.Questions[activeKey].UnDraggable[length][OptKeyName] = response;
      SurveyQuestionList.Questions[activeKey].Draggable.splice(index, 1);
    } else if (!response) {
      this.setState(prevstate => ({ questionsAdded: prevstate.questionsAdded + 1 }));
      let length = SurveyQuestionList.Questions[activeKey].Draggable.length;
      survey[OptKeyName] = response;
      SurveyQuestionList.Questions[activeKey].Draggable.push(survey);
      SurveyQuestionList.Questions[activeKey].Draggable[length][OptKeyName] = response;
      SurveyQuestionList.Questions[activeKey].UnDraggable.splice(index, 1);
    }

    this.setState({ SurveyQuestionList });
  };

  onSave = () => {
    const { SurveyQuestionList } = this.state;
    let surveyArray = [];
    SurveyQuestionList.Questions.map((item, index) => {
      const { Tab, Sequence, Questions, UnDraggable, Draggable } = item;
      surveyArray.push({
        Tab: Tab,
        Sequence: Sequence,
        IsAdmin: true,
        Questions: [...Draggable, ...UnDraggable],
      });
    });
    this.props.dispatch(
      surveyQuestionConfigUpsert({
        json: JSON.stringify({
          SurveyQuestion: [...surveyArray],
          SurveyQuestionCategoryConfigId: this.props.surveyQuestion.SurveyQuestionCategoryConfigId,
        }),
      }),
    );
  };
  toggleDrawer = () => {
    this.setState({ isQuestionDrawerOpen: !this.state.isQuestionDrawerOpen });
  };
  toggleCategoryDrawer = () => {
    this.setState({ isCategoryDrawerOpen: !this.state.isCategoryDrawerOpen });
  };
  handleNewQuestion = values => {
    const {
      Category,
      Question,
      QuestionFormat,
      isQuestionMandatory,
      isAddNote,
      ThresholdSign,
      ResponseThreshold,
      MaxResponseThreshold,
    } = values;
    let options = values[`options${QuestionFormat}`];
    let surveyQuestion = {};

    options &&
      Object.values(options[0]).length > 0 &&
      options.map((opt, optIndex) => {
        let index = optIndex + 1;
        surveyQuestion = {
          ...surveyQuestion,
          [`Option${index}Text`]: opt.option,
          [`Option${index}QF`]: !opt.IsFlagged,
        };
      });
    const questionData = {
      SurveyQuestionLibrary: [
        {
          SurveyQuestionCategoryConfigId: this.props.surveyQuestion.SurveyQuestionCategoryConfigId,
          SurveyQuestion: [
            {
              SurveyQuestionId: values.SurveyQuestionId || null,
              SurveyQuestionSubCategoryId: Category,
              Question: Question,
              SurveyQuestionTypeId: QuestionFormat,
              IsMandatory: isQuestionMandatory,
              AddNote: isAddNote,
              ThresholdSign: ThresholdSign,
              ResponseThreshold: ResponseThreshold,
              MaxResponseThreshold: ThresholdSign === 'Between' ? MaxResponseThreshold : null,
              Option1QF: !values.Option1QF,
              ...surveyQuestion,
            },
          ],
        },
      ],
    };
    this.props.dispatch(
      surveyQuestionLibraryQuestionUpsert({
        JSON: JSON.stringify(questionData),
      }),
    );
  };
  render() {
    const {
      activeKey,
      studentData,
      tabLabels,
      activeTabs,
      SurveyQuestionList,
      questionsAdded,
      TotalQuestionsAdded,
    } = this.state;
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Library" />
        <div class="content-area">
          <Loader loading={this.props.isFetchingSurveyQuestion} />
          <div class="row top-table-search mt-2 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              {/* <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage/question-library')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a> */}
              <AntdModal
                ModalButton={({ onClick }) => (
                  <a class="back-link" style={{ cursor: 'pointer' }} onClick={onClick}>
                    <img src="images/back-arrow.svg" /> Back
                  </a>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div className="modal-content border-0">
                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                      <h4 className="modal-title col-12">
                        Are you sure you want to exit this page? <br />
                        Please click "Cancel" and Save your changes.
                      </h4>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary py-1"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn py-1"
                        onClick={() => {
                          onOk();
                          this.props.history.push('/manage');
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
              />
              <h3 class="page-title mb-3 mb-md-0">Screening Questions Library Config</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div className="d-flex" style={{ backgroundColor: 'white', padding: '10px' }}>
                <p className="mb-0">
                  Total Questions Added: <strong>{questionsAdded || 0}</strong>
                </p>
              </div>
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  this.setState({
                    isCategoryDrawerOpen: true,
                  });
                }}
              >
                Manage Categories
              </button>

              <Button
                // loading={this.props.isUpsertFetching}
                type="primary"
                className="save-button"
                style={{ height: '46px', marginLeft: '15px' }}
                onClick={() => {
                  this.props.dispatch(resetSurveyQuestionLibraryQuestionGet({}));
                  this.setState({
                    questionData: null,
                    SurveyQuestionCategoryConfigId: null,
                    isQuestionDrawerOpen: true,
                  });
                }}
              >
                Add New Question
              </Button>
            </div>
          </div>
          <div className="row">
            <div class="col-12 mb-2 vertical-tabs question-lib-tab">
              {SurveyQuestionList && (
                <Tabs
                  activeKey={String(activeKey)}
                  tabPosition="left"
                  defaultActiveKey="0"
                  onTabClick={this.onTabClick}
                  // type="editable-card"
                >
                  {SurveyQuestionList.Questions &&
                    SurveyQuestionList.Questions.map((item, index) => {
                      const { Tab, Sequence, Questions } = item;
                      // if (!Questions) return;
                      let Component = () => (
                        <ConfigutationTab
                          {...this.props}
                          SurveyItem={item}
                          dispatch={this.props.dispatch}
                          SurveyQuestion={Questions}
                          onNext={this.onNext}
                          onQuestionStateChange={this.onQuestionStateChange}
                          setValue={this.setValue}
                          scope={this}
                          Sequence={Sequence}
                          TabName={Tab}
                          onSort={this.onSort}
                          onMoveTo={this.onMoveTo}
                          onEditAction={this.onEditAction}
                          activeKey={activeKey}
                          SurveyQuestionList={SurveyQuestionList}
                          UnDraggable={item.UnDraggable}
                          Draggable={item.Draggable}
                          questionsListOptions={this.state.questionsListOptions}
                          isAdmin={true}
                        />
                      );
                      let isTab = Tab && Tab.length;
                      if (Questions && Questions.length > 0) {
                        return (
                          <TabPane
                            tab={isTab && renderHTML(Tab)}
                            key={`${index}`}
                            className="tab-content"
                          >
                            {activeKey == index && (
                              <Component style={{ padding: '36px 20px 150px 20px' }} />
                            )}
                          </TabPane>
                        );
                      }
                    })}
                </Tabs>
              )}
            </div>
          </div>
          <div class="col-12" style={{ position: 'sticky', bottom: '0', zIndex: '990' }}>
            <div
              className="row enrollment-page top-heading-row"
              style={{
                backgroundColor: '#ffffff',
                boxShadow: 'rgba(0, 0, 0, 0.03)',
              }}
            >
              <div class="col-12 px-5 py-3">
                <div className="d-flex align-items-center justify-content-end">
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <button
                        onClick={onClick}
                        className="btn btn-outline-primary"
                        tyle={{ height: '46px', marginLeft: '15px' }}
                      >
                        Cancel
                      </button>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                          <h4 className="modal-title col-12">
                            Are you sure you want to exit this page? <br />
                            Please click "Cancel" and Save your changes.
                          </h4>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary py-1"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn py-1"
                            onClick={() => {
                              onOk();
                              this.props.history.push('/manage');
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    )}
                  />
                  <Button
                    loading={this.props.isUpsertFetching}
                    type="primary"
                    style={{ height: '46px', marginLeft: '15px' }}
                    className="save-button"
                    onClick={() => {
                      this.onSave();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Drawer
            className="question-library-drawer"
            title={
              <div className="question-library-drawer-title">
                <span className="title">
                  {this.state.SurveyQuestionCategoryConfigId ? `Edit` : `Add`} New Question
                </span>
              </div>
            }
            placement="right"
            open={this.state.isQuestionDrawerOpen}
            width={800}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.toggleDrawer();
            }}
          >
            <ManageNewQuestion
              toggleDrawer={this.toggleDrawer}
              handleNewQuestion={this.handleNewQuestion}
              isQuestionUpsertFetching={this.props.isQuestionUpsertFetching}
              surveyQuestion={this.props.surveyQuestion}
              initData={this.state.questionData}
              SurveyQuestionCategoryConfigId={this.state.SurveyQuestionCategoryConfigId}
            />
          </Drawer>
          <Drawer
            className="question-library-drawer"
            title={
              <div className="question-library-drawer-title">
                <span className="title">
                  Manage Categories
                  {/* {this.state.SurveyQuestionCategoryConfigId ? `Edit` : `Add`} New Question */}
                </span>
              </div>
            }
            placement="right"
            open={this.state.isCategoryDrawerOpen}
            width={700}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.toggleCategoryDrawer();
            }}
          >
            <AddCategory
              toggleCategoryDrawer={this.toggleCategoryDrawer}
              SurveyQuestionList={SurveyQuestionList}
            />
          </Drawer>
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={'Your changes have been saved successfully'}
            yesAction={() => {
              this.setState({ isModelOpen: false });
              this.props.history.push('/manage');
              this.props.dispatch(surveyQuestionLibraryQuestionListGet({}));
              this.props.dispatch(resetSurveyQuestionConfigUpsert({}));
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
          {/* Your question has been added successfully */}
          <CustomModal
            isOpen={this.state.isQuestionModelOpen}
            header={this.state.questionUpsertMsg}
            yesAction={() => {
              this.props.dispatch(surveyQuestionLibraryQuestionListGet({}));
              this.props.dispatch(resetSurveyQuestionLibraryQuestionUpsert({}));
              this.setState({ isQuestionModelOpen: false });
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
          <CustomModal
            isOpen={this.state.isSectionModalOpen}
            header={this.state.successMsg}
            yesAction={() => {
              this.setState({ isSectionModalOpen: false });
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    survey_question_library_question_list_get,
    survey_answer_upsert,
    survey_question_config_upsert,
    survey_question_library_question_upsert,
    survey_question_library_question_get,
    survey_question_section_upsert,
  } = state;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = survey_question_library_question_list_get;
  const { data: configUpsert, isFetching: isUpsertFetching } = survey_question_config_upsert;
  const { data: upsertData } = survey_answer_upsert;
  const {
    data: questionUpsertData,
    isFetching: isQuestionUpsertFetching,
  } = survey_question_library_question_upsert;
  const { data: sectionUpsertData } = survey_question_section_upsert;
  const {
    data: questionGetData,
    isFetching: isFetchingQuestion,
  } = survey_question_library_question_get;

  let questionData;
  let surveyQuestion;
  let augmentedAnswer;
  let isAnswerUpsert = false;
  let isQuestionUpsert = false;
  let isSectionUpsert = false;
  let isConfigUpsert = false;
  let upsertSuccessMsg = '';
  let isQuestionUpsertMsg = '';

  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }
  if (configUpsert && configUpsert.success) {
    isConfigUpsert = true;
  }
  if (upsertData && upsertData.data && upsertData.data.success) {
    isAnswerUpsert = true;
  }
  if (questionUpsertData && questionUpsertData.success) {
    isQuestionUpsert = true;
    isQuestionUpsertMsg = questionUpsertData.data;
  }
  if (sectionUpsertData && sectionUpsertData.success) {
    isSectionUpsert = true;
    upsertSuccessMsg = sectionUpsertData.data;
  }
  if (questionGetData && questionGetData) {
    questionData = questionGetData;
  }

  return {
    surveyQuestion,
    isFetchingSurveyQuestion: isFetchingSurveyQuestion,
    augmentedAnswer,
    isAnswerUpsert,
    isConfigUpsert,
    isUpsertFetching,
    isQuestionUpsert,
    questionData,
    isFetchingQuestion,
    isSectionUpsert,
    isQuestionUpsertFetching,
    upsertSuccessMsg,
    isQuestionUpsertMsg,
  };
};

export default connect(mapStateToProps)(withRouter(ManageAdminQuestionLibrary));
