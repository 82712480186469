import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import NumberShorten from '../../util/numberShorten';
import { currencyFormatter2, numberFormat } from '../../util/formatter';

class TokenAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: 'Debited in $',
          data: [],
        },
        {
          name: 'Tokens Debited',
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        colors: ['#F3AE85', '#0079FD'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          // curve: ['smooth', 'straight', 'stepline']
          curve: ['straight', 'smooth'],
          width: [2, 2],
          lineCap: 'square',
        },
        // title: {
        //   text: 'Average High & Low Temperature',
        //   align: 'left',
        // },
        // grid: {
        //   borderColor: '#e7e7e7',
        //   row: {
        //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //     opacity: 0.5,
        //   },
        // },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            min: 10,
            max: 1000,
            forceNiceScale: true,
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#F3AE85',
            },
            labels: {
              style: {
                colors: '#000000',
              },
            },
            title: {
              text: 'Debited in $',
              style: {
                color: '#9A9A9A',
              },
            },
          },
          {
            title: {
              text: 'Number of Tokens Debited',
              style: {
                color: '#9A9A9A',
              },
            },
            labels: {
              forceNiceScale: true,
              show: true,
              align: 'left',
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
              },
              offsetX: 0,
              offsetY: 0,
              rotate: 0,
              formatter: value => {
                return value && NumberShorten(value);
              },
            },
            axisBorder: {
              show: true,
              color: '#0079FD',
            },
            min: 1000,
            max: 12000,
            forceNiceScale: true,
          },
        ],

        legend: {
          horizontalAlign: 'center',
          offsetX: 40,
        },
      },
    };
  }
  componentWillReceiveProps({ Graph }) {
    if (Graph && Graph != this.props.Graph) {
      let stateData = this.state.series;
      let DebitedIn$ = [];
      let TokensDebited = [];
      let Categories = [];
      Graph.map((item, index) => {
        // let cat = item.MonthName.substring(0, 3);
        Categories.push(item.MonthAndYear);
        TokensDebited.push(item.TotalTokensDebited);
        DebitedIn$.push(item.TotalDebitedin$);
      });

      stateData[0].data = DebitedIn$;
      stateData[1].data = TokensDebited;
      let newXaxis = { categories: Categories };
      this.setState({
        options: { ...this.state.options, xaxis: newXaxis },
      });
    }
  }
  render() {
    const { Header, Graph } = this.props;
    const { BalanceTokens, TotalTokensCredited, TotalTokensDebited, TotalDebitedin$ } =
      Header || {};
    return (
      <div className="card border-0 token-analytics">
        {/* {NumberShorten(5500)} */}

        <div class="card-body">
          <span className="title">Token Analytics</span>
          <div className="row mt-2">
            <div className="col-4">
              <div className="status-card status-gray">
                <span className="title">Total Tokens Credited</span>
                <span className="count">
                  <img className="mr-1" src="/images/bigCoin.svg" />
                  {TotalTokensCredited && numberFormat.format(parseInt(TotalTokensCredited))}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="status-card status-blue">
                <span className="title">Total Tokens Debited</span>
                <span className="count">
                  <img className="mr-1" src="/images/bigCoin.svg" />
                  {TotalTokensDebited && numberFormat.format(parseInt(TotalTokensDebited))}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="status-card status-warn">
                <span className="title">Total Debited in $</span>
                <span className="count">
                  {/* <img className="mr-1" src="/images/bigCoin.svg" /> */}
                  {currencyFormatter2.format(TotalDebitedin$ || 0)}
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-12">
              <div className="token-analytics-chart">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(TokenAnalytics);
