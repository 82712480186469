import React from 'react';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

class ReactApexRiskChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barOptions: {
        chart: {
          type: 'bar',
          // height: 300,
          // width: '100%',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        annotations: {
          position: 'front',
          xaxis: [
            {
              x: 15,
              borderColor: '#6DEAB3',
              label: {
                borderColor: '#fff',
                offsetX: 0,
                offsetY: -5,
                style: {
                  color: '#00DC31',
                  fontSize: '12px',
                  fontWeight: 600,
                  // background: '#6DEAB3',
                },

                orientation: 'horizontal',
                text: 'Low Risk',
              },
            },
            {
              x: 50,
              borderColor: '#FFAE49',
              label: {
                borderColor: '#fff',
                offsetX: 0,
                offsetY: -5,
                style: {
                  color: '#FFAE49',
                  fontSize: '12px',
                  fontWeight: 600,
                  // background: '#66C5FA',
                },
                orientation: 'horizontal',
                text: 'Medium Risk',
              },
            },
            {
              x: 100,
              borderColor: '#FF6B6B',
              label: {
                borderColor: '#fff',
                offsetX: 0,
                offsetY: -5,
                style: {
                  color: '#FF2C2C',
                  fontSize: '12px',
                  fontWeight: 600,
                  // background: '#FF6B6B',
                },
                orientation: 'horizontal',
                text: 'High Risk',
              },
            },
          ],
        },
        plotOptions: {
          bar: {
            borderRadius: 1,
            horizontal: true,
            distributed: true,
            dataLabels: {
              enabled: false,
            },
            columnWidth: '100%',
            barHeight: '55%',
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        colors: [],
        dataLabels: {
          enabled: false,
        },
        // title: {
        //   text: 'Risk Factors',
        //   align: 'left',
        //   floating: true,
        // },
        xaxis: {
          tooltip: {
            enabled: false,
          },
          labels: {
            show: false,
          },
          categories: [],
        },
        tooltip: {
          enabled: false, // to hide mouse hover tolltip on bar
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return '';
              },
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.riskChartInfo) {
      let Risk = {
        0: '#6DEAB3',
        50: '#FFE194',
        100: '#FF6B6B',
      };

      let color = [];
      let categories = [];
      let series = [];

      this.props.riskChartInfo.map(item => {
        if (item.Value === null) return;
        categories.push(item.ShortQuestion || '');
        color.push(Risk[item.Value ? item.Value : 0]);
        series.push(item.Value === 0 ? 15 : item.Value);
        // item.Value && series.push(item.Value === 0 ? 15 : item.Value);
      });
      let barOptions = this.state.barOptions;
      let seriesData = null;
      if (series.length > 0) {
        seriesData = [{ data: [...series] }];
      }
      barOptions.xaxis.categories = categories;
      barOptions.colors = [...color];
      this.setState({
        barOptions: barOptions,
        barSeries: seriesData,
      });
    }
  }
  render() {
    const { riskChartInfo, isBackground } = this.props;
    return (
      <div>
        {this.state.barSeries && this.state.barOptions && (
          <>
            {isBackground ? (
              <div class="students-risk-chart-card mb-3" style={{ height: '160px' }}>
                <div class="block">
                  <div style={{ height: '150px', overflowY: 'scroll' }}>
                    <ReactApexChart
                      options={this.state.barOptions}
                      series={this.state.barSeries}
                      type="bar"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div class="border-0 mb-3">
                <div class="block">
                  <div>
                    <ReactApexChart
                      options={this.state.barOptions}
                      series={this.state.barSeries}
                      type="bar"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect()(ReactApexRiskChart);
