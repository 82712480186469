import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import StaffForm from './staffform/index';

class ManageStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getStaffId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffId');
    if (staffId) {
      return (staffId = parseInt(staffId));
    } else {
      staffId = null;
    }
    return staffId;
  };
  render() {
    return (
      <div class="container-fluid">
        <Helmet>
          <title>Staff Management | Sober Peer</title>
        </Helmet>
        <div class="content-area">
          <div class="row top-table-search mt-1 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage/staff-management/staff-list')}
              >
                <img src="images/back-arrow.svg" /> Back to Staff list
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <StaffForm
                pageTitle="Staff"
                baseURL="/manage/"
                listUrl="staff-management/staff-list"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect()(withRouter(ManageStaff));
