import React, { Component } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import SubmitButton from '../../Components/submitButton';
import renderHTML from 'react-render-html';
import { Link, withRouter } from 'react-router-dom';
import { Modal, Button, Menu, Divider, Collapse } from 'antd';
import Loader, { Loader2 } from '../../Components/Loader';
import CustomModal2 from '../../core/modal';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
  ReduxTextarea,
  ReduxTextareaWithCounter,
} from '../../component/custom-redux-components';
import ReduxSelectGroupStudentModal from './userSearchSelectComponent';
import CollapseWithCheckbox from './collapseWithCheckbox';
import {
  getBroadcastFilter,
  appSubscribersUserSearch,
  sendBroadcast,
  comboBroadcastTemplate,
  upsertBroadcastTemplate,
  broadCastUserListGet,
} from '../../store/actions';
const { SubMenu } = Menu;
const { Panel } = Collapse;
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};
var settings = {
  className: 'slider variable-width',
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  variableWidth: true,
  // nextArrow: <SampleNextArrow />,
  // prevArrow: <SamplePrevArrow />,
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'green',
        height: '20px',
        width: '20px',
        fontSize: '0',
        borderRadius: '50%',
        backgroundColor: '#46B4FE',
        backgroundSize: '10px 10px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        position: 'absolute',
        top: '50%',
        left: '99%',
        transform: 'translateY(-50%)',
        backgroundImage: 'url(images/back-arrow.svg)',
        cursor: 'pointer',
        backgroundSize: '19px 45px',
      }}
      // style={{ ...style, display: 'block', background: '#46B4FE' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'green',
        height: '20px',
        width: '20px',
        fontSize: '0',
        borderRadius: '50%',
        backgroundColor: '#46B4FE',
        backgroundSize: '10px 10px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        position: 'absolute',
        top: '50%',
        right: '100%',
        transform: 'translateY(100%)',
        backgroundImage: 'url(images/back-arrow.svg)',
        cursor: 'pointer',
        backgroundSize: '19px 45px',
      }}
      // style={{ ...style, display: 'block', background: '#46B4FE' }}
      onClick={onClick}
    />
  );
}

const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Message broadcasted successfully ',
  });
};

class BroadcaseInvitationModal extends Component {
  constructor() {
    super();
    this.state = {
      message: '',
      selectedPersons: [],
      selectAll: false,
      active: {},
      newTemplateName: '',
      isCreateNew: false,
      filtersArray: [],
      centerFilterArray: [],
      userTypeFilter: [],
      programTypeFilter: [],
      userTagsFilter: [],
      searchStr: null,
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  success = () => {
    const { templateId, newTemplateName } = this.state;

    if (templateId) {
      if (newTemplateName) {
        Modal.success({
          content: 'Template Created Successfully',
        });
      } else {
        Modal.success({
          content: 'Template Updated Successfully',
        });
      }
    } else {
      Modal.success({
        content: 'Template Created Successfully',
      });
    }

    // if (newTemplateName) {
    //   if (templateId && templateId) {
    //     Modal.success({
    //       content: 'Template Updated Successsfully',
    //     });
    //   } else {
    //     Modal.success({
    //       content: 'Template Created Successfully',
    //     });
    //   }
    // } else {
    //   Modal.success({
    //     content: 'Template Updated Successsfully',
    //   });
    // }
  };

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  getSelectAllValue = selectAll => {
    this.setState({ selectAll });
  };

  updatedSelectedPersons = selectedPersons => {
    this.setState({ selectedPersons });
  };
  componentDidMount() {
    this.setState({ active: { Text: 'All Users', Value: 'All' } });

    if (this.props.SmallGroupGuid) {
      let data = {
        Broadcast: [
          {
            Context: 'SmallGroup',
          },
        ],
      };

      let stringifiedData = {
        json: JSON.stringify(data),
      };

      this.props.dispatch(getBroadcastFilter(stringifiedData));
    } else {
      this.props.dispatch(getBroadcastFilter({}));
    }

    this.props.dispatch(comboBroadcastTemplate({}));
  }
  getSelectedUsers = () => {
    const { selectedPersons } = this.state;
    let result;
    if (this.state.selectAll) {
      result =
        selectedPersons &&
        selectedPersons[0] &&
        selectedPersons[0].map(item => {
          return { UserGuid: item.value };
        });
    } else {
      result =
        selectedPersons &&
        selectedPersons[0] &&
        selectedPersons[0].map(item => {
          return { UserGuid: item };
        });
    }
    return result;
  };
  onhandleSubmit = val => {
    const { message, isEmail, isTextMessage, template } = val;
    this.setState({ message, templateId: template });
    let data;
    if (this.props.SmallGroupGuid) {
      data = {
        Broadcast: [
          {
            BroadcastMessage: message,
            IsText: isTextMessage ? 1 : 0,
            IsEmail: isEmail ? 1 : 0,
            User: this.getSelectedUsers(),
            BroadcastGuid: uuidv4(),
            SmallGroupGuid: this.props.SmallGroupGuid,
          },
        ],
      };
    } else {
      data = {
        Broadcast: [
          {
            BroadcastMessage: message,
            IsText: isTextMessage ? 1 : 0,
            IsEmail: isEmail ? 1 : 0,
            User: this.getSelectedUsers(),
            BroadcastGuid: uuidv4(),
          },
        ],
      };
    }

    let searchData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(sendBroadcast(searchData));
  };

  componentWillReceiveProps({
    isSuccess,
    val,
    sendStatusSuccess,
    isTemplateSuccess,
    broadcastFilter,
  }) {
    if (broadcastFilter && broadcastFilter != this.props.broadcastFilter) {
      let filters = [];
      for (let i in broadcastFilter) {
        if (broadcastFilter[i] && broadcastFilter[i].length > 0) {
          let name =
            i == 'FilterBy'
              ? 'User Type'
              : i == 'ProgramType'
              ? 'Program Type'
              : i == 'TagType'
              ? 'User Tags'
              : i;
          filters.push({
            name: name,
            data: broadcastFilter[i],
          });
        }
      }
      this.setState({ filtersArray: filters });
    }
    if (isSuccess && isSuccess !== this.props.isSuccess) {
      this.props.onCancel();
      // this.success();
      this.props.dispatch(reset('inviteForm'));
    }
    if (sendStatusSuccess && sendStatusSuccess !== this.props.sendStatusSuccess) {
      this.setState({ isModalOpen: true });
      // this.props.onCancel();
      // success();
      // this.props.dispatch(reset('inviteForm'));
    }
    if (isTemplateSuccess && isTemplateSuccess !== this.props.isTemplateSuccess) {
      this.setState({ isModalOpen: false });
      this.props.onCancel();
      this.success();
      this.props.dispatch(reset('inviteForm'));
    }
  }

  handleTemplate = operation => {
    let data;
    if (operation === 'createTemplate') {
      data = {
        BroadcastTemplate: [
          {
            BroadcastTemplateId: null,
            NameTemplate: this.state.newTemplateName,
            MessageTemplate: this.state.message,
          },
        ],
      };
    } else {
      data = {
        BroadcastTemplate: [
          {
            BroadcastTemplateId: this.state.templateId,
            MessageTemplate: this.state.message,
            NameTemplate: this.state.textObj[0].Text,
          },
        ],
      };
    }
    let stringifiedData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(upsertBroadcastTemplate(stringifiedData));
  };
  handleCheck = (checkedArray, filterName) => {
    const {
      userTypeFilter,
      centerFilterArray,
      programTypeFilter,
      userTagsFilter,
      searchStr,
    } = this.state;
    let userFilterArray = [...userTypeFilter];
    let programFilterArray = [...programTypeFilter];
    let centersFilterArray = [...centerFilterArray];
    let userTags = [...userTagsFilter];
    // let centerFilterArray = checkedArray && checkedArray.length > 0 ? checkedArray.join(',') : null;

    if (filterName == 'User Type') {
      userFilterArray = checkedArray;
      this.setState({
        userTypeFilter: userFilterArray,
      });
    } else if (filterName == 'Program Type') {
      programFilterArray = checkedArray;
      this.setState({
        programTypeFilter: programFilterArray,
      });
    } else if (filterName == 'User Tags') {
      userTags = checkedArray;
      this.setState({
        userTagsFilter: userTags,
      });
    } else {
      centersFilterArray = checkedArray;
      this.setState({
        centerFilterArray: centersFilterArray,
      });
    }

    let data = {
      Broadcast: [
        {
          SmallGroupGuid: this.props.SmallGroupGuid,
          SearchStr: searchStr,
          FilterBy: userFilterArray.length > 0 ? userFilterArray.join(',') : null,
          CenterIds: centersFilterArray.length > 0 ? centersFilterArray.join(',') : null,
          ProgramTypeIds: programFilterArray.length > 0 ? programFilterArray.join(',') : null,
          TagTypeIds: userTags.length > 0 ? userTags.join(',') : null,
        },
      ],
    };
    let searchData = {
      Json: JSON.stringify(data),
    };
    this.props.dispatch(broadCastUserListGet(searchData));
  };

  getSearchStr = searchStr => {
    this.setState({ searchStr });
  };
  render() {
    const {
      onOk,
      onCancel,
      handleSubmit,
      filters,
      userSearchOption,
      isFilterFetching,
      comboTemplateData,
      isUpsertFetching,
      isAppSubscriber,
    } = this.props;
    const {
      filtersArray,
      userTypeFilter,
      centerFilterArray,
      programTypeFilter,
      userTagsFilter,
    } = this.state;
    return (
      <div
        class="modal-content border-0"
        style={isAppSubscriber ? { boxShadow: 'none' } : { boxShadow: 'inherit' }}
      >
        <div class="modal-header justify-content-start flex-wrap  border-bottom-0 ">
          {!isAppSubscriber && (
            <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
              ×
            </button>
          )}
          {!isAppSubscriber && <h4 class="modal-title col-12">Broadcast Message</h4>}
        </div>
        {localStorage.getItem('isStaffMobileRegistered') != 'null' &&
          !JSON.parse(localStorage.getItem('isStaffMobileRegistered')) && (
            <div>
              <p
                className="font-italic d-flex justify-content-center align-items-center"
                style={{ fontWeight: '600', color: 'red', textAlign: 'center' }}
              >
                <img class="mr-1" src="images/warning.svg" />
                You are required to be registered as an advisor on the mobile app to send in-app
                chat messages.
              </p>
            </div>
          )}

        <div
          class="modal-body col-md-12 enrollment-form"
          style={{ overflowX: 'hidden', overflowY: 'auto', paddingBottom: '10px' }}
        >
          <form onSubmit={handleSubmit(this.onhandleSubmit)}>
            <div className="row broadcast-invitation">
              <div className="form-group col-5 mt-2 mb-2">
                {' '}
                <Field
                  name="template"
                  type="text"
                  errorMessage="Parent Center Required"
                  component={ReduxSelect}
                  options={comboTemplateData}
                  placeholder="Select Template"
                  // validate={Validator.required}
                  fieldName={'template'}
                  onChange={value => {
                    let textObj =
                      comboTemplateData &&
                      comboTemplateData.filter(item => {
                        return item.Value === value;
                      });
                    this.setState({ textObj });
                    this.props.change(`message`, textObj[0].Description);
                  }}
                />
              </div>
              <div className="col-7 d-flex align-items-center">
                <div className="pl-2">Additionally, send via:</div>
                <div class="custom-redux-checkbox">
                  <Field
                    className="mr-2"
                    name="isEmail"
                    component={ReduxCheckbox}
                    fieldName={'Email'}
                  />
                  <label class="placeholder-label" style={{ top: '0px' }}>
                    Email
                  </label>
                </div>
                <div class="custom-redux-checkbox">
                  <Field
                    className="mr-2"
                    name="isTextMessage"
                    component={ReduxCheckbox}
                    fieldName={'Text Message'}
                  />
                  <label class="placeholder-label" style={{ top: '0px' }}>
                    SMS
                  </label>
                </div>
                {/* {this.state.selectedPersons &&
                  this.state.selectedPersons[0] &&
                  this.state.selectedPersons[0].length > 0 && (
                    <div class="modal-footer justify-content-center border-top-0">
                      <SubmitButton
                      loading={this.props.sendBroadcastUpsert}
                      disabled={
                        localStorage.getItem('isStaffMobileRegistered') != 'null' &&
                        !JSON.parse(localStorage.getItem('isStaffMobileRegistered'))
                      }
                      class="btn black-btn font-weight-bold mr-2"
                      value="Send"
                      style={{minWidth : 'unset', padding: '0 43px'}}
                      />
                    </div>
                  )} */}
              </div>
            </div>

            <div
              class="form-group"
              style={
                {
                  // borderRadius: '4px',
                  // border: '1px solid #D8D8D8',
                  // borderBottom: 'none',
                }
              }
            >
              <Field
                name={'message'}
                // class="form-control"
                type="text"
                component={ReduxTextareaWithCounter}
                rows={'10'}
                cols={'50'}
                style={{ minHeight: '192px' }}
                placeholder={' Write your message here or select a template above'}
                showPlaceHolder={true}
                validate={Validator.required}
                fieldName={'Message'}
              />
              {/* <label class="placeholder-label" style={{ paddingLeft: '5px' }}>
                Write your message here
              </label> */}
            </div>

            <div className="mb-2 mt-1" style={{ color: '#263238', fontSize: '20px' }}>
              Select Members
            </div>
            {/* <div className="mb-1 mt-1" style={{ color: '#959595', fontSize: '16px' }}>
              Filter by
            </div>
            <div className="row">
              <div className="col-1 d-flex align-items-center pr-0">
                {' '}
                <img
                  src="images/whiteLeftArrow.svg"
                  onClick={this.previous}
                  style={{
                    background: '#46B4FE',
                    width: '30px',
                    height: '30px',
                    padding: '6px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className="col-10 px-0">
                <div

                // className="d-flex flex-wrap"
                >
                  <Loader2 loading={isFilterFetching} />

                  <Slider ref={c => (this.slider = c)} {...settings}>
                    {filters &&
                      filters.map((items, index) => {
                        return (
                          <li
                            className="d-flex max-content"
                            key={index}

                            // className="p-4" style={{ width: 'auto' }}
                          >
                            <span
                              style={
                                JSON.stringify(this.state.active) === JSON.stringify(items)
                                  ? {
                                      border: '1px solid #4AB0FE',
                                      boxSizing: 'border-box',
                                      filter: 'drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384))',
                                      borderRadius: '50px',
                                      padding: '5px',
                                      margin: '5px',
                                      fontSize: '12px',
                                      color: 'white',
                                      padding: '5px 10px',
                                      background: '#4AB0FE',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      border: '1px solid #979797',
                                      boxSizing: 'border-box',
                                      filter: 'drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384))',
                                      borderRadius: '50px',
                                      padding: '5px',
                                      margin: '5px',
                                      fontSize: '12px',
                                      color: '#979797',
                                      padding: '5px 10px',
                                      cursor: 'pointer',
                                    }
                              }
                              onClick={event => {
                                if (this.props.SmallGroupGuid) {
                                  let data = {
                                    Broadcast: [
                                      {
                                        SmallGroupGuid: this.props.SmallGroupGuid,
                                        FilterBy: items.Value,
                                      },
                                    ],
                                  };
                                  let searchData = {
                                    Json: JSON.stringify(data),
                                  };
                                  this.props.dispatch(appSubscribersUserSearch(searchData));
                                } else {
                                  let data = {
                                    Broadcast: [{ FilterBy: items.Value }],
                                  };
                                  let searchData = {
                                    Json: JSON.stringify(data),
                                  };
                                  this.props.dispatch(appSubscribersUserSearch(searchData));
                                }
                                this.setState({ active: items });
                              }}
                            >
                              {items.Text}
                            </span>
                          </li>
                        );
                      })}
                  </Slider>
                </div>
              </div>

              <div className="col-1 d-flex align-items-center px-0">
                {' '}
                <img
                  onClick={this.next}
                  src="images/whiteLeftArrow.svg"
                  style={{
                    transform: 'rotate(180deg)',
                    background: '#46B4FE',
                    width: '30px',
                    height: '30px',
                    padding: '6px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                />
              </div>
            </div> */}

            <div className="row">
              <div className="col-3 mt-4" style={{ height: '400px', overflow: 'hidden auto' }}>
                <p className="mb-1 mt-1" style={{ color: '#959595', fontSize: '16px' }}>
                  Filter by
                </p>
                {filtersArray &&
                  filtersArray.map((item, index) => {
                    return (
                      <CollapseWithCheckbox
                        filterData={item}
                        handleCheck={this.handleCheck}
                        collapsekey={index}
                      />
                    );
                  })}
              </div>
              {/* <Divider type={'vertical'} /> */}
              <div className="col-9">
                <ReduxSelectGroupStudentModal
                  updatedSelectedPersons={this.updatedSelectedPersons}
                  selectedPersons={this.state.selectedPersons}
                  getSelectAllValue={this.getSelectAllValue}
                  SmallGroupGuid={this.props.SmallGroupGuid}
                  isBroacastScreen={true}
                  getSearchStr={this.getSearchStr}
                  userTypeFilter={userTypeFilter}
                  centerFilterArray={centerFilterArray}
                  programTypeFilter={programTypeFilter}
                  userTagsFilter={userTagsFilter}
                  // searchedStudent={this.props.searchedStudent}
                  // searchStr={this.props.searchStr}
                />
                {/* {this.state.selectedPersons && this.state.selectedPersons.length} */}
                {this.state.selectedPersons &&
                  this.state.selectedPersons[0] &&
                  this.state.selectedPersons[0].length > 0 && (
                    <div class="modal-footer justify-content-center border-top-0 p-0">
                      
                      <SubmitButton
                        loading={this.props.sendBroadcastUpsert}
                        disabled={
                          localStorage.getItem('isStaffMobileRegistered') != 'null' &&
                          !JSON.parse(localStorage.getItem('isStaffMobileRegistered'))
                        }
                        class="btn black-btn font-weight-bold mr-2"
                        value=" Send"
                      />
                    </div>
                  )}
              </div>
            </div>
          </form>
          <div style={{ width: '900px' }}>
            <CustomModal2
              isOpen={this.state.isModalOpen}
              headerTop={
                '<div class="col-12 mb-3 text-center"><span style="color:#2FE192"><i class="fas fa-check-circle"></i></span>&nbsp;<span>The broadcast message was sent successfully</span></div>'
              }
              header={'<span class="text-capitalize">Manage Template</span>'}
              destroyOnClose={true}
              yesAction={() => {
                this.setState({ isModalOpen: false });
                this.props.onCancel();
                // this.success();
                this.props.dispatch(reset('inviteForm'));
              }}
              yesText="close"
              hideOk={true}
              hideCancel={true}
              changeMaxWidth="800px"
              className="px-5 pb-1"
              width="700px"
            >
              <div
                style={{
                  width: '100%',
                  marginBottom: '30px',
                  fontWeight: 'bold',
                }}
              >
                {this.state.templateId && this.state.templateId ? (
                  <div>
                    {/* <div className="d-flex flex-column">
                  <div className="flex-item mb-2">
                    New template message is:
                    <p>"{this.state.message}"</p>
                  </div>
                  <div className="flex-item mb-2">
                    <button
                      className="btn"
                      onClick={() => {
                        this.handleTemplate('updateTemplate');
                      }}
                    >
                      Update as new template{' '}
                    </button>
                  </div>
                  <div className="flex-item mb-2 mt-2">
                    <input
                      style={{ border: '1px solid gray', padding: '5px', marginRight: '10px' }}
                      type="text"
                      onBlur={e => {
                        this.setState({ newTemplateName: e.target.value });
                      }}
                      placeholder="Template name"
                      className="mb-4 w-100"
                    />
                    <button
                      className="btn"
                      onClick={() => {
                        this.handleTemplate('createTemplate');
                      }}
                    >
                      Create as new template{' '}
                    </button>
                  </div>
                  </div> */}
                    <div className="d-flex flex-column">
                      <div className="flex-item mb-2">
                        <div style={{ color: '#828282', fontSize: '14px', fontWeight: 'normal' }}>
                          You made changes to the template{' '}
                          <span
                            style={{
                              color: '#263238',
                              fontSize: '14px',
                            }}
                          >
                            {this.state.textObj[0].Text}
                          </span>
                        </div>
                        <div
                          style={{
                            color: '#263238',
                            fontWeight: '400',
                            fontSize: '16px',
                            marginBottom: '10px',
                            // border: '1px solid #80808059',
                            padding: '5px',
                            background: 'rgb(248, 248, 248)',
                          }}
                        >
                          {this.state.message}
                        </div>
                      </div>
                      <div
                        className="row mb-4"
                        style={{ fontSize: '14px', color: '#263238', fontWeight: '700' }}
                      >
                        {this.state.isCreateNew ? (
                          <>
                            <div className="col-md-12 mb-4">
                              <div className="row">
                                <div
                                  className="col-md-4 d-flex align-items-center "
                                  style={{
                                    fontSize: '14px',
                                    color: '#263238',
                                    fontWeight: '700',
                                  }}
                                >
                                  Create as new template
                                </div>
                                <div className="col-md-5 mt-1 px-0 d-flex align-items-center flex-column">
                                  <input
                                    style={{
                                      border: '1px solid #D8D8D8',
                                      padding: '5px',
                                      marginRight: '10px',
                                    }}
                                    type="text"
                                    onBlur={e => {
                                      this.setState({
                                        newTemplateName: e.target.value,
                                        error: '',
                                      });
                                    }}
                                    className="w-100"
                                    placeholder="Enter new template name"
                                    maxlength="30"
                                  />{' '}
                                  {this.state.error && (
                                    <div className="text-left">
                                      <span style={{ color: 'red', fontWeight: '400' }}>
                                        {this.state.error}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-3 px-0 d-flex align-items-center">
                                  <SubmitButton
                                    className="btn btn-primary"
                                    style={{
                                      // color: '#46B4FE',
                                      // fontSize: '14px',
                                      // marginRight: '15px',
                                      // fontWeight: 'bold',
                                      height: '42px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      if (this.state.newTemplateName.length < 1) {
                                        this.setState({ error: 'Template Name Required' });
                                      } else {
                                        this.setState({ error: '' });
                                        this.handleTemplate('createTemplate');
                                      }
                                    }}
                                    value={'Create as New'}
                                    loading={isUpsertFetching}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-4">
                            Save all changes to the existing template?
                          </div>
                        )}
                        <div
                          className="col-md-5"
                          // style={{
                          //   color: '#46B4FE',
                          //   fontSize: '14px',
                          //   fontWeight: 'bold',
                          //   cursor: 'pointer',
                          // }}
                        >
                          <SubmitButton
                            className={
                              this.state.isCreateNew ? 'btn btn-outline-primary' : 'btn btn-primary'
                            }
                            style={{ height: '42px' }}
                            onClick={() => {
                              this.handleTemplate('updateTemplate');
                            }}
                            loading={isUpsertFetching}
                            value={'Update existing template'}
                          />
                        </div>
                        <div
                          className="col-md-4"
                          // style={{
                          //   color: '#46B4FE',
                          //   fontSize: '14px',
                          //   fontWeight: 'bold',
                          //   cursor: 'pointer',
                          // }}
                        >
                          {!this.state.isCreateNew && (
                            <span
                              className="btn btn-outline-primary"
                              style={{
                                cursor: 'pointer',
                              }}
                              // style={{
                              //   color: '#46B4FE',
                              //   fontSize: '14px',
                              //   marginRight: '15px',
                              //   fontWeight: 'bold',
                              //   cursor: 'pointer',
                              // }}
                              onClick={() => {
                                this.setState({
                                  isCreateNew: true,
                                });
                              }}
                            >
                              Create as new +
                            </span>
                          )}
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                          <span style={{ cursor: 'pointer' }} onClick={this.props.onCancel}>
                            Skip for now
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column">
                    <div className="flex-item mb-2">
                      <div style={{ color: '#828282', fontSize: '14px', fontWeight: 'normal' }}>
                        Your broadcast message:
                      </div>
                      <div
                        style={{
                          color: '#263238',
                          fontWeight: '400',
                          fontSize: '16px',
                          marginBottom: '10px',
                          // border: '1px solid #80808059',
                          padding: '5px',
                          background: 'rgb(248, 248, 248)',
                        }}
                      >
                        {this.state.message}
                      </div>
                    </div>

                    <div style={{ fontSize: '16px', color: '#263238', fontWeight: '700' }}>
                      Save as new template
                    </div>
                    <div className="flex-item  mt-2">
                      <div className="row">
                        <div className="col-6">
                          <input
                            style={{
                              border: '1px solid #D8D8D8',
                              padding: '5px',
                              marginRight: '10px',
                              width: '220px',
                            }}
                            type="text"
                            onBlur={e => {
                              this.setState({ newTemplateName: e.target.value, error: '' });
                            }}
                            // className="w-100"
                            placeholder="Enter new template name here"
                            maxlength="30"
                          />{' '}
                          {this.state.error && (
                            <div className="text-left">
                              <span style={{ color: 'red', fontWeight: '400' }}>
                                {this.state.error}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-12 d-flex align-items-center mt-4">
                          <div className="row w-100">
                            <div className="col-6 d-flex justify-content-start align-items-center">
                              {' '}
                              <SubmitButton
                                className="btn btn-primary"
                                style={{
                                  // color: '#46B4FE',
                                  // fontSize: '16px',
                                  // marginRight: '15px',
                                  // fontWeight: 'bold',
                                  height: '42px',
                                  cursor: 'pointer',
                                }}
                                loading={isUpsertFetching}
                                onClick={() => {
                                  if (this.state.newTemplateName.length < 1) {
                                    this.setState({ error: 'Template Name Required' });
                                  } else {
                                    this.setState({ error: '' });
                                    this.handleTemplate('createTemplate');
                                  }
                                }}
                                value={' Create Template'}
                              />{' '}
                              {/* </span> */}
                            </div>
                            <div className="col-6 d-flex justify-content-end align-items-center">
                              {' '}
                              <span style={{ cursor: 'pointer' }} onClick={this.props.onCancel}>
                                Skip for now
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CustomModal2>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropsModal = state => {
  const {
    app_invite_dashboard,
    get_broadcast_filter,
    send_broadcast,
    combo_broadcast_template,
    upsert_broadcast_template,
    // app_subscribers_user_search
  } = state;
  const { data: inviteData } = app_invite_dashboard;
  const { data: filterArray, isFetching: isFilterFetching } = get_broadcast_filter;
  const { data: sendStatus, isFetching: sendBroadcastUpsert } = send_broadcast;
  const { data: comboTemplateArray } = combo_broadcast_template;
  const { data, isFetching: isUpsertFetching } = upsert_broadcast_template;

  // const { data, isFetching } = app_subscribers_user_search;
  // let searchedStudent = [];
  // let searchStr;

  let isSuccess = false;
  let isTemplateSuccess = false;
  let sendStatusSuccess = false;
  let filters = [];
  let broadcastFilter = {};
  let comboTemplateData = [];

  if (send_broadcast.data && send_broadcast.data.success) {
    sendStatusSuccess = sendStatus.success;
  }
  if (comboTemplateArray && comboTemplateArray.BroadcastTemplate) {
    comboTemplateData = comboTemplateArray && comboTemplateArray.BroadcastTemplate;
  }
  if (app_invite_dashboard.data && app_invite_dashboard.data.Success) {
    isSuccess = inviteData.Success;
  }
  if (filterArray && filterArray.FilterBy && filterArray.FilterBy.length > 0) {
    filters = (filterArray && filterArray.FilterBy) || [];
  }
  if (filterArray) {
    broadcastFilter = filterArray || {};
  }
  if (data && data.success) {
    isTemplateSuccess = data.success;
  }

  // if (app_subscribers_user_search && app_subscribers_user_search.data) {
  //   if (app_subscribers_user_search.data.searchStr) {
  //     searchedStudent = Object.values(data.data) || [];
  //     searchStr = data.searchStr;
  //   } else {
  //     searchedStudent = Object.values(data) || [];
  //   }
  // }
  return {
    isSuccess,
    filters,
    sendStatusSuccess,
    isFilterFetching,
    comboTemplateData,
    isTemplateSuccess,
    broadcastFilter,
    sendBroadcastUpsert,
    isUpsertFetching,
    // searchedStudent,
    // searchStr,
    // name: selector(state, 'firstName'),
  };
};

const ReduxBroadcaseInvitationModal = reduxForm({
  form: 'inviteForm',
})(connect(mapStateToPropsModal)(withRouter(BroadcaseInvitationModal)));

export default ReduxBroadcaseInvitationModal;
