import React, { useState, useEffect } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import { Collapse, Button, Drawer, Divider, Space, Tag, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, submit, reset, FieldArray } from 'redux-form';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusPercentageInput,
  ReduxPlusMinusInputWithRange,
} from '../../../../component/custom-redux-components';
import { comboServiceCode, getServiceCode, smartContractUpdate } from '../../../../store/actions';
import validate from '../../../../core/generic-form/validation';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
import getParam from '../../../../util/getParam';

const { Panel } = Collapse;

const AddRemarkForm = props => {
  const [remarkSubmit, setRemarkSubmit] = useState(null);
  const [textAreaInputData, setTextAreaInputData] = useState(null);
  const { handleSubmit, drawerData, onClose } = props;
  const { CodeName, ServiceCodeDescription, StandardCost, ServiceDelivedDate, ServiceStatus } =
    drawerData && drawerData.serviceData;
  const dispatch = useDispatch();
  useEffect(() => {
    if (remarkSubmit != null) {
      dispatch(submit('addRemarkForm'));
      setRemarkSubmit(null);
    }
  }, [remarkSubmit]);

  const { remarkFieldData } = useSelector(state => {
    return {
      remarkFieldData: formValueSelector('addRemarkForm')(state, 'remarks'),
    };
  }, shallowEqual);

  useEffect(() => {
    setTextAreaInputData(remarkFieldData);
  }, [remarkFieldData]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Code
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {CodeName}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Code Description
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {ServiceCodeDescription}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Cost
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {currencyFormatter.format(StandardCost || 0)}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Delived Date
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {ServiceDelivedDate}
          </span>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div class="form-group mb-0 w-100">
          <label
            className="placeholder-label"
            style={{
              zIndex: 1,
              fontSize: '11px',
              top: '-5px',
              // left: !!disabled || value || active ? '0' : '0',
              position: 'absolute',
            }}
          >
            Service Status
          </label>
          <span
            className=""
            style={{
              marginTop: '13px',
              fontSize: '16px',
              color: '#000',
              padding: '3px',
              display: 'inline-block',
            }}
          >
            {ServiceStatus}
          </span>
        </div>
      </div>
      <div class="col-12 enrollment-form mt-3">
        <div class="form-group">
          <Field
            name="remarks"
            type="text"
            errorMessage="remark Required"
            component={ReduxTextarea}
            validate={validate.required}
            fieldName={'Remarks'}
          />
          <label class="placeholder-label">Add Remarks*</label>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        // class="btn btn-disabled"
        // data-dismiss="modal"
        onClick={() => {
          setRemarkSubmit('Remarks');
          onClose();
        }}
        disabled={!textAreaInputData}
      >
        Add Remarks
      </button>
    </form>
  );
};

const ReduxAddRemarkForm = reduxForm({
  form: 'addRemarkForm',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    const { drawerData } = props;
    const { SmartContractBundleServiceCodeId } = drawerData && drawerData.serviceData;
    let data = {
      SmartContract: [
        {
          SmartContractBundleServiceCodeId: SmartContractBundleServiceCodeId,
          Remarks: values.remarks,
          IsCompleted: drawerData.IsCompleted,
        },
      ],
    };
    dispatch(
      smartContractUpdate({
        json: JSON.stringify(data),
        markAsComplete: true,
      }),
    );
  },
})(connect()(withRouter(AddRemarkForm)));

const BuildServiceBundle = props => {
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [markAsCompleteId, setMarkAsCompleteId] = useState();
  const [drawerData, setDrawerData] = useState();
  const dispatch = useDispatch();
  const SMART_CONTRACT_ID = getParam(props.history, 'smartContractId') || null;
  useEffect(() => {
    if (!props.accessingInContract) {
      dispatch(
        comboServiceCode({
          Json: JSON.stringify({
            ServiceCode: [
              {
                ServiceCodeTypeId: 2,
              },
            ],
          }),
        }),
      );
      if (props.fields && props.fields.length < 1) {
        props.fields.push();
      }
    }
  }, []);

  const showDrawer = (serviceData, IsCompleted) => {
    setOpen(true);
    setDrawerData({ serviceData, IsCompleted });
  };

  const onClose = () => {
    setOpen(false);
  };

  const {
    fields,
    CenterConsumable,
    ConsumableFormData,
    setTotalCostOfCare,
    comboServiceCodeValue,
    existingRowsBuildServiceBundle,
    serviceTypeIndex,
    goalIndex,
    bundleIndex,
    initialValues,
    existingRows,
    Practitioner,
    // PayerCombo,
    accessingInContract,
  } = props;
  //  let existingRowsWithServiceCodes =
  //    (existingRowsBuildServiceBundle &&
  //      existingRowsBuildServiceBundle.filter(item => item && item.ServiceCodeId)) ||
  //   [];
  // Restricting Duplicate service code .

  let existingRowsWithServiceCodes =
    (existingRows && existingRows.filter(item => item && item.ServiceCodeId)) || [];

  let NewComboServiceCodeValue = comboServiceCodeValue;
  let scDropDownOptions = [];
  existingRowsWithServiceCodes.map((mainItem, index) => {
    NewComboServiceCodeValue = NewComboServiceCodeValue.filter(item => {
      return item.Value != mainItem.ServiceCodeId;
    });
  });

  const handleTotalChange = () => {
    // ConsumableFormData && ConsumableFormData.length > 0 &&
    if (
      ConsumableFormData &&
      ConsumableFormData.some(item => item && item.Consumable != undefined)
    ) {
      let result = ConsumableFormData.map((Item, Index) => {
        let filterV;
        let consumablePriceValue;
        if (Item != undefined && Item.Consumable != undefined) {
          filterV = CenterConsumable.filter(option => {
            return option.Value == Item.Consumable;
          });
          consumablePriceValue = filterV.length > 0 && filterV[0].Price * Item.UnitNeeded;
        }
        return consumablePriceValue;
      });
      let newTotal =
        result.filter(item => item).length > 0 &&
        result
          .filter(item => item)
          .reduce((p, c) => {
            return p + c;
          });
      if (!isNaN(newTotal)) {
        setTotal(newTotal);
        setTotalCostOfCare(newTotal);
      }
    } else {
      setTotal(0);
      setTotalCostOfCare(0);
    }
  };

  const onChange = key => {
    console.log(key);
  };
  useEffect(() => {
    handleTotalChange();
  }, [ConsumableFormData]);

  useEffect(() => {
    if (props.initialValues) {
    }
  }, [props.initialValues]);

  return (
    <div className="row buildServiceBundle">
      <div className="col-12 col-md-12 col-lg-12 my-3">
        <div className="p-2">
          <div className="d-flex justify-content-between p-2 mx-3">
            {/* <div className="bold h5">{`Service Codes`}</div> */}
          </div>
          <Collapse
            // expandIcon={({ isActive }) => (
            //   <RightCircleOutlined
            //     rotate={isActive ? -90 : 90}
            //     style={{
            //       fontSize: '20px',
            //       marginTop: '4px',
            //       color: 'rgba(0,0,0,0.7)',
            //     }}
            //   />
            // )}
            defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
            onChange={onChange}
          >
            {fields.length > 0 ? (
              fields.map((item, index) => {
                const {
                  LaberCost,
                  NetMargin,
                  NetMarginCost,
                  NetAmount,
                  OverHeadCost,
                  StandardCost,
                  TotalCost,
                  TotalServiceCost,
                  UnitOfMeasurementId,
                  UpdatedBy,
                  ConsumableCost,
                  ServiceCodeDescription,
                  BillingRate,
                  OverHeadCostPercentage,
                  UnitOfMeasurement,
                  ServiceCodeLaberCost,
                  SmartContractBundleServiceCodeId,
                  IsServiceCompleted,
                  ServiceStatus,
                  IsRequestPending,
                } = (existingRows && existingRows[index]) || {};
                let temp = [];
                if (existingRowsWithServiceCodes[index]) {
                  temp = comboServiceCodeValue.filter(
                    code => code.Value == existingRowsWithServiceCodes[index].ServiceCodeId,
                  );
                }
                scDropDownOptions = [...NewComboServiceCodeValue, ...temp];

                return (
                  <>
                    <Panel
                      className="service-code-panel-border"
                      key={index}
                      header={
                        <>
                          <div className="row">
                            <div className="col-md-4 panel-header-text">{`Service Code ${index +
                              1}`}</div>
                            <div className="col-md-8 text-right d-flex">
                              {' '}
                              {props.existingRowsBuildServiceBundle ? (
                                props.existingRowsBuildServiceBundle.every(item => {
                                  if (item != undefined) {
                                    if (Object.keys(item).length > 1) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                }) ? (
                                  <>
                                    {!props.disabled && (
                                      <button
                                        id="scroll-to-bottom"
                                        type="button"
                                        class="btn-newCon"
                                        style={{
                                          position: 'unset',
                                          width: 'unset',
                                        }}
                                        onClick={event => {
                                          event.stopPropagation();
                                          fields.push();
                                          let pageBottom = document.querySelector('#page-bottom');
                                          pageBottom.scrollIntoView(true);
                                        }}
                                      >
                                        + Add{' '}
                                        {fields.length == 0
                                          ? `Service Code`
                                          : `Another Service Code`}
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn-disabled"
                                    style={{
                                      position: 'unset',
                                      width: 'unset',
                                    }}
                                    disabled={true}
                                  >
                                    + Add{' '}
                                    {fields.length == 0 ? `Service Code` : `Another Service Code`}
                                  </button>
                                )
                              ) : (
                                <>
                                  {!props.disabled && (
                                    <button
                                      type="button"
                                      class="btn-newCon"
                                      style={{
                                        position: 'unset',
                                        width: 'unset',
                                      }}
                                      onClick={event => {
                                        event.stopPropagation();
                                        fields.push();
                                      }}
                                    >
                                      + Add{' '}
                                      {fields.length == 0 ? `Service Code` : `Another Service Code`}
                                    </button>
                                  )}
                                </>
                              )}
                              {fields.length > 1 && (
                                <div>
                                  {!props.disabled && (
                                    <a
                                      class="col-1 delete-contract"
                                      onClick={() => {
                                        if (fields.length && fields.length > 0) {
                                          fields.remove(index);
                                        }
                                      }}
                                    >
                                      <i class="fas fa-trash" />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    >
                      {accessingInContract && IsRequestPending ? (
                        <div style={{ color: 'red' }} className="mt-4">
                          {' '}
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;Note: Change request is raised by payer for the below service code.{' '}
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="mt-3">
                        {SMART_CONTRACT_ID &&
                        !props.isButtonVisible &&
                        !IsServiceCompleted &&
                        !ServiceStatus &&
                        SmartContractBundleServiceCodeId &&
                        accessingInContract ? (
                          <>
                            {' '}
                            <div className="col-12 text-right">
                              <div
                                className="btn-deny"
                                style={{
                                  display: 'inline-block',
                                  cursor: 'pointer',
                                  margin: '0px',
                                }}
                                onClick={() => {
                                  showDrawer(existingRows[index], '2');
                                }}
                              >
                                Mark as Failed
                              </div>
                              <div
                                className="btn-approved"
                                style={{
                                  display: 'inline-block',
                                }}
                                onClick={() => {
                                  showDrawer(existingRows[index], '1');
                                }}
                              >
                                Mark as Completed
                              </div>
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        <>
                          {SMART_CONTRACT_ID && ServiceStatus && accessingInContract ? (
                            <>
                              <div className="col-12 text-right d-flex align-items-center justify-content-end ">
                                <span
                                  style={{
                                    marginBottom: '29px',
                                  }}
                                >
                                  <Tag
                                    icon={
                                      IsServiceCompleted ? (
                                        <CheckCircleOutlined />
                                      ) : (
                                        <CloseCircleOutlined />
                                      )
                                    }
                                    color={IsServiceCompleted ? 'success' : 'error'}
                                  >
                                    <Tooltip title="Service Status">{ServiceStatus}</Tooltip>
                                  </Tag>
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                      <div
                        class="mx-4"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {' '}
                        <div class="row p-2">
                          <div
                            className={SMART_CONTRACT_ID && accessingInContract ? 'col-8' : 'col-8'}
                          >
                            <div class="form-group">
                              <Field
                                name={`${item}ServiceCodeId`}
                                type="text"
                                errorMessage="State Required"
                                component={ReduxSelect}
                                options={scDropDownOptions}
                                validate={validate.required}
                                placeholder={'Service Code*'}
                                fieldName={'Service Code'}
                                class="form-control"
                                onChange={selectedValue => {
                                  dispatch(
                                    getServiceCode({
                                      json: JSON.stringify({
                                        ServiceCode: [
                                          {
                                            ServiceCodeId: selectedValue,
                                          },
                                        ],
                                      }),
                                      index,
                                      serviceTypeIndex,
                                      goalIndex,
                                      bundleIndex,
                                    }),
                                  );
                                  let initV =
                                    props.initialValues &&
                                    props.initialValues[index] &&
                                    props.initialValues[index].ServiceCodeId;
                                  if (initV != selectedValue) {
                                    props.setFieldChanged('ServiceCodeId');
                                  }
                                }}
                                disabled={props.disabled}
                              />
                              {/* <label class="placeholder-label">Select Consumable</label> */}
                              {/* <span class="small-info">Provide a specific list of goal(s) to be achieved</span> */}
                            </div>
                          </div>

                          {ServiceCodeDescription ? (
                            <div className="col-12">
                              <div>
                                <div
                                  style={{
                                    color: '#959595',
                                    fontSize: '14px',
                                  }}
                                >
                                  Code Description
                                </div>
                                <div
                                  style={{
                                    color: '#525252',
                                    fontSize: '16px',
                                  }}
                                >
                                  {ServiceCodeDescription}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {existingRows &&
                      existingRows.length > 0 &&
                      existingRows[index] &&
                      Object.keys(existingRows[index]).length > 0 ? (
                        <>
                          {accessingInContract && (
                            <div className="col-6 mt-4 mb-4 d-none">
                              <div class="form-group">
                                <Field
                                  name={`${item}PractitionerId`}
                                  type="text"
                                  errorMessage="State Required"
                                  component={ReduxSelect}
                                  options={
                                    Practitioner &&
                                    Practitioner.filter((item, index) => {
                                      const { StaffRoleId } =
                                        (ServiceCodeLaberCost && ServiceCodeLaberCost[0]) || {};
                                      if (item.StaffRoleId == StaffRoleId) {
                                        return item;
                                      }
                                    })
                                  }
                                  // validate={validate.required}
                                  placeholder={'Assign Labor*'}
                                  fieldName={'Assign Labor'}
                                  class="form-control"
                                />
                              </div>
                            </div>
                          )}

                          <div className="col-12 mt-3 mb-3 noOfService">
                            <div
                              class="license-quantity d-flex  justify-content-left"
                              style={{ fontWeight: '600' }}
                            >
                              <label
                                class="placeholder-label flex-grow-1"
                                style={{
                                  fontSize: '16px',
                                  maxWidth: '331px',
                                }}
                              >
                                Number of Services Required
                                <div
                                  style={{
                                    color: '#7F8188',
                                    fontSize: '14px',
                                  }}
                                >
                                  {UnitOfMeasurement}
                                </div>
                              </label>
                              <div class="">
                                <Field
                                  name={`${item}NoOfServices`}
                                  type="text"
                                  component={ReduxPlusMinusInputWithRange}
                                  //   validate={validate.required}
                                  fieldName={'No Of Services Needed'}
                                  className="number-icon"
                                  min={0}
                                  max={100}
                                  style={{
                                    textAlign: 'center',
                                    margin: '0px 5px',
                                    height: '32px',
                                    width: '56px',
                                    fontSize: '16px',
                                  }}
                                  onChange={item => {
                                    props.setIndexOfNetMarginChange(index);
                                    props.setCurrentServiceTypeIndex(props.serviceTypeIndex);
                                    let initV =
                                      existingRows &&
                                      existingRows[index] &&
                                      existingRows[index].NoOfServices;
                                    if (initV != item) {
                                      props.setFieldChanged('NoOfServices');
                                    }
                                  }}
                                  disableOnPaste={true}
                                  disabled={props.disabled}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="shubham" style={{ overflow: 'auto' }}>
                            {' '}
                            <div
                              className="d-flex align-items-center blocks"
                              style={
                                {
                                  //  border: '1px solid #D8D8D8',
                                }
                              }
                            >
                              <div
                                className="item"
                                style={{
                                  position: 'relative',
                                  borderLeft: '1px solid #D8D8D8',
                                }}
                              >
                                <div className="label">Labor Cost/Burden</div>
                                <div className="value">
                                  <span className="value-container">
                                    {currencyFormatter.format(LaberCost || 0)}
                                  </span>
                                </div>
                                <img
                                  src={`images/masterBillService/plus.svg`}
                                  width="11"
                                  height="11"
                                  alt=""
                                  style={{
                                    position: 'absolute',
                                    top: accessingInContract ? '71%' : '71%',
                                    right: '-6px',
                                    zIndex: '1',
                                  }}
                                />
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">Consumable Cost</div>
                                <div className="value">
                                  <span className="value-container">
                                    {currencyFormatter.format(ConsumableCost || 0)}
                                  </span>
                                </div>
                                <img
                                  src={`images/masterBillService/plus.svg`}
                                  width="11"
                                  height="11"
                                  alt=""
                                  style={{
                                    position: 'absolute',
                                    top: accessingInContract ? '71%' : '71%',
                                    right: '-6px',
                                    zIndex: '1',
                                  }}
                                />
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">
                                  Overhead(
                                  {OverHeadCostPercentage}%)
                                </div>
                                <div className="value">
                                  <span className="value-container">
                                    {currencyFormatter.format(OverHeadCost || 0)}
                                  </span>
                                </div>
                                <img
                                  src={`images/masterBillService/equalTo.svg`}
                                  width="11"
                                  height="11"
                                  alt=""
                                  style={{
                                    position: 'absolute',
                                    top: accessingInContract ? '71%' : '71%',
                                    right: '-6px',
                                    zIndex: '1',
                                  }}
                                />
                              </div>
                              <div
                                className="item"
                                style={{
                                  position: 'relative',
                                  borderRight: '1px solid #e8e8e8',
                                }}
                              >
                                <div className="label">Standard Cost</div>
                                <div className="value">
                                  <span className="value-container">
                                    {currencyFormatter.format(StandardCost || 0)}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="item"
                                style={{
                                  position: 'relative',
                                  display: 'none',
                                }}
                              >
                                <div className="label">Net Margin Not being used %</div>
                                <div className="value">
                                  <span
                                    //  className="value-container"
                                    style={{
                                      padding: '9px 30.9px',
                                      margin: '15px',
                                      display: 'inline-block',
                                      textAlign: 'center',
                                      border: '0.5px solid #959595',
                                      fontWeight: '700',
                                    }}
                                  >
                                    Not being used
                                    {/* {NetMargin ? NetMargin : 0}% */}
                                  </span>
                                </div>
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">Net Margin %</div>
                                <div className="value masterBillOfService">
                                  <div
                                    class="license-quantity d-flex  justify-content-left"
                                    style={{
                                      fontWeight: '600',
                                    }}
                                  >
                                    <span
                                    // className="value-container"
                                    // style={{
                                    //   padding: '9px 30.9px',
                                    //   margin: '15px',
                                    //   display: 'inline-block',
                                    //   textAlign: 'center',
                                    //   border: '0.5px solid #959595',
                                    //   fontWeight: '700',
                                    // }}
                                    >
                                      <Field
                                        name={`${item}NetMargin`}
                                        type="text"
                                        min={0}
                                        max={100}
                                        component={ReduxPlusMinusPercentageInput}
                                        disableOnPaste={true}
                                        //   validate={validate.required}
                                        fieldName={'NetMargin'}
                                        className="number-icon"
                                        style={{
                                          textAlign: 'center',
                                          margin: '0px 5px',
                                          height: '32px',
                                          width: '56px',
                                          fontSize: '16px',
                                        }}
                                        onChange={(e, item) => {
                                          let initV =
                                            props.initialValues &&
                                            props.initialValues[index] &&
                                            props.initialValues[index].NetMargin;
                                          props.setIndexOfNetMarginChange(index);
                                          props.setCurrentServiceTypeIndex(props.serviceTypeIndex);
                                          if (initV != item) {
                                            props.setFieldChanged('NetMargin');
                                          }
                                        }}
                                        disabled={props.disabled}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="item" style={{ position: 'relative' }}>
                                <div className="label">Net Amount</div>
                                <div className="value">
                                  <span className="value-container">
                                    {NetMarginCost
                                      ? currencyFormatter.format(NetMarginCost || 0)
                                      : currencyFormatter.format(NetAmount || 0) || 0}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="item"
                                style={{
                                  position: 'relative',
                                  borderRight: '1px solid #D8D8D8',
                                }}
                              >
                                <div className="label">Billing Rate</div>
                                <div className="value">
                                  <span className="value-container">
                                    {TotalServiceCost
                                      ? currencyFormatter.format(TotalServiceCost || 0)
                                      : currencyFormatter.format(BillingRate || 0) || 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* {accessingInContract && (
                            <div className="col-6 mt-4">
                              <div class="form-group">
                                <Field
                                  name={`${item}ClientInsuranceId`}
                                  // type="text"
                                  // errorMessage="State Required"
                                  component={ReduxSelect}
                                  options={PayerCombo}
                                  validate={validate.required}
                                  placeholder={'Assign Payer*'}
                                  fieldName={'Assign Payer'}
                                  class="form-control"
                                />
                              </div>
                            </div>
                          )} */}
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-md-12 mx-4 mt-2">Please select a Service Code.</div>
                          </div>
                        </>
                      )}
                    </Panel>
                  </>
                );
              })
            ) : (
              <div class="row"></div>
            )}
          </Collapse>
          <p id="page-bottom" style={{ height: '100px' }}></p>
          <Drawer
            title="Add Remarks"
            placement="right"
            onClose={onClose}
            open={open}
            width="600"
            destroyOnClose={true}
          >
            <ReduxAddRemarkForm drawerData={drawerData} onClose={onClose} />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { combo_service_code, practitioner_combos, client_insurance_combo_get } = state;
  const { data: comboServiceCodeData } = combo_service_code;
  const { data: practitionerData } = practitioner_combos;
  // const { data: payerDropdown } = client_insurance_combo_get;
  let comboServiceCodeValue = [];
  let Practitioner = [];
  // let PayerCombo = null;

  if (comboServiceCodeData && comboServiceCodeData.ServiceCode) {
    comboServiceCodeValue = comboServiceCodeData.ServiceCode;
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner = practitionerData.PractitionerList || [];
  }
  // if (payerDropdown && payerDropdown.Client) {
  //   PayerCombo = payerDropdown.Client || [];
  // }
  return {
    Practitioner,
    comboServiceCodeValue,
    // payerDropdown,
    // PayerCombo,
  };
};

const ReduxBuildServiceBundle = connect(mapStateToProps)(withRouter(BuildServiceBundle));
export default ReduxBuildServiceBundle;
