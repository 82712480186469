import React, { Component } from 'react';
import Loader, { Loader2 } from '../../../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { Checkbox, Radio, Tooltip, Modal, Drawer, Cascader, Popover } from 'antd';
import CustomModal from '../../../core/modal';
import AntdModal from '../../../Components/CustomModal';
import moment from 'moment';
import { mapTime } from '../../../util/time';
import renderHTML from 'react-render-html';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  CheckboxGroup,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import {
  getSmallGroupList,
  getSmallGroup,
  sendVoucherCode,
  // appSubscribersUserSearch,
  upsertAppointment,
  deleteAppointment,
  practitionerCombos,
  practitionerCombosOffice,
  listCombos,
  recurringCount,
  medicateCombos,
  getAppointment,
  listAppointment,
  getComboFacility,
  listSmartCombos,
  appointmentGetClientUpcomingSchedule,
  listSurveyQuestionCategoryGetAll,
} from '../../../store/actions';
import { ProcessCombos } from '../../../store/comboConfig';
import NotesDrawer from '../patientEnrollmentForm/formSteps/notesDrawer/notesDrawer';
import validate, { MobileFormatter, MobileNormalize } from '../validation/validate';
import SharedNote from '../../../Components/sharedNotes';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
let eventGuid = 0;

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (moment.isMoment(beginningTime)) {
      if (beginningTime.isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    } else {
      if (moment(beginningTime, 'HH:mm a').isBefore(moment(endTime, 'HH:mm a'))) {
      } else {
        return 'should be greater than Start time';
      }
    }
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

const weekDays = [
  { label: 'Mon', value: '2' },
  { label: 'Tue', value: '3' },
  { label: 'Wed', value: '4' },
  { label: 'Thu', value: '5' },
  { label: 'Fri', value: '6' },
  { label: 'Sat', value: '7' },
  { label: 'Sun', value: '1' },
];
class IndividualAppointmentModal extends Component {
  constructor(props) {
    super();
    this.state = {
      isModelOpen: false,
      initEventValues: {
        referralType: 'R',
        clientId: '',
      },
    };
  }
  toggle = (header, messageContentAppointmentSuccess) => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
      msgContentArray: messageContentAppointmentSuccess,
    }));
  };
  componentWillReceiveProps({
    getAppointment,
    isAppointmentBooked,
    messageContentAppointmentSuccess,
  }) {
    if (
      getAppointment &&
      JSON.stringify(getAppointment) != JSON.stringify(this.props.getAppointment)
    ) {
      //  this.props.dispatch(
      //    practitionerCombosOffice({
      //      json: JSON.stringify({
      //        PractitionerOffice: [
      //          {
      //            PractitionerId: getAppointment.PractitionerId,
      //          },
      //        ],
      //      }),
      //    }),
      //  );
      this.setState({
        initEventValues: {
          ...this.state.initEventValues,
          clientId: getAppointment.ClientId,
          displayClientId: getAppointment.DisplayClientId,
          patientLastName: getAppointment.LastName,
          middleName: getAppointment.MiddleName,
          patientAppointmentType: getAppointment.AppointmentTypeId,
          mobileNumber: getAppointment.PrimaryPhone,
          patientFirstName: getAppointment.Title,
          appointmentDate: getAppointment.AppointmentDate,
          startTime: getAppointment.AppointmentTime,
          endTime: getAppointment.AppointmentEndTime,
          duration: parseInt(getAppointment.DurationInMin),
          providerName: getAppointment.PractitionerId,
          appointmentTypeDropdown: getAppointment.AppTypeId,
          officeName: getAppointment.OfficeName,
          serviceCode: getAppointment.ServiceCode && parseInt(getAppointment.ServiceCode),
          status: getAppointment.Status && parseInt(getAppointment.Status),
          reasonForVisit: getAppointment.ReasonForVisit,
          // clientId: getAppointment.DisplayClientId,
          program: getAppointment.ProgramId && parseInt(getAppointment.ProgramId),
          placeOfService: getAppointment.PlaceOfServiceId,
          centerServiceType: getAppointment.ServiceTypeId,
          recurringAppointment: getAppointment.IsRecurring,
          isEditAll: getAppointment.IsRecurring && false,
          showDeleteAll: getAppointment.IsMultipleAppointment,
          appointmentType: getAppointment.GroupAppointmentRecurring
            ? getAppointment.GroupAppointmentRecurring[0].RecurringType
            : 'Weekly',
          dayOfWeek: getAppointment.GroupAppointmentRecurring
            ? getAppointment.GroupAppointmentRecurring[0].WeekDays &&
              getAppointment.GroupAppointmentRecurring[0].WeekDays.split(',')
            : '',
          recurringOccurance:
            getAppointment.GroupAppointmentRecurring &&
            getAppointment.GroupAppointmentRecurring[0].MonthlyRecurring,
          recurringCount: getAppointment.GroupAppointmentRecurring
            ? JSON.stringify(getAppointment.GroupAppointmentRecurring[0].RecurringCount)
            : null,
          GroupAppointmentRecurringId: getAppointment.GroupAppointmentRecurring
            ? getAppointment.GroupAppointmentRecurring[0].GroupAppointmentRecurringId
            : null,
          IsASAM: getAppointment.IsASAM,
          ShowNotes: getAppointment.ShowNotes,
          dateOfBirth: getAppointment.DateOfBirth,
          eligibility: getAppointment.Eligibility && parseInt(getAppointment.Eligibility),
          coPay: getAppointment.Eligibility && parseInt(getAppointment.CoPay),
          PayerName: getAppointment.PayerName,
          // sharedNote: getAppointment.SharedNote,
        },
        ShowNotes: getAppointment.ShowNotes,
      });

      let data2 = {
        json: JSON.stringify({
          Appointment: [
            {
              ClientId: getAppointment.ClientId,
            },
          ],
        }),
      };
      this.props.dispatch(appointmentGetClientUpcomingSchedule(data2));
      var getSurveyData = {
        json: JSON.stringify({
          Survey: [
            {
              ClientId: getAppointment.ClientId,
              SurveyTypeDesc: 'Intake Forms',
            },
          ],
        }),
      };
      this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
    }

    if (isAppointmentBooked && isAppointmentBooked != this.props.isAppointmentBooked) {
      this.props.onCancel();
    }

    // if (isAppointmentBooked && isAppointmentBooked != this.props.isAppointmentBooked) {
    //   debugger;
    //   this.props.dispatch(listAppointment({}));
    //   this.props.dispatch(reset('viewEventForm'));
    //   if (
    //     parseInt(
    //       this.props.clickInfo &&
    //         this.props.clickInfo.event &&
    //         this.props.clickInfo.event._def.publicId,
    //     )
    //   ) {
    //     this.toggle('Appointment updated successfully');
    //   } else {
    //     this.toggle('Appointment created successfully', messageContentAppointmentSuccess);
    //   }
    // }
  }
  componentDidMount() {
    this.props.dispatch(listCombos({ comboTypes: 'AppointmentTypeCenter' }));
    this.props.dispatch(getComboFacility({}));
    this.props.dispatch(
      getAppointment({
        json: JSON.stringify({
          Appointment: [
            {
              AppointmentId: this.props.AppointmentId,
            },
          ],
        }),
      }),
    );
  }
  render() {
    const { onOk, onCancel, AppointmentId, isReadOnlyUser } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header flex-wrap border-bottom-0 ">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              onCancel();
              // this.props.resetClickInfo();
              this.setState({
                isDisable: true,
              });
            }}
          >
            ×
          </button>
        </div>
        <div className="modal-body rcm-panel">
          <ReduxEventViewForm
            initialValues={this.state.initEventValues}
            ShowNotes={this.state.ShowNotes}
            AppointmentId={AppointmentId}
            onCancel={onCancel}
            warningContent={this.props.warningContent}
            isReadOnlyUser={isReadOnlyUser}
            // deleteEventDetails={this.props.deleteEvent}
          />
        </div>
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            msgContentArray={this.state.msgContentArray}
            yesAction={() => {
              this.toggle();
              onCancel();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}

class EventViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      searchStr: '',
      selectedStudent: '',
      isClientView: true,
      searchedStudent: [],
      //   todaysDate: new Date(),
      isDisable: true,
      clientId: props.clientId,
      notesDrawerVisible: false,
      appointmentId: '',
      isGroup: '',
      isModelOpen: '',
      header: '',
      comboAppointmentType: [],
      // isDrawerClosing: false,
    };
  }

  onChangeCascader = (value, selectedOptions) => {
    let extractedData = selectedOptions[0].label;
    this.props.change(`reasonForVisit`, extractedData);
  };

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };
  handelGui = () => {
    this.props.GetStudentGui(this.state.selectedStudent);
  };
  componentWillUnmount() {
    this.setState({
      isDisable: true,
    });
  }
  componentDidMount() {
    //we can comment this api all because in cwrp we are alaredy calling.
    var result = ProcessCombos(
      'RCM_ServiceCode,RCM_Program,RCM_PlaceOfService,AppointmentType,CenterServiceType,AppointmentEligibility,AppointmentCoPay',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }

    this.setState({
      isDisable: true,
    });
    this.props.dispatch(practitionerCombos({}));
    // this.props.dispatch(appSubscribersUserSearch({}));
    this.props.dispatch(recurringCount({}));
    let medicateComboTypes = {
      ComboTypes: 'Status',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));

    // this.input.focus();
  }

  // setIsDrawerClosing = () => {
  //   this.setState({
  //     isDrawerClosing: false,
  //   });
  // };
  // setNotesDrawerVisible = () => {
  //   this.setState({
  //     notesDrawerVisible: false,
  //   });
  // };

  componentWillReceiveProps({
    searchedStudent,
    isSuccess,
    errorMessage,
    AppointmentTypeOption,
    upsertAppointmentNoteSuccess,
    initialValues,
  }) {
    // if (
    //   upsertAppointmentNoteSuccess &&
    //   JSON.stringify(upsertAppointmentNoteSuccess) !=
    //     JSON.stringify(this.props.upsertAppointmentNoteSuccess)
    // ) {
    //   this.setState({
    //     isDrawerClosing: false,
    //     notesDrawerVisible: false,
    //   });
    // }

    if (
      initialValues &&
      JSON.stringify(initialValues) != JSON.stringify(this.props.initialValues)
    ) {
      this.props.dispatch(
        listSmartCombos({
          comboTypes:
            'RCM_ServiceCode,RCM_Program,RCM_PlaceOfService,AppointmentType,CenterServiceType,AppointmentEligibility,AppointmentCoPay',
          json: JSON.stringify({
            Combo: [
              {
                ClientId: initialValues.clientId,
              },
            ],
          }),
        }),
      );
    }

    if (searchedStudent && searchedStudent != this.props.searchedStudent) {
      this.setState({ searchedStudent });
    }

    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState(
        {
          inviteMsg: 'Mobile app invitation sent successfully.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              inviteMsg: '',
            });
          }, 5000);
        },
      );

      this.props.dispatch(reset('inviteForm'));
    }
    if (errorMessage && errorMessage != this.props.errorMessage) {
      this.setState(
        {
          errorMessage: errorMessage,
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorMessage: '',
            });
          }, 5000);
        },
      );
      this.props.dispatch(reset('inviteForm'));
    }
    if (
      AppointmentTypeOption &&
      JSON.stringify(AppointmentTypeOption) != JSON.stringify(this.props.AppointmentTypeOption)
    ) {
      let temp = AppointmentTypeOption.map(item => {
        return {
          label: item.Text,
          value: item.Value,
        };
      });
      this.setState({
        comboAppointmentType: temp,
      });
    }
  }

  getSerachedStudent = () => {
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: this.state.searchStr }));
  };
  studentSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    // this.props.dispatch(appSubscribersUserSearch({ SearchStr: value, saveSearch: true }));
  };
  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };
  createEventId = () => {
    return String(eventGuid++);
  };
  // onhandleSubmit = val => {
  //   let title = 'Please enter a new title for your event';
  //   let calendarApi = this.props.selectInfo.view.calendar;
  //   let dateToBook = moment(val.appointmentDate).format('YYYY-MM-DD');
  //   let timeToBook = moment(val.startTime._d).format('HH:mm:ss');
  //   calendarApi.unselect(); // clear date selection
  //   if (val.patientFirstName) {
  //     calendarApi.addEvent({
  //       id: this.createEventId(),
  //       title: `${val.patientFirstName} ${val.patientLastName}`,
  //       start: `${dateToBook}T${timeToBook}`,
  //       backgroundColor: '#4AB0FE',
  //       // end: this.props.selectInfo.endStr,
  //       // allDay: this.props.selectInfo.allDay,
  //     });
  //   }
  //   var data = {
  //     Appointment: [
  //       {
  //         // AppointmentId: this.createEventId(),
  //         // Episode: 123,
  //         // DoctorName: val.patientFirstName,
  //         ClientId: 20000008,
  //         Title: `${val.patientFirstName}`,
  //         AppointmentDate: moment(val.appointmentDate).format('YYYY-MM-DD'),
  //         AppointmentTime: moment(val.startTime._d).format('HH:mm:ss'),
  //         PractitionerId: val.providerName,
  //         DurationInMin: val.duration,
  //         OfficeName: val.officeName,
  //         ServiceCode: val.serviceCode,
  //         ReasonForVisit: val.reasonForVisit,
  //         Status: val.status,
  //         MobileNumber: val.mobileNumber,
  //         MiddleName: val.middleName,
  //         PatientFirstName: val.patientFirstName,
  //         PatientLastName: val.patientLastName,
  //       },
  //     ],
  //   };
  //   var appointmentData = {
  //     json: JSON.stringify(data),
  //   };

  //   this.props.dispatch(upsertAppointment(appointmentData));
  //   this.props.onCancel();
  // };
  onhandleSubmit = val => {
    let title = 'Please enter a new title for your event';
    // let calendarApi = this.props.selectInfo.view.calendar;
    let dateToBook = moment(val.appointmentDate).format('YYYY-MM-DD');
    // let timeToBook = moment(val.startTime._d).format('HH:mm:ss');
    // calendarApi.unselect(); // clear date selection
    // if (val.patientFirstName) {
    //   calendarApi.addEvent({
    //     id: this.createEventId(),
    //     title: `${val.patientFirstName} ${val.patientLastName}`,
    //     start: `${dateToBook}T${timeToBook}`,
    //     backgroundColor: '#4AB0FE',
    //     // end: this.props.selectInfo.endStr,
    //     // allDay: this.props.selectInfo.allDay,
    //   });
    // }

    let data;
    if (val.recurringAppointment) {
      data = {
        Appointment: [
          {
            // AppointmentId: this.createEventId(),
            // Episode: 123,
            // DoctorName: val.patientFirstName,
            ClientId: this.props.initialValues.clientId,
            AppointmentId: this.props.AppointmentId,
            Title: `${val.patientFirstName}`,
            AppointmentDate: moment(val.appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime: mapTime(val.startTime),
            // val.startTime && typeof val.startTime == 'string'
            //   ? val.startTime
            //   : moment(val.startTime._d).format('HH:mm:ss'),
            AppointmentEndTime: mapTime(val.endTime),
            // val.endTime && typeof val.endTime == 'string'
            //   ? val.endTime
            //   : moment(val.endTime._d).format('HH:mm:ss'),
            // AppointmentEndTime: (val.endTime && moment(val.endTime._d).format('HH:mm:ss')) || '',
            PractitionerId: val.providerName,
            AppTypeId: val.appointmentTypeDropdown,
            DurationInMin: val.duration,
            OfficeName: val.officeName,
            ServiceCode: val.serviceCode,
            ReasonForVisit: val.reasonForVisit,
            Status: val.status,
            PrimaryPhone: val.mobileNumber,
            MiddleName: val.middleName,
            // AppointmentTypeId: val.patientAppointmentType,
            FirstName: val.patientFirstName,
            LastName: val.patientLastName,
            ProgramId: val.program,
            PlaceOfServiceId: val.placeOfService,
            ServiceTypeId: val.centerServiceType,
            IsRecurring: val.recurringAppointment,
            IsEditAll: val.recurringAppointment,
            GroupAppointmentRecurring: [
              {
                GroupAppointmentRecurringId: val.GroupAppointmentRecurringId,
                RecurringType: val.appointmentType,
                WeekDays:
                  val.recurringAppointment &&
                  (typeof val.dayOfWeek == 'string' ? val.dayOfWeek : val.dayOfWeek.join()),
                MonthlyRecurring: val.recurringOccurance || null,
                RecurringCount: val.recurringCount,
              },
            ],
            // DateOfBirth: this.state.searchedClient.DateOfBirth,
            DateOfBirth: val.dateOfBirth,
            Eligibility: val.eligibility,
            CoPay: val.coPay,
            // SharedNote: val.sharedNote,
          },
        ],
      };
    } else {
      data = {
        Appointment: [
          {
            // AppointmentId: this.createEventId(),
            // Episode: 123,
            // DoctorName: val.patientFirstName,
            ClientId: this.props.initialValues.clientId,
            AppointmentId: this.props.AppointmentId,
            Title: `${val.patientFirstName}`,
            AppointmentDate: moment(val.appointmentDate).format('YYYY-MM-DD'),
            AppointmentTime: mapTime(val.startTime),
            // val.startTime && typeof val.startTime == 'string'
            //   ? val.startTime
            //   : moment(val.startTime._d).format('HH:mm:ss'),
            AppointmentEndTime: mapTime(val.endTime),
            // val.endTime && typeof val.endTime == 'string'
            //   ? val.endTime
            //   : moment(val.endTime._d).format('HH:mm:ss'),
            // AppointmentEndTime: (val.endTime && moment(val.endTime._d).format('HH:mm:ss')) || '',
            PractitionerId: val.providerName,
            AppTypeId: val.appointmentTypeDropdown,
            DurationInMin: val.duration,
            OfficeName: val.officeName,
            ServiceCode: val.serviceCode,
            ReasonForVisit: val.reasonForVisit,
            Status: val.status,
            PrimaryPhone: val.mobileNumber,
            MiddleName: val.middleName,
            // AppointmentTypeId: val.patientAppointmentType,
            FirstName: val.patientFirstName,
            LastName: val.patientLastName,
            ProgramId: val.program,
            PlaceOfServiceId: val.placeOfService,
            IsRecurring: val.recurringAppointment,
            IsEditAll: val.recurringAppointment,
            DateOfBirth: val.dateOfBirth,
            Eligibility: val.eligibility,
            CoPay: val.coPay,
            // SharedNote: val.sharedNote,
          },
        ],
      };
    }
    var appointmentData = {
      json: JSON.stringify(data),
    };
    this.setState({
      isDisable: true,
    });
    this.props.dispatch(upsertAppointment(appointmentData));
    // this.props.onCancel();
  };

  toggleView = () => {
    this.setState(prevState => ({
      isClientView: !prevState.isClientView,
      inviteMsg: '',
      errorMessage: '',
    }));
  };

  showDrawer = appointmentId => {
    this.setState({
      notesDrawerVisible: true,
      appointmentId: appointmentId,
    });
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  onCloseDrawer = () => {
    // this.setState({
    //   isDrawerClosing: true,
    // });
    this.setState({
      notesDrawerVisible: false,
    });
  };

  render() {
    const {
      handleSubmit,
      MobileNumberValue,
      EmailValue,
      referralTypeValue,
      dateClicked,
      AppointmentId,
      dispatch,
      practitioner,
      practitionerOffice,
      isFetchingGetAppointment,
      ProgramType,
      recurringAppointmentValue,
      appointmentTypeValue,
      appointmentDateValue,
      recurringCount,
      isMedicalComboFetching,
      ServiceCodeList,
      PlaceOfService,
      CenterServiceType,
      AppointmentTypeOption,
      clientId,
      displayClientId,
      change,
      IsASAM,
      medicateCombosObj,
      currentStatusValue,
      isReadOnlyUser,
      initialValues,
      AppointmentEligibility,
      AppointmentCoPay,
    } = this.props;
    const {
      searchStr,
      searchedStudent,
      isClientView,
      //   todaysDate
      noteAddEditBox,
      columns,
      notesDrawerVisible,
      appointmentId,
      isGroup,
      isModelOpen,
      header,
    } = this.state;
    const { Status, ServiceCode } = medicateCombosObj;

    // const toogleChange = bool => {
    //   if (bool) {
    //     change(`appointmentType`, 'Weekly');
    //     change(`recurringCount`, '2');
    //   }
    // };
    return (
      <div className="">
        <div className="rcm-panel">
          <div style={{ textAlign: 'center' }}>
            <h6 style={{ fontSize: '16px', color: 'rgb(18, 196, 117)' }}>{this.state.inviteMsg}</h6>
            <h6 style={{ fontSize: '16px', color: 'rgb(255, 0, 0)' }}>{this.state.errorMessage}</h6>
          </div>
          <form onSubmit={handleSubmit(this.onhandleSubmit)}>
            <Loader2 loading={isFetchingGetAppointment || isMedicalComboFetching} />

            <div className="form-group mb-2 custom-radio px-3">
              {/* <h6 className="mr-4 d-inline">Referral Type :</h6> */}
              {/* <label className="mr-5">
                New Patient
                <Field
                  name="referralType"
                  component={ReduxInput}
                  type="radio"
                  value="R"
                  style={{
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #bcbcbc',
                    marginRight: '5px',
                  }}
                />
                <span className="checkmark"></span>
              </label>
              <label>
                Existing Patient
                <Field
                  name="referralType"
                  component={ReduxInput}
                  type="radio"
                  value="V"
                  style={{
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #bcbcbc',
                    marginRight: '5px',
                  }}
                />
                <span className="checkmark"></span>
              </label>*/}
            </div>

            <div className="newclientview col-md-12 enrollment-form">
              <div className="row">
                <div className="col-12 mb-2 d-flex justify-content-between">
                  {' '}
                  {/* <h4 className="modal-title text-left">
                      {moment(this.props.clickInfo.event._instance.range.start).format(
                        'Do MMM, YYYY',
                      )}
                    </h4> */}
                  <h4 className="modal-title text-left">Appointment</h4>
                  {/* {this.props.clickInfo.el.className.split(' ')[
                    this.props.clickInfo.el.className.split(' ').length - 1
                  ] == 'fc-event-future' && (
                    <div>
                      <img
                        src="images/shape.svg"
                        style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({ isDisable: false });
                        }}
                      />

                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <img
                            src="images/deleteEvent.svg"
                            style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                            data-toggle="modal"
                            data-target="#deactivate-staff-model"
                            onClick={onClick}
                          />
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div className="modal-content border-0">
                            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 className="modal-title col-12 mb-3">
                                Are you sure you want to deactivate this appointment?
                              </h4>
                            </div>
                            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                className="btn black-btn"
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                No
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  onOk();
                                  let data = {
                                    Appointment: [
                                      {
                                        AppointmentId: parseInt(clickedEventId),
                                      },
                                    ],
                                  };
                                  let clickedEventDetails = {
                                    json: JSON.stringify(data),
                                  };
                                  dispatch(deleteAppointment(clickedEventDetails));
                                  this.props.onCancel();
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  )} */}
                  {/* {this.props.clickInfo.el.className.split(' ')[
                    this.props.clickInfo.el.className.split(' ').length - 1
                  ] == 'fc-event-today' && ( */}
                  {!isReadOnlyUser && (
                    <>
                      <div className="d-flex align-items-center">
                        <Popover
                          content={
                            <>
                              {this.props.isFetchingListConsent && (
                                <div className="row px-2">
                                  <div className="col-md-12">Loading...</div>
                                </div>
                              )}
                              {this.props.ConsentQuestionCategoryList &&
                                this.props.ConsentQuestionCategoryList.map((item, index) => {
                                  const { SurveyName, SignatureStatus } = item || {};
                                  return (
                                    <div className="row px-2">
                                      <div className="col-md-12">
                                        {index + 1}.{SurveyName}({SignatureStatus})
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          }
                          title={
                            <>
                              {
                                <div
                                  className=""
                                  onClick={() => {
                                    this.showDrawer(
                                      this.props.history.push(
                                        `/cw/patient-management/patient-profile?tab=forms&pId=${initialValues.clientId}`,
                                      ),
                                    );
                                  }}
                                  style={{
                                    color: ' rgb(74, 176, 254)',
                                    cursor: 'pointer',
                                    // background: 'transparent',
                                    // border: 'none',
                                    // fontSize: '16px',
                                    // fontWeight: 'bold',
                                    // display: 'flex',
                                    // alignItems: 'center',
                                  }}
                                >
                                  Intake Forms{' '}
                                  <i
                                    class="fas fa-external-link-alt"
                                    style={{ color: '#46b4fe', cursor: 'pointer' }}
                                  ></i>
                                </div>
                              }
                            </>
                          }
                          overlayClassName="alert-box-style"
                          // open={true}
                        >
                          <div
                            style={{
                              // color: ' rgb(74, 176, 254)',
                              cursor: 'pointer',
                              background: 'transparent',
                              border: 'none',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src="images/appNoteIcon.svg"
                              style={{
                                width: '35px',
                                padding: '10px 0px 10px 10px',
                                cursor: 'pointer',
                              }}
                            />
                            Forms
                          </div>
                        </Popover>

                        {(this.props.ShowNotes ||
                          this.props.currentStatusValue == 19707 ||
                          this.props.currentStatusValue == 19709) && (
                          <div
                            onClick={() => {
                              this.showDrawer(this.props.AppointmentId);
                            }}
                            style={{
                              color: ' rgb(74, 176, 254)',
                              cursor: 'pointer',
                              background: 'transparent',
                              border: 'none',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src="images/appNoteIcon.svg"
                              style={{
                                width: '35px',
                                padding: '10px 0px 10px 10px',
                                cursor: 'pointer',
                              }}
                            />
                            Notes
                          </div>
                        )}

                        <Tooltip placement="top" title={'Edit'}>
                          <img
                            src="images/shape.svg"
                            style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({ isDisable: false });
                            }}
                          />
                        </Tooltip>

                        <AntdModal
                          ModalButton={({ onClick }) => (
                            <Tooltip placement="top" title={'Delete'}>
                              <img
                                src="images/deleteEvent.svg"
                                style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                                data-toggle="modal"
                                data-target="#deactivate-staff-model"
                                onClick={onClick}
                              />
                            </Tooltip>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div className="modal-content border-0">
                              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                <h4 className="modal-title col-12 mb-3">
                                  Are you sure you want to deactivate this appointment?
                                </h4>
                              </div>
                              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  className="btn black-btn"
                                  onClick={() => {
                                    onCancel();
                                  }}
                                >
                                  No
                                </button>
                                <button
                                  className="btn"
                                  onClick={() => {
                                    onOk();
                                    let data = {
                                      Appointment: [
                                        {
                                          AppointmentId: AppointmentId,
                                        },
                                      ],
                                    };
                                    let clickedEventDetails = {
                                      json: JSON.stringify(data),
                                    };
                                    dispatch(deleteAppointment(clickedEventDetails));
                                    this.props.onCancel();
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </>
                  )}
                  {/*  )} */}
                </div>
                <div className="col-12 mb-4">
                  {this.props.initialValues && this.props.initialValues.clientId && (
                    <SharedNote ClientId={this.props.initialValues.clientId} />
                  )}
                </div>
                <div className="col-12">
                  {true ? (
                    <div className="row mt-1 mb-1">
                      <div className="col-md-6">
                        <div
                          className="mt-4 mb-4 appointment-slider"
                          style={{ margin: '0 auto', width: '90%' }}
                        >
                          <div className="row">
                            {' '}
                            <div
                              className="col-md-12 bold text-center"
                              style={{
                                color: '#80808078',
                                fontSize: '18px',
                                marginBottom: '18px',
                              }}
                            >
                              Previous Appointments
                            </div>
                          </div>

                          <Slider {...settings}>
                            {this.props.oldAppointmentList &&
                            this.props.oldAppointmentList.length > 0 ? (
                              this.props.oldAppointmentList.map((item, index) => {
                                const {
                                  AppointmentDate,
                                  AppointmentEndTime,
                                  AppointmentTime,
                                  ProviderName,
                                  Text,
                                } = item;
                                return (
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-12 pb-4">{Text}</div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="text-center">
                                <span style={{ color: 'rgb(149, 149, 149)' }}>
                                  <i class="fa fa-info-circle" aria-hidden="true"></i> Note : No
                                  Previous Appointments available.
                                </span>
                              </div>
                            )}
                          </Slider>
                        </div>
                      </div>
                      <div
                        className="col-md-6"
                        style={{ borderLeft: '2px solid rgba(128, 128, 128, 19%)' }}
                      >
                        <div
                          className="mt-4 mb-5 appointment-slider"
                          style={{ margin: '0 auto', width: '90%' }}
                        >
                          <div className="row">
                            {' '}
                            <div
                              className="col-md-12 bold text-center"
                              style={{
                                color: '#80808078',
                                fontSize: '18px',
                                marginBottom: '18px',
                              }}
                            >
                              Upcoming Appointments
                            </div>
                          </div>

                          <Slider {...settings}>
                            {this.props.upcomingAppointmentList &&
                            this.props.upcomingAppointmentList.length > 0 ? (
                              this.props.upcomingAppointmentList.map((item, index) => {
                                const {
                                  AppointmentDate,
                                  AppointmentEndTime,
                                  AppointmentTime,
                                  ProviderName,
                                  Text,
                                } = item;
                                return (
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-12 pb-4">{Text}</div>
                                    </div>
                                    {/* <div className="row">
                                          <div className="col-md-6">
                                            {' '}
                                            <div className="row">
                                              <div className="col-md-6 bold">Provider Name</div>
                                              <div className="col-md-6">{ProviderName}</div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="row">
                                              <div className="col-md-6 bold">Appointment Date</div>
                                              <div className="col-md-6">{AppointmentDate}</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            {' '}
                                            <div className="row">
                                              <div className="col-md-6 bold">Start Time</div>
                                              <div className="col-md-6">{AppointmentTime}</div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="row">
                                              <div className="col-md-6 bold">End Time</div>
                                              <div className="col-md-6">{AppointmentEndTime}</div>
                                            </div>
                                          </div>
                                        </div> */}
                                  </div>
                                );
                              })
                            ) : (
                              <div className="text-center">
                                <span style={{ color: 'rgb(149, 149, 149)' }}>
                                  {' '}
                                  <i class="fa fa-info-circle" aria-hidden="true"></i> Note : No
                                  Upcoming Appointments available.
                                </span>
                              </div>
                            )}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* <div className="col-6">
                    <div className="form-group mb-2">
                      <Field
                        name="title"
                        type="text"
                        component={ReduxInput}
                        fieldName={'Title'}
                        // validate={[Validator.required]}
                      />
                      <label className="placeholder-label">Title</label>
                    </div>
                  </div>
                  <div className="col-6"></div> */}
                {/* <div className="col-6">
                  <div
                    className="form-group mb-2"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={val => {
                      this.props.history.push(
                        `/cw/patient-management/patient-profile?tab=demographic&pId=${this.props.initialValues.clientId}`,
                      );
                    }}
                  >
                    <Field
                      name="displayClientId"
                      type="text"
                      component={ReduxInput}
                      disabled={true}
                      style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }}
                    />
                    <label className="placeholder-label">Patient ID</label>
                  </div>
                </div> */}

                <div className="col-6">
                  <div
                    className="form-group mb-2"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <p className="static-placeholder">Patient ID</p>
                    <p
                      className="mb-0 d-flex align-items-center static-formValue"
                      onClick={val => {
                        this.props.history.push(
                          `/cw/patient-management/patient-profile?tab=demographic&pId=${this.props.initialValues.clientId}`,
                        );
                      }}
                    >
                      {initialValues && initialValues.displayClientId}
                    </p>
                  </div>
                </div>
                {/* <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="patientAppointmentType"
                      type="text"
                      component={ReduxSelect}
                      errorMessage="Appointment Type Required"
                      placeholder={'Appointment Type'}
                      fieldName={'Appointment Type'}
                      disabled={this.state.isDisable}
                      // validate={Validator.required}
                      options={AppointmentTypeOption}
                    />
                  </div>
                </div> */}
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="patientFirstName"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Patient First Name'}
                      disabled={this.state.isDisable}
                      validate={Validator.required}
                    />
                    <label className="placeholder-label">Patient First Name*</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="middleName"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Patient Middle Name'}
                      disabled={this.state.isDisable}
                    />
                    <label className="placeholder-label">Patient Middle Name</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="patientLastName"
                      type="text"
                      component={ReduxInput}
                      fieldName={'Patient Last Name'}
                      disabled={this.state.isDisable}
                      // validate={Validator.required}
                    />
                    <label className="placeholder-label">Patient Last Name</label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="mobileNumber"
                      type="text"
                      component={ReduxInput}
                      validate={validate.NumericMobile}
                      format={MobileFormatter}
                      normalize={MobileNormalize}
                      // validate={[
                      //   Validator.allowNumericMobile,
                      //   Validator.requiredAny,
                      //   () => {
                      //     if (!this.props.MobileNumberValue && !this.props.EmailValue) {
                      //       return 'or Email Required';
                      //     }
                      //   },
                      // ]}
                      fieldName={'Mobile Number'}
                      disabled={true}
                    />
                    <label className="placeholder-label">Mobile Number</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="dateOfBirth"
                      type="text"
                      component={ReduxDateInput}
                      errorMessage="Date of Birth Required"
                      label={'Date of Birth*'}
                      validate={Validator.required}
                      maxDate={new Date()}
                      disabled={this.state.isDisable}
                    />
                    <i className="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <p className="static-placeholder">Payer Name</p>
                    <p
                      className="mb-0 d-flex align-items-center static-formValue"
                      style={{ color: 'grey', cursor: 'none' }}
                    >
                      {initialValues && initialValues.PayerName}
                    </p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="eligibility"
                      type="text"
                      component={ReduxSelect}
                      errorMessage="Eligibility Required"
                      placeholder={'Eligibility'}
                      // label={'Eligibility*'}
                      fieldName={'Eligibility'}
                      // validate={Validator.required}
                      options={AppointmentEligibility}
                      disabled={this.state.isDisable}
                      // fieldName={'Appointment Date'}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="coPay"
                      type="text"
                      component={ReduxSelect}
                      errorMessage="Co-pay of Patient Required"
                      placeholder={'Co-pay'}
                      // label={'Co-pay*'}
                      fieldName={'Co-pay'}
                      // validate={Validator.required}
                      options={AppointmentCoPay}
                      disabled={this.state.isDisable}
                      // fieldName={'Appointment Date'}
                    />
                  </div>
                </div>
                {/* <div className="col-6">
                          <div className="form-group mb-2">
                            <Field
                              name="email"
                              type="text"
                              component={ReduxInput}
                                validate={validate.required}
                                fieldName={'patient Email'}
                            />
                            <label className="placeholder-label">Email*</label>
                          </div>
                        </div> */}

                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="appointmentDate"
                      type="text"
                      component={ReduxDateInput}
                      errorMessage="Published Date Required"
                      label={'Appointment Date*'}
                      // fieldName={'Appointment Date'}
                      // minDate={new Date(2016, 0, 1)}
                      disabled={this.state.isDisable}
                      validate={Validator.required}
                      minDate={new Date()}
                    />
                    <i className="far fa-calendar-alt form-control-icon" />
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="startTime"
                      type="text"
                      component={ReduxTimePicker}
                      fieldName={'Start Time'}
                      validate={Validator.required}
                      label={'Start Time*'}
                      disabled={this.state.isDisable}
                      minuteStep={5}
                    />
                    {/* <label className="placeholder-label">Start Time</label> */}
                    {/* <i className="far fa-clock form-control-icon" /> */}
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="endTime"
                      type="text"
                      component={ReduxTimePicker}
                      fieldName={'End Time'}
                      validate={[Validator.required, Validator.endTime]}
                      label={'End Time*'}
                      disabled={this.state.isDisable}
                      minuteStep={5}
                    />
                    {/* <label className="placeholder-label">Start Time</label> */}
                    {/* <i className="far fa-clock form-control-icon" /> */}
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="program"
                      type="text"
                      component={ReduxSelect}
                      errorMessage="Appointment Date Required"
                      placeholder={'Program*'}
                      // label={'Program*'}
                      validate={Validator.required}
                      disabled={this.state.isDisable}
                      options={ProgramType}
                      // fieldName={'Appointment Date'}
                      // minDate={new Date(2016, 0, 1)}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="providerName"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Provider Name'}
                      placeholder={'Provider Name*'}
                      validate={Validator.required}
                      options={practitioner}
                      disabled={this.state.isDisable}
                      // onChange={val => {
                      //   this.props.dispatch(
                      //     practitionerCombosOffice({
                      //       json: JSON.stringify({
                      //         PractitionerOffice: [
                      //           {
                      //             PractitionerId: val,
                      //           },
                      //         ],
                      //       }),
                      //     }),
                      //   );
                      // }}
                    />
                    {/* <label className="placeholder-label"></label> */}
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <Field
                      name="appointmentTypeDropdown"
                      type="text"
                      component={ReduxSelect}
                      placeholder={'Appointment Type'}
                      // label={'Program*'}
                      fieldName={'Appointment Type'}
                      // validate={Validator.required}
                      options={this.props.AppointmentTypeDropdown}
                      disabled={this.state.isDisable}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="officeName"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Office Location'}
                      placeholder={'Office Location*'}
                      validate={Validator.required}
                      options={practitionerOffice}
                      disabled={this.state.isDisable}
                    />
                    {/* <label className="placeholder-label"></label> */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="serviceCode"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Service Code'}
                      placeholder={'Service Code*'}
                      validate={Validator.required}
                      options={ServiceCodeList}
                      disabled={this.state.isDisable}
                    />
                  </div>
                </div>

                {/* <div className="col-6">
                    <div className="form-group mb-2">
                      <Field
                        name="episode"
                        type="text"
                        component={ReduxInput}
                        // validate={Validator.requiredInput}
                        fieldName={'Episode'}
                      />
                      <label className="placeholder-label">Episode</label>
                    </div>
                  </div> */}

                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="status"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Status'}
                      placeholder={'Status'}
                      // validate={Validator.required}
                      options={Status}
                      disabled={this.state.isDisable}
                    />
                    {/* <label className="placeholder-label">Status</label> */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="placeOfService"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Place of Service'}
                      placeholder={'Place of Service*'}
                      validate={Validator.required}
                      options={PlaceOfService}
                      disabled={this.state.isDisable}
                    />
                  </div>
                </div>

                {/* <div className="col-6"></div> */}
                {/* <div className="col-6">
                  <div className="form-group mb-2">
                    <Field
                      name="centerServiceType"
                      type="text"
                      component={ReduxSelect}
                      fieldName={'Service Type'}
                      placeholder={'Service Type*'}
                      validate={Validator.required}
                      options={CenterServiceType}
                      disabled={true}
                    />
                  </div>
                </div> */}
                <div className="col-12 mt-3">
                  {' '}
                  <div className="d-flex">
                    <div className="form-group mb-2" style={{ flexGrow: '8' }}>
                      <Field
                        name="reasonForVisit"
                        type="textarea"
                        component={ReduxTextarea}
                        fieldName={'Reason For Visit'}
                        disabled={this.state.isDisable}
                      />
                      <label className="placeholder-label" style={{ background: '#fefefe' }}>
                        Reason for Visit
                      </label>
                    </div>
                    {!this.state.isDisable && (
                      <div style={{ flexGrow: '1', marginLeft: '5px' }}>
                        <Cascader
                          options={
                            this.props.AppointmentTypeDropDown
                              ? this.props.AppointmentTypeDropDown
                              : []
                          }
                          onChange={this.onChangeCascader}
                        >
                          <a href="#" style={{ color: 'grey' }}>
                            {/* <Icon type="down-circle" /> */}
                            <i className="fas fa-chevron-circle-down"></i>
                          </a>
                        </Cascader>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="col-6 mt-3">
                  <div className="form-group mb-2">
                    <Field
                      name="sharedNote"
                      type="textarea"
                      component={ReduxTextarea}
                      fieldName={'Shared Note'}
                      disabled={this.state.isDisable}
                    />
                    <label className="placeholder-label" style={{ background: '#fefefe' }}>
                      Shared Note
                    </label>
                  </div>
                </div> */}
                {this.props.warningContent && this.props.warningContent.length > 0 && (
                  <div className="col-12">
                    <div className="">
                      <div>
                        <label
                          className="custom-control-label"
                          for="Recurring-Appointment"
                          style={{ fontSize: '18px' }}
                        >
                          Notifications
                        </label>
                      </div>
                      <div className="col-md-12 mt-3 pt-3 pb-3" style={{ background: '#F8F8F8' }}>
                        {this.props.warningContent &&
                          this.props.warningContent.map((item, index) => {
                            return renderHTML(item.WarningMessage);
                          })}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12 mt-3">
                  <div className="form-group mb-2 d-flex align-items-center">
                    <label
                      className="custom-control-label"
                      for="Recurring-Appointment"
                      style={{ fontSize: '18px' }}
                    >
                      Schedule Multiple Appointments {'   '}
                      <Field
                        name={`recurringAppointment`}
                        type="text"
                        className="custom-control-input not-empty"
                        component={ReduxToggle}
                        // onChange={bool => toogleChange(bool)}
                        disabled={this.state.isDisable}
                      />
                    </label>
                    {recurringAppointmentValue && (
                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title={'Delete All'}>
                            {this.props.initialValues.showDeleteAll && (
                              <img
                                src="images/icons/delete-all.svg"
                                style={{ height: '27', padding: '10px', cursor: 'pointer' }}
                                data-toggle="modal"
                                data-target="#deactivate-staff-model"
                                onClick={onClick}
                              />
                            )}
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div className="modal-content border-0">
                            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 className="modal-title col-12 mb-3">
                                Are you sure you want to deactivate all appointments?
                              </h4>
                            </div>
                            <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                className="btn black-btn"
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                No
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  onOk();
                                  let clickedEventDetails = {
                                    json: JSON.stringify({
                                      Appointment: [
                                        {
                                          AppointmentId: AppointmentId,
                                          IsDeleteAll: true,
                                        },
                                      ],
                                    }),
                                  };
                                  dispatch(deleteAppointment(clickedEventDetails));
                                  this.props.onCancel();
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>

                {recurringAppointmentValue && (
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-9">
                        <div className="form-group mb-2 custom-radio px-3">
                          <label className="mr-4">
                            Weekly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="Weekly"
                              disabled={this.state.isDisable}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: 'solid 1px #bcbcbc',
                                marginRight: '5px',
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="mr-4">
                            Bi-weekly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="BiWeekly"
                              disabled={this.state.isDisable}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: 'solid 1px #bcbcbc',
                                marginRight: '5px',
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label>
                            Monthly
                            <Field
                              name="appointmentType"
                              component={ReduxInput}
                              type="radio"
                              value="MonthlyOnce"
                              disabled={this.state.isDisable}
                              style={{
                                width: '20px',
                                height: '20px',
                                border: 'solid 1px #bcbcbc',
                                marginRight: '5px',
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {appointmentTypeValue === 'Weekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block mt-2">
                            <span className="recurring-block-label">Day(s) of the week</span>
                            <p className="recurring-block-value"></p>
                          </div>
                          <div className="recurring-block">
                            <div className="week-days">
                              <div className="col-12">
                                <div
                                  style={{
                                    flexFlow: 'wrap',
                                    display: 'flex',
                                  }}
                                >
                                  <Field
                                    name="dayOfWeek"
                                    component={CheckboxGroup}
                                    col={6}
                                    widthClassName={'w-75'}
                                    disabled={this.state.isDisable}
                                    blockDays={JSON.stringify(
                                      parseInt(moment(appointmentDateValue).format('d')) + 1,
                                    )}
                                    // justify={'start'}
                                    options={weekDays}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {appointmentTypeValue === 'BiWeekly' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day(s) of the week</span>
                            <p className="recurring-block-value">
                              {moment(appointmentDateValue).format('dddd')}
                            </p>
                          </div>
                          <div className="recurring-block">
                            <div className="bi-week-note">
                              <span className="note-text">
                                <strong>Note</strong>: Bi-weekly appointments are automatically
                                scheduled to reoccur every alternate week.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {appointmentTypeValue === 'MonthlyOnce' && (
                      <div className="p-2">
                        <div className="recurring-appointment">
                          <div className="recurring-block">
                            <span className="recurring-block-label">Day of the month</span>
                            {/* <p className="recurring-block-value" style={{ marginTop: '1em' }}>
                              {moment(appointmentDateValue).format('dddd')}
                            </p> */}
                          </div>
                          <div className="recurring-block">
                            <span className="recurring-block-label"> Occurance in the month</span>
                            {/* <p className="recurring-block-value">{'Second'}</p> */}
                            {/* Week1 Week2 WeekLast */}
                            <div className="form-group mb-2">
                              <Field
                                name="recurringOccurance"
                                type="text"
                                component={ReduxSelect}
                                fieldName={'Occurance in the month'}
                                // placeholder={'Occurance in the month'}
                                disabled={this.state.isDisable}
                                options={[
                                  {
                                    Value: 'Week1',
                                    Text: 'First Week',
                                  },
                                  {
                                    Value: 'Week2',
                                    Text: 'Second Week',
                                  },
                                  {
                                    Value: 'Week3',
                                    Text: 'Third Week',
                                  },
                                  {
                                    Value: 'Week4',
                                    Text: 'Fourth Week',
                                  },
                                  {
                                    Value: 'WeekLast',
                                    Text: 'Last Week',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-4 enrollment-form">
                        <div className="form-group mb-2">
                          <Field
                            name="recurringCount"
                            type="text"
                            component={ReduxSelect}
                            fieldName={'Count of Appointment(s)'}
                            placeholder={'Count of Appointment(s)'}
                            disabled={this.state.isDisable}
                            options={recurringCount}
                          />
                        </div>
                      </div>
                      {/* {!this.state.isDisable && (
                        <div className="col-7 mt-3">
                          <div className="form-group">
                            <label
                              className="custom-control-label"
                              for="Recurring-Appointment"
                              style={{ fontSize: '18px' }}
                            >
                              Edit all Appointments {'   '}
                              <Field
                                name={`isEditAll`}
                                type="text"
                                className="custom-control-input not-empty"
                                component={ReduxToggle}
                                disabled={this.state.isDisable}
                              />
                            </label>
                          </div>
                        </div>
                      )} */}
                      {/* {this.props.initialValues.isEditAll && (
                        <div className="col-12">
                          {' '}
                          <p style={{ color: '#b5b4b4' }}>
                            <i className="fa fa-info-circle" aria-hidden="true"></i> This appointment is
                            part of recurring appointments, please click Yes on Edit Multiple
                            Appointments to edit all future appointments of this series.
                          </p>
                        </div>
                      )} */}
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <div className="modal-footer  border-top-0 pt-2">
                    {/* <input onClick={onCancel} type="button" className="btn mx-2" value="Check-In" /> */}
                    {!this.state.isDisable && (
                      <input
                        type="submit"
                        // disabled={isClientView && this.state.selectedStudent.length <= 0}
                        className="btn black-btn font-weight-bold mr-2"
                        value=" Update Visit"
                      />
                    )}
                  </div>
                  {/* {this.props.initialValues.ShowNotes && (
                    <div
                      onClick={() => {
                        this.showDrawer(this.props.clickedEventId);
                      }}
                      style={{
                        color: ' rgb(74, 176, 254)',
                        cursor: 'pointer',
                        background: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      Notes
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </form>
          {notesDrawerVisible && (
            <NotesDrawer
              // isDrawerClosing={this.state.isDrawerClosing}
              OnCloseDrawer={this.onCloseDrawer}
              NotesDrawerVisible={notesDrawerVisible}
              AppointmentId={AppointmentId}
              IsGroup={isGroup}
              ClientId={this.props.initialValues.clientId}
              toggle={this.toggle}
              // setIsDrawerClosing={this.setIsDrawerClosing}
              // setNotesDrawerVisible={this.setNotesDrawerVisible}
            />
          )}
          {isModelOpen && (
            <CustomModal
              isOpen={isModelOpen}
              header={header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
        {/* <Modal
          width={600}
          zIndex={100}
          visible={this.state.isSendAnotherInvitation}
          footer={null}
          onCancel={() => {
            this.setState({
              isSendAnotherInvitation: false,
            });
          }}
          closable={false}
        >
          <div className="modal-content border-0">
            <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
            <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
              <h4 className="modal-title col-12 mb-3">
                {this.state.inviteMsg}
                <br />
              </h4>
            </div>
          </div>
        </Modal> */}
      </div>
    );
  }
}
const mapStateToPropsEventForm = state => {
  const {
    app_subscribers_user_search,
    send_voucher_code,
    practitioner_combos,
    practitioner_combos_office,
    get_appointment,
    combos,
    recurring_count,
    get_combo_facility,
    medicate_combos,
    // upsert_appointment_note,
    appointment_get_client_upcoming_schedule,
    list_survey_question_category_get_all,
  } = state;
  const { isFetching: isFetchingVoucher } = send_voucher_code;
  const { data, isFetching } = app_subscribers_user_search;
  const { data: practitionerData } = practitioner_combos;
  const { data: practitionerOfficeData } = practitioner_combos_office;
  const {
    data: getAppointmentData,
    isFetching: isFetchingGetAppointment,
    error: errorGetAppointment,
  } = get_appointment;
  const { data: recurringCountData } = recurring_count;
  const { data: ComboFacilityData } = get_combo_facility;
  const { data: medicateCombos, isFetching: isMedicalComboFetching } = medicate_combos;
  const { data: upcomingAppointmentData } = appointment_get_client_upcoming_schedule;
  const {
    data: listConsentFormData,
    isFetching: isFetchingListConsent,
  } = list_survey_question_category_get_all;

  let searchedStudent = [];
  let searchStr;
  let isSuccess = false;
  let errorMessage;
  let Practitioner = [];
  let PractitionerOffice = [];

  let ProgramType = [];
  let CenterServiceType = [];
  let recurringCount = [];
  let medicateCombosObj = {
    Status: [],
    ServiceCode: [],
  };
  let ServiceCodeList = [];
  let PlaceOfService = [];
  let AppointmentTypeOption = [];
  let AppointmentTypeDropDown = [];
  let AppointmentEligibility = [];
  let AppointmentCoPay = [];
  let upcomingAppointmentList = [];
  let oldAppointmentList = [];
  let AppointmentTypeDropdown = [];
  let ConsentQuestionCategoryList;
  // let upsertAppointmentNoteSuccess = false;

  // if (upsert_appointment_note && upsert_appointment_note.data) {
  //   upsertAppointmentNoteSuccess = upsert_appointment_note.data.success;
  // }

  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList;
  }
  if (practitionerOfficeData) {
    // PractitionerOffice = practitionerOfficeData.PractitionerOfficeList || [];
  }
  if (ComboFacilityData) {
    // using this combo for all Facility for specific practitione based use practitionerOfficeData
    PractitionerOffice = ComboFacilityData.Facility || [];
  }
  if (app_subscribers_user_search && app_subscribers_user_search.data) {
    if (app_subscribers_user_search.data && app_subscribers_user_search.data.data) {
      searchedStudent = Object.values(data.data) || [];
      searchStr = data.searchStr;
    } else {
      searchedStudent = Object.values(data) || [];
    }
  }
  if (send_voucher_code.data && send_voucher_code.data.success) {
    isSuccess = send_voucher_code.data.success;
  } else if (send_voucher_code && send_voucher_code.error) {
    errorMessage = send_voucher_code.error.Message;
  }
  if (combos.data && combos.data['RCM_Program']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'] || [];
    ProgramType = combos.data['RCM_Program'] || [];
    PlaceOfService = combos.data['RCM_PlaceOfService'] || [];
    CenterServiceType = combos.data['CenterServiceType'] || [];
    AppointmentEligibility = combos.data['AppointmentEligibility'] || [];
    AppointmentCoPay = combos.data['AppointmentCoPay'] || [];
    AppointmentTypeDropdown = combos.data['AppointmentTypeCenter'] || [];
    // AppointmentTypeOption = combos.data['AppointmentType'] || [];
  }
  if (combos.data && combos.data['AppointmentType']) {
    AppointmentTypeDropDown = combos.data['AppointmentType'].map(item => {
      return {
        label: item.Text,
        value: item.Value,
      };
    });
  }
  if (recurringCountData && recurringCountData.Recurring) {
    recurringCount = recurringCountData.Recurring || [];
  }
  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
      Status: medicateCombos.Status,
    };
  }

  if (upcomingAppointmentData && upcomingAppointmentData.Appointment) {
    upcomingAppointmentList =
      (upcomingAppointmentData.Appointment[0] &&
        upcomingAppointmentData.Appointment[0].UpcomingAppointment) ||
      [];
    oldAppointmentList =
      (upcomingAppointmentData.Appointment[0] &&
        upcomingAppointmentData.Appointment[0].PastAppointment) ||
      [];
  }
  if (listConsentFormData && listConsentFormData.SurveyList) {
    ConsentQuestionCategoryList = listConsentFormData.SurveyList;
  }

  const MobileNumberValue = formValueSelector('inviteForm')(state, 'MobileNumber');
  const EmailValue = formValueSelector('inviteForm')(state, 'Email');
  const referralTypeValue = formValueSelector('inviteForm')(state, 'referralType');
  const recurringAppointmentValue = formValueSelector('viewEventForm')(
    state,
    'recurringAppointment',
  );
  const appointmentTypeValue = formValueSelector('viewEventForm')(state, 'appointmentType');
  const appointmentDateValue = formValueSelector('viewEventForm')(state, 'appointmentDate');
  const currentStatusValue = formValueSelector('viewEventForm')(state, 'status');

  return {
    searchedStudent,
    isFetching: isFetching || isFetchingVoucher,
    // isFetchingVoucher,
    searchStr,
    isSuccess,
    errorMessage,
    MobileNumberValue,
    EmailValue,
    referralTypeValue,
    practitioner: Practitioner,
    practitionerOffice: PractitionerOffice,
    isFetchingGetAppointment,
    ProgramType,
    recurringAppointmentValue,
    appointmentTypeValue,
    appointmentDateValue,
    recurringCount,
    medicateCombosObj: medicateCombosObj,
    isMedicalComboFetching,
    ServiceCodeList,
    PlaceOfService,
    AppointmentTypeOption,
    AppointmentTypeDropDown,
    CenterServiceType,
    currentStatusValue,
    AppointmentEligibility,
    AppointmentCoPay,
    // upsertAppointmentNoteSuccess,
    upcomingAppointmentList,
    oldAppointmentList,
    ConsentQuestionCategoryList,
    isFetchingListConsent,
    AppointmentTypeDropdown,
  };
};
const ReduxEventViewForm = reduxForm({
  form: 'viewEventForm',
  enableReinitialize: true,
})(connect(mapStateToPropsEventForm)(withRouter(EventViewForm)));

const mapStateToProps = ({ combos, get_appointment, upsert_appointment }) => {
  const {
    data: getAppointmentData,
    isFetching: isFetchingGetAppointment,
    error: errorGetAppointment,
  } = get_appointment;

  let isAppointmentBooked = false;
  let getAppointment = {};
  let ClientId;
  let messageContentAppointmentSuccess;
  let warningContent;

  if (getAppointmentData && getAppointmentData.Appointment) {
    getAppointment = getAppointmentData.Appointment[0];
    warningContent = getAppointmentData.Warning;
    ClientId = getAppointmentData.Appointment[0].DisplayClientId;
  }
  if (upsert_appointment.data && upsert_appointment.data.success) {
    isAppointmentBooked = upsert_appointment.data.success;
    messageContentAppointmentSuccess = JSON.parse(upsert_appointment.data.data)[0].Warning;
  }

  return {
    getAppointment: getAppointment || {},
    ClientId,
    isAppointmentBooked,
    warningContent,
    messageContentAppointmentSuccess,
  };
};
export default connect(mapStateToProps)(IndividualAppointmentModal);
