import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Modal, Button } from 'antd';
import renderHTML from 'react-render-html';
import TextareaAutosize from 'react-autosize-textarea';
import {
  GPT3AnswerStatusUpsert,
  resetGPT3AnswerStatusUpsert,
  augmentWithAI,
  resetAugmentWithAI,
  augmentWithAIGet,
  resetAugmentWithAIGet,
  GPT3EnrollSurveys,
  gptAugmentationGet,
  gptAugmentationWithAI,
  gptAugmentationUpdate,
  resetGptAugmentationUpdate,
  resetGptAugmentationGet,
} from '../../../store/actions';
import { AILoader } from '../../../Components/Loader';
import Axios from 'axios';
import DictationComponent from '../../../Components/DictationComponent';
import AntdModal from '../../../Components/CustomModal';
import { CommonLicenceScreens } from '../../../Screens/licenceScreens';
import CloseIcon from '../../../Components/CloseIcon';

var timer;

class AIAugmentedSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      value: '',
      isScrolled: true,
      isScrollAllowed: true,
      isActionModal: false,
    };
  }
  componentDidMount() {
    const { isSurveyBanner, SurveyIds, ClinicalSurveys, isMoodAugment } = this.props;
    let data = {
      StudentId: this.props.ClientId,
      UserGuid: this.props.UserGuid,
      Surveys: [
        {
          SurveyIds: this.props.SurveyIds,
          GPT3QuestionId: null,
        },
      ],
    };
    if (isSurveyBanner) {
      this.props.dispatch(
        GPT3EnrollSurveys({
          Json: JSON.stringify({
            StudentId: this.props.ClientId,
            UserGuid: this.props.UserGuid,
          }),
        }),
      );
    } else {
      if (ClinicalSurveys) {
        this.getSelectedSurveys(ClinicalSurveys);
      }
      if (isMoodAugment) {
        this.handleAugmentGet();
      } else {
        this.props.dispatch(augmentWithAI(data));
      }
    }
    this.props.dispatch(resetAugmentWithAIGet());
  }

  handleAugmentGet = () => {
    this.props.dispatch(
      gptAugmentationGet({
        Json: JSON.stringify({
          Student: {
            StudentId: this.props.ClientId,
            UserGuid: this.props.UserGuid,
            AugmentationType: 'EmotionalTrend',
          },
        }),
      }),
    );
  };
  componentWillReceiveProps({
    isUpsert,
    AugmentedWithAI,
    isAugmentedWithAI,
    LatestSurveyIds,
    isFetchingMoodAugmentWithAIGet,
    isRegenerateSuccess,
    isAugmentAgreed,
  }) {
    if (
      AugmentedWithAI &&
      JSON.stringify(AugmentedWithAI) != JSON.stringify(this.props.AugmentedWithAI)
    ) {
      this.setState({
        '0isEditMode': false,
      });
    }
    if (
      !isFetchingMoodAugmentWithAIGet &&
      isFetchingMoodAugmentWithAIGet != this.props.isFetchingMoodAugmentWithAIGet &&
      !AugmentedWithAI
    ) {
      const { isMoodAugment } = this.props;
      if (isMoodAugment) {
        this.handleRegenerate(null, null);
      }
    }
    if (
      (isRegenerateSuccess && isRegenerateSuccess != this.props.isRegenerateSuccess) ||
      (isAugmentAgreed && isAugmentAgreed != this.props.isAugmentAgreed)
    ) {
      this.handleAugmentGet();
    }
    if (isUpsert && isUpsert != this.props.isUpsert) {
      this.getLatestData();
      this.props.dispatch(resetGPT3AnswerStatusUpsert());
      if (this.state.isSummary && this.props.isActionTrigered) this.props.isActionTrigered();
    }
    if (isAugmentedWithAI && isAugmentedWithAI != this.props.isAugmentedWithAI) {
      this.getLatestData();
      this.props.dispatch(resetAugmentWithAI());
      if (this.state.isSummary && this.props.isActionTrigered) this.props.isActionTrigered();
    }

    if (LatestSurveyIds && LatestSurveyIds != this.props.LatestSurveyIds) {
      this.setState({
        ActiveSurveyIds: LatestSurveyIds,
      });
      this.props.dispatch(
        augmentWithAIGet({
          Json: JSON.stringify({
            Survey: [
              {
                StudentId: this.props.ClientId,
                UserGuid: this.props.UserGuid,
                SurveyIds: LatestSurveyIds,
                UserGuid: this.props.UserGuid,
                GPT3QuestionAnswerIds: null,
                AllResponses: this.props.isSurveyBanner ? true : false,
              },
            ],
          }),
        }),
      );
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetGptAugmentationGet());
  }

  handleRegenerate = (GPTQuestionId, value) => {
    this.props.dispatch(
      gptAugmentationWithAI({
        StudentId: this.props.ClientId,
        UserGuid: this.props.UserGuid,
        AugmentationType: 'EmotionalTrend',
        GPTQuestionId: GPTQuestionId,
        Value: value,
      }),
    );
  };
  handleDictate = (result, index) => {
    if (result) {
      let data;
      if (this.state[index + 'value']) {
        data = `${this.state[index + 'value']} ${result}`;
      } else {
        data = result;
      }
      this.setState({ [index + 'value']: data });
    }
  };
  onChangeNote = (event, index, click) => {
    const { value } = event.target;
    this.setState({
      [index + 'value']: value,
    });
  };
  getLatestData = () => {
    this.props.dispatch(
      augmentWithAIGet({
        Json: JSON.stringify({
          Survey: [
            {
              StudentId: this.props.ClientId,
              SurveyIds: this.props.SurveyIds,
              UserGuid: this.props.UserGuid,
              GPT3QuestionAnswerIds: null,
              AllResponses: this.props.isSurveyBanner ? true : false,
            },
          ],
        }),
      }),
    );
  };
  handleAugmentWithAI = item => {
    const { GPT3QuestionId, SurveyIds, Surveys } = item || {};
    let data = {
      StudentId: this.props.ClientId,
      UserGuid: this.props.UserGuid,
      Surveys: SurveyIds
        ? [
            {
              SurveyIds: SurveyIds,
              GPT3QuestionId: GPT3QuestionId,
            },
          ]
        : Surveys,
    };
    this.props.dispatch(augmentWithAI(data));
  };
  AnswerStatusUpsert = ({ agree, isSummary, augmentedAnswer }) => {
    let data = {
      Json: JSON.stringify({
        GPT3: [
          {
            SurveyId: this.props.SurveyId,
            SurveyIds: this.props.SurveyIds,
            GPT3QuestionAnswerId: augmentedAnswer.GPT3QuestionAnswerId,
            IsSummary: isSummary,
            GPT3QuestionId: augmentedAnswer.GPT3QuestionId,
            IsAgreed: agree,
          },
        ],
        StudentId: this.props.ClientId,
        UserGuid: this.props.UserGuid,
        SurveyIds: this.props.SurveyId,
        SurveyIds: this.props.SurveyIds,
        GPT3QuestionAnswerIds: augmentedAnswer.GPT3QuestionAnswerIds,
      }),
    };
    this.props.dispatch(GPT3AnswerStatusUpsert(data));
    // Axios.post('Survey/GPT3AnswerStatusUpsert', data)
    //   .then(response => {
    //     if (response.data.success) {
    //       this.setState({
    //         GPT3Response: response.data.data,
    //       });
    //       this.getLatestData();
    //       if (isSummary) this.props.isActionTrigered();
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
    this.setState({
      isSummary: isSummary,
    });
  };

  handleAgree = (GPTAugmentationId, answer, uniqueId, editNotes) => {
    if (editNotes) {
      this.props.dispatch(
        gptAugmentationUpdate({
          Json: JSON.stringify({
            GPTAugmentationId: GPTAugmentationId,
            // StudentId: props.StudentId,
            EditedAnswer: this.state[uniqueId + 'value'],
            IsAgreed: true,
          }),
        }),
      );
      this.setState({
        [uniqueId + 'isEditMode']: false,
      });
    } else {
      this.props.dispatch(
        gptAugmentationUpdate({
          Json: JSON.stringify({
            GPTAugmentationId: GPTAugmentationId,
            // StudentId: props.StudentId,
            EditedAnswer: answer,
            IsAgreed: true,
          }),
        }),
      );
    }
  };
  saveSummary = (augmentedAnswer, index) => {
    let data = {
      Json: JSON.stringify({
        StudentId: this.props.ClientId,
        UserGuid: this.props.UserGuid,
        SurveyIds: this.props.SurveyId,
        SurveyIds: this.props.SurveyIds,
        GPT3QuestionAnswerIds: augmentedAnswer.GPT3QuestionAnswerIds,
        GPT3: [
          {
            SurveyIds: this.props.SurveyId,
            SurveyIds: this.props.SurveyIds,
            GPT3QuestionAnswerId: augmentedAnswer.GPT3QuestionAnswerId,
            GPT3QuestionId: augmentedAnswer.GPT3QuestionId,
            IsAgreed: true,
            EditedAnswer: this.state[index + 'value'],
          },
        ],
      }),
    };
    Axios.post('Survey/GPT3AnswerUpsert', data)
      .then(response => {
        if (response.data.success) {
          this.setState({
            GPT3Response: response.data.data,
          });
          this.getLatestData();
          this.props.isActionTrigered();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    this.setState({
      [index + 'isEditMode']: false,
    });
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };
  scrollToLatest = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // const element = document.getElementById(this.props.LatestSurveyIds);
      // const element = document.getElementById('AI-summary-section');
      const element = document.getElementById('AI_BODY');
      element &&
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
          align: false,
        });
      this.state.isScrolled = false;
    }, 500);
  };
  getSelectedSurveys = SurveyIds => {
    let data = {
      StudentId: this.props.ClientId,
      UserGuid: this.props.UserGuid,
      UserGuid: null,
      Surveys: SurveyIds.map(survey => {
        return {
          SurveyIds: survey.SurveyIds,
          GPT3QuestionId: null,
        };
      }),
    };
    this.props.dispatch(augmentWithAI(data));
  };
  componentDidUpdate(nextProps, nextState) {
    if (this.state.isScrollAllowed) {
      this.scrollToBottom();
    }
  }

  render() {
    const { value } = this.state;
    const {
      isFetching,
      IsUseSummary,
      AugmentedWithAI,
      EnrollSurveysList,
      isSurveyBanner,
      isAugmentedSummary,
    } = this.props;

    return (
      <>
        {isAugmentedSummary && (
          <CommonLicenceScreens StatusData={isAugmentedSummary} moduleName={'AugmentedAI'} />
        )}

        {isAugmentedSummary &&
          isAugmentedSummary.LicenseStatusId != 1 &&
          isAugmentedSummary.LicenseStatusId != 3 &&
          isAugmentedSummary.LicenseStatusId != 5 && (
            <>
              {' '}
              <div className="row">
                <div class="col-12 mb-2 py-2">
                  <div class="card border-0 rounded-0">
                    {/* <h6>Survey Summary</h6> */}
                    {/* the minHeight used for below div is for loader to be centered if it causing any problems please remove this */}
                    <div class="card-body pt-1 px-1" style={{ minHeight: '70vh' }} id="AI_BODY">
                      <AILoader loading={isFetching} />
                      {isSurveyBanner && (
                        <div
                          className="ai-summary-multiple"
                          style={{
                            position: 'sticky',
                            top: '0',
                          }}
                        >
                          <div className="survey-banner-section">
                            {EnrollSurveysList ? (
                              <div className="survey-buttons">
                                <AntdModal
                                  modalWidth={650}
                                  style={{ top: 20 }}
                                  ModalButton={({ onClick }) => (
                                    <button
                                      title="Multiple"
                                      className={'survey-ai-btn-outline'}
                                      onClick={onClick}
                                    >
                                      Select Survey(s)
                                      <img src={`images/icons/plus_black.svg`} className="mx-1" />
                                    </button>
                                  )}
                                  ModalContent={props => (
                                    <FilterModal
                                      {...props}
                                      EnrollSurveysList={EnrollSurveysList}
                                      getSelectedSurveys={this.getSelectedSurveys}
                                    />
                                  )}
                                />
                              </div>
                            ) : (
                              <div
                                className="p-5 d-flex align-items-center flex-column"
                                style={{ height: '75vh' }}
                              >
                                <img src="images/icons/survey.svg" />
                                <h6 className="mt-2">Oops!</h6>
                                <span>No surveys assigned or completed</span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {this.props.isMoodAugment && (
                        <div className="d-flex justify-content-end">
                          <Button
                            type="button"
                            className="btn btn-outline-primary mb-3 augment-regenerate-button d-flex justify-contet-center align-items-center"
                            style={{
                              cursor: 'pointer',
                              height: '45px',
                              width: 'fit-content',
                              display: 'flex',
                            }}
                            onClick={() => this.handleRegenerate(null, null)}
                            // disabled={!AllowRegenration}
                            loading={this.props.isRegenerateFetching}
                          >
                            <img src="images/regenerate.svg" className="mr-2" />
                            Regenerate Response
                          </Button>
                        </div>
                      )}

                      {AugmentedWithAI && AugmentedWithAI.length ? (
                        AugmentedWithAI.map((survey, item) => {
                          const { Answers, Questions } = survey;
                          let AnswersData = Answers && JSON.parse(Answers);
                          let QuestionsData = Questions && JSON.parse(Questions);

                          return (
                            <>
                              <div id="AI-summary-section">
                                {AnswersData &&
                                  AnswersData.map((augmentedAnswer, index) => {
                                    let {
                                      Answer,
                                      DateInserted,
                                      GPT3QuestionAnswerId,
                                      GPT3QuestionId,
                                      GPT3ShortQuestion,
                                      IsAgreed,
                                      IsSummary,
                                      Question,
                                      ShortQuestion,
                                      StaffProfileImageUrl,
                                      SummaryUpdatedInfo,
                                      Tags,
                                      UpdatedBy,
                                      UpdatedInfo,
                                      SurveyId,
                                      GPTAugmentationId,
                                      GPTQuestionId,
                                    } = augmentedAnswer;
                                    let TagsData = Tags && JSON.parse(Tags)[0];
                                    let uniqueId =
                                      String(item) +
                                      String(GPT3QuestionAnswerId || GPTAugmentationId) +
                                      String(GPT3QuestionId || GPTQuestionId);
                                    if (this.state.isScrolled)
                                      if (AnswersData.length == index + 1) this.scrollToLatest();
                                    return (
                                      <div>
                                        <div className="gpt-question">
                                          <div className="ai-question">
                                            <span className="updated-by">{UpdatedBy}</span>
                                            <div className="question my-2">
                                              <span className="text">
                                                {GPT3ShortQuestion || ShortQuestion || Question}
                                              </span>
                                            </div>
                                            <span className="date">{DateInserted}</span>
                                          </div>
                                          <div className="updated-by-profile">
                                            <img
                                              src={
                                                StaffProfileImageUrl || '/images/blank-profile.png'
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="gpt-answers" id={SurveyId}>
                                          <div className="ai-icon">
                                            <img src="images/gpt-ai.svg" />
                                          </div>
                                          <div
                                            className="ai-block"
                                            style={{
                                              maxWidth: '677px',
                                            }}
                                          >
                                            <span className="ai-question">
                                              {ShortQuestion || Question}
                                            </span>
                                            <div className="tags-section">
                                              {TagsData &&
                                                TagsData.MultipleSurveyDesc &&
                                                TagsData.MultipleSurveyDesc.map(
                                                  (tagItem, index) => {
                                                    return (
                                                      <span className="tag">
                                                        {tagItem.SurveyDesc}
                                                      </span>
                                                    );
                                                  },
                                                )}
                                            </div>
                                            {/* {augmentedAnswer.MultipleSurveyCount && (
                                    <Tooltip
                                      placement="top"
                                      title={augmentedAnswer.MultipleSurveyDesc}
                                    >
                                      <span
                                        className="badge"
                                        style={{ backgroundColor: '#D9D9D9' }}
                                      >
                                        {augmentedAnswer.MultipleSurveyCount}
                                      </span>
                                    </Tooltip>
                                  )} */}
                                            {IsAgreed != null && (
                                              <div className="d-flex align-items-center">
                                                {IsAgreed === true && (
                                                  <span className="ai-agreed">
                                                    <i
                                                      class="fas fa-thumbs-up"
                                                      style={{ paddingRight: 5 }}
                                                    ></i>
                                                    Agreed
                                                  </span>
                                                )}
                                                <span className="ai-updated-info">
                                                  {IsAgreed && UpdatedInfo}
                                                </span>
                                              </div>
                                            )}
                                            {this.state[uniqueId + 'isEditMode'] ? (
                                              <div>
                                                <span className="editing">Editing Now</span>
                                                <div class="print-textarea-component">
                                                  <TextareaAutosize
                                                    style={{ height: '250px', width: '100%' }}
                                                    value={this.state[uniqueId + 'value']}
                                                    id="question"
                                                    name="question"
                                                    rows="4"
                                                    placeholder="Enter Answer"
                                                    className="textarea-type"
                                                    onChange={event => {
                                                      this.onChangeNote(event, uniqueId);
                                                    }}
                                                  />
                                                  <div
                                                    className="dictation-space d-flex justify-content-end ai-action"
                                                    style={{ paddingRight: '10px' }}
                                                  >
                                                    <DictationComponent
                                                      handleDictate={event => {
                                                        this.handleDictate(event, uniqueId);
                                                      }}
                                                      // getDicatationStatus={this.getDicatationStatus}
                                                    />
                                                    <button
                                                      style={{
                                                        marginLeft: '10px',
                                                        marginRight: '8px',
                                                        cursor: 'pointer',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        background: 'transparent',
                                                        border: '2px solid #ff7875',
                                                        color: 'rgb(191, 191, 191)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        borderRadius: '4px',
                                                      }}
                                                      onClick={() => {
                                                        this.setState({ [uniqueId + 'value']: '' });
                                                        // this.getDicatationStatus();
                                                      }}
                                                    >
                                                      <img src="images/clear_icon.svg" />
                                                      <p
                                                        style={{
                                                          marginBottom: '0',
                                                          marginLeft: '7px',
                                                          color: '#ff7875',
                                                        }}
                                                      >
                                                        Clear
                                                      </p>
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-primary"
                                                      onClick={() => {
                                                        // this.props.toggleDrawer();
                                                        this.setState({
                                                          [uniqueId + 'isEditMode']: false,
                                                          [uniqueId +
                                                          'value']: augmentedAnswer.Answer,
                                                        });
                                                      }}
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn"
                                                      disabled={
                                                        this.state[uniqueId + 'value'].trim()
                                                          .length < 1
                                                      }
                                                      onClick={() => {
                                                        if (this.props.isMoodAugment) {
                                                          this.handleAgree(
                                                            GPTAugmentationId,
                                                            augmentedAnswer.Answer,
                                                            uniqueId,
                                                            true,
                                                          );
                                                          this.setState({
                                                            isScrollAllowed: true,
                                                          });
                                                        } else {
                                                          this.saveSummary(
                                                            augmentedAnswer,
                                                            uniqueId,
                                                          );
                                                          this.setState({
                                                            isScrollAllowed: true,
                                                          });
                                                        }
                                                      }}
                                                    >
                                                      Save & Agree
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <p className="ai-answer">
                                                {renderHTML(augmentedAnswer.Answer)}
                                              </p>
                                            )}
                                            <div className="ai-action">
                                              {augmentedAnswer.IsAgreed === null &&
                                                !this.state[uniqueId + 'isEditMode'] && (
                                                  <>
                                                    <button
                                                      type="button"
                                                      class="btn d-flex justify-content-center align-items-center"
                                                      style={{
                                                        border: '1px solid #00DC31',
                                                        color: '#00DC31',
                                                        background: '#fff',
                                                      }}
                                                      onClick={() => {
                                                        if (this.props.isMoodAugment) {
                                                          this.handleAgree(
                                                            GPTAugmentationId,
                                                            augmentedAnswer.Answer,
                                                            uniqueId,
                                                            false,
                                                          );
                                                        } else {
                                                          this.AnswerStatusUpsert({
                                                            agree: true,
                                                            isSummary: true,
                                                            augmentedAnswer: augmentedAnswer,
                                                          });
                                                        }
                                                      }}
                                                    >
                                                      <img
                                                        src="images/outline_like.svg"
                                                        style={{
                                                          marginBottom: '3px',
                                                          marginRight: '2px',
                                                        }}
                                                      />
                                                      Agree
                                                    </button>
                                                    <button
                                                      type="button"
                                                      class="btn"
                                                      style={{
                                                        border: '1px solid #FF2C2C',
                                                        color: '#FF2C2C',
                                                        background: '#fff',
                                                      }}
                                                      onClick={() => {
                                                        // this.AnswerStatusUpsert({
                                                        //   agree: false,
                                                        //   augmentedAnswer: augmentedAnswer,
                                                        // });
                                                        this.setState({
                                                          isScrollAllowed: false,
                                                          [uniqueId + 'isEditMode']: true,
                                                          [uniqueId + 'value']:
                                                            augmentedAnswer.OriginalAnswer ||
                                                            augmentedAnswer.Answer, //to avoid rendering html tg using OriginalAnswer
                                                          // [index + 'value']: augmentedAnswer.Answer, // contain HTML tags in response
                                                        });
                                                      }}
                                                    >
                                                      <i
                                                        class="fas fa-thumbs-down"
                                                        style={{ paddingRight: 5 }}
                                                      ></i>
                                                      Disagree
                                                    </button>
                                                  </>
                                                )}
                                              {augmentedAnswer.IsAgreed != null &&
                                                !this.state[uniqueId + 'isEditMode'] && (
                                                  <>
                                                    {IsUseSummary && augmentedAnswer.IsAgreed && (
                                                      <>
                                                        {augmentedAnswer.SummaryUpdatedInfo ? (
                                                          <span>
                                                            {augmentedAnswer.SummaryUpdatedInfo}
                                                          </span>
                                                        ) : (
                                                          <button
                                                            type="button"
                                                            className="btn"
                                                            style={{ width: '165px' }}
                                                            onClick={() => {
                                                              this.AnswerStatusUpsert({
                                                                agree: true,
                                                                isSummary: true,
                                                                augmentedAnswer: augmentedAnswer,
                                                              });
                                                            }}
                                                          >
                                                            Post to Chart
                                                          </button>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                <div className="ai-question-button">
                                  {QuestionsData &&
                                    QuestionsData.map(item => {
                                      if (item)
                                        return (
                                          <button
                                            type="button"
                                            className="ai-btn ai-btn-outline"
                                            onClick={() => {
                                              if (this.props.isMoodAugment) {
                                                if (item.IsActionPending) {
                                                  this.setState({
                                                    isActionModal: true,
                                                  });
                                                  this.setState({
                                                    isScrollAllowed: false,
                                                  });
                                                } else {
                                                  this.handleRegenerate(
                                                    item.GPT3QuestionId || item.GPTQuestionId,
                                                    item.Value,
                                                  );
                                                }
                                              } else {
                                                if (item.IsActionPending) {
                                                  this.setState({
                                                    isActionModal: true,
                                                  });
                                                  this.setState({
                                                    isScrollAllowed: false,
                                                  });
                                                } else {
                                                  return this.handleAugmentWithAI(item);
                                                }
                                              }
                                            }}
                                          >
                                            <figure
                                              class="action-button-icon mr-1"
                                              style={{ display: 'inline-block' }}
                                            >
                                              <img src="images/AIStarBG.svg" alt="" width="23" />
                                            </figure>
                                            <span
                                              style={{ textTransform: 'capitalize' }}
                                              onClick={() => {
                                                if (!item.IsActionPending) {
                                                  this.setState({
                                                    isScrollAllowed: false,
                                                  });
                                                } else {
                                                  this.setState({
                                                    isScrollAllowed: true,
                                                  });
                                                }
                                              }}
                                            >
                                              {item.ShortQuestion || item.Question}
                                            </span>
                                          </button>
                                        );
                                    })}
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <h6 className="text-center">No Surveys to augment.</h6>
                      )}
                      <div
                        style={{ float: 'left', clear: 'both' }}
                        ref={el => {
                          this.messagesEnd = el;
                        }}
                      ></div>
                      {this.state.isActionModal && (
                        <Modal
                          open={this.state.isActionModal}
                          className="merge-clients-modal"
                          onCancel={() => {
                            this.setState({ isActionModal: false });
                          }}
                          footer={[
                            <input
                              type="button"
                              style={{ minWidth: '100px' }}
                              className="btn mb-4"
                              value="OK"
                              onClick={() => {
                                this.setState({ isActionModal: false });
                              }}
                            />,
                          ]}
                          closeIcon={<CloseIcon />}
                          maskClosable={false}
                          width={532}
                        >
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ padding: '76px', paddingBottom: '0', paddingTop: '60px' }}
                          >
                            <h3 className="mb-2">Action Required!</h3>
                            <p className="mb-3">
                              To proceed, please click the 'Agree' or 'Disagree' button.
                            </p>

                            <p className="text-center">
                              Help us train augmentation data by Agreeing or Disagreeing to make
                              future responses think more like you.
                            </p>
                          </div>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    gpt3_answer_status_upsert,
    augment_with_ai,
    augment_with_ai_get,
    gpt3_enroll_surveys,
    user_feature_flag_get,
    gpt_augmentation_get,
    gpt_augmentation_with_ai,
    gpt_augmentation_update,
  } = state;
  const { data: upserData, isFetching: isFetchingUpsert } = gpt3_answer_status_upsert;
  const { data: AugmentWithAIData, isFetching: isFetchingAugmentWithAI } = augment_with_ai;
  const { data: fFData, isFetching, error } = user_feature_flag_get;

  const {
    data: AugmentWithAIGetData,
    isFetching: isFetchingAugmentWithAIGet,
  } = augment_with_ai_get;
  const { data: EnrollSurveysData, isFetching: isFetchingGPTSurveys } = gpt3_enroll_surveys;
  const {
    data: moodAugmentation,
    isFetching: isFetchingMoodAugmentWithAIGet,
  } = gpt_augmentation_get;
  const {
    data,
    isFetching: isRegenerateFetching,
    error: regenerateError,
  } = gpt_augmentation_with_ai;
  const { data: GPTAugmentation, isFetching: isUpdateFetching } = gpt_augmentation_update;

  let isAugmentedSummary = false;
  let isUpsert = false;
  let isAugmentedWithAI = false;
  let AugmentedWithAI;
  let EnrollSurveysList = null;
  let LatestSurveyIds = null;
  let isRegenerateSuccess = false;
  let isAugmentAgreed = GPTAugmentation ? GPTAugmentation.success : false;
  if (data && data.success) {
    isRegenerateSuccess = data.success;
  }
  if (upserData && upserData.success) {
    isUpsert = true;
  }
  if (AugmentWithAIData && AugmentWithAIData.success) {
    isAugmentedWithAI = true;
  }
  if (AugmentWithAIGetData && AugmentWithAIGetData.data) {
    AugmentedWithAI = AugmentWithAIGetData.data;
  }
  if (moodAugmentation && moodAugmentation.data) {
    AugmentedWithAI = moodAugmentation.data;
  }
  if (EnrollSurveysData && EnrollSurveysData.StudentId) {
    EnrollSurveysList = EnrollSurveysData.Surveys;
    LatestSurveyIds = EnrollSurveysData.SurveyIds;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    isAugmentedSummary =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }

  return {
    isFetching:
      isFetchingAugmentWithAIGet ||
      isFetchingUpsert ||
      isFetchingAugmentWithAI ||
      isFetchingGPTSurveys ||
      isFetchingMoodAugmentWithAIGet ||
      isRegenerateFetching,
    isUpsert,
    isAugmentedWithAI,
    AugmentedWithAI,
    EnrollSurveysList,
    LatestSurveyIds,
    isAugmentedSummary,
    isFetchingMoodAugmentWithAIGet,
    isRegenerateSuccess,
    isRegenerateFetching,
    isAugmentAgreed,
    isUpdateFetching,
  };
};
class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedSurveys: [],
      checkedList: {},
    };
  }
  handelCheckbox = (event, item) => {
    let data = this.state.SelectedSurveys;
    let checked = event.target.checked;
    if (checked) {
      data.push(item);
    } else {
      const filteredPeople = data.filter(survey => survey.SurveyIds !== item.SurveyIds);
      data = filteredPeople;
    }
    this.setState({
      SelectedSurveys: data,
    });
  };
  render() {
    const { onOk, onCancel, EnrollSurveysList, getSelectedSurveys } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header d-flex align-items-center flex-row-reverse border-bottom-1">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h5 className="modal-title col-12 text-left">What would you like to Augment today?</h5>
        </div>
        <div
          className="modal-body pt-2"
          style={{
            height: '500px',
            overflowY: 'scroll',
          }}
        >
          <span
            style={{
              color: '#7F8188',
              fontWeight: '400',
              fontSize: '16px',
            }}
          >
            You can select upto 5 surveys :
          </span>
          <div className="row">
            {EnrollSurveysList &&
              EnrollSurveysList.map(survey => (
                <div className="col-12">
                  <div className="survey-list-item">
                    <Checkbox
                      onChange={event => {
                        this.handelCheckbox(event, survey);
                      }}
                      // disabled={true}
                      className="dis-Ref mr-2 d-flex align-items-end"
                    >
                      {survey.Name}
                    </Checkbox>
                    <div
                      className="text-right"
                      style={{
                        maxWidth: '250px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: '500',
                          fontSize: '16px',
                        }}
                      >
                        Completed On:{' '}
                      </span>
                      <span
                        style={{
                          color: 'rgb(127, 129, 136)',
                          fontWeight: '400',
                          fontSize: '16px',
                        }}
                      >
                        {survey.CompletedDate}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="modal-footer border-top-0 pb-5 pt-2">
          <button
            title="Augment with AI"
            className="ai-btn"
            onClick={() => {
              getSelectedSurveys(this.state.SelectedSurveys);
              onOk();
            }}
            style={{
              display: 'inline',
              padding: '5px 20px',
            }}
            disabled={
              !this.state.SelectedSurveys.length > 0 || this.state.SelectedSurveys.length > 5
            }
          >
            <figure class="action-button-icon" style={{ display: 'inline-block' }}>
              <img src="images/AIStar.svg" alt="" width="23" />
            </figure>
            Augment with AI
          </button>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(AIAugmentedSummary);
