import React, { Component } from 'react';
import Webcam from 'react-webcam';
import Axios from 'axios';
import { Modal } from 'antd';

class WebcamCapture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startCamera: true,
      level: 2,
      imageSrc: null,
    };
  }
  setRef = webcam => {
    this.webcam = webcam;
  };

  componentDidMount() {
    /*navigator.getUserMedia =
      navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    if (navigator.getUserMedia) {
      const me = this;
      navigator.getUserMedia(
        { video: true },
        function() {
          // webcam is available
        },
        function() {
          me.onUserMediaError();
          // webcam is not available
        },
      );
    }*/
  }

  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('surveyId');
  }

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('clientId');
  }
  getIntakeType() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('intake');
  }
  getIgnoreUpdatingPin() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('ignoreUpdatingPin') == 'true' ? true : false;
  }

  process = () => {
    if (this.state.level == 1) {
      this.setState({ startCamera: true, level: 2 });
    } else if (this.state.level == 2) {
      const imageSrc = this.webcam.getScreenshot();
      const me = this;
      Axios.post('student/UploadImage', {
        image: imageSrc,
        studentId: this.getStudentId() || this.getClientId(),
        IsProfilePic: true,
      })
        .then(response => {
          me.setState({ startCamera: false, level: 3, imageSrc: imageSrc });
        })
        .catch(error => {
          console.log('Image Failed');
        });
    } else if (this.state.level == 3) {
      if (this.getClientId())
        var searchStr =
          'surveyId=' +
          this.getSurveyId() +
          '&clientId=' +
          this.getClientId() +
          '&intake=' +
          this.getIntakeType();
      else
        var searchStr =
          'surveyId=' +
          this.getSurveyId() +
          '&studentId=' +
          this.getStudentId() +
          '&intake=' +
          this.getIntakeType();

      if (this.getIgnoreUpdatingPin()) {
        this.props.history.push({
          pathname: 'validate',
          search: searchStr,
        });
      } else {
        if (this.getClientId())
          this.props.history.push({
            pathname: 'clientPin',
            search: searchStr,
          });
        else
          this.props.history.push({
            pathname: 'pin',
            search: searchStr,
          });
      }
    }
  };

  onUserMediaError = err => {
    if (this.getClientId())
      var searchStr =
        'surveyId=' +
        this.getSurveyId() +
        '&clientId=' +
        this.getClientId() +
        '&intake=' +
        this.getIntakeType();
    else
      var searchStr =
        'surveyId=' +
        this.getSurveyId() +
        '&studentId=' +
        this.getStudentId() +
        '&intake=' +
        this.getIntakeType();

    const errorMsg = err.name == 'NotFoundError' ? 'not available' : 'not enabled';
    let me = this;
    Modal.error({
      title: 'Auto Redirecting Page to Validate Pin as Camera is ' + errorMsg,
      onOk() {
        if (me.getIgnoreUpdatingPin()) {
          me.props.history.push({
            pathname: 'validate',
            search: searchStr,
          });
        } else {
          if (me.getClientId())
            me.props.history.push({
              pathname: 'clientPin',
              search: searchStr,
            });
          else
            me.props.history.push({
              pathname: 'pin',
              search: searchStr,
            });
        }
      },
    });
  };

  render() {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user',
    };

    var text = '';
    if (this.state.level == 1) {
      text = 'Start Camera';
    } else if (this.state.level == 2) {
      text = 'Capture';
      if (this.props.location && this.props.location.param && this.props.location.param.message) {
        alert(this.props.location.param.message);
      }
    } else if (this.state.level == 3) {
      text = 'Next';
    }

    return (
      <div class="main complete-selfie-page  mb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-center">
              <div class="complete-selfie-box">
                <h3>Let’s take a selfie.</h3>

                {this.state.startCamera && (
                  <Webcam
                    audio={false}
                    onUserMediaError={this.onUserMediaError}
                    ref={this.setRef}
                    screenshotFormat="image/jpeg"
                    className="selfie-img"
                    videoConstraints={videoConstraints}
                  />
                )}
                {this.state.level == 3 && (
                  <figure class="selfie-img">
                    <img src={this.state.imageSrc} class="img-background" />
                  </figure>
                )}
                <input type="button" value={text} onClick={this.process} class="btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WebcamCapture;
