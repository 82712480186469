import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Checkbox, Divider, DatePicker as AntdDatePicker } from 'antd';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { clientChartReport, resetClientChartReport } from '../store/actions';
import { stringDate } from '../util/date';
import { Loader2 } from './Loader';

const CheckboxGroup = Checkbox.Group;

// const DragHandle = sortableHandle(() => (
//   <MenuOutlined style={{ cursor: 'grab', color: '#999', marginRight: '10px' }} />
// ));
// const CheckboxGroup = Checkbox.Group;
// const { Search } = Input;

const { RangePicker } = AntdDatePicker;
const dateFormat = 'YYYY-MM-DD';

const SortableItem = SortableElement(({ value, name, required, onCheckChanged }) => (
  <li
    style={{
      padding: '10px',
      margin: '10px',
      background: '#F7F8FA',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      cursor: 'grab',
      border: '1px solid #d9d9d9',
      position: 'relative',
      zIndex: '985',
    }}
  >
    <img src="images/dragDrop.svg" className="mr-2" style={{ position: 'relative', top: '2px' }} />
    <Checkbox
      className="d-flex align-items-end mb-0"
      value={value}
      //   disabled={required}
      //   style={{ zIndex: 99999 }}
    >
      {name}
    </Checkbox>
  </li>
));

const SortableList = SortableContainer(({ items, onCheckChanged }) => {
  return (
    <ul style={{ display: 'flex', flexDirection: 'column', padding: '0' }}>
      {items.map((item, index) => {
        return (
          <SortableItem
            key={item.value}
            index={index}
            value={item.value}
            name={item.label}
            // required={item.required}
            disabled={!!item.fixed}
            onCheckChanged={onCheckChanged}
          />
        );
      })}
    </ul>
  );
});

class MultipleCategoryCheckboxAll extends React.Component {
  static defaultProps = {
    filter: true,
    filterList: [],
    onFilterSave: () => {},
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      array: [],
      filterList: [],
      showList: [],
      checkAll: false,
      indeterminate: false,
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
      isFetching: false,
    };
  }

  componentDidMount() {
    this.setState({
      filterList: this.props.filterList.children,
      showList: this.props.filterList.children,
      checkAll:
        this.props.filterList.children.filter(item => item.selected).length ===
        this.props.filterList.children.length,
      indeterminate: this.isIndeterminate(this.props.filterList.children),
    });
  }

  async componentWillReceiveProps({ isSuccess, reportURL, isFetching, statusCode, fileName }) {
    if (isSuccess) {
      // window.open(reportURL, '_self');
      this.setState({ isFetching: false });
      const res = await fetch(reportURL);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      this.props.dispatch(resetClientChartReport());
      this.props.onCancel();
    }
    if (statusCode) this.setState({ isFetching: false });
  }
  componentWillUnmount() {
    this.setState({ isFetching: false });
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  isIndeterminate(list) {
    return (
      list.filter(item => item.selected).length &&
      list.filter(item => item.selected).length < list.length
    );
  }

  onCheckChange(checkedList) {
    this.setState(({ filterList }) => {
      return {
        filterList: filterList.map(item => {
          return {
            ...item,
            selected: !!checkedList.includes(item.value),
          };
        }),
        checkAll: checkedList.length === filterList.length,
        indeterminate: checkedList.length && checkedList.length < filterList.length,
      };
    });
  }

  onCheckAllChange(e) {
    const isCheckedAll = e.target.checked;
    this.setState(
      {
        filterList: isCheckedAll
          ? this.state.filterList.map(item => ({ ...item, selected: true }))
          : this.state.filterList.map(item => ({ ...item, selected: false })),
        checkAll: isCheckedAll,
      },
      () => {
        this.setState({
          indeterminate:
            this.state.filterList.filter(item => item.selected).length &&
            this.state.filterList.filter(item => item.selected).length <
              this.state.filterList.length,
        });
      },
    );
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ filterList }) => {
      const newList = arrayMove(filterList, oldIndex, newIndex);
      return {
        filterList: newList,
        showList: newList,
      };
    });
  }
  handleDateChange = (key, date) => {
    this.setState({
      startDate: date[0],
      endDate: date[1],
    });
  };
  generatePDF() {
    let finalCheckedList = this.state.filterList
      .filter(item => item.selected === true)
      .map(item => item.label);
    if (finalCheckedList.length) {
      this.setState({ isFetching: true }, () => {
        this.modalRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      });
      this.props.dispatch(
        clientChartReport({
          ClientId: this.props.getClientId,
          StartDate: stringDate(this.state.startDate),
          EndDate: stringDate(this.state.endDate),
          ReportTypes: finalCheckedList.toString(),
        }),
      );
    } else {
      alert('Please select atleast one category to generate PDF');
    }
  }

  render() {
    const {
      filterList,
      showList,
      checkAll,
      indeterminate,
      startDate,
      endDate,
      isFetching,
    } = this.state;
    // const { isFetching } = this.props;
    const PDF_TEXT_STYLES = {
      text: {
        transform: 'translate(-45%, -50%)',
        width: '100%',
        height: 'unset',
        textAlign: 'center',
      },
    };
    return (
      <div className="print-client-chart">
        {/* <Loader2 loading={isFetching} /> */}
        {isFetching && (
          <div className="preloader-box2" style={{ zIndex: '999' }}>
            <div ref={this.modalRef} className="preloader2 loading" style={PDF_TEXT_STYLES.text}>
              Generating client chart, please wait ...
              <img src={`images/chatLoader.gif`} className="ml-1" alt="" /> <br />
              <p>
                Note: If the document fails to generate, the requested document will be
                automatically uploaded to the Documents tab after 5 - 10 minutes.
              </p>
            </div>
          </div>
        )}
        <div>
          <div className="select-all">
            <Checkbox
              onChange={this.onCheckAllChange.bind(this)}
              checked={checkAll}
              indeterminate={indeterminate}
            />
            <span className="ml-2" style={{ fontSize: '17px', fontWeight: '600' }}>
              Select All
            </span>
          </div>
          <div className="mt-4">
            <span class="font-weight-bold ">Date Range : </span>
            <RangePicker
              // style={{}}
              defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
              onChange={this.handleDateChange}
              className="range-picker w-50 text-left"
              format={['YYYY-MM-DD', 'YYYY/MM/DD']}
            />
            <p style={{ fontSize: '13px', fontStyle: 'italic', marginTop: '5px' }}>
              Note: The date range is not applicable to Demographics, Medical History, Insurance and
              Group Membership.
            </p>
          </div>
          <CheckboxGroup
            value={filterList.filter(item => item.selected).map(item => item.value)}
            onChange={this.onCheckChange.bind(this)}
          >
            <SortableList
              lockAxis="y"
              lockToContainerEdges={true}
              items={showList}
              onSortEnd={this.onSortEnd.bind(this)}
              onCheckChanged={this.onCheckChanged}
            />
          </CheckboxGroup>
        </div>
        <div className="d-flex justify-content-end" style={{ marginBottom: '15px' }}>
          {/* <button type="button" className="btn btn-outline-primary" onClick={this.props.onCancel}>
            Cancel
          </button> */}
          <button type="button" className="btn ml-2" onClick={this.generatePDF.bind(this)}>
            Generate PDF
          </button>
        </div>
      </div>
    );
  }
}
// export default MultipleCategoryCheckboxAll;
const mapStateToProps = state => {
  const { client_chart_report } = state;
  const { data: pdfReport, isFetching, error } = client_chart_report;

  let reportURL;
  let fileName;
  let statusCode;
  let isSuccess = false;

  if (pdfReport && pdfReport.success) {
    isSuccess = true;
    reportURL = pdfReport.result.blobUrl;
    fileName = pdfReport.result.fileName;
  }
  if (error && error.StatusCode) {
    statusCode = error.StatusCode;
  }

  return {
    reportURL,
    isSuccess,
    isFetching,
    statusCode,
    fileName,
  };
};
export default connect(mapStateToProps)(withRouter(MultipleCategoryCheckboxAll));
