import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PanasScaleChart from './PanasScaleChart';

class PanasScale extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { EventJsonData, isFetching } = this.props;
    let { CreatedDate, DayNumber, IsPositive, MoodEmoji, MoodReason, MoodType, Score } =
      EventJsonData.PANASScale && EventJsonData.PANASScale[0];
    return (
      <div className="pb-5">
        <div class="d-flex" style={{ justifyContent: 'space-evenly' }}>
          {/* <PanasScaleChart MiscData={EventJsonData.PANASScale} /> */}
          <div className="text-center">
            <img src={MoodEmoji} width="180" />
          </div>
          <div>
            <h6 className="mt-2">
              Type : <small> {MoodType} </small>
            </h6>
            <h6 className="mt-2">
              Reason : <small> {MoodReason} </small>
            </h6>
          </div>
        </div>
      </div>
    );
  }
}

export default PanasScale;
