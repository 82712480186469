import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';

class ProspectNote extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { EventJsonData } = this.props;
    let { AddedBy, ImageUrl, AddedOn, Role } = EventJsonData || {};
    return (
      <div class="border-0 update-history-card">
        <div className="row survey-event-details">
          <div className="screening-questions-answered-event w-100">
            <div className="note-event">
              <div className="added-by">
                <span className="added-text">Added by</span>
                <img src={ImageUrl || 'images/blank-profile.png'} alt="added by" />
                <div className="name-box">
                  <span className="name">{AddedBy}</span>
                  <span className="role">{Role}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProspectNote;
