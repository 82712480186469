import React, { Component } from 'react';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
// import bundleServiceCategory from '../../components/bundleServiceCategory';
import ContractBundles from '../../components/contractBundles';
import { smartContractGet } from '../../../../store/actions';
import { Loader2 } from '../../../../Components/Loader';
import { currencyFormatter } from '../../../../util/formatter';
import BundleCollapse from '../../components/milestonesCollapseView/bundleCollapse';

let contarctFeilds = [
  {
    key: 'Start Date',
    value: null,
  },
  {
    key: 'Expected End Date',
    value: null,
  },
  {
    key: 'Total Goals Defined',
    value: null,
  },
  {
    key: 'Total Service Bundles',
    value: null,
  },
  {
    key: 'Total Services',
    value: null,
  },
  {
    key: 'Total Milestones',
    value: null,
  },
  {
    key: 'Total Tasks',
    value: null,
  },
  {
    key: 'Days remaining in Treatment',
    value: null,
  },
  {
    key: 'Claim Amount',
    value: null,
  },
];

class ContractDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractfields: contarctFeilds,
    };
  }

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  getContractId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var cId = param.get('cId');
    if (cId) {
      cId = parseInt(cId);
    } else {
      cId = null;
    }
    return cId;
  }

  componentWillReceiveProps({ smartContractArray, smartContractOverView }) {
    if (smartContractOverView && smartContractOverView != this.props.smartContractOverView) {
      contarctFeilds &&
        contarctFeilds.map(item => {
          let name = item.key.replaceAll(' ', '').toLowerCase();
          for (let i in smartContractOverView) {
            if (name == i.toLowerCase()) {
              item.value = smartContractOverView[i];
            }
          }
        });
    }
  }

  componentDidMount() {
    let data = {
      SmartContract: [{ ClientId: this.props.clientId, SmartContractId: this.props.contractId }],
    };

    var smartContractData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(smartContractGet(smartContractData));
  }

  render() {
    const {
      smartContractArray,
      showRequest,
      isReadOnly,
      handleShowRequest,
      getServiceCodeDetails,
      smartContractBundles,
      isFetching,
      smartContractMilestones,
      tabsCount,
      goToPreviuos,
      currentTab,
      disable,
      goToNext,
    } = this.props;
    const { contractfields } = this.state;

    return (
      <div className="px-3">
        <Loader2 loading={isFetching} />
        <div className="card border-0 payer-authorizations" style={{ boxShadow: 'none' }}>
          <div className="d-flex flex-wrap client-authorization">
            {contractfields &&
              contractfields.map(item => (
                <div className="col-12 d-flex client-authorization-container col-md-6">
                  <p className="mb-0 client-authorization-label">{item.key}</p>
                  <p className="mb-0 client-authorization-value">
                    {item.value && item.key == 'Claim Amount'
                      ? currencyFormatter.format(item.value)
                      : item.value}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <h5 className="mt-5"> Bundles</h5>

        {/* {smartContractBundles &&
          smartContractBundles.map(item => {
            const { Milestone, OverAllProgress } = item;
            // return ();
          })} */}
        <div className="">
          <div
            className="mt-3"
            style={{
              width: '100%',
              marginLeft: '10px',
              backgroundColor: '#F8F8F8',
              padding: '20px',
              border: ' 1px solid #EAEAEA',
            }}
          >
            {/* <div className="d-flex">
                    <div className="client-detail-box col-4 p-0 m-0">
                      <p className="client-label mb-0">Goal*</p>
                      <p className="client-text mb-0">{item.Description}</p>
                    </div>
                    <div className="client-detail-box col-4 p-0 m-0">
                      <p className="client-label mb-0">Duration*</p>
                      <p className="client-text mb-0">{item.Duration}</p>
                    </div>
                  </div> */}
            {/* <h5 className="text-uppercase mt-3"></h5> */}
            <ContractBundles
              // showRequest={showRequest}
              actionAllowed={true}
              getServiceCodeDetails={getServiceCodeDetails}
              smartContractArray={smartContractBundles}
              isReadOnly={isReadOnly}
            />
            <h5 className="mt-0">Milestones</h5>
            <div className="card border-0 p-4">
              {/* {smartContractMilestones &&
                smartContractMilestones.map((item, index) => {
                  return (
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: '#F8F8F8',
                        padding: '20px',
                        border: ' 1px solid #EAEAEA',
                        marginBottom: '15px',
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-start mb-3">
                        <div className="client-detail-box p-0 m-0">
                          <p className="client-label mb-0">Bundle {index + 1}*</p>
                          <p className="client-text mb-0">{item.BundleName && item.BundleName}</p>
                        </div>

                        <div className="client-detail-box col-3 p-0 m-0">
                          <p className="client-label mb-0">Goal*</p>
                          <p className="client-text mb-0">{item.Goal && item.Goal}</p>
                        </div>
                        <div className="client-detail-box col-3 p-0 m-0">
                          <p
                            className={`mb-0 client-text client-info-text ${item.StatusClassName}`}
                            style={{ width: 'fit-content' }}
                          >
                            {item.Status && item.Status}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-start">
                        <div className="client-detail-box p-0 m-0">
                          <p className="client-label mb-0">Milestone {index + 1}*</p>
                          <p className="client-text mb-0">{item.Milestone && item.Milestone}</p>
                        </div>

                        <div className="client-detail-box col-3 p-0 m-0">
                          <p className="client-label mb-0">Duration*</p>
                          <p className="client-text mb-0">{item.Duration && item.Duration}</p>
                        </div>
                      </div>
                    </div>
                  );
                })} */}
              <BundleCollapse bundles={smartContractBundles} />
            </div>
          </div>
        </div>

        <div class="col-12 text-right mt-3">
          {(currentTab > 0 || currentTab + 1 == tabsCount) && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="button"
              onClick={goToNext}
              class="btn btn-eForm-Next"
              disabled={disable}
              style={{ padding: '10px 39px' }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}

// export default ContractDetails;

const mapStateToProps = ({
  smart_contract_get,
  payer_price_details_get,
  payer_portal_request_upsert,
  combos,
}) => {
  const { data: smartContractDetails, isFetching } = smart_contract_get;

  let smartContractOverView = {};
  let smartContractArray = {};
  let smartContractBundles = [];
  let smartContractMilestones = [];

  if (smartContractDetails && smartContractDetails.SmartContract) {
    smartContractArray = smartContractDetails.SmartContract;

    smartContractOverView =
      smartContractDetails.SmartContract[0] &&
      smartContractDetails.SmartContract[0].ContractDetailsOverView &&
      smartContractDetails.SmartContract[0].ContractDetailsOverView[0];

    smartContractBundles =
      smartContractDetails.SmartContract[0] && smartContractDetails.SmartContract[0].Bundle
        ? smartContractDetails.SmartContract[0].Bundle
        : [];

    smartContractMilestones =
      smartContractDetails.SmartContract[0] && smartContractDetails.SmartContract[0].Milestones
        ? smartContractDetails.SmartContract[0].Milestones
        : [];
  }

  return {
    smartContractArray,
    smartContractOverView,
    smartContractBundles,
    isFetching,
    smartContractMilestones,
  };
};

export default connect(mapStateToProps)(withRouter(ContractDetails));
