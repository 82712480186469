import React from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { Table, Checkbox, Radio, Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../Components/Loader';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
} from '../../../component/custom-redux-components';
import CustomModal from '../../../core/modal';
import CustomModal2 from '../../../Components/CustomModal';
import { Component } from 'react';
import {
  getClientAppointment,
  // comboAppointmentTreatmentPlan,
  linkAppointment,
  getTreatmentPlanAccordin,
  appointmentLinkGet,
} from '../../../store/actions';
import { Tooltip } from 'antd';
import IndividualAppointmentModal from '../appointments/individualAppointmentModal';
import SubmitButton from '../../../Components/submitButton';
class LinkAppointment extends Component {
  constructor() {
    super();
    this.state = {
      tieAppointmentId: null,
      eventModalView: false,
      isModelOpen: '',
      header: '',
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentWillReceiveProps({ linkAppointmentSuccess }) {
    if (linkAppointmentSuccess && linkAppointmentSuccess != this.props.linkAppointmentSuccess) {
      this.getAppointmentLinkInfo();
      this.props.getLatest && this.props.getLatest();
      this.setState({
        isModelOpen: true,
      });
    }
  }

  getAppointmentLinkInfo = () => {
    let data = {
      Json: JSON.stringify({
        AppointmentLink: [
          {
            ClientId: this.props.clientId,
            FormType: this.props.formType,
            FormId: this.props.componentDataUniqueId,
          },
        ],
      }),
    };

    this.props.dispatch(appointmentLinkGet(data));
  };
  componentDidMount() {
    this.getAppointmentLinkInfo();
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  render() {
    const { eventModalView, isModelOpen, header } = this.state;
    const {
      linkData,
      isAppointmentLink,
      isAppointmentNote,
      appointmentMessage,
      appointmentId,
      appointmentLinkOption,
      isReadOnlyUserSession,
    } = this.props;
    return (
      <div className="mb-2">
        {/* <Loader2 loading={this.props.isFetching} /> */}
        <div className="mb-2">
          {linkData && !linkData.IsSigned && !isAppointmentLink && (
            <>
              <h5>Link Appointment</h5>
              <div className="row mb-4 enrollment-form">
                <div className="col-md-4 form-group mt-3">
                  <ReduxSelect
                    options={appointmentLinkOption}
                    onChange={tieAppointmentId => {
                      this.setState({ tieAppointmentId });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={this.state.tieAppointmentId}
                    placeholder="Select Appointment"
                    disabled={isReadOnlyUserSession}
                  />
                </div>
                <div className="col-md-4">
                  {!isReadOnlyUserSession && (
                    // <button
                    //   className="btn btn-outline-primary  font-weight-bold mr-0"
                    //   type="submit"
                    //   disabled={this.state.tieAppointmentId == null}
                    //   onClick={() => {
                    //     this.setState({
                    //       header: 'Appointment linked successfully',
                    //     });
                    //     let data = {
                    //       Json: JSON.stringify({
                    //         AppointmentLink: [
                    //           {
                    //             FormType: this.props.formType,
                    //             FormId: this.props.componentDataUniqueId,
                    //             AppointmentId: this.state.tieAppointmentId,
                    //           },
                    //         ],
                    //       }),
                    //     };

                    //     this.props.dispatch(linkAppointment(data));
                    //   }}
                    // >
                    //   Link Appointment
                    // </button>
                    <SubmitButton
                      className="btn btn-outline-primary  font-weight-bold mr-0"
                      disabled={this.state.tieAppointmentId == null}
                      value={'Link Appointment'}
                      onClick={() => {
                        this.setState({
                          header: 'Appointment linked successfully',
                        });
                        let data = {
                          Json: JSON.stringify({
                            AppointmentLink: [
                              {
                                FormType: this.props.formType,
                                FormId: this.props.componentDataUniqueId,
                                AppointmentId: this.state.tieAppointmentId,
                              },
                            ],
                          }),
                        };

                        this.props.dispatch(linkAppointment(data));
                      }}
                      style={{
                        height: '45px',
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          {linkData && (linkData.IsSigned || isAppointmentLink) && (
            <>
              <h5>Appointment Linking Status </h5>
              <div className="row">
                <div class="col-md-7">
                  {linkData &&
                    // isAppointmentLink &&
                    appointmentMessage &&
                    renderHTML(appointmentMessage)}
                </div>
                <div class="col-md-5">
                  {linkData && isAppointmentLink && !isReadOnlyUserSession && (
                    <CustomModal2
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-course btn-link"
                          onClick={onClick}
                          style={{ cursor: 'pointer', color: 'red' }}
                        >
                          <i class="fas fa-unlink"></i> Delete
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              &times;
                            </button>
                            <h4 class="modal-title col-12">Are you sure you want to Delete?</h4>
                          </div>

                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn "
                              onClick={() => {
                                this.setState({
                                  header: 'Appointment linking removed successfully',
                                });

                                // { "AppointmentLink":[{"FormType" : "TreatmentPlan", "FormId":1,"AppointmentId":1}]}

                                let data = {
                                  Json: JSON.stringify({
                                    AppointmentLink: [
                                      {
                                        FormType: this.props.formType,
                                        FormId: this.props.componentDataUniqueId,
                                        AppointmentId: null,
                                      },
                                    ],
                                  }),
                                };

                                this.props.dispatch(linkAppointment(data));

                                onOk();
                                // this.cancelGroupCall(Data.VMCallSessionId);
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {linkData && isAppointmentLink && !isReadOnlyUserSession && isAppointmentNote ? (
                    <span
                      className="btn-link"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          eventModalView: true,
                        });
                      }}
                    >
                      <i class="fas fa-clipboard-list"></i> Appointment Note
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        {eventModalView && (
          <Modal
            zIndex={100}
            visible={eventModalView}
            footer={null}
            closable={false}
            width={700}
            destroyOnClose={true}
          >
            <IndividualAppointmentModal
              AppointmentId={linkData && isAppointmentLink && appointmentId}
              onCancel={() => {
                let data = {
                  Json: JSON.stringify({
                    TreatmentPlan: [
                      {
                        TreatmentPlanId: this.props.componentDataUniqueId,
                      },
                    ],
                  }),
                };

                this.props.dispatch(getTreatmentPlanAccordin(data));

                this.props.dispatch(
                  getClientAppointment({
                    Json: JSON.stringify({
                      Appointment: [
                        {
                          ClientId: this.getClientId(),
                        },
                      ],
                    }),
                  }),
                );
                this.setState({
                  eventModalView: false,
                });
              }}
            />
          </Modal>
        )}

        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            zIndex={9999999}
            header={header}
            yesAction={() => {
              this.toggle();
              let data = {
                Json: JSON.stringify({
                  TreatmentPlan: [
                    {
                      TreatmentPlanId: this.props.componentDataUniqueId,
                    },
                  ],
                }),
              };

              this.props.dispatch(getTreatmentPlanAccordin(data));
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ appointment_link_get, link_appointment }) => {
  const { data: appoitmentData } = appointment_link_get;
  const { data: linkData } = link_appointment;

  var isAppointmentLink;
  var isAppointmentNote;
  var appointmentMessage;
  var appointmentId;
  var appointmentLinkOption;
  var linkAppointmentSuccess;

  if (appoitmentData) {
    const {
      IsAppointmentLink,
      IsAppointmentNote,
      AppointmentMessage,
      AppointmentId,
      AppointmentLinkOption,
    } = appoitmentData;

    isAppointmentLink = IsAppointmentLink;
    isAppointmentNote = IsAppointmentNote;
    appointmentMessage = AppointmentMessage;
    appointmentId = AppointmentId;
    appointmentLinkOption = AppointmentLinkOption;
  }
  if (linkData && linkData.success) {
    linkAppointmentSuccess = linkData.success;
  }

  return {
    isAppointmentLink,
    isAppointmentNote,
    appointmentMessage,
    appointmentId,
    appointmentLinkOption: appointmentLinkOption || [],
    linkAppointmentSuccess,
  };
};

export default connect(mapStateToProps)(withRouter(LinkAppointment));
