import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProspectBanner from '../ProspectBanner';
import {
  listSurveyQuestionAssessment,
  surveyAnswerUpsert,
  augmentedClientInsightsGet,
  surveyLatestAnswerDateUpdate,
  studentBanner,
} from '../../../../store/actions';
import QuestionType from '../../../RCM/patientEnrollmentForm/formSteps/questionType/questionComponent';
import {
  HasMandatoryQuestion,
  HasMandatoryQuestionWarning,
  UpdateResponse,
} from '../../../RCM/patientEnrollmentForm/formSteps/questionType/mandatoryQuestionValidation';
import getParam from '../../../../util/getParam';

class AcceptanceQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyQuestionAssessment: {},
      surveys: [],
      surveyListData: [],
    };
  }
  getIntakeId = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentId = param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = null;
    }
    return studentId;
  };
  getSurveyId = () => {
    let param = new URLSearchParams(this.props.history.location.search);
    let surveyid = param.get('surveyid');
    if (surveyid) {
      surveyid = parseInt(surveyid);
    } else {
      surveyid = null;
    }
    return surveyid;
  };
  componentDidMount() {
    if (this.props.AcceptanceQuestionnaireSurveyId) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: this.props.ID,
                SurveyId: this.props.AcceptanceQuestionnaireSurveyId,
                SurveyType: 'AcceptanceQuestionnaire',
              },
            ],
          }),
        }),
      );
    }
  }
  componentWillReceiveProps({ surveyQuestionAssessment, AcceptanceQuestionnaireSurveyId }) {
    if (
      surveyQuestionAssessment &&
      JSON.stringify(surveyQuestionAssessment) !=
        JSON.stringify(this.props.surveyQuestionAssessment)
    ) {
      //   if (!this.state.isAppointmentLinked) {
      //     this.toggleView();
      //   }
      //   const hasMandatory = HasMandatoryQuestion(
      //     surveyQuestionAssessment,
      //     this.state.SignatureCanvas,
      //   );
      this.setState({
        surveyQuestionAssessment: surveyQuestionAssessment,
        // hasMandatory: hasMandatory,
      });
    }
    if (
      AcceptanceQuestionnaireSurveyId &&
      AcceptanceQuestionnaireSurveyId != this.props.AcceptanceQuestionnaireSurveyId
    ) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: this.props.ID,
                SurveyId: AcceptanceQuestionnaireSurveyId,
                SurveyType: 'AcceptanceQuestionnaire',
              },
            ],
          }),
        }),
      );
    }
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index, isResetAnswer }) {
    let json = {
      StudentId: getParam(this.props.history, 'studentid') || null,
      SurveyId: this.props.AcceptanceQuestionnaireSurveyId,
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
    };
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });

    const surveys = [...this.state.surveys];
    for (let survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    this.setState({
      surveys: surveys,
    });
    if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
  }
  setValue({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
  }) {
    var json = {
      StudentId: getParam(this.props.history, 'studentid') || null,
      SurveyId: this.props.AcceptanceQuestionnaireSurveyId,
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    if (IsSignature) {
      this.setState({
        surveys: surveys,
        Signature: IsSignature && Signature,
        SignatureCanvas: IsSignature && {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [surveyQuestionId]: {
              SurveyQuestionId: surveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveys: surveys,
      });
    }

    this.props.dispatch(surveyAnswerUpsert(json));
  }
  saveResponse = ({ response, surveyQuestionId, index, level, subLevel, subCatLevel }) => {
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
  };
  saveSignature = (
    signOne,
    data,
    SurveyQuestionId,
    index,
    Response,
    isSignatureSaved,
    isClear,
    isResponceData,
  ) => {
    let me = this;
    if (isClear) {
      let signaturData = me.state.SignatureCanvas;
      let signaturDataList = me.state.SignatureCanvas && me.state.SignatureCanvas.signatureDataList;
      delete signaturData[signOne];
      delete signaturDataList[signOne];
      signaturData.signatureDataList = signaturDataList;
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
      return;
    }
    if (isResponceData) {
      let signaturData = {
        ...this.state.SignatureCanvas,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    } else {
      let signaturData = {
        ...this.state.SignatureCanvas,
        [signOne]: data,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
            Response: Response,
            IsUpdateSignature: Response ? true : false,
            MediaGuid: Response ? Response : null,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        surveyQuestionId: SurveyQuestionId,
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    }
  };
  render() {
    const { surveyQuestionAssessment } = this.state;
    return (
      <div class="main enrollment-page">
        <div class="row">
          {/* <div class="col-12">
            <ProspectBanner ClientId={this.props.ID} />
          </div> */}
          <div class="col-12 mb-2">
            <div class="card border-0 rounded-0">
              <div class="card-body enrollment-form pb-2">
                <div className="row">
                  <div class="col-12 text-center">
                    <div
                      class="survery-question-box "
                      style={{ maxWidth: 'unset', marginTop: '0px' }}
                    >
                      <div className="screening-tool">
                        {/* <div className="assessment-main-title d-flex align-items-center justify-content-between">
                          <span className="main-title-text">
                            {surveyQuestionAssessment.SurveyMainTitle}
                          </span>
                        </div> */}
                        {surveyQuestionAssessment && surveyQuestionAssessment.SurveyQuestion && (
                          <div
                            style={{
                              padding: '20px',
                              margin: '0px 20px',
                            }}
                          >
                            <QuestionType
                              SurveyQuestion={surveyQuestionAssessment.SurveyQuestion}
                              SurveyId={surveyQuestionAssessment.SurveyId}
                              onNext={this.onNext}
                              setValue={this.setValue}
                              saveResponse={this.saveResponse}
                              scope={this}
                              level={0}
                              // isReadOnly={this.state.isReadOnly}
                              saveSignature={this.saveSignature}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ list_survey_question_assessment, student_banner }) => {
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: bannerData } = student_banner;

  let surveyQuestionAssessment;
  let AcceptanceQuestionnaireSurveyId = null;

  if (surveyQuestionAssessmentData) {
    surveyQuestionAssessment = surveyQuestionAssessmentData;
  }
  if (bannerData) {
    AcceptanceQuestionnaireSurveyId = bannerData.AcceptanceQuestionnaireSurveyId;
  }
  return {
    surveyQuestionAssessment,
    AcceptanceQuestionnaireSurveyId,
  };
};
export default connect(mapStateToProps)(withRouter(AcceptanceQuestionnaire));
