import React from 'react';
var timer;

const GridSearch = props => {
  const { item, onSearchChange } = props;
  const fnDebounce = (value, KeyName) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      onSearchChange(value, KeyName);
    }, 600);
  };

  return (
    <div className="search-box d-inline-flex w-100">
      <div className="form-group mb-0 has-search w-100">
        <input
          type="text"
          class="form-control 
                   shadow-sm"
          placeholder={item.AdditionalFieldName}
          id="searchBar"
          onKeyUp={event => {
            let val = event.target.value.length > 0 ? event.target.value : null;
            fnDebounce(val, item.KeyName);
          }}
          style={{ minHeight: '46px' }}
        />
        <button class="btn search-icon " type="button">
          <i class="fa fa-search" />
        </button>
      </div>
    </div>
  );
};
export default GridSearch;
