import React, { Component } from 'react'

class ProviderAndClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // this.props.dispatch();
  }
    render() {
        const { details } = this.props;
        const { FirstName, LastName, Phone, Email } = details;

    return (
      <div className="client-details d-flex flex-wrap">
        <div className="client-info col-12 col-md-3">
          <p className="client-info-heading mb-0">First Name</p>
          <p className="client-info-text mb-0">{FirstName && FirstName}</p>
        </div>
        <div className="client-info col-12 col-md-3">
          <p className="client-info-heading mb-0">Last Name</p>
          <p className="client-info-text mb-0">{LastName && LastName}</p>
        </div>
        <div className="client-info col-12 col-md-3">
          <p className="client-info-heading mb-0">Phone Number</p>
          <p className="client-info-text mb-0">{Phone && Phone}</p>
        </div>
        <div className="client-info col-12 col-md-3">
          <p className="client-info-heading mb-0">Email</p>
          <p className="client-info-text mb-0">{Email && Email}</p>
        </div>
      </div>
    );
  }
}

export default ProviderAndClientDetails;
