import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import AppointmentFormFields from './AppointmentFormFields';
import { Modal } from 'antd';
import VOIPCallModal from '../AppointmentsScreens/VOIPCallModal';
import Twilio from '../../../../../Components/twilio';
import { Twilio_Enterprise } from '../../../../../app-config/screenName';
import SubmitButton from '../../../../../Components/submitButton';
import CustomModal from '../../../../../core/modal';
import ProspectAutocompleteSearch from './ProspectAutocompleteSearch';
class AppointmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callModelVisible: false,
      isModelOpen: false,
      header: '',
    };
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentWillReceiveProps({ isAppointmentBooked }) {
    if (isAppointmentBooked && isAppointmentBooked != this.props.isAppointmentBooked) {
      this.toggle('Appointment created/updated successfully');
    }
  }
  render() {
    const {
      handleSubmit,
      disable,
      onCancel,
      dispatch,
      initialValues,
      change,
      BannerData,
      handleAppointment,
      OptionData,
      AppointmentId,
      isFetching,
      onClose,
      getAppointmentList,
      isNewCalendarAppointment,
      clientList,
      getSelectedData,
      onSearch,
    } = this.props;
    const {
      PrefferedName,
      CurrentClientId,
      PrimaryPhone,
      StudentImageUrl,
      IsthisPeerRecoveryClient,
      IsVoiceCallAllowed,
      StudentId,
    } = BannerData || {};
    return (
      <div className="prospect-appointments-form mt-2">
        {isNewCalendarAppointment && (
          <div className="row">
            <div className="col-12">
              <ProspectAutocompleteSearch
                ListData={clientList}
                getSelectedData={getSelectedData}
                onSearch={onSearch}
              />
            </div>
          </div>
        )}
        {BannerData && (
          <div className="row mb-4">
            <div className="col-4">
              <span className="d-inline-flex mb-0 mr-3">
                <img src="images/userP.svg" style={{ height: '27', marginRight: '5px' }} />
                {PrefferedName}
              </span>
            </div>
            <div className="col-4">
              <span className="d-inline-flex mb-0 mr-3">
                <img src="images/idP.svg" style={{ height: '27', marginRight: '5px' }} />
                {CurrentClientId}
              </span>
            </div>
            <div className="col-4">
              {AppointmentId && IsVoiceCallAllowed > 0 ? (
                <span
                  className="d-inline-flex mb-0 mr-3"
                  style={{ color: '#46B4FE', cursor: 'pointer', display: 'flex' }}
                  onClick={event => {
                    this.setState({
                      callModelVisible: true,
                      clickedRecord: {
                        Phone: PrimaryPhone,
                        Name: PrefferedName,
                        ProfileImageUrl: StudentImageUrl,
                        StudentId: StudentId,
                      },
                    });
                    event.stopPropagation();
                  }}
                >
                  <img
                    src="images/twilioPhoneAllowed.svg"
                    style={{ height: '27', marginRight: '5px' }}
                  />
                  {PrimaryPhone ? PrimaryPhone : 'N/A'}
                </span>
              ) : (
                <span style={{ color: '#000', display: 'flex' }} className="students-phone">
                  <img
                    src={`images/icons/phone-outline_gray.svg`}
                    alt="Calling Not Allowed"
                    width="15px"
                    className="mr-1"
                  />
                  {PrimaryPhone ? PrimaryPhone : 'N/A'}
                </span>
              )}
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit(handleAppointment)}>
          <div className="enrollment-form">
            <AppointmentFormFields {...OptionData} disable={disable} change={change} />
            <div className="row">
              <div className="col-12">
                <div className="border-top-0 pt-2 mt-2 text-right">
                  <SubmitButton
                    loading={isFetching}
                    style={{ height: '45px' }}
                    value={AppointmentId ? 'Save & Update' : 'Add Appointment'}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* <Modal open={this.state.isCallModal} footer={null} closable={false} destroyOnClose={true}>
          <VOIPCallModal
            BannerData={BannerData}
            onCancel={() => {
              this.setState({
                isCallModal: false,
              });
            }}
          />
        </Modal> */}
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
            IsCallRecording={IsthisPeerRecoveryClient}
            ScreenName={Twilio_Enterprise}
            getCallEndDetails={this.props.getCallEndDetails}
          />
        )}
        {this.state.isModelOpen && (
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              getAppointmentList(CurrentClientId);
              onClose();
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { upsert_appointment } = state;
  const { data, isFetching } = upsert_appointment;

  let isAppointmentBooked = false;
  if (data && data.success) {
    isAppointmentBooked = data.success;
  }
  return {
    isAppointmentBooked,
    isFetching,
  };
};
export default reduxForm({
  form: 'createEventForm',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(AppointmentForm)));

// export default connect()(withRouter(AppointmentForm));
