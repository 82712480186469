import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* powerBiReportListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.powerBiReportListGet(payload);
    yield put({
      type: ActionTypes.POWER_BI_REPORT_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.result),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.POWER_BI_REPORT_LIST_GET_FAILURE,
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.POWER_BI_REPORT_LIST_GET_REQUEST, powerBiReportListGet)]);
}
