import React, { Component } from 'react';
import moment from 'moment';
import Autocomplete from 'react-autocomplete';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import DatePicker from 'react-datepicker';
import { Modal, Button, Menu, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import AppUsers from './appUsers';
import InHouse from './inHouse';
import SMDashboard from './smDashboard';
import FamilyProgram from './familyProgram';
import SendAppInviteReduxModal from './customScreens/sendMobileAppInvitation';
import ReactRangePicker from '../component/Range-Picker';
import PageTitle from '../Components/PageTitle';
import {
  getStudentsSearch,
  resetStudentsSearch,
  appSubscribersUserSearch,
  appInviteDashboard,
  getStaffShiftLog,
  addStaffShiftLog,
  resetStaffShiftLog,
  isAchValidation,
  achValidate,
  getDashboardMenu,
  announcementGetAll,
  upsertStaffViewedActivity,
  staffLicenseAgreementUpdate,
  resetLogin,
  logOut,
} from '../store/actions';
import AntdModal from '../Components/CustomModal';
import CustomModal from '../Components/CustomModal';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import LicenseModal from './licenseAgreementModal';
import ConfirmACHPayment from './confirmACHPaymentModal';


const { SubMenu } = Menu;
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

const error = () => {
  Modal.error({
    content: 'Please Enter either Mobile Number OR Email Address',
  });
};
const success = () => {
  Modal.success({
    content: 'Mobile app referral sent successfully',
  });
};

const dateFormat = 'DD-MMM-YYYY';

const { TextArea } = Input;

class GreetingModal extends Component {
  render() {
    const { onOk, onCancel, handleSubmit, onModalCancel } = this.props;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          {/* <h4 class="modal-title col-12">Welcome {localStorage.getItem('userName')}</h4> */}
        </div>
        <div class="modal-body col-md-10 offset-md-1 enrollment-form pb-2">
          <h6>Hi {localStorage.getItem('userName')}</h6>
          <div style={{ textAlign: 'justify', fontWeight: 600 }}>
            <p>Great to have you onboard. Your subscription to SoberPeer begins on </p>
            <p className="text-center">
              <span className="trial-start">1-May-2020</span>
            </p>
            <p>
              and your method of payment will be billed on{' '}
              <span className="trial-end">7-May-2020</span> for the month of May.
            </p>
            {/* <p>
              We hope you are enjoying the free trial and got to see the potential impact of the
              complete capabilities of SoberPeer.
            </p> */}
          </div>
          <div className="d-flex flex-column text-right">
            <h6>Team SoberPeer</h6>
          </div>
        </div>
        <div class="modal-footer justify-content-center border-top-0">
          <button type="button" class="btn black-btn font-weight-bold mr-2" onClick={onModalCancel}>
            Close
          </button>
          {/* <input type="submit" class="btn black-btn font-weight-bold mr-2" value=" Send  " /> */}
        </div>
      </div>
    );
  }
}

// const SendAppInviteReduxModal = reduxForm({
//   form: 'inviteForm',
// })(connect(null)(withRouter(SendAppInviteModal)));
class StaffLogModal extends Component {
  constructor(props) {
    super(props);
    const { startDate } = props;
    this.state = {
      startDate: startDate || null,
      staffLogNotes: '',
    };
  }

  // componentDidMount() {
  //    this.props.dispatch(getStaffShiftLog({}));
  // }
  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  resetState = () => this.setState({ startDate: '', filterCenterId: '' });
  addNote = () => {
    this.props.dispatch(addStaffShiftLog({ Note: this.state.staffLogNotes }));
    this.props.onOk();
  };
  handleChange = (key, date) => {
    // this.props.dispatch(
    //   listServiceLine({
    //     Json: JSON.stringify({
    //       ServiceLine: [
    //         {
    //           Search: this.state.searchStr,
    //           FilterBy: this.state.filterBy,
    //           StartDate: date[0],
    //           EndDate: date[1],
    //         },
    //       ],
    //     }),
    //   }),
    // );

    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });

    this.props.dispatch(
      getStaffShiftLog({
        JsonInput: JSON.stringify({
          StaffShiftLog: [
            {
              StartDate: moment(date[0]).format('YYYY/MM/DD'),
              EndDate: moment(date[1]).format('YYYY/MM/DD'),
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { onOk, onCancel, arrShiftLog } = this.props;
    const { startDate } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
      </>
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Shift Log</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold "> Staff Log Notes</span>
            </div> */}
            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                {/* <label class="patient-label">Staff Log</label> */}
                {/* <textarea
                  style={{ minHeight: '75px' }}
                  class="form-control"
                  placeholder="Add Shift Note"
                  rows="3"
                  value={this.state.staffLogNotes}
                  onChange={event => this.setState({ staffLogNotes: event.target.value })}
                /> */}
                <TextArea
                  showCount
                  maxLength={2000}
                  style={{ minHeight: '75px' }}
                  class="form-control"
                  placeholder="Add Shift Note"
                  rows="3"
                  value={this.state.staffLogNotes}
                  onChange={event => this.setState({ staffLogNotes: event.target.value })}
                />
              </div>
            </div>
            <div class="col-12 col-md-12" style={{ textAlign: 'right' }}>
              <button type="button" class="btn black-btn" onClick={this.addNote}>
                Add
              </button>
            </div>
          </div>
          <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Filter By Date</span>
            </div>

            <ReactRangePicker
              // value={[
              //   moment(this.state.StartDate, 'MM-DD-YYYY'),
              //   moment(this.state.EndDate, 'MM-DD-YYYY'),
              // ]}
              // defaultValue={[moment(Startdate, dateFormat), moment(Enddate, dateFormat)]}
              handleChange={this.handleChange}
            />
            <div class="col-12 text-left mb-3 mt-2">
              <span class="font-weight-bold ">Shift Notes</span>
            </div>
            <div
              class="col-12 col-md-12 enrollment-form"
              style={{ height: '200px', overflowY: 'scroll' }}
            >
              {arrShiftLog &&
                arrShiftLog.map(item => {
                  return (
                    <div className="shift-log-detail">
                      <div class="text-left mb-1 d-flex justify-content-between">
                        <div>
                          <span class="font-weight-bold ">Staff Name : </span>
                          <span>{item.StaffName} </span>
                        </div>
                        <div>
                          <span class="font-weight-bold ">Created Date : </span>
                          <span>{item.CreatedDate} </span>
                        </div>
                      </div>
                      <div class="text-left mb-1">
                        <span class="font-weight-bold ">Shift Note : </span>
                        <span>{item.ShigtLogNote} </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Filter By Date</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => this.handleChange('startDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="col-12 text-left mb-1">
                <span class="font-weight-bold ">Filtered By Date : </span>
                <span>10 jan 2020 </span>
              </div>
            </div>
            <div class="col-12 col-md-12 enrollment-form">
              <div class="col-12 col-md-6 enrollment-form">
                <div class="text-left mb-1">
                  <span class="font-weight-bold ">Created By : </span>
                  <span>Pawan </span>
                </div>
              </div>
              <div class="col-12 text-left mb-1">
                <span class="font-weight-bold ">Staff Log Note :</span>
              </div>
              <div class="form-group">
                It is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout. It is a long established fact that a
                reader will be distracted by the readable content of a page when looking at its
                layout
              </div>
            </div>
          </div>
        */}
        </div>
        {/* <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
        </div> */}
      </div>
    );
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardMenu: {},
      TabName:
        this.props.location.pathname == '/dashboardNew' ||
        this.props.location.pathname == '/dashboardNew/inHouse'
          ? 'In-House'
          : 'App Users',
      inviteFormModal: false,
      greetingModal: false,
      achModal: false,
      firstLoginModal: true,
      showSubMenu: false,
      current:
        (this.props.location.pathname == '/dashboardNew' && 'inHouse') ||
        this.props.location.pathname.replace('/dashboardNew/', '') ||
        'inHouse',
    };
    // return parseInt(this.props.location.pathname.replace('/studentProfile/', ''));
  }

  componentWillUnmount() {
    this.props.dispatch(resetStudentsSearch());
  }
  componentDidMount() {
    this.props.dispatch(getDashboardMenu({}));
    this.props.dispatch(isAchValidation({}));
    this.props.dispatch(announcementGetAll({}));

    this.setState({
      greetingModal: sessionStorage.getItem('greetingModal'),
      // achModal: sessionStorage.getItem('achModal'),
      achModal: true,
      firstLogin: sessionStorage.getItem('firstLogin'),
    });
    sessionStorage.removeItem('pulledLatest');
  }
  componentWillReceiveProps({ isStaffShiftLog, dashboardMenu, isShiftlog }) {
    if (isStaffShiftLog && isStaffShiftLog != this.props.isStaffShiftLog) {
      this.props.dispatch(resetStaffShiftLog());
      this.props.dispatch(getStaffShiftLog({}));
    }
    if (isShiftlog && isShiftlog != this.props.isShiftlog) {
      if (this.state.current === 'inHouse') {
        this.props.dispatch(getStaffShiftLog({}));
      }
    }
    if (
      dashboardMenu &&
      JSON.stringify(dashboardMenu) != JSON.stringify(this.props.dashboardMenu)
    ) {
      this.setState({
        dashboardMenu,
      });
    }
  }
  onModalCancel = () => {
    this.setState({
      greetingModal: false,
    });
    sessionStorage.setItem('greetingModal', false);
  };
  onachModalCancel = () => {
    this.setState({
      achModal: false,
    });
    // sessionStorage.setItem('achModal', false);
  };
  onLicenseModalCancel = isAgreed => {
    this.setState({
      firstLoginModal: false,
    });
    sessionStorage.setItem('firstLogin', false);
    if (isAgreed == true) {
      this.props.dispatch(staffLicenseAgreementUpdate({}));
    } else if (isAgreed == false) {
      this.props.dispatch(resetLogin);
      this.props.dispatch(logOut({}));
      // this.props.logOutBroadcaster();
    }
  };
  handleMenuClick = e => {
    if (e.key == 'ALUM') {
      this.props.history.push(`/appSubscribers?filter=${e.key}`);
    } else {
      this.props.history.push(`/dashboardNew/${e.key}`);
    }
  };
  render() {
    const {
      showSubMenu,
      TabName,
      searchStr,
      inviteFormModal,
      greetingModal,
      achModal,
      current,
      dashboardMenu,
    } = this.state;
    const {
      history,
      stuSearchOption,
      userSearchOption,
      lastShiftLog,
      staffShiftLog,
      isAchVarification,
      isNewAnnouncement,
      isNewShiftLog,
      FeatureFlags: { shiftlog },
    } = this.props;
    let SearchOption = {
      inHouse: {
        SearchOption: stuSearchOption,
        idKey: 'StudentId',
        dispatchMethod: getStudentsSearch,
        pushKey: '/studentProfile/',
      },
      'app-users': {
        SearchOption: userSearchOption,
        idKey: 'UserGuid',
        dispatchMethod: appSubscribersUserSearch,
        pushKey: '/subscriberProfile/',
      },
      'sg-dashboard': {
        SearchOption: userSearchOption,
        idKey: 'UserGuid',
        dispatchMethod: appSubscribersUserSearch,
        pushKey: '/subscriberProfile/',
      },
      familyProgram: {
        SearchOption: userSearchOption,
        idKey: 'UserGuid',
        dispatchMethod: appSubscribersUserSearch,
        pushKey: '/subscriberProfile/',
      },
    };
    const {
      IsAppUser,
      IsInHouse,
      IsSmallGroup,
      IsFamilyProgram,
      IsAlumni,
      IsTaskCard,
      InPersonDashboard,
      MobileDashboard,
      SmallGroupDashboard,
    } = dashboardMenu;
    return (
      <>
        <PageTitle TitleText="Dashboard" />
        <div class="main coach-dashboard new-dashboard">
          <div className="row mx-0" style={{ background: '#fff', height: '50px' }}>
            <div className="col-sm-4 col-md-4 col-lg-3">
              <div
                style={{ maxWidth: '160px' }}
                className="nav-item dropdown"
                onMouseEnter={() => this.setState({ showSubMenu: true })}
                onMouseLeave={() => this.setState({ showSubMenu: false })}
              >
                {/* <a className="nav-link dropdown-toggle btn btn-outline-primary ">
                  <span className="">{'More'}</span>
                </a> */}
                {current === 'inHouse' && (
                  <>{(isNewShiftLog || isNewAnnouncement) && <span class="new-badge">New</span>}</>
                )}
                {current != 'familyProgram' && (
                  <button
                    type="button"
                    class="nav-link dropdown-toggle btn btn-outline-primary"
                    style={{ padding: '5px 20px', marginTop: '7px', marginLeft: '45px' }}
                  >
                    More
                  </button>
                )}

                <div
                  className={`dropdown-menu ${showSubMenu && 'show'}`}
                  style={{ marginTop: '-5px' }}
                >
                  {current === 'inHouse' && (
                    <>
                      {/* <AntdModal
                        destroyOnClose={true}
                        ModalButton={({ onClick }) => (
                          <>
                            <a
                              onClick={() => {
                                this.setState({ showSubMenu: false });
                                onClick();
                              }}
                            >
                              <span className="dropdown-item">Shift Log </span>
                            </a>
                            {lastShiftLog && lastShiftLog.NewNote == 1 && (
                              <span class="alert-danger-dot" />
                            )}
                          </>
                        )}
                        ModalContent={props => (
                          <StaffLogModal
                            {...props}
                            dispatch={this.props.dispatch}
                            arrShiftLog={staffShiftLog}
                          />
                        )}
                      /> */}

                      {shiftlog && (
                        <Link
                          onClick={() => {
                            this.props.dispatch(
                              upsertStaffViewedActivity({
                                JsonInput: JSON.stringify({
                                  Activity: [
                                    {
                                      ActivityName: 'StaffShiftLog',
                                    },
                                  ],
                                }),
                              }),
                            );
                          }}
                          to={{
                            pathname: '/shift-log',
                          }}
                          target="_blank"
                        >
                          <span className="dropdown-item">
                            Shift Log
                            {isNewShiftLog && (
                              <span class="alert-danger-dot" style={{ top: 'unset' }}></span>
                            )}
                          </span>
                        </Link>
                      )}

                      <a
                        onClick={() => {
                          const element = document.getElementById('announcements-section');
                          if (element) {
                            element.scrollIntoView();
                            this.props.dispatch(
                              upsertStaffViewedActivity({
                                JsonInput: JSON.stringify({
                                  Activity: [
                                    {
                                      ActivityName: 'Announcement',
                                    },
                                  ],
                                }),
                              }),
                            );
                          } else return;
                        }}
                      >
                        <span className="dropdown-item">
                          Announcement
                          {isNewAnnouncement && (
                            <span class="alert-danger-dot" style={{ top: 'unset' }}></span>
                          )}
                        </span>
                      </a>
                      <a
                        onClick={() => {
                          this.setState({
                            inviteFormModal: true,
                          });
                        }}
                      >
                        <span className="dropdown-item"> Send Mobile App Referral </span>
                      </a>

                      <Link
                        to={{
                          pathname: '/dashboardAnalytics',
                        }}
                        target="_blank"
                      >
                        <span className="dropdown-item">Analytics</span>
                      </Link>
                    </>
                  )}
                  {current === 'app-users' && (
                    <>
                      <Link to="/sessionDashboard">
                        <span className="dropdown-item">Manage Telehealth Sessions </span>
                      </Link>

                      <Link to="/appSubscribers">
                        <span className="dropdown-item">Manage App Subscribers </span>
                      </Link>
                      <a
                        onClick={() => {
                          this.setState({
                            inviteFormModal: true,
                          });
                        }}
                      >
                        <span className="dropdown-item">Send Mobile App Referral</span>
                      </a>

                      <Link
                        to={{
                          pathname: '/dashboardAnalytics',
                        }}
                        target="_blank"
                      >
                        <span className="dropdown-item"> Analytics</span>
                      </Link>
                    </>
                  )}
                  {current === 'sg-dashboard' && (
                    <>
                      <Link
                        to={{
                          pathname: '/dashboardAnalytics',
                        }}
                        target="_blank"
                      >
                        <span className="dropdown-item"> Analytics</span>
                      </Link>
                    </>
                  )}
                  {current === 'familyProgram' && (
                    <span className="ml-1 mr-1">No options available</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-5">
              <Menu
                onClick={this.handleMenuClick}
                selectedKeys={[current]}
                mode="horizontal"
                className="dashboard-menu"
              >
                {/* <SubMenu key="SubMenu" title="More">
                  <Menu.ItemGroup title="Item 1">
                    <Menu.Item key="setting:1">Option 1</Menu.Item>
                    <Menu.Item key="setting:2">Option 2</Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu> */}
                {IsInHouse && <Menu.Item key="inHouse">In Person</Menu.Item>}
                {IsSmallGroup && <Menu.Item key="sg-dashboard">Small Groups</Menu.Item>}
                {IsAppUser && <Menu.Item key="app-users">Mobile Advisors</Menu.Item>}
                {IsFamilyProgram && <Menu.Item key="familyProgram"> Family Program</Menu.Item>}
                {IsAlumni && <Menu.Item key="ALUM"> Alumni</Menu.Item>}
                {/* {IsFamilyProgram && (
                  <span
                    className="extra-menu-item"
                    style={{ margin: '0 20px', cursor: 'pointer' }}
                    onClick={() => {
                      // ?subscriberId=5D9303E8-9093-4FCC-AB9D-1B3D7B89EC26
                      this.props.history.push('/appSubscribers?filter=FP');
                    }}
                  >
                    Family Program
                  </span>
                )} */}
                {/* {IsAlumni && (
                  <span
                    className="extra-menu-item"
                    style={{ margin: '0 20px', cursor: 'pointer' }}
                    onClick={() => {
                      this.props.history.push('/appSubscribers?filter=ALUM');
                    }}
                  >
                    Alumni
                  </span>
                )} */}
                {/* {IsFamilyProgram && <Menu.Item key="app-users2">Family Programming</Menu.Item>}
                {IsAlumni && <Menu.Item key="app-users3">Alumni</Menu.Item>} */}
                {/* <Menu.Item key="alipay">
                  <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
                    Navigation Four - Link
                  </a>
                </Menu.Item> */}
              </Menu>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center">
              {/* to display analytics rpo icon */}
              {/* <Link
                to={{
                  pathname: '/dashboardAnalytics',
                }}
                target="_blank"
              >
                <img src="images/icons/analytics-icon.svg" />
              </Link> */}

              <div class="search-box flex-fill">
                <div class="form-group has-search mb-0">
                  <Autocomplete
                    getItemValue={item => `${item[SearchOption[current].idKey]}`}
                    items={SearchOption[current].SearchOption.map(item => {
                      return {
                        label: item.DisplayName,
                        ...item,
                      };
                    })}
                    renderItem={(item, isHighlighted) => {
                      return (
                        <div
                          key={item[SearchOption[current].idKey]}
                          style={{
                            padding: '10px 5px',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            background: isHighlighted ? 'lightgray' : 'white',
                          }}
                        >
                          {/* {item.label} */}
                          {renderHTML(
                            `<img class="autoSelectImage" src=${
                              item.ProfileImgUrl ? item.ProfileImgUrl : 'images/blank-profile.png'
                            }  width="40" height="40">`,
                          )}
                          &nbsp; &nbsp;{' '}
                          <span style={{ color: 'black', fontWeight: 'bold' }}>
                            {item.DisplayName}
                          </span>
                          &nbsp;
                        </div>
                      );
                    }}
                    onChange={e => {
                      this.setState({ searchStr: e.target.value });
                      this.props.dispatch(
                        SearchOption[current].dispatchMethod({ SearchStr: e.target.value }),
                      );
                    }}
                    onSelect={value => {
                      this.props.history.push(SearchOption[current].pushKey + value);
                    }}
                    value={searchStr}
                    wrapperStyle={{ display: 'unset' }}
                    renderInput={props => (
                      <input
                        type="text"
                        {...props}
                        class="form-control search-placeholder dashboard-search"
                        placeholder="Search"
                        style={{ borderRadius: '0px', boxShadow: 'none' }}
                      />
                    )}
                    menuStyle={{
                      borderRadius: '3px',
                      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                      background: 'rgba(255, 255, 255, 0.9)',
                      padding: searchStr ? '4px 4px' : '0px 4px',
                      fontSize: '90%',
                      position: 'fixed',
                      overflow: 'auto',
                      maxHeight: '50%',
                      zIndex: 1,
                    }}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      style={{ marginTop: '-15px' }}
                    >
                      <path
                        fill="#000"
                        fill-rule="nonzero"
                        d="M6 14.203c0 1.425.371 2.828 1.073 4.06a.781.781 0 1 0 1.357-.775 6.639 6.639 0 0 1-.868-3.285 6.648 6.648 0 0 1 6.641-6.64 6.648 6.648 0 0 1 6.64 6.64 6.642 6.642 0 0 1-9.89 5.793.781.781 0 1 0-.766 1.362 8.208 8.208 0 0 0 4.016 1.048c1.972 0 3.783-.7 5.2-1.863l5.265 5.23a.779.779 0 0 0 1.105-.004.781.781 0 0 0-.004-1.105l-5.258-5.222a8.165 8.165 0 0 0 1.895-5.239C22.406 9.68 18.726 6 14.203 6S6 9.68 6 14.203z"
                      />
                    </svg>{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            {/* <div class="col-12 col-md-7">
              <div class="preference-btn d-flex align-items-center justify-content-center justify-content-md-end">
                <span class="preference-title">Population:</span>
                <div class="user-btnbox d-flex">
                  <a
                    class={`preference-link ${TabName === 'In-House' ? 'active' : ''}`}
                    title="In-House"
                    onClick={() => history.push('/dashboard/inHouse')}
                  >
                    In House
                  </a>
                  <a
                    class={`preference-link ${TabName === 'App Users' ? 'active' : ''}`}
                    title="App Users"
                    onClick={() => history.push('/dashboard/app-users')}
                  >
                    App Subscribers
                  </a>
                </div>
              </div>
            </div> */}
            <div class="row pt-3 pb-3 pt-lg-4 pb-lg-4 justify-content-center">
              <div className="col-12 col-md-5 d-none">
                {/* <div class="search-box">
                  <div class="form-group has-search">
                    <Autocomplete
                      getItemValue={item => `${item[SearchOption[TabName].idKey]}`}
                      items={SearchOption[TabName].SearchOption.map(item => {
                        return {
                          label: item.DisplayName,
                          ...item,
                        };
                      })}
                      renderItem={(item, isHighlighted) => {
                        return (
                          <div
                            key={item[SearchOption[TabName].idKey]}
                            style={{
                              margin: '5px',
                              background: isHighlighted ? 'lightgray' : 'white',
                            }}
                          >
                            {item.label}
                          </div>
                        );
                      }}
                      onChange={e => {
                        this.setState({ searchStr: e.target.value });
                        this.props.dispatch(
                          SearchOption[TabName].dispatchMethod({ SearchStr: e.target.value }),
                        );
                      }}
                      onSelect={value => {
                        this.props.history.push(SearchOption[TabName].pushKey + value);
                      }}
                      value={searchStr}
                      wrapperStyle={{ display: 'unset' }}
                      renderInput={props => (
                        <input
                          type="text"
                          {...props}
                          class="form-control shadow-sm search-placeholder"
                          placeholder="Search"
                        />
                      )}
                      menuStyle={{
                        borderRadius: '3px',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: searchStr ? '4px 4px' : '0px 4px',
                        fontSize: '90%',
                        position: 'fixed',
                        overflow: 'auto',
                        maxHeight: '50%',
                        zIndex: 1,
                      }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#000"
                          fill-rule="nonzero"
                          d="M6 14.203c0 1.425.371 2.828 1.073 4.06a.781.781 0 1 0 1.357-.775 6.639 6.639 0 0 1-.868-3.285 6.648 6.648 0 0 1 6.641-6.64 6.648 6.648 0 0 1 6.64 6.64 6.642 6.642 0 0 1-9.89 5.793.781.781 0 1 0-.766 1.362 8.208 8.208 0 0 0 4.016 1.048c1.972 0 3.783-.7 5.2-1.863l5.265 5.23a.779.779 0 0 0 1.105-.004.781.781 0 0 0-.004-1.105l-5.258-5.222a8.165 8.165 0 0 0 1.895-5.239C22.406 9.68 18.726 6 14.203 6S6 9.68 6 14.203z"
                        />
                      </svg>{' '}
                    </button>
                  </div>
                </div> */}
              </div>
              <div className="col-12 col-md-7">
                <Modal
                  visible={inviteFormModal}
                  footer={null}
                  closable={false}
                  destroyOnClose={true}
                  // onCancel={() => {
                  //   this.setState({
                  //     inviteFormModal: false,
                  //   });
                  // }}
                >
                  <SendAppInviteReduxModal
                    onCancel={() => {
                      this.setState({
                        inviteFormModal: false,
                      });
                    }}
                    // initialValues={{
                    //   CountryCode: 'USA',
                    // }}
                  />
                </Modal>
                {TabName === 'In-House' && (
                  <div class="d-flex justify-content-end">
                    {/* <div className="mr-2 position-relative">
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <>
                            {lastShiftLog && lastShiftLog.NewNote == 1 && (
                              <span class="new-badge">New</span>
                            )}
                            <button
                              type="button"
                              class="btn btn-outline-primary px-5"
                              onClick={e => {
                                onClick();
                              }}
                            >
                              Shift Log
                            </button>
                          </>
                        )}
                        ModalContent={props => (
                          <StaffLogModal
                            {...props}
                            dispatch={this.props.dispatch}
                            arrShiftLog={staffShiftLog}
                          />
                        )}
                      />
                    </div> */}
                    <div className="">
                      {/* <button
                        className="btn px-4"
                        type="button"
                        onClick={() => {
                          this.setState({
                            inviteFormModal: true,
                          });
                        }}
                      >
                        Send Mobile App Referral
                      </button> */}

                      {sessionStorage.getItem('greetingModal') == 'true'
                        ? localStorage.getItem('userName') && (
                            <Modal
                              open={greetingModal}
                              footer={null}
                              zIndex={100}
                              onCancel={this.onModalCancel}
                            >
                              <GreetingModal onModalCancel={this.onModalCancel} />
                            </Modal>
                          )
                        : null}
                      {isAchVarification.required ? (
                        <Modal
                          open={achModal}
                          footer={null}
                          zIndex={99}
                          closable={false}
                          mask
                          maskClosable={false}
                          onCancel={this.onModalCancel}
                        >
                          <ConfirmACHPayment
                            onModalCancel={this.onachModalCancel}
                            info={isAchVarification.info}
                          />
                        </Modal>
                      ) : null}
                      {this.state.firstLogin == 1 && (
                        <Modal
                          width={700}
                          zIndex={500}
                          closable={false}
                          mask
                          maskClosable={false}
                          centered
                          open={this.state.firstLoginModal}
                          footer={null}
                          onCancel={this.onLicenseModalCancel}
                        >
                          <LicenseModal onLicenseModalCancel={this.onLicenseModalCancel} />
                        </Modal>
                      )}
                    </div>
                  </div>
                )}
                {TabName === 'App Users' && (
                  <div className="d-flex justify-content-end ">
                    {/* <Link to="/sessionDashboard" class="btn btn-outline-primary mr-2" color="blue">
                      Manage Telehealth Sessions
                    </Link>
                    <Link to="/appSubscribers" class="btn" color="blue">
                      Manage App Subscribers
                    </Link> */}
                  </div>
                )}
              </div>
            </div>
            {/* {TabName === 'App Users' ? <AppUsers /> : <InHouse />} */}
            {current === 'inHouse' && (
              <InHouse DashboardMenu={InPersonDashboard && InPersonDashboard[0]} />
            )}
            {current === 'app-users' && (
              <AppUsers DashboardMenu={MobileDashboard && MobileDashboard[0]} />
            )}
            {current === 'sg-dashboard' && (
              <SMDashboard DashboardMenu={SmallGroupDashboard && SmallGroupDashboard[0]} />
            )}
            {current === 'familyProgram' && (
              <FamilyProgram DashboardMenu={SmallGroupDashboard && SmallGroupDashboard[0]} />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  student_search,
  app_subscribers_user_search,
  get_staff_shift_log,
  add_staff_shift_log,
  is_ach_validation,
  get_dashboard_menu,
  announcement_get_all,
  user_feature_flag_get,
}) => {
  const { data: studentData, error, isFetching } = student_search;
  const { data: appData } = app_subscribers_user_search;
  const { data: loadData } = get_staff_shift_log;
  const { data: menuData } = get_dashboard_menu;
  const { data: announcementData } = announcement_get_all;
  const { data: fFData } = user_feature_flag_get;
  var StaffShiftLog = [];
  let isNewAnnouncement = false;
  let isNewShiftLog = false;
  var LastShiftLog = {};
  let isStaffShiftLog = false;
  let isAchVarification = {};
  let dashboardMenu = null;
  let isShiftlog = false;
  let FeatureFlags = {};
  if (menuData && menuData.DashboardMenu) {
    dashboardMenu = menuData.DashboardMenu[0];
  }
  if (loadData && loadData.StaffShiftLog) {
    StaffShiftLog = loadData.StaffShiftLog;
    LastShiftLog = loadData.StaffShiftLog[0];
    isNewShiftLog =
      loadData.StaffShiftLog[0].NewNote < 2 && !loadData.StaffShiftLog[0].IsStaffViewed;
  }
  if (announcementData && announcementData) {
    let listData = Object.values(announcementData);
    let isNew = listData.some(item => {
      return item.DaysAgo < 2 && !item.IsStaffViewed;
    });
    isNewAnnouncement = isNew;
  }
  if (is_ach_validation.data) {
    isAchVarification = {
      required: is_ach_validation.data.success,
      info: is_ach_validation.data.result,
    };
    // isAchVarification = is_ach_validation.data.result;
    // isAchVarification = is_ach_validation.data.success;
  }
  if (add_staff_shift_log.data) {
    isStaffShiftLog = add_staff_shift_log.data.success;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    FeatureFlags = fFData.FeatureFlag[0];
    isShiftlog = fFData.FeatureFlag[0].shiftlog;
  }
  return {
    stuSearchOption: (studentData && Object.values(studentData)) || [],
    userSearchOption: (appData && Object.values(appData)) || [],
    staffShiftLog: StaffShiftLog,
    lastShiftLog: LastShiftLog,
    isStaffShiftLog,
    isAchVarification,
    dashboardMenu,
    isNewAnnouncement,
    isNewShiftLog,
    FeatureFlags,
    isShiftlog,
  };
};

export default connect(mapStateToProps)(Dashboard);
