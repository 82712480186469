import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoComplete,
  ReduxCheckbox,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import AntdModal from '../Components/CustomModal';
import Autocomplete from 'react-autocomplete';
import Loader, { Loader2 } from '../Components/Loader';
import moment from 'moment';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  submit,
} from 'redux-form';

import { newsUpsert, getNews, deleteNews, resetNewsUpsert } from '../store/actions';

import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditorComponent from 'react-ckeditor-component';
import PageTitle from '../Components/PageTitle';
class ActivityBase extends React.Component {}

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};

class Announcementform extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      newsData: {},
      isModalOpen: false,
      header: '',
      isRemoveModelOpen: false,
    };
  }

  getNewsId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var newsId = param.get('newsId') || param.get('newsId');
    if (newsId) {
      return (newsId = parseInt(newsId));
    } else {
      newsId = -1;
    }
    return newsId;
  };

  // getStringType = data => {
  //   var array = [];
  //   if (data) for (let item of data) array.push(Object.values(item)[0]);
  //   return array.toString();
  // };

  // componentWillReceiveProps({ announcementData, isInserted, isRemoved }) {
  //   if (isInserted) {
  //     this.toggle(Upsert_Sucess_Msg);
  //     this.props.dispatch(resetAnnouncementUpsert());
  //   }
  //   if (isRemoved) {
  //     this.toggle(Delete_Sucess_Msg);
  //     this.props.dispatch(resetAnnouncementDelete());
  //   }
  //   if (announcementData && announcementData.AnnouncementId && this.getAnnouncementId() > 0) {
  //     var announcement = {
  //       abstract: announcementData.Abstract,
  //       announcementId: announcementData.AnnouncementId,
  //       centerId: announcementData.CenterId,
  //       endDate: announcementData.EndDate,
  //       imageUrl: announcementData.ImageUrl,
  //       isMain: announcementData.IsMain,
  //       startDate: announcementData.StartDate,
  //       tags: announcementData.Tags,
  //       title: announcementData.Title,
  //       updatedBy: announcementData.UpdatedBy,
  //       link: announcementData.Link,
  //     };
  //     this.setState({ announcementData: announcement });
  //   }
  // }

  componentWillReceiveProps({ GetNewsData, deleteNewsData }) {
    if (GetNewsData && GetNewsData.NewsId && this.getNewsId() > 0) {
      var news = {
        title: GetNewsData.Title,
        summary: GetNewsData.Summary,
        imageUrl: GetNewsData.ImageUrl,
        htmlContent: GetNewsData.HtmlContent,
        isPublished: GetNewsData.IsPublished,
        publishedDate: GetNewsData.DatePublished,
      };
      this.setState({ newsData: news });
    }
    if (deleteNewsData != this.props.deleteNewsData && deleteNewsData == true) {
      this.props.history.push('/manage/News');
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  componentDidMount() {
    if (this.getNewsId() > 0) {
      var data = {
        News: [
          {
            NewsId: this.getNewsId(),
          },
        ],
      };
      var newsData = {
        jsonInput: JSON.stringify(data),
      };
      this.props.dispatch(getNews(newsData));
    } else {
      // this.props.dispatch(announcementGetJsonReset());
    }
  }

  // toggle = header => {
  //   this.setState(prevState => ({
  //     isModelOpen: !prevState.isModelOpen,
  //     header,
  //   }));
  // };
  // Removetoggle = () => {
  //   this.setState(prevState => ({
  //     isRemoveModelOpen: !prevState.isRemoveModelOpen,
  //   }));
  // };

  onSubmit(values) {
    //
    //alert(JSON.stringify(values));
    const {
      abstract,
      centerId,
      endDate,
      imageUrl,
      isMain,
      startDate,
      tags,
      title,
      updatedBy,
      link,
    } = values;

    // var announcementId = null;

    // var paramAnnouncementId = this.getAnnouncementId();
    // if (paramAnnouncementId && paramAnnouncementId > 0) {
    //   announcementId = paramAnnouncementId;
    // }

    var data = {
      Announcement: [
        {
          Abstract: abstract,
          // AnnouncementId: announcementId,
          CenterId: centerId,
          EndDate: endDate,
          ImageUrl: imageUrl,
          IsMain: isMain,
          StartDate: startDate,
          Tags: tags,
          Title: title,
          UpdatedBy: updatedBy,
          Link: link,
        },
      ],
    };
    var announcementData = {
      // AnnouncementId: announcementId,
      jsonInput: JSON.stringify(data),
    };
    // this.props.dispatch(announcementUpsert(announcementData));
  }

  render() {
    // const { GetNewsData } = this.props;

    return (
      <React.Fragment>
        <PageTitle TitleText="News" />
        <ReduxAnnouncementForm
          onSubmit={this.onSubmit.bind(this)}
          initialValues={this.state.newsData}
          // getAnnouncementId={this.getAnnouncementId()}
          onCancel={() => this.props.history.push('/manage/announcements')}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/announcements')
          }}
          history={this.props.history}
          htmlContent={this.state.newsData.htmlContent}
          isFetching={this.props.isFetching}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/announcements');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(announcementDelete({ AnnouncementId: this.getAnnouncementId() }));
            // this.props.history.push('/manage/announcements');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            this.props.history.push('/manage/announcements');
          }}
        />
      </React.Fragment>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: 'Type Here',
      showLoader: false,
      isModelOpen123: false,
    };
  }
  getNewsId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var newsId = param.get('newsId') || param.get('newsId');
    if (newsId) {
      return (newsId = parseInt(newsId));
    } else {
      newsId = -1;
    }
    return newsId;
  };
  toggle = header => {
    console.log('toggle triggered');
    this.setState(prevState => ({
      isModelOpen123: !prevState.isModelOpen123,
      header,
    }));
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.newsSet) {
      console.log('componentWillReceiveProps triggered');
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(resetNewsUpsert());
      // this.setState({ showLoader: false });
      // setTimeout(() => {
      //   this.props.history.push('/manage/News');
      // }, 2000);
    }
    if (nextProps.htmlContent != this.props.htmlContent) {
      this.setState({ content: nextProps.htmlContent });
    }
    // if (nextProps.someValue !== this.props.someValue) {
    //   //Perform some operation
    //   this.setState({ someState: someValue });
    //   this.classMethod();
    // }
  }
  onAnnouncementSubmit = values => {
    const { title, imageUrl, summary, isPublished, publishedDate } = values;
    var data = {
      News: [
        {
          Title: title,
          ImageUrl: imageUrl,
          Summary: summary,
          HtmlContent: this.state.content,
          IsPublished: isPublished,
          DatePublished: moment(publishedDate).format('YYYY-MM-DD'),
        },
      ],
    };
    if (this.getNewsId() > 0) {
      data.News[0].NewsId = this.getNewsId();
    }
    var newsData = {
      jsonInput: JSON.stringify(data),
    };
    this.props.dispatch(newsUpsert(newsData));
    // this.setState({ showLoader: true });
  };
  updateContent = newContent => {
    this.setState({
      content: newContent,
    });
  };

  onChange = evt => {
    // console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  onBlur(evt) {
    // console.log('onBlur event called with event info: ', evt);
  }

  afterPaste(evt) {
    // console.log('afterPaste event called with event info: ', evt);
  }

  render() {
    const { isFetching } = this.props;
    return (
      <div class="main enrollment-page  mb-5">
        <div class="container-fluid">
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            {/* <div class="col-12 col-md-4 col-lg-3">
              <h3 class="form-main-head mb-3 mb-md-0">Add a News</h3>
            </div> */}
            <div class="col-12 col-md-8 col-lg-9 text-right">
              {/* <button onClick={onCancel} class="btn black-btn mr-2">
              Cancel
            </button> */}
              {/* <button onClick={() => dispatch(submit('Announcementform'))} class="btn">
              Save
            </button> */}
            </div>
          </div>
          <form onSubmit={this.props.handleSubmit(this.onAnnouncementSubmit)}>
            {/* <Loader2 loading={this.state.showLoader} /> */}
            <Loader2 loading={isFetching} />
            <div class="mb-4 col-12 col-md-12 col-lg-12 d-flex justify-content-between">
              <h3 class="form-main-head mb-3 mb-md-0">
                {this.getNewsId() > 0 ? 'Edit News' : 'Add News'}
              </h3>
              <div className="d-flex justify-content-between">
                {/* {this.getNewsId() > 0 && ( */}
                <button
                  class="btn btn-outline-primary"
                  onClick={() => {
                    this.props.history.push('/manage/News');
                  }}
                >
                  Cancel
                </button>
                {/* )} */}
                &nbsp;
                <button type="submit" value="submit" class="btn ml-2">
                  Submit
                </button>
              </div>
            </div>

            <div class="row  mb-2">
              <div class="col-12 mb-4">
                <div class="card border-0 rounded-0 ">
                  <div class="card-body enrollment-form">
                    <div class="row pb-5 pl-3 pr-3 pt-3">
                      <div class="col-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <Field
                            name="title"
                            type="text"
                            errorMessage="Title Required"
                            component={ReduxInput}
                            validate={Validator.required}
                          />
                          <label class="placeholder-label">Title</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <Field
                            name="imageUrl"
                            type="text"
                            component={ReduxInput}
                            // validate={Validator.required}
                            fieldName={'Image URL'}
                            validate={Validator.required}
                          />
                          <label class="placeholder-label">Image URL*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-8">
                        <div class="form-group">
                          <Field name="summary" type="textarea" component={ReduxInput} />
                          <label class="placeholder-label">Summary</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="publishedDate"
                            type="text"
                            component={ReduxDateInput}
                            errorMessage="Published Date Required"
                            validate={Validator.required}
                            label={'Published Date*'}
                            // minDate={new Date(2016, 0, 1)}
                          />
                          <i class="far fa-calendar-alt form-control-icon" />
                        </div>
                      </div>
                      {/* <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="startDate"
                            type="text"
                            label="Start Date"
                            errorMessage="Start Date Line 1 Required"
                            component={ReduxDateInput}
                            // validate={Validator.required}
                          />
                        </div>
                      </div> */}
                      <div class="col-12 col-md-12 col-lg-12">
                        <CKEditorComponent
                          activeClass="p10"
                          content={this.state.content}
                          events={{
                            blur: this.onBlur,
                            afterPaste: this.afterPaste,
                            change: this.onChange,
                          }}
                        />
                      </div>
                      <div class="col-12 col-md-12 col-lg-12 mt-3">
                        <div class="form-group">
                          <Field
                            className="mr-2"
                            name="isPublished"
                            component={ReduxCheckbox}
                            fieldName={'isPublished'}
                          />
                          <label class="placeholder-label" style={{ top: '0px' }}>
                            Publish on Sober Peer
                          </label>
                        </div>
                      </div>
                      {/* <div class="col-12 col-md-12 col-lg-12">
                      <CKEditor
                        editor={ClassicEditor}
                        data="<p>Hello !</p>"
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          
                          const data = editor.getData();
                          console.log('start');
                          console.log(data);
                          console.log('start');
                        }}
                        onBlur={(event, editor) => {
                          // console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log('Focus.', editor);
                        }}
                      />
                    </div>
               */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-right">
                {false > 0 && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <a
                        class="deactivate-link"
                        data-toggle="modal"
                        data-target="#deactivate-staff-model"
                        onClick={onClick}
                      >
                        <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                      </a>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to Deactivate <br />
                            {/* <strong>{title}</strong> announcement? */}
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn black-btn"
                            onClick={() => {
                              onCancel();
                              this.props.history.push('/manage/announcements');
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              // dispatch(announcementDelete({ AnnouncementId: getAnnouncementId }));
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {this.getNewsId() > 0 && (
                <button
                  class="deactivate-link"
                  onClick={() => {
                    var data = {
                      News: [
                        {
                          NewsId: this.getNewsId(),
                        },
                      ],
                    };

                    var newsData = {
                      jsonInput: JSON.stringify(data),
                    };

                    this.props.dispatch(deleteNews(newsData));
                  }}
                >
                  <i class="fa fa-ban" aria-hidden="true" /> Delete News
                </button>
              )}
            </div>
          </form>
          <CustomModal
            isOpen={this.state.isModelOpen123}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              this.props.history.push('/manage/News');
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ news_upsert }) => {
  var { data, isFetching, error } = news_upsert;

  let newsSet;
  if (data && data.success) {
    newsSet = data.success;
    console.log(JSON.stringify(data + 'news_upsert triggered'));
  }
  return { newsSet: newsSet || false };
};

const ReduxAnnouncementForm = reduxForm({
  form: 'Announcementform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(Example)));

const selector = formValueSelector('Announcementform');

const mapStateToPropsParent = ({ get_news, delete_news }) => {
  var { data: getNewsData, isFetching, error } = get_news;
  var { data: deleteNewsData, isFetching, error } = delete_news;
  if (getNewsData) {
    getNewsData = JSON.parse(getNewsData.data);
  }
  if (deleteNewsData && deleteNewsData.success) {
    deleteNewsData = deleteNewsData.success;
  }
  return {
    GetNewsData: getNewsData && getNewsData.News ? getNewsData.News[0] : {},
    isFetching: isFetching ? isFetching : false,
    deleteNewsData: deleteNewsData ? deleteNewsData : false,
  };
};

export default withRouter(connect(mapStateToPropsParent)(Announcementform));
