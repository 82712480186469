import React from 'react';
import { connect } from 'react-redux';
import {
  getCHPatientList,
  pushSingleClaimToCH,
  CHPatientServiceSend,
} from '../../../../store/actions';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import Loader, { Loader2 } from '../../../../Components/Loader';
import PersistTableSort from '../../../../component/persistTableSort';

class CHClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelOpen: '',
      header: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(
      getCHPatientList({
        json: JSON.stringify({
          CenterClaim: [
            {
              SearchStr: '',
            },
          ],
        }),
      }),
    );
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ isInserted, ErrorMsg }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.toggle(ErrorMsg ? ErrorMsg : 'Service Line Saved Successfully');
      // this.props.dispatch(reset('ClientserviceLineForm'));
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      getCHPatientList({
        json: JSON.stringify({
          CenterClaim: [
            {
              SearchStr: value,
            },
          ],
        }),
      }),
    );
  };
  columns = [
    {
      title: 'Patient ID',
      dataIndex: 'PatientId',
      key: 'PatientId',
      sorter: (a, b) => isNumericComparer(a.PatientId, b.PatientId),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Patient Name',
      dataIndex: 'PatientName',
      key: 'PatientName',
      sorter: (a, b) => isComparer(a.PatientName, b.PatientName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Admission Date',
      dataIndex: 'LastServiceLineDate&Time',
      key: 'LastServiceLineDate&Time',
      // sorter: (a, b) =>
      //   isDateComparer(a.LastServiceLineDate & Time, b.LastServiceLineDate & Time),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last Service Line Date',
      dataIndex: 'LastServiceLineDate&Time',
      key: 'LastServiceLineDate&Time',
      // sorter: (a, b) =>
      //   isDateComparer(a.LastServiceLineDate & Time, b.LastServiceLineDate & Time),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Clearing House Patient Id',
      dataIndex: 'ClearingHousePatientId',
      key: 'ClearingHousePatientId',
      sorter: (a, b) => isNumericComparer(a.ClearingHousePatientId, b.ClearingHousePatientId),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status in Clearing House',
      dataIndex: 'Status',
      key: 'Status',
      sorter: (a, b) => isComparer(a.Status, b.Status),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Action',
      render: (text, record, index) => {
        if (!this.props.isReadOnlyUser) {
          return (
            <div class="tools-grid">
              <>
                <span
                  className="start-btn"
                  onClick={() => {
                    this.props.dispatch(
                      CHPatientServiceSend({
                        json: JSON.stringify({
                          Client: [
                            {
                              ClientId: record.ClientId,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Push Claim to CH
                </span>
              </>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];
  render() {
    const { ServiceList, header } = this.state;
    const { name, ClaimsList, isFetching, isFetchingServiceSend } = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid mt-5">
          <div className="mb-3">
            <div className="row mt-3 d-flex align-items-center">
              <div className="col-7">
                <h4 className="">Patients</h4>
              </div>
              <div className="col-md-5 col-xl-5 d-flex align-items-center mb-3">
                <div className="search-box d-inline-flex w-100">
                  <div className="form-group has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row rcm-subtab-table-block">
                <Loader2 loading={isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name={name}
                    columns={this.columns}
                    dataSource={ClaimsList}
                    size={'middle'}
                    loader={isFetchingServiceSend}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { get_CH_patient_list, get_CH_patient_service_send } = state;
  const { data, isFetching } = get_CH_patient_list;
  const { data: ServiceSendData, isFetching: isFetchingServiceSend } = get_CH_patient_service_send;

  let ClaimsList = [];
  let isInserted = false;
  let ErrorMsg = null;

  if (data && data.Claims) {
    ClaimsList = data.Claims;
  }

  if (ServiceSendData && ServiceSendData.success) {
    ErrorMsg = JSON.parse(ServiceSendData.data)[0].ErrorMsg;
    isInserted = true;
  }

  return {
    ClaimsList,
    isInserted,
    isFetching,
    isFetchingServiceSend,
    ErrorMsg,
  };
};
export default connect(mapStateToProps)(CHClients);
