import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tag, Divider, Drawer, Button } from 'antd';
import { ReduxSelect } from '../../component/custom-redux-components';
import { getDate, stringDate } from '../../util/date';
import DatePicker from 'react-datepicker';
import Autocomplete from 'react-autocomplete';
import { listCombos, surveyAssign, getStudentsSearch } from '../../store/actions';
import SurveysQuestionPreview from '../customScreens/surveyQuestionPreview';
import CustomModal from '../../core/modal';

const Close = props => (
  <span {...props}>
    <svg
      height="14"
      width="14"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      class="css-19bqh2r"
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
    </svg>
  </span>
);
class SurveysModalContent extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      dueDate: '',
      surveyQuestionCategoryId: '',
      studentData: [],
      isDrawerOpen: false,
      isModalOpen: false,
    };
  }
  toggleSuccess = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  componentDidMount() {
    this.props.dispatch(getStudentsSearch({ SearchStr: this.getStudentId() }));
    this.props.dispatch(
      listCombos({
        comboTypes: 'SurveyOptions,TaskOptions,ExitReason,Courses,NoteType',
        StudentId: this.getStudentId(),
      }),
    );
  }
  componentWillReceiveProps({ searchOption, isSurveyAssign }) {
    if (
      searchOption.length > 0 &&
      JSON.stringify(searchOption) != JSON.stringify(this.props.searchOption)
    ) {
      this.setState({
        studentData: searchOption.filter(item => item.StudentId === this.getStudentId()),
      });
    }
    if (isSurveyAssign && isSurveyAssign != this.props.isSurveyAssign) {
      this.toggleSuccess('Survey Assigned Successfully.');
    }
  }
  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }
  handleChange = date => {
    this.setState({
      dueDate: date,
      isDueDateReq: false,
    });
  };
  handelAssignSurvey = () => {
    let { dueDate, surveyQuestionCategoryId, searchStr, studentData } = this.state;
    if (!dueDate || !surveyQuestionCategoryId || !studentData.length > 0) {
      if (!surveyQuestionCategoryId) {
        this.setState({
          isCategoryReq: true,
        });
      } else if (!dueDate) {
        this.setState({
          isDueDateReq: true,
        });
      } else if (!studentData.length > 0) {
        this.setState({
          isStudentDataReq: true,
        });
      }
    } else {
      var surveyQuestionCategoryIds = [];
      for (var item of surveyQuestionCategoryId) {
        surveyQuestionCategoryIds.push(item.Value);
      }
      // this.props.onOk();
      this.onAssignSurvey(
        surveyQuestionCategoryIds.toString(),
        dueDate,
        studentData.map(item => item.StudentId).toString(),
      );
    }
  };
  showDrawer = () => {
    this.setState({
      isDrawerOpen: true,
    });
  };
  onClose = () => {
    this.setState({
      isDrawerOpen: false,
    });
  };
  onAssignSurvey = (surveyQuestionCategoryIds, dueDate, studentData) => {
    this.state.requestData = true;
    var item = {
      studentId: this.getStudentId(),
      surveyQuestionCategoryIds,
      studentIds: studentData,
      dueDate: stringDate(dueDate),
    };
    this.props.dispatch(surveyAssign(item));
  };
  render() {
    const { dueDate, surveyQuestionCategoryId, searchStr, studentData, isDrawerOpen } = this.state;
    const { onOk, onCancel, surveyOptions, searchOption } = this.props;
    const me = this;
    const ExampleCustomInput = ({ value, onClick }) => (
      <input
        type="text"
        class="form-control datepicker"
        placeholder="Survey due date"
        value={value}
        onClick={onClick}
      />
    );
    return (
      <form>
        <div class="modal-content border-0">
          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
            <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
              &times;
            </button>
            <h4 class="modal-title col-12">Assign Survey</h4>
          </div>
          <div class="modal-body px-5">
            <div class="form-group isMultiHeight">
              <ReduxSelect
                options={surveyOptions}
                isMulti
                value={surveyQuestionCategoryId}
                onChange={value =>
                  this.setState({
                    surveyQuestionCategoryId: value,
                    msg: false,
                    isCategoryReq: false,
                  })
                }
                placeholder="Select"
                allowDefault={true}
              />
              {this.state.isCategoryReq && <span className="error-text">Task Name Required</span>}
            </div>
            <div class="form-group">
              <DatePicker
                selected={dueDate}
                onChange={this.handleChange}
                className="form-control datepicker"
                minDate={new Date()}
                customInput={<ExampleCustomInput />}
              />
              <i class="far fa-calendar-alt form-control-icon" />
              {this.state.isDueDateReq && <span className="error-text">Due Date Required</span>}
            </div>
            <div className="form-group">
              <Autocomplete
                getItemValue={item => `${item.StudentId}`}
                items={searchOption.map(item => {
                  return {
                    label: item.DisplayName,
                    ...item,
                  };
                })}
                renderItem={(item, isHighlighted) => {
                  return (
                    <div
                      key={item.StudentId}
                      style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                    >
                      {item.label}
                    </div>
                  );
                }}
                // onChange={e => {
                //   this.setState({ searchStr: e.target.value });
                //   this.props.dispatch(getStudentsSearch({ SearchStr: e.target.value }));
                // }}
                // onSelect={value => {
                //   const st = [...this.state.studentData];
                //   for (let item of studentData) if (item.StudentId == value) return;
                //   for (let item of searchOption) if (item.StudentId == value) st.push(item);

                //   this.setState({ studentData: st, searchStr: '', isStudentDataReq: false });
                //   this.props.dispatch(getStudentsSearch({ SearchStr: '' }));
                //   // this.props.history.push(`/studentProfile/${value}`);
                // }}
                // value={searchStr}
                wrapperStyle={{ display: 'unset' }}
                renderInput={props => {
                  return (
                    <div
                      {...props}
                      style={{
                        borderBottom: 'solid 1px rgba(149, 149, 149, 0.5)',
                        paddingBottom: '2px',
                      }}
                    >
                      <ul
                        style={{ height: 40, overflowY: 'auto', padding: 'unset', margin: 'unset' }}
                        // onClick={() => me.input.current.focus()}
                      >
                        {studentData.map((item, index) => (
                          <li className="multi-auto">
                            {item.DisplayName}
                            <Close
                            // onClick={event => {
                            //   event.preventDefault();
                            //   studentData.splice(index, 1);
                            //   me.setState({ studentData });
                            // }}
                            />
                          </li>
                        ))}
                        <li className="multi-auto">
                          <input
                            type="text"
                            onClick={event => {
                              event.preventDefault();
                            }}
                            ref={me.input}
                            value={searchStr}
                            style={{
                              border: 'none',
                              width: (searchStr && searchStr.length * 13) || '0.75em',
                              maxWidth: '100%',
                              backgroundColor: 'inherit',
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                  );
                }}
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 4px',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'auto',
                  maxHeight: '50%',
                  zIndex: 1,
                  width: 'inherit',
                }}
              />
              {this.state.isStudentDataReq && (
                <span className="error-text">Please select atleast one student</span>
              )}
            </div>
            <div class="form-group text-right">
              {surveyQuestionCategoryId && surveyQuestionCategoryId.length > 0 && (
                <span
                  style={{ color: 'rgb(74, 176, 254)', cursor: 'pointer' }}
                  onClick={this.showDrawer}
                >
                  View selected Survey Questions
                </span>
              )}
            </div>
          </div>

          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
              onClick={onCancel}
            >
              Close
            </button>
            <button
              type="button"
              class="btn "
              onClick={this.handelAssignSurvey}
              // onClick={() => {
              //   if (surveyQuestionCategoryId.length > 0) {
              //     var surveyQuestionCategoryIds = [];
              //     for (var item of surveyQuestionCategoryId) {
              //       surveyQuestionCategoryIds.push(item.Value);
              //     }
              //     onOk();
              //     onAssignSurvey(
              //       surveyQuestionCategoryIds.toString(),
              //       dueDate,
              //       studentData.map(item => item.StudentId).toString(),
              //     );
              //   } else {
              //     this.setState({ msg: true });
              //   }
              // }}
            >
              Assign
            </button>
          </div>
        </div>
        {isDrawerOpen && (
          <Drawer
            className="custom-drawer"
            title="View selected Survey Questions"
            placement="right"
            onClose={this.onClose}
            visible={isDrawerOpen}
            width="600"
          >
            <SurveysQuestionPreview surveyQuestionCategoryId={surveyQuestionCategoryId} />
          </Drawer>
        )}
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.setState({
              isModalOpen: false,
              header: '',
            });
            this.props.onOk();
          }}
          yesText="Close"
          hideCancel={true}
        />
      </form>
    );
  }
}

export default SurveysModalContent = connect(({ student_search, combos, survey_assign }) => ({
  surveyOptions: (combos.data && combos.data.SurveyOptions) || [],
  searchOption: (student_search.data && Object.values(student_search.data)) || [],
  isSurveyAssign: survey_assign.data && survey_assign.data.success,
}))(withRouter(SurveysModalContent));
