import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import Axios from 'axios';
import { Button } from 'antd';
// import ListRenderer from '../../../../Components/listRenderer';
// import ViewScreeningAnswer from '../../components/ViewScreeningAnswer';
// import FlaggedStatus from '../../components/FlaggedStatus';

import ListRenderer from '../../../Components/listRenderer';
import ViewScreeningAnswer from '../../EnterpriseClient/components/ViewScreeningAnswer';
import FlaggedStatus from '../../EnterpriseClient/components/FlaggedStatus';
// import { studentBanner, listSurveyQuestionAssessment } from '../../../../store/actions';
import { studentBanner, listSurveyQuestionAssessment } from '../../../store/actions';
import renderHTML from 'react-render-html';
import downloadPdf from '../../../util/downloadPDF';

function getDefaultTab(props) {
  const query = new URLSearchParams(props.location.search);
  let tabName = query.get('tab_name');
  if (tabName === 'notes') {
    return 0;
  } else if (tabName === 'surveys') {
    return 1;
  } else if (tabName === 'tasks') {
    return 2;
  } else if (tabName === 'courses') {
    return 3;
  } else if (tabName === 'growthplan') {
    return 4;
  }
}
class ViewAnswerTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      tabList: null,
      isDocFetching: false,
      //   activeKey: getDefaultTab(props),
    };
  }
  componentDidMount() {
    if (this.props.PreItakeSurveyId) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: this.props.ClientId,
                SurveyId: this.props.PreItakeSurveyId,
                SurveyType: 'ClientScreening',
              },
            ],
          }),
        }),
      );
    }
  }
  componentWillReceiveProps({ surveyQuestion, bannerInfo }) {
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      let tabList = [];
      surveyQuestion.SurveyQuestion &&
        surveyQuestion.SurveyQuestion.map(survey => {
          if (survey.Questions && survey.Questions.length > 0) {
            tabList.push({
              Name: renderHTML(survey.Tab),
              ExtraComponent: props => {
                return (
                  <div className="">
                    <ViewScreeningAnswer Questions={survey.Questions} />
                  </div>
                );
              },
            });
          }
        });

      this.setState({ SurveyQuestionList: surveyQuestion, tabList: tabList });
    }
    if (bannerInfo && JSON.stringify(bannerInfo) != JSON.stringify(this.props.bannerInfo)) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                StudentId: bannerInfo.ClientId,
                SurveyId: bannerInfo.PreItakeSurveyId,
                SurveyType: 'ClientScreening',
              },
            ],
          }),
        }),
      );
    }
  }
  viewHistory = () => {
    this.props.viewHistory('3', 42, '7_ClientScreening');
  };

  printClientScreeningPdf = async () => {
    if (this.props.PreItakeSurveyId) {
      let data = {
        JsonInput: JSON.stringify({
          ClientScreeing: [
            {
              StudentId: this.props.ClientId,
              SurveyId: this.props.PreItakeSurveyId,
            },
          ],
        }),
      };
      this.setState({
        isDocFetching: true,
      });
      const response = await Axios.post('Survey/ScreeningQuestionExport', data);

      if (response.status == 200) {
        const res = await fetch(response.data.ExportUrl);
        // const res = await fetch(
        //   'https://drdevattach.blob.core.windows.net/doc-uploads/05fc0a14-378e-4dac-943f-2b6cf750c00b.pdf?sp=r&st=2023-07-27T08:23:26Z&se=2023-07-28T16:23:26Z&spr=https&sv=2022-11-02&sr=b&sig=mWwBiJECqfy0HRRH2Tp78fihaM39Hr9aNeDbkbLTugM%3D',
        // );
        downloadPdf(res, '')
        this.setState({
          isDocFetching: false,
        });
      } else if (response.status != 200) {
        this.setState({
          isDocFetching: false,
        });
      }
    }
  };
  render() {
    const {
      bannerInfo,
      currentTab,
      tabsCount,
      handleSubmit,
      disable,
      onLastSubmit,
      handleSubmitAndNext,
      goToPreviuos,
      style,
    } = this.props;
    const { tabList, isDocFetching } = this.state;
    // const list = [
    //   {
    //     Name: 'Legal',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Physical',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Medical',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'DNR',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     Name: 'Financial',
    //     ExtraComponent: props => {
    //       return (
    //         <div className="d-flex">
    //           <ViewScreeningAnswer />
    //         </div>
    //       );
    //     },
    //   },
    // ];

    return (
      <form
        className=""
        onSubmit={handleSubmit(data => {
          handleSubmitAndNext(data, currentTab, tabsCount);
        })}
        style={style}
      >
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-6 mt-2">
            <div className="d-flex">
              <h6>Client Screening</h6>
              <span
                onClick={this.viewHistory}
                style={{
                  color: '#009EF7',
                  cursor: 'pointer',
                  fontSize: '14px',
                  margin: '0px 5px',
                }}
              >
                {this.props.ClientId > 0 && 'View History'}
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 d-flex justify-content-end align-items-center">
            {tabList && tabList.length > 0 && (
              <Button
                loading={isDocFetching}
                icon={<img style={{ marginRight: '5px' }} src="images/export_pdf.svg" />}
                onClick={() => this.printClientScreeningPdf()}
                className="btn save-button export-pdf"
              >
                Export as PDF
              </Button>
            )}
            {tabList && tabList.length > 0 && (
              <div className="p-3">
                <FlaggedStatus FlaggedStatus={bannerInfo} />
              </div>
            )}
          </div>
        </div>
        <div className="forms show-tabs center-tabs">
          {tabList && tabList.length > 0 && (
            <ListRenderer
              list={tabList}
              activeKey={this.state.activeKey}
              // getActiveKey={activeKey => this.setState({ activeKey })}
            />
          )}
        </div>
        <div class="col-sm-12 text-center mt-5">
          {(!tabList || (tabList && tabList.length == 0)) && <h6>No Data Available</h6>}
        </div>
        <div class="row pagination-button">
          <div class="col-12 text-right">
            {currentTab > 0 && (
              <button
                onClick={goToPreviuos}
                type="button"
                class="btn btn-outline-primary btn-eForm-Prev mr-2"
                disabled={disable}
              >
                Previous
              </button>
            )}
            {currentTab + 1 != tabsCount && (
              <button
                type="submit"
                // onClick={handleNext}
                class="btn btn-eForm-Next"
                disabled={disable}
              >
                Next
              </button>
            )}
            {currentTab + 1 == tabsCount && (
              <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}
export default connect(state => {
  const { student_banner, list_survey_question_assessment } = state;

  const { data, isFetching, error } = student_banner;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = list_survey_question_assessment;

  let bannerInfo = null;
  let preItakeSurveyId = null;
  let surveyQuestion;

  if (data) {
    bannerInfo = data || {};
    preItakeSurveyId = data.PreItakeSurveyId;
  }
  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }
  return {
    bannerInfo,
    preItakeSurveyId,
    surveyQuestion,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(ViewAnswerTab),
);

const selector = formValueSelector('wizard');
