import React from 'react';
import { Tabs, Modal, Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import Loader, { Loader2 } from '../../../Components/Loader';
import { getDashboardDailySchedule } from '../../../store/actions';
import IndividualAppointmentModal from '../appointments/individualAppointmentModal';
import GroupAppointmentModal from '../appointments/groupAppointmentModal';
import PersistTableSort from '../../../component/persistTableSort';

class DailySchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventModalView: false,
      groupModalView: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(getDashboardDailySchedule({}));
  }
  componentWillReceiveProps({ PractitionerValue }) {
    if (PractitionerValue && PractitionerValue != this.props.PractitionerValue) {
      this.props.dispatch(
        getDashboardDailySchedule({
          Json: JSON.stringify({
            Client: [
              {
                PractitionerId: PractitionerValue,
              },
            ],
          }),
        }),
      );
    }
  }
  render() {
    const tempColumns = [
      {
        title: 'Name / Group',
        dataIndex: 'ClientName',
        key: 'ClientName',
        // fixed: 'left',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              record.AppointmentType === 'Group'
                ? this.setState({
                    groupModalView: true,
                    appointmentRecord: record,
                  })
                : this.setState({
                    eventModalView: true,
                    appointmentRecord: record,
                  });
            },
          };
        },
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ color: '#46b4fe', cursor: 'pointer' }}>{text}</span>
            </div>
          );
        },
      },
      {
        title: 'Appointment Type',
        dataIndex: 'AppointmentType',
        key: 'AppointmentType',
        sorter: (a, b) => isComparer(a.AppointmentType, b.AppointmentType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Primary Provider',
        dataIndex: 'PrimaryProvider',
        key: 'PrimaryProvider',
        sorter: (a, b) => isComparer(a.PrimaryProvider, b.PrimaryProvider),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Time',
        dataIndex: 'AppointmentTime',
        key: 'AppointmentTime',
        sorter: (a, b) => isComparer(a.AppointmentTimeForSort, b.AppointmentTimeForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        // width: 290,
        sorter: (a, b) => isComparer(a.Status, b.Status),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },

      //   {
      //     title: 'Duration',
      //     dataIndex: 'DurationInMin',
      //     key: 'DurationInMin',
      //     sorter: (a, b) => isComparer(a.DurationInMin, b.DurationInMin),
      //     sortDirections: ['descend', 'ascend'],
      //     render: (text, record) => {
      //       return (
      //         <div className="">
      //           <span className={record.IsBold ? 'font-weight-bold' : ''}>
      //             {text && timeConvert(text)}
      //           </span>
      //         </div>
      //       );
      //     },
      //   },
    ];
    const { ListData, isFetching, isReadOnlyUser } = this.props;
    const { groupModalView, eventModalView, appointmentRecord } = this.state;

    return (
      <div class="row">
        <Loader2 loading={isFetching} />
        <div className="col-12">
          <h5>Daily Schedule</h5>
        </div>
        <div className="col-12 custom-header">
          <PersistTableSort
            name="daily-schedule-list"
            // bordered
            columns={tempColumns}
            dataSource={ListData}
            size={'middle'}
            // scroll={{ x: 2150, y: 380 }}
            pagination={{
              itemRender: (page, type, originalElement) => {
                return (
                  <div className="dataTables_wrapper no-footer">
                    <div className="dataTables_paginate paging_simple_numbers">
                      {type == 'prev' ? (
                        <a className="paginate_button previous">Previous</a>
                      ) : type == 'next' ? (
                        <a className="paginate_button next">Next</a>
                      ) : (
                        <a className="paginate_button ">{page}</a>
                      )}
                    </div>
                  </div>
                );
              },
              showSizeChanger: true,
              // hideOnSinglePage: true,
            }}
          />
        </div>

        {eventModalView && (
          <Modal
            zIndex={100}
            visible={eventModalView}
            footer={null}
            closable={false}
            width={700}
            destroyOnClose={true}
          >
            <IndividualAppointmentModal
              isReadOnlyUser={isReadOnlyUser}
              AppointmentId={appointmentRecord.AppointmentId}
              AppointmentRecord={appointmentRecord}
              onCancel={() => {
                // this.props.dispatch(
                //   getClientAppointment({
                //     Json: JSON.stringify({
                //       Appointment: [
                //         {
                //           ClientId: this.getClientId(),
                //         },
                //       ],
                //     }),
                //   }),
                // );
                this.setState({
                  eventModalView: false,
                });
              }}
            />
          </Modal>
        )}
        {groupModalView && (
          <Modal
            zIndex={100}
            visible={groupModalView}
            footer={null}
            closable={false}
            width={700}
            destroyOnClose={true}
          >
            <GroupAppointmentModal
              isReadOnlyUser={isReadOnlyUser}
              GroupAppointmentId={appointmentRecord.GroupAppointmentId}
              AppointmentRecord={appointmentRecord}
              onCancel={() => {
                // this.props.dispatch(
                //   getGroupAppointment({
                //     json: JSON.stringify({
                //       GroupAppointment: [
                //         {
                //           GroupId: this.getGroupId(),
                //         },
                //       ],
                //     }),
                //   }),
                // );
                this.setState({
                  groupModalView: false,
                });
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ get_dashboard_daily_schedule }) => {
  const { data, isFetching } = get_dashboard_daily_schedule;

  let List = [];
 

  if (data && data.data) {
    List = data.data || [];
  }
    
  return {
    ListData: List,
    isFetching,
  };
};
export default withRouter(connect(mapStateToProps)(DailySchedule));
