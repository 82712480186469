import axios from 'axios';
import { URLs } from './configuration';
import { getAppSubscribers } from '../actions';

axios.defaults.baseURL = 'api/v1/';

// Comment Above line and use below line for working with live URL.
// axios.defaults.baseURL = 'https://drdev.azurewebsites.net/api/v1/';

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
//axios.defaults.headers.common['Authorization'] = 'Bearer CfDJ8FnfxrGHJRpLl2eA0rhporHiOsGOPueK_snedUxMIAZ7JCU3TR6Ipfn_HHy36FQEB-ehGAscEOE4hsj5bXHGKJC4cJRpt3Tn_2KmGSASzma_UmC4D0K5ncp0Bxp1ClSpTI2JEFPLknG8NvGaKsMQNXEOuigM8GSLfmCSSVVsk8iaoertvd72ww2WkIdPQhaN2irpU5ksPAJUJMBc5yYUZPsmHiR09WHA9E97L8PPIwI4kaWBcq8GEOTk-1y6IKT-5Q1muTzAJLDHyryQ80DuPxj21Vn3c6R0LqCc2Og9OAhh3eApVPzkJLb42w0L54E68raXcS3HhXOCaVBtLeaUVta-zEC4VJC_5q5t6INT8ENir25bTiEuDN4z7gCc2bhJ3rEgPLMJLsm3X13ZFLBgcEIS_NwBhjt6az1tTslaDPuVaNwZxAOXZvG7PI31oqCmLYcDtKxLilkgbJAQqzicbX5tiDTrIPtyR2BGoSW9XYLu8yyWTcSPH19S5MW_i_AA5YqzST5BJonit65z4lBIRZxBDgfExJ99N-3n08p_-Wn5ZStvv4fzkIafVbzzcAfRbGKk9CKoYmh3VEN9xY3-CoFLTjaI7KUoQmlWQSNAmr92zfW4TdhT-HlSL5v5OExNysxMsDLaMcbGbGP_KyAHDJdM7OPdkMpg92Npo-7PYRaT';

const onRequestSuccess = config => {
  console.debug('request success', config);
  var tokenObject = localStorage.getItem('dr:token');
  if (tokenObject) {
    const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
  }
  return config;
};

const onRequestFail = error => {
  console.debug('request error', error);
  return Promise.reject(error);
};

axios.interceptors.request.use(onRequestSuccess, onRequestFail);

const onResponseSuccess = response => {
  console.debug('response success', response);
  if (response && response.data) {
    if (Object.keys(response.data).indexOf('success') > -1) {
      if (!response.data.success && response.data.result) {
        alert(response.data.result);
      }
    }
  }
  return response;
};
const onResponseFail = error => {
  console.debug('response error', error);
  return Promise.reject(error);
};

axios.interceptors.response.use(onResponseSuccess, onResponseFail);

export default class API {
  static login = param => {
    const defaultOptions = {
      // baseURL: 'https://drdev.azurewebsites.net/',
      baseURL: '',
      timeout: 36000,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    };
    let instance = axios.create(defaultOptions);
    //var loginData = `?username=` + param.username + `&password=` + param.password + `&grant_type=` + param.grant_type;
    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');
    console.debug(formBody);
    return instance.post('connect/token', formBody);
  };

  static refreshToken = param => {
    const defaultOptions = {
      // baseURL: 'https://drdev.azurewebsites.net/',
      baseURL: '',
      timeout: 36000,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    };
    let instance = axios.create(defaultOptions);
    //var loginData = `?username=` + param.username + `&password=` + param.password + `&grant_type=` + param.grant_type;
    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');
    console.debug(formBody);

    return instance.post('connect/token', formBody);
  };

  static listStudents = param => {
    return axios.post(URLs.listStudents, param);
  };

  static addStudent = param => {
    return axios.post(URLs.addStudent, param);
  };

  static addStudentNote = param => {
    return axios.post(URLs.addStudentNote, param);
  };

  static studentNoteListGet = param => {
    return axios.post(URLs.studentNoteListGet, param);
  };

  static getStudent = param => {
    return axios.post(URLs.getStudent, param);
  };

  static getDashboard = () => {
    return axios.post(URLs.getDashboard);
  };

  static getCourses = param => {
    return axios.post(URLs.listCourses, param);
  };

  static upsertStudentTask = param => {
    return axios.post(URLs.upsertStudentTask, param);
  };

  static courseAssign = param => {
    return axios.post(URLs.courseAssign, param);
  };

  static studentMarkComplete = param => {
    return axios.post(URLs.studentMarkComplete, param);
  };

  static studentUnAssign = param => {
    return axios.post(URLs.studentUnAssign, param);
  };

  static studentAddExistingTask = param => {
    return axios.post(URLs.studentAddExistingTask, param);
  };

  static upsertStudentPin = param => {
    return axios.post(URLs.upsertStudentPin, param);
  };

  static validateStudentPin = param => {
    return axios.post(URLs.validateStudentPin, param);
  };

  static studentDischarge = param => {
    return axios.post(URLs.studentDischarge, param);
  };

  static studentReinstate = param => {
    return axios.post(URLs.studentReinstate, param);
  };

  static getSurveys = param => {
    return axios.post(URLs.getSurveys, param);
  };

  static surveyAssign = param => {
    return axios.post(URLs.surveyAssign, param);
  };

  static surveyGetToAssign = param => {
    return axios.post(URLs.surveyGetToAssign, param);
  };

  static getSurveyQuestionMultiple = param => {
    return axios.post(URLs.getSurveyQuestionMultiple, param);
  };

  static surveyAnswerUpsert = param => {
    return axios.post(URLs.surveyAnswerUpsert, param);
  };

  static listCombos = param => {
    return axios.post(URLs.listCombos, param);
  };

  static listFilterCombo = param => {
    return axios.post(URLs.listFilterCombo, param);
  };

  static listSmartCombos = param => {
    return axios.post(URLs.listSmartCombos, param);
  };

  static listTodo = () => {
    return axios.post(URLs.listTodo);
  };

  static listToDoByStudent = param => {
    return axios.post(URLs.listTodoByStudent, param);
  };

  static listActivity = () => {
    return axios.post(URLs.listActivity);
  };

  static listActivityByStudent = param => {
    return axios.post(URLs.listActivityByStudent, param);
  };

  static addTodo = param => {
    return axios.post(URLs.addTodo, param);
  };

  static studentTaskUnAssign = param => {
    return axios.post(URLs.studentTaskUnAssign, param);
  };

  static studentTaskMarkComplete = param => {
    return axios.post(URLs.studentTaskMarkComplete, param);
  };

  static surveyTaskMarkInComplete = param => {
    return axios.post(URLs.surveyTaskMarkInComplete, param);
  };

  static forgetPassword = param => {
    return axios.post(URLs.forgetPassword, param);
  };

  static resetPassword = param => {
    return axios.post(URLs.resetPassword, param);
  };

  static getReportData = () => {
    return axios.post(URLs.getReportData);
  };

  static adminstrationListCourse = param => {
    return axios.post(URLs.adminstrationListCourse, param);
  };

  static adminstrationListStaff = param => {
    return axios.post(URLs.adminstrationListStaff, param);
  };

  static adminstrationListCenter = param => {
    return axios.post(URLs.adminstrationListCenter, param);
  };

  static adminstrationGetCourse = param => {
    return axios.post(URLs.adminstrationGetCourse, param);
  };

  static getCenterFieldByCenter = param => {
    return axios.post(URLs.getCenterFieldByCenter, param);
  };
  static adminstrationCourseUpsert = param => {
    return axios.post(URLs.adminstrationCourseUpsert, param);
  };

  static adminstrationCourseDelete = param => {
    return axios.post(URLs.adminstrationCourseDelete, param);
  };

  static adminstrationGetStaff = param => {
    return axios.post(URLs.adminstrationGetStaff, param);
  };

  static adminstrationStaffUpsert = param => {
    return axios.post(URLs.adminstrationStaffUpsert, param);
  };

  static adminstrationStaffDelete = param => {
    return axios.post(URLs.adminstrationStaffDelete, param);
  };

  static adminstrationGetCenter = param => {
    return axios.post(URLs.adminstrationGetCenter, param);
  };

  static adminstrationCenterUpsert = param => {
    return axios.post(URLs.adminstrationCenterUpsert, param);
  };

  static adminstrationCenterDelete = param => {
    return axios.post(URLs.adminstrationCenterDelete, param);
  };

  static getStudentSurveyDashboardData = param => {
    return axios.post(URLs.getStudentSurveyDashboardData, param);
  };

  static announcementGetAll = param => {
    return axios.post(URLs.announcementGetAll, param);
  };

  static announcementGetJson = param => {
    return axios.post(URLs.announcementGetJson, param);
  };

  static announcementUpsert = param => {
    return axios.post(URLs.announcementUpsert, param);
  };

  static announcementDelete = param => {
    return axios.post(URLs.announcementDelete, param);
  };

  static logDevice = param => {
    return axios.post(URLs.logDevice, param);
  };

  static logError = param => {
    return axios.post(URLs.logError, param);
  };

  static updateStaffEmail = param => {
    return axios.post(URLs.updateStaffEmail, param);
  };

  static updateStaffPassword = param => {
    return axios.post(URLs.updateStaffPassword, param);
  };

  static updateStaffCenter = param => {
    return axios.post(URLs.updateStaffCenter, param);
  };

  static loadDefaultValues = param => {
    return axios.post(URLs.loadDefaultValues, param);
  };

  static loadMenu = param => {
    return axios.post(URLs.loadMenu, param);
  };

  static logOut = param => {
    return axios.post(URLs.logOut, {});
  };

  static surveyAnswersGet = param => {
    return axios.post(URLs.surveyAnswersGet, param);
  };

  static getStudentProfileDashboardData = param => {
    return axios.post(URLs.getStudentProfileDashboardData, param);
  };

  static getStudentsSearch = param => {
    return axios.post(URLs.getStudentsSearch, param);
  };

  static getUserRole = param => {
    return axios.post(URLs.getUserRole, param);
  };

  static getListJobTitle = param => {
    return axios.post(URLs.getListJobTitle, param);
  };

  static updateJobTitle = param => {
    return axios.post(URLs.updateJobTitle, param);
  };

  static updateRole = param => {
    return axios.post(URLs.updateRole, param);
  };

  static deleteRole = param => {
    return axios.post(URLs.deleteRole, param);
  };

  static deleteJobTitle = param => {
    return axios.post(URLs.deleteJobTitle, param);
  };

  static updateRolePermissions = param => {
    return axios.post(URLs.updateRolePermissions, param);
  };

  static updateJobTitleSync = param => {
    return axios.post(URLs.updateJobTitleSync, param);
  };

  static getContract = param => {
    return axios.post(URLs.getContract, param);
  };

  static upsertContract = param => {
    return axios.post(URLs.upsertContract, param);
  };

  static deleteContract = param => {
    return axios.post(URLs.deleteContract, param);
  };

  static getContractTemplate = param => {
    return axios.post(URLs.getContractTemplate, param);
  };

  static upsertContractTemplate = param => {
    return axios.post(URLs.upsertContractTemplate, param);
  };

  static getStudentContractTemplate = param => {
    return axios.post(URLs.getStudentContractTemplate, param);
  };

  static createContractFromTemplate = param => {
    return axios.post(URLs.createContractFromTemplate, param);
  };

  static addFavoriteTemplate = param => {
    return axios.post(URLs.addFavoriteTemplate, param);
  };

  static removeFavoriteTemplate = param => {
    return axios.post(URLs.removeFavoriteTemplate, param);
  };

  static uploadStaffImage = param => {
    return axios.post(URLs.uploadStaffImage, param);
  };

  static closeContract = param => {
    return axios.post(URLs.closeContract, param);
  };

  static userFeedback = param => {
    return axios.post(URLs.userFeedback, param);
  };

  static isLicenseAvailable = param => {
    return axios.post(URLs.isLicenseAvailable, param);
  };

  static getDashboardLoadAppUsers = param => {
    return axios.post(URLs.getDashboardLoadAppUsers, param);
  };

  static getLoadUserInfo = param => {
    return axios.post(URLs.getLoadUserInfo, param);
  };

  static getValidateImage = param => {
    return axios.post(URLs.getValidateImage, param);
  };

  static getLicenseActivites = param => {
    return axios.post(URLs.getLicenseActivites, param);
  };

  static getCurrentLicenses = param => {
    return axios.post(URLs.getCurrentLicenses, param);
  };

  static getLicensesInvoice = param => {
    return axios.post(URLs.getLicensesInvoice, param);
  };

  static getLicensesReceipt = param => {
    return axios.post(URLs.getLicensesReceipt, param);
  };

  static addLicenses = param => {
    return axios.post(URLs.addLicenses, param);
  };

  static startFreeLicense = param => {
    return axios.post(URLs.startFreeLicense, param);
  };

  static getPaymentInfo = param => {
    return axios.post(URLs.getPaymentInfo, param);
  };

  static updatePaymentMethode = param => {
    return axios.post(URLs.updatePaymentMethode, param);
  };

  static isAchValidation = param => {
    return axios.post(URLs.isAchValidation, param);
  };

  static getBillingInfo = param => {
    return axios.post(URLs.getBillingInfo, param);
  };

  static achValidate = param => {
    return axios.post(URLs.achValidate, param);
  };

  static addNewApplicant = param => {
    return axios.post(URLs.addNewApplicant, param);
  };

  static listAppSubscribers = param => {
    return axios.post(URLs.listAppSubscribers, param);
  };

  static getAppSubscriberProfileDashboard = param => {
    return axios.post(URLs.getAppSubscriberProfileDashboard, param);
  };

  static getAppSubscribersProfile = param => {
    return axios.post(URLs.getAppSubscribersProfile, param);
  };

  static getAppSubscribersChat = param => {
    return axios.post(URLs.getAppSubscribersChat, param);
  };

  static getAppSubscriberChatCoach = param => {
    return axios.post(URLs.getAppSubscriberChatCoach, param);
  };

  static getAppSubscriberProfileActivities = param => {
    return axios.post(URLs.getAppSubscriberProfileActivities, param);
  };

  static addAppSubscribersSurvey = param => {
    return axios.post(URLs.addAppSubscribersSurvey, param);
  };

  static addAppSubscriberNote = param => {
    return axios.post(URLs.addAppSubscriberNote, param);
  };

  static addAppSubscriberTask = param => {
    return axios.post(URLs.addAppSubscriberTask, param);
  };

  static deleteAppSubscriberTask = param => {
    return axios.post(URLs.deleteAppSubscriberTask, param);
  };

  static deleteAppSubscribersSurvey = param => {
    return axios.post(URLs.deleteAppSubscribersSurvey, param);
  };

  static appSubscribersUserSearch = param => {
    return axios.post(URLs.appSubscribersUserSearch, param);
  };

  static appSubscriberCompleteTask = param => {
    return axios.post(URLs.appSubscriberCompleteTask, param);
  };

  static addAppSubscriberCourse = param => {
    return axios.post(URLs.addAppSubscriberCourse, param);
  };

  static appSubscribersupdateCase = param => {
    return axios.post(URLs.appSubscribersupdateCase, param);
  };

  static deleteAppSubscriberCourse = param => {
    return axios.post(URLs.deleteAppSubscriberCourse, param);
  };

  static appSubscriberCompleteCourse = param => {
    return axios.post(URLs.appSubscriberCompleteCourse, param);
  };

  static spListCombos = param => {
    return axios.post(URLs.spListCombos, param);
  };

  static listMobileAppRoleGet = param => {
    return axios.post(URLs.listMobileAppRoleGet, param);
  };

  static appSubscriberMapHistory = param => {
    return axios.post(URLs.appSubscribersMapHistory, param);
  };

  static appInvite = param => {
    return axios.post(URLs.appInvite, param);
  };

  static getMobileAppDownloadStatus = param => {
    return axios.post(URLs.getMobileAppDownloadStatus, param);
  };

  static updateMobileAppRole = param => {
    return axios.post(URLs.updateMobileAppRole, param);
  };

  static addNoteType = param => {
    return axios.post(URLs.addNoteType, param);
  };

  static changeAppRole = param => {
    return axios.post(URLs.changeAppRole, param);
  };

  static twoStepAuth = param => {
    return axios.post(URLs.twoStepAuth, param);
  };

  static validatePasscode = param => {
    return axios.post(URLs.validatePasscode, param);
  };

  static appInviteDashboard = param => {
    return axios.post(URLs.appInviteDashboard, param);
  };

  static getStaffShiftLog = param => {
    return axios.post(URLs.getStaffShiftLog, param);
  };

  static addStaffShiftLog = param => {
    return axios.post(URLs.addStaffShiftLog, param);
  };

  static isManageLicenseRequired = param => {
    return axios.post(URLs.isManageLicenseRequired, param);
  };

  static getAllCallSession = param => {
    return axios.post(URLs.getAllCallSession, param);
  };

  static addCallSession = param => {
    return axios.post(URLs.addCallSession, param);
  };

  static getAllVacation = param => {
    return axios.post(URLs.getAllVacation, param);
  };

  static addVacationDays = param => {
    return axios.post(URLs.addVacationDays, param);
  };

  static getOneCallTimeSlots = param => {
    return axios.post(URLs.getOneCallTimeSlots, param);
  };

  static getOneCallSession = param => {
    return axios.post(URLs.getOneCallSession, param);
  };

  static inviteOneCallSession = param => {
    return axios.post(URLs.inviteOneCallSession, param);
  };

  static cancelOneCallSession = param => {
    return axios.post(URLs.cancelOneCallSession, param);
  };

  static getStripeApiKey = param => {
    return axios.post(URLs.getStripeApiKey, param);
  };

  static editGroupCallSession = param => {
    return axios.post(URLs.editGroupCallSession, param);
  };

  static getCallSessionVideo = param => {
    return axios.post(URLs.getCallSessionVideo, param);
  };

  static getCallSessionVideoUrl = param => {
    return axios.post(URLs.getCallSessionVideoUrl, param);
  };

  static staffReactivate = param => {
    return axios.post(URLs.staffReactivate, param);
  };

  static getReports = param => {
    return axios.post(URLs.getReports, param);
  };

  static getPromoCode = param => {
    return axios.post(URLs.getPromoCode, param);
  };

  static getSmallGroup = param => {
    return axios.post(URLs.getSmallGroup, param);
  };

  static addSmallGroup = param => {
    return axios.post(URLs.addSmallGroup, param);
  };

  static getCounselor = param => {
    return axios.post(URLs.getCounselor, param);
  };

  static getClientCounselor = param => {
    return axios.post(URLs.getClientCounselor, param);
  };

  static getDynamicComos = param => {
    return axios.post(URLs.getDynamicComos, param);
  };

  static sendVoucherCode = param => {
    return axios.post(URLs.sendVoucherCode, param);
  };

  static getSmallGroupList = param => {
    return axios.post(URLs.getSmallGroupList, param);
  };

  static getSmallGroupUsers = param => {
    return axios.post(URLs.getSmallGroupUsers, param);
  };

  static uploadLeaderPhoto = param => {
    return axios.post(URLs.uploadLeaderPhoto, param);
  };

  static markSystemObservation = param => {
    return axios.post(URLs.markSystemObservation, param);
  };

  static appMarkSystemObservation = param => {
    return axios.post(URLs.appMarkSystemObservation, param);
  };

  static getAnalytics = param => {
    return axios.post(URLs.getAnalytics, param);
  };

  static getActiveClient = param => {
    return axios.post(URLs.getActiveClient, param);
  };

  static isStaffProBio = param => {
    return axios.post(URLs.isStaffProBio, param);
  };

  static updateStaffProBio = param => {
    return axios.post(URLs.updateStaffProBio, param);
  };

  static getNotes = param => {
    return axios.post(URLs.getNotes, param);
  };

  static getCategoryClient = param => {
    return axios.post(URLs.getCategoryClient, param);
  };

  static getClientDemographic = param => {
    return axios.post(URLs.getClientDemographic, param);
  };

  static getCostOfCare = param => {
    return axios.post(URLs.getCostOfCare, param);
  };

  static getBehavioralRating = param => {
    return axios.post(URLs.getBehavioralRating, param);
  };

  static getScoreImprovement = param => {
    return axios.post(URLs.getScoreImprovement, param);
  };

  static getProviderPerformance = param => {
    return axios.post(URLs.getProviderPerformance, param);
  };

  static getTriggerLocationActivity = param => {
    return axios.post(URLs.getTriggerLocationActivity, param);
  };

  static getServiceDelivered = param => {
    return axios.post(URLs.getServiceDelivered, param);
  };

  static getReferralSource = param => {
    return axios.post(URLs.getReferralSource, param);
  };

  static getStudentDocumentList = param => {
    return axios.post(URLs.getStudentDocumentList, param);
  };

  static archiveStudentDocument = param => {
    return axios.post(URLs.archiveStudentDocument, param);
  };

  static uploadDocument = formData => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
      maxContentLength: 100000000,
      maxBodyLength: 100000000,
    };
    return axios.post(URLs.uploadDocument, formData, config);
  };

  static downloadStudentDocument = param => {
    return axios.post(URLs.downloadStudentDocument, param);
  };

  static menuContractTemplate = param => {
    return axios.post(URLs.menuContractTemplate, param);
  };

  static archiveContractTemplate = param => {
    return axios.post(URLs.archiveContractTemplate, param);
  };

  static newsUpsert = param => {
    return axios.post(URLs.newsUpsert, param);
  };

  static getListNews = param => {
    return axios.post(URLs.getListNews, param);
  };

  static getNews = param => {
    return axios.post(URLs.getNews, param);
  };

  static deleteNews = param => {
    return axios.post(URLs.deleteNews, param);
  };

  static getEmbedInfo = param => {
    return axios.post(URLs.getEmbedInfo, param);
  };

  static getGenderPieChartEmbedInfo = param => {
    return axios.post(URLs.getGenderPieChartEmbedInfo, param);
  };

  static getRefernceSourceTimeInTreatmentReport = param => {
    return axios.post(URLs.getRefernceSourceTimeInTreatmentReport, param);
  };

  static getPatientList = param => {
    return axios.post(URLs.getPatientList, param);
  };

  static getPatientDetail = param => {
    return axios.post(URLs.getPatientDetail, param);
  };

  static clientChartListGet = param => {
    return axios.post(URLs.clientChartListGet, param);
  };

  static clientChartReport = param => {
    return axios.post(URLs.clientChartReport, param);
  };

  static upsertPatientEnrollment = param => {
    return axios.post(URLs.upsertPatientEnrollment, param);
  };

  static upsertClientInsurance = param => {
    return axios.post(URLs.upsertClientInsurance, param);
  };

  static upsertClientMedical = param => {
    return axios.post(URLs.upsertClientMedical, param);
  };

  static upsertClientPayment = param => {
    return axios.post(URLs.upsertClientPayment, param);
  };

  static upsertProviderEnrollment = param => {
    return axios.post(URLs.upsertProviderEnrollment, param);
  };

  static upsertAppointment = param => {
    return axios.post(URLs.upsertAppointment, param);
  };

  static listAppointment = param => {
    return axios.post(URLs.listAppointment, param);
  };

  static getAppointment = param => {
    return axios.post(URLs.getAppointment, param);
  };

  static deleteAppointment = param => {
    return axios.post(URLs.deleteAppointment, param);
  };

  static medicateCombos = param => {
    return axios.post(URLs.medicateCombos, param);
  };

  static listSurveyQuestionCategory = param => {
    return axios.post(URLs.listSurveyQuestionCategory, param);
  };

  static listSurveyQuestionCategoryAssessment = param => {
    return axios.post(URLs.listSurveyQuestionCategoryAssessment, param);
  };

  static listSurveyQuestionAssessment = param => {
    return axios.post(URLs.listSurveyQuestionAssessment, param);
  };

  static practitionerCombos = param => {
    return axios.post(URLs.practitionerCombos, param);
  };

  static practitionerCombosOffice = param => {
    return axios.post(URLs.practitionerCombosOffice, param);
  };

  static upsertGroup = param => {
    return axios.post(URLs.upsertGroup, param);
  };

  static listGroup = param => {
    return axios.post(URLs.listGroup, param);
  };

  static getGroup = param => {
    return axios.post(URLs.getGroup, param);
  };

  static deleteGroup = param => {
    return axios.post(URLs.deleteGroup, param);
  };

  static upsertClientGroup = param => {
    return axios.post(URLs.upsertClientGroup, param);
  };

  static getClientGroup = param => {
    return axios.post(URLs.getClientGroup, param);
  };

  static upsertGroupAppointment = param => {
    return axios.post(URLs.upsertGroupAppointment, param);
  };

  static upsertGroupAppointmentGroupAllInfoJson = param => {
    return axios.post(URLs.upsertGroupAppointmentGroupAllInfoJson, param);
  };

  static getListClientGroup = param => {
    return axios.post(URLs.getListClientGroup, param);
  };

  static patientComboGroup = param => {
    return axios.post(URLs.patientComboGroup, param);
  };

  static deactivateGroupAppointment = param => {
    return axios.post(URLs.deactivateGroupAppointment, param);
  };

  static comboCenterConsent = param => {
    return axios.post(URLs.comboCenterConsent, param);
  };

  static upsertClientConsent = param => {
    return axios.post(URLs.upsertClientConsent, param);
  };

  static listClientConsent = param => {
    return axios.post(URLs.listClientConsent, param);
  };

  static deactivateClientConsent = param => {
    return axios.post(URLs.deactivateClientConsent, param);
  };

  static deactivateClientScreening = param => {
    return axios.post(URLs.deactivateClientScreening, param);
  };

  static loadJudicialDashboard = param => {
    return axios.post(URLs.loadJudicialDashboard, param);
  };

  static getJudicialUserList = param => {
    return axios.post(URLs.getJudicialUserList, param);
  };

  // static UploadDocumentUsingMultiPart = param => {
  //   return axios.post(URLs.UploadDocumentUsingMultiPart, param);
  // };

  static upsertClientDocument = param => {
    return axios.post(URLs.upsertClientDocument, param);
  };

  static UploadDocumentUsingMultiPart = formData => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
      maxContentLength: 100000000,
      maxBodyLength: 100000000,
    };
    return axios.post(URLs.UploadDocumentUsingMultiPart, formData, config);
  };

  static listClientDocument = param => {
    return axios.post(URLs.listClientDocument, param);
  };

  static downloadClientDocument = param => {
    return axios.post(URLs.downloadClientDocument, param);
  };

  static archiveClientDocument = param => {
    return axios.post(URLs.archiveClientDocument, param);
  };

  static listGroupMembership = param => {
    return axios.post(URLs.listGroupMembership, param);
  };

  static getSurveyScore = param => {
    return axios.post(URLs.getSurveyScore, param);
  };

  static getConsentFormTemplate = param => {
    return axios.post(URLs.getConsentFormTemplate, param);
  };

  static getComboFacility = param => {
    return axios.post(URLs.getComboFacility, param);
  };

  static loadAppSubSurveyDashboard = param => {
    return axios.post(URLs.loadAppSubSurveyDashboard, param);
  };

  static appSubSurveyAnsGet = param => {
    return axios.post(URLs.appSubSurveyAnsGet, param);
  };

  static centerProgramTypeGet = param => {
    return axios.post(URLs.centerProgramTypeGet, param);
  };

  static downloadClientConsent = param => {
    return axios.post(URLs.downloadClientConsent, param);
  };

  static comboPractitionerAll = param => {
    return axios.post(URLs.comboPractitionerAll, param);
  };

  static comboFacilityAll = param => {
    return axios.post(URLs.comboFacilityAll, param);
  };

  static saveClientConsent = param => {
    return axios.post(URLs.saveClientConsent, param);
  };

  static getTreatmentPlan = param => {
    return axios.post(URLs.getTreatmentPlan, param);
  };

  static getDiagnosis = param => {
    return axios.post(URLs.getDiagnosis, param);
  };

  static upsertTreatmentPlanProblem = param => {
    return axios.post(URLs.upsertTreatmentPlanProblem, param);
  };

  static upsertMedicalAnalysis = param => {
    return axios.post(URLs.upsertMedicalAnalysis, param);
  };

  static upsertTreatmentPlanGoal = param => {
    return axios.post(URLs.upsertTreatmentPlanGoal, param);
  };

  static upsertTreatmentPlanObjective = param => {
    return axios.post(URLs.upsertTreatmentPlanObjective, param);
  };

  static upsertTreatmentPlanIntervention = param => {
    return axios.post(URLs.upsertTreatmentPlanIntervention, param);
  };

  static deleteTreatmentPlan = param => {
    return axios.post(URLs.deleteTreatmentPlan, param);
  };

  static getDiagnosisDescription = param => {
    return axios.post(URLs.getDiagnosisDescription, param);
  };

  static getDiagnosisCode = param => {
    return axios.post(URLs.getDiagnosisCode, param);
  };

  static listTreatmentPlan = param => {
    return axios.post(URLs.listTreatmentPlan, param);
  };

  static comboProblemTemplate = param => {
    return axios.post(URLs.comboProblemTemplate, param);
  };

  static comboGoalTemplate = param => {
    return axios.post(URLs.comboGoalTemplate, param);
  };

  static recurringCount = param => {
    return axios.post(URLs.recurringCount, param);
  };

  static staffPermission = param => {
    return axios.post(URLs.staffPermission, param);
  };

  static updateStaffPermission = param => {
    return axios.post(URLs.updateStaffPermission, param);
  };

  static loadCaseProfile = param => {
    return axios.post(URLs.loadCaseProfile, param);
  };

  static loadLocationAuthHistory = param => {
    return axios.post(URLs.loadLocationAuthHistory, param);
  };

  static screenAuthorization = param => {
    return axios.post(URLs.screenAuthorization, param);
  };

  static listConsentForm = param => {
    return axios.post(URLs.listConsentForm, param);
  };

  static upsertTreatmentPlanDimension = param => {
    return axios.post(URLs.upsertTreatmentPlanDimension, param);
  };

  static saveConsentSign = param => {
    return axios.post(URLs.saveConsentSign, param);
  };

  static consentFormDownload = param => {
    return axios.post(URLs.consentFormDownload, param);
  };

  static listMeeting = param => {
    return axios.post(URLs.listMeeting, param);
  };

  static upsertProgressGroupNote = param => {
    return axios.post(URLs.upsertProgressGroupNote, param);
  };

  static getProgressNoteGroup = param => {
    return axios.post(URLs.getProgressNoteGroup, param);
  };

  static getUserNotePatient = param => {
    return axios.post(URLs.getUserNotePatient, param);
  };

  static getUserNoteComment = param => {
    return axios.post(URLs.getUserNoteComment, param);
  };

  static addUserNoteComment = param => {
    return axios.post(URLs.addUserNoteComment, param);
  };

  static getUserNoteEmoji = param => {
    return axios.post(URLs.getUserNoteEmoji, param);
  };

  static addUserNoteEmoji = param => {
    return axios.post(URLs.addUserNoteEmoji, param);
  };

  static comboInterventionTemplate = param => {
    return axios.post(URLs.comboInterventionTemplate, param);
  };

  static getProgressNoteForIntervention = param => {
    return axios.post(URLs.getProgressNoteForIntervention, param);
  };

  static upsertPayer = param => {
    return axios.post(URLs.upsertPayer, param);
  };

  static listPayer = param => {
    return axios.post(URLs.listPayer, param);
  };

  static deletePayer = param => {
    return axios.post(URLs.deletePayer, param);
  };

  static getPayer = param => {
    return axios.post(URLs.getPayer, param);
  };

  static comboPayer = param => {
    return axios.post(URLs.comboPayer, param);
  };

  static upsertServiceCode = param => {
    return axios.post(URLs.upsertServiceCode, param);
  };

  static listServiceCode = param => {
    return axios.post(URLs.listServiceCode, param);
  };

  static deleteServiceCode = param => {
    return axios.post(URLs.deleteServiceCode, param);
  };

  static getServiceCode = param => {
    return axios.post(URLs.getServiceCode, param);
  };

  static comboCode = param => {
    return axios.post(URLs.comboCode, param);
  };

  static getCodeDescription = param => {
    return axios.post(URLs.getCodeDescription, param);
  };

  static appointmentNoteList = param => {
    return axios.post(URLs.appointmentNoteList, param);
  };

  static upsertAppointmentNote = param => {
    return axios.post(URLs.upsertAppointmentNote, param);
  };

  static getAppointmentNote = param => {
    return axios.post(URLs.getAppointmentNote, param);
  };

  static comboPlaceOfService = param => {
    return axios.post(URLs.comboPlaceOfService, param);
  };

  static listServiceLine = param => {
    return axios.post(URLs.listServiceLine, param);
  };

  static getServiceLine = param => {
    return axios.post(URLs.getServiceLine, param);
  };

  static upsertServiceLine = param => {
    return axios.post(URLs.upsertServiceLine, param);
  };

  static upsertAppointmentNoteAddendum = param => {
    return axios.post(URLs.upsertAppointmentNoteAddendum, param);
  };

  static listGroupAppointmentNote = param => {
    return axios.post(URLs.listGroupAppointmentNote, param);
  };

  static getGroupAppointmentNote = param => {
    return axios.post(URLs.getGroupAppointmentNote, param);
  };

  static comboTreatmentPlanIntervention = param => {
    return axios.post(URLs.comboTreatmentPlanIntervention, param);
  };

  static comboTreatmentPlanIntervention = param => {
    return axios.post(URLs.comboTreatmentPlanIntervention, param);
  };

  static listFeeSchedule = param => {
    return axios.post(URLs.listFeeSchedule, param);
  };

  static getFeeSchedule = param => {
    return axios.post(URLs.getFeeSchedule, param);
  };

  static upsertFeeSchedule = param => {
    return axios.post(URLs.upsertFeeSchedule, param);
  };

  static getTreatmentPlanGraph = param => {
    return axios.post(URLs.getTreatmentPlanGraph, param);
  };

  static listGroupServiceLine = param => {
    return axios.post(URLs.listGroupServiceLine, param);
  };

  static getGroupServiceLine = param => {
    return axios.post(URLs.getGroupServiceLine, param);
  };

  static getGroupBanner = param => {
    return axios.post(URLs.getGroupBanner, param);
  };

  static upsertGroupServiceLine = param => {
    return axios.post(URLs.upsertGroupServiceLine, param);
  };

  static upsertGroupAppointmentNote = param => {
    return axios.post(URLs.upsertGroupAppointmentNote, param);
  };

  static upsertGroupAppointmentNoteAddendum = param => {
    return axios.post(URLs.upsertGroupAppointmentNoteAddendum, param);
  };

  static listSurveyQuestionDischarge = param => {
    return axios.post(URLs.listSurveyQuestionDischarge, param);
  };

  static upsertAppointmentNoteIntervention = param => {
    return axios.post(URLs.upsertAppointmentNoteIntervention, param);
  };

  static markAsComplete = param => {
    return axios.post(URLs.markAsComplete, param);
  };

  static getServiceLineCost = param => {
    return axios.post(URLs.getServiceLineCost, param);
  };

  static upsertPin = param => {
    return axios.post(URLs.upsertPin, param);
  };

  static validateStaffPin = param => {
    return axios.post(URLs.validateStaffPin, param);
  };

  static loadSmallGroupDashboard = param => {
    return axios.post(URLs.loadSmallGroupDashboard, param);
  };

  static upsertTreatmentPlan = param => {
    return axios.post(URLs.upsertTreatmentPlan, param);
  };

  static listClientDiagnosis = param => {
    return axios.post(URLs.listClientDiagnosis, param);
  };

  static getClientDiagnosis = param => {
    return axios.post(URLs.getClientDiagnosis, param);
  };

  static deleteClientDiagnosis = param => {
    return axios.post(URLs.deleteClientDiagnosis, param);
  };

  static validatePinExists = param => {
    return axios.post(URLs.validatePinExists, param);
  };

  static cloneDiagnosis = param => {
    return axios.post(URLs.cloneDiagnosis, param);
  };

  static cloneTreatmentPlan = param => {
    return axios.post(URLs.cloneTreatmentPlan, param);
  };

  static getBreathalyzerSchedule = param => {
    return axios.post(URLs.getBreathalyzerSchedule, param);
  };

  static listBreathalyzerSchedule = param => {
    return axios.post(URLs.listBreathalyzerSchedule, param);
  };

  static upsertBreathalyzerSchedule = param => {
    return axios.post(URLs.upsertBreathalyzerSchedule, param);
  };

  static rcmClientMerge = param => {
    return axios.post(URLs.rcmClientMerge, param);
  };

  static preValidatePin = param => {
    return axios.post(URLs.preValidatePin, param);
  };

  static validateClient = param => {
    return axios.post(URLs.validateClient, param);
  };

  static getAppAdvisor = param => {
    return axios.post(URLs.getAppAdvisor, param);
  };

  static getMediaVideoUrl = param => {
    return axios.post(URLs.getMediaVideoUrl, param);
  };

  static getDashboardMenu = param => {
    return axios.post(URLs.getDashboardMenu, param);
  };

  static breathalyzerPushATest = param => {
    return axios.post(URLs.breathalyzerPushATest, param);
  };

  static listServiceCodeBundle = param => {
    return axios.post(URLs.listServiceCodeBundle, param);
  };

  static getServiceCodeBundle = param => {
    return axios.post(URLs.getServiceCodeBundle, param);
  };

  static getStudentNote = param => {
    return axios.post(URLs.getStudentNote, param);
  };

  static getStudentNoteComment = param => {
    return axios.post(URLs.getStudentNoteComment, param);
  };

  static addStudentNoteComment = param => {
    return axios.post(URLs.addStudentNoteComment, param);
  };

  static addStudentNoteEmoji = param => {
    return axios.post(URLs.addStudentNoteEmoji, param);
  };

  static getStudentNoteEmoji = param => {
    return axios.post(URLs.getStudentNoteEmoji, param);
  };

  static getTreatmentPlanAccordin = param => {
    return axios.post(URLs.getTreatmentPlanAccordin, param);
  };

  static breathalyzerEnable = param => {
    return axios.post(URLs.breathalyzerEnable, param);
  };

  static getBreathalyzerTestResult = param => {
    return axios.post(URLs.getBreathalyzerTestResult, param);
  };

  static getBreathalyzerTestUpcoming = param => {
    return axios.post(URLs.getBreathalyzerTestUpcoming, param);
  };

  static breathalyzerTestUpcomingToggle = param => {
    return axios.post(URLs.breathalyzerTestUpcomingToggle, param);
  };

  static getAdvisorCost = param => {
    return axios.post(URLs.getAdvisorCost, param);
  };

  static upsertAdvisorCost = param => {
    return axios.post(URLs.upsertAdvisorCost, param);
  };

  static claimsPdfDownload = param => {
    return axios.post(URLs.claimsPdfDownload, param);
  };

  static getClientService = param => {
    return axios.post(URLs.getClientService, param);
  };

  static getListTimeLine = param => {
    return axios.post(URLs.getListTimeLine, param);
  };

  static getStudentBreathalyzerTestResult = param => {
    return axios.post(URLs.getStudentBreathalyzerTestResult, param);
  };

  static getDashboardDailySchedule = param => {
    return axios.post(URLs.getDashboardDailySchedule, param);
  };

  static getDashboardDailyCaseLoad = param => {
    return axios.post(URLs.getDashboardDailyCaseLoad, param);
  };

  static getDashboardAlert = param => {
    return axios.post(URLs.getDashboardAlert, param);
  };

  static getCenterAdvisorDefaultCosts = param => {
    return axios.post(URLs.getCenterAdvisorDefaultCosts, param);
  };

  static updateCenterAdvisorDefaultCost = param => {
    return axios.post(URLs.updateCenterAdvisorDefaultCost, param);
  };

  static addClientInsuranceEligibility = param => {
    return axios.post(URLs.addClientInsuranceEligibility, param);
  };

  static editClientInsuranceEligibility = param => {
    return axios.post(URLs.editClientInsuranceEligibility, param);
  };

  static deleteClientInsuranceEligibility = param => {
    return axios.post(URLs.deleteClientInsuranceEligibility, param);
  };

  static getStaffPractitioner = param => {
    return axios.post(URLs.getStaffPractitioner, param);
  };

  static dischargeSummarySave = param => {
    return axios.post(URLs.dischargeSummarySave, param);
  };

  static getClientAppointment = param => {
    return axios.post(URLs.getClientAppointment, param);
  };

  static getGroupAppointment = param => {
    return axios.post(URLs.getGroupAppointment, param);
  };

  static unsignForm = param => {
    return axios.post(URLs.unsignForm, param);
  };

  static getFilters = param => {
    return axios.post(URLs.getFilters, param);
  };

  static listLabOrder = param => {
    return axios.post(URLs.listLabOrder, param);
  };

  static getLabOrder = param => {
    return axios.post(URLs.getLabOrder, param);
  };

  static upsertLabOrder = param => {
    return axios.post(URLs.upsertLabOrder, param);
  };

  static labOrderLabelGet = param => {
    return axios.post(URLs.labOrderLabelGet, param);
  };

  static comboDiagnosisList = param => {
    return axios.post(URLs.comboDiagnosisList, param);
  };

  static getMedicationNote = param => {
    return axios.post(URLs.getMedicationNote, param);
  };

  static upsertClientGroupAppointment = param => {
    return axios.post(URLs.upsertClientGroupAppointment, param);
  };

  static signGroupAppointmentNote = param => {
    return axios.post(URLs.signGroupAppointmentNote, param);
  };

  static listServiceLinePayment = param => {
    return axios.post(URLs.listServiceLinePayment, param);
  };

  static getServiceLinePayment = param => {
    return axios.post(URLs.getServiceLinePayment, param);
  };

  static upsertServiceLinePayment = param => {
    return axios.post(URLs.upsertServiceLinePayment, param);
  };

  static deleteServiceLinePayment = param => {
    return axios.post(URLs.deleteServiceLinePayment, param);
  };

  static comboSupervisor = param => {
    return axios.post(URLs.comboSupervisor, param);
  };

  static locationDayHistory = param => {
    return axios.post(URLs.locationDayHistory, param);
  };

  static upsertPaymentClient = param => {
    return axios.post(URLs.upsertPaymentClient, param);
  };

  static getInsurancePriorityOrder = param => {
    return axios.post(URLs.getInsurancePriorityOrder, param);
  };

  static upsertInsurancePriorityOrder = param => {
    return axios.post(URLs.upsertInsurancePriorityOrder, param);
  };

  static listCWPayment = param => {
    return axios.post(URLs.listCWPayment, param);
  };

  static getCWPayment = param => {
    return axios.post(URLs.getCWPayment, param);
  };

  static deleteCWPayment = param => {
    return axios.post(URLs.deleteCWPayment, param);
  };

  static pastMeetingInfo = param => {
    return axios.post(URLs.pastMeetingInfo, param);
  };

  static listPastMeetingInfo = param => {
    return axios.post(URLs.listPastMeetingInfo, param);
  };

  static comboAppointmentTreatmentPlan = param => {
    return axios.post(URLs.comboAppointmentTreatmentPlan, param);
  };

  static linkAppointment = param => {
    return axios.post(URLs.linkAppointment, param);
  };

  static appointmentLinkGet = param => {
    return axios.post(URLs.appointmentLinkGet, param);
  };

  static getBasicProfile = param => {
    return axios.post(URLs.getBasicProfile, param);
  };

  static getInvitedList = param => {
    return axios.post(URLs.getInvitedList, param);
  };

  static inviteResend = param => {
    return axios.post(URLs.inviteResend, param);
  };

  static suspiciousTermAck = param => {
    return axios.post(URLs.suspiciousTermAck, param);
  };

  static getBroadcastFilter = param => {
    return axios.post(URLs.getBroadcastFilter, param);
  };

  static sendBroadcast = param => {
    return axios.post(URLs.sendBroadcast, param);
  };

  static getServiceLineDiagnosis = param => {
    return axios.post(URLs.getServiceLineDiagnosis, param);
  };

  static upsertServiceLineDiagnosis = param => {
    return axios.post(URLs.upsertServiceLineDiagnosis, param);
  };

  static comboBroadcastTemplate = param => {
    return axios.post(URLs.comboBroadcastTemplate, param);
  };

  static upsertBroadcastTemplate = param => {
    return axios.post(URLs.upsertBroadcastTemplate, param);
  };

  static deleteNoteType = param => {
    return axios.post(URLs.deleteNoteType, param);
  };

  static getFormFieldMetaData = param => {
    return axios.post(URLs.getFormFieldMetaData, param);
  };

  static listClientAuthorization = param => {
    return axios.post(URLs.listClientAuthorization, param);
  };

  static getClientAuthorization = param => {
    return axios.post(URLs.getClientAuthorization, param);
  };

  static deleteClientAuthorization = param => {
    return axios.post(URLs.deleteClientAuthorization, param);
  };

  static upsertClientAuthorization = param => {
    return axios.post(URLs.upsertClientAuthorization, param);
  };

  static getNoteTypeArchive = param => {
    return axios.post(URLs.getNoteTypeArchive, param);
  };

  static getNoteTypeList = param => {
    return axios.post(URLs.getNoteTypeList, param);
  };

  static upsertCouponCode = param => {
    return axios.post(URLs.upsertCouponCode, param);
  };

  static deleteCouponCode = param => {
    return axios.post(URLs.deleteCouponCode, param);
  };

  static getCouponCode = param => {
    return axios.post(URLs.getCouponCode, param);
  };

  static reactivateCenter = param => {
    return axios.post(URLs.reactivateCenter, param);
  };

  static generateCallToken = param => {
    return axios.post(URLs.generateCallToken, param);
  };

  static badgeUpdate = param => {
    return axios.post(URLs.badgeUpdate, param);
  };

  static badgeComboGet = param => {
    return axios.post(URLs.badgeComboGet, param);
  };

  static panasScaleMoodsGet = param => {
    return axios.post(URLs.panasScaleMoodsGet, param);
  };

  static panasTagUpsert = param => {
    return axios.post(URLs.panasTagUpsert, param);
  };

  static bedAssignmentGetAll = param => {
    return axios.post(URLs.bedAssignmentGetAll, param);
  };

  static bedAssignmentUpsert = param => {
    return axios.post(URLs.bedAssignmentUpsert, param);
  };

  static upcomingDischargesGetAll = param => {
    return axios.post(URLs.upcomingDischargesGetAll, param);
  };

  static upcomingAdmissions = param => {
    return axios.post(URLs.upcomingAdmissions, param);
  };

  static admissionCancel = param => {
    return axios.post(URLs.admissionCancel, param);
  };

  static panasTagDelete = param => {
    return axios.post(URLs.panasTagDelete, param);
  };

  static dynamicGridList = param => {
    return axios.post(URLs.dynamicGridList, param);
  };

  static getGrid = param => {
    return axios.post(URLs.getGrid, param);
  };

  static getGridData = param => {
    return axios.post(URLs.getGridData, param);
  };

  static gridOnRowClickGet = param => {
    return axios.post(URLs.gridOnRowClickGet, param);
  };

  static upsertFormData = param => {
    return axios.post(URLs.upsertFormData, param);
  };

  static formDataGet = param => {
    return axios.post(URLs.formDataGet, param);
  };

  static getPredefinedTask = param => {
    return axios.post(URLs.getPredefinedTask, param);
  };

  static getPredefinedTaskArchive = param => {
    return axios.post(URLs.getPredefinedTaskArchive, param);
  };

  static deletePredefinedTask = param => {
    return axios.post(URLs.deletePredefinedTask, param);
  };

  static upsertPredefinedTask = param => {
    return axios.post(URLs.upsertPredefinedTask, param);
  };

  static listSurveyQuestionCategoryGetAll = param => {
    return axios.post(URLs.listSurveyQuestionCategoryGetAll, param);
  };

  static getAdvisorMemberTrends = param => {
    return axios.post(URLs.getAdvisorMemberTrends, param);
  };

  static updateSmallGroupAttendence = param => {
    return axios.post(URLs.updateSmallGroupAttendence, param);
  };

  static getAdvisorMemberTrendsHistory = param => {
    return axios.post(URLs.getAdvisorMemberTrendsHistory, param);
  };

  static mobileInviteOptionsLoad = param => {
    return axios.post(URLs.mobileInviteOptionsLoad, param);
  };

  static getAppUserSubscription = param => {
    return axios.post(URLs.getAppUserSubscription, param);
  };

  static getServiceOptions = param => {
    return axios.post(URLs.getServiceOptions, param);
  };

  static getServiceAdvisorList = param => {
    return axios.post(URLs.getServiceAdvisorList, param);
  };

  static setServiceAdvisor = param => {
    return axios.post(URLs.setServiceAdvisor, param);
  };

  static updateCancelService = param => {
    return axios.post(URLs.updateCancelService, param);
  };

  static setMembership = param => {
    return axios.post(URLs.setMembership, param);
  };

  static cancelMembership = param => {
    return axios.post(URLs.cancelMembership, param);
  };

  static setServiceSubscription = param => {
    return axios.post(URLs.setServiceSubscription, param);
  };

  static upsertClientPin = param => {
    return axios.post(URLs.upsertClientPin, param);
  };

  static getStudentTimeLine = param => {
    return axios.post(URLs.getStudentTimeLine, param);
  };

  static getClientTimeLine = param => {
    return axios.post(URLs.getClientTimeLine, param);
  };

  static clientStudentSynchronize = param => {
    return axios.post(URLs.clientStudentSynchronize, param);
  };

  static updateUserMembership = param => {
    return axios.post(URLs.updateUserMembership, param);
  };

  static comboAppAdvisor = param => {
    return axios.post(URLs.comboAppAdvisor, param);
  };

  static getSurveyCategoryQuestion = param => {
    return axios.post(URLs.getSurveyCategoryQuestion, param);
  };

  static upsertBlockAppointment = param => {
    return axios.post(URLs.upsertBlockAppointment, param);
  };

  static upsertClientMemo = param => {
    return axios.post(URLs.upsertClientMemo, param);
  };

  static getClientMemo = param => {
    return axios.post(URLs.getClientMemo, param);
  };

  static deleteClientMemo = param => {
    return axios.post(URLs.deleteClientMemo, param);
  };

  static validateSSN = param => {
    return axios.post(URLs.validateSSN, param);
  };

  static userAppLinkValidate = param => {
    return axios.post(URLs.userAppLinkValidate, param);
  };

  static clientCenterMove = param => {
    return axios.post(URLs.clientCenterMove, param);
  };

  static formTabListGet = param => {
    return axios.post(URLs.formTabListGet, param);
  };

  static upsertClientCareTeam = param => {
    return axios.post(URLs.upsertClientCareTeam, param);
  };

  static getClientCareTeamList = param => {
    return axios.post(URLs.getClientCareTeamList, param);
  };

  static clientCareTeamDelete = param => {
    return axios.post(URLs.clientCareTeamDelete, param);
  };

  static careTeamMemberCombo = param => {
    return axios.post(URLs.careTeamMemberCombo, param);
  };

  static externalProviderListGet = param => {
    return axios.post(URLs.externalProviderListGet, param);
  };

  static externalProviderGet = param => {
    return axios.post(URLs.externalProviderGet, param);
  };

  static externalProviderFilterGet = param => {
    return axios.post(URLs.externalProviderFilterGet, param);
  };

  static externalProviderAuthentication = param => {
    return axios.post(URLs.externalProviderAuthentication, param);
  };

  static externalStaffArchive = param => {
    return axios.post(URLs.externalStaffArchive, param);
  };

  static externalProviderUpsert = param => {
    return axios.post(URLs.externalProviderUpsert, param);
  };

  static clientReferralToCenter = param => {
    return axios.post(URLs.clientReferralToCenter, param);
  };

  static externalStaffReferralFormGet = param => {
    return axios.post(URLs.externalStaffReferralFormGet, param);
  };

  static externalStaffClientInsuranceGet = param => {
    return axios.post(URLs.externalStaffClientInsuranceGet, param);
  };

  static externalStaffReferralUpsert = param => {
    return axios.post(URLs.externalStaffReferralUpsert, param);
  };

  static organizationCenterFamilyCombo = param => {
    return axios.post(URLs.organizationCenterFamilyCombo, param);
  };

  static clientCareTeamGet = param => {
    return axios.post(URLs.clientCareTeamGet, param);
  };

  static upsertClientVital = param => {
    return axios.post(URLs.upsertClientVital, param);
  };

  static upsertMedicationLog = param => {
    return axios.post(URLs.upsertMedicationLog, param);
  };

  static getMedicationLog = param => {
    return axios.post(URLs.getMedicationLog, param);
  };

  static getClientVital = param => {
    return axios.post(URLs.getClientVital, param);
  };

  static getMedicationLogList = param => {
    return axios.post(URLs.getMedicationLogList, param);
  };

  static getClientVitalList = param => {
    return axios.post(URLs.getClientVitalList, param);
  };

  static getCHPatientList = param => {
    return axios.post(URLs.getCHPatientList, param);
  };

  static CHPatientServiceSend = param => {
    return axios.post(URLs.CHPatientServiceSend, param);
  };

  static pushClaimsToCH = param => {
    return axios.post(URLs.pushClaimsToCH, param);
  };

  static pushSingleClaimToCH = param => {
    return axios.post(URLs.pushSingleClaimToCH, param);
  };

  static getBillableClaims = param => {
    return axios.post(URLs.getBillableClaims, param);
  };

  static cloneForm = param => {
    return axios.post(URLs.cloneForm, param);
  };

  static clearSignature = param => {
    return axios.post(URLs.clearSignature, param);
  };

  static upsertStaffViewedActivity = param => {
    return axios.post(URLs.upsertStaffViewedActivity, param);
  };

  static deleteStudentNote = param => {
    return axios.post(URLs.deleteStudentNote, param);
  };

  static staffAccessedTabGet = param => {
    return axios.post(URLs.staffAccessedTabGet, param);
  };

  static studentStaffAccessedTabGet = param => {
    return axios.post(URLs.studentStaffAccessedTabGet, param);
  };

  static appAnnouncementUpsert = param => {
    return axios.post(URLs.appAnnouncementUpsert, param);
  };

  static appAnnouncementListGetAll = param => {
    return axios.post(URLs.appAnnouncementListGetAll, param);
  };

  static appAnnouncementGet = param => {
    return axios.post(URLs.appAnnouncementGet, param);
  };

  static tokenManagementListGetAll = param => {
    return axios.post(URLs.tokenManagementListGetAll, param);
  };

  static tokenManagementStatsGet = param => {
    return axios.post(URLs.tokenManagementStatsGet, param);
  };

  static resourceUpsert = param => {
    return axios.post(URLs.resourceUpsert, param);
  };

  static resourceListGetAll = param => {
    return axios.post(URLs.resourceListGetAll, param);
  };

  static resourceGet = param => {
    return axios.post(URLs.resourceGet, param);
  };

  static allergenSearch = param => {
    return axios.post(URLs.allergenSearch, param);
  };

  static drugSearch = param => {
    return axios.post(URLs.drugSearch, param);
  };

  static clientAllergyGet = param => {
    return axios.post(URLs.clientAllergyGet, param);
  };

  static allergyOptionsGet = param => {
    return axios.post(URLs.allergyOptionsGet, param);
  };

  static clientAllergyUpsert = param => {
    return axios.post(URLs.clientAllergyUpsert, param);
  };

  static clientAllergyGetAll = param => {
    return axios.post(URLs.clientAllergyGetAll, param);
  };

  static clientAllergyDelete = param => {
    return axios.post(URLs.clientAllergyDelete, param);
  };

  static clientMedicationUpsert = param => {
    return axios.post(URLs.clientMedicationUpsert, param);
  };

  static clientMedicationGetAll = param => {
    return axios.post(URLs.clientMedicationGetAll, param);
  };

  static clientMedicationDelete = param => {
    return axios.post(URLs.clientMedicationDelete, param);
  };

  static drugPackageGetAll = param => {
    return axios.post(URLs.drugPackageGetAll, param);
  };

  static prescribeNotificationGet = param => {
    return axios.post(URLs.prescribeNotificationGet, param);
  };

  static prescribeUpdate = param => {
    return axios.post(URLs.prescribeUpdate, param);
  };

  static clientSignatureSave = param => {
    return axios.post(URLs.clientSignatureSave, param);
  };

  static prescriptionListGet = param => {
    return axios.post(URLs.prescriptionListGet, param);
  };

  static userFeatureFlagGet = param => {
    return axios.post(URLs.userFeatureFlagGet, param);
  };

  static augmentedClientInsightsGet = param => {
    return axios.post(URLs.augmentedClientInsightsGet, param);
  };

  static riskChartGet = param => {
    return axios.post(URLs.riskChartGet, param);
  };

  static augmentedSummaryGet = param => {
    return axios.post(URLs.augmentedSummaryGet, param);
  };

  static ePrescribeSinglePatientModeSSO = param => {
    return axios.post(URLs.ePrescribeSinglePatientModeSSO, param);
  };

  static clientReactivate = param => {
    return axios.post(URLs.clientReactivate, param);
  };

  static clientBannerGet = param => {
    return axios.post(URLs.clientBannerGet, param);
  };

  static labOrderCancel = param => {
    return axios.post(URLs.labOrderCancel, param);
  };

  static drugDetailSearch = param => {
    return axios.post(URLs.drugDetailSearch, param);
  };

  static labResultGet = param => {
    return axios.post(URLs.labResultGet, param);
  };

  static labResultDocumentGet = param => {
    return axios.post(URLs.labResultDocumentGet, param);
  };

  static labOrderDelete = param => {
    return axios.post(URLs.labOrderDelete, param);
  };

  static addSupportGroup = param => {
    return axios.post(URLs.addSupportGroup, param);
  };

  static loadSupportGroup = param => {
    return axios.post(URLs.loadSupportGroup, param);
  };

  static loadPeer = param => {
    return axios.post(URLs.loadPeer, param);
  };

  static loadUserEngagement = param => {
    return axios.post(URLs.loadUserEngagement, param);
  };

  static studentBanner = param => {
    return axios.post(URLs.studentBanner, param);
  };

  static preIntakeSurveyAnswersGet = param => {
    return axios.post(URLs.preIntakeSurveyAnswersGet, param);
  };

  static preIntakeHistoryGet = param => {
    return axios.post(URLs.preIntakeHistoryGet, param);
  };

  static surveyLatestAnswerDateUpdate = param => {
    return axios.post(URLs.surveyLatestAnswerDateUpdate, param);
  };

  static appUserDemographicUpsert = param => {
    return axios.post(URLs.appUserDemographicUpsert, param);
  };

  static appUserDemographicGet = param => {
    return axios.post(URLs.appUserDemographicGet, param);
  };

  static removeSupportGroup = param => {
    return axios.post(URLs.removeSupportGroup, param);
  };

  static concernedPersonList = param => {
    return axios.post(URLs.concernedPersonList, param);
  };

  static concernedPersonDasboardGraphGet = param => {
    return axios.post(URLs.concernedPersonDasboardGraphGet, param);
  };

  static advisorSearch = param => {
    return axios.post(URLs.advisorSearch, param);
  };

  static listClientGetAllWithPagination = param => {
    return axios.post(URLs.listClientGetAllWithPagination, param);
  };

  static appUserLiveLocationGet = param => {
    return axios.post(URLs.appUserLiveLocationGet, param);
  };

  static studentSurveyListGet = param => {
    return axios.post(URLs.studentSurveyListGet, param);
  };

  static GPT3EnrollSurveys = param => {
    return axios.post(URLs.GPT3EnrollSurveys, param);
  };

  static getMultipleSurveyAugmentedSummary = param => {
    return axios.post(URLs.getMultipleSurveyAugmentedSummary, param);
  };

  static GPT3AnswerStatusUpsert = param => {
    return axios.post(URLs.GPT3AnswerStatusUpsert, param);
  };

  static augmentWithAI = param => {
    return axios.post(URLs.augmentWithAI, param);
  };

  static augmentWithAIGet = param => {
    return axios.post(URLs.augmentWithAIGet, param);
  };

  static gridFilterGet = param => {
    return axios.post(URLs.gridFilterGet, param);
  };

  static getGroupDropdown = param => {
    return axios.post(URLs.getGroupDropdown, param);
  };

  static getProspectDashboardGraph = param => {
    return axios.post(URLs.getProspectDashboardGraph, param);
  };

  static medicalProgramUpsert = param => {
    return axios.post(URLs.medicalProgramUpsert, param);
  };

  static medicalProgramGet = param => {
    return axios.post(URLs.medicalProgramGet, param);
  };

  static medicalProgramDelete = param => {
    return axios.post(URLs.medicalProgramDelete, param);
  };

  static regionUpsert = param => {
    return axios.post(URLs.regionUpsert, param);
  };

  static regionGet = param => {
    return axios.post(URLs.regionGet, param);
  };

  static regionDelete = param => {
    return axios.post(URLs.regionDelete, param);
  };

  static staffAccessedTabEntGet = param => {
    return axios.post(URLs.staffAccessedTabEntGet, param);
  };

  static surveyQuestionLibraryGet = param => {
    return axios.post(URLs.surveyQuestionLibraryGet, param);
  };

  static surveyQuestionConfigGet = param => {
    return axios.post(URLs.surveyQuestionConfigGet, param);
  };

  static surveyQuestionSectionUpsert = param => {
    return axios.post(URLs.surveyQuestionSectionUpsert, param);
  };

  static surveyQuestionSectionComboGet = param => {
    return axios.post(URLs.surveyQuestionSectionComboGet, param);
  };

  static surveyQuestionConfigUpsert = param => {
    return axios.post(URLs.surveyQuestionConfigUpsert, param);
  };

  static surveyQuestionCategoryConfigUpsert = param => {
    return axios.post(URLs.surveyQuestionCategoryConfigUpsert, param);
  };

  static surveyQuestionConfigBannerGet = param => {
    return axios.post(URLs.surveyQuestionConfigBannerGet, param);
  };

  static studentQueueListGetEnt = param => {
    return axios.post(URLs.studentQueueListGetEnt, param);
  };

  static studentQueueDelete = param => {
    return axios.post(URLs.studentQueueDelete, param);
  };

  static studentAvailableSpacesListGet = param => {
    return axios.post(URLs.studentAvailableSpacesListGet, param);
  };

  static studentQueueInsert = param => {
    return axios.post(URLs.studentQueueInsert, param);
  };

  static clientScreeningStatusUpdate = param => {
    return axios.post(URLs.clientScreeningStatusUpdate, param);
  };

  static studentAssociateInsert = param => {
    return axios.post(URLs.studentAssociateInsert, param);
  };

  static studentAssociateListGet = param => {
    return axios.post(URLs.studentAssociateListGet, param);
  };

  static studentAssociateDelete = param => {
    return axios.post(URLs.studentAssociateDelete, param);
  };

  static centerOptionGet = param => {
    return axios.post(URLs.centerOptionGet, param);
  };

  static comboPractitionerCenterGet = param => {
    return axios.post(URLs.comboPractitionerCenterGet, param);
  };

  static prospectArchive = param => {
    return axios.post(URLs.prospectArchive, param);
  };

  static tokensStudentTransactionHistory = param => {
    return axios.post(URLs.tokensStudentTransactionHistory, param);
  };

  static tokensCounselorTransactionHistory = param => {
    return axios.post(URLs.tokensCounselorTransactionHistory, param);
  };

  static tokensStudentTransactionHistoryGetAll = param => {
    return axios.post(URLs.tokensStudentTransactionHistoryGetAll, param);
  };

  static tokensCounselorTransactionHistoryGetAll = param => {
    return axios.post(URLs.tokensCounselorTransactionHistoryGetAll, param);
  };

  static centerOverHeadCostGet = param => {
    return axios.post(URLs.centerOverHeadCostGet, param);
  };

  static centerOverHeadCostUpsert = param => {
    return axios.post(URLs.centerOverHeadCostUpsert, param);
  };

  static centerLaberCostGet = param => {
    return axios.post(URLs.centerLaberCostGet, param);
  };

  static centerLaberCostGetUpsert = param => {
    return axios.post(URLs.centerLaberCostGetUpsert, param);
  };

  static centerConsumableGetAll = param => {
    return axios.post(URLs.centerConsumableGetAll, param);
  };

  static centerConsumableGet = param => {
    return axios.post(URLs.centerConsumableGet, param);
  };

  static centerConsumableGetUpsert = param => {
    return axios.post(URLs.centerConsumableGetUpsert, param);
  };

  static centerConsumableDelete = param => {
    return axios.post(URLs.centerConsumableDelete, param);
  };

  static dashboardPendingSurveyListGet = param => {
    return axios.post(URLs.dashboardPendingSurveyListGet, param);
  };

  static clientembeddingprepare = param => {
    return axios.post(URLs.clientembeddingprepare, param);
  };

  static askQuestion = param => {
    return axios.post(URLs.askQuestion, param);
  };

  static chatGet = param => {
    return axios.post(URLs.chatGet, param);
  };

  static youuniverseConversationGet = param => {
    return axios.post(URLs.youuniverseConversationGet, param);
  };

  static youuniverseChatGet = param => {
    return axios.post(URLs.youuniverseChatGet, param);
  };

  static youuniverseChatPost = param => {
    return axios.post(URLs.youuniverseChatPost, param);
  };

  static labOrderPanelCodeComboGet = param => {
    return axios.post(URLs.labOrderPanelCodeComboGet, param);
  };

  static defaultPractotionerFacilityGet = param => {
    return axios.post(URLs.defaultPractotionerFacilityGet, param);
  };

  static licenseNotifyCenterAdmin = param => {
    return axios.post(URLs.licenseNotifyCenterAdmin, param);
  };

  static goalLibraryUpsert = param => {
    return axios.post(URLs.goalLibraryUpsert, param);
  };

  static goalLibraryArchive = param => {
    return axios.post(URLs.goalLibraryArchive, param);
  };

  static goalLibraryGetAll = param => {
    return axios.post(URLs.goalLibraryGetAll, param);
  };

  static goalLibraryGet = param => {
    return axios.post(URLs.goalLibraryGet, param);
  };

  static comboServiceCode = param => {
    return axios.post(URLs.comboServiceCode, param);
  };

  static centerTokenAssignUpdate = param => {
    return axios.post(URLs.centerTokenAssignUpdate, param);
  };

  static serviceCodeBundleUpsert = param => {
    return axios.post(URLs.serviceCodeBundleUpsert, param);
  };

  static serviceCodeBundleDelete = param => {
    return axios.post(URLs.serviceCodeBundleDelete, param);
  };

  static serviceCodeBundleGetAll = param => {
    return axios.post(URLs.serviceCodeBundleGetAll, param);
  };

  static serviceCodeBundleGet = param => {
    return axios.post(URLs.serviceCodeBundleGet, param);
  };

  static smartContractUpsert = param => {
    return axios.post(URLs.smartContractUpsert, param);
  };

  static vbcLedgerRemarkUpsert = param => {
    return axios.post(URLs.vbcLedgerRemarkUpsert, param);
  };

  static distributionPaymentUpsert = param => {
    return axios.post(URLs.distributionPaymentUpsert, param);
  };

  static smartContractGet = param => {
    return axios.post(URLs.smartContractGet, param);
  };

  static smartContractRequestGet = param => {
    return axios.post(URLs.smartContractRequestGet, param);
  };

  static smartContractRequestUpdate = param => {
    return axios.post(URLs.smartContractRequestUpdate, param);
  };

  static payerPortalDashBoard = param => {
    return axios.post(URLs.payerPortalDashBoard, param);
  };

  static payerPortalAuthorizationGet = param => {
    return axios.post(URLs.payerPortalAuthorizationGet, param);
  };

  static payerPortalAuthorizationStatusUpsert = param => {
    return axios.post(URLs.payerPortalAuthorizationStatusUpsert, param);
  };

  static payerPortalRequestInsert = param => {
    return axios.post(URLs.payerPortalRequestInsert, param);
  };

  static clientInsuranceGet = param => {
    return axios.post(URLs.clientInsuranceGet, param);
  };

  static studentRecommendationsListGet = param => {
    return axios.post(URLs.studentRecommendationsListGet, param);
  };

  static youuniverseBookMarkQuestionUpsert = param => {
    return axios.post(URLs.youuniverseBookMarkQuestionUpsert, param);
  };

  static youuniverseBookMarkQuestionListGet = param => {
    return axios.post(URLs.youuniverseBookMarkQuestionListGet, param);
  };

  static youuniverseChatAttributeUpdate = param => {
    return axios.post(URLs.youuniverseChatAttributeUpdate, param);
  };

  static youuniverseConversationDelete = param => {
    return axios.post(URLs.youuniverseConversationDelete, param);
  };

  static youuChatEditedAnswerUpsert = param => {
    return axios.post(URLs.youuChatEditedAnswerUpsert, param);
  };

  static smartContractLedgerGet = param => {
    return axios.post(URLs.smartContractLedgerGet, param);
  };

  static clientStatusReport = param => {
    return axios.post(URLs.clientStatusReport, param);
  };

  static userTriggerReport = param => {
    return axios.post(URLs.userTriggerReport, param);
  };

  static surveyQuestionLibraryQuestionListGet = param => {
    return axios.post(URLs.surveyQuestionLibraryQuestionListGet, param);
  };

  static surveyQuestionLibraryQuestionDelete = param => {
    return axios.post(URLs.surveyQuestionLibraryQuestionDelete, param);
  };

  static surveyQuestionLibrarySectionUpdate = param => {
    return axios.post(URLs.surveyQuestionLibrarySectionUpdate, param);
  };

  static surveyQuestionLibraryQuestionUpsert = param => {
    return axios.post(URLs.surveyQuestionLibraryQuestionUpsert, param);
  };

  static smartContractUpdate = param => {
    return axios.post(URLs.smartContractUpdate, param);
  };

  static studentNoteGetAll = param => {
    return axios.post(URLs.studentNoteGetAll, param);
  };

  static askFollowUpInitiate = param => {
    return axios.post(URLs.askFollowUpInitiate, param);
  };

  static surveyQuestionLibraryQuestionGet = param => {
    return axios.post(URLs.surveyQuestionLibraryQuestionGet, param);
  };

  static signedNoteGet = param => {
    return axios.post(URLs.signedNoteGet, param);
  };

  static dashboardTaskListGet = param => {
    return axios.post(URLs.dashboardTaskListGet, param);
  };

  static dashboardCourseListGet = param => {
    return axios.post(URLs.dashboardCourseListGet, param);
  };

  static dashboardContractListGet = param => {
    return axios.post(URLs.dashboardContractListGet, param);
  };

  static dashboardAlertListGet = param => {
    return axios.post(URLs.dashboardAlertListGet, param);
  };

  static centerLaborBurdenGet = param => {
    return axios.post(URLs.centerLaborBurdenGet, param);
  };

  static centerLaborBurdenUpsert = param => {
    return axios.post(URLs.centerLaborBurdenUpsert, param);
  };

  static studentTimerUpsert = param => {
    return axios.post(URLs.studentTimerUpsert, param);
  };

  static getAppointmentClient = param => {
    return axios.post(URLs.getAppointmentClient, param);
  };

  static appointmentNoteListGet = param => {
    return axios.post(URLs.appointmentNoteListGet, param);
  };

  static comboServiceCodeGet = param => {
    return axios.post(URLs.comboServiceCodeGet, param);
  };

  static noteTypeListGet = param => {
    return axios.post(URLs.noteTypeListGet, param);
  };

  static noteTypeUpsert = param => {
    return axios.post(URLs.noteTypeUpsert, param);
  };

  static studentMergeComboListGet = param => {
    return axios.post(URLs.studentMergeComboListGet, param);
  };

  static studentMergeDetailsGet = param => {
    return axios.post(URLs.studentMergeDetailsGet, param);
  };

  static studentMergeSecondaryDetailsGet = param => {
    return axios.post(URLs.studentMergeSecondaryDetailsGet, param);
  };

  static studentMerge = param => {
    return axios.post(URLs.studentMerge, param);
  };

  static studentTwilioTranscriptGet = param => {
    return axios.post(URLs.studentTwilioTranscriptGet, param);
  };

  static powerBiReportListGet = param => {
    return axios.post(URLs.powerBiReportListGet, param);
  };

  static appointmentPreSelectedGet = param => {
    return axios.post(URLs.appointmentPreSelectedGet, param);
  };

  static callNotesAugment = param => {
    return axios.post(URLs.callNotesAugment, param);
  };

  static callSummaryAugment = param => {
    return axios.post(URLs.callSummaryAugment, param);
  };

  static gptAugmentationUpdate = param => {
    return axios.post(URLs.gptAugmentationUpdate, param);
  };

  static centerFieldGet = param => {
    return axios.post(URLs.centerFieldGet, param);
  };

  static centerFieldUpsert = param => {
    return axios.post(URLs.centerFieldUpsert, param);
  };

  static clientSendMobileAppInvite = param => {
    return axios.post(URLs.clientSendMobileAppInvite, param);
  };

  static smartContractPatientListGet = param => {
    return axios.post(URLs.smartContractPatientListGet, param);
  };

  static smartContractListGet = param => {
    return axios.post(URLs.smartContractListGet, param);
  };

  static termsConditionListGet = param => {
    return axios.post(URLs.termsConditionListGet, param);
  };

  static termsConditionUpsert = param => {
    return axios.post(URLs.termsConditionUpsert, param);
  };

  static termsConditionGetAllInfoJson = param => {
    return axios.post(URLs.termsConditionGetAllInfoJson, param);
  };

  static centerConfigGet = param => {
    return axios.post(URLs.centerConfigGet, param);
  };

  static centerConfigGetById = param => {
    return axios.post(URLs.centerConfigGetById, param);
  };

  static payerPriceDetailsGet = param => {
    return axios.post(URLs.payerPriceDetailsGet, param);
  };

  static payerPortalRequestUpsert = param => {
    return axios.post(URLs.payerPortalRequestUpsert, param);
  };

  static clientInsuranceComboGet = param => {
    return axios.post(URLs.clientInsuranceComboGet, param);
  };

  static smartContractTermsConditionGetAllInfoJson = param => {
    return axios.post(URLs.smartContractTermsConditionGetAllInfoJson, param);
  };

  static termsConditionDelete = param => {
    return axios.post(URLs.termsConditionDelete, param);
  };

  static termsConditionComboGet = param => {
    return axios.post(URLs.termsConditionComboGet, param);
  };

  static comboLabOrderPractitioner = param => {
    return axios.post(URLs.comboLabOrderPractitioner, param);
  };

  static smartContractPayersListGet = param => {
    return axios.post(URLs.smartContractPayersListGet, param);
  };

  static payerPortalRequestGet = param => {
    return axios.post(URLs.payerPortalRequestGet, param);
  };

  static groupServiceLineApproveAllBillingOrBatching = param => {
    return axios.post(URLs.groupServiceLineApproveAllBillingOrBatching, param);
  };

  static payerTermsConditionsStatusUpsert = param => {
    return axios.post(URLs.payerTermsConditionsStatusUpsert, param);
  };

  static clientCareTeamListGet = param => {
    return axios.post(URLs.clientCareTeamListGet, param);
  };

  static dashboardMobileUserGetAll = param => {
    return axios.post(URLs.dashboardMobileUserGetAll, param);
  };

  static entMobileUserDashboardGet = param => {
    return axios.post(URLs.entMobileUserDashboardGet, param);
  };

  static recoveryCapitalQuadrantGraphGet = param => {
    return axios.post(URLs.recoveryCapitalQuadrantGraphGet, param);
  };

  static clientMergeGet = param => {
    return axios.post(URLs.clientMergeGet, param);
  };

  static clientMergeSecondaryGet = param => {
    return axios.post(URLs.clientMergeSecondaryGet, param);
  };

  static clientComboGetAll = param => {
    return axios.post(URLs.clientComboGetAll, param);
  };

  static clientMerge = param => {
    return axios.post(URLs.clientMerge, param);
  };

  static clinicalToIntakeMovePatient = param => {
    return axios.post(URLs.clinicalToIntakeMovePatient, param);
  };

  static staffLicenseAgreementUpdate = param => {
    return axios.post(URLs.staffLicenseAgreementUpdate, param);
  };

  static smartContractTermsConditionUpsert = param => {
    return axios.post(URLs.smartContractTermsConditionUpsert, param);
  };

  static userTagTypeInsert = param => {
    return axios.post(URLs.userTagTypeInsert, param);
  };

  static userTagTypeListGet = param => {
    return axios.post(URLs.userTagTypeListGet, param);
  };

  static userTagTypeComboListGet = param => {
    return axios.post(URLs.userTagTypeComboListGet, param);
  };

  static userTagTypeUpdate = param => {
    return axios.post(URLs.userTagTypeUpdate, param);
  };

  static listProspectAppointment = param => {
    return axios.post(URLs.listProspectAppointment, param);
  };

  static recoveryCapitalTrendGraphGet = param => {
    return axios.post(URLs.recoveryCapitalTrendGraphGet, param);
  };

  static careTeamAssign = param => {
    return axios.post(URLs.careTeamAssign, param);
  };

  static serviceCodeCareTeamGet = param => {
    return axios.post(URLs.serviceCodeCareTeamGet, param);
  };

  static smartContractTabGet = param => {
    return axios.post(URLs.smartContractTabGet, param);
  };

  static smartContractDistributionGet = param => {
    return axios.post(URLs.smartContractDistributionGet, param);
  };

  static smartContractBundleSubmitToPayer = param => {
    return axios.post(URLs.smartContractBundleSubmitToPayer, param);
  };

  static smartContractPayerBundleGetToSubmit = param => {
    return axios.post(URLs.smartContractPayerBundleGetToSubmit, param);
  };

  static serviceCodeSuggestionsGenerate = param => {
    return axios.post(URLs.serviceCodeSuggestionsGenerate, param);
  };

  static serviceCodeSuggestionListGet = param => {
    return axios.post(URLs.serviceCodeSuggestionListGet, param);
  };

  static peerRecoveryServiceCodeUpdate = param => {
    return axios.post(URLs.peerRecoveryServiceCodeUpdate, param);
  };

  static broadCastUserListGet = param => {
    return axios.post(URLs.broadCastUserListGet, param);
  };

  static clientReportGet = param => {
    return axios.post(URLs.clientReportGet, param);
  };

  static clientRiskLevelGraphGet = param => {
    return axios.post(URLs.clientRiskLevelGraphGet, param);
  };

  static contractPeriodGraphGet = param => {
    return axios.post(URLs.contractPeriodGraphGet, param);
  };

  static dashboardClaimsGet = param => {
    return axios.post(URLs.dashboardClaimsGet, param);
  };

  static dashboardCardsGet = param => {
    return axios.post(URLs.dashboardCardsGet, param);
  };

  static riskProfileDataGraphGet = param => {
    return axios.post(URLs.riskProfileDataGraphGet, param);
  };

  static appUserJournalAugmentGet = param => {
    return axios.post(URLs.appUserJournalAugmentGet, param);
  };

  static appUserJournalAugmentation = param => {
    return axios.post(URLs.appUserJournalAugmentation, param);
  };

  static studentJournalAugmentGet = param => {
    return axios.post(URLs.studentJournalAugmentGet, param);
  };

  static studentJournalAugmentation = param => {
    return axios.post(URLs.studentJournalAugmentation, param);
  };

  static studentCourseAugmentationGet = param => {
    return axios.post(URLs.studentCourseAugmentationGet, param);
  };

  static studentCourseAugmentation = param => {
    return axios.post(URLs.studentCourseAugmentation, param);
  };

  static dashboardRadarGraphGet = param => {
    return axios.post(URLs.dashboardRadarGraphGet, param);
  };

  static smartContractDocumentRequestGet = param => {
    return axios.post(URLs.smartContractDocumentRequestGet, param);
  };

  static gptAugmentationGet = param => {
    return axios.post(URLs.gptAugmentationGet, param);
  };

  static taskComboGet = param => {
    return axios.post(URLs.taskComboGet, param);
  };

  static recoveryCategoryComboGet = param => {
    return axios.post(URLs.recoveryCategoryComboGet, param);
  };

  static gptAugmentationWithAI = param => {
    return axios.post(URLs.gptAugmentationWithAI, param);
  };

  static smartContractClone = param => {
    return axios.post(URLs.smartContractClone, param);
  };

  static taskClientUpsert = param => {
    return axios.post(URLs.taskClientUpsert, param);
  };

  static taskClientListGet = param => {
    return axios.post(URLs.taskClientListGet, param);
  };

  static taskClientUpdate = param => {
    return axios.post(URLs.taskClientUpdate, param);
  };

  static taskClientReassign = param => {
    return axios.post(URLs.taskClientReassign, param);
  };

  static studentNoteHistoryGet = param => {
    return axios.post(URLs.studentNoteHistoryGet, param);
  };

  static appUserJournalHistoryGet = param => {
    return axios.post(URLs.appUserJournalHistoryGet, param);
  };

  static appointmentGetClientUpcomingSchedule = param => {
    return axios.post(URLs.appointmentGetClientUpcomingSchedule, param);
  };

  static listClientOutstandingBalanceGet = param => {
    return axios.post(URLs.listClientOutstandingBalanceGet, param);
  };

  static preIntakeGetAll = param => {
    return axios.post(URLs.preIntakeGetAll, param);
  };

  static clientSharedMemoUpsert = param => {
    return axios.post(URLs.clientSharedMemoUpsert, param);
  };

  static clientSharedMemoGetAll = param => {
    return axios.post(URLs.clientSharedMemoGetAll, param);
  };

  static clientSharedMemoUpdate = param => {
    return axios.post(URLs.clientSharedMemoUpdate, param);
  };

  static feedbackFormAnalyticsGet = param => {
    return axios.post(URLs.feedbackFormAnalyticsGet, param);
  };

  static feedbackFormGet = param => {
    return axios.post(URLs.feedbackFormGet, param);
  };

  static feedbackFormDelete = param => {
    return axios.post(URLs.feedbackFormDelete, param);
  };

  static feedbackFormListGet = param => {
    return axios.post(URLs.feedbackFormListGet, param);
  };

  static appointmentAutoSelectGet = param => {
    return axios.post(URLs.appointmentAutoSelectGet, param);
  };
}
