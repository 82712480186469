import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  NoRisk,
  LowRisk,
  MediumRisk,
  HighRisk,
} from '../../RCM/patientEnrollmentForm/formSteps/questionType/risk';

class ClientRiskChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { ClientRisk } = this.props;
    return (
      <div className="d-flex align-items-center">
        <div className="d-flex risk-chart px-4">
          <span className="mr-2" style={{ fontSize: '14px', fontWeight: '400' }}>
            Client Risk Chart:
          </span>
          {ClientRisk === '0' && NoRisk}
          {ClientRisk === '1' && LowRisk}
          {ClientRisk === '2' && MediumRisk}
          {ClientRisk === '3' && HighRisk}
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(ClientRiskChart));
