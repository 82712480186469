import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicGrid from '../Components/DynamicGrid';
import { getClientCounselor, listCombos, getDynamicComos } from '../store/actions';
import { ProcessCombos } from '../store/comboConfig';
import PageTitle from '../Components/PageTitle';

class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );
    var result = ProcessCombos('ClientType');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    let comboTypes = {
      ComboTypes: 'CenterServiceTypeCenter',
      Json: JSON.stringify({
        Combo: [
          {
            StudentId: null,
            StudentCenterId: null,
          },
        ],
      }),
    };
    this.props.dispatch(getDynamicComos(comboTypes));
    this.props.dispatch(
      getClientCounselor({
        CenterId: this.state.filterCenterId,
      }),
    );
  }
  render() {
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Client" />
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h3 class="page-title mb-3 mb-md-0 text-uppercase">Client List</h3>
            </div>
          </div>
          <div />
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 " style={{ backgroundColor: 'unset', boxShadow: 'none' }}>
                <div className="dynamic-grid">
                  <DynamicGrid ScreenName={'Student'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Students);
