import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  getGenderPieChartEmbedInfo,
  getRefernceSourceTimeInTreatmentReport,
  resetGetGenderPieChartEmbedInfo,
  resetGetRefernceSourceTimeInTreatmentReport,
  clientStatusReport,
  userTriggerReport,
} from '../store/actions';
import { connect } from 'react-redux';
import Loader, { Loader2 } from '../Components/Loader';

import 'chartjs-plugin-datalabels';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import PageTitle from '../Components/PageTitle';
let reportNames = {
  0: 'Gender_Pie_Chart_DirectQueryMode',
  1: 'ReferenceSource_TimeInTreatmentPlan',
};

class EmbedPowerBI extends Component {
  constructor() {
    super();
    this.state = {
      reportName: null,
      embedURL: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    // dispatch(getGenderPieChartEmbedInfo({}));
    // dispatch(getRefernceSourceTimeInTreatmentReport({}));
    var param = new URLSearchParams(this.props.history.location.search);
    var reportName = param.get('reportName');
    if (reportName) {
      if (reportName == 'Gender_Pie_Chart_DirectQueryMode') {
        dispatch(getGenderPieChartEmbedInfo({}));
        dispatch(resetGetRefernceSourceTimeInTreatmentReport({}));
        this.setState({
          reportName: reportName,
        });
      } else if (reportName == 'ReferenceSource_TimeInTreatmentPlan') {
        dispatch(getRefernceSourceTimeInTreatmentReport({}));
        dispatch(resetGetGenderPieChartEmbedInfo({}));
        this.setState({
          reportName: reportName,
        });
      } else if (reportName == 'ClientStatus_Data') {
        dispatch(clientStatusReport({}));
        this.setState({
          reportName: reportName,
        });
      } else if (reportName == 'UserTriggers') {
        dispatch(userTriggerReport({}));
        this.setState({
          reportName: reportName,
        });
      }
    }
  }

  componentWillReceiveProps({ embedPowerBI }) {
    if (embedPowerBI && embedPowerBI != this.props.embedPowerBI) {
      embedPowerBI.map(item => {
        if (item.reportName == this.state.reportName) {
          this.setState({ embedURL: item.embedUrl });
        }
      });
    }
  }
  render() {
    const { embedPowerBIToken } = this.props;
    const { embedURL } = this.state;

    return (
      <div>
        <PageTitle TitleText="Power BI" />
        {/* <Loader loading={isFetching} /> */}
        <div class="container-fluid dashboard-analytics">
          <div>
            <PowerBIEmbed
              embedConfig={{
                // type:  powerBI && powerBI.type,
                type: 'report', // Supported types: report, dashboard, tile, visual and qna
                embedUrl: embedURL && embedURL,
                accessToken: embedPowerBIToken && embedPowerBIToken,
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    function() {
                      console.log('Report loaded');
                    },
                  ],
                  [
                    'rendered',
                    function() {
                      console.log('Report rendered');
                    },
                  ],
                  [
                    'error',
                    function(event) {
                      console.log(event.detail);
                    },
                  ],
                  [
                    'onClick',
                    function(event) {
                      console.log(event.detail);
                    },
                  ],
                ])
              }
              cssClassName={'report-style-class-fullView'}
              getEmbeddedComponent={embeddedReport => {
                console.log(`Embedded object of type "${embeddedReport.embedtype}" received`);
                this.setState({ report: embeddedReport });
              }}
              // onClick={() => {}}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  get_gender_pie_chart_embed_info,
  get_refernce_source_time_in_treatment_report,
  client_status_report,
  user_trigger_report,
}) => {
  const { data: genderEmbedPowerBi } = get_gender_pie_chart_embed_info;
  const { data: treatmentEmbedPowerBi } = get_refernce_source_time_in_treatment_report;
  const { data: clientStatusPowerBi } = client_status_report;
  const { data: userTriggerPowerBi } = user_trigger_report;

  let embedPowerBI = [];
  let embedPowerBIToken = null;

  if (genderEmbedPowerBi && genderEmbedPowerBi.result && genderEmbedPowerBi.success) {
    embedPowerBI = genderEmbedPowerBi.result.embedReport;
    embedPowerBIToken = genderEmbedPowerBi.result.embedToken.token;
  }
  if (treatmentEmbedPowerBi && treatmentEmbedPowerBi.result && treatmentEmbedPowerBi.success) {
    embedPowerBI = treatmentEmbedPowerBi.result.embedReport;
    embedPowerBIToken = treatmentEmbedPowerBi.result.embedToken.token;
  }
  if (clientStatusPowerBi && clientStatusPowerBi.result && clientStatusPowerBi.success) {
    embedPowerBI = clientStatusPowerBi.result.embedReport;
    embedPowerBIToken = clientStatusPowerBi.result.embedToken.token;
  }
  if (userTriggerPowerBi && userTriggerPowerBi.result && userTriggerPowerBi.success) {
    embedPowerBI = userTriggerPowerBi.result.embedReport;
    embedPowerBIToken = userTriggerPowerBi.result.embedToken.token;
  }

  return { embedPowerBI, embedPowerBIToken };
};

export default connect(mapStateToProps)(EmbedPowerBI);
