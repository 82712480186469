import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Radio } from 'antd';
import { isComparer } from '../util/handler';
import {
  adminstrationListStaff,
  isLicenseAvailable,
  isManageLicenseRequired,
} from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import CustomModal from '../Components/CustomModal';
import renderHTML from 'react-render-html';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../component/custom-redux-components';
import { saveAs } from 'file-saver';
import PageTitle from '../Components/PageTitle';
class FilterModal extends Component {
  constructor(props) {
    super(props);
    const {
      checked,
      filterCenterName,
      filterSubregion,
      filterRegion,
      filterCountry,
      filterState,
    } = props.appliedFilter;
    this.state = {
      checked: checked || null,
      filterCenterName: filterCenterName || null,
      filterRegion: filterRegion || null,
      filterSubregion: filterSubregion || null,
      filterCountry: filterCountry || null,
      filterState: filterState,
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, data) => {
    this.setState({
      [key]: data,
    });
  };

  handleCenterNameChange = filterCenterName => {
    this.setState({ filterCenterName });
  };
  handleStaffRoleChange = filterStaffRole => {
    this.setState({ filterStaffRole });
  };
  handleRegionChange = filterRegion => {
    this.setState({ filterRegion });
  };
  handleSubregionChange = filterSubregion => {
    this.setState({ filterSubregion });
  };
  handleCountryChange = filterCountry => {
    this.setState({ filterCountry });
  };
  handleStateChange = filterState => {
    this.setState({ filterState });
  };

  resetState = () =>
    this.setState({
      checked: '',
      filterCenterName: '',
      filterSubregion: '',
      filterRegion: '',
      filterCountry: '',
      filterState: '',
    });

  render() {
    const { onOk, onCancel, refreshGrid, filterComboData } = this.props;
    const {
      checked,
      filterCenterName,
      filterSubregion,
      filterRegion,
      filterCountry,
      filterState,
    } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0 filter-modal">
            <div class="col-12 text-left mb-3">
              <span className="filter-status">Staff Status</span>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mr-2 mb-2">
                  <Radio.Group
                    name="radiogroup"
                    onChange={this.onChange}
                    value={checked}
                    className="course-filter"
                  >
                    <Radio value="ALL" style={{ paddingRight: '50px' }}>
                      All
                    </Radio>
                    <Radio value="Deactivated">Deactivated</Radio>
                    <Radio value="Active">Active</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            {/* <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={filterComboData.CenterName}
                  onChange={this.handleCenterNameChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterCenterName}
                  placeholder="Center Name"
                />
              </div>
            </div>
            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={filterComboData.Region}
                  onChange={this.handleRegionChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterRegion}
                  placeholder="Region"
                />
              </div>
            </div>
             */}
            {/* <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={filterComboData.Subregion}
                  onChange={this.handleSubregionChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterSubregion}
                  placeholder="Sub-region"
                />
              </div>
            </div> */}
            {/* <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={filterComboData.Country}
                  onChange={this.handleCountryChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterCountry}
                  placeholder="Country"
                />
              </div>
            </div> */}
            {/* <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={filterComboData.State}
                  onChange={this.handleStateChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterState}
                  placeholder="State"
                />
              </div>
            </div> */}

            {/* NameCourse Input */}
            {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Course Name</span>
            </div> */}

            {/* <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-20px' }}>
                  <input
                    type="text"
                    class="form-control"
                    onChange={courseName => {
                      var CourName = (courseName && courseName.target.value) || null;
                      this.setState({
                        nameCourse: CourName,
                      });
                    }}
                    placeholder="Course Name"
                  />
                </div>
              </div>
            </div> */}
            {/* NameCourse Input */}

            {/* <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Staff Info</span>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mr-2 mb-2">
                  <Radio.Group
                    name="radiogroup"
                    onChange={this.onChange}
                    value="all"
                    className="course-filter"
                  >
                    <Radio value="all" style={{ paddingRight: '50px' }}>
                      All
                    </Radio>
                    <Radio value="centerName">De-Active</Radio>
                    <Radio value="001">Active</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  // options={CourseTopic}
                  // onChange={this.handleCourseTopicChange}
                  className="basic-single"
                  classNamePrefix="select"
                  // value={filterCourseTopic}
                  placeholder="Role"
                />
              </div>
            </div>
            <div class="col-12 col-md-12 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  // options={CourseTopic}
                  // onChange={this.handleCourseTopicChange}
                  className="basic-single"
                  classNamePrefix="select"
                  // value={filterCourseTopic}
                  placeholder="App Role"
                />
              </div>
            </div>
           */}
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              // onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
          <button
            type="button"
            class="btn"
            onClick={() => {
              onOk();
              refreshGrid(
                checked,
                filterCenterName,
                filterSubregion,
                filterRegion,
                filterCountry,
                filterState,
              );
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

const FilterModalWithRouter = withRouter(FilterModal);

class Staff extends Component {
  state = {
    searchStr: null,
  };

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getFilterType() {
    const { history } = this.props;
    var param = new URLSearchParams(history.location.search);
    return param.get('centerId') || param.get('centerid') || null;
  }

  componentDidMount({ searchStr } = this.state) {
    const { dispatch } = this.props;
    dispatch(
      adminstrationListStaff({ filterCenterId: this.getFilterType(), searchStr: searchStr }),
      isLicenseAvailable({}),
    );
    dispatch(isManageLicenseRequired({}));
  }

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    const { dispatch } = this.props;
    if (value.length == 0) {
      dispatch(
        adminstrationListStaff({ Filter: this.getFilterType(), searchStr: this.state.searchStr }),
      );
    } else {
      dispatch(
        adminstrationListStaff({ Filter: this.getFilterType(), searchStr: this.state.searchStr }),
      );
    }
  };

  onRowClick = (event, record, rowIndex) => {
    const { history } = this.props;
    history.push('/manage/staff-form?staffId=' + record.StaffId);
  };

  componentWillReceiveProps({ availableLicenseCount }) {
    if (
      availableLicenseCount != null &&
      availableLicenseCount !== this.props.availableLicenseCount
    ) {
      if (availableLicenseCount == 0) {
        this.setState({ modalVisible: true });
      } else {
        this.props.history.push('/manage/staff-form');
      }
    }
  }
  refreshGrid = (
    checked,
    filterCenterName,
    filterSubregion,
    filterRegion,
    filterCountry,
    filterState,
  ) => {
    const { searchStr } = this.state;
    this.setState({
      checked,
      filterCenterName,
      filterSubregion,
      filterRegion,
      filterCountry,
      filterState,
    });
    this.props.dispatch(
      adminstrationListStaff({
        // Filter: filterType ? filterType.toString() : null,
        // searchStr: searchStr,
        // searchStr: searchStr == null ? nameCourse : searchStr,
        Filter: checked ? checked.toString() : null,
        // filterCenterId: filterCenterName > 0 ? filterCenterName : null,
        // FilterRegion: filterRegion > 0 ? filterRegion : null,
        // FilterStateOrProvince: filterState > 0 ? filterState : null,
        // searchStr: searchStr == null ? filterNameCourse : searchStr,
      }),
    );
  };
  downloadAsExcel = () => {
    const { dispatch } = this.props;
    this.setState({ idFileDownloading: true });
    const me = this;
    var fileName;
    const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
    var hearders = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Accept: 'application/vnd.ms-excel',
    };

    fetch(`/api/v1/Adminstration/DownloadToExcel`, {
      method: 'POST',
      headers: hearders,
      body: JSON.stringify({ InputType: 'Staff' }),
      responseType: 'arraybuffer',
    })
      .then(response => {
        if (response.ok) {
          fileName = response.headers
            .get('content-disposition')
            .split('filename=')[1]
            .split(';')[0];
          return response.blob();
        }
      })
      .then(blob => {
        me.setState({ idFileDownloading: false });
        saveAs(blob, fileName || 'Staff.xlsx');
      });
  };
  render() {
    const { isFetching, staffs, msg, filterComboData } = this.props;
    const { modalVisible, modalVisibleML } = this.state;
    const columns = [
      {
        title: 'Staff Id',
        dataIndex: 'StaffId',
        key: 'StaffId',
        // sorter: (a, b) => isComparer(a.StaffId, b.StaffId),
        // sortDirections: ['descend', 'ascend'],
        // defaultSortOrder: 'ascend',
      },
      {
        title: 'First Name',
        dataIndex: 'FirstName',
        key: 'FirstName',
        sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
        sortDirections: ['descend', 'ascend'],
        // defaultSortOrder: 'ascend',
      },
      {
        title: 'Last Name',
        dataIndex: 'LastName',
        key: 'LastName',
        sorter: (a, b) => isComparer(a.LastName, b.LastName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'App Display Name',
        dataIndex: 'AppUserName',
        key: 'AppUserName',
        sorter: (a, b) => isComparer(a.AppUserName, b.AppUserName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Parent Center Name',
        dataIndex: 'ParentCenterName',
        key: 'ParentCenterName',
        sorter: (a, b) => isComparer(a.ParentCenterName, b.ParentCenterName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Center Name',
        dataIndex: 'CenterName',
        key: 'CenterName',
        sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Role',
        dataIndex: 'StaffRoleName',
        key: 'StaffRoleName',
        sorter: (a, b) => isComparer(a.StaffRoleName, b.StaffRoleName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'App Role',
        dataIndex: 'MobileAppRoleDesc',
        key: 'MobileAppRoleDesc',
        sorter: (a, b) => isComparer(a.MobileAppRoleDesc, b.MobileAppRoleDesc),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Account Status',
        dataIndex: 'AccountStatus',
        key: 'AccountStatus',
        sorter: (a, b) => isComparer(a.AccountStatus, b.AccountStatus),
        sortDirections: ['descend', 'ascend'],
      },
    ];
    const { history } = this.props;
    let link;
    var param = new URLSearchParams(history.location.search);
    let centerId = param.get('centerId') || param.get('centerid');
    if (centerId) {
      link = '/manage/staff-form?centerId=' + centerId;
    } else {
      link = '/manage/staff-form';
    }
    return (
      <>
        <PageTitle TitleText="Staff" />
        <div class="container-fluid">
          {/* <Loader loading={isFetching} /> */}
          <div class="content-area">
            <div class="row top-table-search mt-5 mb-3 align-items-center">
              <div class="col-12 col-md-1 col-lg-1">
                {/* <Link to="/manage" class="back-link">
                  <img src="images/back-arrow.svg" /> Back
                </Link> */}
                <a
                  class="back-link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.history.goBack()}
                >
                  <img src="images/back-arrow.svg" /> Back
                </a>
                <h3 class="page-title mb-3 mb-md-0">Staff</h3>
              </div>
              <div class="col-12 col-md-11 col-lg-11 text-right">
                <div class="search-box d-inline-flex w-100">
                  <div class="form-group has-search w-100">
                    <input
                      type="text"
                      class="form-control shadow-sm"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div>
                <input
                  type="button"
                  className="btn black-btn"
                  value="Download Excel"
                  onClick={this.downloadAsExcel}
                />
                <CustomModal
                  ModalButton={({ onClick }) => (
                    <input
                      type="button"
                      class="btn btn-outline-primary"
                      value="Filter"
                      onClick={onClick}
                    />
                  )}
                  ModalContent={props => (
                    <FilterModalWithRouter
                      {...props}
                      // {...FilterModalData}
                      refreshGrid={this.refreshGrid}
                      filterComboData={filterComboData}
                      appliedFilter={this.state}
                    />
                  )}
                />
                <CustomModal
                  modalVisible={modalVisible}
                  onClose={() => this.setState({ modalVisible: false })}
                  ModalButton={({ onClick }) => (
                    <span class="delete-course">
                      <button
                        class="btn btn-outline-primary"
                        color="blue"
                        //onClick={onClick}
                        onClick={() => {
                          const { dispatch } = this.props;
                          dispatch(isLicenseAvailable({}));
                        }}
                      >
                        Add a Staff
                      </button>
                    </span>
                  )}
                  ModalContent={({ onOk, onCancel }) => (
                    <div class="modal-content border-0">
                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                        <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                          &times;
                        </button>
                        <span class="modal-title col-12"> {renderHTML(msg)}</span>
                      </div>

                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          data-dismiss="modal"
                          onClick={onCancel}
                        >
                          Close
                        </button>
                        <Link to="/manage/maintain-license" class="btn black-btn" color="blue">
                          Buy More License(s)
                        </Link>
                      </div>
                    </div>
                  )}
                />
                {this.props.isManageLicenseRequired && (
                  <CustomModal
                    modalVisible={modalVisibleML}
                    onClose={() => this.setState({ modalVisibleML: false })}
                    ModalButton={({ onClick }) => (
                      <span class="delete-course">
                        <button class="btn black-btn" color="blue" onClick={onClick}>
                          Manage License
                        </button>
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 ">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            &times;
                          </button>
                          {/* <span class="modal-title col-12"> {renderHTML(msg)}</span> */}
                        </div>
                        <span class="modal-title col-12 px-5">
                          No License Available for New Staff. You have to purchase more license
                        </span>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            type="button"
                            class="btn btn-outline-primary"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            Cancel
                          </button>
                          <Link to="/manage/maintain-license" class="btn black-btn" color="blue">
                            Buy More License
                          </Link>
                        </div>
                      </div>
                    )}
                  />
                )}
                {/* <button class="btn black-btn" color="blue"
                onClick={() => {
                  debugger
                  const { dispatch } = this.props;
                  dispatch(isLicenseAvailable());
                }}
              >
                Add A Staff
              </button> */}

                {/* <CustomModal
                ModalButton={({ onClick }) => (
                  // <span class="delete-course" onClick={onClick}>
                  <span class="delete-course">
                    <button
                      class="btn black-btn"
                      color="blue"
                      onClick={() => {
                        debugger
                        const { dispatch } = this.props;
                        dispatch(isLicenseAvailable());
                      }}
                    >
                      Add A Staff
                    </button>
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                        &times;
                      </button>
                      <h4 class="modal-title col-12">
                        No License Avaliable for New Staff.You have to purchase more license
                      </h4>
                    </div>

                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        type="button"
                        class="btn black-btn"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <Link to="/manage/maintainlicense" class="btn black-btn" color="blue">
                        Buy More License
                      </Link>
                    </div>
                  </div>
                )}
              /> */}
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-5">
                <div class="card border-0 ">
                  <div class="row card-body">
                    <div class="col-12">
                      <Loader2 loading={isFetching} />
                      <div className="row-hover-enabled">
                        <Table
                          columns={columns}
                          dataSource={staffs}
                          size={'middle'}
                          rowKey={record => record.StaffId}
                          onRow={(record, rowIndex) => {
                            return {
                              onClick: event => this.onRowClick(event, record, rowIndex),
                            };
                          }}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div class="dataTables_wrapper no-footer">
                                  <div class="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a class="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a class="paginate_button next">Next</a>
                                    ) : (
                                      <a class="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  staff_get_all,
  is_license_available,
  combos,
  is_manage_license_required,
}) => {
  var { data, isFetching, error } = staff_get_all;
  let availableLicenseCount = null;
  let msg = '';
  let isManageLicenseRequired = false;
  let filterComboData = {
    CenterName: [],
    StaffRole: [],
    Region: [],
    Subregion: [],
    Country: [],
    State: [],
    MobileAppRole: [],
  };
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }
  if (is_license_available && is_license_available.data && is_license_available.data.data) {
    availableLicenseCount = is_license_available.data.data[0].AvailableLicenseCount;
    msg = is_license_available.data.data[0].Msg;
  }
  if (is_manage_license_required.data) {
    isManageLicenseRequired = is_manage_license_required.data.IsManageLicenseRequired;
  }
  if (combos.data) {
    let centerName = [];
    let staffRole = [];
    let region = [];
    let subregion = [];
    let country = [];
    let state = [];
    let mobileAppRole = [];

    // if (combos.data.MobileAppRole) {
    //   for (var item of combos.data.MobileAppRole) {
    //     let val = item;
    //     val.Value = item.Text;
    //     mobileAppRole.push(val);
    //   }
    //   filterComboData.MobileAppRole = mobileAppRole;
    // }
    // if (combos.data.StaffRole) {
    //   for (var item of combos.data.StaffRole) {
    //     let val = item;
    //     val.Value = item.Text;
    //     staffRole.push(val);
    //   }
    //   filterComboData.StaffRole = staffRole;
    // }

    //   if (combos.data.Center) {
    //     for (var item of combos.data.Center) {
    //       centerName.push(item);
    //     }
    //     filterComboData.CenterName = centerName;
    //   }
    //   if (combos.data.Region) {
    //     for (var item of combos.data.Region) {
    //       let val = item;
    //       val.Value = item.Text;
    //       region.push(val);
    //     }
    //     filterComboData.Region = region;
    //   }

    //   if (combos.data.Region) {
    //     for (var item of combos.data.Region) {
    //       let val = item;
    //       val.Value = item.Text;
    //       subregion.push(val);
    //     }
    //     filterComboData.Subregion = subregion;
    //   }
    //   if (combos.data.StateProvince) {
    //     for (var item of combos.data.StateProvince) {
    //       let val = item;
    //       val.Value = item.Text;
    //       country.push(val);
    //     }
    //     filterComboData.Country = country;
    //   }
    //   if (combos.data.StateProvince) {
    //     for (var item of combos.data.StateProvince) {
    //       let val = item;
    //       val.Value = item.Text;
    //       state.push(val);
    //     }
    //     filterComboData.State = state;
    //   }
  }
  return {
    staffs: data || [],
    isFetching,
    availableLicenseCount,
    msg,
    isManageLicenseRequired,
    // filterComboData,
  };
};

export default connect(mapStateToProps)(Staff);
