import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { getStaffShiftLog, addStaffShiftLog, resetStaffShiftLog } from '../store/actions';
import SubmitButton from '../Components/submitButton';
import ShiftLogList from './shiftLogList';
import PageTitle from '../Components/PageTitle';

const { TextArea } = Input;

class ShiftLog extends Component {
  constructor(props) {
    super(props);
    const { startDate } = props;
    this.state = {
      startDate: startDate || null,
      staffLogNotes: '',
    };
  }

  componentWillReceiveProps({ isStaffShiftLog, dashboardMenu }) {
    if (isStaffShiftLog) {
      this.props.dispatch(resetStaffShiftLog());
      this.props.dispatch(getStaffShiftLog({}));
    }
  }
  onChange = e => this.setState({ checked: e.target.value });

  addNote = () => {
    this.props.dispatch(addStaffShiftLog({ Note: this.state.staffLogNotes }));
    this.setState({ staffLogNotes: '' });
    // this.props.onOk();
  };
  render() {
    const { isShiftLogUpserting } = this.props;
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Shift Log" />
        <div class="content-area">
          <div class="content-area">
            <div class="row top-table-search mt-5 mb-4 align-items-center">
              <div class="col-12 col-md-4 col-lg-4">
                <h3 class="page-title mb-3 mb-md-0">Shift Log</h3>
              </div>
            </div>
          </div>
          <div class="card border-0 ">
            <div class="row card-body">
              <div class="col-12">
                <div class="">
                  <div class="row m-0">
                    <div class="col-12 col-md-12 enrollment-form">
                      <div class="form-group">
                        <TextArea
                          showCount
                          maxLength={2000}
                          style={{ minHeight: '75px' }}
                          class="form-control"
                          placeholder="Add Shift Note"
                          rows="7"
                          value={this.state.staffLogNotes}
                          onChange={event => this.setState({ staffLogNotes: event.target.value })}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-12 px-2 mt-2" style={{ textAlign: 'right' }}>
                      <SubmitButton
                        loading={isShiftLogUpserting}
                        type="button"
                        onClick={this.addNote}
                        value={'Add'}
                        className={'px-5'}
                        style={{ height: '45px' }}
                      />
                    </div>
                  </div>
                  <div class="row m-0">
                    <ShiftLogList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ add_staff_shift_log }) => {
  const { isFetching: isShiftLogUpserting } = add_staff_shift_log;

  let isStaffShiftLog = false;

  if (add_staff_shift_log.data) {
    isStaffShiftLog = add_staff_shift_log.data.success;
  }
  return {
    isStaffShiftLog,
    isShiftLogUpserting,
  };
};
export default connect(mapStateToProps)(withRouter(ShiftLog));
