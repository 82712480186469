import faker from 'faker';
export const program = [
  {
    key: 'Center_id',
    dataIndex: 'Center_id',
    title: 'Center_id',
  },
  { key: 'Program_id', title: 'Program_id', dataIndex: '' },
  { key: 'Student_id', title: 'Student_id', dataIndex: '' },
  { key: 'Me_id', title: 'ME_id', dataIndex: '' },
  { key: 'Rm_id', title: 'Rm_id', dataIndex: '' },
  { key: 'Prog_typ', title: 'Prog_typ', dataIndex: '' },
  { key: 'Pro_name', title: 'Pro_name', dataIndex: '' },
  { key: 'Pro_desc', title: 'Pro_desc', dataIndex: '' },
  { key: 'Pro_abstract', title: 'Pro_abstract', dataIndex: '' },
  { key: 'Pro_pubdate', title: 'Pro_pubdate', dataIndex: '' },
  { key: 'Pro_author', title: 'Pro_author', dataIndex: '' },
  { key: 'Pro_copyw', title: 'Pro_copyw', dataIndex: '' },
  { key: 'Pro_url', title: 'Pro_url', dataIndex: '' },
  { key: 'Pro_length', title: 'Pro_length', dataIndex: '' },
  { key: 'Language', title: 'Language' },
];
export const center = [
  { key: 'Center_id', title: 'Center_id', dataIndex: '' },
  { key: 'Program_id', title: 'Program_id', dataIndex: '' },
  { key: 'Student_id', title: 'Student_id', dataIndex: '' },
  { key: 'Staff_id', title: 'Staff_id', dataIndex: '' },
  { key: 'Me_id', title: 'ME_id', dataIndex: '' },
  { key: 'Rm_id', title: 'Rm_id', dataIndex: '' },
  { key: 'Center name', title: 'Center name', dataIndex: '' },
  { key: 'Center director', title: 'Center director', dataIndex: '' },
  { key: 'Address1', title: 'Address1', dataIndex: '' },
  { key: 'Address2', title: 'Address2', dataIndex: '' },
  { key: 'City', title: 'City', dataIndex: '' },
  { key: 'state', title: 'state', dataIndex: '' },
  { key: 'Postal Code', title: 'Postal Code', dataIndex: '' },
  { key: 'Country', title: 'Country', dataIndex: '' },
  { key: 'phone1', title: 'phone1', dataIndex: '' },
  { key: 'phone2', title: 'phone2', dataIndex: '' },
  { key: 'Email 1', title: 'Email 1', dataIndex: '' },
  { key: 'Email 2', title: 'Email 2', dataIndex: '' },
  { key: 'Language', title: 'Language' },
];

export const student = [
  { key: 'Student_id', title: 'Student_id', dataIndex: 'Student_id' },
  { key: 'Center_id', title: 'Center_id', dataIndex: 'Center_id' },
  { key: 'Staff_id', title: 'Staff_id', dataIndex: 'Staff_id' },
  { key: 'Program_id', title: 'Program_id', dataIndex: 'Program_id' },
  { key: 'Me_id', title: 'Me_id', dataIndex: 'Me_id' },
  { key: 'Rm_id', title: 'Rm_id', dataIndex: 'Rm_id' },
  { key: 'Status', title: 'Status', dataIndex: 'Status' },
  { key: 'FName', title: 'FName', dataIndex: 'FName' },
  { key: 'MName', title: 'MName', dataIndex: 'MName' },
  { key: 'LName', title: 'LName', dataIndex: 'LName' },
  { key: 'DOB', title: 'DateOfBirth', dataIndex: 'DOB' },
  { key: 'Gen_typ', title: 'Gen_typ', dataIndex: 'Gen_typ' },
  { key: 'Birth_Country', title: 'Birth Country', dataIndex: 'Birth_Country' },
  { key: 'Nationality', title: 'Nationality', dataIndex: 'Nationality' },
  { key: 'Height', title: 'Height', dataIndex: 'Height' },
  { key: 'Weight', title: 'Weight', dataIndex: 'Weight' },
  { key: 'Eye_col', title: 'Eye_col', dataIndex: 'Eye_col' },
  { key: 'Language', title: 'Language', dataIndex: 'Language' },
  {
    key: 'Service_Delivered',
    title: 'Service_Delivered',
    dataIndex: 'Service_Delivered',
  },
  { key: 'Enroll_date', title: 'Enroll_date  ', dataIndex: 'Enroll_date' },
  { key: 'Exit_date', title: 'Exit_date', dataIndex: 'Exit_date' },
  { key: 'Exit_Reason', title: 'Exit_Reason', dataIndex: 'Exit_Reason' },
  { key: 'Housing_type', title: 'Housing_type', dataIndex: 'Housing_type' },
  {
    key: 'Family_Support',
    title: 'Family Support',
    dataIndex: 'Family Support',
  },
  { key: 'Faith_typ', title: 'Faith_typ', dataIndex: 'Faith_typ' },
  { key: 'Educ_typ', title: 'Educ_typ', dataIndex: 'Educ_typ' },
  { key: 'Work_skill', title: 'Work_skill', dataIndex: 'Work_skill' },
  { key: 'Abuse_typ', title: 'Abuse_typ', dataIndex: 'Abuse_typ' },
  { key: 'Druguse_typ', title: 'Druguse_typ', dataIndex: 'Druguse_typ' },
  { key: 'Addict_since', title: 'Addict_since  ', dataIndex: 'Addict_since' },
  {
    key: 'Addict_severity',
    title: 'Addict_severity',
    dataIndex: 'Addict_severity',
  },
  { key: 'Pr_recv_atmpt', title: 'Pr_recv_atmpt', dataIndex: 'Pr_recv_atmpt' },
  {
    key: 'Prior_recv_time',
    title: 'Prior_recv_time',
    dataIndex: 'Prior_recv_time',
  },
  { key: 'Prior_Housing', title: 'Prior Housing  ', dataIndex: 'Housing' },
  { key: 'Enroll_status', title: 'Enroll_status', dataIndex: 'Enroll_status' },
  { key: 'Prior_Abuse', title: 'Prior Abuse', dataIndex: 'Prior Abuse' },
];
function buildFakeUser(i) {
  return {
    key: i,
    Student_id: faker.internet.userName(),
    Center_id: faker.address.city(),
    Staff_id: faker.internet.userName(),
    Program_id: Math.floor(Math.random() * 30),
    Me_id: Math.floor(Math.random() * 30),
    Rm_id: Math.floor(Math.random() * 30),
    Status: Math.floor(Math.random() * 30) > 15 ? 'online' : 'offline',
    FName: faker.name.firstName(),
    MName: faker.name.firstName(),
    LName: faker.name.lastName(),
    DOB:
      Math.floor(Math.random() * 30) +
      ' -' +
      Math.floor(Math.random() * 12) +
      '-' +
      Math.floor(Math.random() * 18),
    Gen_typ: Math.floor(Math.random() * 30) > 15 ? 'M' : 'F',
    Birth_Country: faker.address.country(),
    Nationality: faker.address.city(),
    Height: Math.floor(Math.random() * 6) + 'ft',
    Weight: Math.floor(Math.random() * 3) + 'ft',
    Eye_col: faker.internet.color(),
    Language: faker.address.streetName(),
    Service_Delivered: faker.internet.color(),
    Enroll_date: 'now',
    Exit_date: 'never',
    Exit_Reason: 'not avialble',
    Housing_type: Math.floor(Math.random() * 30) > 15 ? 'big' : 'small',
    Family_Support: Math.floor(Math.random() * 30) > 15 ? 'none' : 'lot',
    Faith_typ: Math.floor(Math.random() * 30) > 15 ? 'hnd' : 'chris',
    Educ_typ: Math.floor(Math.random() * 30) > 15 ? 'no degrees' : 'a lot of degrees',
    Work_skill:
      Math.floor(Math.random() * 30) > 15
        ? 'large'
        : Math.floor(Math.random() * 30) > 15
        ? 'medium'
        : 'small',
    Abuse_typ: Math.floor(Math.random() * 30) > 15 ? 'less' : 'more',
    Druguse_typ: Math.floor(Math.random() * 30) > 15 ? 'less' : 'more',
    Addict_since: Math.floor(Math.random() * 30) > 15 ? 'A long time' : 'few days',
    Addict_severity: Math.floor(Math.random() * 30) > 15 ? 'less' : 'more',
    Pr_recv_atmpt: faker.internet.color(),
    Co_Occur_issue: faker.internet.color(),
    Prior_recv_time: faker.internet.color(),
    Prior_Housing: faker.internet.color(),
    Enroll_status: faker.internet.color(),
    Prior_Abuse: faker.internet.color(),
  };
}
export let rows = [];
for (let i = 0; i < 100; i++) {
  rows.push(buildFakeUser(i));
}

function buildFakeImg() {
  return {
    //image: faker.internet.avatar(),
    name: faker.internet.userName(),
    date: faker.date.recent().toDateString(),
  };
}

export const img = [];

for (let i = 0; i < 50; i++) {
  img.push(buildFakeImg());
}

export const data = [
  {
    name: 'root',
    toggled: true,
    children: [
      {
        name: 'parent',
        children: [{ name: 'child1' }, { name: 'child2' }],
      },
      {
        name: 'loading parent',
        loading: true,
        children: [],
      },
      {
        name: 'parent',
        children: [
          {
            name: 'nested parent',
            children: [{ name: 'nested child 1' }, { name: 'nested child 2' }],
          },
        ],
      },
    ],
  },
  {
    name: 'root',
    toggled: true,
    children: [
      {
        name: 'parent',
        children: [{ name: 'child1' }, { name: 'child2' }],
      },
      {
        name: 'loading parent',
        loading: true,
        children: [],
      },
      {
        name: 'parent',
        children: [
          {
            name: 'nested parent',
            children: [{ name: 'nested child 1' }, { name: 'nested child 2' }],
          },
        ],
      },
    ],
  },
];
export const columns = { student, center, program };

export const studentsList = [
  {
    id: 1,
    image: 'images/student-list-01.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Jan 5th, 1998/20',
    gender: 'Female',
    admissionDate: 'Jun 10th, 2018',
    dismissionDate: '',
    status: '2 Overdue',
    statusColor: 'red-text',
  },
  {
    id: 2,
    image: 'images/pro-pic-02.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: '1 Overdue',
    statusColor: 'red-text',
  },
  {
    id: 3,
    image: 'images/pro-pic-03.jpg',
    firstName: 'Tierna',
    lastName: 'Davidson',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: '1 Overdue',
    statusColor: 'red-text',
  },
  {
    id: 4,
    image: 'images/student-list-02.jpg',
    firstName: 'Andrija',
    lastName: 'Novakovich',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: 'Dec 1st,2012',
    status: '',
    statusColor: 'red-text',
  },
  {
    id: 5,
    image: 'images/student-list-01.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Jan 5th, 1998/20',
    gender: 'Female',
    admissionDate: 'Jun 10th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 6,
    image: 'images/pro-pic-02.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 7,
    image: 'images/pro-pic-03.jpg',
    firstName: 'Tierna',
    lastName: 'Davidson',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 8,
    image: 'images/student-list-02.jpg',
    firstName: 'Andrija',
    lastName: 'Novakovich',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: 'Dec 1st,2012',
    status: '',
    statusColor: '',
  },
  {
    id: 9,
    image: 'images/student-list-01.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Jan 5th, 1998/20',
    gender: 'Female',
    admissionDate: 'Jun 10th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 10,
    image: 'images/pro-pic-02.jpg',
    firstName: 'Alex',
    lastName: 'Morgan',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 11,
    image: 'images/pro-pic-03.jpg',
    firstName: 'Tierna',
    lastName: 'Davidson',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
  {
    id: 12,
    image: 'images/student-list-02.jpg',
    firstName: 'Andrija',
    lastName: 'Novakovich',
    dob: 'Dec 5th, 1998/20',
    gender: 'Male',
    admissionDate: 'Aug 11th, 2018',
    dismissionDate: '',
    status: 'Current',
    statusColor: '',
  },
];
