import React from 'react';
import { Tooltip } from 'antd';

export const ToolTip = value => {
  return (
    value && (
      <Tooltip placement="topLeft" title={value}>
        <div className="text-tooltip">
          <span>{value}</span>
        </div>
      </Tooltip>
    )
  );
};
