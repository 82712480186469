import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ReduxSelect } from '../../../component/custom-redux-components';
import { clientRiskLevelGraphGet, riskProfileDataGraphGet } from '../../../store/actions';
import { Loader2 } from '../../../Components/Loader';

export default class RiskProfileData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: null,
      filterId: null,
      series: [
        {
          name: 'High Risk',
          data: [],
        },
        {
          name: 'Medium Risk',
          data: [],
        },
        {
          name: 'Low Risk',
          data: [],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 430,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: '10%',
            // distributed: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        colors: ['#FF6B6B', '#FFE194', '#6DEAB3'],
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        legeng: {
          showForSingleSeries: true,
          showForNullSeries: true,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  }

  componentWillReceiveProps({ clientRisk }) {
    if (clientRisk && clientRisk != this.props.clientRisk) {
      const { Practitioners, StaffRoles, DatesDropDown } = clientRisk;

      // this.setState({ datesDropDown: DatesDropDown });
      const { series } = this.state;
      if (Practitioners && Practitioners.length > 0) {
        let lowRisk = new Array(Practitioners.length).fill(0);
        let mediumRisk = new Array(Practitioners.length).fill(0);
        let highRisk = new Array(Practitioners.length).fill(0);
        let xAxis = [];

        Practitioners &&
          Practitioners.length > 0 &&
          Practitioners.map((item, index) => {
            const { PractitionerName, Risk } = item;
            xAxis.push(PractitionerName);
            Risk &&
              Risk.length > 0 &&
              Risk.map((pItem, pIndex) => {
                if (pItem.ScoreCategory == 1) {
                  // lowRisk.push(pItem.CategoryPercent);
                  lowRisk[index] = pItem.CategoryPercent;
                } else if (pItem.ScoreCategory == 2) {
                  // mediumRisk.push(pItem.CategoryPercent);
                  mediumRisk[index] = pItem.CategoryPercent;
                } else if (pItem.ScoreCategory == 3) {
                  // highRisk.push(pItem.CategoryPercent);
                  highRisk[index] = pItem.CategoryPercent;
                }
              });
          });

        let risks = [
          { ...series[0], data: [...highRisk] },
          { ...series[1], data: [...mediumRisk] },
          { ...series[2], data: [...lowRisk] },
        ];

        this.setState({
          series: [...risks],
        });
        this.setState(prevState => {
          return {
            ...prevState,
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: [...xAxis],
              },
            },
          };
        });
      } else {
        this.setState({
          series: [
            {
              name: 'High Risk',
              data: [],
            },
            {
              name: 'Medium Risk',
              data: [],
            },
            {
              name: 'Low Risk',
              data: [],
            },
          ],
        });
      }
    }
  }

  handleFilter = (staffId, filterId) => {
    const { datesDropdown } = this.props;
    // if (filterId) {
    this.setState({ filterId, staffId });
    // }
    // if (staffId) {
    //   this.setState({ staffId });
    // }
    let dates =
      datesDropdown &&
      datesDropdown.filter((item, index) => {
        return item.Value == filterId;
      });
    this.handleContractPeriod(
      dates[0] ? dates[0].StartDate : null,
      dates[0] ? dates[0].EndDate : null,
      staffId,
    );
  };

  handleContractPeriod = (startDate, endDate, staffId) => {
    this.props.dispatch(
      riskProfileDataGraphGet({
        JSON: JSON.stringify({
          Dashboard: [
            {
              CenterId: this.props.centerId || null,
              StartDate: startDate,
              EndDate: endDate,
              StaffRoleId: staffId || null,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { clientRisk, datesDropdown, isCTRiskFetching } = this.props;
    const { Practitioners, StaffRoles, DatesDropDown } = clientRisk;

    return (
      <div className="col-12 payer-contracts-period">
        <div className="card border-0">
          <div className="card-body">
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              style={{ borderBottom: '1px solid #CCC', padding: '16px' }}
            >
              <p className="heading" style={{ fontSize: '20px', fontWeight: '700' }}>
                Risk Profile Data
              </p>
              <div className="d-flex ">
                <div className="d-flex align-items-center payer-portal-cente mr-2">
                  <div className="form-group mb-0" style={{ width: '180px' }}>
                    <ReduxSelect
                      name={`staffId`}
                      // component={ReduxSelect}
                      options={StaffRoles ? StaffRoles : []}
                      onChange={value => this.handleFilter(value, this.state.filterId)}
                      value={this.state.staffId}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center payer-portal-center">
                  <div className="form-group mb-0" style={{ width: '160px' }}>
                    <ReduxSelect
                      name={`filterId`}
                      // component={ReduxSelect}
                      options={datesDropdown ? datesDropdown : []}
                      onChange={value => this.handleFilter(this.state.staffId, value)}
                      value={this.state.filterId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="" style={{ position: 'relative' }}>
              <Loader2 loading={isCTRiskFetching} />
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height={430}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
