import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
// import AntdModal from '../../../Components/CustomModal';
import { Link, withRouter } from 'react-router-dom';
import ListRenderer from '../../../Components/listRenderer';
import ClinicalAndGroupNotes from './clinicalAndGroupNotes';
import { signedNoteGet } from '../../../store/actions';

class UnSignNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      searchStr: null,
      isClinicalNotes: null,
    };
  }
  list = [
    {
      Name: 'Clinical Notes',
      ExtraComponent: props => {
        return (
          <div className="">
            <ClinicalAndGroupNotes isClinicalNotes={true} />
          </div>
        );
      },
    },
    {
      Name: 'Group Notes',
      ExtraComponent: props => {
        return (
          <div className="">
            <ClinicalAndGroupNotes isClinicalNotes={false} />
          </div>
        );
      },
    },
  ];

  onSearchChange = control => {
    var value = control.target.value;
    this.state.searchStr = value;
    if (value.length > 1) {
      this.props.dispatch(
        signedNoteGet({
          JsonInput: JSON.stringify({
            Admin: [
              {
                IsGroupNote: parseInt(this.state.activeKey) == 0 ? false : true,
                Search: this.state.searchStr,
              },
            ],
          }),
        }),
      );
    } else if (value.length == 0) {
      this.props.dispatch(
        signedNoteGet({
          JsonInput: JSON.stringify({
            Admin: [
              {
                IsGroupNote: parseInt(this.state.activeKey) == 0 ? false : true,
                Search: null,
              },
            ],
          }),
        }),
      );
    }
  };

  render() {
    return (
      <div class="mt-2 card-body  mx-2">
        <div class="row top-table-search mt-2 mb-4 align-items-center">
          <div class="col-12 col-md-4 col-lg-4">
            <Link to="/cw/manage" class="back-link">
              <img src="images/back-arrow.svg" /> Back
            </Link>
            <h4 class="mb-3 mb-md-0">UN-SIGN NOTES</h4>
          </div>
          <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
            <div class="search-box w-100">
              <div class="form-group has-search w-100">
                <input
                  type="text"
                  class="form-control shadow-sm"
                  placeholder="Search"
                  onChange={this.onSearchChange}
                  value={this.state.searchStr}
                />
                <button class="btn search-icon " type="button">
                  {' '}
                  <i class="fa fa-search" />{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="un-sign-notes center-tabs">
          <ListRenderer
            list={this.list}
            activeKey={this.state.activeKey}
            getActiveKey={activeKey => this.setState({ activeKey, searchStr: null })}
            destroyInactiveTabPane={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { form_tab_list_get } = state;
  const { data } = form_tab_list_get;

  let TabData = null;

  if (data && data.result) {
    TabData = data.result || [];
  }

  return {
    TabData: TabData,
  };
};
export default connect(mapStateToProps)(withRouter(UnSignNotes));
