import React from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoComplete,
  ReduxCheckbox,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import AntdModal from '../Components/CustomModal';
import Autocomplete from 'react-autocomplete';
import Loader, { Loader2 } from '../Components/Loader';
import moment from 'moment';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  submit,
} from 'redux-form';

import { resourceUpsert, resourceListGetAll, resourceGet, listCombos } from '../store/actions';

import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditorComponent from 'react-ckeditor-component';
import SubmitButton from '../Components/submitButton';
import PageTitle from '../Components/PageTitle';
class ActivityBase extends React.Component {}

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value =>
    value ? (value.length > 100 ? 'cannot exceed 100 characters' : undefined) : 'Required',
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'accepts numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowGreaterThanZero: value => value ? value > 0 ? undefined : 'should be greater than 0' : undefined,
};

class AppResourceform extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      announcementData: {},
      isModalOpen: false,
      header: '',
      isRemoveModelOpen: false,
    };
  }

  getResourceId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var resourceId = param.get('appresourceId') || param.get('appresourceId');
    if (resourceId) {
      return (resourceId = parseInt(resourceId));
    } else {
      resourceId = -1;
    }
    return resourceId;
  }

  componentWillReceiveProps({ GetAnnouncementData }) {
    const {
      AppAnnouncementId,
      CenterId,
      HtmlContent,
      IsPublished,
      Title,
      Sequence,
    } = GetAnnouncementData;
    if (GetAnnouncementData && this.getResourceId() > 0) {
      var announcement = {
        title: Title,
        htmlContent: HtmlContent,
        isPublished: IsPublished,
        sequence: Sequence,
      };
      this.setState({ announcementData: announcement });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  componentDidMount() {
    if (this.getResourceId() > 0) {
      var data = {
        Resource: [
          {
            ResourceId: this.getResourceId(),
          },
        ],
      };
      var announcementData = {
        jsonInput: JSON.stringify(data),
      };
      this.props.dispatch(resourceGet(announcementData));
    } else {
      // this.props.dispatch(announcementGetJsonReset());
    }
  }

  // toggle = header => {
  //   this.setState(prevState => ({
  //     isModelOpen: !prevState.isModelOpen,
  //     header,
  //   }));
  // };
  // Removetoggle = () => {
  //   this.setState(prevState => ({
  //     isRemoveModelOpen: !prevState.isRemoveModelOpen,
  //   }));
  // };

  onSubmit(values) {
    //
    //alert(JSON.stringify(values));
    const {
      abstract,
      centerId,
      endDate,
      imageUrl,
      isMain,
      startDate,
      tags,
      title,
      updatedBy,
      link,
    } = values;

    // var announcementId = null;

    // var paramAnnouncementId = this.getAnnouncementId();
    // if (paramAnnouncementId && paramAnnouncementId > 0) {
    //   announcementId = paramAnnouncementId;
    // }

    var data = {
      Announcement: [
        {
          Abstract: abstract,
          // AnnouncementId: announcementId,
          CenterId: centerId,
          EndDate: endDate,
          ImageUrl: imageUrl,
          IsMain: isMain,
          StartDate: startDate,
          Tags: tags,
          Title: title,
          UpdatedBy: updatedBy,
          Link: link,
        },
      ],
    };
    var announcementData = {
      // AnnouncementId: announcementId,
      jsonInput: JSON.stringify(data),
    };
    // this.props.dispatch(announcementUpsert(announcementData));
  }

  render() {
    // const { GetNewsData } = this.props;

    return (
      <React.Fragment>
        <PageTitle TitleText="App Resource" />
        <ReduxAnnouncementForm
          onSubmit={this.onSubmit.bind(this)}
          initialValues={this.state.announcementData}
          // getAnnouncementId={this.getAnnouncementId()}
          onCancel={() => this.props.history.push('/manage/app-resource')}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/announcements')
          }}
          history={this.props.history}
          htmlContent={this.state.announcementData.htmlContent}
          isFetching={this.props.isFetching}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/app-resource');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            // this.props.dispatch(announcementDelete({ AnnouncementId: this.getAnnouncementId() }));
            // this.props.history.push('/manage/announcements');
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
            this.props.history.push('/manage/app-resource');
          }}
        />
      </React.Fragment>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: null,
      showLoader: false,
      isModelOpen123: false,
    };
  }
  getResourceId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var resourceId = param.get('appresourceId') || param.get('appresourceId');
    if (resourceId) {
      return (resourceId = parseInt(resourceId));
    } else {
      resourceId = -1;
    }
    return resourceId;
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen123: !prevState.isModelOpen123,
      header,
    }));
  };

  componentWillReceiveProps({ isSuccess, htmlContent }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.toggle(Upsert_Sucess_Msg);
    }
    if (htmlContent != this.props.htmlContent) {
      this.setState({ content: htmlContent });
    }
  }
  onAnnouncementSubmit = values => {
    const { title, isPublished, sequence } = values;
    var resourceId = null;

    var paramAnnouncementId = this.getResourceId();

    if (paramAnnouncementId && paramAnnouncementId > 0) {
      resourceId = paramAnnouncementId;
    }
    var data = {
      Resource: [
        {
          ResourceId: resourceId,
          Title: title,
          HtmlContent: this.state.content,
          IsPublished: isPublished,
          Sequence: sequence,
        },
      ],
    };
    // if (this.getAnnouncementId() > 0) {
    //   data.News[0].NewsId = this.getAnnouncementId();
    // }
    var resourceData = {
      ResourceId: resourceId,
      jsonInput: JSON.stringify(data),
    };
    this.props.dispatch(resourceUpsert(resourceData));
    // this.setState({ showLoader: true });
  };
  updateContent = newContent => {
    this.setState({
      content: newContent,
    });
  };

  onChange = evt => {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  onBlur(evt) {
    // console.log('onBlur event called with event info: ', evt);
  }

  afterPaste(evt) {
    // console.log('afterPaste event called with event info: ', evt);
  }

  render() {
    const { isFetching } = this.props;
    return (
      <div class="main enrollment-page  mb-5">
        <div class="container-fluid">
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-8 col-lg-9 text-right"></div>
          </div>
          <form onSubmit={this.props.handleSubmit(this.onAnnouncementSubmit)}>
            {/* <Loader2 loading={isFetching} /> */}
            <Link to="/manage/app-resource" class="back-link col-12">
              <img src="images/back-arrow.svg" style={{ marginTop: '10px' }} /> Back
            </Link>
            <div class="mb-4 col-12 col-md-12 col-lg-12 d-flex justify-content-between">
              <h3 class="form-main-head mb-3 mb-md-0">
                {this.getResourceId() > 0 ? 'Edit App Resource' : 'Add App Resource'}
              </h3>
              <div className="d-flex justify-content-between">
                <button
                  class="btn btn-outline-primary mr-2"
                  onClick={() => {
                    this.props.history.push('/manage/app-resource');
                  }}
                >
                  Cancel
                </button>
                {/* )} */}
                &nbsp;
                <SubmitButton
                  loading={isFetching}
                  style={{ padding: '0 24px', height: '44px' }}
                  disabled={!this.state.content}
                  value={"Submit"}
                />
              </div>
            </div>

            <div class="row  mb-2">
              <div class="col-12 mb-4">
                <div class="card border-0 rounded-0 ">
                  <div class="card-body enrollment-form">
                    <div class="row pb-5 pl-3 pr-3 pt-3">
                      <div class="col-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <Field
                            name="title"
                            type="text"
                            errorMessage="Title Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Title'}
                          />
                          <label class="placeholder-label">Title*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <Field
                            name="sequence"
                            type="text"
                            errorMessage="Title Required"
                            component={ReduxInput}
                            validate={[
                              Validator.required,
                              Validator.allowNumeric,
                              Validator.allowGreaterThanZero,
                            ]}
                            fieldName={'Sequence'}
                          />
                          <label class="placeholder-label">Sequence*</label>
                        </div>
                      </div>

                      <div class="col-12 col-md-12 col-lg-12">
                        <CKEditorComponent
                          activeClass="p10"
                          content={this.state.content}
                          events={{
                            blur: this.onBlur,
                            afterPaste: this.afterPaste,
                            change: this.onChange,
                          }}
                        />
                      </div>
                      <div class="col-12 col-md-12 col-lg-12 mt-3">
                        <div class="form-group">
                          <Field
                            className="mr-2"
                            name="isPublished"
                            component={ReduxCheckbox}
                            fieldName={'isPublished'}
                          />
                          <label class="placeholder-label" style={{ top: '5px' }}>
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <CustomModal
            isOpen={this.state.isModelOpen123}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              this.props.history.push('/manage/app-resource');
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ resource_upsert }) => {
  var { data, isFetching, error } = resource_upsert;
  let isSuccess = false;
  if (data && data.success) {
    isSuccess = data.success;
  }
  return { isSuccess: isSuccess, isFetching };
};

const ReduxAnnouncementForm = reduxForm({
  form: 'AppResourceform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(Example)));

const selector = formValueSelector('AppResourceform');

const mapStateToPropsParent = ({ resource_get }) => {
  var { data: getAnnouncementData, isFetching, error } = resource_get;

  if (resource_get && resource_get.data) {
    getAnnouncementData = resource_get.data.Resource;
  }

  return {
    GetAnnouncementData:
      resource_get && resource_get.data && resource_get.data.Resource
        ? resource_get.data.Resource[0]
        : {},
    isFetching: isFetching ? isFetching : false,
  };
};

export default withRouter(connect(mapStateToPropsParent)(AppResourceform));
