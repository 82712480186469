import React, { Component } from 'react';
import CustomModal from '../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../Components/Loader';

const Album = props => {
  const { Album } = props && props.appSubscriberData;
  return (
    <div className="container-fluid p-3">
      <div
        className="newGrid row"
        style={{ background: '#fff', borderRadius: '12px', paddingTop: '10px' }}
      >
        {Album &&
          Album.map(item => {
            const { MediaUrl, DateInserted } = item;
            return (
              <CustomModal
                ModalButton={({ onClick }) => (
                  <div
                    className="album-list-modal"
                    style={{
                      backgroundImage: `url(${MediaUrl})`,
                    }}
                    onClick={onClick}
                  ></div>
                )}
                ModalContent={({ props, onCancel }) => (
                  <div class="modal-content">
                    <div
                      class="modal-body"
                      style={{
                        padding: '10px',
                      }}
                    >
                      <span onClick={onCancel} className="album-modal-close">
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </span>
                      <div>
                        <img src={MediaUrl} alt=" Image Not Available" />
                      </div>
                    </div>
                  </div>
                )}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Album;
