import React, { Component } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
// import { studentMergeComboListGet } from '../store/actions';
import PowerBiReports from './powerBiReports';
import { ReduxSelect } from '../../component/custom-redux-components';
import SubmitButton from '../../Components/submitButton';
import AntdModal from '../../Components/CustomModal';
import CustomModal from '../../core/modal';
import { Loader2 } from '../../Components/Loader';
import { powerBiReportListGet } from '../../store/actions';
import PageTitle from '../../Components/PageTitle';

let reports = [
  {
    ReportName: 'New',
    ReportDescription: 'asdasdasdasdasd',
  },
  {
    ReportName: 'New1',
    ReportDescription: 'asdasdasdasdasd',
  },
  {
    ReportName: 'New2',
    ReportDescription: 'asdasdasdasdasd',
  },
  {
    ReportName: 'New3',
    ReportDescription: 'asdasdasdasdasd',
  },
];

class ManagePowerBiReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalMergeClients: [{}],
      isMergeSuccess: false,
      mergeMsg: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(powerBiReportListGet({}));
  }

  componentWillReceiveProps() {}

  componentWillUnmount() {}

  render() {
    const { reportsList, isFetching } = this.props;
    const {} = this.state;

    return (
      <div class="container-fluid">
        <PageTitle TitleText="Power BI Reports" />

        <div class="content-area">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Power BI Reports</h3>
            </div>
          </div>
          <div class="row power-bi-reports">
            {/* <Loader2 loading={isFetching} /> */}
            <div class="col-12 mb-5">
              <div class="">
                <div class="row" style={{ position: 'relative' }}>
                  <Loader2 loading={isFetching} />
                  {reportsList &&
                    reportsList.map(item => {
                      if (item.ReportType === 'PowerBi') {
                        return <PowerBiReports report={item} {...this.props} />;
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /studentProfile/1002844 */}
        {/* <CustomModal
          isOpen={this.state.isMergeSuccess}
          header={th}
          yesAction={() => {
            this.onMergeComplete();
            //   this.props.history.push('/manage');
            this.setState({ isMergeSuccess: false });
            this.props.dispatch(studentMergeComboListGet({}));
          }}
          yesText="OK"
          hideCancel={true}
        /> */}
      </div>
    );
  }
}

export default connect((state, props) => {
  const { power_bi_report_list_get } = state;

  const { data: powerBiReportList, isFetching } = power_bi_report_list_get;
  let reportsList = [];
  if (powerBiReportList && powerBiReportList.AnalyticReport) {
    reportsList = powerBiReportList.AnalyticReport;
  }

  return { reportsList, isFetching };
})(
  reduxForm({
    form: 'managePowerBiReports',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(ManagePowerBiReports),
);

const selector = formValueSelector('managePowerBiReports');
