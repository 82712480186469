import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import renderHTML from 'react-render-html';

class AISummaryTimelineModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { onOk, onCancel, history, augmentedSummaryHistory, augmentedData } = this.props;

    return (
      <div class="modal-content border-1">
        <div class="modal-header justify-content-center flex-wrap mb-2">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 class="modal-title col-12">AI Summary Timeline</h4>
        </div>
        <div class="modal-body px-4 enrollment-form update-history-card">
          <div className="row" style={{ height: '80vh', overflowY: 'scroll' }}>
            <div className="col-12">
              {augmentedSummaryHistory &&
                augmentedSummaryHistory.map(summary => {
                  const {
                    Answer,
                    ShortQuestion,
                    UpdatedBy,
                    UpdatedOn,
                    ProfileImageURL,
                    Question,
                    UpdatedInfo,
                    UpdatedDate,
                    UpdatedTime,
                  } = summary;
                  return (
                    <div className="box" key={uuid()}>
                      <div key={uuid()} className={`row`}>
                        <div className="col-md-2">
                          <div className="d-flex flex-column">
                            <span className="date">{UpdatedDate}</span>
                            <span className="time">{UpdatedTime}</span>
                          </div>
                        </div>
                        <div className="col-md-1 px-0 d-flex justify-content-center">
                          <div className="icon">
                            <img src={`images/AIStarWBG.svg`} width="54" />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="gpt-answers">
                            <div className="ai-block pt-1">
                              <span className="ai-question">{ShortQuestion || Question}</span>
                              <span className="ai-agreed">
                                <i class="fas fa-thumbs-up" style={{ paddingRight: 5 }}></i>
                                Agreed
                              </span>
                              <span className="ai-updated-info">{UpdatedInfo}</span>
                              <p className="ai-answer">{renderHTML(Answer)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* <div class="modal-footer justify-content-center border-top-0 pb-2 pt-2">
          <button type="button" class="btn black-btn" data-dismiss="modal" onClick={onCancel}>
            Close
          </button>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { augmented_summary_get } = state;

  const { data: AugmentedSummaryData } = augmented_summary_get;

  let augmentedSummaryHistory = null;

  if (AugmentedSummaryData && AugmentedSummaryData.History) {
    augmentedSummaryHistory = AugmentedSummaryData.History;
  }

  return {
    augmentedSummaryHistory: augmentedSummaryHistory,
  };
};
export default connect(mapStateToProps)(AISummaryTimelineModal);
