import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import hostGrabber from '../util/hostGrabber';

const PageTitle = props => {
  const { TitleText, SecondText } = props;
  return (
    <Helmet>
      {hostGrabber() ? (
        <title>
          {TitleText} {SecondText ? SecondText : ''} | Youu
        </title>
      ) : (
        <title>
          {TitleText} {SecondText ? SecondText : ''} | Sober Peer
        </title>
      )}
    </Helmet>
  );
};

export default PageTitle;
