import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { dashboardRadarGraphGet } from '../../store/actions';
import ReactApexChart from 'react-apexcharts';
import { Drawer } from 'antd';
import AIAugmentedSummary from '../customScreens/surveyResponse/AIAugmentedSummary';
const RecoveryMeasureGraph = props => {
  const { AugmentedAIStatusData, UserGuid, studentId } = props || {};
  const dispatch = useDispatch();
  const [series, setSeries] = useState([]);
  const chartRef = useRef();
  const [isSeriesNull, setIsSeriesNull] = useState(false);
  const [xAxis, setXAxis] = useState([]);
  const [apexSeries, setApexSeries] = useState([]);
  const [isMoodDrawerOpen, setIsMoodDrawerOpen] = useState(false);

  const { radarGraphData, isFetching, surveysId } = useSelector(
    ({ dashboard_radar_graph_get: { data: radarGraphData, isFetching } }) => {
      return {
        radarGraphData: radarGraphData ? radarGraphData.Graph : [],
        surveysId: radarGraphData ? radarGraphData.SurveyIds : null,
        isFetching: isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    handleAPICall();
  }, []);

  const toggleDrawer = () => {
    // this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
    setIsMoodDrawerOpen(prevState => !prevState);
  };

  useEffect(() => {
    if (radarGraphData && radarGraphData.length > 0) {
      setIsSeriesNull(false);
      let xaxis = radarGraphData && radarGraphData.map(item => item.Description);
      let baseline = radarGraphData.map(item => item.FirstScore);
      let current = radarGraphData.map(item => item.LastScore);
      setXAxis(xaxis);
      let data = {
        labels: xaxis,
        datasets: [
          {
            label: 'Baseline ',
            data: baseline,
            // fill: false,
            // lineTension: 0,
            // spanGaps: true,
            showLine: false,
            backgroundColor: 'rgba(20, 128, 48, 0.3)',
            borderColor: 'transparent',

            // pointBackgroundColor: 'rgba(20, 128, 48, 0.3)',
            // pointBorderColor: 'rgba(20, 128, 48, 0.3)',
            // pointHoverBackgroundColor: 'rgba(20, 128, 48, 0.3)',
            // pointHoverBorderColor: 'rgba(20, 128, 48, 0.3)',
            pointRadius: 3, // Adjust the radius of the dots as needed
            // borderWidth: 0,
          },
          {
            label: 'Current',
            data: current,
            // fill: false,
            // lineTension: 0,
            // spanGaps: true,
            showLine: false,
            backgroundColor: 'rgba(230, 108, 55, 0.1)',
            borderColor: 'transparent',
            pointRadius: 3,
          },
        ],
      };

      let apexData = [
        {
          name: 'Baseline ',
          data: baseline,
          // data: [60, 20, 50, 45, 12, 65, 85, 64, null, 20, 12, null],
        },
        {
          name: 'Current',
          data: current,
          // data: [10, null, 50, null, null, 65, 85, 64, 20, 20, 12, 90],
        },
      ];
      setSeries(data);
      setApexSeries(apexData);
    } else {
      setSeries([]);

      setIsSeriesNull(true);
    }
  }, [radarGraphData]);

  const handleAPICall = userId => {
    let data = {
      Graph: {
        UserGuid: props.UserGuid,
        StudentId: props.studentId,
      },
    };
    dispatch(
      dashboardRadarGraphGet({
        // UserGuid: '869CD961-CF05-4A18-ADDF-9224138578B0',
        // UserGuid: '25E7F19A-29E3-4352-A2FE-08154A2D2C80',
        Json: JSON.stringify(data),
      }),
    );
  };

  const options = {
    scale: {
      // pointLabels: {
      //   fontSize: 14,
      //   // display: false, // Hide the labels inside the Radar Chart
      // },
    },
    legend: {
      display: true,
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        fontSize: 14,
        padding: 20,
      },
    },
    plugins: {
      width: 1500,
      datalabels: {
        display: false, // Hide data labels
      },
    },
    // tooltips: {
    //   mode: 'index',
    //   intersect: false,
    //   callbacks: {
    //     //Added callbacks for label
    //     title: (tooltipItems, data) => {
    //       return '';
    //     },
    //     // label: (tooltipItems, data) => {
    //     //   // return ' (' + tooltipItems.xLabel + ',' + tooltipItems.yLabel + ')';
    //     // },
    //   },
    // },
    // onClick: (event, elements) => {
    //   const chartInstance = chartRef.current.chartInstance;
    //   const activePoints = chartInstance.getElementsAtEventForMode(
    //     event,
    //     'point',
    //     chartInstance.options,
    //   );
    //   let modalArray = [];
    //   if (activePoints && activePoints.length > 0) {
    //     activePoints &&
    //       activePoints.length > 0 &&
    //       activePoints.map(item => {
    //         const clickedDatasetIndex = item._datasetIndex;
    //         const clickedDataIndex = item._index;
    //         const dataset = series.datasets[clickedDatasetIndex];
    //         const clickedPointData = dataset.data[clickedDataIndex];
    //         dataset.xAxis = item._index;
    //         modalArray.push(dataset);
    //       });
    //     setModalContent(modalArray);
    //     setIsModalOpen(true);
    //   }
    // },
  };
  const apexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['#E66C37', '#148030'],
    labels: xAxis,
    // xaxis: {
    //   labels: {
    //     style: {
    //       fontSize: '9px',
    //     },
    //   },
    // },
    radar: {
      // Hide numbers on radial lines
      labels: {
        show: false,
      },
      dataLabels: {
        // Set enabled to false to hide the numbers inside the radar graph
        enabled: false,
      },
      ranges: {
        // Define range for the first axis (index 0)
        0: {
          min: 0,
          max: 20,
        },
        // Define range for the second axis (index 1)
        1: {
          min: 20,
          max: 40,
        },
        2: {
          min: 40,
          max: 60,
        },
        3: {
          min: 60,
          max: 80,
        },
        4: {
          min: 80,
          max: 100,
        },
        // Repeat for other axes if needed
      },
    },
    legend: {
      show: true,
      position: 'top',
      showForSingleSeries: true,
      showForNullSeries: true,
      // fontSize: '16px',
      onItemHover: {
        highlightDataSeries: false,
      },
      // markers: {
      //   width: 16,
      //   height: 16,
      // },
    },
    stroke: {
      width: 0,
    },
    fill: {
      opacity: 0.2,
    },
    yaxis: {
      show: false,
    },
    // tooltip: {
    //   // x: {
    //   //   formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
    //   //   },
    //   // },
    //   // y: {
    //   //   formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
    //   //   },
    //   // },
    //   // custom: function({ series, seriesIndex, dataPointIndex, w }) {
    //   //   // const seriesData = w.config.series[seriesIndex].data;
    //   //   const label = w.config.labels[dataPointIndex];
    //   //   // const values = seriesData
    //   //   //   .map((value, index) => {
    //   //   //     if (value === series[seriesIndex][dataPointIndex]) {
    //   //   //       return `${w.config.series[index].name}: ${value}`;
    //   //   //     }
    //   //   //     return null;
    //   //   //   })
    //   //   //   .filter(value => value !== null);

    //   //   // return `<div>${label}<br />${values.join('<br />')}</div>`;
    //   //   <div>
    //   //     <p>${label}</p>
    //   //     <div></div>
    //   //   </div>;
    //   // },
    // },
    // tooltip: {
    //   custom: function({ series, seriesIndex, dataPointIndex, w }) {
    //     const label = w.config.labels[dataPointIndex];
    //     const values = [];

    //     // Loop through all series to get values at the same data point
    //     for (let i = 0; i < series.length; i++) {
    //       values.push(`${w.config.series[i].name}: ${series[i][dataPointIndex]}`);
    //     }

    //     return `<div>${label}<br />${values.join('<br />')}</div>`;
    //   },
    // },
  };

  return (
    <div className="col-12">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="mb-0">Recovery Measures</h6>
        {AugmentedAIStatusData &&
          AugmentedAIStatusData.LicenseStatusId != 1 &&
          AugmentedAIStatusData.LicenseStatusId != 3 &&
          AugmentedAIStatusData.LicenseStatusId != 5 && (
            <img
              src="images/gpt-ai.svg"
              style={{ cursor: 'pointer' }}
              onClick={toggleDrawer}
              // className="mr-3"
            />
          )}
      </div>
      <div className="">
        <ReactApexChart
          options={apexOptions}
          series={apexSeries}
          type="radar"
          height={400}
          // ref={chartRef}
        />
      </div>
      {isMoodDrawerOpen && (
        <Drawer
          className="custom-drawer journal-augmenting"
          title="Augment with AI"
          placement="right"
          open={isMoodDrawerOpen}
          width={800}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          destroyOnClose={true}
          onClose={() => {
            // this.props.dispatch(resetSurveyAnswersGet());
            toggleDrawer();
          }}
        >
          <AIAugmentedSummary
            ClientId={studentId}
            toggleDrawer={toggleDrawer}
            isMoodAugment={false}
            SurveyIds={surveysId}
            UserGuid={UserGuid}
            // ColData={surveyanswersdatacol}
            // augmentId={this.state.augmentId}
            // changeAugmentId={changeAugmentId}
            // activekey={activekey}
            // UserGuid={props.UserGuid}
          />
        </Drawer>
      )}
    </div>
  );
};
export default RecoveryMeasureGraph;
