import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import ReactApexChart from 'react-apexcharts';
import ComingSoon from '../component/comingSoonPage';
import {
  // concernedPersonList,
  concernedPersonDasboardGraphGet,
} from '../store/actions';
import {
  Table,
  Checkbox,
  Radio,
  Typography,
  Modal,
  Avatar,
  Divider,
  Tooltip,
  Popover,
  Button,
} from 'antd';
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { ReduxSelect } from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import _blank from '../images/blank-profile.png';
import _info from '../images/info.svg';
import ReduxBroadcaseInvitationModal from './customScreens/broadcastInvitation';
import SendAppInviteReduxModal from './customScreens/sendMobileAppInvitation';

import Axios from 'axios';
import AntdModal from '../Components/CustomModal';
import { Device } from '@twilio/voice-sdk';
import CustomModal2 from '../core/modal';
import Twilio from '../Components/twilio';
import DynamicGrid from '../Components/DynamicGrid';
import { MobileAdvisor } from '../app-config/screenName';
import PersistTableSort from '../component/persistTableSort';
import { CommonLicenceScreens } from './licenceScreens';
import PageTitle from '../Components/PageTitle';
const CheckboxGroup = Checkbox.Group;

const options = [
  { Value: null, Text: 'Please Select' },
  { Value: 'Enrolled', Text: 'All' },
  { Value: 'Graduated', Text: 'Graduated' },
  { Value: 'Discharged', Text: 'Discharged' },
  { Value: 'Active', Text: 'Active' },
];

const plainOptions = ['Graduated', 'Discharged', 'Active'];

class FamilyProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Concerned Person',
          dataIndex: 'Name',
          key: 'Name',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.Name, b.Name),
          render: (text, record, index) => {
            const { Name, ProfileImageUrl, UserGuid } = record;
            return (
              <>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-4"
                    onClick={() => {
                      props.history.push(`concernedPerson/${UserGuid}`);
                    }}
                  >
                    {' '}
                    <figure>
                      <img
                        style={{
                          // boxShadow: '0px 11px 6px #f2f2f2',
                          cursor: 'pointer',
                        }}
                        src={ProfileImageUrl || 'images/blank-profile.png'}
                        class="rounded-circle "
                        width="40"
                        height="40"
                        alt=""
                      />
                    </figure>
                  </div>
                  <div
                    className=""
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    {Name}
                  </div>
                </div>
              </>
            );
          },
        },
        {
          title: 'Phone Number',
          dataIndex: 'Phone',
          key: 'Phone',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isNumericComparer(a.Phone, b.Phone),
        },
        {
          title: 'Clients Connected',
          dataIndex: 'ClientsConnected',
          key: 'ClientsConnected',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isNumericComparer(a.ClientsConnectedCount, b.ClientsConnectedCount),
          render: (text, record, index) => {
            const { ClientsConnected } = record;
            if (ClientsConnected && ClientsConnected.length > 0) {
              return (
                <>
                  <div className="d-flex">
                    {ClientsConnected &&
                      ClientsConnected.map(item => {
                        const { Name, ProfileImageUrl, UserGuid } = item;
                        return (
                          <div>
                            <Tooltip placement="topLeft" title={Name}>
                              <div className="mr-4">
                                {' '}
                                <figure>
                                  <img
                                    style={{
                                      boxShadow: '0px 11px 6px #f2f2f2',

                                      cursor: 'pointer',
                                    }}
                                    src={ProfileImageUrl || 'images/blank-profile.png'}
                                    class="rounded-circle "
                                    width="40"
                                    height="40"
                                    alt=""
                                    onClick={() => {
                                      props.history.push(`subscriberProfile/${UserGuid}`);
                                    }}
                                  />
                                </figure>
                              </div>
                            </Tooltip>
                          </div>
                        );
                      })}
                  </div>
                </>
              );
            } else {
              return null;
            }
          },
        },
        {
          title: 'Center',
          dataIndex: 'CenterName',
          key: 'CenterName',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
        },
        {
          title: 'Advisor',
          dataIndex: 'Advisor',
          key: 'Advisor',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.Advisor, b.Advisor),
        },
        {
          title: 'Joined Date',
          dataIndex: 'AppJoiningDate',
          key: 'AppJoiningDate',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.AppJoiningDateSort, b.AppJoiningDateSort),
        },
        {
          title: 'Status',
          dataIndex: 'UserStatus',
          key: 'UserStatus',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => isComparer(a.UserStatus, b.UserStatus),
        },
      ],
      title: 'Family Program',
      pieSeries: [44, 55],
      pieOptions: {
        chart: {
          type: 'pie',
        },
        colors: ['#FFA800', '#4F99D2'],
        labels: ['Client Connected', 'Support Group Member'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'left',
        },
      },
      barSeries: [
        {
          name: 'This Year',
          type: 'column',
          data: [0],
        },
        {
          name: 'Last Year',
          type: 'line',
          data: [0],
        },
      ],
      barOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
            columnWidth: '75%',
          },
        },
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: 'Active Support Group Members',
          align: 'left',
          margin: 0,
          offsetX: 10,
          offsetY: 17,
          floating: true,
          style: {
            fontSize: '1.3rem',
            fontWeight: '800',
            fontFamily: 'Nunito',
            color: '#263238',
          },
        },
        dataLabels: {
          enabled: true,
          // enabledOnSeries: [1]
          // formatter: function(val, opts) {
          //   return val;
          // },
        },
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: [
          {
            title: {
              text: 'Total',
            },
          },
          {
            opposite: true,
            // title: {
            //   text: 'Newly Added',
            // },
          },
        ],
      },
      options2: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        colors: ['#FF6B00', '#46B4FE'],
        fill: { colors: ['#FF6B00', '#46B4FE'] },
        chart: {
          id: 'basic-bar',
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ['Assessments'],
        },
      },
      series2: [
        {
          name: 'Last 30 days',
          data: [1],
        },
        {
          name: 'Last 12 months',
          data: [2],
        },
      ],
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  // ProfileImageUrl,
  // Advisor,
  // IsVoiceCallAllowed,

  componentDidMount() {
    // this.props.dispatch(concernedPersonList({}));
    this.props.dispatch(concernedPersonDasboardGraphGet({}));
  }

  componentWillReceiveProps({
    ActiveSupportGroupMember,
    Interactions,
    TotalConnectedPopulation,
    TotalInteractions,
    familyProgramStatusData,
  }) {
    if (
      ActiveSupportGroupMember &&
      JSON.stringify(ActiveSupportGroupMember) !=
        JSON.stringify(this.props.ActiveSupportGroupMember)
    ) {
      //Line Chart Data update

      let { CurrentYearActiveCnt, LastYearActiveCnt, Months } = ActiveSupportGroupMember || {};

      let barSeriesFirst = {
        ...this.state.barSeries[0],
        data: (CurrentYearActiveCnt && JSON.parse(CurrentYearActiveCnt)) || [],
      };
      let barSeriesSecond = {
        ...this.state.barSeries[1],
        data: (LastYearActiveCnt && JSON.parse(LastYearActiveCnt)) || [],
      };
      let newBarSeries = [barSeriesFirst, barSeriesSecond];
      let newM = [];
      if (Months) {
        newM = (Months && Months.substr(1, Months.length - 2).split(',')) || '';
      }

      this.setState({
        barOptions: { ...this.state.barOptions, labels: newM },
        barSeries: newBarSeries,
      });
    }

    if (Interactions && JSON.stringify(Interactions) != JSON.stringify(this.props.Interactions)) {
      //Interaction Chart Data update

      let { Activity, TotalMonthlyCompletedCnt, TotalYearlyCompletedCnt } = Interactions || {};
      let newActivity = [];
      if (Activity) {
        newActivity = (Activity && Activity.substr(1, Activity.length - 2).split(',')) || '';
      }

      let series2First = {
        ...this.state.series2[0],
        data: (TotalMonthlyCompletedCnt && JSON.parse(TotalMonthlyCompletedCnt)) || [],
      };
      let series2Second = {
        ...this.state.series2[1],
        data: (TotalYearlyCompletedCnt && JSON.parse(TotalYearlyCompletedCnt)) || [],
      };
      let newBarSeries2 = [series2First, series2Second];
      let totalInteractions = TotalInteractions || 0;
      this.setState(prevState => {
        return {
          ...prevState,
          options2: {
            ...prevState.options2,
            xaxis: {
              ...prevState.options2.xaxis,
              categories: [...newActivity],
            },
          },
          series2: newBarSeries2,
          TotalInteractions: totalInteractions,
        };
      });
    }

    if (
      TotalConnectedPopulation &&
      JSON.stringify(TotalConnectedPopulation) !=
        JSON.stringify(this.props.TotalConnectedPopulation)
    ) {
      //Pie Chart Data update
      let { Total, TotalClientConnected, TotalSupportGroupMember } = TotalConnectedPopulation;

      let a = [TotalClientConnected, TotalSupportGroupMember];

      this.setState({
        pieSeries: [...a],
        pieTotal: Total || 0,
      });
    }

    if (
      familyProgramStatusData &&
      JSON.stringify(familyProgramStatusData) != JSON.stringify(this.props.familyProgramStatusData)
    ) {
      this.setState({ familyProgramStatusData });
    }
  }

  render() {
    const {
      concernedPersonData,
      concernedPersonDashboardData,
      familyProgramStatusData,
    } = this.props;
    // const {
    //   TotalClientConnected,
    //   TotalInteractions,
    //   Interactions,
    //   ActiveSupportGroupMember,
    // } = concernedPersonDashboardData;
    return (
      <div class="">
        <PageTitle TitleText="Subscriber List" />
        <Loader loading={this.props.isFetchingPersonDashboardData} />

        <CommonLicenceScreens
          StatusData={this.state.familyProgramStatusData}
          moduleName={'FamilyProgram'}
        />

        {this.state.familyProgramStatusData &&
        this.state.familyProgramStatusData.LicenseStatusId == 6 ? (
          <div>
            <ComingSoon />
            <div className="pb-5"></div>
          </div>
        ) : (
          <>
            {this.state.familyProgramStatusData &&
              this.state.familyProgramStatusData.LicenseStatusId != 1 &&
              this.state.familyProgramStatusData.LicenseStatusId != 3 &&
              this.state.familyProgramStatusData.LicenseStatusId != 5 && (
                <>
                  <div
                    class="pt-2"
                    // style={{ marginTop: '-40px' }}
                  >
                    {!this.props.isDashboard && (
                      <a
                        class="back-link"
                        style={{ display: 'inline-flex' }}
                        onClick={() => this.props.history.goBack()}
                      >
                        <img src="images/back-arrow.svg" /> &nbsp; Back
                      </a>
                    )}

                    <div className="row">
                      <div class="col-lg-6  mb-4">
                        <div class="card" style={{ position: 'inherit' }}>
                          <div class="card-body" style={{ minHeight: '462px' }}>
                            <h5 class="card-title">
                              Total Connected Population
                              <Tooltip
                                placement="top"
                                title="Connection between support group members and clients"
                              >
                                <img
                                  src={_info}
                                  alt="userPhotograph"
                                  class="img-circle"
                                  // height="80"
                                  // width="80"
                                  style={{
                                    borderRadius: '50%',
                                    marginLeft: '7px',
                                    objectFit: 'cover',
                                    width: '20px',
                                    height: '20px',
                                  }}
                                />
                              </Tooltip>
                            </h5>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ height: '300px', overflowX: 'auto' }}
                            >
                              <ReactApexChart
                                options={this.state.pieOptions}
                                series={this.state.pieSeries}
                                type="pie"
                                width={330}
                                height={330}
                              />

                              <div className="mt-2 custom-header">
                                <table class="table table-bordered">
                                  <tr>
                                    <td>Support Group Members</td>
                                    <td>{this.state.pieSeries[1]}</td>
                                  </tr>
                                  <tr>
                                    <td>Clients Connected</td>
                                    <td>{this.state.pieSeries[0]}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td>
                                      {' '}
                                      <b>{this.state.pieTotal}</b>
                                    </td>
                                  </tr>

                                  <tr></tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6  mb-4">
                        <div class="card border-0 ">
                          <div className="card-body">
                            {/* <h4 className="p-2">Active Support Group Members</h4> */}

                            <ReactApexChart
                              options={this.state.barOptions}
                              series={this.state.barSeries}
                              type="line"
                              height={400}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-lg-12  mb-4">
                        <div class="card border-0 ">
                          <div className="card-body d-flex align-items-center justify-content-between">
                            <h4 className="p-2">
                              Interactions{' '}
                              <Tooltip
                                placement="top"
                                title="Total interactions by concerned persons"
                              >
                                <img
                                  src={_info}
                                  alt="userPhotograph"
                                  class="img-circle"
                                  // height="80"
                                  // width="80"
                                  style={{
                                    borderRadius: '50%',
                                    marginRight: '10px',
                                    objectFit: 'cover',
                                    width: '20px',
                                    height: '20px',
                                    marginTop: '4px',
                                  }}
                                />
                              </Tooltip>
                            </h4>
                            <div>
                              {this.state.TotalInteractions && (
                                <span
                                  style={{
                                    borderRadius: '4px',
                                    backgroundColor: '#F8F8F8',
                                    padding: '15px',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '30px',
                                      fontWeight: '700',
                                      position: 'relative',
                                      top: '2px',
                                    }}
                                  >
                                    {this.state.TotalInteractions}
                                  </span>{' '}
                                  &nbsp; Total Interactions in last 12 months
                                </span>
                              )}
                            </div>
                          </div>

                          <ReactApexChart
                            options={this.state.options2}
                            series={this.state.series2}
                            type="bar"
                            height={280}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-4">
                        <div
                          class="content-area pt-3"
                          style={{ background: 'white', borderRadius: '12px' }}
                        >
                          <div class="col-12 col-md-4 col-lg-3">
                            <h3 class="page-title mb-3 ml-3 mb-md-0" style={{ fontSize: '1.5rem' }}>
                              {this.state.title}
                            </h3>
                          </div>

                          {/* <div className="">
              <div className="row card-body" style={{ minHeight: '300px' }}>
                <div class="col-12 ">
                  <PersistTableSort
                    name="familtProgram"
                    columns={this.state.columns}
                    dataSource={concernedPersonData}
                    size={'middle'}
                    // onRow={(record, rowIndex) => {
                    //   return {
                    //     onClick: event => this.onRowClick(event, record, rowIndex),
                    //   };
                    // }}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div class="dataTables_wrapper no-footer">
                            <div class="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a class="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a class="paginate_button next">Next</a>
                              ) : (
                                <a class="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div> */}

                          <div className="dynamic-grid p-5">
                            <DynamicGrid
                              ScreenName={'FamilyProgram'}
                              isCustomFilter={false}
                              FilterBy={this.state.filterBy}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}{' '}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  get_app_subscribers,
  combos,
  get_broadcast_filter,
  concerned_person_list,
  concerned_person_dasboard_graph_get,
  user_feature_flag_get,
}) => {
  var { data, isFetching, error } = get_app_subscribers;
  const { data: filterArray, isFetching: isFilterFetching } = get_broadcast_filter;
  var { data: personData, error } = concerned_person_list;
  var {
    data: personDashboardData,
    isFetching: isFetchingPersonDashboardData,
  } = concerned_person_dasboard_graph_get;
  const { data: familyProgramStatus } = user_feature_flag_get;

  let userFilter = [];
  let filters = [];
  let subData = [];
  let concernedPersonData = [];
  let concernedPersonDashboardData = [];
  let ActiveSupportGroupMember = [];
  let Interactions = [];
  let TotalConnectedPopulation = [];
  let TotalInteractions = null;
  let familyProgramStatusData = null;

  if (data && data.AppUser) {
    subData = data.AppUser;
  }
  if (data && data.FilterBy) {
    userFilter = data.FilterBy;
  }
  if (filterArray && filterArray.FilterBy && filterArray.FilterBy.length > 0) {
    filters = (filterArray && filterArray.FilterBy) || [];
  }

  if (personData) {
    concernedPersonData = personData && personData[0] && personData[0].AppUser;
  }

  if (personDashboardData && personDashboardData.Graph && personDashboardData.Graph[0]) {
    // concernedPersonDashboardData =
    //   personDashboardData && personDashboardData.Graph && personDashboardData.Graph[0];
    ActiveSupportGroupMember = personDashboardData.Graph[0].ActiveSupportGroupMember[0];
    Interactions = personDashboardData.Graph[0].Interactions[0];
    TotalConnectedPopulation = personDashboardData.Graph[0].TotalConnectedPopulation[0];
    TotalInteractions = personDashboardData.Graph[0].TotalInteractions;
  }

  var Centers = [{ Value: null, Text: 'Please Select' }];

  if (combos.data) {
    if (combos.data.Center)
      for (var center of combos.data.Center) {
        Centers.push(center);
      }
  }
  if (
    familyProgramStatus &&
    familyProgramStatus.FeatureFlag &&
    familyProgramStatus.FeatureFlag.length > 0 &&
    familyProgramStatus.FeatureFlag[0]
  ) {
    familyProgramStatusData =
      familyProgramStatus.FeatureFlag &&
      familyProgramStatus.FeatureFlag[0] &&
      familyProgramStatus.FeatureFlag[0].License &&
      familyProgramStatus.FeatureFlag[0].License.length > 0 &&
      familyProgramStatus.FeatureFlag[0].License.filter(
        item => item.ModuleName == 'FamilyProgram',
      )[0];
  }
  return {
    subscribers: subData || [],
    isFetching,
    Centers: Centers,
    userFilter,
    filters,
    concernedPersonData,
    // concernedPersonDashboardData,
    ActiveSupportGroupMember,
    Interactions,
    TotalConnectedPopulation,
    TotalInteractions,
    isFetchingPersonDashboardData,
    familyProgramStatusData,
  };
};
export default connect(mapStateToProps)(withRouter(FamilyProgram));
