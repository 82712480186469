import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  externalProviderListGet,
  externalProviderGet,
  externalProviderUpsert,
} from '../../../../../../store/actions';
import SuccessModal from '../../../../../../core/modal/SuccessModal';
import { Tooltip, Drawer, Table, Radio } from 'antd';
import CloseIcon from '../../../../../../Components/CloseIcon';
import ExternalCareTeamMemberAssign from './externalCareTeamMemberAssign';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
import ExternalMemberList from '../components/externalMemberList';
class ExternalCareTeamList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {
        Country: 'USA',
      },
      isReadOnly: false,
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  // getGridData = () => {
  //   this.props.dispatch(externalProviderListGet({}));
  // };
  componentDidMount() {
    // this.getGridData();
  }
  componentWillReceiveProps({ externalProviderInfo }) {
    if (
      externalProviderInfo &&
      JSON.stringify(externalProviderInfo) != JSON.stringify(this.props.externalProviderInfo)
    ) {
      this.setState({
        initialData: externalProviderInfo,
      });
    }
  }
  getExternalProviderData = ExternalProviderId => {
    this.props.dispatch(
      externalProviderGet({
        Json: JSON.stringify({
          ExternalProvider: [
            {
              ExternalProviderId: ExternalProviderId,
            },
          ],
        }),
      }),
    );
  };
  toggleDrawer = (record, actionType) => {
    if (record && record.ExternalProviderId) {
      this.getExternalProviderData(record.ExternalProviderId);
    }
    if (actionType == 'View') {
      this.setState({
        isCareTeamViewDrawer: !this.state.isCareTeamViewDrawer,
        actionType: actionType,
      });
    } else {
      this.setState({
        isCareTeamDrawer: !this.state.isCareTeamDrawer,
        // isReadOnly: actionType == 'View' ? true : false,
        actionType: actionType,
      });
    }
  };
  toggle = (header, headerInfo) => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
      headerInfo,
    }));
  };

  handleAssignCareTeam = val => {
    let data = {
      ExternalProvider: [
        {
          ExternalProviderId: val.ExternalProviderId,
          ClientId: this.getClientId(),
          EmailWork: val.EmailWork,
          PhoneNumber: val.PhoneNumber,
        },
      ],
    };
    this.props.dispatch(
      externalProviderUpsert({
        Json: JSON.stringify(data),
      }),
    );
  };
  toggleSelectExternalMember = data => {
    this.getExternalProviderData(this.state.externalProviderId);
    this.setState({
      isAssignCareTeamDrawer: !this.state.isAssignCareTeamDrawer,
    });
  };

  rowSelection = {
    // selectedRowKeys: this.state.selectedRowsArray,
    onChange: (selectedRowKeys, selectedRows) => {
      debugger;
      this.setState({
        // selectedRowsArray: [...selectedRowKeys],
        selectedRows: selectedRows,
        externalProviderId: selectedRows[0].ExternalProviderId,
      });
    },
  };

  render() {
    const { isFetchingUpsert } = this.props;
    const {
      isCareTeamDrawer,
      isCareTeamViewDrawer,
      isAssignCareTeamDrawer,
      isReadOnly,
      initialData,
      isModelOpen,
      header,
      headerInfo,
      actionType,
    } = this.state;

    return (
      <div className="container-fluid mt-5">
        <div className="row mb-2 top-table-search align-items-center">
          <div className="col-md-6 col-xl-8">
            <a
              className="back-link"
              style={{ cursor: 'pointer' }}
              onClick={() => this.props.history.goBack()}
            >
              <img src="images/back-arrow.svg" /> Back
            </a>
            <br />
            <span className="grid-page-title">Additional Care Team Member(s)</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ExternalMemberList
              rowSelection={this.rowSelection}
              toggleMemberDrawer={() => {
                this.setState({
                  isAssignCareTeamDrawer: false,
                  // externalProviderId: null,
                });
              }}
            />
          </div>
          <div className="col-12 position-sticky mt-2" style={{ bottom: 0, zIndex: '2' }}>
            <div
              className="row enrollment-page top-heading-row"
              style={{
                backgroundColor: '#ffffff',
                boxShadow: 'rgba(0, 0, 0, 0.03)',
              }}
            >
              <div className="col-12 px-5 py-3">
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    onClick={() => this.props.history.push('/clients/list')}
                    className="btn btn-outline-primary mr-3 px-5"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.toggleSelectExternalMember}
                    className="btn mr-3 px-5"
                    disabled={!this.state.externalProviderId}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isAssignCareTeamDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">Add External Member</span>
              </div>
            }
            placement="right"
            open={isAssignCareTeamDrawer}
            width={800}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.setState({
                // initialData: {},
                isAssignCareTeamDrawer: false,
                selectedRows: null,
                // externalProviderId: null,
                // selectedRows: [],
              });
            }}
          >
            <ExternalCareTeamMemberAssign
              initialValues={initialData}
              ExternalProviderData={initialData}
              ExternalProviderId={initialData.ExternalProviderId}
              disable={false}
              onSaveClick={this.handleAssignCareTeam}
              onClose={() => {
                this.setState({
                  // initialData: {},
                  isAssignCareTeamDrawer: false,
                  // selectedRows: [],
                });
              }}
              isFetchingUpsert={isFetchingUpsert}
            />
          </Drawer>
        )}
        {/* {isModelOpen && (
          <SuccessModal
            isOpen={isModelOpen}
            header={header}
            headerText={headerInfo}
            yesAction={() => {
              this.getGridData();
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = ({ external_provider_upsert, external_provider_get }) => {
  const { data: externalCareTeamGetData } = external_provider_get;
  const { data: upsertExternalProviderData, isFetching } = external_provider_upsert;

  let externalProviderInfo = null;
  let upsertMsg = false;
  let upsertMsgInfo = false;
  let isUpsertSuccess = false;

  if (externalCareTeamGetData && externalCareTeamGetData.ExternalProvider) {
    externalProviderInfo = externalCareTeamGetData.ExternalProvider[0];
  }
  // if (upsertExternalProviderData && upsertExternalProviderData.success) {
  //   upsertMsg = upsertExternalProviderData.result.Msg;
  //   upsertMsgInfo = upsertExternalProviderData.result.MsgInfo;
  //   isUpsertSuccess = upsertExternalProviderData.success;
  // }
  return {
    externalProviderInfo,
    isFetchingUpsert: isFetching,
  };
};
export default connect(mapStateToProps)(withRouter(ExternalCareTeamList));
