import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { submit, formValueSelector, change } from 'redux-form';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import AntdModal from '../../../../../Components/CustomModal';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
} from '../../../../../component/custom-redux-components';
import {
  smartContractUpsert,
  smartContractUpdate,
  listSmartCombos,
} from '../../../../../store/actions';
import getParam from '../../../../../util/getParam';
import SubmitButton from '../../../../../Components/submitButton';
import Loader, { Loader2 } from '../../../../../Components/Loader';

const ContractHeader = props => {
  let clientId = getParam(props.history, 'pId') || null;
   const [isClicked, setIsClicked] = useState(false);
  const [smartContractDataValues, setSmartContractDataValues] = useState(null);
  const dispatch = useDispatch();
  const { existingRows } = useSelector(state => {
    return {
      existingRows: formValueSelector('contractForm')(state, 'Goal'),
    };
  }, shallowEqual);

  const {
    handleDeleteContract,
    contractType,
    setContractType,
    enableCreateButton,
    setFinalSubmit,
    setEnableCreateButton,
    showPendingContractDrawer,
    currentTab,
    tabsCount,
    handleNext,
    goToPreviuos,
    finalSubmit,
    isUpsertFetching,
  } = props;

  const { SmartContractId, Status, StatusId, IsContractCreated, IsChangeRequest, RequestCount } =
    smartContractDataValues || {};

  // console.log('props.smartContractData', props.smartContractData);
  let status = {
    1: 'incomplete',
    2: 'pending',
    3: 'active',
    4: 'terminated',
    5: 'expired',
    6: 'delivered',
    7: 'declined'
  };

  useEffect(() => {
      if (props.smartContractData && Object.keys(props.smartContractData).length>0) {
        setSmartContractDataValues(props.smartContractData);
      }
    }, [props.smartContractData]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        {/* <div
          style={
            props.isFetchingSmartContractGet
              ? { height: '200px', position: 'relative' }
              : { height: '0px' }
          }
        >
          <Loader2 loading={props.isFetchingSmartContractGet} />
        </div> */}
        {!props.isFetchingSmartContractGet && (
          <>
            <div>
              <div className="row">
                <div className="col-md-6 mb-2 mt-2">
                  <div className="label mt-2 mb-2 d-inline mx-2">
                    Payment Contract {!IsContractCreated ? '' : 'Already Created'}
                  </div>
                  <div className="mt-2 mb-2 d-inline">
                    {SmartContractId != null && (
                      <>
                        <AntdModal
                          ModalButton={({ onClick }) => (
                            <span
                              className="py-1 px-3"
                              style={{
                                color: 'red',
                                border: '1px solid red',
                                borderRadius: '20px',
                                cursor: 'pointer',
                              }}
                              onClick={onClick}
                            >
                              {props.smartContractData.PaymentContractTypeId == 1
                                ? IsContractCreated
                                  ? 'End Contract'
                                  : 'Terminate Contract'
                                : 'End Fee For Service'}
                            </span>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                              <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                <h4 class="modal-title col-12 mb-3">
                                  {IsContractCreated
                                    ? 'Are you sure you want to end this contract? '
                                    : 'Are you sure you want to cancel?'}
                                  <br />
                                </h4>
                              </div>
                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  class="btn black-btn"
                                  onClick={() => {
                                    onCancel();
                                  }}
                                >
                                  No
                                </button>
                                <button
                                  class="btn"
                                  onClick={() => {
                                    onOk();
                                    handleDeleteContract(SmartContractId);
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </>
                    )}
                  </div>
                </div>
                {true && (
                  <div className="col-md-6 mb-2 mt-2 d-flex justify-content-end align-items-center">
                    <div
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #009EF7',
                        padding: '10px',
                        textAlign: 'center',
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'flex-end',
                        cursor: 'pointer',
                        width: 'fit-content',
                        borderRadius: '50px',
                        background: '#fff',
                      }}
                      onClick={showPendingContractDrawer}
                    >
                      {RequestCount ?<span
                          style={{
                            width: '20px',
                            height: '20px',
                            background: 'red',
                            color: 'white',
                            borderRadius: '50px',
                            position: 'absolute',
                            top: '-9px',
                            right: '20px',
                            fontWeight: '800',
                          }}
                        >
                          {RequestCount}
                        </span>:<></>}

                      <span
                        style={{
                          color: '#009EF7',
                          marginRight: '5px',
                        }}
                      >
                        Notes/Change Requests From Payer
                      </span>
                      <span
                        style={{
                          color: '#009EF7',
                          cursor: 'pointer',
                        }}
                      >
                        View all Details
                        <img src="images/icons/open_external.svg" className="mx-2" />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="container-fluid mt-2 pt-1 px-0"
              style={{ minHeight: '100px', overflow: 'visible', background: '#F8F8F8' }}
            >
              <div className="p-2">
                {/* <div className="row">
            <div className="col-md-12 mb-4">
              <div className="label mt-2 mb-2 d-inline mx-2">
                Payment Contract {!IsContractCreated ? '' : 'Already Created'}
              </div>
              <div className="mt-2 mb-2 d-inline">
                {SmartContractId != null && (
                  <>
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          className="py-1 px-3"
                          style={{
                            color: 'red',
                            border: '1px solid red',
                            borderRadius: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={onClick}
                        >
                          {props.smartContractData.PaymentContractTypeId == 1
                            ? IsContractCreated
                              ? 'End Contract'
                              : 'Cancel'
                            : 'End Fee For Service'}
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              {IsContractCreated
                                ? 'Are you sure you want to end this contract? '
                                : 'Are you sure you want to cancel?'}
                              <br />
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                handleDeleteContract(SmartContractId);
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </div> */}
                {
                  <div className="row details mt-1 mb-3">
                    <div className="col-md-3 mt-2 enrollment-form">
                      <div class="form-group mb-0 w-100">
                        <ReduxSelect
                          options={[
                            { Text: 'Value Based Contract', Value: '1' },
                            { Text: 'Fee for Service', Value: '2' },
                          ]}
                          onChange={value => {
                            setContractType(value);
                            if (value == '2') {
                              setEnableCreateButton(false);
                            } else {
                              setEnableCreateButton(true);
                            }
                          }}
                          className="basic-single"
                          classNamePrefix="select"
                          value={contractType}
                          type="text"
                          placeholder="Select Contract Type"
                          disabled={SmartContractId == null ? false : true}
                          // disabled={!IsContractCreated ? false : true}
                        />
                      </div>
                    </div>
                    <div className="col-md-5 mt-2 enrollment-form">
                      <div className="d-flex align-item-center justif-content-end">
                        {Status && (
                          <div class="form-group mb-0 w-100">
                            <label
                              className="placeholder-label"
                              style={{
                                zIndex: 1,
                                fontSize: '11px',
                                top: '-5px',
                                // left: !!disabled || value || active ? '0' : '0',
                                position: 'absolute',
                              }}
                            >
                              Status
                            </label>
                            <div className="contract-status">
                              {Status && (
                                <span
                                  className={status[StatusId]}
                                  style={{
                                    marginTop: '13px',
                                    textTransform: 'uppercase',
                                    display: 'inline-block',
                                  }}
                                >
                                  {Status}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        {props.isNewContract == 'false' && contractType == '1' && StatusId != 6 && (
                          <>
                            {props.fieldDisabled == true ? (
                              <div className="w-100">
                                <button
                                  class="btn"
                                  onClick={() => {
                                    // onOk();
                                    // handleDeleteContract(SmartContractId);
                                    props.setFieldDisabled(false);
                                  }}
                                >
                                  Edit Contract
                                </button>
                              </div>
                            ) : (
                              <div className="w-100">
                                <button
                                  class="btn"
                                  onClick={() => {
                                    // onOk();
                                    // handleDeleteContract(SmartContractId);
                                    props.setFieldDisabled(true);
                                  }}
                                >
                                  Disable Edit
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <>
                      {contractType == '2' ? (
                        <>
                          <div className="col-md-4 mt-2 text-right">
                            {SmartContractId == null && (
                              <button
                                type="button"
                                className="btn black-btn"
                                data-dismiss="modal"
                                onClick={() => {
                                  if (!isClicked) {
                                    setIsClicked(true);
                                    let data = {
                                      SmartContract: [
                                        {
                                          SmartContractId: null,
                                          ClientId: clientId,
                                          PaymentContractTypeId: '2',
                                        },
                                      ],
                                    };

                                    let Data = {
                                      Json: JSON.stringify(data),
                                    };
                                    dispatch(smartContractUpsert(Data));
                                    setTimeout(() => {
                                      setIsClicked(false);
                                    }, 1000);
                                  }
                                }}
                              >
                                Save as Fee for Service
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {currentTab == '0' ? (
                            <div className="col-md-4 mt-2 text-right d-none">
                              {' '}
                              {currentTab + 1 != tabsCount && (
                                <button
                                  type="submit"
                                  // onClick={handleNext}
                                  class="btn btn-outline-primary"
                                  // disabled={disable}
                                  onClick={() => {
                                    setFinalSubmit('Next');
                                    // dispatch(submit('contractForm'));
                                  }}
                                >
                                  Next
                                </button>
                              )}
                            </div>
                          ) : (
                            <>
                              {currentTab > 0 && currentTab != '1' && (
                                <div className="col-md-4 mt-2 text-right d-none">
                                  <button
                                    onClick={goToPreviuos}
                                    type="button"
                                    className="btn btn-outline-primary mr-3"
                                    // class="btn btn-outline-primary btn-eForm-Prev mr-2"
                                    // disabled={disable}
                                  >
                                    Previous
                                  </button>
                                  {currentTab < 9 && (
                                    <button
                                      onClick={handleNext}
                                      type="button"
                                      className="btn btn-outline-primary"
                                      // class="btn btn-outline-primary btn-eForm-Prev mr-2"
                                      // disabled={disable}
                                    >
                                      Next
                                    </button>
                                  )}
                                </div>
                              )}
                              {currentTab == '1' && (
                                <div className="col-md-6 mt-2 text-right">
                                  {enableCreateButton ? (
                                    <>
                                      {/* {!IsContractCreated && contractType == '1'} */}
                                      {!IsContractCreated && contractType == '1' && (
                                        <button
                                          type="button"
                                          className="btn black-btn mr-3 d-none"
                                          data-dismiss="modal"
                                          onClick={() => {
                                            setFinalSubmit('Save');
                                            // dispatch(submit('contractForm'));
                                          }}
                                        >
                                          Save For Now
                                        </button>
                                        // <SubmitButton
                                        //   type="button"
                                        //   data-dismiss="modal"
                                        //   value={'Save For Now'}
                                        //   onClick={() => {
                                        //     setFinalSubmit('Save');
                                        //   }}
                                        //   className={'mr-3'}
                                        //   loading={finalSubmit == 'Save' && isUpsertFetching}
                                        //   style={{ height: '45px' }}
                                        // />
                                      )}
                                      {!IsContractCreated && contractType == '1' && (
                                        <button
                                          type="button"
                                          className="btn black-btn mr-3 d-none"
                                          data-dismiss="modal"
                                          onClick={() => {
                                            setFinalSubmit('Create');
                                            // dispatch(submit('contractForm'));
                                          }}
                                        >
                                          Create Contract
                                        </button>
                                        // <SubmitButton
                                        //   type="button"
                                        //   data-dismiss="modal"
                                        //   value={'Create Contract'}
                                        //   onClick={() => {
                                        //     setFinalSubmit('Create');
                                        //   }}
                                        //   className={'mr-3'}
                                        //   loading={finalSubmit == 'Create' && isUpsertFetching}
                                        //   style={{ height: '45px' }}
                                        // />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {SmartContractId == null && contractType == '2' ? (
                                        <button
                                          type="button"
                                          className="btn black-btn"
                                          data-dismiss="modal"
                                          onClick={() => {
                                            let data = {
                                              SmartContract: [
                                                {
                                                  SmartContractId: null,
                                                  ClientId: clientId,
                                                  PaymentContractTypeId: '2',
                                                },
                                              ],
                                            };

                                            let Data = {
                                              Json: JSON.stringify(data),
                                            };
                                            dispatch(smartContractUpsert(Data));
                                          }}
                                        >
                                          Save as Fee for Service
                                        </button>
                                      ) : (
                                        <>
                                          <div className="d-none">
                                            {' '}
                                            {SmartContractId == null && (
                                              <button
                                                type="button"
                                                className="btn black-btn mr-3"
                                                data-dismiss="modal"
                                                disabled
                                              >
                                                Save For Now1
                                              </button>
                                            )}
                                            {SmartContractId == null && (
                                              <button
                                                type="button"
                                                className="btn black-btn"
                                                data-dismiss="modal"
                                                disabled
                                              >
                                                Create Contract
                                              </button>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}{' '}
                                  <>
                                    <button
                                      onClick={goToPreviuos}
                                      type="button"
                                      className="btn btn-outline-primary mr-3 d-none"
                                      // class="btn btn-outline-primary btn-eForm-Prev mr-2"
                                      // disabled={disable}
                                    >
                                      Previous
                                    </button>
                                    {tabsCount > 2 && (
                                      <button
                                        onClick={handleNext}
                                        type="button"
                                        className="btn btn-outline-primary d-none"
                                        // class="btn btn-outline-primary btn-eForm-Prev mr-2"
                                        // disabled={disable}
                                      >
                                        Next
                                      </button>
                                    )}
                                  </>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </div>
                }
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default connect()(withRouter(ContractHeader));
