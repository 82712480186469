import React, { Component } from 'react';
import { Switch, Checkbox } from 'antd';
import renderHTML from 'react-render-html';
import TextareaAutosize from 'react-autosize-textarea';
import DictationComponent from '../../../../../../Components/DictationComponent';
import SubmitButton from '../../../../../../Components/submitButton';
import { Qualifier, Flagged } from './flag';
import ToggleNote from '../toggleNote';

let noteTimer;
class ToggleMultiSelectOneLineType extends Component {
  constructor(props) {
    super(props);
    let {
      Note,
      Response,
      Option1Value,
      Option2Value,
      Option3Value,
      Option4Value,
      Option5Value,
      Option6Value,
      Option7Value,
      Option8Value,
      Option9Value,
      Option10Value,
      Option11Value,
    } = props.survey;
    let responseArr =
      Response &&
      JSON.parse(Response).map(item => {
        return {
          [item]: true,
        };
      });
    let responseObj =
      responseArr &&
      responseArr.reduce(function(result, current) {
        return Object.assign(result, current);
      }, {});
    this.state = {
      result: {
        [Option1Value]: false,
        [Option2Value]: false,
        [Option3Value]: false,
        [Option4Value]: false,
        [Option5Value]: false,
        [Option6Value]: false,
        [Option7Value]: false,
        [Option8Value]: false,
        [Option9Value]: false,
        [Option10Value]: false,
        [Option11Value]: false,
        ...responseObj,
      },
      noteValue: Note,
      toggleActive: Note ? true : false,
      isFlagged: null,
    };
  }
  getDicatationStatus = value => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    let timer;
    return () => {
      clearTimeout(timer);
      setTimeout(() => {
        setValue.call(scope, {
          response: this.state.noteValue,
          surveyQuestionId: SurveyQuestionId,
          level: level,
          subLevel: subLevel,
          subCatLevel: subCatLevel,
          index: index,
        });
      }, 3000);
    };
  };

  onChange = (event, click) => {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    const { value } = event.target;
    this.setState({
      noteValue: value,
    });
    clearTimeout(noteTimer);
    noteTimer = setTimeout(() => {
      setValue.call(scope, {
        response: this.state.value,
        note: value,
        surveyQuestionId: SurveyQuestionId,
        level: level,
        subLevel: subLevel,
        subCatLevel: subCatLevel,
        index: index,
      });
    }, 1000);
  };
  getNoteChanges = () => {
    this.setState({ noteValue: '' });
  };
  handleDictate = result => {
    if (result) {
      let data;
      if (this.state.noteValue) {
        data = `${this.state.noteValue} ${result}`;
      } else {
        data = result;
      }
      this.setState({ noteValue: data });
    }
  };
  render() {
    const {
      survey,
      onClick,
      setValue,
      scope,
      index,
      total,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option2Value,
      Option2Text,
      Option3Value,
      Option3Text,
      Option4Value,
      Option4Text,
      Option5Value,
      Option5Text,
      Option6Value,
      Option6Text,
      Option7Value,
      Option7Text,
      Option8Value,
      Option8Text,
      Option9Value,
      Option9Text,
      Option10Value,
      Option10Text,
      Option11Value,
      Option11Text,
      Option1QF,
      Option2QF,
      Option3QF,
      Option4QF,
      Option5QF,
      Option6QF,
      Option7QF,
      Option8QF,
      Option9QF,
      Option10QF,
      Option11QF,
      Response,
      SurveyQuestionId,
      AddNote,
    } = survey;
    const textArray = [
        Option1Text,
        Option2Text,
        Option3Text,
        Option4Text,
        Option5Text,
        Option6Text,
        Option7Text,
        Option8Text,
        Option9Text,
        Option10Text,
        Option11Text,
      ],
      valueArray = [
        Option1Value,
        Option2Value,
        Option3Value,
        Option4Value,
        Option5Value,
        Option6Value,
        Option7Value,
        Option8Value,
        Option9Value,
        Option10Value,
        Option11Value,
      ],
      flagArray = [
        Option1QF,
        Option2QF,
        Option3QF,
        Option4QF,
        Option5QF,
        Option6QF,
        Option7QF,
        Option8QF,
        Option9QF,
        Option10QF,
        Option11QF,
      ];
    let responseArr =
      Response &&
      JSON.parse(Response).map((item, index) => {
        return {
          [item]: true,
        };
      });

    let flaggedResponse = [];
    responseArr &&
      responseArr.map(item => {
        for (let i in item) {
          flaggedResponse.push(flagArray[parseInt(i) - 1]);
        }
      });
    // let result = { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false };
    let isFlaggedShow = flaggedResponse && flaggedResponse.some(item => item === false);
    return (
      <div className="type-border col-12">
        <div
        // class="card-body"
        >
          {Question && Question.length > 0 && (
            <h5 className="survey-question rcm-question text-left mb-3">
              {QuestionNumber && `${QuestionNumber}.`} {renderHTML(Question)}
            </h5>
          )}
          <ul class="answer-options d-flex flex-row flex-wrap text-left">
            {textArray.map((item, qIndex) => {
              let val = valueArray[qIndex];
              return (
                item && (
                  <li className="pl-3">
                    <Checkbox
                      disabled={isReadOnly}
                      name="current-situation"
                      checked={this.state.result[val]}
                      value={item}
                      onClick={e => {
                        let result = this.state.result;
                        result[val] = e.target.checked;
                        let keys = Object.keys(result);
                        let filtered = keys.filter(key => {
                          return result[key];
                        });
                        this.setState({
                          result,
                          filtered,
                        });
                        onClick.bind(scope, {
                          surveyQuestionId: SurveyQuestionId,
                          response: JSON.stringify(filtered),
                          note: this.state.noteValue,
                          level: level,
                          subLevel: subLevel,
                          subCatLevel: subCatLevel,
                          index: index,
                        })();
                      }}
                    >
                      {' '}
                      {item && renderHTML(item)}
                    </Checkbox>
                  </li>
                )
              );
            })}
            <div className="ml-3">{isFlaggedShow && Flagged}</div>
          </ul>
          {AddNote && (
            <div className="text-left">
              <div className="custom-control" style={{ padding: 'unset' }}>
                <label className="custom-control-label mb-3">
                  <Switch
                    checked={this.state.toggleActive}
                    onClick={() => this.setState({ toggleActive: !this.state.toggleActive })}
                    size="small"
                  />
                  Add Note
                </label>
              </div>
            </div>
          )}
          {AddNote && this.state.toggleActive && (
            <>
              <ToggleNote
                handleDictate={this.handleDictate}
                getDicatationStatus={this.getDicatationStatus}
                noteValue={this.state.noteValue}
                getNoteChanges={this.getNoteChanges}
                onChange={this.onChange}
                isReadOnly={isReadOnly}
              />
            </>
          )}
          {DoesNotApplyText && (
            <div className="not-apply-to-me">
              <a
                name="current-situation"
                value={DoesNotApplyText}
                onClick={onClick.bind(scope, {
                  surveyQuestionId: SurveyQuestionId,
                  response: DoesNotApplyValue,
                })}
              >
                {DoesNotApplyText}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ToggleMultiSelectOneLineType;
