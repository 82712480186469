import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Table } from 'antd';

import TaskCompleted from './customScreens/TaskCompleted';
import SurveyCompleted from './customScreens/SurveyCompleted';

class TimeTravelEventView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { EventTypeName, EventTypeId, EventJsonData } = this.props;
    return (
      <div class="border-0">
        {EventTypeId === 4 && <TaskCompleted EventJsonData={EventJsonData} />}
        {EventTypeId === 3 && <SurveyCompleted EventJsonData={EventJsonData} />}
      </div>
    );
  }
}

export default TimeTravelEventView;
