import React from 'react';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { listCWPayment, getCWPayment, deleteCWPayment } from '../../../../store/actions';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import moment from 'moment';
import AntdModal from '../../../../Components/CustomModal';
import Loader, { Loader2 } from '../../../../Components/Loader';

import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Delete_Sucess_Msg,
} from '../../../../app-config';
import { Table, Modal, Tooltip } from 'antd';
import PersistTableSort from '../../../../component/persistTableSort';
import InsPayment from './ins-payer';
import ViewPaymentDetail from './viewPaymentDetail';


class PaymentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createAndEditGroupModal: false,
      PaymentList: true,
      NewPayment: false,
      ViewPayment: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(listCWPayment({}));
  }

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  componentWillReceiveProps({ isRemoved }) {
    if (isRemoved && isRemoved != this.props.isRemoved) {
      this.toggle('Payer Deleted Successfully');
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listCWPayment({
        json: JSON.stringify({
          CWPayment: [
            {
              Search: value,
            },
          ],
        }),
      }),
    );
  };
  NewPaymentToggle = () => {
    this.setState(prevState => ({
      NewPayment: !prevState.NewPayment,
      PaymentList: !prevState.PaymentList,
    }));
  };
  EditPaymentToggle = () => {
    this.setState(prevState => ({
      IsEdit: !prevState.IsEdit,
      PaymentList: !prevState.PaymentList,
    }));
  };
  ViewPaymentToggle = () => {
    this.setState(prevState => ({
      ViewPayment: !prevState.ViewPayment,
      PaymentList: !prevState.PaymentList,
    }));
  };
  render() {
    const tableCoumns = [
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
      },
      {
        title: 'Applied Amount',
        dataIndex: 'AppliedAmount',
        key: 'AppliedAmount',
        sorter: (a, b) => isNumericComparer(a.AppliedAmountSort, b.AppliedAmountSort),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
      },
      {
        title: 'Applied Date',
        dataIndex: 'AppliedDate',
        key: 'AppliedDate',
        sorter: (a, b) => isDateComparer(a.AppliedDateSort, b.AppliedDateSort),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
      },
      {
        title: 'Payment Date',
        dataIndex: 'PaymentDate',
        key: 'PaymentDate',
        sorter: (a, b) => isDateComparer(a.PaymentDateSort, b.PaymentDateSort),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
      },
      {
        title: 'Applied Type',
        dataIndex: 'AppliedType',
        key: 'AppliedType',
        sorter: (a, b) => isComparer(a.AppliedType, b.AppliedType),
        sortDirections: ['descend', 'ascend'],
        width: '30px',
      },

      {
        title: 'Action',
        key: 'x',
        width: '30px',
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <>
                {' '}
                <div class="tools-grid">
                  <div className="mr-2">
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Delete">
                            <p>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to delete this payment?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  deleteCWPayment({
                                    json: JSON.stringify({
                                      CWPayment: [
                                        {
                                          CWPaymentId: record.CWPaymentId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>{' '}
                  <div>
                    {record.IsEdit ? (
                      <span
                        className="start-btn"
                        onClick={() => {
                          this.props.dispatch(
                            getCWPayment({
                              json: JSON.stringify({
                                CWPayment: [
                                  {
                                    CWPaymentId: record.CWPaymentId,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.EditPaymentToggle(true);
                        }}
                      >
                        Edit
                      </span>
                    ) : (
                      <span
                        className="start-btn"
                        onClick={() => {
                          this.props.dispatch(
                            getCWPayment({
                              json: JSON.stringify({
                                CWPayment: [
                                  {
                                    CWPaymentId: record.CWPaymentId,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.ViewPaymentToggle();
                        }}
                      >
                        View
                      </span>
                    )}
                  </div>
                </div>
              </>
            );
          } else {
            return null;
          }
        },
       
      },
    ];
    const { paymentList, isReadOnlyUser } = this.props;
    const { PaymentList, NewPayment, ViewPayment, IsEdit } = this.state;
    return (
      <React.Fragment>
        <>
          {PaymentList && (
            <div className="container-fluid mt-5">
              <div className="">
                <div className="row px-0 mt-5 d-flex align-items-center">
                  <div class="col-md-12 col-xl-4 px-0 align-self-baseline">
                    {' '}
                    <h4 className="">PAYMENT LIST</h4>
                  </div>
                  <div class="col-md-6 col-xl-6 px-0 d-flex align-items-center">
                    <div class="search-box d-inline-flex w-100">
                      <div class="form-group has-search w-100 d-flex align-items-center">
                        <input
                          type="text"
                          class="form-control 
                   shadow-sm rcm-document-search"
                          placeholder="Search"
                          id="filterbox"
                          onChange={this.onSearchChange}
                          style={{ minHeight: '46px' }}
                        />
                        <button class="btn search-icon " type="button">
                          {' '}
                          <i class="fa fa-search" />{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-2 px-0 d-flex align-items-center align-self-baseline justify-content-end">
                    {/* <input
                  type="button"
                  style={{ width: '150px' }}
                  class="btn btn-outline-primary btn-eForm-Prev mr-2"
                  value={'Filter'}
                /> */}
                    {!isReadOnlyUser && (
                      <input
                        type="button"
                        style={{ width: '150px' }}
                        class="btn"
                        value={'Add Payment'}
                        onClick={event => {
                          this.NewPaymentToggle();
                          // this.props.history.push('/cw/accounting?tab=insurance-payment-list');
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 enrollment-form mt-1">
                  <div className="row card-body rcm-subtab-table-block">
                    <Loader2 loading={this.props.isFetching} />
                    <div className="col-12 rcm-tools-table">
                      <PersistTableSort
                        name="payer-list"
                        columns={tableCoumns}
                        dataSource={paymentList}
                        size={'middle'}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div className="dataTables_wrapper no-footer">
                                <div className="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a className="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a className="paginate_button next">Next</a>
                                  ) : (
                                    <a className="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {NewPayment && <InsPayment toggleView={this.NewPaymentToggle} />}
          {IsEdit && <InsPayment toggleView={this.EditPaymentToggle} IsEdit={true} />}
          {ViewPayment && <ViewPaymentDetail toggleView={this.ViewPaymentToggle} />}
        </>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.props.dispatch(listCWPayment({}));
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
        {/* <CustomModal
          isOpen={this.state.isModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
            this.props.dispatch(listCWPayment({}));
          }}
          yesText="Close"
          // noText="cancel"
          hideCancel={true}
          // noAction={() => {
          //   this.Removetoggle();
          //   // this.props.history.push('/manage/courses');
          // }}
        /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ list_CWPayment, delete_CWPayment }) => {
  const { data: CWPaymentData, isFetching } = list_CWPayment;
  let isRemoved = false;
  let paymentList = [];

  if (delete_CWPayment.data && delete_CWPayment.data.success) {
    isRemoved = true;
  }
  if (CWPaymentData && CWPaymentData.data) {
    paymentList = CWPaymentData.data || [];
  }

  return {
    isRemoved,
    paymentList: paymentList,
    isFetching: isFetching,
  };
};
export default connect(mapStateToProps)(withRouter(PaymentList));
