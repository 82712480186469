import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { Button, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import AntdModal from '../../../../Components/CustomModal';

import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { getListClientGroup, upsertClientGroup, getClientGroup } from '../../../../store/actions';
import PersistTableSort from '../../../../component/persistTableSort';
import GridSearch from '../../../../Components/gridSearch';

class ManagePatients extends Component {
  constructor() {
    super();
    this.state = {
      searchStr: '',
      columns2: [
        {
          title: 'Image',
          dataIndex: 'ProfileImgUrl',
          key: 'ProfileImgUrl',
          render: (text, record) => (
            <div class="student-list-pic">
              <img
                src={text ? text : 'images/announcements-img2.jpg'}
                class="img-circle"
                height="40"
                width="40"
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
            </div>
          ),
        },
        {
          title: 'Patient ID',
          // dataIndex: 'ClientId',
          dataIndex: 'DisplayClientId',
          key: 'ClientId',
          // sorter: (a, b) => isNumericComparer(a.ClientId, b.ClientId),
          sorter: (a, b) => isNumericComparer(a.DisplayClientId, b.DisplayClientId),

          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Patient Name',
          dataIndex: 'DisplayName',
          key: 'DisplayName',
          sorter: (a, b) => isComparer(a.DisplayName, b.DisplayName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Center',
          dataIndex: 'CenterName',
          key: 'DisplayName',
          sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Include',
          dataIndex: 'IsAppointment',
          key: 'IsAppointment',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  title="Click to Include"
                  style={{ color: '#09B66D', cursor: 'pointer' }}
                  onClick={() => {
                    this.props.dispatch(
                      upsertClientGroup({
                        json: JSON.stringify({
                          ClientGroup: [
                            {
                              ClientGroupId: null,
                              GroupId: this.getGroupId() || this.props.groupId,
                              ClientId: record.ClientId,
                              IsAdd: true,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  {!this.props.isReadOnlyUser && (
                    <img
                      src={'images/circle-plus.svg'}
                      class="img-circle"
                      height="40"
                      width="40"
                      style={{ borderRadius: '50%', marginTop: '12px' }}
                    />
                  )}
                </span>
              </div>
            );
          },
        },
      ],
      columns3: [
        {
          title: 'Image',
          dataIndex: 'ProfileImgUrl',
          key: 'ProfileImgUrl',
          render: (text, record) => (
            <div class="student-list-pic">
              <img
                src={text ? text : 'images/announcements-img2.jpg'}
                class="img-circle"
                height="40"
                width="40"
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
            </div>
          ),
        },
        {
          title: 'Patient ID',
          // dataIndex: 'ClientId',
          dataIndex: 'DisplayClientId',
          key: 'ClientId',
          // sorter: (a, b) => isNumericComparer(a.ClientId, b.ClientId),
          sorter: (a, b) => isNumericComparer(a.DisplayClientId, b.DisplayClientId),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Patient Name',
          dataIndex: 'DisplayName',
          key: 'DisplayName',
          sorter: (a, b) => isComparer(a.DisplayName, b.DisplayName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Center',
          dataIndex: 'CenterName',
          key: 'DisplayName',
          sorter: (a, b) => isComparer(a.CenterName, b.CenterName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Exclude',
          dataIndex: 'IsAppointment',
          key: 'IsAppointment',
          render: (text, record, index) => {
            return (
              <div>
                {!this.props.isReadOnlyUser && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <span
                        title="Click to Exclude"
                        style={{ color: '#FF3D57', cursor: 'pointer' }}
                        onClick={onClick}
                      >
                        <img
                          src={'images/circle-minus.svg'}
                          class="img-circle"
                          height="40"
                          width="40"
                          style={{ borderRadius: '50%', marginTop: '12px' }}
                        />
                      </span>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            {`Are you sure you want to remove this patient from Group?`}
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              this.props.dispatch(
                                upsertClientGroup({
                                  json: JSON.stringify({
                                    ClientGroup: [
                                      {
                                        ClientGroupId: record.ClientGroupId,
                                        GroupId: this.getGroupId() || this.props.groupId,
                                        ClientId: record.ClientId,
                                        IsAdd: false,
                                      },
                                    ],
                                  }),
                                }),
                              );
                              onOk();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
            );
          },
        },
      ],
    };
  }

  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId') || this.props.groupId;
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = -1;
    }
    return groupId;
  }
  componentDidMount() {
    this.props.dispatch(
      getClientGroup({
        json: JSON.stringify({
          ClientGroup: [
            {
              GroupId: this.getGroupId() || this.props.groupId,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(
      getListClientGroup({
        json: JSON.stringify({
          Client: [
            {
              Search: '',
              GroupId: this.getGroupId() || this.props.groupId,
            },
          ],
        }),
      }),
    );
  }

  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      if (this.props.screen != 'appointments') {
        this.props.isClientAdded(isSuccess);
      }
      this.props.dispatch(
        getClientGroup({
          json: JSON.stringify({
            ClientGroup: [
              {
                GroupId: this.getGroupId() || this.props.groupId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(
        getListClientGroup({
          json: JSON.stringify({
            Client: [
              {
                Search: this.state.searchStr,
                GroupId: this.getGroupId() || this.props.groupId,
              },
            ],
          }),
        }),
      );
    }
  }
  resetSearch = () => {
    this.setState({
      searchStr: '',
    });
  };

  patientSearch = value => {
    this.setState({
      searchStr: value,
    });
    let data = {
      Client: [
        {
          Search: value,
          GroupId: this.getGroupId() || this.props.groupId,
        },
      ],
    };
    let groupPatientListData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(getListClientGroup(groupPatientListData));
  };
  render() {
    const { columns2, columns3 } = this.state;
    const { GroupClientList, SearchedClient, Banner } = this.props;
    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="border-0">
                  <div className="row mt-5 d-flex align-items-center">
                    <div class="col-6">
                      {' '}
                      <h4 className="">PATIENT LIST</h4>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-end">
                      <GridSearch onSearchChange={this.patientSearch} />
                    </div>
                  </div>

                  <div className="row pb-5 pt-3 pb-3">
                    <div class="col-12 col-md-6">
                      <h6>{`Add below patients to this group`}</h6>
                      <div
                        style={{
                          position: 'relative',
                        }}
                      >
                        <Loader2 loading={this.props.isFetching3} />
                        <div
                        // style={{ height: '600px', overflowY: 'scroll' }}
                        >
                          <PersistTableSort
                            name="patient-SearchedClient"
                            columns={columns2}
                            dataSource={SearchedClient}
                            size={'middle'}
                            pagination={false}
                            scroll={{ y: 600 }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <h6>Patients Enrolled</h6>
                      <div
                        style={{
                          position: 'relative',
                        }}
                      >
                        <Loader2 loading={this.props.isFetching2} />
                        <div
                        // style={{ height: '600px', overflowY: 'scroll' }}
                        >
                          <PersistTableSort
                            name="patient-GroupClientList"
                            columns={columns3}
                            dataSource={GroupClientList}
                            size={'middle'}
                            pagination={false}
                            scroll={{ y: 600 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ get_client_group, get_list_client_group, upsert_client_group }) => {
  const { data: ClientListData, isFetching: isFetching2 } = get_client_group;
  const { data: ListClientData, isFetching: isFetching3 } = get_list_client_group;

  let GroupClientList = [];
  let SearchedClient = [];
  let isSuccess = false;
  if (ClientListData) {
    GroupClientList = ClientListData.ClientGroup || [];
  }

  if (ListClientData) {
    SearchedClient = ListClientData.Client || [];
  }
  if (upsert_client_group.data && upsert_client_group.data.success) {
    isSuccess = upsert_client_group.data.success;
  }
  return {
    GroupClientList,
    SearchedClient,
    isSuccess,
    isFetching2,
    isFetching3,
  };
};
export default connect(mapStateToProps)(withRouter(ManagePatients));
