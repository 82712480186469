import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { tokensStudentTransactionHistoryGetAll } from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';
import { currencyFormatter2, numberFormat } from '../../../util/formatter';

class ClientsTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(
      tokensStudentTransactionHistoryGetAll({
        JsonInput: JSON.stringify({
          TokenManagement: {
            StudentId: this.props.StudentId,
          },
        }),
      }),
    );
  }

  render() {
    const { listData, isFetching, headerInfo } = this.props;
    const { StaffProfileImage, UsedBy, TotalAmount, TotalTokensDebited } = headerInfo || {};
    const columns = [
      {
        title: 'Transaction date',
        dataIndex: 'PurchasedDate',
        key: 'PurchasedDate',
        sorter: (a, b) => isDateComparer(a.PurchasedDateForSort, b.PurchasedDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Counselor',
        dataIndex: 'UsedBy',
        key: 'UsedBy',
        sorter: (a, b) => isComparer(a.UsedBy, b.UsedBy),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span className="d-flex align-items-center">
            <img
              style={{
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={record.StaffProfileImage || '/images/blank-profile.png'}
            />
            <span className="ml-1">{text}</span>
          </span>
        ),
      },

      {
        title: 'Token Amount',
        dataIndex: 'TotalAmount',
        key: 'TotalAmount',
        sorter: (a, b) => isNumericComparer(a.TotalAmount, b.TotalAmount),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          text && (
            <span>
              <img src="/images/smallCoin.svg" />
              <span style={{ marginLeft: '5px' }}>{text}</span>
            </span>
          ),
      },
    ];
    return (
      <div class="border-0 token-management">
        <div class="row">
          <div class="col-12 mb-2">
            <span className="d-flex align-items-center">
              <img
                style={{ height: '60px', width: '60px', borderRadius: '50%', objectFit: 'cover' }}
                src={StaffProfileImage || '/images/blank-profile.png'}
              />
              <span className="ml-1">{UsedBy}</span>
            </span>
          </div>
          <div class="col-6">
            <div className="status-card status-blue">
              <span className="title">Total Tokens Debited</span>
              <span className="count">
                <img className="mr-1" src="/images/bigCoin.svg" />
                {TotalTokensDebited && numberFormat.format(parseInt(TotalTokensDebited))}
              </span>
            </div>
          </div>
          <div class="col-6">
            <div className="status-card status-blue">
              <span className="title">Total Debited in $</span>
              <span className="count">
                {TotalAmount && currencyFormatter2.format(TotalAmount || 0)}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Loader2 loading={isFetching} />
            <div className="">
              <Table columns={columns} dataSource={listData} pagination={false} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ tokens_student_transaction_history_get_all }) => {
  const { data: TokenData, isFetching, error } = tokens_student_transaction_history_get_all;
  let listData = [];
  let headerInfo = [];

  if (TokenData && TokenData.TokenManagement) {
    listData = TokenData.TokenManagement[0].History;
    headerInfo = TokenData.TokenManagement[0].Header[0];
  }

  return {
    isFetching,
    listData,
    headerInfo,
  };
};
export default connect(mapStateToProps)(ClientsTransactions);
