import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import moment from 'moment';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import { listServiceCodeBundle, getServiceCodeBundle } from '../../../../store/actions';
import BundleCodeDetail from './customScreens/bundleCodeDetail';
import PersistTableSort from '../../../../component/persistTableSort';
import ReactRangePicker from '../../../../component/Range-Picker';
import GridSearch from '../../../../Components/gridSearch';

class BundleService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineData: {},
      tabView: true,
      columns: [
        {
          title: 'Patient ID',
          // dataIndex: 'ClientId',
          dataIndex: 'DisplayClientId',
          key: 'ClientId',
          // sorter: (a, b) => isNumericComparer(a.ClientId, b.ClientId),
          sorter: (a, b) => isNumericComparer(a.DisplayClientId, b.DisplayClientId),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Patient Name',
          dataIndex: 'ClientName',
          key: 'ClientName',
          sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Provider',
          dataIndex: 'PractitionerName',
          key: 'PractitionerName',
          sorter: (a, b) => isComparer(a.PractitionerName, b.PractitionerName),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Bundled Code',
          dataIndex: 'ServiceCode',
          key: 'ServiceCode',
          sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'Insurance Name ',
          dataIndex: 'InsuranceName',
          key: 'InsuranceName',
          sorter: (a, b) => isComparer(a.InsuranceName, b.InsuranceName),
          sortDirections: ['descend', 'ascend'],
          render: (text, record, index) => {
            return <>{text}</>;
          },
        },
        {
          title: 'Start Date',
          dataIndex: 'StartDate',
          key: 'StartDate',
          sorter: (a, b) => isDateComparer(a.StartDateForSort, b.StartDateForSort),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        {
          title: 'End Date',
          dataIndex: 'EndDate',
          key: 'EndDate',
          sorter: (a, b) => isDateComparer(a.EndDateForSort, b.EndDateForSort),
          sortDirections: ['descend', 'ascend'],
          // width: 150,
        },
        // {
        //   title: 'Bundled Cost',
        //   dataIndex: 'BundleCost',
        //   key: 'BundleCost',
        //   sorter: (a, b) => isNumericComparer(a.BundleCost, b.BundleCost),
        //   sortDirections: ['descend', 'ascend'],
        //   // width: 150,
        // },

        {
          title: 'Action',
          // fixed: 'right',
          width: 100,
          render: (text, record, index) => {
            if (!this.props.isReadOnlyUser) {
              return (
                <div class="tools-grid">
                  <>
                    <span
                      className="start-btn"
                      onClick={() => {
                        props.dispatch(
                          getServiceCodeBundle({
                            json: JSON.stringify({
                              ServiceCodeBundle: [
                                {
                                  ServiceLineId: record.ServiceLineId,
                                },
                              ],
                            }),
                          }),
                        );
                        this.setState({
                          tabView: false,
                          ServiceLineId: record.ServiceLineId,
                        });
                      }}
                    >
                      Manage
                    </span>
                  </>
                </div>
              );
            } else {
              return null;
            }
          },
        },
      ],
      StartDate: moment(new Date())
        .subtract(30, 'd')
        .format('MM-DD-YYYY'),
      EndDate: moment(new Date()).format('MM-DD-YYYY'),
    };
  }
  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = -1;
    }
    return groupId;
  }
  toggleView = getLatest => {
    this.setState(prevState => ({
      tabView: !prevState.tabView,
    }));
    // if (getLatest) {
    //   this.props.dispatch(
    //     listSurveyQuestionCategory({
    //       json: JSON.stringify({
    //         Survey: [
    //           {
    //             ClientId: this.props.clientId,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  };
  setStoredFilter = () => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);

    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      if (JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter) {
        let sDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.startDateRange;
        let eDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.endDateRange;
        this.setState({
          StartDate: sDate,
          EndDate: eDate,
        });
        if (!JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter) {
          this.props.dispatch(
            listServiceCodeBundle({
              json: JSON.stringify({
                ServiceCodeBundle: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                  },
                ],
              }),
            }),
          );
          return;
        } else {
          let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
          this.setState({
            filterBy,
          });
          this.props.dispatch(
            listServiceCodeBundle({
              json: JSON.stringify({
                ServiceCodeBundle: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                    FilterBy: filterBy,
                  },
                ],
              }),
            }),
          );
        }
      } else if (
        JSON.parse(pageFilter)[`table_${this.props.name}`] &&
        JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter
      ) {
        let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
        this.setState({
          filterBy,
        });
        this.props.dispatch(
          listServiceCodeBundle({
            json: JSON.stringify({
              ServiceCodeBundle: [
                {
                  StartDate: this.state.StartDate,
                  EndDate: this.state.EndDate,
                  FilterBy: filterBy,
                },
              ],
            }),
          }),
        );
      }
    } else {
      this.props.dispatch(
        listServiceCodeBundle({
          json: JSON.stringify({
            ServiceCodeBundle: [
              {
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate,
              },
            ],
          }),
        }),
      );
    }
  };

  componentDidMount() {
    this.setStoredFilter();
  }
  componentWillReceiveProps({ ServiceLineData, isCreated }) {
    if (
      ServiceLineData &&
      JSON.stringify(ServiceLineData[0]) != JSON.stringify(this.props.ServiceLineData[0])
    ) {
      let data = {
        ...ServiceLineData[0],
        ServiceCodeId: ServiceLineData[0] && parseInt(ServiceLineData[0].ServiceCodeId),
      };
      this.setState({
        ServiceLineData: data,
        tabView: false,
      });
    }
    // if (isCreated && isCreated != this.props.isCreated) {
    //   this.props.dispatch(
    //     getGroupServiceLine({
    //       json: JSON.stringify({
    //         GroupServiceLine: [
    //           {
    //             GroupAppointmentId: this.state.GroupAppointmentId,
    //           },
    //         ],
    //       }),
    //     }),
    //   );
    // }
  }
  storeRangeFilter = date => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && !JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        rangeFilter: { startDateRange: `${date[0]}`, endDateRange: `${date[1]}` },
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };
  handleChange = (key, date) => {
    this.storeRangeFilter(date);
    this.props.dispatch(
      listServiceCodeBundle({
        Json: JSON.stringify({
          ServiceCodeBundle: [
            {
              Search: this.state.searchStr,
              StartDate: date[0],
              EndDate: date[1],
            },
          ],
        }),
      }),
    );
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });
  };
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listServiceCodeBundle({
        json: JSON.stringify({
          ServiceCodeBundle: [
            {
              Search: value,
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { columns, tabView, ServiceLineData, StartDate, EndDate } = this.state;
    const { isFetching, ListbundleCode } = this.props;

    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page">
          {tabView ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="border-0">
                    <div className="mb-3">
                      <div className="row mt-5 d-flex align-items-center">
                        <div className="col-12 col-xl-4">
                          {' '}
                          <h4 className="">BUNDLED SERVICE</h4>
                        </div>
                        <div class="col-md-5 col-xl-3">
                          <ReactRangePicker
                            value={[
                              moment(`${this.state.StartDate}`, 'MM-DD-YYYY'),
                              moment(`${this.state.EndDate}`, 'MM-DD-YYYY'),
                            ]}
                            format={'MM-DD-YYYY'}
                            // value={[
                            //   moment(this.state.StartDate, 'MM-DD-YYYY'),
                            //   moment(this.state.EndDate, 'MM-DD-YYYY'),
                            // ]}

                            // defaultValue={[moment(Startdate, dateFormat), moment(Enddate, dateFormat)]}
                            handleChange={this.handleChange}
                          />
                          {/* <ReactRangePicker
                            // value={[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]}
                            // defaultValue={[moment(Startdate, dateFormat), moment(Enddate, dateFormat)]}
                            handleChange={this.handleChange}
                          /> */}
                        </div>
                        <div className="col-12 col-md-7 col-xl-5 d-flex align-items-center justify-content-end">
                          <GridSearch onSearchChange={this.onSearchChange} />
                        </div>
                      </div>
                    </div>
                    <div className="row rcm-subtab-table-block">
                      <div className="col-12">
                        <Loader2 loading={isFetching} />
                        <PersistTableSort
                          columns={columns}
                          name={this.props.name}
                          dataSource={ListbundleCode}
                          size={'middle'}
                          // onRow={(record, rowIndex) => {
                          //   return {
                          //     onClick: event => this.onRowClick(record.ClientId),
                          //   };
                          // }}
                          pagination={{
                            itemRender: (page, type, originalElement) => {
                              return (
                                <div className="dataTables_wrapper no-footer">
                                  <div className="dataTables_paginate paging_simple_numbers">
                                    {type == 'prev' ? (
                                      <a className="paginate_button previous">Previous</a>
                                    ) : type == 'next' ? (
                                      <a className="paginate_button next">Next</a>
                                    ) : (
                                      <a className="paginate_button ">{page}</a>
                                    )}
                                  </div>
                                </div>
                              );
                            },
                            showSizeChanger: true,
                            // hideOnSinglePage: true,
                          }}
                          scroll={{ x: 1500 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid px-0">
              <BundleCodeDetail
                initialValues={ServiceLineData}
                ServiceLineData={ServiceLineData}
                isCreated={this.props.isCreated}
                toggleView={this.toggleView}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  list_service_code_bundle,
  get_group_service_line,
  upsert_service_line,
}) => {
  const { data: bundleCodeData, isFetching } = list_service_code_bundle;
  const { data: getServiceLineData } = get_group_service_line;

  let ListbundleCode = [];
  let ServiceLineData = [];
  let isCreated = false;

  if (bundleCodeData && bundleCodeData.ServiceCodeBundle) {
    ListbundleCode = bundleCodeData.ServiceCodeBundle || [];
  }
  if (getServiceLineData && getServiceLineData.GroupServiceLine) {
    ServiceLineData = getServiceLineData.GroupServiceLine || [];
  }
  if (upsert_service_line && upsert_service_line.data && upsert_service_line.data.success) {
    isCreated = upsert_service_line.data.success;
  }
  return {
    isCreated,
    isFetching,
    ListbundleCode,
    ServiceLineData: ServiceLineData,
  };
};

export default connect(mapStateToProps)(withRouter(BundleService));
