import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

class CustomPhoneInput extends Component {
  render() {
    return <PhoneInput {...this.props} country="US" />;
  }
}

export default CustomPhoneInput;

const ReduxPhoneInput = ({
  input,
  name,
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  meta,
  errorMessage,
  className,
  disabled,
  maxLength,
  mobileFormat,
  tabIndex,
  country,
  fieldName,
}) => {
  var { touched, error, warning, active } = meta || {};
  if (input) {
    defaultValue = input.defaultValue;
    value = input.value;
    onChange = input.onChange;
    onBlur = input.onBlur;
  }
  let countries = [
    ['us', 'au', 'ca', 'ci', 'ro', 'jm', 'cz', 'dk', 'gd', 'in', 'ke', 'nl', 'pl', 'rw'],
  ];

  // if (country && country.length > 0) {
  //   countries = country && country.map(item => item.CountryShortName.toLowerCase());
  // }

  return (
    <div className="custom-phone-input">
      <PhoneInput
        {...input}
        country="us"
        // onlyCountries={countries}
        onlyCountries={[
          'us',
          'au',
          'ca',
          'ci',
          'ro',
          'jm',
          'cz',
          'dk',
          'gd',
          'in',
          'ke',
          'nl',
          'pl',
          'rw',
        ]}
        preferredCountries={['us']}
        countrySelectProps={{ unicodeFlags: true }}
        countryCodeEditable={false}
        tabIndex={tabIndex}
        name={name}
        id={id}
        defaultValue={defaultValue && defaultValue}
        value={value && value}
        onChange={onChange}
        onBlur={onBlur}
        // placeholder={placeholder}
        placeholder={''}
        disabled={!!disabled}
        autocomplete="off"
        // isValid={(value, country) => {
        //   let iso = country.iso2;
        //   let isValid = isValidPhoneNumber(value, iso.toUpperCase());
        //   let isValidLength = validatePhoneNumberLength(value, iso.toUpperCase());
        //   if (isValidLength) {
        //     return isValidLength;
        //   } else if (!isValid) {
        //     return 'Invalid value: ' + value + ', ' + country.name;
        //   } else {
        //     return true;
        //   }
        // }}
      />
      <label class="placeholder-label" style={{ top: '-5px', fontSize: '14px' }}>
        {placeholder}
      </label>
      {touched &&
        ((error && fieldName && (
          <span className="error-text">
            {fieldName} {error}
          </span>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

export { ReduxPhoneInput };
