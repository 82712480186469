import React, { Component } from 'react';

const CookiePolicy=()=> {
 return (
   <React.Fragment>
     <div class="container mt-5">
       <h5 className="text-center" style={{ fontSize: '38px', color: '#46b4fe' }}>
         Cookie Policy
       </h5>
       <p>
         This cookie policy (“Policy”) describes what cookies are and how Sober Peer, LLC (“Sober
         Peer, LLC”, “we”, “us” or “our”) uses them on the{' '}
         <a target="_blank" rel="nofollow" href="https://provider.soberpeer.com/">
           provider.soberpeer.com
         </a>{' '}
         website and any of its products or services (collectively, “Website” or “Services”).
       </p>

       <p>
         You should read this Policy so you can understand what type of cookies we use, the
         information we collect using cookies and how that information is used. It also describes
         the choices available to you regarding accepting or declining the use of cookies. For
         further information on how we use, store, and keep your personal data secure, see our
         Privacy Policy.
       </p>

       <h5>What are Cookies?</h5>
       <p>
         Cookies are small pieces of data stored in text files that are saved on your computer or
         other devices when websites are loaded in a browser. They are widely used to remember you
         and your preferences, either for a single visit (through a “session cookie”) or for
         multiple repeat visits (using a “persistent cookie”).
       </p>

       <ul>
         <li>
           Session cookies are temporary cookies that are used during the course of your visit to
           the Website, and they expire when you close the web browser.
         </li>
         <li>
           Persistent cookies are used to remember your preferences within our Website and remain on
           your desktop or mobile device even after you close your browser or restart your computer.
           They ensure a consistent and efficient experience for you while visiting our Website or
           using our Services.
         </li>
       </ul>

       <p>
         Cookies may be set by the Website (“first-party cookies”), or by third parties, such as
         those who serve content or provide advertising or analytics services on the website (“third
         party cookies”). These third parties can recognize you when you visit our website and also
         when you visit certain other websites.
       </p>

       <h5>What Type of Cookies Do We Use?</h5>
       <ul>
         <li>
           Necessary Cookies. Necessary cookies allow us to offer you the best possible experience
           when accessing and navigating through our Website and using its features. For example,
           these cookies let us recognize that you have created an account and have logged into that
           account to access the content.
         </li>
         <li>
           Functionality Cookies. Functionality cookies let us operate the Website and our Services
           in accordance with the choices you make. For example, we will recognize your username and
           remember how you customized the Website and Services during future visits.
         </li>
       </ul>

       <h5>What are Your Cookie Options?</h5>
       <p>
         If you don’t like the idea of cookies or certain types of cookies, you can change your
         browser’s settings to delete cookies that have already been set and to not accept new
         cookies. To learn more about how to do this or to learn more about cookies, visit
         internetcookies.org
       </p>

       <h5>Changes and Amendments</h5>
       <p>
         We reserve the right to modify this Policy relating to the Website or Services at any time,
         effective upon posting of an updated version of this Policy on the Website. When we do, we
         will revise the updated date at the bottom of this page. Continued use of the Website after
         any such changes shall constitute your consent to such changes.
       </p>

       <h5>Contacting Us</h5>
       <p>
         If you would like to contact us to understand more about this Policy or wish to contact us
         concerning any matter relating to our use of cookies, you may send an email to{' '}
         <a href="mailto:support@soberpeer.com">
           <strong>support@soberpeer.com</strong>
         </a>
         .
       </p>

       <h5>Acceptance of this Policy</h5>
       <p>
         You acknowledge that you have read this Policy and agree to all its terms and conditions.
         By using the Website or its Services you agree to be bound by this Policy. If you do not
         agree to abide by the terms of this Policy, you are not authorized to use or access the
         Website and its Services.
       </p>

       <p>
         This document is incorporated as a part of the Company’s overall policies, terms and
         conditions, and acceptable use standards for any person using the Website and/or Services.
         We reserve our right to be the sole arbiter in interpreting and applying the policy,
         including determining the seriousness of any violation and any corrective actions.
       </p>

       <p className="pb-5">This document was last updated on July 9, 2023.</p>
     </div>
   </React.Fragment>
 );
}



export default CookiePolicy;
