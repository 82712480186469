import React, { Component } from 'react';
import { Table, Drawer, Modal, Collapse } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import { RightCircleOutlined } from '@ant-design/icons';
import PayerProfileHeader from '../../customScreens/clientProfileHeaderInPayer';
import TimelineComponent from '../../timelineComponent';
import { clientBannerGet, smartContractGet } from '../../../store/actions';
import IndividualMilestones from './individualMilestones';
import { Loader2 } from '../../../Components/Loader';
const { Panel } = Collapse;

// let measures = [
//   {
//     Name: '0',
//   },
//   {
//     Name: '1',
//   },
// ];

class ClientMilestoneDetails extends Component {
  constructor(props) {
    super(props);
  }

  getClientId = () => {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  };

  componentDidMount() {
    this.props.dispatch(
      clientBannerGet({
        json: JSON.stringify({
          Client: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );

    let data = { SmartContract: [{ ClientId: this.getClientId(), IsReadOnly: true }] };

    var smartContractData = {
      json: JSON.stringify(data),
      // IsPayerPortal: true,
    };
    this.props.dispatch(smartContractGet(smartContractData));
  }
  render() {
    const { profileBanner, measures, isFetching } = this.props;
    return (
      <div className="payer-audits">
        <div class="container-fluid">
          <div className="row">
            <div className="col-12  top-table-search mb-2 pt-4">
              <div className="col-12">
                <Link to="/payer/milestones" className="back-link">
                  <img src="images/back-arrow.svg" /> Back
                </Link>
                {/* <h3 className="form-main-head mb-3">Audits</h3> */}
              </div>
              <div className="card border-0 students-profile" style={{ padding: '20px' }}>
                {/* <h4>CLIENT DETAILS</h4> */}
                <PayerProfileHeader clientDetails={profileBanner} />
              </div>

              <div className="row">
                <div className="col-12 mb-2 pt-4">
                  <div className="card border-0 students-profile" style={{ padding: '20px' }}>
                    <Loader2 loading={isFetching} />
                    <h4 className="text-uppercase">Treatment contract</h4>
                    <div className="row card-body align-items-center payer-milestones">
                      {measures &&
                        measures.map((item, index) => (
                          <IndividualMilestones measureDetails={item} activeKey={index} />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default ClientAuditDetails;

const mapStateToProps = ({ client_banner_get, smart_contract_get }) => {
  let { data: bannerDetails } = client_banner_get;
  let { data: contractDetails, isFetching } = smart_contract_get;
  let profileBanner = [];
  let measures = [];

  if (bannerDetails && bannerDetails.Banner) {
    profileBanner = bannerDetails.Banner[0];
  }
  if (contractDetails && contractDetails.SmartContract) {
    measures = contractDetails.SmartContract[0] && contractDetails.SmartContract[0].Measure || [];
  }
  return { profileBanner, measures, isFetching };
};

export default connect(mapStateToProps)(withRouter(ClientMilestoneDetails));
