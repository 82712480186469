import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Tabs, Button, Drawer } from 'antd';
import renderHTML from 'react-render-html';
import { isComparer, isDateComparer } from '../../util/handler';
import CustomModal from '../../core/modal';
// import { Loader2 } from '../../Components/Loader';
import AntdModal from '../../Components/CustomModal';
import {
  surveyQuestionConfigGet,
  surveyQuestionConfigUpsert,
  surveyQuestionConfigBannerGet,
  resetSurveyQuestionConfigUpsert,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import ConfigutationTab from './tabs/ConfigutationTab';
import ManageCategoryDrawer from './ManageCategoryDrawer';
import SubmitButton from '../../Components/submitButton';
import PageTitle from '../../Components/PageTitle';

const { TabPane } = Tabs;

class QuestionLibraryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabMapping: {
        0: 'legal',
        1: 'physical',
        2: 'medical',
        3: 'dnr',
        4: 'financial',
      },
      activeKey: 0,
      isModelOpen: false,
      questionsAdded: 0,
      tabNames: [],
      isMCDrawerOpen: false,
      surveyQuestionCategoryConfigId: null,
      inActiveTabs: null,
    };
  }
  getQId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var question = param.get('qId');
    if (question) {
      question = parseInt(question);
    } else {
      question = null;
    }
    return question;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { tabMapping } = this.state;
    let params = new URLSearchParams(this.props.location.search);
    let tab = params.get('tab') || tabMapping[0];
    let SurveyQuestionCategoryConfigId = params.get('qid');
    this.setState({ surveyQuestionCategoryConfigId: SurveyQuestionCategoryConfigId });
    if (tabMapping) {
      this.setState({
        activeKey: Object.values(tabMapping).indexOf(tab),
      });
    }
    dispatch(
      surveyQuestionConfigGet({
        json: JSON.stringify({
          Survey: [
            {
              SurveyQuestionCategoryConfigId: SurveyQuestionCategoryConfigId,
            },
          ],
        }),
      }),
    );
    dispatch(
      surveyQuestionConfigBannerGet({
        json: JSON.stringify({
          Survey: [
            {
              SurveyQuestionCategoryConfigId: SurveyQuestionCategoryConfigId,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ surveyQuestion, isAnswerUpsert, isModelOpen, bannerDetails }) {
    if (bannerDetails) {
      this.setState({ questionsAdded: bannerDetails.NoOfQuestion });
    }
    if (
      surveyQuestion &&
      JSON.stringify(surveyQuestion) != JSON.stringify(this.props.surveyQuestion)
    ) {
      let array = surveyQuestion;
      let activeTabs = [];
      let inactiveTabs = [];
      let TabNames = [
        {
          label: 'Select All',
          children: [],
        },
      ];
      {
        array &&
          array.SurveyQuestion &&
          array.SurveyQuestion.map((item, index) => {
            const {
              Tab,
              Sequence,
              Questions,
              SurveyQuestionSectionId,
              SurveyQuestionCategoryConfigId,
              IsArchived,
            } = item;
            TabNames[0].children.push({
              label: Tab,
              selected: !IsArchived,
              value: index + 1,
              SurveyQuestionSectionId: SurveyQuestionSectionId,
              SurveyQuestionCategoryConfigId: SurveyQuestionCategoryConfigId,
            });
            if (!IsArchived) {
              activeTabs.push(index);
            } else {
              inactiveTabs.push(index);
            }
            let arrayOne = [];
            let arrayTwo = [];
            Questions &&
              Questions.map(item => {
                if (item.IsArchived) {
                  arrayOne.push(item);
                } else {
                  arrayTwo.push(item);
                }
              });
            array.SurveyQuestion[index].Draggable = [...arrayTwo];
            array.SurveyQuestion[index].UnDraggable = [...arrayOne];
          });
      }
      this.setState({
        SurveyQuestionList: array,
        tabNames: TabNames,
        activeKey: activeTabs[0] || 0,
        inActiveTabs: inactiveTabs.length,
      });
    }
    // TabNames[0].children = [];

    if (isModelOpen) {
      this.setState({ isModelOpen });
    }
  }
  onTabClick = (key, abc) => {
    let keyData = parseInt(key);
    let clickedTab = this.state.tabMapping[keyData];
    this.setState({
      activeKey: parseInt(key),
    });
    window.scrollTo(0, 0);
  };
  setValue = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    TabName,
    Sequence,
    OIndex,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    // let currentTab = SurveyQuestionList.SurveyQuestion.filter(tabItem => tabItem.Tab === TabName);
    let OptKeyName = `Option${OIndex}QF`;
    SurveyQuestionList.SurveyQuestion[activeKey].Questions[index].IsArchived = response;

    this.setState({ SurveyQuestionList });
  };

  setToggle = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    TabName,
    Sequence,
    OIndex,
    OptKeyName,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    if (OptKeyName == 'IsMandatory') {
      SurveyQuestionList.SurveyQuestion[activeKey].Draggable[index].IsMandatory = response;
    } else if (OptKeyName == 'AddNote') {
      SurveyQuestionList.SurveyQuestion[activeKey].Draggable[index].AddNote = response;
    }

    this.setState({ SurveyQuestionList });
  };

  onSort = questionList => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    SurveyQuestionList.SurveyQuestion[activeKey].Draggable = questionList[0];
    this.setState({ SurveyQuestionList });
  };

  onMoveTo = (tabValue, surveyQuestions) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    let questionIndex;
    const { tabMapping, activeKey, tabLabels } = this.state;
    let newArray = [];
    SurveyQuestionList.SurveyQuestion[activeKey].Draggable.map((item, index) => {
      if (item.SurveyQuestionId == surveyQuestions.SurveyQuestionId) {
        questionIndex = index;
        return SurveyQuestionList.SurveyQuestion[activeKey].Draggable.splice(index, item);
      } else {
        return newArray.push(item);
      }
    });

    SurveyQuestionList.SurveyQuestion.map((item, index) => {
      if (item.Tab == tabValue) {
        if (SurveyQuestionList.SurveyQuestion[index].Draggable == null) {
          SurveyQuestionList.SurveyQuestion[index].Draggable = [];
          SurveyQuestionList.SurveyQuestion[index].Draggable.push(
            SurveyQuestionList.SurveyQuestion[activeKey].Draggable[questionIndex],
          );
        } else {
          SurveyQuestionList.SurveyQuestion[index].Draggable.push(
            SurveyQuestionList.SurveyQuestion[activeKey].Draggable[questionIndex],
          );
        }
      }
    });
    SurveyQuestionList.SurveyQuestion[activeKey].Draggable = newArray;
    this.setState({ SurveyQuestionList });
  };

  onNext = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    TabName,
    Sequence,
    OptKeyName,
    SurveyQuestionListItems,
    survey,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    SurveyQuestionList.SurveyQuestion[activeKey].Draggable = SurveyQuestionListItems[0];
    SurveyQuestionList.SurveyQuestion[activeKey].Draggable[index][OptKeyName] = response;

    this.setState({ SurveyQuestionList });
  };
  onQuestionStateChange = ({
    surveyQuestionId,
    response,
    level,
    subLevel,
    subCatLevel,
    index,
    TabName,
    Sequence,
    OptKeyName,
    SurveyQuestionListItems,
    survey,
  }) => {
    let SurveyQuestionList = this.state.SurveyQuestionList;
    const { tabMapping, activeKey, tabLabels } = this.state;
    if (response) {
      let length = SurveyQuestionList.SurveyQuestion[activeKey].UnDraggable.length;
      this.setState(prevstate => ({ questionsAdded: prevstate.questionsAdded - 1 }));
      survey[OptKeyName] = response;
      SurveyQuestionList.SurveyQuestion[activeKey].UnDraggable.push(survey);
      SurveyQuestionList.SurveyQuestion[activeKey].UnDraggable[length][OptKeyName] = response;
      SurveyQuestionList.SurveyQuestion[activeKey].Draggable.splice(index, 1);
    } else if (!response) {
      this.setState(prevstate => ({ questionsAdded: prevstate.questionsAdded + 1 }));
      let length = SurveyQuestionList.SurveyQuestion[activeKey].Draggable.length;
      survey[OptKeyName] = response;
      SurveyQuestionList.SurveyQuestion[activeKey].Draggable.push(survey);
      SurveyQuestionList.SurveyQuestion[activeKey].Draggable[length][OptKeyName] = response;
      SurveyQuestionList.SurveyQuestion[activeKey].UnDraggable.splice(index, 1);
    }

    this.setState({ SurveyQuestionList });
  };

  onSave = () => {
    const { SurveyQuestionList } = this.state;
    let surveyArray = [];
    SurveyQuestionList.SurveyQuestion.map((item, index) => {
      const { Tab, Sequence, Questions, UnDraggable, Draggable } = item;
      surveyArray.push({
        Tab: Tab,
        Sequence: Sequence,
        Questions: [...Draggable, ...UnDraggable],
      });
    });
    this.props.dispatch(
      surveyQuestionConfigUpsert({
        json: JSON.stringify({
          SurveyQuestion: [...surveyArray],
        }),
      }),
    );
  };
  render() {
    const {
      activeKey,
      studentData,
      tabLabels,
      activeTabs,
      SurveyQuestionList,
      questionsAdded,
    } = this.state;
    const { Title, NoOfCategories, NoOfQuestion } = this.props.bannerDetails;
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Library" />
        <div class="content-area">
          <Loader2 loading={this.props.isFetchingSurveyQuestion} />
          <div class="row top-table-search mt-2 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              {/* <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage/question-library')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a> */}
              <AntdModal
                ModalButton={({ onClick }) => (
                  <a class="back-link" style={{ cursor: 'pointer' }} onClick={onClick}>
                    <img src="images/back-arrow.svg" /> Back
                  </a>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div className="modal-content border-0">
                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                      <h4 className="modal-title col-12">
                        Are you sure you want to exit this page? <br />
                        Please click "Cancel" and Save your changes.
                      </h4>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary py-1"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn py-1"
                        onClick={() => {
                          onOk();
                          this.props.history.push('/manage/question-library');
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
              />
              <h3 class="page-title mb-3 mb-md-0">{Title}</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div className="d-flex" style={{ backgroundColor: 'white', padding: '10px' }}>
                <p className="mb-0">
                  Total Categories Added: <strong>{NoOfCategories}</strong>
                </p>
                <p className="mb-0 ml-5">
                  Total Questions Added: <strong>{questionsAdded}</strong>
                </p>
              </div>
              {/* <button className="btn btn-outline-primary">Cancel</button> */}
              <button
                onClick={() => {
                  this.setState({ isMCDrawerOpen: true });
                }}
                className="btn btn-outline-primary"
              >
                Manage Category
              </button>
              <AntdModal
                ModalButton={({ onClick }) => (
                  <button
                    style={{ marginLeft: '15px' }}
                    onClick={onClick}
                    className="btn btn-outline-primary"
                  >
                    Cancel
                  </button>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div className="modal-content border-0">
                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                      {/* <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        &times;
                      </button> */}
                      <h4 className="modal-title col-12">
                        Are you sure you want to exit this page? <br />
                        Please click "Cancel" and Save your changes.
                      </h4>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary py-1"
                        data-dismiss="modal"
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn py-1"
                        onClick={() => {
                          onOk();
                          this.props.history.push('/manage/question-library');
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                )}
              />
              <SubmitButton
                loading={this.props.isUpsertFetching}
                style={{ height: '46px', marginLeft: '15px' }}
                onClick={() => {
                  this.onSave();
                }}
                value={'Save'}
                disabled={
                  SurveyQuestionList &&
                  SurveyQuestionList.SurveyQuestion.length != this.state.inActiveTabs
                    ? false
                    : true
                }
              />
            </div>
          </div>
          <CustomModal
            isOpen={this.state.isModelOpen}
            header={'Your changes have been saved successfully'}
            yesAction={() => {
              this.setState({ isModelOpen: false });
              this.props.history.push('/manage/question-library');
              this.props.dispatch(resetSurveyQuestionConfigUpsert({}));
            }}
            yesText="OK"
            hideCancel={true}
            changeMaxWidth="100%"
          />
          <Drawer
            className="custom-drawer print-drawer"
            title="Manage Category"
            visible={this.state.isMCDrawerOpen}
            onClose={() => {
              this.setState({
                isMCDrawerOpen: !this.state.isMCDrawerOpen,
              });
            }}
            width={700}
            bodyStyle={{ paddingBottom: 80 }}
            destroyOnClose={true}
            placement="right"
            // className="print-drawer"
          >
            <ManageCategoryDrawer
              onCancel={() => {
                this.setState({ isMCDrawerOpen: false });
                this.props.dispatch(
                  surveyQuestionConfigGet({
                    json: JSON.stringify({
                      Survey: [
                        {
                          SurveyQuestionCategoryConfigId: this.state.surveyQuestionCategoryConfigId,
                        },
                      ],
                    }),
                  }),
                );
                this.props.dispatch(
                  surveyQuestionConfigBannerGet({
                    json: JSON.stringify({
                      Survey: [
                        {
                          SurveyQuestionCategoryConfigId: this.state.surveyQuestionCategoryConfigId,
                        },
                      ],
                    }),
                  }),
                );
              }}
              // ={printClientChart}
              // getClientId={this.getClientId()}

              filterList={this.state.tabNames ? this.state.tabNames[0] : []}
              // index={index}
              // getCheckItems={this.getCheckItems}
              // defaultValue={printClientChart}
              // onCancel={onCancel}
              // getClientId={getClientId}
            />
          </Drawer>
          <div className="row">
            <div class="col-12 mb-2 vertical-tabs">
              {SurveyQuestionList &&
              SurveyQuestionList.SurveyQuestion.length != this.state.inActiveTabs ? (
                <Tabs
                  activeKey={String(activeKey)}
                  tabPosition="left"
                  defaultActiveKey="0"
                  onTabClick={this.onTabClick}
                  // type="editable-card"
                >
                  {SurveyQuestionList.SurveyQuestion &&
                    SurveyQuestionList.SurveyQuestion.map((item, index) => {
                      const { Tab, Sequence, Questions, IsArchived } = item;
                      let Component = () => (
                        <ConfigutationTab
                          {...this.props}
                          SurveyItem={item}
                          dispatch={this.props.dispatch}
                          SurveyQuestion={Questions}
                          onNext={this.onNext}
                          onQuestionStateChange={this.onQuestionStateChange}
                          setValue={this.setValue}
                          setToggle={this.setToggle}
                          scope={this}
                          Sequence={Sequence}
                          TabName={Tab}
                          onSort={this.onSort}
                          onMoveTo={this.onMoveTo}
                          activeKey={activeKey}
                          SurveyQuestionList={SurveyQuestionList}
                          UnDraggable={item.UnDraggable}
                          Draggable={item.Draggable}
                        />
                      );
                      let isTab = Tab && Tab.length;
                      if (!IsArchived) {
                        return (
                          <TabPane
                            tab={isTab && renderHTML(Tab)}
                            key={`${index}`}
                            className="tab-content"
                          >
                            {activeKey == index && (
                              <Component style={{ padding: '36px 20px 150px 20px' }} />
                            )}
                          </TabPane>
                        );
                      }
                    })}
                </Tabs>
              ) : (
                <div
                  className="d-flex justify-content-center flex-column align-items-center"
                  style={{
                    height: '500px',
                    backgroundColor: 'white',
                  }}
                >
                  {/* <img src="images/category.svg" /> */}
                  <h4 className="mt-4">
                    Please select at least one category from the list provided under ‘Manage
                    Category’
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    survey_question_config_get,
    survey_answer_upsert,
    survey_question_config_upsert,
    survey_question_config_banner_get,
  } = state;
  const {
    data: surveyQuestionData,
    isFetching: isFetchingSurveyQuestion,
  } = survey_question_config_get;
  const { data: configUpsert, isFetching: isUpsertFetching } = survey_question_config_upsert;
  const { data: upsertData } = survey_answer_upsert;
  const { data: bannerData } = survey_question_config_banner_get;
  let surveyQuestion;
  let augmentedAnswer;
  let isAnswerUpsert = false;
  let isModelOpen = false;
  let bannerDetails = [];

  if (surveyQuestionData) {
    surveyQuestion = surveyQuestionData;
  }
  if (configUpsert && configUpsert.success) {
    isModelOpen = true;
  }
  if (upsertData && upsertData.data && upsertData.data.success) {
    isAnswerUpsert = true;
  }

  if (bannerData && bannerData.Survey) {
    bannerDetails = bannerData.Survey[0];
  }

  return {
    surveyQuestion,
    isFetchingSurveyQuestion: isFetchingSurveyQuestion,
    augmentedAnswer,
    isAnswerUpsert,
    isModelOpen,
    bannerDetails,
    isUpsertFetching,
  };
};

export default connect(mapStateToProps)(withRouter(QuestionLibraryList));
