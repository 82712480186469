import React from 'react';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  CheckboxGroup,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import {
  getBillableClaims,
  practitionerCombos,
  pushSingleClaimToCH,
  resetPushSingleClaimToCH,
  pushClaimsToCH,
} from '../../../../store/actions';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import moment from 'moment';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { Table, Modal, Button, Tooltip } from 'antd';
import PersistTableSort from '../../../../component/persistTableSort';
import ReactRangePicker from '../../../../component/Range-Picker';

class BillableClaimsCH extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelOpen: false,
      header: '',
      StartDate: moment(new Date())
        .subtract(30, 'd')
        .format('MM-DD-YYYY'),
      EndDate: moment(new Date()).format('MM-DD-YYYY'),
    };
  }

  setStoredFilter = () => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);

    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      if (JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter) {
        let sDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.startDateRange;
        let eDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.endDateRange;
        this.setState({
          StartDate: sDate,
          EndDate: eDate,
        });
        if (!JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter) {
          this.props.dispatch(
            getBillableClaims({
              json: JSON.stringify({
                CenterProviderClaim: [
                  {
                    PractitionerId: this.state.practitionerId,
                    StartDate: sDate,
                    EndDate: eDate,
                    SearchStr: '',
                  },
                ],
              }),
            }),
          );
          return;
        } else {
          let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
          this.setState({
            filterBy,
          });
          this.props.dispatch(
            getBillableClaims({
              json: JSON.stringify({
                CenterProviderClaim: [
                  {
                    PractitionerId: this.state.practitionerId,
                    StartDate: sDate,
                    EndDate: eDate,
                  },
                ],
              }),
            }),
          );
        }
      } else if (JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter) {
        let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
        this.setState({
          filterBy,
        });
        this.props.dispatch(
          getBillableClaims({
            json: JSON.stringify({
              CenterProviderClaim: [
                {
                  PractitionerId: this.state.practitionerId,
                  StartDate: this.state.StartDate,
                  EndDate: this.state.EndDate,
                },
              ],
            }),
          }),
        );
      }
    } else {
      this.props.dispatch(
        getBillableClaims({
          json: JSON.stringify({
            CenterProviderClaim: [
              {
                PractitionerId: this.state.practitionerId,
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate,
              },
            ],
          }),
        }),
      );
    }
  };
  componentDidMount() {
    this.props.dispatch(practitionerCombos({}));
    this.setStoredFilter();
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ isPushedToCH, resultMsg, isPushedToCHSingle }) {
    if (resultMsg && JSON.stringify(resultMsg) != JSON.stringify(this.props.resultMsg)) {
      this.toggle(resultMsg);
      // this.props.dispatch(reset('ClientserviceLineForm'));
    }
    if (isPushedToCHSingle && isPushedToCHSingle != this.props.isPushedToCHSingle) {
      this.toggle(resultMsg);
      this.props.dispatch(resetPushSingleClaimToCH());
      this.setStoredFilter();
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };

  storeRangeFilter = date => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && !JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        rangeFilter: { startDateRange: `${date[0]}`, endDateRange: `${date[1]}` },
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };

  storeSelectFilter = val => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        selectFilter: val,
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };

  handleChange = (key, date) => {
    this.storeRangeFilter(date);

    this.props.dispatch(
      getBillableClaims({
        Json: JSON.stringify({
          CenterProviderClaim: [
            {
              PractitionerId: this.state.practitionerId,
              Search: this.state.searchStr,
              StartDate: date[0],
              EndDate: date[1],
            },
          ],
        }),
      }),
    );
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });
  };
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      getBillableClaims({
        json: JSON.stringify({
          CenterProviderClaim: [
            {
              Search: value,
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
              PractitionerId: this.state.practitionerId,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const tempColumns = [
      {
        title: 'Patient Id',
        // dataIndex: 'ClientId',
        dataIndex: 'DisplayClientId',
        key: 'ClientId',
        // sorter: (a, b) => isComparer(a.ClientId, b.ClientId),
        sorter: (a, b) => isComparer(a.DisplayClientId, b.DisplayClientId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'CH PatientId',
        dataIndex: 'CHPatientId',
        key: 'CHPatientId',
        sorter: (a, b) => isComparer(a.CHPatientId, b.CHPatientId),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Insurance Name',
        dataIndex: 'InsuranceName',
        key: 'InsuranceName',
        sorter: (a, b) => isComparer(a.InsuranceName, b.InsuranceName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Provider Name',
        dataIndex: 'ProviderName',
        key: 'ProviderName',
        sorter: (a, b) => isComparer(a.ProviderName, b.ProviderName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Appt Date Time',
        dataIndex: 'ApptDateTime',
        key: 'ApptDateTime',
        sorter: (a, b) => isComparer(a.ApptDateTimeForSort, b.ApptDateTimeForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Service Code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Units',
        dataIndex: 'Units',
        key: 'Units',
        sorter: (a, b) => isComparer(a.Units, b.Units),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: (
          <span>
            Cost Per <br />
            Unit
          </span>
        ),
        dataIndex: 'CostPerUnit',
        key: 'CostPerUnit',
        sorter: (a, b) => isComparer(a.CostPerUnit, b.CostPerUnit),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Total Cost',
        dataIndex: 'TotalCost',
        key: 'TotalCost',
        sorter: (a, b) => isComparer(a.TotalCost, b.TotalCost),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status in CH',
        dataIndex: 'CHStatus',
        key: 'CHStatus',
        sorter: (a, b) => isComparer(a.CHStatus, b.CHStatus),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <div class="tools-grid">
                {record.IsPostActionAllowed ? (
                  <div className="" style={{ width: '120px' }}>
                    <span
                      className="start-btn"
                      style={{ cursor: 'pointer' }}
                      onClick={event => {
                        this.props.dispatch(
                          pushSingleClaimToCH({
                            json: JSON.stringify({
                              Claim: [
                                {
                                  ClientId: record.ClientId,
                                  ServiceLineId: record.ServiceLineId,
                                },
                              ],
                            }),
                          }),
                        );
                      }}
                    >
                      Push Claim to CH
                    </span>
                  </div>
                ) : (
                  record.ActionMsg
                )}
              </div>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const { ServiceList, header } = this.state;
    const {
      ClaimsList,
      Practitioner,
      isFetchingPushClaims,
      isReadOnlyUser,
      isFetchingSinglePushClaims,
    } = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid my-5">
          <div className="mb-3">
            <div className="row mt-3 d-flex align-items-center">
              <div className="col-7">
                <h4 className="">Claims</h4>
              </div>
              <div className="col-md-5 col-xl-5 d-flex align-items-center mb-3">
                {/* <div className="search-box d-inline-flex w-100">
                  <div className="form-group has-search w-100 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control 
                   shadow-sm rcm-document-search"
                      placeholder="Search"
                      id="filterbox"
                      onChange={this.onSearchChange}
                      style={{ minHeight: '46px' }}
                    />
                    <button class="btn search-icon " type="button">
                      {' '}
                      <i class="fa fa-search" />{' '}
                    </button>
                  </div>
                </div> */}
              </div>

              <div class="col-md-6 col-xl-6">
                {!isReadOnlyUser && (
                  <Button
                    type="primary"
                    loading={isFetchingPushClaims}
                    // disabled={!this.state.practitionerId}
                    className="btn btn-outline-primary mr-2 py-2"
                    style={{ height: '45px' }}
                    // style={{
                    //   fontWeight: 'bold',
                    //   borderRadius: '26px',
                    //   height: '42px',
                    //   border: 0,
                    //   color: '#fff',
                    //   padding: '10px 20px',
                    //   lineHeight: '24px',
                    //   background: 'linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%)',
                    //   backgroundSize: '300% 100%',
                    //   transition: 'all 300ms ease-in-out',
                    // }}
                    onClick={event => {
                      this.props.dispatch(
                        pushClaimsToCH({
                          json: JSON.stringify({
                            CenterProviderClaim: [
                              {
                                PractitionerId: this.state.practitionerId,
                                StartDate: this.state.StartDate,
                                EndDate: this.state.EndDate,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                  >
                    {isFetchingPushClaims
                      ? 'Processing...'
                      : 'Push unsent claims to clearing house'}
                  </Button>
                )}
              </div>
              <div className="col-md-3 col-xl-3">
                <div class="form-group call-schedule service-filter">
                  <ReduxSelect
                    options={Practitioner}
                    onChange={val => {
                      this.storeSelectFilter(val);
                      this.props.dispatch(
                        getBillableClaims({
                          Json: JSON.stringify({
                            CenterProviderClaim: [
                              {
                                Search: this.state.searchStr,
                                PractitionerId: val,
                                StartDate: this.state.StartDate,
                                EndDate: this.state.EndDate,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        practitionerId: val,
                      });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={this.state.practitionerId}
                    placeholder="Provider"
                  />
                </div>
              </div>
              <div class="col-md-3 col-xl-3">
                <ReactRangePicker
                  value={[
                    moment(`${this.state.StartDate}`, 'MM-DD-YYYY'),
                    moment(`${this.state.EndDate}`, 'MM-DD-YYYY'),
                  ]}
                  format={'MM-DD-YYYY'}
                  handleChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name={this.props.name}
                    columns={tempColumns}
                    dataSource={ClaimsList}
                    size={'middle'}
                    loader={isFetchingSinglePushClaims}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    push_claims_to_CH,
    push_single_claim_to_CH,
    combos,
    get_billable_to_claims,
    practitioner_combos,
  } = state;
  const { data, isFetching } = get_billable_to_claims;
  const { data: pushClaimsData, isFetching: isFetchingPushClaims } = push_claims_to_CH;
  const {
    data: pushSingleClaimsData,
    isFetching: isFetchingSinglePushClaims,
  } = push_single_claim_to_CH;
  const { data: practitionerData } = practitioner_combos;

  let Practitioner = [];
  let ClaimsList = [];
  let isPushedToCH = false;
  let isPushedToCHSingle = false;
  let resultMsg = null;
  if (data && data.Claims) {
    ClaimsList = data.Claims;
  }
  if (practitionerData && practitionerData.PractitionerList) {
    Practitioner = practitionerData.PractitionerList || [];
  }

  if (pushClaimsData && pushClaimsData.success) {
    isPushedToCH = pushClaimsData.success;
    resultMsg = pushClaimsData.result;
  } else if (pushClaimsData && pushClaimsData.result) {
    resultMsg = pushClaimsData.result;
  }

  if (pushSingleClaimsData && pushSingleClaimsData.success) {
    isPushedToCHSingle = pushSingleClaimsData.success;
    resultMsg = pushSingleClaimsData.result;
  }

  return {
    ClaimsList,
    isPushedToCH,
    isPushedToCHSingle,
    isFetching,
    Practitioner,
    isFetchingPushClaims,
    isFetchingSinglePushClaims,
    resultMsg,
  };
};
export default connect(mapStateToProps)(BillableClaimsCH);
