import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import checkToken from './checkToken';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(history, initialState) {
  const middleware = [thunk, sagaMiddleware, checkToken()];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user_feature_flag_get'],
  };

  const appReducer = (state, action) => {
    if(action.type === 'LOGIN_REQUEST') {
      return rootReducer(undefined, action)
    } 
    return rootReducer(state, action)
  }

  const persistedReducer = persistReducer(persistConfig, appReducer);
  
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );

  let persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
