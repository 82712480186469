import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getJudicialUserList, listCombos } from '../store/actions';
import { Table, Checkbox, Radio, Modal } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { ReduxSelect } from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import CaseDetailModal from './PopupModals/CaseDetails';
import LocationHistory from './PopupModals/LocationHistory';
import ReduxBroadcaseInvitationModal from './customScreens/broadcastInvitation';

const CheckboxGroup = Checkbox.Group;

const options = [
  { Value: null, Text: 'Please Select' },
  { Value: 'Enrolled', Text: 'All' },
  { Value: 'Graduated', Text: 'Graduated' },
  { Value: 'Discharged', Text: 'Discharged' },
  { Value: 'Active', Text: 'Active' },
];

const plainOptions = ['Graduated', 'Discharged', 'Active'];

class FilterModal extends Component {
  constructor(props) {
    super(props);
    const { checked, startDate, endDate, filterCenterId } = props;
    this.state = {
      checked: checked || 'Enrolled',
      startDate: startDate || null,
      endDate: endDate || null,
      filterCenterId: filterCenterId || null,
    };
  }

  onChange = e => this.setState({ checked: e.target.value });

  handleChange = (key, date) => {
    this.setState({
      [key]: date,
    });
  };

  handleCenterChange = filterCenterId => {
    this.setState({ filterCenterId });
  };

  resetState = () => this.setState({ checked: '', startDate: '', endDate: '', filterCenterId: '' });

  render() {
    const { onOk, onCancel, Centers, refreshGrid } = this.props;
    const { startDate, endDate, filterCenterId, checked } = this.state;
    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
      </>
    );
    const ExampleCustomInput2 = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">End date</label>
      </>
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Filter by</h4>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold d-flex ">Status</span>
            </div>
            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mr-2 mb-2">
                  <Radio.Group name="radiogroup" onChange={this.onChange} value={checked}>
                    <Radio value="Enrolled">All</Radio>
                    <Radio value="Graduated">Graduated</Radio>
                    <Radio value="Discharged">Discharged</Radio>
                    <Radio value="Active">Active</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold ">Date</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => this.handleChange('startDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <div style={{ marginTop: '-12px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => this.handleChange('endDate', date)}
                    className="form-control datepicker"
                    customInput={<ExampleCustomInput2 />}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
            </div>
            <div class="col-12 text-left mb-3">
              <span class="font-weight-bold "> Centers</span>
            </div>
            <div class="col-12 col-md-6 enrollment-form">
              <div class="form-group">
                <ReduxSelect
                  options={Centers}
                  onChange={this.handleCenterChange}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterCenterId}
                  placeholder="Centers"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              // onCancel();
              this.resetState();
            }}
          >
            Reset
          </a>
          <button
            type="button"
            class="btn"
            onClick={() => {
              onOk();
              refreshGrid(checked, startDate, endDate, filterCenterId);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

class AppSubscribers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
      filterType: this.getFilterType(),
      subscribers: [],
      startDate: null,
      endDate: null,
      filterCenterId: null,
      editCaseModal: false,
      locationModal: false,
    };
  }
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getFilterType = () => {
    const { history } = this.props;
    var param = new URLSearchParams(history.location.search);
    return param.get('Filter') || param.get('filter');
  };

  componentDidMount({ searchStr, startDate, endDate, filterCenterId } = this.state) {
    const { dispatch } = this.props;
    this.state.filterType = this.getFilterType();
    dispatch(getJudicialUserList({}));

    this.props.dispatch(
      listCombos({
        comboTypes: 'Center',
      }),
    );

    // dispatch(
    //   getJudicialUserList({
    //     Filter: this.state.filterType,
    //     searchStr: searchStr,
    //     startDate,
    //     endDate,
    //     filterCenterId,
    //   }),
    // );
  }

  componentWillReceiveProps({
    history: {
      location: { search },
    },
    subscribers,
    isSuccess,
  }) {
    if (subscribers && subscribers != this.props.subscribers) {
      this.setState({ subscribers });
    }
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.props.dispatch(getJudicialUserList({}));
    }
  }

  refreshGrid = (filterType, startDate, endDate, filterCenterId) => {
    const { searchStr } = this.state;
    this.setState({ checked: filterType, startDate, endDate, filterCenterId });
    this.props.dispatch(
      getJudicialUserList({
        Filter: filterType ? filterType.toString() : null,
        SearchStr: searchStr,
        startDate,
        endDate,
        filterCenterId,
      }),
    );
  };

  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    this.refreshGrid();
  };

  onRowClick(UserGuid) {
    const { history } = this.props;
    history.push('/subscriberProfile/' + UserGuid);
  }

  startDateChange = date => {
    this.state.startDate = date;
    this.refreshGrid();
  };

  endDateChange = date => {
    this.state.endDate = date;
    this.refreshGrid();
  };

  handleFilterChange = filterType => {
    this.state.filterType = filterType;
    this.refreshGrid();
    this.props.history.push(`/clients?Filter=${filterType}`);
  };

  handleCenterChange = filterCenterId => {
    this.state.filterCenterId = filterCenterId;
    this.refreshGrid();
  };

  render() {
    const { isFetching, Centers } = this.props;
    const {
      subscribers,
      checked,
      startDate,
      endDate,
      filterCenterId,
      editCaseModal,
      locationModal,
    } = this.state;
    const FilterModalData = { checked, startDate, endDate, filterCenterId };
    const columns = [
      {
        title: 'Case Id',
        dataIndex: 'ExternalId',
        key: 'ExternalId',
        sorter: (a, b) => isComparer(a.Name, b.Name),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <span
              style={{ color: '#4AB0FE', fontSize: '14px', cursor: 'pointer' }}
              className="mr-2"
              data-toggle="modal"
              data-target="#archive-model"
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  editCaseModal: true,
                  Name: record.Name,
                  UserGuid: record.UserGuid,
                });
              }}
            >
              {record.ExternalId ? record.ExternalId : record.Name}
            </span>
          );
        },
      },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        sorter: (a, b) => isComparer(a.Name, b.Name),
        sortDirections: ['descend', 'ascend'],
        render: (text, student) => (
          <>
            {/* <div class="student-list-pic">
          <img src={student.StudentImageUrl} alt="student" />
        </div> */}
            <div class="student-list-name">
              <b>{student.Name} </b>
            </div>
          </>
        ),
      },
      {
        title: 'Program',
        dataIndex: 'Program',
        key: 'program',
        sorter: (a, b) => isComparer(a.Program, b.Program),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Probation Officer',
        dataIndex: 'ProbationOfficer',
        key: 'ProbationOfficer',
        sorter: (a, b) => isComparer(a.ProbationOfficer, b.ProbationOfficer),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Enrollment Date',
        dataIndex: 'AppJoiningDate',
        key: 'AppJoiningDate',
        sorter: (a, b) => isDateComparer(a.AppJoiningDateForSort, b.AppJoiningDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'SPB Rating',
        dataIndex: 'SPBRating',
        key: 'SPBRating',
        sorter: (a, b) => isNumericComparer(a.SPBRating, b.SPBRating),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Location Services',
        dataIndex: 'LocationServices',
        key: 'LocationServices',
        sorter: (a, b) => isNumericComparer(a.LocationServices, b.LocationServices),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <span
              style={{ color: '#4AB0FE', fontSize: '14px', cursor: 'pointer' }}
              className="mr-2"
              data-toggle="modal"
              data-target="#archive-model"
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  locationModal: true,
                  Name: record.Name,
                  UserGuid: record.UserGuid,
                  LocationServices: record.LocationServices,
                });
              }}
            >
              {text}
            </span>
          );
        },
      },
      {
        title: 'Surveys Up-to-Date',
        dataIndex: 'SurveysCompleted',
        key: 'SurveysCompleted',
        sorter: (a, b) =>
          isNumericComparer(a.SurveysCompleted.split('/')[0], b.SurveysCompleted.split('/')[0]),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Trigger Warning',
        dataIndex: 'TriggerWarnings',
        key: 'TriggerWarnings',
        sorter: (a, b) => isNumericComparer(a.TriggerWarnings, b.TriggerWarnings),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        sorter: (a, b) => isNumericComparer(a.Status, b.Status),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Completion Date',
        dataIndex: 'CompletionDate',
        key: 'CompletionDate',
        sorter: (a, b) => isDateComparer(a.CompletionDateForSort, b.CompletionDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Time Travel',
        key: 'x',
        width: 90,
        render: (text, record) => {
          return (
            <div
              class="tools-grid"
              onClick={event => {
                this.props.history.push(`/timetravel/?subscriberId=${record.UserGuid}`);
                event.stopPropagation();
              }}
              style={{ cursor: 'pointer', width: '40px', height: '40px' }}
            >
              <img src={`images/timeline/timeline.svg`} alt="Time travel" width="100px" />
            </div>
          );
        },
      },
      {
        title: 'App Last Used Date',
        dataIndex: 'AppLastUsedDate',
        key: 'AppLastUsedDate',
        sorter: (a, b) => isDateComparer(a.AppLastUsedDateSort, b.AppLastUsedDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Composite Score',
      //   dataIndex: 'CompositeScore',
      //   key: 'CompositeScore',
      //   sorter: (a, b) => isNumericComparer(a.CompositeScore, b.CompositeScore),
      //   sortDirections: ['descend', 'ascend'],
      // },
    ];
    return (
      <div class="container-fluid">
        {/* <Loader loading={isFetching} /> */}
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Subscribers</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-9  d-flex justify-content-end app-subscriber-page">
              <a
                onClick={() => {
                  this.setState({
                    reduxBroadcaseInvitationModal: true,
                  });
                }}
                className="d-flex align-items-center"
              >
                <span style={{ color: '#46b4fe', marginRight: '15px', fontWeight: '600' }}>
                  Broadcast Message
                </span>
              </a>
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control 
                   shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>

              <CustomModal
                ModalButton={({ onClick }) => (
                  <input
                    type="button"
                    class="btn black-btn d-none"
                    value="Filter"
                    onClick={onClick}
                  />
                )}
                ModalContent={props => (
                  <FilterModal
                    {...props}
                    {...FilterModalData}
                    refreshGrid={this.refreshGrid}
                    Centers={Centers}
                  />
                )}
              />
              <Modal
                visible={editCaseModal}
                footer={null}
                closable={false}
                width={700}
                destroyOnClose={true}
              >
                <CaseDetailModal
                  UserGuid={this.state.UserGuid}
                  Name={this.state.Name}
                  onCancel={() => {
                    this.setState({
                      editCaseModal: false,
                    });
                  }}
                />
              </Modal>
              <Modal
                visible={locationModal}
                footer={null}
                closable={false}
                width={700}
                destroyOnClose={true}
              >
                <LocationHistory
                  UserGuid={this.state.UserGuid}
                  Name={this.state.Name}
                  LocationServices={this.state.LocationServices}
                  onCancel={() => {
                    this.setState({
                      locationModal: false,
                    });
                  }}
                />
              </Modal>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <Table
                      columns={columns}
                      dataSource={subscribers}
                      size={'middle'}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: event => this.onRowClick(record.UserGuid),
                        };
                      }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.reduxBroadcaseInvitationModal}
          footer={null}
          closable={false}
          width={702}
          destroyOnClose={true}
        >
          <ReduxBroadcaseInvitationModal
            onCancel={() => {
              this.setState({
                reduxBroadcaseInvitationModal: false,
              });
            }}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = ({ get_judicial_user_list, combos, update_app_subscribers_case }) => {
  var { data, isFetching, error } = get_judicial_user_list;
  const { data: updateData, isFetching: isFetchingUpdate } = update_app_subscribers_case;
  let isSuccess = false;

  let listJudicialSubscribers = [];
  if (data && data.data) {
    // var array = [];
    // for (var index in data) {
    //   array.push(data[index]);
    // }
    listJudicialSubscribers = data.data;
  }
  if (update_app_subscribers_case.data && update_app_subscribers_case.data.success) {
    isSuccess = updateData.success;
  }
  var Centers = [{ Value: null, Text: 'Please Select' }];

  if (combos.data) {
    if (combos.data.Center)
      for (var center of combos.data.Center) {
        Centers.push(center);
      }
  }

  return {
    subscribers: listJudicialSubscribers || [],
    isFetching: isFetching,
    Centers: Centers,
    isSuccess,
  };
};
export default connect(mapStateToProps)(AppSubscribers);
