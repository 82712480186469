import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button, Popover, Modal, Tooltip } from 'antd';
import moment from 'moment';
import {
  goalLibraryUpsert,
  resetGoalLibraryUpsert,
  goalLibraryArchive,
  goalLibraryGetAll,
  goalLibraryGet,
} from '../../store/actions';
import AntdModal from '../../Components/CustomModal';
import CustomModal from '../../core/modal';
import { PopoverMenu } from '../../Components/PopoverMenu';
import { ProfilePicComponent } from '../../Components/profilePicComponent';
import { isComparer, isDateComparer, isNumericComparer } from '../../util/handler';
import AddGoalModal from './AddGoalModal';
import Loader, { Loader2 } from '../../Components/Loader';
import { ProcessCombos } from '../../store/comboConfig';
import PageTitle from '../../Components/PageTitle';
var timer;

class GoalsLibraryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goalsData: {
        Goal: [
          {
            Milestone: [{ Task: [{}] }],
          },
        ],
      },
    };
  }
  fetchDataGrid = () => {
    this.props.dispatch(goalLibraryGetAll({}));
  };

  componentDidMount() {
    this.fetchDataGrid();
    var result = ProcessCombos('GoalType,GoalRecoveryCapital');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  componentWillReceiveProps({ isGoalUpsert, goalUpsertMsg, goalInfo, isGoalArchive }) {
    if (isGoalUpsert && isGoalUpsert != this.props.isGoalUpsert) {
      this.toggle(goalUpsertMsg || 'Added Successfully.');
      this.props.dispatch(resetGoalLibraryUpsert({}));
      this.setState({
        isAddGoalModal: false,
        goalsData: {},
      });
      //   this.fetchDataGrid();
    }
    if (isGoalArchive && isGoalArchive != this.props.isGoalArchive) {
      this.toggle(goalUpsertMsg || 'Deleted Successfully.');
      //   this.props.dispatch(resetGoalLibraryUpsert({}));
      //   this.fetchDataGrid();
    }
    if (goalInfo && JSON.stringify(goalInfo) != JSON.stringify(this.props.goalInfo)) {
      this.setState({
        isAddGoalModal: true,
        // goalsData:goalInfo,
        goalsData: {
          ...goalInfo,
          Goal: [
            {
              Milestone: goalInfo.Milestone ? goalInfo.Milestone: [{ Task: [{Task:""}] }],
            },
          ],
        },
      });
    }
  }
  handleVisibleChange = visible => {
    this.setState({ visible });
  };
  getPopOverData = record => {
    return (
      <>
        <p
          onClick={() => {
            this.props.dispatch(
              goalLibraryGet({
                JsonInput: JSON.stringify({
                  GoalLibrary: [
                    {
                      GoalLibraryId: record.GoalLibraryId,
                    },
                  ],
                }),
              }),
            );
          }}
        >
          Edit
        </p>
        <AntdModal
          ModalButton={({ onClick }) => (
            <p style={{ color: '#FF3B3B' }} onClick={onClick} data-target="#move-intake-model">
              Delete
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">Are you sure you want to delete?</h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No, keep
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      goalLibraryArchive({
                        JsonInput: JSON.stringify({
                          GoalLibrary: [
                            {
                              GoalLibraryId: record.GoalLibraryId,
                              IsArchived: true,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          )}
        />
      </>
    );
  };
  // addGoal = goals => {
  //   const { GoalLibraryId } = this.state.goalsData;
  //   this.props.dispatch(
  //     goalLibraryUpsert({
  //       JsonInput: JSON.stringify({
  //         GoalLibrary: [
  //           {
  //             GoalLibraryId: GoalLibraryId,
  //             Description: goals,
  //           },
  //         ],
  //       }),
  //     }),
  //   );
  // };

  addGoal = values => {
    const {
      Goal,
      Description,
      GoalTypeId,
      ThresholdValue,
      RecoveryCategoryId,
      RecoveryCapitalId,
    } = values;
    const { GoalLibraryId } = this.state.goalsData;
    this.props.dispatch(
      goalLibraryUpsert({
        JsonInput: JSON.stringify({
          GoalLibrary: [
            {
              GoalLibraryId: GoalLibraryId ? GoalLibraryId : null,
              Description,
              GoalTypeId,
              ThresholdValue,
              Milestone: Goal && Goal[0] && Goal[0].Milestone,
              RecoveryCategoryId,
              RecoveryCapitalId,
            },
          ],
        }),
      }),
    );
  };

  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  onSearchChange = value => {
    this.state.searchStr = value;
    this.props.dispatch(
      goalLibraryGetAll({
        JsonInput: JSON.stringify({
          GoalLibrary: [
            {
              SearchStr: value,
            },
          ],
        }),
      }),
    );
  };
  fnDebounce = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.onSearchChange(value);
    }, 300);
  };
  render() {
    const { GoalLibrary, isFetching, isGoalFetching } = this.props;
    const columns = [
      {
        title: 'Added date',
        dataIndex: 'AddedDate',
        key: 'AddedDate',
        sorter: (a, b) => isDateComparer(a.AddedDateForSort, b.AddedDateForSort),
        sortDirections: ['descend', 'ascend'],
        render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
      },

      {
        title: 'Added By',
        dataIndex: 'AddedBy',
        key: 'AddedBy',
        sorter: (a, b) => isComparer(a.AddedBy, b.AddedBy),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <div class="student-list-pic d-flex align-items-center">
            <ProfilePicComponent src={record.ProfileImage} alt={'Added By'} />
            <span className="mx-2">{text}</span>
          </div>
        ),
      },
      {
        title: 'Role',
        dataIndex: 'RoleDescription',
        key: 'RoleDescription',
        sorter: (a, b) => isComparer(a.RoleDescription, b.RoleDescription),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Goal Type',
        dataIndex: 'GoalType',
        key: 'GoalType',
        sorter: (a, b) => isComparer(a.GoalType, b.GoalType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Goal',
        dataIndex: 'Goal',
        key: 'Goal',
        width: 280,
        sorter: (a, b) => isComparer(a.Goal, b.Goal),
        sortDirections: ['descend', 'ascend'],
        render: text => <div style={{ width: '280px' }}>{text && text}</div>,
      },
      {
        title: 'Milestone',
        dataIndex: 'Milestone',
        key: 'Milestone',
        width: 80,
        sorter: (a, b) => {
          if (a.Milestone && b.Milestone) {
            return isNumericComparer(a.Milestone.length, b.Milestone.length);
          } else {
            return isNumericComparer(a.Milestone, b.Milestone);
          }
        },
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <div class="">
            <Tooltip
              placement="top"
              title={
                <>
                  <p style={{ color: 'white',margin:"0px" }}>
                    Milestone ({record.Milestone && record.Milestone.length})
                  </p>
                  {record.Milestone &&
                    record.Milestone.map((item, index) => {
                      return (
                        <div>
                          {index + 1}.{item.Milestone}
                        </div>
                      );
                    })}
                </>
              }
            >
              {record.Milestone && (
                <span
                  style={{
                    cursor: 'pointer',
                    background: 'rgba(0, 158, 247, 0.16)',
                    padding: '5px',
                  }}
                >
                  {record.Milestone.length}
                </span>
              )}
            </Tooltip>
          </div>
        ),
      },
      {
        title: 'Task',
        dataIndex: 'Task',
        key: 'Task',
        width: 80,
        sorter: (a, b) => {
          if (a.Task && b.Task) {
            return isNumericComparer(a.Task.length, b.Task.length);
          } else {
            return isNumericComparer(a.Task, b.Task);
          }
        },
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <div class="">
            <Tooltip
              placement="top"
              title={
                <>
                  <p style={{ color: 'white',margin:"0px" }}>Task ({record.Task && record.Task.length})</p>
                  {record.Task &&
                    record.Task.map((item, index) => {
                      return (
                        <div>
                          {index + 1}.{item.Task}
                        </div>
                      );
                    })}
                </>
              }
            >
              {record.Task && (
                <span
                  style={{
                    cursor: 'pointer',
                    background: 'rgba(0, 158, 247, 0.16)',
                    padding: '5px',
                  }}
                >
                  {record.Task.length}
                </span>
              )}
            </Tooltip>
          </div>
        ),
      },
      {
        title: 'Action',
        dataIndex: '',
        key: 'x',
        render: (text, record) => (
          //   <Popover
          //     overlayClassName="alert-box-style"
          //     title={false}
          //     content={<div className="sort-by-list">{this.getPopOverData(record)}</div>}
          //     trigger="click"
          //     visible={this.state.visible}
          //     onVisibleChange={this.handleVisibleChange}
          //     class="alert-sort"
          //   >
          //     <img src="images/icons/3dot.svg" style={{ cursor: 'pointer' }} />
          //   </Popover>
          // <div className="tools-grid mx-2" onClick={event => event.stopPropagation()}>
          //   <PopoverMenu
          //     OptionData={this.getPopOverData(record)}
          //     placement="left"
          //     //   trigger="hover"
          //   />
          // </div>
          <div class="tools-grid d-flex">
            <div className="mr-4 mt-1">
              <span
                className="start-btn"
                style={{
                  cursor: 'pointer',
                }}
              >
                <Tooltip placement="top" title="Edit">
                  {' '}
                  <img
                    src="images/shape.svg"
                    width="14px"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={event => {
                      this.props.dispatch(
                        goalLibraryGet({
                          JsonInput: JSON.stringify({
                            GoalLibrary: [
                              {
                                GoalLibraryId: record.GoalLibraryId,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                  />
                </Tooltip>
              </span>
            </div>
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <p
                    style={{ color: '#FF3B3B' }}
                    onClick={onClick}
                    data-target="#move-intake-model"
                  >
                    <Tooltip placement="top" title="Delete">
                      <p style={{ cursor: 'pointer' }}>
                        <i class="far fa-trash-alt"></i>
                      </p>
                    </Tooltip>
                  </p>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div className="modal-content border-0">
                    <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 className="modal-title col-12 mb-3">Are you sure you want to delete?</h4>
                    </div>
                    <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No, keep
                      </button>
                      <button
                        className="btn"
                        onClick={() => {
                          onOk();
                          this.props.dispatch(
                            goalLibraryArchive({
                              JsonInput: JSON.stringify({
                                GoalLibrary: [
                                  {
                                    GoalLibraryId: record.GoalLibraryId,
                                    IsArchived: true,
                                  },
                                ],
                              }),
                            }),
                          );
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Goals Library" />
        <div class="content-area">
          <div class="row top-table-search mt-3 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/cw/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Goals Library</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control 
                   shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    onKeyUp={event => {
                      this.fnDebounce(event.target.value);
                    }}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>

              <input
                type="button"
                class="btn px-5"
                value="Add New Goal"
                onClick={() => {
                  this.setState({
                    isAddGoalModal: true,
                    goalsData: {
                      ThresholdValue: 0,
                      ...this.state.goalsData
                    },
                  });
                }}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="">
                      <Table
                        columns={columns}
                        dataSource={GoalLibrary}
                        size={'middle'}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.fetchDataGrid();
          }}
          yesText="OK"
          hideCancel={true}
        />
        <Modal
          visible={this.state.isAddGoalModal}
          open={this.state.isAddGoalModal}
          footer={null}
          closable={false}
          width={900}
          destroyOnClose={true}
        >
          <AddGoalModal
            onCancel={() => {
              this.setState({
                isAddGoalModal: false,
                goalsData: {
                  Goal: [
                    {
                      Milestone: [{ Task: [{}] }],
                    },
                  ],
                },
              });
            }}
            // addGoal={this.addGoal}
            goalsData={this.state.goalsData}
            isGoalFetching={isGoalFetching}
            goalTypeList={this.props.goalTypeList}
            onSaveClick={this.addGoal}
            // initialValues={{ ...this.state.goalsData, Milestone: [{}]}}
            initialValues={this.state.goalsData}
            goalRecoveryCapitalDropdown={this.props.goalRecoveryCapitalDropdown}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({
  combos,
  goal_library_upsert,
  goal_library_archive,
  goal_library_get_all,
  goal_library_get,
}) => {
  let GoalLibrary = [];
  let isGoalUpsert = false;
  let isGoalArchive = false;
  let goalUpsertMsg = null;
  let goalInfo = null;
  let goalTypeList = null;
  let goalRecoveryCapitalDropdown = null;

  const { data, isFetching, error } = goal_library_get_all;
  const { data: goalData } = goal_library_get;
  const { isFetching: isGoalFetching } = goal_library_upsert;

  if (data && data.GoalLibrary) {
    GoalLibrary = data.GoalLibrary || [];
  }

  if (goal_library_upsert.data && goal_library_upsert.data.success) {
    isGoalUpsert = true;
    goalUpsertMsg = goal_library_upsert.data.data[0].Result;
  }
  if (goal_library_archive.data && goal_library_archive.data.success) {
    isGoalArchive = true;
    // goalUpsertMsg = goal_library_archive.data.data[0].Result;
  }
  if (goalData && goalData.GoalLibrary) {
    goalInfo = goalData.GoalLibrary[0];
  }

  if (combos.data && combos.data['GoalType']) {
    goalTypeList = combos.data['GoalType'] || [];
  }
  if (combos.data && combos.data['GoalRecoveryCapital']) {
    goalRecoveryCapitalDropdown = combos.data['GoalRecoveryCapital'] || [];
  }

  return {
    GoalLibrary,
    isGoalUpsert,
    goalUpsertMsg,
    goalInfo,
    isGoalArchive,
    isFetching,
    isGoalFetching,
    goalTypeList,
    goalRecoveryCapitalDropdown,
  };
};
export default connect(mapStateToProps)(withRouter(GoalsLibraryList));
