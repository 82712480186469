import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Button } from 'antd';
import renderHTML from 'react-render-html';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';
import { isComparer, isDateComparer, isNumericComparer } from '../../../../util/handler';
import {
  augmentedSummaryGet,
  studentAvailableSpacesListGet,
  studentQueueInsert,
} from '../../../../store/actions';
import AssignedProgram from '../../components/AssignedProgram';
import { ProcessCombos } from '../../../../store/comboConfig';
import { Loader2 } from '../../../../Components/Loader.js';

class ProgramMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskId: null,
      selectedTag: null,
      selectedTagText: null,
      isAllowedToAssign: null,
    };
  }
  componentDidMount() {
    let studentId = [{ StudentId: this.props.ClientId }];
    this.props.dispatch(
      augmentedSummaryGet({
        Json: JSON.stringify({
          Student: studentId,
        }),
      }),
    );
    let data = {
      Json: JSON.stringify({
        Queue: studentId,
      }),
    };
    this.props.dispatch(studentAvailableSpacesListGet(data));
    var result = ProcessCombos('QueuePriority');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  componentWillReceiveProps({ centerProgramType, isQueueAdded, isAuthorized }) {
    if (isAuthorized && isAuthorized != this.props.isAuthorized) {
      this.setState({
        isAllowedToAssign: isAuthorized,
      });
    }
    if (
      centerProgramType &&
      JSON.stringify(centerProgramType) != JSON.stringify(this.props.centerProgramType)
    ) {
      this.setState({
        centerProgramType,
      });
    }
    if (isQueueAdded && isQueueAdded != this.props.isQueueAdded) {
      let data = {
        Json: JSON.stringify({
          Queue: [{ StudentId: this.props.ClientId }],
        }),
      };
      this.props.dispatch(studentAvailableSpacesListGet(data));
    }
  }
  handlePriorityChange = riskId => {
    this.setState({
      riskId: riskId,
    });
  };
  handleTagsChange = event => {
    // document.getElementById(event.target.id).classList.add('active-tag');
    // var header = document.getElementById('program-match-tags');
    // var btns = header.getElementsByClassName('tag');
    // for (var i = 0; i < btns.length; i++) {
    //   btns[i].addEventListener('click', function() {
    //     var current = document.getElementsByClassName('active-tag');
    //     if (current.length > 0) {
    //       current[0].className = current[0].className.replace(' active-tag', '');
    //     }
    //     this.className += ' active-tag';
    //   });
    // }
    let current = document.getElementsByClassName('active-tag');
    if (current.length > 0) {
      current[0].className = current[0].className.replace(' active-tag', '');
    }
    document.getElementById(event.target.id).classList.add('active-tag');
    this.setState({
      selectedTag: event.target.id,
      selectedTagText: event.target.textContent,
    });
    let data = {
      Json: JSON.stringify({
        Queue: [
          {
            CenterProgramTypeId: parseInt(event.target.id),
            StudentId: this.props.ClientId,
          },
        ],
      }),
    };
    this.props.dispatch(studentAvailableSpacesListGet(data));
  };
  columns = [
    {
      title: 'Center',
      dataIndex: 'Name',
      key: 'Name',
      sorter: (a, b) => isComparer(a.Name, b.Name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'City',
      dataIndex: 'City',
      key: 'City',
      sorter: (a, b) => isComparer(a.City, b.City),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'State',
      dataIndex: 'StateOrProvince',
      key: 'StateOrProvince',
      sorter: (a, b) => isComparer(a.StateOrProvince, b.StateOrProvince),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Number of Beds Availabe',
      dataIndex: 'BedsAvailable',
      key: 'BedsAvailable',
      sorter: (a, b) => isNumericComparer(a.BedsAvailable, b.BedsAvailable),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Next Available Date',
      dataIndex: 'NextDate',
      key: 'NextDate',
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isDateComparer(a.NextDate, b.NextDate),
      sortDirections: ['descend', 'ascend'],
      render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
    },
    {
      title: 'Action',
      key: 'x',
      width: '50px',
      fixed: 'right',
      render: (text, record) => {
        return (
          <span
            className="assign-queue-action"
            onClick={event => {
              this.props.dispatch(
                studentQueueInsert({
                  json: JSON.stringify({
                    Queue: [
                      {
                        CenterProgramTypeId: parseInt(this.state.selectedTag),
                        StudentId: this.props.ClientId,
                        Priority: this.state.riskId,
                        CenterId: record.CenterId,
                      },
                    ],
                  }),
                }),
              );
            }}
          >
            {this.state.isAllowedToAssign && 'Assign to Queue'}
          </span>
        );
      },
    },
  ];
  render() {
    const {
      ClientId,
      PreItakeSurveyId,
      augmentedSummary,
      centerList,
      studentQueueInfo,
      QueuePriority,
      isFetching,
    } = this.props;
    const { centerProgramType } = this.state;

    return (
      <div className="main program-match mb-5">
        <div className="row">
          <div className="col-12">
            <div className="augmented-client-summary">
              <span className="title">Augmented Client Summary</span>
              <span className="text">
                {augmentedSummary && augmentedSummary.Answer && renderHTML(augmentedSummary.Answer)}
              </span>
            </div>
          </div>

          {studentQueueInfo ? (
            <div className="col-12">
              <AssignedProgram AssignedProgram={studentQueueInfo} />
            </div>
          ) : (
            <>
              <div className="col-12 py-4 enrollment-form">
                <span className="title">Set Priority of Client</span>
                <div className="col-12 col-md-6 col-lg-4 pt-2">
                  <div class="form-group">
                    <ReduxSelect
                      options={QueuePriority}
                      onChange={this.handlePriorityChange}
                      className="basic-single"
                      classNamePrefix="select"
                      value={this.state.riskId}
                      placeholder="Select Priority"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Loader2 loading={isFetching} />
                <div className="care-options">
                  <span className="title">Care Options</span>
                  <div className="tags" id="program-match-tags">
                    {centerProgramType &&
                      centerProgramType.map(item => {
                        return (
                          <span className="tag" id={item.Value} onClick={this.handleTagsChange}>
                            {item.Text}
                          </span>
                        );
                      })}
                    {/* <span className="tag" id="php" onClick={this.handleTagsChange}>
                  PHP
                </span>
                <span className="tag" id="detox" onClick={this.handleTagsChange}>
                  Detox
                </span>
                <span className="tag" id="residential" onClick={this.handleTagsChange}>
                  Residential
                </span>
                <span className="tag" id="op" onClick={this.handleTagsChange}>
                  OP
                </span>
                <span className="tag" id="digital" onClick={this.handleTagsChange}>
                  Digital
                </span> */}
                  </div>
                </div>
              </div>
              {this.state.selectedTagText && (
                <div className="col-12">
                  <div className="care-option-table">
                    <span className="title">
                      {' '}
                      Available Spaces for {this.state.selectedTagText}
                    </span>
                    <div>
                      <Table
                        columns={this.columns}
                        dataSource={centerList}
                        size={'middle'}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    augmented_summary_get,
    student_available_spaces_list_get,
    combos,
    student_queue_insert,
  } = state;
  const { data: AugmentedSummaryData, isFetching } = augmented_summary_get;
  const {
    data: AvailableSpacesData,
    isFetching: isSpaceListFetching,
  } = student_available_spaces_list_get;
  const { data: QueueupsertData } = student_queue_insert;

  let augmentedSummary = null;
  let centerList = [];
  let centerProgramType = null;
  let studentQueueInfo = null;
  let QueuePriority = [];
  let isHistory = false;
  let isQueueAdded = false;
  let isAuthorized = false;
  if (AugmentedSummaryData && AugmentedSummaryData.Summary) {
    augmentedSummary = AugmentedSummaryData.Summary[0];
  }
  if (AvailableSpacesData && AvailableSpacesData.IsAuthorized) {
    isAuthorized = AvailableSpacesData.IsAuthorized;
  }
  if (AvailableSpacesData && AvailableSpacesData.CenterList) {
    centerList = AvailableSpacesData.CenterList;
  }
  if (AvailableSpacesData && AvailableSpacesData.CenterProgramType) {
    centerProgramType = AvailableSpacesData.CenterProgramType;
  }
  if (AvailableSpacesData && AvailableSpacesData.StudentQueueInfo) {
    studentQueueInfo = AvailableSpacesData.StudentQueueInfo[0];
  }
  if (combos.data && combos.data.QueuePriority) {
    // QueuePriority = combos.data.QueuePriority;
    QueuePriority = combos.data.QueuePriority.map(item => {
      return {
        ...item,
        Icon: `${item.Text}Risk`,
      };
    });
  }
  if (QueueupsertData && QueueupsertData.success) {
    isQueueAdded = QueueupsertData.success;
  }

  return {
    augmentedSummary,
    isFetching: isFetching || isSpaceListFetching,
    centerList,
    centerProgramType,
    studentQueueInfo,
    QueuePriority,
    isQueueAdded,
    isAuthorized,
  };
};
export default connect(mapStateToProps)(withRouter(ProgramMatch));
