import React, { Component } from 'react';

class DocumentsRequested extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimelineModalOpen: false,
    };
  }
  render() {
    const { docRequested, isFetching } = this.props;
    return (
      <div style={{ backgroundColor: '#F8F8F8' }} className="p-4">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Requested Document(s)</h4>
        </div>

        <div className="mt-3">
          {/* <Loader2 loading={isFetching} /> */}
          {docRequested &&
            docRequested.map((item, index) => {
              const {
                BlobUrl,
                DocumentName,
                RequestedOn,
                RequestedBy,
                UploadedFileFullName,
                DocumentGuid,
                DocUploadedDate,
                Note,
              } = item;
              const fileExtension =
                UploadedFileFullName &&
                UploadedFileFullName.substr(UploadedFileFullName.lastIndexOf('.') + 1);
              return (
                <div className="requested-documents">
                  <div className="d-flex text-center my-3" style={{ backgroundColor: 'white' }}>
                    <div className="d-flex col-12 my-3 justify-content-between">
                      <div className="client-detail-box col-4 p-0 m-0 text-left">
                        <p className="client-label mb-0">Document Name</p>
                        <p className="client-text mb-0">{Note && Note}</p>
                      </div>
                      <div className="client-detail-box col-4 p-0 m-0 text-center">
                        <p className="client-label mb-0">Requested By(Payer)</p>
                        <p className="client-text mb-0">{RequestedBy && RequestedBy}</p>
                      </div>
                      <div className="client-detail-box col-4 p-0 m-0 text-right">
                        <p className="client-label mb-0">Requested on</p>
                        <p className="client-text mb-0">{RequestedOn && RequestedOn}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default DocumentsRequested;
