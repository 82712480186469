import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  externalProviderListGet,
  externalProviderGet,
  resetExternalProviderGet,
  externalProviderUpsert,
  resetExternalProviderUpsert,
} from '../../../../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../../util/handler';
import { ProfilePicComponent } from '../../../../../../Components/profilePicComponent';
import AntdModal from '../../../../../../Components/CustomModal';
import CustomModal from '../../../../../../core/modal';
import SuccessModal from '../../../../../../core/modal/SuccessModal';
import { Tooltip, Drawer, Table, Radio } from 'antd';
import CloseIcon from '../../../../../../Components/CloseIcon';
import ExternalCareTeamMember from './externalCareTeamMember';
import ExternalCareTeamMemberView from './externalCareTeamMemberView';
import ExternalCareTeamListFilter from './externalCareTeamListFilter';
import GridSearch from '../../../../../../Components/gridSearch';
import { CustomToolTip } from '../../../../../../Components/CustomTooltip';
import Loader, { Loader2 } from '../../../../../../Components/Loader';
import PaginationPrevNext from '../../../../../../Components/Pagination';

class ExternalCareTeamList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {
        Country: 'USA',
      },
      isReadOnly: false,
      PageNumber: 1,
      PageRows: 25,
      filtersData: {},
      selectedRowsArray: [],
    };
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  getGridData = ({ PageNumber, PageRows }) => {
    this.props.dispatch(
      externalProviderListGet({
        json: JSON.stringify({
          ExternalProvider: [
            {
              ...this.state.filtersData,
              Search: this.state.searchStr,
              PageNumber: PageNumber || this.state.PageNumber,
              RowspPage: PageRows || this.state.PageRows,
            },
          ],
        }),
      }),
    );
  };
  getPagination = ({ PageNumber, PageRows }) => {
    this.setState(
      {
        PageNumber: PageNumber,
        PageRows: PageRows,
      },
      () => {
        this.getGridData({ PageNumber, PageRows });
      },
    );
  };
  getFilterInfo = filters => {
    this.state.filtersData = filters;
  };
  componentDidMount() {
    this.getGridData({});
  }
  componentWillReceiveProps({ externalProviderInfo, upsertMsg, upsertMsgInfo, isUpsertSuccess }) {
    if (
      externalProviderInfo &&
      JSON.stringify(externalProviderInfo) != JSON.stringify(this.props.externalProviderInfo)
    ) {
      this.setState({
        initialData: externalProviderInfo,
      });
    }

    if (isUpsertSuccess && isUpsertSuccess != this.props.isUpsertSuccess) {
      this.toggle(upsertMsg, upsertMsgInfo);
      this.props.toggleMemberDrawer();
      this.setState({
        initialData: {},
        isCareTeamDrawer: false,
      });
      this.props.dispatch(resetExternalProviderUpsert());
    }
  }
  getExternalProviderData = ExternalProviderId => {
    this.props.dispatch(
      externalProviderGet({
        Json: JSON.stringify({
          ExternalProvider: [
            {
              ExternalProviderId: ExternalProviderId,
            },
          ],
        }),
      }),
    );
  };
  toggleViewDrawer = record => {
    if (record && record.ExternalProviderId) {
      this.getExternalProviderData(record.ExternalProviderId);
      this.setState({
        isCareTeamViewDrawer: !this.state.isCareTeamViewDrawer,
      });
    } else {
      this.setState({
        isCareTeamViewDrawer: !this.state.isCareTeamViewDrawer,
      });
    }
  };
  toggleDrawer = () => {
    this.setState({
      isCareTeamDrawer: !this.state.isCareTeamDrawer,
      initialData: {},
    });
    this.props.dispatch(resetExternalProviderGet());
  };
  toggle = (header, headerInfo) => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
      headerInfo,
    }));
  };
  tableCoumns = [
    // {
    //   title: 'Profile',
    //   dataIndex: 'ProfileImageUrl',
    //   key: 'ProfileImageUrl',
    //   render: (text, record, index) => (
    //     <div className="d-flex align-items-center">
    //       <ProfilePicComponent src={record.ProfileImageUrl} alt={record.altText} />
    //     </div>
    //   ),
    // },

    {
      title: 'NPI',
      dataIndex: 'NPI',
      key: 'NPI',
      sorter: (a, b) => isComparer(a.NPI, b.NPI),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Member Name',
      dataIndex: 'x',
      key: 'x',
      sorter: (a, b) => isComparer(a.LastName, b.LastName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <div className="d-flex align-items-center">
          <span className="mx-1">{record.FirstName}</span>
          <span className="mx-1">{record.LastName}</span>
        </div>
      ),
    },
    {
      title: 'Specialty',
      dataIndex: 'Specialty',
      key: 'Specialty',
      // sorter: (a, b) => isComparer(a.Specialty, b.Specialty),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const SpecialtyList = text;
        return (
          <div className="">
            {SpecialtyList && SpecialtyList.length > 1 ? (
              <span className="mx-1">
                <CustomToolTip
                  // trigger="hover"
                  placement="left"
                  optionData={SpecialtyList}
                  titleKey={'Specialty'}
                  title={'Specialty'}
                />
              </span>
            ) : (
              <span className="mx-1">{SpecialtyList && SpecialtyList[0].Specialty}</span>
            )}
          </div>
        );
      },
    },
    // {
    //   title: 'First Name',
    //   dataIndex: 'FirstName',
    //   key: 'FirstName',
    //   sorter: (a, b) => isComparer(a.FirstName, b.FirstName),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Last Name',
    //   dataIndex: 'LastName',
    //   key: 'LastName',
    //   sorter: (a, b) => isComparer(a.LastName, b.LastName),
    //   sortDirections: ['descend', 'ascend'],
    // },

    // {
    //   title: 'Email',
    //   dataIndex: 'EmailWork',
    //   key: 'EmailWork',
    //   sorter: (a, b) => isComparer(a.EmailWork, b.EmailWork),
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Gender',
      dataIndex: 'Gender',
      key: 'Gender',
      sorter: (a, b) => isComparer(a.Gender, b.Gender),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Location',
      dataIndex: 'Locations',
      key: 'Locations',
      // sorter: (a, b) => isComparer(a.Location, b.Location),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const LocationList = text;
        return (
          <div className="d-flex align-items-center">
            {LocationList && LocationList.length > 1 ? (
              <span className="mx-1">
                <CustomToolTip
                  // trigger="hover"
                  placement="left"
                  optionData={LocationList}
                  titleKey={'Location'}
                  title={'Locations'}
                />
              </span>
            ) : (
              <span className="mx-1">{LocationList && LocationList[0].Location}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Facility Name',
      dataIndex: 'Facility',
      key: 'Facility',
      // sorter: (a, b) => isComparer(a.Facility, b.Facility),
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const FacilityList = text;
        return (
          <div className="d-flex align-items-center">
            {FacilityList && FacilityList.length > 1 ? (
              <span className="mx-1">
                <CustomToolTip
                  // trigger="hover"
                  placement="left"
                  optionData={FacilityList}
                  titleKey={'FacilityName'}
                  title={'Facility'}
                />
              </span>
            ) : (
              <span className="mx-1">{FacilityList && FacilityList[0].FacilityName}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'x',
      fixed: 'right',
      render: (text, record, index) => {
        return (
          <div className="tools-grid">
            <span className="mx-2">
              <Tooltip placement="top" title="View">
                <img
                  src="images/openIcon.svg"
                  width="16px"
                  style={{ cursor: 'pointer' }}
                  onClick={event => {
                    this.toggleViewDrawer(record);
                  }}
                />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  handleCareTeam = val => {
    let data = {
      ExternalProvider: [
        {
          ExternalProviderId: val.ExternalProviderId || null,
          ClientId: this.getClientId(),
          FirstName: val.FirstName,
          LastName: val.LastName,
          Gender: val.Gender,
          FacilityName: val.FacilityName,
          EmailWork: val.EmailWork,
          PhoneNumber: val.PhoneNumber,
          CountryISO: val.CountryISO,
          State: val.State,
          City: val.City,
          ZipCode: val.ZipCode,
          NPI: val.NPI,
          Specialty:
            typeof val.Specialty == 'object'
              ? val.Specialty.map(item => item.Value).toString()
              : val.Specialty || null,
          // Specialization: [
          //   {
          //     ExternalProviderSpecializationId: val.ExternalProviderSpecializationId || null,
          //     Specialty: val.Specialty,
          //   },
          // ],
        },
      ],
    };
    this.props.dispatch(
      externalProviderUpsert({
        Json: JSON.stringify(data),
      }),
    );
  };

  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });

    this.props.dispatch(
      externalProviderListGet({
        json: JSON.stringify({
          ExternalProvider: [
            {
              ...this.state.filtersData,
              Search: value,
            },
          ],
        }),
      }),
    );
  };

  render() {
    const { isFetchingUpsert, isFetchingList, rowSelection, externalClientCareTeam } = this.props;
    const {
      isCareTeamDrawer,
      isCareTeamViewDrawer,
      isReadOnly,
      initialData,
      isModelOpen,
      header,
      headerInfo,
      actionType,
      PageNumber,
      PageRows,
    } = this.state;

    return (
      <div className="row">
        <div className="col-3 pr-1">
          <ExternalCareTeamListFilter
            getClientId={this.getClientId}
            getFilterInfo={this.getFilterInfo}
          />
        </div>
        <div className="col-9 rcm-tools-table pl-1">
          <div className="card border-0">
            <div className="row card-body">
              <div className="col-md-2"></div>
              <div className="col-md-10 text-right">
                <GridSearch onSearchChange={this.onSearchChange} />
                <input
                  type="button"
                  className="btn"
                  value={'Add External Member Manually'}
                  onClick={this.toggleDrawer}
                />
              </div>
              <div className="col-12 mt-2">
                <Loader2 loading={isFetchingList} />
                <Table
                  name="external-care-list"
                  columns={this.tableCoumns}
                  dataSource={externalClientCareTeam}
                  size={'small'}
                  pagination={false}
                  className="radio-nested-tables"
                  rowSelection={{
                    type: 'radio',
                    ...rowSelection,
                  }}
                />
                <PaginationPrevNext
                  getPagination={this.getPagination}
                  pageNumber={PageNumber}
                  pageRows={PageRows}
                  rowsLength={externalClientCareTeam && externalClientCareTeam.length}
                />
              </div>
            </div>
          </div>
        </div>
        {isCareTeamDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">
                  {/* {actionType ? actionType : 'Select'} */}
                  Add External Member Manually
                </span>
              </div>
            }
            placement="right"
            open={isCareTeamDrawer}
            width={800}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.setState({
                initialData: {},
                isCareTeamDrawer: false,
              });
            }}
          >
            <ExternalCareTeamMember
              initialValues={initialData}
              ExternalProviderId={initialData.ExternalProviderId}
              disable={isReadOnly}
              onSaveClick={this.handleCareTeam}
              onClose={() => {
                this.setState({
                  initialData: {},
                  isCareTeamDrawer: false,
                });
              }}
              isFetchingUpsert={isFetchingUpsert}
            />
          </Drawer>
        )}

        {isCareTeamViewDrawer && (
          <Drawer
            className="peer-recovery-drawer"
            title={
              <div className="drawer-title">
                <span className="title">External Care Team Member Details</span>
              </div>
            }
            placement="right"
            open={isCareTeamViewDrawer}
            width={800}
            bodyStyle={{
              paddingTop: '5px',
              paddingBottom: '20px',
              background: '#fff',
            }}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            closeIcon={<CloseIcon />}
            onClose={() => {
              this.setState({
                initialData: {},
                isCareTeamViewDrawer: false,
              });
            }}
          >
            <ExternalCareTeamMemberView
              ExternalProviderData={initialData}
              ExternalProviderId={initialData.ExternalProviderId}
              onClose={() => {
                this.setState({
                  initialData: {},
                  isCareTeamViewDrawer: false,
                });
              }}
            />
          </Drawer>
        )}
        {isModelOpen && (
          <SuccessModal
            isOpen={isModelOpen}
            header={header}
            headerText={headerInfo}
            yesAction={() => {
              // this.getGridData({});
              this.props.history.goBack();
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  external_provider_list_get,
  external_provider_upsert,
  external_provider_get,
}) => {
  const { data: externalCareTeamData, isFetching: isFetchingList } = external_provider_list_get;
  const { data: externalCareTeamGetData } = external_provider_get;
  const { data: upsertExternalProviderData, isFetching } = external_provider_upsert;

  let externalClientCareTeam = [];
  let externalProviderInfo = null;
  let upsertMsg = false;
  let upsertMsgInfo = false;
  let isUpsertSuccess = false;
  if (externalCareTeamData && externalCareTeamData.ExternalProvider) {
    externalClientCareTeam = externalCareTeamData.ExternalProvider;
  }
  if (externalCareTeamGetData && externalCareTeamGetData.ExternalProvider) {
    externalProviderInfo = externalCareTeamGetData.ExternalProvider[0];
  }

  if (upsertExternalProviderData && upsertExternalProviderData.success) {
    upsertMsg = upsertExternalProviderData.result.Msg;
    upsertMsgInfo = upsertExternalProviderData.result.MsgInfo;
    isUpsertSuccess = upsertExternalProviderData.success;
  }
  return {
    externalClientCareTeam,
    externalProviderInfo,
    isFetchingUpsert: isFetching,
    isFetchingList,
    upsertMsg,
    upsertMsgInfo,
    isUpsertSuccess,
  };
};
export default connect(mapStateToProps)(withRouter(ExternalCareTeamList));
