import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../util/handler';
import { announcementGetAll, getListNews, deleteNews } from '../store/actions';
import Loader, { Loader2 } from '../Components/Loader';
import PageTitle from '../Components/PageTitle';

const columns = [
  {
    title: 'Image URL',
    dataIndex: 'ImageUrl',
    key: 'imageUrl',
    render: (text, record) => (
      <div class="student-list-pic">
        <img
          src={text}
          class="img-circle"
          height="40"
          width="40"
          style={{ borderRadius: '50%', objectFit: 'cover' }}
        />
      </div>
    ),
  },
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'title',
    sorter: (a, b) => isComparer(a.Title, b.Title),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Summary',
    dataIndex: 'Summary',
    key: 'summary',
    sorter: (a, b) => isComparer(a.Summary, b.Summary),
    sortDirections: ['descend', 'ascend'],
  },
  /*{
    title: 'Abstract',
    dataIndex: 'Abstract',
    key: 'abstract',
    sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => <div style={{ width: '200px' }}>{text}</div>,
  },*/
  {
    title: 'Is Published',
    dataIndex: 'IsPublished',
    key: 'IsPublished',
    render: (text, record) => <div>{text ? 'Published' : 'Not Published'}</div>,
    sorter: (a, b) => isNumericComparer(a.IsPublished, b.IsPublished),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Date Published',
    dataIndex: 'DatePublished',
    key: 'datePublished',
    sorter: (a, b) => isDateComparer(a.DatePublishedForSort, b.DatePublishedForSort),
    sortDirections: ['descend', 'ascend'],
  },
  // {
  //   title: 'End Date',
  //   dataIndex: 'EndDate',
  //   key: 'endDate',
  //   sorter: (a, b) => isDateComparer(a.EndDate, b.EndDate),
  //   sortDirections: ['descend', 'ascend'],
  // },
  // {
  //   title: 'Lead Story',
  //   dataIndex: 'IsMain',
  //   key: 'isMain',
  //   sorter: (a, b) => (a.IsMain, b.IsMain),
  //   sortDirections: ['descend', 'ascend'],
  //   render: (text, record) => text && <div style={{ width: '200px' }}>{`${text}`}</div>,
  // },
];

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  componentWillMount() {}

  componentDidMount() {
    // this.props.dispatch(announcementGetAll({ filter: null, searchStr: this.state.searchStr }));
    this.props.dispatch(getListNews({}));
  }

  onSearchChange = control => {
    var value = control.target.value;
    this.state.searchStr = value;
    if (value.length > 1) {
      this.props.dispatch(announcementGetAll({ filter: null, searchStr: this.state.searchStr }));
    } else if (value.length == 0) {
      this.props.dispatch(announcementGetAll({ filter: null, searchStr: null }));
    }
  };

  // onRowClick = announcementId => {
  //   this.props.history.push('/manage/announcement-form?announcementId=' + announcementId);
  // };
  onRowClick = newsId => {
    this.props.history.push('/manage/news-form?newsId=' + newsId);
  };

  onManageClick = announcementId => {
    this.props.history.push('/manage/staff?announcementId=' + announcementId);
  };

  render() {
    const { isFetching, newsLists } = this.props;
    return (
      <div class="main  mb-5">
        <PageTitle TitleText="News List" />
        <div class="container-fluid">
          {/* <Loader loading={isFetching} /> */}
          <div class="row top-table-search mt-5 mb-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              {/* <Link to="/manage" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link> */}
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">News</h3>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right d-flex justify-content-end">
              <div class="search-box w-100 d-none">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>
              <Link to="/manage/news-form/" class="btn" title="Add a News">
                Add News
              </Link>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="row-hover-enabled">
                      <Table
                        columns={columns}
                        dataSource={newsLists}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: event => this.onRowClick(record.NewsId),
                          };
                        }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ announcement_get_all, get_list_news }) => {
  // var { data, isFetching, error } = announcement_get_all;
  var { data, isFetching, error } = get_list_news;

  if (data) {
    data = JSON.parse(data.data).News;
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }
  return {
    newsLists: data || [],
    isFetching,
  };
};

export default connect(mapStateToProps)(Announcement);
