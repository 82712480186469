import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  youuniverseChatPost,
  youuniverseBookMarkQuestionUpsert,
  youuniverseBookMarkQuestionListGet,
  youuniverseChatAttributeUpdate,
  askFollowUpInitiate,
} from '../../store/actions';
import { UserQuery, Response } from './components';
import { AILoader2 } from '../../Components/Loader';
const Bookmarks = props => {
  const dispatch = useDispatch();
  const [bookmarkedList, setBookmarkedList] = useState([]);
  const [loader, setLoader] = useState(false);

  const {
    bookmarkedDataList,
    followUpDataResponse,
    askQuestionResponse,
    isFetching,
    isBookFetching,
  } = useSelector(
    ({
      youuniverse_bookmark_question_list_get: { data: bookmarkedData, isFetching: isBookMFetching },
      ask_follow_up_initiate: { data: followUpData },
      youuniverse_chat_post: { data: askQuestionData, isFetching, error },
    }) => {
      return {
        bookmarkedDataList: bookmarkedData && bookmarkedData.data,
        followUpDataResponse: followUpData && followUpData,
        askQuestionResponse: askQuestionData,
        isFetching: isFetching,
        isBookFetching: isBookMFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    dispatch(youuniverseBookMarkQuestionListGet({}));
    // return () => {
    //   dispatch(resetChatGet());
    // };
  }, []);
  useEffect(() => {
    setBookmarkedList(bookmarkedDataList);
  }, [bookmarkedDataList]);
  useEffect(() => {
    if (followUpDataResponse) {
      props.updateStep(0, followUpDataResponse.ConversationGuid);
    }
    if (askQuestionResponse) {
      setLoader(false);
      props.updateStep(0);
    }
  }, [followUpDataResponse, askQuestionResponse]);

  const queryBookmark = (item, index) => {
    let data = {
      BookMark: {
        ...item,
        IsQueryBookmarked: !item.IsQueryBookmarked,
      },
    };
    dispatch(
      youuniverseBookMarkQuestionUpsert({
        JSON: JSON.stringify(data),
      }),
    );
    let list = bookmarkedList;
    list[index].IsQueryBookmarked = !item.IsQueryBookmarked;
    setBookmarkedList([...list]);
  };

  const responseBookmark = (item, index) => {
    let data = {
      BookMark: {
        // ConversationGuid: item.ConversationGuid,
        // ChatId: item.ChatId,
        // LikedDisliked: item.LikedDisliked,
        ...item,
        IsResponseBookmarked: !item.IsResponseBookmarked,
      },
    };
    dispatch(
      youuniverseChatAttributeUpdate({
        JSON: JSON.stringify(data),
      }),
    );
    let list = bookmarkedList;
    list[index].IsResponseBookmarked = !item.IsResponseBookmarked;
    setBookmarkedList([...list]);
  };

  const responseLikeDislike = (item, index, likeDislike) => {
    let data = {
      BookMark: {
        ...item,
        LikedDisliked: likeDislike,
      },
    };
    dispatch(
      youuniverseChatAttributeUpdate({
        JSON: JSON.stringify(data),
      }),
    );
    let list = bookmarkedList;
    list[index].LikedDisliked = likeDislike;
    setBookmarkedList([...list]);
  };
  const askFollowupQuestion = bookmark => {
    dispatch(askFollowUpInitiate(bookmark));
  };
  const askAgain = bookmark => {
    let data = {
      UserQuery: bookmark.UserQuery,
      ConversationGuid: null,
    };
    setLoader(true);
    dispatch(youuniverseChatPost(data));
  };
  return (
    <div className="bookmarks chatbot ask-you-universe">
      <div className="row chat-message-container" style={{ padding: '15px' }}>
        {loader || isBookFetching ? (
          <AILoader2 loading={loader} />
        ) : (
          <div className="col-12">
            {bookmarkedList &&
              bookmarkedList.map((bookmark, index) => (
                <div className="bookmark-row">
                  {bookmark.UserQuery && (
                    <div className="col-12 px-0">
                      <div className="user-query">
                        <div className="ask-again">
                          <button
                            className="btn btn-outline-primary py-1 px-3 d-flex align-items-center"
                            onClick={() => {
                              askAgain(bookmark);
                            }}
                          >
                            <img src="images/icons/askagain.svg" className="pr-1" />
                            Ask Again
                          </button>
                        </div>
                        <UserQuery
                          QueryBookmark={queryBookmark}
                          QueryData={bookmark}
                          Index={index}
                        />
                      </div>
                    </div>
                  )}
                  {bookmark.Response && (
                    <div className="col-12 px-0">
                      <div className="response">
                        <div className="youu-chat-messages">
                          <Response
                            ResponseBookmark={responseBookmark}
                            ResponseLikeDislike={responseLikeDislike}
                            ResponseData={bookmark}
                            Index={index}
                            IsShowAction={false}
                          />
                        </div>
                      </div>
                      <div className="col-12 text-right mt-2">
                        <div className="ask-again ">
                          <button
                            className="btn btn-outline-primary py-1 px-3"
                            onClick={() => {
                              askFollowupQuestion(bookmark);
                            }}
                          >
                            Ask a follow-up question
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Bookmarks;
