import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { resetLogin, logOut } from '../../store/actions';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { LIST_ROUTES } from '../../store/menuConfig';

class Menu extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      menu: true,
      menus: [],
      centerName: '',
      userName: '',
    };
    var menu = localStorage.getItem('dr:menu');
    if (menu !== undefined) {
      this.state.menus = JSON.parse(menu);
    }

    localStorage.setItem('isWebCam', 1);

    /*if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      localStorage.setItem('isWebCam', 0);
      //alert(1);
      return;
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then(function(devices) {
        devices.forEach(function(device) {
          if (device.kind === 'videoinput') {
            localStorage.setItem('isWebCam', 1);
            //alert(2);
            return;
          }
        });
        localStorage.setItem('isWebCam', 0);
        return;
      })
      .catch(function(err) {
        localStorage.setItem('isWebCam', 0);
        //alert(3);
      });*/
  }

  onLogOut() {
    this.props.dispatch(resetLogin);
    this.props.dispatch(logOut({}));
    localStorage.clear();
    //this.props.history.push('/login');
    window.location.href = '/';
    console.log('master menu=>logout executed');
  }

  openMenu = () => {
    if (this.state.menu) {
      document.getElementById('menu').classList.add('pushmenu-push-toright');
      document.getElementById('nav_list').classList.add('active');
      document.getElementById('navs').classList.add('pushmenu-open');
    } else {
      document.getElementById('menu').classList.remove('pushmenu-push-toright');
      document.getElementById('nav_list').classList.remove('active');
      document.getElementById('navs').classList.remove('pushmenu-open');
    }
    let menu = !this.state.menu;
    this.setState({ menu: menu });
  };
  closeMenu = () => {
    document.getElementById('menu').classList.remove('pushmenu-push-toright');
    document.getElementById('nav_list').classList.remove('active');
    document.getElementById('navs').classList.remove('pushmenu-open');
    this.setState({ menu: true });
  };

  selectMenu = action => {
    if (action == 'onLogOut') {
      this.onLogOut();
    }
  };

  componentDidMount() {
    const me = this;
    var menu = localStorage.getItem('dr:menu');
    if (menu !== undefined) {
      me.setState({
        menus: JSON.parse(menu),
        userName: localStorage.getItem('userName'),
        centerName: localStorage.getItem('centerName'),
      });
    } /* else {
      Axios.post('user/ListMenu', {}).then(response => {
        localStorage.setItem('userName', response.data.UserName);
        localStorage.setItem('centerName', response.data.CenterName);
        me.setState({
          menus: response.data.data,
          userName: response.data.UserName,
          centerName: response.data.CenterName,
        });
      });
    }*/
  }

  render() {
    const { menus, userName, centerName } = this.state;
    return (
      <header>
        <div className="header-right">
          <div className="logo-wrap">
            <Link className="" to={`${this.props.defaultDashboard}`}>
              <img src="images/logo.png" alt="Barcelona Bootcamp" width="180" />
            </Link>
          </div>
          <div id="nav_list" className="menu-mobile-icon visible-xs" onClick={this.openMenu}>
            <i className="fa fa-bars" aria-hidden="true" />
            {'    '}Menu
          </div>
          <div
            id="navs"
            className="main-navigation pushmenu pushmenu-left"
            onClick={this.closeMenu}
          >
            <nav id="nav" className="nav">
              <ul className="clearfix">
                {menus.map(menuItem => {
                  if (menuItem.Text) {
                    return (
                      <li>
                        <Link to={menuItem.Path}>{menuItem.Text}</Link>
                      </li>
                    );
                  } else if (menuItem.IconCls && menuItem.Path) {
                    return (
                      <li className="light-gray">
                        <Link to={menuItem.Path}>
                          <i className={menuItem.IconCls} aria-hidden="true" />
                        </Link>
                      </li>
                    );
                  } else if (menuItem.IconCls && menuItem.Action) {
                    return (
                      <>
                        <li className="light-red">
                          <a onClick={this.selectMenu.bind(null, menuItem.Action)}>
                            <i className="fa fa-power-off" aria-hidden="true" />
                          </a>
                        </li>
                        <li class="menu-side-heading">
                          <span style={{ color: '#31ABE2' }}>{userName}</span>
                          <br /> {centerName}
                        </li>
                      </>
                    );
                  }
                })}
              </ul>
            </nav>
          </div>

          <div className="clearfix" />
        </div>
        <div className="clearfix" />
      </header>
    );
  }
  render_old() {
    return (
      <header>
        <div className="header-right">
          <div className="logo-wrap">
            <Link className="" to="/dashboard/inHouse">
              <img src="images/logo.png" alt="Barcelona Bootcamp" width="180" />
            </Link>
          </div>
          <div id="nav_list" className="menu-mobile-icon visible-xs" onClick={this.openMenu}>
            <i className="fa fa-bars" aria-hidden="true" />
            {'    '}Menu
          </div>
          <div
            id="navs"
            className="main-navigation pushmenu pushmenu-left"
            onClick={this.closeMenu}
          >
            <nav id="nav" className="nav">
              <ul className="clearfix">
                {/* <!--<li><a className="child-menu">demo</a>
                            <ul>
                              <li><a href="#">sub</a></li>
                              <li><a href="#">sub</a></li>
                               
                            </ul>
                          </li>-->   */}
                <li>
                  <Link to="/students">Students</Link>
                </li>
                <li>
                  <Link to="/surveyer">Surveys</Link>
                </li>
                <li>
                  <Link to="/report">Reports</Link>
                </li>
                <li>
                  <Link to="/manage">Admin</Link>
                </li>

                <li className="light-gray">
                  <a className="dropdown-item" href="https://support.soberpeer.com" target="_blank">
                    <i className="fa fa-question-circle-o" aria-hidden="true" />
                  </a>
                </li>
                <li className="light-gray">
                  <Link to="/settings">
                    <i className="fa fa-cog" aria-hidden="true" />
                  </Link>
                </li>
                <li className="light-red">
                  <a onClick={this.onLogOut.bind(this)}>
                    <i className="fa fa-power-off" aria-hidden="true" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="clearfix" />
        </div>
        <div className="clearfix" />
      </header>
    );
  }
}

export default withRouter(connect()(Menu));
