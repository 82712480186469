import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import AntdModal from '../../../Components/CustomModal';
import CustomModal from '../../../core/modal';
import AugmentedSummary from '../AugmentedSummary';
import RiskChart from '../RiskChart';
import RiskChartLegacy from '../RiskChartLegacy';
import ClientScreeningCard from '../ClientScreeningCard';
import UpdateHistory from '../UpdateHistory';
import Banner from '../Banner';
import SetReminderModal from './SetReminderModal';
import { CommonLicenceScreens } from '../../licenceScreens';
import TimeTravel from '../../timelineComponent';
import { Tabs, Modal, Popover, Drawer, Tooltip } from 'antd';
import { prospectArchive, resetProspectArchive } from '../../../store/actions';
import AddProspectNote from './AddNoteModal';
import PageTitle from '../../../Components/PageTitle';

class PreIntakeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAugmentedAIDrawerOpen: false,
      isNoteAddModal: false,
    };
  }
  getIntakeId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('id') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }

  moveToIntake = () => {
    let data = {
      Json: JSON.stringify({
        Student: [
          {
            StudentId: this.getIntakeId(),
            EnrollStatusId: 0,
          },
        ],
      }),
    };
    Axios.post('Student/StudentEnrollStatusUpdate', data)
      .then(response => {
        if (response.data.success) {
          this.props.history.replace(`/enrollment/?studentid=${this.getIntakeId()}`);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  toggleDrawer = () => {
    this.setState({ isAugmentedAIDrawerOpen: !this.state.isAugmentedAIDrawerOpen });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ AugmentedAIStatusData, isArchiveSuccess, archiveSuccessMsg }) {
    if (
      AugmentedAIStatusData &&
      JSON.stringify(AugmentedAIStatusData) != JSON.stringify(this.props.AugmentedAIStatusData)
    ) {
      this.setState({ AugmentedAIStatusData });
    }
    if (isArchiveSuccess && isArchiveSuccess != this.props.isArchiveSuccess) {
      this.toggle(archiveSuccessMsg);
      this.props.dispatch(resetProspectArchive());
      // this.props.history.push(`/clients/pre-intake`);   // based on requirement keeping on same page
    }
  }
  toggleReminder = (header, isSuccess) => {
    this.setState(prevState => ({
      isSetReminderModal: !prevState.isSetReminderModal,
      header,
      isSetReminderSuccess: isSuccess,
    }));
  };
  render() {
    const { isNoteAddModal, isSetReminderModal } = this.state;
    const { PreItakeSurveyId, IsPreIntakeArchived } = this.props;
    const content = (
      <div
        className="sort-by-list"
        onClick={() => {
          this.setState({
            isVisible: false,
          });
        }}
      >
        <p
          onClick={() => {
            this.props.history.push(
              `/pre-intake-form?step=demographics&studentid=${this.getIntakeId()}`,
            );
          }}
        >
          Edit Details
        </p>
        {/* <AntdModal
          ModalButton={({ onClick }) => (
            <p onClick={onClick} data-target="#move-intake-model">
              Move to Intake
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 className="modal-title col-12 mb-3">
                  Are you sure you want to move to Intake?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    onOk();
                    this.moveToIntake();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        /> */}
        <p
          onClick={() => {
            this.setState({
              isNoteAddModal: true,
            });
          }}
        >
          Add Note
        </p>
        <p
          onClick={() => {
            this.setState({
              isSetReminderModal: true,
            });
          }}
        >
          Set Reminder
        </p>

        <AntdModal
          ModalButton={({ onClick }) => (
            <p class="" onClick={onClick}>
              {IsPreIntakeArchived ? 'Restore' : 'Archive'}
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div className="modal-content border-0">
              <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
                  &times;
                </button>
                <h4 className="modal-title col-12">
                  Are you sure you want to {IsPreIntakeArchived ? 'restore' : 'archive'} this
                  prospect?
                </h4>
              </div>
              <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  type="button"
                  className="btn btn-outline-primary py-1"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn py-1"
                  onClick={() => {
                    onOk();
                    this.props.dispatch(
                      prospectArchive({
                        Json: JSON.stringify({
                          Student: [
                            {
                              StudentId: this.getIntakeId(),
                              Archive: !IsPreIntakeArchived,
                            },
                          ],
                        }),
                      }),
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
      </div>
    );
    return (
      <React.Fragment>
        <PageTitle TitleText="Prospect" />
        <div className="main students-page mb-5">
          <div className="container-fluid">
            <div className="row top-table-search align-items-center">
              <div className="col-12 col-md-4 col-lg-4">
                <a className="back-link" onClick={() => this.props.history.goBack()}>
                  <img src="images/back-arrow.svg" /> Back
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-4">
                <span className="title">Prospect Details</span>
              </div>
              <div className="col-sm-12 col-lg-8">
                <div className="d-flex justify-content-end">
                  <Popover
                    overlayClassName="alert-box-style"
                    content={content}
                    title={false}
                    trigger="hover"
                    placement="bottom"
                    class="alert-sort"
                    open={this.state.isVisible}
                    onOpenChange={visible => {
                      this.setState({
                        isVisible: visible,
                      });
                    }}
                  >
                    <button
                      className="btn btn-outline-primary mr-2"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      More <img src="images/icons/3dot_blue.svg" className="mx-2" />
                    </button>
                  </Popover>

                  {/* <AntdModal
                    modalWidth={800}
                    // style={{ top: 20 }}
                    ModalButton={({ onClick }) => (
                      <button
                        data-target="#set-reminder-model"
                        className="btn btn-outline-primary mr-2"
                        onClick={() => {
                          onClick();
                        }}
                      >
                        Set Reminder
                      </button>
                    )}
                    ModalContent={props => (
                      <SetReminderModal
                        {...props}
                        ClientId={this.getIntakeId()}
                        toggle={this.toggle}
                        SurveyId={PreItakeSurveyId}
                      />
                    )}
                  /> */}
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <button
                        className="btn btn-outline-primary mr-2"
                        data-target="#move-intake-model"
                        onClick={onClick}
                      >
                        Move to Intake
                      </button>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 className="modal-title col-12 mb-3">
                            Are you sure you want to Move to Intake?
                          </h4>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              onOk();
                              this.moveToIntake();
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-4 pt-2">
                <Banner ClientId={this.getIntakeId()} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                {this.state.AugmentedAIStatusData && (
                  <CommonLicenceScreens
                    StatusData={this.state.AugmentedAIStatusData}
                    moduleName={'AugmentedAI'}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <>
                  {this.state.AugmentedAIStatusData &&
                    this.state.AugmentedAIStatusData.LicenseStatusId != 1 &&
                    this.state.AugmentedAIStatusData.LicenseStatusId != 3 &&
                    this.state.AugmentedAIStatusData.LicenseStatusId != 5 && (
                      <>
                        <AugmentedSummary
                          ClientId={this.getIntakeId()}
                          SurveyId={PreItakeSurveyId}
                        />
                      </>
                    )}
                </>
              </div>

              <RiskChartLegacy ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />

              {/* <div className="col-sm-12">
                <RiskChart ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />
              </div> */}

              <div className="col-sm-12">
                <ClientScreeningCard
                  ClientId={this.getIntakeId()}
                  SurveyId={PreItakeSurveyId}
                  history={this.props.history}
                />
              </div>
              {/* <div className="col-sm-12">
                <UpdateHistory ClientId={this.getIntakeId()} SurveyId={PreItakeSurveyId} />
              </div> */}
              <div className="prospect-time-travel">
                <TimeTravel
                  hideHeader={true}
                  ProspectTimeline={true}
                  StudentId={this.getIntakeId()}
                  SurveyId={PreItakeSurveyId}
                />
              </div>
            </div>
          </div>
        </div>
        {isNoteAddModal && (
          <Modal visible={isNoteAddModal} footer={null} closable={false} destroyOnClose={true}>
            <AddProspectNote
              StudentId={this.getIntakeId()}
              onCancel={() => {
                this.setState({
                  isNoteAddModal: false,
                });
              }}
              NoteTypeIds={181} //181 is for prospect Note
              IsProspectNote={true}
            />
          </Modal>
        )}
        {isSetReminderModal && (
          <Modal
            visible={isSetReminderModal}
            footer={null}
            closable={false}
            destroyOnClose={true}
            width={800}
            style={{ top: 20 }}
          >
            <SetReminderModal
              ClientId={this.getIntakeId()}
              toggle={this.toggleReminder}
              SurveyId={PreItakeSurveyId}
              onCancel={() => {
                this.setState({
                  isSetReminderModal: false,
                });
              }}
            />
          </Modal>
        )}
        <CustomModal
          isOpen={this.state.isSetReminderSuccess}
          header={this.state.header}
          yesAction={() => {
            this.setState({
              isSetReminderSuccess: false,
              header: '',
            });
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { student_banner, user_feature_flag_get, prospect_archive } = state;
  const { data, isFetching, error } = student_banner;
  const { data: fFData } = user_feature_flag_get;
  const { data: archiveData } = prospect_archive;

  let PreItakeSurveyId = null;
  let EnrollStatusId = null;
  let IsPreIntakeArchived = false;
  let AugmentedAIStatusData = null;
  let isArchiveSuccess = false;
  let archiveSuccessMsg = null;

  if (data) {
    PreItakeSurveyId = data.PreItakeSurveyId;
    EnrollStatusId = data.EnrollStatusId;
    IsPreIntakeArchived = data.IsPreIntakeArchived;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  if (archiveData && archiveData.success) {
    isArchiveSuccess = true;
    archiveSuccessMsg = archiveData.data && JSON.parse(archiveData.data)[0].Msg;
  }
  return {
    PreItakeSurveyId,
    IsPreIntakeArchived,
    EnrollStatusId,
    AugmentedAIStatusData,
    isArchiveSuccess,
    archiveSuccessMsg,
  };
};

export default connect(mapStateToProps)(withRouter(PreIntakeDashboard));
