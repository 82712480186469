import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import moment from 'moment';
import { Table, Button } from 'antd';
import { PlusCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import { timeConvert } from '../../../../../util/time';
import { currencyFormatter, numberFormat } from '../../../../../util/formatter';

import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  ReduxTimePicker,
  CheckboxGroup,
} from '../../../../../component/custom-redux-components';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import { ProcessCombos } from '../../../../../store/comboConfig';
import {
  upsertServiceLine,
  listServiceLine,
  getServiceLineCost,
  getServiceLine,
  medicateCombos,
  upsertGroupServiceLine,
} from '../../../../../store/actions';

class ClaimsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdowns: {
        first: [
          {
            Value: 1,
            Text: 'Medicare',
          },
          {
            Value: 2,
            Text: 'Medicaid',
          },
          {
            Value: 3,
            Text: 'Tricare',
          },
          {
            Value: 4,
            Text: 'Champva',
          },
          {
            Value: 5,
            Text: 'Group Health Plan',
          },
          {
            Value: 6,
            Text: 'Feca Blklung',
          },
          {
            Value: 7,
            Text: 'Other',
          },
        ],
        sex: [
          {
            Value: 1,
            Text: 'Male',
          },
          {
            Value: 2,
            Text: 'Female',
          },
        ],
        relationship: [
          {
            Value: 1,
            Text: 'Self',
          },
          {
            Value: 2,
            Text: 'Spouse',
          },
          {
            Value: 3,
            Text: 'Child',
          },
          {
            Value: 4,
            Text: 'Other',
          },
        ],
      },
    };
  }
  componentDidMount() {}

  render() {
    const { first, sex } = this.state.dropdowns;
    return (
      <div className="px-1">
        <div>
          {/* <Loader2 loading={isFetching} /> */}
          {/* <div
            onClick={() => {
              toggleView();
            }}
            className="text-left"
            style={{ cursor: 'pointer' }}
          >
            <img src="images/back-arrow.svg" /> Back to Bundled service
          </div> */}
        </div>
        <div>
          <form onSubmit={values => {}}>
            <div className="d-flex align-items-center">
              <div className="claimIndex">1.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group">
                      <Field
                        name={`insuranceCategory`}
                        type="text"
                        component={ReduxSelect}
                        options={this.state.dropdowns.first}
                        placeholder="Insurance Category"
                        fieldName="insurance category"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">1a.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">
                        Insured’s I.D. Number (For Program in Item 1)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">2.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Patient’s Last Name</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Patient’s First Name</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Patient’s Middle Initial</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">3.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`patientDOB`}
                        type="text"
                        component={ReduxDateInput}
                        fieldName={'Patient’s date of birth'}
                        label="Patient’s date of birth"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <Field
                        name={`patientSex`}
                        type="text"
                        component={ReduxSelect}
                        options={sex}
                        placeholder="Sex"
                        fieldName="patient sex"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">4.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Insured’s Last Name</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Insured’s First Name</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Insured’s Middle Initial</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">5.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Patient’s Address (No.)</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Patient’s Address (Street)</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">City</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">&nbsp;&nbsp;</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">State/Province/Territories</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Postal/Zip Code</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Telephone (Include Area Code)</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">6.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group">
                      <Field
                        name={`insuranceCategory`}
                        type="text"
                        component={ReduxSelect}
                        options={this.state.dropdowns.first}
                        placeholder="Patient’s relationship to insured"
                        fieldName="insurance category"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">7.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Insured’s Address (No.)</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Insured’s Address (Street)</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">City</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">&nbsp;&nbsp;</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">State/Province/Territories</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Postal/Zip Code</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Telephone (Include Area Code)</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">8.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Reserved for NUCC Use</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">9.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Other Insured’s Last Name</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Other Insured’s First Name</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Other Insured’s Middle Initial</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">14.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-5">
                    <div class="form-group w-100">
                      <Field
                        name={`patientDOB`}
                        type="text"
                        component={ReduxDateInput}
                        fieldName={'Patient’s date of birth'}
                        label="Date of current illness, injury, or pregnancy (lmp)"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <Field
                        name={`Qualifier`}
                        type="text"
                        component={ReduxInput}
                        // placeholder="Qualifier"
                        fieldName="QUAL."
                      />
                      <label class="placeholder-label">Qualifier</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">15.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-5">
                    <div class="form-group w-100">
                      <Field
                        name={`patientDOB45`}
                        type="text"
                        component={ReduxDateInput}
                        fieldName={'Patient’s date of birth'}
                        label="Other Date"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <Field
                        name={`Qualifier`}
                        type="text"
                        component={ReduxInput}
                        // placeholder="Qualifier"
                        fieldName="QUAL."
                      />
                      <label class="placeholder-label">Qualifier</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">17.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">
                        Name of Referring Provider or Other Source
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">17a.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Write here</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">17b.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">NPI</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">19.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">
                        Additional Claim Information(Designated by NUUC)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">20.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxSelect}
                        options={first}
                        fieldName={'insured ID Number'}
                        placeholder={'Outside lab?'}
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Charges</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">22.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Resubmission Code</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Original Ref. No.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">23.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Prior Authorization Number</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">25.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Federral Tax I.D. Number</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">26.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Pateint’s Account Number</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">27.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group">
                      <Field
                        name={`insuranceCategory`}
                        type="text"
                        component={ReduxSelect}
                        options={this.state.dropdowns.first}
                        placeholder="Accept assignement? (for govt. claims )"
                        fieldName="insurance category"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div className="claimIndex">28.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Total Charge</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">29.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Amount Paid</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">30.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Rsvd for NUUC Use</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">31.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  Signature of Physician or Supplier including Degrees or Credentials (i certify
                  that the statements on the reverse apply to thsi bill and are made a part thereof)
                </div>
                <div className="row d-flex align-items-center">
                  <div class="col-4">
                    <div class="form-group w-100">
                      <Field
                        name={`patientDOB45`}
                        type="text"
                        component={ReduxDateInput}
                        fieldName={'Patient’s date of birth'}
                        label="Date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div className="claimIndex">32.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-12">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Service facility location information</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-12">
                    <div className="d-flex align-items-center">
                      <div className="claimIndex">a.</div>
                      <div className="question">
                        {' '}
                        <div className="row d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group w-100">
                              <Field
                                name={`insuredIDNumber`}
                                type="text"
                                component={ReduxInput}
                                fieldName={'insured ID Number'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-12">
                    <div className="d-flex align-items-center">
                      <div className="claimIndex">b.</div>
                      <div className="question">
                        {' '}
                        <div className="row d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group w-100">
                              <Field
                                name={`insuredIDNumber`}
                                type="text"
                                component={ReduxInput}
                                fieldName={'insured ID Number'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">33.</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-12">
                    <div class="form-group w-100">
                      <Field
                        name={`insuredIDNumber`}
                        type="text"
                        component={ReduxInput}
                        fieldName={'insured ID Number'}
                      />
                      <label class="placeholder-label">Billing provider info. & PH No.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-12">
                    <div className="d-flex align-items-center">
                      <div className="claimIndex">a.</div>
                      <div className="question">
                        {' '}
                        <div className="row d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group w-100">
                              <Field
                                name={`insuredIDNumber`}
                                type="text"
                                component={ReduxInput}
                                fieldName={'insured ID Number'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="claimIndex">&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="question">
                {' '}
                <div className="row d-flex align-items-center">
                  <div class="col-12">
                    <div className="d-flex align-items-center">
                      <div className="claimIndex">b.</div>
                      <div className="question">
                        {' '}
                        <div className="row d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group w-100">
                              <Field
                                name={`insuredIDNumber`}
                                type="text"
                                component={ReduxInput}
                                fieldName={'insured ID Number'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 mt-2 px-5 text-right">
          <input type="submit" class="btn px-5" value={'submit'} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { combos } = state;
  return {};
};
const ReduxClaimsForm = reduxForm({
  form: 'claimsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ClaimsForm);
export default connect(mapStateToProps)(withRouter(ReduxClaimsForm));
