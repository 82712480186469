import React, { useState, useEffect } from 'react';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import { Radio } from 'antd';
import { connect } from 'react-redux';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
  ReduxPlusMinusInputWithRange,
  ReduxRadio,
} from '../../../../component/custom-redux-components';
import validate from '../../../../core/generic-form/validation';
import { currencyFormatter, numberFormat } from '../../../../util/formatter';
export const CalculateMaterialCost = props => {
         const [total, setTotal] = useState(0);
         const { fields, CenterConsumable, ConsumableFormData, setTotalConsumableCost } = props;

         const handleTotalChange = () => {
           if (ConsumableFormData.some(item => item && item.Consumable != undefined)) {
             let result = ConsumableFormData.map((Item, Index) => {
               let filterV;
               let consumablePriceValue;
               if (Item != undefined && Item.Consumable != undefined) {
                 filterV = CenterConsumable.filter(option => {
                   return option.Value == Item.Consumable;
                 });
                 consumablePriceValue = filterV.length > 0 && filterV[0].Price * Item.UnitNeeded;
               }
               return consumablePriceValue;
             });

             let newTotal =
               result.filter(item => item).length > 0 &&
               result
                 .filter(item => item)
                 .reduce((p, c) => {
                   return p + c;
                 });
             if (!isNaN(newTotal)) {
               setTotal(Number(newTotal));
               setTotalConsumableCost(Number(newTotal));
             }
           } else {
             setTotal(0);
             setTotalConsumableCost(0);
           }
         };
         const getConsumablePrice = (ConsumableFormData, index) => {
           if (ConsumableFormData.some(item => item && item.Consumable != undefined)) {
             if (
               CenterConsumable &&
               CenterConsumable.filter(item => {
                 if (
                   ConsumableFormData[index] &&
                   item.Value == ConsumableFormData[index].Consumable
                 ) {
                   return item.Value == ConsumableFormData[index].Consumable;
                 }
               }).length > 0
             ) {
               return (
                 CenterConsumable.filter(item => {
                   if (
                     ConsumableFormData[index] &&
                     item.Value == ConsumableFormData[index].Consumable
                   ) {
                     return item.Value == ConsumableFormData[index].Consumable;
                   }
                 })[0].Price * ConsumableFormData[index].UnitNeeded
               );
             }
           } else {
             return '';
           }
         };
         useEffect(() => {
           handleTotalChange();
         }, [ConsumableFormData]);
    // useEffect(() => {
    //   fields.push();
    // }, []);

         return (
           <div className="row">
             <div className="col-12 col-md-12 col-lg-12 my-3">
               <div style={{ background: '#F8F8F8' }} className="p-2">
                 <div className="d-flex justify-content-between p-2 mx-3">
                   <h6
                     className="d-flex align-items-center"
                     style={{
                       fontWeight: '600',
                       margin: '0px',
                     }}
                   >
                     Materials Cost Details
                   </h6>
                   <div>
                     <button
                       type="button"
                       class="btn-newCon"
                       style={{
                         position: 'unset',
                         width: 'unset',
                       }}
                       onClick={() => fields.push({ MaterialDeliveryOption: 'Sell Material' })}
                     >
                       + Add {fields.length == 0 ? 'Material' : 'Another Material'}
                     </button>
                   </div>
                 </div>

                 {fields.length > 0 ? (
                   fields.map((item, index) => (
                     <div class="mx-4">
                       {' '}
                       <div class="row p-2">
                         <div key={index} className="col-12 field-array-item">
                           <div
                             className="row p-3"
                             style={{
                               background: '#fff',
                               border: '1px solid #EAEAEA',
                               borderRadius: '4px',
                             }}
                           >
                             <div className="col-6">
                               <div class="form-group">
                                 <Field
                                   name={`${item}Consumable`}
                                   type="text"
                                   errorMessage="State Required"
                                   component={ReduxSelect}
                                   options={CenterConsumable}
                                   validate={validate.required}
                                   placeholder={'Select Material*'}
                                   fieldName={'Material'}
                                   class="form-control"
                                 />
                                 {/* <label class="placeholder-label">Select Consumable</label> */}
                                 {/* <span class="small-info">Provide a specific list of goal(s) to be achieved</span> */}
                               </div>

                               {ConsumableFormData &&
                                 ConsumableFormData[index] &&
                                 ConsumableFormData[index].Consumable && (
                                   <div
                                     class="license-quantity d-flex  justify-content-left"
                                     style={{
                                       fontWeight: '600',
                                     }}
                                   >
                                     <label
                                       class="placeholder-label flex-grow-1"
                                       style={{
                                         fontSize: '16px',
                                       }}
                                     >
                                       Total Unit Needed*
                                       <div
                                         style={{
                                           fontSize: '14px',
                                           color: '#7F8188',
                                         }}
                                       >
                                         {ConsumableFormData.some(
                                           item => item && item.Consumable != undefined,
                                         )
                                           ? CenterConsumable &&
                                             CenterConsumable.filter(item => {
                                               if (
                                                 ConsumableFormData[index] &&
                                                 item.Value == ConsumableFormData[index].Consumable
                                               ) {
                                                 return (
                                                   item.Value ==
                                                   ConsumableFormData[index].Consumable
                                                 );
                                               }
                                             }).length > 0 &&
                                             CenterConsumable.filter(item => {
                                               if (
                                                 ConsumableFormData[index] &&
                                                 item.Value == ConsumableFormData[index].Consumable
                                               ) {
                                                 return (
                                                   item.Value ==
                                                   ConsumableFormData[index].Consumable
                                                 );
                                               }
                                             })[0].UnitOfMeasureText
                                           : ''}
                                       </div>
                                     </label>

                                     <div class="">
                                       <Field
                                         name={`${item}UnitNeeded`}
                                         type="number"
                                         errorMessage="State Required"
                                         component={ReduxPlusMinusInputWithRange}
                                         validate={validate.required}
                                         // fieldName={'Unit Needed'}
                                         fieldName={'Unit'}
                                         className="number-icon"
                                         min={0}
                                         max={100}
                                         style={{
                                           textAlign: 'center',
                                           margin: '0px 5px',
                                           height: '32px',
                                           width: '56px',
                                           fontSize: '16px',
                                         }}
                                       />
                                     </div>
                                   </div>
                                 )}
                             </div>
                             <div className="col-6 text-right">
                               <div className="row">
                                 <div className="col-12 mt-3 mb-2">
                                   <a
                                     class="col-1 delete-contract"
                                     onClick={() => {
                                       if (fields.length && fields.length > 0) {
                                         fields.remove(index);
                                       }
                                     }}
                                   >
                                     <i class="fas fa-trash" /> Delete
                                   </a>
                                 </div>
                                 {ConsumableFormData &&
                                 ConsumableFormData[index] &&
                                 ConsumableFormData[index].UnitNeeded ? (
                                   <div className="col-12 pt-5">
                                     {' '}
                                     <span
                                       class="p-2"
                                       style={{
                                         background: '#E8E8E8',
                                         color: '#7F8188',
                                         display: 'inline-block',
                                         width: '190px',
                                         textAlign: 'center',
                                       }}
                                     >
                                       Material Cost:{' '}
                                       <span
                                         style={{
                                           color: '#000',
                                           fontWeight: '700',
                                         }}
                                       >
                                         {currencyFormatter.format(
                                           getConsumablePrice(ConsumableFormData, index) || 0,
                                         )}
                                       </span>
                                     </span>
                                   </div>
                                 ) : (
                                   ''
                                 )}
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   ))
                 ) : (
                   <div class="row">
                     {/* <div className="col-9 field-array-item">
            <div class="form-group">
              <h6>Consumable Cost Details</h6>
            </div>
          </div> */}
                   </div>
                 )}
                 {total ? (
                   <div
                     className="d-flex justify-content-between mx-3 mt-2 mb-2"
                     style={{
                       fontSize: '16px',
                       fontWeight: '600',
                       background: '#E8E8E8',
                       borderRadius: '4px',
                     }}
                   >
                     <div
                       class="mx-3 px-3 p-2 my-2"
                       style={{
                         fontWeight: '600',
                       }}
                     >
                       Total Materials Cost
                     </div>
                     <div
                       class="mx-3 px-3 p-2 my-2"
                       style={{
                         fontSize: '18px',
                         fontWeight: '700',
                       }}
                     >
                       {currencyFormatter.format(total || 0)}
                     </div>
                   </div>
                 ) : (
                   <></>
                 )}
               </div>
             </div>
           </div>
         );
       };
