import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { tokenManagementStatsGet } from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import TokenAnalytics from './tokenAnalytics';
import TokenStatementList from './tokenStatement';
import PageTitle from '../../Components/PageTitle';
class Token extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: null,
    };
  }
  componentDidMount() {
    this.props.dispatch(
      tokenManagementStatsGet({
        JsonInput: JSON.stringify({
          TokenManagement: {
            IsDashboard: true,
          },
        }),
      }),
    );
  }
  render() {
    const { isFetching, tokenList, tokenStats } = this.props;
    const { Header, Graph, TokenStatement } = tokenStats;
    return (
      <div class="main mb-5">
        <div class="container-fluid token-management">
          <PageTitle TitleText="Token" />
          <div class="row top-table-search mb-1 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <Link to="/manage" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link>
            </div>
          </div>
          <div className="row mb-2">
            <div class="col-12">
              <TokenAnalytics Header={Header && Header[0]} Graph={Graph} />
            </div>
          </div>
          <div className="row mt-2">
            {TokenStatement && (
              <div class="col-12">
                <TokenStatementList TokenStatement={TokenStatement} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ token_management_stats_get }) => {
  const { data: tokenStatsData, isFetching, error } = token_management_stats_get;
  let tokenStats = {};
  if (tokenStatsData && tokenStatsData.TokenManagement) {
    tokenStats = tokenStatsData.TokenManagement[0];
  }

  return {
    isFetching,
    tokenStats,
  };
};

export default connect(mapStateToProps)(Token);
