import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate from '../validation/validate';
import renderField from '../reduxFormComponent/renderField';
import {
  ReduxSelect,
  ReduxInput,
  ReduxToggle,
  ReduxDateInput,
  ReduxMonthYearInput,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';

const PreviousTreatment = ({
  fields,
  name,
  meta: { touched, error },
  CenterProgramType,
  CenterProgramTypeAll,
  existingRows,
  disable,
}) => {
  return (
    <>
      {fields.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
                <h6 class="d-inline-flex mb-0 mr-3 ">Previous Treatment {index + 1}</h6>
              </div>
              <div class={`col-12 col-md-4`}>
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete Previous Treatment
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.programTypeId`}
                    component={ReduxSelect}
                    placeholder="Program Type*"
                    validate={validate.required}
                    options={CenterProgramTypeAll}
                    fieldName={'Program Type'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.startDate`}
                    component={ReduxDateInput}
                    label="Treatment Start Date*"
                    validate={validate.required}
                    fieldName={'Treatment Start Date'}
                    disabled={disable}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.endDate`}
                    component={ReduxDateInput}
                    validate={validate.endDate}
                    label="Treatment End Date*"
                    disabled={!existingRows[index].startDate}
                    fieldName={'Treatment End Date'}
                    // disabled={disable}
                  />
                  <i class="far fa-calendar-alt form-control-icon" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${item}.centerName`}
                    component={ReduxInput}
                    validate={validate.required}
                    fieldName={'Center Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Center Name*</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${item}.notes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" onClick={() => fields.push({})} disabled={disable}>
            {fields.length >= 1 && (
              <label>
                {' '}
                <a>+ Add Another Previous Treatment</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                <a>
                  {' '}
                  <a>+ Add Previous Treatment</a>
                </a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

const PreviousTreatments = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    CenterProgramType,
    CenterProgramTypeAll,
    HowManyDay,
    style,
    isAdditionTreatBeforeValue,
    existingRows,
    goToPreviuos,
    disable,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <div class="row mb-3">
        <div class="col-12 col-md-8 d-flex flex-wrap align-items-center ">
          <h6 class="d-inline-flex mb-0 mr-3 ">Have you been in treatment for addiction before?</h6>
          <div class="custom-control custom-switch d-inline-flex ">
            <Field name="isAdditionTreatBefore" component={ReduxToggle} disabled={disable} />
          </div>
        </div>
      </div>
      {isAdditionTreatBeforeValue && (
        <div class="row mb-4">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`howManyDay`}
                component={ReduxSelect}
                placeholder="How Many Times?*"
                validate={validate.required}
                options={HowManyDay}
                fieldName={'How Many times?'}
                disabled={disable}
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={`lastTreatmentDate`}
                component={ReduxMonthYearInput}
                label="Date of Last Treatment?*"
                validate={validate.required}
                fieldName={'Date of Last Treatment?'}
                disabled={disable}
              />
              <i class="far fa-calendar-alt form-control-icon" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-12">
            <div class="form-group">
              <Field
                name={`treatmentForAddictionNote`}
                type="textarea"
                component={ReduxTextarea}
                disabled={disable}
              />
              <label class="placeholder-label">Notes</label>
            </div>
          </div>
        </div>
      )}
      {isAdditionTreatBeforeValue && (
        <FieldArray
          name="previousTreatments"
          component={PreviousTreatment}
          CenterProgramType={CenterProgramType}
          CenterProgramTypeAll={CenterProgramTypeAll}
          existingRows={existingRows}
          disable={disable}
        />
      )}
      <div class="row pagination-button">
        <div class="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              class="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect((state, props) => {
  const { combos } = state;
  const existingRows = selector(state, 'previousTreatments');
  var isAdditionTreatBeforeValue = selector(state, 'isAdditionTreatBefore');
  return {
    CenterProgramType: combos.data.CenterProgramType,
    CenterProgramTypeAll: combos.data.CenterProgramTypeAll,
    HowManyDay: combos.data.HowManyDay,
    isAdditionTreatBeforeValue,
    existingRows,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(PreviousTreatments),
);

const selector = formValueSelector('wizard');
