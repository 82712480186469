import React, { Component } from 'react';
import { SparklinesLine, Sparklines } from 'react-sparklines';
import ListRenderer from '../Components/listRenderer';
import Loader from '../Components/Loader';
import {
  getDashboard,
  announcementGetAll,
  surveyTaskMarkInComplete,
  studentTaskMarkComplete,
  studentTaskUnAssign,
  studentMarkComplete,
  studentUnAssign,
  resetStudentTaskUnAssign,
  resetStudentTaskMarkComplete,
  resetStudentMarkComplete,
  resetStudentUnAssign,
  resetSurveyTaskMarkInComplete,
} from '../store/actions';
import { connect } from 'react-redux';
import { Typography, Tooltip } from 'antd';
import CustomModal from '../Components/CustomModal';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import moment from 'moment';

const { Paragraph } = Typography;

const activeKey = 0;
class InHouseOld extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 0, requestData: false };
  }

  componentDidMount() {
    this.props.dispatch(getDashboard());
    this.props.dispatch(announcementGetAll({ filter: null }));
  }

  onTaskInComplete = (studentId, surveyId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      surveyId,
    };
    this.props.dispatch(surveyTaskMarkInComplete(data));
  };

  onTaskComplete = (taskStudentId, studentId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      taskStudentId,
    };
    this.props.dispatch(studentTaskMarkComplete(data));
  };

  onTaskUnAssign = (studentId, taskStudentId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      taskStudentId,
    };
    this.props.dispatch(studentTaskUnAssign(data));
  };

  onUnAssign(studentId, courseCenterStudentId) {
    this.state.requestData = true;
    var data = {
      studentId,
      courseCenterStudentId,
    };

    this.props.dispatch(studentUnAssign(data));
  }

  onMarkComplete(studentId, courseCenterStudentId) {
    this.state.requestData = true;
    var data = {
      studentId,
      courseCenterStudentId,
    };

    this.props.dispatch(studentMarkComplete(data));
  }

  componentWillReceiveProps({
    isMarkComplete,
    isUnAssigned,
    isTaskMarkComplete,
    isTaskUnAssigned,
    isTaskMarkInComplete,
  }) {
    if (isTaskUnAssigned) this.props.dispatch(resetStudentTaskUnAssign());
    if (isTaskMarkComplete) this.props.dispatch(resetStudentTaskMarkComplete());
    if (isMarkComplete) this.props.dispatch(resetStudentMarkComplete());
    if (isUnAssigned) this.props.dispatch(resetStudentUnAssign());
    if (isTaskMarkInComplete) this.props.dispatch(resetSurveyTaskMarkInComplete());
    if (
      (isMarkComplete ||
        isUnAssigned ||
        isTaskMarkComplete ||
        isTaskUnAssigned ||
        isTaskMarkInComplete) &&
      this.state.requestData
    ) {
      this.state.requestData = false;
      this.props.dispatch(getDashboard());
    }
  }

  onAnnouncementClick = url => {
    return <Redirect push to={url} target="_blank" />;
  };

  getMulti = array => {
    for (let item of array) {
      item.count = 0;
      for (let checkItem of array) {
        if (item.StudentId == checkItem.StudentId) {
          item.count += 1;
        }
      }
    }
    let indexArray = [...new Set(array.map(item => item.StudentId))],
      newArray = [];
    for (let item of indexArray) {
      newArray.push(array.find(aItem => aItem.StudentId == item));
    }
    return newArray;
  };
  getDuration(fromdate) {
    var day = moment(moment().diff(fromdate));
    return `${day.month()} Month ${day.dates()} days`;
  }
  render() {
    const { activeTab } = this.state;
    const { dashboardData, announcementData, isFetching } = this.props;
    const { CourseStudent, SurveyStudent, TaskStudent, StudentContract } = dashboardData || {};
    if (dashboardData) {
      const { ChartData, DashboardBlocks } = dashboardData;
    }
    const list = [
      {
        Name: 'Tasks',
        Badge: TaskStudent && TaskStudent.length > 0 ? TaskStudent[0].TaskCount : '0',
        Component: props => {
          const {
            TodoType,
            TodoTitle,
            TaskId,
            TaskStudentId,
            StudentId,
            FirstName,
            LastName,
            ProfileImgAttachmentId,
            StudentImageUrl,
            AssignedDate,
            AssignedDateForSort,
            DueDate,
            DueDateForSort,
            DueDateFontColor,
          } = props;
          return (
            <div class="task-box dashboard-tabs">
              <div class="row no-gutters w-100 justify-content-between align-items-center">
                <div class="col-5 mb-1">
                  <div class="course-name" style={{ width: 'unset' }}>
                    <h6 className="text-capitalize">{TodoTitle}</h6>
                    <span class="due-date" style={{ minWidth: 'unset' }}>
                      <em>Due : </em>
                      {DueDate}
                    </span>
                  </div>
                </div>
                <div class="col-5 mb-1">
                  <div class="studentinfo">
                    <figure>
                      <img
                        src={StudentImageUrl}
                        class="rounded-circle face-fit-cover"
                        width="47"
                        height="47"
                        alt=""
                      />
                    </figure>
                    <span class="student-name">
                      {FirstName} {LastName}
                    </span>
                  </div>
                </div>
                <div class="col-2">
                  <div class="actions-button justify-content-xl-end">
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Un-assign">
                          <span class="delete-course" onClick={onClick}>
                            <i class="fas fa-times" />
                          </span>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              &times;
                            </button>
                            <h4 class="modal-title col-12">Do you want to un-assign this task?</h4>
                          </div>

                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              type="button"
                              class="btn black-btn"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn "
                              onClick={() => {
                                onOk();
                                this.onTaskUnAssign(StudentId, TaskStudentId);
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                    <CustomModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top" title="Complete">
                          <span class="select-course" onClick={onClick}>
                            <i class="select-icon" />
                          </span>
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              &times;
                            </button>
                            <h4 class="modal-title col-12">
                              Do you want to mark this task as complete?
                            </h4>
                          </div>

                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              type="button"
                              class="btn black-btn"
                              data-dismiss="modal"
                              onClick={onCancel}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              class="btn "
                              onClick={() => {
                                onOk();
                                this.onTaskComplete(TaskStudentId, StudentId);
                              }}
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: TaskStudent,
      },
      {
        Name: 'Surveys',
        Badge: SurveyStudent && SurveyStudent.length ? SurveyStudent[0].SurveyCount : '0',
        Component: props => {
          const {
            SurveyId,
            SurveyQuestionCategoryId,
            SurveyName,
            StudentId,
            AssignedByStaffId,
            AssignedDate,
            DueDate,
            StartedDate,
            DueStatus,
            FirstName,
            LastName,
            ProfileImgAttachmentId,
            StudentImageUrl,
            DueDateFontColor,
            count,
          } = props;
          return (
            <div class="task-box">
              <div class="row no-gutters w-100 justify-content-between align-items-center">
                <div class="col-12 col-md-5 mb-1">
                  <div
                    style={{ width: 'unset' }}
                    class="course-name"
                    onClick={() => {
                      this.props.history.push(
                        count == 1
                          ? '/validate?surveyId=' + SurveyId + '&studentId=' + StudentId
                          : `/surveyer/?studentId=${StudentId}`,
                      );
                    }}
                  >
                    <h6>{count > 1 ? 'Multiple Surveys(' + count + ')' : SurveyName}</h6>
                    <span class="due-date" style={{ minWidth: 'unset' }}>
                      <em>Due : </em> {DueDate}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-5 mb-1">
                  <div class="studentinfo">
                    <figure>
                      <img
                        src={StudentImageUrl}
                        class="rounded-circle face-fit-cover"
                        width="47"
                        height="47"
                        alt=""
                      />
                    </figure>
                    <span class="student-name">
                      {FirstName} {LastName}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <div class="actions-button justify-content-xl-end">
                    {count == 1 ? (
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title={'Incomplete'}>
                            <span class="delete-course" onClick={onClick}>
                              <i class="fas fa-times" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>

                              <h4 class="modal-title col-12">
                                Do you want to mark this survey as incomplete?
                              </h4>
                            </div>

                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                class="btn black-btn"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                class="btn "
                                onClick={() => {
                                  onOk();
                                  this.onTaskInComplete(StudentId, SurveyId);
                                }}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    ) : (
                      <span class="delete-course" style={{ visibility: 'hidden' }}>
                        <i class="fas fa-times" />
                      </span>
                    )}
                  </div>
                </div>
                {DueDateFontColor && <span class="alert-danger-dot" />}
              </div>
            </div>
          );
        },
        Content: SurveyStudent && this.getMulti(SurveyStudent),
      },
      {
        Name: 'Courses',
        Badge: CourseStudent && CourseStudent.length > 0 ? CourseStudent[0].CourseCount : '0',
        Component: props => {
          const {
            CourseCenterStudentId,
            CourseId,
            CenterId,
            StudentId,
            EnrollDate,
            DueDate,
            DueDateForSort,
            DueDateFontColor,
            CourseName,
            CourseDescription,
            StudentImageUrl,
            FirstName,
            LastName,
          } = props;
          return (
            <div class="course-box">
              <div class="row no-gutters w-100 justify-content-between mb-2">
                <div class="course-info w-75">
                  <h5
                    class="card-title mb-2 h5"
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  >
                    {CourseName}
                  </h5>
                  <Paragraph
                    ellipsis={{ rows: 2, expandable: false }}
                    textAlign="justify"
                    style={{ color: '#474747' }}
                  >
                    {CourseDescription}
                  </Paragraph>
                </div>
                <div class="actions-button justify-content-xl-end">
                  <CustomModal
                    ModalButton={({ onClick }) => (
                      <Tooltip placement="top" title="Un-assign">
                        <span class="delete-course" onClick={onClick}>
                          <i class="fas fa-times" />
                        </span>
                      </Tooltip>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            &times;
                          </button>
                          <h4 class="modal-title col-12">Are you sure you want to unassign?</h4>
                        </div>

                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            type="button"
                            class="btn black-btn"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="btn "
                            onClick={() => {
                              onOk();
                              this.onUnAssign(StudentId, CourseCenterStudentId);
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    )}
                  />
                  <CustomModal
                    ModalButton={({ onClick }) => (
                      <Tooltip placement="top" title="Complete">
                        <span class="select-course" onClick={onClick}>
                          <i class="select-icon" />
                        </span>
                      </Tooltip>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            &times;
                          </button>
                          <h4 class="modal-title col-12">Are you sure you want to Complete?</h4>
                        </div>

                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            type="button"
                            class="btn black-btn"
                            data-dismiss="modal"
                            onClick={onCancel}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="btn "
                            onClick={() => {
                              onOk();
                              this.onMarkComplete(StudentId, CourseCenterStudentId);
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div class="row no-gutters w-100 justify-content-between flex-nowrap">
                <div class="enroll-date course-date mb-1">
                  Enrolled on <em class="date">{EnrollDate}</em>
                </div>
                <div class="Finish-date course-date mb-1">
                  To be Finished
                  <em class="date" style={{ color: '#ff3b3b' }}>
                    {DueDate}
                  </em>
                </div>
                <div class="studentinfo">
                  <figure>
                    <img
                      src={StudentImageUrl}
                      class="rounded-circle face-fit-cover"
                      width="47"
                      height="47"
                      alt=""
                    />
                  </figure>
                  <span class="student-name">
                    {FirstName} {LastName}
                  </span>
                </div>
              </div>
            </div>
          );
        },
        Content: CourseStudent,
      },
      {
        Name: 'Growth Plan',
        Badge:
          StudentContract && StudentContract.length > 0 ? StudentContract[0].ContractCount : '0',
        Component: props => {
          const {
            StudentContractId,
            ContractName,
            StudentId,
            StartDate,
            DueDate,
            DueDateForSort,
            DueDateFontColor,
            DiscardedDate,
            CompletedDate,
            ContractStatus,
            StudentImageUrl,
            FirstName,
            LastName,
          } = props;
          const contractStatus = {
            '-1': {
              color: '#fef2f2',
              date: DiscardedDate,
              name: 'Discarded On',
            },
            '0': {
              color: '#f8f8f8',
              date: DueDate,
              name: 'Due',
              dateFontColor: DueDateFontColor,
              gotoContractsPage: true,
            },
            '1': {
              color: 'rgb(239, 247, 254)',
              date: CompletedDate,
              name: 'Completed On',
            },
          };
          return (
            <div
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <div
                class="task-box"
                style={{ backgroundColor: contractStatus[ContractStatus].color }}
              >
                <div class="row no-gutters w-100 justify-content-between align-items-center">
                  <div class="col-12 col-md-7 mb-1">
                    <div
                      class="course-name"
                      style={{ width: '100%' }}
                      onClick={() =>
                        contractStatus[ContractStatus].gotoContractsPage &&
                        this.props.history.push(
                          `/newcontract?studentId=${StudentId}&contractId=${StudentContractId}`,
                        )
                      }
                    >
                      <h6>{ContractName}</h6>
                      <div class="row">
                        <div class="col-12 col-md-auto mr-2">
                          <span class="due-date">
                            <em>Assigned: </em> {StartDate}
                          </span>
                        </div>
                        <div class="col-12 col-md-auto">
                          <span
                            class="due-date"
                            style={{ color: contractStatus[ContractStatus].dateFontColor }}
                          >
                            <em>{contractStatus[ContractStatus].name} : </em>{' '}
                            {contractStatus[ContractStatus].date}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-5 mb-1">
                    <div class="studentinfo">
                      <figure>
                        <img
                          src={StudentImageUrl}
                          class="rounded-circle face-fit-cover"
                          width="47"
                          height="47"
                          alt=""
                          style={{ flex: '1' }}
                        />
                      </figure>
                      <span class="student-name" style={{ flex: '2' }}>
                        {FirstName} {LastName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
        Content: StudentContract,
      },
    ];

    var role = localStorage.getItem('userRole');

    return (
      <>
        <div className="d-flex justify-content-between mb-3">
          <h3 class="page-title">Statistics</h3>
          {/* 
          <h5
            class="page-title"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              this.props.history.push('/dataAnalytics');
            }}
          >
            Analytics
          </h5> */}
          {/* {role === 'Super Admin' && (
            
          )} */}
          <Link
            className="btn px-4"
            to={{
              pathname: '/dashboardAnalytics',
            }}
            target="_blank"
          >
            Analytics
          </Link>
          <Loader loading={isFetching} />
          <div class="text-right">
            {dashboardData && dashboardData.DashboardMisc[0].Label.toUpperCase() + ': '}
            {'  '}
            <span style={{ fontWeight: 900, fontSize: '21px' }}>
              {dashboardData && dashboardData.DashboardMisc[0].Score}
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-xl-7 dashboard-col-left">
            <div class="row">
              {dashboardData &&
                dashboardData.DashboardBlocks.map((item, index) => {
                  const { Label, Score, Denominator, URL, Filter, chartData } = item;

                  return (
                    <div class="col-sm-6  mb-4">
                      <div class="card border-0 inner-content">
                        <div class="row  align-items-center card-body">
                          <div class="col-12 pt-3">
                            <h6 class="text-uppercase font-weight-bold mb-3">{Label}</h6>
                          </div>
                          <div class="col-xl-7 col-8">
                            <span class="value">
                              <span>{Score}</span>
                              {Denominator && '/' + Denominator}
                            </span>
                          </div>
                          <div class="col-xl-5 col-4 text-right">
                            <div class="graph-img">
                              <Sparklines data={chartData}>
                                <SparklinesLine color="#179bef" />
                              </Sparklines>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div class="row justify-content-between align-items-baseline mb-4 announcements-row">
              <div class="col-12 col-sm-auto ">
                <h3 class="page-title mb-0">Announcements</h3>
              </div>
            </div>

            <div class="row">
              {announcementData &&
                announcementData.map((item, index) => {
                  const {
                    Abstract,
                    AnnouncementId,
                    CenterId,
                    EndDate,
                    ImageUrl,
                    IsMain,
                    Link,
                    RankId,
                    StartDate,
                    Title,
                    DaysAgo,
                    Tense,
                  } = item;
                  if (Tense == 'Current' || role == 'Super Admin') {
                    return (
                      <div class="col-sm-6 col-xl-4 mb-4">
                        <div
                          class="card announcements-card border-0 "
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (Link) {
                              const win = window.open(Link, '_blank');
                              if (win != null) {
                                win.focus();
                              }
                            }
                          }}
                        >
                          <figure
                            class="images-placeholder"
                            style={{
                              backgroundImage: `url(${ImageUrl})`,
                            }}
                          >
                            <img
                              class="card-img-top img-background"
                              src="images/announcements-img1.jpg"
                              alt=""
                            />
                          </figure>
                          <div class="announcement-card-body">
                            {DaysAgo === 0 ? (
                              <span class="date">Today</span>
                            ) : (
                              <span class="date">{DaysAgo} days ago</span>
                            )}
                            {/* <span>{this.getDuration(SubscriberProfile.AppJoiningDate)}</span> */}
                            <h5 class="card-title mb-2 h5">{Title}</h5>
                            <Paragraph
                              // ellipsis={{ rows: 3, expandable: false }}
                              className="card-body-text"
                              // style={{ color: '#828282' }}
                            >
                              {Abstract}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div class="col-lg-6 col-xl-5 dashboard-tabcol dashboard-col-right mb-5 res-update">
            <div class="card border-0">
              <div class="card-body center-tabs">
                <ListRenderer list={list} activeKey={activeKey} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  dashboard_get,
  announcement_get_all,
  survey_task_mark_in_complete,
  student_task_mark_complete,
  student_task_unassign,
  student_unassign,
  student_mark_complete,
}) => {
  const { data, error, isFetching } = dashboard_get;
  let dashboardData = null,
    announcementData = null,
    isMarkComplete = false,
    isUnAssigned = false,
    isTaskMarkComplete = false,
    isTaskUnAssigned = false,
    isTaskMarkInComplete = false;

  if (data) {
    for (let i = 0; i < data.DashboardBlocks.length; i++) {
      if (data.ChartData[i] && data.ChartData[i].DataAgg)
        data.DashboardBlocks[i].chartData = data.ChartData[i].DataAgg.split(',');
    }
    dashboardData = data;
  }

  if (announcement_get_all.data) {
    announcementData = Object.values(announcement_get_all.data).filter(
      item => item.IsAnnouncementActive,
    );
  }

  if (student_mark_complete && student_mark_complete.data) {
    isMarkComplete = true;
  }

  if (student_unassign && student_unassign.data) {
    isUnAssigned = true;
  }

  if (student_task_unassign && student_task_unassign.data) {
    isTaskUnAssigned = true;
  }

  if (student_task_mark_complete && student_task_mark_complete.data) {
    isTaskMarkComplete = true;
  }

  if (survey_task_mark_in_complete && survey_task_mark_in_complete.data) {
    isTaskMarkInComplete = true;
  }

  return {
    dashboardData,
    announcementData,
    isMarkComplete,
    isUnAssigned,
    isTaskMarkComplete,
    isTaskUnAssigned,
    isTaskMarkInComplete,
    isFetching,
  };
};

export default connect(mapStateToProps)(withRouter(InHouseOld));
