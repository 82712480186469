import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import NumberShorten from '../../../util/numberShorten';

const BarChart = props => {
  const { Score1Count, Score2Count, Score3Count, Score4Count, Score5Count } =
    props.ScoreDistribution && props.ScoreDistribution[0];
  return (
    <div className="bar-chart">
      <div className="bar">
        <div
          className="cat-1"
          style={{
            width: '20%',
          }}
        ></div>
        <div
          className="cat-2"
          style={{
            width: '20%',
          }}
        ></div>
        <div
          className="cat-3"
          style={{
            width: '20%',
          }}
        ></div>
        <div
          className="cat-4"
          style={{
            width: '20%',
          }}
        ></div>
        <div
          className="cat-5"
          style={{
            width: '20%',
          }}
        ></div>
      </div>
      <div className="bar-rating">
        <div className="cat-1-rating">
          <div className="rating">
            <div className="d-flex align-items-start">
              <img src="/images/icons/star_rating_1.svg" width={15} />
              <span className="count">1</span>
            </div>
            <span className="count">{NumberShorten(Score1Count)}</span>
          </div>
        </div>
        <div className="cat-2-rating">
          <div className="rating">
            <div className="d-flex align-items-start">
              <img src="/images/icons/star_rating_2.svg" width={15} />
              <span className="count">2</span>
            </div>
            <span className="count">{NumberShorten(Score2Count)}</span>
          </div>
        </div>
        <div className="cat-3-rating">
          <div className="rating">
            <div className="d-flex align-items-start">
              <img src="/images/icons/star_rating_3.svg" width={15} />
              <span className="count">3</span>
            </div>
            <span className="count">{NumberShorten(Score3Count)}</span>
          </div>
        </div>
        <div className="cat-4-rating">
          <div className="rating">
            <div className="d-flex align-items-start">
              <img src="/images/icons/star_rating_4.svg" width={15} />
              <span className="count">4</span>
            </div>
            <span className="count">{NumberShorten(Score4Count)}</span>
          </div>
        </div>
        <div className="cat-5-rating">
          <div className="rating">
            <div className="d-flex align-items-start">
              <img src="/images/icons/star_rating_5.svg" width={15} />
              <span className="count">5</span>
            </div>
            <span className="count">{NumberShorten(Score5Count)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CategoriesRated = props => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  //   useEffect(() => {}, []);
  const { CategoryData } = props;
  return (
    <div className="card border-0 rounded-0">
      <div className="row card-body summary-block">
        <div className="col-12">
          <h5 className="">Categories Rated from Highest to Lowest</h5>
        </div>
        <div
          className="col-12"
          style={{
            height: '300px',
            overflowY: 'auto',
          }}
        >
          {CategoryData ? (
            CategoryData.map((itemData, index) => {
              return (
                <div className="categories-rated">
                  <span className="title">{itemData.ShortQuestion}</span>
                  <span className="score">{itemData.AvgScore}</span>
                  <span className="count">{itemData.PatientsCount} patient rated</span>
                  <BarChart ScoreDistribution={JSON.parse(itemData.ScoreDistribution)} />
                </div>
              );
            })
          ) : (
            <div>
              <h6
                style={{
                  textAlign: 'center',
                  marginTop: '30%',
                }}
              >
                No Data
              </h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CategoriesRated;
