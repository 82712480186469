import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Modal } from 'antd';
import { ReduxSelect } from '../../../component/custom-redux-components';
import CloseIcon from '../../../Components/CloseIcon';
import EmotionalGraphModaContent from './emotionalGraphModalContent';
import { Loader2 } from '../../../Components/Loader';
import TrendGraphModalContent from './trendGraphModalContent';
import { recoveryCapitalTrendGraphGet } from '../../../store/actions';
const TrendGraph = props => {
  const dispatch = useDispatch();
  const [series, setSeries] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [timePeriod, setTimePeriod] = useState([]);
  const [sliderRef, setSliderRef] = useState(null);
  const [active, setActive] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [colors, setColors] = useState([]);
  const [modalContent, setModalContent] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cummulativeDataModalContent, setCummulativeDataModalContent] = useState([]);
  const chartRef = useRef();
  const [isSeriesNull, setIsSeriesNull] = useState(false);

  const { quadrantGraphData, isFetching } = useSelector(
    ({ recovery_capital_trend_graph_get: { data: quadrantGraphData, isFetching } }) => {
      return {
        quadrantGraphData: quadrantGraphData,
        isFetching: isFetching,
      };
    },
    shallowEqual,
  );
  useEffect(() => {
    handleAPICall(null, null, false);
    setFilterValue('Last 7 days');

    handleFilterChange('Last 7 days');
  }, []);

  useEffect(() => {
    if (quadrantGraphData && quadrantGraphData[0]) {
      setFilters(quadrantGraphData[0].Filter ? quadrantGraphData[0].Filter : []);
      if (quadrantGraphData[0].MoodsMap && quadrantGraphData[0].MoodsMap.length > 0) {
        setIsSeriesNull(false);
        let xaxis =
          quadrantGraphData[0].X_Axis &&
          quadrantGraphData[0].X_Axis.map(item => item.MonYear || item.FormattedDate);

        let seriesData =
          quadrantGraphData[0].CategoryLegend &&
          quadrantGraphData[0].CategoryLegend.map((item, index) => {
            setColors(prevColors => [...prevColors, item.ColorCode]);

            let lineCoordinates = generateSeries(
              item.MoodReasonId,
              quadrantGraphData[0].MoodsMap,
              1,
              quadrantGraphData[0].X_Axis && quadrantGraphData[0].X_Axis.length,
            );

            return {
              label: item.Shortdescription + ', ' + item.Name,
              shortdescription: item.Shortdescription,
              surveyLabel: item.Name,
              data: lineCoordinates.lineCoordinates,
              fill: false,
              borderColor: item.ColorCode,
              lineTension: 0,
              spanGaps: true,
              backgroundColor: item.ColorCode,
              cummulativeData: lineCoordinates.cummulativeData,
            };
          });
        let data = {
          labels: xaxis,
          datasets: seriesData,
        };
        setSeries(data);
      } else {
        setSeries([]);

        setIsSeriesNull(true);
      }
    }
  }, [quadrantGraphData]);

  const generateSeries = (MoodReasonId, MoodsMap, id, xAxis) => {
    let dataObject = {};
    let dataArray = id == 1 ? new Array(xAxis).fill(null) : [];
    let series = MoodsMap && MoodsMap.filter(item => MoodReasonId == item.X);
    series &&
      series[0] &&
      series[0].CummulativeData &&
      series[0].CummulativeData.map((item, index) => {
        if (id == 1) {
          dataArray[item.X] = item.Y;
        } else if (id == 2 && item.CummulativeData && item.CummulativeData.length > 0) {
          dataArray = [...item.CummulativeData];
        }
      });

    let cummulativeData =
      series &&
      series[0] &&
      series[0].CummulativeData &&
      series[0].CummulativeData.map((item, index) => {
        const { X, CummulativeData } = item;
        let obj = {
          [X]: [...CummulativeData],
        };
        return obj;
      });
    cummulativeData
      ? cummulativeData.length == 0
        ? setIsSeriesNull(true)
        : setIsSeriesNull(false)
      : setIsSeriesNull(true);
    dataObject = {
      lineCoordinates: dataArray,
      cummulativeData: cummulativeData,
    };
    return dataObject;
  };

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  var settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    variableWidth: true,
    rtl: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  const handleAPICall = (startDate, endDate, isMonth) => {
    let data = {
      Graph: {
        StartDate: startDate,
        EndDate: endDate,
        IsMonth: isMonth,
      },
    };
    dispatch(
      recoveryCapitalTrendGraphGet({
        // UserGuid: '869CD961-CF05-4A18-ADDF-9224138578B0',
        // UserGuid: '25E7F19A-29E3-4352-A2FE-08154A2D2C80',
        UserGuid: props.UserGuid,
        Json: JSON.stringify(data),
      }),
    );
  };

  const handleFilterChange = value => {
    setFilterValue(value);

    let dates = filters && filters.filter(item => item.Value == value);
    let filterArray = value.split(' ');
    let num = parseInt(filterArray[1]);
    let timePeriod = filterArray[2].toLowerCase();
    setTimeStamp(timePeriod);

    let currentDate = moment();
    let startDate = currentDate.clone().subtract(num - 1, timePeriod);
    let endDate = currentDate;

    var allDatesInRange = [];

    var current = startDate.clone();
    if (timePeriod == 'days') {
      handleAPICall(
        dates[0] ? dates[0].StartDate : null,
        dates[0] ? dates[0].EndDate : null,
        false,
      );
    } else {
      if (timePeriod == 'months') {
        handleAPICall(
          dates[0] ? dates[0].StartDate : null,
          dates[0] ? dates[0].EndDate : null,
          true,
        );
        while (current.isSameOrBefore(endDate, 'day')) {
          allDatesInRange.push({
            Text: current.format('MMM YY'),
            Value: current.format('DD MMM YY'),
          });

          current.add(1, timePeriod);
        }
      }
    }

    setTimePeriod(allDatesInRange);
  };

  const handleDaySelection = (value, text) => {
    let startDate;
    let endDate;
    // let isMonth;
    if (timeStamp == 'days') {
      startDate = moment(value).format('YYYY-MM-DD');
      endDate = moment(value).format('YYYY-MM-DD');
      // isMonth = false;
    } else if (timeStamp == 'months') {
      let year = moment(value).format('YYYY');
      let month = moment(value).format('MM');
      month = month - 1;
      let firstDayOfMonth = moment({ year: year, month: month });
      let lastDayOfMonth = moment({ year: year, month: month }).endOf('month');
      startDate = firstDayOfMonth.format('YYYY-MM-DD');
      endDate = lastDayOfMonth.format('YYYY-MM-DD');
    }

    handleAPICall(startDate, endDate, false);
  };

  const options = {
    scales: {
      // x: {
      //   display: true,
      //   title: {
      //     display: true,
      //     text: 'Months',
      //   },
      // },
      // y: {
      //   display: true,
      //   beginAtZero: true,
      //   min: 0, // Set the minimum y-axis value to 0
      //   max: 10, // Set the maximum y-axis value to 10
      //   ticks: {
      //     stepSize: 1,
      //   },
      // },
      xAxes: [
        {
          type: 'category',
          labels: series && series.labels,
          ticks: {
            // maxRotation: 0, // Rotate the x-axis labels to 0 degrees
            // minRotation: 0,
            autoSkip: false, // Disable label skipping
            // stepSize: 1, // Display every label
            font: {
              size: 10, // Adjust font size to fit labels
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            max: 10,
            min: 1,
            stepSize: 1,
          },
        },
      ],
    },
    legend: {
      display: true,
      position: 'right',
      align: 'start',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        fontSize: 14,
        padding: 20,
      },
    },
    plugins: {
      width: 1500,
      datalabels: {
        display: false, // Hide data labels
      },
    },
    // tooltips: {
    //   mode: 'index',
    //   intersect: false,
    //   callbacks: {
    //     //Added callbacks for label
    //     title: (tooltipItems, data) => {
    //       return '';
    //     },
    //     // label: (tooltipItems, data) => {
    //     //   // return ' (' + tooltipItems.xLabel + ',' + tooltipItems.yLabel + ')';
    //     // },
    //   },
    // },
    onClick: (event, elements) => {
      const chartInstance = chartRef.current.chartInstance;
      const activePoints = chartInstance.getElementsAtEventForMode(
        event,
        'point',
        chartInstance.options,
      );
      let modalArray = [];
      if (activePoints && activePoints.length > 0) {
        activePoints &&
          activePoints.length > 0 &&
          activePoints.map(item => {
            const clickedDatasetIndex = item._datasetIndex;
            const clickedDataIndex = item._index;
            const dataset = series.datasets[clickedDatasetIndex];
            const clickedPointData = dataset.data[clickedDataIndex];
            dataset.xAxis = item._index;
            modalArray.push(dataset);
          });
        setModalContent(modalArray);
        setIsModalOpen(true);
      }
    },
  };

  useEffect(() => {
    if (modalContent && modalContent.length > 0) {
      modalContent &&
        modalContent.map(item => {
          const { cummulativeData, xAxis } = item;
          cummulativeData &&
            cummulativeData.map(items => {
              for (let i in items) {
                if (i == xAxis) {
                  setCummulativeDataModalContent(prevData => [...prevData, items[i]]);
                }
              }
            });
        });
    }
  }, [modalContent]);
  return (
    <div className="col-12 p-0" style={{ position: 'relative' }}>
      <div className="col-12 p-0">
        <Loader2 loading={isFetching} />
        <Line data={series} options={options} ref={chartRef} height={250} />
        {isSeriesNull && (
          <div
            className="d-flex align-items-center flex-column justify-content-center mb-3"
            style={{
              backgroundColor: '#F8F8F8',
              borderRadius: '7px',
              position: 'absolute',
              top: '46%',
              left: '40%',
              transform: 'translate(-50%, -50%)',
              width: '335px',
              padding: '35px',
            }}
          >
            <img src="images/sad.svg" className="mb-1" style={{ height: '35px', width: '35px' }} />
            <h4 className="mb-0" style={{ fontSize: '21px' }}>
              Uh ohh!
            </h4>
            <p className="mb-0 text-center">
              There is no survey data available for the selected{' '}
              {timeStamp && timeStamp.slice(0, -1)}.
            </p>
          </div>
        )}
      </div>
      <div
        className={
          timePeriod && timePeriod.length > 0
            ? 'd-flex col-12 mb-3 p-0 mt-3 justify-content-unset'
            : 'd-flex col-12 mb-3 p-0 mt-3 justify-content-end'
        }
      >
        {timePeriod && timePeriod.length > 0 && (
          <div className="col-9 d-flex align-items-center justify-content-center p-0">
            <div className="col-1 d-flex align-items-center pr-0">
              <img
                src="images/whiteLeftArrow.svg"
                onClick={previous}
                style={{
                  background: '#46B4FE',
                  width: '30px',
                  height: '30px',
                  padding: '6px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div className="col-10 px-0">
              <div

              // className="d-flex flex-wrap"
              >
                {/* <Loader2 loading={isFilterFetching} /> */}

                <Slider
                  // ref={c => (slider = c)}
                  ref={c => setSliderRef(c)}
                  {...settings}
                >
                  {timePeriod &&
                    timePeriod.map((items, index) => {
                      return (
                        <li
                          className="d-flex max-content"
                          key={index}

                          // className="p-4" style={{ width: 'auto' }}
                        >
                          <span
                            style={
                              JSON.stringify(active) === JSON.stringify(items)
                                ? {
                                    border: '1px solid #4AB0FE',
                                    boxSizing: 'border-box',
                                    filter: 'drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384))',
                                    borderRadius: '50px',
                                    padding: '5px',
                                    margin: '5px',
                                    fontSize: '12px',
                                    color: 'white',
                                    padding: '5px 10px',
                                    background: '#4AB0FE',
                                    cursor: 'pointer',
                                  }
                                : {
                                    border: '1px solid #979797',
                                    boxSizing: 'border-box',
                                    filter: 'drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.0467384))',
                                    borderRadius: '50px',
                                    padding: '5px',
                                    margin: '5px',
                                    fontSize: '12px',
                                    color: '#979797',
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                  }
                            }
                            onClick={() => {
                              setActive(items);
                              handleDaySelection(items.Value, items.Text);
                            }}
                          >
                            {items.Text}
                          </span>
                        </li>
                      );
                    })}
                </Slider>
              </div>
            </div>

            <div className="col-1 d-flex align-items-center px-0">
              {' '}
              <img
                onClick={next}
                src="images/whiteLeftArrow.svg"
                style={{
                  transform: 'rotate(180deg)',
                  background: '#46B4FE',
                  width: '30px',
                  height: '30px',
                  padding: '6px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        )}
        <div class="col-3">
          <div class="form-group mb-0">
            <ReduxSelect
              options={filters}
              onChange={handleFilterChange}
              className="basic-single"
              classNamePrefix="select"
              value={filterValue}
              placeholder="Filter"
            />
          </div>
        </div>
      </div>
      {isModalOpen && modalContent && modalContent.length > 0 && (
        <Modal
          open={isModalOpen}
          className="merge-clients-modal"
          onCancel={() => {
            // this.setState({ isMergeSuccess: false });
            // this.onMergeComplete();
            setModalContent([]);
            setIsModalOpen(false);
            setCummulativeDataModalContent([]);
          }}
          closeIcon={<CloseIcon />}
          maskClosable={true}
          width={500}
          footer={null}
          // style={{ paddingTop: '60px', paddingBottom: '60px' }}
        >
          <TrendGraphModalContent
            modalContent={modalContent}
            cummulativeDataModalContent={cummulativeDataModalContent}
          />
        </Modal>
      )}
    </div>
  );
};
export default TrendGraph;
