import React, { PureComponent } from 'react';
import { Modal } from 'antd';

class CustomModal extends PureComponent {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    const { onClose } = this.props;
    console.log(e);
    this.setState({
      visible: false,
    });
    if (typeof onClose == 'function') {
      onClose();
    }
  };

  componentWillReceiveProps({ modalVisible }) {
    if (modalVisible !== this.props.modalVisible) {
      this.setState({ visible: modalVisible });
    }
  }

  render() {
    const { ModalButton, ModalContent, modalWidth, style, maskClosable } = this.props;
    return (
      <>
        {ModalButton && <ModalButton onClick={this.showModal} onCancel={this.handleCancel} />}
        <Modal
          width={modalWidth || 600}
          open={this.state.visible}
          footer={null}
          closeIcon={null}
          onCancel={this.handleCancel}
          closable={false}
          destroyOnClose
          style={style}
          maskClosable={maskClosable}
        >
          <ModalContent onOk={this.handleOk} onCancel={this.handleCancel} />
        </Modal>
      </>
    );
  }
}

export default CustomModal;
