import React from 'react';
import ReactDOM from 'react-dom';
import _blank from '../images/blank-profile.png';
import _ from 'lodash';
import { Checkbox, Divider, Avatar, Tooltip } from 'antd';
import Loader, { Loader2 } from '../Components/Loader';
const CheckboxGroup = Checkbox.Group;

const MultipleCategoryCheckboxAll = ({
  getSelectAllValue,
  getCheckItems,
  data,
  index,
  defaultValue,
  selectedPersons,
  isFetching,
}) => {
  const plainOptions = data.children.map(item => {
    return { ...item, label: item.label, value: item.value };
  });
  // const plainOptions = data.children.map(item => item.label);

  let checkingForSetALL = false;
  // if (defaultValue.length > 0) {
  //   if (defaultValue.length == plainOptions.length) {
  //     checkingForSetALL = true;
  //   } else {
  //     checkingForSetALL = false;
  //   }
  // }
  // const defaultCheckedList = ['Post Added', 'Friend', 'Chat'];
  const [checkedList, setCheckedList] = React.useState(defaultValue);
  const [indeterminate, setIndeterminate] = React.useState(!checkingForSetALL);
  const [checkAll, setCheckAll] = React.useState(checkingForSetALL);
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const getUniqueListBy = (data, key) => {
    if (data) {
      return [...new Map(data.map(item => [item[key], item])).values()];
    } else {
      return [];
    }
  };
  const crossChange = userGuid => {
    let copiedCheckedList = [...checkedList];
    let index = checkedList.indexOf(userGuid);
    copiedCheckedList.splice(index, 1);
    setCheckedList(copiedCheckedList);
    onChange(copiedCheckedList, userGuid, true);
  };
  const onChange = (list, res, itemRemoved) => {
    if (selectedMembers && selectedMembers.length > 0 && !itemRemoved) {
      let newly = [...list, ...checkedList];
      let unique = newly.filter((item, i, ar) => ar.indexOf(item) === i);
      setCheckedList(unique);
      setIndeterminate(!!unique.length && unique.length < plainOptions.length);

      setCheckAll(plainOptions && plainOptions.length > 0 && unique.length === plainOptions.length);

      let selectedView = [];

      unique &&
        unique.map(item => {
          let view = [...selectedMembers, ...plainOptions].filter(user => {
            return user.UserGuid == item;
          });
          selectedView.push(view[0]);
          let data = [...selectedView];
          // const uniqueData = [...new Set(data.map(item => item))];

          if (plainOptions.length == 0) {
            let copiedSelectedMembers = [...selectedMembers];
            let userGuid = res;
            copiedSelectedMembers = copiedSelectedMembers.filter(
              item => !(item.UserGuid == userGuid),
            );

            setCheckAll(false);
            setSelectedMembers(copiedSelectedMembers);
            return;
          } else {
            const uniqueData = getUniqueListBy(data, 'UserGuid');
            setSelectedMembers(uniqueData);
          }
        });
      if (unique && unique.length == 0) {
        setSelectedMembers([]);
      }

      getCheckItems(unique, index);
    } else {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < plainOptions.length);

      setCheckAll(plainOptions && plainOptions.length > 0 && list.length === plainOptions.length);

      let selectedView = [];

      list &&
        list.map(item => {
          let view = [...selectedMembers, ...plainOptions].filter(user => {
            return user.UserGuid == item;
          });
          selectedView.push(view[0]);
          let data = [...selectedView];
          // const uniqueData = [...new Set(data.map(item => item))];
          if (plainOptions.length == 0) {
            let copiedSelectedMembers = [...selectedMembers];
            let userGuid = res;
            copiedSelectedMembers = copiedSelectedMembers.filter(
              item => !(item.UserGuid == userGuid),
            );

            setCheckAll(false);
            setSelectedMembers(copiedSelectedMembers);
            return;
          } else {
            const uniqueData = getUniqueListBy(data, 'UserGuid');
            setSelectedMembers(uniqueData);
          }
        });
      if (list && list.length == 0) {
        setSelectedMembers([]);
      }

      getCheckItems(list, index);
    }
  };

  const onCheckAllChange = e => {
    let checked = e.target.checked;
    let checkedOptions = checked ? plainOptions : [];
    setCheckedList(
      checkedOptions.map(item => {
        return item.value;
      }),
    );

    setIndeterminate(false);

    setCheckAll(checked);
    getCheckItems(checkedOptions, index);
    getSelectAllValue(checked);
    setSelectedMembers(plainOptions);
    if (!checked) {
      setSelectedMembers([]);
    }
  };

  return (
    <div className="MultipleCategoryCheckboxAll2">
      <div className="row">
        <div className="col-7">Members</div>
        <div className="col-5">
          Selected ({checkedList && checkedList.length > 0 ? checkedList.length : 0})
        </div>
        <div
          className="col-7 p-4"
          style={{ background: '#F8F8F8', borderRadius: '4px', height: '300px', overflowY: 'auto' }}
        >
          <Loader2 loading={isFetching} />
          {data && data.children && data.children.length <= 0 ? (
            <div style={{ fontSize: '14px', fontWeight: '700' }} className="text-center">
              No Matching Record Found
            </div>
          ) : (
            <>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                // style = {{backgroudColor: "red"}}
                className="d-flex align-items-center"
              >
                {data.label}
              </Checkbox>
              <br />
              {/* <br /> */}
              <CheckboxGroup
                options={data.children.map(item => {
                  return {
                    label: (
                      <>
                        <div className="col-12 d-inline-block px-0">
                          <div
                            className="d-flex justify-content-between mt-2 align-items-center"
                            style={{
                              // boxShadow: '0 2px 1px 0 rgba(0, 0, 0, 0.06)',
                              borderBottom: '1px solid #EDEDED',
                              paddingTop: '10px',
                              paddingBottom: '10px',
                              paddingRight: '10px',
                              marginBottom: '5px',
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <Avatar
                                size="large"
                                className="mr-2"
                                // icon={<i class="fas fa-users"></i>}
                                // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                src={item.ProfileImgUrl || _blank}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '115px',
                                  wordBreak: 'break-all',
                                }}
                              >
                                <span style={{ fontSize: '14px', fontWeight: '700' }}>
                                  {item.DisplayName}
                                </span>
                                <span
                                  style={{
                                    color: '#515151',
                                    fontSize: '12px',
                                  }}
                                >
                                  {' '}
                                  {item.JoinedInvitedDate}
                                </span>
                                {/* <span style={{ fontSize: '12px', color: 'gray' }}>March 25, 2020</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                    value: item.value,
                  };
                })}
                value={checkedList}
                onChange={onChange}
              />
            </>
          )}

          <Divider />
        </div>

        <div className="col-5" style={{ height: '300px', overflowY: 'auto' }}>
          {selectedMembers &&
            selectedMembers.map(item => {
              return (
                <>
                  {' '}
                  <div className="d-inline-flex mr-2">
                    <div
                      style={{
                        background: '#F8F8F8',
                        borderRadius: '26.5px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        padding: '4px',
                      }}
                    >
                      <Avatar className="mr-2" src={item.ProfileImgUrl || _blank} />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: '13px', width: '90px', wordBreak: 'break-all' }}>{item.DisplayName}</span>
                      </div>
                      <Tooltip placement="top" title="Remove">
                        <span
                          class="delete-course"
                          style={{ margin: '0px 0.5rem', cursor: 'pointer' }}
                          onClick={e => {
                            crossChange(item.UserGuid);
                            // this.clearSelection(item);
                          }}
                        >
                          <img
                            src="images/close_icon_grey.svg"
                            alt="Barcelona Bootcamp"
                            width="20"
                            style={{ cursor: 'pointer' }}
                          />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default MultipleCategoryCheckboxAll;
