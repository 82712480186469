import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';

const Demographic = props => {
  const {
    handleSubmit,
    handleNext,
    onLastSubmit,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    isEmployedValue,
    style,
    goToPreviuos,
    disable,
  } = props;
  return (
    <form onSubmit={handleSubmit(onLastSubmit)} style={{ padding: '36px 20px 200px 20px' }}>
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`accountNumber`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Account Number'}
              disabled={disable}
            />
            <label class="placeholder-label">Account Number</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`accountType`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Account Type'}
              disabled={disable}
            />
            <label class="placeholder-label">Account Type</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`accountDescription`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Account Description'}
              disabled={disable}
            />
            <label class="placeholder-label">Account Description</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name={`transactionType`}
              type="text"
              component={ReduxInput}
              // validate={validate.required}
              fieldName={'Transaction Type'}
              disabled={disable}
            />
            <label class="placeholder-label">Transaction Type</label>
          </div>
        </div>
        <div class="row pagination-button">
          <div class="col-12 text-right">
            <button
              onClick={goToPreviuos}
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  return {
    MaritalStatus: combos.data.MaritalStatus,
    EthnicRace: combos.data.EthnicRace,
    Gender: combos.data.Gender,
    HousingType: combos.data.HousingType,
    EyeColor: combos.data.EyeColor,
    Language: combos.data.Language,
    EducationType: combos.data.EducationType,
    WorkSkill: combos.data.WorkSkill,
    isEmployedValue: selector(state, 'isEmployed'),
  };
})(
  reduxForm({
    form: 'providerEnrollmentForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Demographic),
);

const selector = formValueSelector('providerEnrollmentForm');
