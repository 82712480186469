import React from 'react';
import moment from 'moment';

export default function EmotionalGraphModaContent(props) {
  const { modalContent } = props;
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ paddingTop: '60px', paddingBottom: '60px' }}
    >
      {modalContent &&
        modalContent.map((item, key) => {
          const { name, data, remainingData } = item;
          return (
            <div style={{ width: '100%' }} key={key}>
              {data &&
                data.map((item, index) => {
                  let shortdescription = remainingData[index][0];
                  let reason = remainingData[index][1];
                  let moodEmoji = remainingData[index][2];
                  let moodDescription = remainingData[index][3];
                  let createdDate = remainingData[index][4];
                  let colorCode = remainingData[index][5];
                  let newDate = moment(createdDate).format('lll');

                  return (
                    <div className="d-flex justify-content-around align-items-center my-2" key={`data-labels-${key}-${index}`}>
                      <div className="d-flex flex-column">
                        <div
                          style={{
                            backgroundColor: colorCode,
                            height: '36px',
                            width: '210px',
                            color: 'white',
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <span
                            className="mr-2"
                            style={{
                              border: '1px solid white',
                              borderRadius: '50%',
                              width: '23px',
                              height:"23px",
                              fontSize: '12px',
                              // paddingLeft: '1px',
                              lineHeight: "1.85",
                              textAlign:"center"
                            }}
                          >
                            {shortdescription}
                          </span>
                          <span>{reason}</span>
                        </div>
                        <p className="mb-0">{newDate} </p>
                      </div>

                      {/* <p className="mb-0">{reason}</p> */}
                      <div style={{ textAlign: 'center', minWidth: '46px' }}>
                        <img src={moodEmoji} alt="" style={{ height: '24px', width: '24px' }} />
                        <p className="mb-0">{moodDescription} </p>
                      </div>
                      <div className="text-center">
                        <p className="mb-0">{item[0]}</p>
                        <p className="mb-0">Scale</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
}
