import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';

const Demographic = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    EducationType,
    Language,
    EyeColor,
    HousingType,
    Gender,
    EthnicRace,
    MaritalStatus,
    WorkSkill,
    StudentChildrenCare,
    isEmployedValue,
    style,
    goToPreviuos,
    disable,
    CountryOfBirth,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
    hideQuestions,
    isBoiseGirlsAcademyCenter,
    PreIntakeClientStatus,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={{ padding: '36px 20px 200px 20px' }}
    >
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="maritalStatus"
              type="text"
              placeholder="Relationship Status*"
              options={MaritalStatus}
              component={ReduxSelect}
              validate={validate.required}
              fieldName={'Marital Status'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="ethnicRaceId"
              type="text"
              placeholder="Race/Ethnicity*"
              options={EthnicRace}
              component={ReduxSelect}
              validate={validate.required}
              fieldName={'Race'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="countryOfBirth"
              type="text"
              placeholder="Country of Birth"
              options={CountryOfBirth}
              component={ReduxSelect}
              fieldName={'Country of Birth'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="gender"
              type="text"
              placeholder="Gender at Birth*"
              options={Gender}
              component={ReduxSelect}
              validate={validate.required}
              fieldName={'Gender at Birth'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="housingTypeId"
              type="text"
              placeholder="Current Housing*"
              component={ReduxSelect}
              options={HousingType}
              validate={validate.required}
              fieldName={'Current Housing'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="eyeColorId"
              options={EyeColor}
              type="text"
              placeholder="Eye Color"
              component={ReduxSelect}
              // validate={validate.required}
              fieldName={'Eye Color'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="languageType"
              type="text"
              placeholder="Primary Language"
              component={ReduxSelect}
              options={Language}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="educationTypeId"
              type="text"
              placeholder="Education Level Completed*"
              component={ReduxSelect}
              options={EducationType}
              validate={validate.required}
              fieldName={'Education Level Completed'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="workSkillId"
              type="text"
              placeholder="Work Skill*"
              component={ReduxSelect}
              options={WorkSkill}
              validate={validate.required}
              fieldName={'Work Skill'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label class="custom-control-label" for="is-emergency-contact">
              Employed{'   '}
              <Field
                name={`isEmployed`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
            </label>
          </div>
        </div>
        {isEmployedValue && (
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                component={ReduxDateInput}
                name="employmentStartDate"
                label="Employment Start Date*"
                validate={validate.required}
                fieldName={'Employment Start Date'}
                disabled={disable}
              />
              <i class="far fa-calendar-alt form-control-icon" />
            </div>
            <br />
            <br />
          </div>
        )}
        {!isEmployedValue && (
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                component={ReduxDateInput}
                name="lastEmployedDate"
                label="Date Last Worked"
                disabled={disable}
              />
              <i class="far fa-calendar-alt form-control-icon" />
            </div>
            <br />
            <br />
          </div>
        )}
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="childrenDependent"
              type="text"
              placeholder="Number of Dependent Children"
              component={ReduxSelect}
              options={[
                { Text: '0', Value: 1 },
                { Text: '1', Value: 2 },
                { Text: '2', Value: 3 },
                { Text: '3', Value: 4 },
                { Text: '4', Value: 5 },
                { Text: '5', Value: 6 },
                { Text: '6', Value: 7 },
                { Text: '7', Value: 8 },
                { Text: '8', Value: 9 },
                { Text: '9', Value: 10 },
                { Text: '10', Value: 11 },
                { Text: '11', Value: 12 },
                { Text: '12', Value: 13 },
              ]}
              // validate={validate.required}
              fieldName={'Number of Dependent Children'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="childrenCareId"
              type="text"
              placeholder="Children Being Cared"
              component={ReduxSelect}
              options={StudentChildrenCare}
              // validate={validate.required}
              fieldName={'Children Care'}
              disabled={disable}
            />
          </div>
        </div>

        {!hideQuestions && (
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label class="custom-control-label" for="is-Generate-Income">
                Are you aware that you will not generate any income while you are at this center?{' '}
                {'   '}
                <Field
                  name={`awareNoIncome`}
                  type="text"
                  class="custom-control-input not-empty"
                  component={ReduxToggle}
                  disabled={disable}
                />
              </label>
            </div>
          </div>
        )}
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label class="custom-control-label" for="is-Generate-Income">
              Policy about obtaining a physical has been explained to the applicant?{'   '}
              <Field
                name={`awareOfPolicy`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
            </label>
          </div>
        </div>
        {!isBoiseGirlsAcademyCenter && (
          <>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label class="custom-control-label" for="is-License">
                  Do you have a drivers license? {'   '}
                  <Field
                    name={`hasDriversLicense`}
                    type="text"
                    class="custom-control-input not-empty"
                    component={ReduxToggle}
                    disabled={disable}
                  />
                </label>
              </div>
            </div>

            {!hideQuestions && (
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="custom-control-label" for="is-detox">
                    Client has been informed of the requirement to obtain a physical within 30 days
                    of admission?
                    {'   '}
                    <Field
                      name={`physicalWithin30Days`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                      disabled={disable}
                    />
                  </label>
                </div>
              </div>
            )}
          </>
        )}
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label class="custom-control-label" for="is-detox">
              Client has been informed that no income will be generated through vocational training?
              {'   '}
              <Field
                name={`noIncomeFromVocationalTraining`}
                type="text"
                class="custom-control-input not-empty"
                component={ReduxToggle}
                disabled={disable}
              />
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              component={ReduxDateInput}
              name="expectedDischargeDate"
              label="Expected Discharge Date"
              // validate={validate.required}
              fieldName={'Expected Discharge Date'}
              disabled={disable}
            />
            <i class="far fa-calendar-alt form-control-icon" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-8">
          <div class="form-group">
            <Field
              name={`demographicNotes`}
              type="textarea"
              component={ReduxTextarea}
              disabled={disable}
            />
            <label class="placeholder-label">Notes</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <Field
              name="preIntakeClientStatus"
              type="text"
              placeholder="Client Status"
              component={ReduxSelect}
              options={PreIntakeClientStatus}
              // validate={validate.required}
              fieldName={'Client Status'}
              disabled={disable}
            />
          </div>
        </div>
        <div class="row pagination-button">
          <div class="col-12 text-right">
            {currentTab > 0 && (
              <button
                onClick={goToPreviuos}
                type="button"
                class="btn btn-outline-primary btn-eForm-Prev mr-2"
                disabled={disable}
              >
                Previous
              </button>
            )}
            {currentTab + 1 != tabsCount && (
              <button
                type="submit"
                // onClick={handleNext}
                class="btn btn-eForm-Next"
                disabled={disable}
              >
                Next
              </button>
            )}
            {currentTab + 1 == tabsCount && (
              <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default connect(state => {
  const { combos } = state;
  return {
    MaritalStatus: combos.data.MaritalStatus,
    EthnicRace: combos.data.EthnicRace,
    Gender: combos.data.Gender,
    HousingType: combos.data.HousingType,
    EyeColor: combos.data.EyeColor,
    Language: combos.data.Language,
    EducationType: combos.data.EducationType,
    WorkSkill: combos.data.WorkSkill,
    StudentChildrenCare: combos.data.StudentChildrenCare,
    CountryOfBirth: combos.data.Country,
    PreIntakeClientStatus: combos.data.PreIntakeClientStatus,
    isEmployedValue: selector(state, 'isEmployed'),
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Demographic),
);

const selector = formValueSelector('wizard');
