import React, { Component } from 'react';
import { SparklinesLine, Sparklines } from 'react-sparklines';
import { connect } from 'react-redux';
import { Table, Typography, Tooltip, Progress, Modal } from 'antd';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import renderHTML from 'react-render-html';
import { v4 as uuid } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomModal from '../Components/CustomModal';
import Client from './studentsList';
import { stringDate } from '../util/date';
import ListRenderer from '../Components/listRenderer';
import Loader from '../Components/Loader';
import { currencyFormatter, numberFormat } from '../util/formatter';
import {
  getDashboard,
  announcementGetAll,
  surveyTaskMarkInComplete,
  studentTaskMarkComplete,
  studentTaskUnAssign,
  studentMarkComplete,
  studentUnAssign,
  resetStudentTaskUnAssign,
  resetStudentTaskMarkComplete,
  resetStudentMarkComplete,
  resetStudentUnAssign,
  resetSurveyTaskMarkInComplete,
  dashboardPendingSurveyListGet,
  studentNoteGetAll,
  dashboardTaskListGet,
  dashboardCourseListGet,
  dashboardContractListGet,
  dashboardAlertListGet,
} from '../store/actions';

const { Paragraph } = Typography;

function getDefaultTab(props) {
  const query = new URLSearchParams(props.location.search);
  let tabName = query.get('tab_name');
  if (tabName === 'notes') {
    return 0;
  } else if (tabName === 'tasks') {
    return 1;
  } else if (tabName === 'surveys') {
    return 2;
  } else if (tabName === 'courses') {
    return 3;
  } else if (tabName === 'growthplan') {
    return 4;
  }
}
class ScoreModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: 0,
    };
  }
  setCourseScore = e => {
    let scoreval = e.target.value;
    let pat = /^[0-9]{0,3}$/;
    if (scoreval.match(pat) && scoreval <= 100) {
      this.setState({ score: scoreval });
    } else return;
  };
  render() {
    const { onOk, onCancel, onMarkComplete } = this.props;

    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-start flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">Are you sure you want to mark this course as complete?</h4>
        </div>
        <div class="modal-body col-md-12 enrollment-form">
          <div className="mb-3 row">
            <label for="CourseScore" className="col-sm-3 col-form-label text-right">
              Course Score*
            </label>
            <div className="col-sm-6">
              <input
                type="text"
                className="form-control"
                id="CourseScore"
                value={this.state.score}
                onChange={this.setCourseScore}
              />
              <span className="text-red">
                Please enter a score ( 0 - 100 ) to mark this course as complete
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn "
            onClick={() => {
              if (this.state.score) {
                onCancel();
                onMarkComplete(this.state.score);
              }
            }}
          >
            Yes
          </button>
        </div>
      </div>
    );
  }
}
class InHouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: getDefaultTab(props),
      activeTab: 0,
      requestData: false,
      score: 0,
      barSeries: [
        {
          name: 'Cost Of Care Total',
          type: 'column',
          data: [],
          // data: [
          //   { x: '05/06/2014', y: 54 },
          //   { x: '05/08/2014', y: 17 },
          // ],
        },
        {
          name: 'Cost Of Care Avg',
          type: 'line',
          data: [],
        },
      ],
      barOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
            columnWidth: '25%',
            // distributed: true
          },
        },
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 4],
        },
        // title: {
        //   text: 'Legacy . New',
        // },
        dataLabels: {
          enabled: true,
          // enabledOnSeries: [1]
          textAnchor: 'start',
          formatter: function(val, opt) {
            return currencyFormatter.format(val);
          },
        },
        labels: ['Intake', 'Enrolled', 'Discharged'],
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },
        },

        yaxis: [
          {
            title: {
              text: 'Total',
              style: {
                // color: "red",
                fontSize: '12px',
              },
            },
            labels: {
              formatter: function(value) {
                return currencyFormatter.format(value);
              },
            },
          },
          {
            opposite: true,
            title: {
              text: 'Avg Monthly Cost of Care Per Client',
              style: {
                // color: "red",
                fontSize: '12px',
              },
            },
            labels: {
              formatter: function(value) {
                return currencyFormatter.format(value);
              },
            },
          },
        ],
        // yaxis: {
        //   labels: {
        //     formatter: function(value) {
        //       return '$' + value;
        //     },
        //   },
        // },
      },
      ListArray: [],
      NoteListArray: [],
      TaskListArray: [],
      CourseListArray: [],
      ContractListArray: [],
      AlertListArray: [],
      hasMoreData: true,
      hasMoreNoteData: true,
      hasMoreTaskData: true,
      hasMoreCourseData: true,
      hasMoreCotractData: true,
      hasMoreAlertData: true,
      NoteStudent: [],
      ToDo: [],
      TaskStudent: [],
      CourseStudent: [],
      StudentContract: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getDashboard());
    this.props.dispatch(announcementGetAll({ filter: null }));
    this.getPendingSurvey();
    this.getPendingNote();
    this.getPendingTasks();
    this.getPendingCourses();
    this.getPendingContracts();
    this.getPendingAlerts();
  }
  getPendingSurvey = filterData => {
    this.props.dispatch(
      dashboardPendingSurveyListGet({
        json: JSON.stringify({
          Dashboard: {
            ...filterData,
          },
        }),
      }),
    );
  };
  getPendingNote = filterData => {
    this.props.dispatch(
      studentNoteGetAll({
        json: JSON.stringify({
          Dashboard: {
            ...filterData,
          },
        }),
      }),
    );
  };
  getPendingTasks = filterData => {
    this.props.dispatch(
      dashboardTaskListGet({
        json: JSON.stringify({
          Dashboard: {
            ...filterData,
          },
        }),
      }),
    );
  };
  getPendingCourses = filterData => {
    this.props.dispatch(
      dashboardCourseListGet({
        json: JSON.stringify({
          Dashboard: {
            ...filterData,
          },
        }),
      }),
    );
  };
  getPendingContracts = filterData => {
    this.props.dispatch(
      dashboardContractListGet({
        json: JSON.stringify({
          Dashboard: {
            ...filterData,
          },
        }),
      }),
    );
  };
  getPendingAlerts = filterData => {
    this.props.dispatch(
      dashboardAlertListGet({
        json: JSON.stringify({
          Dashboard: {
            ...filterData,
          },
        }),
      }),
    );
  };
  onTaskInComplete = (studentId, surveyId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      surveyId,
    };
    this.props.dispatch(surveyTaskMarkInComplete(data));
  };

  onTaskComplete = (taskStudentId, studentId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      taskStudentId,
    };
    this.props.dispatch(studentTaskMarkComplete(data));
  };

  onTaskUnAssign = (studentId, taskStudentId) => {
    this.state.requestData = true;
    var data = {
      studentId,
      taskStudentId,
    };
    this.props.dispatch(studentTaskUnAssign(data));
  };

  onUnAssign(studentId, courseCenterStudentId) {
    this.state.requestData = true;
    var data = {
      studentId,
      courseCenterStudentId,
    };

    this.props.dispatch(studentUnAssign(data));
  }
  onMarkComplete = score => {
    this.state.requestData = true;
    var data = {
      studentId: this.state.StudentId,
      courseCenterStudentId: this.state.CourseCenterStudentId,
      Score: parseInt(score),
    };
    this.props.dispatch(studentMarkComplete(data));
  };

  componentWillReceiveProps({
    isMarkComplete,
    isUnAssigned,
    isTaskMarkComplete,
    isTaskUnAssigned,
    isTaskMarkInComplete,
    dashboardData,
    DashboardMenu,
    pendingSurveyList,
    notesList,
    tasksList,
    alertsList,
    contractsList,
    coursesList,
    isHideNotesTab,
  }) {
    if (isTaskUnAssigned) this.props.dispatch(resetStudentTaskUnAssign());
    if (isTaskMarkComplete) this.props.dispatch(resetStudentTaskMarkComplete());
    if (isMarkComplete) this.props.dispatch(resetStudentMarkComplete());
    if (isUnAssigned) this.props.dispatch(resetStudentUnAssign());
    if (isTaskMarkInComplete) this.props.dispatch(resetSurveyTaskMarkInComplete());
    if (
      (isMarkComplete ||
        isUnAssigned ||
        isTaskMarkComplete ||
        isTaskUnAssigned ||
        isTaskMarkInComplete) &&
      this.state.requestData
    ) {
      this.state.requestData = false;
      this.props.dispatch(getDashboard());
    }
    if (
      dashboardData &&
      JSON.stringify(dashboardData) != JSON.stringify(this.props.dashboardData) &&
      dashboardData.ClientCostofCare
    ) {
      let labelText = [];
      let SeriesFirst = [];
      let SeriesSecond = [];
      dashboardData.ClientCostofCare.map(item => {
        labelText.push(item.EnrollStatus);
        SeriesFirst.push(item.CostOfCareTotal);
        SeriesSecond.push(item.CostOfCareAvg);
      });
      let barSeriesFirst = { ...this.state.barSeries[0], data: SeriesFirst };
      let barSeriesSecond = { ...this.state.barSeries[1], data: SeriesSecond };
      let newBarSeries = [barSeriesFirst, barSeriesSecond];

      this.setState({
        barOptions: { ...this.state.barOptions, labels: labelText },
        barSeries: newBarSeries,
        ...dashboardData,
      });
    }
    if (
      pendingSurveyList &&
      JSON.stringify(pendingSurveyList) != JSON.stringify(this.props.pendingSurveyList)
    ) {
      let newData = [...this.state.ListArray, ...pendingSurveyList];
      this.setState({ ListArray: newData, hasMoreData: true });
    } else if (pendingSurveyList && pendingSurveyList.length === 0) {
      this.setState({ hasMoreData: false });
    }

    if (notesList && JSON.stringify(notesList) != JSON.stringify(this.props.notesList)) {
      let newData = [...this.state.NoteListArray, ...notesList];
      this.setState({ NoteListArray: newData, hasMoreNoteData: true });
    } else if (notesList && notesList.length === 0) {
      this.setState({ hasMoreNoteData: false });
    }

    if (tasksList && JSON.stringify(tasksList) != JSON.stringify(this.props.tasksList)) {
      let newData = [...this.state.TaskListArray, ...tasksList];
      this.setState({ TaskListArray: newData, hasMoreTaskData: true });
    } else if (tasksList && tasksList.length === 0) {
      this.setState({ hasMoreTaskData: false });
    }

    if (coursesList && JSON.stringify(coursesList) != JSON.stringify(this.props.coursesList)) {
      let newData = [...this.state.CourseListArray, ...coursesList];
      this.setState({ CourseListArray: newData, hasMoreCourseData: true });
    } else if (coursesList && coursesList.length === 0) {
      this.setState({ hasMoreCourseData: false });
    }

    if (
      contractsList &&
      JSON.stringify(contractsList) != JSON.stringify(this.props.contractsList)
    ) {
      let newData = [...this.state.ContractListArray, ...contractsList];
      this.setState({ ContractListArray: newData, hasMoreCotractData: true });
    } else if (contractsList && contractsList.length === 0) {
      this.setState({ hasMoreCotractData: false });
    }

    if (alertsList && JSON.stringify(alertsList) != JSON.stringify(this.props.alertsList)) {
      let newData = [...this.state.AlertListArray, ...alertsList];
      this.setState({ AlertListArray: newData, hasMoreAlertData: true });
    } else if (alertsList && alertsList.length === 0) {
      this.setState({ hasMoreAlertData: false });
    }
    if (isHideNotesTab && isHideNotesTab != this.props.isHideNotesTab) {
      this.list2.push(this.noteTabComponent);
    }
  }

  onAnnouncementClick = url => {
    return <Redirect push to={url} target="_blank" />;
  };

  getMulti = array => {
    for (let item of array) {
      item.count = 0;
      for (let checkItem of array) {
        if (item.StudentId == checkItem.StudentId) {
          item.count += 1;
        }
      }
    }
    let indexArray = [...new Set(array.map(item => item.StudentId))],
      newArray = [];
    for (let item of indexArray) {
      newArray.push(array.find(aItem => aItem.StudentId == item));
    }
    return newArray;
  };
  getDuration(fromdate) {
    var day = moment(moment().diff(fromdate));
    return `${day.month()} Month ${day.dates()} days`;
  }
  list2 = [
    {
      Name: 'Surveys',
      // Badge: this.state.ListArray.length ? this.state.ListArray[0].SurveyCount : '0',
      ExtraComponent: () => (
        <div class="pt-4 px-2" id="scrollableDiv" style={{ height: 600, overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={this.state.ListArray.length}
            next={() => {
              let { SurveyId } = this.state.ListArray[this.state.ListArray.length - 1];
              this.getPendingSurvey({ PageSize: 10, LastSurveyId: SurveyId });
            }}
            hasMore={this.state.hasMoreData}
            loader={<h4>Loading...</h4>}
            // scrollThreshold={0.8}
            // scrollThreshold="200px"
            // height={600}
            // style={{ overflow: 'scroll' }}
            scrollableTarget="scrollableDiv"
            endMessage={
              <p style={{ textAlign: 'center', marginTop: '15px' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {this.state.ListArray.map((surveyItem, index) => {
              const {
                SurveyId,
                SurveyQuestionCategoryId,
                SurveyName,
                StudentId,
                AssignedByStaffId,
                AssignedDate,
                DueDate,
                StartedDate,
                DueStatus,
                FirstName,
                LastName,
                ProfileImgAttachmentId,
                StudentImageUrl,
                DueDateFontColor,
                count,
                IsForm,
                IsAugmentAllowed,
                EnrollStatusId,
              } = surveyItem;
              return (
                <div class="task-box" key={uuid()}>
                  <div class="row no-gutters w-100 justify-content-between align-items-center">
                    <div class="col-12 col-md-5 mb-1">
                      <div
                        style={{ width: 'unset', cursor: 'pointer' }}
                        class="course-name"
                        onClick={() => {
                          this.props.history.push(
                            EnrollStatusId === 1
                              ? count == 1
                                ? '/validate?surveyId=' +
                                  SurveyId +
                                  '&form=' +
                                  IsForm +
                                  '&studentId=' +
                                  StudentId
                                : `/surveyer/?studentId=${StudentId}`
                              : `/clients/intake/dashboard?tab=intake-survey&id=${StudentId}`,
                          );
                        }}
                      >
                        <h6>{count > 1 ? 'Multiple Surveys(' + count + ')' : SurveyName}</h6>
                        <span class="due-date" style={{ minWidth: 'unset' }}>
                          <em>Due : </em> {DueDate}
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-5 mb-1">
                      <div class="studentinfo">
                        <span
                          onClick={() => {
                            this.props.history.push(
                              EnrollStatusId === 1
                                ? `/studentProfile/${StudentId}`
                                : `/clients/intake/dashboard?tab=client-information&id=${StudentId}`,
                            );
                          }}
                          style={{ cursor: 'pointer' }}
                          class=" d-flex align-items-center"
                        >
                          <figure>
                            <img
                              src={StudentImageUrl}
                              class="rounded-circle face-fit-cover"
                              width="47"
                              height="47"
                              alt=""
                              style={{ minWidth: '47px' }}
                            />
                          </figure>
                          <div className="">
                            <span class="student-name">
                              {FirstName} {LastName}
                            </span>
                            <span className="student-status">
                              {EnrollStatusId === 1 ? 'Enrolled' : 'Intake'}
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-2">
                      <div class="actions-button justify-content-xl-end">
                        {IsAugmentAllowed && (
                          <Link
                            to={{
                              pathname: `/studentProfile/${StudentId}`,
                              state: { TriggerAIDrawer: true },
                            }}
                            state={{ from: 'occupation' }}
                            class=" d-flex align-items-center"
                          >
                            <img src="images/gpt-ai.svg" style={{ cursor: 'pointer' }} />
                          </Link>
                        )}

                        {count == 1 ? (
                          <CustomModal
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title={'Incomplete'}>
                                <span class="delete-course" onClick={onClick}>
                                  <i class="fas fa-times" />
                                </span>
                              </Tooltip>
                            )}
                            ModalContent={({ onOk, onCancel }) => (
                              <div class="modal-content border-0">
                                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    &times;
                                  </button>

                                  <h4 class="modal-title col-12">
                                    Do you want to mark this survey as incomplete?
                                  </h4>
                                </div>

                                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn "
                                    onClick={() => {
                                      onOk();
                                      this.onTaskInComplete(StudentId, SurveyId);
                                    }}
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        ) : (
                          <span class="delete-course" style={{ visibility: 'hidden' }}>
                            <i class="fas fa-times" />
                          </span>
                        )}
                      </div>
                    </div>
                    {DueDateFontColor && <span class="alert-danger-dot" />}
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ),
    },
    {
      Name: 'Alerts',
      // Badge: this.state.ToDo.length > 0 ? this.state.ToDo[0].ToDoCount : '0',
      ExtraComponent: () => (
        <div class="pt-4 px-2" id="alertScrollableDiv" style={{ height: 600, overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={this.state.AlertListArray.length}
            next={() => {
              let { DateInserted, DateInsertedForSort } = this.state.AlertListArray[
                this.state.AlertListArray.length - 1
              ];
              this.getPendingAlerts({ PageSize: 10, DateInserted: DateInsertedForSort });
            }}
            hasMore={this.state.hasMoreAlertData}
            loader={<h4>Loading...</h4>}
            scrollableTarget="alertScrollableDiv"
            endMessage={
              <p style={{ textAlign: 'center', marginTop: '15px' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {this.state.AlertListArray.map(item => {
              const { Message, StudentId, OnRowClick, DateInserted } = item;
              return (
                <div class="ToDo-box">
                  <div className="col-12">
                    <ul className="alert-list">
                      <li
                        className="alert-list-item"
                        style={{ borderBottom: 'none' }}
                        onClick={() => {
                          this.props.history.push(OnRowClick);
                        }}
                      >
                        <div className="alert-border-left">
                          <span className="title">{renderHTML(Message)}</span>
                          <span className="date">
                            {DateInserted && moment(DateInserted).from(new Date())}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ),
    },
    {
      Name: 'Tasks',
      // Badge: this.state.NoteStudent.length > 0 ? this.state.NoteStudent[0].NoteCount : '0',
      ExtraComponent: () => (
        <div class="pt-4 px-2" id="tasksScrollableDiv" style={{ height: 600, overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={this.state.TaskListArray.length}
            next={() => {
              let { TaskId } = this.state.TaskListArray[this.state.TaskListArray.length - 1];
              this.getPendingTasks({ PageSize: 10, TaskId: TaskId });
            }}
            hasMore={this.state.hasMoreTaskData}
            loader={<h4>Loading...</h4>}
            scrollableTarget="tasksScrollableDiv"
            endMessage={
              <p style={{ textAlign: 'center', marginTop: '15px' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {this.state.TaskListArray.map(item => {
              const {
                TodoType,
                TodoTitle,
                TaskId,
                TaskStudentId,
                StudentId,
                FirstName,
                LastName,
                ProfileImgAttachmentId,
                StudentImageUrl,
                AssignedDate,
                AssignedDateForSort,
                DueDate,
                DueDateForSort,
                DueDateFontColor,
              } = item;
              return (
                <div class="task-box dashboard-tabs">
                  <div class="row no-gutters w-100 justify-content-between align-items-center">
                    <div class="col-5 mb-1">
                      <div class="course-name" style={{ width: 'unset' }}>
                        <h6 className="text-capitalize">{TodoTitle}</h6>
                        <span class="due-date" style={{ minWidth: 'unset' }}>
                          <em>Due : </em>
                          {DueDate}
                        </span>
                      </div>
                    </div>
                    <div class="col-5 mb-1">
                      <div class="studentinfo">
                        <Link
                          to={{
                            pathname: `/studentProfile/${StudentId}`,
                          }}
                          class=" d-flex align-items-center"
                        >
                          <figure>
                            <img
                              src={StudentImageUrl}
                              class="rounded-circle face-fit-cover"
                              width="47"
                              height="47"
                              alt=""
                            />
                          </figure>
                          <span class="student-name">
                            {FirstName} {LastName}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="actions-button justify-content-xl-end">
                        <CustomModal
                          ModalButton={({ onClick }) => (
                            <Tooltip placement="top" title="Un-assign">
                              <span class="delete-course" onClick={onClick}>
                                <i class="fas fa-times" />
                              </span>
                            </Tooltip>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <h4 class="modal-title col-12">
                                  Do you want to un-assign this task?
                                </h4>
                              </div>

                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  class="btn btn-outline-primary"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn "
                                  onClick={() => {
                                    onOk();
                                    this.onTaskUnAssign(StudentId, TaskStudentId);
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        />
                        <CustomModal
                          ModalButton={({ onClick }) => (
                            <Tooltip placement="top" title="Complete">
                              <span class="select-course" onClick={onClick}>
                                <i class="select-icon" />
                              </span>
                            </Tooltip>
                          )}
                          ModalContent={({ onOk, onCancel }) => (
                            <div class="modal-content border-0">
                              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  &times;
                                </button>
                                <h4 class="modal-title col-12">
                                  Do you want to mark this task as complete?
                                </h4>
                              </div>

                              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                <button
                                  type="button"
                                  class="btn btn-outline-primary"
                                  data-dismiss="modal"
                                  onClick={onCancel}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn "
                                  onClick={() => {
                                    onOk();
                                    this.onTaskComplete(TaskStudentId, StudentId);
                                  }}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ),
    },
    {
      Name: 'Courses',
      // Badge: this.state.CourseStudent.length > 0 ? this.state.CourseStudent[0].CourseCount : '0',
      ExtraComponent: () => (
        <div class="pt-4 px-2" id="courseScrollableDiv" style={{ height: 600, overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={this.state.CourseListArray.length}
            next={() => {
              let { CourseCenterStudentId } = this.state.CourseListArray[
                this.state.CourseListArray.length - 1
              ];
              this.getPendingCourses({
                PageSize: 10,
                CourseCenterStudentId: CourseCenterStudentId,
              });
            }}
            hasMore={this.state.hasMoreCourseData}
            loader={<h4>Loading...</h4>}
            scrollableTarget="courseScrollableDiv"
            endMessage={
              <p style={{ textAlign: 'center', marginTop: '15px' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {this.state.CourseListArray.map(item => {
              const {
                CourseCenterStudentId,
                CourseId,
                CenterId,
                StudentId,
                EnrollDate,
                DueDate,
                DueDateForSort,
                DueDateFontColor,
                CourseName,
                CourseDescription,
                StudentImageUrl,
                FirstName,
                LastName,
              } = item;
              return (
                <div class="course-box">
                  <div class="row no-gutters w-100 justify-content-between mb-2">
                    <div class="course-info w-75">
                      <h5
                        class="card-title mb-2 h5"
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {CourseName}
                      </h5>
                      <Paragraph
                        ellipsis={{ rows: 2, expandable: false }}
                        textAlign="justify"
                        style={{ color: '#474747' }}
                      >
                        {CourseDescription}
                      </Paragraph>
                    </div>
                    <div class="actions-button justify-content-xl-end">
                      <CustomModal
                        ModalButton={({ onClick }) => (
                          <Tooltip placement="top" title="Un-assign">
                            <span class="delete-course" onClick={onClick}>
                              <i class="fas fa-times" />
                            </span>
                          </Tooltip>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 class="modal-title col-12">Are you sure you want to unassign?</h4>
                            </div>

                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                type="button"
                                class="btn btn-outline-primary"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                class="btn "
                                onClick={() => {
                                  onOk();
                                  this.onUnAssign(StudentId, CourseCenterStudentId);
                                }}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        )}
                      />
                      <Tooltip placement="top" title="Complete">
                        <span
                          class="select-course"
                          onClick={() => {
                            this.setState({
                              setScoreModal: true,
                              StudentId: StudentId,
                              CourseCenterStudentId: CourseCenterStudentId,
                            });
                          }}
                        >
                          <i class="select-icon" />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div class="row no-gutters w-100 justify-content-between flex-nowrap">
                    <div class="enroll-date course-date mb-1">
                      Enrolled on <em class="date">{EnrollDate}</em>
                    </div>
                    <div class="Finish-date course-date mb-1">
                      To be Finished
                      <em class="date" style={{ color: '#ff3b3b' }}>
                        {DueDate}
                      </em>
                    </div>
                    <div class="studentinfo">
                      <Link
                        to={{
                          pathname: `/studentProfile/${StudentId}`,
                        }}
                        class=" d-flex align-items-center"
                      >
                        <figure>
                          <img
                            src={StudentImageUrl}
                            class="rounded-circle face-fit-cover"
                            width="47"
                            height="47"
                            alt=""
                          />
                        </figure>
                        <span class="student-name">
                          {FirstName} {LastName}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ),
    },
    {
      Name: 'Growth Plan',
      // Badge:
      //   this.state.StudentContract.length > 0 ? this.state.StudentContract[0].ContractCount : '0',
      ExtraComponent: () => (
        <div class="pt-4 px-2" id="contractScrollableDiv" style={{ height: 600, overflow: 'auto' }}>
          <InfiniteScroll
            dataLength={this.state.ContractListArray.length}
            next={() => {
              let { StudentContractId } = this.state.ContractListArray[
                this.state.ContractListArray.length - 1
              ];
              this.getPendingContracts({ PageSize: 10, StudentContractId: StudentContractId });
            }}
            hasMore={this.state.hasMoreCotractData}
            loader={<h4>Loading...</h4>}
            scrollableTarget="contractScrollableDiv"
            endMessage={
              <p style={{ textAlign: 'center', marginTop: '15px' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {this.state.ContractListArray.map(item => {
              const {
                StudentContractId,
                ContractName,
                StudentId,
                StartDate,
                DueDate,
                DueDateForSort,
                DueDateFontColor,
                DiscardedDate,
                CompletedDate,
                ContractStatus,
                StudentImageUrl,
                FirstName,
                LastName,
              } = item;
              const contractStatus = {
                '-1': {
                  color: '#fef2f2',
                  date: DiscardedDate,
                  name: 'Discarded On',
                },
                '0': {
                  color: '#f8f8f8',
                  date: DueDate,
                  name: 'Due',
                  dateFontColor: DueDateFontColor,
                  gotoContractsPage: true,
                },
                '1': {
                  color: 'rgb(239, 247, 254)',
                  date: CompletedDate,
                  name: 'Completed On',
                },
              };
              return (
                <div
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <div
                    class="task-box"
                    style={{ backgroundColor: contractStatus[ContractStatus].color }}
                  >
                    <div class="row no-gutters w-100 justify-content-between align-items-center">
                      <div class="col-12 col-md-7 mb-1">
                        <div
                          class="course-name"
                          style={{
                            width: '100%',
                            cursor: contractStatus[ContractStatus].gotoContractsPage
                              ? 'pointer'
                              : '',
                          }}
                          onClick={() => {
                            contractStatus[ContractStatus].gotoContractsPage &&
                              this.props.history.push(
                                `/newcontract?studentId=${StudentId}&contractId=${StudentContractId}`,
                              );
                            sessionStorage.setItem('displayName', LastName + FirstName);
                          }}
                        >
                          <h6>{ContractName}</h6>
                          <div class="row">
                            <div class="col-12 col-md-auto mr-2">
                              <span class="due-date">
                                <em>Assigned: </em> {StartDate}
                              </span>
                            </div>
                            <div class="col-12 col-md-auto">
                              <span
                                class="due-date"
                                style={{ color: contractStatus[ContractStatus].dateFontColor }}
                              >
                                <em>{contractStatus[ContractStatus].name} : </em>{' '}
                                {contractStatus[ContractStatus].date}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-5 mb-1">
                        <div class="studentinfo">
                          <Link
                            to={{
                              pathname: `/studentProfile/${StudentId}`,
                            }}
                            class=" d-flex align-items-center"
                          >
                            <figure>
                              <img
                                src={StudentImageUrl}
                                class="rounded-circle face-fit-cover"
                                width="47"
                                height="47"
                                alt=""
                                style={{ flex: '1' }}
                              />
                            </figure>
                            <span class="student-name" style={{ flex: '2' }}>
                              {FirstName} {LastName}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ),
    },
  ];
  noteTabComponent = {
    Name: 'Notes',
    // Badge: this.state.NoteStudent.length > 0 ? this.state.NoteStudent[0].NoteCount : '0',
    ExtraComponent: () => (
      <div class="pt-4 px-2" id="noteScrollableDiv" style={{ height: 600, overflow: 'auto' }}>
        <InfiniteScroll
          dataLength={this.state.NoteListArray.length}
          next={() => {
            let { StudentNoteId } = this.state.NoteListArray[this.state.NoteListArray.length - 1];
            this.getPendingNote({ PageSize: 10, StudentNoteId: StudentNoteId });
          }}
          hasMore={this.state.hasMoreNoteData}
          loader={<h4>Loading...</h4>}
          scrollableTarget="noteScrollableDiv"
          endMessage={
            <p style={{ textAlign: 'center', marginTop: '15px' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {this.state.NoteListArray.map(item => {
            const {
              StudentId,
              StudentNoteId,
              Note,
              NoteTypes,
              IsConfidential,
              DateEntered,
              EnteredBy,
              StudentImageUrl,
              FirstName,
              LastName,
              IsDischargeNote,
              IsDeleteAllowed,
              OnRowClick,
            } = item;
            return (
              <div class="note-box" key={uuid()}>
                <div class="row">
                  <div class="col-12 col-md-7">
                    <h6 class="note-type">
                      {NoteTypes}
                      {IsConfidential && (
                        <span class="ml-2 alert alert-danger confidential-alert border-0 text-uppercase">
                          <i class="fas fa-lock" /> Confidential
                        </span>
                      )}
                    </h6>
                    <span class="note-author">by {EnteredBy}</span>
                    <br />
                    <span class="note-date" style={{ textAlign: 'left' }}>
                      {DateEntered}
                    </span>
                  </div>
                  <div class="col-12 col-md-5">
                    <div
                      class="studentinfo d-flex align-items-center"
                      style={{ padding: '0 5px 0 5px' }}
                    >
                      <span
                        onClick={() => {
                          this.props.history.push(OnRowClick || `/studentProfile/${StudentId}`);
                        }}
                        class=" d-flex align-items-center"
                      >
                        <figure>
                          <img
                            src={StudentImageUrl}
                            class="rounded-circle face-fit-cover mr-2"
                            width="47"
                            height="47"
                            alt=""
                            style={{ cursor: 'pointer' }}
                          />
                        </figure>
                        <span class="student-name">
                          {FirstName} {LastName}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col-12 pt-2">
                    <p class="note-text mb-0">
                      {IsDeleteAllowed ? Note && renderHTML(Note) : Note}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    ),
  };
  render() {
    const me = this;
    const { activeTab, barOptions, barSeries, ListArray, hasMoreData } = this.state;
    const { dashboardData, announcementData, isFetching, DashboardMenu } = this.props;
    const {
      ClientType,
      ClientTypeTotal,
      ClientAvgScore,
      ClientEngagement,
      ClientCostofCare,
      ClientCostofCareTotal,
    } = dashboardData || {};
    const {
      IsTaskCard,
      IsInHouseRevenueCard,
      IsSmallGroupRevenueCard,
      IsAppUserRevenueCard,
      IsClientListCard,
    } = DashboardMenu || {};
    const { TotalClient, ActiveClient, InActiveClient } =
      (ClientTypeTotal && ClientTypeTotal[0] && ClientTypeTotal[0]) || {};
    const { SPBehavioral, Faith, Addiction, Gateway } =
      (ClientAvgScore && ClientAvgScore[0] && ClientAvgScore[0]) || {};

    if (dashboardData) {
      const { ChartData, DashboardBlocks } = dashboardData;
    }

    var role = localStorage.getItem('userRole');
    const ClientTypeColumns = [
      // {
      //   title: '',
      //   dataIndex: 'Category',
      //   key: 'Category',
      //   align: 'right',
      //   render: (text, record) => {
      //     return (
      //       <div className="">
      //         <span style={{ fontWeight: 'bold' }}>{text && text}</span>
      //       </div>
      //     );
      //   },
      // },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && text}</span>
            </div>
          );
        },
      },
      {
        title: 'Total',
        dataIndex: 'Total',
        key: 'Total',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && numberFormat.format(text)}</span>
            </div>
          );
        },
      },
    ];

    const TasksCompletedColumns = [
      {
        title: 'Client Engagement',
        dataIndex: 'ClientEngagement',
        key: 'ClientEngagement',
        align: 'left',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && text}</span>
            </div>
          );
        },
      },
      {
        title: 'Total Client Engagement',
        dataIndex: 'TotalClientEngagement',
        key: 'TotalClientEngagement',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && numberFormat.format(text)}</span>
            </div>
          );
        },
      },
      {
        title: 'Engagement Per Client',
        dataIndex: 'EngagementPerClient',
        key: 'EngagementPerClient',
        align: 'center',
        render: (text, record) => {
          return (
            <div className="">
              <span style={{ fontWeight: 'bold' }}>{text && numberFormat.format(text)}</span>
            </div>
          );
        },
      },
    ];
    return (
      <div className="in-house">
        <div className="d-flex justify-content-between mb-3">
          <h3 class="page-title d-none">Statistics</h3>
          {/* 
          <h5
            class="page-title"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              this.props.history.push('/dataAnalytics');
            }}
          >
            Analytics
          </h5> */}
          {/* {role === 'Super Admin' && (
            
          )} */}
          {/* <Link
            className="btn px-4"
            to={{
              pathname: '/dashboardAnalytics',
            }}
            target="_blank"
          >
            Analytics
          </Link> */}
          <Loader loading={isFetching} />
          <div class="text-right d-none">
            {dashboardData && dashboardData.DashboardMisc[0].Label.toUpperCase() + ': '}
            {'  '}
            <span style={{ fontWeight: 900, fontSize: '21px' }}>
              {dashboardData && dashboardData.DashboardMisc[0].Score}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-6 dashboard-col-left">
            <div class="row dashboard-analytics client-type mb-5">
              <div class="col-12">
                <div class="card border-0">
                  <div class="row card-body px-4">
                    <div class="col-6 py-3">
                      <span class="client-type-header mb-3">{'Client Types'}</span>
                    </div>
                    <div class="col-6 py-3">
                      {/* <input
                        type="button"
                        class="btn btn-outline-primary mr-2"
                        value="Purchase Analytics Package"
                      /> */}
                    </div>
                    <div class="col-12">
                      <div className="custom-header">
                        <Table
                          dataSource={ClientType || []}
                          columns={ClientTypeColumns}
                          bordered
                          pagination={false}
                        />
                      </div>
                      <div className="client-type-count">
                        <div className="mr-3">
                          <span className="label-text">Total clients </span>
                          <span className="mr-1" style={{ color: '#4AB0FE', fontWeight: '800' }}>
                            {TotalClient && numberFormat.format(TotalClient)}
                          </span>
                          {' | '}
                        </div>
                        <div className="mr-3">
                          <span className="label-text">Active </span>
                          <span className="mr-1" style={{ color: '#2FE192', fontWeight: '800' }}>
                            {ActiveClient && numberFormat.format(ActiveClient)}&nbsp;&nbsp;
                          </span>
                          {' | '}
                        </div>
                        <div className="mr-3">
                          <span className="label-text">Inactive </span>
                          <span className="mr-1" style={{ color: '#E87A7A', fontWeight: '800' }}>
                            {InActiveClient && numberFormat.format(InActiveClient)}
                          </span>{' '}
                        </div>
                      </div>
                    </div>
                    <div class="col-12 py-3">
                      <h6 class="average-score-header mb-3">{'Average Scores'}</h6>
                    </div>
                    <div class="col-sm-6 col-lg-3 text-center">
                      <div class="mb-4">
                        <Progress
                          type="circle"
                          strokeWidth={10}
                          strokeColor="#2fe192"
                          width={130}
                          percent={SPBehavioral}
                          format={percent => (
                            <div class="progress-textbox">
                              <strong>{percent}</strong>
                              score
                            </div>
                          )}
                          trailColor={'#eff7fe'}
                        />
                      </div>
                      <h5 className="progress-subtitle">SP Behavioral</h5>
                    </div>
                    <div class="col-sm-6 col-lg-3 text-center">
                      <div class="mb-4">
                        <Progress
                          type="circle"
                          strokeWidth={10}
                          strokeColor="#46b4fe"
                          width={130}
                          percent={Faith}
                          format={percent => (
                            <div class="progress-textbox">
                              <strong>{percent}</strong>
                              score
                            </div>
                          )}
                          trailColor={'#eefdf8'}
                        />
                      </div>
                      <h5 className="progress-subtitle">Faith</h5>
                    </div>
                    <div class="col-sm-6 col-lg-3 text-center">
                      <div class="mb-4">
                        <Progress
                          type="circle"
                          strokeWidth={10}
                          width={130}
                          strokeColor="#ff5d5d"
                          percent={Addiction}
                          format={percent => (
                            <div class="progress-textbox">
                              <strong>{percent}</strong>
                              score
                            </div>
                          )}
                          trailColor={'#fef2f2'}
                        />
                      </div>
                      <h5 className="progress-subtitle">Addiction</h5>
                    </div>
                    <div class="col-sm-6 col-lg-3 text-center">
                      <div class="mb-4">
                        <Progress
                          type="circle"
                          strokeWidth={10}
                          width={130}
                          strokeColor="#FFBF00"
                          percent={Gateway}
                          format={percent => (
                            <div class="progress-textbox">
                              <strong>{percent}</strong>
                              score
                            </div>
                          )}
                          trailColor={'#FFF9E5'}
                        />
                      </div>
                      <h5 className="progress-subtitle">Gateway</h5>
                    </div>
                    <div class="col-12 mt-3">
                      <div className="custom-header">
                        <Table
                          dataSource={ClientEngagement || []}
                          columns={TasksCompletedColumns}
                          bordered
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row d-none">
              {dashboardData &&
                dashboardData.DashboardBlocks.map((item, index) => {
                  const { Label, Score, Denominator, URL, Filter, chartData } = item;

                  return (
                    <div class="col-sm-6  mb-4">
                      <div class="card border-0 inner-content">
                        <div class="row  align-items-center card-body">
                          <div class="col-12 pt-3">
                            <h6 class="text-uppercase font-weight-bold mb-3">{Label}</h6>
                          </div>
                          <div class="col-xl-7 col-8">
                            <span class="value">
                              <span>{Score}</span>
                              {Denominator && '/' + Denominator}
                            </span>
                          </div>
                          <div class="col-xl-5 col-4 text-right">
                            <div class="graph-img">
                              <Sparklines data={chartData}>
                                <SparklinesLine color="#179bef" />
                              </Sparklines>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {IsTaskCard && (
            <div class="col-12 col-xl-6 dashboard-tabcol dashboard-col-right mb-5 res-update">
              <div class="card border-0" style={{ height: '755px', overflowY: 'scroll' }}>
                <div class="card-body center-tabs">
                  <ListRenderer
                    list={this.list2}
                    activeKey={this.state.activeKey}
                    getActiveKey={activeKey => this.setState({ activeKey })}
                    scuShouldReturnTrue={true}
                  />
                </div>
              </div>
            </div>
          )}
          {IsInHouseRevenueCard && (
            <div class="col-12 col-xl-6 mb-4">
              <div class="card border-0">
                <div class="row  card-body px-3">
                  <div class="col-12">
                    <h3>{ClientCostofCareTotal}</h3>
                    <h5>Total Cost of Care</h5>
                    <div>
                      <ReactApexChart
                        options={barOptions}
                        series={barSeries}
                        // series={[
                        //   {
                        //     name: 'Total Active',
                        //     type: 'column',
                        //     data: [8489, 9322, 10631],
                        //   },
                        //   {
                        //     name: 'Newly added',
                        //     type: 'line',
                        //     data: [435, 9385, 1309],
                        //     // data: [1000, 3000, 6000, 9000, 12000, 15000]
                        //   },
                        // ]}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div class="col-lg-6 col-xl-6 mb-4">
            <div class="card border-0 ">
              <div class="row card-body d-none">
                <div class="col-12 mb-lg-3">
                  <h5 class="text-uppercase pt-4">Map</h5>
                  <div>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {IsClientListCard && (
          <div class="row mt-5" style={{ backgroundColor: 'white' }}>
            <div class="col-md-12">{<Client {...this.props} />}</div>
          </div>
        )}
        {announcementData && announcementData.length >= 0 && (
          <div class="row" id="announcements-section">
            <div class="col-12">
              <div class="row justify-content-between align-items-baseline mb-4 mt-3 announcements-row">
                <div class="col-12 col-sm-auto ">
                  <h3 class="page-title mb-0">Announcements</h3>
                </div>
              </div>
              {announcementData.length <= 0 ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '250px' }}
                >
                  <h4 className="no-announcements">No New Announcements</h4>
                </div>
              ) : (
                <div class="row">
                  {announcementData.map((item, index) => {
                    const {
                      Abstract,
                      AnnouncementId,
                      CenterId,
                      EndDate,
                      ImageUrl,
                      IsMain,
                      Link,
                      RankId,
                      StartDate,
                      Title,
                      DaysAgo,
                      Tense,
                    } = item;
                    if (Tense == 'Current' || role == 'Super Admin') {
                      return (
                        <div class="col-sm-6 col-xl-4 mb-4">
                          <div
                            class="card announcements-card border-0 "
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (Link) {
                                const win = window.open(Link, '_blank');
                                if (win != null) {
                                  win.focus();
                                }
                              }
                            }}
                          >
                            <figure
                              class="images-placeholder"
                              style={{
                                backgroundImage: `url(${ImageUrl})`,
                              }}
                            >
                              <img
                                class="card-img-top img-background"
                                src="images/announcements-img1.jpg"
                                alt=""
                              />
                            </figure>
                            <div class="announcement-card-body">
                              {DaysAgo === 0 ? (
                                <span class="date">Today</span>
                              ) : (
                                <span class="date">{DaysAgo} day(s) ago</span>
                              )}
                              {/* <span>{this.getDuration(SubscriberProfile.AppJoiningDate)}</span> */}
                              <h5 class="card-title mb-2 h5">{Title}</h5>
                              <Paragraph
                                // ellipsis={{ rows: 3, expandable: false }}
                                className="card-body-text"
                                // style={{ color: '#828282' }}
                              >
                                {Abstract}
                              </Paragraph>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        <Modal
          visible={this.state.setScoreModal}
          closable={false}
          footer={null}
          width={600}
          destroyOnClose={true}
        >
          <ScoreModal
            onCancel={() => {
              this.setState({
                setScoreModal: false,
              });
            }}
            dispatch={this.props.dispatch}
            onMarkComplete={this.onMarkComplete}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({
  dashboard_get,
  announcement_get_all,
  survey_task_mark_in_complete,
  student_task_mark_complete,
  student_task_unassign,
  student_unassign,
  student_mark_complete,
  dashboard_pending_survey_list_get,
  student_note_get_all,
  dashboard_task_list_get,
  dashboard_course_list_get,
  dashboard_contract_list_get,
  dashboard_alert_list_get,
  user_feature_flag_get,
}) => {
  const { data, error, isFetching } = dashboard_get;
  const { data: pendingSurvey } = dashboard_pending_survey_list_get;
  const { data: pendingNote } = student_note_get_all;
  const { data: pendingTasks } = dashboard_task_list_get;
  const { data: pendingCourses } = dashboard_course_list_get;
  const { data: pendingContracts } = dashboard_contract_list_get;
  const { data: pendingAlerts } = dashboard_alert_list_get;
  const { data: fFData } = user_feature_flag_get;

  let dashboardData = null,
    announcementData = null,
    isMarkComplete = false,
    isUnAssigned = false,
    isTaskMarkComplete = false,
    isTaskUnAssigned = false,
    isHideNotesTab = false,
    isTaskMarkInComplete = false;
  let pendingSurveyList = [];
  let notesList = [];
  let tasksList = [],
    alertsList = [],
    coursesList = [],
    contractsList = [];
  let NoteStudent = [];

  if (data) {
    for (let i = 0; i < data.DashboardBlocks.length; i++) {
      if (data.ChartData[i] && data.ChartData[i].DataAgg)
        data.DashboardBlocks[i].chartData = data.ChartData[i].DataAgg.split(',');
    }
    dashboardData = data;
    NoteStudent = data.NoteStudent;
  }

  if (announcement_get_all.data) {
    announcementData = Object.values(announcement_get_all.data).filter(
      item => item.IsAnnouncementActive,
    );
  }

  if (student_mark_complete && student_mark_complete.data) {
    isMarkComplete = true;
  }

  if (student_unassign && student_unassign.data) {
    isUnAssigned = true;
  }

  if (student_task_unassign && student_task_unassign.data) {
    isTaskUnAssigned = true;
  }

  if (student_task_mark_complete && student_task_mark_complete.data) {
    isTaskMarkComplete = true;
  }

  if (survey_task_mark_in_complete && survey_task_mark_in_complete.data) {
    isTaskMarkInComplete = true;
  }
  if (pendingSurvey && pendingSurvey.Surveys) {
    pendingSurveyList = pendingSurvey.Surveys;
  }
  if (pendingNote && pendingNote.NoteStudent) {
    notesList = pendingNote.NoteStudent;
  }
  if (pendingTasks && pendingTasks.TaskStudent) {
    tasksList = pendingTasks.TaskStudent;
  }

  if (pendingCourses && pendingCourses.CourseStudent) {
    coursesList = pendingCourses.CourseStudent;
  }
  if (pendingContracts && pendingContracts.StudentContract) {
    contractsList = pendingContracts.StudentContract;
  }
  if (pendingAlerts && pendingAlerts.ToDo) {
    alertsList = pendingAlerts.ToDo;
  }
  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    isHideNotesTab = !fFData.FeatureFlag[0].HideNotes;
  }
  return {
    dashboardData,
    NoteStudent,
    announcementData,
    isMarkComplete,
    isUnAssigned,
    isTaskMarkComplete,
    isTaskUnAssigned,
    isTaskMarkInComplete,
    isFetching,
    pendingSurveyList,
    notesList,
    tasksList,
    alertsList,
    contractsList,
    coursesList,
    isHideNotesTab,
  };
};

export default connect(mapStateToProps)(withRouter(InHouse));
