import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate, {
  dateofbirthNormalize,
  MobileFormatter,
  MobileNormalize,
  ssnFormatter,
  ssnNormalize,
} from '../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxTextarea,
  ReduxPhoneInput,
} from '../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { listCombos } from '../../../store/actions';

const SupportContact = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  StateProvince,
  Country,
  change,
  disable,
  existingRows,
  dispatch,
  Filter_State,
}) => {
  const toogleChange = (bool, currentIndex) => {
    if (bool) {
      fields.forEach((field, index) => {
        if (currentIndex !== index) {
          change(`supportContacts[${index}].isEmergencyContact`, false);
        } else {
          change(`supportContacts[${index}].isEmergencyContact`, true);
        }
      });
    }
  };
  // const countryChange = (countryISO, currentIndex) => {
  //   if (countryISO) {
  //     dispatch(
  //       listCombos({
  //         comboTypes: 'StateProvince',
  //         CountryISO: countryISO,
  //       }),
  //     );
  //   }
  // };
  return (
    <>
      {fields.map((supportContact, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>Support Contact {index + 1}</h6>
              </div>
              <div class="col-12 col-md-6">
                <a class="delete-contact" onClick={() => fields.remove(index)} disabled={disable}>
                  <i class="fas fa-trash" /> Delete Contact
                </a>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.firstName`}
                    type="text"
                    validate={validate.required}
                    component={ReduxInput}
                    fieldName={'First Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">First Name*</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.middleName`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Middle Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.lastName`}
                    type="text"
                    validate={validate.required}
                    component={ReduxInput}
                    fieldName={'Last Name'}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Last Name*</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="custom-control-label" for="is-emergency-contact">
                    Is Emergency Contact {'   '}
                    <Field
                      name={`${supportContact}.isEmergencyContact`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                      // onChange={bool => toogleChange(bool, index)}
                      disabled={disable}
                    />
                  </label>
                </div>
              </div>

              {existingRows && existingRows[index].isEmergencyContact && (
                <div class="col-12 col-md-6 col-lg-4 d-flex">
                  <div class="form-group w-100">
                    <Field
                      name={`${supportContact}.emergencyCN`}
                      type="text"
                      validate={[validate.required, validate.LandlineNumber]}
                      fieldName="Emergency Contact"
                      component={ReduxInput}
                      disabled={disable}
                    />
                    <label class="placeholder-label">Emergency Contact*</label>
                  </div>
                </div>
              )}

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.relationshipId`}
                    validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder="Relationship*"
                    options={RelationShip}
                    fieldName={'Relationship'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.line1`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Address Line 1</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.line2`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Permanent Address Line 2</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.country`}
                    type="text"
                    errorMessage="Country Required"
                    component={ReduxSelect}
                    options={Country}
                    // validate={Validator.required}
                    // onChange={countryISO => countryChange(countryISO, index)}
                    placeholder="Country"
                    fieldName={'Country'}
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.city`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">City</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.stateOrProvince`}
                    type="text"
                    component={ReduxSelect}
                    options={Filter_State && Filter_State[existingRows[index].country]}
                    placeholder="State/Province/Territories"
                    fieldName="State/Province/Territories"
                    disabled={disable}
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.zip`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Postal/Zip Code</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4 d-flex">
                <div class="form-group w-100">
                  <Field
                    name={`${supportContact}.primaryCN`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                    fieldName={'Phone'}
                    validate={validate.NumericMobile}
                    format={MobileFormatter}
                    normalize={MobileNormalize}
                  />
                  <label class="placeholder-label">Phone</label>
                </div>
              </div>

              {/* {isEmergencyContactValue ?
                <div class="col-12 col-md-6 col-lg-4 d-flex">
                  <div class="form-group w-100">
                    <Field name={`${supportContact}.primaryCN`} type="text" validate={validate.required} component={ReduxInput} disabled={disable} />
                    <label class="placeholder-label">Home*</label>
                  </div>
                </div>
                :
                <div class="col-12 col-md-6 col-lg-4 d-flex">
                  <div class="form-group w-100">
                    <Field name={`${supportContact}.primaryCN`} type="text" component={ReduxInput} disabled={disable} />
                    <label class="placeholder-label">Home</label>
                  </div>
                </div>

              } */}

              {/* <div class="col-12 col-md-6 col-lg-4 d-flex"> */}
              {/* <div class="form-group pr-1 phone-code">
                  <select class="custom-select enrollment-select ">
                    <option selected="">+ 91</option>
                    <option value="">+ 44</option>
                    <option value="">+ 33 </option>
                  </select>
                </div> */}
              {/* <div class="form-group w-100">
                  <Field name={`${supportContact}.primaryCN`} type="text" component={ReduxInput} disabled={disable} />
                  <label class="placeholder-label">Home</label>
                </div>
              </div> */}
              {/* <div class="form-group pr-1 phone-code">
                  <select class="custom-select enrollment-select ">
                    <option selected="">+ 91</option>
                    <option value="">+ 44</option>
                    <option value="">+ 33 </option>
                  </select>
                </div> */}
              <div class="col-12 col-md-6 col-lg-4 d-flex">
                <div class="form-group w-100">
                  <Field
                    name={`${supportContact}.secondaryCN`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Other</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.email`}
                    type="text"
                    component={ReduxInput}
                    disabled={disable}
                  />
                  <label class="placeholder-label">E-mail</label>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-8">
                <div class="form-group">
                  <Field
                    name={`${supportContact}.notes`}
                    type="textarea"
                    component={ReduxTextarea}
                    disabled={disable}
                  />
                  <label class="placeholder-label">Notes</label>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}>
            {fields.length >= 1 && (
              <label>
                <a> + Add Another Contact</a>
              </label>
            )}
            {fields.length == 0 && (
              <label>
                {' '}
                <a> + Add Contact</a>
              </label>
            )}
          </a>
        </div>
      </div>
    </>
  );
};
const SupportContact1 = connect(state => {
  const isEmergencyContactValue = selector(state, 'isEmergencyContact');
  return {
    isEmergencyContactValue,
  };
})(SupportContact);

const ContactInformation = props => {
  const {
    handleSubmit,
    handleNext,
    name,
    RelationShip,
    StateProvince,
    Country,
    style,
    change,
    goToPreviuos,
    existingRows,
    disable,
    Filter_State,
    onLastSubmit,
    currentTab,
    tabsCount,
    handleSubmitAndNext,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(data => {
        handleSubmitAndNext(data, currentTab, tabsCount);
      })}
      style={style}
    >
      <FieldArray
        name={name}
        component={SupportContact1}
        RelationShip={RelationShip}
        StateProvince={StateProvince}
        Filter_State={Filter_State}
        Country={Country}
        change={change}
        existingRows={existingRows}
        disable={disable}
      />
      <div class="row pagination-button">
        <div class="col-12 text-right">
          {currentTab > 0 && (
            <button
              onClick={goToPreviuos}
              type="button"
              class="btn btn-outline-primary btn-eForm-Prev mr-2"
              disabled={disable}
            >
              Previous
            </button>
          )}
          {currentTab + 1 != tabsCount && (
            <button
              type="submit"
              // onClick={handleNext}
              class="btn btn-eForm-Next"
              disabled={disable}
            >
              Next
            </button>
          )}
          {currentTab + 1 == tabsCount && (
            <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect((state, props) => {
  const { combos } = state;
  const existingRows = selector(state, `${props.name}`);

  return {
    RelationShip: combos.data.RelationShip,
    StateProvince: combos.data.StateProvince,
    Filter_State: combos.data.Filter_StateProvince,
    Country: combos.data.Country,
    existingRows,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(ContactInformation),
);
const selector = formValueSelector('wizard');
