import React, { Component } from 'react';

const TermsAndConditions=()=> {
 return (
   <React.Fragment>
     <div class="container mt-5">
       <h5 className="text-center" style={{ fontSize: '38px', color: '#46b4fe' }}>
         Terms and Conditions
       </h5>
       <p>
         These terms and conditions ("Terms", "Agreement") are an agreement between Sober Peer, LLC
         ("Sober Peer, LLC", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement
         sets forth the general terms and conditions of your use of the provider.soberpeer.com
         website and any of its products or services (collectively, "Website" or "Services").
       </p>

       <h5>Accounts and Membership</h5>
       <p>
         You must be at least 18 years of age to use this Website. By using this Website and by
         agreeing to this Agreement you warrant and represent that you are at least 18 years of age.
         If you create an account on the Website, you are responsible for maintaining the security
         of your account and you are fully responsible for all activities that occur under the
         account and any other actions taken in connection with it. We may monitor and review new
         accounts before you may sign in and use our Services. Providing false contact information
         of any kind may result in the termination of your account. You must immediately notify us
         of any unauthorized uses of your account or any other breaches of security. We will not be
         liable for any acts or omissions by you, including any damages of any kind incurred as a
         result of such acts or omissions. We may suspend, disable, or delete your account (or any
         part thereof) if we determine that you have violated any provision of this Agreement or
         that your conduct or content would tend to damage our reputation and goodwill. If we delete
         your account for the foregoing reasons, you may not re-register for our Services. We may
         block your email address and Internet protocol address to prevent further registration.
       </p>

       <h5>User Content</h5>
       <p>
         We do not own any data, information, or material ("Content") that you submit on the Website
         in the course of using the Service. You shall have sole responsibility for the accuracy,
         quality, integrity, legality, reliability, appropriateness, and intellectual property
         ownership or right to use of all submitted Content. We may monitor and review Content on
         the Website submitted or created using our Services by you. Unless specifically permitted
         by you, your use of the Website does not grant us the license to use, reproduce, adapt,
         modify, publish, or distribute the Content created by you or stored in your user account
         for commercial, marketing or any similar purpose. But you grant us permission to access,
         copy, distribute, store, transmit, reformat, display, and perform the Content of your user
         account solely as required for the purpose of providing the Services to you. Without
         limiting any of those representations or warranties, we have the right, though not the
         obligation, to, in our own sole discretion, refuse or remove any Content that, in our
         reasonable opinion, violates any of our policies or is in any way harmful or objectionable.
       </p>

       <h5>Billing and Payments</h5>
       <p>
         You shall pay all fees or charges to your account in accordance with the fees, charges, and
         billing terms in effect at the time a fee or charge is due and payable. If auto-renewal is
         enabled for the Services you have subscribed for, you will be charged automatically in
         accordance with the term you selected. If, in our judgment, your purchase constitutes a
         high-risk transaction, we will require you to provide us with a copy of your valid
         government-issued photo identification, and possibly a copy of a recent bank statement for
         the credit or debit card used for the purchase. We reserve the right to change product
         pricing at any time. We also reserve the right to refuse any order you place with us. We
         may, in our sole discretion, limit or cancel quantities purchased per person, per household
         or per order. These restrictions may include orders placed by or under the same customer
         account, the same credit card, and/or orders that use the same billing and/or shipping
         address. In the event that we make a change to or cancel an order, we may attempt to notify
         you by contacting the e-mail and/or billing address/phone number provided at the time the
         order was made.
       </p>

       <h5>Accuracy of Information</h5>
       <p>
         Occasionally there may be information on the Website that contains typographical errors,
         inaccuracies or omissions that may relate to product descriptions, pricing, availability,
         promotions and offers. We reserve the right to correct any errors, inaccuracies, or
         omissions, and to change or update information or cancel orders if any information on the
         Website or on any related Service is inaccurate at any time without prior notice (including
         after you have submitted your order). We undertake no obligation to update, amend or
         clarify information on the Website including, without limitation, pricing information,
         except as required by law. No specified update or refresh date applied on the Website
         should be taken to indicate that all information on the Website or on any related Service
         has been modified or updated.
       </p>

       <h5>Uptime Guarantee</h5>
       <p>
         We offer a Service uptime guarantee of 99% of available time per month. The service uptime
         guarantee does not apply to service interruptions caused by: (1) periodic scheduled
         maintenance or repairs we may undertake from time to time; (2) interruptions caused by you
         or your activities; (3) outages that do not affect core Service functionality; (4) causes
         beyond our control or that are not reasonably foreseeable; and (5) outages related to the
         reliability of certain programming environments.
       </p>

       <h5>Backups</h5>
       <p>
         We perform regular backups of the Website and Content and will do our best to ensure
         completeness and accuracy of these backups. In the event of the hardware failure or data
         loss we will restore backups automatically to minimize the impact and downtime.
       </p>

       <h5>Links to Other Websites</h5>
       <p>
         Although this Website may link to other websites, we are not, directly or indirectly,
         implying any approval, association, sponsorship, endorsement, or affiliation with any
         linked website, unless specifically stated herein. We are not responsible for examining or
         evaluating, and we do not warrant the offerings of, any businesses or individuals or the
         content of their websites. We do not assume any responsibility or liability for the
         actions, products, services, and content of any other third-parties. You should carefully
         review the legal statements and other conditions of use of any website which you access
         through a link from this Website. Your linking to any other off-site websites is at your
         own risk.
       </p>

       <h5>Changes and Amendments</h5>
       <p>
         We reserve the right to modify this Agreement or its policies relating to the Website or
         Services at any time, effective upon posting of an updated version of this Agreement on the
         Website. When we do, we will revise the updated date at the bottom of this page. Continued
         use of the Website after any such changes shall constitute your consent to such changes.
       </p>

       <h5>Contacting Us</h5>
       <p>
         If you would like to contact us to understand more about this Agreement or wish to contact
         us concerning any matter relating to it, you may send an email to {}
         <a href="mailto:support@soberpeer.com">support@soberpeer.com</a>
       </p>

       <h5>Acceptance of these Terms</h5>
       <p>
         You acknowledge that you have read this Agreement and agree to all its terms and
         conditions. By using the Website or its Services you agree to be bound by this Agreement.
         If you do not agree to abide by the terms of this Agreement, you are not authorized to use
         or access the Website and its Services.
       </p>

       <p>
         This document is incorporated as a part of the Company’s overall policies, terms and
         conditions, and acceptable use standards for any person using the Website and/or Services.
         We reserve our right to be the sole arbiter in interpreting and applying the policy,
         including determining the seriousness of any violation and any corrective actions.
       </p>

       <p className='pb-5'>This document was last updated on July 9, 2023.</p>
     </div>
   </React.Fragment>
 );
}



export default TermsAndConditions;
