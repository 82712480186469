import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Modal } from 'antd';

import ClientInformation from './studentInformation';
// import Banner from '../Banner';
import ProfileHeader from '../../customScreens/profileHeader2';

const { TabPane } = Tabs;

class SubscriberDemographics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabLabels: [
        {
          Name: 'Demographic',
          Component: props => <ClientInformation {...props} />,
        },
      ],
      activeKey: 0,
    };
  }
  getUserGuid() {
    if (
      this.props.location.pathname.split('/')[1] == 'subscriberProfile' ||
      this.props.location.pathname.split('/')[1] == 'subscribers-survery'
    ) {
      return this.props.location.pathname.split('/')[2];
    } else {
      return this.props.location.search.split('=')[1];
    }
  }
  render() {
    const { activeKey, tabLabels } = this.state;

    return (
      <div class="main enrollment-page mb-2">
        <div class="container-fluid">
          <div class="row top-heading-row mt-1 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <span className="">
                <a
                  class="back-link d-flex align-items-center"
                  onClick={() => this.props.history.goBack()}
                >
                  <img src="images/back-arrow.svg" /> &nbsp; Back
                </a>
              </span>
              {/* <h4 class="form-main-head">
                {this.getUserGuid() ? 'Edit Student Information' : 'Student Information'}
              </h4> */}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ProfileHeader UserGuid={this.getUserGuid()} />
            </div>
          </div>
          <div class="content-area">
            <div class="col-12 pb-2 vertical-tabs px-0">
              <Tabs activeKey={String(activeKey)} tabPosition="left" defaultActiveKey="0">
                {tabLabels.map((item, index) => {
                  const { Component, Name } = item;
                  return (
                    <TabPane tab={Name} key={`${index}`} className="tab-content enrollment-form">
                      {activeKey == index && (
                        <Component style={{ padding: '36px 0px' }} UserGuid={this.getUserGuid()} />
                      )}
                    </TabPane>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(SubscriberDemographics));
