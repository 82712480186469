import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import renderHTML from 'react-render-html';
var timer;

class ProspectAutocompleteSearch extends Component {
  constructor() {
    super();
    this.state = {
      searchStr: '',
    };
  }
  onSearch = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.props.onSearch(value);
    }, 400);
  };
  render() {
    const { searchStr } = this.state;
    const { ListData, getSelectedData } = this.props;
    return (
      <div className="form-group has-search searchDiv">
        <Autocomplete
          // getItemValue={item => item}
          getItemValue={item => {
            return JSON.stringify(item.StudentId);
          }}
          items={ListData.map(item => {
            return {
              label: ` ${item.PreferredName} ( ${item.StudentId} )`,
              ...item,
            };
          })}
          renderItem={(item, isHighlighted) => (
            <div
              style={{
                background: isHighlighted ? 'lightgray' : 'white',
                padding: '10px 5px',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {renderHTML(
                `<img className="autoSelectImage" src=${
                  item.ProfileImgUrl ? item.ProfileImgUrl : 'images/blank-profile.png'
                }  width="40" height="40">`,
              )}
              &nbsp; &nbsp;{' '}
              <span style={{ color: 'black', fontWeight: 'bold' }}>{item.PreferredName}</span>
              <span style={{ color: 'grey' }}>({item.StudentId})</span>
            </div>
          )}
          onChange={this.onSearch}
          ref={ref => (this.input = ref)}
          onSelect={value => {
            //   this.setState({ searchedClient: value });
            if (ListData && ListData.length > 0) {
              let searchResult = ListData.filter(item => {
                let Value = value.replace(/['"]+/g, '');
                if (item.StudentId == Value) {
                  return item;
                } else {
                  return null;
                }
              });
              getSelectedData(searchResult[0]);
            } else {
              return [];
            }
          }}
          value={this.state.searchStr}
          // wrapperStyle={{ display: 'unset' }}
          renderInput={props => (
            <input
              type="text"
              {...props}
              className="form-control shadow-sm search-placeholder"
              placeholder="Prospect Search..."
              style={{
                paddingLeft: '30px',
                background: 'rgb(255, 255, 255)',
                boxShadow: 'rgba(0, 0, 0, 0.043) 0px 7px 28px',
                borderRadius: ' 26px',
                minHeight: '46px',
              }}
            />
          )}
          menuStyle={{
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: searchStr ? '4px 4px' : '0px 4px',
            fontSize: '90%',
            position: 'fixed',
            overflow: 'auto',
            maxHeight: '300px',
            zIndex: 1,
            position: 'absolute',
            left: 'unset',
            top: 'unset',
          }}
        />
      </div>
    );
  }
}
export default ProspectAutocompleteSearch;
