import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { numberFormat } from '../../../util/formatter';

const ReleaseInsight = ({ ReleaseInsightData }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  //   useEffect(() => {}, []);
  const {
    AvgScore,
    NoOfPatientsRated,
    NoOfComments,
    NoOfPatientsCommented,
    NegativeRating,
    PositiveRating,
    CPIScore,
  } = (ReleaseInsightData && ReleaseInsightData[0]) || {};
  return (
    <div className="card border-0 rounded-0">
      <div className="row card-body summary-block">
        <div className="col-12">
          <h5 className="">Release Insight</h5>
        </div>
        <div className="col-12">
          <div className="blocks">
            <div className="item">
              <span className="title">Average Rating</span>
              <span className="value">{AvgScore || 0}</span>
              <span className="info">
                {NoOfPatientsRated && numberFormat.format(parseInt(NoOfPatientsRated || 0))} Patient
                rated
              </span>
            </div>
            <div className="item">
              <span className="title">Total Comment count</span>
              <span className="value">{NoOfComments || 0}</span>
              <span className="info">
                {NoOfPatientsCommented && numberFormat.format(parseInt(NoOfPatientsCommented || 0))}{' '}
                Patient commented
              </span>
            </div>
            <div className="item">
              <span className="title">New Positive Rating</span>
              <span className="value">{PositiveRating || 0}</span>
              <span className="info">
                <img src="images/icons/trending-up.svg" className="mr-1" />
                Trending up
              </span>
            </div>
            <div className="item">
              <span className="title">New Negative Rating</span>
              <span className="value">{NegativeRating || 0}</span>
              <span className="info">
                <img src="images/icons/trending-down.svg" className="mr-1" />
                Trending down
              </span>
            </div>
            <div className="item">
              <span className="title">CPI Score</span>
              <span className="value">{numberFormat.format(parseInt(CPIScore || 0))}</span>
              {/* <span className="info"></span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReleaseInsight;
