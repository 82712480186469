import { all, put, takeLatest, call } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* listStudents(action) {
  try {
    const { payload } = action;
    const response = yield API.listStudents(payload);
    yield put({
      type: ActionTypes.STUDENTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENTS_FAILURE,
    });
  }
}

export function* getStudent(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudent(payload);

    yield put({
      type: ActionTypes.STUDENT_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });

    /*yield put({
      type: ActionTypes.TODO_GET_ALL_BY_STUDENT_REQUEST,
      payload: payload,
    });

    yield put({
      type: ActionTypes.ACTIVITY_GET_ALL_BY_STUDENT_REQUEST,
      payload: payload,
    });

    yield put({
      type: ActionTypes.STUDENT_GET_ALL_COURSES_REQUEST,
      payload: payload,
    });*/
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_GET_FAILURE,
    });
  }
}

export function* addStudent(action) {
  try {
    const { payload } = action;
    const response = yield API.addStudent(payload);
    const studentId = response.data.result.StudentId;
    const surveyId = response.data.result.SurveyId;
    const enrollStatusId = response.data.result.EnrollStatusId;
    const oldEnrollStatusId = response.data.result.OldEnrollStatusId;
    if (oldEnrollStatusId != enrollStatusId && surveyId) {
      /*const response2 = yield API.surveyAssign({
        studentId: parseInt(studentId),
        surveyQuestionCategoryId: 1,
        dueDate: null,
      });
      const surveyId = response2.data.result;*/
      yield put({
        type: ActionTypes.STUDENT_ADD_SUCCESS,
        payload: {
          studentId: studentId,
          surveyId: surveyId,
          PreSurveyId: surveyId,
          success: true,
          enrollStatusId: enrollStatusId,
          oldEnrollStatusId: oldEnrollStatusId,
        },
      });
    } else {
      yield put({
        type: ActionTypes.STUDENT_ADD_SUCCESS,
        payload: {
          studentId: studentId,
          success: true,
          enrollStatusId: enrollStatusId,
          oldEnrollStatusId: oldEnrollStatusId,
          PreSurveyId: surveyId,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_ADD_FAILURE,
    });
  }
}

export function* studentReinstate(action) {
  try {
    const { payload } = action;
    const response = yield API.studentReinstate(payload);
    yield put({
      type: ActionTypes.STUDENT_REINSTATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_REINSTATE_FAILURE,
    });
  }
}

export function* genericFunction(action) {
  const { payload, method, successType, failureType } = action;
  try {
    const response = yield call(method, payload);
    yield put({
      type: successType,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: failureType,
    });
  }
}

export function* getCourses(action) {
  try {
    const { payload } = action;
    const response = yield API.getCourses(payload);
    yield put({
      type: ActionTypes.STUDENT_GET_ALL_COURSES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_GET_ALL_COURSES_FAILURE,
    });
  }
}

export function* upsertStudentTask(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertStudentTask(payload);
    yield put({
      type: ActionTypes.STUDENT_TASK_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_TASK_UPSERT_FAILURE,
    });
  }
}

export function* upsertStudentPin(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertStudentPin(payload);
    yield put({
      type: ActionTypes.STUDENT_PIN_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_PIN_UPSERT_FAILURE,
    });
  }
}

export function* validateStudentPin(action) {
  try {
    const { payload } = action;
    const response = yield API.validateStudentPin(payload);
    yield put({
      type: ActionTypes.STUDENT_VALIDATE_PIN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_VALIDATE_PIN_FAILURE,
    });
  }
}

export function* studentMarkComplete(action) {
  try {
    const { payload } = action;
    const response = yield API.studentMarkComplete(payload);
    yield put({
      type: ActionTypes.STUDENT_MARK_COMPLETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_MARK_COMPLETE_FAILURE,
    });
  }
}

export function* studentUnAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.studentUnAssign(payload);
    yield put({
      type: ActionTypes.STUDENT_UNASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_UNASSIGN_FAILURE,
    });
  }
}

export function* studentAddExistingTask(action) {
  try {
    const { payload } = action;
    const response = yield API.studentAddExistingTask(payload);
    yield put({
      type: ActionTypes.STUDENT_ADD_EXISTING_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_ADD_EXISTING_TASK_FAILURE,
    });
  }
}

export function* studentDischarge(action) {
  try {
    const { payload } = action;
    const response = yield API.studentDischarge(payload);
    yield put({
      type: ActionTypes.STUDENT_DISCHARGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_DISCHARGE_FAILURE,
    });
  }
}

export function* studentTaskMarkComplete(action) {
  try {
    const { payload } = action;
    const response = yield API.studentTaskMarkComplete(payload);
    yield put({
      type: ActionTypes.STUDENT_TASK_MARK_COMPLETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_TASK_MARK_COMPLETE_FAILURE,
    });
  }
}

export function* studentTaskUnAssign(action) {
  try {
    const { payload } = action;
    const response = yield API.studentTaskUnAssign(payload);
    yield put({
      type: ActionTypes.STUDENT_TASK_UNASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_TASK_UNASSIGN_FAILURE,
    });
  }
}

export function* assignCourse(action) {
  try {
    const { payload } = action;
    const response = yield API.courseAssign(payload);
    yield put({
      type: ActionTypes.STUDENT_COURSE_ASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_COURSE_ASSIGN_FAILURE,
    });
  }
}

export function* loadDefaultValues(action) {
  try {
    const { payload } = action;
    const response = yield API.loadDefaultValues(payload);
    localStorage.setItem('giveRead', JSON.stringify(response.data.data.IsReadOnly));
    yield put({
      type: ActionTypes.LOAD_DEFAULT_VALUES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.LOAD_DEFAULT_VALUES_FAILURE,
    });
  }
}

export function* getStudentProfileDashboardData(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentProfileDashboardData(payload);
    yield put({
      type: ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_FAILURE,
    });
  }
}

export function* getContract(action) {
  try {
    const response = yield API.getContract(action.payload);
    if (response.data.data)
      yield put({
        type: ActionTypes.GET_CONTRACT_SUCCESS,
        payload: JSON.parse(response.data.data).StudentContract[0],
      });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CONTRACT_FAILURE,
    });
  }
}

export function* upsertContract(action) {
  try {
    const response = yield API.upsertContract(action.payload);
    yield put({
      type: ActionTypes.UPSERT_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CONTRACT_FAILURE,
    });
  }
}

export function* deleteContract(action) {
  try {
    const response = yield API.deleteContract(action.payload);
    yield put({
      type: ActionTypes.DELETE_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_CONTRACT_FAILURE,
    });
  }
}

export function* getContractTemplate(action) {
  try {
    const response = yield API.getContractTemplate(action.payload);
    yield put({
      type: ActionTypes.GET_CONTRACT_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data) && JSON.parse(response.data.data).ContractTemplate,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export function* upsertContractTemplate(action) {
  try {
    const response = yield API.upsertContractTemplate(action.payload);
    yield put({
      type: ActionTypes.UPSERT_CONTRACT_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export function* getStudentContractTemplate(action) {
  try {
    const response = yield API.getStudentContractTemplate(action.payload);
    yield put({
      type: ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}

export function* createContractFromTemplate(action) {
  try {
    const response = yield API.createContractFromTemplate(action.payload);
    yield put({
      type: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_FAILURE,
    });
  }
}

export function* addFavoriteTemplate(action) {
  try {
    const response = yield API.addFavoriteTemplate(action.payload);
    yield put({
      type: ActionTypes.ADD_FAVORITE_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_FAVORITE_TEMPLATE_FAILURE,
    });
  }
}

export function* removeFavoriteTemplate(action) {
  try {
    const response = yield API.removeFavoriteTemplate(action.payload);
    yield put({
      type: ActionTypes.REMOVE_FAVORITE_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.REMOVE_FAVORITE_TEMPLATE_FAILURE,
    });
  }
}

export function* closeContract(action) {
  try {
    const { payload } = action;
    const response = yield API.closeContract(payload);
    yield put({
      type: ActionTypes.CLOSE_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLOSE_CONTRACT_FAILURE,
    });
  }
}
export function* menuContractTemplate(action) {
  try {
    const response = yield API.menuContractTemplate(action.payload);
    yield put({
      type: ActionTypes.MENU_CONTRACT_TEMPLATE_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MENU_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}
export function* archiveContractTemplate(action) {
  try {
    const response = yield API.archiveContractTemplate(action.payload);
    yield put({
      type: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_FAILURE,
    });
  }
}
export function* addNewApplicant(action) {
  try {
    const { payload } = action;
    const response = yield API.addNewApplicant(payload);
    yield put({
      type: ActionTypes.ADD_NEW_APPLICANT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_NEW_APPLICANT_FAILURE,
    });
  }
}
export function* getClientCounselor(action) {
  try {
    const { payload } = action;
    const response = yield API.getClientCounselor(payload);
    yield put({
      type: ActionTypes.GET_CLIENT_COUNSELOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_CLIENT_COUNSELOR_FAILURE,
    });
  }
}
export function* markSystemObservation(action) {
  try {
    const { payload } = action;
    const response = yield API.markSystemObservation(payload);
    yield put({
      type: ActionTypes.MARK_SYSTEM_OBSERVATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.MARK_SYSTEM_OBSERVATION_FAILURE,
    });
  }
}
export function* getNotes(action) {
  try {
    const { payload } = action;
    const response = yield API.getNotes(payload);
    yield put({
      type: ActionTypes.GET_NOTES_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_NOTES_FAILURE,
    });
  }
}

export function* upsertPin(action) {
  try {
    const { payload } = action;
    const response = yield API.upsertPin(payload);
    yield put({
      type: ActionTypes.UPSERT_PIN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.UPSERT_PIN_FAILURE,
    });
  }
}

export function* validateStaffPin(action) {
  try {
    const { payload } = action;
    const response = yield API.validateStaffPin(payload);
    yield put({
      type: ActionTypes.VALIDATE_STAFF_PIN_SUCCESS,
      payload: JSON.parse(response.data.data)[0].Staff[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.VALIDATE_STAFF_PIN_FAILURE,
    });
  }
}
export function* getStudentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentNote(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_NOTE_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_NOTE_FAILURE,
    });
  }
}
export function* getStudentNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentNoteComment(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_NOTE_COMMENT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_NOTE_COMMENT_FAILURE,
    });
  }
}
export function* addStudentNoteComment(action) {
  try {
    const { payload } = action;
    const response = yield API.addStudentNoteComment(payload);
    yield put({
      type: ActionTypes.ADD_STUDENT_NOTE_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_STUDENT_NOTE_COMMENT_FAILURE,
    });
  }
}
export function* studentNoteListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentNoteListGet(payload);
    yield put({
      type: ActionTypes.STUDENT_NOTE_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_NOTE_LIST_GET_FAILURE,
    });
  }
}
export function* addStudentNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.addStudentNoteEmoji(payload);
    yield put({
      type: ActionTypes.ADD_STUDENT_NOTE_EMOJI_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADD_STUDENT_NOTE_EMOJI_FAILURE,
    });
  }
}
export function* getStudentNoteEmoji(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentNoteEmoji(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_NOTE_EMOJI_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_NOTE_EMOJI_FAILURE,
    });
  }
}
export function* getStudentBreathalyzerTestResult(action) {
  try {
    const { payload } = action;
    const response = yield API.getStudentBreathalyzerTestResult(payload);
    yield put({
      type: ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_FAILURE,
    });
  }
}
export function* getFilters(action) {
  try {
    const { payload } = action;
    const response = yield API.getFilters(payload);
    yield put({
      type: ActionTypes.GET_FILTERS_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_FILTERS_FAILURE,
    });
  }
}
export function* userAppLinkValidate(action) {
  try {
    const { payload } = action;
    const response = yield API.userAppLinkValidate(payload);
    yield put({
      type: ActionTypes.USER_APP_LINK_VALIDATE_SUCCESS,
      payload: response.data.data[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_APP_LINK_VALIDATE_FAILURE,
    });
  }
}
export function* deleteStudentNote(action) {
  try {
    const { payload } = action;
    const response = yield API.deleteStudentNote(payload);
    yield put({
      type: ActionTypes.DELETE_STUDENT_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DELETE_STUDENT_NOTE_FAILURE,
    });
  }
}
export function* augmentedClientInsightsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.augmentedClientInsightsGet(payload);
    yield put({
      type: ActionTypes.AUGMENTED_CLIENT_INSIGHTS_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.AUGMENTED_CLIENT_INSIGHTS_GET_FAILURE,
    });
  }
}

export function* riskChartGet(action) {
  try {
    const { payload } = action;
    const response = yield API.riskChartGet(payload);
    yield put({
      type: ActionTypes.RISK_CHART_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.RISK_CHART_GET_FAILURE,
    });
  }
}

export function* augmentedSummaryGet(action) {
  try {
    const { payload } = action;
    const response = yield API.augmentedSummaryGet(payload);
    yield put({
      type: ActionTypes.AUGMENTED_SUMMARY_GET_SUCCESS,
      payload: JSON.parse(response.data.data)[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.AUGMENTED_SUMMARY_GET_FAILURE,
    });
  }
}
export function* studentBanner(action) {
  try {
    const { payload } = action;
    const response = yield API.studentBanner(payload);
    yield put({
      type: ActionTypes.STUDENT_BANNER_SUCCESS,
      payload: response.data.data && JSON.parse(response.data.data).Banner[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_BANNER_FAILURE,
    });
  }
}
export function* advisorSearch(action) {
  try {
    const { payload } = action;
    const response = yield API.advisorSearch(payload);
    yield put({
      type: ActionTypes.ADVISOR_SEARCH_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.ADVISOR_SEARCH_FAILURE,
    });
  }
}
export function* GPT3EnrollSurveys(action) {
  try {
    const { payload } = action;
    const response = yield API.GPT3EnrollSurveys(payload);
    yield put({
      type: ActionTypes.GPT3_ENROLL_SURVEYS_SUCCESS,
      payload: JSON.parse(response.data.data) && JSON.parse(response.data.data).Student[0],
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GPT3_ENROLL_SURVEYS_FAILURE,
    });
  }
}
export function* getMultipleSurveyAugmentedSummary(action) {
  try {
    const { payload } = action;
    const response = yield API.getMultipleSurveyAugmentedSummary(payload);
    yield put({
      type: ActionTypes.GET_MULTIPLE_SURVEY_AUGMENTED_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_MULTIPLE_SURVEY_AUGMENTED_SUMMARY_FAILURE,
    });
  }
}
export function* GPT3AnswerStatusUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.GPT3AnswerStatusUpsert(payload);
    yield put({
      type: ActionTypes.GPT3_ANSWER_STATUS_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GPT3_ANSWER_STATUS_UPSERT_FAILURE,
    });
  }
}
export function* getProspectDashboardGraph(action) {
  try {
    const { payload } = action;
    const response = yield API.getProspectDashboardGraph(payload);
    yield put({
      type: ActionTypes.GET_PROSPECT_DASHBOARD_GRAPH_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_PROSPECT_DASHBOARD_GRAPH_FAILURE,
    });
  }
}
export function* studentQueueListGetEnt(action) {
  try {
    const { payload } = action;
    const response = yield API.studentQueueListGetEnt(payload);
    yield put({
      type: ActionTypes.STUDENT_QUEUE_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_QUEUE_LIST_GET_FAILURE,
    });
  }
}
export function* studentQueueDelete(action) {
  try {
    const { payload } = action;
    const response = yield API.studentQueueDelete(payload);
    yield put({
      type: ActionTypes.STUDENT_QUEUE_DELETE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_QUEUE_DELETE_FAILURE,
    });
  }
}
export function* studentAvailableSpacesListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentAvailableSpacesListGet(payload);
    yield put({
      type: ActionTypes.STUDENT_AVAILABLE_SPACES_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_AVAILABLE_SPACES_LIST_GET_FAILURE,
    });
  }
}
export function* studentQueueInsert(action) {
  try {
    const { payload } = action;
    const response = yield API.studentQueueInsert(payload);
    yield put({
      type: ActionTypes.STUDENT_QUEUE_INSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_QUEUE_INSERT_FAILURE,
    });
  }
}

export function* dashboardTaskListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.dashboardTaskListGet(payload);
    yield put({
      type: ActionTypes.DASHBOARD_TASK_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_TASK_LIST_GET_FAILURE,
    });
  }
}

export function* dashboardCourseListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.dashboardCourseListGet(payload);
    yield put({
      type: ActionTypes.DASHBOARD_COURSE_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_COURSE_LIST_GET_FAILURE,
    });
  }
}

export function* dashboardContractListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.dashboardContractListGet(payload);
    yield put({
      type: ActionTypes.DASHBOARD_CONTRACT_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_CONTRACT_LIST_GET_FAILURE,
    });
  }
}

export function* dashboardAlertListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.dashboardAlertListGet(payload);
    yield put({
      type: ActionTypes.DASHBOARD_ALERT_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.DASHBOARD_ALERT_LIST_GET_FAILURE,
    });
  }
}

export function* studentTimerUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.studentTimerUpsert(payload);
    yield put({
      type: ActionTypes.STUDENT_TIMER_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_TIMER_UPSERT_FAILURE,
    });
  }
}

export function* getAppointmentClient(action) {
  try {
    const { payload } = action;
    const response = yield API.getAppointmentClient(payload);
    yield put({
      type: ActionTypes.GET_APPOINTMENT_CLIENT_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_APPOINTMENT_CLIENT_FAILURE,
    });
  }
}

export function* appointmentNoteListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentNoteListGet(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_NOTE_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_NOTE_LIST_GET_FAILURE,
    });
  }
}

export function* studentMergeComboListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentMergeComboListGet(payload);
    yield put({
      type: ActionTypes.STUDENT_MERGE_COMBO_LIST_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_MERGE_COMBO_LIST_GET_FAILURE,
    });
  }
}

export function* studentMergeDetailsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentMergeDetailsGet(payload);
    yield put({
      type: ActionTypes.STUDENT_MERGE_DETAILS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_MERGE_DETAILS_GET_FAILURE,
    });
  }
}

export function* studentMergeSecondaryDetailsGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentMergeSecondaryDetailsGet(payload);
    yield put({
      type: ActionTypes.STUDENT_MERGE_SECONDARY_DETAILS_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_MERGE_SECONDARY_DETAILS_GET_FAILURE,
    });
  }
}

export function* studentMerge(action) {
  try {
    const { payload } = action;
    const response = yield API.studentMerge(payload);
    yield put({
      type: ActionTypes.STUDENT_MERGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_MERGE_FAILURE,
    });
  }
}
export function* studentTwilioTranscriptGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentTwilioTranscriptGet(payload);
    yield put({
      type: ActionTypes.STUDENT_TWILIO_TRANSCRIPT_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_TWILIO_TRANSCRIPT_GET_FAILURE,
    });
  }
}
export function* appointmentPreSelectedGet(action) {
  try {
    const { payload } = action;
    const response = yield API.appointmentPreSelectedGet(payload);
    yield put({
      type: ActionTypes.APPOINTMENT_PRE_SELECTED_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.APPOINTMENT_PRE_SELECTED_GET_FAILURE,
    });
  }
}

export function* studentJournalAugmentGet(action) {
  try {
    const { payload } = action;
    const response = yield API.studentJournalAugmentGet(payload);
    yield put({
      type: ActionTypes.STUDENT_JOURNAL_AUGMENT_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_JOURNAL_AUGMENT_GET_FAILURE,
    });
  }
}

export function* studentJournalAugmentation(action) {
  try {
    const { payload } = action;
    const response = yield API.studentJournalAugmentation(payload);
    yield put({
      type: ActionTypes.STUDENT_JOURNAL_AUGMENTATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_JOURNAL_AUGMENTATION_FAILURE,
    });
  }
}

export function* studentCourseAugmentationGet(action) {
  try {
    const response = yield API.studentCourseAugmentationGet(action.payload);
    yield put({
      type: ActionTypes.STUDENT_COURSE_AUGMENTATION_GET_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_COURSE_AUGMENTATION_GET_FAILURE,
    });
  }
}

export function* studentCourseAugmentation(action) {
  try {
    const response = yield API.studentCourseAugmentation(action.payload);
    yield put({
      type: ActionTypes.STUDENT_COURSE_AUGMENTATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_COURSE_AUGMENTATION_FAILURE,
    });
  }
}

export function* gptAugmentationGet(action) {
  try {
    const response = yield API.gptAugmentationGet(action.payload);
    yield put({
      type: ActionTypes.GPT_AUGMENTATION_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GPT_AUGMENTATION_GET_FAILURE,
    });
  }
}

export function* gptAugmentationWithAI(action) {
  try {
    const response = yield API.gptAugmentationWithAI(action.payload);
    yield put({
      type: ActionTypes.GPT_AUGMENTATION_WITH_AI_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GPT_AUGMENTATION_WITH_AI_FAILURE,
    });
  }
}

export function* studentNoteHistoryGet(action) {
  try {
    const response = yield API.studentNoteHistoryGet(action.payload);
    yield put({
      type: ActionTypes.STUDENT_NOTE_HISTORY_GET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.STUDENT_NOTE_HISTORY_GET_FAILURE,
    });
  }
}

export function* preIntakeGetAll(action) {
  try {
    const response = yield API.preIntakeGetAll(action.payload);
    yield put({
      type: ActionTypes.PRE_INTAKE_GET_ALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.PRE_INTAKE_GET_ALL_FAILURE,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.STUDENTS_REQUEST, listStudents),
    takeLatest(ActionTypes.STUDENT_GET_REQUEST, getStudent),
    takeLatest(ActionTypes.STUDENT_ADD_REQUEST, addStudent),
    takeLatest(ActionTypes.STUDENT_NOTE_ADD_REQUEST, genericFunction),
    takeLatest(ActionTypes.STUDENT_PIN_UPSERT_REQUEST, upsertStudentPin),
    takeLatest(ActionTypes.STUDENT_VALIDATE_PIN_REQUEST, validateStudentPin),
    takeLatest(ActionTypes.STUDENT_GET_ALL_COURSES_REQUEST, getCourses),
    takeLatest(ActionTypes.STUDENT_TASK_UPSERT_REQUEST, upsertStudentTask),
    takeLatest(ActionTypes.STUDENT_MARK_COMPLETE_REQUEST, studentMarkComplete),
    takeLatest(ActionTypes.STUDENT_UNASSIGN_REQUEST, studentUnAssign),
    takeLatest(ActionTypes.STUDENT_ADD_EXISTING_TASK_REQUEST, studentAddExistingTask),
    takeLatest(ActionTypes.STUDENT_DISCHARGE_REQUEST, studentDischarge),
    takeLatest(ActionTypes.STUDENT_TASK_MARK_COMPLETE_REQUEST, studentTaskMarkComplete),
    takeLatest(ActionTypes.STUDENT_TASK_UNASSIGN_REQUEST, studentTaskUnAssign),
    takeLatest(ActionTypes.STUDENT_COURSE_ASSIGN_REQUEST, assignCourse),
    takeLatest(ActionTypes.LOAD_DEFAULT_VALUES_REQUEST, loadDefaultValues),
    takeLatest(
      ActionTypes.STUDENT_GET_PROFILE_DASHBOARD_DATA_REQUEST,
      getStudentProfileDashboardData,
    ),
    takeLatest(ActionTypes.GET_CONTRACT_REQUEST, getContract),
    takeLatest(ActionTypes.UPSERT_CONTRACT_REQUEST, upsertContract),
    takeLatest(ActionTypes.DELETE_CONTRACT_REQUEST, deleteContract),
    takeLatest(ActionTypes.GET_CONTRACT_TEMPLATE_REQUEST, getContractTemplate),
    takeLatest(ActionTypes.UPSERT_CONTRACT_TEMPLATE_REQUEST, upsertContractTemplate),
    takeLatest(ActionTypes.GET_STUDENT_CONTRACT_TEMPLATE_REQUEST, getStudentContractTemplate),
    takeLatest(ActionTypes.CREATE_CONTRACT_FROM_TEMPLATE_REQUEST, createContractFromTemplate),
    takeLatest(ActionTypes.ADD_FAVORITE_TEMPLATE_REQUEST, addFavoriteTemplate),
    takeLatest(ActionTypes.REMOVE_FAVORITE_TEMPLATE_REQUEST, removeFavoriteTemplate),
    takeLatest(ActionTypes.CLOSE_CONTRACT_REQUEST, closeContract),
    takeLatest(ActionTypes.MENU_CONTRACT_TEMPLATE_REQUEST, menuContractTemplate),
    takeLatest(ActionTypes.ARCHIVE_CONTRACT_TEMPLATE_REQUEST, archiveContractTemplate),
    takeLatest(ActionTypes.ADD_NEW_APPLICANT_REQUEST, addNewApplicant),
    takeLatest(ActionTypes.STUDENT_REINSTATE_REQUEST, studentReinstate),
    takeLatest(ActionTypes.GET_CLIENT_COUNSELOR_REQUEST, getClientCounselor),
    takeLatest(ActionTypes.MARK_SYSTEM_OBSERVATION_REQUEST, markSystemObservation),
    takeLatest(ActionTypes.GET_NOTES_REQUEST, getNotes),
    takeLatest(ActionTypes.UPSERT_PIN_REQUEST, upsertPin),
    takeLatest(ActionTypes.VALIDATE_STAFF_PIN_REQUEST, validateStaffPin),
    takeLatest(ActionTypes.GET_STUDENT_NOTE_REQUEST, getStudentNote),
    takeLatest(ActionTypes.GET_STUDENT_NOTE_COMMENT_REQUEST, getStudentNoteComment),
    takeLatest(ActionTypes.ADD_STUDENT_NOTE_COMMENT_REQUEST, addStudentNoteComment),
    takeLatest(ActionTypes.ADD_STUDENT_NOTE_EMOJI_REQUEST, addStudentNoteEmoji),
    takeLatest(ActionTypes.GET_STUDENT_NOTE_EMOJI_REQUEST, getStudentNoteEmoji),
    takeLatest(
      ActionTypes.GET_STUDENT_BREATHALYZER_TEST_RESULT_REQUEST,
      getStudentBreathalyzerTestResult,
    ),
    takeLatest(ActionTypes.GET_FILTERS_REQUEST, getFilters),
    takeLatest(ActionTypes.USER_APP_LINK_VALIDATE_REQUEST, userAppLinkValidate),
    takeLatest(ActionTypes.DELETE_STUDENT_NOTE_REQUEST, deleteStudentNote),
    takeLatest(ActionTypes.AUGMENTED_CLIENT_INSIGHTS_GET_REQUEST, augmentedClientInsightsGet),
    takeLatest(ActionTypes.RISK_CHART_GET_REQUEST, riskChartGet),
    takeLatest(ActionTypes.AUGMENTED_SUMMARY_GET_REQUEST, augmentedSummaryGet),
    takeLatest(ActionTypes.STUDENT_BANNER_REQUEST, studentBanner),
    takeLatest(ActionTypes.ADVISOR_SEARCH_REQUEST, advisorSearch),
    takeLatest(ActionTypes.GPT3_ENROLL_SURVEYS_REQUEST, GPT3EnrollSurveys),
    takeLatest(
      ActionTypes.GET_MULTIPLE_SURVEY_AUGMENTED_SUMMARY_REQUEST,
      getMultipleSurveyAugmentedSummary,
    ),
    takeLatest(ActionTypes.STUDENT_NOTE_LIST_GET_REQUEST, studentNoteListGet),
    takeLatest(ActionTypes.GPT3_ANSWER_STATUS_UPSERT_REQUEST, GPT3AnswerStatusUpsert),
    takeLatest(ActionTypes.GET_PROSPECT_DASHBOARD_GRAPH_REQUEST, getProspectDashboardGraph),
    takeLatest(ActionTypes.STUDENT_QUEUE_LIST_GET_REQUEST, studentQueueListGetEnt),
    takeLatest(ActionTypes.STUDENT_QUEUE_DELETE_REQUEST, studentQueueDelete),
    takeLatest(
      ActionTypes.STUDENT_AVAILABLE_SPACES_LIST_GET_REQUEST,
      studentAvailableSpacesListGet,
    ),
    takeLatest(ActionTypes.STUDENT_QUEUE_INSERT_REQUEST, studentQueueInsert),
    takeLatest(ActionTypes.DASHBOARD_TASK_LIST_GET_REQUEST, dashboardTaskListGet),
    takeLatest(ActionTypes.DASHBOARD_COURSE_LIST_GET_REQUEST, dashboardCourseListGet),
    takeLatest(ActionTypes.DASHBOARD_CONTRACT_LIST_GET_REQUEST, dashboardContractListGet),
    takeLatest(ActionTypes.DASHBOARD_ALERT_LIST_GET_REQUEST, dashboardAlertListGet),
    takeLatest(ActionTypes.STUDENT_TIMER_UPSERT_REQUEST, studentTimerUpsert),
    takeLatest(ActionTypes.GET_APPOINTMENT_CLIENT_REQUEST, getAppointmentClient),
    takeLatest(ActionTypes.APPOINTMENT_NOTE_LIST_GET_REQUEST, appointmentNoteListGet),
    takeLatest(ActionTypes.STUDENT_MERGE_COMBO_LIST_GET_REQUEST, studentMergeComboListGet),
    takeLatest(ActionTypes.STUDENT_MERGE_DETAILS_GET_REQUEST, studentMergeDetailsGet),
    takeLatest(
      ActionTypes.STUDENT_MERGE_SECONDARY_DETAILS_GET_REQUEST,
      studentMergeSecondaryDetailsGet,
    ),
    takeLatest(ActionTypes.STUDENT_MERGE_REQUEST, studentMerge),
    takeLatest(ActionTypes.STUDENT_TWILIO_TRANSCRIPT_GET_REQUEST, studentTwilioTranscriptGet),
    takeLatest(ActionTypes.APPOINTMENT_PRE_SELECTED_GET_REQUEST, appointmentPreSelectedGet),
    takeLatest(ActionTypes.STUDENT_JOURNAL_AUGMENT_GET_REQUEST, studentJournalAugmentGet),
    takeLatest(ActionTypes.STUDENT_JOURNAL_AUGMENTATION_REQUEST, studentJournalAugmentation),
    takeLatest(ActionTypes.STUDENT_COURSE_AUGMENTATION_GET_REQUEST, studentCourseAugmentationGet),
    takeLatest(ActionTypes.STUDENT_COURSE_AUGMENTATION_REQUEST, studentCourseAugmentation),
    takeLatest(ActionTypes.GPT_AUGMENTATION_GET_REQUEST, gptAugmentationGet),
    takeLatest(ActionTypes.GPT_AUGMENTATION_WITH_AI_REQUEST, gptAugmentationWithAI),
    takeLatest(ActionTypes.STUDENT_NOTE_HISTORY_GET_REQUEST, studentNoteHistoryGet),
    takeLatest(ActionTypes.PRE_INTAKE_GET_ALL_REQUEST, preIntakeGetAll),
  ]);
}
