import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import SpeechToText from '../../Components/YouuSpeechToText';
import moment from 'moment';
import TextareaAutosize from 'react-autosize-textarea';
import {
  resetChatGet,
  youuniverseConversationGet,
  youuniverseChatGet,
  resetYouuniverseChatGet,
  youuniverseChatPost,
  resetYouuniverseChatPost,
  youuniverseBookMarkQuestionUpsert,
  resetYouuniverseBookMarkQuestionUpsert,
  youuniverseBookMarkQuestionListGet,
  youuniverseChatAttributeUpdate,
  resetYouuniverseChatAttributeUpdate,
  resetAskFollowUpInitiate,
  youuniverseChatSet,
} from '../../store/actions';
import { UserQuery, Response } from './components';
import ChatHistory from './ChatHistory';
import AntdModal from '../../Components/CustomModal';

const YOUUniverseChat = props => {
  const dispatch = useDispatch();
  // const id = useId();
  const [isMute, setIsMute] = useState(false);
  const [isResposeAction, setIsResposeAction] = useState(false);
  const [message, setMessage] = useState('');
  const [ConversationGuid, setConversationGuid] = useState(props.ConversationGuid);
  const [listMessage, setListMessage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isFinal, setIsFinal] = useState(false);
  const [historyConversationGuid, setHistoryConversationGuid] = useState(null);
  const [isDeleteAll, setIsDeleteAll] = useState(false);

  const messagesEndRef = useRef();
  let UserId = localStorage.getItem('userId');
  let UserName = localStorage.getItem('userName');
  let userProfile = localStorage.getItem('profileImgUrl');
  const {
    isFetchingResponse,
    chatDataList,
    askQuestionResponse,
    askQuestionError,
    ConversationsList,
    followUpDataResponse,
  } = useSelector(
    ({
      youuniverse_conversation_get: { data },
      youuniverse_chat_get: { data: chatData },
      youuniverse_chat_post: { data: askQuestionData, isFetching, error },
      ask_follow_up_initiate: { data: followUpData },
    }) => {
      if (chatData) {
      }
      return {
        ConversationsList: data && data.data,
        askQuestionResponse: askQuestionData,
        isFetchingResponse: isFetching,
        askQuestionError: error,
        chatDataList: chatData && chatData.data,
        followUpDataResponse: followUpData && followUpData,
      };
    },
    shallowEqual,
  );

  const scrollToBottom = () => {
    let scroll = messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;
    return messagesEndRef.current.scrollTo({
      top: scroll,
      behavior: 'instant',
    });
  };

  const handleDictate = (result, isFinal) => {
    if (result) {
      let data;
      if (message) {
        data = `${message} ${result}`;
      } else {
        data = result;
      }
      setMessage(data);
    }
    if (isFinal == 'true') {
      setIsFinal(true);
    }
  };

  useEffect(() => {
    setMessage(sessionStorage.getItem('homePageMsg'));
    StopSpeech(true);
    dispatch(youuniverseConversationGet());
    if (ConversationGuid) {
      setMessage('');
      dispatch(
        youuniverseChatGet({
          JSON: JSON.stringify({
            ConversationGuid: ConversationGuid,
          }),
        }),
      );
      scrollToBottom();
    }
    return () => {
      StopSpeech(true);
      // dispatch(resetChatGet());
      // setConversationGuid(null);
    };
  }, []);

  // useEffect(() => {
  //   if (ConversationsList && ConversationsList.length) {
  //     setMessage('');
  //     dispatch(
  //       youuniverseChatGet({
  //         JSON: JSON.stringify({
  //           ConversationGuid: ConversationsList[0].ConversationGuid,
  //           // ConversationGuid: '3f712cf4-edd6-4035-a5b7-12351d457c7a',
  //         }),
  //       }),
  //     );
  //     scrollToBottom();
  //   }
  // }, [ConversationsList]);

  useEffect(() => {
    if (!isResposeAction && chatDataList) {
      scrollToBottom();
    }
    setIsResposeAction(false);
  }, [listMessage]);

  useEffect(() => {
    if (props.historyConversationGuid || historyConversationGuid || props.chatGuid) {
      setMessage('');
      dispatch(
        youuniverseChatGet({
          JSON: JSON.stringify({
            ConversationGuid:
              props.historyConversationGuid || historyConversationGuid || props.chatGuid,
          }),
        }),
      );
      scrollToBottom();
    }
    if (props.historyConversationGuid) setConversationGuid(props.historyConversationGuid);
    else if (props.chatGuid) setConversationGuid(props.chatGuid);
    else setConversationGuid(historyConversationGuid);
  }, [props.historyConversationGuid, historyConversationGuid, props.chatGuid]);

  useEffect(() => {
    scrollToBottom();
    if (
      sessionStorage.getItem('homePageMsg') != null &&
      sessionStorage.getItem('homePageMsg').length
    ) {
      let e = {
        type: 'click',
      };
      assignMessage(e);
    }
  }, [message]);

  useEffect(() => {
    if (askQuestionResponse && askQuestionResponse.result) {
      if (listMessage && listMessage.length > 0) {
        listMessage.pop();
      }
      let newResponseData = {
        ...askQuestionResponse.result,
      };
      setLoader(isFetchingResponse);
      setListMessage(oldArray => [...oldArray, newResponseData]);
      scrollToBottom();
      setConversationGuid(askQuestionResponse.result.ConversationGuid);
      props.getConversation(askQuestionResponse.result.ConversationGuid);
      dispatch(resetYouuniverseChatPost());

      // if (!isMute) {
      //   Speech(newResponseData.Response);
      // }
    }
    if (askQuestionError && askQuestionError.StatusCode == 500) {
      setLoader(isFetchingResponse);
    }
  }, [askQuestionResponse, askQuestionError]);

  useEffect(() => {
    if (ConversationGuid) {
      setListMessage(chatDataList);
    }
  }, [chatDataList]);

  useEffect(() => {
    if (followUpDataResponse) {
      setConversationGuid(followUpDataResponse.ConversationGuid);
      setListMessage(oldArray => [followUpDataResponse]);
      scrollToBottom();
      // dispatch(resetAskFollowUpInitiate());
      // dispatch(resetYouuniverseChatGet());
      // dispatch(resetChatGet());
    }
  }, [followUpDataResponse]);

  useEffect(() => {
    if (isFinal) {
      let e = {
        type: 'click',
      };
      assignMessage(e);
      setIsFinal(false);
    }
  }, [isFinal]);

  const assignMessage = e => {
    if (
      (!loader && e.keyCode === 13 && message.trim().length) ||
      (e.type === 'click' && message.trim().length)
    ) {
      setLoader(true);
      let data = {
        UserQuery: message,
        ConversationGuid: ConversationGuid,
      };
      if (listMessage && listMessage.length) {
        setListMessage(oldArray => [...oldArray, data]);
      } else {
        setListMessage(oldArray => [data]);
      }
      setMessage('');
      sessionStorage.removeItem('homePageMsg');
      dispatch(youuniverseChatPost(data));
    }
  };

  const Speech = text => {
    const synth = window.speechSynthesis;
    synth.resume();
    // Fetch the available voices.
    const voices = synth.getVoices();
    let word = new SpeechSynthesisUtterance(text);
    // Set the attributes.
    // word.volume = parseFloat(volumeInput.value);
    // word.rate = parseFloat(rateInput.value);
    // word.pitch = parseFloat(pitchInput.value);
    // set the utterance instance's voice attribute.
    // word.voice = voices && voices[1];
    synth.speak(word);
  };

  const StopSpeech = isClear => {
    const synth = window.speechSynthesis;
    if (isClear) {
      // to clear queue
      synth.cancel();
    } else {
      synth.pause();
    }
  };

  const ResumeSpeech = () => {
    const synth = window.speechSynthesis;
    synth.resume();
  };

  const GetLocalTime = date => {
    // let date = moment.utc().format();
    let local = moment
      .utc(date)
      .local()
      .format('DD MMMM YYYY, HH:mm A');
    return local;
  };

  const queryBookmark = (item, index) => {
    let data = {
      BookMark: {
        ...item,
        IsQueryBookmarked: !item.IsQueryBookmarked,
      },
    };
    dispatch(
      youuniverseBookMarkQuestionUpsert({
        JSON: JSON.stringify(data),
      }),
    );
    let list = listMessage;
    list[index].IsQueryBookmarked = !item.IsQueryBookmarked;
    setListMessage([...list]);
    setIsResposeAction(true);
  };

  const responseBookmark = (item, index) => {
    let data = {
      BookMark: {
        // ConversationGuid: item.ConversationGuid,
        // ChatId: item.ChatId,
        // LikedDisliked: item.LikedDisliked,
        ...item,
        IsResponseBookmarked: !item.IsResponseBookmarked,
      },
    };
    dispatch(
      youuniverseChatAttributeUpdate({
        JSON: JSON.stringify(data),
      }),
    );
    let list = listMessage;
    list[index].IsResponseBookmarked = !item.IsResponseBookmarked;
    setListMessage([...list]);
    setIsResposeAction(true);
  };

  const responseLikeDislike = (item, index, likeDislike) => {
    let data = {
      BookMark: {
        ...item,
        LikedDisliked: likeDislike,
      },
    };
    dispatch(
      youuniverseChatAttributeUpdate({
        JSON: JSON.stringify(data),
      }),
    );
    let list = listMessage;
    list[index].LikedDisliked = likeDislike;
    setListMessage([...list]);
    setIsResposeAction(true);
  };

  const getConversationsGuid = history => {
    setHistoryConversationGuid(history.ConversationGuid);
    props.getConversation(history.ConversationGuid);
    // dispatch(resetYouuniverseChatGet({}));
  };

  const chatInit = () => {
    setHistoryConversationGuid(null);
    props.getConversation(null);
    dispatch(resetYouuniverseChatGet({}));
  };

  const footerAction = () => {
    setIsDeleteAll(true);
  };

  return (
    <div className="chatbot row  ask-you-universe">
      {/* chat Messages */}
      {!props.isDrawerView && (
        <div className="col-4">
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ fontSize: '16px', fontWeight: '700' }}>Chat History</p>
            <p className="new-conversation" onClick={chatInit}>
              + New Chat
            </p>
          </div>
          <div style={{ height: '380px', overflowY: 'auto', overflowX: 'hidden' }}>
            <ChatHistory isDeleteAll={isDeleteAll} getConversationsGuid={getConversationsGuid} />
          </div>
          <AntdModal
            ModalButton={({ onClick }) => (
              <div className="row chat-history-footer">
                <div className="footer" onClick={onClick}>
                  <img src="images/deletetp.svg" />
                  <span className="px-2">Clear All Conversations</span>
                </div>
              </div>
            )}
            ModalContent={({ onOk, onCancel }) => (
              <div class="modal-content border-0">
                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                  <h4 class="modal-title col-12 mb-3">
                    Are you sure you want to delete the chat history?
                  </h4>
                </div>
                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                  <button
                    class="btn btn-outline-primary black-btn"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No
                  </button>
                  <button
                    class="btn"
                    onClick={e => {
                      e.stopPropagation();
                      onOk();
                      footerAction();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          />
          {/* <div className="row chat-history-footer">
            <div className="footer" onClick={footerAction}>
              <img src="images/deletetp.svg" />
              <span className="px-2">Clear All Conversations</span>
            </div>
          </div> */}
        </div>
      )}
      <div className={!props.isDrawerView ? `flex-column col-8` : `flex-column col-12 p-0`}>
        <div
          ref={messagesEndRef}
          className={!props.isDrawerView ? `chat-message-container` : `chat-message-container`}
          style={{
            height: '75vh',
            overflowY: 'scroll',
            marginBottom: '50px',
          }}
          onChange={scrollToBottom}
        >
          {listMessage && listMessage.length ? (
            listMessage.map((item, index) => (
              <div key={index} className={!props.isDrawerView && `col-12`}>
                {item.UserQuery && (
                  <div className="col-12">
                    <div className="user-query">
                      <UserQuery QueryBookmark={queryBookmark} QueryData={item} Index={index} />
                    </div>
                  </div>
                )}
                {item.Response && (
                  <div className="col-12">
                    <div className="response">
                      <div className="youu-chat-messages">
                        <Response
                          ResponseBookmark={responseBookmark}
                          ResponseLikeDislike={responseLikeDislike}
                          ResponseData={item}
                          Index={index}
                          IsShowAction={listMessage.length - 1 == index}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {loader && !item.Response && (
                  <div className="col-12">
                    <div className="response">
                      <div className="youu-chat-messages">
                        <img src="images/chatLoaderNew.gif" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              <img style={{ height: '200px' }} src="images/chatSP.svg" />
              <h2
                style={{
                  width: '750px',
                  marginTop: '20px',
                  textAlign: 'center',
                }}
              >
                {`Hi ${UserName}`}
              </h2>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center">
          <div
            className="chat-bot-footer"
            style={{
              position: 'fixed',
              bottom: '10px',
              // width: '50%',
            }}
          >
            {loader && (
              <div
                className=""
                style={{
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                <img
                  src="images/AI_Loader_text.gif"
                  style={{
                    height: '150px',
                  }}
                />
              </div>
            )}
            {/* chat box */}
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: '775px',
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: '#F2F2F2',
                  position: 'relative',

                  borderRadius: '30px',
                  // boxShadow: '2px 2px 3px rgba(0,0,0,0.2)',
                  maxWidth: '990px',
                  minWidth: '85%',
                }}
              >
                <input
                  className="chat-input col-10"
                  placeholder="Type Here..."
                  type="text"
                  value={message}
                  onChange={e => {
                    setMessage(e.target.value);
                  }}
                  onKeyDown={assignMessage}
                  disabled={loader}
                />
                {/* {loader ? (
            <span className="d-flex justify-content-center align-items-center col-1">
              <img src="images/chatLoader.gif" />
            </span>
          ) : (
            <div className="col-2 pl-0">
              <span className="d-flex align-items-center justify-content-end">
                <SpeechToText handleDictate={handleDictate} />
                <span className="youu-chat-submit" onClick={assignMessage}>
                  <img src="images/submit.svg" />
                </span>
              </span>
            </div>
          )} */}
                {!loader && (
                  <div className="col-2 pl-0">
                    <span className="d-flex align-items-center justify-content-end">
                      <SpeechToText handleDictate={handleDictate} />
                      <span className="youu-chat-submit" onClick={assignMessage}>
                        <img src="images/submit.svg" />
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default YOUUniverseChat;
