import React, { Component } from 'react';
import { Table } from 'antd';
import { isComparer } from '../util/handler';
import { Link } from 'react-router-dom';
import {
  getLicenseActivites,
  getCurrentLicenses,
  setCurrentLicenses,
  getLicensesInvoice,
  getLicensesReceipt,
  centerTokenAssignUpdate,
} from '../store/actions';
import { connect } from 'react-redux';
import Loader, { Loader2 } from '../Components/Loader';
import PageTitle from '../Components/PageTitle';
import AntdModal from '../Components/CustomModal';
import CustomModal from '../core/modal';
import { List, Typography, Divider } from 'antd';
import moment from 'moment';
import { FormatDate } from '../Components/FormatDate';
import { numberFormat } from '../util/formatter';
import PurchaseAILicense from './token/PurchaseAILicense';
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
// const columns = [
//   {
//     title: 'Product',
//     dataIndex: 'Product',
//     key: 'product',
//     sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
//     sortDirections: ['descend', 'ascend'],
//   },
//   {
//     title: 'Product Description',
//     dataIndex: 'ProductDesc',
//     key: 'product',
//     sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
//     sortDirections: ['descend', 'ascend'],
//   },
//   {
//     title: 'Quantity',
//     dataIndex: 'Quantity',
//     key: 'quantity',
//     sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
//     sortDirections: ['descend', 'ascend'],
//   },
//   {
//     title: 'Price of License',
//     dataIndex: 'PriceOfLicense',
//     key: 'priceofLicense',
//     sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
//     sortDirections: ['descend', 'ascend'],
//   },
//   {
//     title: 'Licenses Set on Date',
//     dataIndex: 'DateOfPurchase',
//     key: 'dateofPurchase',
//     sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
//     sortDirections: ['descend', 'ascend'],
//   },
//   {
//     title: 'Invoice',
//     dataIndex: 'Product',
//     key: 'product',
//     render: (text, record) => {
//       return (
//         <div className="license-invoice">
//           <span className="license-invoice-link">Download {text} invoice</span>
//         </div>
//       );
//     },
//   },
//   // {
//   //   title: 'Status',
//   //   dataIndex: 'Action',
//   //   key: 'status',
//   //   sorter: (a, b) => isComparer(a.Abstract, b.Abstract),
//   //   sortDirections: ['descend', 'ascend'],
//   // },
// ];

class Maintainlicense extends Component {
  constructor(props) {
    super(props);
    props.dispatch(getCurrentLicenses({}));
    props.dispatch(getLicenseActivites({}));
    props.dispatch(getLicensesInvoice({}));
    this.state = {
      StateLicenseData: [],
      isModalOpen: false,
    };
  }
  getUserCount = (e, item, index) => {
    const { addon, praposed, StateLicenseData } = this.state;
    let value = e.target.value;
    let pat = /^[0-9]+$/;
    if (value.match(pat)) {
      value = parseInt(value);
      let data = item || {};
      data.ChangedLicenseCount = parseInt(e.target.value);
      data.ProposedLicenseCount = parseInt(data.QuantityCurrentActive) + parseInt(e.target.value);
      let newStateLicenseData = [...StateLicenseData];
      newStateLicenseData[index] = data;
      this.setState({
        StateLicenseData: newStateLicenseData,
      });
    }
  };
  getReceipt = chargeId => {
    this.props.dispatch(getLicensesReceipt({ chargeId }));
  };

  componentWillReceiveProps({
    CurrentLicenseData,
    ReceiptURL,
    LicensesCounts,
    isTokenAssignUpdated,
    UpsertMsg,
  }) {
    const curState = JSON.parse(sessionStorage.getItem('state'));
    const isBack = sessionStorage.getItem('isBack');

    if (CurrentLicenseData != this.props.CurrentLicenseData) {
      // for (let item in this.props.CurrentLicenseData) {
      //   obj[item] = this.props.CurrentLicenseData[item].ProposedLicenseCount;
      // }
      // if (CurrentLicenseData.length > 0) {
      //   this.setState({
      //     '0': CurrentLicenseData[0].ProposedLicenseCount,
      //     '1': CurrentLicenseData[1].ProposedLicenseCount,
      //   });
      // }
      if (isBack) {
        this.state = {
          ...curState,
          StateLicenseData: curState.StateLicenseData || [],
        };
      } else {
        let newCurrentLicenseData = CurrentLicenseData.map((item, index) => {
          return {
            ...item,
            ProposedLicenseCount: item.QuantityCurrentActive,
          };
        });
        this.setState({
          ...curState,
          StateLicenseData: CurrentLicenseData,
        });
      }
    }
    if (ReceiptURL && ReceiptURL != this.props.ReceiptURL) {
      window.open(ReceiptURL);
    }
    if (isTokenAssignUpdated && isTokenAssignUpdated != this.props.isTokenAssignUpdated) {
      this.toggle(UpsertMsg || 'Saved successfully.');
    }
  }
  updateCenterLicense = (data, index, isModified, CostPerToken) => {
    let licensedata = this.state.StateLicenseData;
    let TokenData = this.props.CurrentLicenseData[index].LicenseAndTokenDistribution;
    TokenData = typeof TokenData === 'string' ? JSON.parse(TokenData) : TokenData;
    // let isTokenUpdated = isModified;
    // if (isModified) {
    //   isTokenUpdated =
    //     this.props.CurrentLicenseData[index].LicenseAndTokenDistribution === JSON.stringify(data);
    // }
    let proposedLicenseCount = 0;
    let TotalNewTokenCount = 0;
    data.map((item, dataIndex) => {
      if (item.IsLicenseAssigned) {
        proposedLicenseCount = proposedLicenseCount + 1;
        if (!TokenData[dataIndex].IsLicenseAssigned && !TokenData[dataIndex].IsTokenAvailable) {
          TotalNewTokenCount += parseInt(item.TokenCount || 0);
        }
      }
    });
    licensedata[index].ProposedLicenseCount = proposedLicenseCount;
    licensedata[index].ChangedLicenseCount =
      proposedLicenseCount - licensedata[index].QuantityCurrentActive;
    licensedata[index].LicenseAndTokenDistribution = data;
    this.setState({
      StateLicenseData: licensedata,
      isTokenUpdated: isModified,
      // isTokenUpdated: !isTokenUpdated,
      AILicenseIndex: index,
      TotalNewTokenCount: TotalNewTokenCount,
      TotalNewTokenCost: CostPerToken * TotalNewTokenCount,
    });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  render() {
    const { CurrentLicenseData, isFetching, InvoiceData, hasAdminAccess } = this.props;
    const { StateLicenseData, TotalNewTokenCount, TotalNewTokenCost } = this.state;
    let salesTax = (StateLicenseData[0] && StateLicenseData[0].TaxRatePct) || 0;
    // let subTotal = parseInt(totalUserLicenceCost) + parseInt(totalKioskLicenceCost);
    // let salesTaxAmount = (subTotal * salesTax) / 100;
    // let totalAmount = subTotal + salesTax;
    let Total = 0;
    let ProRatedCostTotal = 0;
    return (
      <div class="main">
        <PageTitle TitleText="Manage Licenses" />
        <Loader loading={isFetching} />
        <div class="container-fluid">
          <div class="row top-table-search mt-2 mb-2 mb-md-4 align-items-center">
            <div class="col-12">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              <div className="d-flex justify-content-between align-items-center">
                <h3 class="form-main-head mb-3 mb-md-0 d-inline">Manage Licenses </h3>
                {/* <h3 class="page-title mb-3 mb-md-0 d-inline">Manage Licenses </h3> */}
                {StateLicenseData[0] && StateLicenseData[0].NextBillingDate && (
                  <>
                    <h6 className="mb-3 mb-md-0 d-inline">
                      Next Monthly Billing Date :
                      <small> {StateLicenseData[0] && StateLicenseData[0].NextBillingDate}</small>
                    </h6>
                  </>
                )}
              </div>
            </div>
          </div>
          {!hasAdminAccess && (
            <div className="col-12 text-center">
              <span className="error-text">
                You do not have permission to change licenses. Please contact support to change your
                licenses.
              </span>
            </div>
          )}
          {StateLicenseData &&
            StateLicenseData.map((item, index) => {
              ProRatedCostTotal +=
                item.NextBillingDate && (item.ChangedLicenseCount || 0) * item.ProRatedCost;

              Total += parseInt(item.UnitCostMonthly) * (item.ChangedLicenseCount || 0);
              return (
                <div class="maintain-box">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="user-license">
                        <div class="user-month-content">
                          <span class="license-price">${item.UnitCostMonthly}</span> ${item.UOM}
                        </div>
                        <h3>{item.LicenseShortName}</h3>
                        <p>{item.LicenseLongDesc}</p>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="user-license-cost">
                        {/* <h4>{item.LicenseShortName} Count (increase/decrease)</h4> */}
                        {item.IsChildCenterPurchase ? (
                          <div className="d-flex align-items-center justify-content-end mb-2">
                            <span className="mr-2">
                              Active Centers Included: <strong>{item.QuantityCurrentActive}</strong>
                            </span>
                            {hasAdminAccess && (
                              <AntdModal
                                modalWidth={800}
                                style={{ top: 20 }}
                                ModalButton={({ onClick }) => (
                                  <input
                                    type="button"
                                    class="btn btn-outline-primary px-5"
                                    value={
                                      item.QuantityCurrentActive <= 0
                                        ? 'Select Centers'
                                        : 'Update Centers'
                                    }
                                    onClick={onClick}
                                    disabled={!hasAdminAccess}
                                  />
                                )}
                                ModalContent={props => (
                                  <PurchaseAILicense
                                    {...props}
                                    LicenseAndTokenDistribution={
                                      typeof item.LicenseAndTokenDistribution === 'string'
                                        ? JSON.parse(item.LicenseAndTokenDistribution)
                                        : item.LicenseAndTokenDistribution
                                    }
                                    TokenInfo={JSON.parse(item.TokenInfo)}
                                    index={index}
                                    updateCenterLicense={this.updateCenterLicense}
                                  />
                                )}
                              />
                            )}
                          </div>
                        ) : (
                          <>
                            <h4>
                              Active {item.LicenseShortName} ({item.QuantityCurrentActive})
                            </h4>
                            {/* <h4>{item.LicenseShortName} Count (increase/decrease)</h4> */}
                            <div class="license-quantity">
                              <div class="quantity-btn d-inline-flex align-items-center">
                                <h4 className="inline-block mr-3 mb-0">Change in License(s)</h4>
                                <div class="numbers-row ">
                                  <input
                                    disabled={!hasAdminAccess}
                                    class="number-icon"
                                    type="text"
                                    min="0"
                                    maxlength="2"
                                    value={item.ChangedLicenseCount}
                                    onChange={e => this.getUserCount(e, item, index)}
                                    tabindex="0"
                                    style={{ textAlign: 'center' }}
                                  />
                                  <div
                                    class="add quantity-button"
                                    onClick={event => {
                                      if (hasAdminAccess)
                                        if (item.ChangedLicenseCount <= 98) {
                                          let data = {
                                            ...StateLicenseData[index],
                                            ChangedLicenseCount: item.ChangedLicenseCount + 1,
                                            ProposedLicenseCount:
                                              item.QuantityCurrentActive +
                                              item.ChangedLicenseCount +
                                              1,
                                          };
                                          let newStateLicenseData = [...StateLicenseData];
                                          newStateLicenseData[index] = data;
                                          this.setState({
                                            StateLicenseData: newStateLicenseData,
                                          });
                                        } else {
                                          return;
                                        }
                                    }}
                                  >
                                    +
                                  </div>
                                  {this.state[item.LicenseShortName] ? (
                                    <div
                                      class="remove quantity-button"
                                      // onClick={event => {
                                      //   StateLicenseData[index] = {
                                      //     ...item,
                                      //     ChangedLicenseCount: item.ChangedLicenseCount - 1,
                                      //     ProposedLicenseCount:
                                      //       item.QuantityCurrentActive + item.ChangedLicenseCount,
                                      //   };

                                      //   this.setState(prevState => {
                                      //     const val = prevState.addon[item.LicenseShortName] - 1;
                                      //     const proVal = prevState.praposed[item.LicenseShortName] - 1;
                                      //     if (val < -item.QuantityCurrentActive) return {};
                                      //     return {
                                      //       addon: { ...this.state.addon, [item.LicenseShortName]: val },
                                      //       praposed: {
                                      //         ...this.state.praposed,
                                      //         [item.LicenseShortName]: proVal,
                                      //       },
                                      //     };
                                      //   });
                                      // }}
                                      onClick={event => {
                                        if (hasAdminAccess)
                                          if (
                                            item.ChangedLicenseCount - 1 <
                                            -item.QuantityCurrentActive
                                          )
                                            return {};
                                          else {
                                            let data = {
                                              ...StateLicenseData[index],
                                              ChangedLicenseCount: item.ChangedLicenseCount - 1,
                                              ProposedLicenseCount:
                                                item.QuantityCurrentActive +
                                                item.ChangedLicenseCount -
                                                1,
                                            };
                                            let newStateLicenseData = [...StateLicenseData];
                                            newStateLicenseData[index] = data;
                                            this.setState({
                                              StateLicenseData: newStateLicenseData,
                                            });
                                          }
                                      }}
                                    >
                                      -
                                    </div>
                                  ) : (
                                    <AntdModal
                                      ModalButton={({ onClick }) => (
                                        <div
                                          class="remove quantity-button"
                                          onClick={() => {
                                            if (hasAdminAccess) onClick();
                                          }}
                                          data-toggle="modal"
                                          data-target="#delete-coupon"
                                          style={{ cursor: 'pointer' }}
                                        >
                                          -
                                        </div>
                                      )}
                                      ModalContent={({ onOk, onCancel }) => (
                                        <div class="modal-content border-0">
                                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                                            <h4 class="modal-title col-12 mb-3">
                                              Are you sure you want to reduce this{' '}
                                              {item.LicenseShortName} ?
                                            </h4>
                                          </div>
                                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                            <button
                                              class="btn btn-outline-primary py-1"
                                              onClick={() => {
                                                onCancel();
                                              }}
                                            >
                                              No
                                            </button>
                                            <button
                                              class="btn"
                                              onClick={() => {
                                                if (
                                                  item.ChangedLicenseCount - 1 <
                                                  -item.QuantityCurrentActive
                                                )
                                                  return {};
                                                else {
                                                  let data = {
                                                    ...StateLicenseData[index],
                                                    ChangedLicenseCount:
                                                      item.ChangedLicenseCount - 1,
                                                    ProposedLicenseCount:
                                                      item.QuantityCurrentActive +
                                                      item.ChangedLicenseCount -
                                                      1,
                                                  };
                                                  let newStateLicenseData = [...StateLicenseData];
                                                  newStateLicenseData[index] = data;

                                                  this.setState({
                                                    StateLicenseData: newStateLicenseData,
                                                    isClicked: true,
                                                    [item.LicenseShortName]: true,
                                                  });
                                                }
                                                onOk();
                                              }}
                                            >
                                              Yes
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div class="user-license">
                          {/* <h3>
                            <span>
                              Change in License(s) (
                              <strong class="font-weight-bold">{this.state[index]}</strong>)
                            </span>
                          </h3> */}
                          <h3>
                            <span>
                              Total {item.LicenseShortName}
                              {'(s) Count '}(
                              <strong class="font-weight-bold">
                                {item.ChangedLicenseCount + item.QuantityCurrentActive}
                              </strong>
                              )
                            </span>
                          </h3>
                        </div>

                        <div class="month-price">
                          Recurring monthly cost of license(s):
                          <span>
                            {/* ${parseInt(item.UnitCostMonthly) * (this.state[index] || 0)}/month */}

                            {formatter.format(
                              parseInt(item.UnitCostMonthly) * (item.ChangedLicenseCount || 0),
                            )}
                          </span>
                        </div>
                        <span class="license-note">
                          {item.NextBillingDate && (
                            <>
                              {/* Prorated amount for license(s) added now: <span>${item.ProRatedCost}</span> (Approx.) */}
                              Prorated amount for license(s) added now:{' '}
                              <span>
                                {/* ${(((this.state[index] || 0) - item.QuantityCurrentActive) * item.ProRatedCost).toFixed(2)} */}
                                {formatter.format(
                                  ((item.ChangedLicenseCount || 0) * item.ProRatedCost).toFixed(2),
                                )}
                              </span>{' '}
                              (Approx).
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div class="total-price">
            <div class="sub-total">
              <span>Recurring monthly cost of license(s):</span>
              {StateLicenseData[0] && StateLicenseData[0].NextBillingDate && (
                <span>Prorated amount for license(s) added now:</span>
              )}
              {TotalNewTokenCount && (
                <span>
                  Total cost for {numberFormat.format(parseInt(TotalNewTokenCount || 0))} Tokens :
                </span>
              )}
              {/* <span>Sales Tax {salesTax}%:</span> */}
            </div>
            <div class="sales-price">
              <span>{formatter.format(Total)}</span>
              {StateLicenseData[0] && StateLicenseData[0].NextBillingDate && (
                <span>{formatter.format(ProRatedCostTotal.toFixed(2))}</span>
              )}
              {TotalNewTokenCost > 0 && (
                <span>
                  <span>{formatter.format(TotalNewTokenCost || 0)}</span>
                </span>
              )}
              {/* <span>${((Total * salesTax) / 100).toFixed(2)}</span> */}
            </div>
          </div>
          <div class="total-amount flex-wrap mb-5">
            {/* <span>Total Amount:</span> */}
            {/* <span class="amount-price">${(Total + (Total * salesTax) / 100).toFixed(2)}</span> */}
            {/* <span class="amount-price">${(Total + ProRatedCostTotal).toFixed(2)}</span> */}
            {/* <span class="amount-price">{formatter.format(TotalAmt)}</span> */}
            {/* <div class="text-right w-100 pt-3">
                                <a href="#" class="btn" title="Purchase">Purchase</a>
                            </div> */}
            <div class="text-right w-100 pt-3">
              <button
                onClick={() => {
                  const {
                    isTokenUpdated,
                    AILicenseIndex,
                    StateLicenseData,
                    TotalNewTokenCount,
                    TotalNewTokenCost,
                  } = this.state;
                  if (Total === 0 && isTokenUpdated && TotalNewTokenCount == 0) {
                    this.props.dispatch(
                      centerTokenAssignUpdate({
                        Json: JSON.stringify({
                          License: StateLicenseData[AILicenseIndex].LicenseAndTokenDistribution,
                        }),
                      }),
                    );
                  } else {
                    this.props.dispatch(
                      setCurrentLicenses({
                        LicensesCounts: this.state,
                        Total: Total,
                        salesTax,
                        ProRatedCostTotal,
                        TotalNewTokenCount,
                        TotalNewTokenCost,
                      }),
                    );
                    sessionStorage.setItem('state', JSON.stringify(this.state));
                    sessionStorage.setItem('isBack', true);
                    this.props.history.push('/completepurchase');
                    // this.props.history.push('/manage/completepurchase');
                  }
                }}
                class="btn"
                color="blue"
                disabled={Total === 0 && !this.state.isTokenUpdated ? true : false}
              >
                {Total === 0 && this.state.isTokenUpdated && TotalNewTokenCount == 0
                  ? 'Save'
                  : 'Next'}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <h3 class="page-title mb-4">License Activity</h3>
              <div class="card border-0 ">
                <div class="row card-body">
                  <Loader2 loading={this.props.isFetchingInvoice} />
                  <div class="col-12">
                    <List
                      header={<h5>Invoice Details</h5>}
                      // bordered
                      size="large"
                      itemLayout="horizontal"
                      dataSource={InvoiceData}
                      renderItem={(item, index) => {
                        let createdDate = moment.unix(item.created);
                        let period_end = moment.unix(item.period_end);
                        let period_start = moment.unix(item.period_start);
                        const { lines } = item;

                        return (
                          <List.Item className="align-items-start">
                            <div className="invoice-list">
                              <div>
                                <h4>
                                  Invoice Date :<small> {FormatDate(createdDate)}</small>
                                </h4>
                                {/* <h4>
                                  License Validity :<small> {FormatDate(period_start)}</small> to
                                  <small> {FormatDate(period_end)}</small>
                                </h4> */}

                                <h4>
                                  Payment status :{' '}
                                  <small className="text-capitalize">{item.status}</small>
                                </h4>
                              </div>
                              <div>
                                <h6>License Description</h6>
                                <div className="pl-5">
                                  {lines.data &&
                                    lines.data.map((lineData, index) => {
                                      return (
                                        <h4>
                                          {index + 1} :{' '}
                                          <small>
                                            {lineData.description} (
                                            {moment
                                              .unix(lineData.period.start)
                                              .format('DD-MMM-YYYY')}{' '}
                                            to{' '}
                                            {moment.unix(lineData.period.end).format('DD-MMM-YYYY')}
                                            )
                                          </small>
                                        </h4>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                            <div className="invoice-list">
                              <h4>
                                Invoice Number :<small> {item.number && `#${item.number}`}</small>
                              </h4>
                              <h4>
                                Receipt Number :
                                <small> {item.receipt_number && `#${item.receipt_number}`}</small>
                              </h4>
                              <h4>
                                Amount Paid: <small>{formatter.format(item.total / 100)}</small>
                              </h4>
                            </div>
                            <div className="invoice-list">
                              {/* <div>
                                <a
                                  className="download"
                                  href={item.invoice_pdf}
                                  key="list-loadmore-edit"
                                >
                                  Download Invoice PDF
                                </a>
                              </div> */}
                              <div className="d-none">
                                <span
                                  className="download"
                                  onClick={() => {
                                    item.charge && item.charge
                                      ? this.getReceipt(item.charge)
                                      : alert('No Receipt');
                                  }}
                                  // href={item.invoice_pdf}
                                  key="list-loadmore-edit"
                                >
                                  View Receipt
                                </span>
                              </div>
                            </div>
                          </List.Item>
                        );
                      }}
                    />
                    {/* <div class="table-responsive">
                      <Table
                        columns={columns}
                        dataSource={this.props.LicenseData}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/staff-management/staff-list');
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  get_license_activites,
  get_current_licenses,
  get_licenses_invoice,
  get_licenses_receipt,
  default_values,
  center_token_assign_update,
}) => {
  const { data, isFetching, error } = get_license_activites;
  const { data: tokenAssignData, error: tokenAssignError } = center_token_assign_update;
  const { isFetching: currentLicensesFetching } = get_current_licenses;
  const { isFetching: isFetchingInvoice } = get_licenses_invoice;
  var LicenseData = null;
  var CurrentLicenseData = [];
  var hasAdminAccess = false;
  // let LicenseCounts = {};
  let isTokenAssignUpdated = false;
  var InvoiceData = [];
  var ReceiptURL = null;
  if (default_values && default_values.data) {
    hasAdminAccess = default_values.data && default_values.data.IsPurchaseEligible;
  }
  if (get_license_activites.data) {
    LicenseData = get_license_activites.data.data;
  }

  if (get_current_licenses.data) {
    CurrentLicenseData = get_current_licenses.data.data;
    // LicenseCounts = get_current_licenses.data.LicensesCounts;
  }
  if (get_licenses_invoice.data) {
    InvoiceData = get_licenses_invoice.data.data;
  }
  if (get_licenses_receipt.data) {
    ReceiptURL = get_licenses_receipt.data.result;
  }
  if (tokenAssignData && tokenAssignData.success) {
    isTokenAssignUpdated = tokenAssignData.success;
  }
  return {
    LicenseData: LicenseData || [],
    CurrentLicenseData,
    // LicenseCounts,
    isFetching: currentLicensesFetching || isFetching,
    InvoiceData,
    isFetchingInvoice,
    ReceiptURL,
    hasAdminAccess,
    isTokenAssignUpdated,
  };
};

export default connect(mapStateToProps)(Maintainlicense);
