import React, { useState, useEffect } from 'react';
import AntdModal from './CustomModal';

const ConfirmationPopup = ({
  yesAction,
  noAction,
  buttonText,
  yesText,
  noText,
  title,
  className,
  isIcon,
  iconPath,
}) => {
  let actionClassName = className ? className : 'btn';
  return (
    <AntdModal
      ModalButton={({ onClick }) => {
        return isIcon ? (
          <img
            onClick={onClick}
            className={actionClassName}
            src={iconPath || 'images/icons/3dot_blue.svg'}
          />
        ) : (
          <button
            onClick={onClick}
            type="button"
            className={actionClassName}
            data-toggle="modal"
            data-target={`#${buttonText}`}
          >
            {buttonText || 'Action'}
          </button>
        );
      }}
      ModalContent={({ onOk, onCancel }) => (
        <div className="modal-content border-0">
          <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
          <div className="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
            <h4 className="modal-title col-12 mb-3">
              {title ? title : 'Are you sure you want?'}
              <br />
            </h4>
          </div>
          <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                onCancel();
                noAction && noAction();
              }}
            >
              {noText || 'No'}
            </button>
            <button
              className="btn"
              onClick={() => {
                onOk();
                yesAction && yesAction();
              }}
            >
              {yesText || 'Yes'}
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default ConfirmationPopup;
