import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import ActionableIcon from '../../Components/ActionableIcon';
import { isComparer } from '../../util/handler';
import { adminstrationListCenter } from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import { saveAs } from 'file-saver';
import { ReduxSelect } from '../../component/custom-redux-components/index.js';
import PageTitle from '../../Components/PageTitle';

class Center extends Component {
  constructor() {
    super();
    this.state = {
      searchStr: null,
      FilterBy: [
        {
          Text: 'All',
          Value: 'All',
        },
        {
          Text: 'Active',
          Value: 'Active',
        },
        {
          Text: 'Inactive',
          Value: 'Deactivated',
        },
      ],
      filterBy: 'Active',
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount({ searchStr, filterBy } = this.state) {
    const { dispatch } = this.props;
    dispatch(
      adminstrationListCenter({
        Filter: filterBy,
        searchStr: searchStr,
      }),
    );
  }

  onSearchChange = control => {
    var { value } = control.target;
    const { dispatch } = this.props;
    this.state.searchStr = value;
    if (value.length == 0) {
      dispatch(
        adminstrationListCenter({ searchStr: this.state.searchStr, Filter: this.state.filterBy }),
      );
    } else {
      dispatch(
        adminstrationListCenter({ searchStr: this.state.searchStr, Filter: this.state.filterBy }),
      );
    }
  };

  onRowClick = (event, record, rowIndex) => {
    const { history } = this.props;
    history.push('/manage/center-form?centerId=' + record.CenterId);
  };

  onManageClick = centerId => {
    const { history } = this.props;
    // history.push('/manage/staff?centerId=' + centerId);
    history.push('staff-management/staff-list?centerId=' + centerId);
  };
  downloadAsExcel = () => {
    const { dispatch } = this.props;
    this.setState({ idFileDownloading: true });
    const me = this;
    var fileName;
    const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
    var hearders = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Accept: 'application/vnd.ms-excel',
    };

    fetch(`/api/v1/Adminstration/DownloadToExcel`, {
      method: 'POST',
      headers: hearders,
      body: JSON.stringify({ InputType: 'Center' }),
      responseType: 'arraybuffer',
    })
      .then(response => {
        if (response.ok) {
          fileName = response.headers
            .get('content-disposition')
            .split('filename=')[1]
            .split(';')[0];
          return response.blob();
        }
      })
      .then(blob => {
        me.setState({ idFileDownloading: false });
        saveAs(blob, fileName || 'Center.xlsx');
      });
  };

  render() {
    const { isFetching, centers } = this.props;
    const { FilterBy, filterBy } = this.state;
    const columns = [
      {
        title: ' Center ID',
        dataIndex: 'CenterId',
        key: 'CenterId',
        // sorter: (a, b) => isComparer(a.CenterId, b.CenterId),
        // sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onRowClick(event, record, rowIndex),
          };
        },
      },
      {
        title: ' Center Name',
        dataIndex: 'Name',
        key: 'Name',
        sorter: (a, b) => isComparer(a.Name, b.Name),
        sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onRowClick(event, record, rowIndex),
          };
        },
      },
      {
        title: ' Parent Center',
        dataIndex: 'ParentCenterName',
        key: 'ParentCenterName',
        sorter: (a, b) => isComparer(a.ParentCenterName, b.ParentCenterName),
        sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onRowClick(event, record, rowIndex),
          };
        },
      },
      {
        title: 'City',
        dataIndex: 'City',
        key: 'City',
        sorter: (a, b) => isComparer(a.City, b.City),
        sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onRowClick(event, record, rowIndex),
          };
        },
      },
      {
        title: 'State',
        dataIndex: 'StateOrProvince',
        key: 'State',
        sorter: (a, b) => isComparer(a.StateOrProvince, b.StateOrProvince),
        sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onRowClick(event, record, rowIndex),
          };
        },
      },
      {
        title: 'Region',
        dataIndex: 'Region',
        key: 'Region',
        sorter: (a, b) => isComparer(a.Region, b.Region),
        sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onRowClick(event, record, rowIndex),
          };
        },
      },
      {
        title: 'Sub Region',
        key: 'SubRegion',
        dataIndex: 'SubRegion',
        sorter: (a, b) => isComparer(a.SubRegion, b.SubRegion),
        sortDirections: ['descend', 'ascend'],
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onRowClick(event, record, rowIndex),
          };
        },
      },
      {
        title: 'Status',
        key: 'CenterStatus',
        dataIndex: 'CenterStatus',
        sorter: (a, b) => isComparer(a.CenterStatus, b.CenterStatus),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '',
        key: 'Manage',
        dataIndex: 'Manage',
        render: () => <button class="btn">Manage Staff</button>,
        onCell: (record, rowIndex) => {
          return {
            onClick: event => this.onManageClick(record.CenterId),
          };
        },
      },
    ];
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Center" />
        {/* <Loader loading={isFetching} /> */}
        <div class="content-area">
          <div class="row top-table-search mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-3 col-lg-2">
              {/* <Link to="/manage" class="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link> */}
              <a
                class="back-link"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.history.push('/manage')}
              >
                <img src="images/back-arrow.svg" /> Back
              </a>
              <h3 class="page-title mb-3 mb-md-0">Centers</h3>
            </div>

            <div class="col-12 col-md-12 col-lg-10 text-right d-flex align-items-center justify-content-end">
              <div className="text-left app-subscriber-page d-inline-block">
                <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                  <ReduxSelect
                    options={FilterBy}
                    onChange={val => {
                      this.props.dispatch(
                        adminstrationListCenter({
                          Filter: val,
                          searchStr: this.state.searchStr,
                        }),
                      );
                      this.setState({
                        filterBy: val,
                      });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={filterBy}
                    // placeholder="Filter By"
                  />
                </div>
              </div>
              <div class="search-box d-inline-flex w-100">
                <div class="form-group has-search w-100">
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    placeholder="Search"
                    id="filterbox"
                    onChange={this.onSearchChange}
                  />
                  <button class="btn search-icon " type="button">
                    {' '}
                    <i class="fa fa-search" />{' '}
                  </button>
                </div>
              </div>

              <Link to="/manage/center-form" class="btn black-btn" color="blue">
                Add Center
              </Link>
              {/* <input
                type="button"
                className="btn black-btn"
                value="Download Excellllll"
                onClick={this.downloadAsExcel}
                // onClick={this.getAdmissionReportData}
              /> */}
              <ActionableIcon
                title="Download Excel"
                iconSrc="images/ExcelDownload.svg"
                onClick={this.downloadAsExcel}
                style={{
                  marginTop: '15px',
                }}
              />

              {/* <a href="#" class="btn" title="Add Staff">Add Staff</a> */}
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    <Loader2 loading={isFetching} />
                    <div className="row-hover-enabled">
                      <Table
                        columns={columns}
                        dataSource={centers}
                        size={'middle'}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ center_get_all }) => {
  var { data, isFetching, error } = center_get_all;
  if (data) {
    var array = [];
    for (var index in data) {
      array.push(data[index]);
    }
    data = array;
  }
  return {
    centers: data || [],
    isFetching: isFetching,
  };
};

export default connect(mapStateToProps)(Center);
