import React, { Component } from 'react';
import GroupList from './GroupList';
import GroupDetails from './GroupDetails';

import { connect } from 'react-redux';

class GroupManagement extends Component {
  constructor(props) {
    super(props);
    // var params = new URLSearchParams(props.location.search);
    // var tab = params.get('tab');
    // var isListView = false;

    // if (tab == 'GroupManagement/groupDetails') {
    //   isListView = true;
    // }
    this.state = {
      isListView: true,
    };
  }
  toggleView = () => {
    // this.setState(prevState => ({
    //   isListView: !prevState.isListView,
    // }));
    this.props.history.push('/rcm?tab=GroupManagement/groupList');
  };
  render() {
    // const params = new URLSearchParams(this.props.location.search);
    // const tab = params.get('tab');
    return (
      <>
        <div class="main enrollment-page mb-5">
          <div class="container-fluid px-5">
            {/* {tab == 'GroupManagement/groupList' && (
              <div className="row"> */}
            <GroupList {...this.props} toggleView={this.toggleView} />
            {/* </div>
            )} */}
            {/* {tab == 'GroupManagement/groupDetails' && (
              <GroupDetails {...this.props} toggleView={this.toggleView} />
            )} */}
            {/* {!this.state.isListView ? (
              <div className="row">
                <ClientList {...this.props} toggleView={this.toggleView} />
              </div>
            ) : (
              <ClientProfile {...this.props} toggleView={this.toggleView} />
            )} */}
          </div>
        </div>
      </>
    );
  }
}
export default GroupManagement;
