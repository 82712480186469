import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxTimePicker,
  ReduxTextarea,
} from '../../../../../component/custom-redux-components';
import SubmitButton from '../../../../../Components/submitButton';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  reset,
} from 'redux-form';
import { upsertTreatmentPlanObjective, getTreatmentPlan } from '../../../../../store/actions';

const Validator = {
  required: value => (value ? undefined : 'Required'),
  requiredInput: value => {
    let val = value && value.length > 0 && value.trim();
    return val ? undefined : 'Required';
  },
  requiredAny: (value, values) => {
    let emailVal = values.Email;
    let mobileVal = values.MobileNumber;
    if (!emailVal) {
      if (!mobileVal) {
        return 'Required';
      }
    } else {
      return;
    }
  },
  endTime: (value, values, props, name) => {
    let beginningTime = values.g_startTime || values.startTime || values.existingPatientStartTime;
    let endTime = values.g_endTime || values.endTime || values.existingPatientEndTime;
    if (beginningTime.isBefore(endTime)) {
    } else {
      return 'should be greater than Start time';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'is Incorrect' : undefined,
  allowNumericMobile: value => (value && !/^\d{10}$/.test(value) ? 'is Incorrect' : undefined),
};

class AddObjectiveModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  componentWillReceiveProps({ upsertTreatmentPlanObjectiveDataSuccess }) {
    if (
      upsertTreatmentPlanObjectiveDataSuccess &&
      upsertTreatmentPlanObjectiveDataSuccess != this.props.upsertTreatmentPlanObjectiveDataSuccess
    ) {
      this.props.toggleObjectiveModal();
      this.props.resetMessageText(
        `Objective ${this.props.treatmentPlanObjectiveId ? 'Updated' : 'Added'} Successfully`,
        {
          Json: JSON.stringify({
            TreatmentPlan: [
              {
                TreatmentPlanGoalId: this.props.treatmentPlanGoalId,
              },
            ],
          }),
        },
      );

      // let data = {
      //   Json: JSON.stringify({
      //     TreatmentPlan: [
      //       {
      //         TreatmentPlanGoalId: this.props.treatmentPlanGoalId,
      //       },
      //     ],
      //   }),
      // };
      // this.props.dispatch(getTreatmentPlan(data));
    }
  }

  onhandleSubmit = val => {
    const { treatmentPlanGoalId, treatmentPlanObjectiveId } = this.props;
    let data;
    if (treatmentPlanGoalId && treatmentPlanObjectiveId) {
      data = {
        Json: JSON.stringify({
          TreatmentPlanObjective: [
            {
              TreatmentPlanObjectiveId: treatmentPlanObjectiveId,
              TreatmentPlanGoalId: treatmentPlanGoalId,
              Description: val.objectiveDescription,
            },
          ],
        }),
      };
    } else {
      data = {
        Json: JSON.stringify({
          TreatmentPlanObjective: [
            {
              TreatmentPlanObjectiveId: null,
              TreatmentPlanGoalId: this.props.treatmentPlanGoalId,
              Description: val.objectiveDescription,
            },
          ],
        }),
      };
    }

    this.props.dispatch(upsertTreatmentPlanObjective(data, this.props.treatmentPlanDimensionId));

    // this.props.resetInitialValues();
  };

  render() {
    const { onCancel, isFetching } = this.props;
    return (
      <>
        <div class="modal-content border-0">
          <div class="modal-header flex-wrap border-bottom-0 ">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              onClick={() => {
                onCancel();
                this.props.resetInitialValues();
              }}
            >
              ×
            </button>
            <h4 class="modal-title col-12 text-left">Objective</h4>
            {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
          </div>
          <div class="modal-body rcm-panel">
            <div className="px-4 enrollment-form">
              <form onSubmit={this.props.handleSubmit(this.onhandleSubmit)}>
                <div class="form-group mb-2">
                  <Field
                    name="objectiveDescription"
                    type="textarea"
                    component={ReduxTextarea}
                    fieldName={'Objective Description'}
                    validate={Validator.required}
                    style={{ minHeight: '92px' }}
                  />
                  <label class="placeholder-label" style={{ background: '#fefefe' }}>
                    Objective Description*
                  </label>
                </div>

                <div class="modal-footer  border-top-0 pt-2 px-0">
                  <SubmitButton
                    loading={isFetching}
                    style={{ padding: '0 24px', height: '44px' }}
                    value={
                      this.props.treatmentPlanObjectiveId ? 'Update Objective' : ' Add Objective'
                    }
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ upsert_treatment_plan_objective }) => {
  const { data, isFetching } = upsert_treatment_plan_objective;
  let upsertTreatmentPlanObjectiveDataSuccess;
  if (upsert_treatment_plan_objective && upsert_treatment_plan_objective.data) {
    upsertTreatmentPlanObjectiveDataSuccess = upsert_treatment_plan_objective.data.success;
  }
  return {
    upsertTreatmentPlanObjectiveDataSuccess: upsertTreatmentPlanObjectiveDataSuccess || false,
    isFetching,
  };
};

const ReduxAddObjectiveModal = reduxForm({
  form: 'AddObjectiveModal',
  enableReinitialize: true,
})(connect(mapStateToProps)(AddObjectiveModal));

export { ReduxAddObjectiveModal };
