import React, { useEffect, useState, useRef, useId, useDeferredValue } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setStickeyNote, resetSetStickeyNote } from '../../../store/actions';
const StopwatchTimerAction = props => {
  const dispatch = useDispatch();
  // const PeerToggle = localStorage.getItem(`PeerToggle`);

  const { fNotes, stickeyData } = useSelector(
    ({
      set_stickey_note: { data: stickeyData },
      student_timer_upsert: { data: timerUpsertData },
    }) => {
      return {
        fNotes: stickeyData && stickeyData.fNotes,
        stickeyData: stickeyData,
      };
    },
    shallowEqual,
  );

  const { ID, CurrentClientId, Record } = props;
  const startSession = () => {
    dispatch(setStickeyNote({ fNotes: true, ID, CurrentClientId, Record }));
  };
  return (
    <div>
      {fNotes ? (
        // <span className="mr-2">
        //   The current session is active. To initiate a new session, please conclude the current one.
        // </span>

        <button
          className="btn mr-2"
          style={{
            cursor: 'pointer',
          }}
          disabled
          title="The current session is active. To initiate a new session, please conclude the current one."
        >
          End Sesstion
        </button>
      ) : (
        <span
          className="btn"
          onClick={startSession}
          style={{
            cursor: 'pointer',
          }}
        >
          Start Session
        </span>
      )}
    </div>
  );
};
export default StopwatchTimerAction;
