import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal from '../../../../core/modal';
import { Table, Checkbox, Radio, Modal } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import {
  getClientAppointment,
  getGroupAppointment,
  upsertGroupAppointmentGroupAllInfoJson,
} from '../../../../store/actions';
import IndividualAppointmentModal from '../../appointments/individualAppointmentModal';
import GroupAppointmentModal from '../../appointments/groupAppointmentModal';
import PersistTableSort from '../../../../component/persistTableSort';
import NotesDrawer from './notesDrawer/notesDrawer';

class InduvidualAppointment extends Component {
  constructor() {
    super();
    this.state = {
      notesDrawerVisible: false,
      appointmentId: '',
      isGroup: '',
      groupAppointmentId: '',
      isModelOpen: '',
      header: '',
      groupModalView: false,
      columns: [
        {
          title: 'Session Date',
          dataIndex: 'SessionDate',
          key: 'SessionDate',
          sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Start / End Time',
          dataIndex: 'SessionTime',
          key: 'SessionTime',
          sorter: (a, b) => isComparer(a.SessionTime, b.SessionTime),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Session Type',
          dataIndex: 'SessionType',
          key: 'SessionType',
          sorter: (a, b) => isComparer(a.SessionType, b.SessionType),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Program',
          dataIndex: 'ProgramDescription',
          key: 'ProgramDescription',
          sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          key: 'Status',
          sorter: (a, b) => isComparer(a.Status, b.Status),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Action',
          render: (text, record, index) => {
            return (
              <>
                {!this.props.isReadOnlyUser && (
                  <div class="tools-grid d-flex align-items-center">
                    <>
                      {record.IsGroupAppointment ? (
                        <span className="start-btn">
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              // this.showDrawer(record);

                              this.setState({
                                appointmentRecord: record,
                              });

                              this.props.dispatch(
                                upsertGroupAppointmentGroupAllInfoJson({
                                  json: JSON.stringify({
                                    GroupAppointment: [
                                      {
                                        GroupAppointmentId: record.AppointmentId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          >
                            {' '}
                            Manage Appointment{' '}
                          </span>
                          {record.HasNote && (
                            <span
                              style={{
                                cursor: 'pointer',
                                marginLeft: '25px',
                              }}
                              onClick={() => {
                                this.showDrawer(
                                  record.AppointmentId,
                                  record.SessionType,
                                  record.AppointmentId,
                                );
                              }}
                            >
                              {' '}
                              <img
                                src="images/appNoteIcon.svg"
                                style={{
                                  width: '20px',
                                  cursor: 'pointer',
                                }}
                              />
                            </span>
                          )}
                        </span>
                      ) : (
                        <span className="start-btn">
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({
                                eventModalView: true,
                                appointmentRecord: record,
                              });

                              // this.props.history.push(
                              //   `/cw/patient-management/patient-profile?tab=individual-appointment-list&pId=${this.getClientId()}&appointmentId=${
                              //     record.AppointmentId
                              //   }`,
                              // );
                            }}
                          >
                            {' '}
                            Manage Appointment{' '}
                          </span>
                          {record.HasNote && (
                            <span
                              style={{
                                cursor: 'pointer',
                                marginLeft: '25px',
                              }}
                              onClick={() => {
                                this.showDrawer(
                                  record.AppointmentId,
                                  record.IsGroup,
                                  record.GroupAppointmentId,
                                );
                              }}
                            >
                              {' '}
                              <img
                                src="images/appNoteIcon.svg"
                                style={{
                                  width: '20px',
                                  cursor: 'pointer',
                                }}
                              />
                            </span>
                          )}
                        </span>
                      )}
                    </>
                  </div>
                )}
              </>
            );
          },
        },
      ],
    };
  }

  onCloseDrawer = () => {
    this.setState({
      notesDrawerVisible: false,
    });
    this.props.history.push(
      `/cw/patient-management/patient-profile?tab=individual-appointment-list&pId=${this.getClientId()}`,
    );
  };

  showDrawer = (appointmentId, isGroup, groupAppointmentId) => {
    this.setState({
      notesDrawerVisible: true,
      appointmentId: appointmentId,
      isGroup: isGroup,
      groupAppointmentId: groupAppointmentId,
    });
  };

  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentDidMount() {
    this.props.dispatch(
      getClientAppointment({
        Json: JSON.stringify({
          Appointment: [
            {
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );

    let param = new URLSearchParams(this.props.history.location.search);
    let appointmentId = param.get('appointmentId');
    if (appointmentId) {
      setTimeout(() => {
        this.setState({
          eventModalView: true,
          appointmentRecord: { AppointmentId: appointmentId },
        });
      }, 1000);
    }
  }
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      getClientAppointment({
        json: JSON.stringify({
          Appointment: [
            {
              Search: value,
              ClientId: this.getClientId(),
            },
          ],
        }),
      }),
    );
  };

  componentWillReceiveProps({ groupId }) {
    if (groupId && this.props.groupId != groupId) {
      this.setState({
        groupId: groupId,
        groupModalView: true,
        appointmentRecord: this.state.appointmentRecord,
      });
    }
  }
  render() {
    const {
      columns,
      appointmentId,
      isGroup,
      groupAppointmentId,
      isModelOpen,
      header,
      eventModalView,
      appointmentRecord,
      groupModalView,
      notesDrawerVisible,
    } = this.state;

    const { clientId, AppointmentList, isFetching } = this.props;
    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page mb-5">
          <div class="container-fluid rcm-tab">
            <div class="row">
              <div class="col-12 mb-5 px-0">
                <div class="border-0">
                  <div className="">
                    <div className="row mt-5 d-flex align-items-center">
                      <div class="col-6 px-0">
                        {' '}
                        <h4 className="">Appointment List</h4>
                      </div>
                      <div class="col-6 d-flex align-items-center">
                        <div class="search-box d-inline-flex w-100">
                          <div class="form-group has-search w-100 d-flex align-items-center">
                            <input
                              type="text"
                              class="form-control 
                   shadow-sm rcm-document-search"
                              placeholder="Search"
                              id="filterbox"
                              onChange={this.onSearchChange}
                            />
                            <button class="btn search-icon " type="button">
                              {' '}
                              <i class="fa fa-search" />{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-5 pb-5 rcm-subtab-table-block">
                    <div class="col-12" style={{ position: 'relative' }}>
                      <Loader2 loading={isFetching} />
                      <PersistTableSort
                        name="AppointmentList"
                        columns={columns}
                        dataSource={AppointmentList}
                        size={'middle'}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: event => this.onRowClick(record.ClientId),
                        //   };
                        // }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a
                                      class="paginate_button "
                                      onClick={() => {
                                        window.scrollTo(0, 350);
                                      }}
                                    >
                                      {page}
                                    </a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {eventModalView && (
            <Modal
              zIndex={100}
              visible={eventModalView}
              footer={null}
              closable={false}
              width={700}
              destroyOnClose={true}
            >
              <IndividualAppointmentModal
                isReadOnlyUser={this.props.isReadOnlyUser}
                AppointmentId={appointmentRecord.AppointmentId}
                AppointmentRecord={appointmentRecord}
                onCancel={() => {
                  this.props.dispatch(
                    getClientAppointment({
                      Json: JSON.stringify({
                        Appointment: [
                          {
                            ClientId: this.getClientId(),
                          },
                        ],
                      }),
                    }),
                  );
                  // this.props.history.push(
                  //   `/cw/patient-management/patient-profile?tab=individual-appointment-list&pId=${this.getClientId()}`,
                  // );

                  let param = new URLSearchParams(this.props.history.location.search);
                  let appointmentId = param.get('appointmentId');
                  if (appointmentId) {
                    this.props.history.goBack();
                  }

                  this.setState({
                    eventModalView: false,
                    appointmentRecord: {},
                  });
                }}
              />
            </Modal>
          )}
          {groupModalView && appointmentRecord && (
            <Modal
              zIndex={100}
              visible={groupModalView}
              footer={null}
              closable={false}
              width={700}
              destroyOnClose={true}
            >
              <GroupAppointmentModal
                isReadOnlyUser={this.props.isReadOnlyUser}
                GroupAppointmentId={appointmentRecord.AppointmentId}
                AppointmentRecord={appointmentRecord}
                onCancel={() => {
                  this.props.dispatch(
                    getGroupAppointment({
                      json: JSON.stringify({
                        GroupAppointment: [
                          {
                            GroupId: this.state.groupId,
                          },
                        ],
                      }),
                    }),
                  );
                  this.setState({
                    groupModalView: false,
                  });
                }}
              />
            </Modal>
          )}
          {notesDrawerVisible && (
            <NotesDrawer
              OnCloseDrawer={this.onCloseDrawer}
              NotesDrawerVisible={notesDrawerVisible}
              AppointmentId={appointmentId}
              IsGroup={isGroup}
              ClientId={this.getClientId()}
              GroupAppointmentId={groupAppointmentId}
              toggle={this.toggle}
            />
          )}
          {isModelOpen && (
            <CustomModal
              isOpen={isModelOpen}
              zIndex={9999999}
              header={header}
              yesAction={() => {
                this.toggle();
              }}
              yesText="OK"
              hideCancel={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  get_client_appointment,
  upsert_group_appointment_group_all_info_json,
}) => {
  const { data: appointmentData, isFetching } = get_client_appointment;
  const {
    data: getGroupAppointmentData,
    isFetching: isFetchingGetGroupAppointment,
    error: errorGetGroupAppointment,
  } = upsert_group_appointment_group_all_info_json;
  let AppointmentList = [];
  let groupId;

  if (getGroupAppointmentData && getGroupAppointmentData.GroupAppointment) {
    groupId = getGroupAppointmentData.GroupAppointment[0].GroupId;
  }

  if (appointmentData && appointmentData.data) {
    AppointmentList = appointmentData.data || [];
  }
  return {
    AppointmentList,
    isFetching,
    groupId,
  };
};

export default connect(mapStateToProps)(withRouter(InduvidualAppointment));
