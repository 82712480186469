import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { withRouter, Redirect, Link, Route } from 'react-router-dom';
import MultipleCategoryCheckboxAll from '../../Components/multipleCategoryCheckbox';
import CustomModal from '../../core/modal';
import {
  getFilters,
  payerPortalRequestInsert,
  resetPayerPortalRequestInsert,
} from '../../store/actions';

class FilterModal extends Component {
  constructor(props) {
    super(props);
    // const { checked, author, filterCourseType, filterCourseTopic, filterNameCourse } = props;
    this.state = {
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      //   plainOptions: props.timelineDropDown.map(item => {
      //     return item.Text;
      //   }),
      selectedCheckBoxArrayValues: [],
      checkedItems: [],
      isModalOpen: false,
    };
  }

  //   onChange = checkedList => {
  //     var selectedCheckBoxArray = this.props.timelineDropDown.filter(function(item) {
  //       if (checkedList.includes(item.Text)) return item.Value;
  //     });
  //     var selectedCheckBoxArrayValues = selectedCheckBoxArray.map(item => {
  //       return item.Value;
  //     });
  //     var data1 = JSON.stringify(selectedCheckBoxArrayValues).replaceAll('[', '');
  //     var data2 = data1.replaceAll(']', '');
  //     this.setState({
  //       checkedList,
  //       selectedCheckBoxArrayValues: data2,
  //       indeterminate: !!checkedList.length && checkedList.length < this.state.plainOptions.length,
  //       checkAll: checkedList.length === this.state.plainOptions.length,
  //     });
  //     this.props.updateFilterValues(data2);
  //   };

  //   onCheckAllChange = e => {
  //     this.setState({
  //       checkedList: e.target.checked ? this.state.plainOptions : [],
  //       indeterminate: false,
  //       checkAll: e.target.checked,
  //     });
  //   };

  resetState = () => {
    sessionStorage.removeItem('timelineFilterMultiCheckboxInteger');
    sessionStorage.removeItem('timelineFilterMultiCheckbox');
    this.setState(
      {
        selectedCheckBoxArrayValues: [],
        checkedItems: [],
      },
      () => {
        this.sendFilters();
      },
    );
  };

  getUserGuid() {
    let url = this.props.location && this.props.location.search.split('=')[1];
    if (!url) {
      url = this.props.location.pathname && this.props.location.pathname.split('/')[2];
    }
    return url;
  }
  getStudentId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentid = param.get('studentid') || param.get('id');
    if (studentid) {
      studentid = parseInt(studentid);
    } else {
      studentid = null;
    }
    return studentid;
  }
  getClientId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let patientid = param.get('patientid');
    if (patientid) {
      patientid = parseInt(patientid);
    } else {
      patientid = null;
    }
    return patientid;
  }
  componentDidMount() {
    this.props.dispatch(getFilters({}));
    let getStoredFilter = JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'));
    this.setState({ checkedItems: getStoredFilter || [] });
    let storedValuesArray = JSON.parse(sessionStorage.getItem('timelineFilterValues'));
    if (storedValuesArray && storedValuesArray.length > 0) {
      let result = '';
      for (let i = 0; i < storedValuesArray.length; i++) {
        result += storedValuesArray[i] + ',';
      }

      this.setState({
        // checkedList: JSON.parse(sessionStorage.getItem('timelineFilterTexts')),
        selectedCheckBoxArrayValues: result,
        checkedList: getStoredFilter,
        // defaultCheckedList: JSON.parse(sessionStorage.getItem('timelineFilterTexts')),
      });
    }
  }
  getCheckItems = (data, index) => {
    let checkedItems = {
      ...this.state.checkedItems,
      [index]: data,
    };
    this.setState({ checkedItems });
  };
  sendFilters = () => {
    // this.props.resetTimelineList();
    if (Object.keys(this.state.checkedItems).length > 0) {
      let keys = Object.keys(this.state.checkedItems);
      let filterValue = [];
      keys.map(item => {
        let childrenData = this.props.filtersData[parseInt(item)].children;
        let selectedItems = this.state.checkedItems[parseInt(item)];
        childrenData.filter(item => {
          if (selectedItems.includes(item.label)) {
            filterValue.push(item.value);
            return item.value;
          }
        });
      });
      //    if (this.getStudentId()) {
      //  let data = {
      //    TimeLine: [
      //      {
      //        FilterBy: filterValue.toString(),
      //        StartDate: this.props.StartDate,
      //        EndDate: this.props.EndDate,
      //        StudentId: this.getStudentId(),
      //      },
      //    ],
      //  };

      this.props.dispatch(
        payerPortalRequestInsert({
          JSON: JSON.stringify({
            Request: [
              {
                ClientId: this.props.ClientId,
                SmartContractId: this.props.smartContractId, 
                Notes: this.props.Notes || null,
                ClientAuthorizationId: this.props.ClientAuthorizationId,
                TimelineEventTypeIds: filterValue.toString(),
              },
            ],
          }),
        }),
      );
      //    }
    }
  };

  componentWillReceiveProps({ isSuccess }) {
    if (isSuccess && isSuccess != this.props.isSuccess) {
      this.setState({ isModalOpen: true });
      this.props.dispatch(resetPayerPortalRequestInsert({}));
    }
  }

  render() {
    const {
      onOk,
      onCancel,
      Centers,
      refreshGrid,
      CourseType,
      CourseTopic,
      NameCourse,
      timelineDropDown,
      isFetching,
    } = this.props;
    const { checked, author, filterCourseType, filterCourseTopic, filterNameCourse } = this.state;

    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            // onClick={onCancel}
            onClick={this.props.openAndCloseModal}
          >
            ×
          </button>
          <h4 class="modal-title col-12 text-left">Request More Information</h4>
        </div>
        <div class="modal-body pb-2">
          <div class="row m-0 filter-modal">
            {/* <div class="col-12 text-left mb-3">
              <span className="filter-status">Events</span>
            </div> */}
            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-auto mr-2 mb-2 event-list" style={{ width: '100%' }}>
                  <div
                    className="payer timetravel-filter"
                    style={{ maxHeight: '450px', overflowY: 'auto', height: 'unset' }}
                  >
                    {/* <div
                    // style={{ borderBottom: '1px solid #E9E9E9' }}
                    >
                      <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                      >
                        Check all
                      </Checkbox>
                    </div> */}
                    {this.props.filtersData &&
                      this.props.filtersData.map((item, index) => {
                        return (
                          <MultipleCategoryCheckboxAll
                            data={item}
                            index={index}
                            getCheckItems={this.getCheckItems}
                            // defaultValue={
                            //   sessionStorage.getItem('timelineFilterMultiCheckbox') &&
                            //   JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[
                            //     index
                            //   ] &&
                            //   JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[
                            //     index
                            //   ].length > 0
                            //     ? JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[
                            //         index
                            //       ]
                            //     : []
                            // }
                          />
                        );
                      })}

                    <br />
                    {/* <CheckboxGroup
                      options={this.state.plainOptions}
                      value={this.state.checkedList}
                      onChange={this.onChange}
                      class="event-list-group"
                      // defaultValue={this.state.checkedList}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          {/* <a
            class="link2 font-weight-bold mr-2"
            onClick={() => {
              this.resetState();
            }}
          >
            Reset
          </a> */}
          {!isFetching && (
            <button
              className="btn "
              onClick={this.sendFilters}
              // disabled={this.state.checkedItems && this.state.checkedItems.length <= 0}
            >
              Send Request
            </button>
          )}
        </div>
        <CustomModal
          isOpen={this.state.isModalOpen}
          header={'Request Sent Successfully '}
          yesAction={() => {
            this.setState({ isModalOpen: false });
            this.props.openAndCloseModal();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToChildrean = ({ get_filters, payer_portal_request_insert }) => {
  var { data, isFetching } = get_filters;
  let { data: requestData } = payer_portal_request_insert;
  let filtersData;
  if (data) {
    filtersData = data.TimelineEventType;
  }
  return {
    filtersData,
    isFetching,
    isSuccess: (requestData && requestData.success) || false,
  };
};
export default connect(mapStateToChildrean)(withRouter(FilterModal));
