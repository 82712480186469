import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Collapse, Tooltip } from 'antd';
// import { StudentImage } from '../../../../component/student';
import Loader, { Loader2 } from '../../../../Components/Loader';
import '../../../../component/jquery/slick.js';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isEqual } from 'lodash';
import AntdModal from '../../../../Components/CustomModal';
import QuestionType from './questionType/questionComponent';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';
import ValidatePin from '../../../../Components/validatePinModal';
import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  listSurveyQuestionCategoryAssessment,
  surveyAssign,
  listSurveyQuestionAssessment,
  deactivateClientScreening,
  markAsComplete,
  unsignForm,
  listSurveyQuestionCategoryGetAll,
  cloneForm,
  resetListSurveyQuestionAssessment,
} from '../../../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import Axios from 'axios';
import CustomModal from '../../../../core/modal';
import PersistTableSort from '../../../../component/persistTableSort';
import LinkAppointment from '../../rcmComponents/linkingAppointment';
import DocumentForm from './documentForm';

const { Panel } = Collapse;

const SURVEYTYPES = {
  Binary: 1,
  Options: 2,
  Numeric: 3,
  AlphaNumeric: 4,
  SmileyType: 5,
  Currency: 6,
};

class Survey extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      SurveyQuestionCategoryListData: [],
      assesmentId: '',
      screeningView: true,
      surveyListData: [],
      surveyQuestionAssessment: {},
      currentPage: 1,
      validPinModalVisible: false,
      expandAll: false,
      isAssignAlreadyModal: false,
      isAssignNew: false,
      signStatusText: null,
      signStatusId: null,
    };
  }

  getLatestSurvey = () => {
    this.props.dispatch(
      listSurveyQuestionAssessment({
        json: JSON.stringify({
          Survey: [
            {
              SurveyId: this.state.SurveyId,
            },
          ],
        }),
      }),
    );
  };

  getUpdatedSurvey = () => {
    this.setState(
      {
        isAppointmentLinked: true,
      },
      () => {
        this.getLatestSurvey();
      },
    );
  };

  resetValidPinModalVisible = () => {
    this.setState({ validPinModalVisible: !this.state.validPinModalVisible });
  };

  onAssignSurvey = () => {
    var item = {
      studentIds: this.props.clientId,
      surveyQuestionCategoryIds: this.state.assesmentId,
    };
    if (this.props.SurveyListData.length == 0) {
      this.setState({
        isAssignNew: true,
      });
    } else {
      this.props.SurveyListData.map(item =>
        item.SurveyQuestionCategoryId == this.state.assesmentId &&
        item.DocumentGuid == null &&
        item.SurveyStatus == 0
          ? this.setState({
              isAssignAlreadyModal: true,
            })
          : this.setState({
              isAssignNew: true,
            }),
      );
    }
    this.props.dispatch(surveyAssign(item));
  };

  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }

  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);

    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }
  componentDidMount() {
    this.props.dispatch(
      listSurveyQuestionCategoryGetAll({
        json: JSON.stringify({
          Survey: [
            {
              ClientId: this.props.clientId || this.getClientId(),
              SurveyTypeDesc: this.props.description || 'Assessment Tools',
            },
          ],
        }),
      }),
    );

    var param = new URLSearchParams(this.props.history.location.search);
    var surveyId = param.get('surveyId');
    if (surveyId) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                SurveyId: surveyId,
              },
            ],
          }),
        }),
      );
      this.setState({
        SurveyId: surveyId,
        isReadOnly: true,
      });
    }
  }

  toggleView2 = (getLatest, header) => {
    this.setState(prevState => ({
      screeningView: true,
      isSignedModal: !prevState.isSignedModal,
      header,
    }));
    if (getLatest) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: this.props.description || 'Assessment Tools',
              },
            ],
          }),
        }),
      );
    }
  };

  toggleView = getLatest => {
    this.setState(prevState => ({
      screeningView: !prevState.screeningView,
      isAppointmentLinked: false,
    }));
    if (getLatest) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: this.props.description || 'Assessment Tools',
              },
            ],
          }),
        }),
      );
    }
  };
  componentWillReceiveProps({
    surveys,
    isSurveyAssign,
    SurveyQuestionCategoryList,
    SurveyListData,
    surveyQuestionAssessment,
    isSurveyDeactivated,
    diagnosisData,
    isSurveyCompleted,
    validateStaffPinData,
    unsignFormStatus,
    isSurveyCloned,
  }) {
    if (unsignFormStatus && unsignFormStatus != this.props.unsignFormStatus) {
      this.props.dispatch(
        listSurveyQuestionAssessment({
          json: JSON.stringify({
            Survey: [
              {
                SurveyId: this.state.SurveyId,
              },
            ],
          }),
        }),
      );
      this.setState(prevState => ({
        screeningView: true,
        isReadOnly: false,
      }));
    }

    if (surveys.length > 0 && surveys.length != this.props.surveys.length) {
      this.toggleView();
      for (var surveyIndex in surveys) {
        if (!surveys[surveyIndex].Response) {
          this.setState(
            {
              surveys: surveys,
              currentSlideIndex: parseInt(surveyIndex),
            },
            this.applySlick,
          );
          return;
        }
      }
    }
    if (
      surveyQuestionAssessment &&
      JSON.stringify(surveyQuestionAssessment) !=
        JSON.stringify(this.props.surveyQuestionAssessment)
    ) {
      if (!this.state.isAppointmentLinked && this.state.screeningView) {
        this.toggleView();
      }
      window.scrollTo(0, 0);
      const activeKeys = surveyQuestionAssessment.SurveyCategory
        ? surveyQuestionAssessment.SurveyCategory.map((v, idx) => idx)
        : [];
      this.setState({
        surveyQuestionAssessment: surveyQuestionAssessment,
        activeKey: activeKeys,
        expandAll: false,
      });

      // this.setState({ activeKey: activeKeys });
    }
    // if (
    //   SurveyQuestionCategoryList &&
    //   SurveyQuestionCategoryList != this.props.SurveyQuestionCategoryList
    // ) {
    //   debugger;
    //   this.setState({ SurveyQuestionCategoryListData: SurveyQuestionCategoryList });
    // }
    if (SurveyListData.length > 0 && SurveyListData.length != this.props.SurveyListData.length) {
      this.setState({ surveyListData: SurveyListData });
    }

    if (isSurveyAssign && isSurveyAssign != this.props.isSurveyAssign) {
      // let getSurveyData = {
      //   json: JSON.stringify({
      //     Survey: [
      //       {
      //         ClientId: this.props.clientId,
      //         SurveyTypeDesc: this.props.description || 'Assessment Tools',
      //       },
      //     ],
      //   }),
      // };
      this.setState({
        assesmentId: '',
        currentPage: 1,
      });
      // this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
    }
    if (isSurveyCloned && isSurveyCloned != this.props.isSurveyCloned) {
      let getSurveyData = {
        json: JSON.stringify({
          Survey: [
            {
              ClientId: this.props.clientId,
              SurveyTypeDesc: this.props.description || 'Assessment Tools',
            },
          ],
        }),
      };
      this.setState(prevState => ({
        isSignedModal: true,
        header: 'Cloned Successfully.',
        assesmentId: '',
        currentPage: 1,
      }));
      // this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
    }
    if (isSurveyDeactivated && isSurveyDeactivated != this.props.isSurveyDeactivated) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: this.props.description || 'Assessment Tools',
              },
            ],
          }),
        }),
      );
    }
    if (isSurveyCompleted && isSurveyCompleted != this.props.isSurveyCompleted) {
      this.setState(prevState => ({
        isSignedModal: true,
        header: 'Document signed successfully',
      }));
    }
    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      if (this.state.isSignStatus) {
        if (validateStaffPinData.IsValid) {
          this.submitAssessment(validateStaffPinData.StaffPinHistoryId);
          // this.props.dispatch(
          //   markAsComplete({
          //     SurveyId: this.state.surveyQuestionAssessment.SurveyId,
          //     StaffPinHistoryId: validateStaffPinData.StaffPinHistoryId,
          //   }),
          // );
        }
      }
    }
  }

  setValue({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
  }) {
    let surveyQuestionAssessment = this.state.surveyQuestionAssessment;
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    if (level === 0) {
      surveyQuestionAssessment.SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 1) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 2) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveySubCategory[
        subCatLevel
      ].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    }
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    if (IsSignature) {
      this.setState({
        surveys: surveys,
        Signature: IsSignature && Signature,
        surveyQuestionId: surveyQuestionId,
        SignatureCanvas: IsSignature && {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [surveyQuestionId]: {
              SurveyQuestionId: surveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveys: surveys,
      });
    }

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index, isResetAnswer }) {
    let surveyQuestionAssessment = this.state.surveyQuestionAssessment;
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      Answer: response,
    };
    if (level === 0) {
      surveyQuestionAssessment.SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 1) {
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    } else if (level === 2) {
      // let newState = Object.assign({}, this.state);
      surveyQuestionAssessment.SurveyCategory[subLevel].SurveySubCategory[
        subCatLevel
      ].SurveyQuestion[index].Response = response;
      this.setState({ surveyQuestionAssessment });
    }
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    $('.main-banner').slick('slickNext');

    this.setState({
      surveys: surveys,
    });
    if (isResetAnswer) return;
    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }
  saveSignature = (
    signOne,
    data,
    SurveyQuestionId,
    index,
    Response,
    isSignatureSaved,
    isClear,
    isResponceData,
  ) => {
    let me = this;
    if (isClear) {
      let signaturData = me.state.SignatureCanvas;
      let signaturDataList = me.state.SignatureCanvas && me.state.SignatureCanvas.signatureDataList;
      delete signaturData[signOne];
      delete signaturDataList[signOne];
      signaturData.signatureDataList = signaturDataList;
      this.setState({
        SignatureCanvas: signaturData,
      });
      return;
    }
    if (isResponceData) {
      this.setState({
        SignatureCanvas: {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [SurveyQuestionId]: {
              SurveyQuestionId: SurveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveyQuestionId: SurveyQuestionId,
        SignatureCanvas: {
          ...this.state.SignatureCanvas,
          [signOne]: data,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [SurveyQuestionId]: {
              SurveyQuestionId: SurveyQuestionId,
              isSignatureSaved: isSignatureSaved,
              Response: Response,
              IsUpdateSignature: Response ? true : false,
              MediaGuid: Response ? Response : null,
            },
          },
        },
      });
    }
  };
  markAsCompleteAssessment = (SurveyId, StaffPinHistoryId) => {
    if (this.state.signStatusId != 2) {
      this.props.dispatch(
        markAsComplete({
          SurveyId: SurveyId,
          StaffPinHistoryId: StaffPinHistoryId,
        }),
      );
    } else {
      this.toggleView(true);
    }
  };
  submitAssessment = StaffPinHistoryId => {
    const { SurveyId, SignatureCanvas, surveyQuestionId, surveyQuestionAssessment } = this.state;
    let listData =
      SignatureCanvas &&
      SignatureCanvas.signatureDataList &&
      Object.values(SignatureCanvas.signatureDataList);
    let signatureSaveList = listData && listData.filter(item => !item.isSignatureSaved);
    let saveList = listData && listData.filter(item => item.isSignatureSaved);
    signatureSaveList &&
      signatureSaveList.map((item, index) => {
        let jsonData = {
          StudentId: this.getStudentId(),
          SurveyId: this.getSurveyId(),
          SurveyQuestionId: item.SurveyQuestionId,
          Answer: item.Response,
          IsSignature: true,
          Signature: {
            Base64String: SignatureCanvas[item.SurveyQuestionId].replace(
              'data:image/png;base64,',
              '',
            ),
            IsUpdateSignature: item.Response ? true : false,
            MediaGuid: item.MediaGuid,
          },
        };
        Axios.post('Survey/SurveyAnswerUpsert', jsonData)
          .then(response => {
            saveList.push({
              [surveyQuestionId]: {
                SurveyQuestionId: item.SurveyQuestionId,
                isSignatureSaved: true,
              },
            });
          })
          .then(response => {
            if ((listData && listData.length) == (saveList && saveList.length)) {
              this.markAsCompleteAssessment(SurveyId, StaffPinHistoryId);
            }
          });
      });
    if ((listData && listData.length) == (saveList && saveList.length)) {
      this.markAsCompleteAssessment(SurveyId, StaffPinHistoryId);
    }
  };
  getSurveyId() {
    const { SurveyId } = this.state;

    return SurveyId;
  }

  getStudentId = () => {
    // var param = new URLSearchParams(this.props.history.location.search);
    // return param.get('studentId');
    return this.props.clientId;
  };

  onClose() {
    this.props.history.push('/studentProfile/' + this.getStudentId());
  }

  captureExpandedkeys = key => {
    this.setState({ activeKey: key, expandAll: key.length == 0 });
  };

  expandAll = () => {
    this.setState(prevState => {
      let activeKeys = [];
      if (prevState.expandAll) {
        activeKeys = this.state.surveyQuestionAssessment.SurveyCategory
          ? this.state.surveyQuestionAssessment.SurveyCategory.map((v, idx) => idx)
          : [];
      }
      return {
        expandAll: !prevState.expandAll,
        activeKey: activeKeys,
      };
    });
  };

  unsignFormFun = (FormType, FormId) => {
    let data = {
      Json: JSON.stringify({
        UnSign: [
          {
            FormType,
            FormId,
          },
        ],
      }),
    };

    this.props.dispatch(unsignForm(data));
  };

  render() {
    const {
      surveys,
      currentSlideIndex,
      surveyListData,
      screeningView,
      surveyQuestionAssessment,
    } = this.state;
    const {
      lastSlideIndex,
      SurveyQuestionCategoryList,
      diagnosisData,
      changeActiveTab,
      isFetchingSurveyQuestionAssessment,
      isSigned,
      signInfo,
      counsolerSign,
      isDeActivated,
      isReadOnlyUserSession,
    } = this.props;
    const tempColumns = [
      {
        title: 'Name',
        dataIndex: 'SurveyName',
        key: 'SurveyName',
        sorter: (a, b) => isComparer(a.SurveyName, b.SurveyName),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },

      {
        title: 'Assigned Date',
        dataIndex: 'AssignedDate',
        key: 'AssignedDate',
        sorter: (a, b) =>
          isDateComparer(moment(a.AssignedDateForSort), moment(b.AssignedDateForSort)),

        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },

      {
        title: 'Signed Date',
        dataIndex: 'SignedDate',
        key: 'SignedDate',
        sorter: (a, b) => isDateComparer(moment(a.SignedDateForSort), moment(b.SignedDateForSort)),
        // sorter: (a, b) => isComparer(a.CenterId, b.CenterId),
        // sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Signed By',
        dataIndex: 'SignedBy',
        key: 'SignedBy',
        sorter: (a, b) => isComparer(a.SignedBy, b.SignedBy),
      },
      {
        title: 'Discarded Date',
        dataIndex: 'DiscardedDate',
        key: 'DiscardedDate',
        sorter: (a, b) =>
          isDateComparer(moment(a.DiscardedDateForSort), moment(b.DiscardedDateForSort)),
        // sorter: (a, b) => isComparer(a.CenterId, b.CenterId),
        // sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: 'Status',
        dataIndex: 'SignatureStatus',
        key: 'SignatureStatus',
        sorter: (a, b) => isComparer(a.SignatureStatus, b.SignatureStatus),
      },
      {
        title: 'Action',
        render: (text, record, index) => (
          <div class="d-flex align-items-center">
            {record.SurveyStatus === 0 && (
              <>
                {!this.props.isReadOnlyUser && (
                  <Button
                    type="link"
                    style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                    loading={this.state.index === index && isFetchingSurveyQuestionAssessment}
                    onClick={event => {
                      this.props.dispatch(
                        listSurveyQuestionAssessment({
                          json: JSON.stringify({
                            Survey: [
                              {
                                SurveyId: record.SurveyId,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        SurveyId: record.SurveyId,
                        isReadOnly: false,
                        index: index,
                        signStatusText: record.SignStatusText,
                        signStatusId: record.SignStatusId,
                      });
                    }}
                  >
                    {this.state.index === index && isFetchingSurveyQuestionAssessment
                      ? 'Loading'
                      : record.IsStart
                      ? 'Resume'
                      : 'Start'}
                  </Button>
                )}
                {!this.props.isReadOnlyUser && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <Tooltip
                        placement="top"
                        title="Archive"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#delete-Screen-Tool"
                      >
                        <img src="images/archive.svg" />
                      </Tooltip>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to archive this assessment?
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            No
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                deactivateClientScreening({
                                  json: JSON.stringify({
                                    Survey: [
                                      {
                                        SurveyId: record.SurveyId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </>
            )}
            {record.SurveyStatus === 1 && (
              <div
                className=""
                style={{
                  border: '0px solid red',
                  minWidth: '110px',
                  // paddingLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <Button
                  type="link"
                  style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                  loading={this.state.index === index && isFetchingSurveyQuestionAssessment}
                  onClick={event => {
                    this.props.dispatch(
                      listSurveyQuestionAssessment({
                        json: JSON.stringify({
                          Survey: [
                            {
                              SurveyId: record.SurveyId,
                            },
                          ],
                        }),
                      }),
                    );
                    this.setState({
                      SurveyId: record.SurveyId,
                      isReadOnly: true,
                      index: index,
                      // signStatusText: record.SignStatusText,
                    });
                  }}
                >
                  {this.state.index === index && isFetchingSurveyQuestionAssessment
                    ? 'Loading'
                    : 'View'}
                </Button>
              </div>
            )}
            {record.SurveyStatus === 1 && record.IsCloneable && (
              <div
                className=""
                style={{
                  border: '0px solid red',
                  minWidth: '110px',
                  // paddingLeft: '15px',
                  textAlign: 'left',
                }}
              >
                {!this.props.isReadOnlyUser && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <Tooltip
                        placement="top"
                        title="Clone this"
                        onClick={onClick}
                        data-toggle="modal"
                        data-target="#clone-asam-tool"
                      >
                        <Button
                          type="link"
                          style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                        >
                          Clone this
                        </Button>
                      </Tooltip>
                    )}
                    ModalContent={({ onOk, onCancel }) => (
                      <div class="modal-content border-0">
                        <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                        <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                          <h4 class="modal-title col-12 mb-3">
                            Are you sure you want to clone this?
                            <br />
                            {/* <small>
                              Cloning {record.SurveyName} will generate a new instance listed at the
                              top as "{record.SurveyName} Review".
                            </small> */}
                            <small>
                              Cloning will generate a new instance listed at the top.
                            </small>
                          </h4>
                        </div>
                        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            class="btn btn-outline-primary black-btn"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            class="btn"
                            onClick={() => {
                              onOk();
                              this.props.dispatch(
                                cloneForm({
                                  json: JSON.stringify({
                                    Survey: [
                                      {
                                        SurveyId: record.SurveyId,
                                      },
                                    ],
                                  }),
                                }),
                              );
                              this.setState({
                                SurveyId: record.SurveyId,
                                isReadOnly: true,
                                index: index,
                              });
                            }}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
            )}
            {record.SurveyStatus === -1 && null}
          </div>
        ),
      },
    ];
    return (
      <div className="main survery-question-page  mb-5 rcm-tab">
        {/* <Loader2 loading={this.props.isFetching} /> */}

        {this.props.isFetchingMarkAsComplete && (
          <div style={{ position: 'relative', height: '500px' }}>
            <Loader2 loading={this.props.isFetchingMarkAsComplete} />
          </div>
        )}
        {!this.props.isFetchingMarkAsComplete && (
          <div>
            {' '}
            {screeningView ? (
              <>
                <div className="container-fluid">
                  <div className="row">
                    {/* <h3 class="page-title pt-5 mb-3 mb-md-5 ">Screening Tool</h3> */}
                    <div className="col-12 enrollment-form mt-5">
                      {!isDeActivated && (
                        <div className="row px-0">
                          <div className="col-5 px-0">
                            <div className="form-group">
                              <ReduxSelect
                                options={SurveyQuestionCategoryList}
                                onChange={value => {
                                  this.setState({ assesmentId: value });
                                  // this.props.dispatch(getSurveyQuestionMultiple(parseInt(value), 20000019));
                                }}
                                className="basic-single"
                                classNamePrefix="select"
                                // value={defaultCenter}
                                value={this.state.assesmentId}
                                placeholder="Select"
                                disabled={this.props.isReadOnlyUser}
                              />
                            </div>
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <button
                              class={
                                this.state.assesmentId
                                  ? 'rcm-sub-tab-btn'
                                  : ' rcm-sub-tab-btn disable-cursor'
                              }
                              onClick={this.onAssignSurvey}
                              disabled={!this.state.assesmentId || this.props.isReadOnlyUser}
                            >
                              Assign
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <CustomModal
                      isOpen={this.state.isAssignAlreadyModal || this.state.isAssignNew}
                      // header={this.state.header}
                      header={
                        this.state.isAssignAlreadyModal
                          ? 'This form is already assigned'
                          : 'The form has been assigned successfully'
                      }
                      yesAction={() => {
                        this.setState({
                          isAssignAlreadyModal: false,
                          isAssignNew: false,
                        });
                        let getSurveyData = {
                          json: JSON.stringify({
                            Survey: [
                              {
                                ClientId: this.props.clientId,
                                SurveyTypeDesc: this.props.description || 'Assessment Tools',
                              },
                            ],
                          }),
                        };
                        this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
                      }}
                      yesText="OK"
                      hideCancel={true}
                      changeMaxWidth="100%"
                    />
                    <div className="col-12 enrollment-form mt-1">
                      <div className="row card-body px-2 rcm-subtab-table-block">
                        <Loader2 loading={this.props.isFetching} />
                        <div className="col-12 rcm-tools-table">
                          <PersistTableSort
                            name="surveyListData"
                            // bordered
                            loader={this.props.isFetchingSurveyQuestionAssessment}
                            columns={tempColumns}
                            dataSource={surveyListData}
                            size={'middle'}
                            pagination={{
                              itemRender: (page, type, originalElement) => {
                                return (
                                  <div className="dataTables_wrapper no-footer">
                                    <div className="dataTables_paginate paging_simple_numbers">
                                      {type == 'prev' ? (
                                        <a className="paginate_button previous">Previous</a>
                                      ) : type == 'next' ? (
                                        <a className="paginate_button next">Next</a>
                                      ) : (
                                        <a className="paginate_button ">{page}</a>
                                      )}
                                    </div>
                                  </div>
                                );
                              },
                              current: this.state.currentPage,
                              onChange: page => {
                                this.setState({
                                  currentPage: page,
                                });
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <DocumentForm
                    DocTypeValue={this.props.DocTypeValue || '16006'}
                    clientId={this.props.clientId}
                    isReadOnlyUser={this.props.isReadOnlyUser}
                    isDeActivated={isDeActivated}
                  />
                </div>
              </>
            ) : (
              <div className="container-fluid">
                <div className="row ">
                  <div className="col-12">
                    <Loader2 loading={this.props.isFetching} />
                    <div
                      className="survery-question-box "
                      style={{ maxWidth: 'unset', marginTop: '0px' }}
                    >
                      {/* <h3 className="mb-3 mb-md-5 ">{surveys.length > 0 && surveys[0].SurveyCategory}</h3> */}
                      <div className="screening-tool">
                        <div className="assessment-main-title d-flex align-items-center justify-content-between">
                          <div
                            onClick={() => {
                              this.toggleView(true);
                              this.props.dispatch(resetListSurveyQuestionAssessment({}));
                              // this.expandAll();
                            }}
                            style={{ cursor: 'pointer' }}
                            className="text-left d-flex"
                          >
                            <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                          </div>
                          <span className="main-title-text">
                            {surveyQuestionAssessment.SurveyMainTitle}
                          </span>
                          <div>
                            {!this.props.isReadOnlyUser && (
                              <Link
                                class="mx-2"
                                to={{
                                  pathname: '/printAssessment',
                                  search: 'SurveyId=' + this.state.SurveyId,
                                }}
                                target="_blank"
                              >
                                Print
                              </Link>
                            )}
                          </div>
                        </div>

                        <div className="" style={{ height: '100vh', overflowY: 'scroll' }}>
                          {surveyQuestionAssessment && surveyQuestionAssessment.SurveyQuestion && (
                            <div>
                              <QuestionType
                                SurveyQuestion={surveyQuestionAssessment.SurveyQuestion}
                                SurveyId={surveyQuestionAssessment.SurveyId}
                                onNext={this.onNext}
                                setValue={this.setValue}
                                scope={this}
                                level={0}
                                isReadOnly={this.state.isReadOnly || this.props.isReadOnlyUser}
                                saveSignature={this.saveSignature}
                              />
                            </div>
                          )}
                          {surveyQuestionAssessment && surveyQuestionAssessment.SurveyCategory && (
                            <div className="d-flex justify-content-end">
                              <span
                                onClick={this.expandAll}
                                className="btn mt-2 mb-2"
                                style={{ width: '150px', marginRight: '22px', cursor: 'pointer' }}
                              >
                                {this.state.expandAll ? `+ Expand All` : `- Collapse All`}
                              </span>
                            </div>
                          )}

                          {surveyQuestionAssessment &&
                            surveyQuestionAssessment.SurveyCategory &&
                            surveyQuestionAssessment.SurveyCategory.map((surveyCategory, index) => {
                              return (
                                <Collapse
                                  defaultActiveKey={`${index}`}
                                  key={`${index}`}
                                  activeKey={this.state.activeKey}
                                  onChange={index => this.captureExpandedkeys(index)}
                                >
                                  <Panel
                                    header={
                                      <span
                                        style={{
                                          fontWeight: '800',
                                        }}
                                      >
                                        {surveyCategory.SurveyCategory}
                                      </span>
                                    }
                                    // header={`DIMENSION${index + 1} : ${Description}`}
                                    key={`${index}`}
                                    // className="mt-2 mb-2"
                                    className="page-break dimension-panel-border"
                                  >
                                    <div>
                                      <div
                                        className="assessment-sub-title"
                                        style={{
                                          margin: '0px 5px',
                                        }}
                                      >
                                        <span className="sub-title-text">
                                          {surveyCategory.SurveyCategory}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor: 'white',
                                          padding: '5px',
                                          margin: '0px 5px',
                                          borderRadius: '4px',
                                        }}
                                        key={index}
                                      >
                                        <QuestionType
                                          SurveyQuestion={surveyCategory.SurveyQuestion}
                                          SurveyId={surveyQuestionAssessment.SurveyId}
                                          onNext={this.onNext}
                                          setValue={this.setValue}
                                          scope={this}
                                          level={1}
                                          subLevel={index}
                                          isReadOnly={
                                            this.state.isReadOnly || this.props.isReadOnlyUser
                                          }
                                          saveSignature={this.saveSignature}
                                        />

                                        {surveyCategory &&
                                          surveyCategory.SurveySubCategory &&
                                          surveyCategory.SurveySubCategory.map(
                                            (subCategory, subIndex) => {
                                              return (
                                                <Collapse
                                                  defaultActiveKey={`${subIndex}`}
                                                  key={`${subIndex}`}
                                                >
                                                  <Panel
                                                    header={
                                                      <span
                                                        style={{
                                                          fontWeight: '800',
                                                        }}
                                                      >
                                                        {subCategory.SurveySubCategory}
                                                      </span>
                                                    }
                                                    // header={`DIMENSION${index + 1} : ${Description}`}
                                                    key={`${subIndex}`}
                                                    // className="mt-2 mb-2"
                                                    className="page-break problem-panel-border "
                                                  >
                                                    <div
                                                      style={{
                                                        backgroundColor: '#FFF',
                                                        padding: '15px',
                                                      }}
                                                    >
                                                      <div className="assessment-sub-title">
                                                        <span className="sub-title-text">
                                                          {subCategory.SurveySubCategory}
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          backgroundColor: '#FFF',
                                                          padding: '15px',
                                                          // margin: '0px 25px',
                                                        }}
                                                        key={index}
                                                      >
                                                        <QuestionType
                                                          SurveyQuestion={
                                                            subCategory.SurveyQuestion
                                                          }
                                                          SurveyId={
                                                            surveyQuestionAssessment.SurveyId
                                                          }
                                                          onNext={this.onNext}
                                                          setValue={this.setValue}
                                                          scope={this}
                                                          level={2}
                                                          subLevel={index}
                                                          subCatLevel={subIndex}
                                                          isReadOnly={
                                                            this.state.isReadOnly ||
                                                            this.props.isReadOnlyUser
                                                          }
                                                          saveSignature={this.saveSignature}
                                                        />
                                                      </div>
                                                    </div>
                                                  </Panel>
                                                </Collapse>
                                              );
                                            },
                                          )}
                                      </div>
                                    </div>
                                  </Panel>
                                </Collapse>
                              );
                            })}
                        </div>
                        {diagnosisData ? (
                          <div class="col-12 mt-5">
                            <h4>DIAGNOSIS</h4>
                            <div className="p-2">
                              <div class="row students-detail text-md-left justify-content-between">
                                <div className="col-12">
                                  <div
                                    className="row justify-content-between py-3 my-2"
                                    style={{ backgroundColor: '#f2f2f2', borderRadius: '5px' }}
                                  >
                                    <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                      <span class="info-label">Date of Diagnosis</span>
                                      <h6
                                        // title={diagnosisData.DiagnosisDate}
                                        class="info-detail m-md-0"
                                      >
                                        {diagnosisData.DiagnosisDateTime}
                                      </h6>
                                    </div>
                                    {/* <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                    <span class="info-label">Time of Diagnosis</span>
                                    <h6
                                      title={diagnosisData.DiagnosisTime}
                                      class="info-detail m-md-0"
                                    >
                                      {diagnosisData.DiagnosisTime}
                                    </h6>
                                  </div> */}
                                    <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                      <span class="info-label">Program</span>
                                      <h6
                                        title={diagnosisData.ProgramDescription}
                                        class="info-detail m-md-0"
                                      >
                                        {diagnosisData.ProgramDescription}
                                      </h6>
                                    </div>
                                    <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                      <span class="info-label">Diagnosis Practitioner</span>
                                      <h6
                                        title={diagnosisData.PractitionerName}
                                        class="info-detail m-md-0"
                                      >
                                        {diagnosisData.PractitionerName}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                {diagnosisData.DiagnosisList && (
                                  <div className="col-12">
                                    <h5 className="text-left">Diagnosis List</h5>
                                    {diagnosisData.DiagnosisList.map((diagnosis, index) => {
                                      return (
                                        <div className="row border-bottom mb-2" key={index}>
                                          <div class="col-2 mb-2">
                                            <span class="info-label">Diagnosis Code</span>
                                            <h6
                                              title={diagnosis.DiagnosisDictDesc}
                                              class="info-detail m-md-0"
                                            >
                                              {diagnosis.DiagnosisDictDesc}
                                            </h6>
                                          </div>
                                          <div class="col-8 mb-2">
                                            <span class="info-label">Diagnosis Description</span>
                                            <h6
                                              title={diagnosis.DiagnosisDescription}
                                              class="info-detail m-md-0"
                                              // style={{
                                              //   textOverflow: 'ellipsis',
                                              //   whiteSpace: 'nowrap',
                                              //   overflow: 'hidden',
                                              //   width: '280px',
                                              // }}
                                            >
                                              {diagnosis.DiagnosisDescription}
                                            </h6>
                                          </div>
                                          <div class="col-2 mb-2">
                                            <span class="info-label">Status</span>
                                            <h6 class="info-detail m-md-0">
                                              {diagnosis.StatusDesc}
                                            </h6>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}

                                {/* <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Diagnosis Category</span>
                                <h6 class="info-detail m-md-0">Category One</h6>
                              </div>
                              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Status</span>
                                <h6 class="info-detail m-md-0">Diagnosis Status</h6>
                              </div>
                              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Diagnosis Ranking</span>
                                <h6 class="info-detail m-md-0">Diagnosis Ranking</h6>
                              </div>
                              <div class="col-6 col-sm-auto mb-2 md-sm-0">
                                <span class="info-label">Bill Order</span>
                                <h6 class="info-detail m-md-0">Diagnosis Bill Order</h6>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="col-12 col-md-6 col-lg-4 text-left mt-3 d-inline-block">
                            {!this.props.isReadOnlyUser && (
                              <input
                                type="button"
                                class="btn btn-outline-primary"
                                value="Click here to add new diagnosis"
                                // style={{ marginLeft: '65px' }}
                                onClick={() => {
                                  // changeActiveTab(10);
                                  this.props.history.push(
                                    `/cw/patient-management/patient-profile?tab=medical-analysis&pId=${this.getClientId()}`,
                                  );
                                }}
                              />
                            )}
                          </div>
                        )}
                        <div
                          class="col-12 col-md-6 col-lg-6 text-right mt-3 d-inline-flex flex-wrap align-items-center justify-content-between"
                          style={{ marginLeft: '100px', marginTop: '46px' }}
                        >
                          <input
                            type="button"
                            class=" btn btn-outline-primary mr-2"
                            value={this.state.isReadOnly ? 'Back to List' : 'Save and Back to List'}
                            onClick={() => {
                              this.toggleView(true);
                            }}
                          />
                          {!this.state.isReadOnly && this.state.signStatusText && (
                            <input
                              type="button"
                              class=" btn btn-outline-primary"
                              value={this.state.signStatusText}
                              onClick={() => {
                                // this.toggleView(true);

                                let data = {
                                  Json: JSON.stringify({
                                    Staff: [
                                      {
                                        FormType: 'Survey',
                                        FormId: surveyQuestionAssessment.SurveyId,
                                      },
                                    ],
                                  }),
                                };
                                let me = this;
                                Axios.post('Medicaid/PreValidatePin', data).then(response => {
                                  let isValid = JSON.parse(response.data.data)[0].Staff[0].IsValid;
                                  let failMsg = JSON.parse(response.data.data)[0].Staff[0]
                                    .FailMessage;
                                  let IsRealSignature = JSON.parse(response.data.data)[0].Staff[0]
                                    .IsRealSignature;
                                  if (isValid) {
                                    this.setState({
                                      validPinModalVisible: true,
                                      isSignStatus: true,
                                      IsRealSignature: IsRealSignature,
                                      // assessmentApiObj: surveyQuestionAssessment.SurveyId,
                                    });
                                  } else {
                                    me.props.toggle(failMsg);
                                    me.setState({
                                      isSignStatus: false,
                                    });
                                  }
                                });

                                // this.props.dispatch(
                                //   markAsComplete({
                                //     SurveyId: surveyQuestionAssessment.SurveyId,
                                //   }),
                                // );
                              }}
                            />
                          )}

                          <AntdModal
                            ModalButton={({ onClick }) => (
                              <Tooltip placement="top" title="Un-sign">
                                <div class="delete-course" onClick={onClick}>
                                  {!this.props.isReadOnlyUser && this.props.IsEligibleForUnSign && (
                                    <p
                                      className="mt-2 mb-2 mr-2 danger-custom-btn"
                                      style={{
                                        position: 'relative',
                                        top: '1px',
                                        width: 'fit-content',
                                      }}
                                    >
                                      Un-sign
                                    </p>
                                  )}
                                </div>
                              </Tooltip>
                            )}
                            ModalContent={({ onOk, onCancel }) => (
                              <div class="modal-content border-0">
                                <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    &times;
                                  </button>
                                  <h4 class="modal-title col-12">
                                    Are you sure you want to Un-sign?
                                  </h4>
                                </div>

                                <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                  <button
                                    type="button"
                                    class="btn black-btn"
                                    data-dismiss="modal"
                                    onClick={onCancel}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    class="btn "
                                    onClick={() => {
                                      onOk();
                                      this.unsignFormFun(
                                        'Survey',
                                        surveyQuestionAssessment.SurveyId,
                                      );
                                      // this.cancelGroupCall(Data.VMCallSessionId);
                                    }}
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        </div>

                        {(counsolerSign || isSigned) && (
                          <div className="col-12">
                            <h5 style={{ textAlign: 'left', fontSize: '17px', fontWeight: '700' }}>
                              Signed By:-
                            </h5>
                            {counsolerSign && (
                              <h5
                                style={{ textAlign: 'left', fontSize: '17px', fontWeight: '700' }}
                              >
                                {counsolerSign}
                              </h5>
                            )}
                            {signInfo && signInfo.SignedBy && (
                              <h5
                                style={{ textAlign: 'left', fontSize: '17px', fontWeight: '700' }}
                              >
                                {signInfo.SignedBy}
                              </h5>
                            )}
                          </div>
                        )}

                        {/* {counsolerSign && (
                          <div className="col-12">
                            <h5 style={{ textAlign: 'left', fontSize: '17px', fontWeight: '700' }}>
                              {' '}
                              Signed By:-{counsolerSign}
                            </h5>
                          </div>
                        )}

                        {isSigned && (
                          <div className="col-12">
                            {signInfo.SignedBy && (
                              <h5
                                style={{ textAlign: 'left', fontSize: '17px', fontWeight: '700' }}
                              >
                                {' '}
                                Signed By:-{signInfo.SignedBy}
                              </h5>
                            )}
                          </div>
                        )} */}
                      </div>
                      <div className="mt-4">
                        <LinkAppointment
                          clientId={this.props.clientId}
                          formType="ASAM"
                          componentDataUniqueId={this.state.SurveyId}
                          linkData={{ IsSigned: this.props.isSigned }}
                          isReadOnlyUserSession={isReadOnlyUserSession}
                          getLatest={this.getUpdatedSurvey}
                          // isFetching={this.props.isFetching}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <ValidatePin
          validPinModalVisible={this.state.validPinModalVisible}
          resetValidPinModalVisible={this.resetValidPinModalVisible}
          FormType={'Survey'}
          FormId={surveyQuestionAssessment.SurveyId}
          IsRealSignature={this.state.IsRealSignature}
        />
        <CustomModal
          isCustomClass={true}
          isOpen={this.state.isSignedModal}
          header={this.state.header}
          // header={'Your Changes have been saved successfully'}
          yesAction={() => {
            this.toggleView2(true);
          }}
          yesText="OK"
          hideCancel={true}
          changeMaxWidth="100%"
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  survery_question_get_multiple,
  list_survey_question_category_assessment,
  survey_assign,
  list_survey_question_assessment,
  deactivate_client_screening,
  get_patient_detail,
  mark_as_complete,
  validate_staff_pin,
  unsign_form,
  list_survey_question_category_get_all,
  clone_form,
}) => {
  const { data, isFetching, error } = survery_question_get_multiple;
  const { isFetching: isFetchingMarkAsComplete } = mark_as_complete;
  const { data: unsignForm } = unsign_form;
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: surveyAssignData } = survey_assign;
  const {
    data: listSurveyQuestionCategoryAssessmentData,
    isFetching: isFetchingListSurvey,
  } = list_survey_question_category_get_all;
  const { data: clientInfo } = get_patient_detail;
  var { data: validateDataPin } = validate_staff_pin;
  let validateStaffPinData = {};
  var lastSlideIndex = 1;
  let SurveyQuestionCategoryAssessmentList;
  let SurveyListData = [];
  let surveyData = [];
  let surveyQuestionAssessment = {};
  let isSurveyAssign = false;
  let isSurveyDeactivated = false;
  let diagnosisData = null;
  let isSurveyCompleted = false;
  var IsEligibleForUnSign;
  var unsignFormStatus = false;
  let isSigned = false;
  let isSurveyCloned = false;
  let signInfo = {};
  let counsolerSign = null;

  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (unsignForm && unsignForm.success) {
    unsignFormStatus = unsignForm.success;
  }

  if (data) {
    let array = [];
    for (let index in data) {
      array.push(data[index]);
    }
    //array = array.slice(0, 10);

    array.push({});
    surveyData = [...array];
    lastSlideIndex = data.length - 2;
  }
  if (surveyQuestionAssessmentData) {
    IsEligibleForUnSign = surveyQuestionAssessmentData.IsEligibleForUnSign;
    isSigned = surveyQuestionAssessmentData.IsSigned;
    signInfo = surveyQuestionAssessmentData.SignInfo && surveyQuestionAssessmentData.SignInfo[0];
    counsolerSign = surveyQuestionAssessmentData.CounsolerSign;
    surveyQuestionAssessment = surveyQuestionAssessmentData;
  }
  if (
    listSurveyQuestionCategoryAssessmentData &&
    listSurveyQuestionCategoryAssessmentData.SurveyQuestionCategoryList
  ) {
    SurveyQuestionCategoryAssessmentList =
      listSurveyQuestionCategoryAssessmentData.SurveyQuestionCategoryList;
  }
  if (
    listSurveyQuestionCategoryAssessmentData &&
    listSurveyQuestionCategoryAssessmentData.SurveyList
  ) {
    SurveyListData = listSurveyQuestionCategoryAssessmentData.SurveyList;
  }
  if (surveyAssignData && surveyAssignData.success) {
    isSurveyAssign = surveyAssignData.success;
  }
  if (deactivate_client_screening.data && deactivate_client_screening.data.success) {
    isSurveyDeactivated = deactivate_client_screening.data.success;
  }
  if (mark_as_complete.data && mark_as_complete.data.success) {
    isSurveyCompleted = mark_as_complete.data.success;
  }
  if (clone_form.data && clone_form.data.success) {
    isSurveyCloned = clone_form.data.success;
  }
  if (clientInfo && clientInfo.Diagnosis) {
    diagnosisData = clientInfo.Diagnosis[0];
  }
  return {
    surveys: surveyData,
    surveyQuestionAssessment,
    isSurveyAssign,
    isSurveyDeactivated: isSurveyDeactivated,
    lastSlideIndex: lastSlideIndex,
    SurveyQuestionCategoryList: SurveyQuestionCategoryAssessmentList || [],
    SurveyListData: SurveyListData,
    // isFetchingListSurvey: isFetchingListSurvey || isFetching || false,
    isFetching: isFetching || isFetchingListSurvey,
    isFetchingSurveyQuestionAssessment: isFetchingSurveyQuestionAssessment,
    diagnosisData: diagnosisData,
    isSurveyCompleted,
    validateStaffPinData,
    isFetchingMarkAsComplete,
    IsEligibleForUnSign,
    unsignFormStatus,
    isSigned,
    signInfo,
    counsolerSign,
    isSurveyCloned,
  };
};

export default connect(mapStateToProps)(withRouter(Survey));
