import React from 'react';
import { Tabs, Modal } from 'antd';
import { connect } from 'react-redux';

import {
  addStudent,
  resetAddRequest,
  getStudent,
  resetGetStudent,
  addNewApplicant,
  getClientCounselor,
  getDynamicComos,
} from '../../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import UserAccount from './formSteps/userAccount';
// import ContactInformation from './formSteps/contactInformation';
import AccountInformation from './formSteps/accountInformation';
import Facility from './formSteps/facility';
import Insurance from './formSteps/insurance';
import DiagnosisCodes from './formSteps/diagnosisCodes';
import ProviderInformation from './formSteps/providerInformation';
import CustomModal from '../../../core/modal';
import Loader from '../../../Components/Loader';
import { submit, reset, destroy, Field, reduxForm } from 'redux-form';
import { getDate } from '../../../util/date';
import AntdModal from '../../../Components/CustomModal';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../../component/custom-redux-components';
// import ReduxNewApplicantForm from '../enrollmentForm/newApplicant';
import PageTitle from '../../../Components/PageTitle';

const { TabPane } = Tabs;

const formatDOB = val => {
  if (val) {
    var date = getDate(val);
    var dateString = '';
    var month = date.getMonth() + 1;
    if (month < 10) {
      dateString = '0' + month + '/';
    } else {
      dateString = month + '/';
    }
    var day = date.getDate();
    if (day < 10) {
      dateString += '0' + day + '/';
    } else {
      dateString += day + '/';
    }

    dateString += date.getFullYear();

    return dateString;
  }
  return val;
};

class SlidingTabs extends React.Component {
  constructor(props) {
    super(props);
    // props.reset();
    this.state = {
      isProcessed: false,
      tabLabels: [
        {
          Name: 'User Acount',
          Component: props => <UserAccount {...props} />,
        },
        // {
        //   Name: 'Facility',
        //   Component: props => <Facility {...props} />,
        // },
        // {
        //   Name: 'Provider Information',
        //   Component: props => <ProviderInformation {...props} />,
        // },
        // {
        //   Name: 'Insurance',
        //   Component: props => <Insurance {...props} />,
        // },
        // {
        //   Name: 'CPT/Diagnosis Codes',
        //   Component: props => <DiagnosisCodes {...props} />,
        // },
        // {
        //   Name: 'Account Information',
        //   Component: props => <AccountInformation {...props} />,
        // },
      ],

      // activeKey: this.getStudentId() === -1 ? 0 : null,
      activeKey: 0,
      // activeTabs: this.getStudentId() > 0 ? [true, true, true, true] : [false, false, false, false],
      activeTabs: [true, true, true, true, true, true],
      studentData: {
        counselorStaffId: props.defaultCounsellor,
        languageType: props.defaultLanguage,
        faithTypeId: props.defaultFaithType,
        homeAddressType: props.defaultHomeAddressType,
        supportContacts: [{ country: 'USA' }],
        medicalPast: [{}],
        legalIssues: [],
        substanceUseProfiles: [
          { addictionTypeId: props.defaultAddictionProfileTypeId, addictionProfileTypeId: 1 },
        ],
        previousTreatments: [{}],
        fundSources: [{}],
        priorAbuses: [{}],
        financials: [{ isEndDateEstimated: true }],
        healthInsurances: [{ country: 'USA' }],
        center: props.defaultCenter,
        country: 'USA',
        churchCountry: 'USA',
        enrollDate: props.defaultEnrollDate,
      },
      isModelOpen: false,
      isNewSaveModelOpen: false,
      isDeleteModelOpen: false,
      deleteModelMessage: null,
      yesCallBack: null,
      showError: false,
      modalVisible: false,
    };
  }

  onAddNewApplicant = values => {
    this.props.addNewApplicant({});
  };

  onSubmit(values) {
    if (this.state.isProcessed) {
      return;
    }
    //if (this.getDischarged()) return;
    this.state.isProcessed = true;
    const { activeTabs } = this.state;
    const {} = values;
    var studentId = null;

    if (this.getStudentId() > 0) {
      studentId = this.getStudentId();
    }

    // var dateItem = dateofBirth.split('/');
    // var dob = dateItem[2] + '-' + dateItem[0] + '-' + dateItem[1];
    let studentDemographic = {};

    var data = {
      Student: [{}],
    };
    // var studentInfo = {
    //   userId: 1,
    //   centerId: center,
    //   json: JSON.stringify(data),
    // };
    // if (this.getStudentId() > 0) {
    //   studentInfo.studentId = this.getStudentId();
    // }
    // this.props.addStudent(studentInfo);
  }

  handleNext = index => {
    const { activeTabs } = this.state;
    activeTabs[index] = true;
    activeTabs[index + 1] = true;
    this.setState({
      activeKey: `${index + 1}`,
      activeTabs,
    });
  };

  goToPreviuos = index => {
    /*window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });*/
    this.setState({ activeKey: index - 1 });
  };

  onTabClick = key => {
    const { activeTabs } = this.state;
    if (activeTabs[key]) {
      this.setState({ activeKey: key, activeTabs });
    }
    /*if (this.getDischarged()) {
      this.hideButton();
    } else {
    }*/
  };

  getDischarged = () => {
    if (this.props.isView) {
      return true;
    }
  };

  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var studentId = param.get('studentId') || param.get('studentid');
    if (studentId) {
      studentId = parseInt(studentId);
    } else {
      studentId = -1;
    }
    return studentId;
  }

  hideButton = () => {
    //for (let item of document.getElementsByClassName('btn')) item.disabled = true;
  };

  // componentDidMount(props) {
  //   let comboTypes = {
  //     ComboTypes: 'CenterServiceTypeCenter',
  //     Json: JSON.stringify({
  //       Combo: [
  //         {
  //           StudentId: this.getStudentId(),
  //           StudentCenterId: null,
  //         },
  //       ],
  //     }),
  //   };
  //   if (this.getStudentId() < 0) {
  //     this.props.getDynamicComos(comboTypes);
  //   }

  //   if (this.getStudentId() > 0) {
  //     this.props.getStudent(this.getStudentId());
  //   } else {
  //     /*const initData = {
  //       supportContacts: [{}],
  //     };
  //     this.props.initialize(initData);*/
  //   }
  //   /*if (this.getDischarged()) {
  //     this.hideButton();
  //   } else {
  //   }*/
  // }

  toggle = () => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
    }));
  };
  error = msg => {
    Modal.error({
      content: msg,
    });
  };
  deleteModelToggle = () => {
    this.state.yesCallBack();
    this.setState({ isDeleteModelOpen: !this.state.isDeleteModelOpen });
  };

  updateDeleteModel = (isDeleteModelOpen, deleteModelMessage, yesCallBack) => {
    this.setState({
      isDeleteModelOpen: isDeleteModelOpen,
      deleteModelMessage: deleteModelMessage,
      yesCallBack: yesCallBack,
    });
  };

  launchSurvey = () => {
    this.props.history.push('/surveyer');
  };

  render() {
    const { handleSubmit, isFetching, clientData = {} } = this.props;
    const { onSubmit } = this;
    const { modalMessage, showError, modalVisible } = this.state;

    const { activeKey, studentData, tabLabels, activeTabs } = this.state;
    return (
      <div>
        <PageTitle TitleText="Provider Management" />
        <div class="w-100 rcm-panel">
          <div class="main enrollment-page mb-5">
            <div class="container-fluid px-5">
              <div class="content-area">
                <div>
                  {/* <div class="row top-heading-row mt-2 mb-3 align-items-center"></div> */}
                  {showError && (
                    <div class="alert alert-danger alert-panel" role="alert">
                      Please fill the entire form
                    </div>
                  )}

                  <>
                    <div class="top-table-search">
                      {/* <div
                        className="mt-3 mb-3 cursor-pointer"
                        style={{ display: 'inline-block', marginRight: '20px' }}
                      >
                        <a class="back-link" onClick={() => this.props.history.goBack()}>
                          <img src="images/back-arrow.svg" /> Back
                        </a>
                      </div> */}
                      <div className="mt-3 cursor-pointer" style={{ display: 'inline-block' }}>
                        <a
                          class="back-link"
                          onClick={() => this.props.history.push(`/cw/provider/provider-list`)}
                        >
                          <img src="images/back-arrow.svg" /> Back to provider list
                        </a>
                      </div>
                    </div>
                    {clientData.patientId > 0 && (
                      <div
                        className="row top-heading-row my-3 align-items-center mr-0 p-2"
                        style={{
                          backgroundColor: '#ffffff',
                          borderRadius: '12px',
                          boxShadow: 'rgba(0, 0, 0, 0.03)',
                        }}
                      >
                        <div class="col-12 col-md-4 col-lg-3">
                          <h5 class="font-weight-semibold mb-3 mb-md-0 students-profile">
                            <div className="d-flex align-items-center">
                              {/* <UploadProfile
                                imgSrc={clientData.banner.ProfileImgAttachmentUrl}
                                captureProfile={this.capture}
                              /> */}
                              <div>
                                <span className="d-block">
                                  {/* {this.state.callModelVisible && (
                                    <Twilio
                                      isOpen={this.state.callModelVisible}
                                      // twilioToken={this.state.twilioToken}
                                      userData={this.state.clickedRecord}
                                      resetIsOpen={this.resetIsOpen}
                                    />
                                  )} */}
                                  {clientData.firstName} {clientData.lastName}&nbsp;
                                  <div
                                    class="tools-grid"
                                    onClick={event => {
                                      if (clientData.banner.IsVoiceCallAllowed) {
                                        this.setState({
                                          callModelVisible: true,
                                          clickedRecord: {
                                            Phone: clientData.banner.PrimaryPhone,
                                            Name: clientData.firstName,
                                            ProfileImageUrl:
                                              clientData.banner.ProfileImgAttachmentUrl,
                                          },
                                        });
                                        // Axios.post('CallToken/Generate', {}).then(response => {
                                        //   if (response && response.data.token) {
                                        //     this.setState({
                                        //       twilioToken: response.data.token,
                                        //       callModelVisible: true,
                                        //       clickedRecord: record,
                                        //     });
                                        //   }
                                        // });
                                        // this.props.dispatch(generateCallToken({}));
                                        event.stopPropagation();
                                      } else {
                                        alert('VOIP call not allowed');
                                        event.stopPropagation();
                                      }
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      display: 'inline-block',
                                    }}
                                  >
                                    <p
                                      className="d-flex justify-content-around"
                                      style={
                                        clientData.banner.IsVoiceCallAllowed
                                          ? { color: '#46B4FE' }
                                          : { color: 'black' }
                                      }
                                    >
                                      {' '}
                                      {clientData.banner.IsVoiceCallAllowed ? (
                                        <img
                                          style={{ cursor: 'pointer', display: 'block' }}
                                          src={`images/twilioPhoneAllowed.svg`}
                                          alt="Calling Allowed"
                                          width="10px"
                                        />
                                      ) : null}
                                      &nbsp;
                                      {/* {clientData.banner.PrimaryPhone} */}
                                    </p>
                                  </div>
                                </span>
                                <span className="d-block">{`${clientData.patientId}`}</span>
                              </div>
                            </div>
                          </h5>
                        </div>
                        <div class="col-12 col-md-8 col-lg-9">
                          <div class="row students-detail  text-md-left  justify-content-between">
                            <div class="mb-2">
                              <span class="info-label">Provider Name</span>
                              <h6
                                title={clientData.banner.PractitionerName}
                                class="info-detail m-md-0"
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  width: '180px',
                                }}
                              >
                                {clientData.banner.PractitionerName}
                              </h6>
                            </div>
                            <div class="mb-2">
                              <span class="info-label">Admission Date</span>
                              <h6
                                title={clientData.banner.AdmissionDate}
                                class="info-detail m-md-0"
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  width: '150px',
                                }}
                              >
                                {clientData.banner.AdmissionDate}
                              </h6>
                            </div>
                            <div class="mb-2">
                              <span class="info-label">Service Type</span>
                              <h6
                                title={clientData.banner.ServiceType}
                                class="info-detail m-md-0"
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  width: '180px',
                                }}
                              >
                                {clientData.banner.ServiceType}
                              </h6>
                            </div>
                            <div class="mb-2 mr-2">
                              <span class="info-label">Age</span>
                              <h6 class="info-detail m-md-0">
                                {clientData.banner.Age}
                                {clientData.banner.Age && <span> Years</span>}{' '}
                              </h6>
                            </div>
                            <div class="mb-2 mr-2">
                              <span class="info-label">DOB</span>
                              <h6 class="info-detail m-md-0">{clientData.banner.DateOfBirth}</h6>
                            </div>
                            <div class="mb-2 mr-2">
                              <span class="info-label">Gender</span>
                              <h6 class="info-detail m-md-0">{clientData.banner.Gender}</h6>
                            </div>
                            <div class="mb-2 mr-2">
                              <span class="info-label">Allergies</span>
                              <h6
                                title={clientData.banner.AllergiesText}
                                class="info-detail m-md-0"
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  width: '180px',
                                }}
                              >
                                {clientData.banner.AllergiesText}
                              </h6>
                            </div>
                            {/* {clientData.banner.ProgramLevel && (
                              <div class="mb-2 mr-2">
                                <span class="info-label">Program Level</span>
                                <h6 class="info-detail m-md-0">{clientData.banner.ProgramLevel}</h6>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {!this.getClientId() && <h5>Add New Patient</h5>} */}
                    {<h5>Add New Provider</h5>}
                    <div class="row">
                      <div class="col-12 mb-5 vertical-tabs pat-tab">
                        {this.props.isFetching && (
                          <div style={{ height: '100vh', position: 'relative' }}>
                            {/* <Loader2 loading={this.props.isFetching} /> */}
                          </div>
                        )}
                        {!this.props.isFetching && (
                          <Tabs
                            activeKey={String(activeKey)}
                            tabPosition="left"
                            defaultActiveKey="0"
                            onTabClick={this.onTabClick}
                          >
                            {tabLabels.map((item, index) => {
                              const { Component, Name } = item;
                              return (
                                <TabPane
                                  // tab={Name}
                                  tab={
                                    <div
                                      className="text-left"
                                      // style={{ whiteSpace: 'break-spaces' }}
                                    >
                                      {Name}
                                    </div>
                                  }
                                  key={`${index}`}
                                  className="tab-content enrollment-form"
                                  style={{ overflow: 'visible' }}
                                >
                                  {activeKey == index && (
                                    <Component
                                      style={{ padding: '36px 20px 150px 20px' }}
                                      handleNext={() => this.handleNext(index)}
                                      initialValues={{}}
                                      dispatch={this.props.dispatch}
                                      // onCancel={() => this.props.history.goBack()}
                                      onCancel={this.props.toggleView}
                                      isFetching={isFetching}
                                      // isUpserting={isUpserting}
                                      updateDeleteModel={this.updateDeleteModel}
                                      onLastSubmit={onSubmit.bind(this)}
                                      goToPreviuos={() => this.goToPreviuos(index)}
                                      onSubmit={onSubmit.bind(this)}
                                      enableReinitialize={true}
                                      keepDirtyOnReinitialize={true}
                                      disable={this.getDischarged()}
                                      changeActiveTab={this.changeActiveTab}
                                    />
                                  )}
                                </TabPane>
                              );
                            })}
                          </Tabs>
                        )}
                      </div>
                    </div>
                  </>
                </div>

                <CustomModal
                  isOpen={this.state.isModelOpen}
                  header={'Your changes have been saved successfully'}
                  // header={'Your Changes have been saved successfully'}
                  yesAction={() => {
                    this.toggle();
                    this.props.history.push(
                      `/cw/patient-management/patient-profile?tab=demographic&pId=${this.state.newClientId}`,
                    );
                    // this.props.toggleView();
                    // this.props.history.goBack();
                  }}
                  yesText="OK"
                  hideCancel={true}
                  changeMaxWidth="100%"
                />
                <CustomModal
                  isOpen={this.state.isNewSaveModelOpen}
                  header={modalMessage}
                  hideCancel={true}
                  hideOk={true}
                />
                <CustomModal
                  isOpen={this.state.isDeleteModelOpen}
                  header={this.state.deleteModelMessage}
                  yesAction={() => {
                    this.deleteModelToggle();
                  }}
                  yesText="OK"
                  noText="Cancel"
                  noAction={() => this.setState({ isDeleteModelOpen: false })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(SlidingTabs));
