import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Table, Badge, Dropdown, Space } from 'antd';
// import { isComparer } from '../../../util/handler';
// import ContractRowRender from './contractRowRender';
import CategoryServiceCodes from './categoryServiceCodes';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';

const bundleServiceCategory = (bundleRecord, index, indent, expanded) => {
  const {
    bundleRecord: serviceRecord,
    showRequest,
    actionAllowed,
    getServiceCodeDetails,
    isReadOnly,
  } = bundleRecord;
  const { Services, ServiceType, ServiceCodeBundleName: bundleName, Goal } = serviceRecord;
  const columns = [
    {
      title: 'Service Category',
      dataIndex: 'Services',
      key: 'Services',
      sorter: (a, b) => isComparer(a.Services, b.Services),
    },
    {
      title: 'Total Services',
      dataIndex: 'TotalServices',
      key: 'TotalServices',
      sorter: (a, b) => isComparer(a.TotalServices, b.TotalServices),
    },
  ];

  const goalsColumns = [
    {
      title: 'Goals',
      dataIndex: 'Description',
      key: 'Description',
      sorter: (a, b) => isComparer(a.Description, b.Description),
    },
    {
      title: 'Start Date',
      dataIndex: 'StartDate',
      key: 'StartDate',
      sorter: (a, b) => isDateComparer(a.StartDate, b.StartDate),
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      key: 'EndDate',
      sorter: (a, b) => isDateComparer(a.EndDate, b.EndDate),
    },
  ];

  let data = [];
  return (
    <div style={{ marginLeft: '50px' }}>
      <Table
        columns={columns}
        expandable={{
          //   expandedRowRender: CategoryServiceCodes,
          expandedRowRender: record => (
            <CategoryServiceCodes
              bundleName={bundleName}
              categoryRecord={record}
              showRequest={showRequest}
              isReadOnly={isReadOnly}
              getServiceCodeDetails={getServiceCodeDetails}
              actionAllowed={actionAllowed}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <img
                src="images/icons/expand_grid.svg"
                onClick={e => {
                  onExpand(record, e);
                  e.stopPropagation();
                }}
              />
            ) : (
              <img
                src="images/icons/collapse_grid.svg"
                onClick={e => {
                  onExpand(record, e);
                  e.stopPropagation();
                }}
              />
            ),
        }}
        dataSource={ServiceType}
        pagination={false}
        className="nested-tables"
      />

      <Table
        columns={goalsColumns}
        dataSource={Goal}
        pagination={false}
        className="nested-tables mt-3"
      />
    </div>
  );
};

export default bundleServiceCategory;
