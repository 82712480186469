import React, { Component } from 'react';
import ListRenderer from '../../Components/listRenderer';
import { connect } from 'react-redux';
// import CustomModal from '../Components/CustomModal';
import CustomModal from '../../core/modal';
import Loader, { Loader2 } from '../../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import { isComparer, isDateComparer } from '../../util/handler';
import { stringDate } from '../../util/date';
import AntdModal from '../../Components/CustomModal';
import {
  adminstrationCourseUpsert,
  resetAdminstrationCourseUpsert,
  staffAccessedTabEntGet,
} from '../../store/actions';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../component/custom-redux-components';
import NoteType from './formSteps/NoteType';
import PredefinedTask from './formSteps/PredefinedTask';
import Region from './formSteps/Region';
import MedicalProgram from './formSteps/MedicalProgram';
import UserTags from './formSteps/UserTags';
const activeKey = 0;

class Miscellaneous extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddNodeModelOpen: false,
      AddCourseModelOpen: false,
      TabMenu: null,
    };
  }
  toggle = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  };
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      courseType,
      courseTopic,
      name,
      description,
      abstract,
      publishedDate,
      ladderAssociation,
      authorStaffId,
      authorName,
      copyrightMsg,
      courseUrl,
      language,
      inactivatedDate,
      microEnterprises,
    } = values;
    var courseId = null;
    const { dispatch } = this.props;

    // var paramCourseId = this.getCourseId();
    // if (paramCourseId && paramCourseId > 0) {
    //   courseId = paramCourseId;
    // }
    const data = {
      Course: [
        {
          CourseId: courseId,
          Abstract: abstract,
          AuthorName: authorName,
          AuthorStaffId: authorStaffId,
          CopyrightMsg: copyrightMsg,
          CourseCode: null,
          CourseLength: null,
          CourseLengthUnit: null,
          CourseTopicId: courseTopic,
          CourseTypeId: courseType,
          CourseUrl: courseUrl,
          Description: description,
          InactivatedDate: stringDate(inactivatedDate),
          // LadderAssociation:
          //   typeof ladderAssociation == 'object'
          //     ? ladderAssociation.map(item => item.Value).toString()
          //     : ladderAssociation || '',
          LadderAssociation: this.getStringType(values.ladderAssociation),
          LanguageISO: language,
          Name: name,
          PublishedDate: stringDate(publishedDate),
        },
      ],
    };
    var courseData = {
      // courseId: courseId,
      IsCenterSpecific: true,
      jsonInput: JSON.stringify(data),
    };

    dispatch(adminstrationCourseUpsert(courseData));
  }
  resetForm = () => {
    this.props.dispatch(reset('coursesform'));
  };
  componentDidMount() {
    this.props.dispatch(staffAccessedTabEntGet({}));
  }
  tabs = {
    NoteType: () => <NoteType {...this.props} />,
    PredefinedTask: () => <PredefinedTask {...this.props} />,
    Region: () => <Region {...this.props} />,
    MedicalProgram: () => <MedicalProgram {...this.props} />,
    UserTags: () => <UserTags {...this.props} />,
  };

  componentWillReceiveProps({ isAddCourse, staffTabMenu }) {
    if (isAddCourse && isAddCourse != this.props.isAddCourse) {
      this.setState({ AddCourseModelOpen: true });
      this.props.dispatch(resetAdminstrationCourseUpsert());
    }
    if (staffTabMenu && JSON.stringify(staffTabMenu) != JSON.stringify(this.props.staffTabMenu)) {
      let TabMenu = [];
      TabMenu = staffTabMenu.map((tab, index) => {
        return {
          Name: <span className="border-image">{tab.text}</span>,
          ExtraComponent: this.tabs[tab.component],
        };
      });
      this.setState({
        TabMenu: TabMenu,
      });
    }
  }
  // list = [
  //   {
  //     Name: <span className="border-image">Manage Note Types</span>,
  //     ExtraComponent: () => <NoteType {...this.props} />,
  //   },
  //   {
  //     Name: <span className="border-image">Manage Predefined Tasks</span>,
  //     ExtraComponent: () => <PredefinedTask {...this.props} />,
  //   },
  //   {
  //     Name: <span className="border-image">Region</span>,
  //     ExtraComponent: () => <Region {...this.props} />,
  //   },
  //   {
  //     Name: <span className="border-image">Medical Program</span>,
  //     ExtraComponent: () => <MedicalProgram {...this.props} />,
  //   },
  // ];
  render() {
    return (
      <div className="main  mb-5 Miscellaneous-page">
        {/* <Loader loading={loading} /> */}
        <div className="container-fluid">
          <div className="row top-table-search mt-5 mb-3 align-items-center">
            <div className="col-12">
              <Link to="/manage" className="back-link">
                <img src="images/back-arrow.svg" /> Back
              </Link>
              <h3 className="form-main-head mb-3 mb-md-0">Miscellaneous</h3>
            </div>
          </div>
          <div className="row mb-4">
            <div class="col-12 mb-5">
              <div className="vertical-tabs enrollment-form">
                {this.state.TabMenu && (
                  <ListRenderer
                    list={this.state.TabMenu}
                    activeKey={activeKey}
                    tabPosition="left"
                    destroyInactiveTabPane={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          isOpen={this.state.AddNodeModelOpen}
          header={'Note Type have been saved successfully'}
          yesAction={() => {
            this.toggle('AddNodeModelOpen');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.AddCourseModelOpen}
          header={'Course have been saved successfully'}
          yesAction={() => {
            this.toggle('AddCourseModelOpen');
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { staff_accessed_tab_ent_get } = state;
  const { data: staffTabData, error } = staff_accessed_tab_ent_get;

  let staffTabMenu = null;

  if (staffTabData && staffTabData.MiscellaneousTabs) {
    staffTabMenu = staffTabData.MiscellaneousTabs;
  }

  return {
    staffTabMenu,
  };
};
export default connect(mapStateToProps)(Miscellaneous);
