import React, { useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  smartContractPayerBundleGetToSubmit,
  smartContractBundleSubmitToPayer,
} from '../../../../../../../store/actions';
import { currencyFormatter, numberFormat } from '../../../../../../../util/formatter';
import { Checkbox, Divider } from 'antd';
import downloadPdf from '../../../../../../../util/downloadPDF';
import Axios from 'axios';
import DownloadContractPdf from '../../downloadContractPdf';
const CheckboxGroup = Checkbox.Group;
const defaultCheckedList = [];

const SubmitContract = props => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [plainOptions, setPlainOptions] = useState([]);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  const onChange = list => {
    setCheckedList(list);
  };
  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };
  const handleSubmit = () => {
    let tempArray = payerListToSubmit
      .filter(item => checkedList.includes(item.PayerName))
      .map(item => {
        return { SmartContractServiceCodeBundleId: item.SmartContractServiceCodeBundleId };
      });
    let Data = {
      json: JSON.stringify({
        SmartContract: tempArray,
      }),
    };
    dispatch(smartContractBundleSubmitToPayer(Data));
  };

  useEffect(() => {
    dispatch(
      smartContractPayerBundleGetToSubmit({
        json: JSON.stringify({
          SmartContract: [
            {
              SmartContractId: props.smartContractId,
            },
          ],
        }),
      }),
    );
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const { payerListToSubmit } = useSelector(
    ({ smart_contract_payer_bundle_get_to_submit: { data: payerData } }) => {
      return {
        payerListToSubmit: payerData && payerData.SmartContract,
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (payerListToSubmit) {
      let tempPlainOptions =
        payerListToSubmit &&
        payerListToSubmit.filter(item => {
          const {
            AmountToBeClaimed,
            IsSubmittedToPayer,
            PayerName,
            SmartContractServiceCodeBundleId,
          } = item;

          if (
            (IsSubmittedToPayer == false && PayerName != 'Self Pay') ||
            (IsSubmittedToPayer == null && PayerName != 'Self Pay')
          ) {
            return PayerName;
          }
        });

      setPlainOptions(
        tempPlainOptions.map(option => {
          return option.PayerName;
        }),
      );
    }
  }, [payerListToSubmit]);

  useEffect(() => {
    if (props.submitToPayerSuccess) {
      setOpen(false);
      dispatch(
        smartContractPayerBundleGetToSubmit({
          json: JSON.stringify({
            SmartContract: [
              {
                SmartContractId: props.smartContractId,
              },
            ],
          }),
        }),
      );
    }
  }, [props.submitToPayerSuccess]);

 
  return (
    <div>
      {payerListToSubmit &&
      payerListToSubmit
        .filter(item => {
          const {
            AmountToBeClaimed,
            IsSubmittedToPayer,
            PayerName,
            SmartContractServiceCodeBundleId,
          } = item;

          if (
            (IsSubmittedToPayer == false && PayerName != 'Self Pay') ||
            (IsSubmittedToPayer == null && PayerName != 'Self Pay')
          ) {
            return PayerName;
          }
        })
        .filter(item => item.IsSubmittedToPayer == false).length > 0 ? (
        <button
          type="primary"
          onClick={showDrawer}
          className="btn btn-primary"
          // style={{ marginTop: '17px' }}
        >
          Submit Contract
        </button>
      ) : (
        <>
          <div
            style={{
              color: 'green',
              // marginTop: '17px'
            }}
          >
            Contract Submitted &nbsp;
          </div>
        </>
      )}

      <Drawer title="" placement="right" onClose={onClose} open={open} width={700}>
        <div style={{ background: '#fff' }}>
          <p
            style={{
              borderRadius: '8px',
              background: 'rgba(108, 197, 117, 0.11)',
              color: '#00C914',
              padding: '5px',
              textAlign: 'center',
              fontStyle: 'italic',
              marginBottom: '50px',
            }}
          >
            <i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp; Your Contract has been
            Successfully Created!
          </p>
        </div>
        <h2 style={{ fontSize: '17px', fontWeight: '700' }}>
          Let’s Submit this Contract to the Payer
        </h2>
        <p style={{ fontWeight: '600' }}>Select Payer</p>

        <div className="payer-selection-drawer">
          <div className="container">
            {' '}
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              <div className="row">
                <span
                  className="col-md-12"
                  style={{ margin: '12px', display: 'block', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Submit To All Payers
                </span>
              </div>
            </Checkbox>
          </div>

          {/* <Divider /> */}

          <CheckboxGroup onChange={onChange} value={checkedList}>
            <div className="container">
              {payerListToSubmit &&
                payerListToSubmit.map(item => {
                  const {
                    AmountToBeClaimed,
                    IsSubmittedToPayer,
                    PayerName,
                    SmartContractServiceCodeBundleId,
                  } = item;
                  if (!IsSubmittedToPayer && PayerName != 'Self Pay') {
                    return (
                      <Checkbox value={PayerName}>
                        <div
                          className="row"
                          style={{
                            borderRadius: '4px',
                            border: '1px solid #959595',
                            background: '#FFF',
                            marginLeft: '10px',
                            width: '463px',
                            fontSize: '16px',
                          }}
                        >
                          <span className="col-md-4 d-flex align-items-center">{PayerName}</span>
                          <span className="col-md-8">
                            <span className="d-flex flex-column align-items-end">
                              {' '}
                              <span>Claim Amount:</span>
                              <span className="bold">
                                {currencyFormatter.format(AmountToBeClaimed || 0)}
                              </span>
                            </span>
                          </span>
                        </div>
                      </Checkbox>
                    );
                  } else {
                    return <></>;
                    // return (
                    //   <div className="row d-block">
                    //     <span className="col-md-3">{PayerName}</span>
                    //     <span className="col-md-3">
                    //       <span>Claim Amount:</span>
                    //       <span> {AmountToBeClaimed}</span>
                    //     </span>
                    //   </div>
                    // );
                  }
                })}
            </div>
          </CheckboxGroup>
          <p style={{ color: '#7F8188', fontSize: '14px' }}>
            <span className="bold">Note:</span> Contract distribution will be directed exclusively
            to the payer of your choice.
          </p>
          <div className="row" style={{ marginTop: '50px' }}>
            <DownloadContractPdf clientId={props.clientId} smartContractId={props.smartContractId}>
              <span style={{ color: '#46B4FE' }}>
                <i class="fa fa-print" aria-hidden="true"></i>&nbsp; Print Contract
              </span>
            </DownloadContractPdf>
            <div className="col-md-7 text-right">
              <button className="btn btn-outline-primary mr-1" onClick={onClose}>
                Skip for Now
              </button>
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit Contract
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = state => {
  const { smart_contract_bundle_submit_to_payer } = state;
  const { data: data2 } = smart_contract_bundle_submit_to_payer;
  let submitToPayerSuccess = false;
  if (data2 && data2.success) {
    submitToPayerSuccess = data2.success;
  }
  return {
    submitToPayerSuccess,
  };
};

export default connect(mapStateToProps)(SubmitContract);
