import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import { resetLogin, logOut } from '../../store/actions';
import AntdModal from '../../Components/CustomModal';
import { userFeedback, removeUserFeedback } from '../../store/actions';
import { Modal } from 'antd';
import DefaultCenter from '../defaultCenter';
import { LIST_ROUTES } from '../../store/menuConfig';
import AskYOUUniverse from '../../Screens/YOUUniverse';
import hostGrabber from '../../util/hostGrabber';
class SupportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myText: '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.myText !== this.state.myText) return true;
    return false;
  }

  render() {
    const { onOk, getUserFeedBack, onCancel } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            &times;
          </button>
          <h4 className="modal-title col-12">Please share your feedback here</h4>
        </div>
        <div className="modal-body pl-4 pr-4 pl-sm-5 pr-sm-5 ml-lg-5 mr-lg-5">
          <div className="form-group">
            <textarea
              className={`form-control newcontract-textarea`}
              type="text"
              placeholder="Feedback"
              rows="4"
              value={this.state.myText}
              onChange={e => {
                let newValue = e.target.value;
                this.setState({ myText: newValue });
              }}
            />
          </div>
        </div>

        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            className="btn"
            onClick={() => {
              getUserFeedBack(this.state.myText);
              onOk();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

class Menu extends Component {
  state = { showSubMenu: false, showMenu: false };

  constructor(props) {
    super(props);
    this.state = {
      menu: true,
      menus: [],
      centerName: '',
      userName: '',
      userRole: '',
      profileImgUrl: null,
      email: '',
    };
    var menu = localStorage.getItem('dr:menu');
    if (menu !== undefined) {
      this.state.menus = JSON.parse(menu);
    }

    localStorage.setItem('isWebCam', 1);
  }

  getActiveUrl = path => {
    const { pathname } = this.props.location;
    var exists = pathname.includes(path);
    if (exists > 0) return true;
    else return false;
  };

  onLogOut() {
    this.props.dispatch(resetLogin);
    this.props.dispatch(logOut({}));
    this.props.logOutBroadcaster();
    // localStorage.clear();
    // sessionStorage.clear();
    //this.props.history.push('/login');
    // window.location.href = '/';
    console.log('master5 menu=>logout executed');
  }

  componentDidMount() {
    const me = this;
    var menu = JSON.parse(localStorage.getItem('dr:menu'));
    let inHouseMenu = [];
    // if (menu) {
    //   for (let menuItem of menu) {
    //     if (menuItem.IsInHouse) {
    //       inHouseMenu.push(menuItem);
    //     }
    //   }
    // }
    if (menu) {
      me.setState({
        menus: menu,
        userName: localStorage.getItem('userName'),
        userRole: localStorage.getItem('userRole'),
        centerName: localStorage.getItem('centerName'),
        profileImgUrl: localStorage.getItem('profileImgUrl'),
        hasClinicalAccess: JSON.parse(localStorage.getItem('hasClinicalAccess')),
        email: localStorage.getItem('email'),
        adminUserInfo: localStorage.getItem('adminUserInfo'),
      });
    } else {
      /*Axios.post('user/ListMenu', {}).then(response => {
        localStorage.setItem('userName', response.data.UserName);
        localStorage.setItem('userRole', response.data.UserRole);
        localStorage.setItem('centerName', response.data.CenterName);
        localStorage.setItem('profileImgUrl', response.data.ProfileImgUrl);
        localStorage.setItem('email', response.data.Email);
        localStorage.setItem('adminUserInfo', JSON.stringify(response.data.AdminUserInfo));
        if (response.data.data) {
          for (let menuItem of response.data.data) {
            if (menuItem.IsInHouse) {
              inHouseMenu.push(menuItem);
            }
          }
        }
        me.setState({
          menus: inHouseMenu,
          userName: response.data.UserName,
          userRole: response.data.UserRole,
          centerName: response.data.CenterName,
          profileImgUrl: response.data.ProfileImgUrl,
          email: response.data.Email,
        });
      });*/
    }
  }

  getUserFeedBack = Text => {
    this.props.dispatch(
      userFeedback({
        Notes: Text,
      }),
    );
  };

  render() {
    const {
      showSubMenu,
      showMenu,
      userName,
      menus,
      userRole,
      profileImgUrl,
      hasClinicalAccess,
    } = this.state;
    const { CustomLogo, isFetching, isGlobalChatEnabled } = this.props;
    return (
      <header className="header mb-3 bg-white" style={{ minHeight: '100px' }}>
        <nav className="navbar navbar-light navbar-expand-md static-top pl-0 pr-0">
          <div className="container-fluid new-header-css">
            {/* <a className="navbar-brand" title="Sober peer">
              <img src="images/logo.svg" />
            </a> */}
            <Link className="navbar-brand" to={`${this.props.defaultDashboard}`}>
              <img
                src={
                  !isFetching &&
                  (CustomLogo
                    ? `images/${CustomLogo}`
                    : hostGrabber()
                    ? 'images/youulogo.png'
                    : 'images/logo.png')
                }
                width={hostGrabber() ? !CustomLogo && '150px' : !CustomLogo && '48px'}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() =>
                this.setState(prevState => {
                  return { showMenu: !prevState.showMenu };
                })
              }
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse header-nav ${showMenu &&
                'show'} align-item-center`}
              id="navbarResponsive"
            >
              <ul className="navbar-nav mr-auto  align-items-md-center">
                {menus.map(menuItem => {
                  if (menuItem.Text && !menuItem.hideMenu) {
                    return (
                      <li
                        className={`nav-item ${this.getActiveUrl(menuItem.Path) ? 'active' : ''}`}
                      >
                        <Link className="nav-link" to={menuItem.Path}>
                          {menuItem.Text} <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
              {isGlobalChatEnabled && <AskYOUUniverse />}
              <div className="d-flex navbar-nav  align-items-center">
                <div
                  className="nav-item dropdown user-info"
                  onMouseEnter={() => this.setState({ showSubMenu: true })}
                  onMouseLeave={() => this.setState({ showSubMenu: false })}
                >
                  <a className="nav-link dropdown-toggle d-flex align-items-center">
                    <img
                      src={
                        profileImgUrl && profileImgUrl != 'null'
                          ? profileImgUrl
                          : 'images/blank-profile.png'
                      }
                      className="rounded-circle face-fit-cover"
                      width="47"
                      height="47"
                      alt=""
                    />
                    <span className="ml-2">
                      {userName}
                      <span className="user-name">{userRole}</span>
                    </span>
                  </a>
                  <div
                    className={`dropdown-menu ${showSubMenu && 'show'}`}
                    style={{ marginTop: '-5px' }}
                  >
                    <Link className="dropdown-item" to="/payer/settings">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M7.742 10.934a.742.742 0 1 1 0-1.485h8.647a.742.742 0 1 1 0 1.485H7.742zm13.1 2.412a2.23 2.23 0 0 1-2.227-2.227V9.227A2.23 2.23 0 0 1 20.842 7a2.23 2.23 0 0 1 2.226 2.227v.222h2.19a.742.742 0 1 1 0 1.485h-2.19v.185a2.23 2.23 0 0 1-2.226 2.227zm-.742-4.12v1.893a.743.743 0 0 0 1.484 0V9.227a.743.743 0 0 0-1.484 0zm-3.713 12.878a.742.742 0 1 1 0 1.484H7.742a.742.742 0 1 1 0-1.484h8.645zm-8.645-4.866a.742.742 0 1 1 0-1.485h2.115a.742.742 0 1 1 0 1.485H7.742zm17.516-1.485a.742.742 0 0 1 0 1.485h-8.72v.19a2.23 2.23 0 0 1-2.227 2.226 2.23 2.23 0 0 1-2.227-2.226v-1.93a2.23 2.23 0 0 1 2.227-2.227 2.23 2.23 0 0 1 2.226 2.227v.255h8.72zm-10.205 1.675v-1.93a.743.743 0 0 0-1.485 0v1.93a.743.743 0 0 0 1.485 0zm10.205 4.676a.742.742 0 1 1 0 1.484h-2.191v.185A2.23 2.23 0 0 1 20.84 26a2.23 2.23 0 0 1-2.226-2.227v-1.892a2.23 2.23 0 0 1 2.226-2.227 2.23 2.23 0 0 1 2.227 2.227v.223h2.19zm-3.676 1.67V21.88a.743.743 0 0 0-1.484 0v1.892a.743.743 0 0 0 1.484 0z"
                        />
                      </svg>
                      Settings
                    </Link>
                    {/* <a
                      className="dropdown-item"
                      href="https://support.soberpeer.com"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M15.96 21.664a.781.781 0 1 1 0-1.562.781.781 0 0 1 0 1.562zm.782-3.516V17.1a3.256 3.256 0 0 0 2.498-3.285c-.065-1.688-1.45-3.06-3.152-3.124a3.276 3.276 0 0 0-2.419.917 3.199 3.199 0 0 0-.99 2.323.781.781 0 1 0 1.563 0c0-.455.182-.88.511-1.198.341-.328.794-.499 1.276-.48.891.033 1.616.745 1.65 1.622a1.695 1.695 0 0 1-1.341 1.715 1.46 1.46 0 0 0-1.158 1.433v1.126a.781.781 0 1 0 1.562 0zm4.432 6.41a.781.781 0 1 0-.81-1.336A8.423 8.423 0 0 1 16 24.437c-4.652 0-8.438-3.785-8.438-8.437 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.437 3.786 8.437 8.438a8.404 8.404 0 0 1-1.43 4.683.781.781 0 0 0 1.299.868A9.96 9.96 0 0 0 26 16a9.935 9.935 0 0 0-2.929-7.071A9.935 9.935 0 0 0 16 6a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 6 16a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 16 26a9.984 9.984 0 0 0 5.174-1.441z"
                        />
                      </svg>
                      Help
                    </a> */}

                    <span className="dropdown-item">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#9A9A9A"
                          fill-rule="nonzero"
                          d="M15.96 21.664a.781.781 0 1 1 0-1.562.781.781 0 0 1 0 1.562zm.782-3.516V17.1a3.256 3.256 0 0 0 2.498-3.285c-.065-1.688-1.45-3.06-3.152-3.124a3.276 3.276 0 0 0-2.419.917 3.199 3.199 0 0 0-.99 2.323.781.781 0 1 0 1.563 0c0-.455.182-.88.511-1.198.341-.328.794-.499 1.276-.48.891.033 1.616.745 1.65 1.622a1.695 1.695 0 0 1-1.341 1.715 1.46 1.46 0 0 0-1.158 1.433v1.126a.781.781 0 1 0 1.562 0zm4.432 6.41a.781.781 0 1 0-.81-1.336A8.423 8.423 0 0 1 16 24.437c-4.652 0-8.438-3.785-8.438-8.437 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.437 3.786 8.437 8.438a8.404 8.404 0 0 1-1.43 4.683.781.781 0 0 0 1.299.868A9.96 9.96 0 0 0 26 16a9.935 9.935 0 0 0-2.929-7.071A9.935 9.935 0 0 0 16 6a9.935 9.935 0 0 0-7.071 2.929A9.935 9.935 0 0 0 6 16a9.935 9.935 0 0 0 2.929 7.071A9.935 9.935 0 0 0 16 26a9.984 9.984 0 0 0 5.174-1.441z"
                        />
                      </svg>

                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <a
                            onClick={() => {
                              this.setState({ showSubMenu: false });
                              onClick();
                            }}
                          >
                            Support/Feedback
                          </a>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <SupportForm
                            onOk={onOk}
                            onCancel={onCancel}
                            getUserFeedBack={this.getUserFeedBack}
                          />
                        )}
                      />
                    </span>
                    <Modal
                      width={600}
                      visible={this.props.modalOpen}
                      footer={null}
                      closeIcon={null}
                      onCancel={() => this.props.dispatch(removeUserFeedback())}
                      closable={false}
                    >
                      <div className="modal-content border-0">
                        <div className="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => this.props.dispatch(removeUserFeedback())}
                          >
                            &times;
                          </button>
                          <h4 className="modal-title col-12">Thank you for the feedback.</h4>
                        </div>
                        <div className="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                          <button
                            className="btn"
                            onClick={() => this.props.dispatch(removeUserFeedback())}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </Modal>
                    <a className="dropdown-item" onClick={this.onLogOut.bind(this)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 32 32"
                      >
                        <g fill="#9A9A9A" fill-rule="nonzero">
                          <path d="M15.521 6.388l-1.509 2.14a.586.586 0 0 0 .48.924h.922v10.275a.586.586 0 0 0 1.172 0V9.452h.923c.477 0 .75-.539.479-.924l-1.51-2.14a.587.587 0 0 0-.957 0z" />
                          <path d="M19.762 9.325a.586.586 0 0 0 .214.801 7.952 7.952 0 0 1 3.977 6.89c0 4.385-3.568 7.953-7.953 7.953S8.047 21.4 8.047 17.016a7.952 7.952 0 0 1 3.977-6.89.586.586 0 0 0-.587-1.014 9.124 9.124 0 0 0-4.562 7.904A9.12 9.12 0 0 0 16 26.14a9.12 9.12 0 0 0 9.125-9.125 9.124 9.124 0 0 0-4.562-7.904.586.586 0 0 0-.8.213z" />
                        </g>
                      </svg>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = ({ user_feedback, default_values }) => {
  const { data, isFetching } = default_values;
  var modalOpen = false;
  let defaultDashboard;
  let isGlobalChatEnabled;
  let dashboardClinical;
  let CustomLogo;
  if (data) {
    defaultDashboard = data.defaultDashboard;
    dashboardClinical = data.dashboardClinical;
    isGlobalChatEnabled = data.IsGlobalChatEnabled;
    CustomLogo = data.CustomLogo;
  }

  if (user_feedback && user_feedback.data) {
    modalOpen = (user_feedback && user_feedback.data && user_feedback.data.success) || false;
  }

  return {
    modalOpen,
    defaultDashboard,
    dashboardClinical,
    CustomLogo,
    isFetching,
    isGlobalChatEnabled,
  };
};

export default withRouter(connect(mapStateToProps)(Menu));
