import React from 'react';
import { Tabs, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import BasicInformation from './formSteps/basicInformation';
import ManageGroup from './formSteps/manageGroup';
import ManagePatients from './formSteps/managePatients';
import NotesList from './formSteps/notesList';
import GroupAppointmentList from './formSteps/groupAppointmentList';
import GroupBanner from './formSteps/groupBanner';
import { getGroupBanner } from '../../../store/actions';
import PageTitle from '../../../Components/PageTitle';
const { TabPane } = Tabs;

class SlidingTabs extends React.Component {
  constructor(props) {
    super(props);
    // props.reset();
    this.state = {
      isProcessed: false,
      tabMapping: {
        0: 'group-information',
        1: 'patients-list',
        2: 'group-appointment-list',
        3: 'notes-list',
        4: 'service-list',
      },
      tabLabels: [
        {
          Name: 'Group Information',
          Component: props => <BasicInformation {...props} />,
        },
        {
          Name: 'Patients ',
          Component: props => <ManagePatients {...props} isClientAdded={this.isClientAdded} />,
        },
        {
          Name: 'Appointments',
          Component: props => <GroupAppointmentList {...props} />,
        },
        {
          Name: 'Notes',
          Component: props => <NotesList {...props} />,
        },

        {
          Name: 'Services',
          Component: props => <ManageGroup {...props} />,
        },
      ],

      // activeKey: this.getStudentId() === -1 ? 0 : null,
      activeKey: 0,
      // activeTabs: this.getGroupId() > 0 ? [true, true, true, true] : [false, false, false, false],
      activeTabs: [false, false, false, false],
      isModelOpen: false,
      isNewSaveModelOpen: false,
      isDeleteModelOpen: false,
      deleteModelMessage: null,
      yesCallBack: null,
      showError: false,
      modalVisible: false,
    };
  }
  isClientAdded = isSuccess => {
    if (isSuccess) {
      this.props.dispatch(
        getGroupBanner({
          json: JSON.stringify({
            Group: [
              {
                GroupId: this.getGroupId(),
              },
            ],
          }),
        }),
      );
    }
  };
  onTabClick = key => {
    if (this.getGroupId()) {
      let keyData = parseInt(key);
      let clickedTab = this.state.tabMapping[keyData];
      this.props.history.push(
        `/cw/group-management/group-details?tab=${clickedTab}&gId=${this.getGroupId()}`,
      );
      this.setState({
        activeKey: parseInt(key),
      });
    } else {
      return;
    }

    // const { activeTabs } = this.state;
    // if (activeTabs[key]) {
    //   this.setState({ activeKey: key, activeTabs });
    // }
  };
  getGroupId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var groupId = param.get('gId');
    if (groupId) {
      return (groupId = parseInt(groupId));
    } else {
      groupId = null;
    }
    return groupId;
  }
  backButtonImplimentation = nextProps => {
    let upComingTab = new URLSearchParams(nextProps.history.location.search);
    upComingTab = upComingTab.get('tab');
    let upComingTabIndex = Object.values(this.state.tabMapping).indexOf(upComingTab);
    let pastTabs = new URLSearchParams(this.props.location.search);
    pastTabs = pastTabs.get('tab');
    let pastTabsIndex = Object.values(this.state.tabMapping).indexOf(pastTabs);
    if (upComingTabIndex != pastTabsIndex) {
      this.setState({
        activeKey: upComingTabIndex,
      });
    }
  };
  componentWillReceiveProps(nextProps, state, Banner) {
    this.backButtonImplimentation(nextProps);
  }

  isAuditorLogin = () => {
    let isReadOnlyUserSession = false;
    if (localStorage.getItem('giveRead') == 'true') {
      isReadOnlyUserSession = true;
    }
    return isReadOnlyUserSession;
  };
  componentDidMount(props) {
    const { tabMapping } = this.state;
    var params = new URLSearchParams(this.props.location.search);
    var tab = params.get('tab');
    if (tabMapping) {
      this.setState({
        activeKey: Object.values(tabMapping).indexOf(tab),
      });
    }

    this.props.dispatch(
      getGroupBanner({
        json: JSON.stringify({
          Group: [
            {
              GroupId: this.getGroupId(),
            },
          ],
        }),
      }),
    );
    if (this.getGroupId()) {
      this.setState({
        activeTabs: [true, true, true, true],
      });
    } else {
      this.setState({
        activeKey: 0,
        activeTabs: [true, false, false, false],
      });
    }
  }
  render() {
    const { activeKey, studentData, tabLabels, activeTabs } = this.state;
    const { Banner, isReadOnlyUser, isFetching } = this.props;
    return (
      <div class="w-100 rcm-panel">
        <PageTitle TitleText="Group Management" />
        <div class="main enrollment-page mb-5">
          <div class="container-fluid">
            <div class="content-area">
              <div class="row top-table-search">
                {/* <div className="mt-3 mb-3 cursor-pointer">
                  <a class="back-link" onClick={() => this.props.history.goBack()}>
                    <img src="images/back-arrow.svg" /> Back
                  </a>
                </div> */}
                <div className="mt-3 cursor-pointer" style={{ display: 'inline-block' }}>
                  <a
                    class="back-link"
                    onClick={() => this.props.history.push(`/cw/group-management/group-list`)}
                  >
                    <img src="images/back-arrow.svg" /> Back to Group list
                  </a>
                </div>
                {this.getGroupId() > 0 && (
                  <GroupBanner
                    BannerDetails={Banner}
                    isFetching={isFetching}
                    isReadOnlyUser={this.isAuditorLogin()}
                  />
                )}

                <div class="col-12 mb-5 vertical-tabs pat-tab px-0 rcm-group-tab">
                  <Tabs
                    activeKey={String(activeKey)}
                    tabPosition="left"
                    defaultActiveKey="0"
                    onTabClick={this.onTabClick}
                  >
                    {tabLabels.map((item, index) => {
                      const { Component, Name } = item;
                      return (
                        <TabPane
                          tab={Name}
                          key={`${index}`}
                          className="tab-content enrollment-form"
                        >
                          {activeKey == index && (
                            <Component
                              style={{ padding: '36px 20px 150px 20px' }}
                              Banner={Banner}
                              //   initialValues={studentData}
                              //   studentId={this.getStudentId()}
                              dispatch={this.props.dispatch}
                              // onCancel={() => this.props.history.goBack()}
                              //   isFetching={isFetching}
                              //   isUpserting={isUpserting}
                              //   updateDeleteModel={this.updateDeleteModel}
                              //   onLastSubmit={onSubmit.bind(this)}
                              //   goToPreviuos={() => this.goToPreviuos(index)}
                              //   onSubmit={onSubmit.bind(this)}
                              enableReinitialize={true}
                              //   keepDirtyOnReinitialize={true}
                              //   disable={this.getDischarged()}
                              disable={isReadOnlyUser}
                              isReadOnlyUser={isReadOnlyUser}
                              isReadOnlyUserSession={this.isAuditorLogin()}
                            />
                          )}
                        </TabPane>
                      );
                    })}
                  </Tabs>
                </div>
              </div>
            </div>{' '}
          </div>{' '}
        </div>{' '}
      </div>
    );
  }
}
const mapStateToProps = ({ get_group_banner, default_values }) => {
  const { data: bannerData, isFetching } = get_group_banner;
  let Banner = {};
  let isReadOnlyUser = false;
  if (bannerData && bannerData.Group) {
    Banner = bannerData.Group[0];
  }

  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  return {
    Banner,
    isReadOnlyUser,
    isFetching,
  };
};

export default withRouter(connect(mapStateToProps)(SlidingTabs));
