import React from 'react';
import { Tabs, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import { ReduxSelect } from '../../../component/custom-redux-components/index.js';
import {
  comboPractitionerAll,
  staffLicenseAgreementUpdate,
  resetLogin,
  logOut,
} from '../../../store/actions';
import DailySchedule from './dailySchedule';
import Caseload from './caseload';
import Alerts from './alerts';
import LicenseModal from '../../licenseAgreementModal';
import PageTitle from '../../../Components/PageTitle';
class CWDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      practitionerValue: null,
      firstLogin: sessionStorage.getItem('firstLogin'),
      firstLoginModal: true,
    };
    this.props.dispatch(
      comboPractitionerAll({
        json: JSON.stringify({
          Combo: [
            {
              IsAll: true,
            },
          ],
        }),
      }),
    );
  }
  onLicenseModalCancel = isAgreed => {
    this.setState({
      firstLoginModal: false,
    });
    sessionStorage.setItem('firstLogin', false);
    if (isAgreed == true) {
      this.props.dispatch(staffLicenseAgreementUpdate({}));
    } else if (isAgreed == false) {
      this.props.dispatch(resetLogin);
      this.props.dispatch(logOut({}));
    }
  };
  render() {
    const { Practitioner, isReadOnlyUser } = this.props;
    return (
      <div class="mt-3">
        <PageTitle TitleText="CW Dashboard" />

        <div class="main enrollment-page mb-5 cw-dashboard">
          <div class="container-fluid px-5">
            <div className="row dashboard-analytics-centers">
              <div className="col-10 col-md-9"></div>
              <div
                className="col-2 col-md-3"
                style={{ flexDirection: 'row-reverse', display: 'flex' }}
              >
                <div class="form-group">
                  <ReduxSelect
                    options={Practitioner}
                    onChange={val => {
                      this.setState({
                        practitionerValue: val,
                      });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={this.state.practitionerValue}
                    placeholder="Provider"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div className="col-12 col-md-12 col-lg-9 mt-3">
                <div className="card border-0 rounded-0">
                  <div className="card-body">
                    <div className="daily-schedule">
                      <DailySchedule
                        PractitionerValue={this.state.practitionerValue}
                        isReadOnlyUser={isReadOnlyUser}
                      />
                    </div>
                    <div className="caseload">
                      <Caseload
                        PractitionerValue={this.state.practitionerValue}
                        isReadOnlyUser={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-3 mt-3">
                <div className="card border-0 rounded-0">
                  <div className="card-body">
                    <div className="alerts">
                      <Alerts
                        PractitionerValue={this.state.practitionerValue}
                        isReadOnlyUser={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.firstLogin == 1 && (
            <Modal
              width={700}
              zIndex={500}
              closable={false}
              mask
              maskClosable={false}
              centered
              open={this.state.firstLoginModal}
              footer={null}
              onCancel={this.onLicenseModalCancel}
            >
              <LicenseModal onLicenseModalCancel={this.onLicenseModalCancel} />
            </Modal>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ combo_practitioner_all, default_values }) => {
  const { data } = combo_practitioner_all;
  let Practitioner = [];
  let isReadOnlyUser = false;
  if (data && data.PractitionerList) {
    Practitioner = data.PractitionerList || [];
  }
  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }
  return {
    Practitioner: Practitioner,
    isReadOnlyUser,
  };
};
export default withRouter(connect(mapStateToProps)(CWDashboard));
