import React, { Component } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Player = ({ src, layout }) => (
  <AudioPlayer
    layout={layout || 'stacked'} //'stacked' | 'horizontal' | 'stacked-reverse' | 'horizontal-reverse'
    src={src}
    customVolumeControls={[]}
    customAdditionalControls={[]}
    // volume={0.5}
    // autoPlay
    // onPlay={e => console.log('onPlay')}
    // other props here
  />
);

export default Player;
