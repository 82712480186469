import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Drawer } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import ClientsTransactionsDrawer from './ClientsTransactionsDrawer';
import CounselorsTransactionsDrawer from './CounselorsTransactionsDrawer';
import { currencyFormatter2, numberFormat } from '../../../util/formatter';

class AllTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  showDrawer = ({ isStaff, StaffId, StudentId }) => {
    this.setState({ isStaff: isStaff, open: true, StaffId: StaffId, StudentId: StudentId });
  };

  onClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { tokenList, tokenStats } = this.props;
    const { BalanceTokens, TotalTokensCredited, TotalTokensDebited, TotalDebitedin$ } =
      tokenStats || {};
    const columns = [
      {
        title: 'Transaction Date',
        dataIndex: 'PurchasedDate',
        key: 'PurchasedDate',
        sorter: (a, b) => isDateComparer(a.PurchasedDate, b.PurchasedDate),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Used By',
        dataIndex: 'UsedBy',
        key: 'UsedBy',
        sorter: (a, b) => isComparer(a.UsedBy, b.UsedBy),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          record.StaffId ? (
            <span className="d-flex align-items-center">
              <img
                style={{ height: '30px', width: '30px', borderRadius: '50%', objectFit: 'cover' }}
                src={record.StaffProfileImage || '/images/blank-profile.png'}
              />
              <span className="ml-1">{text}</span>
              <img
                src="images/icons/open_external.svg"
                className="mx-2"
                onClick={() => {
                  this.showDrawer({
                    isStaff: true,
                    StaffId: record.StaffId,
                  });
                }}
              />
            </span>
          ) : (
            <span className="ml-1">{text}</span>
          ),
      },
      {
        title: 'Client',
        dataIndex: 'Client',
        key: 'Client',
        sorter: (a, b) => isComparer(a.Client, b.Client),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          record.StudentId ? (
            <span className="d-flex align-items-center">
              <img
                style={{ height: '30px', width: '30px', borderRadius: '50%', objectFit: 'cover' }}
                src={record.ClientProfileImage || '/images/blank-profile.png'}
              />
              <span className="ml-1">{text}</span>
              <img
                src="images/icons/open_external.svg"
                className="mx-2"
                onClick={() => {
                  this.showDrawer({ isStaff: false, StudentId: record.StudentId });
                }}
              />
            </span>
          ) : (
            <span className="ml-1">{text}</span>
          ),
      },

      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        sorter: (a, b) => isComparer(a.Type, b.Type),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Token Amount',
        dataIndex: 'TokensPurchased',
        key: 'TokensPurchased',
        sorter: (a, b) => isNumericComparer(a.TokensPurchased, b.TokensPurchased),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            text && (
              <>
                {record.Type === 'Credit' ? (
                  <span>
                    <img src="/images/smallCoin.svg" />
                    <span
                      style={{ color: '#00DC31', fontWeight: '600', marginLeft: '5px' }}
                    >{`+${text}`}</span>
                  </span>
                ) : (
                  <span>
                    <img src="/images/smallCoin.svg" />
                    <span
                      style={{ color: '#FF2C2C', fontWeight: '600', marginLeft: '5px' }}
                    >{`-${text}`}</span>
                  </span>
                )}
              </>
            )
          );
        },
      },
      {
        title: 'Ending Balance',
        dataIndex: 'EndingBalance',
        key: 'EndingBalance',
        sorter: (a, b) => isNumericComparer(a.EndingBalance, b.EndingBalance),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) =>
          text && (
            <span>
              <img src="/images/smallCoin.svg" />
              <span style={{ marginLeft: '5px' }}>{text}</span>
            </span>
          ),
      },
    ];
    return (
      <div class="row">
        <div class="col-12 mb-5">
          <div class="card border-0 ">
            <div class="row card-body token-management">
              <div class="col-3">
                <div className="status-card status-gray">
                  <span className="title">Balance as of</span>
                  <span className="count">
                    <img className="mr-1" src="/images/bigCoin.svg" />
                    {BalanceTokens && numberFormat.format(parseInt(BalanceTokens))}
                  </span>
                </div>
              </div>
              <div class="col-3">
                <div className="status-card status-gray">
                  <span className="title">Total Tokens Credited</span>
                  <span className="count">
                    <img className="mr-1" src="/images/bigCoin.svg" />
                    {TotalTokensCredited && numberFormat.format(parseInt(TotalTokensCredited))}
                  </span>
                </div>
              </div>
              <div class="col-3">
                <div className="status-card status-gray">
                  <span className="title">Total Tokens Debited</span>
                  <span className="count">
                    <img className="mr-1" src="/images/bigCoin.svg" />
                    {TotalTokensDebited && numberFormat.format(parseInt(TotalTokensDebited))}
                  </span>
                </div>
              </div>
              <div class="col-3">
                <div className="status-card status-gray">
                  <span className="title">Total Debited in $</span>
                  <span className="count">
                    {TotalDebitedin$ && currencyFormatter2.format(TotalDebitedin$ || 0)}
                  </span>
                </div>
              </div>
              <div class="col-12">
                {/* <Loader2 loading={isFetching} /> */}
                <div className="">
                  <Table
                    columns={columns}
                    dataSource={tokenList}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div class="dataTables_wrapper no-footer">
                            <div class="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a class="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a class="paginate_button next">Next</a>
                              ) : (
                                <a class="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.open && (
          <Drawer
            className="custom-drawer"
            title="Token Transactions"
            placement="right"
            onClose={this.onClose}
            open={this.state.open}
            width="700px"
            bodyStyle={{ backgroundColor: '#fff' }}
            visible={this.state.open}
            destroyOnClose={true}
          >
            {/* {this.state.StaffId && <CounselorsTransactionsDrawer StaffId={this.state.StaffId} />}
          {this.state.StudentId && <ClientsTransactionsDrawer StaffId={this.state.StudentId} />} */}
            <div>
              {this.state.isStaff ? (
                <CounselorsTransactionsDrawer StaffId={this.state.StaffId} />
              ) : (
                <ClientsTransactionsDrawer StudentId={this.state.StudentId} />
              )}
            </div>
          </Drawer>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ token_management_list_get_all }) => {
  const { data: tokenData, isFetching, error } = token_management_list_get_all;

  let tokenList = [];
  let tokenStats = {};

  if (tokenData && tokenData.TokenManagement && tokenData.TokenManagement[0]) {
    tokenList = tokenData.TokenManagement[0].PurchaseData;
    tokenStats = tokenData.TokenManagement[0].TokenHistory[0];
  }

  return {
    isFetching,
    tokenStats,
    tokenList,
  };
};
export default connect(mapStateToProps)(AllTransactions);
