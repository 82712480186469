import React, { Component } from 'react';
import Axios from 'axios';
import Webcam from 'react-webcam';
import Loader from '../Components/Loader';
import { Modal } from 'antd';

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentId: null,
      isSumitted: false,
      isFetching: false,
    };
  }
  setRef = webcam => {
    this.webcam = webcam;
  };
  getStudentId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('studentId');
  }
  getSurveyId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('surveyId');
  }
  getFormType() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('form');
  }
  getIntakeType() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('intake');
  }
  getIgnoreUpdatingPin() {
    var param = new URLSearchParams(this.props.history.location.search);
    return param.get('ignoreUpdatingPin') == 'true' ? true : false;
  }
  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    const me = this;
    this.setState({ isFetching: true });
    Axios.post('FaceAuthentication/Authenticate', {
      image: imageSrc,
      studentId: this.getStudentId(),
    })
      .then(response => {
        if (response.data.matchingPct >= 75) {
          // Assign survey
          var searchStr =
            'surveyId=' +
            this.getSurveyId() +
            '&form=' +
            this.getFormType() +
            '&studentId=' +
            this.getStudentId() +
            '&intake=' +
            this.getIntakeType();
          this.props.history.push({
            pathname: 'survey',
            search: searchStr,
          });
        } else {
          me.setState({ isSumitted: true, isFetching: false });
          let path = this.getIgnoreUpdatingPin() ? '/validate' : '/pin';
          this.props.history.push({
            pathname: path,
            search:
              'surveyId=' +
              this.getSurveyId() +
              '&form=' +
              this.getFormType() +
              '&studentId=' +
              this.getStudentId() +
              '&intake=' +
              this.getIntakeType(),
          });
        }
      })
      .catch(error => {
        console.log('Image Failed');
      });
  };
  onUserMediaError = err => {
    var searchStr =
      'surveyId=' +
      this.getSurveyId() +
      '&form=' +
      this.getFormType() +
      '&studentId=' +
      this.getStudentId() +
      '&intake=' +
      this.getIntakeType();

    const errorMsg = err.name == 'NotFoundError' ? 'not available' : 'not enabled';
    let me = this;
    Modal.error({
      title: 'Auto Redirecting Page to Validate Pin as Camera is ' + errorMsg,
      onOk() {
        if (me.getIgnoreUpdatingPin()) {
          me.props.history.push({
            pathname: 'validate',
            search: searchStr,
          });
        } else {
          me.props.history.push({
            pathname: 'pin',
            search: searchStr,
          });
        }
      },
    });
  };
  render() {
    const { isFetching } = this.state;
    return (
      <div class="container-fluid">
        <Loader loading={isFetching} />
        <div className="row ">
          <div class="text-center mar-top-15 col-md-12">
            {/* <br />
          <div>
            <label>Student Id </label>
            <input
              name="studentId"
              type="text"
              class="form-control"
              onChange={event => (this.state.studentId = event.target.value)}
            />
          </div> */}
            {!this.state.isSumitted && (
              <Webcam
                audio={false}
                onUserMediaError={this.onUserMediaError}
                ref={this.setRef}
                style={{ height: '500px', width: '600px', margin: 'auto' }}
                screenshotFormat="image/jpeg"
              />
            )}
          </div>
          <div className="col-12 text-center mb-5">
            <button
              class="btn px-5"
              // class="black-btn mar-top-15"
              // color="blue"
              // style={{
              //   padding: '3px 10px',
              //   border: '#aeaeae 1px solid',
              //   borderBottom: '#aeaeae 3px solid',
              //   borderRight: '#aeaeae 3px solid',
              //   fontSize: '18px',
              //   borderRadius: '6px',
              //   fontWeight: '600',
              //   display: 'inline-block',
              //   marginLeft: '0px',
              //   background: '#000000',
              //   color: '#fff',
              // }}
              onClick={() => this.capture()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default Authentication;
