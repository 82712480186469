import React from 'react';
import { Field, reduxForm, formValueSelector, reset, FieldArray } from 'redux-form';
import validate from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
  CheckboxGroup,
} from '../../../../component/custom-redux-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  upsertServiceLine,
  listServiceLine,
  getServiceCode,
  getServiceLine,
  medicateCombos,
  getServiceLineCost,
  claimsPdfDownload,
} from '../../../../store/actions';
import { ProcessCombos } from '../../../../store/comboConfig';
import CustomModal from '../../../../core/modal';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import moment from 'moment';
import Loader, { Loader2 } from '../../../../Components/Loader';
import { timeConvert } from '../../../../util/time';
import { stringDate } from '../../../../util/date';
import NotesDrawer from '../../patientEnrollmentForm/formSteps/notesDrawer/notesDrawer';
import ReduxServiceLineModal from '../../CustomModals/serviceLineModal';
import { Delete_Confirmation_Msg } from '../../../../app-config';
import { Table, Modal, Button, Tooltip } from 'antd';
import { saveAs } from 'file-saver';
import PersistTableSort from '../../../../component/persistTableSort';
import ReactRangePicker from '../../../../component/Range-Picker';
import GridSearch from '../../../../Components/gridSearch';

class ServiceLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceLineInfo: {},
      createAndEditGroupModal: false,
      ServiceDetails: [],
      isModelOpen: '',
      header: '',
      StartDate: moment(new Date())
        .subtract(30, 'd')
        .format('MM-DD-YYYY'),
      EndDate: moment(new Date()).format('MM-DD-YYYY'),
    };
  }
  // need to work on it always return zero for me
  setStoredFilter = () => {
    let pageFilter = sessionStorage.getItem(`pageFilter`);

    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      if (JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter) {
        let sDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.startDateRange;
        let eDate = JSON.parse(pageFilter)[`table_${this.props.name}`].rangeFilter.endDateRange;
        this.setState({
          StartDate: sDate,
          EndDate: eDate,
        });
        if (!JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter) {
          this.props.dispatch(
            listServiceLine({
              json: JSON.stringify({
                ServiceLine: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                  },
                ],
              }),
            }),
          );
          return;
        } else {
          let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
          this.setState({
            filterBy,
          });
          this.props.dispatch(
            listServiceLine({
              json: JSON.stringify({
                ServiceLine: [
                  {
                    StartDate: sDate,
                    EndDate: eDate,
                    FilterBy: filterBy,
                  },
                ],
              }),
            }),
          );
        }
      } else if (JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter) {
        let filterBy = JSON.parse(pageFilter)[`table_${this.props.name}`].selectFilter;
        this.setState({
          filterBy,
        });
        this.props.dispatch(
          listServiceLine({
            json: JSON.stringify({
              ServiceLine: [
                {
                  StartDate: this.state.StartDate,
                  EndDate: this.state.EndDate,
                  FilterBy: filterBy,
                },
              ],
            }),
          }),
        );
      }
    } else {
      this.props.dispatch(
        listServiceLine({
          json: JSON.stringify({
            ServiceLine: [
              {
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate,
              },
            ],
          }),
        }),
      );
    }
  };
  componentDidMount() {
    this.setStoredFilter();
    var result = ProcessCombos('RCM_ServiceCode,Supervisor,UnitOfMeasurement,ServiceLineStatus');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({
    isInserted,
    isRemoved,
    ServiceList,
    ServiceInfo,
    serviceLineCodeInfo,
    SupervisorIdValue,
  }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.setState({ createAndEditGroupModal: false });
      this.toggle('Service Line Saved Successfully');
      this.props.dispatch(reset('ClientserviceLineForm'));
      this.props.dispatch(
        listServiceLine({
          json: JSON.stringify({
            ServiceLine: [
              {
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate,
                FilterBy: this.state.filterBy,
              },
            ],
          }),
        }),
      );
      this.setState({
        ServiceLineInfo: {},
        ServiceInfoInit: {},
      });
    }
    if (ServiceList && JSON.stringify(ServiceList) != JSON.stringify(this.props.ServiceList)) {
      this.setState({
        ServiceList: ServiceList,
      });
    }
    if (SupervisorIdValue && SupervisorIdValue != this.props.SupervisorIdValue) {
      this.state.ServiceLineInfo.SupervisorId = SupervisorIdValue;
    }
    if (ServiceInfo && JSON.stringify(ServiceInfo) != JSON.stringify(this.props.ServiceInfo)) {
      this.setState({
        ServiceLineInfo: ServiceInfo,
        ServiceDetails: JSON.parse(ServiceInfo.ServiceDetails),
      });
    }
    if (
      serviceLineCodeInfo &&
      JSON.stringify(serviceLineCodeInfo) != JSON.stringify(this.props.serviceLineCodeInfo)
    ) {
      const {
        ServiceCodeId,
        ServiceCodeDescription,
        UnitsId,
        UnitOfMeasurementId,
        CostPerUnit,
        TotalCost,
        IsExcludeBundle,
        ReactIndex,
      } = serviceLineCodeInfo;

      let serviceLineCode = this.props.existingServiceCodesRows;
      if (serviceLineCode && serviceLineCode.length > ReactIndex) {
        serviceLineCode[ReactIndex].ServiceCodeId = ServiceCodeId;
        serviceLineCode[ReactIndex].ServiceCodeDescription = ServiceCodeDescription;
        serviceLineCode[ReactIndex].UnitOfMeasurementId = UnitOfMeasurementId;
        serviceLineCode[ReactIndex].CostPerUnit = CostPerUnit;
        serviceLineCode[ReactIndex].IsExcludeBundle = IsExcludeBundle;
        serviceLineCode[ReactIndex].UnitsId = UnitsId;
        serviceLineCode[ReactIndex].TotalCost = TotalCost;
      }
      this.setState({
        ServiceDetails: serviceLineCode,
      });
    }
  }

  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit = values => {
    const {
      DiagnosisCode,
      ServiceCodes,
      SupervisorId,
      IsApprovedForBilling,
      IsApprovedForBatching,
      BatchSentDate,
      StatusId,
    } = values;
    // let patientId = null;
    // let paramClientId = this.props.clientId;
    // if (paramClientId && paramClientId > 0) {
    //   patientId = paramClientId;
    // }
    let ServiceCodeId = [];
    let unitsId = [];
    let CostPerUnit = [];
    let UnitOfMeasurementId = [];
    let TotalCost = [];
    let IsExcludeBundle = [];
    ServiceCodes &&
      ServiceCodes.map(item => {
        ServiceCodeId.push(item.ServiceCodeId);
        unitsId.push(item.UnitsId);
        CostPerUnit.push(item.CostPerUnit);
        UnitOfMeasurementId.push(item.UnitOfMeasurementId);
        TotalCost.push(item.TotalCost);
        IsExcludeBundle.push(item.IsExcludeBundle);
      });
    // DiagnosisCode.map(item => {
    //   DiagnosisListId.push(item.DiagnosisListId);
    // });
    let ServiceLineData = {
      json: JSON.stringify({
        ServiceLine: [
          {
            AppointmentId: this.state.ServiceLineInfo.AppointmentId,
            ServiceLineId: this.state.ServiceLineInfo.ServiceLineId,
            ClientId: this.state.ServiceLineInfo.ClientId,
            GroupAppointmentId: this.state.ServiceLineInfo.GroupAppointmentId,
            ServiceCodeId: ServiceCodeId.join(),
            UnitsId: unitsId.join(),
            TotalCost: TotalCost.join(),
            IsExcludeBundle: IsExcludeBundle.join(),
            CostPerUnit: CostPerUnit.join(),
            UnitOfMeasurementId: UnitOfMeasurementId.join(),
            DiagnosisListId: DiagnosisCode && DiagnosisCode.join(),
            SupervisorId: SupervisorId,
            IsApprovedForBilling: IsApprovedForBilling,
            IsApprovedForBatching: IsApprovedForBatching,
            BatchSentDate: BatchSentDate ? stringDate(BatchSentDate) : null,
            StatusId: StatusId ? StatusId : null,
          },
        ],
      }),
    };
    this.props.dispatch(upsertServiceLine(ServiceLineData));
  };
  getTotalCost = (event, index) => {
    let rows = this.props.existingServiceCodesRows;
    let unitVal = parseInt(event.target.value);
    let costPerUnit = parseFloat(rows[index].CostPerUnit);
    rows[index].UnitsId = unitVal;
    rows[index].TotalCost = (unitVal * costPerUnit).toFixed(2) || 0;

    this.setState({
      ServiceDetails: rows,
    });
  };
  deleteMoreCode = index => {
    let ServiceDetails = this.state.ServiceDetails;
    ServiceDetails.splice(index, 1);
    this.setState({
      ServiceDetails,
    });
  };
  downloadAsPdf = () => {
    const { dispatch } = this.props;
    const { ServiceLineInfo } = this.state;
    this.setState({ isFileDownloading: true });
    const me = this;
    var fileName;
    const accessToken = JSON.parse(localStorage.getItem('dr:token')).access_token;
    var hearders = {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Accept: 'application/json, text/plain, */*',
    };

    fetch(`/api/v1/Claims/PDFDownload`, {
      method: 'POST',
      headers: hearders,
      body: JSON.stringify({
        Json: JSON.stringify({
          PDFForm: [
            {
              FormName: 'Claims1500',
              ClientId: ServiceLineInfo.ClientId,
              ServiceLineId: ServiceLineInfo.ServiceLineId,
            },
          ],
        }),
      }),
      // responseType: 'arraybuffer',
    })
      .then(response => {
        if (response.ok) {
          fileName = response.headers.get('FileName');
          return response.blob();
        }
      })
      .then(blob => {
        me.setState({ isFileDownloading: false });
        saveAs(blob, fileName || 'Claim.pdf');
      })
      .catch(error => {
        console.log(error);
        alert(JSON.stringify(error));
      });
  };

  storeRangeFilter = date => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }
    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        rangeFilter: { startDateRange: `${date[0]}`, endDateRange: `${date[1]}` },
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };

  storeSelectFilter = val => {
    let existingFilters = {};
    let pageFilter = sessionStorage.getItem(`pageFilter`);
    if (pageFilter && JSON.parse(pageFilter)[`table_${this.props.name}`]) {
      existingFilters = JSON.parse(pageFilter)[`table_${this.props.name}`];
    }

    let PageFilter = {
      ...JSON.parse(pageFilter),
      [`table_${this.props.name}`]: {
        ...existingFilters,
        selectFilter: val,
      },
    };
    sessionStorage.setItem(`pageFilter`, JSON.stringify(PageFilter));
  };

  handleChange = (key, date) => {
    this.storeRangeFilter(date);

    this.props.dispatch(
      listServiceLine({
        Json: JSON.stringify({
          ServiceLine: [
            {
              Search: this.state.searchStr,
              FilterBy: this.state.filterBy,
              StartDate: date[0],
              EndDate: date[1],
            },
          ],
        }),
      }),
    );
    this.setState({
      StartDate: date[0],
      EndDate: date[1],
    });
  };
  onSearchChange = value => {
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listServiceLine({
        json: JSON.stringify({
          ServiceLine: [
            {
              Search: value,
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
              FilterBy: this.state.filterBy,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const tempColumns = [
      {
        title: 'Patient Name',
        dataIndex: 'ClientName',
        key: 'ClientName',
        // fixed: 'left',
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Type',
        dataIndex: 'AppointmentType',
        key: 'AppointmentType',
        // fixed: 'left',
        sorter: (a, b) => isComparer(a.AppointmentType, b.AppointmentType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Provider Name',
        dataIndex: 'PractionerName',
        key: 'PractionerName',
        sorter: (a, b) => isComparer(a.PractionerName, b.PractionerName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Insurance Name ',
        dataIndex: 'InsuranceName',
        key: 'InsuranceName',
        sorter: (a, b) => isComparer(a.InsuranceName, b.InsuranceName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Service Code',
        dataIndex: 'ServiceCode',
        key: 'ServiceCode',
        width: '190',
        sorter: (a, b) => isComparer(a.ServiceCode, b.ServiceCode),
        sortDirections: ['descend', 'ascend'],
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onRowClick(event, record, rowIndex),
        //   };
        // },
      },
      {
        title: (
          <span>
            Appointment <br />
            Date
          </span>
        ),
        dataIndex: 'SessionDate',
        key: 'SessionDate',
        sorter: (a, b) => isDateComparer(a.SessionDateForSort, b.SessionDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Start Time',
        dataIndex: 'StartTime',
        key: 'StartTime',
        sorter: (a, b) => isComparer(a.StartTime, b.StartTime),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Time',
        dataIndex: 'EndTime',
        key: 'EndTime',
        sorter: (a, b) => isComparer(a.EndTime, b.EndTime),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Supervisor',
        dataIndex: 'SupervisorName',
        key: 'SupervisorName',
        sorter: (a, b) => isComparer(a.SupervisorName, b.SupervisorName),
        sortDirections: ['descend', 'ascend'],
        width: '190',
      },
      {
        title: (
          <span>
            Approved <br />
            For Billing
          </span>
        ),
        dataIndex: 'IsApprovedForBilling',
        key: 'IsApprovedForBilling',
        sorter: (a, b) => isComparer(a.IsApprovedForBilling, b.IsApprovedForBilling),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span>{record.IsApprovedForBilling ? 'Yes' : 'No'}</span>
            </div>
          );
        },
      },
      {
        title: (
          <span>
            Approved <br />
            For Batching
          </span>
        ),
        dataIndex: 'IsApprovedForBatching',
        key: 'IsApprovedForBatching',
        sorter: (a, b) => isComparer(a.IsApprovedForBatching, b.IsApprovedForBatching),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span>{record.IsApprovedForBatching ? 'Yes' : 'No'}</span>
            </div>
          );
        },
      },
      {
        title: 'Program',
        dataIndex: 'ProgramDescription',
        key: 'ProgramDescription',
        sorter: (a, b) => isComparer(a.ProgramDescription, b.ProgramDescription),
        sortDirections: ['descend', 'ascend'],
        width: '200',
      },
      {
        title: 'Duration',
        dataIndex: 'DurationInMin',
        key: 'DurationInMin',
        sorter: (a, b) => isComparer(a.DurationInMin, b.DurationInMin),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => {
          return (
            <div className="">
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && timeConvert(text)}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'x',
        fixed: 'right',
        width: '50px',
        render: (text, record, index) => {
          if (!this.props.isReadOnlyUser) {
            return (
              <>
                <div class="tools-grid">
                  <div className="mr-2">
                    <span className="start-btn">
                      <Tooltip placement="top" title="Edit">
                        <img
                          src="images/shape.svg"
                          width="14px"
                          style={{ cursor: 'pointer' }}
                          onClick={event => {
                            this.setState({
                              createAndEditGroupModal: true,
                              AppointmentId: record.AppointmentId,
                              ServiceLineId: record.ServiceLineId,
                              ClientId: record.ClientId,
                            });
                            // this.props.dispatch(
                            //   getServiceLine({
                            //     json: JSON.stringify({
                            //       ServiceLine: [
                            //         {
                            //           AppointmentId: record.AppointmentId,
                            //           ServiceLineId: record.ServiceLineId,
                            //         },
                            //       ],
                            //     }),
                            //   }),
                            // );
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const { ServiceList, ServiceLineInfo, header } = this.state;
    const { FilterBy } = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid my-5">
          <div className="mb-3">
            <div className="row mt-5 d-flex align-items-center">
              <div className="col-md-12 col-xl-2">
                {' '}
                <h4 className="">SERVICES</h4>
              </div>
              <div class="col-md-5 col-xl-3">
                <ReactRangePicker
                  value={[
                    moment(`${this.state.StartDate}`, 'MM-DD-YYYY'),
                    moment(`${this.state.EndDate}`, 'MM-DD-YYYY'),
                  ]}
                  format={'MM-DD-YYYY'}
                  // value={[
                  //   moment(this.state.StartDate, 'MM-DD-YYYY'),
                  //   moment(this.state.EndDate, 'MM-DD-YYYY'),
                  // ]}

                  // defaultValue={[moment(Startdate, dateFormat), moment(Enddate, dateFormat)]}
                  handleChange={this.handleChange}
                />
              </div>
              <div className="col-md-4 col-xl-3">
                <div class="form-group call-schedule service-filter">
                  <ReduxSelect
                    options={FilterBy}
                    onChange={val => {
                      this.storeSelectFilter(val);
                      this.props.dispatch(
                        listServiceLine({
                          Json: JSON.stringify({
                            ServiceLine: [
                              {
                                Search: this.state.searchStr,
                                FilterBy: val,
                                StartDate: this.state.StartDate,
                                EndDate: this.state.EndDate,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        filterBy: val,
                      });
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={this.state.filterBy}
                    placeholder="Filter By"
                  />
                </div>
              </div>

              <div className="col-md-8 col-xl-4 d-flex align-items-center">
                <GridSearch onSearchChange={this.onSearchChange} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 enrollment-form mt-1">
              <div className="row rcm-subtab-table-block">
                <Loader2 loading={this.props.isFetching} />
                <div className="col-12 rcm-tools-table">
                  <PersistTableSort
                    name={this.props.name}
                    // bordered
                    columns={tempColumns}
                    dataSource={ServiceList}
                    size={'middle'}
                    scroll={{ x: 1950 }}
                    pagination={{
                      itemRender: (page, type, originalElement) => {
                        return (
                          <div className="dataTables_wrapper no-footer">
                            <div className="dataTables_paginate paging_simple_numbers">
                              {type == 'prev' ? (
                                <a className="paginate_button previous">Previous</a>
                              ) : type == 'next' ? (
                                <a className="paginate_button next">Next</a>
                              ) : (
                                <a className="paginate_button ">{page}</a>
                              )}
                            </div>
                          </div>
                        );
                      },
                      showSizeChanger: true,
                      // hideOnSinglePage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
        <CustomModal
          isOpen={this.state.isRemoveModelOpen}
          header={Delete_Confirmation_Msg}
          yesAction={() => {
            this.Removetoggle();
          }}
          yesText="submit"
          noText="cancel"
          noAction={() => {
            this.Removetoggle();
          }}
        />
        <Modal
          zIndex={100}
          visible={this.state.createAndEditGroupModal}
          footer={null}
          closable={false}
          width={980}
          destroyOnClose={true}
        >
          <ReduxServiceLineModal
            // ServiceCodeList={this.props.ServiceCodeList}
            // ServiceDetails={this.state.ServiceDetails}
            // getTotalCost={this.getTotalCost}
            // downloadAsPdf={this.downloadAsPdf}
            // deleteMoreCode={this.deleteMoreCode}
            // onSaveClick={this.onSubmit}
            // isFetchingServiceLine={this.props.isFetchingServiceLine}
            // isFileDownloading={this.state.isFileDownloading}
            // initialValues={{
            //   ServiceCodes: this.state.ServiceDetails,
            //   DiagnosisCode:
            //     ServiceLineInfo.DiagnosisListId &&
            //     ServiceLineInfo.DiagnosisListId.split(',') &&
            //     ServiceLineInfo.DiagnosisListId.split(',').map(Number),
            //   SupervisorId: ServiceLineInfo.SupervisorId,
            //   IsApprovedForBilling: ServiceLineInfo.IsApprovedForBilling,
            //   IsApprovedForBatching: ServiceLineInfo.IsApprovedForBatching,
            //   IsPartofBundledService: ServiceLineInfo.IsPartofBundledService,
            //   BatchSentDate: moment(ServiceLineInfo.BatchSentDate).format('YYYY-MM-DD'),
            //   StatusId: ServiceLineInfo.StatusId,
            // }}
            // ServiceLineInfo={ServiceLineInfo}
            // Supervisor={this.props.Supervisor}
            // UnitOfMeasurement={this.props.UnitOfMeasurement}
            // ServiceLineStatus={this.props.ServiceLineStatus}
            // Delete={() => {
            //   this.Removetoggle();
            // }}
            filterData={{
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
              FilterBy: this.state.filterBy,
            }}
            AppointmentId={this.state.AppointmentId}
            ServiceLineId={this.state.ServiceLineId}
            ClientId={this.state.ClientId}
            onCancel={() => {
              this.setState({
                createAndEditGroupModal: false,
              });
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}
const AddMoreCode = ({
  fields,
  meta: { touched, error },
  disable,
  existingRows,
  ServiceCodeList,
  ServiceCodes,
  UnitOfMeasurement,
  getTotalCost,
  ClientId,
  AppointmentNoteId,
  deleteMoreCode,
  dispatch,
}) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <>
            <div
              class="row"
              style={{
                background: '#F8F8F8',
                margin: '5px',
                padding: '10px 0px',
                alignItems: 'center',
              }}
            >
              <div class="col-5">
                <div class="form-group service_line_code">
                  <Field
                    name={`${field}.ServiceCodeId`}
                    // validate={validate.required}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Code`}
                    options={ServiceCodeList}
                    fieldName={'Code'}
                    disabled={disable}
                    onChange={value => {
                      dispatch(
                        getServiceLineCost({
                          json: JSON.stringify({
                            ServiceLine: [
                              {
                                AppointmentNoteId: AppointmentNoteId,
                                ClientId: ClientId,
                                ServiceCodeId: value,
                                ReactIndex: index,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div class="col-7">
                <div className="service-code p-2">
                  <p className="code-description-lable">Description</p>
                  <p className="code-description-val">
                    {ServiceCodes &&
                      ServiceCodes[index] &&
                      ServiceCodes[index].ServiceCodeDescription}
                  </p>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <Field
                    name={`${field}.UnitsId`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Unit'}
                    min={1}
                    max={999}
                    // negativeNumberBlocked={true}
                    controlledMaxAge={true}
                    style={{ background: '#F8F8F8' }}
                    onBlur={event => {
                      getTotalCost(event, index);
                    }}
                  />
                  <label class="placeholder-label">Units</label>
                </div>
              </div>
              <div class="col-3 pr-0">
                <div class="form-group service_line_code">
                  <Field
                    name={`${field}.UnitOfMeasurementId`}
                    type="text"
                    component={ReduxSelect}
                    placeholder={`Unit of Measure`}
                    options={UnitOfMeasurement}
                    fieldName={'Unit of Measure'}
                    disabled={true}
                  />
                </div>
              </div>
              <div class="col-2 px-0">
                <div class="form-group">
                  <Field
                    name={`${field}.CostPerUnit`}
                    type="number"
                    component={ReduxInput}
                    fieldName={'Cost per Unit'}
                    min={0}
                    max={999}
                    negativeNumberBlocked={true}
                    controlledMaxAge={true}
                    style={{ background: '#F8F8F8' }}
                    disabled={true}
                  />
                  <label class="placeholder-label">Cost per Unit</label>
                </div>
              </div>

              <div class="col-2 px-0">
                <div class="form-group">
                  <Field
                    name={`${field}.TotalCost`}
                    type="text"
                    component={ReduxInput}
                    fieldName={'Cost'}
                    style={{ background: '#F8F8F8' }}
                    disabled={true}
                  />
                  <label class="placeholder-label">Total Cost </label>
                </div>
              </div>
              <div class="col-9">
                <div class="form-group">
                  <Field
                    name={`${field}.IsExcludeBundle`}
                    type="text"
                    component={ReduxCheckbox}
                    label={'Exclude this Service Line from Bundled Service'}
                  />
                </div>
              </div>
              <div class="col-3">
                {fields.length > 1 && (
                  <a
                    class="delete-contact"
                    onClick={() => {
                      fields.remove(index);
                      deleteMoreCode(index);
                    }}
                    disabled={disable}
                  >
                    <i class="fas fa-trash" />
                  </a>
                )}
              </div>
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-1 pb-1">
          {/* <a class="add-contact" onClick={() => fields.push({ country: 'USA' })} disabled={disable}> */}
          <a
            class="add-contact"
            onClick={() => fields.push({})}
            disabled={disable}
            style={{ marginLeft: 'unset' }}
          >
            <label style={{ cursor: 'pointer' }}>
              + Add {fields.length == 0 ? 'Code' : 'Another Code'}
            </label>
          </a>
        </div>
      </div>
    </>
  );
};
class ServiceLineForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notesDrawerVisible: false,
    };
  }
  showDrawer = (
    appointmentId,
    // isGroup,
    // groupAppointmentId
  ) => {
    this.setState({
      notesDrawerVisible: true,
      appointmentId: appointmentId,
      // isGroup: isGroup,
      // groupAppointmentId: groupAppointmentId,
    });
  };

  onCloseDrawer = () => {
    this.setState({
      notesDrawerVisible: false,
    });
  };
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  render() {
    const { notesDrawerVisible, isModelOpen, header } = this.state;
    const {
      handleSubmit,
      onSaveClick,
      disable,
      onCancel,
      ServiceCodeList,
      ServiceLineInfo,
      dispatch,
      isFetchingServiceLine,
      Supervisor,
      deleteMoreCode,
      downloadAsPdf,
      isFileDownloading,
      // AppointmentId,
      ServiceLineStatus,
    } = this.props;
    const {
      AppointmentId,
      DiagnosisInfo,
      EndTime,
      PlaceOfServiceId,
      ProgramDescription,
      ServiceCodeDescription,
      ServiceCodeId,
      SessionDate,
      SessionTime,
      SessionType,
      StartTime,
      SupervisorId,
      SupervisorName,
      PractitionerName,
      ClientName,
      ClientId,
      DisplayClientId,
      PlaceOfServiceName,
      FacilityName,
      StatusDescription,
      Diagnosis,
      DurationInMin,
      ServiceDetails,
      ServiceLineId,
      Note,
    } = ServiceLineInfo;
    const DiagnosisOption =
      (Diagnosis &&
        Diagnosis.map(item => {
          return {
            label: item.DiagnosisDictDesc,
            value: item.DiagnosisListId,
            description: item.FullDescription,
          };
        })) ||
      [];
    return (
      <>
        <form onSubmit={handleSubmit(onSaveClick)}>
          {/* <div class="modal-header flex-wrap border-bottom-0 "></div> */}
          <div class="modal-body rcm-panel enrollment-form py-0">
            <Loader2 loading={isFetchingServiceLine} />
            <div className="row">
              <div className="col-4" style={{ background: '#F8F8F8' }}>
                <div className="service-line" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                  <h4 class="title col-12 text-left">View/edit service</h4>
                  <div className="block">
                    <span className="name">{ClientName}</span>
                    <p className="enrollment"> {DisplayClientId}</p>
                  </div>
                  <div className="service-info-block px-3">
                    <div className="d-flex align-items-center">
                      <img src="images/icons/grey_calendar.svg" width="24px" className="mr-2" />
                      {SessionDate}
                    </div>
                    <div className="d-flex align-items-center">
                      <img src="images/icons/grey_clock.svg" width="24px" className="mr-2" />
                      <span className="mr-2">{SessionTime} </span>{' '}
                      <span
                        style={{
                          border: ' 1px solid #D8D8D8',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          color: '#828282',
                          padding: '0px 5px',
                        }}
                      >
                        {DurationInMin && timeConvert(DurationInMin)}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src="images/icons/location_black.svg" width="24px" className="mr-2" />
                      {FacilityName}
                    </div>
                    <div className="d-flex align-items-center">
                      <img src="images/icons/user.svg" width="24px" className="mr-2" />
                      {SessionType}
                    </div>
                    <div className="d-flex align-items-center">
                      <img
                        src="images/icons/grommet-icons_status-good.svg"
                        width="22px"
                        className="mr-2"
                      />
                      {StatusDescription}
                    </div>
                  </div>

                  <div className="info-block">
                    {/* <div className="block">
                      <span className="label">{'Notes'}</span>
                      <p className="value"> {Note}</p>
                    </div> */}
                    <div className="block">
                      <span className="label">{'Place of Service'}</span>
                      <p className="value"> {PlaceOfServiceName}</p>
                    </div>
                    <div className="block">
                      <span className="label">{'Program'}</span>
                      <p className="value"> {ProgramDescription}</p>
                    </div>
                    <div className="block">
                      <span className="label">{'Staff'}</span>
                      <p className="value"> {PractitionerName}</p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      this.showDrawer();
                    }}
                    style={{
                      color: ' rgb(74, 176, 254)',
                      cursor: 'pointer',
                      background: 'transparent',
                      border: 'none',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      marginTop: '5px',
                      padding: '5px',
                    }}
                  >
                    Notes
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="" style={{ paddingTop: '25px', paddingBottom: '30px' }}>
                  <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                    ×
                  </button>
                  <div class="row my-4">
                    <div class="col-12 ">
                      <div className="row">
                        <div class="col-6 d-flex align-items-center">
                          <h4>Service Details</h4>
                        </div>
                        <div>
                          <Button
                            loading={isFileDownloading}
                            type="link"
                            onClick={() => {
                              downloadAsPdf();
                            }}
                          >
                            Download Claims 1500 Form
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <FieldArray
                        name="ServiceCodes"
                        disable={disable}
                        component={AddMoreCode}
                        deleteMoreCode={deleteMoreCode}
                        ServiceCodeList={ServiceCodeList}
                        ClientId={ServiceLineInfo.ClientId}
                        AppointmentNoteId={ServiceLineInfo.AppointmentNoteId}
                        ServiceCodes={this.props.ServiceDetails}
                        getTotalCost={this.props.getTotalCost}
                        UnitOfMeasurement={this.props.UnitOfMeasurement}
                        // existingRows={existingServiceCodesRows}
                        {...this.props}
                      />
                    </div>
                    <div class="col-12 my-3">
                      <h6>Diagnosis</h6>
                      <div
                        class="col-12"
                        style={{
                          overflowY: 'scroll',
                          maxHeight: '350px',
                        }}
                      >
                        <div
                          class="form-group"
                          style={{
                            flexFlow: 'wrap',
                            display: 'flex',
                          }}
                        >
                          <Field
                            name="DiagnosisCode"
                            component={CheckboxGroup}
                            col={24}
                            widthClassName={'w-75'}
                            options={DiagnosisOption}
                            fullDescription={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <Field
                          name="SupervisorId"
                          type="text"
                          placeholder="Supervisor"
                          component={ReduxSelect}
                          options={Supervisor}
                          disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <Field
                          name="StatusId"
                          type="text"
                          placeholder="Service Line Status"
                          component={ReduxSelect}
                          options={ServiceLineStatus}
                          disabled={disable}
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <Field
                          name={`IsApprovedForBilling`}
                          type="text"
                          component={ReduxCheckbox}
                          label={'Approved For Billing '}
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <Field
                          name={`IsApprovedForBatching`}
                          type="text"
                          component={ReduxCheckbox}
                          label={'Approved For Batching '}
                        />
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form-group">
                        <Field
                          name={`BatchSentDate`}
                          type="text"
                          component={ReduxDateInput}
                          label={'Batch Sent Date '}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group">
                        <Field
                          name={`IsPartofBundledService`}
                          type="text"
                          component={ReduxCheckbox}
                          disabled={true}
                          label={'This service is part of bundled service'}
                        />
                      </div>
                    </div>
                    <div class="col-4 mt-2 text-right">
                      <input type="submit" class="btn px-5" value={'Update'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {notesDrawerVisible && (
          <NotesDrawer
            OnCloseDrawer={this.onCloseDrawer}
            NotesDrawerVisible={notesDrawerVisible}
            AppointmentId={this.props.AppointmentId}
            // IsGroup={isGroup}
            ClientId={ClientId}
            // GroupAppointmentId={groupAppointmentId}
            toggle={this.toggle}
          />
        )}
        {isModelOpen && (
          <CustomModal
            isOpen={isModelOpen}
            header={header}
            yesAction={() => {
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        )}
      </>
    );
  }
}

const ReduxServiceLineForm = reduxForm({
  form: 'ClientserviceLineForm',
  enableReinitialize: true,
})(connect()(withRouter(ServiceLineForm)));

const mapStateToPropsParent = state => {
  const {
    upsert_service_line,
    list_service_line,
    get_service_line,
    combos,
    get_service_line_cost,
  } = state;
  const { data: serviceLineCodeInfoData } = get_service_line_cost;
  const { data: serviceListData, isFetching } = list_service_line;
  const { data: serviceInfoData, isFetching: isFetchingServiceLine } = get_service_line;

  let ServiceCodeList = [];
  let Supervisor = [];
  let UnitOfMeasurement = [];
  let ServiceLineStatus = [];

  if (combos.data && combos.data['RCM_ServiceCode']) {
    ServiceCodeList = combos.data['RCM_ServiceCode'];
    Supervisor = combos.data['Supervisor'];
    UnitOfMeasurement = combos.data['UnitOfMeasurement'] || [];
    ServiceLineStatus = combos.data['ServiceLineStatus'] || [];
  }

  let ServiceList = [];
  let ServiceInfo = null;
  let serviceLineCodeInfo = null;
  let FilterBy = [];
  let isInserted = false;
  let isRemoved = false;

  if (serviceListData && serviceListData.ServiceLine) {
    ServiceList = serviceListData.ServiceLine || [];
  }
  if (serviceListData && serviceListData.FilterBy) {
    FilterBy = serviceListData.FilterBy || [];
  }

  if (serviceInfoData) {
    ServiceInfo = serviceInfoData || {};
  }

  if (serviceLineCodeInfoData && serviceLineCodeInfoData.ServiceLine) {
    serviceLineCodeInfo = serviceLineCodeInfoData.ServiceLine[0] || [];
  }
  if (upsert_service_line.data && upsert_service_line.data.success) {
    isInserted = true;
  }
  const existingServiceCodesRows = selector(state, 'ServiceCodes');
  const SupervisorIdValue = selector(state, 'SupervisorId');

  return {
    ServiceCodeList: ServiceCodeList,
    serviceLineCodeInfo: serviceLineCodeInfo,
    ServiceList: ServiceList,
    ServiceInfo: ServiceInfo,
    isInserted,
    isFetching,
    isFetchingServiceLine,
    Supervisor,
    existingServiceCodesRows,
    UnitOfMeasurement,
    SupervisorIdValue,
    FilterBy,
    ServiceLineStatus,
  };
};
export default connect(mapStateToPropsParent)(ServiceLine);
const selector = formValueSelector('ClientserviceLineForm');
