import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader, { Loader2 } from '../../../../../Components/Loader';
import SubmitButton from '../../../../../Components/submitButton';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
} from '../../../../../component/custom-redux-components/index.js';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { allergenSearch, resetAllergenSearch } from '../../../../../store/actions';

class AllergenForm extends Component {
  constructor(props) {
    const { ClientAllergyId, Allergy, OnsetDate, Reactions, Severity, ReactionIds, SeverityId } =
      props.allergyRecord || {};

    super(props);
    this.state = {
      allergenValue: Allergy || '',
      ReactionId: ReactionIds || '',
      OnsetDate: OnsetDate ? moment(OnsetDate).toDate() : '',
      SeverityId: parseInt(SeverityId) || '',
      IsGroup: false,
      RcopiaId: '',
    };
  }
  handleChange = (key, date, errorKey) => {
    this.setState({
      [key]: date,
      [errorKey]: false,
    });
  };

  fnSaveAllergen = () => {
    let {
      ReactionId,
      SeverityId,
      OnsetDate,
      allergenValue,
      rcopiaIDValue,
      FirstDataBankMedID,
      IsGroup,
      RcopiaId,
    } = this.state;
    let ClientAllergyId;
    if (this.props.allergyRecord) {
      ClientAllergyId = this.props.allergyRecord.ClientAllergyId;
    }
    if (!ReactionId || ReactionId.length <= 0 || !SeverityId) {
      if (!ReactionId || ReactionId.length <= 0) {
        this.setState({
          isReactionIdReq: true,
        });
      } else if (!SeverityId) {
        this.setState({
          isSeverityIdReq: true,
        });
      }
    } else {
      this.props.onSaveClick({
        ReactionId,
        SeverityId,
        OnsetDate,
        allergenValue,
        rcopiaIDValue,
        FirstDataBankMedID,
        ClientAllergyId,
        IsGroup,
        RcopiaId,
      });
      this.setState({
        allergenValue: null,
        ReactionId: null,
        SeverityId: null,
        OnsetDate: null,
        FirstDataBankMedID: null,
        IsGroup: false,
        RcopiaId: null,
      });
      this.props.dispatch(resetAllergenSearch());
      this.props.onCancel();
    }
  };
  render() {
    const {
      onSaveClick,
      disable,
      onOk,
      onCancel,
      viewModalType,
      AllergiesList,
      AllergyReaction,
      AllergySeverity,
      isFetching,
      allergyOptions,
      isEPrescribe,
      isUpsertFetching,
    } = this.props;
    const { ReactionId, SeverityId, OnsetDate, allergenValue, IsGroup, RcopiaId } = this.state;
    let ClientAllergyId;
    if (this.props.allergyRecord) {
      ClientAllergyId = this.props.allergyRecord.ClientAllergyId;
    }
    const ExampleCustomInput = ({ value, onClick }) => (
      <>
        <input
          type="text"
          class={`form-control ${value && 'not-empty'}`}
          placeholder=""
          value={value}
          onClick={onClick}
        />
        <label class="placeholder-label">Start date</label>
        {!this.state.StartDate && <span style={{ color: 'red' }}>{this.state.startDateError}</span>}
      </>
    );
    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            onClick={() => {
              this.props.dispatch(resetAllergenSearch());
              onCancel();
            }}
          >
            ×
          </button>
          <h4 class="modal-title col-12 text-left px-0">
            {ClientAllergyId ? 'Edit' : 'Add'} Allergen
          </h4>
          {/* <h4 class="modal-title col-12 text-left">{moment(dateClicked).format('Do MMM, YYYY')}</h4> */}
        </div>
        <div class="modal-body rcm-panel enrollment-form">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <input
                  disabled={this.props.disabled}
                  name={`Allergen`}
                  type="text"
                  class={`form-control ${allergenValue && 'not-empty'}`}
                  value={allergenValue}
                  onKeyUp={() => {
                    if (this.state.allergenValue.length > 1)
                      this.props.handleAllergenChange(this.state.allergenValue);
                  }}
                  onChange={event => {
                    let allergenValue = event.target.value;
                    this.setState({
                      allergenValue: allergenValue,
                    });
                    // this.props.handleAllergenChange(allergenValue);
                    // this.props.dispatch(
                    //   allergenSearch({ ClientId: this.props.ClientId, SearchStr: allergenValue }),
                    // );
                    // clearTimeout(timer);
                    // timer = setTimeout(() => {
                    //   this.props.dispatch(
                    //     allergenSearch({ ClientId: this.props.ClientId, SearchStr: allergenValue }),
                    //   );
                    // }, 500);
                    // let inputName = `${index}.docName`;
                    // this.documentNameChange(list, index, docName, inputName);
                  }}
                />
                <label className="placeholder-label">Search Allergen</label>
                <span class="small-info">Enter minimum of 2 characters</span>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <DatePicker
                  selected={OnsetDate}
                  onChange={date => this.handleChange('OnsetDate', date)}
                  className="form-control datepicker"
                  customInput={<ExampleCustomInput />}
                />
                <i class="far fa-calendar-alt form-control-icon" />
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <ReduxSelect
                  options={AllergySeverity}
                  onChange={severity =>
                    this.handleChange('SeverityId', severity, 'isSeverityIdReq')
                  }
                  className="basic-single"
                  classNamePrefix="select"
                  value={SeverityId}
                  placeholder="Severity*"
                />
                {this.state.isSeverityIdReq && (
                  <span className="error-text">Please select Severity</span>
                )}
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <ReduxSelect
                  options={AllergyReaction}
                  onChange={reaction =>
                    this.handleChange('ReactionId', reaction, 'isReactionIdReq')
                  }
                  className="basic-single"
                  classNamePrefix="select"
                  value={ReactionId}
                  placeholder="Reaction(s) (one or more)*"
                  isMulti
                />
                {this.state.isReactionIdReq && (
                  <span className="error-text">Please select Reaction</span>
                )}
              </div>
            </div>
            <div class="col-12 mt-4" style={{ position: 'relative' }}>
              <Loader2 loading={isFetching} />
            </div>

            <div className="col-12 my-1">
              {this.state.allergenValue && (
                <>
                  Use Text :
                  <span
                    className="searched-item"
                    style={{ backgroundColor: 'rgba(74, 176, 254, 1)', color: '#fff' }}
                    onClick={() => {
                      this.setState({
                        allergenValue: this.state.allergenValue,
                      });
                    }}
                    id={null}
                  >
                    {this.state.allergenValue}
                  </span>
                </>
              )}
            </div>

            {AllergiesList && isEPrescribe && (
              <div class="col-12 mt-2 mb-3 allergies-section">
                <div className="row">
                  {/* BrandName: "Aqua Care" GenericName: "urea" RcopiaID: "5033" */}
                  {AllergiesList.map(item => (
                    <div className="col-4 mb-3">
                      <span
                        className="searched-item"
                        onClick={() => {
                          this.setState({
                            allergenValue: item.BrandName,
                            rcopiaIDValue: item.RcopiaID,
                            FirstDataBankMedID: item.FirstDataBankMedID,
                            IsGroup: item.IsGroup,
                            RcopiaId: item.RcopiaId,
                          });
                        }}
                        id={item.RcopiaID}
                      >
                        {item.BrandName}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {allergyOptions && !isEPrescribe && (
              <div class="col-12 mt-2 mb-3 allergies-section">
                <div className="row">
                  {/* BrandName: "Aqua Care" GenericName: "urea" RcopiaID: "5033" */}
                  {allergyOptions.map(item => (
                    <div className="col-4 mb-3">
                      <span
                        className="searched-item"
                        onClick={() => {
                          this.setState({
                            allergenValue: item.GenericName,
                            // rcopiaIDValue: item.RcopiaID,
                            // FirstDataBankMedID: item.FirstDataBankMedID,
                            // IsGroup: item.IsGroup,
                            // RcopiaId: item.RcopiaId,
                          });
                        }}
                        // id={item.RcopiaID}
                      >
                        {item.GenericName}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div class="col-12 text-right mb-2">
              {/* <input
                type="button"
                class="btn px-5"
                value={'Add'}
                disabled={!this.state.allergenValue}
                onClick={this.fnSaveAllergen}
                style={!this.state.allergenValue ? { background: 'rgba(0,0,0,0.5)' } : undefined}
                // onClick={() => {
                //   onSaveClick({ ReactionId, SeverityId, OnsetDate, allergenValue });
                //   this.setState({
                //     allergenValue: null,
                //     ReactionId: null,
                //     SeverityId: null,
                //     OnsetDate: null,
                //   });
                //   this.props.dispatch(resetAllergenSearch());
                //   onCancel();
                // }}
              /> */}
              <SubmitButton
                loading={ isUpsertFetching}
                value={'Add'}
                disabled={!this.state.allergenValue}
                onClick={this.fnSaveAllergen}
                style={!this.state.allergenValue ? { background: 'rgba(0,0,0,0.5)' } : undefined}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ allergen_search, medicate_combos, allergy_options_get }) => {
  const { data: allergenData, isFetching } = allergen_search;
  const { data } = medicate_combos;
  const { data: allergyOptionsList } = allergy_options_get;

  let AllergiesList;
  let AllergyReaction = [];
  let allergyOptions;
  let AllergySeverity = [];
  if (allergyOptionsList) {
    allergyOptions = allergyOptionsList.data;
  }
  if (allergenData && allergenData.result) {
    AllergiesList = allergenData.result;
  }
  if (data) {
    AllergyReaction = data.AllergyReaction;
    AllergySeverity = data.AllergySeverity;
  }
  return {
    AllergiesList: AllergiesList,
    AllergyReaction,
    AllergySeverity,
    isFetching,
    allergyOptions,
  };
};
export default connect(mapStateToProps)(AllergenForm);
