import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ChatFormatDate } from '../../../../Components/FormatDate';
import SpeechToText from './SpeechToText';
import moment from 'moment';
import {
  clientembeddingprepare,
  askQuestion,
  resetAskQuestion,
  chatGet,
  resetChatGet,
  userFeatureFlagGet,
} from '../../../../store/actions';
import { stringDate } from '../../../../util/date';
import { CommonLicenceScreens } from '../../../../Screens/licenceScreens';

let id = 0;
let msgsLength;
const VirtaulAssistant = props => {
  // let messages = JSON.parse(localStorage.getItem(props.clientId) || '[]');
  let UserId = localStorage.getItem('userId');
  let UserName = localStorage.getItem('userName');
  let userProfile = localStorage.getItem('profileImgUrl');
  const dispatch = useDispatch();

  const [isMute, setIsMute] = useState(false);
  const [message, setMessage] = useState('');
  const [chatdate, setChatDtae] = useState(moment().format('DD MMMM, YYYY'));
  const [listMessage, setListMessage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isFinal, setIsFinal] = useState(false);
  const messagesEndRef = useRef();
  // const ref = useRef(null);

  const {
    CenterId,
    isFetchingResponse,
    chatDataList,
    askQuestionResponse,
    askQuestionError,
  } = useSelector(
    ({
      default_values: { data },
      chat_get: { data: chatData },
      ask_question: { data: askQuestionData, isFetching, error },
    }) => {
      return {
        CenterId: data && data.centerId,
        askQuestionResponse: askQuestionData,
        isFetchingResponse: isFetching,
        askQuestionError: error,
        chatDataList: chatData && chatData.data,
      };
    },
    shallowEqual,
  );

  const scrollToBottom = () => {
    if (messagesEndRef.current && messagesEndRef.current.scrollHeight) {
      let scroll = messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;
      return messagesEndRef.current.scrollTo({
        top: scroll,
        // behavior: 'smooth',
      });
    }
  };

  const handleDictate = (result, isFinal) => {
    let e = {
      type: 'click',
    };
    if (result) {
      let data;
      if (message) {
        data = `${message} ${result}`;
      } else {
        data = result;
      }
      setMessage(data);
    }
    if (isFinal == 'true') {
      setIsFinal(true);
    }
  };

  useEffect(() => {
    const hostName = window.location.hostname;
    window.scrollTo({
      top: 220,
      behavior: 'smooth',
    });
    // scrollToBottom();
    // if (messages.length) setListMessage(messages);
    if (CenterId !== null) {
      let data = {
        ClientId: props.clientId,
        CenterId: parseInt(CenterId),
        UserId: parseInt({}),
      };
      setMessage('');
      let url;
      dispatch(clientembeddingprepare(data));
      dispatch(
        chatGet({
          ClientId: props.clientId,
        }),
      );
      // if (hostName == 'drstage.azurewebsites.net') {
      //   url = 'https://chatspdrstage.azurewebsites.net/api/InitiateChat';
      // } else if (hostName == 'gtcs.azurewebsites.net' || hostName == 'provider.soberpeer.com') {
      //   url = 'https://chatsplivetemp.azurewebsites.net/api/InitiateChat';
      // } else {
      //   url = 'https://chatspdev.azurewebsites.net/api/InitiateChat';
      // }
      // Axios.post(url, data)
      //   .then(response => {
      //     console.log(response, 'this is response');
      //   })
      //   .catch(error => {
      //     alert(error && error.response && error.response.data);
      //     console.log(error, 'this is error');
      //   });
      scrollToBottom();
    }
  }, [CenterId]);
  useEffect(() => {
    scrollToBottom();
  }, [message, listMessage]);
  // useEffect(() => {
  //   localStorage.setItem(props.clientId, JSON.stringify(listMessage));
  //   scrollToBottom();
  //   // let newArray = listMessage.map(item => false);
  //   // let newestArray = newArray.filter(item => item === false && item);
  //   // setIsSpeaking(oldArray => [...newArray]);
  //   msgsLength = listMessage.length;
  // }, [listMessage]);

  useEffect(() => {
    if (askQuestionResponse) {
      setLoader(isFetchingResponse);
      listMessage.pop();
      let newArray = {
        Response: askQuestionResponse.Response,
        UserQuery: askQuestionResponse.UserQuery,
      };
      setListMessage(oldArray => [...oldArray, newArray]);
      scrollToBottom();
      if (!isMute) {
        Speech(askQuestionResponse.Response);
      }
    }
    if (askQuestionError && askQuestionError.StatusCode == 500) {
      setLoader(isFetchingResponse);
    }
  }, [askQuestionResponse, askQuestionError]);

  useEffect(() => {
    if (chatDataList && chatDataList.length) {
      setListMessage(chatDataList);
      scrollToBottom();
    }
  }, [chatDataList]);

  useEffect(() => {
    if (isFinal) {
      let e = {
        type: 'click',
      };
      assignMessage(e);
      setIsFinal(false);
    }
  }, [isFinal]);
  useEffect(() => {
    dispatch(resetChatGet({}));
    dispatch(resetAskQuestion({}));
    StopSpeech(true);
    return () => {
      StopSpeech(true);
      dispatch(resetChatGet({}));
      dispatch(resetAskQuestion({}));
    };
  }, []);

  useEffect(() => {
    if (props.AugmentedAIStatusData && props.AugmentedAIStatusData.StudentId == null) {
      dispatch(
        userFeatureFlagGet({
          JsonInput: JSON.stringify({ Center: { StudentId: props.clientId } }),
        }),
      );
    }
  }, [props.AugmentedAIStatusData]);

  const assignMessage = e => {
    if (
      (!loader && e.keyCode === 13 && message.trim().length) ||
      (e.type === 'click' && message.trim().length)
    ) {
      setLoader(true);
      // const hostName = window.location.hostname;
      let data = {
        UserQuery: message,
        // Guid: uuidv4(),
        // ClientId: props.clientId,
        ClientId: props.clientId,
        // CenterId: parseInt(CenterId),
        // UserId: parseInt(UserId),
      };
      setListMessage(oldArray => [...oldArray, data]);
      // listMessage.push({
      //   id: id++,
      //   message: message,
      //   time: `${moment().format('hh:mm a')}`,
      //   // date: date,
      // });
      // scrollToBottom();
      setMessage('');
      let url;
      // if (hostName == 'drstage.azurewebsites.net') {
      //   url = 'https://chatspdrstage.azurewebsites.net/api/askquestion';
      // } else if (hostName == 'gtcs.azurewebsites.net' || hostName == 'provider.soberpeer.com') {
      //   url = 'https://chatsplivetemp.azurewebsites.net/api/askquestion';
      // } else {
      //   url = 'https://chatspdev.azurewebsites.net/api/askquestion';
      // }
      // Axios.post(url, data)
      //   .then(response => {
      //     if (response.status === 200) {
      //       setLoader(false);
      //       let newArray = {
      //         message: response.data.Answer,
      //         time: `${moment().format('hh:mm a')}`,
      //         type: 'Bot',
      //       };
      //       setListMessage(oldArray => [...oldArray, newArray]);
      //       scrollToBottom();
      //       Speech(response.data.Answer);
      //       // localStorage.setItem(props.clientId, JSON.stringify(listMessage));
      //     }
      //   })
      //   .catch(function(error) {
      //     setLoader(false);
      //     alert(error);
      //   });
      dispatch(askQuestion(data));
    }
  };

  // const Speech = (text, id) => {
  //   const synth = window.speechSynthesis;

  //   if (id === ref.current) {
  //     synth.cancel();
  //     ref.current = null;
  //     const newArray = isSpeaking.map(() => false);
  //     setIsSpeaking(newArray);
  //   } else if (synth.speaking) {
  //     synth.cancel();
  //     let word = new SpeechSynthesisUtterance(text);
  //     synth.speak(word);
  //     const newArray = isSpeaking.map((item, index) => (index === id ? true : false));
  //     setIsSpeaking(newArray);
  //     ref.current = id;
  //     word.onend = () => {
  //       const newArray = isSpeaking.map(() => false);
  //       setIsSpeaking(newArray);
  //     };
  //   } else {
  //     let word = new SpeechSynthesisUtterance(text);
  //     synth.speak(word);
  //     const newArray = isSpeaking.map((item, index) => (index === id ? true : false));
  //     setIsSpeaking(newArray);
  //     ref.current = id;
  //     word.onend = () => {
  //       const newArray = isSpeaking.map(item => false);
  //       setIsSpeaking(newArray);
  //     };
  //   }
  // };

  // lang: '';
  // onboundary: null;
  // onend: null;
  // onerror: null;
  // onmark: null;
  // onpause: null;
  // onresume: null;
  // onstart: null;
  // pitch: -1;
  // rate: -1;
  // text: "some sample text";
  // voice: null;
  // volume: -1;
  const Speech = text => {
    const synth = window.speechSynthesis;
    synth.resume();
    // Fetch the available voices.
    const voices = synth.getVoices();
    let word = new SpeechSynthesisUtterance(text);
    // Set the attributes.
    // word.volume = parseFloat(volumeInput.value);
    // word.rate = parseFloat(rateInput.value);
    // word.pitch = parseFloat(pitchInput.value);
    // set the utterance instance's voice attribute.
    // word.voice = voices && voices[1];
    synth.speak(word);
  };
  const StopSpeech = isClear => {
    const synth = window.speechSynthesis;
    if (isClear) {
      // to clear queue
      synth.cancel();
    } else {
      synth.pause();
    }
  };
  const ResumeSpeech = () => {
    const synth = window.speechSynthesis;
    synth.resume();
  };

  return (
    <div className="p-2">
      {props.AugmentedAIStatusData && (
        <CommonLicenceScreens StatusData={props.AugmentedAIStatusData} moduleName={'AugmentedAI'} />
      )}

      {props.AugmentedAIStatusData &&
        props.AugmentedAIStatusData.LicenseStatusId != 1 &&
        props.AugmentedAIStatusData.LicenseStatusId != 3 &&
        props.AugmentedAIStatusData.LicenseStatusId != 5 && (
          <>
            <div className="chatbot row flex-column" style={{ padding: '30px' }}>
              {listMessage.length > 0 && (
                <div className="col-12 text-center mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <span></span>
                    <span className="chat-date">{chatdate}</span>
                    <div
                      className={isMute ? 'mute' : 'un-mute'}
                      onClick={() => {
                        if (isMute) {
                          ResumeSpeech();
                        } else {
                          StopSpeech();
                        }
                        setIsMute(!isMute);
                      }}
                    >
                      <img
                        src={`images/icons/${isMute ? 'mute' : 'unmute'}.svg`}
                        className="mr-2"
                      />
                      <span>MUTE</span>
                    </div>
                  </div>
                </div>
              )}
              <div
                ref={messagesEndRef}
                className="chat-message-container"
                style={{
                  height: '500px',
                  overflowY: 'scroll',
                  // padding: '15px',
                }}
                onChange={scrollToBottom}
              >
                {listMessage.length ? (
                  listMessage.map((item, index) => (
                    <div>
                      {item.UserQuery && (
                        <div
                          className="chat-messages"
                          style={{ backgroundColor: 'white', position: 'relative' }}
                          // key={item.id}
                        >
                          <span style={{ position: 'relative' }}>
                            <img
                              className="chat-userimg"
                              src={
                                userProfile !== 'null' ? userProfile : 'images/blank-profile.png'
                              }
                            />
                          </span>
                          <span className="col-10">{item.UserQuery}</span>
                          <span
                            className="col-1 text-right"
                            style={{
                              position: 'absolute',
                              right: '12px',
                              color: 'rgba(0,0,0,0.5)',
                              fontSize: '12px',
                            }}
                          >
                            {stringDate(item.DateInserted)}
                          </span>
                        </div>
                      )}
                      {item.Response && (
                        <div
                          className="chat-messages"
                          style={{ backgroundColor: ' #EAEAEA', position: 'relative' }}
                          // key={item.id}
                        >
                          <span style={{ position: 'relative' }}>
                            <img className="chat-userimg" src={'images/gpt-ai.svg'} />
                          </span>
                          <span className="col-10">{item.Response}</span>
                          <span
                            className="col-1 text-right"
                            style={{
                              position: 'absolute',
                              right: '12px',
                              color: 'rgba(0,0,0,0.5)',
                              fontSize: '12px',
                            }}
                          >
                            {stringDate(item.DateInserted)}
                          </span>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    <img style={{ height: '200px' }} src="images/chatSP.svg" />
                    <h2
                      style={{
                        width: '750px',
                        marginTop: '20px',
                        textAlign: 'center',
                      }}
                    >
                      {`Hi ${UserName}, What do you want to know about ${props.clientInfo.FirstName}   ${props.clientInfo.LastName}?`}
                    </h2>
                  </div>
                )}
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginTop: '15px' }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: '#f2f2f2',
                    position: 'relative',

                    borderRadius: '30px',
                    boxShadow: '2px 2px 3px rgba(0,0,0,0.2)',
                    maxWidth: '990px',
                    minWidth: '85%',
                  }}
                >
                  <input
                    className="chat-input col-11"
                    placeholder="Type Here..."
                    type="text"
                    value={message}
                    onChange={e => {
                      setMessage(e.target.value);
                    }}
                    onKeyDown={assignMessage}
                  />
                  {loader ? (
                    <span className="d-flex justify-content-center align-items-center col-1">
                      <img
                        src="images/chatLoader.gif"
                        // style={{ position: 'absolute', right: '50px', top: '10px' }}
                      />
                    </span>
                  ) : (
                    <div className="col-1 pl-0">
                      <span className="d-flex align-items-center justify-content-around">
                        <SpeechToText handleDictate={handleDictate} />
                        <span onClick={assignMessage} style={{ cursor: 'pointer' }}>
                          <img className="chat-submit" src="images/chatSubmit.svg" />
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

const mapStateToProps = ({ user_feature_flag_get }) => {
  const { data: fFData } = user_feature_flag_get;
  let AugmentedAIStatusData = null;

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    AugmentedAIStatusData =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  return {
    AugmentedAIStatusData,
  };
};

export default connect(mapStateToProps)(VirtaulAssistant);
