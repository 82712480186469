import React, { Component, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector, isDirty, reset } from 'redux-form';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Button, Radio, Space } from 'antd';
import moment from 'moment';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxTextarea,
} from '../../../../component/custom-redux-components';
import Loader, { Loader2 } from '../../../../Components/Loader';
import validate, {
  dateofbirthNormalize,
  dobStudent,
  ssnFormatter,
  ssnNormalize,
  MobileFormatter,
  MobileNormalize,
} from '../../validation/validate';
import {
  listCombos,
  medicateCombos,
  getClientCounselor,
  getDynamicComos,
  validateSSN,
  clientCenterMove,
  clientReactivate,
  getPatientDetail,
} from '../../../../store/actions';
import { ProcessFilterCombos } from '../../../../store/comboConfig';
import DynamicForm, { DynamicSection, COMPONENT_TYPES } from '../../../../core/dynamic-form';
import AntdModal from '../../../../Components/CustomModal';
import { upsertPatientEnrollment } from '../../../../store/actions';
import WarningComponent from '../../../../core/warning-component';
import { get } from 'lodash';
import CustomModal from '../../../../Components/CustomModal';
const Validator = {
  endDate: (value, values, props, name) => {
    if (!value) {
      return;
    }
    var startDate = values.admissionDate && moment(values.admissionDate).format('MM/DD/YYYY');
    var endDate = moment(value).format('MM/DD/YYYY');
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(endDate).getTime();
    if (date2 >= date1) {
    } else {
      return 'has to be greater than or equal to admission date';
    }
  },
};
const EmergencyContact = ({
  fields,
  name,
  meta: { touched, error },
  RelationShip,
  Filter_StateProvince,
  Country,
  emergencyContacts,
  change,
  disable,
  dispatch,
}) => {
  const countryChange = (countryISO, currentIndex) => {
    /*if (countryISO) {
      dispatch(
        ProcessFilterCombos({
          combo: 'StateProvince',
          CountryISO: countryISO,
          filterBy: countryISO,
        }),
      );
    }*/
  };

  var formFields = [
    {
      name: 'firstName',
      fieldName: 'First Name',
    },
    {
      name: 'middleName',
      fieldName: 'Middle Name',
    },
    {
      name: 'lastName',
      fieldName: 'Last Name',
    },
    {
      name: 'relationshipId',
      fieldName: 'Relationship',
      options: RelationShip,
    },
    {
      name: 'line1',
      // fieldName: 'Line 1',
      fieldName: 'Permanent Address Line 1',
    },
    {
      name: 'line2',
      // fieldName: 'Line 2',
      fieldName: 'Permanent Address Line 2',
    },
    // {
    //   name: 'country',
    //   fieldName: 'Country',
    //   options: Country,
    //   onChange: (event, newValue, previousValue, name) => {
    //     countryChange(
    //       newValue,
    //       name
    //         .replace('EmergencyContact', '')
    //         .replace('[', '')
    //         .replace(']', '')
    //         .replace('.country', ''),
    //     );
    //   },
    // },
    {
      name: 'city',
      fieldName: 'City',
    },
    {
      name: 'stateOrProvince',
      fieldName: 'State',
      //options: Filter_StateProvince[emergencyContacts[index].country || 'USA'],
      options: Filter_StateProvince,
      filterBy: 'country',
      defaultFilterBy: 'USA',
    },
    {
      name: 'zip',
      fieldName: 'Zipcode',
      validate: [validate.usaZip],
    },
    {
      name: 'primaryCN',
      fieldName: 'Phone',
    },
    {
      name: 'secondaryCN',
      fieldName: 'Other',
    },
    {
      name: 'email',
      fieldName: 'E-mail Address',
    },
    {
      name: 'notes',
      fieldName: 'Notes',
      class: 'col-12 col-md-6 col-lg-8',
      type: 'textarea',
      style: { height: '130px' },
      componentType: COMPONENT_TYPES.ReduxTextarea,
    },
  ];

  return (
    <DynamicSection
      sectionKey={'EmergencyContact'}
      sections={fields}
      title={'Emergency Contact'}
      deleteTitle={'Delete Contact'}
      addTitle={'Contact'}
      formFields={formFields}
      onAdd={() => fields.push({ country: 'USA' })}
      disable={disable}
      rows={emergencyContacts}
    />
  );
};
const EmergencyContact1 = connect(state => {
  const emergencyContacts = selector(state, 'EmergencyContact');
  return {
    emergencyContacts,
  };
})(EmergencyContact);

class Demographic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerNewValue: null,
      moveCenterModal: false,
      reactivateType: 1,
      centerErrMsg: null,
    };
  }

  //  const [centerNewValue, setCenterNewValue] = useState();
  // const [moveCenterModal, setMoveCenterModal] = useState(false);

  // if (data2) {
  //   this.setState({moveCenterModal:false});
  // }

  handleCenterChange = value => {
    this.setState({ centerNewValue: value, centerErrMsg: null });
  };

  handleNewCenter = () => {
    let data = { Client: [{ ClientId: this.props.clientId, CenterId: this.state.centerNewValue }] };
    this.props.dispatch(clientCenterMove({ Json: JSON.stringify(data) }));
  };
  componentDidMount() {
    this.props.dispatch(
      listCombos({
        comboTypes: 'ClinicalCenter',
      }),
    );
  }
  componentWillReceiveProps({ data2 }) {
    if (data2 && data2.success && data2.success != this.props.data2.success) {
      this.setState({
        moveCenterModal: false,
      });
      toast.success("Patient's center changed successfully", {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // onClose: () => {
        //   this.props.history.push('/manage/staff-management/staff-list');
        // },
      });
      this.props.history.push('/cw/patient-management/patient-list');
    }
  }

  componentWillUnmount() {
    // this.props.reset();
    this.props.dispatch(reset('demographicForm'));
  }
  handelReactivate = e => {
    this.setState({
      reactivateType: e.target.value,
    });
  };
  // useEffect(() => {
  //   return () => {
  //     reset();
  //   };
  handleReactivateSubmission = () => {
    const { reactivateType, centerNewValue } = this.state;
    const { dispatch, clientId } = this.props;
    dispatch(
      clientReactivate({
        json: JSON.stringify({
          Client: [
            {
              ClientId: clientId,
              ReActivateType: reactivateType,
              CenterId: centerNewValue,
            },
          ],
        }),
      }),
    );
    this.setState({ centerErrMsg: null });
  };

  render() {
    const {
      handleSubmit,
      onLastSubmit,
      handleNext,
      name,
      NamePrefix,
      NameSuffix,
      HomeAddressType,
      Counsellor,
      Practitioner,
      Center,
      ReferenceSource,
      CenterProgramType,
      CenterServiceType,
      style,
      Filter_StateProvince,
      disable,
      Country,
      dispatch,
      Gender,
      MaritalStatus,
      EthnicRace,
      EducationType,
      Language,
      RelationShip,
      FaithType,
      Veteran,
      Ethnicity,
      genderValue,
      BMI,
      BMIText,
      ProgramLevel,
      clientId,
      clientInfo,
      isDirty,
      submitSucceeded,
      reset,
      isInValidSSN,
      Msg,
      data2,
      ClinicalCenter,
      ClientStatus,
      isUpserting,
      isReadOnlyUser,
      IsLatestEpisode,
      pristine,
    } = this.props;
    const { centerErrMsg, reactivateType, centerNewValue } = this.state;
    const { IsDeActivated } = this.props.clientInfo || {};
    const countryChange = (countryISO, currentIndex) => {
      if (countryISO) {
        this.props.dispatch(
          listCombos({
            comboTypes: 'StateProvince',
            CountryISO: countryISO,
          }),
        );
      }
    };

    var formFields = [
      {
        name: 'firstName',
        validate: [validate.required, validate.requiredInput],
        isRequired: true,
        fieldName: 'First Name',
      },
      {
        name: 'middleName',
        fieldName: 'Middle Name',
      },
      {
        name: 'lastName',
        isRequired: true,
        fieldName: 'Last Name',
      },
      {
        name: 'dateofBirth',
        normalize: dateofbirthNormalize,
        validate: [validate.dobStudent, validate.required],
        isRequired: true,
        fieldName: 'Date of Birth',
        smallInfo: 'MM/DD/YYYY',
        displayCalenderIcon: true,
      },
      {
        name: 'age',
        fieldName: 'Age',
        disabled: true,
      },
      {
        name: 'gender',
        fieldName: 'Gender',
        options: Gender,
        isRequired: true,
      },
      {
        name: 'ssn',
        fieldName: true,
        validate: [validate.ssn],
        validate: isInValidSSN
          ? [
              value => {
                if (isInValidSSN) {
                  return Msg;
                } else {
                  return undefined;
                }
              },
              validate.ssn,
            ]
          : [validate.ssn],
        format: ssnFormatter,
        normalize: ssnNormalize,
        placeholder: 'SSN',
        isRequired: true,
        onBlur: (event, newValue, previousValue, name) => {
          let ssn = event.target.value;
          this.props.dispatch(
            validateSSN({
              json: JSON.stringify({
                Client: [
                  {
                    ClientId: clientId > 0 ? clientId : null,
                    SSN: ssn,
                    IsClinical: true,
                  },
                ],
              }),
            }),
          );
        },
      },
      {
        name: 'line1',
        // fieldName: 'Address Line 1',
        fieldName: 'Permanent Address Line 1',
        isRequired: true,
      },
      {
        name: 'line2',
        // fieldName: 'Address Line 2',
        fieldName: 'Permanent Address Line 2',
      },
      {
        name: 'city',
        isRequired: true,
        fieldName: 'City',
      },
      {
        name: 'stateOrProvince',
        fieldName: 'State',
        options: Filter_StateProvince,
        filterBy: 'country',
        defaultFilterBy: 'USA',
        placeholder: 'State',
        isRequired: true,
      },
      {
        name: 'zip',
        fieldName: 'Zipcode',
        validate: [validate.required, validate.usaZip],
        isRequired: true,
      },
      {
        name: 'primaryCN',
        fieldName: 'Phone',
        validate: [validate.NumericMobile],
        format: MobileFormatter,
        normalize: MobileNormalize,
        //class: 'col-12 col-md-6 col-lg-4 d-flex',
        //componentClass: 'form-group w-100',
      },
      {
        name: 'email',
        fieldName: 'E-mail Address',
      },
      {
        name: 'race',
        fieldName: 'Race',
        options: EthnicRace,
      },
      {
        name: 'religion',
        fieldName: 'Religion',
        options: FaithType,
      },
      {
        name: 'education',
        fieldName: 'Education Level Completed',
        options: EducationType,
      },
      {
        name: 'maritalStatus',
        fieldName: 'Marital Status',
        options: MaritalStatus,
      },
      {
        name: 'practitioner',
        fieldName: 'Primary Provider',
        options: Practitioner,
        isRequired: true,
      },
      {
        name: 'isPregnant',
        fieldName: 'Pregnant?',
        for: 'is-pregnant',
        condition: () => genderValue === 'F',
        componentType: COMPONENT_TYPES.ReduxToggle,
      },
      {
        name: 'ethnicityStatus',
        fieldName: 'Ethnicity Status',
        options: Ethnicity,
        placeholder: 'Ethnicity',
      },
      {
        name: 'veteranStatus',
        fieldName: 'Veteran Status',
        options: Veteran,
        placeholder: 'Veteran',
      },
      {
        name: 'referralSource',
        fieldName: 'Referral Source',
        options: ReferenceSource,
      },
      {
        name: 'houseHoldincome',
        fieldName: 'Household Income',
        placeholder: 'Household Income (Monthly)',
        componentType: COMPONENT_TYPES.ReduxDollarInput,
      },
      {
        name: 'centerProgramType',
        fieldName: 'Program',
        options: CenterProgramType,
      },
      {
        name: 'programLevel',
        fieldName: 'Program Level',
        options: ProgramLevel,
      },
      {
        name: 'centerServiceType',
        fieldName: 'Service Type',
        options: CenterServiceType,
      },
      {
        name: 'admissionDate',
        fieldName: 'Admission Date',
        placeholder: 'Admission Date',
        componentType: COMPONENT_TYPES.ReduxDateInput,
        // isRequired: true,
        label: 'Admission Date',
        smallInfo: 'MM/DD/YYYY',
      },
      {
        name: 'clientStatus',
        fieldName: 'Status',
        options: ClientStatus,
        placeholder: 'Status',
        isRequired: true,
      },
      {
        name: 'projectedCompletionDate',
        fieldName: 'Projected completion date',
        placeholder: 'Projected Completion Date',
        componentType: COMPONENT_TYPES.ReduxDateInput,
        // isRequired: true,
        validate: [Validator.endDate],
        label: 'Projected Completion Date',
      },
      {
        name: 'demographicNotes',
        fieldName: 'Notes',
        class: 'col-12 col-md-6 col-lg-8',
        type: 'textarea',
        // style: { height: '130px' },
        componentType: COMPONENT_TYPES.ReduxTextarea,
      },
      {
        displayInfo: true,
        name: 'dischargeDate',
      },
      {
        displayTitle: true,
        title: 'Vitals ( Metric Measures)',
      },
      {
        name: 'height',
        fieldName: 'Height (inches)',
        // placeholder: 'Height (inches)',
        validate: [validate.allowNumericValues],
      },
      {
        name: 'weight',
        fieldName: 'Weight (pounds)',
        // placeholder: 'Weight (pounds)',
        validate: [validate.allowNumericValues],
      },
      {
        condition: () => {
          return typeof BMI != 'undefined';
        },
        renderComponent: (
          <label class="placeholder-label">
            BMI: {BMI && BMI.toFixed(2)} <br />
            {/* Categories: {BMIText} */}
          </label>
        ),
      },
      {
        name: 'bloodPressure',
        fieldName: 'Blood Pressure',
        smallInfo: 'Hint: Systolic / Diastolic',
      },
      {
        name: 'temperature',
        fieldName: 'Temperature',
        validate: [validate.allowNumericValues],
      },
      {
        displayTitle: true,
        title: 'Center',
      },
      {
        name: 'centerId',
        fieldName: 'Center',
        options: ClinicalCenter,
        placeholder: 'Select Center',
        disabled: this.props.clientId > 0 ? true : false,
        isRequired: true,
      },
    ];
    return (
      <>
        <form onSubmit={handleSubmit(onLastSubmit)} style={style}>
          <div class="row rcm-tab" style={{ position: 'relative' }}>
            {/* <Loader2 loading={props.isFetching} /> */}
            {this.props.clientId > 0 && (
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <Field
                    name={`displayClientId`}
                    type="text"
                    component={ReduxInput}
                    disabled={true}
                    // disabled={IsDeActivated}
                  />
                  <label class="placeholder-label">Patient ID</label>
                </div>
              </div>
            )}
            <DynamicForm formFields={formFields} disable={IsDeActivated || isReadOnlyUser} />

            <div class="col-12 col-md-12 col-lg-12 mb-5">{null}</div>

            <div class="col-12">
              <FieldArray
                name="EmergencyContact"
                component={EmergencyContact1}
                RelationShip={RelationShip}
                Filter_StateProvince={Filter_StateProvince}
                Country={Country}
                // change={change}
                disable={IsDeActivated || isReadOnlyUser}
                dispatch={this.props.dispatch}
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              // style={{ bottom: '40px', position: 'absolute' }}
            >
              {clientId > 0 && IsLatestEpisode && (
                <div>
                  {!clientInfo.IsDeActivated ? (
                    <>
                      <AntdModal
                        ModalButton={({ onClick }) => {
                          if (!isReadOnlyUser) {
                            return (
                              <span
                                class="deactivate-link"
                                data-toggle="modal"
                                data-target="#deactivate-staff-model"
                                onClick={onClick}
                              >
                                <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                              </span>
                            );
                          } else {
                            return <></>;
                          }
                        }}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                            <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                              <h4 class="modal-title col-12 mb-3">
                                Are you sure you want to continue? <br />
                                <small>
                                  Note: Deactivating this patient will cancel all future
                                  appointments and remove them from groups.
                                </small>
                              </h4>
                            </div>
                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                class="btn"
                                onClick={() => {
                                  onOk();
                                  dispatch(
                                    upsertPatientEnrollment({
                                      json: JSON.stringify({
                                        Client: [
                                          {
                                            ClientId: clientId,
                                            IsDeActivated: 1,
                                          },
                                        ],
                                      }),
                                    }),
                                  );
                                }}
                              >
                                Yes
                              </button>
                              <button
                                class="btn btn-outline-primary"
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <AntdModal
                        ModalButton={({ onClick }) => (
                          <a
                            class="reactivate-link"
                            data-toggle="modal"
                            data-target="#reactivate-staff-model"
                            onClick={onClick}
                          >
                            <i class="fa fa-check" aria-hidden="true" /> Reactivate
                          </a>
                        )}
                        ModalContent={({ onOk, onCancel }) => (
                          <div class="modal-content border-0">
                            <div class="modal-header d-flex flex-row-reverse border-bottom-0 mb-2">
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                onClick={onCancel}
                              >
                                &times;
                              </button>
                              <h4 class="modal-title col-12">REACTIVATE</h4>
                            </div>
                            <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5 reactivate-type checkbox-to-radio">
                              <Radio.Group
                                onChange={this.handelReactivate}
                                value={this.state.reactivateType}
                              >
                                <Space direction="vertical">
                                  <Radio value={1}>
                                    Create a new episode and reactivate for current center
                                  </Radio>
                                  <Radio value={2}>
                                    Create a new episode and reactivate for different center
                                  </Radio>
                                  <Radio value={3}>Keep the current episode and reactivate</Radio>
                                </Space>
                              </Radio.Group>
                              {this.state.reactivateType == 2 && (
                                <div className="m-4">
                                  <div className="enrollment-form">
                                    <div className="form-group mb-0">
                                      <ReduxSelect
                                        options={ClinicalCenter}
                                        onChange={this.handleCenterChange}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        value={this.state.centerNewValue}
                                        placeholder="Select Center*"
                                      />
                                      {centerErrMsg && (
                                        <span className="error-text">{centerErrMsg}</span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                              <button
                                class="btn btn-outline-primary black-btn"
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                Close
                              </button>
                              <button
                                class="btn"
                                onClick={() => {
                                  if (
                                    (reactivateType == 2 && centerNewValue) ||
                                    reactivateType != 2
                                  ) {
                                    onOk();
                                    this.handleReactivateSubmission();
                                  } else {
                                    this.setState({
                                      centerErrMsg:
                                        'Please select the center at which to reactivate the patient.',
                                    });
                                  }
                                }}
                              >
                                Reactivate
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </>
                  )}
                  &nbsp; &nbsp;
                  {clientInfo.IsDeActivated && false && (
                    <>
                      <a
                        class="reactivate-link"
                        data-toggle="modal"
                        data-target="#reactivate-staff-model"
                        onClick={() => {
                          this.setState({ moveCenterModal: true });
                        }}
                        title="Move this patient to different center"
                        style={{ cursor: 'pointer' }}
                      >
                        Change Patient's Center
                      </a>
                      <Modal
                        zIndex={100}
                        // visible={true}
                        visible={this.state.moveCenterModal}
                        footer={null}
                        closable={false}
                        width={700}
                        destroyOnClose={true}
                      >
                        <div class="modal-content border-0">
                          <div
                            className="text-right mx-2"
                            onClick={() => {
                              this.setState({ moveCenterModal: false });
                            }}
                          >
                            <i
                              class="fa fa-times"
                              aria-hidden="true"
                              style={{ cursor: 'pointer', fontSize: '25px' }}
                            ></i>
                          </div>
                          <div class="modal-body pr-2 pl-2 mb-5">
                            <h4 class="modal-title col-12 mb-3 mt-5">
                              Change this patient to a different center
                              <br />
                              <small>Note: This will also activate the patient.</small>
                            </h4>
                            <div className="">
                              <form>
                                <div
                                  className="d-flex align-items-center justify-content-center dynamic-center 
                                enrollment-form"
                                >
                                  <div className="form-group mb-0" style={{ width: '310px' }}>
                                    <ReduxSelect
                                      options={ClinicalCenter}
                                      onChange={this.handleCenterChange}
                                      className="basic-single"
                                      classNamePrefix="select"
                                      value={this.state.centerNewValue}
                                      placeholder="Select Center"
                                    />

                                    <br />
                                    <div className="text-center">
                                      <input
                                        type="button"
                                        onClick={this.handleNewCenter}
                                        value="Change Center"
                                        className="btn btn-sm stn-btn"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Modal>

                      {/* <AntdModal
                      ModalButton={({ onClick }) => (
                        <a
                          class="reactivate-link"
                          data-toggle="modal"
                          data-target="#reactivate-staff-model"
                          onClick={onClick}
                          title="Move this patient to different center"
                        >
                          Move Center
                        </a>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-body pr-2 pl-2 mb-5">
                            <h4 class="modal-title col-12 mb-3 mt-5">
                              Move this patient to a different center
                            </h4>
                            <div className="">
                              <form>
                                <div
                                  className="d-flex align-items-center justify-content-center dynamic-center 
                                enrollment-form"
                                >
                                  <div className="form-group mb-0" style={{ width: '310px' }}>
                                    <ReduxSelect
                                      options={Center}
                                      onChange={handleCenterChange}
                                      className="basic-single"
                                      classNamePrefix="select"
                                      value={centerNewValue}
                                      placeholder="Select Center"
                                    />

                                    <br />
                                    <div className="text-center">
                                      <input
                                        type="button"
                                        onClick={handleNewCenter}
                                        value="Update Center"
                                        className="btn btn-sm stn-btn"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    /> */}
                    </>
                  )}
                </div>
              )}
            </div>
            {!IsDeActivated && (
              <div class="col-12 text-right pagination-button">
                <input
                  type="button"
                  class="btn btn-outline-primary mr-2"
                  value="Cancel"
                  onClick={reset}
                  disabled={IsDeActivated || this.props.isReadOnlyUser || pristine}
                />
                {/* <input type="submit" class="btn" value="Save" disabled={IsDeActivated} /> */}
                <Button
                  loading={isUpserting}
                  htmlType="submit"
                  type="primary"
                  className="save-button"
                  disabled={IsDeActivated || this.props.isReadOnlyUser}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </form>
        <WarningComponent dirtyFlag={isDirty} submitSucceeded={submitSucceeded} />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    combos,
    get_client_counselor,
    medicate_combos,
    validate_SSN,
    client_center_move,
    upsert_patient_enrollment,
    get_patient_detail,
  } = state;
  let { isFetching: isUpserting } = upsert_patient_enrollment;
  let { data, isFetching, error } = get_client_counselor;
  let { data: data2 } = client_center_move;
  const { data: medicateCombos } = medicate_combos;
  const { data: clientInfo, isFetching: isFetchingClientInfo } = get_patient_detail;
  const genderValue = selector(state, 'gender');
  const heightValue = selector(state, 'height');
  const weightValue = selector(state, 'weight');
  const admissionDateValue = selector(state, 'admissionDate');

  let IsLatestEpisode;
  let Counsellors = [],
    Gender = [],
    EducationType = [],
    Language = [],
    RelationShip = [],
    Country = [],
    FaithType = [],
    ReferenceSource = [],
    MaritalStatus = [],
    EthnicRace = [],
    CenterProgramType = [],
    CenterServiceType = [],
    ProgramLevel = [],
    Center = [],
    ClinicalCenter = [],
    ClientStatus = [],
    Filter_StateProvince = {};

  let isInValidSSN = false;
  let Msg = '';
  if (clientInfo) {
    IsLatestEpisode = clientInfo.IsLatestEpisode;
  }
  if (combos.data && combos.data['Gender']) {
    Gender = combos.data['Gender'];
    EducationType = combos.data['EducationType'];
    Language = combos.data['Language'];
    RelationShip = combos.data['RelationShip'];
    Country = combos.data['Country'];
    FaithType = combos.data['FaithType'];
    ReferenceSource = combos.data['ReferenceSource'];
    CenterProgramType = combos.data['RCM_Program'];
    CenterServiceType = combos.data['CenterServiceType'];
    MaritalStatus = combos.data['MaritalStatus'];
    EthnicRace = combos.data['EthnicRace'];
    ProgramLevel = combos.data['ProgramLevel'];
    Filter_StateProvince = combos.data['Filter_StateProvince'];
    Center = combos.data['Center'];
    ClinicalCenter = combos.data['ClinicalCenter'];
    ClientStatus = combos.data['ClientStatus'];
  }

  if (data && data.data) {
    let CounsellorData = JSON.parse(data.data);
    Counsellors =
      CounsellorData &&
      CounsellorData.map(item => {
        return {
          Value: item.StaffId,
          Text: item.StaffName,
        };
      });
    Counsellors.unshift({ Value: null, Text: 'Please Select' });
  }
  if (heightValue && weightValue) {
    // var height = Number(heightValue) * 0.3937;
    // var heightunits = document.getElementById('heightunits').value;
    // var heightunits = 'inches';
    // var weight = Number(weightValue);
    // var weightunits = document.getElementById('weightunits').value;
    // var weightunits = 'lb';

    //Convert all units to metric
    // if (heightunits == 'inches') height /= 39.3700787;
    // if (weightunits == 'lb') weight /= 2.20462;
    // (16.9 kg / 105.4 cm / 105.4 cm ) x 10,000 = 15.2
    //Perform calculation
    // var BMI = weight / Math.pow(height, 2);
    var BMI = (Number(weightValue) / Number(heightValue) / Number(heightValue)) * 703;
    var BMIText = '';

    if (BMI < 18.5) {
      BMIText = 'Underweight';
    } else if (BMI > 18.5 && BMI < 24.9) {
      BMIText = 'Normal weight';
    } else if (BMI > 25 && BMI < 29.9) {
      BMIText = 'Overweight';
    } else {
      BMIText = 'BMI of 30 or greater';
    }
  }
  if (validate_SSN.data && validate_SSN.data.success) {
    isInValidSSN = validate_SSN.data.result.Result;
    Msg = validate_SSN.data.result.Msg;
  }
  return {
    BMI,
    BMIText,
    Counsellors: Counsellors,
    MaritalStatus: MaritalStatus,
    EthnicRace: EthnicRace,
    Gender,
    EducationType,
    Language: Language,
    RelationShip: RelationShip,
    Country: Country,
    FaithType: FaithType,
    ReferenceSource: ReferenceSource,
    CenterProgramType: CenterProgramType,
    CenterServiceType: CenterServiceType,
    Veteran: (medicateCombos && medicateCombos.Veteran) || [],
    Ethnicity: (medicateCombos && medicateCombos.Ethnicity) || [],
    genderValue: genderValue,
    Filter_StateProvince: Filter_StateProvince,
    ProgramLevel: ProgramLevel,
    isDirty: isDirty('demographicForm')(state),
    submitSucceeded: get(state, 'form.demographicForm.submitSucceeded', false),
    isInValidSSN,
    Msg,
    Center: Center || [],
    ClinicalCenter: ClinicalCenter || [],
    data2: data2 || {},
    ClientStatus,
    isUpserting,
    IsLatestEpisode,
    admissionDateValue,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'demographicForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail: errors => {
      if (errors) {
        const firstError = Object.keys(errors)[0];
        let el =
          document.querySelector(`[name="${firstError}"]`) ||
          document.getElementById(`${firstError}`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
  })(Demographic),
);

const selector = formValueSelector('demographicForm');
