import React, { Component, useEffect, useState, useLayoutEffect, useRef } from 'react';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';
const OrgChartComponent = (props, ref) => {
  const d3Container = useRef(null);
  let chart = null;

  function addNode(node) {
    chart.addNode(node);
  }

  props.setClick(addNode);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
          var compact = 0;
      if (!chart) {
        chart = new OrgChart();
      }
      chart
        .container(d3Container.current)
        .data(props.data)
        .nodeWidth(d => 200)
        .nodeHeight(d => 180)
        // .onNodeClick((d, i, arr) => {
        //   console.log(d, 'Id of clicked node ');
        //   props.onNodeClick(d);
        // })
        .nodeContent(function(d, i, arr, state) {
          return `
            <div style="padding-top:30px;background-color:none;margin-left:1px;height:${
              d.height
            }px;border-radius:2px;overflow:visible">
              <div style="height:${d.height -
                32}px;padding-top:0px;background-color:white;border:1px dashed #3AB6E3;cursor: default;">

                <img src=" ${
                  d.data.ProfileImageUrl
                    ? d.data.ProfileImageUrl
                    : `images/familyProgram/blank-profile.png`
                }" style="margin-top:-30px;margin-left:${d.width / 2 -
            30}px;border-radius:100px;width:60px;height:60px;object-fit:cover;border:3px dashed #3AB6E3;" />
               
               <div style="margin-top:-30px;background-color:#3AB6E3;height:10px;width:${d.width -
                 2}px;border-radius:1px"></div>

               <div style="padding:20px; padding-top:35px;text-align:center">
                   <div style="color:#111672;font-size:16px;font-weight:bold"> ${
                     d.data.DisplayName ? d.data.DisplayName : ''
                   } </div>
                   <div style="color:#404040;font-size:16px;margin-top:4px"> ${
                     d.data.Relation ? d.data.Relation : ''
                   } </div>
                   <div style="color:#404040;font-size:12px;margin-top:4px;word-break:break-all"> ${
                     d.data.Email ? d.data.Email : ''
                   } </div><div style="color:#404040;font-size:12px;margin-top:4px"> ${
            d.data.Phone ? d.data.Phone : ''
          } </div>
               </div> 
           
              </div>     
      </div>
  `;
        })
        .compact(!!(compact++ % 2))
        .render()
        .fit()
        .compactMarginBetween(d => 65)
        .compactMarginPair(d => 100)
        .linkUpdate(function(d, i, arr) {
          d3.select(this)
            .attr('stroke', d => (d.data._upToTheRootHighlighted ? '#3AB6E3' : '#3AB6E3'))
            .attr('stroke-width', d => (d.data._upToTheRootHighlighted ? 2 : 2));

          if (d.data._upToTheRootHighlighted) {
            d3.select(this).raise();
          }
        })
        .render();
    }
  }, [props.data, d3Container.current]);

  return (
    <div>
      <div ref={d3Container} />
    </div>
  );
};

export default OrgChartComponent;
