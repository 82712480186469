import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageTitle from '../Components/PageTitle';
import { Table, Checkbox, Radio } from 'antd';
import {
  ReduxSelect,
  ReduxInput,
  ReduxDateInput,
} from '../component/custom-redux-components/index.js';
import DatePicker from 'react-datepicker';
import CustomModal from '../Components/CustomModal';
import Loader, { Loader2 } from '../Components/Loader';
import { Link, withRouter } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import { Tag, Modal } from 'antd';
import { Field, reduxForm, formValueSelector, FieldArray, getFormSyncErrors } from 'redux-form';
import ListRenderer from '../Components/listRenderer';
import CallSchedule from './callSchedule';
import CallSession from './callSession';
// const success = message => {
//   Modal.info({
//     content: message,
//   });
// };

class callSessionDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TabName: true,
    };
  }

  handleClick = () => {
    this.setState(prevState => ({
      TabName: !prevState.TabName,
    }));
  };
  render() {
    const { history } = this.props;
    const { TabName } = this.state;
    return (
      <div class="container-fluid calenderFix">
        <PageTitle TitleText="Schedule TeleHealth Session" />
        {/* <Loader loading={isFetching} /> */}
        <div class="content-area ">
          <div class="row top-table-search mt-4 mb-5 align-items-end">
            <div class="col-12 col-md-3 col-lg-1">
              <a class="back-link" onClick={() => this.props.history.goBack()}>
                <img src="images/back-arrow.svg" /> Back
              </a>
              {/* <h3 class="page-title mb-3 mb-md-0">Manage Call Session Availability</h3> */}
            </div>
            <div class="col-12 col-md-9 col-lg-11 pr-5">
              <div class="preference-btn d-flex align-items-end justify-content-center">
                <div class="user-btnbox d-flex">
                  {/* <a
                    class={`preference-link ${TabName ? 'active' : ''}`}
                    title=" Manage Call Session Availability"
                    style={{ width: '265px', marginTop: '20px' }}
                    onClick={this.handleClick}
                  >
                    Manage Call Session Availability
                  </a> */}
                  <h5
                    // class={`preference-link ${!TabName ? 'active' : ''}`}
                    // class={`preference-link active`}
                    title="Schedule TeleHealth Session"
                    style={{
                      width: '365px',
                      marginTop: '20px',
                      borderRadius: '100px 100px 100px 100px',
                    }}
                    // onClick={this.handleClick}
                  >
                    Schedule TeleHealth Session
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>{TabName ? <CallSession /> : <CallSchedule />}</div> */}
        <div>{<CallSchedule />}</div>
      </div>
    );
  }
}

export default connect()(withRouter(callSessionDashboard));
