import React, { Component } from 'react';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class AlphaNumericMultipleInputs extends React.Component {
  constructor(props) {
    super(props);
    let { Response, QuestionHelp } = props.survey;
    const inputCount = QuestionHelp && QuestionHelp.split('/');
    const responseCount = Response && Response.split('/');
    const valueArray = new Array(inputCount);
    this.state = {
      // value: Response,
      inputCount: inputCount,
      valueArray: responseCount || valueArray,
    };
  }
  onChange = (event, controlIndex) => {
    const { value } = event.target;
    let valueArray = this.state.valueArray;
    valueArray[controlIndex] = value;
    this.setState({
      value: value,
      valueArray: valueArray,
    });
  };
  render() {
    const {
      survey,
      onClick,
      scope,
      index,
      total,
      setValue,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionHelp,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      SurveyQuestionId,
      Response,
    } = survey;
    const { inputCount, valueArray } = this.state;
    return (
      <>
        <div
          // className="card border-0"
          className="type-border  pl-3"
        >
          <div class="answer-options text-left mb-0 pb-0">
            <SurveyQuestion survey={survey} ClassName={'d-inline'} />
            <div class="quantity-btn d-inline alpha-numeric-multi">
              <div class="numbers-row">
                {inputCount &&
                  inputCount.map((control, controlIndex) => {
                    return (
                      <div className="d-flex flex-column mr-2" key={control}>
                        <input
                          disabled={isReadOnly}
                          type="text"
                          value={valueArray[controlIndex]}
                          tabindex="0"
                          placeholder=""
                          className="alphanumeric-type"
                          style={{
                            textAlign: 'left',
                            paddingLeft: '10px',
                            paddingBottom: '0px',
                            width: '150px',
                          }}
                          onChange={val => {
                            this.onChange(val, controlIndex);
                          }}
                          onBlur={() => {
                            setValue.call(scope, {
                              response: valueArray.join('/'),
                              surveyQuestionId: SurveyQuestionId,
                              level: level,
                              subLevel: subLevel,
                              subCatLevel: subCatLevel,
                              index: index,
                            });
                          }}
                        />
                        <span class="small-info">{control}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <DoesNotApplyOption surveyProps={this.props} />
        </div>
        {/*     
        <div class="survey-box" style={{ width: '100%', display: 'inline-block;' }}>
          <h2>{Question}</h2>
          <div class="survey-form-box">
            <div class="survey-form-left">
              <div class="form-group survey-textinput">
                <div class="input-group">
                  <input class="form-control" type="text" style={{ textAlign: 'center' }} />
                </div>
              </div>
              <span class="small-text" />
            </div>
            <div class="survey-form-right">
              <input
                type="button"
                class="form-control survey-form-btn"
                value="Next"
                tabindex="0"
                onClick={onClick.bind(this)}
              />
            </div>
            <div class="clearfix" />
          </div>
        </div> */}
      </>
    );
  }
}
export default AlphaNumericMultipleInputs;
