import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Sparklines,
  SparklinesLine,
  SparklinesCurve,
  SparklinesReferenceLine,
  SparklinesSpots,
} from 'react-sparklines';

class SparkLineComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const newStudentEnrollChart = [10, 15, 19, 13, 25, 5, 15];
    const { MiscData, SparkLineScale } = this.props;
    let MoodScoreData = [];
    let MoodDayData = [];
    MiscData &&
      MiscData.map(item => {
        MoodScoreData.push(parseInt(item.Score));
        MoodDayData.push(item.DayNumber);
      });
    return (
      <div className="row sparkline flex-column">
        <Sparklines data={MoodScoreData} width={90} height={20}>
          <SparklinesLine color="#179bef" />
          <SparklinesSpots style={{ fill: '#179bef' }} />
          {/* <SparklinesReferenceLine type="avg" /> */}
        </Sparklines>
        {SparkLineScale && (
          <span className="desc" style={{ fontSize: '16px' }}>{`${SparkLineScale}`}</span>
        )}
      </div>
    );
  }
}

export default connect()(SparkLineComponent);
