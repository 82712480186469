import React from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const MultiSelectCheckboxes = ({
  hideSearch,
  options,
  placeholderButtonLabel,
  value,
  onChange,
  getDropdownButtonLabel,
}) => (
  <ReactMultiSelectCheckboxes
    // options={[{ label: 'All', value: '*' }, ...ColumnOption]}
    placeholderButtonLabel={placeholderButtonLabel}
    value={value}
    onChange={onChange}
    options={options}
    hideSearch={hideSearch}
    rightAligned={false}
    getDropdownButtonLabel={getDropdownButtonLabel}
  />
);

export default MultiSelectCheckboxes;
