import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Tabs } from 'antd';
import getParam from '../../../util/getParam';
import ContractDetails from './ContractDetails';
import ContractTerms from './ContractTerms';
// import ContractNotes from './ContractNotes';

const PendingContractChangeRequest = props => {
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState([
    { tabLabel: 'Contract Details', keyIndex: 1, Content: 'ContractDetails' },
    { tabLabel: 'Contract Terms', keyIndex: 2, Content: 'ContractTerms' },
    // { tabLabel: 'Notes', keyIndex: 3, Content: 'ContractNotes' },
  ]);
  const CLIENT_ID = getParam(props.history, 'pId') || null;
  const SMART_CONTRACT_ID = getParam(props.history, 'smartContractId') || null;
  const components = {
    ContractDetails,
    ContractTerms,
    // ContractNotes,
  };
  return (
    <div className="container-fluid mt-2 px-0 center-tabs">
      <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} centered>
        {tabName.map((item, index) => {
          const { tabLabel, keyIndex, Content } = item;
          const DynamicComponent = components[Content];
          return (
            <Tabs.TabPane tab={tabLabel} key={keyIndex}>
              {
                <DynamicComponent
                  clientId={props.clientId}
                  smartContractId={props.contractId}
                  onClose={props.onClose}
                  getLatestData={props.getLatestData}
                />
              }
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default PendingContractChangeRequest;
