import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const CustomDatepicker = ({ onChange, date }) => {
  var props = {
    style: { display: 'block' },
    onChange: onChange,
    format: 'DD-MMM-YYYY',
  };

  if (date) {
    props.value = moment(date, 'YYYY/MM/DD');
  } else {
    props.value = null;
  }
  return <DatePicker {...props} />;
};

export default CustomDatepicker;

const ReduxDateInput = ({
  input,
  name,
  id,
  value,
  onChange,
  meta,
  errorMessage,
  minDate,
  disabled,
  tabIndex,
}) => {
  const { touched, error, warning } = meta || {};
  var props = {
    style: { display: 'block' },
    format: 'DD-MMM-YYYY',
  };

  if (input) {
    onChange = input.onChange;
  }

  if (input.value) {
    value = moment(input.value, 'YYYY/MM/DD');
  }

  return (
    <div>
      <DatePicker
        {...props}
        name={name}
        tabIndex={tabIndex}
        id={id}
        showOnInputClick
        onChange={onChange}
        value={value}
        minDate={minDate}
        disabled={disabled}
      />
      {touched &&
        ((error && (
          <span className="required-color-red" style={{ fontSize: '80%', color: '#dc3545' }}>
            {errorMessage}
          </span>
        )) ||
          (warning && <span className="warning-color">{warning}</span>))}
    </div>
  );
};

export { ReduxDateInput };
