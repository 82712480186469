import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader, { Loader2 } from '../../../../Components/Loader';
import '../../../../component/jquery/slick.js';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Table, Button, Tooltip } from 'antd';
import AntdModal from '../../../../Components/CustomModal';
import CustomModal from '../../../../core/modal';
import Axios from 'axios';
import ValidatePin from '../../../../Components/validatePinModal';
import {
  surveyAnswerUpsert,
  getSurveyQuestionMultiple,
  resetLogin,
  listSurveyQuestionDischarge,
  surveyAssign,
  listSurveyQuestionAssessment,
  deactivateClientScreening,
  dischargeSummarySave,
  consentFormDownload,
  resetSaveConsentSign,
  markAsComplete,
  unsignForm,
  listSurveyQuestionCategoryGetAll,
  getPatientDetail,
} from '../../../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { ReduxSelect } from '../../../../component/custom-redux-components/index.js';
import QuestionType from './questionType/questionComponent';
import moment from 'moment';
import CustomModalPopup from '../../../../core/modal';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import PersistTableSort from '../../../../component/persistTableSort';
import DocumentForm from './documentForm';
import {
  HasMandatoryQuestion,
  HasMandatoryQuestionWarning,
  UpdateResponse,
} from './questionType/mandatoryQuestionValidation';
class DischargeSummery extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
      nav1: null,
      nav2: null,
      surveys: [],
      currentSlideIndex: 0,
      SurveyQuestionCategoryListData: [],
      assesmentId: '',
      screeningView: true,
      surveyListData: [],
      surveyQuestionAssessment: {},
      currentPage: 1,
      isAssignAlreadyModal: false,
      isAssignNew: false,
    };
  }
  unsignFormFun = (FormType, FormId) => {
    let data = {
      Json: JSON.stringify({
        UnSign: [
          {
            FormType,
            FormId,
          },
        ],
      }),
    };

    this.props.dispatch(unsignForm(data));
  };

  onAssignSurvey = () => {
    var item = {
      studentIds: this.props.clientId,
      surveyQuestionCategoryIds: this.state.assesmentId,
    };
    if (this.props.SurveyListData.length == 0) {
      this.setState({
        isAssignNew: true,
      });
    } else {
      this.props.SurveyListData.map(item =>
        item.SurveyQuestionCategoryId == this.state.assesmentId &&
        item.DocumentGuid == null &&
        item.SurveyStatus == 0
          ? this.setState({
              isAssignAlreadyModal: true,
            })
          : this.setState({
              isAssignNew: true,
            }),
      );
    }
    this.props.dispatch(surveyAssign(item));
  };

  applySlick() {
    const me = this;
    $('.main-banner').slick({
      dots: false,
      centerMode: false,
      slidesToShow: 1,
      //asNavFor: '.sub-banner',
      initialSlide: this.state.currentSlideIndex,
      swipeToSlide: true,
      infinite: false,
      adaptiveHeight: true,
      arrows: false,
    });

    $('.main-banner').on('afterChange', function(event, slick, currentSlideIndex, nextSlide) {
      me.setState({ currentSlideIndex: currentSlideIndex }, me.applyPendingSlick());

      if (me.props.lastSlideIndex == currentSlideIndex) {
      }
    });

    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slideno - 1);
    });

    $(
      '.feel-list li a, .drinks-list li a, .church-list li a, .completing-btn, .form-control.survey-form-btn, .goto-next.btn',
    ).click(function(e) {
      e.preventDefault();
      $('.main-banner').slick('slickNext');
    });
  }

  applyPendingSlick() {
    setTimeout(() => {
      $('li[data-slide]').click(function(e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.main-banner').slick('slickGoTo', slideno - 1);
      });
    }, 1);
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  gotoSlide(slidetogo) {
    $('li[data-slide]').click(function(e) {
      e.preventDefault();
      var slideno = $(this).data('slide');
      $('.main-banner').slick('slickGoTo', slidetogo);
    });
  }
  getClientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var clientId = param.get('pId');
    if (clientId) {
      clientId = parseInt(clientId);
    } else {
      clientId = null;
    }
    return clientId;
  }

  componentDidMount() {
    let data = {
      Survey: [
        {
          ClientId: this.props.clientId || this.getClientId(),
          SurveyTypeDesc: 'Discharge',
        },
      ],
    };
    var getSurveyData = {
      json: JSON.stringify(data),
    };
    this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
  }
  toggleView = getLatest => {
    this.setState(prevState => ({
      screeningView: !prevState.screeningView,
    }));
    if (getLatest) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: 'Discharge',
              },
            ],
          }),
        }),
      );
    }
  };
  componentWillReceiveProps({
    surveys,
    isSurveyAssign,
    SurveyQuestionCategoryList,
    SurveyListData,
    surveyQuestionAssessment,
    isSurveyDeactivated,
    downloadUrl,
    documentError,
    isSignedDischarge,
    validateStaffPinData,
    isSurveyCompleted,
    unsignFormStatus,
    isSurveyCompletedResult,
  }) {
    if (unsignFormStatus && unsignFormStatus != this.props.unsignFormStatus) {
      let data = {
        Survey: [
          {
            ClientId: this.props.clientId || this.getClientId(),
            SurveyTypeDesc: 'Discharge',
          },
        ],
      };
      var getSurveyData = {
        json: JSON.stringify(data),
      };
      this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
      this.setState(prevState => ({
        screeningView: true,
        isReadOnly: true,
      }));
    }

    if (isSurveyCompleted && isSurveyCompleted != this.props.isSurveyCompleted) {
      if (isSurveyCompletedResult && isSurveyCompletedResult.includes('discharged')) {
        this.props.dispatch(
          getPatientDetail({
            json: JSON.stringify({
              Client: [
                {
                  ClientId: this.props.clientId || this.getClientId(),
                },
              ],
            }),
          }),
        );
      }
      this.setState(prevState => ({
        isModelOpen: true,
        header: isSurveyCompletedResult || 'Signed Successfully',
      }));
    }

    if (
      validateStaffPinData &&
      JSON.stringify(validateStaffPinData) != JSON.stringify(this.props.validateStaffPinData)
    ) {
      if (this.state.isSignStatus) {
        if (validateStaffPinData.IsValid) {
          this.submitDischarge(validateStaffPinData.StaffPinHistoryId);
        }
      }
    }
    if (surveys.length > 0 && surveys.length != this.props.surveys.length) {
      this.toggleView();
      for (var surveyIndex in surveys) {
        if (!surveys[surveyIndex].Response) {
          this.setState(
            {
              surveys: surveys,
              currentSlideIndex: parseInt(surveyIndex),
            },
            this.applySlick,
          );
          return;
        }
      }
    }
    if (
      surveyQuestionAssessment &&
      JSON.stringify(surveyQuestionAssessment) !=
        JSON.stringify(this.props.surveyQuestionAssessment)
    ) {
      this.toggleView();
      const hasMandatory = HasMandatoryQuestion(
        surveyQuestionAssessment,
        this.state.SignatureCanvas,
      );
      this.setState({
        surveyQuestionAssessment: surveyQuestionAssessment,
        hasMandatory: hasMandatory,
      });
    }

    if (SurveyListData.length > 0 && SurveyListData.length != this.props.SurveyListData.length) {
      this.setState({ surveyListData: SurveyListData });
    }
    if (isSurveyAssign && isSurveyAssign != this.props.isSurveyAssign) {
      this.setState({
        assesmentId: '',
        currentPage: 1,
      });
    }
    if (isSurveyDeactivated && isSurveyDeactivated != this.props.isSurveyDeactivated) {
      this.props.dispatch(
        listSurveyQuestionCategoryGetAll({
          json: JSON.stringify({
            Survey: [
              {
                ClientId: this.props.clientId,
                SurveyTypeDesc: 'Discharge',
              },
            ],
          }),
        }),
      );
    }
    if (isSignedDischarge && isSignedDischarge != this.props.isSignedDischarge) {
      this.props.dispatch(resetSaveConsentSign());
      this.toggle('Signed Successfully');
    }
    if (downloadUrl && downloadUrl != this.props.downloadUrl) {
      window.open(downloadUrl);
    }
  }

  setValue({
    response,
    surveyQuestionId,
    level,
    subLevel,
    subCatLevel,
    index,
    IsSignature,
    isSignatureSaved,
    Signature,
  }) {
    var json = {
      StudentId: this.getStudentId(),
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
      IsSignature: IsSignature,
      Signature: IsSignature && Signature,
    };
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
    const surveys = [...this.state.surveys];
    for (var survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }
    if (IsSignature) {
      this.setState({
        surveys: surveys,
        Signature: IsSignature && Signature,
        surveyQuestionId: surveyQuestionId,
        SignatureCanvas: IsSignature && {
          ...this.state.SignatureCanvas,
          signatureDataList: {
            ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
            [surveyQuestionId]: {
              SurveyQuestionId: surveyQuestionId,
              isSignatureSaved: isSignatureSaved,
            },
          },
        },
      });
    } else {
      this.setState({
        surveys: surveys,
      });
    }

    this.props.dispatch(surveyAnswerUpsert(json));
  }

  onNext({ surveyQuestionId, response, level, subLevel, subCatLevel, index, isResetAnswer }) {
    let json = {
      StudentId: this.props.clientId,
      SurveyId: this.getSurveyId(),
      SurveyQuestionId: surveyQuestionId,
      SurveyQuestionIndex: index,
      Answer: response,
    };
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });

    const surveys = [...this.state.surveys];
    for (let survey of surveys) {
      if (survey.SurveyQuestionId == surveyQuestionId) {
        survey.Response = response;
      }
    }

    $('.main-banner').slick('slickNext');
    this.setState({
      surveys: surveys,
    });
    if (isResetAnswer) return;

    this.props.dispatch(surveyAnswerUpsert(json));
    //$('.sub-banner').slickNext();
    //this.slider1.slickNext();
  }
  saveResponse = ({ response, surveyQuestionId, index, level, subLevel, subCatLevel }) => {
    let surveyQuestionAssessment = UpdateResponse({
      assessmentQuestion: this.state.surveyQuestionAssessment,
      response,
      index,
      level,
      subLevel,
      subCatLevel,
    });
    const hasMandatory = HasMandatoryQuestion(surveyQuestionAssessment, this.state.SignatureCanvas);
    this.setState({
      surveyQuestionAssessment,
      hasMandatory: hasMandatory,
    });
  };
  saveSignature = (
    signOne,
    data,
    SurveyQuestionId,
    index,
    Response,
    isSignatureSaved,
    isClear,
    isResponceData,
  ) => {
    let me = this;
    if (isClear) {
      let signaturData = me.state.SignatureCanvas;
      let signaturDataList = me.state.SignatureCanvas && me.state.SignatureCanvas.signatureDataList;
      delete signaturData[signOne];
      delete signaturDataList[signOne];
      signaturData.signatureDataList = signaturDataList;
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);
      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
      return;
    }
    if (isResponceData) {
      let signaturData = {
        ...this.state.SignatureCanvas,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);

      this.setState({
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    } else {
      let signaturData = {
        ...this.state.SignatureCanvas,
        [signOne]: data,
        signatureDataList: {
          ...(this.state.SignatureCanvas && this.state.SignatureCanvas.signatureDataList),
          [SurveyQuestionId]: {
            SurveyQuestionId: SurveyQuestionId,
            isSignatureSaved: isSignatureSaved,
            Response: Response,
            IsUpdateSignature: Response ? true : false,
            MediaGuid: Response ? Response : null,
          },
        },
      };
      const hasMandatory = HasMandatoryQuestion(this.state.surveyQuestionAssessment, signaturData);

      this.setState({
        surveyQuestionId: SurveyQuestionId,
        SignatureCanvas: signaturData,
        hasMandatory: hasMandatory,
      });
    }
  };
  markAsCompleteAssessment = (SurveyId, StaffPinHistoryId) => {
    this.props.dispatch(
      markAsComplete({
        SurveyId: SurveyId,
        StaffPinHistoryId: StaffPinHistoryId,
      }),
    );
  };
  submitDischarge = StaffPinHistoryId => {
    const { SurveyId, SignatureCanvas, surveyQuestionId, surveyQuestionAssessment } = this.state;
    let listData =
      SignatureCanvas &&
      SignatureCanvas.signatureDataList &&
      Object.values(SignatureCanvas.signatureDataList);
    let signatureSaveList = listData && listData.filter(item => !item.isSignatureSaved);
    let saveList = listData && listData.filter(item => item.isSignatureSaved);
    signatureSaveList &&
      signatureSaveList.map((item, index) => {
        let jsonData = {
          StudentId: this.getStudentId(),
          SurveyId: this.getSurveyId(),
          SurveyQuestionId: item.SurveyQuestionId,
          Answer: item.Response,
          IsSignature: true,
          Signature: {
            Base64String: SignatureCanvas[item.SurveyQuestionId].replace(
              'data:image/png;base64,',
              '',
            ),
            IsUpdateSignature: item.Response ? true : false,
            MediaGuid: item.MediaGuid,
          },
        };
        Axios.post('Survey/SurveyAnswerUpsert', jsonData)
          .then(response => {
            saveList.push({
              [surveyQuestionId]: {
                SurveyQuestionId: item.SurveyQuestionId,
                isSignatureSaved: true,
              },
            });
          })
          .then(response => {
            if ((listData && listData.length) == (saveList && saveList.length)) {
              this.markAsCompleteAssessment(SurveyId, StaffPinHistoryId);
            }
          });
      });
    if ((listData && listData.length) == (saveList && saveList.length)) {
      this.markAsCompleteAssessment(SurveyId, StaffPinHistoryId);
    }
  };
  getSurveyId() {
    const { SurveyId } = this.state;

    return SurveyId;
  }

  getStudentId = () => {
    // var param = new URLSearchParams(this.props.history.location.search);
    // return param.get('studentId');
    return this.props.clientId;
  };

  onClose() {
    this.props.history.push('/studentProfile/' + this.getStudentId());
  }

  submitConsentForm = () => {
    const { SurveyId, SignatureCanvas, surveyQuestionId } = this.state;
    // let imgByteArr = SignatureCanvas && Object.values(SignatureCanvas);
    // let imgKeyArr = SignatureCanvas && Object.keys(SignatureCanvas);
    // if (!imgByteArr && !imgKeyArr) return;
    // save_consent_sign;
    // Json: "{"Survey":[{"SurveyId":2132}]}"
    this.props.dispatch(
      dischargeSummarySave({
        Json: JSON.stringify({
          Survey: [
            {
              ClientId: this.props.clientId,
              SurveyId: SurveyId,
            },
          ],
        }),
        // ImageSign: JSON.stringify({
        //   Image: imgByteArr.map((item, index) => {
        //     return {
        //       SurveyQuestionId: imgKeyArr[index],
        //       Data: item,
        //     };
        //   }),
        // }),
      }),
    );
  };
  resetValidPinModalVisible = () => {
    this.setState({ validPinModalVisible: !this.state.validPinModalVisible });
  };

  render() {
    const {
      surveys,
      currentSlideIndex,
      surveyListData,
      screeningView,
      surveyQuestionAssessment,
      hasMandatory,
    } = this.state;
    const {
      lastSlideIndex,
      SurveyQuestionCategoryList,
      isFetchingSurveyQuestionAssessment,
      isDeActivated,
    } = this.props;
    const tempColumns = [
      {
        title: 'Screenings',
        dataIndex: 'SurveyName',
        key: 'SurveyName',
        sorter: (a, b) => isComparer(a.Date, b.Date),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Screening Date',
        dataIndex: 'AssignedDate',
        key: 'AssignedDate',
        sorter: (a, b) =>
          isDateComparer(moment(a.AssignedDateForSort), moment(b.AssignedDateForSort)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Completed Date',
        dataIndex: 'CompletedDate',
        key: 'CompletedDate',
        sorter: (a, b) =>
          isDateComparer(moment(a.CompletedDateForSort), moment(b.CompletedDateForSort)),
      },
      {
        title: 'Discarded Date',
        dataIndex: 'DiscardedDate',
        key: 'DiscardedDate',
        sorter: (a, b) =>
          isDateComparer(moment(a.DiscardedDateForSort), moment(b.DiscardedDateForSort)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        render: (text, record, index) => (
          <div class="tools-grid d-flex align-items-center" style={{ border: '0px solid red' }}>
            {!this.props.isReadOnlyUser && record.SurveyStatus === 0 && (
              <>
                <div className="mr-3" style={{ border: '0px solid red', textAlign: 'left' }}>
                  <Button
                    type="link"
                    style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                    loading={this.state.index === index && isFetchingSurveyQuestionAssessment}
                    onClick={event => {
                      this.props.dispatch(
                        listSurveyQuestionAssessment({
                          json: JSON.stringify({
                            Survey: [
                              {
                                SurveyId: record.SurveyId,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        SurveyId: record.SurveyId,
                        isReadOnly: false,
                        index: index,
                      });
                    }}
                  >
                    {this.state.index === index && isFetchingSurveyQuestionAssessment
                      ? 'Loading'
                      : record.IsStart
                      ? 'Resume'
                      : 'Start'}
                  </Button>
                </div>
                <div className="">
                  {!this.props.isReadOnlyUser && (
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <span
                          class="delete-btn"
                          onClick={onClick}
                          data-toggle="modal"
                          data-target="#delete-Screen-Tool"
                        >
                          <Tooltip placement="top" title="Delete">
                            <p>
                              <i class="far fa-trash-alt"></i>
                            </p>
                          </Tooltip>
                        </span>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to delete this discharge form?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                this.props.dispatch(
                                  deactivateClientScreening({
                                    json: JSON.stringify({
                                      Survey: [
                                        {
                                          SurveyId: record.SurveyId,
                                        },
                                      ],
                                    }),
                                  }),
                                );
                                // this.deleteGroup(record.GroupId);
                                // this.props.onCancel();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div>
              </>
            )}
            {record.SurveyStatus === 1 && (
              <>
                <div
                  className=""
                  style={{
                    border: '0px solid red',
                    minWidth: '110px',
                    // paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Button
                    type="link"
                    style={{ minWidth: '110px', textAlign: 'left', paddingLeft: '0px' }}
                    loading={this.state.index === index && isFetchingSurveyQuestionAssessment}
                    onClick={event => {
                      this.props.dispatch(
                        listSurveyQuestionAssessment({
                          json: JSON.stringify({
                            Survey: [
                              {
                                SurveyId: record.SurveyId,
                              },
                            ],
                          }),
                        }),
                      );
                      this.setState({
                        SurveyId: record.SurveyId,
                        isReadOnly: true,
                        index: index,
                      });
                    }}
                  >
                    {this.state.index === index && isFetchingSurveyQuestionAssessment
                      ? 'Loading'
                      : 'View'}
                  </Button>
                </div>
              </>
            )}
            {record.IsUpload && (
              <>
                <div className="">
                  <span
                    // class="start-btn"
                    onClick={event => {
                      this.props.dispatch(
                        consentFormDownload({
                          json: JSON.stringify({
                            Survey: [
                              {
                                ClientId: record.StudentId || this.props.clientId,
                                DocumentGuid: record.DocumentGuid,
                              },
                            ],
                          }),
                        }),
                      );
                    }}
                    className="mr-5"
                    style={{
                      border: '0px solid red',
                      color: '#1890ff',
                      fontSize: '14px',
                      cursor: 'pointer',
                      textAlign: 'left',
                      // paddingLeft: '15px',
                    }}
                    download
                  >
                    Download
                  </span>
                </div>
              </>
            )}
            {record.SurveyStatus === -1 && (
              <div className="">
                <span
                  className="mr-5"
                  style={{
                    border: '0px solid red',
                    color: '#e87a7a',
                    fontSize: '14px',
                    textAlign: 'left',
                    // paddingLeft: '15px',
                  }}
                  download
                >
                  Deleted
                </span>
              </div>
            )}
          </div>
        ),
      },
    ];
    return (
      <>
        <div class="main survery-question-page  rcm-tab mb-5">
          {/* <Loader2 loading={this.props.isFetching} /> */}
          {this.props.isFetchingMarkAsComplete && (
            <div style={{ position: 'relative', height: '500px' }}>
              <Loader2 loading={this.props.isFetchingMarkAsComplete} />
            </div>
          )}

          {!this.props.isFetchingMarkAsComplete && (
            <div>
              {screeningView ? (
                <>
                  <div class="container-fluid">
                    <div className="row">
                      {/* <h3 class="page-title pt-5 mb-3 mb-md-5 ">Screening Tool</h3> */}
                      <div className="col-12 enrollment-form mt-5">
                        {!isDeActivated && (
                          <div className="row px-0">
                            <div class="col-5 px-0">
                              <div class="form-group">
                                <ReduxSelect
                                  options={SurveyQuestionCategoryList}
                                  onChange={value => {
                                    this.setState({ assesmentId: value });
                                    // this.props.dispatch(getSurveyQuestionMultiple(parseInt(value), 20000019));
                                  }}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  // value={defaultCenter}
                                  value={this.state.assesmentId}
                                  placeholder="Select discharge summary form"
                                  disabled={this.props.isReadOnlyUser}
                                />
                              </div>
                            </div>
                            <div class="col-3 d-flex align-items-center">
                              <button
                                class={
                                  this.state.assesmentId
                                    ? 'rcm-sub-tab-btn'
                                    : ' rcm-sub-tab-btn disable-cursor'
                                }
                                onClick={this.onAssignSurvey}
                                disabled={!this.state.assesmentId || this.props.isReadOnlyUser}
                              >
                                Assign
                              </button>
                            </div>
                          </div>
                        )}
                        <CustomModal
                          isOpen={this.state.isAssignAlreadyModal || this.state.isAssignNew}
                          // header={this.state.header}
                          header={
                            this.state.isAssignAlreadyModal
                              ? 'This form is already assigned'
                              : 'The form has been assigned successfully'
                          }
                          yesAction={() => {
                            this.setState({
                              isAssignAlreadyModal: false,
                              isAssignNew: false,
                            });
                            let getSurveyData = {
                              json: JSON.stringify({
                                Survey: [
                                  {
                                    ClientId: this.props.clientId,
                                    SurveyTypeDesc: 'Discharge',
                                  },
                                ],
                              }),
                            };
                            this.props.dispatch(listSurveyQuestionCategoryGetAll(getSurveyData));
                          }}
                          yesText="OK"
                          hideCancel={true}
                          changeMaxWidth="100%"
                        />
                      </div>
                      <div className="col-12 enrollment-form mt-1">
                        <div class="row card-body px-2 rcm-subtab-table-block">
                          <Loader2 loading={this.props.isFetching} />
                          <div class="col-12 rcm-tools-table">
                            <PersistTableSort
                              name="surveyListData"
                              columns={tempColumns}
                              dataSource={surveyListData}
                              loader={this.props.isFetchingSurveyQuestionAssessment}
                              size={'middle'}
                              pagination={{
                                itemRender: (page, type, originalElement) => {
                                  return (
                                    <div class="dataTables_wrapper no-footer">
                                      <div class="dataTables_paginate paging_simple_numbers">
                                        {type == 'prev' ? (
                                          <a class="paginate_button previous">Previous</a>
                                        ) : type == 'next' ? (
                                          <a class="paginate_button next">Next</a>
                                        ) : (
                                          <a class="paginate_button ">{page}</a>
                                        )}
                                      </div>
                                    </div>
                                  );
                                },
                                showSizeChanger: true,
                                // hideOnSinglePage: true,
                                current: this.state.currentPage,
                                onChange: page => {
                                  this.setState({
                                    currentPage: page,
                                  });
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <DocumentForm
                      DocTypeValue={16010}
                      clientId={this.props.clientId}
                      isReadOnlyUser={this.props.isReadOnlyUser}
                      isDeActivated={isDeActivated}
                    />
                  </div>
                </>
              ) : (
                <div class="container-fluid px-0">
                  <div className="row">
                    <div class="col-12 text-center">
                      <Loader2 loading={this.props.isFetching} />
                      <div
                        class="survery-question-box "
                        style={{ maxWidth: 'unset', marginTop: '0px' }}
                      >
                        {/* <h3 class="mb-3 mb-md-5 ">{surveys.length > 0 && surveys[0].SurveyCategory}</h3> */}
                        <div className="screening-tool consent-form-main">
                          <div className="assessment-main-title d-flex align-items-center justify-content-between">
                            <div
                              onClick={() => {
                                this.toggleView(true);
                              }}
                              class="text-left d-flex"
                              style={{ cursor: 'pointer' }}
                            >
                              <img src="images/back-arrow.svg" className="mr-2" /> Back to List
                            </div>
                            <span className="main-title-text">
                              {surveyQuestionAssessment.SurveyMainTitle}
                            </span>

                            <div>
                              <ReactToPrint
                                trigger={() => (
                                  <div className="text-right ml-2" style={{ cursor: 'pointer' }}>
                                    {' '}
                                    Print
                                  </div>
                                )}
                                content={() => this.componentRef}
                                documentTitle="Treatment Plan"
                              />
                            </div>
                          </div>
                          <div
                            className="pageStyle discharge-print"
                            ref={el => (this.componentRef = el)}
                          >
                            {surveyQuestionAssessment && surveyQuestionAssessment.SurveyQuestion && (
                              <div
                                style={{
                                  padding: '25px',
                                  margin: '0px 25px',
                                }}
                              >
                                <QuestionType
                                  SurveyQuestion={surveyQuestionAssessment.SurveyQuestion}
                                  SurveyId={surveyQuestionAssessment.SurveyId}
                                  onNext={this.onNext}
                                  setValue={this.setValue}
                                  saveResponse={this.saveResponse}
                                  scope={this}
                                  level={0}
                                  isReadOnly={this.state.isReadOnly}
                                  saveSignature={this.saveSignature}
                                />
                              </div>
                            )}

                            {surveyQuestionAssessment &&
                              surveyQuestionAssessment.SurveyCategory &&
                              surveyQuestionAssessment.SurveyCategory.map(
                                (surveyCategory, index) => {
                                  return (
                                    <div className="page-break">
                                      <div
                                        className="assessment-sub-title"
                                        style={{
                                          margin: '0px 25px',
                                        }}
                                      >
                                        <span className="sub-title-text">
                                          {surveyCategory.SurveyCategory}
                                        </span>
                                      </div>
                                      <div className="discharge-acc-section ">
                                        <QuestionType
                                          SurveyQuestion={surveyCategory.SurveyQuestion}
                                          SurveyId={surveyQuestionAssessment.SurveyId}
                                          onNext={this.onNext}
                                          setValue={this.setValue}
                                          saveResponse={this.saveResponse}
                                          scope={this}
                                          level={1}
                                          subLevel={index}
                                          isReadOnly={this.state.isReadOnly}
                                          saveSignature={this.saveSignature}
                                        />
                                      </div>

                                      {surveyQuestionAssessment.SurveyCategory.length - 1 ==
                                        index &&
                                        surveyQuestionAssessment &&
                                        surveyQuestionAssessment.SignInfo &&
                                        surveyQuestionAssessment.SignInfo[0] &&
                                        surveyQuestionAssessment.SignInfo[0].SignedBy && (
                                          <h5 style={{ textAlign: 'left', paddingLeft: '27px' }}>
                                            {' '}
                                            Signed By:-{' '}
                                            {surveyQuestionAssessment &&
                                              surveyQuestionAssessment.SignInfo &&
                                              surveyQuestionAssessment.SignInfo[0] &&
                                              surveyQuestionAssessment.SignInfo[0].SignedBy}
                                          </h5>
                                        )}
                                    </div>
                                  );
                                },
                              )}
                          </div>

                          <div class="col-12 col-md-12 col-lg-12 mt-5">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <HasMandatoryQuestionWarning hasMandatory={hasMandatory} />
                              </div>
                              <div>
                                <input
                                  type="button"
                                  class=" btn btn-outline-primary mr-2"
                                  value={
                                    this.state.isReadOnly ? 'Back to List' : 'Save and Back to List'
                                  }
                                  onClick={() => {
                                    this.toggleView(true);
                                  }}
                                />
                                <AntdModal
                                  ModalButton={({ onClick }) => (
                                    <Tooltip placement="top" title="Un-sign">
                                      <span class="delete-course" onClick={onClick}>
                                        {this.props.surveyQuestionAssessment
                                          .IsEligibleForUnSign && (
                                          <span className="mt-2 mb-2 mr-2 danger-custom-btn">
                                            Un-sign
                                          </span>
                                        )}
                                      </span>
                                    </Tooltip>
                                  )}
                                  ModalContent={({ onOk, onCancel }) => (
                                    <div class="modal-content border-0">
                                      <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                        <button
                                          type="button"
                                          class="close"
                                          data-dismiss="modal"
                                          onClick={onCancel}
                                        >
                                          &times;
                                        </button>
                                        <h4 class="modal-title col-12">
                                          Are you sure you want to Un-sign?
                                        </h4>
                                      </div>

                                      <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                        <button
                                          type="button"
                                          class="btn black-btn"
                                          data-dismiss="modal"
                                          onClick={onCancel}
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="button"
                                          class="btn "
                                          onClick={() => {
                                            onOk();
                                            this.setState({
                                              clickedSurveyId: surveyQuestionAssessment.SurveyId,
                                            });
                                            this.unsignFormFun(
                                              'Survey',
                                              surveyQuestionAssessment.SurveyId,
                                            );
                                            // this.cancelGroupCall(Data.VMCallSessionId);
                                          }}
                                        >
                                          Yes
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                />

                                {!this.state.isReadOnly && (
                                  <input
                                    type="button"
                                    class=" btn btn-outline-primary"
                                    value="Sign and Submit"
                                    disabled={hasMandatory}
                                    onClick={() => {
                                      let data = {
                                        Json: JSON.stringify({
                                          Staff: [
                                            {
                                              FormType: 'Survey',
                                              FormId: surveyQuestionAssessment.SurveyId,
                                            },
                                          ],
                                        }),
                                      };
                                      let me = this;
                                      Axios.post('Medicaid/PreValidatePin', data).then(response => {
                                        let isValid = JSON.parse(response.data.data)[0].Staff[0]
                                          .IsValid;
                                        let failMsg = JSON.parse(response.data.data)[0].Staff[0]
                                          .FailMessage;
                                        let IsRealSignature = JSON.parse(response.data.data)[0]
                                          .Staff[0].IsRealSignature;
                                        if (isValid) {
                                          this.setState({
                                            validPinModalVisible: true,
                                            isSignStatus: true,
                                            IsRealSignature: IsRealSignature,
                                          });
                                        } else {
                                          me.props.toggle(failMsg);
                                          me.setState({
                                            isSignStatus: false,
                                          });
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <ValidatePin
            validPinModalVisible={this.state.validPinModalVisible}
            resetValidPinModalVisible={this.resetValidPinModalVisible}
            FormType={'Survey'}
            FormId={surveyQuestionAssessment.SurveyId}
            IsRealSignature={this.state.IsRealSignature}
          />
          <CustomModalPopup
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggleView(true);
              this.toggle();
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  survery_question_get_multiple,
  survey_assign,
  list_survey_question_assessment,
  deactivate_client_screening,
  list_survey_question_discharge,
  discharge_summary_save,
  consent_form_download,
  validate_staff_pin,
  mark_as_complete,
  unsign_form,
  list_survey_question_category_get_all,
}) => {
  const { data, isFetching, error } = survery_question_get_multiple;
  const { isFetching: isFetchingMarkAsComplete } = mark_as_complete;
  const { data: unsignForm } = unsign_form;
  var { data: validateDataPin } = validate_staff_pin;
  const {
    data: surveyQuestionAssessmentData,
    isFetching: isFetchingSurveyQuestionAssessment,
  } = list_survey_question_assessment;
  const { data: surveyAssignData } = survey_assign;
  const {
    data: listConsentFormData,
    isFetching: isFetchingListConsent,
  } = list_survey_question_category_get_all;
  const { data: saveDischargeData, isFetching: isFetchingSaveConsent } = discharge_summary_save;
  const {
    data: downloadConsentFormData,
    isFetching: isFetchingDownloadConsent,
    error: errorDownload,
  } = consent_form_download;
  var lastSlideIndex = 1;
  let ConsentQuestionCategoryList;
  let ConsentListData = [];
  let surveyData = [];
  let surveyQuestionAssessment;
  let isSurveyAssign = false;
  let isSurveyDeactivated = false;
  let downloadUrl;
  let downloadError;
  let isSignedDischarge = false;
  let validateStaffPinData = {};
  let isSurveyCompleted = false;
  var unsignFormStatus = false;
  let isSurveyCompletedResult = null;
  if (validateDataPin) {
    validateStaffPinData = validateDataPin;
  }
  if (data) {
    let array = [];
    for (let index in data) {
      array.push(data[index]);
    }
    //array = array.slice(0, 10);

    array.push({});
    surveyData = [...array];
    lastSlideIndex = data.length - 2;
  }
  if (surveyQuestionAssessmentData) {
    surveyQuestionAssessment = surveyQuestionAssessmentData;
  }
  if (listConsentFormData && listConsentFormData.SurveyQuestionCategoryList) {
    ConsentQuestionCategoryList = listConsentFormData.SurveyQuestionCategoryList;
  }
  if (listConsentFormData && listConsentFormData.SurveyList) {
    ConsentListData = listConsentFormData.SurveyList;
  }
  if (surveyAssignData && surveyAssignData.success) {
    isSurveyAssign = surveyAssignData.success;
  }
  if (deactivate_client_screening.data && deactivate_client_screening.data.success) {
    isSurveyDeactivated = deactivate_client_screening.data.success;
  }

  if (downloadConsentFormData && downloadConsentFormData.success) {
    downloadUrl = downloadConsentFormData.result;
  } else if (errorDownload) {
    downloadError = errorDownload;
  }
  if (saveDischargeData && saveDischargeData.success) {
    isSignedDischarge = saveDischargeData.success;
  }
  if (mark_as_complete.data && mark_as_complete.data.success) {
    isSurveyCompletedResult = mark_as_complete.data.result;
    isSurveyCompleted = mark_as_complete.data.success;
  }
  if (unsignForm && unsignForm.success) {
    unsignFormStatus = unsignForm.success;
  }
  return {
    surveys: surveyData,
    surveyQuestionAssessment,
    isSurveyAssign,
    isSurveyDeactivated: isSurveyDeactivated,
    lastSlideIndex: lastSlideIndex,
    SurveyQuestionCategoryList: ConsentQuestionCategoryList || [],
    SurveyListData: ConsentListData,
    // isFetchingListSurvey: isFetchingListSurvey || isFetching || false,
    isFetching: isFetching || isFetchingListConsent || isFetchingSaveConsent,
    isFetchingSurveyQuestionAssessment: isFetchingSurveyQuestionAssessment,
    downloadUrl,
    downloadError,
    isSignedDischarge,
    validateStaffPinData,
    isFetchingMarkAsComplete,
    isSurveyCompleted,
    isSurveyCompletedResult,
    unsignFormStatus,
  };
};

export default connect(mapStateToProps)(withRouter(DischargeSummery));
