import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Cascader, Icon, Checkbox, Modal, Steps, Radio, Space } from 'antd';
import Twilio from '../../Components/twilio';
import {
  getAppUserSubscription,
  getServiceOptions,
  getServiceAdvisorList,
  setServiceAdvisor,
  updateCancelService,
  setServiceSubscription,
  setMembership,
} from '../../store/actions';
import Loader, { Loader2 } from '../../Components/Loader';
import { currencyFormatter2 } from '../../util/formatter';
import CustomModal from '../../Components/CustomModal';

const { Step } = Steps;

class ModifyServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      ServiceSubscriptionTypeId: null,
      steps: [
        {
          title: 'Choose Plan',
          content: (
            <ChoosePlan
              UserGuid={props.UserGuid}
              getMembershipValue={this.getMembershipValue}
              {...props}
            />
          ),
        },
        {
          title: 'Service Type',
          content: (
            <ServiceType
              UserGuid={props.UserGuid}
              getServiceSubscriptionValue={this.getServiceSubscriptionValue}
              {...props}
            />
          ),
        },
        {
          title: 'Assign ',
          content: <ReduxAssign UserGuid={props.UserGuid} getAdvisorValue={this.getAdvisorValue} />,
        },
        {
          title: 'Payment ',
          content: <Payment UserGuid={props.UserGuid} {...props} />,
        },
      ],
    };
  }

  handleNext = () => {
    let currentKey = this.state.current + 1;
    if (this.state.current === 0) {
      let jsonData = {
        UserSubscription: [
          {
            UserGuid: this.props.UserGuid,
            SubscriptionTypeId: this.state.value,
          },
        ],
      };
      this.props.dispatch(
        setMembership({
          Json: JSON.stringify(jsonData),
        }),
      );
    }
    if (this.state.current === 1) {
      let jsonData = {
        UserSubscription: [
          {
            AppUserGuid: this.props.UserGuid,
            ServiceSubscriptionTypeId: this.state.ServiceSubscriptionTypeId,
          },
        ],
      };

      this.props.dispatch(
        getServiceAdvisorList({
          Json: JSON.stringify({
            UserSubscription: [
              {
                UserGuid: this.props.UserGuid,
                ServiceSubscriptionTypeId: this.state.ServiceSubscriptionTypeId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(
        setServiceSubscription({
          Json: JSON.stringify(jsonData),
        }),
      );
    }
    if (this.state.current === 2) {
      let jsonData = {
        UserSubscription: [
          {
            AppUserGuid: this.props.UserGuid,
            AdvisorUserGuid: this.state.advisorValue,
          },
        ],
      };
      this.props.dispatch(
        setServiceAdvisor({
          Json: JSON.stringify(jsonData),
        }),
      );
    }
    this.setState({
      current: currentKey,
    });
  };
  handlePrevious = () => {
    let currentKey = this.state.current - 1;
    this.setState({
      current: currentKey,
    });
  };
  componentDidMount() {
    let jsonData = {
      UserSubscription: [
        {
          AppUserGuid: this.props.UserGuid,
          ServiceSubscriptionTypeId: 3,
        },
      ],
    };
    this.props.dispatch(
      getServiceAdvisorList({
        Json: JSON.stringify(jsonData),
      }),
    );
  }
  getServiceSubscriptionValue = value => {
    this.state.ServiceSubscriptionTypeId = value;
  };
  getAdvisorValue = value => {
    this.state.advisorValue = value;
  };
  getMembershipValue = value => {
    this.state.value = value;
  };
  onStepClick = current => {
    this.setState({
      current: current,
    });
  };
  render() {
    const { onOk, onCancel } = this.props;
    const { steps, current } = this.state;
    return (
      <div className="modal-content border-0">
        <div className="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 className="modal-title col-12 mb-3">Modify Service</h4>
        </div>

        <div className="modal-body col-md-12 px-5 enrollment-form modify-service">
          <Steps current={current} onChange={this.onStepClick}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content mb-5">{steps[current].content}</div>
        </div>
        <div class="modal-footer  border-top-0 pb-4 pt-2">
          {false && (
            <CustomModal
              modalWidth={500}
              ModalButton={({ onClick }) => (
                // <span className="dismiss" onClick={onClick}>
                //   Dismiss
                // </span>
                <button onClick={onClick} className="btn btn-outline-primary px-5">
                  Cancel Service
                </button>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                    <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
                      &times;
                    </button>
                    <h6 class="modal-title col-12">
                      Are you sure you want to cancel the current service for Maria garcia?
                    </h6>
                  </div>
                  <div className="modal-body dismiss-request">
                    <div className="row">
                      <div className="col-md-10 offset-md-1 text-center">
                        <img
                          src="images/blank-profile.png"
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '8px',
                            margin: '10px 5px',
                          }}
                        />
                        <div className="mb-2">
                          <span className="name">Counselor</span>
                        </div>
                        <div className="date-info mb-2">
                          <span className="text">Renewal Date</span>
                          <span className="value">13-July-2022</span>
                        </div>
                        <div className="fee-info mb-5">
                          <span className="value">$225</span>
                          <span className="text">/month</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      type="button"
                      class="btn btn-outline-primary px-5"
                      data-dismiss="modal"
                      onClick={onCancel}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      class="btn "
                      onClick={() => {
                        onOk();
                      }}
                    >
                      Yes, cancel
                    </button>
                  </div>
                </div>
              )}
            />
          )}
          {current > 0 && (
            <button onClick={this.handlePrevious} className="btn btn-outline-primary mr-2 px-4">
              Back
            </button>
          )}
          {current < steps.length - 1 && (
            <button onClick={this.handleNext} className="btn px-5">
              Next
            </button>
          )}
          {current === steps.length - 1 && (
            <button
              // onClick={this.handleNext}
              className="btn px-5"
            >
              Pay
            </button>
          )}
        </div>
      </div>
    );
  }
}
class ServiceType extends Component {
  constructor(props) {
    super(props);
    const { CurrentService } = props;
    this.state = {
      value: (CurrentService && CurrentService.SubscriptionTypeId) || 1,
    };
  }

  render() {
    const onChange = e => {
      this.setState({
        value: e.target.value,
      });
      this.props.getServiceSubscriptionValue(e.target.value);
    };
    const { manageService } = this.props;
    return (
      <>
        <div className="row mt-5">
          <Radio.Group onChange={onChange} value={this.state.value} style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {manageService &&
                manageService.map(item => {
                  return (
                    <div
                      className={`col-12 ${
                        item.Badge === 'Counselor' ? 'counselor-box' : 'coach-box'
                      }`}
                    >
                      {/* current-service */}
                      <div className="c-info">
                        <div className="d-flex align-items-center">
                          <div>
                            <Radio value={item.SubscriptionTypeId} />
                          </div>
                          <img
                            src={item.AdvisorIconUrl || 'images/blank-profile.png'}
                            style={{
                              width: '80px',
                              height: '80px',
                              borderRadius: '8px',
                              marginRight: '10px',
                              objectFit: 'cover',
                            }}
                          />
                          <div className="">
                            <div className="">
                              <span className="name" style={{ fontSize: '20px', color: '#46B4FE' }}>
                                {item.SubscriptionName}
                              </span>
                            </div>
                            <div>
                              <span className="info-text">
                                Licensed Professional Counselor, Georgia, #13579
                              </span>
                            </div>
                            <div className="fee-info">
                              <span className="fee">
                                {currencyFormatter2.format(item.Cost || 0)}
                              </span>
                              <span className="text">/month</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <div className="col-12 coach-box">
                <div className="c-info">
                  <div className="d-flex align-items-center">
                    <div>
                      <Radio value={2} />
                    </div>
                    <img
                      src="images/blank-profile.png"
                      style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '8px',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    <div className="">
                      <div className="">
                        <span className="name">Coach</span>
                      </div>
                      <div>
                        <span className="info-text">
                          Licensed Professional Counselor, Georgia, #13579
                        </span>
                      </div>
                      <div className="fee-info">
                        <span className="fee">$225</span>
                        <span className="text">/month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  }
}
// const ReduxServiceType = connect(mapStateToProps)(ServiceType);
class ChoosePlan extends Component {
  constructor(props) {
    super(props);
    const { CurrentPlan } = props;
    this.state = {
      value: (CurrentPlan && CurrentPlan.SubscriptionTypeId) || 1,
    };
  }

  render() {
    const onChange = e => {
      this.setState({
        value: e.target.value,
      });
      this.props.getMembershipValue(e.target.value);
    };
    const { manageMembership } = this.props;
    return (
      <>
        <div className="row mt-5">
          <Radio.Group onChange={onChange} value={this.state.value} style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {manageMembership &&
                manageMembership.map(item => {
                  return (
                    <div
                      className={`col-12 ${
                        item.Badge === 'Premium' ? 'counselor-box' : 'coach-box'
                      }`}
                    >
                      <div className="c-info">
                        <div className="d-flex align-items-center">
                          <div>
                            <Radio value={item.SubscriptionTypeId} />
                          </div>
                          <img
                            src={item.AdvisorIconUrl || 'images/blank-profile.png'}
                            style={{
                              width: '80px',
                              height: '80px',
                              borderRadius: '8px',
                              marginRight: '10px',
                              objectFit: 'cover',
                            }}
                          />
                          <div className="">
                            <div className="">
                              <span className="name" style={{ fontSize: '20px', color: '#46B4FE' }}>
                                {item.SubscriptionName}
                              </span>
                            </div>

                            <div className="fee-info">
                              <span className="">Renews on </span>
                              <span className="text">{'13-July-2022'}</span>
                              {/* <span className="date">{moment(item.RenewalDate).format('ll')}</span> */}
                            </div>
                            <div className="fee-info">
                              <span className="fee">
                                {currencyFormatter2.format(item.Cost || 0)}
                              </span>
                              <span className="text">/month</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* <div className="col-12 coach-box">
                <div className="c-info">
                  <div className="d-flex align-items-center">
                    <div>
                      <Radio value={2} />
                    </div>
                    <img
                      src="images/icons/star_blue.svg"
                      style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '8px',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    <div className="">
                      <div className="">
                        <span className="name">Plus Membership</span>
                      </div>
                      <div>
                        <span className="text">Renews on</span>
                        <span className="date">{'13-July-2022'}</span>
                      </div>
                      <div className="fee-info">
                        <span className="fee">$49</span>
                        <span className="text">/month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  }
}
// const ReduxChoosePlan = connect(mapStateToProps)(ChoosePlan);
class Assign extends Component {
  constructor() {
    super();
    this.state = {
      value: null,
      data: {},
    };
  }
  onSearchChange = control => {
    var { value } = control.target;
    this.state.searchStr = value;
    // this.refreshGrid();
  };
  // componentDidMount() {
  //   let jsonData = {
  //     UserSubscription: [
  //       {
  //         UserGuid: this.props.UserGuid,
  //         ServiceSubscriptionTypeId: 4,
  //       },
  //     ],
  //   };
  //   this.props.dispatch(getServiceAdvisorList({ Json: JSON.stringify(jsonData) }));
  // }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };

  render() {
    const onChange = e => {
      this.setState({
        value: e.target.value,
      });
    };
    const { advisorList } = this.props;
    const { data } = this.state;
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <div className="d-flex align-items-center flex-column">
              <img
                src={data.ProfileImageUrl || 'images/blank-profile.png'}
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  marginRight: '10px',
                  objectFit: 'cover',
                }}
              />
              <span>{data.DisplayName}</span>
            </div>
          </div>
          <div className="col-12">
            <div class="search-box d-inline-flex w-100" style={{ maxWidth: 'unset' }}>
              <div class="form-group has-search w-100">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  // placeholder={`Search ${SubscriptionTypeId === 4 ? 'Counselor' : 'Coach'}...`}
                  onChange={this.onSearchChange}
                  style={{ paddingLeft: '10px' }}
                />

                <button class="btn search-icon " type="button">
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="#000"
                      fill-rule="nonzero"
                      d="M6 14.203c0 1.425.371 2.828 1.073 4.06a.781.781 0 1 0 1.357-.775 6.639 6.639 0 0 1-.868-3.285 6.648 6.648 0 0 1 6.641-6.64 6.648 6.648 0 0 1 6.64 6.64 6.642 6.642 0 0 1-9.89 5.793.781.781 0 1 0-.766 1.362 8.208 8.208 0 0 0 4.016 1.048c1.972 0 3.783-.7 5.2-1.863l5.265 5.23a.779.779 0 0 0 1.105-.004.781.781 0 0 0-.004-1.105l-5.258-5.222a8.165 8.165 0 0 0 1.895-5.239C22.406 9.68 18.726 6 14.203 6S6 9.68 6 14.203z"
                    />
                  </svg>{' '}
                </button>
              </div>
            </div>
          </div>
          <div className="col-12" style={{ height: '250px', overflowY: 'scroll' }}>
            <div className="row">
              {advisorList &&
                advisorList.map((item, index) => {
                  return (
                    <div className="col-12 mb-2" key={index}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <Radio
                            onChange={() => {
                              this.props.getAdvisorValue(item.UserGuid);
                              this.setState({
                                value: item.UserGuid,
                                data: item,
                              });
                            }}
                            name={item.UserGuid}
                            value={item.UserGuid}
                            checked={item.UserGuid === this.state.value ? true : false}
                          />
                          <img
                            src={item.ProfileImageUrl || 'images/blank-profile.png'}
                            style={{
                              width: '50px',
                              height: '50px',
                              borderRadius: '50%',
                              marginRight: '10px',
                              objectFit: 'cover',
                            }}
                          />
                          <div className="d-flex flex-column">
                            <span className="name" style={{ fontSize: '20px', color: '#46B4FE' }}>
                              {item.DisplayName}
                            </span>
                            <span className="info-text">Teen challenge center</span>
                          </div>
                        </div>
                        <div>
                          <span
                            title={`Call ${item.DisplayName}`}
                            style={{
                              cursor: 'pointer',
                              width: '125px',
                              marginRight: '10px',
                              color: '#46B4FE',
                            }}
                            onClick={() => {
                              this.setState({
                                callModelVisible: true,
                                clickedRecord: {
                                  Name: item.DisplayName,
                                  Phone: item.Phone,
                                  ProfileImageUrl: item.ProfileImageUrl,
                                },
                              });
                            }}
                          >
                            {item.Phone}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {this.state.callModelVisible && (
            <Twilio
              isOpen={this.state.callModelVisible}
              userData={this.state.clickedRecord}
              resetIsOpen={this.resetIsOpen}
            />
          )}
        </div>
      </>
    );
  }
}
const mapStateToPropsAssign = state => {
  const { get_service_advisor_list } = state;
  const { data, isFetching } = get_service_advisor_list;

  let advisorList = null;

  if (data && data.UserSubscription) {
    advisorList = data.UserSubscription;
  }
  return {
    advisorList,
    isFetching: isFetching,
  };
};
const ReduxAssign = connect(mapStateToPropsAssign)(Assign);
class Payment extends Component {
  constructor() {
    super();
    this.state = {
      value: null,
      data: {},
    };
  }

  render() {
    const {} = this.props;
    const { data } = this.state;
    return (
      <>
        <div className="row mt-5">
          <h4>Payment</h4>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  const { get_service_advisor_list, get_service_option } = state;
  const { data } = get_service_advisor_list;
  const { data: optionsData, isFetching } = get_service_option;

  let manageService;
  let manageMembership;

  if (optionsData && optionsData.UserSubscription) {
    manageService = optionsData.UserSubscription[0].ManageService;
    manageMembership = optionsData.UserSubscription[0].ManageMembership;
  }
  // if (update_app_subscribers_case.data && update_app_subscribers_case.data.success) {
  //   isSuccess = data.success;
  // }

  return {
    isFetching: isFetching,
    manageService,
    manageMembership,
  };
};

export default connect(mapStateToProps)(withRouter(ModifyServiceModal));
