import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* taskClientUpsert(action) {
  try {
    const { payload } = action;
    const response = yield API.taskClientUpsert(payload);
    yield put({
      type: ActionTypes.TASK_CLIENT_UPSERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TASK_CLIENT_UPSERT_FAILURE,
    });
  }
}

export function* taskClientListGet(action) {
  try {
    const { payload } = action;
    const response = yield API.taskClientListGet(payload);
    yield put({
      type: ActionTypes.TASK_CLIENT_LIST_GET_SUCCESS,
      payload:  JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TASK_CLIENT_LIST_GET_FAILURE,
    });
  }
}

export function* taskClientUpdate(action) {
  try {
    const { payload } = action;
    const response = yield API.taskClientUpdate(payload);
    yield put({
      type: ActionTypes.TASK_CLIENT_UPDATE_SUCCESS,
      payload: {...response.data,StatusId:payload.StatusId},
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TASK_CLIENT_UPDATE_FAILURE,
    });
  }
}

export function* taskClientReassign(action) {
  try {
    const { payload } = action;
    const response = yield API.taskClientReassign(payload);
    yield put({
      type: ActionTypes.TASK_CLIENT_REASSIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.TASK_CLIENT_REASSIGN_FAILURE,
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(ActionTypes.TASK_CLIENT_UPSERT_REQUEST, taskClientUpsert),
    takeLatest(ActionTypes.TASK_CLIENT_LIST_GET_REQUEST, taskClientListGet),
    takeLatest(ActionTypes.TASK_CLIENT_UPDATE_REQUEST, taskClientUpdate),
    takeLatest(ActionTypes.TASK_CLIENT_REASSIGN_REQUEST, taskClientReassign),
  ]);
}
