export const URLs = {
  upsertFormData: `GenericForm/UpsertFormData`,
  formDataGet: 'GenericForm/FormDataGet',
  getFormFieldMetaData: `GenericForm/GetFormFieldMetaData`,
  forgetPassword: 'forgetPassword/SendMail',
  resetPassword: 'login/ResetPassword',
  getDashboard: `dashboard/GetAllInfo_Json`,
  listStudents: `student/list`,
  addStudent: `student/Upsert`,
  addStudentNote: `student/InsertNote`,
  studentNoteListGet: `Student/StudentNoteListGet`,
  testError: 'student/TestError',
  upsertStudentTask: `student/TaskUpsertMultiple`,
  courseAssign: `student/MultipleCourseAssign`,
  upsertStudentPin: `student/UpsertPin`,
  validateStudentPin: `student/ValidatePin`,
  getStudent: `student/Student_GetAllInfo_Json`,
  studentMarkComplete: `student/MarkComplete`,
  studentAddExistingTask: `student/AddExistingTaskMultiple`,
  studentDischarge: `student/Discharge`,
  studentReinstate: `student/StudentReinstate`,
  studentUnAssign: `student/UnAssign`,
  getSurveys: `Survey/SurveyStudentsGetAllPending`,
  getSurveyQuestionMultiple: `Survey/SurveyQuestionGetMultiple`,
  surveyAnswerUpsert: `Survey/SurveyAnswerUpsert`,
  surveyAssign: `Survey/AssignMultiple`,
  surveyGetToAssign: `Survey/GetToAssign`,
  listCombos: `combo/list`,
  listFilterCombo: `Filtercombo/list`,
  listSmartCombos: `SmartCombo/list`,
  listTodo: `todo/list`,
  listTodoByStudent: `todo/listByStudentId`,
  listActivity: `ActivityLog/list`,
  listActivityByStudent: `ActivityLog/listByStudentId`,
  listCourses: `student/GetCurrentCourses`,
  addTodo: `todo/Upsert`,
  studentTaskUnAssign: `student/TaskUnassign`,
  studentTaskMarkComplete: `student/TaskMarkComplete`,
  surveyTaskMarkInComplete: `survey/MarkAsDiscard`,
  getReportData: `report/GetReportData`,
  adminstrationListCourse: `Adminstration/ListCourse`,
  adminstrationListStaff: `Adminstration/ListStaff`,
  adminstrationListCenter: `Adminstration/ListCenter`,
  adminstrationGetCenter: `Adminstration/GetCenter`,
  getCenterFieldByCenter: `Adminstration/GetCenterFieldByCenter `,
  adminstrationCourseUpsert: `Adminstration/UpsertCourse`,
  adminstrationCourseDelete: `Adminstration/DeleteCourse`,
  adminstrationGetStaff: `Adminstration/GetStaff`,
  adminstrationStaffUpsert: `Adminstration/UpsertStaff`,
  adminstrationStaffDelete: `Adminstration/DeleteStaff`,
  adminstrationGetCourse: `Adminstration/GetCourse`,
  adminstrationCenterUpsert: `Adminstration/UpsertCenter`,
  adminstrationCenterDelete: `Adminstration/DeleteCenter`,
  getStudentSurveyDashboardData: `Student/Student_Survey_Dashboard_Get_Json`,
  announcementGetAll: `Adminstration/ListAnnouncement`,
  announcementGetJson: `Adminstration/GetAnnouncement`,
  announcementUpsert: `Adminstration/UpsertAnnouncement`,
  announcementDelete: `Adminstration/DeleteAnnouncement`,
  updateStaffEmail: `User/UpdateEmail`,
  updateStaffPassword: `User/UpdatePassword`,
  updateStaffCenter: `User/UpdateCenter`,
  logDevice: `login/LogDevice`,
  logError: `user/LogError`,
  loadDefaultValues: `user/LoadDefaultValues`,
  loadMenu: `user/ListMenu`,
  logOut: `login/LogOut`,
  surveyAnswersGet: `Survey/Survey_Answers_Get`,
  getStudentProfileDashboardData: `Student/Student_Dashboard_Json`,
  getStudentsSearch: 'student/StudentsSearch',
  getUserRole: 'UserRole/List',
  getListJobTitle: 'UserRole/ListJobTitle',
  updateJobTitle: 'UserRole/UpsertJobTitle',
  updateRole: 'UserRole/Upsert',
  deleteRole: 'UserRole/Delete',
  deleteJobTitle: 'UserRole/DeleteJobTitle',
  updateRolePermissions: 'UserRole/UpdatePermissions',
  updateJobTitleSync: 'UserRole/JobTitleRoleSync',
  getContract: 'student/GetContract',
  upsertContract: 'student/UpsertContract',
  deleteContract: 'student/DeleteContract',
  getContractTemplate: 'ContractTemplate/GetContractTemplate',
  upsertContractTemplate: 'ContractTemplate/UpsertContractTemplate',
  getStudentContractTemplate: 'ContractTemplate/GetContractTemplate',
  createContractFromTemplate: 'ContractTemplate/CreateContractFromTemplate',
  addFavoriteTemplate: 'ContractTemplate/AddFavoriteTemplate',
  removeFavoriteTemplate: 'ContractTemplate/RemoveFavoriteTemplate',
  uploadStaffImage: 'Adminstration/UploadStaffImage',
  closeContract: 'Student/CloseContract',
  userFeedback: 'user/feedback',
  isLicenseAvailable: 'Adminstration/IsLicenseAvailable',
  getDashboardLoadAppUsers: `AppSubscribers/LoadAppUsers`,
  getLoadUserInfo: `user/LoadUserInfo`,
  getValidateImage: `user/ValidateImage`,
  getLicenseActivites: `Adminstration/GetLicenseActivites`,
  getCurrentLicenses: `Adminstration/GetCurrentLicenses`,
  getLicensesInvoice: `Licensing/InvoiceGet`,
  getLicensesReceipt: `Licensing/ReceiptUrlGet`,
  addLicenses: `Licensing/AddLicense`,
  startFreeLicense: `Adminstration/StartFreeLicense`,
  getPaymentInfo: `Licensing/PaymentInfoGet`,
  updatePaymentMethode: `Licensing/PaymentMethodUpdate`,
  isAchValidation: `Licensing/IsCheckingAccountValidationRequired`,
  achValidate: `Licensing/BankAccountValidate`,
  getBillingInfo: `Licensing/GetBillingInfo`,
  getStripeApiKey: `Licensing/GetStripeApiPublicKey`,
  addNewApplicant: 'Student/AddNewApplicant',
  listAppSubscribers: 'AppSubscribers/List',
  getAppSubscriberProfileDashboard: 'AppSubscribers/Load',
  getAppSubscribersProfile: 'AppSubscribers/LoadProfile',
  getAppSubscribersChat: 'AppSubscribers/LoadChat',
  getAppSubscriberChatCoach: 'AppSubscribers/LoadAppUserCoachChat',
  getAppSubscriberProfileActivities: 'AppSubscribers/LoadActivities',
  addAppSubscribersSurvey: 'AppSubscribers/AddSurvey',
  addAppSubscriberNote: 'AppSubscribers/AddNote',
  addAppSubscriberTask: 'AppSubscribers/TaskUpsertMultiple',
  deleteAppSubscriberTask: 'AppSubscribers/TaskUnassign',
  deleteAppSubscribersSurvey: 'AppSubscribers/MarkAsDiscard',
  appSubscribersUserSearch: 'AppSubscribers/UsersSearch',
  appSubscriberCompleteTask: 'AppSubscribers/TaskMarkComplete',
  addAppSubscriberCourse: 'AppSubscribers/CourseAssignMultiple',
  appSubscribersupdateCase: 'AppSubscribers/UpdateProfileMisc',
  deleteAppSubscriberCourse: 'AppSubscribers/CourseUnAssign',
  appSubscriberCompleteCourse: 'AppSubscribers/CourseMarkComplete',
  listMobileAppRoleGet: 'combo/List_MobileAppRole_Get',
  spListCombos: `combo/SPList`,
  appSubscribersMapHistory: 'AppSubscribers/TriggeredHistory',
  appInvite: `Adminstration/SendMobileAppInvite`,
  getMobileAppDownloadStatus: `Adminstration/GetMobileAppDownloadStatus`,
  updateMobileAppRole: `Adminstration/UpdateStaffMobileAppRole`,
  addNoteType: `Adminstration/AddNotesForCenter`,
  changeAppRole: `Adminstration/UpdateUserRole`,
  twoStepAuth: `Adminstration/GetStaffPassCode`,
  validatePasscode: `Adminstration/ValidateStaffPassCode`,
  appInviteDashboard: `Adminstration/SendMobileAppInvite_Dashboard`,
  getStaffShiftLog: `Adminstration/GetStaffShiftLog`,
  addStaffShiftLog: `Adminstration/UpsertStaffShiftLog`,
  isManageLicenseRequired: 'Adminstration/IsManageLicenseRequired',
  getAllCallSession: `Counselor/Counselor_CallSession_GetAllInfo_Json`,
  addCallSession: `Counselor/Counselor_CallSession_Upsert`,
  getAllVacation: `Counselor/Counselor_Vacation_GetAllInfo_Json`,
  addVacationDays: `Counselor/Counselor_Vacation_Upsert`,
  getOneCallTimeSlots: `Counselor/Counselor_TimeSlots_GetAllInfo_Json`,
  getOneCallSession: `Counselor/Counselor_CallSession_GetAllInfo_Json`,
  inviteOneCallSession: `Counselor/Counselor_CallSession_Invite`,
  cancelOneCallSession: `Counselor/Counselor_CallSession_Cancel`,
  editGroupCallSession: `Counselor/Counselor_CallSession_GroupCall_Get_Json`,
  getCallSessionVideo: `Counselor/Counselor_CallSession_VideoList_Get_Json`,
  getCallSessionVideoUrl: `Counselor/Counselor_GetVideoUrl`,
  staffReactivate: `Adminstration/ReActivateStaff`,
  getReports: `dashboard/GetReports`,
  getPromoCode: `Adminstration/GetPromoCode`,
  getSmallGroup: `SmallGroups/LoadSmallGroup`,
  addSmallGroup: `SmallGroups/UpsertSmallGroup`,
  getCounselor: `Adminstration/GetCounselor`,
  getClientCounselor: `Adminstration/GetCounselorByCenter`,
  getDynamicComos: `combo/ListDynamic`,
  sendVoucherCode: `SmallGroups/SendVoucherCode`,
  getSmallGroupList: `SmallGroups/ListSmallGroup`,
  getSmallGroupUsers: `SmallGroups/ListSmallGroupUser`,
  uploadLeaderPhoto: `SmallGroups/UploadPicture`,
  markSystemObservation: 'Student/MarkSystemObservation',
  appMarkSystemObservation: 'AppSubscribers/MarkSystemObservation',
  getAnalytics: 'Dashboard/GetAnalytics',
  getActiveClient: 'Dashboard/GetActiveClient',
  isStaffProBio: 'SmallGroups/CheckStaffProfessionalBio',
  updateStaffProBio: 'Adminstration/UpsertStaffProfessionalBio',
  getNotes: 'Student/GetNote',
  getCategoryClient: 'Dashboard/GetCategoryClient',
  getClientDemographic: 'Dashboard/GetClientDemographic',
  getCostOfCare: 'Dashboard/GetCostOfCare',
  getBehavioralRating: 'Dashboard/GetBehavioralRating',
  getScoreImprovement: 'Dashboard/GetScoreImprovement',
  getProviderPerformance: 'Dashboard/GetProviderPerformance',
  getTriggerLocationActivity: 'Dashboard/GetTriggerLocationActivity',
  getServiceDelivered: 'Dashboard/GetServiceDelivered',
  getReferralSource: 'Dashboard/GetReferralSource',
  getStudentDocumentList: 'Student/GetStudentDocumentList',
  archiveStudentDocument: 'Student/ArchiveStudentDocument',
  uploadDocument: 'Student/UploadDocumentUsingMultiPart',
  downloadStudentDocument: 'Student/DownloadStudentDocument',
  menuContractTemplate: 'ContractTemplate/MenuContractTemplate',
  archiveContractTemplate: 'ContractTemplate/ArchiveContractTemplate',
  newsUpsert: `Adminstration/UpsertNews`,
  getListNews: `Adminstration/ListNews`,
  getNews: `Adminstration/GetNews`,
  deleteNews: `Adminstration/DeleteNews`,
  getEmbedInfo: `EmbedInfo/GetEmbedInfo`,
  getGenderPieChartEmbedInfo: `EmbedInfo/GetGenderPieChartEmbedInfo`,
  getRefernceSourceTimeInTreatmentReport: `EmbedInfo/GetRefernceSourceTimeInTreatmentReport`,
  getPatientList: `Medicaid/ListClient`,
  getPatientDetail: `Medicaid/GetClient`,
  clientChartListGet: `Adminstration/ClientChartListGet`,
  clientChartReport: `Client/ClientChartReport`,
  upsertPatientEnrollment: `Medicaid/UpsertClient`,
  upsertClientInsurance: `Medicaid/UpsertClientInsurance`,
  upsertClientMedical: `Medicaid/UpsertClientMedical`,
  upsertClientPayment: `Medicaid/UpsertClientPayment`,
  upsertProviderEnrollment: `Medicaid/UpsertDoctor`,
  upsertAppointment: `Medicaid/UpsertAppointment`,
  listAppointment: `Medicaid/ListAppointment`,
  getAppointment: `Medicaid/GetAppointment`,
  deleteAppointment: `Medicaid/DeleteAppointment`,
  medicateCombos: `Combo/ListMedicaid`,
  listSurveyQuestionCategory: `Medicaid/ListSurveyQuestionCategory`,
  listSurveyQuestionCategoryAssessment: `Medicaid/ListSurveyQuestionCategoryAssessment`,
  listSurveyQuestionAssessment: `Medicaid/ListSurveyQuestionAssessment`,
  practitionerCombos: `Medicaid/ComboPractitioner`,
  practitionerCombosOffice: `Medicaid/ComboPractitionerOffice`,
  upsertGroup: `Medicaid/UpsertGroup`,
  listGroup: `Medicaid/ListGroup`,
  getGroup: `Medicaid/GetGroup`,
  deleteGroup: `Medicaid/DeleteGroup`,
  upsertClientGroup: `Medicaid/UpsertClientGroup`,
  getClientGroup: `Medicaid/GetClientGroup`,
  upsertGroupAppointment: `Medicaid/UpsertGroupAppointment`,
  upsertGroupAppointmentGroupAllInfoJson: `Medicaid/GetGroupAppointment`,
  getListClientGroup: `Medicaid/ListClient_Group`,
  patientComboGroup: `Medicaid/ComboGroup`,
  deactivateGroupAppointment: `Medicaid/DeactivateGroupAppointment`,
  comboCenterConsent: `Medicaid/ComboCenterConsentForm`,
  upsertClientConsent: `Medicaid/UpsertClientConsent`,
  listClientConsent: `Medicaid/ListClientConsent`,
  deactivateClientConsent: `Medicaid/DeactivateClientConsent`,
  deactivateClientScreening: `Medicaid/DeactivateSurvey`,
  loadJudicialDashboard: `AppSubscribers/LoadJudicialDashboard`,
  getJudicialUserList: `AppSubscribers/JudicialUsersList`,
  UploadDocumentUsingMultiPart: `Misc/UploadDocumentUsingMultiPart`,
  // upsertClientDocument: `Student/UploadDocumentUsingMultiPart`,
  upsertClientDocument: `Medicaid/UpsertClientDocument`,
  listClientDocument: `Medicaid/ListClientDocument`,
  downloadClientDocument: `Medicaid/DownloadClientDocument`,
  archiveClientDocument: `Misc/ArchiveDocument`,
  listGroupMembership: `Medicaid/ListGroupMembership`,
  getSurveyScore: `Medicaid/GetSurveyScorePartial`,
  getConsentFormTemplate: `Medicaid/GetConsentFormTemplate`,
  getComboFacility: `Medicaid/ComboFacility`,
  loadAppSubSurveyDashboard: `AppSubscribers/LoadSurveyDashboard`,
  appSubSurveyAnsGet: `AppSubscribers/Survey_Answers_Get`,
  centerProgramTypeGet: `AppSubscribers/CenterProgramTypeGet`,
  downloadClientConsent: `Medicaid/DownloadClientConsent`,
  comboPractitionerAll: `Medicaid/ComboPractitionerIncludeAll`,
  comboFacilityAll: `Medicaid/ComboFacilityIncludeAll`,
  saveClientConsent: `Medicaid/ConsentFormSignSave`,
  getTreatmentPlan: `Medicaid/GetTreatmentPlan`,
  getDiagnosis: `Combo/ListDictionary`,
  upsertTreatmentPlanProblem: `Medicaid/UpsertTreatmentPlanProblem`,
  upsertMedicalAnalysis: `Medicaid/UpsertDiagnosis`,
  upsertTreatmentPlanGoal: `Medicaid/UpsertTreatmentPlanGoal`,
  upsertTreatmentPlanObjective: `Medicaid/UpsertTreatmentPlanObjective`,
  upsertTreatmentPlanIntervention: `Medicaid/UpsertTreatmentPlanIntervention`,
  deleteTreatmentPlan: `Medicaid/DeleteTreatmentPlan`,
  getDiagnosisDescription: `Medicaid/GetDiagnosisDescription`,
  getDiagnosisCode: `Medicaid/ComboDiagnosis`,
  listTreatmentPlan: `Medicaid/ListTreatmentPlan`,
  comboProblemTemplate: `Medicaid/ComboProblemTemplate`,
  comboGoalTemplate: `Medicaid/ComboGoalTemplate`,
  recurringCount: `Medicaid/ComboGroupAppointmnetRecurring`,
  staffPermission: `StaffPermission/Get`,
  updateStaffPermission: `StaffPermission/Update`,
  loadCaseProfile: `AppSubscribers/LoadCaseProfile`,
  loadLocationAuthHistory: `AppSubscribers/LoadLocationAuthHistory`,
  screenAuthorization: `User/AuthorizedPathGetList`,
  listConsentForm: `Medicaid/ListSurveyQuestionCategoryConsentForm`,
  upsertTreatmentPlanDimension: `Medicaid/UpsertTreatmentPlanDimension`,
  saveConsentSign: `Medicaid/ConsentFormSignSave`,
  consentFormDownload: `Medicaid/ConsentFormDownload`,
  listMeeting: `Medicaid/ListMeeting`,
  upsertProgressGroupNote: `Medicaid/UpsertProgressNoteGroup`,
  getProgressNoteGroup: `Medicaid/GetProgressNoteGroup`,
  getUserNotePatient: `AppSubscribers/GetUserNotePatient`,
  getUserNoteComment: `AppSubscribers/GetUserNoteComment`,
  addUserNoteComment: `AppSubscribers/AddUserNoteComment`,
  getUserNoteEmoji: `AppSubscribers/GetUserNoteEmoji`,
  addUserNoteEmoji: `AppSubscribers/AddUserNoteEmoji`,
  comboInterventionTemplate: `Medicaid/ComboInterventionTemplate`,
  getProgressNoteForIntervention: `Medicaid/GetProgressNoteForIntervention`,
  upsertPayer: `Medicaid/UpsertPayer`,
  listPayer: `Medicaid/ListPayer`,
  deletePayer: `Medicaid/DeletePayer`,
  getPayer: `Medicaid/GetPayer`,
  comboPayer: `Medicaid/ComboPayer`,
  upsertServiceCode: `Medicaid/UpsertServiceCode`,
  listServiceCode: `Medicaid/ListServiceCode`,
  deleteServiceCode: `Medicaid/DeleteServiceCode`,
  getServiceCode: `Medicaid/GetServiceCode`,
  comboCode: `Medicaid/ComboCode`,
  getCodeDescription: `Medicaid/GetCodeDescription`,
  appointmentNoteList: `Medicaid/ListAppointmentNote`,
  upsertAppointmentNote: `Medicaid/UpsertAppointmentNote`,
  getAppointmentNote: `Medicaid/GetAppointmentNote`,
  comboPlaceOfService: `Medicaid/ComboPlaceOfService`,
  listServiceLine: `Medicaid/ListServiceLine`,
  getServiceLine: `Medicaid/GetServiceLine`,
  upsertServiceLine: `Medicaid/UpsertServiceLine`,
  upsertAppointmentNoteAddendum: `Medicaid/UpsertAppointmentNoteAddendum`,
  listGroupAppointmentNote: `Medicaid/ListGroupAppointmentNote`,
  getGroupAppointmentNote: `Medicaid/GetGroupAppointmentNote`,
  comboTreatmentPlanIntervention: `Medicaid/ComboTreatmentPlanIntervention`,
  listFeeSchedule: `Medicaid/ListFeeSchedule`,
  getFeeSchedule: `Medicaid/GetFeeSchedule`,
  upsertFeeSchedule: `Medicaid/UpsertFeeSchedule`,
  getTreatmentPlanGraph: `Medicaid/GetTreatmentPlanGraph`,
  listGroupServiceLine: `Medicaid/ListGroupServiceLine`,
  getGroupServiceLine: `Medicaid/GetGroupServiceLine`,
  getGroupBanner: `Medicaid/GetGroupBanner`,
  upsertGroupServiceLine: `Medicaid/UpsertGroupServiceLine`,
  upsertGroupAppointmentNote: `Medicaid/UpsertGroupAppointmentNote`,
  upsertGroupAppointmentNoteAddendum: `Medicaid/UpsertGroupAppointmentNoteAddendum`,
  listSurveyQuestionDischarge: `Medicaid/ListSurveyQuestionCategoryDischarge`,
  upsertAppointmentNoteIntervention: `Medicaid/UpsertAppointmentNoteIntervention`,
  markAsComplete: `Survey/MarkAsComplete`,
  getServiceLineCost: `Medicaid/GetServiceLineCost`,
  upsertPin: `User/UpdatePin`,
  validateStaffPin: `Adminstration/ValidatePin`,
  loadSmallGroupDashboard: `AppSubscribers/LoadSmallGroupDashboard`,
  upsertTreatmentPlan: `Medicaid/UpsertTreatmentPlan`,
  listClientDiagnosis: `Medicaid/ListDiagnosis`,
  getClientDiagnosis: `Medicaid/GetDiagnosis`,
  deleteClientDiagnosis: `Medicaid/DeleteDiagnosis`,
  validatePinExists: `Adminstration/ValidatePinExists`,
  cloneDiagnosis: `Medicaid/CloneDiagnosis`,
  cloneTreatmentPlan: `Medicaid/CloneTreatmentPlan`,
  getBreathalyzerSchedule: `AppSubscribers/GetBreathalyzerSchedule`,
  listBreathalyzerSchedule: `AppSubscribers/ListBreathalyzerSchedule`,
  upsertBreathalyzerSchedule: `AppSubscribers/UpsertBreathalyzerSchedule`,
  rcmClientMerge: `Student/ClientMerge`,
  preValidatePin: `Medicaid/PreValidatePin`,
  validateClient: `Medicaid/ValidateClient`,
  getAppAdvisor: `AppSubscribers/GetAppAdvisor`,
  getMediaVideoUrl: `AppSubscribers/GetMediaVideoUrl`,
  getDashboardMenu: `Dashboard/GetMenu`,
  breathalyzerPushATest: `AppSubscribers/BreathalyzerPushATest`,
  listServiceCodeBundle: `Medicaid/ListServiceCodeBundle`,
  getServiceCodeBundle: `Medicaid/GetServiceCodeBundle`,
  getStudentNote: `Student/GetStudentNote`,
  getStudentNoteComment: `Student/GetStudentNoteComment`,
  addStudentNoteComment: `Student/AddStudentNoteComment`,
  addStudentNoteEmoji: `Student/AddStudentNoteEmoji`,
  getStudentNoteEmoji: `Student/GetStudentNoteEmoji`,
  getTreatmentPlanAccordin: `Medicaid/GetTreatmentPlanAccordin`,
  breathalyzerEnable: `AppSubscribers/BreathalyzerEnable`,
  getBreathalyzerTestResult: `AppSubscribers/BreathalyzerTestResultsGet`,
  getBreathalyzerTestUpcoming: `AppSubscribers/BreathalyzerUpcomingTestsGet`,
  breathalyzerTestUpcomingToggle: `AppSubscribers/BreathalyzerUpcomingTestsCancelToggle`,
  getAdvisorCost: `Counselor/AdvisorCostGet`,
  upsertAdvisorCost: `Counselor/AdvisorCostUpdate`,
  claimsPdfDownload: `Claims/PDFDownload`,
  getClientService: `Claims/ClientServicesGet`,
  getListTimeLine: `TimeLine/GetTimeLine`,
  getStudentBreathalyzerTestResult: `Student/GetBreathalyzerScheduleTestResults`,
  getDashboardDailySchedule: `Medicaid/GetDashboardDailySchedule`,
  getDashboardDailyCaseLoad: `Medicaid/GetDashboardCaseLoad`,
  getDashboardAlert: `Medicaid/GetDashboardAlert`,
  getCenterAdvisorDefaultCosts: `Counselor/CenterAdvisorDefaultCostsGet`,
  updateCenterAdvisorDefaultCost: `Counselor/CenterAdvisorDefaultCostUpdate`,
  addClientInsuranceEligibility: `Medicaid/AddClientInsuranceEligibility`,
  editClientInsuranceEligibility: `Medicaid/EditClientInsuranceEligibility`,
  deleteClientInsuranceEligibility: `Medicaid/DeleteClientInsuranceEligibility`,
  getStaffPractitioner: `Medicaid/GetStaffPractitioner`,
  dischargeSummarySave: `Medicaid/DischargeSummarySave`,
  getClientAppointment: `Medicaid/GetAppointmentClient`,
  getGroupAppointment: `Medicaid/GetGroupAppointmentForGroup`,
  unsignForm: `Medicaid/UnSignForm`,
  getFilters: `Timeline/GetFilters`,
  listLabOrder: `Medicaid/ListLabOrder`,
  getLabOrder: `Medicaid/GetLabOrder`,
  upsertLabOrder: `Medicaid/UpsertLabOrder`,
  labOrderLabelGet: `Medicaid/LabOrderLabelGet`,
  comboDiagnosisList: `Medicaid/ComboDiagnosisList`,
  getMedicationNote: `Medicaid/GetMedicationNote`,
  upsertClientGroupAppointment: `Medicaid/UpsertClientGroupAppointment`,
  signGroupAppointmentNote: `Medicaid/SignGroupAppointmentNote`,
  listServiceLinePayment: `Medicaid/ListServiceLinePayment`,
  getServiceLinePayment: `Medicaid/GetServiceLinePayment`,
  upsertServiceLinePayment: `Medicaid/UpsertServiceLinePayment`,
  deleteServiceLinePayment: `Medicaid/DeleteServiceLinePayment`,
  comboSupervisor: `Medicaid/ComboSupervisor`,
  locationDayHistory: `AppSubscribers/LocationDayHistory`,
  upsertPaymentClient: `Medicaid/UpsertCWPayment`,
  getInsurancePriorityOrder: `Medicaid/GetClientInsurancePriorityOrder`,
  upsertInsurancePriorityOrder: `Medicaid/UpsertClientInsurancePriorityOrder`,
  listCWPayment: `Medicaid/ListCWPayment`,
  getCWPayment: `Medicaid/GetCWPayment`,
  deleteCWPayment: `Medicaid/DeleteCWPayment`,
  pastMeetingInfo: `SmallGroups/PastMeetingInfo`,
  listPastMeetingInfo: `SmallGroups/ListPastMeetingInfo`,
  comboAppointmentTreatmentPlan: `Medicaid/ComboAppointmentLink`,
  linkAppointment: `Medicaid/LinkAppointment`,
  appointmentLinkGet: `Medicaid/AppointmentLinkGet`,
  getBasicProfile: `AppSubscribers/GetBasicProfile`,
  getInvitedList: `AppSubscribers/InvitedListGet`,
  inviteResend: `AppSubscribers/InviteResend`,
  suspiciousTermAck: `AppSubscribers/SuspiciousTermAck`,
  getBroadcastFilter: `AppSubscribers/GetBroadcastFilter`,
  sendBroadcast: `AppSubscribers/SendBroadcast`,
  getServiceLineDiagnosis: `Medicaid/GetServiceLineDiagnosis`,
  upsertServiceLineDiagnosis: `Medicaid/UpsertServiceLineDiagnosis`,
  comboBroadcastTemplate: `AppSubscribers/ComboBroadcastTemplate`,
  upsertBroadcastTemplate: `AppSubscribers/UpsertBroadcastTemplate`,
  deleteNoteType: `Adminstration/DeleteNoteType`,
  listClientAuthorization: `Medicaid/ListClientAuthorization`,
  getClientAuthorization: `Medicaid/GetClientAuthorization`,
  deleteClientAuthorization: `Medicaid/DeleteClientAuthorization`,
  upsertClientAuthorization: `Medicaid/UpsertClientAuthorization`,
  getNoteTypeArchive: 'Adminstration/GetNoteTypeArchive',
  getNoteTypeList: 'Adminstration/GetNoteType',
  upsertCouponCode: 'Coupon/Upsert',
  deleteCouponCode: 'Coupon/Delete',
  getCouponCode: 'Coupon/Get',
  reactivateCenter: 'Adminstration/ReactivateCenter',
  generateCallToken: 'CallToken/Generate',
  badgeUpdate: 'AppSubscribers/BadgeUpdate',
  badgeComboGet: 'AppSubscribers/BadgeComboGet',
  panasScaleMoodsGet: 'Misc/PANASScaleMoodsGet',
  panasTagUpsert: 'Medicaid/PANASTagUpsert',
  bedAssignmentGetAll: 'BedMgmt/BedAssignmentGetAll',
  bedAssignmentUpsert: 'BedMgmt/BedAssignmentUpsert',
  upcomingDischargesGetAll: 'BedMgmt/UpcomingDischarges ',
  upcomingAdmissions: 'BedMgmt/UpcomingAdmissions ',
  admissionCancel: 'BedMgmt/AdmissionCancel ',
  panasTagDelete: 'Medicaid/PANASTagDelete',
  dynamicGridList: 'AppSubscribers/DynamicGridList',
  getGrid: 'Component/GridGet',
  getGridData: 'Component/GridDataGet',
  gridOnRowClickGet: 'Component/GridOnRowClickGet',
  getPredefinedTask: 'Adminstration/GetPredefinedTask',
  getPredefinedTaskArchive: 'Adminstration/GetPredefinedTaskArchive',
  deletePredefinedTask: 'Adminstration/DeletePredefinedTask',
  upsertPredefinedTask: 'Adminstration/UpsertPredefinedTask',
  listSurveyQuestionCategoryGetAll: 'Medicaid/ListSurveyQuestionCategoryGetAll',
  getAdvisorMemberTrends: 'AppSubscribers/AdvisorMemberTrendsGet',
  updateSmallGroupAttendence: 'SmallGroups/UpdateSmallGroupAttendence',
  getAdvisorMemberTrendsHistory: 'AppSubscribers/AdvisorMemberTrendsHistoryGet',
  mobileInviteOptionsLoad: 'Adminstration/MobileInviteOptionsLoad',
  getAppUserSubscription: 'AppUserSubscription/Get',
  getServiceOptions: 'AppUserSubscription/ServiceOptionsGet',
  getServiceAdvisorList: 'AppUserSubscription/ServiceAdvisorListGet',
  setServiceAdvisor: 'AppUserSubscription/ServiceAdvisorSet',
  updateCancelService: 'AppUserSubscription/ServiceCancelUpdate',
  setMembership: 'AppUserSubscription/MembershipSet',
  cancelMembership: 'AppUserSubscription/MembershipCancel',
  setServiceSubscription: 'AppUserSubscription/ServiceSubscriptionTypeSet',
  upsertClientPin: 'Client/ClientPINUpsert',
  getStudentTimeLine: 'TimeLine/StudentGetTimeLine',
  getClientTimeLine: 'TimeLine/ClientGetTimeLine',
  clientStudentSynchronize: 'Client/ClientStudentSynchronize',
  comboAppAdvisor: 'Combo/AppAdvisor',
  getSurveyCategoryQuestion: 'Survey/SurveyQuestionCategoryQuestionsGet',
  upsertBlockAppointment: 'Medicaid/UpsertBlockAppointment',
  upsertClientMemo: 'Medicaid/UpsertClientMemo',
  getClientMemo: 'Medicaid/GetClientMemo',
  deleteClientMemo: 'Medicaid/DeleteClientMemo',
  validateSSN: 'Medicaid/ValidateSSN',
  userAppLinkValidate: 'Student/UserAppLinkValidate',
  clientCenterMove: 'Medicaid/ClientCenterMove',
  formTabListGet: 'Medicaid/FormTabListGet',
  upsertClientCareTeam: 'Medicaid/UpsertClientCareTeam',
  getClientCareTeamList: 'Medicaid/ClientCareTeamListGet',
  clientCareTeamGet: 'Medicaid/ClientCareTeamGet',
  clientCareTeamDelete: 'Medicaid/ClientCareTeamDelete',
  careTeamMemberCombo: 'Medicaid/CareTeamMemberCombo',
  externalProviderListGet: 'ExternalCareTeam/ExternalProviderListGet',
  externalProviderGet: 'ExternalCareTeam/ExternalProviderGet',
  externalProviderAuthentication: 'ExternalCareTeam/ExternalProviderAuthentication',
  externalStaffArchive: 'ExternalCareTeam/ExternalStaffArchive',
  externalProviderFilterGet: 'ExternalCareTeam/ExternalProviderFilterGet',
  externalProviderUpsert: 'ExternalCareTeam/ExternalProviderUpsert',
  externalStaffClientInsuranceGet: 'ExternalCareTeam/ExternalStaffClientInsuranceGet',
  externalStaffReferralUpsert: 'ExternalCareTeam/ExternalStaffReferralUpsert',
  clientReferralToCenter: 'ExternalCareTeam/ClientReferralToCenter',
  externalStaffReferralFormGet: 'ExternalCareTeam/ExternalStaffReferralFormGet',
  organizationCenterFamilyCombo: 'Medicaid/OrganizationCenterFamilyCombo',
  upsertClientVital: 'Medicaid/ClientVitalUpsert',
  upsertMedicationLog: 'Medicaid/ClientMedicalLogUpsert',
  getMedicationLog: 'Medicaid/ClientMedicalLogGet',
  getClientVital: 'Medicaid/ClientVitalGet',
  getMedicationLogList: 'Medicaid/ClientMedicalLogGetAll',
  getClientVitalList: 'Medicaid/ClientVitalGetAll',
  getCHPatientList: 'ClearingHouse/PatientsGet',
  CHPatientServiceSend: 'ClearingHouse/PatientSend',
  pushClaimsToCH: 'ClearingHouse/ClaimsSendForCenter',
  pushSingleClaimToCH: 'ClearingHouse/ClaimsSendForPatientProvider',
  getBillableClaims: 'ClearingHouse/BillableClaimsGet',
  cloneForm: 'Survey/CloneForm',
  clearSignature: 'Survey/SurveySignatureClear',
  upsertStaffViewedActivity: 'Adminstration/UpsertStaffViewedActivity',
  deleteStudentNote: 'Student/StudentNoteMarkAsDelete',
  staffAccessedTabGet: 'StaffPermission/StaffAccessedTabGet',
  studentStaffAccessedTabGet: 'StaffPermission/StudentStaffAccessedTabGet',
  appAnnouncementUpsert: 'Adminstration/AppAnnouncementUpsert',
  appAnnouncementListGetAll: 'Adminstration/AppAnnouncementListGetAll',
  appAnnouncementGet: 'Adminstration/AppAnnouncementGet',
  resourceUpsert: 'Adminstration/ResourceUpsert',
  resourceListGetAll: 'Adminstration/ResourceListGetAll',
  allergenSearch: 'EPrescribe/AllergenSearch',
  drugSearch: 'EPrescribe/DrugSearch',
  drugPackageGetAll: 'EPrescribe/DrugPackageGetAll',
  clientAllergyGet: 'Medicaid/ClientAllergyGet',
  allergyOptionsGet: 'Medicaid/AllergyOptionsGet',
  clientAllergyUpsert: 'Medicaid/ClientAllergyUpsert',
  clientAllergyGetAll: 'Medicaid/ClientAllergyGetAll',
  clientAllergyDelete: 'Medicaid/ClientAllergyDelete',
  clientMedicationUpsert: 'Medicaid/ClientMedicationUpsert',
  clientMedicationGetAll: 'Medicaid/ClientMedicationGetAll',
  clientMedicationDelete: 'Medicaid/ClientMedicationDelete',
  resourceGet: 'Adminstration/ResourceGet',
  prescribeNotificationGet: 'EPrescribe/NotificationGet',
  prescribeUpdate: 'EPrescribe/Update',
  clientSignatureSave: 'TreatmentPlan/ClientSignatureSave',
  prescriptionListGet: 'EPrescribe/PrescriptionListGet',
  userFeatureFlagGet: 'User/UserFeatureFlagGet',
  augmentedClientInsightsGet: 'Survey/AugmentedClientInsightsGet',
  riskChartGet: 'Survey/RiskChartGet',
  augmentedSummaryGet: 'Survey/AugmentedSummaryGet',
  ePrescribeSinglePatientModeSSO: 'EPrescribe/SinglePatientModeSingleSignOn',
  clientReactivate: 'Client/ClientReActivate',
  clientBannerGet: 'Medicaid/ClientBannerGet',
  labOrderCancel: 'Medicaid/LabOrderCancel',
  drugDetailSearch: 'EPrescribe/DrugDetailSearch',
  labResultGet: 'Medicaid/LabResultGet',
  labResultDocumentGet: 'Medicaid/LabResultDocumentGet',
  labOrderDelete: 'Medicaid/LabOrderDelete',
  addSupportGroup: 'AppSubscribers/AddSupportGroup',
  loadSupportGroup: 'AppSubscribers/LoadSupportGroup',
  loadPeer: 'AppSubscribers/LoadPeer',
  loadUserEngagement: 'AppSubscribers/LoadUserEngagement',
  studentBanner: 'Student/StudentBanner',
  preIntakeSurveyAnswersGet: 'Survey/PreIntakeSurveyAnswersGet',
  preIntakeHistoryGet: 'Survey/PreIntakeHistoryGet',
  surveyLatestAnswerDateUpdate: 'Survey/SurveyLatestAnswerDateUpdate',
  appUserDemographicUpsert: 'AppSubscribers/AppUserDemographicUpsert',
  appUserDemographicGet: 'AppSubscribers/AppUserDemographicGet',
  removeSupportGroup: 'AppSubscribers/RemoveSupportGroup',
  concernedPersonList: 'AppSubscribers/ConcernedPersonList',
  concernedPersonDasboardGraphGet: 'AppSubscribers/ConcernedPersonDasboardGraphGet',
  advisorSearch: 'Student/AdvisorSearch',
  listClientGetAllWithPagination: 'Medicaid/ListClientGetAllWithPagination',
  appUserLiveLocationGet: 'AppSubscribers/AppUserLiveLocationGet',
  studentSurveyListGet: 'Survey/StudentSurveyListGet',
  GPT3EnrollSurveys: 'Survey/GPT3EnrollSurveys',
  getMultipleSurveyAugmentedSummary: 'Survey/MultipleSurveyAugmentedSummaryGet',
  GPT3AnswerStatusUpsert: 'Survey/GPT3AnswerStatusUpsert',
  augmentWithAI: 'Survey/AugmentWithAI',
  augmentWithAIGet: 'Survey/AugmentWithAIGet',
  gridFilterGet: 'Component/GridFilterGet',
  getGroupDropdown: 'Medicaid/GetGroupDropdown',
  getProspectDashboardGraph: 'Student/ProspectDashboardGraph',
  medicalProgramUpsert: 'Adminstration/MedicalProgramUpsert',
  medicalProgramGet: 'Adminstration/MedicalProgramGet',
  medicalProgramDelete: 'Adminstration/MedicalProgramDelete',
  regionUpsert: 'Adminstration/RegionInsert',
  regionGet: 'Adminstration/RegionGet',
  regionDelete: 'Adminstration/RegionDelete',
  staffAccessedTabEntGet: 'StaffPermission/StaffAccessedTabENTGet',
  surveyQuestionLibraryGet: 'Survey/ScreeningQuestionsLibraryGet',
  surveyQuestionConfigGet: 'Survey/SurveyQuestionConfigGet',
  surveyQuestionSectionUpsert: 'Survey/SurveyQuestionSectionUpsert',
  surveyQuestionSectionComboGet: 'Survey/SurveyQuestionSectionComboGet',
  surveyQuestionConfigUpsert: 'Survey/SurveyQuestionConfigUpsert',
  surveyQuestionCategoryConfigUpsert: 'Survey/SurveyQuestionCategoryConfigUpsert',
  surveyQuestionConfigBannerGet: 'Survey/SurveyQuestionConfigBannerGet',
  studentQueueListGetEnt: 'Student/StudentQueueListGet',
  studentQueueDelete: 'Student/StudentQueueDelete',
  studentAvailableSpacesListGet: 'Student/StudentAvailableSpacesListGet',
  studentQueueInsert: 'Student/StudentQueueInsert',
  clientScreeningStatusUpdate: 'Survey/ClientScreeningStatusUpdate',
  studentAssociateInsert: 'Student/StudentAssociateInsert ',
  studentAssociateListGet: 'Student/StudentAssociateListGet',
  studentAssociateDelete: 'Student/StudentAssociateDelete',
  centerOptionGet: 'Combo/CenterOptionGet',
  comboPractitionerCenterGet: 'Medicaid/ComboPractitionerCenterGet',
  prospectArchive: 'Student/ProspectArchive',
  tokenManagementListGetAll: 'Adminstration/TokenManagementAllTransactionGetAll',
  tokenManagementStatsGet: 'Adminstration/TokenManagementDashboardAndStatsGet',
  tokensStudentTransactionHistory: 'Adminstration/TokensStudentTransactionGetAll',
  tokensCounselorTransactionHistory: 'Adminstration/TokenManagementCounsolerTransactionGetAll',
  tokensStudentTransactionHistoryGetAll: 'Adminstration/TokensStudentTransactionHistoryGetAll',
  tokensCounselorTransactionHistoryGetAll:
    'Adminstration/TokenManagementCounselorTransactionHistoryGetAll',
  centerOverHeadCostGet: 'Adminstration/CenterOverHeadCostGet',
  centerOverHeadCostUpsert: 'Adminstration/CenterOverHeadCostUpsert',
  centerLaberCostGet: 'Adminstration/CenterLaberCostGet',
  centerLaberCostGetUpsert: 'Adminstration/CenterLaberCostGetUpsert',
  centerConsumableGetAll: 'Adminstration/CenterConsumableGetAll',
  centerConsumableGet: 'Adminstration/CenterConsumableGet',
  centerConsumableGetUpsert: 'Adminstration/CenterConsumableGetUpsert',
  centerConsumableDelete: 'Adminstration/CenterConsumableDelete',
  dashboardPendingSurveyListGet: 'Survey/DashboardPendingSurveyListGet',
  clientembeddingprepare: 'VirtualAssistant/ClientEmbeddingPrepare',
  askQuestion: 'VirtualAssistant/AskQuery',
  chatGet: 'VirtualAssistant/ChatGet',
  youuniverseConversationGet: 'Youuniverse/ConversationGet',
  youuniverseChatGet: 'Youuniverse/ChatGet',
  youuniverseChatPost: 'Youuniverse/query',
  youuniverseBookMarkQuestionUpsert: 'Youuniverse/YOUUniverseBookMarkQuestionUpsert',
  youuniverseBookMarkQuestionListGet: 'Youuniverse/YOUUniverseBookMarkQuestionListGet',
  youuniverseChatAttributeUpdate: 'Youuniverse/YOUUniverseChatAttributeUpdate',
  labOrderPanelCodeComboGet: 'Medicaid/LaborderPanelCodeComboGet',
  defaultPractotionerFacilityGet: 'Medicaid/DefaultPractotionerFacilityGet',
  licenseNotifyCenterAdmin: 'Adminstration/LicenseNotifyCenterAdmin',
  goalLibraryUpsert: 'Adminstration/GoalLibraryUpsert',
  goalLibraryArchive: 'Adminstration/GoalLibraryArchive',
  goalLibraryGetAll: 'Adminstration/GoalLibraryGetAll',
  goalLibraryGet: 'Adminstration/GoalLibraryGet',
  comboServiceCode: 'Medicaid/ComboServiceCode',
  centerTokenAssignUpdate: 'Licensing/CenterTokenAssignUpdate',
  serviceCodeBundleUpsert: 'Adminstration/ServiceCodeBundleUpsert',
  serviceCodeBundleDelete: 'Adminstration/ServiceCodeBundleDelete',
  serviceCodeBundleGetAll: 'Adminstration/ServiceCodeBundleGetAll',
  serviceCodeBundleGet: 'Adminstration/ServiceCodeBundleGet',
  smartContractUpsert: 'Medicaid/SmartContractUpsert',
  smartContractGet: 'Medicaid/SmartContractGet',
  smartContractRequestGet: 'Medicaid/SmartContractRequestGet',
  smartContractRequestUpdate: 'Medicaid/SmartContractRequestUpdate',
  clientInsuranceGet: 'Medicaid/ClientInsuranceGet',
  payerPortalDashBoard: 'PayerPortal/PayerPortalDashBoard',
  payerPortalAuthorizationGet: 'PayerPortal/PayerPortalAuthorizationGet',
  payerPortalAuthorizationStatusUpsert: 'PayerPortal/PayerPortalAuthorizationStatusUpsert',
  payerPortalRequestInsert: 'PayerPortal/PayerPortalRequestInsert',
  studentRecommendationsListGet: 'Student/StudentRecommendationsListGet',
  youuniverseConversationDelete: 'Youuniverse/YOUUniverseConversationDelete',
  //  youuChatEditedAnswerUpsert: 'Youuniverse/YOUUChatResponseUpdate',
  youuChatEditedAnswerUpsert: 'Youuniverse/YOUUChatEditedAnswerUpsert',
  smartContractLedgerGet: 'Medicaid/SmartContractLedgerGet',
  clientStatusReport: 'EmbedInfo/ClientStatusReport',
  userTriggerReport: 'EmbedInfo/UserTriggerReport',
  surveyQuestionLibraryQuestionListGet: 'Survey/SurveyQuestionLibraryQuestionListGet',
  surveyQuestionLibraryQuestionDelete: 'Survey/SurveyQuestionLibraryQuestionDelete',
  surveyQuestionLibrarySectionUpdate: 'Survey/SurveyQuestionLibrarySectionUpdate',
  surveyQuestionLibraryQuestionUpsert: 'Survey/SurveyQuestionLibraryQuestionUpsert',
  smartContractUpdate: 'Medicaid/SmartContractUpdate',
  vbcLedgerRemarkUpsert: 'Medicaid/SmartContractUpdate',
  distributionPaymentUpsert: 'Medicaid/SmartContractUpdate',
  studentNoteGetAll: 'Dashboard/StudentNoteGetAll',
  askFollowUpInitiate: 'Youuniverse/AskFollowUpInitiate',
  surveyQuestionLibraryQuestionGet: 'Survey/SurveyQuestionLibraryQuestionGet',
  signedNoteGet: 'Adminstration/SignedNoteGet',
  dashboardTaskListGet: 'Student/DashboardTaskListGet',
  dashboardCourseListGet: 'Student/DashboardCourseListGet',
  dashboardContractListGet: 'Student/DashboardContractListGet',
  dashboardAlertListGet: 'Student/DashboardAlertListGet',
  centerLaborBurdenGet: 'Adminstration/CenterLaborburdenGet',
  centerLaborBurdenUpsert: 'Adminstration/CenterLaborburdenUpsert',
  studentTimerUpsert: 'Student/StudentTimerUpsert',
  getAppointmentClient: 'Medicaid/GetAppointmentClient',
  appointmentNoteListGet: 'Student/AppointmentNoteListGet',
  comboServiceCodeGet: 'Medicaid/ComboServiceCodeGet',
  noteTypeListGet: 'Adminstration/NoteTypeListGet',
  noteTypeUpsert: 'Adminstration/NoteTypeUpsert',
  studentMergeComboListGet: 'Student/StudentMergeComboListGet',
  studentMergeDetailsGet: 'Student/StudentMergeDetailsGet',
  studentMergeSecondaryDetailsGet: 'Student/StudentMergeSecondaryDetailsGet',
  studentMerge: 'Student/StudentMerge',
  studentTwilioTranscriptGet: 'Student/StudentTwilioTranscriptGet',
  powerBiReportListGet: 'EmbedInfo/PowerBiReportListGet',
  appointmentPreSelectedGet: 'Student/AppointmentPreSelectedGet',
  callNotesAugment: 'Student/CallNotesAugment',
  callSummaryAugment: 'Student/CallSummaryAugment',
  gptAugmentationUpdate: 'Student/GPTAugmentationUpdate',
  centerFieldUpsert: 'Adminstration/CenterFieldUpsert',
  centerFieldGet: 'Adminstration/CenterFieldGet',
  clientSendMobileAppInvite: 'Medicaid/ClientSendMobileAppInvite',
  smartContractPatientListGet: 'Medicaid/SmartContractPatientListGet',
  smartContractListGet: 'Medicaid/SmartContractListGet',
  termsConditionListGet: 'Adminstration/TermsConditionListGet',
  termsConditionUpsert: 'Adminstration/TermsConditionUpsert',
  termsConditionGetAllInfoJson: 'Adminstration/TermsConditionGetAllInfoJson',
  centerConfigGet: 'User/CenterConfigGet',
  centerConfigGetById: 'User/CenterConfigGet',
  payerPriceDetailsGet: 'PayerPortal/PayerPriceDetailsGet',
  payerPortalRequestUpsert: 'PayerPortal/PayerPortalRequestUpsert',
  clientInsuranceComboGet: 'Medicaid/ClientInsuranceComboGet',
  smartContractTermsConditionGetAllInfoJson: 'Medicaid/SmartContractTermsConditionGetAllInfoJson',
  termsConditionDelete: 'Adminstration/TermsConditionDelete',
  termsConditionComboGet: 'Medicaid/TermsConditionComboGet',
  comboLabOrderPractitioner: 'Medicaid/ComboLabOrderPractitioner',
  smartContractPayersListGet: 'Medicaid/SmartContractPayersListGet',
  groupServiceLineApproveAllBillingOrBatching:
    'Medicaid/GroupServiceLineApproveAllBillingOrBatching',
  payerPortalRequestGet: 'PayerPortal/PayerPortalRequestGet',
  payerTermsConditionsStatusUpsert: 'PayerPortal/PayerTermsConditionsStatusUpsert',
  clientCareTeamListGet: 'PayerPortal/ClientCareTeamListGet',
  dashboardMobileUserGetAll: 'AppSubscribers/DashboardMobileUserGetAll',
  entMobileUserDashboardGet: 'AppSubscribers/EntMobileUserDashboardGet',
  recoveryCapitalQuadrantGraphGet: 'AppSubscribers/RecoveryCapitalQuadrantGraphGet',
  clientMergeGet: 'Client/ClientMergeGet',
  clientMergeSecondaryGet: 'Client/ClientMergeSecondaryGet',
  clientComboGetAll: 'Client/ClientComboGetAll',
  clientMerge: 'Client/ClientMerge',
  clinicalToIntakeMovePatient: 'Client/ClinicalToIntakeMovePatient',
  staffLicenseAgreementUpdate: 'User/StaffLicenseAgreementUpdate',
  smartContractTermsConditionUpsert: 'Medicaid/SmartContractTermsConditionUpsert',
  userTagTypeInsert: 'Adminstration/UserTagTypeInsert',
  userTagTypeListGet: 'Adminstration/UserTagTypeListGet',
  userTagTypeComboListGet: 'Adminstration/UserTagTypeComboListGet',
  userTagTypeUpdate: 'Adminstration/UserTagTypeUpdate',
  listProspectAppointment: 'Medicaid/ListProspectAppointment',
  recoveryCapitalTrendGraphGet: 'AppSubscribers/RecoveryCapitalTrendGraphGet',
  careTeamAssign: 'SmartContract/CareTeamAssign',
  serviceCodeCareTeamGet: 'SmartContract/ServiceCodeCareTeamGet',
  smartContractTabGet: 'SmartContract/SmartContractTabGet',
  smartContractDistributionGet: 'SmartContract/SmartContractDistributionGet',
  smartContractBundleSubmitToPayer: 'SmartContract/BundleSubmitToPayer',
  smartContractPayerBundleGetToSubmit: 'SmartContract/PayerBundleGetToSubmit',
  serviceCodeSuggestionsGenerate: 'Medicaid/ServiceCodeSuggestionsGenerate',
  serviceCodeSuggestionListGet: 'Student/ServiceCodeSuggestionListGet',
  peerRecoveryServiceCodeUpdate: 'Student/PeerRecoveryServiceCodeUpdate',
  broadCastUserListGet: 'AppSubscribers/BroadCastUserListGet',
  clientReportGet: 'PayerPortal/ClientReportGet',
  clientRiskLevelGraphGet: 'PayerPortal/ClientRiskLevelGraphGet',
  contractPeriodGraphGet: 'PayerPortal/ContractPeriodGraphGet',
  dashboardClaimsGet: 'PayerPortal/DashboardClaimsGet',
  dashboardCardsGet: 'PayerPortal/DashboardCardsGet',
  riskProfileDataGraphGet: 'PayerPortal/RiskProfileDataGraphGet',
  appUserJournalAugmentGet: 'AppSubscribers/AppUserJournalAugmentGet',
  appUserJournalAugmentation: 'AppSubscribers/AppUserJournalAugmentation',
  studentJournalAugmentGet: 'Student/StudentJournalAugmentGet',
  studentJournalAugmentation: 'Student/StudentJournalAugmentation',
  studentCourseAugmentationGet: 'Student/StudentCourseAugmentationGet',
  studentCourseAugmentation: 'Student/StudentCourseAugmentation',
  dashboardRadarGraphGet: 'AppSubscribers/DashboardRadarGraphGet',
  smartContractDocumentRequestGet: 'PayerPortal/PayerPortalRequestGet',
  gptAugmentationGet: 'Student/GPTAugmentationGet',
  recoveryCategoryComboGet: 'Adminstration/RecoveryCategoryComboGet',
  taskComboGet: 'Adminstration/TaskComboGet',
  gptAugmentationWithAI: 'Student/GPTAugmentationWithAI',
  smartContractClone: 'SmartContract/Clone',
  taskClientUpsert: 'Medicaid/TaskClientUpsert',
  taskClientListGet: 'Medicaid/TaskClientListGet',
  taskClientUpdate: 'Medicaid/TaskClientUpdate',
  taskClientReassign: 'Medicaid/TaskClientReassign',
  studentNoteHistoryGet: 'Student/StudentNoteHistoryGet',
  appUserJournalHistoryGet: 'AppSubscribers/AppUserJournalHistoryGet',
  appointmentGetClientUpcomingSchedule: 'Medicaid/AppointmentGetClientUpcomingSchedule',
  listClientOutstandingBalanceGet: 'Medicaid/ListClientOutstandingBalanceGet',
  preIntakeGetAll: 'Medicaid/PreIntakeGetAll',
  clientSharedMemoUpsert: 'Medicaid/ClientSharedMemoUpsert',
  clientSharedMemoGetAll: 'Medicaid/ClientSharedMemoGetAll',
  clientSharedMemoUpdate: 'Medicaid/ClientSharedMemoUpdate',
  feedbackFormAnalyticsGet: 'Adminstration/FeedbackFormAnalyticsGet',
  feedbackFormGet: 'Adminstration/FeedbackFormGet',
  feedbackFormDelete: 'Adminstration/FeedbackFormDelete',
  feedbackFormListGet: 'Adminstration/FeedbackFormListGet',
  appointmentAutoSelectGet: 'Medicaid/AppointmentDefaultOptionGet',
};
