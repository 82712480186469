import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import AntdModal from '../../../../Components/CustomModal';
import { Link, withRouter } from 'react-router-dom';
import ListRenderer from '../../../../Components/listRenderer';
import ConsentForm from './consentForm';
import DocumentForm from './documentForm';

import { formTabListGet, resetFormTabListGet } from '../../../../store/actions';
class Forms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      tabList: null,
      isDocumentFormRequired: true,
    };
  }
  list = [
    {
      Name: 'Intake Forms',
      ExtraComponent: props => {
        return (
          <div className="">
            <ConsentForm
              {...props}
              type="Intake Forms"
              clientId={this.props.clientId}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
            <DocumentForm
              {...props}
              type="Intake Forms"
              clientId={this.props.clientId}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
          </div>
        );
      },
    },
    {
      Name: 'Financial',
      ExtraComponent: props => {
        return (
          <div className="">
            <ConsentForm
              {...props}
              type="Financial"
              clientId={this.props.clientId}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
            <DocumentForm
              {...props}
              type="Financial"
              clientId={this.props.clientId}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
          </div>
        );
      },
    },
    {
      Name: 'ROIs',
      ExtraComponent: props => {
        return (
          <div className="">
            <ConsentForm
              {...props}
              type="ROIs"
              clientId={this.props.clientId}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
            <DocumentForm
              {...props}
              type="ROIs"
              clientId={this.props.clientId}
              isReadOnlyUser={this.props.isReadOnlyUser}
              isDeActivated={this.props.isDeActivated}
            />
          </div>
        );
      },
    },
    // {
    //   Name: 'ASAM',
    //   ExtraComponent: () => {
    //     return <div className="Activities">tabs</div>;
    //   },
    // },
    // {
    //   Name: 'Assessment Tools',
    //   ExtraComponent: () => {
    //     return <div className="Activities">tabs</div>;
    //   },
    // },
  ];
  componentDidMount() {
    this.props.dispatch(formTabListGet({}));
  }
  DocumentFormRequired = view => {
    this.setState({
      isDocumentFormRequired: view,
    });
  };
  componentWillReceiveProps({ TabData }) {
    if (TabData && JSON.stringify(TabData) != JSON.stringify(this.props.TabData)) {
      let tabs =
        TabData.length > 0 &&
        TabData.map(item => {
          return {
            Name: item.TabName,
            ExtraComponent: props => {
              return (
                <div className="">
                  <ConsentForm
                    {...props}
                    TabName={item.Value}
                    clientId={this.props.clientId}
                    DocumentFormRequired={this.DocumentFormRequired}
                    isReadOnlyUser={this.props.isReadOnlyUser}
                    isDeActivated={this.props.isDeActivated}
                  />
                  {this.state.isDocumentFormRequired && (
                    <DocumentForm
                      {...props}
                      TabName={item.Value}
                      DocTypeValue={item.DocTypeValue}
                      clientId={this.props.clientId}
                      isReadOnlyUser={this.props.isReadOnlyUser}
                      isDeActivated={this.props.isDeActivated}
                    />
                  )}
                </div>
              );
            },
          };
        });
      this.setState({
        tabList: tabs,
      });
    }
  }

  render() {
    const { tabList } = this.state;
    return (
      <div class="mx-2">
        {tabList && (
          <div class="forms center-tabs nested-tabs-content">
            <ListRenderer
              list={tabList}
              activeKey={this.state.activeKey}
              getActiveKey={activeKey => this.setState({ activeKey })}
              destroyInactiveTabPane={true}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { form_tab_list_get, default_values } = state;
  const { data } = form_tab_list_get;

  let TabData = null;
  let isReadOnlyUser = false;

  if (data && data.result) {
    TabData = data.result || [];
  }
  if (default_values && default_values.data) {
    isReadOnlyUser = default_values.data.IsReadOnly;
  }

  return {
    TabData: TabData,
    isReadOnlyUser,
  };
};
export default connect(mapStateToProps)(withRouter(Forms));
