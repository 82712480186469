import React, { Component } from 'react';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxAutoComplete,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import AntdModal from '../Components/CustomModal';
import validate, {
  dateofbirthNormalize,
  normalizeToYYYYMMDD,
  MobileFormatter,
  MobileNormalize,
} from '../Screens/enrollmentForm/validation/validate';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import {
  adminstrationGetStaff,
  adminstrationStaffUpsert,
  adminstrationResetStaff,
  resetAdminstrationStaffUpsert,
  staffDelete,
  resetStaffDelete,
  appInvite,
  getMobileAppDownloadStatus,
  resetGetMobileAppDownloadStatus,
  updateMobileAppRole,
  resetupdateMobileAppRole,
  changeAppRole,
  resetChangeAppRole,
  listCombos,
  staffReactivate,
  resetStaffReactivate,
  listMobileAppRoleGet,
} from '../store/actions';
import { stringDate } from '../util/date';
import {
  Delete_Confirmation_Msg,
  Upsert_Sucess_Msg,
  Deactivate_Sucess_Msg,
  Deactivate_Fail_Msg,
} from '../app-config';
import Select from '../component/select/index';
import { Modal, Button } from 'antd';
// import { PhoneIphoneOutlinedIcon } from '@material-ui/icons';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Loader from '../Components/Loader';
import staticData from '../Screens/enrollmentForm/formSteps/staticDropdown';
import { ProcessCombos } from '../store/comboConfig';
import RolesPermissions from './PopupModals/RolesPermissions';
import PageTitle from '../Components/PageTitle';

class ActivityBase extends React.Component {}

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  CenterRequired: value => (value ? undefined : ' from the dropdown options.'),
  dobStaff: value => {
    if (value && value.length == 10) {
      let dobArray = value && value.split('/');
      if (
        dobArray[0] < 1 ||
        dobArray[0] > 12 ||
        dobArray[1] < 1 ||
        dobArray[1] > 31 ||
        dobArray[2].length != 4 ||
        dobArray[2] < 1900
        // dobArray[0].length != 4 ||
        // dobArray[0] < 1900 ||
        // dobArray[1] < 1 ||
        // dobArray[1] > 12 ||
        // dobArray[2] < 1 ||
        // dobArray[2] > 31
      ) {
        return 'Format(MM/DD/YYYY)';
      }
    } else {
      return 'Format(MM/DD/YYYY)';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  // allowNumericMobile: value =>
  //   value && !/^[\d\(\)\-\s]+$/.test(value) ? 'Please enter numeric values only' : undefined,

  allowNumericMobile: value => {
    if (value) {
      const numWithoutChar = value.replace(/[^0-9\-\(\)\s)]+/g, '');
      if (value == numWithoutChar) {
        // const onlyNums = value.replace(/^[\d\(\)\-\s]+$/g, '');
        const onlyNums = numWithoutChar.replace(/\D/g, '');
        if (onlyNums.length != 10) {
          return 'Please enter 10 digits';
        }
      } else {
        return 'numeric values only';
      }
    }
  },
  allowNumericNPI: value =>
    value && !/^[0-9--]{10}$/.test(value) ? ' 10 digit numeric values only' : undefined,
};
// const dateofbirthNormalize = (val, prevVal) => {
//   if (!val) {
//     return val;
//   }
// };

class DeactivatingModal extends Component {
  state = {
    newStaffValue: '',
  };

  handleStaffChange = newStaffValue => {
    this.setState({ newStaffValue });
  };

  componentDidMount() {
    this.props.dispatch(
      listCombos({
        CenterId: this.props.centerIdFromParent,
        comboTypes: 'StaffByCenterId',
      }),
    );
  }
  componentWillReceiveProps(nextprops) {
    this.setState({});
  }
  getStaffId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffid');
    if (staffId) {
      staffId = parseInt(staffId);
    } else {
      staffId = -1;
    }
    return staffId;
  }
  error = () => {
    Modal.error({
      content: 'Please Select New Staff',
    });
  };
  render() {
    const { onOk, onCancel, staffByCenter, header, onModalCancel, isRemoved } = this.props;
    const { newStaffValue } = this.state;
    const staffId = this.getStaffId();
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          {/* <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button> */}
          <h4 class="modal-title col-12">{header}</h4>
        </div>
        {isRemoved == -1 && (
          <div class="modal-body col-md-8 offset-md-2">
            <div class="form-group">
              <ReduxSelect
                options={staffByCenter}
                onChange={this.handleStaffChange}
                className="basic-single"
                classNamePrefix="select"
                value={newStaffValue}
                placeholder="Select New Staff "
              />
            </div>
          </div>
        )}
        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button type="button" class="btn black-btn font-weight-bold mr-2" onClick={onModalCancel}>
            {isRemoved == -1 ? 'Cancel' : 'OK'}
          </button>
          {isRemoved == -1 && (
            <button
              type="button"
              onClick={() => {
                if (newStaffValue != staffId) {
                  this.props.dispatch(
                    staffDelete({ staffId: staffId, newStaffId: this.state.newStaffValue }),
                  );
                  // onOk();
                  this.props.history.push('/manage/staff');
                } else this.error();
              }}
              class="btn"
            >
              Move
            </button>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToPropsDeactivate = ({ combos, staff_delete }) => {
  const { data } = combos;
  let staffByCenter = [];
  if (data.StaffByCenterId) {
    staffByCenter = data.StaffByCenterId;
  }
  return {
    staffByCenter: staffByCenter,
  };
};

const DeactivatingModalRedux = connect(mapStateToPropsDeactivate)(withRouter(DeactivatingModal));

class ChangeAppRoleModal extends Component {
  state = {
    mobileAppRoleValue: '',
  };

  handleRoleChange = mobileAppRoleValue => {
    this.setState({ mobileAppRoleValue });
  };
  formatMobile = num => {
    let mobVal = num && num.replace(/[&\/\\#/\-, +()$~%.'":*?<>{}]/g, '');
    return (mobVal = mobVal && mobVal.slice(mobVal.length - 10));
  };
  render() {
    const { onOk, onCancel, type, MobileAppRoleCombo, staffid, dispatch, phoneNumber } = this.props;
    const { mobileAppRoleValue } = this.state;
    return (
      <div class="modal-content border-0">
        <div class="modal-header justify-content-center flex-wrap  border-bottom-0 ">
          <button type="button" class="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4 class="modal-title col-12">Select Your Role</h4>
        </div>
        {/* {this.props.flag ? (
          <>
            <div class="modal-body col-md-6 offset-md-3">
              <h2>Invalid Phone Number</h2>
            </div>
            <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
              <button type="button" class="btn black-btn font-weight-bold mr-2" onClick={onCancel}>
                Ok
              </button>
            </div>
          </>
        ) : (
          'valid'
        )} */}

        <div class="modal-body col-md-8 offset-md-2">
          <div class="form-group">
            <ReduxSelect
              options={MobileAppRoleCombo}
              onChange={this.handleRoleChange}
              className="basic-single"
              classNamePrefix="select"
              value={mobileAppRoleValue}
              placeholder="Mobile App Role "
            />
          </div>
        </div>
        <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
          <button
            type="button"
            class="btn btn-outline-primary font-weight-bold mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          {type == 'appRole' && (
            <button
              type="button"
              onClick={() => {
                dispatch(
                  changeAppRole({
                    StaffId: staffid,
                    MobileAppRole: mobileAppRoleValue,
                    PhoneNumber: this.formatMobile(phoneNumber),
                  }),
                );
                onOk();
              }}
              class="btn"
            >
              Save
            </button>
          )}
          {type == 'inviteSms' && (
            <button
              type="button"
              onClick={() => {
                let formatedValue = this.formatMobile(this.props.mobileNumberValue);
                this.props.dispatch(
                  appInvite({
                    StaffId: this.props.staffid,
                    MobileAppRole: mobileAppRoleValue,
                    PhoneNumber: formatedValue,
                  }),
                );
                onOk();
              }}
              class="btn"
            >
              Send
            </button>
          )}
        </div>
      </div>
    );
  }
}

class SendInvitationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileAppRoleValue: '' || null,
      mobileNumberValue: '' || null,
      flag: true,
    };
  }

  getStaffId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffid');
    if (staffId) {
      staffId = parseInt(staffId);
    } else {
      staffId = -1;
    }
    return staffId;
  }
  formatMobile = num => {
    if (num.length > 0) {
      let mobVal = num.replace(/[&\/\\#/\-, +()$~%.'":*?<>{}]/g, '');
      if (num.charAt(0) === '+1') return (mobVal = mobVal.substring(1));
      if (mobVal.length >= 10) return (mobVal = mobVal.slice(mobVal.length - 10));
      else return mobVal;
    } else return num;
  };
  componentWillReceiveProps({
    phoneMobileValue,
    mobileAppRoleValue,
    isUpdateMobileRole,
    isUpdateMobileRole1,
  }) {
    if (phoneMobileValue !== this.props.phoneMobileValue) {
      this.setState({
        mobileNumberValue: phoneMobileValue,
        mobileAppRoleValue: mobileAppRoleValue,
      });
      let verifiedphoneMobileValue =
        phoneMobileValue && phoneMobileValue.length > 0 ? phoneMobileValue : 0;
      // verifiedphoneMobileValue = this.formatMobile(verifiedphoneMobileValue);
      if (verifiedphoneMobileValue > 0) {
        var abc = verifiedphoneMobileValue.match(/^\d{10}$/);
      }

      if (abc) {
        this.props.dispatch(
          getMobileAppDownloadStatus({
            PhoneNumber: verifiedphoneMobileValue,
          }),
        );
        this.setState({
          flag: true,
        });
      } else {
        this.setState({
          flag: false,
        });
        // alert('invalid no');
      }
    }
    if (isUpdateMobileRole1) {
      this.props.dispatch(resetChangeAppRole());
      this.props.dispatch(adminstrationGetStaff({ StaffId: this.getStaffId() }));
    }
  }

  handleRoleChange = mobileAppRoleValue => {
    this.setState({ mobileAppRoleValue });
  };

  mobileNumberValueChange = e => {
    let formatedValue = this.formatMobile(e.target.value);
    this.setState({ mobileNumberValue: formatedValue });
  };

  onSendInvite = data => {
    const { mobileAppRoleValue, mobileNumberValue } = this.state;
    const sid = this.getStaffId();
    // let formatedValue = this.formatMobile(mobileNumberValue);
    this.props.dispatch(
      appInvite({
        staffid: sid,
        MobileAppRole: mobileAppRoleValue,
        PhoneNumber: this.formatMobile(mobileNumberValue),
      }),
    );
  };
  error = () => {
    Modal.error({
      content: 'Invalid Mobile Number',
    });
  };
  render() {
    const {
      MobileAppRoleCombo,
      appDownloadStatus,
      mobileAppStatus,
      mobileAppMessage,
      appInviteSMS,
    } = this.props;
    const { mobileAppRoleValue, mobileNumberValue } = this.state;
    const AppRoleStatus = {
      '0': '',
      C: 'Coach',
      L: 'Counselor',
      G: 'Guide',
      P: 'Peer',
    };
    const staffId = this.getStaffId();
    var AppStatusMsg = appDownloadStatus && appDownloadStatus.AppStatus;
    const AppStatuscount = AppStatusMsg && AppStatusMsg.charAt(0);
    AppStatusMsg = AppStatusMsg && AppStatusMsg.substring(1);
    return (
      <>
        <div class="row  mb-3">
          <div class="col-12">
            <div class="card border-0 rounded-0 ">
              <div class="card-body enrollment-form">
                <div class="row pl-3 pr-3  app-ref d-block">
                  <div className="p-3">
                    <span style={{ fontWeight: 800 }}> Mobile App Role : </span>
                    <span className="status">
                      {appDownloadStatus && appDownloadStatus.MobileAppRole ? (
                        <>
                          {appDownloadStatus && appDownloadStatus.MobileAppRole}
                          <AntdModal
                            ModalButton={({ onClick }) => (
                              <Button
                                type="link"
                                onClick={onClick}
                                style={{
                                  fontWeight: '700',
                                  marginLeft: '10px',
                                  color: '#00c1ff',
                                }}
                              >
                                Change Role
                              </Button>
                            )}
                            modalWidth={500}
                            ModalContent={props => (
                              <ChangeAppRoleModal
                                {...props}
                                type="appRole"
                                MobileAppRoleCombo={MobileAppRoleCombo}
                                staffid={staffId}
                                dispatch={this.props.dispatch}
                                phoneNumber={mobileNumberValue}
                              />
                            )}
                          />
                        </>
                      ) : null
                      // <>
                      //   {AppRoleStatus[mobileAppRoleValue]}
                      //   <AntdModal
                      //     ModalButton={({ onClick }) => (
                      //       <Button
                      //         type="link"
                      //         onClick={onClick}
                      //         style={{
                      //           fontWeight: '700',
                      //           marginLeft: '10px',
                      //           color: '#00c1ff',
                      //         }}
                      //       >
                      //         Change Role
                      //       </Button>
                      //     )}
                      //     ModalContent={props => (
                      //       <ChangeAppRoleModal
                      //         {...props}
                      //         type="appRole"
                      //         MobileAppRoleCombo={MobileAppRoleCombo}
                      //         staffid={staffId}
                      //         dispatch={this.props.dispatch}
                      //       />
                      //     )}
                      //   />
                      // </>
                      }
                    </span>
                  </div>
                  <div className="p-3">
                    <span style={{ fontWeight: 800 }}> App Installed Status : </span>
                    <span className="status">
                      {/* {AppStatuscount == '1' ? `${AppStatusMsg}` : null} */}
                      {!mobileNumberValue ? (
                        ' Please enter mobile phone number in the main form below to send mobile app invite.'
                      ) : (
                        <>
                          {mobileNumberValue && AppStatuscount == '1' ? (
                            <>
                              {!appInviteSMS ? (
                                <>
                                  {AppStatusMsg}
                                  <AntdModal
                                    ModalButton={({ onClick }) => (
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          if (this.state.flag) {
                                            onClick();
                                          } else {
                                            this.error();
                                          }
                                        }}
                                        style={{ color: '#00c1ff', fontWeight: '700' }}
                                      >
                                        Send Invite Now
                                      </Button>
                                    )}
                                    modalWidth={500}
                                    ModalContent={props => (
                                      <ChangeAppRoleModal
                                        {...props}
                                        type="inviteSms"
                                        MobileAppRoleCombo={MobileAppRoleCombo}
                                        staffid={staffId}
                                        dispatch={this.props.dispatch}
                                        mobileNumberValue={mobileNumberValue}
                                        // flag={this.state.flag}
                                      />
                                    )}
                                  />
                                </>
                              ) : (
                                '  Invitation sent successfully'
                              )}
                            </>
                          ) : null}
                          {AppStatuscount == '3' ? (
                            <>
                              {AppStatusMsg}
                              <span className="resendRef" onClick={this.onSendInvite}>
                                Resend Referral
                              </span>
                            </>
                          ) : null}

                          {AppStatuscount == '2' ? `${AppStatusMsg}` : null}
                        </>
                      )}
                    </span>
                  </div>
                </div>
                {/* <div class="row pl-3 pr-3  app-ref">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <ReduxSelect
                        options={MobileAppRole}
                        onChange={this.handleRoleChange}
                        className="basic-single"
                        classNamePrefix="select"
                        value={mobileAppRoleValue}
                        placeholder="Mobile App Role "
                      />
                    </div>
                    <p>
                      Status:
                      <span className="status">
                        {appDownloadStatus ? appDownloadStatus : 'Loading...'}
                      </span>
                    </p>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={mobileNumberValue}
                        onChange={e => {
                          this.mobileNumberValueChange(e);
                        }}
                        placeholder="Please Enter your mobile number"
                      />
                      {/* <ReduxPhoneInput
                        className="form-control"
                        value={mobileNumberValue}
                        onChange={e => {
                          this.setState({ mobileNumberValue: e });
                        }}
                        placeholder="Please Enter your mobile number"
                      /> */}
                {/* </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4 ">
                    <input
                      type="button"
                      className="btn"
                      style={{ padding: '5px 20px' }}
                      value="Send Mobile App Invitation"
                      onClick={this.onSendInvite}
                    />
                  </div>
                </div>  */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPropsMobile = state => {
  const {
    app_invite,
    combos,
    get_mobile_app_download_status,
    staff_get,
    update_mobile_app_role,
    change_app_role,
    list_mobile_appRole_get,
  } = state;
  let mobileAppRoleValue = null;
  const { data: appRoleData } = list_mobile_appRole_get;
  const { data, error, isFetching } = app_invite;
  if (staff_get.data) {
    mobileAppRoleValue = staff_get.data.MobileAppRole;
  }
  let appInviteSMS = null;
  let MobileAppRoleCombo = [];
  let appDownloadStatus,
    isUpdateMobileRole = false,
    isUpdateMobileRole1 = false;
  if (data && data.Success == true) {
    appInviteSMS = data.Success;
  }
  // if (!combos.data) {
  //   combos.data = {
  //     MobileAppRoleCombo: [],
  //   };
  // }
  if (get_mobile_app_download_status.data) {
    appDownloadStatus = get_mobile_app_download_status.data.result;
  }
  if (update_mobile_app_role.data && update_mobile_app_role.data.success) {
    isUpdateMobileRole = true;
  }
  if (change_app_role.data && change_app_role.data.success) {
    isUpdateMobileRole1 = true;
  }
  if (appRoleData && appRoleData) {
    MobileAppRoleCombo = appRoleData.data;
  }
  return {
    MobileAppRoleCombo: MobileAppRoleCombo,
    appInviteSMS,
    phoneMobileValue: selector(state, 'phoneMobile'),
    appDownloadStatus,
    mobileAppRoleValue,
    isUpdateMobileRole,
    isUpdateMobileRole1,
  };
};

const ReduxSendInvitationForm = connect(mapStateToPropsMobile)(withRouter(SendInvitationForm));

class Staff_Form extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      staffData: {
        centerId: this.getCenterId() || this.props.centerId,
        country: 'USA',
        // centerId
        isRemoved: '',
      },
    };
  }

  success = () => {
    Modal.success({
      content: 'Mobile app referral successfully sent',
    });
  };

  getCenterId() {
    var param = new URLSearchParams(this.props.history.location.search);
    return parseInt(param.get('centerId') || param.get('centerid'));
  }

  getStaffId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var staffId = param.get('staffId') || param.get('staffid');
    if (staffId) {
      staffId = parseInt(staffId);
    } else {
      staffId = -1;
    }
    return staffId;
  }

  componentDidMount() {
    this.props.dispatch(
      listMobileAppRoleGet({
        json: JSON.stringify({
          Combo: [
            {
              ForStaffId: this.getStaffId(),
            },
          ],
        }),
      }),
    );
    this.props.dispatch(resetGetMobileAppDownloadStatus({}));
    var result = ProcessCombos(
      'Center,PayType,StaffRole,StateProvince,Country,Gender,MobileAppRole',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }

    if (this.getStaffId() > 0) {
      this.props.dispatch(adminstrationGetStaff({ StaffId: this.getStaffId() }));
    } else {
      this.props.dispatch(adminstrationResetStaff({ StaffId: this.getStaffId() }));
    }
  }
  toggle = (header, isRemoved) => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
      isRemoved,
    }));
  };
  Removetoggle = () => {
    this.setState(prevState => ({
      isRemoveModelOpen: !prevState.isRemoveModelOpen,
    }));
  };

  componentWillReceiveProps({
    staffData,
    isInserted,
    isRemoved,
    recordInfo,
    defaultCenter,
    appInviteSMS,
    StaffByCenterId,
    isActivated,
    countryISO,
  }) {
    if (appInviteSMS != this.props.appInviteSMS) {
      this.success();
    }
    if (countryISO && countryISO != this.props.countryISO) {
      this.props.dispatch(
        listCombos({
          comboTypes: 'StateProvince',
          CountryISO: countryISO,
        }),
      );
    }
    if (isInserted) {
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(resetAdminstrationStaffUpsert());
      //this.props.history.push('/manage/staff');
    }
    if (recordInfo) {
      if (isRemoved == 1) {
        this.toggle('Staff ' + Deactivate_Sucess_Msg, isRemoved);
        this.props.dispatch(resetStaffDelete());
      }
      if (isRemoved == -1) {
        // this.props.dispatch(
        //   listCombos({
        //     CenterId: this.state.staffData.centerId,
        //     comboTypes: 'StaffByCenterId',
        //   }),
        // );
        this.toggle(Deactivate_Fail_Msg, isRemoved);
      }
    }
    if (isActivated) {
      if (isActivated.result == 1) {
        this.toggle('Staff reactivated successfully', isActivated.result);
        this.props.dispatch(resetStaffReactivate());
      }
    }
    if (staffData && staffData.StaffId && this.getStaffId() > 0) {
      let dobArray = (staffData.DateofBirth && staffData.DateofBirth.split('-')) || null;
      let dobString = dobArray && dobArray[1] + '/' + dobArray[2] + '/' + dobArray[0];
      let isMisc = staffData.Misc;
      const data = {
        firstName: staffData.FirstName,
        middleName: staffData.MiddleName,
        lastName: staffData.LastName,
        gender: staffData.Gender,
        line1: staffData.AddressLine1,
        line2: staffData.AddressLine2,
        city: staffData.City,
        stateOrProvince: staffData.StateOrProvince,
        country: staffData.CountryISO,
        zip: staffData.ZipCode,
        dateofBirth: dobString || '',
        phoneMobile: staffData.PhoneMobile,
        phoneHome: staffData.PhoneHome,
        emailWork: staffData.EmailWork,
        emailHome: staffData.EmailHome,
        //designation: staffData,
        paytype: staffData.PayTypeId,
        centerId: staffData.CenterId,
        role: staffData.StaffRoleId,
        isProvider: staffData.IsProvider,
        appUserName: isMisc && staffData.Misc[0].AppUserName,
        title: isMisc && staffData.Misc[0].Title,
        professionalBio: isMisc && staffData.Misc[0].ProfessionalBio,
        shortStory: isMisc && staffData.Misc[0].ShortStory,
        education: isMisc && staffData.Misc[0].Education,
        exp: isMisc && staffData.Misc[0].Exp,
        certification: isMisc && staffData.Misc[0].Cert,
        specialties: isMisc && staffData.Misc[0].Spec,
        // goal: isMisc && staffData.Misc[0].Goal,
        npi: isMisc && staffData.Misc[0].NPI,
        insuranceAccepted: isMisc && staffData.Misc[0].InsuranceAccepted,
        licensedInStates: isMisc && staffData.Misc[0].LicensedInStates,
      };
      this.setState({ staffData: data });
    } else {
      // staf : centerId
      this.setState({
        staffData: { centerId: this.getCenterId(), country: 'USA' },
      });
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  onSubmit(values) {
    const {
      firstName,
      lastName,
      middleName,
      gender,
      line1,
      line2,
      city,
      stateOrProvince,
      country,
      zip,
      dateofBirth,
      phoneMobile,
      phoneHome,
      emailWork,
      emailHome,
      designation,
      paytype,
      centerId,
      role,
      title,
      professionalBio,
      shortStory,
      education,
      exp,
      certification,
      specialties,
      goal,
      npi,
      insuranceAccepted,
      licensedInStates,
      isProvider,
      appUserName,
    } = values;
    var staffId = null;

    var paramStaffId = this.getStaffId();
    if (paramStaffId && paramStaffId > 0) {
      staffId = paramStaffId;
    }
    const data = {
      Staff: [
        {
          AddressLine1: line1,
          AddressLine2: line2,
          AddressLine3: null,
          CenterId: centerId,
          City: city,
          CountryISO: country,
          DateofBirth: stringDate(dateofBirth),
          EmailWork: emailWork,
          EmailHome: emailHome,
          FirstName: firstName,
          Gender: gender,
          LastName: lastName,
          MiddleName: middleName,
          PayTypeId: paytype,
          PhoneHome: phoneHome,
          PhoneMobile: phoneMobile && phoneMobile.replace(/\D/g, ''),
          PrimaryLanguageISO: null,
          RegistrationStatus: 'Registered',
          StaffId: staffId,
          StaffRoleId: role,
          StateOrProvince: stateOrProvince,
          ZipCode: zip,
          IsProvider: isProvider,
          Misc:
            (isProvider && [
              {
                AppUserName: appUserName,
                Title: title,
                ProfessionalBio: professionalBio,
                ShortStory: shortStory,
                Education: education,
                Exp: exp,
                Cert: certification,
                Spec:
                  typeof values.specialties == 'object'
                    ? values.specialties
                      ? values.specialties.map(item => item.Text).toString()
                      : ''
                    : values.specialties || '',
                // Goal: goal,
                NPI: npi,
                InsuranceAccepted: insuranceAccepted,
                // LicensedInStates: licensedInStates,
                LicensedInStates:
                  typeof values.licensedInStates == 'object'
                    ? values.licensedInStates
                      ? values.licensedInStates.map(item => item.Value).toString()
                      : ''
                    : values.licensedInStates || '',
              },
            ]) ||
            [],
        },
      ],
    };
    var staffData = {
      staffId: staffId,
      email: data.Staff[0].EmailWork,
      jsonInput: JSON.stringify(data),
    };

    this.props.dispatch(adminstrationStaffUpsert(staffData));
  }

  onModalCancel = () => {
    this.setState({
      isModelOpen: false,
    });
    this.props.history.push('/manage/staff');
  };

  render() {
    var staffURL = '/manage/staff';
    var centerId = this.getCenterId();
    if (centerId && centerId > 0) {
      staffURL = '/manage/staff?centerId=' + centerId;
    }
    return (
      <>
        <PageTitle TitleText="Staff" />
        <React.Fragment>
          <div className="edit-staff-mo">
            <Loader loading={this.props.isFetching} />
            <ReduxEnrollmentForm
              onSaveClick={this.onSubmit.bind(this)}
              initialValues={this.state.staffData}
              onCancel={() => this.props.history.push(staffURL)}
              getStaffId={this.getStaffId()}
              getCenterId={centerId}
              Delete={() => {
                this.Removetoggle();
                //this.props.history.push('/manage/staff')
              }}
              history={this.props.history}
              dispatch={this.props.dispatch}
              mobileAppStatus={this.props.mobileAppStatus}
              mobileAppMessage={this.props.mobileAppMessage}
              isDeactivated={this.props.staffData.IsDeactivated}
            />
            <Modal visible={this.state.isModelOpen} footer={null} onCancel={this.onModalCancel}>
              <DeactivatingModalRedux
                header={this.state.header}
                centerIdFromParent={this.state.staffData.centerId}
                onModalCancel={this.onModalCancel}
                isRemoved={this.state.isRemoved}
              />
            </Modal>
            {/* <CustomModal
            isOpen={this.state.isModelOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              this.props.history.push(staffURL);
            }}
            yesText="OK"
            hideCancel={true}
          /> */}
            <CustomModal
              isOpen={this.state.isRemoveModelOpen}
              header={Delete_Confirmation_Msg}
              yesAction={() => {
                this.Removetoggle();
                this.props.dispatch(staffDelete({ staffId: this.getStaffId() }));
              }}
              yesText="submit"
              noText="cancel"
              noAction={() => {
                this.Removetoggle();
                this.props.history.push(staffURL);
              }}
            />
          </div>
        </React.Fragment>
      </>
    );
  }
}

let EnrollmentForm = ({
  onSaveClick,
  onSubmit,
  handleSubmit,
  staffData,
  initialize,
  getStaffId,
  getCenterId,
  StaffRole,
  Center,
  PayType,
  StateProvince,
  Country,
  Gender,
  initialValues,
  onCancel,
  Delete,
  history,
  dispatch,
  name,
  phoneMobileValue,
  MobileAppRole,
  mobileAppStatus,
  mobileAppMessage,
  isProviderValue,
  isDeactivated,
}) => {
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div class="main enrollment-page mb-5">
        <div class="container-fluid">
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <h4 class="form-main-head">
                {getStaffId > 0 ? 'Edit Staff' : 'Add A Staff'}
                &nbsp;
                {/* {getStaffId > 0 && (
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <input
                        type="button"
                        class="btn"
                        value="Mobile App Referral"
                        onClick={onClick}
                      />
                    )}
                    ModalContent={props => (
                      <ReduxSMSModal {...props} phoneMobileValue={phoneMobileValue} />
                    )}
                  />
                )} */}
              </h4>
            </div>
            {/* <div class="col-12 col-md-8 col-lg-4 text-center">
              {getStaffId > 0 && (
                <div className="roleHeader">
                  <h4 class="main-head main-head2 text-uppercase">App Role &nbsp;</h4>
                  <p class="form-control roleClass main-head2">NO ROLE</p>
                  &nbsp;&nbsp;
                  <AntdModal
                    ModalButton={({ onClick }) => (
                      <input type="button" class="btn" value="Change Role" onClick={onClick} />
                    )}
                    ModalContent={props => (
                      <ChangeAppRoleModal {...props} phoneMobileValue={phoneMobileValue} />
                    )}
                  />
                </div>
              )}
            </div> */}
            <div class="col-12 col-md-8 col-lg-8 text-right">
              <input
                type="button"
                class=" btn btn-outline-primary"
                value="Cancel"
                onClick={onCancel}
              />
              &nbsp;
              <input type="submit" class="btn" value="Save" />
            </div>
          </div>
          {getStaffId > 0 && (
            <ReduxSendInvitationForm
              mobileAppStatus={mobileAppStatus}
              mobileAppMessage={mobileAppMessage}
            />
          )}
          <div class="row  mb-5">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form staff-form">
                  <div class="row pb-3 pl-3 pr-3 pt-3">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <h5>Personal Details </h5>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="firstName"
                            type="text"
                            errorMessage="First Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'First Name'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">First Name*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="middleName"
                            type="text"
                            component={ReduxInput}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Middle Name</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="lastName"
                            type="text"
                            errorMessage="Last Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Last Name'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Last Name*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="gender"
                            type="text"
                            component={ReduxSelect}
                            options={Gender}
                            errorMessage="Gender Required"
                            validate={Validator.required}
                            placeholder="Gender*"
                            fieldName={'Gender'}
                            disabled={isDeactivated}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="line1"
                            type="text"
                            errorMessage="Permanent Address Line 1 Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Permanent Address'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">
                            Permanent Address Line 1*
                            {/* <span class="small-text">(Last Known Address)</span> */}
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="line2"
                            type="text"
                            component={ReduxInput}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label"> Permanent Address Line 2</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="country"
                            type="text"
                            errorMessage="Country Required"
                            component={ReduxSelect}
                            options={Country}
                            validate={Validator.required}
                            placeholder="Country*"
                            fieldName={'Country'}
                            disabled={isDeactivated}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="city"
                            type="text"
                            errorMessage="City Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'City'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">City*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="stateOrProvince"
                            type="text"
                            errorMessage="State Required"
                            component={ReduxSelect}
                            options={StateProvince}
                            validate={Validator.required}
                            placeholder={'State/Province/Territories*'}
                            fieldName={'State/Province/Territories'}
                            disabled={isDeactivated}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="zip"
                            type="text"
                            errorMessage="Zip Code Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Zip Code'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Zip Code*</label>
                        </div>
                      </div>
                      {/* <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="dateofBirth"
                            errorMessage="DOB Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            placeholder="MM/DD/YYYY"
                          //label={'Date of Birth*'}
                          />
                          <label class="placeholder-label">Date of Birth*</label>
                        </div>
                      </div> */}
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            component={ReduxInput}
                            name="dateofBirth"
                            normalize={dateofbirthNormalize}
                            // validate={Validator.dobStaff}
                            placeholder="MM/DD/YYYY"
                            fieldName={'Date of Birth'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Date of Birth</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4 d-flex">
                        {/* <div class="form-group pr-1 phone-code">
                          <select class="custom-select enrollment-select ">
                            <option selected="">+ 1</option>
                            <option selected="">+ 91</option>
                            <option value="">+ 44</option>
                            <option value="">+ 33 </option>
                          </select>
                        </div> */}
                        <div class="form-group w-100 addStaff1">
                          <Field
                            name="phoneMobile"
                            component={ReduxInput}
                            validate={validate.NumericMobile}
                            format={MobileFormatter}
                            normalize={MobileNormalize}
                            fieldName={'Mobile'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Phone Mobile</label>
                          {/* <Field
                            name="phoneMobile"
                            component={ReduxPhoneInput}
                            className="form-control"
                            // validate={Validator.required}
                          /> */}
                          {/* <ReduxPhoneInput value={smsNumber} className="form-control" placeholder="Phone Mobile"/> */}
                          {/* <label class="placeholder-label">Phone Mobile</label> */}
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4 d-flex">
                        {/* <div class="form-group pr-1 phone-code">
                          <select class="custom-select enrollment-select ">
                            <option selected="">+ 1</option>
                            <option selected="">+ 91</option>
                            <option value="">+ 44</option>
                            <option value="">+ 33 </option>
                          </select>
                        </div> */}
                        <div class="form-group w-100">
                          <Field
                            name="phoneHome"
                            component={ReduxInput}
                            // validate={Validator.required}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Phone Home</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="emailWork"
                            type="email"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'E-mail Work'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">E-mail Work*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field name="emailHome" type="email" component={ReduxInput} />
                          <label class="placeholder-label">E-mail Home</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          {/* <label class="placeholder-label">Paytype*</label> */}
                          <Field
                            name="paytype"
                            type="text"
                            errorMessage="Paytype Required"
                            component={ReduxSelect}
                            placeholder="Paytype*"
                            options={PayType}
                            validate={Validator.required}
                            fieldName={'Paytype'}
                            disabled={isDeactivated}
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          {/* <label class="placeholder-label">Center*</label> */}
                          <Field
                            type="text"
                            name={`centerId`}
                            errorMessage="Center Required"
                            component={ReduxSelect}
                            placeholder="Center*"
                            options={Center}
                            validate={Validator.CenterRequired}
                            fieldName={'Please choose'}
                            disabled={isDeactivated}
                          />
                        </div>
                      </div>

                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          {/* <label class="placeholder-label">Position*</label> */}
                          <Field
                            name="role"
                            type="text"
                            errorMessage="Role Required"
                            component={ReduxSelect}
                            placeholder="Position*"
                            options={StaffRole}
                            validate={Validator.required}
                            fieldName={'Role'}
                            disabled={isDeactivated}
                          />
                        </div>
                      </div>
                      <div class="col-12 mt-2">
                        <div class="form-group">
                          <label class="custom-control-label">
                            Are you an app provider?{' '}
                            <Field
                              name="isProvider"
                              type="text"
                              class="custom-control-input not-empty"
                              component={ReduxToggle}
                              disabled={isDeactivated}
                            />
                            {'   '}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isProviderValue && (
                    <div class="row">
                      <div class="col-12">
                        <h5>Credentials </h5>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="appUserName"
                            type="text"
                            errorMessage="App User Name Required"
                            component={ReduxInput}
                            // validate={Validator.required}
                            fieldName={'Title'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">App Display Name</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="title"
                            type="text"
                            errorMessage="First Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Title'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">
                            Title (e.g. Peer Recovery Coach,Life Coach)*
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`professionalBio`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Professional Bio'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Professional Bio*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`shortStory`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Short Story'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">
                            Please write a short description of your services*
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`education`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Education'}
                            // disabled={disable}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">
                            Education (Institution,degree,year of graduation)*
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="exp"
                            type="text"
                            errorMessage="First Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Experience'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Relevant Experience (in years)*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`certification`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Certification'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">
                            Certification(s) /Accreditation(s)*
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`specialties`}
                            type="text"
                            placeholder="Specialties*"
                            component={ReduxSelect}
                            validate={Validator.required}
                            options={staticData.specialtiesOptions}
                            fieldName={'specialties'}
                            isMulti
                            disabled={isDeactivated}
                          />
                          {/* <Field
                          name={`specialties`}
                          type="textarea"
                          component={ReduxInput}
                          validate={Validator.required}
                          fieldName={'Specialties'}
                        />
                        <label class="placeholder-label">Specialties*</label> */}
                        </div>
                      </div>
                      {/* <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`goal`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Goal(s)'}
                          />
                          <label class="placeholder-label">Goal(s)*</label>
                        </div>
                      </div> */}
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="npi"
                            type="text"
                            // errorMessage="First Name Required"
                            component={ReduxInput}
                            validate={Validator.allowNumericNPI}
                            fieldName={'NPI'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">
                            National Provider Identifier (NPI) number
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`insuranceAccepted`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Insurance Accepted'}
                            disabled={isDeactivated}
                          />
                          <label class="placeholder-label">Insurance Accepted*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          {/* <Field
                          name="stateOrProvince"
                          type="text"
                          errorMessage="State Required"
                          component={ReduxAutoComplete}
                          options={StateProvince}
                          validate={Validator.required}
                          label={'State*'}
                        /> */}
                          <Field
                            name={`licensedInStates`}
                            type="text"
                            placeholder="Licensed in States"
                            component={ReduxSelect}
                            // validate={Validator.required}
                            options={StateProvince}
                            fieldName={'Licensed in State(s)'}
                            isMulti
                            disabled={isDeactivated}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {getStaffId > 0 && (
              <div class="col-12 text-right">
                <AntdModal
                  modalWidth={800}
                  ModalButton={({ onClick }) => (
                    <a
                      class="reactivate-link mr-2"
                      data-toggle="modal"
                      data-target="#reactivate-staff-model"
                      onClick={onClick}
                    >
                      Roles & Permissions
                    </a>
                  )}
                  ModalContent={props => <RolesPermissions {...props} staffId={getStaffId} />}
                />
                {isDeactivated ? (
                  <>
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <a
                          class="reactivate-link"
                          data-toggle="modal"
                          data-target="#reactivate-staff-model"
                          onClick={onClick}
                        >
                          <i class="fa fa-check" aria-hidden="true" /> Reactivate
                        </a>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to reactivate <br />
                              <strong>{name}</strong> staff?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                                var staffURL = '/manage/staff';
                                var centerId = getCenterId;
                                if (centerId && centerId > 0) {
                                  staffURL = '/manage/staff?centerId=' + centerId;
                                }
                                // if(staffId){
                                //   staffURL='/manage/staff?staffId='+getStaffId
                                // }
                                history.push(staffURL);
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                dispatch(staffReactivate({ staffId: getStaffId }));
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <a
                          class="deactivate-link"
                          data-toggle="modal"
                          data-target="#deactivate-staff-model"
                          onClick={onClick}
                        >
                          <i class="fa fa-ban" aria-hidden="true" /> Deactivate
                        </a>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to Deactivate <br />
                              <strong>{name}</strong> staff?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn black-btn"
                              onClick={() => {
                                onCancel();
                                var staffURL = '/manage/staff';
                                var centerId = getCenterId;
                                if (centerId && centerId > 0) {
                                  staffURL = '/manage/staff?centerId=' + centerId;
                                }
                                // if(staffId){
                                //   staffURL='/manage/staff?staffId='+getStaffId
                                // }
                                history.push(staffURL);
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={() => {
                                onOk();
                                dispatch(staffDelete({ staffId: getStaffId }));
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const { combos } = state;
  if (!combos.data) {
    combos.data = {
      Center: [],
      PayType: [],
      StaffRole: [],
      StateProvince: [],
      Country: [],
      Gender: [],
    };
  }
  return {
    Center: combos.data.Center,
    PayType: combos.data.PayType,
    StaffRole: combos.data.StaffRole,
    StateProvince: combos.data.StateProvince,
    Country: combos.data.Country,
    Gender: combos.data.Gender,
    name: selector(state, 'firstName'),
    isProviderValue: selector(state, 'isProvider'),
  };
};

const ReduxEnrollmentForm = reduxForm({
  form: 'staff-form',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(EnrollmentForm)));

const selector = formValueSelector('staff-form');

const mapStateToPropsParent = state => {
  const {
    staff_get,
    staff_upsert,
    staff_delete,
    default_values,
    app_invite,
    combos,
    staff_reactivate,
  } = state;
  const { data: smsData } = app_invite;
  const { data, error, isFetching } = staff_get;
  const { isFetching: staffUpsertFetching } = staff_upsert;
  let staffData = {};
  var isInserted = false;
  var isRemoved = '';
  var isActivated = '';
  var recordInfo = false;
  var defaultCenter = null;
  var appInviteSMS = '';
  var mobileSuccess = false;
  var mobileAppStatus = '';
  var mobileAppMessage = '';
  var StaffByCenterId = [];
  let countryISO = selector(state, 'country') || 'USA';
  if (data) {
    staffData = data;
    // mobileAppStatus = data.MobileAppStatus;
    // mobileAppMessage = data.MobileAppMessage;
  }
  if (staff_upsert && staff_upsert.data && staff_upsert.data.success) {
    isInserted = true;
  }
  if (staff_delete && staff_delete.data && staff_delete.data.success) {
    isRemoved = staff_delete.data.result;
    recordInfo = staff_delete.data.success;
  }
  if (staff_reactivate && staff_reactivate.data && staff_reactivate.data.success) {
    isActivated = staff_reactivate.data;
  }
  if (default_values && default_values.data) {
    defaultCenter = default_values.data.centerId;
  }
  if (smsData) {
    appInviteSMS = smsData.Success;
  }
  if (smsData && smsData.Success) {
    mobileSuccess = true;
  }
  // if (combos.data && combos.data.StaffByCenterId) {
  //   debugger;
  //   StaffByCenterId = combos.data.StaffByCenterId;
  // }
  return {
    staffData,
    isInserted,
    isRemoved,
    recordInfo,
    defaultCenter,
    appInviteSMS,
    mobileSuccess,
    mobileAppStatus,
    mobileAppMessage,
    isFetching: isFetching || staffUpsertFetching,
    countryISO,
    isActivated,
    // StaffByCenterId,
  };
};

export default connect(mapStateToPropsParent)(Staff_Form);
