import React, { useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import CloseIcon from '../../../Components/CloseIcon';
import renderHTML from 'react-render-html';
import DictationComponent from '../../../Components/DictationComponent';
import {
  appUserJournalAugmentGet,
  gptAugmentationUpdate,
  studentJournalAugmentGet,
  studentJournalAugmentation,
  appUserJournalAugmentation,
  resetGptAugmentationUpdate,
  resetStudentJournalAugmentation,
  resetAppUserJournalAugmentation,
  studentCourseAugmentationGet,
  studentCourseAugmentation,
  resetStudentCourseAugmentation,
  resetStudentCourseAugmentationGet,
} from '../../../store/actions';
import { AILoader } from '../../../Components/Loader';
import SubmitButton from '../../../Components/submitButton';

export default function AugmentWithAIForCourse(props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedNotes, setEditedNotes] = useState(null);
  const dispatch = useDispatch();
  const {
    courseAugObj,
    isCourseFetching,
    isAugmentFetching,
    isRegenerationSuccess,
    isRegenerationFetching,
    isAugmentAgreed,
    isCourseRegenerateFetching,
  } = useSelector(
    ({
      student_course_augmentation_get: { data: studentCourse, isFetching: isStudentCourseFetching },
      student_course_augmentation: {
        data: studentCourseAugment,
        isFetching: isCourseRegenerateFetching,
      },
      gpt_augmentation_update: { data: GPTAugmentation, isFetching },
    }) => {
      return {
        courseAugObj: (studentCourse && studentCourse.Courses && studentCourse.Courses[0]) || {},
        isCourseFetching: isStudentCourseFetching,
        isAugmentFetching: isFetching,
        isCourseRegenerateFetching: isCourseRegenerateFetching,
        isRegenerationSuccess: studentCourseAugment && studentCourseAugment.success,
        isRegenerationFetching: isCourseRegenerateFetching,
        isAugmentAgreed: GPTAugmentation && GPTAugmentation.success,
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    handleAPICalls();
  }, []);

  useEffect(() => {
    if (isAugmentAgreed) {
      props.onHandleDrawer(false);
      handleAPICalls();
      dispatch(resetGptAugmentationUpdate({}));
    }
  }, [isAugmentAgreed]);

  const handleAPICalls = () => {
    dispatch(
      studentCourseAugmentationGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: props.studentId,
            },
          ],
        }),
      }),
    );
  };

  useEffect(() => {
    if (isRegenerationSuccess) {
      handleAPICalls();
      dispatch(resetStudentCourseAugmentation({}));
      // dispatch(resetStudentCourseAugmentationGet({}));
    }
  }, [isRegenerationSuccess]);

  const handleAgree = GPTAugmentationId => {
    dispatch(
      gptAugmentationUpdate({
        Json: JSON.stringify({
          GPTAugmentationId: GPTAugmentationId,
          // StudentId: props.StudentId,
          EditedAnswer: editedNotes,
          IsAgreed: true,
        }),
      }),
    );
  };

  const handleDictate = (result, index) => {
    if (result) {
      let data;
      if (editedNotes) {
        data = `${editedNotes} ${result}`;
      } else {
        data = result;
      }
      setEditedNotes(data);
    }
  };

  const onChangeNote = event => {
    const { value } = event.target;
    setEditedNotes(value);
  };

  const handleRegenerate = () => {
    dispatch(
      studentCourseAugmentation({
        StudentId: props.studentId,
      }),
    );
  };

  const {
    Answer,
    GPTAugmentationId,
    AllowRegenration,
    LastUpdatedOn,
    IsAgreed,
    DateRange,
  } = courseAugObj;
  useEffect(() => {
    setEditedNotes(Answer);
  }, [Answer]);

  useEffect(() => {
    if (!isCourseFetching && Object.keys(courseAugObj).length == 0) {
      handleRegenerate();
    }
  }, [isCourseFetching]);

  return (
    <div className="row ai-course" style={{ position: 'relative' }}>
      <AILoader loading={isCourseFetching || isCourseRegenerateFetching} />
      {Object.keys(courseAugObj).length > 0 ? (
        <div
          className="col-12 d-flex flex-column align-items-end"
          style={{ height: 'fit-content' }}
        >
          {/* <SubmitButton
        className="btn btn-outline-primary mb-3 regenerating-response"
        disabled={!AllowRegenration}
        onClick={handleRegenerate}
        value={'Regenerate Response'}
        // loading={true}
      /> */}
          <Button
            type="button"
            className="btn btn-outline-primary mb-3 augment-regenerate-button justify-contet-center align-items-center"
            style={{ cursor: 'pointer', height: '45px', width: 'fit-content', display: 'flex' }}
            onClick={handleRegenerate}
            disabled={!AllowRegenration}
            loading={isRegenerationFetching}
          >
            <img src="images/regenerate.svg" className="mr-2" />
            Regenerate Response
          </Button>
          {/* <div>
            {IsAgreed === true && (
              <span
                className="ai-agreed"
                style={{ color: '#00DC31', margin: '0 5px', fontSize: '16px', fontWeight: '600' }}
              >
                <i class="fas fa-thumbs-up" style={{ paddingRight: 5 }}></i>
                Agreed
              </span>
            )} */}
          {DateRange && <span style={{ color: 'rgba(151, 151, 151, 0.50)' }}>{DateRange}</span>}
          {/* </div> */}

          {editMode ? (
            <div className="col-12 p-0">
              <span className="editing">Editing Now</span>
              <div class="print-textarea-component">
                <TextareaAutosize
                  style={{ height: '250px', width: '100%' }}
                  value={editedNotes}
                  id="question"
                  name="question"
                  rows="4"
                  placeholder="Enter Answer"
                  className="textarea-type"
                  onChange={event => {
                    onChangeNote(event);
                  }}
                />
                <div
                  className="dictation-space d-flex justify-content-end ai-action p-0"
                  style={{ paddingRight: '10px' }}
                >
                  <DictationComponent
                    handleDictate={event => {
                      handleDictate(event);
                    }}
                    // getDicatationStatus={this.getDicatationStatus}
                  />
                  <button
                    style={{
                      marginLeft: '10px',
                      marginRight: '8px',
                      cursor: 'pointer',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      background: 'transparent',
                      border: '2px solid #ff7875',
                      color: 'rgb(191, 191, 191)',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }}
                    onClick={() => {
                      setEditedNotes('');
                    }}
                  >
                    <img src="images/clear_icon.svg" />
                    <p
                      style={{
                        marginBottom: '0',
                        marginLeft: '7px',
                        color: '#ff7875',
                      }}
                    >
                      Clear
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </button>
                  <SubmitButton
                    type="button"
                    className="btn ml-2"
                    // disabled={this.state[index + 'value'].trim().length < 1}
                    onClick={() => {
                      handleAgree(GPTAugmentationId);
                    }}
                    loading={isAugmentFetching}
                    style={{ height: '42px' }}
                    value={'Save & Agree'}
                  />
                </div>
              </div>
            </div>
          ) : (
            <p className="ai-answer col-12 p-0">{Answer && renderHTML(Answer)}</p>
          )}
          {!editMode && IsAgreed == null && (
            <div className="col-12 ai-action p-0">
              <button
                type="button"
                className="btn d-flex justify-content-center align-items-center mr-2"
                style={{
                  border: '1px solid #00DC31',
                  color: '#00DC31',
                  background: '#fff',
                }}
                onClick={() => handleAgree(GPTAugmentationId)}
              >
                <img
                  src="images/outline_like.svg"
                  style={{
                    marginBottom: '3px',
                    marginRight: '2px',
                  }}
                />
                Agree
              </button>
              <button
                type="button"
                class="btn"
                style={{
                  border: '1px solid #FF2C2C',
                  color: '#FF2C2C',
                  background: '#fff',
                }}
                onClick={() => {
                  setEditMode(true);
                  setEditedNotes(Answer);
                }}
              >
                <i class="fas fa-thumbs-down" style={{ paddingRight: 5 }}></i>
                Disagree
              </button>
            </div>
          )}
        </div>
      ) : (
        <p style={{ width: '100%', textAlign: 'center' }}>No Course(s) Available to Augment</p>
      )}
    </div>
  );
}
