import React from 'react';
import _blank from '../../../../../images/blank-profile.png';
import { Tooltip, Drawer } from 'antd';

export const CareTeamMemberOption = ({ OptionData }) => {
  const { Text, Value, ProfileImageUrl, CareTeamType, RoleDescription, ShortRoleName } = OptionData;
  // let roles = RoleDescription.split(' ');
  return (
    <div className="care-team-member-option">
      <div className="profile mr-2">
        <img src={ProfileImageUrl || _blank} className="pic" height="28" width="28" />
        <div className="info">
          <span className="name">{Text}</span>
          <span className="care-team-type">{CareTeamType}</span>
        </div>
      </div>
      <Tooltip placement="top" title={RoleDescription}>
        <span className="role-description">{ShortRoleName}</span>
      </Tooltip>

      {/* <span className="role-description">{RoleDescription}</span> */}
    </div>
  );
};
