import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Button, Drawer, Tooltip } from 'antd';
import Axios from 'axios';
import Loader, { Loader2 } from '../../../../../Components/Loader';
// import CustomModal2 from '../../../../core/modal';
// import CustomModal from '../core/modal';
import AntdModal from '../../../../../Components/CustomModal';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import validate from '../../../validation/validate';
// import {
//   ReduxInput,
//   ReduxSelect,
//   ReduxToggle,
//   ReduxDateInput,
//   ReduxTextarea,
//   CheckboxGroup,
// } from '../../../../../component/custom-redux-components';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../../util/handler';
import { prescribeUpdate, prescriptionListGet } from '../../../../../store/actions';
import { ProcessCombos } from '../../../../../store/comboConfig';
import PersistTableSort from '../../../../../component/persistTableSort';
import { ReduxSelect } from '../../../../../component/custom-redux-components';
import NewTaskForm from './newTaskForm';
class AssignedTask extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      // SearchStr: null,
      showContentDrawer: false,
      filterId: null,
      initialValues: null,
    };
  }

  getPatientId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var patientId = param.get('pId') || param.get('pId');
    if (patientId) {
      return (patientId = parseInt(patientId));
    } else {
      patientId = -1;
    }
    return patientId;
  }
  componentDidMount() {
    this.setState({ initialValues: { Title: this.props.repeatTaskTitle } });
  }

  render() {
    const { isReadOnlyUser, prescriptionList, isFetching } = this.props;
    return (
      <React.Fragment>
        <div className="container">
          <div
            className="row mt-3 justify-content-between align-items-center"
            style={{ backgroundColor: 'white', padding: '0px 4px' }}
          >
            <div className="col-12">
              <NewTaskForm initialValues={this.state.initialValues} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// prescription_list_get

const mapStateToProps = ({ prescription_list_get }) => {
  var { data, isFetching, error } = prescription_list_get;

  let prescriptionList;
  if (prescription_list_get && prescription_list_get.data) {
    prescriptionList = prescription_list_get.data.Prescription;
  }

  return {
    prescriptionList: prescriptionList || [],
    isFetching,
  };
};

export default withRouter(connect(mapStateToProps)(AssignedTask));
