import React, { Component } from 'react';
import { numberFormat } from '../../../util/formatter';

export default class Contracts extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      TotalActiveContracts,
      TotalCompletedContracts,
      TotalContracts,
      TotalTerminatedContracts,
    } = this.props.contracts || {};
    return (
      <div className="col-12 mb-3">
        <div className="row">
          <div className="col-3">
            <div className={'contract-card'}>
              <div className="status">
                <span className="text">Total Contracts</span>
                <span className="value">{numberFormat.format(parseInt(TotalContracts || 0))}</span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className={'contract-card'}>
              <div className="status">
                <span className="text">Active Contracts</span>
                <span className="value">
                  {numberFormat.format(parseInt(TotalActiveContracts || 0))}
                </span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className={'contract-card'}>
              <div className="status">
                <span className="text">Completed Contracts</span>
                <span className="value">
                  {numberFormat.format(parseInt(TotalCompletedContracts || 0))}
                </span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className={'contract-card'}>
              <div className="status">
                <span className="text">Terminated Contracts</span>
                <span className="value">
                  {numberFormat.format(parseInt(TotalTerminatedContracts || 0))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
