import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader, { Loader2 } from '../../../../Components/Loader';
import CustomModal2 from '../../../../core/modal';
import { Upload, message, Modal, Popconfirm, Icon, Tooltip } from 'antd';
import Autocomplete from 'react-autocomplete';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
// import validate, {
//   dateofbirthNormalize,
//   ssnFormatter,
//   ssnNormalize,
//   MobileFormatter,
//   MobileNormalize,
// } from '../../validation/validate';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxCheckbox,
} from '../../../../component/custom-redux-components';
import { Table, Checkbox, Radio } from 'antd';
import { isComparer, isNumericComparer, isDateComparer } from '../../../../util/handler';
import {
  listCombos,
  medicateCombos,
  practitionerCombosOffice,
  upsertGroup,
  listGroupMembership,
  resetAddCallSession,
  deleteGroup,
  getPatientList,
  getClientGroup,
  upsertClientGroup,
  getListClientGroup,
} from '../../../../store/actions';
import PersistTableSort from '../../../../component/persistTableSort';

import AntdModal from '../../../../Components/CustomModal';
const Validator = {
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
};
// class ActivityBase extends React.Component {}

class PatientGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editGroupData: {},
      createAndEditGroupModal: false,
      manageGroupsModal: false,
      columns: [
        {
          dataIndex: 'IsGroupMember',
          key: 'IsGroupMember',
          render: (text, record, index) => {
            return (
              <div class="actions-button d-flex">
                <div className="actions-button">
                  {!props.isReadOnlyUser && (
                    <AntdModal
                      ModalButton={({ onClick }) => (
                        <Tooltip placement="top">
                          {/* <span class="delete-course" onClick={onClick}>
                          <i class="fas fa-times" />
                        </span> */}
                          <Checkbox
                            onClick={onClick}
                            // onChange={e => {
                            //   this.onChange(record, index, e);
                            // }}
                            name={record.GroupId}
                            checked={text}
                          />
                        </Tooltip>
                      )}
                      ModalContent={({ onOk, onCancel }) => (
                        <div class="modal-content border-0">
                          <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                          <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                            <h4 class="modal-title col-12 mb-3">
                              Are you sure you want to {record.IsGroupMember ? 'remove' : 'add'}{' '}
                              this group member?
                            </h4>
                          </div>
                          <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                            <button
                              class="btn btn-outline-primary black-btn"
                              onClick={() => {
                                onCancel();
                              }}
                            >
                              No
                            </button>
                            <button
                              class="btn"
                              onClick={e => {
                                onOk();
                                this.onChange(record, e);
                                // this.deleteGroup(record.GroupId);
                                // this.props.onCancel();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            );
          },
        },
        {
          title: 'Group Name',
          dataIndex: 'GroupName',
          key: 'GroupName',
          sorter: (a, b) => isComparer(a.GroupName, b.GroupName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Patients Counts',
          dataIndex: 'ClientCount',
          key: 'ClientCount',
          sorter: (a, b) => isNumericComparer(a.ClientCount, b.ClientCount),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Counselor',
          dataIndex: 'PractitionerName',
          key: 'PractitionerName',
          sorter: (a, b) => isComparer(a.PractitionerName, b.PractitionerName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Service',
          dataIndex: 'ServiceCodeText',
          key: 'ServiceCodeText',
          sorter: (a, b) => isComparer(a.ServiceCodeText, b.ServiceCodeText),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Office Location',
          dataIndex: 'FacilityName',
          key: 'FacilityName',
          sorter: (a, b) => isComparer(a.FacilityName, b.FacilityName),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Group Status',
          dataIndex: 'GroupStatus',
          key: 'GroupStatus',
          sorter: (a, b) => isComparer(a.GroupStatus, b.GroupStatus),
          render: (text, record, index) => (
            <>
              <span>{text}</span>
            </>
          ),
        },
      ],
      ListGroup: [],
    };
  }

  onChange = (record, e) => {
    // console.log(`checked = ${e.target.checked}`);
    let result = this.state.ListGroup;
    let filteredIndex = null;
    result.filter((item, index) => {
      if (item.GroupId == record.GroupId) {
        filteredIndex = index;
      }
    });
    result[filteredIndex].IsGroupMember = !record.IsGroupMember;
    // let keys = Object.keys(result);
    // let filtered = keys.filter(key => {
    //   return result[key];
    // });
    this.setState({
      ListGroup: result,
    });
    if (record.IsGroupMember) {
      this.props.dispatch(
        upsertClientGroup({
          json: JSON.stringify({
            ClientGroup: [
              {
                ClientGroupId: null,
                GroupId: record.GroupId,
                ClientId: this.props.clientId,
                IsAdd: true,
              },
            ],
          }),
        }),
      );
    } else {
      this.props.dispatch(
        upsertClientGroup({
          json: JSON.stringify({
            ClientGroup: [
              {
                ClientGroupId: record.ClientGroupId,
                GroupId: record.GroupId,
                ClientId: this.props.clientId,
                IsAdd: false,
              },
            ],
          }),
        }),
      );
    }
    if (e.target.checked == false) {
      // this.props.dispatch(
      //   upsertClientGroup({
      //     json: JSON.stringify({
      //       ClientGroup: [
      //         {
      //           ClientGroupId: record.ClientGroupId,
      //           GroupId: record.GroupId,
      //           ClientId: this.props.clientId,
      //           IsAdd: false,
      //         },
      //       ],
      //     }),
      //   }),
      // );
    }
  };

  componentDidMount() {
    let medicateComboTypes = {
      ComboTypes: 'ServiceCode',
    };
    this.props.dispatch(medicateCombos(medicateComboTypes));
    // this.props.dispatch(listGroupMembership({}));
    this.props.dispatch(
      listGroupMembership({
        json: JSON.stringify({
          Client: [
            {
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  }

  componentWillReceiveProps({ isGroupCreated, ListGroup, isSuccess }) {
    if (
      (isGroupCreated && isGroupCreated != this.props.isGroupCreated) ||
      (isSuccess && isSuccess != this.props.isSuccess)
    ) {
      // this.props.dispatch(listGroupMembership({}));
      this.props.dispatch(
        listGroupMembership({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.props.clientId,
              },
            ],
          }),
        }),
      );
      this.setState({
        inviteMsg: 'Group created successfully.',
        isModelOpen: true,
      });
    }
    if (ListGroup && JSON.stringify(ListGroup) != JSON.stringify(this.props.ListGroup)) {
      this.setState({
        ListGroup,
      });
    }
  }
  resetEditGroup = () => {
    this.setState({ editGroupData: {} });
  };

  onSubmit = values => {
    const { groupId } = this.state.editGroupData;
    var data = {
      Group: [
        {
          GroupId: groupId || null,
          GroupName: values.groupName,
          ServiceCodeId: values.serviceCode,
          CounsellorId: values.counselor,
          PractitionerOfficeId: values.officelocation,
        },
      ],
    };
    var GroupData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertGroup(GroupData));
    this.setState({ createAndEditGroupModal: false, resetEditGroup: {} });
  };

  deleteGroup = groupId => {
    this.props.dispatch(
      deleteGroup({
        json: JSON.stringify({
          Group: [
            {
              GroupId: groupId,
            },
          ],
        }),
      }),
    );
    this.props.dispatch(listGroupMembership({}));
    // let editRecord = {
    //   groupId: record.GroupId,
    //   groupName: record.GroupName,
    //   serviceCode: parseInt(record.ServiceCodeId),
    //   counselor: record.CounsellorId,
    //   officelocation: JSON.stringify(record.PractitionerOfficeId),
    // };
    // this.setState({ editGroupData: editRecord });
  };

  editGroup = record => {
    this.props.dispatch(
      practitionerCombosOffice({
        json: JSON.stringify({
          PractitionerOffice: [
            {
              PractitionerId: record.CounsellorId,
            },
          ],
        }),
      }),
    );

    let editRecord = {
      groupId: record.GroupId,
      groupName: record.GroupName,
      serviceCode: parseInt(record.ServiceCodeId),
      counselor: record.CounsellorId,
      officelocation: JSON.stringify(record.PractitionerOfficeId),
    };
    this.setState({ editGroupData: editRecord });
  };

  onGroupAssign = values => {
    const { groupId } = this.state.editGroupData;
    var data = {
      Group: [
        {
          GroupId: groupId || null,
          GroupName: values.groupName,
          ServiceCodeId: values.serviceCode,
          CounsellorId: values.counselor,
          PractitionerOfficeId: values.officelocation,
        },
      ],
    };
    var GroupData = {
      json: JSON.stringify(data),
    };

    this.props.dispatch(upsertGroup(GroupData));
    this.setState({ createAndEditGroupModal: false, resetEditGroup: {} });
  };
  onSearchChange = e => {
    let { value } = e.target;
    this.setState({
      searchStr: value,
    });
    this.props.dispatch(
      listGroupMembership({
        json: JSON.stringify({
          Client: [
            {
              Search: value,
              ClientId: this.props.clientId,
            },
          ],
        }),
      }),
    );
  };
  render() {
    const { columns } = this.state;
    const { isFetching } = this.props;
    return (
      <React.Fragment>
        <div className="main medicalAnalysis-page mb-5">
          {/* <ReduxPatientGroups
            {...this.props}
            onSubmit={this.onSubmit}
            dispatch={this.props.dispatch}
            history={this.props.history}
            medicateCombos={this.props.medicateCombosObj}
            initialValues={this.state.editGroupData}
            groupId={this.state.editGroupData.groupId}
            resetEditGroup={this.resetEditGroup}
          /> */}

          <div class="container-fluid">
            <div class="row">
              <div class="col-12 mb-5 px-0">
                <div class="border-0">
                  <div className="">
                    <div className="row mt-5 d-flex align-items-center">
                      <div class="col-6 px-0">
                        {' '}
                        <h4 className="">Participating in these groups</h4>
                      </div>
                      <div class="col-6 d-flex align-items-center">
                        <div class="search-box d-inline-flex w-100">
                          <div class="form-group has-search w-100 d-flex align-items-center">
                            <input
                              type="text"
                              class="form-control 
                   shadow-sm rcm-document-search"
                              placeholder="Search"
                              id="filterbox"
                              onChange={this.onSearchChange}
                            />
                            <button class="btn search-icon " type="button">
                              {' '}
                              <i class="fa fa-search" />{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Loader2 loading={isFetching} /> */}
                  <div class="row pt-5 pb-5 rcm-subtab-table-block">
                    <div class="col-12">
                      <Loader2 loading={isFetching} />
                      <PersistTableSort
                        name="grp-ListGroup"
                        columns={columns}
                        dataSource={this.state.ListGroup}
                        size={'middle'}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: event => this.onRowClick(record.ClientId),
                        //   };
                        // }}
                        pagination={{
                          itemRender: (page, type, originalElement) => {
                            return (
                              <div class="dataTables_wrapper no-footer">
                                <div class="dataTables_paginate paging_simple_numbers">
                                  {type == 'prev' ? (
                                    <a class="paginate_button previous">Previous</a>
                                  ) : type == 'next' ? (
                                    <a class="paginate_button next">Next</a>
                                  ) : (
                                    <a class="paginate_button ">{page}</a>
                                  )}
                                </div>
                              </div>
                            );
                          },
                          showSizeChanger: true,
                          // hideOnSinglePage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// let ManageGroupsForm = () => {
//   const { ServiceCode } = medicateCombos;
//   return (
//   );
// };

const mapStateToProps = ({
  medicate_combos,
  practitioner_combos,
  practitioner_combos_office,
  list_group_membership,
  upsert_group,
  get_client_group,
  upsert_client_group,
  get_list_client_group,
}) => {
  const { data: medicateCombos } = medicate_combos;
  const { data: practitionerData } = practitioner_combos;
  const { data: practitionerOfficeData } = practitioner_combos_office;
  const { data: listgroup, isFetching } = list_group_membership;
  const { data: getClientListData, isFetching: isFetchingGroupClient } = get_client_group;
  const {
    data: getListClientGroup,
    isFetching: isFetchingGetListClienGroup,
  } = get_list_client_group;

  let Client = [];
  let GroupClientList = [];
  let isSuccess = false;
  let medicateCombosObj = {
    ServiceCode: [],
  };
  let Practitioner = [];
  let PractitionerOffice = [];
  let ListGroup = [];
  let isGroupCreated = false;
  let searchedClient = [];

  if (getClientListData) {
    GroupClientList = getClientListData.ClientGroup;
  }
  if (practitionerData) {
    Practitioner = practitionerData.PractitionerList;
  }
  if (practitionerOfficeData) {
    PractitionerOffice = practitionerOfficeData.PractitionerOfficeList || [];
  }
  if (medicateCombos) {
    medicateCombosObj = {
      ServiceCode: medicateCombos.ServiceCode,
    };
  }

  if (listgroup && listgroup.Group) {
    ListGroup = listgroup.Group;
  }
  if (upsert_group && upsert_group.data && upsert_group.data.success) {
    isGroupCreated = upsert_group.data.success;
  }

  if (upsert_client_group.data && upsert_client_group.data.success) {
    isSuccess = upsert_client_group.data.success;
  }
  if (getListClientGroup) {
    searchedClient = getListClientGroup.Client || [];
  }
  return {
    medicateCombosObj: medicateCombosObj,
    Practitioner: Practitioner,
    PractitionerOffice: PractitionerOffice,
    ListGroup,
    isGroupCreated,
    isFetching,
    isFetchingGroupClient,
    isFetchingGetListClienGroup,
    GroupClientList,
    isSuccess,
    searchedClient,
  };
};
// const ReduxPatientGroups = reduxForm({
//   form: 'patientGroupsForm',
//   enableReinitialize: true,
// })(connect(mapStateToProps)(withRouter(PatientGroupsForm)));

// const ReduxManageGroups = reduxForm({
//   form: 'manageGroupsForm',
//   enableReinitialize: true,
// })(connect(mapStateToProps)(withRouter(ManageGroupsForm)));

export default connect(mapStateToProps)(withRouter(PatientGroups));
