import React, { Component } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { Loader2 } from '../../../Components/Loader';
// import { Table } from 'antd'
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import DynamicGrid from '../../../Components/DynamicGrid';
import PatientRowRender from './patientRowRender';
import PageTitle from '../../../Components/PageTitle';

class PayerContracts extends Component {
  getPopOverData = record => {
    return (
      <div className="sort-by-list">
        {/* <p
          onClick={() => {
            this.props.history.push(
              `/pre-intake-form?step=demographics&studentid=${record.StudentId}`,
            );
          }}
        >
          Edit Details
        </p> */}
        <p
          onClick={() => {
            this.props.history.push(
              `/clients/pre-intake/dashboard?tab=summary&id=${record.StudentId}`,
            );
          }}
        >
          View Details
        </p>
        <p
          onClick={() => {
            this.setState({
              isNoteAddModal: true,
              StudentId: record.StudentId,
            });
          }}
        >
          Request More Information
        </p>
      </div>
    );
  };
  render() {
    return (
      <div class="container-fluid">
        <PageTitle TitleText="Contracts" />
        <div class="content-area">
          <div class="row top-table-search mt-4 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h3 class="page-title mb-3 mb-md-0 page-title-absolute">Contracts</h3>
            </div>
          </div>
          <div />
          <div class="row payer-grid">
            <div class="col-12 mb-5">
              <div class="card border-0 " style={{ backgroundColor: 'unset', boxShadow: 'none' }}>
                <div className="dynamic-grid">
                  <DynamicGrid
                    ScreenName={'PayerPortalSmartContract'}
                    getPopOverData={this.getPopOverData}
                    IsExpandable={true}
                    ExpandedRowRender={PatientRowRender}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PayerContracts;
