import React, { Component } from 'react';

const ExpandIcon = ({ expanded, onExpand, record }) =>
  expanded ? (
    <img
      src="images/icons/expand_grid.svg"
      onClick={e => {
        onExpand(record, e);
        e.stopPropagation();
      }}
    />
  ) : (
    <img
      src="images/icons/collapse_grid.svg"
      onClick={e => {
        onExpand(record, e);
        e.stopPropagation();
      }}
    />
  );

export default ExpandIcon;
