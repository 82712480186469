import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocViewerComponent from '../Components/docViewerComponent';
import { downloadStudentDocument, downloadClientDocument } from '../store/actions';
const Docview = props => {
  // getStudentId = () => {
  //   var param = new URLSearchParams(props.history.location.search);
  //   return param.get('student-id');
  // };
  // sguid = () => {
  //   var param = new URLSearchParams(props.history.location.search);
  //   return param.get('sguid');
  // };
  // docType = () => {
  //   var param = new URLSearchParams(props.history.location.search);
  //   return param.get('type');
  // };

  const { onOk, onCancel, dispatch, DocumentExtension, isFetching, isClinical } = props;
  return (
    <div className="modal-content border-0">
      <div className="modal-header flex-wrap border-bottom-1">
        <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
          ×
        </button>
        <h4
          className="modal-title col-12 "
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <span className="text-left">View Mode</span>
          <span
            className="text-right"
            onClick={() => {
              props.reset();
              if (isClinical && !props.isReadOnlyUser) {
                props.dispatch(
                  downloadClientDocument({
                    json: JSON.stringify({
                      ClientDocument: [
                        {
                          ClientId: props.ClientId,
                          DocumentGuid: props.ClientDocumentGuid,
                        },
                      ],
                    }),
                    IsDownloadClicked: true,
                  }),
                );
              } else {
                props.dispatch(
                  downloadStudentDocument({
                    json: JSON.stringify({
                      StudentDocument: [
                        {
                          StudentId: props.StudentId,
                          StudentDocumentGuid: props.StudentDocumentGuid,
                        },
                      ],
                    }),
                    IsDownloadClicked: true,
                  }),
                );
              }
            }}
            style={{ color: '#46B4FE', fontSize: '14px', cursor: 'pointer' }}
            download
          >
            {!props.isReadOnlyUser && (
              <span>
                <img src="images/icons/download-document.svg" /> Download
              </span>
            )}
          </span>
        </h4>
      </div>
      <div className="modal-body">
        <div className=" m-0 view-document-modal">
          <div className="">
            <DocViewerComponent file={props.downloadUrl} type={DocumentExtension} />
          </div>
        </div>
      </div>
      <div className="modal-footer border-top-0 pb-4 pt-2">
        <button
          onClick={() => {
            onCancel();
          }}
          type="button"
          className="btn"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ download_student_document, download_client_document }) => {
  const {
    data: DownloadData,
    isFetching: isFetchingDownload,
    error: errorDownload,
  } = download_student_document;
  const {
    data: ClientData,
    isFetching: isFetchingClient,
    error: errorClient,
  } = download_client_document;
  let downloadUrl;
  let documentError;

  if (DownloadData && DownloadData.success) {
    downloadUrl = DownloadData.result;
  }
  if (ClientData && ClientData.success) {
    downloadUrl = ClientData.result;
  } else if (errorClient) {
    documentError = errorClient;
  }
  return {
    downloadUrl,
    documentError,
  };
};

export default connect(mapStateToProps)(Docview);
