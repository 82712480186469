import { all, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import API from '../requests';

export function* getReportData(action) {
  try {
    const response = yield API.getReportData();
    yield put({
      type: ActionTypes.REPORT_GET_REPORT_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.REPORT_GET_REPORT_DATA_FAILURE,
    });
  }
}
export function* getCounselor(action) {
  try {
    const { payload } = action;
    const response = yield API.getCounselor(payload);
    yield put({
      type: ActionTypes.GET_COUNSELOR_SUCCESS,
      payload: JSON.parse(response.data.data),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.GET_COUNSELOR_FAILURE,
    });
  }
}
export function* clientStatusReport(action) {
  try {
    const { payload } = action;
    const response = yield API.clientStatusReport(payload);
    yield put({
      type: ActionTypes.CLIENT_STATUS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.CLIENT_STATUS_REPORT_FAILURE,
    });
  }
}
export function* userTriggerReport(action) {
  try {
    const { payload } = action;
    const response = yield API.userTriggerReport(payload);
    yield put({
      type: ActionTypes.USER_TRIGGER_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERRORS_REQUEST,
      successAction: action,
      error: error,
      failedAction: ActionTypes.USER_TRIGGER_REPORT_FAILURE,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_REPORT_DATA_REQUEST, getReportData),
    takeLatest(ActionTypes.GET_COUNSELOR_REQUEST, getCounselor),
    takeLatest(ActionTypes.CLIENT_STATUS_REPORT_REQUEST, clientStatusReport),
    takeLatest(ActionTypes.USER_TRIGGER_REPORT_REQUEST, userTriggerReport),
  ]);
}
