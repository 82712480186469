import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import CustomModal from '../../../core/modal';
import Loader, { Loader2 } from '../../../Components/Loader';
import { isComparer, isDateComparer } from '../../../util/handler';
import { stringDate } from '../../../util/date';
import AntdModal from '../../../Components/CustomModal';
import {
  addNoteType,
  resetAddNoteType,
  listCombos,
  adminstrationCourseUpsert,
  resetAdminstrationCourseUpsert,
  deleteNoteType,
  getNoteTypeArchive,
  getNoteTypeList,
  getPredefinedTask,
  getPredefinedTaskArchive,
  deletePredefinedTask,
  upsertPredefinedTask,
  resetPredefinedTask,
} from '../../../store/actions';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../../component/custom-redux-components';
import SubmitButton from '../../../Components/submitButton';
import PageTitle from '../../../Components/PageTitle';
class PredefinedTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      predefinedTask: '',
      isModelOpen: false,
      header: '',
      filterBy: 1,
      deleteAction: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(getPredefinedTask({}));
    this.props.dispatch(getPredefinedTaskArchive({}));
  }

  componentWillReceiveProps({ isAddedPredefineTask, isDeletePredefineTask }) {
    if (isAddedPredefineTask && isAddedPredefineTask != this.props.isAddedPredefineTask) {
      this.toggle('Predefined Task Added Successfully');
      this.props.dispatch(getPredefinedTask({}));
      this.props.dispatch(resetPredefinedTask({}));
      this.setState({
        predefinedTask: '',
        predefinedTaskDetail: '',
      });
    }
    if (isDeletePredefineTask && isDeletePredefineTask != this.props.isDeletePredefineTask) {
      this.toggle(`Predefined Task ${this.state.deleteAction} Successfully`);
      this.props.dispatch(getPredefinedTaskArchive({}));
      this.props.dispatch(getPredefinedTask({}));
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };
  render() {
    const PredefinedTaskColumns = [
      {
        title: 'Predefined Task',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Predefined Task Detail',
        dataIndex: 'Detail',
        key: 'Detail',
        sorter: (a, b) => isComparer(a.Detail, b.Detail),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        // sorter: (a, b) => isComparer(a.Title, b.Title),
        // sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <Tooltip placement="top" title="Archive">
                      <img src="images/archive.svg" width="25" />
                    </Tooltip>

                    {/* <i class="far fa-trash-alt"></i> */}
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to archive this predefined task?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            deletePredefinedTask({
                              JsonInput: JSON.stringify({
                                Task: [
                                  {
                                    TaskId: record.Value,
                                    IsArchived: 1,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'archived';
                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    const ArchhivedColumns = [
      {
        title: 'Predefined Task Archived',
        dataIndex: 'Text',
        key: 'Text',
        sorter: (a, b) => isComparer(a.Text, b.Text),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        key: 'x',
        // sorter: (a, b) => isComparer(a.Title, b.Title),
        // sortDirections: ['descend', 'ascend'],
        render: (text, record, index) => (
          <div class="tools-grid">
            <div className="">
              <AntdModal
                ModalButton={({ onClick }) => (
                  <span
                    class="delete-btn"
                    onClick={onClick}
                    data-toggle="modal"
                    data-target="#delete-Screen-Tool"
                    style={{ cursor: 'pointer' }}
                  >
                    <img src="images/restore_icon.svg" width="85" />
                    {/* <i class="far fa-trash-alt"></i> */}
                  </span>
                )}
                ModalContent={({ onOk, onCancel }) => (
                  <div class="modal-content border-0">
                    <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                    <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                      <h4 class="modal-title col-12 mb-3">
                        Are you sure you want to restore this predefined task?
                      </h4>
                    </div>
                    <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                      <button
                        class="btn btn-outline-primary black-btn"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        No
                      </button>
                      <button
                        class="btn"
                        onClick={() => {
                          this.props.dispatch(
                            deletePredefinedTask({
                              JsonInput: JSON.stringify({
                                Task: [
                                  {
                                    TaskId: record.Value,
                                    IsArchived: 0,
                                  },
                                ],
                              }),
                            }),
                          );
                          this.state.deleteAction = 'restored';

                          onOk();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        ),
      },
    ];
    const { predefinedTask, predefinedTaskDetail, filterBy } = this.state;
    const { predefinedTaskArchive, PredefinedTaskList, isTaskUpsert } = this.props;

    return (
      <div>
        <PageTitle TitleText="Miscellaneous" />
        <div class="content-area  setting-gap pl-0">
          <div class="mb-3">
            <h6>Predefined Task</h6>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="enrollment-form">
                <div class="form-group">
                  <input
                    className={`form-control ${predefinedTask && 'not-empty'}`}
                    value={predefinedTask}
                    onChange={e => this.setState({ predefinedTask: e.target.value })}
                  />
                  <label className="placeholder-label">Predefined Task</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="enrollment-form">
                <div class="form-group">
                  <input
                    className={`form-control ${predefinedTaskDetail && 'not-empty'}`}
                    value={predefinedTaskDetail}
                    onChange={e => this.setState({ predefinedTaskDetail: e.target.value })}
                  />
                  <label className="placeholder-label">Predefined Task Detail</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              {/* <button
                type="button"
                class="btn px-5"
                disabled={!predefinedTask}
                onClick={() => {
                  // {"Task":[{"TaskId":null,"Title":"Drug Test1","Detail":"Substance abuse drug test"}]}
                  let jsonData = {
                    Task: [
                      {
                        TaskId: null,
                        Title: predefinedTask,
                        Detail: predefinedTaskDetail,
                      },
                    ],
                  };
                  this.props.dispatch(
                    upsertPredefinedTask({
                      JsonInput: JSON.stringify(jsonData),
                    }),
                  );
                }}
              >
                Add
              </button> */}

              <SubmitButton
                loading={isTaskUpsert}
                onClick={() => {
                  // {"Task":[{"TaskId":null,"Title":"Drug Test1","Detail":"Substance abuse drug test"}]}
                  let jsonData = {
                    Task: [
                      {
                        TaskId: null,
                        Title: predefinedTask.trim(),
                        Detail: predefinedTaskDetail.trim(),
                      },
                    ],
                  };
                  this.props.dispatch(
                    upsertPredefinedTask({
                      JsonInput: JSON.stringify(jsonData),
                    }),
                  );
                }}
                disabled={!predefinedTask.trim()}
                value={'Add'}
                style={{ height: '45px', padding: '0 45px' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <div className="text-left app-subscriber-page d-inline-block">
              <div class="form-group mr-3 mb-0" style={{ width: '230px' }}>
                <ReduxSelect
                  options={[
                    { Text: 'Active', Value: 1 },
                    { Text: 'Archived', Value: 2 },
                  ]}
                  onChange={val => {
                    this.setState({
                      filterBy: val,
                    });
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  value={filterBy}
                  placeholder="Filter By"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        {filterBy === 1 ? (
          <div class="row">
            <div class="col-12 mb-5">
              <div class="card border-0 ">
                <div class="row card-body">
                  <div class="col-12">
                    {/* <Loader2 loading={isFetching} /> */}
                    <Table
                      columns={PredefinedTaskColumns}
                      dataSource={PredefinedTaskList}
                      //   onRow={(record, rowIndex) => {
                      //     return {
                      //       onClick: event => this.onRowClick(record.AnnouncementId),
                      //     };
                      //   }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col-12 mb-5">
              <div class=" border-0 mt-5 mx-2 my-2">
                <div class="row">
                  <div class="col-12">
                    {/* <Loader2 loading={isFetching} /> */}
                    <Table
                      columns={ArchhivedColumns}
                      dataSource={predefinedTaskArchive || []}
                      //   onRow={(record, rowIndex) => {
                      //     return {
                      //       onClick: event => this.onRowClick(record.AnnouncementId),
                      //     };
                      //   }}
                      pagination={{
                        itemRender: (page, type, originalElement) => {
                          return (
                            <div class="dataTables_wrapper no-footer">
                              <div class="dataTables_paginate paging_simple_numbers">
                                {type == 'prev' ? (
                                  <a class="paginate_button previous">Previous</a>
                                ) : type == 'next' ? (
                                  <a class="paginate_button next">Next</a>
                                ) : (
                                  <a class="paginate_button ">{page}</a>
                                )}
                              </div>
                            </div>
                          );
                        },
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
          }}
          yesText="OK"
          hideCancel={true}
        />
      </div>
    );
  }
}
const mapStateToPropsPredefinedTask = state => {
  const {
    get_predefined_task,
    upsert_predefined_task,
    delete_predefined_task,
    get_predefined_task_archive,
  } = state;
  const { data, isFetching } = get_predefined_task;
  const { data: upsertPredefinedTask, isFetching: isTaskUpsert } = upsert_predefined_task;
  const { data: deletePredefinedTask } = delete_predefined_task;
  const { data: archivedPredefinedTask } = get_predefined_task_archive;

  let predefinedTaskList = [];
  let archivedData = [];
  let isAddedPredefineTask = false;
  let isDeletePredefineTask = false;
  if (archivedPredefinedTask && archivedPredefinedTask.data) {
    archivedData = archivedPredefinedTask.data;
  }
  if (data && data.data) {
    predefinedTaskList = data.data;
  }
  if (upsertPredefinedTask && upsertPredefinedTask.success) {
    isAddedPredefineTask = upsertPredefinedTask.success;
  }
  if (deletePredefinedTask && deletePredefinedTask.success) {
    isDeletePredefineTask = deletePredefinedTask.success;
  }
  return {
    PredefinedTaskList: predefinedTaskList || [],
    isFetching,
    isAddedPredefineTask,
    isDeletePredefineTask,
    predefinedTaskArchive: archivedData || [],
    isTaskUpsert,
  };
};
const ReduxPredefinedTask = connect(mapStateToPropsPredefinedTask)(withRouter(PredefinedTask));

export default connect()(ReduxPredefinedTask);
