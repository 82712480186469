import React, { Component } from 'react';
import { ReduxToggle, ReduxSelect, ReduxInput } from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import validate, {
  dateofbirthNormalize,
  normalizeToYYYYMMDD,
  MobileFormatter,
  MobileNormalize,
} from './enrollmentForm/validation/validate';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  adminstrationGetStaff,
  adminstrationStaffUpsert,
  resetAdminstrationStaffUpsert,
  appInvite,
  listCombos,
  resetLogin,
  logOut,
} from '../store/actions';
import { stringDate } from '../util/date';
import { Upsert_Sucess_Msg } from '../app-config';
import { Modal, Button } from 'antd';
import Loader from '../Components/Loader';
import { ProcessCombos } from '../store/comboConfig';
import PageTitle from '../Components/PageTitle';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';
class ActivityBase extends React.Component {}

const Validator = {
  isValidMobile: value => {
    if (value && value.length > 2) {
      let newValue = value.includes('+') ? value : `+${value}`;
      let CallingCode = newValue && parsePhoneNumber(newValue);
      let nationalNumber = CallingCode && CallingCode.nationalNumber;
      if (nationalNumber) {
        let isValid = CallingCode && isValidPhoneNumber(newValue, CallingCode.country);
        let isValidLength = CallingCode && validatePhoneNumberLength(newValue, CallingCode.country);
        if (isValidLength) {
          return isValidLength.toLowerCase().replace('_', ' ');
        } else if (!isValid) {
          return 'Invalid Phone';
        } else return undefined;
      } else return undefined;
    } else {
      return undefined;
    }
  },
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  CenterRequired: value => (value ? undefined : ' from the dropdown options.'),
  dobStaff: value => {
    if (value && value.length == 10) {
      let dobArray = value && value.split('/');
      if (
        dobArray[0] < 1 ||
        dobArray[0] > 12 ||
        dobArray[1] < 1 ||
        dobArray[1] > 31 ||
        dobArray[2].length != 4 ||
        dobArray[2] < 1900
        // dobArray[0].length != 4 ||
        // dobArray[0] < 1900 ||
        // dobArray[1] < 1 ||
        // dobArray[1] > 12 ||
        // dobArray[2] < 1 ||
        // dobArray[2] > 31
      ) {
        return 'Format(MM/DD/YYYY)';
      }
    } else {
      return 'Format(MM/DD/YYYY)';
    }
  },
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  // allowNumericMobile: value =>
  //   value && !/^[\d\(\)\-\s]+$/.test(value) ? 'Please enter numeric values only' : undefined,

  allowNumericMobile: value => {
    if (value) {
      const numWithoutChar = value.replace(/[^0-9\-\(\)\s)]+/g, '');
      if (value == numWithoutChar) {
        // const onlyNums = value.replace(/^[\d\(\)\-\s]+$/g, '');
        const onlyNums = numWithoutChar.replace(/\D/g, '');
        if (onlyNums.length != 10) {
          return 'Please enter 10 digits';
        }
      } else {
        return 'numeric values only';
      }
    }
  },
  allowNumericNPI: value =>
    value && !/^[0-9--]{10}$/.test(value) ? ' 10 digit numeric values only' : undefined,
};

class ExternalStaff_Form extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      staffData: {
        mobileAppRole: '23',
      },
    };
  }

  success = () => {
    Modal.success({
      content: 'Mobile app referral successfully sent',
    });
  };

  componentDidMount() {
    var result = ProcessCombos(
      'Center,PayType,StaffRole,StateProvince,Country,Gender,MobileAppRole,ExternalProviderSpecialtyType',
    );
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    this.props.dispatch(
      listCombos({
        comboTypes: 'StateProvince',
        CountryISO: 'USA',
      }),
    );
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ staffData, isInserted, defaultCenter, appInviteSMS, userId }) {
    if (userId && userId != this.props.userId) {
      this.props.dispatch(adminstrationGetStaff({ StaffId: userId }));
    }
    if (appInviteSMS && appInviteSMS != this.props.appInviteSMS) {
      this.success();
    }

    if (isInserted) {
      let msg = `<h4>Your onboarding process has been completed successfully.</br><small> Kindly proceed to re-login to access to the patient information.</small></h4>`;
      this.toggle(msg);
      this.props.dispatch(resetAdminstrationStaffUpsert());
    }

    if (staffData && staffData.StaffId && this.props.userId > 0) {
      let dobArray = (staffData.DateofBirth && staffData.DateofBirth.split('-')) || null;
      let dobString = dobArray && dobArray[1] + '/' + dobArray[2] + '/' + dobArray[0];
      let isMisc = staffData.Misc;
      const data = {
        firstName: staffData.FirstName,
        middleName: staffData.MiddleName,
        lastName: staffData.LastName,
        gender: staffData.Gender,
        line1: staffData.AddressLine1,
        line2: staffData.AddressLine2,
        city: staffData.City,
        stateOrProvince: staffData.StateOrProvince,
        zip: staffData.ZipCode,
        dateofBirth: dobString || '',
        phoneMobile: staffData.PhoneMobile,
        phoneHome: staffData.PhoneHome,
        emailWork: staffData.EmailWork,
        emailHome: staffData.EmailHome,
        //designation: staffData,
        // StaffRoleId: staffData.StaffRoleId,
        mobileAppRole: '23',
        isProvider: staffData.IsProvider,
        screenName: isMisc && staffData.Misc[0].ScreenName,
        title: isMisc && staffData.Misc[0].Title,
        professionalBio: isMisc && staffData.Misc[0].ProfessionalBio,
        shortStory: isMisc && staffData.Misc[0].ShortStory,
        education: isMisc && staffData.Misc[0].Education,
        exp: isMisc && staffData.Misc[0].Exp,
        certification: isMisc && staffData.Misc[0].Cert,
        specialties: isMisc && staffData.Misc[0].Spec,
        // goal: isMisc && staffData.Misc[0].Goal,
        npi: isMisc && staffData.Misc[0].NPI,
        insuranceAccepted: isMisc && staffData.Misc[0].InsuranceAccepted,
        licensedInStates: isMisc && staffData.Misc[0].LicensedInStates,
        affiliations: isMisc && staffData.Misc[0].Affiliations,
      };
      this.setState({ staffData: data });
    } else {
      this.setState({
        staffData: { mobileAppRole: '23' },
      });
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }

  onSubmit(values) {
    const {
      firstName,
      lastName,
      middleName,
      gender,
      line1,
      line2,
      city,
      stateOrProvince,
      zip,
      dateofBirth,
      phoneMobile,
      phoneHome,
      emailWork,
      emailHome,
      designation,
      mobileAppRole,
      title,
      professionalBio,
      shortStory,
      education,
      exp,
      certification,
      specialties,
      goal,
      npi,
      insuranceAccepted,
      licensedInStates,
      isProvider,
      screenName,
      affiliations,
    } = values;
    var staffId = this.props.userId;
    let providerInfo = {
      MobileAppRole: null,
      ScreenName: null,
      Title: null,
      ProfessionalBio: null,
      ShortStory: null,
      InsuranceAccepted: null,
      LicensedInStates: null,
    };
    if (isProvider) {
      providerInfo = {
        MobileAppRole: mobileAppRole,
        ScreenName: screenName,
        Title: title,
        ProfessionalBio: professionalBio,
        ShortStory: shortStory,
        InsuranceAccepted: insuranceAccepted,
        LicensedInStates:
          typeof values.licensedInStates == 'object'
            ? values.licensedInStates
              ? values.licensedInStates.map(item => item.Value).toString()
              : ''
            : values.licensedInStates || '',
      };
    }
    const data = {
      Staff: [
        {
          IsExternalStaff: 1,
          StaffTypeId: 2,
          IsProvider: isProvider,
          IsFinalSubmit: true,
          FirstName: firstName,
          MiddleName: middleName,
          LastName: lastName,
          Gender: gender,
          AddressLine1: line1,
          AddressLine2: line2,
          AddressLine3: null,
          City: city,
          DateofBirth: stringDate(dateofBirth),
          EmailWork: emailWork,
          EmailHome: emailHome,
          PhoneHome: phoneHome,
          PhoneMobile: phoneMobile,
          RegistrationStatus: 'Registered',
          StaffId: staffId,
          CountryISO: null,
          StateOrProvince: stateOrProvince,
          ZipCode: zip,
          Affiliations: affiliations,
          Education: education,
          Cert: certification,
          NPI: npi,
          Spec:
            typeof values.specialties == 'object'
              ? values.specialties
                ? values.specialties.map(item => item.Value).toString()
                : ''
              : values.specialties || '',
          ...providerInfo,
        },
      ],
    };
    var staffData = {
      staffId: staffId,
      email: data.Staff[0].EmailWork,
      jsonInput: JSON.stringify(data),
    };

    this.props.dispatch(adminstrationStaffUpsert(staffData));
  }

  render() {
    return (
      <>
        <PageTitle TitleText="External Member Onboarding" />
        <React.Fragment>
          <div className="edit-staff-mo">
            <Loader loading={this.props.isFetching} />
            <ReduxExternalStaffRegistrationForm
              onSaveClick={this.onSubmit.bind(this)}
              initialValues={this.state.staffData}
              // onCancel={() => this.props.history.push('')}
              getStaffId={this.props.userId}
              history={this.props.history}
              dispatch={this.props.dispatch}
              mobileAppStatus={this.props.mobileAppStatus}
              mobileAppMessage={this.props.mobileAppMessage}
              isDeactivated={this.props.staffData.IsDeactivated}
            />

            <CustomModal
              isOpen={this.state.isModelOpen}
              header={this.state.header}
              yesAction={() => {
                this.toggle();
                this.props.dispatch(resetLogin);
                this.props.dispatch(logOut({}));
              }}
              yesText="OK"
              hideCancel={true}
            />
          </div>
        </React.Fragment>
      </>
    );
  }
}

let ExternalStaffRegistrationForm = ({
  onSaveClick,
  onSubmit,
  handleSubmit,
  getStaffId,
  StateProvince,
  Gender,
  dispatch,
  phoneMobileValue,
  isProviderValue,
  ExternalProviderSpecialtyType,
}) => {
  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <div class="main enrollment-page mb-5">
        <div class="container-fluid">
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-4">
              <h4 class="form-main-head">External Care Team Member</h4>
            </div>
            <div class="col-12 col-md-8 col-lg-8 text-right">
              {/* <input
                type="button"
                class=" btn btn-outline-primary"
                value="Cancel"
                onClick={onCancel}
              /> */}
              &nbsp;
              <input type="submit" class="btn" value="Save" />
            </div>
          </div>

          <div class="row  mb-5">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form staff-form">
                  <div class="row pb-3 pl-3 pr-3 pt-3">
                    <div class="row">
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="firstName"
                            type="text"
                            errorMessage="First Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'First Name'}
                          />
                          <label class="placeholder-label">First Name*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field name="middleName" type="text" component={ReduxInput} />
                          <label class="placeholder-label">Middle Name</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="lastName"
                            type="text"
                            errorMessage="Last Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Last Name'}
                          />
                          <label class="placeholder-label">Last Name*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="gender"
                            type="text"
                            component={ReduxSelect}
                            options={Gender}
                            errorMessage="Gender Required"
                            validate={Validator.required}
                            placeholder="Gender*"
                            fieldName={'Gender'}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="line1"
                            type="text"
                            errorMessage="Permanent Address Line 1 Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Permanent Address'}
                          />
                          <label class="placeholder-label">
                            Permanent Address Line 1*
                            {/* <span class="small-text">(Last Known Address)</span> */}
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field name="line2" type="text" component={ReduxInput} />
                          <label class="placeholder-label"> Permanent Address Line 2</label>
                        </div>
                      </div>
                      {/* <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="country"
                            type="text"
                            errorMessage="Country Required"
                            component={ReduxSelect}
                            options={Country}
                            validate={Validator.required}
                            placeholder="Country*"
                            fieldName={'Country'}
                           
                          />
                        </div>
                      </div> */}
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="city"
                            type="text"
                            errorMessage="City Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'City'}
                          />
                          <label class="placeholder-label">City*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="stateOrProvince"
                            type="text"
                            errorMessage="State Required"
                            component={ReduxSelect}
                            options={StateProvince}
                            validate={Validator.required}
                            placeholder={'State/Province/Territories*'}
                            fieldName={'State/Province/Territories'}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="zip"
                            type="text"
                            errorMessage="Zip Code Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Zip Code'}
                          />
                          <label class="placeholder-label">Zip Code*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            component={ReduxInput}
                            name="dateofBirth"
                            normalize={dateofbirthNormalize}
                            // validate={Validator.dobStaff}
                            placeholder="MM/DD/YYYY"
                            fieldName={'Date of Birth'}
                          />
                          <label class="placeholder-label">Date of Birth</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <Field
                            name={`phoneMobile`}
                            component={ReduxInput}
                            validate={validate.NumericMobile}
                            format={MobileFormatter}
                            normalize={MobileNormalize}
                            fieldName={'Mobile'}
                          />
                          <label class="placeholder-label">Phone Mobile</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4 d-flex">
                        <div class="form-group w-100">
                          <Field
                            name="phoneHome"
                            // component={ReduxPhoneInput}
                            component={ReduxInput}
                            validate={validate.NumericMobile}
                            format={MobileFormatter}
                            normalize={MobileNormalize}
                            // placeholder="Phone Home*"
                            fieldName={'Phone Home'}
                          />
                          <label class="placeholder-label">Phone Home</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="emailWork"
                            type="email"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'E-mail Work'}
                          />
                          <label class="placeholder-label">E-mail Work*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field name="emailHome" type="email" component={ReduxInput} />
                          <label class="placeholder-label">E-mail Home</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="affiliations"
                            type="text"
                            component={ReduxInput}
                            fieldName={'Affiliations'}
                          />
                          <label class="placeholder-label">
                            Affiliations (Hospital or Medical Group)
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`certification`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Certification'}
                          />
                          <label class="placeholder-label">
                            Certification(s) /Accreditation(s)*
                          </label>
                        </div>
                      </div>

                      <div class="col-12 col-md-8 col-lg-8">
                        <div class="form-group">
                          <Field
                            name={`specialties`}
                            type="text"
                            placeholder="Specialties*"
                            component={ReduxSelect}
                            validate={Validator.required}
                            options={ExternalProviderSpecialtyType}
                            // options={staticData.specialtiesOptions}
                            fieldName={'specialties'}
                            isMulti
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="npi"
                            type="text"
                            component={ReduxInput}
                            validate={Validator.allowNumericNPI}
                            fieldName={'NPI'}
                          />
                          <label class="placeholder-label">
                            National Provider Identifier (NPI) number
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-8 col-lg-8">
                        <div class="form-group">
                          <Field
                            name={`education`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Education'}
                            // disabled={disable}
                          />
                          <label class="placeholder-label">
                            Education (Institution,degree,year of graduation)*
                          </label>
                        </div>
                      </div>
                      <div class="col-12 mt-2">
                        <div class="form-group">
                          <label class="custom-control-label">
                            Mobile platform user?{' '}
                            <Field
                              name="isProvider"
                              type="text"
                              class="custom-control-input not-empty"
                              component={ReduxToggle}
                            />
                            {'   '}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isProviderValue && (
                    <div class="row">
                      {phoneMobileValue && (
                        <div className="col-12 mb-4">
                          <div
                            className="send-invite-info"
                            style={{ justifyContent: 'space-between' }}
                          >
                            <div className="">
                              <img src="images/text-info.svg" className="mr-2" />
                              <span className="info">
                                An invitation will be sent to {phoneMobileValue}, to download and
                                join the mobile application.
                              </span>
                            </div>

                            <button
                              onClick={() => {
                                dispatch(
                                  appInvite({
                                    StaffId: getStaffId,
                                    MobileAppRole: 23,
                                  }),
                                );
                              }}
                              type="button"
                              class="btn btn-outline-primary py-1 mr-5"
                            >
                              Invite
                            </button>
                          </div>
                          {/* let formatedValue = this.formatMobile(this.props.mobileNumberValue);
                this.props.dispatch(
                  appInvite({
                    StaffId: this.props.staffid,
                    MobileAppRole: mobileAppRoleValue,
                    PhoneNumber: formatedValue,
                  }),
                ); */}
                        </div>
                      )}
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`mobileAppRole`}
                            type="text"
                            component={ReduxSelect}
                            // validate={Validator.required}
                            options={[
                              {
                                Text: 'External Care Team',
                                Value: '23',
                              },
                            ]}
                            placeholder="Assigned App Role"
                            fieldName={'Assigned App Role'}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="screenName"
                            type="text"
                            errorMessage="App User Name Required"
                            component={ReduxInput}
                            // validate={Validator.required}
                            fieldName={'Screen Name'}
                          />
                          <label class="placeholder-label">Screen Name</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="title"
                            type="text"
                            errorMessage="First Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Title'}
                          />
                          <label class="placeholder-label">
                            Title (e.g. Peer Recovery Coach,Life Coach)*
                          </label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`professionalBio`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Professional Bio'}
                          />
                          <label class="placeholder-label">Professional Bio*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`shortStory`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Short Story'}
                          />
                          <label class="placeholder-label">
                            Please write a short description of your services*
                          </label>
                        </div>
                      </div>

                      {/* <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name="exp"
                            type="text"
                            errorMessage="First Name Required"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Experience'}
                           
                          />
                          <label class="placeholder-label">Relevant Experience (in years)*</label>
                        </div>
                      </div> */}

                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`insuranceAccepted`}
                            type="textarea"
                            component={ReduxInput}
                            validate={Validator.required}
                            fieldName={'Insurance Accepted'}
                          />
                          <label class="placeholder-label">Insurance Accepted*</label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-4">
                        <div class="form-group">
                          <Field
                            name={`licensedInStates`}
                            type="text"
                            placeholder="Licensed in States"
                            component={ReduxSelect}
                            // validate={Validator.required}
                            options={StateProvince}
                            fieldName={'Licensed in State(s)'}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  const { combos } = state;
  if (!combos.data) {
    combos.data = {
      Center: [],
      PayType: [],
      StaffRole: [],
      StateProvince: [],
      Country: [],
      Gender: [],
      ExternalProviderSpecialtyType: [],
    };
  }
  return {
    Center: combos.data.Center,
    PayType: combos.data.PayType,
    StaffRole: combos.data.StaffRole,
    StateProvince: combos.data.StateProvince,
    Country: combos.data.Country,
    Gender: combos.data.Gender,
    ExternalProviderSpecialtyType: combos.data.ExternalProviderSpecialtyType,
    name: selector(state, 'firstName'),
    isProviderValue: selector(state, 'isProvider'),
    phoneMobileValue: selector(state, 'phoneMobile'),
  };
};

const ReduxExternalStaffRegistrationForm = reduxForm({
  form: 'staff-onboarding-form',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(ExternalStaffRegistrationForm)));

const selector = formValueSelector('staff-onboarding-form');

const mapStateToPropsParent = state => {
  const { staff_get, staff_upsert, default_values, app_invite, combos } = state;
  const { data: smsData } = app_invite;
  const { data, error, isFetching } = staff_get;
  const { isFetching: staffUpsertFetching } = staff_upsert;
  let staffData = {};
  var isInserted = false;
  var defaultCenter = null;
  var userId = null;
  var appInviteSMS = '';
  var mobileAppStatus = '';
  var mobileAppMessage = '';
  if (data) {
    staffData = data;
    // mobileAppStatus = data.MobileAppStatus;
    // mobileAppMessage = data.MobileAppMessage;
  }
  if (staff_upsert && staff_upsert.data && staff_upsert.data.success) {
    isInserted = true;
  }

  if (default_values && default_values.data) {
    defaultCenter = default_values.data.centerId;
    userId = default_values.data.userId;
  }

  if (smsData && smsData.success) {
    appInviteSMS = smsData.success;
  }

  return {
    staffData,
    isInserted,
    defaultCenter,
    appInviteSMS,
    mobileAppStatus,
    mobileAppMessage,
    isFetching: isFetching || staffUpsertFetching,
    userId,
  };
};

export default connect(mapStateToPropsParent)(ExternalStaff_Form);
