import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';

import {
  ReduxInput,
  ReduxDollarInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
} from '../../component/custom-redux-components';

const validate = {
  required: value => (value ? undefined : 'Required'),
};

export const COMPONENT_TYPES = {
  // Toogle: 'Toogle',
  // DatePicker: 'DatePicker',
  // PhoneInput: 'PhoneInput',
  ReduxSelect: 'ReduxSelect',
  // ReduxTextSelect: 'ReduxTextSelect',
  ReduxInput: 'ReduxInput',
  // ReduxPhoneInput: 'ReduxPhoneInput',
  ReduxDateInput: 'ReduxDateInput',
  ReduxToggle: 'ReduxToggle',
  ReduxAutoComplete: 'ReduxAutoComplete',
  // FrequencyReduxSelect: 'FrequencyReduxSelect',
  // CheckboxGroup: 'CheckboxGroup',
  // ReduxCheckbox: 'ReduxCheckbox',
  // ReduxTimePicker: 'ReduxTimePicker',
  ReduxCurrencyInput: 'ReduxCurrencyInput',
  ReduxDollarInput: 'ReduxDollarInput',
  ReduxTextarea: 'ReduxTextarea',
};

export const DynamicSection = ({
  sectionKey,
  sections,
  title,
  deleteTitle,
  addTitle,
  formFields,
  onAdd,
  disable,
  rows,
}) => {
  return (
    <>
      {sections.map((item, index) => {
        return (
          <>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <h6>
                  {title} {index + 1}
                </h6>
              </div>
              <div class="col-12 col-md-6">
                {!disable && (
                  <a
                    class="delete-contact"
                    onClick={() => sections.remove(index)}
                    disabled={disable}
                  >
                    <i class="fas fa-trash" style={{ marginRight: '8px' }} /> {deleteTitle}
                  </a>
                )}
              </div>
            </div>
            <div class="row mb-4">
              <DynamicForm
                formFields={formFields}
                mapper={sectionKey + '[' + index + ']'}
                row={rows[index]}
                disable={disable}
              />
            </div>
          </>
        );
      })}
      <div class="row">
        <div class="col-12 text-right pt-4 pb-4">
          <a
            class="add-contact"
            // onClick={onAdd}
            onClick={() => {
              if (disable) {
              } else {
               onAdd();
              }
            }}
            disabled={disable}
          >
            {sections.length >= 1 && <label> + Add Another {addTitle}</label>}
            {sections.length == 0 && <label> + Add {addTitle}</label>}
          </a>
        </div>
      </div>
    </>
  );
};

// set fieldName: true when we add validations
// fill placeholder for lookups and placeholder != fieldName
// set isRequired: true to see required field
// when isRequired: true, no need to set validate.required
// use condition as a function rather than value
// displayCalenderIcon true to show calender icon for text box. ex: dob
// componentType == COMPONENT_TYPES.ReduxCurrencyInput to get $ icon on left
// displayTitle true to show only title or header. title attribute to fill text

const DynamicForm = ({ formFields, mapper, row, disable }) => {
  return formFields.map(
    ({
      allowDebug,
      componentClass,
      componentType,
      isRequired,
      title,
      displayCalenderIcon,
      disabled,
      component,
      options,
      class: className,
      condition,
      for: forMap,
      displayTitle,
      displayInfo,
      renderComponent,
      smallInfo,
      filterBy,
      defaultFilterBy,
      ...formField
    }) => {
      if (allowDebug) {
      }
      componentType = componentType || COMPONENT_TYPES.ReduxInput;
      var label = title || formField.placeholder || formField.fieldName;
      var isText = true;
      formField.validate = formField.validate || [];
      className = className || 'col-12 col-md-6 col-lg-4';
      if (isRequired) {
        label = (formField.placeholder || formField.fieldName) + '*';
        if (formField.placeholder) {
          formField.placeholder = formField.placeholder + '*';
        }
        if (formField.validate.length == 0) {
          formField.validate.push(validate.required);
        }
      }
      component = component || ReduxInput;
      if (componentType == COMPONENT_TYPES.ReduxDateInput) {
        component = ReduxDateInput;
        isText = false;
      }

      if (options) {
        isText = false;
        component = ReduxSelect;
        formField.placeholder = formField.placeholder || label;
        if (filterBy) {
          var filter = defaultFilterBy;
          if (row && row[filterBy]) {
            filter = row[filterBy];
          }
          options = options[filter];
        }
      }
      if (condition && !condition()) {
        return <></>;
      }

      if (mapper) {
        formField.name = mapper + '.' + formField.name;
      }

      if (componentType == COMPONENT_TYPES.ReduxToggle) {
        return (
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label class="custom-control-label">
                {formField.fieldName} {'   '}
                <Field
                  name={formField.name}
                  type="text"
                  class="custom-control-input not-empty"
                  component={ReduxToggle}
                  disabled={disable || disabled}
                />
              </label>
            </div>
          </div>
        );
      } else if (componentType == COMPONENT_TYPES.ReduxDollarInput) {
        return (
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                type="text"
                disabled={disable || disabled}
                {...formField}
                component={ReduxDollarInput}
                // style={{ width: 'auto' }}
                options={options}
                dollarPlaceHolder={label}
              />
            </div>
          </div>
        );
      } else if (displayTitle) {
        return (
          <div class="col-12 mt-2">
            <h6>{title}</h6>
          </div>
        );
      } else if (displayInfo) {
        return (
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group field-info">
              <Field type="text" disabled={displayInfo} component={ReduxInput} {...formField} />
            </div>
          </div>
        );
      } else if (renderComponent) {
        return (
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">{renderComponent}</div>
          </div>
        );
      }

      if (componentType == COMPONENT_TYPES.ReduxAutoComplete) {
        return (
          <div className="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <Field
                name={formField.name}
                type="text"
                component={ReduxAutoComplete}
                options={options}
                label={label}
              />
            </div>
          </div>
        );
      }
      if (componentType == COMPONENT_TYPES.ReduxTextarea) {
        return (
          <div class={className}>
            <div className="form-group">
              <Field
                name={formField.name}
                className="form-control textarea-box"
                rows="4"
                type="textarea"
                component={ReduxTextarea}
                style={{
                  minHeight: '100px',
                }}
              />
              <label class="placeholder-label">Notes</label>
            </div>
          </div>
        );
      }

      componentClass = componentClass || 'form-group';
      return (
        <div class={className}>
          <div class={componentClass}>
            <Field
              type="text"
              {...formField}
              component={component}
              options={options}
              disabled={disable || disabled}
            />
            {isText && <label class="placeholder-label">{label}</label>}
            {smallInfo && <span class="small-info">{smallInfo}</span>}
            {displayCalenderIcon && <i class="far fa-calendar-alt form-control-icon" />}
          </div>
        </div>
      );
    },
  );
};

export default DynamicForm;
