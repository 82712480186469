import React, { Component } from 'react';
import { Input } from 'antd';
import SubmitButton from '../../../../../Components/submitButton';
import { payerPortalRequestUpsert } from '../../../../../store/actions';

const { TextArea } = Input;

export default class TermRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  addNoteChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  onRequestSubmit = () => {
    this.props.dispatch(
      payerPortalRequestUpsert({
        Json: JSON.stringify({
          Request: [
            {
              PayerPortalRequestId: null,
              SmartContractId: this.props.contractId,
              RequestType: '30204',
              Id: this.props.termsCategoryId,
              Note: this.state.value,
            },
          ],
        }),
      }),
    );
  };
  render() {
    return (
      <div>
        <div className="client-detail-box p-0 m-0">
          <p className="client-label mb-0">Terms Category*</p>
          <p className="client-text mb-0">{`${this.props.termCategory}`}</p>
        </div>

        <div className="mt-3">
          <h6>Add Note*</h6>
          <TextArea rows={5} value={this.state.value} onChange={this.addNoteChange} />
        </div>

        <div className="d-flex justify-content-end mt-5 align-items-center  w-100">
          <button
            type="button"
            className="btn mr-3 btn-outline-primary"
            onClick={this.props.openAndCloseModal}
          >
            Cancel
          </button>
          {/* <button type="button" className="btn btn-primary" onClick={this.onRequestSubmit}>
            Send Request
          </button> */}
          <SubmitButton
            value={'Send Request'}
            // className="btn btn-primary"
            loading={this.props.isReqFetching}
            onClick={this.onRequestSubmit}
            disabled={!(this.state.value && this.state.value.length > 0)}
            style={{ height: '45px' }}
          />
        </div>
      </div>
    );
  }
}
