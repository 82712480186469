import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, Button } from 'antd';
import { getBasicProfile } from '../../store/actions';
import moment from 'moment';
import _blank from '../../images/blank-profile.png';
import Twilio from '../../Components/twilio';

class ProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getUserGuid() {
    if (
      this.props.location.pathname.split('/')[1] == 'subscriberProfile' ||
      this.props.location.pathname.split('/')[1] == 'subscribers-survery'
    ) {
      return this.props.location.pathname.split('/')[2];
    } else {
      return this.props.location.search.split('=')[1];
    }
  }
  componentDidMount() {
    let checkTimelineUrl =
      this.props.location.pathname.split('/')[1] == 'subscriberProfile' ||
      this.props.location.pathname.split('/')[1] == 'subscribers-survery';
    this.setState({ checkTimelineUrl });
    let data = { Profile: [{ UserGuid: this.getUserGuid() }] };

    this.props.dispatch(
      getBasicProfile({
        Json: JSON.stringify(data),
      }),
    );
  }
  resetIsOpen = () => {
    this.setState({
      callModelVisible: false,
      clickedRecord: {},
    });
  };
  render() {
    const { ProfileData } = this.props;
    const {
      Age,
      AppJoiningDate,
      AppLastOpened,
      CurrentSubscription,
      LatestCoordinate,
      LocationLastCaptured,
      Name,
      NotilLastDelivered,
      Phone,
      IsVoiceCallAllowed,
      ProfileImageUrl,
      UserGuid,
      StaffName,
      IsStudent,
      StudentId,
      IsClient,
      ClientId,
      Address,
      LocationAuthorizationStatus,
      NotificationAuthorizationStatus,
      TimeZone,
      Language,
      BadgeId,
    } = ProfileData;
    return (
      <div class="border-0">
        <div className="row">
          {/* NAME DETAIL */}
          <div className="col-12 mb-2 pt-4">
            <div className="card border-0 students-profile">
              <div className="row card-body align-items-center">
                <div className="col-md-1">
                  <div className="EditImage" style={{ margin: '0 auto' }}>
                    <div
                      className="images-placeholder2"
                      style={{
                        backgroundImage: 'url(&quot;images/surveys-img1.jpg&quot;)',
                      }}
                    >
                      <img
                        src={ProfileImageUrl || _blank}
                        className="students-profile-img images-placeholder2"
                        alt=""
                        width="84"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 px-0">
                  <h4 className="students-name text-xs-center text-md-left text-capitalize">
                    {Name}
                  </h4>
                  {IsVoiceCallAllowed && !this.props.isReadOnlyUser && (
                    <span
                      title="Call"
                      className="d-flex align-items.center"
                      style={{ color: '#46B4FE', marginBottom: '0', cursor: 'pointer' }}
                      onClick={event => {
                        this.setState({
                          callModelVisible: true,
                          clickedRecord: {
                            Phone: Phone,
                            Name: StaffName,
                            ProfileImageUrl: ProfileImageUrl,
                          },
                        });
                        event.stopPropagation();
                      }}
                    >
                      <img
                        style={{
                          display: 'block',
                          marginRight: '5px',
                        }}
                        src={`images/twilioPhoneAllowed.svg`}
                        alt="Calling Allowed"
                        width="10px"
                      />
                      {Phone}
                    </span>
                  )}
                </div>
                <div className="col-md-7 students-action-button text-xs-center d-flex align-items-center p-3">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      width: '600px',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span>
                      {' '}
                      {Address && (
                        <img src="./images/profile_location.svg" alt=" Image Not Available" />
                      )}{' '}
                      &nbsp;
                      {Address}
                    </span>

                    <span>
                      {' '}
                      {LocationAuthorizationStatus && (
                        <img
                          src="./images/profile_googleLocastion.svg"
                          alt=" Image Not Available"
                        />
                      )}{' '}
                      &nbsp;
                      {LocationAuthorizationStatus}
                    </span>

                    <span>
                      {' '}
                      {NotificationAuthorizationStatus && (
                        <img src="./images/profile_notification.svg" alt=" Image Not Available" />
                      )}{' '}
                      &nbsp;
                      {NotificationAuthorizationStatus}
                    </span>

                    <span>
                      {' '}
                      {TimeZone && (
                        <img src="./images/profile_earth.svg" alt=" Image Not Available" />
                      )}{' '}
                      &nbsp;
                      {TimeZone}
                    </span>

                    <span>
                      {' '}
                      {Language && (
                        <img src="./images/profile_lang.svg" alt=" Image Not Available" />
                      )}{' '}
                      &nbsp;
                      {Language}
                    </span>
                  </div>
                </div>

                {/* student Personal */}
                <div className="col-12 col-xl-12 mt-4">
                  <div className="row students-detail   text-md-left  justify-content-md-between">
                    {/* <div className="col-1 col-sm-1 mb-2 md-sm-0 ">
                      <span className="info-label">Age</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {Age} {Age && 'Years'}
                        {!Age && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div> */}
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">Last Location Received on</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {LocationLastCaptured && (
                          <a
                            href={`http://www.google.com/maps/place/${LatestCoordinate[0].Latitude},${LatestCoordinate[0].Longitude}`}
                            target="_blank"
                          >
                            {LocationLastCaptured}
                          </a>
                        )}
                        {!LocationLastCaptured && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">App Joining Date</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {AppJoiningDate && moment(AppJoiningDate).format('ll')}
                      </h5>
                    </div>

                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">App Last Opened Date</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {AppLastOpened}
                        {!AppLastOpened && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">Current Subscription</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {CurrentSubscription}
                      </h5>
                    </div>

                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">Notification Last Delivered or Read </span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {NotilLastDelivered}{' '}
                        {!NotilLastDelivered && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="col-2 col-sm-2 mb-2 md-sm-0 pl-0">
                      <span className="info-label">Advisor</span>
                      <h5 className="info-detail m-md-0" style={{ fontSize: '16px' }}>
                        {StaffName}
                        {!StaffName && (
                          <span style={{ color: '#80808073', fontSize: '10px' }}>
                            Not Available
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
                {/* student Personal */}
              </div>
            </div>
          </div>
          {/* NAME DETAIL */}
        </div>
        {this.state.callModelVisible && (
          <Twilio
            isOpen={this.state.callModelVisible}
            userData={this.state.clickedRecord}
            resetIsOpen={this.resetIsOpen}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ get_basic_profile }) => {
  const { data, error, isFetching } = get_basic_profile;
  let ProfileData = {};

  if (data && data.Profile) {
    ProfileData = data.Profile[0];
  }
  return { ProfileData };
};

export default connect(mapStateToProps)(withRouter(ProfileHeader));
