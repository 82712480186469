import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const ComingSoon = ({ history }) => {
  return (
    <div className="bg-white">
      <div className="text-center" style={{ paddingTop: '100px' }}>
        {' '}
        <img src={`images/license/undraw_engineering_team.svg`} width="443" height="227" alt="" />
      </div>
      <p
        className="text-center"
        style={{
          fontStyle: 'normal',
          fontWeight: '300',
          fontSize: '64px',
          lineHeight: '77px',
          textTransform: 'uppercase',
          color: '#979797',
          marginBottom: '5px',
        }}
      >
        Coming Soon
      </p>
      <p
        className="text-center"
        style={{ fontWeight: '400', fontSize: '22px', lineHeight: '30px', color: '#46B4FE' }}
      >
        We’re currently working on creating something fantastic . It’ll be here soon.
      </p>
      <div className="text-center">
        <button
          className="btn text-center mb-4"
          onClick={() => {
            history.push('/dashboardNew/app-users');
          }}
          style={{ cursor: 'pointer' }}
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};
export default connect()(withRouter(ComingSoon));
;
;