import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AskYOUUniverse from './AskYOUUniverse';

class AskYOUUniverseExpanded extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid ask-you-universe youu-expanded">
        <div className="row">
          <div className="col-12 ">
            <div className="you-universe-title">
              <img src="images/gpt-ai.svg" />
              <span className="text">Ask YOUUniverse</span>
            </div>
          </div>
          <div className="col-12 mb-5">
            <AskYOUUniverse />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect()(AskYOUUniverseExpanded));
