import React, { Component } from 'react';
import { Checkbox } from 'antd';
import renderHTML from 'react-render-html';
import { SurveyQuestion, DoesNotApplyOption } from '../questionComponents';

class MultiSelectType extends Component {
  constructor(props) {
    super(props);
    let {
      Response,
      Option1Value,
      Option2Value,
      Option3Value,
      Option4Value,
      Option5Value,
      Option6Value,
      Option7Value,
      Option8Value,
      Option9Value,
      Option10Value,
      Option11Value,
    } = props.survey;
    let responseArr =
      Response &&
      JSON.parse(Response).map(item => {
        return {
          [item]: true,
        };
      });
    let responseObj =
      responseArr &&
      responseArr.reduce(function(result, current) {
        return Object.assign(result, current);
      }, {});
    this.state = {
      result: {
        [Option1Value]: false,
        [Option2Value]: false,
        [Option3Value]: false,
        [Option4Value]: false,
        [Option5Value]: false,
        [Option6Value]: false,
        [Option7Value]: false,
        [Option8Value]: false,
        [Option9Value]: false,
        [Option10Value]: false,
        [Option11Value]: false,
        ...responseObj,
      },
    };
  }
  render() {
    const {
      survey,
      onClick,
      setValue,
      scope,
      index,
      total,
      level,
      subLevel,
      subCatLevel,
      isReadOnly,
    } = this.props;
    const {
      Question,
      QuestionNumber,
      DoesNotApplyText,
      DoesNotApplyValue,
      Option1Value,
      Option1Text,
      Option2Value,
      Option2Text,
      Option3Value,
      Option3Text,
      Option4Value,
      Option4Text,
      Option5Value,
      Option5Text,
      Option6Value,
      Option6Text,
      Option7Value,
      Option7Text,
      Option8Value,
      Option8Text,
      Option9Value,
      Option9Text,
      Option10Value,
      Option10Text,
      Option11Value,
      Option11Text,
      Response,
      SurveyQuestionId,
    } = survey;
    const textArray = [
        Option1Text,
        Option2Text,
        Option3Text,
        Option4Text,
        Option5Text,
        Option6Text,
        Option7Text,
        Option8Text,
        Option9Text,
        Option10Text,
        Option11Text,
      ],
      valueArray = [
        Option1Value,
        Option2Value,
        Option3Value,
        Option4Value,
        Option5Value,
        Option6Value,
        Option7Value,
        Option8Value,
        Option9Value,
        Option10Value,
        Option11Value,
      ];
    // let result = { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false };
    return (
      <div className="type-border col-12">
        <div
        // class="card-body"
        >
          <SurveyQuestion survey={survey} />
          <ul class="answer-options d-flex flex-column text-left">
            {textArray.map((item, qIndex) => {
              let val = valueArray[qIndex];
              return (
                item && (
                  <li className="pl-3 multi-select-checkbox">
                    <Checkbox
                      disabled={isReadOnly}
                      name="current-situation"
                      checked={this.state.result[val]}
                      value={item}
                      onClick={e => {
                        let result = this.state.result;
                        result[val] = e.target.checked;
                        let keys = Object.keys(result);
                        let filtered = keys.filter(key => {
                          return result[key];
                        });
                        this.setState({
                          result,
                          filtered,
                        });
                        onClick.bind(scope, {
                          surveyQuestionId: SurveyQuestionId,
                          response: JSON.stringify(filtered),
                          level: level,
                          subLevel: subLevel,
                          subCatLevel: subCatLevel,
                          index: index,
                        })();
                      }}
                    >
                      {' '}
                      {item && item.length > 0 && renderHTML(item)}
                    </Checkbox>
                  </li>
                )
              );
            })}
          </ul>
          <DoesNotApplyOption surveyProps={this.props} />
        </div>
      </div>
    );
  }
}
export default MultiSelectType;
