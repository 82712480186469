import moment from 'moment';

export const getMMMYYYYFormat = date => {
  // Format Like 26th May, 2023
  return date && moment(date).format('Do MMM, YYYY');
};

export const getMMDDYYYYFormat = date => {
  // Format Like 05-26-2023
  return date && moment(date).format('MM-DD-YYYY');
};
