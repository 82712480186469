import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import Menu from './menu';
import { listCombos, loadDefaultValues, logError, staffAccessedTabGet } from '../../store/actions';
import { CACHE_CONFIG } from '../../store/cacheConfig';
import CustomError from '../CustomError';
import PropTypes from 'prop-types';

ReactGA.initialize('UA-182789994-1');

class MasterPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayJSError: false,
      jsError: '',
    };
  }

  componentDidCatch(error) {
    console.error(error);
    this.props.dispatch(
      logError({
        message: error.message,
        stack: error.stack,
      }),
    );
    this.setState({
      displayJSError: true,
      jsError: 'An error has occurred. Please try again later', //error,
    });
  }

  resetError() {
    this.setState({
      displayJSError: false,
      jsError: '',
    });
    this.props.dispatch({ type: 'DISPLAY_ERROR_RESET' });
  }

  componentDidMount() {
    // if (!CACHE_CONFIG['Center'] || !CACHE_CONFIG['Center'].loaded) {
    //   CACHE_CONFIG['Center'] = { loaded: true };
    //   this.props.dispatch(listCombos({ comboTypes: 'Center' }));
    // }
    if (!CACHE_CONFIG['AccessedTab'] || !CACHE_CONFIG['AccessedTab'].loaded) {
      CACHE_CONFIG['AccessedTab'] = { loaded: true };
      this.props.dispatch(staffAccessedTabGet({}));
    }
    if (!CACHE_CONFIG['DefaultValues'] || !CACHE_CONFIG['DefaultValues'].loaded) {
      CACHE_CONFIG['DefaultValues'] = { loaded: true };
      this.props.dispatch(loadDefaultValues({}));
    }
    ReactGA.pageview(window.location.hash + window.location.search);
  }

  render() {
    const { children, logOutBroadcaster } = this.props;
    const { displayJSError, jsError } = this.state;

    return (
      <div>
        {!displayJSError ? (
          <>
            <Menu logOutBroadcaster={logOutBroadcaster} />
            {children}
          </>
        ) : (
          <CustomError
            displayJSError={displayJSError}
            jsError={jsError}
            resetError={this.resetError.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default connect()(withRouter(MasterPage));
