import React from 'react';
import { Button } from 'antd';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
  ReduxCheckbox,
} from '../component/custom-redux-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomModal from '../core/modal';
import WarningComponent from '../core/warning-component';
import AntdModal from '../Components/CustomModal';
import { stringDate } from '../util/date';

import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  initialize,
  getFormSyncErrors,
  isDirty,
} from 'redux-form';
import {
  adminstrationGetCourse,
  adminstrationCourseUpsert,
  resetAdminstrationCourseUpsert,
  upsertCouponCode,
  resetUpsertCouponCode,
  getCouponCode,
  listCombos,
} from '../store/actions';
import { ProcessCombos } from '../store/comboConfig';
import { Delete_Confirmation_Msg, Upsert_Sucess_Msg, Delete_Sucess_Msg } from '../app-config';
import PageTitle from '../Components/PageTitle';
import { get } from 'lodash';

class ActivityBase extends React.Component {}

const Validator = {
  careTeamPrimaryRequired: value => {
    //To Do: We are reading value from string as we face issue with Array value
    value = value && typeof value === 'string' ? JSON.parse(value) : value;
    if (value && value.length) {
      for (var i = 0, len = value.length; i < len; i++) {
        if (value[i].IsPrimary === true) {
          return undefined;
        }
      }
    }
    return 'At least 1 care team member is required';
  },
  required: value => (value ? undefined : 'Required'),
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  endDate: (value, values, props, name) => {
    if (!value) {
      return 'Required';
    }
    var index = parseInt(name.match(/\d+/), 10);
    var objectName = name.split('[')[0];
    var startDate = values.StartDate;
    var date1 = new Date(startDate).getTime();
    var date2 = new Date(value).getTime();

    if (date2 >= date1) {
    } else {
      return 'has to be greater than or Equal to Start Date';
    }
  },
  allowNumeric: value =>
    value && !/^[0-9]+$/.test(value) ? 'Please enter numeric values only' : undefined,
  allowAlphabet: value =>
    value && !/[a-zA-Z]+/g.test(value) ? 'Please enter alphabets values only' : undefined,
  allowNumericMobile: value =>
    value && !/^[0-9--]*$/.test(value) ? 'Please enter numeric values only' : undefined,
  promoCode: value =>
    value && !/^.{5,50}$/.test(value)
      ? ' has a minimum of 5 and a maximum of 20 characters'
      : undefined,
};

class PromoCode extends ActivityBase {
  constructor(props) {
    super(props);
    this.state = {
      couponCodeData: {
        CenterId: parseInt(localStorage.getItem('centerId')),
      },
    };
  }

  getCouponId() {
    var param = new URLSearchParams(this.props.history.location.search);
    var couponId = param.get('couponId');
    if (couponId) {
      couponId = parseInt(couponId);
    } else {
      couponId = -1;
    }
    return couponId;
  }

  componentDidMount() {
    if (this.getCouponId() > 0) {
      this.props.dispatch(
        getCouponCode({
          Json: JSON.stringify({
            Coupon: [
              {
                CouponId: this.getCouponId(),
              },
            ],
          }),
        }),
      );
    }
    this.props.dispatch(
      listCombos({
        comboTypes: 'AppAdvisor',
        Json: JSON.stringify({
          AppAdvisor: [{ CenterId: parseInt(localStorage.getItem('centerId')) }],
        }),
      }),
    );
    var result = ProcessCombos('Center');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
  }
  toggle = header => {
    this.setState(prevState => ({
      isModelOpen: !prevState.isModelOpen,
      header,
    }));
  };

  componentWillReceiveProps({ couponCodeInfo, isInserted }) {
    if (isInserted && isInserted != this.props.isInserted) {
      this.toggle(Upsert_Sucess_Msg);
      this.props.dispatch(resetUpsertCouponCode());
    }

    if (couponCodeInfo && couponCodeInfo.CouponId > 0 && this.getCouponId() > 0) {
      const data = {
        CenterId: couponCodeInfo.CenterId,
        SubscriptionTypeId: couponCodeInfo.SubscriptionType,
        CouponId: couponCodeInfo.CouponId,
        CouponCode: couponCodeInfo.CouponCode,
        MaxUseLimit: couponCodeInfo.MaxUseLimit,
        StartDate: couponCodeInfo.StartDate,
        EndDate: couponCodeInfo.EndDate,
        StaffId: couponCodeInfo.AdvisorId,
        IsFreeSubscription: couponCodeInfo.IsFreeSubscription,
      };
      this.setState({ couponCodeData: data });
      this.props.dispatch(
        listCombos({
          comboTypes: 'AppAdvisor',
          Json: JSON.stringify({ AppAdvisor: [{ CenterId: couponCodeInfo.CenterId }] }),
        }),
      );
    }
  }
  if(errorMessage) {
    this.setState({ isModelOpen: true, errorMessage: errorMessage });
  }
  getStringType = data => {
    var array = [];
    if (typeof data == 'string') {
      return data;
    } else if (data && data.length > 0) {
      for (let item of data) array.push(item.Value);
    }
    return array.toString();
  };
  onSubmit(values) {
    const {
      CouponCode,
      SubscriptionTypeId,
      MaxUseLimit,
      StartDate,
      EndDate,
      StaffId,
      IsFreeSubscription,
      CenterId,
    } = values;
    var couponId = null;

    var paramCouponId = this.getCouponId();
    if (paramCouponId && paramCouponId > 0) {
      couponId = paramCouponId;
    }

    const data = {
      Coupon: [
        {
          CenterId: CenterId,
          SubscriptionTypeId: null,
          CouponId: couponId,
          CouponCode: CouponCode,
          MaxUseLimit: MaxUseLimit,
          StartDate: stringDate(StartDate),
          EndDate: stringDate(EndDate),
          StaffId: StaffId,
          IsFreeSubscription: IsFreeSubscription,
        },
      ],
    };
    var couponData = {
      Json: JSON.stringify(data),
    };

    this.props.dispatch(upsertCouponCode(couponData));
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle TitleText="Promo Code" />
        <ReduxCouponForm
          onSaveClick={this.onSubmit.bind(this)}
          initialValues={this.state.couponCodeData}
          onCancel={() => this.props.history.push('/manage/promo-code')}
          getCouponId={this.getCouponId()}
          Delete={() => {
            this.Removetoggle();
            //this.props.history.push('/manage/courses')
          }}
        />
        <CustomModal
          isOpen={this.state.isModelOpen}
          header={this.state.header}
          yesAction={() => {
            this.toggle();
            this.props.history.push('/manage/promo-code');
          }}
          yesText="OK"
          hideCancel={true}
        />
        <WarningComponent
          dirtyFlag={this.props.isDirty}
          submitSucceeded={this.props.submitSucceeded}
        />
      </React.Fragment>
    );
  }
}

let CouponForm = ({
  onSaveClick,
  onSubmit,
  handleSubmit,
  initialize,
  Staff,
  AppAdvisor,
  Center,
  getCouponId,
  initialValues,
  onCancel,
  history,
  dispatch,
  isFetching,
}) => {
  return (
    <div class="main enrollment-page mb-5">
      <div className="container-fluid">
        <form onSubmit={handleSubmit(onSaveClick)}>
          <div class="row top-heading-row mt-5 mb-3 align-items-center">
            <div class="col-12 col-md-4 col-lg-3">
              <h4 class="form-main-head">{getCouponId > 0 ? 'Edit Promo' : 'Add a Promo'}</h4>
            </div>
            <div class="col-12 col-md-8 col-lg-9 text-right">
              <input
                type="button"
                class=" btn btn-outline-primary"
                value="Cancel"
                onClick={onCancel}
              />
              &nbsp;
              {/* <input
                type="submit"
                class="btn"
                value="Save"
                //onClick={onSaveClick}
              /> */}
              <Button
                loading={isFetching}
                htmlType="submit"
                type="primary"
                style={{
                  fontWeight: 'bold',
                  borderRadius: '26px',
                  height: '37px',
                  border: 0,
                  color: '#fff',
                  padding: '0 43px',
                  lineHeight: '24px',
                  background: 'linear-gradient(to right, #00f1fe 0%, #4facfe 50%, #00f1fe 100%)',
                  backgroundSize: '300% 100%',
                  transition: 'all 300ms ease-in-out',
                }}
                // disabled={IsDeActivated}
              >
                Save
              </Button>
            </div>
          </div>
          <div class="row  mb-5">
            <div class="col-12 mb-4">
              <div class="card border-0 rounded-0 ">
                <div class="card-body enrollment-form">
                  <div class="row pb-5 pl-3 pr-3 pt-3">
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        {/* <label class="placeholder-label">Center</label> */}
                        <Field
                          name="CenterId"
                          type="text"
                          errorMessage="Center Required"
                          component={ReduxSelect}
                          options={Center}
                          placeholder="Center"
                          // validate={Validator.required}
                          fieldName={'Center'}
                          onChange={centerId => {
                            dispatch(
                              listCombos({
                                comboTypes: 'AppAdvisor',
                                Json: JSON.stringify({ AppAdvisor: [{ CenterId: centerId }] }),
                              }),
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        {/* <label class="placeholder-label">Counselor</label> */}
                        <Field
                          name="StaffId"
                          type="text"
                          errorMessage="Counselor Required"
                          component={ReduxSelect}
                          options={AppAdvisor}
                          placeholder="Counselor"
                          // validate={Validator.required}
                          fieldName={'Counselor'}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="CouponCode"
                          type="text"
                          component={ReduxInput}
                          errorMessage="Promo Code Required"
                          validate={[Validator.required, Validator.promoCode]}
                          fieldName={'Promo Code'}
                        />
                        <label class="placeholder-label">Promo Code*</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="MaxUseLimit"
                          type="number"
                          component={ReduxInput}
                          min={1}
                          max={999}
                          negativeNumberBlocked={true}
                          errorMessage="Max Use Limit Required"
                          // validate={Validator.required}
                          fieldName={'Max Use Limit'}
                        />
                        <label class="placeholder-label">Max Use Limit</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="StartDate"
                          type="text"
                          component={ReduxDateInput}
                          errorMessage="Start Date Required"
                          validate={Validator.required}
                          label={'Start Date*'}
                          minDate={new Date()}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <Field
                          name="EndDate"
                          type="text"
                          // errorMessage="End Date Required"
                          component={ReduxDateInput}
                          validate={[Validator.required, Validator.endDate]}
                          label={'End Date*'}
                          fieldName={'End Date'}
                          minDate={new Date()}
                        />
                        <i class="far fa-calendar-alt form-control-icon" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-3">
                      <div class="form-group">
                        <Field
                          className="mr-2"
                          name="IsFreeSubscription"
                          component={ReduxCheckbox}
                          fieldName={'IsFreeSubscription'}
                        />
                        <label class="placeholder-label" style={{ top: '5px' }}>
                          Is Free Subscription?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { combos, upsert_coupon_code } = state;
  const { isFetching } = upsert_coupon_code;
  if (!combos.data) {
    combos.data = {
      Staff: [],
      Center: [],
      AppAdvisor: [],
    };
  }
  const courseTopic = formValueSelector('couponform')(state, 'name');

  return {
    Staff: combos.data.Staff,
    Center: combos.data.Center,
    AppAdvisor: combos.data.AppAdvisor,
    courseTopic,
    isFetching,
  };
};

const ReduxCouponForm = reduxForm({
  form: 'couponform',
  enableReinitialize: true,
})(connect(mapStateToProps)(withRouter(CouponForm)));

const mapStateToPropsParent = state => {
  const { upsert_coupon_code, get_coupon_code } = state;
  const { data, isFetching, error } = get_coupon_code;

  let couponCodeInfo = [];
  var isInserted = false;

  if (data && data[0]) {
    couponCodeInfo = data[0];
  }
  if (upsert_coupon_code && upsert_coupon_code.data && upsert_coupon_code.data.success) {
    isInserted = true;
  }

  return {
    couponCodeInfo,
    isInserted,
    isDirty: isDirty('couponform')(state),
    submitSucceeded: get(state, 'form.couponform.submitSucceeded', false),
    isFetching,
  };
};

export default connect(mapStateToPropsParent)(PromoCode);
