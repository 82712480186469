import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Drawer } from 'antd';
import renderHTML from 'react-render-html';

class StudentAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { surveyanswersdata, ColData, RowData } = this.props;
    return (
      <div className="row">
        <div class="col-12">
          <div class="survey-list-table mb-3 p-2">
            <Table
              dataSource={RowData}
              columns={ColData}
              pagination={{
                itemRender: (page, type, originalElement) => {
                  return (
                    <div class="dataTables_wrapper no-footer">
                      <div class="dataTables_paginate paging_simple_numbers">
                        {type == 'prev' ? (
                          <a class="paginate_button previous">Previous</a>
                        ) : type == 'next' ? (
                          <a class="paginate_button next">Next</a>
                        ) : (
                          <a class="paginate_button ">{page}</a>
                        )}
                      </div>
                    </div>
                  );
                },
                showSizeChanger: true,
                defaultPageSize: 5,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(StudentAnswer);
