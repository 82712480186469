import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import SurveyAnswers from './SurveyAnswers';
import AIAugmentedSummary from './AIAugmentedSummary';
import ListRenderer from '../../../Components/listRenderer';
const activeKey = 0;

class SurveyResponseDrawer extends Component {
  constructor(props) {
    super(props);
    const { ConcatenatedName } = props.answerRecord || {};
    this.state = {
      isASAM: ConcatenatedName === 'ASAM',
    };
  }
  list = [
    {
      Name: 'Survey Answers',
      ExtraComponent: () => (
        <div className="">
          <SurveyAnswers
            surveyanswersdata={this.props.surveyanswersdata}
            answerRecord={this.props.answerRecord}
            ColData={this.props.ColData}
            RowData={this.props.RowData}
            ClientId={this.props.ClientId}
          />
        </div>
      ),
    },
    {
      Name: 'AI Augmented Summary',
      ExtraComponent: () => {
        return (
          <div className="">
            <AIAugmentedSummary
              ClientId={this.props.ClientId}
              UserGuid={this.props.UserGuid}
              SurveyIds={this.props.answerRecord.SurveyId || this.props.answerRecord.surveyId}
              toggleDrawer={this.props.toggleDrawer}
            />
          </div>
        );
      },
    },
  ];
  list2 = [
    {
      Name: 'Survey Answers',
      ExtraComponent: () => (
        <div className="">
          <SurveyAnswers
            surveyanswersdata={this.props.surveyanswersdata}
            answerRecord={this.props.answerRecord}
            ColData={this.props.ColData}
            RowData={this.props.RowData}
            ClientId={this.props.ClientId}
          />
        </div>
      ),
    },
  ];
  render() {
    const {
      ClientId,
      toggleDrawer,
      answerRecord,
      surveyanswersdata,
      isAugmentedSummary,
    } = this.props;
    const { isASAM } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 mb-2 py-2">
            <div className="survey-response">
              <h5>{answerRecord && answerRecord.SurveyCategory}</h5>
              <span className="text">Completed:</span>{' '}
              <span className="value">{answerRecord && answerRecord.CompletedDt}</span>
            </div>
          </div>
          <div className="col-12 center-tabs">
            <ListRenderer
              list={
                !isASAM &&
                (isAugmentedSummary.LicenseStatusId == 7 ||
                  isAugmentedSummary.LicenseStatusId == 2 ||
                  isAugmentedSummary.LicenseStatusId == 4)
                  ? this.list
                  : this.list2
              }
              activeKey={activeKey}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ user_feature_flag_get }) => {
  const { data: fFData, isFetching, error } = user_feature_flag_get;

  let isAugmentedSummary = false;

  if (fFData && fFData.FeatureFlag && fFData.FeatureFlag.length > 0 && fFData.FeatureFlag[0]) {
    isAugmentedSummary =
      (fFData.FeatureFlag &&
        fFData.FeatureFlag[0] &&
        fFData.FeatureFlag[0].License &&
        fFData.FeatureFlag[0].License.length > 0 &&
        fFData.FeatureFlag[0].License.filter(item => item.ModuleName == 'AugmentedAI')[0]) ||
      {};
  }
  return {
    isAugmentedSummary,
  };
};
export default connect(mapStateToProps)(withRouter(SurveyResponseDrawer));
