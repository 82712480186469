import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tooltip, Drawer } from 'antd';
import Axios from 'axios';
import renderHTML from 'react-render-html';
import {
  studentSurveyListGet,
  surveyAnswersGet,
  surveyTaskMarkInComplete,
  resetSurveyTaskMarkInComplete,
  resetSurveyAnswersGet,
} from '../../../store/actions';
import CustomModal from '../../../Components/CustomModal';
import SurveyResponseDrawer from '../../customScreens/surveyResponse';
import Loader, { Loader2 } from '../../../Components/Loader';
import PageTitle from '../../../Components/PageTitle';
class IntakeSurveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyanswersdata: [],
      surveyanswersdatacol: [
        { title: 'Q No', dataIndex: 'QuestionNumber', key: 'QuestionNumber' },
        {
          title: 'Question',
          dataIndex: 'Question',
          key: 'Question',
          render: (text, record) => {
            return (
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && renderHTML(text)}
              </span>
            );
          },
        },
        { title: 'Answer', dataIndex: 'Answer', key: 'Answer' },
        { title: 'Responded Date Time', dataIndex: 'RespondedDateTime', key: 'RespondedDateTime' },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(
      studentSurveyListGet({
        Json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
            },
          ],
        }),
      }),
    );
  }
  componentWillReceiveProps({ surveyanswersdata, isSurveyMarkInComplete, isSurveyAssigned }) {
    if (surveyanswersdata.length > 0) {
      this.setState({ surveyanswersdata, isResultDrawerOpen: true });
    }
    if (isSurveyMarkInComplete && isSurveyMarkInComplete != this.props.isSurveyMarkInComplete) {
      this.props.dispatch(
        studentSurveyListGet({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: this.props.ClientId,
              },
            ],
          }),
        }),
      );
      this.props.dispatch(resetSurveyTaskMarkInComplete());
    }
    if (isSurveyAssigned && isSurveyAssigned != this.props.isSurveyAssigned) {
      this.props.dispatch(
        studentSurveyListGet({
          Json: JSON.stringify({
            Student: [
              {
                StudentId: this.props.ClientId,
              },
            ],
          }),
        }),
      );
    }
  }
  toggleDrawer = () => {
    this.setState({ isResultDrawerOpen: !this.state.isResultDrawerOpen });
  };
  onTaskInComplete = ({ StudentId, SurveyId }) => {
    let data = {
      studentId: StudentId,
      surveyId: SurveyId,
    };
    this.props.dispatch(surveyTaskMarkInComplete(data));
  };
  onSelectSurvey = ({ SurveyId, StudentId, IsForm }) => {
    //this.props.dispatch(loadStudentDefaultValues({ studentId: studentId }));
    const me = this;
    const isWebCam = localStorage.getItem('isWebCam') === '0' ? false : true;
    if (isWebCam) {
      Axios.post('Student/LoadStudentDefaultValues', { studentId: StudentId }).then(response => {
        if (response.data.data.isNewImageRequired == 1 && isWebCam) {
          me.props.history.push({
            pathname: '/camera',
            search:
              'surveyId=' +
              SurveyId +
              '&form=' +
              IsForm +
              '&studentId=' +
              StudentId +
              '&ignoreUpdatingPin=' +
              !response.data.data.IsPinValidate +
              '&intake=' +
              true,
          });
        } else if (response.data.data.isFacialAuthRequired) {
          me.props.history.push({
            pathname: '/authentication',
            search:
              'surveyId=' +
              SurveyId +
              '&form=' +
              IsForm +
              '&studentId=' +
              StudentId +
              '&ignoreUpdatingPin=' +
              !response.data.data.IsPinValidate +
              '&intake=' +
              true,
          });
        } else {
          me.props.history.push({
            pathname: '/validate',
            search:
              'surveyId=' +
              SurveyId +
              '&form=' +
              IsForm +
              '&studentId=' +
              StudentId +
              '&ignoreUpdatingPin=' +
              !response.data.data.IsPinValidate +
              '&intake=' +
              true,
          });
        }
      });
    } else {
      me.props.history.push({
        pathname: '/validate',
        search:
          'surveyId=' +
          SurveyId +
          '&form=' +
          IsForm +
          '&studentId=' +
          StudentId +
          '&intake=' +
          true,
      });
    }
  };
  render() {
    const { PreItakeSurveyId, ClientId, SurveysList, isFetching } = this.props;

    return (
      <React.Fragment>
        <PageTitle TitleText="Surveys" />
        <div class="main students-page mb-5">
          <div className="row intake-survey-list">
            <Loader2 loading={isFetching} />
            <div className="col-12">
              <div className="pt-2">
                <h6
                  style={{
                    fontSize: '18px',
                    color: '#182838',
                    fontWeight: '700',
                  }}
                >
                  Surveys
                </h6>
              </div>
            </div>
            {SurveysList &&
              SurveysList.map((item, index) => {
                const surveyStatus = {
                  '0': {
                    color: '#f8f8f8',
                    hide: false,
                    date: item.DueDate,
                    name: 'Due',
                    // dateFontColor: DueDateFontColor,
                  },
                  '1': {
                    color: 'rgb(239, 247, 254)',
                    hide: true,
                    date: item.CompletedDate,
                    name: 'Completed',
                    // sendRequest: true,
                  },
                };
                return (
                  <div className="col-12" key={index}>
                    <div className="survey-item">
                      <div className="survey-info">
                        <span className="name">{item.SurveyName}</span>
                        <div className="d-flex">
                          <div className="date">
                            <span className="text">Assigned : </span>
                            <span className="value"> {item.AssignedDate}</span>
                          </div>
                          <div className="date">
                            <span className="text">{surveyStatus[item.SurveyStatus].name}</span>
                            <span className="value"> {surveyStatus[item.SurveyStatus].date}</span>
                          </div>
                        </div>
                      </div>

                      <div class="actions-button justify-content-end">
                        {item.SurveyStatus === 0 && (
                          <>
                            <CustomModal
                              ModalButton={({ onClick }) => (
                                <Tooltip placement="top" title="Incomplete">
                                  <span class="delete-course" onClick={onClick}>
                                    <i class="fas fa-times" />
                                  </span>
                                </Tooltip>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      &times;
                                    </button>
                                    <h4 class="modal-title col-12">
                                      Do you want to mark this survey as incomplete?
                                    </h4>
                                  </div>

                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      class="btn "
                                      onClick={() => {
                                        onOk();
                                        this.onTaskInComplete(item);
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                            <Tooltip placement="top" title="Complete Survey">
                              <span
                                class="select-course"
                                onClick={() => {
                                  this.onSelectSurvey(item);
                                }}
                              >
                                <i class="select-icon" />
                              </span>
                            </Tooltip>
                            {/* <CustomModal
                              ModalButton={({ onClick }) => (
                                <Tooltip placement="top" title="Complete">
                                  <span class="select-course" onClick={onClick}>
                                    <i class="select-icon" />
                                  </span>
                                </Tooltip>
                              )}
                              ModalContent={({ onOk, onCancel }) => (
                                <div class="modal-content border-0">
                                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2">
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      &times;
                                    </button>
                                    <h4 class="modal-title col-12">
                                      Are you sure you want to mark this as complete?
                                    </h4>
                                  </div>
                                  <div className="modal-body">
                                    <div className="mb-3 row">
                                      <label
                                        for="CourseScore"
                                        className="col-sm-3 col-form-label text-right"
                                      >
                                        Course Score*
                                      </label>
                                      <div className="col-sm-6">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="CourseScore"
                                          // value={this.state.score}
                                          onChange={e => {
                                            let scoreval = e.target.value;
                                            let pat = /^[0-9]{0,2}$/;
                                            // if (scoreval.match(pat) && scoreval > 0) {
                                            //   this.setCourseScore(Number(scoreval) || 0);
                                            // } else {
                                            //   return;
                                            // }
                                          }}
                                        />
                                        <span className="text-red">
                                          Please enter a score to mark this course complete
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary"
                                      data-dismiss="modal"
                                      onClick={onCancel}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      class="btn "
                                      onClick={() => {
                                        if (this.state.score) {
                                          onOk();
                                          // this.onMarkComplete(StudentId, CourseCenterStudentId);
                                        }
                                      }}
                                    >
                                      Yes
                                    </button>
                                  </div>
                                </div>
                              )}
                            /> */}
                          </>
                        )}

                        {item.SurveyStatus === 1 && (
                          <Tooltip placement="top" title="View">
                            <span
                              class="select-course"
                              onClick={() => {
                                this.state.answerRecord = item;
                                this.props.dispatch(
                                  surveyAnswersGet({
                                    studentId: item.StudentId,
                                    surveyId: item.SurveyId,
                                  }),
                                );
                              }}
                            >
                              <img src="images/view.svg" alt="" />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Drawer
          className="custom-drawer"
          title="Survey Response"
          placement="right"
          visible={this.state.isResultDrawerOpen}
          width={800}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          destroyOnClose={true}
          onClose={() => {
            this.toggleDrawer();
            this.props.dispatch(resetSurveyAnswersGet());
          }}
        >
          <SurveyResponseDrawer
            ClientId={this.props.ClientId}
            toggleDrawer={this.toggleDrawer}
            answerRecord={this.state.answerRecord}
            ColData={this.state.surveyanswersdatacol}
            RowData={this.state.surveyanswersdata}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    student_banner,
    student_survey_list_get,
    survey_answers_get,
    survey_task_mark_in_complete,
    survey_assign,
  } = state;
  const { data } = student_banner;
  const { data: listData, isFetching, error } = student_survey_list_get;
  const { data: surveyAssignData } = survey_assign;

  let PreItakeSurveyId = null;
  let SurveysList = null;
  let surveyanswersdata = [];
  let isSurveyMarkInComplete = false;
  let isSurveyAssigned = false;
  if (data) {
    PreItakeSurveyId = data.PreItakeSurveyId;
  }
  if (listData && listData.Surveys) {
    SurveysList = listData.Surveys;
  }
  if (survey_answers_get.data) {
    surveyanswersdata = Object.values(survey_answers_get.data);
  }
  if (survey_task_mark_in_complete && survey_task_mark_in_complete.data) {
    isSurveyMarkInComplete = true;
  }
  if (surveyAssignData && surveyAssignData.success) {
    isSurveyAssigned = true;
  }
  return {
    PreItakeSurveyId,
    SurveysList,
    surveyanswersdata,
    isSurveyMarkInComplete,
    isSurveyAssigned,
    isFetching,
  };
};

export default connect(mapStateToProps)(withRouter(IntakeSurveys));
