import React, { Component, useEffect } from 'react';
import { Field, reduxForm, FieldArray, formValueSelector, reset } from 'redux-form';
import {
  ReduxInput,
  ReduxSelect,
  ReduxToggle,
  ReduxDateInput,
  ReduxAutoComplete,
  ReduxTextarea,
  ReduxCheckbox,
  ReduxPlusMinusInput,
} from '../../../../component/custom-redux-components';
import Validator from '../../../Validation';

const OptionForm = ({
  fields,
  name,
  meta: { touched, error },
  change,
  disable,
  existingRows,
  dispatch,
  answerOptions,
}) => {
  useEffect(() => {
    if (fields && fields.length == 0) {
      return fields.push({});
    }
  }, []);
  return (
    <>
      <div class="row">
        <div class="col-12 text-left pt-1 pb-1">
          {fields.length < 10 && (
            <a class="add-contact" onClick={() => fields.push({})} style={{ marginLeft: 'unset' }}>
              {fields.length >= 1 && (
                <label>
                  <a> + Add Another Option</a>
                </label>
              )}
              {fields.length == 0 && (
                <label>
                  {' '}
                  <a> + Add Option</a>
                </label>
              )}
            </a>
          )}
        </div>
      </div>

      <div class="answer-options-section" style={{ maxHeight: '350px' }}>
        {fields.map((options, index) => {
          return (
            <div class="row mb-3 options-row">
              <div className="col-7">
                <div className="d-flex">
                  <img src="images/icons/checkbox-option.svg" className="pr-2" />
                  <div class="form-group w-100">
                    <Field
                      name={`${options}.option`}
                      type="text"
                      component={ReduxInput}
                      fieldName={'Enter Option'}
                      // disabled={disable}
                      validate={Validator.required}
                    />
                    <label class="placeholder-label">Enter Option*</label>
                  </div>
                </div>
              </div>
              <div className="col-4 mt-3">
                <div class="form-group">
                  <Field
                    className="mr-2"
                    name={`${options}.IsFlagged`}
                    component={ReduxCheckbox}
                    fieldName={'isFlagged'}
                  />
                  <label class="placeholder-label" style={{ top: '5px' }}>
                    Mark as Flagged
                  </label>
                </div>
              </div>
              {fields.length > 1 && (
                <div className="col-1">
                  <a
                    class="delete-contact"
                    onClick={() => fields.remove(index)}
                    style={{ marginLeft: 'unset' }}
                  >
                    <i class="fas fa-trash" />
                  </a>
                </div>
              )}
              {/* <div className="col-12">
                <div class="form-group">
                  <label class="custom-control-label">
                    <Field
                      name={`${options}.IsFollowUpQuestion`}
                      type="text"
                      class="custom-control-input not-empty"
                      component={ReduxToggle}
                    />
                    {'   '}
                    Add Follow Up Question
                  </label>
                </div>
              </div> */}
            </div>
          );
        })}
      </div>
    </>
  );
};
class MultiSelectMultipleChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { name } = this.props;
    return (
      // <div class="col-12">
      //   <div class="form-group mb-1">
      //     <Field name={`Question1`} type="text" component={ReduxInput} fieldName={'Question'} />
      //     <label class="placeholder-label">Enter Question</label>
      //   </div>
      // </div>
      <FieldArray name={name} component={OptionForm} />
    );
  }
}
export default MultiSelectMultipleChoice;
