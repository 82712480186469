import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import { Popover, Button } from 'antd';
import renderHTML from 'react-render-html';
// import { suspiciousTermAck } from '../../../store/actions';
// import CustomModal2 from '../../../core/modal';
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // acknowledgeFunction = (AckSubOption, ChatGuid) => {
  //   this.props.dispatch(
  //     suspiciousTermAck({
  //       Json: JSON.stringify({
  //         Acknowledge: [
  //           {
  //             ChatGuid: ChatGuid,
  //             AckSubOption: AckSubOption,
  //           },
  //         ],
  //       }),
  //     }),
  //   );
  // };

  // toggle = header => {
  //   this.setState(prevState => ({
  //     isModalOpen: !prevState.isModalOpen,
  //     header,
  //   }));
  // };

  componentWillReceiveProps({ isAcknowledged }) {
    // if (isAcknowledged && isAcknowledged != this.props.isAcknowledged) {
    //   // this.setState(
    //   //   {
    //   //     msg: 'Acknowledged Successfully',
    //   //   },
    //   //   () => {
    //   //     setTimeout(() => {
    //   //       this.setState({
    //   //         msg: '',
    //   //       });
    //   //     }, 2000);
    //   //   },
    //   // );
    //   this.setState({ msg: 'Acknowledged Successfully' });
    //   this.props.onCancel2();
    //   this.toggle('Acknowledged Successfully');
    // }
  }
  render() {
    const { EventJsonData, onCancel } = this.props;
    const { Chat } = EventJsonData || {};
    return (
      <div class="border-0">
        <div class="row">
          <span style={{ fontSize: '16px', color: 'green' }}>
            {' '}
            {this.state.msg && this.state.msg}
          </span>

          <div className="col-12" style={{ overflowY: 'scroll', height: '500px' }}>
            {Chat &&
              Chat.map(item => {
                const {
                  // ChatGuid,
                  // FromUser,
                  // FromUserBadgeId,
                  // FromUserProfileImgUrl,
                  // Msg,
                  MSG,
                  // ReceivedDate,
                  // ToUser,
                  // ToUserBadgeId,
                  // ToUserProfileImgUrl,
                  // Phrases,
                  // IsAck,
                } = item || {};
                // let terms = Msg.match(Phrases);

                // let searchedTerms = Msg.replace(/Welcome|Glad/gi, function(x) {
                //   let terms = x.fontcolor('red');
                //   return terms;
                // });
                // let searchedTerms = Msg.replace(
                //   // `//${Phrases}//gi`,
                //   Phrases,
                //   `<span style="color:red; background:#FFD5D5;padding:2px;margin:0px 2px;border-radius:4px;cursor:pointer;">${Phrases}</span>`,
                // );
                // let searchedTerms = Msg.replace(
                //   /Welcome|Glad/gi,
                //   `<span style="color:white; background:#FF7979;padding:2px;margin:0px 2px;border-radius:4px;cursor:pointer;" ${(onclick = this
                //     .myFunction)}>Welcome1</span>`,
                // );
                return (
                  <div className="task-box" style={{ backgroundColor: '#f8f8f8' }}>
                    <div className="row no-gutters w-100 justify-content-between align-items-center">
                      <div className="col-md-12">
                        <p style={{ color: '#828282', fontSize: '16px' }}>
                          {/* {Msg}
                              <br /> */}
                          {renderHTML(MSG)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
// const mapStateToProps = ({ suspicious_term_ack }) => {
//   var { data, isFetching, error } = suspicious_term_ack;

//   let isAcknowledged = false;

//   if (data && data.success) {
//     isAcknowledged = true;
//   }

//   return {
//     isAcknowledged: isAcknowledged,
//     isFetching,
//   };
// };
export default connect()(Chat);
