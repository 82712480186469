import React from 'react';
import { Tabs, Modal, Table, Tooltip, Tag, Badge } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import Loader, { Loader2 } from '../../../Components/Loader';
import { getDashboardDailyCaseLoad } from '../../../store/actions';
import PersistTableSort from '../../../component/persistTableSort';

class CaseLoad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(
      getDashboardDailyCaseLoad({
        json: JSON.stringify({
          Client: [{ Search: '', FilterBy: 'Active' }],
        }),
      }),
    );
  }
  componentWillReceiveProps({ PractitionerValue }) {
    if (PractitionerValue && PractitionerValue != this.props.PractitionerValue) {
      this.props.dispatch(
        getDashboardDailyCaseLoad({
          Json: JSON.stringify({
            Client: [
              {
                PractitionerId: PractitionerValue,
                Search: '',
                FilterBy: 'Active',
              },
            ],
          }),
        }),
      );
    }
  }
  render() {
    const tempColumns = [
      {
        title: 'Patient ID',
        // dataIndex: 'ClientId',
        dataIndex: 'DisplayClientId',
        key: 'ClientId',
        onCell: (record, rowIndex) => {
          if (record.HaveprofileAccess) {
            if (record && record.IsSmartContractAvailable) {
              return {
                onClick: event => {
                  this.props.history.push(
                    '/cw/patient-management/patient-profile?tab=smart-contract&pId=' +
                      record.ClientId,
                  );
                },
              };
            } else {
              return {
                onClick: event => {
                  this.props.history.push(
                    '/cw/patient-management/patient-profile?tab=demographic&pId=' + record.ClientId,
                  );
                },
              };
            }
          
          } else return;
        },
        render: (text, record) => {
          return (
            <>
              {record.HaveprofileAccess ? (
                <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>{text}</span>
              ) : (
                <span>{text}</span>
              )}
            </>
          );
        },
        sorter: (a, b) => isNumericComparer(a.LastUASort, b.LastUASort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Patient Name / Group',
        dataIndex: 'ClientName',
        key: 'ClientName',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              // this.onRowClick(event, record, rowIndex);
                if (record && record.IsSmartContractAvailable) {
                 this.props.history.push(
                   '/cw/patient-management/patient-profile?tab=smart-contract&pId=' +
                     record.ClientId,
                 );
                } else {
                   this.props.history.push(
                     '/cw/patient-management/patient-profile?tab=demographic&pId=' +
                       record.ClientId,
                   );
                }
            },
          };
        },
        render: (text,record) => {
          return (
            <>
              <span style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '15px' }}>
                {text}
              </span>
              &nbsp;
              {record && record.IsSmartContractAvailable ? (
                <Tooltip placement="top" title="Smart Contract Available">
                  <Tag color="success">SC</Tag>
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          );
        },
        sorter: (a, b) => isComparer(a.ClientName, b.ClientName),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Appointment Type',
        dataIndex: 'AppointmentType',
        key: 'AppointmentType',
        sorter: (a, b) => isComparer(a.AppointmentType, b.AppointmentType),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Admission Date',
        dataIndex: 'AdmissionDate',
        key: 'AdmissionDate',
        sorter: (a, b) => isDateComparer(a.AdmissionDateSort, b.AdmissionDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Authorization Review Date',
        dataIndex: 'AuthReviewDate',
        key: 'AuthReviewDate',
        sorter: (a, b) => isDateComparer(a.AuthReviewDateForSort, b.AuthReviewDateForSort),
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: 'Program Level',
      //   dataIndex: 'ProgramLevel',
      //   key: 'ProgramLevel',
      //   sorter: (a, b) => isComparer(a.ProgramLevel, b.ProgramLevel),
      //   sortDirections: ['descend', 'ascend'],
      // },
      {
        title: 'Last UA',
        dataIndex: 'LastUA',
        key: 'LastUA',
        sorter: (a, b) => isDateComparer(a.LastUASort, b.LastUASort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Last Service Date',
        dataIndex: 'LastServiceDate',
        key: 'LastServiceDate',
        sorter: (a, b) => isDateComparer(a.LastServiceDateSort, b.LastServiceDateSort),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Next Appointment Date',
        dataIndex: 'NextAppointmentDate',
        key: 'NextAppointmentDate',
        sorter: (a, b) => isDateComparer(a.NextAppointmentDateSort, b.NextAppointmentDateSort),
        sortDirections: ['descend', 'ascend'],
      },

      //   {
      //     title: 'Duration',
      //     dataIndex: 'DurationInMin',
      //     key: 'DurationInMin',
      //     sorter: (a, b) => isComparer(a.DurationInMin, b.DurationInMin),
      //     sortDirections: ['descend', 'ascend'],
      //     render: (text, record) => {
      //       return (
      //         <div className="">
      //           <span className={record.IsBold ? 'font-weight-bold' : ''}>
      //             {text && timeConvert(text)}
      //           </span>
      //         </div>
      //       );
      //     },
      //   },
    ];
    const { ListData, isFetching } = this.props;
    return (
      <div class="row">
        <Loader2 loading={isFetching} />
        <div className="col-12">
          <h5>caseload</h5>
        </div>
        <div className="col-12 custom-header">
          <PersistTableSort
            name="caseload-list"
            // bordered
            columns={tempColumns}
            dataSource={ListData}
            size={'middle'}
            // scroll={{ x: 2150, y: 380 }}
            pagination={{
              itemRender: (page, type, originalElement) => {
                return (
                  <div className="dataTables_wrapper no-footer">
                    <div className="dataTables_paginate paging_simple_numbers">
                      {type == 'prev' ? (
                        <a className="paginate_button previous">Previous</a>
                      ) : type == 'next' ? (
                        <a className="paginate_button next">Next</a>
                      ) : (
                        <a className="paginate_button ">{page}</a>
                      )}
                    </div>
                  </div>
                );
              },
              showSizeChanger: true,
              // hideOnSinglePage: true,
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ get_dashboard_daily_case_load }) => {
  const { data, isFetching } = get_dashboard_daily_case_load;

  let List = [];

  if (data && data) {
    List = data.data || [];
  }

  return {
    ListData: List,
    isFetching,
  };
};
export default withRouter(connect(mapStateToProps)(CaseLoad));
