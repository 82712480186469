import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getListTimeLine,
  getFilters,
  getStudentTimeLine,
  getClientTimeLine,
  resetStudentTimeLine,
  resetClientTimeLine,
  resetListTimeLine,
  getPatientDetail,
  payerPortalRequestInsert,
  resetPayerPortalRequestInsert,
} from '../../store/actions';
import ViewTranscriptDrawer from './viewTranscriptDrawer';
import AudioComponent from './audioComponent';
import SparkLineComponent from './sparkline';
import PanasScaleChart from './customScreens/PanasScaleChart';
import renderHTML from 'react-render-html';
import CustomModal from '../../Components/CustomModal';
import CustomModal2 from '../../core/modal';
import { Link, withRouter } from 'react-router-dom';
import { Checkbox, Radio, DatePicker, Modal, Drawer, Button, Pagination } from 'antd';
import moment from 'moment';
import { ProcessCombos } from '../../store/comboConfig';
import ProfileHeader from '../customScreens/profileHeader';
import StudentProfileHeader from '../customScreens/studentProfileHeader';
import ClientProfileHeader from '../customScreens/clientProfileHeader';
import { v4 as uuid } from 'uuid';
import {
  ReduxToggle,
  ReduxDateInput,
  ReduxSelect,
  ReduxPhoneInput,
  ReduxInput,
} from '../../component/custom-redux-components';
import Loader, { Loader2 } from '../../Components/Loader';
import TimeTravelEventView from './timeTravelEventView';
import TimeTravelEventModal from './eventModalView';
import { timeConvert } from '../../util/time';
import MultipleCategoryCheckboxAll from '../../Components/multipleCategoryCheckbox';
import NestedCheckbox from '../../Components/nestedCheckBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClientScreening from './customScreens/ClientScreening';
import ProgramAllignment from './customScreens/ProgramAllignment';
import ProspectNote from './customScreens/ProspectNote';
import ViewAnswerDrawer from './customScreens/ViewAnswerDrawer';
import { testNodes } from './constants.js';
import PageTitle from '../../Components/PageTitle';
// import {
//   ReduxToggle,
//   ReduxDateInput,
//   ReduxSelect,
//   ReduxPhoneInput,
//   ReduxInput,
// } from '../../component/custom-redux-components';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const CheckboxGroup = Checkbox.Group;

// const defaultCheckedList = ['Breathalyzer Test Completed'];

class FilterModal extends Component {
  constructor(props) {
    super(props);
    // const { checked, author, filterCourseType, filterCourseTopic, filterNameCourse } = props;
    this.state = {
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      plainOptions: props.timelineDropDown.map(item => {
        return item.Text;
      }),
      selectedCheckBoxArrayValues: [],
      checkedItems: [],
    };
  }

  onChange = checkedList => {
    var selectedCheckBoxArray = this.props.timelineDropDown.filter(function(item) {
      if (checkedList.includes(item.Text)) return item.Value;
    });
    var selectedCheckBoxArrayValues = selectedCheckBoxArray.map(item => {
      return item.Value;
    });
    var data1 = JSON.stringify(selectedCheckBoxArrayValues).replaceAll('[', '');
    var data2 = data1.replaceAll(']', '');
    this.setState({
      checkedList,
      selectedCheckBoxArrayValues: data2,
      indeterminate: !!checkedList.length && checkedList.length < this.state.plainOptions.length,
      checkAll: checkedList.length === this.state.plainOptions.length,
    });
    this.props.updateFilterValues(data2);
  };

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? this.state.plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  resetState = () => {
    sessionStorage.removeItem('timelineFilterMultiCheckboxInteger');
    sessionStorage.removeItem('timelineFilterMultiCheckbox');
    this.setState(
      {
        selectedCheckBoxArrayValues: [],
        checkedItems: [],
      },
      () => {
        this.sendFilters();
      },
    );
  };

  getUserGuid() {
    let url = this.props.location && this.props.location.search.split('=')[1];
    if (!url) {
      url = this.props.location.pathname && this.props.location.pathname.split('/')[2];
    }
    return url;
  }
  getStudentId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentid = param.get('studentid') || param.get('id');
    if (studentid) {
      studentid = parseInt(studentid);
    } else {
      studentid = null;
    }
    return studentid;
  }
  getClientId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let patientid = param.get('patientid') || param.get('pId');
    if (patientid) {
      patientid = parseInt(patientid);
    } else {
      patientid = null;
    }
    return patientid;
  }
  componentDidMount() {
    this.props.dispatch(getFilters({}));
    let getStoredFilter = JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'));
    this.setState({ checkedItems: getStoredFilter || [] });
    let storedValuesArray = JSON.parse(sessionStorage.getItem('timelineFilterValues'));
    if (storedValuesArray && storedValuesArray.length > 0) {
      let result = '';
      for (let i = 0; i < storedValuesArray.length; i++) {
        result += storedValuesArray[i] + ',';
      }

      this.setState({
        // checkedList: JSON.parse(sessionStorage.getItem('timelineFilterTexts')),
        selectedCheckBoxArrayValues: result,
        checkedList: getStoredFilter,
        // defaultCheckedList: JSON.parse(sessionStorage.getItem('timelineFilterTexts')),
      });
    }
  }
  getCheckItems = (data, index) => {
    let checkedItems = {
      ...this.state.checkedItems,
      [index]: data,
    };
    this.setState({ checkedItems });
  };
  sendFilters = () => {
    this.props.resetTimelineList();
    if (Object.keys(this.state.checkedItems).length > 0) {
      let keys = Object.keys(this.state.checkedItems);
      let filterValue = [];
      keys.map(item => {
        let childrenData = this.props.filtersData[parseInt(item)].children;
        let selectedItems = this.state.checkedItems[parseInt(item)];
        childrenData.filter(item => {
          if (selectedItems.includes(item.label)) {
            filterValue.push(item.value);
            return item.value;
          }
        });
      });
      if (this.getStudentId()) {
        let data = {
          TimeLine: [
            {
              FilterBy: filterValue.toString(),
              StartDate: this.props.StartDate,
              EndDate: this.props.EndDate,
              StudentId: this.getStudentId(),
            },
          ],
        };

        this.props.dispatch(
          getListTimeLine({
            Json: JSON.stringify(data),
          }),
        );
      } else if (this.getClientId()) {
        if (this.props.isPayer) {
          this.props.dispatch(
            payerPortalRequestInsert({
              JSON: JSON.stringify({
                Request: [
                  {
                    ClientId: this.getClientId(),
                    Notes: this.props.Notes || null,
                    ClientAuthorizationId: this.props.ClientAuthorizationId || null,
                    TimelineEventTypeIds: filterValue.toString(),
                  },
                ],
              }),
            }),
          );
        }
        let data = {
          TimeLine: [
            {
              FilterBy: filterValue.toString(),
              StartDate: this.props.StartDate,
              EndDate: this.props.EndDate,
              ClientId: this.getClientId(),
            },
          ],
        };

        this.props.dispatch(
          getListTimeLine({
            Json: JSON.stringify(data),
          }),
        );
      } else {
        let data = {
          TimeLine: [
            {
              FilterBy: filterValue.toString(),
              StartDate: this.props.StartDate,
              EndDate: this.props.EndDate,
              UserGuid: this.getUserGuid(),
            },
          ],
        };

        this.props.dispatch(
          getListTimeLine({
            Json: JSON.stringify(data),
          }),
        );
      }

      sessionStorage.setItem('timelineFilterMultiCheckboxInteger', filterValue.toString());
      sessionStorage.setItem(
        'timelineFilterMultiCheckbox',
        JSON.stringify(this.state.checkedItems),
      );
      this.props.updateFilterValues(filterValue.toString());
      this.props.openAndCloseModal();
    } else {
      if (this.getStudentId()) {
        this.props.dispatch(
          getListTimeLine({
            Json: JSON.stringify({
              TimeLine: [
                {
                  FilterBy: null,
                  StartDate: this.props.StartDate,
                  EndDate: this.props.EndDate,
                  StudentId: this.getStudentId(),
                },
              ],
            }),
          }),
        );
      } else if (this.getClientId()) {
        this.props.dispatch(
          getListTimeLine({
            Json: JSON.stringify({
              TimeLine: [
                {
                  FilterBy: null,
                  StartDate: this.props.StartDate,
                  EndDate: this.props.EndDate,
                  ClientId: this.getClientId(),
                },
              ],
            }),
          }),
        );
      } else {
        this.props.dispatch(
          getListTimeLine({
            Json: JSON.stringify({
              TimeLine: [
                {
                  FilterBy: null,
                  StartDate: this.props.StartDate,
                  EndDate: this.props.EndDate,
                  UserGuid: this.getUserGuid(),
                },
              ],
            }),
          }),
        );
      }

      this.props.openAndCloseModal();
      // return;
    }
  };

  render() {
    const {
      onOk,
      onCancel,
      Centers,
      refreshGrid,
      CourseType,
      CourseTopic,
      NameCourse,
      timelineDropDown,
      isPayer,
    } = this.props;
    const { checked, author, filterCourseType, filterCourseTopic, filterNameCourse } = this.state;

    return (
      <div class="modal-content border-0">
        <div class="modal-header flex-wrap border-bottom-0 ">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            // onClick={onCancel}
            onClick={this.props.openAndCloseModal}
          >
            ×
          </button>
          <h4 class="modal-title col-12 text-left">
            {isPayer ? 'Request More Information' : 'Filter by Events'}
          </h4>
        </div>
        <div class="modal-body pb-2">
          <div class="row m-0 filter-modal">
            {/* <div class="col-12 text-left mb-3">
              <span className="filter-status">Events</span>
            </div> */}
            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-auto mr-2 mb-2 event-list" style={{ width: '100%' }}>
                  <div className={isPayer ? 'payer timetravel-filter' : 'timetravel-filter'}>
                    {/* <div
                    // style={{ borderBottom: '1px solid #E9E9E9' }}
                    >
                      <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                      >
                        Check all
                      </Checkbox>
                    </div> */}
                    {this.props.filtersData &&
                      this.props.filtersData.map((item, index) => {
                        return (
                          <MultipleCategoryCheckboxAll
                            data={item}
                            index={index}
                            getCheckItems={this.getCheckItems}
                            defaultValue={
                              sessionStorage.getItem('timelineFilterMultiCheckbox') &&
                              JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[
                                index
                              ] &&
                              JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[
                                index
                              ].length > 0
                                ? JSON.parse(sessionStorage.getItem('timelineFilterMultiCheckbox'))[
                                    index
                                  ]
                                : []
                            }
                          />
                        );
                      })}
                    {/* Commented for NestedCheckbox */}
                    {/* <NestedCheckbox data={testNodes} /> */}
                    <br />
                    {/* <CheckboxGroup
                      options={this.state.plainOptions}
                      value={this.state.checkedList}
                      onChange={this.onChange}
                      class="event-list-group"
                      // defaultValue={this.state.checkedList}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!this.props.isFetching && (
          <div class="modal-footer  border-top-0 pb-4 pt-2">
            {!isPayer && (
              <a
                class="link2 font-weight-bold mr-2"
                onClick={() => {
                  this.resetState();
                }}
              >
                Reset
              </a>
            )}
            <button
              className="btn "
              onClick={this.sendFilters}
              // disabled={this.state.checkedItems && this.state.checkedItems.length <= 0}
            >
              {isPayer ? 'Send Request' : 'Apply Filter'}
            </button>
            {/* <button
            type="button"
            class="btn"
            onClick={() => {
              const { selectedCheckBoxArrayValues } = this.state;

              let data = {
                TimeLine: [
                  {
                    FilterBy: selectedCheckBoxArrayValues,
                    StartDate: this.props.StartDate,
                    EndDate: this.props.EndDate,
                    UserGuid: this.getUserGuid(),
                  },
                ],
              };

              var selectedCheckBoxArray1 = this.props.timelineDropDown.filter(function(item) {
                if (
                  selectedCheckBoxArrayValues &&
                  selectedCheckBoxArrayValues.includes(JSON.stringify(item.Value))
                )
                  return item.Text;
              });
              let timelineFilterTexts = selectedCheckBoxArray1.map(item => item.Text);
              let timelineFilterValues = selectedCheckBoxArray1.map(item => item.Value);

              // var selectedCheckBoxArrayValues = selectedCheckBoxArray.map(item => {
              //   return item.Value;
              // });
              sessionStorage.setItem('timelineFilterTexts', JSON.stringify(timelineFilterTexts));
              sessionStorage.setItem('timelineFilterValues', JSON.stringify(timelineFilterValues));

              this.props.dispatch(
                getListTimeLine({
                  Json: JSON.stringify(data),
                }),
              );
              this.props.openAndCloseModal();
              // onOk();
              // refreshGrid(checked, author, filterCourseType, filterCourseTopic, filterNameCourse);
            }}
          >
            Apply
          </button> */}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToChildrean = ({ get_filters }) => {
  var { data, isFetching } = get_filters;
  let filtersData;
  if (data) {
    filtersData = data.TimelineEventType;
    //commented for nestedCheckbox
    // filtersData = testData.TimelineEventType;
  }
  return {
    filtersData,
    isFetching,
  };
};
const FilterModalWithRouter = connect(mapStateToChildrean)(withRouter(FilterModal));
var timer;

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StartDate: null,
      EndDate: null,
      eventTriggerModal: false,
      selectedCheckBoxArrayValuesParent: '',
      ListArray: [],
      hasMoreData: true,
      isPayer: false,
      isReqModalOpen: false,
    };
  }

  resetTimelineList = () => {
    this.state.ListArray = [];
  };
  openAndCloseModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  updateFilterValues = checkboxValues => {
    this.state.selectedCheckBoxArrayValuesParent = checkboxValues;
  };

  getUserGuid() {
    // return this.props.location.search.split('=')[1];
    let param = new URLSearchParams(this.props.history.location.search);
    let userguid =
      param.get('subscriberId') ||
      this.props.history.location.pathname.replace('/subscriberProfile/', '');
    if (userguid) {
      userguid = userguid === '/client-timetravel/' ? null : userguid;
    } else {
      userguid = null;
    }
    return userguid;
  }
  getStudentId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let studentid = param.get('id') || param.get('studentid');
    if (studentid) {
      studentid = parseInt(studentid);
    } else {
      studentid = null;
    }
    return studentid;
  }
  getClientId() {
    let param = new URLSearchParams(this.props.history.location.search);
    let patientid = param.get('patientid') || param.get('pId');
    if (patientid) {
      patientid = parseInt(patientid);
    } else {
      patientid = null;
    }
    return patientid;
  }

  onSearchChange = value => {
    let param = new URLSearchParams(this.props.history.location.search);
    let isClinicalRoute = param.get('patientid') || param.get('pId') ? true : false;
    let userGuid = this.getUserGuid();
    if (isClinicalRoute || userGuid.includes('/')) {
      userGuid = null;
    } else {
      userGuid = userGuid;
    }
    this.props.dispatch(
      getListTimeLine({
        json: JSON.stringify({
          TimeLine: [
            {
              UserGuid: userGuid,
              StudentId: this.getStudentId(),
              ClientId: this.getClientId(),
              SearchStr: value,
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
              // searchStr: value,
            },
          ],
        }),
      }),
    );
    this.setState({
      searchStr: value,
      ListArray: [],
    });
  };
  fnDebounce = value => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.onSearchChange(value);
    }, 300);
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  showAnswerDrawerDrawer = (SurveyId, DrawerEventJsonData, SurveyType, DrawerJsonData) => {
    this.setState({
      visibleAnswerDrawer: true,
      SurveyId: SurveyId,
      DrawerEventJsonData: DrawerEventJsonData,
      SurveyType: SurveyType,
      DrawerJsonData: DrawerJsonData && JSON.parse(DrawerJsonData),
    });
  };

  onAnswerDrawerClose = () => {
    this.setState({
      visibleAnswerDrawer: false,
      SurveyId: null,
      DrawerEventJsonData: null,
      DrawerJsonData: null,
      SurveyType: null,
    });
  };
  onEventClose = () => {
    this.setState({
      eventTriggerModal: false,
    });
  };

  handleChange = (key, date) => {
    // const { selectedCheckBoxArrayValuesParent } = this.state;
    // this.setState({
    //   StartDate: date[0],
    //   EndDate: date[1],
    //   ListArray: [],
    // });
    this.state.StartDate = date[0];
    this.state.EndDate = date[1];
    this.state.ListArray = [];
    this.getData();
    // let data = {
    //   TimeLine: [
    //     {
    //       UserGuid: this.getUserGuid(),
    //       FilterBy: selectedCheckBoxArrayValuesParent ? selectedCheckBoxArrayValuesParent : null,
    //       StartDate: date[0],
    //       EndDate: date[1],
    //     },
    //   ],
    // };

    // this.props.dispatch(
    //   getListTimeLine({
    //     Json: JSON.stringify(data),
    //   }),
    // );
  };

  getData = LastEventDateTime => {
    let param = new URLSearchParams(this.props.history.location.search);
    let isClinicalRoute = param.get('patientid') || param.get('pId') ? true : false;

    let userGuid = this.props.StudentId ? null : this.getUserGuid();
    this.props.dispatch(
      getListTimeLine({
        Json: JSON.stringify({
          TimeLine: [
            {
              UserGuid: isClinicalRoute ? null : userGuid,
              StudentId: this.props.StudentId || this.getStudentId(),
              ClientId: this.getClientId(),
              FilterBy: sessionStorage.getItem('timelineFilterMultiCheckboxInteger'),
              LastEventDateTime: LastEventDateTime,
              StartDate: this.state.StartDate,
              EndDate: this.state.EndDate,
              ProspectTimeline: this.props.ProspectTimeline,
            },
          ],
        }),
      }),
    );
  };

  setTTFilterFromUrl = () => {
    //Sample Urls =>TTFilter will be the filter value and TTItem will be what you want to check in UI
    //you can use , after every filter name to seprate them(0_Suspicious%Terms,1_Chat).you can use : for same category filter.% is used for mention space in actual text.
    //you need to create this urls from whereever you want to apply filters.
    //  (`/timetravel/?subscriberId=${UserGuid}&TTFilter=25&TTItem=0_Suspicious%Terms:Video%Checkin,1_Chat`);
    let param = new URLSearchParams(this.props.history.location.search);
    //Code used for setting tt filter
    let ttFilter = param.get('TTFilter');

    if (ttFilter) {
      sessionStorage.setItem('timelineFilterMultiCheckboxInteger', ttFilter);
    }
    let TTItem = param.get('TTItem');
    //Code used for checking checkboxses in filter ui.

    if (TTItem) {
      let key;
      let value;
      TTItem = TTItem.split(',').map(item => {
        key = item.split('_')[0];
        value = item.split('_')[1];
        value = value.replaceAll('%', ' ');
        value = value.replaceAll(':', ',');
        value = value.split(',');
        return value;
      });
      // sessionStorage.setItem(
      //   'timelineFilterMultiCheckbox',
      //   JSON.stringify(Object.assign({}, TTItem)),
      // );
      sessionStorage.setItem('timelineFilterMultiCheckbox', JSON.stringify({ [key]: value }));
    }
  };

  componentDidMount() {
    this.setTTFilterFromUrl();
    this.props.dispatch(getFilters({}));
    this.setState({
      isPayer: this.props.Ispayer || false,
    });
    window.scrollTo(0, 0);
    // 569F657E-E7D9-4D89-B272-75FE90A8B3DF
    // this.props.dispatch(getListTimeLine({}));
    this.getData();
    var result = ProcessCombos('TimeLine');
    if (result.isProcess) {
      this.props.dispatch(result.processItems);
    }
    if (this.getClientId() && !this.props.Ispayer) {
      this.props.dispatch(
        getPatientDetail({
          json: JSON.stringify({
            Client: [
              {
                ClientId: this.getClientId(),
              },
            ],
          }),
        }),
      );
    }
  }

  componentWillReceiveProps({
    initDates,
    isAcknowledged,
    eventListArray,
    listOfFilters,
    isReqSuccess,
  }) {
    if (initDates && JSON.stringify(initDates) != JSON.stringify(this.props.initDates)) {
      this.setState({
        StartDate: initDates.StartDate,
        EndDate: initDates.EndDate,
      });
    }
    if (isAcknowledged && isAcknowledged != this.props.isAcknowledged) {
      this.setState({
        eventTriggerModal: false,
      });

      this.toggle('Suspicious Term Acknowledged Successfully');
    }
    if (
      eventListArray &&
      JSON.stringify(eventListArray) != JSON.stringify(this.props.eventListArray)
    ) {
      let newData = [...this.state.ListArray, ...eventListArray];
      this.setState({ ListArray: newData, hasMoreData: true });
    } else if (eventListArray && eventListArray.length === 0) {
      this.setState({ hasMoreData: false });
    }

    if (
      listOfFilters &&
      JSON.stringify(listOfFilters) != JSON.stringify(this.props.listOfFilters)
    ) {
      //for future use
    }
    if (isReqSuccess && isReqSuccess != this.props.isReqSuccess) {
      this.setState({
        isReqModalOpen: true,
      });
      this.props.dispatch(resetPayerPortalRequestInsert({}));
    }
  }
  componentWillUnmount() {
    // this.props.dispatch(resetStudentTimeLine());
    // this.props.dispatch(resetClientTimeLine());
    this.props.dispatch(resetListTimeLine());
  }
  toggle = header => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      header,
    }));
  };
  fetchMoreData = LastEventDateTime => {
    this.getData(LastEventDateTime);
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    // let data = this.props.eventListArray.slice(this.state.minValue, this.state.maxValue);
    // // let completelist = this.state.ListArray.length >= this.props.eventListArray.length;

    // setTimeout(() => {
    //   this.setState({
    //     ListArray: this.state.ListArray.concat(data),
    //     minValue: this.state.minValue + 5,
    //     maxValue: this.state.maxValue + 5,
    //   });
    // }, 1500);
  };
  render() {
    const { eventListArray, isFetching, isReqSuccess } = this.props;
    const {
      StartDate,
      EndDate,
      FilterBy,
      eventTriggerModal,
      ListArray,
      hasMoreData,
      isPayer,
    } = this.state;
    return (
      <>
        <PageTitle TitleText="Event Time Travel" />
        <div class="main students-page mb-5">
          <div class={isPayer ? null : 'container-fluid'}>
            {!this.props.hideHeader && (
              <div class="col-12" style={{ padding: '0' }}>
                {/* <a onClick={() => this.props.history.goBack()} class="back-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    class="back-arrow"
                  >
                    <path
                      fill="#000"
                      fill-rule="nonzero"
                      d="M14.2 25.956a.886.886 0 0 1-.63-.261l-7.79-7.81a2.67 2.67 0 0 1 0-3.77l7.79-7.81a.889.889 0 0 1 1.259 1.256l-7.791 7.81a.89.89 0 0 0-.001 1.258l7.792 7.81a.889.889 0 0 1-.63 1.517zM27.755 16a.889.889 0 0 0-.89-.889H10.733a.889.889 0 1 0 0 1.778h16.135a.889.889 0 0 0 .889-.889z"
                    />
                  </svg>{' '}
                  Back
                </a> */}
                <a onClick={() => this.props.history.goBack()} class="back-link d-flex">
                  <img src="images/back-arrow.svg" class="mr-2" /> Back
                </a>
              </div>
            )}

            {/* {this.getUserGuid() && <ProfileHeader />} */}
            {this.props.hideHeader ? (
              <></>
            ) : (
              <>
                {' '}
                {this.getStudentId() ? (
                  <StudentProfileHeader />
                ) : this.getClientId() ? (
                  <ClientProfileHeader isTimeline={true} />
                ) : (
                  <ProfileHeader />
                )}
              </>
            )}

            {/* need to add hedaer profile */}
            {/* {this.getClientId() && <div></div>} */}
            <div class="row">
              <div class="col-12 mb-5 pt-3" style={{ position: 'relative' }}>
                <Loader2 loading={ListArray.length <= 0 && isFetching} />
                <div class="card border-0 ">
                  <div class="row card-body">
                    <div class="col-12">
                      <h5 class="text-uppercase pt-2">Events Time Travel</h5>
                    </div>
                    <div className="col-12">
                      <div className="align-items-center d-flex justify-content-end flex-wrap">
                        {/* className={isPayer ? 'col-sm-6 col-xl-5' : 'col-sm-6 col-xl-5'} */}
                        {StartDate && (
                          <div style={{ width: '330px', textAlign: 'right', marginBottom: '10px' }}>
                            <RangePicker
                              // value={[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]}
                              defaultValue={[
                                moment(StartDate, dateFormat),
                                moment(EndDate, dateFormat),
                              ]}
                              onChange={this.handleChange}
                              className="range-picker w-75 text-left"
                              format={['MM-DD-YYYY', 'MM/DD/YYYY']}
                              bordered={false}
                              showToday
                              // separator="to"
                              allowClear={false}
                              suffixIcon={<i class="far fa-calendar-alt form-control-icon" />}
                            />
                          </div>
                        )}
                        <div className="search-box d-inline-flex w-100 mx-2">
                          <div className="form-group has-search w-100 d-flex align-items-center mb-0">
                            <input
                              type="text"
                              class="form-control 
                   shadow-sm rcm-document-search"
                              placeholder="Search"
                              id="filterbox"
                              onKeyUp={event => {
                                this.fnDebounce(event.target.value);
                              }}
                              style={{ minHeight: '46px' }}
                            />
                            <button class="btn search-icon " type="button">
                              {' '}
                              <i class="fa fa-search" />{' '}
                            </button>
                          </div>
                        </div>
                        <div className="">
                          <input
                            type="button"
                            onClick={() => {
                              this.setState({ showModal: true });
                            }}
                            class="btn btn-outline-primary px-5"
                            value={this.state.isPayer ? 'Request More Information' : 'More Filters'}
                          />
                          <Modal
                            open={this.state.showModal}
                            footer={null}
                            closable={false}
                            width={700}
                            destroyOnClose={true}
                          >
                            {this.state.showModal && (
                              <FilterModalWithRouter
                                // {...props}
                                timelineDropDown={this.props.timelineDropDown}
                                StartDate={this.state.StartDate}
                                EndDate={this.state.EndDate}
                                updateFilterValues={this.updateFilterValues}
                                openAndCloseModal={this.openAndCloseModal}
                                resetTimelineList={this.resetTimelineList}
                                isPayer={this.state.isPayer}
                                ClientAuthorizationId={this.props.ClientAuthorizationId}
                                // {...FilterModalData}
                                // refreshGrid={this.refreshGrid}
                                // Centers={Centers}
                                // CourseType={CourseType}
                                // CourseTopic={CourseTopic}
                                // NameCourse={NameCourse}
                              />
                            )}
                          </Modal>
                          {/* <CustomModal
                            destroyOnClose={true}
                            ModalButton={({ onClick }) => (
                              <input
                                type="button"
                                class="btn btn-outline-primary px-5"
                                value="More Filters"
                                onClick={onClick}
                              />
                            )}
                            ModalContent={props => (
                              <FilterModalWithRouter
                                {...props}
                                timelineDropDown={this.props.timelineDropDown}
                                StartDate={this.state.StartDate}
                                EndDate={this.state.EndDate}
                                updateFilterValues={this.updateFilterValues}
                                // {...FilterModalData}
                                // refreshGrid={this.refreshGrid}
                                // Centers={Centers}
                                // CourseType={CourseType}
                                // CourseTopic={CourseTopic}
                                // NameCourse={NameCourse}
                              />
                              // <EventCheckbox />
                            )}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mb-lg-4 timeline ">
                      {ListArray && ListArray.length > 0 ? (
                        <>
                          <div class={isPayer ? null : 'pt-4 px-5'}>
                            <InfiniteScroll
                              style={{ overflow: 'hidden' }}
                              dataLength={this.state.ListArray.length}
                              scrollThreshold="200px"
                              next={() => {
                                let { LastEventDateTime } = ListArray[ListArray.length - 1];
                                this.fetchMoreData(LastEventDateTime);
                              }}
                              hasMore={hasMoreData}
                              loader={<h4>Loading...</h4>}
                              endMessage={
                                <p style={{ textAlign: 'center', marginTop: '15px' }}>
                                  <b>Yay! You have seen it all</b>
                                </p>
                              }
                            >
                              {ListArray.map((item, index) => {
                                const {
                                  EventDateForSort,
                                  EventDate,
                                  EventTime,
                                  LastEventDateTime,
                                } = item;
                                return (
                                  <div className="box" key={uuid()}>
                                    <div className="row align-items-center">
                                      <div className="col-md-3 col-xl-2 text-left">
                                        {EventDate && <div className="date">{EventDate}</div>}
                                      </div>
                                    </div>

                                    {EventTime &&
                                      EventTime.map(event => {
                                        const {
                                          EventTime,
                                          EventTimeForSort,
                                          EventTypeId,
                                          EventTypeName,
                                          EventIcon,
                                          EventTitle,
                                          EventDetail,
                                          EventWarningTags,
                                          EventInfoTags,
                                          EventRowClass,
                                          EventJson,
                                          MiscJson,
                                        } = event;
                                        let DurationSeconds;
                                        let DurationInSeconds;
                                        let BreathalyzerScore;
                                        let ResultClassBlock;
                                        let MoodEmoji;
                                        let MoodEmojiPanas;
                                        let MiscData;
                                        let SparkLineScale;
                                        let PanasScale;
                                        let PanasScaleData;
                                        let ClientScreeningData;
                                        let ProgramAllignmentData;
                                        let ProspectNoteData;
                                        let UpdatedBy;
                                        let AddedBy;

                                        if (EventTypeId === 3) {
                                          let EventJsonData = EventJson && JSON.parse(EventJson);
                                          let MiscJsonData = MiscJson && JSON.parse(MiscJson);
                                          SparkLineScale =
                                            EventJsonData.SurveyUser &&
                                            EventJsonData.SurveyUser[0].SparkLineScale;
                                          MiscData = MiscJsonData && MiscJsonData.SurveyMisc;
                                        }
                                        if (EventTypeId === 5) {
                                          let EventJsonData = EventJson && JSON.parse(EventJson);
                                          DurationInSeconds =
                                            EventJsonData.SmallGroup &&
                                            EventJsonData.SmallGroup[0].DurationInSeconds.toString();
                                        }
                                        if (EventTypeId === 8) {
                                          let EventJsonData = EventJson && JSON.parse(EventJson);
                                          DurationSeconds =
                                            EventJsonData.VMCall &&
                                            EventJsonData.VMCall[0].DurationSeconds;
                                        }
                                        if (EventTypeId === 7) {
                                          let EventJsonData = EventJson && JSON.parse(EventJson);
                                          BreathalyzerScore =
                                            EventJsonData.BreathalyzerTestResult &&
                                            EventJsonData.BreathalyzerTestResult[0].Result;
                                          ResultClassBlock =
                                            EventJsonData.BreathalyzerTestResult &&
                                            EventJsonData.BreathalyzerTestResult[0]
                                              .ResultClassBlock;
                                        }
                                        if (EventTypeId === 14) {
                                          let EventJsonData = EventJson && JSON.parse(EventJson);
                                          let MoodMiscData = MiscJson && JSON.parse(MiscJson);
                                          MoodEmoji =
                                            EventJsonData.Mood && EventJsonData.Mood[0].MoodEmoji;
                                          SparkLineScale =
                                            EventJsonData.Mood &&
                                            EventJsonData.Mood[0].SparkLineScale;
                                          MiscData = MoodMiscData && MoodMiscData.MoodMisc;
                                        }
                                        if (EventTypeId === 26) {
                                          let EventJsonData =
                                            EventJson && JSON.parse(EventJson).Mood;
                                          let MoodMiscData =
                                            MiscJson && JSON.parse(MiscJson).PANASScale;
                                          MoodEmojiPanas =
                                            MoodMiscData &&
                                            MoodMiscData[MoodMiscData.length - 1].MoodEmoji;
                                          PanasScale = EventJsonData;
                                          PanasScaleData = MoodMiscData;
                                        }
                                        if (EventTypeId === 42) {
                                          let SurveyJsonData = EventJson && JSON.parse(EventJson);
                                          ClientScreeningData =
                                            SurveyJsonData && SurveyJsonData.Survey[0];
                                        }
                                        if (EventTypeId === 43) {
                                          let ProgramAllignmentJsonData =
                                            MiscJson && JSON.parse(MiscJson);
                                          ProgramAllignmentData =
                                            ProgramAllignmentJsonData &&
                                            ProgramAllignmentJsonData.Survey;
                                        }

                                        if (EventTypeId === 44) {
                                          // let EventJsonData =
                                          //   EventJson && JSON.parse(EventJson).Note[0];
                                          let NoteJsonData = MiscJson && JSON.parse(MiscJson);
                                          ProspectNoteData = NoteJsonData && NoteJsonData.Note[0];
                                        }
                                        if (EventTypeId === 215) {
                                        }
                                        // headerFlags: [
                                        //   {
                                        //     text: 'Alcohol',
                                        //     uppercase: true,
                                        //     warning: true,
                                        //     icon: true,
                                        //     iconPath: 'images/InterventionComplete.svg',
                                        //   },
                                        // ],

                                        return (
                                          <>
                                            <div
                                              key={uuid()}
                                              className={`row align-items-center mt-2 ${
                                                EventRowClass ? EventRowClass : 'row_default'
                                              }`}
                                            >
                                              <div className="col-md-2 col-lg-1 time">
                                                {EventTime}
                                              </div>
                                              <div className="col-md-2 col-lg-1 d-flex justify-content-center">
                                                <div className="icon">
                                                  <img src={`images/timeline/${EventIcon}`} />
                                                </div>
                                              </div>
                                              <div className="col-md-8 col-lg-10 event-details">
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <div
                                                    className="event-left-item"
                                                    style={{ wordBreak: 'break-all' }}
                                                  >
                                                    <div className="header-content ">
                                                      {' '}
                                                      <span
                                                        style={{
                                                          cursor: EventJson && 'pointer',
                                                        }}
                                                        onClick={e => {
                                                          e.stopPropagation();
                                                          EventJson &&
                                                            this.setState({
                                                              eventTriggerModal: true,
                                                              EventJsonData: JSON.parse(EventJson),
                                                              EventTitle: EventTitle,
                                                              EventTypeName: EventTypeName,
                                                              EventTypeId: EventTypeId,
                                                            });
                                                        }}
                                                      >
                                                        {EventTitle}
                                                      </span>{' '}
                                                      {EventWarningTags && (
                                                        <span
                                                          className="text-uppercase"
                                                          style={{
                                                            backgroundColor: `#FF7979`,
                                                            color: `white`,
                                                            fontSize: '16px',
                                                            borderRadius: '2px',
                                                            padding: '0px 7px',
                                                            marginLeft: '23px',
                                                            display: 'inline-flex',
                                                          }}
                                                        >
                                                          {/* {icon && (
                                                <img
                                                  src={`images/timeline/${iconPath}`}
                                                  style={{ paddingRight: '5px' }}
                                                />
                                              )} */}
                                                          <span>{EventWarningTags}</span>
                                                        </span>
                                                      )}
                                                      {EventInfoTags && (
                                                        <span
                                                          className="text-uppercase"
                                                          style={{
                                                            backgroundColor: `#4facfe`,
                                                            color: `white`,
                                                            fontSize: '16px',
                                                            borderRadius: '2px',
                                                            padding: '0px 7px',
                                                            marginLeft: '23px',
                                                            display: 'inline-flex',
                                                          }}
                                                        >
                                                          {/* {icon && (
                                                <img
                                                  src={`images/timeline/${iconPath}`}
                                                  style={{ paddingRight: '5px' }}
                                                />
                                              )} */}
                                                          <span>{EventInfoTags}</span>
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="desc mt-2">
                                                      {MoodEmoji && (
                                                        <div className="mr-2">
                                                          <img
                                                            src={`images/timeline/emoji/${MoodEmoji}`}
                                                            height="30"
                                                          />
                                                        </div>
                                                      )}
                                                      {MoodEmojiPanas && (
                                                        <div className="mr-2">
                                                          <img
                                                            src={MoodEmojiPanas}
                                                            height="60"
                                                            borderRadius="50%"
                                                          />
                                                        </div>
                                                      )}
                                                      {EventDetail && renderHTML(EventDetail)}
                                                    </div>
                                                    {DurationInSeconds && (
                                                      <div className="desc mt-2">
                                                        <span>
                                                          Small Group Meeting Duration :{' '}
                                                          <strong>
                                                            {timeConvert(
                                                              parseInt(DurationInSeconds),
                                                            )}
                                                          </strong>
                                                        </span>
                                                      </div>
                                                    )}
                                                    {DurationSeconds && (
                                                      <div className="desc mt-2">
                                                        <span>
                                                          Meeting Duration :{' '}
                                                          <strong>
                                                            {timeConvert(DurationSeconds)}
                                                          </strong>
                                                        </span>
                                                      </div>
                                                    )}
                                                    {BreathalyzerScore && (
                                                      <div className="desc mt-2 time-line-table ">
                                                        <span>Breathalyzer Test Result : </span>
                                                        <span className={ResultClassBlock}>
                                                          {' '}
                                                          <strong>{BreathalyzerScore}</strong>
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="event-right-item">
                                                    {MiscData && (
                                                      <SparkLineComponent
                                                        MiscData={MiscData}
                                                        SparkLineScale={SparkLineScale}
                                                      />
                                                    )}
                                                    {PanasScaleData && (
                                                      <PanasScaleChart
                                                        MiscData={PanasScaleData}
                                                        PanasScale={PanasScale}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                {ClientScreeningData && (
                                                  <ClientScreening
                                                    StudentId={
                                                      this.props.StudentId || this.getStudentId()
                                                    }
                                                    EventJsonData={ClientScreeningData}
                                                    MiscJson={MiscJson}
                                                    EventJson={EventJson}
                                                    showAnswerDrawerDrawer={
                                                      this.showAnswerDrawerDrawer
                                                    }
                                                  />
                                                )}
                                                {ProgramAllignmentData && (
                                                  <ProgramAllignment
                                                    StudentId={
                                                      this.props.StudentId || this.getStudentId()
                                                    }
                                                    EventJsonData={ProgramAllignmentData}
                                                    EventJson={EventJson}
                                                    showAnswerDrawerDrawer={
                                                      this.showAnswerDrawerDrawer
                                                    }
                                                  />
                                                )}
                                                {ProspectNoteData && (
                                                  <ProspectNote EventJsonData={ProspectNoteData} />
                                                )}
                                                {/* {EventJson && (
                                            <div className="event-hyperlink">
                                              <span
                                                onClick={e => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    eventTriggerModal: true,
                                                    EventJsonData: JSON.parse(EventJson),
                                                    EventTitle: EventTitle,
                                                    EventTypeName: EventTypeName,
                                                    EventTypeId: EventTypeId,
                                                  });
                                                }}
                                              >
                                                Click here to view
                                              </span>
                                            </div>
                                          )} */}
                                              </div>

                                              {/* <div123 className="col-md-8 event-details">
                                            <div className="header-content ">
                                              {' '}
                                              {header}
                                              {headerFlags.map(headerFlagContent => {
                                                const {
                                                  text,
                                                  uppercase,
                                                  warning,
                                                  icon,
                                                  iconPath,
                                                } = headerFlagContent;
                                                return (
                                                  <span
                                                    className={
                                                      uppercase
                                                        ? `text-uppercase `
                                                        : `text-lowercase`
                                                    }
                                                    style={{
                                                      backgroundColor: warning
                                                        ? `#FF7979`
                                                        : `#E0E0E0`,
                                                      color: warning == true ? `white` : `#515151`,
                                                      fontSize: '16px',
                                                      borderRadius: '2px',
                                                      padding: '0px 7px',
                                                      marginLeft: '23px',
                                                      display: !warning
                                                        ? 'inline-flex'
                                                        : 'inline-block',
                                                    }}
                                                  >
                                                    {icon && (
                                                      <img
                                                        src={`images/timeline/${iconPath}`}
                                                        style={{ paddingRight: '5px' }}
                                                      />
                                                    )}
                                                    <span>{text}</span>
                                                  </span>
                                                );
                                              })}
                                            </div>
                                            <div className="desc">{desc}</div>
                                          </div123>*/}
                                            </div>
                                          </>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        </>
                      ) : (
                        <>
                          <div class="col-12 text-center mb-3 py-5 my-5">
                            <h4>No Time Travel Events</h4>
                          </div>
                        </>
                      )}

                      {this.state.visible && (
                        <Drawer
                          className="custom-drawer timelineDrawer"
                          title="Transcript"
                          placement="right"
                          onClose={this.onClose}
                          visible={this.state.visible}
                          width="700px"
                          closable={true}
                        >
                          <ViewTranscriptDrawer />
                        </Drawer>
                      )}
                      {this.state.visibleAnswerDrawer && (
                        <Drawer
                          className="custom-drawer timelineDrawer"
                          title={this.state.SurveyType ? this.state.SurveyType : 'View Answer'}
                          placement="right"
                          onClose={this.onAnswerDrawerClose}
                          visible={this.state.visibleAnswerDrawer}
                          width="700px"
                          closable={true}
                        >
                          <ViewAnswerDrawer
                            ClientId={this.props.StudentId || this.getStudentId()}
                            PreItakeSurveyId={this.state.SurveyId}
                            DrawerEventJsonData={this.state.DrawerEventJsonData}
                            DrawerJsonData={this.state.DrawerJsonData}
                            SurveyType={this.state.SurveyType}
                          />
                        </Drawer>
                      )}
                      {eventTriggerModal && (
                        // <Drawer
                        //   className="custom-drawer"
                        //   title={this.state.EventTitle}
                        //   placement="right"
                        //   onClose={this.onEventClose}
                        //   visible={this.state.eventTriggerModal}
                        //   width="700px"
                        //   closable={true}
                        //   className="timelineDrawer"
                        // >
                        //   <TimeTravelEventView
                        //     EventJsonData={this.state.EventJsonData}
                        //     EventTypeName={this.state.EventTypeName}
                        //     EventTypeId={this.state.EventTypeId}
                        //   />
                        // </Drawer>

                        <Modal
                          open={eventTriggerModal}
                          footer={null}
                          closable={false}
                          width={800}
                          destroyOnClose={true}
                        >
                          <TimeTravelEventModal
                            EventJsonData={this.state.EventJsonData}
                            EventTitle={this.state.EventTitle}
                            EventTypeName={this.state.EventTypeName}
                            EventTypeId={this.state.EventTypeId}
                            onCancel={() => {
                              this.setState({
                                eventTriggerModal: false,
                              });
                            }}
                            // onCancel2={() => {
                            //   this.setState({
                            //     eventTriggerModal: false,
                            //   });
                            // }}
                            // callDataApi={this.getData}
                          />
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
                {false && <AudioComponent showDrawer={this.showDrawer} />}
              </div>
            </div>
          </div>
          <CustomModal2
            isOpen={this.state.isModalOpen}
            header={this.state.header}
            yesAction={() => {
              this.toggle();
              this.getData();
            }}
            yesText="OK"
            hideCancel={true}
          />
          <CustomModal2
            isOpen={this.state.isPayer && this.state.isReqModalOpen}
            header={'Request Sent Successfully '}
            yesAction={() => {
              this.setState({ isReqModalOpen: false });
              // this.openAndCloseModal();
            }}
            yesText="OK"
            hideCancel={true}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  get_list_timeline,
  combos,
  suspicious_term_ack,
  get_filters,
  get_student_timeLine,
  get_client_timeLine,
  payer_portal_request_insert,
}) => {
  var { data, isFetching, error } = get_list_timeline;
  let { data: requestData } = payer_portal_request_insert;
  // var { data: studentData, isFetching: isFetchingStudentList } = get_student_timeLine;
  // var { data: clientData, isFetching: isFetchingClientList } = get_client_timeLine;
  var { data: sTData, error } = suspicious_term_ack;
  var { data: FilterList } = get_filters;
  let timelineDropDown = [];
  let isAcknowledged = false;
  let initDates;
  let filtersData;
  let onlyCheckboxses;
  let isReqSuccess = false;

  if (sTData && sTData.success) {
    isAcknowledged = true;
  }
  if (requestData && requestData.success) {
    isReqSuccess = requestData.success;
  }

  if (combos.data) {
    const { TimeLine } = combos.data;
    if (TimeLine) {
      timelineDropDown = TimeLine;
    }
  }

  let eventListArray = [];

  if (data && data.Event) {
    eventListArray = data.Event;
    initDates = data.Event[0].EventDateRange[0];
  }
  // if (studentData && studentData.Event) {
  //   eventListArray = studentData.Event;
  //   initDates = studentData.Event[0].EventDateRange[0];
  // }
  // if (clientData && clientData.Event) {
  //   eventListArray = clientData.Event;
  //   initDates = clientData.Event[0].EventDateRange[0];
  // }
  if (FilterList) {
    filtersData = FilterList.TimelineEventType;
    onlyCheckboxses = filtersData.map(item => item.children);
    var listOfFilters = [];

    onlyCheckboxses.forEach((element, index) => {
      listOfFilters = listOfFilters.concat(onlyCheckboxses[index]);
    });
  }
  return {
    eventListArray,
    timelineDropDown,
    initDates,
    isFetching: isFetching,
    isAcknowledged: isAcknowledged,
    listOfFilters,
    isReqSuccess,
  };
};

export default connect(mapStateToProps)(withRouter(Timeline));
