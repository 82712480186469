import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, Drawer, Tabs } from 'antd';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import {} from 'redux-form';

import { isComparer, isDateComparer, isNumericComparer } from '../../../util/handler';
import {
  studentAssociateListGet,
  studentAssociateInsert,
  studentAssociateDelete,
  listCombos,
} from '../../../store/actions';
import Loader, { Loader2 } from '../../../Components/Loader';
import CustomModal from '../../../Components/CustomModal';
// import AddNewAssociation from './AddNewAssociation';
import AddNewAssociation from '../../EnterpriseClient/components/AddNewAssociation';
import { getPhoneNumber, getCountryCalling } from '../../../util/phoneFormatter';
import PersistTableSort from '../../../component/persistTableSort';
import AntdModal from '../../../Components/CustomModal';
import ChartView from './AssociationChart';
import { PopoverMenu } from '../../../Components/PopoverMenu';

const tabName = [
  { tabLabel: 'List View', keyIndex: 1, Content: 'ListView' },
  { tabLabel: 'Chart View', keyIndex: 3, Content: 'ChartView' },
];
const ListView = props => {
  const getPopOverData = record => {
    return (
      <>
        {/* <p
          onClick={() => {
            props.history.push(`/clients/pre-intake/dashboard?tab=summary&id=${record.StudentId}`);
          }}
        >
          View Details
        </p> */}
        <AntdModal
          ModalButton={({ onClick }) => (
            <p
              class="delete-btn"
              onClick={e => {
                onClick();
                e.stopPropagation();
              }}
              data-toggle="modal"
              data-target="#delete-accociation"
              style={{ cursor: 'pointer', color: '#FF3B3B' }}
            >
              Remove from Association
            </p>
          )}
          ModalContent={({ onOk, onCancel }) => (
            <div class="modal-content border-0">
              <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
              <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                <h4 class="modal-title col-12 mb-3">Are you sure you want to delete?</h4>
              </div>
              <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                <button
                  class="btn btn-outline-primary black-btn"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  No
                </button>
                <button
                  class="btn"
                  onClick={e => {
                    e.stopPropagation();
                    onOk();
                    props.deletestudentAssociate(record.ContactId);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          )}
        />
      </>
    );
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'FullName',
      key: 'FullName',
      render: (text, record) => <div>{text}</div>,
      sorter: (a, b) => isComparer(a.FullName, b.FullName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Address',
      dataIndex: 'Address',
      key: 'Address',
      sorter: (a, b) => isComparer(a.Address, b.Address),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
      sorter: (a, b) => isComparer(a.Email, b.Email),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Primary Phone',
      dataIndex: 'PrimaryPhone',
      key: 'PrimaryPhone',
      sorter: (a, b) => isComparer(a.PrimaryPhone, b.PrimaryPhone),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Relationship',
      dataIndex: 'RelationShip',
      key: 'RelationShip',
      sorter: (a, b) => isComparer(a.RelationShip, b.RelationShip),
      sortDirections: ['descend', 'ascend'],
      // render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
    },
    {
      title: 'Action',
      key: 'x',
      dataIndex: 'x',
      render: (text, record) => {
        return <PopoverMenu OptionData={getPopOverData(record)} placement="left" />;
      },
    },
  ];
  return (
    <div class="col-12 ">
      <PersistTableSort
        name="AssociationListView"
        columns={columns}
        dataSource={(props.seriesData[0] && props.seriesData[0].children) || []}
        size={'middle'}
        pagination={{
          itemRender: (page, type, originalElement) => {
            return (
              <div class="dataTables_wrapper no-footer">
                <div class="dataTables_paginate paging_simple_numbers">
                  {type == 'prev' ? (
                    <a class="paginate_button previous">Previous</a>
                  ) : type == 'next' ? (
                    <a class="paginate_button next">Next</a>
                  ) : (
                    <a class="paginate_button ">{page}</a>
                  )}
                </div>
              </div>
            );
          },
          showSizeChanger: true,
        }}
      />
    </div>
  );
};
const components = {
  ListView,
  ChartView,
};
class Associations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesData: false,
    };
  }
  getAssociatListData = () => {
    const { dispatch } = this.props;
    dispatch(
      studentAssociateListGet({
        json: JSON.stringify({
          Student: [
            {
              StudentId: this.props.ClientId,
            },
          ],
        }),
      }),
    );
  };
  componentDidMount() {
    this.getAssociatListData();
    this.props.dispatch(
      listCombos({
        comboTypes: 'RelationShip,SupportGroup',
        json: JSON.stringify({ User: { StudentId: this.props.ClientId } }),
      }),
    );
  }
  componentWillReceiveProps({ isAssociatAdded, isAssociatDeleted, associatList }) {
    if (isAssociatAdded && isAssociatAdded != this.props.isAssociatAdded) {
      this.getAssociatListData();
    }
    if (isAssociatDeleted && isAssociatDeleted != this.props.isAssociatDeleted) {
      this.getAssociatListData();
    }
    if (associatList && JSON.stringify(associatList) != JSON.stringify(this.props.associatList)) {
      this.setState({
        seriesData: associatList,
      });
    }
  }
  onSearch = value => {
    this.props.dispatch(
      listCombos({
        comboTypes: 'RelationShip,SupportGroup',
        json: JSON.stringify({
          User: { StudentId: this.props.ClientId, SearchStr: value },
        }),
      }),
    );
  };
  onhandleSubmit = values => {
    const { dispatch } = this.props;
    const { FirstName, LastName, Phone, Email, Relation, Address, ZipCode } = values;
    let data = {
      Json: JSON.stringify({
        Student: {
          StudentId: this.props.ClientId,
          Contact: [
            {
              FirstName: FirstName,
              LastName: LastName,
              PrimaryPhone: getPhoneNumber(Phone),
              CountryCode: getCountryCalling(Phone),
              Email: Email,
              RelationShipId: Relation,
              Address,
              ZipCode,
            },
          ],
        },
      }),
    };
    dispatch(studentAssociateInsert(data));
    this.onClose();
  };
  handleExistingMember = selectedMembersList => {
    let newDataArray =
      selectedMembersList &&
      Object.values(selectedMembersList).map(item => {
        if (item.isChecked)
          return {
            UserId: item.UserId,
            RelationShipId: item.RelationShipId,
          };
      });
    let data = {
      Json: JSON.stringify({
        Student: {
          StudentId: this.props.ClientId,
          User: newDataArray,
        },
      }),
    };
    if (newDataArray && newDataArray.length > 0) {
      this.props.dispatch(studentAssociateInsert(data));
    }
  };
  deletestudentAssociate = ContactId => {
    this.props.dispatch(
      studentAssociateDelete({
        json: JSON.stringify({
          Student: {
            ContactId: ContactId,
          },
        }),
      }),
    );
  };

  showDrawer = () => {
    this.setState({ open: true });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      isFetching,
      handleSubmit,
      onLastSubmit,
      currentTab,
      tabsCount,
      handleSubmitAndNext,
      goToPreviuos,
      disable,
    } = this.props;
    const { seriesData } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'FullName',
        key: 'FullName',
        render: (text, record) => <div>{text}</div>,
        sorter: (a, b) => isComparer(a.FullName, b.FullName),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Address',
        dataIndex: 'Address',
        key: 'Address',
        sorter: (a, b) => isComparer(a.Address, b.Address),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        key: 'Email',
        sorter: (a, b) => isComparer(a.Email, b.Email),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Primary Phone',
        dataIndex: 'PrimaryPhone',
        key: 'PrimaryPhone',
        sorter: (a, b) => isComparer(a.PrimaryPhone, b.PrimaryPhone),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Relationship',
        dataIndex: 'RelationShip',
        key: 'RelationShip',
        sorter: (a, b) => isComparer(a.RelationShip, b.RelationShip),
        sortDirections: ['descend', 'ascend'],
        // render: text => <div>{text && moment(text).format('DD-MMM-YYYY')}</div>,
      },
      {
        title: 'Action',
        key: 'x',
        dataIndex: 'x',
        render: (text, record, index) => {
          return (
            // <span
            //   style={{ cursor: 'pointer' }}
            //   onClick={() => {
            //     this.deletestudentAssociate(record.ContactId);
            //   }}
            // >
            //   <i class="fas fa-trash" style={{ color: '#e87a7a' }}></i>
            // </span>
            <AntdModal
              ModalButton={({ onClick }) => (
                <span
                  class="delete-btn"
                  onClick={e => {
                    onClick();
                    e.stopPropagation();
                  }}
                  data-toggle="modal"
                  data-target="#delete-Screen-Tool"
                  style={{ cursor: 'pointer' }}
                >
                  <i class="fas fa-trash" style={{ color: '#e87a7a' }}></i>
                </span>
              )}
              ModalContent={({ onOk, onCancel }) => (
                <div class="modal-content border-0">
                  <div class="modal-header justify-content-center flex-wrap border-bottom-0 mb-2" />
                  <div class="modal-body pr-2 pl-2  pr-xl-5 pl-xl-5">
                    <h4 class="modal-title col-12 mb-3">Are you sure you want to delete?</h4>
                  </div>
                  <div class="modal-footer justify-content-center border-top-0 pb-5 pt-2">
                    <button
                      class="btn btn-outline-primary black-btn"
                      onClick={() => {
                        onCancel();
                      }}
                    >
                      No
                    </button>
                    <button
                      class="btn"
                      onClick={e => {
                        e.stopPropagation();
                        onOk();
                        this.deletestudentAssociate(record.ContactId);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            />
          );
        },
        // onCell: (record, rowIndex) => {
        //   return {
        //     onClick: event => this.onManageClick(record.CenterId),
        //   };
        // },
      },
    ];
    return (
      <form
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit(data => {
            handleSubmitAndNext(data, currentTab, tabsCount);
          });
        }}
        style={{ padding: '36px 20px 200px 20px' }}
      >
        <div className="prospect-associations">
          <div className="row mt-3">
            <div className="col-2">
              <h6>Associations</h6>
            </div>
            <div class="col-10" style={{ textAlign: 'end' }}>
              <button type="button" className="btn px-3 mr-2" onClick={this.showDrawer}>
                Add New Association
              </button>
            </div>
          </div>
          <Loader2 loading={isFetching} />

          <div class="row">
            <div class="col-12 text-center">
              {seriesData ? (
                <div className="forms center-tabs">
                  <Tabs defaultActiveKey="1" centered>
                    {tabName &&
                      tabName.map((item, index) => {
                        const { tabLabel, keyIndex, Content } = item;
                        const DynamicComponentConversion = components[Content];
                        return (
                          <Tabs.TabPane tab={tabLabel} key={keyIndex}>
                            {keyIndex == 3 && (
                              <p style={{ textAlign: 'left', fontWeight: '800' }}>
                                Tip : Enhance your experience by dragging and dropping the
                                relationship diagram.
                              </p>
                            )}
                            {
                              <DynamicComponentConversion
                                {...this.props}
                                deletestudentAssociate={this.deletestudentAssociate}
                                seriesData={seriesData}
                              />
                            }
                          </Tabs.TabPane>
                        );
                      })}
                  </Tabs>
                </div>
              ) : (
                // <AssociationChart seriesData={seriesData} />
                <h5>No associations found</h5>
              )}
            </div>
            {/* <div class="col-12 d-none">
            <Table
              columns={columns}
              dataSource={associatList}
              size={'middle'}
              pagination={{
                itemRender: (page, type, originalElement) => {
                  return (
                    <div class="dataTables_wrapper no-footer">
                      <div class="dataTables_paginate paging_simple_numbers">
                        {type == 'prev' ? (
                          <a class="paginate_button previous">Previous</a>
                        ) : type == 'next' ? (
                          <a class="paginate_button next">Next</a>
                        ) : (
                          <a class="paginate_button ">{page}</a>
                        )}
                      </div>
                    </div>
                  );
                },
                showSizeChanger: true,
              }}
            />
          </div> */}
          </div>
          {this.state.open && (
            <Drawer
              className="custom-drawer"
              title="Add New Association"
              placement="right"
              onClose={this.onClose}
              open={this.state.open}
              width="800px"
              bodyStyle={{ backgroundColor: '#fff' }}
              visible={this.state.open}
            >
              <div>
                <AddNewAssociation
                  initialValues={{ memberType: 'new' }}
                  onhandleSubmit={this.onhandleSubmit}
                  handleExistingMember={this.handleExistingMember}
                  StudentId={this.props.ClientId}
                  memberType={this.props.memberType}
                  relationOptions={this.props.relationOptions}
                  supportGroup={this.props.supportGroup}
                  onSearch={this.onSearch}
                  onClose={this.onClose}
                />
              </div>
            </Drawer>
          )}
        </div>
        <div class="row pagination-button">
          <div class="col-12 text-right">
            {currentTab > 0 && (
              <button
                onClick={goToPreviuos}
                type="button"
                class="btn btn-outline-primary btn-eForm-Prev mr-2"
                disabled={disable}
              >
                Previous
              </button>
            )}
            {currentTab + 1 != tabsCount && (
              <button
                type="submit"
                // onClick={handleNext}
                class="btn btn-eForm-Next"
                disabled={disable}
              >
                Next
              </button>
            )}
            {currentTab + 1 == tabsCount && (
              <button type="submit" class="btn btn-eForm-Next" disabled={disable}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default connect(state => {
  const {
    student_associate_insert,
    student_associate_list_get,
    student_associate_delete,
    combos,
  } = state;
  const { data: associateData, isFetching } = student_associate_list_get;
  const { data: associateInsertData, isFetching: isFetchingInsert } = student_associate_insert;
  const { data: associateDeleteData } = student_associate_delete;
  const selector = formValueSelector('AddNewAssociationForm');

  let associatList = [];
  let isAssociatAdded = false;
  let isAssociatDeleted = false;
  let supportGroup = [];
  let relationOptions = [];
  let memberType = selector(state, 'memberType');
  if (associateData && associateData.Associates) {
    associatList = associateData.Associates;
  }
  if (associateInsertData && associateInsertData.success) {
    isAssociatAdded = associateInsertData.success;
  }
  if (associateDeleteData && associateDeleteData.success) {
    isAssociatDeleted = associateDeleteData.success;
  }
  if (combos.data) {
    if (combos.data.RelationShip) {
      relationOptions = combos.data.RelationShip;
    }
    if (combos.data.SupportGroup) {
      supportGroup = combos.data.SupportGroup;
    }
  }
  return {
    associatList,
    isAssociatAdded,
    isAssociatDeleted,
    memberType,
    relationOptions,
    supportGroup,
    isFetching: isFetching || isFetchingInsert,
  };
})(
  reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(Associations),
);

const selector = formValueSelector('wizard');