import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocViewerComponent from '../../../../../Components/docViewerComponent';
import { labOrderLabelGet } from '../../../../../store/actions';


// let url;
class Docview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: null,
    };
  }

  // getStudentId = () => {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   return param.get('student-id');
  // };
  // sguid = () => {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   return param.get('sguid');
  // };
  // docType = () => {
  //   var param = new URLSearchParams(this.props.history.location.search);
  //   return param.get('type');
  // };

  // componentDidMount() {
  //   this.props.dispatch(
  //     labOrderLabelGet({
  //       Json: JSON.stringify({
  //         LabOrder: [
  //           {
  //             LabOrderId: this.props.LabOrderId,
  //           },
  //         ],
  //       }),
  //     }),
  //   );
  // }
  // async componentWillReceiveProps({ downloadUrl }) {
  //   if (downloadUrl && downloadUrl != this.props.downloadUrl) {
  //     const res = await fetch(downloadUrl);
  //     const blob = await res.blob();
  //      url = window.URL.createObjectURL(blob);
  //     this.setState({
  //       imageURL: url,
  //     });
  //     // window.URL.revokeObjectURL(url);
  //   }
  // }
  render() {
    const { onOk, onCancel, dispatch, DocumentExtension, isFetching, isClinical } = this.props;
    return (
      <div className="modal-content border-0">
        <div className="modal-header flex-wrap border-bottom-0 ">
          <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>
            ×
          </button>
          <h4
            className="modal-title col-12 "
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <span className="text-left">View Mode</span>
            <span
              className="text-right"
              // href={this.state.imageURL}
              onClick={e => {
                e.stopPropagation();
                // window.URL.revokeObjectURL(url);

                this.props.reset();
                this.props.dispatch(
                  labOrderLabelGet({
                    Json: JSON.stringify({
                      LabOrder: [
                        {
                          LabOrderId: this.props.LabOrderId,
                        },
                      ],
                    }),
                  }),
                );
              }}
              // {"StudentDocument":[{"StudentId":5,"StudentDocumentGuid":"E6B92DDA-B2CD-4615-B19D-521087E388E9"}]}
              // href={record.StorageLocation}
              // className="mr-3"
              style={{ color: '#46B4FE', fontSize: '14px', cursor: 'pointer' }}
              download
            >
              {!this.props.isReadOnlyUser && (
                <span>
                  {' '}
                  <i class="fas fa-file-download"></i> Download
                </span>
              )}
            </span>
          </h4>
        </div>
        <div className="modal-body">
          <div className=" m-0 view-document-modal">
            <div className="">
              <DocViewerComponent file={this.props.downloadUrl} type={'png'} />
            </div>
          </div>
        </div>
        <div className="modal-footer border-top-0 pb-4 pt-2">
          <button
            onClick={() => {
              onCancel();
            }}
            type="button"
            className="btn"
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ lab_order_label_get }) => {
  const { data: LabTestLabel } = lab_order_label_get;

  let downloadUrl;
  if (LabTestLabel && LabTestLabel.result) {
    downloadUrl = LabTestLabel.result.labelUrl;
  }
  return {
    downloadUrl,
  };
};

export default connect(mapStateToProps)(Docview);
