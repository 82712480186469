import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Input, Radio, Form, Checkbox } from 'antd';
import SessionBlock from './sessionBlock';
import { ReduxSelect } from '../../../component/custom-redux-components/index.js';
import {
  getAdvisorCost,
  upsertAdvisorCost,
  getCenterAdvisorDefaultCosts,
  updateCenterAdvisorDefaultCost,
  resetUpdateCenterAdvisorDefaultCost,
} from '../../../store/actions';
import { isComparer, isNumericComparer, isDateComparer } from '../../../util/handler';
import { currencyFormatter, numberFormat } from '../../../util/formatter';
import Loader, { Loader2 } from '../../../Components/Loader';
import CustomModal from '../../../core/modal';
import { EditableRow, EditableCell } from './EditableTable';

class SessionTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataReadOnly: false,
      costPerValue: 0,
      costMonthlyValue: 0,
      selectedRowKeys: [],
      Advisors: [],
      dataSource: [],
      editedSource: {},
      FilterBy: [],
      columns: [
        {
          title: 'Advisor Type',
          dataIndex: 'AdvisorType',
          key: '1',
          // sorter: (a, b) => isComparer(a.AdvisorName, b.AdvisorName),
          // render: (text: string) => <a>{text}</a>,
        },
        {
          title: (
            <span>
              Cost <br />
              Per <br />
              Session
            </span>
          ),
          dataIndex: 'PerSessionCost',
          key: '2',
          // align: 'right',
          // sorter: (a, b) => isNumericComparer(a.PerSessionCost, b.PerSessionCost),
          render: (text, record) => {
            return (
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && currencyFormatter.format(text)}
              </span>
            );
          },
        },
        {
          title: (
            <span>
              Mnthly <br /> Cost
            </span>
          ),
          dataIndex: 'MonthlyCost',
          key: '3',
          // align: 'right',
          // sorter: (a, b) => isNumericComparer(a.MonthlyCost, b.MonthlyCost),
          render: (text, record) => {
            return (
              <span className={record.IsBold ? 'font-weight-bold' : ''}>
                {text && currencyFormatter.format(text)}
              </span>
            );
          },
        },
        {
          title: (
            <span>
              Qrtrly <br />
              Cost
            </span>
          ),
          dataIndex: 'QuarterlyCost',
          key: '4',
          // align: 'right',
          editable: true,
          onCell: record => ({
            record,
            editable: true,
            dataIndex: 'QuarterlyCost',
            title: 'Quarterly Cost',
            handleSave: this.handleSave,
          }),
        },
        {
          title: (
            <span>
              Half
              <br />
              Yrly <br /> Cost
            </span>
          ),
          dataIndex: 'HalfYearlyCost',
          key: '5',
          // align: 'right',
          editable: true,
          onCell: record => ({
            record,
            editable: true,
            dataIndex: 'HalfYearlyCost',
            title: 'HalfYearly Cost',
            handleSave: this.handleSave,
          }),
        },
        {
          title: (
            <span>
              Annual <br />
              Cost
            </span>
          ),
          dataIndex: 'AnnualCost',
          key: '6',
          // align: 'right',
          editable: true,
          onCell: record => ({
            record,
            editable: true,
            dataIndex: 'AnnualCost',
            title: 'Annual Cost',
            handleSave: this.handleSave,
          }),
        },
      ],
    };
  }
  componentDidMount() {
    this.props.dispatch(getCenterAdvisorDefaultCosts({}));
    // this.props.dispatch(
    //   getAdvisorCost({
    //     Json: JSON.stringify({
    //       AdvisorCost: [
    //         {
    //           BadgeId: this.props.BadgeId,
    //         },
    //       ],
    //     }),
    //   }),
    // );
  }
  handleCostPer = value => {
    const { CostPerSession, Messages } = this.props;
    let { GreenRangeStart, GreenRangeEnd } = CostPerSession;
    let costPerColorCls = '';
    let costPerNotificationText = '';
    if (value < GreenRangeStart) {
      costPerNotificationText = Messages[0].PriceLevelMessage;
      costPerColorCls = 'warning-notification-block';
    }
    if (value > GreenRangeStart && value < GreenRangeEnd) {
      costPerNotificationText = Messages[2].PriceLevelMessage;
      costPerColorCls = 'success-notification-block';
    }
    if (value > GreenRangeEnd) {
      costPerNotificationText = Messages[1].PriceLevelMessage;
      costPerColorCls = 'warning-notification-block';
    }
    this.setState({ costPerValue: value, costPerColorCls, costPerNotificationText });
  };
  handleSave = row => {
    let newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    let editedSource = this.state.editedSource;
    let newSource = {
      ...editedSource,
      [row.key]: row,
    };
    this.setState({
      dataSource: newData,
      editedSource: newSource,
    });
  };
  handleCostMonthly = value => {
    const { MonthlyCost, Messages } = this.props;
    let { GreenRangeStart, GreenRangeEnd } = MonthlyCost;
    let monthlyColorCls = '';
    let monthlyNotificationText = '';
    if (value < GreenRangeStart) {
      monthlyNotificationText = Messages[0].PriceLevelMessage;
      monthlyColorCls = 'warning-notification-block';
    }
    if (value > GreenRangeStart && value < GreenRangeEnd) {
      monthlyNotificationText = Messages[2].PriceLevelMessage;
      monthlyColorCls = 'success-notification-block';
    }
    if (value > GreenRangeEnd) {
      monthlyNotificationText = Messages[1].PriceLevelMessage;
      monthlyColorCls = 'warning-notification-block';
    }
    this.setState({ costMonthlyValue: value, monthlyColorCls, monthlyNotificationText });
  };

  componentWillReceiveProps({
    Advisors,
    CostPerSession,
    FilterBy,
    Messages,
    MonthlyCost,
    isUpsert,
    isLockPricing,
    isDataReadOnly,
  }) {
    if (Advisors && JSON.stringify(Advisors) != JSON.stringify(this.props.Advisors)) {
      this.setState({ dataSource: Advisors });
    }
    // if (FilterBy && JSON.stringify(FilterBy) != JSON.stringify(this.props.FilterBy)) {
    //   this.setState({ FilterBy });
    // }
    if (
      CostPerSession &&
      JSON.stringify(CostPerSession) != JSON.stringify(this.props.CostPerSession)
    ) {
      this.setState({
        costPerValue: CostPerSession.CurrentCost || 0,
        lockPricing: isLockPricing,
        isDataReadOnly: isDataReadOnly,
      });
    }
    // if (Messages && JSON.stringify(Messages) != JSON.stringify(this.props.Messages)) {
    //   this.setState();
    // }
    if (MonthlyCost && JSON.stringify(MonthlyCost) != JSON.stringify(this.props.MonthlyCost)) {
      this.setState({
        costMonthlyValue: MonthlyCost.CurrentCost || 0,
      });
    }
    if (isUpsert && isUpsert != this.props.isUpsert) {
      this.setState({
        upsertModelOpen: true,
        costPerValue: 0,
        costMonthlyValue: 0,
        selectedRowKeys: [],
      });
      this.props.dispatch(resetUpdateCenterAdvisorDefaultCost({}));
    }
  }

  SaveAdvisorCost = () => {
    const {
      costPerValue,
      costMonthlyValue,
      selectedRowKeys,
      editedSource,
      lockPricing,
    } = this.state;
    const { BadgeId } = this.props;
    let key = (selectedRowKeys.length > 0 && selectedRowKeys[0].key) || this.state.key;
    this.state.key = key;
    let Advisors = [];

    if (Object.values(editedSource).length > 0 && key) {
      const {
        CenterAdvisorCostId,
        SubscriptionTypeId,
        QuarterlyCost,
        HalfYearlyCost,
        AnnualCost,
      } = editedSource[key];
      Advisors = [
        {
          CenterAdvisorCostId: CenterAdvisorCostId,
          SubscriptionTypeId: SubscriptionTypeId,
          QuarterlyCost: QuarterlyCost,
          HalfYearlyCost: HalfYearlyCost,
          AnnualCost: AnnualCost,
        },
      ];
    } else {
      Advisors = selectedRowKeys.map(item => {
        return {
          CenterAdvisorCostId: item.CenterAdvisorCostId,
          SubscriptionTypeId: item.SubscriptionTypeId,
          QuarterlyCost: item.QuarterlyCost,
          HalfYearlyCost: item.HalfYearlyCost,
          AnnualCost: item.AnnualCost,
        };
      });
    }
    this.props.dispatch(
      updateCenterAdvisorDefaultCost({
        Json: JSON.stringify({
          AdvisorCost: [
            {
              PerSessionCost: costPerValue,
              MonthlyCost: costMonthlyValue,
              Advisors: Advisors,
              IsLockPricing: lockPricing,
            },
          ],
        }),
      }),
    );
    // {"AdvisorCost":[{"PerSessionCost":50,"MonthlyCost":180,"Advisors":[{"UserGuid":"EB3E9EC2-4348-4754-A027-58AF9AE538D0"},{"UserGuid":"3D8CABA3-8237-416D-AF67-BB1342621AFC"},{"UserGuid":"4833E380-FF86-4360-A0A0-80CE95BE8C5B"}]}]}
  };
  render() {
    const { Advisors, FilterBy, CostPerSession, Messages, MonthlyCost, isFetching } = this.props;
    const {
      columns,
      dataSource,
      costPerValue,
      costMonthlyValue,
      costPerColorCls,
      costPerNotificationText,
      monthlyColorCls,
      monthlyNotificationText,
      selectedRowKeys,
    } = this.state;
    const CostPerSessionMarks = {
      [CostPerSession.GreenRangeStart]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(CostPerSession.GreenRangeStart)}</strong>,
      },
      [CostPerSession.GreenRangeEnd]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(CostPerSession.GreenRangeEnd)}</strong>,
      },
      [CostPerSession.RecommendedCost]: {
        style: {
          fontSize: '13px',
          color: '#979797',
        },
        label: (
          <div className="recommended-area ">
            <div>
              <div className="recommended-area-body">
                <span className="tip tip-reply"></span>
                <div className="d-flex">
                  <span style={{ color: '#000', fontWeight: 'bold', marginRight: '5px' }}>
                    {currencyFormatter.format(CostPerSession.RecommendedCost)}{' '}
                  </span>{' '}
                  Recommended
                </div>
              </div>
            </div>
          </div>
        ),
      },
    };
    const MonthlyCostMarks = {
      [MonthlyCost.GreenRangeStart]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(MonthlyCost.GreenRangeStart)}</strong>,
      },
      [MonthlyCost.GreenRangeEnd]: {
        style: {
          fontSize: '12px',
          color: '#979797',
        },
        label: <strong>{currencyFormatter.format(MonthlyCost.GreenRangeEnd)}</strong>,
      },
      [MonthlyCost.RecommendedCost]: {
        style: {
          fontSize: '13px',
          color: '#979797',
        },
        label: (
          <div className="recommended-area ">
            <div>
              <div className="recommended-area-body">
                <span className="tip tip-reply"></span>
                <div className="d-flex">
                  <span style={{ color: '#000', fontWeight: 'bold', marginRight: '5px' }}>
                    {currencyFormatter.format(MonthlyCost.RecommendedCost)}{' '}
                  </span>{' '}
                  Recommended
                </div>
              </div>
            </div>
          </div>
        ),
      },
    };
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    // const columns = this.columns.map(col => {
    //   if (!col.editable) {
    //     return col;
    //   }

    //   return {
    //     ...col,
    //     onCell: record => ({
    //       record,
    //       editable: col.editable,
    //       dataIndex: col.dataIndex,
    //       title: col.title,
    //       handleSave: this.handleSave,
    //     }),
    //   };
    // });

    const radioRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const { MonthlyCost, PerSessionCost, key } = selectedRows[0];
        this.handleCostPer(PerSessionCost);
        this.handleCostMonthly(MonthlyCost);
        this.setState({ selectedRowKeys: selectedRows });
      },
    };
    return (
      <>
        <div class="row" style={{ position: 'relative' }}>
          <Loader2 loading={isFetching} />
          <div className="col-7">
            <div className="individual-pricing default-pricing pt-5">
              <h4 className="pricing-heading">Select Advisor group to apply default pricing</h4>
              <div className="row d-none">
                <div class="col-8 text-left mb-3">
                  <div class="pricing-search-box w-100">
                    <div class="form-group has-search">
                      <input
                        type="text"
                        value={this.state.searchStr}
                        className="form-control shadow-sm"
                        placeholder="Search for guides.."
                        onChange={event => {
                          // this.props.dispatch(
                          //   getAdvisorCost({
                          //     Json: JSON.stringify({
                          //       AdvisorCost: [
                          //         {
                          //           Search: event.target.value,
                          //           BadgeId: this.props.BadgeId,
                          //           FilterBy: this.state.filterBy,
                          //         },
                          //       ],
                          //     }),
                          //   }),
                          // );
                          this.setState({
                            searchStr: event.target.value,
                          });
                        }}
                        //   ref={ref => (this.input = ref)}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <ReduxSelect
                      options={FilterBy}
                      onChange={val => {
                        // this.props.dispatch(
                        //   getAdvisorCost({
                        //     Json: JSON.stringify({
                        //       AdvisorCost: [
                        //         {
                        //           // Search:'',
                        //           BadgeId: this.props.BadgeId,
                        //           FilterBy: val,
                        //         },
                        //       ],
                        //     }),
                        //   }),
                        // );
                        this.setState({
                          filterBy: val,
                        });
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      value={this.state.filterBy}
                      placeholder="Filter By"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-12 mt-2">
                <div class="form-group text-center">
                  <Checkbox
                    checked={this.state.lockPricing}
                    onChange={e => {
                      this.setState({
                        lockPricing: e.target.checked,
                      });
                    }}
                    style={{ marginRight: '8px' }}
                    disabled={this.state.isDataReadOnly}
                  />
                  <span style={{ fontWeight: '600' }}>
                    Lock default pricing. This will lock pricing for all child centers and advisors
                  </span>
                </div>
              </div>
              <div className="default-item-section">
                <div>
                  <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    rowSelection={{
                      type: 'radio',
                      ...radioRowSelection,
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                  />
                  <div></div>
                </div>

                {/* <div>
                  <EditableTable
                    columns={Columns}
                    dataSource={Advisors}
                    handleCostPer={this.handleCostPer}
                    handleCostMonthly={this.handleCostMonthly}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="individual-pricing mt-5">
              <h4 className="pricing-heading">Default pricing for Counselors</h4>
              <div className="default-pricing-section  pl-2">
                <div className="mb-2 center-block">
                  <SessionBlock
                    labelText={'Cost per session'}
                    icon={'clock.svg'}
                    included={false}
                    handleCostPer={this.handleCostPer}
                    costPerValue={costPerValue}
                    costPerColorCls={costPerColorCls}
                    tooltipVisible={true}
                    costPerNotificationText={costPerNotificationText}
                    marks={CostPerSessionMarks}
                    max={CostPerSession.MaximumCost}
                    min={CostPerSession.MinimumCost}
                    greenRangeStart={CostPerSession.GreenRangeStart}
                    greenRangeEnd={CostPerSession.GreenRangeEnd}
                  />
                </div>
                <div className="mb-2 center-block">
                  <SessionBlock
                    labelText={'Monthly Cost'}
                    icon={'calender.svg'}
                    included={false}
                    handleCostPer={this.handleCostMonthly}
                    costPerValue={costMonthlyValue}
                    costPerColorCls={monthlyColorCls}
                    tooltipVisible={true}
                    costPerNotificationText={monthlyNotificationText}
                    marks={MonthlyCostMarks}
                    max={MonthlyCost.MaximumCost}
                    min={MonthlyCost.MinimumCost}
                    greenRangeStart={MonthlyCost.GreenRangeStart}
                    greenRangeEnd={MonthlyCost.GreenRangeEnd}
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-10 text-right mt-2 mr-5">
                {!this.state.isDataReadOnly && (
                  <input
                    type="button"
                    value="Save"
                    class="btn btn-sm"
                    style={{ minWidth: '146px', padding: '5px 20px' }}
                    onClick={this.SaveAdvisorCost}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          isOpen={this.state.upsertModelOpen}
          header={'Your changes have been saved successfully'}
          yesAction={() => {
            this.setState({ upsertModelOpen: false });
            this.props.dispatch(getCenterAdvisorDefaultCosts({}));
          }}
          yesText="OK"
          hideCancel={true}
        />
      </>
    );
  }
}
const mapStateToProps = state => {
  const { get_center_advisor_default_costs, update_center_advisor_default_cost } = state;
  const { data, isFetching, error } = get_center_advisor_default_costs;
  const { data: upsertData, isFetching: isFetchingUpsert } = update_center_advisor_default_cost;

  let Advisors = [];
  let CostPerSession = {};
  let FilterBy = [];
  let Messages = [];
  let MonthlyCost = {};
  let isUpsert = false;
  let isLockPricing = false;
  let isDataReadOnly = false;

  if (data && data.Advisors) {
    Advisors = data.Advisors || [];
  }
  if (data && data.PerSessionCost) {
    CostPerSession = data.PerSessionCost[0] || {};
    isLockPricing = data.IsLockPricing;
    isDataReadOnly = data.IsDataReadOnly;
  }
  if (data && data.Messages) {
    Messages = data.Messages || [];
  }
  if (data && data.FilterBy) {
    FilterBy = data.FilterBy || [];
  }
  if (data && data.MonthlyCost) {
    MonthlyCost = data.MonthlyCost[0] || {};
  }
  if (upsertData && upsertData.success) {
    isUpsert = upsertData.success;
  }
  return {
    Advisors,
    CostPerSession,
    FilterBy,
    Messages,
    MonthlyCost,
    isFetching: isFetching || isFetchingUpsert,
    isUpsert,
    isLockPricing,
    isDataReadOnly,
  };
};
export default connect(mapStateToProps)(SessionTab);
